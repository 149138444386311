import { actionTypes } from 'modules/typifications/constants'

export const callInsertionTypifications = () => ({
  type: actionTypes.INSERTION_TYPIFICATIONS_INIT,
})

export const callInsertionTypificationsSuccess = () => ({
  type: actionTypes.INSERTION_TYPIFICATIONS_SUCCESS,
})

export const callInsertionTypificationsFailed = error => ({
  type: actionTypes.INSERTION_TYPIFICATIONS_FAILED,
  payload: error,
})

export const resetInsertionTypificationsState = () => ({
  type: actionTypes.INSERTION_TYPIFICATIONS_RESET,
})
