import { useSelector } from 'react-redux'
import { get } from 'lodash'

import { getSubscriptionsState } from '../../selectors'

const hasPenaltyCondoned = subscription =>
  get(subscription, 'fixed.penaltyCondoned', false) ||
  get(subscription, 'mobile.penaltyCondoned', false)

const hasChangedTariff = subscription => Boolean(get(subscription, 'confirmedTariff', false))
const hasBonusesAdded = subscription => Boolean(get(subscription, 'confirmedBonuses', []).length)
const hasDiscountsAdded = subscription =>
  Boolean(get(subscription, 'confirmedDiscounts', []).length)
const hasSubscriptionModified = subscription =>
  hasChangedTariff(subscription) || hasBonusesAdded(subscription) || hasDiscountsAdded(subscription)
const isModified = subscription =>
  hasPenaltyCondoned(subscription) || hasSubscriptionModified(subscription)

export function useIsOnlyPenaltyCondonation() {
  const modifications = useSelector(getSubscriptionsState)

  const ids = Object.keys(modifications)

  return ids.reduce((isOnlyCondonation, id) => {
    const subscription = modifications[id]
    return !isModified(subscription)
      ? isOnlyCondonation
      : isOnlyCondonation &&
          hasPenaltyCondoned(subscription) &&
          !hasSubscriptionModified(subscription)
  }, true)
}
