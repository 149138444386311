import styled from 'styled-components'
import PropTypes from 'prop-types'
import PanelBase from '../panels/PanelBase'

const statusToBgColorMap = {
  active: '#9adc30',
}

const statusToColorMap = {
  active: '#ffffff',
}

const statusToLabelMap = {
  active: 'Activa',
}

const PanelContainer = styled(PanelBase)`
  background-color: ${props => statusToBgColorMap[props.status]};
  color: ${props => statusToColorMap[props.status]};
`

const PanelStatus = props => {
  const { status, children, ...rest } = props

  return (
    <PanelContainer status={status} {...rest}>
      {statusToLabelMap[status]}
    </PanelContainer>
  )
}

PanelStatus.propTypes = {
  status: PropTypes.oneOf(['active']).isRequired,
  children: PropTypes.node,
}

export default PanelStatus
