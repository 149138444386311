import PropTypes from 'prop-types'

import { useFormikContext, getIn } from 'formik'

import {
  Box,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Grid,
  Divider,
} from '@material-ui/core'
import { ToggleButton } from '@material-ui/lab'
import { makeStyles } from '@material-ui/styles'

import { Modal, SpinnerCenter, ModalActions, Button } from 'components/ui'
import { useLegalTexts } from 'modules/LegalTexts/hooks/index'
import { HTMLTemplate } from 'modules/ui/components/HTMLTemplate/HTMLTemplate'

const useStyles = makeStyles(theme => ({
  'financed-legal-modal': {
    '& p': {
      marginBottom: '20px',
    },
    '& table td': {
      border: `1px solid ${theme.palette.global.gray}`,
      fontWeight: 'normal',
    },
    '& .consent p': {
      margin: '0',
      textAlign: 'justify',
    },
    '& strong': {
      fontWeight: 500,
    },
  },
}))

const LegalButtonSwitch = ({ value, onChange }) => {
  return (
    <Box display="flex">
      <ToggleButton selected={value === '0'} onClick={() => onChange('0')}>
        No
      </ToggleButton>
      <ToggleButton selected={value === '1'} onClick={() => onChange('1')}>
        Sí
      </ToggleButton>
    </Box>
  )
}
LegalButtonSwitch.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

export const FinancedLegalModal = ({ open, onClose, fieldNames }) => {
  const classes = useStyles()

  const { values, setFieldValue } = useFormikContext()

  const consent1 = getIn(values, fieldNames.FINANCED_LEGAL_CONSENT_1)
  const consent2 = getIn(values, fieldNames.FINANCED_LEGAL_CONSENT_2)
  const consent3 = getIn(values, fieldNames.FINANCED_LEGAL_CONSENT_3)

  const { isLoading, legalTexts } = useLegalTexts({ origin: 'sales_financed_device' })

  return (
    <Modal
      title="Comunicaciones y protección de datos XFERA CONSUMER FINANCE EFC"
      isOpen={open}
      className={classes['financed-legal-modal']}>
      {isLoading && <SpinnerCenter showMsg />}
      {!isLoading && (
        <>
          <HTMLTemplate html={legalTexts.legal_text_description} />

          <Table>
            <TableBody>
              <TableRow>
                <TableCell>¿Quién es el responsable del tratamiento de tus datos?</TableCell>
                <TableCell>
                  <HTMLTemplate html={legalTexts.legal_text_financing_company_data} />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>

          <Box mt="35px">
            <Typography variant="body1" style={{ fontWeight: 'bold' }}>
              Consentimiento del titular:
            </Typography>
          </Box>

          <Box className="consent" mb="15px">
            <Grid container>
              <Grid item xs={10}>
                <HTMLTemplate html={legalTexts.legal_text_consent1} />
              </Grid>
              <Grid item xs={2}>
                <Box display="flex" alignItems="center" justifyContent="flex-end" height="100%">
                  <LegalButtonSwitch
                    value={consent1}
                    onChange={v => setFieldValue(fieldNames.FINANCED_LEGAL_CONSENT_1, v)}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Divider />

          <Box className="consent" my="15px">
            <Grid container>
              <Grid item xs={10}>
                <HTMLTemplate html={legalTexts.legal_text_consent2} />
              </Grid>
              <Grid item xs={2}>
                <Box display="flex" alignItems="center" justifyContent="flex-end" height="100%">
                  <LegalButtonSwitch
                    value={consent2}
                    onChange={v => setFieldValue(fieldNames.FINANCED_LEGAL_CONSENT_2, v)}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Box mt="35px">
            <Typography variant="body1" style={{ fontWeight: 'bold' }}>
              Consentimiento para la consulta de mis datos ante la Tesorería General de la Seguridad
              Social:
            </Typography>
          </Box>

          <Box className="consent" mt="15px" mb="35px">
            <Grid container>
              <Grid item xs={10}>
                <HTMLTemplate html={legalTexts.legal_text_consent3} />
              </Grid>
              <Grid item xs={2}>
                <Box display="flex" alignItems="center" justifyContent="flex-end" height="100%">
                  <LegalButtonSwitch
                    value={consent3}
                    onChange={v => setFieldValue(fieldNames.FINANCED_LEGAL_CONSENT_3, v)}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>

          <HTMLTemplate html={legalTexts.legal_text_resume} />

          <ModalActions>
            <Button onClick={onClose} disabled={!consent1 || !consent2 || !consent3}>
              Continuar
            </Button>
          </ModalActions>
        </>
      )}
    </Modal>
  )
}

FinancedLegalModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  fieldNames: PropTypes.object,
}
