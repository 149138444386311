export const LandlineOffer = {
  installationAddress: null,
  isPortabilityFromFtth: '',
  isPortability: '',
  selectedTechnology: '',
  PAR: '',
  IUA: '',
  operator: {
    tradingName: '',
    id: '',
    internalPortability: null,
    isPortable: null,
    installationRequired: null,
  },
  signUpType: '',
  lineNumber: '',
  newNumber: '',
  newFixedNumber: '',
  isSameUser: 'yes',
  campaign: {},
  otherUser: {
    documentType: '',
    documentId: '',
    name: '',
    surname1: '',
    surname2: '',
    companyName: '',
  },
  tariff: {
    id: '',
    name: '',
    monthlyFee: 0,
    discounts: [],
  },
  routerNumber: '',
  routerTerminationFee: '',
  routerName: '',
  broadbandType: '',

  SVA: {
    email: '',
  },
  proSVAs: [],
  crossSellTariffPromo: {},
  hasSecondResidenceDiscount: undefined,
}
