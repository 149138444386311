import styled, { withTheme } from 'styled-components'
import PropTypes from 'prop-types'
import { get } from 'lodash/fp'

const getCalloutBgColor = get('theme.palette.global.gray_light')

const CalloutContainer = styled.div`
  background: ${getCalloutBgColor};
  position: relative;
  width: 100%;
  margin-top: 36px;
  padding: 24px ${props => (props.padded ? '24px' : '0')};

  &::before {
    box-sizing: border-box;
    display: block;
    content: '';
    position: absolute;
    top: -20px;
    height: 20px;
    width: 40px;
    border-top: 0 solid;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 20px solid ${getCalloutBgColor};
    left: ${props => props.caretPosition}%;
  }
`

const Callout = ({ caretPosition, padded, children }) => (
  <CalloutContainer caretPosition={caretPosition} padded={padded}>
    {children}
  </CalloutContainer>
)

Callout.propTypes = {
  caretPosition: PropTypes.number,
  padded: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.node),
  ]),
}

Callout.defaultProps = {
  caretPosition: 50,
  padded: false,
}

export default withTheme(Callout)
