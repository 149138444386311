import { isNil } from 'lodash'

import { getTelesalesChannel } from 'modules/vista-360/helpers/channel.helper'
import { TELESALES_REGEX } from 'services/global-constants/channels'

export const getTelesalesUrl = (customerId, param, location, baseUrl, profile, segment) => {
  const salesUrl = baseUrl
  const hasChannel = location.search.includes('channel')
  let baseUrlIsCartera
  let channel

  if (isNil(profile) || isNil(segment)) {
    baseUrlIsCartera = `${salesUrl}${location.search}`
    channel = 'telesales'
  } else {
    const profileChannel = getTelesalesChannel(profile, segment)
    baseUrlIsCartera = `${salesUrl}${location.search.replace(TELESALES_REGEX, profileChannel)}`
    channel = profileChannel
  }

  const urlParam = customerId ? `${param}=true` : ''
  const finalUrl =
    baseUrlIsCartera.indexOf('?') === -1
      ? `${baseUrlIsCartera}?${urlParam}`
      : `${baseUrlIsCartera}&${urlParam}`

  return {
    hasParam: !!urlParam,
    finalUrl: hasChannel ? finalUrl : `${finalUrl}&channel=${channel}`,
  }
}
