import { withStyles } from '@material-ui/core/styles'

const style = theme => ({
  cardTitle: {
    ...theme.typography.title,
  },
})

const CardTitle = ({ children, classes }) => <h1 className={classes.cardTitle}>{children}</h1>

export default withStyles(style)(CardTitle)
