import PropTypes from 'prop-types'
import styled from 'styled-components'

import { get, map, concat, sortBy } from 'lodash'
import { parseISO } from 'date-fns'

import { Grid } from '@material-ui/core'

import { Board } from '../../components/Board'

import { ChatCard } from './ChatCard'
import { InfoCard } from './InfoCard'

import { MSG_TYPES } from './constants'

export const LivePersonChat = styled(({ className, data }) => {
  const stdMessages = map(get(data, 'communication.messages'), stdMsg => ({
    type: MSG_TYPES.STD,
    text: stdMsg.text,
    date: parseISO(stdMsg.date),
    sentBy: stdMsg.sentBy,
    participant: stdMsg.participant,
    targetSkill: null,
  }))

  const transferMessages = map(get(data, 'communication.transfers'), transfer => ({
    type: MSG_TYPES.TRANSFER,
    text: '',
    date: parseISO(transfer.date),
    sentBy: 'Agent',
    participant: transfer.by,
    targetSkill: transfer.targetSkill,
  }))

  // sorting by date: if has same date ( precision until minutes ) show the Transfer before the message
  const allMsgsSorted = sortBy(concat(transferMessages, stdMessages), [
    item => item.date.toGMTString(),
    'asc',
  ])
  return (
    <>
      <div className={className} data-hook="communication-detail-card">
        <Board>
          <Grid item xs={7}>
            <ChatCard msgs={allMsgsSorted} />
          </Grid>
          <Grid item xs={5}>
            <InfoCard data={data} />
          </Grid>
        </Board>
      </div>
      <div className={className} />
    </>
  )
})`
  .boldable {
    padding: 5px 0;
    & strong {
      font-weight: bold;
    }
  }
  .card-content {
    height: 75vh;
    overflow: auto;
    padding: 10px 24px 18px 24px;
  }
`

LivePersonChat.propTypes = {
  data: PropTypes.object,
  className: PropTypes.string,
}
