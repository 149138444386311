import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'

import FormRow from '../forms/FormRow'

const useStyles = makeStyles(theme => ({
  callout: {
    width: '100%',
    boxShadow: '0 1px 6px 0 rgba(0, 0, 0, 0.2)',
    padding: '8px 20px',
    margin: '12px 0',
    color: theme.palette.global.gray_dark || '#4D4E4F',
    fontWeight: 500,
    display: 'flex',
    flexWrap: 'wrap',
  },
}))

const Callout = ({ children, className, ...props }) => {
  const classes = useStyles()

  return (
    <FormRow className={[classes.callout, ...(className ? [className] : [])].join(' ')} {...props}>
      {children}
    </FormRow>
  )
}

Callout.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}

export default Callout
