import { get, chain, every, filter } from 'lodash'
import { getCountryFromIsoCode, getBuildingInfoFromGescal, NoDataStr } from 'utils'

export const sortProductOrders = arr => arr.sort((current, next) => next.id - current.id)

export const getFullAddress = addressInfo =>
  addressInfo &&
  `${addressInfo.streetOne} ${addressInfo.streetNumber} ${addressInfo.streetTwo} ${
    addressInfo.city
  }, ${get(
    chain(get(addressInfo, 'characteristic'))
      .flatMap()
      .find({ name: 'province' })
      .value(),
    'value',
    '',
  )}, ${addressInfo.postCode}`

export const getInstallationAddressFixedLine = order =>
  chain(order)
    .get('customer.contactMedium')
    .filter({ type: 'InstallationAddressFixedLine' })
    .head()
    .get('medium')
    .value()

export const getBillingAddress = order =>
  chain(order)
    .get('customer.contactMedium')
    .filter({ type: 'BillingAddress' })
    .head()
    .get('medium')
    .value()

export const selectLastOrderWithInstallationFixedLine = orders =>
  chain(orders)
    .orderBy('id', 'desc')
    .find(order => getInstallationAddressFixedLine(order))
    .value()

export const getInstallationAddressFixedLineStr = addressInfo => {
  if (addressInfo) {
    const { streetOne, postCode, city, country } = addressInfo
    const province = chain(addressInfo)
      .get('characteristic')
      .filter({ name: 'province' })
      .head()
      .get('value')
      .value()
    const streetNumber = chain(addressInfo)
      .get('characteristic')
      .filter({ name: 'streetNumber' })
      .head()
      .get('value')
      .value()
    const gescal = chain(addressInfo)
      .get('characteristic')
      .filter({ name: 'gescal' })
      .head()
      .get('value')
      .value()

    return every([!streetOne, !streetNumber, !gescal, !postCode, !city, !province, !country])
      ? NoDataStr
      : filter(
          [
            streetOne,
            streetNumber,
            getBuildingInfoFromGescal(gescal),
            postCode,
            city,
            province,
            getCountryFromIsoCode(country),
          ],
          Boolean,
        ).join(' ')
  }

  return NoDataStr
}

export const getIccMobile = (productId, orders) => {
  let icc
  let finalIcc
  let selectedProductNumber
  Object.assign([], orders).forEach(order => {
    selectedProductNumber = chain(order)
      .get('customer.products[0]')
      .get('productNumber')
      .value()
    icc =
      chain(order)
        .get('customer.products[0]')
        .get('productsSpecifications[0]')
        .get('productSpecCharacteristic')
        .find({ name: 'icc' })
        .get('value')
        .value() || '-'
    finalIcc = icc !== '-' && selectedProductNumber.includes(productId) ? icc : finalIcc
  })
  return finalIcc
}

export const getOrdersByIds = (orders, productIds) =>
  productIds.filter(productId => orders[productId]).map(productId => orders[productId])
