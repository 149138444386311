import { axiosJWT } from 'modules/axios'
import { CONFIG } from 'modules/typifications/environments'

export const getRegistersTipis = (customerId, channel) =>
  axiosJWT({
    method: 'GET',
    url: CONFIG.api.getRegistersTipis,
    params: {
      customerId,
      channel
    },
  }).catch(e => {
    e.response.data.error_message = e.response.data.detailMsg
    throw e
  })

export const getCampaignByEmail = email => 
   axiosJWT({
    method: 'GET',
    url: CONFIG.api.getCampaignByEmail,
    params: {
      user: email,
    },
  })
  .then(resp => resp.data)
  .catch(e => {
    e.response.data.error_message = e.response.data.detailMsg
    throw e
  })