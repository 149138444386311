import { createStructuredSelector } from 'reselect'
import { selectIsCrossSellQueryParam } from 'modules/Router/store/index'
import { selectSaleTax, selectIsCompany, selectIsElFijo, selectSalesTypes } from '../../../store'

export const storeSelectors = createStructuredSelector({
  tax: selectSaleTax,
  isCompany: selectIsCompany,
  isElFijo: selectIsElFijo,
  saleType: selectSalesTypes,
  isCrossSell: selectIsCrossSellQueryParam,
})
