import { LandlineSchema } from './Landline.validation'
import { MainMobileSchema, ExtraMobileSchema } from './MobileLineWithTariff.validation'

export const OfferSchema = (isMobileOnly, isElFijo, isPosSale, is2p, isCrossSell, is2pPure) => {
  const commonOfferSchema = {
    ...MainMobileSchema(isElFijo, isPosSale, is2p, isCrossSell),
    ...ExtraMobileSchema(false, false, isPosSale),
  }
  return !isMobileOnly && !isElFijo
    ? {
        ...LandlineSchema(is2p, is2pPure),
        ...commonOfferSchema,
      }
    : commonOfferSchema
}
