import * as constants from './constants'

export const createTypification = typificationData => ({
  type: constants.CREATE_TYPIFICATION_ATTEMPTED,
  payload: typificationData,
})

export const typificationCreated = data => ({
  type: constants.CREATE_TYPIFICATION_SUCCEEDED,
  payload: {
    data,
  },
})

export const createTypificationFailed = error => ({
  type: constants.CREATE_TYPIFICATION_FAILED,
  payload: error,
})

export const clearTypificationError = () => ({
  type: constants.CLEAR_TYPIFICATION_ERROR,
})
