import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import { useFormikContext } from 'formik'

import { FormControlLabel, Radio, Typography, Divider, Box, Grid } from '@material-ui/core'

import { OutlinedDivider, FormikInput, FormikRadio, ButtonLink, Button } from 'components/ui'
import { formatCensuredDni } from 'modules/vista-360/helpers/formats.helper'

import { COMPANY } from 'services/global-constants'
import { get, includes, isEmpty } from 'lodash'
import { documentTypes, isValidDocumentByType } from 'modules/CustomerDocument'

import { CANT_CHECK_USER } from 'modules/SharedSales/constants'
import { selectIsPosOrMasProximo } from 'modules/Permissions'

import {
  selectConstitutionDate,
  selectors as customer360selectors,
} from 'services/customer-360/selectors'
import { selectIsMobileOnly } from 'modules/Router/store'
import { resetCustomerOrderLimit } from 'modules/CustomerOrders/store/index'
import {
  fetchPrescoringAction,
  setNewBasketAccountValuesCartera,
  getFetchPresaleData,
  setBasketData,
} from 'modules/NewClientSales/store'
import { useAnalytics } from 'modules/Analytics/hooks/index'
import { useValidateErrors } from 'modules/NewClientSales/hooks/Validations/useValidateErrors'

import {
  selectMasStackData,
  selectCustomerAllowed,
  selectCustomerAllowedError,
} from 'modules/CustomerInfo/store/customerInfo.selectors'
import { fetchCustomerIsAllowed } from 'modules/CustomerInfo/store/customerInfo.actions'
import { CLIENT_MAS_STACK } from 'modules/SharedSales/constants/validations'
import { Company } from './Company'
import { getCleanAccount } from './Lead.helpers'
import { selectLead } from './Lead.selectors'
import { useUpdateCarteraValues } from '../../../hooks/Cartera'
import { ErrorModal } from '../../Common'
import { useLeadValidation } from './useLeadValidation'

export function Lead({
  isSegmentChooserDisabled,
  isCartera,
  isCrossSell,
  isPresale,
  isMultiSim,
  applyPrescoring,
}) {
  const location = useLocation()
  const dispatch = useDispatch()

  const isPosOrMasProximo = useSelector(selectIsPosOrMasProximo)

  const customerConstitutionDate = useSelector(selectConstitutionDate)
  const customerBirthday = useSelector(customer360selectors.getBirthday)

  const presaleData = useSelector(getFetchPresaleData)

  const { setFieldValue, values, setFieldTouched } = useFormikContext()

  const account = get(values, 'account', {})
  const { documenttype, segment, documentid, companyCif } = account

  const analytics = useAnalytics()
  const { countries = [], segments = [] } = useSelector(selectLead)

  const masStackData = useSelector(selectMasStackData)
  const isAllowed = useSelector(selectCustomerAllowed)
  const customerIsAllowedError = useSelector(selectCustomerAllowedError)

  const { validateErrors } = useValidateErrors('ClientInfoForm')

  async function validateMandatoryFields() {
    const errors = await validateErrors()
    setFieldTouched('landlineOffer.isPortability', false)
    setFieldTouched('account.birthday', false)
    setFieldTouched('account.phoneContact', false)

    const errorAccount = get(errors, 'account', [])
    const errorLandlineOffer = get(errors, 'landlineOffer', [])

    const validatedFieldsAccount = Object.keys(errorAccount).filter(
      error => !['birthday', 'phoneContact'].includes(error),
    )

    const validatedFieldsLandlineOffer = Object.keys(errorLandlineOffer).filter(
      error => !['isPortability'].includes(error),
    )

    return !isEmpty(validatedFieldsAccount) || !isEmpty(validatedFieldsLandlineOffer)
  }

  const { setCarteraValuesHaveChanged } = useUpdateCarteraValues({
    action: setNewBasketAccountValuesCartera,
    flags: { requiresAccountId: true },
  })

  const isCompany = segment === COMPANY

  const documentType = documenttype

  const { validateIfClientIsRegistered, error, hasMsError, fieldName, fieldvalue, resetMsError } =
    useLeadValidation({
      isCartera,
      isCrossSell,
      isMultiSim,
    })

  useEffect(() => {
    const isEditing = location.search.indexOf('isEditing') !== -1

    if (isPresale && !!presaleData && !location?.state?.goingBack && !isEditing) {
      const {
        data: { account: presaleAccountData },
      } = presaleData

      validateIfClientIsRegistered({
        value:
          presaleAccountData.segment !== COMPANY
            ? presaleAccountData.documentid
            : presaleAccountData.companyCif,
        docType: presaleAccountData.segment !== COMPANY ? presaleAccountData.documenttype : 'CIF',
        isMainDocument: true,
      })
    }
  }, [presaleData])

  useEffect(() => {
    const isNewSale = !isCartera && !isCrossSell && !isMultiSim
    if (
      isNewSale &&
      !isCompany &&
      !!documentid &&
      !!documenttype &&
      isValidDocumentByType(documentid, documenttype)
    ) {
      dispatch(fetchCustomerIsAllowed({ documentType: documenttype, documentNumber: documentid }))
    }
  }, [documenttype, documentid])

  useEffect(() => {
    const isNewSale = !isCartera && !isCrossSell && !isMultiSim
    if (isNewSale && isCompany && !!companyCif && isValidDocumentByType(companyCif, 'CIF')) {
      dispatch(fetchCustomerIsAllowed({ documentType: 'CIF', documentNumber: companyCif }))
    }
  }, [companyCif])

  useEffect(() => {
    if (isAllowed) {
      validateIfClientIsRegistered({
        value: documentid,
        isMainDocument: !isCompany,
        docType: documenttype,
      })
    }
  }, [isAllowed])

  const countriesArr = countries.map(x => ({
    key: x.alpha2code,
    value: x.alpha2code,
    label: x.name,
  }))

  const segmentLabels = segments.map(x => (
    <FormControlLabel
      data-hook={x.description}
      value={x.backendId}
      control={<Radio className="radio-check" color="primary" />}
      label={x.description}
      key={x.backendId}
    />
  ))

  const cardTitle = isCompany ? 'DATOS DEL ADMINISTRADOR' : 'DATOS PERSONALES'

  const isMobileOnly = useSelector(selectIsMobileOnly)

  function changeNationalityByDocumentType(value) {
    if (value === 'NIF') {
      setFieldValue('account.nationality', 'ES', false)
    } else {
      setFieldValue('account.nationality', '', false)
    }
  }

  function resetSegmentDependantData(currentSegmentValue) {
    // If the user has already selected a segment
    analytics.sendCustomEvent({
      dataToSend: {
        eventAction: `Accion/TipoSegmento`,
        eventLabel: currentSegmentValue,
      },
    })
    if (segment) {
      dispatch(resetCustomerOrderLimit())
      // And is coming from or going to Company segment
      if (includes([currentSegmentValue, segment], COMPANY)) {
        // Reset data
        const cleanAccount = getCleanAccount(account, currentSegmentValue)
        setFieldValue('account', cleanAccount)
      }
    }
  }

  async function dispatchPrePrescoring() {
    const hasError = await validateMandatoryFields()

    if (!hasError) {
      dispatch(setBasketData(values))
      dispatch(
        fetchPrescoringAction({
          scoreType: 0,
        }),
      )
    }
  }

  const shouldShowBirthdayField = !isCompany || (!isCartera && !isCrossSell) || !account.accountId
  const shouldShowPrePrescoring = applyPrescoring && !isCompany

  return (
    <div>
      <div className="m-y-12">
        <FormikRadio
          label="Selecciona el segmento"
          disabled={isSegmentChooserDisabled || isMultiSim || isCartera || isCrossSell}
          name="account.segment"
          row
          dataHook="segment-chooser"
          onChange={e => {
            resetSegmentDependantData(e.target.value)
            resetMsError()
          }}>
          {segmentLabels}
        </FormikRadio>
      </div>

      <Divider />

      <Typography variant="subtitle1" color="secondary" className="bold m-b-8 m-t-32">
        {cardTitle}
      </Typography>

      <Grid
        container
        spacing={2}
        style={!shouldShowPrePrescoring ? { marginBottom: '20px' } : null}>
        <Grid item sm={4}>
          <FormikInput
            disabled={{ misses: 'account.segment', bool: isCartera || isMultiSim || isCrossSell }}
            name="account.documenttype"
            select
            fullWidth
            label="T.Documento"
            options={[
              {
                key: documentTypes.NIF,
                value: documentTypes.NIF,
                label: documentTypes.NIF,
              },
              {
                key: documentTypes.NIE,
                value: documentTypes.NIE,
                label: documentTypes.NIE,
              },
            ]}
            onChange={value => {
              validateIfClientIsRegistered({
                value: documentid,
                docType: value,
                isMainDocument: !isCompany,
                fromDocument: true,
              })
              changeNationalityByDocumentType(value)
            }}
          />
        </Grid>
        <Grid item sm={4}>
          <FormikInput
            name="account.documentid"
            maxLength="9"
            fullWidth
            label="NIF / NIE"
            disabled={{
              misses: 'account.documenttype',
              bool: isCartera || isMultiSim || isCrossSell,
            }}
            censuredHelper={
              (isCartera || isMultiSim || isCrossSell) && isPosOrMasProximo
                ? formatCensuredDni
                : null
            }
            uppercase
          />
          {!isCompany && !!documentid && hasMsError && error === CANT_CHECK_USER && (
            <ButtonLink
              className="m-l-12"
              onClick={() =>
                validateIfClientIsRegistered({
                  value: documentid,
                  docType: documenttype,
                  isMainDocument: !isCompany,
                })
              }>
              Reintentar
            </ButtonLink>
          )}
        </Grid>
        <Grid item sm={4}>
          <FormikInput
            name="account.nationality"
            disabled={{
              misses: 'account.documentid',
              error: 'account.documentid',
              bool: documentType === 'NIF' || isCartera || isMultiSim || isCrossSell,
            }}
            label="Nacionalidad"
            select
            fullWidth
            options={countriesArr}
          />
        </Grid>
      </Grid>

      {!shouldShowPrePrescoring && <OutlinedDivider />}

      <Grid
        container
        spacing={2}
        style={
          shouldShowPrePrescoring
            ? { marginTop: '16px', marginBottom: '20px' }
            : { marginTop: '16px' }
        }>
        <Grid item sm={4}>
          <FormikInput
            name="account.name"
            fullWidth
            label="Nombre"
            disabled={{
              misses: 'account.documentid',
              error: 'account.documentid',
              bool: isCartera || isMultiSim || isCrossSell,
            }}
            capitalize
          />
        </Grid>
        <Grid item sm={4}>
          <FormikInput
            name="account.surname1"
            fullWidth
            label="Primer apellido"
            disabled={{
              misses: 'account.documentid',
              error: 'account.documentid',
              bool: isCartera || isMultiSim || isCrossSell,
            }}
            capitalize
          />
        </Grid>
        <Grid item sm={4}>
          <FormikInput
            name="account.surname2"
            fullWidth
            label="Segundo apellido"
            disabled={{
              misses: 'account.documentid',
              error: 'account.documentid',
              bool: isCartera || isMultiSim || isCrossSell,
            }}
            capitalize
          />
        </Grid>
      </Grid>

      {shouldShowPrePrescoring && (
        <>
          <OutlinedDivider />

          <Grid container spacing={2} style={{ marginTop: '16px' }}>
            <Grid item sm={12}>
              <Box display="inline-block" mr="8px">
                Para saber si puedes continuar con la venta, completa: Dirección, Documento, Nombre
                y Apellidos y pulsa
              </Box>
              <Button
                size="small"
                style={{ minWidth: '70px' }}
                onClick={dispatchPrePrescoring}
                data-hook="button-prescoring">
                AQUI
              </Button>
            </Grid>
          </Grid>
        </>
      )}

      <Grid container spacing={2} style={{ marginTop: '16px' }}>
        {shouldShowBirthdayField && (
          <Grid item sm={6}>
            <FormikInput
              date
              name="account.birthday"
              dataTest="account.birthday"
              disabled={{
                misses: 'account.documentid',
                error: 'account.documentid',
                bool:
                  (isCartera || isMultiSim || isCrossSell) &&
                  !!account.accountId &&
                  !!customerBirthday,
              }}
              label="Fecha nacimiento"
            />
          </Grid>
        )}

        {!isMultiSim && (
          <Grid item sm={shouldShowBirthdayField ? 6 : 12}>
            <FormikInput
              maxLength="9"
              name="account.phoneContact"
              onChange={() => setCarteraValuesHaveChanged(true)}
              label="Teléfono de contacto"
              disabled={{
                misses: 'account.documentid',
                error: 'account.documentid',
              }}
            />
          </Grid>
        )}

        {isMultiSim && (
          <Grid item sm={shouldShowBirthdayField ? 6 : 12}>
            <FormikInput
              name="mainProductNumber"
              label="Línea principal"
              disabled={{
                bool: true,
              }}
            />
          </Grid>
        )}
      </Grid>

      {isCompany && (
        <>
          <Box my="20px">
            <Divider />
          </Box>

          <Company
            validateIfClientIsRegistered={validateIfClientIsRegistered}
            isMobileOnly={isMobileOnly}
            isCartera={isCartera}
            isCrossSell={isCrossSell}
            isMultiSim={isMultiSim}
            showRetry={!!companyCif && hasMsError && error === CANT_CHECK_USER}
            isDateDisabled={
              (!!isCartera || !!isCrossSell || !!isMultiSim) &&
              !!account.accountId &&
              !!customerConstitutionDate
            }
            isPosOrMasProximo={isPosOrMasProximo}
          />
        </>
      )}
      {!customerIsAllowedError && !isEmpty(masStackData) && (
        <ErrorModal error={CLIENT_MAS_STACK} hasMsError={!isAllowed} />
      )}
      {
        <ErrorModal
          error={error}
          hasMsError={hasMsError}
          fieldName={fieldName}
          fieldValue={fieldvalue}
        />
      }
    </div>
  )
}

Lead.propTypes = {
  isSegmentChooserDisabled: PropTypes.bool,
  isCartera: PropTypes.bool,
  isCrossSell: PropTypes.bool,
  isPresale: PropTypes.bool,
  isMultiSim: PropTypes.bool,
  applyPrescoring: PropTypes.bool,
}
