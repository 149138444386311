import { objectToQueryString } from 'modules/url/queryParamHelpers'

export const generateMasConsentsParams = ({
  brand,
  segment,
  subscriptionId,
  verbalId,
  sfid,
  channel,
  customerDocument,
  consentType,
  token,
}) =>
  objectToQueryString({
    token,
    lang: 'es-ES',
    brand,
    segment,
    contractAgreementId: subscriptionId,
    verbalAgreementId: verbalId,
    sfid,
    channel,
    customerDocumentNumber: customerDocument,
    consentType,
  })
