const FIND_ORDERONFLY_INIT = 'OVID/ORDERONFLY/FIND_ORDERONFLY_INIT'
const FIND_ORDERONFLY_SUCCESS = 'OVID/ORDERONFLY/ORDERONFLY_SUCCESS'
const FIND_ORDERONFLY_FAILED = 'OVID/ORDERONFLY/FIND_ORDERONFLY_FAILED'

const WORK_ORDER_TYPE_ID_CT_FFTH = 26
const WORK_ORDER_TYPE_ID_CT_NEBA = 201
const WORK_ORDER_NAME_CANGAS = 'MIG_HUELLA'

const WORK_ORDER_TYPE_IDS_CT_FIBER = [WORK_ORDER_TYPE_ID_CT_FFTH, WORK_ORDER_TYPE_ID_CT_NEBA]

export const constants = {
  FIND_ORDERONFLY_INIT,
  FIND_ORDERONFLY_SUCCESS,
  FIND_ORDERONFLY_FAILED,
  WORK_ORDER_TYPE_ID_CT_NEBA,
  WORK_ORDER_TYPE_ID_CT_FFTH,
  WORK_ORDER_NAME_CANGAS,
  WORK_ORDER_TYPE_IDS_CT_FIBER,
}
