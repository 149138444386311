import { pickBy } from 'lodash'
import { ValidateEmail, isMobilePhoneNumber } from 'utils'

import { AUTONOMO } from 'services/global-constants'

export const mapToPersonalData = ({
  name,
  surname1,
  surname2,
  nationality,
  birthday,
  language,
  segment,
}) => {
  return {
    language,
    nationality,
    given_name: name,
    family_name: surname1,
    additional_name: surname2 || '',
    date_of_birth: birthday
      ? birthday
          .split('/')
          .reverse()
          .join('-')
      : null,
    privacy_settings: {
      mark_gdpr: false,
    },
    characteristics: {
      self_employed: segment === AUTONOMO ? 'true' : 'false',
    },
    honorific_prefix: 'Sr.',
  }
}

const returnShapedContactMedia = ({ value, type, role }) => {
  const isEmail = ValidateEmail(value)
  const medium = isEmail
    ? {
        email: value,
      }
    : {
        number: value,
        number_type: type,
      }
  return {
    role: role || 'primary',
    medium,
    mediumType: isEmail ? 'email-address' : 'telephone-number',
  }
}

const getNumberTypeFromValue = value => {
  return isMobilePhoneNumber(value) ? 'mobile' : 'fixed-line'
}

export const mapToContactMedia = ({ phoneContact, phoneContact2, email }) => {
  const medias = [
    { value: phoneContact, type: getNumberTypeFromValue(phoneContact) },
    { value: phoneContact2, type: getNumberTypeFromValue(phoneContact2), role: 'home' },
    { value: email, type: 'email' },
  ]

  const mappedMedias = medias.map(elem => returnShapedContactMedia(elem))

  return mappedMedias
}

export const mapToIndividualIdentification = (account, idDueDate) =>
  pickBy({
    attributes: {
      identification_id: account.documentid || account.documentId,
      identification_type: account.documenttype || account.documentType,
      valid_for: idDueDate
        ? {
            end_datetime: idDueDate,
            meta: {
              type: 'valid-for-datetime',
            },
          }
        : null,
    },
  })

export const addIndividualCustomerToParty = ({
  party,
  personalInformation,
  individualIdentification,
  billingAddress,
  individualContactMedia,
}) => {
  const individual = party.createIndividuals()
  party.createPartyAttributes(pickBy(personalInformation))
  individual.addIdentification(individualIdentification)
  individual.addContactMedia({
    ...billingAddress,
    role: 'billing',
    mediumType: 'postal-address',
  })
  individualContactMedia.forEach(elem => individual.addContactMedia(elem))
}
