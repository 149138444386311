import { isEqual } from 'lodash'
import { useEffect, useState } from 'react'

export const useFormDataIsChanged = (values, storedValues = null) => {
  const [formDataIsChanged, setFormDataIsChanged] = useState(false)

  const onFormDataIsChanged = dataValues => {
    setFormDataIsChanged(!storedValues || !isEqual(dataValues, storedValues))
  }

  useEffect(() => {
    onFormDataIsChanged(values)
  }, [values])

  const resetFormDataIsChanged = () => {
    setFormDataIsChanged(false)
  }

  return [formDataIsChanged, resetFormDataIsChanged]
}
