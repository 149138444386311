import { get } from 'lodash'
import { createSelector } from 'reselect'

import { helpers } from './helpers'

const getAtc = state => get(state, 'atc')

const selectAuthToken = createSelector(
  [getAtc],
  atc => get(atc, 'authToken', ''),
)

const selectIsLoading = createSelector(
  [getAtc],
  atc => get(atc, 'loading', false),
)

const selectError = createSelector(
  [getAtc],
  atc => get(atc, 'error', null),
)

const selectHasError = createSelector(
  [getAtc],
  atc => !!get(atc, 'error', null),
)

const selectIframeUrl = createSelector(
  [selectAuthToken],
  authToken => helpers.generateIframeUrl(authToken),
)

export const selectors = {
  selectAuthToken,
  selectIsLoading,
  selectError,
  selectHasError,
  selectIframeUrl,
}
