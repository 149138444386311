import { useSelector } from 'react-redux'
import { useFormikContext } from 'formik'
import { landlineRegex, mobileNumberRegex } from 'utils'
import { selectIsElFijo } from '../../store'
import { useIsCustomerPhoneValidation } from './useIsCustomerPhone'
import { useOperatorValidation } from './useOperatorValidation'

export function useMobileValidation(lineType) {
  const {
    validateCustomer,
    errorCustomer,
    hasMsErrorCustomer,
    fieldCustomer,
    valueCustomer,
  } = useIsCustomerPhoneValidation(lineType)

  const { validateOperator, hasMsOperatorError, errorOperator } = useOperatorValidation(lineType)

  const isElFijo = useSelector(selectIsElFijo)

  const regex = isElFijo ? landlineRegex : mobileNumberRegex

  const { setStatus, status } = useFormikContext()

  async function validateMobile(value, fromSignUp) {
    const customerValidation = await validateCustomer(value, regex, fromSignUp)
    const operatorValidation = await validateOperator(value, regex, fromSignUp)

    const validation = customerValidation || operatorValidation
    setStatus({ ...status, [`${lineType}.lineNumber`]: validation })
    return validation
  }

  return {
    validate: validateMobile,
    error: errorCustomer || errorOperator,
    hasMsError: hasMsErrorCustomer || hasMsOperatorError,
    fieldName: fieldCustomer,
    fieldValue: valueCustomer,
  }
}
