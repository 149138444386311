import styled from 'styled-components'
import TabBase from '@material-ui/core/Tab'
import { getLighten } from 'utils'
import { get } from 'lodash'
import TextStyleCss from '../text-style/TextStyleCss'

const TabSmall = styled(({ ...rest }) => (
  <TabBase
    classes={{
      root: 'tab-root',
      disabled: 'tab-disabled',
    }}
    {...rest}
  />
))`
  opacity: 1;
  & .labelContainer {
    padding: 0;
  }

  & .labelWrapped {
    min-width: auto;
    height: auto;
    &:not(:last-child) {
      margin-right: 20px;
    }
  }

  .label {
    opacity: 1;
    ${({ selected }) => (selected ? TextStyleCss.subtitle_2_dark : TextStyleCss.subtitle_2_link)}
  }

  &.tab-disabled .label {
    color: ${props => getLighten(get(props, 'theme.palette.global.gray_dark'), 0.3)};
  }
`

export default TabSmall
