import PropTypes from 'prop-types'

import { Box } from '@material-ui/core'

import { getScrollbarWidth } from './helper'

const scrollbarWidth = getScrollbarWidth()

function HiddenScrollbar({ children, className }) {
  return (
    <Box width="100%" height="100%" overflow="hidden" position="relative" className={className}>
      <Box
        position="absolute"
        top="0"
        bottom="0"
        left="0"
        right={scrollbarWidth ? `${scrollbarWidth * -1}px` : 0}
        overflow="hidden scroll">
        {children}
      </Box>
    </Box>
  )
}

HiddenScrollbar.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}

export default HiddenScrollbar
