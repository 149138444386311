import { Typography } from '@material-ui/core'
import { TextStyles } from 'utils/text'

import { Card } from 'modules/ui'

export const EmptyBills = () => (
  <Card title="Facturas" id="Facturas" withShadow>
    <div className="p-l-24">
      <Typography {...TextStyles.subtitle2Dark()}>No existen facturas para este cliente</Typography>
    </div>
  </Card>
)

export const ErrorBills = () => (
  <Card title="Facturas" id="Facturas" withShadow>
    <div className="p-l-24">
      <Typography {...TextStyles.subtitle2Dark()}>Se ha producido un error</Typography>
    </div>
  </Card>
)
