import { get } from 'lodash'
import { createSelector } from 'reselect'
import { selectDiscountsIncompatibilities } from 'services/discounts-incompatibilities'
import { selectUsername } from 'modules/Auth'

import { getIncompatibilityRule, updateDiscountsMeta } from './helpers'

export const selectDiscounts = state => get(state, 'discountsOrder.discounts')
export const selectSelectedDiscount = state => get(state, 'discountsOrder.selectedDiscount')
export const selectLoading = state => get(state, 'discountsOrder.loading')
export const selectError = state => get(state, 'discountsOrder.error')
export const selectPutResponse = state => get(state, 'discountsOrder.put.response')
export const selectPutLoading = state => get(state, 'discountsOrder.put.loading')
export const selectPutError = state => get(state, 'discountsOrder.put.error')
export const selectDiscountsWithIncompatibilities = createSelector(
  [selectDiscounts, selectDiscountsIncompatibilities],
  (discounts = [], incompatibilities = []) => {
    if (!incompatibilities.length) return discounts
    return discounts.map(discount => {
      const incompatibilityRule = getIncompatibilityRule(incompatibilities, discount)
      return incompatibilityRule
        ? { ...discount, incompatibilityRule: incompatibilityRule.rule }
        : discount
    })
  },
)
export const selectDiscountsToUpdate = createSelector(
  [selectDiscounts, selectDiscountsIncompatibilities, selectSelectedDiscount, selectUsername],
  (discounts = [], incompatibilities = [], selectedDiscount = {}, username = '') => {
    const updatedDiscounts = discounts.map(discount =>
      getIncompatibilityRule(incompatibilities, discount)
        ? { ...discount, state: 'Cancelled' }
        : { ...discount, state: 'Pending' },
    )
    return {
      orderDiscounts: updateDiscountsMeta(
        [{ ...selectedDiscount, state: 'New' }, ...updatedDiscounts],
        username,
      ),
    }
  },
)
