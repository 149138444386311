import PropTypes from 'prop-types'
import { ButtonLink } from 'components/ui'

function DownloadBtn({ onDownload, loading, canIBillDownload }) {
  return (
    <ButtonLink
      iconType="download"
      disabled={loading || !canIBillDownload}
      loading={loading}
      onClick={onDownload}
    />
  )
}

DownloadBtn.propTypes = {
  onDownload: PropTypes.func,
  loading: PropTypes.bool,
  canIBillDownload: PropTypes.bool,
}

export default DownloadBtn
