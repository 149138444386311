import { axiosJWT } from 'modules/axios'
import { APP_CONFIG } from 'services/app-config'

const BASE_URL_CHANGE_AGILETV = `${APP_CONFIG.sales_cluster}/v1/svas/changeService/agileTv`

export const changeAgileTv = data => {
  return axiosJWT({
    method: 'POST',
    url: BASE_URL_CHANGE_AGILETV,
    data,
  }).then(resp => resp.data)
}
