import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import { OrderProducts } from '../OrderProducts'
import { Board } from '../../components/Board'

export function OrderProductsMain({ className, ...props }) {
  return (
    <Board className={className}>
      <Grid item xs={12}>
        <OrderProducts {...props} />
      </Grid>
    </Board>
  )
}

OrderProductsMain.propTypes = {
  className: PropTypes.string,
}
