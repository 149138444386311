import { axiosCustomerV3 } from 'modules/axios'

import { CONFIG } from './environments'

export function findOrderProductsById(accountId, orderProductId) {
  return axiosCustomerV3({
    method: 'GET',
    // /masmovil/v3/customers/{{account_id}}/orderProducts/4141
    url: `${CONFIG.api.orderProducts}/${accountId}/orderProducts/${orderProductId}`,
  }).then(resp => resp.data)
}
