import { call, put, takeLatest, delay } from 'redux-saga/effects'
import { logError, formatErrorMessage } from 'services/logging'
import { axiosFactory } from 'modules/axios'

import {
  customerInfoActionTypes,
  fetchIsCustomerActionError,
  fetchIsCustomerActionSuccess,
  setCustomerInformation,
  setCustomerInformationError,
  modifyCustomerContactMediaSuccess,
  modifyCustomerContactMediaError,
  fetchCustomerIsAllowedError,
  fetchCustomerIsAllowedSucess,
} from './customerInfo.actions'
import {
  fetchAuthIsCustomerDoc,
  fetchCustomerInformation,
  setCustomerContactMedia,
  fetchCustomerIsAllowed,
} from '../services'

export function* fetchIsCustomerDocSaga({ payload: { docType, docNumber } }) {
  try {
    const isCustomer = yield call(fetchAuthIsCustomerDoc, docType, docNumber)
    yield put(fetchIsCustomerActionSuccess(isCustomer))
  } catch (e) {
    yield call(logError, { e: new Error(formatErrorMessage(e)), docType, docNumber })
    const error = axiosFactory.buildError(e)
    yield put(fetchIsCustomerActionError(error))
  }
}

export function* watchFetchIsCustomerDocSaga() {
  yield takeLatest(customerInfoActionTypes.FETCH_IS_CUSTOMER, fetchIsCustomerDocSaga)
}

export function* fetchCustomerInformationSaga({ payload: { docType, docNumber } }) {
  try {
    if (docNumber && docType) {
      const customerInformation = yield call(fetchCustomerInformation, docNumber, docType)
      yield put(setCustomerInformation(customerInformation))
    }
  } catch (e) {
    console.warn(e)
    yield call(logError, new Error(formatErrorMessage(e)))

    if (e.response.status === 404) {
      yield put(setCustomerInformation({}))
      return
    }

    yield put(setCustomerInformationError(e))
  }
}

export function* watchFetchCustomerInformationSaga() {
  yield takeLatest(customerInfoActionTypes.FETCH_CUSTOMER_INFORMATION, fetchCustomerInformationSaga)
}

export function* modifyCustomerContactMediaSaga({
  payload: { accountId, segment, data, delay: delayTime },
}) {
  try {
    const newAccountId = yield call(setCustomerContactMedia, accountId, segment, data)

    if (delayTime) {
      yield delay(delayTime)
    }

    yield put(modifyCustomerContactMediaSuccess(newAccountId))
  } catch (e) {
    console.warn(e)
    yield call(logError, {
      e: new Error(formatErrorMessage(e)),
      payload: { accountId, segment, data },
    })
    const error = axiosFactory.buildError(e)
    yield put(modifyCustomerContactMediaError(error))
  }
}

export function* watchModifyCustomerContactMediaSaga() {
  yield takeLatest(
    customerInfoActionTypes.MODIFY_CUSTOMER_CONTACT_MEDIA,
    modifyCustomerContactMediaSaga,
  )
}

export function* customerIsAllowedSaga({ payload: { documentType, documentNumber } }) {
  try {
    const { allowed } = yield call(fetchCustomerIsAllowed, documentType, documentNumber)
    yield put(fetchCustomerIsAllowedSucess({ allowed }))
  } catch (e) {
    yield call(logError, { e: new Error(formatErrorMessage(e)), documentType, documentNumber })
    const error = axiosFactory.buildError(e)
    yield put(fetchCustomerIsAllowedError(error))
  }
}

export function* watchCustomerIsAllowedSaga() {
  yield takeLatest(customerInfoActionTypes.FETCH_CUSTOMER_IS_ALLOWED, customerIsAllowedSaga)
}
