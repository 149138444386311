import PropTypes from 'prop-types'

import { Divider } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import { ActionAdvice } from 'components/ui'

export function SecondResidenceDiscountAdvice({ discountMessage }) {
  const useStyles = makeStyles({
    adviceStyles: {
      width: '100%',
      '& .advice-text-field': {
        paddingRight: '16px',
      },
      '& .message': {
        paddingTop: '5px',
        paddingBottom: '5px',
        marginRight: '24px',
      },
      '& .text-wrapper': {
        position: 'relative',
        paddingTop: '10px',
        paddingBottom: '10px',
        paddingRight: '0px',
      },
      '& .icon': {
        alignSelf: 'center',
        cursor: 'pointer',
      },
      '& [data-hook="icon-cancel"]': {
        position: 'absolute',
        right: '12px',
      },
      '& .advice-wrapper': {
        width: '100%',
      },
    },
    wrapper: {
      fontWeight: '500',
      width: '390px',
      '& .advice-bundle': {
        width: '100%',
      },
    },
  })

  const styles = useStyles()

  return (
    <div className="full-width" data-hook="second-residence-discount">
      <Divider absolute />
      <div className="p-t-20 p-b-20 flex flex-wrap">
        <div className={`flex m-y-4 ${styles.wrapper} m-r-16 m-y-4`}>
          <ActionAdvice
            className={`advice ${styles.adviceStyles}`}
            message={discountMessage}
            type="info"
            block
          />
        </div>
      </div>
    </div>
  )
}

SecondResidenceDiscountAdvice.propTypes = {
  discountMessage: PropTypes.string.isRequired,
}
