import { get } from 'lodash'
import { createSelector } from 'reselect'

import {
  selectSegmentR,
  getContractid,
  selectTechnology,
  selectProductType,
  getContactMediumFromOrdersCustomer,
  getContactMediumFixedLine,
} from 'modules/orders'
import { getCoverageAddressUpdateOrder } from 'modules/Coverage'
import { getCurrentDate } from 'utils'
import { selectQueryParams } from 'modules/Router'
import { selectSfid } from 'modules/Auth'
import { getCustomerData } from './helpers'

export const selectContactMediumFixedLine = createSelector(
  [getContactMediumFromOrdersCustomer, getCoverageAddressUpdateOrder],
  getContactMediumFixedLine,
)

export const selectCustomerData = createSelector(
  [
    selectContactMediumFixedLine,
    getCurrentDate,
    selectQueryParams,
    selectSegmentR,
    getContractid,
    selectTechnology,
    selectProductType,
    selectSfid,
  ],
  getCustomerData,
)

export const getRove = state => get(state, 'rove.rove', '')
