export const RouterAnimationType = {
  NONE: '',
  LEFT: 'left',
  RIGHT: 'right',
  FADE: 'fade',
}

export const RouterAnimationTypes = Object.keys(RouterAnimationType).map(
  key => RouterAnimationType[key],
)

export function getAnimationType(prevRoute, nextRoute) {
  // Add your slided routes slugs here and let the magic do the thing
  const slidePaths = ['changeOwner', 'orderDetail', 'prepaid-detail']

  if (slidePaths.some(path => nextRoute.indexOf(path) !== -1)) {
    return RouterAnimationType.RIGHT
  }

  if (slidePaths.some(path => prevRoute.indexOf(path) !== -1)) {
    return RouterAnimationType.LEFT
  }

  return RouterAnimationType.NONE
}
