import { useState } from 'react'
import PropTypes from 'prop-types'
import { get, filter } from 'lodash'

import { Box } from '@material-ui/core'

import styled from 'styled-components'
import { getLighten } from 'utils'
import { Tabs } from 'components/ui'
import { Card } from 'modules/ui'
import { barringWithErrors, barringOnGoing } from 'modules/barrings'

import { getTabsConfig, tabs } from './tabsConfig'

const CardWrapper = styled(Card)`
  .tabHeader {
    padding: 0;
    border-bottom: 2px solid
      ${props => getLighten(get(props, 'theme.palette.global.gray_medium'), 0.8)};
  }
`

export const ProductServicesCard = ({
  data,
  sortedBarrings,
  isBarringsStatusLoading,
  hasBarringsStatusErrors,
  activeSubscrip,
  onflySubscrip,
  productId,
  multisim,
  isPosOrMasproximo,
}) => {
  const tabsInfo = getTabsConfig(multisim, isPosOrMasproximo)
  const visibleTabsInfo = !activeSubscrip
    ? filter(tabsInfo, infoTab => infoTab.tabType === tabs.SVAS)
    : tabsInfo

  let defaultSelectedTab
  if (get(visibleTabsInfo, '[0].disabled')) {
    if (get(visibleTabsInfo, '[1].disabled')) {
      defaultSelectedTab = '2'
    } else {
      defaultSelectedTab = '1'
    }
  } else {
    defaultSelectedTab = '0'
  }

  const [selectedTab, setSelectedTab] = useState(defaultSelectedTab)

  const TabContent = get(visibleTabsInfo, `${selectedTab}.component`)
  const onSelectChangeHandler = (_, tab) => setSelectedTab(tab)

  return (
    <>
      <CardWrapper margin="0" title="Servicios de la línea" withShadow>
        <Box display="flex" flexDirection="column" className="tabHeader">
          <Tabs
            val={selectedTab}
            tabsInfo={visibleTabsInfo}
            onSelectChange={onSelectChangeHandler}
            data_hook={`${visibleTabsInfo}`}
            isPosOrMasproximo={isPosOrMasproximo}
          />
        </Box>
        <Box display="flex" flexDirection="column" className="tabContent">
          <TabContent
            {...data}
            sortedBarrings={sortedBarrings}
            showErrorMsg={barringWithErrors(sortedBarrings)}
            showBarringOnGoingMsg={barringOnGoing(sortedBarrings)}
            isBarringsStatusLoading={isBarringsStatusLoading}
            hasBarringsStatusErrors={hasBarringsStatusErrors}
            activeSubscrip={activeSubscrip}
            onflySubscrip={onflySubscrip}
            productId={productId}
          />
        </Box>
      </CardWrapper>
    </>
  )
}

ProductServicesCard.propTypes = {
  data: PropTypes.object,
  sortedBarrings: PropTypes.object,
  isBarringsStatusLoading: PropTypes.bool,
  hasBarringsStatusErrors: PropTypes.object,
  activeSubscrip: PropTypes.object,
  onflySubscrip: PropTypes.object,
  productId: PropTypes.string,
  multisim: PropTypes.bool,
  isPosOrMasproximo: PropTypes.bool,
}
