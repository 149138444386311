import { call, put, select, takeEvery } from 'redux-saga/effects'
import { logError, formatErrorMessage } from 'services/logging'
import { axiosFactory } from 'modules/axios'
import { getSegment } from 'services/customer-360/selectors'
import { selectProfileId } from 'modules/Permissions'
import { createOfferRegister } from '../services/activity-register.service'
import {
  REGISTER_OFFER_ATTEMPTED,
  registerOfferErrorAction,
  registerOfferSuccessAction,
} from './activity-register.actions'

export function* registerOfferSaga(action) {
  const profileId = yield select(selectProfileId)
  const { msisdn, ...data } = action.payload
  try {
    const segment = yield select(getSegment)
    yield call(createOfferRegister, msisdn, segment, profileId, data)
    yield put(registerOfferSuccessAction(msisdn))
  } catch (e) {
    yield call(logError, { e: new Error(formatErrorMessage(e)), payload: action.payload })
    const error = axiosFactory.buildError(e)
    yield put(registerOfferErrorAction(msisdn, error))
  }
}

export function* watchActivityRegister() {
  yield takeEvery(REGISTER_OFFER_ATTEMPTED, registerOfferSaga)
}
