import styled from 'styled-components'
import PropTypes from 'prop-types'

const BackgroundColor = styled.div`
  background-color: ${props => (props.background ? props.background : '#f1f8ff')}
  padding: ${props => props.padding || '24px'};
  width: ${props => props.width || 'auto'};
  border-radius: ${props => props.borderRadius || '14px 14px 0px 0px'};
`

const CardBackground = ({ children, padding, width, borderRadius, background }) => (
  <BackgroundColor
    padding={padding}
    width={width}
    borderRadius={borderRadius}
    background={background}>
    {children}
  </BackgroundColor>
)

CardBackground.propTypes = {
  children: PropTypes.node.isRequired,
  padding: PropTypes.string,
  width: PropTypes.string,
  borderRadius: PropTypes.string,
  background: PropTypes.string,
}
export default CardBackground
