import { useState, useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { get, isEmpty, pickBy, isNil, size } from 'lodash'

import { Box, Typography} from '@material-ui/core'
import { Pagination } from "@material-ui/lab"

import { TextStyles } from 'utils/text'
import { Card } from 'modules/ui'
import { NotificationModal, AlertAdvice, ActionAdvice, SpinnerCenter } from 'components/ui'
import { isProCustomer, helpers } from 'services/customer-360/helpers'
import {
  getSegment,
  getIdentificationType,
  getIdentificationId,
  selectMultisimType,
  selectContracts,
  selectors as customer360SvcSelectors,
} from 'services/customer-360/selectors'
import { selectIpAddress, selectSfid } from 'modules/Auth/store/selectors'
import { getOrder, getCurrentTerritoryOwner } from 'modules/Coverage/store'

import {
  getError as getCVMError,
  isLoading as isCVMLoading,
  isTypificationOpen,
  getSubscriptionsState,
} from 'modules/offers-configuration/selectors'

import { getLighten } from 'utils'
import { isDuoTariffId } from 'modules/tariffs/store-apigee/tariffs-apigee.helpers'

import { selectEnergyIsAllowed } from 'modules/energy/store/energy.selectors'

import { BadgeStatusDetail } from 'components'

import { selectQueryParams } from 'modules/Router'

import { MOBILE_PRODUCT_NAME, SUBSCRIPTION_STATUS_ACTIVE } from 'services/subscriptions/constants'
import { TARIFF_TYPE_MAIN_LINE, TERRITORY_OWNER_ADSL } from 'modules/offers-configuration/constants'
import { CATEGORY_MOBILE, CATEGORY_MOBILE_V3, ADSL } from 'modules/tariffs/tariffs.constants'
import {
  ModificationActionsItem,
  SubscriptionFooter,
} from 'modules/offers-configuration/components'
import { OrderCard } from 'modules/offers-configuration/components/OrderCard/OrderCard'

import { getCommercialName, selectTariffsApigeeById } from 'modules/tariffs/store-apigee'
import { useFetchCustomerIds } from 'modules/CustomerInfo'
import { useOrderChannelAndStatus } from 'modules/offers-configuration/hooks/useOrderChannelAndStatus/useOrderChannelAndStatus'
import {
  getSubscriptionTariffId,
  isMobileOnlySubscription,
  getSubscriptions,
} from 'services/subscriptions'

import * as offersDetailSvc from 'services/offers-detail'
import {
  selectBillingId,
  selectAccountId,
  selectCustomerInfoError,
} from 'modules/CustomerInfo/store/customerInfo.selectors'
import { getTax } from 'services/taxes'
import { useRequestIpAddress } from 'modules/Auth/hooks'

import { selectStcV3Flag, selectEnabledButtonInsurancePpi } from 'services/feature-flag/selectors'
import { usePagination } from 'modules/offers-configuration/hooks/usePagination/usePagination'
import { getMsisdnByTypeSubscription, getTypeMigration } from '../helpers'
import { SaleWithClientCardButton } from '../components/SaleWithClientCardButton'
import { SaleEnergyWithClientCardButton } from '../components/SaleEnergyWithClientCardButton'
import { SaleInsuranceWithClientCardButton } from '../components/SaleInsuranceWithClientCardButton'
import { SaleInsurancePpiWithClientCardButton } from '../components/SaleInsurancePpiWithClientCardButton'

import { SubscriptionCardContainer } from './SubscriptionCardContainer'
import { clearError } from '../actions'
import * as offersConfigurationInitSaga from '../sagas/offersConfigurationInit'
import { useDynamicModals, DynamicModal } from '../hooks/useDynamicModals'
import { useFetchOrders } from '../hooks/useFetchOrders'
import { useSubscriptionStatus } from '../hooks/useSubscriptionStatus'
import { usePortOutInfo } from '../hooks/usePortOutInfo'
import { useChangedTariff } from '../hooks/useChangedTariff'
import { useSagaLoad } from '../hooks/useSagaLoad'

const getSuperStatus = (subscription, tariffId, multisimType) => {
  const isMobile = isMobileOnlySubscription(subscription)
  const isMainLine = multisimType === TARIFF_TYPE_MAIN_LINE
  const isDuo = isDuoTariffId(tariffId)

  const isMainMobileAndNotDuo = isMobile && isMainLine && !isDuo

  return isMainMobileAndNotDuo ? MOBILE_PRODUCT_NAME : SUBSCRIPTION_STATUS_ACTIVE
}

function SubscriptionCardLoading({ subscription, className }) {
  const msisdn = getMsisdnByTypeSubscription(subscription)

  const splitter = <span className="p-x-8"> | </span>

  const tariffId = getSubscriptionTariffId(subscription)
  const tariff = useSelector(selectTariffsApigeeById(tariffId))
  const multisimType = useSelector(state => selectMultisimType(state, msisdn))

  const titleHeader = [`${getCommercialName(tariff)}`, splitter, `${msisdn}`].concat()

  const superStatus = getSuperStatus(subscription, tariffId, multisimType)

  return (
    <Card
      className={`subscription-card ${className}`}
      title={titleHeader}
      toggable
      collapsed={false}
      preHeader={
        <BadgeStatusDetail
          superStatus={superStatus}
          status={{ code: undefined, description: 'Activa' }}
          subStatus={{ code: undefined, description: '' }}
          className="header"
        />
      }
      footer={<SubscriptionFooter subscription={subscription} />}
    />
  )
}

SubscriptionCardLoading.propTypes = {
  className: PropTypes.string,
  subscription: PropTypes.object,
}

const SubscriptionCardEnhanced = ({ subscription, ...rest }) => {
  useFetchOrders(subscription)

  const { status, fixedStatus, mobileStatus } = useSubscriptionStatus(subscription)
  const { isPortOutLoading, mobilePortOut, fixedPortOut } = usePortOutInfo(subscription)

  const [isRotated, setIsRotated] = useState(false)
  const onRotate = () => setIsRotated(!isRotated)

  const { changedTariff, allowUndoChanges } = useChangedTariff(subscription)

  if (isPortOutLoading) {
    return <SubscriptionCardLoading subscription={subscription} className={rest.className} />
  }

  const props = {
    ...rest,
    status,
    fixedStatus,
    mobileStatus,
    mobilePortOut,
    fixedPortOut,
    isRotated,
    onRotate,
    subscription,
    changedTariff,
    allowUndoChanges,
  }

  return <SubscriptionCardContainer {...props} />
}
SubscriptionCardEnhanced.propTypes = {
  subscription: PropTypes.object,
}

const StyledDiv = styled.div`
  text-align: center;
`

const changesPendingModifications = modifications => {
  const pendingChanges = pickBy(modifications, modification => {
    const modifiedPermanencies = get(modification, 'mobile') || get(modification, 'fixed')

    return (
      !isEmpty(get(modification, 'confirmedDiscounts')) ||
      !isEmpty(get(modification, 'confirmedBonuses')) ||
      !isNil(get(modification, 'confirmedTariff')) ||
      get(modifiedPermanencies, 'permanenceRemoved') ||
      get(modifiedPermanencies, 'penaltyCondoned')
    )
  })

  return size(pendingChanges) > 0
}
function NewOffersConfigurationContainerBase({
  className,
  subscriptions,
  isCVMInLoading,
  offerDetail,
  subsActiveFiltered,
  ordersFiltered,
  subsAndOrdersFiltered
}) {
  const { modalProps, onModalOpen, onTitleChange, onModalClose } = useDynamicModals()

  const [subsAndOrderPage, setSubsAndOrderPage] = useState([])
  const [page, setPage] = useState(1)
  const [currentPage, setCurrentPage] = useState(1)
  const {handlePage, showPagination, begin, end, maxPage} = usePagination(subsAndOrdersFiltered, currentPage, setPage, setCurrentPage)
 
  useEffect(() => {
    setSubsAndOrderPage(subsAndOrdersFiltered.slice(begin, end))
  }, [page])

  const isStcV3Flag = useSelector(selectStcV3Flag)
  const isEnabledButtonInsurancePpi = useSelector(selectEnabledButtonInsurancePpi)
  const modifications = useSelector(getSubscriptionsState)
  const sfid = useSelector(selectSfid)
  const orders = useSelector(getOrder)
  const ipAddress = useSelector(selectIpAddress)

  const errorCVM = useSelector(getCVMError)

  const { requestIpAddress } = useRequestIpAddress()

  const [optionsV3, setOptionsV3] = useState({})
  const [migrationOrStc, setMigrationOrStc] = useState({})
  const { orderChannel } = useOrderChannelAndStatus()

  const energyIsAllowed = useSelector(selectEnergyIsAllowed)

  const { op, subscription_id } = useSelector(selectQueryParams)

  const customerInfoError = useSelector(selectCustomerInfoError)
  const billingId = useSelector(selectBillingId)
  const accountId = useSelector(selectAccountId)
  const docNumber = useSelector(getIdentificationId)
  const docType = useSelector(getIdentificationType)

  const billingAddress = useSelector(customer360SvcSelectors.getBillingAddress)
  const postCode = get(billingAddress, 'postCode')

  const customerSegment = useSelector(getSegment)
  const taxNeeded = !isProCustomer(customerSegment)
  const taxRate = (taxNeeded && useSelector(state => getTax(state, postCode))) || 0

  const [updatedCVM, setUpdatedCVM] = useState(false)

  const { fetchCustomerIds, customerIdsIsLoading, customerIdsError } = useFetchCustomerIds()

  const hasIds = useMemo(() => !!(billingId && accountId), [billingId, accountId])
  const contracts = useSelector(state => selectContracts(state))

  const getCurrentSubscription = modificationsId =>
    modificationsId.reduce((acc, modificationId) => {
      const sub = subscriptions.find(subscription => subscription.id === modificationId)
      const category = get(sub, 'category')
      const paymentType = get(sub, 'is_postpaid') ? 'postpaid' : 'prepaid'

      const subscriptionId = get(sub, 'subscription_id')

      acc[modificationId] = { category, subscriptionId, paymentType }

      return acc
    }, {})

  const getNewSubscription = modificationsId =>
    modificationsId.reduce((acc, modificationId) => {
      const confirmedTariff = get(modifications[modificationId], 'confirmedTariff')
      const paymentType = get(confirmedTariff, 'payment_type')
      const category = get(confirmedTariff, 'tariffType')

      acc[modificationId] = { category, paymentType }

      return acc
    }, {})

  const getMigrations = (modificationsId, currentSubscription, newSubscription) =>
    modificationsId.reduce((acc, modificationId) => {
      const modificationCurrentSubscription = currentSubscription[modificationId]
      const modificationNewSubscription = newSubscription[modificationId]

      acc[modificationId] =
        modificationCurrentSubscription.category === CATEGORY_MOBILE &&
        modificationNewSubscription.category === CATEGORY_MOBILE_V3 &&
        modificationCurrentSubscription.paymentType !== modificationNewSubscription.paymentType
          ? 'plan-migration'
          : 'plan-change'

      return acc
    }, {})

  const getMigrationOrStc = (modificationsId, currentSubscription, newSubscription) =>
    modificationsId.reduce((acc, modificationId) => {
      const modificationCurrentSubscription = currentSubscription[modificationId]
      const modificationNewSubscription = newSubscription[modificationId]

      acc[modificationId] = getTypeMigration(
        modificationCurrentSubscription.category,
        modificationNewSubscription.category,
      )

      return acc
    }, {})

  const getStcData = (v3 = false) => {
    const modificationsId = Object.keys(modifications)

    const currentSubscription = getCurrentSubscription(modificationsId)
    const newSubscription = getNewSubscription(modificationsId)

    if (v3) {
      const migrations = getMigrations(modificationsId, currentSubscription, newSubscription)
      return { modificationsId, currentSubscription, newSubscription, migrations }
    }

    const isMigrationOrStc = getMigrationOrStc(
      modificationsId,
      currentSubscription,
      newSubscription,
    )
    return isMigrationOrStc
  }

  const buildOptionsV3 = () => {
    const { modificationsId, currentSubscription, newSubscription, migrations } = getStcData(true)

    return modificationsId.reduce((acc, modification) => {
      const confirmedTariff = get(modifications[modification], 'confirmedTariff')
      const subscriptionId = get(currentSubscription[modification], 'subscriptionId')
      const territoryOwner =
        get(confirmedTariff, 'broadband_type') === ADSL
          ? TERRITORY_OWNER_ADSL
          : getCurrentTerritoryOwner(orders, subscriptionId)

      acc[modification] = {
        newTariffId: get(confirmedTariff, 'psId'),
        channel: orderChannel,
        sfid,
        ipAddress,
        productOfferingId: get(confirmedTariff, 'poId'),
        targetAgreementId: subscriptionId,
        salesType: migrations[modification],
        newPaymentType:
          migrations[modification] === 'plan-migration'
            ? get(newSubscription[modification], 'paymentType')
            : '',
        accountId,
        territoryOwner,
        bypass: get(confirmedTariff, 'status') === 'retired',
        speed: get(confirmedTariff, 'internetDownloadSpeed'),
        isNotMobile: get(currentSubscription[modification], 'category') !== CATEGORY_MOBILE,
      }

      return acc
    }, {})
  }

  useEffect(() => {
    if (docNumber && docType) {
      fetchCustomerIds(docType, docNumber)
    }
  }, [docType, docNumber])

  useEffect(() => {
    if (isCVMInLoading) {
      setUpdatedCVM(true)
    }
  }, [isCVMInLoading])

  useEffect(() => {
    requestIpAddress()
  }, [])

  useEffect(() => {
    const isMigrationOrStc = getStcData()
    setMigrationOrStc(isMigrationOrStc)

    if (isStcV3Flag) {
      setOptionsV3(buildOptionsV3())
    }
  }, [modifications])

  const subscriptionComponent = subscription => {
    const msidn = subscription.id
    const tariffId = getSubscriptionTariffId(subscription)
    const product = helpers.getProductByLineId(contracts, msidn)
    const multisimType = get(product, 'multisimType')

    const superStatus = getSuperStatus(subscription, tariffId, multisimType)

    return (
      <SubscriptionCardEnhanced
        key={subscription.id}
        operationToOpen={
          !updatedCVM && subscription.id && subscription.id === subscription_id
            ? op
            : undefined
        }
        preHeader={
          <BadgeStatusDetail
            superStatus={superStatus}
            status={{ code: undefined, description: 'Activa' }}
            subStatus={{ code: undefined, description: '' }}
            className="header"
          />
        }
        footer={<SubscriptionFooter subscription={subscription} />}
        subscription={subscription}
        hasIds={hasIds}
        taxRate={taxRate}
        onModalOpen={onModalOpen}
        onModalClose={onModalClose}
        onTitleChange={onTitleChange}
        withShadow
      />
    )
  }

  return (
    <div className={className}>
      <div className="cards">
        {customerInfoError && (
          <Box p="10px 0 15px">
            <ActionAdvice
              type="error"
              message="Error recuperando los identificadores del cliente"
              onRetry={() => fetchCustomerIds(docType, docNumber)}
            />
          </Box>
        )}

        <DynamicModal {...modalProps} />
        {
          showPagination ? 
            <>
              {subsAndOrderPage.map(subscription => {
              return get(subscription, 'superStatus') === 'fly' ? 
                <OrderCard
                  key={subscription.id}
                  order={subscription}
                  className={className}
                  offerDetail={offerDetail}
                  hasIds={hasIds}
                  taxRate={taxRate}
                />
              :
              subscriptionComponent(subscription)
            })}
            </>
          
          :
            <>
              {subsActiveFiltered.map(subscription => subscriptionComponent(subscription))}
              {ordersFiltered.map(order => (
                <OrderCard
                  key={order.id}
                  order={order}
                  className={className}
                  offerDetail={offerDetail}
                  hasIds={hasIds}
                  taxRate={taxRate}
                />
              ))}
            </>
        }
       
  
        {
          showPagination && 
          <Box display="flex" justifyContent="center" width="100%" mb="15px">
            <Pagination count={maxPage} size="small" page={page} onChange={handlePage} />
          </Box>
        
        }
            
        <SaleWithClientCardButton
          hasCustomerIdsLoaded={!customerIdsIsLoading && !customerIdsError}
        />

        {energyIsAllowed && <SaleEnergyWithClientCardButton />}

        <SaleInsuranceWithClientCardButton />

        {isEnabledButtonInsurancePpi && <SaleInsurancePpiWithClientCardButton />}
      </div>
      {!isEmpty(modifications) && changesPendingModifications(modifications) && !errorCVM && (
        <ModificationActionsItem
          modifications={modifications}
          optionsV3={optionsV3}
          migrationOrStc={migrationOrStc}
          onModalOpen={onModalOpen}
          onModalClose={onModalClose}
        />
      )}
    </div>
  )
}

NewOffersConfigurationContainerBase.propTypes = {
  className: PropTypes.string,
  offerDetail: PropTypes.arrayOf(PropTypes.object),
  subscriptions: PropTypes.arrayOf(PropTypes.object),
  isCVMInLoading: PropTypes.bool,
  subsActiveFiltered: PropTypes.arrayOf(PropTypes.object),
  ordersFiltered: PropTypes.arrayOf(PropTypes.object),
  subsAndOrdersFiltered: PropTypes.arrayOf(PropTypes.object),
}

export const NewOffersConfigurationContainer = styled(({ error, ...rest }) => {
  const dispatch = useDispatch()

  const [actionDone, setActionDone] = useState(false)

  const { isLoading } = useSagaLoad(
    offersConfigurationInitSaga.selectors.isLoading,
    offersConfigurationInitSaga.actions.loadInit,
  )

  const isTypificationOpened = useSelector(isTypificationOpen)
  const subscriptions = useSelector(getSubscriptions)
  const isCVMInLoading = useSelector(isCVMLoading)
  const errorCVM = useSelector(getCVMError)
  const offerDetail = useSelector(offersDetailSvc.selectors.getOrdersByClient)

  if (isLoading) {
    return <SpinnerCenter showMsg style={{ marginTop: '50px' }} />
  }

  if (error) {
    return (
      <>
        {!actionDone && (
          <NotificationModal
            type="alert"
            src="/assets/error.svg"
            title="Se ha encontrado un error al hacer la carga de datos."
            isOpen
            onClose={() => {
              setActionDone(true)
            }}>
            <Typography {...TextStyles.title2Dark()}>{error.info}</Typography>
          </NotificationModal>
        )}
        <Box display="flex" justifyContent="center" width="100%" mt="16px">
          <AlertAdvice message="Se ha encontrado un error al hacer la carga de datos" />
        </Box>
      </>
    )
  }

  if (isCVMInLoading) {
    return (
      <StyledDiv>
        <SpinnerCenter showMsg style={{ marginTop: '10%' }} />
      </StyledDiv>
    )
  }

  if (errorCVM) {
    return (
      <NotificationModal
        type="alert"
        src="/assets/error.svg"
        title="Se ha encontrado un error al actualizar las suscripciones del cliente."
        isOpen
        onClose={() => dispatch(clearError())}>
        {errorCVM.map(message => (
          <Typography {...TextStyles.title2Dark()}>{message}</Typography>
        ))}
      </NotificationModal>
    )
  }

  const props = {
    ...rest,
    isTypificationOpen: isTypificationOpened,
    clearError: () => dispatch(clearError()),
    subscriptions,
    offerDetail,
  }

  return <NewOffersConfigurationContainerBase {...props} />
})`
  display: flex;
  flex-direction: column;
  padding: 0px;

  > .cards {
    display: flex;
    flex-direction: column;
    position: relative;

    margin: 0px;
  }

  & .subscription-card:first-child {
    margin-top: 0px;
  }
  & .subscription-card,
  & .order-onfly-card {
    margin: 14px 0px 14px 0px;
  }

  > .modification-actions-item {
    margin: 14px 0px 20px 0px;
  }
  .card-footer {
    padding: 24px;
    border-top: 2px solid
      ${props => getLighten(get(props, 'theme.palette.global.gray_medium', '#898b8c'), 0.8)};
  }
  .card-footer ul {
    display: flex;
  }
  .card-footer ul li:first-child {
    padding-left: 0;
  }
  .card-footer ul li {
    line-height: 24px;
    padding: 0 16px;
    border-right: solid ${props => get(props, 'theme.palette.global.gray_light')} 2px;
    font-weight: 700;
    color: ${props => getLighten(get(props, 'theme.palette.global.gray_dark'), 0.2)};
  }
  .card-footer ul li:last-child {
    border-right: none;
    padding-right: 0;
  }
  .primary-badge {
    border: 0;
    border-radius: unset;
  }
  .second-badge {
    border: 0;
    border-radius: unset;
  }
  .card-link {
    text-transform: uppercase;
    text-align: left;
    padding: 12px 24px;
  }
`
