import PropTypes from 'prop-types'
import { CardWrapper } from 'components/ui'
import { useSelector } from 'react-redux'

import { Lead } from '../LeadComponent'
import { MainMobileContainer } from '../../../containers/OfferContainer/MainMobileContainer'
import { selectMainMobileTariff } from '../../../store'

export function OnlyMobileSale({ isCartera }) {
  const selectedTariff = useSelector(selectMainMobileTariff)

  return (
    <>
      <CardWrapper padding="0px" width="100%" overflowy="inherit">
        <MainMobileContainer selectedTariff={selectedTariff} isStockCheck />
      </CardWrapper>
      <CardWrapper width="100%">
        <Lead isCartera={!!isCartera} />
      </CardWrapper>
    </>
  )
}

OnlyMobileSale.propTypes = {
  isCartera: PropTypes.bool,
}
