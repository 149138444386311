import { createStructuredSelector, createSelector } from 'reselect'
import { get } from 'lodash'

import { SINGLE } from 'services/global-constants'
import { YOIGO_STORE } from 'modules/SharedSales/constants/shippingAddress'

import {
  selectRenewalDeviceInfo,
  selectRenewalDeviceShipping,
} from '../../store/device-renewal.selectors'

const selectPaymentInfo = createSelector(
  [selectRenewalDeviceInfo],
  deviceInfo => {
    const paymentType = Object.keys(deviceInfo.paymentMethods)[0]
    const conceptType = paymentType === SINGLE ? 'Pago único' : 'Pago inicial'

    const amount = get(
      deviceInfo,
      ['paymentMethods', paymentType, 'initialPayment', 'taxIncludedAmount'],
      0,
    )

    return {
      amount,
      concept: `${conceptType} del ${deviceInfo.name}`,
    }
  },
)

const selectIsYoigoStoreShipping = createSelector(
  selectRenewalDeviceShipping,
  ({ type }) => type === YOIGO_STORE.value,
)

export const billingContainerSelectors = createStructuredSelector({
  paymentInfo: selectPaymentInfo,
  isYoigoStoreShipping: selectIsYoigoStoreShipping,
})
