import PropTypes from 'prop-types'
import { BackButton } from '../Common/BackButton'
import SaveChangesButton from '../Common/SaveChangesButton'
import { BillingData } from './BillingData'
import { InstallationModal } from './InstallationModal'
import Shipping from './Shipping'

function Billing({
  ibanError,
  ibanLoading,
  setBasketData,
  onEditAddress,
  onContinue,
  isLoadingInstallations,
  selectedTechnology,
  fiberInstaller,
  className,
  isEditing,
  isMobileOnly,
  isMultiSim,
  onSelectAddress,
  isElFijo,
  isPos,
  is2P,
  isSubscription,
  isInternalPortabilityWithoutInstallation,
}) {
  if (!isMobileOnly && !isElFijo && isLoadingInstallations) {
    return (
      <div className={className}>
        <div className="loading">Cargando...</div>
      </div>
    )
  }

  const billingComponent = (
    <BillingData
      ibanLoading={ibanLoading}
      onSelectAddress={onSelectAddress}
      onEditAddress={onEditAddress}
    />
  )

  const shippingComponent = !isPos && !isMultiSim && (
    <Shipping
      onSelectAddress={onSelectAddress}
      onEditAddress={onEditAddress}
      selectedTechnology={selectedTechnology}
    />
  )

  return (
    <div className={className} data-hook="billing-page">
      <div className="m-b-32">
        {isMobileOnly || isElFijo || isMultiSim || !!isSubscription
          ? billingComponent
          : shippingComponent}
      </div>
      <div className="m-b-32">
        {isMobileOnly || isElFijo || isMultiSim || !!isSubscription
          ? shippingComponent
          : billingComponent}
      </div>
      <BackButton
        goTo={isMobileOnly || !!isSubscription || !!isMultiSim ? 'info' : 'offers'}
        nomargin
      />
      <SaveChangesButton
        ibanError={ibanError}
        stepName="BillingForm"
        isLoading={ibanLoading}
        setBasketData={setBasketData}
        onContinue={onContinue}
        isEditing={isEditing}
      />
      {(!!fiberInstaller || isInternalPortabilityWithoutInstallation) && (
        <InstallationModal fiberInstaller={fiberInstaller} isPos={isPos} is2P={is2P} />
      )}
    </div>
  )
}

Billing.propTypes = {
  ibanError: PropTypes.string,
  ibanLoading: PropTypes.bool,
  setBasketData: PropTypes.func.isRequired,
  onEditAddress: PropTypes.func.isRequired,
  onSelectAddress: PropTypes.func.isRequired,
  isLoadingInstallations: PropTypes.bool,
  fiberInstaller: PropTypes.string,
  className: PropTypes.string,
  isMobileOnly: PropTypes.bool,
  selectedTechnology: PropTypes.string.isRequired,
  onContinue: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
  isElFijo: PropTypes.bool,
  isMultiSim: PropTypes.bool,
  isPos: PropTypes.bool,
  is2P: PropTypes.bool,
  isSubscription: PropTypes.bool,
  isInternalPortabilityWithoutInstallation: PropTypes.bool,
}

export default Billing
