import { useFormikContext } from 'formik'
import { isEmpty } from 'lodash'

import { flattenObj } from 'utils/formatting/index'
import { useAnalytics } from 'modules/Analytics/hooks/index'

export const useValidateErrors = stepName => {
  const { status, validateForm, setTouched, values } = useFormikContext()
  const analytics = useAnalytics()

  function checkStatusErrors() {
    const errors = Object.values(status).filter(elem => !!elem)
    return errors.length > 0
  }

  async function validateErrors(shouldShowError) {
    const errors = await validateForm()

    const flatErrors = flattenObj(errors)

    const axErrors = Object.keys(flatErrors).map(key => ({
      name: key,
      formName: stepName,
      value: flatErrors[key],
    }))

    if (!isEmpty(errors)) {
      analytics.sendFormErrors({
        errors: axErrors,
      })
    }
    setTouched(errors)

    return !isEmpty(errors) || shouldShowError || checkStatusErrors() ? errors : null
  }

  return {
    validateErrors,
    formikValues: values,
  }
}
