export const REGISTER_OFFER_ATTEMPTED = 'OVID/ACTIVITY_REGISTER/REGISTER_OFFER_ATTEMPT'
export const REGISTER_OFFER_SUCCESS = 'OVID/ACTIVITY_REGISTER/REGISTER_OFFER_SUCCESS'
export const REGISTER_OFFER_ERROR = 'OVID/ACTIVITY_REGISTER/REGISTER_OFFER_ERROR'

export const registerOfferAction = (msisdn, offer) => ({
  type: REGISTER_OFFER_ATTEMPTED,
  payload: { msisdn, ...offer },
})

export const registerOfferSuccessAction = msisdn => ({
  type: REGISTER_OFFER_SUCCESS,
  payload: { msisdn },
})

export const registerOfferErrorAction = (msisdn, error) => ({
  type: REGISTER_OFFER_ERROR,
  payload: { msisdn, error },
})
