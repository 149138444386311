import * as Yup from 'yup/lib'
import * as validations from 'modules/SharedSales/constants/validations'
import { mobileOrLanlineNumberRegex } from 'utils'

export const ContactSchema = {
  account: Yup.object().shape({
    phoneContact2: Yup.string()
      .trim()
      .matches(mobileOrLanlineNumberRegex, validations.ERROR_DATA_TEXT)
      .required(validations.REQUIRED_FIELD_TEXT),
    email: Yup.string()
      .trim()
      .email(validations.INVALID_EMAIL)
      .required(validations.REQUIRED_FIELD_TEXT),
    language: Yup.string().when(['$isCartera', 'accountId'], {
      is: (isCartera, accountId) => isCartera && !!accountId,
      then: Yup.string(),
      otherwise: Yup.string()
        .trim()
        .required(validations.REQUIRED_FIELD_TEXT),
    }),
  }),
}
