import { get } from 'lodash'
import { createSelector } from 'reselect'

export const selectBarringsStatus = state => get(state, 'barringsStatus')

export const selectBarringsStatusData = productId =>
  createSelector(
    selectBarringsStatus,
    barrringsStatus => get(barrringsStatus, `data.${productId}`),
  )

export const selectBarringsByProductId = productId =>
  createSelector(
    selectBarringsStatusData(productId),
    barringsStatus => get(barringsStatus, 'barrings'),
  )

export const selectIsLoadingBarringsStatus = createSelector(
  selectBarringsStatus,
  barrringsStatus => get(barrringsStatus, 'loading'),
)

export const selectBarringsStatusErrorMessage = createSelector(
  selectBarringsStatus,
  barrringsStatus => get(barrringsStatus, 'error'),
)

export const selectIsLoadingSetBarringStatus = productId =>
  createSelector(
    selectBarringsStatusData(productId),
    barringStatus => get(barringStatus, 'loading'),
  )

export const selectErrorMessageSetBarringStatus = productId =>
  createSelector(
    selectBarringsStatusData(productId),
    barringStatus => get(barringStatus, 'error'),
  )

export const selectSingleBarringData = (productId, barringId) =>
  createSelector(
    selectBarringsByProductId(productId),
    barrings => get(barrings, barringId),
  )

export const selectBarringIsOnGoing = (productId, barringId) =>
  createSelector(
    selectSingleBarringData(productId, barringId),
    barringStatus => get(barringStatus, 'isOnGoing'),
  )
