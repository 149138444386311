import { useSelector } from 'react-redux'
import { get } from 'lodash'

import { getCommercialName, getTariffId } from 'modules/tariffs/store-apigee/tariffs-apigee.helpers'
import { selectProfileId, profiles } from 'modules/Permissions'
import { hasTariffChangeEvents } from 'services/subscriptions/helpers'
import { selectStcV3Flag, selectStcV3Phase2Flag } from 'services/feature-flag/selectors'
import { formatBasicTariff } from 'modules/tariffs/helpers/index'
import { getTariffOptionsForSubscription } from '../selectors'
import { getMonthlyPricesTaxFree } from '../helpers'

export const useTariffOptions = (
  subscription,
  newPaymentType,
  isFixedReduction,
  isUnsusbcribe,
  hideCurrentTariff = true,
) => {
  const showV3Phase2Tariffs = useSelector(selectStcV3Phase2Flag) && !isUnsusbcribe
  const showV3Tariffs = useSelector(selectStcV3Flag) && !isUnsusbcribe
  const hasStc = hasTariffChangeEvents(subscription)
  const agentProfile = useSelector(selectProfileId)
  const isLoyaltyAgent = agentProfile === profiles.customer_loyalty.id
  const isRetentionAgent = agentProfile === profiles.retention.id
  const isProAgent = agentProfile === profiles.pro.id

  const filteredTariffs = useSelector(state =>
    getTariffOptionsForSubscription(
      state,
      isLoyaltyAgent,
      isRetentionAgent,
      isProAgent,
      subscription,
      hasStc,
      showV3Tariffs,
      showV3Phase2Tariffs,
      hideCurrentTariff,
      {
        paymentType: newPaymentType,
        forceMobile: isFixedReduction,
      },
    ),
  )

  const filteredEnrichTariffs = filteredTariffs.map(tariff => {
    // tax equal 1 because on SelectTariff calculate correct TAX
    const { priceWithTax, fees, discounts } = formatBasicTariff(tariff, 1)

    const allPricesTaxFree = getMonthlyPricesTaxFree(tariff)

    // if no discounts, get prices from object prices to prevent penny out of square
    const totalPriceFreeTax =
      discounts?.length > 0 ? get(fees, '[0].amount') || priceWithTax : allPricesTaxFree

    return {
      ...tariff,
      id: getTariffId(tariff, showV3Tariffs),
      commercial_name: getCommercialName(tariff, showV3Tariffs),
      totalPriceFreeTax,
      calculatedDiscounts: {
        fees,
        discounts,
      },
    }
  })

  return {
    filteredEnrichTariffs,
  }
}
