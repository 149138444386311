import { useSelector } from 'react-redux'
import { useState } from 'react'
import { fetchWelcomeLetter } from '../../services/welcomeLetter.service'

import { selectWelcomeLetterData } from '../../store/selectors/NewClientSales.selectors'

export function useDownloadWelcomeLetter() {
  const [error, setError] = useState(false)

  const {
    taxZipCode,
    tariffName,
    tariffType,
    segment,
    price,
    fixedLineNumber,
    mobileNumber,
    clientName,
    paymentType,
  } = useSelector(selectWelcomeLetterData)

  function downloadWelcomeLetter() {
    setError(false)
    fetchWelcomeLetter(
      tariffName,
      taxZipCode,
      tariffType,
      segment,
      price,
      fixedLineNumber,
      mobileNumber,
      clientName,
      paymentType,
    )
      .then(() => setError(false))
      .catch(() => setError(true))
  }

  return { downloadWelcomeLetter, welcomeError: error }
}
