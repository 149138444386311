const colors = {
  gray_0: '#4d4e4f',
  blue: '#3a8ff0',
  light_2: '#656f7a',
}

const createTextStyle = properties => {
  return ({ className, style, ...rest } = {}) => ({
    ...properties,
    ...rest,
    className: [
      ...(className ? [className] : []),
      ...(properties.className ? [properties.className] : []),
    ].join(' '),
    style: {
      ...(properties.style || {}),
      ...(style || {}),
    },
  })
}

export const TextStyles = {
  header: createTextStyle({
    variant: 'h5',
    color: 'textSecondary',
  }),
  action: createTextStyle({
    variant: 'caption',
    color: 'textSecondary',
    className: 'bold',
  }),
  title1Dark: createTextStyle({
    variant: 'h4',
    color: 'textSecondary',
  }),
  title1BoldDark: createTextStyle({
    variant: 'h4',
    color: 'textSecondary',
    className: 'bold',
  }),
  title1Light: createTextStyle({
    variant: 'h4',
    className: 'light',
  }),
  title2Dark: createTextStyle({
    variant: 'h5',
    color: 'textSecondary',
  }),
  title2MediumDark: createTextStyle({
    variant: 'h6',
    color: 'textSecondary',
    className: 'regular',
  }),
  title2MediumBoldDark: createTextStyle({
    variant: 'h6',
    color: 'textSecondary',
    className: 'bold',
  }),
  title3Dark: createTextStyle({
    variant: 'h5',
    color: 'textSecondary',
    className: 'bold',
  }),
  subtitle1Secondary: createTextStyle({
    variant: 'body1',
    color: 'secondary',
    className: 'bold',
    style: { textTransform: 'uppercase' },
  }),
  subtitle1Dark: createTextStyle({
    variant: 'body1',
    className: 'bold',
    style: { textTransform: 'uppercase' },
  }),
  subtitle1Link: createTextStyle({
    variant: 'body1',
    className: 'bold',
    style: { textTransform: 'uppercase' },
    color: 'primary',
  }),
  subtitle2Dark: createTextStyle({
    variant: 'body1',
    className: 'bold',
  }),
  subtitle2Link: createTextStyle({
    variant: 'body1',
    color: 'primary',
    className: 'bold',
  }),
  labelDark: createTextStyle({
    variant: 'subtitle2',
    color: 'textSecondary',
  }),
  dataDark: createTextStyle({
    variant: 'body2',
  }),
  dataLight: createTextStyle({
    variant: 'body1',
    className: 'light regular',
  }),
  paragraphDark: createTextStyle({
    variant: 'body1',
  }),
  paragraphLink: createTextStyle({
    variant: 'body1',
    color: 'primary',
  }),
  body: createTextStyle({
    variant: 'subtitle2',
    color: 'textSecondary',
    component: 'p',
  }),
  labelLink: createTextStyle({
    variant: 'subtitle2',
    color: 'primary',
    className: 'regular',
    style: { textDecoration: 'underline' },
  }),
  link: createTextStyle({
    variant: 'body1',
    color: 'primary',
    style: {
      letterSpacing: '0.4px',
    },
  }),
  labelAlert: createTextStyle({
    variant: 'subtitle2',
    color: 'error',
    component: 'p',
  }),
  labelLight: createTextStyle({
    variant: 'subtitle2',
    className: 'light',
    style: {
      lineHeight: '1.7',
    },
  }),
  labelLight2: createTextStyle({
    variant: 'subtitle2',
    style: {
      color: colors.light_2,
    },
  }),
  subM: createTextStyle({
    variant: 'caption',
    color: 'textSecondary',
    className: 'regular',
  }),
  tagM: createTextStyle({
    variant: 'caption',
    className: 'bold light',
    style: {
      textAlign: 'center',
      textTransform: 'uppercase',
    },
  }),
  tagXs: createTextStyle({
    variant: 'caption',
    className: 'bold light',
    style: {
      fontSize: '10px',
      letterSpacing: '0.4px',
      textAlign: 'center',
      textTransform: 'uppercase',
    },
  }),
}
