import { APP_CONFIG } from 'services/app-config'
import { addQueryParamToAbsolute } from 'modules/url'

const AUTH_SCHEMA_PARAM = 'auth_schema'
const AUTH_SCHEMA_SFID = 'sfid-yoigo'

const CREATE_SALE_PATH = 'signups/new'
const CREATE_SALE_DOCUMENT_ID_PARAM = 'fiscalid'
const GO_CRM_SALE_PATH = 'search'

export const addSfidParam = url => {
  return decodeURIComponent(addQueryParamToAbsolute(url, AUTH_SCHEMA_PARAM, AUTH_SCHEMA_SFID))
}

export const createEnergySale = documentId => {
  let url = `${APP_CONFIG.energy}/${CREATE_SALE_PATH}`

  if (documentId) {
    url = addQueryParamToAbsolute(url, CREATE_SALE_DOCUMENT_ID_PARAM, documentId)
  }

  return url
}

export const goEnergyCRM = documentId => {
  let url = `${APP_CONFIG.energy}`

  if (documentId) {
    url = addQueryParamToAbsolute(`${url}/${GO_CRM_SALE_PATH}`, 'q', documentId)
  }

  return url
}
