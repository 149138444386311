import styled from 'styled-components'

const SectionTitle = styled.h1`
  height: 38px;
  width: ${props => props.width || '100%'};
  text-align: left;
  font-size: 32px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${props => props.color || '#555555'}
  margin: ${props => props.margin || null};
`

export default SectionTitle
