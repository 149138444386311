import PropTypes from 'prop-types'

import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import { TextStyles } from 'utils/text'

const useStyles = makeStyles(theme => ({
  line: {
    paddingBottom: '30px',
    position: 'relative',

    '&:before': {
      content: 'close-quote',
      display: ({ isLastStep }) => (isLastStep ? 'none' : 'block'),
      position: 'absolute',
      backgroundColor: theme.palette.global.gray_very_light,
      width: '3px',
      height: '100%',
      top: 0,
      left: '-30px',
      zIndex: 1,
    },

    color: theme.palette.global.gray_dark,
  },
  'step-point': {
    position: 'relative',

    '&:before': {
      content: 'close-quote',
      height: '14px',
      width: '14px',
      backgroundColor: ({ isActive }) =>
        isActive ? theme.palette.primary.main : theme.palette.global.gray_very_light,
      position: 'absolute',
      borderRadius: '50%',
      left: '-37px',
      zIndex: 2,
      border: '1px solid white',
    },
  },
  title: {
    lineHeight: 1,
    marginBottom: '8px',
  },
  description: {
    lineHeight: '24px',
  },
}))

export const Step = ({ title, description, isActive, isLastStep, ...rest }) => {
  const classes = useStyles({ isLastStep, isActive })

  return (
    <div className={classes.line} data-hook={rest['data-hook']}>
      <div className={classes['step-point']}>
        <Typography
          {...TextStyles.dataDark({
            className: classes.title,
          })}>
          {title}
        </Typography>
        <Typography
          {...TextStyles.labelDark({
            className: classes.description,
            component: 'div',
          })}>
          {description}
        </Typography>
      </div>
    </div>
  )
}

Step.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  isActive: PropTypes.bool,
  isLastStep: PropTypes.bool,
}
