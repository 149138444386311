import { useEffect } from 'react'
import { PropTypes } from 'prop-types'
import { get } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectIsLoading,
  selectError,
  applyCreditNoteAction,
  creditNoteClearErrorAction,
} from 'modules/credit'
import { actions as taxes, getTax } from 'services/taxes'
import { selectors as customerSelectors } from 'services/customer-360'
import { CreditModal } from '../../components/CreditModal/CreditModal'

export function CreditModalContainer({ invoice, isOpen, onCloseModal }) {
  const dispatch = useDispatch()

  const billingAddress = useSelector(state => customerSelectors.getBillingAddress(state))
  const postCode = get(billingAddress, 'postCode')

  useEffect(() => {
    dispatch(taxes.findTax(postCode))
  }, [postCode])

  const props = {
    invoice,
    isOpen,
    onCloseModal,
    isCreditLoading: useSelector(selectIsLoading),
    creditErrors: useSelector(selectError),
    applyCreditNote: creditNoteData => dispatch(applyCreditNoteAction(creditNoteData)),
    creditClearError: () => dispatch(creditNoteClearErrorAction()),
    tax: useSelector(state => getTax(state, postCode)),
  }
  return <CreditModal {...props} />
}

CreditModalContainer.propTypes = {
  invoice: PropTypes.object,
  isOpen: PropTypes.bool,
  onCloseModal: PropTypes.func,
}
