import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  selectIsCompany,
  makeBasketTerminalDetails,
  fetchTerminalDetailsSuccessAction,
  fetchTerminalDetailsAction,
} from '../../store'
import { selectProfessionOptions } from '../../containers/OfferContainer/TerminalSelectionContainer/TerminalSelectionContainer.selectors'

export function useTerminalSelectionStore(lineType, paymentType) {
  const dispatch = useDispatch()
  // TODO: Fuse this selectors with structured one
  const selectTerminalDetails = makeBasketTerminalDetails()
  const terminalDetails = useSelector(state => selectTerminalDetails(state, lineType, paymentType))
  const professionOptions = useSelector(selectProfessionOptions)
  const isCompany = useSelector(selectIsCompany)

  const setTerminalInformation = useCallback(data => {
    dispatch(fetchTerminalDetailsSuccessAction(data))
  })
  const fetchTerminalDetails = useCallback(data => {
    dispatch(fetchTerminalDetailsAction(data))
  })

  return {
    terminalDetails,
    isCompany,
    professionOptions,
    setTerminalInformation,
    fetchTerminalDetails,
  }
}
