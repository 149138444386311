import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Typography } from '@material-ui/core'
import { TextStyles } from 'utils/text'

import { Grid, Modal } from 'components/ui'

export const ContractDownloadError = styled(({ className, isOpen, onClose }) => {
  return (
    <Modal type="alert" isOpen={isOpen} onClose={onClose}>
      <Grid className={className} container justify="space-between" alignItems="center">
        <Grid className="content-grid" item xs={12}>
          <img src="/assets/error.svg" alt="Ha ocurrido un error" />
        </Grid>
        <Grid className="content-grid" item xs={12}>
          <Grid className="content-grid" item xs={12}>
            <Typography {...TextStyles.title1Dark()}>
              Ha ocurrido un error al descargar el contrato
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  )
})`
  .content-grid {
    text-align: center;
    padding: 1em 0;
  }
`

ContractDownloadError.propTypes = {
  isOpen: PropTypes.bool,
}
