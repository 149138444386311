import { useMemo } from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/styles'
import { Box, Checkbox, Typography } from '@material-ui/core'

import {
  ADDITIONAL_LINE_CATEGORY_2P,
  ADDITIONAL_LINE_CATEGORY_EL_FIJO,
} from 'modules/orders/constants'
import { LineInformation } from '../../LineInformation'

const useStyles = makeStyles({
  'cancel-order-item': {
    '& > span': {
      marginLeft: '-12px',
      marginRight: '5px',
    },
  },
})

export const CancelOrdersList = ({
  orders,
  onOrderSelected,
  onOrderLineSelected,
  onAllOrdersSelected,
  areAllOrdersSelected,
  isOrderSelected,
  isOrderLineSelected,
  checkOrderPartiallySelected,
  allowPartialCancellation,
  allowSelectAll,
  isAllowedReplaceable,
  replaceableOrder,
  onSetReplaceableOrder,
  replaceableOrderOptions,
}) => {
  const classes = useStyles()

  function canPartiallyCancelOrder(order) {
    const { mainAdditionalLineCategory } = order

    return (
      order.lines.length > 1 &&
      mainAdditionalLineCategory !== ADDITIONAL_LINE_CATEGORY_EL_FIJO &&
      mainAdditionalLineCategory !== ADDITIONAL_LINE_CATEGORY_2P &&
      order.isClosed &&
      allowPartialCancellation
    )
  }

  const shouldShowCancelAllLinesCheckbox = useMemo(() => {
    return (
      allowSelectAll &&
      orders &&
      orders.length > 0 &&
      orders[0].mainAdditionalLineCategory !== ADDITIONAL_LINE_CATEGORY_EL_FIJO &&
      orders[0].mainAdditionalLineCategory !== ADDITIONAL_LINE_CATEGORY_2P
    )
  }, [orders])

  return (
    <>
      {shouldShowCancelAllLinesCheckbox && (
        <Box display="flex" alignItems="baseline" className={classes['cancel-order-item']}>
          <Checkbox
            data-hook="cancel-all-orders-checkbox"
            checked={areAllOrdersSelected}
            onChange={() => {
              onAllOrdersSelected()
            }}
            value=""
            disabled={orders.filter(order => order.isCancellable).length === 0}
          />
          <div style={{ position: 'relative', top: '-4px', marginBottom: '20px' }}>
            <Typography variant="h6">Cancelar TODAS las líneas</Typography>
          </div>
        </Box>
      )}

      {orders.map(order => (
        <Box
          display="flex"
          alignItems="baseline"
          className={classes['cancel-order-item']}
          key={order.id}>
          <Checkbox
            data-hook={`cancel-order-${order.id}-checkbox`}
            checked={isOrderSelected(order.id)}
            onChange={e => {
              onOrderSelected(order, e.target.checked)
            }}
            value={order}
            indeterminate={checkOrderPartiallySelected(order)}
            disabled={!order.isCancellable || replaceableOrder?.id === order.id}
          />
          <LineInformation
            order={order}
            onOrderLineSelected={onOrderLineSelected}
            isOrderLineSelected={isOrderLineSelected}
            shouldShowLinesCheckboxes={canPartiallyCancelOrder(order)}
            replaceable={
              order.main && isAllowedReplaceable
                ? {
                    value: replaceableOrder,
                    onSetReplaceableOrder,
                    options: replaceableOrderOptions,
                  }
                : null
            }
          />
        </Box>
      ))}
    </>
  )
}

CancelOrdersList.propTypes = {
  orders: PropTypes.arrayOf(PropTypes.object),
  onOrderSelected: PropTypes.func,
  onAllOrdersSelected: PropTypes.func,
  areAllOrdersSelected: PropTypes.bool,
  isOrderSelected: PropTypes.func,
  onOrderLineSelected: PropTypes.func,
  isOrderLineSelected: PropTypes.func,
  checkOrderPartiallySelected: PropTypes.func,
  allowPartialCancellation: PropTypes.bool,
  allowSelectAll: PropTypes.bool,
  isAllowedReplaceable: PropTypes.bool,
  replaceableOrder: PropTypes.object,
  onSetReplaceableOrder: PropTypes.func,
  replaceableOrderOptions: PropTypes.arrayOf(PropTypes.object),
}
