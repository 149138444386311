import { axiosJWT } from 'modules/axios'
import { CONFIG } from './environments'

export function findOffers(filters) {
  return axiosJWT({
    method: 'GET',
    url: CONFIG.api.offers,
    params: { ...filters, ignoreWhitelist: true },
  }).then(resp => resp.data)
}
