import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/styles'
import { ButtonBase, Icon } from '@material-ui/core'
import { ICONS } from 'utils/icons'

const useStyles = makeStyles(theme => ({
  'mini-button': {
    width: '24px',
    height: '24px',
    borderRadius: '50%',
    backgroundColor: theme.palette.brand.action_primary,
    color: theme.palette.brand.light,
    fontFamily: 'Material Icons',
    fontSize: '20px',
    textAlign: 'center',
    boxSizing: 'border-box',
    transition:
      'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',

    '&:hover': {
      backgroundColor: theme.palette.brand.medium,
    },

    '&:disabled': {
      backgroundColor: theme.palette.global.gray,
      color: theme.palette.brand.light,
      boxShadow: 'none',
    },
  },
}))

const IconButton = ({ icon, ...rest }) => {
  const classes = useStyles()

  return (
    <ButtonBase className={classes['mini-button']} {...rest}>
      <Icon style={{ fontSize: '20px' }}>{ICONS[icon]}</Icon>
    </ButtonBase>
  )
}

IconButton.defaultProps = {
  disabled: false,
}

IconButton.propTypes = {
  icon: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default IconButton
