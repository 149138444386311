import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { actions as discountsOrder } from 'services/discounts-order'
import { actions as discountsIncompatibilities } from 'services/discounts-incompatibilities'
import { actions as discountsAgentActions } from 'services/discounts-agent'
import { selectDiscountsContainerData } from './selectors'
import { Discounts } from '../../components'

export function DiscountsContainer() {
  const dispatch = useDispatch()
  const data = useSelector(selectDiscountsContainerData)
  useEffect(() => {
    dispatch(discountsOrder.getDiscountsInit(data.orderId))
    dispatch(discountsAgentActions.getDiscountsInit(data.orderId))
  }, [])

  const childProps = {
    ...data,
    getDiscountsIncompatibilitiesOrder: (newDiscountCode, currentDiscountsCode) =>
      dispatch(
        discountsIncompatibilities.getDiscountsIncompatibilitiesInit(
          newDiscountCode,
          currentDiscountsCode,
        ),
      ),
    selectDiscount: discount => dispatch(discountsOrder.selectDiscount(discount)),
    putDiscountsOder: orderId => dispatch(discountsOrder.putDiscountsInit(orderId)),
  }

  return <Discounts {...childProps} />
}
