import * as Yup from 'yup/lib'
import * as validations from 'modules/SharedSales/constants/validations'

import { ONLY_FIXED } from 'modules/SharedSales/constants/order'
import { SIGNUP_TYPE_PORTABILITY } from 'services/global-constants/index'
import { mobileNumberRegex, validateHasNumbers } from 'utils/index'
import { ClientInfoSchema } from './ClientInfo.validation'
// import { SetupSchema } from './Offer.validation'
import { OfferSchema } from './Offer.validation'
import { ContactSchema } from './ContactSummary.validation'
import { BillingSchema } from './Billing.validation'
import { LineSchema } from './Line.validation'
import { portabilityDateValidator } from './MobileLine.validation'
import { LeadSchema } from './Lead.validation'
import { terminalFilterSchema } from './TerminalsFilter.validation'
import { ExtraMobileSchema } from './MobileLineWithTariff.validation'
import { lineOwnerValidationSchema } from './LineOwner.validation'

const SetupSchema = {
  mainMobileLineOffer: Yup.object().shape({
    ...LineSchema({
      validation: mobileNumberRegex,
      isElFijo: false,
      disableOfferEdit: false,
      isMobileOnly: true,
    }),
    tariff: Yup.object().shape({
      id: Yup.string().when('$isMobileOnly', {
        is: true,
        then: Yup.string().required(validations.REQUIRED_FIELD_TEXT),
        otherwise: Yup.string(),
      }),
    }),
    ...terminalFilterSchema(false, true, false),
    portabilityType: Yup.string().when('signUpType', {
      is: SIGNUP_TYPE_PORTABILITY,
      then: Yup.string().required(validations.REQUIRED_FIELD_TEXT),
      otherwise: Yup.string(),
    }),
    isPortabilityDate: Yup.boolean(),
    isNewTerminal: Yup.string().required(validations.REQUIRED_FIELD_TEXT),
  }),
  ...ExtraMobileSchema(false, true),
}

const SetupDataSchema = {
  ...LeadSchema,
  mainMobileLineOffer: Yup.object().shape({
    ...lineOwnerValidationSchema,
    ...LineSchema({
      validation: mobileNumberRegex,
      isElFijo: false,
      disableOfferEdit: true,
      isMobileOnly: true,
    }),
    ...terminalFilterSchema(false, true, true),
    portabilityDate: portabilityDateValidator,
    desiredNumber: Yup.string().test(
      'desiredNumber',
      'Solamente puede contener 4 números',
      value => !value || (validateHasNumbers(value) && value.length === 4),
    ),
  }),
  ...ExtraMobileSchema(true, true),
}

export const SalesSchema = (
  step,
  isMobileOnly,
  saleType,
  isElFijo,
  isPosSale,
  isSubscription,
  isCrossSell,
  is2pPure,
  isMultiSim,
) => {
  const is2p = saleType === ONLY_FIXED

  const validationSchema = {
    1: isMobileOnly
      ? SetupSchema
      : ClientInfoSchema(saleType, isSubscription, isMultiSim, isCrossSell),
    2: isMobileOnly
      ? SetupDataSchema
      : {
          ...OfferSchema(
            isMobileOnly,
            isElFijo,
            isPosSale,
            is2p,
            isCrossSell,
            is2pPure,
            isMultiSim,
          ),
        },
    3: BillingSchema,
    4: ContactSchema,
  }
  return Yup.object().shape({
    ...validationSchema[step],
  })
}
