import PropTypes from 'prop-types'

import { useSelector } from 'react-redux'
import { Route, Switch, useRouteMatch, useLocation } from 'react-router-dom'

import { SimpleLayout } from 'components'
import { AuthenticatedRoute, selectSfid } from 'modules/Auth'
import { isSfidCancelNebaAuthorized } from 'modules/tariffs/store-apigee'

import { APP_CONFIG } from 'services/app-config'
import * as featureFlagSvc from 'services/feature-flag'
import { selectIsLoggedCustomer, selectCustomerIsLoading } from 'modules/Auth/store/selectors'

import Clients from './Clients'

import {
  AddressChangeContainer,
  CancelOrdersContainer,
  ChangeServicesContainer,
  ChangeTechnologyContainer,
  DiscountsContainer,
  ExistingClientSidebarContainer,
  NewAppointmentContainer,
  OrdersContainer,
  StatusOnFlyContainer,
} from './containers'
import MoveClientContainer from './containers/MoveClientContainer/MoveClientContainer'

function Main({ match, isCancelNebaEnabled }) {
  return (
    <Switch>
      <AuthenticatedRoute
        isCustomer
        exact
        path={`${match.url}/orders/:orders_id/actions`}
        component={OrdersContainer}
      />
      <AuthenticatedRoute
        isCustomer
        exact
        path={`${match.url}/orders/:order_id/address/edit`}
        component={AddressChangeContainer}
      />
      <AuthenticatedRoute
        isCustomer
        exact
        path={`${match.url}/orders/:order_id/cancel`}
        component={CancelOrdersContainer}
      />
      <Route
        exact
        path={`${match.url}/orders/:order_id/appointment/new`}
        component={NewAppointmentContainer}
      />
      <AuthenticatedRoute
        isCustomer
        exact
        path={`${match.url}/change-technology`}
        component={ChangeTechnologyContainer}
      />
      <AuthenticatedRoute
        isCustomer
        exact
        path={`${match.url}/change-services`}
        component={ChangeServicesContainer}
      />
      <AuthenticatedRoute
        isCustomer
        exact
        path={`${match.url}/move-client`}
        component={MoveClientContainer}
      />
      <AuthenticatedRoute
        isCustomer
        exact
        path={`${match.url}/move-client/address`}
        component={MoveClientContainer}
      />
      <AuthenticatedRoute
        isCustomer
        exact
        path={`${match.url}/status-order-on-fly`}
        component={isCancelNebaEnabled ? StatusOnFlyContainer : null}
      />
      <AuthenticatedRoute
        isCustomer
        exact
        path={`${match.url}/orders/:order_id/discounts/edit`}
        component={DiscountsContainer}
      />
      {!APP_CONFIG.production && <Route component={Clients} />}
    </Switch>
  )
}

Main.propTypes = {
  match: PropTypes.object,
  isCancelNebaEnabled: PropTypes.bool,
}

export function Routes(props) {
  const match = useRouteMatch()
  const location = useLocation()

  const sfid = useSelector(selectSfid)
  const featFlagNebaEnabled = useSelector(
    featureFlagSvc.selectors.selectFeatureFlagByName('neba_enabled'),
  )
  const isCancelNebaEnabled = isSfidCancelNebaAuthorized(sfid) && featFlagNebaEnabled

  const isLoggedCustomer = useSelector(selectIsLoggedCustomer)
  const isLoadingAuth = useSelector(selectCustomerIsLoading)

  const params = {
    ...props,
    match,
    isCancelNebaEnabled,
  }

  const hasSidebar =
    !(
      location.pathname === match.url ||
      location.pathname.startsWith(`${match.url}/change-services`)
    ) &&
    !isLoadingAuth &&
    isLoggedCustomer

  return (
    <SimpleLayout
      sidebar={hasSidebar ? <ExistingClientSidebarContainer /> : null}
      main={<Main {...params} />}
    />
  )
}
