const FIND_INIT = 'OVID/CUSTOMER360/FIND_INIT'
const FIND_SUCCESS = 'OVID/CUSTOMER360/FIND_SUCCESS'
const FIND_FAILED = 'OVID/CUSTOMER360/FIND_FAILED'
const RESET = 'OVID/CUSTOMER360/RESET'
const SET_SUBSCRIPTION = 'OVID/CUSTOMER360/SET_SUBSCRIPTION'
const FETCH_VIP_TYPE = 'OVID/CUSTOMER360/VIP_TYPE/FETCH'
const FETCH_VIP_TYPE_SUCCESS = 'OVID/CUSTOMER360/VIP_TYPE/FETCH_SUCCESS'
const FETCH_VIP_TYPE_FAILED = 'OVID/CUSTOMER360/VIP_TYPE/FETCH_FAILED'

const LINE_TYPES = {
  FIXED: 'fixed',
  MOBILE: 'mobile',
}

const RESPONSE_FIELDS_MAPPING = {
  NOT_INFLUENCER: 'FALSE',
  INFLUENCER: 'INFLUENCER',
  MAINI: 'MAINI',
  HEBE: 'HEBE',
}

const INFLUENCER_TYPES = {
  NOT_INFLUENCER: false,
  INFLUENCER: 'Influencer',
  MAINI: 'Maini',
  HEBE: 'Hebe',
}

const MAIN_LINE = 'main_line'
const ADDITIONAL_LINE = 'additional_line'
const EXTRA_LINE = 'extra_line'

export const constants = {
  FIND_INIT,
  FIND_SUCCESS,
  FIND_FAILED,
  RESET,
  LINE_TYPES,
  SET_SUBSCRIPTION,
  FETCH_VIP_TYPE,
  FETCH_VIP_TYPE_SUCCESS,
  FETCH_VIP_TYPE_FAILED,
  RESPONSE_FIELDS_MAPPING,
  INFLUENCER_TYPES,
  MAIN_LINE,
  ADDITIONAL_LINE,
  EXTRA_LINE,
}
export const CUSTOMER_PRO_SEGMENT = [
  'PRO-SOHO-AUTONOMO',
  'SOHO-COMPANY',
  'PRO-SOHO-COMPANY',
  'SOHO-AUTONOMO',
]
export const CUSTOMER_PRO_SOHO_SEGMENT = ['PRO-SOHO-AUTONOMO', 'PRO-SOHO-COMPANY']
export const CUSTOMER_SEGMENT = ['CUSTOMER', 'AUTONOMO', 'COMPANY']
export const SOHO_COMPANY = 'SOHO-COMPANY'
export const CUSTOMER_STATUS = {
  Canceled: 'cancelled',
}

export const MULTISIM_LINE = 'multisim_line'
