import { createStructuredSelector } from 'reselect'

import {
  selectIsMultiSimAllowed,
  selectIsMultiSimAllowedLoading,
  selectIsMultiSimAllowedError,
} from 'services/multisim/selectors'

export const selectMultiSimModal = createStructuredSelector({
  isMultiSimAllowed: selectIsMultiSimAllowed,
  isLoading: selectIsMultiSimAllowedLoading,
  error: selectIsMultiSimAllowedError,
})
