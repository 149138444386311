import PropTypes from 'prop-types'

import DownloadBtn from './DownloadBtn'
import { useDownloadInvoice } from './useDownloadInvoice'

const DownloadBtnPdf = ({ billId }) => {
  const { downloadInvoicePdf, loading, canIBillDownload } = useDownloadInvoice()

  const props = {
    onDownload: () => downloadInvoicePdf(billId),
    loading,
    canIBillDownload,
  }

  return <DownloadBtn {...props} />
}

DownloadBtnPdf.propTypes = {
  billId: PropTypes.string,
}

export default DownloadBtnPdf
