import { get } from 'lodash'
import { createSelector } from 'reselect'

import { selectEnergyFlag, selectEnergyForIslandsFlag } from 'services/feature-flag/selectors'
import { selectSfid, isIslandSfid } from 'modules/Auth'

const getEnergy = state => get(state, 'energy')

const ALLOWED_ENERGY_ISLAND_SFIDS = ['LM107006', 'LM138300', 'LM238002', 'LB138611']

export const selectEnergyIsAllowed = createSelector(
  [selectEnergyFlag, selectEnergyForIslandsFlag, selectSfid],
  (energyIsAllowed, energyIsAllowedForIslands, sfid) =>
    energyIsAllowed &&
    (energyIsAllowedForIslands ||
      !isIslandSfid(sfid) ||
      ALLOWED_ENERGY_ISLAND_SFIDS.includes(sfid)),
)

export const selectEnergyContractsIsLoading = createSelector(
  getEnergy,
  data => data.loading,
)

export const selectEnergyContractsData = createSelector(
  getEnergy,
  data => data.data,
)

export const selectEnergyContractsError = createSelector(
  getEnergy,
  data => data.error,
)
