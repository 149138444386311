import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Button, ModalActions, FormSelectOutlined } from 'components/ui'
import { isProCustomer } from 'services/customer-360/helpers'
import { getSegment } from 'services/customer-360/selectors'
import { FormContainer } from './FormContainer'
import { formatDuration, formatBundleName, isUpsellBundleAmount } from '../helpers'

export const BonusForm = ({
  customerTaxRate,
  filteredOptions,
  durationOptions,
  penaltyOptions,
  onSelectDataAmount,
  onSelectDuration,
  onSelectPenalty,
  dataAmount,
  duration,
  penalty,
  onSubmit,
  goToPreviousStep,
}) => {
  const customerSegment = useSelector(getSegment)
  const taxNeeded = !isProCustomer(customerSegment)

  return (
    <>
      <FormContainer>
        <FormSelectOutlined
          label="Selecciona el bono"
          minWidth="320px"
          value={dataAmount}
          options={filteredOptions.map(option => ({
            value: option.mobile_data_amount_mb,
            text:
              (taxNeeded && `${formatBundleName(option, customerTaxRate)} (impuestos incluidos)`) ||
              `${formatBundleName(option, 0)}`,
          }))}
          onChange={onSelectDataAmount}
        />
      </FormContainer>
      {dataAmount && isUpsellBundleAmount(dataAmount) && (
        <FormContainer>
          <FormSelectOutlined
            label="Duración"
            minWidth="160px"
            value={duration}
            options={durationOptions.map(option => ({
              value: option,
              text: formatDuration(option),
            }))}
            onChange={onSelectDuration}
          />
        </FormContainer>
      )}
      {duration && isUpsellBundleAmount(dataAmount) && (
        <FormContainer>
          <FormSelectOutlined
            label="Permanencia"
            minWidth="172px"
            value={penalty}
            options={penaltyOptions.map(option => ({ value: option, text: option ? 'Sí' : 'No' }))}
            onChange={onSelectPenalty}
          />
        </FormContainer>
      )}
      <ModalActions>
        {dataAmount && (!isUpsellBundleAmount(dataAmount) || duration) && penalty !== '' ? (
          <Button onClick={onSubmit}>Confirmar bono</Button>
        ) : null}
        <Button secondary onClick={goToPreviousStep}>
          Cancelar
        </Button>
      </ModalActions>
    </>
  )
}

BonusForm.propTypes = {
  filteredOptions: PropTypes.array,
  durationOptions: PropTypes.array,
  penaltyOptions: PropTypes.array,
  onSelectDataAmount: PropTypes.func,
  onSelectDuration: PropTypes.func,
  onSelectPenalty: PropTypes.func,
  dataAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  duration: PropTypes.string,
  penalty: PropTypes.string,
  onSubmit: PropTypes.func,
  goToPreviousStep: PropTypes.func,
  customerTaxRate: PropTypes.number,
}
