import { get, omit } from 'lodash'
import { call, put, takeLatest } from 'redux-saga/effects'
import { logError, formatErrorMessage } from 'services/logging'
import { axiosFactory } from 'modules/axios'

import * as actions from './actions'
import * as constants from './constants'
import { createTypification } from './api'

export function* createTypificationSaga(action) {
  try {
    const result = yield call(createTypification, get(action.payload, 'documentId'), {
      ...omit(action.payload, 'documentId'),
    })

    yield put(actions.typificationCreated(result))
  } catch (e) {
    yield call(logError, { e: new Error(formatErrorMessage(e)), payload: action.payload })
    const error = axiosFactory.buildError(e)
    yield put(actions.createTypificationFailed(error))
  }
}

export function* watchCreateTypification() {
  yield takeLatest(constants.CREATE_TYPIFICATION_ATTEMPTED, createTypificationSaga)
}
