export const FIND_PREPAID_LINE = 'OVID/FIND_PREPAID_LINE/FETCH'
export const FIND_PREPAID_LINE_SUCCESS = 'OVID/FIND_PREPAID_LINE/SUCCESS'
export const FIND_PREPAID_LINE_FAILED = 'OVID/FIND_PREPAID_LINE/FAILED'

export const findPrepaidLine = productId => ({
  type: FIND_PREPAID_LINE,
  payload: {
    productId,
  },
})

export const findPrepaidLineSuccess = (data, productId) => ({
  type: FIND_PREPAID_LINE_SUCCESS,
  payload: {
    data,
    productId,
  },
})

export const findPrepaidLineFailed = (error, productId) => ({
  type: FIND_PREPAID_LINE_FAILED,
  payload: {
    error,
    productId,
  },
})
