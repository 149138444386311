import PropTypes from 'prop-types'
import {
  OffersCard,
  OffersCardFront,
  OfferHeader,
  OfferRotateAction,
  OfferContent,
} from 'components/ui'
import { isConvergentSubscription } from 'services/subscriptions'
import PortOut from './PortOut'
import { TariffItem } from './TariffItem'
import DiscountsBonusesItem from './DiscountsBonusesItem'
import PermanentItem from './PermanentItem'
import DeviceItem from './DeviceItem'
import SubscriptionCardFooter from './SubscriptionCardFooter'
import { formatCardSubtitle } from '../helpers'

export const cardWidth = 288
export const convergentCardWidth = 600

export function OldSubscriptionCard({
  status,
  subscription,
  mobilePortOut,
  fixedPortOut,
  onRotate,
  isRotated,
  onModalOpen,
  onTitleChange,
}) {
  const hasPortOut = Boolean(mobilePortOut) || Boolean(fixedPortOut)
  const isConvergent = isConvergentSubscription(subscription)

  return (
    <OffersCard width={`${isConvergent ? convergentCardWidth : cardWidth}px`} isRotated={isRotated}>
      <OfferHeader
        title={subscription.productNumber}
        subtitle={formatCardSubtitle(subscription)}
        status={status}
      />
      <OfferContent multicolumn={isConvergent}>
        <OffersCardFront>
          <TariffItem
            subscription={subscription}
            onModalOpen={onModalOpen}
            onTitleChange={onTitleChange}
          />
          <DiscountsBonusesItem subscription={subscription} onModalOpen={onModalOpen} />
          <PermanentItem isFixed subscription={subscription} onModalOpen={onModalOpen} />
          <PermanentItem isFixed={false} subscription={subscription} onModalOpen={onModalOpen} />
          <DeviceItem isFixed subscription={subscription} />
          <DeviceItem isFixed={false} subscription={subscription} />
          {hasPortOut && <OfferRotateAction text="Ver info portout" onRotate={onRotate} />}
        </OffersCardFront>
        {hasPortOut && (
          <PortOut
            subscription={subscription}
            onRotate={onRotate}
            onModalOpen={onModalOpen}
            fixedPortOut={fixedPortOut}
            mobilePortOut={mobilePortOut}
          />
        )}
      </OfferContent>
      <SubscriptionCardFooter subscription={subscription} onModalOpen={onModalOpen} />
    </OffersCard>
  )
}

OldSubscriptionCard.propTypes = {
  status: PropTypes.string,
  mobilePortOut: PropTypes.object,
  fixedPortOut: PropTypes.object,
  onRotate: PropTypes.func.isRequired,
  subscription: PropTypes.shape({
    events: PropTypes.array,
    charges: PropTypes.array,
    id: PropTypes.string,
    type: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    productNumber: PropTypes.string,
    productUsage: PropTypes.shape({
      validFor: PropTypes.object,
      usages: PropTypes.array,
    }),
    productsSpecifications: PropTypes.array,
    productCharacteristic: PropTypes.array,
    barrings: PropTypes.array,
    bundle_upsells: PropTypes.array,
    services: PropTypes.array,
    productPrice: PropTypes.array,
    contracts: PropTypes.shape({
      permanents: PropTypes.array,
      financials: PropTypes.array,
    }),
    bundle_usages: PropTypes.array, // NOTE: This is not documented in lumier
  }),

  onModalOpen: PropTypes.func.isRequired,
  isRotated: PropTypes.bool.isRequired,
  onTitleChange: PropTypes.func,
}
