import PropTypes from 'prop-types'
import styled, { css, withTheme } from 'styled-components'
import { get, values, noop } from 'lodash'
import { Icon } from '@material-ui/core'
import { ICONS } from 'utils/icons'

import { STEP_STATUS, LOGISTIC_STEPS_ICONS } from './LogisticStatus.helpers'

const getStatusColor = theme => ({
  [STEP_STATUS.ACTIVE]: () => get(theme, 'palette.global.gray_dark'),
  [STEP_STATUS.CANCELLED]: () => get(theme, 'palette.brand.alert'),
  [STEP_STATUS.ALERT]: () => get(theme, 'palette.brand.gray_dark'),
})

const IconWrapper = styled.div`
  position: relative;
  background-color: ${({ theme }) => get(theme, 'palette.global.gray_light')};
  padding: 12px 6px;
  z-index: 1;
  color: ${({ theme }) => get(theme, 'palette.global.gray_dark')};
`
const IconStyled = styled(({ statusColor, ...rest }) => <Icon {...rest} />)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: 48px;
  font-size: 26px;
  ${({ statusColor }) =>
    statusColor &&
    css`
      color: ${statusColor};
      border: 1.5px solid ${statusColor};
      border-radius: 100%;
    `};
`

function IconStatus({ theme, icon, status }) {
  const statusColor = get(getStatusColor(theme), status, noop)()

  return (
    <IconWrapper>
      <IconStyled
        statusColor={statusColor}
        className={!status ? 'icon material-icons-outlined' : 'icon'}>
        {ICONS[icon]}
      </IconStyled>
    </IconWrapper>
  )
}

IconStatus.defaultProps = {
  status: null,
}

IconStatus.propTypes = {
  theme: PropTypes.object,
  icon: PropTypes.oneOf(values(LOGISTIC_STEPS_ICONS)),
  status: PropTypes.oneOf(values(STEP_STATUS)),
}

export default withTheme(IconStatus)
