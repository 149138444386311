import { createSelector } from 'reselect'
import { get } from 'lodash'
import { APP_CONFIG } from 'services/app-config'

import { selectSfid } from 'modules/Auth/store/selectors'
import { selectRouter } from 'modules/Router/store/router.selectors'

import { FLAG_ENVIRONMENT, FLAG_STATUS } from './constants'
import { getFeatureFlags, mapFlagsToBool } from './helpers'

export const getFlags = state => get(state, 'featureFlag.data', {})
export const getLoading = state => get(state, 'featureFlag.loading')
export const getFlagsError = state => get(state, 'featureFlag.error', null)

export const selectFlagsToBool = createSelector([getFlags, selectSfid], (flags, sfid) => {
  const environment = APP_CONFIG.production ? FLAG_ENVIRONMENT.PROD : FLAG_ENVIRONMENT.STA

  return mapFlagsToBool(
    flags.filter(flag => flag.environment === environment && flag.status === FLAG_STATUS.ACTIVE),
    sfid,
    environment,
  )
})

export const selectFeatureFlags = createSelector(
  [selectFlagsToBool, selectRouter, selectSfid],
  getFeatureFlags,
)

export const selectFeatureFlagByName = featureName => state => {
  const featureFlags = selectFeatureFlags(state)
  return featureFlags.featureFlags[featureName]
}

export const selectOnlyMobileFlag = selectFeatureFlagByName('only_mobile')

export const selectInstallationsSalesFlag = selectFeatureFlagByName('installationsSales')

export const selectLandline2pFlag = selectFeatureFlagByName('landline_2p')

export const selectIsSVAEmail = selectFeatureFlagByName('sva_email')

export const selectCanGoToStore = selectFeatureFlagByName('can_go_to_store')

export const selectCashOnDeliveryFlag = selectFeatureFlagByName('cash_on_delivery')

export const selectIsCampaigns = selectFeatureFlagByName('campaigns')

export const selectAppleTvFlag = selectFeatureFlagByName('appleTv')

export const selectDownloadContract = selectFeatureFlagByName('download_contract')

export const selectCrossSellFlag = selectFeatureFlagByName('cross_sell')

export const selectDeferredPaymentMenu = selectFeatureFlagByName('deferred_payment_menu')

export const selectDisableDiscounts = selectFeatureFlagByName('disable_discounts')

export const selectDeviceRenewalFlag = selectFeatureFlagByName('device_renewal')

export const selectDeviceRenewalIbanValidation = selectFeatureFlagByName(
  'device_renewal_iban_validation',
)

export const selectDeviceRenewalAgileTv = selectFeatureFlagByName('device_renewal_agile_tv')

export const selectCancelPartialOrders = selectFeatureFlagByName('order_cancel_partial')

export const selectRetainOptionFlag = selectFeatureFlagByName('retain_option')

export const selectEnergyFlag = selectFeatureFlagByName('energy')

export const selectEnergyForIslandsFlag = selectFeatureFlagByName('energy_for_islands')

export const selectSvaSmartHomeFlag = selectFeatureFlagByName('sva_smarthome')

export const selectSvaHomeGoFlag = selectFeatureFlagByName('sva_homego')

export const selectSvaHomeGoOnflyFlag = selectFeatureFlagByName('sva_homego_onfly')

export const selectUpsellSendSubscriptionId = selectFeatureFlagByName('upsell_send_subscription_id')

export const selectGdprConsentsFlag = selectFeatureFlagByName('gdpr_consents')

export const selectGdprClientConsentsFlag = selectFeatureFlagByName('gdpr_client_consents')

export const selectGdprClientCampaignConsentsFlag = selectFeatureFlagByName(
  'gdpr_client_campaign_consents',
)

export const selectBillingDifferencesFlag = selectFeatureFlagByName('billing_differences')

export const selectDoctorGoFlag = selectFeatureFlagByName('doctor_go_sva')

export const selectHebeSvaClientFlag = selectFeatureFlagByName('hebe_sva_client')

export const selectCancelReplaceableFlag = selectFeatureFlagByName('cancel_replaceable')

export const selectAddAgileTvFlag = selectFeatureFlagByName('add_agiletv')

export const selectPreSaleFlag = selectFeatureFlagByName('presale')

export const selectFinancedDeviceLegalConsentsFlag = selectFeatureFlagByName(
  'financed_device_legal_consents',
)
export const selectStcV3Flag = selectFeatureFlagByName('stc_v3')

export const selectStcV3Phase2Flag = selectFeatureFlagByName('stc_v3_phase2')

export const selectReadOnlyIbanOnCarteraFlag = selectFeatureFlagByName('readonly_iban_on_cartera')

export const selectD2RFromMsFlag = selectFeatureFlagByName('d2r_from_ms')

export const selectD2RNewDiscounts = selectFeatureFlagByName('d2r_new_discounts')

export const selectLeadsPosFlag = selectFeatureFlagByName('leads_pos')

export const selectClientGdprUpdateFlag = selectFeatureFlagByName('client_gdpr_update')

export const selectInternalPortabilityFlag = selectFeatureFlagByName('internal_portability')

export const selectSvaRegisterFlag = selectFeatureFlagByName('sva_register')

export const selectHideHebeSvaFlag = selectFeatureFlagByName('hide_hebe_sva')

export const selectChangeAddressV1 = selectFeatureFlagByName('change_address_v1')

export const selectChangeAddressV3 = selectFeatureFlagByName('change_address_v3')

export const selectNebaEnabledFlag = selectFeatureFlagByName('neba_enabled')

export const selectInstallations360 = selectFeatureFlagByName('installations360')

export const selectMultiSimFlag = selectFeatureFlagByName('multisim')

export const selectCoverageV1 = selectFeatureFlagByName('coverage_v1')

export const selectEnergyEmail = selectFeatureFlagByName('energy_email')

export const selectCommercialMigration = selectFeatureFlagByName('commercial_migration')

export const selectNewCitation = selectFeatureFlagByName('new_citation')

export const selectChangeTechnologyV3 = selectFeatureFlagByName('change_technology_v3')

export const selectChangeTechnologyV1 = selectFeatureFlagByName('change_technology_v1')

export const selectChangeAddressAppointment = selectFeatureFlagByName('change_address_appointment')

export const selectChangeTechnologyAppointment = selectFeatureFlagByName(
  'change_technology_appointment',
)

export const selectKairosSalesFlag = selectFeatureFlagByName('kairos_sales')

export const selectCommercialMigrationAppointmentFlag = selectFeatureFlagByName(
  'commercial_migration_appointment',
)
export const selectFetchCutomerSvasFlag = selectFeatureFlagByName('fetch_customer_svas')

export const selectChangeAgileTvFlag = selectFeatureFlagByName('change_agile_tv')

export const selectChangeAddressIndirectFiberFlag = selectFeatureFlagByName(
  'change_address_indirect_fiber',
)

export const selectIframeTipis = selectFeatureFlagByName('iframe-tipis')

export const selectDoctorGoUnsubscribeFlag = selectFeatureFlagByName('doctor_go_unsubscribe')

export const selectUpdateOrderV1 = selectFeatureFlagByName('update_order_v1')

export const selectEnabledAlarmsCtCd = selectFeatureFlagByName('enabled_alarms_ct_cd')

export const selectEnabledBtcAgileTv = selectFeatureFlagByName('enabled_btc_agile_tv')

export const selectEnabledExitButtonIframeTipis = selectFeatureFlagByName(
  'show_exit_button_iframe-tipis',
)

export const selectNewNetkiaUrl = selectFeatureFlagByName('new_netkia_url')

export const selectNewNetkiaCustomerUrl = selectFeatureFlagByName('new_netkia_customer_url')

export const selectEnabledSmsTipis = selectFeatureFlagByName('enabled_sms_tipis')

export const selectEnabledTaxSvaAgileTv = selectFeatureFlagByName('enabled_tax_sva_agile_tv')

export const selectEnabledButtonInsurancePpi = selectFeatureFlagByName(
  'enabled_button_insurance_ppi',
)

export const selectEnabledLogicIpAddress = selectFeatureFlagByName('enabled_logic_ip_address')

export const selectFriendPromoSales = selectFeatureFlagByName('friend_promo_sales')

export const selectEnabledNewClientSale = selectFeatureFlagByName('enabled_new_client_sale')

export const selectOtherAddressClientOnlyMobile = selectFeatureFlagByName(
  'other_address_client_only_mobile',
)

export const selectShowMigrationCustomerMessage = selectFeatureFlagByName(
  'show_migration_customer_message',
)

export const selectEnabledProrratedPenalty = selectFeatureFlagByName('enabled_prorrated_penalty')

export const selectEnabledXsellSaleCampaignFlag = selectFeatureFlagByName('xsell_sale_campaign')

export const selectEnableMasCareIframeTypication = selectFeatureFlagByName('enable_mascare_iframe_typification')

export const selectEnableEmailIframeTypification = selectFeatureFlagByName('enable_email_iframe_typification')

export const selectEnableScheduleDayOne = selectFeatureFlagByName('enable_schedule_day_one')

export const selectEnableFat = selectFeatureFlagByName('enable_fat')

export const selectEnableOnflyDiscounts = selectFeatureFlagByName('enable_onfly_discounts')

export const selectEnableCvmDiscounts = selectFeatureFlagByName('enable_cvm_discounts')

export const selectEnablePeriodFat = selectFeatureFlagByName('enable_period_fat')

export const selectNewTextFrozenClient = selectFeatureFlagByName('show_new_text_frozen_client')

export const selectEnableScheduleTomorrow = selectFeatureFlagByName('enable_schedule_tomorrow')
