import { get, upperCase, pickBy, chain } from 'lodash'

import { CONVERGENT } from 'modules/SharedSales/constants/order'
import { COMPANY } from 'services/global-constants/segments'

const mapToPersonalInfo = (customerInfo, isCompany) => ({
  firstName: customerInfo.clientName,
  middleName: customerInfo.middleName,
  lastName: customerInfo.lastName,
  nationality: customerInfo.nationality,
  document: {
    type: isCompany ? 'CIF' : customerInfo.documentType,
    value: isCompany ? customerInfo.companyCif : customerInfo.documentNumber,
  },
})

export const getAddressStreetType = address => {
  return (
    address?.streetType ||
    address?.addressType ||
    chain(address)
      .get('characteristic')
      .find({ name: 'streetType' })
      .get('value')
      .value()
  )
}

export const getHomeGoRequestData = ({
  orderId,
  msisdn,
  subscription,
  invoicingAddress,
  sendAddress,
  isCarteraFlow,
  segment: customerSegment,
  customerInfo,
  scoring,
  kit,
  tariffId,
  sfidCode,
  isSalesCarteraFlow,
  technology,
  isCompany,
  ibanNumberOnFlyCustomer,
}) => {
  const typeCateogry = get(subscription, 'category') === CONVERGENT ? CONVERGENT : 'MOBILE'
  const customerType = isSalesCarteraFlow || isCarteraFlow ? 'EXISTING' : 'NEW'
  const scoringType = customerType === 'NEW' ? 5 : 10
  const gescalSendAddress = sendAddress.gescal || sendAddress.technicalId

  const streetType =
    sendAddress.streetType || sendAddress.addressType || getAddressStreetType(sendAddress)
  const streetName = sendAddress.streetOne || sendAddress.address || sendAddress.name
  const streetNumber = sendAddress.number || sendAddress.streetNumber

  const town = sendAddress.city || sendAddress.province
  const zipCode = sendAddress.postCode || sendAddress.zipCode || sendAddress.postalCode
  const { province } = sendAddress
  const gescal = gescalSendAddress
  const ibanNumber = scoring.payment?.iban || ibanNumberOnFlyCustomer
  const birthDay = isCompany ? customerInfo.constitutionDate : customerInfo.birthday

  let segment = customerSegment
  if (isCompany) {
    segment = COMPANY
  }
  if (customerInfo.isSelfEmployed) {
    segment = 'FREELANCE'
  }

  const response = {
    msisdn,
    sfid: sfidCode,
    orderId,
    customer: pickBy({
      type: customerType,
      id: String(customerInfo.accountId),
      segment: upperCase(segment),
      personal: pickBy({
        ...mapToPersonalInfo(customerInfo, isCompany),
        birthDay,
        email: customerInfo.email,
        phone: customerInfo.phone1,
        otherPhone: customerInfo.phone2,
        bankAccountNumber: ibanNumber,
      }),
      addressInfo: {
        address: invoicingAddress.fullAddress || invoicingAddress.name,
        city: invoicingAddress.city,
        province: invoicingAddress.province,
        zipCode: invoicingAddress.postalCode,
      },
      ...(isCompany
        ? {
            business: {
              company: customerInfo.companyName,
              admin: {
                ...mapToPersonalInfo(customerInfo),
              },
            },
          }
        : {}),
    }),
    product: pickBy({
      id: String(subscription.subscription_id),
      cliType: tariffId,
      type: typeCateogry,
      technology,
    }),
    scoring: pickBy({
      ...scoring,
      prescoringId: scoring.submission?.referenceNumber,
      scoringType,
      payment: {
        ...scoring.payment,
        iban: ibanNumber,
      },
    }),
    kit: {
      id: kit.psId,
      description: kit.commercialInfo?.name,
      price: kit.prices?.taxFreeAmount || 0,
      units: 1,
    },
    provision: pickBy({
      ...sendAddress,
      streetType,
      streetName,
      streetNumber,
      town,
      zipCode,
      province,
      characteristic: [
        {
          name: 'streetType',
          value: streetType,
        },
        {
          name: 'streetNumber',
          value: streetNumber,
        },
        {
          name: 'province',
          value: province,
        },
        {
          name: 'gescal',
          value: gescal || '',
        },
        {
          name: 'territoryOwner',
          value: '',
        },
      ],
    }),
  }

  return response
}
