import { axiosJWT } from 'modules/axios'
import { get } from 'lodash'
import { CONFIG } from './environments'

export const territoryCompatible = params => {
  return axiosJWT({
    method: 'GET',
    url: `${CONFIG.api.territoryCompatible}`,
    params,
  }).then(resp => get(resp, 'data'))
}
