import { TabServicesBarrings } from './TabServicesBarrings'
import { TabServicesSim } from './TabServicesSim'
import { TabSvas } from './TabSvas'

export const tabs = {
  RESTRICTIONS: 'restricciones',
  SIMSERVICES: 'simservices',
  SVAS: 'svas',
}

export function getTabsConfig(multisim, isPosOrMasproximo) {
  const tabsConfig = [
    {
      label: 'svas',
      val: '0',
      component: TabSvas,
      data_hook: 'svas-tab',
      tabType: tabs.SVAS,
      disabled: multisim,
    },
    {
      label: 'restricciones',
      val: '1',
      component: TabServicesBarrings,
      data_hook: 'barrings-tab',
      tabType: tabs.RESTRICTIONS,
      disabled: isPosOrMasproximo,
    },
    {
      label: 'servicios de la sim',
      val: '2',
      component: TabServicesSim,
      data_hook: 'sim-tab',
      tabType: tabs.SIMSERVICES,
    },
  ]

  return tabsConfig
}
