import { call, put, takeEvery } from 'redux-saga/effects'
import { logError, formatErrorMessage } from 'services/logging'
import { axiosFactory } from 'modules/axios'
import actions from './actions'
import constants from './constants'
import { fetchTax } from './api'

export function* findTaxSaga({ payload: { zipCode } }) {
  try {
    const tax = yield call(fetchTax, zipCode)
    yield put(actions.findTaxSuccess(zipCode, tax))
  } catch (e) {
    yield call(logError, { e: new Error(formatErrorMessage(e)), zipCode })
    const error = axiosFactory.buildError(e)
    yield put(actions.findTaxError(zipCode, error))
  }
}

export function* watchFindTax() {
  yield takeEvery(constants.FIND_TAX, findTaxSaga)
}
