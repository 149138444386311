import { Fragment } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { get, isEmpty } from 'lodash'
import styled from 'styled-components'
import { differenceInCalendarMonths } from 'date-fns'
import { useHistory, useLocation } from 'react-router-dom'

import { getSegment, selectIsCustomerResidential } from 'services/customer-360/selectors'
import { isProCustomer } from 'services/customer-360/helpers'

import { Box, Typography } from '@material-ui/core'
import { TextStyles } from 'utils/text'

import { HighLightFrame } from 'modules/ui/components'
import { LineItemCard, ButtonLink, Advice, ButtonCard } from 'components/ui'
import { selectLostedDiscounts } from 'modules/billings/store/billings.selectors'
import { buildPath, AppPaths } from 'modules/AppPaths'
import { permissions, selectCanIDO } from 'modules/Permissions'
import { useIsEnabledCvmAddDiscountsOrBonus } from 'modules/offers-configuration/hooks'

import { selectDisableDiscounts } from 'services/feature-flag/selectors'
import { canModifySubscription } from 'modules/offers-configuration/selectors'

import { selectURLDocumentId, selectURLDocumentType } from 'modules/CustomerDocument'

import { formatFee } from 'utils'

import { isUnlimitedDiscountByDates } from 'modules/offers-configuration/helpers'
import { urlToRouterParams } from 'modules/Router/helpers'

import { MAX_DURATION_IN_MONTHS_TO_DISPLAY } from 'modules/offers-configuration/components/DiscountsBonusesDisable/DiscountsDisable'
import { CVMActions } from 'modules/offers-configuration/CVMActions.constants'
import { MULTISIM_LINE } from 'services/customer-360/constants'
import { selectSubscriptionById } from 'services/subscriptions'

const formatDateRange = (rangeLeft, endDate, durationInMonths) => {
  const rangeLeftInMonths = differenceInCalendarMonths(endDate, Date.now())

  if (rangeLeftInMonths === 0) {
    return 'Último mes'
  }

  if (durationInMonths > MAX_DURATION_IN_MONTHS_TO_DISPLAY) {
    return 'Para siempre'
  }

  return rangeLeft
}

export const SubscriptionDiscountsInfo = styled(
  ({ className, subscription, discounts, refScroll, dataHook }) => {
    const history = useHistory()
    const location = useLocation()

    const customerSegment = useSelector(getSegment)
    const taxNeeded = !isProCustomer(customerSegment)

    const isEnabledCvmAddDiscountsOrBonusForSub =
      useIsEnabledCvmAddDiscountsOrBonus(subscription) ||
      useIsEnabledCvmAddDiscountsOrBonus(subscription, false)

    const subscriptionCanBeModified = useSelector(state =>
      canModifySubscription(state, subscription),
    )

    const isCustomerResidential = useSelector(selectIsCustomerResidential)
    const isEnabledCvmOpsDisableDiscounts = useSelector(
      selectCanIDO(permissions.cvm_ops_disable_discounts_all.id),
    )

    const documentType = useSelector(selectURLDocumentType)
    const documentId = useSelector(selectURLDocumentId)
    const multisimTariff = get(subscription, 'multisimType') === MULTISIM_LINE
    const parentSubscription = useSelector(selectSubscriptionById(get(subscription, 'multisimId')))

    const pathToAddDiscountsOrBundlesCVM = buildPath(
      AppPaths.cvm.id,
      { documentType, documentId },
      multisimTariff
        ? {
            subscription_id: get(parentSubscription, 'data.id'),
            op: CVMActions.add_multisim_discount.id,
          }
        : {
            subscription_id: subscription.id,
            op: CVMActions.add_discounts_or_bundles.id,
          },
    )

    const handleClickAddDiscount = () => {
      const url = urlToRouterParams(location, pathToAddDiscountsOrBundlesCVM)
      history.push(url)
    }

    const lostedDiscounts = useSelector(state => selectLostedDiscounts(state, subscription.id))

    const getPathToDisableDiscountsCVM = discountId => {
      const redirectDataBuild = multisimTariff
        ? {
            subscription_id: get(parentSubscription, 'data.id'),
            op: CVMActions.disable_multisim_discounts.id,
          }
        : {
            subscription_id: subscription.id,
            op: CVMActions.disable_discounts_or_bundles.id,
          }

      return buildPath(
        AppPaths.cvm.id,
        { documentType, documentId },
        {
          ...redirectDataBuild,
          discount_id: discountId,
        },
      )
    }

    const isDisableDiscountsFlagEnabled = useSelector(selectDisableDiscounts)

    return (
      <div className={className} ref={refScroll} data-hook={dataHook}>
        <HighLightFrame />
        <div className="flex align-space-between-center">
          <Typography
            {...TextStyles.subtitle2Link({
              className: 'p-9-24',
            })}>
            <span className="blue-span">Descuentos</span>
          </Typography>
          <Box padding="9px 24px">
            <ButtonCard
              data-hook="lnk-add-discounts-or-bundles"
              label="Añadir"
              disabled={!isEnabledCvmAddDiscountsOrBonusForSub}
              onClick={handleClickAddDiscount}
            />
          </Box>
        </div>

        {!isEmpty(lostedDiscounts) && (
          <Box p="0 24px 6px">
            {lostedDiscounts.map(discount => (
              <Box mb="5px">
                <Advice key={discount.id} type="warning" block>
                  <Typography variant="subtitle2" className="thin">
                    El cliente ha perdido el descuento &quot;{discount.billingItem.nameEs}&quot; en
                    el último mes.
                  </Typography>
                </Advice>
              </Box>
            ))}
          </Box>
        )}

        {!isEmpty(discounts) ? (
          <div data-hook="subscription-discounts-list" total-discounts={discounts.length}>
            {discounts.map((discount, i) => (
              <Fragment
                key={`${get(discount, 'discountId')}-${get(discount, 'subscriptionDiscountId')}`}>
                <LineItemCard
                  primaryTextDataHook={`subscription-discount-${i}`}
                  primaryText={`${get(discount, 'discountName')}
                  ${
                    get(discount, 'isPercentage')
                      ? `${get(discount, 'amount')} %`
                      : formatFee(get(discount, taxNeeded ? 'amountWithTax' : 'amount'))
                  }`}
                  secondaryText={
                    discount.months > MAX_DURATION_IN_MONTHS_TO_DISPLAY ||
                    // TODO: esto se pone como validación temporal hasta que se despliegue contenful en Noviembre 2020. Eliminar la función isUnlimitedDiscountByDates y sus llamadas
                    isUnlimitedDiscountByDates(discount.start, discount.end)
                      ? `Para siempre`
                      : formatDateRange(
                          get(discount, 'lengthRange'),
                          get(discount, 'end'),
                          get(discount, 'months'),
                        )
                  }
                  fieldsApart
                />

                <Typography
                  {...TextStyles.labelDark({
                    className: 'xs-text p-9-24',
                  })}>
                  {discount.dateRange}
                </Typography>

                {subscriptionCanBeModified &&
                  isCustomerResidential &&
                  isEnabledCvmOpsDisableDiscounts &&
                  isDisableDiscountsFlagEnabled && (
                    <ButtonLink
                      className="button-disable-discount p-9-24"
                      data-hook="lnk-disable-discounts"
                      to={getPathToDisableDiscountsCVM(get(discount, 'subscriptionDiscountId'))}
                      disabled={false}
                      underline>
                      Desactivar
                    </ButtonLink>
                  )}
              </Fragment>
            ))}
          </div>
        ) : (
          <Typography {...TextStyles.body()} className="italic-text p-9-24">
            <span>No tiene ninguno activo</span>
          </Typography>
        )}
      </div>
    )
  },
)`
  && .p-9-24 {
    padding: 9px 24px;
  }

  && .italic-text {
    font-style: italic;
  }

  && .xs-text {
    font-size: 12px;
    margin-top: -14px;
    margin-bottom: 0;
  }

  && .text-row {
    width: 100%;
  }

  && .primary-text {
    width: 60%;
  }

  && .secondary-text {
    width: 40%;
  }

  && .button-disable-discount {
    display: block;
    padding-top: 0;
  }

  .blue-span {
    color: ${props => get(props, 'theme.palette.brand.medium')};
  }
`

SubscriptionDiscountsInfo.propTypes = {
  discounts: PropTypes.arrayOf(PropTypes.object),
  subscription: PropTypes.object,
}
