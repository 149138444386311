export const FIND_BARRINGS_STATUS = 'OVID/FIND_BARRINGS_STATUS/FETCH'
export const FIND_BARRINGS_STATUS_SUCCESS = 'OVID/FIND_BARRINGS_STATUS/SUCCESS'
export const FIND_BARRINGS_STATUS_FAILED = 'OVID/FIND_BARRINGS_STATUS/FAILED'
export const SET_BARRING_STATUS_SUCCEEDED = 'OVID/SET_BARRING_STATUS/SUCCEEDED'
export const SET_MULTIPLE_BARRINGS_STATUS_ATTEMPTED = 'OVID/SET_MULTIPLE_BARRINGS_STATUS/ATTEMPTED'
export const SET_MULTIPLE_BARRINGS_STATUS_SUCCEEDED = 'OVID/SET_MULTIPLE_BARRINGS_STATUS/SUCCEEDED'
export const SET_MULTIPLE_BARRINGS_STATUS_FAILED = 'OVID/SET_MULTIPLE_BARRINGS_STATUS/FAILED'

export const findBarringsStatusAction = productId => ({
  type: FIND_BARRINGS_STATUS,
  payload: {
    productId,
  },
})

export const findBarringsStatusSuccessAction = (productId, data) => ({
  type: FIND_BARRINGS_STATUS_SUCCESS,
  payload: {
    productId,
    data,
  },
})

export const findBarringsStatusFailedAction = (productId, error) => ({
  type: FIND_BARRINGS_STATUS_FAILED,
  payload: {
    error,
    productId,
  },
})

export const setMultipleBarringStatusAction = (productId, barrings, data) => ({
  type: SET_MULTIPLE_BARRINGS_STATUS_ATTEMPTED,
  payload: {
    productId,
    barrings,
    data,
  },
})

export const setMultipleBarringStatusSucceededAction = productId => ({
  type: SET_MULTIPLE_BARRINGS_STATUS_SUCCEEDED,
  payload: {
    productId,
  },
})

export const setMultipleBarringStatusFailedAction = productId => ({
  type: SET_MULTIPLE_BARRINGS_STATUS_FAILED,
  payload: {
    productId,
  },
})

export const setBarringStatusSuccededAction = (productId, barring) => ({
  type: SET_BARRING_STATUS_SUCCEEDED,
  payload: {
    productId,
    barring,
  },
})
