import PropTypes from 'prop-types'

import { Grid } from '@material-ui/core'
import { SpinnerCenter } from 'components/ui'

import { Board } from '../../components/Board'
import { OwnerCard } from '../../components/OwnerCard'
import { OrderCard } from '../../components/OrderCard'
import { ContactCard } from '../../components/ContactCard'
import { LogisticCard } from '../../components/LogisticCard'
import { formatDataOwner, formatDataOrder, formatDataContact } from './helpers'

export function OrderMain({
  isLoading,
  hasErrors,
  ownerData,
  orderData,
  orderDataContact,
  dataLogistic,
  gdprData,
  gdprConsentsAllowed,
  className,
}) {
  return (
    <Board className={className}>
      {isLoading && <SpinnerCenter showMsg />}
      {!isLoading && !hasErrors && (
        <>
          <Grid item xs={4}>
            {orderData && <OrderCard data={formatDataOrder(orderData)} />}
          </Grid>
          <Grid item xs={4}>
            {ownerData && (
              <OwnerCard
                data={formatDataOwner(ownerData)}
                gdprData={gdprConsentsAllowed && !ownerData.isCompany ? gdprData : null}
              />
            )}
          </Grid>
          <Grid item xs={4}>
            {orderDataContact && <ContactCard data={formatDataContact(orderDataContact)} />}
          </Grid>
          <Grid item xs={12}>
            <LogisticCard data={dataLogistic} />
          </Grid>
        </>
      )}
    </Board>
  )
}

OrderMain.propTypes = {
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  hasErrors: PropTypes.bool,
  ownerData: PropTypes.object,
  orderData: PropTypes.object,
  orderDataContact: PropTypes.object,
  dataLogistic: PropTypes.arrayOf(PropTypes.object),
  gdprData: PropTypes.object,
  gdprConsentsAllowed: PropTypes.bool,
}
