const FIND_ORDER_PRODUCTS = 'OVID/VISTA360/FIND_ORDER_PRODUCTS_START'
const FIND_ORDER_PRODUCTS_SUCCESS = 'OVID/VISTA360/FIND_ORDER_PRODUCTS_SUCCESS'
const FIND_ORDER_PRODUCTS_FAILED = 'OVID/VISTA360/FIND_ORDER_PRODUCTS_FAILED'

const FIND_ORDERS_PRODUCT_BY_CLIENT = 'OVID/VISTA360/FIND_ORDERS_PRODUCT_BY_CLIENT_START'
const FIND_ORDERS_PRODUCT_BY_CLIENT_SUCCESS = 'OVID/VISTA360/FIND_ORDERS_PRODUCT_BY_CLIENT_SUCCESS'
const FIND_ORDERS_PRODUCT_BY_CLIENT_FAILED = 'OVID/VISTA360/FIND_ORDERS_PRODUCT_BY_CLIENT_FAILED'

export const constants = {
  FIND_ORDER_PRODUCTS,
  FIND_ORDER_PRODUCTS_SUCCESS,
  FIND_ORDER_PRODUCTS_FAILED,
  FIND_ORDERS_PRODUCT_BY_CLIENT,
  FIND_ORDERS_PRODUCT_BY_CLIENT_SUCCESS,
  FIND_ORDERS_PRODUCT_BY_CLIENT_FAILED,
}
