export const DEVICE_HANDSET = 'handset'
export const DEVICE_TV_SETUP_BOX = 'tv_setup_box'
export const DEVICE_TV = 'tv'
export const DEVICE_SET_TOP_BOX = 'set_top_box'
export const DEVICE_FREE_TIME = 'free_time'
export const DEVICE_MOBILITY = 'mobility'
export const DEVICE_SMART_DEVICES = 'smart_devices'
export const DEVICE_AUDIO = 'audio'
export const DEVICE_HOME = 'home'
export const DEVICE_PC = 'pc'

export const DEVICE_CATEGORIES_NAMES = {
  [DEVICE_HANDSET]: 'Terminales',
  [DEVICE_TV_SETUP_BOX]: 'Agile TV Box (STB)',
  [DEVICE_TV]: 'Smart TV',
  [DEVICE_SET_TOP_BOX]: 'Apple TV',
  [DEVICE_FREE_TIME]: 'Consolas',
  [DEVICE_MOBILITY]: 'Movilidad',
  [DEVICE_SMART_DEVICES]: 'Smart Devices',
  [DEVICE_AUDIO]: 'Audio',
  [DEVICE_HOME]: 'Home',
  [DEVICE_PC]: 'PC',
}
