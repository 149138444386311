import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { get } from 'lodash'
import { CircularProgress } from '@material-ui/core'

import { profiles } from 'modules/Permissions'
import { selectors as customer360SvcSelectors } from 'services/customer-360/selectors'
import { validateAgentProfile } from 'modules/vista-360/components/OrderDashboard/OrderDashboard.helpers'
import {
  maxSTCReached,
  getPaymentType,
  isConvergentSubscription,
  PREPAID_CHARACTERISTIC_NAME,
  POSTPAID_CHARACTERISTIC_NAME,
  getSubscriptionTariffId,
  getSvaSubscriptionType,
  isMobileOnlySubscription,
} from 'services/subscriptions'
import {
  getPaymentType as getTariffPaymentType,
  getCommercialName,
  selectTariffsApigeeById,
} from 'modules/tariffs/store-apigee'

import { formatMonthlyFee } from 'utils'
import {
  RIGHT_NOW,
  NEXT_DAY,
  IN_TWO_DAYS,
  NEXT_MONTH,
  CVM_FIXED_PENALTY_KEY,
  SVA_AGILETV_CODE,
} from 'modules/offers-configuration/constants'

import { applyTax, getTax } from 'services/taxes'
import { selectStcV3Flag, selectStcV3Phase2Flag, selectEnableScheduleDayOne, selectEnableScheduleTomorrow } from 'services/feature-flag/selectors'
import { isLoadingTarrifsV3, selectExcludeDiscounts } from '../selectors'

import {
  isInfiniteTariff,
  isSinfin30GbTariff,
  getMonthlyFeeTariff,
  getMonthlyPricesTaxFree,
  getActivationDate,
} from '../helpers'

import { ModalContent } from './ModalContent'
import MultiStep from './MultiStep'
import SelectorTariffsPo from './SelectorTariffsPo/SelectorTariffsPo.js'
import { TariffWarnings } from './TariffWarnings/TariffWarnings'
import { DiscountsAndBonusesSelectedContainer } from '../containers/DiscountsAndBonusesSelectedContainer'
import PostOrPreTariffStep from './PostOrPreTariffStep'
import BankAccountStep from './BankAccountStep'

import { useOrderChannelAndStatus } from '../hooks/useOrderChannelAndStatus/index'
import { initTariffsV3 } from '../actions'

export const TariffsModal = ({
  showAlertTariffDUOWarning,
  availableStc,
  orderEligibilitiesKo,
  orderConsequencesKo,
  compatibleTariffWithSva,
  feeAmount,
  selectedTariffValue,
  selectedPsIdTariff,
  date,
  filteredEnrichTariffs,
  subscription,
  onConfirm,
  permanenceChanges,
  onClose,
  onTariffSelect,
  onDateSelect,
  isPrepaidTariff,
  newPaymentType,
  onPaymentTypeChange,
  customerIban,
  isFixedReduction,
  discountChanges,
  setDiscountChanges,
  onTitleChange,
  hebeDiscountIncompatibility,
  taxNeeded,
  tariffSegment,
  subscriptionId,
  isUnsusbcribe,
}) => {
  const is1pTariff = isMobileOnlySubscription(subscription)
  const isStcV3Flag = useSelector(selectStcV3Flag)
  const isStcV3Phase2Flag = useSelector(selectStcV3Phase2Flag)
  const isScheduleDayOneFlag = useSelector(selectEnableScheduleDayOne)
  const isScheduleTomorrowFlag = useSelector(selectEnableScheduleTomorrow)
  const showV3Tariffs = (isStcV3Flag || isStcV3Phase2Flag) && !isUnsusbcribe
  const isLoyaltyAgent = validateAgentProfile([profiles.customer_loyalty.id, profiles.retention.id])
  const currentPaymentType = getPaymentType(subscription)
  const isConvergent = isConvergentSubscription(subscription)
  const notAvailableStc =
    (!availableStc || orderEligibilitiesKo || orderConsequencesKo) && !isUnsusbcribe
  const dispatch = useDispatch()
  const loadingTariffsV3 = useSelector(isLoadingTarrifsV3)
  const { orderChannel, status } = useOrderChannelAndStatus()
  const [purpose, setPurpose] = useState(null)
  const incompatibleDiscounts = useSelector(selectExcludeDiscounts)

  const getTariff = psIdOrPoIdTariff =>
    filteredEnrichTariffs.find(t => {
      const idType = showV3Tariffs ? 'poId' : 'id'

      return get(t, idType) === psIdOrPoIdTariff
    })

  const tariff = getTariff(selectedTariffValue)

  const infiniteTariff = isInfiniteTariff(tariff)
  const sinfin30GbTariff = isSinfin30GbTariff(tariff)
  const isPrepaid = isPrepaidTariff || getTariffPaymentType(tariff) === PREPAID_CHARACTERISTIC_NAME
  const shouldShowBankStep = !customerIban && newPaymentType === POSTPAID_CHARACTERISTIC_NAME
  const confirmationProps = {
    ...discountChanges,
    confirmedTariff: tariff,
    iban: customerIban,
    [CVM_FIXED_PENALTY_KEY]: permanenceChanges,
    activationStamp: getActivationDate(date),
    svaAgileTv: getSvaSubscriptionType(subscription, SVA_AGILETV_CODE),
    showV3Tariffs,
    incompatibleDiscounts,
  }

  const billingAddress = useSelector(state => customer360SvcSelectors.getBillingAddress(state))
  const postCode = get(billingAddress, 'postCode')
  const tax = useSelector(state => getTax(state, postCode))
  const totalWithTax = value => {
    return (taxNeeded && applyTax(tax, value)) || value
  }

  const dateOptions = isScheduleDayOneFlag ? [NEXT_MONTH] : []

  if (!maxSTCReached(subscription) && isScheduleTomorrowFlag) {
      dateOptions.unshift(isFixedReduction ? IN_TWO_DAYS : NEXT_DAY)
  }

  if (showV3Tariffs) {
    dateOptions.unshift(RIGHT_NOW)
  }

  const tariffEle = useSelector(selectTariffsApigeeById(getSubscriptionTariffId(subscription)))

  const hasDiscounts = tariff?.calculatedDiscounts?.discounts?.length > 0

  const onTariffSelectByPsIdOrPoId = e => {
    // if v3 value = poId, if v2 value = psId
    const psId = showV3Tariffs ? get(getTariff(e.target.value), 'psId') : e.target.value

    onTariffSelect(e, psId)
  }

  const onDateSelectBy = e => {
    onDateSelect(e)
  }

  const getTariffsOrder = porpuseSelected => {
    dispatch(
      initTariffsV3(
        orderChannel,
        status,
        tariffSegment,
        subscriptionId,
        isStcV3Phase2Flag,
        porpuseSelected,
      ),
    )
    setPurpose(porpuseSelected)
  }

  useEffect(() => {
    if (showV3Tariffs && !loadingTariffsV3 && !is1pTariff) {
      getTariffsOrder('plan-change')
    }
  }, [showV3Tariffs, is1pTariff])

  return (
    <ModalContent data-hook="modal-tariffs">
      {loadingTariffsV3 ? (
        <CircularProgress size="32px" color="secondary" />
      ) : (
        <MultiStep
          initialStep="initial"
          steps={{
            /* eslint-disable-next-line react/prop-types */
            initial: ({ goToStep }) => (
              <>
                <p>
                  Tarifa Actual: <strong>{getCommercialName(tariffEle)}</strong>
                </p>
                <p>
                  Cuota Actual: <strong>{formatMonthlyFee(feeAmount)}</strong>
                </p>
                <MultiStep
                  initialStep={isConvergent ? 'tariff' : 'postOrPre'}
                  forceStep={newPaymentType ? 'tariff' : null}
                  steps={{
                    postOrPre: () => (
                      <PostOrPreTariffStep
                        currentPaymentType={currentPaymentType}
                        onPaymentTypeChange={onPaymentTypeChange}
                        availableTariffs={filteredEnrichTariffs}
                        onTitleChange={onTitleChange}
                        stcV3Flag={isStcV3Flag}
                        getTariffsOrder={getTariffsOrder}
                      />
                    ),
                    tariff: () => (
                      <>
                        <SelectorTariffsPo
                          {...{
                            filteredEnrichTariffs,
                            taxNeeded,
                            subscription,
                            onTariffSelect: onTariffSelectByPsIdOrPoId,
                            selectedTariff: selectedTariffValue,
                            date,
                            onDateSelect: onDateSelectBy,
                            dateOptions,
                            isLoyaltyAgent,
                            isUnsusbcribe,
                            salesType: purpose,
                          }}
                        />

                        {selectedPsIdTariff && date && (
                          <>
                            <p>
                              Cuota nueva:{' '}
                              {hasDiscounts && (
                                <>
                                  <strong style={{ textDecoration: 'line-through' }}>
                                    {formatMonthlyFee(
                                      totalWithTax(getMonthlyPricesTaxFree(tariff)),
                                    )}
                                  </strong>
                                  {' - '}
                                </>
                              )}
                              <strong>
                                {formatMonthlyFee(totalWithTax(getMonthlyFeeTariff(tariff)))}
                              </strong>
                            </p>
                            <DiscountsAndBonusesSelectedContainer
                              currentTariff={tariffEle}
                              newTariffId={selectedPsIdTariff}
                              isFixedReduction={isFixedReduction}
                              forceShowConfirm
                              subscription={subscription}
                              newPaymentType={newPaymentType}
                              canAddDiscount={!isPrepaid}
                              isCompatibleTariffWithSva={compatibleTariffWithSva}
                              hebeDiscountIncompatibility={hebeDiscountIncompatibility}
                              onConfirm={data => {
                                if (shouldShowBankStep) {
                                  setDiscountChanges(data)
                                  goToStep('bankAccount')
                                } else {
                                  onConfirm({
                                    ...confirmationProps,
                                    ...data,
                                    showNewTariffDuoPrincipal: showAlertTariffDUOWarning,
                                  })
                                  onClose()
                                }
                              }}
                              infiniteTariff={infiniteTariff}
                              sinfin30GbTariff={sinfin30GbTariff}
                              disabledButtonConfirm={notAvailableStc}
                            />
                            <TariffWarnings
                              date={Date.now()}
                              subscription={subscription}
                              selectedPsIdTariff={selectedPsIdTariff}
                              taxNeeded={taxNeeded}
                            />
                          </>
                        )}
                      </>
                    ),
                  }}
                />
              </>
            ),

            bankAccount: () => (
              <BankAccountStep
                onConfirm={iban => {
                  onConfirm({ ...confirmationProps, iban })
                  onClose()
                }}
              />
            ),
          }}
        />
      )}
    </ModalContent>
  )
}

TariffsModal.propTypes = {
  showAlertTariffDUOWarning: PropTypes.bool.isRequired,
  availableStc: PropTypes.bool.isRequired,
  orderEligibilitiesKo: PropTypes.bool.isRequired,
  orderConsequencesKo: PropTypes.bool.isRequired,
  compatibleTariffWithSva: PropTypes.bool.isRequired,
  filteredEnrichTariffs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      commercial_name: PropTypes.string.isRequired,
      monthly_fee: PropTypes.string,
    }),
  ).isRequired,
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onDateSelect: PropTypes.func.isRequired,
  feeAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  selectedTariffValue: PropTypes.string.isRequired,
  selectedPsIdTariff: PropTypes.string,
  onTariffSelect: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  subscription: PropTypes.object.isRequired,
  permanenceChanges: PropTypes.any,
  newPaymentType: PropTypes.string,
  onPaymentTypeChange: PropTypes.func,
  customerIban: PropTypes.string,
  discountChanges: PropTypes.any,
  setDiscountChanges: PropTypes.func,
  isPrepaidTariff: PropTypes.bool.isRequired,
  isUnsusbcribe: PropTypes.bool,
  isFixedReduction: PropTypes.bool,
  onTitleChange: PropTypes.func,
  hebeDiscountIncompatibility: PropTypes.bool,
  taxNeeded: PropTypes.bool,
  tariffSegment: PropTypes.string,
  subscriptionId: PropTypes.string,
}
