export const config = {
  staging: false,
  production: true,
  siteRoot: 'https://prod.ovid-project.com',
  apigee_host: 'https://masmovil-prod-prod.apigee.net',
  translate_host: 'https://masmoviltranslate.azurewebsites.net/api',
  coverage_host: 'https://ovid-coverage-component.prod.k8s.masmovil.com',
  logs_host: 'https://logs.prod.ovid-project.com',
  contentfulUserId: '5b7ea1b1b3ea0a140a081b94',
  cdnAppointmentsUrlCss:
    'https://cdncomponentescitaciones-endpointpro.azureedge.net/react/site.css',
  cdnAppointments: 'https://cdncomponentescitaciones-endpointpro.azureedge.net',
  installation_appointments: 'https://masmovil.kairos365.com/',
  basicToken: 'SW5UajZXcldtRkZLcEdvVWFYVW84eXltUjNWSEYyMHU6dnd6ZUN5VlhqcW5vY1R2eg==',
  changeOwner: 'https://coqw.pro.ovid-project.com',
  sales_cluster: 'https://sales.k8s.masmovil.com',
  installations: 'https://connection-installation-api.k8s.masmovil.com',
  google_reporting: {
    enabled: true,
    projectId: 'mm-sales-pro',
    key: 'AIzaSyCWzUFkc9XrUVu7X0BGFJLDbizhh_5Xnaw',
  },
  analytics: {
    id: 'GTM-TC5PG3L',
  },
  care_cluster: 'https://care.prod.k8s.masmovil.com',
  care_private_cluster: 'https://care.private.prod.k8s.masmovil.com',
  cancelled_clients: 'https://clientes.yoigo.com/terminated_subscription_search',
  liveperson: 'http://liveengage.liveperson.net',
  vista: 'https://clientes.yoigo.com',
  masoss: 'https://masoss.masmovil.com/Login',
  atc: 'https://provisionatc.masmovil.com',
  deferred_payment: 'https://mas-care-landings.private.prod-01.k8s.masmovil.com/aplazar-facturas',
  mas_care: 'https://mas-care-prod.prod-01.k8s.masmovil.com',
  energy: 'https://manage.energygo.tools',
  televenta_login: 'https://televenta.yoigo.com/ccorder/login_form',
  newton_login: 'https://clientes.yoigo.com/newton/login_form',
  newton_renewal: 'https://clientes.yoigo.com/newton/frame?url=/ccorder',
  mas_consents: 'https://mas-consents-front.masstack.com',
  mnp: 'https://clientes.yoigo.com/mnp',
  bibe_broker: 'https://bibebroker.bibeseguro.com/ppdatossolicitud/atc',
  bibe_sale: ' https://bibebroker.bibeseguro.com/bibemovil/solicitud/renuevo/login',
  new_bibe_broker: 'https://gestionsegurosgmm.bibeservicios.com/seguromovil/atc',
  new_bibe_sale: 'https://segurosgmm.bibeservicios.com/bibemovil/solicitud/renuevo/login',
  bibe_ppi_sale: 'https://bibebroker.bibeseguro.com/ppdatossolicitudatc/login',
}
