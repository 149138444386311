import { put, select, take } from 'redux-saga/effects'

import { actions } from './actions'
import * as selectors from './selectors'
import { constants } from './constants'

export function* callProductOrdersByIdSaga(productId, filterBy) {
  yield put(actions.findProductOrders(productId, { filterBy }))
  yield take(
    action =>
      (action.type === constants.FIND_PRODUCT_ORDERS_SUCCESS ||
        action.type === constants.FIND_PRODUCT_ORDERS_FAILED) &&
      action.payload.productId === productId,
  )
  return yield select(selectors.selectErrorCodeByProductId, productId)
}
