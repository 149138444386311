import * as Yup from 'yup/lib'
import { isValidDocumentByType } from 'modules/CustomerDocument'

import { validateHasNumbers, mobileNumberRegex, isValidAge, isValidConstitutionDate } from 'utils'
import * as validations from 'modules/SharedSales/constants/validations'

export const LeadSchema = {
  account: Yup.object().shape({
    name: Yup.string().when(['$isCartera'], {
      is: cartera => !cartera,
      then: Yup.string()
        .test('name', 'El nombre no puede contener números', value => !validateHasNumbers(value))
        .required(validations.REQUIRED_FIELD_TEXT),
      otherwise: Yup.string().trim(),
    }),
    surname1: Yup.string().when(['$isCartera'], {
      is: cartera => !cartera,
      then: Yup.string()
        .test(
          'surname1',
          'El apellido no puede contener números',
          value => !validateHasNumbers(value),
        )
        .required(validations.REQUIRED_FIELD_TEXT),
      otherwise: Yup.string().trim(),
    }),
    documenttype: Yup.string()
      .test('surname2', validations.ERROR_DATA_TEXT, value => !validateHasNumbers(value))
      .required(validations.REQUIRED_FIELD_TEXT),
    surname2: Yup.string().when(['$isCartera', 'documenttype'], {
      is: (isCartera, doc) => !isCartera && doc !== 'NIE',
      then: Yup.string().required(validations.REQUIRED_FIELD_TEXT),
      otherwise: Yup.string().trim(),
    }),
    documentid: Yup.string()
      .when('documenttype', {
        is: 'NIF',
        then: Yup.string()
          .trim()
          .test('ValidateNIF', validations.ERROR_DATA_TEXT, value =>
            isValidDocumentByType(value, 'NIF'),
          )
          .required(validations.REQUIRED_FIELD_TEXT),
      })
      .when('documenttype', {
        is: 'NIE',
        then: Yup.string()
          .trim()
          .test('ValidateNIE', validations.ERROR_DATA_TEXT, value =>
            isValidDocumentByType(value, 'NIE'),
          )
          .required(validations.REQUIRED_FIELD_TEXT),
      }),
    segment: Yup.string().required(validations.REQUIRED_FIELD_TEXT),
    companyName: Yup.string().when(['$isCartera', '$isCompany'], {
      is: (isCartera, isCompany) => !isCartera && isCompany,
      then: Yup.string().required(validations.REQUIRED_FIELD_TEXT),
      otherwise: Yup.string().trim(),
    }),
    constitutionDate: Yup.string().when(['$isCompany'], {
      is: isCompany => isCompany,
      then: Yup.string()
        .test('ValidateConstitutionDate', validations.ERROR_DATA_TEXT, isValidConstitutionDate)
        .required(validations.REQUIRED_FIELD_TEXT),
      otherwise: Yup.string(),
    }),
    phoneContact: Yup.string().when(['$isMultiSim'], {
      is: isMultiSim => !isMultiSim,
      then: Yup.string()
        .matches(mobileNumberRegex, validations.ERROR_DATA_TEXT)
        .trim()
        .required(validations.REQUIRED_FIELD_TEXT),
      otherwise: Yup.string(),
    }),
    nationality: Yup.string().when(['documenttype', '$isCartera'], {
      is: (documenttype, isCartera) => documenttype === 'NIE' && !isCartera,
      then: Yup.string()
        .test('ValidateNationality', validations.ERROR_DATA_TEXT, value => value !== 'ES')
        .required(validations.REQUIRED_FIELD_TEXT),
      otherwise: Yup.string().trim(),
    }),
    birthday: Yup.string()
      .trim()
      .when(['$isCartera', '$isSubscription', '$isCrossSell', 'accountId'], {
        is: (isCartera, isSubscription, isCrossSell, accountId) =>
          (!isCartera && !isSubscription && !isCrossSell) || !accountId,
        then: Yup.string()
          .test('ValidateBirthday', validations.ERROR_DATA_TEXT, isValidAge)
          .required(validations.REQUIRED_FIELD_TEXT),
        otherwise: Yup.string().trim(),
      }),
    companyCif: Yup.string().when(['$isCompany', '$isCartera'], {
      is: (isCompany, isCartera) => !isCartera && isCompany,
      then: Yup.string()
        .trim()
        .test('ValidateCIF', validations.ERROR_DATA_TEXT, value =>
          isValidDocumentByType(value, 'CIF'),
        )
        .required(validations.REQUIRED_FIELD_TEXT),
      otherwise: Yup.string().trim(),
    }),
  }),
}
