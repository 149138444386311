import PropTypes from 'prop-types'

const installationAddress = PropTypes.shape({
  coverage: PropTypes.shape({
    Hand: PropTypes.string,
    Km: PropTypes.string,
    addressId: PropTypes.string,
    bis_duplicate: PropTypes.string,
    block: PropTypes.string,
    coverage: PropTypes.oneOfType([PropTypes.string, PropTypes.array]), // String with technology / Array with technologies
    door: PropTypes.string,
    floor: PropTypes.string,
    gescal: PropTypes.string,
    letter: PropTypes.string,
    number: PropTypes.string,
    phoneNumber: PropTypes.string,
    province: PropTypes.string,
    sessionId: PropTypes.string,
    stair: PropTypes.string,
    street: PropTypes.string,
    streetType: PropTypes.string,
    town: PropTypes.string,
    zipCode: PropTypes.string,
  }),
  coverageTechnology: PropTypes.shape({
    technology: PropTypes.array,
    speeds: PropTypes.array,
  }),
  doorAddress: PropTypes.string,
  floorAddress: PropTypes.string,
  gescal: PropTypes.string,
  getBlockAddress: PropTypes.string,
  handAddress: PropTypes.string,
  nameAddress: PropTypes.string,
  numberAddress: PropTypes.string,
  postalCode: PropTypes.string,
  province: PropTypes.string,
  stairAddress: PropTypes.string,
  state: PropTypes.string,
  streetType: PropTypes.string,
})

export const models = { installationAddress }
