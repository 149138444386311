import { subDays, format } from 'date-fns'
import { isEmpty, chain } from 'lodash'
import {
  SVA_HOMEGO_CODE,
  SVA_DOCTORGO_CUSTOMER_CODE,
  SVA_HEBE_CUSTOMER_CODE,
} from 'modules/offers-configuration/constants'
import {
  HOMEGO_SVA_SICOR_STATUS,
  HOMEGO_SVA_CADENCE_STATUS,
  HOMEGO_SVA_CADENCE_SUBSTATUS,
  HOMEGO_SVA_ERROR_MESSAGES,
} from './constants'

export const mapSvaSicorToVasContract = (sva, taxNeeded) => {
  const servicesDesc = sva.services?.reduce(
    (curr, next) => ({
      ...curr,
      description: curr.description
        ? `${curr.description} + ${next.description}`
        : next.description,
    }),
    {},
  )
  const descInstallComp = sva.installedComponents?.reduce((curr, next) => {
    const nextDescrip = `${next.code} + ${next.description}`
    return {
      ...curr,
      fullDescription: curr.fullDescription
        ? `${curr.fullDescription} / ${nextDescrip}`
        : nextDescrip,
    }
  }, {})

  return (
    sva && {
      ...sva,
      svaSicorName: servicesDesc?.description,
      installComp: sva.installedComponents?.length > 0 ? `${descInstallComp?.fullDescription}` : '',
      monthlyTotalFee: sva.nextInvoiceCfeeTotalAmount,
      monthlyNetFee: sva.nextInvoiceCfeeNetAmount,
      monthlyFee: taxNeeded ? sva.nextInvoiceCfeeNetAmount : sva.nextInvoiceCfeeTotalAmount,
      singleTotalFee: sva.nextInvoiceOfeeTotalAmount,
      singleNetFee: sva.nextInvoiceOfeeNetAmount,
      errorCode:
        sva.errorMessage === 'ALARMA NO INSTALADA'
          ? HOMEGO_SVA_ERROR_MESSAGES.ALARM_NOT_INSTALLED
          : '',
    }
  )
}

export const matchSvaSicorWithVasContract = (svaSicor, vasContracts) => {
  const svaHomeGo = vasContracts.find(sva => {
    return (
      sva.contractTypeId.includes(SVA_HOMEGO_CODE) &&
      (svaSicor?.workOrderStatus === HOMEGO_SVA_SICOR_STATUS.INSTALLED ||
        svaSicor?.workflowState === 'END')
    )
  })
  return svaHomeGo && { ...svaHomeGo, ...(svaSicor || {}) }
}

export const replaceVasHomeGowithSicor = (vasContracts, svaHomeGo) => {
  const vasWihoutHomeGo = vasContracts.filter(sva => !sva.contractTypeId.includes(SVA_HOMEGO_CODE))
  if (svaHomeGo) {
    vasWihoutHomeGo.push(svaHomeGo)
  }
  return vasWihoutHomeGo
}

export const getSvaServiceRequests = sva => {
  if (isEmpty(sva.serviceRequest)) return ''

  const allServiceRequests = sva.serviceRequest.reduce((curr, next) => {
    return {
      svRequestTp: `${curr?.svRequestTp} - ${next.svRequestTp}`,
    }
  })
  return allServiceRequests?.svRequestTp
}

export const isHomeGoOrderCancelAllowedSfid = sfid => /^(BL|BO|CNN|GS|MB|MKDX|SRV|YC).+/.test(sfid)

export const fillSvaHomeGoRegisters = registers => {
  const getDescription = reg => {
    if (reg.status === HOMEGO_SVA_CADENCE_SUBSTATUS.CANCELLED) {
      return `Cancelación del servicio Home GO por agente - ${reg.sfid || ''}`
    }
    if (
      reg.workflowState === HOMEGO_SVA_CADENCE_STATUS.SBN_READY_TO_INSTALL &&
      reg.status === HOMEGO_SVA_CADENCE_SUBSTATUS.INCIDENCE &&
      reg.errorMessage === HOMEGO_SVA_ERROR_MESSAGES.ALARM_NOT_INSTALLED
    ) {
      return 'Cancelación del servicio Home GO por motivos técnicos - SICOR'
    }
    return ''
  }

  if (registers?.length > 0) {
    return registers.map(reg => ({
      ...reg,
      description: getDescription(reg),
    }))
  }
  return []
}

const mapSvaCodeToName = svaCode => {
  switch (svaCode) {
    case SVA_DOCTORGO_CUSTOMER_CODE:
      return 'Doctor Go'
    case SVA_HEBE_CUSTOMER_CODE:
      return 'HEBE'
    default:
      return svaCode
  }
}

export const mapMasServicesToCustomerSvas = svas =>
  svas.map(sva => ({
    msisdn: sva.msisdn,
    code: sva.type === 'doctor' ? SVA_DOCTORGO_CUSTOMER_CODE : SVA_HEBE_CUSTOMER_CODE,
    endDate: sva.status === 'inactive' ? format(subDays(new Date(), 2), 'yyyy-MM-dd') : null,
  }))

export const mapCustomerSvas = svas =>
  chain(svas)
    .map(sva => {
      const { characteristics, 'valid-for': validFor } = sva.attributes

      if (!characteristics) {
        return null
      }

      return {
        level: characteristics['applicability-level'],
        code: characteristics['billing-item-name'],
        name: mapSvaCodeToName(characteristics['billing-item-name']),
        monthlyFee: characteristics['monthly-fee'],
        startDate: validFor['start-datetime'],
        endDate: validFor['end-datetime'],
        subscriptionId: sva.subscriptionId,
      }
    })
    .compact()
    .value()
