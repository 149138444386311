import { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { ContainerTitle } from 'components'
import { Modal } from '../Modal'
import { AddressForm } from '../AddressForm'

const styles = () => ({
  input: {
    color: 'black',
    textTransform: 'capitalize',
  },
})

class ClientInfo extends Component {
  handleClose = () => {
    // eslint-disable-next-line no-alert
    alert('Close and Exit App')
  }

  render() {
    return (
      <>
        <ContainerTitle>DIRECCIÓN NUEVA</ContainerTitle>
        <AddressForm
          next={this.props.next}
          previous={this.props.previous}
          tokenGenerated={this.props.tokenGenerated}
          gotCoverageData={this.props.onReceiveCoverageData}
        />
        <Modal
          // open={get(this.props.debt, 'status', 'no-debt') !== 'no-debt'}
          open={false}
          title="Aviso Importante!"
          message="Operacion ha sido cancelada. Servicio no disponible!"
          cancelBtn="Salir"
        />
      </>
    )
  }
}

ClientInfo.propTypes = {
  next: PropTypes.func,
  previous: PropTypes.func,
  tokenGenerated: PropTypes.string,
  onReceiveCoverageData: PropTypes.func,
}

export default withStyles(styles, { withTheme: true })(ClientInfo)
