import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import { getMsisdn } from 'services/subscriptions'

import { disableSubscriptionDiscounts } from '../../actions'

import DiscountsDisable from './DiscountsDisable'
import { ModalContent } from '../ModalContent'

const DiscountsDisableModal = ({ subscription, discounts, discountIdQueryParam, onClose }) => {
  const dispatch = useDispatch()

  const onDisableSubscriptionDiscounts = data =>
    dispatch(
      disableSubscriptionDiscounts(
        Number(getMsisdn(subscription)),
        subscription?.subscription_id,
        data,
      ),
    )

  return (
    <ModalContent data-hook="modal-discounts-disable">
      <DiscountsDisable
        subscription={subscription}
        discounts={discounts}
        discountId={Number(discountIdQueryParam)}
        onConfirm={data => {
          onDisableSubscriptionDiscounts(data)
          onClose()
        }}
        onClose={onClose}
      />
    </ModalContent>
  )
}

DiscountsDisableModal.propTypes = {
  subscription: PropTypes.object.isRequired,
  discounts: PropTypes.array.isRequired,
  discountIdQueryParam: PropTypes.string,
  onClose: PropTypes.func.isRequired,
}

DiscountsDisableModal.defaultProps = {
  subscription: {},
  discounts: [],
  discountIdQueryParam: null,
}

export default DiscountsDisableModal
