import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Box } from '@material-ui/core'

const useStyles = makeStyles({
  'modal-actions': {
    '&& button + button': {
      marginLeft: '24px',
    },
  },
})

export const ModalActions = ({ children, className = '', ...props }) => {
  const classes = useStyles()

  return (
    <Box mt="28px" className={`${className} ${classes['modal-actions']}`} {...props}>
      {children}
    </Box>
  )
}

ModalActions.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}

ModalActions.defaultProps = {
  className: '',
}
