import { useState, useMemo, useEffect } from 'react'
import { head, flatten } from 'lodash'
import {
  FIXED_PARTIAL_CANCELLATION,
  MOBILE_PARTIAL_CANCELLATION,
  ADDITIONAL_LINE_CATEGORY_2P,
  ADDITIONAL_LINE_CATEGORY_EL_FIJO,
  warningMessages,
} from 'modules/orders/constants'

export const useCancelOrders = (orders, isElFijoCancellation, isCancellationFlowTogether) => {
  const [replaceableOrder, setReplaceableOrder] = useState(null)

  const [selectedOrders, setSelectedOrders] = useState([])
  const [areAllOrdersSelected, setAreAllOrdersSelected] = useState(false)

  const [warningMessage, setWarningMessage] = useState('')

  const isAllowedReplaceable = useMemo(() => {
    return !!selectedOrders.find(
      order => order.main && order.lines.length === 1 && order.lines[0].category === 'mobile',
    )
  }, [selectedOrders])

  const replaceableOrderOptions = useMemo(() => {
    return orders.filter(order => !order.main && order.isCancellable)
  }, [orders])

  const onSetReplaceableOrder = order => {
    setReplaceableOrder(order)

    setSelectedOrders(selectedOrders.filter(selectedOrder => selectedOrder.id !== order.id))
  }

  useEffect(() => {
    if (!isAllowedReplaceable) {
      setReplaceableOrder(null)
    }
  }, [isAllowedReplaceable])

  const orderToReplace = useMemo(() => {
    if (!replaceableOrder) {
      return null
    }

    const mainOrder = selectedOrders.find(selectedOrder => selectedOrder.main)

    return {
      cancelOrder: mainOrder,
      replaceableOrder,
    }
  }, [selectedOrders, replaceableOrder])

  const mapOrderToSelectedOrder = (order, lines) => ({
    id: order.id,
    main: order.main,
    lines: lines.filter(line => line.isCancellable),
    penalty: order.penalty,
    mainAdditionalLineCategory: order.mainAdditionalLineCategory,
  })

  const selectOrder = (order, selectedLines) => {
    if (order) {
      setSelectedOrders([...selectedOrders, mapOrderToSelectedOrder(order, selectedLines)])
    }
  }

  const handleOrderSelected = (order, checked) => {
    if (checked) {
      if (selectedOrders.length === orders.filter(order => order.isCancellable).length - 1) {
        setAreAllOrdersSelected(true)
      }

      selectOrder(order, order.lines)
    } else {
      setSelectedOrders(selectedOrders.filter(selectedOrder => selectedOrder.id !== order.id))
      setAreAllOrdersSelected(false)
    }
  }

  const deselectOrder = orderId => {
    setSelectedOrders(selectedOrders.filter(selectedOrder => selectedOrder.id !== orderId))
  }

  const findOrderById = (ordersCollection, orderId) =>
    ordersCollection.find(order => order.id === orderId)

  const deselectOrderLine = (order, lineId) => {
    const newOrders = selectedOrders.slice()
    const lineOrder = findOrderById(newOrders, order.id)
    const isLastOrderLine = lineOrder?.lines?.length === 1

    if (isLastOrderLine) {
      deselectOrder(lineOrder.id)
    } else {
      lineOrder.lines = lineOrder.lines.filter(selectedLine => selectedLine.id !== lineId)

      setSelectedOrders([...newOrders])
    }
    if (areAllOrdersSelected) {
      setAreAllOrdersSelected(false)
    }
  }

  const selectOrderLine = (order, line) => {
    const newOrders = selectedOrders.slice()
    const selectedLineOrder = findOrderById(newOrders, order.id)

    if (
      flatten(selectedOrders.map(order => order.lines)).length ===
      flatten(orders.map(order => order.lines)).filter(line => line.isCancellable).length - 1
    ) {
      setAreAllOrdersSelected(true)
    }

    if (selectedLineOrder) {
      selectedLineOrder.lines.push(line)

      setSelectedOrders([...newOrders])
    } else {
      selectOrder(order, [line])
    }
  }

  const handleOrderLineSelectionChange = (order, line, checked) => {
    if (checked) {
      selectOrderLine(order, line)
    } else {
      deselectOrderLine(order, line.id)
    }
  }

  const isOrderSelected = orderId =>
    !!selectedOrders.find(selectedOrder => selectedOrder.id === orderId)

  const isOrderLineSelected = (orderId, lineId) => {
    let isLineSelected = false
    const lineOrder = selectedOrders.find(selectedOrder => selectedOrder.id === orderId)
    if (lineOrder) {
      isLineSelected = !!lineOrder.lines.find(line => line.id === lineId)
    }
    return isLineSelected
  }

  const findChildOrders = (ordersList, parentOrderId) =>
    ordersList.filter(order => order.id !== parentOrderId)

  const findParentOrders = ordersList => ordersList.filter(order => order.main)

  const removeChildOrders = ordersList => ordersList.filter(order => order.main)

  const findOrdersByLineType = (ordersList, lineType) =>
    ordersList.filter(order => !order.main && order.lines[0].lineType === lineType)

  const findExtraLineOrders = ordersList => findOrdersByLineType(ordersList, 'extra_line')

  const findAdditionalLineOrders = ordersList => findOrdersByLineType(ordersList, 'additional_line')

  const getConvergentCancellationOnlyWarningMessage = order => {
    if (order.partialOperation === null) {
      return warningMessages.cancel3p
    }

    if (order.partialOperation === FIXED_PARTIAL_CANCELLATION) {
      return warningMessages.partialCancellationFixed
    }

    return warningMessages.partialCancellationMobile
  }

  const getPartialMobileCancellationWarningMessage = (
    extraLineOrdersToDelete,
    originalExtraLineOrders,
    additionalLineOrdersToDelete,
    originalAdditionalLineOrders,
    mainOrder,
  ) => {
    const isCancellingAllExtraLines =
      !!extraLineOrdersToDelete && extraLineOrdersToDelete.length === originalExtraLineOrders.length
    const isCancellingAllAdditionalLines =
      !!additionalLineOrdersToDelete &&
      additionalLineOrdersToDelete.length === originalAdditionalLineOrders.length
    const isCancellingAllMobileLines =
      (isCancellingAllAdditionalLines && isCancellingAllExtraLines) || mainOrder?.cancelRelated

    if (isCancellingAllMobileLines) {
      return warningMessages.productChange
    }
    if (!!originalAdditionalLineOrders && !originalExtraLineOrders) {
      if (isCancellingAllAdditionalLines) {
        return warningMessages.productChange
      }
      return warningMessages.partialCancellationMobileWithoutExtra
    }
    if (isCancellingAllExtraLines && !originalAdditionalLineOrders) {
      return warningMessages.productChange
    }
    if (!originalAdditionalLineOrders && !originalExtraLineOrders) {
      return warningMessages.productChange
    }
    return warningMessages.partialCancellationMobile
  }

  const separateOrdersToDeleteByType = ordersToDelete => {
    let mainOrder = null
    let extraLineOrders = null
    let additionalLineOrders = null

    mainOrder = head(findParentOrders(ordersToDelete))
    extraLineOrders = findExtraLineOrders(ordersToDelete)
    additionalLineOrders = findAdditionalLineOrders(ordersToDelete)

    return {
      mainOrder,
      extraLineOrders,
      additionalLineOrders,
    }
  }

  const separateOriginalOrdersByType = originalOrders => {
    let originalExtraLineOrders = null
    let originalAdditionalLineOrders = null
    originalExtraLineOrders = findExtraLineOrders(originalOrders)
    originalAdditionalLineOrders = findAdditionalLineOrders(originalOrders)

    return { originalExtraLineOrders, originalAdditionalLineOrders }
  }

  const getCorrectWarningMessage = (
    mainOrder,
    allOrdersWillBeCanceled,
    isCancelling3pOnly,
    isCancellingMobileLinesOnly,
    extraLineOrders,
    originalExtraLineOrders,
    additionalLineOrders,
    originalAdditionalLineOrders,
  ) => {
    if (
      mainOrder?.mainAdditionalLineCategory === ADDITIONAL_LINE_CATEGORY_2P ||
      mainOrder?.mainAdditionalLineCategory === ADDITIONAL_LINE_CATEGORY_EL_FIJO
    ) {
      return warningMessages.cancel2p
    }

    if (isElFijoCancellation || allOrdersWillBeCanceled) {
      return warningMessages.cancelAllLines
    }

    if (isCancelling3pOnly) {
      return getConvergentCancellationOnlyWarningMessage(mainOrder)
    }
    if (isCancellingMobileLinesOnly) {
      return warningMessages.cancelAdditionalLine
    }

    if (mainOrder?.partialOperation === MOBILE_PARTIAL_CANCELLATION) {
      return getPartialMobileCancellationWarningMessage(
        extraLineOrders,
        originalExtraLineOrders,
        additionalLineOrders,
        originalAdditionalLineOrders,
        mainOrder,
      )
    }

    if (mainOrder?.partialOperation === FIXED_PARTIAL_CANCELLATION) {
      return warningMessages.partialCancellationFixedAndAdditional
    }

    return ''
  }

  const generateNewWarningMessage = ordersToDelete => {
    if (ordersToDelete?.length === 0) {
      return ''
    }

    const { mainOrder, extraLineOrders, additionalLineOrders } = separateOrdersToDeleteByType(
      ordersToDelete,
    )
    const { originalExtraLineOrders, originalAdditionalLineOrders } = separateOriginalOrdersByType(
      orders,
    )

    const isCancellingOneOrMoreMobileLines = !!(
      additionalLineOrders ||
      extraLineOrders ||
      mainOrder?.cancelRelated
    )
    const isFullyCancelling3p = !!(mainOrder?.partialOperation === null)
    const isCancelling3pOnly =
      !!mainOrder && ordersToDelete.length === 1 && !mainOrder.cancelRelated
    const isCancellingMobileLinesOnly = !mainOrder && isCancellingOneOrMoreMobileLines
    const allOrdersWillBeCanceled = !!(mainOrder?.cancelRelated && isFullyCancelling3p)

    return getCorrectWarningMessage(
      mainOrder,
      allOrdersWillBeCanceled,
      isCancelling3pOnly,
      isCancellingMobileLinesOnly,
      extraLineOrders,
      originalExtraLineOrders,
      additionalLineOrders,
      originalAdditionalLineOrders,
    )
  }

  const removeRelatedOrdersIfAllAreSelected = (ordersToDelete, parentOrders) => {
    if (replaceableOrder) {
      return ordersToDelete
    }

    let filteredOrdersToDelete = ordersToDelete

    if (!isCancellationFlowTogether) {
      const mainOrder = head(findParentOrders(orders))
      const mainOrderLines = mainOrder.lines.filter(line => line.isCancellable)

      parentOrders.forEach(parentOrder => {
        const parentOrderId = parentOrder.id
        const childOrders = findChildOrders(orders, parentOrderId).filter(
          order => order.isCancellable,
        )
        const selectedChildOrders = findChildOrders(selectedOrders, parentOrderId)

        if (
          selectedChildOrders.length > 0 &&
          childOrders.length === selectedChildOrders.length &&
          parentOrder.lines?.length === mainOrderLines.length
        ) {
          filteredOrdersToDelete = removeChildOrders(ordersToDelete)

          filteredOrdersToDelete.find(
            orderToDelete => orderToDelete.id === parentOrderId,
          ).cancelRelated = true
        }
      })
    }

    return filteredOrdersToDelete
  }

  const getPartialOperationValue = orderToDelete => {
    const cancelFixed = !!orderToDelete.lines?.find(line => line.category === 'fixed')
    const cancelMobile = !!orderToDelete.lines?.find(line => line.category === 'mobile')

    let partialOperation = null
    if (orderToDelete.main) {
      if (cancelFixed && !cancelMobile) {
        partialOperation = FIXED_PARTIAL_CANCELLATION
      }
      if (cancelMobile && !cancelFixed) {
        partialOperation = MOBILE_PARTIAL_CANCELLATION
      }
    }

    return partialOperation
  }

  const ordersToDelete = useMemo(() => {
    let deleteOrders = selectedOrders
      .map(selectedOrder => ({
        ...selectedOrder,
        cancelRelated: false,
      }))
      .filter(selectedOrder => !replaceableOrder || !selectedOrder.main)

    const parentOrders = findParentOrders(selectedOrders)
    deleteOrders = removeRelatedOrdersIfAllAreSelected(deleteOrders, parentOrders).map(
      orderToDelete => {
        return {
          id: orderToDelete.id,
          main: orderToDelete.main,
          cancelRelated: orderToDelete.cancelRelated,
          partialOperation: getPartialOperationValue(orderToDelete),
          lines: orderToDelete.lines,
          mainAdditionalLineCategory: orderToDelete.mainAdditionalLineCategory,
        }
      },
    )
    setWarningMessage(generateNewWarningMessage(deleteOrders))

    return deleteOrders
  }, [selectedOrders, replaceableOrder])

  const handleAllOrdersSelected = () => {
    if (areAllOrdersSelected) {
      setSelectedOrders([])
      setAreAllOrdersSelected(false)
    } else {
      setSelectedOrders(
        orders
          .filter(order => order.isCancellable)
          .map(order => mapOrderToSelectedOrder(order, order.lines)),
      )
      setAreAllOrdersSelected(true)
    }
  }

  const checkOrderPartiallySelected = order => {
    let isOrderPartiallySelected = false
    const selectedOrder = findOrderById(selectedOrders, order.id)

    if (selectedOrder) {
      const numberOfLinesSelected = selectedOrder.lines.length
      isOrderPartiallySelected =
        numberOfLinesSelected > 0 && numberOfLinesSelected < order.lines.length
    }

    return isOrderPartiallySelected
  }

  return {
    selectedOrders,
    handleOrderSelected,
    handleOrderLineSelectionChange,
    isOrderSelected,
    isOrderLineSelected,
    handleAllOrdersSelected,
    areAllOrdersSelected,
    isAllowedReplaceable,
    replaceableOrder,
    onSetReplaceableOrder,
    replaceableOrderOptions,
    ordersToDelete,
    orderToReplace,
    checkOrderPartiallySelected,
    warningMessage,
  }
}
