import PropTypes from 'prop-types'
import { Grid, Box, Divider } from '@material-ui/core'
import { CardRow, CardTitle, CardRowLabel, CardRowValue } from 'components'

const LineInformation = ({ line, isElFijoCancellation }) => {
  const isFixedLine = line.category === 'fixed'

  return (
    <div key={line.id} data-hook={`line-information-${line.id}`}>
      <CardRow>
        <CardTitle>{isFixedLine ? 'Línea fija' : 'Línea móvil'}</CardTitle>
        <Grid container>
          <Grid item xs={4}>
            <div>
              <CardRowLabel>{isFixedLine ? 'Velocidad de internet' : 'Tarifa'} :</CardRowLabel>
              <CardRowValue>
                {' '}
                {isElFijoCancellation && isFixedLine ? 'N/A' : line.tariffName}
              </CardRowValue>
            </div>
          </Grid>
          <Grid item xs={4} p="0 30px">
            <Divider orientation="vertical" />
            <div>
              <CardRowLabel>Número: </CardRowLabel>
              <CardRowValue>{line.id}</CardRowValue>
            </div>
            <Divider orientation="vertical" />
          </Grid>

          <Grid item xs={4}>
            <CardRowLabel>Tipo: </CardRowLabel>
            <CardRowValue>{line.type}</CardRowValue>
          </Grid>
        </Grid>
      </CardRow>
      <Box mx="20px">
        <Divider />
      </Box>
    </div>
  )
}

LineInformation.propTypes = {
  line: PropTypes.objectOf(PropTypes.any).isRequired,
  isElFijoCancellation: PropTypes.bool,
}

export default LineInformation
