import PropTypes from 'prop-types'

import { MenuItem, Select, FormControl, InputLabel } from '@material-ui/core'
import { map } from 'lodash'

const CallReasons = ({ selectedOption, onMenuItemChange, defaultOption, options }) => {
  return (
    <FormControl variant="outlined" style={{ width: '100%' }} data-hook="call-reason">
      <InputLabel id="call-reason-label">{defaultOption}</InputLabel>
      <Select
        labelId="call-reason-label"
        label={defaultOption}
        value={selectedOption}
        displayEmpty
        onChange={event => onMenuItemChange(event.target.value)}>
        <MenuItem value="">
          <em>{defaultOption}</em>
        </MenuItem>
        {map(options, (item, index) => (
          <MenuItem key={`${item.value}${index}`} value={item.text}>
            {item.text}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

CallReasons.propTypes = {
  selectedOption: PropTypes.string,
  onMenuItemChange: PropTypes.func,
  defaultOption: PropTypes.string,
  options: PropTypes.array,
}

export default CallReasons
