import { actionTypes } from 'modules/typifications/constants'

export const addDataTypifications = token => ({
  type: actionTypes.DATA_TYPIFICATIONS_INIT,
  payload: {
    token,
  },
})

export const addDataTypificationsSuccess = ({
  uuid,
  channel,
  customer_id,
  msisdns,
  conversation_id,
  initial_fee,
  sfid,
  area,
  brand,
  campaignByEmail,
  isSecurizedToken,
  error
}) => ({
  type: actionTypes.DATA_TYPIFICATIONS_SUCCESS,
  payload: {
    uuid,
    channel,
    customer_id,
    msisdns,
    conversation_id,
    initial_fee,
    sfid,
    area,
    brand,
    campaignByEmail,
    isSecurizedToken,
    error
  },
})

export const addDataTypificationsFailed = error => ({
  type: actionTypes.DATA_TYPIFICATIONS_FAILED,
  payload: error,
})
