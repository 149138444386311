import { createStructuredSelector, createSelector } from 'reselect'
import {
  selectOnlyMobileFlag,
  selectLandline2pFlag,
  selectLeadsPosFlag,
  selectPreSaleFlag,
} from 'services/feature-flag/selectors'
import { selectEnergyIsAllowed } from 'modules/energy/store/energy.selectors'
import { selectQueryParams } from 'modules/Router'
import { selectIsCarteraQueryParam } from 'modules/Router/store/index'
import {
  selectCustomerSegment,
  selectSegment,
  getIdentificationId,
  getIdentificationType,
} from 'services/customer-360/selectors'
import { selectIsCustomerCanceled } from 'modules/CustomerInfo/store/customerInfo.selectors'

import { selectAllAgentProfiles } from 'modules/Auth'
import { selectIsD2D } from 'modules/NewClientSales/store'
import { selectCanIDO, permissions } from 'modules/Permissions'
import {
  D2D_ADDITIONAL_ENERGY_ACCESS,
  D2D_ADDITIONAL_ENERGY_SALE_ACCESS,
  REGEX_SFID_ENERGY_SALE_ACCESS,
} from 'modules/Permissions/constants'

const selectIsElFijoFut = createSelector(selectAllAgentProfiles, profile =>
  profile.includes('EL_FIJO_FUT'),
)

export const selectSaleSegment = createSelector(selectSegment, segment => {
  if (!segment) return undefined
  return segment.includes('Pro') ? 'pro' : 'residential'
})

const selectCarteraIdentification = createSelector(
  getIdentificationId,
  getIdentificationType,
  (docNumber, docType) => ({ docNumber, docType }),
)

export const FlowChooserSelector = createStructuredSelector({
  selectedQueryParams: selectQueryParams,
  onlyMobileFlag: selectOnlyMobileFlag,
  landline2pFlag: selectLandline2pFlag,
  energyIsAllowed: selectEnergyIsAllowed,
  isCartera: selectIsCarteraQueryParam,
  isElFijoFut: selectIsElFijoFut,
  isD2D: selectIsD2D,
  carteraSegment: selectCustomerSegment,
  isCustomerCanceled: selectIsCustomerCanceled,
  customerSaleSegment: selectSaleSegment,
  carteraIdentification: selectCarteraIdentification,
  docNumber: getIdentificationId,
  docType: getIdentificationType,
  canEnergySale: selectCanIDO(
    permissions.cvm_energy_sale.id,
    [...D2D_ADDITIONAL_ENERGY_ACCESS, ...D2D_ADDITIONAL_ENERGY_SALE_ACCESS],
    REGEX_SFID_ENERGY_SALE_ACCESS,
  ),
  canAgentAccessPresale: selectPreSaleFlag,
  leadPosAllowed: selectLeadsPosFlag,
})
