import PropTypes from 'prop-types'

import { Box, Divider, Card, CardContent } from '@material-ui/core'

import { TitleSidebar, TextSidebar } from 'components/ui'

import OffersFeeSidebar from './OffersFeeSidebar'

export const OffersSidebar = ({
  mainOffer,
  monthlyPayments,
  extraLines,
  packages,
  isPro,
  isSubscription,
  isMultiSim,
}) => (
  <Box display="flex" justifyContent="center" data-hook="sideBar-offers">
    <Card style={{ width: '90%', borderRadius: '10px', margin: '8px 0' }}>
      <CardContent>
        {!isSubscription && !isMultiSim && (
          <div>
            <TitleSidebar>Tarifa Cabecera</TitleSidebar>
            <TextSidebar dataHook="sideBar-mainoffer">{mainOffer.name || '-'}</TextSidebar>
            {packages.map(p => (
              <TextSidebar dataHook={`sideBar-${p.psId}`} key={p.name}>
                {p.name}
              </TextSidebar>
            ))}
          </div>
        )}
        {!isMultiSim &&
          extraLines.map(elem => (
            <div key={`offerExtra-${elem.index}`}>
              <TitleSidebar>Móvil {elem.index + (isSubscription ? 1 : 2)}</TitleSidebar>
              <TextSidebar dataHook={`sideBar-${elem.index}`}>
                {elem.name || 'Selecciona la tarifa'}
              </TextSidebar>
              {elem.packages.map(p => (
                <TextSidebar key={p.psId}>{p.name}</TextSidebar>
              ))}
            </div>
          ))}

        {!isMultiSim && <Divider />}

        <div className="m-t-12">
          <OffersFeeSidebar isPro={isPro} {...{ monthlyPayments }} />
        </div>
      </CardContent>
    </Card>
  </Box>
)

OffersSidebar.propTypes = {
  // Array of commercial bundles applied to the offer
  packages: PropTypes.array,
  mainOffer: PropTypes.object,
  monthlyPayments: PropTypes.array,
  extraLines: PropTypes.array,
  isPro: PropTypes.bool,
  isSubscription: PropTypes.bool,
  isMultiSim: PropTypes.bool,
}
