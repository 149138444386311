import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { Box, Tabs as TabsBase } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import Tab from './Tab'
import TabSmall from './TabSmall'

const useStyles = makeStyles(theme => ({
  tabs: {
    minHeight: ({ small }) => (small ? 'auto' : ''),

    '& .indicator': {
      height: '3px',
      backgroundColor: theme.palette.global.gray_dark,
    },
  },
}))
/**
 * Component Tabs.
 * Do not use it alone, instead, try to use TabsContainer
 *
 * API Vars (See Tabs.propTypes)
 * @param tabsInfo
 * @param val
 * @param onSelectChange
 *
 * Internal Vars (Ignore them)
 * @param onChange
 * @param value
 */

function Tabs({ tabsInfo, val, onSelectChange, small, isPosOrMasproximo, ...rest }) {
  const classes = useStyles({ small })

  const TabComponent = small ? TabSmall : Tab

  const defaultTab = get(tabsInfo, '[0].disabled')
    ? get(tabsInfo, '[1].val')
    : get(tabsInfo, '[0].val')

  const [value, setValue] = useState(defaultTab)

  const onChange = (event, newValue) => {
    if (onSelectChange) {
      onSelectChange(event, newValue)
    }

    setValue(newValue)
  }

  useEffect(() => {
    if (val) {
      setValue(val)
    }
  }, [val])

  return (
    <Box display="flex" alignItems="center" {...rest}>
      <TabsBase
        className={classes.tabs}
        classes={{ indicator: 'indicator' }}
        value={value}
        onChange={onChange}>
        {tabsInfo &&
          tabsInfo.map((tabInfo, idx) => (
            <TabComponent
              data-hook={`${tabInfo.data_hook}`}
              key={`${idx + (tabInfo.val || tabInfo.value)}`}
              label={tabInfo.label}
              value={tabInfo.val || tabInfo.value}
              disabled={tabInfo.disabled}
            />
          ))}
      </TabsBase>
    </Box>
  )
}

Tabs.propTypes = {
  tabsInfo: PropTypes.arrayOf(
    PropTypes.shape({
      // TODO: deprecated, use value
      val: PropTypes.string,
      value: PropTypes.string,
      label: PropTypes.string,
      disabled: PropTypes.bool,
    }),
  ),
  // TODO: deprecated, use value
  val: PropTypes.string,
  value: PropTypes.string,
  onSelectChange: PropTypes.func,
  small: PropTypes.bool,
  onChange: PropTypes.func,
  isPosOrMasproximo: PropTypes.bool,
}

export default Tabs
