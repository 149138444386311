import { select, takeLatest, call } from 'redux-saga/effects'

import { d2dActionTypes, orderActionTypes } from '../actions'
import { notifyD2DOrder, notifyD2DCancel } from '../../services/d2d.service'
import { selectD2DData, selectIsD2D } from '../selectors'

export function* notifyCancelD2DSaga() {
  const isD2D = yield select(selectIsD2D)

  if (isD2D) {
    notifyD2DCancel()
  }
}

export function* notifyOrderFinishedSaga() {
  const isD2D = yield select(selectIsD2D)

  if (isD2D) {
    const data = yield select(selectD2DData)
    yield call(notifyD2DOrder, data)
  }
}

export function* notifyUserLogoutSaga() {
  const isD2D = yield select(selectIsD2D)
  if (isD2D) {
    yield call(notifyD2DOrder, { action: 'LOGOUT' })
  }
}

export function* notifyHomeSaga() {
  const isD2D = yield select(selectIsD2D)
  if (isD2D) {
    yield call(notifyD2DOrder, { action: 'HOME' })
  }
}

export function* watchNotifyD2DCancel() {
  yield takeLatest(d2dActionTypes.NOTIFY_D2D_CANCEL, notifyCancelD2DSaga)
}

export function* watchNotifyD2DOrderFinished() {
  yield takeLatest(d2dActionTypes.NOTIFY_D2D_ORDER_FINISHED, notifyOrderFinishedSaga)
}

export function* watchNotifyUserLogout() {
  yield takeLatest(d2dActionTypes.NOTIFY_D2D_USER_LOGOUT, notifyUserLogoutSaga)
}

export function* watchNotifyHome() {
  yield takeLatest(d2dActionTypes.NOTIFY_D2D_HOME, notifyHomeSaga)
}

export function* watchOrderFailedD2D() {
  // Order creation failed (before polling)
  yield takeLatest(orderActionTypes.CREATE_NEW_ORDER_ERROR, notifyOrderFinishedSaga)
}
