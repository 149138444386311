import { actionTypes } from 'modules/typifications/constants'

export const getRegisterTypifications = (customerId, channel, campaignByEmail) => ({
  type: actionTypes.GET_REGISTER_TYPIFICATIONS_INIT,
  payload: { customerId, channel, campaignByEmail },
})

export const getRegisterTypificationsSuccess = registers => ({
  type: actionTypes.GET_REGISTER_TYPIFICATIONS_SUCCESS,
  payload: registers,
})

export const getRegisterTypificationsFailed = error => ({
  type: actionTypes.GET_REGISTER_TYPIFICATIONS_FAILED,
  payload: error,
})
