import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useFormikContext } from 'formik'
import {
  setAddressAction,
  setBasketAccountData,
  setIbanAction,
  setSelectedAddressTypeAction,
  setSaleSegmentAction,
  setCustomerInformation,
} from '../../../store/actions/index'
import { storeSelectors } from './useSetCustomerInfo.selectors'

export function useSetCustomerInfo(shouldUpdate) {
  const {
    customerData,
    customerAddress,
    customerIban,
    isSubscription,
    isCrossSell,
    segment,
    accountId,
    billingAccountId,
    documentId,
    subscriptionId,
  } = useSelector(storeSelectors)
  const dispatch = useDispatch()
  const { setFieldValue } = useFormikContext()

  useEffect(() => {
    if (shouldUpdate && !documentId) {
      setFieldValue('account', customerData)
      setFieldValue('billing', {
        ibanNumber: customerIban,
        selectedAddressType: 'otherAddress',
        otherAddress: customerAddress,
        billingAccountId,
      })
      if (isCrossSell) {
        setFieldValue('parentAgreementId', subscriptionId?.toString())
      }

      dispatch(setBasketAccountData(customerData))
      dispatch(setAddressAction({ ...customerAddress, addressType: 'serviceAddress' }))
      dispatch(setAddressAction({ ...customerAddress, addressType: 'billing' }))
      dispatch(setSelectedAddressTypeAction({ type: 'billing', value: 'otherAddress' }))
      dispatch(setIbanAction({ ibanNumber: customerIban }))
      dispatch(
        setCustomerInformation({
          accountId,
          billingId: billingAccountId,
          segment: customerData.segment,
        }),
      )

      if (segment && (!!isSubscription || !!isCrossSell)) {
        dispatch(setSaleSegmentAction(segment))
      }
    }
  }, [shouldUpdate])

  return { customerData, customerSaleSegment: segment, billingAccountId, accountId }
}
