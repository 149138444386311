import { createStructuredSelector } from 'reselect'
import { selectDuoLimitLoading, selectDuoLimitHasError } from 'modules/CustomerOrders'

import {
  selectSaleInformation,
  selectSubscriptionInformation,
  selectHasExtraLines,
} from '../../../store/selectors'

export const SubscriptionSaleSelectors = createStructuredSelector({
  subscriptionInfo: selectSubscriptionInformation,
  saleInfo: selectSaleInformation,
  hasExtraLines: selectHasExtraLines,
  isDuoLoading: selectDuoLimitLoading,
  hasDuoError: selectDuoLimitHasError,
})
