import PropTypes from 'prop-types'
import { get } from 'lodash'

import { Divider, Icon } from '@material-ui/core'
import { ICONS } from 'utils/icons'
import { FormDatePicker, FormDateProvider, ButtonLink } from 'components/ui'
import CheckboxTree from 'react-checkbox-tree'

import 'react-checkbox-tree/lib/react-checkbox-tree.css'
import styled from 'styled-components'

export const FiltersSidebar = styled(
  ({
    dataTree,
    testState,
    setState,
    onChangeStartDate,
    onChangeEndDate,
    onResetFilters,
    className,
    startDate,
    endDate,
  }) => {
    return (
      <FormDateProvider>
        <div className={className}>
          <div className="filters-title">
            <strong>Filtros</strong>
            <ButtonLink
              fullWidth={false}
              onClick={onResetFilters}
              primary
              type="primary"
              data-hook="reset-filters">
              Limpiar filtros
            </ButtonLink>
          </div>
          <div className="p-t-8 p-b-4 p-x-8">Período:</div>
          <div className="datepicker-wrapper">
            <FormDatePicker
              value={startDate}
              label="Desde"
              name="date-from"
              onChange={newValue => onChangeStartDate(newValue)}
              className="form-date-picker-styled"
            />
            <FormDatePicker
              value={endDate}
              label="Hasta"
              name="date-to"
              onChange={newValue => onChangeEndDate(newValue)}
              className="form-date-picker-styled"
            />
          </div>
          <div className="filters-block-wrapper">
            <Divider />
            <div className="filters-wrapper">
              <strong>Categorías:</strong>
            </div>
            <div className="form-control-label-wrapper">
              <CheckboxTree
                nodes={dataTree}
                checked={testState.checked}
                expanded={testState.expanded}
                onCheck={checked => setState({ ...testState, checked })}
                onExpand={expanded => setState({ ...testState, expanded })}
                icons={{
                  check: <Icon className="icon">{ICONS.done}</Icon>,
                  uncheck: <Icon className="icon">{ICONS.crop_din}</Icon>,
                  halfCheck: <Icon className="icon">{ICONS.playlist_add_check}</Icon>,
                  expandClose: <Icon className="icon">{ICONS.expandArrow}</Icon>,
                  expandOpen: <Icon className="icon">{ICONS.expandLess}</Icon>,
                }}
              />
            </div>
          </div>
        </div>
      </FormDateProvider>
    )
  },
)`
  width: 100%;
  font-size: 14px;
  font-weight: bold;
  padding: 10px 12px;
  .material-icons {
    font-size: 20px !important;
    color: #000;
  }
  .is-error {
    color: ${props => get(props, 'theme.palette.error.main')};
  }
  .rct-title {
    padding: 0 5px;
    top: -5px;
    position: relative;
  }
  .calendar-icon {
    position: absolute;
    right: 0;
    top: -3px;
  }
  .datepicker-wrapper {
    display: flex;
    padding: 10px 8px;
    justify-content: space-between;
    width: 100%;
  }
  .checkbox {
    padding: 3px 5px;
    & svg {
      transform: scale(0.9);
      color: ${props => get(props, 'theme.palette.global.gray_dark')};
    }
  }
  li.rct-node.rct-node-parent .rct-node.rct-node-leaf {
    font-size: 15px;
    margin-left: 6px;
  }
  li.rct-node.rct-node-parent ol span.rct-node-icon {
    display: none;
  }

  .rct-node.rct-node-leaf > span,
  .rct-node.rct-node-parent.rct-node-expanded > span,
  .rct-node.rct-node-parent.rct-node-collapsed > span {
    position: relative;
    padding: 0.1em 0;
    .rct-collapse {
      right: 0;
      position: absolute;
    }
  }
  .label-form {
    width: 100px;
    display: block;
    z-index: 0;
  }
  .text-icon-styled {
    display: flex;
    padding: 0 10px;
    color: ${props => get(props, 'theme.palette.global.gray_dark')};
    align-items: center;
    font-weight: 400;
    & .icon {
      font-size: 17px;
      margin-right: 5px;
      color: ${props => get(props, 'theme.palette.global.gray_dark')};
      vertical-align: middle;
    }
  }
  .form-control-label-wrapper {
    display: flex;
    flex-flow: column;
    padding: 10px 8px;
  }
  .filters-block-wrapper {
    font-size: 14px;
    font-weight: bold;
    padding: 5px 8px;
    width: 100%;
  }
  .filters-wrapper {
    display: flex;
    padding: 20px 0 0 0;
    justify-content: space-between;
    width: 100%;
  }
  .filters-title {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 8px;
  }
  .filters-title strong {
    font-size: 16px;
  }
  .form-date-picker-styled + .form-date-picker-styled {
    margin-left: 15px;
  }
`

FiltersSidebar.propTypes = {
  filters: PropTypes.array,
  onAction: PropTypes.func,
  onChangeStartDate: PropTypes.func,
  onChangeEndDate: PropTypes.func,
  filtersSelected: PropTypes.array,
  customFiltersSelected: PropTypes.array,
  onResetFilters: PropTypes.func,
  dataTree: PropTypes.array,
  testState: PropTypes.object,
  setState: PropTypes.func,
}
