import PropTypes from 'prop-types'
import { OfferItemSmall } from 'components/ui'

const portTypeLabelMap = { mobile: 'Móvil', fixed: 'Fijo' }

const PortOutInfo = ({ portOut, type }) => (
  <>
    <OfferItemSmall label="Operador" value={portOut.ReceptorOperatorName} />
    <OfferItemSmall
      label={`Fecha Portabilidad ${portTypeLabelMap[type]}`}
      value={portOut.WindowDate}
    />
    <OfferItemSmall label="Límite de Fecha" value={portOut.PncDate} />
    <OfferItemSmall label="Código Referencia" value={portOut.Reference} />
  </>
)

PortOutInfo.propTypes = {
  portOut: PropTypes.shape({
    WindowDate: PropTypes.string.isRequired,
    Reference: PropTypes.string.isRequired,
    PncDate: PropTypes.string.isRequired,
    ReceptorOperatorName: PropTypes.string.isRequired,
  }),
  type: PropTypes.oneOf(['mobile', 'fixed']),
}

export default PortOutInfo
