import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { Modal, ModalActions, Button } from 'components/ui'

import { ModalContent } from 'modules/offers-configuration/components/ModalContent'
import MultiStep from 'modules/offers-configuration/components/MultiStep'
import { useTariffOptions } from 'modules/offers-configuration/hooks/useTariffOptions'
import {
  selectDecisionAllowed,
  selectCommercialMigrationIsLoading,
  selectCommercialMigrationError,
  selectCommercialMigrationData,
} from 'modules/offers-configuration/selectors'
import { initCommercialMigration } from 'modules/offers-configuration/actions'
import { TECHNOLOGY_CHANGE, COMMERCIAL_MIGRATION } from 'modules/offers-configuration/constants'
import { ChangesHeader } from 'modules/offers-configuration/components/ChangesHeader'
import { useChangeTechnologyOptions } from 'modules/offers-configuration/hooks/useChangeTechnologyOptions'

export const CommercialMigrationModal = ({
  show,
  currentTariff,
  tariffSegment,
  subscription,
  subscriptionId,
  taxNeeded,
  currentFee,
  coverageTerritoryOwner,
  coverageAddressId,
  onClose,
  onError,
}) => {
  const dispatch = useDispatch()

  const isLoading = useSelector(selectCommercialMigrationIsLoading)
  const error = useSelector(selectCommercialMigrationError)
  const migrationResponse = useSelector(selectCommercialMigrationData)

  useEffect(() => {
    if (migrationResponse || error) {
      onClose()
    }

    if (show && error) {
      onError('Se ha producido un error', 'No se ha podido realizar la migración comercial')
    }
  }, [error, migrationResponse])

  const [selectedTariffValue, setSelectedTariffValue] = useState('')
  const [selectedPsIdTariff, setSelectedPsIdTariff] = useState(null)
  const [selectedPoIdTariff, setSelectedPoIdTariff] = useState(null)

  const onTariffSelect = (e, psId, poId) => {
    setSelectedTariffValue(e.target.value)
    setSelectedPsIdTariff(psId)
    setSelectedPoIdTariff(poId)
  }

  const { filteredEnrichTariffs } = useTariffOptions(subscription, null, false, false)
  const decisionAllowed = useSelector(selectDecisionAllowed)

  const options = useChangeTechnologyOptions(
    filteredEnrichTariffs,
    coverageTerritoryOwner,
    subscriptionId,
    selectedPoIdTariff,
    selectedTariffValue,
    TECHNOLOGY_CHANGE,
    { coverageAddressId, changeType: COMMERCIAL_MIGRATION },
  )

  return (
    <Modal
      isOpen={show}
      onClose={!isLoading ? () => onClose(true) : null}
      title="Migración comercial"
      overFlowY="auto">
      <ModalContent data-hook="modal-change-technology">
        <MultiStep
          initialStep="tariffs"
          steps={{
            tariffs: () => (
              <>
                <ChangesHeader
                  commercialName={get(currentTariff, 'commercial_name')}
                  filteredEnrichTariffs={filteredEnrichTariffs}
                  taxNeeded={taxNeeded}
                  subscription={subscription}
                  onTariffSelect={onTariffSelect}
                  selectedPsIdTariff={selectedPsIdTariff}
                  tariffSegment={tariffSegment}
                  subscriptionId={subscriptionId}
                  territoryOwner={coverageTerritoryOwner}
                  currentFee={currentFee}
                  isCommercialMigration
                />

                <ModalActions>
                  <Button
                    type="submit"
                    onClick={() => {
                      dispatch(initCommercialMigration(options))
                    }}
                    disabled={!decisionAllowed || isLoading}>
                    Confirmar
                  </Button>
                </ModalActions>
              </>
            ),
          }}
        />
      </ModalContent>
    </Modal>
  )
}

CommercialMigrationModal.propTypes = {
  show: PropTypes.bool,
  currentTariff: PropTypes.object,
  tariffSegment: PropTypes.string,
  subscription: PropTypes.object,
  subscriptionId: PropTypes.number,
  taxNeeded: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  currentFee: PropTypes.string,
  coverageTerritoryOwner: PropTypes.string,
  coverageAddressId: PropTypes.string,
}
