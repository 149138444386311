import { get } from 'lodash'
import { createSelector } from 'reselect'

export const selectPrepaidLine = state => get(state, 'prepaidLine')

export const selectPrepaidLineData = productId =>
  createSelector(
    selectPrepaidLine,
    prepaidLine => get(prepaidLine, `data.${productId}`),
  )

export const selectIsLoading = createSelector(
  selectPrepaidLine,
  prepaidLine => get(prepaidLine, 'loading'),
)

export const selectErrorMessage = createSelector(
  selectPrepaidLine,
  prepaidLine => get(prepaidLine, 'error'),
)
