import PropTypes from 'prop-types'

import { Typography } from '@material-ui/core'
import { TextStyles } from 'utils/text'

import { FormikInput, Advice } from 'components/ui'
import { FUSION_POS, POS, FUSION_POS_PRO } from 'services/global-constants'

import { getFixedLineTariffLabel } from '../../Common/common.label.helper'

const RouterNumber = ({ fieldNames, channel, landlineTariff, isAdsl, onChange, message }) => {
  return (
    <>
      <h3 className="card-title small m-t-20 m-b-20">
        Internet: {getFixedLineTariffLabel(landlineTariff)}
      </h3>
      {isAdsl && [FUSION_POS, FUSION_POS_PRO, POS].includes(channel) && (
        <div className="flex align-start-center m-y-12">
          <Typography
            {...TextStyles.paragraphDark({
              className: 'p-r-36',
            })}>
            Router
          </Typography>
          <div className="p-r-36">
            <FormikInput
              label="nº de serie"
              fullWidth
              name={fieldNames.ROUTER_NUMBER}
              onChange={onChange}
              maxLength={20}
            />
          </div>
          {message && <Advice type="warning">{message}</Advice>}
        </div>
      )}
    </>
  )
}

RouterNumber.propTypes = {
  fieldNames: PropTypes.object.isRequired,
  channel: PropTypes.any.isRequired,
  landlineTariff: PropTypes.object.isRequired,
  isAdsl: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  message: PropTypes.string,
}

export default RouterNumber
