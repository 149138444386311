import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'

import { Typography, Icon } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import { TextStyles } from 'utils/text'
import { ICONS } from 'utils/icons'

import { ActionsWrapper } from './ActionsWrapper'
import { Advice } from './Advice'
import { AdviceLabelValue } from './AdviceLabelValue'

const useStyles = makeStyles(theme => ({
  'advice-action-message': {
    padding: '5px 0',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    fontWeight: 'normal',
  },

  'advice-action-close-icon': {
    margin: 'auto 0 auto auto',
    color: ({ type }) => type === 'success' && theme.palette.greenPalette.green_500,
    cursor: 'pointer',
  },
}))

export function ActionAdvice({
  className,
  id,
  dataHook,
  type,
  icon,
  items,
  message,
  block,
  justifyContent,
  onUpdate,
  onCancel,
  onDelete,
  onDisable,
  onComplete,
  onClose,
  onRetry,
  children,
}) {
  const classes = useStyles({ type })

  return (
    <ActionsWrapper
      className={className}
      onUpdate={onUpdate}
      onCancel={onCancel}
      onDelete={onDelete}
      onDisable={onDisable}
      onComplete={onComplete}
      onRetry={onRetry}
      dataHook={dataHook}>
      <Advice id={id} type={type} icon={icon} block={block} justifyContent={justifyContent}>
        {!isEmpty(items) &&
          items.map((item, index) => (
            <AdviceLabelValue {...item} key={`${item.label + index}`} padding="6px 32px 6px 0px" />
          ))}
        {!isEmpty(message) && (
          <Typography
            {...TextStyles.labelDark({
              className: `message ${classes['advice-action-message']}`,
              style: { maxWidth: block ? '100%' : null },
              component: 'div',
            })}>
            {message}
          </Typography>
        )}

        {children}

        {onClose && (
          <Icon
            className={`icon ${classes['advice-action-close-icon']}`}
            color="primary"
            data-hook="icon-close"
            onClick={onClose}>
            {ICONS.cancel}
          </Icon>
        )}
      </Advice>
    </ActionsWrapper>
  )
}

ActionAdvice.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  dataHook: PropTypes.string,
  type: PropTypes.oneOf(['error', 'info', 'warning', 'success']).isRequired,
  icon: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
  message: PropTypes.string,
  block: PropTypes.bool,
  justifyContent: PropTypes.string,
  onDelete: PropTypes.func,
  onUpdate: PropTypes.func,
  onCancel: PropTypes.func,
  onDisable: PropTypes.func,
  onComplete: PropTypes.func,
  onClose: PropTypes.func,
  onRetry: PropTypes.func,
  children: PropTypes.node,
}
