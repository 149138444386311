import { call, put, takeEvery } from 'redux-saga/effects'
import { logError, formatErrorMessage } from 'services/logging'
import { actions } from './actions'
import { constants } from './constants'
import { findCustomerProducts } from './api'

export function* findCustomerProductsSaga({ payload: { documentType, documentId } }) {
  try {
    yield put(actions.getCustomerProducts())
    const customerProducts = yield call(findCustomerProducts, documentType, documentId)
    yield put(actions.getCustomerProductsSuccess(customerProducts))
  } catch (e) {
    yield call(logError, { e: new Error(formatErrorMessage(e)), documentType, documentId })
    yield put(actions.getCustomerProductsFailed(e))
  }
}

export function* watchCustomerProducts() {
  yield takeEvery(constants.GET_CUSTOMER_PRODUCTS_INIT, findCustomerProductsSaga)
}
