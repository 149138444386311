const TypificationFields = {
  operator: '',
  campaign: '',
  reason: '',
  subreason: '',
  subreason2: '',
  result: '',
  subresult: '',
  msisdn: '',
  description: '',
}

export default TypificationFields
