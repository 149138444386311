import { useState, useMemo, useEffect } from 'react'
import { PropTypes } from 'prop-types'

import { Typography } from '@material-ui/core'
import { Modal, ModalActions, Button, Grid } from 'components/ui'

import { SVA_HOMEGO_CODE } from 'modules/offers-configuration/constants'
import { HOME_GO_GROUP_SVA } from 'modules/SVAs/constants'

import { ModifyHomeGoAddressModal } from 'modules/NewClientSales/components/Offer/ProSvas/ModifyHomeGoAddressModal'
import { find, isEmpty } from 'lodash'

import { SVACheck } from './SVACheckbox'

const addHomeGoGroupSva = storeSvas => {
  const svas = [...storeSvas]

  if (storeSvas.find(sva => sva.psId.includes(SVA_HOMEGO_CODE))) {
    svas.push(HOME_GO_GROUP_SVA)
  }

  return svas
}

export function SVAsProModal({ svaList, saveSvas, storeSvas, isOpen, isPro, isConvergent }) {
  const [selectedSvas, setSelectedSvas] = useState(addHomeGoGroupSva(storeSvas))

  const [shouldValidate, setShouldValidate] = useState()
  const [validFields, setValidFields] = useState({})

  const [openModifyAddress, setOpenModifyAddress] = useState(false)

  const areAllFieldsValid = useMemo(() => {
    const fieldsStatus = Object.values(validFields)
    return isEmpty(fieldsStatus.filter(elem => !elem))
  }, [validFields])

  const onSaveSvas = () => saveSvas(selectedSvas.filter(sva => sva.psId !== SVA_HOMEGO_CODE))

  function onConfirm() {
    setShouldValidate(false)
    if (!areAllFieldsValid) {
      setShouldValidate(true)

      return
    }

    if (selectedSvas.find(sva => sva.psId.includes(SVA_HOMEGO_CODE))) {
      setOpenModifyAddress(true)
      return
    }

    onSaveSvas()
  }

  function setIsValid(name, bool) {
    setValidFields({ ...validFields, [name]: bool })
  }

  useEffect(() => {
    setSelectedSvas(addHomeGoGroupSva(storeSvas))
  }, [storeSvas])

  return (
    <>
      <Modal isOpen={isOpen} title="Añadir SVAs de fijo">
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="body1">
              Elige los servicios que quieres añadir al contrato y haz click en confirmar
            </Typography>
          </Grid>
          {svaList.map(sva => (
            <SVACheck
              storeSva={find(addHomeGoGroupSva(storeSvas), { poId: sva.poId })}
              key={sva.psId}
              sva={sva}
              setSelectedSvas={setSelectedSvas}
              selectedSvas={selectedSvas}
              shouldValidate={shouldValidate}
              setIsValid={setIsValid}
              isPro={isPro}
              storeSvas={addHomeGoGroupSva(storeSvas)}
              showPrice={!sva.psId.includes(SVA_HOMEGO_CODE)}
            />
          ))}
        </Grid>
        <ModalActions>
          <Button data-hook="confirm-pro-sva" onClick={onConfirm}>
            CONFIRMAR
          </Button>
        </ModalActions>
      </Modal>

      {openModifyAddress && (
        <ModifyHomeGoAddressModal
          onClose={() => setOpenModifyAddress(false)}
          onSave={() => {
            onSaveSvas()
            setOpenModifyAddress(false)
          }}
          allowInputManualAddress={!isConvergent}
          isRequiredFields={!isConvergent}
        />
      )}
    </>
  )
}

SVAsProModal.propTypes = {
  svaList: PropTypes.array.isRequired,
  isOpen: PropTypes.bool,
  saveSvas: PropTypes.func.isRequired,
  storeSvas: PropTypes.array.isRequired,
  isPro: PropTypes.bool,
  isConvergent: PropTypes.bool,
}
