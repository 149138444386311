import styled from 'styled-components'

const ItemLabelCard = styled.span`
  font-family: Roboto;
  font-size: 14px;
  font-weight: ${({ fontWeight }) => fontWeight || 'bold'};
  color: ${({ theme }) => theme.palette.primary.main};
  margin: 4px 0;
  text-decoration: underline;
`

export default ItemLabelCard
