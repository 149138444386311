import { useState } from 'react'
import { imeiRegex } from 'utils'
import { validateImei } from 'modules/Terminals/services/TerminalImeiValidationService/index'
import {
  REQUIRED_FIELD_TEXT,
  NOT_REGISTERED_IMEI,
  NOT_VALID_IMEI,
  IMEI_IN_USE,
} from 'modules/SharedSales/constants'

export function useIMEIValidation() {
  const [prevValue, setPrevValue] = useState('')
  const [prevState, setPrevState] = useState()
  const [currentPsId, setCurrentPsId] = useState(null)

  function validate(value) {
    const trimmedValue = value?.trim()
    setPrevValue(trimmedValue)
    if (!trimmedValue) {
      setPrevState(REQUIRED_FIELD_TEXT)
      setCurrentPsId(null)
      return REQUIRED_FIELD_TEXT
    }
    if (trimmedValue === prevValue) return prevState
    if (imeiRegex.test(trimmedValue)) {
      setPrevState()
      return undefined
    }
    setPrevState(NOT_VALID_IMEI)
    return NOT_VALID_IMEI
  }

  async function fetchValidateIMEI(value) {
    const validationKO = validate(value)
    if (!validationKO) {
      try {
        const { status, psId } = await validateImei(value?.trim())
        setCurrentPsId(psId)
        if (status === 'used') {
          return IMEI_IN_USE
        }
        if (status !== 'available') {
          return NOT_REGISTERED_IMEI
        }
        return undefined
      } catch (e) {
        return NOT_REGISTERED_IMEI
      }
    }
    return undefined
  }

  return { validate, fetchValidateIMEI, currentPsId }
}
