import PropTypes from 'prop-types'
import { ButtonLink, OutlinedDivider } from 'components/ui'
import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { SIGNUP_TYPE_PORTABILITY } from 'services/global-constants'
import { DiscountSummaryComponent } from 'modules/Discounts'
import { SVASummary } from 'modules/SVAs'
import { LINE_TYPE } from 'modules/Lines'

import { isEmpty } from 'lodash'
import { FIBER_INSTALLER } from 'modules/Installations/constants'
import { TextStyles } from 'utils/index'
import { MobileLineSummary } from './MobileLineSummary'
import { OfferSummaryFee } from './OfferSummaryFee'
import { ExtraLineSummary } from './ExtraLineSummary'

const useStyles = makeStyles(theme => ({
  'lines-offer-summary': {
    border: `solid 1px ${theme.palette.bluePalette.light}`,
    borderRadius: '6px',
  },
}))
export function OfferSummary({
  userName,
  signUpTypeText,
  payments,
  formattedTariffName,
  landlineNumber,
  provisionedMobileNumber,
  mobileLineSummmaryProps,
  extraMobileLinesOffers,
  summaryLinesDiscounts,
  signUpType,
  selectedSVA,
  selectedProSvas,
  onClickChangeOfferInfo,
  landlineFiberInstaller,
  landlineInternetName,
  landlineTechnology,
  isElFijo,
  isPro,
  is2p,
  isMobileOnly,
  isSubscription,
  discountMessages,
  isCrossSell,
  is2pPure,
  isMultiSim,
}) {
  const classes = useStyles()

  return (
    <Box data-hook="offer-summary">
      <Typography {...TextStyles.subtitle1Secondary()}>INFORMACIÓN DE LA OFERTA</Typography>

      <Typography
        {...TextStyles.subtitle2Dark({
          className: 'm-t-8 m-b-16',
        })}
        data-hook="landlineTariff.comercialName">
        {formattedTariffName}
      </Typography>

      {!isSubscription && !isMultiSim && (
        <Box
          display="flex"
          flexDirection="column"
          position="relative"
          p="24px"
          width="100%"
          className={classes['lines-offer-summary']}>
          {signUpType && (
            <>
              <Typography variant="subtitle2" color="secondary" className="bold m-b-16">
                Fijo: Tarifa Base
              </Typography>
              <Box display="flex" flexWrap="wrap">
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-start"
                  mb="16px"
                  pr="36px">
                  <Typography
                    {...TextStyles.paragraphDark({
                      className: 'p-r-4',
                    })}>
                    Tipo:
                  </Typography>
                  <Typography {...TextStyles.subtitle2Dark()} data-hook="landlineOffer.signUpType">
                    {signUpTypeText}
                  </Typography>
                </Box>

                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-start"
                  mb="16px"
                  pr="36px">
                  <Typography
                    {...TextStyles.paragraphDark({
                      className: 'p-r-4',
                    })}>
                    Número:{' '}
                  </Typography>
                  <Typography {...TextStyles.subtitle2Dark()} data-hook="landlineNumber">
                    {landlineNumber}
                  </Typography>
                </Box>
                {is2p && !is2pPure && (
                  <Box display="flex" alignItems="center" justifyContent="flex-start" mb="16px">
                    <Typography
                      {...TextStyles.paragraphDark({
                        className: 'p-r-4',
                      })}>
                      Nº Móvil asignado:
                    </Typography>
                    <Typography {...TextStyles.subtitle2Dark()} data-hook="provisionedMobileNumber">
                      {provisionedMobileNumber}
                    </Typography>
                  </Box>
                )}
              </Box>
              {signUpType === SIGNUP_TYPE_PORTABILITY && (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-start"
                  mb="16px"
                  width="100%">
                  <Typography {...TextStyles.paragraphDark()}>Titular: </Typography>
                  <Typography {...TextStyles.subtitle2Dark()} data-hook="landlineOffer.titular">
                    {userName}
                  </Typography>
                </Box>
              )}
              <SVASummary
                selectedSVA={selectedSVA}
                selectedProSvas={selectedProSvas}
                isPro={isPro}
              />
              <Box mb="16px">
                <Box mb="16px">
                  <OutlinedDivider />
                </Box>

                <Typography
                  variant="subtitle2"
                  color="secondary"
                  className="bold"
                  data-hook="internet-speed">
                  Internet: {landlineInternetName}
                </Typography>
                {landlineFiberInstaller ? (
                  <Box display="inline" pl="36px" data-hook="install-advice">
                    <Typography
                      {...TextStyles.paragraphDark({
                        component: 'span',
                      })}>
                      Instala {landlineTechnology}:
                    </Typography>
                    <Typography
                      {...TextStyles.subtitle2Dark({
                        className: 'p-l-4',
                        component: 'span',
                      })}>
                      {landlineFiberInstaller === FIBER_INSTALLER.MM ? 'YOIGO' : 'Telefónica'}
                    </Typography>
                  </Box>
                ) : null}
              </Box>
            </>
          )}

          {!is2p && (
            <>
              {!isMobileOnly && !isMultiSim && (
                <Box my="16px">
                  <OutlinedDivider />
                </Box>
              )}
              <MobileLineSummary
                {...mobileLineSummmaryProps}
                isElFijo={isElFijo}
                isCrossSell={isCrossSell}
                lineType={LINE_TYPE.MAIN_MOBILE}
                selectedSVA={selectedSVA}
                selectedProSvas={selectedProSvas}
                isPro={isPro}
                isMobileOnly={isMobileOnly}
                isMultiSim={isMultiSim}
              />
            </>
          )}
        </Box>
      )}

      {extraMobileLinesOffers.map((extraLine, index) => (
        <ExtraLineSummary
          key={extraLine.tariff.id}
          isCrossSell={isCrossSell}
          isMultiSim={isMultiSim}
          {...extraLine}
          lineType={`extraMobileLinesOffers[${index}]`}
        />
      ))}
      <Box display="flex" flexWrap="wrap" alignItems="flex-start" flexGrow="1" my="32px">
        {(!isEmpty(summaryLinesDiscounts) || !isEmpty(discountMessages)) && (
          <Box flexGrow="1" pr="24px">
            <Typography {...TextStyles.paragraphDark()}>
              Descuentos o promociones añadidos:
            </Typography>
            <DiscountSummaryComponent
              discounts={summaryLinesDiscounts}
              discountMessages={discountMessages}
            />
          </Box>
        )}
        <Box display="flex" flexDirection="column" flexGrow="1">
          <OfferSummaryFee payments={payments} isPro={isPro} />
        </Box>
      </Box>
      <ButtonLink
        to={`${isSubscription || isMultiSim ? 'info' : 'offers'}?isEditing=true`}
        onClick={onClickChangeOfferInfo}>
        Modificar información de la oferta
      </ButtonLink>
    </Box>
  )
}

OfferSummary.propTypes = {
  payments: PropTypes.array,
  userName: PropTypes.string.isRequired,
  signUpTypeText: PropTypes.string.isRequired,
  formattedTariffName: PropTypes.string.isRequired,
  landlineNumber: PropTypes.string,
  provisionedMobileNumber: PropTypes.string,
  landlineInternetName: PropTypes.string.isRequired,
  landlineFiberInstaller: PropTypes.string,
  landlineTechnology: PropTypes.string,
  mobileLineSummmaryProps: PropTypes.any.isRequired,
  extraMobileLinesOffers: PropTypes.any,
  summaryLinesDiscounts: PropTypes.array.isRequired,
  signUpType: PropTypes.string.isRequired,
  selectedSVA: PropTypes.object.isRequired,
  selectedProSvas: PropTypes.array.isRequired,
  onClickChangeOfferInfo: PropTypes.func.isRequired,
  isElFijo: PropTypes.bool.isRequired,
  isPro: PropTypes.bool.isRequired,
  is2p: PropTypes.bool.isRequired,
  isMobileOnly: PropTypes.bool.isRequired,
  isSubscription: PropTypes.bool,
  isCrossSell: PropTypes.bool,
  discountMessages: PropTypes.array,
  is2pPure: PropTypes.bool,
  isMultiSim: PropTypes.bool,
}
