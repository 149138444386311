import { put, select, take } from 'redux-saga/effects'

import { actions } from './actions'
import { selectors } from './selectors'
import { constants } from './constants'

export function* callSaga(fixedNumber) {
  yield put(actions.findOrderOnFly(fixedNumber))
  yield take([constants.FIND_ORDERONFLY_SUCCESS, constants.FIND_ORDERONFLY_FAILED])
  return yield select(selectors.getErrorCode)
}
