import { getIn } from 'formik'
import { selectAddressInfo, selectIsAdsl, selectIsNeba } from 'modules/Coverage'
import { selectFormatedMainTariffs } from 'modules/NewClientSales/store/selectors'
import {
  selectIs2p,
  selectIsCrossSellQueryParam,
  selectIsCarteraQueryParam,
} from 'modules/Router/store'
import { selectIsLoadingMainLineTariffs } from 'modules/tariffs/store'
import { createSelector, createStructuredSelector } from 'reselect'
import { selectIsSVAEmail } from 'services/feature-flag/selectors'

import { selectIsPro, selectMainSelectedTariff, selectSaleTax } from '../../../store'

export function getFormikSelectors(fieldNames, setFieldValue, values) {
  const selectedValues = {
    selectedPackages: getIn(values, fieldNames.SELECTED_PACKAGES),
    portabilityPerson: getIn(values, fieldNames.PORTABILITY_PERSON),
    otherUserDocument: getIn(values, fieldNames.DOCUMENT_TYPE),
    newNumber: getIn(values, fieldNames.NEW_NUMBER_VALUE),
    newFixedNumber: getIn(values, fieldNames.NEW_FIXED_NUMBER_VALUE),
    selectedTariff: getIn(values, fieldNames.TARIFF),
    signUpType: getIn(values, fieldNames.MOBILE_LINE_TYPE),
    isPortabilityFromFtth: getIn(values, fieldNames.IS_PORTA_FTTH),
    isPortability: getIn(values, fieldNames.IS_PORTABILITY),
    operator: getIn(values, fieldNames.OPERATOR),
    tariffId: getIn(values, `${fieldNames.TARIFF_ID}`),
    isAdditionalType: getIn(values, fieldNames.TARIFF_TYPE) === 'additional_line',
    ps: getIn(values, `${fieldNames.TARIFF}.ps`),
    lineNumber: getIn(values, fieldNames.PORTABILITY_NUMBER),
    routerNumber: getIn(values, fieldNames.ROUTER_NUMBER),
    hasD2rDiscount: getIn(values, fieldNames.HAS_D2R_DISCOUNT),
  }
  const portabilityAnswer = selectedValues.signUpType || selectedValues.isPortability
  return { ...selectedValues, portabilityAnswer, setFieldValue }
}

const selectTariffType = createSelector(
  selectIs2p,
  is2p => (is2p ? 'fixed_line' : 'convergent'),
)

export const storeSelectors = createStructuredSelector({
  tariffList: selectFormatedMainTariffs,
  isLoadingTariffs: selectIsLoadingMainLineTariffs,
  isNeba: selectIsNeba,
  isAdsl: selectIsAdsl,
  tax: selectSaleTax,
  mainOffer: selectMainSelectedTariff,
  installationAddress: selectAddressInfo,
  tariffType: selectTariffType,
  isPro: selectIsPro,
  is2p: selectIs2p,
  isSvaFlag: selectIsSVAEmail,
  isCrossSell: selectIsCrossSellQueryParam,
  isCartera: selectIsCarteraQueryParam,
})
