import { get } from 'lodash'
import { createSelector } from 'reselect'

const selectFatCompensation = state => get(state, 'fat')

export const selectIsLoading = createSelector(
  selectFatCompensation,
  fatData => get(fatData, 'loading'),
)

export const selectErrorMessage = createSelector(
  selectFatCompensation,
  fatData => get(fatData, 'error'),
)
