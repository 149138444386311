import PropTypes from 'prop-types'
import Radio from '@material-ui/core/Radio'
import { FormikRadio } from 'components/ui'

import { FormControlLabel } from '@material-ui/core'
import { InputIUA } from './components/InputIUA'

export const PortabilityFromFtth = ({
  isPortabilityFromFtth,
  isNeba,
  shouldShowHelperText,
  onChangePortabilityFromFTTH,
  operator,
}) => {
  // We show IUA input if its a portability from FTTH in NEBA and there is no operator
  // or the operator for the portability is equal than TELEFONICA (00001)
  const shouldShowInputIua =
    isPortabilityFromFtth === 'yes' && isNeba && (!operator.id || operator.id === '00001')
  return (
    <div className="p-b-16">
      <div className="flex align-start-center">
        <FormikRadio
          row
          label="¿El cliente tiene fibra en casa?"
          dataHook="landlineOffer.isPortabilityFromFtth"
          onChange={ev => onChangePortabilityFromFTTH(ev.target.value)}
          name="landlineOffer.isPortabilityFromFtth">
          <FormControlLabel
            value="yes"
            data-hook="landlineOffer.isPortabilityFromFtth.yes"
            control={<Radio className="radio-check" color="primary" />}
            label="Sí"
          />
          <FormControlLabel
            value="no"
            data-hook="landlineOffer.isPortabilityFromFtth.no"
            control={<Radio className="radio-check" color="primary" />}
            label="No / No sabe"
          />
        </FormikRadio>
      </div>
      {shouldShowInputIua && <InputIUA lineType="landlineOffer" />}
      {shouldShowHelperText ? (
        <div style={{ backgroundColor: '#f0f0f0', padding: '5px' }}>
          <small>
            Es importante que selecciones la opción correcta; de ella depende que tenga que acudir
            un técnico de Telefónica
          </small>
        </div>
      ) : null}
    </div>
  )
}

PortabilityFromFtth.propTypes = {
  isPortabilityFromFtth: PropTypes.string.isRequired,
  isNeba: PropTypes.bool,
  shouldShowHelperText: PropTypes.bool.isRequired,
  onChangePortabilityFromFTTH: PropTypes.func,
  operator: PropTypes.object,
}
