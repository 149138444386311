import PropTypes from 'prop-types'
import { get } from 'lodash'
import { makeStyles } from '@material-ui/styles'
import { Icon } from '@material-ui/core'
import { ICONS } from 'utils/icons'

const TYPE_BORDER_COLORS = {
  alert: 'brand.alert',
  attention: 'brand.attention',
  info: 'brand.action_primary',
  success: 'brand.validated',
}

const useStyles = makeStyles(theme => ({
  'dialog-wrapper': {
    position: 'fixed',
    boxSizing: 'border-box',
    borderRadius: '14px',
    backgroundColor: theme.palette.brand.light,
    boxShadow: '0 2px 4px 1px rgba(0, 0, 0, 0.27)',
    borderBottom: ({ type }) =>
      `5px solid ${
        TYPE_BORDER_COLORS[type] ? get(theme.palette, TYPE_BORDER_COLORS[type]) : 'transparent'
      }`,
    overflowY: ({ overFlowY }) => overFlowY || 'auto',
    '-webkit-overflow-scrolling': 'touch',
    left: '50%',
    top: '50%',
    height: 'auto',
    transform: 'translate(-50%, -50%)',
    width: ({ width }) => width,
    maxWidth: 'calc(100% - 20px)',
    maxHeight: 'calc(100% - 40px)',
    paddingRight: '7px',
  },
  'dialog-wrapper-content': {
    padding: '32px 25px 27px 32px',
  },
  'close-button': {
    position: 'absolute',
    top: '32px',
    right: '32px',

    '& .icon': {
      color: theme.palette.global.gray_dark,
      cursor: 'pointer',
    },
  },
}))

const Dialog = ({ className, onClose, children, width, type, overFlowY, ...rest }) => {
  const classes = useStyles({ width, type, overFlowY })

  return (
    <div
      className={[classes['dialog-wrapper'], ...(className ? [className] : [])].join(' ')}
      {...rest}>
      <div className={classes['dialog-wrapper-content']} role="contentinfo">
        {onClose && (
          <div className={classes['close-button']}>
            <Icon className="icon" onClick={onClose} data-hook="dialog-close">
              {ICONS.close}
            </Icon>
          </div>
        )}
        {children}
      </div>
    </div>
  )
}

Dialog.defaultProps = {
  type: 'default',
}

Dialog.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  children: PropTypes.node,
  width: PropTypes.string,
  type: PropTypes.oneOf(['default', 'alert', 'attention', 'info', 'success']),
  overFlowY: PropTypes.string,
}

export default Dialog
