import { put, select, take } from 'redux-saga/effects'
import { includes } from 'lodash'

import { actions } from './actions'
import * as selectors from './selectors'
import { constants } from './constants'

export function* callSaga(profiles, category) {
  yield put(actions.getDiscounts(profiles, category))
  yield take(
    action =>
      includes([constants.GET_DISCOUNTS_SUCCESS, constants.GET_DISCOUNTS_FAILED], action.type) &&
      action.payload.category &&
      action.payload.category === category,
  )
  return yield select(selectors.getErrorCode, category)
}
