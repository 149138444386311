import { useSelector } from 'react-redux'

import * as saga from '../../sagas/clientDashboardLoad'
import { ClientDashboard } from '../../components/ClientDashboard'
import { extractPersonalData } from '../../selectors/selectors'

export function ClientDashboardContainer(props) {
  const newProps = {
    ...props,
    isLoading: useSelector(saga.selectors.isLoading),
    hasErrors: useSelector(saga.selectors.hasErrors),
    clientData: useSelector(extractPersonalData),
  }
  return <ClientDashboard {...newProps} />
}
