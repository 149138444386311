import { template } from 'lodash'
import PropTypes from 'prop-types'

function injectVariablesToHtml(html, values) {
  const compiled = template(html, { escape: /\{(.*?)\}/g })
  return compiled(values)
}

export function HTMLTemplate({ html, values, className, ...rest }) {
  return (
    <span
      className={className}
      data-hook={rest['data-hook']}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: values ? injectVariablesToHtml(html, values) : html }}
    />
  )
}

HTMLTemplate.propTypes = {
  html: PropTypes.string,
  values: PropTypes.object,
  className: PropTypes.string,
}
