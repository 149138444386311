import { FETCH_CUSTOMER, FETCH_CUSTOMER_ERROR, FETCH_CUSTOMER_SUCCESS } from './constants'

export const fetchCustomer = payload => ({
  type: FETCH_CUSTOMER,
  payload,
})

export const fetchCustomerSuccess = payload => ({
  type: FETCH_CUSTOMER_SUCCESS,
  payload,
})

export const fetchCustomerError = payload => ({
  type: FETCH_CUSTOMER_ERROR,
  payload,
  isError: true,
})
