import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/styles'

import DotsCircularSpinner from '../spinners/dots-circular-spinner/DotsCircularSpinner'
import Button from './Button'

const useStyles = makeStyles({
  'spinner-button': {
    padding: '0',
  },
})

const SpinnerButton = ({ className, spinnerProps, ...restProps }) => {
  const classes = useStyles()

  return (
    <Button
      className={[classes['spinner-button'], ...(className ? [className] : [])].join(' ')}
      {...restProps}>
      <DotsCircularSpinner
        {...{
          dotProps: { 'background-color': 'white' },
          width: '32px',
          ...spinnerProps,
        }}
      />
    </Button>
  )
}

SpinnerButton.propTypes = {
  ...Button.propTypes,
  spinnerProps: PropTypes.shape({ ...DotsCircularSpinner.propTypes }),
}

SpinnerButton.defaultProps = {
  spinnerProps: {},
}

export default SpinnerButton
