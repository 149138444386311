import { isEmpty, get } from 'lodash'
import PropTypes from 'prop-types'
import { CircularProgress, Collapse, Box, Typography, Divider } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { TextStyles } from 'utils/text'

import { ActionAdvice, ButtonCard, ButtonLink, OutlinedDivider } from 'components/ui'
import { PopoverMenu, Badge } from 'modules/ui'

import { formatMonthlyFee } from 'utils'

import { CurrentAndNewFees } from '../CurrentAndNewFees'
import { LinesItem } from '../LinesItem'
import { PortOutInfoDetails } from '../PortOutInfoDetails'
import { MultisimDeviceDetails } from '../MultisimDeviceDetails'
import { InternetDeviceDetails } from '../InternetDeviceDetails'
import { TVDeviceDetails } from '../TVDeviceDetails'
import { PermanentBadgeItem } from '../PermanentBadgeItem'
import { TerminalDeviceDetails } from '../TerminalDeviceDetails'
import { ModificationDetails } from '../ModificationDetails'

const useStyles = makeStyles({
  'subscription-detail-container': {
    '& > hr': {
      margin: '0px 24px 0px 24px',

      '&.full-length': {
        margin: '0px',
      },
    },

    '& > section': {
      paddingTop: '8px',

      '& > *': {
        paddingTop: '8px',
        paddingBottom: '8px',

        '&:last-child': {
          paddingTop: '8px',
          paddingBottom: '16px',
        },
      },

      '& > .wrapper-items': {
        marginRight: '32px',
        marginLeft: '48px',

        '&.lines-badges > *': {
          paddingBottom: '8px',
        },
      },

      '& .portout-info-details': {
        padding: '0px 48px 0px 48px',
      },

      '&:last-of-type': {
        '& + hr': {
          display: 'none',
        },
      },
    },

    '& > section > .current-and-new-fees': {
      paddingTop: '16px',
      marginRight: '32px',
      marginLeft: '24px',

      '& .tab-content': {
        padding: '16px 0px 0px 0px',
      },
    },

    '& > .header-detail': {
      paddingTop: '16px',
      paddingBottom: '8px',
      marginRight: '32px',
      marginLeft: '24px',
    },
  },
  'download-button-container': {
    minWidth: '182px',
  },
})

export function SubscriptionDetail({
  subscription,
  tariffName,
  feeAmountWithTax,
  productFixed,
  productMobile,
  downloadActions,
  download,
  downloadTxt,
  downloadWl,
  svaHebe,
  svaHebeAdviceFields,
  isElFijo,
  hasModifications,
  hasPortOutInfoInSub,
  toogledPortOutShow,
  setToogledPortOutShow,
  changedTariff,
  isCollapsed,
  setIsCollapsed,
  mobilePermanent,
  hasAgileTVInSubscription,
  taxNeeded,
  tax,
  enabledTaxAgileTv,
}) {
  const classes = useStyles()

  const multisimData = get(subscription, 'multisimData')

  return (
    <div className={classes['subscription-detail-container']}>
      <section>
        <div>
          <Typography
            {...TextStyles.subtitle1Link({
              className: 'p-x-24 p-y-4',
              color: 'secondary',
            })}>
            <span>Resumen de precios</span>
          </Typography>
        </div>
        <CurrentAndNewFees subscription={subscription} />
      </section>

      <Divider className="full-length" />

      <section>
        <Box className="flex align-space-between">
          <Typography
            {...TextStyles.subtitle1Link({
              className: 'p-x-24 p-y-4',
              color: 'secondary',
            })}>
            <span>{tariffName}</span>
          </Typography>

          <Box
            className={`${classes['download-button-container']} flex align-center align-start-center justify-center m-r-24`}>
            {download.isLoading || downloadTxt.isLoading || downloadWl.isLoading ? (
              <CircularProgress size="20px" />
            ) : (
              <>
                <PopoverMenu
                  ClickableComp={() => (
                    <ButtonCard
                      data-hook="download-contract"
                      label="Descargar archivos"
                      disabled={downloadActions.filter(action => !action.disabled).length === 0}
                      onClick={() => setIsCollapsed(!!isCollapsed)}
                    />
                  )}
                  menuActions={downloadActions}
                  dropdown
                />
              </>
            )}
          </Box>
        </Box>

        <div className="wrapper-items">
          <LinesItem subscription={subscription} />
        </div>

        <div className="wrapper-items lines-badges">
          <Badge
            lg
            keyValContrast
            value={[
              {
                label: 'Tarifa',
                value: tariffName,
              },
              { label: 'Cuota', value: formatMonthlyFee(feeAmountWithTax) },
            ]}
          />

          <PermanentBadgeItem permanent={mobilePermanent} />
        </div>
        {!isEmpty(svaHebe) && (
          <div className="wrapper-items">
            <ActionAdvice className="m-t-10" items={svaHebeAdviceFields} type="info" />
          </div>
        )}
      </section>

      {hasPortOutInfoInSub && (
        <>
          <section>
            <div className="wrapper-items">
              <ButtonLink
                primaryColor
                underLine
                fontWeight="500"
                onClick={() => setToogledPortOutShow(!toogledPortOutShow)}>
                {toogledPortOutShow
                  ? 'Ocultar información de Port Out'
                  : 'Mostrar información de Port Out'}
              </ButtonLink>
            </div>

            <Collapse in={toogledPortOutShow} timeout="auto">
              <PortOutInfoDetails subscription={subscription} />
            </Collapse>
          </section>
        </>
      )}

      <OutlinedDivider />

      {!isEmpty(multisimData) && (
        <>
          <section>
            <div className="wrapper-items">
              <MultisimDeviceDetails multisimData={multisimData} />
            </div>
          </section>
          <OutlinedDivider />
        </>
      )}

      {productFixed && !isElFijo ? (
        <>
          <section>
            <div className="wrapper-items">
              <InternetDeviceDetails subscription={subscription} />
            </div>
          </section>
          <OutlinedDivider />
        </>
      ) : null}

      {productFixed && hasAgileTVInSubscription && (
        <>
          <section>
            <div className="wrapper-items">
              <TVDeviceDetails
                subscription={subscription}
                taxNeeded={taxNeeded}
                tax={tax}
                enabledTaxAgileTv={enabledTaxAgileTv}
              />
            </div>
          </section>
          <OutlinedDivider />
        </>
      )}

      {productMobile ? (
        <>
          <section>
            <div className="wrapper-items">
              <TerminalDeviceDetails subscription={subscription} />
            </div>
          </section>
          <OutlinedDivider />
        </>
      ) : null}

      {hasModifications || changedTariff ? (
        <>
          <section>
            <div className="wrapper-items">
              <ModificationDetails subscription={subscription} />
            </div>
          </section>
        </>
      ) : null}
    </div>
  )
}

SubscriptionDetail.propTypes = {
  className: PropTypes.string,
  subscription: PropTypes.object,
  tariffName: PropTypes.string,
  feeAmountWithTax: PropTypes.number,
  productFixed: PropTypes.object,
  productMobile: PropTypes.object,
  downloadActions: PropTypes.array,
  download: PropTypes.object,
  downloadTxt: PropTypes.object,
  downloadWl: PropTypes.object,
  svaHebe: PropTypes.object,
  svaHebeAdviceFields: PropTypes.array,
  isElFijo: PropTypes.bool,
  hasModifications: PropTypes.bool,
  hasPortOutInfoInSub: PropTypes.bool,
  toogledPortOutShow: PropTypes.bool,
  setToogledPortOutShow: PropTypes.func,
  changedTariff: PropTypes.bool,
  isCollapsed: PropTypes.bool,
  setIsCollapsed: PropTypes.func,
  mobilePermanent: PropTypes.object,
  hasAgileTVInSubscription: PropTypes.bool,
  taxNeeded: PropTypes.bool,
  tax: PropTypes.number,
  enabledTaxAgileTv: PropTypes.bool,
}
