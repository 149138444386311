import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { isNull } from 'lodash'

import { AdviceSection } from 'components/ui'
import { useTypificationNotificationSyles } from 'modules/typifications/styles'
import {
  IMAGE_ERROR,
  TITLE_ERROR,
  BODY_ERROR,
  TYPE_NOTIFICATIONS_TIPIS,
} from 'modules/typifications/constants'

export const TypificationNotification = ({ typeMessage, typeNotification, customBody }) => {
  const typificationNotificationClasses = useTypificationNotificationSyles()
  const urlImage = useMemo(() => IMAGE_ERROR[typeMessage], [typeMessage])
  const titleError = useMemo(() => TITLE_ERROR[typeMessage], [typeMessage])
  const bodyError = useMemo(() => BODY_ERROR[typeMessage], [typeMessage])

  return (
    <AdviceSection
      className={typificationNotificationClasses.styles}
      type={typeNotification}
      image={{ url: urlImage, alt: 'Not found' }}
      title={titleError}
      body={!isNull(customBody) ? customBody : bodyError}
      notShowCard
    />
  )
}

TypificationNotification.propTypes = {
  typeMessage: PropTypes.oneOf(TYPE_NOTIFICATIONS_TIPIS),
  typeNotification: PropTypes.string,
  customBody: PropTypes.string,
}
