import { call, put, takeLatest } from 'redux-saga/effects'
import { logError, formatErrorMessage } from 'services/logging'
import { axiosFactory } from 'modules/axios'

import { actions } from './actions'
import { FETCH_PORTABILITY_DATES } from './constants'
import { fetchPortabilityDatesAPI } from '../services'

export function* fetchPortabilityDatesSaga() {
  try {
    const dates = yield call(fetchPortabilityDatesAPI)
    yield put(actions.fetchPortabilityDatesSuccess(dates))
  } catch (e) {
    yield call(logError, new Error(formatErrorMessage(logError)))
    const error = axiosFactory.buildError(e)
    yield put(actions.fetchPortabilityDatesError(error))
  }
}

export function* watchfetchPortabilityDatesSaga() {
  yield takeLatest(FETCH_PORTABILITY_DATES, fetchPortabilityDatesSaga)
}
