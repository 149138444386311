export const ERROR_DATA_TEXT = 'Los datos introducidos son erróneos'
export const SAME_USER_TEXT = 'El documento no puede ser el mismo que el principal'
export const REQUIRED_FIELD_TEXT = 'El campo es obligatorio'
export const INVALID_MOBILE_NUMBER = 'Introduzca un número móvil válido'
export const INVALID_ICC_NUMBER = 'Introduzca un ICC válido'
export const INVALID_IBAN_NUMBER = 'El número de cuenta introducido no es valido'
export const INVALID_TYPE_HAS_NUMBERS = 'El tipo no puede contener números'
export const INVALID_NAME_HAS_NUMBERS = 'El nombre no puede contener números'
export const INVALID_SURNAME_HAS_NUMBERS = 'El apellido no puede contener números'
export const INVALID_SURNAME2_HAS_NUMBERS = 'El apellido 2 no puede contener números'
export const INVALID_NIF = 'Introduzca un NIF correcto'
export const INVALID_NIE = 'Introduzca un NIE correcto'
export const NUMBER_YOIGO = 'El número ya está activo en Yoigo.'
export const CANT_PORTABILITY = 'No se puede portar este número'
export const CANT_PORT_OPERATOR = 'La portabilidad de este operador no está permitida.'
export const PORTABILITY_DATE_NOT_VALID = 'Fecha de portabilidad no válida'
export const ORDER_ON_BOARDING = 'Ya hay una Orden de instalación en curso'
export const ALREADY_USER = 'El usuario ya es cliente'
export const DONOR_FETCH_ERROR =
  'Ha sucedido un error al intentar recuperar el operador donante. Por favor, inténtalo de nuevo.'
export const NEW_NUMBER_ERROR =
  'Ha sucedido un error al intentar generar un número. Por favor, inténtalo de nuevo'
export const NOT_AVAILABLE_NUM = 'No hay numeración disponible'
export const CLIENT_MAS_STACK =
  'Cliente existente, la venta se debe realizar en el nuevo stack / Agents / MySIM'
export const CLIENT_EXISTS = 'Debes realizar la venta como cliente existente.'
export const NUMBER_YOIGO_LONG = 'El número ya está activo en Yoigo, no es posible la portabilidad.'
export const CANT_CHECK_PHONE =
  'No se ha podido comprobar el teléfono. Por favor, inténtalo de nuevo.'
export const CANT_CHECK_USER =
  'No se ha podido comprobar si el usuario es cliente. Por favor, inténtalo de nuevo.'
export const CANT_CHECK_ROUTER_NUMBER =
  'No se ha podido comprobar el número de serie. Por favor, inténtalo de nuevo.'
export const ROUTER_NUMBER_INVALID = 'Número de serie no válido o en uso.'
export const ORDER_ON_BOARDING_MOBILE =
  'El número móvil seleccionado ya tiene una Orden de instalación en curso'
export const ON_BOARDING_DOC = 'Debes realizar la venta como cliente nuevo.'
export const ORDER_ON_BOARDING_LANDLINE =
  'El número fijo seleccionado ya tiene una Orden de instalación en curso'
export const INVALID_ZIP_CODE = 'El código postal no es válido'
export const INVALID_EMAIL = 'El email no es válido'
export const INVALID_FLAT = 'El piso no es válido'
export const INVALID_DOOR = 'La puerta no es válida'
export const ICC_NOT_FOUND = 'El ICC introducido no existe.'
export const ICC_FAILED =
  'Ha sucedido un error inesperado al intentar comprobar el ICC. Por favor, inténtalo de nuevo.'
export const IBAN_NOT_FOUND = 'El IBAN introducido no existe'
export const IBAN_ERROR =
  'Ha sucedido un error al intentar comprobar el IBAN. Por favor, inténtalo de nuevo'
export const OPERATOR_NOT_FOUND = 'El rango de numeración no pertenece a ningún operador'
export const OPERATOR_IS_MASMOVIL_BRAND =
  'En este momento no es posible tramitar esta portabilidad.'
export const UNEXPECTED_ERROR = 'Ha sucedido un error inesperado.'
export const UNEXPECTED_ERROR_RETRY =
  'Ha sucedido un error inesperado. Por favor, inténtalo de nuevo.'
export const MAIN_ORDER_ERROR = 'Ha habido un error en la generación del pedido principal'
export const SAME_PORTABILITY = 'No se puede repetir el número a portar'
export const TARIFFS_ERROR = 'No se han podido recuperar las tarifas'
export const TARIFFS_EMPTY = 'No hay tarifas disponibles'
export const CLIENT_EXISTS_DOC =
  'No se puede realizar la venta, cliente existente en otra marca del grupo'
export const NOT_REGISTERED_IMEI = 'Terminal no registrado'
export const NOT_VALID_IMEI = 'El IMEI introducido no es válido'
export const IMEI_IN_USE = 'Terminal en uso'
export const NOT_AVAILABLE_ICC = 'El ICC introducido no está disponible'
export const NOT_EXIST_ICC = 'El ICC introducido no existe'
export const NOT_VALID_ICC = 'El ICC introducido no es válido'
export const CARTERA_ERROR =
  'Se modificaran los datos para este pedido, pero no se han podido modificar a nivel Cliente en Vista'
export const EMAIL_NOT_AVAILABLE = 'El email no está disponible'
export const ORDER_LIMIT_REACHED = 'Se ha alcanzado el limite de suscripciones para este cliente'
export const ORDER_LIMIT_ERROR =
  'Ha sucedido un error al intentar comprobar el limite de suscripciones para este cliente'

export const PRESCORING_ERROR =
  'No se puede continuar la venta, no se ha pasado pre-scoring para este cliente'
export const PRESCORING_DENIEND =
  'El cliente no supera los criterios de riesgo. La venta no puede continuar'

export const CHECKOUT_ERROR_EXISTS =
  'Cliente ya existente, intenta realizar la venta como cliente existente'
export const CHECKOUT_ERROR_BARRING =
  'Cliente con bloqueo activo, no se permite finalizar la venta.'
export const CHECKOUT_ERROR_COMMON = 'Error sistema, reporte la incidencia'
export const CHECKOUT_ERROR_SUBSCRIPTION = 'Límite de suscripciones alcanzado'
export const CHECKOUT_ERROR_ONGOING =
  'Numeración asignada incorrecta, intenta realizar la venta de nuevo'
export const CHECKOUT_ERROR_MANDATORY_FIELDS =
  'No se han informado todos los datos de contacto correctamente'
export const CHECKOUT_ERROR_ROVE_FAILED = 'No es posible contactar'
export const CAMPAIGN_SELECT_TARIFF = 'Debe seleccionar una tarifa'
export const CAMPAIGN_EMPTY = 'Introduzca un código de campaña'
export const CAMPAIGN_LINE_INVALID = 'Campaña no compatible con los datos de la línea'
export const CAMPAIGN_INVALID = 'Campaña no valida'

export const SCORING_ERROR = 'Error: se ha producido un error en la consulta de Scoring'
export const SCORING_WAIT = 'Error: hay un problema, inténtalo pasados unos minutos'
export const SCORING_MISSING = 'Error: hay un problema en los datos'
export const SCORING_DENIEND =
  'El cliente no supera los criterios de riesgo. La venta no puede continuar'

export const CROSS_SELL_NOT_ALLOWED =
  'Conversión a convergente para esta linea móvil no permitida. La venta no puede continuar'

export const MULTISIM_NOT_ALLOWED = 'MultiSIM no permitido'
export const RENEWAL_WITHOUT_SEGMENTATION =
  'No se ha realizado la segmentación de valor para este cliente. No se puede realizar esta venta'
export const RENEWAL_MINIMUM_TIME_NOT_EXCEEDED_3 =
  'No se han cumplido 3 meses desde la activación de la suscripción. No se puede realizar esta venta.'
export const RENEWAL_MINIMUM_TIME_NOT_EXCEEDED_6 =
  'No se han cumplido 6 meses desde la activación de la suscripción con terminal o desde la última adquisición de terminal. No se puede realizar esta venta.'
export const RENEWAL_MINIMUM_TIME_NOT_EXCEEDED_12 =
  'No se han cumplido 12 meses desde la activación de la suscripción con terminal o desde la última adquisición de terminal. No se puede realizar esta venta.'
export const RENEWAL_NUMBER_NOT_VALID = 'Número inválido. No se puede realizar esta venta.'
export const RENEWAL_CUSTOMER_NUMBER_NOT_MATCH =
  'El número de cliente solicitado no coincide con el número cliente de la suscripción. No se puede realizar esta venta.'
export const RENEWAL_CUSTOMER_NOT_FOUND =
  'No se encuentra el cliente. No se puede realizar esta venta.'
export const RENEWAL_NOT_ALLOWED_FOR_FIXED_ONLY =
  'No se puede realizar renuevo sobre una línea solo fijo. No se puede realizar esta venta.'
export const RENEWAL_ORDER_NOT_FOUND = 'No se encuentra la orden. No se puede realizar esta venta.'
export const RENEWAL_ONGOING_MNP_OUT_REQUEST =
  'Portabilidad saliente en proceso. No se puede realizar esta venta.'
export const RENEWAL_BUSINESS_NOT_ALLOWED =
  'No se cumplen las condiciones de negocio. No se puede realizar esta venta.'
export const RENEWAL_PASSPORT_STC_NOT_ALLOWED =
  'Migración en proceso. No se puede realizar esta venta.'
export const RENEWAL_SEGMENTATION_CODE_NOT_FOUND =
  'No se encuentra segmentación para la suscripción. No se puede realizar esta venta.'
export const RENEWAL_GENERAL = 'No se puede realizar esta venta.'
export const RENEWAL_PENDING_STC = 'STC pendiente. No se puede realizar esta venta.'
export const RENEWAL_ONGOING_ACC = 'Cambio ACC pendiente. No se puede realizar esta venta.'
export const RENEWAL_ONGOING_REQUEST =
  'Solicitud de renuevo en proceso. No se puede realizar esta venta.'
export const RENEWAL_ONGOING_ORDER = 'Orden de renuevo en proceso. No se puede realizar esta venta.'
export const RENEWAL_POS_STC_BARRINGS = 'Barring STC activo. No se puede realizar esta venta.'

export const RENEWAL_IS_ADDITIONAL_LINE =
  'Si añades un Dispositivo a esta línea Adicional, perderá su descuento de tarifa'
export const RENEWAL_INVALID_IBAN =
  'El numero Iban del cliente no es valido. No se puede realizar esta venta.'
