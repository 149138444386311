import { useState } from 'react'
import { isFunction } from 'lodash'

export const useFocusHandler = props => {
  const [focused, setFocused] = useState(false)

  const onFocus = e => {
    setFocused(true)
    if (isFunction(props.onFocus)) props.onFocus(e)
  }

  const onBlur = e => {
    setFocused(false)
    if (isFunction(props.onBlur)) props.onBlur(e)
  }

  return {
    onFocus,
    onBlur,
    focused,
  }
}
