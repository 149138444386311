import { constants } from './actions'

const initialState = {
  data: null,
  error: null,
  errorCallsNodes: null,
  loading: false,
}

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case constants.FETCH: {
      return { ...initialState, loading: true }
    }

    case constants.FETCH_SUCCESS: {
      return {
        ...state,
        data: payload.data.data,
        errorCallsNodes: payload.data.errorCallsNodes,
        loading: false,
      }
    }

    case constants.FETCH_FAILED: {
      return {
        ...state,
        error: payload.error,
        loading: false,
      }
    }

    default:
      return state
  }
}
