import { useSelector, useDispatch } from 'react-redux'
import { createSelector } from 'reselect'
import { useFormikContext } from 'formik'
import { get } from 'lodash'

import { setTerminalReservations } from 'modules/NewClientSales/store/actions'
import { reserveTerminalById } from 'modules/Terminals/services'
import { LINE_TYPE } from 'modules/Lines/constants'

import {
  TELESALES,
  TELESALES_PRO,
  CC,
  CC_PRO,
  CHANNEL_CODES,
} from 'services/global-constants/channels'
import {
  selectChannelQueryParam,
  selectIsMobileOnly,
  selectIsElFijoSaleType,
  selectIsConvergent,
} from 'modules/Router'

const selectTerminalsReservationInfo = createSelector(
  values => values,
  values => {
    const mainMobileTerminalInfo = [
      {
        terminalId: get(values, `${LINE_TYPE.MAIN_MOBILE}.terminal.id`),
        field: `${LINE_TYPE.MAIN_MOBILE}.terminal.reservationId`,
        stock: get(values, `${LINE_TYPE.MAIN_MOBILE}.terminal.details.stock`),
      },
    ]
    const extraMobileTerminalInfo = values.extraMobileLinesOffers.map((line, i) => ({
      terminalId: get(line, 'terminal.id'),
      field: `extraMobileLinesOffers[${i}].terminal.reservationId`,
      stock: get(line, `terminal.details.stock`),
    }))
    return [...mainMobileTerminalInfo, ...extraMobileTerminalInfo].filter(elem => elem.terminalId)
  },
)

export const getChannelSoftReservation = createSelector(
  [selectChannelQueryParam, selectIsConvergent, selectIsMobileOnly, selectIsElFijoSaleType],
  (channelSelected, isConvergent, isMobileOnly, isFixedMobile) => {
    let channelSoftReservation = CHANNEL_CODES[TELESALES]

    const current_telesales = channelSelected === TELESALES_PRO ? TELESALES_PRO : TELESALES
    const current_cc = current_telesales === TELESALES_PRO ? CC_PRO : CC

    if (isConvergent) {
      channelSoftReservation = CHANNEL_CODES[current_telesales]
    } else if (isMobileOnly || isFixedMobile) {
      channelSoftReservation = CHANNEL_CODES[current_cc]
    }

    return channelSoftReservation
  },
)

export function useReserveTerminals() {
  const { values, setFieldValue } = useFormikContext()
  const dispatch = useDispatch()
  const terminalsInfo = useSelector(() => selectTerminalsReservationInfo(values))

  const channelSoftReservation = useSelector(getChannelSoftReservation)

  function setTerminalsReservationIdsInFormik(reservedTerminalsWithFieldName) {
    reservedTerminalsWithFieldName.forEach(({ formikFieldName, reservationId }) => {
      setFieldValue(formikFieldName, reservationId, true)
    })
  }

  function addReservationIdFieldNameToTerminals(reservedTerminals) {
    return reservedTerminals.map(reservedTerminal => ({
      ...reservedTerminal,
      formikFieldName: terminalsInfo.find(
        terminalInfo => terminalInfo.terminalId === reservedTerminal.terminalId,
      ).field,
    }))
  }

  function setTerminalsReservationIds(reservedTerminals) {
    const reservedTerminalsWithFieldName = addReservationIdFieldNameToTerminals(reservedTerminals)

    setTerminalsReservationIdsInFormik(reservedTerminalsWithFieldName)

    dispatch(setTerminalReservations({ terminals: reservedTerminalsWithFieldName }))
  }

  function buildTerminalReservationPromise(terminalId, channelId) {
    const terminalReservation = {
      terminalId,
      reservationId: '',
    }

    return new Promise((resolve, reject) => {
      reserveTerminalById(terminalId, channelId)
        .then(reservationId => {
          terminalReservation.reservationId = reservationId
          resolve(terminalReservation)
        })
        .catch(() => reject(terminalReservation))
    })
  }

  function onReserveTerminals() {
    setTerminalsReservationIdsInFormik(
      terminalsInfo.map(terminalInfo => ({
        formikFieldName: terminalInfo.field,
        reservationId: '',
      })),
    )

    return Promise.all(
      terminalsInfo
        .filter(terminalInfo => terminalInfo.stock > 0)
        .map(terminalInfo =>
          buildTerminalReservationPromise(terminalInfo.terminalId, channelSoftReservation).catch(
            unreservedTerminal => unreservedTerminal,
          ),
        ),
    ).then(terminalsReservationResults => setTerminalsReservationIds(terminalsReservationResults))
  }

  return { channelSoftReservation, onReserveTerminals }
}
