import PropTypes from 'prop-types'
import { useState } from 'react'

import { Box, FormControlLabel, Checkbox, Typography } from '@material-ui/core'

import { TextStyles } from 'utils/text'
import { AlertAdvice } from 'components/ui'

export function ChangeOwnerLines({ className, lines, onChange }) {
  const [checked, setChecked] = useState([])

  function isAllSelected(checks) {
    return checks.length && checks.every(value => !!value)
  }

  function selectAll() {
    if (isAllSelected(checked)) {
      setChecked(lines.map(() => undefined))
    } else {
      setChecked(lines.map(line => line.id))
    }
  }

  function toggleLine(line, index) {
    const newValues = [...checked]
    newValues[index] = newValues[index] ? undefined : line.id
    setChecked(newValues)
    return onChange && onChange(newValues)
  }

  return (
    <Box display="flex" flexDirection="column" className={className} alignItems="flex-start">
      <Typography {...TextStyles.body({ className: 'body' })}>
        Elige las líneas a las que les vas a realizar el cambio de titular.
      </Typography>

      <Typography {...TextStyles.labelLink({ className: 'label_link' })} onClick={selectAll}>
        {isAllSelected(checked) ? 'Desmarcar todas' : 'Seleccionar todas'}
      </Typography>

      <Box
        display="flex"
        justifyContent="space-between"
        flexWrap="wrap"
        flexDirection="row"
        width="100%">
        {lines.map((line, index) => (
          <FormControlLabel
            key={line.id}
            control={
              <Checkbox
                data-hook={checked[index] ? 'checkbox-checked' : 'checkbox'}
                checked={!!checked[index]}
                onChange={() => toggleLine(line, index)}
                value={line.id}
              />
            }
            label={`${line.line} - ${line.description}`}
          />
        ))}
      </Box>

      <AlertAdvice message="Hay líneas cabeceras NO asignadas, puedes estar perdiendo los descuentos." />
    </Box>
  )
}

ChangeOwnerLines.defaultProps = {
  lines: [],
}

ChangeOwnerLines.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  lines: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      phoneNumber: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
}
