import PropTypes from 'prop-types'
import { CardTitle, CardRow, CardRowLabel, CardRowValue, WizardNavigation } from 'components'
import { modelData } from '../AddressChange/address.helper'

export function AddressDetails({ customerInfo, next }) {
  const customer = modelData(customerInfo)
  const { addressInstallation } = customer
  return (
    <>
      <CardTitle> Modificación del pedido</CardTitle>
      <CardRow>
        <CardRowLabel>Dirección de instalación:</CardRowLabel>
        <CardRowValue>{addressInstallation}</CardRowValue>
      </CardRow>
      <WizardNavigation
        next={{
          callback: next,
          disabled: false,
          caption: 'Siguiente',
        }}
      />
    </>
  )
}

AddressDetails.defaultProps = {
  customerInfo: {},
}

AddressDetails.propTypes = {
  next: PropTypes.func.isRequired,
  customerInfo: PropTypes.object,
}
