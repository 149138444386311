import { memo } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import styled from 'styled-components'

import { Typography, Box } from '@material-ui/core'
import { TextStyles } from 'utils/text'

import { SectionHeader } from 'modules/ui'
import { history } from 'modules/Router'

import { SpinnerCenter } from 'components/ui'
import { BadgeStatusDetail } from 'components'

import { IframeCard } from '../../components/IframeCard'
import { LogisticCard } from '../../components/LogisticCard'

import { SellData } from './SellData'
import DocumentsTable from './DocumentsTable'
import { OrderProducts } from '../OrderProducts'

const ORDER_TYPE_CONVERGENT = 'Convergente'

const Wrapper = styled.div`
  .card {
    width: 100%;
    margin-bottom: 24px;
  }

  .card iframe {
    height: 356px;
  }
`

function goBack() {
  history.goBack()
}

export const OrderDetail = memo(
  ({
    orderId,
    isLoadingTab,
    dataLogistic,
    isLoadingLogistic,
    isPosOrMasProximo,
    dataDocuments,
    dataSellInfo,
    offers,
    isLoadingProducts,
    errorProducts,
    orderStatus,
    isLoadingAtc,
    hasErrorsAtc,
    iframeUrlAtc,
  }) => {
    const showOrderAtcCard = !isPosOrMasProximo && orderStatus?.type === ORDER_TYPE_CONVERGENT

    const titleAndSubtitle = (
      <>
        <Typography
          {...TextStyles.title3Dark({
            className: 'title',
          })}>
          Pedido {orderId}
        </Typography>

        <BadgeStatusDetail
          className="p-y-12"
          superStatus={get(orderStatus, 'superStatus')}
          status={get(orderStatus, 'status')}
          subStatus={get(orderStatus, 'subStatus')}
          statusOt={get(orderStatus, 'statusOt')}
          categoryOt={get(orderStatus, 'categoryOt')}
          icon="home"
        />
      </>
    )

    return (
      <>
        {isLoadingTab && <SpinnerCenter showMsg />}

        {!isLoadingTab && (
          <Wrapper>
            <SectionHeader title="" titleExt={titleAndSubtitle} onBack={goBack} />

            <Box display="flex" flexDirection="column" alignItems="flex-start" p="24px">
              <DocumentsTable data={dataDocuments} isPosOrMasProximo={isPosOrMasProximo} />

              <SellData data={dataSellInfo} isPosOrMasProximo={isPosOrMasProximo} />

              <OrderProducts
                offers={offers}
                loading={isLoadingProducts}
                errors={!!errorProducts}
                className="full-width"
              />

              {showOrderAtcCard && (
                <IframeCard
                  id="order-atc-card"
                  title="Resumen provisión"
                  url={iframeUrlAtc}
                  loading={isLoadingAtc}
                  error={hasErrorsAtc}
                />
              )}

              <LogisticCard
                data={
                  dataLogistic &&
                  dataLogistic.filter(
                    data =>
                      // eslint-disable-next-line eqeqeq
                      data.orderID == orderId,
                  )
                }
                loading={isLoadingLogistic}
                isPosOrMasProximo={isPosOrMasProximo}
              />
            </Box>
          </Wrapper>
        )}
      </>
    )
  },
)

OrderDetail.propTypes = {
  orderId: PropTypes.string,
  isLoadingTab: PropTypes.bool,
  dataLogistic: PropTypes.object,
  isLoadingLogistic: PropTypes.bool,
  isPosOrMasProximo: PropTypes.bool,
  dataDocuments: PropTypes.object,
  dataSellInfo: PropTypes.object,
  offers: PropTypes.array,
  isLoadingProducts: PropTypes.bool,
  errorProducts: PropTypes.object,
  orderStatus: PropTypes.object,
  isLoadingAtc: PropTypes.bool,
  hasErrorsAtc: PropTypes.bool,
  iframeUrlAtc: PropTypes.string.isRequired,
}
