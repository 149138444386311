import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'

import { TerminalsSidebar } from './TerminalsSidebar'
import { OffersSidebar } from './OffersSidebar/OffersSidebar'
import { ClientInfoSidebar } from './ClientInfoSidebar'
import { TotalMonthlyPaymentsSidebar } from './TotalMonthlyPaymentsSidebar'

const useStyles = makeStyles(theme => ({
  sidebar: {
    backgroundColor: theme.palette.brand.medium,
    height: '100%',
  },
}))

const Sidebar = ({
  showClientInfo,
  showOffers,
  showTerminals,
  hasTerminalsWithSeveralPayment,
  totalMonthlyFeeWithTerminals,
  ...props
}) => {
  const classes = useStyles()

  return (
    <div className={classes.sidebar}>
      {showClientInfo ? <ClientInfoSidebar {...props} /> : null}
      {showOffers ? <OffersSidebar {...props} /> : null}
      {showTerminals ? <TerminalsSidebar {...props} /> : null}
      {hasTerminalsWithSeveralPayment && (
        <TotalMonthlyPaymentsSidebar payments={totalMonthlyFeeWithTerminals} />
      )}
    </div>
  )
}

Sidebar.propTypes = {
  showClientInfo: PropTypes.bool.isRequired,
  totalMonthlyFeeWithTerminals: PropTypes.any,
  showOffers: PropTypes.bool.isRequired,
  showTerminals: PropTypes.bool.isRequired,
  hasTerminalsWithSeveralPayment: PropTypes.bool.isRequired,
}

export default Sidebar
