import { Component } from 'react'
import { PropTypes } from 'prop-types'
import styled from 'styled-components'
import { Paper, CircularProgress } from '@material-ui/core'
import { SectionGrid } from 'components'
import { Button } from 'components/ui'
import { featureFlag } from 'services/feature-flag'
import { isMobilePhoneNumber } from 'utils'
import { getDocumentType } from 'modules/CustomerDocument'

const StyledButton = styled(Button)`
  margin: 10px;
`

const StyledCancelButton = styled(Button)`
  width: 25%;
  margin: auto;
  margin-top: 20px;
`
const StyledPaper = styled(Paper)`
  position: absolute;
  top: 20%;
  font-size: 1.2rem;
  padding: 20px;
  border-radius: 5px;
`

const ErrorTitle = styled.h2`
  font-weight: bold;
`

const ErrorMessage = styled.p`
  margin-top: 10px;
  font-size: 16px;
`

// TODO:This looks useful for other screens refactor into a component
export const StartJourneyBtn = ({ to, title, disabled, datahook }) => {
  return (
    <StyledButton data-hook={datahook} disabled={disabled} to={to}>
      {title}
    </StyledButton>
  )
}

const ChangeAddressFlagBtn = StartJourneyBtn
const ChangeTechnologyFlagBtn = StartJourneyBtn
const OnFlyTechNebaNewBtn = StartJourneyBtn

const OnFlyTechNebaBtn = featureFlag('neba_enabled')(OnFlyTechNebaNewBtn, () => null)
const ChangeAddressBtn = featureFlag('change_address_v1')(ChangeAddressFlagBtn, () => null)
const ChangeTechnologyBtn = featureFlag('change_technology_v1')(ChangeTechnologyFlagBtn, () => null)

export class ChangeServices extends Component {
  state = {
    loading: true,
  }

  componentDidMount = () => {
    if (this.props.fixedNumber) {
      this.props.getSubscriptionData(this.props.fixedNumber)
    }
  }

  static getDerivedStateFromProps(nextProps) {
    if (nextProps.loading !== null) {
      return {
        loading: nextProps.loading,
      }
    }
    return null
  }

  handleCancel = () => {
    window.close()
  }

  buildUrlVista = () => {
    const { documentId, redirect } = this.props
    let basePath = redirect || '/vista/dashboard'
    if (documentId) {
      const documentType = getDocumentType(documentId.toUpperCase())
      basePath += `/documentType/${documentType}/documentId/${documentId.toUpperCase()}`
    }

    return `${basePath}`
  }

  render() {
    const {
      onFlyDirection,
      onFlyTechnology,
      onFlyTechNeba,
      errorOnFly,
      isCancelNebaEnabled,
      isVistaEnabled,
      fixedNumber,
      query,
      isOffersAuthorized,
      loadingSession,
    } = this.props
    const { loading } = this.state

    const isMobileNumber = !fixedNumber || isMobilePhoneNumber(fixedNumber)
    let data = (
      <SectionGrid height="100vh" alignitems="center">
        <CircularProgress size={100} />
      </SectionGrid>
    )

    let TextComp

    if (isMobileNumber) {
      TextComp = (
        <StyledPaper>
          <ErrorTitle>Operación no permitida.</ErrorTitle>
          <ErrorMessage>
            No se puede realizar una operación de cambio de tecnología o domicilio sobre una línea
            móvil
          </ErrorMessage>
          <StyledCancelButton onClick={this.handleCancel} color="secondary">
            Salir
          </StyledCancelButton>
        </StyledPaper>
      )
    }

    if (onFlyDirection) {
      TextComp = (
        <StyledPaper>
          <ErrorTitle>Operación no permitida.</ErrorTitle>
          <ErrorMessage>El cliente tiene una orden abierta de cambio de dirección.</ErrorMessage>
          <StyledCancelButton onClick={this.handleCancel} color="secondary">
            Salir
          </StyledCancelButton>
        </StyledPaper>
      )
    }
    if (onFlyTechnology) {
      TextComp = (
        <StyledPaper>
          <ErrorTitle>Operación no permitida.</ErrorTitle>
          <ErrorMessage>El cliente tiene una orden abierta de cambio de tecnología.</ErrorMessage>
          <StyledCancelButton onClick={this.handleCancel} color="secondary">
            Salir
          </StyledCancelButton>
        </StyledPaper>
      )
    }
    if (errorOnFly) {
      TextComp = (
        <StyledPaper>
          <ErrorTitle>
            No se puede continuar con la operación. Se ha producido un error en el sistema.
          </ErrorTitle>
          <ErrorMessage>Imposible comprobar si existe una orden abierta</ErrorMessage>
          <StyledCancelButton onClick={this.handleCancel} color="secondary">
            Salir
          </StyledCancelButton>
        </StyledPaper>
      )
    }

    const OnflyStatusMsgComp = () =>
      onFlyTechNeba && !errorOnFly ? (
        <StyledPaper>
          <ErrorTitle>Ya hay una orden en vuelo.</ErrorTitle>
          <ErrorMessage>Consulte estado pulsando el botón Status.</ErrorMessage>
          <StyledCancelButton onClick={this.handleCancel} color="secondary">
            Salir
          </StyledCancelButton>
        </StyledPaper>
      ) : (
        <>{TextComp}</>
      )

    const OnFlyMsgComp = isCancelNebaEnabled ? OnflyStatusMsgComp : () => <>{TextComp}</>

    if (!loading || isMobileNumber) {
      data = (
        <>
          <OnFlyMsgComp />
          <ChangeTechnologyBtn
            datahook="changeTechnology"
            to={`/clients/change-technology?${query}`}
            title="Cambio de tecnología"
            disabled={onFlyDirection || onFlyTechnology || isMobileNumber || errorOnFly}
          />
          <ChangeAddressBtn
            datahook="btnModifyAddress"
            to={`/clients/move-client?${query}`}
            title="Cambio de domicilio"
            disabled={onFlyDirection || onFlyTechnology || isMobileNumber || errorOnFly}
          />
          <OnFlyTechNebaBtn
            datahook="btnStatusOrderOnFly"
            to={`/clients/status-order-on-fly?${query}`}
            title="Status de Orden en Vuelo"
            disabled={!isCancelNebaEnabled || !onFlyTechNeba || isMobileNumber || errorOnFly}
          />

          {isOffersAuthorized && (
            <StyledButton dataHook="btnConfigureOffer" to={`/offers?${query}`}>
              Configurador de Ofertas
            </StyledButton>
          )}
        </>
      )
    }

    return (
      <div>
        <SectionGrid height="100vh" alignitems="center">
          {data}
          {!loadingSession && isVistaEnabled && (
            <StyledButton dataHook="btnVista360" to={this.buildUrlVista()}>
              MASCare
            </StyledButton>
          )}
        </SectionGrid>
      </div>
    )
  }
}

ChangeServices.propTypes = {
  fixedNumber: PropTypes.string,
  isOffersAuthorized: PropTypes.bool,
  getSubscriptionData: PropTypes.func.isRequired,
  query: PropTypes.object,
  onFlyDirection: PropTypes.bool,
  onFlyTechnology: PropTypes.bool,
  onFlyTechNeba: PropTypes.bool,
  errorOnFly: PropTypes.bool,
  isCancelNebaEnabled: PropTypes.bool,
  isVistaEnabled: PropTypes.bool,
  loadingSession: PropTypes.bool,
  documentId: PropTypes.string,
  redirect: PropTypes.string,
}

StartJourneyBtn.propTypes = {
  to: PropTypes.string,
  title: PropTypes.string,
  disabled: PropTypes.bool,
  datahook: PropTypes.string,
}
