import { call, put, takeEvery, select, all } from 'redux-saga/effects'
import { logError, formatErrorMessage } from 'services/logging'
import { axiosFactory } from 'modules/axios'
import { selectCustomerOrAgentToken, selectCustomerId } from 'modules/Auth'

import { actions } from './actions'
import * as constants from './constants'
import { findProvisioning } from './api'

export function* findProvisioningSaga(payload) {
  try {
    const token = yield select(selectCustomerOrAgentToken)
    const customerId = yield select(selectCustomerId)
    const provisioning = yield call(findProvisioning, payload.zipCode, customerId, token)
    yield put(actions.foundProvisioning({ data: provisioning }))
  } catch (e) {
    yield call(logError, { e: new Error(formatErrorMessage(e)), payload })
    const error = axiosFactory.buildError(e)
    yield put(actions.findProvisioningFailed(error))
  }
}

function* watchFindProvisioning() {
  yield takeEvery(constants.FIND_PROVISIONING, findProvisioningSaga)
}

export function* watchProvisioningSagas() {
  yield all([watchFindProvisioning()])
}
