/* eslint-disable import/no-cycle */
import { call, put, select, takeLatest } from 'redux-saga/effects'
import { logError, formatErrorMessage } from 'services/logging'
import { axiosFactory } from 'modules/axios'
import { selectSfid, selectCustomerOrAgentToken } from 'modules/Auth'

import { get, isNil } from 'lodash'
import { selectCoverageV1 } from 'services/feature-flag/selectors'
import { actions, coverageActionTypes } from './actions'

import { findCoverage, fetchCoverageToken } from '../services'
import * as selectors from './selectors'

export function* findCoverageSaga({ payload: { gescal } }) {
  try {
    const coverageV1 = yield select(selectCoverageV1)
    const gescal37 = isNil(gescal) ? yield select(selectors.getGescal37) : gescal.padEnd(37)
    const coverage = yield call(findCoverage, gescal37, coverageV1)

    yield put(actions.findCoverageSuccess({ data: coverage }))
  } catch (e) {
    yield call(logError, new Error(formatErrorMessage(e)))
    const error = axiosFactory.buildError(e)
    yield put(actions.findCoverageFailed(error))
  }
}

export function* watchFindCoverage() {
  yield takeLatest(coverageActionTypes.FIND_COVERAGE, findCoverageSaga)
}

export function* getCoverageTokenSaga(action = {}) {
  try {
    // TODO: remove customer token
    const token = yield select(selectCustomerOrAgentToken)
    const customerData = yield select(selectors.getCustomerData)
    const externalAddress = get(action, 'payload.externalAddress')
    const sfid = yield select(selectSfid)
    const coverageToken = yield call(
      fetchCoverageToken,
      token,
      customerData,
      externalAddress,
      sfid,
      get(action, 'payload.isOvid', false),
    )

    yield put(actions.getCoverageTokenSuccess(coverageToken))
  } catch (e) {
    yield call(logError, { e: new Error(formatErrorMessage(e)), action })
    const error = axiosFactory.buildError(e)
    yield put(actions.getCoverageFailed(error))
  }
}

export function* watchGetCoverageToken() {
  yield takeLatest(coverageActionTypes.GET_COVERAGE_TOKEN, getCoverageTokenSaga)
}

export function* getCoverageSaga({ payload: { coverage } }) {
  yield put(actions.getCoverageSuccess(coverage))
}

export function* watchGetCoverage() {
  yield takeLatest(coverageActionTypes.GET_COVERAGE, getCoverageSaga)
}
