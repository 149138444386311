import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { get } from 'lodash'
import { OfferItem, OfferItemModified } from 'components/ui'
import { formatMonthlyFee } from 'utils'

import { getCommercialName, selectTariffsApigeeById } from 'modules/tariffs/store-apigee'
import { getSubscriptionTariffId } from 'services/subscriptions'

import { canModifySubscription } from '../selectors'
import { TariffsModalContainer } from '../containers/TariffsModalContainer'

import { useChangedTariff } from '../hooks/useChangedTariff'
import { useUpdateSubscriptionHandlers } from '../hooks/useUpdateSubscriptionHandlers'

import { useTaxApplied } from '../hooks/useTaxApplied'

export function TariffItem({ subscription, onModalOpen, onTitleChange }) {
  const { changedTariff, allowUndoChanges } = useChangedTariff(subscription)
  const disabled = !useSelector(state => canModifySubscription(state, subscription))
  const { onUpdateSubscription } = useUpdateSubscriptionHandlers(subscription)

  const { changedTariffFee } = useTaxApplied({
    changedTariffFee: get(changedTariff, 'monthly_fee', 0),
  })

  const tariff = useSelector(selectTariffsApigeeById(getSubscriptionTariffId(subscription)))

  return !changedTariff ? (
    <OfferItem
      label="Tarifa"
      value={getCommercialName(tariff)}
      actionText="Modificar tarifa"
      actionClick={() => {
        onModalOpen(
          TariffsModalContainer,
          {
            onConfirm: onUpdateSubscription,
            subscription,
            onTitleChange,
          },
          'Modificar tarifa',
        )
      }}
      disabled={disabled}
    />
  ) : (
    <OfferItemModified
      title={`Tarifa: ${subscription.description}`}
      labels={[
        `Nueva: ${get(changedTariff, 'commercial_name', '')}`,
        `Cuota: ${formatMonthlyFee(changedTariffFee)}`,
      ]}
      onRemove={
        allowUndoChanges
          ? () => onUpdateSubscription({ confirmedTariff: null, date: null })
          : undefined
      }
    />
  )
}

TariffItem.propTypes = {
  subscription: PropTypes.object.isRequired,
  onModalOpen: PropTypes.func.isRequired,
  onTitleChange: PropTypes.func,
}
