export const AUTH_LOGIN = 'Auth/AUTH_LOGIN'
export const AUTH_SUCCESS = 'Auth/AUTH_SUCCESS'
export const AUTH_ERROR = 'Auth/AUTH_ERROR'
export const AUTH_LOGOUT = 'Auth/AUTH_LOGOUT'
export const AUTH_CUSTOMER_INIT = 'Auth/AUTH_CUSTOMER_INIT'
export const AUTH_CUSTOMER_SUCCESS = 'Auth/AUTH_CUSTOMER_SUCCESS'
export const AUTH_CUSTOMER_ERROR = 'Auth/AUTH_CUSTOMER_ERROR'
export const AUTH_FINISH = 'Auth/AUTH_FINISH'
export const AUTH_RESET_DOCUMENT_ID = 'Auth/AUTH_RESET_DOCUMENT_ID'
export const AUTH_SAVE_ACCESS_TOKEN = 'Auth/SAVE_ACCESS_TOKEN'
export const REQUEST_IP = 'Auth/REQUEST_IP'
export const REQUEST_IP_ERROR = 'Auth/REQUEST_IP_ERROR'
export const REQUEST_IP_SUCCESS = 'Auth/REQUEST_IP_SUCCESS'

function authLogin({ username, password }) {
  return {
    type: AUTH_LOGIN,
    payload: {
      username,
      password,
    },
  }
}

function authSuccess({ accessToken, agentToken }) {
  return {
    type: AUTH_SUCCESS,
    payload: { accessToken, agentToken },
  }
}

function saveAccessToken(accessToken) {
  return {
    type: AUTH_SAVE_ACCESS_TOKEN,
    payload: accessToken,
  }
}

function authError(error) {
  return {
    type: AUTH_ERROR,
    payload: error,
  }
}

function logout() {
  return {
    type: AUTH_LOGOUT,
  }
}

function authCustomerInit(documentId = '', changeServices = false) {
  return {
    type: AUTH_CUSTOMER_INIT,
    payload: { documentId: documentId.toUpperCase(), changeServices },
  }
}

function authCustomerSuccess({
  customerToken,
  customerId,
  documentId,
  accountId,
  isCustomer,
  migrationStatus,
}) {
  return {
    type: AUTH_CUSTOMER_SUCCESS,
    payload: { customerToken, customerId, documentId, accountId, isCustomer, migrationStatus },
  }
}

function authCustomerError(error) {
  return {
    type: AUTH_CUSTOMER_ERROR,
    payload: error,
  }
}

function authFinish() {
  return {
    type: AUTH_FINISH,
  }
}

function resetDocumentId() {
  return {
    type: AUTH_RESET_DOCUMENT_ID,
  }
}

function requestIpAddress(payload) {
  return {
    type: REQUEST_IP,
    payload,
  }
}

function requestIpAddressSuccess(payload) {
  return {
    type: REQUEST_IP_SUCCESS,
    payload,
  }
}

function requestIpAddressError(payload) {
  return {
    type: REQUEST_IP_ERROR,
    payload,
  }
}

export const actions = {
  authLogin,
  authSuccess,
  authError,
  logout,
  authCustomerInit,
  authCustomerSuccess,
  authCustomerError,
  authFinish,
  resetDocumentId,
  saveAccessToken,
  requestIpAddress,
  requestIpAddressSuccess,
  requestIpAddressError,
}
