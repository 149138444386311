import { constants } from './constants'

const getTerritCompatInit = data => {
  return {
    type: constants.GET_TERRIT_COMPAT_INIT,
    payload: data,
  }
}
const getTerritCompatSuccess = data => {
  return {
    type: constants.GET_TERRIT_COMPAT_SUCCESS,
    payload: {
      ...data,
    },
  }
}

const getTerritCompatFailed = error => ({
  type: constants.GET_TERRIT_COMPAT_ERROR,
  payload: { error },
})

export const actions = {
  getTerritCompatInit,
  getTerritCompatSuccess,
  getTerritCompatFailed,
}
