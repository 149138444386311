import { mapKeys } from 'lodash'

let contactMediaPosition = 0
export function generateContactMedia({ medium, mediumType, role }) {
  if (!medium || !mediumType || !role) {
    throw new Error(
      'missing parameters when calling generateContactMedia. You probably missed passing medium, mediumType or role to your generator',
    )
  }

  contactMediaPosition += 1
  return {
    id: `temp-id-contact-media-${contactMediaPosition}`,
    type: 'contact-media',
    attributes: {
      role,
      medium,
      medium_type: mediumType,
    },
  }
}

let billingAccountPosition = 0
export function generateBillingAccount({ attributes, billingProfileAttributes }) {
  if (!attributes || !billingProfileAttributes) {
    throw new Error(
      'missing parameters when calling generateBillingAccount. You probably missed passing attributes or billingProfileAttributes to your generator',
    )
  }

  billingAccountPosition += 1
  return {
    id: `temp-id-billing-account-${billingAccountPosition}`,
    type: 'billing-accounts',
    attributes,
    relationships: {
      billing_profile: [
        {
          id: `temp-id-billing-profile-${billingAccountPosition}`,
          type: 'billing-profiles',
          attributes: billingProfileAttributes,
        },
      ],
    },
  }
}

let orderDiscountPosition = 0
export function generateOrderDiscount(id) {
  orderDiscountPosition += 1

  return {
    id: `temp-id-orders-discounts-${orderDiscountPosition}`,
    type: 'order-discounts',
    relationships: {
      discount: [
        {
          id,
          type: 'discounts',
        },
      ],
    },
  }
}

let orderProductPosition = 0
export function generateOrderProduct({ inputtedCharacteristics, productOfferingId, discounts }) {
  const newInputtedCharacteristics = mapKeys(inputtedCharacteristics, (v, k) => k.toLowerCase())
  orderProductPosition += 1
  const orderProduct = {
    id: `temp-id-order-products-${orderProductPosition}`,
    type: 'order-products',
    attributes: {
      inputted_characteristics: newInputtedCharacteristics,
    },
    relationships: {
      product_offering: [
        {
          id: productOfferingId,
          type: 'product-offerings',
        },
      ],
    },
  }

  if (discounts?.length > 0) {
    orderProduct.relationships.discounts = discounts?.map(discount =>
      generateOrderDiscount(discount),
    )
  }

  return orderProduct
}

let identificationPosition = 0
export function generateIdentification({ attributes }) {
  if (!attributes) {
    throw new Error(
      'missing parameters when calling generateIdentification. You probably missed passing attributes to your generator',
    )
  }

  identificationPosition += 1
  return {
    id: `temp-id-identifications-${identificationPosition}`,
    type: 'identifications',
    attributes,
  }
}
