export const nebaResponse = {
  gescal: '10000110088300054         BA         ',
  coverage: [
    {
      ull: [],
      ftth: [
        '300M_300M',
        '1M_1M',
        '10M_1M',
        '10M_3M',
        '20M_1M',
        '20M_3M',
        '25M_1M',
        '25M_3M',
        '25M_5M',
        '25M_10M',
        '30M_1M',
        '30M_3M',
        '30M_5M',
        '30M_10M',
        '30M_30M',
        '50M_5M',
        '50M_50M',
        '120M_120M',
      ],
      characteristics: [
        {
          name: 'territoryOwner',
          value: 'FIBNEB01',
        },
        {
          name: 'speedEstimated',
          value:
            '300M_300M || 1M_1M || 10M_1M || 10M_3M || 20M_1M || 20M_3M || 25M_1M || 25M_3M || 25M_5M || 25M_10M || 30M_1M || 30M_3M || 30M_5M || 30M_10M || 30M_30M || 50M_5M || 50M_50M || 120M_120M',
        },
        {
          name: 'addressId',
          value: '10000110088300054         BA',
        },
        {
          name: 'telefonicaCoverage',
          value: 'false',
        },
      ],
    },
  ],
  phoneNumber: 'undefined',
  province: 'CACERES',
  town: 'Caceres',
  streetType: 'Calle',
  street: 'Federico Mayor Zaragoza',
  number: '54',
  bis_duplicate: '',
  zipCode: '10004',
  block: '',
  stair: '',
  door: '',
  letter: '',
  floor: 'BA ',
  Hand: '',
  Km: '',
  addressId: '10000110088300054         BA',
  sessionId: 'e6fb9c75-f70a-41d3-8cb1-d8de68620577',
}
