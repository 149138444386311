import { actionTypes } from 'modules/typifications/constants'

const initialState = {
  data: {
    tokenIframe: null,
    uuid: null,
    channel: null,
    customer_id: null,
    msisdns: [],
    conversation_id: null,
    initial_fee: null,
    sfid: null,
    area: null,
    brand: null,
    campaignByEmail: null,
    isSecurizedToken: false,
  },
  error: null,
  loading: false,
}

export function dataTypificationsReducer(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case actionTypes.DATA_TYPIFICATIONS_INIT: {
      return {
        ...state,
        data: {
          tokenIframe: payload.token,
        },
        loading: true,
      }
    }

    case actionTypes.DATA_TYPIFICATIONS_SUCCESS: {
      return {
        data: { ...state.data, ...payload },
        loading: false,
      }
    }

    case actionTypes.DATA_TYPIFICATIONS_FAILED: {
      return {
        ...state,
        error: payload,
        loading: false,
      }
    }

    default:
      return state
  }
}
