import { constants } from './add-agiletv.constants'

const initialState = {
  validation: {
    loading: false,
    error: null,
    success: false,
  },
  add: {
    loading: false,
    error: null,
    success: false,
  },
}

export const reducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case constants.FETCH_ADD_AGILETV_ALLOWED: {
      return {
        ...state,
        validation: {
          loading: true,
          error: null,
          success: false,
        },
      }
    }

    case constants.FETCH_ADD_AGILETV_ALLOWED_SUCCESS: {
      return {
        ...state,
        validation: {
          loading: false,
          error: null,
          success: true,
        },
      }
    }

    case constants.FETCH_ADD_AGILETV_ALLOWED_FAILED: {
      return {
        ...state,
        validation: {
          loading: false,
          error: payload.rejectionReason,
          success: false,
        },
      }
    }

    case constants.ADD_AGILETV: {
      return {
        ...state,
        add: {
          loading: true,
          error: null,
          success: false,
        },
      }
    }

    case constants.ADD_AGILETV_SUCCESS: {
      return {
        ...state,
        add: {
          loading: false,
          error: null,
          success: true,
        },
      }
    }

    case constants.ADD_AGILETV_FAILED: {
      return {
        ...state,
        add: {
          loading: false,
          error: payload,
          success: false,
        },
      }
    }

    default:
      return state
  }
}
