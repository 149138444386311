import { useSelector } from 'react-redux'
import { orderBy, head } from 'lodash'
import { getCdctWorkOrderIdsBySubscriptionId } from 'services/subscriptions/selectors'

export const useCDCTWorkOrderIds = (subscription_id) => {
  const CdctWorkOrderIds = useSelector(getCdctWorkOrderIdsBySubscriptionId(subscription_id))

  const sortedWorkOrdersByDate = orderBy(CdctWorkOrderIds, ['createAt'], ['desc'])
  const lastWorkOrderId = head(sortedWorkOrdersByDate)?.workorderId

  return lastWorkOrderId
}
