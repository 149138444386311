import { put, select, take } from 'redux-saga/effects'

import {
  registerOfferAction,
  REGISTER_OFFER_SUCCESS,
  REGISTER_OFFER_ERROR,
} from './activity-register.actions'

import { selectActivityErrorMessageByMSISDN } from './activity-register.selectors'

export function* callRegisterOffer(msisdn, offer) {
  yield put(registerOfferAction(msisdn, offer))
  yield take(
    action =>
      (action.type === REGISTER_OFFER_SUCCESS || action.type === REGISTER_OFFER_ERROR) &&
      action.payload.msisdn === msisdn,
  )

  return yield select(selectActivityErrorMessageByMSISDN(msisdn))
}
