import {
  PUT_ORDER_DISCOUNTS_START,
  PUT_ORDER_DISCOUNTS_SUCCESS,
  PUT_ORDER_DISCOUNTS_FAILED,
} from './constants'

export const putOrderDiscountsStart = (orderId, dataDiscount) => ({
  type: PUT_ORDER_DISCOUNTS_START,
  payload: {
    orderId,
    dataDiscount,
  },
})

export const putOrderDiscountsSuccess = payload => ({
  type: PUT_ORDER_DISCOUNTS_SUCCESS,
  payload,
})

export const putOrderDiscountsFailed = error => ({
  type: PUT_ORDER_DISCOUNTS_FAILED,
  payload: {
    error,
  },
})
