import { get, isEmpty } from 'lodash'
import { createSelector } from 'reselect'

const selectProductDashboard = state => get(state, 'vista360ProductDashboardLoad')

export const selectIsLoading = createSelector(
  selectProductDashboard,
  products => get(products, 'loading'),
)

export const selectHasErrors = createSelector(
  selectProductDashboard,
  products => !isEmpty(get(products, 'error')),
)
