export const CONSUMER = 'Consumer'
export const AUTONOMO = 'Autonomo'
export const COMPANY = 'Company'

export const CARTERA_SEGMENTS = {
  CONSUMER,
  AUTONOMO,
  COMPANY,
  'SOHO-AUTONOMO': 'Soho-autonomo',
  'SOHO-COMPANY': 'Soho-company',
  'PRO-SOHO-AUTONOMO': 'Pro-soho-autonomo',
  'PRO-SOHO-COMPANY': 'Pro-soho-company',
}
