import { ButtonLink } from 'components/ui'
import { useChannelAndSegment } from 'modules/NewClientSales/hooks/Shared'
import {
  onResetTerminal,
  useTerminalListForPs,
  useTerminalSelectionStore,
} from 'modules/NewClientSales/hooks/Terminals'
import { Terminal } from 'modules/NewClientSales/store/models'
import { TerminalSelector } from 'modules/Terminals'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { DEVICE_SET_TOP_BOX } from 'modules/NewClientSales/constants'
import { selectIsPosSale, selectIsElFijo } from 'modules/NewClientSales/store/'

import { selectResellerQueryParam } from 'modules/Router/store/index'
import { mapToTerminalValues } from '../../OfferContainer/TerminalSelectionContainer/TerminalSelectionContainer.helpers'
import { getFormikProps } from './TerminalStockContainer.helpers'

export function TerminalStockContainer() {
  const isElFijo = useSelector(selectIsElFijo)

  const isPosSale = useSelector(selectIsPosSale)
  const reseller = useSelector(selectResellerQueryParam)

  const { channel } = useChannelAndSegment()

  const {
    setFieldValue,
    terminalCategory,
    terminalBrand,
    terminalId,
    mobileNumberType,
    ps,
  } = getFormikProps(isElFijo)

  const { fetchTerminalDetails, terminalDetails } = useTerminalSelectionStore('stock', '')

  const { resetTerminalData } = onResetTerminal()

  const {
    isLoadingTerminalList,
    terminalListError,
    terminalList,
    fetchTerminals,
  } = useTerminalListForPs({
    shouldFetchList: true,
    ps,
    mobileNumberType,
  })

  const terminalModels = useMemo(() => mapToTerminalValues(terminalList, [DEVICE_SET_TOP_BOX]), [
    terminalList,
    terminalBrand,
  ])

  useEffect(() => {
    if (!terminalDetails.id && terminalId) {
      let data = {
        tariffId: ps,
        terminalId,
        mobileNumberType,
        channel,
      }

      if (isPosSale) {
        data = {
          ...data,
          reseller,
        }
      }

      fetchTerminalDetails({
        lineType: 'stock',
        isStockCheck: true,
        data,
      })
    }
  }, [terminalId])

  const fieldNames = {
    TERMINAL_MANUFACTURER: 'stock.terminal.manufacturer',
    TERMINAL_ID: 'stock.terminal.id',
    TERMINAL_LINE_TYPE: 'stock',
    TERMINAL_CATEGORY: 'stock.terminal.category',
    TERMINAL_DETAILS: 'stock.terminal.details',
  }

  function onModelChange(model) {
    if (!model || !model.brand) {
      setFieldValue(fieldNames.TERMINAL_ID, '')
      return
    }

    if (model.terminalId === terminalId) {
      return
    }
    resetTerminalData({ lineType: fieldNames.TERMINAL_LINE_TYPE })

    setFieldValue(fieldNames.TERMINAL_DETAILS, {})

    setFieldValue(fieldNames.TERMINAL_MANUFACTURER, model.brand)

    setFieldValue(fieldNames.TERMINAL_CATEGORY, model.category)
    setFieldValue(fieldNames.TERMINAL_ID, model.terminalId)
  }

  function onCategoryChange(category) {
    if (!category) {
      setFieldValue(fieldNames.TERMINAL_CATEGORY, '')
      setFieldValue(fieldNames.TERMINAL_MANUFACTURER, '')
      setFieldValue(fieldNames.TERMINAL_ID, '')
      return
    }

    if (category.label === terminalCategory) {
      return
    }
    resetTerminalData({ lineType: fieldNames.TERMINAL_LINE_TYPE })
    setFieldValue(fieldNames.TERMINAL, Terminal)
    setFieldValue(fieldNames.TERMINAL_ID, '')
    setFieldValue(fieldNames.TERMINAL_MANUFACTURER, '')
    setFieldValue(fieldNames.TERMINAL_CATEGORY, category.key)
  }

  function onBrandChange(brand) {
    if (!brand) {
      setFieldValue(fieldNames.TERMINAL_MANUFACTURER, '')
      setFieldValue(fieldNames.TERMINAL_ID, '')
      return
    }

    if (brand.label === terminalBrand) {
      return
    }
    resetTerminalData({ lineType: fieldNames.TERMINAL_LINE_TYPE })
    setFieldValue(fieldNames.TERMINAL, Terminal)
    setFieldValue(fieldNames.TERMINAL_ID, '')
    setFieldValue(fieldNames.TERMINAL_MANUFACTURER, brand.label)
  }

  const [showStock, setShowStock] = useState()

  return (
    <div className="p-b-20">
      <ButtonLink
        data-hook="stock-check"
        className="m-b-4"
        type="button"
        onClick={() => setShowStock(!showStock)}>
        {!showStock ? 'Consultar Stock de terminales' : 'Ocultar Stock de terminales'}
      </ButtonLink>
      {showStock && (
        <TerminalSelector
          fetchTerminals={fetchTerminals}
          isListLoading={isLoadingTerminalList}
          listError={terminalListError}
          terminalModels={terminalModels}
          onCategoryChange={onCategoryChange}
          onModelChange={onModelChange}
          onBrandChange={onBrandChange}
          fieldNames={fieldNames}
          terminalDetails={terminalDetails}
          isStockCheck
        />
      )}
    </div>
  )
}
