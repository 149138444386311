import PropTypes from 'prop-types'
import { format } from 'date-fns'
import { useLegalTexts } from 'modules/LegalTexts/hooks/index'
import { Modal, SpinnerCenter, ModalActions, Button } from 'components/ui'
import { LegalTexts } from '../LegalTexts'

function LegalDialog({
  isOpen,
  onConfirm,
  data,
  permanencyTerms,
  isPro,
  showConfirm,
  isSubscription,
  legalTextsNoReturnalPenaltiesValues,
}) {
  const { legalTexts, isLoading } = useLegalTexts({ origin: 'sales' })

  return (
    <Modal title="PERMISOS DE UTILIZACIÓN DE TUS DATOS" isOpen={isOpen} type="default">
      {isLoading && <SpinnerCenter showMsg />}
      {!isLoading && (
        <>
          <LegalTexts
            portaLinesWithDonor={data.portaLinesWithDonor}
            portabilityLinesByTitular={data.portabilityLinesByTitular}
            permanencyTerms={permanencyTerms}
            commissions={data.commissions}
            fullName={data.fullName}
            documentid={data.documentid}
            currentDate={format(data.date, 'dd/MM/yy', new Date())}
            currentTime={format(data.date, 'HH:mm', new Date())}
            fiberType={data.fiberType}
            terminals={data.terminals}
            isFixedPortability={data.isFixedPortability}
            isFixedInternalPortability={data.isFixedInternalPortability}
            isFixedInstallationRequired={data.isFixedInstallationRequired}
            discounts={data.discounts}
            agileTV={data.agileTV}
            hasSmartTv={data.hasSmartTv}
            linesLegal={data.linesLegal}
            showConfirm={showConfirm}
            isPro={isPro}
            isSubscription={isSubscription}
            legalTextsNoReturnalPenaltiesValues={legalTextsNoReturnalPenaltiesValues}
            legalTexts={legalTexts}
            selectedHomeGoAlarm={data.selectedHomeGoAlarm}
          />
          <ModalActions>
            <Button data-hook="confirm-legal" onClick={onConfirm}>
              Continuar
            </Button>
          </ModalActions>
        </>
      )}
    </Modal>
  )
}

LegalDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  data: PropTypes.any.isRequired,
  isPro: PropTypes.bool,
  isSubscription: PropTypes.bool,
  permanencyTerms: PropTypes.object,
  showConfirm: PropTypes.bool,
  legalTextsNoReturnalPenaltiesValues: PropTypes.object,
}

export default LegalDialog
