import { YOIGO_STORE } from 'modules/SharedSales/constants/shippingAddress'
import { constants } from './device-renewal.constants'
import { OtherAddressModel } from '../models'

const initialState = {
  billing: {
    paymentType: '',
    transaction: {},
  },
  device: {
    id: '',
    paymentMethods: {},
    stock: null,
  },
  deviceFilters: {
    brand: null,
    model: null,
    minPrice: '',
    maxPrice: '',
    dualSim: false,
    capacity: null,
    screenSize: null,
    category: null,
  },

  devices: {
    data: null,
    loading: null,
    error: null,
  },
  deviceType: '',

  financialData: {
    dueDate: '',
    salary: '',
    job: '',
    company: '',
  },

  financedLegalConsents: {
    consent1: null,
    consent2: null,
    consent3: null,
  },

  financingConditions: {
    data: null,
    loading: null,
    error: null,
  },
  financedDeviceDecisions: {
    amendPenalty: false,
    feesPaymentType: '',
    finalPaymentType: '',
  },

  generateOrder: {
    startPollingTime: null,
    taskId: null,
    retries: 0,
    loading: false,
    error: null,
    result: null,
  },
  msisdn: null,
  reservationId: '',
  saleChannel: '',
  shipping: {
    type: YOIGO_STORE.value,
    deliveryPoint: {
      zipCode: '',
      selectedPoint: {},
    },
    otherAddress: OtherAddressModel,
  },
  specifications: {},

  validation: {
    data: null,
    loading: null,
  },
}

export const reducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case constants.RETRIEVE_SUBSCRIPTION: {
      return {
        ...state,
        msisdn: payload.msisdn,
      }
    }
    case constants.FETCH_DEVICE_RENEWAL_ALLOWED: {
      return { ...state, validation: { ...initialState.validation, loading: true } }
    }
    case constants.FETCH_DEVICE_RENEWAL_ALLOWED_SUCCESS: {
      return { ...state, validation: { data: payload.data, loading: false } }
    }
    case constants.FETCH_DEVICE_RENEWAL_ALLOWED_FAILED: {
      return {
        ...state,
        validation: {
          data: null,
          error: true,
          loading: false,
        },
      }
    }
    case constants.RESET_DEVICE_RENEWAL_ALLOWED: {
      return { ...state, validation: { ...initialState.validation } }
    }
    case constants.SET_DEVICE_RENEWAL_DATA: {
      return {
        ...state,
        ...payload,
      }
    }
    case constants.RESET_DEVICE_RENEWAL_DEVICES: {
      return {
        ...state,
        device: { ...initialState.device },
        devices: { ...initialState.devices },
        deviceFilters: { ...initialState.deviceFilters },
      }
    }

    case constants.FETCH_DEVICE_RENEWAL_DEVICES: {
      return {
        ...state,
        devices: {
          data: null,
          error: null,
          loading: true,
        },
      }
    }
    case constants.FETCH_DEVICE_RENEWAL_DEVICES_SUCCESS: {
      return {
        ...state,
        devices: {
          data: payload,
          error: null,
          loading: false,
        },
      }
    }
    case constants.FETCH_DEVICE_RENEWAL_DEVICES_FAILED: {
      return {
        ...state,
        devices: {
          data: null,
          error: payload.error,
          loading: false,
        },
      }
    }

    case constants.FETCH_DEVICE_SPECIFICATIONS: {
      return {
        ...state,
        specifications: {
          ...state.specifications,
          [payload.id]: {
            error: null,
            data: null,
          },
        },
      }
    }

    case constants.FETCH_DEVICE_SPECIFICATIONS_SUCCESS: {
      return {
        ...state,
        specifications: {
          ...state.specifications,
          [payload.id]: {
            error: null,
            data: payload.data,
          },
        },
      }
    }

    case constants.FETCH_DEVICE_SPECIFICATIONS_FAILED: {
      return {
        ...state,
        specifications: {
          ...state.specifications,
          [payload.id]: {
            error: payload.error,
            data: null,
          },
        },
      }
    }
    case constants.FETCH_FINANCING_CONDITIONS: {
      return {
        ...state,
        financingConditions: {
          ...initialState.financingConditions,
          loading: true,
        },
      }
    }
    case constants.FETCH_FINANCING_CONDITIONS_SUCCESS: {
      return {
        ...state,
        financingConditions: {
          loading: false,
          data: payload.data,
          error: null,
        },
      }
    }
    case constants.FETCH_FINANCING_CONDITIONS_FAILED: {
      return {
        ...state,
        financingConditions: {
          loading: false,
          data: null,
          error: payload.error,
        },
      }
    }
    case constants.RESET_FINANCING_CONDITIONS: {
      return {
        ...state,
        financingConditions: { ...initialState.financingConditions },
      }
    }

    case constants.GENERATE_ORDER: {
      return {
        ...state,
        generateOrder: {
          startPollingTime: null,
          taskId: null,
          retries: 0,
          loading: true,
          error: null,
          success: false,
        },
      }
    }

    case constants.START_POLLING: {
      return {
        ...state,
        generateOrder: {
          ...state.generateOrder,
          startPollingTime: Date.now(),
          taskId: payload.taskId,
          retries: 0,
        },
      }
    }

    case constants.RETRY_POLLING: {
      return {
        ...state,
        generateOrder: {
          ...state.generateOrder,
          retries: payload.retries,
        },
      }
    }

    case constants.GENERATE_ORDER_SUCCESS: {
      return {
        ...state,
        generateOrder: {
          ...state.generateOrder,
          loading: false,
          error: null,
          result: payload.result,
        },
      }
    }
    case constants.GENERATE_ORDER_FAILED: {
      return {
        ...state,
        generateOrder: {
          ...state.generateOrder,
          loading: false,
          error: payload.error,
          success: false,
        },
      }
    }
    default:
      return state
  }
}
