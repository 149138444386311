import { actionTypes } from 'modules/typifications/constants'

export const loginAgent = ({ sfid, uuid }) => ({
  type: actionTypes.LOGIN_AGENT_INIT,
  payload: {
    sfid,
    uuid,
  },
})

export const loginAgentSuccess = ({ accessToken, agentToken }) => ({
  type: actionTypes.LOGIN_AGENT_SUCCESS,
  payload: { accessToken, agentToken },
})

export const loginAgentFailed = error => ({
  type: actionTypes.LOGIN_AGENT_FAILED,
  payload: error,
})
