import { constants } from './constants'

const initialState = {
  customerProducts: [],
  error: null,
  loading: false,
}

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case constants.GET_CUSTOMER_PRODUCTS: {
      return {
        ...initialState,
        loading: true,
      }
    }
    case constants.GET_CUSTOMER_PRODUCTS_SUCCESS: {
      return {
        ...state,
        customerProducts: payload.customerProducts,
        loading: false,
      }
    }
    case constants.GET_CUSTOMER_PRODUCTS_ERROR: {
      return {
        ...state,
        customerProducts: [],
        error: payload.error,
        loading: false,
      }
    }
    case constants.RESET_CUSTOMER_PRODUCTS: {
      return {
        ...initialState,
      }
    }
    default:
      return state
  }
}
