import { get, find, chain } from 'lodash'
import { createSelector } from 'reselect'

import { separateNumberfromString } from './helpers'

export const getAddress = (type, stateWhere) => state => {
  const customerContact = get(
    state,
    stateWhere
      ? `${stateWhere}.data.customer.contactMedium`
      : 'productOrders.data.customer.contactMedium',
  )
  const address = find(customerContact, {
    type: type || 'InstallationAddress',
  })
  const medium = get(address, 'medium')
  const streetTwo = separateNumberfromString(get(medium, 'streetTwo'))
  const characteristic = get(medium, 'characteristic')
  const streetNumber = find(characteristic, {
    name: 'streetNumber',
  })
  const province = find(characteristic, {
    name: 'province',
  })
  if (!medium) return undefined
  return {
    ...medium,
    streetTwo,
    province: get(province, 'value', ''),
    streetNumber: get(streetNumber, 'value', ''),
    street: `${get(medium, 'streetOne', '')} ${get(medium, 'streetTwo', '')}`,
  }
}

export const getProcessedAddress = (type, stateWhere) =>
  createSelector(
    [getAddress(type, stateWhere)],
    installationAddress =>
      `${get(installationAddress, 'streetOne', '')} ${get(
        installationAddress,
        'streetNumber',
        '',
      )} ${get(installationAddress, 'streetTwo', '')} ${get(
        chain(get(installationAddress, 'characteristic'))
          .flatMap()
          .find({ name: 'province' })
          .value(),
        'value',
        '',
      )} ${get(installationAddress, 'city', '')} ${get(installationAddress, 'postCode', '')}`,
  )

export const getPhoneNumber = stateWhere => state => {
  const customerContact = get(
    state,
    stateWhere
      ? `${stateWhere}.data.customer.contactMedium`
      : 'productOrders.data.customer.contactMedium',
  )
  const phoneNumber = find(customerContact, {
    type: 'TelephoneNumber',
  })
  return get(phoneNumber, 'medium')
}

export const getCustomerIdentification = stateWhere => state => {
  const customerContact = get(
    state,
    stateWhere
      ? `${stateWhere}.data.customer.individualIdentification`
      : 'productOrders.data.customer.individualIdentification',
  )
  return {
    id: chain(customerContact)
      .find('identificationId')
      .get('identificationId')
      .value(),
    typeId: chain(customerContact)
      .find('type')
      .get('type')
      .value(),
  }
}
export const getCurrentDate = () => {
  const date = new Date()
  return {
    formatedDate: `${date.getUTCDate()}/${date.getUTCMonth() + 1}/${date.getUTCFullYear()}`,
    hours: date.getHours(),
    minutes: date.getMinutes(),
  }
}

export const getCompanyInfo = state => {
  const company = get(state.orders, 'data.customer.company')
  return company || {}
}

export const getCustomerInfo = (type, stateWhere) =>
  createSelector(
    [
      getAddress(type, stateWhere),
      getPhoneNumber(stateWhere),
      getCustomerIdentification(stateWhere),
    ],
    (address, phone, customerId) => ({
      ...address,
      ...phone,
      ...customerId,
    }),
  )
