import {
  RENEWAL_WITHOUT_SEGMENTATION,
  RENEWAL_MINIMUM_TIME_NOT_EXCEEDED_3,
  RENEWAL_MINIMUM_TIME_NOT_EXCEEDED_6,
  RENEWAL_MINIMUM_TIME_NOT_EXCEEDED_12,
  RENEWAL_NUMBER_NOT_VALID,
  RENEWAL_CUSTOMER_NUMBER_NOT_MATCH,
  RENEWAL_CUSTOMER_NOT_FOUND,
  RENEWAL_NOT_ALLOWED_FOR_FIXED_ONLY,
  RENEWAL_ORDER_NOT_FOUND,
  RENEWAL_ONGOING_MNP_OUT_REQUEST,
  RENEWAL_BUSINESS_NOT_ALLOWED,
  RENEWAL_PASSPORT_STC_NOT_ALLOWED,
  RENEWAL_SEGMENTATION_CODE_NOT_FOUND,
  RENEWAL_GENERAL,
  RENEWAL_PENDING_STC,
  RENEWAL_ONGOING_ACC,
  RENEWAL_ONGOING_REQUEST,
  RENEWAL_ONGOING_ORDER,
  RENEWAL_POS_STC_BARRINGS,
  RENEWAL_IS_ADDITIONAL_LINE,
  RENEWAL_INVALID_IBAN,
} from 'modules/SharedSales/constants/validations'

export const renewalValidationMessagesError = {
  without_segmentation: RENEWAL_WITHOUT_SEGMENTATION,
  'minimum_time_not_exceeded|3': RENEWAL_MINIMUM_TIME_NOT_EXCEEDED_3,
  'minimum_time_not_exceeded|6': RENEWAL_MINIMUM_TIME_NOT_EXCEEDED_6,
  'minimum_time_not_exceeded|12': RENEWAL_MINIMUM_TIME_NOT_EXCEEDED_12,
  number_not_valid: RENEWAL_NUMBER_NOT_VALID,
  'customer number from account id is not matching customer number in subscription': RENEWAL_CUSTOMER_NUMBER_NOT_MATCH,
  customer_not_found: RENEWAL_CUSTOMER_NOT_FOUND,
  renewall_not_allowed_for_fixed_only: RENEWAL_NOT_ALLOWED_FOR_FIXED_ONLY,
  order_not_found: RENEWAL_ORDER_NOT_FOUND,
  ongoing_mnp_out_request: RENEWAL_ONGOING_MNP_OUT_REQUEST,
  business_not_allowed: RENEWAL_BUSINESS_NOT_ALLOWED,
  'PassPort OR Prepaid customer, STC is not allowed': RENEWAL_PASSPORT_STC_NOT_ALLOWED,
  'Segmentation code not found for the subscription': RENEWAL_SEGMENTATION_CODE_NOT_FOUND,
  general: RENEWAL_GENERAL,
  pending_stc: RENEWAL_PENDING_STC,
  ongoing_acc: RENEWAL_ONGOING_ACC,
  'Ongoing renewal request': RENEWAL_ONGOING_REQUEST,
  'Ongoing renewal order': RENEWAL_ONGOING_ORDER,
  pos_stc_barrings: RENEWAL_POS_STC_BARRINGS,
  is_additional_line: RENEWAL_IS_ADDITIONAL_LINE,
  iban_not_valid: RENEWAL_INVALID_IBAN,
}
