import {
  format,
  parse,
  parseISO,
  addSeconds,
  isValid,
  isAfter,
  isBefore,
  toDate,
  addDays,
  addMonths,
  lastDayOfMonth,
  getYear,
  getMonth,
} from 'date-fns'
import es from 'date-fns/locale/es'
import { first, isEmpty } from 'lodash'

export function formatDateGeneric(date, formatStr, noDateStr = ' ') {
  if (date) {
    return isValid(date) ? format(date, formatStr, { locale: es }) : 'Invalid date'
  }
  return noDateStr
}

export const DDMMYYYYToDate = date => {
  const [day, month, year] = date.split('/')

  return new Date(`${year}-${month}-${day}T02:00:00`)
}

export const formatDateYYMMDD = date => (date ? format(date, 'yyyy-MM-dd', { locale: es }) : '-')
export const formatDateDDMMYYYY = date => (date ? format(date, 'dd/MM/yyyy', { locale: es }) : '-')
export function formatDateDDMMYY(date) {
  if (date) {
    return isValid(date) ? format(date, 'dd/MM/yy', { locale: es }) : 'Invalid date'
  }
  return '-'
}

export function formatHHmm_H(date) {
  return isValid(date) ? `${format(date, 'HH:mm', { locale: es })}H` : '-'
}

export function format_dd_MM_yyyy_HHmm_h(date) {
  return formatDateGeneric(date, "dd/MM/yyyy - HH:mm'h'")
}

export function formatYYYYMMDD_HHmmss(date) {
  return formatDateGeneric(date, 'yyyy-MM-dd HH:mm:ss')
}

export function format_dd_MM_yy_HHmm_h(date) {
  return formatDateGeneric(date, "dd/MM/yy - HH:mm'h'")
}

export function format_dd_MM_yy_HHmm_hFromStr(dateStr) {
  return format_dd_MM_yy_HHmm_h(isEmpty(dateStr) ? null : parseISO(dateStr))
}

export function formatHHmm_hFromStr(dateStr) {
  return isValid(parseISO(dateStr)) ? `${format(parseISO(dateStr), 'HH:mm')} h` : ''
}

export const formatDate = date => (date ? format(parseISO(date), 'dd/MM/yyyy') : '-')

export const formatDateFromDate = date => (date ? format(date, 'dd/MM/yyyy') : '-')

export const formatDateStr = (dateStr, formatString) =>
  isValid(new Date(dateStr)) ? formatDateFromDate(parse(dateStr, formatString, new Date())) : '-'

export const formatMonth = date => (date ? format(date, 'MMMM', { locale: es }) : '-')

export const formatMonthYear = date =>
  date ? format(parseISO(date), 'MMM. yyyy', { locale: es }) : '-'

export const formatMonthAbreviate = date =>
  date ? format(date, 'MMM', { locale: es }).toUpperCase() : '-'

export const formatYearAbreviate = date => (date ? format(date, 'yy') : '-')

export const formatYearMonth = date => (date ? format(date, 'yyyyMM') : '-')

export const getMonthFromNumber = month => formatMonth(new Date(2000, month, 1))

export const formatDay = date => (date ? format(date, 'dd') : '-')

export const formatYear = date => (date ? format(date, 'yyyy') : '-')

export const formatMinsToSeconds = seconds => {
  const secondsDate = addSeconds(new Date(0), seconds)
  return format(secondsDate, 'mm:ss')
}

export const formatNetkiaDate = created => {
  try {
    return format(new Date(created.substr(0, 11) + created.substr(11, 5)), 'dd/MM/yyyy')
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e)
  }
  return created
}

export const formatNetkiaDateWithHours = date => {
  // 2019-04-30 15:50 Europe/Madrid
  try {
    const values = date.split(' ')
    return `${formatDate(values[0])} - ${values[1]}h`
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e)
  }
  return date
}

const dateSortDesc = (date1, date2) => (date1 > date2 ? -1 : 1)

export const lastStatus = logisticStates =>
  first(logisticStates.sort((a, b) => dateSortDesc(a.created, b.created)))

export const extendedLiteralDate = date => {
  if (isAfter(new Date(date), new Date(2199, 1, 1))) {
    return 'Sin fecha registrada'
  }
  return isValid(new Date(date))
    ? `${format(new Date(date), "dd 'de' MMMM 'de' yyyy", { locale: es })}`
    : ''
}
export function formatRange(date1, date2) {
  return `${formatDateDDMMYYYY(date1)} al ${formatDateDDMMYYYY(date2)}`
}

export const formatHourFromDate = date => format(new Date(date), 'HH:mm')

export const sumMonthsToToday = months => addMonths(toDate(Date.now()), months)

export const isAfterDate = date => isAfter(parseISO(date), toDate(Date.now()))

export const isWithinDateRange = (date, range) => {
  const endDateRange = addDays(parseISO(date), range)

  return isBefore(toDate(Date.now()), endDateRange)
}

export const getLastDayOfMonthFromMonthNumber = month => {
  const calculateMonth = addMonths(Date.now(), month)
  return lastDayOfMonth(calculateMonth)
}

export const getFirstDayOfMonthFromMonthNumber = month => {
  const calculateMonth = addMonths(Date.now(), month)
  return new Date(getYear(calculateMonth), getMonth(calculateMonth), 1)
}
