import { useState } from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'

import {
  TableHead,
  TableBody,
  TableRow,
  CircularProgress,
  Typography,
  Icon,
} from '@material-ui/core'
import { TextStyles } from 'utils/text'
import { ICONS } from 'utils/icons'

import { Card } from 'modules/ui'
import { ThemedTable, ThemedCellHeader, ThemedTableRow, ThemedCell } from 'components/ui'
import { isValid } from 'date-fns'
import { formatDate } from 'utils/formatting/dates'
import { getContractPdf } from 'services/contract-files'
import { openBlob, buildBlob } from 'utils'
import { logError, formatErrorMessage } from 'services/logging'
import { get } from 'lodash'

const CardStyled = styled(Card)`
  .card-content {
    padding: 24px;
  }
  .widthAuto {
    width: auto;
  }

  tbody {
    overflow-y: auto;
  }
  .title-table-row {
    display: block;
    background-color: #ebf1f7;
  }
  .title-table-cell {
    border: none;
    display: block;
    padding: 8px 0 8px 20px;
    width: 100%;
    text-align: left;
  }
  thead {
    width: 100%;
  }
  thead > tr > th:first-child,
  tbody > tr > td:first-child {
    width: 7vw;
    padding: 12px 5px 12px 20px;
  }
  th:last-child,
  td:last-child {
    width: 7vw;
  }
  td:last-child {
    padding-right: 0;
  }
  .icon {
    color: ${props => get(props, 'theme.palette.brand.action_primary')};
    cursor: pointer;
  }
`

function DocumentsTable({ data, isPosOrMasProximo }) {
  const [isDownloading, setIsDownloading] = useState(false)

  const onDownload = contractId => {
    setIsDownloading(true)

    getContractPdf(contractId)
      .then(blob => {
        const blobBuilt = buildBlob([blob], 'pdf')
        openBlob(blobBuilt)
      })
      .catch(e => logError(new Error(formatErrorMessage(e))))
      .then(() => setIsDownloading(false))
  }

  return (
    <CardStyled title="Documentos" withShadow>
      <div className="p-24">
        <ThemedTable>
          <TableHead>
            <ThemedTableRow>
              <ThemedCellHeader align="left">ID CONTRATO</ThemedCellHeader>
              <ThemedCellHeader align="center">TITULAR</ThemedCellHeader>
              <ThemedCellHeader align="center">SUSCRIPCIÓN</ThemedCellHeader>
              <ThemedCellHeader align="center">FECHA CREACIÓN</ThemedCellHeader>
              {!isPosOrMasProximo && <ThemedCellHeader />}
            </ThemedTableRow>
          </TableHead>
          <TableBody>
            <tr className="title-table-row">
              <Typography
                {...TextStyles.labelDark({
                  component: 'th',
                  className: 'title-table-cell p-y-8 p-l-24 full-width',
                })}>
                Contratos
              </Typography>
            </tr>
            <TableRow>
              <ThemedCell align="left">{data.idContract}</ThemedCell>
              <ThemedCell align="center">{data.fullName}</ThemedCell>
              <ThemedCell align="center">{data.suscription}</ThemedCell>
              <ThemedCell align="center">
                {isValid(new Date(data.orderDate)) && formatDate(data.orderDate)}
              </ThemedCell>
              {!isPosOrMasProximo && (
                <ThemedCell align="center">
                  {!isDownloading && (
                    <Icon className="icon" onClick={() => onDownload(data.idContract)}>
                      {ICONS.download}
                    </Icon>
                  )}
                  {isDownloading && <CircularProgress size="20px" />}
                </ThemedCell>
              )}
            </TableRow>
          </TableBody>
        </ThemedTable>
      </div>
    </CardStyled>
  )
}

DocumentsTable.propTypes = {
  data: PropTypes.object,
  isPosOrMasProximo: PropTypes.bool,
}

export default DocumentsTable
