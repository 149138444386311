import PropTypes from 'prop-types'
import { isNil, isObject } from 'lodash'

import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { TextStyles } from 'utils/text'

import ButtonCard from '../buttons/ButtonCard'
import { ButtonLink } from '../buttons/ButtonLink'

const LineItemCardText = ({
  className,
  dataHook,
  data,
  variant,
  color,
  component,
  isHeader,
  style,
}) => (
  <Typography
    variant={variant}
    color={color}
    component={component}
    data-hook={dataHook}
    {...(isObject(data)
      ? {
          color: data.color || color,
          variant: data.variant || variant,
          component: data.component || component,
          className: [className, ...(data.className ? [data.className] : [])].join(' '),
          style: { ...style, ...(data.style || {}) },
        }
      : { className, style })}>
    {isObject(data) ? data.children : data}
    {isHeader ? '' : ': '}
  </Typography>
)
LineItemCardText.propTypes = {
  className: PropTypes.string,
  dataHook: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isHeader: PropTypes.bool,
  style: PropTypes.object,
  variant: PropTypes.string,
  color: PropTypes.string,
  component: PropTypes.string,
}

const useStyles = makeStyles({
  'line-item-card': {
    width: '100%',

    '& .primary-text': {
      marginRight: '8px',
    },

    '& .secondary-text': {
      marginRight: 0,
    },
    '& .actions-row': {
      '& .button-card': {
        marginLeft: '12px',
      },
    },
  },
})

const LineItemCard = ({
  primaryText,
  secondaryText,
  link,
  listActions,
  isHeader,
  fieldsApart,
  primaryTextDataHook,
  secondaryTextDataHook,
  className,
  labelBlock,
  ...rest
}) => {
  const classes = useStyles()

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      className={[
        'line-item-card',
        classes['line-item-card'],
        ...(isHeader ? ['is-header'] : []),
        ...(className ? [className] : []),
      ].join(' ')}
      {...rest}>
      <Box
        display={labelBlock ? 'block' : 'flex'}
        alignItems="baseline"
        justifyContent={fieldsApart ? 'space-between' : null}
        width={fieldsApart ? '100%' : null}>
        {!isNil(primaryText) && (
          <LineItemCardText
            dataHook={primaryTextDataHook}
            data={primaryText}
            {...TextStyles.labelDark({
              className: 'primary-text',
              component: 'p',
            })}
            isHeader={isHeader}
          />
        )}
        {secondaryText && (
          <LineItemCardText
            dataHook={secondaryTextDataHook}
            data={secondaryText}
            {...TextStyles.dataDark({
              className: 'secondary-text',
            })}
            isHeader
          />
        )}
        {link && (
          <ButtonLink disabled={link.disabled} onClick={link.action} data-hook={link.dataHook}>
            {link.label}
          </ButtonLink>
        )}
      </Box>
      {listActions && (
        <Box display="flex" alignItems="center" className="actions-row">
          {listActions.map(action => (
            <ButtonCard key={`${action.label}-${action.icon}`} {...action} />
          ))}
        </Box>
      )}
    </Box>
  )
}

LineItemCard.defaultProps = {
  padding: '6px 24px',
}

LineItemCard.propTypes = {
  className: PropTypes.string,
  primaryText: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.object]),
  secondaryText: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.object]),
  primaryTextDataHook: PropTypes.string,
  secondaryTextDataHook: PropTypes.string,
  link: PropTypes.shape({
    label: PropTypes.string,
    action: PropTypes.func,
    disabled: PropTypes.bool,
    dataHook: PropTypes.string,
  }),
  listActions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      icon: PropTypes.string,
      action: PropTypes.func,
      disabled: PropTypes.bool,
    }),
  ),
  padding: PropTypes.string,
  fieldsApart: PropTypes.bool,
  isHeader: PropTypes.bool,
  labelBlock: PropTypes.bool,
}

export default LineItemCard
