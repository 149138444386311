export const clientExistsRegex = /.*Customer (.*)( already exists).*/gm

export const barringExistsRegex = /.*(Barring exists to customer).*/gm

export const commonError = /.*(has unexpected attributes|has required attribute empty|Request incorrectly formed|Unsupported channel|Could not fetch customer account|internal-server-error|has required attribute|has unexpected attributes|Could not fetch customer account).*/gm

export const subscriptionError = /.*(Subscription Limit Error to existing Customer).*/gm

export const ongoingOrder = /.*(Ongoing order for number|CLI (.*) is not free|Fixed Number (.*) already exists).*/gm

export const mandatoryFields = /.*(matches none of (.*)(telephone-number|email-address|postal-address|delivery-point)).*/gm

export const failedConnectionRegex = /.*ROVE 8, Order has been discarded.*/gm
