import { axiosJWT } from 'modules/axios'
import { CONFIG } from './environments'

const CACHE_LOGISTIC = {}

export function fetch(documentId, msisdn) {
  const cacheKey = btoa(JSON.stringify({ documentId, msisdn }))
  if (CACHE_LOGISTIC[cacheKey]) {
    return CACHE_LOGISTIC[cacheKey]
  }

  CACHE_LOGISTIC[cacheKey] = axiosJWT({
    method: 'GET',
    url: `${CONFIG.api.logistic}/${documentId}/${msisdn}`,
  })
    .then(resp => {
      return resp.data
    })
    .catch(err => {
      CACHE_LOGISTIC[cacheKey] = null
      throw err
    })

  return CACHE_LOGISTIC[cacheKey]
}
