import { useSelector } from 'react-redux'
import { isEmpty } from 'lodash'

import PropTypes from 'prop-types'
import { Divider, Box, Card, CardContent } from '@material-ui/core'

import { selectMainMobileTariff } from 'modules/NewClientSales/store/selectors/index'
import { MainMobileContainer } from 'modules/NewClientSales/containers/OfferContainer/MainMobileContainer/index'
import { Lead } from '../LeadComponent'
import { ServiceAddress } from '../ServiceAddressComponent'
import { selectSegments } from '../../../store'

const fieldNames = {
  PORTABILITY_NUMBER: 'landlineOffer.lineNumber',
  OPERATOR: 'landlineOffer.operator',
  DOCUMENT_ID: 'account.documentid',
  CLIENT_EXISTS_LAND: 'landlineOffer.clientExistsError',
}

export function FixedMobileSale({ isCartera }) {
  const segments = useSelector(selectSegments)
  const selectedTariff = useSelector(selectMainMobileTariff)
  return (
    <>
      <Card>
        <CardContent style={{ paddingBottom: '0' }}>
          <MainMobileContainer selectedTariff={selectedTariff} isStockCheck />
        </CardContent>
      </Card>

      <Card className="m-y-32">
        <CardContent>
          <Box mb="32px">
            <Lead isCartera={!!isCartera} fieldNames={fieldNames} />
          </Box>

          <Divider />
          {!isEmpty(segments) && selectedTariff.id && <ServiceAddress />}
        </CardContent>
      </Card>
    </>
  )
}

FixedMobileSale.propTypes = {
  isCartera: PropTypes.bool,
}
