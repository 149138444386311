import { APP_CONFIG } from 'services/app-config'
import { axiosJWT } from 'modules/axios'

const TERMINALS_BASE_URL = `${APP_CONFIG.sales_cluster}/v1/terminals`

export const fetchAdslRouterStatus = serialNumber =>
  axiosJWT({
    method: 'GET',
    url: `${TERMINALS_BASE_URL}/adslRouter/${serialNumber}/status`,
  }).then(resp => resp.data)
