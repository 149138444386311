export const FIXED_PARTIAL_CANCELLATION = 'FixedCancellation'
export const MOBILE_PARTIAL_CANCELLATION = 'MobileCancellation'

export const ADDITIONAL_LINE_CATEGORY_2P = 'fixed_line'
export const ADDITIONAL_LINE_CATEGORY_EL_FIJO = 'mobile_fixed_line'

export const CANCELLATION_FLOW_SEPARATELY = 'device-and-sims-delivered-separately'
export const CANCELLATION_FLOW_TOGETHER = 'device-and-sims-delivered-together'

export const warningMessages = {
  cancelAllLines: 'Cancelación total de los productos contratados. ¿Confirmar la operación?',
  cancelAdditionalLine: `Cancelación de las líneas móviles seleccionadas.
    Esta cancelación no afectará a ninguna otra línea ni su compartición de datos, ni sus descuentos.
     ¿Confirmar operación?`,
  cancel3p: `La confirmación de esta operación supone la cancelación de la provisión (FTTH + Nº teléfono Fijo),
  la perdida de descuentos en las líneas móviles adicionales y comparticion de datos en la línea Duo.
   ¿Confirmar la operación?`,
  partialCancellationMobile: `La confirmación de esta operación supone un cambio en el producto contratado.
   La perdida de descuentos en las líneas móviles adicionales,  descuento y compartición de datos en la línea Duo.
    ¿Confirmar la operación?`,
  partialCancellationMobileWithoutExtra: `La confirmación de esta operación supone un cambio en el producto contratado.
  La perdida de descuentos en las líneas móviles adicionales.
   ¿Confirmar la operación?`,
  partialCancellationFixed: `La confirmación de esta operación supone la cancelación de la provisión (FTTH + Nº teléfono Fijo),
   la perdida de descuentos en las líneas móviles adicionales y comparticion de datos en la línea Duo.
    ¿Confirmar la operación?`,
  productChange: `La confirmación de esta operación supone un cambio en el producto contratado. ¿Confirmar la operación? `,
  partialCancellationFixedAndAdditional: `La confirmación de esta operación supone la cancelación de la provisión (FTTH + Nº teléfono Fijo),
   la perdida de descuentos en las líneas móviles adicionales,
   descuento y compartición de datos en la línea Duo.
   ¿Confirmar la Operación? `,
  cancel2p: `La confirmación de esta operación supone la cancelación total del producto contratado. ¿Confirmar la operación?  `,
}
