import { LINE_TYPE } from 'modules/Lines/constants'

import { Account } from './account'
import { Billing } from './billing'
import { HomeGo } from './homeGo'
import { LandlineOffer } from './landlineOffer'
import { MobileLineOffer } from './mobileLineOffer'
import { Shipping } from './shipping'

export const Basket = {
  account: Account,
  billing: Billing,
  landlineOffer: LandlineOffer,
  [LINE_TYPE.MAIN_MOBILE]: MobileLineOffer,
  extraMobileLinesOffers: [],
  shipping: Shipping(''),
  terminalShipping: Shipping(''),
  serviceAddress: Shipping(''),
  homeGo: HomeGo,
  stock: MobileLineOffer,
  order: {},
}
