import * as constants from './constants'

const findOffers = payload => ({
  type: constants.FIND_OFFERS,
  payload: {
    ...payload,
  },
})

const foundOffers = data => ({
  type: constants.FIND_OFFERS_SUCCESS,
  payload: {
    ...data,
  },
})

const findOffersFailed = error => ({
  type: constants.FIND_OFFERS_FAILED,
  payload: {
    error,
  },
})

export const actions = {
  findOffers,
  foundOffers,
  findOffersFailed,
}
