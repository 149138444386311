import { call, put, select, takeEvery } from 'redux-saga/effects'
import { logError, formatErrorMessage } from 'services/logging'
import { axiosFactory } from 'modules/axios'
import { selectAccountId } from 'modules/Auth'
import {
  customerOrderActionTypes,
  findAllOrdersSuccess,
  findAllOrdersError,
  fetchCustomerOrderLimitError,
  fetchCustomerOrderLimitSuccess,
  fetchCustomerDuoLimitSuccess,
  fetchCustomerDuoLimitError,
} from './customerOrders.actions'
import {
  findAllOrders,
  fetchCustomerOrderLimit,
  fetchCustomerDuoLimit,
} from '../services/customerOrders.service'

/**
 * Executes backend call to retrieve customer's
 * in flight orders. It dispatchs findAllOrdersSuccess /
 * findAllOrdersError depending on the execution success.
 */
export function* findAllOrdersSaga() {
  try {
    const accountId = yield select(selectAccountId)
    const orders = yield call(findAllOrders, accountId)

    yield put(findAllOrdersSuccess(orders))
  } catch (e) {
    yield call(logError, new Error(formatErrorMessage(e)))
    const error = axiosFactory.buildError(e)
    yield put(findAllOrdersError({ error }))
  }
}

export function* watchFindAllOrders() {
  yield takeEvery(customerOrderActionTypes.FIND_ALL_ORDERS, findAllOrdersSaga)
}

export function* fetchCustomerOrderLimitSaga({ payload }) {
  try {
    const { limit } = yield call(fetchCustomerOrderLimit, payload)
    yield put(fetchCustomerOrderLimitSuccess(limit))
  } catch (e) {
    yield call(logError, { e: new Error(formatErrorMessage(e)), payload })
    const error = axiosFactory.buildError(e)
    yield put(fetchCustomerOrderLimitError(error))
  }
}

export function* watchFetchCustomerOrderLimitSaga() {
  yield takeEvery(customerOrderActionTypes.FETCH_CUSTOMER_ORDER_LIMIT, fetchCustomerOrderLimitSaga)
}

export function* fetchCustomerDuoLimitSaga({ payload }) {
  try {
    const info = yield call(fetchCustomerDuoLimit, payload)
    yield put(fetchCustomerDuoLimitSuccess(info))
  } catch (e) {
    yield call(logError, { e: new Error(formatErrorMessage(e)), payload })
    const error = axiosFactory.buildError(e)
    yield put(fetchCustomerDuoLimitError(error))
  }
}

export function* watchFetchCustomerDuoLimitSaga() {
  yield takeEvery(customerOrderActionTypes.FETCH_CUSTOMER_DUO_LIMIT, fetchCustomerDuoLimitSaga)
}
