export const AppPaths = {
  dashboard: {
    id: 'dashboard',
    regexPath:
      '/vista/dashboard/documentType/:documentType/documentId/:documentId/:type?/:id?/:subPage?',
  },
  cvm: {
    id: 'cvm',
    regexPath:
      '/vista/dashboard/documentType/:documentType/documentId/:documentId/customer-account',
  },
  energy: {
    id: 'energy',
    regexPath: '/vista/dashboard/documentType/:documentType/documentId/:documentId/energy',
  },
  dashboard_init: {
    id: 'dashboard_init',
    regexPath: '/vista/search',
  },
  change_technology: {
    id: 'change_technology',
    regexPath: '/clients/change-technology',
  },
  move_client: {
    id: 'move_client',
    regexPath: '/clients/move-client',
  },
  client_operations: {
    id: 'client_operations',
    regexPath: '/clients/change-services',
  },
  on_boarding: {
    id: 'on_boarding',
    regexPath: '/clients/orders/:orderId/actions',
  },
}
