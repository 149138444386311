const GET_DISCOUNTS = 'OVID/DISCOUNTS_AGENT_CATEGORIES/GET_DISCOUNTS'
const GET_DISCOUNTS_SUCCESS = 'OVID/DISCOUNTS_AGENT_CATEGORIES/GET_DISCOUNTS_SUCCESS'
const GET_DISCOUNTS_FAILED = 'OVID/DISCOUNTS_AGENT_CATEGORIES/GET_DISCOUNTS_FAILED'

const MOBILE_CATEGORY = 'MOVIL'
const CONVERGENT_CATEGORY = 'CONVERGENTE'

export const constants = {
  GET_DISCOUNTS,
  GET_DISCOUNTS_SUCCESS,
  GET_DISCOUNTS_FAILED,
  MOBILE_CATEGORY,
  CONVERGENT_CATEGORY,
}
