import { constants } from './constants'

const initialState = {
  isAllowed: {
    data: null,
    loading: null,
    error: null,
  },
}

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case constants.FETCH_MULTISIM_ALLOWED: {
      return { ...state, isAllowed: { ...initialState.isAllowed, loading: true, error: null } }
    }
    case constants.FETCH_MULTISIM_ALLOWED_SUCCESS: {
      return { ...state, isAllowed: { data: true, loading: false, error: null } }
    }
    case constants.FETCH_MULTISIM_ALLOWED_FAILED: {
      return {
        ...state,
        isAllowed: {
          data: false,
          loading: false,
          error: payload.error,
        },
      }
    }
    case constants.RESET_MULTISIM_ALLOWED: {
      return {
        ...state,
        isAllowed: {
          ...initialState.isAllowed,
        },
      }
    }

    default:
      return state
  }
}
