import { axiosJWT } from 'modules/axios'
import { CONFIG } from 'modules/typifications/environments'

export const insertionData = dataInsertion =>
  axiosJWT({
    method: 'PUT',
    url: CONFIG.api.insertionData,
    data: dataInsertion,
  }).catch(e => {
    e.response.data.error_message = e.response.data.detailMsg
    throw e
  })
