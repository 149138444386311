import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import { TextStyles } from 'utils/text'
import { ButtonLink } from 'components/ui'
import { formatNumberWithComma } from 'utils'

function PackagesCardSummary({ className, packages, onDeletePackage }) {
  return packages.map(pack => (
    <div className={`${className} flex`} key={pack.name}>
      <Typography {...TextStyles.subtitle1Dark()}>
        - {pack.name} adicional | {formatNumberWithComma(pack.monthlyFeeWithTax)} €/mes{' '}
        {pack.duration ? `| ${pack.duration} meses` : ''}
      </Typography>
      <ButtonLink onClick={() => onDeletePackage(pack.id)}>eliminar</ButtonLink>
    </div>
  ))
}

PackagesCardSummary.propTypes = {
  className: PropTypes.string.isRequired,
  onDeletePackage: PropTypes.func.isRequired,
  packages: PropTypes.array.isRequired,
}

export default PackagesCardSummary
