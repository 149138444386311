export const INFO = 'info'
export const PRESCORING = 'prescoring'
export const OFFERS = 'offers'
export const BILLING = 'billing'
export const SUMMARY = 'summary'
export const APPOINTMENT = 'appointment'
export const NEXT_STEPS = 'next-steps'

export const STEPS = {
  [INFO]: 1,
  [OFFERS]: 2,
  [BILLING]: 3,
  [SUMMARY]: 4,
  [APPOINTMENT]: 6,
  [NEXT_STEPS]: 7,
}

export const OFFER_CONFIG_STEPS = {
  [OFFERS]: 1,
  [INFO]: 2,
  [BILLING]: 3,
  [SUMMARY]: 4,
  [APPOINTMENT]: 5,
  [NEXT_STEPS]: 6,
}
