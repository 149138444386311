import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'

import { HTMLTemplate } from 'modules/ui/components/HTMLTemplate/HTMLTemplate'

import { Step } from './Step'

function TerminalCompanyFinancial({ terminal, nextStepsTexts }) {
  return (
    <HTMLTemplate
      html={nextStepsTexts.terminal_company.description}
      values={{
        finalPayment: terminal.finalPayment || 0,
      }}
    />
  )
}

TerminalCompanyFinancial.propTypes = {
  terminal: PropTypes.object.isRequired,
  nextStepsTexts: PropTypes.object.isRequired,
}

function mapTerminalToHtml(
  terminal,
  htmlTemplateValues,
  htmlTemplateDescription,
  nextStepsTexts,
  isCompany,
) {
  return (
    <div className="m-t-8" key={terminal.lineType}>
      <HTMLTemplate html={htmlTemplateDescription} values={htmlTemplateValues} />
      {isCompany && (
        <TerminalCompanyFinancial terminal={terminal} nextStepsTexts={nextStepsTexts} />
      )}
    </div>
  )
}

function mapFinancedTerminal(terminal, nextStepsTexts, isCompany) {
  return mapTerminalToHtml(
    terminal,
    {
      ...terminal,
      initialPayment: terminal.initialPayment || 0,
      finalPayment: terminal.finalPayment || 0,
      tin: terminal.tin || 0,
      tae: terminal.tae || 0,
      commissionFeePercentage: terminal.commissionFeePercentage || 0,
    },
    nextStepsTexts.terminal_financial.description,
    nextStepsTexts,
    isCompany,
  )
}

function mapSinglePaymentTerminal(terminal, nextStepsTexts, isCompany) {
  return mapTerminalToHtml(
    terminal,
    { ...terminal },
    nextStepsTexts.terminal_financial?.upfront_description,
    nextStepsTexts,
    isCompany,
  )
}

function mapTerminalsToHtml(financedTerminals, singlePaymentTerminals, nextStepsTexts, isCompany) {
  let terminals = []
  if (!isEmpty(financedTerminals)) {
    terminals = [
      ...financedTerminals.map(financialTerminal =>
        mapFinancedTerminal(financialTerminal, nextStepsTexts, isCompany),
      ),
    ]
  }
  if (!isEmpty(singlePaymentTerminals)) {
    terminals = [
      ...terminals,
      ...singlePaymentTerminals.map(singlePaymentTerminal =>
        mapSinglePaymentTerminal(singlePaymentTerminal, nextStepsTexts, isCompany),
      ),
    ]
  }

  return <>{terminals}</>
}

export function TerminalFinancial({
  financedTerminals,
  singlePaymentTerminals,
  isCompany,
  nextStepsTexts,
}) {
  return (
    <Step
      title={nextStepsTexts.terminal_financial.title}
      description={mapTerminalsToHtml(
        financedTerminals,
        singlePaymentTerminals,
        nextStepsTexts,
        isCompany,
      )}
      data-hook="terminal_financial"
    />
  )
}

TerminalFinancial.propTypes = {
  financedTerminals: PropTypes.array.isRequired,
  singlePaymentTerminals: PropTypes.array,
  isCompany: PropTypes.bool,
  nextStepsTexts: PropTypes.object.isRequired,
}
