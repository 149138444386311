/* eslint-disable import/no-cycle */
import PropTypes from 'prop-types'
import { Chip } from '@material-ui/core'
import { get } from 'lodash'

const statusLabels = {
  new: 'Nueva',
  active: 'Activa',
  port: 'Port Out',
  freeze: 'Congelada',
  fly: 'En Vuelo',
  unsubscribe: 'Baja',
  historical: 'Histórico',
  incidence: 'Incidencia',
  unknown: 'Desconocido',
  cancelled: 'Cancelado',
  delivered: 'Enviado',
}

const SubscriptionStatus = {
  FREEZE: 'freeze',
  UNSUBSCRIBE: 'unsubscribe',
  INCIDENCE: 'incidence',
  CANCELLED: 'cancelled',
  FLY: 'fly',
  UNKNOWN: 'unknown',
  NEW: 'new',
  ACTIVE: 'active',
  DELIVERED: 'delivered',
  PORT: 'port',
}

const SubscriptionStatusBadge = ({ className, status }) => {
  let chipClass = null

  const props = {
    className,
  }

  switch (status) {
    case SubscriptionStatus.FREEZE:
      props.color = 'primary'
      break
    case SubscriptionStatus.UNSUBSCRIBE:
    case SubscriptionStatus.INCIDENCE:
    case SubscriptionStatus.CANCELLED:
      chipClass = 'MuiChip-colorBlocked'
      break
    case SubscriptionStatus.FLY:
    case SubscriptionStatus.UNKNOWN:
      chipClass = 'MuiChip-colorWarning'
      break
    case SubscriptionStatus.NEW:
    case SubscriptionStatus.ACTIVE:
    case SubscriptionStatus.DELIVERED:
      chipClass = 'MuiChip-colorSuccess'
      props.variant =
        (status === SubscriptionStatus.ACTIVE || status === SubscriptionStatus.DELIVERED) &&
        'outlined'
      break
    case SubscriptionStatus.PORT:
      chipClass = 'MuiChip-colorError'
      break
    default:
  }

  if (chipClass) {
    props.className = [chipClass, ...(className ? className.split(' ') : [])].join(' ')
  }

  return (
    <Chip
      label={get(statusLabels, status, statusLabels.unknown).toUpperCase()}
      size="small"
      style={{ minWidth: '70px' }}
      {...props}
    />
  )
}

SubscriptionStatusBadge.NEW = 'new'
SubscriptionStatusBadge.ACTIVE = 'active'
SubscriptionStatusBadge.PORT = 'port'
SubscriptionStatusBadge.FREEZE = 'freeze'
SubscriptionStatusBadge.FLY = 'fly'
SubscriptionStatusBadge.UNSUBSCRIBE = 'unsubscribe'
SubscriptionStatusBadge.HISTORICAL = 'historical'
SubscriptionStatusBadge.INCIDENCE = 'incidence'
SubscriptionStatusBadge.DELIVERED = 'delivered'

SubscriptionStatusBadge.defaultProps = {
  status: 'new',
}

SubscriptionStatusBadge.propTypes = {
  className: PropTypes.string,
  status: PropTypes.oneOf([
    'new',
    'active',
    'port',
    'freeze',
    'fly',
    'unsubscribe',
    'historical',
    'incidence',
    'cancelled',
    'delivered',
  ]).isRequired,
}

export default SubscriptionStatusBadge
