import styled from 'styled-components'
import { ChangeOwnerLines } from './ChangeOwnerLines'

export const ChangeOwnerLinesUI = styled(ChangeOwnerLines)`
  padding-bottom: 2em;
  width: 100%;

  & .body {
    margin-top: 1em;
  }

  & label {
    border-radius: 8px;
    border: solid 2px #ececec;
    margin: 5px 0;
    width: 48%;
  }

  & .label_link {
    cursor: pointer;
    margin: 2em 0 1em;
  }

  & .advice-wrapper {
    margin-top: 1.5em;
  }
`
