import { useMemo } from 'react'
import PropTypes from 'prop-types'

import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/styles'
import { get } from 'lodash'

import { useLegalTexts } from 'modules/LegalTexts/hooks/index'

import { HTMLTemplate } from 'modules/ui/components/HTMLTemplate/HTMLTemplate'
import { Modal, SpinnerCenter, ModalActions, Button } from 'components/ui'

import { YOIGO_STORE, OTHER_ADDRESS } from 'modules/SharedSales/constants/shippingAddress'
import { formatOtherFullAddress } from 'modules/Coverage/helpers'

import { getFormattedPriceWithTaxes } from 'modules/NewClientSales/store/selectors/Summary.selectors.helpers'

import { legalDataSelectors } from './RenewalLegalModal.selectors'

const useStyles = makeStyles(theme => ({
  'renewal-legal-texts': {
    '& .html-template': {
      color: theme.palette.global.gray_dark,

      '& p': {
        marginBottom: '24px',
      },

      '& strong': {
        fontWeight: 500,
      },

      '& ul > li': {
        marginBottom: '4px',
        lineHeight: '20px',
      },
    },
  },
}))

export const RenewalLegalModal = ({ isOpen, onConfirm, agileTvData, deviceShipping }) => {
  const classes = useStyles()

  const {
    device,
    isFinalPaymentDiscountApplicable,
    isDeviceBeingPaidUpfront,
    isFinalPaymentBeingExtended,
    subscriptionMsisdn,
    formattedBillingAddress,
    saleTax,
    isPro,
  } = useSelector(legalDataSelectors)

  const formattedInstallationAddress = useMemo(() => {
    const selectedPoint = deviceShipping?.deliveryPoint?.selectedPoint
    const zipCode = deviceShipping?.deliveryPoint?.zipCode || ''
    const storeName = get(selectedPoint, 'attributes.name', '')

    switch (deviceShipping?.type) {
      case YOIGO_STORE.value:
        return selectedPoint
          ? `En tienda (${storeName} ${get(selectedPoint, 'attributes.address', '')}, ${zipCode})`
          : ''
      case OTHER_ADDRESS.value:
        return deviceShipping?.otherAddress
          ? formatOtherFullAddress(deviceShipping?.otherAddress)
          : ''
      default:
        return formattedBillingAddress.address
    }
  }, [deviceShipping])

  const hasAgileTvPromo = get(agileTvData, 'duration', false)

  const { isLoading, legalTexts } = useLegalTexts({ origin: 'sales' })

  return (
    <Modal
      title="Textos de confirmación"
      isOpen={isOpen}
      type="default"
      className={classes['renewal-legal-texts']}>
      {isLoading && <SpinnerCenter showMsg />}
      {!isLoading && (
        <>
          <HTMLTemplate
            className="html-template"
            html={legalTexts.renewal_overview_info}
            values={{
              deviceName: device.name,
              subscriptionMsisdn,
              shippingAddress: formattedInstallationAddress,
              includedDeviceDiscount: device.includedDiscount,
            }}
          />

          {(isFinalPaymentDiscountApplicable || isFinalPaymentBeingExtended) && (
            <HTMLTemplate
              className="html-template"
              html={legalTexts.renewal_final_payment_discount_loss}
            />
          )}

          {isFinalPaymentBeingExtended && (
            <HTMLTemplate
              className="html-template"
              html={legalTexts.renewal_final_payment_extended}
            />
          )}

          {isDeviceBeingPaidUpfront ? (
            <HTMLTemplate
              className="html-template"
              html={legalTexts.renewal_upfront_payment}
              values={{
                upfrontTotalPayment: device.initialPayment,
              }}
            />
          ) : (
            <HTMLTemplate
              className="html-template"
              html={legalTexts.renewal_financed_payment}
              values={{
                totalPaymentAfterCommissionFee: device.totalPaymentAfterCommissionFee,
                initialPayment: device.initialPayment,
                monthlyPayment: device.monthlyPayment,
                finalPayment: device.finalPayment,
                tin: device.tin,
                tae: device.tae,
                commissionFeePercentage: device.commissionFeePercentage,
                commissionFee: device.commissionFee,
                totalPayment: device.totalPayment,
              }}
            />
          )}

          {!!agileTvData && (!hasAgileTvPromo || (hasAgileTvPromo && agileTvData.terms)) && (
            <HTMLTemplate
              className="html-template"
              html={hasAgileTvPromo ? legalTexts.term_agiletvpromo : legalTexts.term_agiletv}
              values={
                hasAgileTvPromo
                  ? {
                      permanence: agileTvData.terms.count,
                      penalty: isPro
                        ? `${agileTvData.terms.taxFreeAmount}€ (impuestos no incluídos)`
                        : `${getFormattedPriceWithTaxes(
                            agileTvData.terms?.taxFreeAmount,
                            saleTax,
                          )}`,
                      noReturnalPenalty: null,
                    }
                  : {
                      noReturnalPenalty: null,
                    }
              }
            />
          )}

          <HTMLTemplate className="html-template" html={legalTexts.renewal_device_return_info} />

          <HTMLTemplate className="html-template" html={legalTexts.renewal_end_greetings} />

          <ModalActions>
            <Button data-hook="confirm-legal" onClick={onConfirm}>
              {device?.initialPayment === 0 ? 'Finalizar' : 'Continuar'}
            </Button>
          </ModalActions>
        </>
      )}
    </Modal>
  )
}

RenewalLegalModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  deviceShipping: PropTypes.object.isRequired,
  agileTvData: PropTypes.object,
}
