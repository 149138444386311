import { useState } from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import { isEmpty } from 'lodash'
import { ModalActions, Button } from 'components/ui'
import { OTHER_ADDRESS } from 'modules/SharedSales/constants/index'
import { ModalContent } from 'modules/offers-configuration/components/ModalContent'
import { AddressChooser } from '../AddressChooser/AddressChooserComponent'

export const AddressChooserModal = ({
  onClose,
  onConfirm,
  preselectedAddress,
  preselectedAddressType,
  allowInputManualAddress,
  isRequiredFields,
}) => {
  const [addressType, setAddressType] = useState(
    preselectedAddressType?.value || OTHER_ADDRESS.value,
  )
  const [svaAddress, setSvaAddress] = useState('')

  return (
    <ModalContent data-hook="modal-address-chooser">
      <Formik initialValues={{ billing: { otherAddress: { address: '' } } }}>
        {(setFieldValue, errors) => (
          <form>
            <AddressChooser
              title="Dirección de instalación"
              preselectedAddress={preselectedAddress}
              availableAddressTypes={[
                ...(preselectedAddressType ? [preselectedAddressType] : []),
                OTHER_ADDRESS,
              ]}
              type="billing"
              hideButtons={!preselectedAddressType}
              selectedAddressType={addressType}
              onSelectAddress={type => setAddressType(type)}
              onSetAddress={address => {
                setSvaAddress(address)
              }}
              onEditAddress={() => {
                setFieldValue('billing.otherAddress', { address: '' })
                setSvaAddress('')
              }}
              allowInputManualAddress={allowInputManualAddress}
              isRequiredFields={isRequiredFields}
            />
            <ModalActions>
              <Button
                type="submit"
                data-hook="sva-close-modal"
                onClick={() => {
                  onClose()
                  onConfirm(addressType === OTHER_ADDRESS.value ? svaAddress : null)
                }}
                disabled={
                  (!svaAddress || isEmpty(svaAddress) || !isEmpty(errors)) &&
                  addressType === OTHER_ADDRESS.value
                }>
                Continuar
              </Button>
              <Button secondary onClick={() => onClose()}>
                Cancelar
              </Button>
            </ModalActions>
          </form>
        )}
      </Formik>
    </ModalContent>
  )
}

AddressChooserModal.propTypes = {
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  preselectedAddress: PropTypes.any,
  preselectedAddressType: PropTypes.object,
  allowInputManualAddress: PropTypes.bool,
  isRequiredFields: PropTypes.bool,
}
