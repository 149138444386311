import { useEffect } from 'react'
import { PropTypes } from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import {
  findBillingsInitAction,
  selectBillingsIsLoading,
  selectBillingsError,
  selectBillingsData,
} from 'modules/billings'
import * as customerActivitySvc from 'modules/CustomerActivity'
import { Bills } from '../../components/Bills'

export default function BillsContainer({ className }) {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(findBillingsInitAction({ filter: 'period:12' }))
  }, [])

  const props = {
    className,
    isLoading: useSelector(selectBillingsIsLoading),
    hasErrors: useSelector(selectBillingsError),
    bills: useSelector(selectBillingsData),
    registers: useSelector(customerActivitySvc.selectData),
    isLoadingRegisters: useSelector(customerActivitySvc.selectLoading),
  }
  return <Bills {...props} />
}

BillsContainer.propTypes = {
  className: PropTypes.string,
}
