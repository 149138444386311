import styled from 'styled-components'

const Card = styled.section`
  width: ${props => (props.width ? props.width : '100%')};
  border-radius: ${props => props.borderRadius || '4px'};
  background-color: #fcfcfc;
  box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.27);
  margin: ${props => (props.margin ? props.margin : '24px auto')};
  padding: ${props => (props.padding ? props.padding : '24px')};
  box-sizing: border-box;
`

export default Card
