import PropTypes from 'prop-types'

import { Box, Typography } from '@material-ui/core'

const CancelWarning = ({ warningMessage }) => (
  <Box margin="32px 0">
    <Typography variant="body1">{warningMessage}</Typography>
  </Box>
)

CancelWarning.propTypes = {
  warningMessage: PropTypes.string,
}

export default CancelWarning
