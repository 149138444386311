import { Component } from 'react'
import PropTypes from 'prop-types'
import { orderBy, isEmpty } from 'lodash'

import { CircularProgress, Grid } from '@material-ui/core'

import { checkIsNeba, checkIsCommercialVula } from 'modules/Coverage'
import { filterTariffs } from 'modules/tariffs/store-apigee'
import { fillChangeTechnologyOffer } from './client-offer.helper'
import { ClientOffersInfo } from '../ClientOffersInfo'
import { CurrentSubscription } from '../CurrentSubscription'
import { NewSubscriptions } from '../NewSubscriptions'
import { Modal } from '../Modal'

export class ClientOffers extends Component {
  state = {
    display: false,
    index: null,
    modal: false,
    select: '',
    changeTechnologyJson: this.props.changeTechnologyJson,
    offer: null,
  }

  shouldComponentUpdate = (nextProps, nextState) => this.state !== nextState

  static getDerivedStateFromProps() {
    return { modal: false }
  }

  onOpenHandler = () => {
    this.setState({ display: true, modal: false })
  }

  onCloseHandler = () => {
    this.setState({ display: false })
  }

  onSelectChange = event => {
    this.setState({ select: event.target.value, modal: false })
  }

  onMenuItemChange = (index, item) => {
    let { changeTechnologyJson } = this.state
    changeTechnologyJson = fillChangeTechnologyOffer(item, this.props.changeTechnologyJson)
    this.setState({ index, changeTechnologyJson, offer: item })
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.checked })
  }

  handleUpdateIfAvailable = data => {
    this.setState({ select: data })
  }

  render() {
    const {
      customer,
      orderId,
      enrichment,
      tariffs,
      loadingOrder,
      sendOrder,
      newSubscription,
      isFtth,
      phoneNumber,
      currentTariff,
      available,
      loading,
      productOrders,
      isFeatNebaEnabled,
      territoryOwner,
      customerTariff,
      showAppointment,
    } = this.props

    let sortedTariffs = []
    const isFtthToNeba = isFtth && checkIsNeba(territoryOwner)
    const isCommercialVulaTO = checkIsCommercialVula(territoryOwner)

    if (tariffs !== undefined && !isFtthToNeba) {
      sortedTariffs = filterTariffs(
        orderBy(tariffs, item => Number(item.monthly_fee), ['desc']),
        territoryOwner,
        isFeatNebaEnabled,
        currentTariff,
      )
    }
    let loadingTariffs = true

    if (loading !== undefined) {
      loadingTariffs = loading
    }
    let offers = <CircularProgress />
    let changeSuscription = <div />
    if (sortedTariffs.length !== 0 && customer !== undefined) {
      if (newSubscription || phoneNumber) {
        changeSuscription = (
          <NewSubscriptions
            phoneNumber={phoneNumber}
            newSubscription={newSubscription}
            data={sortedTariffs}
            tariff={currentTariff}
            index={this.state.index}
            select={this.state.select}
            onSelectChange={this.onSelectChange}
            onMenuItemChange={(index, item) => this.onMenuItemChange(index, item)}
            onOpenHandler={this.onOpenHandler}
            available={available}
            handleUpdate={() => this.handleUpdateIfAvailable(sortedTariffs)}
            offer={this.state.offer}
            changeTechnologyJson={this.state.changeTechnologyJson}
            customer={customer}
            enrichment={enrichment}
            orderId={orderId}
            loadingOrder={loadingOrder}
            sendOrder={sendOrder}
            productOrders={productOrders}
            showAppointment={showAppointment}
            isNebaTerritoryOwner={checkIsNeba(territoryOwner)}
          />
        )
      }

      offers = (
        <>
          <Grid alignItems="baseline" container>
            <CurrentSubscription products={customer.products} customerTariff={customerTariff} />
            {changeSuscription}
          </Grid>
          <ClientOffersInfo
            enrichment={enrichment}
            data={sortedTariffs}
            index={this.state.index}
            display={this.state.display}
            modal={this.state.modal}
            offer={this.state.offer}
            onCloseHandler={this.onCloseHandler}
            changeTechnologyJson={this.state.changeTechnologyJson}
          />
        </>
      )
    }

    if ((isEmpty(sortedTariffs) && !loadingTariffs) || isCommercialVulaTO) {
      offers = <p>No hay tarifas de fibra disponibles en esta dirección.</p>
    }

    return (
      <div>
        {offers}
        {(sortedTariffs.length === 0 && !loadingTariffs) || isCommercialVulaTO ? (
          <Modal
            open
            title="Aviso Importante!"
            message="Servicio de Fibra no está disponible en esta dirección."
            cancelBtn="Salir"
            quit
          />
        ) : null}
      </div>
    )
  }
}

ClientOffers.propTypes = {
  customer: PropTypes.object.isRequired,
  orderId: PropTypes.string,
  enrichment: PropTypes.array,
  tariffs: PropTypes.array,
  loadingOrder: PropTypes.bool,
  sendOrder: PropTypes.func,
  newSubscription: PropTypes.bool,
  isFtth: PropTypes.bool,
  phoneNumber: PropTypes.string,
  currentTariff: PropTypes.object,
  available: PropTypes.bool,
  loading: PropTypes.bool,
  changeTechnologyJson: PropTypes.object,
  productOrders: PropTypes.object,
  isFeatNebaEnabled: PropTypes.bool,
  territoryOwner: PropTypes.string,
  customerTariff: PropTypes.object,
  showAppointment: PropTypes.bool,
}
