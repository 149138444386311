import { useSelector, useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { ListDivider, TabsContainer, SpinnerCenter } from 'components/ui'
import { SectionHeader } from 'modules/ui'
import { isNil } from 'lodash'

import { useCleanQueryParamsWhenUnmount } from 'modules/Router'
import { AlarmsContainer } from 'modules/alarms'

import { useCvmActions } from 'modules/offers-configuration/hooks'
import { useShowTypification } from 'modules/vista-360/hooks/useShowTypification'
import { getIsTypificationAllowed } from 'modules/offers-configuration/selectors'
import { selectIsErrorSecurizerIframe } from 'modules/typifications/store/selectors/securizerTypifications.selectors'
import { useGetIframeData } from 'modules/typifications/hooks'
import { resetSecurizerFailed } from 'modules/typifications/store/actions/securizerTypifications.actions'
import { SECURIZER_ERROR } from 'modules/typifications/constants'
import NotificationModal from 'components/packages/ui/modal/NotificationModal'

import { tabsConfig } from './tabsConfig'

const PADDING = '10px 20px'

export function AccountDashboard({ className, isLoading, hasErrors }) {
  const dispatch = useDispatch()

  const [isSecurizerErrorModal, setIsSecurizerErrorModal] = useState(false)

  const { openTypificationDispatch, openTypificationIframeDispatch } = useCvmActions()
  const { permissionIframeAccess, sfid, area, customerId, msisdns } = useGetIframeData()
  const isTypificationAllowed = useSelector(getIsTypificationAllowed)
  const isSecurizerError = useSelector(selectIsErrorSecurizerIframe)

  useEffect(() => {
    if(!isNil(isSecurizerError)) {
      setIsSecurizerErrorModal(isSecurizerError)
    }
  }, [isSecurizerError])

  const onNotificationClose = () => {
    setIsSecurizerErrorModal(false)
    dispatch(resetSecurizerFailed())
  }

  const actionIframeTipis = permissionIframeAccess ? [{
    label: 'Tipificación',
    onClick: () => openTypificationIframeDispatch({customerId, sfid, area, msisdns}),
    disabled: !isTypificationAllowed,
  }] : []

  useCleanQueryParamsWhenUnmount(['op', 'subscription_id'])

  const actions = useShowTypification()
    ? [
        {
          label: 'Tipificar',
          onClick: openTypificationDispatch,
          disabled: !isTypificationAllowed,
        },
        ...actionIframeTipis
      ]
    : [...actionIframeTipis]

  return (
    <div className={className}>
      {isLoading && <SpinnerCenter showMsg />}
      {isSecurizerErrorModal && <NotificationModal   
        type="alert"
        src="/assets/error.svg"
        title={SECURIZER_ERROR}
        isOpen
        onClose={onNotificationClose} />}
      {!isLoading && !hasErrors && (
        <>
          <SectionHeader title="Configurador de ofertas" actions={actions} />
          <ListDivider padding={PADDING} />
          <AlarmsContainer className="container-alarms p-x-24 p-t-24 p-b-12" />
          <TabsContainer
            tabsConfig={tabsConfig}
            contentProps={{ orderId: 1234 }}
            queryParamName="tab"
          />
        </>
      )}
    </div>
  )
}

AccountDashboard.propTypes = {
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  hasErrors: PropTypes.bool,
}
