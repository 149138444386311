import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'

import { ButtonLink, ToolTip, FormikInput } from 'components/ui'

export function InputIUA({ lineType }) {
  return (
    <Grid container>
      <Grid item xs={3}>
        <FormikInput label="Introduce el IUA" name={`${lineType}.IUA`} fullWidth maxLength="12" />
      </Grid>
      <Grid item xs={1} style={{ alignSelf: 'center', paddingLeft: '10px' }}>
        <ToolTip
          bold="Identificador único de acceso: "
          title="Para una instalación más rápida y sencilla necesitamos un código de 12 dígitos que hay en tu factura. Lo encontrarás como IUA o Identificador único de acceso. Sin él tardaremos algo más en instalarte y deberemos, además, enviarte a un técnico de Telefónica a tu domicilio."
          placement="right"
          visible>
          <div>
            <ButtonLink>¿Qué es el IUA?</ButtonLink>
          </div>
        </ToolTip>
      </Grid>
    </Grid>
  )
}

InputIUA.propTypes = {
  lineType: PropTypes.string.isRequired,
}
