import { axiosCustomer } from 'modules/axios'
import { CONFIG } from './environments'

const buildFilterQuery = (documentType, documentId) => {
  if (documentType === 'MSISDN') return `msisdn:${documentId}`
  return `customerid:${documentId}|customeridtype:${documentType}`
}

export function findCustomerProducts(documentType, documentId) {
  return axiosCustomer({
    method: 'GET',
    url: `${CONFIG.api.customersProducts}?filter=${buildFilterQuery(documentType, documentId)}`,
  }).then(resp => resp.data)
}
