// TODO: delete this file when the new coverage/presales components are done
import { theme } from 'themes'

export const errorBorderStyles = '2px solid #f44336'
export const defaultBorderStyles = `2px solid ${theme.palette.primary.main}`

export const addressInputClassName = 'rbt-input-wrapper'
export const numberInputClassName = 'form-control'
export const addOwnStyles = noToast => {
  const styles = `
  .rbt-input-wrapper, .form-control:not(.rbt-input) {
    border: ${defaultBorderStyles};
    background-color: #ffffff;
    padding: 16px;
    border-radius: 4px;
    font-size: 16px;
    color: #4d4e4f;
  }

  .clearfix {
      width: 100%;
  }

  .col-sm-12 {
      width: 100%;
  }

  .rbt-input-main {
      font-size: 16px;
  }

  .rbt-input-main::placeholder {
      color: #b9c2c7;
  }

  i.fa.fa-info-circle.fa-lg.info {
    position: absolute;
    right: 50px;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 16px;
    cursor: pointer;
    text-transform: uppercase;
  }

  .text-center {
      text-align: center;
  }

  .fa-lg.info:after {
      content: "+ info";
      height: 16px;
  }

  #coverage-form {
      min-height: 55px;
  }

  #client-app-coverage > #coverage-container {
      position: relative;
      padding: 0;
  }

  .clearfix > .rbt-input {
      padding: 0;
  }

  .clearfix > .form-control:focus {
      box-shadow: none!important;
      outline: 0!important;
  }

  div .rbt .focus {
      box-shadow: none!important;
      outline: 0!important;
  }

  .rbt-input-hint {
    display: none!important;
  }
  .rbt-input-main {
      font-size: 16px!important;
      color: #4d4e4f;
  }

  .rbt-input-wrapper:focus-within {
      border: ${defaultBorderStyles};
  }

  .dropdown-menu.rbt-menu.dropdown-menu-justify li {
      padding: 0;
  }

  .dropdown-item div:hover {
      background-color: #efefef;
  }

  .dropdown-item div {
      display: flex;
      align-items: center;
      background-color: white;
      padding: 24px 20px;
      border-bottom: 1px solid #ececec;
  }

  .dropdown-item div > strong {
      padding-right: 32px;
      font-size: 16px;
      color: #1f1f2a;
      font-weight: 500;
  }

  .dropdown-item div > span {
      font-size: 14px;
      color: #9b9b9b;
  }

  .rbt-menu > li a {
      text-decoration: none;
  }

  .dropdown-menu.rbt-menu.dropdown-menu-justify {
      box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.37), 0 0 2px 0 rgba(0, 0, 0, 0.12);
      margin-top: 2px;
  }

  .rbt-sr-status {
      display: none;
  }

  .form-group {
      display: flex;
      position: relative;
    }

  .form-group:not(:last-child) {
      margin-bottom: 18px;
  }
  .form-group > input:first-child {
      margin-top: 12px;
  }

  .form-group input {
      width: 100%;
  }

  .form-group input:disabled {
      background-color: #ececec;
  }

  .btn-form, .btn-default {
      border-radius: 18px;
      border: solid 1px #a9a8da;
      padding: 10px;
      width: 208px;
      text-transform: uppercase;
      font-size: 14px;
      color: #6462cb;
      font-weight: bold;
      margin-top: 36px;
      cursor: pointer;
      margin-bottom: 40px;
  }

  .btn-form:hover {
      background-color: rgba(53, 52, 138, 0.1);
  }

  .modal {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1050;
      display: none;
      overflow: hidden;
      -webkit-overflow-scrolling: touch;
      outline: 0;
  }

  .modal.fade .modal-dialog {
      transition: transform .3s ease-out;
      transform: translate(0, -25%);
  }

  .modal.in .modal-dialog {
      transform: translate(0, 20%);
  }

  .modal-backdrop.in {
      filter: alpha(opacity=50);
      opacity: .5;
  }

  .modal-backdrop.in {
      opacity: 0.35 !important;
  }

  .modal-backdrop.fade {
      filter: alpha(opacity=0);
      opacity: 0;
  }

  .fade.in {
      opacity: 1;
  }

  .modal-backdrop {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1040;
      background-color: #000;
  }

  .fade {
      opacity: 0;
      -webkit-transition: opacity .15s linear;
      -o-transition: opacity .15s linear;
      transition: opacity .15s linear;
  }

  .modal-open .modal {
      overflow-x: hidden;
      overflow-y: auto;
  }

  .modal-content {
      position: relative;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #999;
      border: 1px solid rgba(0, 0, 0, .2);
      border-radius: 6px;
      outline: 0;
      box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
  }

  .modal-header {
      border: none !important;
      padding: 15px 15px 0 15px !important;
  }

  .modal-body {
      position: relative;
      padding: 15px;
  }

  .modal-body table thead tr th:not(:first-child) div:nth-child(2) {
      display: none !important;
  }

  .modal-body table thead tr th,
  .modal-body table tbody tr td {
      height: inherit !important;
      white-space: unset !important;
      padding: initial !important;
      text-align: center !important;
      vertical-align: middle;
  }

  .modal-dialog > .modal-content > .modal-body > .card > div > div > div:nth-child(2) {
    padding: 0 10px;
  }

  .modal-dialog {
      width: 650px;
      margin: 0 auto;
  }

  select.form-control {
      width: 100%;
      min-height: 40px;
  }

  .modal-btn-container.text-center {
      margin: 34px 0;
  }

  .btn-block {
      width: 100%;
  }

  .modal-datatables-btn-container > .row {
      display: flex;
      justify-content: space-between;
  }

  .modal-datatables-btn-container > .row > .col-xs-6 {
      padding: 12px;
      flex: 1;
  }

  .modal-info div.modal-info-content {
      padding: 24px;
      line-height: 24px;
  }

  .form-horizontal > .form-group {
      margin-top: 0;
      padding: 4px;
      width: 100%;
      color: #4d4e4f;
  }

  .form-horizontal:not(:empty) {
      border-radius: 4px;
      border: solid 1px #eff6fa;
      padding: 8px;
      margin-top: 32px;
      background-color: white;
  }

  .text-right {
      font-weight: 500;
      margin-right: 12px;
  }

  .btn-primary {
      border-radius: 18px;
      border: solid 1px #a9a8da;
      padding: 10px;
      text-transform: uppercase;
      background-color: ${theme.palette.primary.main};
      font-size: 14px;
      color: white;
      font-weight: bold;
      margin-top: 36px;
      cursor: pointer;
      margin-bottom: 40px;
  }

  .coverage-360, .toast-error, .toast-success {
      z-index: 999999;
  }

  .toast-error, .toast-success {
      position: fixed;
      top: 24px;
      right: 24px;
      max-width: 300px;
      padding: 24px;
      color: white;
      font-size: 18px;
      border-radius: 4px;
  }

  .toast-error {
      background-color: red;
  }

  .toast-success {
      background-color: green;
  }

  .btn-form[name] {
      display: none
  }

  .dropdown-menu-justify{
      position:absolute;
      width:100%;
      z-index: 9999;
  }

  .spinner-overlay {
      position: absolute;
      z-index: 4001;
      margin: auto;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background-color: white;
      text-align: center;
  }

  .modal-spinner, .spinner {
      position: relative;
      padding: 15px 0 30px;
  }

  .modal-spinner {
      left: calc(50% - 50px);
  }

  .modal-dialog > .modal-content > .modal-body > .modal-spinner > div:first-child {
      width: 100px;
      height: 100px;
      display: inline-block;
      border-radius: 100%;
      border-width: 2px;
      border-style: solid;
      border-image: initial;
      border-color: ${theme.palette.primary.main} ${theme.palette.primary.main} transparent;
      animation: animation-rotate 0.75s linear 0s infinite normal both running;
      background: transparent !important;
  }

  .spinner {
      top: calc(50% - 50px);
      left: -50px;
      z-index: 9999;
      display: inline-block;
  }

  .spinnner:first-child {
      position: relative;
      width: 100px;
      height: 100px;
  }

  .spinner > div > * {
      position: absolute;
      height: 100px;
      width: 100px;
      background-color: ${theme.palette.primary.main};
      opacity: 0.6;
      top: 0px;
      left: 0px;
      border-radius: 100%;
  }

  .spinner > div > div:first-child {
      animation: animation-toggle-size 2.1s ease-in-out 0s infinite normal none running;
  }

  .spinner > div > div:last-child {
      animation: animation-toggle-size 2.1s ease-in-out 1s infinite normal none running;
  }

  @keyframes animation-toggle-size {
      0%, 100% { transform: scale(0); }
      50% { transform: scale(1); }
  }

  @keyframes animation-rotate {
      0% { transform:rotate(0deg) scale(1) }
      50% { transform:rotate(180deg) scale(0.8) }
      100% { transform:rotate(360deg) scale(1) }
  }
  `
  const toastStyle = `
  .toast-success, .toast-error {
        display: none !important;
    }
  `
  let finalStyle = styles
  if (noToast) {
    finalStyle += toastStyle
  }
  const stylesheet = document.createElement('style')
  stylesheet.className = 'coverage-styles'
  stylesheet.innerHTML = finalStyle
  document.body.appendChild(stylesheet)
}
