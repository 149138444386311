import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

export const useSagaLoad = (loadingSelector, sagaLoadAction, onValueChangeLoad) => {
  const dispatch = useDispatch()

  const isSagaLoading = Boolean(useSelector(loadingSelector))

  const sagaLoad = () => {
    dispatch(sagaLoadAction())
  }

  const [isLoading, setLoading] = useState(true)
  const [loadStarted, setLoadStarted] = useState(false)

  useEffect(() => {
    sagaLoad()
    setLoadStarted(true)
    setLoading(true)
  }, [onValueChangeLoad])

  useEffect(() => {
    if (!isSagaLoading && loadStarted) {
      setLoading(false)
      setLoadStarted(false)
    }
  }, [isSagaLoading])

  return {
    sagaLoad,
    isLoading,
  }
}
