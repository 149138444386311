import { call, put, takeLatest } from 'redux-saga/effects'
import { logError, formatErrorMessage } from 'services/logging'
import { axiosFactory } from 'modules/axios'

import * as featureFlagSvc from 'services/feature-flag'
import { actionTypes } from 'modules/typifications/constants'
import { loginAgent } from '../apis'
import { loginAgentSuccess, loginAgentFailed } from '../actions'

export function* loginAgentInit({ payload }) {
  try {
    const response = yield call(loginAgent, payload)
    const { accessToken, agentToken } = response.data
    yield put(loginAgentSuccess({ accessToken, agentToken }))
    yield put(featureFlagSvc.actions.getFlags())
  } catch (e) {
    yield call(logError, { e: new Error(formatErrorMessage(e)), payload })
    const error = axiosFactory.buildError(e)
    yield put(loginAgentFailed(error))
  }
}

export function* watchAuthTypficationSagas() {
  yield takeLatest(actionTypes.LOGIN_AGENT_INIT, loginAgentInit)
}
