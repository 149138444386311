import PropTypes from 'prop-types'
import styled from 'styled-components'
import { get } from 'lodash'
import SubscriptionStatusBadge from '../subscription-status-badge/SubscriptionStatusBadge'

const OfferHeaderWrapper = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 17px 24px;
  background: #2981bc;
  border-radius: 14px 14px 0 0;
  .text {
    color: ${props => get(props, 'theme.palette.brand.light')};
  }
  .title {
    color: ${props => get(props, 'theme.palette.brand.light')};
    font-size: 20px;
  }
  .subtitle {
    font-size: 14px;
    margin-top: -6px;
  }
`

const OfferHeader = props => {
  const { title, subtitle, status } = props
  return (
    <OfferHeaderWrapper className="offer-header">
      <div className="text">
        <div className="title">{title}</div>
        <div className="subtitle">{subtitle}</div>
      </div>
      {status ? <SubscriptionStatusBadge status={status} /> : null}
    </OfferHeaderWrapper>
  )
}

OfferHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  status: PropTypes.oneOf(['new', 'active', 'port', 'freeze', 'fly', 'unsubscribe']),
}

export default OfferHeader
