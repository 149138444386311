import { APP_CONFIG } from 'services/app-config'
import { axiosCustomer, axiosJWT } from 'modules/axios'
import { get, chain } from 'lodash'
import { toLowerKeyParams } from 'modules/url'

export const tariffs = `${APP_CONFIG.apigee_host}/masmovil/v1/tariffs`
export const tariffs_rated = `${APP_CONFIG.care_cluster}/tariffs/`
export const ms_tariffs_catalog = `${APP_CONFIG.care_cluster}/ms-tariffs-catalog/catalog/tariffs`

const TARIFFS_CATALOG_CACHE = {}
const TARIFF_ID_CACHE = {}

const flattenTariff = tariff => ({
  id: tariff.id,
  ...tariff.attributes,
  permanency_contracts: get(tariff, 'relationships.permanency_contracts', []),
})

export function fetchTariffById(id) {
  const cacheKey = btoa(JSON.stringify({ id }))
  if (TARIFF_ID_CACHE[cacheKey]) {
    return TARIFF_ID_CACHE[cacheKey]
  }

  TARIFF_ID_CACHE[cacheKey] = axiosJWT({
    method: 'GET',
    url: `${ms_tariffs_catalog}/${id}`,
  })
    .then(resp => get(resp, 'data', []).map(flattenTariff))
    .catch(err => {
      TARIFF_ID_CACHE[cacheKey] = null
      throw err
    })

  return TARIFF_ID_CACHE[cacheKey]
}

export function fetchApigeeTariffs(filters) {
  const cacheKey = btoa(JSON.stringify({ filters }))
  if (TARIFFS_CATALOG_CACHE[cacheKey]) {
    return TARIFFS_CATALOG_CACHE[cacheKey]
  }

  TARIFFS_CATALOG_CACHE[cacheKey] = axiosJWT({
    method: 'GET',
    url: ms_tariffs_catalog,
    params: { status: 'active', ...filters },
  })
    .then(resp => get(resp, 'data', []).map(flattenTariff))
    .catch(err => {
      TARIFFS_CATALOG_CACHE[cacheKey] = null
      throw err
    })

  return TARIFFS_CATALOG_CACHE[cacheKey]
}

export function fetchConvergentTariffs(filters) {
  return axiosCustomer({
    method: 'GET',
    url: tariffs,
    params: {
      ...toLowerKeyParams(filters),
      ignoreWhitelist: true,
    },
  }).then(resp =>
    chain(get(resp, 'data.data'))
      .map(flattenTariff)
      .value(),
  )
}

export function fetchConvergentTariffsIds(territoryOwner) {
  return axiosJWT({
    method: 'GET',
    url: tariffs_rated,
    params: { territoryOwner, ignoreWhitelist: true, status: 'active,hidden' },
  }).then(resp => get(resp, 'data.tariffsId'))
}
