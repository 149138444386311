import { get, groupBy, find, last } from 'lodash'
import { findFee } from 'services/subscriptions'
import { formatMonthlyFee } from 'utils'
import { getTariffName } from 'modules/tariffs/helpers/tariffs.helpers'
import {
  formatGB,
  formatDuration,
  normalizePromo,
  hasSubscriptionChanged,
  applyDiscount,
} from '../helpers'

const getTariffFee = tariff => Number(tariff.monthly_fee || get(findFee(tariff), 'price.amount', 0))
const adjustDuration = (promo, i, promos) => {
  const lastPromo = promos[i - 1] || { duration: 0 }
  return { ...promo, duration: promo.duration - lastPromo.duration }
}

const generateCondonationDescription = ({ mobile, fixed }) => {
  const summary = []
  if (get(fixed, 'penaltyCondoned', false)) summary.push('Penalización condonada en línea fija.')
  if (get(mobile, 'penaltyCondoned', false)) summary.push('Penalización condonada en línea móvil.')
  if (get(fixed, 'permanenceRemoved', false)) summary.push('Permanencia eliminada en línea fija.')
  if (get(mobile, 'permanenceRemoved', false)) summary.push('Permanencia eliminada en línea móvil.')
  return summary.join(' ')
}

const promoToString = ({ name = '', mobile_data_amount_mb, monthlyFee, duration }) =>
  `${[
    name,
    mobile_data_amount_mb && `+ ${formatGB(mobile_data_amount_mb)}`,
    duration && `x ${formatDuration(duration)}`,
    monthlyFee && `x ${formatMonthlyFee(monthlyFee)}`,
  ]
    .filter(Boolean)
    .join(' ')}.`

const composeSummary = (promosData = [], { mobile, fixed, confirmedTariff }) => {
  const { tariffFee, monthlyFee = tariffFee } = last(promosData) || {}
  const summary = promosData
    .map(adjustDuration)
    .map(promoToString)
    .join(' Despues ')
  return [
    !summary && getTariffName(confirmedTariff),
    summary,
    monthlyFee !== tariffFee &&
      `Cuota sin promo: ${formatMonthlyFee(tariffFee)} impuestos incluidos.`,
    generateCondonationDescription({ mobile, fixed }),
  ]
    .filter(Boolean)
    .join(' ')
}

export const useOffersSummary = (subscriptions, modifications, promosData) => {
  const promosDataById = groupBy(promosData, 'id')

  return {
    modifiedSubscriptions: Object.keys(modifications).reduce((acc, id) => {
      const subscription = find(subscriptions, { id })
      const summary = composeSummary(promosDataById[id], modifications[id])
      if (summary)
        acc.push({ id: subscription.productNumber, subId: subscription.id, description: summary })
      return acc
    }, []),
    subscriptionsSummary: Object.keys(modifications)
      .filter(id => hasSubscriptionChanged(modifications[id]))
      .map(id => {
        const {
          confirmedTariff,
          confirmedBonuses: [bonus],
          confirmedDiscounts: [discount],
        } = modifications[id]
        const subscription = find(subscriptions, { id })
        const tariff = confirmedTariff || subscription
        const monthlyFee = getTariffFee(tariff)
        const normalizedBonus = normalizePromo(bonus)

        return {
          id: subscription.productNumber,
          name: getTariffName(tariff),
          bonusDuration: get(normalizedBonus, 'duration'),
          bonusData: get(normalizedBonus, 'mobile_data_amount_mb'),
          discountDuration: get(discount, 'duration'),
          discountFee: applyDiscount(monthlyFee, discount),
          fee: monthlyFee,
        }
      }),
  }
}
