import { useState } from 'react'
import { useSelector } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { getTerritoryOwner, selectTechnology, selectCoverageFullAddress } from 'modules/Coverage'
import { setSalesLead } from '../services/salesLead.service'

const storeSelectors = createStructuredSelector({
  territoryOwner: getTerritoryOwner,
  technology: selectTechnology,
  address: selectCoverageFullAddress,
})

export function useSendSalesLead(values) {
  const [hasMsError, setHasMsError] = useState()
  const [hasMsSuccess, setHasMsSuccess] = useState()
  const [isLoading, setIsLoading] = useState()

  const { address, ...coverageSelectors } = useSelector(storeSelectors)

  function formatSalesLeadValues() {
    const defaultValues = {
      ...values,
      result: '',
      territoryOwner: '',
      technology: '',
      address: '',
      surname2: values.surname2 || ''
    }
    const coverageValues = {
      ...coverageSelectors,
      address: coverageSelectors.technology ? address : '',
      result: coverageSelectors.technology ? 'OK' : 'KO',
    }
    return values.product === 'convergent' ? { ...defaultValues, ...coverageValues } : defaultValues
  }

  function sendSalesLead() {
    const body = formatSalesLeadValues()
    setIsLoading(true)
    setSalesLead(body)
      .then(() => {
        setIsLoading(false)
        setHasMsSuccess(true)
      })
      .catch(() => {
        setIsLoading(false)
        setHasMsError(true)
      })
  }

  return { hasMsError, sendSalesLead, setHasMsError, hasMsSuccess, setHasMsSuccess, isLoading }
}
