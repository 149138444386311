import { axiosCustomer, axiosJWT } from 'modules/axios'
import { APP_CONFIG } from 'services/app-config'

const BASE_URL_SALES_CUSTOMERS = `${APP_CONFIG.sales_cluster}/v1/customers`

export const apigeeOrdersUrl = `${APP_CONFIG.apigee_host}/masmovil/v1`
export const search360OrdersUrl = `${APP_CONFIG.care_cluster}/search-360/order`

export function getOrdersCancellationInfo(docNumber, docType) {
  return axiosJWT({
    method: 'GET',
    url: `${BASE_URL_SALES_CUSTOMERS}/cancellationConditions?docNumber=${docNumber}&docType=${docType}`,
  }).then(resp => resp.data)
}

export function find(customerId, orderId) {
  return axiosJWT({
    method: 'GET',
    url: `${search360OrdersUrl}/${orderId}?filter=none&expand=all`,
    headers: {
      ...axiosJWT.defaults.headers.common,
      'X-Customer-ID': customerId,
    },
  }).then(resp => resp.data)
}

export function replaceOrder({ body: { callReason } }, sfid, v3, orderId, replaceableOrderId) {
  return axiosJWT({
    method: 'PATCH',
    url: `${APP_CONFIG.sales_cluster}/v1/orders/${orderId}/mobileLine`,
    data: {
      reason: callReason,
      sfid,
      v3,
      mobileOrderId: replaceableOrderId,
    },
  }).then(resp => resp.data)
}

export function cancelOrder(data) {
  return axiosJWT({
    method: 'DELETE',
    url: `${APP_CONFIG.sales_cluster}/v1/orders/${data.orderId}?notification=${
      data.notify
    }&cancelRelated=${data.cancelRelated}`,
    data: {
      ...data.body,
      sfid: data.sfid,
    },
  }).then(resp => resp.data)
}

export function cancelPartialOrder(data, sfid, changeType) {
  const { callReason } = data.body
  return axiosJWT({
    method: 'DELETE',
    url: `${APP_CONFIG.sales_cluster}/v1/orders/${data.orderId}/partial`,
    data: {
      reason: callReason,
      sfid,
      changeType,
      v3: data.v3,
    },
  }).then(resp => resp.data)
}

export function updateOrder(orderId, data, v1 = false) {
  if (v1) {
    return axiosCustomer({
      method: 'PATCH',
      url: `${apigeeOrdersUrl}/orders/ovid/${orderId}`,
      data,
    }).then(resp => resp.data)
  }

  return axiosJWT({
    method: 'PATCH',
    url: `${APP_CONFIG.care_cluster}/change-order-address/${orderId}`,
    data,
  }).then(resp => resp.data)
}

// TODO: todo
export function retainOrder(orderId) {
  return axiosCustomer({
    // @TODO add endpoint
    method: 'POST',
    url: orderId,
  })
}

export function fetchPenaltyOrder(orderId) {
  return axiosJWT({
    method: 'GET',
    url: `${APP_CONFIG.sales_cluster}/v1/orders/${orderId}/penalties`,
  }).then(resp => resp.data)
}

export function fetchAdditionalLines(docType, docNumber, orderId) {
  return axiosJWT({
    method: 'GET',
    url: `${BASE_URL_SALES_CUSTOMERS}/additionalLines`,
    params: {
      docType,
      docNumber,
      orderId,
    },
  }).then(resp => resp.data)
}

export function fetchOrderWithFilters(filters) {
  return axiosCustomer({
    method: 'GET',
    url: `${apigeeOrdersUrl}/orders`,
    params: filters,
  }).then(resp => resp.data)
}
