import { useEffect, memo } from 'react'
import { createStructuredSelector } from 'reselect'
import { useDispatch, useSelector } from 'react-redux'
import { get } from 'lodash'
import { useForceSaveAuth } from 'modules/Auth'
import { getDocumentType } from 'modules/CustomerDocument/helpers/index'
import { selectCurrentOrder } from 'services/customer-360/selectors'
import { selectors as customerSelectors } from 'services/customer-360'
import { extractOrderData } from '../../selectors/selectors'
import * as saga from '../../sagas/orderDashboardLoad'
import { OrderDashboard } from '../../components/OrderDashboard'
import { generateAditionalOperationsLink } from './helpers'

const orderDashboardContainerSelector = createStructuredSelector({
  order: selectCurrentOrder,
  orderData: extractOrderData,
  documentId: customerSelectors.getIdentificationId,
  isLoading: saga.selectors.isLoading,
  hasErrors: saga.selectors.hasErrors,
})

export const OrderDashboardContainer = memo(() => {
  const dispatch = useDispatch()

  const { order, orderData, documentId, isLoading, hasErrors } = useSelector(
    orderDashboardContainerSelector,
  )

  const { saveLatestAccessToken } = useForceSaveAuth()

  const orderId = get(order, 'id')

  const connectionData = {
    documentId,
    documentType: getDocumentType(documentId),
  }
  const props = {
    isLoading,
    hasErrors,
    order,
    operationLink: generateAditionalOperationsLink(orderId || 'orderId', connectionData) || '',
    orderData,
    saveLatestAccessToken,
  }

  useEffect(() => {
    if (orderId) {
      dispatch(saga.actions.loadInit(orderId))
    }
  }, [orderId])

  return <OrderDashboard {...props} />
})
