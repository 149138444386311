import styled from 'styled-components'
import PropTypes from 'prop-types'

import { Typography } from '@material-ui/core'
import { TextStyles } from 'utils/text'

import { Grid, Modal } from 'components/ui'
import { isEmpty } from 'lodash'

export const FATResultModal = styled(({ className, errors, isOpen, onClose }) => {
  const icon = isEmpty(errors) ? '/assets/clap_clap.svg' : '/assets/error.svg'
  const FATchangesResult = isEmpty(errors)
    ? 'Descuento en factura aplicado correctamente'
    : 'Ha ocurrido un error al aplicar el descuento en factura'

  return (
    <Modal
      className={className}
      type={!isEmpty(errors) ? 'alert' : 'success'}
      data-hook="fat-modal-result"
      isOpen={isOpen}
      onClose={onClose}>
      <Grid container justify="space-between" alignItems="center">
        <Grid className="content-grid" item xs={12}>
          <img src={icon} alt={FATchangesResult} />
        </Grid>
        <Grid className="content-grid" item xs={12}>
          <Grid className="content-grid" item xs={12}>
            <Typography
              {...TextStyles.title1Dark({
                component: 'p',
              })}>
              {FATchangesResult}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  )
})`
  .content-grid {
    text-align: center;
    padding: 1em 0;
  }
`

FATResultModal.propTypes = {
  className: PropTypes.string,
  errors: PropTypes.object,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
}
