import { createStructuredSelector } from 'reselect'

import { selectGdprClientConsentsFlag } from 'services/feature-flag/selectors'
import * as ordersSvc from 'modules/orders'
import * as saga from '../../sagas/orderMainTabLoad'

import {
  extractOwnerData,
  extractOrderData,
  extractContactOrderData,
} from '../../selectors/selectors'

export const orderMainContainerSelectors = createStructuredSelector({
  isSvcLoading: saga.selectors.isLoading,
  hasErrors: saga.selectors.hasErrors,
  ownerData: extractOwnerData,
  orderData: extractOrderData,
  orderDataContact: extractContactOrderData,
  msisdn: ordersSvc.getProductNumber,
  gdprConsentsAllowed: selectGdprClientConsentsFlag,
})
