import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'

export const BadgeDiv = styled.div`
  display: flex;
  justify-content: center;
`

export const Span = styled.span`
  width: 12px;
  height: 12px;
  background: red;
  border-radius: 50%;
  margin-top: 3px;
  background: ${props => props.theme.main};
`
export const StyledGrid = styled(Grid)`
  position: relative;
`
