import { get, isEmpty } from 'lodash'
import { createSelector } from 'reselect'

const getAlarmsCadenceData = state => get(state, 'cadence.alarms.data')
const getAlarmsCadenceLoading = state => get(state, 'cadence.alarms.loading')
const getAlarmsCadenceError = state => get(state, 'cadence.alarms.error')

export const selectIsAlarmsCadenceSuccess = createSelector(
  getAlarmsCadenceData,
  getAlarmsCadenceLoading,
  getAlarmsCadenceError,
  (data, isLoading, error) => {
    return !isEmpty(data) && !isLoading && isEmpty(error)
  },
)

export const selectIsAlarmsCadenceLoading = createSelector(
  getAlarmsCadenceLoading,
  isLoading => !!isLoading,
)

export const selectIsAlarmsCadenceError = createSelector(
  getAlarmsCadenceError,
  error => !isEmpty(error),
)

export const selectAlarmsCadenceErrorCode = createSelector(
  getAlarmsCadenceError,
  error => error?.code,
)

export const selectAlarmsCadenceErrorMsg = createSelector(
  getAlarmsCadenceError,
  error => error?.detailMsg,
)
