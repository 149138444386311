import { get, chain } from 'lodash'
import { createSelector } from 'reselect'
import { constants } from './constants'
import { getOrderOnFlyChAddr, getOrderOnFlyChTech } from './helpers'

const orderOnFlyData = state => get(state, 'orderOnFly.data')

const orderOnFlyLoading = state => get(state, 'orderOnFly.loading')

const orderOnFlyError = state => {
  const error_code = get(state, 'orderOnFly.error_code', null)
  const error_message = get(state, 'orderOnFly.error_message', null)
  if (error_code && error_message) {
    return true
  }
  return false
}

const getErrorCode = state => get(state, 'orderOnFly.error_code')
const getErrorMsg = state => get(state, 'orderOnFly.error_message')

const selectOrderOnFlyChTech = createSelector(
  [orderOnFlyData],
  data => getOrderOnFlyChTech(data),
)

const selectOrderOnFlyChAddr = createSelector(
  [orderOnFlyData],
  data => getOrderOnFlyChAddr(data),
)

const selectOrderOnFlyChTechNeba = createSelector(
  [orderOnFlyData],
  data =>
    chain(data)
      .find({ workorderTypeId: constants.WORK_ORDER_TYPE_ID_CT_NEBA })
      .value(),
)

export const selectors = {
  orderOnFlyData,
  orderOnFlyLoading,
  orderOnFlyError,
  getErrorCode,
  getErrorMsg,
  selectOrderOnFlyChTech,
  selectOrderOnFlyChAddr,
  selectOrderOnFlyChTechNeba,
}
