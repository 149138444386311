import { useState } from 'react'
import PropTypes from 'prop-types'

import { useFormikContext } from 'formik'

import { FormControlLabel, Box, Typography, Checkbox } from '@material-ui/core'

import { formatFee } from 'utils'

import {
  FINAL_PAYMENT_DECISION_FINANCING,
  FINAL_PAYMENT_DECISION_SINGLE_PAYMENT,
} from '../../constants'

export const FinalPaymentFinancingDecision = ({ financingCostPerMonth, fieldNames, classes }) => {
  const { setFieldValue, values } = useFormikContext()

  const radioFieldName = fieldNames.FINAL_PAYMENT_TYPE

  const defaultFinalPaymentType = FINAL_PAYMENT_DECISION_SINGLE_PAYMENT

  const isFinalPaymentCheckboxChecked =
    values.financedDeviceDecisions?.finalPaymentType === FINAL_PAYMENT_DECISION_FINANCING
  const [finalPaymentType, setFinalPaymentType] = useState(
    values.financedDeviceDecisions?.finalPaymentType || defaultFinalPaymentType,
  )

  const onCheckFinalPayment = () => {
    const newFinalPaymentValue =
      finalPaymentType === FINAL_PAYMENT_DECISION_FINANCING
        ? FINAL_PAYMENT_DECISION_SINGLE_PAYMENT
        : FINAL_PAYMENT_DECISION_FINANCING
    setFinalPaymentType(newFinalPaymentValue)
    setFieldValue(radioFieldName, newFinalPaymentValue)
  }

  return (
    <>
      <Box>
        <Typography component="span" variant="body1" className={classes.radioQuestion}>
          ¿Desea financiar el pago de la cuota final?
        </Typography>
        <FormControlLabel
          key="final-payment-checkbox"
          control={
            <Checkbox
              key="final-payment-checkbox"
              name={radioFieldName}
              color="primary"
              className="checkbox"
              checked={isFinalPaymentCheckboxChecked}
              onChange={() => onCheckFinalPayment()}
            />
          }
          label={`Financiarla a 12 meses (${formatFee(financingCostPerMonth)}/mes )`}
        />
      </Box>
    </>
  )
}

FinalPaymentFinancingDecision.propTypes = {
  financingCostPerMonth: PropTypes.number,
  fieldNames: PropTypes.object,
  classes: PropTypes.object,
}
