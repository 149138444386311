import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Card, Box, Divider } from '@material-ui/core'
import { AddressChooser } from 'modules/Address'
import { setAddressAction, setTerminalShipping } from 'modules/NewClientSales/store/actions/index'
import { useDispatch, useSelector } from 'react-redux'
import { resetDeliveryPoint } from 'modules/NewClientSales/store'
import { selectOtherAddressClientOnlyMobile } from 'services/feature-flag/selectors'
import { selectShippingData } from '../../store/selectors'

function Shipping({ onSelectAddress, onEditAddress }) {
  const otherAddressFlag = useSelector(selectOtherAddressClientOnlyMobile)
  const {
    address,
    addressType,
    terminalAddressType,
    availableAddressTypes,
    availableTerminalAddressTypes,
    isSubscription,
    isMobileOnly,
    isElFijo,
    isCartera,
    hasTerminals,
  } = useSelector(selectShippingData)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(resetDeliveryPoint())
  }, [])

  const showOtherAddress = (!isCartera && (isMobileOnly || isElFijo)) || otherAddressFlag

  function onSetAddress(result, type) {
    if (type === 'shipping') {
      dispatch(setAddressAction({ ...result, addressType: 'shipping' }))
    } else {
      dispatch(setTerminalShipping({ otherAddress: result }))
    }
  }

  return (
    <Card>
      <div className="p-24">
        <AddressChooser
          preselectedAddress={address.line}
          title="DIRECCIÓN DE ENTREGA"
          showOtherAddress={showOtherAddress}
          availableAddressTypes={availableAddressTypes}
          selectedAddressType={addressType}
          onSelectAddress={onSelectAddress}
          onSetAddress={onSetAddress}
          onEditAddress={onEditAddress}
          type="shipping"
        />
        {hasTerminals && !isMobileOnly && !isElFijo && !isSubscription && (
          <>
            <Box my="28px">
              <Divider />
            </Box>

            <AddressChooser
              title="DIRECCIÓN DE ENTREGA DISPOSITIVO"
              preselectedAddress={address.terminal}
              availableAddressTypes={availableTerminalAddressTypes}
              selectedAddressType={terminalAddressType}
              onSelectAddress={onSelectAddress}
              onSetAddress={onSetAddress}
              onEditAddress={onEditAddress}
              type="terminalShipping"
            />
          </>
        )}
      </div>
    </Card>
  )
}

Shipping.propTypes = {
  onSelectAddress: PropTypes.func.isRequired,
  onEditAddress: PropTypes.func.isRequired,
}

export default Shipping
