import { put, takeLatest } from 'redux-saga/effects'
import axios from 'axios'
import { axiosFactory } from 'modules/axios'

import * as constants from '../constants'

export function* initialiseApp() {
  // TODO: remove when all consumers get axios from factory
  axiosFactory.initAxios(axios)

  yield put({ type: 'INITIALIZED_APPLICATION' })
}

export function* watchInitialiseApp() {
  yield takeLatest(constants.INITIALISE_APP, initialiseApp)
}
