import { createStructuredSelector } from 'reselect'

import {
  selectAccountId,
  selectCustomerInfoIsLoading,
  selectCustomerInfoError,
  selectModifyContactMediaIsLoading,
  selectModifyContactMediaError,
} from 'modules/CustomerInfo/store/customerInfo.selectors'

export const modifyContactEmailModalSelectors = createStructuredSelector({
  accountId: selectAccountId,
  customerInfoIsLoading: selectCustomerInfoIsLoading,
  customerInfoError: selectCustomerInfoError,
  isSaving: selectModifyContactMediaIsLoading,
  hasError: selectModifyContactMediaError,
})
