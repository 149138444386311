import { all, call, put, takeLatest, select } from 'redux-saga/effects'
import { logError, formatErrorMessage } from 'services/logging'
import { axiosFactory } from 'modules/axios'
import { selectAccountId } from 'modules/Auth'

import { findOrderProductsByIdsSuccess, findOrderProductsByIdsFailed } from './actions'
import { FIND_ORDER_PRODUCTS_BY_IDS } from './constants'
import { findOrderProductsById } from './api'

export function* findOrderProductsByIdsSaga({ payload: { productOrderIds } }) {
  try {
    const accountId = yield select(selectAccountId)
    const results = yield all(
      productOrderIds.map(orderProductId => call(findOrderProductsById, accountId, orderProductId)),
    )
    // Map backend responses to input productOrderIds and store the complete structure
    // in the store to avoid triggering reducers on each response
    const orderProducts = productOrderIds.reduce((acc, current, index) => {
      acc[current] = results[index]
      return acc
    }, {})
    yield put(findOrderProductsByIdsSuccess(orderProducts))
  } catch (e) {
    yield call(logError, { e: new Error(formatErrorMessage(e)), productOrderIds })
    const error = axiosFactory.buildError(e)
    yield put(findOrderProductsByIdsFailed(error))
  }
}

export function* watchFindOrderProductsByIds() {
  yield takeLatest(FIND_ORDER_PRODUCTS_BY_IDS, findOrderProductsByIdsSaga)
}
