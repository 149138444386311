import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { some, isEmpty, xor } from 'lodash'

import { makeStyles } from '@material-ui/styles'
import { Checkbox, FormControl, FormControlLabel } from '@material-ui/core'

import { ActionAdvice, Button, ModalActions } from 'components/ui'

const useStyles = makeStyles({
  discountAdvice: {
    'margin-top': '16px',

    '&:first-child': {
      'margin-top': 0,
    },
  },

  discountSelector: {
    'margin-top': '16px',
  },
})

const FIRST_STEP = 0
const SECOND_STEP = 1

export const MAX_DURATION_IN_MONTHS_TO_DISPLAY = 48

const toggleDiscount = (discounts, id) => xor(discounts, [id])

const preselectDiscountId = (
  id,
  discounts,
  selectedDiscounts,
  setSelectedDiscounts,
  step,
  setStep,
) => {
  const discountExists = !!id && some(discounts, ['discountId', id])

  if (!discountExists || step !== FIRST_STEP) {
    return
  }

  setSelectedDiscounts(toggleDiscount(selectedDiscounts, id))
  setStep(SECOND_STEP)
}

const onCheckboxChange = (id, selectedDiscounts, setSelectedDiscounts) => {
  setSelectedDiscounts(toggleDiscount(selectedDiscounts, id))
}

const generateDiscountCheckbox = (id, selectedDiscounts, setSelectedDiscounts) => (
  <Checkbox
    color="primary"
    className="checkbox"
    checked={selectedDiscounts.includes(id)}
    onChange={() => onCheckboxChange(id, selectedDiscounts, setSelectedDiscounts)}
    value={id}
  />
)

const filterSelectedDiscounts = (discounts, selected) =>
  discounts.filter(discount => selected.includes(discount.discountId))

const getDurationMessage = (duration, lengthRange) => {
  if (duration === 0) {
    return 'Último mes de facturación'
  }

  if (duration > MAX_DURATION_IN_MONTHS_TO_DISPLAY) {
    return 'Para siempre'
  }

  return lengthRange
}

const generateSelectDiscountsForm = (
  discounts,
  onClose,
  selected,
  setSelected,
  setStep,
  classes,
) => (
  <>
    <p>Elije los descuentos que quieres desactivar:</p>

    <FormControl className={classes.discountSelector} component="fieldset">
      {discounts.length > 0 ? (
        discounts.map(({ discountId, name, duration, lengthRange }) => (
          <FormControlLabel
            key={discountId}
            control={generateDiscountCheckbox(discountId, selected, setSelected)}
            label={`${name} - ${getDurationMessage(duration, lengthRange)}`}
          />
        ))
      ) : (
        <ActionAdvice
          className={classes.discountSelector}
          items={[{ label: '', value: 'No hay descuentos disponibles que desactivar' }]}
          type="warning"
        />
      )}
    </FormControl>

    <ModalActions>
      <Button
        data-hook="action-disable-submit"
        disabled={isEmpty(selected)}
        onClick={() => setStep(SECOND_STEP)}>
        Desactivar
      </Button>

      <Button secondary data-hook="action-disable-cancel" onClick={onClose}>
        Cancelar
      </Button>
    </ModalActions>
  </>
)

const generateConfirmationStep = (
  discounts,
  onConfirm,
  selected,
  setSelected,
  setStep,
  classes,
) => (
  <>
    <p>¿Estás seguro de querer desactivar estos descuentos?</p>
    {!!discounts &&
      discounts
        .filter(discount => selected.includes(discount.discountId))
        .map(({ discountId, name, duration, lengthRange }) => {
          const items = [
            { label: '', value: name },
            { label: 'Duración', value: getDurationMessage(duration, lengthRange) },
          ]

          return (
            <ActionAdvice
              key={discountId}
              className={classes.discountAdvice}
              items={items}
              type="info"
              block
            />
          )
        })}

    <ModalActions>
      <Button
        data-hook="action-confirmation-submit"
        disabled={isEmpty(selected)}
        onClick={() => onConfirm(filterSelectedDiscounts(discounts, selected))}>
        Sí, desactivar
      </Button>

      <Button
        secondary
        data-hook="action-confirmation-cancel"
        onClick={() => {
          setSelected([])
          setStep(FIRST_STEP)
        }}>
        No, cancelar
      </Button>
    </ModalActions>
  </>
)

const DiscountsDisable = props => {
  const { discounts, onConfirm, onClose, discountId } = props

  const classes = useStyles()

  const [selected, setSelected] = useState([])
  const [step, setStep] = useState(FIRST_STEP)

  useEffect(() => {
    preselectDiscountId(discountId, discounts, selected, setSelected, step, setStep)
  }, [])

  return step === FIRST_STEP
    ? generateSelectDiscountsForm(discounts, onClose, selected, setSelected, setStep, classes)
    : generateConfirmationStep(discounts, onConfirm, selected, setSelected, setStep, classes)
}

DiscountsDisable.propTypes = {
  discounts: PropTypes.array.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  discountId: PropTypes.number,
}

DiscountsDisable.defaultProp = {
  discounts: [],
}

export default DiscountsDisable
