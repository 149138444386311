
export const usePagination = (subsAndOrdersFiltered, currentPage, setPage, setCurrentPage) => {
  const showPagination = subsAndOrdersFiltered.length > 19

    const itemsPerPage = 5

    const begin = (currentPage - 1) * itemsPerPage
    const end = begin + itemsPerPage
    const maxPage = Math.ceil(subsAndOrdersFiltered.length / itemsPerPage)

    const handlePage = (e, p) => {
        setPage(p)
        const pageNumber = Math.max(1, p)
        setCurrentPage(Math.min(pageNumber, maxPage))
      }

    return {showPagination, begin, end, maxPage, handlePage}
}