import {
  GET_LEGAL_TEXTS,
  GET_LEGAL_TEXTS_SUCCESS,
  GET_LEGAL_TEXTS_ERROR,
} from './legalTexts.constants'

export const initialState = {
  sales: {
    legalTexts: {},
    isLoading: true,
  },
  sales_financed_device: {
    legalTexts: {},
    isLoading: true,
  },
  sales_next_steps: {
    legalTexts: {},
    isLoading: true,
  },
  cancellation: {
    legalTexts: {},
    isLoading: true,
  },
}

export const legalTexts = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case GET_LEGAL_TEXTS: {
      return {
        ...state,
        [payload]: {
          ...state[payload],
          isLoading: true,
        },
      }
    }
    case GET_LEGAL_TEXTS_SUCCESS: {
      return {
        ...state,
        [payload.origin]: {
          legalTexts: { ...payload.legalTexts },
          isLoading: false,
        },
      }
    }
    case GET_LEGAL_TEXTS_ERROR: {
      return {
        ...state,
        [payload.origin]: {
          ...state[payload.origin],
          error: {
            code: payload.httpCode,
            message: payload.info,
          },
          isLoading: false,
        },
      }
    }
    default:
      return state
  }
}
