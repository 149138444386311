import { call, put, takeLatest } from 'redux-saga/effects'
import { logError, formatErrorMessage } from 'services/logging'
import { axiosFactory } from 'modules/axios'
import * as actions from './customer-write.actions'
import { customerWrite } from '../services/customer-write.service'

export function* customerWriteSaga({ payload: { customerId, changesData } }) {
  try {
    const result = yield call(customerWrite, customerId, changesData)
    yield put(actions.customerWriteSuccededAction(result))
  } catch (e) {
    yield call(logError, { e: new Error(formatErrorMessage(e)), customerId, changesData })
    const error = axiosFactory.buildError(e)
    yield put(actions.customerWriteFailedAction(error))
  }
}

export function* watchCustomerWrite() {
  yield takeLatest(actions.CUSTOMER_WRITE_ATTEMPTED, customerWriteSaga)
}
