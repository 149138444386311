import { watchActivityRegister } from 'modules/activity-register'
import { watchAlarmsSaga } from 'modules/alarms/store/index'
import {
  watchAnalyticsInit,
  watchSendTransactionComplete,
  watchSendTransactionError,
} from 'modules/Analytics/'
import { watchPageLoaded } from 'modules/Analytics/redux/index'
import {
  watchGetAppointmentSaleToken,
  watchGetKairosAppointmentToken,
  watchGetAppointmentToken,
} from 'modules/Appointments'
import {
  watchAuthLocationChange,
  watchAuthLogout,
  watchCustomereTokenLocationChange,
  watchCustomerTokenInit,
  watchRequestIpAddressSaga,
} from 'modules/Auth'
import { watchBarringsStatus } from 'modules/barrings'
import { watchBillingsFind } from 'modules/billings'
import { watchFindBonuses } from 'modules/bonuses'
import { watchCancelOrderBus } from 'modules/cancel-order-bus'
import { watchFetchContentfulResourceSaga } from 'modules/contentful'
import { watchFindCoverage, watchGetCoverage, watchGetCoverageToken } from 'modules/Coverage'
import { watchCustomerWrite } from 'modules/customer-write'
import {
  watchFetchCustomerActivitySaga,
  watchFetchCustomerSubcriptionsSaga,
} from 'modules/CustomerActivity'
import {
  watchFetchCustomerDuoLimitSaga,
  watchFetchCustomerOrderLimitSaga,
  watchFindAllOrders,
} from 'modules/CustomerOrders'
import {
  watchGetSubscriptionSaga,
  watchInitMoveClientSaga,
  watchInitMoveClientSummarySaga,
  watchOnFlyStatusLoad,
} from 'modules/existing-clients'
import {
  watchRetrieveSubscription,
  watchDeviceRenewalAllowed,
  watchFetchDevices,
  watchFetchDeviceSpecifications,
  watchFetchFinancingConditions,
  watchGenerateOrder,
  watchPolling as watchRenewalPolling,
} from 'modules/device-renewal/store'
import { watchFetchAddAgileTvAllowed, watchAddAgileTv } from 'modules/agile-tv/add-agiletv/store'
import { watchChangeAgileTv } from 'modules/agile-tv/change-agiletv/store'
import { watchFATCompensation } from 'modules/FAT/index'
import { watchValidateIBAN } from 'modules/Iban'
import { watchFetchInstallationDirection } from 'modules/Installations'
import { watchFetchInstallationDirectionCare } from 'modules/InstallationsCare'
import { watchGetLegalTexts } from 'modules/LegalTexts/store/index'
import { watchfetchPortabilityDatesSaga } from 'modules/Lines'
import {
  watchFetchIsCustomerDocSaga,
  watchCustomerIsAllowedSaga,
  watchFetchCustomerInformationSaga,
  watchModifyCustomerContactMediaSaga,
} from 'modules/CustomerInfo'
import {
  watchCreateNewOrder,
  watchExitApp,
  watchFetchCreditScoringSaga,
  watchFetchInstallationDirectionNewClientSales,
  watchFetchNewIbanValueCarteraSaga,
  watchFetchPrescoringSaga,
  watchNotifyD2DCancel,
  watchNotifyD2DOrderFinished,
  watchOrderFailed,
  watchOrderFailedD2D,
  watchOrderSucceded,
  watchSetNewBasketAccountValuesCarteraSaga,
  watchSetNewPostalAddressCarteraSaga,
  watchStartPollingOrder,
  watchSavePresaleDataSaga,
  watchFetchPresaleDataSaga,
} from 'modules/NewClientSales'
import { watchNotifyHome, watchNotifyUserLogout } from 'modules/NewClientSales/store/sagas/index'
import { watchFetchTerminalDetails } from 'modules/NewClientSales/store/sagas/terminals.saga'
import {
  saga as watchOffersConfiguratorSagas,
  watchOffersConfigurationInit,
} from 'modules/offers-configuration'
import {
  watchAuthTypficationSagas,
  watchDataTypficationSagas,
  watchInsertionTypificationsSagas,
  watchRegisterTypigicationsSagas,
  watchSecurizerTypigicationsSagas
} from 'modules/typifications'
import {
  watchCancelOrder,
  watchCancelOrders,
  watchFetchPenaltyOrder,
  watchFetchOrderOnly,
  watchFetchOrder,
  watchUpdateOrder,
  watchFetchOrdersCancellationInfo,
  watchFetchAdditionalLines,
} from 'modules/orders'
import { watchGetPonc } from 'modules/ponc'
import { watchGetPond } from 'modules/pond'
import { watchFindIsOrderPonr } from 'modules/ponr'
import { watchPrepaidLine } from 'modules/prepaid-line'
import {
  watchFetchSVAsAgileSaga,
  watchFetchSVAsProSaga,
  watchFetchRenewalSVAsAgileSaga,
} from 'modules/SVAs/store'
import { watchTariffsModuleSagas, watchFetchMemberGetMemberSaga } from 'modules/tariffs/store'
import {
  watchFindConvergentTariffs,
  watchFindConvergentTariffsIds,
  watchFindTariffById,
  watchFindTariffs,
} from 'modules/tariffs/store-apigee'
import { watchFetchMainLineTariffsSaga } from 'modules/tariffs/store/sagas/index'
import {
  watchClientDashboardLoad,
  watchDashboardLoad,
  watchOrderDashboardLoad,
  watchOrderDetailLoad,
  watchOrderMainTabLoad,
  watchProductDashboardLoad,
} from 'modules/vista-360'
import { all } from 'redux-saga/effects'
import { watchAtcAuthTokenSaga } from 'services/atc'
import { watchCreateChangeTechnology } from 'services/change-technology'
import { watchCheckOrderCTOnFly } from 'services/check-order-ct-on-fly'
import { saga as customer360SvcSaga } from 'services/customer-360/saga'
import { saga as customerNewSvcSaga } from 'services/customer-new/saga'
import { watchCustomerProducts } from 'services/customer-products'
import { watchFindAllSubscriptions } from 'services/customer-subscriptions'
import { watchFetchCustomerSaga } from 'services/customer-v3'
import { watchFetchCustomerContactMediaSaga } from 'services/customer-v3-contact-medias'
import { watchGetDiscountsAgent } from 'services/discounts-agent'
import { watchGetDiscountsAgentCategories } from 'services/discounts-agent-categories'
import { watchGetDiscountsIncompatibilities } from 'services/discounts-incompatibilities'
import { watchGetDiscounts, watchPutDiscounts } from 'services/discounts-order'
import { watchPutCadenceAlarmsSales } from 'services/cadence-flow'

import { watchFindEnrichment } from 'services/enrichment'
import { watchFlags } from 'services/feature-flag/saga'
import { saga as logisticOrdersSvcSaga } from 'services/logistic-orders/saga'
import { watchFindOffers } from 'services/offers'
import { saga as offersDetailSvcSaga } from 'services/offers-detail/saga'

import { watchPutOrderDiscounts } from 'services/order-discounts'

import { watchOrderOnFly } from 'services/order-on-fly'
import { watchFindOrderProductsByIds } from 'services/order-products'
import { watchFindOrderStatusDesc } from 'services/order-status-desc'
// TODO: main-app should expose a function to register sagas in SubApps's
import { watchFindProductOrders } from 'services/product-orders'
import { watchProvisioningSagas } from 'services/provisioning'
import { watchGetRove } from 'services/rove'
import { watchFindSubscriptions } from 'services/subscriptions'
import { watchFindTax } from 'services/taxes'
import { watchTerritCompatSaga } from 'services/territory-compatibility'
import { watchTicketingSagas } from 'services/ticketing'
import { watchFindTranslation } from 'services/translation'
import { watchCreateTypification } from 'services/typifications'
import { watchCheckWizard } from 'services/wizard'
import { watchCreditNote } from 'modules/credit/index'
import {
  watchCrossSellAllowedSaga,
  watchCrossSellCompatibleDiscountsSaga,
  watchCrossSellTariffPromotionsSaga,
} from 'services/cross-sell/saga'
import { watchMultiSimAllowedSaga } from 'services/multisim/saga'
import { watchFetchEnergyContract } from 'modules/energy/store/energy.saga'
import {
  watchFindCustomerSvaSaga,
  watchPutCustomerSvaSaga,
  watchPutHebeSvaCustomerSaga,
  watchFetchCustomerSvasSaga,
} from 'services/customer-sva'
import { watchSmsCommunicationSaga } from 'services/sms-communication/saga'
import {
  watchPutHomeGoSales,
  watchGetHomeGoCustomerSvasSaga,
  watchCancelHomeGoSvaOrderSaga,
} from 'services/customer-sva/saga'
import { watchInitialiseApp } from './initialise.saga'

export function* rootSaga() {
  yield all([
    watchInitialiseApp(),
    watchAuthLocationChange(),
    watchAuthLogout(),
    watchRequestIpAddressSaga(),
    watchCustomereTokenLocationChange(),
    watchCustomerTokenInit(),
    watchGetSubscriptionSaga(),
    watchOnFlyStatusLoad(),
    watchFindProductOrders(),
    watchFetchOrder(),
    watchFetchOrderOnly(),
    watchFindIsOrderPonr(),
    watchCancelOrder(),
    watchCancelOrders(),
    watchCreateNewOrder(),
    watchFindOrderProductsByIds(),
    watchFetchPenaltyOrder(),
    watchFetchAdditionalLines(),
    watchFetchCustomerActivitySaga(),
    watchFetchCustomerSubcriptionsSaga(),
    watchUpdateOrder(),
    watchFetchOrdersCancellationInfo(),
    watchFindCoverage(),
    watchfetchPortabilityDatesSaga(),
    watchFindEnrichment(),
    watchFindTariffs(),
    watchFindTariffById(),
    watchFindConvergentTariffs(),
    watchFindConvergentTariffsIds(),
    watchFindTranslation(),
    watchCreateChangeTechnology(),
    watchGetCoverage(),
    watchGetCoverageToken(),
    watchProvisioningSagas(),
    watchFindOffers(),
    watchGetRove(),
    watchGetAppointmentToken(),
    watchInitMoveClientSaga(),
    watchCheckWizard(),
    watchGetPonc(),
    watchGetPond(),
    watchGetDiscounts(),
    watchPutDiscounts(),
    watchGetDiscountsIncompatibilities(),
    watchPutCadenceAlarmsSales(),
    watchPutHomeGoSales(),
    watchGetHomeGoCustomerSvasSaga(),
    watchCancelHomeGoSvaOrderSaga(),
    watchCustomerProducts(),
    watchInitMoveClientSummarySaga(),
    watchPutOrderDiscounts(),
    watchOrderOnFly(),
    watchGetDiscountsAgent(),
    watchFindSubscriptions(),
    watchFindBonuses(),
    watchOffersConfiguratorSagas(),
    watchFlags(),
    watchFetchContentfulResourceSaga(),
    watchDashboardLoad(),
    watchClientDashboardLoad(),
    watchProductDashboardLoad(),
    watchOrderDashboardLoad(),
    watchOrderDetailLoad(),
    watchOrderMainTabLoad(),
    watchFindTax(),
    watchValidateIBAN(),
    watchTerritCompatSaga(),
    // customer sagas
    watchFetchCustomerSaga(),
    watchFetchCustomerContactMediaSaga(),
    offersDetailSvcSaga.watchOffersDetailSaga(),
    offersDetailSvcSaga.watchOffersDetailByClientSaga(),
    customerNewSvcSaga.watchFind(),
    customer360SvcSaga.watchFind(),
    customer360SvcSaga.watchFetchVipTypeSaga(),
    watchBillingsFind(),
    logisticOrdersSvcSaga.watchFetch(),
    watchCheckOrderCTOnFly(),
    watchFindAllOrders(),
    watchFetchCustomerOrderLimitSaga(),
    watchFetchCustomerDuoLimitSaga(),
    watchFindOrderStatusDesc(),
    watchFindAllSubscriptions(),
    watchModifyCustomerContactMediaSaga(),
    // TODO name next saga block
    watchTicketingSagas(),
    watchGetAppointmentSaleToken(),
    watchGetKairosAppointmentToken(),
    watchCreateTypification(),
    watchActivityRegister(),
    watchGetDiscountsAgentCategories(),
    watchCancelOrderBus(),
    watchOffersConfigurationInit(),
    watchAuthTypficationSagas(),
    watchDataTypficationSagas(),
    watchInsertionTypificationsSagas(),
    watchRegisterTypigicationsSagas(),
    watchSecurizerTypigicationsSagas(),
    // Ordersagas
    watchStartPollingOrder(),
    // SVA Sagas
    watchFetchSVAsAgileSaga(),
    watchFetchSVAsProSaga(),
    watchFetchRenewalSVAsAgileSaga(),
    // D2D Sagas
    watchNotifyD2DOrderFinished(),
    watchNotifyD2DCancel(),
    watchOrderFailedD2D(),
    watchNotifyHome(),
    watchNotifyUserLogout(),
    // New Clients Sales Sagas
    watchOrderFailed(),
    watchOrderSucceded(),
    watchExitApp(),
    watchFetchInstallationDirectionNewClientSales(),
    watchFetchCustomerInformationSaga(),
    watchFetchNewIbanValueCarteraSaga(),
    watchSetNewBasketAccountValuesCarteraSaga(),
    watchSetNewPostalAddressCarteraSaga(),
    watchFetchMemberGetMemberSaga(),
    // Customer Info
    watchFetchIsCustomerDocSaga(),
    watchCustomerIsAllowedSaga(),
    // Scoring Sagas (NewClientSales)
    watchFetchCreditScoringSaga(),
    watchFetchPrescoringSaga(),
    // Installations saga
    watchFetchInstallationDirection(),
    watchFetchInstallationDirectionCare(),
    // Analytics sagas
    watchSendTransactionComplete(),
    watchSendTransactionError(),
    watchAnalyticsInit(),
    watchPageLoaded(),
    // LegalTexts Texts
    watchGetLegalTexts(),
    // Tariffs Module
    watchTariffsModuleSagas(),
    // Customer Write
    watchCustomerWrite(),
    // FAT
    watchFATCompensation(),
    // Prepaid Line
    watchPrepaidLine(),
    // Terminals
    watchFetchTerminalDetails(),
    // Barrings Status
    watchBarringsStatus(),
    // Credit Note
    watchCreditNote(),
    watchFetchMainLineTariffsSaga(),
    watchAlarmsSaga(),
    // ATC
    watchAtcAuthTokenSaga(),
    // Cross sell
    watchCrossSellAllowedSaga(),
    watchCrossSellCompatibleDiscountsSaga(),
    watchCrossSellTariffPromotionsSaga(),
    // MultiSim
    watchMultiSimAllowedSaga(),
    // Device renewal
    watchRetrieveSubscription(),
    watchDeviceRenewalAllowed(),
    watchFetchDevices(),
    watchFetchDeviceSpecifications(),
    watchFetchFinancingConditions(),
    watchGenerateOrder(),
    watchRenewalPolling(),
    // Add agile tv
    watchFetchAddAgileTvAllowed(),
    watchAddAgileTv(),
    // Change Agile tv
    watchChangeAgileTv(),
    // Energy
    watchFetchEnergyContract(),
    // Customer SVA
    watchFindCustomerSvaSaga(),
    watchPutCustomerSvaSaga(),
    watchPutHebeSvaCustomerSaga(),
    watchFetchCustomerSvasSaga(),
    // PRESALE
    watchSavePresaleDataSaga(),
    watchFetchPresaleDataSaga(),
    // Sms communication
    watchSmsCommunicationSaga(),
  ])
}
