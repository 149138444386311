import { APP_CONFIG } from 'services/app-config'
import { axiosJWT } from 'modules/axios'

const iccURL = `${APP_CONFIG.sales_cluster}/v1/sims/`

export function fetchICCValidation(icc, checkWithCatalog) {
  const url = checkWithCatalog ? `${iccURL}${icc}` : `${iccURL}${icc}/validate`
  return axiosJWT({
    method: 'GET',
    url,
  }).then(res => res.data)
}
