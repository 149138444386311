import { useMemo } from 'react'
import PropTypes from 'prop-types'

import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import { TextStyles } from 'utils/text'

import { formatNumberWithComma } from 'utils/formatting/index'
import { TARIFF_TYPE_EXTRA_LINE } from 'modules/offers-configuration/constants'

const useStyles = makeStyles(theme => ({
  'tariff-cell': {
    cursor: 'pointer',

    '& .initial_price': {
      textDecoration: 'line-through',
    },

    '& .discount-duration': {
      marginLeft: '4px',
      alignSelf: 'center',
    },
  },
  'tariff-cell-title': {
    padding: '12px 4px',
    borderRadius: '14px 14px 0 0',
    backgroundColor: ({ selected }) => (selected ? theme.palette.primary.main : '#bebebe'),
  },
  'tariff-cell-body': {
    border: ({ selected }) =>
      selected ? `3px solid ${theme.palette.primary.main}` : `1px solid #bababa`,
    borderRadius: '0 0 14px 14px',
  },
}))
export function TariffCell({ tariff, selected, onSelectTariff }) {
  const classes = useStyles({ selected })

  const showMobileData = useMemo(() => !!(tariff.mobileMin && tariff.data), [tariff])
  const finalFee = tariff.fees.find(fee => fee.duration === 0)

  const textShareDataWithMainLine = 'Comparte datos con la principal'
  const textDataAmount =
    tariff.data.amount < 300 ? `${tariff.data.amount} ${tariff.data.type}` : 'Infinitos'

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="180px"
      height="100%"
      overflow="hidden"
      className={classes['tariff-cell']}
      onClick={() => onSelectTariff(tariff)}
      data-hook="tariff-cell">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        className={classes['tariff-cell-title']}>
        <Typography
          {...TextStyles.dataLight({
            component: 'h6',
            style: { lineHeight: 'normal' },
          })}>
          {tariff.name}
        </Typography>
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        px="12px"
        pt={showMobileData ? '16px' : '2px'}
        className={classes['tariff-cell-body']}>
        {showMobileData && (
          <>
            <Typography
              {...TextStyles.labelDark({
                component: 'p',
              })}>
              Móvil minutos:{' '}
              {tariff.mobileMin < 0 ? 'Infinitos' : `${tariff.mobileMin} Min` || '0 Min'}
            </Typography>

            <Typography
              {...TextStyles.labelDark({
                component: 'p',
              })}>
              Móvil datos:{' '}
              {tariff.tariffType === TARIFF_TYPE_EXTRA_LINE
                ? textShareDataWithMainLine
                : textDataAmount}{' '}
            </Typography>
          </>
        )}

        <Box
          display="flex"
          height="100%"
          flexDirection="column"
          justifyContent="flex-end"
          pb="16px">
          <Box display="flex" flexDirection="column" mt="16px" mb="4px">
            {tariff?.fees?.length > 0 &&
              tariff.fees.map(
                (fee, i) =>
                  !!fee?.duration && (
                    <Box display="flex" flexDirection="column" mb="10px" key={fee.duration}>
                      <Typography
                        {...TextStyles.title2MediumDark({
                          className: 'p-r-4 p-b-4',
                          style: { whiteSpace: 'nowrap' },
                        })}>
                        {formatNumberWithComma(fee.amount)} €/mes
                      </Typography>
                      <Typography
                        {...TextStyles.body({
                          style: {
                            whiteSpace: 'nowrap',
                            lineHeight: 'inherit',
                          },
                        })}>
                        Del mes {tariff.fees[i - 1]?.duration || 0} al {fee.duration}
                      </Typography>
                    </Box>
                  ),
              )}
          </Box>
          <Typography {...TextStyles.title2MediumDark()}>
            {formatNumberWithComma(finalFee?.amount)} €/mes
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

TariffCell.propTypes = {
  tariff: PropTypes.object.isRequired,
  selected: PropTypes.bool,
  onSelectTariff: PropTypes.func,
}
