export const alarmsActionTypes = {
  CUSTOMER_FIND_ALARMS: 'OVID/CUSTOMER360/CUSTOMER_FIND_ALARMS',
  CUSTOMER_FIND_ALARMS_SUCCESS: 'OVID/CUSTOMER360/CUSTOMER_FIND_ALARMS_SUCCESS',
  CUSTOMER_FIND_ALARMS_ERROR: 'OVID/CUSTOMER360/CUSTOMER_FIND_ALARMS_ERROR',
}

// Ask to retrieve alarms for the current customer for the given msisdns. If no msisdn is given then
// it retrieves all the alarms associated to the customer.
const findAlarmsAction = (msisdns = []) => ({
  type: alarmsActionTypes.CUSTOMER_FIND_ALARMS,
  payload: {
    msisdns,
  },
})

const findAlarmsSuccessAction = data => ({
  type: alarmsActionTypes.CUSTOMER_FIND_ALARMS_SUCCESS,
  payload: {
    data,
  },
})

const findAlarmsErrorAction = error => ({
  type: alarmsActionTypes.CUSTOMER_FIND_ALARMS_ERROR,
  payload: { error },
})

export const alarmActions = {
  findAlarmsAction,
  findAlarmsSuccessAction,
  findAlarmsErrorAction,
}
