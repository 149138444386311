import PropTypes from 'prop-types'
import styled from 'styled-components'
import { get, initial, last, first, head, remove } from 'lodash'
import { getLighten, formatMonthlyFee, formatNumber } from 'utils'
import { format, parseISO } from 'date-fns'
import es from 'date-fns/locale/es'
import { Price } from 'components/ui'

import { UNLIMITED_DURATION } from 'modules/offers-configuration/constants'
import { getPromoInterval, formatDiscountDuration } from './helpers'

import { PermanentInfoItem } from '../PermanentInfoItem'

function formatMonthYear(dateStr) {
  return dateStr ? format(parseISO(dateStr), 'MM/yyyy', { locale: es }) : '-'
}

export const FeesItem = styled(
  ({
    className,
    subscription,
    rawFeeSummary,
    terminalsFinancials,
    terminalsLastPayment,
    endDatePayment,
    isModified,
  }) => {
    const lastItem = last(rawFeeSummary)
    const firstItem = head(rawFeeSummary)
    const { totalFeeMainDuo } = lastItem

    let allButLast

    if (
      rawFeeSummary.length === 1 &&
      get(firstItem, 'savings') > 0 &&
      get(firstItem, 'duration') > 0
    ) {
      allButLast = rawFeeSummary
    } else {
      const hasTemporaryDiscount = rawFeeSummary.find(feeSummary => {
        const duration = get(feeSummary, 'duration')
        return duration > 0 && duration !== UNLIMITED_DURATION
      })
      if (hasTemporaryDiscount) {
        remove(rawFeeSummary, feeSummary => get(feeSummary, 'duration') === UNLIMITED_DURATION)
      }

      allButLast = initial(rawFeeSummary)
    }

    return (
      <div className={className}>
        {allButLast.length === 1 && (
          <Price
            title={formatDiscountDuration(first(allButLast).duration)}
            price={formatNumber(first(allButLast).monthlyFee)}
            monthly
            aux={`Se ahorra: ${formatMonthlyFee(first(allButLast).savings)}`}
          />
        )}
        {allButLast.length > 1 && (
          <Price
            title="Cuotas temporales"
            monthly
            quotas={allButLast.map((item, i) => ({
              label: formatNumber(item.monthlyFee),
              value: getPromoInterval(allButLast, i),
            }))}
            subscriptionId={subscription.id}
          />
        )}

        {/* Cuota final */}
        {lastItem && (
          <Price
            title="Cuota final"
            monthly
            price={formatNumber(totalFeeMainDuo || lastItem.monthlyFee)}
          />
        )}

        {/* last */}
        {terminalsFinancials && (
          <Price
            title="Pago final"
            price={formatNumber(terminalsLastPayment)}
            aux={`De terminal (${formatMonthYear(endDatePayment)})`}
          />
        )}

        <PermanentInfoItem subscription={subscription} isModified={Boolean(isModified)} />
      </div>
    )
  },
)`
  &&.tab-content {
    display: flex;
    justify-content: flex-start;
    background-color: transparent;

    > .price {
      padding-bottom: 8px;
    }

    > * {
      padding: 0px 24px 0px 24px;
    }

    & > div:not(:last-child) {
      border-right: 1px solid
        ${props => getLighten(get(props, 'theme.palette.global.gray_dark'), 0.8)};
    }
  }
`

FeesItem.propTypes = {
  className: PropTypes.string,
  subscription: PropTypes.object.isRequired,
  rawFeeSummary: PropTypes.arrayOf(
    PropTypes.shape({
      duration: PropTypes.number.isRequired,
      monthlyFee: PropTypes.number.isRequired,
      savings: PropTypes.number.isRequired,
    }),
  ).isRequired,
  terminalsFinancials: PropTypes.array,
  terminalsLastPayment: PropTypes.number,
  endDatePayment: PropTypes.string,
  isModified: PropTypes.bool,
}
