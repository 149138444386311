import { memo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'

import { Box } from '@material-ui/core'
import { AlertAdvice } from 'components/ui'
import { IframeCard } from '../../components/IframeCard'

const useStyles = makeStyles({
  iframeCardContainer: {
    '& .card iframe': {
      height: 'calc(100vh - 380px)',
    },
  },
})

export const OrderAtcMain = memo(({ loading, error, url, className, notShowAtc }) => {
  const styles = useStyles()

  return (
    url && (
      <div className={`${className} ${styles.iframeCardContainer}`} data-hook="order-atc-container">
        <IframeCard
          id="order-atc-card"
          title="Resumen provisión"
          url={url}
          loading={loading}
          error={error}
          empty={notShowAtc}>
          {notShowAtc && (
            <Box p="10px 24px">
              <AlertAdvice message="Consulta el estado del pedido" type="warning" block />
            </Box>
          )}
        </IframeCard>
      </div>
    )
  )
})

OrderAtcMain.defaultProps = {
  className: '',
}

OrderAtcMain.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.bool,
  url: PropTypes.string.isRequired,
  className: PropTypes.string,
  notShowAtc: PropTypes.bool,
}
