import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import { TextStyles } from 'utils/text'
import { Button, FormikInput, Modal, ModalActions, SpinnerButton } from 'components/ui'
import { useCampaigns } from 'modules/tariffs/hooks'
import { CAMPAIGN_LINE_INVALID } from 'modules/SharedSales/constants'

export function ModalCampaigns({ tariff, lineType, isOpen, closeModal, fieldNames }) {
  const [isLoading, setIsLoading] = useState(false)
  const [showResetCampaignModal, setshowResetCampaignModal] = useState(false)
  const {
    applyCampaign,
    shouldShowResetCampaignError,
    setShouldShowResetCampaignError,
    resetCampaign,
  } = useCampaigns(fieldNames, lineType)

  useEffect(() => {
    if (shouldShowResetCampaignError) setshowResetCampaignModal(true)
  }, [shouldShowResetCampaignError])

  async function setCampaign() {
    setIsLoading(true)
    const applied = await applyCampaign(tariff.psId)
    setIsLoading(false)
    if (applied) closeModal()
  }

  function closeAndReset() {
    resetCampaign()
    closeModal()
  }

  function onCloseResetCampaignModal() {
    resetCampaign()
    setshowResetCampaignModal(false)
    setShouldShowResetCampaignError(false)
  }

  return (
    <>
      <Modal isOpen={isOpen} title="Añadir campaña">
        <FormikInput
          className="m-r-32"
          label="Introduce el código de campaña"
          name={fieldNames.CAMPAIGN_ID}
          dataHook={fieldNames.CAMPAIGN_ID}
          maxLength="50"
          backgroundcolor="white"
          fullWidth
        />
        <ModalActions>
          {isLoading ? (
            <SpinnerButton disabled />
          ) : (
            <Button onClick={setCampaign}>Aplicar campaña</Button>
          )}
          <Button onClick={closeAndReset} disabled={isLoading} style={{ marginLeft: '16px' }}>
            Cancelar
          </Button>
        </ModalActions>
      </Modal>
      <Modal isOpen={showResetCampaignModal} title="Campaña eliminada">
        <ModalActions>
          <Typography {...TextStyles.labelDark()}>{CAMPAIGN_LINE_INVALID}</Typography>
          <Button style={{ marginTop: '20px' }} onClick={onCloseResetCampaignModal}>
            Aceptar
          </Button>
        </ModalActions>
      </Modal>
    </>
  )
}

ModalCampaigns.propTypes = {
  tariff: PropTypes.object.isRequired,
  lineType: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  fieldNames: PropTypes.object.isRequired,
}
