import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty, get } from 'lodash'
import { setIn } from 'formik'

import { selectFinancedDeviceLegalConsentsFlag } from 'services/feature-flag/selectors'
import { FINANCED_LEGAL, LEGAL, TPV, GENERATOR } from 'modules/NewClientSales/constants'
import { withCheckoutForms } from 'modules/NewClientSales/hocs/index'
import { resetCarteraValuesStore } from 'modules/CustomerInfo'
import {
  setBasketAccountData,
  setBasketData,
  fetchCreditScoringAction,
  resetCreditScoring,
} from 'modules/NewClientSales/store'
import { useAnalytics } from 'modules/Analytics/hooks/index'
import { flattenObj } from 'utils/formatting/index'
import { Summary } from '../../../components/Summary'
import { createNewOrder } from '../../../store/actions'
import { selectSummary } from '../../../store/selectors'

export const SummaryContainer = withCheckoutForms(
  ({ values, hasSummaryValidationErrors, setTouched, validateForm, sale }) => {
    const [isSubmitted, setIsSubmitted] = useState(false)

    const {
      terminalPaymentFinished,
      scoring,
      scoringError,
      isOrderCreated,
      hasSomeFinancedTerminal,
      salesTypes: { isPos, isD2D },
      carteraUpdateError,
      hasDeliveryAddress,
      showConfirm,
      canRetryScoring,
      isOrderProcessed,
      isLoading,
      isMultiSim,
    } = useSelector(selectSummary)

    const canShowFinancedLegalModal = useSelector(selectFinancedDeviceLegalConsentsFlag)

    const dispatch = useDispatch()

    const analytics = useAnalytics()

    const [isErrorScoringModalOpen, setIsErrorScoringModalOpen] = useState(false)
    const [isPartialScoringModalOpen, setIsPartialScoringModalOpen] = useState(false)
    const [isFinancedLegalModalOpen, setIsFinancedLegalModalOpen] = useState(false)
    const [isLegalModalOpen, setIsLegalModalOpen] = useState(false)
    const [isTpvDialogOpen, setIsTpvDialogOpen] = useState(false)

    const [resetScoring, setResetScoring] = useState(true)

    const generateOrder = () => {
      setIsSubmitted(false)
      dispatch(createNewOrder())
    }

    const GENERATE_ORDERS_STEPS = [FINANCED_LEGAL, LEGAL, TPV, GENERATOR]

    function navigateGenerateOrderStep(step) {
      const skipStep = () => {
        const stepNumber = GENERATE_ORDERS_STEPS.indexOf(step)

        navigateGenerateOrderStep(GENERATE_ORDERS_STEPS[stepNumber + 1])
      }

      switch (step) {
        case FINANCED_LEGAL:
          if (!hasSomeFinancedTerminal || !canShowFinancedLegalModal) {
            skipStep()

            return
          }

          setIsFinancedLegalModalOpen(true)
          break
        case LEGAL:
          if (isPos || isD2D) {
            skipStep()

            return
          }

          setIsLegalModalOpen(true)
          break
        case TPV:
          if (isPos || terminalPaymentFinished) {
            skipStep()

            return
          }

          setIsTpvDialogOpen(true)
          break
        case GENERATOR:
          if (isSubmitted && !isOrderCreated && !isOrderProcessed) {
            generateOrder()
          }
          break
        default:
          break
      }
    }

    const onFinancedLegalConfirm = () => {
      dispatch(setBasketData(values))

      setIsFinancedLegalModalOpen(false)
      navigateGenerateOrderStep(LEGAL)
    }

    function handleScoringResponse(scoringValue) {
      switch (scoringValue) {
        case 'A': // ACCEPTED
        case 'R': // ACCEPTED WITH PAYMENT
          navigateGenerateOrderStep(FINANCED_LEGAL)
          break
        case 'C': // ERROR
        case 'D': // DENIED
          setIsErrorScoringModalOpen(true)
          break
        case 'M': // MANUAL REVISION
        case 'P': // DOCUMENTATION NEEDED
          setIsPartialScoringModalOpen(true)
          break
        default:
          throw new Error('There is no such scoring result')
      }
    }

    function validateFormHandler(errors) {
      if (isEmpty(errors) && !hasSummaryValidationErrors) {
        dispatch(setBasketData(values))

        if (isMultiSim) {
          generateOrder()
          return
        }

        dispatch(fetchCreditScoringAction())
      } else {
        const flatErrors = flattenObj(errors)

        const axErrors = Object.keys(flatErrors).map(key => ({
          name: key,
          formName: 'summary',
          value: flatErrors[key],
        }))
        analytics.sendFormErrors({
          errors: axErrors,
        })
        setTouched(errors)
      }
    }

    function onSetTerminalPaymentFinished(result) {
      // TODO Add here logic to map each terminal to each line
      // const basket = setIn(values, 'mainMobileLineOffer.terminal.paymentResult', result)
      let basket = { ...values }
      result.forEach(terminal => {
        // buscar en values los terminales que coincidan con los ids del result y que no tengan paymentResult
        const matchesTerminalWithLine =
          get(basket, `${terminal.lineType}.terminal.id`) &&
          isEmpty(get(basket, `${terminal.lineType}.terminal.paymentResult`, true))
        if (matchesTerminalWithLine) {
          // setear cada paymentResult
          basket = setIn(
            basket,
            `${terminal.lineType}.terminal.paymentResult`,
            terminal.paymentResult,
          )
        }
      })

      setIsTpvDialogOpen(false)
      dispatch(setBasketData(basket))

      navigateGenerateOrderStep(GENERATOR)
    }

    useEffect(() => {
      dispatch(resetCreditScoring())
      setResetScoring(true)
    }, [])

    useEffect(() => {
      if (scoring && !resetScoring) {
        handleScoringResponse(scoring)
      } else {
        setResetScoring(false)
      }
    }, [scoring, resetScoring])

    useEffect(() => {
      if (scoringError) {
        setIsErrorScoringModalOpen(scoringError)
      }
    }, [scoringError])

    const childProps = {
      sale,
      carteraUpdateError,
      hasDeliveryAddress,
      showConfirm,
      isLoading,
      onSetTerminalPaymentFinished,
      onSetPartialScoringModal: val => setIsPartialScoringModalOpen(val),
      onLegalConfirm: () => {
        setIsLegalModalOpen(false)

        navigateGenerateOrderStep(TPV)
      },
      onCloseModal: () => {
        dispatch(resetCarteraValuesStore())
      },
      onSetTPVDialog: value => {
        setIsTpvDialogOpen(value)
      },
      onConfirm: () => {
        setIsSubmitted(true)
        validateForm().then(validateFormHandler)
      },
      updateAccountData: () => {
        const { phoneContact2, email, language } = values.account
        // We update the basket data with the info the user has filled in, when we go back
        dispatch(setBasketAccountData({ phoneContact2, email, language }))
      },
      onErrorScoringClose: () => {
        setIsErrorScoringModalOpen(false)
        if (canRetryScoring) {
          dispatch(resetCreditScoring())
        }
      },
      onFinancedLegalConfirm,
      isErrorScoringModalOpen,
      isPartialScoringModalOpen,
      isFinancedLegalModalOpen,
      isLegalModalOpen,
      isTpvDialogOpen,
    }

    return <Summary {...childProps} />
  },
)
