import { useState, useEffect } from 'react'
import { analyticsInstance } from '../services'

export function useAnalytics() {
  const [instance, setInstance] = useState(null)

  useEffect(() => {
    setInstance(analyticsInstance)
  }, [analyticsInstance])

  return instance
}
