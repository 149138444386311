import { call, put, takeLatest, select } from 'redux-saga/effects'
import { axiosFactory } from 'modules/axios'

import { selectors as customerSelectors } from 'services/customer-360'
import { head, isEmpty } from 'lodash'

import { EXPIRED_STATUS } from 'modules/CustomerActivity/CustomerActivity.constants'
import { logError, formatErrorMessage } from 'services/logging'
import { actions as subscriptionsActions } from 'services/subscriptions'
import { actions as taxesActions } from 'services/taxes'
import { getSmsCommunications } from 'services/sms-communication/api'
import { getHomeGoSvaRegister } from 'services/customer-sva/api'
import { fillSvaHomeGoRegisters } from 'services/customer-sva/helpers'
import { selectSvaRegisterFlag } from 'services/feature-flag/selectors'
import { helpers as customerActivityHelpers } from 'modules/CustomerActivity/store/helpers'
import {
  getCustomerActivityVoiceCalls,
  getCustomerMemos,
  getCustomerOrders,
  getCustomerTypifications,
  getCustomerIframeTypifications,
  getCustomerLogistic,
  getCustomerCommunications,
  getCustomerCompensations,
} from './../services'

import { constants, actions } from './actions'

const subTypesForSmsCuco = [
  { 'Atención cliente': ['fsm-masmovil-sa', 'vista360.auth.masmovil.com'] },
  { Comerciales: ['manual-campaigns', 'sf-mkt-cloud'] },
  { Contrato: ['mas-sales'] },
  { 'Incidencias masivas': ['oss.tools-sa'] },
  { Roaming: ['rpas.auth.masmovil.com'] },
]
let errorCallsNodes = []

const fillArraySmsForCuco = fillCucoItem => {
  const newItem = fillCucoItem

  newItem.map(value => {
    const newValue = value

    newValue.type = 'SMS'

    newValue.subType = subTypesForSmsCuco.find(findValueArray => {
      return head(Object.values(findValueArray)).includes(newValue.clientId)
    })

    const [subTypeFilterKeys] = (newValue.subType && Object.keys(newValue.subType)) || [
      newValue.clientId,
    ]

    if (!newValue.subType) {
      newValue.subType = 'Otros'
    } else {
      newValue.subType = subTypeFilterKeys
    }

    newValue.date = value.deliveryStatus === EXPIRED_STATUS ? value.requestTime : value.updatedTime
    newValue.phone = value.terminatingNumber
    newValue.deliveryStatus = value.deliveryStatus

    return newValue
  })

  return newItem
}

const setErrorCallsNodes = newError => {
  errorCallsNodes.push(newError)
}

const cleanErrorCallsNodes = () => {
  errorCallsNodes = []
}

function* callCustomerMemo(documentId, documentType, msisdns) {
  try {
    return yield call(getCustomerMemos, documentId, documentType, msisdns)
  } catch (e) {
    yield call(logError, new Error(formatErrorMessage(e)))
    setErrorCallsNodes('memo')
    return []
  }
}

function* callCustomerOrders(documentId) {
  try {
    return yield call(getCustomerOrders, documentId)
  } catch (e) {
    yield call(logError, new Error(formatErrorMessage(e)))
    setErrorCallsNodes('visits')
    setErrorCallsNodes('sales')
    return []
  }
}

function* callCustomerTypifications(msisdns) {
  try {
    return yield call(getCustomerTypifications, msisdns)
  } catch (e) {
    yield call(logError, new Error(formatErrorMessage(e)))
    setErrorCallsNodes('typification')
    return []
  }
}

function* callCustomerIframeTypifications(customerId) {
  try {
    return yield call(getCustomerIframeTypifications, customerId)
  } catch (e) {
    yield call(logError, new Error(formatErrorMessage(e)))
    setErrorCallsNodes('typification')
    return []
  }
}

function* callCustomerLogistic(documentId, msisdns) {
  try {
    return yield call(getCustomerLogistic, documentId, msisdns)
  } catch (e) {
    yield call(logError, new Error(formatErrorMessage(e)))
    setErrorCallsNodes('logistic')
    return []
  }
}

function* callCustomerCommunications(msisdns) {
  try {
    return yield call(getCustomerCommunications, msisdns)
  } catch (e) {
    yield call(logError, new Error(formatErrorMessage(e)))
    setErrorCallsNodes('communication')
    return []
  }
}

function* callCustomerCompensations(msisdns) {
  try {
    return yield call(getCustomerCompensations, msisdns)
  } catch (e) {
    yield call(logError, new Error(formatErrorMessage(e)))
    setErrorCallsNodes('compensation')
    return []
  }
}

function* fetchCustomerActivitySaga({ payload: { msisdns, customerId } }) {
  try {
    cleanErrorCallsNodes()
    const svaRegisterFlag = yield select(selectSvaRegisterFlag)
    const documentType = yield select(customerSelectors.getIdentificationType)
    const documentId = yield select(customerSelectors.getIdentificationId)
    const dataMemo = yield call(callCustomerMemo, documentId, documentType, msisdns)
    const dataOrders = yield call(callCustomerOrders, documentId)
    const dataTypifications = yield call(callCustomerTypifications, msisdns)
    const dataIframeTypifications = yield call(callCustomerIframeTypifications, customerId)
    const dataLogistic = yield call(callCustomerLogistic, documentId, msisdns)
    const dataCommunications = yield call(callCustomerCommunications, msisdns)
    const dataCompensations = yield call(callCustomerCompensations, msisdns)
    const dataCucoSms = yield call(getSmsCommunications, msisdns)

    const dataSmsCucoParse = fillArraySmsForCuco(dataCucoSms.data.data.items)
    const dataVoiceCalls = yield call(getCustomerActivityVoiceCalls, msisdns)

    let svaHomeGoRegisters = []
    if (svaRegisterFlag) {
      const svaHomeGoDataRegisters = yield call(getHomeGoSvaRegister, msisdns)
      svaHomeGoRegisters = fillSvaHomeGoRegisters(svaHomeGoDataRegisters)
    }

    if (!isEmpty(dataVoiceCalls)) {
      dataVoiceCalls.data.data = dataVoiceCalls?.data?.data.map(value => {
        const dataVoiceCall = value
        if (!('subType' in value)) {
          dataVoiceCall.subType = dataVoiceCall && dataVoiceCall.type
          dataVoiceCall.type = 'voiceCalls'
        }

        return dataVoiceCall
      })
    }

    const memoData = dataMemo?.data || []
    const ordersData = dataOrders?.data || []
    const typificationsData = dataTypifications?.data || []
    const iframeYypificationsData = dataIframeTypifications?.data || [] 
    const logisticData = dataLogistic?.data || []
    const communicationsData = dataCommunications?.data || []
    const compensationsData = dataCompensations?.data || []
    const voiceCallsData = dataVoiceCalls?.data?.data || []
    const smsCucoData = dataSmsCucoParse || []
    const svaRegisters = svaHomeGoRegisters || []

    const iframeYypificationsFormattedData = customerActivityHelpers.formatIframeTypification(iframeYypificationsData, msisdns)
    
    const dataAllRegisters = {
      data: [
        ...memoData,
        ...ordersData,
        ...typificationsData,
        ...iframeYypificationsFormattedData,
        ...logisticData,
        ...communicationsData,
        ...compensationsData,
        ...voiceCallsData,
        ...smsCucoData,
        ...svaRegisters,
      ],
      errorCallsNodes,
    }

    yield put(actions.fetchCustomerActivitySuccess(dataAllRegisters))
  } catch (e) {
    yield call(logError, { e: new Error(formatErrorMessage(e)), msisdns })
    const error = axiosFactory.buildError(e)
    yield put(actions.fetchCustomerActivityFailed(error))
  }
}

function* fetchCustomerSubcriptionsSaga({ payload: postalCode }) {
  try {
    yield put(taxesActions.findTax(postalCode))
    yield put(subscriptionsActions.findSubscriptions())
  } catch (e) {
    yield call(logError, new Error(formatErrorMessage(e)))
  }
}

export function* watchFetchCustomerActivitySaga() {
  yield takeLatest(constants.FETCH, fetchCustomerActivitySaga)
}

export function* watchFetchCustomerSubcriptionsSaga() {
  yield takeLatest(constants.FETCH_SUBSCRIPTIONS, fetchCustomerSubcriptionsSaga)
}
