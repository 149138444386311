import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { fetchFinancingConditions } from '../store/device-renewal.actions'
import { selectFinancingConditionsInformation } from '../store/device-renewal.selectors'

export const useDeviceFinancingConditions = () => {
  const dispatch = useDispatch()

  const {
    msisdn,
    renewalSaleChannel,
    financingConditionsData,
    financingConditionsError,
    isLoadingFinancingConditions,
    isFinalPaymentDiscountApplicable,
    shouldAmendPenalty,
    shouldLetDecideIfAmendPenalty,
    shouldAllowFinancingDecision,
    shouldMantainFinancing,
    shouldAllowFinalPaymentDecision,
  } = useSelector(selectFinancingConditionsInformation)

  useEffect(() => {
    if ((!financingConditionsData || financingConditionsError) && !isLoadingFinancingConditions) {
      dispatch(fetchFinancingConditions(msisdn, renewalSaleChannel))
    }
  }, [])

  const finalPaymentDiscountAmount = financingConditionsData?.quota25?.quota25DiscountAmount

  return {
    financingConditionsError,
    isLoadingFinancingConditions,
    financingConditionsData,
    finalPaymentDiscountAmount,
    isFinalPaymentDiscountApplicable,
    shouldAmendPenalty,
    shouldLetDecideIfAmendPenalty,
    shouldAllowFinancingDecision,
    shouldMantainFinancing,
    shouldAllowFinalPaymentDecision,
  }
}
