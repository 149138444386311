import PropTypes from 'prop-types'

import { Box, Icon, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import { TextStyles } from 'utils/text'

import { ButtonLink } from '../buttons/ButtonLink'

const useStyles = makeStyles({
  'sub-header': {
    padding: '24px',
    marginBottom: '32px',
    backgroundColor: '#FFF',

    '& h4': {
      margin: '0 auto 0 24px',
    },
  },
})

export const SubHeader = ({ className, title, onGoBack, children }) => {
  const classes = useStyles()

  return (
    <Box
      display="flex"
      alignItems="center"
      className={[classes['sub-header'], className].join(' ')}>
      <ButtonLink onClick={onGoBack}>
        <Icon>keyboard_backspace</Icon>
      </ButtonLink>

      <Typography {...TextStyles.title1BoldDark()}>{title}</Typography>

      {children}
    </Box>
  )
}

SubHeader.defaultProps = {
  className: '',
}

SubHeader.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  onGoBack: PropTypes.func.isRequired,
  children: PropTypes.node,
}
