import { get } from 'lodash'
import { constants } from './constants'

const baseInitialState = {
  data: null,
  error: null,
  loading: false,
}

const initialState = {
  findSva: {
    ...baseInitialState,
  },
  disableSva: {
    ...baseInitialState,
  },
  purchaseSva: {
    ...baseInitialState,
  },
  customerSvas: {
    ...baseInitialState,
  },
  hebe: {
    success: null,
    error: null,
    loading: false,
  },
  homeGoSva: {
    data: null,
    error: {
      cadence: null,
      sicor: null,
    },
    loading: false,
  },
  cancelHomeGoSvaOrder: {
    error: null,
    loading: false,
  },
}

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case constants.FIND_CUSTOMER_SVA: {
      return {
        ...state,
        findSva: {
          ...baseInitialState,
          loading: true,
        },
      }
    }
    case constants.FIND_CUSTOMER_SVA_SUCCESS: {
      return {
        ...state,
        findSva: {
          ...baseInitialState,
          data: payload.data,
        },
      }
    }
    case constants.FIND_CUSTOMER_SVA_FAILED: {
      return {
        ...state,
        findSva: {
          ...baseInitialState,
          error: payload.error,
        },
      }
    }
    case constants.RESET_CUSTOMER_SVA: {
      return {
        ...state,
        findSva: {
          ...baseInitialState,
        },
      }
    }

    case constants.CANCEL_SICOR_SVA_START: {
      return {
        ...state,
        disableSva: {
          ...initialState.disableSva,
          loading: true,
        },
      }
    }

    case constants.DISABLE_CUSTOMER_SVA_INIT: {
      return {
        ...state,
        disableSva: {
          ...initialState.disableSva,
          loading: true,
        },
      }
    }
    case constants.DISABLE_CUSTOMER_SVA_SUCCESS: {
      return {
        ...state,
        disableSva: {
          data: payload.data,
          loading: false,
        },
      }
    }
    case constants.DISABLE_CUSTOMER_SVA_FAILED: {
      return {
        ...state,
        disableSva: {
          error: payload.error,
          loading: false,
        },
      }
    }
    case constants.DISABLE_CUSTOMER_SVA_PENDING: {
      return {
        ...state,
        disableSva: {
          ...initialState.disableSva,
          disablePending: true,
        },
      }
    }
    case constants.DISABLE_CUSTOMER_SVA_RESET: {
      return {
        ...state,
        disableSva: {
          ...initialState.disableSva,
        },
      }
    }
    case constants.FETCH_CUSTOMER_SVAS: {
      return {
        ...state,
        customerSvas: {
          ...baseInitialState,
          loading: true,
        },
      }
    }
    case constants.FETCH_CUSTOMER_SVAS_SUCCESS: {
      return {
        ...state,
        customerSvas: {
          ...baseInitialState,
          data: payload.data,
        },
      }
    }
    case constants.FETCH_CUSTOMER_SVAS_FAILED: {
      return {
        ...state,
        customerSvas: {
          ...baseInitialState,
          error: payload.error,
        },
      }
    }

    case constants.PUT_HEBE_SVA_CUSTOMER_INIT: {
      return {
        ...state,
        hebe: {
          ...initialState.hebe,
          loading: true,
        },
      }
    }
    case constants.PUT_HEBE_SVA_CUSTOMER_SUCCESS: {
      return {
        ...state,
        hebe: {
          success: true,
          loading: false,
        },
      }
    }
    case constants.PUT_HEBE_SVA_CUSTOMER_FAILED: {
      return {
        ...state,
        hebe: {
          error: payload.error,
          loading: false,
        },
      }
    }
    case constants.PUT_HEBE_SVA_CUSTOMER_RESET: {
      return {
        ...state,
        hebe: {
          ...initialState.hebe,
        },
      }
    }

    case constants.PURCHASE_ALARM_START: {
      return {
        ...state,
        purchaseSva: {
          ...initialState.purchaseSva,
          loading: true,
        },
      }
    }
    case constants.PURCHASE_ALARM_SUCCESS: {
      return {
        ...state,
        purchaseSva: {
          ...initialState.purchaseSva,
          data: { ...state.data, ...payload },
        },
      }
    }
    case constants.PURCHASE_ALARM_FAILED: {
      return {
        ...state,
        purchaseSva: {
          ...initialState.purchaseSva,
          error: get(payload, 'errorCadence') || payload,
        },
      }
    }
    case constants.RESET_PURCHASE_ALARMS: {
      return {
        ...state,
        purchaseSva: {
          ...initialState.purchaseSva,
        },
      }
    }

    case constants.FIND_HOMEGO_SVA: {
      return {
        ...state,
        homeGoSva: {
          ...baseInitialState,
          error: {
            cadence: null,
            sicor: null,
          },
          loading: true,
        },
      }
    }
    case constants.FIND_HOMEGO_SVA_SUCCESS: {
      return {
        ...state,
        homeGoSva: {
          ...baseInitialState,
          error: {
            cadence: null,
            sicor: null,
          },
          data: payload.data,
        },
      }
    }
    case constants.FIND_HOMEGO_SVA_FAILED: {
      return {
        ...state,
        homeGoSva: {
          ...baseInitialState,
          error: payload.error,
          data: payload.error?.sicor ? state.homeGoSva.data : null,
        },
      }
    }

    case constants.CANCEL_HOMEGO_SVA_ORDER: {
      return {
        ...state,
        cancelHomeGoSvaOrder: {
          error: null,
          loading: true,
        },
      }
    }

    case constants.CANCEL_HOMEGO_SVA_ORDER_SUCCESS: {
      return {
        ...state,
        cancelHomeGoSvaOrder: {
          error: null,
          loading: false,
        },
      }
    }

    case constants.CANCEL_HOMEGO_SVA_ORDER_FAILED: {
      return {
        ...state,
        cancelHomeGoSvaOrder: {
          error: payload.error,
          loading: false,
        },
      }
    }

    default:
      return state
  }
}
