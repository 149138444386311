export const getCssArrowMixin = (itemHeight, color) => ({
  content: '""',
  position: 'absolute',
  right: `-${itemHeight / 4}px`,
  bottom: 0,
  top: 0,
  width: 0,
  height: 0,
  borderStyle: 'solid',
  borderWidth: `${itemHeight / 2}px 0 ${itemHeight / 2}px ${itemHeight / 4}px`,
  borderColor: `transparent transparent transparent ${color}`,
})
