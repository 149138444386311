import { call, put, takeEvery, select } from 'redux-saga/effects'
import { get } from 'lodash'
import { logError, formatErrorMessage } from 'services/logging'
import { selectOrder } from 'services/product-orders'
import { axiosFactory } from 'modules/axios'
import { actions } from './actions'
import * as constants from './constants'
import { create, logBackOfficeInfo } from './api'

export function* createSaga({ payload }) {
  try {
    const response = yield call(create, payload.data)
    yield put(actions.changeTechnologyCreated({ data: response }))
    const originalOrder = yield select(selectOrder)
    const targetTariffId = get(payload, 'data.changeTechnologyData.offer.id')
    yield call(logBackOfficeInfo, originalOrder, response, targetTariffId)
  } catch (e) {
    yield call(logError, { e: new Error(formatErrorMessage(e)), payload })
    const error = axiosFactory.buildError(e)
    yield put(actions.createChangeTechnologyFailed(error))
  }
}

export function* watchCreateChangeTechnology() {
  yield takeEvery(constants.CREATE_CHANGE_TECHNOLOGY, createSaga)
}
