import { axiosFactory } from 'modules/axios'
import { selectTechnology, selectTerritoryOwner } from 'modules/Coverage/store/index'
import { CATEGORY_MOBILE_V3 } from 'modules/tariffs/tariffs.constants'
import { all, call, put, takeLatest, select } from 'redux-saga/effects'
import { logError, formatErrorMessage } from 'services/logging'
import { fetchAdditionalTariffs, fetchTariffs } from '../../services'
import {
  fetchAdditionalLineTariffsAction,
  fetchAdditionalTariffsErrorAction,
  fetchAdditionalTariffsSuccessAction,
  fetchMainTariffsAction,
  fetchMainTariffsErrorAction,
  fetchMainTariffsSuccessAction,
  fetchMobileLineTariffsAction,
  fetchMobileLineTariffsErrorAction,
  fetchMobileLineTariffsSuccessAction,
  FETCH_ADDITIONAL_TARIFFS,
  FETCH_MAIN_TARIFFS,
  FETCH_MOBILE_LINE_TARIFFS,
  FETCH_TARIFFS,
} from '../tariffs.actions'

export function* fetchAdditionalLineTariffsSaga({
  payload: { channel, segment, mainTariffPoId, mainTariffPsId, operation },
}) {
  try {
    if (!mainTariffPsId) {
      yield put(fetchAdditionalTariffsErrorAction())
    } else {
      const additionalTariffs = yield call(fetchAdditionalTariffs, {
        channel,
        segment,
        mainTariffPoId,
        mainTariffPsId,
        operation,
      })
      yield put(fetchAdditionalTariffsSuccessAction(additionalTariffs))
    }
  } catch (e) {
    console.warn(e)
    yield call(logError, {
      e: new Error(formatErrorMessage(e)),
      channel,
      segment,
      mainTariffPoId,
      mainTariffPsId,
      operation,
    })
    const error = axiosFactory.buildError(e)
    yield put(fetchAdditionalTariffsErrorAction(error))
  }
}

export function* watchFetchAdditionalTariffs() {
  yield takeLatest(FETCH_ADDITIONAL_TARIFFS, fetchAdditionalLineTariffsSaga)
}

export function* fetchMobileLineTariffsSaga({
  payload: { channel, segment, tariffType, categories = 'postpaid,main_line' },
}) {
  try {
    const mobileLineTariffs = yield call(fetchTariffs, {
      tariffType,
      categories,
      channel,
      segment,
    })
    yield put(fetchMobileLineTariffsSuccessAction(mobileLineTariffs))
  } catch (e) {
    console.warn(e)
    yield call(logError, { e: new Error(formatErrorMessage(e)), channel, segment, tariffType })
    const error = axiosFactory.buildError(e)
    yield put(fetchMobileLineTariffsErrorAction(error))
  }
}
export function* watchFetchMobileLineTariffs() {
  yield takeLatest(FETCH_MOBILE_LINE_TARIFFS, fetchMobileLineTariffsSaga)
}

export function* fetchMainLineTariffsSaga({
  payload: { channel, segment, tariffType, crossSell, hasD2rDiscount },
}) {
  try {
    const coverageTechnology = yield select(selectTechnology)
    const territoryOwner = yield select(selectTerritoryOwner)
    const technology = ['FTTH', 'NEBA', 'VULA'].includes(coverageTechnology) ? 'fiber' : 'adsl'

    const crossSellCategories = 'postpaid,main_line,cross-sell'
    const convergenteCategories = crossSell ? crossSellCategories : 'postpaid,main_line,new'

    let categories = convergenteCategories

    if (tariffType === 'fixed_line') {
      categories = hasD2rDiscount ? 'postpaid,second_residence' : 'postpaid,main_line'
    }

    const mainLineTariffs = yield call(fetchTariffs, {
      tariffType,
      categories,
      technology,
      territoryOwner: territoryOwner.value,
      channel,
      segment,
    })
    yield put(fetchMainTariffsSuccessAction(mainLineTariffs))
  } catch (e) {
    console.warn(e)
    yield call(logError, { e: new Error(formatErrorMessage(e)), channel, segment, tariffType })
    const error = axiosFactory.buildError(e)
    yield put(fetchMainTariffsErrorAction(error))
  }
}

export function* watchFetchMainLineTariffsSaga() {
  yield takeLatest(FETCH_MAIN_TARIFFS, fetchMainLineTariffsSaga)
}

export function* fetchTariffsSaga({ payload: { type, ...restPayload } }) {
  if (type === 'additional') {
    yield put(fetchAdditionalLineTariffsAction(restPayload))
  }

  if (type === 'mobile') {
    yield put(
      fetchMobileLineTariffsAction({
        ...restPayload,
        tariffType: CATEGORY_MOBILE_V3,
        categories: 'postpaid,main_line,new',
      }),
    )
  }

  if (type === 'mobile_fixed_line') {
    yield put(fetchMobileLineTariffsAction({ ...restPayload, tariffType: type }))
  }

  if (type === 'multisim_line') {
    yield put(
      fetchMobileLineTariffsAction({
        ...restPayload,
        tariffType: 'mobile_line',
        categories: 'postpaid,multisim_line',
      }),
    )
  }

  if (type === 'convergent' || type === 'fixed_line') {
    yield put(fetchMainTariffsAction({ ...restPayload, tariffType: type }))
  }
}

export function* watchFetchTariffs() {
  yield takeLatest(FETCH_TARIFFS, fetchTariffsSaga)
}

export function* watchTariffsModuleSagas() {
  yield all([watchFetchTariffs(), watchFetchMobileLineTariffs(), watchFetchAdditionalTariffs()])
}
