import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { concat, get, isEmpty, remove, uniqBy, isNaN } from 'lodash'

import { Checkbox, Typography, Divider, Icon } from '@material-ui/core'
import { TextStyles } from 'utils/text'
import { ICONS } from 'utils/icons'

import { LineItemCard, OutlinedDivider } from 'components/ui'
import { formatFee } from 'utils'
import { getRowObj, hasCustomAmounts } from 'modules/credit'
import { applyTax } from 'services/taxes'
import { CreditSingleRow } from './CreditSingleRow'

export const CreditSubInvoice = styled(
  ({
    className,
    subInvoiceObj,
    selectedRows,
    setSelectedRows,
    creditSubInvoices,
    setSingleRowError,
    tax,
  }) => {
    const subInvoice = get(subInvoiceObj, 'subInvoice')
    const subInvoiceId = get(subInvoice, 'id')
    const isSubInvoiceSelected = !isEmpty(
      creditSubInvoices.filter(subInv => get(subInv, 'sub-invoice') === subInvoiceId),
    )
    const subInvoiceRows = get(subInvoiceObj, 'rows')
    const subInvoiceRowsIds = subInvoiceRows.map(row => get(row, 'id'))
    const allSubInvoiceRowsSelected =
      selectedRows.filter(row => subInvoiceRowsIds.includes(get(row, 'invoice-row'))).length ===
      subInvoiceRows.length
    const msisdn = get(subInvoice, 'msisdn')
    const subInvoiceAmount = get(subInvoice, 'total')
    const subInvoiceHasCustomAmounts = hasCustomAmounts(subInvoiceRows, selectedRows)
    const [isTotalSubInvoice, setIsTotalSubInvoice] = useState(
      allSubInvoiceRowsSelected && isSubInvoiceSelected && !subInvoiceHasCustomAmounts,
    )
    const [subInvoiceTotalAmount, setSubInvoiceTotalAmount] = useState(0)
    const [toggled, setToggle] = useState(selectedRows.length > 0 && !isTotalSubInvoice)
    const iconType = toggled ? 'upArrow' : 'downArrow'

    const removeSubInvoiceRows = () => {
      const rowsToDelete = subInvoiceRows.map(row => get(row, 'id'))
      setSelectedRows(
        uniqBy(
          remove(selectedRows, row => !rowsToDelete.includes(get(row, 'invoice-row'))),
          'invoice-row',
        ),
      )
    }

    const addSubInvoiceRows = () => {
      const filteredSelectedRows = selectedRows.filter(
        row => !subInvoiceRowsIds.includes(get(row, 'invoice-row')),
      )
      const rows = concat(filteredSelectedRows, subInvoiceRows.map(row => getRowObj(row)))
      setSelectedRows(uniqBy(rows, 'invoice-row'))
    }

    const updateSubInvoiceTotalAmount = () => {
      const subInvoicesSelectedRowsAmount = selectedRows.reduce((totalAmount, row) => {
        const rowWithTaxFree = get(row, 'taxFree')
        if (subInvoiceRowsIds.includes(get(row, 'invoice-row'))) {
          return rowWithTaxFree
            ? get(row, 'amountWithoutVat') + totalAmount
            : applyTax(tax, get(row, 'amountWithoutVat')) + totalAmount
        }
        return totalAmount
      }, 0)

      return setSubInvoiceTotalAmount(
        isTotalSubInvoice ? subInvoiceAmount : subInvoicesSelectedRowsAmount,
      )
    }

    useEffect(() => {
      updateSubInvoiceTotalAmount()
    }, [selectedRows])

    return (
      <div className={`${className} m-y-12`}>
        <div
          className="flex align-start-center clickable-header"
          onClick={() => {
            if (!toggled) {
              setIsTotalSubInvoice(false)
              removeSubInvoiceRows()
            }
            setToggle(!toggled)
          }}
          onKeyPress={() => setToggle(!toggled)}
          tabIndex="0"
          role="button">
          <Typography
            {...TextStyles.labelDark({
              className: 'p-r-16',
            })}>
            {`${msisdn} (${formatFee(subInvoiceAmount)})`}
          </Typography>
          <Icon className="icon">{ICONS[iconType]}</Icon>
        </div>

        <div className="flex align-start-center">
          <Checkbox
            color="primary"
            className="checkbox"
            checked={isTotalSubInvoice}
            onChange={() => {
              if (isTotalSubInvoice) {
                setIsTotalSubInvoice(false)
                removeSubInvoiceRows()
              } else {
                setIsTotalSubInvoice(true)
                addSubInvoiceRows()
              }
            }}
            name="totalSubInvoice"
            id="totalSubInvoice"
          />
          <LineItemCard
            className="custom-width"
            primaryText="Importe completo"
            secondaryText={formatFee(subInvoiceAmount)}
            fieldsApart
          />
        </div>

        {toggled && (
          <div>
            <OutlinedDivider />

            <div className="p-y-12">
              <div className="flex p-y-12">
                <Typography
                  {...TextStyles.action({
                    className: 'row-header',
                  })}>
                  CONCEPTOS:
                </Typography>
                <Typography {...TextStyles.action()}>ELEGIR IMPORTE:</Typography>
              </div>

              {subInvoiceRows.map(row => (
                <CreditSingleRow
                  currentRow={row}
                  selectedRows={selectedRows}
                  setSelectedRows={setSelectedRows}
                  isTotalSubInvoice={isTotalSubInvoice}
                  setIsTotalSubInvoice={setIsTotalSubInvoice}
                  setSingleRowError={setSingleRowError}
                  tax={tax}
                />
              ))}
            </div>

            <Divider className="bottom-line-item bottom-line-item-divider" />
            <div>
              <LineItemCard
                className="bottom-line-item"
                primaryText="Importe total a abonar"
                secondaryText={
                  isTotalSubInvoice
                    ? formatFee(subInvoiceAmount)
                    : !isNaN(subInvoiceTotalAmount) && formatFee(subInvoiceTotalAmount)
                }
                fieldsApart
              />
              <Typography {...TextStyles.action()}>(Impuestos incluidos)</Typography>
            </div>
          </div>
        )}
      </div>
    )
  },
)`
  background-color: #f5f5f5;
  padding: 24px;
  .clickable-header:hover {
    cursor: pointer;
  }
  .clickable-header:focus {
    outline: none;
  }
  .text-capitalized > div > .primary-text {
    text-transform: capitalize;
  }
  .row-line {
    width: 60%;
  }
  .row-header {
    width: 66%;
  }
  .bottom-line-item {
    padding: 12px 0px;
    width: 89%;
  }
  .bottom-line-item-divider {
    height: 4px;
    background-color: #000;
  }
  .checkbox {
    padding: 12px 0px;
  }
  .custom-width {
    width: 63%;
  }
`

CreditSubInvoice.propTypes = {
  className: PropTypes.string,
  applyCreditNote: PropTypes.func,
  creditClearError: PropTypes.func,
  creditErrors: PropTypes.object,
  isCreditLoading: PropTypes.bool,
  isOpen: PropTypes.bool,
  onCloseModal: PropTypes.func,
}
