import PropTypes from 'prop-types'

import { Formik } from 'formik'
import { Modal, SpinnerCenter } from 'components/ui'

import { AgileTvModalForm } from 'modules/agile-tv/components'

export const ChangeAgileTvModal = ({
  open,
  onClose,
  isLoading,
  agileTvEmail,
  agileTvSva,
  applyChangeAgileTv,
}) => {
  return (
    <Modal title="Cambiar servicio televisión" isOpen={open} onClose={!isLoading ? onClose : null}>
      {isLoading && <SpinnerCenter style={{ marginTop: '20px' }} />}
      {!isLoading && agileTvSva && (
        <>
          <Formik
            initialValues={{ email: agileTvEmail, agileTv: {} }}
            onSubmit={({ agileTv }) => {
              applyChangeAgileTv(agileTv)
            }}>
            {props => (
              <AgileTvModalForm
                agileTvSva={agileTvSva}
                onClose={onClose}
                emailDisabled="true"
                {...props}
              />
            )}
          </Formik>
        </>
      )}
    </Modal>
  )
}

ChangeAgileTvModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  agileTvEmail: PropTypes.string.isRequired,
  agileTvSva: PropTypes.array,
  emailValidationIsLoading: PropTypes.bool,
  applyChangeAgileTv: PropTypes.func.isRequired,
}
