import { useState, useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'

import { useSelector } from 'react-redux'
import { APP_CONFIG } from 'services/app-config'

import { makeStyles } from '@material-ui/styles'
import { Card } from '@material-ui/core'
import { SpinnerCenter } from 'components/ui'

import { getAccessToken } from 'modules/Auth/services'

import { masConsentsGdprSelectors } from './MasConsentsGDPR.selectors'
import { updateClientConsents } from '../../services'
import { generateMasConsentsParams } from '../../helpers'

const useStyles = makeStyles({
  'mas-consents-card': {
    display: ({ isLoading }) => isLoading && 'none',
    paddingTop: '10px',

    '& iframe': {
      width: '100%',
      minHeight: 'calc(100vh - 206px)',
    },
  },
})

const COMPONENT_LOADED_EVENT = 'consents-loaded'
const COMPONENT_SUCCESS_EVENT = 'consents-success'
const COMPONENT_ERROR_EVENT = 'consents-error'

export const MasConsentsGdpr = ({
  show,
  contractId,
  subscriptionId,
  verbalId,
  customerDocumentType,
  customerDocument,
  segment,
  lang,
  channel,
  consentType,
  onSuccess,
  onError,
  isLoading: isDataLoading,
  shouldUpdateClientConsents,
}) => {
  const { sfid, brand = 'yoigo' } = useSelector(masConsentsGdprSelectors)

  const [isLoading, setIsLoading] = useState(true)
  const [isClientConsentsUpdating, setIsClientConsentsUpdating] = useState(false)

  const classes = useStyles({ isLoading })

  const params = {
    subscriptionId,
    verbalId,
    customerDocument,
    segment,
    lang,
    brand,
    sfid,
    channel,
    consentType,
    token: getAccessToken(),
  }

  const url = useMemo(() => `${APP_CONFIG.mas_consents}?${generateMasConsentsParams(params)}`, [
    params,
  ])

  const onComponentLoaded = () => {
    setIsLoading(false)
  }

  const onUpdateClientConsents = () => {
    setIsClientConsentsUpdating(true)

    updateClientConsents(contractId, customerDocumentType, customerDocument)
      .then(() => {
        onSuccess()
        setIsClientConsentsUpdating(false)
      })
      .catch(() => {
        onSuccess()
        setIsClientConsentsUpdating(false)
      })
  }

  useEffect(() => {
    if (show) {
      window.addEventListener('message', ({ data: eventName }) => {
        switch (eventName) {
          case COMPONENT_LOADED_EVENT:
            onComponentLoaded()
            break
          case COMPONENT_SUCCESS_EVENT:
            if (shouldUpdateClientConsents) {
              onUpdateClientConsents()
              return
            }

            onSuccess()
            break
          case COMPONENT_ERROR_EVENT:
            if (onError) {
              onError()
            }
            break
          default:
        }
      })
    }
  }, [show])

  if (!show) {
    return null
  }

  return (
    <>
      <Card className={classes['mas-consents-card']}>
        {!isDataLoading && !isClientConsentsUpdating && <iframe title="consents-gdpr" src={url} />}
      </Card>
      {(isLoading || isDataLoading || isClientConsentsUpdating) && <SpinnerCenter />}
    </>
  )
}

MasConsentsGdpr.propTypes = {
  show: PropTypes.bool,
  contractId: PropTypes.string,
  subscriptionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  verbalId: PropTypes.string,
  customerDocumentType: PropTypes.string,
  customerDocument: PropTypes.string.isRequired,
  segment: PropTypes.string.isRequired,
  lang: PropTypes.string.isRequired,
  channel: PropTypes.string.isRequired,
  consentType: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func,
  isLoading: PropTypes.bool,
  shouldUpdateClientConsents: PropTypes.bool,
}
