import { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { get, toUpper } from 'lodash'
import { Typography, Collapse, Grid, Divider } from '@material-ui/core'
import { ButtonLink } from 'components/ui'
import { TextStyles } from 'utils/text'
import { useTypificationsListStyles } from 'modules/typifications/styles'

const BodyList = ({
  msisdn,
  hour,
  brand,
  area,
  campaign,
  sfid,
  channel,
  operator,
  reason,
  subreason1,
  subreason2,
  result,
  subresult,
  description,
}) => {
  const [toogle, setToogle] = useState(false)
  const titleToogle = useMemo(
    () => (toogle ? 'Ocultar información' : 'Mostrar información'),
    [toogle],
  )

  return (
    <div className="body-list">
      <Grid container item className="container-header" xs={12}>
        <Grid item className="item-header" xs={12}>
          <Typography {...TextStyles.labelDark()}>
            {toUpper(brand)} | <span className="bold">{msisdn}</span>
          </Typography>
          <Typography {...TextStyles.subM()}>{`${hour}h`}</Typography>
        </Grid>
        <Grid item className="item-header" xs={12}>
          <Typography {...TextStyles.subM()}>
            {toUpper(area)} | <span className="bold">{toUpper(campaign)}</span>
          </Typography>
          <Typography {...TextStyles.subM()}>
            <span className="bold">{toUpper(sfid)}</span> | {channel}
          </Typography>
        </Grid>
      </Grid>
      <div className="collapse-info">
        <ButtonLink onClick={() => setToogle(!toogle)}>{titleToogle}</ButtonLink>
      </div>
      <Collapse in={toogle} timeout="auto">
        <Grid container item className="container-info" md={12}>
          <Grid item md={12} xs={12}>
            <ul>
              <li className="item-info">
                <Typography {...TextStyles.title2MediumBoldDark()}>Operador:&nbsp;</Typography>
                <Typography {...TextStyles.labelDark()}>{operator}</Typography>
              </li>
            </ul>
          </Grid>
          <Grid item md={6} xs={12}>
            <ul>
              <li className="item-info">
                <Typography {...TextStyles.title2MediumBoldDark()}>Motivo:&nbsp;</Typography>
                <Typography {...TextStyles.labelDark()}>{toUpper(reason)}</Typography>
              </li>
              <li className="item-info">
                <Typography {...TextStyles.title2MediumBoldDark()}>Submotivo:&nbsp;</Typography>
                <Typography {...TextStyles.labelDark()}>{toUpper(subreason1)}</Typography>
              </li>
              {subreason2 && (
                <li className="item-info">
                  <Typography {...TextStyles.title2MediumBoldDark()}>Submotivo 2:&nbsp;</Typography>
                  <Typography {...TextStyles.labelDark()}>{toUpper(subreason2)}</Typography>
                </li>
              )}
            </ul>
          </Grid>
          <Grid item md={6} xs={12}>
            <ul>
              <li className="item-info">
                <Typography {...TextStyles.title2MediumBoldDark()}>Resultado:&nbsp;</Typography>
                <Typography {...TextStyles.labelDark()}>{toUpper(result)}</Typography>
              </li>
              <li className="item-info">
                <Typography {...TextStyles.title2MediumBoldDark()}>Subresultado:&nbsp;</Typography>
                <Typography {...TextStyles.labelDark()}>{toUpper(subresult)}</Typography>
              </li>
            </ul>
          </Grid>
          <Grid item className="item-information" md={12}>
            <Typography {...TextStyles.title2MediumBoldDark()}>Observaciones:&nbsp;</Typography>
            <Typography {...TextStyles.body()}>{description}</Typography>
          </Grid>
        </Grid>
      </Collapse>
    </div>
  )
}

const ListItem = ({ date, registers }) => (
  <div className="list-item">
    <div className="header-list">
      <div>{date}</div>
    </div>
    {registers.map((register, index) => (
      <div key={get(register, 'id').toString()}>
        <BodyList {...register} />
        {index !== registers.length - 1 && <Divider />}
      </div>
    ))}
  </div>
)

const TypificationList = ({ registersData }) => {
  const typificationsListStylesClasses = useTypificationsListStyles()

  return (
    <div className={typificationsListStylesClasses.styles}>
      {registersData.map(data => (
        <ListItem key={get(data, 'id').toString()} {...data} />
      ))}
    </div>
  )
}

BodyList.propTypes = {
  msisdn: PropTypes.string,
  hour: PropTypes.string,
  brand: PropTypes.string,
  area: PropTypes.string,
  campaign: PropTypes.string,
  sfid: PropTypes.string,
  channel: PropTypes.string,
  operator: PropTypes.string,
  reason: PropTypes.string,
  subreason1: PropTypes.string,
  subreason2: PropTypes.string,
  result: PropTypes.string,
  subresult: PropTypes.string,
  description: PropTypes.string,
}

ListItem.propTypes = {
  date: PropTypes.string,
  registers: PropTypes.array,
}

TypificationList.propTypes = {
  registersData: PropTypes.array,
}

export default TypificationList
