import { get, chain } from 'lodash'

export const getZipCode = orderData => {
  const contactMedium = get(orderData, 'customer.contactMedium', {})
  const zipCode = get(
    chain(contactMedium)
      .flatMap()
      .find({ type: 'InstallationAddressFixedLine' })
      .value(),
    'medium.postCode',
  )
  return zipCode
}

export const addRequestType = (data, requestType) => {
  return {
    ...data,
    characteristics: data.characteristics
      ? data.characteristics.map(characteristic =>
          characteristic.name === 'request-type'
            ? { ...characteristic, value: `${characteristic.value}${requestType}` }
            : characteristic,
        )
      : [],
  }
}

export const addInstallerInfo = (data, installer, connectionType) => {
  return {
    ...data,
    characteristics: data.characteristics.map(characteristic => {
      switch (characteristic.name) {
        case 'connection-installation':
          return { ...characteristic, value: `${installer || ''}` }
        case 'connection-type':
          return { ...characteristic, value: `${connectionType || ''}` }
        default:
          return characteristic
      }
    }),
  }
}
