import styled from 'styled-components'

const PanelBase = styled.div`
  font-family: 'Roboto', sistem-ui, sans-serif;
  display: inline-flex;
  justify-content: center;
  align-content: center;
  background-color: ${props => (props.bgColor ? props.bgColor : 'inherit')};
  color: ${props => (props.color ? props.color : 'inherit')};
  font-size: 10px;
  font-weight: bold;
  padding: 4px 15px;
  line-height: 1em;
`

export default PanelBase
