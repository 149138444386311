import { useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { FormGroup, FormControlLabel, Checkbox, Typography, Box, Divider } from '@material-ui/core'
import { TextStyles } from 'utils/text'

import { isProCustomer } from 'services/customer-360/helpers'
import { getSegment } from 'services/customer-360/selectors'

import {
  findFixedProduct,
  findMobileProduct,
  findFixedPermanent,
  findMobilePermanent,
  isConvergentSubscription,
  getMsisdn,
} from 'services/subscriptions'

import { AlertAdvice, Button, ButtonLink, ModalActions, NotificationModal } from 'components/ui'
import { shouldWarnAdditionalLines } from '../selectors'
import { formatRange } from '../helpers'

import { TariffsModalContainer } from '../containers/TariffsModalContainer'
import { ModalContent } from './ModalContent'
import MultiStep from './MultiStep'

const UnsubscribeModal = ({ subscription, onClose, onUpdateSubscription, onConfirm }) => {
  const showAdditionalLinesWarning = useSelector(state =>
    shouldWarnAdditionalLines(state, {
      msisdn: getMsisdn(subscription),
      unsubscribing: true,
    }),
  )
  const showConvergentTariffWarning = isConvergentSubscription(subscription)

  const [fixed, setFixed] = useState(true)
  const [mobile, setMobile] = useState(false)

  const onToggleProduct = e => {
    if (e.target.value === 'fixed') {
      setFixed(e.target.checked)
    } else {
      setMobile(e.target.checked)
    }
  }

  const customerSegment = useSelector(getSegment)
  const taxNeeded = !isProCustomer(customerSegment)
  const isConvergent = isConvergentSubscription(subscription)
  const fixedProduct = findFixedProduct(subscription)
  const fixedPermanent = findFixedPermanent(subscription)
  const mobileProduct = findMobileProduct(subscription)
  const mobilePermanent = findMobilePermanent(subscription)
  const hasSelectedLines = Boolean(fixed || mobile)

  return (
    <ModalContent data-hook="modal-unsubscribe">
      <MultiStep
        initialStep={isConvergent ? 'selectProduct' : 'confirm'}
        steps={{
          /* eslint-disable-next-line react/prop-types */
          selectProduct: ({ goToStep }) => (
            <>
              <p>¿Qué línea del paquete quieres dar de baja?</p>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox onChange={onToggleProduct} value="fixed" checked={fixed} />}
                  label={`Fijo: ${fixedProduct.id}`}
                />
                <FormControlLabel
                  control={<Checkbox onChange={onToggleProduct} value="mobile" checked={mobile} />}
                  label={`Móvil: ${mobileProduct.id}`}
                />
              </FormGroup>

              <ModalActions>
                <Button
                  data-hook="action-continue"
                  disabled={!hasSelectedLines}
                  onClick={() => (mobile ? goToStep('error') : goToStep('selectTariff'))}>
                  Continuar
                </Button>

                <Button secondary onClick={onClose}>
                  Cancelar
                </Button>
              </ModalActions>
            </>
          ),
          selectTariff: () => (
            <>
              <p>Asigna una tarifa a la línea móvil que generas ({mobileProduct.id})</p>
              <TariffsModalContainer
                taxNeeded={taxNeeded}
                isUnsusbcribe
                isFixedReduction
                subscription={subscription}
                onConfirm={data => onUpdateSubscription({ ...data, fixedUnsubscribed: true })}
                onClose={onClose}
              />
            </>
          ),
          confirm: () => (
            <>
              <p>
                Vas a dar de baja esta línea. Una vez confirmes la acción, la línea pasará a estar
                48h en estado congelado antes de darse de baja definitivamente. Revisa la
                información de permamencia y penalización antes de continuar.
              </p>
              {(!isConvergent || fixed) && fixedPermanent ? (
                <>
                  {isConvergent && mobile && (
                    <p>
                      <strong>Por la tarifa:</strong>
                    </p>
                  )}
                  <p>
                    Permanencia actual:{' '}
                    <strong>{formatRange(fixedPermanent.validFor.endDateTime)}</strong>
                  </p>
                  <p>
                    Penalización: <strong>{fixedPermanent.fees.pending}€</strong>
                    <ButtonLink>Condonar</ButtonLink>
                  </p>
                </>
              ) : null}
              {(!isConvergent || mobile) && mobilePermanent ? (
                <>
                  {isConvergent && fixed && (
                    <p>
                      <strong>Por el dispositivo:</strong>
                    </p>
                  )}
                  <p>
                    Permanencia actual:{' '}
                    <strong>{formatRange(mobilePermanent.validFor.endDateTime)}</strong>
                  </p>
                  <p>
                    Penalización: <strong>{mobilePermanent.fees.pending}€</strong>
                    <ButtonLink>Condonar</ButtonLink>
                  </p>
                </>
              ) : null}

              <p>¿Quieres continuar con la baja?</p>
              {showAdditionalLinesWarning && (
                <>
                  <Box mt="24px" mb="24px">
                    <Divider />
                  </Box>
                  <AlertAdvice message="ATENCIÓN informa al cliente que perderá el descuento asociado a las líneas adicionales." />
                </>
              )}
              {showConvergentTariffWarning && (
                <>
                  <Box mt="24px" mb="24px">
                    <Divider />
                  </Box>
                  <AlertAdvice message="ATENCIÓN informa al cliente que perderá todos los descuentos que no sean compatibles con la nueva tarifa convergente." />
                </>
              )}

              <ModalActions>
                <Button
                  onClick={() => {
                    onConfirm({
                      fixedUnsubscribed: fixed,
                      mobileUnsubscribed: mobile,
                      unsubscribed: !isConvergent || (fixed && mobile),
                    })
                    onClose()
                  }}>
                  Continuar
                </Button>

                <Button secondary onClick={onClose}>
                  Cancelar
                </Button>
              </ModalActions>
            </>
          ),
          error: () => (
            <NotificationModal
              type="alert"
              src="/assets/error.svg"
              title="Recuerda que las bajas parciales de móvil/ bajas totales de paquete convergente solo se pueden realizar desde VISTA"
              isOpen
              onClose={onClose}>
              <Typography {...TextStyles.title2Dark()}>
                Funcionalidad disponible proximamente.
              </Typography>
            </NotificationModal>
          ),
        }}
      />
    </ModalContent>
  )
}

UnsubscribeModal.propTypes = {
  subscription: PropTypes.object.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onUpdateSubscription: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default UnsubscribeModal
