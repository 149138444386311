import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { useDispatch, useSelector } from 'react-redux'

import { useFormikContext } from 'formik'

import { Divider, Box, Grid, Card, CardContent, Typography } from '@material-ui/core'
import { AddressChooser } from 'modules/Address'
import { setAddressAction } from 'modules/NewClientSales/store/actions/index'
import { selectBillingData } from 'modules/NewClientSales/store/selectors/index'
import { setNewPostalAddressCartera } from 'modules/CustomerInfo'

import { useUpdateCarteraValues } from '../../../hooks/Cartera'
import PaperInvoice from './PaperInvoice'
import { IbanInput } from './IbanInput/IbanInput'
import { selectHasChangedAndFilledAddress } from '../../../hooks/Shared/useSetCustomerInfo/useSetCustomerInfo.selectors'

function BillingData({ onSelectAddress, onEditAddress }) {
  const dispatch = useDispatch()
  const {
    address,
    addressType,
    availableAddressTypes,
    shouldResetAddress,
    updateValues,
    isMobileOnly,
    isSubscription,
    isMultiSim,
  } = useSelector(selectBillingData)
  const hasChangedAddress = useSelector(selectHasChangedAndFilledAddress)
  const { values, handleChange } = useFormikContext()
  const newPostalAddress = useUpdateCarteraValues({
    action: setNewPostalAddressCartera,
    payload: updateValues,
    flags: { requiresAccountId: true },
  })
  const [newAddressType, setNewAddressType] = useState('')

  function onSetAddress(result) {
    dispatch(setAddressAction({ ...result, addressType: 'billing' }))
  }

  function onEditBillingAddress(value) {
    onEditAddress(value)
  }

  function onSelectAddressType(selectedType, addressName) {
    if (addressType !== selectedType) {
      setNewAddressType(selectedType)
    }

    onSelectAddress(selectedType, addressName)
  }

  useEffect(() => {
    if (shouldResetAddress && newAddressType === 'otherAddress') {
      onEditAddress('billing')
    }
  }, [shouldResetAddress, newAddressType])

  useEffect(() => {
    if (hasChangedAddress) newPostalAddress.setCarteraValuesHaveChanged(true)
  }, [hasChangedAddress])

  return (
    <Card style={{ margin: 0 }}>
      <CardContent>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="subtitle1" color="secondary" style={{ fontWeight: 'bold' }}>
              DATOS DE FACTURACIÓN
            </Typography>

            <Box mt="20px">
              <Grid item xs={12}>
                <Typography variant="body1" color="textSecondary" className="m-b-8">
                  La CUENTA BANCARIA debe cumplir las siguientes condiciones:
                  <strong> Debes </strong> ser titular de la misma (para empresa el titular puede
                  ser tanto la empresa como el apoderado); <strong>Debes </strong>
                  informar a tu banco que admita los recibos que reciban de Xfera Móviles, S.A.U.
                </Typography>

                <Typography variant="body1" color="textSecondary" className="m-b-8">
                  Las facturas se emiten a mes vencido y se pasa a cobro el día 4 de cada mes o
                  siguiente día hábil.
                </Typography>
              </Grid>
            </Box>
          </Grid>

          <Grid item xs={12} className="m-t-24">
            <Typography variant="subtitle1" color="secondary" className="bold">
              INTRODUCE EL NÚMERO DE CUENTA (IBAN)
            </Typography>

            <Grid className="m-b-8" item xs={8}>
              <IbanInput />
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <AddressChooser
              title="DIRECCIÓN DE FACTURACIÓN"
              preselectedAddress={address}
              availableAddressTypes={availableAddressTypes}
              hideButtons={isMobileOnly || isMultiSim || !!isSubscription}
              type="billing"
              selectedAddressType={addressType}
              onSelectAddress={onSelectAddressType}
              onSetAddress={onSetAddress}
              onEditAddress={onEditBillingAddress}
              disableLinkModify={isMultiSim}
            />

            <Box my="28px">
              <Divider />
            </Box>
          </Grid>

          <Grid item xs={12}>
            <PaperInvoice values={values} handleChange={handleChange} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

BillingData.propTypes = {
  onSelectAddress: PropTypes.func.isRequired,
  onEditAddress: PropTypes.func.isRequired,
}

export default BillingData
