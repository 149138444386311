import { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { useFormikContext } from 'formik'

import { Box } from '@material-ui/core'
import { SpinnerButton, Button } from 'components/ui'
import { useScrollToFirstInputError } from 'modules/Core/FormUtils'

import SaveChangesModal from './SaveChangesModal'
import { useValidateErrors } from '../../hooks/Validations/useValidateErrors'

function SaveChangesButton({
  isEditing,
  nomargin,
  isOffer,
  isLoading,
  onContinue,
  beforeContinue,
  setBasketData,
  hasValidationErrors,
  ibanError,
  disabled,
  stepName,
}) {
  const { values, setSubmitting } = useFormikContext()

  // Prevent state updates on unmounted components
  // https://stackoverflow.com/questions/49906437/how-to-cancel-a-fetch-on-componentwillunmount
  const isMounted = useRef(true)

  const { scrollToFirstError } = useScrollToFirstInputError()
  const { validateErrors } = useValidateErrors(stepName)

  const [isReloadSaveModalOpen, setIsReloadSaveModalOpen] = useState(false)

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  const shouldShowError = hasValidationErrors || ibanError || isLoading

  async function onContinueSaveChangesModal() {
    if (beforeContinue) {
      await beforeContinue()
    }
    const errors = await validateErrors(shouldShowError)
    if (errors) {
      if (scrollToFirstError) {
        scrollToFirstError(errors)
      }

      return
    }

    if (isMounted.current) {
      setIsReloadSaveModalOpen(true)
    }
  }

  function onCloseSaveChangesModal() {
    if (isMounted.current) {
      setIsReloadSaveModalOpen(false)
    }
  }

  async function onSuccess() {
    await setBasketData(values)
    await onContinue()
  }

  async function validateAndOnContinue() {
    if (beforeContinue) {
      await beforeContinue()
    }
    const errors = await validateErrors(shouldShowError)

    setSubmitting(true)

    if (errors) {
      if (scrollToFirstError) {
        scrollToFirstError(errors)
      }

      setSubmitting(false)
      return
    }

    if (!beforeContinue) {
      setBasketData(values)
    }

    try {
      await onContinue()
    } catch (e) {
      console.warn(e)
    }

    setSubmitting(false)
  }

  const mainButton = isEditing ? (
    <>
      <Box display="inline-block" ml={nomargin ? '0' : '16px'}>
        <Button
          type="button"
          name="pageContinue"
          disabled={disabled}
          data-hook="checkout-save-changes"
          onClick={onContinueSaveChangesModal}>
          Guardar Cambios
        </Button>
      </Box>
      <SaveChangesModal
        isOpen={isReloadSaveModalOpen}
        onSuccess={onSuccess}
        onClose={onCloseSaveChangesModal}
      />
    </>
  ) : (
    <Box display="inline-block" ml={nomargin ? '0' : '16px'}>
      <Button
        type="button"
        onClick={validateAndOnContinue}
        disabled={disabled}
        name="pageContinue"
        data-hook="page-continue">
        {isOffer ? 'Confirmar oferta' : 'Continuar'}
      </Button>
    </Box>
  )

  return isLoading ? (
    <SpinnerButton style={{ marginLeft: nomargin ? '0' : '16px' }} disabled />
  ) : (
    mainButton
  )
}

SaveChangesButton.defaultProps = {
  nomargin: false,
  isEditing: false,
  onContinue: () => {},
}

SaveChangesButton.propTypes = {
  isEditing: PropTypes.any,
  isLoading: PropTypes.bool,
  setBasketData: PropTypes.func.isRequired,
  onContinue: PropTypes.func,
  nomargin: PropTypes.bool,
  isOffer: PropTypes.bool,
  hasValidationErrors: PropTypes.bool,
  beforeContinue: PropTypes.func,
  disabled: PropTypes.bool,
  ibanError: PropTypes.string,
  stepName: PropTypes.string,
}

export default SaveChangesButton
