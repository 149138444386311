import { Modal, ModalActions, Button } from 'components/ui'
import PropTypes from 'prop-types'

export const PartialScoringModal = ({ isOpen, onClose }) => (
  <Modal title="No pudimos completar la solicitud" isOpen={isOpen} type="default">
    <p>Es necesario que el cliente envíe la siguiente documentación a ...</p>
    <ModalActions>
      <Button onClick={onClose}>Cerrar</Button>
    </ModalActions>
  </Modal>
)
PartialScoringModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
}
