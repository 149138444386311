import { constants } from './constants'

const loadInit = (documentType, documentId, filters) => ({
  type: constants.LOAD_INIT,
  payload: {
    documentType,
    documentId,
    filters,
  },
})

const loadSucceeded = () => ({
  type: constants.LOAD_SUCCEEDED,
  payload: {},
})

const loadFailed = error => ({
  type: constants.LOAD_FAILED,
  payload: error,
})

export const actions = {
  loadInit,
  loadSucceeded,
  loadFailed,
}
