import { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import { Box, Typography, Divider } from '@material-ui/core'
import { push } from 'connected-react-router'

import { Button, FormikInput, ModalActions, SpinnerButton } from 'components/ui'
import { Formik } from 'formik'
import { selectQueryParams } from 'modules/Router'
import { objectToQueryString } from 'modules/url'
import {
  setOrderType,
  fetchPresaleData,
  selectFetchPresaleIsLoading,
  selectFetchedPresaleError,
  getFetchPresaleData,
} from '../../../store'
import { SALES_BASE_PATH } from '../../../constants/index'

export const PreSaleFetchChooser = ({ setIsShow }) => {
  const formRef = useRef(null)

  const dispatch = useDispatch()

  const queryParams = useSelector(selectQueryParams)
  const [isSubmitted, setIsSubmitted] = useState(false)

  const isLoading = useSelector(selectFetchPresaleIsLoading)
  const preSaleData = useSelector(getFetchPresaleData)
  const presaleFetchingError = useSelector(selectFetchedPresaleError)

  const [formikRef, setFormikRef] = useState(null)

  const onSubmit = ({ presaleId }, ref) => {
    setFormikRef(ref)

    dispatch(fetchPresaleData(presaleId))
    setIsSubmitted(true)
  }

  const getPresaleIdError = () => {
    let message

    if (
      isSubmitted &&
      !isLoading &&
      (!!presaleFetchingError || preSaleData?.is_closed || !preSaleData?.data)
    ) {
      message = 'No se ha encontrado ninguna preventa con este identificador'

      if (presaleFetchingError) {
        message = 'Se ha producido un error obteniendo los datos. Reintente por favor'
      }

      if (preSaleData?.is_closed) {
        message = 'Preventa cerrada. Ya se ha completado la venta con este identificador.'
      }
    }

    return message
  }

  useEffect(() => {
    const error = getPresaleIdError()

    if (error) {
      formikRef.setStatus({ ...formikRef.status, presaleId: error })
      if (formRef && formRef.current) {
        formRef.current.querySelector('input').blur()
      }
    }
  }, [preSaleData, presaleFetchingError])

  useEffect(() => {
    if (isSubmitted && !!preSaleData && preSaleData.data && !preSaleData.is_closed) {
      const { ...query } = queryParams

      const {
        data: { orderType },
      } = preSaleData

      query.sale = orderType
      query.isPresale = true

      const queryString = objectToQueryString({ ...query })

      const flowUrl = `${SALES_BASE_PATH}?${queryString}`

      dispatch(setOrderType({ orderType }))
      dispatch(push(flowUrl))
    }
  }, [preSaleData])

  return (
    <Formik
      initialValues={{
        presaleId: '',
      }}
      onSubmit={onSubmit}>
      {({ handleSubmit, values, setStatus, status }) => (
        <form
          ref={formRef}
          onSubmit={e => {
            handleSubmit(e)

            setTimeout(() => formRef?.current?.querySelector('input').blur(), 500)
          }}
          style={{ paddingBottom: '1px' }}>
          <Box pt="16px">
            <Typography variant="body1">Introduce el ID de la preventa, por favor.</Typography>
          </Box>
          <Box display="flex" mt="20px" minWidth="380px">
            <FormikInput
              name="presaleId"
              fullWidth
              label="Identificador"
              validate={inputValue => !inputValue && 'Campo requerido'}
              onChange={() => {
                setStatus({ ...status, presaleId: null })
                setIsSubmitted(false)
              }}
              disabled={{ bool: isLoading }}
              style={{ maxHeight: '76px' }}
              trim
            />
          </Box>

          <ModalActions>
            <Box position="relative" top="-16px">
              <Divider absolute />
            </Box>
            <Button
              secondary
              onClick={() => {
                setIsShow(false)
              }}
              disabled={isLoading}>
              Volver
            </Button>

            {isLoading && <SpinnerButton />}

            {!isLoading && (
              <Button type="submit" disabled={!values.presaleId}>
                Continuar
              </Button>
            )}
          </ModalActions>
        </form>
      )}
    </Formik>
  )
}

PreSaleFetchChooser.propTypes = {
  setIsShow: PropTypes.func,
}
