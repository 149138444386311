import PropTypes from 'prop-types'

export function TerminalsIframeComponent({ url, className }) {
  return (
    url && (
      <iframe
        title="terminal-initial-payment"
        id="terminal-initial-payment"
        frameBorder="0"
        height="450px"
        className={className}
        width="100%"
        src={`${url}&target=_self`}
      />
    )
  )
}

TerminalsIframeComponent.defaultProps = {
  className: '',
}

TerminalsIframeComponent.propTypes = {
  url: PropTypes.string,
  className: PropTypes.string,
}
