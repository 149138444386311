import { call, put, takeLatest } from 'redux-saga/effects'
import { logError, formatErrorMessage } from 'services/logging'
import { chain } from 'lodash'
import { axiosFactory } from 'modules/axios'

import {
  svasActionTypes,
  fetchSvaProListError,
  fetchSvaProListSuccess,
  fetchSvaAgileTvSuccess,
  fetchSvaAgileTvError,
  fetchRenewalSvaAgileTvSuccess,
  fetchRenewalSvaAgileTvError,
} from './svas.actions'
import { fetchFixedSVAs, fetchHomeGoSVAs, fetchSVAs, fetchRenewalSVAs } from '../services'

export function* fetchSVAsProSaga({ payload: { shouldFetchHomeGo, ...data } }) {
  let svas = []

  try {
    const getSvasResult = yield call(() =>
      Promise.allSettled([
        fetchFixedSVAs(data),
        ...(shouldFetchHomeGo ? [fetchHomeGoSVAs({ ...data, paymentType: 'postpaid' })] : []),
      ]),
    )

    svas = chain(getSvasResult)
      .filter(res => res.status === 'fulfilled' && !!res.value)
      .map(res => res.value)
      .flatten()
      .sort((curr, next) => curr?.psId.localeCompare(next?.psId))
      .value()

    const errorDetected = getSvasResult.find(
      res => res.status === 'rejected' && res.reason.response.status !== 404,
    )
    if (errorDetected) {
      throw errorDetected.reason
    }

    yield put(fetchSvaProListSuccess(svas))
  } catch (e) {
    console.warn(e)
    yield call(logError, { e: new Error(formatErrorMessage(e)), data })
    const error = axiosFactory.buildError(e)
    yield put(fetchSvaProListError({ error, svas }))
  }
}

export function* watchFetchSVAsProSaga() {
  yield takeLatest(svasActionTypes.FETCH_SVAS_LIST_PRO, fetchSVAsProSaga)
}

export function* fetchSVAsAgileSaga({ payload }) {
  try {
    const svas = yield call(fetchSVAs, payload)
    yield put(fetchSvaAgileTvSuccess(svas))
  } catch (e) {
    console.warn(e)
    yield call(logError, { e: new Error(formatErrorMessage(e)), payload })
    const error = axiosFactory.buildError(e)
    yield put(fetchSvaAgileTvError(error))
  }
}

export function* watchFetchSVAsAgileSaga() {
  yield takeLatest(svasActionTypes.FETCH_SVAS_AGILE_TV, fetchSVAsAgileSaga)
}

export function* fetchRenewalSVAsAgileSaga({ payload }) {
  try {
    const svas = yield call(fetchRenewalSVAs, payload)
    yield put(fetchRenewalSvaAgileTvSuccess(svas))
  } catch (e) {
    console.warn(e)
    yield call(logError, { e: new Error(formatErrorMessage(e)), payload })
    const error = axiosFactory.buildError(e)
    yield put(fetchRenewalSvaAgileTvError(error))
  }
}

export function* watchFetchRenewalSVAsAgileSaga() {
  yield takeLatest(svasActionTypes.FETCH_RENEWAL_SVAS_AGILE_TV, fetchRenewalSVAsAgileSaga)
}
