/* eslint-disable import/no-cycle */
import { isEmpty } from 'lodash'
import { useFormikContext } from 'formik'
import { resetCarteraValuesStore } from 'modules/CustomerInfo'
import { fetchCustomerOrderLimit, fetchCustomerDuoLimitAction } from 'modules/CustomerOrders'

import {
  selectIsCarteraQueryParam,
  selectIsMobileOnly,
  selectIsElFijoSaleType,
} from 'modules/Router/store/index'
import convergentTariffsMock from 'modules/tariffs/mocks/convergentTariffs.json'
import { fetchMainTariffsSuccessAction } from 'modules/tariffs/store/index'
import PropTypes from 'prop-types'
import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SIGNUP_TYPE_PORTABILITY } from 'services/global-constants'
import { fieldNames } from 'modules/Lines/line.config'
import { LINE_TYPE } from 'modules/Lines/constants'

import { Offer } from '../../components/Offer'
import { BILLING } from '../../constants'
import { withCheckoutForms } from '../../hocs'
import { selectMainOfferTariff, setBasketData, setTerminalShipping } from '../../store'
import { fetchInstallationsNewClientSales, setBasketTariff } from '../../store/actions'
import { selectOffers } from './OfferContainer.selectors'
import { useReserveTerminals } from '../../hooks/Terminals'

function OfferCnt({ queryParams, navigateToNextStep, isEditing }) {
  const dispatch = useDispatch()

  const {
    isCrossSell,
    isDuoLoading,
    subscriptionInformation,
    broadbandType,
    routerName,
    routerPenaltyWithTaxes,
    ...storeSelectors
  } = useSelector(selectOffers)

  const isElFijo = useSelector(selectIsElFijoSaleType)
  const { values, setFieldValue } = useFormikContext()

  const isCartera = useSelector(selectIsCarteraQueryParam)
  const isMobileOnly = useSelector(selectIsMobileOnly)

  const fieldnames = fieldNames(LINE_TYPE.LANDLINE, isMobileOnly)

  const mainOffer = useSelector(selectMainOfferTariff)
  const { onReserveTerminals } = useReserveTerminals()

  const dispatchActions = {
    setBasketData: useCallback(data => {
      dispatch(setBasketData(data))
    }),
    fetchMainTariffsSuccessAction: useCallback(data => {
      dispatch(fetchMainTariffsSuccessAction(data))
    }),
    setTerminalShipping: useCallback(data => {
      dispatch(setTerminalShipping(data))
    }),
    fetchInstallation: useCallback(() => {
      dispatch(fetchInstallationsNewClientSales())
    }),
    setBasketTariff: useCallback(data => {
      dispatch(setBasketTariff(data))
    }),
  }

  function onSetPortability() {
    if (values.landlineOffer.isPortability === 'yes') {
      setFieldValue('landlineOffer.signUpType', SIGNUP_TYPE_PORTABILITY)
    }
  }

  function onSetTariffsForTesting() {
    if ((isEmpty(storeSelectors.tariffs) && queryParams.fillSale) || queryParams.isTesting) {
      const tariffsFromMock = convergentTariffsMock
      dispatchActions.fetchMainTariffsSuccessAction(tariffsFromMock)
    }
  }

  async function beforeContinue() {
    dispatchActions.setBasketData({
      ...values,
    })

    dispatchActions.fetchInstallation()

    if (!storeSelectors.isPosSale) {
      await onReserveTerminals()
    }
  }

  function onContinue() {
    navigateToNextStep(BILLING, isEditing)
  }

  function onCloseModal() {
    dispatch(resetCarteraValuesStore())
  }

  useEffect(() => {
    onSetPortability()
    onSetTariffsForTesting()
    const { docNumber, docType } = storeSelectors.saleInformation
    if (!isCartera && !isMobileOnly && docNumber && docType)
      dispatch(fetchCustomerOrderLimit(storeSelectors.saleInformation))
  }, [])

  useEffect(() => {
    if (isCrossSell && storeSelectors.saleInformation.docNumber && isDuoLoading === null) {
      dispatch(fetchCustomerDuoLimitAction(subscriptionInformation))
    }
  }, [storeSelectors.saleInformation, subscriptionInformation, isDuoLoading, isCrossSell])

  useEffect(() => {
    if (!isMobileOnly && !isElFijo) {
      setFieldValue('landlineOffer.broadbandType', broadbandType)
      setFieldValue('landlineOffer.routerName', routerName)
      setFieldValue('landlineOffer.routerTerminationFee', routerPenaltyWithTaxes)
    }
  }, [broadbandType, routerPenaltyWithTaxes, routerName, isMobileOnly, isElFijo])

  return (
    <Offer
      mainOffer={mainOffer}
      setBasketData={dispatchActions.setBasketData}
      onContinue={onContinue}
      queryParams={queryParams}
      values={values}
      setFieldValue={setFieldValue}
      isEditing={isEditing}
      beforeContinue={beforeContinue}
      onCloseModal={onCloseModal}
      isCrossSell={!!isCrossSell}
      fieldNames={fieldnames}
      {...storeSelectors}
    />
  )
}

OfferCnt.propTypes = {
  queryParams: PropTypes.object.isRequired,
  navigateToNextStep: PropTypes.func.isRequired,
  isEditing: PropTypes.bool,
}

export const OfferContainer = withCheckoutForms(OfferCnt)
