import { useState } from 'react'
import { useSelector } from 'react-redux'

import { getInvoicePdf, getInvoiceZip } from 'modules/billings'
import { logError, formatErrorMessage } from 'services/logging'
import { selectCustomerId } from 'modules/Auth'
import { downloadBlob } from 'utils/files/index'
import { canIBillDownload } from 'modules/vista-360/helpers/permissions.helper'

export const useDownloadInvoice = () => {
  const customerId = useSelector(selectCustomerId)

  const [loading, setLoading] = useState(false)

  const downloadInvoicePdf = billId => {
    setLoading(true)

    getInvoicePdf(billId)
      .then(response => window.open(response?.downloadLink, '_blank'))
      .catch(e => logError(new Error(formatErrorMessage(e))))
      .then(() => setLoading(false))
  }

  const downloadInvoiceZip = yearMonth => {
    setLoading(true)

    getInvoiceZip(yearMonth)
      .then(blob => downloadBlob(blob, `${customerId}-${yearMonth}.zip`))
      .catch(e => logError(new Error(formatErrorMessage(e))))
      .then(() => setLoading(false))
  }

  return {
    loading,
    canIBillDownload: canIBillDownload(),
    downloadInvoicePdf,
    downloadInvoiceZip,
  }
}
