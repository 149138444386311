import { get, chain, cloneDeep, find, isEmpty } from 'lodash'
import { getCountryFromIsoCode } from 'utils'
import changeTechnologyData from './resources/createChangeTechnology.json'

const changeTechnologyJson = cloneDeep(changeTechnologyData)

function setOrderDate() {
  const timeNow = new Date()
  const offset = 2 * 60 * 60000

  timeNow.setTime(timeNow.getTime() + offset)

  const orderDate = `${timeNow.toISOString().slice(0, 19)}.000`
  return orderDate
}

function setCurrentAddress(contactMedium, currentInstallationAddress) {
  let currentAddress = find(contactMedium, { type: 'CurrentInstallationAddress'})

  if(isEmpty(currentAddress)) {
    currentAddress = {
      type: "CurrentInstallationAddress",
      preferred: 'false',
      medium: currentInstallationAddress,
    }
    contactMedium.push(currentAddress)
  } else {
    currentAddress.medium = currentInstallationAddress
  }
}

function setCustomerInfo(customer, currentInstallationAddress, products, coverageData) {
  const contactMediumList = get(customer, 'customer.contactMedium', [])

  setCurrentAddress(contactMediumList, currentInstallationAddress)

  const birthday =
    chain(customer)
      .get('customer.characteristics')
      .find({ name: 'birthday' })
      .get('value')
      .value() || ''

  const nationality = chain(customer)
    .get('customer.characteristics')
    .find({ name: 'nationality' })
    .get('value')
    .value()

  const characteristics = [
    {
      name: 'birthday',
      value: birthday,
    },
    {
      name: 'nationality',
      value: getCountryFromIsoCode(nationality),
    },
  ]

  changeTechnologyJson.customer.firstName = get(customer, 'customer.firstName')
  changeTechnologyJson.customer.midName = get(customer, 'customer.midName')
  changeTechnologyJson.customer.lastName = get(customer, 'customer.lastName')
  changeTechnologyJson.customer.individualIdentification = get(customer, 'customer.individualIdentification', {})
  changeTechnologyJson.customer.characteristics = characteristics
  changeTechnologyJson.customer.contactMedium = contactMediumList

  const fixedNumber = get(
    chain(products)
      .flatMap('productsSpecifications')
      .find({
        name: 'fixed',
      })
      .value(),
    'id',
  )
  const mobile = chain(products)
    .head()
    .get('productsSpecifications')
    .find({
      name: 'mobile',
    })
    .value()

  changeTechnologyJson.customer.products[0].productNumber = fixedNumber
  changeTechnologyJson.customer.products[1] = mobile

  const productsSpecifications = changeTechnologyJson.customer.products[0].productsSpecifications[0]
  productsSpecifications.id = fixedNumber
  productsSpecifications.productSpecCharacteristic[0].value = fixedNumber

  productsSpecifications.productSpecCharacteristic[4].value = get(coverageData, 'territoryOwner')
  productsSpecifications.productSpecCharacteristic[5].value = get(coverageData, 'addressId')
}

function setChangeTechnologyInfo(customer, products, sfid, clientId) {
  changeTechnologyJson.orderDate = setOrderDate()
  changeTechnologyJson.id = customer.id
  changeTechnologyJson.commercialNameTariff = get(products, '[0].description', '')
  changeTechnologyJson.contractId = get(customer, 'contractId')
  changeTechnologyJson.seller = sfid
  changeTechnologyJson.tariffId = get(products, '[0].type', '')
  changeTechnologyJson.subscriptionId = get(products, '[0].subscriptionId', '')
  changeTechnologyJson.clientId = clientId
}

export const fillchangeTechnologyOrder = (
  customer,
  sfid,
  coverageData,
  customerWithProductUpdated,
  clientId,
  currentInstallationAddress,
) => {
  const products = get(customerWithProductUpdated, 'products', {})

  setChangeTechnologyInfo(customer, products, sfid, clientId)
  setCustomerInfo(customer, currentInstallationAddress, products, coverageData)

  const gescal = chain(changeTechnologyJson.customer.contactMedium)
    .flatMap()
    .find({
      type: 'InstallationAddressFixedLine',
    })
    .flatMap('characteristic')
    .filter({
      name: 'gescal',
    })
    .map('value')
    .head()
    .value()

  const productsSpecifications = changeTechnologyJson.customer.products[0].productsSpecifications[0]
  productsSpecifications.productSpecCharacteristic[3].value = gescal

  return changeTechnologyJson
}

export const fillchangeTechnologyOrderCoverage = (
  customer,
  customerCoverageData,
  sfid,
  coverageData,
  customerWithProductUpdated,
  clientId,
  currentInstallationAddress,
) => {
  const products = get(customerWithProductUpdated, 'products', {})

  setChangeTechnologyInfo(customer, products, sfid, clientId)
  setCustomerInfo(customer, currentInstallationAddress, products, coverageData)

  const productsSpecifications = changeTechnologyJson.customer.products[0].productsSpecifications[0]
  productsSpecifications.productSpecCharacteristic[3].value = get(customerCoverageData, 'gescal')

  return changeTechnologyJson
}
