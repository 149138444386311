import { createSelector } from 'reselect'
import { formatDate } from './ponc.helpers'

export const selectPonc = state => state.ponc || {}

export const selectPoncLoading = createSelector(
  selectPonc,
  ponc => ponc.loading,
)

export const selectPoncHasBeenReached = createSelector(
  selectPonc,
  ponc => ponc?.reached,
)

export const selectPoncHasError = createSelector(
  selectPonc,
  ponc => !!ponc.error,
)

export const selectErrorCode = createSelector(
  selectPonc,
  ponc => ponc.error && ponc.error.httpCode,
)

export const selectErrorMsg = createSelector(
  selectPonc,
  ponc => ponc.error && ponc.error.info,
)

export const selectCancelPonc = createSelector(
  selectPonc,
  ponc => ({
    ...ponc,
    ...formatDate(ponc.cancellationDate),
  }),
)
