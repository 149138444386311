import PropTypes from 'prop-types'
import styled from 'styled-components'
import Button from './../buttons/Button'

const ConfigFooterStyled = styled.div`
  margin-bottom: 6px;
  margin-top: 28px;
  button:not(:last-child) {
    margin-right: 24px;
  }
`

const ConfigFooter = (props) => {
  const {onConfirm, onCancel} = props
  return (
    <ConfigFooterStyled>
      <Button onClick={onConfirm}>Confirmar</Button>
      <Button onClick={onCancel} secondary>Salir sin hacer cambios</Button>
    </ConfigFooterStyled>
  )
}

ConfigFooter.propTypes = {
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
}


export default ConfigFooter
