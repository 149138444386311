import { call, put, takeLeading } from 'redux-saga/effects'
import { logError, formatErrorMessage } from 'services/logging'
import { axiosFactory } from 'modules/axios'

import { fetchEnergyContracts } from '../services'

import {
  FETCH_ENERGY_CONTRACTS,
  fetchEnergyContractsSuccess,
  fetchEnergyContractsFailed,
} from './energy.actions'

export function* fetchEnergyContractSaga({ payload: { documentNumber } }) {
  try {
    const result = yield call(fetchEnergyContracts, documentNumber)

    yield put(fetchEnergyContractsSuccess(result))
  } catch (e) {
    if (e.message?.indexOf('404') > -1) {
      yield put(fetchEnergyContractsSuccess([]))
      return
    }

    yield call(logError, { e: new Error(formatErrorMessage(e)), documentNumber })
    const error = axiosFactory.buildError(e)
    yield put(fetchEnergyContractsFailed(error))
  }
}

export function* watchFetchEnergyContract() {
  yield takeLeading(FETCH_ENERGY_CONTRACTS, fetchEnergyContractSaga)
}
