import { constants } from './constants'

const initialState = {
  error: null,
  loading: false,
  ordersByClient: [],
}

export const reducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case constants.FIND_ORDER_PRODUCTS: {
      return { ...initialState, loading: true }
    }
    case constants.FIND_ORDER_PRODUCTS_SUCCESS: {
      return { ...state, data: payload.data, loading: false }
    }
    case constants.FIND_ORDER_PRODUCTS_FAILED: {
      return {
        ...state,
        error: payload.error,
        loading: false,
      }
    }

    case constants.FIND_ORDERS_PRODUCT_BY_CLIENT: {
      return { ...initialState, loading: true }
    }

    case constants.FIND_ORDERS_PRODUCT_BY_CLIENT_SUCCESS: {
      return { ...state, ordersByClient: payload.data, loading: false }
    }

    case constants.FIND_ORDERS_PRODUCT_BY_CLIENT_FAILED: {
      return {
        ...state,
        error: payload.error,
        loading: false,
      }
    }

    default:
      return state
  }
}
