import { constants } from './constants'

const initialState = {
  data: [],
  error: null,
  loading: false,
}

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case constants.FLAGS_INIT: {
      return { ...initialState, loading: true }
    }
    case constants.FLAGS_SUCCESS: {
      return { ...state, data: payload.data, loading: false }
    }
    case constants.FLAGS_FAILED: {
      return {
        ...state,
        error: payload.error,
        loading: false,
      }
    }
    default:
      return state
  }
}
