import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { actions as productOrdersActions } from 'services/product-orders'
import { getMsisdn } from 'services/subscriptions'

export const useFetchOrders = subscription => {
  const dispatch = useDispatch()

  const fetchOrders = (msisdn, filterBy) =>
    dispatch(productOrdersActions.findProductOrders(msisdn, filterBy))

  useEffect(() => {
    fetchOrders(getMsisdn(subscription), { filterBy: 'msisdn' })
  }, [])

  return {
    fetchOrders,
  }
}
