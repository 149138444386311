// Constants
const LOAD_INIT = 'OVID/VISTA360/DASHBOARD_LOAD_INIT'
const LOAD_SUCCEEDED = 'OVID/VISTA360/DASHBOARD_LOAD_SUCCEEDED'
const LOAD_FAILED = 'OVID/VISTA360/DASHBOARD_LOAD_FAILED'

export const constants = {
  LOAD_INIT,
  LOAD_SUCCEEDED,
  LOAD_FAILED,
}
