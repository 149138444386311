import { call, put, takeEvery } from 'redux-saga/effects'
import { logError, formatErrorMessage } from 'services/logging'
import { axiosFactory } from 'modules/axios'

import { actions } from './actions'
import { constants } from './constants'
import { readAll } from './api'

export function* getFlagsSaga() {
  try {
    const nextFeaturesFlags = yield call(readAll)

    yield put(actions.getFlagsSuccess({ data: nextFeaturesFlags.data }))
  } catch (e) {
    yield call(logError, new Error(formatErrorMessage(e)))
    const error = axiosFactory.buildError(e)
    yield put(actions.getFlagsFailed(error))
  }
}

export function* watchFlags() {
  yield takeEvery(constants.FLAGS_INIT, getFlagsSaga)
}
