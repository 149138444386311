import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import { isEmpty, get } from 'lodash'

import { Grid } from '@material-ui/core'
import { SpinnerCenter } from 'components/ui'
import {
  findBarringsStatusAction,
  selectBarringsByProductId,
  getBarringsGroupedByType,
  selectIsLoadingBarringsStatus,
  selectBarringsStatusErrorMessage,
} from 'modules/barrings'
import { selectIsLoading, selectHasErrors } from 'modules/vista-360/sagas/productDashboardLoad'
import * as subscriptionsSvc from 'services/subscriptions'
import { selectors as offersDetailSvcSelectors } from 'services/offers-detail/selectors'
import { actions as offersDetailSvcActions } from 'services/offers-detail/actions'
import { getSubscriptionId } from 'modules/orders/store/orders.selectors'

import { MULTISIM_LINE } from 'services/customer-360/constants'
import { selectIsPosOrMasProximo } from 'modules/Permissions/store/permissions-selectors'
import { ProductServicesCard } from './ProductServicesCard/ProductServicesCard'

import { Board } from '../../components/Board'

import { extractServicesSimData } from '../../selectors/selectors'

export const ProductServices = styled(({ className, orderId, productId }) => {
  const dispatch = useDispatch()

  const isPosOrMasproximo = useSelector(selectIsPosOrMasProximo)
  const subscription = useSelector(subscriptionsSvc.getSubscriptionByCurrentProductId)
  const msisdn = subscription && subscriptionsSvc.getMsisdn(subscription)
  const multisim = get(subscription, 'multisimType') === MULTISIM_LINE

  const onflyOrderSubscriptionId = useSelector(getSubscriptionId)
  const onflySubscription = {
    isLoading: useSelector(offersDetailSvcSelectors.isLoading),
    hasError: useSelector(offersDetailSvcSelectors.hasErrors),
    data: useSelector(offersDetailSvcSelectors.getData),
  }
  const onFlyJoinData = {
    ...get(onflySubscription.data, 'order_info', {}),
    ...get(onflySubscription.data, 'subscriptions[0]', {}),
    subscription_id: onflyOrderSubscriptionId,
  }
  const onflySubscrip = orderId ? onFlyJoinData : null

  useEffect(() => {
    if (msisdn) {
      dispatch(findBarringsStatusAction(msisdn))
    }
  }, [msisdn])

  useEffect(() => {
    if (orderId && isEmpty(onflySubscription.data)) {
      dispatch(offersDetailSvcActions.findOrderProducts(orderId))
    }
  }, [])

  const isLoading = useSelector(selectIsLoading)
  const hasErrors = useSelector(selectHasErrors)
  const isBarringsStatusLoading = useSelector(selectIsLoadingBarringsStatus)
  const hasBarringsStatusErrors = useSelector(selectBarringsStatusErrorMessage)
  const barringsData = useSelector(selectBarringsByProductId(msisdn))
  const sortedBarrings = getBarringsGroupedByType(barringsData)

  const dataServices = {
    servicesSim: useSelector(extractServicesSimData),
  }

  return (
    <Board className={className}>
      {(isLoading || onflySubscription.isLoading) && <SpinnerCenter />}
      {!isLoading && !hasErrors && !onflySubscription.isLoading && (
        <Grid item xs={12}>
          {dataServices && productId && (
            <ProductServicesCard
              data={dataServices}
              sortedBarrings={sortedBarrings}
              isBarringsStatusLoading={isBarringsStatusLoading}
              hasBarringsStatusErrors={hasBarringsStatusErrors}
              activeSubscrip={subscription}
              onflySubscrip={onflySubscrip}
              productId={productId}
              multisim={multisim}
              isPosOrMasproximo={isPosOrMasproximo}
            />
          )}
        </Grid>
      )}
    </Board>
  )
})`
  position: relative;
  && .card-content {
    padding: 0;
  }
`
