import { takeLatest, put } from 'redux-saga/effects'

import { actions } from './actions'
import { constants } from './constants'

function* clientDashboardLoadSaga() {
  try {
    // Put here your required calls...
    return yield put(actions.loadSucceeded())
  } catch (e) {
    const payload = {
      error: {
        all: {
          msg: e,
        },
      },
    }

    return yield put(actions.loadFailed(payload))
  }
}

export function* watchLoad() {
  yield takeLatest(constants.LOAD_INIT, clientDashboardLoadSaga)
}
