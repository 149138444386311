import { useState, useEffect } from 'react'

export const useDocumentLoaded = () => {
  const [documentIsLoaded, setDocumentIsLoaded] = useState(false)

  const checkDocumentState = () => {
    if (document.readyState === 'complete') {
      setDocumentIsLoaded(true)
    }
  }

  useEffect(() => {
    checkDocumentState()

    document.onreadystatechange = checkDocumentState
  }, [])

  return {
    documentIsLoaded,
  }
}
