import { useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import { flatten, isNaN } from 'lodash'
import { Box, Typography, Card, CardContent, Divider } from '@material-ui/core'
import Close from '@material-ui/icons/Close'

import { TextStyles } from 'utils/text'

import {
  selectRetainOptionFlag,
  selectCancelReplaceableFlag,
} from 'services/feature-flag/selectors'
import { cancelOrdersAction, retainOrderAction, selectOrdersCancellationFlow } from 'modules/orders'
import { CANCELLATION_FLOW_TOGETHER } from 'modules/orders/constants'
import { formatFee } from 'utils'

import { ButtonLink } from 'components/ui'
import { SectionTitleGrid } from 'components'
import { LegalRecording } from '../LegalRecording'
import { CancellationResult } from '../CancellationResult'
import { CallReasons } from '../CallReasons'
import { WizardRequest } from '../WizardRequest'
import CancelLegalRecording from '../CancelLegalRecording/CancelLegalRecording'
import RetainLegalRecording from '../RetainLegalRecording/RetainLegalRecording'
import { CancelOrderModal } from '../CancelOrderModal'
import CancelWarning from './CancelWarning'
import { CancelOrdersList } from './CancelOrdersList/CancelOrdersList'
import { CANCEL_OPERATION, RETAIN_OPERATION } from '../../constants/cancellations'
import { useCancelOrders } from './useCancelOrders'

const CancelOrders = ({
  orders,
  orderId,
  currentStep,
  isModalOpened,
  showModal,
  isElFijoCancellation,
  cancelOptions,
  retainReason,
  setRetainReason,
  retainOptions,
  onRetain,
  email,
  setEmailField,
  onInputEmail,
  personalData,
  cancelReason,
  onSelectCancelReason,
  callReason,
  onSelectCallReason,
  onCancel,
  onWizardGoBack,
  onContinue,
  penalties,
  operationType,
  isSaving,
  hasError,
  isSuccess,
  canceledOrders,
}) => {
  const dispatch = useDispatch()
  const showRetainOption = useSelector(selectRetainOptionFlag)
  const cancellationFlow = useSelector(selectOrdersCancellationFlow)
  const cancelReplaceableFlag = useSelector(selectCancelReplaceableFlag)

  const isCancellationFlowTogether = cancellationFlow === CANCELLATION_FLOW_TOGETHER

  const {
    selectedOrders,
    handleOrderSelected,
    handleOrderLineSelectionChange,
    isOrderSelected,
    isOrderLineSelected,
    handleAllOrdersSelected,
    areAllOrdersSelected,
    isAllowedReplaceable,
    replaceableOrder,
    onSetReplaceableOrder,
    replaceableOrderOptions,
    ordersToDelete,
    orderToReplace,
    checkOrderPartiallySelected,
    warningMessage,
  } = useCancelOrders(orders, isElFijoCancellation, isCancellationFlowTogether)

  useEffect(() => {
    if (hasError && canceledOrders?.length && selectedOrders?.length) {
      const selectedOrdersCanceled = selectedOrders.filter(
        order => canceledOrders.indexOf(order.id) > -1,
      )

      selectedOrdersCanceled.forEach(order => handleOrderSelected(order, false))
    }
  }, [hasError, canceledOrders, selectedOrders])

  const lines = useMemo(() => flatten(selectedOrders.map(order => order.lines)), [selectedOrders])

  const mainlineIsClosed = useMemo(() => orders.find(order => order.main)?.isClosed, [orders])

  const mainlinePenalty = useMemo(
    () =>
      !isCancellationFlowTogether
        ? { fixed: selectedOrders.find(order => order.main)?.penalty, mobile: 0 }
        : { fixed: penalties.fixed, mobile: penalties.mobile },
    [selectedOrders, isCancellationFlowTogether, penalties],
  )

  const totalPenalty = useMemo(
    () =>
      !isCancellationFlowTogether
        ? selectedOrders.reduce((currentValue, order) => currentValue + order.penalty, 0)
        : penalties.mobile + penalties.fixed,
    [selectedOrders, isCancellationFlowTogether, penalties],
  )

  const onSelectRetainReason = (reason = '') => {
    setRetainReason(reason)
  }

  const onContinueRetain = () => {
    if (!retainReason) {
      showModal(true)
    }

    onContinue()
  }

  const onShowActionModal = () => {
    if (email.isChecked && !email.isValid) {
      setEmailField({ isValid: false })
    } else {
      showModal(true)
    }
  }

  const onToggleCheck = isChecked => {
    setEmailField({ isChecked })
  }

  const onWindowClose = () => {
    window.close()
  }

  const onCancelOrder = () => {
    const { fullName } = personalData

    dispatch(
      cancelOrdersAction(
        { toDelete: ordersToDelete, toReplace: orderToReplace },
        {
          body: {
            cancelReason: cancelReason.value,
            communication: { email: email.value, clientName: fullName },
            callReason,
          },
          notify: email.isChecked,
        },
      ),
    )
  }

  const onRetainOrder = () => {
    dispatch(
      retainOrderAction(orderId, {
        reason: retainReason,
        email: email.value,
      }),
    )
  }

  const onModalConfirm = () => {
    if (operationType === CANCEL_OPERATION) {
      onCancelOrder()
    } else if (operationType === RETAIN_OPERATION) {
      onRetainOrder()
    }
  }

  const retainLegal = <RetainLegalRecording personalData={personalData} lines={lines} />

  const retainCancel = (
    <CancelLegalRecording
      isElFijoCancellation={isElFijoCancellation}
      personalData={personalData}
      lines={lines}
      mainlineIsClosed={mainlineIsClosed}
      mainlinePenalty={mainlinePenalty}
      totalPenalty={totalPenalty}
      replaceableOrder={replaceableOrder}
    />
  )

  const callReasons = (
    <>
      <CallReasons
        selectedOption={callReason}
        onMenuItemChange={onSelectCallReason}
        defaultOption="Seleccionar el motivo de la llamada"
        options={cancelOptions || []}
      />
    </>
  )
  const confirmAction = (
    <LegalRecording
      onToggleCheck={isChecked => onToggleCheck(isChecked)}
      onInputChange={(isValid, value) => onInputEmail(isValid, value)}
      legalComponent={operationType === RETAIN_OPERATION ? retainLegal : retainCancel}
    />
  )

  const wizardRequestInitialProps = {
    onClickConfirm: onShowActionModal,
    onClickGoBack: onWizardGoBack,
    defaultDisabled: !callReason,
    disabled: (!email.isValid && email.isChecked) || selectedOrders.length < 1,
    state: currentStep,
  }

  const cancelOrderReason = (
    <WizardRequest
      {...wizardRequestInitialProps}
      onClick={onCancel}
      onClickContinue={onContinue}
      onMenuItemChange={onSelectCancelReason}
      selectedOption={cancelReason.value}
      defaultOption="Seleccionar el motivo de la cancelación"
      continueText="Continuar con la cancelación"
      confirmText="Confirmar cancelación"
      isCancel
      defaultText="Cancelar la suscripción"
      options={cancelReason.options}
      required>
      {operationType === CANCEL_OPERATION ? (
        <CancelWarning warningMessage={warningMessage} />
      ) : null}
    </WizardRequest>
  )

  const retainOrderReason = (
    <WizardRequest
      {...wizardRequestInitialProps}
      onClick={onRetain}
      onClickContinue={onContinueRetain}
      isCancel={false}
      onMenuItemChange={onSelectRetainReason}
      selectedOption={retainReason}
      defaultOption="Selecciona el submotivo de la retención"
      continueText="Continuar con la retención"
      confirmText="Confirmar solicitud"
      defaultText="Retener"
      options={retainOptions}
      outlinedButton
    />
  )

  const cancelOrRetain = (
    <>
      {(!operationType || operationType === CANCEL_OPERATION) && (
        <div data-hook="wizard-request-cancel">{cancelOrderReason}</div>
      )}
      {(!operationType || operationType === RETAIN_OPERATION) && showRetainOption && (
        <div data-hook="wizard-request-retain">{retainOrderReason}</div>
      )}
    </>
  )

  let render
  switch (currentStep) {
    case 0:
    case 1:
      render = (
        <div>
          <CancelOrdersList
            orderId={orderId}
            orders={orders}
            onOrderSelected={handleOrderSelected}
            onOrderLineSelected={handleOrderLineSelectionChange}
            onAllOrdersSelected={handleAllOrdersSelected}
            areAllOrdersSelected={areAllOrdersSelected}
            isOrderSelected={isOrderSelected}
            isOrderLineSelected={isOrderLineSelected}
            checkOrderPartiallySelected={checkOrderPartiallySelected}
            allowPartialCancellation={!isCancellationFlowTogether}
            allowSelectAll={!isCancellationFlowTogether}
            isAllowedReplaceable={cancelReplaceableFlag && isAllowedReplaceable}
            replaceableOrder={replaceableOrder}
            onSetReplaceableOrder={onSetReplaceableOrder}
            replaceableOrderOptions={replaceableOrderOptions}
          />

          <Divider />

          {!isNaN(totalPenalty) && (
            <Box my="25px">
              <Typography
                {...TextStyles.paragraphDark({
                  className: 'thin',
                })}>
                Penalización{' '}
                {isCancellationFlowTogether ? 'del paquete principal' : 'total de lo seleccionado'}:{' '}
                {formatFee(totalPenalty)}
              </Typography>
            </Box>
          )}

          <Divider />

          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            mt={4}
            width="100%"
            maxWidth="390px"
            mb="20px">
            {callReasons}
          </Box>

          {cancelOrRetain}
        </div>
      )
      break
    case 2:
      render = (
        <div>
          {confirmAction}
          <Box display="flex" flexDirection="row">
            {operationType === CANCEL_OPERATION ? cancelOrderReason : retainOrderReason}
          </Box>
        </div>
      )
      break
    default:
      break
  }

  return (
    <>
      <SectionTitleGrid>
        <>
          <Typography variant="h3">Cancelar Suscripción</Typography>

          <ButtonLink color="default" underline={false} onClick={onWindowClose}>
            VOLVER A VISTA SIN GUARDAR CAMBIOS
            <Close />
          </ButtonLink>
        </>
      </SectionTitleGrid>

      <Box display="flex" justifyContent="center" margin="24px 32px">
        <Card style={{ margin: 0, width: '100%' }}>
          <CardContent>
            {(isSaving || hasError || isSuccess) && (
              <div>
                <CancellationResult
                  isSaving={isSaving}
                  hasError={hasError}
                  isSuccess={isSuccess}
                  onRetry={onModalConfirm}
                  closeWindow={onWindowClose}
                />
              </div>
            )}

            {isSaving === null && render}
          </CardContent>
        </Card>
        <CancelOrderModal
          open={isModalOpened}
          onClose={() => showModal(false)}
          onConfirm={onModalConfirm}
          operationType={operationType}
        />
      </Box>
    </>
  )
}

CancelOrders.propTypes = {
  orders: PropTypes.arrayOf(PropTypes.object),
  orderId: PropTypes.string,
  currentStep: PropTypes.number,
  isModalOpened: PropTypes.bool,
  showModal: PropTypes.func.isRequired,
  isElFijoCancellation: PropTypes.bool,
  cancelOptions: PropTypes.any,
  retainReason: PropTypes.string,
  setRetainReason: PropTypes.func.isRequired,
  retainOptions: PropTypes.array,
  onRetain: PropTypes.func.isRequired,
  email: PropTypes.object,
  setEmailField: PropTypes.func.isRequired,
  onInputEmail: PropTypes.func.isRequired,
  personalData: PropTypes.object,
  cancelReason: PropTypes.object,
  onSelectCancelReason: PropTypes.func.isRequired,
  callReason: PropTypes.string,
  onSelectCallReason: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onWizardGoBack: PropTypes.func.isRequired,
  onContinue: PropTypes.func.isRequired,
  penalties: PropTypes.object,
  operationType: PropTypes.string,
  isSaving: PropTypes.bool,
  hasError: PropTypes.bool,
  isSuccess: PropTypes.bool,
  canceledOrders: PropTypes.array,
}

CancelOrders.defaultProps = {
  orderId: '',
  retainOptions: [],
  cancelOptions: [],
}
export default CancelOrders
