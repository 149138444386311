import { get } from 'lodash'
import { createSelector } from 'reselect'

export const getIsAllowedData = state => get(state, 'cross.isAllowed', {})
export const getCompatibleDiscountsData = state => get(state, 'cross.compatibleDiscounts', {})
export const getTariffsDiscountsData = state => get(state, 'cross.tariffsDiscounts', {})

export const selectIsCrossAllowedData = createSelector(
  [getIsAllowedData],
  isAllowedData => isAllowedData?.data || null,
)
export const selectIsCrossAllowedLoading = createSelector(
  [getIsAllowedData],
  isAllowedData => isAllowedData?.loading,
)

export const selectIsCrossSellAllowed = createSelector(
  [selectIsCrossAllowedLoading, selectIsCrossAllowedData],
  (isLoading, data) => !isLoading && data,
)

export const selectCrossDiscounts = createSelector(
  [getCompatibleDiscountsData],
  discountsData => discountsData?.data || [],
)

export const selectCrossDiscountsLoading = createSelector(
  [getCompatibleDiscountsData],
  discountsData => discountsData?.loading,
)

export const selectCrossCompatibleDiscounts = createSelector(
  [selectCrossDiscounts],
  discounts => discounts.filter(discount => discount.compatibility === 'compatible'),
)

export const selectCrossIncompatibleDiscounts = createSelector(
  [selectCrossDiscounts],
  discounts => discounts.filter(discount => discount.compatibility === 'not-compatible'),
)

export const selectCrossReplaceableDiscounts = createSelector(
  [selectCrossDiscounts],
  discounts => discounts.filter(discount => discount.compatibility === 'replaceable'),
)

export const selectCrossTariffDiscounts = createSelector(
  [getTariffsDiscountsData],
  tarrifsDiscountsData => tarrifsDiscountsData?.data || [],
)

export const selectCrossTariffDiscountsLoading = createSelector(
  [getTariffsDiscountsData],
  tarrifsDiscountsData => tarrifsDiscountsData?.loading,
)

export const selectCrossTariffDiscountsError = createSelector(
  [getTariffsDiscountsData],
  tarrifsDiscountsData => tarrifsDiscountsData?.error,
)
