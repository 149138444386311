import { get } from 'lodash'
import { createSelector } from 'reselect'

export const getFetchPresale = state => get(state, 'presale.fetchPresale', {})

export const getSavePresale = state => get(state, 'presale.savePresale', {})

export const getFetchPresaleData = createSelector(
  [getFetchPresale],
  fetchedPresale => fetchedPresale?.data,
)

export const selectFetchPresaleIsLoading = createSelector(
  [getFetchPresale],
  fetchedPresale => fetchedPresale?.loading,
)

export const selectFetchedPresaleError = createSelector(
  [getFetchPresale],
  fetchedPresale => fetchedPresale.error,
)

export const selectFetchedPresaleId = createSelector(
  [getFetchPresaleData],
  fetchedPresaleData => fetchedPresaleData?.presaleId,
)

export const getSavePresaleData = createSelector([getSavePresale], savePresale => savePresale?.data)

export const selectIsSavingPresale = createSelector(
  [getSavePresale],
  savePresale => savePresale?.loading,
)

export const selectPresaleSavingError = createSelector(
  [getSavePresale],
  savePresale => savePresale?.error,
)

export const selectSavedPresaleId = createSelector(
  [getSavePresaleData],
  savePresaleData => savePresaleData?.id,
)
