import { put, select, take } from 'redux-saga/effects'

import { actions } from './actions'
import * as selectors from './selectors'
import { constants } from './constants'

export function* callSaga(orderStatusId, fixedStatusId) {
  yield put(actions.findOrderStatusDesc(orderStatusId, fixedStatusId))
  yield take([constants.FIND_ORDER_STATUS_DESC_FAILED, constants.FIND_ORDER_STATUS_DESC_SUCCEEDED])
  return yield select(selectors.getErrorCode)
}
