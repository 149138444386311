import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/styles'

import { Modal } from 'components/ui'
import { KairosAppointment } from '../KairosAppointment'

const useStyles = makeStyles({
  'kairos-appointment-modal': {
    zIndex: 2000,
    '& > div': {
      padding: '0',

      '& [role="contentinfo"]': {
        padding: '0',

        '& div + div': {
          margin: '0',
          height: '700px',
        },
      },
    },
  },
})

export const KairosAppointmentModal = ({ show, onClose, ...rest }) => {
  const classes = useStyles()

  return (
    <Modal className={classes['kairos-appointment-modal']} isOpen={show} onClose={onClose}>
      <KairosAppointment show old {...rest} />
    </Modal>
  )
}

KairosAppointmentModal.propTypes = {
  show: PropTypes.bool,
  data: PropTypes.shape({
    docNumber: PropTypes.string,
    order: PropTypes.string,
    hasDeviceDelivery: PropTypes.bool,
  }),
  delay: PropTypes.number,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
}
