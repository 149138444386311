import { presaleActionTypes } from '../actions'

const savePresaleInitialData = {
  data: null,
  loading: false,
  error: null,
}

const fetchPresaleInitialData = {
  data: null,
  loading: false,
  error: null,
}

export const presaleInitialState = {
  savePresale: { ...savePresaleInitialData },
  fetchPresale: { ...fetchPresaleInitialData },
}

export const presaleReducer = (state = presaleInitialState, action) => {
  const { type, payload } = action

  switch (type) {
    case presaleActionTypes.SAVE_PRESALE_DATA: {
      return { ...state, savePresale: { ...savePresaleInitialData, loading: true } }
    }
    case presaleActionTypes.SAVE_PRESALE_DATA_SUCCESS: {
      return { ...state, savePresale: { ...savePresaleInitialData, data: payload.data } }
    }
    case presaleActionTypes.SAVE_PRESALE_DATA_ERROR: {
      return { ...state, savePresale: { ...savePresaleInitialData, error: payload } }
    }
    case presaleActionTypes.FETCH_PRESALE_DATA: {
      return { ...state, fetchPresale: { ...fetchPresaleInitialData, loading: true } }
    }
    case presaleActionTypes.FETCH_PRESALE_DATA_SUCCESS: {
      return { ...state, fetchPresale: { ...fetchPresaleInitialData, data: payload.data } }
    }
    case presaleActionTypes.FETCH_PRESALE_DATA_ERROR: {
      return { ...state, fetchPresale: { ...fetchPresaleInitialData, error: payload } }
    }
    default:
      return state
  }
}
