import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import { isNil } from 'lodash'

import { TextStyles } from 'utils/text'
import { SpinnerCenter } from 'components/ui'

import { fetchContentfulResourceAction } from 'modules/contentful'
import { selectCampaignByEmailError } from 'modules/typifications/store/selectors/dataTypifications.selectors'
import {
  TypificationForm,
  TypificationNotification,
  TypificationListContainer,
  ActionsButtons,
} from 'modules/typifications/components'
import { getIsInsertionNotification } from 'modules/typifications/helpers'
import { MASCARE, AGENTS , NOT_DATA_CAMPAIGN } from 'modules/typifications/constants'
import { useNotification } from 'modules/typifications/hooks'
import {
  loginAgent,
  addDataTypifications,
  getRegisterTypifications,
  callInsertionTypifications,
  resetInsertionTypificationsState,
  selectDataTypificationContainer,
  selectExitsRegisters,
} from 'modules/typifications/store'
import { useTypificationContainerStyles } from 'modules/typifications/styles'
import { selectEnableEmailIframeTypification } from 'services/feature-flag/selectors'

export const TypificationContainer = ({ match, onClose }) => {
  const dispatch = useDispatch()
  const typificationContainerClasses = useTypificationContainerStyles()
  const permissionEmailIframe = useSelector(selectEnableEmailIframeTypification)

  const { token } = match.params
  
  const [showForm, setShowForm] = useState(false)
  const [typeMessage, typeNotification, customBody, showNotification, setShowNotification, setTypeMessage] = useNotification()
  const {
    area,
    authSuccess,
    featureFlagIframe,
    paramsLoginAgent,
    pageLoading,
    isSecurizedToken,
    customerId,
    isValidForm,
    channel,
    campaignByEmail
  } = useSelector(selectDataTypificationContainer)
  const { registerIsSuccess, existsRegisters } = useSelector(selectExitsRegisters)

  const isMasCareChannel = channel === MASCARE
  const enableDataScreen =
    ((!isSecurizedToken && authSuccess) || isSecurizedToken) &&
    featureFlagIframe &&
    registerIsSuccess
  const isInsertionNotification = getIsInsertionNotification(typeMessage)
  const isCampaignByEmailError = useSelector(selectCampaignByEmailError)
  const propsTypificationNotification = {
    typeMessage,
    typeNotification,
    customBody,
  }
  
  const changeScreen = () => {
    dispatch(resetInsertionTypificationsState())
    setShowForm(prevShowForm => !prevShowForm || isMasCareChannel)
  }

  const renderComponents = () => {
    if (!pageLoading) {
      if (showNotification) {
        return <TypificationNotification {...propsTypificationNotification} />
      }
      if (enableDataScreen) {
        return showForm ? <TypificationForm /> : <TypificationListContainer />
      }
    }
    return <SpinnerCenter />
  }

  const renderActionsButtons = () => {
    let actionsButtons = []

    if (!pageLoading) {
      if (!showNotification) {
        if (!showForm) {
          actionsButtons = [
            {
              name: 'Salir',
              action: onClose,
              isExitButton: true,
            },
            {
              name: 'Nueva tipificación',
              action: changeScreen,
              isExitButton: false,
            },
          ]
        }
        if (showForm) {
          actionsButtons = [
            {
              name: 'Enviar',
              action: () => dispatch(callInsertionTypifications()),
              disabled: isValidForm,
              isExitButton: false,
            },
          ]
          if(isMasCareChannel) {
            actionsButtons = [
              {
                name: 'Salir',
                action: onClose,
                isExitButton: false,
              },
              ...actionsButtons,
            ]
          } else {
            actionsButtons = [
              {
                name: 'Volver al registro',
                action: changeScreen,
                isExitButton: false,
              },
             ...actionsButtons,
            ]
          }
        }
      }

      if (showNotification && isInsertionNotification) {
        actionsButtons = [
          {
            name: 'Salir',
            action: onClose,
            isExitButton: true,
          },
        ]
        if(isMasCareChannel) {
          actionsButtons = [
            ...actionsButtons,
            {
              name: 'Nueva tipificación',
              action: changeScreen,
              isExitButton: false,
            },
          ]
        } else {
          actionsButtons = [
            ...actionsButtons,
            {
              name: 'Volver al registro',
              action: changeScreen,
              isExitButton: false,
            },
          ]
        }
      }

      if (!isMasCareChannel && showNotification && typeMessage === NOT_DATA_CAMPAIGN) {
        actionsButtons = [
          {
            name: 'Salir',
            action: onClose,
            isExitButton: true,
          },
          {
            name: 'Volver al registro',
              action: changeScreen,
              isExitButton: false,
          },
        ]
      }
    }

    return <ActionsButtons buttons={actionsButtons} />
  }

  useEffect(() => {
    dispatch(addDataTypifications(token))
  }, [token])

  useEffect(() => {
    setShowForm(isMasCareChannel)
  }, [channel])

  useEffect(() => {
    if (paramsLoginAgent) {
      dispatch(loginAgent(paramsLoginAgent))
    }
  }, [paramsLoginAgent])

  useEffect(() => {
    const agentsArea = permissionEmailIframe && channel === AGENTS && !isNil(campaignByEmail)

    if (customerId && !showForm) {
      dispatch(getRegisterTypifications(customerId, channel, campaignByEmail))
    }

    if (area && showForm) {
      dispatch(
        fetchContentfulResourceAction('typificationIframeOperator', {
          spaceName: 'cvm',
          area : agentsArea ? campaignByEmail : area
        }),
      )

      dispatch(
        fetchContentfulResourceAction('typificationIframeCampaign', {
          spaceName: 'cvm',
          area : agentsArea ? campaignByEmail : area
        }),
      )
    }

    if(!isMasCareChannel && (isCampaignByEmailError || typeMessage === NOT_DATA_CAMPAIGN)) {
      if(showForm) {
        setTypeMessage(NOT_DATA_CAMPAIGN)
      } else {
        setTypeMessage(null)
        setShowNotification(false)
      }
    }
  }, [customerId, area, showForm])

  useEffect(() => {
    if (registerIsSuccess && !existsRegisters) {
      changeScreen()
    }
  }, [registerIsSuccess, existsRegisters])

  return (
    <div className={typificationContainerClasses.root}>
      <div className="principal-container">
        <div className="header">
          <Typography {...TextStyles.title1BoldDark()}>
            {showForm ? 'Nueva Tipificación' : 'Tipificaciones del cliente'}
          </Typography>
        </div>
        <div className="data-container">{renderComponents()}</div>
        {renderActionsButtons()}
      </div>
    </div>
  )
}

TypificationContainer.propTypes = {
  match: PropTypes.object,
  onClose: PropTypes.func,
}
