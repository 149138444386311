import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import { TextStyles } from 'utils/text'

import { Modal, ModalActions, Button } from 'components/ui'

const CancelSalesProcessDialog = ({ isOpen, onClose, onCancelSalesProcess }) => (
  <Modal title="CANCELAR PROCESO DE VENTA" isOpen={isOpen} onClose={onClose}>
    <Typography {...TextStyles.paragraphDark()}>
      Vas a cancelar la venta y volver a la pantalla de creación de cliente. Todo el proceso
      realizado sobre este cliente se perderá.{' '}
      <span className="bold">¿Estás seguro de que quieres cancelar el proceso?</span>
    </Typography>

    <ModalActions>
      <Button onClick={onClose}>Mejor sigo con la venta</Button>
      <Button secondary onClick={onCancelSalesProcess}>
        Estoy seguro, quiero cancelar el proceso
      </Button>
    </ModalActions>
  </Modal>
)

CancelSalesProcessDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCancelSalesProcess: PropTypes.func.isRequired,
}

export default CancelSalesProcessDialog
