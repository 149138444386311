import styled from 'styled-components'
import { get } from 'lodash'

export const ModalContent = styled.div.attrs(() => ({
  className: 'modal-content-360',
}))`
  color: ${props => get(props, 'theme.palette.global.gray_dark')};
  margin-top: 24px;
  .modal-legend {
    padding-bottom: 10px;
  }
  span {
    color: inherit;
  }
  p {
    font-weight: 300;
    strong {
      font-weight: 500;
    }
  }
  p + p {
    margin-top: 11px;
  }
  h2 {
    font-weight: 500;
  }
  p + h2 {
    margin-top: 25px;
  }
  ul {
    margin: 0 0 11px;
  }
  p,
  li {
    button {
      margin: 0 13px;
    }
  }
  p {
    button {
      &:first-child {
        margin-left: 0;
      }
    }
  }
  strong {
    font-weight: 500;
  }
`
