import { find, filter, negate, isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import { Badge } from 'modules/ui'

export const Statuses = [
  {
    id: 'OPEN',
    descES: 'ABIERTA',
    badgeType: 'validated',
  },
  {
    id: 'CLOSE',
    descES: 'CERRADO',
    badgeType: 'blocked',
  },
  {
    id: 'UNKNOWN',
    descES: '-',
    badgeType: 'info',
  },
]

export function ChatStatusBadge({ className, status, msg }) {
  const statusObj = find(Statuses, { id: status }) || Statuses.UNKNOWN
  const fullMsg = filter([statusObj.descES, msg], negate(isEmpty)).join(' ')

  return (
    <Badge className={className} strong type={statusObj.badgeType} value={{ label: fullMsg }} />
  )
}

ChatStatusBadge.propTypes = {
  className: PropTypes.string,
  status: PropTypes.string,
  msg: PropTypes.string,
}
