import * as actions from './bonuses.actions'

export const initialState = { loading: false, data: [] }

export const reducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case actions.FIND_BONUSES: {
      return { error_code: null, error_message: null, loading: true }
    }
    case actions.FIND_BONUSES_SUCCESS: {
      return { ...state, data: payload.data, loading: false }
    }
    case actions.FIND_BONUSES_ERROR: {
      return {
        error_code: payload.httpCode,
        error_message: payload.info,
        loading: false,
      }
    }

    default:
      return state
  }
}
