import * as authActions from './actions'
import { authService } from '../services/authService'

export const initialState = {
  accessToken: undefined,
  agentToken: undefined,
  avatar: undefined,
  sfid: undefined,
  uuid: undefined,
  ipAddress: null,
  error: undefined,
  // initialized to true to ensure loggedin selectors are not true at first run
  isLoading: true,
  customer: {
    loading: false,
    migrationStatus: undefined,
    accountId: undefined,
    customerToken: undefined,
    customerId: undefined,
    error: undefined,
    loaded: undefined,
  },
}

export function reducer(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case authActions.AUTH_LOGIN: {
      return {
        ...initialState,
        ipAddress: state.ipAddress,
        sfid: payload.username,
        // TODO: kill it with fire (uuid)
        uuid: payload.password,
        isLoading: true,
      }
    }

    case authActions.AUTH_SUCCESS: {
      const JWT = (payload.accessToken && authService.parseJwt(payload.accessToken)) || {}
      const appMeta = JWT.app_metadata || {}
      const userMeta = JWT.user_metadata || {}
      return {
        ...state,
        ...payload,
        ...userMeta,
        appMeta,
        // TODO: temporal mock
        avatar: `https://api.adorable.io/avatars/36/${state.sfid}`,
      }
    }

    case authActions.AUTH_ERROR: {
      return {
        ...initialState,
        error: payload,
      }
    }

    case authActions.AUTH_SAVE_ACCESS_TOKEN: {
      return {
        ...state,
        accessToken: payload,
      }
    }

    case authActions.AUTH_LOGOUT: {
      return {
        ...initialState,
        isLoading: false,
      }
    }

    case authActions.AUTH_CUSTOMER_INIT: {
      return {
        ...state,
        customer: {
          ...initialState.customer,
          documentId: payload.documentId,
          customerId: payload.customerId,
          loading: true,
        },
      }
    }

    case authActions.AUTH_CUSTOMER_SUCCESS: {
      return {
        ...state,
        customer: { ...state.customer, ...payload, loading: false, loaded: true },
      }
    }

    case authActions.AUTH_CUSTOMER_ERROR: {
      return {
        ...state,
        customer: {
          ...initialState.customer,
          loading: false,
          error: payload,
          loaded: false
        },
      }
    }

    case authActions.AUTH_FINISH: {
      return {
        ...state,
        isLoading: false,
      }
    }

    case authActions.AUTH_RESET_DOCUMENT_ID: {
      return {
        ...state,
        customer: {
          ...state.customer,
          documentId: undefined,
        },
      }
    }

    case authActions.REQUEST_IP_ERROR: {
      return {
        ...state,
        ipAddress: null,
      }
    }

    case authActions.REQUEST_IP_SUCCESS: {
      return {
        ...state,
        ipAddress: payload,
      }
    }

    default:
      return state
  }
}
