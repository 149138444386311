import { createPortal } from 'react-dom'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Snackbar, SnackbarContent, Icon } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { ICONS } from 'utils/icons'
import Dialog from '../dialog/Dialog'
import { ButtonLink } from '../buttons/ButtonLink'

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  transform: translateZ(0);
  background-color: rgba(0, 0, 0, ${({ overlay }) => (overlay ? '0.4' : '0')});
  display: ${({ minimized }) => (minimized ? 'none' : 'inherit')};
`
const DialogHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1em;
  border-bottom: ${({ title }) => (title ? '1px solid #d8d8d8' : 'none')};
`
const ModalTitle = styled.h2`
  margin: 0;
  font-weight: normal;
  font-size: 24px;
  line-height: 1em;
  color: #4d4e4f;
  white-space: nowrap;
`
const ModalSubtitle = styled.span`
  align-self: flex-end;
  margin: 0;
  margin-left: 1em;
  font-weight: normal;
  font-size: 16px;
  color: #4d4e4f;
  line-height: 1em;
  white-space: nowrap;
`
const ButtonsWrapper = styled.div`
  display: flex;
  justify-self: flex-end;
  justify-content: flex-end;
  margin-left: auto;
  min-width: 100px;
`
const MinimizeBtnWrapper = styled.div`
  display: flex;
  transform: rotateZ(45deg);
  transform-origin: center;
`

const DialogContainer = styled(Dialog)`
  left: 50%;
  top: calc(50% + (64px / 2));
  max-height: calc(95% - 64px);
`

// SnackBar styles
const MaximizeBtnWrapper = styled.div`
  transform: rotateZ(45deg);
  transform-origin: center calc(50% + 6px);
`
const snackStyles = () => ({
  root: {
    borderRadius: '8.5px',
    backgroundColor: '#ae3f97',
  },
})
const StyledSnackbarContent = withStyles(snackStyles)(SnackbarContent)

const MinimizableModal = ({
  isOpen,
  children,
  title,
  minimizedTitle,
  onClose,
  width,
  overlay,
  type,
  subtitle,
  minimized,
  minimize,
  maximize,
  onBack,
}) =>
  !isOpen
    ? null
    : createPortal(
      <div>
        <Overlay onClick={onClose} overlay={overlay} minimized={minimized}>
          <DialogContainer onClose={null} width={width} type={type} disableBackdropClick>
            <DialogHeader title={title}>
              {onBack && (
              <ButtonLink onClick={onBack}>
                <Icon className="icon">{ICONS.left}</Icon>
              </ButtonLink>
                )}
              {title && <ModalTitle>{title}</ModalTitle>}
              {subtitle && <ModalSubtitle>{subtitle}</ModalSubtitle>}
              <ButtonsWrapper>
                <MinimizeBtnWrapper className="minimize">
                  <ButtonLink onClick={minimize} iconType="minimize" type="tertiary" />
                </MinimizeBtnWrapper>
              </ButtonsWrapper>
            </DialogHeader>
            {children}
          </DialogContainer>
        </Overlay>
        <Snackbar
          anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
          open={minimized}>
          <StyledSnackbarContent
            aria-describedby="message-id"
            message={
                (minimizedTitle || title) && <span id="message-id">{minimizedTitle || title}</span>
              }
            action={
              <MaximizeBtnWrapper>
                <ButtonLink onClick={maximize} iconType="maximize" type="tertiaryInverted" />
              </MaximizeBtnWrapper>
              }
            />
        </Snackbar>
      </div>,
        document.body,
      )

MinimizableModal.defaultProps = {
  overlay: true,
  minimized: false,
  minimizedTitle: null,
  width: 'auto',
}

MinimizableModal.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  minimizedTitle: PropTypes.string,
  children: PropTypes.node,
  onClose: PropTypes.func,
  width: PropTypes.string,
  overlay: PropTypes.bool,
  type: PropTypes.oneOf(['default', 'alert', 'info', 'success']),
  minimized: PropTypes.bool,
  minimize: PropTypes.func,
  maximize: PropTypes.func,
  onBack: PropTypes.func,
}

export default MinimizableModal
