import { createStructuredSelector } from 'reselect'
import { selectMainMobileTariffsIsLoading } from 'modules/tariffs/store'
import { selectIsCrossSellQueryParam, selectIsMobileOnly } from 'modules/Router/store/index'
import { selectSubscriptionProductNumber } from 'services/customer-360/selectors'
import {
  selectSaleTax,
  selectFormatedMobileTariffs,
  selectMobileTariffsErrorType,
  selectIsElFijo,
  selectIsPro,
  selectIsPosSale,
} from '../../../store'

export const storeSelectors = createStructuredSelector({
  tax: selectSaleTax,
  isMobileOnly: selectIsMobileOnly,
  tariffs: selectFormatedMobileTariffs,
  isLoading: selectMainMobileTariffsIsLoading,
  errorType: selectMobileTariffsErrorType,
  isElFijo: selectIsElFijo,
  isPro: selectIsPro,
  isPos: selectIsPosSale,
  isCrossSell: selectIsCrossSellQueryParam,
  subscriptionProductNumber: selectSubscriptionProductNumber,
})
