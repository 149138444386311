import { mbToGb, DEFAULT_NO_VALUE_STR, formatMinutes, formatGB } from 'utils'

export function formatRemainingMb(totalUsage, totalAmount, hasUnlimited) {
  const remaining = Math.max(totalAmount - totalUsage, 0)

  if (totalAmount === 0) return DEFAULT_NO_VALUE_STR
  return hasUnlimited ? 'Infinitos gigas' : formatGB(mbToGb(remaining), 0, true)
}

export function formatRemainingMin(totalUsage, totalAmount, hasUnlimited) {
  const remaining = Math.max(totalAmount - totalUsage, 0)

  if (totalAmount === 0) return DEFAULT_NO_VALUE_STR
  return hasUnlimited ? '♾️ MIN' : formatMinutes(remaining, 0)
}

export function formatRangeStartMb(amount) {
  return formatGB(mbToGb(amount), 0, true)
}

export function formatRangeEndMb(totalAmount, hasUnlimited) {
  return hasUnlimited ? '♾️ GB' : formatGB(mbToGb(totalAmount), 0, true)
}

export function formatRangeStartMin(amount) {
  return formatMinutes(amount, 0)
}

export function formatRangeEndMin(totalAmount, hasUnlimited) {
  return hasUnlimited ? '♾️ MIN' : formatMinutes(totalAmount, 0)
}

export function formatConsumptionMb(totalUsage) {
  return formatGB(mbToGb(totalUsage))
}

export function formatConsumptionMin(totalUsage, hasUnlimited) {
  return hasUnlimited ? DEFAULT_NO_VALUE_STR : formatMinutes(totalUsage, 0)
}
