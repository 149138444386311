import PropTypes from 'prop-types'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'

const Row = styled.tr`
  border-bottom: 1px dashed #dddddd;
  min-height: 100px;
  padding: 5px 0;
  display: flex;
  justify-content: space-between;
`

const Column = styled.td`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: ${props => props.width || 'inherit'};
`

const Code = styled(Typography)`
  text-decoration: ${({ incompatibilityrule }) =>
    incompatibilityrule === 'Cancel' || incompatibilityrule === 'Reject' ? 'line-through' : 'none'};
  text-overflow: ellipsis;
  overflow: hidden;
`

const Message = styled(Typography)`
  display: flex;
  align-items: center;
`

const MessageContent = styled.span`
  display: flex;
  flex-direction: column;
`

const Icon = styled.span`
  font-size: 32px;
  font-weight: bold;
  color: ${props => props.color || 'black'};
  margin-right: 20px;
`

const Alert = styled.span`
  background-color: ${props => props.bgColor || '#f2d065'};
  border-radius: 4px;
  color: ${props => props.color || 'white'};
  padding: 5px;
  margin-top: 5px;
`

const getMessage = (incompatibilityRule, discountCode, discountSelected) => {
  if (incompatibilityRule === 'Cancel' || incompatibilityRule === 'Reject') {
    return (
      <Message variant="caption" align="left" data-hook={`msgIncompatibility-${discountCode}`}>
        <Icon color="#df4141">&times;</Icon>
        <MessageContent>
          El descuento {discountCode} es incompatible con {discountSelected}. Será eliminado.
          {incompatibilityRule === 'Reject' && (
            <Alert>¿Estás seguro? Se recomienda mantener el descuento {discountCode}</Alert>
          )}
        </MessageContent>
      </Message>
    )
  }
  return null
}

function DiscountItem(props) {
  const { discountCode, description, incompatibilityRule, discountSelected } = props
  return (
    <Row>
      <Column width="25%">
        <Code incompatibilityrule={incompatibilityRule} variant="body1">
          {discountCode}
        </Code>
      </Column>
      <Column width="40%">
        <Typography variant="body1" align="center" style={{ width: '100%' }}>
          {description}
        </Typography>
      </Column>
      <Column width="35%">{getMessage(incompatibilityRule, discountCode, discountSelected)}</Column>
    </Row>
  )
}

DiscountItem.propTypes = {
  discountCode: PropTypes.string,
  description: PropTypes.string,
  incompatibilityRule: PropTypes.oneOf(['Cancel', 'Reject']),
  discountSelected: PropTypes.string,
}

DiscountItem.defaultProps = {
  discountCode: '',
  description: '',
  incompatibilityRule: null,
  discountSelected: '',
}

export default DiscountItem
