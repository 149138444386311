import { call, put, takeEvery, select } from 'redux-saga/effects'
import { logError, formatErrorMessage } from 'services/logging'
import { axiosFactory } from 'modules/axios'
import { selectCustomerId } from 'modules/Auth'
import { actions } from './actions'
import { constants } from './constants'
import { checkCTCAOnFly } from './api'

export function* checkCTCAOnFlySaga() {
  try {
    const customerId = yield select(selectCustomerId)
    const orderOnFly = yield call(checkCTCAOnFly, customerId)
    yield put(actions.checkCTCAOnFlySuccess({ data: orderOnFly }))
  } catch (e) {
    yield call(logError, new Error(formatErrorMessage(e)))
    const error = axiosFactory.buildError(e)
    yield put(actions.checkCTCAOnFlyFailed(error))
  }
}

export function* watchCheckOrderCTOnFly() {
  yield takeEvery(constants.CHECK_CT_CA_ONFLY_INIT, checkCTCAOnFlySaga)
}
