/* eslint no-loop-func:0 */
import { call, put, takeEvery, select, take } from 'redux-saga/effects'
import { isGeneratorFunction } from 'utils'
import { checksObj as _checksObj } from './checks'

// Steps Manager
import { actions } from './actions'
import { constants } from './constants'

export function* checkSaga({
  payload: { checks, successStep, failedStep, params, resetErrors = true },
  mockChecks,
}) {
  try {
    const checksObj = mockChecks || _checksObj
    yield put(actions.check({ resetErrors }))
    const checkResults = []

    if (checks.length > 0) {
      let i = 0
      do {
        const checkKey = typeof checks[i] === 'object' ? checks[i].check : checks[i]
        const haveRequired = typeof checks[i] === 'object' && checks[i].required
        let allRequiredSuccess = true

        if (haveRequired) {
          let j = 0
          do {
            const found = yield call(
              [checkResults, checkResults.find],
              result => checks[i].required[j] === result.check && result.result,
            )
            allRequiredSuccess = allRequiredSuccess ? found : false
            j += 1
          } while (j < checks[i].required.length)
        }

        if (!haveRequired || allRequiredSuccess) {
          const paramsCheck = (params && params[checkKey]) || []
          if (checksObj[checkKey].type === 'asyncService') {
            yield put(checksObj[checkKey].actionInit(...paramsCheck))

            const currentCheckAction = yield take(Object.keys(checksObj[checkKey].actionsFinish))
            if (checksObj[checkKey].actionsFinish[currentCheckAction.type] === 'failed') {
              yield put(actions.checkFailed(checkKey))
              yield call([checkResults, checkResults.push], { check: checkKey, result: false })
            } else {
              let result = true
              if (checksObj[checkKey].selector && checksObj[checkKey].expectedResult) {
                const selectorResult = yield select(checksObj[checkKey].selector)
                result = selectorResult === checksObj[checkKey].expectedResult
              }
              if (!result) {
                yield put(actions.checkFailed(checkKey))
              }
              yield call([checkResults, checkResults.push], {
                check: checkKey,
                result,
              })
            }
          } else {
            const typeFunction = yield call(isGeneratorFunction, checksObj[checkKey].validator)
            let result
            if (typeFunction) result = yield* checksObj[checkKey].validator(...paramsCheck)
            else result = yield checksObj[checkKey].validator(...paramsCheck)
            yield call([checkResults, checkResults.push], { check: checkKey, result })
            if (!result) {
              yield put(actions.checkFailed(checkKey))
            }
          }
        } else {
          yield call([checkResults, checkResults.push], { check: checkKey, result: false })
        }
        i += 1
      } while (i < checks.length)
    }

    const allSuccess = yield call([checkResults, checkResults.every], check => check.result)

    if (allSuccess) {
      yield put(actions.checked(successStep))
    } else {
      yield put(actions.checked(failedStep))
    }
  } catch (e) {
    console.warn(e)
    yield put(actions.checkFailed(e))
  }
}

export function* watchCheckWizard() {
  yield takeEvery(constants.CHECK_INIT, checkSaga)
}
