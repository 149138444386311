import { compareAsc, parseISO, endOfMonth, getMonth, subDays } from 'date-fns'
import { get, chain, sortBy } from 'lodash'

export function addIsUnpaid(bills) {
  return bills.map(bill => {
    const amountPayed = bill.payments.reduce((acum, { amount }) => acum + amount, 0).toFixed(2)
    const isInTime = compareAsc(parseISO(bill.paymentDueDate), new Date()) === 1
    return { ...bill, isUnpaid: !isInTime && amountPayed < bill.amount }
  })
}

export const addIsCredited = (bills, registers) => {
  const compensations = registers?.filter(register => register.type === 'compensation')

  return bills.map(bill => {
    const exists = compensations?.some(
      compensation => compensation?.compensation?.billingId === bill.externalId,
    )

    return { ...bill, isCredited: exists }
  })
}

export const getLastMonthsBills = bills =>
  chain(bills)
    .filter(
      bill =>
        getMonth(subDays(new Date(bill.date), 1)) === getMonth(new Date()) - 1 ||
        getMonth(subDays(new Date(bill.date), 1)) === getMonth(new Date()) - 2,
    )
    .orderBy('date', 'asc')
    .value()

export const getBillsWithRowsForMsisdn = (bills, msisdn) =>
  chain(bills)
    .flatMap('subInvoicesWithRows')
    .filter(bill => bill.subInvoice.msisdn === msisdn)
    .value()

export const groupBillsByMonth = bills =>
  chain(bills)
    .groupBy(bill => endOfMonth(parseISO(get(bill, 'validFor.endDateTime'))))
    .toPairsIn()
    .map(ele => [new Date(ele[0]), ele[1]])
    .orderBy(([yearMonth]) => yearMonth, 'desc')
    .map(([yearMonth, monthBills]) => [yearMonth, sortBy(monthBills, 'id')])
    .value()

export const getLostedBillRows = (billRows, pastBillRows) =>
  chain(pastBillRows)
    .map(row => {
      if (row.total === 0) {
        return null
      }

      const sameSubInvoiceRow = billRows.find(
        subInvoiceRow => subInvoiceRow.billingItem.code === row.billingItem.code,
      )

      if (sameSubInvoiceRow) {
        return null
      }

      return {
        ...row,
        losted: true,
        total: 0,
        diffTotal: row.total * -1,
        diffTotalWithoutVat: row.totalWithoutVat * -1,
      }
    })
    .compact()
    .value()

export const getBillsWithDiff = bills =>
  bills.map((bill, index) => {
    const [billDate, subBills] = bill

    return [
      billDate,
      subBills.map((subBill, subBillIndex) => {
        const pastBill = bills[index + 1]
        let { subInvoicesWithRows } = subBill

        if (!!pastBill && subBillIndex === 0) {
          const pastSubInvoicesWithRows = pastBill[1][0].subInvoicesWithRows

          subInvoicesWithRows = chain(subBill.subInvoicesWithRows)
            .map(subInvoiceWithRows => {
              const pastSubInvoiceWithRowsForMsisdn = pastSubInvoicesWithRows.find(
                pastSubInvoiceWithRows =>
                  pastSubInvoiceWithRows.subInvoice.msisdn === subInvoiceWithRows.subInvoice.msisdn,
              )

              const rows = chain(subInvoiceWithRows.rows)
                .map(row => {
                  const pastSubInvoiceRow = !!pastSubInvoiceWithRowsForMsisdn
                    ? pastSubInvoiceWithRowsForMsisdn.rows.find(
                        pastRow => row.billingItem.code === pastRow.billingItem.code,
                      )
                    : null

                  let diffTotal = row.total
                  let diffTotalWithoutVat = row.totalWithoutVat

                  if (pastSubInvoiceRow) {
                    diffTotal -= pastSubInvoiceRow.total
                    diffTotalWithoutVat -= pastSubInvoiceRow.totalWithoutVat
                  }

                  return {
                    ...row,
                    diffTotal,
                    diffTotalWithoutVat,
                  }
                })
                .value()

              const lostedRows = !!pastSubInvoiceWithRowsForMsisdn
                ? getLostedBillRows(subInvoiceWithRows.rows, pastSubInvoiceWithRowsForMsisdn.rows)
                : []

              return {
                ...subInvoiceWithRows,
                rows,
                lostedRows,
              }
            })
            .value()
        }

        return {
          ...subBill,
          subInvoicesWithRows,
        }
      }),
    ]
  })
