import { APP_CONFIG } from 'services/app-config'
import { axiosJWT } from 'modules/axios/services/index'

import { CHANNEL_SALES } from 'modules/Coverage/constants'

const url = `${APP_CONFIG.sales_cluster}/v1/coverage`

export function fetchStreets(req) {
  return axiosJWT({
    method: 'GET',
    url: `${url}/${/\d/.test(req.search) ? 'buildings' : 'streets'}`,
    params: {
      search: req.search,
      brand: 'yoigo',
      channel: CHANNEL_SALES,
      checkId: req.checkId,
      segment: 'res',
    },
  })
}
