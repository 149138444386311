import { useDispatch } from 'react-redux'
import { getMsisdn } from 'services/subscriptions'

import { updateSubscription, clearSubscription } from '../actions'

export const useUpdateSubscriptionHandlers = subscription => {
  const dispatch = useDispatch()

  const onUpdateSubscription = data => {
    return dispatch(updateSubscription(getMsisdn(subscription), data))
  }

  const onClearSubscription = () => dispatch(clearSubscription(getMsisdn(subscription)))

  return {
    onUpdateSubscription,
    onClearSubscription,
  }
}
