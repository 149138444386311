/* eslint indent:0 */
import { get, chain, every, map, includes } from 'lodash'
import { createSelector } from 'reselect'
import { getProvinceFromCodeOrString, getCountryFromIsoCode } from 'utils'
import { parse } from 'date-fns'

const getCustomer = state => get(state, 'customerNew.data', {})

const getLoading = state => get(state, 'customerNew.loading')

const getError = state => get(state, 'customerNew.error')
const getErrorCode = state => get(state, 'customerNew.error.httpCode')
const getErrorMsg = state => get(state, 'customerNew.error.info')
const getData = state => get(state, 'customerNew.data', {})

const getFirstName = state => get(getData(state), 'firstName')
const getMidName = state => get(getData(state), 'midName')
const getLastName = state => get(getData(state), 'lastName')

const getId = state => get(getData(state), 'id')

const getFullName = createSelector(
  [getFirstName, getMidName, getLastName],
  (firstName, midName, lastName) =>
    every([!firstName, !midName, !lastName], Boolean)
      ? '-'
      : [firstName, midName, lastName].filter(Boolean).join(' '),
)

const getName = createSelector([getFirstName], firstName => (!firstName ? '-' : firstName))

const getSurname = createSelector([getMidName, getLastName], (midName, lastName) =>
  every([!midName, !lastName], Boolean) ? '-' : [midName, lastName].filter(Boolean).join(' '),
)

const getActivatedAt = createSelector([getData], data =>
  parse(get(data, 'validFor.startDateTime'), 'dd/MM/yyyy', new Date()),
)

const getNIF = createSelector([getData], data =>
  chain(data)
    .get('individualIdentification')
    .filter({ type: 'NIF' })
    .head()
    .get('identificationId')
    .value(),
)

const getIdentification = createSelector([getData], data =>
  chain(data).get('individualIdentification').filter('identificationId').get('[0]').value(),
)

const getIdentificationType = createSelector([getData], data =>
  chain(data).get('individualIdentification').filter('identificationId').get('[0].type').value(),
)

const getIdentificationId = createSelector([getData], data =>
  chain(data)
    .get('individualIdentification')
    .filter('identificationId')
    .get('[0].identificationId')
    .value(),
)

const getIdentificationStr = identification =>
  `${get(identification, 'type')}: ${get(identification, 'identificationId')}`

const getBirthday = createSelector([getData], data =>
  parse(
    chain(data).get('characteristic').filter({ name: 'birthday' }).get('[0].value').value(),
    'dd/MM/yyyy',
    new Date(),
  ),
)

const getSegment = createSelector([getData], data =>
  chain(data).get('characteristic').filter({ name: 'customer-segment' }).get('[0].value').value(),
)

const segmentOptions = {
  CONSUMER: 'Particular',
  AUTONOMO: 'Autónomo',
  COMPANY: 'Negocio',
  'SOHO-AUTONOMO': 'Autónomo',
  'SOHO-COMPANY': 'Negocio',
  'PRO-SOHO-AUTONOMO': 'PRO Autónomo',
  'PRO-SOHO-COMPANY': 'PRO Empresa',
}

const getSegmentStr = segment => get(segmentOptions, segment, 'Segmento desconocido')

const getEmail = createSelector([getData], data =>
  chain(data).get('contactMedium').filter({ type: 'Email' }).get('[0].medium.emailAddress').value(),
)

const getContactPhones = createSelector([getData], data =>
  chain(data).get('contactMedium').filter({ type: 'TelephoneNumber' }).map('medium').value(),
)

const getContactPhonesStr = phones => (phones ? map(phones, 'number').join(' / ') : '-')

const getBillingAddress = createSelector([getData], data =>
  chain(data).get('contactMedium').filter({ type: 'BillingAddress' }).get('[0].medium').value(),
)

const getBillingAddressStr = billingAddress => {
  if (billingAddress) {
    const { city, country, postCode, stateOrProvince, streetOne, streetTwo, streetNumber } =
      billingAddress
    return every(
      [!city, !country, !postCode, !stateOrProvince, !streetOne, !streetTwo, !streetNumber],
      Boolean,
    )
      ? '-'
      : [
          streetOne,
          streetNumber,
          streetTwo,
          postCode,
          city,
          getProvinceFromCodeOrString(stateOrProvince),
          getCountryFromIsoCode(country),
        ]
          .filter(Boolean)
          .join(' ')
  }
  return '-'
}

const getBillingAddressPostCode = state => get(getBillingAddress(state), 'postCode')

const getLang = createSelector([getData], data =>
  chain(data).get('characteristic').filter({ name: 'language' }).get('[0].value').value(),
)

const getCompany = createSelector([getData], data =>
  chain(data).get('characteristic').find({ name: 'company' }).get('value').value(),
)

const getIsCompany = createSelector([getSegment], data => includes(data, 'COMPANY'))

const getAuthorizedPersonDocumentId = createSelector([getData], data =>
  chain(data)
    .get('characteristic')
    .find({ name: 'authorized-person-id-number' })
    .get('value')
    .value(),
)

const getIBAN = createSelector([getData], data =>
  chain(data).get('characteristic').filter({ name: 'iban' }).get('[0].value').value(),
)

const extractPersonalData = createSelector(
  [
    getFirstName,
    getMidName,
    getLastName,
    getFullName,
    getName,
    getSurname,
    getIdentification,
    getId,
    getBirthday,
    getSegment,
    getActivatedAt,
    getEmail,
    getContactPhones,
    getBillingAddress,
    getLang,
  ],
  (
    firstName,
    midName,
    lastName,
    fullName,
    name,
    surname,
    identification,
    clientId,
    birthDay,
    segment,
    activeFrom,
    email,
    contactPhones,
    billingAddress,
    lang,
  ) => ({
    firstName,
    midName,
    lastName,
    fullName,
    name,
    surname,
    birthDay,
    identification,
    clientId,
    segment,
    segmentStr: getSegmentStr(segment),
    activeFrom,
    email,
    contactPhones,
    contactPhonesStr: getContactPhonesStr(contactPhones),
    billingAddress,
    billingAddressStr: getBillingAddressStr(billingAddress),
    lang,
  }),
)

export const selectors = {
  getFullName,
  getLoading,
  getData,
  getFirstName,
  getMidName,
  getLastName,
  getCustomer,
  getId,

  getNIF,
  getIdentificationType,
  getIdentificationId,
  getIdentificationStr,
  getErrorCode,
  getErrorMsg,
  getActivatedAt,
  getIdentification,
  getBirthday,
  getSegment,
  getEmail,
  getContactPhones,
  getContactPhonesStr,
  getBillingAddress,
  getBillingAddressStr,
  getBillingAddressPostCode,
  getLang,
  extractPersonalData,
  getName,
  getSurname,
  getIBAN,
  getCompany,
  getIsCompany,
}

export {
  getFullName,
  getLoading,
  getData,
  getFirstName,
  getMidName,
  getLastName,
  getCustomer,
  getNIF,
  getId,
  getIdentificationType,
  getIdentificationId,
  getIdentificationStr,
  getError,
  getErrorCode,
  getErrorMsg,
  getActivatedAt,
  getIdentification,
  getBirthday,
  getSegment,
  getSegmentStr,
  getEmail,
  getContactPhones,
  getContactPhonesStr,
  getBillingAddress,
  getBillingAddressStr,
  getBillingAddressPostCode,
  getLang,
  extractPersonalData,
  getName,
  getSurname,
  getCompany,
  getIsCompany,
  getAuthorizedPersonDocumentId,
  getIBAN,
}
