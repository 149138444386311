export const SEND_SMS_COMMUNICATION = 'OVID/SMS_COMMUNICATION/SEND'
export const SEND_SMS_COMMUNICATION_SUCCESS = 'OVID/SMS_COMMUNICATION/SEND_SUCCESS'
export const SEND_SMS_COMMUNICATION_FAILED = 'OVID/SMS_COMMUNICATION/SEND_FAILED'

export const sendSmsCommunication = (
  senderInfo,
  terminatingNumber,
  content,
  timeOpions = { realTimeRequired: 'true' },
) => ({
  type: SEND_SMS_COMMUNICATION,
  payload: {
    senderInfo,
    terminatingNumber,
    content,
    ...timeOpions,
  },
})

export const sendSmsCommunicationSuccess = () => ({
  type: SEND_SMS_COMMUNICATION_SUCCESS,
})

export const sendSmsCommunicationFailed = error => ({
  type: SEND_SMS_COMMUNICATION_FAILED,
  payload: error,
})
