import { get } from 'lodash'
import { GET_POND, GET_POND_SUCCESS, GET_POND_FAILED } from './pond.actions'

const initialState = {
  reached: true,
  loading: true,
  error: null,
}

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_POND: {
      return {
        ...initialState,
        loading: true,
      }
    }

    case GET_POND_SUCCESS: {
      return {
        ...state,
        reached: get(payload, 'pond.pond', true),
        loading: false,
      }
    }

    case GET_POND_FAILED: {
      return {
        ...state,
        error: payload.error,
      }
    }

    default:
      return state
  }
}
