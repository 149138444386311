import { createStructuredSelector } from 'reselect'

import { selectDocumentId, selectDocumentType, selectUUID, selectSfid } from 'modules/Auth'
import * as subscriptionsSvc from 'services/subscriptions'
import * as ticketingSvc from 'services/ticketing'
import { selectors as customer360SvcSelectors } from 'services/customer-360/selectors'

import { getTax } from 'services/taxes/selectors'

import { SVA_HOMEGO_CODE, SVA_SMARTHOME_CODE } from 'modules/offers-configuration/constants'

export const productMainContainerSelectors = (subscription, msisdn, productId, zipCode) =>
  createStructuredSelector({
    ...(subscription
      ? {
          STCErrors: state => subscriptionsSvc.getTariffChangeErrorMsg(state, msisdn),
          isTariffChangeLoading: state => subscriptionsSvc.isTariffChangeLoading(state, msisdn),
          disableBonusErrors: subscriptionsSvc.getDisableBonusError(msisdn),
          disableBonusOK: subscriptionsSvc.getDisabledBonusOK(msisdn),
          disableBonusPreviousStatus: subscriptionsSvc.getDisabledBonusPreviousStatus(msisdn),
          isDisableBonusLoading: subscriptionsSvc.isDisableBonusLoading(msisdn),
          disableSvaSmartHomeError: subscriptionsSvc.isDisableSvaError(SVA_SMARTHOME_CODE),
          disableSvaSmartHomeOk: subscriptionsSvc.isDisableSvaSuccess(SVA_SMARTHOME_CODE),
          disableSvaHomeGoError: subscriptionsSvc.isDisableSvaError(SVA_HOMEGO_CODE),
          disableSvaHomeGoOk: subscriptionsSvc.isDisableSvaSuccess(SVA_HOMEGO_CODE),
        }
      : {}),
    documentType: selectDocumentType,
    documentId: selectDocumentId,
    uuid: selectUUID,
    sfid: selectSfid,
    caseOpened: ticketingSvc.isCaseOpened,
    customerBasicInfo: createStructuredSelector({
      fullName: customer360SvcSelectors.getFullName,
      documentType: customer360SvcSelectors.getIdentificationType,
      getIdentificationId: customer360SvcSelectors.getIdentificationId,
      isCompany: customer360SvcSelectors.getIsCompany,
      getCompany: customer360SvcSelectors.getCompany,
    }),
    tax: state => getTax(state, zipCode),
    dss: state => customer360SvcSelectors.selectDSS(state, productId),
  })
