import { put, takeEvery, select, call } from 'redux-saga/effects'
import { logError, formatErrorMessage } from 'services/logging'
import { selectSfid } from 'modules/Auth/store/selectors'

import { constants } from './add-agiletv.constants'
import {
  fetchAddAgileTvAllowedSuccess,
  fetchAddAgileTvAllowedFailed,
  addAgileTvSuccess,
  addAgileTvFailed,
} from './add-agiletv.actions'
import { fetchAddAgileTvAllowed, addAgileTv } from '../services/add-agiletv.service'

export function* fetchAddAgileTvAllowedSaga({ payload }) {
  try {
    const result = yield call(fetchAddAgileTvAllowed, payload)
    if (!result.orderAllowed) {
      throw result
    }

    yield put(fetchAddAgileTvAllowedSuccess())
  } catch (e) {
    if (e.isAxiosError) {
      yield call(logError, new Error(formatErrorMessage(e)))
    }
    yield put(fetchAddAgileTvAllowedFailed(e))
  }
}

export function* watchFetchAddAgileTvAllowed() {
  yield takeEvery(constants.FETCH_ADD_AGILETV_ALLOWED, fetchAddAgileTvAllowedSaga)
}

export function* addAgileTvSaga({ payload }) {
  try {
    const salesPersonId = yield select(selectSfid)

    const submitData = {
      salesPersonId,
      ...payload,
    }

    yield call(addAgileTv, submitData)

    yield put(addAgileTvSuccess())
  } catch (e) {
    yield call(logError, new Error(formatErrorMessage(e)))
    yield put(addAgileTvFailed(e?.response?.data || e))
  }
}

export function* watchAddAgileTv() {
  yield takeEvery(constants.ADD_AGILETV, addAgileTvSaga)
}
