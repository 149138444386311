import { Terminal } from './terminal'

export const MobileLineOffer = {
  signUpType: '',
  lineNumber: '',
  newNumber: {
    value: '',
    options: [],
  },
  newFixedNumber: '',
  campaign: {},
  memberGetMemberPromo: {},
  portabilityType: '',
  iccNumber: '',
  isSameUser: 'yes',
  enabledSearchNewNumber: false,
  desiredNumber: '',
  selectedNewNumber: '',
  selectedPackages: [],
  isPortabilityDate: false,
  portabilityDate: null,
  otherUser: {
    documentType: '',
    documentId: '',
    name: '',
    surname1: '',
    surname2: '',
    companyName: '',
  },
  isNewTerminal: '',
  terminal: Terminal,
  tariff: {
    id: '',
    name: '',
    monthlyFee: 0,
    discounts: [],
  },
  enableSearchStock: false,
  operator: {
    id: '',
    tradingName: '',
  },
}
