import { useEffect, useState } from 'react'
import { isEmpty, isNil } from 'lodash'

import { DEFAULT_VALUE_SELECTOR } from 'modules/typifications/constants'
import { onChange, buildFieldOption, getNameFieldOptions } from '../helpers'

export const useFormTipisData = (msisdns, operators, campaigns, values, operatorsLoading) => {
  const [reasonSelectedObj, setReasonSelected] = useState({})
  const [resultSelectedObj, setResultSelected] = useState({})
  const [subreasonSelectedObj, setSubreasonSelected] = useState({})
  const [fieldOption, setFieldOption] = useState({
    operators: [],
    campaigns: [],
    reasons: [],
    subreasons: [],
    subreasons2: [],
    results: [],
    subresults: [],
    msisdns: msisdns ? buildFieldOption(msisdns) : [],
  })
  const { campaign, reason, result, subreason, subreason2, subresult } = values

  const onChangeField = ({ parent, itemSelected, fields }) => {
    const options = fields.reduce((acc, f) => {
      const { field, fieldOptions } = onChange(parent, itemSelected, f)

      if (f === 'reason') {
        setReasonSelected(field)
      } else if (f === 'subreason') {
        setSubreasonSelected(field)
      } else if (f === 'result') {
        setResultSelected(field)
      }
      return { ...acc, ...{ [getNameFieldOptions(f)]: fieldOptions } }
    }, {})
    setFieldOption(oldOptions => ({ ...oldOptions, ...options }))
  }

  useEffect(() => {
    if (!isEmpty(operators) && isEmpty(fieldOption.operators)) {
      setFieldOption(oldOptions => ({
        ...oldOptions,
        ...{ operators: buildFieldOption(operators) },
      }))
    }
    if (!isEmpty(campaigns) && isEmpty(fieldOption.campaigns)) {
      setFieldOption(oldOptions => ({
        ...oldOptions,
        ...{ campaigns: buildFieldOption(campaigns) },
      }))
    }
  }, [fieldOption, operators, campaigns])

  useEffect(() => {
    if (!isEmpty(campaign) && isEmpty(reason)) {
      onChangeField({
        parent: campaigns,
        itemSelected: campaign,
        fields: ['reason'],
      })
    }
  }, [campaign, reason])

  useEffect(() => {
    if(!operatorsLoading && !isNil(operatorsLoading) && isEmpty(operators)) {
      setFieldOption(oldOptions => ({
        ...oldOptions,
        ...{ operators: buildFieldOption([{ text: DEFAULT_VALUE_SELECTOR }]) },
      }))
    }
  }, [operatorsLoading]) 

  useEffect(() => {
    if (!isEmpty(reason) && isEmpty(result) && isEmpty(subreason)) {
      onChangeField({
        parent: reasonSelectedObj,
        itemSelected: reason,
        fields: ['result', 'subreason'],
      })
    }
  }, [reason, result, subreason])

  useEffect(() => {
    if (!isEmpty(subreason) && isEmpty(subreason2)) {
      onChangeField({
        parent: subreasonSelectedObj,
        itemSelected: subreason,
        fields: ['subreason2'],
      })
    }
  }, [subreason, subreason2])

  useEffect(() => {
    if (!isEmpty(result) && isEmpty(subresult)) {
      onChangeField({
        parent: resultSelectedObj,
        itemSelected: result,
        fields: ['subresult'],
      })
    }
  }, [result, subresult])

  return { fieldOption, setFieldOption }
}
