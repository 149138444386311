import { get, head } from 'lodash'
import { call, takeEvery, put, select, takeLatest, take } from 'redux-saga/effects'
import { getURLSearchParams } from 'modules/url'
import { axiosJWT, axiosUUID, axiosCustomer, axiosFactory } from 'modules/axios'
import { LOCATION_CHANGE, replace } from 'connected-react-router'
import { selectURLDocumentId } from 'modules/CustomerDocument/selectors'
import * as featureFlagSvc from 'services/feature-flag'
import { setReportingUser } from 'services/logging'
import { selectEnabledLogicIpAddress } from 'services/feature-flag/selectors'

import { selectURLOrderId } from 'modules/orders/store/orders.selectors'
import { authService, fraudService } from '../services'
import {
  selectAgentToken,
  selectAccessToken,
  selectCustomerToken,
  selectCustomerId,
  selectSfid,
} from './selectors'
import { actions, AUTH_LOGOUT, REQUEST_IP } from './actions'

function* initialiseAuth(action) {
  // get data from url or storage
  const params = getURLSearchParams(action.payload.location.search || '')
  const orderId = yield select(selectURLOrderId)
  const pathName = action.payload.location.pathname
  const changeServices =
    pathName === '/clients/change-services' || pathName === `/clients/orders/${orderId}/actions`
  const documentId = yield select(selectURLDocumentId)

  let sfid = yield select(selectSfid)
  sfid = params.get('sfid') || sfid
  const uuid = params.get('uuid')

  let accessToken = yield select(selectAccessToken)
  accessToken = params.get('token') || accessToken
  let agentToken = yield select(selectAgentToken)
  const customerToken = yield select(selectCustomerToken)
  const customerId = yield select(selectCustomerId)
  if (customerToken) {
    axiosFactory.setDefaultHeaders(axiosCustomer, {
      Authorization: `Bearer ${customerToken}`,
      'X-Customer-ID': customerId,
    })
  }

  if (sfid && uuid) {
    try {
      yield put(
        actions.authLogin({
          username: sfid,
          password: uuid,
        }),
      )
      if (!params.get('token')) {
        const tokens = yield call(authService.loginAgentJWT, sfid, uuid)
        // eslint-disable-next-line prefer-destructuring
        accessToken = tokens.accessToken
        // eslint-disable-next-line prefer-destructuring
        agentToken = tokens.agentToken
      } else {
        const token = yield call(authService.loginAgent, uuid, sfid)
        // eslint-disable-next-line prefer-destructuring
        agentToken = token
      }

      yield put(
        actions.authSuccess({
          accessToken,
          agentToken,
        }),
      )
    } catch (e) {
      yield put(actions.authError(e))
      // TODO: redirect to safe place in it's saga, login?
    }
    if (documentId) {
      yield put(actions.authCustomerInit(documentId))
    }
  }

  if (changeServices) {
    axiosFactory.setDefaultHeaders(axiosUUID, {
      Authorization: `Bearer ${agentToken}`,
    })
    yield put(actions.authCustomerInit(documentId, changeServices))
  }

  // remove credentials from url if present
  if (
    ['sfid', 'uuid', 'token'].some(key => {
      return !!params.get(key)
    })
  ) {
    params.delete('sfid')
    params.delete('uuid')
    params.delete('token')
    yield put(replace(`${action.payload.location.pathname}?${params.toString()}`))
  }

  // initialize axios instances if credentials present

  if (sfid) {
    axiosFactory.setCommonDefaultHeaders({
      'X-SFID': sfid,
    })
  }

  if (accessToken) {
    axiosFactory.setDefaultHeaders(axiosJWT, {
      Authorization: `Bearer ${accessToken}`,
    })

    if (uuid) {
      yield put(featureFlagSvc.actions.getFlags())

      yield take([featureFlagSvc.constants.FLAGS_SUCCESS, featureFlagSvc.constants.FLAGS_FAILED])
    }
  }

  if (agentToken) {
    axiosFactory.setDefaultHeaders(axiosUUID, {
      Authorization: `Bearer ${agentToken}`,
    })
  }

  if (sfid) {
    setReportingUser(sfid)
  }
  yield put(actions.authFinish())
}

export function* watchAuthLocationChange() {
  yield takeEvery(action => {
    const params = getURLSearchParams(get(action, 'payload.location.search', '') || '')
    // Check login every first render with or withour auth query params
    // * if auth query param present, will login
    // * if auth query param not present, will rehydrate from storage if present
    // Login any explicit change location (push/pop) with auth query param
    return (
      action.type === LOCATION_CHANGE &&
      action.payload.action !== 'REPLACE' &&
      (get(action, 'payload.isFirstRendering', true) ||
        (params.get('sfid') && params.get('uuid')) ||
        params.get('token'))
    )
  }, initialiseAuth)
}

function logout() {
  authService.logout()
}

export function* watchAuthLogout() {
  yield takeEvery(AUTH_LOGOUT, logout)
}

function* getIpAddressSaga() {
  try {
    let result

    try {
      result = yield call(fraudService.fetchClientIpAddressFromExternalApi)
    } catch {
      result = yield call(fraudService.fetchClientIpAddress)
    }

    let ipAddress = get(result, 'data.ip')
    const enabledLogicIpAddress = yield select(selectEnabledLogicIpAddress)

    if (enabledLogicIpAddress) {
      ipAddress = head(ipAddress.split(','))
    }

    yield put(actions.requestIpAddressSuccess(ipAddress))
  } catch (err) {
    yield put(actions.requestIpAddressError(err))
  }
}

export function* watchRequestIpAddressSaga() {
  yield takeLatest(REQUEST_IP, getIpAddressSaga)
}
