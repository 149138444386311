import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { onResetTerminal } from 'modules/NewClientSales/hooks/Terminals/resetTerminal'
import { Checkbox, FormControlLabel } from '@material-ui/core'
import {
  DEVICE_SET_TOP_BOX,
  DEVICE_CATEGORIES_NAMES,
} from 'modules/NewClientSales/constants/devicesCategory'

import { selectSVAAppleTvWithPsId } from 'modules/NewClientSales/store/selectors'

export function SVAAppleTv({ svaMainPsid, onAppleTvSelected }) {
  const { resetDeviceMainMobileLine } = onResetTerminal()

  const isAppleTv = useSelector(selectSVAAppleTvWithPsId(svaMainPsid))

  function onCheckAppleTV(valueAppleTv) {
    onAppleTvSelected(valueAppleTv)

    resetDeviceMainMobileLine()
  }

  return (
    <div className="p-b-20">
      <FormControlLabel
        className="label"
        control={
          <Checkbox
            data-hook="sva-appletv-checkbox"
            color="primary"
            className="checkbox"
            checked={isAppleTv}
            onChange={() => onCheckAppleTV(!isAppleTv)}
          />
        }
        label={`Añadir ${DEVICE_CATEGORIES_NAMES[DEVICE_SET_TOP_BOX]}`}
      />
    </div>
  )
}

SVAAppleTv.propTypes = {
  svaMainPsid: PropTypes.string.isRequired,
  onAppleTvSelected: PropTypes.func.isRequired,
}
