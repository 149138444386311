import styled from 'styled-components'

const SummaryActions = styled.div`
  && {
    button + button {
      margin-top: 20px;
    }
  }
`
export default SummaryActions
