import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { OfferItem, OfferItemModified } from 'components/ui'
import {
  findFixedPermanents,
  findMobilePermanents,
  findFixedProduct,
  findMobileProduct,
} from 'services/subscriptions'

import PermanenceModal from './PermanenceModal'
import { getPermanenceOrPenaltyChanges } from '../selectors'
import { findTotalPermanent, formatPermanent, fullyFormatPermanent } from '../helpers'
import { useUpdateSubscriptionHandlers } from '../hooks/useUpdateSubscriptionHandlers'
import { CVM_FIXED_PENALTY_KEY, CVM_MOBILE_PENALTY_KEY } from '../constants'

const PermanentItem = ({ subscription, isFixed, onModalOpen }) => {
  const { onUpdateSubscription } = useUpdateSubscriptionHandlers()

  const permanenceChanges = useSelector(state =>
    getPermanenceOrPenaltyChanges(state, subscription.id, isFixed),
  )

  const permanent = isFixed ? findFixedPermanents(subscription) : findMobilePermanents(subscription)
  const product = isFixed ? findFixedProduct(subscription) : findMobileProduct(subscription)

  if (!product) {
    return null
  }

  const modified = permanenceChanges.permanenceRemoved || permanenceChanges.penaltyCondoned
  const hasTooltip = permanent.length > 1
  const offerItemValue = permanent[0] ? formatPermanent(permanent[0]) : 'No'
  const totalPermanent = findTotalPermanent(
    isFixed ? CVM_FIXED_PENALTY_KEY : CVM_MOBILE_PENALTY_KEY,
    permanent,
  )

  return modified ? (
    <OfferItemModified
      title={`Permanencia: ${formatPermanent(totalPermanent)}`}
      labels={[
        permanenceChanges.penaltyCondoned ? 'Penalización condonada' : 'Permanencia eliminada',
      ]}
      onRemove={() =>
        onUpdateSubscription({
          [isFixed ? CVM_FIXED_PENALTY_KEY : CVM_MOBILE_PENALTY_KEY]: {
            penaltyCondoned: false,
            permanenceRemoved: false,
            reason: null,
          },
        })
      }
    />
  ) : (
    <OfferItem
      label={`Permanencia ${isFixed ? 'Internet' : 'Móvil'}`}
      value={hasTooltip ? 'Varias' : offerItemValue}
      actionText="Modificar"
      hasTooltip={hasTooltip}
      placement="right"
      visible
      tooltipText={permanent.map(fullyFormatPermanent).sort()}
      multiline
      ellipsis
      actionClick={() =>
        permanent &&
        onModalOpen(
          PermanenceModal,
          {
            permanent: totalPermanent,
            onConfirm: data =>
              onUpdateSubscription({
                [isFixed ? CVM_FIXED_PENALTY_KEY : CVM_MOBILE_PENALTY_KEY]: data,
              }),
          },
          'Modificar permanencia',
        )
      }
      disabled={permanent.length === 0}
    />
  )
}

PermanentItem.propTypes = {
  subscription: PropTypes.object.isRequired,
  isFixed: PropTypes.bool.isRequired,
  onModalOpen: PropTypes.func,
}

export default PermanentItem
