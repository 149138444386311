import { memo, useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import { isEqual } from 'lodash'
import { makeStyles } from '@material-ui/styles'
import { Box } from '@material-ui/core'

import { usePreventReload, usePreventBackwards } from 'modules/ui/hooks'
import { LayoutComponent, SubHeader, ButtonCard } from 'components/ui'
import { SidebarContainer } from 'modules/device-renewal/containers/SidebarContainer/SidebarContainer'
import { HeaderContainer } from 'modules/device-renewal/containers/HeaderContainer'
import { DeviceTypeContainer } from '../../DeviceTypeContainer'
import { DeviceOffersContainer } from '../../DeviceOffersContainer'
import { DeviceSummaryContainer } from '../../DeviceSummaryContainer'
import { BillingContainer } from '../../BillingContainer'
import { SummaryContainer } from '../../SummaryContainer'

import {
  DEVICE_TYPE_SELECTION,
  DEVICE_OFFERS,
  DEVICE_SUMMARY,
  BILLING,
  SUMMARY,
} from '../../../constants'

const useStyles = makeStyles(mtheme => ({
  'device-renewal-step': {
    width: '100%',
    maxWidth: '1260px',
    margin: '0 auto',
    overflow: 'auto',

    '& .card-title': {
      color: mtheme.palette.brand.medium,
      fontWeight: 'bold',
      '&.small': {
        fontSize: '14px',
      },
    },

    '& .flex-grow': {
      flexBasis: 'auto',
    },

    '& .label': {
      color: mtheme.palette.global.gray_dark,
      fontWeight: 500,
    },

    '& .error-label': {
      color: mtheme.palette.error.main,
      fontWeight: 500,
    },

    '& .radio-check': {
      '& + span': {
        color: '#2f2f2f' /* TODO: define new colors in stylesheet */,
        fontWeight: 300,
        lineHeight: 'normal',
      },
    },
  },
}))

export const Steps = memo(
  ({ step, basket }) => {
    const classes = useStyles()

    // const analytics = useAnalytics()
    // const analyticsTransaction = useSelector(selectTransactionForAnalytics)

    const stepsContainers = {
      [DEVICE_TYPE_SELECTION]: DeviceTypeContainer,
      [DEVICE_OFFERS]: DeviceOffersContainer,
      [DEVICE_SUMMARY]: DeviceSummaryContainer,
      [BILLING]: BillingContainer,
      [SUMMARY]: SummaryContainer,
    }

    const Container = useMemo(() => stepsContainers[step], [stepsContainers[step]])

    const onGoBack = () => {
      window.open('', '_self', '')
      window.close()
    }

    const { addPreventReload, removePreventReload } = usePreventReload()
    const { addPreventBackwards, removePreventBackwards } = usePreventBackwards()

    useEffect(() => {
      addPreventReload()

      return () => {
        removePreventReload()
      }
    }, [])

    useEffect(() => {
      addPreventBackwards()

      return () => {
        removePreventBackwards()
      }
    }, [Container])

    const StepContent = () => {
      return (
        <>
          <SubHeader title="Nuevo dispositivo" onGoBack={onGoBack}>
            {step !== SUMMARY && <ButtonCard label="Cancelar venta" onClick={onGoBack} />}
          </SubHeader>

          <div className={classes['device-renewal-step']}>
            <Box p="0 24px 40px 24px">
              <Container step={step} basket={basket} />
            </Box>
          </div>
        </>
      )
    }

    return (
      <LayoutComponent
        width="100vw"
        fullWidth
        mainContent={<StepContent />}
        headerContent={<HeaderContainer />}
        sidebarContent={<SidebarContainer stepName={step} />}
      />
    )
  },
  (prevProps, nextProps) =>
    (prevProps.step === nextProps.step &&
      (isEqual(prevProps.basket, nextProps.basket) ||
        (nextProps.step === DEVICE_OFFERS &&
          prevProps.step === DEVICE_OFFERS &&
          !!nextProps.basket.device.id &&
          !isEqual(prevProps.basket.device, nextProps.basket.device)))) ||
    (nextProps.step === DEVICE_SUMMARY &&
      prevProps.step === DEVICE_SUMMARY &&
      !!prevProps.basket.device.id &&
      !isEqual(prevProps.basket.financialData, nextProps.basket.financialData)),
)

Steps.propTypes = {
  step: PropTypes.string,
  basket: PropTypes.object,
}
