import { useSelector } from 'react-redux'
import {
  groupBy,
  get,
  last,
  find,
  keys,
  union,
  uniq,
  isEmpty,
  head,
  clone,
  some,
  remove,
} from 'lodash'
import { formatFee, formatMonthlyFee, roundTwoDecimals, formatNumber } from 'utils'
import {
  formatDateDDMMYYYY,
  formatRange,
  getLastDayOfMonthFromMonthNumber,
  getFirstDayOfMonthFromMonthNumber,
} from 'utils/formatting/dates'
import { isPrepaidTariff } from 'modules/tariffs/store-apigee/tariffs-apigee.helpers'
import { selectTariffsApigeeById } from 'modules/tariffs/store-apigee/tariffs-apigee.selectors'
import {
  getSubscriptions,
  getFinancialTerminals,
  getTerminalsFee,
  DUO_PRINCIPAL_TARIFF_ID,
} from 'services/subscriptions'
import { applyTax } from 'services/taxes'

import { selectors } from 'services/customer-360/selectors'
import { isProCustomer } from 'services/customer-360/helpers'
import { getTariffName } from 'modules/tariffs/helpers/tariffs.helpers'
import {
  getSubscriptionsState,
  getCompatibleCurrentDiscounts,
  getCurrentCustomerTax,
  generateFeeSummary,
} from 'modules/offers-configuration/selectors'

import { constants as customer360Constants } from 'services/customer-360/constants'
import { UNLIMITED_DURATION, PRICE_DUO_PRINCIPAL } from 'modules/offers-configuration/constants'
import { formatDuration, formatGB } from '../../helpers'

import { generateRawFeeSummaryForSubscription } from '../../hooks/useFeeSummary'

import { usePromosData } from '../usePromosData'

const adjustDuration = (promo, i, promos) => {
  const lastPromo = promos[i - 1] || { duration: 0 }
  return { ...promo, duration: promo.duration - lastPromo.duration }
}

const generateCondonationDescription = ({ mobile, fixed }) => {
  const summary = []
  if (get(fixed, 'penaltyCondoned', false)) summary.push('Penalización condonada en línea fija.')
  if (get(mobile, 'penaltyCondoned', false)) summary.push('Penalización condonada en línea móvil.')
  if (get(fixed, 'permanenceRemoved', false)) summary.push('Permanencia eliminada en línea fija.')
  if (get(mobile, 'permanenceRemoved', false)) summary.push('Permanencia eliminada en línea móvil.')
  return summary.join(' ')
}

const promoToString = ({ name = '', mobile_data_amount_mb, monthlyFee, duration }) =>
  `${[
    name,
    mobile_data_amount_mb && `+ ${formatGB(mobile_data_amount_mb)}`,
    duration && `x ${formatDuration(duration)}`,
    monthlyFee && `x ${formatMonthlyFee(monthlyFee)}`,
  ]
    .filter(Boolean)
    .join(' ')}.`

const composeSummary = (promosData = [], { mobile, fixed, confirmedTariff }) => {
  const { tariffFee, monthlyFee = tariffFee } = last(promosData) || {}
  const summary = promosData.map(adjustDuration).map(promoToString).join(' Después ')
  return [
    !summary && getTariffName(confirmedTariff),
    summary,
    monthlyFee !== tariffFee &&
      `Cuota sin promo: ${formatMonthlyFee(tariffFee)} impuestos incluidos.`,
    generateCondonationDescription({ mobile, fixed }),
  ]
    .filter(Boolean)
    .join(' ')
}

const formatTotalFees = feesSubscriptions => {
  const customerSegment = useSelector(selectors.getSegment)
  const taxNeededLabel = isProCustomer(customerSegment) ? 'no incluidos' : 'incluidos'

  let totalFeeByMonth = ''

  const feesRange = feesSubscriptions
    .reduce((acc, feeSubscription) => {
      const range = feeSubscription.map(fee => fee.duration)

      return union(acc, range)
    }, [])
    .sort((a, b) => a - b)

  const discountValuesByRange = {}

  let currentRange = head(feesRange)

  feesRange.forEach(feeRange => {
    const oldRange = currentRange

    currentRange = feeRange

    feesSubscriptions.forEach(feeSubscription => {
      let monthlyFeeSubscription = feeSubscription.find(
        fee =>
          (currentRange === oldRange && fee.duration === 0) ||
          (fee.duration > oldRange && fee.duration <= currentRange) ||
          (feeSubscription.length === 1 && fee.duration === 0),
      )
      // TODO OR
      if (!monthlyFeeSubscription) {
        monthlyFeeSubscription = feeSubscription.find(
          fee => fee.duration > currentRange || fee.duration === 0,
        )
      }

      if (!discountValuesByRange[`range${currentRange}`]) {
        discountValuesByRange[`range${currentRange}`] = 0
      }

      discountValuesByRange[`range${currentRange}`] += roundTwoDecimals(
        monthlyFeeSubscription.monthlyFee,
      )
    })
  })

  remove(feesRange, feeRange => feeRange === UNLIMITED_DURATION)

  if (feesRange.length === 1) {
    totalFeeByMonth = `${formatFee(discountValuesByRange.range0)} impuestos ${taxNeededLabel}.`
  } else {
    let oldRange = 0
    const secondRange = feesRange[1]

    const feesRangeWithoutFirst = clone(feesRange)
    feesRangeWithoutFirst.shift()

    feesRangeWithoutFirst.forEach(feeRange => {
      const periodInMonths = feeRange - 1
      const fee = formatFee(discountValuesByRange[`range${feeRange}`])

      if (feeRange === secondRange) {
        const textMonth = `hasta el ${formatDateDDMMYYYY(
          getLastDayOfMonthFromMonthNumber(periodInMonths),
        )}`
        totalFeeByMonth = `${fee} impuestos ${taxNeededLabel}, ${textMonth}.`
      } else {
        totalFeeByMonth += ` ${fee} impuestos ${taxNeededLabel}, del ${formatRange(
          getFirstDayOfMonthFromMonthNumber(oldRange),
          getLastDayOfMonthFromMonthNumber(periodInMonths),
        )}.`
      }
      oldRange = feeRange
    })

    totalFeeByMonth += ` Después, ${formatFee(
      discountValuesByRange.range0,
    )} impuestos ${taxNeededLabel}.`
  }

  return totalFeeByMonth
}

const formatDiscountPermanency = discountPermanencies =>
  isEmpty(discountPermanencies)
    ? ''
    : ` Con una permanencia de ${head(discountPermanencies).duration} meses.`

const composeSummaryForAllSubscriptions = (
  tariffsName,
  financialTerminals,
  fullName,
  fees,
  feeFinancialTerminals,
  discountPermanencies,
  modifiedMainLine,
  tax,
  taxNeeded,
  existDuoPrincipalNewTariff,
) => {
  let message = ''
  const feesFormated = formatTotalFees(fees)

  const permanencyFormated = formatDiscountPermanency(discountPermanencies)

  const newTariffDuo = formatNumber(
    (taxNeeded && applyTax(tax, PRICE_DUO_PRINCIPAL)) || PRICE_DUO_PRINCIPAL,
  )

  const duoPrincipalMessage = existDuoPrincipalNewTariff
    ? `El precio de tu linea Dúo costará ${newTariffDuo}€ impuestos  ${
        !taxNeeded ? 'no' : ''
      } incluidos.`
    : ''

  const financialTerminalsMessage = isEmpty(financialTerminals)
    ? 'Estos importes no incluyen cuotas de posibles dispositivos financiados que pudieras tener con Yoigo.'
    : `A estos precios les debes sumar los ${feeFinancialTerminals}€ de la cuota de los dispositivos financiados que tienes.`

  message += `Sr./Sra. ${fullName}, a continuación te mostramos la oferta de Yoigo aceptada por ti: ${tariffsName}. Total oferta: ${feesFormated}${permanencyFormated} ${financialTerminalsMessage} ${duoPrincipalMessage} Gracias por seguir confiando en Yoigo.`

  return message
}

const getNamesWithCount = productNames =>
  productNames.map(product => {
    const totalProducts = productNames.filter(filteredProduct => filteredProduct === product).length

    return totalProducts > 1 ? `${product} (${totalProducts})` : product
  })

const getTariffNameSubscriptions = (subscriptions, modifications) => {
  const subscriptionTariffNames = subscriptions.reduce((acc, subscription) => {
    const modification = modifications[subscription?.id]
    const agileTvName = get(modification, 'svaAgileTv.contractTypeName')

    let summary

    if (modification?.confirmedTariff) {
      summary = modification.confirmedTariff.commercial_name
    } else {
      summary = subscription.description
    }

    if (agileTvName) {
      summary += ` + ${agileTvName}`
    }

    acc.push(summary)

    return acc
  }, [])

  const subscriptionTariffNamesWithCount = getNamesWithCount(subscriptionTariffNames)

  const tariffNames = uniq(subscriptionTariffNamesWithCount).join(', ')

  return tariffNames
}

const getFinancialTerminalsSubscriptions = subscriptions => {
  const financialTerminalsNames = subscriptions.reduce((acc, subscription) => {
    const financialTerminals = !!subscription && getFinancialTerminals(subscription)

    if (financialTerminals) {
      const terminalNames = financialTerminals.map(({ item }) => item.name)
      acc.push(...terminalNames)
    }

    return acc
  }, [])

  const financialTerminalsNamesWithCount = getNamesWithCount(financialTerminalsNames)

  const terminalNames = uniq(financialTerminalsNamesWithCount).join(', ')

  return terminalNames
}

const getFeeFinancialTerminalsSubscriptions = subscriptions =>
  subscriptions.reduce((acc, subscription) => acc + getTerminalsFee(subscription), 0)

const existStc = (modification, modificationId) =>
  !isEmpty(get(modification[modificationId], 'confirmedTariff'))

const getSubscriptionsContentDuoPrincipal = subscriptions =>
  subscriptions.some(subscription => subscription.type === DUO_PRINCIPAL_TARIFF_ID)

const stcMainLine = (modifications, subscriptions) =>
  Object.keys(modifications).some(subscriptionId => {
    const subscriptionInfo = subscriptions.find(
      subscription => get(subscription, 'id') === subscriptionId,
    )
    return (
      get(subscriptionInfo, 'multisimType') === customer360Constants.MAIN_LINE &&
      getSubscriptionsContentDuoPrincipal(subscriptions) &&
      existStc(modifications, subscriptionId)
    )
  })
const formatRawFeeSummary = modifiedSubscriptionFee =>
  modifiedSubscriptionFee.map(({ rawFeeSummary, subscriptionId }) =>
    rawFeeSummary.map(({ duration, monthlyFee, savings, totalFeeMainDuo }) => ({
      subscriptionId,
      duration,
      monthlyFee: totalFeeMainDuo || monthlyFee,
      savings,
    })),
  )

const hasModificationsInSubscriptions = modifications => {
  let hasModifications = false

  keys(modifications).forEach(id => {
    const modification = modifications[id]

    if (
      !isEmpty(modification.confirmedBonuses) ||
      !isEmpty(modification.confirmedDiscounts) ||
      !isEmpty(modification.confirmedTariff)
    ) {
      hasModifications = true
    }
  })

  return hasModifications
}

const hasSubscriptions = subscriptions => !!head(subscriptions)

const getFeesSubscriptions = (subscriptions, modifications, storeState, tax) =>
  subscriptions
    .filter(subscription => !keys(modifications).includes(subscription?.id))
    .reduce((acc, subscription) => {
      const compatibleCurrentDiscounts = getCompatibleCurrentDiscounts(storeState, subscription, {})

      const rawFeeSummary = generateFeeSummary(storeState, {
        subscription,
        compatibleCurrentDiscounts,
        tax,
        withoutTerminalFee: true,
      })

      return [...acc, { ...rawFeeSummary, subscriptionId: subscription?.id }]
    }, [])

const getFeesSubscriptionsModified = (subscriptions, modifications, storeState, tax, taxNeeded) =>
  subscriptions
    .filter(subscription => keys(modifications).includes(subscription?.id))
    .reduce((acc, subscription) => {
      const rawFeeSummary = generateRawFeeSummaryForSubscription(storeState, {
        subscription,
        modifications,
        tax,
        taxNeeded,
        withoutTerminalFee: true,
      })

      return [...acc, { ...rawFeeSummary, subscriptionId: subscription?.id }]
    }, [])

const getModificationsPermanencies = modifications =>
  keys(modifications).reduce((acc, id) => {
    const confirmedDiscountsModification = get(modifications[id], 'confirmedDiscounts')

    const confirmedDiscountsPermanencyModification = confirmedDiscountsModification
      .filter(discounts => !isEmpty(discounts.permanency))
      .sort((a, b) => b.duration - a.duration)

    if (confirmedDiscountsPermanencyModification) {
      acc.push(...confirmedDiscountsPermanencyModification)
    }

    return acc
  }, [])

export const useIsNotPrepaidOnlySubscriptions = () => {
  const subscriptions = useSelector(getSubscriptions)
  return subscriptions.some(subscription => {
    const tariff = useSelector(selectTariffsApigeeById(subscription.type))
    return !isPrepaidTariff(tariff)
  })
}

const isConfirmedPrepaidTariff = (modifications, subscriptionId, storeState) => {
  const confirmedTariff = get(modifications[subscriptionId], 'confirmedTariff')

  if (confirmedTariff) {
    const confirmedTariffId = get(confirmedTariff, 'id')
    const newTariff = selectTariffsApigeeById(confirmedTariffId)(storeState)

    return isPrepaidTariff(newTariff)
  }
  return false
}

const modifiedTariffIsPrepaid = (modifications, subscriptionId) =>
  Object.keys(modifications).some(key => key === subscriptionId)

const getExistDuoPrincipalNewTariff = modifications =>
  some(modifications, 'isDuoPrincipalNewTariff')

export function getFeesSummaryForSubscriptions(modifications) {
  let feeSummary

  const storeState = useSelector(state => state)
  const subscriptions = useSelector(getSubscriptions)
  const subscriptionsNoPrepaidOnly = subscriptions?.filter(subscription => {
    const tariffId = get(subscription, 'type')
    const tariff = useSelector(selectTariffsApigeeById(tariffId))
    const subscriptionId = get(subscription, 'id')
    const confirmedPrepaidTariff = isConfirmedPrepaidTariff(
      modifications,
      subscriptionId,
      storeState,
    )
    const prepaidTariff = isPrepaidTariff(tariff)

    return (
      (prepaidTariff &&
        modifiedTariffIsPrepaid(modifications, subscriptionId) &&
        !confirmedPrepaidTariff) ||
      (!prepaidTariff && !confirmedPrepaidTariff)
    )
  })

  const fullName = useSelector(selectors.getFullName)
  const mainLineWithStc = stcMainLine(modifications, subscriptions)
  const customerSegment = useSelector(selectors.getSegment)
  const taxNeeded = !isProCustomer(customerSegment)
  const tax = taxNeeded ? useSelector(getCurrentCustomerTax) : 0
  const existDuoPrincipalNewTariff = getExistDuoPrincipalNewTariff(modifications)

  if (
    !hasModificationsInSubscriptions(modifications) ||
    !hasSubscriptions(subscriptionsNoPrepaidOnly)
  ) {
    return null
  }

  const modifiedAndNotSubscriptionsTariffName = getTariffNameSubscriptions(
    subscriptionsNoPrepaidOnly,
    modifications,
  )

  let feesSubscriptionsFormatted = []

  const financialTerminals = getFinancialTerminalsSubscriptions(subscriptions)

  const feeFinancialTerminals = getFeeFinancialTerminalsSubscriptions(subscriptions)

  const feesSubscriptions = getFeesSubscriptions(
    subscriptionsNoPrepaidOnly,
    modifications,
    storeState,
    tax,
    taxNeeded,
  )
  const feesSubscriptionsModified = getFeesSubscriptionsModified(
    subscriptionsNoPrepaidOnly,
    modifications,
    storeState,
    tax,
    taxNeeded,
  )

  if (!isEmpty(feesSubscriptionsModified)) {
    feesSubscriptionsFormatted = feesSubscriptionsFormatted.concat(
      formatRawFeeSummary(feesSubscriptionsModified),
    )

    if (!isEmpty(feesSubscriptions)) {
      feesSubscriptionsFormatted = feesSubscriptionsFormatted.concat(
        formatRawFeeSummary(feesSubscriptions),
      )
    }
  }

  let modificationsPermanencies = getModificationsPermanencies(modifications)

  if (!isEmpty(modificationsPermanencies)) {
    modificationsPermanencies = modificationsPermanencies.sort((a, b) => b.duration - a.duration)
  }

  if (!isEmpty(feesSubscriptionsFormatted)) {
    feeSummary = composeSummaryForAllSubscriptions(
      modifiedAndNotSubscriptionsTariffName,
      financialTerminals,
      fullName,
      feesSubscriptionsFormatted,
      feeFinancialTerminals,
      modificationsPermanencies,
      mainLineWithStc,
      tax,
      taxNeeded,
      existDuoPrincipalNewTariff,
    )
  }

  return feeSummary
}

export const useModifiedSubscriptions = () => {
  const subscriptions = useSelector(getSubscriptions)
  const modifications = useSelector(getSubscriptionsState)

  const promosData = usePromosData()
  const promosDataById = groupBy(promosData, 'id')

  const modifiedSubscriptions = Object.keys(modifications).reduce((acc, id) => {
    const subscription = find(subscriptions, { id })
    const summary = composeSummary(promosDataById[id], modifications[id])

    if (summary)
      acc.push({ id: subscription.productNumber, subId: subscription.id, description: summary })

    return acc
  }, [])

  return modifiedSubscriptions
}
