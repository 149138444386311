import PropTypes from 'prop-types'

import { useSelector } from 'react-redux'
import { useFormikContext } from 'formik'
import { get } from 'lodash'
import { Card, CardContent } from '@material-ui/core'

import { Button, SpinnerButton, SpinnerCenter } from 'components/ui'
import { FinancedLegalModal } from 'modules/financing/components'
import { OfferSummaryContainer } from 'modules/NewClientSales/containers'
import { selectSummary } from 'modules/NewClientSales/store/selectors'
import { CARTERA_ERROR } from 'modules/SharedSales/constants'
import { getMaskedIbanNumber } from 'modules/Iban'
import { BackButton } from '../Common/BackButton'
import { AddressSummary } from './AddressSummary'
import { BillingSummary } from './BillingSummary'
import { ContactSummary } from './ContactSummary'
import { ErrorScoringModal, LegalDialog, PartialScoringModal } from './Modals'
import { PersonalDataSummary } from './PersonalDataSummary'
import { StyledTerminalsPaymentDialog } from './TerminalsPaymentDialog'
import { ErrorModal, QuitButton } from '../Common'

export function Summary({
  isErrorScoringModalOpen,
  isPartialScoringModalOpen,
  isFinancedLegalModalOpen,
  isLegalModalOpen,
  isTpvDialogOpen,
  onErrorScoringClose,
  onSetPartialScoringModal,
  onFinancedLegalConfirm,
  onLegalConfirm,
  onSetTerminalPaymentFinished,
  sale,
  updateAccountData,
  onSetTPVDialog,
  onConfirm,
  hasDeliveryAddress,
  carteraUpdateError,
  onCloseModal,
  showConfirm,
  isLoading,
}) {
  const {
    account,
    isOrderCreated,
    isOrderLoading,
    terminalsAddress,
    TPVTerminals,
    legalData,
    installationFullAddress,
    shippingFullAddress,
    isMobileOnly,
    permanencyTerms,
    billingFullAddress,
    scoringMessage,
    isElFijo,
    isPos,
    isPro,
    isSubscription,
    canRetryScoring,
    shouldMaskIban,
    legalTextsNoReturnalPenaltiesValues,
    isMultiSim,
  } = useSelector(selectSummary)

  const { values } = useFormikContext()

  const { ibanNumber } = get(values, 'billing', {})

  return (
    <>
      {!isOrderCreated && !isOrderLoading ? (
        <div>
          <Card className="m-b-32">
            <CardContent>
              <OfferSummaryContainer onClickChangeOfferInfo={updateAccountData} sale={sale} />
            </CardContent>
          </Card>
          <Card className="m-b-32">
            <CardContent>
              <PersonalDataSummary
                account={account}
                onClickChangePersonalData={updateAccountData}
                isMultiSim={!!isMultiSim}
              />
            </CardContent>
          </Card>
          <Card className="m-b-32">
            <CardContent>
              <ContactSummary account={account} isMultiSim={!!isMultiSim} />
            </CardContent>
          </Card>
          {!isMultiSim && (
            <Card className="m-b-32">
              <CardContent>
                <AddressSummary
                  isSubscription={isSubscription}
                  hasDeliveryAddress={hasDeliveryAddress}
                  terminalsAddress={terminalsAddress}
                  installationFullAddress={installationFullAddress}
                  shippingFullAddress={shippingFullAddress}
                  onClickChangeAddress={updateAccountData}
                  isMobileOnly={isMobileOnly}
                  isElFijo={isElFijo}
                  isPos={isPos}
                  isMultiSim={!!isMultiSim}
                />
              </CardContent>
            </Card>
          )}
          <Card className="m-b-32">
            <CardContent>
              <BillingSummary
                ibanNumber={shouldMaskIban ? getMaskedIbanNumber(ibanNumber) : ibanNumber}
                billingFullAddress={billingFullAddress}
                onClickModifyBillingData={updateAccountData}
                isMultiSim={!!isMultiSim}
              />
            </CardContent>
          </Card>
          <FinancedLegalModal
            open={isFinancedLegalModalOpen}
            fieldNames={{
              FINANCED_LEGAL_CONSENT_1: 'financedLegalConsents.consent1',
              FINANCED_LEGAL_CONSENT_2: 'financedLegalConsents.consent2',
              FINANCED_LEGAL_CONSENT_3: 'financedLegalConsents.consent3',
            }}
            onClose={onFinancedLegalConfirm}
          />
          <LegalDialog
            isSubscription={!!isSubscription}
            showConfirm={showConfirm}
            isOpen={isLegalModalOpen}
            onConfirm={onLegalConfirm}
            data={legalData}
            permanencyTerms={permanencyTerms}
            isPro={isPro}
            legalTextsNoReturnalPenaltiesValues={legalTextsNoReturnalPenaltiesValues}
          />
          <StyledTerminalsPaymentDialog
            isOpen={isTpvDialogOpen}
            onSuccess={onSetTerminalPaymentFinished}
            onClose={() => onSetTPVDialog(false)}
            terminals={TPVTerminals}
          />
          <ErrorScoringModal
            scoringMessage={scoringMessage}
            isOpen={isErrorScoringModalOpen}
            onClose={onErrorScoringClose}
          />
          <PartialScoringModal
            isOpen={isPartialScoringModalOpen}
            onClose={() => onSetPartialScoringModal(false)}
          />
          {canRetryScoring ? (
            <>
              <BackButton goTo="billing" nomargin />
              {isLoading ? (
                <SpinnerButton style={{ marginLeft: '16px' }} disabled />
              ) : (
                <Button
                  onClick={onConfirm}
                  style={{ marginLeft: '16px' }}
                  data-hook="confirm-checkout">
                  Confirmar pedido
                </Button>
              )}
            </>
          ) : (
            <QuitButton />
          )}
        </div>
      ) : (
        <SpinnerCenter />
      )}
      <ErrorModal
        hasMsError={carteraUpdateError}
        error={CARTERA_ERROR}
        onCloseModal={onCloseModal}
      />
    </>
  )
}

Summary.propTypes = {
  sale: PropTypes.object.isRequired,
  isErrorScoringModalOpen: PropTypes.bool.isRequired,
  isLegalModalOpen: PropTypes.bool.isRequired,
  isPartialScoringModalOpen: PropTypes.bool.isRequired,
  isTpvDialogOpen: PropTypes.bool.isRequired,
  updateAccountData: PropTypes.func.isRequired,
  onSetTPVDialog: PropTypes.func.isRequired,
  onErrorScoringClose: PropTypes.func.isRequired,
  onSetPartialScoringModal: PropTypes.func.isRequired,
  onLegalConfirm: PropTypes.func.isRequired,
  onSetTerminalPaymentFinished: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  hasDeliveryAddress: PropTypes.bool,
  onCloseModal: PropTypes.func,
  carteraUpdateError: PropTypes.bool,
  showConfirm: PropTypes.bool,
  isLoading: PropTypes.bool,
  isFinancedLegalModalOpen: PropTypes.bool,
  onFinancedLegalConfirm: PropTypes.func.isRequired,
}
