import PropTypes from 'prop-types'
import { HTMLTemplate } from 'modules/ui/components/HTMLTemplate/HTMLTemplate'

import { Step } from './Step'

export function NextStepsElFijo({
  installationTitle,
  installationDescription,
  teleasistTitle,
  teleasistDescription,
}) {
  return (
    <>
      <Step
        title={teleasistTitle}
        description={<HTMLTemplate html={teleasistDescription} data-hook="teleasist_elfijo" />}
      />
      <Step
        title={installationTitle}
        description={<HTMLTemplate html={installationDescription} />}
      />
    </>
  )
}

NextStepsElFijo.propTypes = {
  installationDescription: PropTypes.string.isRequired,
  installationTitle: PropTypes.string.isRequired,
  teleasistTitle: PropTypes.string.isRequired,
  teleasistDescription: PropTypes.string.isRequired,
}
