import PropTypes from 'prop-types'

import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import { segsToSegsOrMinsLiteral } from 'utils/formatting/durations'
import { get } from 'lodash'
import { format, isValid } from 'date-fns'
import { Card } from 'modules/ui'
import { VoiceCallsStatusBadge } from './VoiceCallsStatusBadge'

const useStyles = makeStyles({
  'voice-calls': {
    '& .info-card-row': {
      flexWrap: 'wrap',
      padding: '5px 0',
    },

    '& .board-modal-voice-calls': {
      display: 'flex',
      flexDirection: 'column',

      '& > div + div': {
        marginTop: '24px',
      },
    },

    '& .info-row-communication-labels': {
      display: 'flex',
      flexDirection: 'column',
    },

    '& p': {
      fontWeight: 'normal',
    },

    '& .with-badge': {
      marginRight: '10px',
    },

    '& .card-content': {
      padding: '10px 24px 18px 24px',
    },

    '& .list-divider-child:not(:first-child)': {
      borderTop: '1px solid #ccc',
      padding: '10px 5px',
    },
  },
})

export const VoiceCalls = ({ dataVoiceCalls }) => {
  const classes = useStyles()

  const startDate = get(dataVoiceCalls, 'startDate')
  const endDate = get(dataVoiceCalls, 'endDate')
  const formatedStartDate =
    startDate && isValid(new Date(startDate)) && format(new Date(startDate), 'dd/MM/yyyy HH:mm')
  const formatedEndDate =
    endDate && isValid(new Date(endDate)) && format(new Date(endDate), 'dd/MM/yyyy HH:mm')

  const typographyProps = {
    variant: 'subtitle2',
    component: 'p',
    color: 'textSecondary',
  }

  return (
    <>
      <div className={classes['voice-calls']} data-hook="communication-detail-card">
        <Box display="flex" flexDirection="column" className="board-modal-voice-calls">
          <div className="info-card-row info-row-communication-labels">
            <Typography {...typographyProps} className="with-badge">
              {formatedStartDate} h - {formatedEndDate} h
            </Typography>
            <Typography {...typographyProps} className="with-badge">
              <span className="bold">{get(dataVoiceCalls, 'msisdn')} </span>
              {get(dataVoiceCalls, 'sfid') && (
                <>
                  <VoiceCallsStatusBadge status={get(dataVoiceCalls, 'status')} />
                </>
              )}
            </Typography>
            <Typography {...typographyProps}>
              Llamada: <span className="bold">{get(dataVoiceCalls, 'subType')}</span>
            </Typography>
            <Typography {...typographyProps}>
              Duración llamada:{' '}
              <span className="bold">
                {segsToSegsOrMinsLiteral(get(dataVoiceCalls, 'duration'))}
              </span>
            </Typography>
            <Typography {...typographyProps}>
              Origen: <span className="bold">{get(dataVoiceCalls, 'origin')}</span> &gt; Destino:
              <span className="bold"> {get(dataVoiceCalls, 'destination')}</span>
            </Typography>
            <Typography {...typographyProps}>
              UCID: <span className="bold">{get(dataVoiceCalls, 'ucid')}</span>
            </Typography>
          </div>
          {get(dataVoiceCalls, 'segments') && (
            <>
              <Card title="Traza de la llamada">
                {dataVoiceCalls.segments.map((value, i) => {
                  return (
                    <div className="info-card-row list-divider-child" key={`voice_calls_${i + 1}`}>
                      <Typography {...typographyProps}>
                        <span>{i + 1}</span>: <span className="bold"> {get(value, 'type')} </span>
                      </Typography>
                      <Typography {...typographyProps}>
                        Duración llamada:{' '}
                        <span className="bold">
                          {segsToSegsOrMinsLiteral(get(value, 'duration'))}
                        </span>
                      </Typography>
                      {get(value, 'destination') && (
                        <>
                          <Typography {...typographyProps}>
                            Destino: <span className="bold"> {get(value, 'destination')}</span>
                          </Typography>
                        </>
                      )}
                      {get(value, 'sfid') && (
                        <>
                          <Typography {...typographyProps}>
                            Agente: <span className="bold">{get(value, 'sfid')}</span>
                          </Typography>
                        </>
                      )}
                      {get(value, 'acd_group') && (
                        <>
                          <Typography {...typographyProps}>
                            Grupo ACD: <span className="bold">{get(value, 'acd_group')}</span>
                          </Typography>
                        </>
                      )}
                      {get(value, 'category') && (
                        <>
                          <Typography {...typographyProps}>
                            Categoría: <span className="bold">{get(value, 'category')}</span>
                          </Typography>
                        </>
                      )}
                      {get(value, 'transcription') && (
                        <>
                          <Typography {...typographyProps}>
                            Transcripción:{' '}
                            <span className="bold">{get(value, 'transcription')}</span>
                          </Typography>
                        </>
                      )}
                    </div>
                  )
                })}
              </Card>
            </>
          )}
        </Box>
      </div>
    </>
  )
}

VoiceCalls.propTypes = {
  dataVoiceCalls: PropTypes.object,
}
