import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { head, isNil } from 'lodash'
import { getIdentificationId, getMainSubscriptionTariffId } from 'services/customer-360/selectors'
import { selectProfileId } from 'modules/Permissions'
import { actions } from 'services/customer-360'

import { selectTariffSegment } from 'modules/tariffs/store-apigee'
import { getSegmentCustomer } from 'modules/orders/store/orders.selectors'
import { selectQueryParams } from 'modules/Router'
import { NEWTON } from 'services/global-constants/channels'
import { getTelesalesUrl } from '../helpers'

export function useAddSubscription(status) {
  const queryParams = useSelector(selectQueryParams)
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()

  const customerId = useSelector(getIdentificationId)
  const agentProfile = useSelector(selectProfileId)
  const mainSubscriptionId = useSelector(getMainSubscriptionTariffId)
  let segment

  if (!isNil(mainSubscriptionId)) {
    segment = useSelector(selectTariffSegment(mainSubscriptionId))
  } else {
    segment = useSelector(getSegmentCustomer)
  }

  function navigateToNewSubscription(sub, offerDetail = []) {
    const order = offerDetail.find(detail => head(detail.subscriptions)?.id === sub?.id)
    const orderId = order?.order_info?.order_id
    const tariffId = sub?.tariff?.id

    let url
    if (queryParams.channel === NEWTON) {
      url = getTelesalesUrl(
        customerId,
        'isSubscription',
        location,
        '/add-client/sales/info',
        agentProfile,
        segment,
      ).finalUrl
    } else {
      url = getTelesalesUrl(customerId, 'isSubscription', location, '/add-client/sales/info')
        .finalUrl
    }

    dispatch(
      actions.setSubscriptionAction({
        id: status === 'active' ? sub?.subscription_id : orderId,
        status,
        tariffId: status === 'active' ? sub?.type : tariffId,
      }),
    )
    history.push(url)
  }

  return { navigateToNewSubscription }
}
