import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import { TextField, Typography, Divider, Box, Icon, FormHelperText } from '@material-ui/core'
import {
  find,
  get,
  map,
  some,
  isEmpty,
  omit,
  head,
  size,
  isNil,
  isNaN,
  isNumber,
  sortBy,
} from 'lodash'
import { Button, ModalActions, FormSelectOutlined } from 'components/ui'
import { TextStyles } from 'utils/text'
import { ICONS } from 'utils/icons'
import { selectContentfulResource } from 'modules/contentful'
import { createTypification } from 'services/typifications'
import { sendSmsCommunication } from 'services/sms-communication/actions'
import { SMS_SENDER_INFO } from 'services/sms-communication/constants'
import {
  selectSubscriptionByProductId,
  isConvergentSubscription,
  getSubscriptionTariffId,
  isFixedNumber,
} from 'services/subscriptions'
import { getSegment, selectProductByLineId } from 'services/customer-360/selectors'
import { selectPhones } from 'modules/vista-360/selectors/selectors'
import { isProCustomer, helpers as customer360SvcHelpers } from 'services/customer-360/helpers'
import { selectProfileId, profiles } from 'modules/Permissions'
import { selectEnabledSmsTipis } from 'services/feature-flag/selectors'
import {
  SFID_MIGRATION_TYPIFICATION_ACCESS,
  SFID_UPGRADE_TYPIFICATION_ACCESS,
  CAMPAIGN_VALUE_UPDATE,
  CAMPAIGN_VALUE_MIGRATION,
  CAMPAIGN_AREA_PRO_SOHO,
  CAMPAIGN_AREA_INBOUND,
  CAMPAIGN_VALUE_ATCN,
  CAMPAIGN_VALUE_APN,
  CAMPAIGN_VALUE_RT,
  REGEX_SFIDS_ENABLED_CAMPGAIGN_RT,
  SFID_ATCN_CAMPAIGN_ACCESS,
  SFID_APN_CAMPAIGN_ACCESS,
} from 'modules/Permissions/constants'
import { MOBILE_PRODUCT_NAME } from 'services/subscriptions/constants.js'
import {
  NOT_CANCELATION_FIELDS,
  NOT_BREAKDOWN_FIELDS,
  NOT_BO_FIELDS,
  NOT_RETENTION_FIELDS,
  NOT_PRO_FIELDS,
  NOT_LOYALTY_FIELDS,
  NOT_INBOUND_LOYALTY_FIELDS,
  NOT_INBOUND_AND_UTIL_LOYALTY_FIELDS,
  NOT_ATC_FIELDS,
  BUSINESS_PROFILE_IDS,
  SUFIX_LOYALTY_FIELDS_PERMISSION,
  NOT_RETAINED_CODE,
  NOT_RETAINED_CANCELATION,
  RETAINED_CODE,
  RETAINED_CODE_CANCELATION_PRODUCT,
  RETAINED_CODE_CANCELATION_OFFERT,
  RETAINED_NO_ACTION_CODE,
  NOT_UTIL_CODE,
  GESTIONES_ADMINISTRATIVAS,
  INBOUND_REASONS,
  SUBSCRIPTIONS_TYPE_OPTIONS,
  TICKET_JIRA_OPTIONS,
  FIXED_ISSUE_OPTIONS,
  DEPENDENCIES,
  LOYALTY_DETAIL_NAME,
  RETENTION_NAME,
  INITIAL_FEE_NAME,
  FINAL_FEE_NAME,
  NA_CODES,
  NA_RETENTION_CODE,
  SMS_TYPIFICATIONS_CAMPAIGNS,
  SMS_TYPIFICATIONS_AREAS,
} from 'modules/offers-configuration/constants'
import { isSfidInArray } from 'modules/vista-360/helpers/helpers.js'
import { selectCustomerId, selectSfid } from 'modules/Auth'
import { checkProfile } from 'modules/Auth/helpers'
import { ModalContent } from './ModalContent'
import { FormContainer } from './FormContainer'

const NaOption = {
  text: 'No Aplica',
  value: NA_RETENTION_CODE,
}

let retentionOptions = [
  {
    text: 'No retenido',
    value: NOT_RETAINED_CODE,
  },
  {
    text: 'Retenido con herramienta',
    value: RETAINED_CODE,
  },
  {
    text: 'Retenido sin herramienta',
    value: RETAINED_NO_ACTION_CODE,
  },
]

const retentionOptionsCancelation = [
  {
    text: 'No retenido',
    value: NOT_RETAINED_CANCELATION,
  },
  {
    text: 'Retenido con producto',
    value: RETAINED_CODE_CANCELATION_PRODUCT,
  },
  {
    text: 'Retenido con oferta',
    value: RETAINED_CODE_CANCELATION_OFFERT,
  },
  NaOption,
]

export const TypificationsModal = styled(props => {
  const dispatch = useDispatch()

  const [initialFee, setInitialFee] = useState(null)
  const [finalFee, setFinalFee] = useState(null)
  const [initialFeeError, setInitialFeeError] = useState(false)
  const [finalFeeError, setFinalFeeError] = useState(false)

  const [fieldValues, setFieldValues] = useState({
    documentId: props.documentId,
    msisdn: '',
    subscriptionType: '',
    campaign: '',
    reason: '',
    subreason: '',
    loyaltyDetail: {},
    detail: '',
    retention: '',
    operator: '',
    description: '',
    ticketCreated: '',
    retentionDetail: '',
    initialFee,
    finalFee,
  })

  const [loyaltyValues, setLoyaltyValues] = useState([])

  const cvmCampaigns = sortBy(useSelector(selectContentfulResource('typificationsCvmCampaign')), [
    'text',
  ])
  const cvmLoyalty = sortBy(useSelector(selectContentfulResource('typificationsCvmFidelizacion')), [
    'text',
  ])
  const cvmRetention = sortBy(useSelector(selectContentfulResource('typificationsCvmRetention')), [
    'text',
  ])
  const cvmOperators = sortBy(useSelector(selectContentfulResource('typificationsCvmOperator')), [
    'text',
  ])
  const cvmNoutil = sortBy(useSelector(selectContentfulResource('typificationsCvmNoutil')), [
    'text',
  ])

  const profileId = useSelector(selectProfileId)
  const sfid = useSelector(selectSfid)
  const customerSegment = useSelector(getSegment)
  const contactPhones = useSelector(selectPhones)
  const enabledSms = useSelector(selectEnabledSmsTipis)

  const checkTypeLoyaltyProfile = type => isSfidInArray(type, sfid)

  const isCancelation = checkProfile(profiles, profileId, 'cancelation.id')
  const isRetention = checkProfile(profiles, profileId, 'retention.id')
  const isAverias = checkProfile(profiles, profileId, 'retentionBreakdown.id')
  const isBo = isAverias && fieldValues.campaign === 'BST'
  const isBoProfile = checkProfile(profiles, profileId, 'backoffice.id')
  const isAtc = checkProfile(profiles, profileId,'atc.id')
  const isAtcComercial = checkProfile(profiles, profileId,'atc_comercial.id')
  const isInboundLoyalty = checkProfile(profiles, profileId,'customer_loyalty_inbound.id')
  const isOutboundLoyalty = checkProfile(profiles, profileId,'customer_loyalty_outbound.id')
  const isLoyalty = checkProfile(profiles, profileId,'customer_loyalty.id') || isInboundLoyalty || checkProfile(profiles, profileId,'customer_loyalty_outbound.id')
  const isUpdate = checkTypeLoyaltyProfile(SFID_UPGRADE_TYPIFICATION_ACCESS)
  const isMigration = checkTypeLoyaltyProfile(SFID_MIGRATION_TYPIFICATION_ACCESS)
  const hasAtcnCampaignAccess = isSfidInArray(SFID_ATCN_CAMPAIGN_ACCESS, sfid, true)
  const hasApnCampaignAccess = isSfidInArray(SFID_APN_CAMPAIGN_ACCESS, sfid, true)
  const checkRetentionOptions = retentionOptions.find(option => option.value === NOT_UTIL_CODE)

  const hasPermissionToViewLoyaltyFields = sfid.endsWith(SUFIX_LOYALTY_FIELDS_PERMISSION)
  const isUtilReasonSelected = INBOUND_REASONS.includes(fieldValues.reason)
  const showLoyaltyFields = isLoyalty && hasPermissionToViewLoyaltyFields
  const showRetentionFields =
    !isLoyalty && !isAtc && !isBoProfile && !isAtcComercial && !hasAtcnCampaignAccess && !hasApnCampaignAccess

  let campaignProfile

  if (
    BUSINESS_PROFILE_IDS.includes(profileId) ||
    ((hasAtcnCampaignAccess || hasApnCampaignAccess) && !isProCustomer(customerSegment))
  ) {
    campaignProfile = CAMPAIGN_AREA_PRO_SOHO

    if (isEmpty(checkRetentionOptions)) {
      retentionOptions = sortBy(
        [
          ...retentionOptions,
          {
            text: 'No útiles',
            value: NOT_UTIL_CODE,
          },
        ],
        ['text'],
      )
    }
  } else if (isInboundLoyalty) {
    campaignProfile = CAMPAIGN_AREA_INBOUND
  } else if(isOutboundLoyalty) {
    campaignProfile = 'FIDELIZACION'
  } else {
    campaignProfile = profileId
  }

  const filteredCampaigns = () => {
    let filterCampaigns = cvmCampaigns.filter(campaign => campaign.area === campaignProfile)

    if (isMigration) {
      filterCampaigns = filterCampaigns.filter(
        campaign => campaign.value === CAMPAIGN_VALUE_MIGRATION,
      )
    } else if (isUpdate) {
      filterCampaigns = filterCampaigns.filter(campaign => campaign.value === CAMPAIGN_VALUE_UPDATE)
    }

    if (campaignProfile === CAMPAIGN_AREA_PRO_SOHO) {
      if (hasAtcnCampaignAccess) {
        filterCampaigns = filterCampaigns.filter(campaign => campaign.value === CAMPAIGN_VALUE_ATCN)
      } else if (hasApnCampaignAccess) {
        filterCampaigns = filterCampaigns.filter(campaign => campaign.value === CAMPAIGN_VALUE_APN)
      } else {
        filterCampaigns = filterCampaigns.filter(
          campaign =>
            campaign.value !== CAMPAIGN_VALUE_ATCN && campaign.value !== CAMPAIGN_VALUE_APN,
        )
      }
    }

    if (!REGEX_SFIDS_ENABLED_CAMPGAIGN_RT.test(sfid)) {
      filterCampaigns = filterCampaigns.filter(campaign => campaign.value !== CAMPAIGN_VALUE_RT)
    }

    return filterCampaigns
  }

  const lineVals = sortBy(
    map(props.lineNumbers, lineNumber => ({ text: lineNumber, value: lineNumber })),
    ['text'],
  )
  const onlyFixedLinesVals = sortBy(
    lineVals.filter(line => isFixedNumber(line.value)),
    ['text'],
  )
  const ticketJiraOptions = sortBy(TICKET_JIRA_OPTIONS, ['text'])
  const fixedIssueOptions = sortBy(FIXED_ISSUE_OPTIONS, ['text'])

  const [fieldOptionsValues, setFieldOptionsValues] = useState({
    linesVals: isAverias ? onlyFixedLinesVals : lineVals,
    subscriptionType: [],
    campaigns: filteredCampaigns(),
    reasons: [],
    subreasons: [],
    loyalty: [],
    loyaltyDetail: [],
    details: [],
    retentionDetails: [],
    cvmOperators,
    retentionOptions: isCancelation ? retentionOptionsCancelation : retentionOptions,
    ticketJiraOptions,
    fixedIssueOptions,
  })

  const product = useSelector(state => selectProductByLineId(state, fieldValues.msisdn))

  const customerId = useSelector(selectCustomerId)

  // TODO: Remove when the old cvm is no longer in use
  const productOldCvm = useSelector(selectSubscriptionByProductId(fieldValues.msisdn))

  const mobileContact = () =>
    get(
      contactPhones.find(phone => phone.type === MOBILE_PRODUCT_NAME),
      'number',
    )

  const [buttonDisabled, setButtonDisabled] = useState(false)
  const [campaignDisabled, setCampaignDisabled] = useState(false)

  const onConfirmDispatch = typification => {
    const { msisdn, area, campaign } = typification
    const smsMobile = isFixedNumber(msisdn) ? mobileContact() : msisdn

    dispatch(createTypification(typification))

    if (
      SMS_TYPIFICATIONS_AREAS.includes(area) &&
      SMS_TYPIFICATIONS_CAMPAIGNS.includes(campaign) &&
      enabledSms
    ) {
      dispatch(
        sendSmsCommunication(
          SMS_SENDER_INFO.Yoigo,
          smsMobile,
          'Si eres cliente y tienes alguna duda, contáctanos por WhatsApp. Solo tienes que pinchar el enlace, escribir tu consulta y marcar la opción 5 para contactar con un agente de bajas: https://wa.me/+34633996999',
        ),
      )
    }
  }

  const setDefaultCampaign = () => {
    const filteredCampaign = filteredCampaigns()

    if (filteredCampaign.length === 1) {
      const stateChanges = { campaign: head(filteredCampaign).value }
      const newFieldValues = { ...fieldValues, ...stateChanges }

      setFieldValues(newFieldValues)
    }
  }

  const checkEmptyFields = fields => {
    return (
      initialFeeError ||
      finalFeeError ||
      some(omit(fieldValues, fields), field =>
        isNumber(field) ? isNil(field) || isNaN(field) : isEmpty(field),
      )
    )
  }
  const checkEmptyDetailLoyalty = () => {
    const detailLoyaltySelectedSize = size(fieldValues.loyaltyDetail)
    const loyaltySize = size(loyaltyValues)

    return detailLoyaltySelectedSize === 0 || detailLoyaltySelectedSize !== loyaltySize
  }
  const checkDetailLoyaltyValues = () => {
    const loyaltyDetailValues = Object.values(fieldValues.loyaltyDetail)

    return !loyaltyDetailValues.some(value => !NA_CODES.includes(value))
  }
  useEffect(() => {
    if (isAverias) {
      setButtonDisabled(checkEmptyFields(NOT_BREAKDOWN_FIELDS))
    } else if (isLoyalty) {
      if (hasPermissionToViewLoyaltyFields && isUtilReasonSelected) {
        setButtonDisabled(
          checkEmptyFields(NOT_INBOUND_LOYALTY_FIELDS) ||
            checkEmptyDetailLoyalty() ||
            checkDetailLoyaltyValues(),
        )
      } else if (hasPermissionToViewLoyaltyFields && !isUtilReasonSelected) {
        setButtonDisabled(checkEmptyFields(NOT_INBOUND_AND_UTIL_LOYALTY_FIELDS))
      } else {
        setButtonDisabled(checkEmptyFields(NOT_LOYALTY_FIELDS))
      }
    } else if (isAtc || isAtcComercial || isBoProfile || hasAtcnCampaignAccess || hasApnCampaignAccess) {
      setButtonDisabled(checkEmptyFields(NOT_ATC_FIELDS))
    } else if (isRetention) {
      setButtonDisabled(checkEmptyFields(NOT_RETENTION_FIELDS))
    } else if (isCancelation) {
      setButtonDisabled(checkEmptyFields(NOT_CANCELATION_FIELDS))
    } else {
      setButtonDisabled(checkEmptyFields(NOT_PRO_FIELDS))
    }

    if (isBo) {
      setButtonDisabled(checkEmptyFields(NOT_BO_FIELDS))
    }
  }, [fieldValues])

  const setFeeError = (isInitialFee, value) =>
    isInitialFee ? setInitialFeeError(value) : setFinalFeeError(value)

  const checkFee = (fee, isInitialFee = true) => {
    const parsedFee = parseFloat(fee?.replace(',', '.'))
    const fieldValueName = isInitialFee ? INITIAL_FEE_NAME : FINAL_FEE_NAME

    if (parsedFee > 999.99) {
      setFeeError(isInitialFee, true)
    } else {
      setFeeError(isInitialFee, false)
    }
    setFieldValues({
      ...fieldValues,
      ...{
        [fieldValueName]: parsedFee,
      },
    })
  }

  useEffect(() => {
    checkFee(initialFee)
  }, [initialFee])

  useEffect(() => {
    checkFee(finalFee, false)
  }, [finalFee])

  useEffect(() => {
    setDefaultCampaign()

    if (isLoyalty && (isUpdate || isMigration)) {
      setCampaignDisabled(true)
    }
  }, [])

  const onInitialAndFinalFeeChange = e => {
    const { value, name } = e.target
    const isInitialFee = name === INITIAL_FEE_NAME
    const splitValue = value.split(/[,.]/g)

    if (splitValue[1]?.length > 2 || splitValue.length > 2) {
      const sliceValue = value.slice(0, -1)

      if (isInitialFee) {
        setInitialFee(sliceValue)
      } else {
        setFinalFee(sliceValue)
      }
    }
  }

  const onChange = (event, key = '') => {
    const field = event.target.name

    let stateChanges
    if (field === LOYALTY_DETAIL_NAME) {
      const currentLoyaltyDetail = fieldValues[field]
      currentLoyaltyDetail[key] = event.target.value

      stateChanges = { [field]: currentLoyaltyDetail }
    } else {
      stateChanges = { [field]: event.target.value }
    }

    if (DEPENDENCIES[field]) {
      DEPENDENCIES[field].forEach(f => {
        if (f === LOYALTY_DETAIL_NAME) {
          stateChanges[f] = {}
        } else {
          stateChanges[f] = ''
        }
      })
    }

    const newFieldValues = { ...fieldValues, ...stateChanges }
    setFieldValues(newFieldValues)

    let reasons = []
    let subreasons = []
    let details = []
    let retentionDetails = []
    let loyalty = []
    let loyaltyDetail = []
    let retentionOptionsModified = retentionOptions

    let current

    if (newFieldValues.campaign) {
      current = find(cvmCampaigns, { value: newFieldValues.campaign })
      reasons = sortBy(get(current, 'reasons', []), ['text'])

      if (newFieldValues.reason) {
        current = find(reasons, { value: newFieldValues.reason })
        subreasons = sortBy(get(current, 'subreasons', []), ['text'])

        if (newFieldValues.retention === NOT_RETAINED_CODE) {
          retentionDetails = sortBy(get(current, 'notRetainedOptions', []), ['text'])
        }

        if (newFieldValues.retention === NOT_UTIL_CODE) {
          retentionDetails = cvmNoutil
        }

        if (newFieldValues.subreason) {
          current = find(subreasons, { value: newFieldValues.subreason })
          details = sortBy(get(current, 'details', []), ['text'])
          if (isRetention) {
            setFieldValues(prevFieldValues => {
              return { ...prevFieldValues, detail: 'NA' }
            })
          }
        }

        if (INBOUND_REASONS.includes(newFieldValues.reason) && newFieldValues.subreason) {
          loyalty = cvmLoyalty.filter(
            f =>
              f.campaign === newFieldValues.campaign &&
              f.product.includes(newFieldValues.subscriptionType),
          )

          loyaltyDetail = loyalty.map(l => get(l, 'fidelizaciondetail', []))

          setLoyaltyValues(loyalty)
        } else {
          setLoyaltyValues([])
        }

        const valueTextReason = get(
          reasons.find(({ value }) => newFieldValues.reason === value),
          'text',
        )
        if (isRetention && valueTextReason === GESTIONES_ADMINISTRATIVAS) {
          retentionOptionsModified = retentionOptions.filter(
            option => option.value !== NOT_RETAINED_CODE,
          )
        }
      }
    }

    if (
      newFieldValues.retention === RETAINED_CODE ||
      newFieldValues.retention === RETAINED_CODE_CANCELATION_OFFERT
    ) {
      retentionDetails = cvmRetention.filter(r => r.withTool && r.area === campaignProfile)
    } else if (
      newFieldValues.retention === RETAINED_NO_ACTION_CODE ||
      newFieldValues.retention === NOT_RETAINED_CANCELATION ||
      newFieldValues.retention === RETAINED_CODE_CANCELATION_PRODUCT
    ) {
      retentionDetails = cvmRetention.filter(r => !r.withTool && r.area === campaignProfile)
    } else if (newFieldValues.retention === NA_RETENTION_CODE) {
      retentionDetails = [NaOption]
    }

    setFieldOptionsValues({
      ...fieldOptionsValues,
      ...{
        campaigns: filteredCampaigns(),
        reasons,
        subreasons,
        details,
        retentionDetails,
        loyalty,
        loyaltyDetail,
        retentionOptions: isCancelation ? retentionOptionsCancelation : retentionOptionsModified,
      },
    })
  }

  const onConfirm = e => {
    e.preventDefault()
    setButtonDisabled(true)

    let isConvergent
    let tariff
    const area = campaignProfile

    if (product) {
      isConvergent = customer360SvcHelpers.isConvergent(product)
      tariff = customer360SvcHelpers.getTariffId(product)
    } else {
      // TODO: Remove when the old cvm is no longer in use.
      isConvergent = isConvergentSubscription(productOldCvm)
      tariff = getSubscriptionTariffId(productOldCvm)
    }

    let confirmFieldValues

    if (isAverias) {
      confirmFieldValues = {
        ...fieldValues,
        ...{ retentionDetail: fieldValues.retention + fieldValues.retentionDetail },
      }
    } else if (isInboundLoyalty) {
      if (isEmpty(loyaltyValues)) {
        confirmFieldValues = {
          ...fieldValues,
        }
      } else {
        confirmFieldValues = loyaltyValues
          .map((loyaltyValue, index) => {
            const loyaltyDetailValue = fieldValues.loyaltyDetail[index]
            if (NA_CODES.includes(loyaltyDetailValue)) {
              return null
            }

            return {
              ...fieldValues,
              ...{
                retention: loyaltyValue.value,
                retentionDetail: fieldValues.loyaltyDetail[index],
              },
            }
          })
          .filter(value => !isNil(value))
      }
    } else if (isCancelation) {
      const { text } = retentionOptionsCancelation.find(
        option => fieldValues.retention === option.value,
      )

      confirmFieldValues = {
        ...fieldValues,
        ...{ retention: text },
      }
    } else {
      confirmFieldValues = {
        ...fieldValues,
        ...{
          retentionDetail:
            fieldValues.retention === NOT_UTIL_CODE
              ? fieldValues.retention + fieldValues.retentionDetail
              : fieldValues.retentionDetail,
        },
      }
    }

    if (isInboundLoyalty && !isEmpty(loyaltyValues)) {
      confirmFieldValues.forEach(fieldValue =>
        onConfirmDispatch({ ...fieldValue, isConvergent, tariff, area, customerId }),
      )
    } else {
      onConfirmDispatch({ ...confirmFieldValues, isConvergent, tariff, area, customerId })
    }
  }

  const errorComponent = (
    <>
      <div className="error-container">
        <FormHelperText className="error-msg">La cantidad máxima es 999,99€</FormHelperText>
      </div>
    </>
  )

  return (
    <ModalContent className={props.className} data-hook="typifications-modal">
      <Typography
        {...TextStyles.paragraphDark({
          className: 'modal-legend',
        })}>
        Antes de terminar, por favor, codifica la acción que has realizado.
      </Typography>

      {!isAverias && showRetentionFields && (
        <>
          <FormContainer>
            <FormSelectOutlined
              label="Selecciona el Operador Relacionado"
              name="operator"
              value={fieldValues.operator}
              options={fieldOptionsValues.cvmOperators}
              onChange={e => onChange(e)}
              className="operator-select"
            />
          </FormContainer>

          <Box my="12px">
            <Divider />
          </Box>
        </>
      )}

      <FormContainer>
        <FormSelectOutlined
          label="Selecciona el MSISDN"
          name="msisdn"
          value={fieldValues.msisdn}
          options={fieldOptionsValues.linesVals}
          onChange={e => onChange(e)}
          className="msisdns-select"
        />
      </FormContainer>

      {showLoyaltyFields && (
        <FormContainer>
          <FormSelectOutlined
            label="Selecciona el tipo de suscripción"
            name="subscriptionType"
            value={fieldValues.subscriptionType}
            options={SUBSCRIPTIONS_TYPE_OPTIONS}
            onChange={e => onChange(e)}
            className="subscription-type-select"
          />
        </FormContainer>
      )}

      <FormContainer>
        <FormSelectOutlined
          label="Selecciona la campaña"
          name="campaign"
          value={fieldValues.campaign}
          options={fieldOptionsValues.campaigns}
          onChange={e => onChange(e)}
          disabled={campaignDisabled}
          className="campaign-select"
        />
      </FormContainer>

      <Box my="12px">
        <Divider />
      </Box>

      {(isRetention || isLoyalty) && (
        <>
          <FormContainer>
            <TextField
              className="initial-fee-text text-with-error"
              label="Cuota inicial"
              placeholder="Introduzca la cantidad"
              name={INITIAL_FEE_NAME}
              type="text"
              value={initialFee?.replace('.', ',')}
              variant="outlined"
              onChange={e => {
                setInitialFee(e.target.value.replace(/[^0-9,.]/g, ''))
                onInitialAndFinalFeeChange(e)
              }}
              inputProps={{
                maxLength: '6',
              }}
              InputProps={{
                endAdornment: <Icon className="icon euro-icon">{ICONS.euro}</Icon>,
              }}
            />

            <TextField
              className="last-fee-text text-with-error"
              label="Cuota final"
              placeholder="Introduzca la cantidad"
              name={FINAL_FEE_NAME}
              type="text"
              value={finalFee?.replace('.', ',')}
              variant="outlined"
              onChange={e => {
                setFinalFee(e.target.value.replace(/[^0-9,.]/g, ''))
                onInitialAndFinalFeeChange(e)
              }}
              inputProps={{
                maxLength: '6',
              }}
              InputProps={{
                endAdornment: <Icon className="icon euro-icon">{ICONS.euro}</Icon>,
              }}
            />

            {initialFeeError ? errorComponent : <div />}

            {finalFeeError && errorComponent}
          </FormContainer>

          <Box my="12px">
            <Divider />
          </Box>
        </>
      )}

      {!isBo && (
        <>
          <FormContainer>
            <FormSelectOutlined
              label="Selecciona el motivo"
              name="reason"
              value={fieldValues.reason}
              options={fieldOptionsValues.reasons}
              onChange={e => onChange(e)}
              className="reason-select"
            />

            {(!isAverias || (isAverias && fieldValues.reason === 'STR25')) && (
              <FormSelectOutlined
                label="Selecciona el submotivo 1"
                name="subreason"
                value={fieldValues.subreason}
                options={fieldOptionsValues.subreasons}
                onChange={e => onChange(e)}
                className="subreason1-select"
              />
            )}
            {!isAverias && !isLoyalty && !isRetention && !isCancelation && (
              <FormSelectOutlined
                label="Selecciona el submotivo 2"
                name="detail"
                value={fieldValues.detail}
                options={fieldOptionsValues.details}
                onChange={e => onChange(e)}
                className="subreason2-select"
              />
            )}
          </FormContainer>
          <Box my="12px">
            <Divider />
          </Box>
        </>
      )}
      {showLoyaltyFields &&
        isUtilReasonSelected &&
        fieldOptionsValues.loyalty.map((loyaltyOption, index) => {
          const loyaltyDetailValue = fieldValues.loyaltyDetail[index]
          return (
            <>
              <FormContainer>
                <TextField
                  placeholder="Selecciona la fidelización"
                  data-hook={`input-loyalty-${loyaltyOption.text}`}
                  name="loyalty"
                  value={loyaltyOption.text}
                  rows={1}
                  rowsMax={2}
                  variant="outlined"
                  disabled
                />
                <FormSelectOutlined
                  label="Selecciona el detalle de fidelización"
                  name={LOYALTY_DETAIL_NAME}
                  value={!isNil(loyaltyDetailValue) ? loyaltyDetailValue : ''}
                  options={fieldOptionsValues.loyaltyDetail[index]}
                  onChange={e => onChange(e, index)}
                  className="loyalty-detail-select"
                />
              </FormContainer>
              <Box my="12px">
                <Divider />
              </Box>
            </>
          )
        })}

      {isAverias && (
        <>
          <FormContainer className="flex">
            {!isBo && (
              <FormSelectOutlined
                label="Ticket Jira"
                name="ticketCreated"
                value={fieldValues.ticketCreated}
                options={fieldOptionsValues.ticketJiraOptions}
                onChange={e => onChange(e)}
                className="ticketJira-select"
              />
            )}
            <TextField
              label="Nº Ticket Jira"
              name="description"
              value={fieldValues.description}
              onChange={e => onChange(e)}
              multiline
              variant="outlined"
              inputProps={{
                maxLength: 50,
              }}
              className="ticketJira-description-text"
            />
          </FormContainer>
          <Box my="12px">
            <Divider />
          </Box>
        </>
      )}

      {showRetentionFields ? (
        <>
          <FormContainer>
            <FormSelectOutlined
              label="Retenido/no retenido"
              name={RETENTION_NAME}
              value={fieldValues.retention}
              options={fieldOptionsValues.retentionOptions}
              onChange={e => onChange(e)}
              className="retention-select"
            />
            <FormSelectOutlined
              label={isAverias ? 'Avería solucionada' : 'Detalle retención/no retención'}
              name="retentionDetail"
              value={fieldValues.retentionDetail}
              options={
                isAverias
                  ? fieldOptionsValues.fixedIssueOptions
                  : fieldOptionsValues.retentionDetails
              }
              onChange={e => onChange(e)}
              className="retentionDetail-select"
            />
          </FormContainer>
          <Box my="12px">
            <Divider />
          </Box>
        </>
      ) : null}

      {!isAverias && (
        <>
          <FormContainer>
            <TextField
              className="free-text"
              data-hook="typifications-free-text"
              placeholder="Oferta y breve descripción de la llamada"
              name="description"
              value={fieldValues.description}
              onChange={e => onChange(e)}
              multiline
              rows={1}
              rowsMax={4}
              variant="outlined"
              inputProps={{
                maxLength: 1024,
              }}
            />
          </FormContainer>
        </>
      )}

      <ModalActions>
        <Button onClick={onConfirm} disabled={buttonDisabled} data-hook="make-typification">
          Confirmar
        </Button>
        <Button secondary onClick={props.onCancel}>
          Cancelar
        </Button>
      </ModalActions>
    </ModalContent>
  )
})`
  > .form-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    > div {
      margin-top: 12px;
      margin-bottom: 13px;
      width: calc(50% - 12px);
      box-sizing: border-box;
      &:nth-child(odd) {
        margin-right: 24px;
      }
    }
    > div.free-text {
      width: 100%;
      margin-right: 0px;
    }
    .text-with-error {
      margin-bottom: 0 !important;
    }
    .error-container {
      margin-top: 0;
      margin-bottom: 0;
    }
    .error-msg {
      color: ${props => get(props, 'theme.palette.brand.alert')};
      margin-top: 0;
    }
  }
`

TypificationsModal.defaultProps = {
  documentId: '',
  lineNumbers: [],
}

TypificationsModal.propTypes = {
  lineNumbers: PropTypes.arrayOf(PropTypes.string).isRequired,
  documentId: PropTypes.string.isRequired,
  onCancel: PropTypes.func,
}
