import { axiosJWT } from 'modules/axios'
import { APP_CONFIG } from 'services/app-config'

const packagesAPIUrl = `${APP_CONFIG.sales_cluster}/v1/bundles/`
// const packagesAPIUrl = 'https://sales-bundles-mm.mocklab.io/'

export function fetchBundles(id, channel, segment) {
  return axiosJWT({
    method: 'GET',
    url: `${packagesAPIUrl}${id}`,
    params: { channel, segment },
  }).then(({ data }) => data)
}
