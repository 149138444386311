import { constants } from './constants'

const findAllSubscriptions = payload => ({
  type: constants.FIND_ALL_SUBSCRIPTIONS,
  payload,
})

const findAllSubscriptionsSuccess = subscriptions => ({
  type: constants.FIND_ALL_SUBSCRIPTIONS_SUCCESS,
  payload: {
    subscriptions,
  },
})

const findAllSubscriptionsError = error => ({
  type: constants.FIND_ALL_SUBSCRIPTIONS_FAILED,
  payload: {
    error,
  },
})

export const actions = {
  findAllSubscriptions,
  findAllSubscriptionsSuccess,
  findAllSubscriptionsError,
}
