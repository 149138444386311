import PropTypes from 'prop-types'
import ListItemsCard from './ListItemsCard'
import ItemRowLabelCard from '../item-row/ItemRowLabelCard'
import ItemRowValueCard from '../item-row/ItemRowValueCard'

const ItemAttrCard = ({ name, value }) => (
  <ListItemsCard>
    <ItemRowLabelCard>{name}</ItemRowLabelCard>
    <ItemRowValueCard>{value}</ItemRowValueCard>
  </ListItemsCard>
)

ItemAttrCard.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
}

export default ItemAttrCard
