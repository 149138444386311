import {
  isEmpty,
  head,
  orderBy,
  get,
  groupBy,
  uniqBy,
  has,
  cloneDeep,
  round,
  isArray,
  isNil,
} from 'lodash'
import { TARIFFS_EMPTY, TARIFFS_ERROR } from 'modules/SharedSales/constants'

import { VULA_100MB_FIBER, NEBA_100MB_FIBER, VULA, NEBA } from 'modules/tariffs/tariffs.constants'
import {
  OLD_PORTFOLIO_TARIFFS_NAME,
  OLD_PORTOLIO_TARIFFS_SPECIAL_NAME_LOYALTY,
} from 'modules/offers-configuration/constants'
import { roundTwoDecimals, formatNumberWithComma } from 'utils'
import { getPriceWithTax } from '../../Taxes'

function getTariffPrice(tariff) {
  return tariff?.prices?.length
    ? tariff.prices.reduce((curr, next) => {
        return {
          ...curr,
          ...next,
          taxFreeAmount: parseFloat(curr.taxFreeAmount) + parseFloat(next.taxFreeAmount),
          taxIncludedAmount: null,
        }
      })
    : { taxFreeAmount: 0, taxIncludedAmount: 0, taxRate: 0 }
}

export function sumFeesByDuration(fees) {
  return Object.values(groupBy(fees, 'duration')).map(group => {
    return group.reduce((curr, next) => {
      return { ...curr, amount: curr.amount + next.amount }
    })
  })
}

export function combineFees(allFees) {
  if (!allFees?.length) return []

  const finalFees = []
  for (let i = 0; i < allFees.length; i += 1) {
    const baseFee = allFees[i]
    for (let j = 0; j < allFees.length; j += 1) {
      const nextFee = allFees[j]
      if (baseFee.duration === nextFee.duration) continue
      if (baseFee.duration < nextFee.duration && baseFee.duration !== 0 && nextFee.duration !== 0) {
        baseFee.amount += nextFee.amount
      }
    }
    finalFees.push(baseFee)
  }
  if (finalFees.length) {
    finalFees.push(finalFees.shift())
  }
  return finalFees || []
}

export function getStackedFees(fees, amount = 0) {
  return fees.map(fee =>
    fee.duration !== 0
      ? { ...fee, amount: roundTwoDecimals(fee.amount + fees[fees.length - 1].amount + amount) }
      : fee,
  )
}

export function getDiscountFromCampaign({ discounts }) {
  if (!discounts || !discounts[0]) return null
  const { amount, units, interval, maxRepetitions, priority, isCampaign } = discounts[0]
  return {
    amount,
    units,
    interval,
    maxRepetitions,
    priority,
    isCampaign
  }
}

export function uniqsDiscountsFromCrossSellPromotion(crossSellPromo) {
  if (!crossSellPromo?.discounts || !crossSellPromo.discounts[0]) return []

  const uniqsDiscounts = uniqBy(crossSellPromo.discounts, discount =>
    [
      discount.name,
      discount.amount,
      discount.units,
      discount.interval,
      discount.maxRepetitions,
    ].join(),
  )

  return uniqsDiscounts.map(discount => {
    const {
      name,
      amount,
      units,
      interval,
      maxRepetitions,
      targetPrices = [],
      description,
    } = discount
    return {
      name,
      amount,
      units,
      interval,
      maxRepetitions,
      id: crossSellPromo.poId,
      targetPrices,
      description,
    }
  })
}

function isXsellDiscount(id) {
  return id?.includes('PROMOXSELL')
}

function isD2rDiscount(id) {
  return id?.includes('2RESIDISC')
}

/**
 *
 * @param {Discount} discount
 * @param {Price} price
 * @param {Number} taxRate
 *
 * returns a number with the total discount amount in euros
 */
export function getDiscountAmount(discount, price, taxRate) {
  let result = 0
  // Some bundles come with taxes and we remove them
  if (!discount.units) {
    return parseFloat((discount.amount * 100) / ((taxRate * 100 - 100 || 21) + 100))
  }

  if (discount.units === 'monetary') {
    result = discount.amount * -1
  } else {
    result = parseFloat(((discount.amount * price) / 100) * -1)
  }

  return result
}

const calculatePercentDiscounts = (discounts, discountFees, price, tax) => {
  const mergedDiscounts = [...discountFees, ...discounts]
  const percentDiscounts = []
  let feeWithForeverDiscount

  let tariffPrice
  return mergedDiscounts.map((item, idx) => {
    if (idx === 0) {
        tariffPrice = price 
        if (item.duration === 0) {
          feeWithForeverDiscount = tariffPrice + item.amount
          tariffPrice = feeWithForeverDiscount
        }
    } else {
      let currIdx = idx
      // find the tariff base price that previous priority left
      while (currIdx >= 0) {
        const previousDiscount = mergedDiscounts[currIdx]
        if (
          item.units === 'percents' &&
          previousDiscount.duration > 0
        ) {
          tariffPrice += previousDiscount.amount

          percentDiscounts.push({
            amount: getDiscountAmount(item, tariffPrice, tax),
            duration: previousDiscount.duration,
          })
        }
        currIdx -= 1
      }
    }
    const amount = feeWithForeverDiscount ? getDiscountAmount(item, feeWithForeverDiscount || tariffPrice, tax) : getDiscountAmount(item, price, tax)

    if (item.units === 'percents') {
      return {
        amount: { amount, percents: percentDiscounts },
        duration: item.maxRepetitions || 0,
        isXsellDiscount: isXsellDiscount(item.id),
        isD2rDiscount: isD2rDiscount(item.id),
      }
    }

    return item
  })
}

function calculatePriorityDiscounts(discountsWithPriority, price, tax) {
  let tariffPrice = price
  return discountsWithPriority.map((item, idx) => {
    // set tariff price for accumulated discounts
    if (idx > 0) {
      let currIdx = idx
      // find the tariff base price that previous priority left
      while (currIdx >= 0) {
        const previousDiscount = discountsWithPriority[currIdx]
        if (item.priority > previousDiscount.priority) {
          tariffPrice += getDiscountAmount(previousDiscount, tariffPrice, tax)
          // we found it, stop
          currIdx = 0
        }
        currIdx -= 1
      }
    }
    const amount = getDiscountAmount(item, tariffPrice, tax)
    // if its the last one, we remove the discount from tariff price to have a base calculation for null ones.
    if (discountsWithPriority && idx === discountsWithPriority.length - 1) {
      tariffPrice += amount
    }
    return {
      amount,
      duration: item.maxRepetitions || 0,
      priority: item.priority,
      isXsellDiscount: isXsellDiscount(item.id),
      isD2rDiscount: isD2rDiscount(item.id),
      // isPercent: false,
    }
  })
}

function calculateNullPriorityDiscounts(discountsWithNullPriority, price, tax) {
  let tariffPrice = price
  return discountsWithNullPriority.map((item, idx) => {
    if (idx > 0) {
      let currIdx = idx - 1
      // find the tariff base price that previous priority left
      while (currIdx >= 0) {
        const previousDiscount = discountsWithNullPriority[currIdx]
        if (item.amount > previousDiscount.amount) {
          tariffPrice += getDiscountAmount(previousDiscount, tariffPrice, tax)
          // we found it, stop
          currIdx = 0
        }
        currIdx -= 1
      }
    }

    return {
      amount: getDiscountAmount(item, tariffPrice, tax),
      duration: item.maxRepetitions || 0,
      isXsellDiscount: isXsellDiscount(item.id),
      isD2rDiscount: isD2rDiscount(item.id),
    }
  })
}

export function getAllFees(tariff, tax) {
  return tariff?.prices
    ?.map(price => {
      // 1.- Calculate discounts fees without taxes
      const tariffPrice = price.taxFreeAmount
      if (price?.discounts?.length > 0) {
        // separate discounts with priority
        const discountsWithPriority = price?.discounts
          ?.filter(i => i.priority !== null && (i.units === 'monetary' || !i.isCampaign) )
          .sort((a, b) => (a.priority > b.priority ? 1 : -1))
          .sort((a, b) => (a.maxRepetitions > b.maxRepetitions ? 1 : -1))
        const discountsWithPriorityAndPercents = price?.discounts
          ?.filter(i => i.priority !== null && i.units === 'percents' && i.isCampaign)
          .sort((a, b) => (a.priority > b.priority ? 1 : -1))

        // separate null priority discounts
        const discountsWithNullPriority = price?.discounts
          ?.filter(i => i.priority === null)
          .sort((a, b) => (a.amount > b.amount ? 1 : -1))

        // Calculate discount fees with priority
        const priorityDiscounts = calculatePriorityDiscounts(
          discountsWithPriority,
          tariffPrice,
          tax,
        )

        const percentsDiscounts = calculatePercentDiscounts(
          discountsWithPriorityAndPercents,
          priorityDiscounts,
          tariffPrice,
          tax,
        )

        const percentDiscount = percentsDiscounts.filter(discount =>
          isArray(discount.amount?.percents),
        )

        const finalDiscounts = percentDiscount
          .map(discount => get(discount, 'amount.percents', []))
          .map(discount => {
            return discount.reduce((acc, d) => {
              const related = percentsDiscounts?.find(({ duration, amount }) => {
                return !isArray(amount) && duration > 0 && d.duration === duration
              })
              if (!isNil(related)) {
                acc.push({
                  amount: get(related, 'amount') + d.amount,
                  duration: get(related, 'duration'),
                })
              }

              return acc
            }, [])
          })

         head(
          finalDiscounts?.map(discount => {
            return discount?.map(d => {
              const related = percentsDiscounts?.find(({ duration }) => duration === d.duration)
              related.amount = d.amount
              return related
            })
          }),
        ) 

        const percentDiscountsFormatted = []
        percentDiscount.forEach((discount) =>
          percentDiscountsFormatted.push({ amount: discount.amount.amount, duration: discount.duration, isPercent: true, isXsellDiscount: discount.isXsellDiscount, isD2rDiscount: discount.isD2rDiscount}),
        )

        const priorityAndPercentDiscounts = isEmpty(percentDiscountsFormatted)
          ? priorityDiscounts
          : [...priorityDiscounts, ...percentDiscountsFormatted]


        // Calculate discount fees with null priority
        const nullPriorityDiscounts = calculateNullPriorityDiscounts(
          discountsWithNullPriority,
          tariffPrice,
          tax,
        )

        // Join both discount fees
        const discountFee = [...priorityAndPercentDiscounts, ...nullPriorityDiscounts]

        // We sum fees by duration
        const discountFeesDurationSum = sumFeesByDuration(discountFee).sort((a, b) =>
          a.duration > b.duration ? 1 : -1,
        ) 

        // We combine discounts (stack amounts)
        const combinedDiscountFee = discountFeesDurationSum.map((fee, i) => {
          // We always add taxes (for pro taxRate will not be applied)
          // We must add taxes to the discounts partial total,
          //  because if we do it before, the total tariff price is not correct
          let amountWithTax = getPriceWithTax({ taxFreeAmount: fee.amount, taxRate: tax }, tax)

          amountWithTax = fee.isXsellDiscount ? round(amountWithTax, 3) : amountWithTax

          if (i === 0) return { ...fee, amount: amountWithTax }

          const nextFee = discountFeesDurationSum[i + 1]
          const nextDiscount = nextFee?.isPercent || false ? 0 : nextFee?.amount || 0
      
          return {
            ...fee,
            amount: amountWithTax + nextDiscount,
          }
        })
        // We return discount with taxes but without rounding
        return [
          {
            amount: getPriceWithTax(price, tax),
            duration: 0,
          },
          ...combinedDiscountFee,
        ]
      }

      // We return discount with taxes but without rounding
      return [
        {
          amount: getPriceWithTax(price, tax),
          duration: 0,
        },
      ]
    })

    .reduce((curr, next) => [...curr, ...next], [])
}

export const getTariffName = tariff => tariff && (tariff.description || tariff.commercial_name)

const calculateFinalPrice = (tariff, tax) => {
  const allFees = getAllFees(tariff, tax)
  const amountPercent = allFees.find(fee => fee?.isPercent)?.amount || 0
  return allFees.map((fee, index) => { 
    return index > 0 && !fee?.isPercent && fee?.duration > 0 ? {...fee, amount: fee?.amount - amountPercent} : fee }) 
}

export function getTariffFeesAndDiscounts(tariff, tax) {
  const tariffFees = calculateFinalPrice(tariff, tax)

  const groupedFeesByDuration = sumFeesByDuration(tariffFees)

  const fees = combineFees(groupedFeesByDuration).map(fee => ({
    ...fee,
    amount: roundTwoDecimals(fee.amount),
  }))
  const discounts = sumFeesByDuration(tariffFees?.filter(fee => fee.amount < 0)).map(discount => ({
    ...discount,
    amount: roundTwoDecimals(discount.amount),
  }))

  return { fees, discounts }
}

export function removeIncludedAmount(product, tax = undefined) {
  const tariffPrices = product?.prices?.map(price => ({
    ...price,
    taxIncludedAmount: null,
    taxRate: tax || price.taxRate,
  }))
  return {
    ...product,
    prices: tariffPrices || [],
  }
}

export function formatBasicTariff(tariff, tax, isMultiSim) {
  const tariffWithoutIncludedAmounts = removeIncludedAmount(tariff)

  const tariffPrice = getTariffPrice(tariffWithoutIncludedAmounts)
  const tariffPriceWithTaxes = {
    ...tariffPrice,
    taxFreeAmount: roundTwoDecimals(getPriceWithTax(tariffPrice, tax)),
    taxRate: tax,
  }
  const { fees, discounts } = getTariffFeesAndDiscounts(tariffWithoutIncludedAmounts, tax)
  const tariffTermsToApplyTax = {
    taxFreeAmount: tariff.terms?.taxFreeAmount,
    taxRate: tax,
  }

  const formattedTariff = {
    ...tariffWithoutIncludedAmounts,
    id: tariff.poId,
    ps: tariff.psId,
    name: tariff?.commercialInformation?.name,
    longDescription: tariff?.commercialInformation?.longDescription,
    monthlyFee: tariffPriceWithTaxes,
    priceWithTax: tariffPriceWithTaxes.taxFreeAmount,
    permanency: {
      duration: tariff.terms?.count,
      amount: tariff.terms ? formatNumberWithComma(getPriceWithTax(tariffTermsToApplyTax, tax)) : 0,
    },
    discounts,
    data: {
      amount:
        get(tariff, 'mobileData', 0) < 1024
          ? get(tariff, 'mobileData', 0)
          : Math.floor(get(tariff, 'mobileData', 0) / 1024),
      type: get(tariff, 'mobileData', 0) < 1024 ? 'MB' : 'GB',
    },
    fees: getStackedFees(fees),
    ...(isMultiSim
      ? {
          bundledItems: tariff.bundledItems.map(bundledItem => bundledItem.id),
        }
      : {}),
  }
  return formattedTariff
}
export function formatTariffName(tariff) {
  if (!tariff.id) return ''
  return tariff.monthlyFee ? `${tariff.name} - ${tariff.priceWithTax} €/mes` : tariff.name
}

export function getLandlineTariff(selectedTariffs) {
  return selectedTariffs.find(
    tariff => tariff.id && (tariff.id.includes('CONTFH') || tariff.id.includes('CONTDSL')),
  )
}

export function mapToSortedMobileTariffs(tariffs) {
  const compareType = tariff =>
    tariff.tariffType === 'additional_line'
      ? true
      : tariff.tariffType === 'main_line' || tariff.tariffType === 'extra_line'
  const compareMobileData = tariff => Number(tariff.mobileData)
  const compareMobileMin = tariff => tariff.mobileMin === -1
  const compareInternetSpeed = tariff => Number((tariff.internetDownloadSpeed || '0M').slice(0, -1))
  const sortedFormattedTariffs = orderBy(
    tariffs,
    [compareType, compareMobileData, compareMobileMin, compareInternetSpeed],
    ['desc', 'desc', 'desc', 'desc'],
  )
  return sortedFormattedTariffs
}

export function formatMobileTariff(tariff, tax, isMultiSim) {
  const basicTariff = formatBasicTariff(tariff, tax, isMultiSim)

  return {
    ...basicTariff,
    mobileMin: tariff.mobileMin,
  }
}

export function formatMobileTariffs(tariffList, tax, isMultiSim) {
  const formattedTariffs = tariffList.map(tariff => formatMobileTariff(tariff, tax, isMultiSim))
  return mapToSortedMobileTariffs(formattedTariffs)
}

export function formatTariffError(tariffs, isLoading, error) {
  if (isLoading) return {}
  const tariffError = isEmpty(tariffs) ? { label: 'Error', value: TARIFFS_EMPTY } : {}
  const defaultError = { label: 'Error', value: TARIFFS_ERROR }
  return error ? defaultError : tariffError
}

export function isElFijoTariff(tariffId) {
  return tariffId === 'CONT100MR10' || tariffId === 'CONT100MP10'
}

export function getElFijoMsisdn(subscription) {
  const productsSpecifications = get(subscription, 'productsSpecifications')
  const fixedLine = head(productsSpecifications.filter(spec => spec.name === 'fixed'))

  return get(fixedLine, 'id') || get(subscription, 'id')
}

export function findPriceDiscounts(price, discounts) {
  let priceDiscounts = []

  if (discounts?.length > 0) {
    priceDiscounts = discounts.filter(discount => {
      const targetPrices = discount.targetPrices || []
      return targetPrices.includes(price?.priceSpecification)
    })
  }
  return priceDiscounts
}

export function findGlobalCrossSellDiscounts(discounts, prices) {
  const tariffPricesSpecifications = prices?.map(price => price.priceSpecification)
  return discounts.filter(
    discount =>
      !discount.targetPrices?.some(targetPrice => tariffPricesSpecifications.includes(targetPrice)),
  )
}

export function addDiscountsToTariffPrices(tariff, discounts = []) {
  if (discounts?.length > 0 && tariff?.prices?.length > 0 && discounts[0].units === 'monetary') {
    const prices = cloneDeep(tariff.prices)
    prices[0].discounts = [...prices[0].discounts, ...discounts]
    return prices
  }

  const tariffPrices = tariff?.prices

  return tariffPrices?.map(price => {
    const priceDiscounts = findPriceDiscounts(price, discounts)

    return {
      ...price,
      discounts: [...price.discounts, ...priceDiscounts],
    }
  })
}

export function getTariffPriceWithDiscountPrice(tariff, tax) {
  const tariffFormatBasic = formatBasicTariff(tariff, tax)
  return {
    ...tariffFormatBasic,
    discountPrice: tariffFormatBasic.fees,
  }
}

export const buildTariffName = (tariffId, tariffComercialName, isLoyaltyAgent = false) => {
  let tariffName = tariffComercialName

  if (isLoyaltyAgent && has(OLD_PORTOLIO_TARIFFS_SPECIAL_NAME_LOYALTY, tariffId)) {
    tariffName = get(OLD_PORTOLIO_TARIFFS_SPECIAL_NAME_LOYALTY, tariffId)
  } else if (has(OLD_PORTFOLIO_TARIFFS_NAME, tariffId)) {
    tariffName = get(OLD_PORTFOLIO_TARIFFS_NAME, tariffId)
  } else if (VULA_100MB_FIBER.includes(tariffId)) {
    tariffName = tariffName.concat(` (${VULA})`)
  } else if (NEBA_100MB_FIBER.includes(tariffId)) {
    tariffName = tariffName.concat(` (${NEBA})`)
  }

  return tariffName
}
