import { axiosFactory } from 'modules/axios'
import { fetchContentfulResource } from 'modules/contentful'
import { call, put, takeEvery } from 'redux-saga/effects'
import { logError, formatErrorMessage } from 'services/logging'
import { getPropertyFromResponse } from '../legalTexts.helpers'
import { getLegalTextsErrorsAction, getLegalTextsSuccessAction } from './legalTexts.actions'
import { GET_LEGAL_TEXTS } from './legalTexts.constants'

export function* getLegalTextsSaga({ payload: { origin } }) {
  try {
    const texts = yield call(fetchContentfulResource, 'legalText', { origin })
    const field = origin === 'sales_next_steps' ? 'description' : null
    yield put(getLegalTextsSuccessAction(origin, getPropertyFromResponse(texts, field)))
  } catch (e) {
    yield call(logError, { e: new Error(formatErrorMessage(e)), origin })
    const error = axiosFactory.buildError(e)
    yield put(getLegalTextsErrorsAction(origin, error))
  }
}

export function* watchGetLegalTexts() {
  yield takeEvery(GET_LEGAL_TEXTS, getLegalTextsSaga)
}
