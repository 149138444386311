import { buildPath, AppPaths } from 'modules/AppPaths'

export const generateAditionalOperationsLink = (orderId, { documentId, documentType }) =>
  buildPath(
    AppPaths.on_boarding.id,
    { orderId },
    {
      documentId,
      documentType,
      orderId,
      channel: 'newton',
      brand: 'yoigo',
    },
  )
