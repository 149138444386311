import { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { get, isEmpty } from 'lodash'
import { parseISO, differenceInMilliseconds } from 'date-fns'

import { Typography } from '@material-ui/core'
import { TextStyles } from 'utils/text'

import { format_dd_MM_yy_HHmm_hFromStr } from 'utils/formatting/dates'
import { formatDistanceFromMs_HsMins_ES } from 'utils/durations'

import { Card, Popover } from 'modules/ui'
import { ButtonLink } from 'components/ui'

import { CLOSING_REASONS } from './constants'
import { ChatStatusBadge } from './ChatStatusBadge'
import { SurveyBanner } from './SurveyBanner'

const InfoRow = styled(({ className, children }) => {
  return (
    <div className={`${className} info-card-row`} data-hook="info-card-row">
      {children}
    </div>
  )
})`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 5px 0;
`

const TextLabel = styled(({ className, children }) => {
  return (
    <Typography
      {...TextStyles.labelDark({
        className: `${className} txt-label`,
        component: 'span',
      })}>
      {children}&nbsp;
    </Typography>
  )
})`
  white-space: nowrap;
`

const TextValue = ({ className, children }) => {
  return (
    <Typography
      {...TextStyles.dataDark({
        className: `${className} txt-value`,
        component: 'span',
      })}>
      {children}
    </Typography>
  )
}

TextValue.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}

export const InfoCard = styled(({ className, data }) => {
  const startDate = parseISO(get(data, 'date'))
  const endDate = !(isEmpty(get(data, 'endDate')) || get(data, 'endDate') === 'undefined')
    ? parseISO(get(data, 'endDate'))
    : Date.now()

  const durationStr = formatDistanceFromMs_HsMins_ES(differenceInMilliseconds(endDate, startDate))

  const isClosed = get(data, 'status') === 'CLOSE'
  const hasSummary = get(data, 'communication.summary')
  const closingReason = isClosed
    ? get(
        CLOSING_REASONS[get(data, 'communication.closeReason', CLOSING_REASONS.OTHER.id)],
        'desc',
      ) || get(data, 'communication.closeReason')
    : ''

  const surveyMeasure = get(data, 'communication.feedbackRating', 0)

  const [popoverTarget, setPopoverTarget] = useState(null)
  const [agent, setAgent] = useState(null)

  const handlePopoverOpen = (ev, atcAgent) => {
    setAgent(atcAgent)
    setPopoverTarget(ev.currentTarget)
  }

  const handlePopoverClose = () => {
    setPopoverTarget(null)
  }

  return (
    <Card title="Ficha técnica" className={className}>
      <InfoRow>
        <TextLabel>MSISDIN: </TextLabel>
        <TextValue>{get(data, 'phone')}</TextValue>
      </InfoRow>

      <InfoRow>
        <TextLabel>Estado: </TextLabel>
        <ChatStatusBadge status={get(data, 'status')} />
      </InfoRow>

      <InfoRow id="info-card-nicknames">
        <TextLabel>Agentes implicados: </TextLabel>

        {get(data, 'communication.agentParticipants').map(atcAgent => (
          <ButtonLink
            key={atcAgent.nickname}
            className="button-with-popover"
            onMouseEnter={ev => handlePopoverOpen(ev, atcAgent)}
            onMouseLeave={handlePopoverClose}>
            <TextValue className="txt-in-button">{get(atcAgent, 'name', '')}</TextValue>
          </ButtonLink>
        ))}

        <Popover hasArrow popoverTarget={popoverTarget}>
          <InfoRow>
            <TextLabel>Nickname: </TextLabel>
            <TextValue>{get(agent, 'nickname', '')}</TextValue>
          </InfoRow>
          <InfoRow>
            <TextLabel>Grupo: </TextLabel>
            <TextValue>{get(agent, 'group', '')}</TextValue>
          </InfoRow>
        </Popover>
      </InfoRow>

      <InfoRow>
        <TextLabel>Fecha y hora de inicio: </TextLabel>
        <TextValue>{format_dd_MM_yy_HHmm_hFromStr(get(data, 'date'))}</TextValue>
      </InfoRow>

      {isClosed && (
        <InfoRow>
          <TextLabel>Fecha cierre: </TextLabel>
          <TextValue>{format_dd_MM_yy_HHmm_hFromStr(get(data, 'endDate'))}</TextValue>
        </InfoRow>
      )}

      <InfoRow>
        <TextLabel>Duración: </TextLabel>
        <TextValue>{durationStr}</TextValue>
      </InfoRow>
      {hasSummary && (
        <InfoRow>
          <TextLabel>Resumen: </TextLabel>
          <TextValue>{get(data, 'communication.summary', '-')}</TextValue>
        </InfoRow>
      )}
      {isClosed && (
        <InfoRow>
          <TextLabel>Motivo cierre: </TextLabel>
          <TextValue>{closingReason}</TextValue>
        </InfoRow>
      )}

      {surveyMeasure && (
        <InfoRow>
          <TextLabel>Encuesta: </TextLabel>
          <SurveyBanner rating={surveyMeasure} />
        </InfoRow>
      )}
    </Card>
  )
})`
  .textstyle {
    line-height: 1.42;
  }

  .txt-in-button {
    color: inherit;
  }

  .button-with-popover {
    position: relative;
    margin-left: 12px;
    vertical-align: top;
  }
`

InfoCard.propTypes = {
  data: PropTypes.object,
  className: PropTypes.string,
}
