import PropTypes from 'prop-types'
import { get, filter, isNil } from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'

import { Typography } from '@material-ui/core'
import { TextStyles } from 'utils/text'

import { BadgeStatusDetail } from 'components'
import { ListDivider, TabsContainer, SpinnerCenter } from 'components/ui'

import { SectionHeader } from 'modules/ui'
import { AlarmsContainer } from 'modules/alarms'
import { useCvmActions } from 'modules/offers-configuration/hooks'
import { useShowTypification } from 'modules/vista-360/hooks/useShowTypification'
import { getIsTypificationAllowed } from 'modules/offers-configuration/selectors'
import { selectIsPosOrMasProximo } from 'modules/Permissions/store/permissions-selectors'
import { useGetIframeData } from 'modules/typifications/hooks'
import { selectIsErrorSecurizerIframe } from 'modules/typifications/store/selectors/securizerTypifications.selectors'
import { resetSecurizerFailed } from 'modules/typifications/store/actions/securizerTypifications.actions'
import { SECURIZER_ERROR } from 'modules/typifications/constants'

import NotificationModal from 'components/packages/ui/modal/NotificationModal'
import { canIAccessToAdditionalOps } from './OrderDashboard.helpers'

import { tabsConfig, tabs } from './tabsConfig'

const ORDER_TYPE_CONVERGENT = 'Convergente'
const PADDING = '10px 20px'

export function OrderDashboard({
  className,
  isLoading,
  hasErrors,
  order,
  orderData,
  operationLink,
  saveLatestAccessToken,
}) {
  const dispatch = useDispatch()

  const { openTypificationDispatch, openTypificationIframeDispatch } = useCvmActions()
  const { permissionIframeAccess, sfid, area, customerId, msisdns } = useGetIframeData()

  const [isSecurizerErrorModal, setIsSecurizerErrorModal] = useState(false)

  const isTypificationAllowed = useSelector(getIsTypificationAllowed)
  const isPosOrMasproximo = useSelector(selectIsPosOrMasProximo)
  const isSecurizerError = useSelector(selectIsErrorSecurizerIframe)

  const additionalOpsActionEnabled = canIAccessToAdditionalOps(orderData.sfid)

  useEffect(() => {
    if(!isNil(isSecurizerError)) {
      setIsSecurizerErrorModal(isSecurizerError)
    }
  }, [isSecurizerError])

  const onNotificationClose = () => {
    setIsSecurizerErrorModal(false)
    dispatch(resetSecurizerFailed())
  }

  const actionAditionalOps = additionalOpsActionEnabled
    ? [
        {
          label: 'opciones adicionales',
          onClick: () => {
            saveLatestAccessToken()
            window.open(operationLink, '_blank')
          },
        },
      ]
    : []

  const actionIframeTipis = permissionIframeAccess ? [{
    label: 'Tipificación',
    onClick: () => openTypificationIframeDispatch({customerId, sfid, area, msisdns}),
    disabled: !isTypificationAllowed,
  }] : []
  

  const actions = useShowTypification()
    ? [
        {
          label: 'Tipificar',
          onClick: openTypificationDispatch,
          disabled: !isTypificationAllowed,
        },
        ...actionIframeTipis,
        ...actionAditionalOps,
      ]
    : [...actionIframeTipis, ...actionAditionalOps]

  const visibleTabsConfig =
    order?.type !== ORDER_TYPE_CONVERGENT || isPosOrMasproximo
      ? filter(tabsConfig, config => config.value !== tabs.ATC)
      : tabsConfig

  const titleAndSubtitle = order && get(order, 'id') && (
    <>
      <Typography
        {...TextStyles.title3Dark({
          className: 'title',
        })}>
        Pedido {get(order, 'id')}
      </Typography>
      <BadgeStatusDetail
        className="p-y-12"
        superStatus={get(order, 'superStatus')}
        status={get(order, 'status')}
        subStatus={get(order, 'subStatus')}
        statusOt={get(order, 'statusOt')}
        categoryOt={get(order, 'categoryOt')}
        icon="home"
      />
    </>
  )

  return (
    <div className={className}>
      {isLoading && <SpinnerCenter showMsg />}
      {isSecurizerErrorModal && <NotificationModal   
        type="alert"
        src="/assets/error.svg"
        title={SECURIZER_ERROR}
        isOpen
        onClose={onNotificationClose} />}
      {!isLoading && !hasErrors && order && (
        <>
          <SectionHeader title="" titleExt={titleAndSubtitle} actions={actions} />

          <ListDivider padding={PADDING} />
          <AlarmsContainer className="container-alarms p-x-24 p-t-24 p-b-12" />
          <TabsContainer
            tabsConfig={visibleTabsConfig}
            contentProps={{ orderId: order.id, orderStatus: order.status }}
            queryParamName="tab"
          />
        </>
      )}
    </div>
  )
}

OrderDashboard.propTypes = {
  className: PropTypes.string,
  order: PropTypes.object,
  orderData: PropTypes.object,
  isLoading: PropTypes.bool,
  hasErrors: PropTypes.bool,
  operationLink: PropTypes.string,
  saveLatestAccessToken: PropTypes.func,
}
