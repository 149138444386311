import { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { get, omit, size, compact, find, isEmpty, isNil, head } from 'lodash'

import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'

import { Typography, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { TextStyles } from 'utils/text'

import { actions } from 'modules/Coverage/store/actions'
import { permissions, selectCanIDO } from 'modules/Permissions'
import { isElFijoTariff, getElFijoMsisdn } from 'modules/tariffs/helpers'
import { isProCustomer } from 'services/customer-360/helpers'
import { formatAddressStr } from 'modules/vista-360/helpers/formats.helper'
import {
  getSegment,
  selectInstallationAddressPackageByMsisdn,
} from 'services/customer-360/selectors'
import {
  getTerritoryOwner,
  getCoverageComponentTechnology,
  getCoverageLoading,
  selectCoverageFailure,
  selectCoverageSusccess,
  selectCoverageErrorMessage,
  getCurrentTerritoryOwner,
  getCurrentGescal,
  getOrder,
  selectIdAddress,
  selectGescal17,
  selectGescal17FromAddressId,
} from 'modules/Coverage/store/selectors'
import { useForceSaveAuth } from 'modules/Auth'
import { use1wSales } from 'modules/offers-configuration/hooks/use1wSales/use1wSales'
import { buildPath, AppPaths } from 'modules/AppPaths'
import {
  getCommercialName,
  selectTariffsApigeeById,
  getTariffSegment,
} from 'modules/tariffs/store-apigee'
import {
  is2pSubscription,
  canModifySubscription,
  getSubscriptionMostRecentOrder,
  getIsDuoPrincipalNewTariff,
  getHasModifications,
  selectParentTechnologyIsKo,
  selectCoverageTechnologyIsKo,
  selectLoadingParentTechnology,
  selectLoadingCoverageTechnology,
  selectParentTechnology,
  selectCoverageTechnology,
  selectCoverageTechnologyErrorMessage,
  selectCommercialMigrationData,
  selectCommercialMigrationIsLoading,
  selectChangeAddressIsLoading,
  selectChangeAddressData,
  selectChangeTechnologyIsLoading,
  selectChangeTechnologyData,
  selectChangeTechnology,
  selectInstaller,
  selectChangeAddress,
} from 'modules/offers-configuration/selectors'

import { selectQueryParams } from 'modules/Router/store/index'

import {
  isConvergentSubscription,
  isMobileOnlySubscription,
  getMsisdn,
  findFixedProduct,
  findMobileProduct,
  findFixedPermanents,
  findMobilePermanents,
  isFixedLine,
  getSubscriptionTariffId,
  getSubscriptionDiscounts,
  getSubscriptionDuoPrincipal,
} from 'services/subscriptions'

import {
  selectCrossSellFlag,
  selectDeviceRenewalFlag,
  selectDisableDiscounts,
  selectCommercialMigration,
  selectMultiSimFlag,
  selectChangeAddressAppointment,
  selectChangeTechnologyAppointment,
  selectCommercialMigrationAppointmentFlag,
  selectChangeAddressIndirectFiberFlag,
  selectEnableCvmDiscounts
} from 'services/feature-flag/selectors'
import { selectors as customerSelectors } from 'services/customer-360'

import { applyTax, getTax } from 'services/taxes'

import { useDocumentLoaded } from 'utils/loading/useDocumentLoaded'
import { NEWTON, TELESALES } from 'services/global-constants/channels'
import { ADSL } from 'modules/Coverage/constants/technologies'
import {
  SVA_HOMEGO_CODE,
  CVM_FIXED_PENALTY_KEY,
  CVM_MOBILE_PENALTY_KEY,
  ADD_SUBSCRIPTION_OPERATION,
  CROSS_SUBSCRIPTION_OPERATION,
  ADD_MULTISIM_OPERATION,
  SVA_SMARTHOME_CODE,
  SVAS_TYPES_CODE_TEXT,
  COMMERCIAL_MIGRATION_PERMISSIONS,
  TESA_INSTALLER,
  INDIRECT_FIBER_TERRITORY_OWNER,
  ADSL_TERRITORY_OWNER,
} from 'modules/offers-configuration/constants'
import { getBillingAddressStr } from 'services/customer-new/selectors'

import { Button, NotificationModal, Modal } from 'components/ui'
import { useCadenceAlarms } from 'modules/NewClientSales/hooks/Cadence/useCadenceAlarms'
import { TO_ACCEPT, TRY_AGAIN } from 'modules/SharedSales/constants/literals'
import { AddressChooserModal } from 'modules/Address/components/AddressChooserModalComponent/index'
import { selectIsPosSale } from 'modules/NewClientSales/store/selectors/Basket.selectors'
import { APP_CONFIG } from 'services/app-config'

import { getAddressStreetType } from 'services/cadence-flow/helpers'
import {
  BILLING_ADDRESS,
  INSTALLATION_ADDRESS,
} from 'modules/SharedSales/constants/shippingAddress'

import { isFtth, findFee, isAdsl } from 'services/subscriptions/helpers'
import { formatMonthlyFee } from 'utils/formatting/index'
import {
  initParentTechnology,
  initCoverageTechnology,
  initCustomerOffersConfiguration,
  updateSubscription,
  clearSubscription,
  notifyDiscountsDisableReset,
  notifySetSvasReset,
  initInstaller,
} from 'modules/offers-configuration/actions'
import { selectSubscriptionCardContainer } from './SubscriptionCardContainer.selectors'

import { CVMActions } from '../../CVMActions.constants'

import { getCrossWarnings } from './SubscriptionCardContainer.helpers'
import {
  setModalTitle,
  isProSegment,
  findTotalPermanent,
  formatDiscountValue,
  applyTaxesToDiscount,
  generateTariffDuoPrincipal,
} from '../../helpers'

import { TariffsModalContainer } from '../TariffsModalContainer'
import PermanenceModal from '../../components/PermanenceModal'
import SvasSetModal from '../../components/AddSvaModal/AddSvaModal'
import UnsubscribeModal from '../../components/UnsubscribeModal'
import DiscountsAndBonusesModal from '../../components/DiscountsAndBonusesModal'
import DiscountsDisableModal from '../../components/DiscountsBonusesDisable/DiscountsDisableModal'
import { SubscriptionCard } from '../../components/SubscriptionCard'

import { useAddSubscription } from '../../hooks/useAddSubscription'
import { useCrossSubscription } from '../../hooks/useCrossSubscription'
import { useAddMultiSim } from '../../hooks/useAddMultiSim'

const useStyles = makeStyles({
  'custom-width-modal': {
    '& > div': {
      width: '940px',
    },
  },
})

const NOTIFICATION_MODAL_TYPES = {
  success: {
    type: 'success',
    iconPath: '/assets/clap_clap.svg',
  },
  alert: {
    type: 'alert',
    iconPath: '/assets/error.svg',
  },
}

export function SubscriptionCardContainer({
  subscription,
  hasIds,
  onModalOpen,
  onTitleChange,
  ...restProps
}) {
  const classes = useStyles()

  const canChangeAddress = useSelector(selectChangeAddress)
  const canChangeTechnology = useSelector(selectChangeTechnology)

  const subscriptionId = get(subscription, 'subscription_id')
  const multisimData = get(subscription, 'multisimData')
  const isSubscriptionMobileOnly = isMobileOnlySubscription(subscription)

  const commercialMigrationFlag = useSelector(selectCommercialMigration)
  const hasCommercialMigrationPermission = useSelector(
    selectCanIDO(permissions.cvm_commercial_migration.id),
  )
  const canCommercialMigration = commercialMigrationFlag && hasCommercialMigrationPermission
  const canCommercialMigrationAppointment = useSelector(selectCommercialMigrationAppointmentFlag)
  const canChangeTechnologyAppointment = useSelector(selectChangeTechnologyAppointment)
  const canChangeAddressAppointment = useSelector(selectChangeAddressAppointment)
  const canChangeAddressIndirectFiber = useSelector(selectChangeAddressIndirectFiberFlag)
  const canAddDiscounts = useSelector(selectEnableCvmDiscounts)

  const canCrossSell = useSelector(selectCrossSellFlag) && isSubscriptionMobileOnly
  const currentQueryParams = useSelector(selectQueryParams)

  const is1WSales = use1wSales()
  const isPos = useSelector(selectIsPosSale)
  const canDeviceRenewal = useSelector(selectDeviceRenewalFlag)
  const isCompany = useSelector(customerSelectors.getIsCompany)
  const isAdslSubscription = isAdsl(subscription)
  const isConvergent = isConvergentSubscription(subscription)

  const multiSimFlag = useSelector(selectMultiSimFlag)
  const haveMultiSimPermission = useSelector(selectCanIDO(permissions.cvm_multi_sim.id))
  const canMultiSim =
    multiSimFlag &&
    (isSubscriptionMobileOnly || isConvergent) &&
    haveMultiSimPermission &&
    subscription.is_postpaid

  const { documentIsLoaded } = useDocumentLoaded()

  const {
    canEnableSellOption,
    documentType,
    documentId,
    segment,
    channel,
    clientChildrenPackages,
    canAddAgileTv,
    isPosOrMasProximo,
  } = useSelector(selectSubscriptionCardContainer)

  const is2P = useMemo(() => subscription.category === 'fixed_only', [subscription])

  const allowUnsubscribe = useSelector(state => !is2pSubscription(state, subscription))
  const subscriptionCannotBeModified = useSelector(
    state => !canModifySubscription(state, subscription),
  )

  const customerOrders = useSelector(customerSelectors.orders)

  const billingAddress = useSelector(state => customerSelectors.getBillingAddress(state))
  const postCode = get(billingAddress, 'postCode')

  const customerSegment = useSelector(getSegment)
  const taxNeeded = !isProCustomer(customerSegment)
  const taxRate = (taxNeeded && useSelector(state => getTax(state, postCode))) || 0

  const feeAmount = get(findFee(subscription), 'price.amount')
  const currentFee = formatMonthlyFee(applyTax(taxRate, feeAmount))

  const disableDiscounts = useSelector(state => state.cvm.disableDiscounts)
  const showDisableDiscountsNotification =
    !!disableDiscounts.error || disableDiscounts?.succeededDiscounts?.length > 0

  const setSvas = useSelector(state => state.cvm.setSvas)
  const showSetSvasNotification =
    !!get(setSvas, 'error', null) || get(setSvas, 'succeededSvas', []).length > 0

  const msisdn = isElFijoTariff(get(subscription, 'type'))
    ? getElFijoMsisdn(subscription)
    : getMsisdn(subscription)

  const installationAddressFixedLine = useSelector(state =>
    selectInstallationAddressPackageByMsisdn(state, msisdn),
  )
  const installationAddressFixedLineCharacteristics = get(
    installationAddressFixedLine,
    'medium.characteristic',
    [],
  )

  const orders = useSelector(getOrder)

  const coverageTerritoryOwner = useSelector(getTerritoryOwner)
  const coverageAddressId = useSelector(selectIdAddress)
  const clientTerritoryOwner =
    find(installationAddressFixedLineCharacteristics, { name: 'territoryOwner' })?.value ||
    getCurrentTerritoryOwner(orders, subscriptionId)

  const [commercialMigrationSelected, setCommercialMigrationSelected] = useState(false)
  const [openCommercialMigrationModal, setOpenCommercialMigrationModal] = useState(false)

  const [changeTechnologySelected, setChangeTechnologySelected] = useState(false)
  const [openChangeTechnologyModal, setOpenChangeTechnologyModal] = useState(false)

  const [changeAddressSelected, setChangeAddressSelected] = useState(false)
  const [openChangeAddressModal, setOpenChangeAddressModal] = useState(false)

  const [technologyOperationError, setTechnologyOperationError] = useState({})
  const isTechnologyOperationErrorModalOpen = !isEmpty(technologyOperationError)
  const openTechnologyOperationErrorModal = setTechnologyOperationError
  const closeTechnologyOperationErrorModal = () => setTechnologyOperationError({})

  const [isTechnologyOperationSuccessModalOpen, setIsTechnologyOperationSuccessModalOpen] =
    useState(false)

  const resetTechnologyOperationSelected = () => {
    if (commercialMigrationSelected) {
      setCommercialMigrationSelected(false)
    } else if (changeTechnologySelected) {
      setChangeTechnologySelected(false)
    }
  }

  const coverageLoading = useSelector(getCoverageLoading)
  const coverageFailureSelector = useSelector(selectCoverageFailure)
  const coverageSuccess = !isEmpty(useSelector(selectCoverageSusccess))
  const coverageFailure = !isNil(coverageLoading) && !coverageLoading && coverageFailureSelector
  const coverageErrorMessage = useSelector(selectCoverageErrorMessage)
  const parentTechnologyLoading = useSelector(selectLoadingParentTechnology)
  const coverageTechnologyLoading = useSelector(selectLoadingCoverageTechnology)
  const coverageTechnology = useSelector(selectCoverageTechnology)
  const componentCoverageTechnology = useSelector(getCoverageComponentTechnology)
  const parentTechnology = useSelector(selectParentTechnology)
  const commercialMigrationLoading = useSelector(selectCommercialMigrationIsLoading)
  const commercialMigration = useSelector(selectCommercialMigrationData)
  const parentTechnologyFailure = useSelector(selectParentTechnologyIsKo)
  const coverageTechnologyFailure = useSelector(selectCoverageTechnologyIsKo)
  const parentTechnologySuccess = !isEmpty(parentTechnology)
  const coverageTechnologySuccess = !isEmpty(coverageTechnology)
  const coverageTechnologyErrorMessage = useSelector(selectCoverageTechnologyErrorMessage)
  const commercialMigrationSuccess =
    commercialMigrationSelected && !commercialMigrationLoading && !isEmpty(commercialMigration)

  const checkCoverageTechnology = COMMERCIAL_MIGRATION_PERMISSIONS.includes(coverageTechnology)

  const isIndirectFiber = coverageTechnology === INDIRECT_FIBER_TERRITORY_OWNER

  const technologyFailure =
    !parentTechnologyLoading &&
    !coverageTechnologyLoading &&
    (coverageTechnologyFailure || parentTechnologyFailure)
  const technologySuccess =
    !parentTechnologyLoading &&
    !coverageTechnologyLoading &&
    (coverageTechnologySuccess || parentTechnologySuccess)

  const changeTechnologyLoading = useSelector(selectChangeTechnologyIsLoading)
  const changeTechonology = useSelector(selectChangeTechnologyData)
  const changeTechnologySuccess =
    changeTechnologySelected && !changeTechnologyLoading && !isEmpty(changeTechonology)

  const changeAddressLoading = useSelector(selectChangeAddressIsLoading)
  const changeAddress = useSelector(selectChangeAddressData)
  const changeAddressSuccess =
    changeAddressSelected && !changeAddressLoading && !isEmpty(changeAddress)
  const [changeAddressIsAdsl, setChangeAddressIsAdsl] = useState(false)

  const gescal17 = useSelector(selectGescal17)
  const gescal17FromAddressId = useSelector(selectGescal17FromAddressId)
  const currentGescal =
    find(installationAddressFixedLineCharacteristics, { name: 'gescal' })?.value ||
    getCurrentGescal(orders, subscriptionId)

  const installer = useSelector(selectInstaller)

  const [showNotification, setShowNotification] = useState(false)
  const [showNotificationAlarmsSva, setShowNotificationAlarmsSva] = useState(false)
  const [changingSvaSelected, setChangingSvaSelected] = useState()

  // CVM permissions operations
  const isDisableDiscountsFlagEnabled = useSelector(selectDisableDiscounts)

  const permissionsCvmOpsTariffChange = useSelector(
    selectCanIDO(permissions.cvm_ops_tariff_change.id),
  )
  const permissionsCvmOpsAddSomeDiscounts =
    useSelector(selectCanIDO(permissions.cvm_ops_add_discounts_all.id)) ||
    useSelector(selectCanIDO(permissions.cvm_ops_add_retention_discounts.id))
  const permissionsCvmOpsAddMultisimDiscounts = useSelector(
    selectCanIDO(permissions.cvm_ops_add_multisim_discounts.id),
  )

  const isCustomerResidential = useSelector(customerSelectors.selectIsCustomerResidential)
  const permissionsCvmOpsDisableDiscounts = useSelector(
    selectCanIDO(permissions.cvm_ops_disable_discounts_all.id),
  )
  const canAddMultisimDiscount = !isEmpty(multisimData) && permissionsCvmOpsAddMultisimDiscounts
  const canDisableMultisimDiscount =
    !isEmpty(multisimData) &&
    !subscriptionCannotBeModified &&
    permissionsCvmOpsDisableDiscounts &&
    isDisableDiscountsFlagEnabled

  const permissionsCvmOpsAddDevices = useSelector(selectCanIDO(permissions.cvm_ops_add_devices.id))

  const permissionsCvmOpsAddBundlesAll = useSelector(
    selectCanIDO(permissions.cvm_ops_add_bundles_all.id),
  )
  const permissionsCvmOpsModifyPermanencyFixed = useSelector(
    selectCanIDO(permissions.cvm_ops_modify_permanency_fixed.id),
  )
  const permissionsCvmOpsModifyPermanencyMobile = useSelector(
    selectCanIDO(permissions.cvm_ops_modify_permanency_mobile.id),
  )
  const permissionsCvmOpsUnsubscribePackage = useSelector(
    selectCanIDO(permissions.cvm_ops_unsubscribe_package.id),
  )
  const permissionsCvmOpsAddMobileLine = useSelector(
    selectCanIDO(permissions.cvm_ops_add_mobile_line.id),
  )

  const isElFijo = isElFijoTariff(get(subscription, 'type'))

  const permanentFixed = findFixedPermanents(subscription)
  const permanentMobile = findMobilePermanents(subscription)
  const totalPermanentFixed = findTotalPermanent(CVM_FIXED_PENALTY_KEY, permanentFixed)
  const totalpermanentMobile = findTotalPermanent(CVM_MOBILE_PENALTY_KEY, permanentMobile)
  const productFixed = findFixedProduct(subscription)
  const productMobile = findMobileProduct(subscription)

  const [shouldCheckLimit, setShouldCheckLimit] = useState(false)
  const [shouldCheckXsellAllowed, setShouldCheckXsellAllowed] = useState(false)
  const [shouldCheckDeviceRenewalAllowed, setShouldCheckDeviceRenewalAllowed] = useState(false)
  const [shouldCheckMultiSimAllowed, setShouldCheckMultiSimAllowed] = useState(false)

  const subscriptionTariffId = getSubscriptionTariffId(subscription)
  const subscriptionDuoPrincipal = useSelector(getSubscriptionDuoPrincipal)
  const duoPrincipalhasModifications = useSelector(state => {
    return getHasModifications(state, getMsisdn(subscriptionDuoPrincipal))
  })
  const isDuoPrincipalNewTariff = useSelector(state =>
    getIsDuoPrincipalNewTariff(state, getMsisdn(subscriptionDuoPrincipal)),
  )

  const orderByProductId = useSelector(state =>
    getSubscriptionMostRecentOrder(state, getMsisdn(subscription)),
  )
  const contractId = orderByProductId?.contractId
  const orderId = orderByProductId?.id

  const dispatch = useDispatch()
  const [salesOperationSelected, setSalesOperationSelected] = useState(null)

  const { saveLatestAccessToken } = useForceSaveAuth()

  const crossWarnings = useMemo(
    () =>
      canCrossSell && salesOperationSelected === CROSS_SUBSCRIPTION_OPERATION
        ? getCrossWarnings(subscription, clientChildrenPackages)
        : {},
    [canCrossSell, salesOperationSelected, subscription, clientChildrenPackages],
  )

  const hasSubscriptionOneCrossSellOnFly =
    isSubscriptionMobileOnly &&
    find(customerOrders, {
      mobileNumber: get(subscription, 'id'),
      superStatus: 'fly',
      type: 'Convergente',
    })

  const { navigateToNewSubscription } = useAddSubscription('active')
  const { navigateToCrossSubscription } = useCrossSubscription('active')
  const { navigateToAddMultiSim } = useAddMultiSim('active')

  const tariff = useSelector(selectTariffsApigeeById(subscriptionTariffId))

  const tariffSegment = getTariffSegment(tariff)

  const navigateToDeviceRenewal = id => {
    saveLatestAccessToken()
    window.open(
      `${window.location.origin}/device-renewal/${id}?channel=${
        channel === NEWTON ? TELESALES : channel
      }&tariffSegment=${tariffSegment}`,
      '_blank',
    )
  }

  const subscriptionDiscounts = useSelector(getSubscriptionDiscounts(subscription.id))
  const multisimSubscriptionDiscount = useSelector(
    getSubscriptionDiscounts(!isEmpty(multisimData) && head(multisimData).id),
  )

  const discountIdQueryParam = get(currentQueryParams, 'discount_id', null)
  const cleanQueryParams = () => {
    const finalQueryParams = omit(currentQueryParams, ['op', 'subscription_id', 'discount_id'])

    const toPath = buildPath(AppPaths.cvm.id, { documentType, documentId }, finalQueryParams)
    dispatch(push(toPath))
  }

  const [isOpenAddAgileTv, setIsOpenAddAgileTv] = useState(false)
  const [openSvasModal, setOpenSvasModal] = useState(false)

  const onSuccessAddAgileTv = () => {
    dispatch(initCustomerOffersConfiguration())
  }

  const operationsEnabled =
    useSelector(selectCanIDO(permissions.client_ops_access.id)) &&
    productFixed &&
    isFixedLine(productFixed)
  const operationsLink = operationsEnabled
    ? buildPath(
        AppPaths.client_operations.id,
        {},
        {
          documentId,
          documentType,
          fixedNumber: productFixed.id,
          uuid: '',
          sfid: '',
          channel: 'newton',
          brand: 'yoigo',
        },
      )
    : ''

  const [isModifyPhoneNumberModalOpen, setIsModifyPhoneNumberModalOpen] = useState(false)
  const [isModifyEmailModalOpen, setIsModifyEmailModalOpen] = useState(false)

  const {
    svas,
    isLoadingSvas,
    sendAlarmCadence,
    isSmartHomeCompatible,
    isHomeGoCompatible,
    isAlarmsCadenceError,
    isAlarmsCadenceSuccess,
    isAlarmsCadenceLoading,
    resetAlarmCadence,
    clientContactData,
    isValidPhonesContact,
    isValidEmailContact,
  } = useCadenceAlarms(
    subscriptionTariffId,
    isCustomerResidential,
    productFixed?.id,
    subscription,
    false,
    isCompany,
    openSvasModal,
  )

  const onClearSubscription = () => dispatch(clearSubscription(getMsisdn(subscription)))

  const onUpdateSubscription = data => {
    dispatch(clearSubscription(getMsisdn(subscription)))
    dispatch(updateSubscription(getMsisdn(subscription), data))

    if (get(data, 'showNewTariffDuoPrincipal') && !duoPrincipalhasModifications) {
      const msisdnDuo = getMsisdn(subscriptionDuoPrincipal)
      dispatch(updateSubscription(msisdnDuo, generateTariffDuoPrincipal(taxRate)))
    }
  }

  const onUnsubscribe = () =>
    onModalOpen(
      UnsubscribeModal,
      {
        subscription,
        onUpdateSubscription,
        onConfirm: data => {
          onClearSubscription()
          onUpdateSubscription(data)
        },
      },
      'Dar de baja',
    )

  const closeSetSvaNotificationModal = () => {
    setShowNotification(false)
    dispatch(notifySetSvasReset())
  }

  const closeDiscountdisableNotificationModal = () => {
    setShowNotification(false)
    dispatch(notifyDiscountsDisableReset())
  }

  const generateDisableDiscountsResults = (succeededDiscounts, failedDiscounts, hasError) => {
    if (!hasError) {
      return null
    }

    return (
      <>
        {!!succeededDiscounts.length && (
          <>
            <Typography {...TextStyles.title2MediumDark()}>
              Descuentos desactivados correctamente:
            </Typography>

            <ul className="m-t-24 m-b-32">
              {succeededDiscounts.map(discount => (
                <li key={discount.discountId} className="m-12">
                  <Typography {...TextStyles.labelDark()}>
                    {`${discount.name} ${formatDiscountValue(
                      applyTaxesToDiscount(discount, taxRate),
                    )}`}
                  </Typography>
                </li>
              ))}
            </ul>
          </>
        )}

        {!!failedDiscounts.length && (
          <>
            <Typography {...TextStyles.title2MediumDark()}>
              Descuentos que no se pudieron desactivar:
            </Typography>

            <ul className="m-t-24 m-b-32">
              {failedDiscounts.map(discount => (
                <li key={discount.discountId} className="m-12">
                  <Typography {...TextStyles.labelDark()}>
                    {`${discount.name} ${formatDiscountValue(
                      applyTaxesToDiscount(discount, taxRate),
                    )}`}
                  </Typography>
                </li>
              ))}
            </ul>
          </>
        )}
      </>
    )
  }

  const svaInstallationAddress = useMemo(() => {
    const fixedInstallAddress = get(installationAddressFixedLine, 'medium')

    return isSubscriptionMobileOnly ? billingAddress : fixedInstallAddress
  }, [installationAddressFixedLine, isSubscriptionMobileOnly, billingAddress])

  const svaInstallationAddressType = useMemo(() => {
    if (!getAddressStreetType(svaInstallationAddress)) {
      return null
    }

    return isSubscriptionMobileOnly ? BILLING_ADDRESS : INSTALLATION_ADDRESS
  }, [svaInstallationAddress])

  const installationAddressFormatted = formatAddressStr(svaInstallationAddress)

  function NotificationDisableDiscounts() {
    const { error = {}, succeededDiscounts = [], failedDiscounts = [] } = disableDiscounts

    const hasError = !!error?.info

    const notificationType = hasError ? 'alert' : 'success'
    const buttonLabel = hasError ? 'PROBAR DE NUEVO' : 'ACEPTAR'
    const title = hasError
      ? 'No se ha podido desactivar alguno de los descuentos'
      : '¡Has desactivado los descuentos con éxito!'

    return (
      <NotificationModal
        type={NOTIFICATION_MODAL_TYPES[notificationType].type}
        src={NOTIFICATION_MODAL_TYPES[notificationType].iconPath}
        title={title}
        onClose={() => closeDiscountdisableNotificationModal()}
        isOpen={showDisableDiscountsNotification}>
        {generateDisableDiscountsResults(succeededDiscounts, failedDiscounts, hasError)}

        <Button data-hook="action-accept" onClick={() => closeDiscountdisableNotificationModal()}>
          {buttonLabel}
        </Button>
      </NotificationModal>
    )
  }

  function NotificationAddSva() {
    const { error = {} } = setSvas

    const hasError = !!get(error, 'info', null) || (isAlarmsCadenceError && !isAlarmsCadenceSuccess)
    const msgError = hasError && !isEmpty(isAlarmsCadenceError) ? isAlarmsCadenceError : null

    const notificationType = hasError ? 'alert' : 'success'
    const errorMsgButtonLabel = msgError ? TO_ACCEPT : TRY_AGAIN
    const buttonLabel = hasError ? errorMsgButtonLabel : TO_ACCEPT

    const svaName = showNotificationAlarmsSva
      ? SVAS_TYPES_CODE_TEXT[changingSvaSelected?.alarmType]
      : 'Hebe'
    const title = hasError
      ? 'No se ha podido añadir el SVA'
      : `¡Has añadido el SVA ${svaName} con éxito!`

    const closeModal = () => {
      if (showNotificationAlarmsSva) {
        resetAlarmCadence()
        setShowNotificationAlarmsSva(false)
      } else {
        closeSetSvaNotificationModal()
      }
      setChangingSvaSelected(null)
    }

    return (
      <NotificationModal
        type={NOTIFICATION_MODAL_TYPES[notificationType].type}
        src={NOTIFICATION_MODAL_TYPES[notificationType].iconPath}
        title={title}
        message={msgError}
        onClose={closeModal}
        isOpen={showSetSvasNotification || (showNotificationAlarmsSva && !isAlarmsCadenceLoading)}>
        <Button data-hook="action-accept" onClick={closeModal}>
          {buttonLabel}
        </Button>
      </NotificationModal>
    )
  }

  function Notification() {
    return (
      <NotificationModal
        type="alert"
        src="/assets/error.svg"
        title={setModalTitle(segment)}
        onClose={() => setShowNotification(false)}
        isOpen={showNotification}>
        {isProSegment(segment) && (
          <Typography {...TextStyles.title2Dark()}>
            Funcionalidad disponible proximamente.
          </Typography>
        )}
      </NotificationModal>
    )
  }

  const [openNewProductModal, setOpenNewProductModal] = useState(false)

  useEffect(() => {
    if (commercialMigrationSelected || changeTechnologySelected) {
      dispatch(actions.findCoverage({ gescal: currentGescal }))
    }
  }, [commercialMigrationSelected, changeTechnologySelected])

  useEffect(() => {
    if (commercialMigrationSelected) {
      if (coverageFailure) {
        openTechnologyOperationErrorModal({
          title: 'Se ha producido un error',
          errorMessage: `No se ha podido recuperar la información de cobertura por el siguiente error:  ${coverageErrorMessage}`,
          closeModal: true,
        })
      } else if (coverageSuccess) {
        if (clientTerritoryOwner === coverageTerritoryOwner) {
          openTechnologyOperationErrorModal({
            title: 'No es posible tramitar la migración comercial',
            errorMessage: `El territory owner origen y destino es el mismo para esta subscripción`,
            closeModal: true,
          })
        } else {
          dispatch(initParentTechnology(clientTerritoryOwner))
          dispatch(initCoverageTechnology(coverageTerritoryOwner))
        }
      }
    }
  }, [coverageFailure, coverageSuccess, commercialMigrationSelected])

  useEffect(() => {
    if (changeTechnologySelected) {
      if (coverageFailure) {
        openTechnologyOperationErrorModal({
          title: 'Se ha producido un error',
          errorMessage: `No se ha podido recuperar la información de cobertura por el siguiente error:  ${coverageErrorMessage}`,
          closeModal: true,
        })
      } else if (coverageSuccess) {
        if (componentCoverageTechnology === ADSL) {
          openTechnologyOperationErrorModal({
            title: 'No es posible tramitar el cambio de tecnología',
            errorMessage: 'La tecnología destino no puede ser ADSL',
            closeModal: true,
          })
        } else {
          setOpenChangeTechnologyModal(true)
          dispatch(initCoverageTechnology(coverageTerritoryOwner))
        }
      }
    }
  }, [coverageFailure, coverageSuccess, changeTechnologySelected])

  useEffect(() => {
    if (commercialMigrationSelected) {
      if (technologyFailure) {
        openTechnologyOperationErrorModal({
          title: 'Se ha producido un error',
          errorMessage: `No se ha podido recuperar la información de tecnología por el siguiente error:  ${coverageTechnologyErrorMessage}`,
          closeModal: true,
        })
      }
    }
  }, [technologyFailure])

  const onCommercialMigrationError = (title, errorMessage, errorDetails, onRetry) => {
    openTechnologyOperationErrorModal({
      title,
      errorMessage,
      errorDetails,
      onRetry,
    })
  }

  const commercialMigrationProps = {
    show: openCommercialMigrationModal,
    onClose: isCancel => {
      dispatch(actions.coverageReset())
      setOpenCommercialMigrationModal(false)

      if (isCancel) {
        setCommercialMigrationSelected(null)
      }
    },
    onError: onCommercialMigrationError,
    currentTariff: tariff,
    tariffSegment,
    subscription,
    subscriptionId,
    taxNeeded,
    coverageTerritoryOwner,
    coverageAddressId,
    currentFee,
  }

  const changeTechnologyProps = {
    show: openChangeTechnologyModal,
    onClose: isCancel => {
      dispatch(actions.coverageReset())
      setOpenChangeTechnologyModal(false)
      if (isCancel) {
        setChangeTechnologySelected(null)
      }
    },
    onError: () => {
      openTechnologyOperationErrorModal({
        title: 'Se ha producido un error',
        errorMessage: `No se ha podido realizar el cambio de tecnología`,
      })
    },
    currentTariff: tariff,
    currentAddress: installationAddressFormatted,
    tariffSegment,
    subscription,
    subscriptionId,
    taxNeeded,
    coverageTerritoryOwner,
    coverageAddressId,
    currentFee,
    isIndirectFiber,
  }

  const changeAddressProps = {
    show: openChangeAddressModal,
    onClose: (isCancel, changeIsAdsl) => {
      setOpenChangeAddressModal(false)
      setChangeAddressIsAdsl(changeIsAdsl)

      if (isCancel) {
        setChangeAddressSelected(null)
      }
    },
    onError: () => {
      openTechnologyOperationErrorModal({
        title: 'Se ha producido un error',
        errorMessage: `No se ha podido realizar el cambio de domicilio`,
      })
    },
    actualInstallationAddress: svaInstallationAddress,
    currentTariff: tariff,
    tariffSegment,
    subscription,
    subscriptionId,
    taxNeeded,
    clientTerritoryOwner,
    isIndirectFiber,
    canChangeAddressIndirectFiber,
    currentGescal17: currentGescal?.substr(0, 17),
  }

  useEffect(() => {
    if (technologySuccess) {
      if (commercialMigrationSelected) {
        if (coverageTechnology === parentTechnology) {
          openTechnologyOperationErrorModal({
            title: 'No es posible tramitar la migración comercial',
            errorMessage: 'La tecnología origen y destino es la misma para esta subscripción',
            closeModal: true,
          })
        } else if (!checkCoverageTechnology) {
          openTechnologyOperationErrorModal({
            title: 'No es posible tramitar la migración comercial',
            errorMessage: 'La tecnología destino no puede ser ADSL',
            closeModal: true,
          })
        } else {
          setOpenCommercialMigrationModal(true)
        }
      } else if (changeAddressSelected) {
        if (coverageTechnology === ADSL_TERRITORY_OWNER) {
          openTechnologyOperationErrorModal({
            title: 'No se permite el cambio de domicilio',
            errorMessage: 'No se puede realizar cambio de domicilio a tecnología ADSL',
            closeModal: true,
          })
        } else if (isIndirectFiber) {
          if (canChangeAddressIndirectFiber) {
            dispatch(initInstaller(gescal17))
          } else {
            openTechnologyOperationErrorModal({
              title: 'No se permite el cambio de domicilio',
              errorMessage:
                'Desde esta opción no puedes tramitar el Cambio de Domicilio a Fibra Indirecta. Debes realizarlo desde Operaciones de Cliente',
              closeModal: true,
            })
          }
        }
      } else if (changeTechnologySelected) {
        if (isIndirectFiber) {
          dispatch(initInstaller(gescal17FromAddressId))
        }
      }
    }
  }, [technologySuccess])

  useEffect(() => {
    if (commercialMigrationSuccess || changeTechnologySuccess || changeAddressSuccess) {
      setIsTechnologyOperationSuccessModalOpen(true)
    }
  }, [commercialMigrationSuccess, changeTechnologySuccess, changeAddressSuccess])

  useEffect(() => {
    if (
      !salesOperationSelected ||
      salesOperationSelected === ADD_SUBSCRIPTION_OPERATION ||
      (salesOperationSelected === CROSS_SUBSCRIPTION_OPERATION &&
        compact(Object.values(crossWarnings)).length > 0)
    ) {
      setOpenNewProductModal(salesOperationSelected !== null)
    } else if (salesOperationSelected === CROSS_SUBSCRIPTION_OPERATION) {
      setShouldCheckLimit(true)
    } else if (salesOperationSelected === ADD_MULTISIM_OPERATION) {
      setShouldCheckMultiSimAllowed(true)
    }
  }, [salesOperationSelected])

  const confirmAlarmCadence = (address, svaSelected, phones, email) => {
    const isCarteraFlow = true

    sendAlarmCadence(
      contractId,
      orderId,
      address,
      isCarteraFlow,
      svaSelected.alarmType,
      svaSelected,
      phones,
      email,
    )
    setShowNotificationAlarmsSva(true)
  }

  function showModalModifyAddress(svaSelected, { phones, email } = {}) {
    const hasStreetAddress =
      svaSelected.alarmType === SVA_SMARTHOME_CODE || getAddressStreetType(svaInstallationAddress)

    if (
      svaInstallationAddress &&
      hasStreetAddress &&
      !svaSelected.alarmType.includes(SVA_HOMEGO_CODE)
    ) {
      confirmAlarmCadence(svaInstallationAddress, svaSelected, phones)
    } else {
      onModalOpen(
        AddressChooserModal,
        {
          onConfirm: address =>
            confirmAlarmCadence(address || svaInstallationAddress, svaSelected, phones, email),
          preselectedAddress: {
            address: getBillingAddressStr(svaInstallationAddress),
          },
          preselectedAddressType: svaInstallationAddressType,
          allowInputManualAddress: !(isConvergent || is2P),
          isRequiredFields: !(isConvergent || is2P),
        },
        'Editar datos de instalación del SVA',
      )
    }
  }

  function validateHomeGoContactInfo(svaSelected, { phones, email } = {}) {
    if (!isValidPhonesContact() && !phones && svaSelected.alarmType.includes(SVA_HOMEGO_CODE)) {
      setIsModifyPhoneNumberModalOpen(true)
    } else if (
      !isValidEmailContact() &&
      !email &&
      svaSelected.alarmType.includes(SVA_HOMEGO_CODE) &&
      !isPosOrMasProximo
    ) {
      setIsModifyEmailModalOpen(true)
    } else {
      showModalModifyAddress(svaSelected, { phones, email })
    }
  }

  const onConfirmSvasModal = svasSelected => {
    const sva = get(svasSelected, '[0]')

    if (sva) {
      setChangingSvaSelected(sva)
      validateHomeGoContactInfo(sva)
    }
  }

  const svasList = [...(isSmartHomeCompatible || isHomeGoCompatible ? svas : [])]

  function ModalSvas() {
    return (
      openSvasModal && (
        <Modal
          isOpen={openSvasModal}
          onClose={() => {
            setOpenSvasModal(false)
            cleanQueryParams()
          }}
          title="Añadir SVA"
          overFlowY="auto">
          <SvasSetModal
            subscription={subscription}
            tariffId={subscriptionTariffId}
            svas={svasList}
            isLoadingSvas={isLoadingSvas}
            onConfirmSvas={onConfirmSvasModal}
            onClose={() => setOpenSvasModal(false)}
          />
        </Modal>
      )
    )
  }

  const handleErrorModalOnClose = () => {
    dispatch(actions.coverageReset())
    closeTechnologyOperationErrorModal()

    resetTechnologyOperationSelected()
  }

  const TechnologyOperationErrorModal = () => {
    const title = get(technologyOperationError, 'title', '')
    const errorMessage = get(technologyOperationError, 'errorMessage', '')
    const errorDetails = get(technologyOperationError, 'errorDetails', '')
    const closeModal = get(technologyOperationError, 'closeModal')
    const onRetry = get(technologyOperationError, 'onRetry')

    useEffect(() => {
      if (isTechnologyOperationErrorModalOpen && closeModal) {
        if (commercialMigrationSelected) {
          setOpenCommercialMigrationModal(false)
        } else if (changeTechnologySelected) {
          setOpenChangeTechnologyModal(false)
        } else if (changeAddressSelected) {
          setOpenChangeAddressModal(false)
        }
      }
    }, [isTechnologyOperationErrorModalOpen, closeModal])

    return (
      isTechnologyOperationErrorModalOpen && (
        <NotificationModal
          type="alert"
          src="/assets/error.svg"
          title={title}
          isOpen={isTechnologyOperationErrorModalOpen}
          onClose={handleErrorModalOnClose}>
          <Typography {...TextStyles.title2Dark()}>{errorMessage}</Typography>

          {errorDetails && (
            <Box mt="14px">
              <Typography {...TextStyles.paragraphDark()}>{errorDetails}</Typography>
            </Box>
          )}

          {onRetry && (
            <Box mb="15px">
              <Button onClick={onRetry}>Reintentar</Button>
            </Box>
          )}
        </NotificationModal>
      )
    )
  }

  const [isShowAppointmentModal, setIsShowAppointmentModal] = useState(false)

  const TechnologyOperationSuccessModal = () => {
    const commercialMigrationAppointment =
      commercialMigrationSelected && canCommercialMigrationAppointment
    const changeTechnologyAppointment =
      changeTechnologySelected && canChangeTechnologyAppointment && installer !== TESA_INSTALLER
    const changeAddressAppointment =
      changeAddressSelected &&
      canChangeAddressAppointment &&
      !changeAddressIsAdsl &&
      installer !== TESA_INSTALLER
    const canAccessToAppointment =
      commercialMigrationAppointment || changeTechnologyAppointment || changeAddressAppointment

    const modalMessages = {
      commercial_migration: {
        title: 'Migración Comercial',
        successMessage1: 'La migración comercial se ha ejecutado con éxito.',
        successMessage2: 'Se procederá a la citación para la misma.',
      },
      change_address: {
        title: 'Cambio de domicilio',
        successMessage1: 'El cambio de domicilio se ha ejecutado con éxito.',
        successMessage2: 'Se procederá a la citación para la misma.',
      },
      change_technology: {
        title: 'Cambio de tecnología',
        successMessage1: 'El cambio de tecnología se ha ejecutado con éxito.',
        successMessage2: 'Se procederá a la citación para la misma.',
      },
    }
    const getModalMessages = () => {
      if (commercialMigrationSelected) {
        return modalMessages.commercial_migration
      }

      if (changeTechnologySelected) {
        return modalMessages.change_technology
      }

      return modalMessages.change_address
    }

    const modalMessagesBuild = getModalMessages()

    return (
      isTechnologyOperationSuccessModalOpen && (
        <NotificationModal
          type="success"
          src="/assets/clap_clap.svg"
          title={modalMessagesBuild.title}
          isOpen={isTechnologyOperationSuccessModalOpen}>
          <Typography {...TextStyles.title2Dark()}>{modalMessagesBuild.successMessage1}</Typography>
          {canAccessToAppointment && (
            <Box mt="14px" mb="35px">
              <Typography {...TextStyles.title2Dark()}>
                {modalMessagesBuild.successMessage2}
              </Typography>
            </Box>
          )}
          <Button
            onClick={() => {
              setIsTechnologyOperationSuccessModalOpen(false)

              if (canAccessToAppointment) {
                setIsShowAppointmentModal(true)
              } else {
                resetTechnologyOperationSelected()
              }
            }}>
            Aceptar
          </Button>
        </NotificationModal>
      )
    )
  }

  const getKairosOrder = () => {
    if (commercialMigrationSelected) {
      return commercialMigration?.id
    }

    if (changeTechnologySelected) {
      return changeTechonology?.id
    }

    return changeAddress?.id
  }

  const kairosAppointmentProps = {
    show: isShowAppointmentModal,
    data: {
      docNumber: documentId,
      order: getKairosOrder(),
      hasDeviceDelivery: false,
    },
    delay: 5000,
    onSuccess: () => {
      setIsShowAppointmentModal(false)
      resetTechnologyOperationSelected()
    },
    onError: err => {
      setIsShowAppointmentModal(false)
      onCommercialMigrationError(
        'No se ha podido citar al cliente',
        'Por favor intente la citación más adelante',
        err,
        () => {
          closeTechnologyOperationErrorModal()
          setIsShowAppointmentModal(true)
        },
      )
    },
    onClose: () => {
      setIsShowAppointmentModal(false)
      resetTechnologyOperationSelected()
    },
  }

  const menuActions = [
    {
      id: CVMActions.change_tariff.id,
      label: 'Cambiar Tarifa',
      onClick: () => {
        onModalOpen(
          TariffsModalContainer,
          {
            onConfirm: onUpdateSubscription,
            subscription,
            onTitleChange,
            taxNeeded,
            tariffSegment,
            subscriptionId,
          },
          'Modificar tarifa',
          null,
          classes,
        )
      },
      disabled:
        (subscriptionCannotBeModified || !permissionsCvmOpsTariffChange || isElFijo) &&
        !isDuoPrincipalNewTariff,
    },
    ...(canChangeAddress
      ? [
          {
            id: CVMActions.change_address,
            label: 'Cambio de Domicilio',
            onClick: () => {
              setChangeAddressSelected(true)
              setOpenChangeAddressModal(true)
            },
            disabled: !isConvergent,
          },
        ]
      : []),
    ...(canChangeTechnology
      ? [
          {
            label: 'Cambio de Tecnología',
            disabled: !isAdslSubscription,
            onClick: () => setChangeTechnologySelected(true),
          },
        ]
      : []),
    ...(canCommercialMigration
      ? [
          {
            id: CVMActions.commercial_migration.id,
            label: 'Migración Comercial',
            onClick: () => setCommercialMigrationSelected(true),
            disabled: !isFtth(subscription),
          },
        ]
      : []),
    {
      id: CVMActions.add_mobile_line,
      label: 'Añadir línea adicional con descuento',
      onClick: () => {
        setSalesOperationSelected(ADD_SUBSCRIPTION_OPERATION)
      },
      disabled:
        isPos ||
        !permissionsCvmOpsAddMobileLine ||
        !subscription.isMainLine ||
        !hasIds ||
        !canEnableSellOption ||
        hasSubscriptionOneCrossSellOnFly,
    },
    ...(canCrossSell
      ? [
          {
            id: CVMActions.convertMobileSubToConvergent.id,
            label: 'Convertir a convergente',
            onClick: () => setSalesOperationSelected(CROSS_SUBSCRIPTION_OPERATION),
            disabled: isPos || !permissionsCvmOpsAddMobileLine || !hasIds || !canEnableSellOption,
          },
        ]
      : []),

    ...(canMultiSim
      ? [
          {
            id: CVMActions.add_multi_sim.id,
            label: 'Añadir MultiSIM',
            onClick: () => {
              setSalesOperationSelected(ADD_MULTISIM_OPERATION)
            },
          },
        ]
      : []),
    ...(canAddDiscounts 
      ? [
          {
            id: CVMActions.add_discounts_or_bundles.id,
            label: isElFijo ? 'Añadir descuentos' : 'Añadir descuentos o bonos',
            onClick: () => {
              onModalOpen(
                DiscountsAndBonusesModal,
                {
                  onConfirm: onUpdateSubscription,
                  subscription,
                  taxNeeded,
                },
                'Añadir descuentos o bonos',
                cleanQueryParams,
              )
            },
            disabled:
              subscriptionCannotBeModified ||
              (!(permissionsCvmOpsAddSomeDiscounts || permissionsCvmOpsAddBundlesAll)) && !is1WSales,
          },
        ]
      : []),
    ...(canAddMultisimDiscount
      ? [
          {
            id: CVMActions.add_multisim_discount.id,
            label: 'Añadir descuentos MultiSim',
            onClick: () => {
              onModalOpen(
                DiscountsAndBonusesModal,
                {
                  onConfirm: onUpdateSubscription,
                  subscription: head(multisimData),
                  taxNeeded,
                },
                'Añadir descuentos MultiSim',
                cleanQueryParams,
              )
            },
          },
        ]
      : []),
    {
      id: CVMActions.add_sva.id,
      label: 'Añadir SVA',
      onClick: () => {
        setOpenSvasModal(true)
      },
    },
    {
      id: CVMActions.disable_discounts_or_bundles.id,
      label: 'Desactivar descuentos',
      onClick: () => {
        onModalOpen(
          DiscountsDisableModal,
          {
            subscription,
            discounts: subscriptionDiscounts,
            discountIdQueryParam,
          },
          'Desactivar descuentos',
          cleanQueryParams,
        )
      },
      disabled:
        subscriptionCannotBeModified ||
        !permissionsCvmOpsDisableDiscounts ||
        !isDisableDiscountsFlagEnabled,
    },
    ...(canDisableMultisimDiscount
      ? [
          {
            id: CVMActions.disable_multisim_discounts.id,
            label: 'Desactivar descuentos MultiSim',
            onClick: () => {
              onModalOpen(
                DiscountsDisableModal,
                {
                  subscription: head(multisimData),
                  discounts: multisimSubscriptionDiscount,
                  discountIdQueryParam,
                },
                'Desactivar descuentos Multisim',
                cleanQueryParams,
              )
            },
          },
        ]
      : []),
    {
      id: CVMActions.modify_fixed_permanency.id,
      label: 'Modificar permanencia fija',
      onClick: () => {
        onModalOpen(
          PermanenceModal,
          {
            permanent: totalPermanentFixed,
            type: 'fixed',
            onConfirm: data =>
              onUpdateSubscription({
                [CVM_FIXED_PENALTY_KEY]: data,
              }),
          },
          'Modificar permanencia fija',
        )
      },
      disabled:
        subscriptionCannotBeModified ||
        isElFijo ||
        !productFixed ||
        size(permanentFixed) === 0 ||
        !permissionsCvmOpsModifyPermanencyFixed,
    },
    {
      id: CVMActions.modify_mobile_permanency.id,
      label: 'Modificar permanencia movil',
      onClick: () => {
        onModalOpen(
          PermanenceModal,
          {
            permanent: totalpermanentMobile,
            type: 'mobile',
            onConfirm: data =>
              onUpdateSubscription({
                [CVM_MOBILE_PENALTY_KEY]: data,
              }),
          },
          'Modificar permanencia movil',
        )
      },
      disabled:
        subscriptionCannotBeModified ||
        isElFijo ||
        !productMobile ||
        size(permanentMobile) === 0 ||
        !permissionsCvmOpsModifyPermanencyMobile,
    },
    {
      id: CVMActions.freeze.id,
      label: 'Congelar',
      onClick: null,
      disabled: true,
    },
    {
      id: CVMActions.unsubscribe.id,
      label: 'Dar de baja',
      onClick: () => (isConvergent ? onUnsubscribe() : setShowNotification(true)),
      disabled: !allowUnsubscribe || !permissionsCvmOpsUnsubscribePackage,
    },

    {
      disabled: !operationsEnabled || isElFijo,
      onClick: () => {
        saveLatestAccessToken()
        window.open(`${window.location.origin}${operationsLink}`, '_blank', 'toolbar=false')
      },
      label: 'Operaciones de cliente',
    },
    ...(tariffSegment !== 'pro' &&
    !isElFijo &&
    !is2P &&
    (isSubscriptionMobileOnly || isConvergent) &&
    subscription.is_postpaid
      ? [
          {
            label: 'Añadir dispositivo',
            onClick: () => {
              if (canDeviceRenewal) {
                setShouldCheckDeviceRenewalAllowed(true)
              } else {
                window.open(APP_CONFIG.newton_renewal, '_blank')
              }
            },
            disabled: !documentIsLoaded || !permissionsCvmOpsAddDevices,
          },
        ]
      : []),

    ...((is2P || isConvergent) && canAddAgileTv && !isPosOrMasProximo
      ? [
          {
            label: 'Añadir servicio TV',
            onClick: () => setIsOpenAddAgileTv(true),
            disabled: !documentIsLoaded || tariff?.fixed_line_type !== 'fiber',
          },
        ]
      : []),
    ...(isConvergent
      ? [
          {
            id: CVMActions.break.id,
            label: 'Romper',
            onClick: null,
            disabled: true,
          },
        ]
      : []),
  ]

  const titleHeader = (
    <>
      {' '}
      {getCommercialName(tariff)} <span className="p-x-8"> | </span> {msisdn}
    </>
  )

  const props = {
    ...restProps,
    subscription,
    title: titleHeader,
    isConvergent,
    onUpdateSubscription,
    allowUnsubscribe,
    onClearSubscription,
    menuActions,
    Notification,
    NotificationDisableDiscounts,
    NotificationAddSva,
    setShouldCheckLimit,
    shouldCheckLimit,
    shouldCheckXsellAllowed,
    setShouldCheckXsellAllowed,
    shouldCheckDeviceRenewalAllowed,
    setShouldCheckDeviceRenewalAllowed,
    shouldCheckMultiSimAllowed,
    setShouldCheckMultiSimAllowed,
    navigateToNewSubscription,
    navigateToCrossSubscription,
    navigateToDeviceRenewal,
    navigateToAddMultiSim,
    salesOperationSelected,
    setSalesOperationSelected,
    setOpenNewProductModal,
    openNewProductModal,
    hasIds,
    crossWarnings,
    classes,
    canAddAgileTv,
    isOpenAddAgileTv,
    setIsOpenAddAgileTv,
    onSuccessAddAgileTv,
    ModalSvas,
    TechnologyOperationErrorModal,
    TechnologyOperationSuccessModal,
    kairosAppointmentProps,
    clientContactData,
    isModifyPhoneNumberModalOpen,
    setIsModifyPhoneNumberModalOpen,
    isModifyEmailModalOpen,
    setIsModifyEmailModalOpen,
    validateHomeGoContactInfo,
    changingSvaSelected,
    commercialMigrationProps,
    changeTechnologyProps,
    changeAddressProps,
  }

  return <SubscriptionCard {...props} />
}

SubscriptionCardContainer.propTypes = {
  subscription: PropTypes.object,
  hasIds: PropTypes.bool,
  onModalOpen: PropTypes.func,
  onTitleChange: PropTypes.func,
}
