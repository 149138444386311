import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { selectBillingAccountId, selectAccountId } from '../../store'

export function useUpdateCarteraValues({ action, payload, flags }) {
  const [carteraValuesHaveChanged, setCarteraValuesHaveChanged] = useState()
  const dispatch = useDispatch()

  const billingAccountId = useSelector(selectBillingAccountId)
  const accountId = useSelector(selectAccountId)
  const { requiresAccountId, requiresBillingAccountId } = flags

  function updateValues() {
    if (
      ((billingAccountId && requiresBillingAccountId) || (accountId && requiresAccountId)) &&
      carteraValuesHaveChanged
    ) {
      dispatch(action(payload))
    }
  }

  useEffect(() => {
    return () => updateValues()
  }, [carteraValuesHaveChanged])

  return { setCarteraValuesHaveChanged }
}
