import { createSelector } from 'reselect'
import { get, remove } from 'lodash'

import { selectSfid } from 'modules/Auth'
import { selectSaleTax } from 'modules/NewClientSales/store/selectors/Taxes.selectors'
import { selectSVAAgileTv } from 'modules/NewClientSales/store/selectors/Basket.selectors'

import { selectSvaSmartHomeFlag, selectSvaHomeGoFlag } from 'services/feature-flag/selectors'
import { SVA_SMARTHOME_CODE, SVA_HOMEGO_CODE } from 'modules/offers-configuration/constants'
import { formatSVAS, addPriceTaxSvas, mapSvasWithHomeGo } from '../helpers'
import { formatSingleSVA, isBibeAllowed } from '../helpers/helpers'

const getSvas = state => get(state, 'svas', {})

export const selectSVAPro = createSelector(getSvas, svas => svas.pro)

export const selectSVAAgile = createSelector(getSvas, svas => svas.agile)

const selectSVAProList = createSelector(
  selectSVAPro,
  selectSvaSmartHomeFlag,
  selectSvaHomeGoFlag,
  (svas, isSvaSmartHomeFlag, isSvaHomeGoFlag) => {
    let allSvas = get(svas, 'data', [])

     if (!isSvaSmartHomeFlag) {
      allSvas = remove(allSvas, sva => !sva.psId.includes(SVA_SMARTHOME_CODE))
    } 
    if (!isSvaHomeGoFlag) {
      allSvas = remove(allSvas, sva => !sva.psId.includes(SVA_HOMEGO_CODE))
    }

    return isSvaHomeGoFlag ? mapSvasWithHomeGo(allSvas) : allSvas
  },
)

export const selectSVAListWithTax = createSelector(
  selectSVAProList,
  selectSaleTax,
  (svasList, tax) => addPriceTaxSvas(svasList, tax),
)

export const selectIsSVAProListLoading = createSelector(
  selectSVAPro,
  svas => get(svas, 'loading') === true,
)

export const selectSVAAgileList = createSelector(selectSVAAgile, svas => get(svas, 'data', []))

export const selectIsSVAAgileListLoading = createSelector(
  selectSVAAgile,
  svas => get(svas, 'loading') === true,
)

export const selectSVAAgileListError = createSelector(selectSVAAgile, svas =>
  get(svas, 'error_code'),
)

export const selectSVAAgileListMsgError = createSelector(selectSVAAgile, svas =>
  get(svas, 'error_message'),
)

export const selectSVAAgileListIsSuccess = createSelector(selectSVAAgile, svas =>
  get(svas, 'success'),
)

export const selectSVAAgileListFormated = createSelector(
  selectSVAAgileList,
  selectSaleTax,
  (list, tax) =>
    formatSVAS(list, tax).filter(s => s.withStb || (!s.withStb && s.withStb !== false)),
)

export const selectSVAAgileStandard = createSelector(
  selectSVAAgileList,
  selectSVAAgileTv,
  (SVAs, agileTv) => SVAs.find(sva => sva.psId === agileTv.psId && sva.withStb),
)

export const selectSVAAgileAppleTv = createSelector(
  selectSVAAgileList,
  selectSVAAgileTv,
  (SVAs, agileTv) => SVAs.find(sva => sva.psId === agileTv.psId && sva.withStb === false),
)

export const selectSameEmail = createSelector(getSvas, svas => svas.sameEmail)

export const selectAllSVAList = createSelector(
  selectSVAProList,
  selectSVAAgileListFormated,
  (proSva, agileTv) => [...proSva, ...agileTv],
)

export const selectAgileTvFormated = createSelector([selectSVAAgileAppleTv], agileTvSva => {
  return !agileTvSva ? null : formatSingleSVA(agileTvSva)
})

export const selectRenewalSVAAgile = createSelector(getSvas, svas => svas.renewalAgile)

export const selectRenewalSVAAgileLoading = createSelector(
  selectRenewalSVAAgile,
  renewalSvas => get(renewalSvas, 'loading') === true,
)

export const selectRenewalSVAAgileList = createSelector(selectRenewalSVAAgile, svas =>
  get(svas, 'data', []),
)

export const selectRenewalAgileTvFormated = createSelector(
  [selectRenewalSVAAgileList],
  agileTvSva => {
    return !agileTvSva || agileTvSva.length === 0 ? null : formatSingleSVA(agileTvSva[0])
  },
)

export const selectRenewalSVAAgileError = createSelector(selectRenewalSVAAgile, svas =>
  get(svas, 'error_code'),
)

export const selectIsBibeAllowed = createSelector(selectSfid, sfid => isBibeAllowed(sfid))
