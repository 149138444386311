import PropTypes from 'prop-types'
import { Box, Divider, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import { TextStyles } from 'utils/text'
import { formatNumberWithComma } from 'utils'

const useStyles = makeStyles({
  'tariff-final-price': {
    color: '#2e2e2e',

    '& h6, & .price, & .duration': {
      fontWeight: 'bold',
    },
  },
})

export function TariffPickerFinalPrice({ selectedTariff, isPro }) {
  const classes = useStyles()

  return (
    <div className={classes['tariff-final-price']}>
      <Divider />
      {selectedTariff?.discountPrice && selectedTariff?.discountPrice?.length > 0 ? (
        <Box display="flex" pt="14.5px" pb="17px" px="24px" flexWrap="wrap">
          <Typography variant="subtitle1" className="p-r-16">
            Precio final:
          </Typography>
          {selectedTariff?.discountPrice?.map((elem, index) =>
            elem.duration ? (
              /* eslint-disable react/no-array-index-key */
              <Box display="flex" key={`price-${index}`}>
                <Typography variant="subtitle1" component="span" className="p-r-4 price">
                  {formatNumberWithComma(elem.amount)} €/mes
                </Typography>
                <Typography variant="subtitle1" component="span" className="p-r-4">
                  durante
                </Typography>
                <Typography variant="subtitle1" component="span" className="p-r-4 duration">
                  {elem.duration - (selectedTariff.discountPrice[index - 1]?.duration || 0)}
                  {elem.duration > 1 ? ' meses' : ' mes'}
                </Typography>
                <Typography variant="subtitle1" component="span" className="p-r-4">
                  , después
                </Typography>
              </Box>
            ) : (
              /* eslint-disable react/no-array-index-key */
              <Typography
                key={`price-${index}`}
                variant="subtitle1"
                component="span"
                className="p-r-4 price">
                {formatNumberWithComma(elem.amount)} €/mes
              </Typography>
            ),
          )}
          {isPro && (
            <Typography variant="subtitle1" className="p-r-16">
              (impuestos no incl.)
            </Typography>
          )}
        </Box>
      ) : (
        <div className="flex">
          <Typography
            {...TextStyles.paragraphDark({
              className: 'p-r-4',
            })}>
            Precio resultante:
          </Typography>
          <Typography
            {...TextStyles.subtitle2Dark({
              className: 'p-r-4',
            })}>
            {formatNumberWithComma(selectedTariff?.priceWithTax)}
          </Typography>
          <Typography {...TextStyles.paragraphDark()}>€/mes</Typography>
        </div>
      )}
    </div>
  )
}

TariffPickerFinalPrice.propTypes = {
  selectedTariff: PropTypes.any,
  isPro: PropTypes.bool,
}
