import { axiosJWT } from 'modules/axios'
import { CONFIG } from './environments'

const CACHE_CONTRACT = {}

export const getContractPdf = contractId => {
  const cacheKey = btoa(JSON.stringify({ contractId }))
  if (CACHE_CONTRACT[cacheKey]) {
    return CACHE_CONTRACT[cacheKey]
  }

  CACHE_CONTRACT[cacheKey] = axiosJWT({
    method: 'GET',
    headers: {
      'Content-Type': 'application/array',
    },
    url: `${CONFIG.api.contractsPdf}/${contractId}/file`,
    responseType: 'arraybuffer',
  })
    .then(resp => {
      return resp.data
    })
    .catch(err => {
      CACHE_CONTRACT[cacheKey] = null
      throw err
    })

  return CACHE_CONTRACT[cacheKey]
}
