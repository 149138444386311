import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { isEmpty, orderBy, get, filter, isNil, groupBy, first } from 'lodash'

import { Grid, Icon } from '@material-ui/core'

import { ICONS } from 'utils/icons'

import * as customerActivitySvc from 'modules/CustomerActivity'
import { getSubscriptionByCurrentProductId } from 'services/subscriptions/selectors'
import { actions as customerActivityActions } from 'modules/CustomerActivity/store/actions'

import { SpinnerCenter, ActionAdvice } from 'components/ui'

import { Card } from 'modules/ui'

import styled from 'styled-components'
import { format, parseISO } from 'date-fns'
import { useClientDashboard } from 'modules/vista-360/hooks/useClientDashboard'
import { SVAS_TYPES_CODE_TEXT, SVA_HOMEGO_CODE } from 'modules/offers-configuration/constants'
import { selectSvaRegisterFlag } from 'services/feature-flag/selectors'
import { selectCustomerId } from 'modules/Auth'
import { Board } from '../../components/Board'

import { ListWithFilters } from '../../components/ListWithFilters'
import { EmptyActivity, ErrorActivity } from './EmptyCustomerActivity'
import { ActivityItem } from './ActivityItem'

const checkboxfilters = [
  {
    icon: 'formatLeft',
    label: 'Tipificaciones',
    value: 'typification',
  },
  {
    icon: 'description',
    label: 'Memos',
    value: 'memo',
  },
  {
    icon: 'build',
    label: 'Visita técnico',
    value: 'visits',
  },
  {
    icon: 'delivery',
    label: 'Logística',
    value: 'logistic',
  },
  {
    icon: 'work',
    label: 'Ventas',
    value: 'sales',
  },
  {
    icon: 'smsTipification',
    label: 'Live Person SMS',
    value: 'communication',
  },
  {
    icon: 'Entrante',
    label: 'Llamadas de voz',
    value: 'voiceCalls',
  },
  {
    icon: 'receipt',
    label: 'Compensaciones',
    value: 'compensation',
  },
  {
    icon: 'home',
    label: 'SVAs',
    value: 'SVA',
    flag: 'svaRegisterFlag',
  },
  {
    icon: '',
    label: SVA_HOMEGO_CODE,
    value: SVA_HOMEGO_CODE,
    subtypeFor: 'SVA',
    flag: 'svaRegisterFlag',
  },
  {
    icon: 'receipt',
    label: 'SMS YOIGO',
    value: 'SMS',
  },
  {
    icon: '',
    label: 'Atención cliente',
    value: 'Atención cliente',
    subtypeFor: 'SMS',
  },
  {
    icon: '',
    label: 'Comerciales',
    value: 'Comerciales',
    subtypeFor: 'SMS',
  },
  {
    icon: '',
    label: 'Contrato',
    value: 'Contrato',
    subtypeFor: 'SMS',
  },
  {
    icon: '',
    label: 'Incidencias masivas',
    value: 'Incidencias masivas',
    subtypeFor: 'SMS',
  },
  {
    icon: '',
    label: 'Roaming',
    value: 'Roaming',
    subtypeFor: 'SMS',
  },
  {
    icon: '',
    label: 'Otros',
    value: 'Otros',
    subtypeFor: 'SMS',
  },
]

const initialFilters = {
  startDate: null,
  endDate: null,
  filteredTypes: [],
}

const initialCheckboxFilters = { checked: [], expanded: ['SMS', 'SVA'] }

export const CustomerActivity = styled(props => {
  const dispatch = useDispatch()
  const subscription = useSelector(getSubscriptionByCurrentProductId)
  const productNumber = get(subscription, 'productNumber')
  const customerId = useSelector(selectCustomerId)
  const DEFAULT_NO_DATE = '2200-01-01T00:00:00.000Z'
  const allActivityData = useSelector(customerActivitySvc.selectData)
  const dataActivity = filter(allActivityData, register => isNil(register.error))
  const isLoading = useSelector(customerActivitySvc.selectLoading)
  const errorObject = useSelector(customerActivitySvc.selectError)
  const [filters, setFilters] = useState(initialFilters)
  const [testState, setState] = useState(initialCheckboxFilters)
  const errorInCallRegisters = useSelector(customerActivitySvc.selectIsErrorsInNodes)
  const isErrorInMemo = useSelector(customerActivitySvc.selectErrorInNode('memo'))
  const isErrorInVisits = useSelector(customerActivitySvc.selectErrorInNode('visits'))
  const isErrorInSales = useSelector(customerActivitySvc.selectErrorInNode('sales'))
  const isErrorInTypification = useSelector(customerActivitySvc.selectErrorInNode('typification'))
  const isErrorInLogistic = useSelector(customerActivitySvc.selectErrorInNode('logistic'))
  const isErrorInCommunication = useSelector(customerActivitySvc.selectErrorInNode('communication'))
  const isErrorInCompensation = useSelector(customerActivitySvc.selectErrorInNode('compensation'))

  const svaRegisterFlag = useSelector(selectSvaRegisterFlag)

  const [fetchCustomerActivityWithMsisdns] = useClientDashboard()

  const nodes = [
    {
      label: 'Tipificaciones',
      value: 'typification',
      tagClassName: 'typification',
      className: `${isErrorInTypification && 'is-error'}`,
      icon: (
        <Icon className={`icon ${isErrorInTypification && 'is-error'}`}>{ICONS.formatLeft}</Icon>
      ),
      disabled: isErrorInTypification,
    },
    {
      label: 'Memos',
      value: 'memo',
      tagClassName: 'memo',
      className: `${isErrorInMemo && 'is-error'}`,
      icon: <Icon className={`icon ${isErrorInMemo && 'is-error'}`}> {ICONS.description}</Icon>,
      disabled: isErrorInMemo,
    },
    {
      label: 'Visita técnico',
      value: 'visits',
      tagClassName: 'visits',
      className: `${isErrorInVisits && 'is-error'}`,
      icon: <Icon className={`icon ${isErrorInVisits && 'is-error'}`}>{ICONS.build}</Icon>,
      disabled: isErrorInVisits,
    },
    {
      label: 'Logística',
      value: 'logistic',
      tagClassName: 'logistic',
      className: `${isErrorInLogistic && 'is-error'}`,
      icon: <Icon className={`icon ${isErrorInLogistic && 'is-error'}`}>{ICONS.delivery}</Icon>,
      disabled: isErrorInLogistic,
    },
    {
      label: 'Ventas',
      value: 'sales',
      tagClassName: 'sales',
      className: `${isErrorInSales && 'is-error'}`,
      icon: <Icon className={`icon ${isErrorInSales && 'is-error'}`}>{ICONS.work}</Icon>,
      disabled: isErrorInSales,
    },
    {
      label: 'Live Person SMS',
      value: 'communication',
      tagClassName: 'communication',
      className: `${isErrorInCommunication && 'is-error'}`,
      icon: (
        <Icon className={`icon ${isErrorInCommunication && 'is-error'}`}>
          {ICONS.smsTipification}
        </Icon>
      ),
      disabled: isErrorInCommunication,
    },
    {
      label: 'Llamadas de voz',
      value: 'voiceCalls',
      tagClassName: 'voiceCalls',
      icon: <Icon className="icon">{ICONS.Entrante}</Icon>,
    },
    {
      label: 'Compensaciones',
      value: 'compensation',
      tagClassName: 'compensation',
      className: `${isErrorInCompensation && 'is-error'}`,
      icon: <Icon className={`icon ${isErrorInCompensation && 'is-error'}`}>{ICONS.receipt}</Icon>,
      disabled: isErrorInCompensation,
    },
    {
      label: 'SVAs',
      value: 'SVA',
      tagClassName: 'SVA',
      icon: <Icon className="icon">{ICONS.home}</Icon>,
      flag: 'svaRegisterFlag',
      children: [
        {
          label: SVAS_TYPES_CODE_TEXT[SVA_HOMEGO_CODE],
          value: SVA_HOMEGO_CODE,
          tagClassName: SVA_HOMEGO_CODE,
        },
      ],
    },
    {
      label: 'SMS YOIGO',
      value: 'SMS',
      tagClassName: 'SMS',
      icon: <Icon className="icon">{ICONS.receipt}</Icon>,
      children: [
        {
          label: 'Atención cliente',
          value: 'Atención cliente',
          tagClassName: 'Atención cliente',
        },
        {
          label: 'Comerciales',
          value: 'Comerciales',
          tagClassName: 'Comerciales',
        },
        {
          label: 'Contrato',
          value: 'Contrato',
          tagClassName: 'Contrato',
        },
        {
          label: 'Incidencias masivas',
          value: 'Incidencias masivas',
          tagClassName: 'Incidencias masivas',
        },
        {
          label: 'Roaming',
          value: 'Roaming',
          tagClassName: 'Roaming',
        },
        {
          label: 'Otros',
          value: 'Otros',
          tagClassName: 'Otros',
        },
      ],
    },
  ]

  const callFetchCustomerActivity = () => {
    if (!isNil(productNumber)) {
      dispatch(customerActivityActions.fetchCustomerActivity(productNumber.replace('+', ','), customerId))
    } else {
      fetchCustomerActivityWithMsisdns(customerId)
    }
  }

  useEffect(() => {
    callFetchCustomerActivity()
  }, [])

  function onChangeStartDate(startDate) {
    setFilters({ ...filters, startDate })
  }
  useEffect(() => {
    if (JSON.stringify(testState.checked) !== JSON.stringify(filters.filteredTypes)) {
      setFilters({
        startDate: filters.startDate,
        endDate: filters.endDate,
        filteredTypes: testState.checked,
      })
    }
  }, [testState, filters])

  function onResetFilters() {
    setFilters(initialFilters)
    setState(initialCheckboxFilters)
  }

  function onChangeEndDate(endDate) {
    setFilters({ ...filters, endDate })
  }

  const sanitizeNullDates = itemsList => {
    return itemsList.map(item => {
      const date = get(item, 'date') || get(item, 'startDate') || DEFAULT_NO_DATE
      const itemWithoutNulls = { ...item, date }
      return itemWithoutNulls
    })
  }
  function groupItemsByDay(itemsList) {
    const objByDay = groupBy(itemsList, element => {
      const date = get(element, 'date') || get(element, 'startDate') || DEFAULT_NO_DATE
      return format(parseISO(date), 'P')
    })
    const arrayItems = Object.values(objByDay).map(item => [get(first(item), 'date'), item])
    return arrayItems
  }

  function filterByFlag(node) {
    return node.flag !== 'svaRegisterFlag' || svaRegisterFlag
  }

  const dataActivityWithoutNullDates = sanitizeNullDates(dataActivity)
  const dataActivityOrderByDate = orderBy(dataActivityWithoutNullDates, ['date'], ['desc'])
  const filtersSelected = filters.filteredTypes
  const dataItems = groupItemsByDay(dataActivityOrderByDate)
  return (
    <Board className={props.className}>
      {isLoading && <SpinnerCenter showMsg />}
      {!isLoading && errorObject && <ErrorActivity />}
      {!isLoading && !errorObject && (
        <Grid item xs={12}>
          {isEmpty(dataActivity) && <EmptyActivity />}
          {!isEmpty(dataActivity) && dataActivity && (
            <Card title="Registro interacciones">
              {errorInCallRegisters ? (
                <ActionAdvice
                  className="p-y-12 p-x-24 action-div"
                  type="error"
                  block
                  message="Se ha producido un error recuperando algunas de las interacciones, por favor, inténtelo de nuevo."
                  onRetry={() => callFetchCustomerActivity()}
                />
              ) : (
                ''
              )}

              <ListWithFilters
                items={dataItems}
                dataTree={nodes.filter(filterByFlag)}
                testState={testState}
                setState={setState}
                filters={filters}
                onChangeStartDate={onChangeStartDate}
                onChangeEndDate={onChangeEndDate}
                checkboxfilters={checkboxfilters.filter(filterByFlag)}
                filtersSelected={
                  filtersSelected.length === initialFilters.filteredTypes.length
                    ? []
                    : filtersSelected
                }
                onResetFilters={onResetFilters}
                ItemComponent={ActivityItem}
              />
            </Card>
          )}
        </Grid>
      )}
    </Board>
  )
})`
  position: relative;
  && .card-content {
    padding-top: 0;
    margin-bottom: -10px;
  }
  /* fallback, until https://github.com/CompuIves/codesandbox-client/issues/174 is resolved */
  @font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/materialicons/v36/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2)
      format('woff2');
  }

  .material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-smoothing: antialiased;
  }

  /* mdl-demo is a custom classname to increases the specificity of our styles. It can be anything.
   * The idea is that it is easy to extend/override builtin styles with very little effort.
   */

  .mdl-demo .dropdown-trigger > span:after {
    font-size: 12px;
    color: #555;
  }

  .mdl-demo .toggle {
    font: normal normal normal 18px/1 'Material Icons';
    color: #555;
    white-space: pre;
    margin-right: 4px;
  }

  .mdl-demo .toggle.collapsed::after {
    cursor: pointer;
    content: '\E5CF';
    vertical-align: middle;
  }

  .mdl-demo .toggle.expanded::after {
    cursor: pointer;
    content: '\E5CE';
    vertical-align: middle;
  }

  /* checkbox styles */
  .mdl-demo .checkbox-item {
    position: relative;
    width: 1rem;
    height: 1rem;
    margin-right: 0.75rem;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: 0;
    vertical-align: middle;
  }

  .mdl-demo .checkbox-item:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    border: 2px solid #aaa;
    transition: all 0.3s ease-in-out;
  }

  .mdl-demo .checkbox-item:checked:before {
    height: 50%;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    border-top-style: none;
    border-right-style: none;
    border-color: #2196f3;
  }
`
