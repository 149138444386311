import PropTypes from 'prop-types'
import { get } from 'lodash'

import { FormHelperText, Select, MenuItem, Box, OutlinedInput, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { TextStyles } from 'utils/text'

import { StyledFormControl, StyledInputLabel } from './componentsUtilsOutline'

const useStyles = makeStyles(theme => ({
  cssLabel: {
    color: theme.palette.global.gray,
    whiteSpace: 'nowrap',
    padding: '0 4px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: 'calc(100% - 46px)',
    minHeight: '1.2em',
  },
  cssSelect: {
    width: 'auto',
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'space-around',
    justifyContent: 'flex-start',
    '&:focus': {
      background: 'transparent',
    },
  },
  cssSelectContent: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}))

const SelectOutline = ({
  minWidth,
  labelColor,
  labelText,
  name,
  id,
  selectValue,
  onSelectChange,
  options,
  error,
  errorText,
  menuItemExtra,
  dataHook,
}) => {
  const classes = useStyles()

  return (
    <StyledFormControl variant="outlined" minwidth={minWidth} error={error}>
      <StyledInputLabel
        classes={{ root: get(classes, 'cssLabel', null) }}
        backgroundcolor={labelColor}
        htmlFor={id}
        margin="dense">
        {labelText}
      </StyledInputLabel>
      <Select
        value={selectValue}
        onChange={onSelectChange}
        data-hook={dataHook}
        classes={{
          select: get(classes, 'cssSelectContent', null),
          selectMenu: get(classes, 'cssSelect', null),
        }}
        input={<OutlinedInput labelWidth={0} name={name} id={id} margin="dense" />}>
        {options.map(option => (
          <MenuItem key={option.id || option.value} value={option.value}>
            <Box display="flex" justifyContent="space-between">
              <Typography {...TextStyles.paragraphDark()}>
                {option.label || option.value}
              </Typography>
              {menuItemExtra && menuItemExtra(option)}
            </Box>
          </MenuItem>
        ))}
      </Select>
      {error && errorText ? <FormHelperText>{errorText}</FormHelperText> : null}
    </StyledFormControl>
  )
}

SelectOutline.propTypes = {
  minWidth: PropTypes.number,
  labelColor: PropTypes.string,
  labelText: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  selectValue: PropTypes.string,
  onSelectChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  error: PropTypes.bool,
  errorText: PropTypes.string,
  menuItemExtra: PropTypes.func,
  dataHook: PropTypes.string,
}

SelectOutline.defaultProps = {
  minWidth: null,
  id: 'select',
  name: null,
  labelColor: '',
  labelText: '',
  selectValue: '',
  options: [],
  error: false,
  menuItemExtra: null,
}

export default SelectOutline
