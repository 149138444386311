import { pick, get, trim, toUpper, flatten } from 'lodash'

const arrayForFilteringInfoCard = ['id', 'orderDate', 'contractId', 'state']
const arrayForFilteringInfoTree = ['id', 'contractId']

const adaptProductCard = product =>
  product && {
    title: product.products && product.products[0] && product.products[0].type,
    products:
      product.products &&
      product.products.map(eachProduct => ({
        id: eachProduct.id,
        title: eachProduct.description,
        numbers: eachProduct.productNumber && eachProduct.productNumber.split('+'),
      })),
  }
const adaptPackageTree = product =>
  product && {
    id: product.products && product.products[0] && product.products[0].type,
    title: product.products && product.products[0] && product.products[0].type,
    meta: 'package',
    nodes:
      product.products &&
      product.products.map(eachProduct => ({
        id: eachProduct.id,
        title: eachProduct.description,
        meta: 'product',
      })),
  }

const adaptPackageSidebar = product =>
  product && {
    id: product.products && product.products[0] && product.products[0].type,
    title: product.products && product.products[0] && product.products[0].type,
    meta: 'package',
    nodes:
      product.products &&
      product.products.map(eachProduct => ({
        id: eachProduct.id,
        title: eachProduct.description,
        meta: 'product',
        nodes:
          eachProduct.productsSpecifications &&
          eachProduct.productsSpecifications.map(lines => ({
            id: lines.id,
            title: lines.name,
            meta: 'line',
          })),
      })),
  }

const filterInfoProductsCard = products =>
  products &&
  products.map(product => ({
    ...pick(product, arrayForFilteringInfoCard, {}),
    products: get(product, 'customer.products', ''),
  }))
const filterInfoProductsTree = products =>
  products &&
  products.map(product => ({
    ...pick(product, arrayForFilteringInfoTree, {}),
    products: get(product, 'customer.products', ''),
  }))

const createStructureCard = products =>
  products &&
  products.reduce((structure, product) => {
    const structureProduct = structure.find(
      productInStructure => productInStructure.contractId === product.contractId,
    )
    if (structureProduct) {
      structureProduct.packages = structureProduct.packages.concat(adaptProductCard(product))
    } else {
      return structure.concat({
        id: product.id,
        title: product.contractId,
        packages: [adaptProductCard(product)],
      })
    }
    return structure
  }, [])
const createStructureTree = products =>
  products &&
  products.reduce((structure, product) => {
    const structureProduct = structure.find(
      productInStructure => productInStructure.contractId === product.contractId,
    )
    if (structureProduct) {
      structureProduct.packages = structureProduct.packages.concat(adaptPackageTree(product))
    } else {
      return structure.concat({
        id: product.id,
        title: product.contractId,
        meta: 'contract',
        nodes: [adaptPackageTree(product)],
      })
    }
    return structure
  }, [])
const createStructureSidebar = products =>
  products &&
  products.reduce((structure, product) => {
    const structureProduct = structure.find(
      productInStructure => productInStructure.contractId === product.contractId,
    )
    if (structureProduct) {
      structureProduct.packages = structureProduct.packages.concat(adaptPackageTree(product))
    } else {
      return structure.concat({
        id: product.id,
        title: product.contractId,
        meta: 'contract',
        nodes: [adaptPackageSidebar(product)],
      })
    }
    return structure
  }, [])

const structureAndFilterProductsCard = products =>
  createStructureCard(filterInfoProductsCard(products))

const structureAndFilterProductsTree = products =>
  createStructureTree(filterInfoProductsTree(products))

const structureAndFilterProductsSidebar = products =>
  createStructureSidebar(filterInfoProductsTree(products))

const fixedLineName = 'FIXED'

export const extractSubscriptionsData = contract => {
  const { contractId } = contract

  const subscriptions = contract.customer.products.map(product =>
    product.productsSpecifications.map(subscription => ({
      id: subscription.id,
      isFixedLine: toUpper(trim(subscription.name)) === fixedLineName,
      tariff: product.description,
      typeSubscription: product.payment_type,
      isConvergent: product.productsSpecifications.length > 1,
      contractId,
      status: '',
    })),
  )

  return flatten(subscriptions)
}

export {
  structureAndFilterProductsCard,
  structureAndFilterProductsTree,
  structureAndFilterProductsSidebar,
}
