import PropTypes from 'prop-types'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { TextField } from '@material-ui/core'
import { useAddressAutocomplete } from './useAddressAutocomplete'

export function AddressAutocomplete({ name, disabled, value, onChange, error, onBlur, allowInputManualAddress }) {
  const { setAddresses, setInputValue, addresses, inputValue } = useAddressAutocomplete(value, allowInputManualAddress)

  return (
    <Autocomplete
      options={addresses}
      getOptionLabel={option => option?.address || option}
      disableClearable
      freeSolo
      value={value}
      filterOptions={x => x}
      includeInputInList
      disabled={disabled}
      autoHighlight
      data-hook={name}
      onBlur={onBlur}
      onChange={(event, newValue) => {
        if (newValue === 'Utilizar la dirección introducida') {
          const val = {
            address: inputValue,
            city: '',
            door: '',
            flat: '',
            number: '',
            otherInfo: '',
            province: ' ',
            zipCode: '',
            street: inputValue,
            streetType: '-',
          }
          setAddresses([...addresses, val])
          onChange(val)
        } else {
          const parsedAddress = `${newValue?.streetType} ${newValue.street}`

          setAddresses(
            newValue ? [{ ...newValue, address: parsedAddress }, ...addresses] : addresses,
          )
          onChange({ ...newValue, address: parsedAddress })
        }
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue)
      }}
      renderInput={params => (
        <TextField
          variant="outlined"
          label="Dirección"
          name={name}
          data-hook={name}
          error={!!error}
          helperText={error}
          InputProps={{ autocomplete: 'new-password', ...params.inputProps }}
          {...params}
        />
      )}
    />
  )
}
AddressAutocomplete.propTypes = {
  disabled: PropTypes.bool,
  touched: PropTypes.bool,
  error: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  allowInputManualAddress: PropTypes.bool,
}
