/* eslint-disable import/no-cycle */
import { createSelector, createStructuredSelector } from 'reselect'
import {
  get,
  chain,
  isEmpty,
  some,
  method,
  isString,
  find,
  head,
  startsWith,
  isNil,
  sortBy,
  last,
} from 'lodash'
import { parseISO } from 'date-fns'
import {
  getIdentificationIdFromCustomer,
  getTelephoneFromCustomer,
  getFixedNumberPhone,
} from 'modules/orders/store/orders.selectors'
import { filterFTTHSpeeds } from './helpers'
import { configureSelectedTechnology, formatFullAddress, getTechnology } from '../helpers'
import {
  SEGMENT,
  EMPTY_GESCAL,
  NEBA_TERRITORIES,
  NEBA,
  VULA,
  ADSL,
  FTTH,
  MASMOVIL,
  NEBA_VULA_PREFIX,
  VULA_TERRITORY,
  VULA_COMMERCIAL_TERRITORY,
} from '../constants'

export const getCoverage = state => get(state, 'coverage', {})

export const getCoverageToken = createSelector(getCoverage, coverage => coverage.token)

export const getCoverageLoading = state => get(state, 'coverage.loading')
export const selectCoverageFailure = state => get(state, 'coverage.error_code')
export const selectCoverageSusccess = state => get(state, 'coverage.data')
export const selectCoverageErrorMessage = state => get(state, 'coverage.error_message')

export const getCustomerData = state => ({
  phoneNumber: getTelephoneFromCustomer(state),
  document_id: getIdentificationIdFromCustomer(state),

  // TODO: Right now we are only serving the RESIDENTIAL segment
  // In the future we will need to found a rule to decide what to do with lines
  // That do not have a segment.
  segment: SEGMENT,
  SFID: get(state, 'session.sfid', ''),
})

export const getContactMedium = state => get(state, 'productOrders.data.customer.contactMedium')
export const getOrder = state => get(state, 'productOrders.orders')

const getInstallationAddressFixedLine = order =>
  get(order, 'customer.contactMedium').find(
    medium => medium.type === 'InstallationAddressFixedLine',
  )

export const selectExternalId = subscriptionId =>
  createSelector(getOrder, orders => {
    const ordersSubscription = Object.values(orders).filter(
      order =>
        // eslint-disable-next-line eqeqeq
        order.subscriptionId == subscriptionId &&
        order.state === '6' &&
        getInstallationAddressFixedLine(order),
    )

    return chain(ordersSubscription)
      .sortBy(order => parseISO(order.orderDate))
      .last()
      .get('externalId')
      .value()
  })

export const getGescal37 = state => {
  const customer = get(state, 'productOrders.data.customer.contactMedium')
  let gescal = chain(customer)
    .flatMap()
    .find({ type: 'InstallationAddressFixedLine' })
    .flatMap('characteristic')
    .filter({ name: 'gescal' })
    .map('value')
    .value()

  if (isEmpty(gescal)) {
    gescal = chain(customer)
      .flatMap()
      .find({ type: 'InstallationAddress' })
      .flatMap('characteristic')
      .filter({ name: 'gescal' })
      .map('value')
      .value()
  }

  if (!isEmpty(gescal)) {
    return gescal[0].padEnd(37)
  }

  return EMPTY_GESCAL
}

export const getOrderTerritoryOwner = state => {
  const customer = getContactMedium(state)

  const territoryOwner = chain(customer)
    .flatMap()
    .find({
      type: 'InstallationAddressFixedLine',
    })
    .flatMap('characteristic')
    .filter({
      name: 'territoryOwner',
    })
    .map('value')
    .head()
    .value()

  if (isEmpty(territoryOwner)) {
    return chain(customer)
      .flatMap()
      .find({ type: 'InstallationAddress' })
      .flatMap('characteristic')
      .filter({ name: 'territoryOwner' })
      .map('value')
      .head()
      .value()
  }

  return territoryOwner
}

export const getCurrentTerritoryOwnerOrGescal = (
  orders,
  subscriptionId,
  territoryOwnerOrGescal,
) => {
  let lastTerritoryOwnerOrGescal

  const ordersSubscription = Object.values(orders).filter(
    // eslint-disable-next-line eqeqeq
    order => order.subscriptionId == subscriptionId,
  )
  const territoriesOwnerOrGescal = ordersSubscription.map(order => {
    // TODO
    // const installationAddressFixedLine = getInstallationAddressFixedLine(order)
    const installationAddressFixedLine = get(order, 'customer.contactMedium').find(
      medium => medium.type === 'InstallationAddressFixedLine',
    )

    const territoryOwner = get(
      get(installationAddressFixedLine, 'medium.characteristic')?.find(
        char => char.name === 'territoryOwner',
      ),
      'value',
    )

    const gescal = get(
      get(installationAddressFixedLine, 'medium.characteristic')?.find(
        char => char.name === 'gescal',
      ),
      'value',
    )

    return {
      territoryOwner: isEmpty(territoryOwner) ? MASMOVIL : territoryOwner,
      gescal,
      orderDate: get(order, 'orderDate'),
      state: get(order, 'state'),
    }
  })

  if (territoryOwnerOrGescal) {
    const territoriesOwnerFiltered = territoriesOwnerOrGescal.filter(
      territoryOwner =>
        !isNil(territoryOwner.territoryOwner) &&
        territoryOwner.territoryOwner !== '' &&
        territoryOwner.state === '6',
    )

    lastTerritoryOwnerOrGescal = get(
      last(sortBy(territoriesOwnerFiltered, ele => parseISO(ele.orderDate))),
      'territoryOwner',
    )
  } else {
    const gescalFiltered = territoriesOwnerOrGescal.filter(
      gescal => !isNil(gescal.gescal) && gescal.gescal !== '' && gescal.state === '6',
    )

    lastTerritoryOwnerOrGescal = get(
      last(sortBy(gescalFiltered, ele => parseISO(ele.orderDate))),
      'gescal',
    )
  }

  return lastTerritoryOwnerOrGescal
}

export const getCurrentTerritoryOwner = (orders, subscriptionId) => {
  return getCurrentTerritoryOwnerOrGescal(orders, subscriptionId, true)
}

export const getCurrentGescal = (orders, subscriptionId) => {
  return getCurrentTerritoryOwnerOrGescal(orders, subscriptionId, false)
}

export const getGescal37byContactMedium = contactMedium => {
  const gescal = chain(contactMedium)
    .flatMap()
    .find({ type: 'InstallationAddressFixedLine' })
    .flatMap('characteristic')
    .filter({ name: 'gescal' })
    .map('value')
    .value()[0]
  return gescal
}

export const selectCoverageData = createSelector(getCoverage, coverage => get(coverage, 'data', {}))

export const selectGescal = createSelector(selectCoverageData, data => get(data, 'gescal', ''))

export const selectGescal17 = createSelector(selectGescal, gescal => gescal.substr(0, 17))

export const selectCity = createSelector(selectCoverageData, data => get(data, 'town', ''))

export const selectProvince = createSelector(selectCoverageData, data => get(data, 'province', ''))

export const selectPostalCode = createSelector(selectCoverageData, data => get(data, 'zipCode', ''))

export const selectTypeAddress = createSelector(selectCoverageData, data =>
  get(data, 'streetType', ''),
)

export const selectNameAddress = createSelector(selectCoverageData, data => get(data, 'street', ''))

export const selectNumberAddress = createSelector(selectCoverageData, data =>
  get(data, 'number', ''),
)

export const selectDoorAddress = createSelector(selectCoverageData, data => get(data, 'door', ''))

export const selectHandAddress = createSelector(selectCoverageData, data => get(data, 'Hand', ''))

export const selectStairAddress = createSelector(selectCoverageData, data =>
  get(data, 'stair', '').trim(),
) // Need to trim because the backend is returning an untrimmed response

export const selectFloorAddress = createSelector(selectCoverageData, data => get(data, 'floor', ''))

export const selectBlockAddress = createSelector(selectCoverageData, data => get(data, 'block', ''))

export const selectLetterAddress = createSelector(selectCoverageData, data =>
  get(data, 'letter', ''),
)

export const selectIdAddress = createSelector(selectCoverageData, data =>
  get(data, 'addressId', ''),
)

export const selectGescal17FromAddressId = createSelector(selectIdAddress, addressId =>
  addressId.substr(0, 17),
)

export const selectKmAddress = createSelector(selectCoverageData, data => get(data, 'Km', ''))

export const selectBisAddress = createSelector(selectCoverageData, data =>
  get(data, 'bis_duplicate', ''),
)

export const selectCoverageTechnologies = createSelector(selectCoverageData, data =>
  get(data, 'coverage'),
)

export const getTerritoryOwner = createSelector(getCoverage, coverage => {
  return (
    // passing by coverage component flow
    chain(coverage)
      .get('data_edited.coverage')
      .head()
      .get('characteristics')
      .filter({ name: 'territoryOwner' })
      .head()
      .get('value')
      .value() ||
    // Change address flow
    chain(coverage)
      .get('data.coverage')
      .head()
      .get('characteristics')
      .find({ name: 'territoryOwner' })
      .get('value')
      .value() ||
    // Change technology flow
    chain(coverage).get('data').head().get('territoryOwner').value() ||
    // Commercial migration
    chain(coverage).get('data').get('territoryOwner').value()
  )
})

export const getCoverageComponentTechnology = createSelector(getCoverage, coverage =>
  chain(coverage).get('data').get('technology').value(),
)

export const getCoverageDataCoverage = state => head(get(state, 'coverage.data.coverage', []))

export const getCharacteristics = createSelector([getCoverageDataCoverage], coverage =>
  get(coverage, 'characteristics', []),
)

export const selectTerritoryOwner = createSelector([getCharacteristics], characteristics =>
  characteristics.find(x => x.name === 'territoryOwner'),
)

const isADSL = coverageData => {
  if (isEmpty(coverageData)) return null
  return !isEmpty(coverageData.ull) && isEmpty(coverageData.ftth)
}

export const selectEstimatedSpeed = createSelector(
  [getCoverageDataCoverage, getCharacteristics],
  (coverageData, characteristics) => {
    if (isADSL(coverageData)) {
      const speedEstimated = characteristics.find(x => x.name === 'speedEstimated')
      return speedEstimated && speedEstimated.value
    }
    return null
  },
)

export const selectTerritoryOwnerFilter = state => {
  const coverage = head(get(state, 'coverage.data', []))
  return get(coverage, 'territoryOwner')
}

export const getSessionId = state => get(state, 'coverage.data.sessionId', '')

export const checkIsNeba = territory => some(NEBA_TERRITORIES, method('match', territory))
export const checkIsVula = territory => VULA_TERRITORY === territory
export const checkIsNebaAndVula = territory =>
  NEBA_VULA_PREFIX.some(to => startsWith(territory, to))
export const checkIsCommercialVula = territory => VULA_COMMERCIAL_TERRITORY === territory

export const getCoverageAddressUpdateOrder = state => {
  const { coverage, ...coverageAddress } = selectCoverageData(state)
  return {
    ...coverageAddress,
    phoneNumber: getFixedNumberPhone(state),
    coverageToken: getSessionId(state),
    territoryOwner: get(selectTerritoryOwner(state), 'value', ''),
    available_speeds: get(
      (coverage || []).find(item => item.ftth),
      'ftth',
      [],
    ),
  }
}

export const isValidNewDirection = state => {
  const coverage = get(selectCoverageData(state), 'coverage', [])
  return coverage.every(x => {
    const coverageFtth = isString(coverage) ? null : x.ftth.length === 0
    const territoryOwner = get(
      find(x.characteristics, j => j.name === 'territoryOwner'),
      'value',
      '',
    )
    const isNebaTO = checkIsNeba(territoryOwner)
    return coverageFtth === false && isNebaTO === false
  })
}

export const getCoverageTechnology = state => getTechnology(get(state, 'coverage.data.coverage'))

export const getSelectedTechnology = state => get(state.coverage, 'selectedTechnology')

export const selectCoverageFromTechnology = createSelector(
  [getSelectedTechnology, selectCoverageData],
  (technology, coverage) => configureSelectedTechnology(technology, coverage),
)

export const getAccessToken = state => state.coverage.accessToken || {}

export const selectAccessToken = createSelector(getAccessToken, token => token.accessToken)

export const selectCoverageTechnology = createSelector(
  state => state,
  state => filterFTTHSpeeds(getCoverageTechnology(state)),
)

export const selectAddressInfo = createStructuredSelector({
  state: selectCity,
  province: selectProvince,
  postalCode: selectPostalCode,
  streetType: selectTypeAddress,
  nameAddress: selectNameAddress,
  numberAddress: selectNumberAddress,
  doorAddress: selectDoorAddress,
  handAddress: selectHandAddress,
  stairAddress: selectStairAddress,
  floorAddress: selectFloorAddress,
  getBlockAddress: selectBlockAddress,
  coverageTechnology: selectCoverageTechnology,
  gescal: selectGescal,
  coverage: selectCoverageData,
})

export const selectHasAvailableTechnologies = createSelector(
  selectAddressInfo,
  installationAddress => {
    const technologyList = get(installationAddress, 'coverageTechnology.technology')
    return !isEmpty(technologyList)
  },
)

export const selectCoverageFullAddress = createSelector(selectCoverageData, formatFullAddress)

export const selectCoverageInfo = createSelector(
  [selectPostalCode, selectNumberAddress, selectIdAddress],
  (postCode, number, addressId) => ({
    postCode,
    number,
    addressId,
  }),
)

export function hasTechnology(coverageTechnology) {
  return (
    coverageTechnology && coverageTechnology.technology && !isEmpty(coverageTechnology.technology)
  )
}

export const selectHasTechnology = createSelector([selectCoverageTechnology], hasTechnology)

export const selectInstallationTechnologiesNames = createSelector(
  selectCoverageTechnology,
  technologies => get(technologies, 'technology', []),
)

const selectFirstInstallationTechnology = createSelector(
  selectInstallationTechnologiesNames,
  technologyNames => head(technologyNames) || '',
)

// Returns composed name of installation technologies sometimes only NEBA, sometimes ADSL-NEBA, etc
export const selectInstallationTechnologyName = createSelector(
  selectInstallationTechnologiesNames,
  names => names.join('-'),
)

export const selectCoverageTokenLoading = createSelector(
  getCoverage,
  coverage => coverage.loading_token,
)

export const selectIsNeba = createSelector(
  selectFirstInstallationTechnology,
  technology => technology.indexOf(NEBA) !== -1,
)

export const selectIsVula = createSelector(
  selectFirstInstallationTechnology,
  technology => technology.indexOf(VULA) !== -1,
)

export const selectTechnology = createSelector(selectCoverageTechnology, technology =>
  head(get(technology, 'technology', [])),
)

export const selectIsAdsl = createSelector(selectTechnology, technology => technology === ADSL)

export const selectIsFtth = createSelector(selectTechnology, technology => technology === FTTH)
