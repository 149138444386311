import PropTypes from 'prop-types'
import { ActionAdvice } from 'components/ui'
import { Box, Divider } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
  adviceStyles: {
    width: '100%',

    '& .advice-text-field': {
      paddingRight: '16px',
    },
  },
})

export function TariffPermanency({ permanency: { amount, duration }, isPro }) {
  const styles = useStyles()

  const noIvaIncluded = isPro ? '(impuestos no incluidos)' : ''

  return (
    <Box width={1}>
      <Divider />
      <Box display="flex" flexWrap="wrap" py="20px">
        <Box minWidth="380px">
          <ActionAdvice
            id="bundle"
            className={`advice ${styles.adviceStyles}`}
            items={[
              { label: 'Penalización', value: `${amount}€ ${noIvaIncluded}` },
              { label: 'Durante', value: `${duration} meses` },
            ]}
            type="error"
            icon="warning"
            block
          />
        </Box>
      </Box>
    </Box>
  )
}

TariffPermanency.propTypes = {
  permanency: PropTypes.object,
  isPro: PropTypes.bool,
}
