import { ConnectedRouter } from 'connected-react-router'
import { AuthenticatedRoute } from 'modules/Auth'
import { Routes as ExistingClientsRoutes } from 'modules/existing-clients'
import { ChannelChooser } from 'modules/new-clients/components/ChannelChooser'
import { NewClientSalesRoutes } from 'modules/NewClientSales/NewClientSalesRoutes'
import { Routes as OffersConfigurationRoutes } from 'modules/offers-configuration'
import { Routes as DeviceRenewalRoutes } from 'modules/device-renewal'

import { SalesLead } from 'modules/SalesLead'
import { EnergyRedirectContainer, ENERGY_REDIRECT_ROUTE_PATH } from 'modules/energy'
import { SALES_LEADS_PATH } from 'modules/NewClientSales/constants/index'
import { TypificationContainer } from 'modules/typifications'

import { history } from 'modules/Router'
import { Routes as VistaRoutes } from 'modules/vista-360'
import { Redirect, Route, Switch } from 'react-router-dom'
import { APP_CONFIG } from 'services/app-config'
import { CypressHistorySupport } from 'cypress-react-router'
import { Home } from './components/Home'

export function Routes() {
  return (
    <ConnectedRouter history={history}>
      <CypressHistorySupport />
      <Switch>
        {!APP_CONFIG.production && <Route exact path="/" component={Home} />}

        <Route path="/clients" component={ExistingClientsRoutes} />

        <AuthenticatedRoute isCustomer path="/offers" component={OffersConfigurationRoutes} />

        <Route path="/add-client" component={NewClientSalesRoutes} />

        <Route path="/vista" component={VistaRoutes} />

        <Route path="/device-renewal" component={DeviceRenewalRoutes} />

        {!APP_CONFIG.production && <Route path="/choose-channel" component={ChannelChooser} />}

        <Route path={SALES_LEADS_PATH} component={SalesLead} />

        <Route path="/typfication/:token" component={TypificationContainer} />

        <AuthenticatedRoute path={ENERGY_REDIRECT_ROUTE_PATH} component={EnergyRedirectContainer} />

        <AuthenticatedRoute
          path="/customer-search"
          render={({ location }) => (
            <Redirect to={`/vista/dashboard/${location.search}&isSalesFlow=true`} />
          )}
        />
      </Switch>
    </ConnectedRouter>
  )
}
