import PropTypes from 'prop-types'
import { formatMonthlyFee } from 'utils'
import { ModalContent } from './ModalContent'
import { DiscountsAndBonusesSelectedContainer } from '../containers/DiscountsAndBonusesSelectedContainer'
import { getSubscriptionFeeAmount } from '../helpers'
import { useUpdateSubscriptionHandlers } from '../hooks/useUpdateSubscriptionHandlers'
import { useTaxApplied } from '../hooks/useTaxApplied'

const DiscountsAndBonusesModal = ({ subscription, onClose, taxNeeded }) => {
  const feeAmount = getSubscriptionFeeAmount(subscription)

  const { taxFeeAmount } = useTaxApplied({ taxFeeAmount: feeAmount })

  const { onUpdateSubscription } = useUpdateSubscriptionHandlers(subscription)

  return (
    <ModalContent data-hook="modal-discounts-bonuses">
      <p>
        Cuota Actual: <strong>{formatMonthlyFee(taxNeeded ? taxFeeAmount : feeAmount)}</strong>
      </p>
      <DiscountsAndBonusesSelectedContainer
        subscription={subscription}
        onConfirm={data => {
          onUpdateSubscription(data)
          onClose()
        }}
      />
    </ModalContent>
  )
}

DiscountsAndBonusesModal.propTypes = {
  subscription: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  taxNeeded: PropTypes.bool,
}

export default DiscountsAndBonusesModal
