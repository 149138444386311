import { axiosJWT } from 'modules/axios'

import { APP_CONFIG } from 'services/app-config'

const BASE_URL_360_CUSTOMER_SVA = `${APP_CONFIG.care_cluster}/ms-sva-info/customersva`
const BASE_URL_360_DISABLE_DOCTORGO = `${APP_CONFIG.care_cluster}/ms-sva-doctorgo/sva-doctorgo-off`
const BASE_URL_360_CUSTOMER_SVAS_INFO = `${APP_CONFIG.care_cluster}/ms-sva-info/sva`
const BASE_URL_360_MAS_SERVICES_SVA = `${APP_CONFIG.care_cluster}/ms-sva-info/audit/doctorgo`
const URL_FIND_SVA_HOMEGO = `${APP_CONFIG.care_cluster}/ms-sva-homeit/alarms/contracts`
const URL_HOMEGO_SVAS_CADENCE_STATUS = `${APP_CONFIG.care_cluster}/ms-sva-homeit/status`

const URL_SELL_SVA_HOMEGO = `${APP_CONFIG.care_cluster}/ms-sva-homeit/purchase`
const URL_SELL_SVA_HOMEGO_WITHOUT_SCORING = `${
  APP_CONFIG.care_cluster
}/ms-sva-homeit/purchase/scoring-approved`
const URL_CANCEL_SVA_HOMEGO_ORDER = `${APP_CONFIG.care_cluster}/ms-sva-homeit/purchase/cancellation`

const URL_HOMEGO_SVA_REGISTER = `${APP_CONFIG.care_cluster}/ms-sva-homeit/register`

export function findCustomerSva(documentId, documentType) {
  return axiosJWT({
    method: 'GET',
    url: BASE_URL_360_CUSTOMER_SVA,
    params: { documentId, documentType },
  }).then(resp => resp.data)
}

export function getCustomerSvasInfo(accountId) {
  return axiosJWT({
    method: 'GET',
    url: BASE_URL_360_CUSTOMER_SVAS_INFO,
    params: { accountId },
  }).then(resp => resp.data)
}

export function findMasServicesSva(msisdns) {
  return axiosJWT({
    method: 'GET',
    url: BASE_URL_360_MAS_SERVICES_SVA,
    params: { msisdn: msisdns },
  }).then(resp => resp.data)
}

export function putDisabledCustomerSva(sfid, documentId, customerId, documentType, msisdn) {
  return axiosJWT({
    method: 'POST',
    url: BASE_URL_360_DISABLE_DOCTORGO,
    data: { sfid, documentId, customerId, documentType, msisdn },
  }).then(resp => resp.data)
}

export function putHomeGoSva(dataSVAalarm) {
  return axiosJWT({
    method: 'POST',
    url: URL_SELL_SVA_HOMEGO,
    data: dataSVAalarm,
  }).then(resp => resp.data)
}

export function putHomeGoSvaWithoutScoring(dataSVAalarm) {
  return axiosJWT({
    method: 'POST',
    url: URL_SELL_SVA_HOMEGO_WITHOUT_SCORING,
    data: dataSVAalarm,
  }).then(resp => resp.data)
}

export function getHomeGoSvasCadenceStatus(msisdn) {
  return axiosJWT({
    method: 'GET',
    url: URL_HOMEGO_SVAS_CADENCE_STATUS,
    params: { msisdn },
  }).then(resp => resp.data)
}

export function findSicorSva(customerId) {
  return axiosJWT({
    method: 'GET',
    url: URL_FIND_SVA_HOMEGO,
    params: { customerId },
  }).then(resp => resp.data)
}

export function cancelHomeGoSvaOrder(msisdn, workflowId, sfid, reason = '') {
  return axiosJWT({
    method: 'POST',
    url: `${URL_CANCEL_SVA_HOMEGO_ORDER}/${msisdn}`,
    data: {
      workflowId,
      sfid,
      reason,
    },
  }).then(resp => resp.data)
}

export function getHomeGoSvaRegister(msisdn) {
  return axiosJWT({
    method: 'GET',
    url: URL_HOMEGO_SVA_REGISTER,
    params: { msisdn },
  }).then(resp => resp.data)
}
