import { useDispatch, useSelector } from 'react-redux'

import { notifyD2DHomeAction, notifyD2DUserLogoutAction, selectIsD2D } from '../../store'

export function useD2DHeader() {
  const dispatch = useDispatch()

  function onClickPowerButton() {
    dispatch(notifyD2DUserLogoutAction())
  }

  function onClickHome() {
    dispatch(notifyD2DHomeAction())
  }

  const isD2D = useSelector(selectIsD2D)

  return { onClickPowerButton, onClickHome, isD2D }
}
