import { call, put, takeLatest, takeEvery, select } from 'redux-saga/effects'
import { logError, formatErrorMessage } from 'services/logging'
import { axiosFactory } from 'modules/axios'
import { selectCustomerId, selectSfid, selectIsCustomer } from 'modules/Auth/store'
import { selectSaleInformation, getId } from 'services/customer-360/selectors'
import { selectAccountId } from 'modules/Auth/store/selectors'
import { fetchCDCTWorkOrderIds } from 'services/change-technology/api'
import actions from './actions'
import * as constants from './constants'
import {
  fetchSubscriptions,
  fetchSubscriptionByMsisdn,
  updateSubscriptionTariff,
  setBundles,
  setBundlesHebeSva,
  disableSvaSmartHome,
  setUpsellBundles,
  fetchPortOutInfo,
  disableSvaHomeGo,
} from './api'

export function* findSubscriptionsSaga() {
  try {
    const isCustomer = yield select(selectIsCustomer)
    const customerId = yield select(isCustomer ? selectCustomerId : getId)

    const subscriptions = yield call(fetchSubscriptions, customerId)

    yield put(actions.findSubscriptionsSucceeded(subscriptions))
  } catch (e) {
    yield call(logError, new Error(formatErrorMessage(e)))
    const error = axiosFactory.buildError(e)
    yield put(actions.findSubscriptionsFailed(error))
  }
}

export function* findSubscriptionByMsisdnSaga({ payload: { msisdn } }) {
  try {
    const subscription = yield call(fetchSubscriptionByMsisdn, msisdn)

    yield put(actions.findSubscriptionByMsisdnSucceeded(msisdn, subscription))
  } catch (e) {
    yield call(logError, { e: new Error(formatErrorMessage(e)), msisdn })
    const error = axiosFactory.buildError(e)
    yield put(actions.findSubscriptionByMsisdnFailed(msisdn, error))
  }
}

export function* changeTariffSaga({ payload: { msisdn, tariffId, options, v3 = false } }) {
  try {
    yield call(updateSubscriptionTariff, msisdn, tariffId, options, v3)
    yield put(actions.changeTariffSucceeded(msisdn))
  } catch (e) {
    yield call(logError, { e: new Error(formatErrorMessage(e)), msisdn, tariffId, options })
    const error = axiosFactory.buildError(e)
    yield put(actions.changeTariffFailed(msisdn, error))
  }
}

export function* setBundlesSaga(action) {
  const { msisdn, bundleId, bundleStatus } = action.payload

  try {
    yield call(setBundles, msisdn, bundleId, bundleStatus)
    yield put(actions.setBundlesSucceeded(msisdn))
  } catch (e) {
    yield call(logError, { e: new Error(formatErrorMessage(e)), payload: action.payload })
    const error = axiosFactory.buildError(e)
    yield put(actions.setBundlesFailed(msisdn, error))
  }
}

export function* disableDataBonusSaga(action) {
  const { msisdn, bonusId } = action.payload

  try {
    yield call(setBundles, msisdn, bonusId, 'off')
    yield put(actions.disableDataBonusSucceeded(msisdn))
  } catch (e) {
    yield call(logError, { e: new Error(formatErrorMessage(e)), payload: action.payload })
    const error = axiosFactory.buildError(e)
    yield put(actions.disableDataBonusFailed(msisdn, error))
  }
}

export function* setDataBonusHebeSvaSaga(action) {
  const { msisdn, service, status, resetOnSuccess, resetOnFailure } = action.payload
  let result

  try {
    result = yield call(setBundlesHebeSva, msisdn, service, status)
    yield put(actions.setDataBonusHebeSvaSucceeded(msisdn, status))

    if (resetOnSuccess) {
      yield put(actions.setDataBonusHebeSvaReset(msisdn))
    }
  } catch (e) {
    yield call(logError, { e: new Error(formatErrorMessage(e)), payload: action.payload })
    const error = axiosFactory.buildError(e)
    yield put(actions.setDataBonusHebeSvaFailed(msisdn, error))

    result = { error }

    if (resetOnFailure) {
      yield put(actions.setDataBonusHebeSvaReset(msisdn))
    }
  }

  return result
}

export function* disableSvaSmartHomeSaga(action) {
  const { msisdn, orderId, contractId, documentNumber } = action.payload
  let result

  const alarmData = {
    msisdn,
    orderId,
    contractId,
    documentNumber,
  }

  try {
    result = yield call(disableSvaSmartHome, alarmData)
    yield put(actions.disableSvaSmartHomeSucceeded(msisdn))
  } catch (e) {
    yield call(logError, { e: new Error(formatErrorMessage(e)), payload: action.payload })
    const error = axiosFactory.buildError(e)
    result = { error }
    yield put(actions.disableSvaSmartHomeFailed(msisdn, error))
  }

  return result
}

export function* disableSvaHomeGoSaga({ payload: { msisdn, subscriptionId, psIdKit, reason } }) {
  const sfidCode = yield select(selectSfid)
  const accountId = yield select(selectAccountId)
  const { docNumber, docType, segment } = yield select(selectSaleInformation)

  try {
    const dataSvaCancelation = {
      customerId: accountId,
      sfid: sfidCode,
      msisdn,
      segment: (segment === 'pro' ? 'COMPANY' : segment).toUpperCase(),
      documentType: docType,
      documentId: docNumber,
      kitId: psIdKit,
      subscriptionId: String(subscriptionId),
      reason,
    }
    const sicorSvaData = yield call(disableSvaHomeGo, dataSvaCancelation)

    yield put(actions.disableSvaHomeGoSucceeded(sicorSvaData))
  } catch (e) {
    yield call(logError, { e, accountId, msisdn })
    const error = axiosFactory.buildError(e)
    if (e.response?.data?.code) {
      error.endpointError = e.response.data
    }
    yield put(actions.disableSvaHomeGoFailed(error))
  }
}

export function* setUpsellBundlesSaga(action) {
  const { msisdn, bundleId } = action.payload

  try {
    const customerId = yield select(selectCustomerId)
    yield call(setUpsellBundles, msisdn, bundleId, customerId)
    yield put(actions.setUpsellBundlesSucceeded(msisdn))
  } catch (e) {
    yield call(logError, { e: new Error(formatErrorMessage(e)), payload: action.payload })
    const error = axiosFactory.buildError(e)
    yield put(actions.setUpsellBundlesFailed(msisdn, error))
  }
}

export function* findPortOutInfoSaga(action) {
  const { msisdn } = action.payload

  try {
    const portOutInfo = msisdn < 800000000 ? yield call(fetchPortOutInfo, msisdn) : null
    yield put(actions.findPortOutInfoSucceeded(msisdn, portOutInfo))
  } catch (e) {
    if (!e.isAxiosError || e.response.status !== 404) {
      yield call(logError, {
        e: new Error(formatErrorMessage(e)),
        payload: action.payload,
      })
    }
    const error = axiosFactory.buildError(e)
    yield put(actions.findPortOutInfoFailed(msisdn, error))
  }
}

export function* findDocumentCDCTSaga({ payload: { subscriptionId } }) {
  try {
    const workOrderIdsData = yield call(fetchCDCTWorkOrderIds, subscriptionId)
    yield put(actions.
      findDocumentCDCTSucceeded(subscriptionId, workOrderIdsData))
  } catch(e) {
    yield call(logError, { e: new Error(formatErrorMessage(e)), subscriptionId })
    const error = axiosFactory.buildError(e)
    yield put(actions.findDocumentCDCTFailed(subscriptionId, error))
  }
}

export function* watchFindSubscriptions() {
  yield takeLatest(constants.FIND_SUBSCRIPTIONS_ATTEMPTED, findSubscriptionsSaga)
  yield takeLatest(constants.FIND_SUBSCRIPTION_BY_ID_ATTEMPTED, findSubscriptionByMsisdnSaga)
  yield takeEvery(constants.CHANGE_TARIFF_ATTEMPTED, changeTariffSaga)
  yield takeEvery(constants.SET_BUNDLES_ATTEMPTED, setBundlesSaga)
  yield takeLatest(constants.DISABLE_DATA_BONUS_ATTEMPTED, disableDataBonusSaga)
  yield takeLatest(constants.SET_SVA_ATTEMPTED, setDataBonusHebeSvaSaga)
  yield takeEvery(constants.SET_UPSELL_BUNDLES_ATTEMPTED, setUpsellBundlesSaga)
  yield takeEvery(constants.FIND_PORT_OUT_INFO_ATTEMPTED, findPortOutInfoSaga)
  yield takeLatest(constants.DISABLE_SVA_SMARTHOME_ATTEMPTED, disableSvaSmartHomeSaga)
  yield takeLatest(constants.DISABLE_SVA_HOMEGO_ATTEMPTED, disableSvaHomeGoSaga)
  yield takeLatest(constants.FIND_DOCUMENT_CDCT_WORKORDER_ID_ATTEMPTED, findDocumentCDCTSaga)
}
