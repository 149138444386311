import { map } from 'lodash'
import PropTypes from 'prop-types'

import { Typography, Box, Divider } from '@material-ui/core'
import { TextStyles } from 'utils/text'
/**
 * WizardStepWarning
 */

import { CardRow, CardTitle, CardRowLabel, CardRowValue } from 'components'

const CancelStepWarning = props => {
  const { lines, title } = props
  return (
    <div>
      <CardRow>
        <CardTitle>{title}</CardTitle>
        {map(lines, item => (
          <CardRow key={item.id}>
            <CardRowLabel>
              {item.category === 'fixed' ? 'Línea fija' : 'Línea móvil'}:{' '}
            </CardRowLabel>
            <CardRowValue>{item.id}</CardRowValue>
          </CardRow>
        ))}
        <Box mt="20px" mb="10px">
          <Divider />
        </Box>
        <CardRow>
          <Typography {...TextStyles.paragraphDark()}>{props.secondaryComponent}</Typography>
        </CardRow>
      </CardRow>
    </div>
  )
}

CancelStepWarning.propTypes = {
  lines: PropTypes.arrayOf(PropTypes.object).isRequired,
  title: PropTypes.string.isRequired,
  secondaryComponent: PropTypes.node.isRequired,
}
export default CancelStepWarning
