import styled from 'styled-components'
import PlaceholderLine from '../placeholder-line/PlaceholderLine'

const OfferItemWrapper = styled.div`
  .first-line {
    margin-bottom: 4px;
  }
  .second-line {
    margin-bottom: 6px;
    animation-delay: 300ms;
  }
  .action-line {
    animation-delay: 600ms;
  }
`

const OfferItem = () => (
  <OfferItemWrapper className="offer-item">
    <PlaceholderLine className="first-line" width="40%" height="14px" opacity={0.5} />
    <PlaceholderLine className="second-line" width="60%" height="16px" />
    <PlaceholderLine className="action-line" width="75%" height="16px" opacity={0.8} />
  </OfferItemWrapper>
)

export default OfferItem
