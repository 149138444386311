import {
  FETCH_MOBILE_LINE_TARIFFS,
  FETCH_MOBILE_LINE_TARIFFS_SUCCESS,
  FETCH_MOBILE_LINE_TARIFFS_ERROR,
  FETCH_ADDITIONAL_TARIFFS,
  FETCH_ADDITIONAL_TARIFFS_SUCCESS,
  FETCH_ADDITIONAL_TARIFFS_ERROR,
  FETCH_MAIN_TARIFFS,
  FETCH_MAIN_TARIFFS_SUCCESS,
  FETCH_MAIN_TARIFFS_ERROR,
  FETCH_MEMBER_GET_MEMBER,
  FETCH_MEMBER_GET_MEMBER_SUCCESS,
  FETCH_MEMBER_GET_MEMBER_ERROR,
  RESET_MAIN_TARIFFS,
  RESET_ADDITIONAL_TARIFFS,
  RESET_ALL_TARIFFS,
} from './tariffs.actions'

const initialState = {
  mainLineTariffs: {
    loading: null,
    error: false,
    data: [],
  },
  mobileLineTariffs: {
    loading: null,
    error: false,
    data: [],
  },
  additionalTariffs: {
    loading: null,
    error: false,
    data: [],
  },
  memberGetMemberPromo: {
    loading: null,
    error: false,
    data: null,
  },
}

export const tariffsReducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case FETCH_MOBILE_LINE_TARIFFS: {
      return {
        ...state,
        mobileLineTariffs: {
          loading: true,
          error: false,
          data: [],
        },
      }
    }
    case FETCH_MOBILE_LINE_TARIFFS_SUCCESS: {
      return {
        ...state,
        mobileLineTariffs: {
          loading: false,
          error: false,
          data: payload,
        },
      }
    }
    case FETCH_MOBILE_LINE_TARIFFS_ERROR: {
      return {
        ...state,
        mobileLineTariffs: {
          loading: false,
          error: true,
          data: [],
        },
      }
    }

    case FETCH_ADDITIONAL_TARIFFS: {
      return { ...state, additionalTariffs: { loading: true, error: false, data: [] } }
    }
    case FETCH_MAIN_TARIFFS: {
      return { ...state, mainLineTariffs: { ...state.mainLineTariffs, loading: true } }
    }
    case FETCH_MAIN_TARIFFS_SUCCESS: {
      return { ...state, mainLineTariffs: { loading: false, error: false, data: payload } }
    }
    case FETCH_MAIN_TARIFFS_ERROR: {
      return { ...state, mainLineTariffs: { loading: false, error: true, data: [] } }
    }
    case RESET_MAIN_TARIFFS: {
      return { ...state, mainLineTariffs: { loading: false, error: false, data: [] } }
    }
    case RESET_ALL_TARIFFS: {
      return initialState
    }
    case FETCH_ADDITIONAL_TARIFFS_SUCCESS: {
      return { ...state, additionalTariffs: { loading: false, error: false, data: payload } }
    }
    case RESET_ADDITIONAL_TARIFFS: {
      return { ...state, additionalTariffs: { loading: null, error: false, data: [] } }
    }
    case FETCH_ADDITIONAL_TARIFFS_ERROR: {
      return {
        ...state,
        additionalTariffs: {
          loading: false,
          error: true,
          data: [],
        },
      }
    }
    case FETCH_MEMBER_GET_MEMBER:
      return {
        ...state,
        memberGetMemberPromo: {
          loading: true,
          error: false,
          data: null,
        },
      }
    case FETCH_MEMBER_GET_MEMBER_SUCCESS:
      return {
        ...state,
        memberGetMemberPromo: {
          loading: false,
          error: false,
          data: payload,
        },
      }
    case FETCH_MEMBER_GET_MEMBER_ERROR:
      return {
        ...state,
        memberGetMemberPromo: {
          loading: false,
          error: true,
          data: payload,
        },
      }
    default:
      return state
  }
}
