import * as constants from './constants'

const findTicketsSucceeded = data => ({
  type: constants.FIND_TICKETS_SUCCEEDED,
  payload: { data },
})

const findTicketsFailed = error => ({
  type: constants.FIND_TICKETS_FAILED,
  payload: error,
})

const findTickets = () => ({
  type: constants.FIND_TICKETS_ATTEMPTED,
})

export default {
  findTicketsSucceeded,
  findTicketsFailed,
  findTickets,
}
