import { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'

import { useDispatch, useSelector } from 'react-redux'

import { fetchSvaAgileTv } from 'modules/SVAs/store'

import { Typography } from '@material-ui/core'
import { NotificationModal, Button } from 'components/ui'

import { useChannelAndSegment } from '../../../add-agiletv/hooks'
import { changeAgileTvSelectors } from './ChangeAgileTvModalContainer.selector'
import { ChangeAgileTvModal } from './ChangeAgileTvModal'

import { changeAgileTv } from '../../store/change-agiletv.actions'

export const ChangeAgileTvModalContainer = ({
  open,
  onClose,
  subscription,
  psid,
  msisdn,
  onSuccess,
}) => {
  const dispatch = useDispatch()

  const [isShowResultModal, setIsShowResultModal] = useState(false)

  const {
    agileTvSva,
    isLoading,
    svaAgileListError,
    svaAgileListMsgError,
    svaAgileListIsSuccess,
    customerEmail,
    sfid,
    changeError,
    changeIsSuccess,
  } = useSelector(changeAgileTvSelectors(psid))

  const hasSvaAgileListError = !!svaAgileListError
  const hasAgileTvSvaError = svaAgileListIsSuccess && !agileTvSva
  const hasSvaError = !isLoading && (hasSvaAgileListError || hasAgileTvSvaError)
  const hasError = hasSvaError || changeError

  const { segment, channel } = useChannelAndSegment(subscription)

  const applyChangeAgileTv = agileTvSelected => {
    dispatch(
      changeAgileTv({
        msisdn,
        oldBundle: psid,
        newBundle: get(agileTvSelected, 'psId', null),
        sfid,
      }),
    )
  }

  const propsModal = {
    open,
    onClose,
    isLoading,
    agileTvEmail: customerEmail,
    agileTvSva,
    applyChangeAgileTv,
  }

  const onNotificationClose = () => {
    setIsShowResultModal(false)

    if (onSuccess && !hasError && changeIsSuccess) {
      onSuccess()
    }
  }

  useEffect(() => {
    if (open) {
      dispatch(
        fetchSvaAgileTv({
          mainTariffPsId: subscription.type,
          channel,
          segment,
        }),
      )
    }
  }, [open])

  useEffect(() => {
    if (open && !isLoading && (hasError || changeIsSuccess)) {
      if (hasError) {
        onClose()
      }

      setIsShowResultModal(true)
    }
  }, [hasError, changeIsSuccess])

  const resultModalTitle = useMemo(() => {
    let title = 'Solicitud de cambio de AgileTV realizada correctamente'

    if (hasSvaError) {
      if (hasSvaAgileListError) {
        title = 'Ha ocurrido un error durante la carga de datos'
      } else if (hasAgileTvSvaError) {
        title = 'No es posible realizar la operación'
      }
    } else if (changeError) {
      if (changeError?.code) {
        title = 'No se ha podido realizar la solicitud de cambio de AgileTV'
      } else {
        title = 'Ups! Se ha producido un error'
      }
    }

    return title
  }, [hasSvaError, changeError])

  return (
    <>
      <NotificationModal
        isOpen={isShowResultModal}
        onClose={onNotificationClose}
        type={hasError ? 'alert' : 'success'}
        src={hasError ? '/assets/error.svg' : '/assets/clap_clap.svg'}
        title={resultModalTitle}>
        <>
          {hasSvaAgileListError && (
            <Typography variant="body1" style={{ marginBottom: '15px' }}>
              {svaAgileListMsgError}
            </Typography>
          )}
          {hasAgileTvSvaError && (
            <Typography variant="body1" style={{ marginBottom: '15px' }}>
              No existe ninguna tarifa de TV a la que realizar el cambio
            </Typography>
          )}
          {changeError && (
            <Typography variant="body1" style={{ marginBottom: '15px' }}>
              {changeError?.detailMsg || 'Por favor, vuelve a intentarlo'}
            </Typography>
          )}
          <Button onClick={onNotificationClose}>Aceptar</Button>
        </>
      </NotificationModal>

      <ChangeAgileTvModal {...propsModal} />
    </>
  )
}

ChangeAgileTvModalContainer.propTypes = {
  subscription: PropTypes.object,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  psid: PropTypes.string,
  msisdn: PropTypes.string,
  onSuccess: PropTypes.func,
}
