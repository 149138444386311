import { get, isEmpty } from 'lodash'
import { Fragment, useState } from 'react'
import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import { format } from 'date-fns'

import { Box, Typography, Divider } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import {
  ActionAdvice,
  TitleLineItemCard,
  ButtonLink,
  SpinnerCenter,
  OutlinedDivider,
} from 'components/ui'

import LineItemCard from 'components/packages/ui/card360/LineItemCard'

import { formatFee, formatRange } from 'utils'

import { applyTax } from 'services/taxes'

import { SMSPrice } from 'modules/tariffs'
import { Card } from 'modules/ui/components/Card/Card'

import { formatDiscountProrrated, formatSvaProrrated } from 'services/subscriptions/helpers'
import {
  hasBundlesCallError,
  ERROR_BUNDLES_CALL_MESSAGE,
} from 'modules/bonuses/store/bonuses.helper'
import { SVA_WIFI_TOTAL_01, SVA_WIFI_TOTAL_02 } from 'modules/offers-configuration/constants'
import { FATsExcedeed, FutureFATs, SubtotalsFATs } from '../FATs'

import { LatestBillsContainer } from '../../containers/LatestBillsContainer'
import { FATModalContainer } from '../../../vista-360/containers/FATModalContainer'

const PADDING_ELEMENT = '12px 24px 0'

const useStyles = makeStyles(theme => ({
  'monthly-consumption-card': {
    position: 'relative',

    '& .msg-error': {
      fontStyle: 'italic',
      padding: '24px',
      fontSize: '14px',
      lineHeight: '1.4',
      color: theme.palette.global.gray_dark,
    },

    '& .card-content .line-item-fst': {
      paddingBottom: '4px',
    },

    '& .xs-text': {
      fontSize: '12px',
      fontWeight: '400',
      marginTop: '-4px',
      marginBottom: '8px',
    },

    '& .message .xs-text': {
      marginBottom: '0px',
    },

    '& .xxs-text': {
      marginTop: '-4px',
    },

    '& .line-item-card-xs': {
      padding: '6px 24px',

      '& > div': {
        fontWeight: 800,
      },
    },

    '& .line-item-card .primary-text': {
      maxWidth: 'calc(100% - 80px)',
    },

    '& .line-item-card.totals .primary-text': {
      fontWeight: 500,
      fontSize: '16px',
    },

    '& .line-item-card .secondary-text': {
      whiteSpace: 'nowrap',
    },

    '& .prepaid-title .primary-text': {
      textTransform: 'none',
      maxWidth: '100%',
    },

    '& .italic': {
      fontStyle: 'italic',
    },

    '& .btn-lnk-prepaid': {
      padding: '10px 24px',
      display: 'block',
    },

    '& .loader-wrapper': {
      position: 'relative',
    },

    '& .fat-line-item-card': {
      margin: '0px',
      padding: '0px',
    },

    '&& .advice-label-value': {
      padding: '0px',
    },

    '&& .message': {
      width: '100%',
    },

    '& .bold-divider': {
      backgroundColor: theme.palette.global.gray_dark,
    },
  },
}))
export const MonthlyConsumptionCard = ({
  consumptionData: {
    enrichedTariffRanges,
    restExpenses,
    terminalsFee,
    VAS,
    discounts,
    hasDiscounts,
    restDiscounts,
    bundleGroups,
    totalExpenses,
    totalExpensesWithoutFats,
  },
  isPrepaid,
  prepaidData,
  UIActionUpdate,
  fats,
  tax,
  taxNeeded,
  billDiscountPermission,
}) => {
  const classes = useStyles()

  const location = useLocation()

  const { currentBalance, prepaidEvents, errorsPrepaid, isLoadingPrepaid } = prepaidData
  const title = isPrepaid ? 'Gasto mes y saldo' : 'Gasto mes y Facturas'
  const currentMonthFATFormat = format(new Date(), 'yyyyMM')
  const has100per100Discount = discounts.some(dis => dis.isPercentage && dis.amount === 100)
  const [currentMonthFATs, futureMonthFATs] = !fats
    ? [[], []]
    : fats.reduce(
        (result, fat) => {
          const isCurrentMonthFAT = currentMonthFATFormat === get(fat, 'air_time.period')
          result[isCurrentMonthFAT ? 0 : 1].push(fat)
          return result
        },
        [[], []],
      )

  const totalFATDebtNextMonth = totalExpensesWithoutFats - fats
  const [openFATModal, setOpenFATModal] = useState(false)

  const menuActions = [
    {
      label: 'Descuento en facturas',
      onClick: () => setOpenFATModal(true),
      disabled: !billDiscountPermission,
    },
  ]

  return (
    <Card
      className={classes['monthly-consumption-card']}
      id="monthly-cost-card"
      title={title}
      withShadow
      menuActions={!isPrepaid && menuActions}>
      {enrichedTariffRanges &&
        enrichedTariffRanges.map((enrichedTariffRange, i) => (
          <div key={`${enrichedTariffRange.tariffId + i}`} className="div-tariffs">
            <LineItemCard
              className="line-item-fst"
              primaryTextDataHook="tariff-fee"
              primaryText={`Cuota ${enrichedTariffRange.commercial_name}`}
              link={{
                label: formatFee(enrichedTariffRange.monthlyFeeSofarWithTaxes),
                action: () => UIActionUpdate('tariff'),
                dataHook: 'tariff-fee-button',
              }}
              fieldsApart
            />
            <Typography variant="caption" component="div" className="xs-text p-y-0 p-x-24">
              {`(${formatRange(enrichedTariffRange.start, enrichedTariffRange.end)})`}
            </Typography>
          </div>
        ))}

      {!isEmpty(bundleGroups) &&
        bundleGroups.map(({ id, name, nameCountSuffix, totalFee }) => (
          <LineItemCard
            key={id}
            primaryText={name + nameCountSuffix}
            link={{
              label: formatFee(totalFee),
              action: () => UIActionUpdate('bundles'),
              dataHook: 'bundle-fee-button',
            }}
            fieldsApart
          />
        ))}

      {hasDiscounts && (
        <div data-hook="discounts-list" total-discounts={discounts.length}>
          {discounts.map((discount, i) => (
            <Fragment key={`${discount.discountId + i}`}>
              <LineItemCard
                className="line-item-fst"
                primaryTextDataHook={`discount-fee-name-${i}`}
                fieldsApart
                primaryText={`${discount.discountName}`}
                link={{
                  label: discount.isPercentage
                    ? `-${discount.amount} %`
                    : `-${formatDiscountProrrated(discount)}`,
                  action: () => UIActionUpdate('discounts'),
                  dataHook: `discount-fee-value-${i}`,
                }}
              />
              <Typography variant="caption" component="div" className="xs-text p-y-0 p-x-24">
                {`(${formatRange(discount.currMonthRange.start, discount.currMonthRange.end)})`}
              </Typography>
            </Fragment>
          ))}
        </div>
      )}

      <FATModalContainer isOpen={openFATModal} onCloseModal={setOpenFATModal} />

      {terminalsFee ? (
        <LineItemCard
          primaryText="Cuota dispositivo"
          fieldsApart
          link={{
            label: formatFee(terminalsFee),
            action: () => UIActionUpdate('terminalFee'),
            dataHook: 'terminal-fee-button',
          }}
        />
      ) : (
        ''
      )}
      {!isEmpty(VAS)
        ? VAS.map((service, i) => {
            const { contractTypeId, monthlyFee, currMonthRange } = service
            return (
              <Fragment key={`${contractTypeId + i}`}>
                <LineItemCard
                  primaryText={get(service, 'contractTypeName') || 'Agile TV'}
                  fieldsApart
                  link={{
                    label:
                      contractTypeId === SVA_WIFI_TOTAL_01 || contractTypeId === SVA_WIFI_TOTAL_02
                        ? formatSvaProrrated(service)
                        : formatFee(monthlyFee),
                    action: () => UIActionUpdate('VASFee'),
                  }}
                />
                <Typography variant="caption" component="div" className="xs-text p-y-0 p-x-24">
                  {`(${formatRange(currMonthRange.start, currMonthRange.end)})`}
                </Typography>
              </Fragment>
            )
          })
        : ''}

      {!isEmpty(restDiscounts) &&
        restDiscounts.map((discount, i) => (
          <Fragment key={`${discount.discountId + i}`}>
            <LineItemCard
              className="line-item-fst"
              primaryTextDataHook={`discount-fee-name-${i}`}
              fieldsApart
              primaryText={`${discount.discountName}`}
              link={{
                label: discount.isPercentage
                  ? `-${discount.amount} %`
                  : `-${formatDiscountProrrated(discount)}`,
                action: () => UIActionUpdate('discounts'),
                dataHook: `discount-fee-value-${i}`,
              }}
            />
            <Typography variant="caption" component="div" className="xs-text p-y-0 p-x-24">
              {`(${formatRange(discount.currMonthRange.start, discount.currMonthRange.end)})`}
            </Typography>
          </Fragment>
        ))}

      {!isEmpty(currentMonthFATs) && (
        <SubtotalsFATs
          currentMonthFATs={currentMonthFATs}
          totalExpenses={totalExpensesWithoutFats}
          SMSPriceWithTax={applyTax(tax, SMSPrice)}
        />
      )}

      <LineItemCard
        primaryText="Gasto total fuera de tarifa"
        fieldsApart
        link={{
          label: formatFee(restExpenses),
          action: () => UIActionUpdate('otherExpenses'),
          dataHook: 'other-expenses-button',
        }}
      />

      <Box p={PADDING_ELEMENT}>
        <Divider className="bold-divider" />
      </Box>

      <LineItemCard
        className="totals "
        fieldsApart
        primaryText="Gasto estimado total"
        secondaryText={`${formatFee(totalExpenses)}`}
      />
      {taxNeeded && (
        <Typography variant="body1" className="xs-text xxs-text p-y-0 p-x-24">
          (Impuestos incluídos)
        </Typography>
      )}
      {!isEmpty(currentMonthFATs) && !has100per100Discount && totalFATDebtNextMonth < 0 && (
        <FATsExcedeed
          currentMonthFATs={currentMonthFATs}
          totalFATDebtNextMonth={totalFATDebtNextMonth}
        />
      )}
      {!isEmpty(futureMonthFATs) && <FutureFATs futureMonthFATs={futureMonthFATs} />}
      {hasBundlesCallError() && (
        <div className="p-x-24 p-y-12">
          <ActionAdvice type="error" icon="warning" message={ERROR_BUNDLES_CALL_MESSAGE} />
        </div>
      )}
      <Box pt="8px" pb="12px">
        <Divider />
      </Box>

      {isPrepaid ? (
        <>
          {isLoadingPrepaid && (
            <div className="loader-wrapper">
              <SpinnerCenter />
            </div>
          )}
          {!isLoadingPrepaid && !isEmpty(errorsPrepaid) && (
            <span className="msg-error">No ha sido posible cargar la información</span>
          )}
          {!isLoadingPrepaid && isEmpty(errorsPrepaid) && !isEmpty(currentBalance) && (
            <div>
              <Typography variant="subtitle2" color="textSecondary" className="text-style m-t-12">
                <TitleLineItemCard className="prepaid-title" primaryText="Saldo" isHeader />
              </Typography>
              <LineItemCard
                primaryText="Saldo actual"
                secondaryText={`${currentBalance} €`}
                fieldsApart
              />

              <div className="m-y-12">
                <Box margin="10px" padding={PADDING_ELEMENT}>
                  <OutlinedDivider />
                </Box>
                <Typography className="text-style m-t-12" variant="subtitle2" color="textSecondary">
                  <TitleLineItemCard
                    className="prepaid-title"
                    primaryText="Últimos movimientos"
                    isHeader
                  />
                </Typography>
                {!isEmpty(prepaidEvents) ? (
                  <>
                    {prepaidEvents.map(event => (
                      <LineItemCard
                        key={JSON.stringify(event.date)}
                        primaryText={`${format(new Date(event.date), 'dd/MM/yyyy - HH:mm')}h`}
                        secondaryText={`${parseFloat(
                          get(event, 'amount_without_vat') + get(event, 'taxes'),
                        )
                          .toFixed(2)
                          .replace('.', ',')} €`}
                        fieldsApart
                      />
                    ))}
                    <ButtonLink
                      className="btn-lnk-prepaid"
                      to={`${location.pathname}/prepaid-detail`}
                      dataHook="prepaid-detail">
                      Ver histórico
                    </ButtonLink>
                  </>
                ) : (
                  <Typography variant="body1" className="text-style italic p-y-8 p-x-24">
                    No tiene recargas
                  </Typography>
                )}
              </div>
            </div>
          )}
        </>
      ) : (
        <LatestBillsContainer />
      )}
    </Card>
  )
}

MonthlyConsumptionCard.propTypes = {
  consumptionData: PropTypes.shape({
    restExpenses: PropTypes.number,
    terminalsFee: PropTypes.number,
    VAS: PropTypes.array,
    VASFee: PropTypes.number,
    hasDiscounts: PropTypes.bool,
    discounts: PropTypes.arrayOf(PropTypes.object),
    totalExpenses: PropTypes.number,
    enrichedTariffRanges: PropTypes.arrayOf(PropTypes.object),
    activeBundlesWithFees: PropTypes.array,
    bundleGroups: PropTypes.array,
    restDiscounts: PropTypes.array,
    totalExpensesWithoutFats: PropTypes.number,
  }),
  taxNeeded: PropTypes.bool,
  isPrepaid: PropTypes.bool,
  prepaidData: PropTypes.object,
  UIActionUpdate: PropTypes.func,
  fats: PropTypes.arrayOf(
    PropTypes.shape({
      meta: PropTypes.object,
      air_time: PropTypes.object,
      air_time_group: PropTypes.object,
    }),
  ),
  billDiscountPermission: PropTypes.bool,
  tax: PropTypes.number,
}
