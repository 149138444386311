import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Typography, Icon } from '@material-ui/core'
import { TextStyles } from 'utils/text'
import { ICONS } from 'utils/icons'

import { ButtonLink } from 'components/ui'

export const ItemLine = styled(({ className, type, label, action }) => {
  return (
    <div className={className}>
      {type && <Icon className="icon">{ICONS[type]}</Icon>}

      <Typography
        {...TextStyles.subtitle2Dark({
          style: {
            marginRight: '18px',
          },
        })}>
        {label}
      </Typography>
      {action && (
        <ButtonLink
          primaryColor
          underLine
          fontWeight={500}
          onClick={action.onClick}
          disabled={action.disabled}>
          {action.label}
        </ButtonLink>
      )}
    </div>
  )
})`
  display: flex;
  justify-content: left;
  align-content: center;

  > .icon {
    margin-right: 8px;
  }
`

ItemLine.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  action: PropTypes.shape({
    label: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
  }),
}
