import { constants } from './constants'

const initialState = {
  isAllowed: {
    data: null,
    loading: null,
  },
  compatibleDiscounts: {
    data: [],
    loading: null,
  },
  tariffsDiscounts: {
    data: [],
    loading: null,
  },
}

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case constants.FETCH_CROSS_SELL_ALLOWED: {
      return { ...state, isAllowed: { ...initialState.isAllowed, loading: true } }
    }
    case constants.FETCH_CROSS_SELL_ALLOWED_SUCCESS: {
      return { ...state, isAllowed: { data: true, loading: false } }
    }
    case constants.FETCH_CROSS_SELL_ALLOWED_FAILED: {
      return {
        ...state,
        isAllowed: {
          data: null,
          error: true,
          loading: false,
        },
      }
    }
    case constants.RESET_CROSS_SELL_ALLOWED: {
      return {
        ...state,
        isAllowed: {
          ...initialState.isAllowed,
        },
      }
    }

    case constants.FETCH_CROSS_COMPATIBLE_DISCOUNTS: {
      return {
        ...state,
        compatibleDiscounts: { ...initialState.compatibleDiscounts, loading: true },
      }
    }
    case constants.FETCH_CROSS_COMPATIBLE_DISCOUNTS_SUCCESS: {
      return { ...state, compatibleDiscounts: { data: payload, loading: false } }
    }
    case constants.FETCH_CROSS_COMPATIBLE_DISCOUNTS_FAILED: {
      return {
        ...state,
        compatibleDiscounts: {
          data: [],
          error: true,
          loading: false,
        },
      }
    }
    case constants.RESET_CROSS_COMPATIBLE_DISCOUNTS: {
      return {
        ...state,
        compatibleDiscounts: {
          ...initialState.compatibleDiscounts,
        },
      }
    }

    case constants.FETCH_CROSS_TARIFF_PROMOTIONS: {
      return {
        ...state,
        tariffsDiscounts: { ...initialState.tariffsDiscounts, loading: true },
      }
    }
    case constants.FETCH_CROSS_TARIFF_PROMOTIONS_SUCCESS: {
      return { ...state, tariffsDiscounts: { data: payload, loading: false } }
    }
    case constants.FETCH_CROSS_TARIFF_PROMOTIONS_FAILED: {
      return {
        ...state,
        tariffsDiscounts: {
          data: null,
          error: true,
          loading: false,
        },
      }
    }

    default:
      return state
  }
}
