import { call, takeLatest, put, all, select } from 'redux-saga/effects'
import { selectSfid, LEGACY_CUSTOMER_TOKEN } from 'modules/Auth'
import { selectProfileId } from 'modules/Permissions/store/permissions-selectors'

import * as customer360Svc from 'services/customer-360'
import { actions as customerSvaServiceActions } from 'services/customer-sva/actions'
import { callContentfulSaga } from 'modules/contentful'
import { callTariffsSaga } from 'modules/tariffs/store-apigee/tariffs-apigee.call'
import { callBonusesSaga } from 'modules/bonuses'
import { getSfidSuffix } from 'modules/offers-configuration/helpers'
import { selectIsLegacyCustomer, selectIsCustomer } from 'modules/Auth/store/selectors'

import { getCampaignArea } from 'modules/vista-360/helpers/helpers'
import { selectFindCustomerSvaFlag } from 'services/customer-sva/selectors'

import { actions } from './actions'
import { constants } from './constants'

function* dashboardLoadSaga({ payload: { documentType } }) {
  const isLegacyCustomer = yield select(selectIsLegacyCustomer)
  const shouldFetchCustomerSva = yield select(selectFindCustomerSvaFlag)

  try {
    // Resets
    yield put(customer360Svc.actions.reset())

    if (documentType === 'UNKNOWN') {
      const payload = {
        error: {
          all: {
            msg: 'Unknown document type.',
          },
        },
      }

      return yield put(actions.loadFailed(payload))
    }

    // Rest services
    // Customer360
    const errorCodeCust360 = yield call(
      customer360Svc.callSaga,
      isLegacyCustomer ? LEGACY_CUSTOMER_TOKEN : undefined,
    )

    if (errorCodeCust360) {
      const payload = {
        error: {
          customer360Svc: errorCodeCust360,
        },
      }

      return yield put(actions.loadFailed(payload))
    }

    const isCustomer = yield select(selectIsCustomer)

    if (isCustomer) {
      const documentId = yield select(customer360Svc.selectors.getIdentificationId)

      if (shouldFetchCustomerSva) {
        const idType = yield select(customer360Svc.selectors.getIdentificationType)

        yield all([
          put(customer360Svc.actions.fetchVipType(documentId)),
          put(customerSvaServiceActions.findCustomerSvaInit(documentId, idType)),
        ])
      } else {
        yield put(customer360Svc.actions.fetchVipType(documentId))
      }
    }
    if (isLegacyCustomer) {
      return yield put(actions.loadSucceeded())
    }

    const sfid = yield select(selectSfid)
    const agentProfile = yield select(selectProfileId)
    const sfidSuffix = getSfidSuffix(sfid)
    const area = getCampaignArea(agentProfile, sfid)

    const contentfulParallelCalls = yield all([
      call(callContentfulSaga, 'removePenaltyReasonOptions', { brands: 'YOIGO' }),
      call(callContentfulSaga, 'condonePermanenceReasonOptions', { brands: 'YOIGO' }),
      call(callContentfulSaga, 'typificationsCvmCampaign', {
        area,
        spaceName: 'cvm',
      }),
      call(callContentfulSaga, 'typificationsCvmNoutil', {
        area,
        spaceName: 'cvm',
      }),
      call(callContentfulSaga, 'typificationsCvmFidelizacion', { spaceName: 'cvm' }),
      call(callContentfulSaga, 'typificationsCvmRetention', { isRetained: true, spaceName: 'cvm' }),
      call(callContentfulSaga, 'typificationsCvmOperator', { spaceName: 'cvm' }),
      call(callContentfulSaga, 'typificationsCvmNoutil', {
        area,
        spaceName: 'cvm',
      }),
      call(callContentfulSaga, 'legalText', { origin: 'cvm' }),
      call(callContentfulSaga, 'sfidProfiles', { 'sfid[in]': sfidSuffix }),
    ])

    const errorCode = contentfulParallelCalls.find(ele => Boolean(ele))
    if (errorCode) {
      return yield put(actions.loadFailed({ error: { code: errorCode } }))
    }

    const segment = yield select(customer360Svc.selectors.getSegment)
    const parallelCalls = yield all([
      call(callTariffsSaga, { segment }),
      call(callTariffsSaga, { status: 'retired,hidden', segment }),
    ])

    yield call(callBonusesSaga)

    const tariffErrorCode = parallelCalls.find(ele => Boolean(ele))
    if (tariffErrorCode) {
      return yield put(actions.loadFailed({ error: { code: tariffErrorCode } }))
    }
    return yield put(actions.loadSucceeded())
  } catch (e) {
    const payload = {
      error: {
        all: {
          msg: e,
        },
      },
    }

    return yield put(actions.loadFailed(payload))
  }
}

export function* watchLoad() {
  yield takeLatest(constants.LOAD_INIT, dashboardLoadSaga)
}
