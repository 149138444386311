import { createStructuredSelector, createSelector } from 'reselect'
import {
  selectCustomerReachedOrderLimit,
  selectCustomerOrderLimitLoading,
  selectCustomerOrderError,
} from 'modules/CustomerOrders'
import {
  getIdentificationId,
  getIdentificationType,
  selectIsSelfEmployed,
  getSegment,
} from 'services/customer-360/selectors'
import { selectQueryParams } from 'modules/Router/store/index'

const selectSaleInformation = createSelector(
  getIdentificationId,
  getIdentificationType,
  selectIsSelfEmployed,
  getSegment,
  selectQueryParams,
  (docNumber, docType, selfEmployed, segment, { channel }) => ({
    docNumber,
    docType,
    selfEmployed:
      (!!selfEmployed && selfEmployed !== 'false') ||
      (segment || '').toLowerCase().includes('autonomo'),
    segment: (segment || '').toLowerCase().includes('pro') ? 'pro' : 'residential',
    channel,
  }),
)

export const selectSaleWithClientCardButton = createStructuredSelector({
  customerId: getIdentificationId,
  saleInformation: selectSaleInformation,
  hasOrderLimit: selectCustomerReachedOrderLimit,
  isLoading: selectCustomerOrderLimitLoading,
  error: selectCustomerOrderError,
})
