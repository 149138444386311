import PropTypes from 'prop-types'
import { get, head, isEmpty } from 'lodash'
import styled from 'styled-components'

import { Typography, Box, Divider } from '@material-ui/core'
import { TextStyles } from 'utils/text'

import { findMobilePermanents, getFeesPendingFromPermanent } from 'services/subscriptions'

import { PermanencyTab } from './PermanencyTab'
import { PendingAndTotalFee } from './PendingAndTotalFee'

export const PermanencyTariffFromTerminal = styled(({ className, subscription }) => {
  const permanent = head(findMobilePermanents(subscription))

  return permanent ? (
    <div className={className}>
      <Typography
        {...TextStyles.subtitle1Secondary({
          className: 'txt-none p-b-12',
        })}>
        Penalización por dispositivo
      </Typography>
      <div className="flex">
        {!isEmpty(permanent) && (
          <>
            <PermanencyTab validFor={permanent.validFor} totalMonths={permanent.length} />

            <Box mt="8px">
              <Divider orientation="vertical" />
            </Box>

            <PendingAndTotalFee
              className="p-l-24"
              title="Penalización"
              pendingFee={getFeesPendingFromPermanent(permanent)}
              totalFee={get(permanent, 'fees.penalty', 0)}
            />
          </>
        )}
        <Box mt="8px">
          <Divider orientation="vertical" />
        </Box>
      </div>
    </div>
  ) : null
})`
  .txt-none {
    text-transform: none;
  }
`

PermanencyTariffFromTerminal.propTypes = {
  className: PropTypes.string,
}
