export const RETENTION_PROFILE = 'RET'
export const ATC_PROFILE = 'ATC'
export const LOYALTY_PROFILE = 'FIDELIZACION'

export const LEGACY_CUSTOMER_TOKEN = 'LEGACY_CUSTOMER_TOKEN'

export const ISLAND_SFID_LIST = [
  'BY135004',
  'BY135210',
  'BY135290',
  'BY135509',
  'BY138004',
  'BY138350',
  'BY138626',
  'BY138631',
  'BY138660',
  'BY235006',
  'BY235212',
  'BY235220',
  'BY235500',
  'BY235510',
  'BY335002',
  'BY335212',
  'BY338204',
  'BY338312',
  'BY338650',
  'BY435110',
  'DX135019',
  'DX135600',
  'DX138107',
  'DX138109',
  'DX138430',
  'DX138670',
  'DX207300',
  'DX207760',
  'DX238760',
  'DX307003',
  'LM107005',
  'LM107006',
  'LM107400',
  'LM135010',
  'LM135013',
  'LM135018',
  'LM135400',
  'LM138107',
  'LM138205',
  'LM138300',
  'LM138320',
  'LM138530',
  'LM238002',
  'LM238005',
  'TA107500',
  'LB135110',
  'LB135460',
  'LB138005',
  'LB138202',
  'LB138611',
  'LB207007',
  'LB207800',
  'LBE10029',
]
