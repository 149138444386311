import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { get } from 'lodash'
import { makeStyles } from '@material-ui/styles'

import { getSubscriptionTariffId, findFee } from 'services/subscriptions'
import { getCommercialName, selectTariffsApigeeById } from 'modules/tariffs/store-apigee'
import { getSegment, selectors as customer360SvcSelectors } from 'services/customer-360/selectors'
import { formatMonthlyFee } from 'utils'
import { isProCustomer } from 'services/customer-360/helpers'
import { applyTax, getTax } from 'services/taxes'

import { Badge } from 'modules/ui'
import { ItemLine } from '../ItemLine'

const useStyles = makeStyles({
  'lines-badges': {
    marginTop: '18px',
  },
})

export const MultisimDeviceDetails = ({ multisimData }) => {
  const classes = useStyles()
  const billingAddress = useSelector(customer360SvcSelectors.getBillingAddress)
  const postCode = get(billingAddress, 'postCode')
  const customerSegment = useSelector(getSegment)
  const taxNeeded = !isProCustomer(customerSegment)
  const tax = (taxNeeded && useSelector(state => getTax(state, postCode))) || 0

  return (
    <div>
      {useSelector(state =>
        multisimData.map(elem => {
          const tariffId = getSubscriptionTariffId(elem)
          const tariff = selectTariffsApigeeById(tariffId)(state)
          const feeAmount = get(findFee(elem), 'price.amount', 0)
          const feeAmountWithTax = applyTax(tax, feeAmount)
          const multisimType = get(elem, 'multisimType')
          const msisdn = get(elem, 'id')
          const label = `Multisim - ${msisdn}`

          return (
            <div key={`multisim-details-${msisdn}`}>
              <ItemLine type="simCard" label={label} />
              <div className={classes['lines-badges']}>
                <Badge
                  lg
                  keyValContrast
                  value={[
                    {
                      label: 'Tarifa',
                      value: getCommercialName(tariff, false, multisimType),
                    },
                    { label: 'Cuota', value: formatMonthlyFee(feeAmountWithTax) },
                  ]}
                />
              </div>
            </div>
          )
        }),
      )}
    </div>
  )
}
MultisimDeviceDetails.propTypes = {
  multisimData: PropTypes.array.isRequired,
}
