import * as Yup from 'yup/lib'
import { noSpecialCharactersRegex, zipCodeRegex } from 'utils'
import {
  REQUIRED_FIELD_TEXT,
  INVALID_FLAT,
  INVALID_DOOR,
  INVALID_ZIP_CODE,
} from 'modules/SharedSales/constants'

export const ServiceAddressSchema = {
  serviceAddress: Yup.object({
    otherAddress: Yup.object({
      address: Yup.string().required(REQUIRED_FIELD_TEXT),
      number: Yup.string().when('editAddress', {
        is: true,
        then: Yup.string().nullable(),
        otherwise: Yup.string()
          .nullable()
          .required(REQUIRED_FIELD_TEXT),
      }),
      flat: Yup.string().matches(noSpecialCharactersRegex, INVALID_FLAT),
      door: Yup.string().matches(noSpecialCharactersRegex, INVALID_DOOR),
      zipCode: Yup.string()
        .matches(zipCodeRegex, INVALID_ZIP_CODE)
        .required(REQUIRED_FIELD_TEXT),
      city: Yup.string().required(REQUIRED_FIELD_TEXT),
      province: Yup.string().required(REQUIRED_FIELD_TEXT),
    }),
  }),
}
