import PropTypes from 'prop-types'
import styled from 'styled-components'
import { ButtonLink } from '../buttons/ButtonLink'
import ToolTipMultiline from '../tooltip/TooltipMultiline'

const OfferItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const ItemLabel = styled.div`
  color: ${({ theme }) => theme.palette.global.gray};
  font-size: 12px;
  font-weight: bold;
`

// TODO: move #9d9d9d to palette
const ItemValue = styled.div`
  color: ${({ disabled, theme }) => (disabled ? '#9d9d9d' : theme.palette.global.gray_dark)};
  font-size: 14px;
  display: unset;
`

const OfferItem = ({
  label,
  value,
  actionClick,
  actionText,
  disabled,
  hasTooltip,
  placement,
  visible,
  tooltipText,
  multiline,
  ellipsis,
}) => (
  <OfferItemWrapper disabled={disabled} className="offer-item" data-hook="offer-item">
    <ItemLabel>{label}</ItemLabel>
    {hasTooltip ? (
      <ToolTipMultiline
        placement={placement}
        visible={visible}
        title={tooltipText}
        multiline={multiline}
        ellipsis={ellipsis}>
        <ItemValue disabled={disabled}>{value}</ItemValue>
      </ToolTipMultiline>
    ) : (
      <ItemValue disabled={disabled}>{value}</ItemValue>
    )}
    <ButtonLink disabled={disabled} onClick={actionClick}>
      {actionText}
    </ButtonLink>
  </OfferItemWrapper>
)

OfferItem.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.string,
  actionClick: PropTypes.func,
  actionText: PropTypes.string,
  hasTooltip: PropTypes.bool,
  tooltipText: PropTypes.array,
  placement: PropTypes.oneOf([
    'top-start',
    'top',
    'top-end',
    'left-start',
    'left',
    'left-end',
    'right-start',
    'right',
    'right-end',
    'bottom-start',
    'bottom',
    'bottom-end',
  ]),
  visible: PropTypes.bool,
  multiline: PropTypes.bool,
  ellipsis: PropTypes.bool,
}

export default OfferItem
