import { actionTypes } from 'modules/typifications/constants'

export const setDataFormTypifications = ({ subreason, isValid, dirty, ...restData }) => ({
  type: actionTypes.SET_DATA_FORM_TYPIFICATIONS,
  payload: {
    data: {
      subreason1: subreason,
      ...restData,
    },
    valid: isValid,
    dirty,
  },
})
