export const FETCH_CONTENTFUL_RESOURCE = 'OVID/CONTENTFUL/FETCH_RESOURCE'
export const FETCH_CONTENTFUL_RESOURCE_SUCCESS = 'OVID/CONTENTFUL/FETCH_RESOURCE_SUCCESS'
export const FETCH_CONTENTFUL_RESOURCE_ERROR = 'OVID/CONTENTFUL/FETCH_RESOURCE_ERROR'

export const fetchContentfulResourceAction = (resource, params, { alias = '' } = '') => {
  return {
    type: FETCH_CONTENTFUL_RESOURCE,
    payload: {
      resource,
      params,
      alias,
    },
  }
}

export const fetchContentfulResourceSuccessAction = (resource, data, alias = '') => ({
  type: FETCH_CONTENTFUL_RESOURCE_SUCCESS,
  payload: {
    resource,
    data,
    alias,
  },
})

export const fetchContentfulResourceErrorAction = (resource, error, alias = '') => ({
  type: FETCH_CONTENTFUL_RESOURCE_ERROR,
  payload: {
    resource,
    error,
    alias,
  },
})
