import { Component } from 'react'
import PropTypes from 'prop-types'
import { differenceWith, isEqual, head as lodashHead } from 'lodash'
import { APP_CONFIG } from 'services/app-config'
import { SpinnerCenter } from 'components/ui'
import {
  addOwnStyles,
  errorBorderStyles,
  defaultBorderStyles,
  addressInputClassName,
  numberInputClassName,
} from './helpers'
import './styles.css'

const vendor = `${APP_CONFIG.coverage_host}/files/vendor.js`
const main = `${APP_CONFIG.coverage_host}/files/main.js`
const vendorcss = `${APP_CONFIG.coverage_host}/files/vendor.css`

let styleSheetList
class Coverage extends Component {
  state = {
    showCoverage: true,
    loading: true,
  }

  async componentDidMount() {
    if (this.props.withoutCss) {
      const prevStyleSheet = document.querySelector('.coverage-styles')
      if (prevStyleSheet) {
        document.body.removeChild(prevStyleSheet)
      }
      await addOwnStyles(this.props.noToast)
    }
    styleSheetList = [...document.styleSheets]

    if (!this.isLoadedScript(vendor)) {
      await this.loadScriptsComponentCoverage(vendor, false)
    }

    if (!this.props.isTesting) {
      // TODO: Optimize this script loading. Nowadays, coverage doesn't unsubscribe onCoverageCheckFinished when component will unmount
      await this.loadScriptsComponentCoverage(main, false)
    }

    if (!this.isLoadedScript(vendorcss)) {
      await this.loadScriptsComponentCoverage(vendorcss, true)
    }
    await this.initializeCoverageCheck()
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.hasFormErrors !== this.props.hasFormErrors) {
      await this.updateInputListBorderStyles(this.props.hasFormErrors)
    }
  }

  onCoverageCheckFinished = json => {
    this.setState({ showCoverage: this.props.showCoverage })
    const { MM } = window
    MM.default.clearState()
    this.props.onCoverageCheckFinished(JSON.parse(json))
    Array.prototype.forEach.call(document.querySelectorAll('link[cdn=coverage]'), element => {
      element.parentNode.removeChild(element)
    })
  }

  getNumberInputElement = coverageComponent => {
    const elements = coverageComponent.getElementsByClassName(numberInputClassName)
    return elements.length > 0 ? elements[1] : undefined
  }

  updateInputListBorderStyles = async hasFormErrors => {
    const coverageComponent = document.getElementById(this.props.containerId)
    const numberInput = this.getNumberInputElement(coverageComponent, numberInputClassName)
    if (numberInput) {
      this.updateInputBorderStyles(numberInput, hasFormErrors)
    } else {
      const addressInput = lodashHead(
        coverageComponent.getElementsByClassName(addressInputClassName),
      )
      this.updateInputBorderStyles(addressInput, hasFormErrors)
    }
  }

  updateInputBorderStyles = (input, hasFormErrors) => {
    /* eslint-disable no-param-reassign */
    if (input && hasFormErrors) {
      input.style.border = errorBorderStyles
    } else if (input) {
      input.style.border = defaultBorderStyles
      /* eslint-enable no-param-reassign */
    }
  }

  isLoadedScript = src =>
    !!this.props.isTesting || document.querySelector(`script[src="${src}"]`) !== null

  loadScriptsComponentCoverage = async (src, isCss) =>
    new Promise((resolve, reject) => {
      let script
      if (isCss) {
        script = document.createElement('link')
        script.setAttribute('rel', 'stylesheet')
        script.setAttribute('type', 'text/css')
        script.setAttribute('href', src)
        script.setAttribute('cdn', 'coverage')
        script.addEventListener('load', () => {
          if (this.props.withoutCss) {
            // TODO: FIX STYLES: Temporal solution: This checks the styles inserted by the js script
            // (not only the css one) but some added through e.style.cssText and removes them
            const styles = differenceWith([...document.styleSheets], styleSheetList, isEqual)
            if (styles.length) {
              styles.forEach(i => {
                /* eslint-disable no-param-reassign */
                i.disabled = true
                /* eslint-enable no-param-reassign */
              })
            }
          }
          resolve()
        })
        script.addEventListener('error', e => {
          reject(e)
        })
        document.head.appendChild(script)
        const head = document.getElementsByTagName('head')[0]
        head.insertBefore(script, head.childNodes[0])
      } else {
        script = document.createElement('script')
        script.className = 'coverage-script'
        script.src = src
        script.addEventListener('load', () => {
          resolve()
        })
        script.addEventListener('error', e => {
          reject(e)
        })
        document.body.appendChild(script)
      }
    })

  initializeCoverageCheck = () => {
    const wrapper = document.getElementById(this.props.containerId)
    if (!wrapper) return null
    wrapper.innerHTML = ''
    const { MM } = window

    const config = { token: this.props.token, idHtml: this.props.containerId }
    MM.default.Cobertura.init(config, this.onCoverageCheckFinished)
    this.setState({ loading: false })
    return false
  }

  render() {
    const { showCoverage, loading } = this.state
    return (
      <div data-hook="coverage-form">
        {showCoverage && (
          <div id={this.props.containerId}>
            <div id={`${this.props.containerId}-result`} />
          </div>
        )}
        {loading && !this.props.withoutCss && <SpinnerCenter style={{ marginTop: '20px' }} />}
        {loading && this.props.applicationId === '360' && <SpinnerCenter showMsg />}
      </div>
    )
  }
}

Coverage.propTypes = {
  onCoverageCheckFinished: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
  withoutCss: PropTypes.bool,
  showCoverage: PropTypes.any,
  containerId: PropTypes.string.isRequired,
  hasFormErrors: PropTypes.bool,
  isTesting: PropTypes.string,
  noToast: PropTypes.bool,
  applicationId: PropTypes.string,
}

Coverage.defaultProps = {
  showCoverage: false,
  hasFormErrors: false,
}

export default Coverage
