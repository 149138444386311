import { axiosJWT } from 'modules/axios'
import { APP_CONFIG } from 'services/app-config'

const reserveURL = `${APP_CONFIG.sales_cluster}/v1/terminals/soft-reservation`

export function reserveTerminalById(productId, channelId) {
  return axiosJWT({
    method: 'GET',
    params: {
      productId,
      channel: channelId,
      warehouse: 'MEYGD01',
      quantity: 1,
    },
    url: reserveURL,
  }).then(({ data }) => data.id)
}
