import { axiosJWT, axiosCustomer } from 'modules/axios'
import { get } from 'lodash'
import { CONFIG } from './environments'

export const fetchDiscounts = (profiles, category) =>
  axiosJWT({
    method: 'GET',
    headers: {
      'X-Authorization': axiosCustomer.defaults.headers.common.Authorization,
    },
    url: `${CONFIG.api.discounts}?profiles=${profiles}&category=${category}`,
  }).then(resp => get(resp, 'data.discountAvailabilities', []))
