import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'

import LabelWithInfo from 'components/packages/ui/forms/LabelWithInfo'

import { isLoadingTarrifsV3 } from 'modules/offers-configuration/selectors'
import { TariffWarnings } from 'modules/offers-configuration/components/TariffWarnings'
import { CircularProgress, Box, Divider } from '@material-ui/core'
import { Button } from 'components/ui'
import { ContainerTitle } from 'components'

import SelectorTariffsPo from 'modules/offers-configuration/components/SelectorTariffsPo/SelectorTariffsPo'
import { selectStcV3Flag, selectStcV3Phase2Flag } from 'services/feature-flag/selectors'
import { initTariffsV3 } from 'modules/offers-configuration/actions'
import { useOrderChannelAndStatus } from 'modules/offers-configuration/hooks/useOrderChannelAndStatus/useOrderChannelAndStatus'
import { TECHNOLOGY_CHANGE, ADDRESS_CHANGE, NEXT_DAY } from 'modules/offers-configuration/constants'

export const ChangesHeader = ({
  currentAddress,
  commercialName,
  newAddress,
  newNumber,
  coverageReset,
  goToStep,
  filteredEnrichTariffs,
  taxNeeded,
  subscription,
  onTariffSelect,
  selectedPsIdTariff,
  tariffSegment,
  subscriptionId,
  territoryOwner,
  currentFee,
  isCommercialMigration,
}) => {
  const dispatch = useDispatch()
  const isStcV3Flag = useSelector(selectStcV3Flag)
  const isStcV3Phase2Flag = useSelector(selectStcV3Phase2Flag)
  const loadingTariffsV3 = useSelector(isLoadingTarrifsV3)

  const { orderChannel, status } = useOrderChannelAndStatus()
  const purpose = newAddress ? ADDRESS_CHANGE : TECHNOLOGY_CHANGE

  useEffect(() => {
    if (isStcV3Flag && !loadingTariffsV3) {
      dispatch(
        initTariffsV3(
          orderChannel,
          status,
          tariffSegment,
          subscriptionId,
          isStcV3Phase2Flag,
          purpose,
          territoryOwner,
        ),
      )
    }
  }, [])

  return (
    <>
      {isCommercialMigration ? (
        <>
          <LabelWithInfo label="Tarifa Actual:" value={commercialName} />
          <LabelWithInfo label="Cuota Actual:" value={currentFee} />
        </>
      ) : (
        <>
          <ContainerTitle>DIRECCIÓN DE INSTALACIÓN</ContainerTitle>
          <LabelWithInfo label="Dirección Actual:" value={currentAddress} />
          {newAddress && (
            <Box display="flex">
              <LabelWithInfo label="Dirección Nueva:" value={newAddress} />
              <Box mt="5px">
                <Button
                  primaryColor
                  style={{ padding: 0 }}
                  onClick={() => {
                    coverageReset()
                    goToStep('addressForm')
                  }}>
                  Modificar
                </Button>
              </Box>
            </Box>
          )}
          {newNumber && <LabelWithInfo label="Nº asignado:" value={newNumber} />}
          <Box mt="15px" mb="15px">
            <Divider />
          </Box>
          <ContainerTitle>CAMBIO DE TARIFA</ContainerTitle>
          <LabelWithInfo label="Tarifa Actual:" value={commercialName} />
        </>
      )}
      {loadingTariffsV3 ? (
        <CircularProgress size="32px" color="secondary" />
      ) : (
        <>
          <LabelWithInfo
            label="Tarifa Nueva:"
            alignItems
            value={
              <SelectorTariffsPo
                filteredEnrichTariffs={filteredEnrichTariffs}
                taxNeeded={taxNeeded}
                subscription={subscription}
                onTariffSelect={onTariffSelect}
                salesType={purpose}
                date={NEXT_DAY.value}
              />
            }
          />
          <TariffWarnings
            date={Date.now()}
            subscription={subscription}
            selectedPsIdTariff={selectedPsIdTariff}
            taxNeeded={taxNeeded}
          />
        </>
      )}
    </>
  )
}

ChangesHeader.propTypes = {
  currentAddress: PropTypes.string,
  commercialName: PropTypes.string,
  newAddress: PropTypes.string,
  newNumber: PropTypes.string,
  coverageReset: PropTypes.func,
  goToStep: PropTypes.func,
  subscription: PropTypes.object,
  tariffSegment: PropTypes.string,
  subscriptionId: PropTypes.number,
  taxNeeded: PropTypes.bool,
  filteredEnrichTariffs: PropTypes.array,
  onTariffSelect: PropTypes.func,
  selectedPsIdTariff: PropTypes.string,
  territoryOwner: PropTypes.string,
  currentFee: PropTypes.string,
  isCommercialMigration: PropTypes.bool,
}
