import { get, find, head, startsWith } from 'lodash'

export const getContractId = order => {
  const characteristics = get(order, 'characteristics')
  if (!characteristics) return ''
  const referenceNumberCharacteristic = characteristics.find(i => i.name === 'reference_number')
  return referenceNumberCharacteristic ? referenceNumberCharacteristic.value : ''
}

export const formatAdditionalContracts = (order, orderId, svas, orderCheckout) => {
  const contracts = get(find(order.characteristics, { name: 'contracts' }), 'value', [])
  const orderItem = find(orderCheckout.relationships.order_items, { id: orderId })
  const childOrderItems = orderItem.relationships.child_order_items
  return contracts.map(contract => {
    const value = contract.agreementId
    const orderItemId = contract.orderItem
    const orderChildItem = find(childOrderItems, { id: orderItemId })
    const info =
      head(
        get(
          head(get(orderChildItem, 'relationships.order_product', [])),
          'relationships.product_offering',
          [],
        ),
      ) || {}
    const orderChildItemId = info?.id
    const type = get(find(svas, { poId: orderChildItemId }), 'psId')

    return {
      value,
      type,
    }
  })
}

export const formatCheckoutOrderMainLineInfo = orderCheckout => {
  const allOrders = get(orderCheckout, 'relationships.order_items', [])

  return allOrders
    .filter(order => startsWith(order.id, 'main-line'))
    .map(orderItem => {

      const characteristics = get(
        orderItem,
        'relationships.order_product[0].attributes.inputted_characteristics',
        {}
      )

      return {
        id: orderItem.id,
        characteristics,
      }
    })
    .find(order => order?.characteristics?.ch_msisdn)
}
