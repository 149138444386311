import { axiosJWT } from 'modules/axios'

import { APP_CONFIG } from 'services/app-config'

const BASE_URL_IS_DEVICE_RENEWAL_ALLOWED = `${
  APP_CONFIG.sales_cluster
}/v1/customers/renewal-validation`

const BASE_URL_IS_TV_RENEWAL_ALLOWED = `${
  APP_CONFIG.sales_cluster
}/v1/customers/renewal-tv-validation`

const BASE_URL_FETCH_RENEWAL_DEVICES = `${APP_CONFIG.sales_cluster}/v1/terminals/renewal/catalog`

const BASE_URL_FETCH_FINANCING_CONDITIONS = `${
  APP_CONFIG.sales_cluster
}/v1/customers/financingConditions`

export function fetchDeviceRenewalAllowed(
  customerId,
  msisdn,
  channel,
  subscriptionId,
  phoneNumber,
) {
  return axiosJWT({
    method: 'GET',
    url: BASE_URL_IS_DEVICE_RENEWAL_ALLOWED,
    params: {
      msisdn,
      accountId: customerId,
      orderChannel: channel,
      subscriberId: subscriptionId,
      phoneNumber,
      ordersCount: 1,
      salesType: 'renewal',
    },
  }).then(resp => resp.data)
}

export function fetchTvRenewalAllowed(customerId, msisdn, channel, subscriptionId, phoneNumber) {
  return axiosJWT({
    method: 'GET',
    url: BASE_URL_IS_TV_RENEWAL_ALLOWED,
    params: {
      msisdn,
      accountId: customerId,
      orderChannel: channel,
      subscriberId: subscriptionId,
      phoneNumber,
      ordersCount: 1,
      salesType: 'renewal',
    },
  }).then(resp => resp.data)
}

export function fetchDevicesOffers(
  docNumber,
  docType,
  subscriptionSegment,
  selfEmployed,
  renewalSaleChannel,
  offerCode,
  deviceType,
  subscriptionLineType,
  subscriptionPaymentType,
  tariffType,
) {
  return axiosJWT({
    method: 'GET',
    url: BASE_URL_FETCH_RENEWAL_DEVICES,
    params: {
      docNumber,
      docType,
      subscriptionSegment,
      selfEmployed,
      channel: renewalSaleChannel,
      offerCode,
      deviceType,
      subscriptionLineType,
      paymentType: subscriptionPaymentType,
      tariffType,
    },
  }).then(resp => {
    return resp.data
  })
}

export function fetchDeviceFinancingConditions(msisdn, renewalSaleChannel) {
  return axiosJWT({
    method: 'GET',
    url: BASE_URL_FETCH_FINANCING_CONDITIONS,
    params: {
      msisdn,
      channel: renewalSaleChannel,
    },
  }).then(resp => resp.data)
}
