import PropTypes from 'prop-types'
import styled from 'styled-components'
import { isEmpty } from 'lodash'
import { Button, ModalActions } from 'components/ui'
import { POSTPAID_CHARACTERISTIC_NAME, PREPAID_CHARACTERISTIC_NAME } from 'services/subscriptions'

const paymentTypeMap = {
  [POSTPAID_CHARACTERISTIC_NAME]: 'pospago',
  [PREPAID_CHARACTERISTIC_NAME]: 'prepago',
}

const isDisabled = availableTariffs => isEmpty(availableTariffs)

const PostOrPreTariffStep = styled(
  ({
    currentPaymentType,
    onPaymentTypeChange,
    availableTariffs,
    onTitleChange,
    stcV3Flag,
    getTariffsOrder,
  }) => {
    const primaryAction = () => {
      onTitleChange(currentPaymentType)
      onPaymentTypeChange(currentPaymentType)
      getTariffsOrder('plan-change')
    }
    const primaryText = `Cambio de tarifa ${paymentTypeMap[currentPaymentType]}`
    const secondaryPaymentType =
      currentPaymentType === POSTPAID_CHARACTERISTIC_NAME
        ? PREPAID_CHARACTERISTIC_NAME
        : POSTPAID_CHARACTERISTIC_NAME
    const secondaryAction = () => {
      onTitleChange(secondaryPaymentType)
      onPaymentTypeChange(secondaryPaymentType)
      getTariffsOrder('plan-migration')
    }
    const secondaryText = `Pasar a ${paymentTypeMap[secondaryPaymentType]}`
    const primaryAvailableTariffs = availableTariffs.filter(
      tariff => tariff.payment_type === currentPaymentType,
    )
    const secondaryAvailableTariffs = availableTariffs.filter(
      tariff => tariff.payment_type === secondaryPaymentType,
    )
    return (
      <ModalActions>
        <Button
          data-hook={`btn-change-tariff-${paymentTypeMap[currentPaymentType]}`}
          onClick={primaryAction}
          disabled={!stcV3Flag && isDisabled(primaryAvailableTariffs)}>
          {primaryText}
        </Button>
        <Button
          data-hook={`btn-change-tariff-${paymentTypeMap[secondaryPaymentType]}`}
          secondary
          onClick={secondaryAction}
          disabled={!stcV3Flag && isDisabled(secondaryAvailableTariffs)}>
          {secondaryText}
        </Button>
      </ModalActions>
    )
  },
)`
  padding: 12px;
`
PostOrPreTariffStep.propTypes = {
  currentPaymentType: PropTypes.string.isRequired,
  onPaymentTypeChange: PropTypes.func.isRequired,
  availableTariffs: PropTypes.arrayOf(PropTypes.object).isRequired,
  onTitleChange: PropTypes.func,
  stcV3Flag: PropTypes.boolean,
  getTariffsOrder: PropTypes.func.isRequired,
}

export default PostOrPreTariffStep
