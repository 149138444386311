/* eslint-disable import/no-cycle */
import { useAnalytics } from 'modules/Analytics/hooks/index'
import { selectTransactionForAnalytics } from 'modules/NewClientSales/store/selectors/index'
import { useScrollToTop, usePreventReload, usePreventBackwards } from 'modules/ui/hooks'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/styles'

import { selectKairosSalesFlag } from 'services/feature-flag/selectors'

import { LeadAndLinesSummary, SetupLines } from '../../components/MobileOnlyFlow'
import {
  APPOINTMENT,
  BILLING,
  INFO,
  NEXT_STEPS,
  OFFERS,
  SUMMARY,
  STEPS,
} from '../../constants/wizardSteps'
import { BillingContainer } from '../../containers/BillingContainer'
import { ClientInfoContainer } from '../../containers/ClientInfoContainer'
import { OfferContainer } from '../../containers/OfferContainer'
import { NewSalesAppointment } from '../../containers/SalesContainer/NewSalesAppointment'
import { NewAppointmentContainer } from '../../containers/SalesContainer/NewAppointment'
import { NextStepsContainer } from '../../containers/SalesContainer/NextSteps/NextStepsContainer'
import { SummaryContainer } from '../../containers/SalesContainer/Summary'

const useStyles = makeStyles(theme => ({
  'new-client-sales-step': {
    maxWidth: '1260px',
    margin: '0 auto',
    padding: '40px 24px',

    '& .card-title': {
      color: theme.palette.brand.medium,
      fontWeight: 'bold',
      '&.small': {
        fontSize: '14px',
      },
    },

    '& .flex-grow': {
      flexBasis: 'auto',
    },

    '& .label': {
      color: theme.palette.global.gray_dark,
      fontWeight: 500,
    },

    '& .error-label': {
      color: theme.palette.error.main,
      fontWeight: 500,
    },

    '& .radio-check': {
      '& + span': {
        color: '#2f2f2f' /* TODO: define new colors in stylesheet */,
        fontWeight: 300,
        lineHeight: 'normal',
      },
    },
  },
}))

export function NewClientSalesStep({ step, ...props }) {
  const analytics = useAnalytics()
  const analyticsTransaction = useSelector(selectTransactionForAnalytics)

  const kairosSalesFlag = useSelector(selectKairosSalesFlag)

  const flowSteps = {
    [INFO]: props.isMobileOnly ? LeadAndLinesSummary : ClientInfoContainer,
    [BILLING]: BillingContainer,
    [SUMMARY]: SummaryContainer,
    [APPOINTMENT]: kairosSalesFlag ? NewSalesAppointment : NewAppointmentContainer,
    [NEXT_STEPS]: NextStepsContainer,
    [OFFERS]: props.isMobileOnly ? SetupLines : OfferContainer,
  }

  const classes = useStyles()

  const Container = flowSteps[step]

  const { scrollToTop } = useScrollToTop()

  const { addPreventReload, removePreventReload } = usePreventReload()
  const { addPreventBackwards, removePreventBackwards } = usePreventBackwards()

  useEffect(() => {
    scrollToTop()
    if (analytics && analyticsTransaction?.items?.length) {
      analytics.sendCheckoutEvent({
        step: STEPS[step],
        items: analyticsTransaction.items,
      })
    }
  }, [step, analytics])

  useEffect(() => {
    addPreventReload()

    return () => {
      removePreventReload()
    }
  }, [])

  useEffect(() => {
    addPreventBackwards()

    return () => {
      removePreventBackwards()
    }
  }, [Container])

  return (
    <div className={classes['new-client-sales-step']}>
      <Container step={step} {...props} />
    </div>
  )
}

NewClientSalesStep.propTypes = {
  step: PropTypes.string.isRequired,
  className: PropTypes.string,
  isMobileOnly: PropTypes.bool,
}
