import { createSelector } from 'reselect'
import { get, find } from 'lodash'

export const getOrderProducts = state => state.orderProducts.data

export const mapProductOfferingIds = orderProducts => {
  const orderProduct = find(
    orderProducts,
    product =>
      get(product, 'data[0].attributes.inputted_characteristics.ch_msisdn') ||
      get(product, 'data[0].attributes.inputted_characteristics.ch_fixed_number'),
  )
  return get(orderProduct, 'data[0].relationships.product_offering[0].id')
}

export const selectOrderProductOfferingIds = createSelector(
  getOrderProducts,
  mapProductOfferingIds,
)
