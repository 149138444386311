import { axiosCustomer, axiosJWT } from 'modules/axios'
import { CONFIG } from './environments'

export const getDiscounts = (profiles, orderId) =>
  axiosJWT({
    method: 'GET',
    headers: {
      'X-Authorization': axiosCustomer.defaults.headers.common.Authorization,
    },
    url: `${CONFIG.api.discounts}?profiles=${profiles}&orderId=${orderId}`,
  }).then(resp => resp.data)
