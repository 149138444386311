import { selectTransactionForAnalytics } from 'modules/NewClientSales'
import { select, takeLatest } from 'redux-saga/effects'
import {
  SEND_TRANSACTION_COMPLETE,
  SEND_TRANSACTION_ERROR,
  PAGE_LOADED,
} from './analytics.constants'
import { analyticsInstance } from '../services'

export function sendTransactionCompleteSaga({ payload }) {
  analyticsInstance.sendTransactionComplete(payload)
}

export function* watchSendTransactionComplete() {
  yield takeLatest(SEND_TRANSACTION_COMPLETE, sendTransactionCompleteSaga)
}

export function* pageLoaded() {
  const fullTransaction = yield select(selectTransactionForAnalytics)

  analyticsInstance.pageLoaded({
    items: fullTransaction?.items,
    customerId: fullTransaction?.sfid,
  })
}

export function* watchPageLoaded() {
  yield takeLatest(PAGE_LOADED, pageLoaded)
}

export function sendTransactionErrorSaga({ payload }) {
  analyticsInstance.sendTransactionError(payload)
}

export function* watchSendTransactionError() {
  yield takeLatest(SEND_TRANSACTION_ERROR, sendTransactionErrorSaga)
}
