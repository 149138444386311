import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import PropTypes from 'prop-types'
import { head } from 'lodash'
import {
  Box,
  Grid,
  Divider,
  Checkbox,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core'

import { selectCancelPartialOrders } from 'services/feature-flag/selectors'
import { constants as customer360Constants } from 'services/customer-360/constants'

import { formatFee } from 'utils'

import { selectOrdersCancellationFlow } from 'modules/orders'
import {
  CANCELLATION_FLOW_TOGETHER,
  ADDITIONAL_LINE_CATEGORY_2P,
  ADDITIONAL_LINE_CATEGORY_EL_FIJO,
} from 'modules/orders/constants'

import { CardRow, CardTitle, CardRowLabel, CardRowValue } from 'components'
import { MainLineCancellationInfoModal } from '../CancelOrders/MainLineCancellationInfoModal/MainLineCancellationInfoModal'

const mainLineCancellationModalDescription =
  'Puedes cancelar individualmente la linea fija, la línea móvil o cancelar ambas'

const LineInformation = ({
  order,
  onOrderLineSelected,
  isOrderLineSelected,
  shouldShowLinesCheckboxes,
  replaceable,
}) => {
  const cancellationFlow = useSelector(selectOrdersCancellationFlow)
  const canCancelPartialOrders = useSelector(selectCancelPartialOrders)

  const cancellationFlowIsTogether = cancellationFlow === CANCELLATION_FLOW_TOGETHER
  const isMainLine = order.main

  const { lineType } = head(order.lines)

  function getMainLineTypeLabel(mainAdditionalLineCategory) {
    switch (mainAdditionalLineCategory) {
      case ADDITIONAL_LINE_CATEGORY_2P:
        return 'Fijo + Internet (2P)'
      case ADDITIONAL_LINE_CATEGORY_EL_FIJO:
        return 'El Fijo'
      default:
        return 'Paquete convergente'
    }
  }

  const lineTypeLabel = useMemo(() => {
    if (isMainLine) {
      return getMainLineTypeLabel(order.mainAdditionalLineCategory)
    }

    return lineType === customer360Constants.ADDITIONAL_LINE ? 'Línea Adicional' : 'Línea Extra'
  }, [lineType])

  return (
    <div style={{ width: '100%', position: 'relative', top: '-4px', marginBottom: '20px' }}>
      <CardRow>
        <Box display="flex">
          <CardTitle>Cancelar {lineTypeLabel}</CardTitle>
          {canCancelPartialOrders &&
            shouldShowLinesCheckboxes &&
            isMainLine &&
            !cancellationFlowIsTogether && (
              <MainLineCancellationInfoModal
                description={mainLineCancellationModalDescription}
                name="mainLineCancellation"
              />
            )}
        </Box>

        {order.lines.map((line, i) => (
          <Grid
            container
            style={{ marginTop: '13px' }}
            key={line.id}
            data-hook={`line-information-${line.id}`}>
            <Grid item xs={5}>
              <Box display="flex">
                {i === 0 && (
                  <div>
                    <CardRowLabel>Tarifa:</CardRowLabel>
                    <CardRowValue>{order.tariffName}</CardRowValue>
                  </div>
                )}
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box display="flex" height="100%">
                <Divider orientation="vertical" />
                {canCancelPartialOrders && shouldShowLinesCheckboxes && (
                  <Checkbox
                    data-hook={`cancel-order-line-${line.id}-checkbox`}
                    checked={isOrderLineSelected(order.id, line.id)}
                    onChange={e => {
                      onOrderLineSelected(order, line, e.target.checked)
                    }}
                    value={line}
                    style={{ padding: '0 0 0 10px', marginRight: '9px', alignItems: 'baseline' }}
                    disabled={!line.isCancellable}
                  />
                )}

                <Box padding="0 30px 0 10px">
                  <CardRowLabel>Número: </CardRowLabel>
                  <CardRowValue>{line.id}</CardRowValue>
                </Box>
                <Divider orientation="vertical" />
              </Box>
            </Grid>

            <Grid item xs={4}>
              <Box padding="0 0 0 25px">
                <CardRowLabel>Tipo: </CardRowLabel>
                <CardRowValue>{line.type || '-'}</CardRowValue>
              </Box>
            </Grid>
          </Grid>
        ))}

        {!!replaceable && (
          <Box pt="24px">
            <Typography variant="body1">
              AVISO: Vas a cancelar la <strong>Línea de cabecera</strong> del paquete convergente.
              Para mantener el paquete y sus descuentos tienes que elegir otra línea para
              sustituirla. La nueva línea pasará a tener la misma tarifa que la línea que la
              sustituye.
            </Typography>

            <Box width="100%" maxWidth="390px" mt="40px" mb="36px">
              <FormControl variant="outlined" style={{ width: '100%' }}>
                <InputLabel id="replaceable-order-label">
                  Selecciona la nueva línea cabecera
                </InputLabel>
                <Select
                  labelId="replaceable-order-label"
                  label="Selecciona la nueva línea cabecera"
                  value={replaceable.value}
                  displayEmpty
                  onChange={event => replaceable.onSetReplaceableOrder(event.target.value)}>
                  <MenuItem value="">
                    <em>Selecciona la nueva línea cabecera</em>
                  </MenuItem>
                  {replaceable.options.map(item => (
                    <MenuItem key={item.id} value={item}>
                      {item.lines[0].id}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
        )}

        {order.penalty !== null && (
          <Box display="flex" mt="13px">
            <CardRowLabel>Cargo de instalación: </CardRowLabel>
            <CardRowValue>{formatFee(order.penalty)}</CardRowValue>
          </Box>
        )}
      </CardRow>
    </div>
  )
}

LineInformation.propTypes = {
  order: PropTypes.object.isRequired,
  onOrderLineSelected: PropTypes.func,
  isOrderLineSelected: PropTypes.func,
  shouldShowLinesCheckboxes: PropTypes.bool,
  replaceable: PropTypes.shape({
    value: PropTypes.object,
    onSetReplaceableOrder: PropTypes.func,
    options: PropTypes.arrayOf(PropTypes.object),
  }),
}

export default LineInformation
