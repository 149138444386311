import { call, takeLatest, select, put } from 'redux-saga/effects'
import { get, isEmpty } from 'lodash'
import { selectURLProductId } from 'services/customer-products'
import * as subscriptionsSvc from 'services/subscriptions'
import { callProductOrdersByIdSaga } from 'services/product-orders/call'
import { selectors as customer360SvcSelectors } from 'services/customer-360/selectors'
import { actions as taxes } from 'services/taxes'
import { loadFailedAction, loadSucceededAction } from './actions'
import { constants } from './constants'

function* productDashboardLoadSaga() {
  try {
    const productId = yield select(selectURLProductId)

    // Subscripcion for product
    const errorSubscription = yield call(subscriptionsSvc.callSubscriptionByMsisdnSaga, productId)
    if (errorSubscription) {
      const payload = {
        error: {
          errorSubscription: errorSubscription && {
            code: yield select(subscriptionsSvc.getSubscriptionByMsisdnErrorCode),
            msg: yield select(subscriptionsSvc.getSubscriptionByMsisdnErrorMsg),
          },
        },
      }

      yield put(loadFailedAction(payload))
    }

    yield put(loadSucceededAction())

    const billingAddress = yield select(customer360SvcSelectors.getBillingAddress)
    const postCode = get(billingAddress, 'postCode')
    if (!isEmpty(postCode)) {
      yield put(taxes.findTax(postCode))
    }

    const sub = yield select(subscriptionsSvc.getSubscriptionByCurrentProductId)
    const prod = subscriptionsSvc.getProductById(sub, productId)
    const filterBy = subscriptionsSvc.isFixedLine(prod) ? 'fixednumber' : 'msisdn'

    // Orders for product
    yield call(callProductOrdersByIdSaga, productId, filterBy)
  } catch (e) {
    const payload = {
      error: {
        all: {
          msg: e,
        },
      },
    }

    yield put(loadFailedAction(payload))
  }
}

export function* watchLoad() {
  yield takeLatest(constants.LOAD_INIT, productDashboardLoadSaga)
}
