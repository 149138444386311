import PropTypes from 'prop-types'
import styled from 'styled-components'
import { get } from 'lodash'
import { getLighten } from 'utils'

import { Typography, Divider } from '@material-ui/core'

import { TextStyles } from 'utils/text'

import { OutlinedDivider } from '../OutlinedDivider'
import ConfigLine from './ConfigLine'
import { ButtonLink } from './../buttons/ButtonLink'
import ConfigTariffItem from './ConfigTariffItem'
import ConfigPortOut from './ConfigPortOut'
import ConfigChanges from './ConfigChanges'

const DetailBlock = styled.div`
  margin-top: 15px;
  margin-bottom: 16px;
`
const ConfigDetailContent = styled.div`
  padding-left: 24px;
  padding-right: 24px;
`
const ConfigLines = styled.div`
  display: flex;
  align-items: center;
`
const ConfigTariffItems = styled.div`
  display: flex;
  & > div {
    padding-right: 30px;
    &:not(:last-child) {
      border-right: 1px solid
        ${props => getLighten(get(props, 'theme.palette.global.gray_medium'), 0.8)};
    }
    &:not(:first-child) {
      padding-left: 30px;
    }
  }
`
const ConfigDevice = styled.div`
  display: flex;
  & > div {
    &:not(:first-child) {
      padding-left: 4px;
    }
  }
  button {
    margin-left: 17px;
  }
`

const ConfigDetail = ({
  titleTariff,
  lines,
  tariffDetails,
  collapsed,
  portOutData,
  hasDevice,
  handleModalDevice,
  items,
  onCancel,
  message,
  onComplete,
}) => (
  <div>
    <DetailBlock>
      <Typography {...TextStyles.subtitle1Secondary()}>{titleTariff}</Typography>
    </DetailBlock>

    <ConfigDetailContent>
      <DetailBlock>
        <ConfigLines>
          {lines.map(line => (
            <ConfigLine {...line} />
          ))}
        </ConfigLines>
      </DetailBlock>

      <DetailBlock>
        <ConfigTariffItems>
          {tariffDetails.map(tariff => (
            <ConfigTariffItem {...tariff} />
          ))}
        </ConfigTariffItems>
        <ConfigPortOut portOutData={portOutData} collapsed={collapsed} />
      </DetailBlock>

      <OutlinedDivider />

      <DetailBlock>
        <ConfigDevice>
          <Typography {...TextStyles.subtitle2Dark()}>Terminal:</Typography>
          <Typography {...TextStyles.subtitle2Dark()}>{hasDevice ? 'SI' : 'NO'}</Typography>
          {hasDevice || <ButtonLink onClick={handleModalDevice}>Añadir terminal</ButtonLink>}
        </ConfigDevice>
      </DetailBlock>

      <Divider />

      <DetailBlock>
        <ConfigChanges
          items={items}
          onCancel={onCancel}
          message={message}
          onComplete={onComplete}
        />
      </DetailBlock>
    </ConfigDetailContent>
  </div>
)

ConfigDetail.propTypes = {
  titleTariff: PropTypes.string,
  lines: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      line: PropTypes.string,
      status: PropTypes.oneOf([
        'new',
        'active',
        'port',
        'freeze',
        'fly',
        'unsubscribe',
        'historical',
        'incidence',
      ]),
      newStatus: PropTypes.oneOf([
        'new',
        'active',
        'port',
        'freeze',
        'fly',
        'unsubscribe',
        'historical',
        'incidence',
      ]),
    }),
  ),
  tariffDetails: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
      btnTxt: PropTypes.string,
    }),
  ),
  collapsed: PropTypes.bool,
  portOutData: PropTypes.arrayOf(
    PropTypes.shape({
      lines: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          info: PropTypes.arrayOf(
            PropTypes.shape({
              label: PropTypes.string,
              value: PropTypes.string,
            }),
          ),
        }),
      ),
    }),
  ),
  hasDevice: PropTypes.bool,
  handleModalDevice: PropTypes.func,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  onCancel: PropTypes.func,
  message: PropTypes.string,
  onComplete: PropTypes.func,
}

export default ConfigDetail
