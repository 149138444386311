import PropTypes from 'prop-types'

import { Collapse, Box } from '@material-ui/core'

export const CardContent = ({ className, collapsed, children, refContent }) => {
  return (
    <Collapse in={!collapsed} timeout="auto">
      <Box
        display="flex"
        flexDirection="column"
        className={['card-content', ...(className ? [className] : [])].join(' ')}
        ref={refContent}>
        {children}
      </Box>
    </Collapse>
  )
}
CardContent.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  collapsed: PropTypes.bool,
  refContent: PropTypes.object,
}
