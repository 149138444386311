export const DEVICE_TYPE_SELECTION = 'device-type-selection'
export const DEVICE_OFFERS = 'device-offers'
export const DEVICE_SUMMARY = 'device-summary'
export const BILLING = 'billing'
export const SUMMARY = 'summary'

export const ORDERED_DEVICE_RENEWAL_STEPS = [
  DEVICE_TYPE_SELECTION,
  DEVICE_OFFERS,
  DEVICE_SUMMARY,
  BILLING,
  SUMMARY,
]
