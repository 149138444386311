import StackdriverErrorReporter from 'stackdriver-errors-js'

import { APP_CONFIG } from '../app-config'
import pck from '../../package.json'

const errorHandler = new StackdriverErrorReporter()

export const registryErrorHandler = () => {
  errorHandler.start({
    projectId: APP_CONFIG.google_reporting.projectId,
    key: APP_CONFIG.google_reporting.key,
    service: 'ovid-front',
    version: pck.version,
    disabled: !APP_CONFIG.google_reporting.enabled,
    context: {},
    reportUnhandledPromiseRejections: false,
    reportUnhandledExceptions: false,
  })
}
registryErrorHandler()

export const setReportingUser = sfid => {
  return errorHandler.setUser(sfid)
}

const IGNORE_NETWORK_ERRORS_STATUS = [401]

export const formatErrorMessage = err => {
  if (!err.isAxiosError) {
    return err.message
  }

  if (IGNORE_NETWORK_ERRORS_STATUS.includes(err.response.status)) {
    return ''
  }

  const url = err.config.url.replace(/\/([a-zA-Z])?([0-9])+([a-zA-Z])?/, '/{field}')

  return `${err.message} (${err.config.method.toUpperCase()} ${url}) ${
    err.response.config.headers['X-Request-ID']
  }`
}

const sendError = err => {
  try {
    errorHandler.report(err)
  } catch (handlerError) {
    console.warn(handlerError)
  }
}

export const logError = err => {
  if (!err || (!err.e && !err.message) || (err.e && !err.e.message)) {
    return null
  }

  const error = err?.e || err

  sendError(error)
}
