import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { set, clone } from 'lodash'
import { Field, getIn, useFormikContext } from 'formik'
import { FormikInput, ButtonLink } from 'components/ui'

import { AddressAutocomplete } from 'modules/Address/components/AddressAutocomplete/index'

function OtherAddress({
  addressType = 'shipping',
  onSetAddress,
  className,
  onEditAddress,
  disabled,
  allowInputManualAddress,
  isRequiredFields,
  disableLinkModify,
}) {
  const {
    values,
    setFieldTouched,
    touched,
    errors,
    setFieldValue,
    validateForm,
    setTouched,
  } = useFormikContext()

  const addressDataField = `${addressType}.otherAddress`
  const addressFieldName = `${addressDataField}.address`

  const [fullAddress, address, streetType, isNotEdited] = useMemo(() => {
    const addressData = getIn(values, addressDataField)

    return [
      addressData,
      addressData.address,
      addressData.streetType,
      !addressData.address || addressData.editAddress,
    ]
  }, [values[addressType]])

  const disableFields = !(address && streetType === '-')
  const isManualAddress = address && !address.id && streetType === '-'
  const disabledProvince = !(allowInputManualAddress && isRequiredFields) || !isManualAddress

  const addressError = useMemo(
    () => getIn(touched, addressFieldName) && getIn(errors, addressFieldName),
    [touched, errors, addressFieldName],
  )

  const updateAddressField = (field, value) => {
    const currentAddress = clone(fullAddress)
    set(currentAddress, field, value)

    onSetAddress(currentAddress, addressType)
    setFieldValue(addressDataField, currentAddress)
  }

  const isRequiredField = inputValue => isRequiredFields && !inputValue?.trim() && 'Campo requerido'

  async function validateInputs() {
    const formErrors = await validateForm()
    setTouched(formErrors)
  }

  return (
    <div className={className}>
      <div className="flex flex-wrap">
        <div className="flex-grow full-width p-r-16">
          <Field name={addressFieldName}>
            {() => {
              return (
                <AddressAutocomplete
                  value={address}
                  name={addressFieldName}
                  disabled={disabled || !!address}
                  error={addressError}
                  onBlur={() => {
                    setFieldTouched(addressFieldName, true)
                  }}
                  onChange={value => {
                    onSetAddress(value, addressType)
                    setFieldValue(addressDataField, value)
                    validateInputs()
                  }}
                  allowInputManualAddress={allowInputManualAddress}
                />
              )
            }}
          </Field>
          {(!!address || disabled) && !disableLinkModify && (
            <ButtonLink
              dataHook="modify-other-address"
              type="button"
              onClick={() => onEditAddress(addressType)}>
              Modificar dirección
            </ButtonLink>
          )}
        </div>
        <div className="flex-grow p-r-16">
          <FormikInput
            disabled={{
              bool: disabled || isNotEdited || (/\d/.test(address) && disableFields),
            }}
            fullWidth
            name={`${addressType}.otherAddress.number`}
            label="Número"
            onChange={val => updateAddressField('number', val)}
            validate={isRequiredField}
            dataHook={`${addressType}.otherAddress.number`}
          />
        </div>

        <div className="flex-grow p-r-16">
          <FormikInput
            disabled={{ bool: disabled || isNotEdited }}
            fullWidth
            name={`${addressType}.otherAddress.flat`}
            label="Piso"
            maxLength="3"
            onChange={val => updateAddressField('flat', val)}
            dataHook={`${addressType}.otherAddress.flat`}
          />
        </div>

        <div className="flex-grow p-r-16">
          <FormikInput
            disabled={{ bool: disabled || isNotEdited }}
            fullWidth
            name={`${addressType}.otherAddress.door`}
            label="Puerta"
            maxLength="3"
            onChange={val => updateAddressField('door', val)}
            dataHook={`${addressType}.otherAddress.door`}
          />
        </div>

        <div className="flex-grow p-r-16">
          <FormikInput
            fullWidth
            disabled={{ bool: disabled || isNotEdited }}
            name={`${addressType}.otherAddress.otherInfo`}
            label="Otra información"
            maxLength="50"
            onChange={val => updateAddressField('otherInfo', val)}
            dataHook={`${addressType}.otherAddress.otherInfo`}
          />
        </div>

        <div className="flex-grow p-r-16">
          <FormikInput
            fullWidth
            name={`${addressType}.otherAddress.zipCode`}
            disabled={{ bool: disableFields }}
            label="Código Postal"
            onChange={val => isRequiredFields && updateAddressField('zipCode', val)}
            validate={isRequiredField}
            dataHook={`${addressType}.otherAddress.zipCode`}
          />
        </div>
        <div className="flex-grow p-r-16">
          <FormikInput
            fullWidth
            name={`${addressType}.otherAddress.city`}
            disabled={{ bool: disableFields }}
            label="Ciudad"
            onChange={val => isRequiredFields && updateAddressField('city', val)}
            validate={isRequiredField}
            dataHook={`${addressType}.otherAddress.city`}
          />
        </div>
        <div className="flex-grow p-r-16">
          <FormikInput
            fullWidth
            name={`${addressType}.otherAddress.province`}
            disabled={{ bool: disabledProvince }}
            label={
              isManualAddress && disabledProvince ? 'Provincia (Se recogerá del CP)' : 'Provincia'
            }
            onChange={val => updateAddressField('province', val)}
            validate={isRequiredField}
            dataHook={`${addressType}.otherAddress.province`}
          />
        </div>
      </div>
    </div>
  )
}

OtherAddress.defaultProps = {
  className: '',
  onSetAddress: () => {},
  allowInputManualAddress: true,
  isRequiredFields: false,
  disableLinkModify: false,
}

OtherAddress.propTypes = {
  addressType: PropTypes.string.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onSetAddress: PropTypes.func.isRequired,
  onEditAddress: PropTypes.func.isRequired,
  allowInputManualAddress: PropTypes.bool,
  isRequiredFields: PropTypes.bool,
  disableLinkModify: PropTypes.bool,
}

export default OtherAddress
