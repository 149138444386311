import * as constants from './constants'

export const initialState = {
  opened: false,
  error: null,
  ticketTypes: {
    loading: false,
    data: [],
    error: null,
  },
  categories: {
    loading: false,
    data: [],
    error: null,
  },
  subCategories: {
    loading: false,
    data: [],
    error: null,
  },
  ticketDataSteps: [],
  ticketCreation: {
    loading: false,
    data: null,
    error: null,
  },
}

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case constants.OPEN_CASE: {
      return {
        ...state,
        opened: true,
      }
    }

    case constants.CLOSE_CASE: {
      return {
        ...initialState,
      }
    }

    case constants.FIND_CATEGORIES_ATTEMPTED: {
      return {
        ...state,
        categories: {
          loading: true,
        },
      }
    }

    case constants.FIND_CATEGORIES_SUCCEEDED: {
      return {
        ...state,
        categories: {
          loading: false,
          data: payload.data,
        },
      }
    }
    case constants.FIND_CATEGORIES_FAILED: {
      return {
        ...state,
        categories: {
          ...state.categories,
          loading: false,
          error: { ...payload },
        },
      }
    }

    case constants.FIND_SUBCATEGORIES_ATTEMPTED: {
      return {
        ...state,
        subCategories: {
          loading: true,
        },
      }
    }

    case constants.FIND_SUBCATEGORIES_SUCCEEDED: {
      return {
        ...state,
        subCategories: {
          loading: false,
          data: payload.data,
        },
      }
    }
    case constants.FIND_SUBCATEGORIES_FAILED: {
      return {
        ...state,
        subCategories: {
          ...state.subCategories,
          loading: false,
          error: { ...payload },
        },
      }
    }

    case constants.FIND_TICKET_TYPES_ATTEMPTED: {
      return {
        ...state,
        ticketTypes: {
          loading: true,
        },
      }
    }

    case constants.FIND_TICKET_TYPES_SUCCEEDED: {
      return {
        ...state,
        ticketTypes: {
          loading: false,
          data: payload.data,
        },
      }
    }
    case constants.FIND_TICKET_TYPES_FAILED: {
      return {
        ...state,
        ticketTypes: {
          ...state.ticketTypes,
          loading: false,
          error: { ...payload },
        },
      }
    }

    case constants.CREATE_TICKET_ATTEMPTED: {
      return {
        ...state,
        ticketCreation: {
          loading: true,
        },
      }
    }
    case constants.CREATE_TICKET_SUCCEEDED: {
      return {
        ...state,
        ticketCreation: {
          loading: false,
          data: payload.data,
        },
      }
    }
    case constants.CREATE_TICKET_FAILED: {
      return {
        ...state,
        ticketCreation: {
          ...state.ticketCreation,
          loading: false,
          error: { ...payload },
        },
      }
    }

    case constants.UPDATE_TICKET_CREATION_FORM_DATA: {
      return {
        ...state,
        ticketDataSteps: [...state.ticketDataSteps.slice(0, payload.step), payload.data],
        ticketCreation: {
          loading: false,
          data: null,
          error: null,
        },
      }
    }

    default:
      return state
  }
}

export default reducer
