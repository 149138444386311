import * as constants from './constants'

// GET Product Orders Data
const createChangeTechnology = changeTechnologyData => ({
  type: constants.CREATE_CHANGE_TECHNOLOGY,
  payload: {
    data: changeTechnologyData,
    loading: true,
  },
})

const changeTechnologyCreated = data => ({
  type: constants.CREATE_CHANGE_TECHNOLOGY_SUCCESS,
  payload: {
    ...data,
    loading: false,
  },
})

const createChangeTechnologyFailed = error => ({
  type: constants.CREATE_CHANGE_TECHNOLOGY_FAILED,
  payload: { ...error },
})

const saveOperation = data => ({
  type: constants.CHANGE_TECHNOLOGY_SAVE_OPERATION_DATA,
  payload: {
    dataOperation: data,
  },
})

export const actions = {
  createChangeTechnology,
  changeTechnologyCreated,
  createChangeTechnologyFailed,
  saveOperation,
}
