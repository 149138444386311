import styled from 'styled-components'
import { get } from 'lodash'

const OfferCardFrontStyled = styled.div`
  background: ${props => get(props, 'theme.palette.brand.light')};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: absolute;
  height: 100%;
  width: 100%;
  .rotate-action {
    position: absolute;
    top: 8px;
    right: 0px;
  }
  .multicolumn & {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .offer-item {
      flex: 1 50%;
      width: 50%;
      box-sizing: border-box;
      &:nth-child(even) {
        border-left: 1px solid #ececec;
      }
      &:first-child,
      &:nth-child(2) {
        flex: none;
        width: 100%;
        border-left: none;
      }
    }
  }
`

const OfferCardFront = props => <OfferCardFrontStyled className="offer-card-front" {...props} />

export default OfferCardFront
