import * as constants from './constants'

const openCase = () => ({
  type: constants.OPEN_CASE,
})

const closeCase = () => ({
  type: constants.CLOSE_CASE,
})

// ticket categories
const findCategoriesSucceeded = data => ({
  type: constants.FIND_CATEGORIES_SUCCEEDED,
  payload: { data },
})

const findCategoriesFailed = error => ({
  type: constants.FIND_CATEGORIES_FAILED,
  payload: error,
})

const findCategories = ticketTypeId => ({
  type: constants.FIND_CATEGORIES_ATTEMPTED,
  payload: { ticketTypeId },
})

// ticket subcategories
const findSubcategoriesSucceeded = data => ({
  type: constants.FIND_SUBCATEGORIES_SUCCEEDED,
  payload: { data },
})

const findSubcategoriesFailed = error => ({
  type: constants.FIND_SUBCATEGORIES_FAILED,
  payload: error,
})

const findSubcategories = id => ({
  type: constants.FIND_SUBCATEGORIES_ATTEMPTED,
  payload: { id },
})

// ticket types
const findTicketTypesSucceeded = data => ({
  type: constants.FIND_TICKET_TYPES_SUCCEEDED,
  payload: { data },
})

const findTicketTypesFailed = error => ({
  type: constants.FIND_TICKET_TYPES_FAILED,
  payload: error,
})

const findTicketTypes = () => ({
  type: constants.FIND_TICKET_TYPES_ATTEMPTED,
})

// ticket creation
const createTicketSucceeded = data => ({
  type: constants.CREATE_TICKET_SUCCEEDED,
  payload: { data },
})

const createTicketFailed = error => ({
  type: constants.CREATE_TICKET_FAILED,
  payload: error,
})

const createTicket = ticketData => ({
  type: constants.CREATE_TICKET_ATTEMPTED,
  payload: { ticketData },
})

// update ticket data
const updateCreationTicketData = (data, step) => ({
  type: constants.UPDATE_TICKET_CREATION_FORM_DATA,
  payload: { data, step },
})

export default {
  openCase,
  closeCase,
  findCategoriesSucceeded,
  findCategoriesFailed,
  findCategories,
  findSubcategoriesSucceeded,
  findSubcategoriesFailed,
  findSubcategories,
  findTicketTypesSucceeded,
  findTicketTypesFailed,
  findTicketTypes,
  createTicketSucceeded,
  createTicketFailed,
  createTicket,
  updateCreationTicketData,
}
