import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { selectFeatureFlags } from './selectors'

/* Change the visibility of a component based on the feature role.
 *
 * class Hola extends Component {
 *   render() {
 *     return <div> Hola </div>
 *   }
 * }
 *
 * export default featureFlag('development')(Hola)
 */
export const featureFlag = flag => ComposedComponent => {
  const FeatureFlag = ({ featureFlags, ...props }) => {
    const isVisible = !!featureFlags[flag]
    return isVisible ? <ComposedComponent {...props} /> : null
  }

  FeatureFlag.propTypes = {
    featureFlags: PropTypes.any.isRequired,
    dispatch: PropTypes.func.isRequired,
  }

  const mapStateToProps = state => ({ ...selectFeatureFlags(state) })

  return connect(mapStateToProps)(FeatureFlag)
}

/* Switch the visibility of two components based on the feature role.
 *
 * class Hola extends Component {
 *   render() {
 *     return <div> Hola </div>
 *   }
 * }
 * class Adios extends Component {
 *   render() {
 *     return <div> Adios </div>
 *   }
 * }
 *
 * export default featureFlag('development')(Hola, Adios)
 */

export const featureFlagSwitchTwoOptions = flag => (ComposedComponent, OtherComposedComponent) => {
  const FeatureFlag = ({ featureFlags, ...props }) => {
    const isVisible = !!featureFlags[flag]
    return isVisible ? <ComposedComponent {...props} /> : <OtherComposedComponent {...props} />
  }

  FeatureFlag.propTypes = {
    featureFlags: PropTypes.any.isRequired,
    dispatch: PropTypes.func.isRequired,
  }

  const mapStateToProps = state => ({ ...selectFeatureFlags(state) })

  return connect(mapStateToProps)(FeatureFlag)
}
