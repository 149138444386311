import { get, flatten } from 'lodash'
import { createSelector } from 'reselect'
import { selectSfid } from 'modules/Auth'
import { selectNebaEnabledFlag } from 'services/feature-flag/selectors'
import {
  getUniqueKey,
  isSfidCancelNebaAuthorized,
  isTariffWithStatus,
} from './tariffs-apigee.helpers'

const selectTariffsApigee = state => state.tariffsApigee || {}

export const selectTariffsApigeeData = createSelector(selectTariffsApigee, store => {
  return get(store, 'data', {})
})

export const selectTariffsApigeeDataTariffs = createSelector(selectTariffsApigeeData, data => {
  return get(data, 'tariffs', {})
})

export const filterTariffsWithStatus = tariffStatus =>
  createSelector([selectTariffsApigeeDataTariffs], tariffs =>
    Object.keys(tariffs)
      .map(id => tariffs[id])
      .filter(tariff => isTariffWithStatus(tariff, tariffStatus)),
  )

export const selectTariffsApigeeDataFilters = createSelector(selectTariffsApigeeData, data => {
  return get(data, 'filters', {})
})

export const selectTariffsApigeeDataByFilters = filters =>
  createSelector(
    [selectTariffsApigeeDataTariffs, selectTariffsApigeeDataFilters],
    (tariffs, tariffsFilters) =>
      (tariffsFilters[getUniqueKey(filters)] || []).map(tariffId => tariffs[tariffId]),
  )

export const selectTariffsApigeeById = id =>
  createSelector(selectTariffsApigeeDataTariffs, tariffs => tariffs[id])

export const selectTariffApigeeOneTariff = id =>
  createSelector(selectTariffsApigeeData, data => get(data, `tariffById.${id}`))

export const selectTariffApigeeErrorCode = createSelector(
  selectTariffsApigee,
  tariffs => tariffs.error_code,
)

export const selectTariffApigeeErrorMessage = createSelector(
  selectTariffsApigee,
  tariffs => tariffs.error_message,
)

export const selectTariffApigeeIsLoading = createSelector(
  selectTariffsApigee,
  tariffs => tariffs.loading,
)

export const selectTariffApigeeConvergentTariffs = createSelector(
  selectTariffsApigeeData,
  data => data.convergent || [],
)

export const selectTariffApigeeConvergentTariffsIds = createSelector(
  selectTariffsApigeeData,
  data => data.convergentIds || [],
)

export const selectTariffsApigeeSelectedTerminals = createSelector(
  selectTariffsApigee,
  state => state.selectedTerminals,
)

export const selectTariffsApigeeSelectedTerminalDevices = createSelector(
  selectTariffsApigeeSelectedTerminals,
  terminals => flatten(Object.keys(terminals).map(lineType => terminals[lineType].devices)),
)

export const selectIsCancelNebaEnabled = createSelector(
  [selectSfid, selectNebaEnabledFlag],
  (sfid, nebaEnabled) => nebaEnabled && isSfidCancelNebaAuthorized(sfid),
)

export const selectTariffSegment = id =>
  createSelector(selectTariffsApigeeById(id), tariff => get(tariff, 'segment_type'))
