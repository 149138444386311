import { getIn } from 'formik'
import { chain } from 'lodash'
import { getMobileNumberType } from 'modules/Lines/helpers/index'

export function mapToTerminalValues(terminalListByCategories, excludeCategory = []) {
  let currentCategory = ''
  let currentBrand = ''
  let isCurrentFieldCategory = false

  const flattenCategories = category => [category.category, ...category.brands]

  const flattenBrands = (acc, brand) => {
    let result = []

    if (typeof brand === 'string') {
      currentCategory = brand
    } else {
      result = [currentCategory, brand.brand, ...brand.models]
    }

    return [...acc, ...result]
  }

  const flattenModels = (acc, model) => {
    if (typeof model === 'string' && !isCurrentFieldCategory) {
      currentCategory = model
      isCurrentFieldCategory = true
    } else if (typeof model === 'string' && isCurrentFieldCategory) {
      currentBrand = model
      isCurrentFieldCategory = false
    } else {
      acc.push({
        ...model,
        category: currentCategory,
        brand: currentBrand,
        label: model.name,
        key: `terminal-${model.terminalId}`,
        name: `terminal-${model.terminalId}`,
      })
    }

    return acc
  }

  //  NOTE: to avoid O(n^3) we flatten 3 levels of the tree
  const terminals = chain(terminalListByCategories)
    .filter(category => !excludeCategory.includes(category.category))
    .flatMap(flattenCategories)
    .reduce(flattenBrands, [])
    .reduce(flattenModels, [])
    .value()

  return terminals
}

export function mapToProfessionOptions(professions) {
  return professions
    ? professions.map(elem => ({
        label: elem.profession,
        value: elem.code,
        company: elem.company,
      }))
    : []
}

export function findSelectedCompany(professionId, professionOptions) {
  const selectedCompany = professionOptions.find(profession => profession.value === professionId)
  return selectedCompany || {}
}

export function getFormikProps(values, errors, touched, fieldNames, isElFijo) {
  const terminalCategory = getIn(values, fieldNames.TERMINAL_CATEGORY)
  const terminalBrand = getIn(values, fieldNames.TERMINAL_MANUFACTURER)
  const terminalId = getIn(values, fieldNames.TERMINAL_ID)
  const ps = getIn(values, `${fieldNames.TARIFF}.ps`)
  const portaPaymentType = getIn(values, fieldNames.PORTABILITY_PAYMENT_TYPE)
  const jobId = getIn(values, fieldNames.JOB)
  const signUpType = getIn(values, fieldNames.MOBILE_LINE_TYPE)
  const mobileNumberType = getMobileNumberType(signUpType, portaPaymentType, isElFijo)
  const paymentType = getIn(values, fieldNames.TERMINAL_PAYMENT)
  const isNewTerminal = getIn(values, fieldNames.HAS_TERMINAL) === 'yes'
  const reservationError =
    !!getIn(errors, fieldNames.RESERVATION_ID) && !!getIn(touched, fieldNames.RESERVATION_ID)

  return {
    signUpType,
    terminalCategory,
    terminalBrand,
    terminalId,
    ps,
    mobileNumberType,
    reservationError,
    paymentType,
    portaPaymentType,
    isNewTerminal,
    jobId,
  }
}
