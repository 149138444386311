import * as Yup from 'yup/lib'
import { mobileOrLanlineNumberRegex } from 'utils'
import { REQUIRED_FIELD_TEXT, ERROR_DATA_TEXT } from 'modules/SharedSales/constants/validations'

export const SalesLeadSchema = Yup.object().shape({
  sfId: Yup.string().trim().required(REQUIRED_FIELD_TEXT),
  brand: Yup.string().trim().required(REQUIRED_FIELD_TEXT),
  product: Yup.string().trim().required(REQUIRED_FIELD_TEXT),
  name: Yup.string().trim().required(REQUIRED_FIELD_TEXT),
  surname1: Yup.string().trim().required(REQUIRED_FIELD_TEXT),
  surname2: Yup.string().trim(),
  telephoneNumber: Yup.string()
    .matches(mobileOrLanlineNumberRegex, ERROR_DATA_TEXT)
    .trim()
    .required(REQUIRED_FIELD_TEXT),
  segment: Yup.string().trim().required(REQUIRED_FIELD_TEXT),
  contactHour: Yup.string().when('segment', {
    is: 'residential',
    then: Yup.string().trim().required(REQUIRED_FIELD_TEXT),
    otherwise: Yup.string(),
  }),
})
