function onUnexpectedLeave(e) {
  e.preventDefault()
  e.returnValue = true
}

export const usePreventReload = () => {
  const addStopper = () => window.addEventListener('beforeunload', onUnexpectedLeave)
  const removeStopper = () => window.removeEventListener('beforeunload', onUnexpectedLeave)

  return {
    addPreventReload: addStopper,
    removePreventReload: removeStopper,
  }
}
