import { Component } from 'react'
import { PropTypes } from 'prop-types'
import { isEqual, chain, isEmpty, get, every } from 'lodash'

import { Grid, CircularProgress, Button, Divider, Box } from '@material-ui/core'
import Close from '@material-ui/icons/Close'

import { categoriesTariff2P_3P } from 'modules/tariffs/store-apigee'
import { checkIsNeba, NEBA_FAKE_TERRITORY, STATUS_ACTIVE_HIDDEN } from 'modules/Coverage'

import {
  Card,
  SectionTitle,
  SectionGrid,
  SectionTitleGrid,
  SectionValidationGrid,
  WizardNavigation,
  ContainerTitle,
} from 'components'
import { getFullAddress } from 'services/product-orders/helpers'
import { checkForMsisdn } from 'services/check-order-ct-on-fly'
import { addRequestType, addInstallerInfo } from 'services/change-technology'
import { BUSSINESS_TYPE_RULES } from 'services/change-technology/constants'

import { AddressForm } from '../AddressForm'
import { CustomerMoveAddress } from '../CustomerMoveAddress'
import { Modal } from '../Modal'

import { ClientOffers, ClientInfo, ClientValidation } from '../'

import {
  formatAddress,
  formatOrderWithNewDirection,
  formatOrderWithoutNewDirection,
  replaceCustomerToCustomerNew,
} from '../../helpers/formats.helper'

import ActualAddress from './ActualAddress'

import { fillchangeTechnologyOrder } from './change-technology.helper'

class ChangeTechnology extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loadingData: true,
      adsl: null,
      currentStep: 0,
      maxSteps: 4,
      changeTechnologyJson: {},
      editedAddress: null,
      isFtthNewDirection: null,
      isEqualProvince: null,
      noCheckCoverage: null,
    }
  }

  componentDidMount() {
    this.props.getSubscriptionData({
      phoneNumber: this.props.fixedNumber,
      fixedline: this.props.fixedNumber,
      customerId: this.props.customerId,
      customerIdType: this.props.customerIdType,
    })
  }

  shouldComponentUpdate = (nextProps, nextState) => {
    const {
      tariffs,
      loadingOrder,
      coverageLoading,
      tariffsLoading,
      orderCTOnFly,
      orderOnFly,
      loadingInitialStep,
      isLoadingInstallations,
    } = this.props
    const { loadingData, adsl, currentStep } = this.state
    if (tariffsLoading !== nextProps.tariffsLoading) {
      if (!nextProps.tariffsLoading) {
        return true
      }
    }
    if (loadingInitialStep !== nextProps.loadingInitialStep) {
      return true
    }
    if (loadingOrder !== nextProps.loadingOrder) {
      return true
    }
    if (orderCTOnFly !== nextProps.orderCTOnFly) {
      return true
    }
    if (orderOnFly !== nextProps.orderOnFly) {
      return true
    }

    if (coverageLoading !== nextProps.coverageLoading) {
      return true
    }
    if (isEqual(currentStep, nextState.currentStep)) {
      if (loadingData && nextState.loadingData) {
        if (!isEqual(tariffs, nextProps.tariffs)) {
          return true
        }
        return false
      }
      if (!isEqual(loadingData, nextState.loadingData) && !isEqual(adsl, nextState.adsl)) {
        return true
      }
      if (isLoadingInstallations !== nextProps.isLoadingInstallations) {
        return true
      }
      return false
    }

    return true
  }

  componentDidUpdate = () => {
    const {
      customer,
      coverageData,
      isAdslOrder,
      sfid,
      coverageError,
      customerWithProductUpdated,
      productOrders,
      installationAddress,
    } = this.props
    const clientId = get(productOrders, 'clientId')
    const changeTechnologyJson = fillchangeTechnologyOrder(
      customer,
      sfid,
      coverageData,
      customerWithProductUpdated,
      clientId,
      installationAddress,
    )
    if (changeTechnologyJson !== null || changeTechnologyJson !== undefined) {
      this.setState({ changeTechnologyJson })
    }

    if (isAdslOrder) {
      this.setState({ loadingData: false, adsl: true })
    } else {
      this.setState({ loadingData: false, adsl: false })
    }

    if (coverageError) {
      this.setState({
        loadingData: false,
      })
    }
  }

  onCoverageCheckFinished = coverageData => {
    const {
      findOffers,
      tariffById,
      segment,
      findTariffs,
      territoryOwnerOrder,
      getTerritCompat,
      connectionType,
    } = this.props
    this.setState({ editedAddress: coverageData })
    this.checkCoverageFtth(coverageData)
    const territoryOwnerNewCoverage = chain(coverageData)
      .get('coverage')
      .head()
      .get('characteristics')
      .filter({ name: 'territoryOwner' })
      .head()
      .get('value')
      .value()

    findOffers({ fixedLineType: 'fiber' })
    if (typeof territoryOwnerNewCoverage === 'object' || territoryOwnerNewCoverage === null) {
      throw new Error({ name: 'Invalid territoryOwner' })
    }

    const category = categoriesTariff2P_3P(tariffById)
    const territoryOwnerFilterTariff = checkIsNeba(territoryOwnerNewCoverage)
      ? NEBA_FAKE_TERRITORY
      : territoryOwnerNewCoverage
    findTariffs(
      category
        ? {
            status: STATUS_ACTIVE_HIDDEN,
            segment,
            territoryOwner: territoryOwnerFilterTariff,
            category,
            fixedLineType: 'fiber',
          }
        : {
            status: STATUS_ACTIVE_HIDDEN,
            segment,
            territoryOwner: territoryOwnerFilterTariff,
            fixedLineType: 'fiber',
          },
    )

    getTerritCompat({
      originTech: connectionType,
      originTerritoryOwner: territoryOwnerOrder,
      destinationTerritoryOwner: territoryOwnerNewCoverage,
    })

    if (checkIsNeba(territoryOwnerNewCoverage)) {
      this.props.fetchInstallationDirection(get(coverageData, 'gescal', ''))
    }
  }

  setStep = () => {
    this.setState({
      currentStep: 1,
    })
  }

  checkDifferentsProvinces = data => {
    const zipCodeNewDirection = get(data, 'zipCode')
    const zipCodeOldDirection = get(this.props.customerInfo, 'postCode')
    const isEqualProvince =
      zipCodeOldDirection.substring(0, 2) === zipCodeNewDirection.substring(0, 2)
    this.setState({ isEqualProvince })
  }

  checkCoverageFtth = data => {
    const ftthCoverage = chain(data)
      .get('coverage')
      .head()
      .get('ftth')
      .value()
    if (!isEmpty(ftthCoverage)) {
      this.setState({ isFtthNewDirection: true })
      this.checkDifferentsProvinces(data)
    }
  }

  checkCoverageNewAddress = () => {
    const x =
      chain(this.state.editedAddress)
        .get('coverage')
        .head()
        .get('ftth')
        .value().length === 0
    return x
  }

  next = () => {
    const { currentStep, maxSteps } = this.state
    this.setState({
      currentStep: Math.min(currentStep + 1, maxSteps),
    })
  }

  previous = () => {
    const { currentStep } = this.state
    const nextStep = currentStep - 1 < 0 ? 0 : currentStep - 1
    this.setState({
      currentStep: nextStep,
    })
  }

  closeTab = () => {
    window.close()
  }

  goEditAddress = () => {
    this.setState({
      currentStep: 3,
      editedAddress: null,
      isFtthNewDirection: null,
      isEqualProvince: null,
    })
  }

  goEditAddressWithotCoverageFtth = () => {
    this.setState({
      currentStep: 3,
      editedAddress: null,
      isFtthNewDirection: null,
      isEqualProvince: null,
      noCheckCoverage: true,
    })
  }

  goToStep = step => {
    this.setState({ currentStep: step })
  }

  correctInfoAddress = customerInfo => {
    if (this.state.editedAddress) {
      return formatAddress(this.state.editedAddress)
    }
    return getFullAddress(customerInfo)
  }

  sendFillDataOrder = data => {
    const {
      sendOrder,
      customerNew,
      isFeatNebaEnabled,
      territoryCompatible,
      coverageData,
    } = this.props
    let dataWithFillCustomer = replaceCustomerToCustomerNew(data, customerNew)
    let requestType = ''
    if (BUSSINESS_TYPE_RULES.includes(territoryCompatible)) {
      if (territoryCompatible === 'neba' && !isFeatNebaEnabled) {
        requestType = ''
      } else {
        requestType = `-${territoryCompatible}`
      }
      dataWithFillCustomer = addRequestType(dataWithFillCustomer, requestType)
    }

    if (this.props.FFlagInstaller) {
      dataWithFillCustomer = addInstallerInfo(
        dataWithFillCustomer,
        this.props.installer,
        this.props.installConnectionType,
      )
    }

    if (this.state.editedAddress) {
      const orderWithNewDirection = formatOrderWithNewDirection(
        dataWithFillCustomer,
        this.state.editedAddress,
      )
      sendOrder(orderWithNewDirection)
    } else {
      const territoryOwnerCoverageApi = get(coverageData, 'territoryOwner')
      const addressIdCoverageApi = get(coverageData, 'addressId')
      const response = formatOrderWithoutNewDirection(
        dataWithFillCustomer,
        territoryOwnerCoverageApi,
        addressIdCoverageApi,
      )
      sendOrder(response)
    }
  }

  getCorrectTerritoryOwner = () => {
    const { editedAddress } = this.state
    const { coverageData } = this.props
    if (editedAddress) {
      return get(editedAddress, 'coverage[0].characteristics[0].value')
    }
    if (!editedAddress && coverageData) {
      return get(coverageData, 'territoryOwner', '')
    }
    return ''
  }

  render() {
    const {
      currentStep,
      disabled,
      maxSteps,
      changeTechnologyJson,
      adsl,
      loadingData,
      isEqualProvince,
      noCheckCoverage,
    } = this.state
    const {
      orderId,
      customerInfo,
      customer,
      tariffs,
      enrichment,
      coverage,
      coverageLoading,
      loadingOrder,
      tariffsError,
      tariffsLoading,
      orderOnFly,
      loadingInitialStep,
      tokenCoverage,
      productOrders,
      customerProd,
      isFeatNebaEnabled,
      descriptionTariffSubscription,
      territoryCompatible,
      orderError,
      isLoadingInstallations,
    } = this.props
    let content
    switch (currentStep) {
      case 0: {
        const { fixedNumber } = this.props
        const msisdn = chain(customerProd)
          .map(prod => (get(prod.productNumber.split('+'), '[1]') === fixedNumber ? prod.id : null))
          .filter(obj => obj)
          .head()
          .value()
        const orderCTOnFlyCheckForMsisdn = checkForMsisdn(this.props.orderCTOnFly, msisdn)
        content = (
          <ClientValidation
            loading={loadingInitialStep || loadingData}
            tariffsError={tariffsError}
            tariffsLoading={tariffsLoading}
            setStep={this.setStep}
            existCoverage={coverage === 'FTTH'}
            orderOnFly={orderOnFly}
            checkOrderCTOnFly={orderCTOnFlyCheckForMsisdn}
            coverageLoading={coverageLoading}
            tokenCoverage={tokenCoverage}
            goEditAddressFn={this.goEditAddressWithotCoverageFtth}
            orderError={orderError}
          />
          // <OrderModal orderId="Y38786545" showAppointment />
        )
        break
      }
      case 1:
        content = (
          <>
            <ClientInfo
              customerInfo={this.correctInfoAddress(customerInfo)}
              customer={customer.customer}
              customerTariff={descriptionTariffSubscription}
              coverage={coverage !== 'FTTH'}
              showInstallerInfo={false}
            />
          </>
        )
        break
      case 2:
        content = (
          <Grid container>
            <Grid item xs={12}>
              <ClientInfo
                customerInfo={this.correctInfoAddress(customerInfo)}
                customer={customer.customer}
                customerTariff={descriptionTariffSubscription}
                coverage={
                  noCheckCoverage === true ? this.checkCoverageNewAddress() : coverage !== 'FTTH'
                }
                installer={this.props.installer}
                showInstallerInfo={this.props.FFlagInstaller}
              />
            </Grid>
            {!territoryCompatible || territoryCompatible === 'none' ? (
              <div>
                <Modal
                  open
                  title="Aviso Importante!"
                  message="El territorio destino no es compatible, no se pueden mostrar tarifas."
                  cancelBtn="Salir"
                  quit
                />
              </div>
            ) : (
              <>
                <Box my="15px">
                  <Divider />
                </Box>
                <Grid item xs={12}>
                  <ClientOffers
                    newSubscription
                    tariffs={tariffs}
                    customer={customer.customer}
                    currentTariff={this.props.customerWithProductUpdated.products[0]}
                    changeTechnologyJson={changeTechnologyJson}
                    sendOrder={this.sendFillDataOrder}
                    orderId={orderId}
                    loadingOrder={loadingOrder}
                    enrichment={enrichment}
                    loading={loadingData}
                    productOrders={productOrders}
                    customerInfo={this.correctInfoAddress(customerInfo)}
                    isFeatNebaEnabled={isFeatNebaEnabled}
                    territoryOwner={this.getCorrectTerritoryOwner()}
                    customerTariff={descriptionTariffSubscription}
                    showAppointment={
                      !(checkIsNeba(this.getCorrectTerritoryOwner()) && isFeatNebaEnabled)
                    }
                  />
                </Grid>
              </>
            )}
          </Grid>
        )
        break
      case 3:
        content = (
          <>
            <ActualAddress data={this.correctInfoAddress(customerInfo)} />
            <Box my="15px">
              <Divider />
            </Box>
            <ContainerTitle>DIRECCIÓN NUEVA</ContainerTitle>
            <AddressForm
              tokenGenerated={tokenCoverage}
              gotCoverageData={this.onCoverageCheckFinished}
              next={this.next}
            />
          </>
        )
        break

      case 4:
        if (isLoadingInstallations) {
          content = <CircularProgress size={100} />
        } else {
          content = (
            <>
              <ActualAddress data={getFullAddress(customerInfo)} />
              <Box my="15px">
                <Divider />
              </Box>
              <CustomerMoveAddress
                address={formatAddress(this.state.editedAddress)}
                coverageData={this.state.editedAddress}
                closeWindowIfExistError={false}
                isFeatNebaEnabled={isFeatNebaEnabled}
                installer={this.props.installer}
                FFlagInstaller={this.props.FFlagInstaller}
                territoryOwner={this.getCorrectTerritoryOwner()}
              />
              {isEqualProvince === false && (
                <div style={{ margin: '10px 0' }}>
                  <p>
                    LA DIRECCIÓN INTRODUCIDA DEBE SER TRAMITADA POR EL PROCESO DE CAMBIO DE
                    DOMICILIO
                  </p>
                </div>
              )}
              <WizardNavigation
                previous={{
                  callback: this.goEditAddress,
                  disabled: false,
                  caption: 'Probar otra dirección',
                }}
                next={{
                  callback: () => this.goToStep(2),
                  disabled: !every(
                    [
                      this.state.isFtthNewDirection,
                      this.state.isEqualProvince,
                      this.state.noCheckCoverage === true ? !this.props.tariffsLoading : true,
                    ],
                    Boolean,
                  ),
                  caption: 'Confirmar dirección',
                }}
              />
            </>
          )
        }
        break
      default:
        content = <ClientValidation loading={loadingData} adsl={adsl} setStep={this.setStep} />
    }

    if (currentStep !== 0) {
      return (
        <>
          <SectionTitleGrid>
            <SectionTitle width="auto">Cambio de Tecnología</SectionTitle>
            <Button color="default" onClick={this.closeTab}>
              cancelar y volver
              <Close />
            </Button>
          </SectionTitleGrid>
          <SectionGrid>
            <Card width="80%">{content}</Card>
          </SectionGrid>
          <SectionGrid alignitems="center">
            {currentStep === 1 && (
              <>
                <Button
                  data-hook="Editar"
                  variant="contained"
                  color="primary"
                  onClick={this.goEditAddress}>
                  Editar
                </Button>
                <WizardNavigation
                  next={{
                    callback: this.next,
                    disabled: !!(disabled || currentStep === maxSteps),
                    caption: 'Siguiente',
                  }}
                />
              </>
            )}
          </SectionGrid>
        </>
      )
    }
    return (
      <>
        <SectionTitleGrid>
          <SectionTitle width="auto">Cambio de Tecnología</SectionTitle>
          <Button color="default" onClick={this.closeTab}>
            cancelar y volver
            <Close />
          </Button>
        </SectionTitleGrid>
        <SectionValidationGrid>{content}</SectionValidationGrid>
      </>
    )
  }
}

ChangeTechnology.propTypes = {
  getSubscriptionData: PropTypes.func,
  tariffs: PropTypes.array,
  customer: PropTypes.object,
  coverageData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  isAdslOrder: PropTypes.bool,
  orderId: PropTypes.string,
  customerInfo: PropTypes.object,
  installationAddress: PropTypes.object,
  orderOnFly: PropTypes.array,
  loadingInitialStep: PropTypes.bool,
  enrichment: PropTypes.array,
  coverage: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  loadingOrder: PropTypes.bool,
  coverageLoading: PropTypes.bool,
  sendOrder: PropTypes.func,
  sfid: PropTypes.string,
  customerId: PropTypes.string,
  customerIdType: PropTypes.string,
  fixedNumber: PropTypes.string,
  coverageError: PropTypes.string,
  customerNew: PropTypes.object,
  tariffsError: PropTypes.object,
  tokenCoverage: PropTypes.string,
  productOrders: PropTypes.object,
  customerProd: PropTypes.array,
  isFeatNebaEnabled: PropTypes.bool,
  installer: PropTypes.string,
  isLoadingInstallations: PropTypes.bool,
  tariffsLoading: PropTypes.bool,
  orderCTOnFly: PropTypes.object,
  customerWithProductUpdated: PropTypes.object,
  findOffers: PropTypes.func,
  tariffById: PropTypes.func,
  segment: PropTypes.string,
  findTariffs: PropTypes.func,
  territoryOwnerOrder: PropTypes.string,
  getTerritCompat: PropTypes.func,
  connectionType: PropTypes.string,
  fetchInstallationDirection: PropTypes.func,
  territoryCompatible: PropTypes.array,
  FFlagInstaller: PropTypes.string,
  installConnectionType: PropTypes.string,
  descriptionTariffSubscription: PropTypes.object,
  orderError: PropTypes.string,
}

export default ChangeTechnology
