import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { get } from 'lodash'

import * as customer360Svc from 'services/customer-360'
import { getProductNumbers, getDiscounts } from 'services/subscriptions'
import { getMsisdnByTypeSubscription } from 'modules/offers-configuration/helpers'
import { is2pPureTariff } from 'modules/vista-360/helpers/helpers'

export const SubscriptionFooter = ({ subscription, taxRate }) => {
  const discounts = getDiscounts(subscription)
  const productNumbers = getProductNumbers(subscription)
  const msisdn = getMsisdnByTypeSubscription(subscription)
  const multisimType = useSelector(state =>
    customer360Svc.selectors.selectMultisimType(state, msisdn),
  )

  return productNumbers.fixed ? (
    <ul>
      {!is2pPureTariff(get(subscription, 'type')) && <li>{productNumbers.mobile}</li>}
      <li>{productNumbers.fixed}</li>
      <li>Internet</li>
      {multisimType ? <li>{customer360Svc.helpers.getMultisimTypeStr(multisimType)}</li> : ''}
    </ul>
  ) : (
    <ul>
      <li>{productNumbers.mobile}</li>
      {multisimType && (
        <li>{customer360Svc.helpers.getMultisimTypeStr(multisimType, discounts, taxRate)}</li>
      )}
    </ul>
  )
}

SubscriptionFooter.propTypes = {
  subscription: PropTypes.object,
  taxRate: PropTypes.number,
}
