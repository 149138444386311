import * as constants from './constants'

const findTranslation = productId => ({
  type: constants.FIND_TRANSLATION,
  payload: {
    loading: true,
    productId,
  },
})

const foundTranslation = data => ({
  type: constants.FIND_TRANSLATION_SUCCESS,
  payload: {
    ...data,
    loading: false,
  },
})

const findTranslationFailed = () => ({
  type: constants.FIND_TRANSLATION_FAILED,
  payload: {
    loading: false,
  },
})

const getTranslatorInit = () => ({
  type: constants.GET_TRANSLATION_TOKEN_INIT,
})

const getTranslator = () => ({
  type: constants.GET_TRANSLATION_TOKEN,
})

const gotTranslator = token => ({
  type: constants.GOT_TRANSLATION_TOKEN,
  payload: { token },
})

const getTranslatorFailed = error => ({
  type: constants.GET_TRANSLATION_TOKEN_FAILED,
  payload: { error },
})

export const actions = {
  findTranslation,
  foundTranslation,
  findTranslationFailed,
  getTranslatorInit,
  getTranslator,
  gotTranslator,
  getTranslatorFailed,
}
