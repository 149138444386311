import { makeStyles } from '@material-ui/styles'

import PropTypes from 'prop-types'

const useStyles = makeStyles(theme => ({
  'advice-text-field': {
    'vertical-align': 'middle',
    'box-sizing': 'border-box',
    'font-size': '14px',

    color: theme.palette.global.gray_dark,
    padding: props => props.padding,

    '& strong': {
      'font-weight': '500',
    },
  },
}))

export function AdviceTextField({ children, padding, className }) {
  const classes = useStyles({ padding })

  return (
    <p className={` advice-text-field ${className || ''} ${classes['advice-text-field']}`}>
      {children}
    </p>
  )
}

AdviceTextField.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  padding: PropTypes.string,
}
