import axios from 'axios'
import { axiosJWT } from 'modules/axios'
import { CONFIG } from './environments'

export function create(changeTechnologyData) {
  return axiosJWT({
    method: 'POST',
    url: CONFIG.api.changeTechnology,
    data: changeTechnologyData,
  }).then(resp => resp.data)
}

export function logBackOfficeInfo(originalOrder, finalOrderId, targetTariffId) {
  return axios({
    method: 'POST',
    url: `${CONFIG.api.logBackOfficeInfo}`,
    data: { originalOrder, finalOrderId, targetTariffId },
  }).then(resp => resp.data)
}

export function fetchCDCTWorkOrderIds(subscriptionId) {
  return axiosJWT({
    method: 'GET',
    url: `${CONFIG.api.changeTechnology}/${subscriptionId}`,
  }).then(resp => resp.data)
}
