import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'

import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import { formatFee, TextStyles } from 'utils'
import { Modal, Button, ModalActions } from 'components/ui'

import { getFinancingInfo } from '../../helpers'

const useStyles = makeStyles({
  'device-modal-financed-payment': {},
  paymentGroup: {
    marginBottom: '30px',
  },
})

export const FinancedPaymentDetailModal = ({
  name,
  financingConditionsTaxes,
  financedPaymentInfo,
  open,
  onClose,
}) => {
  const classes = useStyles()

  const {
    monthlyPayment,
    monthlyFees,
    monthlyTotalPayment,
    finalPayment,
    commissionFee,
    commissionFeePercentage,
    totalPayment,
    tin,
    tae,
  } = useMemo(() => getFinancingInfo(financingConditionsTaxes, financedPaymentInfo), [
    financedPaymentInfo,
    financingConditionsTaxes,
  ])

  return open ? (
    <Modal
      data-hook="device-modal-financed-payment"
      title="Pago a Plazos"
      className={classes['device-modal-financed-payment']}
      onClose={onClose}
      isOpen>
      <div className={classes.paymentGroup}>
        <Typography {...TextStyles.subtitle2Dark()}>{name}</Typography>
        <Typography {...TextStyles.paragraphDark()}>
          Cuota: {monthlyPayment}€/mes. Permanencia de {monthlyFees} meses con{' '}
          {formatFee(get(financedPaymentInfo, 'permanency.penaltyAmountTaxFree'))} de penalización
        </Typography>
        <Typography {...TextStyles.paragraphDark()}>
          Comisión de aplazamiento: {formatFee(commissionFee)}
        </Typography>
        <Typography {...TextStyles.paragraphDark()}>
          Pago final: {formatFee(finalPayment)}
        </Typography>
      </div>

      {finalPayment > 0 && (
        <Typography
          {...TextStyles.paragraphDark({
            className: classes.paymentGroup,
          })}>
          <span className="bold">Pago final:</span> Después de 24 plazos, el mes 25 puedes decidir
          entre quedarte con el móvil (en ese caso, añadiríamos a tu factura el pago de{' '}
          {formatFee(finalPayment)}), o devolvérnoslo, sin ningún coste adicional.
        </Typography>
      )}

      <Typography
        {...TextStyles.paragraphDark({
          className: classes.paymentGroup,
        })}>
        <span className="bold">Comisión por financiación: </span> El pago a plazos está financiado
        por Xfera Consumer Finance, EFC S.A. y lleva asociado una comisión por financiación del{' '}
        {commissionFeePercentage}% ({formatFee(commissionFee)}), que se incluirá en tu primera
        factura. El importe total financiado es de {formatFee(monthlyTotalPayment + finalPayment)},
        con la comisión se queda en {formatFee(totalPayment)}
      </Typography>

      <div className={classes.paymentGroup}>
        <Typography {...TextStyles.paragraphDark()}>
          TIN: {tin}% TAE {tae}%
        </Typography>
      </div>

      <ModalActions>
        <Button onClick={onClose}>Entendido</Button>
      </ModalActions>
    </Modal>
  ) : null
}

FinancedPaymentDetailModal.propTypes = {
  name: PropTypes.string,
  financingConditionsTaxes: PropTypes.object,
  financedPaymentInfo: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
}
