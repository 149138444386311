export const FETCH_TARIFFS = 'OVID/FETCH_TARIFFS'
export const FETCH_MOBILE_LINE_TARIFFS = 'OVID/FETCH_MOBILE_LINE_TARIFFS'
export const FETCH_MOBILE_LINE_TARIFFS_SUCCESS = 'OVID/FETCH_MOBILE_LINE_TARIFFS_SUCCESS'
export const FETCH_MOBILE_LINE_TARIFFS_ERROR = 'OVID/FETCH_MOBILE_LINE_TARIFFS_ERROR'
export const FETCH_ADDITIONAL_TARIFFS = 'OVID/FETCH_ADDITIONAL_TARIFFS'
export const FETCH_ADDITIONAL_TARIFFS_SUCCESS = 'OVID/FETCH_ADDITIONAL_TARIFFS_SUCCESS'
export const FETCH_ADDITIONAL_TARIFFS_ERROR = 'OVID/FETCH_ADDITIONAL_TARIFFS_ERROR'
export const FETCH_MAIN_TARIFFS = 'OVID/FETCH_MAIN_TARIFFS'
export const FETCH_MAIN_TARIFFS_SUCCESS = 'OVID/FETCH_MAIN_TARIFFS_SUCCESS'
export const FETCH_MAIN_TARIFFS_ERROR = 'OVID/FETCH_MAIN_TARIFFS_ERROR'
export const FETCH_MEMBER_GET_MEMBER = 'OVID/FETCH_MEMBER_GET_MEMBER'
export const FETCH_MEMBER_GET_MEMBER_SUCCESS = 'OVID/FETCH_MEMBER_GET_MEMBER_SUCCESS'
export const FETCH_MEMBER_GET_MEMBER_ERROR = 'OVID/FETCH_MEMBER_GET_MEMBER_ERROR '

export const RESET_MAIN_TARIFFS = 'OVID/RESET_MAIN_TARIFFS'
export const RESET_ADDITIONAL_TARIFFS = 'OVID/RESET_ADDITIONAL_TARIFFS'
export const RESET_ALL_TARIFFS = 'OVID/RESET_ALL_TARIFFS'

export const fetchTariffsAction = payload => ({
  type: FETCH_TARIFFS,
  payload,
})

export const fetchMobileLineTariffsAction = payload => ({
  type: FETCH_MOBILE_LINE_TARIFFS,
  payload,
})

export const resetMainTariffsAction = () => ({
  type: RESET_MAIN_TARIFFS,
})

export const fetchMobileLineTariffsSuccessAction = payload => ({
  type: FETCH_MOBILE_LINE_TARIFFS_SUCCESS,
  payload,
})

export const fetchMobileLineTariffsErrorAction = payload => ({
  type: FETCH_MOBILE_LINE_TARIFFS_ERROR,
  payload,
})

export const fetchAdditionalLineTariffsAction = payload => ({
  type: FETCH_ADDITIONAL_TARIFFS,
  payload,
})

export const fetchAdditionalTariffsSuccessAction = payload => ({
  type: FETCH_ADDITIONAL_TARIFFS_SUCCESS,
  payload,
})

export const resetAdditionalTariffsAction = () => ({
  type: RESET_ADDITIONAL_TARIFFS,
})

export const resetAllTariffsAction = () => ({
  type: RESET_ALL_TARIFFS,
})

export const fetchAdditionalTariffsErrorAction = payload => ({
  type: FETCH_ADDITIONAL_TARIFFS_ERROR,
  payload,
})

export const fetchMainTariffsAction = payload => ({
  type: FETCH_MAIN_TARIFFS,
  payload,
})

export const fetchMainTariffsSuccessAction = payload => ({
  type: FETCH_MAIN_TARIFFS_SUCCESS,
  payload,
})

export const fetchMainTariffsErrorAction = payload => ({
  type: FETCH_MAIN_TARIFFS_ERROR,
  payload,
})

export const fetchMemberGetMemberAction = () => ({
  type: FETCH_MEMBER_GET_MEMBER,
})
export const fetchMemberGetMemberSuccessAction = payload => ({
  type: FETCH_MEMBER_GET_MEMBER_SUCCESS,
  payload,
})

export const fetchMemberGetMemberErrorAction = payload => ({
  type: FETCH_MEMBER_GET_MEMBER_ERROR,
  payload,
})
