import * as actions from './customer-write.actions'

const initialState = {
  loading: false,
  error: null,
  done: false,
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.CUSTOMER_WRITE_ATTEMPTED:
      return {
        ...initialState,
        loading: true,
      }
    case actions.CUSTOMER_WRITE_SUCCEEDED:
      return {
        ...state,
        loading: false,
        done: true,
      }
    case actions.CLEAR_CUSTOMER_WRITE_ERROR:
      return {
        ...initialState,
      }
    case actions.CUSTOMER_WRITE_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
        done: true,
      }
    default:
      return state
  }
}
