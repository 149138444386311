import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { isEmpty, head } from 'lodash'

import { landlineRegex } from 'utils'
import { SIGNUP_TYPE_NEWLINE } from 'services/global-constants'
import { NEW_NUMBER_ERROR } from 'modules/SharedSales/constants'
import { LINE_TYPE } from 'modules/Lines/constants'
import {
  findLandlineProvisioningNumber,
  findMobileProvisioningNumber,
  setLineReservation,
} from 'modules/Lines'
import { useIsCustomerPhoneValidation } from '../Validations/useIsCustomerPhone'

import { setRequestLoading } from '../../store'

export function useSignUpLine(
  newNumber,
  signUpType,
  isMobile,
  zipCode,
  onError,
  onSuccess,
  mobileOptions = [],
) {
  const dispatch = useDispatch()

  const defaultState = {
    msg: newNumber,
    options: mobileOptions,
    isLoading: false,
    isError: false,
  }

  const [state, setState] = useState(defaultState)
  const shouldSetLineReservation = res =>
    !!(isMobile && !isEmpty(res) && head(res) && res.length && head(res) !== newNumber)

  const provisioningNumberRequest = isMobile
    ? findMobileProvisioningNumber
    : findLandlineProvisioningNumber

  const isNewLine = signUpType === SIGNUP_TYPE_NEWLINE

  const { validateCustomer } = useIsCustomerPhoneValidation(LINE_TYPE.LANDLINE)

  async function getValidAndFreeLineNumber(lineNumber, attemps = 3) {
    let checkNewNumber = lineNumber

    const isCustomerNumberInvalid = await validateCustomer(checkNewNumber, landlineRegex, true)

    if (isCustomerNumberInvalid) {
      if (attemps === 0) {
        throw isCustomerNumberInvalid
      }

      checkNewNumber = await provisioningNumberRequest(zipCode)

      checkNewNumber = await getValidAndFreeLineNumber(checkNewNumber, attemps - 1)
    }

    return checkNewNumber
  }

  function setError() {
    setState({
      isError: true,
      error: NEW_NUMBER_ERROR,
      msg: 'Ha sucedido un error',
      options: [],
      isLoading: false,
    })
  }

  function setSuccess(res, isLoading) {
    const phoneNumber = head(res)
    setState({
      isError: isEmpty(res),
      error: '',
      msg: !isEmpty(res) ? phoneNumber : 'Ha sucedido un error',
      options: res,
      isLoading,
    })
  }

  async function onFetchProvisioningNumber(value, currentValue) {
    setState({ ...state, isLoading: true, error: '' })
    try {
      dispatch(setRequestLoading({ loading: true }))
      let provisionalNumber = await provisioningNumberRequest(isMobile ? value : zipCode)

      if (isEmpty(provisionalNumber)) {
        dispatch(setRequestLoading({ loading: false }))
        setError()
        onError({ response: { status: '404' } })
      } else {
        if (!isMobile) {
          provisionalNumber = await getValidAndFreeLineNumber(provisionalNumber)
        }

        const data = isMobile ? provisionalNumber : [provisionalNumber]
        const shouldReservation = shouldSetLineReservation(data)

        if (data && currentValue) {
          data.push(currentValue)
        }
        setSuccess(data, shouldReservation)

        if (!shouldReservation) {
          dispatch(setRequestLoading({ loading: false }))
          onSuccess(provisionalNumber)
        }
      }
    } catch (e) {
      dispatch(setRequestLoading({ loading: false }))
      setError()
      onError(e)
    }
  }

  function onChangeDesiredNumber(value, currentValue) {
    if (value.length === 4) {
      onFetchProvisioningNumber(value, currentValue)
    }
  }
  function onSelectNewNumber(value) {
    setState({ ...state, msg: value })
    onSuccess(value)
  }

  useEffect(() => {
    if (shouldSetLineReservation(state.options)) {
      const doReservation = async () => {
        try {
          await setLineReservation(state.msg)
          dispatch(setRequestLoading({ loading: false }))
          setState({ ...state, isLoading: false, error: '' })
          onSuccess(state.msg)
        } catch (e) {
          dispatch(setRequestLoading({ loading: false }))
          setError()
          onError(e)
        }
      }

      doReservation()
    }
  }, [state.msg])

  useEffect(() => {
    if (!newNumber && isNewLine) {
      onFetchProvisioningNumber()
    }
  }, [isNewLine])

  return {
    onChangeDesiredNumber,
    onFetchProvisioningNumber,
    onSelectNewNumber,
    ...state,
    msg: !state.isLoading ? state.msg : '',
  }
}

useSignUpLine.defaultProps = {
  onSuccess: () => {},
  onError: () => {},
}
