import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'
import { AlertAdvice } from 'components/ui'

export const CrossSellWarnings = ({ list }) => {
  return (
    <Box maxWidth="540px">
      {list.isDuo && (
        <Box mb={4}>
          <AlertAdvice
            message={
              <>
                CONFLICTO: Esta línea comparte sus MB con el resto de líneas cabeceras y
                adicionales. <br />
                Al cambiar a esta tarifa dejará de compartir sus MB.
              </>
            }
          />
        </Box>
      )}
      {list.hasDuo && (
        <Box mb={4}>
          <AlertAdvice
            message={
              <>
                Aviso: Tus líneas DÚO pasarán a ser líneas DÚO de la nueva tarifa convergente
                contratada.
              </>
            }
          />
        </Box>
      )}

      {list.hasAdditionalLines && (
        <Box mb={4}>
          <AlertAdvice
            message={
              <>
                Aviso: Si su línea adicional tiene más datos contratados que la línea cabecera que
                va a contratar, perderá el descuento del 50%
              </>
            }
          />
        </Box>
      )}

      {list.isDuo && list.hasDuoInterminable && (
        <Box mb={4}>
          <AlertAdvice
            message={
              <>Aviso: Su línea DÚO interminable pasará a ser línea DÚO principal y costará 0€</>
            }
          />
        </Box>
      )}
    </Box>
  )
}

CrossSellWarnings.propTypes = {
  list: PropTypes.object,
}
