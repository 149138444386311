import { ContainerTitle, CardRow, CardRowLabel, CardRowValue } from 'components'

// eslint-disable-next-line react/prop-types
const ActualAddress = ({ data }) => (
  <>
    <ContainerTitle>DIRECCIÓN ACTUAL</ContainerTitle>
    <CardRow>
      <CardRowLabel>Dirección de la instalación: </CardRowLabel>
      <CardRowValue>{data}</CardRowValue>
    </CardRow>
  </>
)

export default ActualAddress
