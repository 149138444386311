import { APP_CONFIG } from 'services/app-config'
import { axiosJWT } from 'modules/axios'

const url = `${APP_CONFIG.sales_cluster}/v1`

export function fetchSVAs(params) {
  return axiosJWT({
    method: 'GET',
    url: `${url}/svas/agileTv`,
    params,
  }).then(resp => resp.data)
}

export function fetchFixedSVAs(params) {
  return axiosJWT({
    method: 'GET',
    url: `${url}/svas/fixedSva`,
    params,
  }).then(resp => resp.data)
}

export function fetchHomeGoSVAs(params) {
  return axiosJWT({
    method: 'GET',
    url: `${url}/svas/homeGo`,
    params,
  }).then(resp => resp.data)
}

export function fetchSVAExists(params) {
  return axiosJWT({
    method: 'GET',
    url: `${url}/customers/agile-tv/validateEmail`,
    params,
  }).then(resp => resp.data)
}

export function fetchSVABlackList(params) {
  return axiosJWT({
    method: 'GET',
    url: `${url}/customers/agile-tv/blacklist`,
    params,
  }).then(resp => resp.data)
  // .catch(e => e.response)
}

export function fetchRenewalSVAs(params) {
  return axiosJWT({
    method: 'GET',
    url: `${url}/svas/renewal/agileTv`,
    params,
  }).then(resp => resp.data)
}
