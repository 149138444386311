import PropTypes from 'prop-types'

import { Grid } from '@material-ui/core'

import { MonthlyCustomerConsumptionContainer } from 'modules/consumption/containers/MonthlyCustomerConsumptionContainer/index'

import { OwnerCard } from '../../components/OwnerCard'
import { ContactCard } from '../../components/ContactCard'
import { ClientAddresses } from '../../components/ClientAddresses'

import { ModifyContactPhoneModal } from '../ModifyContactPhoneModal'

export function ClientMain({
  className,
  isLoading,
  hasErrors,
  enableChangeOwner,
  ownerData,
  contactData,
  contactMenuActions,
  isModifyPhoneNumberModalOpen,
  setIsModifyPhoneNumberModalOpen,
  onModifyPhoneNumberSuccess,
  clientAddressData,
  gdprData,
  gdprConsentsAllowed,
}) {
  return (
    <Grid
      className={className}
      container
      spacing={3}
      style={{ width: 'calc(100% + 11px)', marginTop: '1px', padding: '10px 10px 24px 24px' }}>
      {ownerData && (
        <>
          <ModifyContactPhoneModal
            customer={{
              docType: ownerData.identification?.type,
              docNumber: ownerData.identification?.identificationId,
              isCompany: ownerData.isCompany,
            }}
            current={contactData.telephones}
            open={isModifyPhoneNumberModalOpen}
            onClose={() => setIsModifyPhoneNumberModalOpen(false)}
            onSuccess={onModifyPhoneNumberSuccess}
          />
        </>
      )}

      {!isLoading && !hasErrors && (
        <>
          <Grid item xs={4}>
            {ownerData && (
              <OwnerCard
                data={ownerData}
                enableChangeOwner={enableChangeOwner}
                gdprData={gdprConsentsAllowed && !ownerData.isCompany ? gdprData : null}
              />
            )}
          </Grid>
          <Grid item xs={4}>
            {contactData && <ContactCard data={contactData} menuActions={contactMenuActions} />}
          </Grid>
          <Grid item xs={4}>
            <MonthlyCustomerConsumptionContainer />
          </Grid>
          <Grid item xs={12} style={{ height: 'fit-content' }}>
            {clientAddressData && <ClientAddresses data={clientAddressData} />}
          </Grid>
        </>
      )}
    </Grid>
  )
}

ClientMain.propTypes = {
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  hasErrors: PropTypes.bool,
  ownerData: PropTypes.object,
  enableChangeOwner: PropTypes.bool,
  contactData: PropTypes.object,
  contactMenuActions: PropTypes.array,
  isModifyPhoneNumberModalOpen: PropTypes.bool,
  setIsModifyPhoneNumberModalOpen: PropTypes.func.isRequired,
  onModifyPhoneNumberSuccess: PropTypes.func,
  clientAddressData: PropTypes.object,
  gdprData: PropTypes.object,
  gdprConsentsAllowed: PropTypes.bool,
}
