import { memo } from 'react'
import PropTypes from 'prop-types'

import { Card } from 'modules/ui'

export const IframeCard = memo(
  ({ id, title, loading, error, empty, url, width, height, className, onIframeLoad, children }) => (
    <Card title={title} data-hook={id} withShadow loading={loading} error={error} empty={empty}>
      {!empty && (
        <div className={`p-l-24 p-r-24 ${className}`}>
          <iframe
            id={`${id}-iframe`}
            title={`${title} Iframe`}
            src={`${url}&target=_self`}
            width={width}
            height={height}
            className={className}
            onLoad={onIframeLoad}
            seamless
          />
        </div>
      )}

      {children}
    </Card>
  ),
)

IframeCard.defaultProps = {
  title: '',
  className: '',
  width: '100%',
  height: '600px',
  onIframeLoad: () => {},
  empty: false,
}

IframeCard.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  loading: PropTypes.bool,
  error: PropTypes.bool,
  empty: PropTypes.bool,
  url: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  className: PropTypes.string,
  onIframeLoad: PropTypes.func,
  children: PropTypes.node,
}
