import { createStructuredSelector, createSelector } from 'reselect'

import { selectQueryParam, selectURLSearchParams } from 'modules/Router/store/router.selectors'
import { selectAccessToken } from 'modules/Auth/store/selectors'

import { ENERGY_REDIRECT_URI_PARAM } from '../../contants'

const selectRedirectUri = createSelector(
  [selectQueryParam(ENERGY_REDIRECT_URI_PARAM)],
  redirectUriParam => decodeURIComponent(redirectUriParam),
)

export const energyRedirectSelectors = createStructuredSelector({
  redirectUri: selectRedirectUri,
  accessToken: selectAccessToken,
  urlParams: selectURLSearchParams,
})
