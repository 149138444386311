import { Box } from '@material-ui/core'

import { SimpleLayout } from 'components'
import { Button } from 'components/ui'

function Home() {
  const home = (
    <Box
      component="section"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      height="100vh"
      maxWidth="500px"
      width="90vw"
      className="homecontainer">
      <Button to="/clients" data-hook="clients" variant="contained" color="primary">
        CARE
      </Button>
      <Button to="/choose-channel" data-hook="newClient" variant="contained" color="primary">
        SALES
      </Button>
    </Box>
  )
  return <SimpleLayout title="Agent Dashboard" main={home} />
}

export default Home
