import PropTypes from 'prop-types'

import { LineItemCard } from 'components/ui'
import { Card } from 'modules/ui'

export function ClientAddresses({ data }) {
  const { billingAddressStr, deliveryAddressStr } = data
  return (
    <Card title="Direcciones guardadas" id="Direcciones guardadas" withShadow>
      <LineItemCard primaryText="Dirección 1" secondaryText={billingAddressStr} />
      <LineItemCard primaryText="Dirección 2" secondaryText={deliveryAddressStr} />
    </Card>
  )
}

ClientAddresses.propTypes = {
  data: PropTypes.object,
}
