import { axiosJWT } from 'modules/axios'
import { get } from 'lodash'
import { CONFIG } from './environments'

export function findEnrichment() {
  return axiosJWT({
    method: 'GET',
    url: CONFIG.api.enrichment,
  }).then(res => {
    const filterEnrichments = get(res, 'data')
    return filterEnrichments
  })
}
