import React from 'react'
import PropTypes from 'prop-types'

import { Box, CircularProgress } from '@material-ui/core'
import { Button } from 'components/ui'

import LabelWithInfo from 'components/packages/ui/forms/LabelWithInfo'

const InstallationAddress = ({
  handAddress,
  floorAddress,
  doorAddress,
  streetType,
  nameAddress,
  numberAddress,
  postalCode,
  province,
  stairAddress,
  state,
  prev,
  newNumber,
  errorNewNumber,
  loadingNewNumber,
  retryCallNewNumber,
}) => {
  const getStair = () => {
    switch (stairAddress) {
      case 'W':
        return 'Derecha'
      case 'X':
        return 'Centro'
      case 'V':
        return 'Izquierda'
      case 'Y':
        return 'Interior'
      case 'Z':
        return 'Exterior'
      default:
        return stairAddress
    }
  }
  return (
    <div>
      <Box display="flex">
        <LabelWithInfo
          label="Dirección:"
          value={`${streetType} ${nameAddress}, ${numberAddress}`}
        />
        <LabelWithInfo label="Escalera:" value={getStair()} isVisible={Boolean(stairAddress)} />
        <LabelWithInfo
          label="Planta:"
          value={`${floorAddress.replace(/^0+/, '')}${handAddress}`}
          isVisible={Boolean(floorAddress)}
        />
        <LabelWithInfo label="Puerta:" value={`${doorAddress}`} isVisible={Boolean(doorAddress)} />
      </Box>
      <Box display="flex">
        <LabelWithInfo label="Municipio:" value={state} />
        <LabelWithInfo label="CP:" value={postalCode} />
        <LabelWithInfo label="Provincia:" value={province} />
      </Box>
      {loadingNewNumber ? (
        <Box display="flex" mb="16px" mr="48px">
          <CircularProgress size="32px" color="secondary" />
        </Box>
      ) : (
        <>
          {newNumber && (
            <Box display="flex">
              <LabelWithInfo label="Nº asignado:" value={newNumber} />
              {errorNewNumber && (
                <Box mt="5px">
                  <Button text style={{ padding: 0, marginTop: 1 }} onClick={retryCallNewNumber}>
                    Reintentar
                  </Button>
                </Box>
              )}
            </Box>
          )}
        </>
      )}
      <Box display="flex">
        <Button onClick={prev} text style={{ margin: 0, padding: 0 }}>
          Editar
        </Button>
      </Box>
    </div>
  )
}
InstallationAddress.propTypes = {
  handAddress: PropTypes.string.isRequired,
  floorAddress: PropTypes.string.isRequired,
  doorAddress: PropTypes.string,
  streetType: PropTypes.string.isRequired,
  nameAddress: PropTypes.string.isRequired,
  numberAddress: PropTypes.string.isRequired,
  postalCode: PropTypes.string.isRequired,
  province: PropTypes.string.isRequired,
  stairAddress: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  prev: PropTypes.func.isRequired,
  newNumber: PropTypes.string,
  errorNewNumber: PropTypes.bool,
  loadingNewNumber: PropTypes.bool,
  retryCallNewNumber: PropTypes.func,
}

export default InstallationAddress
