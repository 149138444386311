import { call, put, take, takeEvery } from 'redux-saga/effects'
import { logError, formatErrorMessage } from 'services/logging'
import {
  fetchTariffsAction,
  FETCH_MOBILE_LINE_TARIFFS_SUCCESS,
  FETCH_MOBILE_LINE_TARIFFS_ERROR,
} from 'modules/tariffs/store'

import { fetchMultiSimAllowedSuccess, fetchMultiSimAllowedFailed } from './actions'
import { constants } from './constants'
import { fetchMultiSimAllowed } from './api'

function* fetchMultiSimAllowedSaga({ payload: { subscriptionId, channel, segment } }) {
  try {
    yield put(
      fetchTariffsAction({
        channel,
        segment,
        type: 'multisim_line',
      }),
    )

    const tariffResult = yield take([
      FETCH_MOBILE_LINE_TARIFFS_SUCCESS,
      FETCH_MOBILE_LINE_TARIFFS_ERROR,
    ])
    if (
      tariffResult.type === FETCH_MOBILE_LINE_TARIFFS_ERROR ||
      tariffResult.payload.length === 0
    ) {
      throw new Error('No tariffs found')
    }

    const [{ poId }] = tariffResult.payload

    const result = yield call(fetchMultiSimAllowed, subscriptionId, poId)

    if (!result) {
      throw new Error('Multisim eligibility failed')
    }

    if (result.status !== 'accepted') {
      yield put(fetchMultiSimAllowedFailed(result['reject-description']))

      return
    }

    yield put(fetchMultiSimAllowedSuccess())
  } catch (e) {
    yield call(logError, new Error(formatErrorMessage(e)))
    yield put(fetchMultiSimAllowedFailed())
  }
}

export function* watchMultiSimAllowedSaga() {
  yield takeEvery(constants.FETCH_MULTISIM_ALLOWED, fetchMultiSimAllowedSaga)
}
