import { constants } from './constants'

const initialState = {
  loading: false,
  error: null,
}

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case constants.LOAD_INIT: {
      return {
        ...initialState,
        loading: true,
      }
    }
    case constants.LOAD_SUCCEEDED: {
      return {
        ...state,
        loading: false,
      }
    }
    case constants.LOAD_FAILED: {
      return {
        ...state,
        error: payload.error,
        loading: false,
      }
    }
    default:
      return state
  }
}
