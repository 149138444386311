import * as Yup from 'yup/lib'

import { withFormik, yupToFormErrors } from 'formik'
import { SINGLE } from 'services/global-constants'

import { DEVICE_TYPE_SELECTION, DEVICE_OFFERS, DEVICE_SUMMARY, BILLING } from '../constants'
import {
  DeviceTypeSelectionSchema,
  DeviceOfferSchema,
  DeviceSummarySchema,
  BillingSchema,
} from '../validations'

export const withDeviceRenewalForms = Component =>
  withFormik({
    mapPropsToValues: props => props.basket,
    mapPropsToStatus: () => ({}),
    validate: async (
      values,
      { step, basket }, // these are the props passed to the wrapped component
    ) => {
      const paymentMethods = basket.device?.paymentMethods
      const paymentType =
        paymentMethods && Object.keys(paymentMethods).length > 0
          ? Object.keys(paymentMethods)[0]
          : null

      const validationSchema = {
        [DEVICE_TYPE_SELECTION]: DeviceTypeSelectionSchema,
        [DEVICE_OFFERS]: DeviceOfferSchema,
        [DEVICE_SUMMARY]: DeviceSummarySchema,
        [BILLING]: BillingSchema,
      }

      const context = {
        isFinancedDevice: paymentType && paymentType !== SINGLE,
      }

      let errors = {}

      await Yup.object()
        .shape({
          ...validationSchema[step],
        })
        .validate(values, { abortEarly: false, context })
        .catch(err => {
          errors = yupToFormErrors(err)
        })

      return errors
    },
  })(({ step }) => <Component step={step} />)
