import { ClientMainContainer } from '../../containers/ClientMainContainer'
import { BillHistoric } from '../../components/BillHistoric'
import { CustomerActivity } from '../CustomerActivity/CustomerActivity'
// import { OrderTickets } from '../OrderTickets'
import { ContractsOrdersContainer } from '../../containers/ContractsOrdersContainer'
import { ClientServices } from '../ClientServices/ClientServices'

export const tabs = {
  MAIN: 'main',
  CONTRACTS: 'contracts',
  // TICKETS: 'tickets',
  REGISTER: 'register',
  BILLINGS: 'billings',
  SERVICES: 'services',
}

export const tabsConfig = [
  {
    label: 'Datos personales',
    value: tabs.MAIN,
    component: ClientMainContainer,
  },
  {
    label: 'Pedidos',
    value: tabs.CONTRACTS,
    component: ContractsOrdersContainer,
  },
  // {
  //   label: 'Tickets',
  //   value: tabs.TICKETS,
  //   component: OrderTickets,
  // },
  {
    label: 'Facturación',
    value: tabs.BILLINGS,
    component: BillHistoric,
  },
  {
    label: 'Servicios',
    value: tabs.SERVICES,
    component: ClientServices,
  },
  {
    label: 'Registro',
    value: tabs.REGISTER,
    component: CustomerActivity,
  },
]
