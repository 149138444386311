import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { isEmpty, isUndefined } from 'lodash'
import { Checkbox, FormControlLabel, CircularProgress, Box, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'

import { COMPANY } from 'modules/SharedSales/constants'
import { CIF } from 'modules/CustomerDocument/constants'

import { fetchAddAgileTvAllowed } from 'modules/agile-tv/add-agiletv/store/add-agiletv.actions'
import { formatAddAgileTvValidationError } from 'modules/agile-tv/add-agiletv/helpers'
import { selectAppleTvFlag } from 'services/feature-flag/selectors'
import { ActionAdvice } from 'components/ui'
import { SVAsModal } from '../SVAsModalComponent'
import { SVAEmailInput } from './SVAEmailInput'
import { SVAAppleTv } from './SVAAppleTv'
import { fetchSvaAgileTv } from '../../store'
import { SVAsSelector } from './SVAsCheckbox.selectors'

export function SVAsCheckbox({
  onCheckSVA,
  mainTariffPsId,
  channel,
  segment,
  selectedSVA,
  fieldNames,
  onChangeSVAEmail,
  emailVal,
  isSvaFlag,
  is2p,
  isCrossSell,
  validateAgileTvEmail,
  documentType,
  documentId,
  salesSegment,
  companyCif,
  isSelfEmployed,
  isCartera,
}) {
  const {
    isLoadingAllowed,
    isAllowed,
    allowedError,
    isLoading,
    formatedSVAS,
    SVAList,
    agileTvSVA,
    standardStbSVA,
  } = useSelector(SVAsSelector)
  const appleTvEnabled = useSelector(selectAppleTvFlag)

  const canSellAppleTv = appleTvEnabled && agileTvSVA && !is2p && !isCrossSell

  const dispatch = useDispatch()

  const shouldCheckAgileTv = isCrossSell || isCartera

  const checkAgileTvAllowed = () => {
    dispatch(
      fetchAddAgileTvAllowed({
        documentType: salesSegment === COMPANY ? CIF : documentType,
        documentId: salesSegment === COMPANY ? companyCif : documentId,
        segment,
        isSelfEmployed,
        channel,
      }),
    )
  }

  useEffect(() => {
    if (shouldCheckAgileTv) {
      checkAgileTvAllowed()
    }
  }, [])

  useEffect(() => {
    if (mainTariffPsId && (!shouldCheckAgileTv || (isAllowed && !isLoadingAllowed))) {
      dispatch(
        fetchSvaAgileTv({
          mainTariffPsId,
          channel,
          segment,
        }),
      )
    }
  }, [mainTariffPsId, isLoadingAllowed])

  function changeSelectedSVA(newSVA) {
    onCheckSVA(newSVA, false, true)
  }

  function swapSVAwithAppleTvSVA(isAppleTvSelected) {
    if (isAppleTvSelected) {
      changeSelectedSVA(agileTvSVA)
    } else {
      changeSelectedSVA(standardStbSVA)
    }
  }

  return (
    <>
      {(isLoading || isLoadingAllowed) && (
        <Box>
          <CircularProgress size="16px" />
        </Box>
      )}

      {!isLoading && !isLoadingAllowed && (
        <>
          {shouldCheckAgileTv && !isAllowed && (
            <ActionAdvice
              type={allowedError ? 'info' : 'error'}
              message={formatAddAgileTvValidationError(allowedError)}
              onRetry={!allowedError && checkAgileTvAllowed}
            />
          )}

          {(isAllowed || !shouldCheckAgileTv) &&
            (!isEmpty(formatedSVAS) ? (
              formatedSVAS.map(elem => {
                const stbSVA = SVAList.find(s => s.poId === elem.poId)
                const isSelected =
                  selectedSVA.poId === elem.poId ||
                  (!isUndefined(selectedSVA.psId) && selectedSVA.psId === elem.psId)
                return (
                  <div key={elem.poId}>
                    <Box display="flex" alignItems="center">
                      <FormControlLabel
                        data-hook={`landlineOffer.SVA.${elem.poId}`}
                        className="label"
                        control={
                          <Checkbox
                            data-hook="sva-checkbox"
                            color="primary"
                            className="checkbox"
                            checked={isSelected}
                            onChange={() => onCheckSVA(stbSVA, isSelected)}
                          />
                        }
                        label={`Añadir Televisión (${elem.name}):`}
                      />
                      <Box mr="8px">
                        <Typography variant="body1" data-hook="sva-price">
                          {elem.price.taxFreeAmount}€/mes{' '}
                          {elem.price.tax > 1 ? 'impuestos incluidos' : ''}
                        </Typography>
                      </Box>
                      <SVAsModal commercialInfo={elem.commercialInfoFormated} />
                    </Box>
                    {isSelected && isSvaFlag && (
                      <>
                        <Box>
                          <SVAEmailInput
                            fieldNames={fieldNames}
                            onChangeSVAEmail={onChangeSVAEmail}
                            emailVal={emailVal}
                            validateAgileTvEmail={validateAgileTvEmail}
                          />
                        </Box>
                        {canSellAppleTv && (
                          <SVAAppleTv
                            svaMainPsid={elem.psId}
                            channel={channel}
                            onAppleTvSelected={swapSVAwithAppleTvSVA}
                          />
                        )}
                      </>
                    )}
                  </div>
                )
              })
            ) : (
              <Box>
                <Typography variant="subtitle1">No hay SVA&apos;s disponibles</Typography>
              </Box>
            ))}
        </>
      )}
    </>
  )
}

SVAsCheckbox.propTypes = {
  onCheckSVA: PropTypes.func.isRequired,
  mainTariffPsId: PropTypes.string.isRequired,
  channel: PropTypes.string.isRequired,
  segment: PropTypes.string.isRequired,
  selectedSVA: PropTypes.object.isRequired,
  fieldNames: PropTypes.object.isRequired,
  onChangeSVAEmail: PropTypes.func.isRequired,
  validateAgileTvEmail: PropTypes.func.isRequired,
  emailVal: PropTypes.string,
  isSvaFlag: PropTypes.bool,
  is2p: PropTypes.bool,
  isCrossSell: PropTypes.bool,
  documentType: PropTypes.string,
  documentId: PropTypes.string,
  salesSegment: PropTypes.string,
  companyCif: PropTypes.string,
  isSelfEmployed: PropTypes.bool,
  isCartera: PropTypes.bool,
}
