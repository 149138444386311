import { useState } from 'react'
import PropTypes from 'prop-types'
import { get, isEmpty } from 'lodash'
import {
  Radio,
  CircularProgress,
  FormHelperText,
  FormControlLabel,
  Typography,
  Box,
  Divider,
  Card,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import { SEVERAL, SINGLE, QUOTA25 } from 'services/global-constants'

import { ButtonLink, FormikRadio } from 'components/ui'
import { CardTitle } from 'components'
import TerminalItemDescriptionComponent from './TerminalItemDescriptionComponent'
import { TerminalItemPaymentInfo } from './TerminalItemPaymentInfo'

const terminalStockErrorMessage = 'Error en la consulta de stock, vuelva a intentarlo'
const terminalDetailsErrorMessage = 'Ha sucedido un error al intentar recuperar el dispositivo'

const useStyles = makeStyles({
  'device-image': {
    background: props =>
      props.image ? `url(${props.image}) no-repeat center top / cover` : 'none',
    backgroundSize: 'cover',
    width: '120px',
    height: '220px',
    'mix-blend-mode': 'multiply',
    transform: 'translateY(-30px) translateZ(0)',
  },
  characteristics: {
    width: '280px',
  },
  characteristic: {
    fontSize: '14px',
  },
  'low-stock': {
    fontSize: '12px',
    color: 'red',
  },
})

export function TerminalItemComponent({
  paymentType,
  fieldNames,
  reservationError,
  onPaymentTypeChange,
  terminalDetails,
  isFinancialsLoading,
  disableOfferEdit,
  isPosSale,
  signUpType,
}) {
  const classes = useStyles({ image: get(terminalDetails, 'image') })

  const [isShowingDescription, setShowDescription] = useState(false)
  const isStockVisible =
    terminalDetails.stock && (terminalDetails.stock > 0 && terminalDetails.stock < 11)
  const hasSinglePayment = !!get(terminalDetails, 'paymentMethods.upfront')

  const paymentMethods = get(terminalDetails, 'paymentMethods')

  return (
    <>
      {terminalDetails.isLoading && !terminalDetails.isError && <CircularProgress />}
      {!terminalDetails.isLoading &&
        !terminalDetails.isError &&
        !!terminalDetails.id &&
        ((!!terminalDetails.stock && terminalDetails.stock > 0) || isPosSale) && (
          <Card
            elevation={2}
            style={{
              overflowY: 'visible',
              margin: '36px auto 12px',
            }}>
            <Box display="flex" flexDirection="row" alignItems="flex-start">
              <Box
                display="flex"
                minWidth="25%"
                flexDirection="row"
                alignItems="flex-start"
                alignSelf="stretch">
                <Box display="flex" width="100%" justifyContent="center">
                  <div data-hook="terminal.image" className={classes['device-image']} />
                </Box>
                <Divider orientation="vertical" height="100%" />
              </Box>
              <Box display="flex" flexDirection="column" alignItems="flex-start">
                <Box display="flex" flexDirection="row" padding="20px 20px 0px">
                  <CardTitle data-hook="terminal.name" className="m-r-20">
                    {get(terminalDetails, 'name')}
                  </CardTitle>
                  <ButtonLink
                    data-hook="description-button"
                    type="button"
                    className="m-b-20"
                    onClick={() => setShowDescription(!isShowingDescription)}>
                    {isShowingDescription ? 'Ocultar descripción' : 'Ver descripción'}
                  </ButtonLink>
                </Box>
                {isShowingDescription && (
                  <Box padding="0 20px 12px">
                    <div className="m-b-12">
                      <TerminalItemDescriptionComponent>
                        {/* eslint-disable react/no-danger */}
                        <p
                          data-hook="terminal.description"
                          dangerouslySetInnerHTML={{
                            __html: get(terminalDetails, 'longDescription'),
                          }}
                        />
                        {/* eslint-enable react/no-danger */}
                      </TerminalItemDescriptionComponent>
                    </div>
                    <Divider />
                  </Box>
                )}

                {reservationError && (
                  <Box display="flex" padding="0 20px 12px">
                    <FormHelperText error name={fieldNames.RESERVATION_ID}>
                      La reserva de dispositivo ha fallado. Vuelve a intentarlo o selecciona otro
                      modelo.
                    </FormHelperText>
                  </Box>
                )}

                {!isEmpty(signUpType) && (
                  <Box display="flex" flexDirection="row" padding="0px 20px 10px">
                    {!isEmpty(paymentMethods) ? (
                      <FormikRadio
                        name={fieldNames.TERMINAL_PAYMENT}
                        disabled={disableOfferEdit}
                        label="Método de pago"
                        row
                        onChange={onPaymentTypeChange}>
                        {get(paymentMethods, 'financing') && (
                          <FormControlLabel
                            value={SEVERAL}
                            control={<Radio className="radio-check" color="primary" />}
                            label="A plazos"
                          />
                        )}
                        {get(paymentMethods, 'quota25') && (
                          <FormControlLabel
                            value={QUOTA25}
                            control={<Radio className="radio-check" color="primary" />}
                            label="A plazos"
                          />
                        )}

                        {hasSinglePayment && (
                          <FormControlLabel
                            value={SINGLE}
                            control={<Radio className="radio-check" color="primary" />}
                            label="Único"
                          />
                        )}
                      </FormikRadio>
                    ) : (
                      <FormHelperText error name={fieldNames.TERMINAL_PAYMENT}>
                        No existen ofertas disponibles para este dispositivo para el canal, tipo de
                        línea
                      </FormHelperText>
                    )}
                  </Box>
                )}
                <Box display="flex" flexDirection="row" padding="0px 20px 20px" flexWrap="initial">
                  <div className={classes.characteristics}>
                    <Typography
                      variant="body2"
                      className={classes.characteristic}
                      data-hook="terminal.capacity">
                      <Typography variant="subtitle2" component="span" className="thin p-r-4">
                        Capacidad:
                      </Typography>

                      {get(terminalDetails, 'characteristics.capacity')}
                    </Typography>
                    <Typography
                      variant="body2"
                      className={classes.characteristic}
                      data-hook="terminal.screen">
                      <Typography variant="subtitle2" component="span" className=" thin p-r-4">
                        Pantalla:
                      </Typography>
                      {get(terminalDetails, 'characteristics.screen')}
                    </Typography>
                    {isStockVisible && (
                      <div className={classes['low-stock']}>
                        Solo quedan {terminalDetails.stock} unidades de este dispositivo. Avisa al
                        cliente para acelerar la venta{' '}
                      </div>
                    )}
                  </div>
                  {paymentType && (
                    <TerminalItemPaymentInfo
                      terminalDetails={terminalDetails}
                      hasSinglePayment={hasSinglePayment}
                      paymentType={paymentType}
                      isFinancialsLoading={isFinancialsLoading}
                      isFinanced={paymentType && paymentType !== SINGLE}
                    />
                  )}
                </Box>
              </Box>
            </Box>
          </Card>
        )}
      {terminalDetails.isError && (
        <FormHelperText error name={fieldNames.TERMINAL_PAYMENT} data-hook="terminal.details.error">
          {terminalDetails.isStockError ? terminalStockErrorMessage : terminalDetailsErrorMessage}
        </FormHelperText>
      )}
    </>
  )
}

TerminalItemComponent.propTypes = {
  onPaymentTypeChange: PropTypes.func.isRequired,
  reservationError: PropTypes.bool,
  paymentType: PropTypes.string,
  fieldNames: PropTypes.object,
  terminalDetails: PropTypes.object,
  isFinancialsLoading: PropTypes.bool,
  disableOfferEdit: PropTypes.bool,
  isPosSale: PropTypes.bool,
  signUpType: PropTypes.string,
}
