import { get } from 'lodash'

/* eslint-disable no-param-reassign, prefer-destructuring, max-len */
const setLinkState = changeTechnologyJson => {
  changeTechnologyJson.customer.products[0].productsSpecifications[0].productSpecCharacteristic.push(
    {
      name: 'linkstate',
      value: 'V',
    },
  )
}

export const fillChangeTechnologyOffer = (offer, changeTechnologyJson) => {
  const downloadSpeed = get(offer, 'fixed_line_download_speed')
  const uploadSpeed = get(offer, 'fixed_line_upload_speed')
  const lineType = get(offer, 'fixed_line_type')
  const newTariff = get(offer, 'id')
  const commercialName = get(offer, 'commercial_name')

  if (lineType === 'adsl') setLinkState(changeTechnologyJson)
  changeTechnologyJson.customer.products[0].productsSpecifications[0].productSpecCharacteristic[1].value = uploadSpeed
  changeTechnologyJson.customer.products[0].productsSpecifications[0].productSpecCharacteristic[2].value = downloadSpeed
  changeTechnologyJson.customer.products[0].productsSpecifications[0].productSpecCharacteristic[6].value = newTariff
  changeTechnologyJson.customer.products[0].productsSpecifications[0].productSpecCharacteristic[7].value = commercialName
  /* eslint-enable no-param-reassign, prefer-destructuring */
  return changeTechnologyJson
}
