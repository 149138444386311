import { createSelector } from 'reselect'
import { get } from 'lodash'

const getSmsCommunicationData = state => get(state, 'smsCommunication')

export const selectSmsCommunicationIsLoading = createSelector(
  [getSmsCommunicationData],
  data => data.loading,
)

export const selectSmsCommunicationIsSuccess = createSelector(
  [getSmsCommunicationData],
  data => data.success,
)

export const selectSmsCommunicationError = createSelector(
  [getSmsCommunicationData],
  data => data.error,
)
