export const installationsActionTypes = {
  FETCH_INSTALLATION: 'OVID/CARE/FETCH_INSTALLATION',
  FETCH_INSTALLATION_SUCCESS: 'OVID/CARE/FETCH_INSTALLATION_SUCCESS',
  FETCH_INSTALLATION_ERROR: 'OVID/CARE/FETCH_INSTALLATION_ERROR',
  RESET_INSTALLATION: 'OVID/CARE/RESET_INSTALLATION',
}

export const fetchInstallationDirection = payload => ({
  type: installationsActionTypes.FETCH_INSTALLATION,
  payload,
})

export const fetchInstallationDirectionSuccess = payload => ({
  type: installationsActionTypes.FETCH_INSTALLATION_SUCCESS,
  payload,
})

export const fetchInstallationDirectionError = payload => ({
  type: installationsActionTypes.FETCH_INSTALLATION_ERROR,
  payload,
})

export const resetInstallationDirection = () => ({
  type: installationsActionTypes.RESET_INSTALLATION,
})
