import PropTypes from 'prop-types'
import { ActionAdvice } from 'components/ui'
import { format, addMonths } from 'date-fns'
import { formatFee } from 'utils'
import { get, first } from 'lodash'

export const FATsExcedeed = ({ currentMonthFATs, totalFATDebtNextMonth }) => {
  const nextMonthFATFormat = format(addMonths(new Date(), 1), 'MM/yyyy')
  return (
    <div className="p-x-24 p-y-12">
      <ActionAdvice
        id={currentMonthFATs}
        message={`Quedan por descontar ${formatFee(totalFATDebtNextMonth)} de ${
          currentMonthFATs.length > 1
            ? 'varios FATs'
            : get(first(currentMonthFATs), 'air_time.air_time_group_id')
        }. Se restarán de la factura ${nextMonthFATFormat}`}
        block
      />
    </div>
  )
}
FATsExcedeed.propTypes = {
  totalFATDebtNextMonth: PropTypes.number,
  currentMonthFATs: PropTypes.arrayOf(
    PropTypes.shape({
      meta: PropTypes.object,
      air_time: PropTypes.object,
      air_time_group: PropTypes.object,
    }),
  ),
}
