import PropTypes from 'prop-types'

import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import { Board } from '../Board'

import { ClientServicesCard } from './ClientServicesCard/ClientServicesCard'

const useStyles = makeStyles({
  'client-services': {
    position: 'relative',

    '& .card-content': {
      padding: 0,
    },
  },
})

export const ClientServices = ({ className }) => {
  const classes = useStyles()

  return (
    <Board className={[classes['client-services'], ...(className ? [className] : [])].join(' ')}>
      <Grid item xs={12}>
        <ClientServicesCard />
      </Grid>
    </Board>
  )
}

ClientServices.propTypes = {
  className: PropTypes.string,
}
