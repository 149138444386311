import { createSelector } from 'reselect'
import { get } from 'lodash'

const getLogisticOrders = state => get(state, 'logisticOrders', {})

const selectLogisticByOrderId = createSelector(
  getLogisticOrders,
  (_, orderId) => orderId,
  (logisticOrders, orderId) => logisticOrders[orderId] || {},
)

const selectLogisticDataByOrderId = createSelector(
  selectLogisticByOrderId,
  logisticOrder => get(logisticOrder, 'data.shippingExpeditions'),
)

const selectLogisticLoadingByOrderId = createSelector(
  selectLogisticByOrderId,
  logisticOrder => get(logisticOrder, 'loading', null),
)

const selectLogisticErrorByOrderId = createSelector(
  selectLogisticByOrderId,
  logisticOrder => logisticOrder.error,
)

export const selectors = {
  getLogisticOrders,
  selectLogisticByOrderId,
  selectLogisticDataByOrderId,
  selectLogisticLoadingByOrderId,
  selectLogisticErrorByOrderId,
}
