import PropTypes from 'prop-types'

import styled from 'styled-components'
import { Box, Icon } from '@material-ui/core'
import { ICONS } from 'utils/icons'
import { ButtonLink } from 'components/ui'
import { APP_CONFIG } from 'services/app-config'

const AppointmentBtnWrapper = styled(ButtonLink)`
  display: flex;
  flex-direction: row;
  align-items: center;
  & p {
    font-size: 1rem;
    font-family: Roboto;
    font-weight: 400;
  }
`
const goToUrl = () => {
  window.open(`${APP_CONFIG.installation_appointments}`, '_blank')
}

const ShowAppointmentLink = ({ openKairosModal, newCitation }) => {
  return (
    <Box display="flex" m="20px 0 0 0">
      <AppointmentBtnWrapper onClick={newCitation ? openKairosModal : goToUrl}>
        <Icon className="icon">{ICONS.calendar}</Icon>
        <p data-hook="asignar cita">Asignar cita al cliente</p>
      </AppointmentBtnWrapper>
    </Box>
  )
}

ShowAppointmentLink.propTypes = {
  openKairosModal: PropTypes.func,
  newCitation: PropTypes.bool,
}

export default ShowAppointmentLink
