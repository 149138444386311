import { get } from 'lodash'
import {
  formatYoigoStore,
  mapToInstallationMedium,
  mapToCarteraAddress,
  formatDueDate,
} from 'modules/NewClientSales/store/selectors/checkout.selectors.helpers'

import {
  FINANCIAL_CODE_ZEROS,
  FINANCIAL_CODE_XFERA,
} from 'modules/SharedSales/constants/financialCodes'

import { SINGLE, COMPANY } from 'services/global-constants'
import { OTHER_ADDRESS, YOIGO_STORE, PAPER } from 'modules/SharedSales/constants/index'

import {
  FEES_DECISION_KEEP_FINANCING,
  FINAL_PAYMENT_DECISION_FINANCING,
  Q25_UNIQUE_PAYMENT_PO_ID,
  Q25_FINANCING_PO_ID,
  Q25_NO_ACTION_PO_ID,
} from '../constants'

export const generateDeviceRootOrderItem = (
  paymentMethod,
  paymentType,
  financialData,
  financedLegalConsents,
  segment,
  reservationId,
) => {
  const { job, salary, company, dueDate } = financialData

  const CH_Financed_By = segment === COMPANY ? FINANCIAL_CODE_ZEROS : FINANCIAL_CODE_XFERA
  const terminalIsFinanced = paymentType !== SINGLE

  const inputtedCharacteristics = {
    Ch_Hard_Book: null,
    CH_Financed_By: paymentType === SINGLE ? undefined : CH_Financed_By,
    CH_Dni_Expiry_Date: terminalIsFinanced ? formatDueDate(dueDate) : undefined,
    CH_Monthly_Income: terminalIsFinanced ? salary?.replace('€', '').replace('.', '') : undefined,
    CH_Profession: terminalIsFinanced ? job?.toString() : undefined,
    CH_Employer: terminalIsFinanced ? company : undefined,
    CH_Bank_Consent_1: terminalIsFinanced ? financedLegalConsents.consent1 : undefined,
    CH_Bank_Consent_2: terminalIsFinanced ? financedLegalConsents.consent2 : undefined,
    CH_Bank_Consent_3: terminalIsFinanced ? financedLegalConsents.consent3 : undefined,
    hard_reservation: reservationId,
  }

  return {
    productOfferingId: paymentMethod.id,
    inputtedCharacteristics,
  }
}

export const generateDeviceShipping = ({ type, deliveryPoint, otherAddress }, customerAddress) => {
  let shipping = {
    medium: mapToCarteraAddress(customerAddress),
  }

  if (type === YOIGO_STORE.value) {
    shipping = formatYoigoStore(deliveryPoint)
  } else if (type === OTHER_ADDRESS.value) {
    shipping = mapToInstallationMedium(otherAddress)
  }

  return shipping
}

export const generateFinancingDecisionsChildOrder = (
  { amendPenalty, finalPaymentType, feesPaymentType },
  financedDeviceInfo,
  finalPaymentDiscountAmount,
  permanencyContractId,
  installmentContractId,
  isFinalPaymentDiscountApplicable,
) => {
  let financedDecisionsChildOrder

  if (financedDeviceInfo?.fee || financedDeviceInfo?.penalty) {
    let CH_Keep_Device_Installment
    let CH_Installment_Contract_ID
    let CH_Exempt_Device_Penalty
    let CH_Permanent_Contract_ID
    let CH_Q25_Discount
    let productOfferingId = Q25_NO_ACTION_PO_ID

    const finalPaymentDecisionHasBeenTaken = !!finalPaymentType
    const shouldApplyQ25Action =
      finalPaymentDecisionHasBeenTaken || isFinalPaymentDiscountApplicable

    if (shouldApplyQ25Action) {
      CH_Q25_Discount = isFinalPaymentDiscountApplicable
        ? finalPaymentDiscountAmount.toString()
        : '0'

      productOfferingId =
        finalPaymentType === FINAL_PAYMENT_DECISION_FINANCING
          ? Q25_FINANCING_PO_ID
          : Q25_UNIQUE_PAYMENT_PO_ID

      CH_Installment_Contract_ID = installmentContractId
      CH_Permanent_Contract_ID = permanencyContractId
    } else {
      if (financedDeviceInfo.fee) {
        CH_Installment_Contract_ID = installmentContractId || null
        CH_Keep_Device_Installment = (feesPaymentType === FEES_DECISION_KEEP_FINANCING).toString()
      }
      if (financedDeviceInfo.penalty) {
        CH_Permanent_Contract_ID = permanencyContractId || null
        CH_Exempt_Device_Penalty = amendPenalty.toString()
      }
    }

    financedDecisionsChildOrder = {
      inputtedCharacteristics: {
        CH_Installment_Contract_ID,
        CH_Permanent_Contract_ID,
        CH_Exempt_Device_Penalty,
        CH_Keep_Device_Installment,
        CH_Q25_Discount,
      },
      productOfferingId,
    }
  }

  return financedDecisionsChildOrder
}

export const generateBillingAccount = ibanInformation => {
  let billingAccount

  if (ibanInformation) {
    billingAccount = {
      attributes: {
        name: 'default',
        currency: 'EUR',
        characteristics: {
          iban: get(ibanInformation, 'iban'),
          bank_name: get(ibanInformation, 'name'),
        },
      },
      billingProfileAttributes: {
        bill_delivery_method: PAPER,
        payment_method: 'Direct Debit',
        billing_cycle: 1,
        billing_interval: { count: 1, interval: 'month' },
      },
    }
  }

  return billingAccount
}
