import { find, get } from 'lodash'
import { findFee } from 'services/subscriptions'
import { applyTax } from 'services/taxes'
import { getTariffName } from 'modules/tariffs/helpers/tariffs.helpers'
import { normalizePromo, applyDiscount } from '../helpers'

const getTariffFee = tariff => Number(tariff.monthly_fee || get(findFee(tariff), 'price.amount', 0))
const byDuration = (a, b) => a.duration - b.duration

const findDataAmount = promos =>
  promos.reduce(
    (dataAmount, { mobile_data_amount_mb }) => dataAmount || mobile_data_amount_mb,
    undefined,
  )

const findDiscount = promos =>
  promos.reduce(
    (discount, { value, unit }) => ({
      value: value || discount.value,
      unit: unit || discount.unit,
    }),
    { value: 0, unit: '' },
  )

const extractPromosData = (tariff, promos) => {
  const [promo] = promos
  if (!promo) return []
  const remainingPromos = promos.filter(({ duration }) => duration !== promo.duration)
  const mobile_data_amount_mb = findDataAmount(promos)
  const tariffFee = getTariffFee(tariff)
  const discount = findDiscount(promos)
  const monthlyFee = applyDiscount(tariffFee, discount)
  return [
    {
      id: tariff.productNumber,
      name: getTariffName(tariff),
      tariffFee,
      mobile_data_amount_mb,
      monthlyFee,
      duration: promo.duration,
    },
    ...extractPromosData(tariff, remainingPromos),
  ]
}

const getPromos = (bonuses, discounts) =>
  []
    .concat(get(bonuses, 0, []), get(discounts, 0, []))
    .map(normalizePromo)
    .sort(byDuration)

export const usePromosData = (subscriptions, modifications, tax) =>
  Object.keys(modifications).reduce((acc, id) => {
    const { confirmedTariff, confirmedBonuses = [], confirmedDiscounts = [] } = modifications[id]
    const subscription = find(subscriptions, { id })
    const tariff = confirmedTariff || subscription
    const promosData = extractPromosData(tariff, getPromos(confirmedBonuses, confirmedDiscounts))
    return [
      ...acc,
      ...promosData.map(promo => ({
        ...promo,
        id: subscription.id,
        tariffFee: promo.tariffFee && applyTax(tax, promo.tariffFee),
        monthlyFee: promo.monthlyFee && applyTax(tax, promo.monthlyFee),
      })),
    ]
  }, [])
