import PropTypes from 'prop-types'
import { get, isEmpty } from 'lodash'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { OutlinedDivider } from 'components/ui'

import { getMobileLineNumber, getFixedLineNumber, selectPortOutInfo } from 'services/subscriptions'

import { InfoValues } from '../ui/InfoValues'

export const PortOutInfoDetails = styled(({ className, subscription }) => {
  const mobileNumber = getMobileLineNumber(subscription)
  const fixedNumber = getFixedLineNumber(subscription)

  const mobilePortOut = useSelector(selectPortOutInfo(mobileNumber))
  const fixedPortOut = useSelector(selectPortOutInfo(fixedNumber))
  const hasPortOutInfo = mobilePortOut || fixedPortOut

  const portOuts = [
    { info: mobilePortOut, title: 'Información de Port Out de línea móvil' },
    { info: fixedPortOut, title: 'Información de Port Out de línea fija' },
  ].filter(ele => !isEmpty(ele.info))

  return hasPortOutInfo ? (
    <div className={`portout-info-details ${className}`}>
      {portOuts &&
        portOuts.map((portOut, i) => (
          <>
            <InfoValues
              key={portOut.info.Reference}
              title={portOut.title}
              values={[
                { label: 'Operador', value: portOut.info.ReceptorOperatorName },
                { label: 'Fecha Portabilidad', value: portOut.info.WindowDate },
                { label: 'Limite de Fecha', value: portOut.info.PncDate },
                { label: 'Codigo Referencia', value: portOut.info.Reference },
              ]}
            />
            {i + 1 < portOuts.length && <OutlinedDivider />}
          </>
        ))}
    </div>
  ) : null
})`
  background-color: ${props => get(props, 'theme.palette.global.gray_light')};
`

PortOutInfoDetails.propTypes = {
  className: PropTypes.string,
  subscription: PropTypes.object,
}
