import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { Button } from 'components/ui'
import { isNil } from 'lodash'

import { isLoading as isLoadingSubscriptions } from 'services/subscriptions'
import { isAppLoading } from 'services/global-selectors/helpers'
import { selectQueryParams } from 'modules/Router'
import { getMainSubscriptionTariffId } from 'services/customer-360/selectors'
import { selectProfileId } from 'modules/Permissions'
import { getTelesalesChannel } from 'modules/vista-360/helpers/channel.helper'
import { selectTariffSegment } from 'modules/tariffs/store-apigee'
import { TELESALES_REGEX, NEWTON } from 'services/global-constants/channels'
import { getSegmentCustomer } from 'modules/orders/store/orders.selectors'
import { selectSfid } from 'modules/Auth/store/selectors'

export function NewSaleButton() {
  let segment
  const history = useHistory()
  const location = useLocation()

  const subscriptionsLoading = useSelector(isLoadingSubscriptions)

  const agentSfid = useSelector(selectSfid)
  const agentProfile = useSelector(selectProfileId)
  const mainSubscriptionId = useSelector(getMainSubscriptionTariffId)
  const queryParams = useSelector(selectQueryParams)

  if (!isNil(mainSubscriptionId)) {
    segment = useSelector(selectTariffSegment(mainSubscriptionId))
  } else {
    segment = useSelector(getSegmentCustomer)
  }

  function nagivateToSales() {
    if (queryParams.channel === NEWTON) {
      history.push(
        `/add-client${location.search.replace(
          TELESALES_REGEX,
          getTelesalesChannel(agentProfile, segment, queryParams, agentSfid),
        )}`,
      )
    } else {
      history.push(`/add-client${location.search}`)
    }
  }

  return (
    <Button
      onClick={nagivateToSales}
      disabled={isAppLoading() || subscriptionsLoading}
      data-hook="new-sale-button">
      Nuevo cliente
    </Button>
  )
}
