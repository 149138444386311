import { constants } from './constants'

export const fetchMultiSimAllowed = (subscriptionId, channel, segment) => ({
  type: constants.FETCH_MULTISIM_ALLOWED,
  payload: {
    subscriptionId,
    channel,
    segment,
  },
})

export const fetchMultiSimAllowedSuccess = () => ({
  type: constants.FETCH_MULTISIM_ALLOWED_SUCCESS,
})

export const fetchMultiSimAllowedFailed = error => ({
  type: constants.FETCH_MULTISIM_ALLOWED_FAILED,
  payload: {
    error,
  },
})

export const resetMultiSimAllowed = () => ({
  type: constants.RESET_MULTISIM_ALLOWED,
})
