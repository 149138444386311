import {
  SEND_TRANSACTION_COMPLETE,
  PAGE_LOADED,
  SEND_TRANSACTION_ERROR,
} from './analytics.constants'

export function sendTransactionComplete(payload) {
  return {
    type: SEND_TRANSACTION_COMPLETE,
    payload,
  }
}
export function pageLoadedAction() {
  return {
    type: PAGE_LOADED,
  }
}

export function sendTransactionError(payload) {
  return {
    type: SEND_TRANSACTION_ERROR,
    payload,
  }
}
