import { call, put, takeEvery, select } from 'redux-saga/effects'
import { logError, formatErrorMessage } from 'services/logging'
import { axiosFactory } from 'modules/axios'
import { selectAccountId } from 'modules/Auth'

import { FETCH_CUSTOMER } from './constants'
import { fetchCustomerError, fetchCustomerSuccess } from './action-creators'
import { fetchCustomerFromV3API } from './api'

function* saga() {
  try {
    const accountId = yield select(selectAccountId)
    const customer = yield call(fetchCustomerFromV3API, accountId)
    yield put(fetchCustomerSuccess(customer))
  } catch (e) {
    yield call(logError, new Error(formatErrorMessage(e)))
    const error = axiosFactory.buildError(e)
    yield put(fetchCustomerError(error))
  }
}

export function* watchFetchCustomerSaga() {
  yield takeEvery(FETCH_CUSTOMER, saga)
}
