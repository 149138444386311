import { connect } from 'react-redux'
import { NewAppointment } from '../../components/NewAppointment'

// TODO: looks like token is not fetched
const mapStateToProps = state => ({
  token: state.appointments.appointmentToken,
})

export default connect(
  mapStateToProps,
  null,
)(NewAppointment)
