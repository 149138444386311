import { createSelector } from 'reselect'
import { get } from 'lodash'

const selectTariffs = state => get(state, 'tariffs')

export const selectMainMobileTariffs = createSelector(
  selectTariffs,
  tariffs => get(tariffs, 'mobileLineTariffs.data'),
)

export const selectMainMobileTariffsIsLoading = createSelector(
  selectTariffs,
  tariffs => get(tariffs, 'mobileLineTariffs.loading'),
)

export const selectMainLineTariffs = createSelector(
  selectTariffs,
  tariffs => get(tariffs, 'mainLineTariffs.data'),
)

export const selectIsLoadingMainLineTariffs = createSelector(
  selectTariffs,
  tariffs => get(tariffs, 'mainLineTariffs.loading'),
)

export const selectMainLineTariffsError = createSelector(
  selectTariffs,
  tariffs => get(tariffs, 'mainLineTariffs.error'),
)

export const selectMainMobileTariffsError = createSelector(
  selectTariffs,
  tariffs => get(tariffs, 'mobileLineTariffs.error'),
)

export const selectAdditionalMobileTariffs = createSelector(
  selectTariffs,
  tariffs => get(tariffs, 'additionalTariffs.data'),
)

export const selectAdditionalMobileTariffsIsLoading = createSelector(
  selectTariffs,
  tariffs => get(tariffs, 'additionalTariffs.loading'),
)

export const selectAdditionalMobileTariffsError = createSelector(
  selectTariffs,
  tariffs => get(tariffs, 'additionalTariffs.error'),
)

export const selectMemberGetMemberPromo = createSelector(
  selectTariffs,
  tariffs => get(tariffs, 'memberGetMemberPromo'),
)

export const selectMemberGetMemberPromoInfo = createSelector(
  selectMemberGetMemberPromo,
  memberGetMemberPromo => ({
    isLoading: memberGetMemberPromo.loading,
    memberGetMemberPoId: memberGetMemberPromo.data?.poId,
    isAvailable: !memberGetMemberPromo.error,
  }),
)
