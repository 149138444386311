import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { get } from 'lodash'
import { ActionAdvice } from 'components/ui'

import { findFixedProduct, getMsisdn } from 'services/subscriptions'

import { updateSubscription } from '../../actions'
import { getNewTariff, getIsFixedBeingUnsubscribed } from '../../selectors'

export function UnsubscribeModified({ className, subscription }) {
  const newTariff = useSelector(getNewTariff(subscription))

  const allowUndoChanges = !newTariff

  const dispatch = useDispatch()
  const onUpdateSubscription = data => {
    dispatch(updateSubscription(getMsisdn(subscription), data))
  }

  const fixedLineId = get(findFixedProduct(subscription), 'id')

  const isFixedUnsubscribing = useSelector(getIsFixedBeingUnsubscribed(subscription))

  return isFixedUnsubscribing ? (
    <ActionAdvice
      id="unsubscribe-modified"
      className={className}
      type="info"
      onCancel={
        allowUndoChanges
          ? () => onUpdateSubscription({ confirmedTariff: null, date: null })
          : undefined
      }
      items={[
        { label: 'Nuevo Estado', value: `Baja de linea (Línea fija + Internet) ${fixedLineId}` },
      ]}
    />
  ) : null
}

UnsubscribeModified.propTypes = {
  className: PropTypes.string,
  subscription: PropTypes.object,
}
