import {
  FIND_ORDER_PRODUCTS_BY_IDS,
  FIND_ORDER_PRODUCTS_BY_IDS_SUCCESS,
  FIND_ORDER_PRODUCTS_BY_IDS_FAILED,
} from './constants'

export const findOrderProductsByIds = payload => ({
  type: FIND_ORDER_PRODUCTS_BY_IDS,
  payload,
})

export const findOrderProductsByIdsSuccess = payload => ({
  type: FIND_ORDER_PRODUCTS_BY_IDS_SUCCESS,
  payload,
})

export const findOrderProductsByIdsFailed = error => ({
  type: FIND_ORDER_PRODUCTS_BY_IDS_FAILED,
  payload: {
    error,
  },
})
