import { APP_CONFIG } from 'services/app-config'

function getSaleTypeButtons({
  isCartera,
  isTelesales,
  isPro,
  isPos,
  landline2pFlag,
  matchCarteraChannelAndCustomerSegment,
  isD2D,
  energyIsAllowed,
  canEnergySale,
  isCustomerCanceled,
  leadPosAllowed,
  isEnabledNewClientSale,
}) {
  return [
    {
      label: 'Convergente',
      value: 'convergent',
      icons: ['simCard', 'home'],
      disabled: (!isTelesales && !isPro) || !isEnabledNewClientSale,
    },
    {
      label: 'Sólo Móvil',
      value: 'only_mobile',
      icons: ['simCard'],
      disabled:
        (isCartera &&
          !isD2D &&
          !matchCarteraChannelAndCustomerSegment &&
          (!isCustomerCanceled || isPos)) ||
        !isEnabledNewClientSale,
    },
    {
      label: 'Fijo + Internet (2P)',
      value: '2p',
      icons: ['home'],
      disabled: !landline2pFlag || !isEnabledNewClientSale,
    },
    ...(!isCartera
      ? [
          {
            label: 'Energía',
            value: 'energy',
            icons: ['bulb'],
            disabled: !energyIsAllowed || !canEnergySale,
          },
        ]
      : []),
    ...(leadPosAllowed
      ? [
          {
            label: 'Leads',
            value: 'leads',
            icons: ['catching'],
          },
        ]
      : []),
  ]
}

function getMobileTypeButtons({
  mobileType,
  onlyMobileFlag,
  isTelesales,
  isPro,
  isEnabledNewClientSale,
}) {
  return [
    {
      label: 'Sólo Móvil',
      value: 'only_mobile',
      icons: ['simCard'],
      disabled: !onlyMobileFlag || (!isTelesales && !isPro) || !isEnabledNewClientSale,
      selected: mobileType === 'only_mobile' && isEnabledNewClientSale,
    },
    {
      label: 'El Fijo',
      value: 'mobile_fixed_line',
      icons: ['phone'],
      disabled: !isEnabledNewClientSale,
      selected: mobileType === 'fixed_mobile_line' && isEnabledNewClientSale,
    },
  ]
}

function getSegmentButtons({
  isAllSalesDisabled,
  isPro,
  isCarteraProChannel,
  isTelesales,
  isPos,
  isCustomerSegmentResidential,
  isCustomerSegmentPro,
  isD2D,
  isEnabledNewClientSale,
}) {
  return [
    {
      label: 'Residencial',
      value: 'residential',
      icons: ['client'],
      disabled:
        isAllSalesDisabled ||
        isPro ||
        isCarteraProChannel ||
        (isPos && isCustomerSegmentPro) ||
        !isEnabledNewClientSale,
      selected:
        !isAllSalesDisabled &&
        (isTelesales || (isPos && isCustomerSegmentResidential)) &&
        isEnabledNewClientSale,
    },
    {
      label: 'Negocios',
      value: 'pro',
      icons: ['work'],
      disabled:
        isAllSalesDisabled ||
        (!isPos && !isPro && !isD2D) ||
        (isPos && isCustomerSegmentResidential) ||
        !isEnabledNewClientSale,
      selected:
        !isAllSalesDisabled && (isPro || (isPos && isCustomerSegmentPro)) && isEnabledNewClientSale,
    },
  ]
}

function getIsAllButtonsDisabled(buttons) {
  return !buttons.some(button => !button.disabled)
}

function getEnableAllButtons(buttons) {
  const buttonsEnabled = {}
  Object.keys(buttons).forEach(buttonsTypes => {
    buttonsEnabled[buttonsTypes] = buttons[buttonsTypes].map(button => {
      return {
        ...button,
        disabled: false,
      }
    })
  })
  return buttonsEnabled
}

export function getFlowButtons(options) {
  const mobileTypeButtons = getMobileTypeButtons(options)
  const saleTypeButtons = getSaleTypeButtons(options)
  const isAllSalesDisabled = getIsAllButtonsDisabled(saleTypeButtons)
  const segmentButtons = getSegmentButtons({ isAllSalesDisabled, ...options })
  const buttons = {
    saleTypeButtons,
    mobileTypeButtons,
    segmentButtons,
  }
  if (!APP_CONFIG.production) {
    return getEnableAllButtons(buttons)
  }
  return buttons
}
