import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { get, head } from 'lodash'

import { Box, FormControlLabel, Checkbox, Typography } from '@material-ui/core'
import { FormikInput, OutlinedDivider, ModalActions, Button, SpinnerButton } from 'components/ui'

import { formatFee } from 'utils'

import { SVAsModal } from 'modules/SVAs/components/SVAsModalComponent'
import { useAgileTvValidation } from 'modules/NewClientSales/hooks/Validations'
import { HTMLTemplate } from 'modules/ui/components/HTMLTemplate/HTMLTemplate'

export const AgileTvModalForm = ({
  values,
  setFieldValue,
  handleSubmit,
  agileTvSva,
  legalText,
  showLegalText,
  penalty,
  emailValidationIsLoading,
  emailDisabled,
  onClose,
}) => {
  const { validateAgileTvEmail } = useAgileTvValidation()
  const { email, agileTv } = values

  const onCheckAgileTv = agileTvSelected => {
    setFieldValue('agileTv', agileTvSelected)
  }

  useEffect(() => {
    if (agileTvSva.length !== 0) {
      onCheckAgileTv(head(agileTvSva), 'poId')
    }
  }, [agileTvSva])

  useEffect(() => {
    if (!emailDisabled) {
      validateAgileTvEmail(email, 'email')
    }
  }, [emailDisabled])

  return (
    <>
      {agileTvSva.map(elem => {
        const isSelected = get(agileTv, 'poId') === get(elem, 'poId')
        return (
          <div key={elem.poId}>
            <Box mb="25px">
              <FormControlLabel
                control={<Checkbox checked={isSelected} onChange={() => onCheckAgileTv(elem)} />}
                label={
                  <>
                    {`${elem.commercialInfo.name} (${formatFee(
                      elem.price.taxFreeAmount,
                    )}/mes imp. incluido)`}

                    {elem.commercialInfoFormated && (
                      <SVAsModal commercialInfo={elem.commercialInfoFormated} />
                    )}
                  </>
                }
              />
            </Box>
          </div>
        )
      })}
      {
        showLegalText && 
          <> 
            <Box mt="20px">
              <Typography variant="body1" style={{ fontWeight: 300, marginBottom: '5px', fontSize: '14px' }}>
                <HTMLTemplate
                  data-hook="agile-tv-legal-text"
                  html={legalText}
                  values={{penalty}}
              />
              </Typography>
            </Box>
          </>
      }
     
      <OutlinedDivider />

      <Box mt="20px">
        <Typography variant="body1" style={{ fontWeight: 300, marginBottom: '5px' }}>
          Email para la subscripción:
        </Typography>

        <FormikInput
          name="email"
          fullWidth
          label="Email"
          validate={value => !emailDisabled && validateAgileTvEmail(value, 'email')}
          disabled={{ bool: emailDisabled }}
        />
      </Box>

      <ModalActions style={{ marginTop: '10px' }}>
        <Box display="flex" alignItems="center" pt="12px">
          {emailValidationIsLoading && <SpinnerButton disabled />}

          {!emailValidationIsLoading && (
            <Button type="submit" onClick={handleSubmit}>
              Continuar
            </Button>
          )}

          <Button
            margin="0 0 0 16px"
            secondary
            onClick={() => onClose()}
            data-hook="exit-add-agiletv">
            Cancelar
          </Button>
        </Box>
      </ModalActions>
    </>
  )
}

AgileTvModalForm.propTypes = {
  values: PropTypes.shape({
    email: PropTypes.string,
    agileTv: PropTypes.object,
  }).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  agileTvSva: PropTypes.array.isRequired,
  legalText: PropTypes.string,
  showLegalText: PropTypes.bool,
  penalty: PropTypes.string,
  emailValidationIsLoading: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  emailDisabled: PropTypes.bool,
}
