import { useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchContentfulResourceAction } from 'modules/contentful'
import { SEVERAL, QUOTA25 } from 'services/global-constants/index'
import { selectProfessionOptions } from '../../containers/OfferContainer/TerminalSelectionContainer/TerminalSelectionContainer.selectors'
import { findSelectedCompany } from '../../containers/OfferContainer/TerminalSelectionContainer/TerminalSelectionContainer.helpers'

export function useTerminalProffession(paymentType, jobId, setFieldValue, fieldName) {
  const dispatch = useDispatch()
  const [showCompany, setCompany] = useState(false)
  const professionOptions = useSelector(selectProfessionOptions)
  const fetchProffessions = useCallback(() => {
    dispatch(fetchContentfulResourceAction('profession'))
  })

  function onSelectProfession(value) {
    const { company, label } = findSelectedCompany(value, professionOptions)
    setCompany(!!company)
    if (!company) {
      setFieldValue(fieldName, label)
    } else {
      setFieldValue(fieldName, '')
    }
  }

  useEffect(() => {
    if (!professionOptions.length && [SEVERAL, QUOTA25].includes(paymentType)) {
      fetchProffessions()
    } else {
      const { company } = findSelectedCompany(jobId, professionOptions)
      setCompany(!!company)
    }
  }, [paymentType])

  useEffect(() => {
    if ([SEVERAL, QUOTA25].includes(paymentType) && jobId) {
      setCompany(true)
    }
  }, [])

  return { showCompany, onSelectProfession }
}
