import { Component } from 'react'
import { PropTypes } from 'prop-types'
import { isEqual } from 'lodash'
import { Grid, Button, Box, Divider } from '@material-ui/core'
import Close from '@material-ui/icons/Close'
import { getFullAddress } from 'services/product-orders/helpers'

import {
  Card,
  SectionTitle,
  SectionGrid,
  SectionTitleGrid,
  SectionValidationGrid,
  WizardNavigation,
} from 'components'

import { ClientOffers, ClientInfo, ClientValidation } from '..'

import { fillchangeTechnologyOrder } from './change-technology.helper'

class ChangeTechnologyOld extends Component {
  constructor(props) {
    super(props)

    this.state = {
      disabled: true,
      loadingData: true,
      adsl: null,
      currentStep: 0,
      maxSteps: 2,
      changeTechnologyJson: {},
    }
  }

  componentDidMount() {
    this.props.getSubscriptionData({
      phoneNumber: this.props.fixedNumber,
    })
  }

  shouldComponentUpdate = (nextProps, nextState) => {
    const { tariffs, loadingOrder, coverageLoading, tariffsLoading } = this.props
    const { loadingData, adsl, currentStep } = this.state
    if (tariffsLoading !== nextProps.tariffsLoading) {
      if (!nextProps.tariffsLoading) {
        return true
      }
    }
    if (loadingOrder !== nextProps.loadingOrder) {
      return true
    }

    if (coverageLoading !== nextProps.coverageLoading) {
      return true
    }
    if (isEqual(currentStep, nextState.currentStep)) {
      if (loadingData && nextState.loadingData) {
        if (!isEqual(tariffs, nextProps.tariffs)) {
          return true
        }
        return false
      }
      if (!isEqual(loadingData, nextState.loadingData) && !isEqual(adsl, nextState.adsl)) {
        return true
      }
      return false
    }
    return true
  }

  componentDidUpdate = () => {
    const { customer, coverageData, isAdslOrder, sfid, coverageError } = this.props
    const changeTechnologyJson = fillchangeTechnologyOrder(customer, sfid, coverageData)
    if (changeTechnologyJson !== null || changeTechnologyJson !== undefined) {
      this.setState({ changeTechnologyJson })
    }

    if (isAdslOrder) {
      this.setState({ loadingData: false, adsl: true, disabled: false })
    } else {
      this.setState({ loadingData: false, adsl: false })
    }

    if (coverageError) {
      this.setState({
        loadingData: false,
      })
    }
  }

  setStep = () => {
    this.setState({
      currentStep: 1,
    })
  }

  next = () => {
    const { currentStep, maxSteps } = this.state
    const nextStep = currentStep + 1 >= maxSteps ? maxSteps : currentStep + 1
    this.setState({
      currentStep: nextStep,
    })
  }

  previous = () => {
    const { currentStep } = this.state

    const nextStep = currentStep - 1 < 0 ? 0 : currentStep - 1
    this.setState({
      currentStep: nextStep,
    })
  }

  closeTab = () => {
    window.close()
  }

  render() {
    const { currentStep, disabled, maxSteps, changeTechnologyJson, adsl, loadingData } = this.state
    const {
      order,
      customerInfo,
      customer,
      tariffs,
      enrichment,
      coverage,
      coverageLoading,
      loadingOrder,
      tariffsError,
      tariffsLoading,
      productOrders,
    } = this.props
    let content

    switch (currentStep) {
      case 0:
        content = (
          <ClientValidation
            loading={loadingData}
            adsl={adsl}
            tariffsError={tariffsError}
            tariffsLoading={tariffsLoading}
            setStep={this.setStep}
            existCoverage={coverage === 'FTTH'}
            coverageLoading={coverageLoading}
          />
        )
        break
      case 1:
        content = (
          <ClientInfo
            customerInfo={getFullAddress(customerInfo)}
            customer={customer.customer}
            coverage={coverage !== 'FTTH'}
          />
        )
        break
      case 2:
        content = (
          <Grid container>
            <Grid item xs={12}>
              <ClientInfo
                customerInfo={getFullAddress(customerInfo)}
                customer={customer.customer}
                coverage={coverage !== 'FTTH'}
              />
            </Grid>
            <Box mt="15px" mb="30px">
              <Divider />
            </Box>
            <Grid item xs={12}>
              <ClientOffers
                newSubscription
                tariffs={tariffs}
                customer={customer.customer}
                changeTechnologyJson={changeTechnologyJson}
                sendOrder={this.props.sendOrder}
                orderId={order}
                loadingOrder={loadingOrder}
                enrichment={enrichment}
                loading={loadingData}
                customerInfo={customerInfo}
                productOrders={productOrders}
              />
            </Grid>
          </Grid>
        )
        break
      default:
        content = <ClientValidation loading={loadingData} adsl={adsl} setStep={this.setStep} />
    }

    if (currentStep !== 0) {
      return (
        <>
          <SectionTitleGrid item xs={12}>
            <SectionTitle width="auto">Cambio de Tecnología</SectionTitle>
            <Button color="default" onClick={this.closeTab}>
              cancelar y volver
              <Close />
            </Button>
          </SectionTitleGrid>
          <SectionGrid item xs={12}>
            <Card width="80%">{content}</Card>
          </SectionGrid>
          <SectionGrid item xs={3}>
            <WizardNavigation
              next={{
                callback: this.next,
                disabled: !!(disabled || currentStep === maxSteps),
                caption: 'Siguiente',
              }}
            />
          </SectionGrid>
        </>
      )
    }
    return (
      <>
        <SectionTitleGrid item xs={12}>
          <SectionTitle width="auto">Cambio de Tecnología</SectionTitle>
          <Button color="default" onClick={this.closeTab}>
            cancelar y volver
            <Close />
          </Button>
        </SectionTitleGrid>
        <SectionValidationGrid item xs={12}>
          {content}
        </SectionValidationGrid>
      </>
    )
  }
}

ChangeTechnologyOld.propTypes = {
  fixedNumber: PropTypes.string,
  getSubscriptionData: PropTypes.func,
  tariffs: PropTypes.arrayOf(PropTypes.object),
  customer: PropTypes.object,
  coverageData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  isAdslOrder: PropTypes.bool,
  order: PropTypes.any,
  customerInfo: PropTypes.object,
  enrichment: PropTypes.array,
  coverage: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  loadingOrder: PropTypes.bool,
  coverageLoading: PropTypes.bool,
  sendOrder: PropTypes.func,
  sfid: PropTypes.string,
  coverageError: PropTypes.string,
  tariffsLoading: PropTypes.bool,
  tariffsError: PropTypes.bool,
  productOrders: PropTypes.object,
}

export default ChangeTechnologyOld
