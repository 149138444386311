import PropTypes from 'prop-types'
import MaterialDialog from '@material-ui/core/Dialog'
import styled, { withTheme } from 'styled-components'
import { get } from 'lodash'

const Nav = styled.section`
  position: relative;
  display: flex;
  align-items: center;
  background-color: ${props =>
    get(props, 'theme.customComponentsStyle.Dialog.nav.backgroundColor', '#2e2e3a')};
  color: white;
  height: 40px;
  padding: 0 20px;
`

const Close = styled.a`
  position: absolute;
  right: 10px;
  font-size: 25px;
  cursor: pointer;
`

const Content = styled.section`
  min-width: 520px;
  padding: 20px;
`

function Dialog(props) {
  const { theme, children, title, closeBtn, alignment = 'left', ...materialProps } = props

  const onClickCloseBtnHandler = e => {
    e.preventDefault()
    if (props.onClose) props.onClose()
  }

  const DialogNav = (title || closeBtn) && (
    <Nav theme={theme}>
      {title} {closeBtn && <Close onClick={onClickCloseBtnHandler}>&times;</Close>}
    </Nav>
  )

  return (
    <MaterialDialog {...materialProps}>
      {DialogNav}
      <Content style={{ textAlign: alignment }}>{children}</Content>
    </MaterialDialog>
  )
}

Dialog.propTypes = {
  title: PropTypes.string,
  onClose: PropTypes.func,
  closeBtn: PropTypes.bool,
  children: PropTypes.node,
  theme: PropTypes.object,
  alignment: PropTypes.oneOf(['left', 'center', 'right']),
}

Dialog.defaultProps = {
  title: '',
  alignment: 'left',
}

export default withTheme(Dialog)
