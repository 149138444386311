import { useSelector } from 'react-redux'
import { selectIsSubscriptionQueryParam, selectIsMobileOnly } from 'modules/Router/store/index'
import { mapSegments, mapToCCChannel } from '../../helpers'
import { selectIsElFijo, selectSaleChannel } from '../../store/selectors/Basket.selectors'

export function useChannelAndSegment() {
  const isMobileOnly = useSelector(selectIsMobileOnly)
  const isElFijo = useSelector(selectIsElFijo)
  const isSubscription = useSelector(selectIsSubscriptionQueryParam)
  const channel = useSelector(selectSaleChannel)
  const mappedSegment = mapSegments(channel)
  const mappedChannel = mapToCCChannel(channel, isMobileOnly || isElFijo || !!isSubscription)

  return { channel: mappedChannel, segment: mappedSegment }
}
