import { APP_CONFIG } from 'services/app-config'

let config

if (APP_CONFIG.production) {
  config = [
    { name: 'address_change', type: 'boolean', value: 'true' },
    { name: 'ticketing', type: 'boolean', value: 'false' },
    { name: 'change_owner', type: 'boolean', value: 'false' },
    { name: 'neba_enabled', type: 'boolean', value: 'true' },
    { name: 'vista', type: 'boolean', value: 'true' },
    { name: 'installationsSales', type: 'boolean', value: 'true' },
    { name: 'company_appointment', type: 'boolean', value: 'false' },
    { name: 'only_mobile', type: 'boolean', value: 'true' },
    { name: 'landline_2p', type: 'boolean', value: 'true' },
    { name: 'installations360', type: 'boolean', value: 'true' },
    { name: 'sva_email', type: 'boolean', value: 'true' },
    { name: 'can_go_to_store', type: 'boolean', value: 'true' },
    { name: 'campaigns', type: 'boolean', value: 'true' },
    { name: 'cash_on_delivery', type: 'boolean', value: 'true' },
    { name: 'appleTv', type: 'boolean', value: 'true' },
    { name: 'download_contract', type: 'boolean', value: 'false' },
    { name: 'cross_sell', type: 'boolean', value: 'true' },
    { name: 'deferred_payment_menu', type: 'boolean', value: 'false' },
    { name: 'order_cancel_partial', type: 'boolean', value: 'true' },
    { name: 'retain_option', type: 'boolean', value: 'false' },
    { name: 'disable_discounts', type: 'boolean', value: 'true' },
    { name: 'device_renewal', type: 'boolean', value: 'false' },
    { name: 'device_renewal_iban_validation', type: 'boolean', value: 'false' },
    { name: 'device_renewal_agile_tv', type: 'boolean', value: 'false' },
    { name: 'energy', type: 'boolean', value: 'true' },
    { name: 'energy_for_islands', type: 'boolean', value: 'true' },
    { name: 'sva_smarthome', type: 'boolean', value: 'true' },
    { name: 'upsell_send_subscription_id', type: 'boolean', value: 'true' },
    { name: 'gdpr_consents', type: 'boolean', value: 'true' },
    { name: 'gdpr_client_consents', type: 'boolean', value: 'true' },
    { name: 'gdpr_client_campaign_consents', type: 'boolean', value: 'true' },
    { name: 'billing_differences', type: 'boolean', value: 'true' },
    { name: 'doctor_go_sva', type: 'boolean', value: 'true' },
    { name: 'hebe_sva_client', type: 'boolean', value: 'true' },
    { name: 'sva_homego', type: 'boolean', value: 'true' },
    { name: 'sva_homego_onfly', type: 'boolean', value: 'true' },
    { name: 'add_agiletv', type: 'boolean', value: 'true' },
    { name: 'presale', type: 'sfid_regex', value: '^AW|^BW|^GW|^KW|^MW' },
    { name: 'readonly_iban_on_cartera', type: 'boolean', value: 'true' },
    { name: 'financed_device_legal_consents', type: 'boolean', value: 'true' },
    { name: 'stc_v3', type: 'boolean', value: 'false' },
    { name: 'stc_v3_phase2', type: 'boolean', value: 'false' },
    { name: 'cancel_replaceable', type: 'boolean', value: 'false' },
    { name: 'd2r_from_ms', type: 'boolean', value: 'true' },
    { name: 'd2r_new_discounts', type: 'boolean', value: 'true' },
    { name: 'leads_pos', type: 'boolean', value: 'false' },
    { name: 'client_gdpr_update', type: 'boolean', value: 'true' },
    { name: 'internal_portability', type: 'boolean', value: 'true' },
    { name: 'sva_register', type: 'boolean', value: 'false' },
    { name: 'hide_hebe_sva', type: 'boolean', value: 'false' },
    { name: 'change_address_v1', type: 'boolean', value: 'false' },
    { name: 'multisim', type: 'boolean', value: 'false' },
    { name: 'coverage_v1', type: 'boolean', value: 'false' },
    { name: 'energy_email', type: 'boolean', value: 'false' },
    { name: 'commercial_migration', type: 'boolean', value: 'false' },
    { name: 'change_address_v3', type: 'boolean', value: 'false' },
    { name: 'new_citation', type: 'boolean', value: 'false' },
    { name: 'change_technology_v3', type: 'boolean', value: 'false' },
    { name: 'change_address_appointment', type: 'boolean', value: 'false' },
    { name: 'change_technology_v1', type: 'boolean', value: 'true' },
    { name: 'change_technology_appointment', type: 'boolean', value: 'false' },
    { name: 'kairos_sales', type: 'boolean', value: 'false' },
    { name: 'commercial_migration_appointment', type: 'boolean', value: 'false' },
    { name: 'fetch_customer_svas', type: 'boolean', value: 'false' },
    { name: 'change_agile_tv', type: 'boolean', value: 'false' },
    { name: 'change_address_indirect_fiber', type: 'boolean', value: 'false' },
    { name: 'iframe-tipis', type: 'boolean', value: 'false' },
    { name: 'doctor_go_unsubscribe', type: 'boolean', value: 'false' },
    { name: 'update_order_v1', type: 'boolean', value: 'false' },
    { name: 'enabled_alarms_ct_cd', type: 'boolean', value: 'true' },
    { name: 'enabled_btc_agile_tv', type: 'boolean', value: 'false' },
    { name: 'show_exit_button_iframe-tipis', type: 'boolean', value: 'false' },
    { name: 'new_netkia_url', type: 'boolean', value: 'false' },
    { name: 'new_netkia_customer_url', type: 'boolean', value: 'false' },
    { name: 'enabled_sms_tipis', type: 'boolean', value: 'false' },
    { name: 'enabled_tax_sva_agile_tv', type: 'boolean', value: 'false' },
    { name: 'enabled_button_insurance_ppi', type: 'boolean', value: 'true' },
    { name: 'enabled_logic_ip_address', type: 'boolean', value: 'false' },
    { name: 'friend_promo_sales', type: 'boolean', value: 'false' },
    { name: 'enabled_new_client_sale', type: 'boolean', value: 'false' },
    { name: 'other_address_client_only_mobile', type: 'boolean', value: 'false' },
    { name: 'show_migration_customer_message', type: 'boolean', value: 'false' },
    { name: 'enabled_prorrated_penalty', type: 'boolean', value: 'false' },
    { name: 'xsell_sale_campaign', type: 'boolean', value: 'false' },
    { name: 'enable_mascare_iframe_typification', type: 'boolean', value: 'false' },
    { name: 'enable_email_iframe_typification', type: 'boolean', value: 'false' },
    { name: 'enable_schedule_day_one', type: 'boolean', value: 'true' },
    { name: 'enable_fat', type: 'boolean', value: 'true' },
    { name: 'enable_onfly_discounts', type: 'boolean', value: 'true' },
    { name: 'enable_cvm_discounts', type: 'boolean', value: 'true' },
    { name: 'enable_period_fat', type: 'boolean', value: 'true' },
    { name: 'show_new_text_frozen_client', type: 'boolean', value: 'true' },
    { name: 'enable_schedule_tomorrow', type: 'boolean', value: 'true' },
  ]
} else {
  config = [
    { name: 'address_change', type: 'boolean', value: 'true' },
    { name: 'ticketing', type: 'boolean', value: 'true' },
    { name: 'change_owner', type: 'boolean', value: 'true' },
    { name: 'neba_enabled', type: 'boolean', value: 'true' },
    { name: 'vista', type: 'boolean', value: 'true' },
    { name: 'installationsSales', type: 'boolean', value: 'true' },
    { name: 'company_appointment', type: 'boolean', value: 'true' },
    { name: 'only_mobile', type: 'boolean', value: 'true' },
    { name: 'landline_2p', type: 'boolean', value: 'true' },
    { name: 'installations360', type: 'boolean', value: 'true' },
    { name: 'sva_email', type: 'boolean', value: 'true' },
    { name: 'can_go_to_store', type: 'boolean', value: 'true' },
    { name: 'campaigns', type: 'boolean', value: 'true' },
    { name: 'cash_on_delivery', type: 'boolean', value: 'true' },
    { name: 'appleTv', type: 'boolean', value: 'true' },
    { name: 'download_contract', type: 'boolean', value: 'false' },
    { name: 'cross_sell', type: 'boolean', value: 'true' },
    { name: 'deferred_payment_menu', type: 'boolean', value: 'true' },
    { name: 'disable_discounts', type: 'boolean', value: 'true' },
    { name: 'device_renewal', type: 'boolean', value: 'false' },
    { name: 'device_renewal_iban_validation', type: 'boolean', value: 'false' },
    { name: 'device_renewal_agile_tv', type: 'boolean', value: 'false' },
    { name: 'order_cancel_partial', type: 'boolean', value: 'true' },
    { name: 'retain_option', type: 'boolean', value: 'false' },
    { name: 'energy', type: 'boolean', value: 'true' },
    { name: 'energy_for_islands', type: 'boolean', value: 'true' },
    { name: 'sva_smarthome', type: 'boolean', value: 'true' },
    { name: 'upsell_send_subscription_id', type: 'boolean', value: 'true' },
    { name: 'gdpr_consents', type: 'boolean', value: 'true' },
    { name: 'gdpr_client_consents', type: 'boolean', value: 'true' },
    { name: 'gdpr_client_campaign_consents', type: 'boolean', value: 'true' },
    { name: 'billing_differences', type: 'boolean', value: 'true' },
    { name: 'doctor_go_sva', type: 'boolean', value: 'true' },
    { name: 'hebe_sva_client', type: 'boolean', value: 'true' },
    { name: 'sva_homego', type: 'boolean', value: 'true' },
    { name: 'sva_homego_onfly', type: 'boolean', value: 'true' },
    { name: 'add_agiletv', type: 'boolean', value: 'true' },
    { name: 'presale', type: 'sfid_regex', value: '^AW|^BW|^GW|^KW|^MW' },
    { name: 'readonly_iban_on_cartera', type: 'boolean', value: 'true' },
    { name: 'financed_device_legal_consents', type: 'boolean', value: 'true' },
    { name: 'stc_v3', type: 'boolean', value: 'true' },
    { name: 'stc_v3_phase2', type: 'boolean', value: 'true' },
    { name: 'cancel_replaceable', type: 'boolean', value: 'true' },
    { name: 'd2r_from_ms', type: 'boolean', value: 'true' },
    { name: 'd2r_new_discounts', type: 'boolean', value: 'true' },
    { name: 'leads_pos', type: 'boolean', value: 'true' },
    { name: 'client_gdpr_update', type: 'boolean', value: 'true' },
    { name: 'internal_portability', type: 'boolean', value: 'true' },
    { name: 'sva_register', type: 'boolean', value: 'true' },
    { name: 'hide_hebe_sva', type: 'boolean', value: 'true' },
    { name: 'change_address_v1', type: 'boolean', value: 'true' },
    { name: 'multisim', type: 'boolean', value: 'true' },
    { name: 'coverage_v1', type: 'boolean', value: 'true' },
    { name: 'energy_email', type: 'boolean', value: 'false' },
    { name: 'commercial_migration', type: 'boolean', value: 'true' },
    { name: 'change_address_v3', type: 'boolean', value: 'true' },
    { name: 'change_technology_v3', type: 'boolean', value: 'true' },
    { name: 'change_address_appointment', type: 'boolean', value: 'true' },
    { name: 'change_technology_v1', type: 'boolean', value: 'true' },
    { name: 'change_technology_appointment', type: 'boolean', value: 'true' },
    { name: 'new_citation', type: 'boolean', value: 'true' },
    { name: 'kairos_sales', type: 'boolean', value: 'true' },
    { name: 'commercial_migration_appointment', type: 'boolean', value: 'true' },
    { name: 'fetch_customer_svas', type: 'boolean', value: 'true' },
    { name: 'change_agile_tv', type: 'boolean', value: 'true' },
    { name: 'change_address_indirect_fiber', type: 'boolean', value: 'true' },
    { name: 'iframe-tipis', type: 'boolean', value: 'true' },
    { name: 'doctor_go_unsubscribe', type: 'boolean', value: 'false' },
    { name: 'update_order_v1', type: 'boolean', value: 'false' },
    { name: 'enabled_alarms_ct_cd', type: 'boolean', value: 'true' },
    { name: 'enabled_btc_agile_tv', type: 'boolean', value: 'true' },
    { name: 'show_exit_button_iframe-tipis', type: 'boolean', value: 'false' },
    { name: 'new_netkia_url', type: 'boolean', value: 'true' },
    { name: 'new_netkia_customer_url', type: 'boolean', value: 'true' },
    { name: 'enabled_sms_tipis', type: 'boolean', value: 'false' },
    { name: 'enabled_tax_sva_agile_tv', type: 'boolean', value: 'false' },
    { name: 'enabled_button_insurance_ppi', type: 'boolean', value: 'true' },
    { name: 'enabled_logic_ip_address', type: 'boolean', value: 'true' },
    { name: 'friend_promo_sales', type: 'boolean', value: 'false' },
    { name: 'enabled_new_client_sale', type: 'boolean', value: 'true' },
    { name: 'other_address_client_only_mobile', type: 'boolean', value: 'false' },
    { name: 'show_migration_customer_message', type: 'boolean', value: 'true' },
    { name: 'enabled_prorrated_penalty', type: 'boolean', value: 'false' },
    { name: 'xsell_sale_campaign', type: 'boolean', value: 'false' },
    { name: 'enable_mascare_iframe_typification', type: 'boolean', value: 'false' },
    { name: 'enable_email_iframe_typification', type: 'boolean', value: 'true' },
    { name: 'enable_schedule_day_one', type: 'boolean', value: 'true' },
    { name: 'enable_fat', type: 'boolean', value: 'true' },
    { name: 'enable_onfly_discounts', type: 'boolean', value: 'true' },
    { name: 'enable_cvm_discounts', type: 'boolean', value: 'true' },
    { name: 'enable_period_fat', type: 'boolean', value: 'true' },
    { name: 'show_new_text_frozen_client', type: 'boolean', value: 'true' },
    { name: 'enable_schedule_tomorrow', type: 'boolean', value: 'true' },
  ]
}
export const flags = config
