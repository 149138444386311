import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import {
  actions as subscriptionsActions,
  selectPortOutInfo,
  isPortOutInfoLoading,
  getMobileLineNumber,
  getFixedLineNumber,
} from 'services/subscriptions'

export const usePortOutInfo = subscription => {
  const mobileNumber = getMobileLineNumber(subscription)
  const fixedNumber = getFixedLineNumber(subscription)

  const dispatch = useDispatch()

  const mobilePortOut = useSelector(selectPortOutInfo(mobileNumber))
  const fixedPortOut = useSelector(selectPortOutInfo(fixedNumber))

  const isPortOutFixedLoading = useSelector(state => isPortOutInfoLoading(state, fixedNumber))
  const isPortOutMobileLoading = useSelector(state => isPortOutInfoLoading(state, mobileNumber))

  const isPortOutLoading = isPortOutFixedLoading || isPortOutMobileLoading

  const findPortOutInfo = msisdn => dispatch(subscriptionsActions.findPortOutInfo(msisdn))

  useEffect(() => {
    if (mobileNumber) {
      findPortOutInfo(mobileNumber)
    }

    if (fixedNumber) {
      findPortOutInfo(fixedNumber)
    }
  }, [])

  return {
    isPortOutLoading,
    mobilePortOut,
    fixedPortOut,
  }
}
