/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/no-cycle */
import { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Menu, MenuItem, Typography } from '@material-ui/core'

export const PopoverMenu = styled(
  ({ id = 'popover', menuActions, ClickableComp, dropdown, menuScroll, ...rest }) => {
    const [anchorEl, setAnchorEl] = useState(null)

    const anchorOrigin = {
      vertical: dropdown ? 'bottom' : 'center',
      horizontal: dropdown ? 'center' : 'left',
    }

    const transformOrigin = {
      vertical: dropdown ? 'top' : 'center',
      horizontal: dropdown ? 'center' : 'right',
    }

    function handleClick(event) {
      setAnchorEl(event.currentTarget)
    }

    function handleClose() {
      setAnchorEl(null)
    }

    function handleMenuItemClick(ev, action) {
      if (action) {
        action()
      }
      setAnchorEl(null)
    }

    return menuActions ? (
      <>
        <div data-hook={`menu-clickable-${id}`} onClick={ev => handleClick(ev)}>
          <ClickableComp />
        </div>
        <Menu
          data-hook={`menu-${id}`}
          classes={{ paper: 'paper' }}
          MenuListProps={{ disablePadding: true }}
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          disableAutoFocusItem
          elevation={0}
          getContentAnchorEl={null}
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
          {...rest}>
          {menuActions.map(menuAction => (
            <div key={menuAction.label} className="list-item">
              <MenuItem
                data-hook={`action-${menuAction.label}`}
                key={menuAction.label}
                onClick={event => handleMenuItemClick(event, menuAction.onClick)}
                disabled={menuAction.disabled}>
                <Typography
                  color="primary"
                  variant="subtitle2"
                  component="div"
                  className="menu-actions">
                  {menuAction.label}
                </Typography>
              </MenuItem>
              <div className="border" />
            </div>
          ))}
        </Menu>
      </>
    ) : null
  },
)`
  left: -20px;
  & > .paper {
    filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.5));
    overflow: visible;
    border-radius: 8px;
    margin-top: ${props => props.dropdown && '10px;'}
    overflow-y: ${props => props.menuScroll && 'auto;'}
  }
  & > .paper:after {
    content: '';
    position: absolute;
    z-index: 1000;
    top: 50%;
    margin-top: -10px;
    right: -14px;
    width: 0;
    height: 0;
    border-left: ${props => !props.dropdown && '7px solid transparent;'}
    border-right: ${props => !props.dropdown && '7px solid white;'}
    border-top: ${props => !props.dropdown && '7px solid white;'}
    border-bottom: ${props => !props.dropdown && '7px solid transparent;'}
    transform: rotate(45deg);
    transform-origin: top left;
  }
  & .list-item {
    display: flex;
    flex-direction: column;

    & .menu-actions {
      font-weight: 500;
      text-align: center;
      letter-spacing: 0.4px;
      text-transform: unset;
      display: flex;
      width: 100%;
      padding: 8px 24px 8px 24px;
      justify-content: center;
    }

    :not(:last-child) {
      & .border {
        margin: 0px 10px;
        border-bottom: 1px solid rgba(200, 200, 200, 0.3);
      }
    }
  }
`

PopoverMenu.propTypes = {
  ClickableComp: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  id: PropTypes.string,
  menuActions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func,
      disabled: PropTypes.bool,
    }),
  ),
}
