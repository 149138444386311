import { call, put, takeEvery, select, take } from 'redux-saga/effects'
import { logError, formatErrorMessage } from 'services/logging'
import { axiosFactory } from 'modules/axios'
import {
  validateIBANAction,
  validateIBANActionSuccess,
  validateIBANActionError,
  VALIDATE_IBAN,
  VALIDATE_IBAN_SUCCESS,
  VALIDATE_IBAN_ERROR,
} from '../actions'
import { fetchIBAN } from '../../services'
import { selectIBANErrorCode } from '../selectors'

export function* validateIBANSaga({ payload: { iban } }) {
  try {
    const ibanData = yield call(fetchIBAN, iban)

    yield put(validateIBANActionSuccess(ibanData))
  } catch (e) {
    yield call(logError, { e: new Error(formatErrorMessage(e)), iban })
    const error = axiosFactory.buildError(e)
    yield put(validateIBANActionError(error))
  }
}

export function* watchValidateIBAN() {
  yield takeEvery(VALIDATE_IBAN, validateIBANSaga)
}

export function* callSaga(iban) {
  yield put(validateIBANAction(iban))
  yield take([VALIDATE_IBAN_SUCCESS, VALIDATE_IBAN_ERROR])
  return yield select(selectIBANErrorCode, iban)
}
