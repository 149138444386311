import { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'

import { useSelector } from 'react-redux'
import { useFormikContext } from 'formik'
import { get } from 'lodash'

import { Line } from 'modules/Lines/components/LineComponent'
import { PostponePortabilityDate } from 'modules/Lines/components/PostponePortabilityDateComponent'
import { fieldNames, lineOptions } from 'modules/Lines/line.config'
import { LINE_TYPE } from 'modules/Lines/constants'
import { formatTariffName } from 'modules/tariffs/helpers'
import { POS, SIGNUP_TYPE_NEWLINE, SIGNUP_TYPE_PORTABILITY } from 'services/global-constants'
import { ErrorModal } from '../../../components/Common'
import { getFormikSelectors, useSignUpLine } from '../../../hooks/Lines'
import { useChannelAndSegment, useMainUser } from '../../../hooks/Shared'
import { onResetTerminal } from '../../../hooks/Terminals'
import { useMobileValidation } from '../../../hooks/Validations'
import { Terminal } from '../../../store/models'
import { TerminalSelectionContainer } from '../TerminalSelectionContainer'
import { storeSelectors } from './LineDataContainer.selectors'

export function LineDataContainer({
  lineType,
  selectedTariff,
  disableOfferEdit,
  isMobileOnly,
  isMultiSim,
  isLeadFilled,
  shouldDisableTerminal,
}) {
  const {
    tax,
    isCompany,
    isElFijo,
    saleType: { isPos },
  } = useSelector(storeSelectors)

  const { setFieldValue, values, status, setStatus } = useFormikContext()

  const zipCode = get(values, 'serviceAddress.otherAddress.zipCode')

  const lineFieldNames = fieldNames(lineType, isMobileOnly || isElFijo || isMultiSim)

  const formikLineValues = getFormikSelectors(lineFieldNames, setFieldValue, values)

  const { resetTerminalData } = onResetTerminal()

  const { channel, segment } = useChannelAndSegment()

  const mainUser = useMainUser()

  const tariffName = !lineType.includes('extra') ? formatTariffName(selectedTariff, tax) : ''

  const hasICCNumber = isMobileOnly || channel !== POS

  const { validate, error, hasMsError, fieldName, fieldValue } = useMobileValidation(lineType)

  const resetTerminalValue = useCallback(() => {
    setFieldValue(lineFieldNames.HAS_TERMINAL, shouldDisableTerminal ? 'no' : '')
    setFieldValue(lineFieldNames.TERMINAL, Terminal)
    resetTerminalData({ lineType })
  }, [lineType, shouldDisableTerminal])

  function onSignUpTypeChange(signUpType) {
    if (signUpType === formikLineValues.signUpType) return
    const isPortability = signUpType === SIGNUP_TYPE_PORTABILITY
    setFieldValue(`${lineType}.isPortability`, isPortability)
    setFieldValue(`${lineType}.isPortabilityFromFtth`, false)
    resetTerminalValue(lineType)
  }

  function onPortabilityTypeChange() {
    resetTerminalValue(lineType)
  }

  function onSearchButton(value) {
    setFieldValue(lineFieldNames.NEW_LINE_SEARCH, !value, false)
  }

  function onCheckPortaDate(e) {
    setFieldValue(lineFieldNames.POSTPONE_PORTABILITY, e.target.checked)
    setFieldValue(lineFieldNames.POSTPONE_PORTABILITY_NEW_DATE, null)
  }

  function onSetPortaDate(e) {
    setFieldValue(lineFieldNames.POSTPONE_PORTABILITY_NEW_DATE, e)
  }

  function onFetchProvisioningNumberError() {
    setFieldValue(lineFieldNames.NEW_NUMBER_OPTIONS, [])
  }

  function onFetchProvisioningNumberSuccess(res) {
    setFieldValue(lineFieldNames.NEW_NUMBER_VALUE, Array.isArray(res) ? res[0] : res)
    if (Array.isArray(res)) {
      setFieldValue(lineFieldNames.NEW_NUMBER_OPTIONS, res)
    }
  }

  function onFetchFixedProvisioningNumberSuccess(res) {
    setFieldValue(lineFieldNames.NEW_FIXED_NUMBER_VALUE, Array.isArray(res) ? res[0] : res)
  }

  useEffect(() => {
    if (formikLineValues.signUpType === SIGNUP_TYPE_PORTABILITY) {
      validate(formikLineValues.lineNumber, true)
    } else {
      setStatus({ ...status, [lineFieldNames.LINE_NUMBER]: '' })
    }
  }, [formikLineValues.signUpType])

  const signUpMobile = useSignUpLine(
    formikLineValues.newNumber,
    isElFijo ? SIGNUP_TYPE_NEWLINE : formikLineValues.signUpType,
    true,
    zipCode,
    onFetchProvisioningNumberError,
    onFetchProvisioningNumberSuccess,
    formikLineValues.mobileNumberOptions,
  )

  const signUpProps = useSignUpLine(
    formikLineValues?.newFixedNumber?.value,
    isElFijo && formikLineValues.signUpType,
    false,
    zipCode,
    () => {},
    onFetchFixedProvisioningNumberSuccess,
  )

  return (
    <>
      <Line
        {...formikLineValues}
        isPos={isPos}
        isElFijo={isElFijo}
        lineOptions={
          isMultiSim
            ? lineOptions.filter(lineOption => lineOption.value !== 'portability')
            : lineOptions
        }
        isMultiSim={!!isMultiSim}
        isCompany={isCompany}
        signUpMobile={isElFijo && signUpMobile}
        isMobile
        title={tariffName}
        lineType={LINE_TYPE.MAIN_MOBILE}
        tax={tax}
        onSignUpTypeChange={onSignUpTypeChange}
        onPortabilityTypeChange={onPortabilityTypeChange}
        selectedTariff={selectedTariff}
        fieldNames={lineFieldNames}
        channel={channel}
        segment={segment}
        onSearchButton={onSearchButton}
        onCheckIsPortability={validate}
        hasICCNumber={hasICCNumber}
        mainUser={mainUser}
        disableOfferEdit={disableOfferEdit}
        isMobileOnly={isMobileOnly}
        isLeadFilled={isLeadFilled}
        signUpProps={isElFijo ? signUpProps : signUpMobile}>
        {!isMultiSim && (
          <TerminalSelectionContainer
            channel={channel}
            shouldDisableTerminal={shouldDisableTerminal}
            isLeadFilled={isLeadFilled}
            fieldNames={lineFieldNames}
            // remove once we dont need to mock the request to terminals
            disableOfferEdit={disableOfferEdit}
          />
        )}

        {formikLineValues.signUpType === SIGNUP_TYPE_PORTABILITY &&
          !isCompany &&
          !isElFijo &&
          (!isMobileOnly || disableOfferEdit) && (
            <PostponePortabilityDate
              title={tariffName}
              lineType={LINE_TYPE.MAIN_MOBILE}
              tax={tax}
              selectedTariff={selectedTariff}
              fieldNames={lineFieldNames}
              channel={channel}
              segment={segment}
              isCompany={isCompany}
              onSetPortaDate={onSetPortaDate}
              onCheckPortaDate={onCheckPortaDate}
              {...formikLineValues}
            />
          )}
      </Line>
      <ErrorModal
        error={error}
        hasMsError={hasMsError}
        fieldName={fieldName}
        fieldValue={fieldValue}
      />
    </>
  )
}

LineDataContainer.propTypes = {
  className: PropTypes.string,
  lineType: PropTypes.string.isRequired,
  selectedTariff: PropTypes.object.isRequired,
  disableOfferEdit: PropTypes.bool,
  isMobileOnly: PropTypes.bool,
  isLeadFilled: PropTypes.bool,
  isMultiSim: PropTypes.bool,
  shouldDisableTerminal: PropTypes.bool,
}
