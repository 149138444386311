import PropTypes from 'prop-types'
import styled from 'styled-components'
import { get } from 'lodash'

const SummaryWrapper = styled.div`
  margin-bottom: 36px;
`

const SummaryTitle = styled.h2`
  margin: 0 8px 12px;
  font-size: 16px;
  font-weight: 600;
  color: ${props => get(props, 'theme.palette.global.gray_dark')};
`

const SummaryItem = styled.div`
  background: #fcfcfc;
  border-radius: 10px;
  padding: 16px 21px;
  color: ${props => get(props, 'theme.palette.global.gray')};
  font-size: 12px;
  font-weight: bold;
  span {
    display: block;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.14;
    color: ${props => get(props, 'theme.palette.global.gray_dark')};
  }
  p {
    margin: 0;
  }
  p + p {
    margin: 13px 0 0;
  }
`
const getPromoInterval = (summary, i) => {
  switch (true) {
    case i + 1 === summary.length:
      return 'final sin promoción'
    case i === 0:
      return `los ${summary[0].duration} primeros meses`
    default:
      return `del mes ${summary[i - 1].duration} al mes ${summary[i].duration}`
  }
}

const SubscriptionsSummary = props => (
  <SummaryWrapper>
    <SummaryTitle>Resumen de cuotas:</SummaryTitle>
    <SummaryItem>
      {!props.hideSummary &&
        props.summary.map((item, i) => (
          <p key={item.duration}>
            Cuota {getPromoInterval(props.summary, i)}
            <span>{item.monthlyFee} impuestos incluidos.</span>
          </p>
        ))}
    </SummaryItem>
  </SummaryWrapper>
)

SubscriptionsSummary.propTypes = {
  summary: PropTypes.array,
  hideSummary: PropTypes.bool,
}

export default SubscriptionsSummary
