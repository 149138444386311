import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'

import { ClientAndOrderInfo } from './ClientAndOrderInfo'
import { DeviceTypeAndChannelInfo } from './DeviceTypeAndChannelInfo'
import { DeviceInfo } from './DeviceInfo'
import { FinancedDeviceDecisionsInfo } from './FinancedDeviceDecisionsInfo'

const useStyles = makeStyles(theme => ({
  sidebar: {
    backgroundColor: theme.palette.brand.medium,
    height: '100%',
  },
}))

export const Sidebar = ({
  shouldShowClientInfo,
  shouldShowDeviceType,
  shouldShowDeviceInfo,
  shouldShowCurrentDevice,
  ...restProps
}) => {
  const classes = useStyles()

  const {
    clientFullName,
    msisdn,
    tariffDescription,
    tariffPrice,
    deviceType,
    saleChannel,
    device,
    financedDeviceDecisionsInfo,
  } = restProps

  return (
    <div className={classes.sidebar}>
      {shouldShowClientInfo && (
        <ClientAndOrderInfo
          clientFullName={clientFullName}
          msisdn={msisdn}
          tariffDescription={tariffDescription}
          tariffPrice={tariffPrice}
        />
      )}

      {shouldShowDeviceType && (
        <DeviceTypeAndChannelInfo deviceType={deviceType} saleChannel={saleChannel} />
      )}

      {shouldShowCurrentDevice && financedDeviceDecisionsInfo && (
        <FinancedDeviceDecisionsInfo decisionsInfo={financedDeviceDecisionsInfo} />
      )}

      {shouldShowDeviceInfo && <DeviceInfo device={device} />}
    </div>
  )
}

Sidebar.propTypes = {
  shouldShowClientInfo: PropTypes.bool,
  shouldShowDeviceInfo: PropTypes.bool,
  shouldShowDeviceType: PropTypes.bool,
  shouldShowCurrentDevice: PropTypes.bool,
}
