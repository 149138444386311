export const adslResponse = {
  gescal: '28000010132000003         002A       ',
  coverage: [
    {
      ull: ['20M'],
      ftth: [],
      characteristics: [
        { name: 'territoryOwner', value: 'MASMOVIL' },
        { name: 'speedEstimated', value: '13' },
        { name: 'telefonicaCoverage', value: 'false' },
      ],
    },
  ],
  phoneNumber: 'undefined',
  province: 'MADRID',
  town: 'Madrid',
  streetType: 'Calle',
  street: 'Bonetero',
  number: '3',
  bis_duplicate: '',
  zipCode: '28016',
  block: '',
  stair: '',
  door: '',
  letter: '',
  floor: '002',
  Hand: 'A',
  Km: '',
  addressId: '',
  sessionId: '7eca42e9-a97e-4813-a767-b2b592b3e109',
}
