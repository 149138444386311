import { get, isEmpty } from 'lodash'

import * as jsonGenerators from './jsonGenerators'
import CheckoutValidator from './CheckoutValidator'

class OrderGenerator {
  constructor(id) {
    if (!id) {
      throw new Error('Can not generate checkout without ids')
    }
    this.result = {
      id,
      type: 'order-items',
      attributes: {
        action: 'create',
        quantity: 1,
      },
      relationships: {},
    }
  }

  setRequestedStartDateTime({ requestedDate }) {
    if (!requestedDate) return
    this.result.attributes['requested-start-datetime'] = requestedDate
  }

  setRootOrderItem({ inputtedCharacteristics, productOfferingId, discounts }) {
    if (!this.result.relationships.order_product) {
      this.result.relationships.order_product = []
    }
    const product = jsonGenerators.generateOrderProduct({
      inputtedCharacteristics,
      productOfferingId,
      discounts,
    })
    this.result.relationships.order_product.push(product)
  }

  addInstallationMedium({ medium, type }, noDelivery) {
    if (noDelivery) return {}
    const po = get(
      this.result.relationships,
      'order_product[0].relationships.product_offering[0].id',
      '',
    )
    const isDelivery = !po.includes('MOD')

    if (!this.result.relationships.installation_medium && !isDelivery) {
      this.result.relationships.installation_medium = []
    }
    if (isDelivery && !this.result.relationships.delivery_medium) {
      this.result.relationships.delivery_medium = []
    }

    const contactMedia = jsonGenerators.generateContactMedia({
      role: isDelivery ? 'delivery' : 'installation',
      mediumType: type || 'postal-address',
      medium,
    })

    if (isDelivery) {
      this.result.relationships.delivery_medium.push(contactMedia)
    } else {
      this.result.relationships.installation_medium.push(contactMedia)
    }

    return contactMedia
  }

  addBillingAccount({ attributes, billingProfileAttributes }) {
    if (!this.result.relationships.billing_account) {
      this.result.relationships.billing_account = []
    }
    const billingAccount = jsonGenerators.generateBillingAccount({
      attributes,
      billingProfileAttributes,
    })
    this.result.relationships.billing_account.push(billingAccount)
    return billingAccount
  }

  createOrderChild(id) {
    if (!this.result.relationships.child_order_items) {
      this.result.relationships.child_order_items = []
    }
    const orderChild = new OrderGenerator(id)
    this.result.relationships.child_order_items.push(orderChild.result)
    return orderChild
  }

  createRelatedParty({ role, sub_role }) {
    if (!this.result.relationships.related_parties) {
      this.result.relationships.related_parties = []
    }
    // eslint-disable-next-line no-use-before-define
    const relatedParty = new PartyGenerator({ role, sub_role })
    this.result.relationships.related_parties.push(relatedParty.result)
    return relatedParty
  }
}

let individualPosition = 0
class IndividualsGenerator {
  constructor() {
    individualPosition += 1
    this.result = {
      id: `temp-id-indiviuals-${individualPosition}`,
      type: 'individuals',
      attributes: {},
      relationships: {},
    }
  }

  setAttributes({ attributes }) {
    this.result.attributes = attributes
  }

  addIdentification({ attributes }) {
    const identification = jsonGenerators.generateIdentification({ attributes })
    if (!this.result.relationships.identifications) {
      this.result.relationships.identifications = []
    }
    this.result.relationships.identifications.push(identification)
    return identification
  }

  addContactMedia({ role, medium, mediumType }) {
    const contactMedia = jsonGenerators.generateContactMedia({ role, medium, mediumType })
    if (!this.result.relationships.contact_media) {
      this.result.relationships.contact_media = []
    }
    this.result.relationships.contact_media.push(contactMedia)
    return contactMedia
  }

  addContactMediaObject(contactMedia) {
    if (!this.result.relationships.contact_media) {
      this.result.relationships.contact_media = []
    }
    this.result.relationships.contact_media.push(contactMedia)
    return contactMedia
  }

  addRelatedParties(related) {
    if (!this.result.relationships.related_parties) {
      this.result.relationships.related_parties = []
    }
    this.result.relationships.related_parties.push(related)
    return related
  }
}

let organizationPosition = 0

class OrganizationGenerator extends IndividualsGenerator {
  constructor() {
    super()
    organizationPosition += 1
    this.result = {
      id: `temp-id-organizations-${organizationPosition}`,
      type: 'organizations',
      attributes: {},
      relationships: {},
    }
  }

  createParty({ role, sub_role }) {
    if (!this.result.relationships.related_parties) {
      this.result.relationships.related_parties = []
    }
    // eslint-disable-next-line no-use-before-define
    const party = new PartyGenerator({ role, sub_role })
    this.result.relationships.related_parties.push(party.result)
    return party
  }
}

let terminalPaymentPosition = 0

class TerminalPaymentGenerator {
  constructor(
    { importe, numeroCifrado, mascara, fechaCaducidad, numOperacionNNP, codigo, numAutorizacion },
    idTarget,
  ) {
    terminalPaymentPosition += 1
    this.result = {
      id: `temp-id-organizations-${terminalPaymentPosition}`,
      target: idTarget,
      type: 'order-payments',
      attributes: {
        amount: {
          amount: +importe,
          currency: 'EUR',
        },
        'payment-source': 'Indra',
        'payment-method': {
          token: numeroCifrado,
          'masked-card-number': mascara,
          'expiry-month': parseInt(fechaCaducidad.toString().substring(2, 4), 10),
          'expiry-year': parseInt(fechaCaducidad.toString().substring(0, 2), 10),
        },
        'payment-status': 'committed',
        'reference-number': numOperacionNNP,
        'return-status': codigo,
        characteristics: {
          'authorization-number': numAutorizacion,
        },
      },
    }
  }
}

let terminalCODPosition = 0
class TerminalPaymentCODGenerator {
  constructor({ amount }, idTarget) {
    terminalCODPosition += 1
    this.result = {
      id: `temp-id-organizations-${terminalCODPosition}`,
      target: idTarget,
      type: 'order-payments',
      attributes: {
        amount: {
          amount,
          currency: 'EUR',
        },
        'payment-source': 'Cash-on-delivery',
        'payment-method': {
          'payment-type': 'Cash',
        },
      },
    }
  }
}

let partyPosition = 0
class PartyGenerator {
  constructor({ role, sub_role }) {
    partyPosition += 1
    this.result = {
      id: `temp-id-party-relationships-${partyPosition}`,
      type: 'party-relationships',
      attributes: {
        role,
        sub_role,
      },
      relationships: {},
    }
  }

  createIndividuals() {
    const individual = new IndividualsGenerator()
    if (!this.result.relationships.party) {
      this.result.relationships.party = []
    }
    this.result.relationships.party.push(individual.result)
    return individual
  }

  createOrganizations() {
    const organization = new OrganizationGenerator()
    if (!this.result.relationships.party) {
      this.result.relationships.party = []
    }
    this.result.relationships.party.push(organization.result)
    return organization
  }

  createPartyAttributes(personalInformation) {
    if (!this.result.relationships.party.attributes) {
      this.result.relationships.party[0].attributes = {}
    }

    const partyAttributes = personalInformation

    this.result.relationships.party[0].attributes = partyAttributes

    return partyAttributes
  }
}

let customerPosition = 0
class CustomerGenerator {
  constructor({ name, type }) {
    customerPosition += 1
    this.result = {
      id: `temp-id-customer-accounts-${customerPosition}`,
      type,
      attributes: {
        name,
      },
      relationships: {},
    }
  }

  createParty({ role, sub_role }) {
    if (!this.result.relationships.related_parties) {
      this.result.relationships.related_parties = []
    }
    const party = new PartyGenerator({ role, sub_role })
    this.result.relationships.related_parties.push(party.result)
    return party
  }

  addBillingAccount({ attributes, billingProfileAttributes }) {
    if (!this.result.relationships.billing_accounts) {
      this.result.relationships.billing_accounts = []
    }
    const billingAccount = jsonGenerators.generateBillingAccount({
      attributes,
      billingProfileAttributes,
    })
    this.result.relationships.billing_accounts.push(billingAccount)
    return billingAccount
  }

  addBillingAccountObject(billingAccount) {
    if (!this.result.relationships.billing_accounts) {
      this.result.relationships.billing_accounts = []
    }
    this.result.relationships.billing_accounts.push(billingAccount)
  }
}

export default class CheckoutGenerator {
  constructor({ offerDetail, offersMetadata } = {}) {
    this.offerDetail = offerDetail
    this.offersMetadata = offersMetadata
    this.errors = {}
    this.result = {
      attributes: {
        sales_info: {},
      },
      relationships: {
        order_items: [],
        payments: [],
      },
    }
  }

  setSalesInfo({ channel, sfid, ip, salesType }) {
    // setSalesInfo({ channel, sfid, ip, salesType, reseller }) {
    this.result.attributes.sales_info = {
      channel,
      'ip-address': ip,
      salesperson_id: sfid,
      'sales-type': salesType,
      // 'dealer-id': reseller,
    }
    return this
  }

  setParentAgreement(id) {
    this.result.attributes.parent_agreement_id = id
  }

  setTerminalPaymentInfo(terminalPaymentInfo, id) {
    let paymentInfo
    if (get(terminalPaymentInfo, 'isTPV', false)) {
      paymentInfo = new TerminalPaymentCODGenerator({ ...terminalPaymentInfo }, id)
    } else {
      paymentInfo = new TerminalPaymentGenerator({ ...terminalPaymentInfo }, id)
    }
    this.result.relationships.payments.push(paymentInfo.result)
    return this
  }

  createOrder(id) {
    const order = new OrderGenerator(id)
    this.result.relationships.order_items.push(order.result)
    return order
  }

  createCustomerAccount({ name, type }) {
    const customer = new CustomerGenerator({ name, type })
    if (!this.result.relationships.customer_account) {
      this.result.relationships.customer_account = []
    }
    this.result.relationships.customer_account.push(customer.result)
    return customer
  }

  toString() {
    return JSON.stringify(this.result, null, 2)
  }

  isValid() {
    const checkoutValidator = new CheckoutValidator({
      offerDetail: this.offerDetail,
      result: this.result,
      offersMetadata: this.offersMetadata,
    })
    this.errors = checkoutValidator.validate()
    return isEmpty(this.errors)
  }
}
