import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import { selectIsPosOrMasProximo } from 'modules/Permissions'

import { BillingDataContainer } from '../../containers/BillingDataContainer'
import { BillsContainer } from '../../containers/BillsContainer'

const useStyles = makeStyles(theme => ({
  'bill-historic': {
    position: 'relative',

    '& thead': {
      width: '100%',
    },

    '& div > table > tbody > tr > td:first-child': {
      width: '6vw',
      borderRight: `1px solid ${theme.palette.global.gray_medium_04}`,
      fontSize: '12px',
    },

    '& div > table > thead > tr > th:first-child': {
      width: '6vw',
    },

    '& .billId': {
      textAlign: 'left',
      whiteSpace: 'nowrap',
    },

    '& .expand-link': {
      color: 'inherit',
      textDecoration: 'none',
      cursor: 'default',
    },

    '& .billId, & .amount': {
      padding: '5.5px 23px',
      fontWeight: 'bold',
    },

    '& .amount': {
      fontSize: '16px',
    },

    '& tbody': {
      overflowY: 'auto',
    },

    '& thead > tr > th.credit-column': {
      width: '3vw',
    },

    '& tbody > tr > td.credit-column': {
      width: '3vw',
      borderLeft: `1px solid ${theme.palette.global.gray_medium_04}`,
    },
  },
}))

export default function BillHistoric({ className, ...props }) {
  const classes = useStyles()
  const isPosOrMasproximo = useSelector(selectIsPosOrMasProximo)

  return (
    <Box
      className={[classes['bill-historic'], ...(className ? [className] : [])].join(' ')}
      {...props}>
      <Box mb="20px" position="relative">
        <BillingDataContainer />
      </Box>

      {!isPosOrMasproximo && <BillsContainer />}
    </Box>
  )
}

BillHistoric.propTypes = {
  className: PropTypes.string,
}
