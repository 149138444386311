import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
  'placeholder-line': {
    animation: 'Gradient 2s ease infinite',
    background: 'linear-gradient(-90deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.15))',
    backgroundSize: '400% 400%',
    borderRadius: '10px',
    height: ({ height }) => height || '10px',
    margin: 0,
    opacity: ({ opacity }) => opacity || 1,
    width: ({ width }) => width || '100%',
    '-webkit-animation': 'Gradient 2s ease infinite',
    '-moz-animation': 'Gradient 2s ease infinite',
  },
  '@keyframes Gradient': {
    '0%': {
      backgroundPosition: '0% 50%',
    },
    '50%': {
      backgroundPosition: '100% 50%',
    },
    '100%': {
      backgroundPosition: '0% 50%',
    },
  },
})
const PlaceholderLine = ({ height, width, opacity, className, ...rest }) => {
  const classes = useStyles({ height, width, opacity })

  return (
    <div
      className={[classes['placeholder-line'], ...(className ? [className] : [])].join(' ')}
      {...rest}
    />
  )
}

PlaceholderLine.propTypes = {
  className: PropTypes.string,
  opacity: PropTypes.number,
  width: PropTypes.string,
  height: PropTypes.string,
}

PlaceholderLine.defaultProps = {
  opacity: 1,
  width: '100%',
  height: '10px',
}

export default PlaceholderLine
