import * as Yup from 'yup/lib'
import * as validations from 'modules/SharedSales/constants/validations'

import {
  INVALID_ZIP_CODE,
  INVALID_FLAT,
  INVALID_DOOR,
  YOIGO_STORE,
  DELIVERY_POINT,
  OTHER_ADDRESS,
} from 'modules/SharedSales/constants'

import { isValidIdDueDate, zipCodeRegex, noSpecialCharactersRegex } from 'utils'
import { DEVICE_TYPE_AGILE_TV } from '../constants/deviceTypes'

export const DeviceSummarySchema = {
  svaAgileEmail: Yup.string().when(['deviceType'], {
    is: deviceType => deviceType === DEVICE_TYPE_AGILE_TV,
    then: Yup.string().required(validations.REQUIRED_FIELD_TEXT),
    otherwise: Yup.string(),
  }),
  financialData: Yup.object({
    dueDate: Yup.string().when(['$isFinancedDevice'], {
      is: isFinanced => isFinanced,
      then: Yup.string()
        .trim()
        .test('ValidateIdDueDate', validations.ERROR_DATA_TEXT, isValidIdDueDate)
        .required(validations.REQUIRED_FIELD_TEXT),
      otherwise: Yup.string().trim(),
    }),
    salary: Yup.string().when(['$isFinancedDevice'], {
      is: isFinanced => isFinanced,
      then: Yup.string().required(validations.REQUIRED_FIELD_TEXT),
      otherwise: Yup.string(),
    }),
    job: Yup.string().when(['$isFinancedDevice'], {
      is: isFinanced => isFinanced,
      then: Yup.string().required(validations.REQUIRED_FIELD_TEXT),
      otherwise: Yup.string(),
    }),
    company: Yup.string().when('job', {
      is: job => !!job,
      then: Yup.string()
        .trim()
        .required(validations.REQUIRED_FIELD_TEXT),
    }),
  }),
  shipping: Yup.object({
    type: Yup.string(),
    deliveryPoint: Yup.object().when('type', {
      is: YOIGO_STORE.value || DELIVERY_POINT.value,
      then: Yup.object({
        zipCode: Yup.string()
          .matches(zipCodeRegex, INVALID_ZIP_CODE)
          .required(validations.REQUIRED_FIELD_TEXT),
        selectedPoint: Yup.object().when('zipCode', {
          is: zipCode => !!zipCode,
          then: Yup.object({
            id: Yup.string().required(validations.REQUIRED_FIELD_TEXT),
          }),
          otherwise: Yup.object({
            id: Yup.string(),
          }),
        }),
      }),
      otherwise: Yup.object({
        zipCode: Yup.string(),
        selectedPointId: Yup.object({
          id: Yup.string(),
        }),
      }),
    }),
    otherAddress: Yup.object().when('type', {
      is: OTHER_ADDRESS.value,
      then: Yup.object({
        address: Yup.string().required(validations.REQUIRED_FIELD_TEXT),
        number: Yup.string().when('editAddress', {
          is: true,
          then: Yup.string().nullable(),
          otherwise: Yup.string()
            .nullable()
            .required(validations.REQUIRED_FIELD_TEXT),
        }),
        flat: Yup.string().matches(noSpecialCharactersRegex, INVALID_FLAT),
        door: Yup.string().matches(noSpecialCharactersRegex, INVALID_DOOR),
        zipCode: Yup.string()
          .matches(zipCodeRegex, INVALID_ZIP_CODE)
          .required(validations.REQUIRED_FIELD_TEXT),
        city: Yup.string().required(validations.REQUIRED_FIELD_TEXT),
        province: Yup.string().required(validations.REQUIRED_FIELD_TEXT),
      }),
    }),
  }),
}
