import { useState, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { createStructuredSelector } from 'reselect'

import { isNil } from 'lodash'

import { selectPoncHasBeenReached } from 'modules/ponc'

import { useWaitingForLoad } from 'utils/hooks/useWaitingForLoad'
import { useSvcLoading } from 'utils/hooks/useSvcLoading'

import * as orderOnFlySvc from 'services/order-on-fly'
import * as orderStatusDescSvc from 'services/order-status-desc'
import * as cancelOrderBusSvc from 'modules/cancel-order-bus'
import { selectors as selectorsCustomer } from 'services/customer-new'
import { selectQueryParam } from 'modules/Router'

import { StatusOnFly } from '../../components/StatusOnFly'
import * as statusOnFlySaga from './StatusOnFlyLoad'

const TitlesStatus = {
  onFlyChTechnology: 'Cambio de Tecnología',
  onFlyChDirection: 'Cambio de Dirección',
}

const statusOnFlyContainerSelectors = createStructuredSelector({
  orderOnFlyNeba: orderOnFlySvc.selectors.selectOrderOnFlyChTechNeba,
  isCancelLoadingVal: cancelOrderBusSvc.selectCancelOrderBusLoading,
  fixedStatus: orderStatusDescSvc.getProductStatus,
  fixedNumber: selectQueryParam('fixedNumber'),
  isSvcLoading: statusOnFlySaga.selectors.isLoading,
  hasErrors: statusOnFlySaga.selectors.hasErrors,
  poncHasBeenReached: selectPoncHasBeenReached,
  clientData: selectorsCustomer.extractPersonalData,
  onFlyChTechnology: orderOnFlySvc.selectors.selectOrderOnFlyChTech,
  onFlyChDirection: orderOnFlySvc.selectors.selectOrderOnFlyChAddr,
  hasCancelErrors: cancelOrderBusSvc.selectCancelOrderBusError,
})

const StatusOnFlyContainer = ({ ...rest }) => {
  const dispatch = useDispatch()

  const {
    orderOnFlyNeba,
    isCancelLoadingVal,
    fixedStatus,
    fixedNumber,
    isSvcLoading,
    hasErrors,
    poncHasBeenReached,
    clientData,
    onFlyChTechnology,
    onFlyChDirection,
    hasCancelErrors,
  } = useSelector(statusOnFlyContainerSelectors)

  const { executeWaiting, waitingForLoad, setWaitingForLoad } = useWaitingForLoad()
  const { isLoading } = useSvcLoading(isSvcLoading, waitingForLoad, setWaitingForLoad)

  const [isCancelling, onSetCancelling] = useState(false)

  const pageTitle = useMemo(
    () =>
      (!!onFlyChDirection && TitlesStatus.onFlyChDirection) ||
      (!!onFlyChTechnology && TitlesStatus.onFlyChTechnology) ||
      '',
    [onFlyChTechnology, onFlyChDirection],
  )

  const cancelOrder = (workOrderId, cancellationMotive) => {
    onSetCancelling(true)
    dispatch(cancelOrderBusSvc.cancelOrderBusAction(workOrderId, cancellationMotive))
  }

  const statusOnFlyLoadInit = () => {
    const phoneNumber = fixedNumber
    const fixedline = fixedNumber

    dispatch(
      statusOnFlySaga.actions.loadInit({
        phoneNumber,
        fixedline,
      }),
    )
  }

  useEffect(() => {
    executeWaiting(statusOnFlyLoadInit)
  }, [])

  const props = {
    ...rest,
    isLoading,
    isCancelling,
    onSetCancelling,
    hasErrors,
    poncHasBeenReached,
    clientData,
    isOnFlyTechNeba: !!orderOnFlyNeba,
    workOrderID: orderOnFlySvc.getWorkOrderID(orderOnFlyNeba),
    lastModifiedDate: orderOnFlySvc.getLastModifiedDate(orderOnFlyNeba),
    fixedStatusStr: orderStatusDescSvc.getFixedStatusStr(fixedStatus),
    pageTitle,
    isCancelLoading: isNil(isCancelLoadingVal) || !!isCancelLoadingVal,
    hasCancelErrors,
    cancelOrder,
  }

  return <StatusOnFly {...props} />
}

export default StatusOnFlyContainer
