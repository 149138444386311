import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { Typography } from '@material-ui/core'

import { NotificationModal } from 'components/ui'
import { CROSS_SELL_NOT_ALLOWED } from 'modules/SharedSales/constants/validations'
import { resetCrossSellAllowed, fetchCrossSellAllowed } from 'services/cross-sell'
import { resetCustomerOrderLimitLoading } from 'modules/CustomerOrders'

import { selectCrossSellModal } from './CrossSellModal.selectors'

export function CrossSellModal({
  shouldCheckAllowed,
  subscription,
  onCrossSellAllowed,
  onCrossSellReached,
}) {
  const dispatch = useDispatch()
  const [isNotAllowedModalOpen, setIsNotAllowedModalOpen] = useState(false)
  const { saleInformation, isCrossSellAllowed, isLoading } = useSelector(selectCrossSellModal)

  function checkAllowed() {
    dispatch(fetchCrossSellAllowed(saleInformation, subscription))
  }

  useEffect(() => {
    if (shouldCheckAllowed && isCrossSellAllowed === null) {
      checkAllowed()
    }
  }, [shouldCheckAllowed, isCrossSellAllowed])

  useEffect(() => () => dispatch(resetCrossSellAllowed()), [])

  useEffect(() => {
    if (isLoading === false && !!shouldCheckAllowed) {
      if (isCrossSellAllowed) {
        setIsNotAllowedModalOpen(false)

        onCrossSellAllowed()
      } else {
        setIsNotAllowedModalOpen(true)
        onCrossSellReached()
      }
    }
  }, [isLoading, shouldCheckAllowed, isCrossSellAllowed])

  return (
    <NotificationModal
      isOpen={isNotAllowedModalOpen}
      type="alert"
      src="/assets/error.svg"
      onClose={() => {
        setIsNotAllowedModalOpen(false)
        dispatch(resetCustomerOrderLimitLoading())
        dispatch(resetCrossSellAllowed())
      }}>
      <Typography variant="h5" style={{ fontWeight: 400 }}>
        {CROSS_SELL_NOT_ALLOWED}
      </Typography>
    </NotificationModal>
  )
}

CrossSellModal.propTypes = {
  shouldCheckAllowed: PropTypes.bool,
  subscription: PropTypes.object,
  onCrossSellAllowed: PropTypes.func.isRequired,
  onCrossSellReached: PropTypes.func.isRequired,
}
