/* eslint-disable import/no-cycle */
import { useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Field, getIn } from 'formik'

import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import { Coverage } from 'components'
import { isEmpty } from 'lodash/fp'
import { hasTechnology } from 'modules/Coverage/store'
import InstallationAddress from './InstallationAddress'
import CoverageResult from './CoverageResult'

const useStyles = makeStyles({
  'coverage-wrapper': {
    '& #coverage-container': {
      minHeight: 'auto',
    },
  },
})

const CoverageForm = ({
  name,
  installationAddress,
  installationAddress: { coverageTechnology },
  coverageToken,
  onCoverageCheckFinished,
  reset,
  isTesting,
  className,
  applicationId,
  allowAutocompleteEvent,
  retryCallNewNumber,
}) => {
  const classes = useStyles()

  const onAutocompleteCoverage = useCallback(evt => {
    if (!allowAutocompleteEvent) {
      return
    }

    try {
      const coverageData = JSON.parse(evt.data)

      onCoverageCheckFinished(coverageData)

      window.removeEventListener('message', onAutocompleteCoverage)
    } catch (e) {
      console.warn(e)
    }
  }, [])

  useEffect(() => {
    window.addEventListener('message', onAutocompleteCoverage)
  }, [])

  const hasAvailableTechnologies = hasTechnology(coverageTechnology)

  const hasAvailableAddress = !isEmpty(installationAddress.gescal)

  return (
    <>
      {hasAvailableAddress && (
        <CoverageResult
          technology={coverageTechnology.technology}
          applicationId={applicationId}
          className={className}
        />
      )}
      {hasAvailableAddress && (
        <InstallationAddress
          {...installationAddress}
          prev={reset}
          retryCallNewNumber={retryCallNewNumber}
        />
      )}
      {!hasAvailableTechnologies && !hasAvailableAddress && coverageToken && (
        <Box position="relative" className={classes['coverage-wrapper']}>
          {applicationId === '360' ? (
            <Coverage
              withoutCss
              token={coverageToken}
              onCoverageCheckFinished={onCoverageCheckFinished}
              containerId="coverage-form"
              isTesting={isTesting}
              applicationId={applicationId}
            />
          ) : (
            <>
              <Field name={name}>
                {({ form, field }) => {
                  const hasError =
                    getIn(form.touched, field.name) && !isEmpty(getIn(form.errors, field.name))
                  return (
                    <Coverage
                      withoutCss
                      token={coverageToken}
                      onCoverageCheckFinished={onCoverageCheckFinished}
                      containerId="coverage-form"
                      isTesting={isTesting}
                      applicationId={applicationId}
                      hasFormErrors={hasError}
                    />
                  )
                }}
              </Field>
            </>
          )}
        </Box>
      )}
    </>
  )
}

CoverageForm.propTypes = {
  name: PropTypes.string.isRequired,
  installationAddress: PropTypes.object.isRequired,
  coverageToken: PropTypes.string,
  onCoverageCheckFinished: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  isTesting: PropTypes.string,
  className: PropTypes.string,
  applicationId: PropTypes.string,
  allowAutocompleteEvent: PropTypes.bool,
  retryCallNewNumber: PropTypes.func,
}

export default CoverageForm
