import { get } from 'lodash'
import * as actions from './orders.actions'

const initialState = {
  data: {},
  error_code: null,
  error_message: null,
  loading: null,
  penalties: { data: [], loading: null, error: null },
  ordersCancellationInfo: { data: [], loading: null, error: null },
  additionalLines: { loading: null, error: null, data: null },
  cancellation: { loading: null, error: null, result: null },
  update: {
    data: null,
    error: null,
  },
  ordersById: {},
}

export const reducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case actions.FETCH_ORDER:
    case actions.FETCH_ORDER_ONLY: {
      return {
        ...state,
        loading: true,
        error_code: null,
        error_message: null,
        data: { ...state.data, id: payload.orderId },
      }
    }
    case actions.FETCH_ORDER_SUCCESS: {
      return {
        ...state,
        data: payload.data,
        loading: false,
        ordersById: {
          ...state.ordersById,
          [payload.data.id]: payload.data,
        },
      }
    }
    case actions.FETCH_ORDER_FAILED: {
      return {
        ...state,
        error_code: get(payload, 'error.httpCode') || 'unknown',
        error_message: get(payload, 'error.info'),
        loading: false,
        data: { ...initialState.data },
      }
    }

    case actions.FETCH_ADDITIONAL_LINES: {
      return {
        ...state,
        additionalLines: { loading: true, data: null, error: null },
      }
    }

    case actions.FETCH_ADDITIONAL_LINES_SUCCESS: {
      return {
        ...state,
        additionalLines: { loading: false, data: payload, error: null },
      }
    }

    case actions.FETCH_ADDITIONAL_LINES_FAILED: {
      return {
        ...state,
        additionalLines: { loading: false, data: null, error: payload },
      }
    }

    case actions.CANCEL_ORDER: {
      return { ...state, loading: true, error_code: null, error_message: null }
    }
    case actions.CANCELED_ORDER: {
      return { ...state, error_code: false, loading: false }
    }
    case actions.CANCEL_ORDER_ERROR: {
      return {
        ...state,
        error_code: payload.code,
        error_message: payload.error_message,
        loading: false,
      }
    }

    case actions.CANCEL_ORDERS: {
      return { ...state, cancellation: { loading: true, error: null, result: null } }
    }
    case actions.CANCEL_ORDERS_SUCCESS: {
      return {
        ...state,
        cancellation: { loading: false, error: null, result: payload.ordersCanceled },
      }
    }
    case actions.CANCEL_ORDERS_FAILED: {
      return {
        ...state,
        cancellation: {
          loading: false,
          error: {
            code: payload.error.code,
            message: payload.error.error_message,
          },
          result: payload.ordersCanceled,
        },
      }
    }
    case actions.RESET_CANCEL_ORDERS: {
      return { ...state, cancellation: { ...initialState.cancellation } }
    }

    case actions.UPDATE_ORDER: {
      return { ...state, loading: true, update: { data: null, error: null } }
    }
    case actions.UPDATED_ORDER: {
      return { ...state, loading: false, update: { data: payload.data, error: null } }
    }
    case actions.UPDATE_ORDER_FAILED: {
      return { ...state, loading: false, update: { data: null, error: action.payload.error } }
    }

    case actions.INCREMENT_STEP: {
      return { ...state, stepModifiedOrder: Number(action.payload.stepModifiedOrder) + 1 }
    }

    case actions.FETCH_PENALTY_ORDER: {
      return { ...state, penalties: { loading: true } }
    }

    case actions.FETCH_PENALTY_ORDER_SUCCESS: {
      return { ...state, penalties: { loading: false, ...action.payload } }
    }

    case actions.FETCH_PENALTY_ORDER_FAILED: {
      return { ...state, penalties: { loading: false, ...action.payload } }
    }

    case actions.FETCH_ORDERS_CANCELLATION_INFO: {
      return { ...state, ordersCancellationInfo: { loading: true, data: [], error: null } }
    }

    case actions.FETCH_ORDERS_CANCELLATION_INFO_SUCCESS: {
      return {
        ...state,
        ordersCancellationInfo: { loading: false, data: action.payload, error: null },
      }
    }

    case actions.FETCH_ORDERS_CANCELLATION_INFO_FAILED: {
      return {
        ...state,
        ordersCancellationInfo: { loading: false, data: [], error: action.payload },
      }
    }

    default:
      return state
  }
}
