import { useEffect } from 'react'

export const useSvcLoading = (isSvcLoading, waitingForLoad, setWaitingForLoad) => {
  const isLoading = waitingForLoad || isSvcLoading

  useEffect(() => {
    if (!isSvcLoading && setWaitingForLoad) {
      setWaitingForLoad(false)
    }
  }, [isSvcLoading])

  return { isLoading }
}
