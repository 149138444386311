import PropTypes from 'prop-types'

import { Card, CardContent, Box, Typography } from '@material-ui/core'

const TariffSidebar = ({ customer }) => {
  return (
    <Card elevation={2} style={{ width: '100%', borderRadius: '4px', marginTop: 0 }}>
      <CardContent>
        {customer &&
          customer.products !== undefined &&
          customer.products.map(item => (
            <div key={item.id}>
              <Typography variant="h6">{item.description || 'Tarifa desconocida'}</Typography>
              <Box marginTop="21px">
                {item.productsSpecifications &&
                  item.productsSpecifications.map(line => (
                    <Box key={line.id} marginBottom="14px">
                      <Typography variant="body1" component="label" style={{ fontWeight: 300 }}>
                        Teléfono:{' '}
                      </Typography>
                      <Typography variant="body1" component="span" style={{ fontWeight: 500 }}>
                        {line.id}
                      </Typography>
                    </Box>
                  ))}
              </Box>
            </div>
          ))}
      </CardContent>
    </Card>
  )
}

TariffSidebar.propTypes = {
  customer: PropTypes.object,
}

export default TariffSidebar
