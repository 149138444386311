import { get } from 'lodash'
import { createSelector } from 'reselect'

export function selectContentful(state) {
  return state.contentful
}

const createSelectContentfulResource = resource =>
  createSelector(selectContentful, contentful => get(contentful, [resource, 'data']))

const createSelectContentfulResourceError = resource =>
  createSelector(selectContentful, contentful => get(contentful, [resource, 'error']))

const SELECTORS = {
  typificationsCvmCampaign: createSelectContentfulResource('typificationsCvmCampaign'),
  typificationsCvmRetention: createSelectContentfulResource('typificationsCvmRetention'),
  typificationsCvmOperator: createSelectContentfulResource('typificationsCvmOperator'),
  typificationsCvmNoutil: createSelectContentfulResource('typificationsCvmNoutil'),
  typificationIframeOperator: createSelectContentfulResource('typificationIframeOperator'),
  typificationIframeCampaign: createSelectContentfulResource('typificationIframeCampaign'),
}

const SELECTORS_ERRORS = {
  typificationsCvmCampaign: createSelectContentfulResourceError('typificationsCvmCampaign'),
  typificationsCvmRetention: createSelectContentfulResourceError('typificationsCvmRetention'),
  typificationsCvmOperator: createSelectContentfulResourceError('typificationsCvmOperator'),
  typificationsCvmNoutil: createSelectContentfulResourceError('typificationsCvmNoutil'),
  typificationIframeOperator: createSelectContentfulResourceError('typificationIframeOperator'),
  typificationIframeCampaign: createSelectContentfulResourceError('typificationIframeCampaign'),
}

export function selectContentfulResource(resource) {
  return SELECTORS[resource] || createSelectContentfulResource(resource)
}

export function selectContentfulResourceError(resource) {
  return SELECTORS_ERRORS[resource] || createSelectContentfulResourceError(resource)
}

export const selectContentfulLoading = resource =>
  createSelector(selectContentful, contentful => {
    return get(contentful, [resource, 'loading'])})