import { useState } from 'react'
import PropTypes from 'prop-types'

import { Typography, Box } from '@material-ui/core'
import { Modal, Button } from 'components/ui'
import { FIBER_INSTALLER } from 'modules/Installations/constants'

export function InstallationModal({ className, fiberInstaller, is2P, isPos }) {
  const [accepted, setAccepted] = useState(false)
  return (
    <Modal isOpen={!accepted} width="35%">
      <div
        className={`${className} flex flex-column align-start-center`}
        data-hook="installation-modal">
        <div className="p-t-12">
          {!!fiberInstaller && (
            <>
              <Typography
                variant="body1"
                data-hook={`fiber-install-${fiberInstaller}`}
                className="bold"
                style={{ marginBotton: '10px' }}>
                Aviso: Instala {fiberInstaller === FIBER_INSTALLER.MM ? 'Yoigo' : 'Telefónica'}
              </Typography>

              <Box mt="10px">
                <Typography variant="body1">INSTALACIÓN:</Typography>

                {fiberInstaller === FIBER_INSTALLER.TESA && (
                  <Typography variant="body1">
                    Informa al cliente que: <br />
                    - Primero acudirá el técnico de Telefónica. <br />
                    - Después acudirá el técnico de YOIGO para finalizar la instalación. <br />
                    <br />
                    Recuerda que en este caso no gestionamos la cita en el momento de la venta.
                    Llamaremos al cliente una vez finalice los trabajos el técnico de Telefónica.
                  </Typography>
                )}

                {fiberInstaller === FIBER_INSTALLER.MM && (
                  <Typography variant="body1">
                    Informa al cliente que solo acudirá el técnico de YOIGO para realizar la
                    instalación en la fecha y franja horaria de la cita.
                  </Typography>
                )}
              </Box>
            </>
          )}

          {!fiberInstaller && (
            <>
              <Typography
                variant="body1"
                data-hook="fiber-install-not-required"
                className="bold"
                style={{ marginBotton: '10px' }}>
                Aviso: Instalación no requerida
              </Typography>

              <Box mt="10px">
                <Typography variant="body1">INSTALACIÓN:</Typography>

                <Typography variant="body1">
                  Informa al cliente que podemos darle el servicio sin necesidad de enviar un
                  técnico a su domicilio y podrá seguir utilizando el mismo router que tiene ahora.
                </Typography>
              </Box>
              <Box mt="10px">
                <Typography variant="body1">ENTREGA DE LA SIM:</Typography>
                <Typography variant="body1">
                  {is2P && isPos
                    ? 'Recuerda que como el instalador no acudirá al domicilio del cliente, tendrás que asociar el ICC al pedido y entregarle la SIM al cliente en tienda.'
                    : 'Recuerda que como el instalador no acudirá al domicilio del cliente, un mensajero le entregará la tarjeta SIM.'}
                </Typography>
              </Box>
            </>
          )}
        </div>

        <div className="p-t-12">
          <Button onClick={() => setAccepted(true)}>Continuar</Button>
        </div>
      </div>
    </Modal>
  )
}

InstallationModal.propTypes = {
  className: PropTypes.string,
  fiberInstaller: PropTypes.string,
  is2P: PropTypes.bool,
  isPos: PropTypes.bool,
}
