import { find } from 'lodash'
import { createSelector } from 'reselect'

export const selectBonuses = state => state.bonuses || {}

export const selectBonusesData = createSelector(
  selectBonuses,
  bonuses => bonuses.data || [],
)
export const selectBonusesErrorMessage = createSelector(
  selectBonuses,
  bonuses => bonuses.error_message,
)
export const selectBonusesErrorCode = createSelector(
  selectBonuses,
  bonuses => bonuses.error_code,
)

export const selectBonusById = id =>
  createSelector(
    selectBonusesData,
    bonuses => find(bonuses, { id }),
  )
