import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'

import SidebarContainer from '../SidebarContainer'

const FlexCol1 = ({ children, ...props }) => (
  <Box order={-1} minWidth="400px" flexBasis="auto" {...props}>
    {children}
  </Box>
)
FlexCol1.propTypes = {
  children: PropTypes.element,
}

const FlexColMain = ({ children }) => (
  <Box flex={1} height="100vh" style={{ overflowX: 'scroll' }}>
    {children}
  </Box>
)
FlexColMain.propTypes = {
  children: PropTypes.element,
}

const SimpleLayout = ({ sidebar, main, dataHook }) => {
  if (sidebar) {
    return (
      <Box display="flex" margin="0 auto" flexWrap="wrap" minHeight="100vh" data-hook={dataHook}>
        <FlexCol1>
          <SidebarContainer>{sidebar}</SidebarContainer>
        </FlexCol1>
        <FlexColMain>{main}</FlexColMain>
      </Box>
    )
  }
  return (
    <Box display="flex" justifyContent="center" data-hook={dataHook}>
      <FlexCol1 style={{ minHeight: '100vh' }}>{main}</FlexCol1>
    </Box>
  )
}

export default SimpleLayout

SimpleLayout.propTypes = {
  sidebar: PropTypes.element,
  main: PropTypes.element.isRequired,
  dataHook: PropTypes.string,
}
