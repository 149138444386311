import { useEffect } from 'react'
import { PropTypes } from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import { permissions, selectCanIDO } from 'modules/Permissions'
import {
  findBillingsInitAction,
  selectBillingsIsLoading,
  selectBillingsError,
} from 'modules/billings'
import { selectors as customer360SvcSelectors } from 'services/customer-360/selectors'
import { helpers as customer360SvcHelpers } from 'services/customer-360/helpers'
import { BillingDataCard } from '../../components/BillingDataCard/BillingDataCard'

export default function BillingDataContainer({ className }) {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(findBillingsInitAction({ filter: 'period:12' }))
  }, [])

  const hasEditPermissions = useSelector(selectCanIDO(permissions.bill_edit_data.id))
  const billingAddress = useSelector(customer360SvcSelectors.getBillingAddress)
  const clientId = useSelector(customer360SvcSelectors.getId)

  const props = {
    className,
    isLoading: useSelector(selectBillingsIsLoading),
    hasErrors: useSelector(selectBillingsError),
    billingDataInfo: {
      clientId,
      iban: useSelector(customer360SvcSelectors.getIBAN),
      billingAddress: customer360SvcHelpers.getBillingAddressStr(billingAddress),
    },
    billingAddressObject: billingAddress,
    hasEditPermissions,
  }
  return <BillingDataCard {...props} />
}

BillingDataContainer.propTypes = {
  className: PropTypes.string,
}
