const fourDigitsIBAN = [/\d/, /\d/, /\d/, /\d/]

export const ibanMask = [
  /([a-zA-Z0-9])/,
  /([a-zA-Z0-9])/,
  /\d/,
  /\d/,
  ' ',
  ...fourDigitsIBAN,
  ' ',
  ...fourDigitsIBAN,
  ' ',
  ...fourDigitsIBAN,
  ' ',
  ...fourDigitsIBAN,
  ' ',
  ...fourDigitsIBAN,
]

export function getChangesKey(key) {
  switch (key) {
    case 'iban':
      return 'IBAN'
    case 'address':
      return 'Dirección'
    case 'zipcode':
      return 'Código Postal'
    case 'city':
      return 'Localidad'
    case 'region':
      return 'Provincia'
    default:
      return ''
  }
}
