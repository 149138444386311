import PropTypes from 'prop-types'

import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import { TextStyles } from 'utils/index'

const useStyles = makeStyles(theme => ({
  'tariff-cell-discount': {
    cursor: 'pointer',
    overflow: 'hidden',

    '& > div': {
      border: ({ selected }) =>
        selected ? `3px solid ${theme.palette.primary.main}` : `1px solid #bababa`,
      borderRadius: '14px',
      padding: '16px 12px',
    },
  },
}))
export function TariffCellDiscount({ discount, selected, onSelectDiscount }) {
  const classes = useStyles({ selected })

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="180px"
      height="100%"
      className={classes['tariff-cell-discount']}
      onClick={() => onSelectDiscount(discount)}
      data-hook="tariff-cell">
      <Box display="flex" flexDirection="column" height="100%">
        <Typography {...TextStyles.labelDark()}>{discount.promotionName}</Typography>
      </Box>
    </Box>
  )
}

TariffCellDiscount.propTypes = {
  discount: PropTypes.object.isRequired,
  selected: PropTypes.bool,
  onSelectDiscount: PropTypes.func,
}
