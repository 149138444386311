import { COMPANY } from 'services/global-constants/segments'

export const getSavePresaleRequestBody = (
  formikValues,
  sfid,
  coverageToken,
  orderType,
  prescoringReference,
) => {
  const { landlineOffer, account, segment } = formikValues

  return {
    sfid,
    data: {
      landlineOffer: {
        installationAddress: landlineOffer.installationAddress,
        isPortability: landlineOffer.isPortability,
        isPortabilityFromFtth: landlineOffer.isPortabilityFromFtth,
        signUpType: landlineOffer.signUpType,
        lineNumber: landlineOffer.lineNumber,
        operator: landlineOffer.operator,
      },
      account: {
        name: account.name,
        surname1: account.surname1,
        surname2: account.surname2,
        documenttype: account.documenttype,
        documentid: account.documentid,
        nationality: account.nationality,
        phoneContact: account.phoneContact,
        language: account.language,
        constitutionDate: account.constitutionDate,
        birthday: account.birthday,
        companyName: account.companyName,
        companyCif: account.companyCif,
        segment: account.segment,
        province: landlineOffer.installationAddress?.province,
        technology: landlineOffer.installationAddress?.coverage,
      },
      segment,
      orderType,
      coverageToken,
      referenceNumber: prescoringReference,
    },
    postalCode: landlineOffer.installationAddress?.zipCode,
    documentId: account.segment === COMPANY ? account.companyCif : account.documentid,
  }
}
