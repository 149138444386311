import { useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'

import { APP_CONFIG } from 'services/app-config'

import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import { SpinnerCenter } from 'components/ui'

import { useGenerateKairosAppointmentToken } from '../../hooks/useGenerateKairosAppointmentToken'

const APPOINTMENT_SUCCESS_EVENT_CODES = [200, 201]

const APPOINTMENT_ERROR_EVENT_CODES = [400, 500]

const useStyles = makeStyles({
  'kairos-appointment-card': {
    paddingTop: '10px',

    '& iframe': {
      width: '100%',
      minHeight: 'calc(100vh - 206px)',
    },
  },
})

export const KairosAppointment = ({ show, data, old, onSuccess, onError, delay }) => {
  const classes = useStyles()

  const {
    generateAppointmentToken,
    appointmentToken,
    appointmentTokenIsLoading,
    appointmentTokenError,
  } = useGenerateKairosAppointmentToken(data, old)

  const url = useMemo(
    () => `${APP_CONFIG.installation_appointments}Booking?jwt=${appointmentToken}`,
    [appointmentToken],
  )

  const onEventReceived = ({ data: eventData }) => {
    const eventDataObj = typeof eventData === 'string' ? JSON.parse(eventData) : eventData

    if (!eventDataObj?.code || !eventDataObj?.message) {
      return
    }

    if (APPOINTMENT_SUCCESS_EVENT_CODES.includes(eventDataObj.code)) {
      onSuccess()
    } else if (APPOINTMENT_ERROR_EVENT_CODES.includes(eventDataObj.code)) {
      onError(eventDataObj.message)
    }
  }

  useEffect(() => {
    if (show) {
      if (delay) {
        setTimeout(generateAppointmentToken, delay)
      } else {
        generateAppointmentToken()
      }

      window.addEventListener('message', onEventReceived)
    }

    return () => {
      window.removeEventListener('message', onEventReceived)
    }
  }, [show])

  useEffect(() => {
    if (appointmentTokenError && show && onError) {
      onError()
    }
  }, [appointmentTokenError])

  if (!show) {
    return null
  }

  return (
    <>
      {appointmentToken && (
        <Box className={classes['kairos-appointment-card']}>
          <iframe title="appointments" src={url} />
        </Box>
      )}
      {appointmentTokenIsLoading && <SpinnerCenter />}
    </>
  )
}

KairosAppointment.propTypes = {
  show: PropTypes.bool,
  old: PropTypes.bool,
  data: PropTypes.shape({
    docNumber: PropTypes.string,
    order: PropTypes.string,
    hasDeviceDelivery: PropTypes.bool,
  }),
  delay: PropTypes.number,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
}
