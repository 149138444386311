import { appointmentActionTypes } from './appointments.actions'

const initialState = {
  error: null,
  loading: false,
  appointmentToken: null,

  kairos: {
    appointmentToken: null,
    loading: false,
    error: null,
  },
}

export const reducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case appointmentActionTypes.GET_APPOINTMENT_TOKEN: {
      return {
        ...state,
        error: null,
        appointmentToken: null,
        loading: true,
      }
    }
    case appointmentActionTypes.GOT_APPOINTMENT_TOKEN: {
      return {
        ...state,
        appointmentToken: payload.appointmentToken.token,
        loading: false,
      }
    }
    case appointmentActionTypes.GET_APPOINTMENT_TOKEN_FAILED: {
      return {
        ...state,
        error: payload.error,
        appointmentToken: null,
        loading: false,
      }
    }
    case appointmentActionTypes.GET_KAIROS_APPOINTMENT_TOKEN: {
      return {
        ...state,
        kairos: {
          error: null,
          appointmentToken: null,
          loading: true,
        },
      }
    }
    case appointmentActionTypes.GET_KAIROS_APPOINTMENT_TOKEN_SUCCESS: {
      return {
        ...state,
        kairos: {
          error: null,
          appointmentToken: payload.appointmentToken,
          loading: false,
        },
      }
    }
    case appointmentActionTypes.GET_KAIROS_APPOINTMENT_TOKEN_FAILED: {
      return {
        ...state,
        kairos: {
          error: payload.error,
          appointmentToken: null,
          loading: false,
        },
      }
    }
    case appointmentActionTypes.SET_APPOINTMENT_ERROR: {
      return {
        ...state,
        error: payload.error,
      }
    }

    default:
      return state
  }
}
