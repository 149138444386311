import PropTypes from 'prop-types'

import { Typography, Box } from '@material-ui/core'
import { ButtonLink } from 'components/ui'

export function BillingSummary({
  billingFullAddress,
  ibanNumber,
  onClickModifyBillingData,
  isMultiSim,
}) {
  return (
    <>
      <Typography variant="subtitle1" color="secondary" className="bold m-b-20">
        DATOS DE FACTURACIÓN
      </Typography>
      <Box display="flex" alignItems="center" justifyContent="flex-start" mb="4px">
        <Typography variant="body1" className="p-r-4">
          IBAN:
        </Typography>
        <Typography variant="body1" className="bold" data-hook="billing.ibanNumber">
          {ibanNumber}
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="flex-start">
        <Typography variant="body1" className="p-r-4">
          Dirección de facturación:
        </Typography>
        <Typography variant="body1" className="bold" data-hook="billing.address">
          {billingFullAddress}
        </Typography>
      </Box>
      {!isMultiSim && (
        <Box mt="8px">
          <ButtonLink to="billing?isEditing=true" onClick={onClickModifyBillingData}>
            Modificar datos de facturación
          </ButtonLink>
        </Box>
      )}
    </>
  )
}

BillingSummary.propTypes = {
  billingFullAddress: PropTypes.string.isRequired,
  onClickModifyBillingData: PropTypes.func.isRequired,
  ibanNumber: PropTypes.string,
  isMultiSim: PropTypes.bool,
}
