import { axiosCustomer } from 'modules/axios'
import { APP_CONFIG } from 'services/app-config'

const ordersAPIUrl = `${APP_CONFIG.apigee_host}/masmovil/v1/orders/ovid`

export function cancelOrder({ workOrderId, cancelationMotive }) {
  return axiosCustomer({
    method: 'DELETE',
    url: `${ordersAPIUrl}/${workOrderId}/cancel`,
    params: { cancelationMotive },
  }).then(resp => resp.data)
}
