import { Fragment, useState } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { isEmpty, get, head, map, reduce, orderBy } from 'lodash'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { formatFee } from 'utils'
import { Card } from 'modules/ui'
import { LineItemCard, ButtonLink, Modal, OutlinedDivider, SpinnerCenter } from 'components/ui'
import { selectURLProductId } from 'services/customer-products'
import { formatPermanent, formatPermanentFromDetails } from 'modules/offers-configuration/helpers'
import { parseISO } from 'date-fns'
import { buildPath, AppPaths } from 'modules/AppPaths'
import { selectURLDocumentId, selectURLDocumentType } from 'modules/CustomerDocument'
import { useIsEnabledCvmModifyPermanencyForLine } from 'modules/offers-configuration/hooks'
import { selectContractId } from 'services/product-orders'
import { applyTax, getTax } from 'services/taxes'
import { selectors as customerSelectors } from 'services/customer-360'
import { selectIsPosOrMasProximo } from 'modules/Permissions/store/permissions-selectors'

import {
  getProductById,
  isFixedLine,
  findFixedPermanents,
  findMobilePermanents,
  findTVPermanents,
  getTerminalsFinancials,
  formatMonthlyFeeForFinancials,
  getTotalCancellationCostsLeft,
  getFeesPendingFromPermanent,
} from 'services/subscriptions'
import { selectEnabledProrratedPenalty } from 'services/feature-flag/selectors'
import { CVMActions } from 'modules/offers-configuration/CVMActions.constants'

import { ContractDownloadError } from './ContractDownloadError'
import { SubscriptionDiscountsInfo } from '../SubscriptionDiscountsInfo/SubscriptionDiscountsInfo'

import { PermanencyDetail } from '../PermanencyDetail/PermanencyDetail'

const PADDING_ELEMENT = '9px 24px'

const useStyles = makeStyles({
  'elfijo-subscription-card': {
    position: 'relative',

    '& .subs-card-button-lnk': {
      whiteSpace: 'nowrap',
    },

    '& .btn-link': {
      textAlign: 'left',
      display: 'block',
    },
  },
})

export const ElFijoSubscriptionCard = ({
  subscription,
  highLigthedUI,
  dataSubscrtiptionFormatted: { tariffDescription, calls, discounts },
  refScroll,
  contractDownloadLoading,
  contractDownloadError,
  setContractDownloadError,
  onContractDownload,
}) => {
  const classes = useStyles()

  const isPosOrMasproximo = useSelector(selectIsPosOrMasProximo)
  const lineId = useSelector(selectURLProductId)
  const product = getProductById(subscription, lineId)
  const isFixedLineVal = isFixedLine(product)
  const contractId = useSelector(selectContractId)

  const documentType = useSelector(selectURLDocumentType)
  const documentId = useSelector(selectURLDocumentId)

  // Permanents
  const latestFixedPermanentByEndDate = head(
    orderBy(
      findFixedPermanents(subscription),
      permanent => parseISO(permanent.validFor.endDateTime),
      'desc',
    ),
  )

  const totalCostsFixedPermanents = reduce(
    findFixedPermanents(subscription),
    (acc, permanent) => acc + getFeesPendingFromPermanent(permanent),
    0,
  )

  const mobilePermanent = head(findMobilePermanents(subscription))
  const tvPermanent = head(findTVPermanents(subscription))

  // Terminal Financials
  const terminalsFinancials = getTerminalsFinancials(subscription)

  const enabledProrratedPenalty = useSelector(selectEnabledProrratedPenalty)

  const totalCancellationCostLeft = getTotalCancellationCostsLeft(
    subscription,
    enabledProrratedPenalty,
  )

  const [isOpenedPermanencyDetail, setIsOpenedPermanencyDetail] = useState(false)

  const isEnabledCvmModifyPermanencyForLine = useIsEnabledCvmModifyPermanencyForLine(lineId)
  const pathToModifyPermanencyCVM = buildPath(
    AppPaths.cvm.id,
    { documentType, documentId },
    {
      subscription_id: get(subscription, 'id'),
      op: isFixedLineVal
        ? CVMActions.modify_fixed_permanency.id
        : CVMActions.modify_mobile_permanency.id,
    },
  )

  const billingAddress = useSelector(state => customerSelectors.getBillingAddress(state))
  const postCode = get(billingAddress, 'postCode')
  const tax = useSelector(state => getTax(state, postCode))

  const enrichedDiscounts = map(discounts, discount => ({
    ...discount,
    amountWithTax: discount.isPercentage ? '-' : applyTax(tax, discount.amount),
  }))

  const menuActions = [
    {
      label: 'Descargar contrato',
      onClick: () => onContractDownload(contractId),
      disabled: isPosOrMasproximo,
    },
  ]

  return (
    <Card
      title="Suscripción"
      id="Suscripción"
      withShadow
      menuActions={menuActions}
      className={`${classes['elfijo-subscription-card']} highLightable ${
        get(highLigthedUI, 'tariff') && 'active'
      }`}>
      {contractDownloadLoading && <SpinnerCenter absolute style={{ marginTop: '50px' }} />}
      {contractDownloadError && (
        <ContractDownloadError
          isOpen={contractDownloadError}
          onClose={() => setContractDownloadError(false)}
        />
      )}

      <LineItemCard primaryText="Tarifa" secondaryText={tariffDescription} />

      <LineItemCard primaryText="MSISDN asociado" secondaryText={get(subscription, 'id')} />

      <LineItemCard primaryText="Total llamadas" secondaryText={calls} />

      <Box p={PADDING_ELEMENT}>
        <OutlinedDivider />
      </Box>

      {/* Permanency */}
      <LineItemCard
        primaryText="Perm. Tarifa"
        secondaryText={
          isFixedLineVal
            ? formatPermanentFromDetails(
                get(latestFixedPermanentByEndDate, 'validFor.endDateTime'),
                totalCostsFixedPermanents,
              )
            : formatPermanent(mobilePermanent)
        }
      />

      <Box padding="0px 24px 6px 24px">
        <ButtonLink
          className="btn-link"
          dataHook={isFixedLineVal ? 'lnk-modify-permanency-fixed' : 'lnk-modify-permanency-mobile'}
          to={pathToModifyPermanencyCVM}
          disabled={!isEnabledCvmModifyPermanencyForLine}>
          Modificar Permanencia
        </ButtonLink>
      </Box>

      <div>
        <Modal
          width="auto"
          isOpen={isOpenedPermanencyDetail}
          title="Desglose de permanencia y penalización"
          onClose={() => setIsOpenedPermanencyDetail(false)}>
          <PermanencyDetail subscription={subscription} />
        </Modal>
      </div>

      <Box p={PADDING_ELEMENT}>
        <OutlinedDivider />
      </Box>

      {/* Terminals */}
      {isEmpty(terminalsFinancials) && isEmpty(mobilePermanent) && isEmpty(tvPermanent) ? (
        <>
          <LineItemCard primaryText="Terminal" secondaryText="Sin terminal" />
          <LineItemCard primaryText="Cuotas pendientes" secondaryText="Sin terminal" />
        </>
      ) : (
        ''
      )}
      {map(terminalsFinancials, financials => (
        <Fragment key={financials.id}>
          {' '}
          <LineItemCard primaryText="Terminal" secondaryText={financials.item.name} />
          <LineItemCard
            primaryText="Cuotas pendientes"
            secondaryText={formatMonthlyFeeForFinancials(financials)}
          />
        </Fragment>
      ))}

      <Box p={PADDING_ELEMENT}>
        <OutlinedDivider />
      </Box>

      <div className="flex align-space-between p-r-24">
        <LineItemCard
          primaryText="Si cancela hoy"
          secondaryText={formatFee(totalCancellationCostLeft)}
        />
        <ButtonLink
          className="subs-card-button-lnk"
          onClick={() => setIsOpenedPermanencyDetail(true)}
          disabled={!(totalCancellationCostLeft > 0)}>
          Ver desglose
        </ButtonLink>
      </div>

      <Box p={PADDING_ELEMENT}>
        <OutlinedDivider />
      </Box>

      <SubscriptionDiscountsInfo
        refScroll={refScroll}
        subscription={subscription}
        discounts={enrichedDiscounts}
        className={`highLightable ${get(highLigthedUI, 'discounts') && 'active'}`}
      />
    </Card>
  )
}

ElFijoSubscriptionCard.propTypes = {
  dataSubscrtiptionFormatted: PropTypes.object,
  subscription: PropTypes.object,
  highLigthedUI: PropTypes.object,
  refScroll: PropTypes.object,
  contractDownloadLoading: PropTypes.bool,
  contractDownloadError: PropTypes.bool,
  setContractDownloadError: PropTypes.func,
  onContractDownload: PropTypes.func,
}
