import { useChannelAndSegment } from 'modules/NewClientSales/hooks/Shared'
import { mapToBundlesWithTaxes } from 'modules/Packages/helpers'
import { fetchBundles } from 'modules/Packages/services'
import { useEffect, useMemo, useState } from 'react'

export function useFetchBundles(ps, tax, isMultiSim) {
  const { channel, segment } = useChannelAndSegment()
  const [isLoadingBundles, setIsLoadingBundles] = useState(false)
  const [hasError, setHasError] = useState(false)

  const [bundleList, setBundleList] = useState([])

  function getBundles() {
    setIsLoadingBundles(true)
    fetchBundles(ps, channel, segment)
      .then(res => {
        setBundleList(res)
        setIsLoadingBundles(false)
      })
      .catch(() => {
        setHasError(true)
        setBundleList([])
        setIsLoadingBundles(false)
      })
  }

  useEffect(() => {
    if (ps && !isMultiSim) {
      getBundles()
    }
  }, [ps, isMultiSim])

  const bundlesWithTaxes = useMemo(() => mapToBundlesWithTaxes(bundleList, tax), [tax, bundleList])

  return { bundleList: bundlesWithTaxes, isLoadingBundles, hasError }
}
