/* eslint no-param-reassign:0 */
import { useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'

import { useSelector } from 'react-redux'

import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import { permissions, selectCanIDO } from 'modules/Permissions'
import { selectEnergyIsAllowed } from 'modules/energy/store/energy.selectors'

import { isEmpty, chain, includes } from 'lodash'
import { PackageBox, HiddenScrollbar, SidebarMenu, SidebarMenuItem } from 'components/ui'
import { D2D_ADDITIONAL_ENERGY_ACCESS } from 'modules/Permissions/constants'

const OVERFLOW_SIDEBAR = 24

const useStyles = makeStyles({
  'hidden-sidebar-scrollbar': {
    width: `calc(100% + ${OVERFLOW_SIDEBAR}px)`,

    '& > div > li': {
      width: `calc(100% - ${OVERFLOW_SIDEBAR}px)`,
    },
  },
})

const Contract = ({ contractItem, selectedOrder, selectedLine, onItemClick }) => (
  <SidebarMenuItem title={contractItem.description} expanded>
    {contractItem.packages.map(packageItem => (
      <SidebarMenuItem
        key={packageItem.id}
        component={
          <PackageBox
            packageItem={packageItem}
            selectedOrder={selectedOrder}
            selectedLine={selectedLine}
            onClickLine={onItemClick}
          />
        }
      />
    ))}
  </SidebarMenuItem>
)

Contract.propTypes = {
  contractItem: PropTypes.object,
  selectedOrder: PropTypes.string,
  selectedLine: PropTypes.string,
  onItemClick: PropTypes.func,
}

const Order = ({ orderItem, onItemClick }) => (
  <SidebarMenuItem
    onClick={() => onItemClick({ type: 'order', id: orderItem.id })}
    title={`Pedido ${orderItem.id}`}
    selected={orderItem.selected}
  />
)

Order.propTypes = {
  orderItem: PropTypes.object,
  onItemClick: PropTypes.func,
}
function SidebarClient({ selectedType, selectedId, data, isCustomer, onItemClick }) {
  const classes = useStyles()

  const permissionCvmOpsAccess = useSelector(selectCanIDO(permissions.cvm_ops_access.id))

  const energyIsAllowed = useSelector(selectEnergyIsAllowed)
  const permissionEnergyViewContracts = useSelector(
    selectCanIDO(permissions.energy_view_contracts.id, D2D_ADDITIONAL_ENERGY_ACCESS),
  )

  const [contracts, setContracts] = useState([])
  const [orders, setOrders] = useState([])

  const getSelectedOrder = () => (selectedType === 'order' ? selectedId : null)

  const updateStateOrder = order => {
    order.selected = String(order.id) === getSelectedOrder()
  }

  const getSelectedLine = () => (selectedType === 'line' ? selectedId : null)

  const getContractLines = contract =>
    chain(contract).get('packages').flatMap('products').flatMap('lines').map('id').value()

  const getContractOrders = contract => chain(contract).get('packages').map('orderId').value()

  const updateStateContract = contract => {
    if (
      includes(getContractLines(contract), getSelectedLine()) ||
      includes(getContractOrders(contract), getSelectedOrder())
    ) {
      contract.expanded = true
    }
  }

  const updateData = useCallback(() => {
    if (isEmpty(data)) {
      return
    }

    const clonedData = chain(data).cloneDeep().value()

    const newContracts = chain(clonedData).get('contracts').forEach(updateStateContract).value()

    const newOrders = chain(clonedData).get('orders').forEach(updateStateOrder).value()

    setContracts(newContracts)
    setOrders(newOrders)
  }, [data])

  useEffect(() => {
    updateData()
  }, [])

  useEffect(() => {
    updateData()
  }, [selectedId])

  const isDefaultSelectedType = !selectedType

  return (
    <Box display="flex" flex={1} flexDirection="column">
      <SidebarMenu>
        {isCustomer && (
          <SidebarMenuItem
            title="Zona de cliente"
            overlay
            selected={isDefaultSelectedType}
            onClick={() => onItemClick({})}
          />
        )}
        {permissionCvmOpsAccess && (
          <SidebarMenuItem
            title="Configurador de ofertas"
            overlay
            selected={selectedType === 'customer-account'}
            onClick={() => onItemClick({ type: 'customer-account' })}
          />
        )}

        {energyIsAllowed && permissionEnergyViewContracts && (
          <SidebarMenuItem
            title="Contratos de energía"
            overlay
            selected={selectedType === 'energy'}
            onClick={() => onItemClick({ type: 'energy' })}
          />
        )}

        <HiddenScrollbar className={classes['hidden-sidebar-scrollbar']}>
          {orders &&
            orders.map(orderItem => (
              <Order key={orderItem.id} orderItem={orderItem} onItemClick={onItemClick} />
            ))}
          {contracts &&
            contracts.map(contractItem => (
              <Contract
                key={contractItem.id}
                contractItem={contractItem}
                selectedOrder={getSelectedOrder()}
                selectedLine={getSelectedLine()}
                onItemClick={onItemClick}
              />
            ))}
        </HiddenScrollbar>
      </SidebarMenu>
    </Box>
  )
}

SidebarClient.propTypes = {
  data: PropTypes.object,
  selectedType: PropTypes.string,
  selectedId: PropTypes.string,
  onItemClick: PropTypes.func,
  isCustomer: PropTypes.bool,
}

SidebarClient.defaultProps = {
  data: {},
}

export default SidebarClient
