import { get } from 'lodash'

export const downloadBlob = (blob, fileName = new Date().getTime()) => {
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, fileName)
  } else {
    const a = document.createElement('a')
    document.body.appendChild(a)
    const url = window.URL.createObjectURL(blob)
    a.href = url
    a.download = fileName
    a.click()
    setTimeout(() => {
      window.URL.revokeObjectURL(url)
      document.body.removeChild(a)
    }, 0)
  }
}

export const openBlob = blob => {
  try {
    const fileURL = URL.createObjectURL(blob)
    window.open(fileURL)
  } catch (e) {
    console.error(e)
  }
}

export const buildBlob = (fileParts, type) => {
  const types = {
    pdf: 'application/pdf',
    zip: 'application/zip',
  }

  return new Blob(fileParts, { type: get(types, type, 'text/html') })
}

export const downloadFromSrc = async src => {
  try {
    const blob = await fetch(src).then(r => r.blob())
    const url = URL.createObjectURL(blob)
    const a = document.createElement('a')
    document.body.appendChild(a)
    a.href = url
    a.target = '_blank'
    a.download = ''
    a.click()
    document.body.removeChild(a)
  } catch (e) {
    console.error(e)
  }
}
