import { useEffect } from 'react'
import PropTypes from 'prop-types'

import { Button, Box, Divider } from '@material-ui/core'
import Close from '@material-ui/icons/Close'

import { SpinnerCenter } from 'components/ui'
import {
  Card,
  CardTitle,
  CardRow,
  CardRowLabel,
  CardRowValue,
  SectionTitle,
  SectionTitleGrid,
} from 'components'

import { constants } from './WizardManager/constants'
import { WizardManager } from './WizardManager'

import { modelData } from '../AddressChange/address.helper'

export function AddressChange({ customer, currentStep, loadingStep, ...restProps }) {
  useEffect(() => {
    if (customer) {
      restProps.checkWizard(['getCoverageToken'], constants.ADDRESS_FORM)
    }
  }, [])

  const closeTab = () => {
    window.close()
  }

  const customerInfo = modelData(customer)
  const { addressInstallation } = customerInfo
  return (
    <>
      <SectionTitleGrid xs={12}>
        <SectionTitle width="auto">Cambio de Dirección</SectionTitle>
        <Button color="default" onClick={closeTab}>
          cancelar y volver
          <Close />
        </Button>
      </SectionTitleGrid>

      {loadingStep && <SpinnerCenter showMsg />}
      {!loadingStep && (
        <Card width="80%">
          <CardTitle> DIRECCIÓN ACTUAL</CardTitle>
          <CardRow>
            <CardRowLabel>Dirección de instalación:</CardRowLabel>
            <CardRowValue>{addressInstallation}</CardRowValue>
          </CardRow>
          <Box mt="15px" mb="30px">
            <Divider />
          </Box>
          <WizardManager currentStep={currentStep || null} parentData={restProps} />
        </Card>
      )}
    </>
  )
}

AddressChange.propTypes = {
  checkWizard: PropTypes.func,
  currentStep: PropTypes.string,
  loadingStep: PropTypes.bool,
  customer: PropTypes.object,
  coverage: PropTypes.object,
  creditScoring: PropTypes.object,
  rove: PropTypes.object,
}

AddressChange.defaultProps = {
  coverage: {},
  creditScoring: {},
  rove: {},
}
