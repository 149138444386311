import PropTypes from 'prop-types'

import { Divider } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  'outlined-divider': {
    backgroundColor: 'initial',
    height: '1px',
    borderBottom: `2px dashed ${theme.palette.global.gray_light_05}`,
  },
}))

export const OutlinedDivider = ({ className, ...props }) => {
  const classes = useStyles()

  return <Divider className={[classes['outlined-divider'], className].join(' ')} {...props} />
}

OutlinedDivider.defaultProps = {
  className: '',
}

OutlinedDivider.propTypes = {
  className: PropTypes.string,
}
