import { reject } from 'lodash/fp'

// constants
export const DISPLAY_NOTIFICATION = 'OVID/NOTIFICATIONS/DISPLAY_NOTIFICATION'
export const HIDE_NOTIFICATION = 'OVID/NOTIFICATIONS/HIDE_NOTIFICATION'

// action creators
export const displayNotification = payload => ({ type: DISPLAY_NOTIFICATION, payload })
export const hideNotification = payload => ({ type: HIDE_NOTIFICATION, payload })

// reducer
export default function notificationsReducer(notifications = [], { type, payload }) {
  switch (type) {
    case DISPLAY_NOTIFICATION:
      /*
       * notification can contain { type, message, title, ...rest } object or array of them
       */
      return notifications.concat(payload)
    case HIDE_NOTIFICATION:
      /*
       * Allow filter by object part e.g. { id: 1, message: 'foo', bar: 'baz' } can be filtered by action
       * { type: 'OVID/NOTIFICATIONS/HIDE_NOTIFICATION', payload: { message: 'foo' } }
       * see https://lodash.com/docs/4.17.11#filter
       */
      return reject(payload, notifications)
    default:
      return notifications
  }
}
