import { useDispatch } from 'react-redux'

import { authService } from '../services/authService'
import { actions } from '../store/actions'

export const useForceSaveAuth = () => {
  const dispatch = useDispatch()

  const saveLatestAccessToken = () => {
    const latestAccessToken = authService.getAccessToken()

    dispatch(actions.saveAccessToken(latestAccessToken))
  }

  return {
    saveLatestAccessToken,
  }
}
