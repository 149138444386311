import { find, isEmpty, sortBy, get, head, chain } from 'lodash'
import {
  ADSL,
  FTTH,
  NEBA,
  NEBA_REGEXP,
  VULA,
  VULA_REGEXP,
} from 'modules/SharedSales/constants/technology'

function processSpeeds(speeds) {
  const result = speeds.map(speed => speed.split('_')[0])
  return result
}

function speedSorter(speed) {
  const speedNumber = speed.match(/\d+/)[0]
  return parseInt(speedNumber, 10) * 100
}

export const getTechnology = coverage => {
  if (!coverage) {
    return undefined
  }

  const coverageInfo = coverage
  let nebaAvailable = false
  let vulaAvailable = false
  let ftthAvailable = false
  let adslAvailable = false
  let ftthSpeeds = []
  let adslSpeeds = []
  let nebaSpeeds = []
  let vulaSpeeds = []

  for (let i = 0; i < coverageInfo.length; i += 1) {
    if (
      coverageInfo[i].ftth &&
      coverageInfo[i].ftth.length > 0 &&
      coverageInfo[i].characteristics[0].value.match(NEBA_REGEXP) !== null
    ) {
      nebaAvailable = true
      nebaSpeeds = processSpeeds(coverageInfo[i].ftth)
    } else if (
      coverageInfo[i].ftth &&
      coverageInfo[i].ftth.length > 0 &&
      coverageInfo[i].characteristics[0].value.match(VULA_REGEXP) !== null
    ) {
      vulaAvailable = true
      vulaSpeeds = processSpeeds(coverageInfo[i].ftth)
    } else if (coverageInfo[i].ftth && coverageInfo[i].ftth.length > 0) {
      ftthAvailable = true
      ftthSpeeds = processSpeeds(coverageInfo[i].ftth)
    } else if (coverageInfo[i].ull && coverageInfo[i].ull.length > 0) {
      adslAvailable = true
      adslSpeeds = processSpeeds(coverageInfo[i].ull)
    }
  }

  const result = {
    technology: [],
    speeds: [],
  }
  if (nebaAvailable) {
    result.speeds = sortBy(Array.from(new Set(nebaSpeeds)), speedSorter)
    result.technology.unshift(NEBA)
  }
  if (vulaAvailable) {
    result.speeds = sortBy(Array.from(new Set(vulaSpeeds)), speedSorter)
    result.technology.unshift(VULA)
  }
  if (adslAvailable) {
    result.speeds = sortBy(Array.from(new Set(adslSpeeds)), speedSorter)
    result.technology.unshift(ADSL)
  }
  if (ftthAvailable) {
    result.speeds = sortBy(Array.from(new Set(ftthSpeeds)), speedSorter)
    result.technology.unshift(FTTH)
  }

  return result
}

export const configureSelectedTechnology = (technology, data) => {
  const coverage = data.coverage || []
  if (coverage.length === 1) {
    return coverage
  }
  if (technology === 'NEBA') {
    return [find(coverage, elem => !isEmpty(elem.ftth))]
  }
  if (technology === 'ADSL') {
    return [find(coverage, elem => !isEmpty(elem.ull))]
  }
  return []
}

export const formatFullAddress = ({
  streetType,
  street,
  number,
  block,
  stair,
  floor,
  door,
  letter,
  Hand,
  town,
  province,
  zipCode,
}) =>
  `${streetType} ${street}, ${number || ''}, ${block} ${stair} ${floor} ${door ||
    ''} ${letter} ${Hand}. ${town}, ${province}, ${zipCode}`

export function hasNebaOrVula(territoryOwner) {
  const nebaRegexp = new RegExp('FIBNEB')
  const vulaRegexp = new RegExp('FIBNBL')
  return nebaRegexp.test(territoryOwner) || vulaRegexp.test(territoryOwner)
}

const numberSymbol = otherAddress => (Number(otherAddress.flat) ? 'º' : ' ')

export const formatOtherFullAddress = otherAddress => {
  if (!otherAddress.address && !otherAddress.door && !otherAddress.city && !otherAddress.province) {
    return ''
  }
  return `${otherAddress.address} ${
    /\d/.test(otherAddress.address) ? '' : otherAddress.number || ''
  } ${otherAddress.flat ? `,${otherAddress.flat}` : ''}${numberSymbol(
    otherAddress,
  )}${otherAddress.door || ''}, ${otherAddress.city},
  ${otherAddress.province},
  ${otherAddress.zipCode}. ${otherAddress.otherInfo || ''}`
}

function getTechnologyName(coverage) {
  const formated = getTechnology(coverage)
  return head(get(formated, 'technology'))
}

export function isSameCoverage(coverage1, coverage2) {
  return getTechnologyName(coverage1) === getTechnologyName(coverage2)
}

export const getTerritoryOwnerByCoverage = coverage =>
  chain(coverage)
    .first()
    .get('characteristics')
    .find({ name: 'territoryOwner' })
    .get('value')
    .value()
