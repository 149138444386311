import axios from 'axios'
import { axiosJWT } from 'modules/axios'

import { APP_CONFIG } from 'services/app-config'

const appointmentsAPIUrl = `${APP_CONFIG.apigee_host}/masmovil/v1/appointments`
const newAppointmentsAPIUrl = `${APP_CONFIG.sales_cluster}/v1/appointments/generateToken`

const kairosGetAppointmentTokenAPIUrl = `${APP_CONFIG.sales_cluster}/v1/appointments/token`

/**
 *
 * @param {string} customerId
 * @param {string} data
 * @param {string} token customer or agent token
 */
export function getToken({ data, token, customerId, newURL }) {
  // TODO: once we get rid of the quickwins we can keep the newURL and delete the old request
  if (newURL) {
    return axiosJWT({
      method: 'POST',
      url: newAppointmentsAPIUrl,
      data: { JSON: data },
    }).then(resp => resp.data)
  }
  return axios({
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'X-Customer-ID': customerId,
    },
    url: `${appointmentsAPIUrl}/jwt`,
    data,
  }).then(resp => resp.data)
}

export function getKairosAppointmentToken(data) {
  return axiosJWT({
    method: 'POST',
    url: kairosGetAppointmentTokenAPIUrl,
    data,
  }).then(resp => resp.data)
}
