import { isEmpty, forEach, isNil, get } from 'lodash'
import { MAIN_DUO_DISCOUNT_ID } from 'modules/offers-configuration/constants'

export const DEFAULT_FIXED_DIGITS = 2
export const DEFAULT_NO_VALUE_STR = '-'

export const TIME_UNITS = {
  YEARS: {
    id: 'years',
  },
  MONTHS: {
    id: 'months',
  },
  DAYS: {
    id: 'days',
  },
}

/*
  If amount is a string is first replaced , by . and then converted to number
*/

export function formatAmount(
  amount,
  fixedDigits = DEFAULT_FIXED_DIGITS,
  space = true,
  suffix = '',
  opts = {
    noDecimalsIfZero: false, // Dont show decimals if they are ,0
    truncated: false,
  },
) {
  if (isNil(amount)) {
    return 'NaN'
  }

  const amountVal =
    typeof amount === 'string' ? parseFloat(amount.replace(',', '.'), 10) : parseFloat(amount, 10)

  if (Number.isNaN(amountVal)) {
    return 'NaN'
  }

  const spaceStr = space && suffix ? ' ' : ''
  const powerOf10 = 10 ** fixedDigits

  const amountValTruncated = Math.trunc(amountVal * powerOf10)
  const amountValRound = Math.round(amountVal * powerOf10)
  const amountValFixed = opts.truncated ? amountValTruncated : amountValRound

  const amountValFixedDigits = !Number.isInteger(amountVal) ? amountValFixed / powerOf10 : amountVal

  const value =
    Number.isInteger(amountValFixedDigits) && opts.noDecimalsIfZero
      ? amountValFixedDigits.toFixed(0)
      : amountValFixedDigits.toFixed(fixedDigits)

  return `${value.replace('.', ',')}${spaceStr}${suffix}`
}

export function formatFee(price, fixedDigits = DEFAULT_FIXED_DIGITS, space = true, opts) {
  return formatAmount(price, fixedDigits, space, '€', opts)
}

export function formatMinutes(minutes, fixedDigits = DEFAULT_FIXED_DIGITS, space = true, opts) {
  return formatAmount(minutes, fixedDigits, space, 'MIN', opts)
}

export function formatGB(gb, fixedDigits = DEFAULT_FIXED_DIGITS, space = true, opts) {
  return formatAmount(gb, fixedDigits, space, 'GB', opts)
}

export function formatMB(mb, fixedDigits = DEFAULT_FIXED_DIGITS, space = true, opts) {
  return formatAmount(mb, fixedDigits, space, 'MB', opts)
}

export function formatAmountStd(amount, fixedDigits = DEFAULT_FIXED_DIGITS, space = false, opts) {
  return formatAmount(amount, fixedDigits, space, '', opts)
}

export const formatNumber = price =>
  `${(Math.round(parseFloat(price, 10) * 100) / 100).toFixed(2).replace('.', ',')}`

export function roundTwoDecimals(num) {
  const t = 10 ** 2
  return parseFloat(
    (Math.round(num * t + 1 * (Math.sign(num) * (10 / 100 ** 2))) / t).toFixed(2).replace(',', '.'),
  )
}

export const formatTwoDigits = value => `0${value}`.slice(-2)

export function formatPercent(total, percentage) {
  return (total * percentage) / 100
}

export const formatStringToNumber = str =>
  !Number.isInteger(str) ? parseFloat(str.replace(',', '.')) : str

export const formatMonthlyFee = fee => `${formatFee(fee)}/mes`

export const formatNumberWithComma = price => {
  const priceFloat = Number.parseFloat(price)
  if (Number.isNaN(priceFloat)) {
    return 0
  }
  return !Number.isInteger(priceFloat) ? priceFloat.toFixed(2).replace('.', ',') : priceFloat
}

export const formatRawText = (text, str) => {
  return text
    .replace(/['"]+/g, '')
    .split(str)
    .filter(elem => !!elem)
}

export const truncateDecimals = num => Number.parseInt(num, 10)

export const getSelectedProperty = (obj, property) => {
  if (isEmpty(Object.keys(obj))) return []
  const errors = []
  forEach(Object.keys(obj), elem => {
    if (obj[elem][property]) errors.push({ [elem]: obj[elem][property] })
  })
  return errors
}

export const getSelectedPropertyComparing = (obj, property, type, equal) => {
  if (isEmpty(Object.keys(obj))) return []
  const errors = []
  forEach(Object.keys(obj), elem => {
    if (obj[elem][property] !== type && !equal && obj[elem][property]) {
      errors.push({ [elem]: obj[elem][property] })
    } else if (obj[elem][property] === type && equal) {
      errors.push({ [elem]: obj[elem][property] })
    }
  })
  return errors
}

export function getTranslatedPeriod(period) {
  switch (period) {
    case 'month':
      return 'meses'
    case 'year':
      return 'años'
    case 'day':
      return 'días'
    default:
      return ''
  }
}

export const setPromotionLiteral = (promo, discountId = '') => {
  const promoUnit = get(promo, 'unit')
  const unit = promoUnit === 'percents' || promoUnit === 'Percentage' ? '%' : '€'
  return `${get(promo, 'name')} ${formatNumberWithComma(get(promo, 'amount'))}${unit} ${
    discountId === MAIN_DUO_DISCOUNT_ID ? 'sin impuestos incluidos' : ''
  }`
}

export function formatTimeUnit(value, unit) {
  switch (unit) {
    case TIME_UNITS.YEARS.id:
      return value === 1 ? 'año' : 'años'
    case TIME_UNITS.MONTHS.id:
      return value === 1 ? 'mes' : 'meses'
    case TIME_UNITS.DAYS.id:
      return value === 1 ? 'día' : 'días'
    default:
      return ''
  }
}

export const NoDataStr = '-'

export * from './dates'

export function flattenObj(obj, prefix = '', res = {}) {
  return Object.entries(obj).reduce((r, [key, val]) => {
    const k = `${prefix}${key}`
    if (typeof val === 'object') {
      flattenObj(val, `${k}.`, r)
    } else {
      res[k] = val
    }
    return r
  }, res)
}

function isLetter(character) {
  return character.toLowerCase() !== character.toUpperCase()
}

export function capitalizeText(text) {
  let capitalizedText = text
  const firstChar = text.charAt(0)

  if (isLetter(firstChar)) {
    capitalizedText = firstChar.toUpperCase() + text.slice(1)
  }
  return capitalizedText
}
