import { differenceInYears, differenceInCalendarDays, isAfter, getYear } from 'date-fns'
import { zipCodeRegex } from './regex'

export const ValidateMinLength = (text, length) => text.length >= length

export const ValidationDirection = text => /([a-zA-Z0-9])/.test(text)

export const ValidateEmail = email => new RegExp('[^@]+@[^@]+\\.[^@]+').test(email)

export const validateHasNumbers = value => /\d/.test(value)

export const validateHasAllNumbers = value => /^\d+$/.test(value)

export const validateIUA = value => {
  const basicStructure = value && value.length === 12 && validateHasAllNumbers(value)

  if (!basicStructure) {
    return false
  }

  const [c, p1, p2, ...digits] = `${value}`.split('')

  // PP should be between 1 and 52
  const pp = `${p1}${p2}` * 1
  const isValidPP = pp < 53 && pp > 0

  if (!isValidPP) {
    return false
  }

  // Calculate the control digit to match C
  let index = 2
  const newControlDigit = [p2, ...digits]
    .map(i => i * 1)
    .reduce((prev, next) => {
      const newC = prev + index * next
      index += 1
      return newC
    }, p1 * 1)

  const module9 = (newControlDigit % 9) + 1

  return module9 === c * 1
}

const daysInMonth = (m, y) => {
  switch (m) {
    case 2:
      return (y % 4 === 0 && y % 100 !== 0) || y % 400 === 0 ? 29 : 28
    case 8:
    case 1:
    case 3:
    case 5:
    case 7:
    case 12:
    case 10:
      return 31
    default:
      return 30
  }
}

export const isValidDate = (d, m, y) => {
  const month = parseInt(m, 10) - 1
  return month >= 0 && month < 12 && d > 0 && d <= daysInMonth(parseInt(m, 10), y)
}

export const isValidAge = value => {
  if (!value) return false
  const formated = value
    .split('/')
    .reverse()
    .join('/')
  const validate = value.split('/').map(elem => parseInt(elem, 10))
  return (
    differenceInYears(new Date(), new Date(formated)) >= 18 &&
    isValidDate(...validate) &&
    getYear(new Date(formated)) >= 1800
  )
}
export const isValidConstitutionDate = value => {
  if (!value) return false

  const year = value.split('/').reverse()[0]
  const formated = value
    .split('/')
    .reverse()
    .join('/')
  return differenceInCalendarDays(new Date(), new Date(formated)) >= 1 && year >= 1650
}
export const isValidIdDueDate = (value = '') => {
  const formated = value
    .split('/')
    .reverse()
    .join('/')
  const validate = value.split('/').map(elem => parseInt(elem, 10))
  return isAfter(new Date(formated), new Date()) && isValidDate(...validate)
}

export const isValidPostCode = postCode => zipCodeRegex.test(postCode)
