import { Box } from '@material-ui/core'

import { APP_CONFIG } from 'services/app-config'

import { AdviceSection, Button } from 'components/ui'

export const NotAuthenticatedSection = ({ hideButtons, hideModal }) => {
  const goTo = url => {
    window.location.href = url
  }

  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <Box maxWidth="872px" p="20px">
        <AdviceSection
          type={hideModal ? null : 'error'}
          image={{ url: '/assets/error.svg', alt: 'Not found' }}
          title="Usuario no autenticado"
          body="Para poder acceder debe autenticarse primero en Yoigo">
          {!hideButtons && (
            <Box display="flex" mt="15px" justifyContent="center">
              <Button
                onClick={() => goTo(APP_CONFIG.newton_login)}
                secondary
                style={{ marginRight: '10px' }}>
                Newton
              </Button>
              <Button onClick={() => goTo(APP_CONFIG.televenta_login)} secondary>
                Telesales
              </Button>
            </Box>
          )}
        </AdviceSection>
      </Box>
    </Box>
  )
}
