import { APP_CONFIG } from 'services/app-config'
import { axiosJWT } from 'modules/axios'

const BASE_URL = `${APP_CONFIG.sales_cluster}/v1/terminals/deviceCharacteristics`

export const fetchDeviceSpecifications = id =>
  axiosJWT({
    method: 'GET',
    url: `${BASE_URL}/${id}`,
  }).then(({ data }) => data)
