import PropTypes from 'prop-types'

import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import { getLighten } from 'utils'
import { ButtonLink } from '../buttons/ButtonLink'
import Tabs from '../tabs/Tabs'
import Price from './../price/Price'

const useStyles = makeStyles(theme => ({
  'price-wrapper': {
    '& > div:not(:last-child)': {
      borderRight: `1px solid ${getLighten(theme.palette.global.gray_dark, 0.8)}`,
      paddingRight: '30px',
    },

    '& > div:not(:first-child)': {
      paddingLeft: '23px',
    },
  },
}))

const ConfigSubHeader = ({
  tabsInfo,
  onSelectChange,
  handleBreakDown,
  price,
  aux,
  quotas,
  quotasPayment,
}) => {
  const classes = useStyles()

  return (
    <Box pt="18px">
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" flexDirection="column" p={0}>
          <Tabs tabsInfo={tabsInfo} onSelectChange={onSelectChange} small />
        </Box>
        <ButtonLink onClick={handleBreakDown}>Ver Desglose</ButtonLink>
      </Box>
      <Box display="flex" mt="17px" mb="24px" className={classes['price-wrapper']}>
        <Price title="Cuota 24 meses" price={price} monthly aux={aux} />
        <Price title="Cuotas temporales" monthly quotas={quotas} linkText />
        <Price title="Pagos" quotas={quotasPayment} linkText />
      </Box>
    </Box>
  )
}

ConfigSubHeader.propTypes = {
  tabsInfo: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  onSelectChange: PropTypes.func,
  handleBreakDown: PropTypes.func,
  price: PropTypes.string,
  quotas: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  quotasPayment: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  aux: PropTypes.string,
}

export default ConfigSubHeader
