export const PERCENTS = 'percents'
export const MONETARY = 'monetary'
export const SPECIAL = 'special'

export const discount = {
  id: '',
  monthlyFee: 0,
  type: '',
  duration: 0,
}
