import * as constants from './constants'

const findEnrichment = productId => ({
  type: constants.FIND_ENRICHMENT,
  payload: {
    loading: true,
    productId,
  },
})

const foundEnrichment = data => ({
  type: constants.FIND_ENRICHMENT_SUCCESS,
  payload: data,
})

const findEnrichmentFailed = () => ({
  type: constants.FIND_ENRICHMENT_FAILED,
  payload: {
    loading: false,
  },
})

export const actions = {
  findEnrichment,
  foundEnrichment,
  findEnrichmentFailed,
}
