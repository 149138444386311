import { useState } from 'react'
import { fetchWelcomeLetter } from 'modules/NewClientSales/services/welcomeLetter.service'

export const useDownloadFileWl = () => {
  const [state, setState] = useState({
    isLoading: false,
    error: null,
  })

  function downloadFromPromiseWl(welcomeLetter2pData) {
    setState({
      isLoading: true,
      error: null,
    })
    const {
      tariffName,
      taxZipCode,
      tariffType,
      segment,
      price,
      fixedLineNumber,
      clientName,
      paymentType,
    } = welcomeLetter2pData

    fetchWelcomeLetter(
      tariffName,
      taxZipCode,
      tariffType,
      segment,
      price,
      fixedLineNumber,
      clientName,
      paymentType,
    )
      .then(() => {
        setState({
          isLoading: false,
          error: null,
        })
      })
      .catch(e => {
        setState({
          isLoading: false,
          error: e,
        })
      })
  }

  return [state, downloadFromPromiseWl]
}
