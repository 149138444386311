/* eslint-disable react/no-array-index-key */
import PropTypes from 'prop-types'

import { TitleSidebar, TextSidebar } from 'components/ui'
import { formatNumberWithComma } from 'utils/formatting/index'

const FEE_SUFFIX = ' €/mes'

const AfterPaymentFee = ({ fee, isPro }) => (
  <div>
    <TitleSidebar>Después</TitleSidebar>
    <TextSidebar dataHook="sideBar-fee-after">
      {fee}
      {isPro && ' (impuestos no incl.)'}
    </TextSidebar>
  </div>
)

AfterPaymentFee.propTypes = {
  fee: PropTypes.string,
  isPro: PropTypes.bool,
}

const FirstPaymentFee = ({ fee, months }) => (
  <div>
    <TitleSidebar>Primeros {months} meses</TitleSidebar>
    <TextSidebar dataHook={`sideBar-fee-first${months}`}>{fee}</TextSidebar>
  </div>
)

FirstPaymentFee.propTypes = {
  fee: PropTypes.string,
  months: PropTypes.number,
}
// TODO: find better way of indexing things
const OffersFeeSidebar = ({ monthlyPayments, isPro = false }) => {
  return (
    monthlyPayments &&
    monthlyPayments.map((payment, index) => {
      // Only one monthly payment type
      if (monthlyPayments.length === 1) {
        return (
          <div key={index}>
            <TitleSidebar>Cuota total final</TitleSidebar>
            <TextSidebar>
              {formatNumberWithComma(payment.amount)}
              {FEE_SUFFIX}
              {isPro && ' (impuestos no incl.)'}
            </TextSidebar>
          </div>
        )
      }
      if (index === 0) {
        return (
          <FirstPaymentFee
            key={index}
            fee={`${formatNumberWithComma(payment.amount)}${FEE_SUFFIX}`}
            months={payment.duration}
          />
        )
      }
      if (index === monthlyPayments.length - 1) {
        return (
          <AfterPaymentFee
            key={index}
            fee={`${formatNumberWithComma(payment.amount)}${FEE_SUFFIX}`}
            isPro={isPro}
          />
        )
      }
      return (
        payment.duration && (
          <div key={index}>
            <TitleSidebar>
              Meses {monthlyPayments[index - 1]?.duration || 0} a {payment.duration}
            </TitleSidebar>
            <TextSidebar>
              {formatNumberWithComma(payment.amount) || ''}
              {FEE_SUFFIX || ''}
            </TextSidebar>
          </div>
        )
      )
    })
  )
}

OffersFeeSidebar.propTypes = {
  monthlyPayments: PropTypes.array,
  isPro: PropTypes.bool,
}

OffersFeeSidebar.defaultProps = {
  isPro: false,
}

export default OffersFeeSidebar
