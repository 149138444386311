import { createStructuredSelector } from 'reselect'
import { selectAdditionalMobileTariffsIsLoading } from 'modules/tariffs/store'
import {
  selectIsMobileOnly,
  selectIsSubscriptionQueryParam,
  selectIsCarteraQueryParam,
  selectIsCrossSellQueryParam,
} from 'modules/Router/store/index'
import { selectSubscriptionTariffId } from 'services/customer-360/selectors'
import {
  selectMainSelectedTariff,
  selectSelectedExtraMobileTariffs,
  selectFormatedAdditionalTariffs,
  selectAdditionalTariffsErrorType,
} from '../../../store'

export const storeSelectors = createStructuredSelector({
  isMobileOnly: selectIsMobileOnly,
  mainOffer: selectMainSelectedTariff,
  selectedExtraTariffs: selectSelectedExtraMobileTariffs,
  tariffList: selectFormatedAdditionalTariffs,
  loading: selectAdditionalMobileTariffsIsLoading,
  error: selectAdditionalTariffsErrorType,
  isSubscription: selectIsSubscriptionQueryParam,
  isCrossSell: selectIsCrossSellQueryParam,
  subsctiptionTariffId: selectSubscriptionTariffId,
  isCartera: selectIsCarteraQueryParam,
})
