import { Card, CardContent, Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { TextStyles } from 'utils/index'

import { ReactComponent as NotFoundSvg } from '../../../../apps/web-main/public/assets/not_found.svg'

const useStyles = makeStyles(theme => ({
  'dashboard-home': {
    height: 'calc(100vh - 64px)',
    position: 'relative',
  },
  'no-client-selected-advice': {
    margin: '0 auto',
    maxWidth: '450px',
    left: 0,
    position: 'absolute',
    right: 0,
    textAlign: 'center',
    top: '10px',

    '& h5': {
      marginBottom: '15px',
    },

    '& p': {
      color: theme.palette.global.gray,
    },
  },
  'no-client-selected-message': {
    '& svg': {
      '& path': {
        fill: theme.palette.global.gray,
      },
      marginBottom: '12px',
    },

    '& h4': {
      marginBottom: '20px',
    },

    '& h4, & p': {
      color: theme.palette.global.gray,
    },
  },

  'arrow-image-wrapper': {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,

    '& img': {
      left: '20%',
      maxHeight: '70%',
      maxWidth: '40%',
      position: 'relative',
    },
  },
}))

function DashboardHome() {
  const classes = useStyles()

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      className={classes['dashboard-home']}>
      <Card className={classes['no-client-selected-advice']} elevation={0}>
        <CardContent>
          <Typography
            {...TextStyles.title2Dark({
              color: 'error',
            })}>
            AVISO
          </Typography>
          <Typography {...TextStyles.paragraphDark()}>Ningún cliente seleccionado.</Typography>
        </CardContent>
      </Card>

      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        className={classes['no-client-selected-message']}>
        <NotFoundSvg />

        <Typography {...TextStyles.title1Dark()}>No hay ningún cliente seleccionado</Typography>
        <Typography
          {...TextStyles.title2Dark({
            component: 'p',
            className: 'thin',
          })}>
          Por favor, busca un cliente en la barra de búsqueda
        </Typography>
      </Box>

      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        className={classes['arrow-image-wrapper']}>
        <img alt="arrow" src="/assets/arrow_path.svg" />
      </Box>
    </Box>
  )
}

export default DashboardHome
