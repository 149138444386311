import { axiosJWT } from 'modules/axios'
import { APP_CONFIG } from 'services/app-config'

const url = `${APP_CONFIG.sales_cluster}/v1/terminals/imeis`

export function validateImei(candidate) {
  return axiosJWT({
    method: 'GET',
    baseURL: `${url}/${candidate}`,
  }).then(resp => resp.data)
}
