import { PropTypes } from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectIsLoading,
  selectErrorMessage,
  customerWriteAction,
  customerWriteClearErrorAction,
} from 'modules/customer-write'
import { CustomerWriteModal } from '../../components/CustomerWriteModal/CustomerWriteModal'

export default function CustomerWriteModalContainer({
  openCustomerWriteModal,
  setOpenCustomerWriteModal,
  currentIban,
  billingAddress,
  clientId,
}) {
  const dispatch = useDispatch()
  const props = {
    openCustomerWriteModal,
    setOpenCustomerWriteModal,
    currentIban,
    billingAddress,
    isCustomerWriteLoading: useSelector(selectIsLoading),
    customerWriteErrors: useSelector(selectErrorMessage),
    customerWrite: changes => dispatch(customerWriteAction(clientId, changes)),
    customerWriteClearError: () => dispatch(customerWriteClearErrorAction()),
  }
  return <CustomerWriteModal {...props} />
}

CustomerWriteModalContainer.propTypes = {
  openCustomerWriteModal: PropTypes.bool,
  setOpenCustomerWriteModal: PropTypes.func,
  currentIban: PropTypes.string,
  billingAddress: PropTypes.object,
  clientId: PropTypes.string,
}
