import { isNil, isEmpty, get } from 'lodash'
import { axiosFactory, axiosJWT } from 'modules/axios'

import {
  DATA_TOKEN_OBLIGATORY_FIELDS,
  DATA_TOKEN_OBLIGATORY_FIELDS_SECURIZED,
  DATA_TOKEN_ERROR_CODE,
  INSERTION_NOTIGICATIONS_TIPIS,
  SUCCESS_NOTIFICATIONS_TIPIS,
  DEFAULT_VALUE_SELECTOR,
  CAMPAIGN_BY_EMAIL_ERROR
} from './constants'

export const isDataTokenCorrect = (dataToken, isSecurizedToken) => {
  const obligatoryFields = isSecurizedToken
    ? DATA_TOKEN_OBLIGATORY_FIELDS_SECURIZED
    : DATA_TOKEN_OBLIGATORY_FIELDS
  let errorCode = null

  obligatoryFields.forEach(obligatoryField => {
    const dataTokenValue = dataToken[obligatoryField]

    if (isNil(dataTokenValue) || isEmpty(dataTokenValue)) {
      errorCode = DATA_TOKEN_ERROR_CODE[obligatoryField]
    }
  })

  return errorCode
}

export const hasCampaignEmailError = () => CAMPAIGN_BY_EMAIL_ERROR

export const buildFieldOption = field =>
  field.reduce((acc, f) => {
    const text = get(f, 'text', '').trim()
    acc.push({ key: text || f, value: text || f, label: text || f })
    return acc
  }, [])

export const onChange = (parentItem, itemSelected, getItem) => {
  const field = get(
    parentItem.find(item => get(item, 'text') === itemSelected),
    getItem,
    [{ text: DEFAULT_VALUE_SELECTOR }],
  ).filter(item => !isEmpty(item))

  const option = buildFieldOption(field)

  return {
    field,
    fieldOptions: option,
  }
}

export const formatNumberMsisdn = (actualValues, newMsisdn) => {
  const reg = /^[0-9\b]+$/
  const actualMsisdn = get(actualValues, 'msisdn')
  if (isNil(newMsisdn) || reg.test(newMsisdn)) {
    return newMsisdn
  }
  return actualMsisdn
}

export const authorizationTokenHeader = (token, sfid) => {
  axiosFactory.setDefaultHeaders(axiosJWT, {
    Authorization: `Bearer ${token}`,
    'X-SFID': sfid,
  })
}

export const getTypeNotification = typeDataNotification =>
  SUCCESS_NOTIFICATIONS_TIPIS.includes(typeDataNotification) ? 'success' : 'error'

export const getIsInsertionNotification = typeDataNotification =>
  INSERTION_NOTIGICATIONS_TIPIS.includes(typeDataNotification)

export const getNameFieldOptions = field => {
  const lastChar = field.slice(-1)
  const regularExp = /^\d*\.?\d*$/

  if (regularExp.test(lastChar)) {
    return `${field.slice(0, -1)}s${lastChar}`
  }

  return `${field}s`
}