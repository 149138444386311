import { LayoutComponent } from 'components/ui'
import { SALES_BASE_PATH, SALES_LEADS_PATH } from 'modules/NewClientSales/constants/index'
import {
  setBasketAccountData,
  setOrderType,
  setSaleSegmentAction,
} from 'modules/NewClientSales/store/actions/index'
import { useSetVista360URL } from 'modules/NewClientSales/hooks/Shared'

import { actions as authActions, useForceSaveAuth } from 'modules/Auth'
import {
  CONVERGENT,
  FIXED_MOBILE,
  ONLY_MOBILE,
  FIXED_ONLY,
  ENERGY,
  LEADS,
} from 'modules/SharedSales/constants'
import { ONLY_FIXED } from 'modules/SharedSales/constants/order'
import { objectToQueryString } from 'modules/url'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AUTONOMO, CONSUMER } from 'services/global-constants'
import { fetchIsCustomerAction, resetIsCustomerAction } from 'modules/CustomerInfo/store'
import { selectEnabledNewClientSale } from 'services/feature-flag/selectors'

import { addSfidParam, createEnergySale } from 'modules/energy/helpers'

import { FlowChooserComponent } from '../../components/FlowChooser'
import { FlowChooserHeader } from '../../components/FlowChooserHeader'
import { FlowChooserSelector } from './FlowChooser.selectors'
import { getFlowButtons } from './FlowChooserButtons'

function EmptyHeader() {
  return <></>
}

export function FlowChooserContainer() {
  // this params are just placeholders for development mode
  const dispatch = useDispatch()
  const {
    selectedQueryParams,
    isD2D,
    carteraSegment,
    isCustomerCanceled,
    customerSaleSegment,
    docType,
    docNumber,
    canEnergySale,
    canAgentAccessPresale,
    ...flags
  } = useSelector(FlowChooserSelector)

  const {
    isCartera,
    isElFijoFut,
    landline2pFlag,
    onlyMobileFlag,
    energyIsAllowed,
    leadPosAllowed,
  } = flags

  const isEnabledNewClientSale = useSelector(selectEnabledNewClientSale) || isCartera

  const [selectedOptions, setSelectedOptions] = useState({})

  const orderType = useMemo(() => {
    const { sale, mobileType } = selectedOptions
    if (sale === CONVERGENT) return CONVERGENT
    if (sale === ONLY_FIXED) return FIXED_ONLY
    if (sale === LEADS) return LEADS
    if (mobileType === ONLY_MOBILE) return ONLY_MOBILE
    return FIXED_MOBILE
  }, [selectedOptions])

  const { vista360URL } = useSetVista360URL(isCartera)
  const [saleFlowURL, setSaleFlowURL] = useState(null)
  const { channel } = selectedQueryParams

  const { saveLatestAccessToken } = useForceSaveAuth()

  const options = useMemo(() => {
    const { mobileType } = selectedOptions
    const isTelesales = channel === 'telesales'
    const isPro = channel ? channel.includes('pro') : false
    const isPos = channel === 'pos'
    const isCustomerSegmentResidential = customerSaleSegment === 'residential'
    const isCustomerSegmentPro = customerSaleSegment === 'pro'
    const isCarteraTelesalesChannel = isTelesales && isCustomerSegmentResidential && !!isCartera
    const isCarteraProChannel = isPro && isCustomerSegmentPro && !!isCartera
    const isCarteraPOSChannel = isPos && !!isCartera
    const opt = {
      mobileType,
      onlyMobileFlag,
      landline2pFlag,
      isCartera: !!isCartera,
      isTelesales,
      isPro,
      isPos,
      isElFijoFut,
      isCustomerSegmentResidential,
      isCustomerSegmentPro,
      isCarteraTelesalesChannel,
      isCarteraProChannel,
      isCarteraPOSChannel,
      matchCarteraChannelAndCustomerSegment:
        isCarteraTelesalesChannel || isCarteraProChannel || isCarteraPOSChannel,
      isD2D,
      energyIsAllowed,
      canEnergySale,
      isCustomerCanceled,
      leadPosAllowed,
      isEnabledNewClientSale,
    }
    return opt
  }, [selectedQueryParams, flags, canEnergySale, isEnabledNewClientSale])

  const buttons = useMemo(() => getFlowButtons(options), [options])

  function getSegmentFormButtons() {
    const selectedButton = buttons.segmentButtons.filter(button => button.selected)
    return selectedButton.length > 0 ? selectedButton[0].value : ''
  }

  function onCreateEnergySale() {
    const redirectUrl = createEnergySale(isCartera ? docNumber : null)

    saveLatestAccessToken()
    window.location.href = addSfidParam(redirectUrl)
  }

  function onSetAccountSegment(value) {
    // if the segment is CONSUMER but the agent has selected pro we have to automatically set AUTONOMO
    // because there is no CONSUMER segment in pro sale
    if (value === 'pro' && carteraSegment === CONSUMER) {
      dispatch(setBasketAccountData({ segment: AUTONOMO }))
    } else {
      dispatch(setBasketAccountData({ segment: carteraSegment }))
    }
  }

  function onSelectSegment(value) {
    onSetAccountSegment(value)
    setSelectedOptions({ ...selectedOptions, saleSegment: value, channel })
  }

  const canSale = useMemo(() => {
    const { sale, mobileType, saleSegment } = selectedOptions

    if (sale === ENERGY || sale === LEADS) {
      return true
    }

    const segment = saleSegment || getSegmentFormButtons()
    if (segment) {
      dispatch(setSaleSegmentAction(segment))
      onSetAccountSegment(segment)
    }
    if (!channel) return false
    if ((sale === CONVERGENT || sale === ONLY_FIXED) && segment) return true
    if (
      sale === ONLY_MOBILE &&
      mobileType &&
      segment &&
      (options.isTelesales || options.isPro || mobileType === FIXED_MOBILE)
    )
      return true

    return false
  }, [selectedOptions])

  function onSelect(value, key) {
    setSelectedOptions({ ...selectedOptions, channel, [key]: value })
  }

  function getRedirectUrl(data) {
    const { mobileType, ...query } = data

    if (data.sale === LEADS) {
      setSaleFlowURL(SALES_LEADS_PATH)
    } else {
      if (mobileType === 'mobile_fixed_line' && data.sale === 'only_mobile') {
        query.sale = mobileType
      }
      const queryString = objectToQueryString({ ...query })
      setSaleFlowURL(`${SALES_BASE_PATH}?${queryString}`)
    }
  }

  function onBackToVista360(resetVista360View) {
    if (resetVista360View) {
      dispatch(authActions.resetDocumentId())
    }
  }

  useEffect(() => {
    dispatch(setOrderType({ orderType }))
    getRedirectUrl({ ...selectedQueryParams, ...selectedOptions })
  }, [selectedOptions])

  useEffect(() => {
    if (isCartera && docNumber && docType) {
      dispatch(fetchIsCustomerAction({ docNumber, docType }))
    } else dispatch(resetIsCustomerAction())
  }, [isCartera, docNumber, docType])

  const mainContent = (
    <FlowChooserComponent
      saleFlowURL={saleFlowURL}
      vista360URL={vista360URL}
      onBackToVista360={() => onBackToVista360(!isCartera)}
      onSelect={onSelect}
      onSelectSegment={onSelectSegment}
      onResetSelect={() => setSelectedOptions({})}
      mobileTypeButtons={buttons.mobileTypeButtons}
      segmentButtons={buttons.segmentButtons}
      saleTypeButtons={buttons.saleTypeButtons}
      saleType={selectedOptions.sale}
      canSale={canSale}
      onCreateEnergySale={onCreateEnergySale}
      canAgentAccessPresale={canAgentAccessPresale && !isCartera}
      isEnabledNewClientSale={isEnabledNewClientSale}
    />
  )

  return (
    <LayoutComponent
      width="100%"
      headerContent={isD2D ? <FlowChooserHeader /> : <EmptyHeader />}
      mainContent={mainContent}
    />
  )
}
