import { createStore, applyMiddleware, compose } from 'redux'
import hardSet from 'redux-persist/lib/stateReconciler/hardSet'
import createSagaMiddleware from 'redux-saga'
import { routerMiddleware } from 'connected-react-router'
import storage from 'redux-persist/lib/storage'
import { history } from 'modules/Router'
import { persistStore, persistReducer } from 'redux-persist'
import createRootReducer from '../reducers'
import { rootSaga } from './root-saga'
import packageJson from '../../../../package.json'

const initialState = {}
const sagaMiddleware = createSagaMiddleware()

const middleware = [sagaMiddleware]
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export default (initial = {}) => {
  const persistConfig = {
    key: `ovid-${packageJson.version}:`,
    storage,
    stateReconciler: hardSet,
    whitelist: ['auth', 'featureFlag'],
  }

  const persistedReducer = persistReducer(persistConfig, createRootReducer(history))

  const store = createStore(
    persistedReducer,
    { ...initial, ...initialState },
    composeEnhancers(applyMiddleware(routerMiddleware(history), ...middleware)),
  )
  const persistor = persistStore(store)

  sagaMiddleware.run(rootSaga)
  return { store, persistor }
}
