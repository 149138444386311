import PropTypes from 'prop-types'
import { map, isEmpty } from 'lodash'

import { Typography, Box, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import { PillAlarm } from 'components/packages/PillAlarm'
import {
  AlertAdvice,
  Button,
  Modal,
  ModalActions,
  SpinnerButton,
  NotificationModal,
} from 'components/ui'

import { Board } from 'modules/vista-360/components/Board'
import { HomeGoSvaDetailsModal } from 'modules/vista-360/components/SubscriptionBundlesInfo/HomeGoSvaDetailsModal'

import { getAlarmTitle, getAlarmDescription, getAlarmSubdescription } from '../../alarms.helpers'

const useStyles = makeStyles({
  'alarm-board': {
    display: ({ isShowing }) => !isShowing && 'none',

    '& .alarms-advice': {
      width: '100%',
      marginBottom: '20px',
    },
  },
})

export const AlarmBoard = ({
  className,
  alarms,
  isLoading,
  hasErrors,
  isHomeGoSvaDetailsOpen,
  setSelectedHomeGoSvaDetails,
  selectedHomeGoSvaDetails,
  isHomeGoSvaConfirmCancelOpen,
  setSelectedHomeGoSvaCancellation,
  onConfirmHomeGoSvaCancellation,
  cancelHomeGoOrderIsLoading,
  cancelHomeGoOrderError,
  cancelHomeGoOrderResultIsOpen,
  onCloseCancelHomeGoOrderResult,
  hasFindHomeGoSvaError,
  findHomeGoSvaInit,
}) => {
  const isShowing =
    (!isLoading && hasErrors) ||
    ((!isLoading && !hasErrors && !isEmpty(alarms)) || hasFindHomeGoSvaError)

  const classes = useStyles({ isShowing })

  return (
    <div className={[classes['alarm-board'], ...(className ? [className] : [])].join(' ')}>
      {!isLoading && hasErrors && (
        <AlertAdvice
          dataHook="alarm-error-msg"
          className="alarms-advice"
          message="Ha habido un error y no podemos mostrar las Alarmas."
          block
        />
      )}

      {hasFindHomeGoSvaError && (
        <AlertAdvice
          dataHook="alarm-error-msg"
          className="alarms-advice"
          message="Ha habido un error al intentar recuperar los datos del SVA de Home GO"
          onRetry={findHomeGoSvaInit}
          block
        />
      )}

      {!isLoading && !hasErrors && !isEmpty(alarms) && (
        <Board data-hook="alarm-board">
          {map(alarms, (alarm, i) => {
            const alarmTitle = getAlarmTitle(alarm)

            const { type, subType, level, icon, actions } = alarm

            return (
              <Grid item xs={4} key={`${alarmTitle} - ${i}`}>
                <PillAlarm
                  id={`${alarmTitle} - ${i}`}
                  dataHook={`pill-alarm-${type}-${subType}-${level} - ${i}`}
                  title={alarmTitle}
                  subtitle={getAlarmDescription(alarm)}
                  description={getAlarmSubdescription(alarm)}
                  status={level}
                  icon={icon}
                  actions={actions}
                />
              </Grid>
            )
          })}

          {isHomeGoSvaDetailsOpen && (
            <HomeGoSvaDetailsModal
              isOpen={isHomeGoSvaDetailsOpen}
              setIsOpen={() => setSelectedHomeGoSvaDetails(null)}
              title={`Contrato de alarma Home GO - ${selectedHomeGoSvaDetails.msisdn}`}
              data={selectedHomeGoSvaDetails}
            />
          )}

          <Modal
            title="Confirmar cancelación"
            isOpen={isHomeGoSvaConfirmCancelOpen}
            onClose={
              !cancelHomeGoOrderIsLoading ? () => setSelectedHomeGoSvaCancellation(false) : null
            }>
            <div>
              <Typography variant="body1" style={{ marginBottom: '15px' }}>
                ¿Estás seguro que deseas cancelar este paquete de alarma?
              </Typography>
            </div>

            <ModalActions>
              {cancelHomeGoOrderIsLoading && <SpinnerButton />}

              {!cancelHomeGoOrderIsLoading && (
                <Button onClick={onConfirmHomeGoSvaCancellation}>Aceptar</Button>
              )}
              <Button
                onClick={() => setSelectedHomeGoSvaCancellation(false)}
                disabled={cancelHomeGoOrderIsLoading}
                secondary>
                Cancelar
              </Button>
            </ModalActions>
          </Modal>

          <NotificationModal
            isOpen={cancelHomeGoOrderResultIsOpen}
            type={cancelHomeGoOrderError ? 'alert' : 'success'}
            src={cancelHomeGoOrderError ? '/assets/error.svg' : '/assets/clap_clap.svg'}
            title={
              cancelHomeGoOrderError ? 'Error cancelando la alarma' : 'Pedido de alarma cancelado!'
            }
            message={cancelHomeGoOrderError?.message}
            onClose={onCloseCancelHomeGoOrderResult}>
            {cancelHomeGoOrderError && !cancelHomeGoOrderError?.message && (
              <>
                <Box mb="10px">
                  <Typography variant="body1">
                    Se ha producido un fallo, por favor inténtalo más tarde.
                  </Typography>
                </Box>
              </>
            )}

            <Button data-hook="action-accept" onClick={onCloseCancelHomeGoOrderResult}>
              Cerrar
            </Button>
          </NotificationModal>
        </Board>
      )}
    </div>
  )
}

AlarmBoard.propTypes = {
  className: PropTypes.string,
  alarms: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      subType: PropTypes.string,
      level: PropTypes.string,
      icon: PropTypes.string,
      actions: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          onClick: PropTypes.func,
        }),
      ),
      title: PropTypes.string,
      subdescription: PropTypes.string,
    }),
  ),
  isLoading: PropTypes.bool,
  hasErrors: PropTypes.bool,
  isHomeGoSvaDetailsOpen: PropTypes.bool,
  setSelectedHomeGoSvaDetails: PropTypes.func,
  selectedHomeGoSvaDetails: PropTypes.object,
  isHomeGoSvaConfirmCancelOpen: PropTypes.bool,
  setSelectedHomeGoSvaCancellation: PropTypes.func,
  onConfirmHomeGoSvaCancellation: PropTypes.func,
  cancelHomeGoOrderIsLoading: PropTypes.bool,
  cancelHomeGoOrderError: PropTypes.object,
  cancelHomeGoOrderResultIsOpen: PropTypes.bool,
  onCloseCancelHomeGoOrderResult: PropTypes.func,
  hasFindHomeGoSvaError: PropTypes.bool,
  findHomeGoSvaInit: PropTypes.func,
}
