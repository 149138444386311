import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'
import { find, get, isEmpty, chain } from 'lodash'
import { actions as provisioning } from 'services/provisioning'
import {
  actions as ChangeTechnology,
  addRequestType,
  addInstallerInfo,
} from 'services/change-technology'
import {
  selectTariffsApigeeDataTariffs,
  isFtthTariff,
  selectTariffApigeeOneTariff,
} from 'modules/tariffs/store-apigee'
import { getCustomerInfo } from 'services/global-selectors/selectors'
import { getSubscriptionByCurrentProductId } from 'services/subscriptions/selectors'
import { selectors as selectorsCustomer } from 'services/customer-new'
import { checkIsNeba, getGescal37byContactMedium, checkIsCommercialVula } from 'modules/Coverage'
import { NEBA_FAKE_TERRITORY, STATUS_ACTIVE_HIDDEN } from 'modules/Coverage/constants'
import { fillMoveAddress, fillMoveNumber } from '../../components/MoveClient/move-order.helper'
import { ModalMove } from '../../components/ModalMove'
import { ClientOffers } from '../../components/ClientOffers'
import { Modal } from '../../components/Modal'
import { initMoveClientSummarySagaAction } from './get-tariffs-offers-saga'
import { getCoverageDisponibility } from './utils'
import {
  replaceCustomerToCustomerNew,
  modelSubscriptionProduct,
} from '../../helpers/formats.helper'

class MoveClientSummaryContainer extends Component {
  state = {
    changeAddressJson: this.props.changeAddressJson,
    orderSent: false,
  }

  componentDidMount() {
    const { coverageData, changeAddressJson, getTariffsOffers, segment } = this.props
    if (!isEmpty(coverageData)) {
      // eslint-disable-next-line
      this.setState({
        changeAddressJson: fillMoveAddress(coverageData, changeAddressJson),
      })
    }
    const territoryOwner = get(coverageData, 'coverage[0].characteristics[0].value')
    const territoryOwnerFilterTariff = checkIsNeba(territoryOwner)
      ? NEBA_FAKE_TERRITORY
      : territoryOwner
    const coverage = get(coverageData, 'coverage[0]')
    const disponibility = getCoverageDisponibility(coverage)

    if (!isEmpty(disponibility)) {
      getTariffsOffers({
        segment,
        territoryOwner: territoryOwnerFilterTariff,
        fixedLineType: disponibility,
        status: STATUS_ACTIVE_HIDDEN,
      })
    } else {
      getTariffsOffers({ segment, territoryOwner: territoryOwnerFilterTariff })
    }
  }

  componentDidUpdate(nextProps) {
    const { phoneNumber } = this.props
    const { changeAddressJson } = this.state
    if (nextProps.phoneNumber !== phoneNumber) {
      if (!isEmpty(phoneNumber)) {
        this.setState({
          changeAddressJson: fillMoveNumber(phoneNumber, changeAddressJson),
        })
      }
    }
  }

  checkAvailability = () => {
    const { offersLoading, offers, customer } = this.props
    if (offersLoading) return true
    return offers.indexOf(customer.products[0].type) > 1
  }

  checkProvince = () => {
    const { coverageData, address } = this.props
    const desiredPlace = coverageData.zipCode
    const currentPlace = get(
      find(address, elem => elem.type === 'InstallationAddressFixedLine'),
      'medium.postCode',
      '',
    )
    return desiredPlace.substring(0, 2) === currentPlace.substring(0, 2)
  }

  checkSameAddress = () => {
    const { coverageData, address } = this.props
    const nextAddress = coverageData.gescal
    const currentAddress = getGescal37byContactMedium(address)

    return nextAddress === currentAddress
  }

  checkTariffDisponibility = () => {
    const { customerWithProductUpdated, tariffs } = this.props
    const tariff = get(customerWithProductUpdated, 'products[0].type', '')
    return find(tariffs, { id: tariff })
  }

  sendOrder = data => {
    const { customerNew, isFeatNebaEnabled, segment } = this.props
    this.setState({ orderSent: true })
    const dataWithSegment = chain([data])
      .map(el => ({
        ...el,
        characteristics: el.characteristics.map(charac => {
          if (charac.name === 'segment') {
            return {
              ...charac,
              value: segment,
            }
          }
          return charac
        }),
      }))
      .head()
      .value()

    let dataWithFillCustomer = replaceCustomerToCustomerNew(dataWithSegment, customerNew)
    if (checkIsNeba(this.getTerritoryOwner()) && isFeatNebaEnabled) {
      dataWithFillCustomer = addRequestType(dataWithFillCustomer, '-neba')
    }
    if (this.props.FFlagInstaller) {
      dataWithFillCustomer = addInstallerInfo(
        dataWithFillCustomer,
        this.props.installer,
        this.props.installConnectionType,
      )
    }
    this.props.sendOrder(dataWithFillCustomer)
  }

  getTerritoryOwner = () => {
    return chain(this.props.coverageData)
      .get('coverage')
      .head()
      .get('characteristics')
      .find({ name: 'territoryOwner' })
      .get('value')
      .value()
  }

  render() {
    const phoneNumber = get(this.props.phoneNumber, 'number')
    const territoryOwner = this.getTerritoryOwner()
    return (
      <div>
        <Modal
          open={this.checkSameAddress()}
          title="Aviso Importante!"
          message="No se puede realizar un traslado a la misma dirección"
          cancelBtn="Salir"
          quit
        />
        <ClientOffers
          newSubscription={this.checkProvince() || phoneNumber}
          tariffs={this.props.tariffs}
          currentTariff={this.props.customerWithProductUpdated.products[0]}
          customer={this.props.customerWithProductUpdated}
          changeTechnologyJson={this.state.changeAddressJson}
          sendOrder={data => this.sendOrder(data)}
          orderId={this.props.order}
          loadingOrder={this.props.loadingOrder}
          phoneNumber={phoneNumber}
          enrichment={this.props.enrichment}
          available={this.checkAvailability()}
          customerInfo={this.props.customerInfo}
          loading={this.props.offersLoading}
          isFeatNebaEnabled={this.props.isFeatNebaEnabled}
          coverageData={this.props.coverageData}
          territoryOwner={this.getTerritoryOwner()}
          isFtth={this.props.isFtth}
          showAppointment={!(this.props.isFeatNebaEnabled && checkIsNeba(territoryOwner))}
          installer={this.props.installer}
        />
        {!this.props.orderId ? (
          <ModalMove
            orderSent={this.state.orderSent}
            available={this.checkAvailability()}
            loadingOrder={this.props.loadingOrder}
            sendOrder={() => this.props.sendOrder(this.state.changeAddressJson)}
            changeTechnologyJson={this.state.changeAddressJson}
            isSameProvince={this.checkProvince()}
            loading={this.props.loadingProvisioning}
            onOrder={() => this.props.getNewLandLine(this.props.coverageData.zipCode)}
            phoneNumber={phoneNumber}
            sameTariff={this.checkTariffDisponibility()}
            loadingOffer={this.props.offersLoading}
            isNebaTO={checkIsNeba(territoryOwner)}
            isFeatNebaEnabled={this.props.isFeatNebaEnabled}
            isCommercialVulaTO={checkIsCommercialVula(territoryOwner)}
          />
        ) : null}
      </div>
    )
  }
}

MoveClientSummaryContainer.propTypes = {
  changeAddressJson: PropTypes.object,
  coverageData: PropTypes.object,
  getTariffsOffers: PropTypes.func,
  segment: PropTypes.string,
  phoneNumber: PropTypes.object,
  offersLoading: PropTypes.bool,
  offers: PropTypes.array,
  customer: PropTypes.object,
  address: PropTypes.array,
  customerWithProductUpdated: PropTypes.object,
  tariffs: PropTypes.array,
  customerNew: PropTypes.object,
  isFeatNebaEnabled: PropTypes.bool,
  FFlagInstaller: PropTypes.bool,
  installer: PropTypes.object,
  installConnectionType: PropTypes.string,
  sendOrder: PropTypes.func,
  order: PropTypes.string,
  orderId: PropTypes.string,
  loadingOrder: PropTypes.bool,
  enrichment: PropTypes.object,
  customerInfo: PropTypes.object,
  isFtth: PropTypes.bool,
  loadingProvisioning: PropTypes.bool,
  getNewLandLine: PropTypes.func,
}
const mapDispatchToProps = dispatch => ({
  getNewLandLine: zipCode => dispatch(provisioning.findProvisioning(zipCode)),
  getTariffsOffers: filters => dispatch(initMoveClientSummarySagaAction(filters)),
  sendOrder: changeTechnologyData => {
    dispatch(ChangeTechnology.createChangeTechnology({ changeTechnologyData }))
    dispatch(ChangeTechnology.saveOperation({ changeTechnologyData }))
  },
})

const mapStateToProps = state => {
  const subscription = getSubscriptionByCurrentProductId(state)
  const customer = get(state, 'customerNew.data', {})
  const isFtth = isFtthTariff(selectTariffApigeeOneTariff(get(subscription, 'type'))(state))

  return {
    address: state.productOrders.data.customer.contactMedium,
    customer,
    tariffs: selectTariffsApigeeDataTariffs(state),
    phoneNumber: state.provisioning.data,
    loadingProvisioning: get(state.provisioning, 'loading'),
    penalty: get(state.productPenalizations, 'data', []),
    enrichment: get(state.enrichment, 'data', []),
    offers: get(state.offers, 'data.tariffsId', []),
    offersLoading: get(state.offers, 'loading'),
    order: get(state.changeTechnology, 'data.orderId'),
    loadingOrder: get(state.changeTechnology, 'loading'),
    customerInfo: getCustomerInfo('InstallationAddressFixedLine')(state),
    customerNew: get(state, 'customerNew.data', {}),
    segment: selectorsCustomer.getSegment(state),
    subscription,
    isFtth,
    customerWithProductUpdated: modelSubscriptionProduct(customer, subscription),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MoveClientSummaryContainer)
