const environtments = ['local', 'development', 'staging', 'production']

const env =
  environtments.find(environment => environment.indexOf(process.env.REACT_APP_ENV) !== -1) ||
  'development'

// eslint-disable-next-line import/no-dynamic-require
const { config } = require(`./${env}`)

module.exports = { APP_CONFIG: config }
