import { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { find, concat, compact } from 'lodash'

import { useDispatch, useSelector } from 'react-redux'

import { makeStyles } from '@material-ui/styles'
import { Typography, Grid } from '@material-ui/core'

import { Modal, ModalActions, Button, SpinnerCenter } from 'components/ui'

import { fetchDeviceSpecifications } from '../../store/device-renewal.actions'
import { deviceSpecificationsSelectors } from './DeviceSpecificationsModal.selectors'

const useStyles = makeStyles(theme => ({
  'device-modal-specifications': {
    '& h6': {
      fontWeight: 'bold',
      color: theme.palette.global.gray_dark,
    },

    '& .more-featured, & .technical-specifications': {
      marginTop: '12px',

      '& > div': {
        paddingTop: 0,
      },

      '& label': {
        fontWeight: 'bold',
      },

      '& label, & p': {
        color: theme.palette.global.gray_dark,
      },
    },

    '& .more-featured': {
      marginBottom: '10px',
    },
  },
}))

const IGNORE_SPECS = ['color_es', 'color_hexa', 'Tarifa pospago recomendada']

const SPECIFICATION_GROUPS_ORDER = [
  'Generales',
  'Pantalla',
  'Cámara',
  'Memoria',
  'SIM',
  'Batería y carga',
  'Dimensiones y peso',
  'Otros',
]

const DeviceSpecification = ({ label, value, block }) => (
  <Grid item xs={block ? 12 : 6}>
    <Typography variant="subtitle2" component="label">
      {label}
    </Typography>
    <Typography variant="subtitle2" component="p">
      {value}
    </Typography>
  </Grid>
)

DeviceSpecification.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  block: PropTypes.bool,
}

export const DeviceSpecificationsModal = ({ id, name, open, onClose }) => {
  const classes = useStyles()

  const { specifications = null, hasError, isLoading } = useSelector(
    deviceSpecificationsSelectors(id),
  )

  const removeExcludedSpecifications = specs =>
    specs.filter(spec => !IGNORE_SPECS.includes(spec.name))

  const removeExcludedSpecificationsFromGroups = specificationGroups =>
    specificationGroups.map(specGroup => ({
      ...specGroup,
      specifications: removeExcludedSpecifications(specGroup.specifications),
    }))

  const dispatch = useDispatch()

  const specificationGroupsOrderWithIndexes = useMemo(
    () =>
      SPECIFICATION_GROUPS_ORDER.map((specificationGroup, index) => {
        return {
          name: specificationGroup,
          index,
        }
      }),
    [],
  )

  const orderSpecificationsGroups = (specificationsGroups, specificationGroupsOrder) => {
    const orderedGroups = new Array(specificationGroupsOrder.length)
    const unknownGroups = []

    specificationsGroups.forEach(specificationGroup => {
      const currentGroupOrder = find(specificationGroupsOrder, ['name', specificationGroup.name])
      if (currentGroupOrder) {
        orderedGroups[currentGroupOrder.index] = specificationGroup
      } else {
        unknownGroups.push(specificationGroup)
      }
    })

    return concat(compact(orderedGroups), [...unknownGroups])
  }

  const filteredSpecificationsGroups = useMemo(() => {
    let groups = []

    if (specifications?.length) {
      groups = orderSpecificationsGroups(
        removeExcludedSpecificationsFromGroups(specifications),
        specificationGroupsOrderWithIndexes,
      )
    }

    return groups
  }, [specifications])

  useEffect(() => {
    if (!specifications && open) {
      dispatch(fetchDeviceSpecifications(id))
    }
  }, [id, specifications, open])

  return open ? (
    <Modal
      data-hook="device-modal-specifications"
      title={`Características ${name}`}
      className={classes['device-modal-specifications']}
      onClose={onClose}
      isOpen>
      {(isLoading || hasError) && <SpinnerCenter showMsg />}
      {!isLoading && !hasError && (
        <>
          {filteredSpecificationsGroups.map(specGroup => (
            <div key={specGroup.name}>
              <Typography variant="subtitle1">{specGroup.name.toUpperCase()}</Typography>
              <Grid container className="more-featured" spacing={4}>
                {specGroup.specifications.map(spec => (
                  <DeviceSpecification label={spec.name} value={spec.value} key={spec.name} />
                ))}
              </Grid>
            </div>
          ))}

          <ModalActions>
            <Button onClick={onClose}>Entendido</Button>
          </ModalActions>
        </>
      )}
    </Modal>
  ) : null
}

DeviceSpecificationsModal.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
}
