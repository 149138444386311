import styled from 'styled-components'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import {
  MenuItem,
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  OutlinedInput,
} from '@material-ui/core'

const CustomFormControl = styled(({ minWidth, ...rest }) => <FormControl {...rest} />)`
  position: relative;
  min-width: ${props => props.minWidth || '120px'};
`

const CustomInputLabel = styled(({ maxWidth, ...rest }) => <InputLabel {...rest} />)`
  pointer-events: none;
  background: white;
  color: #646464;
  padding: 0 2px;
  line-height: 1;
  white-space: nowrap;
  max-width: ${props => props.maxWidth || 'calc(100% - 78px)'};
  overflow: hidden;
  text-overflow: ellipsis;
`

const CustomSelect = styled(Select)`
  em,
  span {
    color: #646464;
  }
  span + span {
    margin-left: 20px;
  }
  .CustomSelect {
    border-color: ${props => get(props, 'theme.palette.global.gray')};
    &:focus {
      border-color: ${props => get(props, 'theme.palette.brand.action_primary')};
    }
  }
  svg {
    right: 8px;
  }
`

const CustomMenuItem = styled(MenuItem)`
  justify-content: space-between;
  color: #646464;
  span + span {
    margin-left: 20px;
  }
`

const FormSelectOutlined = props => {
  const {
    autoWidth,
    children,
    className,
    error,
    errorMessage,
    id,
    label,
    name,
    onChange,
    options,
    value,
    minWidth,
    maxWidth,
    dataHook,
    ...restProps
  } = props

  return (
    <CustomFormControl
      data-hook={dataHook || `select-${label}`}
      variant="outlined"
      error={error}
      className={className}
      minWidth={minWidth}
      {...restProps}>
      {label ? (
        <CustomInputLabel maxWidth={maxWidth} htmlFor={id}>
          {label}
        </CustomInputLabel>
      ) : null}
      <CustomSelect
        MenuProps={{ 'data-hook': `menu-${name}` }}
        data-hook={dataHook ? `custom-select-${dataHook}` : `custom-select-${label}`}
        displayEmpty
        value={value}
        onChange={onChange}
        classes={{ select: 'CustomSelect' }}
        input={<OutlinedInput name={name} id={id} labelWidth={0} />}
        autoWidth={autoWidth}>
        {label && (
          <CustomMenuItem value="none" disabled>
            <em>{label}</em>
          </CustomMenuItem>
        )}
        {options.map(option => (
          <CustomMenuItem key={option.poId || option.value} value={option.poId || option.value}>
            {children(option)}
          </CustomMenuItem>
        ))}
      </CustomSelect>
      {error && errorMessage ? <FormHelperText>{errorMessage}</FormHelperText> : null}
    </CustomFormControl>
  )
}

FormSelectOutlined.defaultProps = {
  error: false,
  children: option => option.text,
}

FormSelectOutlined.propTypes = {
  autoWidth: PropTypes.bool,
  name: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string,
  minWidth: PropTypes.string,
  maxWidth: PropTypes.string,
  children: PropTypes.func,
  error: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      text: PropTypes.string,
    }),
  ),
  value: PropTypes.string,
  dataHook: PropTypes.string,
}

export default FormSelectOutlined
