import { useSelector } from 'react-redux'
import { get, isUndefined } from 'lodash'

import { useOrderChannelAndStatus } from 'modules/offers-configuration/hooks/useOrderChannelAndStatus/useOrderChannelAndStatus'
import { selectIpAddress, selectSfid } from 'modules/Auth/store/selectors'
import { selectAccountId, selectBillingId } from 'modules/CustomerInfo/store/customerInfo.selectors'
import {
  COVERAGE_TOKEN,
  NEXT_DAY,
  ADDRESS_CHANGE,
  COMMERCIAL_MIGRATION,
} from 'modules/offers-configuration/constants'
import { INSTALLER_DEFAULT } from 'modules/Installations/constants'
import { selectExternalId } from 'modules/Coverage/store/selectors'
import { getActivationDate } from 'modules/offers-configuration/helpers'

export const useChangeTechnologyOptions = (
  filteredEnrichTariffs,
  coverageTerritoryOwner,
  subscriptionId,
  selectedPoIdTariff,
  selectedTariffValue,
  salesType,
  { coverageAddressId, coverageToken, changeType, installer, connectionType, isIndirectFiber } = {},
) => {
  const { orderChannel } = useOrderChannelAndStatus()

  const sfid = useSelector(selectSfid)
  const ipAddress = useSelector(selectIpAddress)
  const accountId = useSelector(selectAccountId)
  const billingId = useSelector(selectBillingId)
  const externalId = useSelector(selectExternalId(subscriptionId))
  const tariffSelected = filteredEnrichTariffs.find(tariff => tariff.poId === selectedTariffValue)
  const bypass = get(tariffSelected, 'status') === 'retired'
  const speed = get(tariffSelected, 'internetDownloadSpeed')
  const activationStamp = getActivationDate(NEXT_DAY.value)

  let inputtedCharacteristics = {
    CH_Fixed_Coverage_Token: coverageToken || COVERAGE_TOKEN,
    CH_Fixed_Territory_Owner: coverageTerritoryOwner,
    CH_Broadband_Estimated_Speed: speed,
  }

  if (bypass) {
    inputtedCharacteristics = {
      ...inputtedCharacteristics,
      CH_Bypass_STC_Rules: `${bypass}`,
    }
  }

  if (coverageAddressId) {
    inputtedCharacteristics = {
      ...inputtedCharacteristics,
      CH_New_Address_Id: coverageAddressId,
    }
  }

  if (changeType !== COMMERCIAL_MIGRATION && isIndirectFiber && salesType !== ADDRESS_CHANGE) {
    let connectionTypeAndInstaller = INSTALLER_DEFAULT

    if (!isUndefined(installer) && !isUndefined(connectionType)) {
      connectionTypeAndInstaller = {
        CH_Installation_Point_Provider: installer,
        CH_Installation_Point_Type: connectionType,
      }
    }

    inputtedCharacteristics = {
      ...inputtedCharacteristics,
      ...connectionTypeAndInstaller,
    }
  }

  let options = {
    sfid,
    channel: orderChannel,
    ipAddress,
    salesType,
    customerAccountId: accountId,
    billingAccountId: billingId,
    targetAgreementId: subscriptionId,
    productOfferingId: selectedPoIdTariff,
    activationStamp,
    inputtedCharacteristics,
    externalId,
  }

  if (changeType) {
    options = {
      ...options,
      changeType,
    }
  }

  return options
}
