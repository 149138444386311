import { useEffect, useState, useMemo, useRef } from 'react'

import { useSelector } from 'react-redux'
import { useFormikContext } from 'formik'
import { find, get, isEmpty, some, partition, head } from 'lodash'

import { fetchAddtionalTariffsWithoutDiscounts } from 'modules/tariffs'
import { formatTariffName, formatMobileTariff } from 'modules/tariffs/helpers'
import { selectIsSubscriptionQueryParam } from 'modules/Router/store/index'
import { selectBusyDuoLines, selectDuoLimitLoading } from 'modules/CustomerOrders'
import { selectSaleTax } from 'modules/NewClientSales/store/selectors/index'
import { useResetLine, useSetFormikValuesToBasket, useChannelAndSegment } from '../Shared'

export function useOnCheckDUOTariffs({
  index,
  tariffList,
  selectedTariff,
  fieldNames,
  lineType,
  isAdditionalType,
  isNewTerminal,
  ps,
  isLoadingTariffs,
  canOnlyShowDuo,
  shouldDisableTerminal,
}) {
  const { channel } = useChannelAndSegment()
  const tax = useSelector(selectSaleTax)
  const { onResetSingleLine } = useResetLine(fieldNames)
  const setFormikValuesToBasket = useSetFormikValuesToBasket()
  const [isLoading, setIsLoading] = useState()
  const isSubscription = useSelector(selectIsSubscriptionQueryParam)
  const busyDuoLines = useSelector(selectBusyDuoLines)
  const isBusyDuoLoading = useSelector(selectDuoLimitLoading)
  const maxCardinality = useRef(0)

  const previousTariff = useMemo(
    () =>
      tariffList.find(
        tariff => tariff.commercialInformation?.name === `${selectedTariff.name} Adicional`,
      ) || selectedTariff,
    [tariffList, selectedTariff],
  )

  const { setFieldValue, values } = useFormikContext()
  const extraMobileLinesOffers = get(values, 'extraMobileLinesOffers', [])

  const selectedTariffs = extraMobileLinesOffers
    .map(elem => elem.tariff?.poId)
    .filter((elem, i) => i !== index)

  function onChangeTariff(tariff) {
    if (tariff?.ps !== selectedTariff.ps) {
      onResetSingleLine(shouldDisableTerminal)
      // save values in formik
      setFieldValue(`${lineType}.tariff`, tariff)
      // save values in store
      const newValues = extraMobileLinesOffers.map((offer, i) => ({
        ...offer,
        tariff: index === i ? tariff : offer.tariff,
      }))
      setFormikValuesToBasket('extraMobileLinesOffers', newValues)
    }
  }

  // checks if all the required tariffs have been selected
  function onCheckIfRequiredIsFilled(tariff, tariffArr) {
    const requiredPo = get(tariff, 'requiredPo', [])

    const { max } = tariff.cardinality
    if (max !== null && max <= 0) return false
    if (isEmpty(requiredPo)) return true

    const requiredTariff = find(tariffArr, { poId: head(requiredPo) })

    const areSelected =
      some(requiredPo, elem => selectedTariffs.includes(elem)) ||
      requiredTariff.cardinality.max <= 0
    return areSelected
  }

  const formatedDuos = useMemo(() => {
    const [extras, noExtras] = partition(tariffList || [], elem => elem.tariffType === 'extra_line')
    const sortedExtrasByRequiredPo = extras.sort(
      (a, b) => a.requiredPo.length - b.requiredPo.length,
    )

    let availableToSubsctract = busyDuoLines
    maxCardinality.current = 0
    const cardinalityAdjusted = sortedExtrasByRequiredPo.map(tariff => {
      const { max } = tariff.cardinality

      const tariffWithCardinalityFormated = {
        ...tariff,
        cardinality: {
          ...tariff.cardinality,
          max: availableToSubsctract ? max - availableToSubsctract : max,
        },
      }

      availableToSubsctract -= max

      maxCardinality.current += max
      return tariffWithCardinalityFormated
    })

    return isSubscription ? [...noExtras, ...cardinalityAdjusted] : tariffList
  }, [tariffList, busyDuoLines, isSubscription])

  // checks if the selectedTariff complies with the requirements, if not it is deleted from the array

  const availableDuoLines = useMemo(() => {
    return formatedDuos
      .filter(tariff => tariff.tariffType === 'extra_line')
      .map(extraLine => extraLine.poId)
  }, [formatedDuos])

  const numberOfSelectedDuos = useMemo(() => {
    if (selectedTariffs && availableDuoLines) {
      return selectedTariffs.filter(tariff => availableDuoLines.includes(tariff)).length
    }
    return 0
  }, [selectedTariffs, availableDuoLines])

  // checks if the maximum of available selected tariffs of the same type have been reached
  function onCheckIfMaxCardinalityReached({ poId, cardinality }) {
    if (
      maxCardinality.current - busyDuoLines - numberOfSelectedDuos <= 0 &&
      availableDuoLines.includes(poId)
    ) {
      return false
    }

    const max = get(cardinality, 'max', null)

    if (!max || max <= 0) return true

    let count = 0
    selectedTariffs.forEach(po => {
      count += po === poId ? 1 : 0
    })

    return count < max
  }

  const formatedTariffList = useMemo(() => {
    return formatedDuos
      .filter(elem =>
        canOnlyShowDuo && (selectedTariff.tariffType === 'extra_line' || !selectedTariff.id)
          ? elem.tariffType === 'extra_line'
          : elem,
      )
      .filter(tariff => {
        return tariff?.poId === selectedTariff?.poId
          ? onCheckIfRequiredIsFilled(tariff, formatedDuos)
          : onCheckIfMaxCardinalityReached(tariff) &&
              onCheckIfRequiredIsFilled(tariff, formatedDuos)
      })
  }, [formatedDuos, selectedTariff])

  const isLoadingState =
    isLoading || isLoadingTariffs || (isSubscription && isBusyDuoLoading !== false)

  const finalTariffWithSelected = formatedTariffList.map(elem =>
    elem.psId === ps ? selectedTariff : elem,
  )

  const tariffOptions = finalTariffWithSelected.map((tariff, i) => ({
    key: `${tariff?.id}${i}`,
    value: tariff?.id,
    label: formatTariffName(tariff, tax),
  }))

  // logic to fetch tariffs without discounts
  useEffect(() => {
    if (isNewTerminal && isAdditionalType && ps) {
      setIsLoading(true)
      fetchAddtionalTariffsWithoutDiscounts({
        mainTariffPsId: ps,
        channel,
      }).then(res => {
        const formatedTariff = formatMobileTariff(res, tax)
        setIsLoading(false)
        setFieldValue(fieldNames.TARIFF, formatedTariff)
        setFormikValuesToBasket(fieldNames.TARIFF, formatedTariff)
      })
    } else if (!isNewTerminal && ps === previousTariff?.psId) {
      setFieldValue(fieldNames.TARIFF, previousTariff)
      setFormikValuesToBasket(fieldNames.TARIFF, previousTariff)
    }
  }, [isNewTerminal])

  return {
    tariffOptions,
    availableTariffs: formatedTariffList,
    onChangeTariff,
    isLoadingState,
  }
}
