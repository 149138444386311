import { get } from 'lodash'
import { coverageActionTypes } from './actions'

export const reducer = (state = {}, action) => {
  const { type, payload } = action

  switch (type) {
    case coverageActionTypes.FIND_COVERAGE: {
      return {
        ...state,
        loading: true,
      }
    }
    case coverageActionTypes.FIND_COVERAGE_SUCCESS: {
      return {
        ...state,
        data: payload.data,
        loading: false,
      }
    }
    case coverageActionTypes.FIND_COVERAGE_FAILED: {
      return {
        ...state,
        error_code: payload.httpCode,
        error_message: payload.info,
        loading: false,
      }
    }
    case coverageActionTypes.COVERAGE_RESET: {
      return {
        ...state,
        data: {},
        error_code: null,
        error_message: null,
        loading: false,
      }
    }
    case coverageActionTypes.GET_COVERAGE_TOKEN: {
      return {
        ...state,
        loading_token: true,
      }
    }
    case coverageActionTypes.GET_COVERAGE_TOKEN_SUCCESS: {
      return {
        ...state,
        token: get(payload.token, 'token'),
        loading_token: false,
      }
    }
    case coverageActionTypes.GET_COVERAGE_TOKEN_FAILED: {
      return {
        ...state,
        error: payload.error,
        loading_token: false,
      }
    }
    case coverageActionTypes.GET_COVERAGE_DATA_SUCCESS: {
      return {
        ...state,
        data: payload.data,
        loading: false,
      }
    }

    case coverageActionTypes.GET_COVERAGE: {
      return {
        ...state,
        loading: true,
      }
    }

    case coverageActionTypes.GET_COVERAGE_SUCCESS: {
      return {
        ...state,
        data: payload.coverage,
        loading: false,
      }
    }

    case coverageActionTypes.GET_COVERAGE_RESET: {
      return {
        ...state,
        loading: false,
        error_code: null,
        error_message: null,
        data: {},
      }
    }

    case coverageActionTypes.GET_ACCESS_TOKEN: {
      return {
        ...state,
        accessToken: {
          loading: true,
        },
      }
    }

    case coverageActionTypes.GET_ACCESS_TOKEN_SUCCESS: {
      return {
        ...state,
        accessToken: {
          loading: false,
          ...payload.accessToken,
        },
      }
    }

    case coverageActionTypes.GET_ACCESS_TOKEN_FAILED: {
      return {
        ...state,
        accessToken: {
          loading: false,
          error_code: payload.error.httpCode,
          error_msg: payload.error.info,
        },
      }
    }

    case coverageActionTypes.SET_COVERAGE: {
      const { token, data } = payload
      return {
        ...state,
        loading: false,
        loading_token: false,
        token,
        data,
      }
    }

    default:
      return state
  }
}
