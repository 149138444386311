import { createSelector } from 'reselect'
import { selectBillingDifferencesFlag } from 'services/feature-flag/selectors'

import {
  getLastMonthsBills,
  getBillsWithRowsForMsisdn,
  getLostedBillRows,
} from '../billings.helpers'
import { BILLING_GROUPS } from '../billings.constants'

const selectBillings = state => state.billings || {}

export const selectBillingsData = createSelector(
  selectBillings,
  billings => billings.data || [],
)

export const selectBillingsError = createSelector(
  selectBillings,
  billings => billings.error,
)

export const selectBillingsErrorCode = createSelector(
  selectBillingsError,
  error => error && error.code,
)

export const selectBillingsErrorMsg = createSelector(
  selectBillingsError,
  error => error && error.info,
)

export const selectBillingsIsLoading = createSelector(
  selectBillings,
  billings => billings.loading,
)

export const selectLostedDiscounts = createSelector(
  [selectBillingsData, selectBillingDifferencesFlag, (state, msisdn) => msisdn],
  (billingData, billingDifferencesFlag, msisdn) => {
    if (!billingDifferencesFlag) {
      return []
    }

    const [pastMonthBill, lastBill] = getBillsWithRowsForMsisdn(
      getLastMonthsBills(billingData),
      msisdn,
    )

    if (!pastMonthBill || !lastBill) {
      return []
    }

    const lostedRows = getLostedBillRows(lastBill.rows, pastMonthBill.rows)

    return lostedRows.filter(
      row => row.billingItem.group.code === BILLING_GROUPS.DISCOUNTS && row.diffTotal > 0,
    )
  },
)
