import { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { isEqual } from 'lodash'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { selectNewAppointmentQw } from 'modules/NewClientSales/store/selectors'
import { selectNewCitation } from 'services/feature-flag/selectors'
import { KairosAppointmentModal } from 'modules/kairos-appointment/components/KairosAppointmentModal'
import ShowAppointmentLink from './ShowAppointmentLink'
import DownloadResume from './DownloadResume'

const mapStateToProps = state => ({
  newAppointment: selectNewAppointmentQw(state),
  newCitation: selectNewCitation(state),
})

const useStyles = () => ({
  'alert-dialog-order': {
    zIndex: props => props.appointment && '1500 !important',
  },
})

class Modal extends Component {
  state = {
    open: this.props.open,
    propsAppointmentModal: {},
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.open, this.props.open)) {
      this.setState({ open: this.props.open })
    }
  }

  handleSuccess = () => {
    this.setState({
      open: false,
    })
  }

  sendOrder = orderData => {
    this.props.sendOrder(orderData)
    this.setState({
      open: false,
    })
  }

  handleCancel = () => {
    this.props.handleClose()
    this.setState({
      open: false,
    })
    if (this.props.quit) window.close()
  }

  onCloseAppointment = () => {
    this.setState({
      open: true,
      propsAppointmentModal: {
        show: false,
      },
    })
  }

  onAppointmentFinish = () => {
    this.onCloseAppointment()
  }

  openKairosModal = () => {
    this.setState({
      propsAppointmentModal: {
        show: true,
        data: this.props.newAppointment,
        onSuccess: this.onAppointmentFinish,
        onClose: this.onCloseAppointment,
      },
    })
  }

  render() {
    const cancelButton = this.props.cancelBtn ? (
      <Button onClick={this.handleCancel} color="secondary" data-hook={this.props.cancelBtn}>
        {this.props.cancelBtn}
      </Button>
    ) : null

    const button = this.props.button ? (
      <Button onClick={this.handleSuccess} color="primary" autoFocus>
        {this.props.button}
      </Button>
    ) : null
    const orderButton = this.props.orderButton ? (
      <Button
        onClick={() => this.sendOrder(this.props.orderData)}
        color="primary"
        autoFocus
        data-hook={this.props.orderButton}
        disabled={this.props.disabledBtn}>
        {this.props.orderButton}
      </Button>
    ) : null

    return (
      <>
        <div>
          <Dialog
            className={this.props.classes['alert-dialog-order']}
            disableEnforceFocus
            open={this.state.open || false}
            onClose={this.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description" component="div">
                {this.props.message}
              </DialogContentText>
              <DialogContentText id="alert-dialog-description" component="div">
                {this.props.appointment && this.props.showAppointment && (
                  <ShowAppointmentLink
                    openKairosModal={this.openKairosModal}
                    newCitation={this.props.newCitation}
                  />
                )}
                {this.props.appointment && this.props.showDownloadResume && <DownloadResume />}
              </DialogContentText>
            </DialogContent>

            <DialogActions>
              {button}
              {orderButton}
              {cancelButton}
            </DialogActions>
          </Dialog>
        </div>
        <KairosAppointmentModal {...this.state.propsAppointmentModal} />
      </>
    )
  }
}

Modal.propTypes = {
  open: PropTypes.bool,
  sendOrder: PropTypes.func,
  handleClose: PropTypes.func,
  quit: PropTypes.bool,
  disabledBtn: PropTypes.bool,
  orderButton: PropTypes.string,
  button: PropTypes.string,
  cancelBtn: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  title: PropTypes.string,
  orderData: PropTypes.object,
  appointment: PropTypes.bool,
  showAppointment: PropTypes.bool,
  showDownloadResume: PropTypes.bool,
  newAppointment: PropTypes.object,
  classes: PropTypes.any,
  newCitation: PropTypes.bool,
}

export default compose(connect(mapStateToProps), withStyles(useStyles, Modal))(Modal)

Modal.defaultProps = {
  handleClose: () => {},
  // showAppointment: true,
}
