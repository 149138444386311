import axios from 'axios'
import { CONFIG } from './environments'

const translationAPIUrl = `${CONFIG.api.translate}`
const authAPIurl = `${CONFIG.api.auth}`

const getRequestHeaders = token => ({
  Authorization: `Bearer ${token}`,
})

const mapToTranslationByTerm = (iso, term, token) => ({
  method: 'GET',
  headers: getRequestHeaders(token),
  url: `${translationAPIUrl}?iso=${iso}&text=${term}`,
})

const mapToGetTranslationToken = data => ({
  method: 'POST',
  url: authAPIurl,
  data,
})

const getTranslatorToken = data =>
  axios(mapToGetTranslationToken(data)).then(resp => resp.data.token)

const findTranslation = (iso, term, token) =>
  axios(mapToTranslationByTerm(iso, term, token)).then(resp => resp.data)

export { findTranslation, getTranslatorToken }
