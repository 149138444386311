import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { get, isEmpty, isNil, isUndefined } from 'lodash'
import { Box, CircularProgress, Divider } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Button, ModalActions, Modal, AlertAdvice } from 'components/ui'
import { ContainerTitle } from 'components'
import LabelWithInfo from 'components/packages/ui/forms/LabelWithInfo'

import {
  actions,
  getCoverageToken,
  selectAddressInfo,
  selectIsAdsl,
  selectIsFtth,
  getTerritoryOwnerByCoverage,
  CoverageForm,
} from 'modules/Coverage'
import { selectStcV3Flag, selectStcV3Phase2Flag } from 'services/feature-flag/selectors'
import { useOrderChannelAndStatus } from 'modules/offers-configuration/hooks/useOrderChannelAndStatus/useOrderChannelAndStatus'
import {
  isLoadingTarrifsV3,
  selectDecisionAllowed,
  selectOrderConsequencesIsKo,
  selectChangeAddressIsLoading,
  selectChangeAddressData,
  selectChangeAddressError,
  selectLoadingParentTechnology,
  selectParentTechnologyIsKo,
  selectLoadingCoverageTechnology,
  selectCoverageTechnologyIsKo,
  selectParentTechnology,
  selectCoverageTechnology,
  selectInstaller,
  selectConnectionType,
} from 'modules/offers-configuration/selectors'
import {
  ADDRESS_CHANGE,
  PLAN_CHANGE,
  TECHNOLOGY_CHANGE,
  NEXT_DAY,
} from 'modules/offers-configuration/constants'
import { INSTALLER_DEFAULT } from 'modules/Installations/constants'
import {
  initTariffsV3,
  initChangeAddress,
  initParentTechnology,
  initCoverageTechnology,
} from 'modules/offers-configuration/actions'

import { getBillingAddressStr } from 'services/customer-new/selectors'
import { checkProvince } from 'modules/offers-configuration/helpers'
import { getCoverageAction } from 'modules/Coverage/store/actions'
import { formatAddressInfo } from 'modules/existing-clients/helpers/formats.helper'
import { useTariffOptions } from 'modules/offers-configuration/hooks/useTariffOptions'
import { useChangeTechnologyOptions } from 'modules/offers-configuration/hooks/useChangeTechnologyOptions/useChangeTechnologyOptions'

import ActualAddress from 'modules/existing-clients/components/ChangeTechology'
import MultiStep from 'modules/offers-configuration/components/MultiStep.js'
import SelectorTariffsPo from 'modules/offers-configuration/components/SelectorTariffsPo/SelectorTariffsPo.js'
import { TariffWarnings } from 'modules/offers-configuration/components/TariffWarnings/TariffWarnings.js'
import { mapToInstallationMedium } from 'modules/NewClientSales/store/selectors/checkout.selectors.helpers'
import { isAdslTariff } from 'modules/tariffs/store-apigee/tariffs-apigee.helpers'
import {findLandlineProvisioningNumber} from 'modules/Lines/services/LinesProvisioningService/LinesProvisioningService'

const useStyles = makeStyles({
  'align-right': {
    display: 'flex',
    justifyContent: 'flex-end',
  },
})

export const ChangeAddressModal = ({
  show,
  actualInstallationAddress,
  currentTariff,
  tariffSegment,
  subscription,
  subscriptionId,
  taxNeeded,
  clientTerritoryOwner,
  isIndirectFiber,
  canChangeAddressIndirectFiber,
  currentGescal17,
  onClose,
  onError,
}) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const [selectedTariffValue, setSelectedTariffValue] = useState('')
  const [selectedPsIdTariff, setSelectedPsIdTariff] = useState(null)
  const [selectedPoIdTariff, setSelectedPoIdTariff] = useState(null)
  const [orderPurpose, setOrderPurpose] = useState('')
  const [isEqualsProvince, setIsEqualsProvince] = useState(null)
  const [newPhoneNumber, setNewPhoneNumber] = useState({})
  const [postalCode, setPostalCode] = useState('')

  const { filteredEnrichTariffs } = useTariffOptions(subscription, null, false, false, false)

  const isLoading = useSelector(selectChangeAddressIsLoading)
  const isStcV3Flag = useSelector(selectStcV3Flag)
  const isStcV3Phase2Flag = useSelector(selectStcV3Phase2Flag)
  const loadingTariffsV3 = useSelector(isLoadingTarrifsV3)
  const error = useSelector(selectChangeAddressError)
  const changeAddressResponse = useSelector(selectChangeAddressData)
  const coverageToken = useSelector(getCoverageToken)
  const installationAddress = useSelector(selectAddressInfo)
  const coverageIsAdsl = useSelector(selectIsAdsl)
  const coverageIsFtth = useSelector(selectIsFtth)
  const installer = useSelector(selectInstaller)
  const connectionType = useSelector(selectConnectionType)
  const territoryOwner = getTerritoryOwnerByCoverage(get(installationAddress, 'coverage.coverage'))

  const parentTechnologyLoading = useSelector(selectLoadingParentTechnology)
  const parentTechnologyFailure = useSelector(selectParentTechnologyIsKo)
  const coverageTechnologyLoading = useSelector(selectLoadingCoverageTechnology)
  const coverageTechnologyFailure = useSelector(selectCoverageTechnologyIsKo)

  const parentTechnology = useSelector(selectParentTechnology)
  const coverageTechnology = useSelector(selectCoverageTechnology)

  const sameTechnology = parentTechnology === coverageTechnology

  const salesType = sameTechnology ? PLAN_CHANGE : TECHNOLOGY_CHANGE

  const { orderChannel, status } = useOrderChannelAndStatus()
  const decisionAllowed = useSelector(selectDecisionAllowed)
  const orderConsequencesKo = useSelector(selectOrderConsequencesIsKo)

  const commercialName = get(currentTariff, 'commercial_name')
  const currentTariffId = get(currentTariff, 'id')
  const currentTariffIsAdsl = isAdslTariff(currentTariff)
  const { gescal } = installationAddress

  const installationMedium = mapToInstallationMedium(installationAddress.coverage, false)
  const medium = isNil(get(installationMedium, 'geolocation'))
    ? {
        ...installationMedium.medium,
        geolocation: { latitude: null, longitude: null },
      }
    : { ...installationMedium }

  const { newNumber, errorNewNumber } = newPhoneNumber

  const disabledNewTariffButton = !decisionAllowed || orderConsequencesKo
  const disabledNewAddressButton =
    isEmpty(gescal) ||
    (!isEqualsProvince && (errorNewNumber || isEmpty(newNumber))) ||
    parentTechnologyLoading ||
    coverageTechnologyLoading
  const installationAddressData = { ...installationAddress, ...newPhoneNumber }

  const optionSalesType = sameTechnology ? orderPurpose : salesType

  let inputtedCharacteristicsAd = newNumber
    ? {
        CH_Fixed_Number_Type: 'new',
        CH_Fixed_Number: newNumber,
      }
    : null

  if (optionSalesType === ADDRESS_CHANGE) {
    let connectionTypeAndInstaller = INSTALLER_DEFAULT

    if (!isUndefined(installer) && !isUndefined(connectionType)) {
      connectionTypeAndInstaller = {
        CH_Installation_Point_Provider: installer,
        CH_Installation_Point_Type: connectionType,
      }
    }

    inputtedCharacteristicsAd = {
      ...inputtedCharacteristicsAd,
      ...connectionTypeAndInstaller,
    }
  }

  const options = {
    ...useChangeTechnologyOptions(
      filteredEnrichTariffs,
      territoryOwner,
      subscriptionId,
      selectedPoIdTariff,
      selectedTariffValue,
      optionSalesType,
      { coverageToken, installer, connectionType, isIndirectFiber },
    ),
    inputtedCharacteristicsAd: {
      ...inputtedCharacteristicsAd,
      CH_Fixed_Territory_Owner: territoryOwner,
    },
    typeNumbering: newNumber ? 'address_change_new_number' : 'address_change_same_number',
    medium,
  }

  const onTariffSelect = (e, psId, poId) => {
    if (salesType === PLAN_CHANGE) {
      if (currentTariffId === psId) {
        setOrderPurpose(ADDRESS_CHANGE)
      } else {
        setOrderPurpose(PLAN_CHANGE)
      }
    } else {
      setOrderPurpose(TECHNOLOGY_CHANGE)
    }

    setSelectedTariffValue(e.target.value)
    setSelectedPsIdTariff(psId)
    setSelectedPoIdTariff(poId)
  }

  const getTariffsOffers = () => {
    if (isStcV3Flag && !loadingTariffsV3) {
      dispatch(
        initTariffsV3(
          orderChannel,
          status,
          tariffSegment,
          subscriptionId,
          isStcV3Phase2Flag,
          salesType,
          territoryOwner,
        ),
      )
    }
  }

  async function getNewPhoneNumber(zipCode) { 
    await findLandlineProvisioningNumber(zipCode).then(value => {
      setNewPhoneNumber({newNumber: value, errorNewNumber: false, loadingNewNumber: false})
    }).catch(() => {
      setNewPhoneNumber({newNumber: 'Error en la consulta', errorNewNumber: true, loadingNewNumber: false})
    })
  }

  const callNewNumber = coverageData => {
    const { zipCode } = coverageData

    if(postalCode === '') {
      setPostalCode(zipCode)
    }
    
    getNewPhoneNumber(zipCode || postalCode)
  }

  const onCoverageCheckFinished = newCoverageData => {
    const equalsProvince = checkProvince(actualInstallationAddress, newCoverageData)

    dispatch(getCoverageAction(newCoverageData))

    if (!equalsProvince) {
      callNewNumber(newCoverageData)
    }
    setIsEqualsProvince(equalsProvince)
  }

  const coverageReset = () => {
    dispatch(actions.getCoverageReset())
    setNewPhoneNumber({})
    setPostalCode('')
  }

  useEffect(() => {
    if (show) {
      dispatch(actions.getCoverageToken({ isOvid: true }))
    }

    return () => coverageReset()
  }, [show])

  useEffect(() => {
    if (show && clientTerritoryOwner && territoryOwner) {
      dispatch(initParentTechnology(clientTerritoryOwner))
      dispatch(initCoverageTechnology(territoryOwner))
    }
  }, [clientTerritoryOwner, territoryOwner])

  useEffect(() => {
    if (show && (coverageTechnologyFailure || parentTechnologyFailure)) {
      onError('Se ha producido un error', 'No se ha podido recuperar la información de tecnología')
    }
  }, [coverageTechnologyFailure, parentTechnologyFailure])

  useEffect(() => {
    if (changeAddressResponse || error) {
      const changeIsAdsl = currentTariffIsAdsl && coverageIsAdsl && !coverageIsFtth
      onClose(false, changeIsAdsl)
    }

    if (show && error) {
      onError('Se ha producido un error', 'No se ha podido realizar el cambio de domicilio')
    }
  }, [error, changeAddressResponse])

  return (
    <Modal
      isOpen={show}
      onClose={() => !isLoading && onClose(true)}
      title="Cambio de domicilio"
      overFlowY="auto">
      <MultiStep
        initialStep="addressForm"
        steps={{
          // eslint-disable-next-line react/prop-types
          addressForm: ({ goToStep }) => (
            <>
              <ActualAddress data={getBillingAddressStr(actualInstallationAddress)} />
              <Box my="15px">
                <Divider />
              </Box>
              <Box minHeight="330px">
                <ContainerTitle>DIRECCIÓN NUEVA</ContainerTitle>
                <CoverageForm
                  installationAddress={installationAddressData}
                  coverageToken={coverageToken}
                  onCoverageCheckFinished={onCoverageCheckFinished}
                  reset={coverageReset}
                  retryCallNewNumber={callNewNumber}
                  applicationId="360"
                />
              </Box>
              <ModalActions className={classes['align-right']}>
                <Button
                  type="submit"
                  disabled={disabledNewAddressButton}
                  onClick={() => {
                    getTariffsOffers()
                    goToStep('changeTariff')
                  }}>
                  Continuar
                </Button>
              </ModalActions>
            </>
          ),
          // eslint-disable-next-line react/prop-types
          changeTariff: ({ goToStep }) => (
            <>
              <ContainerTitle>DIRECCIÓN DE INSTALACIÓN</ContainerTitle>
              <LabelWithInfo
                label="Dirección Actual:"
                value={getBillingAddressStr(actualInstallationAddress)}
              />
              <Box display="flex">
                <LabelWithInfo
                  label="Dirección Nueva:"
                  value={formatAddressInfo(installationAddress)}
                />
                <Box mt="5px">
                  <Button
                    text
                    style={{ padding: 0, marginTop: 1 }}
                    disabled={isLoading}
                    onClick={() => {
                      coverageReset()
                      goToStep('addressForm')
                    }}>
                    Modificar
                  </Button>
                </Box>
              </Box>
              {newNumber && <LabelWithInfo label="Nº asignado:" value={newNumber} />}
              <Box my="15px">
                <Divider />
              </Box>
              <ContainerTitle>CAMBIO DE TARIFA</ContainerTitle>
              <LabelWithInfo label="Tarifa Actual:" value={commercialName} />
              {loadingTariffsV3 ? (
                <CircularProgress size="32px" color="secondary" />
              ) : (
                <>
                  <LabelWithInfo
                    label="Tarifa Nueva:"
                    value={
                      <SelectorTariffsPo
                        filteredEnrichTariffs={filteredEnrichTariffs}
                        taxNeeded={taxNeeded}
                        subscription={subscription}
                        onTariffSelect={onTariffSelect}
                        salesType={orderPurpose}
                        date={NEXT_DAY.value}
                        territoryOwner={territoryOwner}
                      />
                    }
                  />
                  <TariffWarnings
                    date={Date.now()}
                    subscription={subscription}
                    selectedPsIdTariff={selectedPsIdTariff}
                    taxNeeded={taxNeeded}
                  />
                </>
              )}

              {canChangeAddressIndirectFiber &&
                isIndirectFiber &&
                gescal?.substr(0, 17) === currentGescal17 && (
                  <AlertAdvice
                    type="warning"
                    message="Recuerda que no se puede realizar un cambio de domicilio a Fibra Indirecta si estás realizando una Migración Comercial."
                  />
                )}

              <ModalActions className={classes['align-right']}>
                <Button
                  type="submit"
                  disabled={disabledNewTariffButton || isLoading}
                  onClick={() => {
                    dispatch(initChangeAddress(options))
                  }}>
                  Confirmar
                </Button>
                <Button secondary onClick={() => onClose(true)} disabled={isLoading}>
                  Cancelar
                </Button>
              </ModalActions>
            </>
          ),
        }}
      />
    </Modal>
  )
}

ChangeAddressModal.propTypes = {
  show: PropTypes.bool,
  actualInstallationAddress: PropTypes.object.isRequired,
  currentTariff: PropTypes.object.isRequired,
  tariffSegment: PropTypes.string,
  subscription: PropTypes.object,
  subscriptionId: PropTypes.number,
  taxNeeded: PropTypes.bool,
  clientTerritoryOwner: PropTypes.string,
  isIndirectFiber: PropTypes.bool,
  canChangeAddressIndirectFiber: PropTypes.bool,
  currentGescal17: PropTypes.string,
  onClose: PropTypes.func,
  onError: PropTypes.func,
}
