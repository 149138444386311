import PropTypes from 'prop-types'

import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import { TextStyles } from 'utils/text'

const useStyles = makeStyles({
  alignItems: {
    alignItems: 'center',
  },
})

const LabelWithInfo = ({ isVisible, label, value, className, alignItems }) => {
  const classes = useStyles()

  if (!isVisible && isVisible !== undefined) {
    return null
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      mb="16px"
      mr="48px"
      className={alignItems && classes.alignItems}>
      <Typography
        {...TextStyles.paragraphDark({
          component: 'span',
          className,
        })}>
        {label}
      </Typography>
      <Typography
        {...TextStyles.paragraphDark({
          component: 'span',
          className: 'regular',
          style: { marginLeft: '5px' },
        })}>
        {value}
      </Typography>
    </Box>
  )
}

LabelWithInfo.propTypes = {
  isVisible: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  className: PropTypes.string,
  alignItems: PropTypes.bool,
}

export default LabelWithInfo
