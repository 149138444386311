import { useSelector, useDispatch } from 'react-redux'

import {
  appointmentActions,
  selectKairosAppointmentToken,
  selectKairosAppointmentTokenError,
} from 'modules/Appointments/store'
import { selectOrderStatusScoring } from 'modules/NewClientSales/store/selectors/orders.selectors'
import { useState } from 'react'

const VALID_STATUS_VALUES = ['0']
const RISK_STATUS_VALUES = ['1', '2', '3', '4']
const KO_STATUS_VALUES = ['8', '20', '21']
const VALID_SCORING_RESULT = 'OK'
const RISK_SCORING_RESULT = 'RISK'
const KO_SCORING_RESULT = 'KO'

export const useGenerateKairosAppointmentToken = (data, old) => {
  const dispatch = useDispatch()
  const [error, setError] = useState(useSelector(selectKairosAppointmentTokenError))

  const SELLER_APPOINTMENT_ROLE = '14'

  const token = useSelector(selectKairosAppointmentToken)
  const roveStatus = useSelector(selectOrderStatusScoring)

  const scoringResult = () => {
    if (VALID_STATUS_VALUES.includes(roveStatus)) {
      return VALID_SCORING_RESULT
    }
    if (RISK_STATUS_VALUES.includes(roveStatus)) {
      return RISK_SCORING_RESULT
    }
    if (KO_STATUS_VALUES.includes(roveStatus)) {
      setError(true)
      return KO_SCORING_RESULT
    }
    return null
  }

  const generate = () => {
    if (!error) {
      dispatch(
        appointmentActions.getKairosAppointmentToken({
          ...data,
          scoringResult: scoringResult(),
          scoringLevel: roveStatus,
        }),
      )
    }
  }

  const generateQw = () => {
    const appointmentData = {
      docNumber: data?.docNumber,
      ot: data?.order ? `Y${data?.order}` : null,
      entregaTerminal: data?.hasDeviceDelivery,
      role: SELLER_APPOINTMENT_ROLE,
      css: `https://${window.location.hostname}/kairos.css`,
      origin: window.location.hostname,
    }

    dispatch(appointmentActions.getKairosAppointmentToken(appointmentData))
  }

  return {
    appointmentToken: token,
    appointmentTokenIsLoading: !token && !error,
    appointmentTokenError: error,
    generateAppointmentToken: old ? generateQw : generate,
  }
}
