import { useEffect } from 'react'
import PropTypes from 'prop-types'

import { useSelector } from 'react-redux'

import { usePreventReload } from 'modules/ui/hooks'
import { Button, SpinnerCenter } from 'components/ui'

import { summaryContainerSelectors } from './SummaryContainer.selectors'
import { Summary } from './Summary'

export const SummaryContainer = () => {
  const {
    isLoading,
    taskId,
    deviceName,
    errorCheckout,
    startPollingTime,
    docNumber,
    msisdn,
    documentType,
    orderId,
    willDeviceBeShippedToStore,
    isDevicePaymentMethodUpfront,
  } = useSelector(summaryContainerSelectors)

  const { removePreventReload } = usePreventReload()

  useEffect(() => {
    if (!isLoading && !!taskId) {
      removePreventReload()
    }
  }, [isLoading, taskId])

  const onClose = () => {
    window.close()
  }

  const props = {
    taskId,
    deviceName,
    errorCheckout,
    startPollingTime,
    docNumber,
    msisdn,
    documentType,
    orderId,
    willDeviceBeShippedToStore,
    isDevicePaymentMethodUpfront,
  }

  return (
    <>
      {isLoading && <SpinnerCenter showMsg />}

      {!isLoading && (
        <>
          <Summary {...props} />

          <Button onClick={onClose}>Volver a la pantalla de inicio</Button>
        </>
      )}
    </>
  )
}

SummaryContainer.propTypes = {
  step: PropTypes.string,
}
