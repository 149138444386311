import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Box, CircularProgress } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  'spinner-center': {
    width: '100%',
    height: ({ overlay }) => overlay && '100%',
    backgroundColor: ({ overlay }) => overlay && `${theme.palette.componentColors.White}4`,
    paddingTop: ({ overlay }) => !overlay && '20vh',
    position: ({ absolute }) => absolute && 'absolute',

    '& .msg': {
      display: 'flex',
      maxWidth: '300px',
      marginTop: '20px',
      lineHeight: '24px',
      color: theme.palette.bluePalette.main,
      fontWeight: 'bold',
      textAlign: 'center',
      animation: '$cssAnimation 0s 3s forwards',
      opacity: 0,
    },
  },
  '@keyframes cssAnimation': {
    to: {
      opacity: 1,
    },
  },
}))

export const SpinnerCenter = ({
  className,
  showMsg,
  size,
  color,
  absolute,
  overlay,
  ...restProps
}) => {
  const classes = useStyles({ absolute, overlay })

  return (
    <Box
      display="flex"
      justifyContent="center"
      className={`circular-progress-center ${classes['spinner-center']}`}
      {...restProps}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        className="wrapper">
        <CircularProgress size={size} color={color} />
        {showMsg && (
          <div className="msg">Seguimos cargando los datos. Por favor, espera unos segundos...</div>
        )}
      </Box>
    </Box>
  )
}

SpinnerCenter.defaultProps = {
  className: '',
  size: 100,
  color: 'primary',
}

SpinnerCenter.propTypes = {
  className: PropTypes.string,
  showMsg: PropTypes.bool,
  size: PropTypes.number,
  color: PropTypes.oneOf(['primary', 'secondary', 'inherit']),
  absolute: PropTypes.bool,
  overlay: PropTypes.bool,
}
