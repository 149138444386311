import { useMemo, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { get } from 'lodash'
import { useFormikContext } from 'formik'

import {
  IbanAutoComplete,
  selectIBANErrorCode,
  getMaskedIbanNumber,
  selectShouldMaskIban,
  setExistingClientIsBeingEdited,
} from 'modules/Iban'
import { selectReadOnlyIbanOnCarteraFlag } from 'services/feature-flag/selectors'

import { IBAN_NOT_FOUND, IBAN_ERROR, UNEXPECTED_ERROR } from 'modules/SharedSales/constants'
import { fetchNewIbanValueCartera } from 'modules/CustomerInfo'

import { makeStyles } from '@material-ui/styles'
import { Box } from '@material-ui/core'
import { ComposedTextField, ButtonLink } from 'components/ui'
import { ErrorModal } from '../../../Common'
import { useUpdateCarteraValues } from '../../../../hooks/Cartera'

const useStyles = makeStyles({
  'iban-input': {
    maxWidth: '80%',
  },
})

export function IbanInput() {
  const classes = useStyles()
  const dispatch = useDispatch()

  const [error, setError] = useState()

  const ibanError = useSelector(selectIBANErrorCode)

  const canModifyIbanOnCartera = !useSelector(selectReadOnlyIbanOnCarteraFlag)

  const shouldMaskIban = useSelector(selectShouldMaskIban)

  const { setCarteraValuesHaveChanged } = useUpdateCarteraValues({
    action: fetchNewIbanValueCartera,
    flags: { requiresBillingAccountId: true },
  })
  const {
    values,
    setFieldValue,
    errors,
    touched,
    handleBlur,
    setStatus,
    status,
  } = useFormikContext()

  const ibanNumber = get(values, 'billing.ibanNumber')

  const ibanStatus = useMemo(
    () => ({
      touched: !!get(status, 'billing.ibanNumber') || get(touched, 'billing.ibanNumber'),
      error: get(errors, 'billing.ibanNumber') || get(status, 'billing.ibanNumber'),
    }),
    [touched, errors, ibanError],
  )

  const setIbanFieldStatus = fieldStatus => {
    setStatus({ ...status, 'billing.ibanNumber': fieldStatus })
  }

  function onChangeIbanNumber(value) {
    if (value.replace(/\s/g, '') !== ibanNumber) {
      setCarteraValuesHaveChanged(true)
    }
    setIbanFieldStatus(null)
    setFieldValue('billing.ibanNumber', value)
  }

  function setIbanError() {
    let statusError = ''

    switch (ibanError) {
      case 404:
        statusError = IBAN_NOT_FOUND
        break
      case 400:
        statusError = IBAN_ERROR
        break
      case 500:
        statusError = UNEXPECTED_ERROR
        break
      default:
        statusError = setError('')
    }

    setError(statusError)
    setIbanFieldStatus(statusError)
  }

  const [shouldShowMaskedIban, setShouldShowMaskedIban] = useState(
    shouldMaskIban && !ibanError && ibanNumber,
  )

  const handleEditIbanClick = () => {
    setShouldShowMaskedIban(false)
    dispatch(setExistingClientIsBeingEdited())
    onChangeIbanNumber('')
  }

  useEffect(() => {
    setIbanError()
  }, [ibanError])

  const maskedIbanNumber = getMaskedIbanNumber(ibanNumber)

  const MaskedIbanInput = () => (
    <>
      <Box display="flex">
        <ComposedTextField
          value={maskedIbanNumber}
          label="IBAN"
          fullWidth
          margin="normal"
          variant="outlined"
          disabled
          className={classes['iban-input']}
          autoComplete="off"
        />
      </Box>
    </>
  )

  return (
    <>
      {shouldShowMaskedIban && (
        <>
          <MaskedIbanInput />

          {canModifyIbanOnCartera && (
            <ButtonLink type="button" onClick={handleEditIbanClick}>
              Modificar iban
            </ButtonLink>
          )}
        </>
      )}

      <IbanAutoComplete
        onBlur={handleBlur}
        value={ibanNumber.toUpperCase()}
        label="IBAN"
        fullWidth
        name="billing.ibanNumber"
        data-hook="billing.ibanNumber"
        onChange={onChangeIbanNumber}
        touched={ibanStatus.touched}
        ibanInputClass={classes['iban-input']}
        hideInput={shouldShowMaskedIban}
        error={ibanStatus.error}
      />
      <ErrorModal error={error} hasMsError={!!ibanError} />
    </>
  )
}
