import { useEffect, useState, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { omitBy, get, find } from 'lodash'
import { parseISO, isThisMonth } from 'date-fns'

import { selectSvaHomeGoFlag, selectSvaSmartHomeFlag } from 'services/feature-flag/selectors'
import {
  findBillingsInitAction,
  selectBillingsIsLoading,
  selectBillingsData,
} from 'modules/billings'
import { putCadenceAlarmSalesStart, resetCadenceAlarmSales } from 'services/cadence-flow/actions'
import { resetCreditScoring } from 'modules/NewClientSales/store/actions/scoring.actions'
import { useRequestIpAddress } from 'modules/Auth/hooks'

import {
  formatNumberWithComma,
  getProvinceFromCode,
  formatTwoDigits,
  mobileNumberRegex,
} from 'utils'
import { applyTax, getTax } from 'services/taxes'
import { getMsisdn } from 'services/subscriptions/helpers'
import {
  selectSaleInformation,
  selectors as customerSelectors,
  selectInstallationAddressPackageByMsisdn,
  selectClientPackages,
} from 'services/customer-360/selectors'

import { RESIDENTIAL, PRO } from 'modules/SharedSales/constants'

import { useProSvas } from 'modules/NewClientSales/components/Common/SalesTariffPicker/useProSVAs'
import {
  selectIs3pOr2pTariff,
  selectIsElFijoTariff,
  isPostpaidTariffId,
} from 'modules/offers-configuration/selectors'
import {
  selectCadenceAlarmsCustomerInfo,
  selectHomeGoCustomerInfo,
} from 'modules/NewClientSales/store/selectors/cadence.selectors'

import { useScoringSVAs } from 'modules/offers-configuration/hooks/useScoringSVAs/useScoringSVAs'
import { SVA_HOMEGO_CODE, SVA_SMARTHOME_CODE } from 'modules/offers-configuration/constants'

import { putHomeGoSvaStart, resetPurchaseAlarm } from 'services/customer-sva/actions'
import { isDuoTariffId } from 'modules/tariffs/store-apigee/index'
import { getElFijoMsisdn } from 'modules/tariffs/helpers'
import { getFixedNumberOnFlySubscription } from 'modules/orders/orders.helpers'
import { extractPersonalData, selectIsPosProfile } from 'modules/vista-360/selectors/selectors'
import { ADDITIONAL_LINE } from 'modules/SharedSales/constants/productCategories'
import { formatDataContact } from 'modules/vista-360/containers/ClientMainContainer/helpers'
import {
  selectIsAlarmsError,
  selectIsAlarmsSuccess,
  selectIsAlarmsLoading,
  selectAlarmsErrorMsg,
} from './useCadenceAlarms.selectors'

export const useCadenceAlarms = (
  tariffId,
  isCustomerResidential,
  fixedNumber,
  subscription,
  isOnFly,
  isCompany,
  shouldFetchSvas = false,
) => {
  const dispatch = useDispatch()
  const [cadenceParams, setCadenceParams] = useState({
    fixedNumber,
  })

  const { requestIpAddress } = useRequestIpAddress()

  const [customerAddress, setCustomerAddress] = useState(null)
  const shouldSendScoring = useMemo(() => !!customerAddress, [customerAddress])

  const [canFetchCadence, setCanFetchCadence] = useState(false)
  const [isContractIdError, setIsContractIdError] = useState(false)

  const isSvaSmartHomeFlag = useSelector(selectSvaSmartHomeFlag)
  const isSvaHomeGoFlag = useSelector(selectSvaHomeGoFlag)
  const isPosProfile = useSelector(selectIsPosProfile)

  const { segment } = useSelector(selectSaleInformation)
  const is3pOr2p = useSelector(state => selectIs3pOr2pTariff(state, tariffId))
  const isDuoTariff = isDuoTariffId(tariffId)
  const isElFijoTariff = useSelector(state => selectIsElFijoTariff(state, tariffId))
  const isPostpaid = useSelector(state => isPostpaidTariffId(state, tariffId))

  const clientPackages = useSelector(selectClientPackages)
  const orderData = find(clientPackages, { id: subscription?.id })
  const isAdditionalLine = orderData?.lineType === ADDITIONAL_LINE

  const billingAddress = useSelector(state => customerSelectors.getBillingAddress(state))
  const careTaxRate = useSelector(state => getTax(state, get(billingAddress, 'postCode')))

  const { disableProSVAs, svaList, isLoadingSvas } = useProSvas(
    shouldFetchSvas ? tariffId : '',
    (isSvaSmartHomeFlag || isSvaHomeGoFlag) && shouldFetchSvas,
    segment,
    !isElFijoTariff && !isAdditionalLine && isSvaHomeGoFlag,
  )
  const disableSvasWithFetch =
    !shouldFetchSvas || !disableProSVAs || (disableProSVAs && shouldFetchSvas)

  const billingData = useSelector(selectBillingsData)
  const billingIsLoading = useSelector(selectBillingsIsLoading)
  const hasBillingUnpaid = useMemo(
    () =>
      !billingIsLoading &&
      billingData &&
      billingData.find(bill => bill.isUnpaid && !isThisMonth(parseISO(bill.date))),
    [billingIsLoading, billingData],
  )

  const formatSva = (sva, textTax) => {
    const svaName = sva.name || sva.commercialInfo?.name
    const taxFreeAmount = sva.prices?.taxFreeAmount || 0
    const priceWithTax = isCustomerResidential
      ? applyTax(careTaxRate, taxFreeAmount)
      : taxFreeAmount

    const subSvas = sva.subSvas?.map(subSva => formatSva(subSva, textTax)) || []
    const alarmType = sva.psId?.includes(SVA_HOMEGO_CODE) ? SVA_HOMEGO_CODE : SVA_SMARTHOME_CODE
    const svaNameAndPrice =
      alarmType === SVA_HOMEGO_CODE
        ? svaName
        : `${svaName} (${formatNumberWithComma(priceWithTax)} €/mes ${textTax})`

    return {
      svaId: sva.psId,
      name: svaNameAndPrice,
      status: false,
      subSvas,
      alarmType,
    }
  }

  const dataCardClient = useSelector(extractPersonalData)
  const clientContactData = useMemo(
    () => ({
      ...dataCardClient,
      ...formatDataContact(dataCardClient),
    }),
    [dataCardClient],
  )

  const customerPersonalInfo = useSelector(selectCadenceAlarmsCustomerInfo)
  const customerInfoHomeGo = useSelector(selectHomeGoCustomerInfo)
  const msisdn = getMsisdn(subscription)
  const installationAddress = useSelector(state =>
    selectInstallationAddressPackageByMsisdn(state, msisdn),
  )
  const isDataChange = isOnFly || (!isOnFly && !installationAddress?.medium)

  const isOnFlyAndPosProfile = isOnFly && isPosProfile
  const isSmartHomeCompatible =
    is3pOr2p &&
    !hasBillingUnpaid &&
    disableSvasWithFetch &&
    isSvaSmartHomeFlag &&
    (!isOnFly || isOnFlyAndPosProfile) &&
    isPostpaid &&
    !isAdditionalLine

  const isHomeGoCompatible =
    isSvaHomeGoFlag &&
    !isDuoTariff &&
    !isElFijoTariff &&
    (isPostpaid || subscription?.postpaid) &&
    disableSvasWithFetch &&
    !isAdditionalLine

  const svas = useMemo(() => {
    const textTax = isCustomerResidential ? 'impuestos incl.' : 'impuestos no incl.'
    const filterSvas = svaList.filter(
      sva =>
        (sva.psId === SVA_SMARTHOME_CODE && isSmartHomeCompatible) ||
        (sva.psId?.includes(SVA_HOMEGO_CODE) && isHomeGoCompatible),
    )
    return filterSvas && !disableProSVAs ? filterSvas.map(sva => formatSva(sva, textTax)) : []
  }, [svaList, disableProSVAs, isSmartHomeCompatible, isHomeGoCompatible])

  const formatJsonAddress = (address, customerInfo) => {
    const postalCode = address.postCode || address.zipCode
    const provinceId = address.provinceId || (postalCode ? postalCode.substr(0, 2) : '')

    const floor = address.flat ? `piso ${address.flat}` : ''
    const door = address.door ? `puerta ${address.door}` : ''

    const flatAndDoor = [floor, door, address.otherInfo].filter(Boolean).join(', ')

    const otherInfo = address.streetTwo || flatAndDoor

    const nameAddress = address.streetOne || address.address
    const provinceName = address.province || getProvinceFromCode(+provinceId)

    return omitBy(
      {
        addressType: address.streetType,
        name: nameAddress,
        other: otherInfo,
        number: address.number || address.streetNumber,
        postalCode,
        city: address.city,
        province: provinceName,
        provinceCode: formatTwoDigits(provinceId),
        ...customerInfo,
      },
      value => !value || value === '',
    )
  }

  const {
    sendScoring,
    getScoringDataRequest,
    scoringError,
    scoringMessage,
    scoringLoading,
  } = useScoringSVAs(subscription, isDataChange)

  const contractIdErrorMsg = 'No se ha podido recuperar el identificador del contrato'

  const isAlarmsCadenceSuccess = useSelector(selectIsAlarmsSuccess)
  const isAlarmsCadenceError = useSelector(selectIsAlarmsError)
  const isAlarmsCadenceLoading = useSelector(selectIsAlarmsLoading)
  const alarmsCadenceErrorMsg = useSelector(selectAlarmsErrorMsg)

  const errorWithMessage = alarmsCadenceErrorMsg || isAlarmsCadenceError

  const scoringErrorMsg = scoringError ? scoringMessage : errorWithMessage

  const isProcessError = isContractIdError ? contractIdErrorMsg : scoringErrorMsg

  const isProcessSuccess = !scoringError && !!isAlarmsCadenceSuccess
  const isProcessLoading =
    (scoringLoading || isAlarmsCadenceLoading || canFetchCadence) && !isProcessError

  const isValidPhonesContact = () => {
    const { phone1, phone2 } = customerInfoHomeGo
    return (phone1 && mobileNumberRegex.test(phone1)) || (phone2 && mobileNumberRegex.test(phone2))
  }

  const isValidEmailContact = () => !!customerInfoHomeGo.email

  const getSvasFromList = (svaSelected, listSvas) => {
    let okSva

    listSvas.find(sva => {
      if (sva.psId === SVA_SMARTHOME_CODE && sva.psId === svaSelected?.alarmType) {
        okSva = sva
      } else if (sva.psId === SVA_HOMEGO_CODE) {
        okSva = sva.subSvas?.find(subSva => subSva.psId === svaSelected?.svaId)
      }
      return okSva
    })
    return okSva
  }

  useEffect(() => {
    requestIpAddress()
  }, [])

  useEffect(() => {
    dispatch(findBillingsInitAction({ filter: 'period:12' }))
    setCadenceParams({ ...cadenceParams, tariffId })
  }, [tariffId])

  useEffect(() => {
    if (scoringError) {
      setCanFetchCadence(false)
    } else if (
      (isSvaSmartHomeFlag || isSvaHomeGoFlag) &&
      canFetchCadence &&
      !scoringError &&
      !scoringLoading &&
      !isAlarmsCadenceLoading
    ) {
      setCanFetchCadence(false)

      if (cadenceParams.alarmType === SVA_HOMEGO_CODE) {
        const { svaSelected } = cadenceParams
        const scoringHomeIt = getScoringDataRequest(
          {
            ...cadenceParams.sendAddress,
            ...cadenceParams.addressCustomer,
          },
          [svaSelected],
        )

        // YCARE-2028 - if do not have orderId, send a cero value to bypass required param
        const orderIdHomeGo = cadenceParams.orderId || '0'

        const subsFixedNumber = isOnFly
          ? getFixedNumberOnFlySubscription(subscription)
          : getElFijoMsisdn(subscription)
        const msisdnOrFijo = is3pOr2p ? subsFixedNumber : msisdn

        let { phone1, phone2, email } = customerInfoHomeGo
        if (cadenceParams.contactPhones) {
          phone1 = cadenceParams.contactPhones[0] || cadenceParams.contactPhones[1]
          phone2 = cadenceParams.contactPhones[0] ? cadenceParams.contactPhones[1] : null
        }
        email = cadenceParams.email || email

        dispatch(
          putHomeGoSvaStart({
            orderId: orderIdHomeGo,
            msisdn: msisdnOrFijo,
            subscription,
            invoicingAddress: cadenceParams.invoicingAddress,
            sendAddress: cadenceParams.addressCustomer,
            isCarteraFlow: cadenceParams.isCarteraFlow,
            segment: cadenceParams.segment,
            customerInfo: {
              ...customerInfoHomeGo,
              phone1,
              phone2,
              email,
            },
            scoring: scoringHomeIt,
            kit: svaSelected,
            tariffId: cadenceParams.tariffId,
            isCompany: cadenceParams.isCompany,
          }),
        )
      } else {
        dispatch(
          putCadenceAlarmSalesStart(
            cadenceParams.contractId,
            cadenceParams.orderId,
            cadenceParams.fixedNumber,
            cadenceParams.tariffId,
            cadenceParams.invoicingAddress,
            cadenceParams.sendAddress,
            cadenceParams.isCarteraFlow,
            cadenceParams.segment,
          ),
        )
      }
    }
  }, [canFetchCadence, scoringError, isAlarmsCadenceLoading, scoringLoading])

  useEffect(() => {
    const { svaSelected } = cadenceParams

    if (shouldSendScoring && svaSelected) {
      setCustomerAddress(null)

      if (cadenceParams.alarmType === SVA_SMARTHOME_CODE) {
        sendScoring(
          {
            ...cadenceParams.sendAddress,
            ...customerAddress,
          },
          [svaSelected],
        )
      }

      setCanFetchCadence(true)
    }
  }, [shouldSendScoring, cadenceParams.sendAddress, customerAddress])

  const sendAlarmCadence = useCallback(
    (
      contractId,
      orderId,
      addressCustomer,
      isCarteraFlow,
      alarmType = SVA_SMARTHOME_CODE,
      svaFormSelected,
      contactPhones,
      email,
    ) => {
      if (isSvaSmartHomeFlag || isSvaHomeGoFlag) {
        if (!contractId && alarmType === SVA_SMARTHOME_CODE) {
          setIsContractIdError(true)
          return
        }
        setIsContractIdError(false)

        const streetType = billingAddress.streetOne ? billingAddress.streetOne.split(' ')[0] : ''
        const customerBillingAddress = {
          ...billingAddress,
          streetType,
        }

        const svaSelectedFromList = getSvasFromList(svaFormSelected, svaList)

        setCadenceParams({
          ...cadenceParams,
          contractId,
          orderId,
          invoicingAddress: formatJsonAddress(customerBillingAddress, customerPersonalInfo),
          sendAddress: formatJsonAddress(addressCustomer, customerPersonalInfo),
          isCarteraFlow,
          segment: isCustomerResidential ? RESIDENTIAL : PRO,
          alarmType,
          svaSelected: svaSelectedFromList,
          contactPhones,
          email,
          addressCustomer: {
            ...addressCustomer,
            address: addressCustomer.street,
            streetOne: addressCustomer.streetOne?.replace(
              new RegExp(`^${addressCustomer.streetType} `),
              '',
            ),
          },
          isCompany,
        })

        setCustomerAddress(addressCustomer)
      }
    },
    [cadenceParams, customerPersonalInfo, svaList],
  )

  const resetAlarmCadence = () => {
    dispatch(resetCadenceAlarmSales())
    dispatch(resetCreditScoring())
    dispatch(resetPurchaseAlarm())
  }

  return {
    svas,
    isLoadingSvas,
    sendAlarmCadence,
    isSmartHomeCompatible,
    isHomeGoCompatible,
    isAlarmsCadenceError: isProcessError,
    isAlarmsCadenceSuccess: isProcessSuccess,
    isAlarmsCadenceLoading: isProcessLoading,
    resetAlarmCadence,
    clientContactData,
    isValidPhonesContact,
    isValidEmailContact,
  }
}
