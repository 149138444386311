import { axiosCustomer, axiosJWT } from 'modules/axios'
import { APP_CONFIG } from 'services/app-config'

export const customersAPIUrl = `${APP_CONFIG.care_cluster}/search-360/customer/invoices`
export const pdfURL = `${APP_CONFIG.care_cluster}/search-360/invoice`
export const zipURL = `${APP_CONFIG.apigee_host}/masmovil/v1/customers/ovid/customer-360`

const CACHE_BILLS = {}

export function findBillings(documentType, documentId, filters) {
  const cacheKey = btoa(JSON.stringify({ documentType, documentId, filters }))
  if (CACHE_BILLS[cacheKey]) {
    return CACHE_BILLS[cacheKey]
  }

  CACHE_BILLS[cacheKey] = axiosJWT({
    method: 'GET',
    url: customersAPIUrl,
    params: { documentType, documentId, ...filters },
  })
    .then(resp => {
      return resp.data
    })
    .catch(err => {
      CACHE_BILLS[cacheKey] = null
      throw err
    })

  return CACHE_BILLS[cacheKey]
}

export const getInvoicePdf = billId =>
  axiosJWT({
    method: 'GET',
    url: `${pdfURL}/${billId}/file`,
  }).then(resp => resp.data)

export function getInvoiceZip(yearMonth) {
  return axiosCustomer({
    method: 'GET',
    responseType: 'blob',
    url: `${zipURL}/${yearMonth}/billings`,
  }).then(resp => resp.data)
}
