import { useDispatch, useSelector } from 'react-redux'
import { get } from 'lodash'

import { fetchCreditScoringAction } from 'modules/NewClientSales/store/actions/index'
import {
  selectCareSVAsSellInfo,
  selectCareCustomer,
  selectScoringDecision,
  selectScoringError,
  selectScoringErrorMessage,
  selectScoringLoading,
  selectSubmission,
} from 'modules/NewClientSales/store/selectors/scoring.selectors.new'
import { selectTariffsApigeeById } from 'modules/tariffs/store-apigee/index'
import {
  mapAddressToInstallAddress,
  getOfferCareSubscriptionScoring,
  getHomeGoFromSvas,
} from 'modules/NewClientSales/store/selectors/scoring.selectors.helpers.new'

export function useScoringSVAs(subscription, isDataChange) {
  const dispatch = useDispatch()

  const scoringDecision = useSelector(selectScoringDecision)
  const scoringError = useSelector(selectScoringError)
  const scoringMessage = useSelector(selectScoringErrorMessage)
  const scoringLoading = useSelector(selectScoringLoading)
  const scoringSubmission = useSelector(selectSubmission)

  const tariffId = get(subscription, 'tariff.id', subscription?.type)
  const tariff = useSelector(selectTariffsApigeeById(tariffId))

  const scoringCareCustomer = useSelector(selectCareCustomer(isDataChange))

  const { submission, sellInfo, payment, organizations } = useSelector(selectCareSVAsSellInfo)

  const isScoringError = scoringError || (scoringDecision && scoringMessage !== '')

  const getScoringData = (installAddress, svas) => {
    const homeGoSva = getHomeGoFromSvas(svas)
    const scort = homeGoSva ? 'XsellHomeGo' : sellInfo.scort
    const channel = homeGoSva ? 'TELESALES' : submission.channel

    const installationAddress = mapAddressToInstallAddress(installAddress)
    const scoringData = {
      submission: {
        ...submission,
        channel,
      },
      sellInfo: {
        ...sellInfo,
        scort,
      },
      organizations,
      customer: {
        ...scoringCareCustomer,
        installationAddress,
      },
      payment,
      offer: getOfferCareSubscriptionScoring(subscription, tariff || { id: tariffId }, svas),
    }

    return scoringData
  }

  const sendScoring = (installAddress, svas) => {
    dispatch(fetchCreditScoringAction(getScoringData(installAddress, svas)))
  }

  return {
    sendScoring,
    getScoringDataRequest: getScoringData,
    scoringDecision,
    scoringSubmission,
    scoringError: isScoringError,
    scoringMessage,
    scoringLoading,
  }
}
