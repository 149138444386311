import PropTypes from 'prop-types'
import styled from 'styled-components'
import { map, isEmpty, upperFirst } from 'lodash'
import { formatDate, formatMonthYear, formatFee } from 'utils'
import { Badge } from 'modules/ui'
import {
  findMobileProduct,
  getFees25WithDiscountFromFinancials,
  getTerminalsFinancials,
  getCancellationCostsLeftFromFinancials,
  formatMonthlyFeeForFinancials,
  checkIsProrratedFinancial,
} from 'services/subscriptions'

import { ItemLine } from '../ItemLine'

const formatFee25 = financials =>
  ` ${formatFee(getFees25WithDiscountFromFinancials(financials))} (${upperFirst(
    formatMonthYear(financials.validFor.endDateTime),
  )})`

export const TerminalDeviceDetails = styled(({ className, subscription }) => {
  const product = findMobileProduct(subscription)

  const terminalsFinancials = getTerminalsFinancials(subscription)

  return (
    <div>
      {product && isEmpty(terminalsFinancials) && (
        <ItemLine type="mobile" label="Dispositivo: NO" />
      )}

      {product && !isEmpty(terminalsFinancials) && (
        <>
          {map(terminalsFinancials, financials => {
            const isProrratedFinancial = checkIsProrratedFinancial(financials)

            return (
              <div className={className}>
                <ItemLine type="mobile" label={`Dispositivo: ${financials.item.name}`} />

                {financials && (
                  <div className="section-badges">
                    <Badge
                      lg
                      keyValContrast
                      value={[
                        {
                          label: isProrratedFinancial
                            ? 'Pago final prorrateado'
                            : 'Fecha de inicio de contrato',
                          value: formatDate(financials.validFor.startDateTime),
                        },
                      ]}
                    />
                    <Badge
                      lg
                      keyValContrast
                      value={[{ label: 'Cuota', value: formatMonthlyFeeForFinancials(financials) }]}
                    />

                    {!isProrratedFinancial && (
                      <Badge
                        lg
                        keyValContrast
                        value={[{ label: 'Cuota 25', value: formatFee25(financials) }]}
                      />
                    )}

                    <Badge
                      lg
                      keyValContrast
                      value={[
                        {
                          label: isProrratedFinancial
                            ? 'Cuotas pendientes'
                            : 'Cuotas pendientes + Cuota 25',
                          value: `${formatFee(getCancellationCostsLeftFromFinancials(financials))}`,
                        },
                      ]}
                    />
                  </div>
                )}
              </div>
            )
          })}
        </>
      )}
    </div>
  )
})`
  > .section-badges {
    display: flex;
    justify-content: left;
    align-content: center;
    flex-wrap: wrap;
    margin-top: 24px;

    > .badge {
      margin-bottom: 16px;
    }
  }

  > .icon {
    margin-right: 8px;
  }

  > .MuiTypography-root {
    margin-right: 18px;
  }

  > .badge {
    margin-top: 24px;
  }
`

TerminalDeviceDetails.propTypes = {
  className: PropTypes.string,
  subscription: PropTypes.object,
}
