import { useEffect, useState } from 'react'
import { PropTypes } from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { isNil, map } from 'lodash'

import { selectSfid } from 'modules/Auth'
import {
  selectHomeGoAlarmsSva,
  selectCancelHomeGoSvaOrderIsLoading,
  selectCancelHomeGoSvaOrderError,
} from 'services/customer-sva/selectors'
import {
  HOMEGO_SVA_CADENCE_STATUS,
  HOMEGO_SVA_CADENCE_SUBSTATUS,
  HOMEGO_SVA_ERROR_MESSAGES,
  HOMEGO_SVA_SICOR_STATUS,
} from 'services/customer-sva/constants'
import { actions as customerSvaActions } from 'services/customer-sva/actions'
import { isHomeGoOrderCancelAllowedSfid } from 'services/customer-sva/helpers'

import { selectSvaHomeGoOnflyFlag, selectSvaRegisterFlag } from 'services/feature-flag/selectors'
import { useHomeGoSva } from 'modules/NewClientSales/hooks/CustomerSva/useHomeGoSva'

import { useHistory, useLocation } from 'react-router-dom'
import { buildPath, AppPaths } from 'modules/AppPaths'
import { urlToRouterParams } from 'modules/Router/helpers'
import { selectURLDocumentId, selectURLDocumentType } from 'modules/CustomerDocument'
import { CVMActions } from 'modules/offers-configuration/CVMActions.constants'
import { selectContracts } from 'services/customer-360/selectors'
import { helpers } from 'services/customer-360/helpers'
import { AlarmBoard } from '../../components/AlarmBoard'
import { AlarmInfo, AlarmTypes } from '../../alarms.constants'
import { getAlarmLevel, getAlarmInfoBy } from '../../alarms.helpers'
import {
  selectIsAlarmsLoading,
  selectAlarmsError,
  selectAlarms,
} from '../../store/alarms.selectors'

function getIconStrForAlarm(alarm) {
  const alarmInfo = getAlarmInfoBy(alarm.type, alarm.subType)

  if (AlarmInfo.Bills_Pending.id === alarmInfo.id) {
    return 'flag'
  }

  switch (getAlarmLevel(alarm)) {
    case 'WARNING':
      return 'info'
    case 'ERROR':
      return 'warningOutlined'
    case 'INFO':
      return 'info'
    default:
      return 'info'
  }
}

function translateAlarmLevelToUI(alarm) {
  const level = getAlarmLevel(alarm)

  if (level === 'ERROR') {
    return 'alert'
  }

  return level.toLowerCase()
}

function isSvaNotInstalledBySicor(sva) {
  return (
    sva.workflowState === HOMEGO_SVA_CADENCE_STATUS.SBN_READY_TO_INSTALL &&
    sva.status === HOMEGO_SVA_CADENCE_SUBSTATUS.INCIDENCE &&
    sva.errorCode === HOMEGO_SVA_ERROR_MESSAGES.ALARM_NOT_INSTALLED
  )
}

function getSubdescription(sva) {
  return sva.status === HOMEGO_SVA_CADENCE_SUBSTATUS.INCIDENCE &&
    sva.workflowState === HOMEGO_SVA_CADENCE_STATUS.SBN_READY_TO_INSTALL
    ? sva.cancellationMessage
    : ''
}

export function AlarmsContainer({ className }) {
  const dispatch = useDispatch()

  const documentType = useSelector(selectURLDocumentType)
  const documentId = useSelector(selectURLDocumentId)
  const contracts = useSelector(selectContracts)

  const buildPathTo = (opId, phoneNumber) => {
    const product = helpers.getProductByLineId(contracts, phoneNumber)

    if (!phoneNumber || !product) {
      return false
    }
    return buildPath(
      AppPaths.cvm.id,
      { documentType, documentId },
      { subscription_id: product.id, op: opId },
    )
  }

  const history = useHistory()
  const location = useLocation()
  const handleClickAddSva = pathTo => {
    if (pathTo) {
      const url = urlToRouterParams(location, pathTo)
      history.push(url)
    }
  }

  const sfid = useSelector(selectSfid)

  const [selectedHomeGoSvaDetails, setSelectedHomeGoSvaDetails] = useState(null)
  const [selectedHomeGoSvaCancellation, setSelectedHomeGoSvaCancellation] = useState(null)
  const alarms = useSelector(selectAlarms)

  const svaHomeGoCustomerSvas = useSelector(selectHomeGoAlarmsSva)
  const svaHomeGoAlarmFlag = useSelector(selectSvaHomeGoOnflyFlag)
  const svaRegisterFlag = useSelector(selectSvaRegisterFlag)

  const cancelHomeGoOrderIsLoading = useSelector(selectCancelHomeGoSvaOrderIsLoading)
  const cancelHomeGoOrderError = useSelector(selectCancelHomeGoSvaOrderError)
  const [cancelHomeGoOrderResultIsOpen, setCancelHomeGoOrderResultIsOpen] = useState(false)

  const { findHomeGoSvaInit, hasFindHomeGoSvaError } = useHomeGoSva()

  const mapSvsRequestTpToAlarm = svaServiceRequest =>
    svaServiceRequest.map(sva => ({
      type: AlarmTypes.SVAs.id,
      subType: null,
      level: sva.errorMessage ? 'alert' : 'warning',
      title: `Alarma HOMEGO - ${sva.msisdn}`,
      description: sva.errorMessage || sva.workflowStateDescription,
      subdescription: getSubdescription(sva),
      actions: [
        ...(sva.workflowState === HOMEGO_SVA_CADENCE_STATUS.SBN_READY_TO_INSTALL && sva.svaSicorName
          ? [
              {
                name: 'Más info.',
                onClick: () => {
                  setSelectedHomeGoSvaDetails(sva)
                },
              },
            ]
          : []),

        ...(sva.status === HOMEGO_SVA_CADENCE_SUBSTATUS.IN_PROGRESS &&
        isHomeGoOrderCancelAllowedSfid(sfid)
          ? [
              {
                name: 'Cancelar',
                onClick: () => {
                  setSelectedHomeGoSvaCancellation(sva)
                },
              },
            ]
          : []),

        ...(isSvaNotInstalledBySicor(sva)
          ? [
              {
                name: 'Volver a añadir sva',
                onClick: () => handleClickAddSva(buildPathTo(CVMActions.add_sva.id, sva.msisdn)),
              },
            ]
          : []),
      ],
    }))

  const onConfirmHomeGoSvaCancellation = () => {
    const { msisdn, workflowId } = selectedHomeGoSvaCancellation

    dispatch(customerSvaActions.cancelHomeGoSvaOrder(msisdn, workflowId))
  }

  useEffect(() => {
    if (!cancelHomeGoOrderIsLoading && !!selectedHomeGoSvaCancellation) {
      setSelectedHomeGoSvaCancellation(null)

      setCancelHomeGoOrderResultIsOpen(true)
    }
  }, [cancelHomeGoOrderIsLoading])

  const svaHomeGoCustomerAlarms = svaHomeGoAlarmFlag
    ? mapSvsRequestTpToAlarm(
        svaHomeGoCustomerSvas.filter(homeGoSva => {
          if (svaRegisterFlag && isSvaNotInstalledBySicor(homeGoSva)) {
            return false
          }
          return (
            homeGoSva.workOrderStatus !== HOMEGO_SVA_SICOR_STATUS.INSTALLED &&
            homeGoSva.workOrderStatus !== HOMEGO_SVA_SICOR_STATUS.CANCELLED &&
            (homeGoSva.workflowState !== HOMEGO_SVA_CADENCE_STATUS.END ||
              (homeGoSva.status === HOMEGO_SVA_CADENCE_SUBSTATUS.COMPLETED &&
                !!homeGoSva.errorMessage))
          )
        }),
      )
    : []

  const alarmsEnriched = map(alarms.concat(svaHomeGoCustomerAlarms), alarm => {
    return {
      ...alarm,
      level: translateAlarmLevelToUI(alarm),
      icon: getIconStrForAlarm(alarm),
    }
  })

  const onCloseCancelHomeGoOrderResult = () => {
    if (!cancelHomeGoOrderError) {
      findHomeGoSvaInit()
    }
    setCancelHomeGoOrderResultIsOpen(false)
  }

  const allProps = {
    className,
    isLoading: useSelector(selectIsAlarmsLoading),
    hasErrors: !isNil(useSelector(selectAlarmsError)),
    alarms: alarmsEnriched,
    isHomeGoSvaDetailsOpen: !!selectedHomeGoSvaDetails,
    selectedHomeGoSvaDetails,
    setSelectedHomeGoSvaDetails,
    isHomeGoSvaConfirmCancelOpen: !!selectedHomeGoSvaCancellation,
    setSelectedHomeGoSvaCancellation,
    onConfirmHomeGoSvaCancellation,
    cancelHomeGoOrderIsLoading,
    cancelHomeGoOrderError,
    cancelHomeGoOrderResultIsOpen,
    onCloseCancelHomeGoOrderResult,
    hasFindHomeGoSvaError,
    findHomeGoSvaInit,
  }

  return <AlarmBoard {...allProps} />
}

AlarmsContainer.propTypes = {
  className: PropTypes.string,
}
