import PropTypes from 'prop-types'

import { Typography, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import { TextStyles } from 'utils/text'
import { formatNumberWithComma } from 'utils/formatting/index'

const useStyles = makeStyles({
  'price-summary-border': {
    borderLeft: '1px solid #DBDCDC',
  },
})
export function OfferSummaryFee({ payments, isPro }) {
  const classes = useStyles()

  const finalPayment = payments.find(payment => payment.duration === 0) || {
    amount: 0,
    duration: 0,
  }
  return (
    <Box display="flex" flexGrow="1">
      {payments.length > 1 && (
        <Box flexGrow="1" px="24px" className={classes['price-summary-border']}>
          <Typography
            {...TextStyles.subtitle2Dark({
              className: 'm-b-8',
            })}>
            Precios Temporales:
          </Typography>
          {payments.map(
            (item, index) =>
              (item.duration && (
                // eslint-disable-next-line react/no-array-index-key
                <div key={`${item.duration}-${index}`}>
                  {' '}
                  <Box display="flex" py="4px">
                    <Typography
                      {...TextStyles.body({
                        className: 'bold p-r-4',
                      })}
                      data-hook={`temporary-${index}`}>
                      {`${formatNumberWithComma(item.amount)} €/mes`}
                      {`(meses ${payments[index - 1]?.duration || 0} a ${item.duration})`}
                    </Typography>
                  </Box>
                </div>
              )) ||
              null,
          )}
        </Box>
      )}
      <Box flexGrow="1" px="24px" className={classes['price-summary-border']}>
        <Typography
          {...TextStyles.subtitle2Dark({
            className: 'm-b-8',
          })}>
          {payments.length > 1 ? 'Después' : 'Precio total'}:
        </Typography>
        <Box display="flex">
          <Typography
            {...TextStyles.title1BoldDark({
              className: 'p-y-4',
            })}
            data-hook="final-price">
            {formatNumberWithComma(finalPayment.amount)} €/mes{isPro && ' (impuestos no incl.)'}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

OfferSummaryFee.propTypes = {
  payments: PropTypes.array,
  isPro: PropTypes.bool,
}
