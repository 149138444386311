import { get, isEmpty } from 'lodash'
import { getCoverageToken, selectCoverageFullAddress } from 'modules/Coverage'
import { selectQueryParams } from 'modules/Router'
import { createSelector, createStructuredSelector } from 'reselect'
import { COMPANY, SIGNUP_TYPE_PORTABILITY, AUTONOMO } from 'services/global-constants'
import {
  selectIsSubscriptionQueryParam,
  selectIsMobileOnly,
  selectIsMultiSimQueryParam,
} from 'modules/Router/store/index'

import {
  FFTH_ROUTER_PENALTY_TAX_FREE,
  ADSL_ROUTER_PENALTY_TAX_FREE,
  AGILE_TV_PENALTY_TAX_FREE,
} from 'modules/NewClientSales/constants'

import { SCORING_DENIEND, CONVERGENT, RESIDENTIAL, PRO } from 'modules/SharedSales/constants'

import {
  selectModifyContactMediaError,
  selectPostalAddressValuesError,
  selectEmailValueError,
} from 'modules/CustomerInfo'

import { getLegalTextPenaltyValues } from 'modules/contentful/helpers'
import { selectShouldMaskIban } from 'modules/Iban'
import { SVA_HOMEGO_CODE } from 'modules/offers-configuration/constants'

import {
  BILLING_ADDRESS,
  INSTALLATION_ADDRESS,
} from 'modules/SharedSales/constants/shippingAddress'
import {
  selectAccount,
  selectAllLinesWithDonor,
  selectHasAgileTV,
  selectSelectedHomeGoAlarm,
  selectHasSmartTv,
  selectHasSomeTerminal,
  selectIsElFijo,
  selectLandlineOffer,
  selectOrderCheckout,
  selectPortabilityLinesByTitular,
  selectSalesTypes,
  selectTerminalPaymentInfo,
  selectTerminalsShipping,
  selectIsPro,
  selectIsPosSale,
  selectBasketRequestLoading,
  selectedLandlineProSVAs,
  selectMainlineTariffId,
  selectVerbalId,
  selectIsCompany,
  selectHasSomeFinancedTerminal,
  selectAccountSegment,
  getBasket,
} from './Basket.selectors'
import {
  selectSelectedTerminalsWithComputedPrices,
  selectTPVTerminals,
} from './BasketWithTaxes.selectors'
import { selectBillingAddress, selectMainAddress, selectShippingAddress } from './billing.selectors'
import { orderCheckoutSelectors, selectPersonalData } from './checkout.selectors'
import {
  selectErrorCheckout,
  selectHasDeliveryAddress,
  selectLinesForLegal,
  selectOrderData,
  selectShowConfirm,
  selectIsExistingClientSaleFlow,
} from './NewClientSales.selectors'
import {
  selectIsOrderCreated,
  selectOrderLoading,
  selectOrderStatusError,
  selectTaskId,
  selectOrderIsProcessed,
  selectSubscriptionId,
  selectOrderCheckingId,
} from './orders.selectors'
import {
  selectScoringError,
  selectScoringDecision,
  selectScoringRequestBodyNew,
  selectScoringErrorMessage,
} from './scoring.selectors.new'
import {
  selectCadenceAlarmsInvoicingAddressSales,
  selectCadenceAlarmsSendAddressSales,
  selectHomeGoCustomerInfo,
  selectHomeGoOtherAddressSales,
} from './cadence.selectors'
import { mapToSummaryAddress, getFormattedPriceWithTaxes } from './Summary.selectors.helpers'
import { selectSaleTax } from './Taxes.selectors'

const selectBillingFullAddress = createSelector(
  selectBillingAddress,
  ({ address, formattedAddress }) => formattedAddress || address,
)
const selectTerminalDeliveryAddress = createSelector(
  selectHasSomeTerminal,
  selectTerminalsShipping,
  selectCoverageFullAddress,
  selectBillingFullAddress,
  (hasSomeTerminal, terminalShipping, coverageAddress, billingAddress) => {
    if (!hasSomeTerminal) return ''
    // TODO: Check if we need to add the ismobile only
    return mapToSummaryAddress(terminalShipping, coverageAddress, billingAddress)
  },
)

const selectShippingFullAddress = createSelector(
  selectShippingAddress,
  ({ line }) => line.formattedAddress || line.address,
)

const selectHasSelectedTerminalsWithInitialPayment = createSelector(
  selectSelectedTerminalsWithComputedPrices,
  terminals =>
    terminals.all.find(terminal =>
      get(terminal, 'computedPrices.initialPayment', get(terminal, 'computedPrices.totalPayment')),
    ),
)

const selectTerminalPaymentFinished = createSelector(
  orderCheckoutSelectors,
  selectHasSelectedTerminalsWithInitialPayment,
  selectIsPosSale,
  (checkout, terminalInitialPayment, isPos) => {
    if (isPos) {
      return true
    }

    return (
      get(checkout, 'terminalPaymentInfo', []).some(i => !isEmpty(i) && !i.isFree) ||
      !terminalInitialPayment
    )
  },
)

const selectSummaryDocumentId = createSelector(
  selectAccount,
  ({ segment, documentid, companyCif }) => (segment === COMPANY ? companyCif : documentid),
)

const selectAgileTvTerms = createSelector(
  selectLandlineOffer,
  selectSaleTax,
  (landline, saleTax) => {
    const agileTvTermsTaxFreeAmount = landline.SVA?.terms?.taxFreeAmount
    let agileTvTerms

    if (agileTvTermsTaxFreeAmount) {
      agileTvTerms = {
        ...landline.SVA?.terms,
        taxIncludedAmount: getFormattedPriceWithTaxes(agileTvTermsTaxFreeAmount, saleTax),
      }
    }
    return agileTvTerms
  },
)

const selectLegalData = createSelector(
  [
    selectLandlineOffer,
    selectOrderData,
    selectHasAgileTV,
    selectHasSmartTv,
    selectSelectedHomeGoAlarm,
    selectAgileTvTerms,
    selectPortabilityLinesByTitular,
    selectAllLinesWithDonor,
    selectLinesForLegal,
    selectSummaryDocumentId,
    selectPersonalData,
  ],
  (
    landline,
    { fiberType, terminals, discounts },
    agileTV,
    hasSmartTv,
    selectedHomeGoAlarm,
    agileTvTerms,
    portabilityLinesByTitular,
    portaLinesWithDonor,
    linesLegal,
    documentid,
    { given_name, family_name, additional_name },
  ) => {
    return {
      date: new Date(),
      fiberType,
      terminals,
      isFixedPortability: landline.signUpType === SIGNUP_TYPE_PORTABILITY,
      isFixedInternalPortability: landline.operator.internalPortability,
      isFixedInstallationRequired: landline.operator.installationRequired,
      discounts,
      agileTV: { ...agileTV, terms: agileTvTerms },
      hasSmartTv,
      selectedHomeGoAlarm,
      portabilityLinesByTitular,
      portaLinesWithDonor,
      linesLegal,
      documentid,
      fullName: `${given_name} ${family_name} ${additional_name}`,
    }
  },
)

const selectPermanencyTerms = createSelector(
  selectLandlineOffer,
  landline => landline.tariff.terms,
)

const selectCarteraUpdateValuesError = createSelector(
  selectModifyContactMediaError,
  selectPostalAddressValuesError,
  selectEmailValueError,
  (account, postalAddress, email) => account || postalAddress || email,
)

const selectCanRetryScoring = createSelector(
  selectScoringErrorMessage,
  scoringMessage => scoringMessage !== SCORING_DENIEND,
)

const permanencyTermsWithTax = createSelector(
  selectPermanencyTerms,
  selectSaleTax,
  (permanencyTerms, saleTax) => {
    if (permanencyTerms?.taxFreeAmount) {
      return {
        ...permanencyTerms,
        taxIncludedAmount: getFormattedPriceWithTaxes(permanencyTerms.taxFreeAmount, saleTax),
      }
    }
    return permanencyTerms
  },
)

const selectFfthRouterNoReturnalPenaltyText = createSelector(
  [selectSaleTax],
  saleTax => getLegalTextPenaltyValues(saleTax, FFTH_ROUTER_PENALTY_TAX_FREE),
)

const selectAdslRouterNoReturnalPenaltyText = createSelector(
  [selectSaleTax],
  saleTax => getLegalTextPenaltyValues(saleTax, ADSL_ROUTER_PENALTY_TAX_FREE),
)

const selectAgileTvNoReturnalPenaltyText = createSelector(
  [selectSaleTax],
  saleTax => getLegalTextPenaltyValues(saleTax, AGILE_TV_PENALTY_TAX_FREE),
)

const selectLegalTextsNoReturnalPenaltiesValues = createSelector(
  [
    selectFfthRouterNoReturnalPenaltyText,
    selectAdslRouterNoReturnalPenaltyText,
    selectAgileTvNoReturnalPenaltyText,
  ],
  (ffthRouterNoReturnalPenalty, adslRouterNoReturnalPenalty, agileTvNoReturnalPenalty) => ({
    ffthRouterNoReturnalPenalty,
    adslRouterNoReturnalPenalty,
    agileTvNoReturnalPenalty,
  }),
)

export const selectSummary = createStructuredSelector({
  hasDeliveryAddress: selectHasDeliveryAddress,
  queryParams: selectQueryParams,
  coverageToken: getCoverageToken,
  account: selectAccount,
  installationFullAddress: selectMainAddress,
  billingFullAddress: selectBillingFullAddress,
  shippingFullAddress: selectShippingFullAddress,
  terminalsAddress: selectTerminalDeliveryAddress,
  isOrderCreated: selectIsOrderCreated,
  isOrderStatusError: selectOrderStatusError,
  isOrderLoading: selectOrderLoading,
  orderCheckout: selectOrderCheckout,
  TPVTerminals: selectTPVTerminals,
  tax: selectSaleTax,
  terminalPaymentFinished: selectTerminalPaymentFinished,
  scoring: selectScoringDecision,
  scoringError: selectScoringError,
  checkout: orderCheckoutSelectors,
  hasSomeFinancedTerminal: selectHasSomeFinancedTerminal,
  salesTypes: selectSalesTypes,
  legalData: selectLegalData,
  permanencyTerms: permanencyTermsWithTax,
  isMobileOnly: selectIsMobileOnly,
  isElFijo: selectIsElFijo,
  isPos: selectIsPosSale,
  isPro: selectIsPro,
  carteraUpdateError: selectCarteraUpdateValuesError,
  isSubscription: selectIsSubscriptionQueryParam,
  scoringMessage: selectScoringErrorMessage,
  showConfirm: selectShowConfirm,
  canRetryScoring: selectCanRetryScoring,
  shouldMaskIban: selectShouldMaskIban,
  legalTextsNoReturnalPenaltiesValues: selectLegalTextsNoReturnalPenaltiesValues,
  isOrderProcessed: selectOrderIsProcessed,
  isLoading: selectBasketRequestLoading,
  isMultiSim: selectIsMultiSimQueryParam,
})

const selectHasPaidTerminal = createSelector(
  selectTerminalPaymentInfo,
  payments => payments.some(payment => !!payment.isTPV),
)

export const selectErrorSummary = createStructuredSelector({
  errorCheckout: selectErrorCheckout,
  taskId: selectTaskId,
  documentid: selectSummaryDocumentId,
  hasPaidTerminals: selectHasPaidTerminal,
})

const selectPurchaseHomeItSvaSalesDataSubscription = createSelector(
  [selectSubscriptionId, selectIsMobileOnly],
  (subscriptionId, isOnlyMobile) => ({
    subscription_id: subscriptionId,
    category: isOnlyMobile ? 'MOBILE' : CONVERGENT,
  }),
)

const selectPurchaseHomeItSvaSalesDataSegment = createSelector(
  [selectIsCompany, selectAccountSegment],
  (isCompany, accountSegment) => (isCompany || accountSegment === AUTONOMO ? PRO : RESIDENTIAL),
)

const selectPurchaseHomeItSvaSalesDataScoring = createSelector(
  selectScoringRequestBodyNew,
  scoringRequestBody => {
    const { submission, sellInfo, customer, payment, organizations, offer } = scoringRequestBody

    return {
      ...submission,
      submission,
      sellInfo,
      organizations,
      customer,
      payment,
      offer: {
        ...offer,
        lineDetails: offer.lineDetails.filter(lineDetail =>
          lineDetail.lineType.includes(SVA_HOMEGO_CODE),
        ),
      },
    }
  },
)

const selectPurchaseHomeItSelectedKit = createSelector(
  selectedLandlineProSVAs,
  proSVAs => proSVAs.find(proSVA => proSVA.psId.includes(SVA_HOMEGO_CODE)),
)

const selectHomeGoSendAddress = createSelector(
  [
    selectCadenceAlarmsSendAddressSales,
    selectCadenceAlarmsInvoicingAddressSales,
    getBasket,
    selectHomeGoOtherAddressSales,
  ],
  (cadenceSendAddress, cadenceInvoiceAddress, { homeGo }, homeGoOtherAddress) => {
    if (homeGo.selectedAddressType === BILLING_ADDRESS.value) {
      return {
        ...cadenceInvoiceAddress,
        streetType: cadenceInvoiceAddress.addressType,
        gescal: cadenceInvoiceAddress.id,
        territoryOwner: '',
      }
    }

    return homeGo.selectedAddressType === INSTALLATION_ADDRESS.value
      ? cadenceSendAddress
      : homeGoOtherAddress
  },
)

export const selectPurchaseHomeItSvaSalesData = createStructuredSelector({
  orderId: selectOrderCheckingId,
  msisdn: selectVerbalId,
  subscription: selectPurchaseHomeItSvaSalesDataSubscription,
  invoicingAddress: selectCadenceAlarmsInvoicingAddressSales,
  sendAddress: selectHomeGoSendAddress,
  isCarteraFlow: selectIsExistingClientSaleFlow,
  segment: selectPurchaseHomeItSvaSalesDataSegment,
  customerInfo: selectHomeGoCustomerInfo,
  scoring: selectPurchaseHomeItSvaSalesDataScoring,
  kit: selectPurchaseHomeItSelectedKit,
  tariffId: selectMainlineTariffId,
  isCompany: selectIsCompany,
})
