import { omit } from 'lodash'
import constants from './constants'

export const initialState = {
  loading: {},
  data: {},
  error: {},
}

export const reducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case constants.FIND_TAX: {
      return {
        ...state,
        error: omit(state.error, [payload.zipCode]),
        loading: { ...state.loading, [payload.zipCode]: true },
      }
    }
    case constants.FIND_TAX_SUCCESS: {
      return {
        ...state,
        data: { ...state.data, [payload.zipCode]: payload.data },
        error: omit(state.error, [payload.zipCode]),
        loading: omit(state.loading, [payload.zipCode]),
      }
    }
    case constants.FIND_TAX_ERROR: {
      return {
        ...state,
        error: {
          ...state.error,
          [payload.zipCode]: { code: payload.httpCode, message: payload.info },
        },
        loading: { ...state.loading, [payload.zipCode]: false },
      }
    }
    default:
      return state
  }
}
