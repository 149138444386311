import { useMemo } from 'react'
import PropTypes from 'prop-types'

import { useSelector } from 'react-redux'
import { get, map, head } from 'lodash'

import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import { TextStyles } from 'utils/text'

import { getCompanyInfo } from 'services/global-selectors/selectors'
import { selectIsOrderTypeADSL, selectIsOrderType2p } from 'modules/orders/index'
import { formatFee } from 'utils'
import { getCurrentDateFormatted } from 'utils/dates'

import { useLegalTexts } from 'modules/LegalTexts'
import { HTMLTemplate } from 'modules/ui/components/HTMLTemplate/HTMLTemplate'

const useStyles = makeStyles({
  router: {
    display: 'inline',
  },
})

const CancelLegalRecording = ({
  personalData,
  lines,
  isElFijoCancellation,
  mainlinePenalty,
  mainlineIsClosed,
  totalPenalty,
  replaceableOrder,
}) => {
  const companyInfo = useSelector(getCompanyInfo)
  const currentDate = getCurrentDateFormatted()

  const isConvergent = useSelector(selectIsOrderType2p)
  const isADSL = useSelector(selectIsOrderTypeADSL)

  const hasMainFixedLineSelected = useMemo(
    () => !!lines.find(line => line.lineType === 'main_line' && line.category === 'fixed'),
    [lines],
  )

  const hasMainMobileLineSelected = useMemo(
    () => !!lines.find(line => line.lineType === 'main_line' && line.category === 'mobile'),
    [lines],
  )

  const hasMobileLineSelected = useMemo(
    () =>
      !!lines.find(line => line.lineType === 'extra_line' || line.lineType === 'additional_line'),
    [lines],
  )

  const fixedLines = useMemo(() => lines.filter(line => line.category === 'fixed'), [lines])
  const mobileLines = useMemo(() => lines.filter(line => line.category === 'mobile'), [lines])

  const companyName = get(companyInfo, 'name', '')
  const companyCif = get(companyInfo, 'individualIdentification', '')
  const { legalTexts } = useLegalTexts({ origin: 'cancellation' })

  const styles = useStyles()

  const getCancellationText = textLines => {
    if (!textLines.length) {
      return ''
    }

    const [{ category }] = textLines

    const singularOrPluralTexts =
      textLines.length > 1
        ? { number: ' los números', fixed: 'fijos', mobile: 'móviles' }
        : { number: 'l número', fixed: 'fijo', mobile: 'móvil' }

    return (
      <>
        {' '}
        <span className="bold">procedemos a cancelar</span>
        {` la portabilidad/alta de${singularOrPluralTexts.number} `}
        {category === 'fixed' ? singularOrPluralTexts.fixed : singularOrPluralTexts.mobile}{' '}
        {textLines.map((item, index) => (
          <span key={item.id}>
            <span className="bold">{item.id}</span>
            {index === textLines.length - 2 && ' y '}
            {index !== textLines.length - 1 && index !== textLines.length - 2 && ','}
          </span>
        ))}
      </>
    )
  }

  return (
    <>
      <Typography {...TextStyles.paragraphDark()}>
        Como nos has solicitado vamos a proceder a la cancelación de tus servicios con YOIGO.
      </Typography>
      <Typography {...TextStyles.paragraphDark()}>
        ¿Eres el titular de las líneas? <strong>SI</strong>
      </Typography>
      <Typography {...TextStyles.paragraphDark()}>
        Confírmame tu Nombre y Apellidos:{' '}
        <span className="bold">
          {personalData.fullName}
          {companyName ? ` / ${companyName}` : null}
        </span>
      </Typography>
      <Typography {...TextStyles.paragraphDark()}>
        Confírmame tu DNI / CIF:{' '}
        <span className="bold">
          {get(personalData, 'identification.identificationId')}
          {companyCif ? ` / ${companyCif}` : null}
        </span>
      </Typography>
      <Typography {...TextStyles.paragraphDark()}>
        Confírmame las líneas que deseas Cancelar (en caso de portabilidad)
        <span className="bold">
          {isElFijoCancellation
            ? ` Linea fija: ${head(lines).id}`
            : map(lines, (item, index) => {
                return (
                  <span key={item.id}>
                    {' '}
                    {item.category === 'fixed' ? 'Línea fija' : 'Línea Movil'}: {item.id}
                    {index < lines.length - 1 ? ' /' : ''}
                  </span>
                )
              })}
        </span>
      </Typography>
      {replaceableOrder && (
        <Typography {...TextStyles.paragraphDark()}>
          La línea <span className="bold">{replaceableOrder.lines[0].id}</span> formará parte del
          paquete principal, <span className="bold">sustituyendo a</span> la línea{' '}
          <span className="bold">{lines.find(line => line.lineType === 'main_line').id}</span>{' '}
          cancelada.
        </Typography>
      )}
      <Typography {...TextStyles.paragraphDark()}>
        Hoy a fecha de {currentDate.formatedDate}, siendo las {currentDate.hours} Horas,{' '}
        {currentDate.minutes} Minutos {getCancellationText(fixedLines)}
        {!!fixedLines.length && '. '}
        {!!fixedLines.length && !!mobileLines.length && 'También'}
        {getCancellationText(mobileLines)}
      </Typography>

      {isConvergent && hasMainFixedLineSelected && mainlineIsClosed && (
        <Typography {...TextStyles.paragraphDark()}>
          Como indica tu contrato te serán aplicadas las siguientes penalizaciones:{' '}
          {mainlinePenalty.fixed > 0 && (
            <>
              <span className="bold">{formatFee(mainlinePenalty.fixed)}</span> en concepto de gastos
              de instalación.
            </>
          )}
          <span className="bold">
            <HTMLTemplate
              className={styles.router}
              html={
                isADSL
                  ? legalTexts.legal_text_router_penalty_adsl
                  : legalTexts.legal_text_router_penalty_ftth
              }
            />
          </span>{' '}
          si no devuelves el router.
        </Typography>
      )}

      {totalPenalty - (mainlinePenalty?.fixed || 0) > 0 &&
        (isElFijoCancellation || hasMobileLineSelected || hasMainMobileLineSelected) && (
          <Typography {...TextStyles.paragraphDark()}>
            Solo si los dispositivos llevan penalización asociada y han sido entregados, como indica
            tu contrato, te serán aplicadas las siguientes penalizaciones:{' '}
            <span className="bold">
              {formatFee(totalPenalty - (mainlinePenalty?.fixed || 0))} si no devuelves el equipo
            </span>{' '}
            facilitado.
          </Typography>
        )}

      <Typography {...TextStyles.paragraphDark()}>Muchas gracias. Buenos días</Typography>
    </>
  )
}

CancelLegalRecording.propTypes = {
  personalData: PropTypes.object,
  lines: PropTypes.array,
  isElFijoCancellation: PropTypes.bool,
  mainlineIsClosed: PropTypes.bool,
  mainlinePenalty: PropTypes.shape({
    fixed: PropTypes.number,
    mobile: PropTypes.number,
  }),
  totalPenalty: PropTypes.number,
  replaceableOrder: PropTypes.object,
}

export default CancelLegalRecording
