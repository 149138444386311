import { get } from 'lodash'

export const getDiscounts = (state, category) => {
  return get(state, `discountsAgentCategories.discounts.${category}`, [])
}

export const isLoading = (state, category) =>
  get(state, `discountsAgentCategories.loading.${category}`, false)

export const getError = (state, category) =>
  get(state, `discountsAgentCategories.error.${category}`, null)

export const getErrorCode = (state, category) =>
  get(state, `discountsAgentCategories.error.${category}.code`)
