import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { applyTax, getTax } from 'services/taxes'
import { selectors as customerSelectors } from 'services/customer-360'
import { extractAllDataMobileConsumption } from 'services/subscriptions/helpers'
import { isProCustomer } from 'services/customer-360/helpers'
import { getSegment } from 'services/customer-360/selectors'
import { UsagesMobileCard } from '../../components/Usages'

export function UsagesMobileCardContainer({ sub, isElFijo, highLigthedUI, isPrepaid }) {
  const customerSegment = useSelector(state => getSegment(state))
  const taxNeeded = !isProCustomer(customerSegment)

  const billingAddress = useSelector(state => customerSelectors.getBillingAddress(state))
  const postCode = get(billingAddress, 'postCode')
  const tax = (taxNeeded && useSelector(state => getTax(state, postCode))) || 0

  const mobileComsumption = extractAllDataMobileConsumption(sub)

  const applyTaxComsumptionData = (data, consumption) => applyTax(tax, get(data, consumption, 0))

  return (
    <>
      {mobileComsumption.map((extractDataMobileConsumptionData, index) => (
        <UsagesMobileCard
          key={`UsagesMobileCard-${extractDataMobileConsumptionData.type}`}
          data={{
            mobile: {
              ...extractDataMobileConsumptionData,
              callsConsumedWithTaxes: applyTaxComsumptionData(
                extractDataMobileConsumptionData,
                'callsConsumed',
              ),
              premiumCallsConsumedWithTaxes: applyTaxComsumptionData(
                extractDataMobileConsumptionData,
                'premiumCallsConsumed',
              ),
              internationalCallsConsumedWithTaxes: applyTaxComsumptionData(
                extractDataMobileConsumptionData,
                'internationalCallsConsumed',
              ),
              totalExtrasMobileWithTaxes: applyTaxComsumptionData(
                extractDataMobileConsumptionData,
                'totalExtrasMobile',
              ),
              consumedCallsWithoutExtrasWithTaxes: applyTaxComsumptionData(
                extractDataMobileConsumptionData,
                'consumedCallsWithoutExtras',
              ),
              smsAmountConsumedWithTaxes: applyTaxComsumptionData(
                extractDataMobileConsumptionData,
                'smsAmountConsumed',
              ),
              othersConsumedWithTaxes: applyTaxComsumptionData(
                extractDataMobileConsumptionData,
                'othersConsumed',
              ),
              totalDataConsumedWithTaxes: applyTaxComsumptionData(
                extractDataMobileConsumptionData,
                'totalDataConsumed',
              ),
            },
          }}
          highLigthedUI={highLigthedUI}
          isElFijo={isElFijo}
          isPrepaid={isPrepaid}
          toggable
          collapsed={index !== 0}
          taxNeeded={taxNeeded}
        />
      ))}
    </>
  )
}

UsagesMobileCardContainer.propTypes = {
  sub: PropTypes.object,
  isElFijo: PropTypes.bool,
  highLigthedUI: PropTypes.object,
  isPrepaid: PropTypes.bool,
}
