import PropTypes from 'prop-types'

import { LINE_TYPE } from 'modules/Lines/constants'
import { Typography } from '@material-ui/core'

import { ButtonLink } from 'components/ui'

export const SelectedProvisioningNumber = ({
  numberDisplay: { isError, isLoading, msg, onFetchProvisioningNumber },
  lineType,
}) => {
  return isError ? (
    <>
      <Typography
        variant="body1"
        color="error"
        className="p-l-8 p-r-20 align-self-center"
        data-hook={`${lineType}.provisioningNumber`}>
        {msg}
      </Typography>

      {!isLoading && lineType === LINE_TYPE.LANDLINE && (
        <ButtonLink onClick={onFetchProvisioningNumber}>Reintentar</ButtonLink>
      )}
    </>
  ) : (
    <Typography
      variant="body1"
      className="bold p-r-20"
      data-hook={`${lineType}.provisioningNumber`}>
      {msg}
    </Typography>
  )
}

SelectedProvisioningNumber.propTypes = {
  numberDisplay: PropTypes.object.isRequired,
  lineType: PropTypes.string.isRequired,
}
