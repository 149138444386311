import { createSelector } from 'reselect'
import { get, chain, find, map, uniqBy, sortBy, head, isEmpty } from 'lodash'
import { startOfMonth, differenceInCalendarMonths, addDays, isPast } from 'date-fns'

import { selectURLProductId } from 'services/customer-products'

import {
  formatRangeFromTodayToEndDate,
  getMobileLineNumber,
  getFixedLineNumber,
} from 'services/subscriptions/helpers'

import {
  SVA_HEBE_CODE,
  SVA_HEBE_DISCOUNT_CODE,
  SVA_HOMEGO_CODE,
  SVA_SMARTHOME_CODE,
} from 'modules/offers-configuration/constants'
import {
  MOBILE_PRODUCT_NAME,
  DUO_PRINCIPAL_TARIFF_ID,
  CONVERGENT_CATEGORY,
  OLD_DISCOUNT_AMOUNT_DUO_PRINCIPAL,
} from 'services/subscriptions/constants'

const getSubscriptionsState = state => get(state, 'subscriptions')
export const getDisableBonusState = createSelector([getSubscriptionsState], subscriptions =>
  get(subscriptions, 'disableDataBonus', {}),
)

const getRawSubs = createSelector(getSubscriptionsState, subscriptions =>
  get(subscriptions, 'subs', {}),
)

export const getSubscriptions = createSelector(getRawSubs, subsData => {
  const subsDataSorted = sortBy(subsData, ['order'])

  return uniqBy(map(subsDataSorted, 'data'), 'id')
})

export const isLoading = state => get(state, 'subscriptions.loading')

export const getErrorCode = state => get(state, 'subscriptions.error.httpCode')
export const getErrorMsg = state => get(state, 'subscriptions.error.info')

export const selectSubscriptions = createSelector(getRawSubs, subscriptions => subscriptions)

export const selectHasSubscriptions = createSelector(
  selectSubscriptions,
  subscriptions =>
    chain(subscriptions).flatMap().filter({ loading: false, error: null }).value().length > 0,
)

export const selectFirstSubscription = createSelector(selectSubscriptions, subscriptions =>
  chain(subscriptions)
    .flatMap()
    .filter({ loading: false, error: null })
    .get('[0].data', null)
    .value(),
)

export const getSubscriptionContentDuoPrincipal = createSelector(
  selectSubscriptions,
  subscriptions =>
    chain(subscriptions)
      .some(subscription => get(subscription, 'data.type') === DUO_PRINCIPAL_TARIFF_ID)
      .value(),
)

export const getSubscriptionDuoPrincipal = createSelector(selectSubscriptions, subscriptions =>
  chain(subscriptions)
    .filter(subscription => get(subscription, 'data.type') === DUO_PRINCIPAL_TARIFF_ID)
    .head()
    .get('data', null)
    .value(),
)

export const getSubscriptionConvergent = createSelector(selectSubscriptions, subscriptions =>
  chain(subscriptions)
    .filter(subscription => get(subscription, 'data.category') === CONVERGENT_CATEGORY)
    .head()
    .get('data', null)
    .value(),
)

export const duoPrincipalContentOldTariff = createSelector(
  getSubscriptionDuoPrincipal,
  subscription =>
    get(subscription, 'charges', []).length === 1 &&
    get(head(get(subscription, 'charges', [])), 'subscription_discount.amount') ===
      OLD_DISCOUNT_AMOUNT_DUO_PRINCIPAL,
)

export const selectSubscriptionByMsisdn = msisdn =>
  createSelector(getRawSubs, subscriptionsObj => get(subscriptionsObj, [msisdn, 'data']))

export const getSubscriptionByCurrentProductId = createSelector(
  [getRawSubs, selectURLProductId],
  (subscriptionsObj, productId) => get(subscriptionsObj, [productId, 'data']),
)

export const selectSubscriptionByProductId = productId =>
  createSelector(getRawSubs, subscriptionsObj => get(subscriptionsObj, [productId, 'data']))

export const selectSubscriptionById = subscriptionId =>
  createSelector(getRawSubs, subscriptions =>
    Object.values(subscriptions).find(
      subscription => subscription?.data?.subscription_id === subscriptionId,
    ),
  )
export const getSubscriptionByMsisdnErrorCode = (state, msisdn) =>
  get(state, `subscriptions.subs[${msisdn}].error.httpCode`, null)

export const getSubscriptionByMsisdnErrorMsg = (state, msisdn) =>
  get(state, `subscriptions.subs[${msisdn}].error.info`, null)

export const isTariffChangeLoading = (state, msisdn) =>
  get(state, `subscriptions.changeTariff[${msisdn}.loading]`, false)

export const isTariffChangeDone = (state, msisdn) =>
  get(state, `subscriptions.changeTariff[${msisdn}].done`, false)

export const getTariffChangeErrorCode = (state, msisdn) =>
  get(state, `subscriptions.changeTariff[${msisdn}].error.httpCode`, null)

export const getTariffChangeErrorMsg = (state, msisdn) =>
  get(state, `subscriptions.changeTariff[${msisdn}].error.info`, null)

export const getTariffChangeErrorMsgV3 = (state, msisdn) =>
  get(state, `subscriptions.changeTariffV3[${msisdn}].error.info`, null)

export function selectSetBundlesLoading(msisdn) {
  return createSelector(getSubscriptionsState, subscriptions =>
    get(subscriptions, `setBundles[${msisdn}.loading]`, false),
  )
}

export function selectSetBundlesDone(msisdn) {
  return createSelector(getSubscriptionsState, subscriptions =>
    get(subscriptions, `setBundles[${msisdn}].done`, false),
  )
}

export function selectSetBundlesErrorCode(msisdn) {
  return createSelector(getSubscriptionsState, subscriptions =>
    get(subscriptions, `setBundles[${msisdn}].error.httpCode`, null),
  )
}

export function selectSetBundlesErrorMsg(msisdn) {
  return createSelector(getSubscriptionsState, subscriptions =>
    get(subscriptions, `setBundles[${msisdn}].error.info`, null),
  )
}

export const isSetUpsellBundlesLoading = (state, msisdn) =>
  get(state, `subscriptions.setUpsellBundles[${msisdn}.loading]`, false)

export const isSetUpsellBundlesDone = (state, msisdn) =>
  get(state, `subscriptions.setUpsellBundles[${msisdn}].done`, false)

export const getSetUpsellBundlesErrorCode = (state, msisdn) =>
  get(state, `subscriptions.setUpsellBundles[${msisdn}].error.httpCode`, null)

export const getSetUpsellBundlesErrorMsg = (state, msisdn) =>
  get(state, `subscriptions.setUpsellBundles[${msisdn}].error.info`, null)

export const selectPortOutInfo = msisdn =>
  createSelector(getSubscriptionsState, subState =>
    get(subState, ['portOuts', msisdn, 'data'], null),
  )

export const isPortOutInfoLoading = (state, msisdn) =>
  get(state, `subscriptions.portOuts[${msisdn}].loading`, false)

export const getPortOutInfoErrorCode = (state, msisdn) =>
  get(state, `subscriptions.portOuts[${msisdn}].error.httpCode`, null)

export const getPortOutInfoErrorMsg = (state, msisdn) =>
  get(state, `subscriptions.portOuts[${msisdn}].error.info`, null)

export const getMainSubscription = state => find(getSubscriptions(state), { name: 'main' })

export const getMainSubscriptionMsisdn = state => get(getMainSubscription(state), 'id')

export function hasPortOutInfo(state, msisdn) {
  const sub = selectSubscriptionByMsisdn(msisdn)(state)

  return !!(
    selectPortOutInfo(getMobileLineNumber(sub))(state) ||
    selectPortOutInfo(getFixedLineNumber(sub))(state)
  )
}

const getProductsSpecificationsMobile = createSelector([getSubscriptionByCurrentProductId], data =>
  chain(data)
    .get('productsSpecifications')
    .find(productSpecifications => productSpecifications.name === MOBILE_PRODUCT_NAME)
    .value(),
)

const getProductSpecCharacteristic = createSelector([getProductsSpecificationsMobile], data =>
  get(data, 'productSpecCharacteristic', []),
)

export const getPinMobile = createSelector(
  [getProductSpecCharacteristic],
  data => chain(data).find({ name: 'pin' }).get('value').value() || '',
)

export const getPukMobile = createSelector(
  [getProductSpecCharacteristic],
  data => chain(data).find({ name: 'puk' }).get('value').value() || '',
)

export const getPin2Mobile = createSelector(
  [getProductSpecCharacteristic],
  data => chain(data).find({ name: 'pin2' }).get('value').value() || '',
)

export const getPuk2Mobile = createSelector(
  [getProductSpecCharacteristic],
  data => chain(data).find({ name: 'puk2' }).get('value').value() || '',
)

export const getImsiMobile = createSelector(
  [getProductSpecCharacteristic],
  data => chain(data).find({ name: 'imsi' }).get('value').value() || '',
)

export const isDisableBonusLoading = msisdn =>
  createSelector([getDisableBonusState], data => get(data, `${msisdn}.loading`))

export const getDisableBonusError = msisdn =>
  createSelector([getDisableBonusState], data => get(data, `${msisdn}.error`))

export const getDisabledBonusOK = msisdn =>
  createSelector([getDisableBonusState], data => get(data, `${msisdn}.success`))

export const getDisabledBonusPreviousStatus = msisdn =>
  createSelector([getDisableBonusState], data => get(data, `${msisdn}.previousStatus`, false))

export const getDisableSvaState = createSelector([getSubscriptionsState], subscriptions => {
  return get(subscriptions, 'disableDataSva')
})

export const isDisableSvaError = svaType =>
  createSelector([getDisableSvaState], data => {
    switch (svaType) {
      case SVA_SMARTHOME_CODE:
        return get(data, 'smarthome.error', false)
      case SVA_HOMEGO_CODE:
        // eslint-disable-next-line no-case-declarations
        const error = get(data, 'homego.error', false)
        return error ? { ...error, info: error?.detailMsg } : error
      default:
        return false
    }
  })

export const isDisableSvaSuccess = svaType =>
  createSelector([getDisableSvaState], data => {
    switch (svaType) {
      case SVA_SMARTHOME_CODE:
        return get(data, 'smarthome.success', false)
      case SVA_HOMEGO_CODE:
        return get(data, 'homego.success', false)
      default:
        return false
    }
  })

export const isDisableSvaLoading = svaType =>
  createSelector([getDisableSvaState], data => {
    switch (svaType) {
      case SVA_SMARTHOME_CODE:
        return get(data, 'smarthome.loading', false)
      case SVA_HOMEGO_CODE:
        return get(data, 'homego.loading', false)
      default:
        return false
    }
  })

export const getSubscriptionDiscounts = msisdn =>
  createSelector(getRawSubs, subscriptions => {
    const subscriptionCharges = subscriptions[msisdn]?.data?.charges || []

    return subscriptionCharges
      .filter(charge => charge.meta.type === 'discount')
      .map(discount => {
        const discountCode = get(discount, 'discount_plan.rule_id', null)

        const validFrom = new Date(discount.subscription_discount?.valid_from)
        const validTo = new Date(discount.subscription_discount?.valid_to)

        const lengthRange =
          (validTo && !isPast(validTo) && formatRangeFromTodayToEndDate(validTo.toISOString())) ||
          '-'

        const firstDayOfCurrentMonth = startOfMonth(Date.now())
        const validFromFollowingDay = addDays(validFrom, 1)

        const validFromForDisablingDiscount =
          discountCode === SVA_HEBE_DISCOUNT_CODE ? validFromFollowingDay : firstDayOfCurrentMonth

        return {
          msisdn: get(discount, 'msisdn'),
          discountCode,
          discountId: get(discount, 'subscription_discount.discount_id'),
          name: get(discount, 'discount_plan.name'),
          value: get(discount, 'subscription_discount.amount'),
          activationStamp: validFromForDisablingDiscount,
          duration: differenceInCalendarMonths(validTo, Date.now()),
          lengthRange,
          validFrom,
          validTo,
          unit: get(discount, 'discount_plan.amount_unit'),
          permanency: '',
        }
      })
  })

export const validateActiveDiscount = (subscription, discountCode) => {
  const subscriptionCharges = subscription.charges || []

  return (
    subscriptionCharges
      .filter(charge => charge.meta.type === 'discount')
      .filter(discount => get(discount, 'discount_plan.billing_item_id') === discountCode).length >
    0
  )
}

export const getSvaHebe = createSelector([getSubscriptionByCurrentProductId], data =>
  chain(data)
    .get('vasContracts')
    .filter(vasContract => vasContract.contractTypeId === SVA_HEBE_CODE)
    .value(),
)

export const getSvaSmartHome = createSelector([getSubscriptionByCurrentProductId], data =>
  chain(data)
    .get('vasContracts')
    .filter(vasContract => vasContract.contractTypeId === SVA_SMARTHOME_CODE)
    .value(),
)

export const getSvatHomeGo = createSelector([getSubscriptionByCurrentProductId], data =>
  chain(data)
    .get('vasContracts')
    .filter(vasContract => vasContract.contractTypeId === SVA_HOMEGO_CODE)
    .value(),
)

const getCdctWorkOrderIds = createSelector([getSubscriptionsState], subscriptions => {
  return get(subscriptions, 'cdctWorkOrderIds', {})
})

export const getCdctWorkOrderIdsBySubscriptionId = subscriptionId =>
  createSelector(getCdctWorkOrderIds, documents => get(documents, [subscriptionId, 'data']))

export const getExistVasContract = contractTypeId =>
  createSelector(
    [getSubscriptionByCurrentProductId],
    data =>
      !isEmpty(
        chain(data)
          .get('vasContracts')
          .filter(vasContract => vasContract.contractTypeId === contractTypeId)
          .value(),
      ),
  )
