import { useSelector } from 'react-redux'
import { useMemo } from 'react'
import { createStructuredSelector, createSelector } from 'reselect'
import { selectCustomerOrderLimit } from 'modules/CustomerOrders'
import {
  selectIsSubscriptionQueryParam,
  selectIsCrossSellQueryParam,
} from 'modules/Router/store/index'

import {
  selectHasReachedExtraTariffsLimit,
  selectHasReachedAdditionalLinesLimit,
  selectAdditionalLinesLength,
  selectSelectedAdditionalLines,
} from '../../store'

const selectAddditionalLinesIndex = createSelector(
  selectSelectedAdditionalLines,
  additionalLines => additionalLines.map(line => line.index),
)

const selectLimitInfo = createStructuredSelector({
  hasReachedExtraTariffsLimit: selectHasReachedExtraTariffsLimit,
  hasReachedAdditionalLinesLimit: selectHasReachedAdditionalLinesLimit,
  limit: selectCustomerOrderLimit,
  additionalLinesLength: selectAdditionalLinesLength,
  additionalLinesIndex: selectAddditionalLinesIndex,
  isSubscription: selectIsSubscriptionQueryParam,
  isCrossSell: selectIsCrossSellQueryParam,
})

export function useLimitInfo(index, selectedTariff = {}) {
  const {
    hasReachedExtraTariffsLimit,
    hasReachedAdditionalLinesLimit,
    limit,
    additionalLinesLength,
    additionalLinesIndex,
    isSubscription,
    isCrossSell,
  } = useSelector(selectLimitInfo)

  const canOnlyShowDuo = useMemo(
    () => additionalLinesLength === (isSubscription || isCrossSell ? limit : limit - 1),
    [additionalLinesLength, limit],
  )

  const isSubscriptionAdditional = useMemo(
    () => (isSubscription || isCrossSell) && selectedTariff.tariffType === 'additional_line',
    [isSubscription, selectedTariff],
  )

  const shouldDisableTerminal = useMemo(() => {
    const isExtraAndCrossSell = !isSubscriptionAdditional && isCrossSell
    return (
      !isExtraAndCrossSell &&
      ((hasReachedAdditionalLinesLimit && additionalLinesIndex.indexOf(index) === -1) ||
        isSubscriptionAdditional)
    )
  }, [hasReachedAdditionalLinesLimit, additionalLinesIndex, isSubscriptionAdditional])

  return {
    canOnlyShowDuo,
    hasReachedExtraTariffsLimit,
    hasReachedAdditionalLinesLimit,
    shouldDisableTerminal,
    additionalLinesLength,
  }
}
