import PropTypes from 'prop-types'
import styled from 'styled-components'
import { head } from 'lodash'

import { findFixedProduct, findRouterPermanents } from 'services/subscriptions'

import { ItemLine } from '../ItemLine'
import { PermanentBadgeItem } from '../PermanentBadgeItem'

export const InternetDeviceDetails = styled(({ className, subscription }) => {
  const product = findFixedProduct(subscription)

  const permanent = head(findRouterPermanents(subscription))

  return product ? (
    <div className={`internet-device-details ${className}`}>
      <ItemLine type="router" label="Dispositivo: Router" />
      <PermanentBadgeItem permanent={permanent} />
    </div>
  ) : null
})`
  > .permanent-badge-item {
    margin-top: 18px;
  }
`

InternetDeviceDetails.propTypes = {
  className: PropTypes.string,
  subscription: PropTypes.object,
}
