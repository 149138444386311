import { get } from 'lodash'
import { createSelector } from 'reselect'

import { selectDeviceRenewalIbanValidation } from 'services/feature-flag/selectors'

import {
  selectors as customer360Selectors,
  selectData,
  selectContracts,
} from 'services/customer-360/selectors'
import { helpers as customer360Helpers } from 'services/customer-360/helpers'

import { isDuoTariffId } from 'modules/tariffs/store-apigee/tariffs-apigee.helpers'

import { profiles } from 'modules/Permissions'
import { selectProfileId } from 'modules/Permissions/store/permissions-selectors'

import { SINGLE } from 'services/global-constants'

import {
  isLoading as selectSubscriptionIsLoading,
  selectSubscriptions,
} from 'services/subscriptions/selectors'

import { formatFee } from 'utils'

import {
  SALE_CHANNEL_RETENTION,
  SALE_CHANNEL_RENEWAL,
  SALE_CHANNEL_CTC,
  DECISIONS_ALLOWED_AGENT_PROFILES,
  FINAL_PAYMENT_DECISION_FINANCING,
} from '../constants'

import {
  getSidebarDeviceType,
  getSidebarSaleChannel,
  getDeviceFinancing,
  getDevicePermanent,
  getDevicePenalty,
  getDeviceFee,
  getDeviceFinalPayment,
  getDeviceCancelationToday,
  getDeviceResidualPenaltyDays,
  getShouldAmendPenalty,
  getShouldLetDecideIfAmendPenalty,
  getShouldAllowFinancingDecision,
  getShouldMantainFinancing,
  getShouldAllowFinalPaymentDecision,
  getFinancedDeviceSummaryInfo,
  doesDeviceFinalPaymentExists,
} from './device-renewal.selectors.helpers'

export const getIsRenewalAllowedData = state => get(state, 'deviceRenewal.validation', {})

export const selectIban = createSelector(
  [selectData],
  ({ dataClient }) => {
    return customer360Helpers.getCharacteristic(dataClient.characteristic, 'iban')
  },
)

export const selectRenewalOrderChannel = createSelector(
  selectProfileId,
  agentProfile => {
    switch (agentProfile) {
      case profiles.retention.id:
        return SALE_CHANNEL_RETENTION
      case profiles.customer_loyalty.id:
      case profiles.xselling.id:
        return SALE_CHANNEL_RENEWAL
      default:
        return SALE_CHANNEL_CTC
    }
  },
)

export const selectCanAgentTakeFinancingDecisions = createSelector(
  [selectProfileId],
  profileId =>
    DECISIONS_ALLOWED_AGENT_PROFILES.includes(profileId) || profileId === profiles.admin.id,
)

export const selectCanAgentChooseAnyChannel = createSelector(
  [selectProfileId],
  profileId => profileId === profiles.admin.id,
)

export const selectCanAgentChooseRetentionChannel = createSelector(
  [selectProfileId, selectCanAgentChooseAnyChannel],
  (profileId, canAgentChooseAnyChannel) =>
    canAgentChooseAnyChannel || profileId === profiles.retention.id,
)

export const selectCanAgentChooseCustomerLoyaltyChannel = createSelector(
  [selectProfileId, selectCanAgentChooseAnyChannel],
  (profileId, canAgentChooseAnyChannel) =>
    canAgentChooseAnyChannel ||
    profileId === profiles.retention.id ||
    profileId === profiles.customer_loyalty.id ||
    profileId === profiles.xselling.id,
)

export const selectIsRenewalAllowed = createSelector(
  [getIsRenewalAllowedData],
  validationData => validationData.data?.orderAllowed || null,
)
export const selectIsRenewalTvAllowed = createSelector(
  [getIsRenewalAllowedData],
  validationData => validationData.data?.tvAllowed || null,
)
export const selectRenewalRejectionReasonCode = createSelector(
  [getIsRenewalAllowedData],
  validationData => validationData.data?.rejectionReason,
)
export const selectIsRenewalAllowedLoading = createSelector(
  [getIsRenewalAllowedData],
  validationData => validationData?.loading,
)

const selectIsRenewalAllowedHasError = createSelector(
  [getIsRenewalAllowedData],
  validationData => validationData?.error,
)

export const selectRenewalOfferCode = createSelector(
  [getIsRenewalAllowedData],
  validationData => validationData.data?.offercode,
)

export const selectIbanInformation = createSelector(
  [getIsRenewalAllowedData],
  validationData => validationData.data?.ibanInformation,
)

export const selectHasIbanError = createSelector(
  [selectIbanInformation],
  ibanInformation => !!ibanInformation?.error,
)

export const getDeviceRenewalBasket = state => state.deviceRenewal

export const selectRenewalMsisdn = createSelector(
  getDeviceRenewalBasket,
  basket => basket.msisdn,
)

export const selectRenewalSubscriptionError = createSelector(
  [selectSubscriptions, selectRenewalMsisdn],
  (subscriptions, msisdn) => get(subscriptions, [msisdn, 'error']),
)

export const selectRenewalOrderIsLoading = createSelector(
  [
    customer360Selectors.loading,
    selectIsRenewalAllowedLoading,
    selectSubscriptionIsLoading,
    selectRenewalSubscriptionError,
  ],
  (customer360IsLoading, renewalAllowedIsLoading, subscriptionIsLoading, subscriptionError) =>
    customer360IsLoading ||
    renewalAllowedIsLoading ||
    (subscriptionIsLoading && !subscriptionError),
)

export const selectRenewalOrderHasError = createSelector(
  [customer360Selectors.error, selectIsRenewalAllowedHasError, selectRenewalSubscriptionError],
  (customer360Error, renewalValidationHasError, subscriptionError) =>
    !!customer360Error || renewalValidationHasError || !!subscriptionError,
)

export const selectRenewalSubscriptionProduct = createSelector(
  [state => state, selectRenewalMsisdn],
  (state, msisdn) => customer360Selectors.selectProductByLineId(state, msisdn),
)

export const selectRenewalSubscription = createSelector(
  [selectSubscriptions, selectRenewalMsisdn],
  (subscriptions, msisdn) => get(subscriptions, [msisdn, 'data']),
)

export const selectRenewalSubscriptionId = createSelector(
  [selectRenewalSubscription],
  renewalSubscription => renewalSubscription?.subscription_id,
)

export const selectRenewalFixedLineNumber = createSelector(
  [selectRenewalSubscription],
  renewalSubscription =>
    renewalSubscription?.productsSpecifications.find(line => line.name === 'fixed')?.id,
)

export const selectRenewalSubscriptionIsMobile = createSelector(
  [selectRenewalSubscription],
  renewalSubscription =>
    renewalSubscription?.productsSpecifications?.length === 1 &&
    renewalSubscription?.productsSpecifications[0].name === 'mobile',
)

export const selectRenewalSubscriptionIsPostpaid = createSelector(
  [selectRenewalSubscription],
  renewalSubscription => renewalSubscription?.is_postpaid,
)

export const selectRenewalSubscriptionTariffName = createSelector(
  [selectRenewalSubscription],
  renewalSubscription => renewalSubscription?.description,
)

export const selectRenewalSubscriptionTariffPrice = createSelector(
  [selectRenewalSubscription],
  renewalSubscription => {
    const priceBase = renewalSubscription.productPrice.find(price => price.name === 'base')

    return priceBase ? formatFee(priceBase.price.amount) : '-'
  },
)

export const selectRenewalSubscriptionFinancedDevice = createSelector(
  [selectRenewalSubscription],
  renewalSubscription => {
    let financedDevice = getDeviceFinancing(renewalSubscription)

    const devicePermanent = getDevicePermanent(renewalSubscription)

    if (financedDevice) {
      financedDevice.permanent = devicePermanent
    } else if (devicePermanent) {
      financedDevice = {
        permanent: devicePermanent,
      }
    }
    return financedDevice
  },
)

export const selectDeviceType = createSelector(
  [getDeviceRenewalBasket],
  basket => basket?.deviceType,
)

export const selectRenewalDeviceType = createSelector(
  [selectDeviceType],
  deviceType => getSidebarDeviceType(deviceType),
)

export const selectRenewalSaleChannelValue = createSelector(
  [getDeviceRenewalBasket],
  basket => basket?.saleChannel,
)

export const selectRenewalSaleChannel = createSelector(
  [selectRenewalSaleChannelValue],
  saleChannel => getSidebarSaleChannel(saleChannel),
)

export const selectSubscriptionCategory = createSelector(
  [selectRenewalSubscription],
  renewalSubscription => renewalSubscription?.category,
)

export const selectSubscriptionLineType = createSelector(
  [selectRenewalSubscription, selectSubscriptionCategory],
  (renewalSubscription, subscriptionCategory) => {
    let subscriptionLineType

    if (isDuoTariffId(renewalSubscription?.type)) {
      subscriptionLineType = 'extra_line'
    } else {
      subscriptionLineType = subscriptionCategory
    }

    return subscriptionLineType
  },
)

export const selectSubscriptionLineCategory = createSelector(
  [selectContracts, selectRenewalSubscription],
  (contracts, renewalSubscription) => {
    if (isDuoTariffId(renewalSubscription?.type)) {
      return 'extra_line'
    }

    let subscriptionLineType = 'main_line'
    const packages = get(contracts, '[0].packages', [])

    if (packages) {
      const subscriptionPackage = packages.find(p => p.id === renewalSubscription?.id)
      const multisimType = get(subscriptionPackage, 'products[0].multisimType', null)

      if (multisimType) {
        subscriptionLineType = multisimType
      }
    }
    return subscriptionLineType
  },
)

export const selectSubscriptionTariffType = createSelector(
  [selectRenewalSubscription],
  renewalSubscription => renewalSubscription?.type,
)

export const selectRenewalDevices = createSelector(
  [getDeviceRenewalBasket],
  basket => basket.devices,
)

export const selectFetchedDevices = createSelector(
  [selectRenewalDevices],
  devices => devices?.data,
)

export const selectFetchDevicesError = createSelector(
  [selectRenewalDevices],
  devices => devices?.error,
)
export const selectIsFetchingDevices = createSelector(
  [selectRenewalDevices],
  devices => devices?.loading,
)

export const selectDeviceFilters = createSelector(
  [getDeviceRenewalBasket],
  basket => basket.deviceFilters,
)

export const selectRenewalDeviceInfo = createSelector(
  [getDeviceRenewalBasket],
  basket => basket.device,
)

export const selectRenewalReservationId = createSelector(
  [getDeviceRenewalBasket],
  basket => basket.reservationId,
)

export const selectDeviceSpecifications = id =>
  createSelector(
    [getDeviceRenewalBasket],
    basket => get(basket, ['specifications', id]),
  )

export const selectDeviceSpecificationsIsLoading = id =>
  createSelector(
    [selectDeviceSpecifications(id)],
    specifications => !specifications?.data && !specifications?.error,
  )

export const selectDeviceSpecificationsData = id =>
  createSelector(
    [selectDeviceSpecifications(id)],
    specifications => specifications?.data,
  )

export const selectDeviceSpecificationsError = id =>
  createSelector(
    [selectDeviceSpecifications(id)],
    specifications => specifications?.error,
  )

export const selectFinancingConditions = createSelector(
  [getDeviceRenewalBasket],
  basket => basket.financingConditions,
)

export const selectFinancedDeviceDecisions = createSelector(
  [getDeviceRenewalBasket],
  basket => basket.financedDeviceDecisions,
)

export const selectFinancingConditionsData = createSelector(
  [selectFinancingConditions],
  financingConditions => financingConditions?.data,
)

export const selectPermanencyExemptAllowed = createSelector(
  [selectFinancingConditionsData],
  financingConditionsData => financingConditionsData?.permanency?.permanencyExemptAllowed,
)

export const selectPermanencyContractId = createSelector(
  [selectFinancingConditionsData],
  financingConditionsData => financingConditionsData?.permanency?.permanencyContractId,
)

export const selectInstallmentContractId = createSelector(
  [selectFinancingConditionsData],
  financingConditionsData => financingConditionsData?.financing?.installmentContractId,
)

export const selectAmendPenaltyDecision = createSelector(
  [selectFinancedDeviceDecisions],
  financedDeviceDecisions => financedDeviceDecisions?.amendPenalty,
)

export const selectFinancingConditionFinalPayment = createSelector(
  [selectFinancingConditions],
  financingConditions => financingConditions?.data?.quota25,
)

export const selectQuota25ExtensionStatus = createSelector(
  [selectFinancingConditionFinalPayment],
  finalPaymentConditions => finalPaymentConditions?.quota25ExtensionStatus,
)

export const selectFinalPaymentDiscountAmount = createSelector(
  [selectFinancingConditionFinalPayment],
  finalPaymentFinancingConditions => finalPaymentFinancingConditions?.quota25DiscountAmount,
)

export const selectFinancingConditionFinancing = createSelector(
  [selectFinancingConditions],
  financingConditions => financingConditions?.data?.financing,
)

export const selectFinancingInstallmentKeepAllowed = createSelector(
  [selectFinancingConditionFinancing],
  financing => financing?.installmentKeepAllowed,
)

export const selectSubscriptionDeviceName = createSelector(
  [selectRenewalSubscriptionFinancedDevice],
  device => device?.item?.name || device?.permanent?.item?.name,
)

export const selectSubscriptionDevicePenalty = createSelector(
  [selectRenewalSubscriptionFinancedDevice, selectAmendPenaltyDecision],
  (device, amendPenalty) => {
    let penalty
    if (device?.permanent) {
      penalty = getDevicePenalty(device)

      if (amendPenalty) {
        penalty.pending = 0
      }
    }
    return penalty
  },
)

export const selectSubscriptionDeviceFee = createSelector(
  [selectRenewalSubscriptionFinancedDevice],
  device => getDeviceFee(device),
)

export const selectSubscriptionDeviceCancelationToday = createSelector(
  [selectRenewalSubscriptionFinancedDevice],
  device => getDeviceCancelationToday(device),
)

export const selectDeviceResidualPenaltyDays = createSelector(
  [selectSubscriptionDevicePenalty],
  penalty => getDeviceResidualPenaltyDays(penalty),
)

export const selectIsFinalPaymentDiscountApplicable = createSelector(
  [
    selectRenewalSubscriptionFinancedDevice,
    selectDeviceResidualPenaltyDays,
    selectFinancingConditionFinalPayment,
  ],
  (device, residualPenaltyDays, finalPaymentConditions) => {
    return (
      doesDeviceFinalPaymentExists(device) &&
      !!finalPaymentConditions?.quota25DiscountAmount &&
      finalPaymentConditions?.quota25ExtensionStatus !== 'already-done' &&
      finalPaymentConditions?.quota25TerminalReturn !== 'already-done' &&
      residualPenaltyDays <= 60 &&
      residualPenaltyDays > 10
    )
  },
)

export const selectSubscriptionDeviceFinalPayment = createSelector(
  [
    selectRenewalSubscriptionFinancedDevice,
    selectFinalPaymentDiscountAmount,
    selectIsFinalPaymentDiscountApplicable,
  ],
  (device, finalPaymentDiscountAmount, isFinalPaymentDiscountApplicable) =>
    getDeviceFinalPayment(device, finalPaymentDiscountAmount, isFinalPaymentDiscountApplicable),
)

export const selectShouldLetDecideIfAmendPenalty = createSelector(
  [
    selectSubscriptionDevicePenalty,
    selectDeviceResidualPenaltyDays,
    selectCanAgentTakeFinancingDecisions,
  ],
  (penalty, residualPenaltyDays, canAgentTakeFinancingDecisions) =>
    canAgentTakeFinancingDecisions &&
    getShouldLetDecideIfAmendPenalty(penalty, residualPenaltyDays),
)

export const selectShouldAmendPenalty = createSelector(
  [selectSubscriptionDevicePenalty, selectDeviceResidualPenaltyDays],
  (penalty, residualPenaltyDays) => getShouldAmendPenalty(penalty, residualPenaltyDays),
)

export const selectShouldAllowFinancingDecision = createSelector(
  [
    selectSubscriptionDeviceFee,
    selectDeviceResidualPenaltyDays,
    selectCanAgentTakeFinancingDecisions,
  ],
  (fees, residualPenaltyDays, canAgentTakeFinancingDecisions) =>
    canAgentTakeFinancingDecisions && getShouldAllowFinancingDecision(fees, residualPenaltyDays),
)

export const selectShouldMantainFinancing = createSelector(
  [selectSubscriptionDeviceFee, selectDeviceResidualPenaltyDays],
  (fees, residualPenaltyDays) => getShouldMantainFinancing(fees, residualPenaltyDays),
)

export const selectShouldAllowFinalPaymentDecision = createSelector(
  [selectQuota25ExtensionStatus, selectDeviceResidualPenaltyDays],
  (quota25ExtensionStatus, residualPenaltyDays) =>
    getShouldAllowFinalPaymentDecision(quota25ExtensionStatus, residualPenaltyDays),
)

export const selectSubscriptionFinancedDeviceInfo = createSelector(
  [
    selectRenewalSubscriptionFinancedDevice,
    selectSubscriptionDeviceName,
    selectSubscriptionDevicePenalty,
    selectSubscriptionDeviceFee,
    selectSubscriptionDeviceFinalPayment,
    selectSubscriptionDeviceCancelationToday,
  ],
  (device, name, penalty, fee, finalPayment, cancelationToday) =>
    device && (!!penalty || !!fee || !!finalPayment)
      ? {
          name,
          penalty,
          fee,
          finalPayment,
          cancelationToday,
        }
      : null,
)

export const selectFinancingConditionsInformation = createSelector(
  [
    selectRenewalMsisdn,
    selectRenewalSaleChannelValue,
    selectFinancingConditions,
    selectIsFinalPaymentDiscountApplicable,
    selectShouldAmendPenalty,
    selectShouldLetDecideIfAmendPenalty,
    selectShouldAllowFinancingDecision,
    selectShouldMantainFinancing,
    selectShouldAllowFinalPaymentDecision,
  ],
  (
    msisdn,
    renewalSaleChannel,
    financingConditions,
    isFinalPaymentDiscountApplicable,
    shouldAmendPenalty,
    shouldLetDecideIfAmendPenalty,
    shouldAllowFinancingDecision,
    shouldMantainFinancing,
    shouldAllowFinalPaymentDecision,
  ) => ({
    msisdn,
    renewalSaleChannel,
    financingConditionsData: financingConditions?.data,
    financingConditionsError: financingConditions?.error,
    isLoadingFinancingConditions: financingConditions?.loading,
    isFinalPaymentDiscountApplicable,
    shouldAmendPenalty,
    shouldLetDecideIfAmendPenalty,
    shouldAllowFinancingDecision,
    shouldMantainFinancing,
    shouldAllowFinalPaymentDecision,
  }),
)

export const selectRenewalDeviceShipping = createSelector(
  [getDeviceRenewalBasket],
  basket => {
    return basket.shipping
  },
)

export const getGenerateOrderData = createSelector(
  [getDeviceRenewalBasket],
  basket => basket.generateOrder,
)

export const selectGenerateOrderIsLoading = createSelector(
  [getGenerateOrderData],
  generateOrderData => generateOrderData.loading,
)

export const selectGenerateOrderTaskId = createSelector(
  [getGenerateOrderData],
  generateOrderData => generateOrderData.taskId,
)

export const selectGenerateOrderRetries = createSelector(
  [getGenerateOrderData],
  generateOrderData => generateOrderData.retries,
)

export const selectGenerateOrderStartPollingTime = createSelector(
  [getGenerateOrderData],
  generateOrderData => generateOrderData.startPollingTime,
)

export const selectGenerateOrderResult = createSelector(
  [getGenerateOrderData],
  generateOrderData => generateOrderData.result,
)

export const selectGenerateOrderError = createSelector(
  [getGenerateOrderData],
  generateOrderData => generateOrderData.error,
)

export const selectFinancedDeviceSummaryInfo = createSelector(
  [
    selectSubscriptionFinancedDeviceInfo,
    selectFinancedDeviceDecisions,
    selectRenewalSubscriptionFinancedDevice,
  ],
  (deviceInfo, financedDeviceDecisions, financials) => {
    let summaryInfo = null

    if (deviceInfo) {
      const { name, penalty, finalPayment, cancelationToday } = deviceInfo
      summaryInfo = getFinancedDeviceSummaryInfo(
        name,
        penalty,
        cancelationToday,
        finalPayment,
        financedDeviceDecisions,
        financials,
      )
    }

    return summaryInfo
  },
)

export const selectDeviceBilling = createSelector(
  [getDeviceRenewalBasket],
  basket => basket.billing,
)

export const selectDeviceBillingType = createSelector(
  [selectDeviceBilling],
  billing => billing.paymentType,
)

export const selectDeviceBillingTransaction = createSelector(
  [selectDeviceBilling],
  billing => billing.transaction,
)

export const selectFinancialData = createSelector(
  [getDeviceRenewalBasket],
  basket => basket.financialData,
)

export const selectFinancedLegalConsentsData = createSelector(
  [getDeviceRenewalBasket],
  basket => basket.financedLegalConsents,
)

export const selectIbanValidationFlag = createSelector(
  selectDeviceRenewalIbanValidation,
  flag => flag,
)

export const selectIsDevicePaymentMethodUpfront = createSelector(
  selectRenewalDeviceInfo,
  device => {
    let isDevicePaymentMethodUpfront = false

    if (device?.paymentMethods) {
      const devicePaymentMethod = Object.keys(device.paymentMethods)[0]
      isDevicePaymentMethodUpfront = devicePaymentMethod === SINGLE
    }

    return isDevicePaymentMethodUpfront
  },
)

export const selectIsFinalPaymentBeingExtended = createSelector(
  selectFinancedDeviceDecisions,
  ({ finalPaymentType }) => finalPaymentType === FINAL_PAYMENT_DECISION_FINANCING,
)

export const selectFormattedBillingAddress = createSelector(
  customer360Selectors.getBillingAddress,
  address => ({ address: customer360Helpers.getBillingAddressStr(address) }),
)

export const selectRenewalAgileTvEmail = createSelector(
  [getDeviceRenewalBasket],
  basket => basket?.svaAgileEmail,
)
