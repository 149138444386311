import { Fragment } from 'react'
import PropTypes from 'prop-types'

import { get, isEmpty } from 'lodash'
import { format } from 'date-fns'

import { Box, Divider } from '@material-ui/core'

import { formatFee } from 'utils'
import LineItemCard from 'components/packages/ui/card360/LineItemCard'

export const SubtotalsFATs = ({ currentMonthFATs, totalExpenses, SMSPriceWithTax }) => {
  return (
    <>
      <Box m="16px 24px 0">
        <Divider />
      </Box>

      <LineItemCard primaryText="Subtotal" fieldsApart secondaryText={formatFee(totalExpenses)} />
      {!isEmpty(currentMonthFATs) &&
        currentMonthFATs.map(fat => {
          const dateFATFormat = get(fat, 'air_time.period')
          const FATId = get(fat, 'air_time.air_time_group_id')
          const FATamount = get(fat, 'air_time.amount', 0)
          const isCurrentMonthFATFormat = format(new Date(), 'yyyyMM') === dateFATFormat
          const SMSCompensationType = FATId === 'CPSMSFAT'
          const FATItemDescription = `FAT ${FATId}${
            SMSCompensationType ? ` (${FATamount} SMS)` : ``
          }`

          return (
            <Fragment key={`${fat.air_time}`}>
              {isCurrentMonthFATFormat && (
                <LineItemCard
                  primaryText={FATItemDescription}
                  fieldsApart
                  secondaryText={`- ${
                    SMSCompensationType
                      ? formatFee(SMSPriceWithTax * FATamount)
                      : formatFee(FATamount)
                  }`}
                />
              )}
            </Fragment>
          )
        })}
    </>
  )
}

SubtotalsFATs.propTypes = {
  currentMonthFATs: PropTypes.array,
  totalExpenses: PropTypes.number,
  SMSPriceWithTax: PropTypes.number,
}
