import { createStructuredSelector, createSelector } from 'reselect'
import { useFormikContext } from 'formik'

import { selectPreSaleFlag } from 'services/feature-flag/selectors'
import {
  getCoverageToken,
  selectAddressInfo,
  selectHasTechnology,
  selectCoverageTokenLoading,
  selectCoverageData,
} from 'modules/Coverage'
import {
  selectSaleParam,
  selectIsExistingClientSaleFlow,
  selectIsPosSale,
} from 'modules/NewClientSales/store/selectors/index'
import {
  selectIsMobileOnly,
  selectIsCarteraQueryParam,
  selectIsSubscriptionQueryParam,
  selectIsCrossSellQueryParam,
  selectIsPresaleQueryParam,
  selectIsMultiSimQueryParam,
} from 'modules/Router/store/index'
import { selectDuoLimitHasError } from 'modules/CustomerOrders'
import { get } from 'lodash'
import { selectCustomerAllowed } from 'modules/CustomerInfo/store/customerInfo.selectors'
import {
  selectAccount,
  selectIsElFijo,
  selectIsServiceAddressBillingAddress,
  selectServiceAddressZipCode,
  selectPrescoringData,
  selectPrescoringFailed,
  selectReferenceNumber,
  getFetchPresaleData,
} from '../../store'

export const selectClientInfo = createStructuredSelector({
  coverageToken: getCoverageToken,
  coverageData: selectCoverageData,
  hasTechnology: selectHasTechnology,
  installationAddress: selectAddressInfo,
  basketAcccount: selectAccount,
  isCoverageTokenLoading: selectCoverageTokenLoading,
  isMobileOnly: selectIsMobileOnly,
  isElFijo: selectIsElFijo,
  isExistingClientFlow: selectIsExistingClientSaleFlow,
  isCartera: selectIsCarteraQueryParam,
  isSubscription: selectIsSubscriptionQueryParam,
  isCrossSell: selectIsCrossSellQueryParam,
  isPresale: selectIsPresaleQueryParam,
  saleType: selectSaleParam,
  isServiceSameAsBilling: selectIsServiceAddressBillingAddress,
  serviceAddressZipCode: selectServiceAddressZipCode,
  prescoringData: selectPrescoringData,
  prescoringFailed: selectPrescoringFailed,
  prescoringReference: selectReferenceNumber,
  hasDuoError: selectDuoLimitHasError,
  canAgentAccessPresale: selectPreSaleFlag,
  presaleData: getFetchPresaleData,
  isPos: selectIsPosSale,
  isMultiSim: selectIsMultiSimQueryParam,
  isCustomerAllowed: selectCustomerAllowed,
})

export const selectHasClientInfoChanged = createSelector(
  (state, props) => props,
  data => data.storeAccount !== data.formikAccount,
)

export function getFormikProps() {
  const { setFieldValue, setTouched, setValues, values } = useFormikContext()

  const account = get(values, 'account', {})
  const { documenttype, segment } = account
  const { lineNumber, isPortability, signUpType } = get(values, 'landlineOffer', {})

  return {
    values,
    account,
    setTouched,
    documenttype,
    segment,
    lineNumber,
    isPortability,
    setFieldValue,
    setValues,
    signUpType,
  }
}
