import { get } from 'lodash'

import {
  BUSINESS_PROFILE_IDS,
  TARIFFS_2P_PURE,
} from 'modules/offers-configuration/constants'
import {
  CAMPAIGN_AREA_INBOUND,
  CAMPAIGN_AREA_PRO_SOHO,
  SFID_ATCN_CAMPAIGN_ACCESS,
  SFID_APN_CAMPAIGN_ACCESS,
} from 'modules/Permissions/constants'
import { profiles } from 'modules/Permissions'

export const isSfidInArray = (sfidList, sfidFind, prefix = false) =>
  prefix
    ? sfidList.some(item => sfidFind.startsWith(item))
    : sfidList.some(item => sfidFind.endsWith(item))

export const is2pPureTariff = tariff => TARIFFS_2P_PURE.includes(tariff)

export const getCampaignArea = (agentProfile, sfid, oldTypification = true) => {
  if (oldTypification && agentProfile === get(profiles, 'customer_loyalty_inbound.id')) {
    return CAMPAIGN_AREA_INBOUND
  }

  if (oldTypification && agentProfile === get(profiles, 'customer_loyalty_outbound.id')) {
    return 'FIDELIZACION'
  }  
  if (
    BUSINESS_PROFILE_IDS.includes(agentProfile) ||
    (sfid !== 'MKCN_LUCAS.TAVARES' && isSfidInArray(SFID_ATCN_CAMPAIGN_ACCESS, sfid, true)) ||
    isSfidInArray(SFID_APN_CAMPAIGN_ACCESS, sfid, true)
  ) {
    return CAMPAIGN_AREA_PRO_SOHO
  }

  return agentProfile
}
