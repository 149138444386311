import styled from 'styled-components'

export default styled.div`
  margin-bottom: ${props => props.marginbottom || '12px'};
  color: #646464;
  font-weight: 500;
  display: flex;
  flex-wrap: wrap;
  align-items: ${props => props.alignitems || 'unset'}
  &:last-child {
    margin-bottom: 0;
  }
`
