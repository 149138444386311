import { useState } from 'react'
import PropTypes from 'prop-types'

import { useHistory, useLocation } from 'react-router-dom'

import { Divider } from '@material-ui/core'

import { Line, PortabilityFromFtth } from 'modules/Lines/components'
import { lineOptions } from 'modules/Lines/line.config'
import { SALES_INFO } from 'modules/SharedSales/constants'
import { LINE_TYPE } from 'modules/Lines/constants'

import RouterNumber from './RouterNumber'
import { AgileTv } from '../AgileTv'
import { ErrorModal } from '../../Common'
import { SalesTariffPicker } from '../../Common/SalesTariffPicker/SalesTariffPicker'

const requiredFields = [
  'account.documenttype',
  'account.documentid',
  'account.name',
  'account.surname1',
  'account.surname2',
  'account.phoneContact',
]

export function Landline(props) {
  const {
    channel,
    onChangeTariff,
    mainOffer,
    mainOffer: { ps },
    tariffList,
    isLoading,
    is2p,
    isCartera,
    isCrossSell,
    shouldShowHelperText,
    shouldShowPortability,
    signUpType,
    isPortabilityFromFtth,
    onSignUpTypeChange,
    otherUserDocument,
    portabilityPerson,
    postalCode,
    SVAProps,
    fieldNames,
    newNumber,
    tax,
    shouldShowSVA,
    segment,
    tariffName,
    onChangePortabilityFromFTTH,
    isNeba,
    isAdsl,
    operator,
    provisioningError,
    onDeleteBundle,
    isPro,
    mainUser,
    selectedBundles,
    isSvaFlag,
    validateLandline,
    validateRouterNumber,
    routerValidationMessage,
    hasMsError,
    errorMs,
    mainLineTariffsError,
    fieldName,
    fieldValue,
    ...restProps
  } = props

  const history = useHistory()
  const location = useLocation()

  const [pickedTariff, setPickedTariff] = useState(mainOffer)

  function onSelectTariff(tariff) {
    onChangeTariff(tariff)
  }

  function onCancelSelectTariff() {
    if (mainOffer.id !== pickedTariff.id) {
      setPickedTariff(mainOffer)
    }
    if (!mainOffer.id) {
      history.push(`${SALES_INFO}${location.search}`, { goingBack: true })
    }
  }

  return (
    <>
      <SalesTariffPicker
        selectedTariff={mainOffer}
        selectedBundles={selectedBundles}
        tariffs={tariffList}
        isLoadingTariffs={isLoading}
        isConvergent
        isCrossSell={isCrossSell}
        lineType={LINE_TYPE.LANDLINE}
        onSelectTariff={onSelectTariff}
        onCancelSelectTariff={onCancelSelectTariff}
        onSelectBundles={SVAProps.onSetPackages}
        onDeleteBundle={onDeleteBundle}
        fieldNames={fieldNames}
        error={mainLineTariffsError}
        shouldShowAddBundles
        shouldShowCrossSellBundlesChanges={isCrossSell}
        shouldShowPermanency={!operator.internalPortability || operator.installationRequired}
      />

      {shouldShowPortability && (
        <div className="m-t-6">
          <PortabilityFromFtth
            onChangePortabilityFromFTTH={onChangePortabilityFromFTTH}
            isNeba={isNeba}
            isPortabilityFromFtth={isPortabilityFromFtth}
            shouldShowHelperText={shouldShowHelperText}
            operator={operator}
          />
          <Divider />
        </div>
      )}

      <h3 className="card-title small m-t-20">Fijo: Tarifa base</h3>

      <div className="p-y-12">
        <Line
          mainUser={mainUser}
          onCheckIsPortability={validateLandline}
          lineType={LINE_TYPE.LANDLINE}
          lineOptions={lineOptions}
          fieldNames={fieldNames}
          onSignUpTypeChange={onSignUpTypeChange}
          signUpType={signUpType}
          portabilityPerson={portabilityPerson}
          otherUserDocument={otherUserDocument}
          requiredFields={requiredFields}
          newNumber={newNumber}
          is2p={is2p}
          {...restProps}
        />
      </div>

      {shouldShowSVA && (
        <div className="m-b-12">
          <Divider />
          <AgileTv
            fieldNames={fieldNames}
            mainTariffPsId={ps}
            isSvaFlag={isSvaFlag}
            is2p={is2p}
            isCrossSell={isCrossSell}
            isCartera={isCartera}
          />
        </div>
      )}

      <Divider />
      <RouterNumber
        fieldNames={fieldNames}
        channel={channel}
        landlineTariff={mainOffer}
        isAdsl={isAdsl}
        onChange={validateRouterNumber}
        message={routerValidationMessage}
      />

      <ErrorModal
        error={errorMs || provisioningError}
        hasMsError={!!hasMsError || !!provisioningError}
        fieldName={fieldName}
        fieldValue={fieldValue}
      />
    </>
  )
}

Landline.propTypes = {
  channel: PropTypes.string.isRequired,
  fieldNames: PropTypes.object,
  onChangePortabilityFromFTTH: PropTypes.func,
  onCheckIsPortability: PropTypes.func,
  onCheckSVA: PropTypes.func,
  operator: PropTypes.object,
  isAdsl: PropTypes.bool,
  isNeba: PropTypes.bool,
  is2p: PropTypes.bool,
  isCrossSell: PropTypes.bool,
  mainOffer: PropTypes.object.isRequired,
  onChangeTariff: PropTypes.func.isRequired,
  shouldShowHelperText: PropTypes.bool.isRequired,
  shouldShowPortability: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  onSignUpTypeChange: PropTypes.func.isRequired,
  otherUserDocument: PropTypes.string.isRequired,
  portabilityPerson: PropTypes.string,
  postalCode: PropTypes.string,
  signUpType: PropTypes.string,
  isPortabilityFromFtth: PropTypes.string,
  newNumber: PropTypes.string,
  SVAProps: PropTypes.object,
  tax: PropTypes.number,
  segment: PropTypes.string,
  tariffList: PropTypes.array.isRequired,
  selectedBundles: PropTypes.array,
  shouldShowSVA: PropTypes.bool,
  tariffName: PropTypes.string,
  provisioningError: PropTypes.string,
  onDeleteBundle: PropTypes.func,
  isPro: PropTypes.bool,
  isSvaFlag: PropTypes.bool,
  mainUser: PropTypes.string,
  validateLandline: PropTypes.func,
  validateRouterNumber: PropTypes.func,
  routerValidationMessage: PropTypes.string,
  hasMsError: PropTypes.bool,
  errorMs: PropTypes.string,
  mainLineTariffsError: PropTypes.object,
  fieldName: PropTypes.string,
  fieldValue: PropTypes.string,
  isCartera: PropTypes.bool,
}
