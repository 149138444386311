import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useFormikContext } from 'formik'
import { set } from 'lodash'

import { setBasketData } from '../../store/actions'

export function useSetFormikValuesToBasket() {
  const dispatch = useDispatch()
  const { values } = useFormikContext() || {}

  const dispatchSetBasketData = useCallback(data => {
    dispatch(setBasketData(data))
  })

  function setFormikValuesToBasket(newPath, newValue) {
    const newValues = { ...values }
    set(newValues, newPath, newValue)
    dispatchSetBasketData(newValues)
  }

  return setFormikValuesToBasket
}
