import { get } from 'lodash'

export const getTaxes = state => state.taxes

export const getTax = (state, zipCode) => get(state, `taxes.data.${zipCode}`, 0)

export const getTaxData = (state, zipCode) => get(state, `taxes.data.${zipCode}`)

export const isLoading = (state, zipCode) => get(state, `taxes.loading.${zipCode}`, false)

export const getErrorCode = (state, zipCode) => get(state, `taxes.error.${zipCode}.code`)

export const getErrorMessage = (state, zipCode) => get(state, `taxes.error.${zipCode}.message`)
