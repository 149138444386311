import { styled } from '@material-ui/styles'
import { Tab as TabBase } from '@material-ui/core'

const Tab = styled(({ ...rest }) => (
  <TabBase
    classes={{
      root: 'tab-root',
      disabled: 'tab-disabled',
    }}
    {...rest}
  />
))({
  opacity: 1,

  '&.tab-root': {
    padding: '12px 24px 16px 24px',
  },
})

export default Tab
