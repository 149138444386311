import { useSelector } from 'react-redux'

import { getSegment } from 'services/customer-360/selectors'
import { selectTariffsApigeeById, getTariffSegment } from 'modules/tariffs/store-apigee'
import { getSubscriptionTariffId } from 'services/subscriptions'
import {
  getChannelFromTariffSegment,
  getSegmentFromCustomerSegment,
} from 'modules/agile-tv/add-agiletv/helpers/index'

const SEGMENT_MIXED = 'mixed'

export const useChannelAndSegment = subscription => {
  const customerSegment = useSelector(getSegment)
  const subscriptionTariffId = getSubscriptionTariffId(subscription)
  const tariff = useSelector(selectTariffsApigeeById(subscriptionTariffId))
  const tariffSegment = getTariffSegment(tariff)
  const segment =
    tariffSegment === SEGMENT_MIXED
      ? getSegmentFromCustomerSegment(customerSegment)
      : getTariffSegment(tariff)

  return {
    segment,
    channel: getChannelFromTariffSegment(segment),
  }
}
