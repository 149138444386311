import { call, put, takeEvery } from 'redux-saga/effects'
import { logError, formatErrorMessage } from 'services/logging'
import { axiosFactory } from 'modules/axios'

import {
  cancelOrderBusActionTypes,
  cancelOrderBusSuccessAction,
  cancelOrderBusErrorAction,
} from './cancel-order-bus.actions'

import { cancelOrder } from '../services'

export function* cancelOrderBusSaga({ payload: { workOrderId, cancelationMotive } }) {
  try {
    const data = yield call(cancelOrder, {
      workOrderId,
      cancelationMotive,
    })
    yield put(cancelOrderBusSuccessAction(data))
  } catch (e) {
    yield call(logError, { e: new Error(formatErrorMessage(e)), workOrderId, cancelationMotive })
    const error = axiosFactory.buildError(e)
    yield put(cancelOrderBusErrorAction(error))
  }
}

export function* watchCancelOrderBus() {
  yield takeEvery(cancelOrderBusActionTypes.CANCEL_ORDER_BUS, cancelOrderBusSaga)
}
