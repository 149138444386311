import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import { SaveChangesStepButton } from 'components/ui'
import { useStepNavigation } from 'utils/navigation'

import { setDeviceRenewalData } from '../../store/device-renewal.actions'
import { ORDERED_DEVICE_RENEWAL_STEPS } from '../../constants'

export const RenewalSaveChangesStepButton = ({
  onContinue,
  noStoreValues,
  children,
  stepName,
  ...rest
}) => {
  const dispatch = useDispatch()

  const { navigateToNextStep } = useStepNavigation(ORDERED_DEVICE_RENEWAL_STEPS, stepName)
  const setFormData = noStoreValues ? null : data => dispatch(setDeviceRenewalData(data))

  const props = {
    onContinue: onContinue || navigateToNextStep,
    setFormData,
    stepName,
    ...rest,
  }

  return <SaveChangesStepButton {...props}>{children}</SaveChangesStepButton>
}

RenewalSaveChangesStepButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  isLoading: PropTypes.bool,
  beforeContinue: PropTypes.func,
  onContinue: PropTypes.func,
  stepName: PropTypes.string,
  hasValidationErrors: PropTypes.bool,
  noStoreValues: PropTypes.bool,
  afterContinue: PropTypes.func,
  disabled: PropTypes.bool,
  customValidationAction: PropTypes.func,
}

RenewalSaveChangesStepButton.defaultProps = {
  children: 'Continuar',
}
