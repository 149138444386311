import { isEmpty } from 'lodash'

export const getCoverageDisponibility = coverage => {
  if (isEmpty(coverage)) return undefined
  const { ftth, ull: adsl } = coverage
  const isFtth = !isEmpty(ftth)
  const isAdsl = !isEmpty(adsl)

  // Return '' to verify if we need or not the filter for fixed line type
  // If we return '' we dont apply the fixedLineTtype filter on the tariffs
  // request and we return both coverages tariffs
  // If we return adsl or fiber we will apply the filter and return one of this coverages tariffs
  if (isAdsl && isFtth) {
    return ''
  }
  if (isFtth) {
    return 'fiber'
  }
  if (isAdsl) {
    return 'adsl'
  }
  return undefined
}
