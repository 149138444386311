import { constants } from './constants'

const getCustomerProductsInit = (documentType, documentId) => ({
  type: constants.GET_CUSTOMER_PRODUCTS_INIT,
  payload: {
    documentType,
    documentId,
  },
})

const getCustomerProducts = () => ({
  type: constants.GET_CUSTOMER_PRODUCTS,
})

const getCustomerProductsSuccess = (customerProducts = {}) => ({
  type: constants.GET_CUSTOMER_PRODUCTS_SUCCESS,
  payload: {
    customerProducts,
  },
})

const getCustomerProductsFailed = error => ({
  type: constants.GET_CUSTOMER_PRODUCTS_ERROR,
  payload: {
    error,
  },
})

const resetCustomerProducts = () => ({
  type: constants.RESET_CUSTOMER_PRODUCTS,
})

export const actions = {
  getCustomerProductsInit,
  getCustomerProducts,
  getCustomerProductsSuccess,
  getCustomerProductsFailed,
  resetCustomerProducts,
}
