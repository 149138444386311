import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { ActionAdvice, Button, ButtonLink } from 'components/ui'
import { Typography } from '@material-ui/core'
import { selectURLProductId } from 'services/customer-products'
import { setMultipleBarringStatusAction, formatBarringAdviceMsg } from 'modules/barrings'
import { PropTypes } from 'prop-types'
import { get, isEmpty } from 'lodash'

export const BarringsSummary = styled(
  ({
    className,
    barringsWithChanges,
    setBarringsWithChanges,
    showSummary,
    removeBarringFromSummary,
    setResetBarringStatus,
    showBarringStatusResult,
    setShowBarringStatusResult,
  }) => {
    const dispatch = useDispatch()
    const productId = useSelector(selectURLProductId)

    const handleOnClick = () => {
      const data = {}
      barringsWithChanges.forEach(barring => {
        data[get(barring, 'id')] = get(barring, 'status') === 'active'
      })
      const barrings = barringsWithChanges.map(barring => ({ barr_code: get(barring, 'id') }))
      dispatch(setMultipleBarringStatusAction(productId, barrings, data))
      setBarringsWithChanges([])
      setResetBarringStatus('all')
      setShowBarringStatusResult(true)
      showSummary(false)
    }

    return (
      <div className={className} data-hook="barrings-summary">
        {!showBarringStatusResult && (
          <div>
            <Typography className="main-text-style p-y-12">
              {isEmpty(barringsWithChanges)
                ? 'No hay cambios por realizar.'
                : 'Se van a realizar los siguientes cambios:'}
            </Typography>

            <div className="p-y-12">
              {!isEmpty(barringsWithChanges) &&
                barringsWithChanges.map(barring => (
                  <div className="flex align-start">
                    <ActionAdvice
                      bold
                      dataHook="barring-advice"
                      className="barring-advice p-r-12 p-y-12"
                      message={formatBarringAdviceMsg(barring)}
                      maxWidth="550px"
                      type="info"
                    />
                    <ButtonLink
                      className="barring-button p-y-12"
                      onClick={() => removeBarringFromSummary(get(barring, 'id'))}>
                      Cancelar
                    </ButtonLink>
                  </div>
                ))}
            </div>

            <div className="p-y-12">
              <Button
                className="button"
                onClick={handleOnClick}
                data-hook="set-barrings-status-btn"
                disabled={isEmpty(barringsWithChanges)}>
                Confirmar
              </Button>

              <Button secondary onClick={() => showSummary(false)}>
                Cancelar
              </Button>
            </div>
          </div>
        )}
      </div>
    )
  },
)`
  .main-text-style {
    color: ${({ theme }) => theme.palette.global.gray_dark};
  }
  .button {
    margin-right: 16px;
  }
  .alarms-advice {
    background-color: ${({ theme }) => theme.palette.brand.attention};
  }
}`

BarringsSummary.propTypes = {
  barring: PropTypes.object,
}
