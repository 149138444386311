import PropTypes from 'prop-types'
import styled from 'styled-components'
import { ButtonLink } from '../buttons/ButtonLink'

const ConfigPortOutHeaderStyled = styled.div`
  margin-bottom: 13px;
`

const ConfigPortOutHeader = ({ collapsed, actionToggle }) => (
  <ConfigPortOutHeaderStyled>
    <ButtonLink onClick={actionToggle}>
      {collapsed ? 'Ver información de Port Out' : 'Ocultar información de Port Out'}
    </ButtonLink>
  </ConfigPortOutHeaderStyled>
)

ConfigPortOutHeader.propTypes = {
  collapsed: PropTypes.bool,
  actionToggle: PropTypes.func,
}

export default ConfigPortOutHeader
