import { mergeParams } from 'modules/url'

export function urlToRouterParams(location = {}, pathname = '') {
  const urlSplitted = pathname.split('?')
  const params = mergeParams(urlSplitted[1] || '', location.search).toString()
  return {
    pathname: urlSplitted[0],
    search: params,
  }
}
