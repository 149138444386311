import { get } from 'lodash'

export function getInitialPaymentFromPaymentMethod(paymentMethod) {
  return get(paymentMethod, 'initialPayment.taxIncludedAmount', 0)
}

export function getMonthlyPaymentFromPaymentMethod(paymentMethod) {
  return get(paymentMethod, 'monthlyPayment.taxFreeAmount', 0)
}

export function getFinalPaymentFromPaymentMethod(paymentMethod) {
  return get(paymentMethod, 'finalPayment.taxIncludedAmount', 0)
}

export function getMonthlyFeesFromPaymentMethod(paymentMethod) {
  return get(paymentMethod, `fees`, 0)
}

export function getIncludedDeviceDiscountFromPaymentMethod(paymentMethod) {
  return get(paymentMethod, 'permanency.penaltyAmountTaxFree', 0)
}

export function getCommissionFeePercentage(financingConditions) {
  return get(financingConditions, 'commissionFeePercentage', 0)
}

export function calculateMonthlyTotalPayment(monthlyPayment, monthlyFees) {
  return monthlyPayment * monthlyFees
}

export function calculateCommissionFee(comissionFeePercentage, monthlyTotalPayment, finalPayment) {
  return ((monthlyTotalPayment + finalPayment) * comissionFeePercentage) / 100
}

export function getFinancingInfo(financingConditions, paymentMethod) {
  const monthlyPayment = getMonthlyPaymentFromPaymentMethod(paymentMethod)
  const finalPayment = getFinalPaymentFromPaymentMethod(paymentMethod)

  const commissionFeePercentage = getCommissionFeePercentage(financingConditions)
  const monthlyFees = getMonthlyFeesFromPaymentMethod(paymentMethod)
  const monthlyTotalPayment = calculateMonthlyTotalPayment(monthlyPayment, monthlyFees)
  const commissionFee = calculateCommissionFee(
    commissionFeePercentage,
    monthlyTotalPayment,
    getFinalPaymentFromPaymentMethod(paymentMethod),
  )

  return {
    initialPayment: getInitialPaymentFromPaymentMethod(paymentMethod),
    monthlyPayment,
    finalPayment,
    tae: get(financingConditions, 'tae', 0),
    tin: get(financingConditions, 'tin', 0),
    commissionFeePercentage,
    commissionFee,
    monthlyTotalPayment,
    totalPayment: monthlyTotalPayment + finalPayment + commissionFee,
    totalPaymentAfterCommissionFee: monthlyTotalPayment + finalPayment,
    includedDiscount: getIncludedDeviceDiscountFromPaymentMethod(paymentMethod),
  }
}
