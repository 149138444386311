import { connect } from 'react-redux'
import { appointmentActions } from 'modules/Appointments'
import { selectNewAppointment, selectIsD2D } from 'modules/NewClientSales/store/selectors'
import { notifyD2DOrderFinishedAction } from '../../../store/actions'

import { NewAppointment } from '../../../components/NewAppointment'

const mapDispatchToProps = dispatch => ({
  getAppointmentToken: () => dispatch(appointmentActions.getAppointmentSaleTokenInit()),
  setAppointmentError: error => dispatch(appointmentActions.setAppointmentError(error)),
  notifyD2D: () => dispatch(notifyD2DOrderFinishedAction()),
})

const mapStateToProps = state => ({
  token: state.appointments.appointmentToken,
  appointmentData: state.appointments.data,
  isD2D: selectIsD2D(state),
  ...selectNewAppointment(state),
})
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewAppointment)
