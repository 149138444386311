import { constants } from './constants'

export const loadInitAction = () => ({
  type: constants.LOAD_INIT,
})

export const loadSucceededAction = () => ({
  type: constants.LOAD_SUCCEEDED,
  payload: {},
})

export const loadFailedAction = error => ({
  type: constants.LOAD_FAILED,
  payload: error,
})
