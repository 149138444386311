export const constants = {
  FETCH: 'OVID/CUSTOMER_ACTIVITY/FETCH',
  FETCH_SUCCESS: 'OVID/CUSTOMER_ACTIVITY/FETCH_SUCCESS',
  FETCH_FAILED: 'OVID/CUSTOMER_ACTIVITY/FETCH_FAILED',

  FETCH_SUBSCRIPTIONS: 'OVID/CUSTOMER_ACTIVITY/FETCH_SUBSCRIPTIONS',
}

const fetchCustomerActivity = (msisdns, customerId = '') => ({
  type: constants.FETCH,
  payload: {msisdns, customerId},
})

const fetchCustomerActivitySuccess = data => ({
  type: constants.FETCH_SUCCESS,
  payload: {
    data,
  },
})

const fetchCustomerActivityFailed = error => ({
  type: constants.FETCH_FAILED,
  payload: { error },
})

const fetchCustomerSubscriptions = postalCode => ({
  type: constants.FETCH_SUBSCRIPTIONS,
  payload: postalCode,
})

export const actions = {
  fetchCustomerActivity,
  fetchCustomerActivitySuccess,
  fetchCustomerActivityFailed,
  fetchCustomerSubscriptions,
}
