import { cancelOrderBusActionTypes } from './cancel-order-bus.actions'

export const cancelOrderBusInitialState = {
  data: null,
  error: null,
  loading: null,
}

export const reducer = (state = cancelOrderBusInitialState, { type, payload }) => {
  switch (type) {
    case cancelOrderBusActionTypes.CANCEL_ORDER_BUS: {
      return { ...cancelOrderBusInitialState, loading: true }
    }
    case cancelOrderBusActionTypes.CANCEL_ORDER_BUS_SUCCESS: {
      return { ...state, data: payload.data, loading: false }
    }
    case cancelOrderBusActionTypes.CANCEL_ORDER_BUS_ERROR: {
      return {
        ...state,
        error: payload.error,
        loading: false,
      }
    }
    default:
      return state
  }
}
