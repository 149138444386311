import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { isNil } from 'lodash'

import { NOT_DATA_CAMPAIGN } from 'modules/typifications/constants'
import { selectDataNotification } from '../store'
import { getTypeNotification } from '../helpers'

export const useNotification = () => {
  const [typeMessage, setTypeMessage] = useState(null)
  const [typeNotification, setTypeNotification] = useState(null)
  const [customBody, setCustomBody] = useState(null)
  const [showNotification, setShowNotification] = useState(false)

  const { typeDataNotification, insertionErrorMessage } = useSelector(selectDataNotification)
  
  const setNotificationIframe = (show, message, notification, customMessage) => {
    setTypeMessage(message)
    setTypeNotification(notification)
    setCustomBody(customMessage)
    setShowNotification(show)
  }

  useEffect(() => {
    if (!isNil(typeDataNotification)) {
      setNotificationIframe(
        true,
        typeDataNotification,
        getTypeNotification(typeDataNotification),
        insertionErrorMessage,
      )
    } else {
      setShowNotification(false)
    }
  }, [typeDataNotification])

  
  useEffect(() => {
    if(typeMessage === NOT_DATA_CAMPAIGN) {
      setNotificationIframe(
        true,
        NOT_DATA_CAMPAIGN,
        getTypeNotification(NOT_DATA_CAMPAIGN),
      )
    }
  }, [typeMessage])

  return [typeMessage, typeNotification, customBody, showNotification, setShowNotification, setTypeMessage]
}
