import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { Typography } from '@material-ui/core'
import { FormikInput } from 'components/ui'

import {
  selectIsCarteraQueryParam,
  selectIsCrossSellQueryParam,
  selectIsSubscriptionQueryParam,
} from 'modules/Router/store'

import { AccountLanguageOptions } from '../../../store/models/account'
import { setNewBasketAccountValuesCartera } from '../../../store/actions/basket.actions'
import { useUpdateCarteraValues } from '../../../hooks/Cartera'

const accountLanguageFieldName = 'account.language'

function ContactSummary({ account, isMultiSim }) {
  const isCartera = useSelector(selectIsCarteraQueryParam)
  const isCrossSell = useSelector(selectIsCrossSellQueryParam)
  const isSubscription = useSelector(selectIsSubscriptionQueryParam)

  const fieldsLoadedEmpty = useMemo(
    () => ({
      lang: !account.language,
    }),
    [isCartera],
  )
  const { setCarteraValuesHaveChanged } = useUpdateCarteraValues({
    action: setNewBasketAccountValuesCartera,
    payload: 'summary',
    flags: { requiresAccountId: true },
  })

  return (
    <>
      <Typography variant="subtitle1" color="secondary" className="bold m-b-8">
        DATOS DE CONTACTO
      </Typography>
      <div className="flex flex-wrap align-center-between">
        <div className="flex-grow p-r-16">
          <FormikInput
            onChange={() => setCarteraValuesHaveChanged(true)}
            name="account.phoneContact2"
            label="Teléfono de contacto 2"
            fullWidth
            maxLength="9"
            disabled={{ bool: isMultiSim }}
          />
        </div>
        <div className="flex-grow p-r-16">
          <FormikInput
            onChange={() => setCarteraValuesHaveChanged(true)}
            disabled={{
              bool: ((isCartera || isCrossSell || isSubscription) && !!account.email) || isMultiSim,
            }}
            name="account.email"
            label="E-mail de contacto"
            fullWidth
          />
        </div>
        <div className="flex-grow p-r-16">
          <FormikInput
            disabled={{
              bool:
                (isCartera &&
                  account.accountId &&
                  account.language &&
                  !fieldsLoadedEmpty.language) ||
                isMultiSim,
            }}
            name={accountLanguageFieldName}
            label="Idioma"
            select
            fullWidth
            options={AccountLanguageOptions}
          />
        </div>
      </div>
    </>
  )
}

ContactSummary.propTypes = {
  account: PropTypes.object,
  isMultiSim: PropTypes.bool,
}

export default ContactSummary
