import _defineProperty from "/home/jenkins/workspace/b2c_ovid-front_v1.341.3/build/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _objectSpread from "/home/jenkins/workspace/b2c_ovid-front_v1.341.3/build/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { createTheme } from '@material-ui/core/styles';
import { alpha } from '@material-ui/core/styles/colorManipulator';
import { getDarken } from 'utils';
import { bluePalette, componentColors, grayPalette, greenPalette, orangePalette, redPalette, yoigoPalette } from './colors';
import { typography } from './typography';
export var BRANDS = {
  YOIGO: 'yoigo'
};
var THEME_COMMON_PROPS = {
  typography: _objectSpread({
    useNextVariants: true
  }, typography),
  props: {}
};

var yoigoBrandPalette = _objectSpread({
  palette: {
    primary: {
      main: bluePalette.main,
      dark: bluePalette.main
    },
    secondary: {
      main: yoigoPalette.action_secondary,
      dark: bluePalette.main
    },
    error: {
      main: yoigoPalette.alert
    },
    global: grayPalette,
    brand: yoigoPalette,
    componentColors: componentColors,
    bluePalette: bluePalette,
    redPalette: redPalette,
    greenPalette: greenPalette,
    orangePalette: orangePalette
  },
  typography: {
    fontWeightLight: 400,
    fontWeightRegular: 500,
    fontWeightMedium: 600,
    fontWeightBold: 700
  },
  overrides: {
    // prevent margin 0
    MuiButtonBase: {
      root: {
        margin: 'none'
      }
    },
    MuiDivider: {
      root: {
        'background-color': grayPalette.gray_light,
        height: '1.5px'
      }
    },
    // prevent min-width 0
    MuiFormControl: {
      root: {
        'min-width': 'none'
      }
    },
    MuiTypography: {
      root: {
        margin: null,
        '& strong': {
          fontWeight: 'bold'
        }
      },
      body1: {
        fontWeight: 'normal'
      },
      h4: {
        'font-size': '24px',
        fontWeight: 'normal'
      },
      subtitle2: {
        'font-size': '14px',
        fontWeight: 'normal'
      },
      colorSecondary: {
        color: yoigoPalette.medium
      },
      colorTextSecondary: {
        color: grayPalette.gray_dark
      }
    },
    MuiFormLabel: {
      root: {
        'font-weight': 400,
        '&$focused': {
          color: bluePalette.main
        }
      }
    },
    MuiFormHelperText: {
      root: {
        'font-size': '12px',
        'font-weight': 400,
        color: grayPalette.gray_dark
      }
    },
    MuiSelect: {
      select: {
        'font-weight': 400,
        '&:focus': {
          'background-color': 'initial'
        },
        '& em': {
          visibility: 'hidden'
        }
      }
    },
    MuiMenuItem: {
      root: {
        'font-weight': 400
      }
    },
    MuiListItem: {
      root: {
        'font-weight': 400
      }
    },
    MuiTabs: {
      root: {
        '& button': {
          'font-size': '16px',
          'line-height': '1.5',
          'font-weight': 'bold',
          color: '#4a90e2',
          '&.Mui-selected': {
            color: grayPalette.gray_dark
          }
        }
      },
      indicator: {
        height: '3px',
        'background-color': grayPalette.gray_dark
      }
    },
    MuiCard: {
      root: {
        margin: '24px auto',
        'border-radius': '14px',
        'background-color': '#FCFCFC'
      }
    },
    MuiCardContent: {
      root: {
        padding: '24px'
      }
    },
    MuiPaper: {
      elevation1: {
        'box-shadow': "0 0 10px 1px ".concat(grayPalette.gray_medium_02)
      },
      elevation2: {
        'box-shadow': '0 2px 4px 1px rgba(0,0,0, 0.27)'
      }
    },
    MuiGrid: {
      item: {
        margin: null
      }
    },
    MuiButton: {
      root: {
        margin: '4px 0',
        minWidth: '120px',
        borderRadius: '18px',
        border: '1px solid transparent',
        fontSize: '14px',
        fontWeight: 'bold',
        padding: '9px 24px',
        lineHeight: 'normal',
        transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        '& .icon': {
          margin: '-5px 16px -5px -12px'
        },
        '&.MuiButton-text': {
          minWidth: 0,
          '& .icon': {
            fontSize: '14px',
            margin: '0 5px 0 0'
          }
        },
        '&.MuiButton-dashed': {
          backgroundColor: yoigoPalette.light,
          borderRadius: '8px',
          boxShadow: 'none',
          border: '1px dashed rgba(126, 128, 129, 0.4)',
          color: yoigoPalette.action_primary,
          textTransform: 'none',
          '& .icon': {
            fontSize: '16px'
          },
          '&:hover': {
            boxShadow: 'none',
            backgroundColor: bluePalette.blue_50
          }
        },
        '&$disabled': {
          '&.MuiButton-dashed': {
            backgroundColor: getDarken(grayPalette.gray_light, 0.4),
            border: 'none',
            color: yoigoPalette.light,
            '&:hover': {
              backgroundColor: getDarken(grayPalette.gray_dark, 0.4)
            }
          }
        }
      },
      containedPrimary: {
        boxShadow: '0 2px 4px 1px rgba(0, 0, 0, 0.27)',
        '&:hover': {
          backgroundColor: yoigoPalette.medium
        },
        '&$disabled': {
          color: yoigoPalette.light,
          borderColor: grayPalette.gray,
          backgroundColor: grayPalette.gray,
          boxShadow: 'none',
          pointerEvents: 'none'
        }
      },
      outlined: {
        padding: '9px 24px'
      },
      outlinedPrimary: {
        borderColor: yoigoPalette.action_secondary,
        color: yoigoPalette.action_secondary,
        '&:hover': {
          backgroundColor: alpha(yoigoPalette.action_secondary, 0.1),
          borderColor: yoigoPalette.action_secondary
        }
      }
    },
    MuiToggleButton: {
      root: {
        height: '36px',
        color: grayPalette.gray_dark_2,
        borderColor: grayPalette.gray,
        '& + .MuiToggleButton-root': {
          marginLeft: '5px'
        }
      }
    },
    MuiChip: {
      root: {
        borderRadius: '2px',
        fontWeight: 'bold',
        marginRight: '1em',
        backgroundColor: grayPalette.gray_light,
        color: componentColors.BlackBodyText,
        '&.MuiChip-colorWarning': {
          backgroundColor: yoigoPalette.attention,
          color: yoigoPalette.light
        },
        '&.MuiChip-colorBlocked': {
          backgroundColor: grayPalette.gray_dark,
          color: yoigoPalette.light
        },
        '&.MuiChip-colorSuccess': {
          backgroundColor: yoigoPalette.validated,
          color: yoigoPalette.light
        },
        '&.MuiChip-colorError': {
          backgroundColor: yoigoPalette.alert,
          color: yoigoPalette.light
        }
      },
      outlined: {
        '&.MuiChip-colorWarning': {
          backgroundColor: yoigoPalette.light,
          color: componentColors.BlackBodyText,
          borderColor: yoigoPalette.attention
        },
        '&.MuiChip-colorBlocked': {
          backgroundColor: yoigoPalette.light,
          color: componentColors.BlackBodyText,
          borderColor: grayPalette.gray_dark
        },
        '&.MuiChip-colorSuccess': {
          backgroundColor: yoigoPalette.light,
          color: componentColors.BlackBodyText,
          borderColor: yoigoPalette.validated
        },
        '&.MuiChip-colorError': {
          backgroundColor: yoigoPalette.light,
          color: componentColors.BlackBodyText,
          borderColor: yoigoPalette.alert
        }
      },
      colorSecondary: {
        backgroundColor: yoigoPalette.action_secondary,
        color: yoigoPalette.light
      },
      outlineSecondary: {
        borderColor: yoigoPalette.action_secondary
      },
      sizeSmall: {
        height: '18px',
        fontSize: '10px'
      }
    },
    MuiIcon: {
      root: {
        '&.MuiIcon-outlined': {
          fontFamily: 'Material Icons Outlined'
        }
      }
    }
  }
}, THEME_COMMON_PROPS);

var themes = _defineProperty({}, BRANDS.YOIGO, yoigoBrandPalette);

export var getTheme = function getTheme() {
  var brand = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : process.env.REACT_APP_BRAND || BRANDS.YOIGO;
  return themes[brand];
};
export var theme = createTheme(getTheme());