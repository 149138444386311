import { APP_CONFIG } from 'services/app-config'
import { axiosJWT } from 'modules/axios'

const donorAPIUrl = `${APP_CONFIG.sales_cluster}/v1/portabilities/operators`
const checkDonorAPIUrl = `${APP_CONFIG.sales_cluster}/v1/portabilities/fixedPortabilityConditions`

export function fetchOperatorDonor(phone) {
  return axiosJWT({
    method: 'GET',
    url: `${donorAPIUrl}/${phone}`,
  }).then(res => res.data)
}

export function checkFixedOperatorDonor(number, data) {
  const { territoryOwner, technicalId, technology, sfid } = data

  return axiosJWT({
    method: 'POST',
    url: checkDonorAPIUrl,
    data: {
      number,
      territoryOwner,
      technicalId,
      technology,
      sfid,
    },
  }).then(res => res.data)
}
