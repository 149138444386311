import { APP_CONFIG } from 'services/app-config'
import { axiosJWT } from 'modules/axios'

const updateClientConsentsURL = `${APP_CONFIG.sales_cluster}/v1/contracts/update-consents`

export function updateClientConsents(contractId, customerIdType, customerId) {
  return axiosJWT({
    method: 'POST',
    url: updateClientConsentsURL,
    data: {
      contractId,
      customerId,
      customerIdType,
    },
  }).then(resp => resp.data)
}
