import PropTypes from 'prop-types'

import { ButtonLink } from 'components/ui'
import { Typography, Box, Card, CardContent, Icon, CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  removeButton: {
    paddingTop: '12px',
    textAlign: 'left',
  },
  addedPromoText: {
    fontWeight: '500',
    marginRight: '24px',
  },
  contrastPromoText: {
    color: theme.palette.brand.validated,

    '& h6': {
      fontWeight: '500',
      marginRight: '4px',
    },

    '& span': {
      fontSize: '16px',
    },
  },
}))

const MEMBER_GET_MEMBER_CARD_LABEL = '+ PROMOCIÓN "TRAE A UN AMIGO"'

export const MemberGetMemberPromo = ({
  hasPromoBeenApplied,
  handleClick,
  handleRemovePromoClick,
  isLoadingMemberGetMemberInfo,
}) => {
  const classes = useStyles()

  return (
    <>
      {!hasPromoBeenApplied ? (
        <Card className="align-start-start m-y-24">
          <CardContent>
            <ButtonLink
              dataHook="mgm-add-promo"
              onClick={() => handleClick()}
              type="primary"
              underline={false}>
              {MEMBER_GET_MEMBER_CARD_LABEL}
            </ButtonLink>
            {isLoadingMemberGetMemberInfo && (
              <CircularProgress size={16} color="primary" className="m-l-8" />
            )}
          </CardContent>
        </Card>
      ) : (
        <Card className="align-start-start m-y-24" data-hook="mgm-success-card">
          <CardContent>
            <Box display="flex" flexWrap="wrap">
              <Typography variant="subtitle1" className={classes.addedPromoText}>
                Promoción &quot;Trae a un amigo&quot;
              </Typography>
              <Box display="flex" alignItems="center" className={classes.contrastPromoText}>
                <Typography variant="subtitle1">Aplicada</Typography>
                <Icon>check_circle</Icon>
              </Box>
            </Box>
            <ButtonLink
              dataHook="mgm-remove-promo"
              type="primary"
              underline
              className={classes.removeButton}
              onClick={handleRemovePromoClick}>
              Eliminar promoción
            </ButtonLink>
          </CardContent>
        </Card>
      )}
    </>
  )
}

MemberGetMemberPromo.propTypes = {
  hasPromoBeenApplied: PropTypes.bool,
  handleClick: PropTypes.func.isRequired,
  handleRemovePromoClick: PropTypes.func.isRequired,
  isLoadingMemberGetMemberInfo: PropTypes.bool,
}
