import { constants } from './change-agiletv.constants'

export const changeAgileTv = ({ msisdn, oldBundle, newBundle, sfid }) => ({
  type: constants.CHANGE_AGILETV,
  payload: {
    msisdn,
    oldBundle,
    newBundle,
    sfid,
  },
})

export const changeAgileTvSuccess = () => ({
  type: constants.CHANGE_AGILETV_SUCCESS,
})

export const changeAgileTvFailed = error => ({
  type: constants.CHANGE_AGILETV_FAILED,
  payload: error,
})
