import { Modal, ModalActions, Button } from 'components/ui'
import PropTypes from 'prop-types'

export const ErrorScoringModal = ({ isOpen, onClose, scoringMessage }) => (
  <Modal title="ERROR de Scoring" isOpen={isOpen} type="default">
    <p>{scoringMessage}</p>
    <ModalActions>
      <Button onClick={onClose}>Cerrar</Button>
    </ModalActions>
  </Modal>
)
ErrorScoringModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  scoringMessage: PropTypes.string,
}
