import { axiosJWT } from 'modules/axios'

import { APP_CONFIG } from 'services/app-config'

const BASE_URL_SECOND_RESIDENCE_ELEGIBILITY = `${
  APP_CONFIG.sales_cluster
}/v1/tariffs/secondResidence`

export function fetchSecondHomeDiscountEligibility(accountId) {
  return axiosJWT({
    method: 'GET',
    url: `${BASE_URL_SECOND_RESIDENCE_ELEGIBILITY}`,
    params: {
      accountId,
    },
  }).then(res => res.data)
}
