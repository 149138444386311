import { APP_CONFIG } from 'services/app-config'

const iconBasePath = '/appLauncherIcons/'

const externalAppLinksDeferredPayment = [
  {
    id: 'deferred-payment',
    title: 'Landing pago a plazos',
    icon: `${iconBasePath}/payment.png`,
    url: APP_CONFIG.deferred_payment,
    hasPre: true,
  },
]

export const externalAppsLinks = [
  {
    id: 'buhonet',
    title: 'Buhonet',
    icon: `${iconBasePath}/buhonet.png`,
    url: 'https://360.mm-red.net',
    hasPre: false,
  },
  {
    id: '',
    title: 'ACS',
    icon: `${iconBasePath}/acs.png`,
    url: 'http://acs.masmovil.com/auth/login',
    hasPre: false,
  },
  {
    id: 'anovo',
    title: 'Anovo Tool',
    icon: `${iconBasePath}/anovo_tool.png`,
    url: 'https://yoigopostventa.a-novo.es/aspx/login.aspx',
    hasPre: false,
  },
  {
    id: 'btr',
    title: 'BTR',
    icon: `${iconBasePath}/btr.png`,
    url: 'http://smadavbtr.yoigo.inet:8180/EIRWeb/',
    hasPre: false,
  },
  {
    id: 'correos',
    title: 'Correos',
    icon: `${iconBasePath}/correos.png`,
    url:
      'https://www.correos.es/ss/Satellite/site/aplicacion-1349167812778-herramientas_y_apps/detalle_app-sidioma=es_ES',
    hasPre: false,
  },
  {
    id: 'cnmc',
    title: 'CNMC',
    icon: `${iconBasePath}/cnmc.png`,
    url: 'https://numeracionyoperadores.cnmc.es/numeracion',
    hasPre: false,
  },
  {
    id: 'itm',
    title: 'ITM',
    icon: `${iconBasePath}/itm.png`,
    url: 'https://distribuidor.yoigo.com/docs/index.php',
    hasPre: false,
  },
  {
    id: 'jira-azul',
    title: 'Jira CC (azul)',
    icon: `${iconBasePath}/jira_azul.png`,
    url: 'http://mosaic2.yoigo.inet/jira6/secure/Dashboard.jspa',
    hasPre: false,
  },
  {
    id: 'jira-amarillo',
    title: 'Jira MM (amarillo)',
    icon: `${iconBasePath}/jira_amarillo.png`,
    url: 'https://jira.masmovil.com/secure/Dashboard.jspa',
    hasPre: false,
  },
  {
    id: 'kairos',
    title: 'Kairos',
    icon: `${iconBasePath}/kairos.png`,
    url: 'https://masmovil.kairos365.com',
    hasPre: false,
  },
  {
    id: 'koala',
    title: 'Koala',
    icon: `${iconBasePath}/koala.png`,
    url: 'http://koala.yoigo.inet/login?return_to=%2Fissues%2Fopen',
    hasPre: false,
  },
  {
    id: 'live-person',
    title: 'Live Person',
    icon: `${iconBasePath}/live_person.png`,
    url: 'http://liveengage.liveperson.net',
    hasPre: false,
  },
  {
    id: 'lola',
    title: 'Lola',
    icon: `${iconBasePath}/lola.png`,
    url: 'http://lola.netkia.es',
    hasPre: false,
  },
  {
    id: 'maslola',
    title: 'MasLola',
    icon: `${iconBasePath}/mas_lola.png`,
    url: 'http://maslola.netkia.es/',
    hasPre: false,
  },
  {
    id: 'masoss',
    title: 'MasOss',
    icon: `${iconBasePath}/masoss.png`,
    url: APP_CONFIG.masoss,
    hasPre: true,
  },
  {
    id: 'mosaico',
    title: 'Mosaico',
    icon: `${iconBasePath}/mosaico.png`,
    url: 'http://mosaic2.yoigo.inet/mosaicmm/#init',
    hasPre: false,
  },
  {
    id: 'orderbox',
    title: 'OrderBox',
    icon: `${iconBasePath}/orderbox.png`,
    url: 'http://37.18.240.10',
    hasPre: false,
  },
  {
    id: 'schaman',
    title: 'Schaman',
    icon: `${iconBasePath}/schaman.png`,
    url: 'https://schaman.yoigo.com/Schaman/login.xhtml',
    hasPre: false,
  },
  {
    id: 'sgi',
    title: 'SGI',
    icon: `${iconBasePath}/sgi.png`,
    url: 'https://www.portanet.net/SGI/inicio.action',
    hasPre: false,
  },
  {
    id: 'sos-apn',
    title: 'SOS APN',
    icon: `${iconBasePath}/sosapn.png`,
    url: 'http://www.helpforsmartphone.com',
    hasPre: false,
  },
  {
    id: 'topo',
    title: 'Topo',
    icon: `${iconBasePath}/topo.png`,
    url: 'http://172.30.112.208/knowledge/display',
    hasPre: false,
  },
  {
    id: 'vista',
    title: 'Vista',
    icon: `${iconBasePath}/vista.png`,
    url: APP_CONFIG.vista,
    hasPre: true,
  },
  {
    id: 'botones',
    title: 'Web de los botones',
    icon: `${iconBasePath}/botones_mm.png`,
    url: 'http://10.100.6.19:8080/nuevagestiones_MM/login.xhtml',
    hasPre: false,
  },
]

export const externalAppsLinksDeferredPaymentFlag = externalAppLinksDeferredPayment.concat(
  externalAppsLinks,
)
