import { get } from 'lodash'
import { formatNumberWithComma, roundTwoDecimals, formatPercent } from 'utils'
import { SINGLE } from 'services/global-constants'
import { getPriceWithTax } from '../Taxes'

export const isTerminalPaymentMultiple = terminal => {
  return terminal.paymentType !== SINGLE && terminal.paymentType
}

export const isTerminalPaymentSingle = terminal => {
  return terminal.paymentType && terminal.paymentType === SINGLE
}

export const getTerminalComputedPrices = (terminal, tax) => {
  const emptyPrices = {
    finalPayment: null,
    monthlyPayment: null,
    initialPayment: null,
    fees: 1,
    debt: null,
    permanency: null,
  }

  if (!terminal.details || !terminal.details.paymentMethods || !terminal.paymentType) {
    return emptyPrices
  }
  const paymentInfo = terminal.details.paymentMethods[terminal.paymentType]

  if (!paymentInfo) {
    return emptyPrices
  }

  const preFormatedMonthly =
    paymentInfo.monthlyPayment && getPriceWithTax(paymentInfo.monthlyPayment, tax)

  const monthlyPayment = paymentInfo.monthlyPayment
    ? formatNumberWithComma(preFormatedMonthly)
    : null

  const preFormatedInitialPayment =
    paymentInfo.initialPayment && getPriceWithTax(paymentInfo.initialPayment, tax)

  const initialPayment = paymentInfo.initialPayment
    ? formatNumberWithComma(preFormatedInitialPayment)
    : null

  const preFormatedFinalPayment =
    paymentInfo.finalPayment &&
    formatNumberWithComma(getPriceWithTax(paymentInfo.finalPayment, tax))

  const finalPayment = paymentInfo.finalPayment
    ? formatNumberWithComma(preFormatedFinalPayment)
    : null

  const fees = get(paymentInfo, 'fees', 1)

  const preFormatedDebt = isTerminalPaymentMultiple(terminal)
    ? (preFormatedMonthly || 0) * fees + (preFormatedFinalPayment || 0)
    : 0

  const commissionFee = formatNumberWithComma(
    formatPercent(preFormatedDebt, terminal.details.commissionFeePercentage),
  )

  const totalPayment = formatNumberWithComma(
    isTerminalPaymentMultiple(terminal)
      ? roundTwoDecimals(preFormatedDebt + (preFormatedInitialPayment || 0))
      : get(terminal.paymentResult, 'amount'),
  )

  const penalty = get(paymentInfo, 'permanency.penaltyAmountTaxFree', 0)

  const permanency = formatNumberWithComma(penalty)

  const debt = formatNumberWithComma(preFormatedDebt)

  return {
    finalPayment,
    initialPayment,
    monthlyPayment,
    fees,
    totalPayment,
    commissionFee,
    permanency,
    discount: permanency,
    debt,
  }
}

export const getTerminalWithComputedPrices = (terminal, tax) => {
  return {
    ...terminal,
    computedPrices: getTerminalComputedPrices(terminal, tax),
  }
}
