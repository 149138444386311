import axios from 'axios'
import uuidv4 from 'uuid'
import { get } from 'lodash'

const instances = {}

function setDefaultHeaders(axiosInstance, headers) {
  Object.keys(headers).forEach(key => {
    /* eslint-disable no-param-reassign */
    axiosInstance.defaults.headers.common[key] = headers[key]
    /* eslint-enable no-param-reassign */
  })
  return axiosInstance
}

function resetDefaultHeaders(axiosInstance) {
  // eslint-disable-next-line no-param-reassign
  axiosInstance.defaults.headers.common = {}
  return axiosInstance
}

function setCommonDefaultHeaders(headers) {
  setDefaultHeaders(axios, headers)
  return Object.keys(instances).map(instanceName =>
    setDefaultHeaders(instances[instanceName], headers),
  )
}

function resetCommonDefaultHeaders() {
  resetDefaultHeaders(axios)
  return Object.keys(instances).map(instanceName => resetDefaultHeaders(instances[instanceName]))
}

function initAxios(axiosInstance) {
  axiosInstance.interceptors.request.use(config => {
    /* eslint-disable no-param-reassign */
    config.headers.common['X-Request-ID'] = uuidv4()
    return config
  })

  setDefaultHeaders(axiosInstance, {
    'X-Correlation-ID': uuidv4(),
    'X-Application-ID': 'TransformacionIT',
    // Comment out when apigee accept this headers
    // 'x-front-client': 'ovid-web'
  })
  return axiosInstance
}

function getAxios(name, defaultConfig) {
  instances[name] = instances[name] || initAxios(axios.create(defaultConfig))
  return instances[name]
}

function buildError(e) {
  const response = get(e, 'response.data.result')
  return (
    response || {
      code: 'bad_request',
      httpCode: get(e, 'response.status', '400'),
      info: get(
        e,
        'response.data.error_message',
        'The request had bad syntax or was inherently impossible to be satisfied',
      ),
    }
  )
}

export const axiosFactory = {
  setDefaultHeaders,
  resetDefaultHeaders,
  setCommonDefaultHeaders,
  resetCommonDefaultHeaders,
  initAxios,
  getAxios,
  buildError,
}
