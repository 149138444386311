var bluePalette = {
  light: '#5A99E2',
  main: '#4A90E2',
  dark: '#3181E0',
  contrastText: '#FFF',
  blue_50: '#E9F2FF',
  blue_500: '#6EAAFF'
};
var redPalette = {
  red_50: '#FFECEA',
  red_500: '#d32f2f'
};
var componentColors = {
  BlackOutline: '#5E5E5E',
  BlackHeadsText: '#555',
  BlackBodyText: '#646464',
  BlackCaptionText: '#2E2E3B',
  BlueLinkText: bluePalette.main,
  White: '#FFF'
}; // Fixed Colors (They don´t change)

var grayPalette = {
  gray_dark: '#4D4E4F',
  gray_dark_2: '#51556F',
  gray_medium: '#898B8C',
  gray_medium_2: '#E9E9E9',
  gray: '#B9C2C7',
  gray_light: '#EFEFEF',
  gray_very_light: '#EDEDED',
  gray_dark_05: 'rgba(77, 78, 79, 0.5)',
  gray_light_05: 'rgba(191, 191, 191, 0.5)',
  gray_medium_04: 'rgb(137, 139, 140, 0.4)',
  gray_medium_02: 'rgba(137, 139, 140, 0.2)',
  gray_medium_06: '#8C8E91',
  gray_medium_07: '#D8D8D8'
};
var orangePalette = {
  orange_50: '#ffebcd',
  orange_500: '#e48c04'
}; // Brand Palettes

var yoigoPalette = {
  light: '#FFF',
  medium: '#2B67BB',
  action_primary: bluePalette.main,
  action_secondary: '#35348A',
  action_tertiary: '#AE3F97',
  attention: '#FF9C05',
  attention_01: 'rgba(255, 156, 5, 0.1)',
  attention_secondary: '#FFEBCD',
  validated: '#9ADC30',
  alert: '#FF341E',
  bck: '#EBF0F5',
  degradedRadial: 'radial-gradient(circle at top left, #8ADDFF, #18BCFF)',
  degradedRadialAlert: 'radial-gradient(circle at top left, #F19E66  20%, #E55F3A 80%)',
  degradedRadialInfo: 'radial-gradient(circle at top left, #8ADDFF  20%, #18BCFF 80%)',
  degradedRadialWarning: 'radial-gradient(circle at top left, #FFD79B  20%, #FFAF37 80%)'
};
var greenPalette = {
  green_50: '#F3FDE3',
  green_500: '#85D10A',
  green_600: '#43a047'
};
var segmentPalette = {
  consumer: '#72BA45',
  "default": '#4D4E4F',
  influencer: '#FFE54D',
  pro: '#00B8EE',
  soho: '#A175F9',
  warning: '#FF341E',
  customerSva: '#4140a2'
};
export { grayPalette, bluePalette, redPalette, componentColors, orangePalette, yoigoPalette, greenPalette, segmentPalette };