import { useEffect, memo } from 'react'
import { createStructuredSelector } from 'reselect'
import { useSelector, useDispatch } from 'react-redux'
import { get } from 'lodash'
import * as logisticOrdersSvc from 'services/logistic-orders'
import * as ordersSvc from 'modules/orders'
import { selectCurrentHistoricOrder } from 'services/customer-360/selectors'
import * as offersDetailSvc from 'services/offers-detail'
import * as atcSvc from 'services/atc'
import { selectIsPosOrMasProximo } from 'modules/Permissions/store/permissions-selectors'
import { OrderDetail } from '../../components/OrderDetail/OrderDetail'
import * as orderDetailLoad from '../../sagas/orderDetailLoad'

import {
  extractDataOrderDetailSell,
  extractDataOrderDetailDocuments,
} from '../../selectors/selectors'

// selector with params as described here
// https://github.com/reduxjs/reselect/blob/master/README.md#containersvisibletodolistjs-3
function makeLogisticDataSelector() {
  return createStructuredSelector({
    dataLogistic: logisticOrdersSvc.selectors.selectLogisticDataByOrderId,
    isLoadingLogistic: logisticOrdersSvc.selectors.selectLogisticLoadingByOrderId,
  })
}

const orderDetailContainerSelector = createStructuredSelector({
  dataOrder: ordersSvc.getOrderData,
  dataDocuments: extractDataOrderDetailDocuments,
  dataSellInfo: extractDataOrderDetailSell,
  isLoadingTab: orderDetailLoad.selectors.isLoading,
  isLoadingProducts: offersDetailSvc.selectors.isLoading,
  errorProducts: offersDetailSvc.selectors.hasErrors,
  orderStatus: selectCurrentHistoricOrder,
  isLoadingAtc: atcSvc.selectors.selectIsLoading,
  hasErrorsAtc: atcSvc.selectors.selectHasError,
  iframeUrlAtc: atcSvc.selectors.selectIframeUrl,
})

export const OrderDetailContainer = memo(() => {
  const msisdn = useSelector(ordersSvc.getProductNumber).split(' ')[0]
  const dispatch = useDispatch()
  const orderId = useSelector(ordersSvc.selectURLOrderId)
  const dataLogisticSelector = makeLogisticDataSelector()
  const { dataLogistic, isLoadingLogistic } = useSelector(state =>
    dataLogisticSelector(state, orderId),
  )

  const childProps = {
    ...useSelector(orderDetailContainerSelector),
    offers: get(useSelector(offersDetailSvc.selectors.getData), 'subscriptions'),
    orderId,
    dataLogistic,
    isLoadingLogistic,
    isPosOrMasProximo: useSelector(selectIsPosOrMasProximo),
  }

  useEffect(() => {
    dispatch(orderDetailLoad.actions.loadInit(orderId, msisdn))
  }, [msisdn])

  useEffect(() => {
    dispatch(atcSvc.actions.fetchAtcAuthToken(orderId))
  }, [])

  return <OrderDetail {...childProps} />
})
