import { useSelector } from 'react-redux'
import { getChangedTariff, getNewTariff } from '../selectors'

export const useChangedTariff = subscription => {
  const newTariff = useSelector(getNewTariff(subscription))
  const changedTariff = useSelector(getChangedTariff(subscription))

  return {
    changedTariff,
    allowUndoChanges: !newTariff,
  }
}
