import { get, pickBy, isEmpty } from 'lodash'
import uuidv4 from 'uuid'
import CheckoutGenerator from 'services/checkout-order-generator/CheckoutGenerator'
import { COMPANY } from 'services/global-constants'

const generateCompanyUser = (
  {
    party,
    companyData,
    billingAddress,
    personalInformation,
    individualIdentification,
    companyIdentification,
    individualContactMedia,
  },
  shouldGenerateContact,
) => {
  const organization = party.createOrganizations()
  organization.setAttributes({ attributes: companyData })
  organization.addIdentification(companyIdentification)
  organization.addContactMedia({
    ...billingAddress,
    role: 'billing',
    mediumType: 'postal-address',
  })
  const organizationAdmin = organization.createParty({
    role: 'internal',
    sub_role: 'administrator',
  })
  const adminIndividual = organizationAdmin.createIndividuals()
  organizationAdmin.createPartyAttributes(personalInformation)
  adminIndividual.addIdentification(individualIdentification)
  individualContactMedia.forEach(elem => adminIndividual.addContactMedia(elem))

  if (shouldGenerateContact) {
    const organizationContact = organization.createParty({
      role: 'internal',
      sub_role: 'contact',
    })
    const contactIndividual = organizationContact.createIndividuals()
    organizationContact.createPartyAttributes(personalInformation, true)
    contactIndividual.addIdentification(individualIdentification)
    individualContactMedia.forEach(elem => contactIndividual.addContactMedia(elem))
  }
}

const generateIndividualCustomer = ({
  party,
  personalInformation,
  individualIdentification,
  billingAddress,
  individualContactMedia,
}) => {
  const individual = party.createIndividuals()
  party.createPartyAttributes(pickBy(personalInformation))
  individual.addIdentification(individualIdentification)
  individual.addContactMedia({
    ...billingAddress,
    role: 'billing',
    mediumType: 'postal-address',
  })
  individualContactMedia.forEach(elem => individual.addContactMedia(elem))
}

const generateAdditionalUsers = ({ order, otherUserIdentification, ...info }) => {
  if (!otherUserIdentification.length) return
  otherUserIdentification.forEach(elem => {
    const party = order.createRelatedParty({
      role: 'internal',
      sub_role: `${elem.type || 'mobile'}-line-holder`,
    })
    if (elem.isCompany) {
      // TODO: Add company admin information if needed or developed in the frontend, otherwise remove this
      /*
        generateAdditionalUserIndividualContactMedia
        generateAdditionalUserIndividualIdentification
        generateAdditionalUserPersonalInformation
      */
      generateCompanyUser(
        {
          party,
          ...info,
          ...elem,
        },
        true,
      )
    } else {
      generateIndividualCustomer({
        party,
        ...info,
        ...elem,
      })
    }
  })
}

export const generateCustomer = (information, account, segment) => {
  const party = account.createParty({ role: 'customer' })
  if (segment === COMPANY) {
    generateCompanyUser({ party, ...information })
  } else {
    generateIndividualCustomer({ party, ...information })
  }
}

export const generateOrder = information => {
  const {
    salesInfo,
    offerDetail,
    extraLinesDetails,
    segment,
    terminalPaymentInfo,
    allOrders,
    personalInformation,
    billingAddress,
    individualIdentification,
    individualContactMedia,
    companyData,
    companyIdentification,
    isSVAEmail,
    isAppleTv,
  } = information

  const basket = new CheckoutGenerator({ offersMetadata: [offerDetail, ...extraLinesDetails] })
  const { isPos, isMultiSim } = salesInfo
  basket.setSalesInfo(salesInfo)

  let billing
  allOrders.forEach(
    (
      { rootOrderItem, orderChildren, billingAccount, otherUserIdentification, orderMobile, id },
      index,
    ) => {
      const order = basket.createOrder(id)
      order.setRequestedStartDateTime(rootOrderItem)

      order.setRootOrderItem(rootOrderItem)
      const orderBilling = order.addBillingAccount(billingAccount)
      billing = !billing ? orderBilling : billing
      if (!isEmpty(terminalPaymentInfo[index])) {
        if (!terminalPaymentInfo[index].isFree) {
          basket.setTerminalPaymentInfo(terminalPaymentInfo[index], id)
        }
        if (orderMobile) {
          const idChild = `order-child-${uuidv4()}`
          const orderChild = order.createOrderChild(idChild)
          orderChild.setRootOrderItem(get(orderMobile, 'characteristic', {}))
          if (!isPos && !isMultiSim) {
            orderChild.addInstallationMedium(get(orderMobile, 'installation', {}))
          }
        }
      }

      orderChildren.forEach(child => {
        const idChild = `order-child-${uuidv4()}`
        const orderChild = order.createOrderChild(idChild)
        const { noDelivery, category, ...characteristics } = get(child, 'characteristic', {})

        orderChild.setRootOrderItem(characteristics)
        if (!isAppleTv && isSVAEmail && characteristics?.productOfferingId?.includes('AGILETV')) {
          const idTvChild = `order-child-${uuidv4()}`
          const tvOrderChild = orderChild.createOrderChild(idTvChild)
          tvOrderChild.setRootOrderItem({ productOfferingId: 'G050DTVN2STBBBB001' })
        }

        if ((!isPos && !isMultiSim) || category === 'router') {
          orderChild.addInstallationMedium(get(child, 'installation', {}), noDelivery)
        }
      })

      generateAdditionalUsers({
        order,
        otherUserIdentification,
        personalInformation,
        individualIdentification,
        billingAddress,
        individualContactMedia,
        companyData,
        companyIdentification,
      })
    },
  )

  const account = basket.createCustomerAccount({
    type: 'customer-accounts',
    name: 'default',
  })

  generateCustomer(information, account, segment)

  account.addBillingAccountObject(billing)

  return basket.result
}
