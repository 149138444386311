import { createStructuredSelector } from 'reselect'

import { selectDocumentId, selectDocumentType } from 'modules/Auth'
import { permissions, selectCanIDO } from 'modules/Permissions'

import * as ticketingSvc from 'services/ticketing'
import * as subscriptionsSvc from 'services/subscriptions'
import { selectURLProductId } from 'services/customer-products'
import { selectIsLoading, selectHasErrors } from '../../sagas/productDashboardLoad'

export const productDashboardContainerSelectors = () =>
  createStructuredSelector({
    isLoading: selectIsLoading,
    hasErrors: selectHasErrors,
    productId: selectURLProductId,
    subscription: subscriptionsSvc.getSubscriptionByCurrentProductId,
    caseOpened: ticketingSvc.isCaseOpened,
    connectionData: createStructuredSelector({
      documentType: selectDocumentType,
      documentId: selectDocumentId,
    }),
    permissionClientOpsAccess: selectCanIDO(permissions.client_ops_access.id),
  })
