import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { isEmpty, find } from 'lodash'
import { UNEXPECTED_ERROR_RETRY } from 'modules/SharedSales/constants'
import { makeStyles } from '@material-ui/styles'

import { Card, CardContent, Typography, Grid } from '@material-ui/core'

import { NextSteps, ButtonLink, AlertAdvice, Button } from 'components/ui'

import { LinesOrderSummary } from 'modules/Lines'
import { selectDownloadContract } from 'services/feature-flag/selectors'
import { useSelector } from 'react-redux'
import {
  useDownloadPDFContract,
  useDownloadTXTContract,
  useDownloadWelcomeLetter,
  useDownloadReadableContract,
} from '../../hooks/Contracts'
import { ErrorModal, QuitButton } from '../Common'

const styles = makeStyles(theme => ({
  buttonError: {
    '& button.custom_button': {
      'background-color': theme.palette.brand.alert,
    },
  },
}))

export const NextStepsAndResult = ({
  fiberType,
  financedTerminals,
  singlePaymentTerminals,
  hasTerminals,
  isFixedPortability,
  isPortabilityFromFtth,
  errorCheckout,
  errorAppointment,
  isPosSale,
  isCompany,
  agileTV,
  installsMM,
  isInstallationRequired,
  hasIUA,
  nextStepsTexts,
  isElFijo,
  isPos,
  hasVoiceBox,
  is2p,
  is2pPure,
  isConvergent,
  isMultiSim,
  gdprHasError,
  homeGoSvaIsLoading,
  homeGoSvaHasError,
  retryHomeGoSva,
  selectedHomeGoAlarm,
}) => {
  const { downloadWelcomeLetter, welcomeError } = useDownloadWelcomeLetter()
  const { downloadPDFContract, contractError } = useDownloadPDFContract()
  const { downloadTXTContract, txtError } = useDownloadTXTContract()
  const { downloadReadableContract, readableContractError } = useDownloadReadableContract()
  const downloadError = useMemo(
    () => welcomeError || contractError || txtError || readableContractError,
    [welcomeError, contractError, txtError, readableContractError],
  )

  const hasOrderFailed = !!find(errorCheckout, lineError => !lineError.id)

  const downloadContract = useSelector(selectDownloadContract)
  const classes = styles()

  const isDocumentsDownloadAllowed =
    isPosSale && (isElFijo || is2pPure || is2p || isMultiSim) && !hasOrderFailed

  return (
    <>
      <Card style={{ margin: '32px auto' }}>
        <CardContent>
          <LinesOrderSummary errorCheckout={errorCheckout} />
          {errorAppointment && (
            <AlertAdvice
              className="m-t-16"
              message="La orden se ha creado correctamente, sin embargo no es posible citar al cliente. Por favor, informa al cliente que nos pondremos en contacto con él para realizar la cita"
              block
            />
          )}
          {gdprHasError && (
            <AlertAdvice
              className="m-t-16"
              message="Ha ocurrido un error al guardar la configuración de la política de privacidad y tratamiento de datos del cliente."
              block
            />
          )}

          {homeGoSvaIsLoading && (
            <AlertAdvice
              className="m-t-16"
              type="warning"
              message="Procesando el alta del SVA de alarma HomeGo..."
              block
            />
          )}

          {homeGoSvaHasError && (
            <AlertAdvice
              className="m-t-16"
              message="Ha ocurrido un error al procesar el alta del SVA de alarma HomeGo."
              block
              onRetry={retryHomeGoSva}
            />
          )}
        </CardContent>
      </Card>
      <Card style={{ margin: '32px auto' }}>
        <CardContent>
          {!isEmpty(nextStepsTexts) && (
            <NextSteps
              isConvergent={isConvergent}
              isPos={isPos}
              isElFijo={isElFijo}
              nextStepsTexts={nextStepsTexts}
              fiberType={fiberType}
              hasTerminals={hasTerminals}
              financedTerminals={financedTerminals}
              singlePaymentTerminals={singlePaymentTerminals}
              isFixedPortability={isFixedPortability}
              isPortabilityFromFtth={isPortabilityFromFtth}
              isCompany={isCompany}
              installsMM={installsMM}
              isInstallationRequired={isInstallationRequired}
              agileTV={agileTV}
              hasIUA={hasIUA}
              hasVoiceBox={hasVoiceBox}
              is2p={is2p}
              selectedHomeGoAlarm={selectedHomeGoAlarm}
            />
          )}
        </CardContent>
      </Card>
      {isDocumentsDownloadAllowed && (
        <Card style={{ margin: '32px auto' }}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Typography variant="body1" className="bold">
                  Descarga de documentos:{' '}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <ButtonLink underline={false} onClick={downloadPDFContract}>
                  Contrato PDF
                </ButtonLink>
              </Grid>
              <Grid item xs={2}>
                <ButtonLink underline={false} onClick={downloadTXTContract}>
                  Contrato TXT
                </ButtonLink>
              </Grid>
              <Grid item xs={3}>
                <ButtonLink underline={false} onClick={downloadWelcomeLetter}>
                  Carta de presentación (pdf)
                </ButtonLink>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}
      <Card style={{ margin: '32px auto' }}>
        <CardContent>
          <Typography variant="body1">
            Ya está. En este momento comenzamos la gestión de tu pedido. Si para finalizarlo fuera
            necesario solicitar documentación, nos pondremos en contacto contigo.
          </Typography>
          <Typography variant="body1" className="bold" style={{ textTransform: 'uppercase' }}>
            Bienvenido a Yoigo. ¡Que pases un buen día!
          </Typography>
        </CardContent>
      </Card>
      <ErrorModal hasMsError={downloadError} error={UNEXPECTED_ERROR_RETRY} />
      <div className="flex">
        <div>
          <QuitButton />
        </div>
        {downloadContract && (
          <div className={hasOrderFailed ? `${classes.buttonError} m-l-16` : 'm-l-16'}>
            <Button className="custom_button" onClick={downloadReadableContract}>
              DESCARGAR DATOS DE LA VENTA
            </Button>
          </div>
        )}
      </div>
    </>
  )
}

NextStepsAndResult.propTypes = {
  fiberType: PropTypes.string.isRequired,
  financedTerminals: PropTypes.array.isRequired,
  isFixedPortability: PropTypes.bool.isRequired,
  isPortabilityFromFtth: PropTypes.bool.isRequired,
  errorCheckout: PropTypes.array.isRequired,
  errorAppointment: PropTypes.bool,
  isPosSale: PropTypes.bool,
  isCompany: PropTypes.bool,
  hasTerminals: PropTypes.bool,
  agileTV: PropTypes.object.isRequired,
  installsMM: PropTypes.bool.isRequired,
  isInstallationRequired: PropTypes.bool,
  hasIUA: PropTypes.bool,
  isElFijo: PropTypes.bool,
  isPos: PropTypes.bool,
  hasVoiceBox: PropTypes.bool,
  nextStepsTexts: PropTypes.object,
  is2p: PropTypes.bool,
  is2pPure: PropTypes.bool,
  isConvergent: PropTypes.bool,
  isMultiSim: PropTypes.bool,
  gdprHasError: PropTypes.bool,
  singlePaymentTerminals: PropTypes.array,
  homeGoSvaIsLoading: PropTypes.bool,
  homeGoSvaHasError: PropTypes.bool,
  retryHomeGoSva: PropTypes.func,
  selectedHomeGoAlarm: PropTypes.object,
}
