import { axiosCustomer, axiosJWT } from 'modules/axios'
import { CONFIG } from './environments'

export function updatePenaltyCondonation(msisdn, amount, reason, segment) {
  return axiosCustomer({
    method: 'PATCH',
    url: CONFIG.api.condonePenalties,
    data: {
      msisdn,
      amount,
      reason,
      segment,
    },
  }).catch(e => {
    e.error_message = e.info
    throw e
  })
}

const composeDate = date => (date < 10 ? `0${date}` : date)

const formatDate = (time = Date.now()) => {
  const t = new Date(time)
  return `${t.getFullYear()}-${composeDate(t.getMonth() + 1)}-${composeDate(t.getDate())}`
}

// const formatPermanency = duration => `DTERM${duration}-${duration}0`

export function updateDiscount(
  msisdn,
  sfid,
  subscriptionId,
  discountCode,
  value,
  duration,
  activationStamp,
  permanency,
) {
  const data = {
    type: 'subscriptions-apply-discount',
    attributes: {
      'subscription-id': Number(subscriptionId),
      username: sfid,
      'discount-id': discountCode,
      amount: value,
      'valid-from': formatDate(activationStamp),
      'valid-period': duration,
      permanency,
    },
  }
  return axiosJWT({
    method: 'POST',
    url: CONFIG.api.applyDiscount,
    data: { data },
  }).catch(e => {
    e.response.data.error_message = e.response.data.detailMsg
    throw e
  })
}

export const getTariffsStc = (
  segment,
  channel,
  status,
  subscriptionId,
  phase2 = false,
  purpose,
  territoryOwner,
) => {
  const offerType = 'stc'
  const params = { channel, offerType, status }
  if (phase2) {
    params.subscriptionId = subscriptionId
    params.purpose = purpose
    params.territoryOwner = territoryOwner
  } else {
    params.segment = segment
  }

  return axiosJWT({
    method: 'GET',
    url: phase2 ? CONFIG.api.getTariffsV3Phase2 : CONFIG.api.getTariffsV3,
    params,
  }).catch(e => {
    e.response.data.error_message = e.response.data.message
    throw e
  })
}

export const getOrderElegibility = (
  subscriptionId,
  tariffId,
  activationStamp,
  salesType,
  territoryOwner,
) => {
  const data = { subscriptionId, tariffId, activationStamp, salesType, territoryOwner }

  return axiosJWT({
    method: 'POST',
    url: CONFIG.api.getOrderElegibility,
    data: { data },
  }).catch(e => {
    e.response.data.error_message = e.response.data.detailMsg
    throw e
  })
}

export const getOrderConsequences = (subscriptionId, tariffId) => {
  const data = { subscriptionId, tariffId }

  return axiosJWT({
    method: 'POST',
    url: CONFIG.api.getOrderConsequences,
    data: { data },
  }).catch(e => {
    e.response.data.error_message = e.response.data.detailMsg
    throw e
  })
}

export const getTechnology = territoryOwner =>
  axiosJWT({
    method: 'GET',
    url: `${CONFIG.api.getTechnology}/${territoryOwner}/parent`,
  }).catch(e => {
    e.response.data.error_message = e.response.data.detailMsg
    throw e
  })

export const updateTechnologyChange = options =>
  axiosJWT({
    method: 'POST',
    url: CONFIG.api.technologyChange,
    data: options,
  }).catch(e => {
    e.response.data.error_message = e.response.data.detailMsg
    throw e
  })

export const updateAddressChange = options =>
  axiosJWT({
    method: 'POST',
    url: CONFIG.api.addressChange,
    data: options,
  }).catch(e => {
    e.response.data.error_message = e.response.data.detailMsg
    throw e
  })

export const getInstaller = gescal17 =>
  axiosJWT({
    method: 'GET',
    url: `${CONFIG.api.getInstaller}/${gescal17}`,
  }).catch(e => {
    e.response.data.error_message = e.response.data.detailMsg
    throw e
  })
