import PropTypes from 'prop-types'

import { times } from 'lodash'

import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import { PlaceholderLine } from 'components/ui'
import { SidebarClient } from 'components/client'
import ClientBasicInfo from '../ClientBasicInfo/ClientBasicInfo'

const useStyles = makeStyles({
  'aside-placeholder': {
    margin: '12px 0',
  },
})

function AsideLoading() {
  const classes = useStyles()

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <PlaceholderLine className={classes['aside-placeholder']} height="185px" width="95%" />
      {times(5, i => (
        <PlaceholderLine
          key={i}
          className={classes['aside-placeholder']}
          height="20px"
          width="95%"
        />
      ))}
    </Box>
  )
}

export const AsideDashboard = ({
  selectedSection,
  selectedId,
  data,
  isLoading,
  onSelect,
  isCustomer,
}) => (
  <>
    {!isLoading ? (
      <Box display="flex" height="calc(100vh - 64px)" flexDirection="column">
        <ClientBasicInfo data-hook="client-info-aside" />

        <SidebarClient
          data={data}
          selectedType={selectedSection}
          selectedId={selectedId}
          onItemClick={onSelect}
          isCustomer={isCustomer}
        />
      </Box>
    ) : (
      <AsideLoading />
    )}
  </>
)

AsideDashboard.propTypes = {
  isLoading: PropTypes.bool,
  data: PropTypes.object,
  selectedSection: PropTypes.string,
  selectedId: PropTypes.string,
  onSelect: PropTypes.func,
  isCustomer: PropTypes.bool,
}
