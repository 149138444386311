import { get, omit } from 'lodash'
import * as constants from './constants'

const initialState = {
  orders: {},
  productOrders: {},
  loadingByProductId: {},
  errorByProductId: {},
  data: {},
  error: null,
  loading: null,
}

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case constants.FIND_PRODUCT_ORDERS: {
      return {
        ...state,
        loadingByProductId: {
          ...state.loadingByProductId,
          [payload.productId]: true,
        },
        errorByProductId: omit(state.errorByProductId, [payload.productId]),
        loading: true,
      }
    }
    case constants.FIND_PRODUCT_ORDERS_SUCCESS: {
      return {
        ...state,
        data: payload.data[0],
        orders: {
          ...state.orders,
          ...payload.data.reduce(
            (acum, d) => ({
              ...acum,
              [d.id]: d,
            }),
            {},
          ),
        },
        productOrders: {
          ...state.productOrders,
          [payload.productId]: payload.data.map(d => d.id),
        },
        loadingByProductId: omit(state.loadingByProductId, [payload.productId]),
        loading: false,
      }
    }
    case constants.FIND_PRODUCT_ORDERS_FAILED: {
      return {
        ...state,
        loadingByProductId: omit(state.loading, [payload.productId]),
        errorByProductId: {
          ...state.errorByProductId,
          [payload.productId]: {
            code: get(payload, 'error.httpCode'),
            message: get(payload, 'error.info'),
          },
        },
        loading: false,
        error: {
          code: get(payload, 'error.httpCode'),
          message: get(payload, 'error.info'),
        },
      }
    }
    default:
      return state
  }
}
