import { call, put, takeEvery } from 'redux-saga/effects'
import { logError, formatErrorMessage } from 'services/logging'
import { axiosFactory } from 'modules/axios'
import {
  getPondAction,
  getPondSuccessAction,
  getPondFailedAction,
  GET_POND_INIT,
} from './pond.actions'
import { getPond } from '../services/pond.service'

export function* getPondSaga({ payload: { orderId } }) {
  try {
    yield put(getPondAction())
    const data = yield call(getPond, orderId)
    yield put(getPondSuccessAction(data))
  } catch (e) {
    yield call(logError, { e: new Error(formatErrorMessage(e)), orderId })
    const error = axiosFactory.buildError(e)
    yield put(getPondFailedAction(error))
  }
}

export function* watchGetPond() {
  yield takeEvery(GET_POND_INIT, getPondSaga)
}
