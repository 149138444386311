import PropTypes from 'prop-types'
import styled from 'styled-components'
import { get } from 'lodash'

const OfferContentStyled = styled.div`
  position: relative;
  background: ${props => get(props, 'theme.palette.brand.light')};
  height: 380px;
  width: 100%;
  .offer-item {
    padding: 19px 25px 20px;
    height: 95px;
    width: 100%;
    box-sizing: border-box;
    & + .offer-item {
      border-top: 1px solid #ececec;
    }
  }
`
const OfferContent = props => {
  const { multicolumn, children } = props
  return (
    <OfferContentStyled className={multicolumn ? 'multicolumn' : null}>
      {children}
    </OfferContentStyled>
  )
}

OfferContent.propTypes = {
  multicolumn: PropTypes.bool,
  children: PropTypes.any,
}

export default OfferContent
