import { memo } from 'react'
import { createStructuredSelector, createSelector } from 'reselect'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Route } from 'react-router-dom'
import { SpinnerCenter } from 'components/ui'
import { NotAuthenticatedSection } from './NotAuthenticatedSection'
import {
  selectIsLogged,
  selectIsLoggedCustomer,
  selectIsLoading,
  selectCustomerIsLoading,
} from '../store/selectors'

const authenticatedRouteSelector = createStructuredSelector({
  isLogged: selectIsLogged,
  isLoggedCustomer: selectIsLoggedCustomer,
  isLoading: createSelector(
    [selectIsLoading, selectCustomerIsLoading],
    (authIsLoading, authCustomerIsLoading) => authIsLoading || authCustomerIsLoading,
  ),
})

export const AuthenticatedRoute = memo(({ component: Component, render, isCustomer, ...rest }) => {
  const { isLogged, isLoggedCustomer, isLoading } = useSelector(authenticatedRouteSelector)

  return (
    <Route
      {...rest}
      render={props => {
        if (isCustomer ? isLoggedCustomer : isLogged) {
          return Component ? <Component /> : render && render(props)
        }

        return isLoading ? <SpinnerCenter /> : <NotAuthenticatedSection />
      }}
    />
  )
})

AuthenticatedRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  render: PropTypes.func,
  isCustomer: PropTypes.bool,
}
