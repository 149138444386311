import { isEmpty } from 'lodash'
import { createSelector } from 'reselect'
import { getOrderId } from 'modules/orders'
import { selectPond } from 'modules/pond'
import { selectCustomerTokenError } from 'modules/Auth'
import { selectProfileId } from 'modules/Permissions'

const selectCanApplyDiscounts = createSelector(
  selectProfileId,
  profileId => !isEmpty(profileId),
)

const getDiscountsOrderButtonData = (orderId, pond, canApplyDiscounts, customerTokenError) => ({
  orderId,
  pond,
  canApplyDiscounts,
  isCustomerTokenError: !!customerTokenError,
})

export const selectDiscountsOrderButtonData = createSelector(
  [getOrderId, selectPond, selectCanApplyDiscounts, selectCustomerTokenError],
  getDiscountsOrderButtonData,
)
