export const mobileNumberRegex = /^(6|7)[0-9]{8}$/

export const iccNumberRegex = /^[1-9][0-9]{18}$/

export const landlineRegex = /^(8|9)[0-9]{8}$/

export const mobileOrLanlineNumberRegex = /^(8|9|6|7)[0-9]{8}$/

export const zipCodeRegex = /^(?:0[1-9]|[1-4]\d|5[0-2])\d{3}$/

export const phoneNumber = /^[0-9]{9}$/

export const noSpecialCharactersRegex = /^[0-9a-zA-Z\sñÑ]*$/

export const imeiRegex = /^[0-9]{15}$/

export const emailRegex = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
