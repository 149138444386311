import { get, orderBy, isNil, isEmpty } from 'lodash'
import { roundTwoDecimals } from 'utils'
import { differenceInCalendarMonths, parseISO } from 'date-fns'
import { findFee } from 'services/subscriptions/helpers'
import { applyTax } from 'services/taxes'
import { applyDiscount, isFixedDiscountUnit } from '../helpers'

export const getTariffFee = tariff =>
  Number(tariff?.monthly_fee || get(findFee(tariff), 'price.amount', 0))

export const getTariffMultisimWithTax = (tariff, tax) => {
  const multisimData = get(tariff, 'multisimData')
  const multisimPrice = !isEmpty(multisimData)
    ? roundTwoDecimals(
        applyTax(
          tax,
          Number(
            multisimData
              .map(multisim => get(findFee(multisim), 'price.amount', 0))
              .reduce((prev, current) => prev + current, 0),
          ),
        ),
      )
    : 0

  return multisimPrice
}
export const byAscendingDuration = (a, b) =>
  (get(a, 'duration') || Infinity) - (get(b, 'duration') || Infinity)

export const generateEmptyDiscounts = durations =>
  durations.map((duration = 0) => ({ duration, unit: 'Percentage', value: 0 }))

export const normalizeCurrentDiscount = charge => {
  const periodLength = get(charge, 'discount_plan.validity_length_in_periods')
  const validToFormatted = parseISO(get(charge, 'subscription_discount.valid_to', new Date()))

  const isDiscountLastMonth = new Date().getMonth() === validToFormatted.getMonth()

  const remainingMonths = differenceInCalendarMonths(validToFormatted, new Date()) || -1

  return {
    id: get(charge, 'msisdn'),
    duration: periodLength && remainingMonths,
    lastMonth: isDiscountLastMonth,
    unit: get(charge, 'discount_plan.amount_unit'),
    value: get(charge, 'subscription_discount.amount'),
    discountCode: get(charge, 'discount_plan.rule_id'),
    partialFee: get(charge, 'subscription_discount.partial_fee'),
    prices: get(charge, 'subscription_discount.prices'),
    isProrratedDuo: get(charge, 'isProrratedDuo'),
  }
}

export const applyDiscounts = (fee, discounts, prices = null, relatedMobileTariff = null) => {
  if (!discounts.length) return []
  const [{ duration, isProrratedDuo }] = discounts

  if (!isNil(relatedMobileTariff)) {
    discounts.reduce((accumulator, current, index) => {
      accumulator[index].relatedMobileTariff = relatedMobileTariff
      accumulator[index].prices = prices

      return accumulator
    }, discounts)
  }

  // Order discounts by fixed/percentage. Apply first the percentage discounts and then the rest.
  const sortedDiscounts = orderBy(
    discounts,
    discount => !isFixedDiscountUnit(discount.unit),
    'desc',
  )

  const amountDiscounts = sortedDiscounts.reduce((acc, discount) => {
    return acc + applyDiscount(fee, discount, true)
  }, 0)

  const remainingPromos = discounts.filter(discount => discount.duration !== duration)
  return [
    {
      monthlyFee: fee - amountDiscounts,
      duration,
      isProrratedDuo,
    },
    ...applyDiscounts(fee, remainingPromos, prices),
  ]
}
