import { useSelector } from 'react-redux'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import { ThemedTable, ThemedCellHeader, ThemedTableRow, SpinnerCenter } from 'components/ui'
import { Card } from 'modules/ui'
import { TableHead } from '@material-ui/core'
import { selectBillingDifferencesFlag } from 'services/feature-flag/selectors'
import { groupBillsByMonth, addIsCredited, getBillsWithDiff } from 'modules/billings'
import { BillTableBody } from '../BillHistoric/BillTableBody'
import { EmptyBills, ErrorBills } from '../BillHistoric/EmptyBills'

const CardStyled = styled(Card)`
  thead > tr > th:first-child,
  tbody > tr > td:first-child {
    width: 7vw;
    padding: 12px 5px 12px 20px;
  }
`

export default function Bills({ bills, isLoading, hasErrors, registers, isLoadingRegisters }) {
  const billingDifferencesFlag = useSelector(selectBillingDifferencesFlag)

  const billsCredited = addIsCredited(bills, registers)
  const billsFormatted = groupBillsByMonth(billsCredited)
  const billsWithDifferences = billingDifferencesFlag
    ? getBillsWithDiff(billsFormatted)
    : billsFormatted

  return (
    <>
      {isLoading && <SpinnerCenter size={64} style={{ marginTop: '40px' }} />}
      {!isLoading && hasErrors && <ErrorBills />}
      {!isLoading && !hasErrors && (
        <>
          {isEmpty(billsWithDifferences) && <EmptyBills />}
          {!isEmpty(billsWithDifferences) && (
            <CardStyled id="Facturas" title={`Facturas: ${billsWithDifferences.length || '-'}`}>
              <div className="p-24">
                <ThemedTable>
                  <TableHead>
                    <ThemedTableRow>
                      <ThemedCellHeader align="center">MES</ThemedCellHeader>
                      <ThemedCellHeader align="center" className="billId">
                        FACTURA
                      </ThemedCellHeader>
                      <ThemedCellHeader align="center">MODO DE PAGO</ThemedCellHeader>
                      <ThemedCellHeader align="center">VENCIMIENTO</ThemedCellHeader>
                      <ThemedCellHeader align="center" className="amount-th">
                        TOTAL
                      </ThemedCellHeader>
                      <ThemedCellHeader align="center" className="credit-column" />
                      {!billingDifferencesFlag && (
                        <ThemedCellHeader align="center">ABONO</ThemedCellHeader>
                      )}
                    </ThemedTableRow>
                  </TableHead>
                  <BillTableBody
                    data={billsWithDifferences}
                    isLoadingRegisters={isLoadingRegisters}
                  />
                </ThemedTable>
              </div>
            </CardStyled>
          )}
        </>
      )}
    </>
  )
}

Bills.propTypes = {
  bills: PropTypes.array,
  isLoading: PropTypes.bool,
  hasErrors: PropTypes.bool,
  registers: PropTypes.array,
  isLoadingRegisters: PropTypes.bool,
}
