import { put, takeLatest, select } from 'redux-saga/effects'
import { coreActionTypes } from 'modules/Core/store'
import * as resetStoreSvc from 'services/reset-store'
import { push } from 'connected-react-router'

import { notifyD2DOrderCancelAction } from '../actions'
import { selectIsD2D } from '../selectors'
// TODO: separate concerns

export function* exitAppSaga() {
  const isD2D = yield select(selectIsD2D)
  if (isD2D) {
    yield put(notifyD2DOrderCancelAction())
  }

  yield put(push('/'))
  yield put({ type: resetStoreSvc.constants.STORE_RESET })
}

export function* watchExitApp() {
  yield takeLatest(coreActionTypes.EXIT_APP, exitAppSaga)
}
