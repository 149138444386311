import { useMemo } from 'react'
import PropTypes from 'prop-types'

import { useFormikContext } from 'formik'
import { Box, Grid } from '@material-ui/core'

import { ToggleCardButton } from 'components/ui'
import { useFormikSubmitted } from 'modules/Core/hooks/index'

export const DeviceTypeSelector = ({ fieldName, dataHook, options }) => {
  const { values, errors, setFieldValue, isSubmitting } = useFormikContext()
  const { isSubmitted } = useFormikSubmitted(isSubmitting)

  const value = useMemo(() => values[fieldName], [values[fieldName]])
  const error = useMemo(() => errors[fieldName], [errors[fieldName]])

  const setValue = v => {
    setFieldValue(fieldName, v)
  }

  return (
    <>
      <Grid
        container
        spacing={3}
        style={{ minHeight: '25vw', maxHeight: '350px' }}
        data-hook={dataHook}>
        {options.map(option => (
          <Grid item xs={4} key={option.value}>
            <ToggleCardButton
              label={option.label}
              icon={option.icon}
              selected={value === option.value}
              value={option.value}
              onSelected={() => setValue(option.value)}
              disabled={option.disabled}
            />
          </Grid>
        ))}
      </Grid>

      {error && isSubmitted && (
        <Box className="error-label" mt="20px">
          {error}
        </Box>
      )}
    </>
  )
}

DeviceTypeSelector.propTypes = {
  fieldName: PropTypes.string.isRequired,
  dataHook: PropTypes.string,
  options: PropTypes.array.isRequired,
}
