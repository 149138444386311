import { useState, useMemo } from 'react'
import PropTypes from 'prop-types'

import { get } from 'lodash'
import { useFormikContext } from 'formik'

import { Card, CardContent, Box, Typography, Divider, Icon } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import { ICONS } from 'utils/icons'
import { ActionAdvice } from 'components/ui'
import { formatRange, formatDateDDMMYYYY, formatFee } from 'utils'

import { PendingAndTotalFee } from 'modules/vista-360/components/PermanencyDetail/PendingAndTotalFee'
import { useDeviceFinancingConditions } from 'modules/device-renewal/hooks'
import { FinancedDeviceConditions } from '../FinancedDeviceConditions'

import { FEES_DECISION_KEEP_FINANCING } from '../../constants'

const useStyles = makeStyles(theme => ({
  'current-financed-device': {
    '& .header': {
      cursor: 'pointer',

      '& .icon': {
        marginLeft: '20px',
      },
    },

    '& .fees-info': {
      paddingBottom: '15px',

      '& hr': {
        marginLeft: '35px',
        marginRight: '35px',
        height: '90px',
      },
    },

    '& .dotted': {
      backgroundColor: 'initial',
      height: '1px',
      borderBottom: `2px dashed ${theme.palette.global.gray_medium_2}`,
    },
  },
}))

export const CurrentFinancedDeviceInfo = ({ deviceInfo }) => {
  const classes = useStyles()

  const { values } = useFormikContext()

  const [isOpen, setIsOpen] = useState(true)

  const financingData = useDeviceFinancingConditions()

  const { finalPaymentDiscountAmount, isFinalPaymentDiscountApplicable } = financingData

  const amendPenalty = useMemo(() => get(values, 'financedDeviceDecisions.amendPenalty', false), [
    values,
  ])

  const isKeepingFinancing = useMemo(
    () => get(values, 'financedDeviceDecisions.feesPaymentType') === FEES_DECISION_KEEP_FINANCING,
    [values],
  )

  const deviceInfoWithUpdatedPenalty = useMemo(() => {
    if (deviceInfo?.penalty) {
      const penaltyWithAmend = {
        ...deviceInfo.penalty,
        pendingAfterAmend: deviceInfo.penalty.pending,
      }

      const infoWithUpdatedPenalty = { ...deviceInfo, penalty: penaltyWithAmend }

      infoWithUpdatedPenalty.penalty.pendingAfterAmend = amendPenalty
        ? 0
        : infoWithUpdatedPenalty.penalty.pending

      return infoWithUpdatedPenalty
    }
    return deviceInfo
  }, [deviceInfo, amendPenalty])

  const shouldShowFinalPaymentDivider =
    !!deviceInfoWithUpdatedPenalty?.penalty || !!deviceInfoWithUpdatedPenalty?.fee

  const cancelationTodayTotalAmount = useMemo(() => {
    let amountFromFeesAndQ25 = 0
    const finalPaymentDiscount = isFinalPaymentDiscountApplicable ? finalPaymentDiscountAmount : 0

    if (!isKeepingFinancing) {
      amountFromFeesAndQ25 = deviceInfoWithUpdatedPenalty?.cancelationToday
        ? (deviceInfoWithUpdatedPenalty.cancelationToday?.amount || 0) - finalPaymentDiscount
        : deviceInfoWithUpdatedPenalty?.finalPayment?.amount || 0
    }

    return amountFromFeesAndQ25 + (deviceInfoWithUpdatedPenalty?.penalty?.pendingAfterAmend || 0)
  }, [
    deviceInfoWithUpdatedPenalty,
    isKeepingFinancing,
    isFinalPaymentDiscountApplicable,
    finalPaymentDiscountAmount,
  ])

  const showCancelationTodayInfo = !(isKeepingFinancing && amendPenalty)

  const cancelationTodayDescription = useMemo(() => {
    const texts = []
    if (!isKeepingFinancing) {
      texts.push('Cuotas pendientes')
    }
    if (!amendPenalty) {
      texts.push('Penalización')
    }
    if (deviceInfoWithUpdatedPenalty?.finalPayment?.amount && !isKeepingFinancing) {
      texts.push('Q25')
    }

    return texts.join(' + ')
  }, [amendPenalty, isKeepingFinancing, deviceInfoWithUpdatedPenalty?.finalPayment?.amount])

  return (
    <Card style={{ width: '100%' }} className={classes['current-financed-device']}>
      <Box
        p="20px 24px 18px"
        className="header"
        display="flex"
        alignItems="center"
        onClick={() => setIsOpen(!isOpen)}>
        <Typography variant="h5" style={{ fontWeight: 'bold' }}>
          Dispositivo actual - {deviceInfoWithUpdatedPenalty.name}
        </Typography>
        <Icon className="icon">{isOpen ? ICONS.upArrow : ICONS.downArrow}</Icon>
      </Box>

      {isOpen && (
        <>
          <Divider />

          <CardContent>
            <Box display="flex" className="fees-info">
              {!!deviceInfoWithUpdatedPenalty?.penalty && (
                <PendingAndTotalFee
                  title="Penalización"
                  pendingFee={deviceInfoWithUpdatedPenalty.penalty.pendingAfterAmend}
                  totalFee={deviceInfoWithUpdatedPenalty.penalty.total}
                  feeDescription={`(${formatRange(
                    deviceInfoWithUpdatedPenalty.penalty.startDate,
                    deviceInfoWithUpdatedPenalty.penalty.endDate,
                  )})`}
                />
              )}
              {!!deviceInfoWithUpdatedPenalty?.fee && (
                <>
                  {!!deviceInfoWithUpdatedPenalty?.penalty && <Divider orientation="vertical" />}

                  <PendingAndTotalFee
                    title="Importe por amortizar"
                    pendingFee={deviceInfoWithUpdatedPenalty.fee.pending}
                    totalFee={deviceInfoWithUpdatedPenalty.fee.total}
                    feeDescription={deviceInfoWithUpdatedPenalty.fee.months}
                  />
                </>
              )}
              {!!deviceInfoWithUpdatedPenalty?.finalPayment && (
                <>
                  {shouldShowFinalPaymentDivider && <Divider orientation="vertical" />}

                  <PendingAndTotalFee
                    title="Pago final (Q25)"
                    pendingFee={deviceInfoWithUpdatedPenalty.finalPayment.amount}
                    previousFee={
                      deviceInfoWithUpdatedPenalty.finalPayment.amount <
                      deviceInfoWithUpdatedPenalty.finalPayment.total
                        ? deviceInfoWithUpdatedPenalty.finalPayment.total
                        : null
                    }
                    feeDescription={formatDateDDMMYYYY(
                      deviceInfoWithUpdatedPenalty.finalPayment.date,
                    )}
                  />
                </>
              )}
              {showCancelationTodayInfo && (
                <>
                  <Divider orientation="vertical" />
                  <PendingAndTotalFee
                    title="Si finaliza el pedido ahora"
                    pendingFee={cancelationTodayTotalAmount}
                    feeDescription={cancelationTodayDescription}
                  />
                </>
              )}
            </Box>

            {isFinalPaymentDiscountApplicable && (
              <Box mt="12px" mb="12px">
                <ActionAdvice
                  type="info"
                  message={`Descuento de ${formatFee(
                    finalPaymentDiscountAmount,
                  )} aplicado en el pago final (Q25)`}
                  width="100%"
                />
              </Box>
            )}

            <FinancedDeviceConditions
              financingConditionsInfo={financingData}
              deviceInfo={deviceInfoWithUpdatedPenalty}
            />
          </CardContent>
        </>
      )}
    </Card>
  )
}

CurrentFinancedDeviceInfo.propTypes = {
  deviceInfo: PropTypes.object,
}
