import { call, put, takeLatest, select } from 'redux-saga/effects'
import { logError, formatErrorMessage } from 'services/logging'
import { selectGescal, selectIsNeba, selectIsVula } from 'modules/Coverage/store/selectors'
import { selectInstallationsSalesFlag } from 'services/feature-flag/selectors'

import {
  fetchInstallationDirection,
  resetInstallationDirection,
  getLocationIUA,
} from 'modules/Installations'

import { selectLandlineOffer, selectIsFiberInstallationRequired } from '../selectors'

import { installationsNewClientSalesActionTypes, setBasketData } from '../actions'

export function shouldFetchInstallation(
  alreadyHasFiber,
  isNeba,
  isVula,
  isFiberInstallationRequired,
  iua,
) {
  return (
    ((isNeba && !alreadyHasFiber) || (isNeba && alreadyHasFiber && !iua) || isVula) &&
    isFiberInstallationRequired
  )
}

export function shouldFetchIUA(
  isNeba,
  currentIua,
  alreadyHasFiber,
  isNewNumber,
  operatorIsTelefonica,
) {
  return isNeba && !currentIua && alreadyHasFiber && !isNewNumber && operatorIsTelefonica
}

export function* fetchInstallationDirectionSaga() {
  const gescal = yield select(selectGescal)
  const landlineOffer = yield select(selectLandlineOffer)
  const alreadyHasFiber = landlineOffer.isPortabilityFromFtth === 'yes'
  const operatorIsTelefonica = landlineOffer.operator.id === '00001'
  const isNewNumber = landlineOffer.signUpType === 'new number'
  const isNeba = yield select(selectIsNeba)
  const isVula = yield select(selectIsVula)
  const isFiberInstallationRequired = yield select(selectIsFiberInstallationRequired)

  const hasFeatureFlagInstallation = yield select(selectInstallationsSalesFlag)

  if (hasFeatureFlagInstallation) {
    const fetchIUA = shouldFetchIUA(
      isNeba,
      landlineOffer.IUA,
      alreadyHasFiber,
      isNewNumber,
      operatorIsTelefonica,
    )
    let iua = ''
    try {
      iua = fetchIUA ? (yield call(getLocationIUA, gescal)).data.iua : landlineOffer.IUA
    } catch (e) {
      console.warn('Exception while consulting IUA', e)
      const errorPayload = {
        gescal,
        landlineOffer,
        alreadyHasFiber,
        operatorIsTelefonica,
        isNewNumber,
        isNeba,
        isVula,
        hasFeatureFlagInstallation,
      }
      yield call(logError, { e: new Error(formatErrorMessage(e)), errorPayload })
      // We catch this exception because we want to continue even when the request to IUA fails
    }

    if (iua && fetchIUA) {
      // Update the IUA in the landlineoffer so it can be informed to the checkout
      yield put(
        setBasketData({
          landlineOffer: {
            ...landlineOffer,
            IUA: iua,
          },
        }),
      )
    }

    const shouldFetch = shouldFetchInstallation(
      alreadyHasFiber,
      isNeba,
      isVula,
      isFiberInstallationRequired,
      iua,
    )
    if (shouldFetch) {
      yield put(fetchInstallationDirection())
    } else {
      // Reset the installation if we don't fetch it, in case the user goes back and forward changing data
      yield put(resetInstallationDirection())
    }
  }
}

export function* watchFetchInstallationDirectionNewClientSales() {
  yield takeLatest(
    installationsNewClientSalesActionTypes.FETCH_INSTALLATIONS,
    fetchInstallationDirectionSaga,
  )
}
