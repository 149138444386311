import { flow, flatMap, get, getOr, map } from 'lodash/fp'
import { createSelector } from 'reselect'

export const getCustomerOrdersState = state => state.customerOrders

export const getIsLoadingOrders = get('customerOrders.loading')
export const getCustomerOrders = getOr([], 'customerOrders.orders')

// It assumes that every order has at least one order_item,
// and that order_item has a data array with the ids
export const selectOrderProductIds = state =>
  flatMap(
    flow(
      // relationships.order_items.data is an array with 'id' and 'type'
      get('relationships.order_items.data'),
      map('id'),
    ),
    getCustomerOrders(state),
  )

export const selectCustomerOrderLimit = createSelector(
  getCustomerOrdersState,
  customerOrders => customerOrders?.orderLimit.data,
)

export const selectCustomerReachedOrderLimit = createSelector(
  selectCustomerOrderLimit,
  limit => limit !== null && limit <= 0,
)

export const selectCustomerOrderLimitLoading = createSelector(
  getCustomerOrdersState,
  customerOrders => customerOrders?.orderLimit.loading,
)

export const selectCustomerOrderError = createSelector(
  getCustomerOrdersState,
  customerOrders => !!customerOrders?.orderLimit.error,
)

export const selectDuoLimitLoading = createSelector(
  getCustomerOrdersState,
  customerOrders => customerOrders?.duoLimit.loading,
)

export const selectBusyDuoLines = createSelector(
  getCustomerOrdersState,
  customerOrders => customerOrders?.duoLimit.data?.busyLines || 0,
)

export const selectContractId = createSelector(
  getCustomerOrdersState,
  customerOrders => customerOrders?.duoLimit.data?.contractId,
)

export const selectDuoLimitSubscriptionId = createSelector(
  getCustomerOrdersState,
  customerOrders => customerOrders?.duoLimit.data?.subscriptionId,
)

export const selectDuoLimitHasError = createSelector(
  getCustomerOrdersState,
  customerOrders => !!customerOrders?.duoLimit.error,
)
