import { call, takeLatest, select, put, take } from 'redux-saga/effects'
import * as subscriptionsSvc from 'services/subscriptions'

import * as customerNewSvc from 'services/customer-new'
import * as productOrdersSvc from 'services/product-orders'
import * as orderOnFlySvc from 'services/order-on-fly'
import { callPoncSaga, selectErrorCode, selectErrorMsg } from 'modules/ponc'
import * as statusOrderDescSvc from 'services/order-status-desc'

import { actions } from './actions'
import { constants } from './constants'

function* onFlyStatusLoadSaga({ payload: { phoneNumber, fixedline } }) {
  try {
    // CustomerNew
    yield put(customerNewSvc.actions.findInit())
    yield take([customerNewSvc.constants.FIND_SUCCESS, customerNewSvc.constants.FIND_FAILED])
    const errorCodeCustNew = yield select(customerNewSvc.selectors.getErrorCode)

    if (errorCodeCustNew) {
      const payload = {
        error: {
          customerNewSvc: {
            code: yield select(customerNewSvc.selectors.getErrorCode),
            msg: yield select(customerNewSvc.selectors.getErrorMsg),
          },
        },
      }

      return yield put(actions.loadFailed(payload))
    }

    // Orders for product
    const filterBy = 'fixednumber'

    const errorProductOrders = yield call(
      productOrdersSvc.callProductOrdersByIdSaga,
      phoneNumber,
      filterBy,
    )

    if (errorProductOrders) {
      const payload = {
        error: {
          errorProductOrders: errorProductOrders && {
            code: yield select(productOrdersSvc.selectErrorCodeByProductId, fixedline),
            msg: yield select(productOrdersSvc.selectErrorMsgByProductId, fixedline),
          },
        },
      }
      return yield put(actions.loadFailed(payload))
    }

    // Subscripcion for product
    const errorSubscription = yield call(subscriptionsSvc.callSubscriptionByMsisdnSaga, phoneNumber)
    if (errorSubscription) {
      const payload = {
        error: {
          errorSubscription: errorSubscription && {
            code: yield select(subscriptionsSvc.getSubscriptionByMsisdnErrorCode),
            msg: yield select(subscriptionsSvc.getSubscriptionByMsisdnErrorMsg),
          },
        },
      }

      return yield put(actions.loadFailed(payload))
    }

    // Onfly
    const errorOrderOnFly = yield call(orderOnFlySvc.callSaga, phoneNumber)
    if (errorOrderOnFly) {
      const payload = {
        error: {
          errorOrderOnFly: errorOrderOnFly && {
            code: yield select(orderOnFlySvc.selectors.getErrorCode),
            msg: yield select(orderOnFlySvc.selectors.getErrorMsg),
          },
        },
      }

      return yield put(actions.loadFailed(payload))
    }

    // Ponc
    const orderOnFlyDat = yield select(orderOnFlySvc.selectors.selectOrderOnFlyChTechNeba)
    const workOrderId = orderOnFlySvc.getWorkOrderID(orderOnFlyDat)

    if (workOrderId) {
      const errorPonc = yield call(callPoncSaga, workOrderId)
      if (errorPonc) {
        const payload = {
          error: {
            errorPonc: errorPonc && {
              code: yield select(selectErrorCode),
              msg: yield select(selectErrorMsg),
            },
          },
        }

        return yield put(actions.loadFailed(payload))
      }
    }

    //  [TODO][HEC] Add these lines when the service gets operative
    if (workOrderId) {
      const fixedStatus = orderOnFlySvc.getStatus(orderOnFlyDat)
      const errorStatusOrder = yield call(statusOrderDescSvc.callSaga, 77, fixedStatus)

      if (errorStatusOrder) {
        const payload = {
          error: {
            errorStatusOrder: errorStatusOrder && {
              code: yield select(statusOrderDescSvc.getErrorCode),
              msg: yield select(statusOrderDescSvc.getErrorMsg),
            },
          },
        }

        return yield put(actions.loadFailed(payload))
      }
    }

    return yield put(actions.loadSucceeded())
  } catch (e) {
    const payload = {
      error: {
        all: {
          msg: e,
        },
      },
    }

    return yield put(actions.loadFailed(payload))
  }
}

export function* watchLoad() {
  yield takeLatest(constants.LOAD_INIT, onFlyStatusLoadSaga)
}
