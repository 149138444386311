import { axiosJWT } from 'modules/axios'
import { APP_CONFIG } from 'services/app-config'

export const endpoint = `${APP_CONFIG.care_cluster}/ms-activity-register`

export function createOfferRegister(msisdn, segment, profileId, data) {
  return axiosJWT({
    method: 'POST',
    headers: {
      'X-Segment': segment,
      'x-campaign': profileId,
    },
    url: `${endpoint}/${msisdn}`,
    data,
  })
}
