import { constants } from './constants'

const fetch = (orderId, msisdn) => ({
  type: constants.FETCH,
  payload: {
    orderId,
    msisdn,
  },
})

const fetchSuccess = (orderId, data) => ({
  type: constants.FETCH_SUCCESS,
  payload: {
    orderId,
    data,
  },
})

const fetchError = (orderId, error) => ({
  type: constants.FETCH_ERROR,
  payload: {
    orderId,
    error,
  },
})

export const actions = { fetch, fetchSuccess, fetchError }
