import PropTypes from 'prop-types'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { Box, Divider } from '@material-ui/core'
import { selectContentfulResource } from 'modules/contentful'
import { Button, ModalActions, FormSelectOutlined } from 'components/ui'
import { ModalContent } from './ModalContent'
import { FormContainer } from './FormContainer'
import MultiStep from './MultiStep'
import { formatRange } from '../helpers'

const PermanenceModal = ({ type, permanent, onConfirm, onClose }) => {
  const condonePenaltyOptions =
    useSelector(selectContentfulResource('removePenaltyReasonOptions')) || []
  const removePermanenceOptions =
    useSelector(selectContentfulResource('condonePermanenceReasonOptions')) || []

  const [reason, setReason] = useState('')
  const onSelectReason = e => setReason(e.target.value)
  const onClearReason = () => setReason('')

  const dataHookSufix = type ? `-${type}` : ''

  return (
    <ModalContent data-hook={`modal-permanence${dataHookSufix}`}>
      <p>
        Esta suscripción tiene una penalización de
        <strong> {permanent.fees.pending} € </strong>
        con una permanencia de
        <strong> {formatRange(permanent.validFor.endDateTime)}</strong>.
      </p>
      <MultiStep
        initialStep="initial"
        steps={{
          initial: ({ goToStep }) => (
            <ModalActions>
              <Button onClick={() => goToStep('condonePenalty')} data-hook="condone-penalty">
                Condonar penalización
              </Button>
              <Button onClick={() => goToStep('removePermanence')} data-hook="remove-penalty">
                Eliminar permanencia
              </Button>
            </ModalActions>
          ),
          condonePenalty: ({ goToStep }) => (
            <>
              <Box mt="30px" mb="15px">
                <Divider />
              </Box>
              <p>
                Vas a <strong>condonar la penalización</strong>. Selecciona primero el motivo.
              </p>
              <FormContainer>
                <FormSelectOutlined
                  label="Selecciona el motivo"
                  minWidth="320px"
                  value={reason}
                  options={condonePenaltyOptions.map(option => ({
                    value: option.value,
                    text: option.name,
                  }))}
                  onChange={onSelectReason}
                />
              </FormContainer>
              <ModalActions>
                {reason ? (
                  <Button
                    onClick={() => {
                      onConfirm({ penaltyCondoned: true, reason })
                      onClose()
                    }}>
                    Confirmar
                  </Button>
                ) : null}
                <Button
                  secondary
                  onClick={() => {
                    goToStep('initial')
                    onClearReason()
                  }}>
                  Atrás
                </Button>
              </ModalActions>
            </>
          ),
          removePermanence: ({ goToStep }) => (
            <>
              <Box mt="30px" mb="15px">
                <Divider />
              </Box>
              <p>
                Vas a <strong>eliminar la permanencia</strong>. Selecciona primero el motivo.
              </p>
              <FormContainer>
                <FormSelectOutlined
                  label="Selecciona el motivo"
                  minWidth="320px"
                  value={reason}
                  options={removePermanenceOptions.map(option => ({
                    value: option.value,
                    text: option.name,
                  }))}
                  onChange={onSelectReason}
                />
              </FormContainer>
              <ModalActions>
                {reason ? (
                  <Button
                    onClick={() => {
                      onConfirm({ permanenceRemoved: true, reason })
                      onClose()
                    }}>
                    Confirmar
                  </Button>
                ) : null}
                <Button
                  secondary
                  onClick={() => {
                    goToStep('initial')
                    onClearReason()
                  }}>
                  Atrás
                </Button>
              </ModalActions>
            </>
          ),
        }}
      />
    </ModalContent>
  )
}

PermanenceModal.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  permanent: PropTypes.shape({
    fees: PropTypes.object.isRequired,
    validFor: PropTypes.shape({
      endDateTime: PropTypes.string.isRequired,
    }),
    residualLength: PropTypes.number.isRequired,
  }),
  type: PropTypes.string,
}

export default PermanenceModal
