import { call, put, takeLatest, all } from 'redux-saga/effects'
import { axiosFactory } from 'modules/axios'

import { logError, formatErrorMessage } from 'services/logging'
import { fetchContentfulResourceAction } from 'modules/contentful/index'
import {
  FETCH_MEMBER_GET_MEMBER,
  fetchMemberGetMemberSuccessAction,
  fetchMemberGetMemberErrorAction,
} from '../tariffs.actions'

import { fetchMemberGetMember } from '../../services'

export function* fetchMemberGetMemberSaga({ payload }) {
  try {
    const memberGetMemberInfo = yield call(fetchMemberGetMember)
    yield all([
      put(fetchMemberGetMemberSuccessAction(memberGetMemberInfo)),
      put(
        fetchContentfulResourceAction('legalText', {
          origin: 'sales_next_steps',
          operation: 'campaign_MGM',
        }),
      ),
    ])
  } catch (e) {
    console.warn(e)
    yield call(logError, { e: new Error(formatErrorMessage(e)), payload })
    const error = axiosFactory.buildError(e)
    yield put(fetchMemberGetMemberErrorAction(error))
  }
}

export function* watchFetchMemberGetMemberSaga() {
  yield takeLatest(FETCH_MEMBER_GET_MEMBER, fetchMemberGetMemberSaga)
}
