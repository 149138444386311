import { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'

import { useSelector } from 'react-redux'
import { map, flatMap, get, filter } from 'lodash'

import {
  CircularProgress,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from '@material-ui/core'
import { ButtonLink } from 'components/ui'

import { helpers as helpers360, selectors as selectors360 } from 'services/customer-360'

import { selectCustomerInfoIsLoading } from 'modules/CustomerInfo/store/index'

function buildLinesInfo(contracts, ordersOnFly) {
  const activeLines = filter(
    flatMap(get(contracts, ['0', 'packages']), packageObj =>
      flatMap(get(packageObj, 'products'), product =>
        map(get(product, 'lines'), ({ line, stringStatus }) => ({
          id: `${line}-subscription`,
          line: `${line}`,
          stringStatus,
          productDesc: product.description,
          productId: `${product.id}`,
          type: 'subscription',
        })),
      ),
    ),
    { stringStatus: helpers360.STATUS_ACTIVE_STR },
  )

  const orders = flatMap(ordersOnFly, order => {
    const lines = filter([order.mobileNumber, order.fixedNumber])
    return map(lines, line => ({
      id: `${line}-order`,
      line: `${line}`,
      stringStatus: helpers360.STATUS_ON_FLY_STR,
      productDesc: order.tariffDescription,
      orderId: order.id,
      type: 'order',
    }))
  })
  return [...activeLines, ...orders]
}

function buildStatusOptions() {
  return [
    {
      value: helpers360.STATUS_ACTIVE_STR,
      text: 'Activas',
    },
    {
      value: helpers360.STATUS_ON_FLY_STR,
      text: 'En vuelo',
    },
  ]
}

export const FilterLines = ({ onSelectedLines }) => {
  const customerInfoIsLoading = useSelector(selectCustomerInfoIsLoading)

  const contracts = useSelector(selectors360.contracts)
  const ordersOnfly = useSelector(selectors360.orders)

  // Lines
  const linesInfo = useMemo(() => buildLinesInfo(contracts, ordersOnfly), [contracts, ordersOnfly])

  const linesOptions = useMemo(
    () =>
      map(linesInfo, lineInfo => ({
        value: lineInfo.id,
        text: `${lineInfo.line} - ${lineInfo.productDesc}`,
      })),
    [linesInfo],
  )

  const [selectedLine, setSelectedLine] = useState('')

  // Status
  const statusOptions = buildStatusOptions()

  const [selectedStatus, setSelectedStatus] = useState('')

  useEffect(() => {
    let linesInfoFiltered = linesInfo

    if (selectedLine) {
      linesInfoFiltered = filter(linesInfoFiltered, { id: selectedLine })
    }

    if (selectedStatus) {
      linesInfoFiltered = filter(linesInfoFiltered, { stringStatus: selectedStatus })
    }

    if (onSelectedLines) {
      onSelectedLines(linesInfoFiltered)
    }
  }, [selectedLine, selectedStatus])

  function onSelectLine(ev) {
    setSelectedLine(ev.target.value)
  }

  function onSelectStatus(ev) {
    setSelectedStatus(ev.target.value)
  }

  function cleanSelections() {
    setSelectedLine('')
    setSelectedStatus('')
  }

  return (
    <Box display="flex" alignItems="center">
      <Box width="320px" mr="32px">
        <FormControl variant="outlined" style={{ width: '100%' }}>
          <InputLabel id="subreason-label">Buscar subscripción</InputLabel>
          <Select
            labelId="subreason-label"
            label="Buscar subscripción"
            value={selectedLine}
            displayEmpty
            data-hook="select-cvm-search-ele"
            onChange={onSelectLine}>
            {linesOptions.map(item => (
              <MenuItem key={item.value} value={item.value}>
                {item.text}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Box width="200px" mr="32px">
        <FormControl variant="outlined" style={{ width: '100%' }}>
          <InputLabel id="subreason-label">Filtrar por estado</InputLabel>
          <Select
            labelId="subreason-label"
            label="Filtrar por estado"
            value={selectedStatus}
            displayEmpty
            data-hook="select-cvm-search-state"
            onChange={onSelectStatus}>
            {statusOptions.map(item => (
              <MenuItem key={item.value} value={item.value}>
                {item.text}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <ButtonLink onClick={cleanSelections}>Limpiar filtros</ButtonLink>

      {customerInfoIsLoading && (
        <Box display="flex" alignItems="center" ml="auto">
          <CircularProgress size={20} />
          <Typography variant="body1" style={{ marginLeft: '10px' }}>
            Cargando
          </Typography>
        </Box>
      )}
    </Box>
  )
}

FilterLines.propTypes = {
  onSelectedLines: PropTypes.func,
}
