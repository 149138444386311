import { get } from 'lodash'
import { roundTwoDecimals } from 'utils/formatting/index'
import { getPriceWithTax } from '../../Taxes'

export const mapToBundlesWithTaxes = (bundles, tax) =>
  bundles.map(bundle => ({
    ...bundle,
    monthlyFee: get(bundle, 'price.taxFreeAmount', 0),
    monthlyFeeWithTax: roundTwoDecimals(
      getPriceWithTax({ ...bundle.price, taxIncludedAmount: null }, tax),
    ),
  }))

export const formatPackagesName = packages =>
  packages.map(p => {
    const name = get(p, 'commercialInfo.name', get(p, 'name', p.psId))
    // TODO: The name we get from the backend shound be enough, no need to map it to a custom text here,
    // if the backend returns a better commercial name we should delete this hardcoded name.
    return {
      name,
      psId: p.psId,
    }
  })

export const addMonthlyFeeWithTax = (packages, tax) =>
  packages.map(pack => {
    return {
      ...pack,
      monthlyFeeWithTax: roundTwoDecimals(
        getPriceWithTax({ ...pack.price, taxIncludedAmount: null }, tax),
      ),
    }
  })
