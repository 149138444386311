import { orderActionTypes } from '../actions'

const initialState = {
  creationStatus: {
    loading: null,
    error_code: null,
    error_message: null,
    data: {},
  },
  newOrder: {
    loading: null,
    error_code: null,
    error_message: null,
    data: {},
  },
  ordersV3: {},
  pollingTime: null,
  fetchRetries: 0,
}

export const ordersReducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case orderActionTypes.FETCH_ORDER_CREATION_STATUS: {
      return { ...state, creationStatus: { loading: true, error_code: null, error_message: null } }
    }

    case orderActionTypes.FETCH_ORDER_CREATION_STATUS_SUCCESS: {
      return {
        ...state,
        creationStatus: { ...state.creationStatus, loading: false, data: payload.status },
      }
    }

    case orderActionTypes.FETCH_ORDER_CREATION_STATUS_ERROR: {
      const {
        error: { httpCode, info },
      } = payload
      return {
        ...state,
        creationStatus: {
          loading: state.fetchRetries < 3,
          error_code: httpCode,
          error_message: info,
        },
        fetchRetries: state.fetchRetries + 1,
      }
    }

    case orderActionTypes.CREATE_NEW_ORDER: {
      return {
        ...state,
        newOrder: {
          loading: true,
          error_code: null,
          error_message: null,
          data: {},
        },
      }
    }

    case orderActionTypes.CREATE_NEW_ORDER_SUCCESS: {
      return {
        ...state,
        newOrder: {
          ...state.newOrder,
          loading: false,
          data: payload.taskId,
        },
      }
    }

    case orderActionTypes.CREATE_NEW_ORDER_ERROR: {
      const {
        error: { httpCode, info },
      } = payload
      return {
        ...state,
        newOrder: {
          data: {},
          loading: false,
          error_code: httpCode,
          error_message: info,
        },
      }
    }

    case orderActionTypes.START_POLL_ORDER: {
      return {
        ...state,
        pollingTime: Date.now(),
        fetchRetries: 0,
      }
    }
    default:
      return state
  }
}
