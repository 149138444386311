import { get, find, flow, flatMap, concat } from 'lodash/fp'
import { remove, isEmpty } from 'lodash'

import { createSelector } from 'reselect'

export const getContactMedia = get('customerContactMedia.data')
export const getContactMediaIsLoading = get('customerContactMedia.loading')
export const getMobileNumber = flow(
  getContactMedia,
  find(({ id }) => /:mobile-number$/.test(id)),
  get('attributes.medium.number'), // TODO not sure about the path, too unobvious
)

const MEDIUM_TYPE = {
  POSTAL: 'postal-address',
}

const ADDRESS_ROLE = {
  INSTALLATION: 'installation',
}

export const getFullNumber = (building, floor, door) => {
  let fullNumber = ''
  fullNumber += [building, floor, door].filter(item => !isEmpty(item)).join(' ')
  if (isEmpty(fullNumber)) {
    return `${fullNumber}. `
  }
  return `, ${fullNumber}. `
}

export const filterPostalContactMedia = contactMedia =>
  isEmpty(contactMedia)
    ? []
    : contactMedia.filter(
        media => media.attributes.medium_type === MEDIUM_TYPE.POSTAL, // telephone-number, postal-address, email-address
      )

export const getCustomerAddresses = createSelector(
  [getContactMedia],
  filterPostalContactMedia,
)

const buildAddressObject = postalAddress => {
  const {
    attributes: {
      role, // installation, primary, billing, delivery
      medium: { street, building, floor, door, city, postal_code } = {},
    } = {},
    id,
  } = postalAddress

  return {
    // Calle Cervantes, 17, 6ªA. Madrid, Madrid, 28032
    text: `${street}${getFullNumber(building, floor, door)}${city}, ${postal_code}`,
    isInstallationAddress: role === ADDRESS_ROLE.INSTALLATION,
    roles: [role],
    id,
  }
}

const mapCustomerAddresses = contactMedia => {
  const postalMedia = filterPostalContactMedia(contactMedia)
  if (isEmpty(postalMedia)) return []

  const customerAddresses = postalMedia.map(buildAddressObject)

  const installationAddress = customerAddresses.find(address => address.isInstallationAddress)
  if (isEmpty(installationAddress)) {
    return customerAddresses
  }

  const installationAddressText = installationAddress.text
  const duplicatedAddresses = remove(
    customerAddresses,
    address => !address.isInstallationAddress && address.text === installationAddressText,
  )
  installationAddress.roles = concat(
    installationAddress.roles,
    flatMap('roles')(duplicatedAddresses),
  )
  return customerAddresses
}

const getInstallationAddress = contactMedia => {
  const installationAddress = find(
    ({ attributes }) => attributes.role === ADDRESS_ROLE.INSTALLATION,
    contactMedia,
  )

  return !isEmpty(installationAddress) ? buildAddressObject(installationAddress) : { text: '-' }
}

export const selectInstallationAddress = createSelector(
  [getContactMedia],
  getInstallationAddress,
)

export const selectCustomerAddresses = createSelector(
  [getContactMedia],
  mapCustomerAddresses,
)
