import { useMemo } from 'react'
import PropTypes from 'prop-types'

import { useSelector, useDispatch } from 'react-redux'

import { Box } from '@material-ui/core'

import { Button } from 'components/ui'
import { selectDeviceRenewalAgileTv } from 'services/feature-flag/selectors'

import { selectors as customer360Selectors } from 'services/customer-360/selectors'

import { withDeviceRenewalForms } from '../../hocs/withDeviceRenewalForms'
import {
  selectRenewalSubscriptionIsMobile,
  selectCanAgentChooseRetentionChannel,
  selectCanAgentChooseCustomerLoyaltyChannel,
  selectIsRenewalTvAllowed,
} from '../../store/device-renewal.selectors'
import {
  resetDevices,
  resetFinancingConditions,
  setDeviceRenewalData,
} from '../../store/device-renewal.actions'

import { DeviceType } from './DeviceType'
import { RenewalSaveChangesStepButton } from '../../components/RenewalSaveChangesStepButton'

import {
  SALE_CHANNEL_RETENTION,
  SALE_CHANNEL_RENEWAL,
  SALE_CHANNEL_CTC,
  DEVICE_TYPE_TERMINAL,
  DEVICE_TYPE_AGILE_TV,
  DEVICE_TYPE_WITHOUT_IMEI,
  DEVICE_RENEWAL_FIELDNAMES,
} from '../../constants'

export const DeviceTypeContainer = withDeviceRenewalForms(({ step }) => {
  const dispatch = useDispatch()

  const canAgentChooseRetentionChannel = useSelector(selectCanAgentChooseRetentionChannel)
  const canAgentChooseCustomerLoyaltyChannel = useSelector(
    selectCanAgentChooseCustomerLoyaltyChannel,
  )

  const isMobileSubscription = useSelector(selectRenewalSubscriptionIsMobile)
  const isAgileTvEnabled = useSelector(selectDeviceRenewalAgileTv)
  const isAgileTvAllowed = useSelector(selectIsRenewalTvAllowed)

  const clientContactEmail = useSelector(customer360Selectors.getEmail)

  const saleOptions = useMemo(() => {
    const options = []

    if (canAgentChooseRetentionChannel) {
      options.push({
        label: 'Retención',
        value: SALE_CHANNEL_RETENTION,
      })
    }

    if (canAgentChooseCustomerLoyaltyChannel) {
      options.push({
        label: 'Fidelización',
        value: SALE_CHANNEL_RENEWAL,
      })
    }

    return [
      ...options,
      {
        label: 'Genérico',
        value: SALE_CHANNEL_CTC,
      },
    ]
  }, [canAgentChooseRetentionChannel, canAgentChooseCustomerLoyaltyChannel])

  const deviceTypeOptions = useMemo(() => {
    const options = [
      {
        label: 'Terminales',
        icon: 'phone_android',
        value: DEVICE_TYPE_TERMINAL,
      },
    ]

    if (!isMobileSubscription) {
      options.push({
        label: 'Agile TV + Apple TV',
        icon: 'ondemand_video',
        value: DEVICE_TYPE_AGILE_TV,
        disabled: !isAgileTvEnabled || !isAgileTvAllowed,
      })
    }

    options.push({
      label: 'Otros dispositivos',
      icon: 'devices',
      value: DEVICE_TYPE_WITHOUT_IMEI,
    })

    return options
  }, [isMobileSubscription])

  const onContinueSale = ({ deviceType }) => {
    if (deviceType === DEVICE_TYPE_AGILE_TV) {
      dispatch(
        setDeviceRenewalData({
          [DEVICE_RENEWAL_FIELDNAMES.SVA_AGILE_EMAIL]: clientContactEmail,
        }),
      )
    }

    dispatch(resetDevices())
    dispatch(resetFinancingConditions())
  }

  const props = {
    saleOptions,
    deviceTypeOptions,
  }

  return (
    <>
      <DeviceType {...props} />

      <Box display="flex" alignItems="center" pt="12px">
        <RenewalSaveChangesStepButton stepName={step} afterContinue={onContinueSale} />

        <Button
          margin="0 0 0 16px"
          secondary
          onClick={() => {
            window.close()
          }}
          data-hook="exit-device-renew">
          Atrás
        </Button>
      </Box>
    </>
  )
})

DeviceTypeContainer.propTypes = {
  step: PropTypes.string,
}
