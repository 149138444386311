import { FormControl, FormHelperText, InputLabel } from '@material-ui/core'
import { styled, withTheme } from '@material-ui/styles'

export const StyledFormControl = styled(FormControl)(({ minwidth }) => ({
  minWidth: minwidth || '120px',
}))

export const StyledFormHelperText = withTheme(
  styled(FormHelperText)(({ success, theme }) => ({
    color: success && theme.palette.primary.main,
  })),
)

export const StyledInputLabel = withTheme(
  styled(InputLabel)(({ backgroundcolor, success, theme }) => ({
    backgroundColor: backgroundcolor || theme.palette.global.gray_light,

    '&&&': {
      color: success && theme.palette.primary.main,
    },
  })),
)
