import { constants } from './constants'

const initialState = {
  error: null,
  loading: false,
  authToken: '',
}

export const reducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case constants.FETCH_ATC_AUTH_TOKEN:
      return {
        ...state,
        loading: true,
        error: null,
      }

    case constants.FETCH_ATC_AUTH_TOKEN_SUCCESS:
      return {
        ...state,
        loading: false,
        authToken: payload.data.authToken,
      }

    case constants.FETCH_ATC_AUTH_TOKEN_FAILED:
      return {
        ...state,
        loading: false,
        error: payload.error,
        authToken: '',
      }

    default:
      return state
  }
}
