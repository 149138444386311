import { get } from 'lodash'
import { createSelector } from 'reselect'
import { selectEnabledAlarmsCtCd } from 'services/feature-flag/selectors'

import { AlarmTypes, AlarmSubtypes } from '../alarms.constants'

export const getAlarmsState = state => get(state, 'alarms', {})

export const selectAlarmsData = createSelector(getAlarmsState, state => get(state, 'data', {}))

export const selectAlarms = createSelector(
  selectAlarmsData,
  selectEnabledAlarmsCtCd,
  (data, enabledAlarmCtCd) =>
    get(data, 'alarms', []).filter(
      ({ type, subType }) =>
        !!enabledAlarmCtCd &&
        type === get(AlarmTypes, 'OpenOrder.id') &&
        (subType !== get(AlarmSubtypes, 'ChangeTechnology.id') ||
          subType !== get(AlarmSubtypes, 'ChangeAddress.id')),
    ),
)

export const selectIsAlarmsLoading = createSelector(getAlarmsState, state => state.loading)

export const selectAlarmsError = createSelector(getAlarmsState, state => state.error)

export const selectAlarmsErrorCode = createSelector(selectAlarmsError, error =>
  get(error, 'httpCode'),
)

export const selectAlarmsErrorMessage = createSelector(selectAlarmsError, error =>
  get(error, 'info'),
)
