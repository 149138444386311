import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { getIn, useFormikContext } from 'formik'
import { selectRequiredPos } from '../../store'

export function useOnDeleteLineError({ lineType, onRemoveExtraTariff }) {
  const { values } = useFormikContext()
  const selectedTariff = getIn(values, `${lineType}.tariff`)
  const requiredPos = useSelector(selectRequiredPos)
  const [errorDelete, onSetErrorDelete] = useState(false)

  const isTariffRequired = useMemo(() => requiredPos.includes(selectedTariff.poId), [
    selectedTariff,
    requiredPos,
  ])

  function onRemove() {
    if (isTariffRequired) {
      onSetErrorDelete(true)
    } else {
      onSetErrorDelete(false)
      onRemoveExtraTariff()
    }
  }

  useEffect(() => {
    if (!isTariffRequired) {
      onSetErrorDelete(false)
    }
  }, [isTariffRequired])

  return { onRemove, errorDelete }
}
