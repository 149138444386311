import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'

const ListItemsCard = ({ children, ...props }) => (
  <Box p="5px 0" m="4px 0" {...props}>
    {children}
  </Box>
)

ListItemsCard.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ListItemsCard
