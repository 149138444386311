import { useState } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { makeStyles } from '@material-ui/styles'
import { Checkbox, Icon } from '@material-ui/core'
import { ICONS } from 'utils/icons'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  'selector-button': {
    backgroundColor: ({ selected, disabled, checkbox = false }) => {
      if (disabled) {
        return get(theme, 'palette.global.gray_light')
      }

      if (checkbox) {
        return get(theme, 'palette.brand.light')
      }

      return get(theme, selected ? 'palette.global.gray_dark' : 'palette.brand.light')
    },

    color: ({ selected, disabled, checkbox = false }) => {
      if (disabled) {
        return get(theme, 'palette.gray')
      }

      if (checkbox) {
        return get(theme, 'palette.brand.action_primary')
      }

      return get(theme, selected ? 'palette.brand.light' : 'palette.brand.action_primary')
    },

    border: ({ selected, checkbox = false }) => {
      if (checkbox) {
        return `solid 1px ${get(theme, 'palette.global.gray')}`
      }

      return `solid 1px ${get(
        theme,
        selected ? 'palette.global.gray_dark' : 'palette.global.gray',
      )}`
    },

    borderRadius: '8px',
    cursor: ({ disabled }) => (disabled ? 'not-allowed' : 'pointer'),
    fontSize: '16px',
    boxShadow: `0 2px 4px 0 ${get(theme, 'palette.global.gray')}`,
    padding: '10px 20px',
    display: 'flex',
    alignItems: 'center',
    margin: '15px 0',

    '&:not(:last-child)': {
      marginRight: '16px',
    },

    '&:focus': {
      outline: 0,
    },

    '& .icon-wrapper': {
      display: 'flex',
      alignItems: 'center',
      marginRight: '8px',
    },

    '& .btn-checkbox': {
      padding: '0 12px 0 0',
    },
  },
}))

const SelectorButton = ({ selected, disabled, checkbox, children, ...rest }) => {
  const classes = useStyles({ selected, disabled, checkbox })

  return (
    <button type="button" className={classes['selector-button']} disabled={disabled} {...rest}>
      {children}
    </button>
  )
}

SelectorButton.propTypes = {
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  checkbox: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
}

export function ButtonsSelector({
  items = [],
  isMultiSelect,
  selectedItems = [],
  onSelect,
  type,
  className,
  dataHook,
  clickToRemove,
  checkbox,
}) {
  const classes = useStyles()

  const initialState =
    (selectedItems.length &&
      items.map(item =>
        selectedItems.includes(item.value) ? { ...item, selected: true } : item,
      )) ||
    items

  const [buttonsState, setButtonsState] = useState(initialState)
  function setSelectedItem(item) {
    setButtonsState(
      buttonsState.map(i =>
        i.value === item.value
          ? { ...i, selected: clickToRemove ? !item.selected : true }
          : { ...i, selected: isMultiSelect ? i.selected : false },
      ),
    )
  }

  function removeSelectedItem(item) {
    setButtonsState(buttonsState.map(i => (i.value === item.value ? { ...i, selected: false } : i)))
  }

  function onButtonClick(item) {
    if (!clickToRemove && buttonsState.includes(item)) {
      removeSelectedItem(item)
    }
    setSelectedItem(item)
    onSelect(item.value)
  }
  return (
    <div data-hook={dataHook} className={`${className} ${classes.container}`}>
      {items.map((item, index) => {
        return (
          <SelectorButton
            data-hook={get(item, 'dataHook')}
            disabled={item.disabled}
            selected={buttonsState[index].selected}
            value={item.value}
            key={`button_selector_${item.value}${index + 1}`}
            onClick={() => onButtonClick(item)}
            type={type}
            checkbox={checkbox}>
            {checkbox && (
              <Checkbox className="btn-checkbox" checked={item.selected} color="primary" />
            )}
            {item.icons && (
              <div className="icon-wrapper">
                {get(item, 'icons', []).map((icon, i) => (
                  <Icon
                    key={`button_icon_${item.value}${i + 1}`}
                    selected={buttonsState[index].selected}
                    className="icon">
                    {ICONS[icon]}
                  </Icon>
                ))}
              </div>
            )}
            {item.label}
          </SelectorButton>
        )
      })}
    </div>
  )
}

ButtonsSelector.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      selected: PropTypes.bool,
      disabled: PropTypes.bool,
    }),
  ).isRequired,
  onSelect: PropTypes.func.isRequired,
  isMultiSelect: PropTypes.bool,
  type: PropTypes.string,
  className: PropTypes.string,
  dataHook: PropTypes.string,
  selectedItems: PropTypes.array,
  clickToRemove: PropTypes.bool,
  checkbox: PropTypes.bool,
}

ButtonsSelector.defaultProps = {
  isMultiSelect: false,
  type: 'button',
}
