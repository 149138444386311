import { createStructuredSelector, createSelector } from 'reselect'
import {
  selectIsSubscriptionQueryParam,
  selectIsMobileOnly,
  selectIsMultiSimQueryParam,
} from 'modules/Router/store/index'

import { selectSaleTax, selectSelectedAdditionalLines } from '../../../store'

const selectAddditionalLinesIndex = createSelector(
  selectSelectedAdditionalLines,
  additionalLines => additionalLines.map(line => line.index),
)

export const storeSelectors = createStructuredSelector({
  tax: selectSaleTax,
  isMobileOnly: selectIsMobileOnly,
  additionalLinesIndex: selectAddditionalLinesIndex,
  isSubscription: selectIsSubscriptionQueryParam,
  isMultiSim: selectIsMultiSimQueryParam,
})
