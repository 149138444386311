import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { head, isNil } from 'lodash'

import { getIdentificationId, getMainSubscriptionTariffId } from 'services/customer-360/selectors'
import { actions } from 'services/customer-360'
import { getActiveBundles } from 'services/subscriptions'
import { selectProfileId } from 'modules/Permissions'
import { selectTariffSegment } from 'modules/tariffs/store-apigee'
import { getSegmentCustomer } from 'modules/orders/store/orders.selectors'
import { selectQueryParams } from 'modules/Router'
import { NEWTON } from 'services/global-constants/channels'
import { getTelesalesUrl } from '../helpers'

export function useCrossSubscription(status) {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()

  let segment
  const queryParams = useSelector(selectQueryParams)
  const customerId = useSelector(getIdentificationId)
  const agentProfile = useSelector(selectProfileId)
  const mainSubscriptionId = useSelector(getMainSubscriptionTariffId)

  if (!isNil(mainSubscriptionId)) {
    segment = useSelector(selectTariffSegment(mainSubscriptionId))
  } else {
    segment = useSelector(getSegmentCustomer)
  }

  function navigateToCrossSubscription(sub, offerDetail = []) {
    const order = offerDetail.find(detail => head(detail.subscriptions)?.id === sub?.id)
    const orderId = order?.order_info?.order_id
    const tariffId = sub?.tariff?.id
    const activeBundles = getActiveBundles(sub) || []

    let url
    if (queryParams.channel === NEWTON) {
      url = getTelesalesUrl(
        customerId,
        'isCross',
        location,
        '/add-client/sales/info',
        agentProfile,
        segment,
      ).finalUrl
    } else {
      url = getTelesalesUrl(customerId, 'isCross', location, '/add-client/sales/info').finalUrl
    }

    dispatch(
      actions.setSubscriptionAction({
        id: status === 'active' ? sub?.subscription_id : orderId,
        status,
        tariffId: status === 'active' ? sub?.type : tariffId,
        productNumber: sub.productNumber,
        bundles: activeBundles,
      }),
    )

    history.push(url)
  }

  return { navigateToCrossSubscription }
}
