import PropTypes from 'prop-types'
import { get } from 'lodash'
import styled, { keyframes } from 'styled-components'

const DEFAULT_POINTS_NUMBER = 8
const DEFAULT_ANIMATION_TIME = 1000 // in milliseconds
const pointAnimation = keyframes`
  0% {
    transform: scale(0.33);
  }
  30% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.33);
  }
`

const SpinnerPoint = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  padding: calc((${({ pointWidth }) => pointWidth} * 0.23) * 0.5);
  box-sizing: border-box;
  transform: rotateZ(${({ index, pointsNumber }) => `${(360 / pointsNumber) * index}deg`});

  &:after {
    content: '';
    width: calc(
      ${({ pointWidth }) => pointWidth} * 0.23 * (8 / ${({ pointsNumber }) => pointsNumber})
    );
    height: calc(
      ${({ pointWidth }) => pointWidth} * 0.23 * (8 / ${({ pointsNumber }) => pointsNumber})
    );
    position: absolute;
    border-radius: 100%;
    background-color: ${props =>
      get(props, 'background-color') || get(props, 'theme.palette.brand.action_primary')};
    transform: scale(0.33);
    animation-name: ${pointAnimation};
    animation-duration: ${DEFAULT_ANIMATION_TIME}ms;
    animation-iteration-count: infinite;
    animation-delay: ${({ index, pointsNumber }) =>
      `${(DEFAULT_ANIMATION_TIME / pointsNumber) * index}ms`};
  }
`
const SpinnerWrapper = ({ className, width, pointsNumber, dotProps }) => {
  const Points = Array(pointsNumber)
    .fill(null)
    .map((_, i) => {
      // TODO maybe lodash uniqueId ?
      const key = Number(
        Math.random()
          .toString()
          .split('.')[1],
      ).toString(32)
      return (
        <SpinnerPoint
          key={`spinner-point_${key}`}
          index={i + 1}
          pointWidth={width}
          pointsNumber={pointsNumber}
          {...dotProps}
        />
      )
    })

  return <div className={className}>{Points}</div>
}

SpinnerWrapper.propTypes = {
  className: PropTypes.string,
  pointsNumber: PropTypes.number,
  width: PropTypes.string,
  dotProps: PropTypes.shape({
    'background-color': PropTypes.string,
  }),
}

const DotsCircularSpinner = styled(SpinnerWrapper)`
  position: relative;
  width: ${({ width }) => width};
  height: ${({ width }) => width};
`

DotsCircularSpinner.propTypes = {
  height: PropTypes.string,
  ...SpinnerWrapper.propTypes,
}

DotsCircularSpinner.defaultProps = {
  pointsNumber: DEFAULT_POINTS_NUMBER,
  width: '92px',
}

export default DotsCircularSpinner
