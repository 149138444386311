import { FUSION_TELESALES, FUSION_TELESALES_PRO } from 'services/global-constants'
import { PRO, RESIDENTIAL } from 'modules/SharedSales/constants/productCategories'

export const formatAddAgileTvValidationError = error => {
  let message = 'Error desconocido al comprobar elegibilidad.'

  if (error?.indexOf('TV Service already exist for Customer') > -1) {
    message = 'El cliente ya dispone de una suscripción Agile TV activa.'
  }

  if (error?.indexOf('TV Service request ongoing for Customer') > -1) {
    message = 'Ya existe una petición en curso de servicio de TV para el cliente.'
  }

  return message
}

export const getChannelFromTariffSegment = segment =>
  segment === PRO ? FUSION_TELESALES_PRO : FUSION_TELESALES

export const getSegmentFromCustomerSegment = customerSegment =>
  customerSegment.includes(PRO.toUpperCase()) ? PRO : RESIDENTIAL
