import { constants } from './constants'

const loadInit = payload => ({
  type: constants.LOAD_INIT,
  payload,
})

const loadSucceeded = () => ({
  type: constants.LOAD_SUCCEEDED,
  payload: {},
})

const loadFailed = error => ({
  type: constants.LOAD_FAILED,
  payload: error,
})

export const actions = {
  loadInit,
  loadSucceeded,
  loadFailed,
}
