function addHistoryState() {
  window.history.pushState({ origin: 'sales' }, document.title, window.location.href)
}

function onUnexpectedBackwards(e) {
  if (e.state) {
    addHistoryState()
    e.preventDefault()
    e.returnValue = true

    alert('No se permite navegar. Utiliza los botones de navegación de la propia aplicación')
  }
}

export const usePreventBackwards = () => {
  const addStopper = () => {
    addHistoryState()
    window.addEventListener('popstate', onUnexpectedBackwards)
  }
  const removeStopper = () => {
    window.removeEventListener('popstate', onUnexpectedBackwards)
  }

  return {
    addPreventBackwards: addStopper,
    removePreventBackwards: removeStopper,
  }
}
