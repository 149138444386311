import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import { TextStyles } from 'utils/text'

import { Badge } from 'modules/ui'
import { isEmpty } from 'lodash'
import { formatAddressStr } from '../../helpers/formats.helper'

// import { PopoverInfo } from '../PopoverInfo'
// import SubscriptionStatusBadge from '../../../../components/packages/ui/subscription-status-badge/SubscriptionStatusBadge'

const iconSvg = {
  fixed: '/assets/fixed_phone.svg',
  mobile: '/assets/sim_filled.svg',
  internet: '/assets/fixed_internet_filled.svg',
}

export function InternetDetail({ internet }) {
  return !isEmpty(internet) ? (
    <>
      <div className="p-x-24 p-y-8" data-hook="internet-detail">
        {internet.map(internetItem => (
          <div key={internetItem.id} className="flex p-x-24 align-start-center">
            <img src={iconSvg.internet} alt="internet" />
            <Typography
              {...TextStyles.subtitle2Dark({
                className: 'p-x-24',
              })}>
              Internet
            </Typography>
            {!isEmpty(internetItem.state_desc) && (
              <>
                {/* <SubscriptionStatusBadge status={internetItem.state_desc} variant="small" /> */}
                {/* <PopoverInfo info={internetItem.state_desc} /> */}
              </>
            )}

            {!isEmpty(internetItem.download_speed) && (
              <Badge
                lg
                value={{
                  label: 'Tecnología',
                  value: `${
                    internetItem.tech_type === 'fiber' || internetItem.tech_type === 'FTTH'
                      ? `Fibra`
                      : `ADSL`
                  } ${internetItem.download_speed}B`,
                }}
              />
            )}

            {!isEmpty(internetItem.installation_address) && (
              <Badge
                lg
                value={{
                  label: 'Dirección de instalación',
                  value: formatAddressStr(internetItem.installation_address),
                }}
              />
            )}
          </div>
        ))}
      </div>
    </>
  ) : (
    ''
  )
}

InternetDetail.propTypes = {
  internet: PropTypes.arrayOf(PropTypes.object),
}
