import PropTypes from 'prop-types'

import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import { TextStyles } from 'utils/text'

const useStyles = makeStyles(theme => ({
  'text-title': {
    whiteSpace: 'nowrap',
    color: theme.palette.brand.medium,
  },
}))

export const TextTitle = ({ children }) => {
  const classes = useStyles()

  return (
    <Typography
      {...TextStyles.subtitle2Link({
        className: `${classes['text-title']} txt-title p-y-8 p-x-24`,
      })}>
      {children}&nbsp;
    </Typography>
  )
}

TextTitle.propTypes = {
  children: PropTypes.node.isRequired,
}
