import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { Typography } from '@material-ui/core'
import { TextStyles } from 'utils/text'

import { selectors as customer360SvcSelectors } from 'services/customer-360'

import { Header } from 'modules/Header'

function HeaderCustomerContainer({ customerName, customerNIF }) {
  const title = (
    <div>
      <Typography {...TextStyles.header()}>{customerName}</Typography>
      <Typography {...TextStyles.action()}>{customerNIF}</Typography>
    </div>
  )
  return <Header icon="menu" iconText="menu" title={title} />
}

HeaderCustomerContainer.propTypes = {
  customerName: PropTypes.string,
  customerNIF: PropTypes.string,
}

const enhance = connect(state => {
  const customerId = customer360SvcSelectors.getIdentification(state)
  return {
    customerName: customer360SvcSelectors.getFullName(state),
    customerNIF: customerId ? customerId.identificationId : '',
  }
})

export default enhance(HeaderCustomerContainer)
