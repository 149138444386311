import { useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Icon } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { ICONS } from 'utils/icons'

const useStyles = makeStyles(theme => ({
  layout: {
    zIndex: 0,

    '& .sidebar-wrapper': {
      '&.opened': {
        '@media (max-width: 1260px)': {
          backgroundColor: '#3e3c3c94',
          width: '100%',
          position: 'fixed',
          top: 0,
          left: 0,
          zIndex: 199,
          height: '100vh',
        },
      },
    },

    '& .sidebar': {
      overflowY: 'auto',

      '@media (max-width: 1260px)': {
        width: '48px',

        '&.opened': {
          width: '312px',
          position: 'fixed',
          right: 0,
          zIndex: 200,
          height: '100vh',
        },
      },

      '@media (min-width: 1261px)': {
        width: '312px',
      },
    },

    '& .sidebar-wrapper-fixed': {
      width: '312px',
    },

    '& .sidebar-wrapper-fixed, & .sidebar': {
      height: 'calc(100vh - 64px)',
      flexBasis: 'auto',
      backgroundColor: ({ backgroundcolor }) => (backgroundcolor ? '#blue' : '#e4e6e7'),

      '&:after': {
        content: '',
        position: 'absolute',
        top: 0,
        left: '80%',
        right: 0,
        bottom: 0,
        background: '#e4e6e7',
        zIndex: -1,
      },

      '& > section': {
        boxShadow: 'none',
      },
    },

    '& .sidebar-content': {
      backgroundColor: '#2b67bb',
      minHeight: '100%',

      '@media (max-width: 1260px)': {
        display: 'none',

        '&.opened': {
          display: 'block',
        },
      },
    },

    '& .button-wrapper': {
      display: 'flex',
      padding: '8px',

      '&.opened': {
        justifyContent: 'flex-end',
        position: 'absolute',
        right: 0,
      },

      '@media (min-width: 1261px)': {
        display: 'none',
      },

      '& .button': {
        width: '25px',
        height: '25px',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
      },
    },

    '& > section': {
      backgroundColor: theme.palette.global.gray_light,
    },
  },
}))

export const LayoutComponent = ({
  className,
  backgroundColor,
  headerContent,
  headerHeight,
  width,
  fullWidth,
  id,
  mainContent,
  sidebarContent,
  sideBarFixed = false,
}) => {
  const classes = useStyles({ backgroundColor })

  const [opened, setOpened] = useState(false)

  const sidebarFloating = (
    <div className={opened ? 'sidebar-wrapper opened' : 'sidebar-wrapper'}>
      <div className={opened ? 'sidebar opened' : 'sidebar'}>
        {' '}
        <div className={opened ? 'button-wrapper opened' : 'button-wrapper'}>
          <a
            href="#"
            className="button"
            onClick={e => {
              e.preventDefault()
              setOpened(!opened)
            }}>
            <Icon color="primary" className="icon" size="20px">
              {opened ? ICONS.close : ICONS.cart}
            </Icon>
          </a>
        </div>
        <div className={opened ? 'sidebar-content opened' : 'sidebar-content'}>
          {sidebarContent}
        </div>
      </div>
    </div>
  )

  const sidebarFixedComp = <div className="sidebar-wrapper-fixed">{sidebarContent}</div>

  const sidebar = sideBarFixed ? sidebarFixedComp : sidebarFloating

  return (
    <Box
      display="flex"
      height="100vh"
      flexDirection="column"
      position="relative"
      className={[classes.layout, ...(className ? [className] : [])].join(' ')}>
      {headerContent || null}
      <Box
        component="section"
        display="flex"
        flexWrap="nowrap"
        height={`calc(100% - ${headerHeight || '64px'})`}
        maxWidth={width || '1260px'}
        margin={fullWidth ? 'inherit' : '0 auto'}
        lineHeight="normal">
        <Box flex={1} style={{ overflowY: 'auto' }} id={id}>
          {mainContent}
        </Box>
        {sidebarContent ? sidebar : null}
      </Box>
    </Box>
  )
}

LayoutComponent.propTypes = {
  headerContent: PropTypes.node,
  className: PropTypes.string,
  headerHeight: PropTypes.string,
  mainContent: PropTypes.node.isRequired,
  sidebarContent: PropTypes.node,
  width: PropTypes.string,
  id: PropTypes.string,
  fullWidth: PropTypes.bool,
  sideBarFixed: PropTypes.bool,
  backgroundColor: PropTypes.bool,
}
