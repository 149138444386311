import PropTypes from 'prop-types'
import styled from 'styled-components'
import { get } from 'lodash'
import { ButtonBase, Icon } from '@material-ui/core'
import { ICONS } from 'utils/icons'

const OfferRotateStyled = styled(ButtonBase)`
  outline: none;
  border: none;
  width: 144px;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  color: white;
  background: ${props => get(props, 'theme.palette.brand.medium')};
  border-radius: 12px 0 0 12px;
  line-height: 24px;
  padding: 0;
  cursor: pointer;
  .icon {
    margin: -2px 0 0 2px;
    font-size: 22px;
    vertical-align: middle;
  }
`

const OfferRotateAction = props => {
  const { text, onRotate } = props

  return (
    <OfferRotateStyled className="rotate-action" onClick={onRotate}>
      {text}
      <Icon className="icon">{ICONS.generalView}</Icon>
    </OfferRotateStyled>
  )
}

OfferRotateAction.propTypes = {
  text: PropTypes.string.isRequired,
  onRotate: PropTypes.func,
}

export default OfferRotateAction
