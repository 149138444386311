const GET_ROVE_INIT = 'OVID/SESSION/GET_ROVE_INIT'
const GET_ROVE = 'OVID/SESSION/GET_ROVE'
const GOT_ROVE = 'OVID/SESSION/GOT_ROVE'
const GET_ROVE_FAILED = 'OVID/SESSION/GET_ROVE_FAILED'

export const constants = {
  GET_ROVE_INIT,
  GET_ROVE,
  GOT_ROVE,
  GET_ROVE_FAILED,
}
