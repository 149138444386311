import { axiosJWT } from 'modules/axios'
import { APP_CONFIG } from 'services/app-config'

export function customerWrite(customerId, data) {
  return axiosJWT({
    method: 'POST',
    url: `${APP_CONFIG.care_cluster}/customers-write/customer/customer-update`,
    data: {
      data: {
        type: 'customers-update',
        attributes: {
          'id-number': customerId,
          ...data,
        },
      },
    },
  })
}
