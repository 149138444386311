import { call, put, takeLatest, select } from 'redux-saga/effects'
import { logError, formatErrorMessage } from 'services/logging'
import { axiosFactory } from 'modules/axios'
import { setNewPostalAddressCartera, fetchNewIbanValueCartera } from 'modules/CustomerInfo/services'
import {
  setNewPostalAddressCarteraSuccess,
  setNewPostalAddressCarteraError,
  customerInfoActionTypes,
  fetchNewIbanValueCarteraSuccess,
  fetchNewIbanValueCarteraError,
  modifyCustomerContactMedia,
} from 'modules/CustomerInfo'

import { basketActionTypes } from '../actions/basket.actions'

import {
  selectNewPostalAddresss,
  selectIBAN,
  selectBillingAccountId,
  selectAccountId,
  selectPhoneContact2,
  selectPhoneContact,
  selectEmail,
  selectIsCompany,
  selectBillingCarteraAddress,
} from '../selectors'

import {
  objectHasEmptyValue,
  mapToPostalAddressUpdate,
} from '../selectors/NewClientSales.selectors.helpers'

export function* fetchNewIbanValueCarteraSaga() {
  try {
    const bankAccount = yield select(selectIBAN)
    if (!bankAccount) return
    const billingAccountId = yield select(selectBillingAccountId)
    const newBillingAccountId = yield call(fetchNewIbanValueCartera, bankAccount, billingAccountId)
    yield put(fetchNewIbanValueCarteraSuccess(newBillingAccountId))
  } catch (e) {
    console.warn(e)
    yield call(logError, new Error(formatErrorMessage(e)))
    const error = axiosFactory.buildError(e)
    yield put(fetchNewIbanValueCarteraError(error))
  }
}

export function* setNewAccountValuesCarteraSaga({ payload }) {
  const telephonePrimary = yield select(selectPhoneContact)
  const telephoneHome = yield select(selectPhoneContact2)
  const email = yield select(selectEmail)

  let data = {}
  if (payload?.type === 'summary') {
    data = { email, telephoneHome }
  } else {
    data = { telephonePrimary }
  }

  if (objectHasEmptyValue(data)) {
    return
  }

  const isCompany = yield select(selectIsCompany)
  const accountId = yield select(selectAccountId)

  yield put(modifyCustomerContactMedia(accountId, isCompany ? 'pro' : 'residential', data))
}

function getIsValidAddress(address) {
  return (
    !!(address.zipCode || address.postal_code) &&
    !!(address.city || address.town) &&
    !!(address.province || address.state_or_province) &&
    !!(address.building || address.number)
  )
}
export function* setNewPostalAddressCarteraSaga() {
  try {
    const isCompany = yield select(selectIsCompany)
    const params = { segment: isCompany ? 'pro' : 'residential' }
    const accountId = yield select(selectAccountId)
    const postalAddress = yield select(selectNewPostalAddresss)
    const carteraAddress = yield select(selectBillingCarteraAddress)
    const formattedCarteraAddress = mapToPostalAddressUpdate({ medium: carteraAddress })
    const isValidPostalAddress = getIsValidAddress(postalAddress)

    const isValidCarteraAddress = getIsValidAddress(formattedCarteraAddress)
    if (isValidCarteraAddress || isValidPostalAddress) {
      const addressToUpdate = isValidPostalAddress ? postalAddress : formattedCarteraAddress
      const newPostalAddress = yield call(
        setNewPostalAddressCartera,
        addressToUpdate,
        accountId,
        params,
      )
      yield put(setNewPostalAddressCarteraSuccess(newPostalAddress))
    }
  } catch (e) {
    console.warn(e)
    yield call(logError, new Error(formatErrorMessage(e)))
    const error = axiosFactory.buildError(e)
    yield put(setNewPostalAddressCarteraError(error))
  }
}

export function* watchSetNewPostalAddressCarteraSaga() {
  yield takeLatest(
    customerInfoActionTypes.SET_NEW_POSTAL_ADDRESS_CARTERA,
    setNewPostalAddressCarteraSaga,
  )
}

export function* watchFetchNewIbanValueCarteraSaga() {
  yield takeLatest(customerInfoActionTypes.SET_NEW_IBAN_VALUE_CARTERA, fetchNewIbanValueCarteraSaga)
}

export function* watchSetNewBasketAccountValuesCarteraSaga() {
  yield takeLatest(
    basketActionTypes.SET_NEW_BASKET_ACCOUNT_VALUES_CARTERA,
    setNewAccountValuesCarteraSaga,
  )
}
