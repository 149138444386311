import { createStructuredSelector } from 'reselect'

import {
  selectFetchAddAgileTvAllowedIsLoading,
  selectFetchAddAgileTvAllowedError,
  selectFetchAddAgileTvAllowedIsSuccess,
} from 'modules/agile-tv/add-agiletv/store/add-agiletv.selectors'

import {
  selectIsSVAAgileListLoading,
  selectSVAAgileListFormated,
  selectSVAAgileList,
  selectSVAAgileAppleTv,
  selectSVAAgileStandard,
} from '../../store'

export const SVAsSelector = createStructuredSelector({
  isLoadingAllowed: selectFetchAddAgileTvAllowedIsLoading,
  allowedError: selectFetchAddAgileTvAllowedError,
  isAllowed: selectFetchAddAgileTvAllowedIsSuccess,
  isLoading: selectIsSVAAgileListLoading,
  formatedSVAS: selectSVAAgileListFormated,
  standardStbSVA: selectSVAAgileStandard,
  agileTvSVA: selectSVAAgileAppleTv,
  SVAList: selectSVAAgileList,
})
