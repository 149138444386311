import { call, put, select, takeEvery } from 'redux-saga/effects'
import { logError, formatErrorMessage } from 'services/logging'
import { axiosFactory } from 'modules/axios'
import { selectAccountId } from 'modules/Auth'

import { actions } from './actions'
import { constants } from './constants'
import { findAllSubscriptions } from './api'

/**
 * Executes backend call to retrieve customer's
 * active subscriptions. It dispatches findAllSubscriptionsSuccess /
 * findAllSubscriptionsError depending on the execution success.
 */
export function* findAllSubscriptionsSaga() {
  try {
    const accountId = yield select(selectAccountId)
    const subscriptions = yield call(findAllSubscriptions, accountId)

    yield put(actions.findAllSubscriptionsSuccess(subscriptions))
  } catch (e) {
    yield call(logError, new Error(formatErrorMessage(e)))
    const error = axiosFactory.buildError(e)
    yield put(actions.findAllSubscriptionsError({ error }))
  }
}

export function* watchFindAllSubscriptions() {
  yield takeEvery(constants.FIND_ALL_SUBSCRIPTIONS, findAllSubscriptionsSaga)
}
