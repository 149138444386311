import { createSelector } from 'reselect'

const selectLegalTexts = state => state.legalTexts

export const selectLegalTextsByOrigin = origin =>
  createSelector(
    selectLegalTexts,
    legalTexts => legalTexts[origin],
  )

export const selectLegalTextsById = (origin, textId) =>
  createSelector(
    selectLegalTexts,
    legalTexts => ({
      isLoading: false,
      legalTexts: legalTexts[origin].legalTexts[textId],
    }),
  )
