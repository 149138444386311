import { chain } from 'lodash'

const getIdentificationTypeByCustomer = customer => {
  return chain(customer)
    .get('individualIdentification')
    .filter('identificationId')
    .get('[0].type')
    .value()
}

export const helpers = { getIdentificationTypeByCustomer }
