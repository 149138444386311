export const READY_FOR_BILLING_ORDER_STATUS = ['6', '7']
export const ACTIVE_SUBSCRIPTION_STATUS = [4, 8]
export const UNSUBSCRIBED_MOBILE_LINE_2P_STATUS = [17]

export const INVALID_DISCOUNTS_SUFFIX = 'FAT'

export const CVM_FIXED_PENALTY_KEY = 'fixed'
export const CVM_MOBILE_PENALTY_KEY = 'mobile'

export const TARIFF_TYPE_EXTRA_LINE = 'extra_line'
export const TARIFF_TYPE_MAIN_LINE = 'main_line'

export const ADDITIONAL_LINE_DISCOUNT_CATEGORIES = ['additional_line', 'extra_line']

export const DUO_TARIFF_ID = 'CONT28'

export const DUO_TARIFFS_IDS = [DUO_TARIFF_ID, 'CONT29']

export const SINFIN_REGEX = /sinf[ií]n/i
export const TARIFF_1GB_REGEX = /^CONTFHD/

export const SINFIN_MOBILE_TARIFF_IDS = ['CONT999GR', 'CONT999GP']

export const NETWORK_ISSUE_DISCOUNT = 'LRDISCPS'

export const MOBILE_INFINITE_GIGAS_DISCOUNTS = ['RETDISCMOB_CONT999GR', 'RETDISCMOB_CONT999GP']

// DESCUENTOS RETENCION - CONVERGENTE
export const PRO_CONVERGENT_DISCOUNT_50_PERCENT = 'PRORETCONV50'
export const PRO_CONVERGENT_DISCOUNT_100_PERCENT = 'PRORETCONV100'

// DESCUENTOS PRO-SOHO
export const PRORETMOV50 = 'PRORETMOV50'
export const PRORETMOV100 = 'PRORETMOV100'
export const CONT_DISC_TB_20 = 'CONT_DISC_TB_20'
export const RETDISCMOB_CONT999GP = 'RETDISCMOB_CONT999GP'
export const CONV999DISCR01 = 'CONV999DISCR01'
export const CONV999DISCP01 = 'CONV999DISCP01'
export const CONVDISC_CS10F12 = 'CONVDISC_CS10F12'
export const CONVDISC_CS10M12 = 'CONVDISC_CS10M12'
export const CONVDISC_CS20F12 = 'CONVDISC_CS20F12'
export const CONVDISC_CS20M12 = 'CONVDISC_CS20M12'
export const CONVDISC_CS30F12 = 'CONVDISC_CS30F12'
export const CONVDISC_CS30M12 = 'CONVDISC_CS30M12'
export const CONVDISC_CS100_6 = 'CONVDISC_CS100_6'
export const DISCAGILETV03 = 'DISCAGILETV03'
export const DISCAGILETV06 = 'DISCAGILETV06'
export const MOB_DISC_TB_PRO = 'MOB_DISC_TB_PRO'
export const FH600DISC003 = 'FH600DISC003'
export const FH600DISC004 = 'FH600DISC004'
export const FH600DISC001 = 'FH600DISC001'
export const FH600DISC002 = 'FH600DISC002'
export const FHD300DISCR001 = 'FHD300DISCR001'
export const FHD300DISCP001 = 'FHD300DISCP001'
export const RETFH600DISCP01 = 'RETFH600DISCP01'
export const RETFH600DISCP02 = 'RETFH600DISCP02'
export const MSEDEDISC = 'MSEDEDISC'
export const CONVDISC = 'CONVDISC'
export const COL08DISCP = 'COL08DISCP'
export const CONVDISC_CS50_3 = 'CONVDISC_CS50_3'

// NUEVO DESCUENTO SEGUNDA RESIDENCIA
export const CVM_NEW_PORTFOLIO_2R_DISCOUNT = 'FH2RESIDISC'

export const CVM_NEW_PORTFOLIO_300MB_PR0_2P_DISCOUNT = 'CONTFHDISCP001'
export const CVM_NEW_PORTFOLIO_300MB_2P_DISCOUNT = 'CONTFHDISCR001'

export const CVM_FIBRA_300MB_2P_DISCOUNT = 'RETFHC2RESIDISC03'

const PRO_SOHO_CONVERGENT_DISCOUNTS = [
  CONT_DISC_TB_20,
  CONV999DISCR01,
  CONV999DISCP01,
  CONVDISC_CS100_6,
  DISCAGILETV03,
  DISCAGILETV06,
  MSEDEDISC,
  CONVDISC,
  COL08DISCP,
  CONVDISC_CS10F12,
  CONVDISC_CS10M12,
  CONVDISC_CS20F12,
  CONVDISC_CS20M12,
  CONVDISC_CS30F12,
  CONVDISC_CS30M12,
  FHD300DISCR001,
  FHD300DISCP001,
  RETFH600DISCP01,
  RETFH600DISCP02,
  CVM_NEW_PORTFOLIO_300MB_PR0_2P_DISCOUNT,
]

export const PRO_SOHO_MOBILE_DISCOUNTS = [
  PRORETMOV50,
  PRORETMOV100,
  RETDISCMOB_CONT999GP,
  MOB_DISC_TB_PRO,
]

export const CVM_DISCOUNT_AGILE_TV = 'DISCAGILETV03'

export const BONUS_TYPE_CODE = 'BONUS'
export const ENERGY_GO_DISC = 'ENERGYGODISC'
export const SVA_AGILETV_CODE = 'AGILETVPLUS'
export const SVA_HEBE_CODE = 'HEBESUBSVA'
export const SVA_HEBE_DISCOUNT_CODE = 'HEBESUBSVADISC'
export const SVA_SMARTHOME_CODE = 'SMARTHOME'
export const SVA_HOMEGO_CODE = 'HOMEGO'
export const SVA_DOCTORGO_CODE = 'DOCTORGO'
export const SVA_DOCTORGO_CUSTOMER_CODE = 'DOCTORGOCUSTSVA'
export const SVA_HEBE_CUSTOMER_CODE = 'HEBECUSTSVA'
export const SVA_AGILE_TV_PREMIUM = 'AGILETV_0_PREMIUM'
export const SVA_WIFI_TOTAL_01 = 'WIFITOTALSVA01'
export const SVA_WIFI_TOTAL_02 = 'WIFITOTALSVA02'
export const EXTRA_DUO_DISCOUNT_ID = 'CONT28DISC_EXTRA'
export const MAIN_DUO_DISCOUNT_ID = 'CONT28DISC'
export const MAIN_DUO_DISCOUNT_NEW_AMOUNT = 9.09
export const MAIN_DUO_DISCOUNT_OLD_AMOUNT = 11.57
export const SVA_FHD_300_CODE_RESIDENTIAL = FHD300DISCR001
export const SVA_FHD_300_CODE_PRO = FHD300DISCP001
export const CVM_RETENTION_1GB_3P_DISCOUNTS = ['RETFHD010DISCR', 'RETFHD005DISCR']
export const DISC2POR1 = 'MOB2X1DISC100_12'
export const DISC_PERM = 'MOB2X1DISC00_24'
export const MOBILE_2_X_1_DISCOUNT = [DISC_PERM, DISC2POR1]
export const AMAZON_DISCOUNT = 'DISCAMAZON'

export const SVA_AGILE_TV_REGEX = /^AGILETV/i

export const SVAS_TYPES_CODE_TEXT = {
  SMARTHOME: 'SMART HOME',
  HOMEGO: 'Home GO',
}

export const CVM_DISCOUNT_RETENTION_CONVERGENT = 'RETDISC_CONV'
export const CVM_DISCOUNT_RETENTION_CONVERGENT_VALUES = {
  PREOCUPATE_CERO_100_MB: 49.27,
  CONVERGENT_100_MB_AND_40_GB: 49.15,
  CONVERGENT_100_MB_AND_15_GB: 48.98,
  CONVERGENT_40: 40,
  CONVERGENT_30: 30,
}

export const CVM_DISCOUNT_RETENTION = 'RETDISC'
export const CVM_DISCOUNT_RETENTION_VALUES = {
  SUPER_DUO: 2.48,
  TARIFF_1GB_5_EUROS: 4.13,
  TARIFF_1GB_10_EUROS: 8.26,
}

export const WIFI_TOTAL_01_DISCOUNTS = [
  'WIFITOTAL01DISC_03',
  'WIFITOTAL01DISC_06',
  'WIFITOTAL01DISC_12',
]
export const WIFI_TOTAL_02_DISCOUNTS = [
  'WIFITOTAL02DISC_03',
  'WIFITOTAL02DISC_06',
  'WIFITOTAL02DISC_12',
]

export const WIFI_TOTAL_DISCOUNTS = [...WIFI_TOTAL_01_DISCOUNTS, ...WIFI_TOTAL_02_DISCOUNTS]
export const NETFLIX_DISCOUNT = 'NETFLIX05DISC'
export const SOFTBUNDLE_NETFLIX_DISCOUNT = 'NETFLIX05DISC02'

export const MOBILE_MAIN_TARIFFS_DISCOUNTS = [
 'CANDISCMOB010',
 'CANDISCMOB020',
 'CANDISCMOB030',
 'CANDISCMOB040',
 'CANDISCMOB050'
]

export const CONVERGENT_5_EUROS_DISCOUNT = 'RETFH999DISCR005'

export const CVM_DISCOUNTS_CONVERGENT = [
  CONVDISC_CS50_3,
  CVM_DISCOUNT_RETENTION_CONVERGENT,
  'PRODISCPS',
  NETWORK_ISSUE_DISCOUNT,
  PRO_CONVERGENT_DISCOUNT_50_PERCENT,
  PRO_CONVERGENT_DISCOUNT_100_PERCENT,
  'RETFH600DISCR01',
  'RETFH600DISCR02',
  'CONVDISC_CS100_6',
  'FH600DISC001',
  'FH600DISC002',
  CVM_DISCOUNT_AGILE_TV,
  SVA_HEBE_DISCOUNT_CODE,
  ...PRO_SOHO_CONVERGENT_DISCOUNTS,
]
// DESCUENTOS RETENCION - MOVIL
export const CVM_TRY_AND_BUY_DISCOUNT = 'CONT_DISC_TB_20'
export const TRY_AND_BUY_MOBILE_DISCOUNT_PRO = 'MOB_DISC_TB_PRO'

export const CVM_INDEFINITE_DISCOUNT_MOBILE = 'MOBANTOMVRETDISC'
export const CVM_INDEFINITE_DISCOUNT_CONVERGENT = 'COVANTOMVRETDISC'

export const PRO_MOBILE_DISCOUNT_50_PERCENT = 'PRORETMOV50'
export const PRO_MOBILE_DISCOUNT_100_PERCENT = 'PRORETMOV100'

export const CVM_DISCOUNT_SINFIN_INFINITE_GB_PRO = 'CONT999GP01DISC02'

export const CVM_TARIFF_RETENTION_MOBILE_RETIRED = 'CONT999GR01'
export const CVM_TARIFF_RETENTION_MOBILE = 'CONT999GR02'
export const CVM_TARIFF_RETENTION_MOBILE_PRO = 'CONT999GP01'
export const CVM_DISCOUNT_RETENTION_MOBILE = 'RETDISC_MOB'
export const CVM_DISCOUNT_RETENTION_MOBILE_VALUES = {
  ADDITIONAL_LINE_INFINITE_GB: 58.97,
  SINFIN_40_GB_X_20: 37.5,
  SINFIN_MOBILE: 28.6,
  SINFIN_MOBILE_RETIRED: 21.87,
  SINFIN_INFINITE_GB_2_EUROS: 5.71,
  SINFIN_INFINITE_GB_4_EUROS: 12.5,
  SINFIN_INFINITE_GB_6_EUROS: 18.75,
}

export const RETENTION_ADDITIONAL_LINE_DISCOUNT = 'additional_line_discount'

export const MULTISIM_DISCOUNT = 'CONT020GMSDISC'

export const CVM_DISCOUNT_MOBILE = [
  RETENTION_ADDITIONAL_LINE_DISCOUNT,
  CVM_DISCOUNT_RETENTION_MOBILE,
  PRO_MOBILE_DISCOUNT_50_PERCENT,
  PRO_MOBILE_DISCOUNT_100_PERCENT,
  ...PRO_SOHO_MOBILE_DISCOUNTS,
  ...MOBILE_2_X_1_DISCOUNT
]

// DESCUENTOS SEGUNDA RESIDENCIA
const CVM_ADSL_20MB_2P_DISCOUNT = 'RETDSLA2RESIDISC'
const CVM_FIBRA_100MB_2P_DISCOUNT = 'RETFHE2RESIDISC'
const CVM_FIBRA_600MB_2P_DISCOUNT = 'RETFHF2RESIDISC'
const CVM_FIBRA_1GB_2P_DISCOUNT = 'RETFHD2RESIDISC'

export const CVM_2P_DISCOUNTS = [
  CVM_ADSL_20MB_2P_DISCOUNT,
  CVM_FIBRA_100MB_2P_DISCOUNT,
  CVM_FIBRA_600MB_2P_DISCOUNT,
  CVM_FIBRA_1GB_2P_DISCOUNT,
  'RETDSLA2RESIDISC02',
  'RETFHC2RESIDISC02',
  'RETFHF2RESIDISC02',
  'RETFHD2RESIDISC02',
]


export const PRUEBALO_DIGITAL_12_DISCOUNTS = [
  'PRUEBALO600DISCP012SD',
  'PRUEBALODISCP012SD',
]

export const PRUEBALO_12_PRO_DISCOUNTS = [
  'PRUEBALO600DISCP012',
  'PRUEBALODISCP012',
]

export const PRUEBALO_12_RESIDENTIAL_DISCOUNTS = [
  'PRUEBALO600DISCR012',
  'PRUEBALODISCR012'
]

export const PRUEBALO_DIGITAL_18_DISCOUNTS = [
  'PRUEBALO600DISCP018SD',
  'PRUEBALODISCP018SD'
]

export const PRUEBALO_18_PRO_DISCOUNTS = [
  'PRUEBALO600DISCP018',
  'PRUEBALODISCP018',
]

export const PRUEBALO_18_RESIDENTIAL_DISCOUNTS = [
  'PRUEBALO600DISCR018',
  'PRUEBALODISCR018'
]

// DESCUENTOS FIDELIZACION
export const CVM_RETENTION_DISCOUNTS = [
  CVM_DISCOUNT_RETENTION_CONVERGENT,
  CVM_DISCOUNT_RETENTION_MOBILE,
  'RETFH600DISCR01',
  'RETFH600DISCR02',
  'CONVDISC_CS100_6',
  'FH600DISC001',
  'FH600DISC002',
  CVM_TRY_AND_BUY_DISCOUNT,
  'DISCCONT999GRHM',
  'RETDISCMOB_CONT999GR',
  'DISCCONT999GPHM',
  'MOB_DISC_TB_PRO',
  'RETDISCMOB_CONT999GP',
  CVM_DISCOUNT_AGILE_TV,
  SVA_HEBE_DISCOUNT_CODE,
  ...CVM_2P_DISCOUNTS,
  CVM_FIBRA_300MB_2P_DISCOUNT,
  SVA_FHD_300_CODE_RESIDENTIAL,
  SVA_FHD_300_CODE_PRO,
  CVM_NEW_PORTFOLIO_2R_DISCOUNT,
  CVM_NEW_PORTFOLIO_300MB_2P_DISCOUNT,
  ...WIFI_TOTAL_DISCOUNTS,
  EXTRA_DUO_DISCOUNT_ID,
  CVM_DISCOUNT_SINFIN_INFINITE_GB_PRO,
  CVM_DISCOUNT_RETENTION,
  ...CVM_RETENTION_1GB_3P_DISCOUNTS,
  AMAZON_DISCOUNT,
  ...MOBILE_2_X_1_DISCOUNT,
  CONVERGENT_5_EUROS_DISCOUNT,
  ...PRUEBALO_DIGITAL_12_DISCOUNTS,
  ...PRUEBALO_12_PRO_DISCOUNTS,
  ...PRUEBALO_12_RESIDENTIAL_DISCOUNTS,
  ...PRUEBALO_DIGITAL_18_DISCOUNTS,
  ...PRUEBALO_18_PRO_DISCOUNTS,
  ...PRUEBALO_18_RESIDENTIAL_DISCOUNTS
]

export const DISCOUNT_DISTRIBUIDOR_RES_DISC = 'DISTRIBUIDORDISC'

export const CVM_DISCOUNT_WITHOUT_PERMANENCY = [
  EXTRA_DUO_DISCOUNT_ID,
  ...WIFI_TOTAL_DISCOUNTS,
  DISCOUNT_DISTRIBUIDOR_RES_DISC,
  DISC2POR1
]

// DESCUENTOS SOHO PRO
export const CVM_PRO_DISCOUNTS_WITH_PERMANENCY = [
  PRO_CONVERGENT_DISCOUNT_50_PERCENT,
  PRO_CONVERGENT_DISCOUNT_100_PERCENT,
  PRO_MOBILE_DISCOUNT_50_PERCENT,
  PRO_MOBILE_DISCOUNT_100_PERCENT,
]

export const CVM_PRO_DISCOUNTS_WITHOUT_PERMANENCY = [
  CONVDISC_CS10F12,
  CONVDISC_CS10M12,
  CONVDISC_CS20F12,
  CONVDISC_CS20M12,
  CONVDISC_CS30F12,
  CONVDISC_CS30M12,
  FHD300DISCR001,
  FHD300DISCP001,
  RETFH600DISCP01,
  RETFH600DISCP02,
]

export const CVM_PRO_DURATIONS_WITH_PERMANENCY = [1, 2, 3, 6]

export const TRY_AND_BUY_2_MONTHS_TARIFFS = ['CONT25', 'CONT030GR', 'CONT040GR']

export const SINFIN_6GB = 'CONT006GR01'
export const SINFIN_30GB = 'CONT030GR'
export const SINFIN_60GB = 'CONT060GR'
export const SINFIN_60GB_PRO = 'CONT060GP'

export const MULTISIM_TARIFF = ['CONT020GMSR', 'CONT020GMSP']

export const TARIFF_DONT_LOSE_ADDITIONAL_DUO_DISCOUNT = [SINFIN_60GB_PRO, SINFIN_60GB]

export const ADSL_FIBRA_SINFIN_30GB = ['CONTFHE030GR', 'CONTDSLA030GR']

export const ADSL_20MB = 'CONTDSLAR'
export const FIBRA_100MB = ['CONTFHER', 'CONTFHENR', 'CONTFHENR01']
export const FIBRA_300MB = ['CONTFH300', 'CONTFH45_300', 'CONTFHNB45_300']
export const FIBRA_600MB = ['CONTFHFR', 'CONTFHFNR']
export const FIBRA_1GB = 'CONTFHDR'

export const SINFIN_TRY_AND_BUY_100MB = 'CONTFHE999GTR02'
export const SINFIN_TRY_AND_BUY_ADSL = 'CONTDSLA999GTR02'
export const SINFIN_TRY_AND_BUY_100MB_NEBA = 'CONTFHE999GNTR02'
export const SINFIN_TRY_AND_BUY_100MB_VULA = 'CONTFHE999GNTR03'
export const SINFIN_FIBER_600MB = 'CONTFHF999GTR02'
export const SINFIN_FIBER_600MB_INDIRECT = 'CONTFHF999GNTR02'
export const SINFIN_FIBER_1GB = 'CONTFHD999GTR02'
export const PREOCUPATE_CERO_TRY_AND_BUY_100MB = 'CONTFHE999GHR'
export const PREOCUPATE_CERO_TRY_AND_BUY_100MB_NEBA = 'CONTFHE999GNHR'
export const PREOCUPATE_CERO_TRY_AND_BUY_100MB_VULA = 'CONTFHE999GNHR01'

export const SINFIN_25MB_ADSL = 'CONTDSLA025GR'
export const SINFIN_25MB_FIBER_1GB = 'CONTFHD025GR'
export const SINFIN_25MB_FIBER_100MB_NEBA = 'CONTFHE025GNR'
export const SINFIN_25MB_FIBER_100MB_VULA = 'CONTFHE025GNR01'
export const SINFIN_25MB_FIBER_100MB = 'CONTFHE025GR'
export const SINFIN_25MB_FIBER_600MB_INDIRECT = 'CONTFHF025GNR'
export const SINFIN_25MB_FIBER_600MB = 'CONTFHF025GR'

const SINFIN_30GB_TARIFF = 'CONT030GR02'
const SINFIN_40GB_PRO_TARIFF = 'CONT040GP02'

export const OLD_PORTFOLIO_TARIFFS_NAME = {
  [SINFIN_TRY_AND_BUY_100MB]: 'Fibra 100 Mb + La Sinfín Infinitos GB TRY&BUY',
  [SINFIN_TRY_AND_BUY_100MB_NEBA]: 'Fibra 100 Mb Indirecta + La Sinfín Infinitos GB (NEBA) TRY&BUY',
  [SINFIN_TRY_AND_BUY_100MB_VULA]: 'Fibra 100 Mb Indirecta + La Sinfín Infinitos GB (VULA) TRY&BUY',
  [SINFIN_TRY_AND_BUY_ADSL]: 'ADSL + La Sinfín Infinitos GB',
  [SINFIN_FIBER_600MB]: 'Fibra 600 Mb + La Sinfín Infinitos GB',
  [SINFIN_FIBER_600MB_INDIRECT]: 'Fibra 600 Mb + La Sinfín Infinitos GB (NEBA/VULA)',
  [SINFIN_FIBER_1GB]: 'Fibra 1 Gb + La Sinfín Infinitos GB',
  [PREOCUPATE_CERO_TRY_AND_BUY_100MB]: 'Preocúpate Cero Fibra 100 Mb TRY&BUY',
  [PREOCUPATE_CERO_TRY_AND_BUY_100MB_NEBA]: 'Preocúpate Cero Fibra 100 Mb Indirecta TRY&BUY',
  [PREOCUPATE_CERO_TRY_AND_BUY_100MB_VULA]: 'Preocúpate Cero Fibra 100 Mb Indirecta TRY&BUY',
  [SINFIN_6GB]: 'La Sinfin 6 GB',
  [SINFIN_25MB_ADSL]: 'ADSL + La Sinfin 25 GB',
  [SINFIN_25MB_FIBER_1GB]: 'Fibra 1 Gb + La Sinfin 25 GB',
  [SINFIN_25MB_FIBER_100MB_NEBA]: 'Fibra 100 Mb Indirecta + La Sinfin 25 GB',
  [SINFIN_25MB_FIBER_100MB_VULA]: 'Fibra 100 Mb Indirecta + La Sinfin 25 GB',
  [SINFIN_25MB_FIBER_100MB]: 'Fibra 100 Mb + La Sinfin 25 GB',
  [SINFIN_25MB_FIBER_600MB_INDIRECT]: 'Fibra 600 Mb Indirecta + La Sinfin 25 GB',
  [SINFIN_25MB_FIBER_600MB]: 'Fibra 600 Mb + La Sinfin 25 GB',
}

export const OLD_PORTOLIO_TARIFFS_SPECIAL_NAME_LOYALTY = {
  [SINFIN_TRY_AND_BUY_100MB]: 'Fibra 100 Mb + La Sinfín Infinitos GB',
  [SINFIN_TRY_AND_BUY_100MB_NEBA]: 'Fibra 100 Mb Indirecta + La Sinfín Infinitos GB (NEBA)',
  [SINFIN_TRY_AND_BUY_100MB_VULA]: 'Fibra 100 Mb Indirecta + La Sinfín Infinitos GB (VULA)',
}

export const SINFIN_40GB_IDS_WITH_INDEFINITE_DISCOUNT = ['CONTFHE040GR', 'CONTDSLA040GR']

export const INFINITE_CODE = '999'

export const CVM_BONUSES = [
  // 'FID3GB_12m_R_UPSELL',
  // 'FID3GB_3m_R_UPSELL',
  // 'FID3GB_6m_R_UPSELL',
  // 'FID3GB_R_UPSELL',
  // 'RET5GB_12mP_R_UPSELL',
  // 'RET5GB_12m_R_UPSELL',
  // 'RET5GB_3mP_R_UPSELL',
  // 'RET5GB_3m_R_UPSELL',
  // 'RET5GB_6mP_R_UPSELL',
  // 'RET5GB_6m_R_UPSELL',
  'DATA6',
  'DATA6_UPSELL',
  'DATA200',
  'DATA7',
]

export const CVM_SET_BUNDLE_BONUSES = ['DATA6', 'DATA200', 'DATA7']

export const OBSOLETE_TARIFFS = [
  'CONTF11',
  'CONTF8',
  'CONTS12',
  'CONT31',
  'CONT36',
  'CONT37',
  'CONT38',
  'CONT39',
  'CONTDSL52',
  'CONTFHNB52_50',
  'CONTFHNB62_300',
  'CONTFH52_50',
  'CONTFH62_300',
  'CONTFH82_1000',
]

export const AVAILABLE_RETIRED_TARIFFS = [
  'CONTDSLA040GR',
  'CONTFHE040GR',
  'CONTFHF040GR',
  'CONTFHD040GR',
  'CONTFHE040GNR',
  'CONTFHE040GNR01',
  'CONTFHF040GNR',
  'CONTDSLA003GR02',
  'CONTFHD003GR02',
  'CONTFHE003GNR02',
  'CONTFHE003GNR03',
  'CONTFHE003GR02',
  'CONTFHF003GNR02',
  'CONTFHF003GR02',
]

export const AVAILABLE_TRY_AND_BUY_LOYALTY_TARIFFS = [
  SINFIN_TRY_AND_BUY_100MB,
  SINFIN_TRY_AND_BUY_ADSL,
  SINFIN_TRY_AND_BUY_100MB_NEBA,
  SINFIN_TRY_AND_BUY_100MB_VULA,
  SINFIN_FIBER_600MB,
  SINFIN_FIBER_600MB_INDIRECT,
  SINFIN_FIBER_1GB,
]

export const NOT_AVAILABLE_TRY_AND_BUY_NOT_LOYALTY = [
  SINFIN_TRY_AND_BUY_ADSL,
  SINFIN_FIBER_600MB,
  SINFIN_FIBER_600MB_INDIRECT,
  SINFIN_FIBER_1GB,
]

export const AVAILABLE_LOYALTY_RETIRED_TARIFFS = [
  SINFIN_6GB,
  SINFIN_30GB_TARIFF,
  SINFIN_40GB_PRO_TARIFF,
]

export const AVAILABLE_LOYALTY_RETENTION_RETIRED_TARIFFS = [
  SINFIN_25MB_ADSL,
  SINFIN_25MB_FIBER_1GB,
  SINFIN_25MB_FIBER_100MB_NEBA,
  SINFIN_25MB_FIBER_100MB_VULA,
  SINFIN_25MB_FIBER_100MB,
  SINFIN_25MB_FIBER_600MB_INDIRECT,
  SINFIN_25MB_FIBER_600MB,
  SINFIN_30GB_TARIFF,
]

export const AVAILABLE_PRO_RETIRED_TARIFFS = [SINFIN_40GB_PRO_TARIFF]

export const AVAILABLE_TRY_AND_BUY_RETIRED_TARIFFS = [
  SINFIN_TRY_AND_BUY_100MB,
  SINFIN_TRY_AND_BUY_100MB_NEBA,
  SINFIN_TRY_AND_BUY_100MB_VULA,
  PREOCUPATE_CERO_TRY_AND_BUY_100MB,
  PREOCUPATE_CERO_TRY_AND_BUY_100MB_NEBA,
  PREOCUPATE_CERO_TRY_AND_BUY_100MB_VULA,
]

// The ONLY current discount codes that DO NOT create an incompatibility
// with the ones added from this module
export const COMPATIBLE_CURRENT_DISCOUNTS = [
  'DISCFH300P',
  'PRODISCPS',
  CVM_DISCOUNT_AGILE_TV,
  'DISCAGILETV06',
  'DISCCONT999GRHM',
  'RETDISCMOB_CONT999GR',
  'DISCCONT999GPHM',
  'MOB_DISC_TB_PRO',
  'RETDISCMOB_CONT999GP',
]

// Filters for legal texts in Contentful
export const DISCOUNT_OR_BONUS_OPERATION = 'descuento_bono'
export const TARIFF_OPERATION = 'cambio_tarifa'
export const PREPAID_TO_POSPAID_OPERATION_ES = 'cambio_prepago'
export const PREPAID_TO_POSPAID_OPERATION_EN = 'cambio_prepago_ingles'
export const PREPAID_TO_POSPAID_OPERATION_CAT = 'cambio_prepago_catalan'
export const UNSUBSCRIBE_OPERATION = 'baja_servicio'
export const HEAD_BOILERPLATE_OPERATION = 'head'
export const FOOTER_BOILERPLATE_OPERATION = 'footer'
export const ALTA_AGILE_CARTERA_OPERATION = 'alta_agile_cartera'
export const PENALTY_AGILE_CARTERA_OPERATION = 'legal_text_penalty_agile_cartera'

export const RENEWAL_ORDER_KEY = 'renewal_posorder'

export const NETFLIX_TARIFFS = [
  'CONTFHD025GNRN',
  'CONTFHD025GRN',
  'CONTFHD045GNRN',
  'CONTFHD045GRN',
  'CONTFHD999GNTRN',
  'CONTFHD999GTRN',
  'CONTFHG025GNRN',
  'CONTFHG025GRN',
  'CONTFHG045GNRN',
  'CONTFHG045GRN',
  'CONTFHG999GNTRN',
  'CONTFHG999GTRN',
]

export const SINFIN_INFINITE_3P = [
  'CONTFHD999GNTR04',
  'CONTFHD999GNTRN',       
  'CONTFHD999GTR04',
  'CONTFHD999GTRN',
  'CONTFHF999GNTR04',
  'CONTFHF999GTR04', 
  'CONTFHG999GNTR', 
  'CONTFHG999GNTRN',
  'CONTFHG999GTR',
  'CONTFHG999GTRN',
]

export const UNLIMITED_DURATION = 480
export const SPECIAL_UNLIMITED_DURATION = 999
export const UNLIMITED_DURATIONS = [UNLIMITED_DURATION, SPECIAL_UNLIMITED_DURATION]

export const CVM_TARIFFS_COMPATIBLE_WITH_HEBE_DISCOUNT = [
  'CONTFHE999GHR',
  'CONTFHE999GNHR',
  'CONTFHE999GNHR01',
  'CONTFHF999GHR',
  'CONTFHF999GNHR',
  'CONTFHD999GHR',
  'CONTFHD015GHR',
  'CONTFHE015GHR',
  'CONTFHE015GNHR01',
  'CONTFHE015GNHR',
  'CONTFHF015GHR',
  'CONTFHF015GNHR',
]

export const DISCOUNTS_THAT_APPLY_WHOLE_MONTH = [SVA_HEBE_DISCOUNT_CODE]

export const ADDITIONAL_LINE_DISCOUNTS = [
  {
    discountCode: 'DISCCONT008GRH',
    amounts: [
      {
        value: 50,
        unit: 'PORCENTAJE',
        durations: [
          {
            value: UNLIMITED_DURATION,
            description: '50% para siempre por línea adicional',
          },
        ],
      },
    ],
  },
  {
    discountCode: 'DISCCONT008GRHM',
    amounts: [
        {
        value: 50,
        unit: 'PORCENTAJE',
        durations: [
          {
            value: UNLIMITED_DURATION,
            description: '50% para siempre por línea adicional',
          },
        ],
      },
    ],
  },
  {
    discountCode: 'DISCCONT030GRH',
    amounts: [
      {
        value: 50,
        unit: 'PORCENTAJE',
        durations: [
          {
            value: UNLIMITED_DURATION,
            description: '50% para siempre por línea adicional',
          },
        ],
      },
    ],
  },
  {
    discountCode: 'DISCCONT030GRHM',
    amounts: [
      {
        value: 50,
        unit: 'PORCENTAJE',
        durations: [
          {
            value: UNLIMITED_DURATION,
            description: '50% para siempre por línea adicional',
          },
        ],
      },
    ],
  },
  {
    discountCode: 'DISCCONT25HM',
    amounts: [
      {
        value: 50,
        unit: 'PORCENTAJE',
        durations: [
          {
            value: UNLIMITED_DURATION,
            description: '50% para siempre por línea adicional',
          },
        ],
      },
    ],
  },
  {
    discountCode: 'DISCCONT33HM',
    amounts: [
      {
        value: 50,
        unit: 'PORCENTAJE',
        durations: [
          {
            value: UNLIMITED_DURATION,
            description: '50% para siempre por línea adicional',
          },
        ],
      },
    ],
  },
  {
    discountCode: 'DISCCONT15H',
    amounts: [
      {
        value: 50,
        unit: 'PORCENTAJE',
        durations: [
          {
            value: UNLIMITED_DURATION,
            description: '50% para siempre por línea adicional',
          },
        ],
      },
    ],
  },
  {
    discountCode: 'DISCCONT15HM',
    amounts: [
      {
        value: 50,
        unit: 'PORCENTAJE',
        durations: [
          {
            value: UNLIMITED_DURATION,
            description: '50% para siempre por línea adicional',
          },
        ],
      },
    ],
  },
  {
    discountCode: 'DISCCONT25H',
    amounts: [
      {
        value: 50,
        unit: 'PORCENTAJE',
        durations: [
          {
            value: UNLIMITED_DURATION,
            description: '50% para siempre por línea adicional',
          },
        ],
      },
    ],
  },
  {
    discountCode: 'DISCCONT34HM',
    amounts: [
      {
        value: 50,
        unit: 'PORCENTAJE',
        durations: [
          {
            value: UNLIMITED_DURATION,
            description: '50% para siempre por línea adicional',
          },
        ],
      },
    ],
  },
  {
    discountCode: 'DISCCONT26HM',
    amounts: [
      {
        value: 50,
        unit: 'PORCENTAJE',
        durations: [
          {
            value: UNLIMITED_DURATION,
            description: '50% para siempre por línea adicional',
          },
        ],
      },
    ],
  },
  {
    discountCode: 'DISCCONT33H',
    amounts: [
      {
        value: 50,
        unit: 'PORCENTAJE',
        durations: [
          {
            value: UNLIMITED_DURATION,
            description: '50% para siempre por línea adicional',
          },
        ],
      },
    ],
  },
  {
    discountCode: 'DISCCONT26H',
    amounts: [
      {
        value: 50,
        unit: 'PORCENTAJE',
        durations: [
          {
            value: UNLIMITED_DURATION,
            description: '50% para siempre por línea adicional',
          },
        ],
      },
    ],
  },
  {
    discountCode: 'DISCCONT34H',
    amounts: [
      {
        value: 50,
        unit: 'PORCENTAJE',
        durations: [
          {
            value: UNLIMITED_DURATION,
            description: '50% para siempre por línea adicional',
          },
        ],
      },
    ],
  },
  {
    discountCode: 'DISCCONT10H',
    amounts: [
      {
        value: 50,
        unit: 'PORCENTAJE',
        durations: [
          {
            value: UNLIMITED_DURATION,
            description: '50% para siempre por línea adicional',
          },
        ],
      },
    ],
  },
  {
    discountCode: 'DISCCONT10HM',
    amounts: [
      {
        value: 50,
        unit: 'PORCENTAJE',
        durations: [
          {
            value: UNLIMITED_DURATION,
            description: '50% para siempre por línea adicional',
          },
        ],
      },
    ],
  },
  {
    discountCode: 'DISCCONT100MRH',
    amounts: [
      {
        value: 50,
        unit: 'PORCENTAJE',
        durations: [
          {
            value: UNLIMITED_DURATION,
            description: '50% para siempre por línea adicional',
          },
        ],
      },
    ],
  },
  {
    discountCode: 'DISCCONT100MRHM',
    amounts: [
      {
        value: 50,
        unit: 'PORCENTAJE',
        durations: [
          {
            value: UNLIMITED_DURATION,
            description: '50% para siempre por línea adicional',
          },
        ],
      },
    ],
  },
  {
    discountCode: 'DISCCONT003GRH',
    amounts: [
      {
        value: 50,
        unit: 'PORCENTAJE',
        durations: [
          {
            value: UNLIMITED_DURATION,
            description: '50% para siempre por línea adicional',
          },
        ],
      },
    ],
  },
  {
    discountCode: 'DISCCONT003GRHM',
    amounts: [
      {
        value: 50,
        unit: 'PORCENTAJE',
        durations: [
          {
            value: UNLIMITED_DURATION,
            description: '50% para siempre por línea adicional',
          },
        ],
      },
    ],
  },
  {
    discountCode: 'DISCCONT015GRH',
    amounts: [
      {
        value: 50,
        unit: 'PORCENTAJE',
        durations: [
          {
            value: UNLIMITED_DURATION,
            description: '50% para siempre por línea adicional',
          },
        ],
      },
    ],
  },
  {
    discountCode: 'DISCCONT015GRHM',
    amounts: [
      {
        value: 50,
        unit: 'PORCENTAJE',
        durations: [
          {
            value: UNLIMITED_DURATION,
            description: '50% para siempre por línea adicional',
          },
        ],
      },
    ],
  },
  {
    discountCode: 'DISCCONT040GRH',
    amounts: [
      {
        value: 50,
        unit: 'PORCENTAJE',
        durations: [
          {
            value: UNLIMITED_DURATION,
            description: '50% para siempre por línea adicional',
          },
        ],
      },
    ],
  },
  {
    discountCode: 'DISCCONT040GRHM',
    amounts: [
      {
        value: 50,
        unit: 'PORCENTAJE',
        durations: [
          {
            value: UNLIMITED_DURATION,
            description: '50% para siempre por línea adicional',
          },
        ],
      },
    ],
  },
  {
    discountCode: 'DISCCONT006GRH',
    amounts: [
      {
        value: 50,
        unit: 'PORCENTAJE',
        durations: [
          {
            value: UNLIMITED_DURATION,
            description: '50% para siempre por línea adicional',
          },
        ],
      },
    ],
  },
  {
    discountCode: 'DISCCONT006GRHM',
    amounts: [
      {
        value: 50,
        unit: 'PORCENTAJE',
        durations: [
          {
            value: UNLIMITED_DURATION,
            description: '50% para siempre por línea adicional',
          },
        ],
      },
    ],
  },
  {
    discountCode: 'DISCCONT005GP01H',
    amounts: [
      {
        value: 50,
        unit: 'PORCENTAJE',
        durations: [
          {
            value: UNLIMITED_DURATION,
            description: '50% para siempre por línea adicional',
          },
        ],
      },
    ],
  },
  {
    discountCode: 'DISCCONT005GP01HM',
    amounts: [
      {
        value: 50,
        unit: 'PORCENTAJE',
        durations: [
          {
            value: UNLIMITED_DURATION,
            description: '50% para siempre por línea adicional',
          },
        ],
      },
    ],
  },
  {
    discountCode: 'DISCCONT010GP01H',
    amounts: [
      {
        value: 50,
        unit: 'PORCENTAJE',
        durations: [
          {
            value: UNLIMITED_DURATION,
            description: '50% para siempre por línea adicional',
          },
        ],
      },
    ],
  },
  {
    discountCode: 'DISCCONT010GP01HM',
    amounts: [
      {
        value: 50,
        unit: 'PORCENTAJE',
        durations: [
          {
            value: UNLIMITED_DURATION,
            description: '50% para siempre por línea adicional',
          },
        ],
      },
    ],
  },
  {
    discountCode: 'DISCCONT020GPH',
    amounts: [
      {
        value: 50,
        unit: 'PORCENTAJE',
        durations: [
          {
            value: UNLIMITED_DURATION,
            description: '50% para siempre por línea adicional',
          },
        ],
      },
    ],
  },
  {
    discountCode: 'DISCCONT020GPHM',
    amounts: [
      {
        value: 50,
        unit: 'PORCENTAJE',
        durations: [
          {
            value: UNLIMITED_DURATION,
            description: '50% para siempre por línea adicional',
          },
        ],
      },
    ],
  },
  {
    discountCode: 'DISCCONT050GPH',
    amounts: [
      {
        value: 50,
        unit: 'PORCENTAJE',
        durations: [
          {
            value: UNLIMITED_DURATION,
            description: '50% para siempre por línea adicional',
          },
        ],
      },
    ],
  },
  {
    discountCode: 'DISCCONT050GPHM',
    amounts: [
      {
        value: 50,
        unit: 'PORCENTAJE',
        durations: [
          {
            value: UNLIMITED_DURATION,
            description: '50% para siempre por línea adicional',
          },
        ],
      },
    ],
  },
  {
    discountCode: 'DISCCONT999GRHM',
    amounts: [
      {
        value: 50,
        unit: 'PORCENTAJE',
        durations: [
          {
            value: UNLIMITED_DURATION,
            description: 'Descuento de línea adicional',
          },
        ],
      },
    ],
  },
  {
    discountCode: 'DISCCONT999GPHM',
    amounts: [
      {
        value: 50,
        unit: 'PORCENTAJE',
        durations: [
          {
            value: UNLIMITED_DURATION,
            description: 'Descuento de línea adicional',
          },
        ],
      },
    ],
  },
]

export const PERMANENT_DOMAINS = {
  DTERM: 'Descuento',
  FTERM: 'Instalación',
  TERM: 'Dispositivo',
  UTERM: 'Bono',
}

export const MODAL_TITLES = {
  PRO: 'Solamente es posible dar de baja esta línea desde VISTA.',
  RESIDENTIAL:
    'Las bajas de líneas de suscripción móvil hay que dejarlas 6 meses desactivadas. Para ello, debes utilizar el botón de “Pasar a Prepago” ubicado en “Modificar tarifa”.',
}

export const MULTIPLE_DISCOUNTS_SEGMENTS = [
  'PRO-SOHO-AUTONOMO',
  'PRO-SOHO-COMPANY',
  'SOHO-COMPANY',
  'SOHO-AUTONOMO',
]

export const NA_RETENTION_CODE = 'NA'
export const NOT_RETAINED_CODE = 'A'
export const NOT_RETAINED_CANCELATION = 'NRCA13'
export const RETAINED_CODE = 'C'
export const RETAINED_CODE_CANCELATION_PRODUCT = 'CA13'
export const RETAINED_CODE_CANCELATION_OFFERT = 'CA'
export const RETAINED_NO_ACTION_CODE = 'B'
export const NOT_UTIL_CODE = 'NU'
export const GESTIONES_ADMINISTRATIVAS = 'Gestiones administrativas'
export const RETAINED_INFO = 'B05'
const SUBSCRIPTION_TYPE_1P_CODE = '1P'
const SUBSCRIPTION_TYPE_2P_CODE = '2P'
const SUBSCRIPTION_TYPE_3P_CODE = '3P'
export const INBOUND_REASONS = [
  'SEGU+',
  'SEGU-',
  'MIGRU+',
  'MIGRU-',
  'BTVU+',
  'BTVU-',
  'STCU+',
  'STCU-',
  'STCINFU+',
  'STCINFU-',
  'Q25U+',
  'Q25U-',
  'TERMU+',
  'TERMU-',
  'DOWNU+',
  'DOWNU-',
  'FDPU+',
  'FDPU-',
]

export const NA_CODES = [
  'CSTC09',
  'CDES07',
  'CFIJ09',
  'CMIG10',
  'CMOV09',
  'CQ2507',
  'CSEG07',
  'CTER12',
  'CTV07',
  'CDES17',
  'CMIG10',
  'CBTV08',
]

export const SMS_TYPIFICATIONS_CAMPAIGNS = ['BM', 'BI', 'BF', 'BE']
export const SMS_TYPIFICATIONS_AREAS = ['RETENCION']

export const SUBSCRIPTIONS_TYPE_OPTIONS = [
  {
    text: '1P',
    value: SUBSCRIPTION_TYPE_1P_CODE,
  },
  {
    text: '2P',
    value: SUBSCRIPTION_TYPE_2P_CODE,
  },
  {
    text: '3P',
    value: SUBSCRIPTION_TYPE_3P_CODE,
  },
]

export const TICKET_JIRA_OPTIONS = [
  {
    text: 'Sí',
    value: '01',
  },
  {
    text: 'No',
    value: '02',
  },
  {
    text: 'Ya creado',
    value: '03',
  },
]

export const FIXED_ISSUE_OPTIONS = [
  {
    text: 'Sí',
    value: '01',
  },
  {
    text: 'No',
    value: '02',
  },
  {
    text: 'KO Técnico',
    value: '03',
  },
]

export const DEPENDENCIES = {
  campaign: ['reason', 'subreason', 'detail', 'retention', 'retentionDetail'],
  reason: ['subreason', 'detail', 'retention', 'retentionDetail'],
  subreason: ['detail', 'loyaltyDetail'],
  detail: [],
  retention: ['retentionDetail'],
  retentionDetail: [],
  loyalty: [],
  subscriptionType: ['loyaltyDetail'],
  operator: [],
}

export const RETENTION_NAME = 'retention'
export const LOYALTY_DETAIL_NAME = 'loyaltyDetail'
export const INITIAL_FEE_NAME = 'initialFee'
export const FINAL_FEE_NAME = 'finalFee'

export const NOT_BREAKDOWN_FIELDS = [
  'subreason',
  'detail',
  'operator',
  'subscriptionType',
  'loyalty',
  'loyaltyDetail',
  INITIAL_FEE_NAME,
  FINAL_FEE_NAME,
]

export const NOT_BO_FIELDS = [
  'reason',
  'subreason',
  'detail',
  'operator',
  'ticketCreated',
  'subscriptionType',
  'loyalty',
  'loyaltyDetail',
  INITIAL_FEE_NAME,
  FINAL_FEE_NAME,
]

export const NOT_ATC_FIELDS = [
  'operator',
  'ticketCreated',
  'retention',
  'retentionDetail',
  'subscriptionType',
  'loyalty',
  'loyaltyDetail',
  INITIAL_FEE_NAME,
  FINAL_FEE_NAME,
]

export const NOT_RETENTION_FIELDS = [
  'ticketCreated',
  'subscriptionType',
  'loyalty',
  'loyaltyDetail',
]

export const NOT_PRO_FIELDS = [...NOT_RETENTION_FIELDS, INITIAL_FEE_NAME, FINAL_FEE_NAME]

export const NOT_CANCELATION_FIELDS = [
  'detail',
  'ticketCreated',
  'subscriptionType',
  'loyalty',
  'loyaltyDetail',
  INITIAL_FEE_NAME,
  FINAL_FEE_NAME,
]

export const NOT_LOYALTY_FIELDS = [
  'operator',
  'detail',
  'ticketCreated',
  'retention',
  'retentionDetail',
  'subscriptionType',
  'loyalty',
  'loyaltyDetail',
]

export const NOT_INBOUND_LOYALTY_FIELDS = [
  'operator',
  'detail',
  'ticketCreated',
  'retention',
  'retentionDetail',
]

export const NOT_INBOUND_AND_UTIL_LOYALTY_FIELDS = [
  ...NOT_INBOUND_LOYALTY_FIELDS,
  'loyalty',
  'loyaltyDetail',
]

export const LOYALTY_PROFILE_ID = 'FIDELIZACION'
export const SUFIX_LOYALTY_FIELDS_PERMISSION = '_FREC'

export const BUSINESS_PROFILE_IDS = ['PRO', 'SOHO']

export const ADD_SUBSCRIPTION_OPERATION = 1
export const CROSS_SUBSCRIPTION_OPERATION = 2
export const ADD_MULTISIM_OPERATION = 3

export const UNIT_TYPE_PERCENTAGE = 'percentage'
export const UNIT_TYPE_FIXED = 'fixed'

export const ADSL_20MB_PURE = 'CONTDSLAFR'
export const FIBER_100MB_PURE = ['CONTFHEFR', 'CONTFHENFR01', 'CONTFHENFR']
const FIBER_300MB_PURE = ['CONTFHCFR', 'CONTFHCNFR']
export const FIBER_600MB_PURE = ['CONTFHFFR', 'CONTFHFNFR']
export const FIBER_1GB_PURE = ['CONTFHDFR', 'CONTFHDFR01', 'CONTFHDNFR']

export const TARIFFS_2P_PURE = [
  'CONTDSLAFP',
  'CONTFHEFP',
  'CONTFHENFP',
  'CONTFHENFP01',
  'CONTFHFNFP',
  'CONTFHFFP',
  'CONTFHDFP',
  ADSL_20MB_PURE,
  ...FIBER_100MB_PURE,
  ...FIBER_300MB_PURE,
  ...FIBER_600MB_PURE,
  ...FIBER_1GB_PURE,
]

export const PRUEBALO_PRO_TARIFFS = [
  'CONTFHD005GP02',
  'CONTFHD030GNP02',
  'CONTFHD030GP02',
  'CONTFHD050GNP03',
  'CONTFHD050GP03',
  'CONTFHD999GNTP06',
  'CONTFHD999GTP06',
  'CONTFHDFP01',
  'CONTFHDNFP01'
]

export const PRUEBALO_RESIDENTIAL_TARIFFS = [
  'CONTFHD020GNR',
  'CONTFHD020GR',
  'CONTFHD025GNRN',
  'CONTFHD025GRN',
  'CONTFHD040GNR02',
  'CONTFHD040GR02',
  'CONTFHD045GNRN',
  'CONTFHD045GRN',
  'CONTFHD999GNTR04',
  'CONTFHD999GNTRN',
  'CONTFHD999GTR04',
  'CONTFHD999GTRN',
  'CONTFHDFR02',
  'CONTFHDNFR01'
]

export const PRUEBALO_DIGITAL_TARIFFS = [
  'CONTFHD999GNTPSD1',
  'CONTFHD999GTPSD1'
]

export const PETERM1275 = 'PTERM12-75'
export const PETERM1875 = 'PTERM18-75'
export const PETERM12150 = 'PTERM12-150'
export const PETERM18150 = 'PTERM18-150'

export const PRUEBALO_DISCOUNTS_PERMANENCY = {
  PRUEBALO600DISCP012: PETERM1275,
  PRUEBALO600DISCP012SD: PETERM1275,
  PRUEBALO600DISCP018: PETERM1875,
  PRUEBALO600DISCP018SD: PETERM1875,
  PRUEBALODISCP012: PETERM12150,
  PRUEBALODISCP012SD: PETERM12150,
  PRUEBALODISCP018: PETERM18150,
  PRUEBALODISCP018SD: PETERM18150,
  PRUEBALO600DISCR012: PETERM1275,
  PRUEBALO600DISCR018: PETERM1875,
  PRUEBALODISCR012: PETERM12150,
  PRUEBALODISCR018: PETERM18150
}

export const DTERM1241 = 'DTERM12-41'
export const DTERM1250 = 'DTERM12-50'
export const DTERM2450 = 'DTERM24-50'
export const DTERM12150 = 'DTERM12-150'
export const PERMANENCY_12_MONTHS = 12

export const TERRITORY_OWNER_ADSL = 'MASMOVIL'
export const COVERAGE_TOKEN = '19384392-0395-0982-9893-784379230910'
export const SUBSCRIPTION_ADDITIONAL_LINE = 'additional_line'

export const PRICE_DUO_PRINCIPAL = 2.48
export const TARIFF_MIXED = 'mixed'

export const TYPE_TERM = {
  FTERM: 'instalación de fibra.',
  NEBTERM: 'instalación de fibra.',
  TERM: 'permanencia por dispositivo.',
  UTERM: 'permanencia por bono de datos.',
  DTERM: 'permanencia por descuento.',
  TVTERM: 'permanencia en Televisión.',
}

export const RIGHT_NOW = { value: 'rightNow', text: 'Inmediato' }
export const NEXT_DAY = { value: 'nextDay', text: 'Mañana' }
export const IN_TWO_DAYS = { value: 'inTwoDays', text: 'Dentro de dos días (48h)' }
export const NEXT_MONTH = { value: 'nextMonth', text: 'El 1 del próximo mes' }

export const CHANGE_TECHNOLOGY = 'change-technology'
export const TECHNOLOGY_CHANGE = 'technology-change'
export const ADDRESS_CHANGE = 'address-change'
export const PLAN_CHANGE = 'plan-change'
export const COMMERCIAL_MIGRATION = 'commercial-migration'

export const FIBRA_1GB_3P_RESIDENTIAL = [
  'CONTFHD010GNR',
  'CONTFHD010GR',
  'CONTFHD030GNR02',
  'CONTFHD030GR02',
  'CONTFHD999GNTR04',
  'CONTFHD999GTR04',
]

export const FIBRA_1GB_3P_RESIDENTIAL_DISCOUNT = [
  'CONTFHD020GNR',
  'CONTFHD020GR',
  'CONTFHD040GNR02',
  'CONTFHD040GR02',
  'CONTFHD999GNTR04',
  'CONTFHD999GTR04',
]

export const FIBRA_1GB_2P_RESIDENTIAL_DISCOUNT = ['CONTFHDNFR01', 'CONTFHDFR02']

export const FIBRA_1GB_3P_PRO = [
  'CONTFHD015GNP',
  'CONTFHD015GP',
  'CONTFHD040GNP02',
  'CONTFHD040GP02',
  'CONTFHD999GNTP06',
  'CONTFHD999GTP06',
  'CONTFHD005GP02',
]

export const DISCOUNT_DESCRIPTION = [
  ...WIFI_TOTAL_DISCOUNTS,
  EXTRA_DUO_DISCOUNT_ID,
  DISCOUNT_DISTRIBUIDOR_RES_DISC,
  ...CVM_RETENTION_1GB_3P_DISCOUNTS,
  ...MOBILE_2_X_1_DISCOUNT,
  AMAZON_DISCOUNT,
  CONVERGENT_5_EUROS_DISCOUNT,
  NETFLIX_DISCOUNT,
  SOFTBUNDLE_NETFLIX_DISCOUNT,
  ...MOBILE_MAIN_TARIFFS_DISCOUNTS,
  ...PRUEBALO_DIGITAL_12_DISCOUNTS,
  ...PRUEBALO_12_RESIDENTIAL_DISCOUNTS,
  ...PRUEBALO_12_PRO_DISCOUNTS,
  ...PRUEBALO_DIGITAL_18_DISCOUNTS,
  ...PRUEBALO_18_PRO_DISCOUNTS,
  ...PRUEBALO_18_RESIDENTIAL_DISCOUNTS,
  PRO_CONVERGENT_DISCOUNT_50_PERCENT
]

export const WARN_DISCOUNT_LOST = 'POTENTIAL_DISCOUNT_LOST'

export const ADSL_TERRITORY_OWNER = 'adsl_territory_owner'
export const INDIRECT_FIBER_TERRITORY_OWNER = 'indirect_fiber_territory_owner'
export const COMMERCIAL_MIGRATION_PERMISSIONS = [
  'fiber_territory_owner',
  INDIRECT_FIBER_TERRITORY_OWNER,
]

export const DISCOUNTS_THAT_NOT_APPPLY_PRORRATED = [ENERGY_GO_DISC, SVA_HEBE_DISCOUNT_CODE]

export const XSELL_DISCOUNT_EXCEPTION = [CONVDISC_CS50_3]

export const CHANGE_TECHNOLOGY_SFID_ACCESS = ['YC007024_RPORT']
export const CHANGE_ADDRESS_SFID_ACCESS = ['YC007024_RPORT', 'BL001119']

export const TESA_INSTALLER = 'TESA'
