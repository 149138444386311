import { get } from 'lodash'
import { createSelector } from 'reselect'

import { selectSVAAgileList } from 'modules/SVAs/store'
import { formatSingleSVA } from 'modules/SVAs/helpers'

export const getAddAgileTvData = state => get(state, 'addAgileTv', {})

export const selectAgileTvSvaWithStb = createSelector([selectSVAAgileList], svas => {
  const agileTvSva = svas.filter(sva => sva.withStb)

  if (!agileTvSva) {
    return null
  }

  return agileTvSva.map(agileTv => formatSingleSVA(agileTv))
})

export const selectFetchAddAgileTvAllowedIsLoading = createSelector(
  [getAddAgileTvData],
  ({ validation: { loading } }) => loading,
)
export const selectFetchAddAgileTvAllowedError = createSelector(
  [getAddAgileTvData],
  ({ validation: { error } }) => error,
)
export const selectFetchAddAgileTvAllowedIsSuccess = createSelector(
  [getAddAgileTvData],
  ({ validation: { success } }) => success,
)

export const selectAddAgileTvOrderIsLoading = createSelector(
  [getAddAgileTvData],
  ({ add: { loading } }) => loading,
)
export const selectAddAgileTvOrderError = createSelector(
  [getAddAgileTvData],
  ({ add: { error } }) => error,
)
export const selectAddAgileTvOrderIsSuccess = createSelector(
  [getAddAgileTvData],
  ({ add: { success } }) => success,
)
