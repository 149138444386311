import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Box, Divider } from '@material-ui/core'
import Close from '@material-ui/icons/Close'

import { formatDate } from 'utils/formatting/dates'

import { Button, ButtonLink, SpinnerCenter } from 'components/ui'
import {
  SectionTitle,
  SectionTitleGrid,
  SectionValidationGrid,
  ContainerTitle,
  CardRow,
  Card,
  CardRowLabel,
  CardRowValue,
} from 'components'

import { CancelOrderInBusModal } from '../CancelOrderInBusModal'

const SectionValidationGridStyled = styled(SectionValidationGrid)`
  position: relative;
`

const closeTab = () => window.close()

function StatusOnFly({
  hasErrors,
  isOnFlyTechNeba,
  poncHasBeenReached,
  workOrderID,
  lastModifiedDate,
  fixedStatusStr,
  isCancelLoading,
  hasCancelErrors,
  cancelOrder: cancelOrderAction,
  pageTitle,
  isCancelling,
  isLoading,
}) {
  const cancelOrder = () => {
    // [TODO][HEC] Choose a better message
    cancelOrderAction(workOrderID, 'Cancelling NEBA')
  }

  return (
    <>
      <SectionTitleGrid item xs={12}>
        <SectionTitle width="auto">{!isLoading && pageTitle}</SectionTitle>
        <ButtonLink color="default" underline={false} onClick={closeTab}>
          cancelar y volver
          <Close />
        </ButtonLink>
      </SectionTitleGrid>
      <SectionValidationGridStyled item xs={12}>
        {isLoading && <SpinnerCenter showMsg />}
        {!isLoading && (
          <Card width="80%">
            <Box display="flex" alignItems="center">
              {hasErrors && <ContainerTitle>Se ha producido un error!</ContainerTitle>}

              {!hasErrors && isOnFlyTechNeba && (
                <>
                  <ContainerTitle>WorkOrderID: {workOrderID}</ContainerTitle>
                  <CardRow>
                    <CardRowLabel>Fecha última actualización: </CardRowLabel>
                    <CardRowValue>{formatDate(lastModifiedDate)}</CardRowValue>
                  </CardRow>
                  <CardRow>
                    <CardRowLabel>Status: </CardRowLabel>
                    <CardRowValue>{fixedStatusStr}</CardRowValue>
                  </CardRow>

                  <Box mx="15px">
                    <Divider />
                  </Box>

                  {poncHasBeenReached ? (
                    <ContainerTitle>
                      <span>Punto de NO CANCELACIÓN alcanzado.</span>
                    </ContainerTitle>
                  ) : null}

                  <Button disabled={poncHasBeenReached} onClick={cancelOrder}>
                    Cancelar
                  </Button>

                  {/* Modal Cancellation */}
                  {isCancelling && isCancelLoading && <SpinnerCenter showMsg />}
                  {isCancelling && !isCancelLoading && (
                    <CancelOrderInBusModal success={!hasCancelErrors} orderId={workOrderID} />
                  )}
                </>
              )}
            </Box>
          </Card>
        )}
      </SectionValidationGridStyled>
    </>
  )
}

StatusOnFly.propTypes = {
  isLoading: PropTypes.bool,
  hasErrors: PropTypes.bool,
  isOnFlyTechNeba: PropTypes.bool,
  cancelOrder: PropTypes.func,
  poncHasBeenReached: PropTypes.bool,
  workOrderID: PropTypes.string,
  lastModifiedDate: PropTypes.string,
  fixedStatusStr: PropTypes.string,
  isCancelling: PropTypes.bool,
  isCancelLoading: PropTypes.bool,
  hasCancelErrors: PropTypes.bool,
  pageTitle: PropTypes.string,
}

export default StatusOnFly
