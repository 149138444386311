import { call, put, takeEvery } from 'redux-saga/effects'
import { get } from 'lodash'
import { logError, formatErrorMessage } from 'services/logging'
import { axiosFactory } from 'modules/axios'
import actions from './create.actions'
import { findCategories, findSubcategories, findTicketTypes, createTicket } from './api'
import * as constants from './constants'

export function* findCategoriesSaga({ payload: { ticketTypeId } }) {
  try {
    const categories = yield call(findCategories, ticketTypeId)

    yield put(actions.findCategoriesSucceeded(categories))
  } catch (e) {
    yield call(logError, { e: new Error(formatErrorMessage(e)), ticketTypeId })
    const error = axiosFactory.buildError(e)
    yield put(actions.findCategoriesFailed(error))
  }
}

export function* findSubcategoriesSaga({ payload: { id } }) {
  try {
    const subcategories = yield call(findSubcategories, id)

    yield put(actions.findSubcategoriesSucceeded(subcategories))
  } catch (e) {
    yield call(logError, { e: new Error(formatErrorMessage(e)), id })
    const error = axiosFactory.buildError(e)
    yield put(actions.findSubcategoriesFailed(error))
  }
}

export function* findTicketTypesSaga() {
  try {
    const types = yield call(findTicketTypes)

    yield put(actions.findTicketTypesSucceeded(types))
  } catch (e) {
    yield call(logError, new Error(formatErrorMessage(e)))
    const error = axiosFactory.buildError(e)
    yield put(actions.findTicketTypesFailed(error))
  }
}

export function* createTicketSaga({ payload: { ticketData } }) {
  try {
    const resp = yield call(createTicket, ticketData)

    yield put(actions.createTicketSucceeded(resp))
  } catch (e) {
    const { message, response, request } = e
    const result = get(response, 'data.result', null)
    const error = result || {
      code: request.status,
      httpCode: request.status,
      info: [
        {
          message,
          errorText: request.statusText,
          errorCode: request.status.code,
        },
      ],
    }
    yield call(logError, { e: new Error(formatErrorMessage(e)), ticketData })
    yield put(actions.createTicketFailed(error))
  }
}

export function* watchFindCategories() {
  yield takeEvery(constants.FIND_CATEGORIES_ATTEMPTED, findCategoriesSaga)
}

export function* watchFindSubcategories() {
  yield takeEvery(constants.FIND_SUBCATEGORIES_ATTEMPTED, findSubcategoriesSaga)
}

export function* watchFindTicketTypes() {
  yield takeEvery(constants.FIND_TICKET_TYPES_ATTEMPTED, findTicketTypesSaga)
}

export function* watchCreateTicket() {
  yield takeEvery(constants.CREATE_TICKET_ATTEMPTED, createTicketSaga)
}
