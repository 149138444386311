import { useSelector } from 'react-redux'
import { get } from 'lodash'

import { permissions, selectCanIDO } from 'modules/Permissions'
import { MULTISIM_LINE } from 'services/customer-360/constants'
import { canModifySubscription } from '../../selectors'

export const useIsEnabledCvmAddDiscountsOrBonus = (subscription, discounts = true) => {
  const disabled = useSelector(state => !canModifySubscription(state, subscription))
  const multisimTariff = get(subscription, 'multisimType') === MULTISIM_LINE

  if (discounts) {
    const permissionsCvmOpsAddSomeDiscounts =
      useSelector(selectCanIDO(permissions.cvm_ops_add_discounts_all.id)) ||
      useSelector(selectCanIDO(permissions.cvm_ops_add_retention_discounts.id))
    const permissionsCvmOpsAddMultisimDiscounts = useSelector(
      selectCanIDO(permissions.cvm_ops_add_multisim_discounts.id),
    )

    return (
      !disabled &&
      ((multisimTariff && permissionsCvmOpsAddMultisimDiscounts) ||
        (!multisimTariff && permissionsCvmOpsAddSomeDiscounts))
    )
  }

  const permissionsCvmOpsAddBundlesAll = useSelector(
    selectCanIDO(permissions.cvm_ops_add_bundles_all.id),
  )

  return !disabled && !multisimTariff && permissionsCvmOpsAddBundlesAll
}
