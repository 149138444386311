import { useSelector } from 'react-redux'
import { get, reduce, head, orderBy, isEmpty } from 'lodash'
import { parseISO } from 'date-fns'
import { getTerminalsFinancials, getFees25WithDiscountFromFinancials } from 'services/subscriptions'

import { isProCustomer } from 'services/customer-360/helpers'
import { getSegment } from 'services/customer-360/selectors'
import {
  getCurrentCustomerTax,
  getCompatibleCurrentDiscounts,
  generateFeeSummary,
} from '../../selectors'

import { FeesItem } from '../../components/FeesItem'

export function getCurrentFeesProps(props) {
  const customerSegment = useSelector(state => getSegment(state))
  const taxNeeded = !isProCustomer(customerSegment)
  const tax = (taxNeeded && useSelector(getCurrentCustomerTax)) || 0
  const multisimData = get(props.subscription, 'multisimData')

  const compatibleSubcriptionDiscounts = useSelector(state =>
    getCompatibleCurrentDiscounts(state, props.subscription, {}),
  )
  const compatibleMultisimDiscounts = head(
    useSelector(state => {
      if (isEmpty(multisimData)) {
        return []
      }
      return multisimData.map(data => getCompatibleCurrentDiscounts(state, data, {}))
    }),
  )
  const compatibleCurrentDiscounts = [
    ...compatibleSubcriptionDiscounts,
    ...(!isEmpty(multisimData) ? compatibleMultisimDiscounts : []),
  ]

  const rawFeeSummary = useSelector(state =>
    generateFeeSummary(state, {
      subscription: props.subscription,
      compatibleCurrentDiscounts,
      tax,
      taxNeeded,
    }),
  )

  const terminalsFinancials = getTerminalsFinancials(props.subscription)
  const totalFees25WithDiscountFromFinancials = reduce(
    terminalsFinancials,
    (acc, financials) => acc + getFees25WithDiscountFromFinancials(financials),
    0,
  )

  const lastFinancials = head(
    orderBy(terminalsFinancials, financials => parseISO(financials.validFor.endDateTime), 'desc'),
  )
  const endDatePayment = get(lastFinancials, 'validFor.endDateTime')

  return {
    ...props,
    ...rawFeeSummary,
    terminalsFinancials,
    terminalsLastPayment: totalFees25WithDiscountFromFinancials,
    endDatePayment,
  }
}

export function CurrentFeesContainer(props) {
  const newProps = getCurrentFeesProps(props)

  return (
    <div className="p-x-12">
      <FeesItem {...newProps} />
    </div>
  )
}
