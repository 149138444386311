export const Billing = {
  ibanNumber: '',
  selectedAddressType: '',
  otherAddress: {
    address: '',
    city: '',
    door: '',
    flat: '',
    number: '',
    otherInfo: '',
    province: '',
    zipCode: '',
    fullAddress: '',
  },
  isPaperInvoice: '',
}
