import PropTypes from 'prop-types'
import LinearProgress from '@material-ui/core/LinearProgress'
import styled from 'styled-components'
import { get } from 'lodash'

const ProgressBar = styled(({ ...rest }) => (
  <LinearProgress
    classes={{
      bar: 'bar',
    }}
    variant="determinate"
    {...rest}
  />
))`
  && {
    border-radius: 8px;
    height: 8px;
    background-color: ${props =>
      props.colorBackground ? props.colorBackground : get(props, 'theme.palette.brand.bck')};
  }
  & .bar {
    opacity: 0.8;
    background-color: ${props =>
      props.colorBar ? props.colorBar : get(props, 'theme.palette.brand.validated')};
  }
`

ProgressBar.propTypes = {
  colorBar: PropTypes.string,
  colorBackground: PropTypes.string,
}

export default ProgressBar
