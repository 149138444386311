import { constants } from './constants'

const fetchAtcAuthToken = orderId => ({
  type: constants.FETCH_ATC_AUTH_TOKEN,
  payload: { orderId },
})

const fetchAtcAuthTokenSuccess = data => ({
  type: constants.FETCH_ATC_AUTH_TOKEN_SUCCESS,
  payload: { data },
})

const fetchAtcAuthTokenFailed = error => ({
  type: constants.FETCH_ATC_AUTH_TOKEN_FAILED,
  payload: { error },
})

export const actions = {
  fetchAtcAuthToken,
  fetchAtcAuthTokenSuccess,
  fetchAtcAuthTokenFailed,
}
