import { put, takeEvery, select, call, take, delay, takeLatest } from 'redux-saga/effects'
import { logError, formatErrorMessage } from 'services/logging'

import { isEmpty } from 'lodash'

import { actions as customer360Actions } from 'services/customer-360/actions'
import { constants as customer360Constants } from 'services/customer-360/constants'
import { selectors as customer360Selectors } from 'services/customer-360/selectors'
import { selectDeviceRenewalAgileTv } from 'services/feature-flag/selectors'

import subscriptionActions from 'services/subscriptions/actions'
import {
  FIND_SUBSCRIPTION_BY_ID_SUCCEEDED,
  FIND_SUBSCRIPTION_BY_ID_FAILED,
} from 'services/subscriptions/constants'

import { selectAccountId } from 'modules/Auth/store/selectors'

import { fetchDeviceSpecifications } from 'modules/Terminals/services/TerminalSpecificationService'

import { fetchCustomerInformation } from 'modules/CustomerInfo/store/customerInfo.actions'

import {
  createNewOrder,
  fetchCheckOrderStatus,
} from 'modules/NewClientSales/services/orders.service'

import { fetchIBAN } from 'modules/Iban/services/iban.service'

import {
  actions as authActions,
  REQUEST_IP_ERROR,
  REQUEST_IP_SUCCESS,
  selectIpAddress,
} from 'modules/Auth/store'

import {
  fetchDeviceRenewalAllowed,
  fetchTvRenewalAllowed,
  fetchDevicesOffers,
  fetchDeviceFinancingConditions,
} from '../services/device-renewal.service'
import { constants } from './device-renewal.constants'

import { generateOrderSelectors, selectBasketData, generateOrder } from '../checkout'

import {
  selectRenewalSubscriptionId,
  selectRenewalFixedLineNumber,
  selectRenewalOrderChannel,
  selectGenerateOrderRetries,
  selectGenerateOrderTaskId,
  selectGenerateOrderStartPollingTime,
  selectIban,
  selectIbanValidationFlag,
} from './device-renewal.selectors'
import {
  fetchDeviceRenewalAllowedSuccess,
  fetchDeviceRenewalAllowedFailed,
  fetchDeviceRenewalAllowed as fetchDeviceRenewalAllowedAction,
  fetchDevicesSuccess,
  fetchDevicesFailed,
  fetchDeviceSpecificationsSuccess,
  fetchDeviceSpecificationsFailed,
  fetchFinancingConditionsSuccess,
  fetchFinancingConditionsError,
  generateOrderSuccess,
  generateOrderFailed,
  startPolling,
  retryPolling,
} from './device-renewal.actions'

export function* retrieveSubscriptionSaga({ payload: { msisdn } }) {
  // Get customer 360 info
  yield put(customer360Actions.findInit())

  const findCustomer360Result = yield take([
    customer360Constants.FIND_SUCCESS,
    customer360Constants.FIND_FAILED,
  ])

  if (findCustomer360Result.type === customer360Constants.FIND_FAILED) {
    return
  }

  // Get subscription info.
  yield put(subscriptionActions.findSubscriptionByMsisdn(msisdn))

  const fetchSubscriptionResult = yield take([
    FIND_SUBSCRIPTION_BY_ID_SUCCEEDED,
    FIND_SUBSCRIPTION_BY_ID_FAILED,
  ])

  if (fetchSubscriptionResult.type === FIND_SUBSCRIPTION_BY_ID_FAILED) {
    return
  }

  // Check is allowed
  const channel = yield select(selectRenewalOrderChannel)

  const subscriptionId = yield select(selectRenewalSubscriptionId)
  const phoneNumber = yield select(selectRenewalFixedLineNumber)
  const iban = yield select(selectIban)
  const isAgileTvEnabled = yield select(selectDeviceRenewalAgileTv)

  const isAllowedTv = isAgileTvEnabled && !!phoneNumber

  yield put(
    fetchDeviceRenewalAllowedAction(
      channel,
      iban,
      msisdn,
      subscriptionId,
      phoneNumber,
      isAllowedTv,
    ),
  )

  const docNumber = yield select(customer360Selectors.getIdentificationId)
  const docType = yield select(customer360Selectors.getIdentificationType)

  yield put(fetchCustomerInformation({ docType, docNumber }))
}

export function* watchRetrieveSubscription() {
  yield takeEvery(constants.RETRIEVE_SUBSCRIPTION, retrieveSubscriptionSaga)
}

function* validateIban(iban) {
  try {
    return yield call(fetchIBAN, iban)
  } catch (e) {
    return {
      error: e.message || 'Unexpected error',
    }
  }
}

function* deviceRenewalAllowedSaga({
  payload: { channel, iban, msisdn, subscriptionId, phoneNumber, shouldValidateTv },
}) {
  try {
    const customerId = yield select(selectAccountId)
    const shouldValidateIban = yield select(selectIbanValidationFlag)

    let validationSuccessPayload = yield call(
      fetchDeviceRenewalAllowed,
      customerId,
      msisdn,
      channel,
      subscriptionId,
      phoneNumber,
    )

    if (shouldValidateTv) {
      const { orderAllowed: tvAllowed } = yield call(
        fetchTvRenewalAllowed,
        customerId,
        msisdn,
        channel,
        subscriptionId,
        phoneNumber,
      )

      validationSuccessPayload = { ...validationSuccessPayload, tvAllowed }
    }

    if (shouldValidateIban) {
      const ibanInformation = yield validateIban(iban)

      validationSuccessPayload = { ...validationSuccessPayload, ibanInformation }
    }

    yield put(fetchDeviceRenewalAllowedSuccess(validationSuccessPayload))
  } catch (e) {
    yield call(logError, new Error(formatErrorMessage(e)))
    yield put(fetchDeviceRenewalAllowedFailed())
  }
}

export function* watchDeviceRenewalAllowed() {
  yield takeEvery(constants.FETCH_DEVICE_RENEWAL_ALLOWED, deviceRenewalAllowedSaga)
}

export function* fetchDevicesSaga({
  payload: {
    customerId,
    customerIdentificationType,
    subscriptionSegment,
    selfEmployed,
    renewalSaleChannel,
    offerCode,
    deviceType,
    subscriptionLineType,
    subscriptionPaymentType,
    tariffType,
  },
}) {
  try {
    const fetchDevicesResponse = yield call(
      fetchDevicesOffers,
      customerId,
      customerIdentificationType,
      subscriptionSegment,
      selfEmployed,
      renewalSaleChannel,
      offerCode,
      deviceType,
      subscriptionLineType,
      subscriptionPaymentType,
      tariffType,
    )

    yield put(fetchDevicesSuccess(fetchDevicesResponse))
  } catch (e) {
    yield call(logError, new Error(formatErrorMessage(e)))
    yield put(fetchDevicesFailed(e))
  }
}

export function* watchFetchDevices() {
  yield takeLatest(constants.FETCH_DEVICE_RENEWAL_DEVICES, fetchDevicesSaga)
}

export function* fetchDevicesSpecificationsSaga({ payload: { id } }) {
  try {
    const data = yield call(fetchDeviceSpecifications, id)

    yield put(fetchDeviceSpecificationsSuccess(id, data))
  } catch (e) {
    yield call(logError, new Error(formatErrorMessage(e)))
    yield put(fetchDeviceSpecificationsFailed(id, e))
  }
}

export function* watchFetchDeviceSpecifications() {
  yield takeEvery(constants.FETCH_DEVICE_SPECIFICATIONS, fetchDevicesSpecificationsSaga)
}

export function* fetchFinancingConditionsSaga({ payload: { msisdn, renewalSaleChannel } }) {
  try {
    const data = yield call(fetchDeviceFinancingConditions, msisdn, renewalSaleChannel)

    yield put(fetchFinancingConditionsSuccess(data))
  } catch (e) {
    yield call(logError, new Error(formatErrorMessage(e)))
    yield put(fetchFinancingConditionsError(e))
  }
}

export function* watchFetchFinancingConditions() {
  yield takeEvery(constants.FETCH_FINANCING_CONDITIONS, fetchFinancingConditionsSaga)
}

export function* generateOrderSaga() {
  let basket = null

  try {
    const ipAddress = yield select(selectIpAddress)
    if (!ipAddress) {
      yield put(authActions.requestIpAddress())

      const scoringIpResult = yield take([REQUEST_IP_SUCCESS, REQUEST_IP_ERROR])

      if (scoringIpResult.type === REQUEST_IP_ERROR) {
        throw new Error('fraud error')
      }
    }

    basket = yield select(selectBasketData)

    const data = yield select(generateOrderSelectors)

    const order = generateOrder(data)

    const checkout = { order, basket }

    const fakeScoringToken = 'no-validation'

    const task = yield call(createNewOrder, fakeScoringToken, checkout)

    yield put(startPolling(task))
  } catch (e) {
    console.warn(e)

    yield call(logError, { e: new Error(formatErrorMessage(e)), basket })
    yield put(generateOrderFailed(e))
  }
}

export function* watchGenerateOrder() {
  yield takeEvery(constants.GENERATE_ORDER, generateOrderSaga)
}

const ERROR_RETRIES = 9
const TIME_POLLING = 3000
const MAX_POLLING_TIME = 90000

export function* pollingSaga() {
  const task = yield select(selectGenerateOrderTaskId)
  const startPollingTime = yield select(selectGenerateOrderStartPollingTime)
  const retries = yield select(selectGenerateOrderRetries)

  try {
    const { status, success_response, ...rest } = yield call(fetchCheckOrderStatus, task)
    if (status === 'finished') {
      if (!isEmpty(success_response)) {
        yield put(generateOrderSuccess(success_response))
      } else {
        yield put(generateOrderFailed(rest.response?.data || rest))
        return
      }
    } else {
      throw rest
    }
  } catch (e) {
    const difference = Date.now() - startPollingTime

    if (difference > MAX_POLLING_TIME || retries > ERROR_RETRIES) {
      yield call(logError, new Error(formatErrorMessage(e)))
      yield put(generateOrderFailed(e.response?.data || e))

      return
    }

    yield delay(TIME_POLLING)
    yield put(retryPolling(retries + 1))
  }
}

export function* watchPolling() {
  yield takeEvery([constants.START_POLLING, constants.RETRY_POLLING], pollingSaga)
}
