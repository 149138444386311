import PropTypes from 'prop-types'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import styled, { withTheme } from 'styled-components'
import { get } from 'lodash'

const StyledCellHeader = styled(TableCell)`
  border: 1px solid #d8d8d8;
  font-weight: 500 !important;
  border-bottom: 1px solid #a0a0a0 !important;
  background-color: ${props => (props.color ? props.color : 'white')};
  color: ${props => get(props, 'theme.palette.global.gray_dark')};
`

const SimpleTable = props => {
  const { headers, color, children } = props
  return (
    <Table>
      <TableHead>
        <TableRow>
          {headers.map(header => (
            <StyledCellHeader key={header.key} color={color}>
              {header.value}
            </StyledCellHeader>
          ))}
          <StyledCellHeader id="icons" color={color} />
        </TableRow>
      </TableHead>
      <TableBody>{children}</TableBody>
    </Table>
  )
}

SimpleTable.propTypes = {
  headers: PropTypes.array.isRequired,
  children: PropTypes.any.isRequired,
  color: PropTypes.string,
}

export default withTheme(SimpleTable)
