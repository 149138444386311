import PropTypes from 'prop-types'
import { HTMLTemplate } from 'modules/ui/components/HTMLTemplate/HTMLTemplate'

import { Step } from './Step'
import RouterSteps from './RouterSteps'

const TelefonicaTechnicianStep = ({ title, description }) => (
  <Step
    title={title}
    description={<HTMLTemplate html={description} data-hook="telefonica_technician_step" />}
  />
)
TelefonicaTechnicianStep.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
}

function getTechnicianTelefonica(nextStepsTexts, is2p) {
  const description = is2p ? 'description_2p' : 'description'
  return (
    <TelefonicaTechnicianStep
      title={nextStepsTexts.technician_telefonica.title}
      description={nextStepsTexts.technician_telefonica[description]}
    />
  )
}

function NextStepsADSL({ portability, isPortabilityFromFtth, nextStepsTexts, is2p }) {
  if (!portability) {
    return (
      <>
        {getTechnicianTelefonica(nextStepsTexts, is2p)}
        <RouterSteps
          shipmentTitle={nextStepsTexts.router_shipment.title}
          shipmentDescription={nextStepsTexts.router_shipment.description}
        />
      </>
    )
  }
  if (isPortabilityFromFtth) {
    return (
      <>
        {getTechnicianTelefonica(nextStepsTexts, is2p)}
        <RouterSteps
          shipmentTitle={nextStepsTexts.router_shipment.title}
          shipmentDescription={nextStepsTexts.router_shipment.description}
          portaTitle={nextStepsTexts.porta_router.title}
          portaDescription={nextStepsTexts.porta_router.description}
        />
      </>
    )
  }
  return (
    <RouterSteps
      shipmentTitle={nextStepsTexts.router_shipment.title}
      shipmentDescription={nextStepsTexts.router_shipment.description}
      portaTitle={nextStepsTexts.porta_router.title}
      portaDescription={nextStepsTexts.porta_router.description}
    />
  )
}

NextStepsADSL.propTypes = {
  nextStepsTexts: PropTypes.object,
  portability: PropTypes.bool.isRequired,
  isPortabilityFromFtth: PropTypes.bool.isRequired,
  is2p: PropTypes.bool.isRequired,
}

export default NextStepsADSL
