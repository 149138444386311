export const quota25DaysLowerLimit = 10
export const quota25DaysUpperLimit = 30
export const penaltyDaysLowerLimit = 60
export const penaltyDaysUpperLimit = 180

export const FEES_DECISION_KEEP_FINANCING = 'keepFinancing'
export const FEES_DECISION_SETTLE_PAYMENT_NOW = 'settlePaymentNow'

export const FINAL_PAYMENT_DECISION_FINANCING = 'financing'
export const FINAL_PAYMENT_DECISION_SINGLE_PAYMENT = 'singlePayment'

export const DECISIONS_ALLOWED_AGENT_PROFILES = ['RETENCION', 'FIDELIZACION', 'XSELLING']
