import { useState } from 'react'
import PropTypes from 'prop-types'

import { Modal, ButtonLink } from 'components/ui'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
  modalDescription: {
    lineHeight: '24px',
    whiteSpace: 'pre-line',
  },
  iconButton: {
    height: '100%',
    padding: '0 0 0 10px',
  },
})

export function MainLineCancellationInfoModal({ description, name, disabled }) {
  const [isOpen, setIsOpen] = useState(false)
  const classes = useStyles()
  return (
    <>
      <ButtonLink
        type="primary"
        iconType="info"
        onClick={() => setIsOpen(true)}
        disabled={!name || disabled}
        dataHook="mainlineCancellation.infoButton"
        className={['button-link', classes.iconButton].join(' ')}
      />
      <Modal
        data-hook="mainline-cancellation-info-modal"
        isOpen={isOpen}
        type="default"
        onClose={() => setIsOpen(false)}>
        <span className={classes.modalDescription} data-hook="info-modal-content">
          {description}
        </span>
      </Modal>
    </>
  )
}

MainLineCancellationInfoModal.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
  disabled: PropTypes.bool,
}
