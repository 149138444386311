import { createSelector, createStructuredSelector } from 'reselect'
import { filter, chain } from 'lodash'
import { hasNebaOrVula } from 'modules/Coverage'
import { isDuoTariffId } from 'modules/tariffs/store-apigee/tariffs-apigee.helpers'
import { constants as customer360Constants } from 'services/customer-360/constants'

import {
  selectLines,
  getOrderId,
  getPenalties,
  selectOrdersAdditionalLines,
  getFullNameAndIdentificationIdFromOrdersCustomer,
  selectIsOrderTypeElFijo,
  selectTerritoryOwnerOrder,
  selectInstallationPointProvider,
  selectOrdersCancellationInfo,
  selectOrdersCancellationIsLoading,
  selectOrdersCancellationError,
  selectOrdersCancellationResult,
  selectOrdersCanceled,
} from 'modules/orders'
import { CANCELLATION_FLOW_TOGETHER } from 'modules/orders/constants'

import { selectContentfulResource } from 'modules/contentful'
import { getFormattedRetentionOptions } from './CancelOrdersContainer.helpers'

const selectCancelOptions = selectContentfulResource('cancellationReasonOptions')

const selectRetainOptions = createSelector(
  selectContentfulResource('retentionReasonOptions'),
  resource => getFormattedRetentionOptions(resource),
)

const selectIsNeba = createSelector(
  selectTerritoryOwnerOrder,
  hasNebaOrVula,
)

const selectHasExtraCancellation = createSelector(
  selectIsNeba,
  selectInstallationPointProvider,
  (hasNeba, installationProvider) => hasNeba && installationProvider === 'MM',
)

const selectAvailableCancelOptions = createSelector(
  selectCancelOptions,
  selectHasExtraCancellation,
  (options, hasExtraCancellation) =>
    hasExtraCancellation ? options : filter(options, elem => elem.value !== '10'),
)

const getLineTypeLabel = (type, operator) => {
  let value = 'Alta nueva'

  if (type !== 'new') {
    value = `Portabilidad${operator ? ` (${operator})` : ''}`
  }

  return value
}

const CONVERGENT_ORDER_PARTIAL_CLOSED = '62'
const CONVERGENT_ORDER_CLOSED = '3'

const selectOrders = createSelector(
  [getOrderId, selectLines, selectOrdersAdditionalLines, selectOrdersCancellationInfo],
  (orderId, mainLines, additionalLines, cancellationInfo = []) => {
    if (!additionalLines) {
      return null
    }
    const mainAdditionalLine = additionalLines.find(additionalLine => additionalLine.main) || {}

    const [{ tariffName: mainLineTariffName }] = mainLines

    const fixedStatus = chain(mainLines)
      .find({ category: 'fixed' })
      .get('fixedStatus')
      .value()

    const mainLineCancellationInfo = cancellationInfo.find(order => order.orderId === orderId) || {}

    const cancellationFlowIsTogether = mainLineCancellationInfo.flow === CANCELLATION_FLOW_TOGETHER

    const orders = [
      {
        id: orderId,
        tariffName: mainLineTariffName,
        main: true,
        penalty: !cancellationFlowIsTogether ? mainLineCancellationInfo.penalty || 0 : null,
        mnpTime: mainLineCancellationInfo.mnpTime,
        mainAdditionalLineCategory: mainAdditionalLine?.category,
        isCancellable: cancellationFlowIsTogether || mainLineCancellationInfo.cancellable,
        isClosed:
          fixedStatus === CONVERGENT_ORDER_PARTIAL_CLOSED ||
          fixedStatus === CONVERGENT_ORDER_CLOSED,
        lines: mainLines.map(line => {
          const { v3, mobileLineType, fixedLineType, fixedLineOperator } = mainAdditionalLine
          const { category, id } = line
          const lineTypeLabelProps =
            category === 'fixed' ? [fixedLineType, fixedLineOperator] : [mobileLineType]

          const isCancellable =
            category === 'fixed'
              ? mainLineCancellationInfo.fixedCancellable
              : mainLineCancellationInfo.mobileCancellable

          return {
            id,
            lineType: customer360Constants.MAIN_LINE,
            type: getLineTypeLabel(...lineTypeLabelProps),
            category,
            v3,
            isCancellable: cancellationFlowIsTogether ? true : isCancellable,
          }
        }),
      },
    ]

    const childOrders = additionalLines
      .filter(p => !p.main)
      .map(line => {
        const { penalty, mobileCancellable, mnpTime } =
          cancellationInfo.find(order => order.orderId === line.orderId) || {}

        const isCancellable = cancellationFlowIsTogether || mobileCancellable

        return {
          id: line.orderId,
          tariffName: line.commercialName,
          main: false,
          penalty: !cancellationFlowIsTogether ? penalty || 0 : null,
          mnpTime,
          isCancellable,
          lines: [
            {
              id: line.phoneNumber,
              type: getLineTypeLabel(line.mobileLineType, null),
              lineType: isDuoTariffId(line.tariff)
                ? customer360Constants.EXTRA_LINE
                : customer360Constants.ADDITIONAL_LINE,
              category: 'mobile',
              v3: line.v3,
              isCancellable,
            },
          ],
        }
      })

    orders.push(...childOrders)

    return orders
  },
)

const selectHasError = createSelector(
  selectOrdersCancellationError,
  cancelError => !!cancelError,
)

export const selectOrderCancellationData = createStructuredSelector({
  cancelOptions: selectAvailableCancelOptions,
  retainOptions: selectRetainOptions,
  orderId: getOrderId,
  personalData: getFullNameAndIdentificationIdFromOrdersCustomer,
  penalties: getPenalties,
  isElFijoCancellation: selectIsOrderTypeElFijo,
  orders: selectOrders,
  isSaving: selectOrdersCancellationIsLoading,
  hasError: selectHasError,
  canceledOrders: selectOrdersCanceled,
  isSuccess: selectOrdersCancellationResult,
})
