import PropTypes from 'prop-types'

import { useSelector } from 'react-redux'

import { makeStyles } from '@material-ui/styles'
import { Box, Icon, Typography } from '@material-ui/core'

import { ICONS } from 'utils/icons'
import { TextStyles } from 'utils/text'

import { selectAgentProfile } from 'modules/Auth/store/index'
import { HeaderLogoutModal } from './HeaderLogoutModal.component'

const useStyle = makeStyles({
  'header-profile': {
    '& .profile-name': {
      maxWidth: '200px',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },

    '& .profile-pic img': {
      borderRadius: '50%',
    },
  },
})
export function HeaderProfile({ isD2D, onClickPowerButton }) {
  const classes = useStyle()

  const { fullName, avatar } = useSelector(selectAgentProfile)

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="flex-start"
      px="20px"
      className={classes['header-profile']}>
      <div className="profile-name">
        <Typography {...TextStyles.title2Dark()}>{fullName}</Typography>
      </div>
      <div className="profile-pic p-x-12">
        <img src={avatar} alt={fullName} width="36px" height="36px" />
      </div>
      {isD2D ? (
        <HeaderLogoutModal onClickPowerButton={onClickPowerButton} />
      ) : (
        <Icon>{ICONS.expandArrow}</Icon>
      )}
    </Box>
  )
}

HeaderProfile.defaultProps = {
  onClickPowerButton: () => {},
}

HeaderProfile.propTypes = {
  onClickPowerButton: PropTypes.func,
  isD2D: PropTypes.bool,
}
