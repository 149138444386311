import { createStructuredSelector } from 'reselect'
import {
  selectDeviceSpecificationsIsLoading,
  selectDeviceSpecificationsData,
  selectDeviceSpecificationsError,
} from '../../store/device-renewal.selectors'

export const deviceSpecificationsSelectors = id =>
  createStructuredSelector({
    specifications: selectDeviceSpecificationsData(id),
    hasError: selectDeviceSpecificationsError(id),
    isLoading: selectDeviceSpecificationsIsLoading(id),
  })
