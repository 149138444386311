import { useState } from 'react'
import PropTypes from 'prop-types'

import { Typography, Icon } from '@material-ui/core'

import { TextStyles } from 'utils/text'
import { ICONS } from 'utils/icons'

import { ButtonLink, Modal } from 'components/ui'
import { Badge } from 'modules/ui/components/Badge'
import { Popover } from 'modules/ui'
import { formatFee } from 'utils'
import { segsToSegsOrMinsLiteral } from 'utils/formatting/durations'

import styled from 'styled-components'
import { get, concat, sortBy, last, isEmpty, upperFirst } from 'lodash'
import { format, isValid, parseISO, differenceInMilliseconds } from 'date-fns'
import { formatDateDDMMYY, format_dd_MM_yy_HHmm_h } from 'utils/formatting/dates'
import { formatDistanceFromMs_HsMins_ES } from 'utils/durations'

import { getBarringName, translateBarringStatusNumber } from 'modules/barrings'
import { canIViewAdditionalInfoActivityRegister } from 'modules/vista-360/helpers/permissions.helper'
import { SVAS_TYPES_CODE_TEXT, SVA_HOMEGO_CODE } from 'modules/offers-configuration/constants'
import { TypificationDetail } from '../TypificationDetail/TypificationDetail'

import { LivePersonChat } from '../LivePersonChat'
import { ChatStatusBadge } from '../LivePersonChat/ChatStatusBadge'

import { VoiceCalls } from '../VoiceCalls'
import { VoiceCallsStatusBadge } from '../VoiceCalls/VoiceCallsStatusBadge'

function formatLiteralHour(date) {
  return isValid(new Date(date)) ? `${format(new Date(date), 'HH:mm')} h` : ''
}

const iconTypeActivityMap = {
  logistic: {
    icon: 'delivery',
    literal: 'Logística',
  },
  typification: {
    icon: 'formatLeft',
    literal: 'Tipificación',
  },
  typificationIframe: {
    icon: 'formatLeft',
    literal: 'Tipificación Iframe',
  },
  memo: {
    icon: 'description',
    literal: 'Memos',
  },
  visits: {
    icon: 'build',
    literal: 'Visita técnico',
  },
  sales: {
    icon: 'work',
    literal: 'Ventas',
  },
  communication: {
    icon: 'smsTipification',
    literal: 'Live Person - SMS',
  },
  voiceCalls: {
    literal: 'Llamadas de voz',
  },
  compensation: {
    icon: 'receipt',
    literal: 'Compensación',
  },
  HOMEGO: {
    icon: 'linked_camera',
    literal: SVAS_TYPES_CODE_TEXT[SVA_HOMEGO_CODE],
  },
  SMS: {
    icon: 'receipt',
    literal: 'SMS YOIGO',
  },
  'Atención cliente': {
    icon: 'receipt',
    literal: 'Atención cliente',
  },
  Comerciales: {
    icon: 'receipt',
    literal: 'Comerciales',
  },
  Contrato: {
    icon: 'receipt',
    literal: 'Contrato',
  },
  'Incidencias masivas': {
    icon: 'receipt',
    literal: 'Incidencias masivas',
  },
  Roaming: {
    icon: 'receipt',
    literal: 'Roaming',
  },
  Otros: {
    icon: 'receipt',
    literal: 'Otros',
  },
}

export const ActivityItem = styled(props => {
  const [isCommunicationShown, setIsDetailCommunicationShown] = useState(false)
  const [popoverTarget, setPopoverTarget] = useState(null)
  const { data, className } = props
  const activityInfo = data?.isIframeTypification ? iconTypeActivityMap.typificationIframe : iconTypeActivityMap[data.type]
  const subActivityInfo = iconTypeActivityMap[data.subType]

  const [isOpenTypificationDetail, setIsOpenTypificationDetail] = useState(false)

  const canViewAdditionalInfo = canIViewAdditionalInfoActivityRegister()

  function openTypificationDetail() {
    setIsOpenTypificationDetail(true)
  }

  const [isOpenVoiceCalls, setIsOpenVoiceCalls] = useState(false)
  const [isOpenLivePersonChat, setIsOpenLivePersonChat] = useState(false)

  function openLivePersonChat() {
    setIsOpenLivePersonChat(true)
  }

  function openVoiceCalls() {
    setIsOpenVoiceCalls(true)
  }

  const communicationAllMsgs = concat(
    get(data, 'communication.messages', []),
    get(data, 'communication.transfers', []),
  )
  const lastCommunicationDateStr = get(
    last(sortBy(communicationAllMsgs, ele => parseISO(ele.date))),
    'date',
  )
  const lastCommunicationDate = lastCommunicationDateStr ? parseISO(lastCommunicationDateStr) : null
  const dataType = get(data, 'type')
  const dataSubType = get(data, 'subType')
  const dataSfid = get(data, 'sfid')
  const isDataTypeCommunication = dataType === 'communication'

  let communicationDurationStr = ''
  if (isDataTypeCommunication) {
    const startDate = parseISO(get(data, 'date'))
    const endDate = !(isEmpty(get(data, 'endDate')) || get(data, 'endDate') === 'undefined')
      ? parseISO(get(data, 'endDate'))
      : Date.now()
    communicationDurationStr = formatDistanceFromMs_HsMins_ES(
      differenceInMilliseconds(endDate, startDate),
    )
  }

  const getSfidToTitle = () => {
    const sfidQvantelRegister = dataSfid
    const sfid = sfidQvantelRegister.split('/')[0]
    const sfidWithoutPrefix = sfid.replace('VISTA_', '')

    return sfidWithoutPrefix ? ` | ${sfidWithoutPrefix}` : ''
  }

  const getSvaTitle = () =>
    SVAS_TYPES_CODE_TEXT[dataSubType] ? SVAS_TYPES_CODE_TEXT[dataSubType] : ''

  const barrings = get(data, 'barrings')

  const deliveryStatus = get(data, 'deliveryStatus')

  const subTypeIncludeAtcComercRoamingOtros = [
    'Atención cliente',
    'Comerciales',
    'Contrato',
    'Incidencias masivas',
    'Roaming',
    'Otros',
  ].includes(dataSubType)

  const getIconTitle = () => {
    let activityIcon = get(activityInfo, 'icon')
    if (['voiceCalls', 'SVA'].includes(dataType)) {
      activityIcon =
        dataSubType === SVA_HOMEGO_CODE ? get(subActivityInfo, 'icon') : get(dataSubType, 'icon')
    }
    return activityIcon
  }

  return (
    <div className={className} data-hook={`AvctivityItem-${dataType}`}>
      <li className="activity">
        <div className="icon-wrapper">
          <Icon className="icon">{ICONS[getIconTitle()]}</Icon>
        </div>
        <div className="left-wrapper">
          <div className="header-wrapper">
            <Typography {...TextStyles.labelDark()}>{get(data, 'phone')}</Typography>
            {deliveryStatus && (
              <Badge
                className="status-badge"
                strong
                type="blocked"
                value={{ label: deliveryStatus }}
              />
            )}
          </div>

          {!['voiceCalls'].includes(dataType) && (
            <Typography
              {...TextStyles.labelDark({
                className: 'type-activity-item',
              })}>
              {!['voiceCalls', 'SMS'].includes(dataType) &&
                !subTypeIncludeAtcComercRoamingOtros &&
                get(activityInfo, 'literal')}

              {['SMS'].includes(dataType) &&
                !['Otros'].includes(dataSubType) &&
                `${dataType} ${get(data, 'senderInfo')} | ${dataSubType}`}

              {['SMS'].includes(dataType) &&
                ['Otros'].includes(dataSubType) &&
                `${dataType} ${get(data, 'senderInfo')} | ${get(data, 'clientId')}`}

              {!['voiceCalls', 'compensation', 'SMS'].includes(dataType) &&
                !subTypeIncludeAtcComercRoamingOtros &&
                get(data, 'channel') &&
                ` | ${get(data, 'channel')} | ${dataSfid}  `}

              {dataType === 'compensation' &&
                ` ${upperFirst(get(data, 'subType', ''))} | ${dataSfid}`}

              {isDataTypeCommunication &&
                (get(data, 'communication.latestAgentFullName') !== 'NA' ? (
                  <ButtonLink
                    onMouseEnter={ev => {
                      setIsDetailCommunicationShown(true)
                      setPopoverTarget(ev.currentTarget)
                    }}
                    onMouseLeave={() => {
                      setIsDetailCommunicationShown(false)
                      setPopoverTarget(null)
                    }}
                    className="button-with-popover">
                    {get(data, 'communication.latestAgentFullName')}
                    {isDataTypeCommunication && isCommunicationShown && (
                      <Popover
                        className={`${className} popover`}
                        hasArrow
                        popoverTarget={popoverTarget}>
                        <Typography
                          {...TextStyles.labelDark({
                            className: 'boldable',
                          })}>
                          Nickname:{' '}
                          <span className="bold">
                            {get(data, 'communication.latestAgentFullName')}
                          </span>
                          Grupo:{' '}
                          <span className="bold">
                            {get(data, 'communication.latestAgentGroupName')}
                          </span>
                        </Typography>
                      </Popover>
                    )}
                  </ButtonLink>
                ) : (
                  ' No asignado'
                ))}

              {dataType === 'memo' && dataSfid && getSfidToTitle()}
              {dataType === 'SVA' && getSvaTitle()}
            </Typography>
          )}

          {isDataTypeCommunication && (
            <>
              <Typography
                {...TextStyles.labelDark({
                  className: 'boldable',
                })}>
                Categoría: <span className="bold">{get(data, 'communication.skill')}</span>
              </Typography>
            </>
          )}

          {['voiceCalls'].includes(dataType) && (
            <>
              <Typography
                {...TextStyles.labelDark({
                  className: 'boldable-with-badge',
                })}>
                <span className="bold">{get(data, 'msisdn')}</span>
                <VoiceCallsStatusBadge status={get(data, 'status')} />
              </Typography>
              <Typography
                {...TextStyles.labelDark({
                  className: 'boldable',
                })}>
                Llamada: <span className="bold">{dataSubType}</span>
              </Typography>
              <Typography
                {...TextStyles.labelDark({
                  className: 'boldable',
                })}>
                Duración llamada:{' '}
                <span className="bold">{segsToSegsOrMinsLiteral(get(data, 'duration'))}</span>
              </Typography>
              <Typography
                {...TextStyles.labelDark({
                  className: 'boldable',
                })}>
                Origen: <span className="bold">{get(data, 'origin')}</span> &gt; Destino:
                <span className="bold"> {get(data, 'destination')}</span>
              </Typography>
              <Typography
                {...TextStyles.labelDark({
                  className: 'boldable',
                })}>
                UCID: <span className="bold">{get(data, 'ucid')}</span>
              </Typography>
            </>
          )}

          {['SMS'].includes(dataType) && subTypeIncludeAtcComercRoamingOtros && (
            <>
              <Typography
                {...TextStyles.labelDark({
                  className: 'boldable',
                })}>
                Contenido: {get(data, 'content')}
              </Typography>
            </>
          )}

          {(dataType === 'typification' || dataType === 'typificationIframe') && (
            <>
              {get(data, 'typification.reason') && (
                <Typography
                  {...TextStyles.labelDark({
                    className: 'boldable',
                  })}>
                  Motivo: <span className="bold">{get(data, 'typification.reason')}</span>
                </Typography>
              )}
              {get(data, 'typification.retained1') && (
                <Typography
                  {...TextStyles.labelDark({
                    className: 'boldable',
                  })}>
                  Resultado: <span className="bold"> {get(data, 'typification.retained1')}</span>
                </Typography>
              )}
            </>
          )}
          {!['voiceCalls', 'typification', 'typificationIframe', 'compensation', 'communication', 'SMS'].includes(
            dataType,
          ) &&
            !subTypeIncludeAtcComercRoamingOtros && (
              <>
                <Typography
                  {...TextStyles.labelDark({
                    className: 'boldable',
                  })}>
                  Descripción: <span className="bold"> {get(data, 'description')}</span>
                </Typography>
              </>
            )}
          {dataType === 'compensation' && (
            <>
              {get(data, 'compensation.id') === 'CPSMSFAT' && (
                <Typography
                  {...TextStyles.labelDark({
                    className: 'boldable',
                  })}>
                  Número de SMS: <span className="bold"> {get(data, 'compensation.amount')}</span>
                </Typography>
              )}
              {get(data, 'compensation.id') !== 'CPSMSFAT' && (
                <Typography
                  {...TextStyles.labelDark({
                    className: 'boldable',
                  })}>
                  Importe:{' '}
                  <span className="bold">
                    {formatFee(get(data, 'compensation.amount'), undefined, false, {
                      noDecimalsIfZero: true,
                    })}
                  </span>
                </Typography>
              )}
              {dataSubType === 'fat' ? (
                <Typography
                  {...TextStyles.labelDark({
                    className: 'boldable',
                  })}>
                  Comentario: <span className="bold"> {get(data, 'detail')}</span>
                </Typography>
              ) : (
                <Typography
                  {...TextStyles.labelDark({
                    className: 'boldable',
                  })}>
                  Id de factura:{' '}
                  <span className="bold"> {get(data.compensation, 'billingId')}</span>
                </Typography>
              )}
            </>
          )}
          {(dataType === 'memo' || dataType === 'logistic') && get(data, 'detail') && (
            <>
              <Typography
                {...TextStyles.labelDark({
                  className: 'boldable',
                })}>
                Detalle: <span className="bold"> {get(data, 'detail')}</span>
              </Typography>
            </>
          )}
          {get(data, 'status') &&
            !['voiceCalls', 'SMS', 'SVA'].includes(dataType) &&
            !subTypeIncludeAtcComercRoamingOtros && (
              <>
                {isDataTypeCommunication ? (
                  <Typography
                    {...TextStyles.labelDark({
                      className: 'boldable',
                    })}>
                    Estado:{' '}
                    <ChatStatusBadge
                      status={get(data, 'status')}
                      msg={
                        get(data, 'status') === 'CLOSE'
                          ? formatDateDDMMYY(parseISO(get(data, 'endDate')))
                          : ''
                      }
                    />
                  </Typography>
                ) : (
                  <Typography
                    {...TextStyles.labelDark({
                      className: 'boldable',
                    })}>
                    Estado: <span className="bold"> {get(data, 'status')}</span>
                  </Typography>
                )}
              </>
            )}

          {isDataTypeCommunication && (
            <>
              <Typography
                {...TextStyles.labelDark({
                  className: 'boldable',
                })}>
                Último mensaje:{' '}
                <span className="bold"> {format_dd_MM_yy_HHmm_h(lastCommunicationDate)}</span>
              </Typography>
              <Typography
                {...TextStyles.labelDark({
                  className: 'boldable',
                })}>
                Duración: <span className="bold"> {communicationDurationStr}</span>
              </Typography>
            </>
          )}

          {dataType === 'memo' && barrings && (
            <div className="list-divider">
              {barrings.map((value, index) => {
                return (
                  <div className="list-divider-child">
                    <Typography
                      {...TextStyles.labelDark({
                        className: 'boldable',
                      })}>
                      Barring {barrings.length > 1 ? index + 1 : ''}:
                      <span className="bold"> {getBarringName(get(value, 'literal'))}</span>
                    </Typography>
                    <Typography
                      {...TextStyles.labelDark({
                        className: 'boldable',
                      })}>
                      Estado barring:
                      <span className="bold">
                        {' '}
                        {translateBarringStatusNumber(get(value, 'activate'))}
                      </span>
                    </Typography>
                  </div>
                )
              })}
            </div>
          )}
        </div>
        <div className="right-wrapper">
          <Typography {...TextStyles.labelDark()}>
            {formatLiteralHour(get(data, 'date'))}
          </Typography>
          <Typography
            {...TextStyles.labelDark({
              className: 'type-activity-item-bold',
            })}>
            {(dataType === 'typification' || dataType === 'typificationIframe') && (
              <ButtonLink
                onClick={() => openTypificationDetail()}
                fullWidth={false}
                disabled={false}
                primary={false}
                type="primary"
                data-hook={`show-more-${dataType}`}>
                Ver más
              </ButtonLink>
            )}
            {['voiceCalls'].includes(dataType) && (
              <ButtonLink
                onClick={() => openVoiceCalls()}
                fullWidth={false}
                disabled={!canViewAdditionalInfo}
                primary={false}
                type="primary"
                data-hook={`show-more-${dataType}`}>
                Ver más
              </ButtonLink>
            )}

            {isDataTypeCommunication && (
              <ButtonLink
                onClick={() => openLivePersonChat()}
                fullWidth={false}
                disabled={false}
                primary={false}
                type="primary"
                data-hook={`show-more-${dataType}`}>
                Ver más
              </ButtonLink>
            )}
          </Typography>
        </div>
        <Modal
          isOpen={isOpenTypificationDetail}
          title="Detalle tipificación"
          onClose={() => setIsOpenTypificationDetail(false)}>
          <TypificationDetail data={data} />
        </Modal>

        <Modal
          isOpen={isOpenLivePersonChat}
          width="928px"
          title={`Live Person SMS | ${get(data, 'communication.skill')}`}
          onClose={() => setIsOpenLivePersonChat(false)}>
          <LivePersonChat data={data} />
        </Modal>

        <Modal
          isOpen={isOpenVoiceCalls}
          width="928px"
          title="Detalle de la llamada"
          onClose={() => setIsOpenVoiceCalls(false)}>
          <VoiceCalls dataVoiceCalls={data} />
        </Modal>
      </li>
    </div>
  )
})`
  .type-activity-item {
    padding: 10px 0;
    color: ${props => get(props, 'theme.palette.global.gray_dark')};
    font-weight: bold;
    font-size: 16px;
  }
  .boldable {
    padding: 5px 0;
  }
  .boldable-with-badge {
    padding: 5px 0;

    & > span:not(.bold) {
      margin-left: 10px;
    }
  }
  .activity {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #d9d9d9;
    padding: 10px 0;
    font-size: 14px;
  }
  .icon-wrapper {
    width: 90px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    height: 39px;
    .icon {
      font-size: 19px;
    }
  }
  .left-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-flow: column;
  }
  .header-wrapper {
    display: flex;
    align-items: center;
  }
  .status-badge {
    margin-left: 10px;
  }
  .right-wrapper {
    width: 100px;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: space-between;
    text-align: right;
  }
  .button-with-popover {
    padding-left: 12px;
    vertical-align: top;
  }

  .list-divider .list-divider-child:not(:first-child) {
    border-top: 1px solid #ccc;
    padding: 2px 0;
  }
`

ActivityItem.propTypes = {
  data: PropTypes.object,
}
