export const addOwnStyles = () => {
  const styles = `
body {
}
/* General */
body {
    word-spacing: 0.05em;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.container {
    max-width: 1100px !important;
    max-height: 610px !important;
    width: 1100px !important;
    height: 610px !important;
}


.main-nav li .glyphicon {
    margin-right: 10px;
}

/* Highlighting rules for nav menu items */
.main-nav li a.active,
.main-nav li a.active:hover,
.main-nav li a.active:focus {
    background-color: #4189C7;
    color: white;
}

.container body-content
{
    padding-top: 0 !important;
}
/* Keep the nav menu independent of scrolling and on top of other items */
.main-nav {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
}


input, select {
    border-radius: 0 !important;
    transition: all .4s !important;
    padding: 12px !important;
    font-size: 15px !important;
}

#coverage-container {
    min-height: 470px;
    padding-top: 10px;
    transition: all linear 0.4s;
}

/* Forms */

.form-group {
    margin-bottom: 12px !important;
    display:block;
    margin-top:0px !important;
}

.form-control {
    height: auto !important;
}

    .form-control:focus {
        border-color: #ccc !important;
        -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(0,0,0,0.3) !important;
        box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(0,0,0,0.3) !important;
    }

.form-error {
    margin: 5px 0 !important;
}

.btn-container {
    margin: 30px 0 15px 0;
    float: right;
}

.btn-form {
    padding: 10px 16px;
    font-size: 15px;
    background: #88c23f;
    color: #fff !important;
    border: none;
    border-radius: 0;
    cursor: pointer;
}

    .btn-form:hover {
        background: #6d9c32;
        border-color: #5a8129;
        text-decoration: none;
        box-shadow: 0 3px 2px rgba(0,0,0,.2);
    }

.btn-primary {
    background: #88c23f !important;
    border-color: transparent !important;
    color: #fff !important;
}

    .btn-primary:hover {
        background: #6d9c32 !important;
        border-color: #5a8129 !important;
        box-shadow: 0 3px 2px rgba(0,0,0,.2);
    }

    .btn-form, .btn-default {
        border-radius: 18px;
        border: solid 1px #a9a8da;
        padding: 10px;
        width: auto;
        text-transform: uppercase;
        font-size: 14px;
        color: #6462cb;
        font-weight: bold;
        margin-top: 0px;
        cursor: pointer;
        margin-bottom: 0px;
      }

.btn-default.active {
    background: rgb(170, 170, 170) !important;
    border-color: transparent !important;
    color: #fff !important;
}

    .btn-default.active:hover {
        background: rgb(136, 136, 136) !important;
        border-color: #777 !important;
        box-shadow: 0 3px 2px rgba(0,0,0,.2);
    }

#btn-back-client-not-exists {
    margin-right: 5px;
}

.panel-body {
    padding-bottom: 0 !important;
}

/* Spinner */

.spinner-overlay {
    position: absolute;
    z-index: 4001;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.35);
    text-align: center;
}

.spinner {
    position: relative;
    z-index: 4002;
    top: calc(50% - 50px);
    display: inline-block;
}

.modal-spinner {
    position: relative;
    top: calc(50% - 50px);
    left: calc(50% - 50px);
    padding: 15px 0 30px 0;
}

/* Search */

.rbt-input {
    border-radius: 0 !important;
    transition: all .4s !important;
    padding: 12px !important;
    font-size: 15px !important;
}

.rbt-input-main {
    padding: 0 !important;
}

.rbt-input-hint {
    color: transparent !important;
}

.rbt .focus {
    border-color: #ccc !important;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(0,0,0,0.3) !important;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(0,0,0,0.3) !important;
}

.info {
    color: #88c23f;
    float: right;
    position: relative;
    margin-top: -30px;
    margin-right: 10px;
    cursor: pointer;
}

    .info:hover {
        color: #6d9c32 !important;
    }

.dropdown-menu > li > a:hover, .dropdown-menu > li > a:focus {
    background-color: rgb(245,245,245) !important;
}

.dropdown-menu > .active > a, .dropdown-menu > .active > a:hover, .dropdown-menu > .active > a:focus {
    background-color: rgb(224,224,224) !important;
    color: #262626 !important;
}

/* Modal */
.card {
    padding: 0 15px;
}

.modal-backdrop.in {
    opacity: 0.35 !important;
}

/*.modal-header {
    border: none !important;
    padding: 15px 15px 0 15px !important;
}*/

.modal-header {
    padding: 15px 20px !important;
    border-bottom: 1px solid #eaedef;
}

    .modal-header .close {
        margin-top: 0px !important;
        line-height: 25px;
    }

        .modal-header .close:focus {
            outline: none;
        }

.modal-btn-container {
    margin: 35px 0 5px 0;
}

.modal-datatables-btn-container {
    padding: 5px 0;
}

    .modal-datatables-btn-container button {
        border-radius: 0 !important;
        margin: 10px 0;
    }

.modal-info {
    padding: 0 15px 15px 15px;
}

    .modal-info h2 {
        padding-top: 15px;
    }

    .modal-info div.modal-info-content {
        padding: 10px 20px 0 20px;
    }

.modal-header-info {
    padding: 5px 15px;
}

.modal-header-info-title {
    color: #5bc0de;
    font-size: 60px;
    line-height: 80px;
    text-align: center;
    width: 80px;
    height: 80px;
    border: 4px solid;
    border-radius: 50%;
    margin: 20px auto auto;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    cursor: default;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
}

    .modal-header-info-title span:first-child {
        color: #46b8da;
    }

/* Datatables */

.modal-body table tbody tr {
    cursor: pointer !important;
}

.modal-title {
    margin: 0;
    line-height: 1.42857143;
}

/* Font-awesome icons */

.fa-thumbs-up {
    padding-right: 5px !important;
}


/* Vertical info */

.vertical-info-container {
    border: 1px solid #ccc;
    padding-top: 18px;
}

.blocked {
    background-color: #eee;
}

/*toastr*/
.toast-title {
    font-weight: 700
}

.toast-message {
    -ms-word-wrap: break-word;
    word-wrap: break-word
}

    .toast-message a, .toast-message label {
        color: #FFF
    }

        .toast-message a:hover {
            color: #CCC;
            text-decoration: none
        }

.toast-close-button {
    position: relative;
    right: -.3em;
    top: -.3em;
    float: right;
    font-size: 20px;
    font-weight: 700;
    color: #FFF;
    -webkit-text-shadow: 0 1px 0 #fff;
    text-shadow: 0 1px 0 #fff;
    opacity: .8;
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
    filter: alpha(opacity=80);
    line-height: 1
}

    .toast-close-button:focus, .toast-close-button:hover {
        color: #000;
        text-decoration: none;
        cursor: pointer;
        opacity: .4;
        -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
        filter: alpha(opacity=40)
    }

.rtl .toast-close-button {
    left: -.3em;
    float: left;
    right: .3em
}

button.toast-close-button {
    padding: 0;
    cursor: pointer;
    background: 0 0;
    border: 0;
    -webkit-appearance: none
}

.toast-top-center {
    top: 0;
    right: 0;
    width: 100%
}

.toast-bottom-center {
    bottom: 0;
    right: 0;
    width: 100%
}

.toast-top-full-width {
    top: 0;
    right: 0;
    width: 100%
}

.toast-bottom-full-width {
    bottom: 0;
    right: 0;
    width: 100%
}

.toast-top-left {
    top: 12px;
    left: 12px
}

.toast-top-right {
    top: 12px;
    right: 12px
}

.toast-bottom-right {
    right: 12px;
    bottom: 12px
}

.toast-bottom-left {
    bottom: 12px;
    left: 12px
}

#toast-container {
    position: fixed;
    z-index: 999999;
    pointer-events: none
}

    #toast-container * {
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box
    }

    #toast-container > div {
        position: relative;
        pointer-events: auto;
        overflow: hidden;
        margin: 0 0 6px;
        padding: 15px 15px 15px 50px;
        width: 300px;
        -moz-border-radius: 3px;
        -webkit-border-radius: 3px;
        border-radius: 3px;
        background-position: 15px center;
        background-repeat: no-repeat;
        -moz-box-shadow: 0 0 12px #999;
        -webkit-box-shadow: 0 0 12px #999;
        box-shadow: 0 0 12px #999;
        color: #FFF;
        opacity: .8;
        -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
        filter: alpha(opacity=80)
    }

        #toast-container > div.rtl {
            direction: rtl;
            padding: 15px 50px 15px 15px;
            background-position: right 15px center
        }

        #toast-container > div:hover {
            -moz-box-shadow: 0 0 12px #000;
            -webkit-box-shadow: 0 0 12px #000;
            box-shadow: 0 0 12px #000;
            opacity: 1;
            -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
            filter: alpha(opacity=100);
            cursor: pointer
        }

    #toast-container > .toast-info {
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGwSURBVEhLtZa9SgNBEMc9sUxxRcoUKSzSWIhXpFMhhYWFhaBg4yPYiWCXZxBLERsLRS3EQkEfwCKdjWJAwSKCgoKCcudv4O5YLrt7EzgXhiU3/4+b2ckmwVjJSpKkQ6wAi4gwhT+z3wRBcEz0yjSseUTrcRyfsHsXmD0AmbHOC9Ii8VImnuXBPglHpQ5wwSVM7sNnTG7Za4JwDdCjxyAiH3nyA2mtaTJufiDZ5dCaqlItILh1NHatfN5skvjx9Z38m69CgzuXmZgVrPIGE763Jx9qKsRozWYw6xOHdER+nn2KkO+Bb+UV5CBN6WC6QtBgbRVozrahAbmm6HtUsgtPC19tFdxXZYBOfkbmFJ1VaHA1VAHjd0pp70oTZzvR+EVrx2Ygfdsq6eu55BHYR8hlcki+n+kERUFG8BrA0BwjeAv2M8WLQBtcy+SD6fNsmnB3AlBLrgTtVW1c2QN4bVWLATaIS60J2Du5y1TiJgjSBvFVZgTmwCU+dAZFoPxGEEs8nyHC9Bwe2GvEJv2WXZb0vjdyFT4Cxk3e/kIqlOGoVLwwPevpYHT+00T+hWwXDf4AJAOUqWcDhbwAAAAASUVORK5CYII=) !important
    }

    #toast-container > .toast-error {
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAHOSURBVEhLrZa/SgNBEMZzh0WKCClSCKaIYOED+AAKeQQLG8HWztLCImBrYadgIdY+gIKNYkBFSwu7CAoqCgkkoGBI/E28PdbLZmeDLgzZzcx83/zZ2SSXC1j9fr+I1Hq93g2yxH4iwM1vkoBWAdxCmpzTxfkN2RcyZNaHFIkSo10+8kgxkXIURV5HGxTmFuc75B2RfQkpxHG8aAgaAFa0tAHqYFfQ7Iwe2yhODk8+J4C7yAoRTWI3w/4klGRgR4lO7Rpn9+gvMyWp+uxFh8+H+ARlgN1nJuJuQAYvNkEnwGFck18Er4q3egEc/oO+mhLdKgRyhdNFiacC0rlOCbhNVz4H9FnAYgDBvU3QIioZlJFLJtsoHYRDfiZoUyIxqCtRpVlANq0EU4dApjrtgezPFad5S19Wgjkc0hNVnuF4HjVA6C7QrSIbylB+oZe3aHgBsqlNqKYH48jXyJKMuAbiyVJ8KzaB3eRc0pg9VwQ4niFryI68qiOi3AbjwdsfnAtk0bCjTLJKr6mrD9g8iq/S/B81hguOMlQTnVyG40wAcjnmgsCNESDrjme7wfftP4P7SP4N3CJZdvzoNyGq2c/HWOXJGsvVg+RA/k2MC/wN6I2YA2Pt8GkAAAAASUVORK5CYII=) !important
    }

    #toast-container > .toast-success {
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADsSURBVEhLY2AYBfQMgf///3P8+/evAIgvA/FsIF+BavYDDWMBGroaSMMBiE8VC7AZDrIFaMFnii3AZTjUgsUUWUDA8OdAH6iQbQEhw4HyGsPEcKBXBIC4ARhex4G4BsjmweU1soIFaGg/WtoFZRIZdEvIMhxkCCjXIVsATV6gFGACs4Rsw0EGgIIH3QJYJgHSARQZDrWAB+jawzgs+Q2UO49D7jnRSRGoEFRILcdmEMWGI0cm0JJ2QpYA1RDvcmzJEWhABhD/pqrL0S0CWuABKgnRki9lLseS7g2AlqwHWQSKH4oKLrILpRGhEQCw2LiRUIa4lwAAAABJRU5ErkJggg==) !important
    }

    #toast-container > .toast-warning {
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGYSURBVEhL5ZSvTsNQFMbXZGICMYGYmJhAQIJAICYQPAACiSDB8AiICQQJT4CqQEwgJvYASAQCiZiYmJhAIBATCARJy+9rTsldd8sKu1M0+dLb057v6/lbq/2rK0mS/TRNj9cWNAKPYIJII7gIxCcQ51cvqID+GIEX8ASG4B1bK5gIZFeQfoJdEXOfgX4QAQg7kH2A65yQ87lyxb27sggkAzAuFhbbg1K2kgCkB1bVwyIR9m2L7PRPIhDUIXgGtyKw575yz3lTNs6X4JXnjV+LKM/m3MydnTbtOKIjtz6VhCBq4vSm3ncdrD2lk0VgUXSVKjVDJXJzijW1RQdsU7F77He8u68koNZTz8Oz5yGa6J3H3lZ0xYgXBK2QymlWWA+RWnYhskLBv2vmE+hBMCtbA7KX5drWyRT/2JsqZ2IvfB9Y4bWDNMFbJRFmC9E74SoS0CqulwjkC0+5bpcV1CZ8NMej4pjy0U+doDQsGyo1hzVJttIjhQ7GnBtRFN1UarUlH8F3xict+HY07rEzoUGPlWcjRFRr4/gChZgc3ZL2d8oAAAAASUVORK5CYII=) !important
    }

    #toast-container.toast-bottom-center > div, #toast-container.toast-top-center > div {
        width: 300px;
        margin-left: auto;
        margin-right: auto
    }

    #toast-container.toast-bottom-full-width > div, #toast-container.toast-top-full-width > div {
        width: 96%;
        margin-left: auto;
        margin-right: auto
    }

.toast {
    background-color: #030303
}

.toast-success {
    background-color: #51A351
}

.toast-error {
    background-color: #BD362F
}

.toast-info {
    background-color: #2F96B4
}

.toast-warning {
    background-color: #F89406
}

.toast-progress {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 4px;
    background-color: #000;
    opacity: .4;
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
    filter: alpha(opacity=40)
}

@media all and (max-width:240px) {
    #toast-container > div {
        padding: 8px 8px 8px 50px;
        width: 11em
    }

        #toast-container > div.rtl {
            padding: 8px 50px 8px 8px
        }

    #toast-container .toast-close-button {
        right: -.2em;
        top: -.2em
    }

    #toast-container .rtl .toast-close-button {
        left: -.2em;
        right: .2em
    }
}

@media all and (min-width:241px) and (max-width:480px) {
    #toast-container > div {
        padding: 8px 8px 8px 50px;
        width: 18em
    }

        #toast-container > div.rtl {
            padding: 8px 50px 8px 8px
        }

    #toast-container .toast-close-button {
        right: -.2em;
        top: -.2em
    }

    #toast-container .rtl .toast-close-button {
        left: -.2em;
        right: .2em
    }
}

@media all and (min-width:481px) and (max-width:768px) {
    #toast-container > div {
        padding: 15px 15px 15px 50px;
        width: 25em
    }

        #toast-container > div.rtl {
            padding: 15px 50px 15px 15px
        }
}

.no-resize {
    resize: none;
}

.modal-footer {
    padding: 15px 20px;
    margin: 15px -20px -15px -20px;
    border-top: 1px solid #eaedef;
    background-color: #fff;
}


.mr-15 {
    margin-right: 15px;
}

#postpone-container label {
    font-weight: normal;
    line-height: 1.4285755000000002em;
    margin-bottom: 3px;
    opacity: 0.7;
    font-size: 11px;
    display: inline-block;
    max-width: 100%;
}

#postpone-container {
    border-bottom: 1px solid #eaedef;
    border-top: 1px solid #eaedef;
}

    #postpone-container .select2-container {
        width: 100% !important;
        box-sizing: border-box;
        display: inline-block;
        margin: 0;
        position: relative;
        vertical-align: middle;
    }

    #postpone-container .form-group {
        margin-bottom: 10px !important;
    }

    #postpone-container .select2-container {
        width: 100% !important;
        box-sizing: border-box;
        display: inline-block;
        margin: 0;
        position: relative;
        vertical-align: middle;
    }

    #postpone-container .select2-container--default .select2-selection--single {
        background-color: #fff;
        border: 1px solid #d7dce0;
        border-radius: 3px;
        height: 34px;
    }

    #postpone-container .select2-container .select2-selection--single {
        box-sizing: border-box;
        cursor: pointer;
        display: block;
        height: 28px;
        user-select: none;
        -webkit-user-select: none;
    }

    #postpone-container .select2-container--default .select2-selection--single .select2-selection__rendered {
        color: #4d5865;
        line-height: 32px;
        padding-left: 12px;
        padding-right: 27px;
        font-size: 12px;
    }

    #postpone-container .select2-container .select2-selection--single .select2-selection__rendered {
        display: block;
        padding-left: 8px;
        padding-right: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    #postpone-container .select2-container--default .select2-selection--single .select2-selection__arrow {
        height: 32px;
        width: 20px;
        right: 7px;
    }

    #postpone-container option {
        color: #4d5865;
        line-height: 32px;
        padding-left: 12px;
        padding-right: 27px;
        font-size: 12px;
    }

    #postpone-container select {
        height: 30px;
        padding-top: 2px !important;
        padding-left: 10px !important;
        padding-bottom: 0px !important;
    }

    #postpone-container textarea.form-control {
        resize: none;
    }

    #postpone-container .select2-box form-group select2-hidden-accessible {
        width: 100% !important;
        font-size: 12px;
    }

    #postpone-container #sel_franja_horaria {
        width: 100% !important;
        font-size: 12px !important;
        color: #4d5865 !important;
        line-height: 32px !important;
        padding-right: 27px !important;
    }

    #postpone-container .unaclase {
        font-size: 100px;
    }

    #postpone-container input {
        font-size: 12px !important;
        padding-right: 27px !important;
        height: 30px !important;
    }

#postpone-footer .btn-primary {
    color: #fff !important;
    background-color: #3fa7d6 !important;
    border-color: #2c9dcf !important;
    font-family: 'Lato', Helvetica, Arial, sans-serif;
    font-weight: 700;
    display: inline-block;
    height: 100%;
    border-radius: 3px !important;
}

#postpone-footer {
    padding-top: 15px;
    padding-right: 15px;
}

#postpone-container .modal-body {
    padding: 15px 20px;
}

/*.modal-body {
    position: relative;
    padding-top: 10px !important;
    padding-bottom: 15px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
}*/

#notification-container p {
    font-family: 'Lato', Helvetica, Arial, sans-serif !important;
    font-size: 12px !important;
    line-height: 1.4 !important;
    color: #686e71 !important;
}

#notification-container button {
    color: #ffffff !important;
    background-color: #3fa7d6 !important;
    border-color: #2c9dcf !important;
}


/*ESTILOS MAS MOVIL*/


/*
    Colors Variables
*/
.text-primary {
    color: #3fa7d6;
}

a.text-primary:hover,
a.text-primary:focus {
    color: #288dba;
}

.text-info {
    color: #45bddc;
}

a.text-info:hover,
a.text-info:focus {
    color: #26a7c8;
}

.text-success {
    color: rgb(50, 180, 50);
}

a.text-success:hover,
a.text-success:focus {
    color: #38bb76;
}

.text-warning {
    color: #f7b267;
}

a.text-warning:hover,
a.text-warning:focus {
    color: #f49937;
}

.text-danger {
    color: rgb(180, 50, 50);
}

a.text-danger:hover,
a.text-danger:focus {
    color: #e93924;
}

.container,
.container-fluid {
    padding-left: 10px;
    padding-right: 10px;
}

    .container.no-paddings,
    .container-fluid.no-paddings {
        padding-left: 0;
        padding-right: 0;
    }

.row {
    margin-right: -10px;
    margin-left: -10px;
}

.col-fluid {
    float: left;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
}

@media (min-width: 769px) {
    .col-fluid {
        padding-right: 310px;
        margin-right: -300px;
    }

        .col-fluid.right {
            padding-left: 310px;
            margin-left: -300px;
        }
}

.col-fixed {
    float: left;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
}

@media (min-width: 769px) {
    .col-fixed {
        width: 300px;
    }
}

.form-fixed {
    position: fixed;
    top: 0px;
}

.form-fixed-results {
    margin-left: 300px;
}

.col-xs,
.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-sm,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-md,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-lg,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12 {
    position: relative;
    min-height: 1px;
    padding-right: 10px;
    padding-left: 10px;
}

.col-right {
    float: right;
}

.header-buttons {
    margin-top: 10px;
}

.btns-inline .btn + .btn {
    margin-bottom: 0;
    margin-left: 5px;
}

.btn-link.disabled,
fieldset[disabled] .btn-link {
    color: #d7dce0 !important;
    pointer-events: none;
}

.btn-loading {
    position: relative;
    overflow: hidden;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
}

    .btn-loading.active {
        pointer-events: none;
    }

    .btn-loading span {
        font-family: 'Lato', Helvetica, Arial, sans-serif;
        font-weight: 700;
        display: inline-block;
        width: 100%;
        height: 100%;
        -webkit-transition: all 0.3s;
        -webkit-backface-visibility: hidden;
        -moz-transition: all 0.3s;
        -moz-backface-visibility: hidden;
        transition: all 0.3s;
        backface-visibility: hidden;
    }

    .btn-loading:before {
        position: absolute;
        width: 100%;
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        transition: all 0.3s;
        padding: 6px 12px;
        line-height: 1.42857143;
    }

.btn-area.active span {
    -webkit-transform: translateX(200%);
    -moz-transform: translateX(200%);
    -ms-transform: translateX(200%);
    transform: translateX(200%);
}

.btn-area:before {
    left: -100%;
    top: 0;
}

.btn-area.active:before {
    left: 0;
    -webkit-animation: spin 3s infinite linear;
    -moz-animation: spin 3s infinite linear;
    -ms-animation: spin 3s infinite linear;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@-ms-keyframes spin {
    from {
        -ms-transform: rotate(0deg);
    }

    to {
        -ms-transform: rotate(360deg);
    }
}

@-moz-keyframes spin {
    from {
        -moz-transform: rotate(0deg);
    }

    to {
        -moz-transform: rotate(360deg);
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}
/*  /////////////////////////////////////////// */
.btn {
    font-weight: normal;
    text-decoration: none;
    outline: none;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    border-radius: 3px;
}

    .btn:focus,
    .btn:active:focus,
    .btn.active:focus,
    .btn.focus,
    .btn:active.focus,
    .btn.active.focus {
        outline: none;
    }

    .btn:hover,
    .btn:focus,
    .btn.focus {
        color: #ffffff;
        text-decoration: none;
    }

    .btn:active,
    .btn.active {
        outline: 0;
        background-image: none;
        box-shadow: inset 0 3px 3px rgba(0, 0, 0, 0.125);
    }

    .btn.disabled,
    .btn[disabled],
    fieldset[disabled] .btn {
        opacity: 0.65;
        box-shadow: none;
    }

a.btn.disabled,
fieldset[disabled] a.btn {
    pointer-events: none;
}

.btn > i.fa + span:not(:empty) {
    padding-left: 8px;
}

.btn-default {
    color: #4d5865;
    background-color: #ffffff;
    border-color: #d7dce0;
}

    .btn-default:focus,
    .btn-default.focus,
    .btn-default:hover {
        color: #4d5865;
        background-color: #f2f5f7;
        border-color: #d7e1e8;
    }

    .btn-default:active,
    .btn-default.active,
    .open > .dropdown-toggle.btn-default {
        color: #4d5865;
        background-color: #f2f5f7;
        border-color: #d7e1e8;
    }

        .btn-default:active:hover,
        .btn-default.active:hover,
        .open > .dropdown-toggle.btn-default:hover,
        .btn-default:active:focus,
        .btn-default.active:focus,
        .open > .dropdown-toggle.btn-default:focus,
        .btn-default:active.focus,
        .btn-default.active.focus,
        .open > .dropdown-toggle.btn-default.focus {
            color: #4d5865;
            background-color: #f2f5f7;
            border-color: #d7e1e8;
        }

    .btn-default:active,
    .btn-default.active,
    .open > .dropdown-toggle.btn-default {
        background-image: none;
    }

    .btn-default.disabled:hover,
    .btn-default[disabled]:hover,
    fieldset[disabled] .btn-default:hover,
    .btn-default.disabled:focus,
    .btn-default[disabled]:focus,
    fieldset[disabled] .btn-default:focus,
    .btn-default.disabled.focus,
    .btn-default[disabled].focus,
    fieldset[disabled] .btn-default.focus {
        background-color: #f2f5f7;
        border-color: #d7e1e8;
    }

    .btn-default .badge {
        color: #f2f5f7;
        background-color: #4d5865;
    }

.btn-primary {
    color: #ffffff;
    background-color: #3fa7d6;
    border-color: #2c9dcf;
}

    .btn-primary:focus,
    .btn-primary.focus {
        color: #ffffff;
        background-color: #288dba;
        border-color: #2686b2;
    }

    .btn-primary:hover {
        color: #ffffff;
        background-color: #288dba;
        border-color: #2686b2;
    }

    .btn-primary:active,
    .btn-primary.active,
    .open > .dropdown-toggle.btn-primary {
        color: #ffffff;
        background-color: #288dba;
        border-color: #2686b2;
    }

        .btn-primary:active:hover,
        .btn-primary.active:hover,
        .open > .dropdown-toggle.btn-primary:hover,
        .btn-primary:active:focus,
        .btn-primary.active:focus,
        .open > .dropdown-toggle.btn-primary:focus,
        .btn-primary:active.focus,
        .btn-primary.active.focus,
        .open > .dropdown-toggle.btn-primary.focus {
            color: #ffffff;
            background-color: #288dba;
            border-color: #2686b2;
        }

    .btn-primary:active,
    .btn-primary.active,
    .open > .dropdown-toggle.btn-primary {
        background-image: none;
    }

    .btn-primary.disabled:hover,
    .btn-primary[disabled]:hover,
    fieldset[disabled] .btn-primary:hover,
    .btn-primary.disabled:focus,
    .btn-primary[disabled]:focus,
    fieldset[disabled] .btn-primary:focus,
    .btn-primary.disabled.focus,
    .btn-primary[disabled].focus,
    fieldset[disabled] .btn-primary.focus {
        background-color: #3fa7d6;
        border-color: #2c9dcf;
    }

    .btn-primary .badge {
        color: #3fa7d6;
        background-color: #ffffff;
    }

.btn-success {
    color: #ffffff;
    background-color: #59cd90;
    border-color: #45c783;
}

    .btn-success:focus,
    .btn-success.focus {
        color: #ffffff;
        background-color: #38bb76;
        border-color: #36b371;
    }

    .btn-success:hover {
        color: #ffffff;
        background-color: #38bb76;
        border-color: #36b371;
    }

    .btn-success:active,
    .btn-success.active,
    .open > .dropdown-toggle.btn-success {
        color: #ffffff;
        background-color: #38bb76;
        border-color: #36b371;
    }

        .btn-success:active:hover,
        .btn-success.active:hover,
        .open > .dropdown-toggle.btn-success:hover,
        .btn-success:active:focus,
        .btn-success.active:focus,
        .open > .dropdown-toggle.btn-success:focus,
        .btn-success:active.focus,
        .btn-success.active.focus,
        .open > .dropdown-toggle.btn-success.focus {
            color: #ffffff;
            background-color: #38bb76;
            border-color: #36b371;
        }

    .btn-success:active,
    .btn-success.active,
    .open > .dropdown-toggle.btn-success {
        background-image: none;
    }

    .btn-success.disabled:hover,
    .btn-success[disabled]:hover,
    fieldset[disabled] .btn-success:hover,
    .btn-success.disabled:focus,
    .btn-success[disabled]:focus,
    fieldset[disabled] .btn-success:focus,
    .btn-success.disabled.focus,
    .btn-success[disabled].focus,
    fieldset[disabled] .btn-success.focus {
        background-color: #59cd90;
        border-color: #45c783;
    }

    .btn-success .badge {
        color: #59cd90;
        background-color: #ffffff;
    }

.btn-info {
    color: #ffffff;
    background-color: #45bddc;
    border-color: #30b5d8;
}

    .btn-info:focus,
    .btn-info.focus {
        color: #ffffff;
        background-color: #26a7c8;
        border-color: #24a0c0;
    }

    .btn-info:hover {
        color: #ffffff;
        background-color: #26a7c8;
        border-color: #24a0c0;
    }

    .btn-info:active,
    .btn-info.active,
    .open > .dropdown-toggle.btn-info {
        color: #ffffff;
        background-color: #26a7c8;
        border-color: #24a0c0;
    }

        .btn-info:active:hover,
        .btn-info.active:hover,
        .open > .dropdown-toggle.btn-info:hover,
        .btn-info:active:focus,
        .btn-info.active:focus,
        .open > .dropdown-toggle.btn-info:focus,
        .btn-info:active.focus,
        .btn-info.active.focus,
        .open > .dropdown-toggle.btn-info.focus {
            color: #ffffff;
            background-color: #26a7c8;
            border-color: #24a0c0;
        }

    .btn-info:active,
    .btn-info.active,
    .open > .dropdown-toggle.btn-info {
        background-image: none;
    }

    .btn-info.disabled:hover,
    .btn-info[disabled]:hover,
    fieldset[disabled] .btn-info:hover,
    .btn-info.disabled:focus,
    .btn-info[disabled]:focus,
    fieldset[disabled] .btn-info:focus,
    .btn-info.disabled.focus,
    .btn-info[disabled].focus,
    fieldset[disabled] .btn-info.focus {
        background-color: #45bddc;
        border-color: #30b5d8;
    }

    .btn-info .badge {
        color: #45bddc;
        background-color: #ffffff;
    }

.btn-warning {
    color: #ffffff;
    background-color: #f7b267;
    border-color: #f6a64f;
}

    .btn-warning:focus,
    .btn-warning.focus {
        color: #ffffff;
        background-color: #f49937;
        border-color: #f4952d;
    }

    .btn-warning:hover {
        color: #ffffff;
        background-color: #f49937;
        border-color: #f4952d;
    }

    .btn-warning:active,
    .btn-warning.active,
    .open > .dropdown-toggle.btn-warning {
        color: #ffffff;
        background-color: #f49937;
        border-color: #f4952d;
    }

        .btn-warning:active:hover,
        .btn-warning.active:hover,
        .open > .dropdown-toggle.btn-warning:hover,
        .btn-warning:active:focus,
        .btn-warning.active:focus,
        .open > .dropdown-toggle.btn-warning:focus,
        .btn-warning:active.focus,
        .btn-warning.active.focus,
        .open > .dropdown-toggle.btn-warning.focus {
            color: #ffffff;
            background-color: #f49937;
            border-color: #f4952d;
        }

    .btn-warning:active,
    .btn-warning.active,
    .open > .dropdown-toggle.btn-warning {
        background-image: none;
    }

    .btn-warning.disabled:hover,
    .btn-warning[disabled]:hover,
    fieldset[disabled] .btn-warning:hover,
    .btn-warning.disabled:focus,
    .btn-warning[disabled]:focus,
    fieldset[disabled] .btn-warning:focus,
    .btn-warning.disabled.focus,
    .btn-warning[disabled].focus,
    fieldset[disabled] .btn-warning.focus {
        background-color: #f7b267;
        border-color: #f6a64f;
    }

    .btn-warning .badge {
        color: #f7b267;
        background-color: #ffffff;
    }

.btn-danger {
    color: #ffffff;
    background-color: #ee6352;
    border-color: #ec4e3b;
}

    .btn-danger:focus,
    .btn-danger.focus {
        color: #ffffff;
        background-color: #e93924;
        border-color: #e9311a;
    }

    .btn-danger:hover {
        color: #ffffff;
        background-color: #e93924;
        border-color: #e9311a;
    }

    .btn-danger:active,
    .btn-danger.active,
    .open > .dropdown-toggle.btn-danger {
        color: #ffffff;
        background-color: #e93924;
        border-color: #e9311a;
    }

        .btn-danger:active:hover,
        .btn-danger.active:hover,
        .open > .dropdown-toggle.btn-danger:hover,
        .btn-danger:active:focus,
        .btn-danger.active:focus,
        .open > .dropdown-toggle.btn-danger:focus,
        .btn-danger:active.focus,
        .btn-danger.active.focus,
        .open > .dropdown-toggle.btn-danger.focus {
            color: #ffffff;
            background-color: #e93924;
            border-color: #e9311a;
        }

    .btn-danger:active,
    .btn-danger.active,
    .open > .dropdown-toggle.btn-danger {
        background-image: none;
    }

    .btn-danger.disabled:hover,
    .btn-danger[disabled]:hover,
    fieldset[disabled] .btn-danger:hover,
    .btn-danger.disabled:focus,
    .btn-danger[disabled]:focus,
    fieldset[disabled] .btn-danger:focus,
    .btn-danger.disabled.focus,
    .btn-danger[disabled].focus,
    fieldset[disabled] .btn-danger.focus {
        background-color: #ee6352;
        border-color: #ec4e3b;
    }

    .btn-danger .badge {
        color: #ee6352;
        background-color: #ffffff;
    }

.btn-purple {
    color: #ffffff;
    background-color: #b45c9b;
    border-color: #b45c9b;
}

    .btn-purple:focus,
    .btn-purple.focus {
        color: #ffffff;
        background-color: #974680;
        border-color: #a14b89;
    }

    .btn-purple:hover {
        color: #ffffff;
        background-color: #974680;
        border-color: #a14b89;
    }

    .btn-purple:active,
    .btn-purple.active,
    .open > .dropdown-toggle.btn-purple {
        color: #ffffff;
        background-color: #974680;
        border-color: #a14b89;
    }

        .btn-purple:active:hover,
        .btn-purple.active:hover,
        .open > .dropdown-toggle.btn-purple:hover,
        .btn-purple:active:focus,
        .btn-purple.active:focus,
        .open > .dropdown-toggle.btn-purple:focus,
        .btn-purple:active.focus,
        .btn-purple.active.focus,
        .open > .dropdown-toggle.btn-purple.focus {
            color: #ffffff;
            background-color: #974680;
            border-color: #a14b89;
        }

    .btn-purple:active,
    .btn-purple.active,
    .open > .dropdown-toggle.btn-purple {
        background-image: none;
    }

    .btn-purple.disabled:hover,
    .btn-purple[disabled]:hover,
    fieldset[disabled] .btn-purple:hover,
    .btn-purple.disabled:focus,
    .btn-purple[disabled]:focus,
    fieldset[disabled] .btn-purple:focus,
    .btn-purple.disabled.focus,
    .btn-purple[disabled].focus,
    fieldset[disabled] .btn-purple.focus {
        background-color: #b45c9b;
        border-color: #b45c9b;
    }

    .btn-purple .badge {
        color: #b45c9b;
        background-color: #ffffff;
    }

.btn-link {
    color: #3ebae8;
    font-weight: normal;
    border-radius: 0;
}

    .btn-link,
    .btn-link:active,
    .btn-link.active,
    .btn-link[disabled],
    fieldset[disabled] .btn-link {
        background-color: transparent;
        box-shadow: none;
    }

        .btn-link,
        .btn-link:hover,
        .btn-link:focus,
        .btn-link:active {
            border-color: transparent;
        }

            .btn-link:hover,
            .btn-link:focus {
                color: #3ebae8;
                text-decoration: underline;
                background-color: transparent;
            }

            .btn-link[disabled]:hover,
            fieldset[disabled] .btn-link:hover,
            .btn-link[disabled]:focus,
            fieldset[disabled] .btn-link:focus {
                color: #ffffff;
                text-decoration: none;
            }

.btn-lg {
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.42857143;
    border-radius: 3px;
}

    .btn-lg > i.fa + span:not(:empty) {
        padding-right: 10px;
    }

.btn-sm {
    padding: 5px 10px;
    font-size: 13px;
    line-height: 18px;
    border-radius: 3px;
}

    .btn-sm > i.fa + span:not(:empty) {
        padding-right: 8px;
    }

.btn-xs {
    padding: 1px 5px;
    font-size: 12px;
    line-height: 1.42857143;
    border-radius: 3px;
    border-radius: 2px;
}

.btn-group > .btn + .btn-xs.dropdown-toggle {
    padding-left: 5px;
    padding-right: 5px;
}

.btn-block {
    display: block;
    width: 100%;
}

    .btn-block + .btn-block {
        margin-top: 5px;
    }

input[type="submit"],
input[type="reset"],
input[type="button"] {
    outline: none;
}

    input[type="submit"].btn-block,
    input[type="reset"].btn-block,
    input[type="button"].btn-block {
        width: 100%;
    }

.btn-group-inline {
    white-space: nowrap;
    font-size: 0;
}

    .btn-group-inline .btn {
        display: inline-block;
        float: none;
    }

label {
    font-weight: normal;
    line-height: 1.4285755000000002em;
    margin-bottom: 3px;
    /*
  opacity: 0.7;
  text-transform: uppercase;
  font-size: 10px;
  */
    opacity: 0.7;
    font-size: 11px;
}

.form-group {
    margin-bottom: 10px;
    position: relative;
}

    .form-group:last-child {
        margin-bottom: 10px !important;
    }

.help-block {
    color: #5d6875;
    padding: 0 13px;
    margin-bottom: 6px;
    font-size: 12px;
}

    .help-block.btn-action {
        padding: 0;
        margin-top: 3px;
        margin-bottom: 12px;
        font-size: 10px;
    }

        .help-block.btn-action a {
            color: #5d6875;
            text-decoration: none;
        }

.form-control {
    font-size: 12px;
}

label {
    font-weight: normal;
}

.form-control {
    color: #4d5865;
    background-image: none;
    border: 1px solid #d7dce0;
    border-radius: 3px;
    box-shadow: none;
    outline: 0;
    padding: 6px 12px 7px;
    height: 34px;
}

    .form-control:focus {
        border-color: #56d0e9;
        outline: 0;
        box-shadow: 0 0 5px #56d0e9;
    }

    .form-control::-moz-placeholder {
        color: #acb6bf;
        opacity: 1;
    }

    .form-control:-ms-input-placeholder {
        color: #acb6bf;
        opacity: 1;
    }

    .form-control::-webkit-input-placeholder {
        color: #acb6bf;
        opacity: 1;
    }

    .form-control[disabled],
    .form-control[readonly],
    fieldset[disabled] .form-control {
        background-color: #f2f5f7;
    }

    .form-control.input-sm {
        height: 30px;
        padding: 4px 10px 5px;
        font-size: 13px;
        line-height: inherit;
        border-radius: 3px;
    }

.has-success .help-block,
.has-success .control-label {
    color: #3c763d;
}

.has-success .form-control {
    border-color: #d6e9c6;
    background-color: #dff0d8;
    color: #3c763d;
    box-shadow: none;
}

    .has-success .form-control:focus {
        border-color: #56d0e9;
        outline: 0;
        box-shadow: 0 0 5px #56d0e9;
    }

    .has-success .form-control::-moz-placeholder {
        color: #aed48e;
        opacity: 1;
    }

    .has-success .form-control:-ms-input-placeholder {
        color: #aed48e;
        opacity: 1;
    }

    .has-success .form-control::-webkit-input-placeholder {
        color: #aed48e;
        opacity: 1;
    }

.has-success .input-group-addon {
    color: #3c763d;
    border-color: #d6e9c6;
    background-color: #dff0d8;
}

.has-success .form-control-feedback {
    color: #3c763d;
}

.has-success .radio label:before,
.has-success .checkbox label:before,
.has-success .radio-inline label:before,
.has-success .checkbox-inline label:before {
    border: 1px solid #d6e9c6;
    background-color: #dff0d8;
}

.has-warning .help-block,
.has-warning .control-label {
    color: #8a6d3b;
}

.has-warning .form-control {
    border-color: #faebcc;
    background-color: #fcf8e3;
    color: #8a6d3b;
    box-shadow: none;
}

    .has-warning .form-control:focus {
        border-color: #56d0e9;
        outline: 0;
        box-shadow: 0 0 5px #56d0e9;
    }

    .has-warning .form-control::-moz-placeholder {
        color: #f2cf87;
        opacity: 1;
    }

    .has-warning .form-control:-ms-input-placeholder {
        color: #f2cf87;
        opacity: 1;
    }

    .has-warning .form-control::-webkit-input-placeholder {
        color: #f2cf87;
        opacity: 1;
    }

.has-warning .input-group-addon {
    color: #8a6d3b;
    border-color: #faebcc;
    background-color: #fcf8e3;
}

.has-warning .form-control-feedback {
    color: #8a6d3b;
}

.has-warning .radio label:before,
.has-warning .checkbox label:before,
.has-warning .radio-inline label:before,
.has-warning .checkbox-inline label:before {
    border: 1px solid #faebcc;
    background-color: #fcf8e3;
}

.has-error .help-block,
.has-error .control-label {
    color: #a94442;
}

.has-error .form-control {
    border-color: #ebccd1;
    background-color: #f2dede;
    color: #a94442;
    box-shadow: none;
}

    .has-error .form-control:focus {
        border-color: #56d0e9;
        outline: 0;
        box-shadow: 0 0 5px #56d0e9;
    }

    .has-error .form-control::-moz-placeholder {
        color: #d595a0;
        opacity: 1;
    }

    .has-error .form-control:-ms-input-placeholder {
        color: #d595a0;
        opacity: 1;
    }

    .has-error .form-control::-webkit-input-placeholder {
        color: #d595a0;
        opacity: 1;
    }

.has-error .input-group-addon {
    color: #a94442;
    border-color: #ebccd1;
    background-color: #f2dede;
}

.has-error .form-control-feedback {
    color: #a94442;
}

.has-error .radio label:before,
.has-error .checkbox label:before,
.has-error .radio-inline label:before,
.has-error .checkbox-inline label:before {
    border: 1px solid #ebccd1;
    background-color: #f2dede;
}

.form-group {
    margin-bottom: 10px;
}

    .form-group:last-child {
        margin-bottom: 0;
    }

.form-horizontal .form-group {
    margin-left: -10px;
    margin-right: -10px;
}

.form-horizontal .control-label {
    text-align: left;
    padding-top: 6px;
}

.form-horizontal .mb {
    margin-bottom: 10px;
}

.form-inline .form-group {
    margin-bottom: 10px;
    margin-right: 5px;
}

.form-inline label {
    margin-right: 5px;
}

.form-inline .btn {
    vertical-align: top;
}

.form-spacious .form-group {
    margin-bottom: 15px;
}

.form-spacious .help-block {
    margin-top: 10px;
    margin-bottom: 0;
}

.input-group-addon {
    color: #4d5865;
    text-align: center;
    background-color: #f2f5f7;
    border: 1px solid #d7dce0;
}

    .input-group-addon .fa {
        width: 15px;
        text-align: center;
    }

.help-block {
    color: #5d6875;
    padding: 0 13px;
    margin-bottom: 6px;
    font-size: 12px;
}

.noresize {
    resize: none;
}

select option[disabled] {
    color: #acb6bf;
}

.dropdown-menu {
    border: 1px solid #d7dce0;
    border-radius: 2px;
}

    .dropdown-menu > li > a,
    .dropdown-menu > li > a:hover,
    .dropdown-menu > li > a:focus {
        text-decoration: none;
        color: #686e71;
        outline: 0;
        cursor: pointer;
    }

    .dropdown-menu > li.active > a,
    .dropdown-menu > li.active > a:hover,
    .dropdown-menu > li.active > a:focus {
        color: #686e71;
        background-color: #f2f5f7;
    }

    .dropdown-menu.contented {
        min-width: auto;
        text-align: center;
    }

        .dropdown-menu.contented > li > a {
            padding: 3px 15px;
        }

.nav-tabs > li.tabs-title,
.nav-pills > li.tabs-title {
    float: right;
}

.nav-tabs > li.right,
.nav-pills > li.right {
    float: right;
}

.nav-tabs > li.left,
.nav-pills > li.left {
    float: left;
}

.nav-tabs > li > a,
.nav-pills > li > a {
    text-decoration: none;
    color: #5d6875;
    padding: 10px 20px;
    line-height: 18px;
}

    .nav-tabs > li > a:focus,
    .nav-pills > li > a:focus,
    .nav-tabs > li > a:hover,
    .nav-pills > li > a:hover {
        color: #5d6875;
        background-color: #eaedef;
        border-color: #d7dce0;
    }

.nav-tabs > li.active > a,
.nav-pills > li.active > a {
    color: #4d5865;
    background: #ffffff;
    border-color: #d7dce0 #d7dce0 transparent;
}

    .nav-tabs > li.active > a:focus,
    .nav-pills > li.active > a:focus,
    .nav-tabs > li.active > a:hover,
    .nav-pills > li.active > a:hover {
        color: #4d5865;
    }

.nav-tabs-inline,
.nav-pills-inline {
    text-align: center;
}

    .nav-tabs-inline > li,
    .nav-pills-inline > li {
        float: none;
        display: inline-block;
        vertical-align: top;
        margin-bottom: 5px;
    }

.nav-pills {
    margin-bottom: 10px;
}

    .nav-pills > li + li {
        margin-left: 4px;
    }

    .nav-pills > li > a {
        border: 1px solid transparent;
    }

    .nav-pills > li.active > a {
        border: 1px solid #d7dce0;
        cursor: default;
    }

        .nav-pills > li.active > a,
        .nav-pills > li.active > a:hover,
        .nav-pills > li.active > a:focus {
            color: #4d5865;
            background-color: #ffffff;
        }

.nav-pills-sm > li + li,
.nav-tabs-sm > li + li {
    margin-left: 2px;
}

.nav-pills-sm > li > a,
.nav-tabs-sm > li > a {
    padding: 5px 10px;
    font-size: 13px;
    border-radius: 3px;
}

    .nav-pills-sm > li > a .badge,
    .nav-tabs-sm > li > a .badge {
        vertical-align: top;
        font-size: 13px;
        line-height: 18px;
        padding: 0 6px;
    }

.tabs-container {
    padding: 20px 20px;
    background: #ffffff;
    border: 1px solid #d7dce0;
    border-top: 0;
    border-radius: 0 0 4px 4px;
}

.nav-pills + .tabs-container {
    border-top: 1px solid #d7dce0;
    border-radius: 4px;
}

.tab-content {
    color: #4d5865;
}

.tab-pane.fade {
    transition-duration: 65ms;
}

.tabs-right > li {
    float: right;
}

    .tabs-right > li.tabs-title {
        float: left;
    }

    .tabs-right > li > a {
        margin-right: 0px;
        margin-left: 2px;
    }

.tabs-right.nav-pills > li > a {
    margin-left: 4px;
}

.tabs-title {
    padding: 10px 20px;
    line-height: 18px;
}

    .tabs-title span {
        color: #4d5865;
        font-size: 15px;
        font-weight: 600;
    }

    .tabs-title small {
        font-weight: 400;
        font-size: 12px;
        padding-left: 5px;
        color: #5d6875;
    }

.nav .open > a,
.nav .open > a:focus,
.nav .open > a:hover {
    color: #5d6875;
    background-color: #eaedef;
    border-color: #d7dce0;
}

.air-tabs .nav-tabs {
    border-bottom: 1px solid #eaedef;
}

    .air-tabs .nav-tabs > li > a {
        padding: 0px 15px 10px;
        border: 0;
        margin-right: 0;
    }

        .air-tabs .nav-tabs > li > a:focus,
        .air-tabs .nav-tabs > li > a:hover,
        .air-tabs .nav-tabs > li.active > a,
        .air-tabs .nav-tabs > li.active > a:focus,
        .air-tabs .nav-tabs > li.active > a:hover {
            color: #4d5865;
            background: transparent;
            border: 0;
            border-bottom: 1px solid #45bddc;
        }

.air-tabs .tabs-container {
    padding: 20px 0;
    border: 0;
}

.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
    padding: 6px 10px;
    font-size: 11px !important;
}

.table > tbody > tr td.time-slot {
    background-color: #e7edf1;
    font-size: 0.8em;
    vertical-align: middle;
    text-align: center;
}

.tabla-min-padding > tbody > tr.zona {
    background-color: #f9fafb;
    font-weight: bold;
    border-width: 0 0 1px 0;
    border-color: #d7dce0;
    border-style: solid;
}

    .tabla-min-padding > tbody > tr.zona td {
        border-width: 0;
    }

.tabla-min-padding > thead > tr > th {
    text-align: center;
    padding: 5px !important;
    font-size: 0.8em !important;
}

.tabla-min-padding > tbody > tr td {
    padding: 5px !important;
    font-size: 0.8em !important;
}

    .tabla-min-padding > tbody > tr td .label {
        font-size: 85%;
        padding: .2em .6em;
    }

.tabla-huecos input {
    width: 85px;
}

.tabla-huecos thead > tr > th {
    text-align: center;
}

.table-hover > tbody > tr:hover > td,
.table-hover > tbody > tr:hover > th {
    cursor: pointer;
}

.fixed-header thead,
.fixed-header tbody,
.fixed-header tr,
.fixed-header td,
.fixed-header th {
    display: block;
}

.fixed-header thead {
    /* para restar el ancho de la barra de scroll */
    margin-right: 15px;
}

    .fixed-header thead th {
        height: 30px;
    }

.fixed-header tbody {
    overflow-y: auto;
    max-height: 960px;
}

    .fixed-header tbody td,
    .fixed-header thead th {
        float: left;
        width: 6.66666667%;
    }

.fixed-header tr:after {
    clear: both;
    content: ' ';
    display: block;
    visibility: hidden;
}

.table tbody tr.backofficeTask_close {
    opacity: 0.5;
}

table.tablesorter thead tr th.tablesorter-header {
    cursor: pointer;
}

    table.tablesorter thead tr th.tablesorter-header.tablesorter-headerDesc {
        /*background-image: url('../images/tables/desc.gif');*/
        background-repeat: no-repeat;
        background-position: center right;
    }

    table.tablesorter thead tr th.tablesorter-header.tablesorter-headerAsc {
        /*background-image: url('../images/tables/asc.gif');*/
        background-repeat: no-repeat;
        background-position: center right;
    }
/**********************************/
.table {
    margin-bottom: 15px;
    border-spacing: 0;
}

    .table > thead > tr > th,
    .table > tbody > tr > th,
    .table > tfoot > tr > th,
    .table > thead > tr > td,
    .table > tbody > tr > td,
    .table > tfoot > tr > td {
        padding: 6px 10px;
        border-top: 1px solid #d7dce0;
        color: #4d5865;
        vertical-align: top;
        font-size: 12px;
    }

    .table > thead > tr > th {
        border-bottom: 1px solid #d7dce0;
        color: #4d5865;
    }

    .table > tbody + tbody {
        border-top: 1px solid #d7dce0;
    }

    .table .table {
        background-color: #ffffff;
    }

    .table.no-border > thead > tr > th,
    .table.no-border > tbody > tr > th,
    .table.no-border > tfoot > tr > th,
    .table.no-border > thead > tr > td,
    .table.no-border > tbody > tr > td,
    .table.no-border > tfoot > tr > td {
        border: 0;
    }

    .table .content-width {
        white-space: nowrap;
        width: 1px;
    }

    .table .checkbox {
        vertical-align: top;
        height: 19px;
    }

    .table .label {
        padding: 4px 6px;
        vertical-align: top;
        display: inline-block;
    }

    .table .btn-xs {
        padding: 4px 5px;
        font-size: 75%;
        font-size: 10.5px;
        line-height: 1;
    }

    .table .btn-group {
        vertical-align: top;
        white-space: nowrap;
        font-size: 0;
    }

        .table .btn-group .btn {
            float: none;
        }

.table-bordered {
    border-collapse: separate;
    border: 1px solid #d7dce0;
    border-radius: 2px;
}

    .table-bordered > thead > tr > th,
    .table-bordered > tbody > tr > th,
    .table-bordered > tfoot > tr > th,
    .table-bordered > thead > tr > td,
    .table-bordered > tbody > tr > td,
    .table-bordered > tfoot > tr > td {
        border-width: 0 1px 1px 0;
        border-color: #d7dce0;
        border-style: solid;
    }

        .table-bordered > thead > tr > th:last-child,
        .table-bordered > tbody > tr > th:last-child,
        .table-bordered > tfoot > tr > th:last-child,
        .table-bordered > thead > tr > td:last-child,
        .table-bordered > tbody > tr > td:last-child,
        .table-bordered > tfoot > tr > td:last-child {
            border-right: 0;
        }

    .table-bordered > thead > tr:last-child > th,
    .table-bordered > tbody > tr:last-child > th,
    .table-bordered > tfoot > tr:last-child > th,
    .table-bordered > thead > tr:last-child > td,
    .table-bordered > tbody > tr:last-child > td,
    .table-bordered > tfoot > tr:last-child > td {
        border-bottom: 0;
    }

    .table-bordered > thead > tr:last-child > th,
    .table-bordered > thead > tr:last-child > td {
        border-bottom: 1px solid #d7dce0;
    }

.table-unbordered > thead > tr > th,
.table-unbordered > tbody > tr > th,
.table-unbordered > tfoot > tr > th,
.table-unbordered > thead > tr > td,
.table-unbordered > tbody > tr > td,
.table-unbordered > tfoot > tr > td {
    border: 0;
}

.table-unbordered > thead > tr > th,
.table-unbordered > thead > tr > td {
    border-bottom: 1px solid #d7dce0;
}

.table-filled tr {
    background-color: #fff;
}

.table-filled thead td,
.table-filled thead th {
    background-color: #e7edf1;
}

.table-responsive {
    margin-bottom: 15px;
}

@media screen and (max-width: 767px) {
    .table-responsive {
        border: 0;
    }

        .table-responsive.table-responsive-bordered {
            border: 1px solid #d7dce0;
            border-radius: 2px;
        }

            .table-responsive.table-responsive-bordered > .dataTables_wrapper > .table-bordered {
                border: 0;
            }

        .table-responsive .table {
            margin-bottom: 0 !important;
        }
}

.table-headless > thead > tr > th,
.table-headless > tbody > tr > th,
.table-headless > tfoot > tr > th,
.table-headless > thead > tr > td,
.table-headless > tbody > tr > td,
.table-headless > tfoot > tr > td {
    border-top: 0;
}

.table-headless > thead > tr:not(:last-child) > th,
.table-headless > tbody > tr:not(:last-child) > th,
.table-headless > tfoot > tr:not(:last-child) > th,
.table-headless > thead > tr:not(:last-child) > td,
.table-headless > tbody > tr:not(:last-child) > td,
.table-headless > tfoot > tr:not(:last-child) > td {
    border-top: 0;
    border-bottom: 1px solid #d7dce0;
}

.table-headless thead {
    display: none;
}

.table-wide > tbody > tr > td:first-child {
    padding-left: 0;
}

.table-wide > tbody > tr > td:last-child {
    padding-right: 0;
}

.table-select tr {
    cursor: pointer;
}

.alert {
    margin-bottom: 15px;
}

    .alert:last-child {
        margin-bottom: 0;
    }

.alert-dismissible .close {
    top: -1px !important;
    outline: 0;
}

    .alert-dismissible .close:hover,
    .alert-dismissible .close:focus {
        color: inherit;
    }

.alert-sm {
    padding: 6px 10px;
    margin-bottom: 10px;
}

    .alert-sm p {
        font-size: 12px;
        line-height: 16px;
    }

.alert-success {
    background-color: #dff0d8;
    border-color: #d6e9c6;
    color: #3c763d;
}

    .alert-success hr {
        border-top-color: #c9e2b3;
    }

    .alert-success .alert-link {
        color: #2b542c;
    }

.alert-info {
    background-color: #d9edf7;
    border-color: #bce8f1;
    color: #31708f;
}

    .alert-info hr {
        border-top-color: #a6e1ec;
    }

    .alert-info .alert-link {
        color: #245269;
    }

.alert-warning {
    background-color: #fcf8e3;
    border-color: #faebcc;
    color: #8a6d3b;
}

    .alert-warning hr {
        border-top-color: #f7e1b5;
    }

    .alert-warning .alert-link {
        color: #66512c;
    }

.alert-danger {
    background-color: #f2dede;
    border-color: #ebccd1;
    color: #a94442;
}

    .alert-danger hr {
        border-top-color: #e4b9c0;
    }

    .alert-danger .alert-link {
        color: #843534;
    }

.label {
    font-weight: normal;
    border: 1px solid transparent;
    color: #ffffff;
    padding: 4px 6px;
    display: inline-block;
}

.label-default {
    background-color: #ffffff;
    color: #4d5865;
    border: 1px solid #d7dce0;
}

    .label-default[href] {
        text-decoration: none;
    }

        .label-default[href]:hover,
        .label-default[href]:focus {
            background-color: #e6e6e6;
        }

a.label-default:hover,
a.label-default:focus {
    color: #4d5865;
}

.label-primary {
    background-color: #3fa7d6;
}

    .label-primary[href] {
        text-decoration: none;
    }

        .label-primary[href]:hover,
        .label-primary[href]:focus {
            background-color: #288dba;
        }

.label-success {
    background-color: #59cd90;
}

    .label-success[href] {
        text-decoration: none;
    }

        .label-success[href]:hover,
        .label-success[href]:focus {
            background-color: #38bb76;
        }

.label-info {
    background-color: #45bddc;
}

    .label-info[href] {
        text-decoration: none;
    }

        .label-info[href]:hover,
        .label-info[href]:focus {
            background-color: #26a7c8;
        }

.label-warning {
    background-color: #f7b267;
}

    .label-warning[href] {
        text-decoration: none;
    }

        .label-warning[href]:hover,
        .label-warning[href]:focus {
            background-color: #f49937;
        }

.label-danger {
    background-color: #ee6352;
}

    .label-danger[href] {
        text-decoration: none;
    }

        .label-danger[href]:hover,
        .label-danger[href]:focus {
            background-color: #e93924;
        }

.badge {
    color: #5d6875;
    font-weight: normal;
    background-color: #f2f5f7;
}

a.badge {
    text-decoration: none;
}

    a.badge:hover,
    a.badge:focus {
        color: #5d6875;
        text-decoration: none;
        background-color: #f2f5f7;
    }

.nav-pills > li:hover > a > .badge {
    background-color: #fff;
}

.list-group-item.active > .badge,
.nav-pills > li.active > a > .badge {
    color: #5d6875;
    background-color: #f2f5f7;
}

.badge-primary {
    background-color: #3fa7d6;
    color: #ffffff;
}

.badge-success {
    background-color: #59cd90;
    color: #ffffff;
}

.badge-info {
    background-color: #45bddc;
    color: #ffffff;
}

.badge-warning {
    background-color: #f7b267;
    color: #ffffff;
}

.badge-danger {
    background-color: #ee6352;
    color: #ffffff;
}

.progress {
    background-color: #f2f5f7;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);
}

.progress-bar {
    color: #ffffff;
    background-color: #45bddc;
    font-weight: bold;
    text-shadow: 1px 1px 1px #555;
}

.progress-bar-mini {
    height: 10px;
    border-radius: 3px;
}

    .progress-bar-mini .progress-bar {
        font-size: 8px;
        line-height: 10px;
    }

.progress-bar-micro {
    height: 6px;
    border-radius: 2px;
}

    .progress-bar-micro .progress-bar {
        font-size: 0;
        line-height: 6px;
    }

.progress-bar-success {
    background-color: #59cd90;
}

.progress-bar-info {
    background-color: #45bddc;
}

.progress-bar-warning {
    background-color: #f7b267;
}

.progress-bar-danger {
    background-color: #ee6352;
}

.tooltip .tooltip-inner {
    background-color: #686e71;
}

.tooltip.top .tooltip-arrow {
    border-top-color: #686e71;
}

.tooltip.top-left .tooltip-arrow {
    border-top-color: #686e71;
}

.tooltip.top-right .tooltip-arrow {
    border-top-color: #686e71;
}

.tooltip.right .tooltip-arrow {
    border-right-color: #686e71;
}

.tooltip.left .tooltip-arrow {
    border-left-color: #686e71;
}

.tooltip.bottom .tooltip-arrow {
    border-bottom-color: #686e71;
}

.tooltip.bottom-left .tooltip-arrow {
    border-bottom-color: #686e71;
}

.tooltip.bottom-right .tooltip-arrow {
    border-bottom-color: #686e71;
}

.popover {
    background-color: #ffffff;
    border: 1px solid #c9cfd5;
    border-radius: 4px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
    padding: 0;
    color: #4d5865;
}

    .popover.top > .arrow {
        border-top-color: #c9cfd5;
    }

        .popover.top > .arrow:after {
            border-top-color: #ffffff;
        }

    .popover.right > .arrow {
        border-right-color: #c9cfd5;
    }

        .popover.right > .arrow:after {
            border-right-color: #ffffff;
        }

    .popover.bottom > .arrow {
        border-bottom-color: #c9cfd5;
    }

        .popover.bottom > .arrow:after {
            border-bottom-color: #f2f5f7;
        }

    .popover.left > .arrow {
        border-left-color: #c9cfd5;
    }

        .popover.left > .arrow:after {
            border-left-color: #ffffff;
        }

.popover-title {
    background-color: #f2f5f7;
    border-bottom: 1px solid #d7dce0;
    border-radius: 3 3 0 0;
}

pre {
    padding: 0;
    border: 1px solid #d7dce0;
    border-radius: 2px;
    margin: 0;
}

.modal-xlg_90 {
    max-width: 90%;
    width: 90%;
}

.modal-xlg,
.modal-xlg-90 {
    max-width: 90%;
    width: 90%;
}

.modal-xlg-75 {
    max-width: 75%;
    width: 75%;
}

.modal.modal-middle {
    white-space: nowrap;
    text-align: center;
}

    .modal.modal-middle:before {
        content: '';
        display: inline-block;
        height: 100%;
        vertical-align: middle;
    }

    .modal.modal-middle .modal-dialog {
        display: inline-block;
        vertical-align: middle;
        white-space: normal;
        text-align: left;
    }

.modal.scale-in .modal-dialog {
    transform: translate(0, 0) scale(1.1, 1.1);
    transition: transform 0.20s;
}

.modal.scale-in.in .modal-dialog {
    transform: scale(1, 1);
}

.modal.scale-out .modal-dialog {
    transform: translate(0, 0) scale(0.9, 0.9);
    transition: transform 0.20s;
}

.modal.scale-out.in .modal-dialog {
    transform: scale(1, 1);
}

.modal-content {
    border: 1px solid #677784;
    border-radius: 3px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.175);
    color: #686e71;
}

.modal-header {
    padding: 15px 20px;
    border-bottom: 1px solid #eaedef;
}

    .modal-header .close {
        margin-top: 0px;
        line-height: 25px;
    }

        .modal-header .close:focus {
            outline: none;
        }

.modal-body {
    padding:20px;
    background: #ffffff;
}

.modal-footer {
    padding: 15px 20px;
    border-top: 1px solid #eaedef;
}

@media (min-width: 768px) {
    .modal-dialog {
        max-width: 600px;
        width: 90%;
    }

    .modal-sm {
        max-width: 300px;
        width: 90%;
    }
}

@media (min-width: 992px) {
    .modal-lg {
        max-width: 900px;
        width: 90%;
    }
}

.modal-xlg_90 {
    max-width: 90%;
    width: 90%;
}

.modal-xlg,
.modal-xlg-90 {
    max-width: 90%;
    width: 90%;
}

.modal-xlg-75 {
    max-width: 75%;
    width: 75%;
}

.thumbnail {
    background-color: #fff;
    border: 1px solid #d7dce0;
    border-radius: 3px;
    padding: 3px;
}

.thumbnail-round {
    border-radius: 50%;
    overflow: hidden;
}

.pagination {
    margin: 0;
    border-radius: 3px;
    vertical-align: top;
}

    .pagination > li > a,
    .pagination > li > span {
        padding: 7px 12px;
        color: #4d5865;
        background-color: #fff;
        border: 1px solid #d7dce0;
        height: 34px;
    }

    .pagination > li:first-child > a,
    .pagination > li:first-child > span {
        margin-left: 0;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
    }

    .pagination > li:last-child > a,
    .pagination > li:last-child > span {
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
    }

    .pagination > li > a:hover,
    .pagination > li > span:hover,
    .pagination > li > a:focus,
    .pagination > li > span:focus {
        color: #4d5865;
        background-color: #f2f5f7;
        border-color: #d7dce0;
        outline: 0;
    }

    .pagination > .active > a,
    .pagination > .active > span,
    .pagination > .active > a:hover,
    .pagination > .active > span:hover,
    .pagination > .active > a:focus,
    .pagination > .active > span:focus {
        color: #4d5865;
        background-color: #f2f5f7;
        border-color: #d7dce0;
    }

    .pagination > .disabled > span,
    .pagination > .disabled > span:hover,
    .pagination > .disabled > span:focus,
    .pagination > .disabled > a,
    .pagination > .disabled > a:hover,
    .pagination > .disabled > a:focus {
        color: #828e9c;
        background-color: #fff;
        border-color: #d7dce0;
    }

.pagination-sm li > a,
.pagination-sm li > span {
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
}

.pagination-xs li > a,
.pagination-xs li > span {
    padding: 3px 8px;
    font-size: 12px;
    line-height: 16px;
}

.pagination-inline {
    white-space: nowrap;
}

    .pagination-inline li {
        display: inline-block;
        vertical-align: top;
    }

.well {
    padding: 15px 20px;
    margin-bottom: 20px;
    background-color: #f2f5f7;
    border: 1px solid #eaedef;
    border-radius: 1px;
    box-shadow: none;
    color: #4d5865;
}

    .well blockquote {
        border-color: #eaedef;
    }

    .well.subtle-bg {
        background-color: #f9fafb;
    }

    .well.info-text {
        padding: 10px 15px;
        margin-top: 5px;
        margin-bottom: 0;
        overflow-y: auto;
        max-height: 130px;
    }

        .well.info-text.remarked {
            background-color: #d9edf7;
            border-color: #bce8f1;
            color: #31708f;
        }

        .well.info-text p {
            line-height: 1.6;
            font-size: 11px;
            padding-bottom: 0;
            margin-bottom: 0;
        }

            .well.info-text p:first-child {
                margin-top: 0;
            }

.panel {
    background-color: #ffffff;
    box-shadow: none;
}

.panel-footer {
    background-color: #ffffff;
    border-top: 1px solid #d7dce0;
}

.panel-default {
    border-color: #d7dce0;
}

    .panel-default > .panel-heading {
        color: #4d5865;
        background-color: #f2f3f5;
        border-color: #d7dce0;
    }

        .panel-default > .panel-heading + .panel-collapse > .panel-body {
            border-top-color: #d7dce0;
        }

        .panel-default > .panel-heading .badge {
            color: #f2f3f5;
            background-color: #4d5865;
        }

    .panel-default > .panel-footer + .panel-collapse > .panel-body {
        border-bottom-color: #d7dce0;
    }

.panel-info {
    border-color: #45bddc;
}

    .panel-info > .panel-heading {
        color: #ffffff;
        background-color: #45bddc;
        border-color: #45bddc;
    }

        .panel-info > .panel-heading + .panel-collapse > .panel-body {
            border-top-color: #45bddc;
        }

        .panel-info > .panel-heading .badge {
            color: #45bddc;
            background-color: #ffffff;
        }

    .panel-info > .panel-footer + .panel-collapse > .panel-body {
        border-bottom-color: #45bddc;
    }

.panel-primary {
    border-color: #3fa7d6;
}

    .panel-primary > .panel-heading {
        color: #ffffff;
        background-color: #3fa7d6;
        border-color: #3fa7d6;
    }

        .panel-primary > .panel-heading + .panel-collapse > .panel-body {
            border-top-color: #3fa7d6;
        }

        .panel-primary > .panel-heading .badge {
            color: #3fa7d6;
            background-color: #ffffff;
        }

    .panel-primary > .panel-footer + .panel-collapse > .panel-body {
        border-bottom-color: #3fa7d6;
    }

.panel-success {
    border-color: #59cd90;
}

    .panel-success > .panel-heading {
        color: #ffffff;
        background-color: #59cd90;
        border-color: #59cd90;
    }

        .panel-success > .panel-heading + .panel-collapse > .panel-body {
            border-top-color: #59cd90;
        }

        .panel-success > .panel-heading .badge {
            color: #59cd90;
            background-color: #ffffff;
        }

    .panel-success > .panel-footer + .panel-collapse > .panel-body {
        border-bottom-color: #59cd90;
    }

.panel-warning {
    border-color: #f7b267;
}

    .panel-warning > .panel-heading {
        color: #ffffff;
        background-color: #f7b267;
        border-color: #f7b267;
    }

        .panel-warning > .panel-heading + .panel-collapse > .panel-body {
            border-top-color: #f7b267;
        }

        .panel-warning > .panel-heading .badge {
            color: #f7b267;
            background-color: #ffffff;
        }

    .panel-warning > .panel-footer + .panel-collapse > .panel-body {
        border-bottom-color: #f7b267;
    }

.panel-danger {
    border-color: #ee6352;
}

    .panel-danger > .panel-heading {
        color: #ffffff;
        background-color: #ee6352;
        border-color: #ee6352;
    }

        .panel-danger > .panel-heading + .panel-collapse > .panel-body {
            border-top-color: #ee6352;
        }

        .panel-danger > .panel-heading .badge {
            color: #ee6352;
            background-color: #ffffff;
        }

    .panel-danger > .panel-footer + .panel-collapse > .panel-body {
        border-bottom-color: #ee6352;
    }

.scrollbar {
    overflow: auto;
}

    .scrollbar > .scroll-element,
    .scrollbar > .scroll-element div {
        background: none;
        border: none;
        margin: 0;
        padding: 0;
        position: absolute;
        z-index: 10;
    }

        .scrollbar > .scroll-element div {
            display: block;
            height: 100%;
            left: 0;
            top: 0;
            width: 100%;
        }

        .scrollbar > .scroll-element .scroll-element_track {
            display: none;
        }

        .scrollbar > .scroll-element .scroll-bar {
            background-color: rgba(0, 0, 0, 0.15);
            display: block;
            -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
            filter: alpha(opacity=0);
            opacity: 0;
            border-radius: 2px;
            transition: opacity 0.2s linear;
        }

        .scrollbar:hover > .scroll-element .scroll-bar,
        .scrollbar > .scroll-element.scroll-draggable .scroll-bar {
            -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
            filter: alpha(opacity=100);
            opacity: 1;
        }

        .scrollbar > .scroll-element.scroll-x {
            bottom: 0px;
            height: 0px;
            left: 0;
            min-width: 100%;
            overflow: visible;
            width: 100%;
        }

        .scrollbar > .scroll-element.scroll-y {
            height: 100%;
            min-height: 100%;
            right: 0px;
            top: 0;
            width: 0px;
        }
        /* scrollbar height/width & offset from container borders */
        .scrollbar > .scroll-element.scroll-x .scroll-bar {
            height: 5px;
            min-width: 10px;
            top: -5px;
        }

        .scrollbar > .scroll-element.scroll-y .scroll-bar {
            left: -5px;
            min-height: 10px;
            width: 5px;
        }

        .scrollbar > .scroll-element.scroll-x .scroll-element_outer {
            left: 2px;
        }

        .scrollbar > .scroll-element.scroll-x .scroll-element_size {
            left: -4px;
        }

        .scrollbar > .scroll-element.scroll-y .scroll-element_outer {
            top: 2px;
        }

        .scrollbar > .scroll-element.scroll-y .scroll-element_size {
            top: -4px;
        }
        /* update scrollbar offset if both scrolls are visible */
        .scrollbar > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_size {
            left: -11px;
        }

        .scrollbar > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_size {
            top: -11px;
        }

.hljs {
    padding: 10px 15px;
}

.checkbox {
    margin-top: 7px;
    margin-bottom: 5px;
}

    .checkbox label {
        user-select: none;
        line-height: 16px;
    }

        .checkbox label:before {
            border: 1px solid #d7dce0;
        }

        .checkbox label:after {
            color: #4d5865;
        }

    .checkbox.condensed {
        margin: 0;
        padding-left: 17px;
        width: 0;
    }

        .checkbox.condensed label::before {
            margin-left: -17px;
            margin-top: 1px;
        }

        .checkbox.condensed label::after {
            margin-left: -17px;
            padding-top: 2px;
        }

.checkbox-primary input[type="checkbox"]:checked + label::before,
.checkbox-primary input[type="radio"]:checked + label::before {
    background-color: #3fa7d6;
    border-color: #3fa7d6;
}

.checkbox-primary input[type="checkbox"]:checked + label::after,
.checkbox-primary input[type="radio"]:checked + label::after {
    color: #fff;
}

.checkbox-danger input[type="checkbox"]:checked + label::before,
.checkbox-danger input[type="radio"]:checked + label::before {
    background-color: #ee6352;
    border-color: #ee6352;
}

.checkbox-danger input[type="checkbox"]:checked + label::after,
.checkbox-danger input[type="radio"]:checked + label::after {
    color: #fff;
}

.checkbox-info input[type="checkbox"]:checked + label::before,
.checkbox-info input[type="radio"]:checked + label::before {
    background-color: #45bddc;
    border-color: #45bddc;
}

.checkbox-info input[type="checkbox"]:checked + label::after,
.checkbox-info input[type="radio"]:checked + label::after {
    color: #fff;
}

.checkbox-warning input[type="checkbox"]:checked + label::before,
.checkbox-warning input[type="radio"]:checked + label::before {
    background-color: #f7b267;
    border-color: #f7b267;
}

.checkbox-warning input[type="checkbox"]:checked + label::after,
.checkbox-warning input[type="radio"]:checked + label::after {
    color: #fff;
}

.checkbox-success input[type="checkbox"]:checked + label::before,
.checkbox-success input[type="radio"]:checked + label::before {
    background-color: #59cd90;
    border-color: #59cd90;
}

.checkbox-success input[type="checkbox"]:checked + label::after,
.checkbox-success input[type="radio"]:checked + label::after {
    color: #fff;
}

.radio {
    margin-top: 7px;
    margin-bottom: 5px;
}

    .radio label {
        user-select: none;
        line-height: 16px;
    }

        .radio label:before {
            border: 1px solid #d7dce0;
        }

        .radio label:after {
            background-color: #4d5865;
            width: 9px;
            height: 9px;
            left: 4px;
            top: 4px;
        }

.radio-inline,
.checkbox-inline {
    margin-right: 10px;
    margin-bottom: 0;
}

    .radio-inline + .radio-inline,
    .radio-inline + .checkbox-inline,
    .checkbox-inline + .radio-inline,
    .checkbox-inline + .checkbox-inline {
        margin-left: 0;
    }

.radio-primary input[type="radio"] + label::after {
    background-color: #3fa7d6;
}

.radio-primary input[type="radio"]:checked + label::before {
    border-color: #3fa7d6;
}

.radio-primary input[type="radio"]:checked + label::after {
    background-color: #3fa7d6;
}

.radio-danger input[type="radio"] + label::after {
    background-color: #ee6352;
}

.radio-danger input[type="radio"]:checked + label::before {
    border-color: #ee6352;
}

.radio-danger input[type="radio"]:checked + label::after {
    background-color: #ee6352;
}

.radio-info input[type="radio"] + label::after {
    background-color: #45bddc;
}

.radio-info input[type="radio"]:checked + label::before {
    border-color: #45bddc;
}

.radio-info input[type="radio"]:checked + label::after {
    background-color: #45bddc;
}

.radio-warning input[type="radio"] + label::after {
    background-color: #f7b267;
}

.radio-warning input[type="radio"]:checked + label::before {
    border-color: #f7b267;
}

.radio-warning input[type="radio"]:checked + label::after {
    background-color: #f7b267;
}

.radio-success input[type="radio"] + label::after {
    background-color: #59cd90;
}

.radio-success input[type="radio"]:checked + label::before {
    border-color: #59cd90;
}

.radio-success input[type="radio"]:checked + label::after {
    background-color: #59cd90;
}

.checkbox input[type="checkbox"]:focus + label::before,
.checkbox input[type="radio"]:focus + label::before,
.radio input[type="radio"]:focus + label::before {
    border-color: #56d0e9;
    outline: 0;
    box-shadow: 0 0 5px #56d0e9;
}

.radio input[type="radio"],
.radio-inline input[type="radio"],
.checkbox input[type="checkbox"],
.checkbox-inline input[type="checkbox"] {
    cursor: pointer;
    position: absolute !important;
}

.input-group-addon .radio,
.input-group-addon .checkbox {
    margin-right: 0;
    min-height: 17px;
    padding-top: 0px;
    padding-left: 17px;
}

    .input-group-addon .radio label,
    .input-group-addon .checkbox label {
        padding: 0;
        min-height: 17px;
    }

        .input-group-addon .radio label:before,
        .input-group-addon .checkbox label:before,
        .input-group-addon .radio label:after,
        .input-group-addon .checkbox label:after {
            margin-left: -17px;
            padding-left: 1px;
        }

select.select2-box {
    display: none;
}

.select2-container {
    width: 100% !important;
}

.select2-container--default .select2-search--dropdown {
    padding: 10px 12px;
}

    .select2-container--default .select2-search--dropdown .select2-search__field {
        border: 1px solid #d7dce0;
        border-radius: 3px;
        padding: 4px 8px;
    }

        .select2-container--default .select2-search--dropdown .select2-search__field:focus {
            border-color: #56d0e9;
            outline: 0;
            box-shadow: 0 0 5px #56d0e9;
        }

.select2-container--default .select2-selection--single {
    background-color: #fff;
    border: 1px solid #d7dce0;
    border-radius: 3px;
    height: 34px;
}

    .select2-container--default .select2-selection--single .select2-selection__rendered {
        color: #4d5865;
        line-height: 32px;
        padding-left: 12px;
        padding-right: 27px;
        font-size: 12px;
    }

    .select2-container--default .select2-selection--single .select2-selection__placeholder {
        color: #acb6bf;
    }

    .select2-container--default .select2-selection--single .select2-selection__arrow {
        height: 32px;
        width: 20px;
        right: 7px;
    }

        .select2-container--default .select2-selection--single .select2-selection__arrow b {
            border-color: #d7dce0 transparent transparent transparent;
            border-style: solid;
            border-width: 5px 4px 0 4px;
        }

    .select2-container--default .select2-selection--single .select2-selection__clear {
        cursor: pointer;
        float: right;
        font-weight: bold;
        line-height: 20px;
        margin-top: 5px;
        margin-right: 0px;
        padding: 0 5px;
        background-color: #f2f5f7;
        border: 1px solid #e7edf1;
        border-radius: 3px;
        color: #4d5865;
        vertical-align: top;
    }

        .select2-container--default .select2-selection--single .select2-selection__clear:hover {
            background-color: #e7edf1;
        }

.has-error .select2-container--default .select2-selection--single {
    border-color: #ebccd1;
    background-color: #f2dede;
    color: #a94442;
}

    .has-error .select2-container--default .select2-selection--single .select2-selection__rendered {
        color: #a94442;
    }

    .has-error .select2-container--default .select2-selection--single .select2-selection__placeholder {
        color: #d595a0;
    }

    .has-error .select2-container--default .select2-selection--single .select2-selection__arrow b {
        border-color: #d595a0 transparent transparent transparent;
    }

.select2-container--default .select2-selection--single:focus {
    border-color: #56d0e9;
    outline: 0;
    box-shadow: 0 0 5px #56d0e9;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border-color: transparent transparent #4d5865 transparent;
}

.select2-container--default .select2-selection--multiple {
    background-color: white;
    border: 1px solid #d7dce0;
    border-radius: 3px;
    min-height: 34px;
}

    .select2-container--default .select2-selection--multiple .select2-selection__rendered {
        padding: 0 12px 5px;
        min-height: 28px;
        vertical-align: top;
    }

    .select2-container--default .select2-selection--multiple .select2-selection__placeholder {
        color: #acb6bf;
        margin-top: 5px;
    }

    .select2-container--default .select2-selection--multiple .select2-selection__clear {
        cursor: pointer;
        float: right;
        font-weight: bold;
        line-height: 20px;
        margin-top: 5px;
        margin-right: 0px;
        padding: 0 5px;
        background-color: #f2f5f7;
        border: 1px solid #e7edf1;
        border-radius: 3px;
        color: #4d5865;
        vertical-align: top;
    }

        .select2-container--default .select2-selection--multiple .select2-selection__clear:hover {
            background-color: #e7edf1;
        }

    .select2-container--default .select2-selection--multiple .select2-selection__choice {
        background-color: #f2f5f7;
        color: #4d5865;
        border: 1px solid #e7edf1;
        border-radius: 3px;
        line-height: 20px;
        margin-right: 5px;
        margin-top: 5px;
        padding: 0 0 0 6px;
        font-size: 80%;
    }

    .select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
        color: #4d5865;
        display: inline-block;
        float: right;
        font-weight: bold;
        font-size: 14px;
        margin: 0 0 0 6px;
        padding: 0 5px;
        border-left: 1px solid #e7edf1;
    }

        .select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
            background-color: #e7edf1;
            color: #4d5865;
        }

.select2-container--default.select2-container--focus .select2-selection--multiple {
    border-color: #56d0e9;
    outline: 0;
    box-shadow: 0 0 5px #56d0e9;
}

    .select2-container--default.select2-container--focus .select2-selection--multiple:focus {
        border-color: #56d0e9;
        outline: 0;
        box-shadow: 0 0 5px #56d0e9;
    }

.select2-container--default .select2-results__option {
    padding: 6px 12px;
    color: #4d5865;
}

    .select2-container--default .select2-results__option[aria-disabled=true] {
        color: #acb6bf;
    }

    .select2-container--default .select2-results__option[aria-selected=true] {
        background-color: #e7edf1;
    }

    .select2-container--default .select2-results__option.select2-results__option--highlighted {
        background-color: #45bddc;
        color: white;
    }

fieldset[disabled] .select2-container--default .select2-selection--single,
fieldset[disabled] .select2-container--default,
fieldset[disabled] .select2-container--default .select2-selection--multiple {
    background-color: #f2f5f7;
    pointer-events: none;
    cursor: not-allowed;
}

fieldset[readonly] .select2-container--default .select2-selection--single,
fieldset[readonly] .select2-container--default,
fieldset[readonly] .select2-container--default .select2-selection--multiple {
    background-color: #f2f5f7;
    pointer-events: none;
    cursor: not-allowed;
}

.select2-dropdown {
    background-color: white;
    border: 1px solid #d7dce0;
    border-radius: 3px;
}

    .select2-dropdown.contented .select2-results > .select2-results__options {
        overflow-y: visible;
        max-height: none;
        text-align: center;
    }

.select2-contented .select2-container {
    width: auto !important;
}

.select2-sm .select2-container--default .select2-selection--single {
    height: 30px;
}

    .select2-sm .select2-container--default .select2-selection--single .select2-selection__rendered {
        line-height: 28px;
        font-size: 11px;
        padding-left: 10px;
    }

    .select2-sm .select2-container--default .select2-selection--single .select2-selection__arrow {
        height: 28px;
        right: 5px;
    }

.irs {
    height: 34px;
}

    .irs.irs-with-grid {
        height: 60px;
    }

.irs-line,
.irs-bar,
.irs-bar-edge {
    top: 22px;
}

.irs-slider {
    top: 19px;
}

.irs-line {
    background-color: #fff;
    border-radius: 6px;
    border: 1px solid #e7edf1;
    cursor: pointer;
}

.irs-line-mid,
.irs-line-left,
.irs-line-right,
.irs-bar,
.irs-slider,
.irs-min,
.irs-max,
.irs-bar-edge {
    background: none;
}

.irs-bar-edge {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}

.irs-min,
.irs-max {
    background-color: #fff;
    border: 1px solid #e7edf1;
    color: #4d5865;
}

.irs-slider {
    background-color: #fff;
    border: 1px solid #e7edf1;
    width: 18px;
    border-radius: 50%;
    cursor: pointer;
}

.irs-from:after,
.irs-to:after,
.irs-single:after {
    display: none;
}

.irs-grid-pol {
    background-color: #4d5865;
}

.irs-grid-text {
    color: #4d5865;
}

.irs-bar,
.irs-from,
.irs-to,
.irs-single,
.irs-bar-edge {
    background-color: #a7bdcb;
    cursor: pointer;
}

.slider-danger .irs-bar,
.slider-danger .irs-from,
.slider-danger .irs-to,
.slider-danger .irs-single,
.slider-danger .irs-bar-edge {
    background-color: #ed5565;
    cursor: pointer;
}

.slider-success .irs-bar,
.slider-success .irs-from,
.slider-success .irs-to,
.slider-success .irs-single,
.slider-success .irs-bar-edge {
    background-color: #59cd90;
    cursor: pointer;
}

.slider-info .irs-bar,
.slider-info .irs-from,
.slider-info .irs-to,
.slider-info .irs-single,
.slider-info .irs-bar-edge {
    background-color: #45bddc;
    cursor: pointer;
}

.slider-warning .irs-bar,
.slider-warning .irs-from,
.slider-warning .irs-to,
.slider-warning .irs-single,
.slider-warning .irs-bar-edge {
    background-color: #f7b267;
    cursor: pointer;
}

.slider-novalues .irs {
    height: 15px;
}

    .slider-novalues .irs.irs-with-grid {
        height: 41px;
    }

.slider-novalues .irs-line,
.slider-novalues .irs-bar,
.slider-novalues .irs-bar-edge {
    top: 3px;
}

.slider-novalues .irs-slider {
    top: 0px;
}

.fileinput.fileinput-buttons {
    width: 100%;
    margin-bottom: 0;
    white-space: nowrap;
}

    .fileinput.fileinput-buttons.fileinput-new .btn-group > .btn-file {
        border-top-right-radius: 3px !important;
        border-bottom-right-radius: 3px !important;
    }

    .fileinput.fileinput-buttons .fileinput-filename {
        width: 100%;
        padding-left: 122px;
        margin-left: -112px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

.fileinput-new .btn-group > .btn-file {
    border-top-right-radius: 3px !important;
    border-bottom-right-radius: 3px !important;
}

.btn-file {
    overflow: hidden;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
}

    .btn-file > input {
        position: absolute;
        top: 0;
        right: 0;
        margin: 0;
        opacity: 0;
        filter: alpha(opacity=0);
        font-size: 0px;
        height: 100%;
        width: 100%;
        direction: ltr;
        cursor: pointer;
    }

.fileinput {
    margin-bottom: 0px;
    display: inline-block;
}

    .fileinput .form-control {
        cursor: text;
    }

    .fileinput .thumbnail {
        overflow: hidden;
        display: inline-block;
        margin-bottom: 10px;
        vertical-align: middle;
        text-align: center;
    }

        .fileinput .thumbnail > img {
            max-height: 100%;
        }

    .fileinput .btn {
        vertical-align: middle;
    }

.fileinput-exists .fileinput-new,
.fileinput-new .fileinput-exists {
    display: none;
}

.fileinput-exists.close {
    float: none;
}

.fileinput-inline .fileinput-controls {
    display: inline;
}

.fileinput-filename {
    display: inline-block;
    overflow: hidden;
    vertical-align: middle;
}

.form-control .fileinput-filename {
    vertical-align: bottom;
}

.fileinput.input-group {
    display: table;
}

    .fileinput.input-group > * {
        position: relative;
        z-index: 2;
    }

    .fileinput.input-group > .btn-file {
        z-index: 1;
    }

.fileinput-new.input-group .btn-file,
.fileinput-new .input-group .btn-file {
    border-radius: 0 3px 3px 0;
}

    .fileinput-new.input-group .btn-file.btn-xs,
    .fileinput-new .input-group .btn-file.btn-xs,
    .fileinput-new.input-group .btn-file.btn-sm,
    .fileinput-new .input-group .btn-file.btn-sm {
        border-radius: 0 3px 3px 0;
    }

    .fileinput-new.input-group .btn-file.btn-lg,
    .fileinput-new .input-group .btn-file.btn-lg {
        border-radius: 0 3px 3px 0;
    }

.form-group.has-warning .fileinput .fileinput-preview {
    color: #8a6d3b;
}

.form-group.has-warning .fileinput .thumbnail {
    border-color: #faebcc;
}

.form-group.has-error .fileinput .fileinput-preview {
    color: #a94442;
}

.form-group.has-error .fileinput .thumbnail {
    border-color: #ebccd1;
}

.form-group.has-success .fileinput .fileinput-preview {
    color: #3c763d;
}

.form-group.has-success .fileinput .thumbnail {
    border-color: #d6e9c6;
}

.input-group-addon:not(:first-child) {
    border-left: 0;
}

input.periodpicker {
    display: none;
}

.period_picker_input {
    width: 100%;
    line-height: 34px;
    color: #4d5865;
    font-family: 'Lato', Helvetica, Arial, sans-serif;
    text-align: left;
    font-size: 14px;
}

    .period_picker_input:before {
        box-shadow: 0 0 0 1px #d7dce0;
    }

    .period_picker_input:hover:before,
    .period_picker_input:focus:before,
    .period_picker_input:active:before {
        background-color: #ededed;
        box-shadow: 0 0 0 1px #c3cad0;
    }

    .period_picker_input .period_button_text {
        width: 100%;
        display: inline-block;
        vertical-align: top;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .period_picker_input .icon_calendar,
    .period_picker_input .icon_clear {
        opacity: 0.35;
    }

.period_picker_box .period_picker_show {
    line-height: 1.42857143;
    height: 34px;
    font-size: 14px;
}

.period_picker_box .period_picker_submit_dates .period_picker_date {
    width: 100px;
    font-size: 14px;
}

    .period_picker_box .period_picker_submit_dates .period_picker_date .input_box {
        padding: 0;
        box-shadow: none !important;
        background: none;
    }

        .period_picker_box .period_picker_submit_dates .period_picker_date .input_box input.input_control {
            color: #4d5865;
            background-image: none;
            border: 1px solid #d7dce0;
            border-radius: 3px;
            box-shadow: none;
            outline: 0;
            padding: 6px 12px 7px;
            height: 34px;
        }

            .period_picker_box .period_picker_submit_dates .period_picker_date .input_box input.input_control:focus {
                border-color: #56d0e9;
                outline: 0;
                box-shadow: 0 0 5px #56d0e9;
            }

            .period_picker_box .period_picker_submit_dates .period_picker_date .input_box input.input_control::-moz-placeholder {
                color: #acb6bf;
                opacity: 1;
            }

            .period_picker_box .period_picker_submit_dates .period_picker_date .input_box input.input_control:-ms-input-placeholder {
                color: #acb6bf;
                opacity: 1;
            }

            .period_picker_box .period_picker_submit_dates .period_picker_date .input_box input.input_control::-webkit-input-placeholder {
                color: #acb6bf;
                opacity: 1;
            }

            .period_picker_box .period_picker_submit_dates .period_picker_date .input_box input.input_control[disabled],
            .period_picker_box .period_picker_submit_dates .period_picker_date .input_box input.input_control[readonly],
            fieldset[disabled] .period_picker_box .period_picker_submit_dates .period_picker_date .input_box input.input_control {
                background-color: #f2f5f7;
            }

table.dataTable {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

    table.dataTable td,
    table.dataTable th {
        outline: 0;
    }

    table.dataTable thead th {
        border-bottom: 0;
    }

    table.dataTable thead .sorting::after,
    table.dataTable thead .sorting_desc::after,
    table.dataTable thead .sorting_asc::after {
        position: static;
        display: inline-block;
        vertical-align: middle;
        opacity: 1;
        margin-left: 4px;
        content: '';
    }

    table.dataTable thead .sorting_desc::after,
    table.dataTable thead .sorting_asc::after {
        border-right: 4px solid transparent;
        border-left: 4px solid transparent;
    }

    table.dataTable thead .sorting_desc:after {
        border-top: 6px solid #4d5865;
    }

    table.dataTable thead .sorting_asc:after {
        border-bottom: 6px solid #4d5865;
    }

    table.dataTable.table-bordered tbody td {
        border-bottom-width: 1px;
    }

    table.dataTable tbody tr.selected {
        color: inherit;
        background-color: inherit;
    }

        table.dataTable tbody tr.selected td {
            background-color: rgba(0, 0, 0, 0.09);
        }

            table.dataTable tbody tr.selected td a {
                color: inherit;
            }

div.dataTables_wrapper div.dataTables_paginate {
    text-align: center;
    margin-top: 15px;
}

    div.dataTables_wrapper div.dataTables_paginate ul.pagination {
        margin: 0;
        vertical-align: top;
    }

.dataTables_info .select-info {
    padding-left: 4px;
}

.hide-info .dataTables_info {
    display: none;
}

.avoid-flicker table:not(.dataTable) {
    display: none;
}

.bootstrap-duallistbox-container .info-container {
    overflow: hidden;
    display: block;
}

.bootstrap-duallistbox-container .info {
    vertical-align: top;
}

.bootstrap-duallistbox-container .filter {
    margin: 0 0 10px;
}

.bootstrap-duallistbox-container .box1.filtered .clear1,
.bootstrap-duallistbox-container .box2.filtered .clear2 {
    line-height: 13px;
}

.bootstrap-duallistbox-container.moveonselect .btn-group button.btn.removeall {
    margin-left: 0;
    border-top-left-radius: 3px;
}

.bootstrap-duallistbox-container.moveonselect .btn-group button.btn.moveall {
    border-top-right-radius: 3px;
}

.bootstrap-duallistbox-container label {
    color: #4d5865;
    font-size: 14px;
    font-weight: 700;
}

.bootstrap-duallistbox-container select {
    padding: 5px 0 0 10px;
}

.bootstrap-duallistbox-container .box1,
.bootstrap-duallistbox-container .box2 {
    margin-bottom: 20px;
}

@media (min-width: 990px) {
    .bootstrap-duallistbox-container .box1,
    .bootstrap-duallistbox-container .box2 {
        margin-bottom: 0;
    }
}

.jqstooltip {
    padding: 5px;
    border-radius: 2px;
    background-color: rgba(0, 0, 0, 0.6) !important;
    border-color: rgba(0, 0, 0, 0.1) !important;
    width: auto !important;
    height: auto !important;
}

textarea.simplemde {
    display: none;
}

.editor-toolbar.fullscreen,
.CodeMirror-fullscreen {
    z-index: 9000;
}

.CodeMirror {
    border: 1px solid #d7dce0;
    color: #686e71;
    padding: 15px;
    min-height: 200px;
}

    .CodeMirror pre {
        padding: 0;
    }

.CodeMirror-lines {
    padding: 0;
}

.editor-preview {
    padding: 15px;
}

.CodeMirror-scroll {
    margin-right: -33px;
    margin-bottom: -33px;
    min-height: 170px;
    max-height: 300px;
}

.editor-toolbar {
    border: 1px solid #d7dce0;
    border-bottom: 0;
    opacity: 1;
}

    .editor-toolbar:hover a,
    .editor-toolbar:hover i {
        opacity: 1;
    }

    .editor-toolbar a,
    .editor-toolbar i {
        opacity: 0.6;
        outline: 0;
    }

.fa-spin {
    animation: fa-spin 1s infinite linear;
}

.fa-ab-flame:before {
    content: '';
    width: 14px;
    height: 14px;
    display: inline-block;
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/PjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iMTRweCIgaGVpZ2h0PSIxNHB4IiB2aWV3Qm94PSIwIDAgNTMzLjMzMyA1MzMuMzMzIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxNCAxNDsiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxnPjxwYXRoIGlkPSJhYi1mbGFtZSIgZD0iTTE2NS40OTQsNTMzLjMzM2MtMzUuNTQ1LTczLjk2Mi0xNi42MTYtMTE2LjM0MywxMC43MDMtMTU2LjI3MmMyOS45MTctNDMuNzI4LDM3LjYyNy04Ny4wMTMsMzcuNjI3LTg3LjAxMyAgIHMyMy41MTgsMzAuNTczLDE0LjExLDc4LjM5YzQxLjU0OC00Ni4yNSw0OS4zODktMTE5LjkzOCw0My4xMTUtMTQ4LjE1OWM5My45MTQsNjUuNjMsMTM0LjA1MSwyMDcuNzM3LDc5Ljk2LDMxMy4wNTQgICBjMjg3LjY5NS0xNjIuNzc2LDcxLjU2Mi00MDYuMzM5LDMzLjkzNC00MzMuNzc1YzEyLjU0MywyNy40MzUsMTQuOTIyLDczLjg4LTEwLjQxNiw5Ni40MkMzMzEuNjM1LDMzLjMzMywyMjUuNTgzLDAsMjI1LjU4MywwICAgYzEyLjU0Myw4My44NzctNDUuNDY2LDE3NS41OTYtMTAxLjQwNCwyNDQuMTNjLTEuOTY1LTMzLjQ0Ni00LjA1My01Ni41MjUtMjEuNjQxLTg4LjUzMSAgIEM5OC41OSwyMTYuMzU3LDUyLjE1NywyNjUuODg0LDM5LjU4MywzMjYuNzZDMjIuNTUxLDQwOS4yLDUyLjM0MSw0NjkuNTYyLDE2NS40OTQsNTMzLjMzM3oiIGZpbGw9IiNlZTYzNTIiLz48L2c+PC9zdmc+);
}

.jstree {
    overflow: auto;
}

.jstree-initial-node.jstree-loading {
    display: none;
}

.jstree-default-contextmenu {
    background: #fff;
    border: 1px solid #d7dce0;
    border-radius: 2px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    padding: 5px 0;
}

    .jstree-default-contextmenu > li > a {
        color: #686e71;
        text-shadow: none;
        padding: 0 1.5em 0 2.4em;
    }

        .jstree-default-contextmenu > li > a i,
        .jstree-default-contextmenu > li > a i:empty {
            width: 2.6em;
            margin-left: -2.3em;
        }

        .jstree-default-contextmenu > li > a .vakata-contextmenu-sep {
            border-left: 1px solid #e5e5e5;
            margin: 0 .9em 0 0;
        }

    .jstree-default-contextmenu > li.vakata-context-hover > a,
    .jstree-default-contextmenu > li.vakata-context-hover > a:hover {
        background-color: #f5f5f5;
        box-shadow: none;
    }
/*!
 * Datepicker for Bootstrap
 *
 * Copyright 2012 Stefan Petre
 * Improvements by Andrew Rowls
 * Licensed under the Apache License v2.0
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 */
.datepicker {
    padding: 20px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    direction: ltr;
}

.dow {
    border: none !important;
}

.datepicker-inline {
    width: 220px;
}

.datepicker.datepicker-rtl {
    direction: rtl;
}

    .datepicker.datepicker-rtl table tr td span {
        float: right;
    }

.datepicker-dropdown {
    top: 0;
    left: 0;
}

    .datepicker-dropdown:before {
        content: '';
        display: inline-block;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-bottom: 7px solid #ffffff;
        border-top: 0;
        position: absolute;
    }

    .datepicker-dropdown.datepicker-orient-left:before {
        left: 6px;
    }

    .datepicker-dropdown.datepicker-orient-left:after {
        left: 7px;
    }

    .datepicker-dropdown.datepicker-orient-right:before {
        right: 14px;
    }

    .datepicker-dropdown.datepicker-orient-right:after {
        right: 7px;
    }

    .datepicker-dropdown.datepicker-orient-top:before {
        top: -7px;
    }

    .datepicker-dropdown.datepicker-orient-top:after {
        top: -6px;
    }

    .datepicker-dropdown.datepicker-orient-bottom:before {
        bottom: -7px;
        border-bottom: 0;
        border-top: 7px solid #ffffff;
    }

    .datepicker-dropdown.datepicker-orient-bottom:after {
        bottom: -6px;
        border-bottom: 0;
        border-top: 6px solid #ffffff;
    }

.datepicker > div {
    display: none;
}

.datepicker.days div.datepicker-days {
    display: block;
}

.datepicker.months div.datepicker-months {
    display: block;
}

.datepicker.years div.datepicker-years {
    display: block;
}

.datepicker table {
    margin: 0;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.datepicker td,
.datepicker th {
    text-align: center;
    width: 20px;
    height: 20px;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    padding: 5px 10px !important;
    font-weight: 700;
    border: 1px solid #ebedf0;
    /*border: none;*/
}

.table-striped .datepicker table tr td,
.table-striped .datepicker table tr th {
    background-color: transparent;
}

.datepicker table tr td.day:hover {
    background: #ebedf0;
    cursor: pointer;
}

.datepicker table tr td.old,
.datepicker table tr td.new {
    color: #d7dbe0;
}

.datepicker table tr td.disabled,
.datepicker table tr td.disabled:hover {
    background: none;
    color: #d7dbe0;
    cursor: default;
}

.datepicker table tr td.today,
.datepicker table tr td.today:hover,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today.disabled:hover {
    background-color: #fde19a;
    color: #000;
}

    .datepicker table tr td.today:hover,
    .datepicker table tr td.today:hover:hover,
    .datepicker table tr td.today.disabled:hover,
    .datepicker table tr td.today.disabled:hover:hover,
    .datepicker table tr td.today:active,
    .datepicker table tr td.today:hover:active,
    .datepicker table tr td.today.disabled:active,
    .datepicker table tr td.today.disabled:hover:active,
    .datepicker table tr td.today.active,
    .datepicker table tr td.today:hover.active,
    .datepicker table tr td.today.disabled.active,
    .datepicker table tr td.today.disabled:hover.active,
    .datepicker table tr td.today.disabled,
    .datepicker table tr td.today:hover.disabled,
    .datepicker table tr td.today.disabled.disabled,
    .datepicker table tr td.today.disabled:hover.disabled,
    .datepicker table tr td.today[disabled],
    .datepicker table tr td.today:hover[disabled],
    .datepicker table tr td.today.disabled[disabled],
    .datepicker table tr td.today.disabled:hover[disabled] {
        background-color: #fdf59a;
    }

        .datepicker table tr td.today:active,
        .datepicker table tr td.today:hover:active,
        .datepicker table tr td.today.disabled:active,
        .datepicker table tr td.today.disabled:hover:active,
        .datepicker table tr td.today.active,
        .datepicker table tr td.today:hover.active,
        .datepicker table tr td.today.disabled.active,
        .datepicker table tr td.today.disabled:hover.active {
            background-color: #fbf069;
        }

        .datepicker table tr td.today:hover:hover {
            color: #000;
        }

        .datepicker table tr td.today.active:hover {
            color: #fff;
        }

.datepicker table tr td.range,
.datepicker table tr td.range:hover,
.datepicker table tr td.range.disabled,
.datepicker table tr td.range.disabled:hover {
    background: #ebedf0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}

    .datepicker table tr td.range.today,
    .datepicker table tr td.range.today:hover,
    .datepicker table tr td.range.today.disabled,
    .datepicker table tr td.range.today.disabled:hover {
        background-color: #f3d17a;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
    }

        .datepicker table tr td.range.today:hover,
        .datepicker table tr td.range.today:hover:hover,
        .datepicker table tr td.range.today.disabled:hover,
        .datepicker table tr td.range.today.disabled:hover:hover,
        .datepicker table tr td.range.today:active,
        .datepicker table tr td.range.today:hover:active,
        .datepicker table tr td.range.today.disabled:active,
        .datepicker table tr td.range.today.disabled:hover:active,
        .datepicker table tr td.range.today.active,
        .datepicker table tr td.range.today:hover.active,
        .datepicker table tr td.range.today.disabled.active,
        .datepicker table tr td.range.today.disabled:hover.active,
        .datepicker table tr td.range.today.disabled,
        .datepicker table tr td.range.today:hover.disabled,
        .datepicker table tr td.range.today.disabled.disabled,
        .datepicker table tr td.range.today.disabled:hover.disabled,
        .datepicker table tr td.range.today[disabled],
        .datepicker table tr td.range.today:hover[disabled],
        .datepicker table tr td.range.today.disabled[disabled],
        .datepicker table tr td.range.today.disabled:hover[disabled] {
            background-color: #f3e97a;
        }

            .datepicker table tr td.range.today:active,
            .datepicker table tr td.range.today:hover:active,
            .datepicker table tr td.range.today.disabled:active,
            .datepicker table tr td.range.today.disabled:hover:active,
            .datepicker table tr td.range.today.active,
            .datepicker table tr td.range.today:hover.active,
            .datepicker table tr td.range.today.disabled.active,
            .datepicker table tr td.range.today.disabled:hover.active {
                background-color: #efe24b;
            }

.datepicker table tr td.selected,
.datepicker table tr td.selected:hover,
.datepicker table tr td.selected.disabled,
.datepicker table tr td.selected.disabled:hover {
    background-color: #9e9e9e;
    color: #fff;
}

    .datepicker table tr td.selected:hover,
    .datepicker table tr td.selected:hover:hover,
    .datepicker table tr td.selected.disabled:hover,
    .datepicker table tr td.selected.disabled:hover:hover,
    .datepicker table tr td.selected:active,
    .datepicker table tr td.selected:hover:active,
    .datepicker table tr td.selected.disabled:active,
    .datepicker table tr td.selected.disabled:hover:active,
    .datepicker table tr td.selected.active,
    .datepicker table tr td.selected:hover.active,
    .datepicker table tr td.selected.disabled.active,
    .datepicker table tr td.selected.disabled:hover.active,
    .datepicker table tr td.selected.disabled,
    .datepicker table tr td.selected:hover.disabled,
    .datepicker table tr td.selected.disabled.disabled,
    .datepicker table tr td.selected.disabled:hover.disabled,
    .datepicker table tr td.selected[disabled],
    .datepicker table tr td.selected:hover[disabled],
    .datepicker table tr td.selected.disabled[disabled],
    .datepicker table tr td.selected.disabled:hover[disabled] {
        background-color: #808080;
    }

        .datepicker table tr td.selected:active,
        .datepicker table tr td.selected:hover:active,
        .datepicker table tr td.selected.disabled:active,
        .datepicker table tr td.selected.disabled:hover:active,
        .datepicker table tr td.selected.active,
        .datepicker table tr td.selected:hover.active,
        .datepicker table tr td.selected.disabled.active,
        .datepicker table tr td.selected.disabled:hover.active {
            background-color: #666666;
        }

.datepicker table tr td.active,
.datepicker table tr td.active:hover,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active.disabled:hover {
    background-color: #006dcc;
    color: #fff;
}

    .datepicker table tr td.active:hover,
    .datepicker table tr td.active:hover:hover,
    .datepicker table tr td.active.disabled:hover,
    .datepicker table tr td.active.disabled:hover:hover,
    .datepicker table tr td.active:active,
    .datepicker table tr td.active:hover:active,
    .datepicker table tr td.active.disabled:active,
    .datepicker table tr td.active.disabled:hover:active,
    .datepicker table tr td.active.active,
    .datepicker table tr td.active:hover.active,
    .datepicker table tr td.active.disabled.active,
    .datepicker table tr td.active.disabled:hover.active,
    .datepicker table tr td.active.disabled,
    .datepicker table tr td.active:hover.disabled,
    .datepicker table tr td.active.disabled.disabled,
    .datepicker table tr td.active.disabled:hover.disabled,
    .datepicker table tr td.active[disabled],
    .datepicker table tr td.active:hover[disabled],
    .datepicker table tr td.active.disabled[disabled],
    .datepicker table tr td.active.disabled:hover[disabled] {
        background-color: #3fa7d6;
    }

        .datepicker table tr td.active:active,
        .datepicker table tr td.active:hover:active,
        .datepicker table tr td.active.disabled:active,
        .datepicker table tr td.active.disabled:hover:active,
        .datepicker table tr td.active.active,
        .datepicker table tr td.active:hover.active,
        .datepicker table tr td.active.disabled.active,
        .datepicker table tr td.active.disabled:hover.active {
            background-color: #003399;
        }

.datepicker table tr td span {
    display: block;
    width: 23%;
    height: 54px;
    line-height: 54px;
    float: left;
    margin: 1%;
    cursor: pointer;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}

    .datepicker table tr td span:hover {
        background: #ebedf0;
    }

    .datepicker table tr td span.disabled,
    .datepicker table tr td span.disabled:hover {
        background: none;
        color: #d7dbe0;
        cursor: default;
    }

    .datepicker table tr td span.active,
    .datepicker table tr td span.active:hover,
    .datepicker table tr td span.active.disabled,
    .datepicker table tr td span.active.disabled:hover {
        background-color: #3fa7d6;
        color: #fff;
    }

        .datepicker table tr td span.active:hover,
        .datepicker table tr td span.active:hover:hover,
        .datepicker table tr td span.active.disabled:hover,
        .datepicker table tr td span.active.disabled:hover:hover,
        .datepicker table tr td span.active:active,
        .datepicker table tr td span.active:hover:active,
        .datepicker table tr td span.active.disabled:active,
        .datepicker table tr td span.active.disabled:hover:active,
        .datepicker table tr td span.active.active,
        .datepicker table tr td span.active:hover.active,
        .datepicker table tr td span.active.disabled.active,
        .datepicker table tr td span.active.disabled:hover.active,
        .datepicker table tr td span.active.disabled,
        .datepicker table tr td span.active:hover.disabled,
        .datepicker table tr td span.active.disabled.disabled,
        .datepicker table tr td span.active.disabled:hover.disabled,
        .datepicker table tr td span.active[disabled],
        .datepicker table tr td span.active:hover[disabled],
        .datepicker table tr td span.active.disabled[disabled],
        .datepicker table tr td span.active.disabled:hover[disabled] {
            background-color: #3fa7d6;
        }

            .datepicker table tr td span.active:active,
            .datepicker table tr td span.active:hover:active,
            .datepicker table tr td span.active.disabled:active,
            .datepicker table tr td span.active.disabled:hover:active,
            .datepicker table tr td span.active.active,
            .datepicker table tr td span.active:hover.active,
            .datepicker table tr td span.active.disabled.active,
            .datepicker table tr td span.active.disabled:hover.active {
                background-color: #003399;
            }

    .datepicker table tr td span.old,
    .datepicker table tr td span.new {
        color: #d7dbe0;
    }

.datepicker th.datepicker-switch {
    width: 145px;
}

.datepicker thead tr:first-child th,
.datepicker tfoot tr th {
    cursor: pointer;
    border: none;
}

    .datepicker thead tr:first-child th:hover,
    .datepicker tfoot tr th:hover {
        background: #ebedf0;
    }

.datepicker .cw {
    font-size: 10px;
    width: 12px;
    padding: 0 2px 0 5px;
    vertical-align: middle;
}

.datepicker thead tr:first-child th.cw {
    cursor: default;
    background-color: transparent;
}

.input-append.date .add-on i,
.input-prepend.date .add-on i {
    display: block;
    cursor: pointer;
    width: 16px;
    height: 16px;
}

.input-daterange input {
    text-align: center;
}

    .input-daterange input:first-child {
        -webkit-border-radius: 3px 0 0 3px;
        -moz-border-radius: 3px 0 0 3px;
        border-radius: 3px 0 0 3px;
    }

    .input-daterange input:last-child {
        -webkit-border-radius: 0 3px 3px 0;
        -moz-border-radius: 0 3px 3px 0;
        border-radius: 0 3px 3px 0;
    }

.input-daterange .add-on {
    display: inline-block;
    width: auto;
    min-width: 16px;
    height: 18px;
    padding: 4px 5px;
    font-weight: normal;
    line-height: 18px;
    text-align: center;
    text-shadow: 0 1px 0 #ffffff;
    vertical-align: middle;
    background-color: #ebedf0;
    border: 1px solid #ccc;
    margin-left: -5px;
    margin-right: -5px;
}

.daterangepicker {
    position: absolute;
    color: #686e71;
    background-color: #ffffff;
    border-radius: 2px;
    width: 278px;
    padding: 15px 15px 15px;
    margin-top: 10px;
    top: 100px;
    left: 20px;
    /* Calendars */
}

    .daterangepicker:before,
    .daterangepicker:after {
        position: absolute;
        display: inline-block;
        border-bottom-color: rgba(0, 0, 0, 0.2);
        content: '';
    }

    .daterangepicker:before {
        top: -7px;
        border-right: 7px solid transparent;
        border-left: 7px solid transparent;
        border-bottom: 7px solid #d7dce0;
    }

    .daterangepicker:after {
        top: -6px;
        border-right: 6px solid transparent;
        border-bottom: 6px solid #ffffff;
        border-left: 6px solid transparent;
    }

    .daterangepicker.opensleft:before {
        right: 9px;
    }

    .daterangepicker.opensleft:after {
        right: 10px;
    }

    .daterangepicker.openscenter:before {
        left: 0;
        right: 0;
        width: 0;
        margin-left: auto;
        margin-right: auto;
    }

    .daterangepicker.openscenter:after {
        left: 0;
        right: 0;
        width: 0;
        margin-left: auto;
        margin-right: auto;
    }

    .daterangepicker.opensright:before {
        left: 9px;
    }

    .daterangepicker.opensright:after {
        left: 10px;
    }

    .daterangepicker.dropup {
        margin-top: -5px;
    }

        .daterangepicker.dropup:before {
            top: initial;
            bottom: -7px;
            border-bottom: initial;
            border-top: 7px solid #d7dce0;
        }

        .daterangepicker.dropup:after {
            top: initial;
            bottom: -6px;
            border-bottom: initial;
            border-top: 6px solid #ffffff;
        }

    .daterangepicker.dropdown-menu {
        max-width: none;
        z-index: 3001;
    }

    .daterangepicker.single .ranges,
    .daterangepicker.single .calendar {
        float: none;
    }

    .daterangepicker.show-calendar .calendar {
        display: block;
    }

    .daterangepicker .calendar {
        display: none;
        max-width: 278px;
        margin: 0;
    }

        .daterangepicker .calendar.single .calendar-table {
            border: none;
        }

        .daterangepicker .calendar th,
        .daterangepicker .calendar td {
            white-space: nowrap;
            text-align: center;
            min-width: 30px;
        }

    .daterangepicker .calendar-table {
        border: 0px solid #ffffff;
        padding: 5px 0 0;
        border-radius: 2px;
        background-color: #ffffff;
    }

        .daterangepicker .calendar-table .fa-chevron-left:before {
            content: "\f104";
        }

        .daterangepicker .calendar-table .fa-chevron-right:before {
            content: "\f105";
        }

    .daterangepicker table {
        width: 100%;
        margin: 0;
    }

    .daterangepicker td,
    .daterangepicker th {
        text-align: center;
        width: 18px;
        height: 18px;
        line-height: 18px;
        border-radius: 2px;
        border: 0px solid transparent;
        white-space: nowrap;
        cursor: pointer;
        padding: 5px;
        font-size: 13px;
    }

        .daterangepicker td.available:hover,
        .daterangepicker th.available:hover {
            background-color: #d4dfe6;
            border-color: transparent;
            color: #686e71;
        }

        .daterangepicker td.week,
        .daterangepicker th.week {
            font-size: 80%;
            color: #ccc;
        }

        .daterangepicker td .fa,
        .daterangepicker th .fa {
            top: 0;
            font-size: 16px;
        }

        .daterangepicker td.off,
        .daterangepicker td.off.in-range,
        .daterangepicker td.off.start-date,
        .daterangepicker td.off.end-date {
            background-color: #ffffff;
            border-color: transparent;
            color: #d1d3d4;
        }

        .daterangepicker td.in-range {
            background-color: #f2f5f7;
            border-color: #f2f5f7;
            color: #707070;
            border-radius: 0;
        }

        .daterangepicker td.start-date {
            border-radius: 2px 0 0 2px;
        }

        .daterangepicker td.end-date {
            border-radius: 0 2px 2px 0;
        }

        .daterangepicker td.start-date.end-date {
            border-radius: 2px;
        }

        .daterangepicker td.active,
        .daterangepicker td.active:hover {
            background-color: #45bddc;
            border-color: #45bddc;
            color: #ffffff;
        }

        .daterangepicker th.month {
            width: auto;
        }

        .daterangepicker td.disabled,
        .daterangepicker option.disabled {
            color: #999;
            cursor: not-allowed;
            text-decoration: line-through;
        }

    .daterangepicker select.monthselect,
    .daterangepicker select.yearselect {
        font-size: 12px;
        padding: 1px;
        height: auto;
        margin: 0;
        cursor: default;
    }

    .daterangepicker select.monthselect {
        margin-right: 2%;
        width: 56%;
    }

    .daterangepicker select.yearselect {
        width: 40%;
    }

    .daterangepicker select.hourselect,
    .daterangepicker select.minuteselect,
    .daterangepicker select.secondselect,
    .daterangepicker select.ampmselect {
        width: 50px;
        margin-bottom: 0;
    }

    .daterangepicker .input-mini {
        border: 1px solid #d7dce0;
        border-radius: 3px;
        color: #4d5865;
        height: 30px;
        line-height: 1.42857143;
        display: block;
        vertical-align: middle;
        font-size: 13px;
        text-align: center;
        margin: 0 0 5px 0;
        padding: 0 30px 0 30px;
        width: 100%;
    }

        .daterangepicker .input-mini.active {
            border: 1px solid #56d0e9;
            border-radius: 3px;
            box-shadow: 0 0 5px #56d0e9;
        }

    .daterangepicker .daterangepicker_input {
        position: relative;
    }

        .daterangepicker .daterangepicker_input i {
            position: absolute;
            left: 10px;
            top: 8px;
        }

    .daterangepicker.rtl .input-mini {
        padding-right: 30px;
        padding-left: 10px;
    }

    .daterangepicker.rtl .daterangepicker_input i {
        left: auto;
        right: 10px;
    }

    .daterangepicker .calendar-time {
        text-align: center;
        margin: 5px auto;
        line-height: 1.42857143;
        position: relative;
        padding-left: 30px;
    }

        .daterangepicker .calendar-time select.disabled {
            color: #4d5865;
            cursor: not-allowed;
        }

.ranges {
    font-size: 14px;
    float: none;
    margin: 0 0 5px 15px;
    text-align: left;
}

    .ranges ul {
        list-style: none;
        margin: 0 auto 10px;
        padding: 0;
        width: 100%;
    }

    .ranges li {
        font-size: 13px;
        background-color: #ffffff;
        border: 1px solid #d7dce0;
        border-radius: 2px;
        color: #686e71;
        padding: 5px 10px;
        margin-bottom: 5px;
        cursor: pointer;
    }

        .ranges li:hover {
            background-color: #f2f5f7;
            border: 1px solid #d7e1e8;
            color: #4d5865;
        }

        .ranges li.active {
            background-color: #45bddc;
            border: 1px solid #45bddc;
            color: #ffffff;
        }

.range_inputs {
    text-align: right;
}
/*  Larger Screen Styling */
@media (min-width: 769px) {
    .daterangepicker {
        width: auto;
    }

        .daterangepicker .ranges ul {
            width: 130px;
        }

        .daterangepicker.single .ranges ul {
            width: 100%;
        }

        .daterangepicker.single .calendar.left {
            clear: none;
        }

        .daterangepicker.single.ltr .ranges,
        .daterangepicker.single.ltr .calendar {
            float: left;
        }

        .daterangepicker.single.rtl .ranges,
        .daterangepicker.single.rtl .calendar {
            float: right;
        }

        .daterangepicker.ltr {
            direction: ltr;
            text-align: left;
        }

            .daterangepicker.ltr .calendar.left {
                clear: left;
                margin-right: 0;
            }

                .daterangepicker.ltr .calendar.left .calendar-table {
                    border-right: none;
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                }

            .daterangepicker.ltr .calendar.right {
                margin-left: 0;
            }

                .daterangepicker.ltr .calendar.right .calendar-table {
                    border-left: none;
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                }

            .daterangepicker.ltr .left .daterangepicker_input {
                padding-right: 15px;
            }

            .daterangepicker.ltr .calendar.left .calendar-table {
                padding-right: 15px;
            }

            .daterangepicker.ltr .ranges,
            .daterangepicker.ltr .calendar {
                float: left;
            }

        .daterangepicker.rtl {
            direction: rtl;
            text-align: right;
        }

            .daterangepicker.rtl .calendar.left {
                clear: right;
                margin-left: 0;
            }

                .daterangepicker.rtl .calendar.left .calendar-table {
                    border-left: none;
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                }

            .daterangepicker.rtl .calendar.right {
                margin-right: 0;
            }

                .daterangepicker.rtl .calendar.right .calendar-table {
                    border-right: none;
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                }

            .daterangepicker.rtl .left .daterangepicker_input {
                padding-left: 15px;
            }

            .daterangepicker.rtl .calendar.left .calendar-table {
                padding-left: 15px;
            }

            .daterangepicker.rtl .ranges,
            .daterangepicker.rtl .calendar {
                text-align: right;
                float: right;
            }
}

@media (max-width: 768px) {
    .daterangepicker .ranges {
        width: auto;
        margin-left: 0;
        float: none;
    }

    .daterangepicker .calendar {
        float: none;
    }

    .daterangepicker .calendar-table {
        padding-right: 0;
        margin-bottom: 20px;
    }
}

.daterangepicker.daterangepicker-inline {
    position: relative;
    top: auto !important;
    left: auto !important;
    right: auto !important;
    display: inline-block !important;
    padding: 0;
    margin: 0;
    max-width: 100%;
}

    .daterangepicker.daterangepicker-inline:before,
    .daterangepicker.daterangepicker-inline:after {
        display: none;
    }

    .daterangepicker.daterangepicker-inline .calendar.left .daterangepicker_input,
    .daterangepicker.daterangepicker-inline .calendar.left .calendar-table {
        padding-right: 0;
        width: 218px;
    }

    .daterangepicker.daterangepicker-inline .calendar.left .calendar-table {
        padding: 0;
        margin: 0 auto;
    }

    .daterangepicker.daterangepicker-inline .ranges,
    .daterangepicker.daterangepicker-inline .calendar.right {
        display: none;
    }

.daterangepicker.daterangepicker-alternative {
    background-color: transparent;
}

    .daterangepicker.daterangepicker-alternative .calendar-table {
        background-color: transparent;
    }

        .daterangepicker.daterangepicker-alternative .calendar-table td.off {
            background-color: transparent;
        }

        .daterangepicker.daterangepicker-alternative .calendar-table td.in-range {
            background-color: #ffffff;
        }

        .daterangepicker.daterangepicker-alternative .calendar-table td:hover {
            background-color: #e7edf1;
        }

        .daterangepicker.daterangepicker-alternative .calendar-table td.active,
        .daterangepicker.daterangepicker-alternative .calendar-table td.active:hover {
            background-color: #45bddc;
            color: #ffffff;
        }

.amcharts-chart-div svg + a {
    opacity: 0.3 !important;
}

.am-ex-legend {
    position: relative;
}

    .am-ex-legend > svg {
        position: relative !important;
        width: auto !important;
        left: 0 !important;
    }

html {
    height: 100%;
}

body {
    height: 100%;
    font-family: 'Lato', Helvetica, Arial, sans-serif;
    font-size: 12px;
    line-height: 1.4;
    box-sizing: border-box;
    color: #686e71;
    background: #f2f5f7;
}

a {
    color: #3ebae8;
    text-decoration: none;
}

    a:hover,
    a:focus,
    a:active {
        color: #1bace2;
        text-decoration: underline;
    }

p {
    margin: 0;
}

    p + p {
        margin-top: 1em;
    }

    p:not(:last-child) {
        margin-bottom: 1em;
    }

.clearfix:after {
    content: '';
    display: block;
    clear: both;
}

.disable-scroll {
    overflow: hidden;
}

a.unstyled {
    color: inherit;
    text-decoration: none;
}

.text-nowrap {
    white-space: nowrap;
}

.text-capitalize {
    text-transform: capitalize;
}

.text-bold {
    font-weight: bold;
}

.text-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.fluid-cols {
    display: table;
    width: 100%;
}

    .fluid-cols > .expand-col {
        display: table-cell;
        max-width: 0px;
        vertical-align: top;
    }

    .fluid-cols > .min-col {
        display: table-cell;
        width: 1px;
        vertical-align: top;
    }

.mb {
    margin-bottom: 20px;
}

.mt {
    margin-top: 20px;
}

.border-right {
    border-right: 20px solid transparent;
}

.st-wrapper {
    height: 100%;
    position: relative;
    min-width: 320px;
}

    .st-wrapper:after {
        content: '';
        display: block;
        clear: both;
    }

.st-sidebar {
    min-height: 100%;
    width: 220px;
    background: #4d5865;
    color: #b8bdc1;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    font-size: 13px;
    transition: all 150ms;
}

    .st-sidebar .scrollbar > .scroll-element.scroll-y .scroll-bar {
        display: none;
    }

    .st-sidebar ul {
        padding: 0;
        margin: 0;
        list-style: none;
    }

.st-sidebar__header {
    padding: 5px 15px 5px 20px;
    font-size: 24px;
    line-height: 30px;
    overflow: hidden;
    border-bottom: 1px solid #5d6875;
    transition: padding 150ms;
    position: relative;
    z-index: 100;
}

.st-sidebar__logo {
    letter-spacing: .05em;
    width: 100%;
    margin-right: -14px;
    color: #b8bdc1;
    display: inline-block;
    vertical-align: top;
    opacity: 1;
    overflow: hidden;
    text-decoration: none;
    color: inherit;
    transition: all 150ms;
}

    .st-sidebar__logo:hover,
    .st-sidebar__logo:focus,
    .st-sidebar__logo:active {
        text-decoration: none;
        color: inherit;
        outline: none;
    }

    .st-sidebar__logo img {
        width: 85px;
        height: auto;
        margin-top: -5px;
        margin-bottom: 0;
    }

.st-sidebar__mico {
    float: right;
    width: 14px;
    text-align: center;
    cursor: pointer;
    position: relative;
    background: #4d5865;
}

    .st-sidebar__mico .fa {
        font-size: 14px;
        line-height: 30px;
        vertical-align: top;
    }

.st-sidebar__cont {
    height: 100%;
    border-top: 61px solid transparent;
    margin: -61px 0 0;
    position: relative;
}

.st-sidebar__scroll {
    height: 100%;
    overflow: auto;
}

.st-sidebar__nav {
    padding-bottom: 50px;
}

    .st-sidebar__nav ul li,
    .st-sidebar__popup ul li {
        display: block;
        background: #4d5865;
        position: relative;
    }

        .st-sidebar__nav ul li.active,
        .st-sidebar__popup ul li.active {
            background: #3b444e;
        }

            .st-sidebar__nav ul li.active > .st-sidebar__nested,
            .st-sidebar__popup ul li.active > .st-sidebar__nested {
                display: block;
            }

        .st-sidebar__nav ul li:hover,
        .st-sidebar__popup ul li:hover,
        .st-sidebar__nav ul li.hover,
        .st-sidebar__popup ul li.hover {
            background: #3b444e;
        }

        .st-sidebar__nav ul li.open > a .st-sidebar__arrow,
        .st-sidebar__popup ul li.open > a .st-sidebar__arrow {
            transform: rotate(90deg);
        }

        .st-sidebar__nav ul li a,
        .st-sidebar__popup ul li a {
            display: block;
            text-decoration: none;
            color: #b8bdc1;
            padding: 12px 20px;
            line-height: 20px;
            overflow: hidden;
            white-space: nowrap;
            width: 100%;
            transition: padding 150ms;
        }

            .st-sidebar__nav ul li a .fluid-cols .expand-col + .min-col,
            .st-sidebar__popup ul li a .fluid-cols .expand-col + .min-col {
                border-left: 15px solid transparent;
                text-align: right;
            }

                .st-sidebar__nav ul li a .fluid-cols .expand-col + .min-col .label,
                .st-sidebar__popup ul li a .fluid-cols .expand-col + .min-col .label {
                    vertical-align: top;
                }

.st-sidebar__title {
    font-size: 14px;
}

.st-sidebar__arrow {
    text-align: center;
    transition: transform .150s;
    font-size: 14px;
    width: 5px;
}

    .st-sidebar__arrow.fa {
        font-size: 14px;
    }

.st-sidebar__ico {
    width: 30px;
    text-align: center;
    padding-right: 15px;
    font-style: normal;
    font-weight: bold;
    display: inline-block;
}

    .st-sidebar__ico.fa {
        font-weight: normal;
        font-size: 14px;
    }

ul.st-sidebar__nested {
    display: none;
    overflow: hidden;
}

    ul.st-sidebar__nested li {
        padding-left: 30px;
        background: transparent;
    }

        ul.st-sidebar__nested li:hover,
        ul.st-sidebar__nested li.active {
            background: rgba(0, 0, 0, 0.1);
        }

            ul.st-sidebar__nested li:hover > a,
            ul.st-sidebar__nested li.active > a {
                color: #ffffff;
            }

        ul.st-sidebar__nested li a {
            padding: 8px 20px;
        }

    ul.st-sidebar__nested .st-sidebar__nested li {
        padding-left: 15px;
    }

        ul.st-sidebar__nested .st-sidebar__nested li:hover,
        ul.st-sidebar__nested .st-sidebar__nested li.active {
            background: inherit;
        }

.st-sidebar__popup {
    display: none;
    position: absolute !important;
    background: #4d5865;
    top: 0;
    bottom: auto;
    left: 45px;
    overflow: hidden;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    transition: top 150ms, bottom 150ms;
}

    .st-sidebar__popup .st-sidebar__ico {
        display: none;
    }

    .st-sidebar__popup ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }

        .st-sidebar__popup ul li a .fluid-cols .expand-col {
            max-width: none;
            width: 100%;
        }

        .st-sidebar__popup ul li a .fluid-cols .min-col {
            width: 1%;
        }

        .st-sidebar__popup ul.st-sidebar__nested {
            width: 100%;
            min-width: 190px;
            padding-left: 0;
        }

            .st-sidebar__popup ul.st-sidebar__nested li {
                padding-left: 0;
            }

            .st-sidebar__popup ul.st-sidebar__nested .st-sidebar__nested li {
                padding-left: 15px;
            }

                .st-sidebar__popup ul.st-sidebar__nested .st-sidebar__nested li:hover {
                    background: inherit;
                }

body.sidebar-collapsible:not(.sidebar-open) .st-sidebar {
    width: 45px;
}

body.sidebar-collapsible:not(.sidebar-open) .st-sidebar__header {
    /*padding: 15px 10px 15px 10px;*/
}

body.sidebar-collapsible:not(.sidebar-open) .st-sidebar__logo {
    width: 0;
    opacity: 0;
}

body.sidebar-collapsible:not(.sidebar-open) .st-sidebar__cont:hover .st-sidebar__popup {
    display: block;
}

body.sidebar-collapsible:not(.sidebar-open) .st-sidebar__cont .st-sidebar__popup {
    display: none;
}

body.sidebar-collapsible:not(.sidebar-open) .st-sidebar__nav .expand-col + .min-col {
    display: none;
}

body.sidebar-collapsible:not(.sidebar-open) .st-sidebar__nav ul > li > a,
body.sidebar-collapsible:not(.sidebar-open) .st-sidebar__popup a {
    padding: 12px 15px;
}

body.sidebar-collapsible:not(.sidebar-open) .st-sidebar__nav > ul > li > .st-sidebar__nested {
    display: none;
}

body.sidebar-offcanvas .st-sidebar {
    left: -220px;
}

body.sidebar-offcanvas.sidebar-open .st-sidebar,
body.sidebar-offcanvas.sidebar-collapsible .st-sidebar {
    left: 0;
}

.st-sidebar.vista-cliente .st-sidebar__cont {
    display: none;
}
/* ========== LOGIN DIV ========== */
#login {
    max-width: 356px;
    margin: 0 auto 20px;
    padding-top: 40px;
}

    #login .login-header img {
        width: 240px;
        height: auto;
    }

    #login input {
        background-color: #ffffff;
    }

    #login .form-control {
        text-transform: none;
    }

        #login .form-control + .input-icon {
            background-color: #ffffff;
        }

#login-error {
    text-align: center;
    margin-top: 24px;
}

@media (max-width: 480px) {
    #login {
        max-width: 300px;
    }
}
/******************************/
.st-main {
    width: 100%;
    padding-left: 220px;
    min-height: 100%;
    position: relative;
    overflow: hidden;
    transition: all 150ms;
}

body.sidebar-collapsible:not(.sidebar-open) .st-main {
    padding-left: 45px;
}

body.sidebar-offcanvas .st-main {
    padding-left: 0;
}

body.sidebar-offcanvas.sidebar-collapsible .st-main {
    padding-left: 45px;
}
/* ********* HELPERS **********/
.ptn {
    padding-top: 0 !important;
}

.pts {
    padding-top: 5px !important;
}

.ptm {
    padding-top: 10px !important;
}

.ptl {
    padding-top: 20px !important;
}

.prn {
    padding-right: 0 !important;
}

.prs {
    padding-right: 5px !important;
}

.prm {
    padding-right: 10px !important;
}

.prl {
    padding-right: 20px !important;
}

.pbn {
    padding-bottom: 0 !important;
}

.pbs {
    padding-bottom: 5px !important;
}

.pbm {
    padding-bottom: 10px !important;
}

.pbl {
    padding-bottom: 20px !important;
}

.pln {
    padding-left: 0 !important;
}

.pls {
    padding-left: 5px !important;
}

.plm {
    padding-left: 10px !important;
}

.pll {
    padding-left: 20px !important;
}

.mtn {
    margin-top: 0px !important;
}

.mts {
    margin-top: 5px !important;
}

.mtm {
    margin-top: 10px !important;
}

.mtl {
    margin-top: 20px !important;
}

.mrn {
    margin-right: 0px !important;
}

.mrs {
    margin-right: 5px !important;
}

.mrm {
    margin-right: 10px !important;
}

.mrl {
    margin-right: 20px !important;
}

.mbn {
    margin-bottom: 0px !important;
}

.mbs {
    margin-bottom: 5px !important;
}

.mbm {
    margin-bottom: 10px !important;
}

.mbl {
    margin-bottom: 20px !important;
}

.mln {
    margin-left: 0px !important;
}

.mls {
    margin-left: 5px !important;
}

.mlm {
    margin-left: 10px !important;
}

.mll {
    margin-left: 20px !important;
}

.padding-s {
    padding: 5px;
}

.padding-m {
    padding: 10px;
}

.padding-l {
    padding: 20px;
}

.padding-xl {
    padding: 30px;
}

hr.small {
    margin-top: 10px;
    margin-bottom: 10px;
}

.no-padding {
    padding: 0 !important;
}

.no-margin {
    margin: 0 !important;
}
/**********   FULL-LOADER  **************/
.loader-anim,
.loader-anim-calendar,
.loader-anim-widget {
    position: relative;
    display: block;
    width: 40px;
    height: 40px;
    margin: 120px auto 120px -20px;
    top: 50%;
    left: 50%;
}

.svg-loader {
    position: relative;
    display: block;
    width: 40px;
    height: 40px;
    margin: 120px auto 120px -20px;
    top: 50%;
    left: 50%;
}

.full-loader {
    position: fixed;
    z-index: 998;
    height: 2em;
    width: 2em;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}
    /* Transparent Overlay */
    .full-loader:before {
        content: '';
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.75);
    }
    /* :not(:required) hides these rules from IE9 and below */
    .full-loader:not(:required) {
        /* hide "loading..." text */
        font: 0/0 a;
        color: transparent;
        text-shadow: none;
        background-color: transparent;
        border: 0;
    }

        .full-loader:not(:required):after {
            font-family: 'FontAwesome';
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            content: "\f1ce";
            display: block;
            font-size: 48px;
            -webkit-animation: spinner 1500ms infinite linear;
            -moz-animation: spinner 1500ms infinite linear;
            -ms-animation: spinner 1500ms infinite linear;
            -o-animation: spinner 1500ms infinite linear;
            animation: spinner 1500ms infinite linear;
            color: #4d5865;
            position: absolute;
            width: auto;
            margin-left: -24px;
            opacity: 0.65;
        }
/* Animation */
@-webkit-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-moz-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-o-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
/**********   Typeahead  **************/
.dropdown-menu.typeahead {
    display: none;
    opacity: 1;
    visibility: visible;
    width: auto;
    margin-top: 5px;
    border: 2px solid #42D000;
    padding: 5px 0;
    background-color: #ffffff;
    border-radius: 3px;
}

    .dropdown-menu.typeahead li a {
        padding: 6px 14px;
    }

    .dropdown-menu.typeahead li:first-child a,
    .dropdown-menu.typeahead li:last-child a {
        padding: 6px 14px;
        border-radius: 0;
    }
/*typeAhead version 0.11.1*/
.tt-menu {
    padding: 5px;
    width: 100%;
    margin-top: 5px;
    background-color: #ffffff;
    /* border: 1px solid #d7dbe0; */
    border-radius: 3px;
    -webkit-box-shadow: 0px 1px 5px rgba(50, 50, 50, 0.2);
    box-shadow: 0px 1px 5px rgba(50, 50, 50, 0.2);
    /* para activar el scroll vertical del select de sugerencias */
    max-height: 220px;
    overflow-y: auto;
}

.tt-suggestion {
    padding: 5px 15px;
    /*border-bottom: 1px solid #ebedf0;*/
}

    .tt-suggestion:hover {
        cursor: pointer;
        /*color: #fff;*/
        background-color: #ebedf0;
        border-radius: 3px;
    }

    .tt-suggestion:last-child {
        border-bottom: none;
    }

    .tt-suggestion.tt-cursor {
        background-color: #ebedf0;
        border-radius: 3px;
        cursor: pointer;
    }

    .tt-suggestion strong {
        color: #b2bcc5;
        font-weight: 400;
        margin-right: 1px;
    }

.twitter-typeahead {
    position: initial !important;
    display: inherit !important;
}
/*typeAhead version 0.11.1*/
.tt-menu > [class^='tt-dataset-'] {
    margin: 0;
}

.typeahead-group {
    font-size: 11px;
    /*text-transform: uppercase;*/
    text-align: left;
    margin: 0 10px;
    padding-bottom: 5px;
    /*border-bottom: 1px solid #ebedf0;*/
    color: #2d3845;
}

    .typeahead-group a {
        cursor: pointer;
        /*
  color: #2d3845 !important;
  text-decoration: none;
  */
    }

.tt-suggestion p {
    margin: 0;
}

.tt-hint {
    margin-top: 0;
    color: #d7dbe0 !important;
}

.loading-tableData-tr {
    background-color: #ffffff !important;
    /*background-image: url('../images/input-loading.gif') !important;*/
    background-size: 26px 22px;
    background-position: center center;
    background-repeat: no-repeat;
}

    .loading-tableData-tr.tt-hint {
        background-color: transparent !important;
        background-image: none !important;
    }

.loading-input-field {
    background-color: #ffffff !important;
    /*background-image: url('../images/input-loading.gif') !important;*/
    background-size: 26px 22px;
    background-position: right center;
    background-repeat: no-repeat;
}

    .loading-input-field.tt-hint {
        background-color: transparent !important;
        background-image: none !important;
    }
/* ///////////////////////////////////////// */
.clickable {
    cursor: pointer;
}

    .clickable.clickable:hover p {
        text-decoration: underline;
    }

p.module-text-paragraph {
    margin-bottom: 10px;
}

ul.ot-tareas__menu {
    margin: 0 0 0;
    padding: 0;
    list-style: none;
}

    ul.ot-tareas__menu > li {
        padding: 0;
        cursor: pointer;
    }

        ul.ot-tareas__menu > li:not(:last-child) > a {
            margin-bottom: 4px;
        }

        ul.ot-tareas__menu > li > a {
            display: block;
            position: relative;
            text-decoration: none;
            color: #4d5865;
            padding: 8px 15px;
            border-radius: 3px;
            border: 1px solid #f2f5f7;
            background-color: #f8fafb;
        }

        ul.ot-tareas__menu > li:hover > a,
        ul.ot-tareas__menu > li.active > a {
            background-color: #f2f5f7;
        }

#rowTaskSteps ul {
    padding-left: 20px;
}

#rowTaskSteps li {
    list-style-type: none;
}

.no-upper {
    text-transform: none;
    font-size: 14px;
}
/* ///////////// Citaci󮠖ISTA CLIENTE  //////////////////// */
.vista-cliente #listOrdenes .expand-col {
    display: none;
}

.vista-cliente #listOrdenes .btn {
    width: 100%;
}

    .vista-cliente #listOrdenes .btn:last-child {
        margin-top: 10px;
    }

    .vista-cliente #listOrdenes .btn:first-child {
        margin-top: 0px;
    }

.vista-cliente .tabla-huecos {
    margin-top: 10px;
    border: 1px solid #d7dce0;
}

    .vista-cliente .tabla-huecos .hueco-disponible .btn-rounded {
        padding: 2px 6px 2px;
    }

    .vista-cliente .tabla-huecos .fa-times {
        padding-top: 6px;
        /*display: none;*/
    }

    .vista-cliente .tabla-huecos .table > thead > tr > th {
        font-size: 12px;
    }

        .vista-cliente .tabla-huecos .table > thead > tr > th span {
            display: block;
        }

.dv-cita h4 {
    line-height: 1.3;
    font-size: 14px;
    font-weight: 600;
}

p#cita-tipText0,
p#cita-tipText1 {
    line-height: 1.2;
    font-weight: 600;
}

#cita-tipText0 small,
#cita-tipText1 small {
    font-weight: normal;
    line-height: 0;
}

.vista-cliente #datosCliente h6 {
    font-size: 13px;
}

.vista-cliente #datosCliente .mrm {
    display: block;
}

.cita-details {
    margin: 20px 0 40px;
    padding: 20px;
    color: #fff;
    font-size: 24px;
}

.tabla-huecos .table-bordered > tbody > tr > td.no-habiles {
    background-color: #f9fafb;
}
/* ///////////// Formularios  //////////////////// */
.form-wrap.form-builder .btn,
.rendered-form .btn {
    color: #fff;
}

.form-wrap.form-builder .form-actions {
    float: right;
    margin-top: 15px !important;
}

.checkbox-group,
.radio-group {
    margin-left: 20px !important;
}

.form-wrap.form-builder .frmb .field-label,
.form-wrap.form-builder .frmb .legend {
    color: #666;
    margin-bottom: 5px;
    line-height: 27px;
    font-size: 16px;
    font-weight: normal;
    text-transform: initial;
}

.form-wrap.form-builder .frmb li {
    position: relative;
    border: 1px dashed #d7dce0;
    padding: 12px !important;
    clear: both;
    margin-left: 0;
    margin-bottom: 3px;
    background-color: #fff;
    transition: background-color 250ms ease-in-out, margin-top 400ms;
}

.form-wrap.form-builder .frmb-control li {
    padding: 10px !important;
    box-shadow: inset 0 0 0 1px #d7dce0 !important;
}

.form-wrap.form-builder .frmb .form-elements {
    padding: 10px !important;
    border: 1px solid #d7dce0 !important;
}

.icon-starRating:before,
.icon-dynamicLabel:before,
.icon-signature:before,
.icon-qrScan:before,
.icon-barcodeScan:before {
    font-family: "FontAwesome" !important;
}

.icon-starRating:before {
    content: "\f006";
}

.icon-dynamicLabel:before {
    content: "\f02c";
}

.icon-signature:before {
    content: "\f044";
}

.icon-qrScan:before {
    content: "\f029";
}

.icon-barcodeScan:before {
    content: "\f02a";
}

.st-header {
    border-bottom: 1px solid #eaedef;
    box-sizing: content-box;
    padding: 5px 20px;
    background: #ffffff;
}

    .st-header:after {
        content: '';
        display: block;
        clear: both;
    }

.st-header__menu {
    display: none;
    margin-right: 20px;
}

    .st-header__menu .btn {
        padding: 4px 10px;
        background: #4d5865;
        color: #b8bdc1;
    }

        .st-header__menu .btn:hover {
            color: #fff;
        }

.st-header__title {
    display: inline;
    font-size: 16px;
    line-height: 30px;
    /*text-transform: capitalize;*/
}

.st-header__actions {
    white-space: nowrap;
    text-align: right;
    user-select: none;
    margin: 0;
    padding: 0;
    list-style: none;
}

.st-header__act {
    display: inline-block;
    padding: 5px 10px;
    cursor: pointer;
    position: relative;
}

    .st-header__act:hover {
        opacity: 0.8;
    }

.st-header__count {
    background: #ffffff;
    position: absolute;
    padding: 2px 2px;
    top: 0px;
    left: 50%;
    font-size: 10px;
    line-height: 10px;
    border-radius: 2px;
}

    .st-header__count:empty {
        display: none;
    }

body.sidebar-offcanvas:not(.sidebar-collapsible) .st-header__menu {
    display: block;
}

@media (max-width: 768px) {
    .st-header__title {
        font-size: 18px;
    }

    .st-header__act:last-child {
        padding-right: 0;
    }

    body.sidebar-offcanvas:not(.sidebar-collapsible) .st-header > .fluid-cols > .expand-col {
        text-align: center;
        border-right: 25px solid transparent;
    }
}

.st-crumbs {
    background: #ffffff;
    padding: 5px 20px;
    border-bottom: 1px solid #eaedef;
}

    .st-crumbs ul {
        margin: 0;
        padding: 0;
        list-style: none;
        font-size: 0;
        display: inline;
    }

        .st-crumbs ul li {
            display: inline;
        }

            .st-crumbs ul li:not(:last-child):after {
                content: '\\';
                display: inline-block;
                padding: 0 10px;
                font-size: 14px;
            }

            .st-crumbs ul li a,
            .st-crumbs ul li span {
                font-size: 14px;
                line-height: 20px;
            }

            .st-crumbs ul li a {
                text-decoration: underline;
                color: #686e71;
            }

                .st-crumbs ul li a:hover {
                    color: #686e71;
                    text-decoration: none;
                }

    .st-crumbs > .fluid-cols > .expand-col {
        padding: 7px 0;
        border-right: 20px solid transparent;
    }

    .st-crumbs > .fluid-cols > .min-col {
        width: 180px;
    }

@media (max-width: 530px) {
    .st-crumbs > .fluid-cols > .expand-col {
        display: none;
    }

    .st-crumbs > .fluid-cols > .min-col {
        width: 100%;
    }
}

.st-spanel {
    position: fixed;
    z-index: 1000;
    top: 40px;
    bottom: 0;
    right: -250px;
    background: #ffffff;
    border: 1px solid #d7dce0;
    border-right: 0;
    width: 250px;
    transition: right 150ms;
}

    .st-spanel.open {
        right: 0px;
        box-shadow: 0 2px 2px 0px rgba(0, 0, 0, 0.12);
    }

.st-spanel__tabs {
    position: relative;
    z-index: 2;
}

    .st-spanel__tabs > ul {
        display: table;
        width: 100%;
    }

        .st-spanel__tabs > ul li {
            display: table-cell;
        }

            .st-spanel__tabs > ul li.active a {
                background: transparent;
                border-bottom: 0;
            }

            .st-spanel__tabs > ul li.active.alt a {
                background: #f8f8f8;
                border-bottom: 1px solid #f8f8f8;
            }

            .st-spanel__tabs > ul li a {
                color: #686e71;
                text-decoration: none;
                background: #e7edf1;
                border-right: 1px solid #d7dce0;
                border-bottom: 1px solid #d7dce0;
                outline: 0;
            }

                .st-spanel__tabs > ul li a:hover {
                    background: transparent;
                    color: #686e71;
                    text-decoration: none;
                }

            .st-spanel__tabs > ul li:last-child a {
                border-right: 0;
            }

.st-spanel__content {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-top: 40px solid transparent;
    width: 100%;
}

    .st-spanel__content .tab-content,
    .st-spanel__content .tab-pane {
        height: 100%;
    }

.st-spanel__close {
    cursor: pointer;
    text-align: center;
    vertical-align: top;
}

    .st-spanel__close a {
        line-height: 19px;
    }

.st-spanel__settings {
    padding: 15px 0;
    background-color: #f8f8f8;
    height: 100%;
}

    .st-spanel__settings .st-settings {
        padding: 0 15px;
    }

.st-spanel .st-notifications {
    height: 100%;
}

.st-spanel__scroll {
    height: 100%;
}

.st-content {
    padding: 10px 0;
}

.st-panel {
    color: #4d5865;
    margin-bottom: 20px;
}

    .st-panel:last-child {
        margin-bottom: 0;
    }

    .st-panel h2 {
        color: #4d5865;
        font-size: 14px;
        font-weight: 700;
        margin: 0 0 10px;
    }

.st-panel__cont {
    border: 1px solid #eaedef;
    border-radius: 1px;
    background: #ffffff;
    position: relative;
}

.st-panel__header {
    padding: 0;
    min-height: 0;
    margin: 0 20px;
    height: 50px;
    line-height: 20px;
    position: relative;
    z-index: 5;
    transition: height 150ms, margin-bottom 150ms;
}

    .st-panel__header.select-inside {
        height: 60px;
    }

    .st-panel__header + .st-panel__content {
        padding-top: 0;
    }

    .st-panel__header[data-tool="collapse"] {
        cursor: pointer;
    }

    .st-panel__header .fluid-cols,
    .st-panel__header .expand-col,
    .st-panel__header .min-col {
        vertical-align: top;
        height: 100%;
        white-space: nowrap;
    }

    .st-panel__header .expand-col {
        padding-top: 15px;
    }

    .st-panel__header .min-col {
        padding-top: 5px;
        border-left: 10px solid transparent;
    }

    .st-panel__header ul.pagination {
        font-size: 0;
    }

        .st-panel__header ul.pagination > li {
            display: inline-block;
            vertical-align: top;
        }

.st-panel__header__block {
    padding: 0;
    min-height: 0;
    margin: -15px 0 0;
    height: 50px;
    line-height: 20px;
}

    .st-panel__header__block + .st-panel__content {
        padding-top: 0;
    }

    .st-panel__header__block[data-tool="collapse"] {
        cursor: pointer;
    }

    .st-panel__header__block .fluid-cols,
    .st-panel__header__block .expand-col,
    .st-panel__header__block .min-col {
        vertical-align: top;
        height: 100%;
        white-space: nowrap;
    }

    .st-panel__header__block .expand-col {
        padding-top: 15px;
    }

    .st-panel__header__block .min-col {
        padding-top: 5px;
        border-left: 10px solid transparent;
    }

    .st-panel__header__block ul.pagination {
        font-size: 0;
    }

        .st-panel__header__block ul.pagination > li {
            display: inline-block;
            vertical-align: top;
        }

.st-panel__title {
    color: #4d5865;
    font-size: 14px;
    font-weight: 700;
}

    .st-panel__title small {
        font-weight: 400;
        font-size: 12px;
        padding-left: 7px;
        color: #5d6875;
    }

    .st-panel__title .dataTables_info {
        display: inline;
    }

        .st-panel__title .dataTables_info .select-info {
            padding-left: 3px;
        }

.st-panel__tools {
    position: relative;
    display: inline-block;
    padding-top: 10px;
    font-size: 0;
    white-space: nowrap;
}

    .st-panel__tools > .st-panel-tool:last-child {
        margin-right: -6px;
    }

    .st-panel__tools .st-panel-tool--menu:last-child {
        margin-right: 0;
    }

.st-panel__form {
    display: inline-block;
    padding: 5px 0 0 5px;
    line-height: 1em;
    vertical-align: top;
}

    .st-panel__form:not(:last-child) {
        padding-right: 6px;
    }

    .st-panel__form .pagination-xs {
        padding: 3px 0;
    }

.st-panel__content {
    padding: 15px 0;
    margin: 0 20px 0;
    line-height: 1.5;
    position: relative;
    box-sizing: content-box;
}

    .st-panel__content p:not(:last-child) {
        margin-bottom: 10px;
    }

    .st-panel__content p + p {
        margin-top: 10px;
    }

    .st-panel__content p .btn {
        margin-bottom: 3px;
    }

    .st-panel__content .tab-content:first-child {
        margin-top: 0;
        border-top: 0;
        padding-top: 0;
    }

    .st-panel__content .btn-toolbar .btn-group {
        margin-bottom: 3px;
    }

.st-panel__footer {
    padding: 5px 20px;
    border-top: 1px solid #eaedef;
    line-height: 24px;
    color: #5d6875;
    font-size: 12px;
}

    .st-panel__footer .st-panel__tools {
        padding-top: 0;
        vertical-align: top;
    }

    .st-panel__footer .st-panel-tool {
        height: 24px;
        padding-top: 0;
    }

    .st-panel__footer .expand-col {
        border-right: 10px solid transparent;
    }

    .st-panel__footer ul.pagination {
        white-space: nowrap;
    }

        .st-panel__footer ul.pagination > li > a,
        .st-panel__footer ul.pagination > li > span {
            padding: 3px 6px;
            font-size: 12px;
            line-height: 14px;
            float: none;
            display: inline-block;
        }

    .st-panel__footer .dataTables_info .select-info {
        padding-left: 3px;
    }

.st-panel__tabs {
    display: inline-block;
    font-size: 14px;
    padding-left: 10px;
    height: 40px;
    vertical-align: top;
}

    .st-panel__tabs ul.nav {
        border-bottom: 0;
        margin-top: 1px;
        font-size: 0;
    }

        .st-panel__tabs ul.nav > li {
            font-size: 14px;
        }

            .st-panel__tabs ul.nav > li:last-child > a {
                margin-right: 0px;
            }

            .st-panel__tabs ul.nav > li > a {
                padding: 10px 15px;
            }

@media (min-width: 768px) {
    .st-panel__tabs ul.nav {
        white-space: nowrap;
        min-width: 100px;
    }

        .st-panel__tabs ul.nav > li {
            float: none;
            display: inline-block;
        }
}

.st-panel--max {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 1010;
    overflow: auto;
    padding: 15px;
    background-color: #f2f5f7;
}

    .st-panel--max .st-panel__cont {
        min-height: 100%;
    }

    .st-panel--max .st-panel__content {
        padding-bottom: 50px;
    }

    .st-panel--max .st-panel__footer {
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 100%;
    }

.st-panel--border .st-panel__content {
    border-top: 1px solid #eaedef;
    padding-top: 10px;
    margin-top: -5px;
}

.st-panel--collapsed .st-panel__tabs {
    display: none;
}

.st-panel--collapsed .st-panel__content {
    display: none;
}

.st-panel__buttons {
    padding: 15px 0;
    margin: -15px 20px 0;
    line-height: 1.5;
    box-sizing: content-box;
}

.st-panel-tool {
    position: relative;
    display: inline-block;
    padding: 0 6px;
    font-size: 14px;
    text-align: center;
    color: #5d6875;
    vertical-align: middle;
    cursor: pointer;
    line-height: inherit;
    height: auto;
    transition: transform 150ms;
}

    .st-panel-tool:hover i {
        opacity: 1;
    }

    .st-panel-tool i {
        opacity: 0.7;
    }

        .st-panel-tool i.fa {
            line-height: inherit;
            white-space: nowrap;
        }

a.st-panel-tool {
    color: #5d6875;
    outline: 0;
}

.x_panel,
.collapsible-panel {
    margin-right: -11px;
    margin-left: -11px;
    margin-top: -11px;
    margin-bottom: 10px;
}

    .x_panel .header-buttons,
    .collapsible-panel .header-buttons {
        margin-top: 5px;
    }

    .x_panel .st-panel__content,
    .collapsible-panel .st-panel__content {
        padding: 15px 0;
        padding-top: 0;
        margin: 0 20px 0;
        line-height: 1.5;
        position: relative;
        box-sizing: content-box;
    }

.st-panel__content.grupal .st-panel__title {
    font-size: 13px;
    margin-right: 5px;
}

.st-panel__content.grupal .well {
    margin-top: 1px;
    margin-bottom: 15px;
}

.st-inputbar {
    display: table;
    width: 100%;
}

    .st-inputbar .form-control {
        width: 100%;
    }

    .st-inputbar .btn-group {
        font-size: 0;
        vertical-align: top;
    }

        .st-inputbar .btn-group .btn {
            float: none;
            white-space: nowrap;
        }

.st-inputbar-input {
    display: table-cell;
    vertical-align: top;
}

    .st-inputbar-input + .st-inputbar-btn {
        padding-left: 8px;
    }

.form-horizontal .st-inputbar-input + .st-inputbar-btn {
    padding-left: 20px;
}

.st-inputbar-btn {
    display: table-cell;
    width: 1%;
    white-space: nowrap;
    vertical-align: top;
}

    .st-inputbar-btn .btn {
        vertical-align: top;
    }

    .st-inputbar-btn .select2-container {
        vertical-align: top;
    }

    .st-inputbar-btn + .st-inputbar-input {
        padding-left: 8px;
    }

.st-cropper__preview {
    width: 200px;
    height: 112px;
    max-width: 100%;
    overflow: hidden;
    margin-bottom: 15px;
}

.st-cropper__upload {
    margin-bottom: 15px;
}

@media (max-width: 768px) {
    .st-cropper__upload .btn {
        padding: 5px 10px;
        font-size: 13px;
        line-height: 18px;
        border-radius: 3px;
    }
}

.st-cropper__actions {
    margin-bottom: 15px;
}

@media (max-width: 768px) {
    .st-cropper__actions .btn {
        padding: 5px;
        font-size: 13px;
        line-height: 18px;
        border-radius: 3px;
    }
}

.st-cropper-img {
    max-width: 100%;
}

table.table-images {
    margin-bottom: 0;
}

    table.table-images:not(:first-child) {
        margin-top: 15px;
    }

    table.table-images > tbody > tr > td {
        padding: 15px;
    }

.table-images__preview {
    display: block;
    width: 150px;
    height: 85px;
    background-position: 50% 50%;
    background-size: cover;
    background-color: #e7edf1;
}

.table-images__res {
    display: block;
}

.table-images__order {
    text-align: center;
    width: 45px;
}

.st-error {
    height: 100%;
    position: relative;
    text-align: center;
    margin: 0 20px;
}

.st-error__cont {
    text-align: center;
    display: inline-block;
    vertical-align: top;
    margin-top: 100px;
    width: 100%;
    max-width: 358px;
}

@media screen and (max-width: 767px) {
    .st-error__cont {
        margin-top: 50px;
    }
}

.st-error__code {
    font-size: 150px;
    line-height: 150px;
    font-weight: bold;
}

@media screen and (max-width: 767px) {
    .st-error__code {
        font-size: 90px;
        line-height: 90px;
    }
}

.st-error__message {
    margin: 10px 0 20px;
}

.st-bar {
    background: #ffffff;
    padding: 10px 10px;
    border: 1px solid #eaedef;
    margin-bottom: 20px;
}

.st-loading_on {
    position: relative;
}

    .st-loading_on .st-loading {
        display: block;
    }

.st-loading {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.7);
    z-index: 1000;
    text-align: center;
}

    .st-loading:before {
        content: '';
        display: inline-block;
        height: 100%;
        vertical-align: middle;
    }

.st-loading__content {
    display: inline-block;
    vertical-align: middle;
}

    .st-loading__content .fa {
        color: #974680;
    }

.st-datatable td small {
    color: #5d6875;
}

div.st-datatable__footer {
    margin-top: 10px;
}

    div.st-datatable__footer:after {
        content: '';
        display: block;
        clear: both;
    }

    div.st-datatable__footer .dataTables_length {
        float: left;
    }

        div.st-datatable__footer .dataTables_length label {
            font-size: 0;
            margin: 0;
        }

    div.st-datatable__footer div.dataTables_paginate {
        float: right;
        margin-top: 0;
        text-align: right;
    }

.st-source {
    position: relative;
}

    .st-source:not(.st-source--copy):after {
        content: 'Source';
        display: block;
        position: absolute;
        font-size: 12px;
        color: #5d6875;
        top: 1px;
        right: 1px;
        padding: 5px 10px;
        background: #ffffff;
        border-left: 1px solid #d7dce0;
        border-bottom: 1px solid #d7dce0;
        border-bottom-left-radius: 2px;
    }

.st-source__copy {
    display: block;
    position: absolute;
    font-size: 12px;
    color: #5d6875;
    top: 1px;
    right: 1px;
    padding: 5px 10px;
    background: #ffffff;
    border-left: 1px solid #d7dce0;
    border-bottom: 1px solid #d7dce0;
    border-bottom-left-radius: 2px;
    cursor: pointer;
}

    .st-source__copy:hover {
        background-color: #fafafa;
    }

.st-popup {
    display: none;
    text-align: left;
    position: absolute;
    z-index: 1000;
    font-size: 14px;
    border: 1px solid #d7dce0;
    border-radius: 1px;
    background: #ffffff;
    padding: 10px 20px 15px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}

.st-search {
    width: 100%;
    padding: 2px 0;
}

    .st-search input {
        width: 100%;
    }

.st-userpopup {
    width: 230px;
    padding: 15px;
}

.st-userpopup__cont {
    padding: 0px;
}

.st-userpopup__main {
    overflow: hidden;
}

.st-userpopup__photo {
    float: left;
    position: relative;
    width: 50px;
    height: 50px;
    padding: 5px;
    background-color: #f2f5f7;
    border: 1px solid #eff3f5;
    border-radius: 5px;
}

.st-userpopup__img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #ffffff;
    background-size: cover;
    background-repeat: no-repeat;
}

.st-userpopup__info {
    float: left;
    width: 100%;
    padding-left: 65px;
    margin-left: -50px;
    padding-top: 1px;
}

.st-userpopup__name {
    font-weight: 700;
    margin-bottom: 3px;
    margin-top: 2px;
}

ul.st-userpopup__menu {
    margin: 15px 0 0;
    padding: 0;
    list-style: none;
}

    ul.st-userpopup__menu > li {
        padding: 0;
        cursor: pointer;
    }

        ul.st-userpopup__menu > li:not(:last-child) > a {
            margin-bottom: 4px;
        }

        ul.st-userpopup__menu > li > a {
            display: block;
            position: relative;
            text-decoration: none;
            color: #4d5865;
            padding: 8px 15px;
            /*font-weight: 600;*/
            border-radius: 3px;
            border: 1px solid #f2f5f7;
            background-color: #f8fafb;
        }

            ul.st-userpopup__menu > li > a .min-col {
                position: relative;
            }

        ul.st-userpopup__menu > li:hover > a,
        ul.st-userpopup__menu > li.active > a,
        ul.st-userpopup__menu > li:hover > a .st-userpopup__count,
        ul.st-userpopup__menu > li.active > a .st-userpopup__count {
            background-color: #f2f5f7;
        }

.st-userpopup__count {
    background: #f8fafb;
    position: absolute;
    padding: 2px;
    top: -5px;
    font-size: 10px;
    line-height: 10px;
    border-radius: 2px;
    left: 50%;
}

.row.no-gutter {
    margin-right: -5px;
    margin-left: -5px;
}

.mainPanel-padding {
    padding-right: 5px;
    padding-left: 5px;
}
/* task list */
.task-list > li {
    margin-bottom: 32px;
}

.task-list p {
    position: relative;
    font-weight: normal;
    font-size: 12px;
    margin: 0 0 0px;
    padding: 0;
}

    .task-list p .label-percent {
        position: absolute;
        right: 0;
    }

.mainPanel .panel {
    padding: 0 !important;
    margin-bottom: 10px;
}

.mainPanel .st-panel__header {
    padding: 0 10px;
    min-height: 0;
    margin: 0;
    height: 32px;
    background-color: #f2f3f5;
    border-bottom: 1px solid #d7dce0;
}

    .mainPanel .st-panel__header .expand-col {
        padding-top: 5px;
    }

    .mainPanel .st-panel__header .min-col {
        padding-top: 2px;
    }

.mainPanel .st-panel__tools {
    padding-top: 0;
}

.mainPanel .st-panel__content {
    padding: 0;
    margin: 0;
}

.mainPanel .st-panel__cont {
    border: 1px solid #d7dce0;
}

.mainPanel .panel-heading .panel-title {
    font-size: 12px;
}

.mainPanel .panel-heading .dv-botones {
    line-height: 0;
    position: absolute;
    top: 3px;
    right: 5px;
}

.btn.xsmall {
    line-height: 0;
    font-size: 9px;
    margin-top: 5px;
    margin-right: 2px;
}

    .btn.xsmall:last-child {
        margin-right: 5px;
    }

.mainPanel .panel-body {
    padding: 10px;
}

.mainPanel .btn-toggleTecnico {
    outline: 0;
}

.mainPanel .btn-toggleTecnico {
    outline: 0;
    opacity: 0.5;
    cursor: pointer;
}

    .mainPanel .btn-toggleTecnico:hover,
    .mainPanel .btn-toggleTecnico:focus {
        opacity: 1;
    }

.mainPanel .panel-footer {
    padding: 10px 15px;
    background-color: #fff;
    border-top: 1px solid #fff;
}

@media (min-width: 1280px) {
    .mainPanel .col-md-2.mainPanel-padding {
        width: 12%;
    }

    .mainPanel .col-md-8.mainPanel-padding {
        width: 75.99%;
    }
}
/* CON PADDING */
.tree {
    margin: -18px 0 -18px -25px;
    font-size: 9px;
}

    .tree:first-child {
        margin-top: 5px;
    }
    /*****************************/
    .tree li {
        margin: 0px 0;
        list-style-type: none;
        position: relative;
        padding: 0;
    }

        .tree li::before {
            content: '';
            position: absolute;
            top: 0;
            width: 1px;
            height: 100%;
            right: auto;
            left: -25px;
            border-left: 1px solid #D4D8DD;
            bottom: 50px;
        }

        .tree li::after {
            content: '';
            position: absolute;
            top: 9px;
            width: 25px;
            height: 20px;
            right: auto;
            left: -25px;
            border-top: 1px solid #D4D8DD;
        }

        .tree li a {
            display: inline-block;
            padding: 2px 5px;
            /*text-decoration: none;*/
        }
    /*Remove connectors before root*/
    .tree > ul > li::before,
    .tree > ul > li::after {
        border: 0;
    }
    /*Remove connectors after last child*/
    .tree li:last-child::before {
        height: 10px;
    }
/*Connector styles on hover*/
/*
 .tree li a:hover+ul li::after, .tree li a:hover+ul li::before, .tree li a:hover+ul::before, .tree li a:hover+ul ul::before {
    border-color: #3d484e;
}
*/
/* ***********  SCHEDULER  ********************* */
/*

*/
.fc-event .fc-bg {
    opacity: 0.15;
}

.fc-event {
    color: #ffffff;
    border: 0;
    border-left: 4px solid rgba(0, 0, 0, 0.1);
    font-size: 10px;
    /*
    border: 1px solid #ffffff;
    background: repeating-linear-gradient(
    45deg,
    #606dbc,
    #606dbc 5px,
    transparent 5px,
    transparent 12px
  );
*/
}

    .fc-event.citada {
        background: #afd2e2;
        /*background: #02a8f4;*/
    }

    .fc-event.en-camino {
        background: #9365b8;
    }

    .fc-event.iniciada {
        background: #69C62B;
    }

    .fc-event.linea-verificada {
        background: repeating-linear-gradient(45deg, #8fd460, #8fd460 5px, #69c62b 5px, #69c62b 10px);
    }

    .fc-event.linea-activada {
        background: repeating-linear-gradient(45deg, #59c014, #59c014 5px, #69c62b 5px, #69c62b 10px);
    }

    .fc-event.suspendida {
        background: #fac51c;
    }

    .fc-event.incidencia {
        background: #e14938;
    }

    .fc-event.finalizada {
        background: #dee0e1;
        color: #b2b3b4;
    }

    .fc-event.alarma:after {
        display: inline-block;
        font-family: 'Flat-UI-Icons';
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        -webkit-font-smoothing: antialiased;
        position: absolute;
        top: 4px;
        right: 5px;
        font-size: 16px;
    }

.fc-timeline-event .fc-content {
    padding: 0 3px;
    white-space: nowrap;
    overflow: hidden;
}

.fc-no-overlap .fc-timeline-event {
    margin-top: 0;
    padding: 9px 0;
    margin-bottom: 0;
}

#external-events .fc-event {
    font-size: 10px;
    line-height: 1.3;
    border-radius: 3px;
    border: 1px solid #d4d8dd;
    background: #f5f5f5 !important;
    color: #3287B2;
    padding: 4px 6px;
    margin-bottom: 3px;
    cursor: move;
    font-weight: 400;
}

    #external-events .fc-event .icon {
        float: right;
        cursor: pointer;
    }

        #external-events .fc-event .icon:before {
            margin-right: 0 !important;
        }

    #external-events .fc-event.urgencia {
        border: 1px solid #dfcb86;
        background: #fff7c2 !important;
        color: #947459;
    }

#external-events .label {
    font-size: 100%;
    background-color: #28b5f6;
    color: #fff;
}

.ots-filter-container {
    padding: 10px;
}

.ots-filter-box {
    position: relative;
}

    .ots-filter-box input {
        font-size: 12px;
        height: 28px;
        margin-bottom: 0px;
    }

    .ots-filter-box .input-icon {
        top: 3px !important;
        right: -5px !important;
        line-height: 25px !important;
        font-size: 12px !important;
        background-color: transparent !important;
    }

.fc-license-message {
    display: none;
}

.fc-unthemed .fc-divider,
.fc-unthemed .fc-list-heading td,
.fc-unthemed .fc-popover .fc-header {
    background: #f5f5f5;
}

.fc-toolbar {
    display: none;
}

.fc-resource-area td {
    font-size: 11px;
}

.fc-resource-area th {
    font-size: 14px;
}

.fc-resource-area .fc-expander {
    color: inherit;
}

.fc-icon {
    opacity: 0.6;
}

.fc-icon-down-triangle:after {
    font-size: 100%;
}

.fc-icon-right-triangle:after {
    font-size: 95%;
    top: 2%;
}
/*
.fc-now-indicator {
    position: absolute;
    border: 1px solid #03a9f4;
    opacity: 0.8;
}
*/
.fc-time-area .fc-now-indicator-arrow {
    margin: 0 -6px;
    border-width: 6px 6px 0px;
    border-left-color: transparent;
    border-right-color: transparent;
}

.tooltip-ot {
    position: absolute;
    z-index: 10001;
    padding: 0;
    width: auto;
    min-width: 300px;
    box-shadow: 0 3px 15px rgba(0, 0, 0, 0.3);
    background: #ffffff;
    border-radius: 0;
}

    .tooltip-ot .tooltip-header {
        padding: 5px 15px;
        border-bottom: 1px solid #eee;
    }

    .tooltip-ot .tooltip-body {
        padding: 12px 15px;
        color: #3d484e;
        font-size: 12px;
        opacity: .85;
    }

#tablaListadosOT .tooltip-inner {
    line-height: 16px;
    padding: 6px;
}
/* ///////////////   PROGRESS BARS  ///////////////////*/
.progress {
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    box-shadow: none;
    background-color: #f1f1f1;
}

    .progress.wide {
        width: 60px;
    }

    .progress .progress-bar {
        -moz-transition-property: width;
        -o-transition-property: width;
        -webkit-transition-property: width;
        transition-property: width;
        -moz-transition-duration: 3s;
        -o-transition-duration: 3s;
        -webkit-transition-duration: 3s;
        transition-duration: 3s;
        -moz-transition-timing-function: ease;
        -o-transition-timing-function: ease;
        -webkit-transition-timing-function: ease;
        transition-timing-function: ease;
        box-shadow: none;
    }

    .progress.progress-sm {
        height: 12px;
    }

    .progress.progress-xs {
        height: 5px;
    }

        .progress.progress-sm .progress-bar,
        .progress.progress-xs .progress-bar {
            text-indent: -9999px;
        }

.progress-bar-success {
    background-color: #41b314;
}

.progress-bar-warning {
    background-color: #e4cb10;
}

.progress-bar-danger {
    background-color: #f9354c;
}
/* ///// CONTRATACION + CITA CLIENTE  //////////*/
#header.standalone-page .navbar-brand img {
    height: 38px;
    padding: 0;
    margin-bottom: 8px;
}

#header.standalone-page .navbar .navbar-inner {
    /*background-color: #000000 !important;*/
}

#tabla-cliente.tabla-huecos .table > thead > tr > th {
    text-transform: capitalize;
}

#tabla-cliente .table > tbody > tr td.time-slot {
    font-size: 0.9em;
}

.cita-details {
    margin: 20px 0 40px;
    padding: 20px;
    color: #fff;
    background-color: #6ac62c;
    font-size: 24px;
}

@media (max-width: 767px) {
    /* On small screens, the nav menu spans the full width of the screen. Leave a space for it. */
    body {
        padding-top: 50px;
    }
    .navbar .navbar-brand {
        margin-left: 24px;
    }
}

.lista-tecnicos ul.activity-list > li {
    padding: 25px 0;
}

    .lista-tecnicos ul.activity-list > li a {
        font-weight: 700;
        color: inherit;
    }

    .lista-tecnicos ul.activity-list > li:not(:last-child) {
        border-bottom: 1px solid #f0f0f0;
    }

    .lista-tecnicos ul.activity-list > li .avatar {
        width: 60px;
    }

    .lista-tecnicos ul.activity-list > li > p {
        margin: 0;
        padding-bottom: 5px;
        padding-left: 4.5em;
    }

    .lista-tecnicos ul.activity-list > li .glyphicon {
        font-size: 14px;
    }

    .lista-tecnicos ul.activity-list > li .timestamp {
        display: block;
        font-size: 12px;
        color: #a3a3a3;
    }

.text-yellow {
    color: #fddb00;
}

.no-borde {
    border: 0 !important;
}

.leyenda_group {
    margin-top: 3px;
    margin-left: 22px;
}

.leyenda {
    float: left;
    margin-top: 2px;
    margin-right: 20px;
    font-size: 13px;
}

.text-leyenda {
    color: #C4C9CF;
    margin-left: 3px;
    font-size: 12px;
}

td .hueco-disponible {

}

td.green .hueco-disponible {
    background-color: #8bc34a;
}

td.red .hueco-disponible {
    background-color: #f44336;
}

td.yellow .hueco-disponible {
    background-color: #ffeb3b;
}

td.orange .hueco-disponible {
    background-color: #ff9800;
}

td .hueco-invent-situacion {
    padding: 0px;
    font-size: 13px;
}

    td .hueco-invent-situacion table td {
        border: 0;
        width: 25%;
    }

table.no-borde td {
    width: 25%;
}

.tablaListadoOts.table {
    font-size: 11px;
    margin-bottom: 0;
}

    .tablaListadoOts.table > thead > tr > th,
    .tablaListadoOts.table > tbody > tr > th,
    .tablaListadoOts.table > tfoot > tr > th,
    .tablaListadoOts.table > thead > tr > td,
    .tablaListadoOts.table > tbody > tr > td,
    .tablaListadoOts.table > tfoot > tr > td {
        padding: 8px 15px;
        line-height: 1.231;
        border-top: 1px solid #eef0f0;
    }

.tablaListadoOts.table-hover > tbody > tr:hover > td,
.tablaListadoOts.table-hover > tbody > tr:hover > th {
    background-color: #e5f2fa;
    cursor: pointer;
}

.tablaListadoOts.table tbody tr.urgencia > td,
.tablaListadoOts.table tbody tr > td.urgencia {
    background-color: #fff7c2;
    border-color: #dfcb86;
}

.tablaListadoOts.table tr .dot {
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    display: inline-block;
    width: 10px;
    height: 10px;
    content: '';
    margin-right: 8px;
}

.tablaListadoOts.table tr.citada .dot {
    background-color: #02a8f4;
}

.tablaListadoOts.table tr.en-camino .dot {
    background-color: #9365b8;
}

.tablaListadoOts.table tr.iniciada .dot {
    background-color: #69C62B;
}

.tablaListadoOts.table tr.linea-verificada .dot {
    background: repeating-linear-gradient(45deg, #8fd460, #8fd460 5px, #69c62b 5px, #69c62b 10px);
}

.tablaListadoOts.table tr.linea-activada .dot {
    background: repeating-linear-gradient(45deg, #59c014, #59c014 5px, #69c62b 5px, #69c62b 10px);
}

.tablaListadoOts.table tr.suspendida .dot {
    background-color: #fac51c;
}

.tablaListadoOts.table tr.incidencia .dot {
    background-color: #e14938;
}

.tablaListadoOts.table tr.finalizada .dot {
    background-color: #dee0e1;
}

.tablaListadoOts.table tr.finalizada {
    color: #b2b3b4;
}

.tablaListadoOts.table tr.alarma {
    background: #fff7c2 !important;
}

.tablaListadoOts.table td button {
    margin-top: 0;
    padding: 8px 10px;
    margin-right: 5px;
}

    .tablaListadoOts.table td button:last-child {
        margin-right: 0;
    }

.tablaListadoOts.table td .btn-table-icon:last-child {
    margin-right: 5px;
}

.st-notifications__head {
    padding: 15px 15px 15px;
    border-bottom: 1px solid #eaedef;
}

    .st-notifications__head small {
        display: block;
        line-height: 14px;
    }

        .st-notifications__head small.empty {
            display: none;
        }

.st-notifications__title {
    font-weight: bold;
    line-height: 16px;
}

.st-notifications__list {
    height: 100%;
    border-top: 61px solid transparent;
    margin-top: -61px;
}

.st-notifications__scroll {
    max-height: 225px;
}

.st-notification {
    padding: 15px 15px 0;
    border-bottom: 1px solid #eaedef;
}

    .st-notification.new {
        background-color: #f8f8f8;
        cursor: pointer;
    }

        .st-notification.new:hover {
            background-color: #f2f2f2;
        }

        .st-notification.new .st-notification__ico {
            background-color: #ffffff;
        }

        .st-notification.new .st-notification__mark {
            opacity: 0;
        }

    .st-notification:after {
        content: '';
        display: block;
        clear: both;
    }

.st-notification__ico {
    float: left;
    width: 35px;
    height: 35px;
    margin-bottom: 15px;
    border: 1px solid #eaedef;
    border-radius: 3px;
    background-color: #f8f8f8;
    text-align: center;
    white-space: nowrap;
    font-size: 0;
}

    .st-notification__ico:before {
        content: '';
        display: inline-block;
        height: 100%;
        vertical-align: middle;
    }

    .st-notification__ico i.fa {
        font-size: 14px;
        vertical-align: middle;
    }

.st-notification__main {
    float: left;
    width: 100%;
    padding-left: 50px;
    margin-left: -35px;
    margin-bottom: 15px;
    font-size: 13px;
    line-height: 16px;
}

    .st-notification__main p {
        margin: 0;
    }

.st-notification__title {
    position: relative;
    padding-right: 15px;
}

    .st-notification__title:after {
        content: '';
        display: block;
        clear: both;
    }

    .st-notification__title b {
        display: inline-block;
        padding-right: 5px;
    }

    .st-notification__title small {
        color: #5d6875;
        display: inline-block;
        font-size: 85%;
    }

.st-notification__mark {
    position: absolute;
    width: 15px;
    height: 16px;
    right: 0;
    top: 0;
    text-align: right;
    color: #5d6875;
    cursor: pointer;
    opacity: 0.6;
    transition: opacity 0.150s;
}

    .st-notification__mark:hover {
        color: #686e71;
        opacity: 1;
    }

.st-notification.new:hover .st-notification__mark {
    opacity: 1;
}

.st-settings {
    color: #4d5865;
}

    .st-settings:not(:last-child) {
        margin-bottom: 15px;
    }

.st-settings--updating .st-settings__loader {
    display: block;
}

.st-settings__loader {
    display: none;
}

.st-settings__list {
    border: 1px solid #eaedef;
    border-radius: 2px;
    background-color: #ffffff;
}

.st-settings__item {
    border-bottom: 1px solid #eaedef;
}

    .st-settings__item:last-child {
        border-bottom: 0;
    }

    .st-settings__item .st-settings__item {
        border-bottom: 0;
    }

        .st-settings__item .st-settings__item .st-settings__row {
            padding: 5px 0;
        }

.st-settings__row {
    display: table;
    width: 100%;
    height: 45px;
    padding: 5px 15px;
}

    .st-settings__row + .st-settings__row {
        padding: 0 15px 10px;
    }

.st-settings__label {
    display: table-cell;
    width: 100%;
    vertical-align: middle;
    padding: 8px 0;
}

.st-settings__control {
    display: table-cell;
    vertical-align: middle;
}

    .st-settings__control .checkbox {
        margin-right: 0;
        width: 0;
    }

    .st-settings__control .irs {
        margin-bottom: 5px;
    }

.st-settings__sub {
    display: none;
    padding: 0 15px 10px;
    width: 100%;
}

.st-settings__expand {
    cursor: pointer;
    user-select: none;
}

.st-settings__ico {
    display: inline-block;
    width: 20px;
    height: 20px;
    text-align: center;
    white-space: nowrap;
    transition: transform 150ms;
}

    .st-settings__ico:before {
        vertical-align: middle;
    }

    .st-settings__ico:after {
        content: '';
        display: inline-block;
        height: 100%;
        vertical-align: middle;
    }

.st-settings__item.expanded .st-settings__ico {
    transform: rotate(90deg);
}

.st-afeed .st-panel__content {
    margin: 0;
}

.st-afeed__tabs {
    margin-bottom: 18px;
}

    .st-afeed__tabs ul {
        text-align: center;
        display: block;
        list-style: none;
        padding: 0;
        font-size: 0;
    }

        .st-afeed__tabs ul > li {
            display: inline-block;
        }

            .st-afeed__tabs ul > li > a {
                text-decoration: none;
                color: #4d5865;
                display: block;
                padding: 5px 10px;
                font-size: 14px;
                border-bottom: 1px solid transparent;
            }

            .st-afeed__tabs ul > li:hover > a,
            .st-afeed__tabs ul > li.active > a {
                border-bottom: 1px solid #56d0e9;
            }

.st-afeed__body .tab-content {
    padding: 0 28px;
}

.st-afeed-calendar {
    margin: 0 0px 10px;
    padding: 5px 0px;
    background-color: #f8f8f8;
}

.st-afeed-stats__item:after {
    display: block;
    content: '';
    clear: both;
}

.st-afeed-stats__item:not(:last-child) {
    margin-bottom: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid #eaedef;
}

.st-afeed-stats__info {
    float: left;
    width: 100%;
    padding-right: 100px;
    margin-right: -85px;
}

.st-afeed-stats__title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.st-afeed-stats__chart {
    float: left;
    width: 85px;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
}

.st-afeed-stats__sparks {
    text-align: right;
    height: 42px;
}

    .st-afeed-stats__sparks:before {
        content: '';
        display: inline-block;
        height: 100%;
        vertical-align: middle;
    }

    .st-afeed-stats__sparks canvas {
        vertical-align: middle !important;
    }

.st-afeed-events {
    max-height: 328px;
}

.st-afeed-events__item:not(:last-child) {
    margin-bottom: 12px;
    padding-bottom: 10px;
    border-bottom: 1px solid #eaedef;
}

.st-afeed-events__title {
    font-weight: bold;
    line-height: 14px;
}

.st-afeed-events__time {
    color: #748191;
}

.st-afeed-events__text {
    margin-top: 5px;
}

.st-afeed-feed {
    max-height: 328px;
}

.st-afeed-feed__item {
    position: relative;
}

    .st-afeed-feed__item:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 15px;
        bottom: 0;
        border-left: 1px solid #f2f5f7;
    }

    .st-afeed-feed__item:not(:last-child) .st-afeed-feed__text {
        padding-bottom: 20px;
    }

.st-afeed-feed__head:after {
    content: '';
    display: block;
    clear: both;
}

.st-afeed-feed__ico {
    float: left;
    width: 30px;
    height: 30px;
    background-color: #f2f5f7;
    position: relative;
    text-align: center;
    font-size: 0;
    white-space: nowrap;
    border-radius: 50%;
}

    .st-afeed-feed__ico .fa {
        font-size: 14px;
        vertical-align: middle;
    }

    .st-afeed-feed__ico:before {
        content: '';
        display: inline-block;
        height: 100%;
        vertical-align: middle;
    }

.st-afeed-feed__title {
    float: left;
    width: 100%;
    padding-left: 45px;
    margin-left: -30px;
    margin-top: 2px;
}

.st-afeed-feed__time {
    line-height: 14px;
}

.st-afeed-feed__ago {
    line-height: 16px;
    color: #748191;
}

.st-afeed-feed__text {
    padding: 5px 0 0 45px;
}

.st-widsbar {
    font-size: 0;
    overflow: auto;
    white-space: nowrap;
}

.st-wid {
    color: #686e71;
    display: inline-block;
    width: 100%;
    font-size: 14px;
    padding: 0 10px;
    margin-bottom: 3px;
    white-space: normal;
}

    .st-wid:first-child {
        padding-left: 0;
    }

    .st-wid:last-child {
        padding-right: 0;
    }

@media (min-width: 600px) {
    .st-wid {
        width: 50%;
    }
}

@media (min-width: 800px) {
    .st-wid {
        width: 33.33%;
    }
}

@media (min-width: 992px) {
    .st-wid {
        width: 25%;
    }
}

.st-wid__title {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

    .st-wid__title span {
        font-weight: bold;
        margin-right: 5px;
    }

    .st-wid__title small {
        font-weight: normal;
        color: #5d6875;
    }

.st-wid__time {
    display: inline-block;
    color: #5d6875;
    font-weight: normal;
}

.st-wid__text {
    margin-top: 5px;
}

    .st-wid__text p {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 85%;
    }

.st-wid__body {
    margin-top: 7px;
    overflow: hidden;
}

.st-earns__chart {
    height: 26px;
    margin: 2px 0 -3px;
}

.st-earns__item {
    font-size: 80%;
}

    .st-earns__item:not(:last-child) {
        margin-bottom: 2px;
    }

    .st-earns__item label {
        display: block;
        margin-bottom: 0;
        line-height: 14px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

.st-earns__val {
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.st-earns__cur {
    font-weight: normal;
    padding-right: 1px;
}

.st-solds {
    overflow: hidden;
    margin: 3px 0 -3px;
}

.st-solds__chart {
    float: left;
    width: 55px;
    height: 55px;
    position: relative;
}

    .st-solds__chart canvas {
        width: 100%;
        height: 100%;
    }

.st-solds__legend {
    float: left;
    width: 100%;
    padding-left: 65px;
    margin-left: -55px;
}

.st-solds__total {
    font-size: 85%;
    line-height: 16px;
}

    .st-solds__total label {
        margin-bottom: 0;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

.st-solds__item {
    font-size: 85%;
}

    .st-solds__item label {
        display: block;
        color: #686e71;
        margin-bottom: 2px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

.st-solds__count {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.st-solds__percent {
    color: #5d6875;
    font-size: 75%;
}

.st-goals .row:not(:last-child) {
    margin-bottom: 3px;
}

.st-goals__item label {
    display: block;
    margin-bottom: 3px;
    font-size: 85%;
    overflow: hidden;
}

.st-goals__item .progress {
    margin-bottom: 0;
}

.st-goals__label {
    float: left;
    width: 100%;
    padding-right: 15px;
    margin-right: -11px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.st-goals__val {
    color: #5d6875;
    font-size: 75%;
    opacity: 0.8;
    display: inline-block;
    padding-top: 4px;
    float: right;
    width: 11px;
}

.st-pops .progress {
    margin-bottom: 9px;
}

.st-pops__item label {
    display: block;
    margin-bottom: 3px;
    font-size: 85%;
    overflow: hidden;
}

.st-pops__item .progress {
    margin-bottom: 0;
}

.st-pops__info {
    overflow: hidden;
    height: 20px;
}

.st-pops__val {
    font-size: 75%;
    display: block;
    line-height: 8px;
    float: left;
    width: 100%;
    padding-left: 20px;
    margin-left: -14px;
    text-align: right;
    padding-top: 12px;
}

.st-pops__chart {
    display: block;
    float: left;
    width: 14px;
}

.st-users tr {
    cursor: pointer;
}

.st-users__contact {
    display: inline-block;
    padding: 3px;
    background: #e7edf1;
    border-radius: 2px;
    margin-right: 3px;
    line-height: 14px;
}

.selected .st-users__contact {
    background: #fff;
}

.st-users__filter.active .st-users__refresh {
    display: block;
}

.st-users__filter.active .st-users__filico {
    display: none;
}

.st-users__refresh {
    display: none;
}

.st-users-preview__photo {
    text-align: center;
}

    .st-users-preview__photo .thumbnail {
        display: inline-block;
        vertical-align: top;
        padding: 0;
        border: 0;
        margin-bottom: 0;
        width: 100%;
        height: 180px;
        background-color: #f2f5f7;
        background-clip: content-box;
        background-size: cover;
        background-position: center;
    }

.st-users-preview__head {
    margin-bottom: 10px;
}

    .st-users-preview__head:after {
        content: '';
        display: block;
        clear: both;
    }

    .st-users-preview__head .fluid-cols .expand-col {
        line-height: 16px;
    }

    .st-users-preview__head .fluid-cols .min-col {
        border-left: 15px solid transparent;
    }

.st-users-preview__fullname {
    font-size: 14px;
    font-weight: 600;
    color: #4d5865;
    display: inline;
    vertical-align: top;
}

.st-users-preview__username {
    color: #5d6875;
    font-size: 85%;
    display: inline;
    vertical-align: top;
}

.st-auth {
    height: 100%;
    position: relative;
    text-align: center;
    margin: 0 20px;
}

    .st-auth:before {
        content: '';
        display: inline-block;
        height: 100%;
        vertical-align: middle;
    }

.st-auth__cont {
    text-align: left;
    display: inline-block;
    vertical-align: middle;
    width: 100%;
    max-width: 250px;
}

.st-auth__back {
    position: absolute;
    top: 30px;
    left: 10px;
    text-align: center;
}

@media screen and (max-width: 767px) {
    .st-auth__back {
        position: static;
    }
}

.st-auth .popover {
    min-width: 250px;
}

.st-auth .alert {
    margin-bottom: 10px;
}

.st-profile .tabs-container {
    padding-bottom: 0;
}

.st-profile__menu {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 15px;
    right: 13px;
    text-align: center;
    line-height: 16px;
    background-color: #f2f5f7;
    border-radius: 50%;
    cursor: pointer;
}

    .st-profile__menu i.fa {
        width: 100%;
        height: 100%;
        text-align: center;
        color: #5d6875;
        vertical-align: top;
        line-height: 20px;
    }

        .st-profile__menu i.fa:hover {
            color: #4d5865;
        }

    .st-profile__menu .dropdown-menu {
        left: -122px;
        right: auto;
    }

.st-profile__photo {
    margin: 10px auto 5px;
    width: 150px;
    height: 150px;
    position: relative;
}

.st-profile__img {
    width: 100%;
    height: 100%;
    border: 5px solid #f2f5f7;
    border-radius: 50%;
    background-color: #f2f5f7;
    background-position: center center;
    background-size: cover;
}

.st-profile__status {
    position: absolute;
    right: 17px;
    bottom: 17px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
}

    .st-profile__status.active {
        background-color: #59cd90;
    }

    .st-profile__status.disabled {
        background-color: #ee6352;
    }

.st-profile__title {
    padding: 10px 0 15px;
    margin: 0 10px;
    text-align: center;
    border-bottom: 1px solid #eaedef;
}

.st-profile__name {
    color: #686e71;
}

.st-profile__username {
    color: #5d6875;
    padding-left: 4px;
}

.st-profile__info {
    margin: 10px;
    padding-bottom: 12px;
    border-bottom: 1px solid #eaedef;
}

.st-profile__field {
    padding: 3px 10px;
}

@media (max-width: 768px) {
    .st-profile__field {
        text-align: center;
    }
}

.st-profile__field i.fa {
    width: 25px;
    text-align: center;
    padding-right: 10px;
}

.st-profile__value {
    display: inline-block;
    min-width: 150px;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    vertical-align: top;
}

.st-profile__links {
    text-align: center;
    margin: 0 10px;
}

    .st-profile__links a {
        display: inline-block;
        padding: 0 10px;
        text-decoration: none;
        color: #4d5865;
        opacity: 0.8;
    }

        .st-profile__links a:hover {
            opacity: 1;
        }

.st-profile__spark {
    height: 20px;
    display: inline-block;
}

.st-profile__bookmarks {
    margin-bottom: -15px;
}

.st-profile__bookmark {
    margin-bottom: 20px;
    border-radius: 5px;
    overflow: hidden;
    height: 140px;
    background-color: #f2f5f7;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    cursor: pointer;
    opacity: 0.8;
}

    .st-profile__bookmark:hover {
        opacity: 1;
    }

.st-profile-overview .table {
    margin-bottom: 0;
}

.st-profile-settings {
    padding: 0 5px;
    margin-top: -5px;
}

    .st-profile-settings .row {
        margin-bottom: 10px;
    }

@media (min-width: 991px) {
    .st-profile-settings > .container-fluid > .row > .col-md-8,
    .st-profile-settings > .container-fluid > .row > .col-md-4 {
        padding: 0 15px;
    }

        .st-profile-settings > .container-fluid > .row > .col-md-8:first-child,
        .st-profile-settings > .container-fluid > .row > .col-md-4:first-child {
            padding-left: 10px;
        }

        .st-profile-settings > .container-fluid > .row > .col-md-8:last-child,
        .st-profile-settings > .container-fluid > .row > .col-md-4:last-child {
            padding-right: 10px;
        }
}

.st-profile-settings .fileinput {
    position: relative;
    overflow: hidden;
}

    .st-profile-settings .fileinput .fileinput-upload {
        position: absolute;
        bottom: 15px;
        width: 100%;
        text-align: center;
    }

        .st-profile-settings .fileinput .fileinput-upload .btn-file {
            position: relative;
            display: inline-block;
            padding: 3px 10px 5px;
            color: rgba(255, 255, 255, 0.9);
            background-color: rgba(0, 0, 0, 0.5);
            border-radius: 3px;
        }

            .st-profile-settings .fileinput .fileinput-upload .btn-file:hover {
                background-color: rgba(0, 0, 0, 0.65);
            }

    .st-profile-settings .fileinput .fileinput-remove {
        position: absolute;
        top: 15px;
        right: 15px;
        padding: 0 4px;
        color: #ffffff;
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 2px;
        text-align: center;
        cursor: pointer;
        font-size: 16px;
        line-height: 17px;
    }

        .st-profile-settings .fileinput .fileinput-remove:hover {
            background-color: rgba(0, 0, 0, 0.65);
        }

.st-profile-settings .thumbnail {
    border: 0;
    min-width: 70px;
    background-color: #f2f5f7;
    border-radius: 5px;
    padding: 5px;
    margin-bottom: 0;
}

    .st-profile-settings .thumbnail img {
        border-radius: 3px;
    }

.st-profile-settings textarea {
    min-height: 160px;
    resize: none;
}

.st-profile-settings__title {
    font-weight: 700;
    margin-bottom: 10px;
}

.st-profile-settings__section {
    margin-bottom: 20px;
}

@media (max-width: 990px) {
    .st-profile-settings__section .col-md-6:not(:last-child) {
        margin-bottom: 10px;
    }
}

.st-profile-settings__submit {
    text-align: right;
    padding: 15px 0 0;
    border-top: 1px solid #eaedef;
}

#slots input {
    font-size: 9px !important;
    border-radius: 5px !important;
    width: 50px !important;
}

#slots .stop:hover {
    background-color: #ee6352;
    cursor: auto;
    -webkit-box-shadow: inset 0px px 0px 0px rgba(0,0,0,0.96);
    -moz-box-shadow: inset 0px 0px 0px 0px rgba(0,0,0,0.96);
    box-shadow: inset 0px 0px 0px 0px rgba(0,0,0,0.96);
}

#slots input:hover {
    cursor: pointer;
    -webkit-box-shadow: inset 10px 9px 35px -7px rgba(0,0,0,0.96);
    -moz-box-shadow: inset 10px 9px 35px -7px rgba(0,0,0,0.96);
    box-shadow: inset 10px 9px 35px -7px rgba(0,0,0,0.96);
}

#createAppoinment-container .btn-primary {
    color: #ffffff !important;
    background-color: #3fa7d6 !important;
    border-color: #2c9dcf !important;
}

#createAppoinment-container .well {
    padding-left: 30px !important;
}

#createAppoinment-container .pull-left {
    width: 150px;
}

#cancelAppoinment-container .btn-primary {
    color: #ffffff !important;
    background-color: #3fa7d6 !important;
    border-color: #2c9dcf !important;
}


#datosIdentificacion, #datosIdentificacion *, #datosWorkOrder, #datosWorkOrder *, .text-size-15px{
	font-size: 15px;
}

#datosIdentificacion {
	height: 125px;
}

#mensajeResultado{
	font-size: 16px;
}

.text-size-2em{
	font-size: 2em;
}

.text-size-4em{
	font-size: 4em;
}

.text-size-6em{
	font-size: 6em;
}

.background-success{
	background: rgb(230,255,230) !important;
}

.background-alert{
	background: rgb(255,230,230) !important;
}

#stateIcon{
	position: relative;
}

#stateIcon > span{
	position: absolute;
	top: 0;
	right: 0;
	font-size: 5em;
}

#listOrdenes{
	overflow: hidden;
}

.text-muted{
	display: inline-block;
    min-width: 100px;
}

/*JAVIERIF*/

.form-group input {
    width: auto !important;
    min-height:40px !important;
  }

  .btn-primary {
    margin-top:0px;
}

.ms-TextField-field{
    min-height:40px !important;
}


`
  const stylesheet = document.createElement('style')
  stylesheet.id = 'appointments-style'
  stylesheet.innerHTML = styles
  document.body.appendChild(stylesheet)
}
