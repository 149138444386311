import PropTypes from 'prop-types'
import { Divider, Box, Card, CardContent } from '@material-ui/core'

import { get, isNil } from 'lodash'

import { TitleSidebar, TextSidebar } from 'components/ui'

const TerminalRow = ({ terminal, title }) => {
  const payment = terminal.computedPrices

  return (
    <div data-hook={`sideBar-terminal-${terminal.id}`}>
      <div>
        <TitleSidebar>
          <span>{title}</span>
        </TitleSidebar>
        <TextSidebar dataHook="sideBar-terminal-name">{get(terminal, 'details.name')}</TextSidebar>
      </div>

      <Divider />

      <div className="m-t-12">
        {!isNil(payment.initialPayment) && (
          <div>
            <TitleSidebar>Pago {payment.fees === 1 ? 'único' : 'inicial'}</TitleSidebar>
            <TextSidebar dataHook="sideBar-terminal-initialPayment">
              {payment.initialPayment}€
            </TextSidebar>
          </div>
        )}

        {payment.monthlyPayment && (
          <div>
            <TitleSidebar>Cuota mensual</TitleSidebar>
            <TextSidebar dataHook="sideBar-terminal-quota">
              {payment.monthlyPayment}€/mes
            </TextSidebar>
          </div>
        )}

        {payment.finalPayment && (
          <div>
            <TitleSidebar>Pago final</TitleSidebar>
            <TextSidebar dataHook="sideBar-terminal-finalPayment">
              {payment.finalPayment}€
            </TextSidebar>
          </div>
        )}
      </div>
    </div>
  )
}
TerminalRow.propTypes = {
  terminal: PropTypes.object,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

export const TerminalsSidebar = ({ terminals, isSubscription }) => (
  <Box display="flex" justifyContent="center" data-hook="sideBar-terminals">
    <Card style={{ width: '90%', borderRadius: '10px', margin: '8px 0' }}>
      <CardContent>
        {terminals.main ? (
          <TerminalRow
            key={`terminalMain-${terminals.main.name}`}
            terminal={terminals.main}
            title="Dispositivo Cabecera"
          />
        ) : null}

        {terminals.additional.map((elem, i) => (
          <TerminalRow
            // TODO: replace with generated id for extra line
            key={`terminalExtra-${elem.name}`}
            terminal={elem}
            title={`Dispositivo ${i + isSubscription ? 1 : 2}`}
          />
        ))}
      </CardContent>
    </Card>
  </Box>
)

TerminalsSidebar.propTypes = {
  terminals: PropTypes.object,
  isSubscription: PropTypes.bool,
}
