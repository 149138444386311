import { call, put, takeEvery, select } from 'redux-saga/effects'
import { logError, formatErrorMessage } from 'services/logging'
import { axiosFactory } from 'modules/axios'
import { selectors as customerSelectors } from 'services/customer-360'

import {
  FIND_BILLINGS_INIT,
  findBillingsAction,
  findBillingsSuccessAction,
  findBillingsErrorAction,
} from './billings.actions'
import { findBillings } from '../services/billings.service'

export function* findBillingsSaga({ payload: { filters } }) {
  try {
    yield put(findBillingsAction())
    const documentType = yield select(customerSelectors.getIdentificationType)
    const documentId = yield select(customerSelectors.getIdentificationId)
    const billing = yield call(findBillings, documentType, documentId, filters)
    yield put(findBillingsSuccessAction({ data: billing?.invoices || [] }))
  } catch (e) {
    yield call(logError, { e: new Error(formatErrorMessage(e)), filters })
    const error = axiosFactory.buildError(e)
    yield put(findBillingsErrorAction(error))
  }
}

export function* watchBillingsFind() {
  yield takeEvery(FIND_BILLINGS_INIT, findBillingsSaga)
}
