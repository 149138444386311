import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Typography } from '@material-ui/core'
import { TextStyles } from 'utils/text'
import { Button, Modal, ModalActions } from 'components/ui'

export const NewProductModal = styled(
  ({
    onConfirm,
    onCancel,
    disabled,
    openNewProductModal,
    setOpenNewProductModal,
    message,
    children,
  }) => {
    return (
      <Modal
        title="Advertencia"
        isOpen={openNewProductModal}
        onClose={() => setOpenNewProductModal(false)}>
        <div>
          {children}

          <Typography
            {...TextStyles.paragraphDark({
              className: 'modal-legend',
            })}>
            {message}
          </Typography>
        </div>

        <ModalActions>
          <Button
            onClick={onConfirm}
            disabled={disabled}
            data-hook="sale-withclient-confirm-button"
            className="card-link">
            Aceptar
          </Button>
          <Button
            secondary
            onClick={() => {
              setOpenNewProductModal(false)
              if (onCancel) onCancel()
            }}>
            Cancelar
          </Button>
        </ModalActions>
      </Modal>
    )
  },
)``

NewProductModal.propTypes = {
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  disabled: PropTypes.bool,
  openNewProductModal: PropTypes.bool,
  setOpenNewProductModal: PropTypes.func,
  message: PropTypes.string,
  children: PropTypes.node,
}
