import { get, find, isEmpty } from 'lodash'
import { AlarmInfo, AlarmTypes } from './alarms.constants'

export function getAlarmType(alarm) {
  return get(alarm, 'type')
}

export function getAlarmStatusDesc(alarm) {
  return get(alarm, 'statusDesc')
}

export function getAlarmInfoBy(type, subType) {
  return find(Object.values(AlarmInfo), { type, subType })
}

export function getAlarmTitle(alarm) {
  if (alarm) {
    const alarmInfo = getAlarmInfoBy(alarm.type, alarm.subType)

    if (alarmInfo) {
      if (alarmInfo.type === AlarmTypes.SVAs.id) {
        return get(alarm, 'title', get(alarmInfo.statuses, ['default', 'title']))
      }
      return get(
        alarmInfo.statuses,
        [getAlarmStatusDesc(alarm), 'title'],
        get(alarmInfo.statuses, ['default', 'title']),
      )
    }
  }

  return 'No Alarm Info'
}

export function getAlarmDescription(alarm) {
  return get(alarm, 'description')
}

export function getAlarmSubdescription(alarm) {
  return get(alarm, 'subdescription')
}

export function getAlarmLevel(alarm) {
  return get(alarm, 'level')
}

export function mapSvsRequestTpToAlarm(svaServiceRequest) {
  if (isEmpty(svaServiceRequest)) return []

  return svaServiceRequest.map(sva => {
    const errorMsg = get(sva, 'serviceRequest.[0]')

    if (isEmpty(errorMsg)) {
      return {}
    }
    // TODO - HOMEGO
    return {
      type: AlarmTypes.SVAs.id,
      subType: null,
      level: 'WARNING',
      documentId: '15540415M',
      statusId: '20',
      title: 'Alarma Home GO',
      description: errorMsg.svRequestTp,
    }
  })
}
