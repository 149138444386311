import { call, put, select, takeEvery } from 'redux-saga/effects'
import { logError, formatErrorMessage } from 'services/logging'
import { selectAccountId } from 'modules/CustomerInfo/store/customerInfo.selectors'
import { fetchTariffPromotions } from 'modules/tariffs/services/tariffs.service'
import {
  fetchCrossSellAllowedSuccess,
  fetchCrossSellAllowedFailed,
  fetchCrossSellCompatibleDiscountsSuccess,
  fetchCrossSellCompatibleDiscountsFailed,
  fetchCrossSellTariffPromotionsSuccess,
  fetchCrossSellTariffPromotionsFailed,
} from './actions'
import { constants } from './constants'
import { fetchCrossSellAllowed, fetchCrossCompatibleDiscounts } from './api'

function* handleCrossSellAllowedFailure() {
  yield put(fetchCrossSellAllowedFailed())
}

function* handleCrossSellAllowedSuccess() {
  yield put(fetchCrossSellAllowedSuccess())
}

function* fetchCrossSellAllowedSaga({
  payload: {
    saleInformation: { channel },
    subscription: { productNumber },
  },
}) {
  try {
    const customerId = yield select(selectAccountId)

    const result = yield call(fetchCrossSellAllowed, customerId, productNumber, channel)

    if (!result) {
      yield handleCrossSellAllowedFailure()
      return
    }

    yield handleCrossSellAllowedSuccess()
  } catch (e) {
    yield call(logError, new Error(formatErrorMessage(e)))
    yield handleCrossSellAllowedFailure()
  }
}

export function* watchCrossSellAllowedSaga() {
  yield takeEvery(constants.FETCH_CROSS_SELL_ALLOWED, fetchCrossSellAllowedSaga)
}

function* fetchCrossSellCompatibleDiscountsSaga({
  payload: { productNumber, productSpecification, idPOCrossSell, idPOPromoXSell },
}) {
  try {
    const result = yield call(
      fetchCrossCompatibleDiscounts,
      productSpecification,
      productNumber,
      idPOCrossSell,
      idPOPromoXSell,
    )

    yield put(fetchCrossSellCompatibleDiscountsSuccess(result))
  } catch (e) {
    yield call(logError, new Error(formatErrorMessage(e)))
    yield put(fetchCrossSellCompatibleDiscountsFailed())
  }
}

export function* watchCrossSellCompatibleDiscountsSaga() {
  yield takeEvery(constants.FETCH_CROSS_COMPATIBLE_DISCOUNTS, fetchCrossSellCompatibleDiscountsSaga)
}

export function* fetchCrossSellTariffPromotionsSaga({ payload: { segment, poId } }) {
  try {
    const result = yield call(fetchTariffPromotions, segment, poId)

    yield put(fetchCrossSellTariffPromotionsSuccess(result))
  } catch (e) {
    yield call(logError, new Error(formatErrorMessage(e)))
    yield put(fetchCrossSellTariffPromotionsFailed())
  }
}

export function* watchCrossSellTariffPromotionsSaga() {
  yield takeEvery(constants.FETCH_CROSS_TARIFF_PROMOTIONS, fetchCrossSellTariffPromotionsSaga)
}
