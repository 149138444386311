import * as actions from './credit.actions'

const initialState = {
  loading: false,
  error: null,
  done: false,
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.CREDIT_ATTEMPTED:
      return {
        ...initialState,
        loading: true,
      }
    case actions.CREDIT_SUCCEEDED:
      return {
        ...state,
        loading: false,
      }
    case actions.CLEAR_CREDIT_ERROR:
      return {
        ...initialState,
      }
    case actions.CREDIT_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}
