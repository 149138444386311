import { put, select, take } from 'redux-saga/effects'
import { get, isNil } from 'lodash'
import { AUTH_CUSTOMER_SUCCESS } from 'modules/Auth/store/actions'
import { actions } from './actions'
import { constants } from './constants'

const getError = state => get(state, 'customer360.error')
const selectCustomerId = state => get(state, 'auth.customer.customerId')

export function* callSaga(legacyCustomerId) {
  const customerId = isNil(legacyCustomerId) ? yield select(selectCustomerId) : legacyCustomerId

  if (isNil(customerId)) {
    yield take(AUTH_CUSTOMER_SUCCESS)
  }

  yield put(actions.findInit(customerId))
  yield take([constants.FIND_SUCCESS, constants.FIND_FAILED])
  return yield select(getError)
}
