import { get } from 'lodash'
import { createSelector } from 'reselect'

const selectCustomerWrite = state => get(state, 'customerWrite')

export const selectIsLoading = createSelector(
  selectCustomerWrite,
  customerWrite => get(customerWrite, 'loading'),
)

export const selectErrorMessage = createSelector(
  selectCustomerWrite,
  customerWrite => get(customerWrite, 'error'),
)
