import PropTypes from 'prop-types'
import { get } from 'lodash'
import styled, { css } from 'styled-components'

import { Tooltip as MatTooltip, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import { TextStyles } from 'utils/text'

const TooltipStyled = styled(MatTooltip)`
  cursor: default;
  position: relative;
  padding-right: ${props => (props.ellipsis ? '30px' : '')};
  ${props =>
    props.ellipsis &&
    css`
      font-size: inherit;
      line-height: inherit;
      &::after {
        content: '...';
        top: 50%;
        transform: translateY(-50%);
        font-size: 44px;
        height: 14px;
        width: auto;
        position: absolute;
        line-height: 0;
        margin-top: -4px;
        margin-left: 4px;
        letter-spacing: -4px;
      }
    `}
`

const useStyles = makeStyles(theme => ({
  lightTooltip: {
    background: get(theme, 'palette.brand.light'),
    color: get(theme, 'palette.global.gray_dark'),
    boxShadow: get(theme, 'shadows[1]'),
    fontSize: 14,
    padding: '16px 11px',
    maxWidth: '265px',
  },
  multiline: {
    padding: '12px 14px',
    maxWidth: 'none',
  },
  hide: {
    display: 'none',
  },
}))

const ToolTipMultiline = ({ placement, title, children, visible, bold, multiline, ellipsis }) => {
  const classes = useStyles()

  const tooltipClasses = {
    [classes.lightTooltip]: visible,
    [classes.multiline]: visible && multiline,
    [classes.hide]: !visible,
  }

  const tooltipClassesNames = Object.keys(tooltipClasses)
    .filter(c => tooltipClasses[c])
    .join(' ')

  return (
    <TooltipStyled
      placement={placement}
      ellipsis={ellipsis}
      title={
        <t>
          <Typography {...TextStyles.subtitle2Dark()}>{bold}</Typography>
          <ul>
            {title.map((text, i) => (
              <li key={i.toString()}>{text}</li>
            ))}
          </ul>
          <span className={classes.arrow} />
        </t>
      }
      classes={{ tooltip: tooltipClassesNames }}>
      {children}
    </TooltipStyled>
  )
}

ToolTipMultiline.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.array.isRequired,
  bold: PropTypes.string,
  placement: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
  visible: PropTypes.bool.isRequired,
  multiline: PropTypes.bool,
  ellipsis: PropTypes.bool,
}

export default ToolTipMultiline
