import { isNil, isEmpty, pullAt, get } from 'lodash'
import { put, takeEvery, takeLatest, take, all, select, call } from 'redux-saga/effects'

import { actions as taxesActions } from 'services/taxes'
import { logError, formatErrorMessage } from 'services/logging'
import { setDataBonusHebeSvaSaga } from 'services/subscriptions/saga'
import {
  actions as discountsActions,
  constants as discountConstants,
} from 'services/discounts-agent-categories'
import {
  actions as subscriptionsActions,
  callChangeTariffSaga,
  callSetBundlesSaga,
  callSetUpsellBundlesSaga,
  selectPortOutInfo,
  selectSubscriptionByMsisdn,
  getSubscriptionId,
  getSubscriptionTariffId,
  getSubscriptionDiscounts,
} from 'services/subscriptions'
import {
  getSegment,
  getIdentification,
  getIdentificationId,
  getIdentificationType,
} from 'services/customer-360/selectors'
import { getSegmentTariffMixed } from 'services/customer-360/helpers'
import {
  actions as customerActions,
  selectors as customerSelectors,
  constants as customerConstants,
} from 'services/customer-new'

import { callRegisterOffer } from 'modules/activity-register'
import { findTariffsAction } from 'modules/tariffs/store-apigee'
import { findBonusesAction } from 'modules/bonuses'
import { axiosFactory } from 'modules/axios'
import { selectSfid } from 'modules/Auth'
import { selectProfileId } from 'modules/Permissions'
import { getCampaignArea } from 'modules/vista-360/helpers/helpers'
import {
  fetchContentfulResourceAction,
  FETCH_CONTENTFUL_RESOURCE_SUCCESS,
} from 'modules/contentful'
import { SMS_SENDER_INFO } from 'services/sms-communication'
import { sendSmsCommunication } from 'services/sms-communication/api'
import {
  updatePenaltyCondonation,
  updateDiscount,
  getTariffsStc,
  getOrderElegibility,
  getOrderConsequences,
  getTechnology,
  updateAddressChange,
  updateTechnologyChange,
  getInstaller,
} from 'modules/offers-configuration/api'

import {
  CVM_FIXED_PENALTY_KEY,
  CVM_MOBILE_PENALTY_KEY,
  CVM_SET_BUNDLE_BONUSES,
  SVA_HEBE_DISCOUNT_CODE,
  COVERAGE_TOKEN,
  TARIFF_MIXED,
} from 'modules/offers-configuration/constants'
import { isFixedNumber } from 'services/subscriptions/helpers'
import {
  actionTypes,
  setError,
  setFinished,
  updateSubscription,
  updateSubscriptions,
  fetchTariffsV3,
  fetchTariffsSuccessV3,
  fetchTariffsFailedV3,
  fetchOrderElegibility,
  fetchOrderSuccessElegibility,
  fetchOrderFailedElegibility,
  initOrderConsequences,
  fetchOrderConsequences,
  fetchOrderSuccessConsequences,
  fetchOrderFailedConsequences,
  fetchParentTechnologySuccess,
  fetchCoverageTechnologySuccess,
  fetchParentTechnologyFailed,
  fetchCoverageTechnologyFailed,
  fetchCommercialMigrationSucceeded,
  fetchCommercialMigrationFailed,
  fetchChangeAddressSucceeded,
  fetchChangeAddressFailed,
  fetchChangeTechnologySucceeded,
  fetchChangeTechnologyFailed,
  notifyPermanenceModification,
  notifyPermanenceModificationSucceeded,
  notifyPermanenceModificationFailed,
  notifyDiscountApplication,
  notifyDiscountApplicationSucceeded,
  notifyDiscountApplicationFailed,
  notifyDiscountsDisable,
  notifyDiscountsDisableSucceeded,
  notifyDiscountsDisableFailed,
  notifySetSvas,
  notifySetSvasSucceeded,
  notifySetSvasFailed,
  clearAllSubscriptions,
  fetchInstallerSucceeded,
  fetchInstallerFailed,
} from './actions'

import {
  getPermanenceRemovedReasonName,
  getPenaltyCondonedReasonName,
  getNotifyPermanenceModificationErrorMsg,
  getNotifyDiscountApplicationErrorMsg,
  getSubscriptionsState,
} from './selectors'

import {
  extractOfferResume,
  getSfidSuffix,
  findFixedTotalPermanent,
  findMobileTotalPermanent,
  pickDiscountsProperties,
} from './helpers'
// The next two sagas are intended to be part of a 'future'
// service, depending on the use of Bigquery across the app
export function* callNotifyPermanenceModification(msisdn, amount, reason) {
  yield put(notifyPermanenceModification(msisdn, amount, reason))
  yield take(
    action =>
      (action.type === actionTypes.NOTIFY_PERMANENCE_MODIFICATION_SUCCEEDED ||
        action.type === actionTypes.NOTIFY_PERMANENCE_MODIFICATION_FAILED) &&
      action.payload.msisdn === msisdn,
  )
  return yield select(getNotifyPermanenceModificationErrorMsg, msisdn)
}

export function* notifyPermanenceModificationSaga(action) {
  const { msisdn, reason, amount } = action.payload
  try {
    const segment = yield select(customerSelectors.getSegment)
    yield call(updatePenaltyCondonation, msisdn, amount, reason, segment)
    yield put(notifyPermanenceModificationSucceeded(msisdn))
  } catch (e) {
    yield call(logError, { e: new Error(formatErrorMessage(e)), payload: action.payload })
    const error = axiosFactory.buildError(e)
    yield put(notifyPermanenceModificationFailed(msisdn, error))
  }
}

export function* callNotifyDiscountApplication(msisdn, subscriptionId, discount, activationStamp) {
  yield put(notifyDiscountApplication(msisdn, subscriptionId, discount, activationStamp))
  yield take(
    action =>
      (action.type === actionTypes.NOTIFY_DISCOUNT_APPLICATION_SUCCEEDED ||
        action.type === actionTypes.NOTIFY_DISCOUNT_APPLICATION_FAILED) &&
      action.payload.msisdn === msisdn &&
      action.payload.discountCode === discount.discountCode,
  )
  return yield select(getNotifyDiscountApplicationErrorMsg, msisdn, discount.discountCode)
}

export function* notifyDiscountApplicationSaga(action) {
  const { msisdn, discountCode, activationStamp, subscriptionId, value, duration, permanency } =
    action.payload
  try {
    const sfid = yield select(selectSfid)
    yield call(
      updateDiscount,
      msisdn,
      sfid,
      subscriptionId,
      discountCode,
      value,
      duration,
      activationStamp,
      permanency,
    )
    yield put(notifyDiscountApplicationSucceeded(msisdn, discountCode))
  } catch (e) {
    yield call(logError, { e: new Error(formatErrorMessage(e)), payload: action.payload })
    const error = axiosFactory.buildError(e)
    yield put(notifyDiscountApplicationFailed(msisdn, discountCode, error))
  }
}

function* registerSubscriptionOffers(msisdn, discounts) {
  const subscription = yield select(selectSubscriptionByMsisdn(msisdn))
  const tariffId = getSubscriptionTariffId(subscription)
  const portOutInfo = yield select(selectPortOutInfo(msisdn))
  const profileId = yield select(selectProfileId)

  let customerId = yield select(customerSelectors.getIdentification)

  if (isNil(customerId)) {
    customerId = yield select(getIdentification)
  }

  yield all(
    discounts.map(discount => {
      const offerResume = extractOfferResume(
        customerId,
        { confirmedDiscounts: [discount] },
        portOutInfo,
        tariffId,
        profileId,
      )

      return callRegisterOffer(msisdn, {
        ...offerResume,
        discounts: `Desactivación ${offerResume.discounts}`,
      })
    }),
  )
}

function* callUpdateDiscountSaga({
  msisdn,
  sfid,
  subscriptionId,
  discountCode,
  value,
  duration,
  activationStamp,
  permanency,
}) {
  let result

  try {
    result = yield call(
      updateDiscount,
      msisdn,
      sfid,
      subscriptionId,
      discountCode,
      value,
      duration,
      activationStamp,
      permanency,
    )
  } catch (e) {
    const error = axiosFactory.buildError(e)
    yield call(logError, {
      e: new Error(formatErrorMessage(e)),
      payload: { msisdn, sfid, subscriptionId, discountCode },
    })

    result = { error }
  }

  return result
}

export function* disableSubscriptionDiscountsSaga(action) {
  const DISCOUNT_DISABLE_DURATION = 1
  const DISCOUNT_DISABLE_PERMANENCY = ''

  const sfid = yield select(selectSfid)
  const { msisdn, subscriptionId, changes: discounts } = action.payload

  yield put(notifyDiscountsDisable())

  const parallelCalls = yield all(
    discounts.map(({ discountCode, activationStamp, value }) =>
      call(callUpdateDiscountSaga, {
        msisdn,
        sfid,
        subscriptionId,
        discountCode,
        value,
        duration: DISCOUNT_DISABLE_DURATION,
        activationStamp,
        permanency: DISCOUNT_DISABLE_PERMANENCY,
      }),
    ),
  )

  const failedDiscountsIndexes = parallelCalls.reduce(
    (result, response, index) => (response?.error ? [...result, index] : result),
    [],
  )

  const disabledDiscounts = [...discounts]

  if (failedDiscountsIndexes.length === 0) {
    yield put(notifyDiscountsDisableSucceeded(msisdn, pickDiscountsProperties(discounts)))
  } else {
    const failedDiscounts = pullAt(disabledDiscounts, failedDiscountsIndexes)

    yield put(
      notifyDiscountsDisableFailed(msisdn, disabledDiscounts, failedDiscounts, {
        info: failedDiscountsIndexes,
      }),
    )
  }

  yield call(registerSubscriptionOffers, msisdn, disabledDiscounts)
}

// eslint-disable-next-line consistent-return
function* updateSubscriptionSaga(
  msisdn,
  subscriptionId,
  changes,
  optionsV3,
  migrationOrStc,
  sendSms,
  smsContent,
) {
  const shouldFetchTariffsV3 = get(changes, 'showV3Tariffs')

  let error

  if (changes.hebeDiscountIncompatibility) {
    const subscriptionDiscounts = yield select(getSubscriptionDiscounts(msisdn))
    const hebeDiscount = subscriptionDiscounts.filter(
      discount => discount.discountCode === SVA_HEBE_DISCOUNT_CODE,
    )
    const disableDiscount = {
      payload: {
        changes: hebeDiscount,
        msisdn,
        subscriptionId,
      },
    }

    error = yield disableSubscriptionDiscountsSaga(disableDiscount)

    if (error) return error
  }

  if (changes.confirmedTariff) {
    const documentId = yield select(getIdentificationId)
    const documentType = yield select(getIdentificationType)
    // NOTE : hardcoded for now. In the future they'll be retrieved from the client app envrironment
    const scoring = {
      brand: 'YOIGO',
      salesTool: 'OVID',
      channel: 'TELESALES',
      channelRaw: 'telesales',
      scort: migrationOrStc,
    }

    let options = {
      activationStamp: changes.activationStamp,
      iban: changes.iban,
      documentId,
      documentType,
      scoring,
    }

    if (shouldFetchTariffsV3) {
      const {
        newTariffId,
        channel,
        sfid,
        ipAddress,
        salesType,
        productOfferingId,
        targetAgreementId,
        newPaymentType,
        accountId,
        territoryOwner,
        bypass,
        speed,
        isNotMobile,
      } = optionsV3

      options = {
        ...options,
        tariffId: newTariffId,
        channel,
        sfid,
        ipAddress,
        salesType,
        newPaymentType,
        customerAccountId: accountId,
        targetAgreementId,
        productOfferingId,
      }

      if (bypass) {
        options = {
          ...options,
          inputtedCharacteristics: {
            CH_Bypass_STC_Rules: bypass,
          },
        }
      }

      if (isNotMobile) {
        options = {
          ...options,
          inputtedCharacteristics: {
            CH_Fixed_Coverage_Token: COVERAGE_TOKEN,
            CH_Fixed_Territory_Owner: territoryOwner,
            CH_Broadband_Estimated_Speed: speed,
          },
        }
      }
    }
    error = yield callChangeTariffSaga(
      msisdn,
      changes.confirmedTariff.id,
      options,
      shouldFetchTariffsV3,
    )

    if (error) return error

    yield put(updateSubscription(msisdn, { confirmedTariff: null }))
  }

  if (changes.confirmedDiscounts && changes.confirmedDiscounts.length) {
    for (let i = 0; i < changes.confirmedDiscounts.length; i += 1) {
      error = yield callNotifyDiscountApplication(
        msisdn,
        subscriptionId,
        changes.confirmedDiscounts[i],
        changes.activationStamp,
      )

      if (error) return error
    }
    yield put(updateSubscription(msisdn, { confirmedDiscounts: [] }))
  }

  if (changes.confirmedBonuses && changes.confirmedBonuses.length) {
    if (CVM_SET_BUNDLE_BONUSES.includes(changes.confirmedBonuses[0].id)) {
      error = yield callSetBundlesSaga(msisdn, changes.confirmedBonuses[0].id, 'ON')
    } else {
      error = yield callSetUpsellBundlesSaga(msisdn, changes.confirmedBonuses[0].id)
    }

    if (error) return error

    yield put(updateSubscription(msisdn, { confirmedBonuses: [] }))
  }

  if (changes[CVM_FIXED_PENALTY_KEY]) {
    const { reason, penaltyCondoned } = changes[CVM_FIXED_PENALTY_KEY]

    const subscription = yield select(selectSubscriptionByMsisdn(msisdn))
    const amount = findFixedTotalPermanent(subscription).fees.pending
    const reasonName = yield select(
      penaltyCondoned ? getPenaltyCondonedReasonName : getPermanenceRemovedReasonName,
      reason,
    )

    error = yield callNotifyPermanenceModification(msisdn, amount, reasonName)

    if (error) return error

    yield put(
      updateSubscription(msisdn, {
        [CVM_FIXED_PENALTY_KEY]: { penaltyCondoned: null, permanenceRemoved: null },
      }),
    )
  }

  if (changes[CVM_MOBILE_PENALTY_KEY]) {
    const { reason, penaltyCondoned } = changes[CVM_MOBILE_PENALTY_KEY]

    const subscription = yield select(selectSubscriptionByMsisdn(msisdn))
    const amount = findMobileTotalPermanent(subscription).fees.pending
    const reasonName = yield select(
      penaltyCondoned ? getPenaltyCondonedReasonName : getPermanenceRemovedReasonName,
      reason,
    )

    error = yield callNotifyPermanenceModification(msisdn, amount, reasonName)

    if (error) return error

    yield put(
      updateSubscription(msisdn, {
        [CVM_MOBILE_PENALTY_KEY]: { penaltyCondoned: null, permanenceRemoved: null },
      }),
    )
  }

  const sub = yield select(selectSubscriptionByMsisdn(msisdn))
  const tariffId = getSubscriptionTariffId(sub)
  const portOutInfo = yield select(selectPortOutInfo(msisdn))

  let customerId = yield select(customerSelectors.getIdentification)

  if (isNil(customerId)) {
    customerId = yield select(getIdentification)
  }

  const profileId = yield select(selectProfileId)
  const offerResume = extractOfferResume(customerId, changes, portOutInfo, tariffId, profileId)

  error = yield callRegisterOffer(msisdn, offerResume)

  if (error) return error

  if (sendSms && !isNil(smsContent) && !isFixedNumber(msisdn)) {
    try {
      yield call(sendSmsCommunication, SMS_SENDER_INFO.Yoigo, msisdn, smsContent)
    } catch (e) {
      error = axiosFactory.buildError(e)
    }
  }
}

export function* updateSubscriptionsSaga(action) {
  const { payload } = action
  const infoSubs = payload.data
  const subs = Object.keys(infoSubs)
  const state = yield select()
  const responses = yield all(
    subs.map(msisdn => {
      const subscription = selectSubscriptionByMsisdn(msisdn)(state)

      return updateSubscriptionSaga(
        msisdn,
        getSubscriptionId(subscription),
        infoSubs[msisdn],
        payload.optionsV3[msisdn],
        payload.migrationOrStc[msisdn],
        payload?.sendSms,
        payload?.smsContent,
      )
    }),
  )

  const errors = []
  responses.forEach((err, i) => {
    if (err) errors.push(`${subs[i]}: ${err}`)
  })

  if (errors.length) {
    yield put(setError(errors))
  } else {
    yield put(clearAllSubscriptions())
  }

  // Wait to refetch suscriptions so they're updated
  if (errors.length !== subs.length) {
    yield new Promise(resolve => setTimeout(resolve, 10000))
  }

  yield put(setFinished())

  yield put(subscriptionsActions.findSubscriptions())
}

function* initOffersConfiguratorSaga() {
  yield put(customerActions.findInit())

  yield take(customerConstants.FIND_SUCCESS)
  const customerZipCode = yield select(customerSelectors.getBillingAddressPostCode)
  if (!isEmpty(customerZipCode)) {
    yield put(taxesActions.findTax(customerZipCode))
  }
  const sfid = yield select(selectSfid)
  const sfidSuffix = getSfidSuffix(sfid)

  yield put(fetchContentfulResourceAction('sfidProfiles', { 'sfid[in]': sfidSuffix }))
  yield take(
    act =>
      act.type === FETCH_CONTENTFUL_RESOURCE_SUCCESS && act.payload.resource === 'sfidProfiles',
  )

  const profileId = yield select(selectProfileId)
  const agentProfiles = !isEmpty(profileId) ? [profileId] : []
  const area = getCampaignArea(profileId, sfid)

  const segment = yield select(customerSelectors.getSegment)

  yield all([
    put(fetchContentfulResourceAction('removePenaltyReasonOptions', { brands: 'YOIGO' })),
    put(fetchContentfulResourceAction('condonePermanenceReasonOptions', { brands: 'YOIGO' })),
    put(
      fetchContentfulResourceAction('typificationsCvmCampaign', {
        area,
        spaceName: 'cvm',
      }),
    ),
    put(
      fetchContentfulResourceAction('typificationsCvmRetention', {
        isRetained: true,
        spaceName: 'cvm',
      }),
    ),
    put(fetchContentfulResourceAction('typificationsCvmFidelizacion', { spaceName: 'cvm' })),
    put(fetchContentfulResourceAction('typificationsCvmOperator', { spaceName: 'cvm' })),
    put(fetchContentfulResourceAction('typificationsCvmNoutil', { area, spaceName: 'cvm' })),
    put(fetchContentfulResourceAction('legalText', { origin: 'cvm' })),
    put(subscriptionsActions.findSubscriptions()),
    put(findTariffsAction({ segment })),
    // Get retired tariffs because a subscription can have one retired
    put(findTariffsAction({ status: 'retired,hidden', segment })),
    put(findBonusesAction()),
    put(discountsActions.getDiscounts(agentProfiles, discountConstants.CONVERGENT_CATEGORY)),
    put(discountsActions.getDiscounts(agentProfiles, discountConstants.MOBILE_CATEGORY)),
  ])
}

export function* updateAndSendSubscription(action) {
  const { msisdn, subscriptionId, changes, optionsV3, migrationOrStc } = action.payload
  const error = yield updateSubscriptionSaga(
    msisdn,
    subscriptionId,
    changes,
    optionsV3,
    migrationOrStc,
  )

  if (!error) {
    const modifications = yield select(getSubscriptionsState)

    yield put(updateSubscriptions(modifications))
  }
}

function* callSetSvaSaga({ msisdn, svaId: service, status, sfid, resetOnSuccess, resetOnFailure }) {
  let result

  try {
    result = yield call(setDataBonusHebeSvaSaga, {
      payload: { msisdn, service, status, resetOnSuccess, resetOnFailure },
    })
  } catch (e) {
    const error = axiosFactory.buildError(e)
    yield call(logError, {
      e: new Error(formatErrorMessage(e)),
      payload: { msisdn, service, status, sfid },
    })

    result = { error }
  }

  return result
}

export function* setSvasSaga(action) {
  const sfid = yield select(selectSfid)
  const { msisdn, changes: svas } = get(action, 'payload', {})

  yield put(notifySetSvas())

  const parallelCalls = yield all(
    svas.map(({ svaId, status }) =>
      call(callSetSvaSaga, {
        msisdn,
        svaId,
        status,
        sfid,
        resetOnSuccess: true,
        resetOnFailure: true,
      }),
    ),
  )

  const failedSvasIndexes = parallelCalls.reduce(
    (result, response, index) => (get(response, 'error', false) ? [...result, index] : result),
    [],
  )

  const disabledSvas = [...svas]

  if (failedSvasIndexes.length === 0) {
    yield put(notifySetSvasSucceeded(msisdn, svas))
  } else {
    const failedSvas = pullAt(disabledSvas, failedSvasIndexes)

    yield put(
      notifySetSvasFailed(msisdn, disabledSvas, failedSvas, {
        info: failedSvasIndexes,
      }),
    )
  }
}

export function* initGetStcTariffsSaga({ payload }) {
  const { channel, status, tariffSegment, subscriptionId, phase2, purpose, territoryOwner } =
    payload

  let segment = tariffSegment

  if (tariffSegment === TARIFF_MIXED) {
    const customerSegment = yield select(getSegment)
    segment = getSegmentTariffMixed(customerSegment)
  }

  try {
    const data = yield call(
      getTariffsStc,
      segment,
      channel,
      status,
      subscriptionId,
      phase2,
      purpose,
      territoryOwner,
    )
    yield put(fetchTariffsV3(data))
    yield put(fetchTariffsSuccessV3())
  } catch (e) {
    const error = axiosFactory.buildError(e)
    yield call(logError, new Error(formatErrorMessage(e)))
    yield put(fetchTariffsFailedV3(error))
  }
}

export function* initGetOrderConsequences({ payload }) {
  const { subscriptionId, tariffId } = payload

  try {
    const response = yield call(getOrderConsequences, subscriptionId, tariffId)
    yield put(fetchOrderConsequences(response))
    yield put(fetchOrderSuccessConsequences())
  } catch (e) {
    yield call(logError, new Error(formatErrorMessage(e)))
    const error = axiosFactory.buildError(e)
    yield put(fetchOrderFailedConsequences(error))
  }
}

export function* initGetOrderElegibility({ payload }) {
  const { subscription, newTariff, activationStamp, purpose, territoryOwner } = payload

  const subscriptionId = getSubscriptionId(subscription)
  const tariffId = get(newTariff, 'id')
  const salesType = purpose

  try {
    const response = yield call(
      getOrderElegibility,
      subscriptionId,
      tariffId,
      activationStamp,
      salesType,
      territoryOwner,
    )
    yield put(fetchOrderElegibility(response))
    yield put(fetchOrderSuccessElegibility())
    if (get(response, 'data.decisionAllowed')) {
      yield put(initOrderConsequences(subscriptionId, tariffId, activationStamp))
    }
  } catch (e) {
    yield call(logError, new Error(formatErrorMessage(e)))
    const error = axiosFactory.buildError(e)
    yield put(fetchOrderFailedElegibility(error))
  }
}

export function* initParentTechnology({ payload }) {
  const { territoryOwner } = payload
  try {
    const response = yield call(getTechnology, territoryOwner)
    yield put(fetchParentTechnologySuccess(response.data.id))
  } catch (e) {
    yield call(logError, new Error(formatErrorMessage(e)))
    const error = axiosFactory.buildError(e)
    yield put(fetchParentTechnologyFailed(error))
  }
}

export function* initCoverageTechnology({ payload }) {
  const { territoryOwner } = payload
  try {
    const response = yield call(getTechnology, territoryOwner)
    yield put(fetchCoverageTechnologySuccess(response.data.id))
  } catch (e) {
    yield call(logError, new Error(formatErrorMessage(e)))
    const error = axiosFactory.buildError(e)
    yield put(fetchCoverageTechnologyFailed(error))
  }
}

export function* initCommercialMigration({ payload }) {
  try {
    const response = yield call(updateTechnologyChange, payload)
    yield put(fetchCommercialMigrationSucceeded(response.data.data))
  } catch (e) {
    yield call(logError, new Error(formatErrorMessage(e)))
    const error = axiosFactory.buildError(e)
    yield put(fetchCommercialMigrationFailed(error))
  }
}

export function* initChangeAddress({ payload }) {
  try {
    const response = yield call(updateAddressChange, payload)
    yield put(fetchChangeAddressSucceeded(response.data))
  } catch (e) {
    yield call(logError, new Error(formatErrorMessage(e)))
    const error = axiosFactory.buildError(e)
    yield put(fetchChangeAddressFailed(error))
  }
}

export function* initChangeTechnology({ payload }) {
  try {
    const response = yield call(updateTechnologyChange, payload)
    yield put(fetchChangeTechnologySucceeded(response.data.data))
  } catch (e) {
    yield call(logError, new Error(formatErrorMessage(e)))
    const error = axiosFactory.buildError(e)
    yield put(fetchChangeTechnologyFailed(error))
  }
}

export function* initInstaller({ payload }) {
  try {
    const response = yield call(getInstaller, payload)
    yield put(fetchInstallerSucceeded(response.data))
  } catch (e) {
    yield call(logError, new Error(formatErrorMessage(e)))
    const error = axiosFactory.buildError(e)
    yield put(fetchInstallerFailed(error))
  }
}

export default function* watchOffersConfiguratorSagas() {
  yield takeLatest(actionTypes.INIT_OFFERS_CONFIGURATION, initOffersConfiguratorSaga)
  yield takeLatest(actionTypes.UPDATE_SUBSCRIPTIONS, updateSubscriptionsSaga)
  yield takeLatest(actionTypes.UPDATE_AND_SEND_SUBSCRIPTION, updateAndSendSubscription)
  yield takeLatest(actionTypes.DISABLE_SUBSCRIPTION_DISCOUNTS, disableSubscriptionDiscountsSaga)
  yield takeLatest(actionTypes.SET_SUBSCRIPTION_SVAS, setSvasSaga)
  yield takeLatest(actionTypes.INIT_TARIFFS_V3, initGetStcTariffsSaga)
  yield takeLatest(actionTypes.INIT_ORDER_CONSEQUENCES, initGetOrderConsequences)
  yield takeLatest(actionTypes.INIT_ORDER_ELEGIBILITY, initGetOrderElegibility)
  yield takeLatest(actionTypes.INIT_PARENT_TECHNOLOGY, initParentTechnology)
  yield takeLatest(actionTypes.INIT_COVERAGE_TECHNOLOGY, initCoverageTechnology)
  yield takeLatest(actionTypes.INIT_COMMERCIAL_MIGRATION, initCommercialMigration)
  yield takeLatest(actionTypes.INIT_CHANGE_ADDRESS, initChangeAddress)
  yield takeLatest(actionTypes.INIT_CHANGE_TECHNOLOGY, initChangeTechnology)
  yield takeLatest(actionTypes.INIT_INSTALLER, initInstaller)
  yield takeEvery(actionTypes.NOTIFY_DISCOUNT_APPLICATION_ATTEMPTED, notifyDiscountApplicationSaga)
  yield takeEvery(
    actionTypes.NOTIFY_PERMANENCE_MODIFICATION_ATTEMPTED,
    notifyPermanenceModificationSaga,
  )
}
