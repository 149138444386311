import { useState, useMemo } from 'react'
import PropTypes from 'prop-types'

import { get } from 'lodash'

import { useFormikContext } from 'formik'

import { TerminalsPaymentComponent } from 'modules/Terminals/components'

import { getPaymentIframeUrl } from 'modules/Terminals/services'
import { DEVICE_RENEWAL_FIELDNAMES } from '../../constants'

const COD_SURCHARGE = 3

export const DevicePayment = ({ amount, noCodPayment }) => {
  const { values, setFieldValue } = useFormikContext()

  const paymentType = useMemo(() => get(values, DEVICE_RENEWAL_FIELDNAMES.BILLING_PAYMENT_TYPE), [
    values,
  ])

  const [result, setResult] = useState({ loading: false, success: false, error: null, retry: 0 })
  const [url, setUrl] = useState(null)

  const onItemLoad = iframeUrl => {
    setUrl(iframeUrl)
    setResult({ ...result, loading: false })
  }

  const onItemError = error => {
    setResult({ success: false, loading: false, retry: result.retry + 1, error })
  }

  const getAndSetUrl = () => {
    setResult({ success: false, error: null, retry: 0, loading: true })

    return getPaymentIframeUrl(amount)
      .then(iframeUrl => {
        onItemLoad(iframeUrl)
      })
      .catch(() => {
        onItemError(true)
      })
  }

  const onItemRetry = () => {
    onItemError(false)
    getAndSetUrl()
  }

  const onChangePaymentMethod = value => {
    setFieldValue(DEVICE_RENEWAL_FIELDNAMES.BILLING_PAYMENT_TYPE, value)
  }

  const onSuccess = value => {
    setFieldValue(DEVICE_RENEWAL_FIELDNAMES.BILLING_PAYMENT_TRANSACTION, value)
    setResult({ ...result, success: true, error: null, loading: false })
  }

  const onChangePaymentType = (_, val) => {
    onChangePaymentMethod(val)

    if (val === 'CASH_ON_DELIVERY') {
      setFieldValue(DEVICE_RENEWAL_FIELDNAMES.BILLING_PAYMENT_TRANSACTION, {
        amount: amount + COD_SURCHARGE,
      })
    }
  }

  return (
    <TerminalsPaymentComponent
      onError={onItemError}
      onRetry={onItemRetry}
      onChange={onChangePaymentType}
      onSuccess={onSuccess}
      onLoad={onItemLoad}
      amount={amount}
      getUrl={getAndSetUrl}
      url={url}
      numOfRetries={result.retry}
      paymentType={paymentType}
      isSuccess={result.success}
      isError={!!result.error}
      isLoading={result.loading}
      noCodPayment={noCodPayment}
      codSurcharge={COD_SURCHARGE}
    />
  )
}

DevicePayment.propTypes = {
  amount: PropTypes.number.isRequired,
  noCodPayment: PropTypes.bool,
}
