import { actionTypes } from 'modules/typifications/constants'

const initialState = {
  data: {
    operator: null,
    campaign: null,
    reason: null,
    subreason1: null,
    result: null,
    subresult: null,
    msisdn: null,
    description: null,
  },
  valid: null,
  dirty: null,
}

export function formTypificationsReducer(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case actionTypes.SET_DATA_FORM_TYPIFICATIONS: {
      return {
        ...state,
        ...payload,
      }
    }

    default:
      return state
  }
}
