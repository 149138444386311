import PropTypes from 'prop-types'

import { Typography, Box } from '@material-ui/core'

import { ListItem } from 'components/ui'
import { TextStyles } from 'utils/index'

const MonthBillLineItem = ({ yearMonth, monthTxt, dueDateTxt, action, showAction, ...rest }) => (
  <ListItem pb="2px" {...rest}>
    <Box display="flex" flexDirection="column" width="100%">
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography
          {...TextStyles.paragraphDark({
            lineHeight: '1.5em',
            textTransform: 'uppercase',
          })}>
          {monthTxt}
        </Typography>
      </Box>
    </Box>
  </ListItem>
)

MonthBillLineItem.propTypes = {
  yearMonth: PropTypes.string,
  monthTxt: PropTypes.string,
  dueDateTxt: PropTypes.string,
  showAction: PropTypes.bool,
  action: PropTypes.func,
}

export default MonthBillLineItem
