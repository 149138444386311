import { call, put, takeLatest } from 'redux-saga/effects'
import { logError, formatErrorMessage } from 'services/logging'
import { axiosFactory } from 'modules/axios'

import { actions } from './actions'
import { constants } from './constants'
import { getAtcAuthToken } from './api'

export function* atcAuthTokenSaga({ payload: { orderId } }) {
  try {
    const result = yield call(getAtcAuthToken, orderId)
    yield put(actions.fetchAtcAuthTokenSuccess({ authToken: result.acctkn }))
  } catch (e) {
    const error = axiosFactory.buildError(e)
    yield call(logError, new Error(formatErrorMessage(e)))
    yield put(actions.fetchAtcAuthTokenFailed(error))
  }
}

export function* watchAtcAuthTokenSaga() {
  yield takeLatest(constants.FETCH_ATC_AUTH_TOKEN, atcAuthTokenSaga)
}
