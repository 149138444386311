import { axiosJWT } from 'modules/axios'

import { APP_CONFIG } from 'services/app-config'

const BASE_URL_PRESALE = `${APP_CONFIG.sales_cluster}/v1/orders/preorder`

export function fetchPresale(presaleId) {
  return axiosJWT({
    method: 'GET',
    url: `${BASE_URL_PRESALE}/${presaleId}`,
  }).then(resp => resp.data)
}

export function savePresale(data) {
  return axiosJWT({
    method: 'POST',
    url: `${BASE_URL_PRESALE}/save`,
    data,
  }).then(resp => resp.data)
}

export function closePresale(presaleId) {
  return axiosJWT({
    method: 'PATCH',
    url: `${BASE_URL_PRESALE}/${presaleId}/close`,
  }).then(resp => resp.data)
}
