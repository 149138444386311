import styled from 'styled-components'
import { get } from 'lodash'

// Important: Its wrapper should has relative position to cover everything

export const HighLightFrame = styled(({ className }) => (
  <div className={`${className} highLightFrame`} />
))`
  transition: opacity 0.6s;
  position: absolute;
  border: 4px ${props => get(props, 'theme.palette.bluePalette.main')} solid;
  box-shadow: 0 0 10px 1px rgba(137, 139, 140, 0.4);
  width: 100%;
  height: 100%;
  border-radius: 14px;
  pointer-events: none;
  z-index: 0;
  opacity: 0;
`
