import { createSelector } from 'reselect'

export const getInstallations = state => state.installations

export const selectInstallsMM = createSelector(getInstallations, inst => {
  return inst.data.installer === 'MM'
})

export const selectIsLoadingInstallations = createSelector(getInstallations, inst => inst.loading)

export const selectInstallationsData = createSelector(getInstallations, inst => inst?.data)

export const selectFiberInstaller = createSelector(selectInstallationsData, installationData => {
  return installationData?.installer
})
