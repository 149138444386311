import { connect } from 'react-redux'
import { selectSfid } from 'modules/Auth'
import {
  getAddress,
  getProcessedAddress,
  getCustomerInfo,
} from 'services/global-selectors/selectors'
import { getSubscriptionByCurrentProductId } from 'services/subscriptions/selectors'

import { selectTariffApigeeErrorCode } from 'modules/tariffs/store-apigee'
import { actions as coverage } from 'modules/Coverage'
import { selectQueryParam } from 'modules/Router'

import { selectors as selectorsOrderOnFly } from 'services/order-on-fly'
import { selectors as selectorsCheckOrderCTOnFly } from 'services/check-order-ct-on-fly'
import { selectOrder } from 'services/product-orders'
import { selectNebaEnabledFlag, selectInstallations360 } from 'services/feature-flag/selectors'
import {
  selectIsLoadingInstallations,
  selectInstaller,
  selectConnectionType,
  fetchInstallationDirection,
} from 'modules/InstallationsCare'

import { get } from 'lodash'
import { MoveClient } from '../../components'
import { modelSubscriptionProduct, hasAdslCustomer } from '../../helpers/formats.helper'

import { initMoveClientSagaAction, selectorInitialStep } from './init-move-client-saga'

const mapDispatchToProps = dispatch => ({
  getCoverageToken: () => dispatch(coverage.getCoverageToken()),
  getSubscriptionData: payload => dispatch(initMoveClientSagaAction(payload)),
  gotCoverageData: data => dispatch(coverage.gotCoverageData(data)),
  fetchInstallationDirection: data => dispatch(fetchInstallationDirection(data)),
})

const mapStateToProps = state => {
  const subscription = getSubscriptionByCurrentProductId(state)
  const customer = get(state, 'productOrders.data', {})

  const sfid = selectSfid(state)

  const FFlagNeba = selectNebaEnabledFlag(state)
  const FFlagInstaller = selectInstallations360(state)
  const order = selectOrder(state)
  const customerWithProductUpdated = modelSubscriptionProduct(customer, subscription)
  const productIsAdsl = hasAdslCustomer(customerWithProductUpdated) || false

  return {
    sfid,
    fixedNumber: selectQueryParam('fixedNumber')(state),
    orders: state.orders,
    order: get(state.changeAddress, 'data.orderId'),
    loadingOrder: get(state.changeAddress, 'loading'),
    ponr: state.ponr.reached,
    customer: get(state.productOrders, 'data', {}),
    customerCoverageData: state.coverage.data,
    loading: !(get(state, 'productOrders.data.id') && get(state, 'customerNew.data')),
    installationAddress: getAddress('InstallationAddressFixedLine')(state),
    currentAddress: getProcessedAddress('InstallationAddressFixedLine')(state),
    customerInfo: getCustomerInfo('productOrders')(state),
    tariffsError: selectTariffApigeeErrorCode(state),
    tokenCoverage: get(state, 'coverage.token'),
    customerProd: get(state, 'customerNew.data.products', []),
    orderCTOnFly: selectorsCheckOrderCTOnFly.getOrderCTOnFly(state),
    orderOnFly: selectorsOrderOnFly.orderOnFlyData(state),
    clientId: get(order, 'clientId'),
    loadingInitialStep: selectorInitialStep(state),
    customerWithProductUpdated,
    isFeatNebaEnabled: productIsAdsl && FFlagNeba,
    isLoadingInstallations: selectIsLoadingInstallations(state),
    installer: selectInstaller(state),
    installConnectionType: selectConnectionType(state),
    FFlagInstaller,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MoveClient)
