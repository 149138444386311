import { toDate, addMonths, format } from 'date-fns'

export function getFATPeriods(enableFatPeriod = true) {
  const today = Date.now()
  const allDates = enableFatPeriod ? [toDate(today), addMonths(today, 1), addMonths(today, 2)] : [toDate(today)]

  return allDates.map(date => ({ text: format(date, 'MM-yyyy'), value: format(date, 'yyyyMM') }))
}

export const FATCategories = {
  COMP75: { id: 'COMP75', text: 'COMP75', value: 'COMP75' },
  COMPPRE1000: { id: 'COMPPRE1000', text: 'COMPPRE1000', value: 'COMPPRE1000' },
  CPSMSFAT: { id: 'CPSMSFAT', text: 'CPSMSFAT', value: 'CPSMSFAT' },
}

export const formatPeriodForResume = period =>
  period && `${period.substr(4)}-${period.substr(0, 4)}`

export const FAT_MAX_AMOUNT = 100
export const FAT_MIN_AMOUNT = 0
