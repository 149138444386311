import { createStructuredSelector, createSelector } from 'reselect'
import { selectIsMobileOnly } from 'modules/Router/store/index'
import { formatSvasPenalty } from 'modules/NewClientSales/store/selectors/NewClientSales.selectors.helpers'
import { selectSVAListWithTax, selectIsSVAProListLoading } from 'modules/SVAs/store'
import {
  selectedLandlineProSVAsFormated,
  selectFormatedProSvas,
  selectIsPro,
} from 'modules/NewClientSales/store/selectors/index'

export const selectFormatedProSvasPermanency = createSelector(
  selectedLandlineProSVAsFormated,
  landlineProSvas => formatSvasPenalty(landlineProSvas),
)

export const proSvasSelectors = createStructuredSelector({
  svaList: selectSVAListWithTax,
  isLoading: selectIsSVAProListLoading,
  formatedSvas: selectFormatedProSvas,
  storeSvas: selectedLandlineProSVAsFormated,
  isMobile: selectIsMobileOnly,
  isPro: selectIsPro,
  formatedSvasPermanency: selectFormatedProSvasPermanency,
})
