import PropTypes from 'prop-types'
import { get } from 'lodash'
import { Popover, ModalActions, ButtonLink } from 'components/ui'
import { isElFijoTariff } from 'modules/tariffs/helpers'
import { MULTISIM_LINE } from 'services/customer-360/constants'
import { shouldEnableInfiniteOption } from '../helpers'

import { DiscountFormContainer } from '../containers/DiscountFormContainer'
import { BonusFormContainer } from '../containers/BonusFormContainer'

const DiscountsAndBonusesSelector = ({
  isOpen,
  type,
  openPopover,
  subscription,
  closePopover,
  onDiscountSelect,
  onDiscountPreSelect,
  hasIncompatibilities,
  onBonusSelect,
  canAddBonus,
  canAddDiscount,
  isFixedReduction,
  infiniteTariff,
  newTariffId,
  currentTariff,
}) => {
  const isElFijo = isElFijoTariff(get(subscription, 'type'))
  const isMultisim = get(subscription, 'multisimType') === MULTISIM_LINE

  return (
    <>
      <ModalActions>
        <ButtonLink
          data-hook="add-discount-btn"
          disabled={!canAddDiscount}
          onClick={() => openPopover('discount')}>
          Añadir descuento
        </ButtonLink>
        {!isMultisim && (
          <ButtonLink
            disabled={!canAddBonus || isElFijo}
            data-hook="add-promo-bonus"
            onClick={() => openPopover('bonus')}>
            Añadir bono
          </ButtonLink>
        )}
      </ModalActions>
      {isOpen && (
        <Popover hasArrow arrowLeft={type === 'discount' ? '35px' : '195px'}>
          {type && type === 'discount' && (
            <DiscountFormContainer
              newTariffId={newTariffId}
              currentTariff={currentTariff}
              isFixedReduction={isFixedReduction}
              goToPreviousStep={closePopover}
              goToNextStep={closePopover}
              subscription={subscription}
              onDiscountSelect={onDiscountSelect}
              onDiscountPreSelect={onDiscountPreSelect}
              hasIncompatibilities={hasIncompatibilities}
              infiniteTariff={shouldEnableInfiniteOption(subscription.type, infiniteTariff)}
            />
          )}
          {type && type === 'bonus' && (
            <BonusFormContainer
              onBonusSelect={onBonusSelect}
              goToPreviousStep={closePopover}
              goToNextStep={closePopover}
            />
          )}
        </Popover>
      )}
    </>
  )
}

DiscountsAndBonusesSelector.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  openPopover: PropTypes.func.isRequired,
  subscription: PropTypes.object.isRequired,
  closePopover: PropTypes.func.isRequired,
  onDiscountSelect: PropTypes.func.isRequired,
  onDiscountPreSelect: PropTypes.func.isRequired,
  hasIncompatibilities: PropTypes.bool.isRequired,
  onBonusSelect: PropTypes.func.isRequired,
  canAddBonus: PropTypes.bool.isRequired,
  canAddDiscount: PropTypes.bool,
  isFixedReduction: PropTypes.bool,
  newTariffId: PropTypes.string,
  currentTariff: PropTypes.object,
  infiniteTariff: PropTypes.bool,
}

export default DiscountsAndBonusesSelector
