import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Typography, Icon } from '@material-ui/core'
import { ICONS } from 'utils/icons'

import { TextStyles } from 'utils/text'

import SubscriptionStatusBadge from '../subscription-status-badge/SubscriptionStatusBadge'

const iconSvg = {
  fixed: '/assets/fixed_phone.svg',
  mobile: '/assets/sim_filled.svg',
  internet: '/assets/fixed_internet_filled.svg',
}

const ConfigLine = styled(({ className, type, line, status, newStatus }) => (
  <div className={`config-line ${className}`}>
    <span>
      <img src={iconSvg[type]} alt={type} />
      {line && line !== '' ? (
        <Typography
          {...TextStyles.subtitle2Dark({
            component: 'div',
          })}>
          {line}
        </Typography>
      ) : null}
    </span>
    {status && <SubscriptionStatusBadge variant="small" status={status} />}
    {status && newStatus && (
      <div className="new-status">
        <Icon className="icon" data-hook="icon-forward">
          {ICONS.forward}
        </Icon>
        <SubscriptionStatusBadge variant="small" status={newStatus} />
      </div>
    )}
  </div>
))`
  display: flex;
  align-items: center;
  span {
    display: flex;
    align-items: center;
    div {
      margin-right: 8px;
    }
  }
  img {
    height: 24px;
    margin-right: 8px;
  }
  .new-status {
    display: flex;
    align-items: center;
    .icon {
      margin-left: 8px;
      margin-right: 7px;
    }
  }

  .badge {
    margin-left: 8px;
  }
`

ConfigLine.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf(['fixed', 'mobile', 'internet']),
  line: PropTypes.string,
  status: PropTypes.oneOf([
    '',
    'new',
    'active',
    'port',
    'freeze',
    'fly',
    'unsubscribe',
    'historical',
    'incidence',
  ]),
  newStatus: PropTypes.oneOf([
    'new',
    'active',
    'port',
    'freeze',
    'fly',
    'unsubscribe',
    'historical',
    'incidence',
  ]),
}

export default ConfigLine
