import { get, chain, indexOf } from 'lodash'
import { constants } from './constants'

export const getOrderOnFlyChTech = data =>
  chain(data)
    .find(
      orderOnFlyDat =>
        indexOf(constants.WORK_ORDER_TYPE_IDS_CT_FIBER, orderOnFlyDat.workorderTypeId) >= 0,
    )
    .value()

export const getOrderOnFlyChAddr = data =>
  chain(data)
    .find({ workorderName: 'CD_A' })
    .value()

export const getWorkOrderID = orderOnFlyDat => get(orderOnFlyDat, 'workorderId')
export const getLastModifiedDate = orderOnFlyDat => get(orderOnFlyDat, 'lastModifiedDate')
export const getStatus = orderOnFlyDat => get(orderOnFlyDat, 'statusId')

export const getOrderByType = data => ({
  change: Boolean(getOrderOnFlyChTech(data)),
  move: Boolean(getOrderOnFlyChAddr(data)),
})
