import { get } from 'lodash'
import { createSelector } from 'reselect'

const getCancelOrderBus = state => get(state, 'cancelOrdersBus', {})

export const selectCancelOrderBusData = createSelector(
  getCancelOrderBus,
  state => state.data || {}
)

export const selectCancelOrderBusLoading = createSelector(
  getCancelOrderBus,
  state => state.loading
)

export const selectCancelOrderBusError = createSelector(
  getCancelOrderBus,
  state => state.error || {}
)

export const selectCancelOrderBusErrorCode = createSelector(
  selectCancelOrderBusError,
  error => error.httpCode
)


export const selectCancelOrderBusErrorMessage = createSelector(
  selectCancelOrderBusError,
  error => error.info
)