import {
  PUT_ALARMS_SALES_START,
  PUT_ALARMS_SALES_SUCCESS,
  PUT_ALARMS_SALES_FAILED,
  RESET_ALARMS_SALES,
} from './constants'

export const putCadenceAlarmSalesStart = (
  contractId,
  workOrder,
  msisdn,
  subscriptionType,
  invoicingAddress,
  sendAddress,
  isCarteraFlow,
  segment,
) => ({
  type: PUT_ALARMS_SALES_START,
  payload: {
    contractId,
    workOrder,
    msisdn,
    subscriptionType,
    invoicingAddress,
    sendAddress,
    isCarteraFlow,
    segment,
  },
})

export const putCadenceAlarmSalesSuccess = payload => ({
  type: PUT_ALARMS_SALES_SUCCESS,
  payload,
})

export const putCadenceAlarmSalesFailed = error => ({
  type: PUT_ALARMS_SALES_FAILED,
  payload: error,
})

export const resetCadenceAlarmSales = () => ({
  type: RESET_ALARMS_SALES,
})
