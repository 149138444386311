import { chain } from 'lodash'

import { createStructuredSelector, createSelector } from 'reselect'

import { getIdentificationType, getIdentificationId } from 'services/customer-360/selectors'

import { mapToFormatedError } from 'modules/NewClientSales/store/selectors/NewClientSales.selectors.helpers'
import { YOIGO_STORE } from 'modules/SharedSales/constants/shippingAddress'

import {
  selectGenerateOrderIsLoading,
  selectGenerateOrderTaskId,
  selectGenerateOrderError,
  selectRenewalDeviceInfo,
  selectGenerateOrderStartPollingTime,
  selectRenewalMsisdn,
  selectGenerateOrderResult,
  selectRenewalDeviceShipping,
  selectIsDevicePaymentMethodUpfront,
} from '../../store/device-renewal.selectors'

const selectDeviceName = createSelector(
  selectRenewalDeviceInfo,
  ({ name }) => name,
)

const selectWillDeviceBeShippedToStore = createSelector(
  selectRenewalDeviceShipping,
  shipping => shipping?.type === YOIGO_STORE.value,
)

const selectErrorCheckout = createSelector(
  [selectGenerateOrderError, selectRenewalMsisdn, getIdentificationType, getIdentificationId],
  (error, msisdn, documentType, documentId) => {
    if (!error) {
      return []
    }

    const errorResponse = error.error_response
    const errorIds = errorResponse ? Object.keys(errorResponse) : []

    return [
      {
        documentType,
        documentId,
        error: mapToFormatedError(
          error,
          { id: errorResponse ? errorIds[0] : null },
          errorResponse || {},
          errorResponse && errorIds.length > 0,
        ),
        key: 'renewal-1',
        name: 'Renuevo',
        phone: msisdn,
        shouldStartOpen: false,
      },
    ]
  },
)

const selectOrderId = createSelector(
  [selectGenerateOrderResult],
  orderResult => {
    return orderResult
      ? chain(orderResult)
          .flatMap()
          .first()
          .value().id
      : undefined
  },
)

export const summaryContainerSelectors = createStructuredSelector({
  isLoading: selectGenerateOrderIsLoading,
  taskId: selectGenerateOrderTaskId,
  errorCheckout: selectErrorCheckout,
  deviceName: selectDeviceName,
  startPollingTime: selectGenerateOrderStartPollingTime,
  docNumber: getIdentificationId,
  msisdn: selectRenewalMsisdn,
  documentType: getIdentificationType,
  orderId: selectOrderId,
  willDeviceBeShippedToStore: selectWillDeviceBeShippedToStore,
  isDevicePaymentMethodUpfront: selectIsDevicePaymentMethodUpfront,
})
