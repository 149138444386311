import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

export function useCleanQueryParamsWhenUnmount(queryParams = []) {
  const history = useHistory()

  useEffect(() => {
    return function cleanup() {
      // Only Push actions need to be changed
      if (history.action === 'PUSH') {
        const searchParams = new URLSearchParams(history.location.search)
        queryParams.forEach(queryParam => {
          searchParams.delete(queryParam)
        })

        const newLocation = { ...history.location, search: searchParams.toString() }

        history.replace(newLocation)
      }
    }
  }, [])
}
