import PropTypes from 'prop-types'

import { IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  root: {
    border: `1px solid ${theme.palette.global.gray}`,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1),
  },
}))

export function OutlinedIconButton({ children, ...props }) {
  const styles = useStyles()

  return (
    <IconButton classes={styles} {...props}>
      {children}
    </IconButton>
  )
}
OutlinedIconButton.propTypes = {
  children: PropTypes.node.isRequired,
}
