export const config = {
  staging: false,
  production: false,
  siteRoot: 'http://localhost:3000',
  apigee_host: 'https://masmovil-test-staging.apigee.net',
  translate_host: 'https://masmoviltranslate.azurewebsites.net/api',
  coverage_host: 'https://ovid-coverage-component.sta.k8s.masmovil.com',
  logs_host: 'https://logs.dev.ovid-project.com',
  contentfulUserId: '5b7ea1b1b3ea0a140a081b94',
  cdnAppointmentsUrlCss:
    'https://cdncomponentescitaciones-endpointpre.azureedge.net/react/site.css',
  cdnAppointments: 'https://cdncomponentescitaciones-endpointpre.azureedge.net',
  installation_appointments: 'https://pruebasmm.kairos365.com/',
  basicToken: 'Yk1ZV0pOZGlzRTJuMUdaQUpvNGR4VE9HN1dhZUdMeUE6d0k4R0dCT0dUNGZuOHBaVQ==',
  changeOwner: 'https://coqw.dev.ovid-project.com',
  sales_cluster: 'https://sales.sta.k8s.masmovil.com',
  google_reporting: {
    enabled: false,
    projectId: 'mm-sales-dev',
    key: 'AIzaSyBnXj0EaM7dg6FsJdGCAnYRK01Y1nOdi_4',
  },
  analytics: {
    id: 'GTM-TC5PG3L',
  },
  care_cluster: 'https://care.sta.k8s.masmovil.com',
  care_private_cluster: 'https://care.private.sta.k8s.masmovil.com',
  cancelled_clients: 'https://yuatb-clientes.staging.qvantel.net/terminated_subscription_search',
  liveperson: 'http://liveengage.liveperson.net',
  vista: 'https://yuatb-clientes.staging.qvantel.net',
  masoss: 'https://premasoss.masmovil.com',
  atc: 'https://preprovisionatc.masmovil.com',
  deferred_payment: 'https://mas-care-landings.dev.k8s.masmovil.com/aplazar-facturas',
  mas_care: 'https://mas-care-sta.dev-01.k8s.masmovil.com',
  energy: 'https://manage.energygo.blue',
  televenta_login: 'https://yuatb-televenta.staging.qvantel.net/ccorder/login_form',
  newton_login: 'https://yuatb-clientes.staging.qvantel.net/newton/login_form',
  newton_renewal: 'https://yuatb-clientes.staging.qvantel.net/newton/frame?url=/ccorder',
  mas_consents: 'https://mas-consents-front.sta.masstack.com',
  mnp: 'https://yuatb-clientes.staging.qvantel.net/mnp',
  bibe_broker: 'https://bibebroker.ntkia.es/ppdatossolicitud/atc',
  bibe_sale: ' https://bibebroker.ntkia.es/bibemovil/solicitud/renuevo/login',
  new_bibe_broker: 'https://bibebroker.ntkia.es/ppdatossolicitud/atc',
  new_bibe_sale: 'https://segurosgmm.ntkia.es/bibemovil/solicitud/renuevo/login',
  bibe_ppi_sale: 'https://bibebroker.ntkia.es/ppdatossolicitudatc/login',
}
