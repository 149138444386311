import { uniqBy, flatMap, find, isEmpty, concat } from 'lodash'
import { permissions } from './permissions'

export const roles = [
  {
    id: 'super_admin',
    desc: 'All operations for Amazing 360',
    roleIds: [],
    permissions: Object.values(permissions),
  },
  {
    id: 'pro_admin',
    desc: 'All operations for an ATC PRO operator',
    roleIds: [],
    permissions: [
      permissions.additional_activity_register,
      permissions.barring_ops_access,
      permissions.bill_discount_ops_access,
      permissions.bill_edit_data,
      permissions.bill_download,
      permissions.client_ops_access,
      permissions.credit_fat_ops_access,
      permissions.cvm_energy_sale,
      permissions.cvm_modify_contact_email,
      permissions.cvm_modify_contact_phone_numbers,
      permissions.cvm_ops_access,
      permissions.cvm_ops_add_bundles_all,
      permissions.cvm_ops_add_devices,
      permissions.cvm_ops_add_discounts_all,
      permissions.cvm_ops_add_mobile_line,
      permissions.cvm_ops_add_retention_discounts,
      permissions.cvm_ops_disable_discounts_all,
      permissions.cvm_ops_modify_permanency_fixed,
      permissions.cvm_ops_modify_permanency_mobile,
      permissions.cvm_ops_tariff_change,
      permissions.cvm_ops_unsubscribe_package,
      permissions.energy_go_crm,
      permissions.energy_view_contracts,
      permissions.typification_ops_access,
      permissions.view_complete_account_number,
      permissions.cvm_commercial_migration,
      permissions.cvm_insurance_sale,
      permissions.cvm_insurance_ppi_sale,
      permissions.view_detail_use_subscription,
    ],
  },
  {
    id: 'soho_admin',
    desc: 'All operations for an ATC SOHO operator',
    roleIds: [],
    permissions: [
      permissions.additional_activity_register,
      permissions.barring_ops_access,
      permissions.bill_discount_ops_access,
      permissions.bill_edit_data,
      permissions.bill_download,
      permissions.client_ops_access,
      permissions.credit_fat_ops_access,
      permissions.cvm_energy_sale,
      permissions.cvm_modify_contact_email,
      permissions.cvm_modify_contact_phone_numbers,
      permissions.cvm_ops_access,
      permissions.cvm_ops_add_bundles_all,
      permissions.cvm_ops_add_devices,
      permissions.cvm_ops_add_discounts_all,
      permissions.cvm_ops_add_mobile_line,
      permissions.cvm_ops_add_retention_discounts,
      permissions.cvm_ops_disable_discounts_all,
      permissions.cvm_ops_modify_permanency_fixed,
      permissions.cvm_ops_modify_permanency_mobile,
      permissions.cvm_ops_tariff_change,
      permissions.cvm_ops_unsubscribe_package,
      permissions.energy_go_crm,
      permissions.energy_view_contracts,
      permissions.typification_ops_access,
      permissions.view_complete_account_number,
      permissions.cvm_commercial_migration,
      permissions.cvm_insurance_sale,
      permissions.cvm_insurance_ppi_sale,
      permissions.view_detail_use_subscription,
    ],
  },
  {
    id: 'atc_admin',
    desc: 'All operations for an ATC admin operator',
    roleIds: [],
    permissions: [
      permissions.additional_activity_register,
      permissions.barring_ops_access,
      permissions.bill_discount_ops_access,
      permissions.bill_download,
      permissions.bill_edit_data,
      permissions.client_ops_access,
      permissions.credit_fat_ops_access,
      permissions.cvm_modify_contact_email,
      permissions.cvm_modify_contact_phone_numbers,
      permissions.cvm_ops_access,
      permissions.cvm_ops_add_bundles_all,
      permissions.cvm_ops_add_discounts_all,
      permissions.cvm_ops_add_mobile_line,
      permissions.cvm_ops_add_retention_discounts,
      permissions.cvm_ops_disable_discounts_all,
      permissions.cvm_ops_tariff_change,
      permissions.energy_go_crm,
      permissions.energy_view_contracts,
      permissions.typification_ops_access,
      permissions.view_complete_account_number,
      permissions.cvm_commercial_migration,
      permissions.view_detail_use_subscription,
      permissions.cvm_insurance_sale,
    ],
  },
  {
    id: 'atc_comercial',
    desc: 'All operations for an ATC Comercial',
    roleIds: [],
    permissions: [
      permissions.additional_activity_register,
      permissions.barring_ops_access,
      permissions.bill_discount_ops_access,
      permissions.bill_download,
      permissions.bill_edit_data,
      permissions.client_ops_access,
      permissions.credit_fat_ops_access,
      permissions.cvm_modify_contact_email,
      permissions.cvm_modify_contact_phone_numbers,
      permissions.cvm_ops_access,
      permissions.cvm_ops_add_bundles_all,
      permissions.cvm_ops_add_discounts_all,
      permissions.cvm_ops_add_mobile_line,
      permissions.cvm_ops_add_retention_discounts,
      permissions.cvm_ops_disable_discounts_all,
      permissions.cvm_ops_tariff_change,
      permissions.energy_go_crm,
      permissions.energy_view_contracts,
      permissions.typification_ops_access,
      permissions.view_complete_account_number,
      permissions.cvm_commercial_migration,
      permissions.view_detail_use_subscription,
      permissions.cvm_insurance_sale,
    ],
  },
  {
    id: 'atc_sales_admin',
    desc: 'All operations for an ATC sales operator',
    roleIds: [],
    permissions: [
      permissions.additional_activity_register,
      permissions.barring_ops_access,
      permissions.bill_discount_ops_access,
      permissions.bill_download,
      permissions.bill_edit_data,
      permissions.client_ops_access,
      permissions.credit_fat_ops_access,
      permissions.cvm_energy_sale,
      permissions.cvm_modify_contact_email,
      permissions.cvm_modify_contact_phone_numbers,
      permissions.cvm_ops_access,
      permissions.cvm_ops_add_devices,
      permissions.cvm_ops_add_mobile_line,
      permissions.energy_view_contracts,
      permissions.view_complete_account_number,
      permissions.cvm_commercial_migration,
      permissions.view_detail_use_subscription,
    ],
  },
  {
    id: 'atc_cancelations_admin',
    desc: 'All operations for an ATC cancelations operator',
    roleIds: [],
    permissions: [
      permissions.additional_activity_register,
      permissions.additional_ops_access,
      permissions.barring_ops_access,
      permissions.bill_discount_ops_access,
      permissions.bill_download,
      permissions.bill_edit_data,
      permissions.client_ops_access,
      permissions.credit_fat_ops_access,
      permissions.cvm_energy_sale,
      permissions.cvm_modify_contact_email,
      permissions.cvm_modify_contact_phone_numbers,
      permissions.cvm_ops_access,
      permissions.cvm_ops_add_bundles_all,
      permissions.cvm_ops_add_devices,
      permissions.cvm_ops_add_discounts_all,
      permissions.cvm_ops_add_mobile_line,
      permissions.cvm_ops_tariff_change,
      permissions.energy_go_crm,
      permissions.energy_view_contracts,
      permissions.view_complete_account_number,
      permissions.cvm_commercial_migration,
      permissions.typification_ops_access,
      permissions.view_detail_use_subscription,
      permissions.cvm_insurance_ppi_sale,
      permissions.cvm_insurance_sale,
    ],
  },
  {
    id: 'atc_retention_admin',
    desc: 'All operations for an ATC retention operator',
    roleIds: [],
    permissions: [
      permissions.additional_activity_register,
      permissions.barring_ops_access,
      permissions.bill_discount_ops_access,
      permissions.bill_download,
      permissions.bill_edit_data,
      permissions.client_ops_access,
      permissions.credit_fat_ops_access,
      permissions.cvm_energy_sale,
      permissions.cvm_modify_contact_email,
      permissions.cvm_modify_contact_phone_numbers,
      permissions.cvm_ops_access,
      permissions.cvm_ops_add_bundles_all,
      permissions.cvm_ops_add_devices,
      permissions.cvm_ops_add_discounts_all,
      permissions.cvm_ops_add_mobile_line,
      permissions.cvm_ops_add_retention_discounts,
      permissions.cvm_ops_disable_discounts_all,
      permissions.cvm_ops_add_multisim_discounts,
      permissions.cvm_ops_modify_permanency_fixed,
      permissions.cvm_ops_modify_permanency_mobile,
      permissions.cvm_ops_tariff_change,
      permissions.cvm_ops_unsubscribe_package,
      permissions.energy_go_crm,
      permissions.energy_view_contracts,
      permissions.typification_ops_access,
      permissions.view_complete_account_number,
      permissions.cvm_insurance_sale,
      permissions.cvm_commercial_migration,
      permissions.cvm_insurance_ppi_sale,
      permissions.view_detail_use_subscription,
    ],
  },
  {
    id: 'atc_retention_breakdown_admin',
    desc: 'All operations for an ATC retention breakdown operator',
    roleIds: [],
    permissions: [
      permissions.additional_activity_register,
      permissions.barring_ops_access,
      permissions.bill_discount_ops_access,
      permissions.bill_download,
      permissions.bill_edit_data,
      permissions.client_ops_access,
      permissions.credit_fat_ops_access,
      permissions.cvm_modify_contact_email,
      permissions.cvm_modify_contact_phone_numbers,
      permissions.cvm_ops_access,
      permissions.cvm_ops_add_bundles_all,
      permissions.cvm_ops_add_discounts_all,
      permissions.cvm_ops_add_mobile_line,
      permissions.cvm_ops_add_retention_discounts,
      permissions.cvm_ops_disable_discounts_all,
      permissions.cvm_ops_modify_permanency_fixed,
      permissions.cvm_ops_modify_permanency_mobile,
      permissions.cvm_ops_tariff_change,
      permissions.cvm_ops_unsubscribe_package,
      permissions.energy_go_crm,
      permissions.energy_view_contracts,
      permissions.typification_ops_access,
      permissions.view_complete_account_number,
      permissions.cvm_commercial_migration,
      permissions.view_detail_use_subscription,
    ],
  },
  {
    id: 'atc_fidelization_admin',
    desc: 'All operations for an ATC fidelization operator, but only allows 10% discounts',
    roleIds: [],
    permissions: [
      permissions.additional_activity_register,
      permissions.barring_ops_access,
      permissions.bill_discount_ops_access,
      permissions.bill_download,
      permissions.bill_edit_data,
      permissions.client_ops_access,
      permissions.credit_fat_ops_access,
      permissions.cvm_energy_sale,
      permissions.cvm_modify_contact_email,
      permissions.cvm_modify_contact_phone_numbers,
      permissions.cvm_ops_access,
      permissions.cvm_ops_add_bundles_all,
      permissions.cvm_ops_add_devices,
      permissions.cvm_ops_add_mobile_line,
      permissions.cvm_ops_add_retention_discounts,
      permissions.cvm_ops_disable_discounts_all,
      permissions.cvm_ops_tariff_change,
      permissions.cvm_ops_unsubscribe_package,
      permissions.energy_go_crm,
      permissions.energy_view_contracts,
      permissions.view_complete_account_number,
      permissions.cvm_insurance_sale,
      permissions.cvm_commercial_migration,
      permissions.cvm_insurance_ppi_sale,
      permissions.view_detail_use_subscription,
      permissions.typification_ops_access,
    ],
  },
  {
    id: 'loyalty_inbound_outbound',
    desc: 'Loyalty operator',
    roleIds: [],
    permissions: [
      permissions.additional_activity_register,
      permissions.barring_ops_access,
      permissions.bill_discount_ops_access,
      permissions.bill_download,
      permissions.bill_edit_data,
      permissions.client_ops_access,
      permissions.credit_fat_ops_access,
      permissions.cvm_energy_sale,
      permissions.cvm_modify_contact_email,
      permissions.cvm_modify_contact_phone_numbers,
      permissions.cvm_ops_access,
      permissions.cvm_ops_add_bundles_all,
      permissions.cvm_ops_add_devices,
      permissions.cvm_ops_add_mobile_line,
      permissions.cvm_ops_add_retention_discounts,
      permissions.cvm_ops_disable_discounts_all,
      permissions.cvm_ops_tariff_change,
      permissions.cvm_ops_unsubscribe_package,
      permissions.energy_go_crm,
      permissions.energy_view_contracts,
      permissions.view_complete_account_number,
      permissions.cvm_insurance_sale,
      permissions.cvm_commercial_migration,
      permissions.cvm_insurance_ppi_sale,
      permissions.view_detail_use_subscription,
      permissions.typification_iframe_access
    ],
  },
  {
    id: 'atc_xselling_admin',
    desc: 'All operations for an ATC xselling operator',
    roleIds: [],
    permissions: [
      permissions.additional_activity_register,
      permissions.barring_ops_access,
      permissions.bill_discount_ops_access,
      permissions.bill_download,
      permissions.bill_edit_data,
      permissions.client_ops_access,
      permissions.credit_fat_ops_access,
      permissions.cvm_energy_sale,
      permissions.cvm_modify_contact_email,
      permissions.cvm_modify_contact_phone_numbers,
      permissions.cvm_ops_access,
      permissions.cvm_ops_add_discounts_all,
      permissions.cvm_ops_add_bundles_all,
      permissions.cvm_ops_add_devices,
      permissions.cvm_ops_add_mobile_line,
      permissions.cvm_ops_tariff_change,
      permissions.cvm_ops_unsubscribe_package,
      permissions.energy_go_crm,
      permissions.energy_view_contracts,
      permissions.view_complete_account_number,
      permissions.cvm_commercial_migration,
      permissions.cvm_insurance_sale,
      permissions.cvm_insurance_ppi_sale,
      permissions.view_detail_use_subscription,
    ],
  },
  {
    id: 'pos_admin',
    desc: 'All operations for POS operator',
    roleIds: [],
    permissions: [
      permissions.additional_ops_access,
      permissions.barring_ops_access,
      permissions.client_ops_access,
      permissions.cvm_energy_sale,
      permissions.cvm_modify_contact_phone_numbers,
      permissions.cvm_ops_access,
      permissions.cvm_ops_tariff_change,
      permissions.energy_go_crm,
      permissions.energy_view_contracts,
      permissions.cvm_ops_add_discounts_all,
      permissions.cvm_multi_sim,
      permissions.bill_edit_data,
      permissions.view_detail_use_subscription,
    ],
  },
  {
    id: 'd2d_soho',
    desc: 'All operations for D2D operator',
    roleIds: [],
    permissions: [
      permissions.additional_activity_register,
      permissions.additional_ops_access,
      permissions.bill_download,
      permissions.cvm_ops_access,
      permissions.cvm_ops_add_devices,
      permissions.cvm_ops_add_mobile_line,
      permissions.view_complete_account_number,
      permissions.cvm_commercial_migration,
      permissions.view_detail_use_subscription,
    ],
  },
  {
    id: 'masproximo_admin',
    desc: 'All operations for MASProximo operator',
    roleIds: [],
    permissions: [
      permissions.additional_activity_register,
      permissions.additional_ops_access,
      permissions.cvm_energy_sale,
      permissions.cvm_ops_access,
      permissions.cvm_ops_add_devices,
      permissions.cvm_ops_add_mobile_line,
      permissions.energy_go_crm,
      permissions.energy_view_contracts,
      permissions.client_ops_access,
      permissions.cvm_ops_tariff_change,
      permissions.cvm_commercial_migration,
      permissions.bill_edit_data,
      permissions.cvm_multi_sim,
      permissions.cvm_insurance_sale,
      permissions.cvm_insurance_ppi_sale,
    ],
  },
  {
    id: 'onboarding_admin',
    desc: 'All operations for ONBOARDING operator',
    roleIds: [],
    permissions: [
      permissions.additional_activity_register,
      permissions.additional_ops_access,
      permissions.barring_ops_access,
      permissions.bill_discount_ops_access,
      permissions.bill_download,
      permissions.bill_edit_data,
      permissions.client_ops_access,
      permissions.credit_fat_ops_access,
      permissions.cvm_modify_contact_email,
      permissions.cvm_modify_contact_phone_numbers,
      permissions.cvm_ops_access,
      permissions.cvm_ops_add_bundles_all,
      permissions.cvm_ops_add_devices,
      permissions.cvm_ops_add_discounts_all,
      permissions.cvm_ops_add_mobile_line,
      permissions.cvm_ops_add_retention_discounts,
      permissions.cvm_ops_disable_discounts_all,
      permissions.cvm_ops_modify_permanency_fixed,
      permissions.cvm_ops_modify_permanency_mobile,
      permissions.cvm_ops_tariff_change,
      permissions.cvm_ops_unsubscribe_package,
      permissions.typification_ops_access,
      permissions.view_complete_account_number,
      permissions.cvm_commercial_migration,
      permissions.view_detail_use_subscription,
    ],
  },
  {
    id: 'backoffice_admin',
    desc: 'All operations for BACKOFFICE operator',
    roleIds: [],
    permissions: [
      permissions.additional_activity_register,
      permissions.barring_ops_access,
      permissions.bill_discount_ops_access,
      permissions.bill_download,
      permissions.bill_edit_data,
      permissions.client_ops_access,
      permissions.credit_fat_ops_access,
      permissions.cvm_energy_sale,
      permissions.cvm_modify_contact_email,
      permissions.cvm_modify_contact_phone_numbers,
      permissions.cvm_ops_access,
      permissions.cvm_ops_add_bundles_all,
      permissions.cvm_ops_add_devices,
      permissions.cvm_ops_add_discounts_all,
      permissions.cvm_ops_add_mobile_line,
      permissions.cvm_ops_add_retention_discounts,
      permissions.cvm_ops_disable_discounts_all,
      permissions.cvm_ops_modify_permanency_fixed,
      permissions.cvm_ops_modify_permanency_mobile,
      permissions.cvm_ops_tariff_change,
      permissions.cvm_ops_unsubscribe_package,
      permissions.energy_go_crm,
      permissions.energy_view_contracts,
      permissions.typification_ops_access,
      permissions.view_complete_account_number,
      permissions.cvm_commercial_migration,
      permissions.view_detail_use_subscription,
    ],
  },
]

// Get all roles in a DAG of roles
export function getRoles(roleIds = []) {
  if (isEmpty(roleIds)) {
    return []
  }

  const initialRoles = roleIds.map(roleId => find(roles, { id: roleId }))
  return uniqBy(
    concat(
      initialRoles,
      flatMap(initialRoles, role => getRoles(role.roleIds)),
    ),
    'id',
  )
}
