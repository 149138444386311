import { createSelector } from 'reselect'
import { omitBy, get } from 'lodash'

import { formatTwoDigits } from 'utils/formatting'
import { INSTALLATION_ADDRESS, OTHER_ADDRESS } from 'modules/SharedSales/constants'

import {
  selectCustomerData,
  selectIsSelfEmployed,
  selectors as customer360Svc,
} from 'services/customer-360/selectors'

import {
  selectAccount,
  selectIsCompany,
  getBasket,
} from 'modules/NewClientSales/store/selectors/Basket.selectors'
import {
  selectBillingAddressCoverageInfo,
  selectBillingData,
} from 'modules/NewClientSales/store/selectors/billing.selectors'
import { selectAddressInfo } from 'modules/Coverage/store/selectors'
import { formatDateYYMMDD, DDMMYYYYToDate } from 'utils/formatting/dates'

import { storeSelectors } from 'modules/NewClientSales/hooks/Shared/useSetCustomerInfo/useSetCustomerInfo.selectors'
import { extractPersonalData } from 'modules/vista-360/selectors/selectors'
import { AUTONOMO } from 'services/global-constants/segments'
import { selectOrderAccountId } from './orders.selectors'

const documentTypeToNumber = {
  NIF: 1,
  NIE: 1,
  CIF: 2,
  PASSPORT: 3,
}

export const selectCadenceAlarmsCustomerInfo = createSelector(
  selectAccount,
  selectCustomerData,
  customer360Svc.getFullName,
  customer360Svc.getIsCompany,
  selectIsCompany,
  extractPersonalData,
  (
    customerAccount,
    customerData,
    customerFullName,
    isCompanyCare,
    isCompanySales,
    extraCustomerData,
  ) => {
    const isCompany = isCompanyCare || isCompanySales

    const carePhoneContact = get(extraCustomerData.contactPhones, '[0].number')
    const carePhoneContact2 = get(extraCustomerData.contactPhones, '[1].number')

    const customerPersonalInfo = customerAccount.name
      ? {
          documentType: documentTypeToNumber[isCompany ? 'CIF' : customerAccount.documenttype],
          documentNumber: isCompany ? customerAccount.companyCif : customerAccount.documentid,
          clientName: customerAccount.name,
          lastName: customerAccount.surname1,
          contactPerson: `${customerAccount.name} ${customerAccount.surname1} ${
            customerAccount.surname2
          }`,
          phone1: customerAccount.phoneContact,
          phone2: customerAccount.phoneContact2,
          email: customerAccount.email,
        }
      : {
          documentType: documentTypeToNumber[isCompany ? 'CIF' : customerData.documenttype],
          documentNumber: isCompany ? customerData.companyCif : customerData.documentid,
          clientName: customerData.name,
          lastName: customerData.surname1,
          contactPerson: customerFullName,
          phone1: carePhoneContact || '',
          phone2: carePhoneContact2 || '',
          email: customerData.email,
        }

    return customerPersonalInfo
  },
)

export const selectCadenceAlarmsSendAddressSales = createSelector(
  selectAddressInfo,
  selectCadenceAlarmsCustomerInfo,
  (installationAddress, customerInfo) => {
    const { postalCode, coverage, nameAddress, gescal } = installationAddress
    const provinceId =
      (installationAddress.provinceId && formatTwoDigits(installationAddress.provinceId)) ||
      (postalCode ? postalCode.substr(0, 2) : '')

    const km = coverage.Km ? `km ${coverage.Km}` : ''
    const block = coverage.block ? coverage.block : ''
    const letter = coverage.letter ? `letra ${coverage.letter}` : ''

    const stair = installationAddress.stairAddress
      ? `escalera ${installationAddress.stairAddress}`
      : ''
    const floor = installationAddress.floorAddress ? `piso ${installationAddress.floorAddress}` : ''
    const door =
      installationAddress.doorAddress || installationAddress.handAddress
        ? `puerta ${installationAddress.doorAddress || installationAddress.handAddress}`
        : ''

    const otherInfo = [km, block, stair, floor, door, letter, installationAddress.otherInfo]
      .filter(Boolean)
      .join(', ')

    return omitBy(
      {
        addressType: installationAddress.streetType,
        name: nameAddress.replace(new RegExp(`^${installationAddress.streetType} `), ''),
        fullAddress: `${nameAddress} ${installationAddress.numberAddress}`,
        other: otherInfo,
        number: installationAddress.numberAddress,
        postalCode,
        city: installationAddress.state,
        province: installationAddress.province,
        provinceCode: provinceId,
        gescal,
        territoryOwner: '',
        ...customerInfo,
      },
      value => !value || value === '',
    )
  },
)

export const selectCadenceAlarmsInvoicingAddressSales = createSelector(
  selectBillingAddressCoverageInfo,
  selectCadenceAlarmsCustomerInfo,
  selectBillingData,
  selectCadenceAlarmsSendAddressSales,
  (billingAddress, customerInfo, billinData, installationAddress) => {
    if (billinData.addressType === INSTALLATION_ADDRESS.value) {
      return installationAddress
    }

    const { streetType, address, stairAddress } = billingAddress

    const floor = billingAddress.flat ? `piso ${billingAddress.flat}` : ''
    const door = billingAddress.door ? `puerta ${billingAddress.door}` : ''
    const stair = stairAddress ? `escalera ${stairAddress}` : ''

    const otherInfo = [stair, floor, door, billingAddress.otherInfo].filter(Boolean).join(', ')

    const { zipCode } = billingAddress
    const provinceId =
      (billingAddress.provinceId && formatTwoDigits(billingAddress.provinceId)) ||
      (zipCode ? zipCode.substr(0, 2) : '')

    return omitBy(
      {
        addressType: streetType,
        name: address.replace(new RegExp(`^${streetType} `), ''),
        fullAddress: `${address} ${billingAddress.number}`,
        other: otherInfo,
        number: billingAddress.number,
        postalCode: zipCode,
        city: billingAddress.city,
        province: billingAddress.province,
        provinceCode: provinceId,
        id: billingAddress.id,
        ...customerInfo,
      },
      value => !value || value === '',
    )
  },
)

export const selectHomeGoOtherAddressSales = createSelector(
  [getBasket, selectCadenceAlarmsCustomerInfo],
  ({ homeGo }, customerInfo) => {
    if (homeGo.selectedAddressType !== OTHER_ADDRESS.value) {
      return null
    }

    const { otherAddress } = homeGo

    const { streetType, address, stairAddress, number, zipCode, province } = otherAddress

    const floor = otherAddress.flat ? `piso ${otherAddress.flat}` : ''
    const door = otherAddress.door ? `puerta ${otherAddress.door}` : ''
    const stair = stairAddress ? `escalera ${stairAddress}` : ''

    const other = [stair, floor, door, otherAddress.otherInfo].filter(Boolean).join(', ')

    const provinceId =
      (otherAddress.provinceId && formatTwoDigits(otherAddress.provinceId)) ||
      (zipCode ? zipCode.substr(0, 2) : '')

    const gescal = otherAddress.id

    return {
      addressType: streetType,
      name: address.replace(new RegExp(`^${streetType} `), ''),
      fullAddress: `${address} ${otherAddress.number}`,
      other,
      number,
      postalCode: zipCode,
      city: otherAddress.city,
      province,
      provinceCode: provinceId,
      gescal,
      territoryOwner: '',
      ...customerInfo,
    }
  },
)

export const selectHomeGoCustomerInfo = createSelector(
  selectAccount,
  storeSelectors,
  selectIsSelfEmployed,
  selectCadenceAlarmsCustomerInfo,
  selectOrderAccountId,
  (customerAccount, { customerData }, isSelfEmployed, customerInfoCadence, accountId) => {
    const customerPersonalInfo = customerAccount.name
      ? {
          ...customerInfoCadence,
          middleName: customerAccount.surname1,
          lastName: customerAccount.surname2,
          documentType: customerAccount.documenttype,
          documentNumber: customerAccount.documentid,
          birthday: customerAccount.birthday
            ? formatDateYYMMDD(DDMMYYYYToDate(customerAccount.birthday))
            : null,
          nationality: customerAccount.nationality,
          accountId,
          companyName: customerAccount.companyName,
          companyCif: customerAccount.companyCif,
          constitutionDate: customerAccount.constitutionDate
            ? formatDateYYMMDD(DDMMYYYYToDate(customerAccount.constitutionDate))
            : null,
          isSelfEmployed: customerAccount.segment === AUTONOMO,
        }
      : {
          ...customerInfoCadence,
          middleName: customerData.surname1,
          lastName: customerData.surname2,
          documentType: customerData.documenttype,
          documentNumber: customerData.documentid,
          birthday:
            customerData.birthday && formatDateYYMMDD(DDMMYYYYToDate(customerData.birthday)),
          nationality: customerData.nationality,
          accountId: customerData.accountId,
          companyName: customerData.companyName,
          companyCif: customerData.companyCif,
          constitutionDate: customerData.constitutionDate
            ? formatDateYYMMDD(DDMMYYYYToDate(customerData.constitutionDate))
            : null,
          isSelfEmployed: isSelfEmployed === 'true',
        }

    return customerPersonalInfo
  },
)
