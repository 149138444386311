import { React, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { get, isEmpty } from 'lodash'

import { selectStcV3Flag, selectStcV3Phase2Flag } from 'services/feature-flag/selectors'
import { initOrderElegibility } from 'modules/offers-configuration/actions'
import { getTariff, getActivationDate } from 'modules/offers-configuration/helpers'

import SelectTariff from 'modules/offers-configuration/components/SelectTariff'

const SelectorTariffsPo = ({
  filteredEnrichTariffs,
  taxNeeded,
  subscription,
  onTariffSelect,
  selectedTariff,
  date,
  onDateSelect,
  dateOptions,
  isLoyaltyAgent,
  isUnsusbcribe,
  salesType,
  territoryOwner,
}) => {
  const dispatch = useDispatch()
  const isStcV3Flag = useSelector(selectStcV3Flag)
  const isStcV3Phase2Flag = useSelector(selectStcV3Phase2Flag)
  const showV3Tariffs = (isStcV3Flag || isStcV3Phase2Flag) && !isUnsusbcribe
  const [dateOrder, setDateOrder] = useState(date)
  const [tariffOrder, setTariffOrder] = useState(selectedTariff)

  const dispatchInitOrderElegibility = salesTypeOrder =>
    tariffOrder &&
    !isEmpty(dateOrder) &&
    showV3Tariffs &&
    dispatch(
      initOrderElegibility(
        subscription,
        getTariff(showV3Tariffs, filteredEnrichTariffs, tariffOrder),
        getActivationDate(dateOrder),
        salesTypeOrder,
        territoryOwner,
      ),
    )

  useEffect(() => {
    if (!isEmpty(dateOrder) && !isEmpty(tariffOrder) && !isEmpty(salesType))
      dispatchInitOrderElegibility(salesType)
  }, [dateOrder, tariffOrder, salesType])

  const onTariffSelectByPsIdOrPoId = e => {
    const psId = showV3Tariffs
      ? get(getTariff(showV3Tariffs, filteredEnrichTariffs, e.target.value), 'psId')
      : e.target.value

    const poId =
      showV3Tariffs && get(getTariff(showV3Tariffs, filteredEnrichTariffs, e.target.value), 'poId')

    onTariffSelect(e, psId, poId)
    setTariffOrder(e.target.value)
  }

  const onDateSelectBy = e => {
    onDateSelect(e)
    setDateOrder(e.target.value)
  }

  return (
    <SelectTariff
      options={filteredEnrichTariffs}
      taxNeeded={taxNeeded}
      onTariffSelect={onTariffSelectByPsIdOrPoId}
      selectedTariff={selectedTariff}
      date={date}
      onDateSelect={onDateSelectBy}
      dateOptions={dateOptions}
      isLoyaltyAgent={isLoyaltyAgent}
      isUnsusbcribe={isUnsusbcribe}
    />
  )
}

SelectorTariffsPo.propTypes = {
  filteredEnrichTariffs: PropTypes.array,
  taxNeeded: PropTypes.bool,
  subscription: PropTypes.object,
  onTariffSelect: PropTypes.func,
  selectedTariff: PropTypes.string,
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onDateSelect: PropTypes.func,
  dateOptions: PropTypes.array,
  isLoyaltyAgent: PropTypes.bool,
  isUnsusbcribe: PropTypes.bool,
  salesType: PropTypes.string,
  territoryOwner: PropTypes.string,
}

export default SelectorTariffsPo
