import { createSelector } from 'reselect'
import { get } from 'lodash'
import { getDocumentType } from 'modules/CustomerDocument'
import { LEGACY_CUSTOMER_TOKEN } from '../constants'

export const selectAuth = state => state.auth || {}

export const selectAccessToken = createSelector(selectAuth, auth => auth.accessToken)

export const selectAgentToken = createSelector(selectAuth, auth => auth.agentToken)

export const selectIpAddress = createSelector(selectAuth, auth => auth.ipAddress)

export const selectIsLoading = createSelector(selectAuth, auth => auth.isLoading)

export const selectError = createSelector(selectAuth, auth => auth.error)

export const selectIsLogged = createSelector(
  selectAuth,
  auth => !!(!auth.isLoading && auth.accessToken && auth.sfid),
)

export const selectSfid = createSelector(selectAuth, auth => auth.sfid)

export const selectAgentProfileId = createSelector(selectAuth, auth => {
  // TODO: At the moment we only allow a single profile
  const profiles = (auth.roles && auth.roles.length ? auth.roles : auth.profiles) || []
  if (profiles.length > 0) {
    return profiles[0]
  }
  return []
})

export const selectAllAgentProfiles = createSelector(selectAuth, auth => {
  const profiles = (auth.roles && auth.roles.length ? auth.roles : auth.profiles) || []
  if (profiles.length > 0) {
    return profiles
  }
  return []
})

// TODO: temporal mock
export const selectUsername = selectSfid

export const selectUUID = createSelector(selectAuth, auth => auth.uuid)

export const selectFullName = createSelector(
  selectAuth,
  // TODO: temporal mock
  auth => auth.sfid,
)

export const selectAvatar = createSelector(
  selectAuth,
  // TODO: temporal mock
  auth => auth.avatar,
)

export const selectAgentProfile = createSelector(
  [selectFullName, selectAvatar],
  (fullName, avatar) => ({
    fullName,
    avatar,
  }),
)

/**
 * CUSTOMER TOKEN LEGACY SELECTORS
 */

export const selectCustomerIsLoading = createSelector(selectAuth, auth => auth.customer.loading)

export const selectCustomerLoaded = createSelector(selectAuth, auth => get(auth, 'customer.loaded'))

export const selectCustomerId = createSelector([selectAuth], auth =>
  get(auth, 'customer.customerId'),
)

export const selectCustomerToken = createSelector([selectAuth], auth =>
  get(auth, 'customer.customerToken'),
)

export const selectAccountId = createSelector([selectAuth], auth => get(auth, 'customer.accountId'))

export const selectCustomerTokenError = createSelector(selectAuth, auth =>
  get(auth, 'customer.error'),
)

export const selectAccountInfoError = createSelector(selectAuth, auth =>
  get(auth, 'customer.error'),
)

export const selectIsCustomer = createSelector([selectAuth], auth =>
  get(auth, 'customer.isCustomer'),
)

export const selectCustomerOrAgentToken = createSelector(
  [selectAuth, selectCustomerToken],
  (auth, customerToken) => customerToken || auth.agentToken,
)

export const selectIsLoggedCustomer = createSelector(
  [selectAuth, selectIsLogged],
  (auth, isLogged) => !!(isLogged && auth.customer),
)

export const selectDocumentId = createSelector([selectAuth], auth =>
  get(auth, 'customer.documentId'),
)

export const selectDocumentType = createSelector([selectAuth], auth =>
  getDocumentType(get(auth, 'customer.documentId')),
)

export const selectIsLegacyCustomer = createSelector(
  [selectAuth],
  auth => get(auth, 'customer.customerId') === LEGACY_CUSTOMER_TOKEN,
)

export const selectMigrationStatus = createSelector(selectAuth, auth =>
  get(auth, 'customer.migrationStatus'),
)
