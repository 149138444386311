import { get } from 'lodash'
import { ISLAND_SFID_LIST } from './constants'

const AUTHORIZED_OFFERS_SFID_SUFFIXES = ['_RPORT', '_RBAJA', '_RNAVY', '_RPORT2', '_RBAJA2']
const AUTHORIZED_LOYALTY_SFID_SUFFIXES = ['_UPS', '_UP1', '_FREC']

const authorizedSfidSuffixesRegex = sfid_suffix => new RegExp(`(${sfid_suffix.join('|')})$`)

const authorizedOffersSfidInfixRegex = /^[A-Z]{2}N\d{4,5}_?[A-Z]*/ // e.g. ABN012345 or MPN54321_fep

export const isOffersAuthorizedAgent = sfid =>
  authorizedSfidSuffixesRegex(AUTHORIZED_OFFERS_SFID_SUFFIXES).test(sfid) ||
  authorizedOffersSfidInfixRegex.test(sfid)

export const isLoyaltyAuthorizedAgent = sfid =>
  authorizedSfidSuffixesRegex(AUTHORIZED_LOYALTY_SFID_SUFFIXES).test(sfid)

export const isIslandSfid = sfid => ISLAND_SFID_LIST.includes(sfid)

export const checkProfile = (profiles, profileId, checkProfileId) => profileId === get(profiles, checkProfileId)