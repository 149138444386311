export const FETCH_PRESALE_DATA = 'OVID/PRESALE/FETCH_PRESALE_DATA'
export const FETCH_PRESALE_DATA_SUCCESS = 'OVID/PRESALE/FETCH_PRESALE_DATA_SUCCESS'
export const FETCH_PRESALE_DATA_ERROR = 'OVID/PRESALE/FETCH_PRESALE_DATA_ERROR'

export const SAVE_PRESALE_DATA = 'OVID/PRESALE/SAVE_PRESALE_DATA'
export const SAVE_PRESALE_DATA_SUCCESS = 'OVID/PRESALE/SAVE_PRESALE_DATA_SUCCESS'
export const SAVE_PRESALE_DATA_ERROR = 'OVID/PRESALE/SAVE_PRESALE_DATA_ERROR'

export const presaleActionTypes = {
  FETCH_PRESALE_DATA,
  FETCH_PRESALE_DATA_SUCCESS,
  FETCH_PRESALE_DATA_ERROR,
  SAVE_PRESALE_DATA,
  SAVE_PRESALE_DATA_SUCCESS,
  SAVE_PRESALE_DATA_ERROR,
}

export const savePresaleData = presaleData => ({
  type: SAVE_PRESALE_DATA,
  payload: {
    presaleData,
  },
})

export const savePresaleDataSuccess = data => ({
  type: SAVE_PRESALE_DATA_SUCCESS,
  payload: {
    data,
  },
})

export const savePresaleDataError = error => ({
  type: SAVE_PRESALE_DATA_ERROR,
  payload: {
    error,
  },
})

export const fetchPresaleData = presaleId => ({
  type: FETCH_PRESALE_DATA,
  payload: {
    presaleId,
  },
})

export const fetchPresaleDataSuccess = data => ({
  type: FETCH_PRESALE_DATA_SUCCESS,
  payload: {
    data,
  },
})

export const fetchPresaleDataError = error => ({
  type: FETCH_PRESALE_DATA_ERROR,
  payload: {
    error,
  },
})

export const presaleActions = {
  fetchPresaleData,
  fetchPresaleDataSuccess,
  fetchPresaleDataError,
  savePresaleData,
  savePresaleDataSuccess,
  savePresaleDataError,
}
