import { call, put, takeLatest } from 'redux-saga/effects'
import { logError, formatErrorMessage } from 'services/logging'
import { axiosFactory } from 'modules/axios'
import {
  GET_PONC_INIT,
  getPoncAction,
  getPoncSuccessAction,
  getPoncFailedAction,
} from './ponc.actions'
import { getPonc } from '../services/ponc.service'

export function* getPoncSaga({ payload: { orderId } }) {
  try {
    yield put(getPoncAction())
    const options = yield call(getPonc, orderId)
    yield put(getPoncSuccessAction(options))
  } catch (e) {
    yield call(logError, { e: new Error(formatErrorMessage(e)), orderId })
    const error = axiosFactory.buildError(e)
    yield put(getPoncFailedAction(error))
  }
}

export function* watchGetPonc() {
  yield takeLatest(GET_PONC_INIT, getPoncSaga)
}
