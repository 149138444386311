import PropTypes from 'prop-types'
import styled from 'styled-components'
import { get } from 'lodash'
import { useSelector } from 'react-redux'

import { Typography, Box, Divider } from '@material-ui/core'
import { TextStyles } from 'utils/text'

import { getFeesPendingFromPermanent } from 'services/subscriptions'
import { selectEnabledProrratedPenalty } from 'services/feature-flag/selectors'

import { PermanencyTab } from './PermanencyTab'
import { PendingAndTotalFee } from './PendingAndTotalFee'

export const PermanencyDiscount = styled(({ className, permanents }) => {
  const enableProrratedPenalty = useSelector(selectEnabledProrratedPenalty)

  return (
    <div className={className}>
      <Typography
        {...TextStyles.subtitle1Secondary({
          className: 'txt-none p-b-12',
        })}>
        Descuentos
      </Typography>
      <div className="flex">
        {permanents.map(permanent => (
          <>
            <PermanencyTab
              validFor={get(permanent, 'validFor')}
              totalMonths={get(permanent, 'length')}
            />

            <Box mt="8px">
              <Divider orientation="vertical" />
            </Box>

            <PendingAndTotalFee
              className="p-l-24"
              title="Penalización"
              pendingFee={getFeesPendingFromPermanent(permanent)}
              totalFee={get(permanent, 'fees.penalty', 0)}
              enableProrratedPenalty={enableProrratedPenalty}
            />
          </>
        ))}
        <Box mt="8px">
          <Divider orientation="vertical" />
        </Box>
      </div>
    </div>
  )
})`
  .txt-none {
    text-transform: none;
  }
`

PermanencyDiscount.propTypes = {
  className: PropTypes.string,
}
