import { useState, useEffect, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import { Typography, Box } from '@material-ui/core'
import { Formik, Field } from 'formik'

import {
  FormikInput,
  Modal,
  ModalActions,
  OutlinedDivider,
  Button,
  ActionAdvice,
  SpinnerButton,
  ButtonLink,
  NotificationModal,
} from 'components/ui'
import { ButtonsSelector } from 'components'
import { formatFee, formatAmountStd } from 'utils'
import { useProvinceTaxes } from 'services/taxes/hooks'
import { selectors as customer360Selectors } from 'services/customer-360/selectors'
import {
  getFATPeriods,
  FATCategories,
  fatCompensationAction,
  selectIsLoading as selectFatIsLoading,
  selectErrorMessage as selectFatErrorMessage,
} from 'modules/FAT'
import { useFetchContentfulResource } from 'modules/contentful'
import { selectTariffsApigeeById } from 'modules/tariffs/store-apigee/tariffs-apigee.selectors'
import { isLoading as selectTypificationIsLoading } from 'services/typifications/selectors'
import {
  selectSmsCommunicationIsLoading,
  selectSmsCommunicationError,
  sendSmsCommunication,
  SMS_SENDER_INFO,
} from 'services/sms-communication'

const EditAmountModal = ({ amount, show, onClose }) => {
  return show ? (
    <Modal
      title="Editar cantidad"
      isOpen={show}
      onClose={() => onClose()}
      style={{ width: '365px' }}>
      <Formik
        initialValues={{
          amount,
        }}
        onSubmit={values => {
          onClose(values.amount)
        }}
        render={({ handleSubmit, errors }) => (
          <form>
            <Box>
              <FormikInput
                label="Abono"
                data-hook="fat-amount"
                name="amount"
                type="number"
                step="0.01"
                validate={v => !v && v > 0 && 'Campo requerido'}
              />
            </Box>

            <ModalActions>
              <Button
                type="submit"
                onClick={handleSubmit}
                disabled={Object.keys(errors).length > 0}>
                Guardar
              </Button>
              <Button secondary onClick={() => onClose()}>
                Cancelar
              </Button>
            </ModalActions>
          </form>
        )}
      />
    </Modal>
  ) : null
}
EditAmountModal.propTypes = {
  amount: PropTypes.number.isRequired,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

const AdjustBillDataColumn = ({ title, value }) => (
  <Box display="flex" alignItems="baseline" mr="32px">
    <Typography variant="caption" component="label" style={{ marginRight: '5px' }}>
      {title}
    </Typography>
    <Typography variant="body1" style={{ maxWidth: '256px', fontWeight: 'bold' }}>
      {value}
    </Typography>
  </Box>
)
AdjustBillDataColumn.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
}

export const AdjustBillModal = ({ data, show, onClose }) => {
  const dispatch = useDispatch()

  const clientIsResidential = useSelector(customer360Selectors.selectIsCustomerResidential)
  const tariffData = useSelector(selectTariffsApigeeById(data?.tariff || ''))

  const clientContactPhoneNumbers = useSelector(customer360Selectors.getContactMobilePhones)
  const clientFullName = useSelector(customer360Selectors.getFullName)

  const [step, setStep] = useState(0)
  const [isShowPreviewSmsMessage, setIsShowPreviewSmsMessage] = useState(false)

  const [sendSmsCopy, setSendSmsCopy] = useState()
  const [typificationData, setTypificationData] = useState({
    reason: { value: '' },
    subreason: { value: '' },
    reasonJira: '',
  })

  const { contentfulResource, fetchContentfulResource } = useFetchContentfulResource(
    'typificationsCvmCampaign',
    {
      area: 'FAT',
      spaceName: 'cvm',
    },
    'typificationsCvmCampaignFat',
  )

  useEffect(() => {
    if (show) {
      fetchContentfulResource()
    }
  }, [show])

  const typificationReasons = useMemo(() => {
    if (!contentfulResource?.length) {
      return []
    }

    return contentfulResource[0].reasons.map(option => ({
      ...option,
      label: option.text,
    }))
  }, [contentfulResource])

  const typificationSubreasons = useCallback(
    reasonValue =>
      typificationReasons
        .find(reason => reason.value === reasonValue)
        ?.subreasons.map(option => ({
          ...option,
          label: option.text,
        })) || [],
    [typificationReasons],
  )

  const [isSaved, setIsSaved] = useState(false)

  const typificationIsLoading = useSelector(selectTypificationIsLoading)
  const smsSendingIsLoading = useSelector(selectSmsCommunicationIsLoading)
  const fatIsLoading = useSelector(selectFatIsLoading)

  const isSaving = useMemo(() => fatIsLoading || smsSendingIsLoading || typificationIsLoading, [
    fatIsLoading,
    smsSendingIsLoading,
    typificationIsLoading,
  ])

  const fatErrorMessage = useSelector(selectFatErrorMessage)
  const smsErrorMessage = useSelector(selectSmsCommunicationError)

  const [isShowFatResult, setIsShowFatResult] = useState(false)

  useEffect(() => {
    if (isSaved && !isSaving) {
      setIsShowFatResult(true)
      setIsSaved(false)

      if (!fatErrorMessage) {
        setStep(0)
        setTypificationData({
          reason: { value: '' },
          subreason: { value: '' },
          reasonJira: '',
        })
        onClose(true)
      }
    }
  }, [isSaved, isSaving, fatErrorMessage])

  const zipCode = useSelector(customer360Selectors.getBillingAddressPostCode)

  const [fatAmount, setFatAmount] = useState(0)
  const [isShowModifyAmount, setIsShowModifyAmount] = useState(false)

  const { tax, taxIsLoading } = useProvinceTaxes(zipCode)

  const smsMessage = useMemo(
    () => `Sr./Sra. ${clientFullName}, le informamos que hemos realizado un ajuste en su facturación.
  En su próxima factura se le aplicará un descuento de ${formatFee(fatAmount)} ${
      clientIsResidential ? 'IVA/impuesto incl' : 'sin impuestos'
    },
  en concepto de ${data?.concepts?.join(', ')}`,
    [clientFullName, fatAmount, data?.concepts],
  )

  useEffect(() => {
    if (show && data) {
      setFatAmount(clientIsResidential ? data.total : data.totalWithoutVat)
    }
  }, [data, show])

  const fatPeriod = getFATPeriods()[0].value

  const smsData = useMemo(
    () => ({
      phoneNumber: clientContactPhoneNumbers[0]?.number,
      content: smsMessage,
    }),
    [smsMessage, clientContactPhoneNumbers],
  )

  const onSubmitFat = () => {
    let amountWithoutVat = data.totalWithoutVat

    if (clientIsResidential && data.total !== fatAmount) {
      amountWithoutVat = fatAmount - fatAmount * (tax / 100)
    }

    const isConvergent = tariffData === 'convergent'
    const { customerId, tariff } = data

    const typification = {
      subscriptionType: '',
      campaign: contentfulResource[0]?.value,
      reason: typificationData.reason.value,
      subreason: typificationData.subreason.value,
      loyaltyDetail: {},
      detail: '',
      retention: '',
      operator: '',
      description: typificationData.reasonJira,
      ticketCreated: '',
      retentionDetail: '',
      isConvergent,
      tariff,
      area: 'FAT',
      customerId,
    }

    dispatch(
      fatCompensationAction(
        data.subscriptionId,
        data.msisdn,
        {
          'fat-group-id': FATCategories.COMP75.value,
          'euro-amount': formatAmountStd(amountWithoutVat).replace(',', '.'),
          'sms-amount': '',
          period: fatPeriod,
          comment: data.concepts.join(', '),
        },
        {
          typification,
          sms: sendSmsCopy && !!smsData.phoneNumber ? smsData : null,
        },
      ),
    )
    setIsSaved(true)
  }

  const smsOptionsButtons = useMemo(
    () => [
      { label: 'No es necesario', value: false, selected: sendSmsCopy === false, disabled: false },
      { label: 'Si', value: true, selected: sendSmsCopy === true, disabled: false },
    ],
    [sendSmsCopy],
  )

  const fatResultTitle = useMemo(() => {
    if (fatErrorMessage) {
      return 'Error intentando aplicar el FAT.'
    }

    if (smsErrorMessage) {
      return 'No se ha podido enviar el SMS al cliente'
    }

    return 'FAT aplicado correctamente!'
  }, [smsErrorMessage, fatErrorMessage])

  const retrySmsCommunication = () => {
    setIsShowFatResult(false)

    dispatch(sendSmsCommunication(SMS_SENDER_INFO.Yoigo, smsData.phoneNumber, smsData.content))
    setIsSaved(true)
  }

  return (
    <>
      <Modal
        title="Previsualización de SMS"
        isOpen={isShowPreviewSmsMessage}
        onClose={() => setIsShowPreviewSmsMessage(false)}
        style={{ width: '405px' }}>
        <Typography variant="body1">{smsMessage}</Typography>

        <ModalActions>
          <Button onClick={() => setIsShowPreviewSmsMessage(false)}>Cerrar</Button>
        </ModalActions>
      </Modal>
      <NotificationModal
        isOpen={isShowFatResult}
        type={fatErrorMessage || smsErrorMessage ? 'alert' : 'success'}
        src={fatErrorMessage || smsErrorMessage ? '/assets/error.svg' : '/assets/clap_clap.svg'}
        title={fatResultTitle}
        onClose={() => {
          setIsShowFatResult(false)
        }}>
        {fatErrorMessage && <Typography variant="body1">Por favor inténtalo más tarde.</Typography>}

        <ModalActions>
          {smsErrorMessage && (
            <Button
              onClick={() => retrySmsCommunication()}
              data-hook="sms-retry"
              className="card-link">
              Reintentar
            </Button>
          )}
          <Button secondary={!!smsErrorMessage} onClick={() => setIsShowFatResult(false)}>
            Cerrar
          </Button>
        </ModalActions>
      </NotificationModal>

      <EditAmountModal
        amount={fatAmount}
        show={isShowModifyAmount}
        onClose={amount => {
          if (amount) {
            setFatAmount(amount)
          }
          setIsShowModifyAmount(false)
        }}
      />
      <Modal title="Ajustar factura" isOpen={show} onClose={!isSaving ? () => onClose() : null}>
        {!!data && (
          <>
            {step === 0 && (
              <>
                <Box mb="21px">
                  <Typography variant="body1" component="h5" color="primary" className="bold">
                    Datos del abono
                  </Typography>
                  <Box display="flex" mt="18px">
                    <AdjustBillDataColumn
                      title="Abono:"
                      value={
                        <>
                          <span className="m-r-8">
                            {formatFee(fatAmount)} {clientIsResidential ? 'IVA inc.' : 'sin IVA'}
                          </span>
                          <ButtonLink onClick={() => setIsShowModifyAmount(true)}>
                            Editar
                          </ButtonLink>
                        </>
                      }
                    />
                    <AdjustBillDataColumn
                      title="Concepto:"
                      value={data?.concepts.map((concept, index) => {
                        const isLastestConcept = index === data.concepts.length - 1

                        return (
                          <>
                            <Typography variant="subtitle2" component="span" className="bold">
                              {concept} {!isLastestConcept && ','}
                            </Typography>
                            {!isLastestConcept && <br />}
                          </>
                        )
                      })}
                    />
                    <AdjustBillDataColumn title="ID Factura:" value={data.invoiceId} />
                  </Box>
                </Box>
                <OutlinedDivider />
                <Formik
                  initialValues={{
                    sendSmsCopy,
                    reason: typificationData.reason.value,
                    subreason: typificationData.subreason.value,
                    reasonJira: typificationData.reasonJira,
                  }}
                  onSubmit={values => {
                    const reason = typificationReasons.find(
                      typificationReason => typificationReason.value === values.reason,
                    )
                    const subreason = typificationSubreasons(values.reason).find(
                      typificationSubreason => typificationSubreason.value === values.subreason,
                    )
                    setTypificationData({
                      reason,
                      subreason,
                      reasonJira: values.reasonJira,
                    })
                    setSendSmsCopy(values.sendSmsCopy)
                    setStep(1)
                  }}
                  render={({ values, handleSubmit, setFieldValue, errors }) => (
                    <form>
                      <Box mt="18px" mb="18px">
                        <Typography variant="body1" component="h5" color="primary" className="bold">
                          Completa los motivos
                        </Typography>
                        <Box display="flex" mt="10px">
                          <FormikInput
                            label="Tipología del motivo"
                            data-hook="fat-typification-reason"
                            name="reason"
                            options={typificationReasons}
                            select
                            validate={v => !v && 'Campo requerido'}
                            style={{ marginRight: '10px' }}
                            onChange={v => {
                              const reason = typificationReasons.find(
                                typificationReason => typificationReason.value === v,
                              )

                              if (reason?.subreasons.length === 1) {
                                const [{ value: subreasonValue }] = reason.subreasons

                                setFieldValue('subreason', subreasonValue)
                              }
                            }}
                            disabled={{ bool: typificationReasons.length === 0 }}
                          />
                          <FormikInput
                            label="Motivo"
                            data-hook="fat-typification-subreason"
                            name="subreason"
                            options={typificationSubreasons(values.reason)}
                            select
                            validate={v => !v && 'Campo requerido'}
                            style={{ marginRight: '10px' }}
                            disabled={{
                              bool:
                                !values.reason || typificationSubreasons(values.reason).length < 2,
                            }}
                          />
                          <FormikInput
                            label="ID de Jira"
                            data-hook="fat-typification-jira-reason"
                            name="reasonJira"
                            validate={v => !v && 'Campo requerido'}
                          />
                        </Box>
                      </Box>

                      <OutlinedDivider />

                      <Box mt="18px">
                        <Box display="flex">
                          <Typography
                            variant="body1"
                            component="h5"
                            color="primary"
                            className="bold"
                            style={{ marginRight: '10px' }}>
                            ¿Quieres enviar una copia al cliente por SMS?
                          </Typography>

                          <ButtonLink
                            onClick={e => {
                              e.preventDefault()
                              setIsShowPreviewSmsMessage(true)
                            }}>
                            Previsualizar SMS
                          </ButtonLink>
                        </Box>

                        <Field
                          name="sendSmsCopy"
                          validate={v => v === undefined && 'Campo requerido'}
                          value={values.sendSmsCopy}>
                          {() => (
                            <Box display="flex" mt="10px">
                              <ButtonsSelector
                                dataHook="fat-sms.buttons"
                                items={smsOptionsButtons}
                                onSelect={selectedValue => {
                                  setFieldValue('sendSmsCopy', selectedValue)
                                }}
                              />
                            </Box>
                          )}
                        </Field>
                      </Box>

                      <ModalActions>
                        <Button
                          type="submit"
                          onClick={handleSubmit}
                          disabled={
                            taxIsLoading || !values.reason || Object.keys(errors).length > 0
                          }>
                          Continuar
                        </Button>
                        <Button secondary onClick={() => onClose()}>
                          Cancelar
                        </Button>
                      </ModalActions>
                    </form>
                  )}
                />
              </>
            )}
            {step === 1 && (
              <>
                <Box mb="16px">
                  <Typography variant="body1" component="h5" color="primary" className="bold">
                    Resumen del abono
                  </Typography>

                  <ActionAdvice
                    className="m-y-24"
                    type="info"
                    message={`${formatFee(fatAmount)} por incidencia en la factura ${
                      data.invoiceId
                    }`}
                  />
                </Box>
                <Box mb="23px">
                  <Typography variant="body1" component="h5" color="primary" className="bold">
                    Motivos
                  </Typography>
                  <Box display="flex" mt="10px" mb="5px">
                    <AdjustBillDataColumn
                      title="Tipología del motivo:"
                      value={typificationData.reason.text}
                    />
                    <AdjustBillDataColumn title="Motivo:" value={typificationData.subreason.text} />
                  </Box>
                  <AdjustBillDataColumn title="Motivo Jira:" value={typificationData.reasonJira} />
                </Box>

                <Box mb="38px">
                  <Typography variant="body1" component="h5" color="primary" className="bold">
                    Envío de copia por SMS
                  </Typography>
                  <Box display="flex" mt="10px" mb="5px">
                    {smsOptionsButtons.find(option => option.selected)?.label || '-'}
                  </Box>
                </Box>

                <ModalActions>
                  {!isSaving && (
                    <Button type="submit" onClick={() => onSubmitFat()}>
                      Confirmar y abonar
                    </Button>
                  )}

                  {isSaving && <SpinnerButton disabled />}

                  <Button secondary onClick={() => setStep(0)} disabled={isSaving}>
                    Volver atrás
                  </Button>
                </ModalActions>
              </>
            )}
          </>
        )}
      </Modal>
    </>
  )
}

AdjustBillModal.propTypes = {
  data: PropTypes.shape({
    concepts: PropTypes.array.isRequired,
    total: PropTypes.number.isRequired,
    totalWithoutVat: PropTypes.number.isRequired,
    invoiceId: PropTypes.number.isRequired,
    subscriptionId: PropTypes.number.isRequired,
    msisdn: PropTypes.string.isRequired,
    customerId: PropTypes.number.isRequired,
    tariff: PropTypes.string.isRequired,
  }),
  show: PropTypes.bool,
  onClose: PropTypes.func,
}
