import PropTypes from 'prop-types'
import { Box, Typography } from '@material-ui/core'
import { TextStyles } from 'utils/text'

const ContainerTitle = ({ children }) => (
  <Box
    component="header"
    m="0px 0px 21px 0px"
    p="13px 0px"
    textAlign="left"
    mb="21px"
    height="19px">
    <Typography
      {...TextStyles.paragraphDark({
        className: 'regular',
      })}>
      {children}
    </Typography>
  </Box>
)
ContainerTitle.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ContainerTitle
