import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import { TextStyles } from 'utils/text'
import { MobileLineSummary } from './MobileLineSummary'

export function ExtraLineSummary({ formattedTariffName, lineType, isMultiSim, ...props }) {
  return (
    <>
      <Typography
        {...TextStyles.subtitle2Dark({
          className: 'm-y-16',
        })}
        data-hook={`${lineType}.title`}>
        {formattedTariffName}
      </Typography>
      <div className="card-border relative m-b-16">
        <MobileLineSummary {...props} lineType={lineType} isMultiSim={isMultiSim} />
      </div>
    </>
  )
}

ExtraLineSummary.propTypes = {
  extraMobileLineOffer: PropTypes.object,
  userName: PropTypes.string.isRequired,
  formattedTariffName: PropTypes.string.isRequired,
  lineType: PropTypes.string.isRequired,
  tax: PropTypes.any.isRequired,
  isCrossSell: PropTypes.bool,
  isMultiSim: PropTypes.bool,
}
