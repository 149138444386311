import * as Yup from 'yup/lib'
import { isWithinInterval, isValid, addHours } from 'date-fns'
import { isValidInterval } from 'utils/dates'
import { formatDateYYMMDD } from 'utils/formatting'
import { validateHasNumbers, mobileNumberRegex } from 'utils'
import { SIGNUP_TYPE_PORTABILITY } from 'services/global-constants'
import * as validations from 'modules/SharedSales/constants/validations'

import { terminalFilterSchema } from './TerminalsFilter.validation'
import { lineOwnerValidationSchema } from './LineOwner.validation'
import { LineSchema } from './Line.validation'

export const portabilityDateValidator = Yup.date().when('isPortabilityDate', {
  is: true,
  then: Yup.date()
    .test('ValidatePortaDate', validations.PORTABILITY_DATE_NOT_VALID, function checkPortaDate(
      value,
    ) {
      const { startDay, endDay, holidays } = this.options.context.portaDates || {}

      return (
        isValid(value) &&
        isValidInterval(startDay, endDay) &&
        isWithinInterval(addHours(value, 1), {
          start: new Date(startDay),
          end: new Date(endDay),
        }) &&
        (!holidays || !holidays.includes(formatDateYYMMDD(value)))
      )
    })
    .required(validations.REQUIRED_FIELD_TEXT),
  otherwise: Yup.date().nullable(),
})

export const mobileLineSchema = (isElFijo, isPosSale, disableOfferEdit, isMobileOnly) => ({
  ...terminalFilterSchema(isPosSale, isMobileOnly, disableOfferEdit),
  ...lineOwnerValidationSchema,
  ...LineSchema({ validation: mobileNumberRegex, isElFijo, disableOfferEdit, isMobileOnly }),
  portabilityType: Yup.string().when('signUpType', {
    is: SIGNUP_TYPE_PORTABILITY,
    then: isElFijo ? Yup.string() : Yup.string().required(validations.REQUIRED_FIELD_TEXT),
    otherwise: Yup.string(),
  }),
  isPortabilityDate: Yup.boolean(),
  portabilityDate: portabilityDateValidator,
  desiredNumber: Yup.string().test(
    'desiredNumber',
    'Solamente puede contener 4 números',
    value => !value || (validateHasNumbers(value) && value.length === 4),
  ),
  isNewTerminal: Yup.string().when('$isMultiSim', {
    is: false,
    then: Yup.string().required(validations.REQUIRED_FIELD_TEXT),
    otherwise: Yup.string(),
  }),
})
