import { axiosJWT } from 'modules/axios'

import { APP_CONFIG } from 'services/app-config'

const BASE_URL_FETCH_ADD_AGILETV_ALLOWED = `${
  APP_CONFIG.sales_cluster
}/v1/customers/agile-tv-validation`
const BASE_URL_ADD_AGILETV = `${APP_CONFIG.sales_cluster}/v1/orders/agileTv`

export function fetchAddAgileTvAllowed(params) {
  return axiosJWT({
    method: 'GET',
    url: BASE_URL_FETCH_ADD_AGILETV_ALLOWED,
    params,
  }).then(resp => resp.data)
}

export function addAgileTv(data) {
  return axiosJWT({
    method: 'POST',
    url: BASE_URL_ADD_AGILETV,
    data,
  }).then(resp => resp.data)
}
