import { useState } from 'react'
import PropTypes from 'prop-types'
import {
  FormControl,
  FormControlLabel,
  Checkbox,
  Box,
  Divider,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import { ValidateEmail } from 'utils'
import { ComposedTextField } from 'components/ui'

const useStyles = makeStyles({
  'legal-texts': {
    marginTop: '21px',

    '& p': {
      fontWeight: 300,
      padding: '10px 0 12px',
    },
  },
})

const LegalRecording = ({ children, legalComponent, onInputChange, onToggleCheck }) => {
  const classes = useStyles()

  const [isChecked, setIsChecked] = useState(false)
  const [isValid, setIsValid] = useState(false)
  const [value, setValue] = useState('')

  const onInputChangeEvt = evt => {
    const val = evt.target.value

    const isValidValue = ValidateEmail(val) && isChecked

    setIsValid(isValidValue)
    setValue(val)

    onInputChange(isValidValue, val)
  }

  const onCheckboxChange = () => {
    onToggleCheck(!isChecked)

    setIsChecked(!isChecked)
    setValue('')
  }

  return (
    <div data-hook="legal-recording">
      <Box mb="20px">
        <Typography variant="h6" color="textSecondary">
          GRABACIÓN LEGAL
        </Typography>
        <div className={classes['legal-texts']}>{legalComponent}</div>
      </Box>

      <Divider />

      <Box display="flex" flexDirection="column" alignItems="flex-start" mt="20px">
        {children}
      </Box>

      <Box mb="13px">
        <Typography variant="h6" color="textSecondary">
          MAIL DE CONFIRMACIÓN
        </Typography>
        <Box mt="21px" mb="13px">
          <FormControl component="fieldset">
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  className="checkbox"
                  checked={isChecked}
                  onChange={onCheckboxChange}
                  value="false"
                />
              }
              label="Enviar comunicación al cliente con el resumen
            de la operación"
            />
          </FormControl>
        </Box>
        <Box mb="28px">
          <>
            <ComposedTextField
              label="Correo electrónico"
              value={value}
              onChange={evt => onInputChangeEvt(evt)}
              touched={isChecked}
              disabled={!isChecked}
              error={!isValid ? 'Por favor, introduzca un email válido' : ''}
              style={{ width: '100%', maxWidth: '380px' }}
            />
          </>
        </Box>
      </Box>
    </div>
  )
}

LegalRecording.propTypes = {
  onToggleCheck: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  legalComponent: PropTypes.element.isRequired,
  children: PropTypes.node,
}

export default LegalRecording
