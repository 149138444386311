import { all } from 'redux-saga/effects'

import {
  watchFindCategories,
  watchFindSubcategories,
  watchFindTicketTypes,
  watchCreateTicket,
} from './create.saga'

import { watchFindTickets } from './view.saga'

export function* watchTicketingSagas() {
  yield all([
    watchFindCategories(),
    watchFindSubcategories(),
    watchFindTicketTypes(),
    watchFindTickets(),
    watchCreateTicket(),
  ])
}
