import { constants } from './constants'

const checkCTCAOnFly = documentId => ({
  type: constants.CHECK_CT_CA_ONFLY_INIT,
  payload: {
    documentId,
  },
})

const checkCTCAOnFlySuccess = data => ({
  type: constants.CHECK_CT_CA_ONFLY_SUCCESS,
  payload: {
    ...data,
  },
})

const checkCTCAOnFlyFailed = error => ({
  type: constants.CHECK_CT_CA_ONFLY_FAILED,
  payload: error,
})

export const actions = {
  checkCTCAOnFly,
  checkCTCAOnFlySuccess,
  checkCTCAOnFlyFailed,
}
