import { omit } from 'lodash'
import { axiosCustomer, axiosJWT } from 'modules/axios'

import { CONFIG } from './environments'

const CACHE_ATC = {}

export const getAtcAuthToken = orderId => {
  const commonHeaders = omit(axiosCustomer.defaults.headers.common, [
    'Authorization',
    'X-Application-ID',
    'X-SFID',
  ])

  const cacheKey = btoa(JSON.stringify({ orderId }))

  if (CACHE_ATC[cacheKey]) {
    return CACHE_ATC[cacheKey]
  }

  CACHE_ATC[cacheKey] = axiosJWT({
    method: 'GET',
    headers: {
      ...commonHeaders,
      'X-Authorization': axiosCustomer.defaults.headers.common.Authorization,
    },
    url: `${CONFIG.api.atcAuthToken}/${orderId}`,
  })
    .then(resp => resp.data)
    .catch(err => {
      CACHE_ATC[cacheKey] = null
      throw err
    })

  return CACHE_ATC[cacheKey]
}
