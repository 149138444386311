import PropTypes from 'prop-types'

import { useSelector } from 'react-redux'

import { selectOfferSummary, selectMainTariffName } from './OfferSummaryContainer.selectors'
import { OfferSummary } from '../../components/Summary/OfferSummary'

export function OfferSummaryContainer({ sale, ...otherProps }) {
  const formattedTariffName = useSelector(state => selectMainTariffName(state, { sale }))
  const { isCrossSell, discountMessages, ...props } = useSelector(selectOfferSummary)

  return (
    <OfferSummary
      {...props}
      {...otherProps}
      formattedTariffName={formattedTariffName}
      discountMessages={discountMessages}
      isCrossSell={!!isCrossSell}
    />
  )
}

OfferSummaryContainer.propTypes = {
  sale: PropTypes.object.isRequired,
  onClickChangeOfferInfo: PropTypes.func,
}
