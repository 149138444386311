export const Shipping = point => ({
  selectedAddressType: point,
  otherAddress: {
    address: '',
    city: '',
    door: '',
    flat: '',
    number: '',
    otherInfo: '',
    province: '',
    zipCode: '',
  },
  deliveryPoint: {
    zipCode: '',
    selectedPoint: {},
  },
})
