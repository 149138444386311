import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  selectCustomerInfoIsLoading,
  selectCustomerInfoDocNumber,
  selectCustomerInfoError,
} from '../store/customerInfo.selectors'
import { fetchCustomerInformation } from '../store/customerInfo.actions'

export const useFetchCustomerIds = () => {
  const dispatch = useDispatch()

  const isLoading = useSelector(selectCustomerInfoIsLoading)
  const error = useSelector(selectCustomerInfoError)

  const customerInfoDocNumber = useSelector(selectCustomerInfoDocNumber)

  const getShouldFetch = useCallback(
    docNumber =>
      !isLoading && (!customerInfoDocNumber || customerInfoDocNumber !== docNumber || !!error),
    [customerInfoDocNumber, error, isLoading],
  )

  const fetchCustomerIds = (docType, docNumber, force = false) => {
    if (!force && !getShouldFetch(docNumber)) {
      return
    }

    dispatch(fetchCustomerInformation({ docType, docNumber }))
  }

  return {
    fetchCustomerIds,
    customerIdsError: error,
    customerIdsIsLoading: isLoading,
  }
}
