import { useFormikContext } from 'formik'
import { landlineRegex } from 'utils'
import { useIsCustomerPhoneValidation, useOperatorValidation } from '../../../hooks/Validations'

export function useOfferTypeValidation(lineType) {
  const {
    validateCustomer,
    errorCustomer,
    hasMsErrorCustomer,
    fieldCustomer,
    valueCustomer,
  } = useIsCustomerPhoneValidation(lineType)

  const { validateOperator, hasMsOperatorError, errorOperator } = useOperatorValidation(lineType)

  const { setStatus, status } = useFormikContext()

  async function validateOfferType(value, fromSignUp) {
    const customerValidation = await validateCustomer(value, landlineRegex, fromSignUp)
    const operatorValidation = await validateOperator(value, landlineRegex, fromSignUp)

    const validation = customerValidation || operatorValidation
    setStatus({ ...status, [`${lineType}.lineNumber`]: validation })

    return validation
  }

  return {
    validate: validateOfferType,
    error: errorCustomer || errorOperator,
    hasMsError: hasMsErrorCustomer || hasMsOperatorError,
    fieldName: fieldCustomer,
    fieldValue: valueCustomer,
  }
}
