/* eslint-disable import/no-cycle */
import PropTypes from 'prop-types'

import { Box, Typography, Icon } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import { ICONS } from 'utils/icons'
import { ButtonCard } from 'components/ui'

const useStyles = makeStyles(theme => ({
  'section-header': {
    backgroundColor: theme.palette.brand.light,
    boxSizing: 'border-box',

    '& > .title': {
      margin: '0em .5em',
    },

    '& > .children': {
      flexGrow: 1,
      lineHeight: '.7em',
    },

    '& > button:first-child': {
      backgroundColor: 'transparent',
      border: 'none',
      color: theme.palette.primary.main,
      cursor: 'pointer',
      outline: 'none',
      padding: 0,
    },

    '& button:not(:last-child)': {
      marginRight: '1em',
    },

    '& .button-disabled': {
      cursor: 'not-allowed',
      '&:hover': {
        color: theme.palette.bluePalette.main,
      },
    },
  },
}))

export const SectionHeader = ({ title, titleExt, children, actions, onBack }) => {
  const classes = useStyles()

  return (
    <Box
      component="header"
      display="flex"
      alignItems="center"
      height="100px"
      width="100%"
      p="10px 20px 0 20px"
      className={classes['section-header']}
      data-hook={title}>
      {onBack && (
        <button type="button" onClick={onBack}>
          <Icon className="icon" data-hook="icon-back">
            {ICONS.back}
          </Icon>
        </button>
      )}
      <Typography className="title bold" variant="h4" component="div">
        {titleExt || title}
      </Typography>
      <div className="children">{children}</div>
      <div className="actions">
        {actions &&
          actions.map(action => (
            <ButtonCard
              className={action.disabled ? 'button-disabled' : null}
              key={action.label}
              {...action}
            />
          ))}
      </div>
    </Box>
  )
}

SectionHeader.propTypes = {
  onBack: PropTypes.func,
  title: PropTypes.string,
  titleExt: PropTypes.any,
  children: PropTypes.node,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func,
      to: PropTypes.string,
      target: PropTypes.string,
      disabled: PropTypes.bool,
    }),
  ),
}
