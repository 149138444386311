import * as Yup from 'yup/lib'
import { landlineRegex, mobileNumberRegex, validateIUA } from 'utils'
import { SIGNUP_TYPE_NEWLINE, POS } from 'services/global-constants'
import { REQUIRED_FIELD_TEXT, ERROR_DATA_TEXT } from 'modules/SharedSales/constants'
import { lineOwnerValidationSchema } from './LineOwner.validation'
import { LineSchema } from './Line.validation'

export const mainLineSchema = (isElFijo, is2p, is2pPure) => ({
  ...lineOwnerValidationSchema,
  ...LineSchema({
    validation: is2p ? mobileNumberRegex : landlineRegex,
    is2p,
    isElFijo: false,
    isMobileOnly: false,
    disableOfferEdit: false,
    is2pPure,
  }),
  newFixedNumber: Yup.string().when(['signUpType'], {
    is: signUpType => signUpType === SIGNUP_TYPE_NEWLINE,
    then: is2p
      ? Yup.string()
          .matches(landlineRegex, ERROR_DATA_TEXT)
          .required(REQUIRED_FIELD_TEXT)
      : Yup.string().nullable(),
    otherwise: Yup.string(),
  }),
  isPortability: isElFijo ? Yup.string() : Yup.string().required(REQUIRED_FIELD_TEXT),

  isPortabilityFromFtth: Yup.string()
    .when(['$isAdsl', 'isPortability', 'signUpType'], {
      is: (isAdsl, isPortability, signUpType) => {
        const portability = signUpType || isPortability
        return (isAdsl && ['no', SIGNUP_TYPE_NEWLINE].includes(portability)) || isElFijo
      },
      then: () => Yup.string(),
    })
    .required(REQUIRED_FIELD_TEXT),
  selectedTechnology: Yup.string(),
  tariff: Yup.object().shape({
    id: Yup.string().required(REQUIRED_FIELD_TEXT),
  }),

  IUA: Yup.string()
    .trim()
    .test('IUA', ERROR_DATA_TEXT, value => !value || validateIUA(value)),
  routerNumber: Yup.string().when(['$isAdsl', '$channel'], {
    is: (isAdsl, channel) => isAdsl && channel === POS,
    then: () =>
      Yup.string()
        .trim()
        .min(10, REQUIRED_FIELD_TEXT)
        .required(REQUIRED_FIELD_TEXT),
  }),
})

export const LandlineSchema = (is2p, is2pPure) => ({
  landlineOffer: Yup.object().shape({
    ...mainLineSchema(false, is2p, is2pPure),
  }),
})
