import { useState } from 'react'
import PropTypes from 'prop-types'

import { get, uniqBy, filter, find } from 'lodash'
import { useSelector } from 'react-redux'

import { selectors as customer360SvcSelectors } from 'services/customer-360/selectors'
import { getTax } from 'services/taxes/selectors'

import { getBonusOptions } from '../selectors'

import { normalizePromo, isUpsellBundleAmount } from '../helpers'
import { BonusForm } from '../components/BonusForm'

const uniqueAndSort = (key, array) =>
  [...new Set(array.map(item => item[key]))].sort((a, b) => a - b)

export const BonusFormContainer = ({ onBonusSelect, goToNextStep, goToPreviousStep }) => {
  const billingAddress = useSelector(customer360SvcSelectors.getBillingAddress)
  const zipCode = get(billingAddress, 'postCode')

  const customerTaxRate = useSelector(state => getTax(state, zipCode))
  const bonusOptions = useSelector(getBonusOptions)

  const [dataAmount, setDataAmount] = useState('')
  const [duration, setDuration] = useState('')
  const [penalty, setPenalty] = useState('')

  const onSelectDataAmount = e => {
    setDataAmount(e.target.value)

    setDuration(!isUpsellBundleAmount(e.target.value) ? 1 : '')
    setPenalty(!isUpsellBundleAmount(e.target.value) ? 0 : '')
  }

  const onSelectDuration = e => {
    setDuration(e.target.value)
    setPenalty('')
  }

  const onSelectPenalty = e => {
    setPenalty(e.target.value)
  }

  const options = bonusOptions.map(normalizePromo)

  const filteredOptions = uniqBy(options, option => option.mobile_data_amount_mb).sort(
    (a, b) => a.mobile_data_amount_mb - b.mobile_data_amount_mb,
  )

  const penaltyOptions = uniqueAndSort(
    'penalty',
    filter(options, { mobile_data_amount_mb: dataAmount, duration }),
  ).map(Number)

  const currentPenalty = penaltyOptions.length === 1 ? penaltyOptions[0] : penalty

  const onSubmit = () => {
    const { id } = find(options, {
      mobile_data_amount_mb: dataAmount,
      penalty: Boolean(currentPenalty),
      duration,
    })

    // TODO: think about to send only bonus id instead of the whole bonus
    onBonusSelect(bonusOptions.find(bonus => bonus.id === id))
    goToNextStep()
  }

  const props = {
    customerTaxRate,
    filteredOptions,
    durationOptions: uniqueAndSort(
      'duration',
      filter(options, { mobile_data_amount_mb: dataAmount }),
    ),
    penaltyOptions,
    onSelectDataAmount,
    onSelectDuration,
    onSelectPenalty,
    dataAmount,
    duration,
    penalty: currentPenalty,
    onSubmit,
    goToPreviousStep,
  }

  return <BonusForm {...props} />
}

BonusFormContainer.propTypes = {
  onBonusSelect: PropTypes.func.isRequired,
  goToNextStep: PropTypes.func.isRequired,
  goToPreviousStep: PropTypes.func.isRequired,
}
