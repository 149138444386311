import { call, put, takeEvery } from 'redux-saga/effects'
import { logError, formatErrorMessage } from 'services/logging'
import { axiosFactory } from 'modules/axios'
import {
  fetchContentfulResourceSuccessAction,
  fetchContentfulResourceErrorAction,
  FETCH_CONTENTFUL_RESOURCE,
} from './contentful.actions'
import { fetchContentfulResource } from '../services'

function* fetchContentfulResourceSaga(action) {
  const { resource, params, alias } = action.payload
  try {
    const data = yield call(fetchContentfulResource, resource, params)
    yield put(fetchContentfulResourceSuccessAction(resource, data, alias))
  } catch (e) {
    yield call(logError, { e: new Error(formatErrorMessage(e)), action })
    const error = axiosFactory.buildError(e)
    yield put(fetchContentfulResourceErrorAction(resource, error, alias))
  }
}

export function* watchFetchContentfulResourceSaga() {
  yield takeEvery(FETCH_CONTENTFUL_RESOURCE, fetchContentfulResourceSaga)
}
