export const scoringActionTypes = {
  FETCH_CREDIT_SCORING: 'OVID/CREDIT_SCORING_V3/FETCH_CREDIT_SCORING',
  FETCH_CREDIT_SCORING_SUCCESS: 'OVID/CREDIT_SCORING_V3/FETCH_CREDIT_SCORING_SUCCESS',
  FETCH_CREDIT_SCORING_ERROR: 'OVID/CREDIT_SCORING_V3/FETCH_CREDIT_SCORING_ERROR',
  RESET_CREDIT_SCORING: 'OVID/CREDIT_SCORING/RESET_CREDIT_SCORING',
  FETCH_PRESCORING: 'OVID/CREDIT_SCORING/FETCH_PRESCORING',
  FETCH_PRESCORING_SUCCESS: 'OVID/CREDIT_SCORING/FETCH_PRESCORING_SUCCESS',
  FETCH_PRESCORING_ERROR: 'OVID/CREDIT_SCORING/FETCH_PRESCORING_ERROR',
}

export const fetchCreditScoringAction = payload => ({
  type: scoringActionTypes.FETCH_CREDIT_SCORING,
  payload,
})

export const fetchCreditScoringActionSuccess = payload => ({
  type: scoringActionTypes.FETCH_CREDIT_SCORING_SUCCESS,
  payload,
})

export const fetchCreditScoringActionError = payload => ({
  type: scoringActionTypes.FETCH_CREDIT_SCORING_ERROR,
  payload,
})

export const resetCreditScoring = () => ({
  type: scoringActionTypes.RESET_CREDIT_SCORING,
})

export const fetchPrescoringAction = payload => ({
  type: scoringActionTypes.FETCH_PRESCORING,
  payload,
})

export const fetchPrescoringActionSuccess = payload => ({
  type: scoringActionTypes.FETCH_PRESCORING_SUCCESS,
  payload,
})

export const fetchPrescoringActionError = payload => ({
  type: scoringActionTypes.FETCH_PRESCORING_ERROR,
  payload,
})
