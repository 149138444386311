import PropTypes from 'prop-types'
import styled from 'styled-components'
import { get } from 'lodash'
import { extendedLiteralDate } from 'utils/formatting'

export const DateHeaderItem = styled(props => {
  return <li className={props.className}>{extendedLiteralDate(get(props, 'date'))} </li>
})`
  color: ${props => get(props, 'theme.palette.global.gray_dark')};
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${props => get(props, 'theme.palette.global.gray_light')};
  font-weight: bold;
  padding-left: 20px;
  border-bottom: 1px solid ${props => get(props, 'theme.palette.global.gray_light_05')};
`

DateHeaderItem.propTypes = {
  date: PropTypes.string,
}
