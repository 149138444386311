import { call, put, takeEvery, select } from 'redux-saga/effects'
import { logError, formatErrorMessage } from 'services/logging'
import { axiosFactory } from 'modules/axios'
import { selectors as customerSelectors } from 'services/customer-360'

import { selectCustomerId } from 'modules/Auth'

import { alarmActions, alarmsActionTypes } from './alarms.actions'
import { findAlarms } from '../services'

export function* findAlarmsSaga({ payload: { msisdns } }) {
  try {
    const customerId = yield select(selectCustomerId)
    const documentType = yield select(customerSelectors.getIdentificationType)
    const documentId = yield select(customerSelectors.getIdentificationId)
    const alarmsData = yield call(findAlarms, customerId, msisdns, documentType, documentId)

    yield put(alarmActions.findAlarmsSuccessAction(alarmsData))
  } catch (e) {
    yield call(logError, { e: new Error(formatErrorMessage(e)), msisdns })
    const error = axiosFactory.buildError(e)
    yield put(alarmActions.findAlarmsErrorAction(error))
  }
}

export function* watchAlarmsSaga() {
  yield takeEvery(alarmsActionTypes.CUSTOMER_FIND_ALARMS, findAlarmsSaga)
}
