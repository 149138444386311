export const VISTA_AUTHORIZED_SFIDS = [
  'ainara.alvarez',
  'amamate',
  'anamartinr',
  'ATC00002',
  'ATC00453',
  'ATC00930',
  'ATC00993',
  'ATC01065',
  'ATC01067',
  'ATC01068',
  'ATC01120',
  'ATC01122',
  'ATC01124',
  'ATC01125',
  'ATC01195',
  'ATC01198',
  'ATC01200',
  'ATC01207',
  'ATC01208',
  'ATC01265',
  'bgirolromero',
  'bromerojimenez',
  'CS001003',
  'CS001004',
  'CS001005',
  'CS001006',
  'CS001008',
  'CS001010',
  'CS001012',
  'CS001014',
  'CS001015',
  'CS001017',
  'CS001018',
  'CS001019',
  'CS001023',
  'CS001026',
  'CS001027',
  'CS001028',
  'CS001029',
  'CS001030',
  'CS001032',
  'CS001033',
  'CS001035',
  'CS001036',
  'CS001038',
  'CS001039',
  'CS001041',
  'CS001043',
  'CS001048',
  'CS001049',
  'CS001050',
  'CS001052',
  'CS001053',
  'CS001054',
  'CS001057',
  'CS001060',
  'CS001063',
  'CS001064',
  'CS001067',
  'CS001083',
  'CS001101',
  'CS001110',
  'CS001114',
  'CS001115',
  'CS001118',
  'CS001124',
  'CS001126',
  'CS001161',
  'CS001173',
  'CS001194',
  'CS001195',
  'CS001196',
  'CS001204',
  'CS001206',
  'CS001213',
  'CS001214',
  'CS001216',
  'CS002062',
  'ctejada',
  'dperezurrieta',
  'drodriguez',
  'EM_GV_ATCY_ymor',
  'EM_GV_NEGY_mherce',
  'fgarciagarcia',
  'fizquierdo',
  'fpazdecastro',
  'fponte',
  'Garceran_RPORT',
  'gsancheza',
  'jacurillo',
  'jrdelrosario',
  'KNC000077',
  'KT000001_RBO',
  'KT0000027_RBO',
  'KT0000028_RBO',
  'KT000002_RBO',
  'KT0000030_RBO',
  'KT0000031_RBO',
  'KT0000032_RBO',
  'KT0000033_RBO',
  'KT0000034_RBO',
  'KT0000035_RBO',
  'KT0000036_RBO',
  'KT0000037_RBO',
  'KT0000038_RBO',
  'KT0000039_RBO',
  'KT000003_RBO',
  'KT0000040_RBO',
  'KT0000041_RBO',
  'KT0000042_RBO',
  'KT0000043_RBO',
  'KT0000044_RBO',
  'KT0000045_RBO',
  'KT0000046_RBO',
  'KT0000047_RBO',
  'KT0000048_RBO',
  'KT0000049_RBO',
  'KT000004_RBO',
  'KT0000050_RBO',
  'KT0000051_RBO',
  'KT0000052_RBO',
  'KT0000053_RBO',
  'KT0000054_RBO',
  'KT0000055_RBO',
  'KT0000056_RBO',
  'KT0000057_RBO',
  'KT0000058_RBO',
  'KT0000059_RBO',
  'KT000005_RBO',
  'KT0000060_RBO',
  'KT0000061_RBO',
  'KT0000062_RBO',
  'KT0000063_RBO',
  'KT0000064_RBO',
  'KT0000065_RBO',
  'KT0000066_RBO',
  'KT000006_RBO',
  'KT000007_RBO',
  'KT000008_RBO',
  'KT000009_RBO',
  'KT000010_RBO',
  'KT000011_RBO',
  'KT000012_RBO',
  'KT000013_RBO',
  'KT000014_RBO',
  'KT000015_RBO',
  'KT000016_RBO',
  'KT000017_RBO',
  'KT000018_RBO',
  'KT000019_RBO',
  'KT000020_RBO',
  'KT000021_RBO',
  'KT000022_RBO',
  'KT000023_RBO',
  'KT000024_RBO',
  'KT000025_RBO',
  'KT000026_RBO',
  'mdiazdec',
  'miguelizquierdo',
  'mjsanzsanz',
  'MKC01001',
  'MKC01002',
  'MKC01004',
  'MKC01006',
  'MKC01010',
  'MKC01012',
  'MKC01013',
  'MKC01014',
  'MKC01017',
  'MKC01018',
  'MKC01020',
  'MKC01021',
  'MKC01022',
  'MKC01023',
  'MKC01026',
  'MKC01027',
  'MKC01030',
  'MKC01032',
  'MKC01033',
  'MKC01034',
  'MKC01035',
  'MKC01038',
  'MKC01039',
  'MKC01040',
  'MKC01041',
  'MKC01042',
  'MKC01044',
  'MKC01045',
  'MKC01046',
  'MKC01047',
  'MKC01048',
  'MKC01049',
  'MKC01050',
  'MKC01051',
  'MKC01053',
  'MKC01055',
  'MKC01056',
  'MKC01057',
  'MKC01060',
  'MKC01061',
  'MKC01062',
  'MKC01064',
  'MKC01065',
  'MKC01066',
  'MKC01068',
  'MKC01072',
  'MKC01074',
  'MKC01075',
  'MKC01077',
  'MKC01080',
  'MKC01081',
  'MKC01082',
  'MKC01083',
  'MKC01084',
  'MKC01086',
  'MKC01088',
  'MKC01089',
  'MKC01090',
  'MKC01091',
  'MKC01092',
  'MKC01093',
  'MKC01095',
  'MKC01097',
  'MKC01098',
  'MKC01099',
  'MKC01101',
  'MKC01102',
  'MKC01107',
  'MKC01108',
  'MKC01114',
  'MKC01115',
  'MKC01116',
  'MKC01119',
  'MKC01120',
  'MKC01122',
  'MKC01125',
  'MKC01126',
  'MKC01128',
  'MKC01129',
  'MKC01132',
  'MKC01137',
  'MKC01139',
  'MKC01141',
  'MKC01143',
  'MKC01145',
  'MKC01147',
  'MKC01148',
  'MKC01149',
  'MKC01150',
  'MKC01152',
  'MKC01153',
  'MKC01155',
  'MKC01156',
  'MKC01158',
  'MKC01159',
  'MKC01160',
  'MKC01162',
  'MKC01164',
  'MKC01166',
  'MKC01170',
  'MKC01171',
  'MKC01173',
  'MKC01174',
  'MKC01176',
  'MKC01177',
  'MKC01178',
  'MKC01179',
  'MKC01180',
  'MKC01181',
  'MKC01184',
  'MKC01187',
  'MKC01189',
  'MKC01191',
  'MKC01196',
  'MKC01198',
  'MKC01199',
  'MKC01203',
  'MKC01206',
  'MKC01208',
  'MKC01210',
  'MKC01211',
  'MKC01213',
  'MKC01216',
  'MKC01218',
  'MKC01220',
  'MKC01221',
  'MKC01223',
  'MKC01225',
  'MKC01226',
  'MKC01231',
  'MKC01232',
  'MKC01233',
  'MKC01234',
  'MKC01237',
  'MKC01239',
  'MKC01241',
  'MKC01242',
  'MKC01244',
  'MKC01245',
  'MKC01247',
  'MKC01250',
  'MKC01256',
  'MKC01257',
  'MKC01259',
  'MKC01262',
  'MKC01264',
  'MKC01265',
  'MKC01269',
  'MKC01270',
  'MKC01271',
  'MKC01272',
  'MKC01273',
  'MKC01276',
  'MKC01279',
  'MKC01280',
  'MKC01283',
  'MKC01285',
  'MKC01286',
  'MKC01290',
  'MKC01294',
  'MKC01297',
  'MKC01298',
  'MKC01299',
  'MKC01300',
  'MKC01304',
  'MKC01305',
  'MKC01309',
  'MKC01314',
  'MKC01319',
  'MKC01320',
  'MKC01321',
  'MKC01327',
  'MKC01328',
  'MKC01329',
  'MKC01331',
  'MKC01332',
  'MKC01333',
  'MKC01338',
  'MKC01339',
  'MKC01344',
  'MKC01345',
  'MKC01346',
  'MKC01350',
  'MKC01352',
  'MKC01354',
  'MKC01366',
  'MKC01369',
  'MKC01370',
  'MKC01374',
  'MKC01375',
  'MKC01378',
  'MKC01379',
  'MKC01382',
  'MKC01383',
  'MKC01385',
  'MKC01386',
  'MKC01388',
  'MKC01389',
  'MKC01390',
  'MKC01391',
  'MKC01392',
  'MKC01393',
  'MKC01394',
  'MKC01395',
  'MKC01396',
  'MKC01397',
  'MKC01398',
  'MKC01399',
  'MKC01400',
  'MKC01401',
  'MKC01402',
  'MKC01403',
  'MKC01437',
  'MKC01438',
  'MKC01445',
  'MKC01446',
  'MKC01449',
  'MKC01453',
  'MKC01454',
  'MKC01457',
  'MKC01459',
  'MKC01462',
  'MKC01463',
  'MKC01467',
  'MKC01468',
  'MKC01469',
  'MKC01470',
  'MKC01471',
  'MKC01472',
  'MKC01473',
  'MKC01474',
  'MKC01475',
  'MKC01476',
  'MKC01477',
  'MKC01478',
  'MKC01479',
  'MKC01480',
  'MKC01481',
  'MKC01482',
  'MKC01483',
  'MKC01484',
  'MKC01489',
  'MKC01490',
  'MKC01491',
  'MKC01493',
  'MKC01499',
  'MKC01500',
  'MKC01501',
  'MKC01502',
  'MKC01503',
  'MKC01504',
  'MKC01505',
  'MKC01506',
  'MKC01507',
  'MKC01508',
  'MKC01511',
  'MKC01512',
  'MKC01513',
  'MKC01514',
  'MKC01515',
  'MKC01516',
  'MKC01517',
  'MKC01518',
  'MKC01519',
  'MKC01520',
  'MKC01521',
  'MKC01522',
  'MKC01523',
  'MKC01524',
  'MKC01525',
  'MKC01526',
  'MKC01527',
  'MKC01528',
  'MKC01529',
  'MKC01531',
  'MKC01533',
  'MKC01535',
  'MKC01536',
  'MKC01537',
  'MKC01538',
  'MKC01539',
  'MKC01550',
  'MKC01551',
  'MKC01552',
  'MKC01553',
  'MKC01554',
  'MKC01555',
  'MKC01556',
  'MKC01557',
  'MKC01558',
  'MKC01559',
  'MKC01560',
  'MKC01561',
  'MKC01562',
  'MKC01563',
  'MKC01564',
  'MKC01565',
  'MKC01566',
  'MKC01567',
  'MKC01568',
  'MKC01569',
  'MKC01570',
  'MKC01571',
  'MKC01572',
  'MKC01573',
  'MKC01574',
  'MKC01575',
  'MKC01576',
  'MKC01577',
  'MKC01578',
  'MKC01579',
  'MKC01580',
  'MKC01581',
  'MKC01582',
  'MKC01583',
  'MKC01584',
  'MKC01585',
  'MKC01586',
  'MKC01587',
  'MKC01588',
  'MKC01589',
  'MKC01590',
  'MKC01591',
  'MKC01592',
  'MKC01593',
  'MKC01594',
  'MKC01595',
  'MKC01596',
  'MKC01597',
  'MKC01598',
  'MKC01599',
  'MKC01600',
  'MKC01601',
  'MKC01602',
  'MKC01603',
  'MKC01604',
  'MKC01605',
  'MKC01606',
  'MKC01607',
  'MKC01608',
  'MKC01609',
  'MKC01610',
  'MKC01611',
  'MKC01612',
  'MKC01613',
  'MKC01614',
  'MKC01615',
  'MKC01619',
  'MKC01621',
  'MKC01622',
  'MKC01623',
  'MKC01626',
  'MKC01627',
  'MKC01628',
  'MKC01630',
  'MKC01632',
  'MKC01636',
  'MKC01641',
  'MKC01643',
  'MKC01649',
  'MKC01650',
  'MKC01651',
  'MKC01652',
  'MKC01653',
  'MKC01654',
  'MKC01655',
  'MKC01656',
  'MKC01657',
  'MKC01658',
  'MKC01659',
  'MKC01660',
  'MKC01661',
  'MKC01662',
  'MKC01663',
  'MKC01664',
  'MKC01665',
  'MKC01666',
  'MKC01667',
  'MKC01668',
  'MKC01669',
  'MKC01670',
  'MKC01671',
  'MKC01672',
  'MKC01673',
  'MKC01674',
  'MKC01675',
  'MKC01676',
  'MKC01677',
  'MKC01678',
  'MKC01679',
  'MKC01680',
  'MKC01682',
  'MKVA003_ATC',
  'MKVAL001_ATC',
  'MKVAL003_ATC',
  'MKVAL009_ATC',
  'MP200711',
  'MP390791',
  'mralvarez',
  'N2001002',
  'N2001004',
  'N2001005',
  'N2001007',
  'N2001008',
  'N2001011',
  'N2001012',
  'N2001015',
  'N2001016',
  'N2001026',
  'N2001029',
  'N2001030',
  'N2001031',
  'N2001032',
  'N2001039',
  'N2001040',
  'N2001041',
  'N2001043',
  'N2001045',
  'N2001046',
  'N2001047',
  'N2001049',
  'N2001050',
  'N2001051',
  'N2001053',
  'N2001054',
  'N2001055',
  'N2001058',
  'N2001059',
  'N2001060',
  'N2001061',
  'N2001064',
  'N2001065',
  'N2001066',
  'N2001068',
  'N2001069',
  'N2001070',
  'N2001071',
  'N2001072',
  'N2001073',
  'N2001074',
  'N2001077',
  'N2001091',
  'N2001099',
  'N2001104',
  'N2001108',
  'N2001109',
  'N2001122',
  'N2001135',
  'N2001136',
  'N2001138',
  'N2001139',
  'N2001156',
  'N2001231',
  'N2001233',
  'N2001258',
  'N2001235',
  'N2001238',
  'N2001239',
  'N2001240',
  'N2001241',
  'N2001242',
  'N2001243',
  'N2001244',
  'N2001245',
  'N2001246',
  'N2001247',
  'N2001248',
  'N2001249',
  'N2001250',
  'N2001360',
  'N2001365',
  'N2001370',
  'N2001372',
  'N2001376',
  'N2001383',
  'N2001392',
  'N2001396',
  'N2001402',
  'N2001576',
  'N2001577',
  'N2001578',
  'N2001579',
  'N2001580',
  'natalia',
  'ralcala',
  'rtcamacho',
  'SV001002',
  'SV001004',
  'SV001005',
  'SV001012',
  'SV001013',
  'SV001014',
  'SV001015',
  'SV001016',
  'SV001019',
  'SV001020',
  'SV001022',
  'SV001024',
  'SV001027',
  'SV001028',
  'SV001032',
  'SV001040',
  'SV001041',
  'SV001048',
  'SV001050',
  'SV001070',
  'SV001075',
  'SV001086',
  'SV001119',
  'SV001127',
  'SV001130',
  'SV001132',
  'SV001135',
  'SV001136',
  'SV001144',
  'SV001165',
  'SV001173',
  'SV001178',
  'SV001180',
  'SV001190',
  'SV001200',
  'SV001202',
  'SV001203',
  'SV001204',
  'SV001210',
  'SV001224',
  'SV001232',
  'SV001265',
  'SV001275',
  'SV001287',
  'SV001296',
  'SV001302',
  'SV001305',
  'SV001313',
  'SV001315',
  'SV001316',
  'SV001322',
  'SV001337',
  'SV001347',
  'SV001352',
  'SV001360',
  'SV001364',
  'SV001365',
  'SV001387',
  'SV001404',
  'SV001411',
  'SV001412',
  'SV001413',
  'SV001416',
  'SV001417',
  'SV001418',
  'SV001419',
  'SV001421',
  'SV001422',
  'SV001423',
  'SV001424',
  'SV001425',
  'SV001427',
  'SV001432',
  'SV001433',
  'SV001438',
  'SV001440',
  'SV001441',
  'SV001442',
  'SV001445',
  'SV001448',
  'SV001450',
  'SV001451',
  'SV001452',
  'SV001454',
  'SV001480',
  'SV001567',
  'SV001600',
  'SV001601',
  'SVFC00001',
  'SVFC00002',
  'SVFC00003',
  'SVFC00004',
  'SVFC00005',
  'SVFC00006',
  'SVFC00007',
  'SVFC00008',
  'SVFC00009',
  'SVFC00010',
  'SVFC00011',
  'SVFC00012',
  'SVFC00013',
  'SVFC00014',
  'SVFC00015',
  'SVFC00016',
  'SVFC00017',
  'SVFC00018',
  'SVFC00019',
  'SVFC00020',
  'SVFC00021',
  'SVFC00022',
  'SVFC00616',
  'SVFC00617',
  'SVFC00619',
  'SVFC00620',
  'SVFC00623',
  'SVFC00624',
  'SVFC00625',
  'SVFC00627',
  'SVFC00628',
  'SVFC00629',
  'SVFC00630',
  'SVFC00631',
  'SVFC00632',
  'SVFC00633',
  'SVFC00637',
  'SVFC00639',
  'SVFC00640',
  'SVFC00641',
  'SVFC00644',
  'SVFC00645',
  'SVFC00648',
  'SVFC00651',
  'SVFC00652',
  'SVR00292',
  'victormolina',
  'YC000619',
  'YC00084720',
  'YC00089175',
  'YC001011',
  'YC001018',
  'YC001034',
  'YC001046',
  'YC001067_RNAVY',
  'YC001068_RNAVY',
  'YC001069_RNAVY',
  'YC001071_RNAVY',
  'YC001072_RNAVY',
  'YC001075_RNAVY',
  'YC001077_RNAVY',
  'YC001079_RNAVY',
  'YC001081_RNAVY',
  'YC001082_RNAVY',
  'YC001083_RNAVY',
  'YC001085_RNAVY',
  'YC001086_RNAVY',
  'YC001088_RNAVY',
  'YC001090_RNAVY',
  'YC001100_RNAVY',
  'YC001104_RNAVY',
  'YC001105_RNAVY',
  'YC001106_RNAVY',
  'YC001107_RNAVY',
  'YC001133_RNAVY',
  'YC001135_RNAVY',
  'YC001136_RNAVY',
  'YC001137_RNAVY',
  'YC001138_RNAVY',
  'YC001139_RNAVY',
  'YC001171',
  'YC001189',
  'YC001206',
  'YC001212',
  'YC001214',
  'YC001215',
  'YC001217',
  'YC001236',
  'YC001261',
  'YC001269',
  'YC001280',
  'YC001329',
  'YC001368',
  'YC001376',
  'YC001428',
  'YC001431',
  'YC001434',
  'YC001478',
  'YC001495',
  'YC001497',
  'YC001505',
  'YC001520',
  'YC001525',
  'YC001601',
  'YC001613',
  'YC001614',
  'YC001632',
  'YC001677',
  'YC001720',
  'YC001754',
  'YC001781',
  'YC001786',
  'YC001860',
  'YC001875',
  'YC001878',
  'YC001911',
  'YC001976',
  'YC001986',
  'YC001994',
  'YC002051',
  'YC002053',
  'YC002079',
  'YC002091',
  'YC002118',
  'YC002136',
  'YC002140',
  'YC002145',
  'YC002157',
  'YC002159',
  'YC002160',
  'YC002164',
  'YC002174',
  'YC002187',
  'YC002205',
  'YC002206',
  'YC002228',
  'YC002229',
  'YC002240',
  'YC002255',
  'YC002256',
  'YC00230',
  'YC002300',
  'YC002306',
  'YC002311',
  'YC002312',
  'YC002317',
  'YC002335',
  'YC002416',
  'YC002471',
  'YC002488',
  'YC002500',
  'YC002516',
  'YC002537',
  'YC002554',
  'YC002559',
  'YC002586',
  'YC002641',
  'YC002671',
  'YC002738',
  'YC002744',
  'YC002782',
  'YC002785',
  'YC002805',
  'YC002809',
  'YC002813',
  'YC002819',
  'YC002840',
  'YC002847',
  'YC002862',
  'YC002891',
  'YC002895',
  'YC002916',
  'YC002918',
  'YC002929',
  'YC002934',
  'YC003027',
  'YC003031',
  'YC003035',
  'YC003042',
  'YC003047',
  'YC003068',
  'YC003069',
  'YC003071',
  'YC003079',
  'YC003089',
  'YC003098',
  'YC0030987',
  'YC003101',
  'YC003110',
  'YC003111',
  'YC003119',
  'YC003121',
  'YC003127',
  'YC003133',
  'YC003151',
  'YC003180',
  'YC003181',
  'YC003185',
  'YC003189',
  'YC003195',
  'YC003197',
  'YC003237',
  'YC003279',
  'YC003317',
  'YC003322',
  'YC003323',
  'YC003329',
  'YC003332',
  'YC003362',
  'YC003364',
  'YC003392',
  'YC003426',
  'YC003428',
  'YC003741',
  'YC003918',
  'YC003961',
  'YC003974',
  'YC003976',
  'YC003979',
  'YC003981',
  'YC003983',
  'YC003986',
  'YC003988',
  'YC003990',
  'YC003991',
  'YC003992',
  'YC003998',
  'YC004034',
  'YC004038',
  'YC004046',
  'YC004047',
  'YC004053',
  'YC004054',
  'YC004060',
  'YC004071',
  'YC004073',
  'YC004074',
  'YC004080',
  'YC004085',
  'YC004087',
  'YC004090',
  'YC004093',
  'YC004096',
  'YC004107',
  'YC004112',
  'YC004115',
  'YC004117',
  'YC004120',
  'YC004122',
  'YC004130',
  'YC004133',
  'YC004146',
  'YC004153',
  'YC004154',
  'YC004158',
  'YC004159',
  'YC004162',
  'YC004165',
  'YC004167',
  'YC004171',
  'YC004181',
  'YC004182',
  'YC004186',
  'YC004187',
  'YC004190',
  'YC004204',
  'YC004208',
  'YC004213 ',
  'YC004213',
  'YC004216',
  'YC004230',
  'YC004236',
  'YC004244',
  'YC004258',
  'YC004263',
  'YC004264',
  'YC004281',
  'YC004282',
  'YC004290',
  'YC004311',
  'YC004312',
  'YC004315',
  'YC004327',
  'YC004344',
  'YC004353',
  'YC004355',
  'YC004368',
  'YC004375',
  'YC004381',
  'YC004382',
  'YC004396',
  'YC004397',
  'YC004398',
  'YC004400',
  'YC004403',
  'YC004407',
  'YC004410',
  'YC004415',
  'YC004418',
  'YC004419',
  'YC004436',
  'YC004440',
  'YC004445',
  'YC004448',
  'YC004458',
  'YC004461',
  'YC004463',
  'YC004471',
  'YC004478',
  'YC004479',
  'YC004480',
  'YC004487',
  'YC004491',
  'YC004497',
  'YC004499',
  'YC004506',
  'YC004507',
  'YC004510',
  'YC004511',
  'YC004512',
  'YC004514',
  'YC004520',
  'YC004522',
  'YC004523',
  'YC004525',
  'YC004535',
  'YC004540',
  'YC004541',
  'YC004542',
  'YC004545',
  'YC004548',
  'YC004549',
  'YC004550',
  'YC004554',
  'YC004556',
  'YC004569',
  'YC004572',
  'YC004573',
  'YC004578',
  'YC004582',
  'YC004583',
  'YC004624',
  'YC004625',
  'YC004626',
  'YC004640',
  'YC004643',
  'YC004646',
  'YC004652',
  'YC004654',
  'YC004659',
  'YC004667',
  'YC004669',
  'YC004681',
  'YC004682',
  'YC004689',
  'YC004690',
  'YC004692',
  'YC004694',
  'YC004697',
  'YC004701',
  'YC004704',
  'YC004713',
  'YC004718',
  'YC004720',
  'YC004721',
  'YC004724',
  'YC004725',
  'YC004728',
  'YC004729',
  'YC004730',
  'YC004733',
  'YC004742',
  'YC004743',
  'YC004749',
  'YC004751',
  'YC004759',
  'YC004764',
  'YC004768',
  'YC004769',
  'YC004779',
  'YC004783',
  'YC004784',
  'YC004787',
  'YC004789',
  'YC004790',
  'YC004793',
  'YC004794',
  'YC004799',
  'YC004805',
  'YC004806',
  'YC004830',
  'YC004833',
  'YC004834',
  'YC004838',
  'YC004863',
  'YC004866',
  'YC004886',
  'YC004887',
  'YC004894',
  'YC004895',
  'YC004900',
  'YC004904',
  'YC004907',
  'YC004909',
  'YC004920',
  'YC004922',
  'YC004923',
  'YC004924',
  'YC004933',
  'YC004937',
  'YC004939',
  'YC004945',
  'YC004951',
  'YC004962',
  'YC004963',
  'YC004966',
  'YC004973',
  'YC004991',
  'YC004993',
  'YC004997',
  'YC004999',
  'YC005022',
  'YC005026',
  'YC005041',
  'YC005101',
  'YC005103',
  'YC005104',
  'YC005106',
  'YC005114',
  'YC005120',
  'YC005121',
  'YC005122',
  'YC005123',
  'YC005125',
  'YC005126',
  'YC005127',
  'YC005129',
  'YC005130',
  'YC005131',
  'YC005133',
  'YC005134',
  'YC005135',
  'YC005136',
  'YC005141',
  'YC006019',
  'YC006020',
  'YC006034',
  'YC006036',
  'YC006037',
  'YC006054',
  'YC006074',
  'YC006075',
  'YC006077',
  'YC006079',
  'YC006080',
  'YC006082',
  'YC006083',
  'YC006088',
  'YC006091',
  'YC006093',
  'YC006094',
  'YC006098',
  'YC006099',
  'YC006100',
  'YC006101',
  'YC006103',
  'YC006104',
  'YC006105',
  'YC006107',
  'YC006110',
  'YC006157',
  'YC006169',
  'YC006170',
  'YC006201',
  'YC006202',
  'YC006256',
  'YC006272',
  'YC006320',
  'YC006321',
  'YC006325',
  'YC006332',
  'YC006334',
  'YC006339',
  'YC006341',
  'YC006342',
  'YC006343',
  'YC006345',
  'YC006347',
  'YC006348',
  'YC006354',
  'YC006357_RNAVY',
  'YC006358_RNAVY',
  'YC006359_RNAVY',
  'YC006361_RNAVY',
  'YC006362_RNAVY',
  'YC006363_RNAVY',
  'YC006364_RNAVY',
  'YC006376',
  'YC006377',
  'YC006378',
  'YC006379',
  'YC006380',
  'YC006381',
  'YC006383',
  'YC006384',
  'YC006393',
  'YC006396',
  'YC006403',
  'YC006409',
  'YC006416',
  'YC006422',
  'YC006423',
  'YC006431',
  'YC006453',
  'YC006454',
  'YC006471',
  'YC006472',
  'YC006473',
  'YC006474',
  'YC006475',
  'YC006477',
  'YC006482',
  'YC006484',
  'YC006486',
  'YC006492',
  'YC006495',
  'YC006496',
  'YC006498',
  'YC006499',
  'YC006512',
  'YC006513',
  'YC006514',
  'YC006515',
  'YC006516',
  'YC006517',
  'YC006518',
  'YC006519',
  'YC006520',
  'YC006521',
  'YC006522',
  'YC006523',
  'YC006524',
  'YC006525',
  'YC006526',
  'YC006529',
  'YC006530',
  'YC006532',
  'YC006533',
  'YC006534',
  'YC006535',
  'YC006541',
  'YC006573',
  'YC006574',
  'YC006576',
  'YC006580',
  'YC006582',
  'YC006583',
  'YC006584',
  'YC006585',
  'YC006587',
  'YC006588',
  'YC006589',
  'YC006595',
  'YC006611',
  'YC006616',
  'YC006625',
  'YC006628',
  'YC006629',
  'YC006630',
  'YC006633',
  'YC006644',
  'YC006647',
  'YC006648',
  'YC006649',
  'YC006652',
  'YC006653',
  'YC006654',
  'YC006655',
  'YC006656',
  'YC006657',
  'YC006658',
  'YC006659',
  'YC006660',
  'YC006661',
  'YC006662',
  'YC006664',
  'YC006665',
  'YC006666',
  'YC006667',
  'YC006668',
  'YC006672',
  'YC006673',
  'YC006678',
  'YC006679',
  'YC006680',
  'YC006681',
  'YC006682',
  'YC006683',
  'YC006684',
  'YC006694',
  'YC006696',
  'YC006697',
  'YC006739',
  'YC006746',
  'YC006749',
  'YC006750',
  'YC006752',
  'YC006753',
  'YC006754',
  'YC006755',
  'YC006757',
  'YC006760',
  'YC006761',
  'YC006762',
  'YC006763',
  'YC006764',
  'YC006766',
  'YC006767',
  'YC006769',
  'YC006770',
  'YC006771',
  'YC006772',
  'YC006773',
  'YC006774',
  'YC006775',
  'YC006776',
  'YC006777',
  'YC006778',
  'YC006782',
  'YC006783',
  'YC006785',
  'YC006840',
  'YC006841',
  'YC006842',
  'YC006938',
  'YC006940',
  'YC006948',
  'YC006950',
  'YC006951',
  'YC006952',
  'YC007027',
  'YC007028',
  'YC007029',
  'YC007040',
  'YC007052',
  'YC007092',
  'YC007093',
  'YC007094',
  'YC007096',
  'YC007097',
  'YC007098',
  'YC007099',
  'YC007100',
  'YC007101',
  'YC007102',
  'YC007106',
  'YC0083683',
  'YC0084338',
  'YC0084705',
  'YC0084707',
  'YC0084725',
  'YC0086243',
  'YC0086269',
  'YC0087785',
  'YC0089172',
  'YC00TEST_RPA',
  'YC010050',
  'YC010051',
  'YC010052',
  'YC010053',
  'YC010054',
  'YC010055',
  'YC010056',
  'YC010057',
  'YC010058',
  'YC010059',
  'YC010060',
  'YC010061',
  'YC010062',
  'YC010063',
  'YC010064',
  'YC010065',
  'YCN01001',
  'YCN01003',
  'YCN01011',
  'YCN01012',
  'YCN01017',
  'YCN01022',
  'YCN01023',
  'YCN01028',
  'YCN01057',
  'YCN01078',
  'YCN01079',
  'YOIGO_USER_2',
  'YYC01001',
]

export const VISTA_AUTHORIZED_SUFIX_SFID = [
  '_ACC',
  '_CA',
  '_ENER',
  '_FBAL',
  '_FBAN',
  '_FBOF',
  '_FC25',
  '_FCA',
  '_FCAN',
  '_FDAT',
  '_FDIS',
  '_FF3P',
  '_FFCI',
  '_FFLA',
  '_FFPC',
  '_FIN2',
  '_FIRR',
  '_FLAB',
  '_FMA1',
  '_FMAS',
  '_FQ25',
  '_FREC',
  '_FRTy',
  '_FTER',
  '_FTRM',
  '_FTVA',
  '_FTVB',
  '_FTYC',
  '_FUP1',
  '_FUP2',
  '_FUPS',
  '_FUPS2',
  '_FVEN',
  '_OU',
  '_PREV',
  '_RBO',
  '_RBAJA',
  '_RBAJA2',
  '_RBANG',
  '_RPORT',
  '_RPORT2',
  '_STVA',
  '_UPS',
  '_FWPP',
  '_F3UP',
  '_FFAP',
  '_RET',
]

export const VISTA_AUTHORIZED_PREFIX_SFID = [
  'AVERIAS_KNT',
  'BL',
  'BNN',
  'BO',
  'BX',
  'CKN',
  'CNN',
  'CON',
  'EBN',
  'ESN',
  'EVC',
  'EY',
  'GCN',
  'GMN',
  'GS',
  'GSS',
  'GSS_AVE',
  'KCN',
  'KIN',
  'KN_ATC',
  'KN_AVE',
  'KNC',
  'KNN',
  'LW',
  'LY',
  'MAN',
  'MB',
  'MCN',
  'MIN',
  'MK_ATC',
  'MK_AVE',
  'MK_NEG',
  'MKC',
  'MKCN',
  'MKDX',
  'MME',
  'MP',
  'N200',
  'PF',
  'SRV',
  'SRV_APN',
  'SRV_NEG',
  'SRVAPN',
  'SVFC',
  'VA',
  'VCN',
  'VNC',
  'VON',
  'ZEC',
  'ZE_REC',
]

export const VISTA_AUTHORIZED_PREFIX_SUFIX_SFID = [
  { startBy: 'PH', endBy: '_FUP6' },
  { startBy: 'MCN', endBy: '_FCA' },
  { startBy: 'KIN', endBy: '_CA' },
  { startBy: 'MIN', endBy: '_IN' },
  { startBy: 'VON', endBy: '_CA' },
  { startBy: 'CON', endBy: '_OU' },
  { startBy: 'GCN', endBy: '_CA' },
  { startBy: 'GMN', endBy: '_OU' },
  { startBy: 'KCN', endBy: '_CA' },
  { startBy: 'KNN', endBy: '_OU' },
]
