import PropTypes from 'prop-types'

import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
  'card-row': {
    marginTop: ({ subscript }) => (subscript ? '-6px' : '0'),
    marginBottom: ({ marginBottom }) => marginBottom || '13px',

    '& dd, & dt': ({ subscript }) =>
      subscript && {
        fontSize: '14px',
        fontWeight: '300',
      },
  },
})
const CardRow = ({ subscript, children, marginBottom, ...props }) => {
  const classes = useStyles({ subscript, marginBottom })

  return (
    <Box component="dl" className={classes['card-row']} {...props}>
      {children}
    </Box>
  )
}

CardRow.propTypes = {
  subscript: PropTypes.bool,
  children: PropTypes.node,
  marginBottom: PropTypes.string,
}

export default CardRow
