import { APP_CONFIG } from 'services/app-config'
import { axiosJWT } from 'modules/axios'

const url = `${APP_CONFIG.sales_cluster}/v1/tpv/iframeUrl`

export const getPaymentIframeUrl = amount =>
  axiosJWT({
    method: 'GET',
    params: {
      amount,
      // urlToRedirect: 'http://localhost:3000', // used for testing in local
      urlCSS: `${APP_CONFIG.siteRoot}/tpv.css`,
    },
    url,
  }).then(({ data }) => data.url)
