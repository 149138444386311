import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'

import { Typography } from '@material-ui/core'

import { TextStyles } from 'utils/text'

const useStyles = makeStyles({
  'sidebar-text': {
    marginBottom: '14px',
    lineHeight: 1.25,
  },
})

const TextSidebar = ({ dataHook, children = '' }) => {
  const classes = useStyles()

  return (
    <Typography
      {...TextStyles.dataDark({
        className: classes['sidebar-text'],
      })}
      data-hook={dataHook}>
      {children}
    </Typography>
  )
}

TextSidebar.propTypes = {
  children: PropTypes.any,
  dataHook: PropTypes.string,
}

TextSidebar.defaultProps = {
  children: '',
}

export default TextSidebar
