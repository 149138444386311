import { get, chain } from 'lodash'
import { createSelector } from 'reselect'
import { getOrdersByIds } from './helpers'

export const selectProductOrders = state => get(state, 'productOrders')

export const selectOrder = createSelector(
  selectProductOrders,
  productOrders => get(productOrders, 'data'),
)

export const selectIsLoading = createSelector(
  selectProductOrders,
  productOrders => get(productOrders, 'loading'),
)

export const selectErrorMessage = createSelector(
  selectProductOrders,
  productOrders => get(productOrders, 'error.message'),
)

export const selectOrders = createSelector(
  selectProductOrders,
  productOrders => get(productOrders, 'orders', []),
)

export const selectOrdersByProductId = productId => {
  return createSelector(
    [selectOrders, selectProductOrders],
    (orders, productOrders) => {
      const ids = get(productOrders, `productOrders.${productId}`, [])
      return getOrdersByIds(orders, ids)
    },
  )
}

export const selectOrderBillingAddressByProductId = productId => {
  const order = selectOrdersByProductId(productId)

  return chain(order)
  .get('contactMedium')
  .find({ type: 'BillingAddress' })
  .get('medium')
  .value()
}

export const selectIsLoadingByProductId = (state, productId) =>
  get(state, `productOrders.loadingByProductId.${productId}`, false)

export const selectHasErrorByProductId = (state, productId) =>
  get(state, `productOrders.errorByProductId.${productId}`, false)

export const selectErrorCodeByProductId = (state, productId) =>
  get(state, `productOrders.errorByProductId[${productId}].code`, null)

export const selectErrorMsgByProductId = (state, productId) =>
  get(state, `productOrders.errorByProductId[${productId}].message`, null)

export const selectOrderTerritoryOwner = state => {
  const order = selectOrder(state)
  return chain(order)
    .get('customer.contactMedium')
    .find({ type: 'InstallationAddressFixedLine' })
    .get('medium.characteristic')
    .find({ name: 'territoryOwner' })
    .get('value')
    .value()
}

export const selectContractId = createSelector(
  selectOrder,
  order => get(order, 'contractId'),
)
