import { filter, isEmpty } from 'lodash'
import {
  addMilliseconds,
  format as formatFns,
  isValid,
  differenceInYears,
  differenceInMonths,
  startOfDay,
  addYears,
  addMonths,
  differenceInDays,
} from 'date-fns'
import { es } from 'date-fns/locale'

function fromMsToBaseDate(ms) {
  const dateBase = new Date(2000, 0, 1)
  const dateRef = new Date(dateBase.setFullYear(2000))

  return addMilliseconds(dateRef, ms)
}

/**
 * Return a string of a duration formatted using the same format string pattern as date-fns
 *
 * IMPORTANT: At the moment only works for duration less than a day.
 * - ms = milliseconds
 * - format = format as expected with the date-fns format method, but only works for formats that work at the hour level
 * and less.
 *
 * - options = options as expected with the date-fns format method
 *
 * Strict use to get good results:
 *
 *  - Use HH not hh, because HH gives you the correct 0-24 hours duration.
 *
 *  Ex: format -> 'HH:mm:ss:SSSS' for a duration of 10ms is 00:00:00:0100
 *
 * All the formatting not strictly related to dates, and time like era, etc are not supported al all!! Beware!!
 *
 */
export function formatDistanceFromMs(ms, format, options) {
  const baseDate = fromMsToBaseDate(ms)
  // const dateFormat = addMilliseconds(baseDate, -60000) // bug for year 0 that makes the date to have always 1 minute when formatting

  return formatFns(baseDate, format, options)
}

// format hours:minutes:seconds. Only works for durations less than 24 hours ; locale=ES
export function formatDistanceFromMs_HH_mm_ss_ES(ms) {
  return formatDistanceFromMs(ms, 'HH:mm:ss', { locale: es })
}

function pluralES(num) {
  return num !== 1 ? 's' : ''
}

/**
 * format a duration with words. Only works for durations less than 24 hours
 *
 */
export function formatDistanceFromMs_HsMinsSecs_ES(ms) {
  const baseDate = fromMsToBaseDate(ms)

  const { hours, minutes, seconds } = {
    hours: baseDate.getHours(),
    minutes: baseDate.getMinutes(),
    seconds: baseDate.getSeconds(),
  }

  const timePieces = [
    `${hours} hora${pluralES(hours)}`,
    `${minutes} minuto${pluralES(minutes)}`,
    `${seconds} segundo${pluralES(seconds)}`,
  ]

  return filter(timePieces, ele => !isEmpty(ele)).join(' ')
}

/**
 * format a duration with words, only the hours, minutes part. Only works for durations less than 24 hours
 *
 * If the duration is 0 ms then returns '0 minutos'
 * If duration is less than a minute but not 0 then returns '1 minute'
 * In any other case returns the hours and minutes part ignoring the seconds part.
 */
export function formatDistanceFromMs_HsMins_ES(ms) {
  if (ms === 0) {
    return '0 minutos'
  }

  if (ms < 60000) {
    // less than a minute
    return '1 minuto'
  }

  const baseDate = fromMsToBaseDate(ms)

  const { hours, minutes } = {
    hours: baseDate.getHours(),
    minutes: baseDate.getMinutes(),
  }

  const timePieces = [
    hours ? `${hours} hora${pluralES(hours)}` : '',
    minutes ? `${minutes} minuto${pluralES(minutes)}` : '',
  ]

  const durationStr = filter(timePieces, ele => !isEmpty(ele)).join(' ')

  return isEmpty(durationStr) ? '0 minutos' : durationStr
}

export function getDurationFromRange(endDate, startDate) {
  if (isValid(endDate) && isValid(startDate)) {
    let date = startOfDay(startDate)
    const years = differenceInYears(endDate, date)

    date = addYears(date, years)
    const months = differenceInMonths(endDate, date)

    date = addMonths(date, months)
    const days = differenceInDays(endDate, date)

    return {
      years,
      months,
      days,
    }
  }

  return null
}
