import axios from 'axios'
import { axiosJWT } from 'modules/axios'
import { APP_CONFIG } from 'services/app-config'

import { filter, get } from 'lodash'
import { CHANNEL, SEGMENT, CHANNEL_MOVE, CHANNEL_SALES, BRAND } from '../constants'

const basicToken = 'zWQwVum74lB7p6Mg6QIjVySFziA9fZPe'

export function findCoverage(gescal37, coverageV1) {
  if (coverageV1) {
    return axiosJWT({
      method: 'GET',
      url: `${
        APP_CONFIG.sales_cluster
      }/v1/coverage/units?gescal=${gescal37}&brand=${BRAND}&segment=${SEGMENT}&channel=${CHANNEL}`,
    }).then(resp => resp.data.coverage)
  }

  return axios({
    method: 'GET',
    headers: {
      Authorization: `${basicToken}`,
    },
    url: `${
      APP_CONFIG.coverage_host
    }/Cobertura/Get?gescal37=${gescal37}&brand=${BRAND}&segment=${SEGMENT}&channel=${CHANNEL}`,
  }).then(resp => filter(get(resp, 'data.feasibilities'), item => item.technology === 'FTTH'))
}

function mapToGetCoverageToken(token, customerData, externalAddress, sfid, isOvid) {
  const externalAddressParam = {
    Address: externalAddress,
    InputFocus: 'true',
    Gescal17: '',
  }

  const data = {
    channel: !isOvid ? CHANNEL_MOVE : CHANNEL_SALES,
    brand: BRAND,
    css: 'css',
    ...customerData,
    sfId: sfid,
    externalAddress: JSON.stringify(externalAddressParam),
  }

  const urlTokenCoverage = `${APP_CONFIG.sales_cluster}/v1/coverage/generate-token`

  return {
    method: 'POST',
    url: urlTokenCoverage,
    data,
  }
}

/**
 *
 * @param {string} token customer or agent token
 * @param {object} data
 * @param {object} externalAddress
 * @param {string} sfid
 * @param {boolean} isOvid
 */
export function fetchCoverageToken(token, data, externalAddress, sfid, isOvid) {
  // TODO: refactor with customer token
  return axiosJWT(mapToGetCoverageToken(token, data, externalAddress, sfid, isOvid)).then(
    resp => resp.data,
  )
}
