import { useEffect } from 'react'
import { isEmpty, get } from 'lodash'
import { Formik, useFormikContext } from 'formik'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { getCoverageAction } from 'modules/Coverage'

import { Card, CardContent, Typography, Box } from '@material-ui/core'
import {
  LayoutComponent,
  FormikInput,
  Button,
  NotificationModal,
  SpinnerButton,
} from 'components/ui'

import { selectSfid } from 'modules/Auth/store/selectors'
import { useGetHoursOptions, useSendSalesLead, useGetOptionsSalesLead } from '../hooks'
import { SalesLeadSchema } from './SalesLead.validation'
import { salesLeadModel } from './SalesLead.model'

function SaveButton({ sfid }) {
  const { validateForm, setTouched, values, setValues, setFieldValue } = useFormikContext()

  const dispatch = useDispatch()

  const { hasMsError, sendSalesLead, setHasMsError, hasMsSuccess, setHasMsSuccess, isLoading } =
    useSendSalesLead(values)

  async function onSubmit() {
    const errors = await validateForm()
    setTouched(errors)

    if (isEmpty(errors)) {
      sendSalesLead()
    }
  }

  function onResetData() {
    setHasMsSuccess(false)
    setValues(salesLeadModel)
    dispatch(getCoverageAction({}))
    setFieldValue('sfId', sfid)
  }

  return (
    <div className="m-t-20">
      <NotificationModal
        isOpen={hasMsError}
        type="alert"
        src="/assets/error.svg"
        onClose={() => setHasMsError(false)}>
        <Typography variant="body1">
          Ha sucedido un error al intentar guardar los datos del cliente, por favor inténtalo de
          nuevo
        </Typography>
      </NotificationModal>
      <NotificationModal
        isOpen={hasMsSuccess}
        type="success"
        src="/assets/clap_clap.svg"
        onClose={onResetData}>
        <Typography variant="body1">Registro insertado correctamente</Typography>
      </NotificationModal>
      {!isLoading ? (
        <Button type="button" onClick={onSubmit} name="pageContinue">
          Confirmar
        </Button>
      ) : (
        <SpinnerButton disabled />
      )}
    </div>
  )
}

function ClientInfo() {
  const { values } = useFormikContext()
  const segment = get(values, 'segment')
  const [hoursOptions, shouldShowHours] = useGetHoursOptions(segment, 'contactHour')

  return (
    <>
      <Typography variant="subtitle1" color="secondary" className="bold m-t-20">
        DATOS DEL CLIENTE
      </Typography>
      <div className="flex flex-wrap align-center-between">
        <div className="flex-1 p-r-16">
          <FormikInput fullWidth name="name" label="Nombre" />
        </div>
        <div className="flex-1 p-r-16">
          <FormikInput fullWidth name="surname1" label="Primer Apellido" />
        </div>
        <div className="flex-1">
          <FormikInput fullWidth name="surname2" label="Segundo Apellido" />
        </div>
      </div>
      <div className="flex p-b-40">
        <div style={{ width: '34%' }} className="p-r-16">
          <FormikInput
            fullWidth
            name="telephoneNumber"
            maxLength="9"
            label="Teléfono de contacto"
          />
        </div>
        {shouldShowHours && (
          <div style={{ width: '34%' }} className="p-r-16">
            <FormikInput
              fullWidth
              name="contactHour"
              select
              label="Programación de contacto"
              options={hoursOptions}
            />
          </div>
        )}
      </div>
    </>
  )
}

function AgentInfo({ sfid }) {
  const {
    setFieldValue,
    values: { segment, product },
  } = useFormikContext()
  const [brandOptions, productOptions, segmentOptions] = useGetOptionsSalesLead(segment)

  useEffect(() => {
    if (sfid) {
      setFieldValue('sfId', sfid)
    }
  }, [sfid])

  useEffect(() => {
    if (segment === 'business' && product === 'familyhealth') {
      setFieldValue('product', '')
    }
  }, [segment])

  return (
    <>
      <Typography variant="subtitle1" color="secondary" className="bold">
        DATOS DE LA VENTA
      </Typography>
      <div className="flex flex-wrap align-center-between">
        {!sfid && (
          <div className="flex-1 p-r-16">
            <FormikInput fullWidth name="sfId" label="SFID" />
          </div>
        )}
        <div className="flex-1 p-r-16">
          <FormikInput fullWidth name="brand" select label="Marca" options={brandOptions} />
        </div>
        <div className="flex-1 p-r-16">
          <FormikInput fullWidth name="product" select label="Producto" options={productOptions} />
        </div>
        <div className="flex-1">
          <FormikInput fullWidth name="segment" select label="Segmento" options={segmentOptions} />
        </div>
      </div>
    </>
  )
}

export function SalesLead() {
  const sfid = useSelector(selectSfid)

  return (
    <LayoutComponent
      width="100%"
      fullWidth
      mainContent={
        <Formik
          validationSchema={SalesLeadSchema}
          initialValues={salesLeadModel}
          onSubmit={() => {}}>
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Box maxWidth="1260px" m="0 auto" p="40px 24px">
                <Card style={{ width: '100%' }}>
                  <CardContent>
                    <AgentInfo sfid={sfid} />
                    <ClientInfo />
                    <SaveButton sfid={sfid} />
                  </CardContent>
                </Card>
              </Box>
            </form>
          )}
        </Formik>
      }
    />
  )
}

AgentInfo.propTypes = {
  sfid: PropTypes.string,
}

SaveButton.propTypes = {
  sfid: PropTypes.string,
}
