import { APP_CONFIG } from 'services/app-config'
import { axiosJWT } from 'modules/axios'
import { CONFIG } from 'modules/typifications/environments'

import { MASCARE } from 'modules/typifications/constants'

const customerActivityURL = `${APP_CONFIG.care_cluster}/customer-reg/register`
const customerActivityVoiceCallsUrl = `${APP_CONFIG.care_cluster}/ms-mdtel-care/infoCalls`
const customerActivityMemosUrl = `${APP_CONFIG.care_cluster}/customer-reg/register/memo`
const customerActivityOrdersUrl = `${APP_CONFIG.care_cluster}/customer-reg/register/orders/history`
const customerActivityTypificationsUrl = `${APP_CONFIG.care_cluster}/customer-reg/register/typifications`
const customerActivityLogisticUrl = `${APP_CONFIG.care_cluster}/customer-reg/register/logistic`
const customerActivityCommunicationsUrl = `${APP_CONFIG.care_cluster}/customer-reg/register/communications`
const customerActivityCompensationsUrl = `${APP_CONFIG.care_cluster}/customer-reg/register/compensations`

const CACHE_CUSTOMER_ACTIVITY = {}
const CACHE_CUSTOMER_ACTIVITY_VOICE_CALLS = {}
const CACHE_CUSTOMER_MEMOS = {}
const CACHE_CUSTOMER_ORDERS = {}
const CACHE_CUSTOMER_TYPIFICATIONS = {}
const CACHE_CUSTOMER_IFRAME_TYPIFICATIONS = {}
const CACHE_CUSTOMER_LOGISTIC = {}
const CACHE_CUSTOMER_COMMUNICATIONS = {}
const CACHE_CUSTOMER_COMPENSATIONS = {}

export function getCustomerActivity(documentId, documentType, msisdns) {
  const cacheKey = btoa(JSON.stringify({ documentId, documentType, msisdns }))

  if (CACHE_CUSTOMER_ACTIVITY[cacheKey]) {
    return CACHE_CUSTOMER_ACTIVITY[cacheKey]
  }

  CACHE_CUSTOMER_ACTIVITY[cacheKey] = axiosJWT({
    method: 'GET',
    url: customerActivityURL,
    params: {
      documentId,
      documentType,
      msisdns,
    },
  })
    .then(resp => {
      return resp
    })
    .catch(err => {
      CACHE_CUSTOMER_ACTIVITY[cacheKey] = null
      throw err
    })

  return CACHE_CUSTOMER_ACTIVITY[cacheKey]
}

export function getCustomerMemos(documentId, documentType, msisdns) {
  const cacheKey = Buffer.from(JSON.stringify({ documentId, documentType, msisdns })).toString(
    'base64',
  )

  if (CACHE_CUSTOMER_MEMOS[cacheKey]) {
    return CACHE_CUSTOMER_MEMOS[cacheKey]
  }

  CACHE_CUSTOMER_MEMOS[cacheKey] = axiosJWT({
    method: 'GET',
    url: customerActivityMemosUrl,
    params: {
      documentId,
      documentType,
      msisdns,
    },
  })
    .then(resp => {
      return resp
    })
    .catch(err => {
      CACHE_CUSTOMER_MEMOS[cacheKey] = null
      throw err
    })

  return CACHE_CUSTOMER_MEMOS[cacheKey]
}

export function getCustomerOrders(documentId) {
  const cacheKey = Buffer.from(JSON.stringify({ documentId })).toString('base64')

  if (CACHE_CUSTOMER_ORDERS[cacheKey]) {
    return CACHE_CUSTOMER_ORDERS[cacheKey]
  }

  CACHE_CUSTOMER_ORDERS[cacheKey] = axiosJWT({
    method: 'GET',
    url: customerActivityOrdersUrl,
    params: {
      documentId,
    },
  })
    .then(resp => {
      return resp
    })
    .catch(err => {
      CACHE_CUSTOMER_ORDERS[cacheKey] = null
      throw err
    })

  return CACHE_CUSTOMER_ORDERS[cacheKey]
}

export function getCustomerTypifications(msisdns) {
  const cacheKey = Buffer.from(JSON.stringify({ msisdns })).toString('base64')

  if (CACHE_CUSTOMER_TYPIFICATIONS[cacheKey]) {
    return CACHE_CUSTOMER_TYPIFICATIONS[cacheKey]
  }

  CACHE_CUSTOMER_TYPIFICATIONS[cacheKey] = axiosJWT({
    method: 'GET',
    url: customerActivityTypificationsUrl,
    params: {
      msisdns,
    },
  })
    .then(resp => {
      return resp
    })
    .catch(err => {
      CACHE_CUSTOMER_TYPIFICATIONS[cacheKey] = null
      throw err
    })

  return CACHE_CUSTOMER_TYPIFICATIONS[cacheKey]
}

export function getCustomerIframeTypifications(customerId) {
  const cacheKey = Buffer.from(JSON.stringify({ customerId })).toString('base64')

  if (CACHE_CUSTOMER_IFRAME_TYPIFICATIONS[cacheKey]) {
    return CACHE_CUSTOMER_IFRAME_TYPIFICATIONS[cacheKey]
  }

  CACHE_CUSTOMER_IFRAME_TYPIFICATIONS[cacheKey] = axiosJWT({
    method: 'GET',
    url: CONFIG.api.getRegistersTipis,
    params: {
      customerId,
      channel: MASCARE
    },
  })
    .then(resp => {
      return resp
    })
    .catch(err => {
      CACHE_CUSTOMER_IFRAME_TYPIFICATIONS[cacheKey] = null
      throw err
    })

  return CACHE_CUSTOMER_IFRAME_TYPIFICATIONS[cacheKey]
}

export function getCustomerLogistic(documentId, msisdns) {
  const cacheKey = Buffer.from(JSON.stringify({ documentId, msisdns })).toString('base64')

  if (CACHE_CUSTOMER_LOGISTIC[cacheKey]) {
    return CACHE_CUSTOMER_LOGISTIC[cacheKey]
  }

  CACHE_CUSTOMER_LOGISTIC[cacheKey] = axiosJWT({
    method: 'GET',
    url: customerActivityLogisticUrl,
    params: {
      documentId,
      msisdns,
    },
  })
    .then(resp => {
      return resp
    })
    .catch(err => {
      CACHE_CUSTOMER_LOGISTIC[cacheKey] = null
      throw err
    })

  return CACHE_CUSTOMER_LOGISTIC[cacheKey]
}

export function getCustomerCommunications(msisdns) {
  const cacheKey = Buffer.from(JSON.stringify({ msisdns })).toString('base64')

  if (CACHE_CUSTOMER_COMMUNICATIONS[cacheKey]) {
    return CACHE_CUSTOMER_COMMUNICATIONS[cacheKey]
  }

  CACHE_CUSTOMER_COMMUNICATIONS[cacheKey] = axiosJWT({
    method: 'GET',
    url: customerActivityCommunicationsUrl,
    params: {
      msisdns,
    },
  })
    .then(resp => {
      return resp
    })
    .catch(err => {
      CACHE_CUSTOMER_COMMUNICATIONS[cacheKey] = null
      throw err
    })

  return CACHE_CUSTOMER_COMMUNICATIONS[cacheKey]
}

export function getCustomerCompensations(msisdns) {
  const cacheKey = Buffer.from(JSON.stringify({ msisdns })).toString('base64')

  if (CACHE_CUSTOMER_COMPENSATIONS[cacheKey]) {
    return CACHE_CUSTOMER_COMPENSATIONS[cacheKey]
  }

  CACHE_CUSTOMER_COMPENSATIONS[cacheKey] = axiosJWT({
    method: 'GET',
    url: customerActivityCompensationsUrl,
    params: {
      msisdns,
    },
  })
    .then(resp => {
      return resp
    })
    .catch(err => {
      CACHE_CUSTOMER_COMPENSATIONS[cacheKey] = null
      throw err
    })

  return CACHE_CUSTOMER_COMPENSATIONS[cacheKey]
}

export function getCustomerActivityVoiceCalls(msisdn) {
  const cacheKey = btoa(JSON.stringify({ msisdn }))

  if (CACHE_CUSTOMER_ACTIVITY_VOICE_CALLS[cacheKey]) {
    return CACHE_CUSTOMER_ACTIVITY_VOICE_CALLS[cacheKey]
  }

  CACHE_CUSTOMER_ACTIVITY_VOICE_CALLS[cacheKey] = axiosJWT({
    method: 'GET',
    url: customerActivityVoiceCallsUrl,
    params: {
      msisdn,
    },
  })
    .then(resp => {
      return resp
    })
    .catch(() => {
      CACHE_CUSTOMER_ACTIVITY_VOICE_CALLS[cacheKey] = null
      return []
      // throw err
    })

  return CACHE_CUSTOMER_ACTIVITY_VOICE_CALLS[cacheKey]
}
