import { createSelector, createStructuredSelector } from 'reselect'
import { get, isNil, isArray, isEmpty } from 'lodash'
import { selectContentfulResource, selectContentfulResourceError } from 'modules/contentful'
import { getLoading, selectIframeTipis } from 'services/feature-flag/selectors'
import {
  selectAuthTypificationsSuccess,
  selectAuthTypificationsError,
  selectAuthTypificationsLoading,
} from './authTypifications.selectors'
import {
  selectRegistersTypificationsError,
  selectRegistersTypificationsLoading,
} from './registerTypifications.selectors'
import {
  selectInsertionTypificationsError,
  selectInsertionTypificationsErrorMessage,
  selectInsertionTypificationsLoading,
  selectInsertionTypificationsSuccess,
} from './insertionTypifications.selectors'
import { selectIsValidForm } from './formTypifications.selectors'
import {
  NOT_AVIABLE_ERROR,
  NOT_AUTHENTICATED_ERROR,
  NOT_REGISTERS_ERROR,
  NOT_DATA_CAMPAIGN,
  INSERTION_ERROR,
  INSERTION_SUCCESS,
} from '../../constants'

const selectDataTypifications = state => get(state, 'dataTypifications')

const selectDataTypificationsData = createSelector(selectDataTypifications, dataTypifications =>
  get(dataTypifications, 'data'),
)

export const selectDataTypificationsError = createSelector(
  selectDataTypifications,
  dataTypifications => get(dataTypifications, 'error'),
)

export const selectDataTypificationsLoading = createSelector(
  selectDataTypifications,
  dataTypifications => get(dataTypifications, 'loading'),
)

export const selectParamsLoginAgent = createSelector(
  selectDataTypificationsData,
  dataTypifications => {
    const sfid = get(dataTypifications, 'sfid')
    const uuid = get(dataTypifications, 'uuid')

    return sfid && uuid ? { sfid, uuid } : null
  },
)

export const selectIsSecurizedToken = createSelector(
  selectDataTypificationsData,
  dataTypifications => get(dataTypifications, 'isSecurizedToken'),
)

export const selectTokenIframe = createSelector(selectDataTypificationsData, dataTypifications =>
  get(dataTypifications, 'tokenIframe'),
)

export const selectAreaTypification = createSelector(
  selectDataTypificationsData,
  dataTypifications => get(dataTypifications, 'area'),
)

export const selectChannelTypification = createSelector(
  selectDataTypificationsData,
  dataTypifications => get(dataTypifications, 'channel'),
)

export const selectMsisdnTypification = createSelector(
  selectDataTypificationsData,
  dataTypifications => get(dataTypifications, 'msisdns'),
)

export const selectSfidTypification = createSelector(
  selectDataTypificationsData,
  dataTypifications => get(dataTypifications, 'sfid'),
)

export const selectCustomeridTypification = createSelector(
  selectDataTypificationsData,
  dataTypifications => get(dataTypifications, 'customer_id'),
)

export const selectCampaignByEmailTypification = createSelector(
  selectDataTypificationsData,
  dataTypifications => get(dataTypifications, 'campaignByEmail'))

export const selectConversationidTypification = createSelector(
  selectDataTypificationsData,
  dataTypifications => get(dataTypifications, 'conversation_id'),
)

export const selectBrandTypfication = createSelector(
  selectDataTypificationsData,
  dataTypifications => get(dataTypifications, 'brand'),
)

export const selectDataCallInsertion = createStructuredSelector({
  channel: selectChannelTypification,
  area: createSelector(
    [selectAreaTypification, selectCampaignByEmailTypification],
    (area, campaignByEmail) => campaignByEmail || area,
  ),
  sfid: selectSfidTypification,
  customerId: selectCustomeridTypification,
  conversationId: selectConversationidTypification,
  brand: selectBrandTypfication,
})

export const typificationFormSelectors = createStructuredSelector({
  msisdns: selectMsisdnTypification,
})

export const selectCampaignByEmailError = createSelector(
  selectDataTypifications,
  dataTypifications => get(dataTypifications, 'data.error'),
)

export const selectDataTypificationContainer = createStructuredSelector({
  area: selectAreaTypification || 'Default',
  authSuccess: selectAuthTypificationsSuccess,
  featureFlagIframe: selectIframeTipis,
  paramsLoginAgent: selectParamsLoginAgent,
  pageLoading:
    getLoading ||
    selectDataTypificationsLoading ||
    selectAuthTypificationsLoading ||
    selectRegistersTypificationsLoading ||
    selectInsertionTypificationsLoading,
  isSecurizedToken: selectIsSecurizedToken,
  campaignByEmail: selectCampaignByEmailTypification,
  customerId: selectCustomeridTypification,
  isValidForm: selectIsValidForm,
  channel: selectChannelTypification
})

export const selectTypeDataNotification = createSelector(
  selectAuthTypificationsError,
  selectDataTypificationsError,
  selectIframeTipis,
  selectRegistersTypificationsError,
  selectContentfulResource('typificationIframeCampaign'),
  selectContentfulResourceError('typificationIframeCampaign'),
  selectInsertionTypificationsError,
  selectInsertionTypificationsSuccess,
  (
    authError,
    dataError,
    featureFlagIframe,
    registersError,
    campaigns,
    campaignsError,
    insertionError,
    insertionSuccess,
  ) => {
    if (!featureFlagIframe) {
      return NOT_AVIABLE_ERROR
    }
    if (!isNil(authError)) {
      return NOT_AUTHENTICATED_ERROR
    }
    if (!isNil(dataError)) {
      return dataError
    }
    if (!isNil(registersError)) {
      return NOT_REGISTERS_ERROR
    }
    if ((isArray(campaigns) && isEmpty(campaigns)) || !isNil(campaignsError)) {
      return NOT_DATA_CAMPAIGN
    }
    if (!isNil(insertionError)) {
      return INSERTION_ERROR
    }
    if (insertionSuccess) {
      return INSERTION_SUCCESS
    }
    return null
  },
)

export const selectDataNotification = createStructuredSelector({
  typeDataNotification: selectTypeDataNotification,
  insertionErrorMessage: selectInsertionTypificationsErrorMessage,
})
