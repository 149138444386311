import PropTypes from 'prop-types'

import { ErrorMessage } from 'formik'
import { Typography, Box, Divider, CardContent } from '@material-ui/core'
import { TextStyles } from 'utils/text'

import { Card } from 'modules/ui'

import { FormError } from 'components/ui'
import { CoverageForm } from 'modules/Coverage/components/CoverageForm'
import { OfferType } from '../OfferTypeComponent'
import { Lead } from '../LeadComponent'

const fieldNames = {
  PORTABILITY: 'landlineOffer.lineNumber',
  CLIENT_EXISTS: 'account.clientExistsError',
  OPERATOR: 'landlineOffer.operator',
  DOCUMENT_ID: 'account.documentid',
  CLIENT_EXISTS_LAND: 'landlineOffer.clientExistsError',
}

export function OnlyFixedSale({
  installationAddress,
  isLoadingCoverage,
  coverageToken,
  onCoverageCheckFinished,
  resetCoverage,
  isTesting,
  isPortability,
  hasTechnology,
  handleIsPortability,
  isCartera,
  isPresale,
  isPos,
}) {
  return (
    <>
      <Card width="100%" className="p-x-24 p-y-32" style={{ marginBottom: '32px' }}>
        <CardContent>
          <Typography
            {...TextStyles.subtitle1Secondary({
              className: 'm-b-20',
            })}>
            DIRECCIÓN DE INSTALACIÓN
          </Typography>
          <CoverageForm
            name="landlineOffer.installationAddress.gescal"
            installationAddress={installationAddress}
            isLoading={isLoadingCoverage}
            coverageToken={coverageToken}
            onCoverageCheckFinished={onCoverageCheckFinished}
            reset={resetCoverage}
            isTesting={isTesting}
            allowAutocompleteEvent={isPos}
          />
          <ErrorMessage name="landlineOffer.installationAddress.gescal">
            {msg => (
              <FormError className="p-b-16 m-l-16" fullwidth>
                {msg}
              </FormError>
            )}
          </ErrorMessage>

          <Box mt="32px">
            <Divider />
          </Box>

          <div className="p-y-12">
            <OfferType
              fieldNames={fieldNames}
              isDisabled={!hasTechnology}
              lineType="landlineOffer"
              installationAddress={installationAddress}
              isPortability={isPortability}
              handleIsPortability={handleIsPortability}
            />
          </div>
          <Divider />
          <Lead
            fieldNames={fieldNames}
            isSegmentChooserDisabled={!hasTechnology}
            isCartera={isCartera}
            isPresale={isPresale}
            applyPrescoring={!isCartera && !isPresale}
          />
        </CardContent>
      </Card>
    </>
  )
}

OnlyFixedSale.propTypes = {
  installationAddress: PropTypes.object.isRequired,
  isLoadingCoverage: PropTypes.bool,
  coverageToken: PropTypes.string,
  onCoverageCheckFinished: PropTypes.func.isRequired,
  resetCoverage: PropTypes.func.isRequired,
  isTesting: PropTypes.string,
  isPortability: PropTypes.string.isRequired,
  hasTechnology: PropTypes.bool,
  isCartera: PropTypes.bool,
  handleIsPortability: PropTypes.func.isRequired,
  isPresale: PropTypes.bool,
  isPos: PropTypes.bool,
}
