import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getIn, useFormikContext } from 'formik'

import { fetchAuthIsCustomerNum } from 'modules/CustomerInfo/services/customerInfo.service'
import { CANT_CHECK_USER, CLIENT_EXISTS } from 'modules/SharedSales/constants'
import { selectIsElFijo, setRequestLoading } from '../../store'

export function useIsCustomerPhoneValidation(lineType) {
  const { values } = useFormikContext()
  const lineNumber = getIn(values, `${lineType}.lineNumber`)

  const [prevValue, setPrevValue] = useState(lineNumber)
  const [prevState, setPrevState] = useState()
  const [hasMsErrorCustomer, setMsError] = useState()

  const dispatch = useDispatch()

  const isElFijo = useSelector(selectIsElFijo)

  const phoneType = lineType.includes('land') || isElFijo ? 'fixed-number' : 'msisdn'

  function setAndReturnState(state, isMsError) {
    setMsError(isMsError)
    setPrevState(state)
    return state
  }

  async function validateWithMS(value) {
    setMsError(false)
    // this is to know when the continue button should have a loading state, is not done yet
    dispatch(setRequestLoading({ loading: true }))

    let state

    try {
      const { status } = await fetchAuthIsCustomerNum(phoneType, value).catch(err => {
        throw err
      })

      dispatch(setRequestLoading({ loading: false }))

      if (status === 'Active') {
        state = CLIENT_EXISTS
      }
      if (status === 'In flight' || status === 'Canceled-Inactive') {
        state = CLIENT_EXISTS
      }
      if (status === 'Failed') {
        state = CANT_CHECK_USER
      }
    } catch {
      dispatch(setRequestLoading({ loading: false }))

      state = CANT_CHECK_USER
    }

    return setAndReturnState(state, !!state)
  }

  const validateCustomer = async function validateCustomer(value, regex, fromSignUp) {
    const trimmedValue = value?.trim()
    setPrevValue(value)
    if (!fromSignUp) return prevState

    if (regex.test(trimmedValue)) {
      const validation = await validateWithMS(trimmedValue)
      return validation
    }
    return setAndReturnState(undefined)
  }

  return {
    validateCustomer,
    errorCustomer: prevState,
    hasMsErrorCustomer,
    valueCustomer: prevValue,
    fieldCustomer: 'Número',
  }
}
