import { get } from 'lodash'
import { createSelector } from 'reselect'

const selectCreditNote = state => get(state, 'credit')

export const selectIsLoading = createSelector(
  selectCreditNote,
  creditNote => get(creditNote, 'loading'),
)

export const selectError = createSelector(
  selectCreditNote,
  creditNote => get(creditNote, 'error'),
)
