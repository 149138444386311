import PropTypes from 'prop-types'
import { Button as MaterialButton } from '@material-ui/core'

import { Link, useLocation } from 'react-router-dom'
import { urlToRouterParams } from 'modules/Router'

// NOTE: padding is set to 9px because of the border
const Button = ({ className, to, secondary, text, dashed, margin, style, ...rest }) => {
  const location = useLocation()
  let variant = 'contained'
  if (secondary) {
    variant = 'outlined'
  }
  if (text) {
    variant = 'text'
  }

  return (
    <MaterialButton
      className={[
        ...(text ? ['MuiButton-text'] : []),
        ...(dashed ? ['MuiButton-dashed'] : []),
        ...(className ? [className] : []),
      ].join(' ')}
      component={to ? Link : 'button'}
      to={to ? urlToRouterParams(location, to) : null}
      variant={variant}
      color="primary"
      style={{
        margin,
        ...style,
      }}
      {...rest}
    />
  )
}

Button.defaultProps = {
  secondary: false,
  style: {},
}

Button.propTypes = {
  className: PropTypes.string,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  margin: PropTypes.string,
  secondary: PropTypes.bool,
  text: PropTypes.bool,
  dashed: PropTypes.bool,
  onClick: PropTypes.func,
  style: PropTypes.object,
}

export default Button
