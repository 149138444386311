import { get, head, isEmpty, isNil } from 'lodash'

export const MAX_CREDIT_AMOUNT = 100
export const MIN_CREDIT_AMOUNT = 0

const FREE_TAX_GROUPS = ['14', '33', '55']

export const creditNoteCategories = [
  { text: 'Negociación', value: 'Compens' },
  { text: 'Facturación incorrecta', value: 'Correct' },
  { text: 'Legal', value: 'Legal' },
]

export const creditNoteSubcategories = {
  Compens: [
    { text: 'Recobros', value: 'Collections' },
    { text: 'Fraude', value: 'Fraud' },
    { text: 'Compensación', value: 'Compensation' },
  ],
  Correct: [
    { text: 'Tarificación', value: 'Rating' },
    { text: 'Penalización', value: 'Erroneous-penalty-application' },
    { text: 'Cargo de llamada incorrecto', value: 'Incorrect-call-charge' },
    { text: 'Factura errónea', value: 'Erroneous-invoice' },
  ],
  Legal: [{ text: 'Arbitraje', value: 'Arbitrage' }, { text: 'Jurídico', value: 'Juridical' }],
}

export const translateCategory = category => {
  const selectedCategory = creditNoteCategories.filter(cat => cat.value === category)
  return get(head(selectedCategory), 'text')
}

export const translateSubcategory = (category, subcategory) => {
  const sub = creditNoteSubcategories[category].filter(cat => cat.value === subcategory)
  return get(head(sub), 'text')
}

export const getInvoiceMsisdns = subInvoiceWithRows =>
  subInvoiceWithRows.map(subInvoice => get(subInvoice, 'subInvoice.msisdn'))

export const getSubInvoicesObject = subInvoicesWithRows => {
  const filteredsubInvoicesWithRows = subInvoicesWithRows.map(subInvoice => {
    const rows = get(subInvoice, 'rows')
    return {
      'sub-invoice': get(subInvoice, 'subInvoice.id'),
      'invoice-rows': rows.map(row => ({
        'invoice-row': get(row, 'id'),
        amount: get(row, 'totalWithoutVat'),
        amountVat: get(row, 'total'),
      })),
    }
  })

  return filteredsubInvoicesWithRows
}

export const formatErrorMsg = msg => msg.slice(msg.indexOf('detail') + 10, msg.length - 8)

export const getSubInvoiceData = (subInvoicesWithRows, subInvoiceId) =>
  subInvoicesWithRows.filter(subInvoice => get(subInvoice, 'subInvoice.id') === subInvoiceId)

export const getGroupNameFromRow = row => {
  const billingItem = get(row, 'billingItem')
  const groupNameEs = get(billingItem, 'group.nameEs').toLowerCase()

  return `${groupNameEs} / ${get(billingItem, 'nameEs')}`
}

export const updateSubInvoiceRows = (subInvoice, rows) => {
  const subInvoiceRows = get(subInvoice, 'invoice-rows')
  const filteredRows = subInvoiceRows.map(invoiceRow => {
    const selectedRow = rows.filter(r => get(r, 'invoice-row') === get(invoiceRow, 'invoice-row'))
    if (!isEmpty(selectedRow)) {
      const updatedRow = head(selectedRow)
      return {
        ...invoiceRow,
        amount: get(updatedRow, 'amountWithoutVat') || get(updatedRow, 'amount'),
        amountVat: get(updatedRow, 'amount'),
      }
    }
    return null
  })

  return { ...subInvoice, 'invoice-rows': filteredRows.filter(row => !isNil(row)) }
}

export const getCreditSubInvoices = (subInvoices, rows) =>
  subInvoices.map(subInvoice => updateSubInvoiceRows(subInvoice, rows))

export const filterEmptySubInvoices = subInvoices =>
  subInvoices.filter(subInv => !isEmpty(get(subInv, 'invoice-rows')))

export const getRowObj = row => {
  const rowGroup = get(row, 'billingItem.group.code')
  return {
    'invoice-row': get(row, 'id'),
    amountWithoutVat: get(row, 'totalWithoutVat'),
    amount: get(row, 'total'),
    taxFree: FREE_TAX_GROUPS.includes(rowGroup),
  }
}

export const hasCustomAmounts = (subInvoiceRows, selectedRows) => {
  const rowsWithCustomAmount = subInvoiceRows.filter(row => {
    const selectedRow = head(
      selectedRows.filter(selected => get(selected, 'invoice-row') === get(row, 'id')),
    )
    return get(selectedRow, 'amount') !== get(row, 'totalWithoutVat')
  })

  return !isEmpty(rowsWithCustomAmount)
}
