import PropTypes from 'prop-types'
import { Component } from 'react'
import { isEmpty } from 'lodash'
import { Button, CircularProgress } from '@material-ui/core'
import { Modal } from '../Modal'
import { fillChangeTechnologyOffer } from '../ClientOffers/client-offer.helper'

class ModalMove extends Component {
  sendOrder = item => {
    const changeTechnologyJson = fillChangeTechnologyOffer(item, this.props.changeTechnologyJson)
    this.props.sendOrder(changeTechnologyJson)
  }

  render() {
    const {
      available,
      isSameProvince,
      phoneNumber,
      loading,
      loadingOrder,
      orderSent,
      sameTariff,
      loadingOffer,
      isNebaTO,
      isFeatNebaEnabled,
      isCommercialVulaTO,
    } = this.props
    let provinceButton = <div />
    const provinceProblem = !isSameProvince && !phoneNumber
    const checkTariff = !isEmpty(sameTariff)
    let loadingTariffs = true
    if (loadingOffer !== undefined) {
      loadingTariffs = loadingOffer
    }

    if (loading) {
      return <CircularProgress />
    }

    if (
      (provinceProblem || !available) &&
      !loading &&
      !loadingOrder &&
      !orderSent &&
      !loadingTariffs
    ) {
      provinceButton = (
        <div>
          <Modal
            open={(!isSameProvince && !phoneNumber) || !checkTariff}
            title="Aviso Importante!"
            message={
              !isSameProvince &&
              !phoneNumber &&
              !isCommercialVulaTO &&
              (!isNebaTO || (isNebaTO && isFeatNebaEnabled))
                ? 'Debe asignarse un nuevo número de teléfono al cliente'
                : 'La tarifa no se encuentra disponible en esta dirección'
            }
            cancelBtn="Salir"
          />
          {!isSameProvince &&
          !phoneNumber &&
          !isCommercialVulaTO &&
          (!isNebaTO || (isNebaTO && isFeatNebaEnabled)) ? (
            <Button variant="contained" onClick={this.props.onOrder} color="primary">
              Asignar nuevo número
            </Button>
          ) : (
            <div />
          )}
        </div>
      )
    } else {
      provinceButton = null
    }
    return provinceButton
  }
}

ModalMove.propTypes = {
  changeTechnologyJson: PropTypes.object,
  available: PropTypes.bool,
  isSameProvince: PropTypes.bool,
  phoneNumber: PropTypes.object,
  loading: PropTypes.bool,
  loadingOrder: PropTypes.bool,
  orderSent: PropTypes.bool,
  sameTariff: PropTypes.object,
  loadingOffer: PropTypes.bool,
  isNebaTO: PropTypes.bool,
  isFeatNebaEnabled: PropTypes.bool,
  isCommercialVulaTO: PropTypes.bool,
  onOrder: PropTypes.func,
  sendOrder: PropTypes.func,
}

export default ModalMove
