import PropTypes from 'prop-types'

import { Box, Icon } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import { ICONS } from 'utils/icons'

import Button from '../buttons/Button'

const useStyles = makeStyles(theme => ({
  'offer-footer': {
    borderRadius: '0 0 14px 14px',
    backgroundColor: theme.palette.global.gray_light,
    borderTop: '1px solid rgba(185, 194, 199, 0.5)',

    '& button': {
      flex: 1,
    },

    '& button + button:before': {
      content: "''",
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      background: 'rgba(185, 194, 199, 0.5)',
      width: '1px',
    },
  },
}))
function OfferFooter(props) {
  const classes = useStyles()

  return (
    <Box
      display="flex"
      flexDirection="row"
      overflow="hidden"
      className={['offer-footer', classes['offer-footer']].join(' ')}>
      {props.unsubscribed ? (
        <Button text onClick={props.onCancel}>
          <Icon className="icon">{ICONS.delete}</Icon>
          Cancelar baja
        </Button>
      ) : (
        <>
          <Button text disabled>
            <Icon className="icon">{ICONS.suspend}</Icon>
            Congelar
          </Button>
          {props.isConvergent ? (
            <Button text disabled>
              <Icon className="icon">{ICONS.split}</Icon>
              Romper
            </Button>
          ) : null}
          <Button
            text
            disabled={!props.allowUnsubscribe}
            onClick={props.onUnsubscribe}
            data-hook="unsubscribe-action">
            <Icon className="icon">{ICONS.delete}</Icon>
            Dar de baja
          </Button>
        </>
      )}
    </Box>
  )
}

OfferFooter.defaultProps = {
  unsubscribed: false,
  isConvergent: false,
  allowUnsubscribe: true,
}

OfferFooter.propTypes = {
  unsubscribed: PropTypes.bool.isRequired,
  isConvergent: PropTypes.bool.isRequired,
  allowUnsubscribe: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onUnsubscribe: PropTypes.func.isRequired,
}

export default OfferFooter
