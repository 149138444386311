export const GET_POND_INIT = 'GET_POND_INIT'
export const GET_POND = 'GET_POND'
export const GET_POND_SUCCESS = 'GET_POND_SUCCESS'
export const GET_POND_FAILED = 'GET_POND_FAILED'

export const getPondInitAction = orderId => ({
  type: GET_POND_INIT,
  payload: {
    orderId,
  },
})

export const getPondAction = () => ({
  type: GET_POND,
})

export const getPondSuccessAction = pond => ({
  type: GET_POND_SUCCESS,
  payload: {
    pond,
  },
})

export const getPondFailedAction = error => ({
  type: GET_POND_FAILED,
  payload: {
    error,
  },
})
