import { createSelector } from 'reselect'
import { get, isNil } from 'lodash'

const selectAuthTypifications = state => get(state, 'authTypifications')

const selectAuthTypificationsData = createSelector(selectAuthTypifications, authTypifications =>
  get(authTypifications, 'data'),
)

export const selectAuthTypificationsError = createSelector(
  selectAuthTypifications,
  authTypifications => get(authTypifications, 'error'),
)

export const selectAuthTypificationsLoading = createSelector(
  selectAuthTypifications,
  authTypifications => get(authTypifications, 'loading'),
)

export const selectAuthTypificationsAgentToken = createSelector(
  selectAuthTypificationsData,
  authTypificationsData => get(authTypificationsData, 'agentToken'),
)

export const selectAuthTypificationsUuid = createSelector(
  selectAuthTypificationsData,
  authTypificationsData => get(authTypificationsData, 'uuid'),
)

export const selectAuthTypificationsSfid = createSelector(
  selectAuthTypificationsData,
  authTypificationsData => get(authTypificationsData, 'sfid'),
)

export const selectAuthTypificationsSuccess = createSelector(
  selectAuthTypificationsData,
  authTypifications => !isNil(get(authTypifications, 'accessToken')),
)
