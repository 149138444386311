import { Component } from 'react'
import PropTypes from 'prop-types'
import { get, find, isEqual } from 'lodash'
import { Grid, Box } from '@material-ui/core'
import Close from '@material-ui/icons/Close'
import { ButtonLink } from 'components/ui'
import { Card, CardRow, CardRowValue, SectionTitle, SectionTitleGrid } from 'components'
import LineInformation from './LineInformation'
import { LegalRecording } from '../LegalRecording'
import { CancellationResult } from '../CancellationResult'
import { CallReasons } from '../CallReasons'
import { WizardRequest } from '../WizardRequest'
import CancelLegalRecording from '../CancelLegalRecording/CancelLegalRecording'
import RetainLegalRecording from '../RetainLegalRecording/RetainLegalRecording'
import { CancelOrderModal } from '../CancelOrderModal'
import { CANCEL_OPERATION } from '../../constants/cancellations'

import CancelWarning from './CancelWarning'

const initialState = {
  select: '',
  currentStep: 0,
  maxSteps: 2,
  poncChecked: false,
  showModal: false,
  retainReason: '',
  cancelReason: '',
  callReason: '',
  retainState: -1,
  cancelState: -1,
  modalState: -1,
  retainDefaultOptions: ['Opción 1'],
  cancelReasons: [],
  isValidEmail: false,
  isEmailChecked: false,
  cancelResult: 0,
  email: '',
}

const state = initialState

class CancelOrder extends Component {
  state = state

  componentDidMount() {
    this.props.fetchContentfulResource('cancellationReasonOptions', { brands: 'yoigo' })
    this.props.fetchContentfulResource('retentionReasonOptions', { brands: 'yoigo' })
  }

  componentDidUpdate(newProps) {
    if (isEqual(newProps, this.props)) return
    if (!newProps.orderId) this.onClickGoBack()
    if (this.state.showModal && !newProps.ponc.loading) {
      this.onSendOrder()
    }
    const poncHasFailed = this.props.ponc.loading === false && this.props.ponc.error
    if (this.props.cancellation.orderLoading === false || poncHasFailed) {
      this.onSetResult()
    }
  }

  onClickGoBack = () => {
    window.history.back()
  }

  onClickClose = () => {
    window.close()
  }

  onSelectRetainReason = (retainReason = '') => {
    this.setState({ retainReason })
  }

  onSelectCancelReason = (cancelReason = '') => {
    this.setState({ cancelReason })
  }

  onSelectCallReason = callReason => {
    const { cancelOptions } = this.props
    const cancelReasons = get(find(cancelOptions, { text: callReason }), 'subreason')
    this.setState({ callReason, cancelReasons, cancelReason: '' })
  }

  onRetain = () => {
    this.setState({ retainState: 0, retainReason: '' })
  }

  onCancel = () => {
    this.setState({ cancelState: 0, cancelReason: '' })
  }

  onContinue = () => {
    const { currentStep, retainState, cancelState } = this.state
    this.setState({
      currentStep: currentStep + 1,
      retainState: retainState === 0 ? 1 : -1,
      cancelState: cancelState === 0 ? 1 : -1,
      isEmailChecked: false,
      isValidEmail: false,
    })
  }

  onContinueRetain = () => {
    if (!this.state.retainReason) {
      return this.setState({ showModal: true, modalState: 0 })
    }
    return this.onContinue()
  }

  onContinueCancel = () => {
    this.onContinue()
  }

  onShowActionModal = () => {
    const { isValidEmail, isEmailChecked } = this.state
    this.onResetModalState()
    if (isEmailChecked && !isValidEmail) {
      this.setState({ isValidEmail: false })
    } else {
      this.setState({ showModal: true })
    }
  }

  onToggleCheck = isEmailChecked => {
    this.setState({ isEmailChecked })
  }

  onSendOrder = () => {
    const { currentStep } = this.state
    this.onModalClose()
    this.setState({ currentStep: currentStep + 1 })
  }

  onWindowClose = () => {
    window.close()
  }

  onSetResult = () => {
    const hasError = this.props.cancellation.orderError || this.props.ponc.reached
    // Success case
    let cancelResult

    if (!hasError && !this.props.ponc.error) {
      cancelResult = 1
      // Cancellation order error
    } else if (this.props.cancellation.orderError || this.props.ponc.error) {
      cancelResult = 2
      // PONC Reached
    } else if (this.props.ponc.reached) {
      cancelResult = 3
    }
    this.setState({ cancelResult })
  }

  onResetModalState = () => {
    const { retainState } = this.state
    const modalState = retainState !== -1 ? 0 : -1
    this.setState({ modalState })
  }

  onModalClose = () => {
    // Reset modal state
    this.setState({ showModal: false })
  }

  onInputEmail = (isValidEmail, email) => {
    this.setState({ isValidEmail, email: isValidEmail ? email : '' })
  }

  onCancelOrder = () => {
    const {
      personalData: { fullName },
      orderId,
    } = this.props

    const { isEmailChecked, email, cancelReason, callReason } = this.state
    this.props.cancelOrder(orderId, {
      orderId,
      body: { cancelReason, communication: { email, clientName: fullName }, callReason },
      notify: isEmailChecked,
      cancelRelated: false,
    })
  }

  onRetainOrder = () => {
    this.props.retainOrder(this.props.orderId, {
      reason: this.state.retainReason,
      email: this.state.email,
    })
    this.setState({ showModal: false })
  }

  onModalConfirm = () => {
    this.setState({ cancelResult: 0 })
    if (this.state.cancelState === -1) {
      this.onRetainOrder()
    } else {
      this.onCancelOrder()
    }
  }

  onWizardGoBack = () => {
    const { retainState, cancelState } = this.state
    this.setState({
      cancelState: cancelState > -1 ? cancelState - 1 : -1,
      currentStep: 0,
      retainState: retainState > -1 ? retainState - 1 : -1,
    })
  }

  render() {
    const {
      showModal,
      retainState,
      cancelState,
      callReason,
      cancelReasons,
      retainReason,
      cancelReason,
      isValidEmail,
      isEmailChecked,
    } = this.state
    const {
      ponc,
      cancelOptions,
      lines,
      currentDate,
      personalData,
      companyInfo,
      retainOptions,
      penalties,
      products,
      isElFijoCancellation,
    } = this.props

    const retainLegal = (
      <RetainLegalRecording
        personalData={personalData}
        currentDate={currentDate}
        lines={lines}
        products={products}
        companyInfo={companyInfo}
      />
    )

    const retainCancel = (
      <CancelLegalRecording
        isElFijoCancellation={isElFijoCancellation}
        personalData={personalData}
        currentDate={currentDate}
        lines={lines}
        penalties={penalties || {}}
        companyInfo={companyInfo}
        routerPenalty="79€/139€"
      />
    )

    const callReasons = (
      <>
        <CallReasons
          selectedOption={callReason}
          onMenuItemChange={this.onSelectCallReason}
          defaultOption="Seleccionar el motivo de la llamada"
          options={cancelOptions || []}
        />
      </>
    )
    const confirmAction = (
      <LegalRecording
        sendOrder={this.onShowActionModal}
        onToggleCheck={isChecked => this.onToggleCheck(isChecked)}
        onInputChange={(isValid, value) => this.onInputEmail(isValid, value)}
        legalComponent={this.state.retainState > -1 ? retainLegal : retainCancel}
      />
    )

    const cancelOrderReason = (
      <WizardRequest
        onClick={this.onCancel}
        onClickContinue={this.onContinueCancel}
        onClickConfirm={this.onShowActionModal}
        onClickGoBack={this.onWizardGoBack}
        state={cancelState + 1}
        onMenuItemChange={this.onSelectCancelReason}
        selectedOption={cancelReason}
        defaultOption="Seleccionar el motivo de la cancelación"
        continueText="Continuar con la cancelación"
        confirmText="Confirmar cancelación"
        isCancel
        defaultText="Cancelar la suscripción"
        options={cancelReasons}
        defaultDisabled={!callReason}
        disabled={!isValidEmail && isEmailChecked}
        required
      />
    )

    const retainOrderReason = (
      <WizardRequest
        onClick={this.onRetain}
        onClickContinue={this.onContinueRetain}
        onClickConfirm={this.onShowActionModal}
        onClickGoBack={this.onWizardGoBack}
        state={retainState}
        isCancel={false}
        onMenuItemChange={this.onSelectRetainReason}
        selectedOption={retainReason}
        defaultOption="Selecciona el submotivo de la retención"
        continueText="Continuar con la retención"
        confirmText="Confirmar solicitud"
        defaultText="Retener"
        disabled={!isValidEmail && isEmailChecked}
        defaultDisabled={!callReason}
        options={retainOptions}
      />
    )

    const cancelOrRetain = (
      <Box display="flex" alignItems="flex-start" justifyContent="flex-start" p="13px 0 0 0">
        {this.state.retainState === -1 ? (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            mr="15px"
            data-hook="wizard-request-cancel">
            {cancelOrderReason}
          </Box>
        ) : null}

        {this.state.cancelState === -1 ? (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            data-hook="wizard-request-retain">
            {retainOrderReason}
          </Box>
        ) : null}
      </Box>
    )

    const poncDateStr = ponc.date ? `${ponc.date} a las ${ponc.time}` : 'N/A'

    const penalty = penalties?.error ? (
      <CardRowValue style={{ marginTop: '10px' }}>
        No se ha podido acceder a las penalizaciones.
      </CardRowValue>
    ) : null

    let render
    switch (this.state.currentStep) {
      case 0:
        render = (
          <div>
            <CardRow>
              <CardRowValue>
                Suscripciones en el pedido a cancelar (Punto de no cancelación: {poncDateStr})
              </CardRowValue>
            </CardRow>

            {this.props.lines &&
              this.props.lines.map((line, i) => {
                let tariffName = line?.tariffName.split('+')[i] || '-'
                if (isElFijoCancellation && tariffName === '-') {
                  tariffName = line?.tariffName
                }

                return (
                  <LineInformation
                    key={line.id}
                    line={{ ...line, tariffName: tariffName.trim() }}
                    isElFijoCancellation={isElFijoCancellation}
                  />
                )
              })}

            <CardRow>{penalty}</CardRow>

            <Box
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              style={{ maxWidth: '366px', marginBottom: '25px' }}>
              {callReasons}
            </Box>

            {this.state.cancelState !== -1 && !isElFijoCancellation ? CancelWarning : null}

            {cancelOrRetain}
          </div>
        )
        break
      case 1:
        render = (
          <div>
            {confirmAction}
            <Box display="flex" alignItems="center">
              {this.state.cancelState !== -1 ? cancelOrderReason : retainOrderReason}
            </Box>
          </div>
        )
        break
      case 2:
        render = (
          <div>
            <CancellationResult
              closeWindow={this.onWindowClose}
              isSaving={this.state.cancelResult === 0}
              hasError={this.state.cancelResult > 1}
              isSuccess={this.state.cancelResult === 1}
              onRetry={this.onModalConfirm}
            />
          </div>
        )
        break
      default:
        break
    }
    return (
      <>
        <SectionTitleGrid xs={12}>
          <SectionTitle margin="24px" width="auto">
            Cancelar Suscripción
          </SectionTitle>
          <ButtonLink color="default" underline={false} onClick={this.onClickClose}>
            VOLVER A VISTA SIN GUARDAR CAMBIOS
            <Close />
          </ButtonLink>
        </SectionTitleGrid>{' '}
        <Grid style={{ margin: '50px auto' }}>
          <Card width="90%">{render}</Card>
          <CancelOrderModal
            operationType={CANCEL_OPERATION}
            open={showModal}
            onClose={this.onModalClose}
            onConfirm={this.onModalConfirm}
            modalState={this.state.modalState}
          />
        </Grid>
      </>
    )
  }
}

CancelOrder.propTypes = {
  orderId: PropTypes.string,
  lines: PropTypes.arrayOf(PropTypes.object),
  fetchContentfulResource: PropTypes.func.isRequired,
  companyInfo: PropTypes.any.isRequired,
  cancelOptions: PropTypes.any,
  retainOptions: PropTypes.any,
  ponc: PropTypes.objectOf(PropTypes.any).isRequired,
  cancelOrder: PropTypes.func.isRequired,
  retainOrder: PropTypes.func.isRequired,
  currentDate: PropTypes.any.isRequired,
  personalData: PropTypes.any.isRequired,
  cancellation: PropTypes.any.isRequired,
  penalties: PropTypes.object,
  products: PropTypes.array,
  isElFijoCancellation: PropTypes.bool,
}

CancelOrder.defaultProps = {
  orderId: '',
  retainOptions: [],
  cancelOptions: [],
}
export default CancelOrder
