import { get, keyBy } from 'lodash'

import * as actions from './barrings.actions'

const initialState = {
  data: {},
  loading: false,
  error: null,
}

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actions.FIND_BARRINGS_STATUS: {
      return {
        ...state,
        loading: true,
      }
    }
    case actions.FIND_BARRINGS_STATUS_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          [payload.productId]: {
            barrings: {
              ...keyBy(
                payload.data.barrings.map(barring => {
                  if (state.data[payload.productId]) {
                    if (
                      state.data[payload.productId].barrings[barring.id].status !== barring.status
                    ) {
                      return { ...barring, loading: false, error: null, done: null }
                    }
                    return {
                      ...state.data[payload.productId].barrings[barring.id],
                    }
                  }

                  return {
                    ...barring,
                  }
                }),
                'id',
              ),
            },
          },
        },
        loading: false,
        error: null,
      }
    }
    case actions.FIND_BARRINGS_STATUS_FAILED: {
      return {
        ...state,
        error: get('error', payload) || payload,
        loading: false,
      }
    }
    case actions.SET_MULTIPLE_BARRINGS_STATUS_ATTEMPTED:
      return {
        ...state,
        data: {
          ...state.data,
          [payload.productId]: {
            ...state.data[payload.productId],
            barrings: {
              ...state.data[payload.productId].barrings,
            },
            loading: true,
          },
        },
      }
    case actions.SET_MULTIPLE_BARRINGS_STATUS_SUCCEEDED:
      return {
        ...state,
        data: {
          ...state.data,
          [payload.productId]: {
            ...state.data[payload.productId],
            barrings: {
              ...state.data[payload.productId].barrings,
            },
            loading: false,
            error: false,
          },
        },
      }
    case actions.SET_MULTIPLE_BARRINGS_STATUS_FAILED:
      return {
        ...state,
        data: {
          ...state.data,
          [payload.productId]: {
            ...state.data[payload.productId],
            barrings: {
              ...state.data[payload.productId].barrings,
            },
            loading: false,
            error: get('error', payload) || payload,
          },
        },
      }
    case actions.SET_BARRING_STATUS_SUCCEEDED:
      return {
        ...state,
        data: {
          ...state.data,
          [payload.productId]: {
            ...state.data[payload.productId],
            barrings: {
              ...state.data[payload.productId].barrings,
              [payload.barring.barr_code]: {
                ...state.data[payload.productId].barrings[payload.barring.barr_code],
                loading: false,
                error: false,
                isOnGoing: true,
              },
            },
          },
        },
      }
    default:
      return state
  }
}
