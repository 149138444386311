import { get } from 'lodash'
import { constants } from './constants'

const initialState = {
  error: null,
  loading: false,
}

export const reducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case constants.FIND_ORDER_STATUS_DESC: {
      return { ...initialState, loading: true }
    }
    case constants.FIND_ORDER_STATUS_DESC_SUCCEEDED: {
      return { ...state, data: get(payload, 'data.data'), loading: false }
    }
    case constants.FIND_ORDER_STATUS_DESC_FAILED: {
      return {
        ...state,
        loading: false,
        error: {
          httpCode: payload.httpCode,
          info: payload.info,
        },
      }
    }
    default:
      return state
  }
}
