import { actionTypes } from 'modules/typifications/constants'

export const initSecurizerTypification = ({customerId, sfid, area, msisdns})  => 
   ({
    type: actionTypes.SECURIZER_TYPIFICATION_INIT,
    payload: {customerId, sfid, msisdns, area}
  })
  
  export const securizerSuccess = payload  => 
     ({
    type: actionTypes.SECURIZER_SUCCESS,
    payload
  })
  
  export const securizerFailed = payload  => ({
    type: actionTypes.SECURIZER_FAILED,
    payload
  })

  export const resetSecurizerFailed = ()  => ({
    type: actionTypes.RESET_SECURIZER_FAILED,
  })

  export const closeTypificationIframe = () => ({
    type: actionTypes.CLOSE_TYPIFICATION_IFRAME
  })
  