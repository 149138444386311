import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { Typography } from '@material-ui/core'
import { TextStyles } from 'utils/text'
import { NotificationModal } from 'components/ui'
import { ORDER_LIMIT_ERROR, ORDER_LIMIT_REACHED } from 'modules/SharedSales/constants/validations'
import { resetCustomerOrderLimitLoading, fetchCustomerOrderLimit } from 'modules/CustomerOrders'

import { selectOrderLimitModal } from './OrderLimitModal.selectors'

export function OrderLimitModal({ shouldCheckLimit, onOrderLimitOk, onOrderLimitReached }) {
  const dispatch = useDispatch()
  const [isOpen, setIsOpen] = useState(false)
  const { saleInformation, hasOrderLimit, isLoading, error } = useSelector(selectOrderLimitModal)

  useEffect(() => {
    if (shouldCheckLimit && !hasOrderLimit) {
      dispatch(fetchCustomerOrderLimit(saleInformation))
    }
  }, [shouldCheckLimit, saleInformation])

  useEffect(() => {
    return () => dispatch(resetCustomerOrderLimitLoading())
  }, [])

  useEffect(() => {
    if (isLoading === false && !!shouldCheckLimit) {
      if (hasOrderLimit || error) {
        setIsOpen(true)
        onOrderLimitReached()
      } else {
        setIsOpen(false)
        onOrderLimitOk()
      }
    }
  }, [isLoading, shouldCheckLimit])

  return (
    <NotificationModal
      isOpen={isOpen}
      type="alert"
      src="/assets/error.svg"
      onClose={() => {
        setIsOpen(false)
        dispatch(resetCustomerOrderLimitLoading())
      }}>
      <Typography {...TextStyles.title2Dark()}>
        {error ? ORDER_LIMIT_ERROR : ORDER_LIMIT_REACHED}
      </Typography>
    </NotificationModal>
  )
}

OrderLimitModal.propTypes = {
  shouldCheckLimit: PropTypes.bool,
  onOrderLimitOk: PropTypes.func,
  onOrderLimitReached: PropTypes.func,
}
