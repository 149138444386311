/**
 * This function accepts an object like { foo: { bar: { what: {} } }, bar: {}, ... }
 * And returns a String like 'foo.bar.what' (first element full nested path)
 * In formik, error keys are sorted by position in DOM.
 *
 * @param {Object | String} obj
 * @param {String | Null } lastPath
 *
 */
function getFirstObjPath(obj, lastPath) {
  function isObj(val) {
    return val && typeof val === 'object' && val.constructor === Object
  }

  if (isObj(obj)) {
    const firstKey = Object.keys(obj)[0]
    return getFirstObjPath(obj[firstKey], lastPath ? `${lastPath}.${firstKey}` : firstKey)
  }

  return lastPath
}

export const useScrollToFirstInputError = () => {
  const scrollToFirstError = errors => {
    const errorElement = document.querySelector(`[name^="${getFirstObjPath(errors)}"]`)
    if (errorElement) {
      if (errorElement.type === 'hidden') errorElement.parentElement.scrollIntoView()
      errorElement.scrollIntoView()
    }
  }

  return {
    scrollToFirstError,
  }
}
