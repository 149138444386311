// Constants
const LOAD_INIT = 'OVID/OFFERSCONFIGURATION/LOAD_INIT'
const LOAD_SUCCEEDED = 'OVID/OFFERSCONFIGURATION/LOAD_SUCCEEDED'
const LOAD_FAILED = 'OVID/OFFERSCONFIGURATION/LOAD_FAILED'

export const constants = {
  LOAD_INIT,
  LOAD_SUCCEEDED,
  LOAD_FAILED,
}
