import { get } from 'lodash'
import { useFormikContext } from 'formik'
import { COMPANY } from 'services/global-constants'

export function useMainUser(account) {
  const formikProps = useFormikContext()
  const userAccount = get(formikProps, 'values.account', account)

  const { segment, name = '', surname1 = '', surname2 = '', companyName } = userAccount || {}

  return segment === COMPANY ? companyName : `${name} ${surname1} ${surname2}`
}
