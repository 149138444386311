export const GET_ORDER_PONR_INIT = 'OVID/GET_ORDER_PONR_INIT'
export const GET_ORDER_PONR = 'OVID/GET_ORDER_PONR'
export const GET_ORDER_PONR_SUCCESS = 'OVID/GET_ORDER_PONR_SUCCESS'
export const GET_ORDER_PONR_ERROR = 'OVID/GET_ORDER_PONR_ERROR'

export const orderPonrInitAction = orderId => ({
  type: GET_ORDER_PONR_INIT,
  payload: {
    orderId,
  },
})

export const getOrderPonrAction = () => ({
  type: GET_ORDER_PONR,
})

export const getOrderPonrSuccessAction = ponr => ({
  type: GET_ORDER_PONR_SUCCESS,
  payload: {
    ponr,
  },
})

export const getOrderPonrErrorAction = (error_code, error_message) => ({
  type: GET_ORDER_PONR_ERROR,
  payload: {
    error_code,
    error_message,
  },
})
