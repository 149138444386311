import { Component } from 'react'
import { PropTypes } from 'prop-types'
import { isEqual, isEmpty, chain, get } from 'lodash'
import { Button, CircularProgress, Box, Divider } from '@material-ui/core'
import Close from '@material-ui/icons/Close'
import {
  ContainerTitle,
  CardRow,
  CardRowLabel,
  CardRowValue,
  Card,
  SectionTitle,
  SectionGrid,
  SectionTitleGrid,
  SectionValidationGrid,
  WizardNavigation,
} from 'components'
import { checkForMsisdn } from 'services/check-order-ct-on-fly'
import { checkIsNeba } from 'modules/Coverage'
import { CustomerMoveInfo } from '../CustomerMoveInfo'
import { CustomerMoveAddress } from '../CustomerMoveAddress'
import { ClientValidation } from '../ClientValidation'
import { MoveClientSummaryContainer } from '../../containers/MoveClientSummaryContainer'
import { fillchangeTechnologyOrderCoverage } from '../ChangeTechology/change-technology.helper'
import { formatAddress } from '../../helpers/formats.helper'

class MoveClient extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentStep: 0,
      maxSteps: 3,
      changeAddressJson: null,
    }
  }

  componentDidMount() {
    const { fixedNumber } = this.props
    this.props.getSubscriptionData({
      phoneNumber: fixedNumber,
      fixedline: fixedNumber,
    })
  }

  componentDidUpdate(nextProps) {
    const {
      customer,
      sfid,
      customerCoverageData,
      customerWithProductUpdated,
      clientId,
      installationAddress,
    } = this.props
    let changeAddressJson = {}
    if (isEqual(customerCoverageData, nextProps.customerCoverageData)) {
      return
    }
    if (customerCoverageData) {
      changeAddressJson = fillchangeTechnologyOrderCoverage(
        customer,
        customerCoverageData,
        sfid,
        {},
        customerWithProductUpdated,
        clientId,
        installationAddress,
      )
    }
    if (!isEmpty(changeAddressJson)) {
      this.setState({ changeAddressJson })
    }
  }

  onReceiveCoverageData = (coverageData = {}) => {
    this.setState({ coverageData })
    this.props.gotCoverageData(coverageData)
    const territoryOwnerNewCoverage = chain(coverageData)
      .get('coverage')
      .head()
      .get('characteristics')
      .filter({ name: 'territoryOwner' })
      .head()
      .get('value')
      .value()
    if (checkIsNeba(territoryOwnerNewCoverage)) {
      this.props.fetchInstallationDirection()
    }
  }

  previous = () => {
    const { currentStep } = this.state
    const nextStep = currentStep - 1 < 0 ? 0 : currentStep - 1

    this.setState({
      currentStep: nextStep,
    })
  }

  next = () => {
    const { currentStep, maxSteps } = this.state
    const nextStep = currentStep + 1 >= maxSteps ? maxSteps : currentStep + 1
    this.setState({
      currentStep: nextStep,
    })
  }

  closeTab = () => {
    window.close()
  }

  render() {
    const { currentStep, coverageData } = this.state
    const {
      customer,
      getCoverageToken,
      debt,
      tokenCoverage,
      loading,
      tariffsError,
      orderOnFly,
      orderCTOnFly,
      loadingInitialStep,
      customerProd,
      isFeatNebaEnabled,
      isLoadingInstallations,
      installer,
      FFlagInstaller,
      installConnectionType,
    } = this.props
    let render
    switch (currentStep) {
      case 0: {
        const { fixedline } = this.state
        const msisdn = chain(customerProd)
          .map(prod => (get(prod.productNumber.split('+'), '[1]') === fixedline ? prod.id : null))
          .filter(obj => obj)
          .head()
          .value()

        const orderCTOnFlyCheckForMsisdn = checkForMsisdn(orderCTOnFly, msisdn)

        render = (
          <ClientValidation
            loading={loadingInitialStep || loading}
            tariffsError={tariffsError}
            adsl
            existCoverage // !important!
            orderOnFly={orderOnFly}
            checkOrderCTOnFly={orderCTOnFlyCheckForMsisdn}
            setStep={() => this.setState({ currentStep: 1 })}
            tokenCoverage={tokenCoverage}
          />
        )
        break
      }
      case 1:
        render = (
          <CustomerMoveInfo
            getToken={getCoverageToken}
            customer={customer.customer}
            next={this.next}
            debt={debt}
            previous={this.previous}
            tokenGenerated={tokenCoverage}
            onReceiveCoverageData={this.onReceiveCoverageData}
          />
        )
        break
      case 2:
        if (isLoadingInstallations) {
          render = <CircularProgress size={100} />
        } else {
          render = (
            <>
              <CustomerMoveAddress
                next={this.next}
                previous={this.previous}
                address={formatAddress(coverageData)}
                customer={customer.customer}
                coverageData={coverageData}
                closeWindowIfExistError
                isFeatNebaEnabled={isFeatNebaEnabled}
                installer={installer}
                FFlagInstaller={FFlagInstaller}
              />
              <WizardNavigation
                next={{
                  callback: this.next,
                  disabled: false,
                  caption: 'Confirmar dirección',
                }}
                previous={{
                  callback: this.previous,
                  disabled: false,
                  caption: 'Probar otra dirección',
                }}
              />
            </>
          )
        }
        break
      case 3:
        if (isLoadingInstallations) {
          render = <CircularProgress size={100} />
        } else {
          render = (
            <>
              <CustomerMoveAddress
                next={this.next}
                address={formatAddress(coverageData)}
                customer={customer.customer}
                coverageData={coverageData}
                isFeatNebaEnabled={isFeatNebaEnabled}
                installer={installer}
                FFlagInstaller={FFlagInstaller}
              />
              <MoveClientSummaryContainer
                changeAddressJson={this.state.changeAddressJson}
                customer={customer.customer}
                previous={this.previous}
                coverageData={coverageData}
                isFeatNebaEnabled={isFeatNebaEnabled}
                installer={installer}
                FFlagInstaller={FFlagInstaller}
                installConnectionType={installConnectionType}
              />
            </>
          )
        }
        break
      default:
        render = <h1>Hola</h1>
        break
    }

    if (currentStep !== 0) {
      return (
        <>
          <SectionTitleGrid>
            <SectionTitle width="auto">Cambio de Domicilio</SectionTitle>
            <Button color="default" onClick={this.closeTab}>
              cancelar y volver
              <Close />
            </Button>
          </SectionTitleGrid>
          <SectionGrid>
            <Card width="80%">
              <ContainerTitle>DIRECCIÓN ACTUAL</ContainerTitle>
              <CardRow>
                <CardRowLabel>Dirección de la instalación: </CardRowLabel>
                <CardRowValue>{this.props.currentAddress}</CardRowValue>
              </CardRow>
              <Box mt="15px 30px">
                <Divider />
              </Box>
              {render}
            </Card>
          </SectionGrid>
        </>
      )
    }
    return (
      <>
        <SectionTitleGrid>
          <SectionTitle width="auto">Cambio de Domicilio</SectionTitle>
          <Button color="default" onClick={this.closeTab}>
            cancelar y volver
            <Close />
          </Button>
        </SectionTitleGrid>
        <SectionValidationGrid>{render}</SectionValidationGrid>
      </>
    )
  }
}

MoveClient.propTypes = {
  sfid: PropTypes.string,
  currentAddress: PropTypes.string,
  installationAddress: PropTypes.object,
  getCoverageToken: PropTypes.func,
  gotCoverageData: PropTypes.func,
  getSubscriptionData: PropTypes.func,
  debt: PropTypes.object,
  tokenCoverage: PropTypes.string,
  loading: PropTypes.bool,
  fixedNumber: PropTypes.string,
  customerCoverageData: PropTypes.object,
  customerWithProductUpdated: PropTypes.object,
  customer: PropTypes.object,
  tariffsError: PropTypes.bool,
  orderOnFly: PropTypes.object,
  orderCTOnFly: PropTypes.array,
  loadingInitialStep: PropTypes.bool,
  customerProd: PropTypes.array,
  isFeatNebaEnabled: PropTypes.bool,
  clientId: PropTypes.string,
  fetchInstallationDirection: PropTypes.func,
  isLoadingInstallations: PropTypes.bool,
  installer: PropTypes.string,
  FFlagInstaller: PropTypes.bool,
  installConnectionType: PropTypes.string,
}

export default MoveClient
