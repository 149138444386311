import PropTypes from 'prop-types'
import { get } from 'lodash'
import { CircularProgress, Typography, Divider, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { SEVERAL, QUOTA25 } from 'services/global-constants'
import { formatFee } from 'utils'

const useStyles = makeStyles(theme => ({
  paymentInfoWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },

  rowWrapper: {
    display: 'flex',
  },

  divider: {
    display: 'none',
    height: '70px',
    margin: '0 8px',

    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },

  'divider-light': {
    display: 'inline',
    backgroundColor: '#efefef',
  },

  itemInfo: {
    marginBottom: '8px',
    [theme.breakpoints.up('md')]: {
      marginBottom: '0',
      marginTop: '4px',
      paddingLeft: '4px',
      paddingRight: '4px',
    },
  },

  itemCommission: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: '4px',
    },
  },
}))

const PermanencyInfo = ({ terminalDetails }) => {
  return (
    <Box display="flex" flexWrap="wrap" className="m-y-4">
      <Typography variant="subtitle2" className="thin">
        Penalización:
      </Typography>
      <Typography variant="body2" className="p-l-4" data-hook="terminal.permanency">
        {formatFee(get(terminalDetails, 'permanency', 0))}
      </Typography>
    </Box>
  )
}

export function TerminalItemPaymentInfo({
  terminalDetails,
  hasSinglePayment,
  paymentType,
  isFinancialsLoading,
  isFinanced,
}) {
  const isPaymentTypeSeveral = [SEVERAL, QUOTA25].includes(paymentType)
  const classes = useStyles()
  return (
    <Box display="flex">
      <Divider
        orientation="vertical"
        className={`${classes.divider} ${classes['divider-light']} m-x-8`}
      />
      {isPaymentTypeSeveral && (
        <div className={classes.paymentInfoWrapper}>
          {terminalDetails.initialPayment && (
            <>
              <div className={classes.itemInfo}>
                <Typography variant="subtitle2" className="thin">
                  Pago inicial
                </Typography>
                <Typography
                  variant="h4"
                  component="p"
                  className="regular"
                  data-hook="terminal.initialPayment">
                  {formatFee(get(terminalDetails, 'initialPayment', 0))}
                </Typography>
              </div>
              <Divider className={`${classes.divider} m-x-8`} orientation="vertical" />
            </>
          )}
          <div className={classes.itemInfo}>
            <Typography variant="subtitle2" className="thin" data-hook="terminal.fees">
              Precio mes ({get(terminalDetails, `fees`, 0)})
            </Typography>
            <Typography
              variant="h4"
              component="p"
              className="regular"
              data-hook="terminal.monthlyPayment">
              {get(terminalDetails, `monthlyPayment`)} €/mes
            </Typography>
          </div>
          {terminalDetails.finalPayment && (
            <>
              <Divider className={`${classes.divider} m-x-8`} orientation="vertical" />
              <div className={classes.itemInfo}>
                <Typography variant="subtitle2" className="thin">
                  Pago final
                </Typography>
                <Typography
                  variant="h4"
                  component="p"
                  className="regular"
                  data-hook="terminal.finalPayment">
                  {formatFee(get(terminalDetails, 'finalPayment', 0))}
                </Typography>
              </div>
            </>
          )}
          {isFinanced &&
            (isFinancialsLoading ? (
              <CircularProgress className="m-l-20" size="16px" color="secondary" />
            ) : (
              <>
                <Divider className={`${classes.divider} m-x-8`} orientation="vertical" />
                <div className={classes.itemCommission}>
                  <Box display="flex" flexWrap="wrap" className="m-y-4">
                    <Typography variant="subtitle2" className="thin">
                      Comisión de apertura:
                    </Typography>

                    <Typography variant="body2" className="p-l-4" data-hook="terminal.comissionFee">
                      {get(terminalDetails, 'commissionFee')
                        ? `${formatFee(get(terminalDetails, 'commissionFee', 0))}`
                        : 'No disponible'}
                    </Typography>
                  </Box>
                  <PermanencyInfo terminalDetails={terminalDetails} />
                </div>
              </>
            ))}
        </div>
      )}
      {hasSinglePayment && !isPaymentTypeSeveral && (
        <>
          <div className={classes.itemInfo}>
            <Typography variant="subtitle2" className="thin">
              Pago único
            </Typography>
            <Typography
              variant="h4"
              component="p"
              className="regular"
              data-hook="terminal.initialPayment">
              {formatFee(get(terminalDetails, 'initialPayment', 0))}
            </Typography>
          </div>
          <Divider
            orientation="vertical"
            className={`${classes.divider} ${classes['divider-light']} m-x-8`}
          />
          <PermanencyInfo terminalDetails={terminalDetails} />
        </>
      )}
    </Box>
  )
}

PermanencyInfo.propTypes = {
  terminalDetails: PropTypes.object,
}

TerminalItemPaymentInfo.propTypes = {
  terminalDetails: PropTypes.object,
  hasSinglePayment: PropTypes.bool,
  paymentType: PropTypes.string,
  isFinancialsLoading: PropTypes.bool,
  isFinanced: PropTypes.bool,
}
