import { call, put, takeEvery } from 'redux-saga/effects'
import { logError, formatErrorMessage } from 'services/logging'
import { axiosFactory } from 'modules/axios'

import {
  getOrderPonrAction,
  getOrderPonrSuccessAction,
  getOrderPonrErrorAction,
  GET_ORDER_PONR_INIT,
} from './ponr.actions'
import { findIsOrderPonr } from '../services/ponr.service'

export function* findIsOrderPonrSaga({ payload: { orderId } }) {
  try {
    yield put(getOrderPonrAction())
    const data = yield call(findIsOrderPonr, orderId)
    yield put(getOrderPonrSuccessAction(data))
  } catch (e) {
    yield call(logError, { e: new Error(formatErrorMessage(e)), orderId })
    const error = axiosFactory.buildError(e)
    yield put(getOrderPonrErrorAction(error))
  }
}

export function* watchFindIsOrderPonr() {
  yield takeEvery(GET_ORDER_PONR_INIT, findIsOrderPonrSaga)
}
