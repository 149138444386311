import { call, put, takeEvery } from 'redux-saga/effects'
import { logError, formatErrorMessage } from 'services/logging'
import { axiosFactory } from 'modules/axios'
import actions from './view.actions'
import { findTickets } from './api'
import * as constants from './constants'

export function* findTicketsSaga() {
  try {
    const tickets = yield call(findTickets)

    yield put(actions.findTicketsSucceeded(tickets))
  } catch (e) {
    yield call(logError, new Error(formatErrorMessage(e)))
    const error = axiosFactory.buildError(e)
    yield put(actions.findTicketsFailed(error))
  }
}

export function* watchFindTickets() {
  yield takeEvery(constants.FIND_TICKETS_ATTEMPTED, findTicketsSaga)
}
