import PropTypes from 'prop-types'
import { CircularProgress, Box } from '@material-ui/core'
import { Advice } from 'components/ui'

export function TerminalStockComponent({ stock, isStockLoading, terminalId }) {
  const stockCmp =
    stock > 0 ? (
      <Advice id="available-stock" type="success" block>
        <Box p="8px">Stock disponible</Box>
      </Advice>
    ) : (
      <Advice id="no-stock" type="error" block>
        <Box p="8px">No hay stock disponible. Marque un NO si el cliente no quiere dispositivo para poder continuar la venta.</Box>
      </Advice>
    )
  return (
    <div className="flex align-center-center p-l-16">
      {isStockLoading && <CircularProgress color="secondary" size={16} />}
      {isStockLoading === false && terminalId && stockCmp}
    </div>
  )
}

TerminalStockComponent.propTypes = {
  stock: PropTypes.number,
  isStockLoading: PropTypes.bool,
  terminalId: PropTypes.string,
}
