import { call, put, takeEvery, select } from 'redux-saga/effects'
import { get, isEmpty } from 'lodash'
import { logError, formatErrorMessage } from 'services/logging'
import { axiosFactory } from 'modules/axios'
import { selectProfileId } from 'modules/Permissions'
import { actions } from './actions'
import { constants } from './constants'
import { getDiscounts } from './api'

export function* getDiscountsAgentSaga({ payload: { orderId, profiles } }) {
  try {
    yield put(actions.getDiscounts(orderId))

    const profileId = yield select(selectProfileId)
    const agentProfilesStore = !isEmpty(profileId) ? [profileId] : []

    const agentProfiles = yield profiles || agentProfilesStore
    const responseData = yield call(getDiscounts, agentProfiles.join(','), orderId)
    const data = get(responseData, 'discountAvailabilities')
    yield put(actions.getDiscountsSuccess(data, orderId))
  } catch (e) {
    yield call(logError, { e: new Error(formatErrorMessage(e)), orderId, profiles })
    const error = axiosFactory.buildError(e)
    yield put(actions.getDiscountsFailed(error, orderId))
  }
}

export function* watchGetDiscountsAgent() {
  yield takeEvery(constants.GET_DISCOUNTS_INIT, getDiscountsAgentSaga)
}
