import { SVA_HOMEGO_CODE } from 'modules/offers-configuration/constants'

export const HOME_GO_GROUP_SVA = {
  poId: SVA_HOMEGO_CODE,
  psId: SVA_HOMEGO_CODE,
  prices: {},
  commercialInfo: {
    name: 'Alarma Home GO',
  },
  fields: {},
}
