import styled, { withTheme } from 'styled-components'
import PropTypes from 'prop-types'
import { get } from 'lodash'

const Wrapper = styled.div`
  position: relative;
  width: ${props => props.width || '200px'};
  margin-top: ${props => (props.fullwidth ? '8px' : 'auto')};
`
const Small = styled.small`
  color: ${props => get(props, 'theme.palette.brand.alert', '#ff5c1e')};
  font-size: 12px;
  padding-left: ${props => (props.fullwidth ? '0px' : '-20px')};
  position: ${props => (props.initial ? 'initial' : 'absolute')};
  top: ${props => (props.fullwidth ? '0px' : '-10px')};
  left: ${props => (props.fullwidth ? '0px' : '20px')};
`

const FormError = props => (
  <Wrapper className={props.className} width={props.width} fullwidth={props.fullwidth}>
    <Small fullwidth={props.fullwidth} initial={props.initial}>
      {props.children}
    </Small>
  </Wrapper>
)

FormError.propTypes = {
  children: PropTypes.any,
  width: PropTypes.string,
  fullwidth: PropTypes.bool,
  className: PropTypes.string,
  initial: PropTypes.bool,
}

export default withTheme(FormError)
