import { get } from 'lodash'

import { getTaxes } from 'services/taxes'
import { createSelector } from 'reselect'

import { selectors as customerSelectors } from 'services/customer-360/selectors'
import { selectBaksetSaleTax } from './Basket.selectors'

export const selectSaleTax = createSelector(selectBaksetSaleTax, taxes => taxes)

export const selectCareTax = createSelector(
  customerSelectors.getBillingAddressPostCode,
  getTaxes,
  customerSelectors.getSegment,
  (billingAddressPostCode, taxes, segment) => {
    const tax = 1 + parseInt(get(taxes, `data.${billingAddressPostCode}`, 21), 10) / 100
    return segment.toUpperCase().includes('PRO') ? 1 : tax
  },
)
