import { createSelector } from 'reselect'
import { selectQueryParams, selectIsConvergent } from 'modules/Router'
import { isTerminalPaymentMultiple } from 'modules/Terminals/helpers'
import { selectIsCustomerActive } from 'modules/CustomerInfo/store'

import {
  CONSUMER,
  AUTONOMO,
  POSTPAID_D2D,
  CONVERGENT_D2D,
  EL_FIJO_D2D,
} from 'services/global-constants'
import { selectTechnology } from 'modules/Coverage'
import { get } from 'lodash'
import { selectTaskId, selectOrders } from './orders.selectors'
import { selectBasket, selectIsElFijo } from './Basket.selectors'
import { selectInstallation } from './NewClientSales.selectors'
import { getContractId } from './orders.selectors.helpers'

const SIGNUP_TYPE_NEWLINE = 'new number'

const getSegmentType = segment => {
  const consumer = 1
  const company = 2
  const selfEmployed = 6

  if (segment === CONSUMER) {
    return consumer
  }
  if (segment === AUTONOMO) {
    return selfEmployed
  }

  return company
}

export const findBasketLineById = (basket, orderKey, isConvergent) => {
  const mainOffer = !isConvergent ? basket.mainMobileLineOffer : basket.landlineOffer
  return mainOffer.id === orderKey
    ? mainOffer
    : basket.extraMobileLinesOffers.find(i => i.id === orderKey)
}

const isMain = line => line && line.id.indexOf('main-') === 0

const getOrderChannel = (line, isElFijo) => {
  if (isElFijo) {
    return EL_FIJO_D2D
  }

  if (isMain(line)) {
    return CONVERGENT_D2D
  }

  return POSTPAID_D2D
}

const getOrderType = line => {
  const newLine = 1
  const portability = 2
  const renewal = 3

  if (line.signUpType === SIGNUP_TYPE_NEWLINE) {
    return newLine
  }

  if (line.signUpType === 'portability') {
    return portability
  }

  return renewal
}

const getPhoneNumber = line => {
  return line.signUpType === SIGNUP_TYPE_NEWLINE ? line.newNumber.value : line.lineNumber
}

const getTerminalType = line => {
  const simOnly = 1
  const financial = 2
  const handset = 3

  const hasNotTerminal = !line.terminal || !line.terminal.id

  if (hasNotTerminal) {
    return simOnly
  }

  if (isTerminalPaymentMultiple(line.terminal)) {
    return financial
  }

  return handset
}

const getDonorHolder = line => {
  const isSameUser = 0
  const isDifferentUser = 1

  return line.isSameUser === 'yes' ? isSameUser : isDifferentUser
}

const getIsDonorHoldersMobileAndFixed = (mobileLine, fixedLine) => {
  // Should return 0 if the holders are the same, 1 if different, only for the main lines
  if (fixedLine.isSameUser !== mobileLine.isSameUser) {
    // One of them is different
    return 1
  }

  if (fixedLine.isSameUser === 'yes') {
    // Both same as original
    return 0
  }

  // Both different than original, and different documentId
  if (fixedLine.otherUser.documentId !== mobileLine.otherUser.documentId) {
    return 1
  }

  // Same documentId, different than origianl
  return 0
}

function getRoveData(order) {
  const rove = order?.characteristics?.find(characteristic => characteristic?.name === 'rove')
  if (!rove || !rove.value) return {}
  const { result, level } = rove.value
  return {
    result,
    level,
    finaluserdescription: rove.value['final_user-description'],
  }
}

export const formatD2DDataFromSelector = (
  basket,
  taskId,
  orders,
  isElFijo,
  isConvergent,
  technology,
  installation,
  client,
) => {
  const {
    segment,
    email,
    name,
    surname1,
    companyName,
    companyCif,
    documentid: document,
    documenttype,
  } = basket?.account
  const segmenttype = getSegmentType(segment)

  return {
    action: 'SALE',
    basket: {
      brandid: 1,
      segmenttype,
      taskid: taskId,
      email,
      name,
      surname: surname1,
      sign: '',
      document,
      documenttype,
      companyName,
      companyCif,
      client,
      order: orders.map(i => {
        const line = findBasketLineById(basket, i.orderKey, isConvergent)
        const contractid = i.success ? getContractId(i) : null
        if (!i.success) {
          // Different body structure for error
          const errorMessage = get(i, 'result.result.info', i.errorMessage)
          return {
            status: 'KO',
            description: errorMessage,
            orderid: i.id,
            taskid: taskId,
            contractid,
          }
        }

        // Success scenario
        const baseSuccessResponse = {
          status: 'OK',
          description: 'Order item success',
          orderid: i.id,
          contracts: i.contracts,
          contractid,
          orderchannel: getOrderChannel(line, isElFijo),
          ...getRoveData(i),
        }

        const isMainLine = isMain(line)
        // Different structure of data for mainline, adding things like fixnumbertype
        if (isMainLine) {
          const mainLineOrder = {
            ...baseSuccessResponse,
            agileTv: line?.SVA?.poId || false,
            ordertype: getOrderType(basket.mainMobileLineOffer),
            terminaltype: getTerminalType(basket.mainMobileLineOffer),
            fixnumbertype: getOrderType(line),
            fixnumber: getPhoneNumber(line),
            mobilenumber: getPhoneNumber(basket.mainMobileLineOffer),
            fixdonorholder: getDonorHolder(line),
            mobiledonorholder: getDonorHolder(basket.mainMobileLineOffer),
            donorholders: getIsDonorHoldersMobileAndFixed(basket.mainMobileLineOffer, line),
          }
          // if is el fijo or only mobile we dont need to send a technology type
          if (!isConvergent) {
            return mainLineOrder
          }

          if (installation.installer) {
            return {
              ...mainLineOrder,
              installationProvider: installation.installer,
              technology,
            }
          }

          return {
            ...mainLineOrder,
            technology,
          }
        }

        // Extra mobile line
        return {
          ...baseSuccessResponse,
          ordertype: getOrderType(line),
          terminaltype: getTerminalType(line),
          mobiledonorholder: getDonorHolder(line),
          mobilenumber: getPhoneNumber(line),
        }
      }),
    },
  }
}

export const selectD2DData = createSelector(
  selectBasket,
  selectTaskId,
  selectOrders,
  selectIsElFijo,
  selectIsConvergent,
  selectTechnology,
  selectInstallation,
  selectIsCustomerActive,
  formatD2DDataFromSelector,
)

// TODO: select from store
export const selectIsD2D = createSelector(
  selectQueryParams,
  ({ reseller }) => {
    return reseller === 'D2D'
  },
)
