import { actionTypes } from 'modules/typifications/constants'

const initialState = {
  data: [],
  error: null,
  loading: false,
  registerIsSuccess: false,
}

export function registerTypificationsReducer(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case actionTypes.GET_REGISTER_TYPIFICATIONS_INIT: {
      return {
        ...state,
        loading: true,
      }
    }

    case actionTypes.GET_REGISTER_TYPIFICATIONS_SUCCESS: {
      return {
        ...state,
        data: payload,
        loading: false,
        registerIsSuccess: true,
      }
    }

    case actionTypes.GET_REGISTER_TYPIFICATIONS_FAILED: {
      return {
        ...state,
        error: payload,
        loading: false,
      }
    }

    default:
      return state
  }
}
