import { constants } from './constants'

const getRoveInit = () => ({
  type: constants.GET_ROVE_INIT,
})

const getRove = () => ({
  type: constants.GET_ROVE,
})

const gotRove = ROVEresponse => ({
  type: constants.GOT_ROVE,
  payload: {
    rove: ROVEresponse,
  },
})

const getRoveFailed = error => ({
  type: constants.GET_ROVE_FAILED,
  payload: {
    error,
  },
})

export const actions = {
  getRoveInit,
  getRove,
  gotRove,
  getRoveFailed,
}
