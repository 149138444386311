import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import styled from 'styled-components'

import { formatDate, formatMonth, formatYearMonth } from 'utils/formatting/dates'
import { groupBillsByMonth } from 'modules/billings'
// import { selectURLDocumentId, selectURLDocumentType } from 'modules/CustomerDocument'
// import { buildPath, AppPaths } from 'modules/AppPaths'

// import { ButtonLink } from 'components/ui'
import BillingEmpty from 'modules/vista-360/components/BillingCard/BillingEmpty'
import MonthBillLineItem from 'modules/vista-360/components/BillingCard/MonthBillLineItem'
import Bill from 'modules/vista-360/components/BillingCard/Bill/Bill'

import { TextTitle } from './TextTitle'

export const LatestBills = styled(({ className, hasErrors, bills, lastNumBills = 2 }) => {
  const billsGroupedMonthly = groupBillsByMonth(bills)

  // const documentType = useSelector(selectURLDocumentType)
  // const documentId = useSelector(selectURLDocumentId)
  // const pathToAllBills = buildPath(AppPaths.dashboard.id, { documentType, documentId }, { tab: 2 })

  return (
    <div className={className}>
      {(isEmpty(billsGroupedMonthly) || hasErrors) && <BillingEmpty />}
      {!isEmpty(billsGroupedMonthly) && !hasErrors && (
        <>
          <TextTitle>Últimas facturas</TextTitle>
          {billsGroupedMonthly.slice(0, lastNumBills).map(monthBills => {
            const monthDate = monthBills[0]
            const billsPerMonth = monthBills[1]
            return (
              <div key={monthDate}>
                <MonthBillLineItem
                  yearMonth={formatYearMonth(monthDate)}
                  monthTxt={formatMonth(monthDate)}
                  dueDateTxt={formatDate(billsPerMonth[0].paymentDueDate)}
                  showAction={billsPerMonth.length > 1}
                />
                {billsPerMonth.map(bill => {
                  return <Bill key={bill.id} billData={bill} hasDivider={false} />
                })}
              </div>
            )
          })}{' '}
          {/* <ButtonLink className="btn-lnk" to={pathToAllBills}>
            Ver todas las facturas
          </ButtonLink> */}
        </>
      )}
    </div>
  )
})`
  & {
    padding-top: 12px;
  }
  > .btn-lnk {
    padding: 10px 24px;
    display: block;
  }
`

LatestBills.propTypes = {
  className: PropTypes.string,
  data: PropTypes.shape({
    restExpenses: PropTypes.number,
    terminalsFee: PropTypes.number,
    discounts: PropTypes.arrayOf(PropTypes.object),
    totalExpenses: PropTypes.number,
    enrichedTariffRanges: PropTypes.arrayOf(PropTypes.object),
    tax: PropTypes.number,
  }),
}
