import { Header } from 'modules/Header'
import { useD2DHeader } from 'modules/NewClientSales/hooks/D2D/useD2DHeader'

export function FlowChooserHeader() {
  const { onClickPowerButton, onClickHome } = useD2DHeader()

  return (
    <>
      <Header
        icon="menu"
        iconText="Menu"
        title="MasGestión"
        onClickPowerButton={onClickPowerButton}
        onClickHome={onClickHome}
        isD2D
      />
    </>
  )
}
