import { AnalyticsService } from '@masmovil/thor-ax-js-library'

const config = {
  funnelTypes: {},
  parseItemToProduct: i => i,
  parseExternalPromotionToGenericPromotion: i => i,
}

const env = process.env.REACT_APP_ENV === 'staging' ? 'sta' : process.env.REACT_APP_ENV

export const analyticsInstance = new AnalyticsService(config, 'ovid', env)
