export const ftthResponse = {
  gescal: '29000230241000002         001DCHA    ',
  coverage: [
    {
      ull: [],
      ftth: [
        '100M_10M',
        '200M_200M',
        '200M_20M',
        '300M_300M',
        '30M_30M',
        '320K_320K',
        '35M_5M',
        '50M_5M',
        '100M_100M',
        '300M_30M',
        '50M_50M',
        '1000M_300M',
      ],
      characteristics: [
        { name: 'territoryOwner', value: 'RMDMM01' },
        {
          name: 'speedEstimated',
          value:
            '100M_10M || 200M_200M || 200M_20M || 300M_300M || 30M_30M || 320K_320K || 35M_5M || 50M_5M || 100M_100M || 300M_30M || 50M_50M || 1000M_300M',
        },
        { name: 'addressId', value: 'J29000230241000002         001DCHA' },
        { name: 'telefonicaCoverage', value: 'false' },
      ],
    },
  ],
  phoneNumber: 'undefined',
  province: 'MALAGA',
  town: 'Malaga',
  streetType: 'Calle',
  street: 'Niña de Antequera',
  number: '2',
  bis_duplicate: '',
  zipCode: '29006',
  block: '',
  stair: '',
  door: '',
  letter: '',
  floor: '001',
  Hand: 'DCHA',
  Km: '',
  addressId: 'J29000230241000002         001DCHA',
  sessionId: 'e16ee8c5-d5e2-43ec-8230-c327de69f79b',
}
