import { useMemo } from 'react'
import { find } from 'lodash'
import PropTypes from 'prop-types'
import { ActionAdvice } from 'components/ui'
import { CircularProgress, Box } from '@material-ui/core'
import { SIGNUP_TYPE_PORTABILITY } from 'services/global-constants/index'
import { InputICC } from '../PortabilityComponent/components/InputICC'
import { LineSignupType } from './LineSignupType'

export function Line(props) {
  const {
    onSignUpTypeChange,
    signUpType,
    fieldNames,
    lineOptions,
    signUpMobile,
    children,
    isElFijo,
    isPos,
    is2p,
    isMobileOnly,
    isLeadFilled,
    disableOfferEdit,
    is2pPure,
    ps,
    isMultiSim,
  } = props
  const line = useMemo(() => find(lineOptions, option => option.value === signUpType) || {}, [
    signUpType,
  ])

  return (
    <>
      <Box display="flex" flexWrap="wrap" alignItems="center">
        <LineSignupType
          fieldNames={fieldNames}
          lineOptions={lineOptions}
          onSignUpTypeChange={onSignUpTypeChange}
          disabled={disableOfferEdit}
          isMobileOnly={isMobileOnly}
          disableOfferEdit={disableOfferEdit}
          signUpType={signUpType}>
          {signUpType && (
            <line.component
              isMobileOnly={isMobileOnly}
              disableOfferEdit={disableOfferEdit}
              isLeadFilled={isLeadFilled}
              className="full-width"
              fieldNames={fieldNames}
              {...props}
            />
          )}
        </LineSignupType>

        {(isElFijo || isPos || is2p || isMultiSim) && (
          <Box display="flex" flexWrap="wrap" alignItems="start" pl="20px">
            {(isElFijo || (is2p && !is2pPure && ps)) && (
              <>
                {signUpMobile.isLoading ? (
                  <CircularProgress className="p-x-16" size="16px" />
                ) : (
                  <Box display="flex" alignSelf="center">
                    <ActionAdvice
                      className="m-l-12 m-r-20 m-b-12"
                      type={signUpMobile.isError ? 'error' : 'info'}
                      icon={signUpMobile.isError ? 'error' : null}
                      dataHook="provisionedMobileNumber"
                      message={
                        signUpMobile.isError
                          ? 'Nº Móvil no asignado'
                          : `Nº Móvil asignado: ${signUpMobile.msg}`
                      }
                    />
                  </Box>
                )}
              </>
            )}

            {((isPos && (!isMobileOnly || disableOfferEdit)) || isMultiSim) && (
              <Box className="icc-wrap" flexGrow={0.2} height="100px">
                <InputICC
                  fieldNames={fieldNames}
                  checkWithCatalog={isElFijo || line.value === SIGNUP_TYPE_PORTABILITY}
                  noObtainPrefix
                />
              </Box>
            )}
          </Box>
        )}
      </Box>
      {children}
    </>
  )
}

Line.propTypes = {
  // Action creators (mobile line)
  onSignUpTypeChange: PropTypes.func,
  fieldNames: PropTypes.object,
  title: PropTypes.string,
  lineOptions: PropTypes.array,
  signUpType: PropTypes.string,
  isNewTerminal: PropTypes.bool,
  lineType: PropTypes.string,
  mobileValues: PropTypes.object,
  channel: PropTypes.string,
  isMobile: PropTypes.bool,
  stock: PropTypes.number,
  selectedTerminal: PropTypes.object,
  onModelChange: PropTypes.func,
  mobileLineType: PropTypes.string,
  signUpMobile: PropTypes.any,
  className: PropTypes.string,
  children: PropTypes.any,
  isElFijo: PropTypes.bool,
  isPos: PropTypes.bool,
  is2p: PropTypes.bool,
  isMobileOnly: PropTypes.bool,
  disableOfferEdit: PropTypes.bool,
  isLeadFilled: PropTypes.bool,
  is2pPure: PropTypes.bool,
  ps: PropTypes.string,
  isMultiSim: PropTypes.bool,
}
