import { constants } from './constants'

const getDiscountsInit = (orderId, profiles) => ({
  type: constants.GET_DISCOUNTS_INIT,
  payload: {
    orderId,
    profiles,
  },
})

const getDiscounts = orderId => ({
  type: constants.GET_DISCOUNTS,
  payload: {
    orderId,
  },
})

const getDiscountsSuccess = (discounts, orderId) => ({
  type: constants.GET_DISCOUNTS_SUCCESS,
  payload: {
    discounts,
    orderId,
  },
})

const getDiscountsFailed = (error, orderId) => ({
  type: constants.GET_DISCOUNTS_FAILED,
  payload: {
    error,
    orderId,
  },
})

export const actions = {
  getDiscountsInit,
  getDiscounts,
  getDiscountsSuccess,
  getDiscountsFailed,
}
