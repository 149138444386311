import { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { ButtonSwitch, LineItemCard } from 'components/ui'
import { PropTypes } from 'prop-types'
import { get, isEmpty } from 'lodash'
import { selectIsPosOrMasProximo } from 'modules/Permissions'

import {
  getBarringInfo,
  selectBarringIsOnGoing,
  DISABLED_BARRING_GROUPS,
  DISABLED_PRODUCT_BARRING_ID,
} from 'modules/barrings'
import { selectURLProductId } from 'services/customer-products'
import { isFixedNumber } from 'services/subscriptions/helpers'

export const Barring = styled(
  ({
    barring,
    className,
    removeBarringFromSummary,
    addBarringToSummary,
    resetBarringStatus,
    barringGroup,
  }) => {
    const [barringStatus, setBarringStatus] = useState(get(barring, 'status'))
    const productId = useSelector(selectURLProductId)
    const barringId = get(barring, 'id')

    const isPosOrMasProximo = useSelector(selectIsPosOrMasProximo)
    const isBarringOnGoing = useSelector(selectBarringIsOnGoing(productId, barringId))

    const isDisabled =
      isPosOrMasProximo ||
      DISABLED_PRODUCT_BARRING_ID.includes(barringId) ||
      DISABLED_BARRING_GROUPS.includes(barringGroup)

    const toggleBarringStatus = () => (barringStatus === 'active' ? 'inactive' : 'active')
    const handleOnClick = () => setBarringStatus(toggleBarringStatus)

    useEffect(() => {
      if (barring.status === barringStatus) {
        removeBarringFromSummary(barringId)
      } else {
        addBarringToSummary({ id: barringId, status: barringStatus })
      }
    }, [barringStatus])

    useEffect(() => {
      if (resetBarringStatus === barringId || resetBarringStatus === 'all') {
        setBarringStatus(get(barring, 'status'))
      }
    }, [resetBarringStatus])

    return (
      !isEmpty(barring) &&
      !isFixedNumber(productId) && (
        <div className={`${className} flex align-space-between align-start-center`}>
          <LineItemCard
            className="status-loading"
            primaryText={get(getBarringInfo(barringId), 'name')}
            secondaryText={
              isBarringOnGoing &&
              `En proceso de ${
                get(barring, 'status') === 'active' ? 'desactivación' : 'activación'
              }...`
            }
            fieldsApart
          />
          <div className="p-r-24">
            <ButtonSwitch
              disabled={isBarringOnGoing || isDisabled}
              checked={barringStatus === 'active'}
              onChange={handleOnClick}
              data-hook={`barring_id_${get(getBarringInfo(barringId), 'id')}`}
            />
          </div>
        </div>
      )
    )
  },
)`
  .status-loading {
    padding-right: 0px;
  }
  .secondary-text {
    color: ${props => get(props, 'theme.palette.brand.action_primary')};
  }
`

Barring.propTypes = {
  barring: PropTypes.object,
}
