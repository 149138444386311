import { axiosJWT } from 'modules/axios'
import { CONFIG } from './environments'

export function findProductOrders(productId, filterBy, customerId) {
  const url = CONFIG.api.productOrders
  let params = {
    [filterBy]: productId,
  }

  if (customerId) {
    params = {
      ...params,
      customerid: customerId,
    }
  }

  return axiosJWT({
    method: 'GET',
    url,
    params,
  }).then(resp => resp.data)
}
