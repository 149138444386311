import { createSelector } from 'reselect'
import { get } from 'lodash'

const selectInsertionTypifications = state => get(state, 'insertionTypifications')

export const selectInsertionTypificationsError = createSelector(
  selectInsertionTypifications,
  insertionTypifications => get(insertionTypifications, 'error'),
)

export const selectInsertionTypificationsErrorMessage = createSelector(
  selectInsertionTypificationsError,
  insertionTypificationsError => get(insertionTypificationsError, 'info'),
)

export const selectInsertionTypificationsLoading = createSelector(
  selectInsertionTypifications,
  insertionTypifications => get(insertionTypifications, 'loading'),
)

export const selectInsertionTypificationsSuccess = createSelector(
  selectInsertionTypifications,
  insertionTypifications => get(insertionTypifications, 'insertionSuccess'),
)
