/* eslint indent:0 */
import { get } from 'lodash'

const NoDataStr = '-'

export function formatDataContact(data) {
  const { email, contactPhones, billingAddressStr } = data
  return {
    mail: email,
    telephones: contactPhones.reduce((acum, obj) => {
      acum.push(get(obj, 'number'))
      return acum
    }, []),
    address: billingAddressStr,
  }
}
export function formatDataCardClients(dataCardClient) {
  const {
    name,
    surname,
    birthDayStr,
    identification,
    clientId,
    segmentStr,
    email,
    contactPhonesStr,
    billingAddressStr,
    langStr,
  } = dataCardClient

  return {
    name: {
      title: 'Nombre',
      value: name,
    },
    surnames: {
      title: 'Apellidos',
      value: surname,
    },
    identificationId: {
      title: get(identification, 'type', NoDataStr),
      value: get(identification, 'identificationId', NoDataStr),
    },
    clientId: {
      title: 'Id Cliente',
      value: clientId || NoDataStr,
    },
    birthDay: {
      title: 'Fecha de nacimiento',
      value: birthDayStr,
    },
    segment: {
      title: 'Segmento',
      value: segmentStr || NoDataStr,
    },
    email: {
      title: 'Email',
      value: email || NoDataStr,
    },
    telephone: {
      title: 'Teléfono de contacto',
      value: contactPhonesStr,
    },
    address: {
      title: 'Dirección',
      value: billingAddressStr,
    },
    languagueComunication: {
      title: 'Idioma de comunicación',
      value: langStr,
    },
  }
}
