import { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import { Box } from '@material-ui/core'
import { getIn, useFormikContext } from 'formik'

import { Button } from 'components/ui'
import { resetAllTariffsAction } from 'modules/tariffs/store/index'
import { selectTerminalsReservationInfo } from 'modules/NewClientSales/containers/OfferContainer/OfferContainer.selectors'
import { withCheckoutForms } from 'modules/NewClientSales/hocs/index'
import { setBasketData, setBasketTariff } from 'modules/NewClientSales/store'
import { Basket, MobileLineOffer } from 'modules/NewClientSales/store/models'
import { selectBasketRequestLoading } from 'modules/NewClientSales/store/selectors/index'
import { selectIsCarteraQueryParam, selectChannelQueryParam } from 'modules/Router/store/index'

import { reserveTerminalById } from 'modules/Terminals/services/TerminalReserveService/index'

import { TELESALES } from 'services/global-constants/index'
import { getChannelSoftReservation } from 'modules/NewClientSales/hooks/Terminals'

import { selectIsCustomerCanceled } from 'modules/CustomerInfo/store/index'
import { selectFriendPromoSales } from 'services/feature-flag/selectors'
import { INFO } from '../../../constants/index'
import { useSaleExit } from '../../../hooks/Shared/useSaleExit'
import SaveChangesButton from '../../Common/SaveChangesButton'
import { ExtraMobileLines } from '../ExtraMobileLines'
import { MainMobileLine } from '../MainMobileLine'
import { MemberGetMemberPromoContainer } from '../MemberGetMemberPromo'

export const SetupLines = withCheckoutForms(function SetupLines({
  navigateToNextStep,
  getCoverageData,
}) {
  const dispatch = useDispatch()

  const { goExitSale } = useSaleExit()

  const isLoading = useSelector(selectBasketRequestLoading)
  const { setFieldValue, values } = useFormikContext()
  const terminalsInfo = useSelector(() => selectTerminalsReservationInfo(values))
  const isCartera = useSelector(selectIsCarteraQueryParam)
  const channel = useSelector(selectChannelQueryParam)

  const isTelesales = useMemo(() => channel === TELESALES, [channel])

  const channelSoftReservation = useSelector(getChannelSoftReservation)

  const isCustomerCancelled = useSelector(selectIsCustomerCanceled)

  const friendPromoFlag = useSelector(selectFriendPromoSales)

  const shouldShowMemberGetMemberPromo =
    friendPromoFlag && (!isCartera || isCustomerCancelled) && isTelesales

  function onSaveData(ids) {
    const newData = { ...values }
    const finalData = {
      mainMobileLineOffer: values.mainMobileLineOffer,
      extraMobileLinesOffers: values.extraMobileLinesOffers,
    }

    terminalsInfo.forEach(({ id, field, type, pos }, index) => {
      setFieldValue(field, ids[index], true)
      const lineData = getIn(newData, id)
      const newLine = {
        ...lineData,
        terminal: {
          ...lineData.terminal,
          reservationId: ids[index],
        },
      }
      if (type === 'extraMobileLinesOffers') {
        if (!finalData.extraMobileLinesOffers) {
          finalData.extraMobileLinesOffers = []
        }
        finalData.extraMobileLinesOffers[pos] = {
          ...newLine,
        }
      } else {
        finalData.mainMobileLineOffer = newLine
      }
    })
    dispatch(setBasketData(finalData))
  }

  function onReserveTerminals() {
    return Promise.all(
      terminalsInfo
        .filter(terminalInfo => terminalInfo.stock > 0)
        .map(elem => reserveTerminalById(elem.terminalId, channelSoftReservation)),
    )
      .then(ids => {
        return onSaveData(ids)
      })
      .catch(() => {
        return onSaveData([])
      })
  }

  function resetAllTariffsAndBasket() {
    if (!isCartera) {
      dispatch(setBasketData(Basket))
    } else {
      dispatch(
        setBasketTariff({
          mainMobileLineOffer: MobileLineOffer,
        }),
      )
    }
    dispatch(resetAllTariffsAction())
  }

  function resetCoverageData() {
    getCoverageData({})
  }

  useEffect(() => {
    resetCoverageData()
  }, [])

  async function beforeContinue() {
    await onReserveTerminals()
  }

  return (
    <div>
      <MainMobileLine />
      <ExtraMobileLines />
      {shouldShowMemberGetMemberPromo && <MemberGetMemberPromoContainer />}
      <Box display="flex" alignItems="center">
        <Button
          margin="0 16px 0 0"
          secondary
          onClick={() => {
            goExitSale()
            resetAllTariffsAndBasket()
          }}
          data-hook="exit-sale">
          Volver
        </Button>
        <SaveChangesButton
          stepName="SetupMobileOnly"
          isLoading={isLoading}
          nomargin
          isOffer
          setBasketData={d => dispatch(setBasketData(d))}
          beforeContinue={beforeContinue}
          onContinue={() => navigateToNextStep(INFO)}
        />
      </Box>
    </div>
  )
})

SetupLines.propTypes = {
  disableOfferEdit: PropTypes.bool,
}
