import PropTypes from 'prop-types'
import styled from 'styled-components'
import { get } from 'lodash'

const ModWrapper = styled.div`
  margin-bottom: 25px;
`

const ModTitle = styled.h2`
  margin: 0 8px 12px;
  font-size: 16px;
  font-weight: bold;
  color: ${props => get(props, 'theme.palette.global.gray_dark')};
`

const ModItem = styled.div`
  background: #fcfcfc;
  border-radius: 10px;
  padding: 16px 21px;
  color: ${props => get(props, 'theme.palette.global.gray')};
  font-size: 12px;
  font-weight: bold;
  span {
    display: block;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.14;
    color: ${props => get(props, 'theme.palette.global.gray_dark')};
  }
  p {
    margin: 0;
  }
  p + p {
    margin: 13px 0 0;
  }
  & + & {
    margin-top: 24px;
  }
`

const SubscriptionsModifications = props => (
  <ModWrapper>
    <ModTitle>Vas a modificar:</ModTitle>
    {props.modifiedSubscriptions.length ? (
      props.modifiedSubscriptions.map(subscription => (
        <ModItem key={subscription.id}>
          <p>
            Número: <span>{subscription.id}</span>
          </p>
          <p>
            Cambio realizado: <span>{subscription.description}</span>
          </p>
          {Boolean(subscription.promoFee) && (
            <p>
              Cuota promocional: <span>{subscription.promoFee}</span>
            </p>
          )}
          {Boolean(subscription.fee) && (
            <p>
              Cuota{subscription.promoFee ? ' sin promoción' : ''}: <span>{subscription.fee}</span>
            </p>
          )}
          {Boolean(subscription.duration) && (
            <p>
              Permanencia: <span>{subscription.duration} meses máximo</span>
            </p>
          )}
          {subscription.penalty && (
            <p>
              Penalización: <span>{subscription.penalty} €</span>
            </p>
          )}
        </ModItem>
      ))
    ) : (
      <ModItem />
    )}
  </ModWrapper>
)

SubscriptionsModifications.propTypes = {
  modifiedSubscriptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    }),
  ),
}

export default SubscriptionsModifications
