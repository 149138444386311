import { axiosJWT, axiosCustomer } from 'modules/axios'
import { APP_CONFIG } from 'services/app-config'

export const fetchBonuses = () =>
  axiosJWT({
    method: 'GET',
    headers: {
      'X-Authorization': axiosCustomer.defaults.headers.common.Authorization,
    },
    url: `${APP_CONFIG.care_cluster}/search-360/catalog/bundles`,
  }).then(resp => resp.data)
