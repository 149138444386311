import { makeStyles } from '@material-ui/styles'
import PropTypes from 'prop-types'
import { get, head, isEmpty } from 'lodash'

import { Typography, Box, Divider } from '@material-ui/core'
import { TextStyles } from 'utils/text'

import { findOtherServicesPermanents, getFeesPendingFromPermanent } from 'services/subscriptions'

import { PermanencyTab } from './PermanencyTab'
import { PendingAndTotalFee } from './PendingAndTotalFee'

const useStyles = makeStyles({
  'txt-none': {
    'text-transform': 'none',
  },
})

export const PermanencyOtherServices = ({ className, subscription }) => {
  const classes = useStyles()
  const permanent = head(findOtherServicesPermanents(subscription))

  return permanent ? (
    <div className={className}>
      <Typography
        {...TextStyles.subtitle1Secondary({
          className: `${classes['txt-none']} p-b-12`,
        })}>
        Otros servicios
      </Typography>
      <div className="flex">
        {!isEmpty(permanent) && (
          <>
            <PermanencyTab validFor={permanent.validFor} totalMonths={permanent.length} />

            <Box mt="8px">
              <Divider orientation="vertical" />
            </Box>

            <PendingAndTotalFee
              className="p-l-24"
              title="Penalización"
              pendingFee={getFeesPendingFromPermanent(permanent)}
              totalFee={get(permanent, 'fees.penalty', 0)}
            />
          </>
        )}
        <Box mt="8px">
          <Divider orientation="vertical" />
        </Box>
      </div>
    </div>
  ) : null
}

PermanencyOtherServices.propTypes = {
  className: PropTypes.string,
  subscription: PropTypes.object.isRequired,
}
