import { useSelector } from 'react-redux'

import { get, uniq } from 'lodash'
import { formatMonthlyFee } from 'utils'
import { applyTax } from 'services/taxes'
import { getConvergentRelatedMobileTariff } from 'modules/tariffs/store-apigee'
import { selectTariffsApigeeById } from 'modules/tariffs/store-apigee/tariffs-apigee.selectors'
import {
  is2pTariff,
  is2pFreeLineTariff,
  isElFijoTariff,
} from 'modules/tariffs/store-apigee/tariffs-apigee.helpers'
import { selectEnabledNewClientSale } from 'services/feature-flag/selectors'
import {
  getCompatibleCurrentDiscounts,
  getCompatibleCurrentDiscountsModificationView,
} from '../selectors'
import { applyDiscount, getOtherFeesCvm, getMonthlyFeeTariff } from '../helpers'
import { UNLIMITED_DURATION } from '../constants'

import {
  applyDiscounts,
  byAscendingDuration,
  generateEmptyDiscounts,
  getTariffFee,
  normalizeCurrentDiscount,
} from './summary.helpers'

export const generateFeeSummary = (state, { subscriptions, modifications, tax }) => {
  const allDiscounts = subscriptions.reduce((all, subscription) => {
    const { confirmedDiscounts = [] } = get(modifications, subscription.id, {})

    const confirmed = confirmedDiscounts.map(discount => ({ id: subscription.id, ...discount }))
    const compatibleCurrentDiscounts = getCompatibleCurrentDiscounts(state, subscription, {
      selectedDiscounts: confirmed,
    })
      .map(normalizeCurrentDiscount)
      .filter(({ duration }) => duration >= 0 || duration === UNLIMITED_DURATION)
    return [...all, ...compatibleCurrentDiscounts, ...confirmed]
  }, [])

  const discountsWithFiniteDuration = allDiscounts.filter(d => d.duration !== UNLIMITED_DURATION)
  const discountsForever = allDiscounts.filter(d => d.duration === UNLIMITED_DURATION)

  const periods = uniq([
    ...discountsWithFiniteDuration.sort(byAscendingDuration).map(d => d.duration),
    0,
  ])

  const appliedDiscounts = subscriptions.reduce((acc, subscription) => {
    const { confirmedTariff } = get(modifications, subscription.id, {})
    const fee = getTariffFee(confirmedTariff || subscription)
    const feeWithForeverDiscountsApplied = discountsForever.reduce(
      (partialFee, d) => (d.id === subscription.id ? applyDiscount(partialFee, d) : partialFee),
      fee,
    )
    const discounts = discountsWithFiniteDuration
      .filter(d => d.id === subscription.id)
      .concat(generateEmptyDiscounts(periods))
      .sort(byAscendingDuration)
    return [...acc, ...applyDiscounts(feeWithForeverDiscountsApplied, discounts)]
  }, [])

  return periods.map(duration => ({
    monthlyFee: formatMonthlyFee(
      applyTax(
        tax,
        appliedDiscounts
          .filter(dis => dis.duration === duration)
          .reduce((sum, { monthlyFee }) => sum + monthlyFee, 0),
      ),
    ),
    duration,
  }))
}

export const useFeeSummary = (subscriptions, modifications, tax) => {
  const feeSummary = useSelector(state =>
    generateFeeSummary(state, { subscriptions, modifications, tax }),
  )

  return {
    feeSummary,
  }
}

export const generateRawFeeSummaryForSubscription = (
  state,
  {
    subscription: subscriptionIn,
    modifications,
    tax,
    taxNeeded,
    tariffs,
    withoutTerminalFee = false,
  },
) => {
  const subscriptions = subscriptionIn ? [subscriptionIn] : []
  const prices = get(subscriptionIn, 'prices.data')
  const tariffId = get(subscriptionIn, 'type')
  const tariff = selectTariffsApigeeById(tariffId)(state)
  const is2pOrFijo = is2pTariff(tariff) || is2pFreeLineTariff(tariff) || isElFijoTariff(tariff)

  const subsFee = getTariffFee(subscriptionIn)
  const subsFeeWithTax = applyTax(tax, subsFee)

  const allDiscounts = subscriptions.reduce((all, subscription) => {
    const { confirmedDiscounts = [], incompatibleDiscounts = [] } = get(
      modifications,
      subscription.id,
      {},
    )

    const confirmed = confirmedDiscounts.map(discount => ({
      id: subscription.id,
      ...discount,
      prices,
      is2pOrFijo,
    }))
    const compatibleCurrentDiscounts = getCompatibleCurrentDiscountsModificationView(
      state,
      subscription,
      {
        selectedDiscounts: confirmed,
      },
    )
      .map(normalizeCurrentDiscount)
      .filter(({ discountCode }) => !incompatibleDiscounts.includes(discountCode))
      .filter(({ duration }) => duration >= 0 || duration === UNLIMITED_DURATION)
    return [...all, ...compatibleCurrentDiscounts, ...confirmed]
  }, [])

  const discountsWithFiniteDuration = allDiscounts.filter(d => d.duration !== UNLIMITED_DURATION)
  const discountsForever = allDiscounts.filter(d => d.duration === UNLIMITED_DURATION)

  const periods = uniq([
    ...discountsWithFiniteDuration.sort(byAscendingDuration).map(d => d.duration),
    ...discountsForever.sort(byAscendingDuration).map(d => d.duration),
    0,
  ])

  const appliedDiscounts = subscriptions.reduce((acc, subscription) => {
    const relatedMobileTariff = getConvergentRelatedMobileTariff(tariffId, tariffs)

    const { confirmedTariff } = get(modifications, subscription.id, {})
    const fee = getMonthlyFeeTariff(confirmedTariff || subscription)
    const feeWithForeverDiscountsApplied = discountsForever.reduce(
      (partialFee, d) => (d.id === subscription.id ? applyDiscount(partialFee, d) : partialFee),
      fee,
    )
    const discounts = discountsWithFiniteDuration
      .filter(d => d.id === subscription.id)
      .concat(generateEmptyDiscounts(periods))
      .sort(byAscendingDuration)

    return [
      ...acc,
      ...applyDiscounts(feeWithForeverDiscountsApplied, discounts, prices, relatedMobileTariff),
    ]
  }, [])

  const enabledTaxAgileTv = selectEnabledNewClientSale(state)

  const otherFees = getOtherFeesCvm(
    subscriptionIn,
    tax,
    withoutTerminalFee,
    taxNeeded,
    enabledTaxAgileTv,
  )

  return {
    rawFeeSummary: periods.map(duration => {
      const monthlyFeeCalc = applyTax(
        tax,
        appliedDiscounts
          .filter(dis => dis.duration === duration)
          .reduce((sum, { monthlyFee }) => sum + monthlyFee, 0),
      )

      return {
        monthlyFee: monthlyFeeCalc + otherFees,
        savings: subsFeeWithTax - monthlyFeeCalc,
        duration,
      }
    }),
  }
}
