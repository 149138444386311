import { SpinnerCenter } from 'components/ui'
import styled from 'styled-components'

export const CardContentLoading = styled(props => {
  return (
    <div className={props.className}>
      <div className="spinner-container">
        <SpinnerCenter showMsg absolute style={{ marginTop: '40px' }} />
      </div>
    </div>
  )
})`
  > .spinner-container {
    position: relative;
    min-height: 25vh;
  }

  > .spinner-container {
    & .circular-progress-center {
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
    }
  }
`
