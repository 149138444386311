import { APP_CONFIG } from 'services/app-config'

const URL_PATH_EXTERNAL_LOGIN = 'account/externallogin'
const URL_QUERY_PARAM_ID_TOKEN = 'acctkn'

const generateIframeUrl = token =>
  `${APP_CONFIG.atc}/${URL_PATH_EXTERNAL_LOGIN}?${URL_QUERY_PARAM_ID_TOKEN}=${token}`

export const helpers = {
  generateIframeUrl,
}
