import { axiosJWT } from 'modules/axios'
import { CONFIG } from './environments'

export const getDiscounts = orderId =>
  axiosJWT({
    method: 'GET',
    url: `${CONFIG.api.discounts}/?state=Pending&orderId=${orderId}`,
  }).then(resp => resp.data)

export const putDiscounts = (orderId, discounts) =>
  axiosJWT({
    method: 'PUT',
    url: `${CONFIG.api.discounts}/?orderId=${orderId}`,
    data: discounts,
  }).then(resp => resp.data)
