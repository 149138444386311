import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { useDispatch, useSelector } from 'react-redux'

import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import { Button } from 'components/ui'

import { getPoncInitAction } from 'modules/ponc'
import { fetchOrderAdditionalLinesAction } from 'modules/orders/store/orders.actions'
import { CANCELLATION_FLOW_TOGETHER } from 'modules/orders/constants'
import { selectCancelOrderButtonData } from './selectors'

const useStyles = makeStyles({
  small: {
    marginTop: '10px',
    maxWidth: '200px',
    textAlign: 'center',
    display: 'block',
    color: 'red',
  },
})
export const CancelOrdersButton = ({ docType, docNumber, orderId, onClick }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [isLoaded, setIsLoaded] = useState(false)
  const { isLoading, isCancellable, isValid, hasError, flow } = useSelector(
    selectCancelOrderButtonData,
  )

  useEffect(() => {
    if (docNumber && docType && !isLoaded && !!flow && isCancellable) {
      setIsLoaded(true)

      if (flow === CANCELLATION_FLOW_TOGETHER) {
        dispatch(getPoncInitAction(orderId))
      }

      dispatch(fetchOrderAdditionalLinesAction(docType, docNumber, orderId))
    }
  }, [docNumber, docType, flow, isLoaded, isCancellable])

  return (
    <Box
      display="flex"
      p="20px 0 0 20px"
      flexDirection="column"
      justifyContent="center"
      alignItems="center">
      <Button
        className="orderActions__cancelOrder"
        disabled={isLoading || hasError || !isValid || !isCancellable}
        onClick={onClick}
        data-hook="btnCancelOrder rounder">
        Cancelación de pedidos
      </Button>
      {!isLoading && !hasError && (!isValid && isCancellable) ? (
        <span className={classes.small}>Punto de no cancelación alcanzado</span>
      ) : null}
      {hasError ? <span className={classes.small}>Ha sucedido un error</span> : null}
    </Box>
  )
}

CancelOrdersButton.propTypes = {
  onClick: PropTypes.func,
  orderId: PropTypes.string,
  docType: PropTypes.string,
  docNumber: PropTypes.string,
}
