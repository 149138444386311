import { axiosJWT } from 'modules/axios'
import { APP_CONFIG } from 'services/app-config'

const stockApiUrl = `${APP_CONFIG.sales_cluster}/v1/terminals/stock`

export function fetchTerminalStock(id, channel = '02') {
  return axiosJWT({
    method: 'GET',
    url: `${stockApiUrl}?warehouseId=MEYGD01&channel=${channel}&productId=${id}`,
  })
    .then(resp => resp.data)
    .then(data => {
      if (data.length === 0) {
        return 0
      }
      return data[0].available || data[0].virtual
    })
    .catch(err => {
      const stockError = {
        name: 'stockError',
        data: err,
      }
      throw stockError
    })
}
