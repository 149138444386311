export const NIF = 'NIF'
export const NIE = 'NIE'
export const CIF = 'CIF'
export const MSISDN = 'MSISDN'
export const CLI = 'CLI'
export const UNKNOWN = 'UNKNOWN'
export const PASSPORT = 'Passport'

export const PASSPORT_LABEL = 'PASAPORTE'

export const documentTypes = { NIF, NIE, CIF, MSISDN, CLI, PASSPORT }
