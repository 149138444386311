import { APP_CONFIG } from 'services/app-config'
import { axiosJWT } from 'modules/axios'

const financingConditionsURL = `${APP_CONFIG.sales_cluster}/v1/legal-agreements/financingConditions`

export function fetchFinancingConditions(params) {
  return axiosJWT({
    method: 'GET',
    url: `${financingConditionsURL}`,
    params,
  }).then(res => res.data)
}
