import { put, select, take } from 'redux-saga/effects'
import { includes } from 'lodash'

import {
  fetchContentfulResourceAction,
  FETCH_CONTENTFUL_RESOURCE_SUCCESS,
  FETCH_CONTENTFUL_RESOURCE_ERROR,
} from './contentful.actions'
import { selectContentfulResourceError } from './contentful.selectors'

export function* callContentfulSaga(resource, params) {
  yield put(fetchContentfulResourceAction(resource, params))
  yield take(
    action =>
      includes([FETCH_CONTENTFUL_RESOURCE_SUCCESS, FETCH_CONTENTFUL_RESOURCE_ERROR], action.type) &&
      action.payload.resource &&
      action.payload.resource === resource,
  )
  return yield select(selectContentfulResourceError(resource))
}
