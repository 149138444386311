import { constants } from './constants'

const initialState = {
  error: null,
  loading: false,
}

export const reducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case constants.GET_TERRIT_COMPAT_INIT: {
      return { ...initialState, payload, loading: true }
    }
    case constants.GET_TERRIT_COMPAT_SUCCESS: {
      return { ...state, territCompat: payload, loading: false }
    }
    case constants.GET_TERRIT_COMPAT_ERROR: {
      return {
        ...state,
        error: payload.error,
        loading: false,
      }
    }
    default:
      return state
  }
}
