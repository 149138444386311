import PropTypes from 'prop-types'
import styled from 'styled-components'
import { get, isEmpty } from 'lodash'
import { TableHead, TableBody, TableRow, Typography } from '@material-ui/core'
import { TextStyles } from 'utils/text'
import { history } from 'modules/Router'
import { ThemedTable, ThemedCellHeader, ThemedTableRow, ThemedCell } from 'components/ui'
import { formatDate, formatHourFromDate } from 'utils/formatting/dates'
import { Card, SectionHeader } from 'modules/ui'
import { formatNumberWithComma } from 'utils'

export const PrepaidLineDetail = styled(
  ({ className, isLoading, errors, prepaidLineEvents, productId }) => {
    const titleAndSubtitle = (
      <>
        <Typography
          {...TextStyles.title3Dark({
            className: 'title',
          })}>
          Histórico recargas
        </Typography>
        <Typography {...TextStyles.subtitle2Dark()}>{productId}</Typography>
      </>
    )
    return (
      <div className={className}>
        <SectionHeader title="" titleExt={titleAndSubtitle} onBack={() => history.goBack()} />
        <div className="p-x-24 p-y-24">
          <Card
            title="Detalle de recargas"
            id="Detalle"
            withShadow
            loading={isLoading}
            error={!isEmpty(errors)}
            empty={!isLoading && isEmpty(errors) && isEmpty(prepaidLineEvents)}>
            {!isLoading && isEmpty(errors) && !isEmpty(prepaidLineEvents) && (
              <div className="wrapper">
                <ThemedTable>
                  <TableHead>
                    <ThemedTableRow>
                      <ThemedCellHeader align="center">FECHA</ThemedCellHeader>
                      <ThemedCellHeader align="center">HORA</ThemedCellHeader>
                      <ThemedCellHeader align="center">TIPO SOLICITUD</ThemedCellHeader>
                      <ThemedCellHeader align="center">ORIGEN</ThemedCellHeader>
                      <ThemedCellHeader align="center">REFERENCIA</ThemedCellHeader>
                      <ThemedCellHeader align="center">
                        IMPORTE
                        <br />
                        (Imp. incluidos)
                      </ThemedCellHeader>
                    </ThemedTableRow>
                  </TableHead>
                  <TableBody>
                    {prepaidLineEvents.map(event => (
                      <TableRow key={`${event.entity}`}>
                        <ThemedCell align="center">
                          <Typography {...TextStyles.subtitle2Dark()}>
                            {formatDate(event.date)}
                          </Typography>
                        </ThemedCell>
                        <ThemedCell align="center">
                          <Typography {...TextStyles.labelDark()}>
                            {formatHourFromDate(event.date)} h
                          </Typography>
                        </ThemedCell>
                        <ThemedCell align="center">
                          <Typography {...TextStyles.labelDark()}>{event.request_type}</Typography>
                        </ThemedCell>
                        <ThemedCell align="center">
                          <Typography {...TextStyles.labelDark()}>{event.origin}</Typography>
                        </ThemedCell>
                        <ThemedCell align="center">
                          <Typography {...TextStyles.labelDark()}>{event.reference}</Typography>
                        </ThemedCell>
                        <ThemedCell className="no-border" align="center">
                          <Typography {...TextStyles.subtitle2Dark()}>
                            {`${formatNumberWithComma(
                              get(event, 'amount_without_vat') + get(event, 'taxes'),
                            )} €`}
                          </Typography>
                        </ThemedCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </ThemedTable>
              </div>
            )}
          </Card>
        </div>
      </div>
    )
  },
)`
  .title {
    font-size: 24px;
  }
  .content {
    padding: 24px;
  }
  .wrapper {
    padding: 24px;
    tbody {
      overflow-y: auto;
      max-height: 40vh;
    }
  }
  thead {
    width: 100%;
  }
  tr > td:last-child.no-border {
    border-left: none;
  }
  && td:first-child {
    padding: 12px;
  }
`

PrepaidLineDetail.propTypes = {
  productId: PropTypes.string,
}
