import PropTypes from 'prop-types'
import styled from 'styled-components'
import { get } from 'lodash'
import { parseISO } from 'date-fns'

import { Typography } from '@material-ui/core'
import { TextStyles } from 'utils/text'

import { getDurationFromRange } from 'utils/durations'
import { formatTimeUnit, TIME_UNITS, formatRange } from 'utils'

import { DurationMonthsDays } from './DurationMonthsDays'

export const PermanencyTab = styled(
  ({ className, validFor, totalMonths, residualMonth, financial }) => {
    const today = Date.now()
    const startDate = parseISO(get(validFor, 'startDateTime'))
    const endDate = parseISO(get(validFor, 'endDateTime'))

    return (
      <div className={className}>
        {financial ? (
          <Typography {...TextStyles.subtitle2Dark()}>Pago final prorrateado:</Typography>
        ) : (
          <Typography {...TextStyles.subtitle2Dark()}>Permanencia:</Typography>
        )}

        <div className="durations-line flex">
          {financial ? (
            <>
              <Typography {...TextStyles.title1BoldDark()}>{residualMonth}</Typography>
              <Typography {...TextStyles.title2MediumBoldDark()}>
                <strong>&nbsp;meses&nbsp;/&nbsp;</strong>
              </Typography>
            </>
          ) : (
            <>
              <DurationMonthsDays
                className="p-y-4"
                duration={getDurationFromRange(endDate, today)}
              />
              <Typography {...TextStyles.title2MediumBoldDark()}>
                <strong>&nbsp;/&nbsp;</strong>
              </Typography>
            </>
          )}

          <Typography {...TextStyles.title2MediumBoldDark()}>
            <strong>{`${totalMonths} ${formatTimeUnit(totalMonths, TIME_UNITS.MONTHS.id)}`}</strong>
          </Typography>
        </div>

        <Typography {...TextStyles.labelDark()} className="p-t-4">
          ({formatRange(startDate, endDate)})
        </Typography>
      </div>
    )
  },
)`
  width: 300px;

  .durations-line {
    align-items: baseline;
  }
  .durations-line .textstyle {
    white-space: nowrap;
  }
`

PermanencyTab.propTypes = {
  className: PropTypes.string,
  timeData: PropTypes.object,
}
