import PropTypes from 'prop-types'
import { get, orderBy } from 'lodash'
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'
import {
  ButtonLink,
  ThemedTable,
  ThemedCellHeader,
  ThemedTableRow,
  ThemedCell,
  SubscriptionStatusBadge,
} from 'components/ui'
import { Grid, TableHead, TableBody, TableRow, Typography } from '@material-ui/core'
import { TextStyles } from 'utils/text'
import { formatDate } from 'utils/formatting/dates'
import { Card } from 'modules/ui'
import { APP_CONFIG } from 'services/app-config'

import { Board } from '../../components/Board'

const Wrapper = styled.div`
  tbody {
    overflow-y: auto;
    max-height: 40vh;
  }
`

export const ContractsOrders = styled(
  ({ dataHistoricOrders, className, searchKey, isPosOrMasProximo }) => {
    const dataHistoricOrderByDate = orderBy(dataHistoricOrders, ['orderDate'], ['desc'])
    const location = useLocation()

    const exitToNewton = (
      <ButtonLink
        iconType="exitToApp"
        onClick={() =>
          window.open(`${APP_CONFIG.cancelled_clients}?searchkey=${searchKey}`, '_blank')
        }
        disabled={isPosOrMasProximo}
      />
    )

    return (
      <Board className={className}>
        <Grid item xs={12}>
          <Card title="Pedidos" id="Pedidos" withShadow>
            <Wrapper className="p-24">
              <ThemedTable>
                <TableHead>
                  <ThemedTableRow>
                    <ThemedCellHeader>PEDIDOS ID</ThemedCellHeader>
                    <ThemedCellHeader align="center" className="product-number-row">
                      SUSCRIPCIÓN
                    </ThemedCellHeader>
                    <ThemedCellHeader align="left" className="billId">
                      ESTADO
                    </ThemedCellHeader>
                    <ThemedCellHeader align="center">TIPO</ThemedCellHeader>
                    <ThemedCellHeader align="center">FECHA CREACIÓN</ThemedCellHeader>
                    <ThemedCellHeader align="center">ÚLTIMA MODIFICACIÓN</ThemedCellHeader>
                    <ThemedCellHeader align="center" />
                  </ThemedTableRow>
                </TableHead>
                <TableBody>
                  {dataHistoricOrderByDate.map(el => (
                    <TableRow key={get(el, 'id')}>
                      <ThemedCell className="no-bold">
                        <Typography {...TextStyles.subtitle2Dark()}>{get(el, 'id')}</Typography>
                      </ThemedCell>
                      <ThemedCell align="left" className="product-number-row">
                        <Typography {...TextStyles.labelDark()}>
                          {get(el, 'productNumber')}
                        </Typography>
                      </ThemedCell>
                      <ThemedCell align="left">
                        <SubscriptionStatusBadge status={get(el, 'superStatus')} />
                      </ThemedCell>
                      <ThemedCell align="center">
                        <Typography {...TextStyles.labelDark()}>{get(el, 'type')}</Typography>
                      </ThemedCell>
                      <ThemedCell align="center">
                        <Typography {...TextStyles.labelDark()}>
                          {formatDate(get(el, 'orderDate'))}
                        </Typography>
                      </ThemedCell>
                      <ThemedCell align="center">
                        <Typography {...TextStyles.labelDark()}>
                          {formatDate(get(el, 'orderDate'))}
                        </Typography>
                      </ThemedCell>
                      <ThemedCell align="center">
                        <ButtonLink
                          iconType="forward"
                          to={`${location.pathname}/orderDetail/${get(el, 'id')}`}
                        />
                      </ThemedCell>
                    </TableRow>
                  ))}
                </TableBody>
              </ThemedTable>
            </Wrapper>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card
            className="card-cancelled-subscriptions"
            title="Suscripciones dadas de baja"
            withShadow
            childrenInHeader={exitToNewton}
          />
        </Grid>
      </Board>
    )
  },
)`
  tr > td:last-child,
  th:last-child {
    width: 5vw;
    padding: 0;
    *[data-icon^='icon-'] {
      cursor: pointer;
    }
  }

  && td:first-child {
    padding: 12px 12px 12px 20px;
  }

  .card-cancelled-subscriptions .card-content {
    display: none;
  }

  .product-number-row {
    width: 200px;
  }
`

ContractsOrders.propTypes = {
  data: PropTypes.array,
  isPosOrMasProximo: PropTypes.bool,
}
