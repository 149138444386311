import { get, isEmpty, find, chain } from 'lodash'
import { selectSfid, selectIsCustomer } from 'modules/Auth/store/selectors'
import { createSelector } from 'reselect'
import {
  selectDoctorGoFlag,
  selectHebeSvaClientFlag,
  selectSvaHomeGoOnflyFlag,
  selectHideHebeSvaFlag,
  selectSvaHomeGoFlag
} from 'services/feature-flag/selectors'
import { isProCustomer } from 'services/customer-360/helpers'
import {
  getSegment,
  selectors as customer360selectors,
  getProducts,
} from 'services/customer-360/selectors'
import { getTax, applyTax } from 'services/taxes'
import { REGEX_SFIDS_ALLOWED_CANCEL_HOMEGO } from 'modules/Permissions/constants'
import { parseISO, isPast, startOfDay } from 'date-fns'
import {
  SVA_DOCTORGO_CUSTOMER_CODE,
  SVA_HEBE_CUSTOMER_CODE,
} from 'modules/offers-configuration/constants'
import { constants } from './constants'
import { mapSvaSicorToVasContract } from './helpers'

const selectCustomerSvasData = state => get(state, 'customerSva.customerSvas.data') || []
const selectCustomerSvasLoading = state => get(state, 'customerSva.customerSvas.loading')
const selectCustomerSvasError = state => get(state, 'customerSva.customerSvas.error')

const selectDisableCustomerSvaData = state => get(state, 'customerSva.disableSva.data', {})
const selectDisableCustomerSvaLoading = state => get(state, 'customerSva.disableSva.loading')
const selectDisableCustomerSvaError = state => get(state, 'customerSva.disableSva.error')
const selectDisableCustomerSvaPending = state => get(state, 'customerSva.disableSva.disablePending')

const getPurchaseAlarmData = state => get(state, 'customerSva.purchaseSva.data')
const getPurchaseAlarmLoading = state => get(state, 'customerSva.purchaseSva.loading')
const getPurchaseAlarmError = state => get(state, 'customerSva.purchaseSva.error')

const getCancelHomeGoSvaOrderData = state => get(state, 'customerSva.cancelHomeGoSvaOrder')

export const selectHomeGoSvaData = state => get(state, 'customerSva.homeGoSva.data', {})
export const selectHomeGoSvaError = state => get(state, 'customerSva.homeGoSva.error')

export const selectHebeSvaCustomer = state => get(state, 'customerSva.hebe')

export const selectHebeSvaCustomerSuccess = createSelector([selectHebeSvaCustomer], hebeCustomer =>
  get(hebeCustomer, 'success'),
)

export const selectHebeSvaCustomerLoading = createSelector([selectHebeSvaCustomer], hebeCustomer =>
  get(hebeCustomer, 'loading'),
)

export const selectHebeSvaCustomerError = createSelector([selectHebeSvaCustomer], hebeCustomer =>
  get(hebeCustomer, 'error'),
)

const getBillingAddressTax = state => {
  const postCode = customer360selectors.getBillingAddressPostCode(state)

  return getTax(state, postCode)
}

export const selectActiveCustomerSvas = createSelector(
  [selectCustomerSvasData, getSegment, getBillingAddressTax, getProducts],
  (customerSvas, customerSegment, tax, products) =>
    customerSvas
      .filter(
        customerSva => !customerSva.endDate || !isPast(startOfDay(parseISO(customerSva.endDate))),
      )
      .map(sva => {
        const taxNeeded = !isProCustomer(customerSegment)
        const subscription = sva.subscriptionId
          ? chain(products)
              .flatten()
              .find({ subscriptionId: +sva.subscriptionId })
              .value()
          : null

        return {
          ...sva,
          monthlyFeeWithTax: taxNeeded ? applyTax(tax, sva.monthlyFee) : sva.monthlyFee,
          msisdn: subscription ? subscription.id : sva.msisdn,
        }
      }),
)

export const selectCustomerSvaDoctorGo = createSelector(selectActiveCustomerSvas, customerSvas =>
  find(customerSvas, { code: SVA_DOCTORGO_CUSTOMER_CODE }),
)

const selectHasDoctorGoSva = createSelector(
  [selectCustomerSvaDoctorGo],
  doctorGoCustomerSva => !isEmpty(doctorGoCustomerSva),
)

const selectCustomerSvaHebe = createSelector(selectActiveCustomerSvas, customerSvas =>
  find(customerSvas, { code: SVA_HEBE_CUSTOMER_CODE }),
)

export const selectHasHebeSva = createSelector(
  [selectCustomerSvaHebe],
  customeSvaHeve => !isEmpty(customeSvaHeve),
)

export const selectCustomerSvaLabel = createSelector(
  [selectHasDoctorGoSva, selectHasHebeSva, selectHideHebeSvaFlag],
  (hasDoctorGoSva, hasHebeSva, isHideHebeSva) => {
    if (hasDoctorGoSva) {
      return constants.CUSTOMER_SVA_RESPONSE_FIELDS_MAPPING.DOCTOR_GO
    }

    if (!isHideHebeSva && hasHebeSva) {
      return constants.CUSTOMER_SVA_RESPONSE_FIELDS_MAPPING.HEBE
    }

    return ''
  },
)

export const selectHasValidCustomerSva = createSelector(
  [selectCustomerSvaLabel],
  customerSvaLabel => !!customerSvaLabel,
)

export const selectHasCustomerSvaError = createSelector(
  [selectCustomerSvasError, selectHasDoctorGoSva, selectIsCustomer],
  (customerSvasError, hasDoctorGoSva, isCustomer) =>
    !hasDoctorGoSva && !!customerSvasError && isCustomer,
)

export const selectIsDisableSuccess = createSelector(
  selectDisableCustomerSvaData,
  selectDisableCustomerSvaLoading,
  selectDisableCustomerSvaError,
  (data, isLoading, error) => {
    return !isEmpty(data) && !isLoading && isEmpty(error)
  },
)

export const selectIsDisableError = createSelector(selectDisableCustomerSvaError, error => {
  return !isEmpty(error)
})

export const selectFindCustomerSvaFlag = createSelector(
  [selectSfid, selectDoctorGoFlag],
  (sfid, flag) => flag || 'YC007024_RPORT'.includes(sfid),
)

export const selectHebeClientFlag = createSelector(
  [selectSfid, selectHebeSvaClientFlag, selectHideHebeSvaFlag],
  (sfid, flag, isHideHebeSva) => (flag || 'YC007024_RPORT'.includes(sfid)) && !isHideHebeSva,
)

export const selectIsPurchaseAlarmSuccess = createSelector(
  getPurchaseAlarmData,
  getPurchaseAlarmLoading,
  getPurchaseAlarmError,
  (data, isLoading, error) => {
    return !isEmpty(data) && !isLoading && isEmpty(error)
  },
)

export const selectIsPurchaseAlarmLoading = createSelector(
  getPurchaseAlarmLoading,
  isLoading => !!isLoading,
)

export const selectIsPurchaseAlarmError = createSelector(
  getPurchaseAlarmError,
  error => error?.errorMessage || !isEmpty(error),
)

export const selectPurchaseAlarmErrorCode = createSelector(
  getPurchaseAlarmError,
  error => error?.code,
)

export const selectPurchaseAlarmErrorMsg = createSelector(
  getPurchaseAlarmError,
  error => error?.detailMsg,
)

export const selectHomeGoAlarmsSva = createSelector(
  selectHomeGoSvaData,
  getSegment,
  (homeGoSvas, clientSegment) => {
    const taxNeeded = !isProCustomer(clientSegment)

    return chain(homeGoSvas)
      .map((value, msisdn) => mapSvaSicorToVasContract({ ...value, msisdn }, taxNeeded))
      .value()
  },
)

export const selectHomeGoAlarmsSvaByMsisdn = (msisdn, subscriptionId, subscriptionMsisdn) =>
  createSelector(selectHomeGoSvaData, homeGoSvas => {
    let svaData = msisdn && get(homeGoSvas, msisdn)

    if ((!svaData || !svaData.subscriptionId) && !!subscriptionId) {
      svaData = get(homeGoSvas, subscriptionId)
    }

    if ((!svaData || !svaData.subscriptionId) && !!subscriptionMsisdn) {
      svaData = get(homeGoSvas, subscriptionMsisdn)
    }

    return svaData && mapSvaSicorToVasContract(svaData)
  })

export const selectAllowedCancelHomeGoFlag = createSelector(
  [selectSfid, selectSvaHomeGoFlag],
  (sfid, flag) => flag ? true : REGEX_SFIDS_ALLOWED_CANCEL_HOMEGO.test(sfid),
)

export const selectAllowedCancelOnflyHomeGoFlag = createSelector(
  [selectSfid, selectSvaHomeGoOnflyFlag],
  (sfid, flag) => flag && REGEX_SFIDS_ALLOWED_CANCEL_HOMEGO.test(sfid),
)

export const selectCancelHomeGoSvaOrderIsLoading = createSelector(
  getCancelHomeGoSvaOrderData,
  cancelHomeGoSvaOrderData => get(cancelHomeGoSvaOrderData, 'loading'),
)

export const selectCancelHomeGoSvaOrderError = createSelector(
  getCancelHomeGoSvaOrderData,
  cancelHomeGoSvaOrderData => get(cancelHomeGoSvaOrderData, 'error'),
)

export const selectors = {
  loading: selectCustomerSvasLoading,
  error: selectHasCustomerSvaError,
  hasValidCustomerSva: selectHasValidCustomerSva,
  customerSvaLabel: selectCustomerSvaLabel,
  disableLoading: selectDisableCustomerSvaLoading,
  disableError: selectDisableCustomerSvaError,
  disablePending: selectDisableCustomerSvaPending,
  hasDoctorGoSva: selectHasDoctorGoSva,
  hasHebeSva: selectHasHebeSva,
}
