import { call, put, takeLatest, select } from 'redux-saga/effects'
import { logError, formatErrorMessage } from 'services/logging'
import { axiosFactory } from 'modules/axios'

import { selectSfid } from 'modules/Auth'
import { selectIsExistingClientSaleFlow } from 'modules/NewClientSales/store/selectors/NewClientSales.selectors'
import { RESIDENTIAL } from 'modules/SharedSales/constants'

import { putCadenceAlarmSalesSuccess, putCadenceAlarmSalesFailed } from './actions'
import { PUT_ALARMS_SALES_START } from './constants'
import { putCadenceAlarmsSales } from './api'

export function* putCadenceAlarmsSalesSaga({
  payload: {
    contractId,
    workOrder,
    msisdn,
    subscriptionType,
    invoicingAddress,
    sendAddress,
    isCarteraFlow,
    segment,
  },
}) {
  const sfidCode = yield select(selectSfid)
  const isSalesCarteraFlow = yield select(selectIsExistingClientSaleFlow)

  try {
    const alarmData = {
      type: isSalesCarteraFlow || isCarteraFlow ? 'existingClient' : 'newSale',
      invoicingAddress,
      sendAddress,
      subscriptionType,
      msisdn,
      workOrder: `${workOrder}_SH`,
      contractId: isSalesCarteraFlow ? 'XXXXX' : contractId,
      segmentType: segment === RESIDENTIAL ? 1 : 2,
    }

    const response = yield call(putCadenceAlarmsSales, sfidCode, alarmData)

    if (response?.workflowId) {
      yield put(putCadenceAlarmSalesSuccess(response))
    } else {
      yield put(putCadenceAlarmSalesFailed(response))
    }
  } catch (e) {
    const error = axiosFactory.buildError(e)

    if (e.response?.data?.code) {
      error.errorCadence = e.response.data
    }

    yield call(logError, new Error(formatErrorMessage(e)))
    yield put(putCadenceAlarmSalesFailed(error))
  }
}

export function* watchPutCadenceAlarmsSales() {
  yield takeLatest(PUT_ALARMS_SALES_START, putCadenceAlarmsSalesSaga)
}
