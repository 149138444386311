import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { get, head, isEmpty, isNil } from 'lodash'
import { ListDivider, TabsContainer, SpinnerCenter } from 'components/ui'
import { SectionHeader } from 'modules/ui'
import { useRouteMatch } from 'react-router-dom'

import { isElFijoTariff } from 'modules/tariffs/helpers'
import { isFixedNumber } from 'services/subscriptions/helpers'

import { useCvmActions } from 'modules/offers-configuration/hooks'
import { useShowTypification } from 'modules/vista-360/hooks/useShowTypification'
import { getIsTypificationAllowed } from 'modules/offers-configuration/selectors'
import { useGetIframeData } from 'modules/typifications/hooks'
import { resetSecurizerFailed } from 'modules/typifications/store/actions/securizerTypifications.actions'
import { SECURIZER_ERROR } from 'modules/typifications/constants'

import { AlarmsContainer } from 'modules/alarms'
import { selectIsErrorSecurizerIframe } from 'modules/typifications/store/selectors/securizerTypifications.selectors'
import NotificationModal from 'components/packages/ui/modal/NotificationModal'
import { getTabCofig } from './tabsConfig'
import { canIViewDetailUseSubscription } from '../../helpers/permissions.helper'

const ELE_PADDING = '10px 20px'

export const ProductDashboard = styled(
  ({ isLoading, hasErrors, className, productId, actions, subscription }) => {
    const dispatch = useDispatch()

    const { openTypificationDispatch, openTypificationIframeDispatch } = useCvmActions()
    const { permissionIframeAccess, sfid, area, customerId, msisdns } = useGetIframeData()

    const [isSecurizerErrorModal, setIsSecurizerErrorModal] = useState(false)

    const isTypificationAllowed = useSelector(getIsTypificationAllowed)
    const isSecurizerError = useSelector(selectIsErrorSecurizerIframe)

    useEffect(() => {
      if(!isNil(isSecurizerError)) {
        setIsSecurizerErrorModal(isSecurizerError)
      }
    }, [isSecurizerError])
  
    const onNotificationClose = () => {
      setIsSecurizerErrorModal(false)
      dispatch(resetSecurizerFailed())
    }

  const actionIframeTipis = permissionIframeAccess ? [{
    label: 'Tipificación',
    onClick: () => openTypificationIframeDispatch({customerId, sfid, area, msisdns}),
    disabled: !isTypificationAllowed,
  }] : []

    const tabsConfig = getTabCofig(isFixedNumber(productId), canIViewDetailUseSubscription())
    const allActions = useShowTypification()
      ? [
          {
            label: 'Tipificar',
            onClick: openTypificationDispatch,
            disabled: !isTypificationAllowed,
          },
          ...actionIframeTipis,
          ...actions,
        ]
      : [...actionIframeTipis, ...actions]

    const getPackageTitle = product => {
      const match = useRouteMatch()
      const msisdn = get(match, 'params.id')
      const productsSpecifications = get(product, 'productsSpecifications')
      const fixedLine =
        productsSpecifications && head(productsSpecifications.filter(line => line.name === 'fixed'))
      return isElFijoTariff(get(product, 'type')) && !isEmpty(fixedLine)
        ? `${get(fixedLine, 'id')} (${get(product, 'id')})`
        : msisdn
    }

    return (
      <div className={className}>
        <SectionHeader title={getPackageTitle(subscription)} actions={allActions} />
        <ListDivider padding={ELE_PADDING} />
        <AlarmsContainer className="container-alarms p-x-24 p-t-24 p-b-12" />
        {/* unused modal
        {productId && caseOpened && (
          <TicketingModal
            isOpen={caseOpened}
            onClose={onCloseCase}
            selectedSubscription={productId}
          />
        )} */}
        {isSecurizerErrorModal && <NotificationModal   
          type="alert"
          src="/assets/error.svg"
          title={SECURIZER_ERROR}
          isOpen
          onClose={onNotificationClose} />}
        {isLoading && <SpinnerCenter showMsg />}
        {!isLoading && !hasErrors && (
          <TabsContainer
            tabsConfig={tabsConfig}
            queryParamName="tab"
            contentProps={{ productId }}
          />
        )}
      </div>
    )
  },
)`
  .container-alarms {
  }
`

ProductDashboard.propTypes = {
  isLoading: PropTypes.bool,
  hasErrors: PropTypes.bool,
  className: PropTypes.string,
  productId: PropTypes.string,
  caseOpened: PropTypes.bool,
  onCloseCase: PropTypes.func,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      onClick: PropTypes.func,
      disabled: PropTypes.bool,
    }),
  ),
  subscription: PropTypes.object,
}
