import PropTypes from 'prop-types'

import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  'list-divider': {
    backgroundColor: ({ color }) => color || theme.palette.global.gray,
    opacity: ({ opacity }) => opacity || 1,
  },
}))

const ListDivider = ({ color, opacity, padding }) => {
  const classes = useStyles({ color, opacity })

  return (
    <Box display="flex" p={padding} alignItems="center">
      <Box className={classes['list-divider']} height="1px" width="100%" />
    </Box>
  )
}

ListDivider.defaultProps = {
  padding: '8px 16px 8px 16px',
}

ListDivider.propTypes = {
  color: PropTypes.string,
  padding: PropTypes.string,
  opacity: PropTypes.string,
}

export default ListDivider
