import { SectionTitle } from 'components'
import styled from 'styled-components'

import Grid from '@material-ui/core/Grid'

import { SearchCustomerContainer } from './containers'

const StyledTitleGrid = styled(Grid)`
  && {
    width: 80%;
    margin: 20px auto;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }
`
const Clients = () => (
  <>
    <StyledTitleGrid item xs={12}>
      <SectionTitle width="auto" margin="auto">
        Client Dashboard
      </SectionTitle>
    </StyledTitleGrid>
    <SearchCustomerContainer />
  </>
)

export default Clients
