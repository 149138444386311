import PropTypes from 'prop-types'
import SubmenuItem from './SubmenuItem'

const CloseClient = props => {
  const { onClick } = props

  return <SubmenuItem text="Cerrar cliente" icon="close" onClick={onClick} primary />
}

CloseClient.defaultProps = {}

CloseClient.propTypes = {
  onClick: PropTypes.func,
}

export default CloseClient
