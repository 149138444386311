import { constants } from './device-renewal.constants'

export const retrieveSubscription = msisdn => ({
  type: constants.RETRIEVE_SUBSCRIPTION,
  payload: {
    msisdn,
  },
})

export const fetchDeviceRenewalAllowed = (
  channel,
  iban,
  msisdn,
  subscriptionId,
  phoneNumber,
  shouldValidateTv,
) => {
  return {
    type: constants.FETCH_DEVICE_RENEWAL_ALLOWED,
    payload: {
      channel,
      iban,
      msisdn,
      subscriptionId,
      phoneNumber,
      shouldValidateTv,
    },
  }
}

export const fetchDeviceRenewalAllowedSuccess = data => ({
  type: constants.FETCH_DEVICE_RENEWAL_ALLOWED_SUCCESS,
  payload: { data },
})

export const fetchDeviceRenewalAllowedFailed = () => ({
  type: constants.FETCH_DEVICE_RENEWAL_ALLOWED_FAILED,
})

export const resetDeviceRenewalAllowed = () => ({
  type: constants.RESET_DEVICE_RENEWAL_ALLOWED,
})

export const setDeviceRenewalData = payload => ({
  type: constants.SET_DEVICE_RENEWAL_DATA,
  payload,
})

export const fetchDevices = payload => ({
  type: constants.FETCH_DEVICE_RENEWAL_DEVICES,
  payload,
})

export const fetchDevicesSuccess = payload => ({
  type: constants.FETCH_DEVICE_RENEWAL_DEVICES_SUCCESS,
  payload,
})

export const fetchDevicesFailed = error => ({
  type: constants.FETCH_DEVICE_RENEWAL_DEVICES_FAILED,
  payload: { error },
})

export const resetDevices = () => ({
  type: constants.RESET_DEVICE_RENEWAL_DEVICES,
})

export const fetchDeviceSpecifications = id => ({
  type: constants.FETCH_DEVICE_SPECIFICATIONS,
  payload: { id },
})

export const fetchDeviceSpecificationsSuccess = (id, data) => ({
  type: constants.FETCH_DEVICE_SPECIFICATIONS_SUCCESS,
  payload: { id, data },
})

export const fetchDeviceSpecificationsFailed = (id, error) => ({
  type: constants.FETCH_DEVICE_SPECIFICATIONS_FAILED,
  payload: { id, error },
})

export const fetchFinancingConditions = (msisdn, renewalSaleChannel) => ({
  type: constants.FETCH_FINANCING_CONDITIONS,
  payload: { msisdn, renewalSaleChannel },
})

export const fetchFinancingConditionsSuccess = data => ({
  type: constants.FETCH_FINANCING_CONDITIONS_SUCCESS,
  payload: { data },
})

export const fetchFinancingConditionsError = error => ({
  type: constants.FETCH_FINANCING_CONDITIONS_FAILED,
  payload: { error },
})

export const resetFinancingConditions = () => ({
  type: constants.RESET_FINANCING_CONDITIONS,
})

export const generateOrder = () => ({
  type: constants.GENERATE_ORDER,
})

export const startPolling = task => ({
  type: constants.START_POLLING,
  payload: {
    taskId: task.taskId,
  },
})
export const retryPolling = retries => ({
  type: constants.RETRY_POLLING,
  payload: { retries },
})

export const generateOrderSuccess = result => ({
  type: constants.GENERATE_ORDER_SUCCESS,
  payload: { result },
})
export const generateOrderFailed = error => ({
  type: constants.GENERATE_ORDER_FAILED,
  payload: { error },
})
