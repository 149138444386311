import { combineReducers } from 'redux'
import create from './create.reducer'
import view from './view.reducer'

const reducer = combineReducers({
  create,
  view,
})

export default reducer
