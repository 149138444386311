import PropTypes from 'prop-types'

import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import { TextStyles } from 'utils/text'

const useStyles = makeStyles({
  'card-row-label': {
    paddingRight: '4px',
  },
})

const CardRowLabel = ({ children, className, ...props }) => {
  const classes = useStyles()

  return (
    <Typography
      {...TextStyles.paragraphDark({
        component: 'label',
        className: ['thin', classes['card-row-label'], className].join(' '),
      })}
      {...props}>
      {children}
    </Typography>
  )
}

CardRowLabel.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.array]),
  className: PropTypes.string,
}

CardRowLabel.defaultProps = {
  className: '',
}

export default CardRowLabel
