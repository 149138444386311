import PropTypes from 'prop-types'
import { get } from 'lodash'
import { Typography, Box, Divider } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/styles'

import { TextStyles } from 'utils/text'
import { Card } from 'modules/ui'
import { LineItemCard, TitleLineItemCard, OutlinedDivider } from 'components/ui'
import { HighLightFrame } from 'modules/ui/components'
import { formatFee } from 'utils'

import {
  formatRemainingMb,
  formatRemainingMin,
  formatRangeEndMb,
  formatRangeEndMin,
  formatConsumptionMb,
  formatConsumptionMin,
  formatRangeStartMin,
  formatRangeStartMb,
} from './helpers'

import { ConsumptionCounter } from './ConsumptionCounter'

const getCardTitle = ({ description }) =>
  description ? `Contadores y consumo - ${description}` : 'Contadores y consumo'

const useStyles = makeStyles({
  'usages-mobile-card': {
    '&:not(:last-child)': {
      marginBottom: '8px',
    },

    '&.title-consumption': {
      height: 'auto !important',

      '& .titles .title': {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        width: '388px',
      },
    },

    '& .usage-card-consumption-title': {
      '& .primary-text': {
        textTransform: 'none',
      },
    },

    '& .line-item-card.totals .primary-text': {
      fontWeight: 500,
      fontSize: '16px',
    },

    '& .xs-text': {
      fontSize: '12px',
      marginTop: '-4px',
      marginBottom: '12px',
    },

    '& .line-item-card-xs': {
      padding: '6px 24px',

      '& > div': {
        fontWeight: 800,
      },
    },

    '& .otherExpenses': {
      position: 'relative',
    },
  },
})

export const UsagesMobileCard = ({
  data: { mobile },
  highLigthedUI,
  isPrepaid,
  toggable,
  collapsed,
  taxNeeded,
}) => {
  const classes = useStyles()

  const PADDING_ELEMENT = '9px 24px'
  const themeContext = useTheme()

  return (
    <Card
      className={`${classes['usages-mobile-card']} title-consumption highLightable ${get(
        highLigthedUI,
        'otherExpenses',
      )}`}
      title={getCardTitle(mobile)}
      data-hook={`card-Contadores y consumo - ${mobile.description}`}
      toggable={toggable}
      toolTip={mobile.description}
      collapsed={collapsed}
      withShadow>
      <div>
        <ConsumptionCounter
          title="Contador Internet"
          data={{
            totalUsage: mobile.consumedData,
            totalAmount: mobile.totalData || 0,
          }}
          formatRangeStart={formatRangeStartMb}
          formatRangeEnd={formatRangeEndMb}
          formatRemaining={formatRemainingMb}
          formatConsumption={formatConsumptionMb}
          hasUnlimited={mobile.hasUnlimitedGigas}
        />
        <Box p={PADDING_ELEMENT}>
          <OutlinedDivider />
        </Box>

        <ConsumptionCounter
          title="Contador Llamadas"
          data={{
            totalUsage: mobile.consumedCallsTariff,
            totalAmount: mobile.totalCallsTariff || 0,
          }}
          formatRangeStart={formatRangeStartMin}
          formatRangeEnd={formatRangeEndMin}
          formatRemaining={formatRemainingMin}
          formatConsumption={formatConsumptionMin}
          hasUnlimited={mobile.hasUnlimitedMin}
        />
        <Box p={PADDING_ELEMENT}>
          <OutlinedDivider />
        </Box>
        <div
          data-hook={get(highLigthedUI, 'otherExpenses') && 'other-expenses-lightframe'}
          className="p-y-12 flex flex-column flex-grow otherExpenses">
          <HighLightFrame />
          <TitleLineItemCard
            className="usage-card-consumption-title"
            primaryText="Consumo fuera de tarifa"
            isHeader
          />
          <LineItemCard
            primaryText={`SMS enviados (${mobile.smsConsumedAtCost}u.)`}
            secondaryText={formatFee(mobile.smsAmountConsumedWithTaxes)}
            fieldsApart
          />
          <LineItemCard
            primaryText="Llamadas"
            secondaryText={formatFee(mobile.callsConsumedWithTaxes)}
            fieldsApart
          />
          <LineItemCard
            primaryText="Llamadas Premium"
            secondaryText={formatFee(mobile.premiumCallsConsumedWithTaxes)}
            fieldsApart
          />
          <LineItemCard
            primaryText="Llamadas Internacionales"
            secondaryText={formatFee(mobile.internationalCallsConsumedWithTaxes)}
            fieldsApart
          />
          {isPrepaid && (
            <LineItemCard
              primaryText="Resto llamadas prepago"
              secondaryText={formatFee(mobile.consumedCallsWithoutExtrasWithTaxes)}
              fieldsApart
            />
          )}
          <LineItemCard
            primaryText="Uso de datos"
            secondaryText={formatFee(mobile.totalDataConsumedWithTaxes)}
            fieldsApart
          />
          <LineItemCard
            primaryText="Otros"
            secondaryText={formatFee(mobile.othersConsumedWithTaxes)}
            fieldsApart
          />
          <Box m="8px 24px 0">
            <Divider style={{ backgroundColor: themeContext.palette.global.gray_dark }} />
          </Box>

          <LineItemCard
            className="totals "
            secondaryTextDataHook="usages-card-other-expenses"
            primaryText="Gasto total fuera de tarifa"
            secondaryText={`${formatFee(
              mobile.totalExtrasMobileWithTaxes + mobile.othersConsumedWithTaxes,
            )}`}
            fieldsApart
          />
          {taxNeeded && (
            <Typography
              {...TextStyles.labelDark({
                className: 'xs-text p-y-0 p-x-24',
              })}>
              (Impuestos incluídos)
            </Typography>
          )}
        </div>
      </div>
    </Card>
  )
}

UsagesMobileCard.propTypes = {
  data: PropTypes.shape({
    mobile: PropTypes.shape({
      consumedData: PropTypes.number,
      totalData: PropTypes.number,
      remainingDataInternetTariff: PropTypes.number,
      consumedCallsTariff: PropTypes.number,
      totalCallsTariff: PropTypes.number,
      smsConsumedAtCost: PropTypes.number,
      smsAmountConsumed: PropTypes.number,
      totalAmount: PropTypes.number,
      description: PropTypes.string,
      hasUnlimitedMin: PropTypes.bool,
      hasUnlimitedGigas: PropTypes.bool,
      smsAmountConsumedWithTaxes: PropTypes.number,
      callsConsumedWithTaxes: PropTypes.number,
      premiumCallsConsumedWithTaxes: PropTypes.number,
      internationalCallsConsumedWithTaxes: PropTypes.number,
      consumedCallsWithoutExtrasWithTaxes: PropTypes.number,
      totalDataConsumedWithTaxes: PropTypes.number,
      totalExtrasMobileWithTaxes: PropTypes.number,
      othersConsumedWithTaxes: PropTypes.number,
    }),
  }),
  highLigthedUI: PropTypes.object,
  isPrepaid: PropTypes.bool,
  toggable: PropTypes.bool,
  collapsed: PropTypes.bool,
  taxNeeded: PropTypes.bool,
}
