import PropTypes from 'prop-types'

import { useSelector } from 'react-redux'

import fp from 'lodash/fp'

import { createStructuredSelector } from 'reselect'
import { selectors as customer360SvcSelectors } from 'services/customer-360/selectors'
import { helpers as customer360SvcHelpers } from 'services/customer-360/helpers'
import { selectors as customerSvaSelectors } from 'services/customer-sva/selectors'
import { selectIsPosOrMasProximo } from 'modules/Permissions/store/permissions-selectors'
import { formatDate } from 'utils/formatting/dates'

import { ClientBasicInfo } from 'components/client'

const customerSelector = createStructuredSelector({
  id: customer360SvcSelectors.getId,
  segment: customer360SvcSelectors.getSegment,
  composedSegment: fp.compose(
    customer360SvcHelpers.getSegmentStr,
    customer360SvcSelectors.getSegment,
  ),
  fullName: customer360SvcSelectors.getFullName,
  documentId: customer360SvcSelectors.getIdentificationId,
  activeFromStr: fp.compose(
    formatDate,
    customer360SvcSelectors.getActivatedAt,
  ),
  billingAddress: fp.compose(
    customer360SvcHelpers.getBillingAddressStr,
    customer360SvcSelectors.getBillingAddress,
  ),
  tfn: fp.compose(
    customer360SvcHelpers.getContactPhonesStr,
    customer360SvcSelectors.getContactPhones,
  ),
  email: customer360SvcSelectors.getEmail,
  company: customer360SvcSelectors.getCompany,
  isCompany: customer360SvcSelectors.getIsCompany,
  authorizedPersonDocumentId: customer360SvcSelectors.getAuthorizedPersonDocumentId,
  vipType: customer360SvcSelectors.selectVipType,
  hasVipError: customer360SvcSelectors.selectHasVipError,
  customerSvaLabel: customerSvaSelectors.customerSvaLabel,
  hasValidCustomerSva: customerSvaSelectors.hasValidCustomerSva,
  customerSvaError: customerSvaSelectors.error,
  isPosOrMasproximo: selectIsPosOrMasProximo,
})

export const ClientBasicInfoContainer = rest => {
  const customerDat = useSelector(customerSelector)

  const props = {
    ...rest,
    customerDat: {
      ...customerDat,
      segmentLine: [customerDat.composedSegment, customerDat.id].filter(Boolean).join(' - '),
    },
  }

  return <ClientBasicInfo {...props} />
}

ClientBasicInfoContainer.propTypes = {
  dataHook: PropTypes.string,
}

export default ClientBasicInfoContainer
