import PropTypes from 'prop-types'
import { Card, CardContent } from '@material-ui/core'

import { LINE_TYPE } from 'modules/Lines/constants'

import { Line } from '../../Offer/Line/Line'
import { useLine } from '../useLine'

export function MainMobileLine({ disableOfferEdit, isLeadFilled }) {
  const lineType = LINE_TYPE.MAIN_MOBILE
  const {
    tariffs,
    isLoadingTariffs,
    onChangeTariff,
    selectedTariff,
    fieldNames,
    formikValues,
    tariffsError,
  } = useLine({ lineType, tariffReqParams: { type: 'mobile' } })

  return (
    <Card style={{ margin: '0 auto 32px 0' }}>
      <CardContent style={{ paddingBottom: '0' }}>
        <Line
          showPicker
          isMobileOnly
          disableOfferEdit={disableOfferEdit}
          showPackages
          showLineData={!!selectedTariff.id}
          selectedTariff={selectedTariff}
          lineFieldNames={fieldNames}
          formikLineValues={formikValues}
          lineType={lineType}
          error={tariffsError}
          isLoading={isLoadingTariffs}
          onChangeTariff={onChangeTariff}
          isLeadFilled={isLeadFilled}
          tariffs={tariffs}
        />
      </CardContent>
    </Card>
  )
}

MainMobileLine.propTypes = {
  disableOfferEdit: PropTypes.bool,
  isLeadFilled: PropTypes.bool,
}
