import {
  selectPonr,
  orderPonrInitAction,
  GET_ORDER_PONR_SUCCESS,
  GET_ORDER_PONR_ERROR,
} from 'modules/ponr'
import { actions as roveActions, constants as roveConstants, getRove } from 'services/rove'
import { appointmentActions, appointmentActionTypes } from 'modules/Appointments'
import {
  actions as coverageActions,
  coverageActionTypes as coverangeConstants,
} from 'modules/Coverage'
import {
  findConvergentTariffsIdsAction,
  FIND_CONVERGENT_TARIFFS_IDS_SUCCESS,
  FIND_CONVERGENT_TARIFFS_IDS_ERROR,
} from 'modules/tariffs/store-apigee'

export const services = {
  rove: {
    type: 'asyncService',
    actionInit: roveActions.getRoveInit,
    actionsFinish: {
      [roveConstants.GOT_ROVE]: 'success',
      [roveConstants.GET_ROVE_FAILED]: 'failed',
    },
    selector: getRove,
    expectedResult: 'A',
  },
  ponr: {
    type: 'asyncService',
    actionInit: orderPonrInitAction,
    actionsFinish: {
      [GET_ORDER_PONR_SUCCESS]: 'success',
      [GET_ORDER_PONR_ERROR]: 'failed',
    },
    selector: selectPonr,
    expectedResult: false,
  },
  getCoverageToken: {
    type: 'asyncService',
    actionInit: coverageActions.getCoverageToken,
    actionsFinish: {
      [coverangeConstants.GET_COVERAGE_TOKEN_SUCCESS]: 'success',
      [coverangeConstants.GET_COVERAGE_TOKEN_FAILED]: 'failed',
    },
    // selector: getCoverageToken,
    // expectedResult: 'true',
  },

  getAppointmentToken: {
    type: 'asyncService',
    actionInit: appointmentActions.getAppointmentTokenInit,
    actionsFinish: {
      [appointmentActionTypes.GOT_APPOINTMENT_TOKEN]: 'success',
      [appointmentActionTypes.GET_APPOINTMENT_TOKEN_FAILED]: 'failed',
    },
  },
  getTariffsRated: {
    type: 'asyncService',
    actionInit: findConvergentTariffsIdsAction,
    actionsFinish: {
      [FIND_CONVERGENT_TARIFFS_IDS_SUCCESS]: 'success',
      [FIND_CONVERGENT_TARIFFS_IDS_ERROR]: 'failed',
    },
  },
}
