export const FIND_PRODUCT_ORDERS = 'ORDERS/FIND_PRODUCT_ORDERS/FETCH'
export const FIND_PRODUCT_ORDERS_SUCCESS = 'ORDERS/FIND_PRODUCT_ORDERS/SUCCESS'
export const FIND_PRODUCT_ORDERS_FAILED = 'ORDERS/FIND_PRODUCT_ORDERS/FAILED'
export const FIND_PRODUCT_ORDERS_NOT_FOUND = 'ORDERS/FIND_PRODUCT_ORDERS/NOT_FOUND'

export const constants = {
  FIND_PRODUCT_ORDERS,
  FIND_PRODUCT_ORDERS_SUCCESS,
  FIND_PRODUCT_ORDERS_FAILED,
  FIND_PRODUCT_ORDERS_NOT_FOUND,
}
