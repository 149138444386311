import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'

export function Board({ children, ...rest }) {
  return (
    <Grid container spacing={3} style={{ width: '100%', marginLeft: 0 }} {...rest}>
      {children}
    </Grid>
  )
}

Board.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
}
