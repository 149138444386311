import { useMemo } from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/styles'
import { TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'

const useStyles = makeStyles({
  'filter-autocomplete-input': {
    width: '150px',
  },
  'filter-autocomplete-input-multi': {
    minWidth: '300px',
    '& span': {
      maxWidth: '100px',
    },
  },
})

export const DeviceFilterAutocomplete = ({
  multiple = false,
  options,
  value,
  singleInputValue,
  onChange: onChangeHandler,
  inputValue,
  onInputChange: onInputChangeHandler,
  inputLabel,
  inputPlaceholder,
}) => {
  const classes = useStyles()

  const onChange = (event, newOption) => {
    onChangeHandler(newOption)
  }

  const onInputChange = (event, newValue) => {
    onInputChangeHandler(newValue)
  }

  const getOptionLabel = option => {
    return option?.label || ''
  }

  const optionsWithoutSelectedValues = useMemo(() => {
    if (multiple && value?.length > 0 && options?.length > 0) {
      const valueMappedOptions = value.map(option => option.value)
      return options.filter(option => !valueMappedOptions.includes(option.value))
    }
    return options
  }, [options, value])

  const multiAutocompleteProps = {
    value,
    options: optionsWithoutSelectedValues,
    onChange,
    getOptionLabel,
    multiple: multiple || undefined,
  }
  const standardAutocompleteProps = {
    ...multiAutocompleteProps,
    value: singleInputValue,
    onInputChange,
    autocomplete: true,
    includeInputInList: true,
  }

  const autocompleteProps = multiple ? multiAutocompleteProps : standardAutocompleteProps

  const inputClass = multiple
    ? classes['filter-autocomplete-input-multi']
    : classes['filter-autocomplete-input']

  return (
    <>
      <Autocomplete
        disableClearable
        filterSelectedOptions
        autoHighlight
        {...autocompleteProps}
        renderInput={params => (
          <TextField
            {...params}
            className={inputClass}
            variant="outlined"
            label={inputLabel}
            placeholder={multiple ? inputPlaceholder : undefined}
            value={!multiple ? inputValue : undefined}
          />
        )}
      />
    </>
  )
}

DeviceFilterAutocomplete.defaultProps = {
  onChange: () => {},
  onInputChange: () => {},
}

DeviceFilterAutocomplete.propTypes = {
  options: PropTypes.array,
  value: PropTypes.array,
  singleInputValue: PropTypes.object,
  onChange: PropTypes.func,
  inputValue: PropTypes.string,
  onInputChange: PropTypes.func,
  inputLabel: PropTypes.string,
  multiple: PropTypes.bool,
  inputPlaceholder: PropTypes.string,
}
