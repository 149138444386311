import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import {
  RESIDENTIAL_SEGMENT,
  SELF_EMPLOYED_SEGMENT,
  VISTA_CHANNEL,
  PORTFOLIO_CONSENT,
} from 'modules/mas-consents/constants'
import { CARTERA_SEGMENTS } from 'services/global-constants'

import { useWaitingForLoad } from 'utils/hooks/useWaitingForLoad'
import { useSvcLoading } from 'utils/hooks/useSvcLoading'

import * as logisticOrdersSvc from 'services/logistic-orders'
import * as saga from '../../sagas/orderMainTabLoad'

import { OrderMain } from '../../components/OrderMain/OrderMain'
import { orderMainContainerSelectors } from './OrderMainContainer.selectors'

export const OrderMainContainer = ({ orderId, ...rest }) => {
  const dispatch = useDispatch()

  const {
    isSvcLoading,
    hasErrors,
    ownerData,
    orderData,
    orderDataContact,
    msisdn: lines,
    gdprConsentsAllowed,
  } = useSelector(orderMainContainerSelectors)

  const msisdn = lines !== '-' ? lines.split(' ')[0] : null

  const dataLogistic = useSelector(state =>
    logisticOrdersSvc.selectors.selectLogisticDataByOrderId(state, orderId),
  )
  const dataLogisticIsLoading = useSelector(state =>
    logisticOrdersSvc.selectors.selectLogisticLoadingByOrderId(state, orderId),
  )

  const { executeWaiting, waitingForLoad, setWaitingForLoad } = useWaitingForLoad()
  const { isLoading } = useSvcLoading(isSvcLoading, waitingForLoad, setWaitingForLoad)

  const updateSvcs = () => {
    dispatch(saga.actions.loadInit(orderId, msisdn))
  }

  useEffect(() => {
    if (dataLogisticIsLoading === null && !!msisdn) {
      executeWaiting(updateSvcs)
    }
  }, [])

  const { subscriptionId, verbalAgreementId } = orderData
  const { individualIdentification, segment, langCode } = ownerData

  const gdprData = {
    subscriptionId,
    verbalId: verbalAgreementId,
    customerDocument: individualIdentification?.identificationId,
    segment:
      segment === CARTERA_SEGMENTS.CONSUMER.toUpperCase()
        ? RESIDENTIAL_SEGMENT
        : SELF_EMPLOYED_SEGMENT,
    lang: langCode,
    channel: VISTA_CHANNEL,
    consentType: PORTFOLIO_CONSENT,
  }

  const props = {
    ...rest,
    hasErrors,
    ownerData,
    orderData,
    orderDataContact,
    msisdn,
    gdprConsentsAllowed,
    dataLogistic,
    gdprData,
    isLoading,
  }

  return <OrderMain {...props} />
}

OrderMainContainer.propTypes = {
  orderId: PropTypes.string,
}
