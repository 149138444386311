import propTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import styled from 'styled-components'

const StyledButton = styled(Button)`
  margin: 0 10px;
`

const WizardNavigation = ({ previous, next }) => (
  <div>
    {!!previous && (
      <StyledButton
        variant="contained"
        color="secondary"
        disabled={previous.disabled}
        className=""
        data-hook="rounder"
        onClick={previous.callback}>
        {previous.caption}
      </StyledButton>
    )}
    {!!next && (
      <StyledButton
        variant="contained"
        color="primary"
        disabled={next.disabled}
        className=""
        onClick={next.callback}
        data-hook="btnWizardNext rounder">
        {next.caption}
      </StyledButton>
    )}
  </div>
)

export default WizardNavigation

WizardNavigation.defaultProps = {
  previous: null,
  next: null,
}

WizardNavigation.propTypes = {
  previous: propTypes.shape({
    callback: propTypes.func.isRequired,
    disabled: propTypes.bool.isRequired,
    caption: propTypes.string.isRequired,
  }),
  next: propTypes.shape({
    callback: propTypes.func.isRequired,
    disabled: propTypes.bool.isRequired,
    caption: propTypes.string.isRequired,
  }),
}
