import { logError, formatErrorMessage } from 'services/logging'
import { axiosFactory } from 'modules/axios'
import { put, takeLatest, take, call, select } from 'redux-saga/effects'
import { selectIsLoading } from 'modules/Auth'

import { find, get, chain } from 'lodash'

import {
  actions as productOrdersActions,
  constants as productOrdersConstants,
  selectOrderTerritoryOwner,
} from 'services/product-orders'

import {
  findTariffByIdAction,
  findTariffsAction,
  findTariffsErrorAction,
  FIND_TARIFF_BY_ID_SUCCESS,
  selectTariffApigeeOneTariff,
  categoriesTariff2P_3P,
  selectTariffApigeeIsLoading,
} from 'modules/tariffs/store-apigee'
import {
  actions as actionsCoverage,
  coverageActionTypes as coverageConstants,
  getCoverageLoading,
  checkIsNeba,
  NEBA_FAKE_TERRITORY,
  STATUS_ACTIVE_HIDDEN,
} from 'modules/Coverage'
import {
  actions as orderOnFlyActions,
  selectors as selectorsOrderOnFly,
} from 'services/order-on-fly'

import { actions as actionsTerritCompat } from 'services/territory-compatibility'

import { actions as actionsEnrichment } from 'services/enrichment'

import { actions as actionsOffers, getOffersLoading } from 'services/offers'
import {
  actions as actionsCustomer,
  selectors as selectorsCustomer,
  constants as constantsCustomer,
} from 'services/customer-new'
import { xDSLs } from 'services/change-technology/constants'

import {
  actions as actionsCheckCTCAOnFly,
  selectors as selectorsCheckOrderCTOnFly,
} from 'services/check-order-ct-on-fly'

import {
  actions as subscriptionsActions,
  constants as subscriptionsConstants,
  getSubscriptions,
} from 'services/subscriptions'

import { fetchInstallationDirection } from 'modules/InstallationsCare'
import { getGescal37 } from 'modules/Coverage/store/selectors'
import { selectCoverageV1 } from 'services/feature-flag/selectors'

import { getConnectionType } from 'services/subscriptions/helpers'

import { findTypeByFixedNumber } from '../../helpers'

export const GET_SUBSCRIPTION_ACTION = 'CHANGE_TECHNOLOGY/SUBSCRIPTION/GET'

export const getSubscriptionSagaAction = payload => ({
  type: GET_SUBSCRIPTION_ACTION,
  payload,
})

export function* getSubscriptionSaga({
  payload: { phoneNumber, fixedline, customerId, customerIdType },
}) {
  try {
    yield put(actionsEnrichment.findEnrichment())
    yield put(productOrdersActions.findProductOrders(phoneNumber, { customerId, customerIdType }))
    yield take(productOrdersConstants.FIND_PRODUCT_ORDERS_SUCCESS)
    yield put(subscriptionsActions.findSubscriptionByMsisdn(phoneNumber))
    yield take(subscriptionsConstants.FIND_SUBSCRIPTION_BY_ID_SUCCEEDED)

    yield put(actionsCoverage.getCoverageToken())

    yield put(actionsCustomer.findInit())
    yield take(constantsCustomer.FIND_SUCCESS)

    yield put(actionsCoverage.findCoverage({}))
    const coverage = yield take(coverageConstants.FIND_COVERAGE_SUCCESS)
    const products = yield select(getSubscriptions)
    const coverageV1 = yield select(selectCoverageV1)

    const coverageResult = coverageV1
      ? get(coverage, 'payload.data')
      : find(coverage.payload.data, { technology: 'FTTH' })

    const territoryOwnerCustomer = yield select(selectOrderTerritoryOwner)
    const territoryOwnerCoverage = get(coverageResult, 'territoryOwner')
    const subscriptionTechnology = chain(products)
      .head()
      .value('value')
    if (typeof territoryOwner === 'object' || territoryOwnerCoverage === null) {
      throw new Error({ name: 'Invalid territoryOwner' })
    }
    const orginConnectionGot = get(getConnectionType(subscriptionTechnology), 'value', [])
    const originTech = xDSLs.includes(orginConnectionGot) ? 'ADSL' : orginConnectionGot

    yield put(
      actionsTerritCompat.getTerritCompatInit({
        originTech,
        originTerritoryOwner: territoryOwnerCustomer,
        destinationTerritoryOwner: territoryOwnerCoverage,
      }),
    )
    yield put(actionsCheckCTCAOnFly.checkCTCAOnFly())
    yield put(orderOnFlyActions.findOrderOnFly(fixedline))
    yield put(actionsOffers.findOffers({ fixedLineType: 'fiber' }))

    const idTariff = findTypeByFixedNumber(products, phoneNumber)
    yield put(findTariffByIdAction({ id: idTariff }))
    yield take(FIND_TARIFF_BY_ID_SUCCESS)
    const tariff = yield select(selectTariffApigeeOneTariff(idTariff))
    const category = categoriesTariff2P_3P(tariff)
    const segment = yield select(selectorsCustomer.getSegment)
    const territoryOwnerFilterTariff = checkIsNeba(territoryOwnerCoverage)
      ? NEBA_FAKE_TERRITORY
      : territoryOwnerCoverage

    const gescal = yield select(getGescal37)

    if (checkIsNeba(territoryOwnerCoverage)) {
      yield put(fetchInstallationDirection(gescal))
    }

    yield put(
      findTariffsAction(
        category
          ? {
              status: STATUS_ACTIVE_HIDDEN,
              segment,
              territoryOwner: territoryOwnerFilterTariff,
              category,
              fixedLineType: 'fiber',
            }
          : {
              status: STATUS_ACTIVE_HIDDEN,
              segment,
              territoryOwner: territoryOwnerFilterTariff,
              fixedLineType: 'fiber',
            },
      ),
    )
  } catch (e) {
    yield call(logError, { e: new Error(formatErrorMessage(e)), phoneNumber, fixedline })
    const error = axiosFactory.buildError(e)
    yield put(findTariffsErrorAction(error))
  }
}

export function* watchGetSubscriptionSaga() {
  yield takeLatest(GET_SUBSCRIPTION_ACTION, getSubscriptionSaga)
}

export const selectorInitialStep = state =>
  selectIsLoading(state) ||
  selectorsCustomer.getLoading(state) ||
  getCoverageLoading(state) ||
  getOffersLoading(state) ||
  selectTariffApigeeIsLoading(state) ||
  selectorsCheckOrderCTOnFly.getOrderCTOnFlyLoading(state) ||
  selectorsOrderOnFly.orderOnFlyLoading(state)
