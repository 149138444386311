import PropTypes from 'prop-types'

import { Grid } from '@material-ui/core'
import './CustomerContactDetails.css'
import { Section } from '../styles'

function CustomerRow({ label, value }) {
  return (
    <Grid container>
      <Grid item xs={4}>
        {label}
      </Grid>
      <Grid item xs={8}>
        {value}
      </Grid>
    </Grid>
  )
}
CustomerRow.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
}

export function CustomerContactDetails({ personalData }) {
  return (
    <Section>
      <CustomerRow label="Name" value={personalData.name} />
      <CustomerRow label="ID" value={personalData.id} />
      <CustomerRow label="Email" value={personalData.emailAccount} />
      <CustomerRow label="Phone" value={personalData.phoneNumber} />
      <CustomerRow label="ID Type" value={personalData.individualIdentification.type} />
      <CustomerRow label="ID Number" value={personalData.individualIdentification.value} />
    </Section>
  )
}
CustomerContactDetails.propTypes = {
  personalData: PropTypes.object,
}
