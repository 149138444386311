import styled from 'styled-components'

const TitleInfo = styled.h5`
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #b9c2c7;
`

const CardInfo = ({ children }) => <TitleInfo>{children}</TitleInfo>

export default CardInfo
