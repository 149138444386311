import { slice, capitalize } from 'lodash'
import { helpers as customer360SvcHelpers } from 'services/customer-360/helpers'
import { NoDataStr, getCountryFromIsoCode } from 'utils'
import { formatDate } from 'utils/formatting/dates'
import { formatAddressStr } from '../../helpers/formats.helper'

export function formatDataOwner(data) {
  const {
    segment,
    birthDayStr,
    birthdate,
    fullName,
    iban,
    individualIdentification,
    nationality,
    langStr,
    langCode,
    isCompany,
  } = data
  return {
    segment: customer360SvcHelpers.getSegmentStr(segment) || NoDataStr,
    segmentCode: capitalize(segment),
    fullName: fullName || NoDataStr,
    identification: individualIdentification,
    birthdate: birthDayStr || formatDate(birthdate) || NoDataStr,
    country: getCountryFromIsoCode(nationality) || NoDataStr,
    iban: iban || NoDataStr,
    language: langStr || NoDataStr,
    languageCode: langCode,
    isCompany,
  }
}

export const formatDataOrder = ({
  orderId,
  dateOrder,
  channel,
  sfid,
  contractId,
  typeContract,
  flow,
  products,
}) => ({
  orderId: orderId || NoDataStr,
  dateOrder: formatDate(dateOrder) || NoDataStr,
  channel: channel || NoDataStr,
  sfid: sfid || NoDataStr,
  contractId: contractId || NoDataStr,
  typeContract: typeContract || NoDataStr,
  flow: flow || NoDataStr,
  products: products || [],
})

export function formatDataContact(data) {
  const { address, telephones, mail } = data
  return {
    address: formatAddressStr(address) || NoDataStr,
    // Get only two telephones number
    telephones: slice(telephones, 0, 2) || NoDataStr,
    mail: mail || NoDataStr,
  }
}

export function formatDataClientArea(data) {
  const { email } = data
  return {
    username: email || NoDataStr,
  }
}

export function formatDataClientAddresses(data) {
  const { billingAddressStr } = data
  return {
    billingAddressStr: billingAddressStr || NoDataStr,
    deliveryAddressStr: NoDataStr,
  }
}

export const formatDataFlow = dataFlow => {
  const formattedDataFlow = {
    'device-and-sims-delivered-together': 'Dispositivo y sims entregados juntos',
    'device-and-sims-delivered-separately': 'Dispositivo y sims entregados separados',
  }
  return formattedDataFlow[dataFlow] || NoDataStr
}
