import { useFormikContext } from 'formik'
import { get } from 'lodash'
import { getMobileNumberType } from 'modules/Lines/helpers'

export function getFormikProps(isElFijo) {
  const { setFieldValue, values } = useFormikContext()

  const { category, manufacturer, id } = get(values, 'stock.terminal', {})
  const mainMobileLineOffer = get(values, 'mainMobileLineOffer', {})
  const { signUpType, portaPaymentType } = mainMobileLineOffer
  const ps = get(mainMobileLineOffer, 'tariff.ps')

  const mobileNumberType = getMobileNumberType(signUpType, portaPaymentType, isElFijo)

  return {
    setFieldValue,
    terminalCategory: category,
    terminalBrand: manufacturer,
    terminalId: id,
    mobileNumberType,
    ps,
  }
}
