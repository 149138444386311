import { actionTypes } from 'modules/typifications/constants'

const initialState = {
  data: [],
  error: null,
  loading: false,
  open: false,
}

export function securizerTypificationsReducer(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case actionTypes.SECURIZER_TYPIFICATION_INIT:
        return {
          ...state,
          loading: true,
          open: false
        }
  
      case actionTypes.SECURIZER_SUCCESS:
        return {
          ...state,
          loading: false, 
          data: payload,
          open: true
        }
    
      case actionTypes.SECURIZER_FAILED:
        return {
          ...state,
          loading: false, 
          error: payload,
          open: false
        }

      case actionTypes.RESET_SECURIZER_FAILED:
        return {
          ...state,
          loading: false, 
          error: false,
          open: false
        }
        
      case actionTypes.CLOSE_TYPIFICATION_IFRAME:
        return {
          ...state,
          open: false
        }

    default:
      return state
  }
}
