import { createStructuredSelector, createSelector } from 'reselect'
import { RESIDENTIAL, PRO } from 'modules/SharedSales/constants'
import {
  selectCustomerReachedOrderLimit,
  selectCustomerOrderLimitLoading,
  selectCustomerOrderError,
} from 'modules/CustomerOrders'
import {
  getIdentificationId,
  getIdentificationType,
  selectIsSelfEmployed,
  getSegment,
} from 'services/customer-360/selectors'
import {
  selectAccountType,
  selectAccountSelfEmployed,
} from 'modules/CustomerInfo/store/customerInfo.selectors'

import { selectQueryParams } from 'modules/Router/store/index'

const selectSaleInformation = createSelector(
  getIdentificationId,
  getIdentificationType,
  selectIsSelfEmployed,
  getSegment,
  selectQueryParams,
  selectAccountType,
  selectAccountSelfEmployed,
  (
    docNumber,
    docType,
    selfEmployed360,
    segment360 = '',
    { channel },
    customerSegment,
    customerSelfEmployed,
  ) => {
    const saleSegment = customerSegment || segment360
    const saleSelfEmployed = customerSelfEmployed || selfEmployed360

    return {
      docNumber,
      docType,
      selfEmployed:
        (!!saleSelfEmployed && saleSelfEmployed !== 'false') ||
        saleSegment.toLowerCase().includes('autonomo'),
      segment: saleSegment.toLowerCase().includes(PRO) ? PRO : RESIDENTIAL,
      channel,
    }
  },
)

export const selectOrderLimitModal = createStructuredSelector({
  customerId: getIdentificationId,
  saleInformation: selectSaleInformation,
  hasOrderLimit: selectCustomerReachedOrderLimit,
  isLoading: selectCustomerOrderLimitLoading,
  error: selectCustomerOrderError,
})
