export const FETCH_ENERGY_CONTRACTS = 'OVID/ENERGY/FETCH_CONTRACTS'
export const FETCH_ENERGY_CONTRACTS_SUCCESS = 'OVID/ENERGY/FETCH_CONTRACTS_SUCCESS'
export const FETCH_ENERGY_CONTRACTS_FAILED = 'OVID/ENERGY/FETCH_CONTRACTS_FAILED'

export const fetchEnergyContracts = documentNumber => ({
  type: FETCH_ENERGY_CONTRACTS,
  payload: {
    documentNumber,
  },
})

export const fetchEnergyContractsSuccess = data => ({
  type: FETCH_ENERGY_CONTRACTS_SUCCESS,
  payload: {
    data,
  },
})

export const fetchEnergyContractsFailed = error => ({
  type: FETCH_ENERGY_CONTRACTS_FAILED,
  payload: {
    error,
  },
})
