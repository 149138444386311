const FETCH_CROSS_SELL_ALLOWED = 'OVID/CROSS_SELL/FETCH_CROSS_SELL_ALLOWED'
const FETCH_CROSS_SELL_ALLOWED_SUCCESS = 'OVID/CROSS_SELL/FETCH_CROSS_SELL_ALLOWED_SUCCESS'
const FETCH_CROSS_SELL_ALLOWED_FAILED = 'OVID/CROSS_SELL/FETCH_CROSS_SELL_ALLOWED_FAILED'
const RESET_CROSS_SELL_ALLOWED = 'OVID/CROSS_SELL/RESET_CROSS_SELL_ALLOWED'

const FETCH_CROSS_COMPATIBLE_DISCOUNTS = 'OVID/CROSS_SELL/FETCH_CROSS_COMPATIBLE_DISCOUNTS'
const FETCH_CROSS_COMPATIBLE_DISCOUNTS_SUCCESS =
  'OVID/CROSS_SELL/FETCH_CROSS_COMPATIBLE_DISCOUNTS_SUCCESS'
const FETCH_CROSS_COMPATIBLE_DISCOUNTS_FAILED =
  'OVID/CROSS_SELL/FETCH_CROSS_COMPATIBLE_DISCOUNTS_FAILED'
const RESET_CROSS_COMPATIBLE_DISCOUNTS = 'OVID/CROSS_SELL/RESET_CROSS_COMPATIBLE_DISCOUNTS'

const FETCH_CROSS_TARIFF_PROMOTIONS = 'OVID/CROSS_SELL/FETCH_CROSS_TARIFF_PROMOTIONS'
const FETCH_CROSS_TARIFF_PROMOTIONS_SUCCESS =
  'OVID/CROSS_SELL/FETCH_CROSS_TARIFF_PROMOTIONS_SUCCESS'
const FETCH_CROSS_TARIFF_PROMOTIONS_FAILED = 'OVID/CROSS_SELL/FETCH_CROSS_TARIFF_PROMOTIONS_FAILED'

export const constants = {
  FETCH_CROSS_SELL_ALLOWED,
  FETCH_CROSS_SELL_ALLOWED_SUCCESS,
  FETCH_CROSS_SELL_ALLOWED_FAILED,
  RESET_CROSS_SELL_ALLOWED,
  FETCH_CROSS_COMPATIBLE_DISCOUNTS,
  FETCH_CROSS_COMPATIBLE_DISCOUNTS_SUCCESS,
  FETCH_CROSS_COMPATIBLE_DISCOUNTS_FAILED,
  RESET_CROSS_COMPATIBLE_DISCOUNTS,
  FETCH_CROSS_TARIFF_PROMOTIONS,
  FETCH_CROSS_TARIFF_PROMOTIONS_SUCCESS,
  FETCH_CROSS_TARIFF_PROMOTIONS_FAILED,
}
