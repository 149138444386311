import { call, put, takeLatest, all } from 'redux-saga/effects'
import { logError, formatErrorMessage } from 'services/logging'
import { axiosFactory } from 'modules/axios'

import { actions } from './actions'
import { constants } from './constants'
import { getOrderProducts } from './api'

function* offersDetailSaga({ payload: orderId }) {
  try {
    const result = yield call(getOrderProducts, orderId)
    yield put(actions.findOrderProductsSuccess({ data: result }))
  } catch (e) {
    yield call(logError, { e: new Error(formatErrorMessage(e)), orderId })
    const error = axiosFactory.buildError(e)
    yield put(actions.findOrderProductsFailed(error))
  }
}

function* offersDetailByClientSaga({ payload }) {
  try {
    const { ordersId } = payload

    const result = yield all(ordersId.map(orderId => call(getOrderProducts, orderId)))

    yield put(actions.findOrdersProductsByClientSuccess({ data: result }))
  } catch (e) {
    yield call(logError, { e: new Error(formatErrorMessage(e)), payload })
    const error = axiosFactory.buildError(e)
    yield put(actions.findOrdersProductsByClientFailed(error))
  }
}

function* watchOffersDetailByClientSaga() {
  yield takeLatest(constants.FIND_ORDERS_PRODUCT_BY_CLIENT, offersDetailByClientSaga)
}

function* watchOffersDetailSaga() {
  yield takeLatest(constants.FIND_ORDER_PRODUCTS, offersDetailSaga)
}

export const saga = {
  offersDetailSaga,
  watchOffersDetailSaga,
  offersDetailByClientSaga,
  watchOffersDetailByClientSaga,
}
