import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { partition, get, isEmpty, every } from 'lodash'
import PropTypes from 'prop-types'
import { Divider, CircularProgress, MenuItem, Select, Typography, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import { useFetchTariffCompetitors } from 'modules/tariffs/hooks/index'
import { Modal, ModalActions, Button, ActionAdvice, ButtonLink } from 'components/ui'
import { formatNumberWithComma } from 'utils'

import {
  addDiscountsToTariffPrices,
  getTariffPriceWithDiscountPrice,
  uniqsDiscountsFromCrossSellPromotion,
} from 'modules/tariffs/helpers/tariffs.helpers'

import { TariffCell } from 'modules/ui'
import { useCrossSellTariffPromotions } from 'modules/offers-configuration/hooks/useCrossSellTariffPromotions'
import { selectSaleTax } from 'modules/NewClientSales/store/selectors/Taxes.selectors'
import { TariffDiscounts } from './TariffDiscounts'

import { TariffCompetitors } from './TariffCompetitors'

const useStyles = makeStyles({
  'price-box': {
    '& .MuiTypography-body1': {
      'line-height': '1.43',
    },
  },
})

export function TariffPickerModal({
  isOpen,
  onCancel,
  isConvergent,
  onSelectTariff,
  tariffs,
  selectedTariff,
  isLoadingTariffs,
  error,
  segment,
}) {
  const [pickedTariff, setPickedTariff] = useState(selectedTariff)
  const [showAllTariffs, setShowAllTariffs] = useState(false)
  const [speedFilter, setSpeedFilter] = useState('none')
  const [showCompetitors, setShowCompetitors] = useState(false)
  const { isLoadingCompetitors, competitorsTariffs } = useFetchTariffCompetitors({
    tariff: pickedTariff,
    showCompetitors,
  })
  const classes = useStyles()

  function filterTariffs(speeds, speed) {
    const filtered = tariffs?.filter(tariff => tariff.internetDownloadSpeed === speed)
    if (filtered.length && speeds.length > 1) {
      return filtered
    }
    return tariffs
  }

  const speeds = useMemo(
    () =>
      [
        ...new Set((tariffs?.length > 0 && tariffs.map(item => item.internetDownloadSpeed)) || []),
      ].sort((a, b) => (+a.slice(0, -1) > +b.slice(0, -1) ? -1 : 1)),
    [tariffs],
  )

  const hasOneTariffPerSpeed = useMemo(() => {
    const tariffPerSpeed = speeds.map(speed => ({ amount: filterTariffs(speeds, speed).length }))
    return every(tariffPerSpeed, { amount: 1 }) && !!tariffPerSpeed.length
  })

  const filteredTariffsBySpeed = useMemo(() => filterTariffs(speeds, speedFilter))

  const [advisedTariffs, notAdvisedTariffs] = useMemo(
    () =>
      partition(
        filteredTariffsBySpeed,
        tariff => !!get(tariff, 'advised', false) || tariff?.tariffType === 'extra_line',
      ),
    [filteredTariffsBySpeed],
  )

  const advisedSpeed = useMemo(
    () => (advisedTariffs.length && advisedTariffs[0].internetDownloadSpeed) || null,
    [advisedTariffs],
  )

  const tariffList = useMemo(() => {
    if (hasOneTariffPerSpeed) return tariffs
    const baseTariffs = showAllTariffs ? [...advisedTariffs, ...notAdvisedTariffs] : advisedTariffs
    return baseTariffs.length ? baseTariffs : filteredTariffsBySpeed
  }, [showAllTariffs, advisedTariffs, notAdvisedTariffs, speedFilter, hasOneTariffPerSpeed])

  const isTariffDisplayed = () => tariffList?.find(tariff => tariff.psId === pickedTariff?.psId)

  const {
    isCrossSell,
    fetchCrossSellTariffPromotions,
    isLoadingPromotions,
    isErrorPromotions,
    promotionsData,
    setDiscount,
    selectedCrossTariffPromo,
  } = useCrossSellTariffPromotions()

  const [pickedPromo, setPickedPromo] = useState(selectedCrossTariffPromo)

  const isValidFormTariffDiscounts =
    !isEmpty(pickedPromo) ||
    (!isLoadingPromotions && !isErrorPromotions && promotionsData?.length === 0)

  const disabledButtonSelecTariff =
    isCrossSell && isConvergent && (!isValidFormTariffDiscounts || !pickedTariff)

  const tariffPriceWithoutDiscount = useMemo(
    () => pickedTariff?.fees?.find(fee => fee.duration === 0),
    [pickedTariff],
  )

  const tax = useSelector(selectSaleTax)

  const pickedTariffDiscountPrice = useMemo(() => {
    if (!pickedTariff?.psId) {
      return null
    }

    const tariff = tariffs.find(t => t.psId === pickedTariff?.psId)

    if (pickedPromo?.discounts?.length > 0) {
      const uniqsDiscounts = uniqsDiscountsFromCrossSellPromotion(pickedPromo)
      const prices = addDiscountsToTariffPrices(tariff, uniqsDiscounts)
      return getTariffPriceWithDiscountPrice({ ...tariff, prices }, tax)
    }

    return tariff
  }, [pickedPromo, pickedTariff])

  const crossDiscountToTariffPrices = promoCrossSell => {
    if (isCrossSell && isConvergent) {
      setDiscount(promoCrossSell)
    }
  }

  const setSpeedFilterValue = speed => {
    setSpeedFilter(speed)

    if (isCrossSell) {
      setPickedPromo({})
    }
  }

  const handleSpeedFilterChange = speed => {
    if (speed !== speedFilter) {
      setSpeedFilterValue(speed)
      setPickedTariff()
    }
  }

  useEffect(() => {
    if (isOpen && selectedTariff?.psId) {
      setSpeedFilter(selectedTariff.internetDownloadSpeed)
    }
  }, [isOpen])

  useEffect(() => {
    if (speedFilter !== advisedSpeed) {
      setSpeedFilterValue(advisedSpeed)
    }
  }, [speeds])

  useEffect(() => {
    if (isCrossSell && !isLoadingPromotions && promotionsData?.length === 1) {
      setPickedPromo(promotionsData[0])
    }
  }, [isLoadingPromotions, promotionsData])

  return (
    <Modal data-hook="mobile-picker" isOpen={isOpen} onClose={onCancel} title="Selección de tarifa">
      {!isEmpty(error) && (
        <ActionAdvice type="error" items={[{ label: 'Error', value: error.value }]} />
      )}
      <div className="flex align-space-between-center">
        <div className="flex align-center-center">
          {!isConvergent && (
            <>
              <Typography variant="body2" color="textSecondary" className="p-r-16">
                Tarifas recomendadas
              </Typography>
              <ButtonLink
                size="small"
                disabled={!notAdvisedTariffs.length}
                data-hook="show-all-tariffs"
                onClick={() => setShowAllTariffs(bool => !bool)}>
                {showAllTariffs ? 'Ver sólo recomendadas' : 'Ver todas'}
              </ButtonLink>
            </>
          )}
        </div>
        {isConvergent && !hasOneTariffPerSpeed && (
          <div>
            <span>Velocidad: </span>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              labelWidth={600}
              value={speedFilter || '20M'}
              onChange={e => {
                handleSpeedFilterChange(e.target.value)
              }}>
              {speeds.map(speed => (
                <MenuItem key={speed} value={speed}>
                  {speed}
                </MenuItem>
              ))}
            </Select>
          </div>
        )}
      </div>
      <div className="flex full-width flex-wrap align-start-center p-y-16">
        {isLoadingTariffs && <CircularProgress size="32px" color="secondary" />}
        {tariffList?.length > 0 &&
          tariffList?.map(tariff => (
            <div className="m-r-20 m-b-12 tariff-wrapper" key={tariff?.poId}>
              <TariffCell
                selected={tariff?.psId === pickedTariff?.psId}
                tariff={tariff}
                onSelectTariff={() => {
                  setPickedTariff(tariff)

                  if (isCrossSell && isConvergent) {
                    setPickedPromo({})
                    fetchCrossSellTariffPromotions(segment, tariff)
                  }
                }}
              />
            </div>
          ))}
        {!isLoadingTariffs && !tariffList.length && isEmpty(error) && (
          <div className="p-y-32">
            {`No hay tarifas ${!showAllTariffs && 'recomendadas'} para la velocidad seleccionada`}
          </div>
        )}
      </div>

      {isCrossSell && isConvergent && pickedTariff?.psId && isTariffDisplayed() && (
        <TariffDiscounts
          isLoadingPromotions={isLoadingPromotions}
          isErrorPromotions={isErrorPromotions}
          promotionsData={promotionsData}
          setDiscount={setPickedPromo}
          selectedDiscount={pickedPromo}
        />
      )}

      <Divider />
      <div className="p-y-16">
        {isCrossSell && isConvergent && pickedTariff?.psId && pickedPromo?.discounts?.length > 0 ? (
          <Box display="flex" flexWrap="wrap" data-hook="price" className={classes['price-box']}>
            <Typography variant="body2" className="p-r-4" key="txt-precio">
              Precio:
            </Typography>
            {pickedTariffDiscountPrice?.discountPrice?.map((elem, index) =>
              elem.duration ? (
                <Box display="flex" flexWrap="wrap" key={`${elem.duration}-${elem.amount}`}>
                  <Typography variant="body2" className="p-r-4">
                    {`${formatNumberWithComma(elem.amount)} €/mes`}
                  </Typography>
                  <Typography variant="body1" className="p-r-4">
                    {` durante ${elem.duration -
                      (pickedTariffDiscountPrice.discountPrice[index - 1]?.duration || 0)}`}
                    {elem.duration > 1 ? ' meses' : ' mes'}
                  </Typography>
                  <Typography variant="body2" className="p-r-4">
                    , después
                  </Typography>
                </Box>
              ) : (
                <Typography variant="body2" color="textSecondary" key="ultimo-precio">
                  {` ${tariffPriceWithoutDiscount?.amount} €/mes`}
                </Typography>
              ),
            )}
          </Box>
        ) : (
          <Typography variant="body2" color="textSecondary" data-hook="price">
            {`Precio: ${tariffPriceWithoutDiscount?.amount || '-'} €/mes`}
          </Typography>
        )}
      </div>

      <Divider />

      {isConvergent && (
        <>
          <ButtonLink
            className="m-y-16"
            size="small"
            onClick={() => setShowCompetitors(bool => !bool)}>
            {showCompetitors
              ? 'Ocultar tarifas de la competencia'
              : 'Mostrar tarifas de la competencia'}
          </ButtonLink>
          {showCompetitors && (
            <>
              {competitorsTariffs.length > 0 && (
                <TariffCompetitors
                  tariffCompetitors={competitorsTariffs}
                  pickedTariff={pickedTariff}
                  isLoading={isLoadingCompetitors}
                />
              )}
              {isLoadingCompetitors && (
                <div>
                  <CircularProgress size="32px" color="secondary" />
                </div>
              )}
              {pickedTariff?.id && !isLoadingCompetitors && !competitorsTariffs.length && (
                <Typography variant="body1" color="textSecondary">
                  No hay comparativas para esta tarifa
                </Typography>
              )}
              {!pickedTariff?.id && !isLoadingCompetitors && !competitorsTariffs.length && (
                <Typography variant="body1" color="textSecondary">
                  Haga clic en una tarifa para ver la comparativa
                </Typography>
              )}
            </>
          )}
        </>
      )}

      <ModalActions display="flex" justifyContent="space-between" alignItems="center" mt="16px">
        <Button
          data-hook="select-tariff"
          onClick={() => {
            crossDiscountToTariffPrices(pickedPromo)
            onSelectTariff(pickedTariff, pickedPromo)
          }}
          disabled={disabledButtonSelecTariff}>
          Seleccionar esta tarifa
        </Button>
        <Button
          data-hook="close-picker"
          secondary
          onClick={() => {
            onCancel()
          }}>
          Atrás
        </Button>
      </ModalActions>
    </Modal>
  )
}

TariffPickerModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func,
  isConvergent: PropTypes.bool,
  onSelectTariff: PropTypes.func,
  tariffs: PropTypes.array,
  selectedTariff: PropTypes.any,
  isLoadingTariffs: PropTypes.bool,
  error: PropTypes.any,
  segment: PropTypes.string,
}
