import { get } from 'lodash'

import {
  PUT_ALARMS_SALES_START,
  PUT_ALARMS_SALES_SUCCESS,
  PUT_ALARMS_SALES_FAILED,
  RESET_ALARMS_SALES,
} from './constants'

const initialState = {
  alarms: {
    loading: false,
    error: null,
  },
}

export const cadenceReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case PUT_ALARMS_SALES_START: {
      return {
        ...state,
        alarms: {
          ...initialState.alarms,
          loading: true,
        },
      }
    }
    case PUT_ALARMS_SALES_SUCCESS: {
      return {
        ...state,
        alarms: {
          ...initialState.alarms,
          data: { ...state.data, ...payload },
        },
      }
    }
    case PUT_ALARMS_SALES_FAILED: {
      return {
        ...state,
        alarms: {
          ...initialState.alarms,
          error: get(payload, 'errorCadence') || payload,
        },
      }
    }
    case RESET_ALARMS_SALES: {
      return {
        ...state,
        alarms: {
          ...initialState.alarms,
        },
      }
    }
    default:
      return state
  }
}
