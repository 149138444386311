import PropTypes from 'prop-types'
import { SIGNUP_TYPE_PORTABILITY, SIGNUP_TYPE_NEWLINE, SIGNUP_TYPE_NEW } from './signUpTypes'

export const signUpType = PropTypes.oneOf([
  SIGNUP_TYPE_PORTABILITY,
  SIGNUP_TYPE_NEWLINE,
  SIGNUP_TYPE_NEW,
  '',
])

export const CustomPropTypes = {
  signUpType,
}
