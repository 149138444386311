import { ibanNumberRegexp } from 'modules/Iban'
import * as Yup from 'yup/lib'
import { REQUIRED_FIELD_TEXT, INVALID_IBAN_NUMBER } from 'modules/SharedSales/constants'

import { ShippingSchema } from './Shipping.validation'

export const BillingSchema = {
  shipping: Yup.object({ ...ShippingSchema }),
  terminalShipping: Yup.object({ ...ShippingSchema }),
  billing: Yup.object({
    ibanNumber: Yup.string()
      .matches(ibanNumberRegexp, INVALID_IBAN_NUMBER)
      .required(REQUIRED_FIELD_TEXT),

    ...ShippingSchema,
  }),
}
