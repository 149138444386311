import { call, put, takeEvery, select } from 'redux-saga/effects'
import { logError, formatErrorMessage } from 'services/logging'
import { axiosFactory } from 'modules/axios'
import { selectCustomerId, selectDocumentId, selectAgentToken } from 'modules/Auth/store'
import { LEGACY_CUSTOMER_TOKEN } from 'modules/Auth/constants'

import { selectURLDocumentId } from 'modules/CustomerDocument/selectors'

import { get } from 'lodash'
import { getIdentificationType } from 'services/customer-360/selectors'
import { actions } from './actions'
import { constants } from './constants'
import { find, findNotClientWithoutOrders, fetchVipTypeClient } from './api'
import { helpers } from './helpers'

function* findSaga({ payload }) {
  try {
    if (get(payload, 'customerId') !== LEGACY_CUSTOMER_TOKEN) {
      const customerId = yield select(selectCustomerId)
      const documentId = yield select(selectDocumentId)

      const data = yield call(find, customerId, documentId)

      yield put(actions.findSuccess(data))
    } else {
      const documentId = yield select(selectURLDocumentId)
      const agentToken = yield select(selectAgentToken)

      const data = yield call(findNotClientWithoutOrders, 'customerId', documentId, agentToken)

      yield put(actions.findSuccess(data))
    }
  } catch (e) {
    yield call(logError, { e: new Error(formatErrorMessage(e)), payload })
    const error = axiosFactory.buildError(e)
    yield put(actions.findFailed(error))
  }
}

function* watchFind() {
  yield takeEvery(constants.FIND_INIT, findSaga)
}

function* fetchVipTypeSaga(action) {
  try {
    const documentType = yield select(getIdentificationType)
    const vipTypeResponse = yield call(fetchVipTypeClient, documentType, action.payload.documentId)
    if (!vipTypeResponse) {
      throw new Error('Empty vip type client response')
    }

    yield put(actions.fetchVipTypeSuccess(helpers.getVipTypeFromResponse(vipTypeResponse)))
  } catch (e) {
    yield call(logError, new Error(formatErrorMessage(e)))
    const error = axiosFactory.buildError(e)
    yield put(actions.fetchVipTypeFailed(error))
  }
}

function* watchFetchVipTypeSaga() {
  yield takeEvery(constants.FETCH_VIP_TYPE, fetchVipTypeSaga)
}

export const saga = {
  findSaga,
  watchFind,
  fetchVipTypeSaga,
  watchFetchVipTypeSaga,
}
