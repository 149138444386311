import { call, takeEvery, put, select } from 'redux-saga/effects'
import { get } from 'lodash'
import { LOCATION_CHANGE } from 'connected-react-router'
import { selectURLDocumentId } from 'modules/CustomerDocument/selectors'
import { getURLSearchParams } from 'modules/url'
import { callSaga } from 'services/customer-360/call'
import { authService } from '../services'

import { selectIsLogged, selectDocumentId } from './selectors'
import { actions, AUTH_CUSTOMER_INIT } from './actions'
import { LEGACY_CUSTOMER_TOKEN } from '../constants'

function* routerCustomerTokenSaga() {
  const pathDocumentId = yield select(selectURLDocumentId)
  const currentDocumentId = yield select(selectDocumentId)
  const isAgentLogged = yield select(selectIsLogged)

  if (isAgentLogged && pathDocumentId && pathDocumentId !== currentDocumentId) {
    yield put(actions.authCustomerInit(pathDocumentId))
  }
}

export function* watchCustomereTokenLocationChange() {
  yield takeEvery(action => {
    const params = getURLSearchParams(get(action, 'payload.location.search', '') || '')
    // Only consider to call routerCustomerTokenSaga if initialiseAuth is not going
    // to be called.
    // watchAuthLocationChange and watchCustomereTokenLocationChange should work complementarily.
    // At least they way they are implemented right now.
    return (
      action.type === LOCATION_CHANGE &&
      action.payload.action !== 'REPLACE' &&
      !(
        get(action, 'payload.isFirstRendering', true) ||
        (params.get('sfid') && params.get('uuid')) ||
        params.get('token')
      )
    )
  }, routerCustomerTokenSaga)
}

function* customerTokenSaga({ payload }) {
  const { documentId, changeServices } = payload
  let customerTokenError
  let accountInfoError
  const pathDocumentId = yield select(selectURLDocumentId)

  if (changeServices) {
    try {
      const customerToken = yield call(authService.fetchCustomerToken, documentId)
      yield put(
        actions.authCustomerSuccess({
          ...customerToken,
          documentId: pathDocumentId,
        }),
      )
    } catch (e) {
      customerTokenError = e
    }

    if (customerTokenError) {
      try {
        const error = yield call(callSaga, LEGACY_CUSTOMER_TOKEN)
        if (error) {
          yield put(actions.authCustomerError(error))
        } else {
          yield put(
            actions.authCustomerSuccess({
              customerToken: LEGACY_CUSTOMER_TOKEN,
              documentId: pathDocumentId,
              customerId: LEGACY_CUSTOMER_TOKEN,
              accountId: LEGACY_CUSTOMER_TOKEN,
              isCustomer: false,
            }),
          )
        }
      } catch (e) {
        yield put(actions.authCustomerError(e))
      }
    }
  }

  try {
    const accountInfo = yield call(authService.fetchAccountInfo, documentId)

    yield put(
      actions.authCustomerSuccess({
        ...accountInfo,
        documentId: pathDocumentId,
      }),
    )
  } catch (e) {
    accountInfoError = e
  }

  if (accountInfoError) {
    try {
      yield put(actions.authCustomerError(accountInfoError))
    } catch (e) {
      yield put(actions.authCustomerError(e))
    }
  }

  // TODO: fix error case (customer not found)
  yield put(actions.authFinish())
}

export function* watchCustomerTokenInit() {
  yield takeEvery(AUTH_CUSTOMER_INIT, customerTokenSaga)
}
