export const cancelOrderBusActionTypes = {
  CANCEL_ORDER_BUS: 'OVID/ORDERS-BUS/CANCEL_ORDER_BUS' ,
  CANCEL_ORDER_BUS_SUCCESS: 'OVID/ORDERS-BUS/CANCEL_ORDER_BUS_SUCCESS',
  CANCEL_ORDER_BUS_ERROR: 'OVID/ORDERS-BUS/CANCEL_ORDER_BUS_ERROR'
}

export const cancelOrderBusAction = (workOrderId, cancelationMotive) => ({
  type: cancelOrderBusActionTypes.CANCEL_ORDER_BUS,
  payload: {
    workOrderId,
    cancelationMotive,
  },
})

export const cancelOrderBusSuccessAction = data => ({
  type: cancelOrderBusActionTypes.CANCEL_ORDER_BUS_SUCCESS,
  payload: {
    data,
  },
})

export const cancelOrderBusErrorAction = error => ({
  type: cancelOrderBusActionTypes.CANCEL_ORDER_BUS_ERROR,
  payload: { error },
})
