import PropTypes from 'prop-types'
import { SectionHeader } from 'modules/ui'
import { history } from 'modules/Router'
import { ChangeOwnerCard } from '../ChangeOwnerCard'

export function ChangeOwnerContainer({ className }) {
  function newIssue() {
    console.warn('newIssue')
  }
  function goBack() {
    history.goBack()
  }

  const actions = [
    {
      label: 'CREAR TICKET',
      onClick: newIssue,
    },
  ]

  return (
    <div>
      <SectionHeader title="Cambio de titular" onBack={goBack} actions={actions} />
      <ChangeOwnerCard className={className} />
    </div>
  )
}

ChangeOwnerContainer.propTypes = {
  className: PropTypes.string,
}
