import { useState } from 'react'
import PropTypes from 'prop-types'
import { Grid, Typography } from '@material-ui/core'
import { TextStyles } from 'utils/text'
import { ButtonLink, Modal, ModalActions, Button } from 'components/ui'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
  masInfoButton: {
    minWidth: '60px',
    display: 'block',
    marginRight: '20px',
  },
  'sva-modal-description-item': {
    margin: '4px 0',
  },
})

export const SVAsModal = ({ commercialInfo }) => {
  const classes = useStyles()

  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <ButtonLink
        data-hook="sva-btn-description"
        className={classes.masInfoButton}
        onClick={() => setIsOpen(true)}>
        Más info
      </ButtonLink>
      <Modal isOpen={isOpen} data-hook="sva-modal-description" title="Detalles del producto">
        <Grid container>
          {commercialInfo.map((elem, i) => (
            <Grid item xs={12} key={`${elem}`} className={classes['sva-modal-description-item']}>
              <Typography
                {...(!i ? TextStyles.subtitle2Dark() : TextStyles.paragraphDark())}
                data-hook="sva-description">
                {elem}
              </Typography>
            </Grid>
          ))}
        </Grid>
        <ModalActions>
          <Button data-hook="sva-close-modal" onClick={() => setIsOpen(false)}>
            ACEPTAR
          </Button>
        </ModalActions>
      </Modal>
    </>
  )
}

SVAsModal.propTypes = {
  commercialInfo: PropTypes.array.isRequired,
}
