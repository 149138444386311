import { createGlobalStyle } from 'styled-components'
import PropTypes from 'prop-types'

export const RouterAnimationFade = createGlobalStyle`
  &.animate-enter,
  &.animate-exit {
    transition: opacity ${props => `${props.time / 1000}s`};
  }
  &.animate-enter,
  &.animate-exit-active {
    opacity: 0;
  }
  &.animate-enter-active {
    opacity: 1;
    z-index: 1;
  }
`

RouterAnimationFade.defaultValues = {
  time: 500,
}

RouterAnimationFade.propTypes = {
  time: PropTypes.number,
}
