/* eslint-disable */
let users = [{ id: 1, username: 'admin', password: 'admin', firstName: 'Admin', lastName: 'Admin' }]

const configureApiMock = () => {
  let realFetch = window.fetch
  window.fetch = function(url, opts) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (url.endsWith('/sessions') && opts.method === 'POST') {
          let params = JSON.parse(opts.body)

          let filteredUsers = users.filter(user => {
            return user.username === params.username && user.password === params.password
          })

          if (filteredUsers.length) {
            let user = filteredUsers[0]
            let responseJson = {
              id: user.id,
              username: user.username,
              firstName: user.firstName,
              lastName: user.lastName,
              token:
                'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE1MjUxMDYyMjE2MjZ9.j7ma8Z022dGBf7VA78Q6zwOaoUd6Z7X4AaxbzFb5Gf0',
            }
            resolve({ ok: true, json: () => responseJson })
          } else {
            reject('Username or password is incorrect')
          }
          return
        }

        if (url.match(/\/users\/\d+$/) && opts.method === 'DELETE') {
          if (opts.headers && opts.headers.Authorization === 'Bearer fake-jwt-token') {
            let urlParts = url.split('/')
            let id = parseInt(urlParts[urlParts.length - 1])
            for (let i = 0; i < users.length; i++) {
              let user = users[i]
              if (user.id === id) {
                users.splice(i, 1)
                localStorage.setItem('users', JSON.stringify(users))
                break
              }
            }

            resolve({ ok: true, json: () => ({}) })
          } else {
            reject('Unauthorised')
          }
          return
        }
        realFetch(url, opts).then(response => resolve(response))
      }, 500)
    })
  }
}

export { configureApiMock }
