import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { get } from 'lodash'
import * as offersDetailSvc from 'services/offers-detail'
import { OrderProductsMain } from '../../components/OrderProductsMain/OrderProductsMain'

export function OrderProductsContainer({ orderId, ...rest }) {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(offersDetailSvc.actions.findOrderProducts(orderId))
  }, [])

  const props = {
    loading: useSelector(offersDetailSvc.selectors.isLoading),
    errors: useSelector(offersDetailSvc.selectors.hasErrors),
    offers: get(useSelector(offersDetailSvc.selectors.getData), 'subscriptions'),
    ...rest,
  }

  return <OrderProductsMain {...props} />
}

OrderProductsContainer.propTypes = {
  orderId: PropTypes.string,
}
