import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import 'modules/Core/Styles/index.scss'

import { App } from './containers/App'
import configureStore from './bootstrap/configureStore'
import { configureApiMock } from './helpers'
// TODO remove me to use real  API
configureApiMock()

const { store, persistor } = configureStore()

if (!Promise.allSettled) {
  Promise.allSettled = promises =>
    Promise.all(
      promises.map(promise =>
        promise
          .then(value => ({
            status: 'fulfilled',
            value,
          }))
          .catch(reason => ({
            status: 'rejected',
            reason,
          })),
      ),
    )
}

const rootElement = document.getElementById('root')
ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App data={store} />
    </PersistGate>
  </Provider>,
  rootElement,
)
