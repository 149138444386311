export const FTTH = 'FTTH'
export const ADSL = 'ADSL'
export const NEBA = 'NEBA'
export const VULA = 'VULA'

export const NEBA_REGEXP = /neb/i
export const VULA_REGEXP = /nbl/i

export const FIBER = 'fiber'
export const ADSL_LOWER_CASED = 'adsl'
export const FIBRA = 'Fibra'

export const MOCK_TARIFF_ID = 'G053CHRN2CVP019'

export const PO = 'PO'
export const PV = 'PV'
