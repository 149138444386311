/**
 *
 * @param {*} parentEl // the parent DOM element where we will search scrollable DOM
 * @param  {[ReactComponentElement]} refs // (Optional) Set here explicit refs to apply scroll. Check  https://es.reactjs.org/docs/hooks-reference.html#useref
 */

function getParentScrollableNode(node) {
  if (node == null) {
    return null
  }

  if (node.scrollHeight > node.clientHeight) {
    return node
  } 
    return getParentScrollableNode(node.parentNode)
  
}

export function useScrollToTop() {
  function scrollToTop(element) {
    let node

    if (element) {
      node = getParentScrollableNode(element)
    } else {
      const ele = document.querySelector('[scrolltotop="true"]')
      node = getParentScrollableNode(ele)
    }

    if (node) {
      node.scrollTo(0, 0)
    }
  }

  return { scrollToTop }
}
