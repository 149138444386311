export function getCleanAccount(account, segment) {
  function reducer(acc, [key, value]) {
    let newValue
    switch (key) {
      case 'segment':
        newValue = segment
        break
      case 'language':
      case 'phoneContact2':
      case 'email':
        newValue = value
        break
      case 'documentid':
      case 'companyCif':
      case 'clientExistsError':
        newValue = ''
        break
      default:
        newValue = ''
        break
    }
    acc[key] = newValue
    return acc
  }
  const cleanAccount = Object.entries(account).reduce(reducer, {})
  return cleanAccount
}
