import { get, head, isEmpty } from 'lodash'
import { SVA_HOMEGO_CODE } from 'modules/offers-configuration/constants'
import { formatRawText, formatRange } from 'utils'
import { getTariffFeesAndDiscounts } from 'modules/tariffs/helpers'
import { roundTwoDecimals, formatFee } from 'utils/formatting/index'
import { addMonths, parseISO } from 'date-fns'
import { removeIncludedAmount } from 'modules/tariffs/helpers/tariffs.helpers'
import { HOME_GO_GROUP_SVA } from '../constants'

import { getPriceWithTax } from '../../Taxes'

export function formatSingleSVA(sva, tax) {
  if (!sva.poId) return sva

  const {
    commercialInfo,
    commercialInfo: { name },
    terms,
  } = sva

  const formattedSva = removeIncludedAmount(sva, tax)

  const duration = get(terms, 'count', 0)

  const { fees } = getTariffFeesAndDiscounts(formattedSva, tax || 0)

  const price = roundTwoDecimals(fees.length > 0 ? head(fees).amount : 0)

  const commercialInfoFormated = formatRawText(get(commercialInfo, 'description', ''), '\n\n')

  const priceTerms =
    sva?.terms && getPriceWithTax({ taxFreeAmount: sva.terms.taxFreeAmount, taxRate: tax }, tax)

  return {
    ...sva,
    commercialInfoFormated,
    duration,
    name,
    price: {
      taxFreeAmount: price,
      tax,
    },
    monthlyFeeWithTax: price,
    monthlyFee: sva.prices?.length > 0 ? sva.prices[0].taxFreeAmount : null,
    terms: {
      ...sva.terms,
      ...(priceTerms
        ? {
            taxIncludedAmount: roundTwoDecimals(priceTerms),
          }
        : {}),
    },
  }
}

export function formatSVAS(SVAS, tax) {
  return SVAS.map(elem => {
    return formatSingleSVA(elem, tax)
  })
}

export function addPriceTaxSvas(svas, tax) {
  return svas.map(sva => {
    const price = getPriceWithTax({ taxFreeAmount: sva.prices.taxFreeAmount, taxRate: tax }, tax)

    const priceTerms =
      sva?.terms && getPriceWithTax({ taxFreeAmount: sva.terms.taxFreeAmount, taxRate: tax }, tax)

    return {
      ...sva,
      price: {
        taxFreeAmount: price,
      },
      monthlyFeeWithTax: price,
      terms: {
        ...sva.terms,
        ...(priceTerms
          ? {
              taxIncludedAmount: priceTerms,
            }
          : {}),
      },
    }
  })
}

export const mapSvasWithHomeGo = svasList => {
  const homeGoSubSvas = []

  const svasWithouHomeGo = svasList.filter(sva => {
    if (sva.psId.includes(SVA_HOMEGO_CODE)) {
      homeGoSubSvas.push(sva)
      return false
    }
    return true
  })

  return isEmpty(homeGoSubSvas)
    ? svasWithouHomeGo
    : svasWithouHomeGo.concat([
        {
          ...HOME_GO_GROUP_SVA,
          subSvas: homeGoSubSvas,
        },
      ])
}

export const getPenaltyTexts = svaHomeGo => {
  const totalStayTime =
    svaHomeGo?.stayStatus?.totalStayTime || svaHomeGo?.stayStatus?.stayPeriod || 0
  const stayCost = formatFee(svaHomeGo?.stayStatus?.stayCost)

  const homeGoPenaltyCancellation = `Al haber contraído un descuento especial por ser cliente de Yoigo en la cuota y la instalación,
  el cliente ha asumido un compromiso de permanencia de ${totalStayTime} meses asociado al servicio de la alarma. En caso de causar baja
  durante el periodo de permanencia se le cobrará un importe total de ${stayCost} al cliente (150€ derivados
  de costes de instalación más el restante de la penalización por permanencia).`

  return svaHomeGo?.contractTypeId?.includes(SVA_HOMEGO_CODE) ? [homeGoPenaltyCancellation] : []
}

export const getTextTotalStayTime = sva => {
  const totalStayTime = sva?.stayStatus?.totalStayTime

  const totalStayTextMeses = totalStayTime > 1 ? 'meses' : 'mes'

  return totalStayTime ? `${+totalStayTime} ${totalStayTextMeses}` : '-'
}

export const getTextCalculatedStayTime = sva => {
  const { totalStayTime, stayPeriod } = sva?.stayStatus || {}

  const stayTextMeses = totalStayTime - stayPeriod > 1 ? 'meses' : 'mes'

  if (stayPeriod === 0) {
    return `${stayPeriod} ${stayTextMeses}`
  }

  return totalStayTime - stayPeriod ? `${+(totalStayTime - stayPeriod)} ${stayTextMeses}` : '-'
}

export const getFormatPermanentDateRange = sva => {
  const startDate = parseISO(get(sva, 'validFrom'))
  const totalStayTime = sva?.stayStatus?.totalStayTime
  return startDate && totalStayTime
    ? formatRange(startDate, addMonths(startDate, totalStayTime))
    : '-'
}

export const isBibeAllowed = sfid => /^SRV/.test(sfid)
