import { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { orderBy, get, isEmpty, first, last } from 'lodash'
import styled from 'styled-components'
import { isAfter, isBefore, parse, addDays, parseISO } from 'date-fns'
import { FiltersSidebar } from './FiltersSidebar'
import { DateHeaderItem } from './DateHeaderItem'

export const ListWithFilters = styled(
  ({
    items,
    ItemComponent,
    dataTree,
    filters,
    checkboxfilters,
    testState,
    setState,
    onChangeStartDate,
    onChangeEndDate,
    filtersSelected,
    customFiltersSelected,
    onResetFilters,
    className,
  }) => {
    const itemsFiltered = items.map(itemByDay => {
      const date = first(itemByDay)
      const itemInfo = last(itemByDay).filter(item => {
        const dateInfo = parseISO(get(item, 'date'))
        const startDate = filters.startDate || parse('01/01/1900', 'MM/dd/yyyy', Date.now())
        const endDate = filters.endDate
          ? addDays(filters.endDate, 1)
          : parse('01/01/3000', 'MM/dd/yyyy', Date.now())
        const isTypeOf =
          filters.filteredTypes.includes(get(item, 'type')) ||
          filters.filteredTypes.includes(get(item, 'subType'))

        let showAllWithoutCustomFilters = false

        const showAllWithoutFilters = filters.filteredTypes.length ? isTypeOf : true

        if (filters.customFilteredTypes) {
          const isIn = customFiltersSelected.some(
            customFilteredType => item[customFilteredType.toLowerCase()],
          )
          showAllWithoutCustomFilters = customFiltersSelected.length ? isIn : true
        }

        return (
          isAfter(dateInfo, startDate) &&
          isBefore(dateInfo, endDate) &&
          showAllWithoutFilters &&
          (filters.customFilteredTypes ? showAllWithoutCustomFilters : true)
        )
      })
      return [date, itemInfo]
    })

    const itemsOrderByDate = orderBy(itemsFiltered, ['date'], ['desc'])

    const itemList = useRef(null)

    useEffect(() => {
      itemList.current.scrollTo(0, 0)
    }, [items])

    return (
      <div className={`full-width flex ${className}`}>
        <div className="left-item-wrapper">
          <FiltersSidebar
            startDate={filters.startDate}
            dataTree={dataTree}
            testState={testState}
            setState={setState}
            endDate={filters.endDate}
            filters={checkboxfilters}
            onChangeStartDate={onChangeStartDate}
            onChangeEndDate={onChangeEndDate}
            filtersSelected={filtersSelected}
            customFiltersSelected={customFiltersSelected}
            onResetFilters={onResetFilters}
          />
        </div>
        <div className="right-item-wrapper">
          <ul className="styled-list" ref={itemList}>
            {itemsOrderByDate.map((dayGroup, i) => {
              const date = first(dayGroup)
              const itemsPerDay = last(dayGroup)
              return !isEmpty(itemsPerDay) ? (
                <div key={i.toString()}>
                  <DateHeaderItem date={date} />
                  {itemsPerDay.map((item, j) => (
                    <ItemComponent
                      data={item}
                      dataConsumed={get(item, 'data_amount')}
                      key={j.toString()}
                    />
                  ))}
                </div>
              ) : null
            })}
          </ul>
        </div>
      </div>
    )
  },
)`
  .left-item-wrapper {
    flex: 1;
    width: 100%;
  }

  .styled-list {
    max-height: calc(100vh - 350px);
    overflow: auto;
  }
  .right-item-wrapper {
    flex: 3;
    border-left: 2px solid ${props => get(props, 'theme.palette.global.gray_medium_02')};
    @media (max-height: 960px) {
      .styled-list {
        max-height: 620px;
      }
    }
  }
`

ListWithFilters.propTypes = {
  data: PropTypes.object,
  dataTree: PropTypes.array,
  ItemComponent: PropTypes.object,
  filters: PropTypes.object,
  customFilters: PropTypes.array,
  checkboxfilters: PropTypes.array,
  filtersSelected: PropTypes.array,
  customFiltersSelected: PropTypes.array,
  onChangeStartDate: PropTypes.func,
  onChangeEndDate: PropTypes.func,
  onResetFilters: PropTypes.func,
  testState: PropTypes.object,
  setState: PropTypes.func,
  className: PropTypes.string,
}
