import { Component } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { format } from 'date-fns'
import { es } from 'date-fns/locale'

import { Box, Divider } from '@material-ui/core'
import { CardTitle, CardRow, CardRowLabel, CardRowValue, WizardNavigation } from 'components'
import { appointmentActions } from 'modules/Appointments'
import { selectNewAppointment } from 'modules/NewClientSales/store/selectors'
import { KairosAppointment } from 'modules/kairos-appointment/components/KairosAppointment'
import { showCoverageStreet } from '../AddressChange/address.helper'

class NewAppointment extends Component {
  state = {
    appointmentFinished: false,
    validAppointment: null,
  }

  setAppointmentError = error => {
    const dispatch = useDispatch()
    dispatch(appointmentActions.setAppointmentError(error))
  }

  appointmentData = () => useSelector(selectNewAppointment)

  onCloseAppointment = () => {
    this.setState({
      appointmentFinished: true,
    })
  }

  onAppointmentFinish = () => this.onCloseAppointment()

  onAppointmentFailed = error => {
    this.onAppointmentFinish()

    this.setAppointmentError(error)
  }

  validationAppointment = () => {
    const { token, appointmentState, gescal17Order, gescal17NewDirection } = this.props
    const getGescalOrder = gescal17Order && gescal17Order.split(' ')[0]
    const getGescalNewDirection = gescal17NewDirection && gescal17NewDirection.split(' ')[0]
    if (token && getGescalNewDirection === getGescalOrder && appointmentState === 'Citada') {
      return false
    }
    if (token && getGescalNewDirection === getGescalOrder && appointmentState === 'No citada') {
      return true
    }
    return true
  }

  formatDatesAppointment = data => {
    const startDate = get(data, 'startDate', new Date())
    const endDate = get(data, 'endDate', new Date())

    return `
    ${format(new Date(startDate), 'MMMM d, yyyy HH:mm a', { locale: es })} -
    ${format(new Date(endDate), 'HH:mm a')}`
  }

  disabledButton = () => {
    const { appointmentFinished, validAppointment } = this.state

    if (appointmentFinished === true) {
      return false
    }
    if (validAppointment !== null) {
      return false
    }

    if (!this.validationAppointment()) {
      return false
    }

    return true
  }

  render() {
    const {
      newAppointmentData,
      next,
      coverageData,
      appointmentData = { startDate: null, endDate: null },
    } = this.props

    const props = {
      data: newAppointmentData,
      onSuccess: this.onAppointmentFinish,
      onError: this.onAppointmentFailed,
    }

    return (
      <>
        <CardTitle>DIRECCIÓN NUEVA</CardTitle>
        <CardRow>
          <CardRowLabel>Dirección de instalación:</CardRowLabel>
          <CardRowValue>{showCoverageStreet(coverageData)}</CardRowValue>
        </CardRow>
        <Box mt="15px" mb="30px">
          <Divider />
        </Box>
        <CardTitle>INSTALACIÓN</CardTitle>
        <CardRow>
          <CardRowLabel>Cita de instalación:</CardRowLabel>
          <CardRowValue>
            {appointmentData.startDate && appointmentData.endDate
              ? this.formatDatesAppointment(appointmentData)
              : 'aún no seleccionada'}
          </CardRowValue>
        </CardRow>
        {this.validationAppointment() ? <KairosAppointment show {...props} /> : next}
        <WizardNavigation
          next={{
            callback: next,
            disabled: this.disabledButton(),
            caption: 'Confirmar cita',
          }}
        />
      </>
    )
  }
}

export default NewAppointment

NewAppointment.propTypes = {
  coverageData: PropTypes.object,
  next: PropTypes.func.isRequired,
  token: PropTypes.string,
  appointmentData: PropTypes.object,
  gescal17Order: PropTypes.string,
  gescal17NewDirection: PropTypes.string,
  appointmentState: PropTypes.string,
  newAppointmentData: PropTypes.object,
}
