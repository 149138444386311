import { constants } from './constants'

const findInit = customerId => ({
  type: constants.FIND_INIT,
  payload: {
    customerId,
  },
})

const find = customerId => ({
  type: constants.FIND,
  payload: {
    loading: true,
    customerId,
  },
})

const findSuccess = data => ({
  type: constants.FIND_SUCCESS,
  payload: {
    ...data,
  },
})

const findFailed = error => ({
  type: constants.FIND_FAILED,
  payload: { error },
})

const reset = () => ({
  type: constants.RESET,
})

export const actions = {
  findInit,
  find,
  findSuccess,
  findFailed,
  reset,
}
