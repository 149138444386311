import { constants } from './constants'

const initialState = {
  discounts: [],
  selectedDiscount: null,
  error: null,
  loading: false,
  put: {
    loading: false,
    response: null,
    error: null,
  },
}

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case constants.GET_DISCOUNTS: {
      return {
        ...initialState,
        loading: true,
      }
    }
    case constants.GET_DISCOUNTS_SUCCESS: {
      return {
        ...state,
        discounts: payload.discounts,
        loading: false,
      }
    }

    case constants.GET_DISCOUNTS_FAILED: {
      return {
        ...state,
        error: payload.error,
        loading: false,
      }
    }

    case constants.SELECT_DISCOUNT: {
      return {
        ...state,
        selectedDiscount: payload.discount,
      }
    }

    case constants.RESET_DISCOUNTS: {
      return {
        ...initialState,
      }
    }

    case constants.PUT_DISCOUNTS: {
      return {
        ...state,
        put: {
          ...initialState.put,
          loading: true,
        },
      }
    }

    case constants.PUT_DISCOUNTS_SUCCESS: {
      return {
        ...state,
        put: {
          ...state.put,
          response: payload.response,
          loading: false,
        },
      }
    }

    case constants.PUT_DISCOUNTS_FAILED: {
      return {
        ...state,
        put: {
          ...state.put,
          error: payload.error,
          loading: false,
        },
      }
    }

    default:
      return state
  }
}
