import { call, takeLatest, put } from 'redux-saga/effects'
import * as logisticOrdersSvc from 'services/logistic-orders'
import { actions } from './actions'
import { constants } from './constants'

function* ordersMainTabLoadSaga({ payload: { orderId, msisdn } }) {
  try {
    // Logistic for product
    const errorLogistic = yield call(logisticOrdersSvc.callSaga, orderId, msisdn)
    if (errorLogistic) {
      const payload = {
        error: {
          errorLogistic,
        },
      }

      return yield put(actions.loadFailed(payload))
    }

    return yield put(actions.loadSucceeded())
  } catch (e) {
    const payload = {
      error: {
        all: {
          msg: e,
        },
      },
    }

    return yield put(actions.loadFailed(payload))
  }
}

export function* watchLoad() {
  yield takeLatest(constants.LOAD_INIT, ordersMainTabLoadSaga)
}
