import { call, put, takeEvery } from 'redux-saga/effects'
import { get } from 'lodash'
import { logError, formatErrorMessage } from 'services/logging'
import { axiosFactory } from 'modules/axios'
import { actions } from './actions'
import { constants } from './constants'
import { getDiscountsIncompatibilities } from './api'

export function* getDiscountsIncompatibilitiesSaga({
  payload: { newDiscountCode, currentDiscountsCode },
}) {
  try {
    const data = yield call(getDiscountsIncompatibilities, newDiscountCode, currentDiscountsCode)
    const discountsIncompatibilities = get(data, 'discountIncompatibilities', [])
    yield put(
      actions.getDiscountsIncompatibilitiesSuccess(newDiscountCode, discountsIncompatibilities),
    )
  } catch (e) {
    yield call(logError, {
      e: new Error(formatErrorMessage(e)),
      newDiscountCode,
      currentDiscountsCode,
    })
    const error = axiosFactory.buildError(e)
    yield put(actions.getDiscountsIncompatibilitiesFailed(error))
  }
}

export function* watchGetDiscountsIncompatibilities() {
  yield takeEvery(constants.GET_DISCOUNTS_INCOMPATIBILITIES_INIT, getDiscountsIncompatibilitiesSaga)
}
