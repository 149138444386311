import { axiosJWT } from 'modules/axios'
import { APP_CONFIG } from 'services/app-config'
import { get } from 'lodash'

export function applyCreditNote(customerData, creditNoteData) {
  return axiosJWT({
    method: 'POST',
    url: `${APP_CONFIG.care_cluster}/search-360/product/credit`,
    data: {
      data: {
        type: 'subscriptions-apply-credit-note',
        attributes: {
          'subscription-invoice': get(creditNoteData, 'invoiceId'),
          username: get(customerData, 'sfid'),
          documentId: get(customerData, 'documentId'),
          channel: get(customerData, 'profileId'),
          externalId: get(creditNoteData, 'externalId'),
          msisdn: get(creditNoteData, 'msisdn'),
          'reason-category': get(creditNoteData, 'reason-category'),
          reason: get(creditNoteData, 'reason'),
          remark: get(creditNoteData, 'remark'),
          'sub-invoices': get(creditNoteData, 'subInvoices'),
        },
      },
    },
  })
}
