export const d2dActionTypes = {
  NOTIFY_D2D_ORDER_FINISHED: 'D2D/NOTIFY_D2D_ORDER_FINISHED',
  NOTIFY_D2D_CANCEL: 'D2D/NOTIFY_D2D_CANCEL',
  NOTIFY_D2D_USER_LOGOUT: 'D2D/NOTIFY_USER_LOGOUT',
  NOTIFY_D2D_HOME: 'D2D/NOTIFY_D2D_HOME',
}

export const notifyD2DOrderFinishedAction = payload => ({
  type: d2dActionTypes.NOTIFY_D2D_ORDER_FINISHED,
  payload,
})

export const notifyD2DOrderCancelAction = payload => ({
  type: d2dActionTypes.NOTIFY_D2D_CANCEL,
  payload,
})

export const notifyD2DHomeAction = payload => ({
  type: d2dActionTypes.NOTIFY_D2D_HOME,
  payload,
})

export const notifyD2DUserLogoutAction = () => ({
  type: d2dActionTypes.NOTIFY_D2D_USER_LOGOUT,
})
