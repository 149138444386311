import { put, select, take } from 'redux-saga/effects'

import { GET_PONC_SUCCESS, GET_PONC_FAILED, getPoncInitAction } from './ponc.actions'
import { selectErrorCode } from './ponc.selectors'

export function* callPoncSaga(workOrderID) {
  yield put(getPoncInitAction(workOrderID))
  yield take([GET_PONC_SUCCESS, GET_PONC_FAILED])
  return yield select(selectErrorCode)
}
