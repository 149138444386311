import PropTypes from 'prop-types'

import { get } from 'lodash'
import { connect, getIn } from 'formik'
import { Box, FormControl, FormLabel, RadioGroup } from '@material-ui/core'

import FormError from '../forms/FormError'

const FormikRadio = ({
  theme,
  formik: { errors, values },
  formik,
  label,
  children,
  name,
  isDisabled,
  disabled,
  dataHook,
  row,
  onChange,
}) => {
  const value = getIn(values, name)
  const error = getIn(errors, name)
  const touched = getIn(formik.touched, name)

  const handleChangeRadio = e => {
    formik.handleChange(e)
    if (onChange) {
      onChange(e)
    }
  }

  const isMissing = get(isDisabled, 'misses') ? !getIn(values, isDisabled.misses) : false
  const hasValError = get(isDisabled, 'error') ? !!getIn(errors, isDisabled.error) : false

  return (
    <Box display="flex" alignItems="center">
      {label && (
        <div className="p-r-36">
          <FormLabel className={touched && error ? 'error-label' : 'label'}>{label}</FormLabel>
          {touched && error && <FormError fullwidth>{error}</FormError>}
        </div>
      )}
      <FormControl
        data-hook={dataHook}
        component="fieldset"
        disabled={isMissing || disabled || hasValError || isDisabled?.bool}>
        <RadioGroup
          theme={theme}
          aria-label={label}
          onChange={handleChangeRadio}
          value={value}
          name={name}
          row={row}
          label={label}>
          {children}
        </RadioGroup>
      </FormControl>
    </Box>
  )
}

FormikRadio.propTypes = {
  theme: PropTypes.object,
  formik: PropTypes.object,
  error: PropTypes.any,
  label: PropTypes.string,
  children: PropTypes.any,
  name: PropTypes.string,
  isDisabled: PropTypes.any,
  disabled: PropTypes.any,
  dataHook: PropTypes.string,
  row: PropTypes.any,
  onChange: PropTypes.func,
}

export default connect(FormikRadio)
