import { createStructuredSelector } from 'reselect'

import { isChangeOwnerEnabled } from 'modules/change-owner'

import { selectCanIDO, permissions } from 'modules/Permissions'
import { selectEnergyIsAllowed } from 'modules/energy/store/energy.selectors'
import { selectGdprClientConsentsFlag } from 'services/feature-flag/selectors'
import { selectClientVerbalId, selectClientSubscriptionId } from 'modules/mas-consents/selectors'

import * as saga from '../../sagas/clientDashboardLoad'
import { extractPersonalData } from '../../selectors/selectors'

export const clientMainContainerSelectors = createStructuredSelector({
  isLoading: saga.selectors.isLoading,
  hasErrors: saga.selectors.hasErrors,
  dataCardClients: extractPersonalData,
  enableChangeOwner: isChangeOwnerEnabled,
  canModifyContactEmail: selectCanIDO(permissions.cvm_modify_contact_email.id),
  canModifyContactPhoneNumbers: selectCanIDO(permissions.cvm_modify_contact_phone_numbers.id),
  energyIsAllowed: selectEnergyIsAllowed,
  gdprConsentsAllowed: selectGdprClientConsentsFlag,
  verbalId: selectClientVerbalId,
  subscriptionId: selectClientSubscriptionId,
})
