import { useState } from 'react'
import PropTypes from 'prop-types'

import { isEqual } from 'lodash'
import { useSelector } from 'react-redux'

import { permissions, selectCanIDO } from 'modules/Permissions'

import { selectors as customer360SvcSelectors } from 'services/customer-360/selectors'
import { getActiveUpsellAndNotUpsellBonus } from 'services/subscriptions'

import { use1wSales } from 'modules/offers-configuration/hooks/use1wSales/use1wSales'
import { getCompatibleCurrentDiscounts, hasDiscountIncompatibilities } from '../selectors'
import { shouldApplyMultipleDiscounts, getIsPrepaidTariff } from '../helpers'

import { DiscountsAndBonusesSelected } from '../components/DiscountsAndBonusesSelected'

export const DiscountsAndBonusesSelectedContainer = ({
  subscription,
  newTariffId,
  newPaymentType,
  ...restProps
}) => {
  const [selectedDiscounts, setSelectedDiscounts] = useState([])
  const [preSelectedDiscount, setPreSelectedDiscount] = useState(null)
  const [selectedBonuses, setSelectedBonuses] = useState([])

  const onDiscountCancel = discount => {
    setSelectedDiscounts(selectedDiscounts.filter(d => !isEqual(d, discount)))
    setPreSelectedDiscount(null)
  }

  const onDiscountPreSelect = value => {
    setPreSelectedDiscount(value)
  }

  const onDiscountSelect = () => {
    setSelectedDiscounts([...selectedDiscounts, preSelectedDiscount])
    setPreSelectedDiscount(null)
  }

  const onBonusCancel = bonus => {
    setSelectedBonuses(selectedBonuses.filter(d => !isEqual(d, bonus)))
  }

  const onBonusSelect = bonus => {
    setSelectedBonuses([bonus])
  }

  const [isOpen, setIsOpen] = useState(false)
  const [type, setType] = useState('')

  const openPopover = value => {
    setIsOpen(true)
    setType(value)
  }

  const closePopover = () => {
    setIsOpen(false)
  }

  const currentBonuses = [...getActiveUpsellAndNotUpsellBonus(subscription)]

  const compatibleCurrentDiscounts = useSelector(state =>
    getCompatibleCurrentDiscounts(state, subscription, {
      selectedDiscounts,
      newTariffId,
    }),
  )

  const hasIncompatibilities = useSelector(state =>
    hasDiscountIncompatibilities(state, subscription, {
      selectedDiscounts,
      preSelectedDiscount,
      newTariffId,
    }),
  )

  const segment = useSelector(customer360SvcSelectors.getSegment)

  const canIAddBonus = useSelector(selectCanIDO(permissions.cvm_ops_add_bundles_all.id))

  const canIAddAllDiscounts = useSelector(selectCanIDO(permissions.cvm_ops_add_discounts_all.id))
  const canIAddRetentionDiscounts = useSelector(
    selectCanIDO(permissions.cvm_ops_add_retention_discounts.id),
  )
  const canIAddSomeDiscounts = canIAddAllDiscounts || canIAddRetentionDiscounts

  const isPrepaidTariff = getIsPrepaidTariff(subscription, newPaymentType)

  const canAddBonus = !isPrepaidTariff
  const canAddDiscount = shouldApplyMultipleDiscounts(segment, selectedDiscounts, isPrepaidTariff)

  const is1wSales = use1wSales()

  const props = {
    compatibleCurrentDiscounts,
    onDiscountSelect,
    onDiscountPreSelect,
    hasIncompatibilities,
    selectedDiscounts,
    currentBonuses,
    selectedBonuses,
    onDiscountCancel,
    isOpen,
    type,
    openPopover,
    closePopover,
    onBonusSelect,
    onBonusCancel,
    subscription,
    canAddBonus: canAddBonus && canIAddBonus,
    canAddDiscount: canAddDiscount && canIAddSomeDiscounts || is1wSales,
    newPaymentType,
    ...restProps,
  }

  return <DiscountsAndBonusesSelected {...props} />
}

DiscountsAndBonusesSelectedContainer.propTypes = {
  subscription: PropTypes.object.isRequired,
  newTariffId: PropTypes.string,
  newPaymentType: PropTypes.string,
}
