import {
  FETCH_CUSTOMER_CONTACT_MEDIA,
  FETCH_CUSTOMER_CONTACT_MEDIA_ERROR,
  FETCH_CUSTOMER_CONTACT_MEDIA_SUCCESS,
} from './constants'

export const fetchCustomerContactMedia = payload => ({
  type: FETCH_CUSTOMER_CONTACT_MEDIA,
  payload,
})

export const fetchCustomerContactMediaSuccess = payload => ({
  type: FETCH_CUSTOMER_CONTACT_MEDIA_SUCCESS,
  payload,
})

export const fetchCustomerContactMediaError = payload => ({
  type: FETCH_CUSTOMER_CONTACT_MEDIA_ERROR,
  payload,
})
