import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { getSubscriptions } from 'services/subscriptions/selectors'
import { getSegment } from 'services/customer-360/selectors'
import { SubscriptionsAside, SubscriptionsSummary, Button } from 'components/ui'
import { getSubscriptionsState, getCurrentCustomerTax } from '../selectors'
import SummaryActions from '../components/SummaryActions'
import LegalTextModal from '../components/LegalTextModal'
import SubscriptionsModifications from '../components/SubscriptionsModifications'
import {
  updateSubscriptions,
  clearAllSubscriptions,
  openTypification,
  setFinished,
} from '../actions'
import { useDynamicModals, DynamicModal } from '../hooks/useDynamicModals'
import { usePromosData } from '../hooks/usePromosData'
import { useOffersSummary } from '../hooks/useOffersSummary'
import { useFeeSummary } from '../hooks/useFeeSummary'
import { usePenaltyCondonation } from '../hooks/usePenaltyCondonation'

export const OffersSidebar = props => {
  const { modalProps, onModalOpen, onModalClose } = useDynamicModals()

  return (
    <SubscriptionsAside>
      <DynamicModal {...modalProps} />

      <SubscriptionsModifications modifiedSubscriptions={props.subscriptionsSummary} />
      <SubscriptionsSummary
        summary={props.feeSummary}
        hideSummary={!props.haveSubscriptionsChanged}
      />
      <SummaryActions>
        {props.modifiedSubscriptions.length ? (
          <>
            <Button
              fullWidth
              onClick={() =>
                onModalOpen(
                  LegalTextModal,
                  {
                    hideLegal: props.isOnlyPenaltyCondonation,
                    modifiedSubscriptions: props.modifiedSubscriptions,
                    onConfirm: () => {
                      props.applyTariffChanges(props.modifications)
                      onModalClose()
                    },
                  },
                  props.isOnlyPenaltyCondonation ? 'Confirmar cambios' : 'Textos legales',
                )
              }>
              Guardar cambios
            </Button>
            <Button secondary fullWidth onClick={props.clearAllSubscriptions}>
              Cancelar
            </Button>
          </>
        ) : (
          <Button
            fullWidth
            onClick={props.segment === 'CONSUMER' ? props.openTypification : props.setFinished}>
            {props.segment === 'CONSUMER' ? 'Tipificar y salir' : 'Salir'}
          </Button>
        )}
      </SummaryActions>
    </SubscriptionsAside>
  )
}

OffersSidebar.propTypes = {
  modifiedSubscriptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      description: PropTypes.string,
    }),
  ),
  subscriptionsSummary: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      description: PropTypes.string,
      promoFee: PropTypes.string,
      fee: PropTypes.number,
    }),
  ),
  isOnlyPenaltyCondonation: PropTypes.bool,
  applyTariffChanges: PropTypes.func.isRequired,
  clearAllSubscriptions: PropTypes.func.isRequired,
  openTypification: PropTypes.func.isRequired,
  setFinished: PropTypes.func.isRequired,
  modifications: PropTypes.object.isRequired,
  feeSummary: PropTypes.arrayOf(
    PropTypes.shape({
      duration: PropTypes.number.isRequired,
      monthlyFee: PropTypes.string.isRequired,
    }),
  ).isRequired,
  haveSubscriptionsChanged: PropTypes.bool.isRequired,
  segment: PropTypes.string,
}

export default () => {
  const dispatch = useDispatch()

  const subscriptions = useSelector(getSubscriptions)
  const segment = useSelector(getSegment)

  const modifications = useSelector(getSubscriptionsState)
  const tax = useSelector(getCurrentCustomerTax)

  const promosData = usePromosData(subscriptions, modifications, tax)
  const { modifiedSubscriptions, subscriptionsSummary } = useOffersSummary(
    subscriptions,
    modifications,
    promosData,
  )

  const { feeSummary } = useFeeSummary(subscriptions, modifications, tax)
  const { isOnlyPenaltyCondonation, haveSubscriptionsChanged } =
    usePenaltyCondonation(modifications)

  const props = {
    segment,
    modifications,
    modifiedSubscriptions,
    subscriptionsSummary,
    feeSummary,
    isOnlyPenaltyCondonation,
    haveSubscriptionsChanged,

    openTypification: () => dispatch(openTypification()),
    setFinished: () => dispatch(setFinished()),
    clearAllSubscriptions: () => dispatch(clearAllSubscriptions()),
    applyTariffChanges: tariffChanges => dispatch(updateSubscriptions(tariffChanges)),
  }

  return <OffersSidebar {...props} />
}
