import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/styles'

import { fieldNames } from 'modules/Lines/line.config'
import { IconButton } from 'components/ui'
import { storeSelectors } from './ExtraLine.selectors'
import { Line } from '../../../components/Offer/Line'
import { useSingleExtraMobileLogic, useOnDeleteLineError, useLimitInfo } from '../../../hooks/Lines'

const useStyles = makeStyles({
  'delete-extra-line': {
    position: 'absolute',
    right: '40px',
    top: '45px',
    zIndex: 9,
  },
})

export function ExtraLine({
  index,
  selectedTariff,
  error,
  isLoading,
  tariffList,
  onRemoveExtraTariff,
}) {
  const classes = useStyles()

  const { tax, isMobileOnly, isSubscription, isMultiSim } = useSelector(storeSelectors)
  const lineType = `extraMobileLinesOffers[${index}]`

  const lineFieldNames = fieldNames(lineType, isMobileOnly)

  const shouldShowDelete = isSubscription || isMultiSim ? index !== 0 : true

  const { errorDelete, onRemove } = useOnDeleteLineError({
    onRemoveExtraTariff,
    lineType,
  })

  const { shouldDisableTerminal, canOnlyShowDuo } = useLimitInfo(index, selectedTariff)

  // logic of extra tariffs
  const {
    formikLineValues,
    availableTariffs,
    onChangeTariff,
    isLoadingTariffs,
  } = useSingleExtraMobileLogic(
    lineFieldNames,
    index,
    tariffList,
    lineType,
    isLoading,
    canOnlyShowDuo,
    shouldDisableTerminal,
  )

  return (
    <>
      {shouldShowDelete && (
        <div className={classes['delete-extra-line']}>
          <IconButton icon="close" onClick={onRemove} />
        </div>
      )}
      <div>
        <Line
          showPicker
          showLineData
          showPackages
          selectedTariff={selectedTariff}
          lineFieldNames={lineFieldNames}
          tax={tax}
          selectedPackages={formikLineValues.selectedPackages}
          formikLineValues={formikLineValues}
          lineType={lineType}
          error={error}
          isLoading={isLoadingTariffs}
          onChangeTariff={onChangeTariff}
          tariffs={availableTariffs}
          errorDelete={errorDelete}
          shouldDisableTerminal={shouldDisableTerminal}
          isMultiSim={isMultiSim}
        />
      </div>
    </>
  )
}

ExtraLine.propTypes = {
  index: PropTypes.number.isRequired,
  selectedTariff: PropTypes.object.isRequired,
  error: PropTypes.any,
  isLoading: PropTypes.bool,
  tariffList: PropTypes.array.isRequired,
  onRemoveExtraTariff: PropTypes.func.isRequired,
}
