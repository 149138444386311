import { put, takeEvery, call } from 'redux-saga/effects'
import { logError, formatErrorMessage } from 'services/logging'

import { constants } from './change-agiletv.constants'
import { changeAgileTv } from '../services/change-agiletv.service'
import { changeAgileTvSuccess, changeAgileTvFailed } from './change-agiletv.actions'

export function* changeAgileTvSaga({ payload }) {
  try {
    yield call(changeAgileTv, payload)

    yield put(changeAgileTvSuccess())
  } catch (e) {
    yield call(logError, new Error(formatErrorMessage(e)))
    yield put(changeAgileTvFailed(e?.response?.data || e))
  }
}

export function* watchChangeAgileTv() {
  yield takeEvery(constants.CHANGE_AGILETV, changeAgileTvSaga)
}
