import { useSelector } from 'react-redux'
import { startsWith } from 'lodash'

import { selectProfileId, profiles } from 'modules/Permissions'
import { checkProfile } from 'modules/Auth/helpers'
import { selectSfid } from 'modules/Auth'

export const use1wSales = () => {
    const profileId = useSelector(selectProfileId)
    const sfid = useSelector(selectSfid)
    return checkProfile(profiles, profileId,'sales.id') && startsWith(sfid.toUpperCase(), '1W')
}
