import PropTypes from 'prop-types'

import { Card, CardContent, Box, Typography, Divider } from '@material-ui/core'

import { formatFee } from 'utils'

import { DevicePayment } from '../../../components/DevicePayment'

export const Billing = ({ paymentInfo, isYoigoStoreShipping }) => {
  return (
    <Card>
      <Box p="20px 24px 18px" display="flex" alignItems="center">
        <Typography variant="h5" style={{ fontWeight: 'bold' }}>
          Pago ahora
        </Typography>
      </Box>

      <Divider />

      <CardContent>
        <Box mb="32px">
          <Typography variant="body2" style={{ marginBottom: '16px' }}>
            <Typography variant="subtitle2" component="span" className="p-r-4">
              Cantidad a pagar:
            </Typography>

            {formatFee(paymentInfo.amount)}
          </Typography>

          <Typography variant="body2">
            <Typography variant="subtitle2" component="span" className="p-r-4">
              Concepto:
            </Typography>

            {paymentInfo.concept}
          </Typography>
        </Box>

        <DevicePayment amount={paymentInfo.amount} noCodPayment={isYoigoStoreShipping} />
      </CardContent>
    </Card>
  )
}

Billing.propTypes = {
  paymentInfo: PropTypes.shape({
    amount: PropTypes.number.isRequired,
    concept: PropTypes.isRequired,
  }),
  isYoigoStoreShipping: PropTypes.bool,
}
