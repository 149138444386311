import { call, put, takeEvery, select } from 'redux-saga/effects'
import { logError, formatErrorMessage } from 'services/logging'
import { axiosFactory } from 'modules/axios'
import { selectCustomerId } from 'modules/Auth'

import { actions } from './actions'
import { constants } from './constants'
import { find } from './api'

function* findSaga() {
  try {
    yield put(actions.find())
    const customerId = yield select(selectCustomerId)
    const customer = yield call(find, customerId)
    yield put(actions.findSuccess({ data: customer }))
  } catch (e) {
    yield call(logError, new Error(formatErrorMessage(e)))
    const error = axiosFactory.buildError(e)
    yield put(actions.findFailed(error))
  }
}

function* watchFind() {
  yield takeEvery(constants.FIND_INIT, findSaga)
}

export const saga = {
  findSaga,
  watchFind,
}
