import { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { get, find } from 'lodash'
import { Checkbox, FormControlLabel, Grid, Box } from '@material-ui/core'
import { formatNumberWithComma } from 'utils'

import { SVA_HOMEGO_CODE } from 'modules/offers-configuration/constants'
import { SVAInput } from './SVAInput'

export function SVACheck({
  sva,
  setSelectedSvas,
  selectedSvas,
  storeSva,
  shouldValidate,
  setIsValid,
  isPro,
  storeSvas,
  showPrice,
}) {
  const storeFields = get(storeSva, `fields`, {})
  const [selected, onSetSelected] = useState(!!storeSva)
  const [fields, setFields] = useState(storeFields)

  function saveFieldValue(name, value) {
    setFields({ ...fields, [name]: value })
  }

  const name = get(sva, 'commercialInfo.name')

  const prices = formatNumberWithComma(get(sva, 'price.taxFreeAmount', 0))

  const labelPriceWithTax = isPro ? `${prices} €/mes imp. no incluidos` : `${prices} €/mes`

  const label = useMemo(() => (showPrice ? `${name} (${labelPriceWithTax})` : name), [])

  const inputs = get(sva, 'inputCharacteristics', [])

  const areFieldsValid = useMemo(() => {
    const values = Object.values(fields).filter(elem => !!elem)
    return values.length === inputs.length
  }, [fields])

  const subSvas = get(sva, 'subSvas', [])

  function setValidSelectedSvas(isSelected) {
    const filteredSvas = selectedSvas.filter(elem => {
      const isUnselectedOrHomegoGroupUnselected =
        !isSelected && (sva.psId !== SVA_HOMEGO_CODE || !elem.psId.includes(SVA_HOMEGO_CODE))

      return (
        elem.psId !== sva.psId &&
        (isUnselectedOrHomegoGroupUnselected ||
          !sva.psId.includes(SVA_HOMEGO_CODE) ||
          !elem.psId.includes(SVA_HOMEGO_CODE) ||
          elem.psId === SVA_HOMEGO_CODE)
      )
    })

    if (areFieldsValid && isSelected) {
      setIsValid(name, true)
      setSelectedSvas([...filteredSvas, { ...sva, fields }])
    } else {
      setSelectedSvas(filteredSvas)
      setIsValid(name, !isSelected)
    }
  }

  useEffect(() => {
    if (
      !selectedSvas.find(selectedSva => selectedSva.psId === sva.psId) &&
      selected &&
      sva.psId.includes(SVA_HOMEGO_CODE)
    ) {
      onSetSelected(false)
    }
  }, [selectedSvas])

  useEffect(() => {
    if (fields && Object.keys(fields).length > 0) {
      setValidSelectedSvas(true)
    }
  }, [areFieldsValid, fields])

  useEffect(() => {
    setIsValid(name, true)
  }, [])

  return (
    <>
      <Grid item xs={12} className="p-t-16">
        <FormControlLabel
          className="label"
          control={
            <Checkbox
              data-hook={`sva-pro-${name}`}
              color="primary"
              className="checkbox"
              checked={selected}
              onChange={() => {
                setValidSelectedSvas(!selected)
                onSetSelected(!selected)
              }}
            />
          }
          label={label}
        />
      </Grid>
      {selected && (
        <Grid container>
          {subSvas &&
            subSvas.map(subSva => (
              <Box key={subSva.psId} display="block" width="100%" className="p-x-12">
                <SVACheck
                  storeSva={find(storeSvas, { poId: subSva.poId })}
                  sva={subSva}
                  setSelectedSvas={setSelectedSvas}
                  selectedSvas={selectedSvas}
                  shouldValidate={shouldValidate}
                  setIsValid={setIsValid}
                  isPro={isPro}
                  storeSvas={storeSvas}
                  showPrice={showPrice}
                />
              </Box>
            ))}

          {inputs.map(input => {
            const shouldShowCheckbox = input.name === 'CH_FIXED_SVA_FIXED_NUMBER'
            return (
              <Grid
                item
                xs={shouldShowCheckbox ? 6 : 5}
                key={input.name}
                className={`${shouldShowCheckbox ? '' : 'p-r-32'} p-y-16`}>
                <SVAInput
                  {...input}
                  shouldShowCheckbox={shouldShowCheckbox}
                  shouldValidate={shouldValidate}
                  saveFieldValue={saveFieldValue}
                  storeField={get(storeFields, `${input.name}`)}
                />
              </Grid>
            )
          })}
        </Grid>
      )}
    </>
  )
}

SVACheck.propTypes = {
  sva: PropTypes.object.isRequired,
  setSelectedSvas: PropTypes.func.isRequired,
  selectedSvas: PropTypes.array.isRequired,
  storeSva: PropTypes.object,
  shouldValidate: PropTypes.bool,
  setIsValid: PropTypes.func.isRequired,
  isPro: PropTypes.bool,
  storeSvas: PropTypes.array.isRequired,
  showPrice: PropTypes.bool,
}

SVACheck.defaultProps = {
  showPrice: true,
}
