import {
  FETCH_CONTENTFUL_RESOURCE,
  FETCH_CONTENTFUL_RESOURCE_SUCCESS,
  FETCH_CONTENTFUL_RESOURCE_ERROR,
} from './contentful.actions'

export function reducer(state = {}, action = {}) {
  const { payload } = action
  switch (action.type) {
    case FETCH_CONTENTFUL_RESOURCE:
      return {
        ...state,
        [payload.alias || payload.resource]: { loading: true}
      }
    case FETCH_CONTENTFUL_RESOURCE_SUCCESS:
      return {
        ...state,
        [payload.alias || payload.resource]: { data: payload.data, loading: false },
      }
    case FETCH_CONTENTFUL_RESOURCE_ERROR:
      return {
        ...state,
        [payload.alias || payload.resource]: { error: payload.error, loading: false },
      }
    default:
      return state
  }
}
