import { useLine } from './useLine'
import { useOnCheckDUOTariffs } from '../../hooks/Lines/index'

export function useExtraLine({ lineType, index }) {
  const { ...line } = useLine({ lineType })

  const extraLogic = useOnCheckDUOTariffs({
    index,
    tariffList: line.tariffs,
    selectedTariff: line?.formikValues?.selectedTariff,
    fieldNames: line.fieldNames,
    lineType,
    isNewTerminal: line?.formikValues?.isNewTerminal,
    isAdditionalType: line?.formikValues?.selectedTariff?.tariffType === 'additional_line',
    ps: line?.formikValues?.selectedTariff?.ps,
    isLoadingTariffs: line.isLoadingTariffs,
  })
  return { ...line, ...extraLogic }
}
