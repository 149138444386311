import { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import {
  selectQueryParams,
  selectRouterSearch,
  useCleanQueryParamsWhenUnmount,
} from 'modules/Router'
import { addQueryParam } from 'modules/url'
import { push } from 'connected-react-router'
import { Box, Tabs } from '@material-ui/core'

import Tab from '../tabs/Tab'
import { ButtonLink } from '../buttons/ButtonLink'
import TabSmall from '../tabs/TabSmall'

export function TabsContainer({
  className,
  dataHook,
  tabsConfig,
  queryParamName,
  contentProps,
  onChange,
  small,
  action,
  ...restProps
}) {
  const tabParam = useSelector(selectQueryParams)[queryParamName]
  const tabIndex = useMemo(() => +tabParam || 0, [tabParam])
  const search = useSelector(selectRouterSearch)
  const [index, setIndex] = useState(0)

  const dispatch = useDispatch()

  function updateValue(event, newIndex) {
    if (queryParamName) {
      dispatch(
        push({
          search: addQueryParam(search, queryParamName, newIndex),
        }),
      )
    } else {
      setIndex(newIndex)
    }
    return onChange && onChange(event, newIndex, tabsConfig[newIndex].value)
  }

  const TabComponent = small ? TabSmall : Tab
  const TabContent =
    tabsConfig &&
    tabsConfig[queryParamName ? tabIndex : index] &&
    tabsConfig[queryParamName ? tabIndex : index].component

  useCleanQueryParamsWhenUnmount(queryParamName ? [queryParamName] : [])

  return (
    <div
      className={['tabs-container', ...(className ? [className] : [])].join(' ')}
      data-hook={dataHook}
      {...restProps}>
      <Box className="tabs-div" justifyContent="space-between" alignItems="center" display="flex">
        <Tabs
          centered={false}
          classes={{ root: 'tabs', indicator: 'indicator', flexContainer: 'flexContainer' }}
          className="tabs"
          value={queryParamName ? tabIndex : index}
          indicatorColor="primary"
          textColor="primary"
          onChange={updateValue}>
          {tabsConfig &&
            tabsConfig.map((tabInfo, i) => {
              return (
                <TabComponent
                  key={tabInfo.value}
                  label={tabInfo.label}
                  disabled={tabInfo.disabled}
                  value={i}
                  data-hook={`Tab-${tabInfo.value}`}
                />
              )
            })}
        </Tabs>
        {action && (
          <ButtonLink onClick={action.onClick} disabled={action.disabled}>
            {action.label}
          </ButtonLink>
        )}
      </Box>
      {TabContent && <TabContent {...contentProps} className="tab-content p-24" />}
    </div>
  )
}

TabsContainer.defaultProps = {
  tabsConfig: [],
  queryParamName: '',
  contentProps: {},
  small: false,
}

TabsContainer.propTypes = {
  className: PropTypes.string,
  dataHook: PropTypes.string,
  tabsConfig: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.string,
      component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    }),
  ),
  queryParamName: PropTypes.string,
  contentProps: PropTypes.object,
  small: PropTypes.bool,
  onChange: PropTypes.func,
  action: PropTypes.shape({
    label: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
  }),
}
