import PropTypes from 'prop-types'
import { get } from 'lodash'
import { Icon } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Link, useLocation } from 'react-router-dom'
import { urlToRouterParams } from 'modules/Router/helpers'
import { ICONS } from 'utils/icons'

// TODO: unify colors
const buttonLinkColors = {
  primary: theme => ({
    main: theme.palette.primary.main,
    hover: '#2573a7',
  }),
  tertiary: theme => ({
    main: theme.palette.brand.action_tertiary,
    hover: '#963683',
  }),
  tertiaryInverted: () => ({
    main: 'rgba(255,255,255,0.8)',
    hover: '#ffffff',
  }),
  default: () => ({
    main: 'rgba(77,78,79,0.6)',
    hover: 'rgba(77,78,79,1)',
  }),
}

// get colours by buttonType and state
const getColor = (theme, buttonType = 'default', buttonState = 'main') =>
  get(buttonLinkColors, buttonType, buttonLinkColors.default)(theme)[buttonState]

const useStyles = makeStyles(theme => ({
  link: {
    width: ({ fullWidth }) => fullWidth && '100%',
    color: ({ type, primary }) => getColor(theme, primary ? 'primary' : type, 'main'),
    fontFamily: `'Roboto', sistem-ui, sans-serif`,
    fontSize: ({ iconType }) => (iconType ? '12px' : '14px'),
    fontWeight: ({ fontWeight }) => fontWeight || 'bold',
    textTransform: ({ iconType }) => iconType && 'uppercase',
    textDecoration: ({ underline }) => (underline ? 'underline' : 'none'),
    background: 'none',
    border: 'none',
    padding: 0,
    outline: 'none',
    cursor: ({ loading, disabled }) => {
      if (loading) return 'wait'
      if (disabled) return 'not-allowed'

      return 'pointer'
    },
    opacity: ({ disabled }) => (disabled ? '0.6' : '1'),

    '& .icon, & svg': {
      fontSize: '24px',
      verticalAlign: 'middle',
      color: ({ type, primary }) => getColor(theme, primary ? 'primary' : type, 'main'),

      '& + span': {
        marginLeft: '5px',
      },
    },

    '&:hover': {
      color: ({ type, primary }) => getColor(theme, primary ? 'primary' : type, 'hover'),

      '& .icon, & svg': {
        color: 'inherit',
      },
    },
  },
}))

export const ButtonLink = ({
  className,
  to,
  children,
  iconType,
  primary,
  type,
  onClick,
  fullWidth,
  disabled,
  fontWeight,
  underline,
  dataHook,
  target,
  loading,
  ...rest
}) => {
  const classes = useStyles({ iconType, primary, type, fullWidth, disabled, fontWeight, underline })

  const props = {
    onClick: e => {
      if (disabled) {
        e.preventDefault()
      }

      if (!disabled && onClick) {
        onClick(e)
      }
    },
    disabled,
    'data-hook': dataHook,
    className: className ? `${classes.link} ${className}` : classes.link,
    ...rest,
  }

  const location = useLocation()

  return to ? (
    <Link target={target} {...props} to={urlToRouterParams(location, to)}>
      {iconType ? (
        <Icon className="icon" data-hook={`icon-${iconType}`}>
          {ICONS[iconType]}
        </Icon>
      ) : null}
      {children}
    </Link>
  ) : (
    // eslint-disable-next-line react/button-has-type
    <button {...props}>
      {iconType ? (
        <Icon className="icon" data-hook={`icon-${iconType}`}>
          {ICONS[iconType]}
        </Icon>
      ) : null}
      {children}
    </button>
  )
}

ButtonLink.defaultProps = {
  type: 'default',
  primary: true,
  fullWidth: false,
  fontWeight: '500',
  disabled: false,
  underline: true,
}

ButtonLink.propTypes = {
  className: PropTypes.string,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  children: PropTypes.any,
  fullWidth: PropTypes.bool,
  primary: PropTypes.bool,
  onClick: PropTypes.func,
  type: PropTypes.string,
  iconType: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  fontWeight: PropTypes.string,
  underline: PropTypes.bool,
  dataHook: PropTypes.string,
  target: PropTypes.string,
}
