export const BRAND = 'yoigo'
export const CHANNEL = 'telesales'
export const CHANNEL_MOVE = 'traslados'
export const CHANNEL_SALES = 'telesales_ovid'
export const SEGMENT = 'RES'

export const STATUS_ACTIVE_HIDDEN = 'active,hidden'

export const EMPTY_GESCAL = '                                     '
export const NEBA_TERRITORIES = ['FIBNEB01', 'FIBNEB02', 'FIBNEB03', 'FIBNEB04', 'FIBNEB05']
export const NEBA_FAKE_TERRITORY = 'TESTNEBA086'
export const NEBA_VULA_PREFIX = ['FIBNEB', 'FIBNBL']
export const VULA_TERRITORY = 'FIBNBL'
export const VULA_COMMERCIAL_TERRITORY = 'FIBNBLC1'
