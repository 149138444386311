import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button, ModalActions } from 'components/ui'
import { get } from 'lodash'

import { filterModifications } from 'modules/offers-configuration/helpers'
import {
  useIsOnlyPenaltyCondonation,
  useCvmActions,
  getFeesSummaryForSubscriptions,
} from '../../hooks'

import LegalTextModal from '../LegalTextModal'

const FixedFooter = styled(
  ({
    className,
    onModalOpen,
    onModalClose,
    isOnlyPenaltyCondonation,
    summaryFeesText,
    modifications,
    optionsV3,
    migrationOrStc,
    clearAllSubscriptionsDispatch,
    updateSubscriptionsDispatch,
  }) => (
    <div className={className}>
      <ModalActions className="fixed-actions" mt="10px" ml="22px" data-hook="fixed-actions">
        <Button
          onClick={() =>
            onModalOpen(
              LegalTextModal,
              {
                hideLegal: isOnlyPenaltyCondonation,
                summaryFeesText,
                onConfirm: sendSms => {
                  const tariffs = filterModifications(modifications)
                  updateSubscriptionsDispatch(
                    tariffs,
                    optionsV3,
                    migrationOrStc,
                    sendSms,
                    summaryFeesText,
                  )
                  onModalClose()
                },
              },
              isOnlyPenaltyCondonation ? 'Confirmar cambios' : 'Textos legales',
            )
          }>
          Guardar cambios
        </Button>
        <Button secondary onClick={clearAllSubscriptionsDispatch}>
          Cancelar
        </Button>
      </ModalActions>
    </div>
  ),
)`
  position: fixed;
  bottom: 0px;
  left: 392px;
  width: 100%;
  background-color: ${props => get(props, 'theme.palette.global.gray_light')};
  height: 64px;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.35);
  & {
    margin-top: 0;
  }
`
function ModificationActionsItemBase({
  className,
  modifications,
  optionsV3,
  migrationOrStc,
  onModalOpen,
  onModalClose,
}) {
  const { updateSubscriptionsDispatch, clearAllSubscriptionsDispatch } = useCvmActions()

  const summaryFeesText = getFeesSummaryForSubscriptions(modifications)
  const isOnlyPenaltyCondonation = useIsOnlyPenaltyCondonation()

  return (
    <div className={`modification-actions-item ${className}`}>
      <FixedFooter
        onModalOpen={onModalOpen}
        onModalClose={onModalClose}
        updateSubscriptionsDispatch={updateSubscriptionsDispatch}
        summaryFeesText={summaryFeesText}
        modifications={modifications}
        optionsV3={optionsV3}
        migrationOrStc={migrationOrStc}
        isOnlyPenaltyCondonation={isOnlyPenaltyCondonation}
        clearAllSubscriptionsDispatch={clearAllSubscriptionsDispatch}
      />
    </div>
  )
}

ModificationActionsItemBase.propTypes = {
  className: PropTypes.string,
  modifications: PropTypes.object,
  optionsV3: PropTypes.object,
  migrationOrStc: PropTypes.object,
  onModalOpen: PropTypes.func,
  onModalClose: PropTypes.func,
}

export const ModificationActionsItem = styled(ModificationActionsItemBase)`
  display: flex;
  justify-content: flex-start;

  > * {
    margin-right: 20px;
  }
`
