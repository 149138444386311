import { useDispatch, useSelector } from 'react-redux'

import { actions } from 'services/customer-sva/actions.js'
import {
  selectIsDisableError,
  selectIsDisableSuccess,
  selectors,
} from 'services/customer-sva/selectors'

export const useCustomerSva = () => {
  const dispatch = useDispatch()

  const isDisableSuccess = useSelector(selectIsDisableSuccess)
  const isDisableError = useSelector(selectIsDisableError)
  const isDisableLoading = useSelector(selectors.disableLoading)
  const isDisabledPending = useSelector(selectors.disablePending)

  const fetchDisableCustomerSva = (documentId, documentType, msisdn) => {
    dispatch(actions.putDisableCustomerSvaInit(documentId, documentType, msisdn))
  }

  const resetDisableSva = () => {
    dispatch(actions.resetDisableCustomerSva())
  }

  const putDisableSvaPending = () => {
    dispatch(actions.putDisableCustomerSvaPending())
  }

  return {
    fetchDisableCustomerSva,
    isDisableCustomerSvaSuccess: isDisableSuccess,
    isDisableCustomerSvaError: isDisableError,
    isDisableCustomerSvaLoading: isDisableLoading,
    isDisableCustomerSvaPending: isDisabledPending,
    putDisableCustomerSvaPending: putDisableSvaPending,
    resetDisableCustomerSva: resetDisableSva,
  }
}
