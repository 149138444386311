import { get } from 'lodash/fp'

import {
  FETCH_CUSTOMER_CONTACT_MEDIA,
  FETCH_CUSTOMER_CONTACT_MEDIA_SUCCESS,
  FETCH_CUSTOMER_CONTACT_MEDIA_ERROR,
} from './constants'

const initialCustomerContactMedia = {
  data: null,
  error: null,
  loading: false,
}

export const reducer = (state = initialCustomerContactMedia, { type, payload }) => {
  switch (type) {
    case FETCH_CUSTOMER_CONTACT_MEDIA: {
      return {
        ...state,
        error: null,
        loading: true,
      }
    }
    case FETCH_CUSTOMER_CONTACT_MEDIA_SUCCESS: {
      return {
        ...state,
        data: get('data', payload),
        error: null,
        loading: false,
      }
    }
    case FETCH_CUSTOMER_CONTACT_MEDIA_ERROR: {
      return {
        ...state,
        error: get('error', payload) || payload,
        loading: false,
      }
    }

    default:
      return state
  }
}
