import { put, call, takeLatest, all, select } from 'redux-saga/effects'
import { axiosFactory } from 'modules/axios/services/index'
import { logError, formatErrorMessage } from 'services/logging'
import { fetchTerminalCommercialInfo } from 'modules/Terminals/services/TerminalDetailsService/index'
import { fetchTerminalStock } from 'modules/Terminals/services/TerminalsStockService/index'

import {
  fetchTerminalDetailsSuccessAction,
  fetchTerminalDetailsErrorAction,
  basketActionTypes,
} from '../actions/index'
import { selectIsPosSale } from '../selectors/index'

export function* fetchTerminalDetailsSaga({ payload }) {
  const isPos = yield select(selectIsPosSale)

  try {
    const [commercialInfo, stock] = yield all([
      !payload.isStockCheck
        ? call(fetchTerminalCommercialInfo, payload.data)
        : () => ({ id: payload.data.terminalId }),
      isPos ? () => {} : call(fetchTerminalStock, payload.data.terminalId),
    ])

    yield put(
      fetchTerminalDetailsSuccessAction({
        lineType: payload.lineType,
        data: { ...commercialInfo, id: payload.data.terminalId, stock },
      }),
    )
  } catch (e) {
    yield call(logError, { e: new Error(formatErrorMessage(e)), payload })
    const error = axiosFactory.buildError(e)
    if (e?.name && e?.name === 'stockError') {
      error.isStockError = true
    }

    yield put(fetchTerminalDetailsErrorAction({ lineType: payload.lineType, data: error }))
  }
}

export function* watchFetchTerminalDetails() {
  yield takeLatest(basketActionTypes.FETCH_TERMINAL_DETAILS, fetchTerminalDetailsSaga)
}
