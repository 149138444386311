import { memo } from 'react'
import PropTypes from 'prop-types'
import { AuthenticatedRoute } from 'modules/Auth'
import { DashboardContainer } from './containers/DashboardContainer'

export const Routes = memo(
  ({ match }) => (
    <>
      <AuthenticatedRoute
        path={`${match.url}/dashboard/documentType/:documentType/documentId/:documentId`}
        component={DashboardContainer}
      />
      <AuthenticatedRoute exact path={`${match.url}/dashboard`} component={DashboardContainer} />
    </>
  ),
  // Only re-render with path match changes
  (prevProps, nextProps) => prevProps.match.url === nextProps.match.url,
)

Routes.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }),
}
