const FETCH_ORDER_CREATION_STATUS = 'OVID/FETCH_ORDER_CREATION_STATUS'
const FETCH_ORDER_CREATION_STATUS_SUCCESS = 'OVID/FETCH_ORDER_CREATION_STATUS_SUCCESS'
const FETCH_ORDER_CREATION_STATUS_ERROR = 'OVID/FETCH_ORDER_CREATION_STATUS_ERROR'

const CREATE_NEW_ORDER = 'OVID/CREATE_NEW_ORDER'
const CREATE_NEW_ORDER_SUCCESS = 'OVID/CREATE_NEW_ORDER_SUCCESS'
const CREATE_NEW_ORDER_ERROR = 'OVID/CREATE_NEW_ORDER_ERROR'

const START_POLL_ORDER = 'OVID/START_POLLING_STATUS'
const FINISH_POLL_ORDER_ERROR = 'OVID/FINISH_POLL_ORDER_ERROR'
const FINISH_POLL_ORDER_SUCCESS = 'OVID/FINISH_POLL_ORDER_SUCCESS'

export const orderActionTypes = {
  FETCH_ORDER_CREATION_STATUS,
  FETCH_ORDER_CREATION_STATUS_SUCCESS,
  FETCH_ORDER_CREATION_STATUS_ERROR,
  CREATE_NEW_ORDER,
  CREATE_NEW_ORDER_SUCCESS,
  CREATE_NEW_ORDER_ERROR,
  START_POLL_ORDER,
  FINISH_POLL_ORDER_SUCCESS,
  FINISH_POLL_ORDER_ERROR
}

export const fetchOrderCreationStatus = taskId => ({
  type: orderActionTypes.FETCH_ORDER_CREATION_STATUS,
  payload: {
    taskId,
  },
})

export const fetchOrderCreationStatusSuccess = status => ({
  type: orderActionTypes.FETCH_ORDER_CREATION_STATUS_SUCCESS,
  payload: {
    status,
  },
})

export const fetchOrderCreationStatusError = error => ({
  type: orderActionTypes.FETCH_ORDER_CREATION_STATUS_ERROR,
  payload: {
    error,
  },
})

export const createNewOrder = data => ({
  type: orderActionTypes.CREATE_NEW_ORDER,
  payload: {
    data,
  },
})

export const createNewOrderSuccess = taskId => ({
  type: orderActionTypes.CREATE_NEW_ORDER_SUCCESS,
  payload: {
    taskId,
  },
})

export const createNewOrderError = error => ({
  type: orderActionTypes.CREATE_NEW_ORDER_ERROR,
  payload: {
    error,
  },
})

export const startPollingOrderStatus = () => (
  {
    type: orderActionTypes.START_POLL_ORDER
  }
)

export const finishedPollingOrderStatusSuccess = payload => ({
  type: orderActionTypes.FINISH_POLL_ORDER_SUCCESS,
  payload
})
export const finishedPollingOrderStatusError = payload => ({
  type: orderActionTypes.FINISH_POLL_ORDER_ERROR,
  payload
})
