import { get } from 'lodash'
import { axiosJWT } from 'modules/axios'
import { CONFIG } from './environments'
import { getProvinceCode } from './helpers'

export function fetchTax(zipCode) {
  return axiosJWT({
    method: 'GET',
    url: `${CONFIG.api.financials}/${getProvinceCode(zipCode)}`,
  }).then(resp => get(resp, 'data.tax'))
}
