import { useMemo } from 'react'
import PropTypes from 'prop-types'

import { get } from 'lodash'

import { makeStyles } from '@material-ui/styles'
import { Card, CardContent, Box, Typography } from '@material-ui/core'

import { TextStyles } from 'utils/text'

const useStyles = makeStyles(theme => ({
  'error-section': {
    textAlign: 'center',
    borderBottom: ({ borderColor }) =>
      borderColor && `5px solid ${get(theme.palette, borderColor)}`,

    '& h4': {
      padding: '10px 0',
    },

    '& p': {
      fontWeight: 'normal',
    },
  },
}))

const ADVICE_BORDER_COLORS = {
  error: 'error.main',
  info: 'bluePalette.dark',
  success: 'greenPalette.green_500',
}

const setContent = (image, title, body, children) => (
  <>
    <Box p="30px 70px">
      {image && <img src={image.url} alt={image.alt} {...image} />}
      <Typography {...TextStyles.title1Dark()}>{title}</Typography>
      <Typography {...TextStyles.dataDark()}>{body}</Typography>

      {children}
    </Box>
  </>
)
export const AdviceSection = ({ type, image, title, body, children, notShowCard, className }) => {
  const borderColor = useMemo(() => ADVICE_BORDER_COLORS[type], [type])
  const classes = useStyles({ borderColor })

  if (!notShowCard) {
    return (
      <Card className={classes['error-section']} elevation={2}>
        <CardContent>{setContent(image, title, body, children)}</CardContent>
      </Card>
    )
  }

  return (
    <div className={[classes['error-section'], className].join(' ')}>
      {setContent(image, title, body, children)}
    </div>
  )
}

AdviceSection.propTypes = {
  type: PropTypes.oneOf(['error', 'success', 'info']),
  image: PropTypes.shape({
    url: PropTypes.string,
    alt: PropTypes.string,
  }),
  title: PropTypes.string,
  body: PropTypes.string,
  children: PropTypes.node,
  notShowCard: PropTypes.bool,
  className: PropTypes.string,
}
