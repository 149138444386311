/* eslint-disable import/no-cycle */

import { Box, Typography } from '@material-ui/core'

import { TextStyles } from 'utils/text'

export const CardContentError = () => (
  <Box pl="24px" pt="10px" pb="20px">
    <Typography {...TextStyles.subtitle2Dark()}>Se ha producido un error</Typography>
  </Box>
)
