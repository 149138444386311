import { constants } from './constants'

const getDiscountsInit = orderId => ({
  type: constants.GET_DISCOUNTS_INIT,
  payload: {
    orderId,
  },
})

const getDiscounts = () => ({
  type: constants.GET_DISCOUNTS,
})

const getDiscountsSuccess = discounts => ({
  type: constants.GET_DISCOUNTS_SUCCESS,
  payload: {
    discounts,
  },
})

const getDiscountsFailed = error => ({
  type: constants.GET_DISCOUNTS_FAILED,
  payload: {
    error,
  },
})

const selectDiscount = discount => ({
  type: constants.SELECT_DISCOUNT,
  payload: {
    discount,
  },
})

const resetDiscounts = () => ({
  type: constants.RESET_DISCOUNTS,
})

const putDiscountsInit = orderId => ({
  type: constants.PUT_DISCOUNTS_INIT,
  payload: {
    orderId,
  },
})

const putDiscounts = () => ({
  type: constants.PUT_DISCOUNTS,
})

const putDiscountsSuccess = response => ({
  type: constants.PUT_DISCOUNTS_SUCCESS,
  payload: {
    response,
  },
})

const putDiscountsFailed = error => ({
  type: constants.PUT_DISCOUNTS_FAILED,
  payload: {
    error,
  },
})

export const actions = {
  getDiscountsInit,
  getDiscounts,
  getDiscountsSuccess,
  getDiscountsFailed,
  selectDiscount,
  resetDiscounts,
  putDiscountsInit,
  putDiscounts,
  putDiscountsSuccess,
  putDiscountsFailed,
}
