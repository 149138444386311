import { useState } from 'react'
import PropTypes from 'prop-types'
import { Form } from 'formik'
import { Radio, Box } from '@material-ui/core'
import { RadioGroup, FormLabel, FormikInput } from 'components/ui'

export function ChangeOwnerSearch({ className, onChange }) {
  const [isNewClient, setIsNewClient] = useState(false)

  function toggleClientType(_, newValue) {
    onChange(newValue)
    setIsNewClient(newValue)
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      flexWrap="wrap"
      className={className}>
      <RadioGroup onChange={toggleClientType} value={isNewClient} name="newClient">
        <FormLabel value="false" control={<Radio data-hook="checkbox" />} label="Ya cliente" />
        <FormLabel value="true" control={<Radio data-hook="checkbox" />} label="Nuevo cliente" />
      </RadioGroup>
      <Form>
        <FormikInput
          name="documentValue"
          fullWidth
          label="Introduce NIF o NIE"
          disabled={{ misses: 'documentType' }}
        />

        {isNewClient === 'false' && (
          <FormikInput
            name="phone"
            label="Nº de teléfono (YA CLIENTES)"
            disabled={{ misses: 'documentType' }}
          />
        )}
      </Form>
    </Box>
  )
}

ChangeOwnerSearch.propTypes = {
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
}
