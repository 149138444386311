import PropTypes from 'prop-types'

import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  'card-footer': {
    background: theme.palette.global.gray_medium_07,
    width: '100%',
    borderBottomLeftRadius: '4px',
    borderBottomRightRadius: '4px',
  },
}))

const CardFooter = ({ children }) => {
  const classes = useStyles()

  return (
    <Box p="24px" className={classes['card-footer']}>
      {children}
    </Box>
  )
}

CardFooter.propTypes = {
  children: PropTypes.node,
}

export default CardFooter
