import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/styles'
import { Card, CardContent, Box, Typography, Divider } from '@material-ui/core'

import { FormikInput } from 'components/ui'

import { DEVICE_RENEWAL_FIELDNAMES } from '../../../constants'
import { DeviceTypeSelector } from '../../../components/DeviceTypeSelector'

const useStyles = makeStyles({
  'sale-type-card': {
    '& > .MuiCardContent-root': {
      padding: '10px 24px 15px 24px',
    },

    '& h5': {
      marginTop: '30px',
    },
  },
})

export const DeviceType = ({ saleOptions, deviceTypeOptions }) => {
  const classes = useStyles()

  return (
    <>
      <Card className={classes['sale-type-card']} style={{ width: '100%' }}>
        <CardContent>
          <Box display="flex">
            <Typography variant="h5" style={{ fontWeight: 'bold' }}>
              Selecciona el catálogo de venta
            </Typography>

            <Box ml="35px" width="185px">
              <FormikInput
                name={DEVICE_RENEWAL_FIELDNAMES.SALE_CHANNEL}
                dataHook={DEVICE_RENEWAL_FIELDNAMES.SALE_CHANNEL}
                select
                fullWidth
                label="Catálogo de venta"
                options={saleOptions}
              />
            </Box>
          </Box>
        </CardContent>
      </Card>

      <Card style={{ width: '100%' }}>
        <Box p="20px 24px 18px">
          <Typography variant="h5" style={{ fontWeight: 'bold' }}>
            Tipo de dispositivo
          </Typography>
        </Box>

        <Divider />

        <CardContent>
          <DeviceTypeSelector
            fieldName={DEVICE_RENEWAL_FIELDNAMES.DEVICE_TYPE}
            dataHook={DEVICE_RENEWAL_FIELDNAMES.DEVICE_TYPE}
            options={deviceTypeOptions}
          />
        </CardContent>
      </Card>
    </>
  )
}

DeviceType.propTypes = {
  saleOptions: PropTypes.array.isRequired,
  deviceTypeOptions: PropTypes.array.isRequired,
}
