import PropTypes from 'prop-types'

import { Box, Typography, Icon } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import { TextStyles } from 'utils/text'
import { ICONS } from 'utils/icons'

import { ButtonLink } from '../buttons/ButtonLink'

const useStyles = makeStyles({
  'icon-line-item': {
    padding: '3px 16px 3px 16px',

    '& .first-block': {
      backgroundColor: ({ bgColor }) => bgColor,

      '& p': {
        lineHeight: ({ bgColor }) => (bgColor ? 0 : '1.5'),
      },

      '& .icon-text': {
        padding: '0.2em 0',

        '& .icon': {
          fontSize: 'inherit',
        },
      },
    },
  },
})

const IconLineItem = ({
  className,
  bgColor,
  iconType,
  alignItems,
  primaryTypo = {
    variant: 'body1',
  },
  primaryTxt,
  actionLabel,
  action,
  ...rest
}) => {
  const classes = useStyles({ bgColor })

  return (
    <Box
      display="flex"
      alignItems="center"
      className={[classes['icon-line-item'], ...(className ? [className] : [])].join(' ')}
      {...rest}>
      <Box display="flex" width="100%" alignItems="center" justifyContent="space-between">
        {(iconType || primaryTxt) && (
          <Box display="flex" className="first-block" alignItems={alignItems}>
            {iconType && (
              <Box display="flex" alignItems="center" mr="12px" className="icon-text">
                <Typography className="light" {...primaryTypo}>
                  <Icon className="icon">{ICONS[iconType]}</Icon>
                </Typography>
              </Box>
            )}
            {primaryTxt && (
              <Box display="flex" alignItems="center" mr={bgColor ? '12px' : '24px'}>
                <Typography className="light" {...primaryTypo}>
                  {primaryTxt}
                </Typography>
              </Box>
            )}
          </Box>
        )}

        {actionLabel && (
          <ButtonLink onClick={action}>
            <Box display="flex" alignItems="center" mr="12px">
              <Typography
                {...TextStyles.labelLight({
                  style: {
                    textDecoration: 'underline',
                  },
                })}>
                {actionLabel}
              </Typography>
            </Box>
          </ButtonLink>
        )}
      </Box>
    </Box>
  )
}

IconLineItem.defaultProps = {
  alignItems: 'center',
}

IconLineItem.propTypes = {
  className: PropTypes.string,
  bgColor: PropTypes.string,
  iconType: PropTypes.string,
  alignItems: PropTypes.oneOf(['flex-start', 'center', 'flex-bottom']),
  primaryTypo: PropTypes.object,
  primaryTxt: PropTypes.string,
  actionLabel: PropTypes.string,
  action: PropTypes.func,
}

export default IconLineItem
