import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { find, map, isEmpty } from 'lodash'

import { Box, Button, Grid, Paper, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { TextStyles } from 'utils/index'

import { getZipCode } from 'services/change-technology'
import { applyTax, getTax } from 'services/taxes'

const useStyles = makeStyles({
  'tariff-paper': {
    margin: '20px',
    padding: '20px',
    textAlign: 'center',

    '& h6': {
      margin: '10px 0',
    },

    '& .tariff-promotion': {
      border: '1px dashed #ccc',
      padding: '10px',
      margin: '10px',
      textAlign: 'center',
    },

    '& .close-details': {
      justifyContent: 'flex-end',
      marginTop: '10px',
    },
  },
})

const ClientOffersInfo = ({ enrichment, data, onCloseHandler, changeTechnologyJson, ...rest }) => {
  const classes = useStyles()

  const [display, setDisplay] = useState(false)
  const [tariffIndex, setTariffIndex] = useState(null)

  useEffect(() => {
    setDisplay(rest.display)
    setTariffIndex(rest.index)
  }, [rest.display, rest.index])

  let zipCode = ''

  if (!isEmpty(changeTechnologyJson)) {
    zipCode = getZipCode(changeTechnologyJson)
  }

  const tax = useSelector(state => getTax(state, zipCode))

  return (
    <Box display="flex">
      {map(data, (item, index) => {
        const itemInfo = find(enrichment, { id: item.id }) || {}

        return index === tariffIndex && display ? (
          <Paper key={item.id} className={classes['tariff-paper']}>
            <Typography
              {...TextStyles.title2Dark({
                className: 'thin',
              })}>
              {item.commercial_name}
            </Typography>
            <Typography {...TextStyles.dataDark()}>
              {item.extendedInfo ? `"${item.extendedInfo.tariffQuote}"` : null}
            </Typography>
            <Typography {...TextStyles.title2MediumBoldDark()}>Info:</Typography>

            <Typography {...TextStyles.dataDark()}>{itemInfo.mobileInfo}</Typography>
            <Typography {...TextStyles.dataDark()}>{itemInfo.fixedInfo}</Typography>

            <Grid container>
              <Grid item xs={6}>
                <Typography {...TextStyles.title2MediumBoldDark()}>Precio:</Typography>
                <ul>
                  <Typography {...TextStyles.dataDark()}>
                    Pago Mensual: {applyTax(tax, item.monthly_fee)}€ imp. inc.
                  </Typography>
                </ul>
              </Grid>
              <Grid item xs={6}>
                <Typography {...TextStyles.title2MediumBoldDark()}>Velocidad:</Typography>
                <ul>
                  <Typography {...TextStyles.dataDark()}>
                    Download: {item.fixed_line_download_speed || ''}
                  </Typography>
                  <Typography {...TextStyles.dataDark()}>
                    Upload: {item.fixed_line_upload_speed || ''}
                  </Typography>
                </ul>
              </Grid>
            </Grid>
            <Typography {...TextStyles.title2MediumBoldDark()}>Promociones:</Typography>
            {itemInfo.promotion ? (
              <Grid key={index} className="tariff-promotion">
                <Typography {...TextStyles.title2MediumBoldDark()}>
                  {itemInfo.promotion.title}
                </Typography>
                <Typography {...TextStyles.dataDark()}>{itemInfo.promotion.desc}</Typography>
              </Grid>
            ) : (
              <Typography {...TextStyles.dataDark()}>
                No existe ninguna promoción aplicable
              </Typography>
            )}
            <Button
              variant="contained"
              color="primary"
              onClick={onCloseHandler}
              data-hook="Ocultar detalles"
              className="close-details">
              Ocultar detalles
            </Button>
          </Paper>
        ) : null
      })}
    </Box>
  )
}

ClientOffersInfo.propTypes = {
  enrichment: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  onCloseHandler: PropTypes.func,
  changeTechnologyJson: PropTypes.object,
}

export default ClientOffersInfo
