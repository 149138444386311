export const OPEN_CASE = 'OVID/TICKETING/OPEN_CASE'
export const CLOSE_CASE = 'OVID/TICKETING/CLOSE_CASE'
export const FIND_CATEGORIES_ATTEMPTED = 'OVID/TICKETING/CATEGORIES/FIND_ATTEMPTED'
export const FIND_CATEGORIES_SUCCEEDED = 'OVID/TICKETING/CATEGORIES/FOUND'
export const FIND_CATEGORIES_FAILED = 'OVID/TICKETING/CATEGORIES/FIND_FAILED'
export const FIND_SUBCATEGORIES_ATTEMPTED = 'OVID/TICKETING/SUBCATEGORIES/FIND_ATTEMPTED'
export const FIND_SUBCATEGORIES_SUCCEEDED = 'OVID/TICKETING/SUBCATEGORIES/FOUND'
export const FIND_SUBCATEGORIES_FAILED = 'OVID/TICKETING/SUBCATEGORIES/FIND_FAILED'
export const FIND_TICKETS_ATTEMPTED = 'OVID/TICKETING/TICKETS/FIND_ATTEMPTED'
export const FIND_TICKETS_SUCCEEDED = 'OVID/TICKETING/TICKETS/FOUND'
export const FIND_TICKETS_FAILED = 'OVID/TICKETING/TICKETS/FIND_FAILED'
export const FIND_TICKET_TYPES_ATTEMPTED = 'OVID/TICKETING/TYPES/FIND_ATTEMPTED'
export const FIND_TICKET_TYPES_SUCCEEDED = 'OVID/TICKETING/TYPES/FOUND'
export const FIND_TICKET_TYPES_FAILED = 'OVID/TICKETING/TYPES/FIND_FAILED'

export const CREATE_TICKET_SUCCEEDED = 'OVID/TICKETING/CREATE_TICKET/SUCCESS'
export const CREATE_TICKET_FAILED = 'OVID/TICKETING/CREATE_TICKET/FAILED'
export const CREATE_TICKET_ATTEMPTED = 'OVID/TICKETING/CREATE_TICKET/ATTEMPTED'

export const UPDATE_TICKET_CREATION_FORM_DATA = 'OVID/TICKETING/TICKETS/CREATION/FORM/UPDATE'

export const KEY_PHONE = 'mobile'

export default {
  OPEN_CASE,
  CLOSE_CASE,
  FIND_CATEGORIES_ATTEMPTED,
  FIND_CATEGORIES_SUCCEEDED,
  FIND_CATEGORIES_FAILED,
  FIND_SUBCATEGORIES_ATTEMPTED,
  FIND_SUBCATEGORIES_SUCCEEDED,
  FIND_SUBCATEGORIES_FAILED,
  FIND_TICKETS_ATTEMPTED,
  FIND_TICKETS_SUCCEEDED,
  FIND_TICKETS_FAILED,
  FIND_TICKET_TYPES_ATTEMPTED,
  FIND_TICKET_TYPES_SUCCEEDED,
  FIND_TICKET_TYPES_FAILED,
  CREATE_TICKET_SUCCEEDED,
  CREATE_TICKET_FAILED,
  CREATE_TICKET_ATTEMPTED,
  UPDATE_TICKET_CREATION_FORM_DATA,
}
