import { call, put, takeEvery, select } from 'redux-saga/effects'
import { logError, formatErrorMessage } from 'services/logging'
import { selectProfilesDiscount } from 'modules/Permissions'
import { axiosFactory } from 'modules/axios'
import { actions } from './actions'
import { constants } from './constants'
import { fetchDiscounts } from './api'

export function* getDiscountsSaga({ payload: { category } }) {
  try {
    const profileId = yield select(selectProfilesDiscount)
    const data = yield call(fetchDiscounts, profileId.join(','), category)
    yield put(actions.getDiscountsSuccess(data, category))
  } catch (e) {
    yield call(logError, { e: new Error(formatErrorMessage(e)), category })
    const error = axiosFactory.buildError(e)
    yield put(actions.getDiscountsFailed(error, category))
  }
}

export function* watchGetDiscountsAgentCategories() {
  yield takeEvery(constants.GET_DISCOUNTS, getDiscountsSaga)
}
