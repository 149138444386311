import { useSelector } from 'react-redux'
import { selectProfileId } from 'modules/Permissions/store/permissions-selectors'

export const useOrderChannelAndStatus = () => {
  const SPECIFICS_AGENT_PROFILES = [
    'FIDELIZACION',
    'FIDELIZACION OUTBOUND',
    'FIDELIZACION INBOUND',
    'POS',
    'ATC',
    'XSELLING',
    'RETENCION',
    'PRO',
    'SOHO',
  ]

  const DEFAULT_ORDER_CHANNEL = 'customer_care'
  const DEFAULT_STATUS = 'active'
  const RETENTION_ORDER_CHANNEL = 'customer_care_retention'
  const ACTIVE_AND_RETIRED_STATUS = 'active,retired'

  const DEFAULT_ORDER_CHANNEL_AND_STATUS = {
    orderChannel: DEFAULT_ORDER_CHANNEL,
    status: DEFAULT_STATUS,
  }

  const RETENTION_PRO_SOHO_CHANNEL_AND_STATUS = {
    orderChannel: RETENTION_ORDER_CHANNEL,
    status: ACTIVE_AND_RETIRED_STATUS,
  }

  const ATC_XSELLING_CHANNEL_AND_STATUS = {
    orderChannel: DEFAULT_ORDER_CHANNEL,
    status: ACTIVE_AND_RETIRED_STATUS,
  }

  const LOYALTY_CHANNEL_AND_STATUS = {
    orderChannel: 'customer_care_loyalty', 
    status: ACTIVE_AND_RETIRED_STATUS
  }
  const ORDER_CHANNEL_AND_STATUS = {
    FIDELIZACION: { ...LOYALTY_CHANNEL_AND_STATUS },
    'FIDELIZACION OUTBOUND': { ...LOYALTY_CHANNEL_AND_STATUS },
    'FIDELIZACION INBOUND': { ...LOYALTY_CHANNEL_AND_STATUS },
    POS: { orderChannel: 'pos_customer_care', status: DEFAULT_STATUS },
    ATC: { ...ATC_XSELLING_CHANNEL_AND_STATUS },
    XSELLING: { ...ATC_XSELLING_CHANNEL_AND_STATUS },
    RETENCION: { ...RETENTION_PRO_SOHO_CHANNEL_AND_STATUS },
    PRO: { ...RETENTION_PRO_SOHO_CHANNEL_AND_STATUS },
    SOHO: { ...RETENTION_PRO_SOHO_CHANNEL_AND_STATUS },
  }

  const agentProfile = useSelector(selectProfileId)

  if (SPECIFICS_AGENT_PROFILES.includes(agentProfile)) {
    return ORDER_CHANNEL_AND_STATUS[agentProfile]
  }

  return DEFAULT_ORDER_CHANNEL_AND_STATUS
}
