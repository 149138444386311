import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { Typography, Divider } from '@material-ui/core'
import { TextStyles } from 'utils/text'

import { startOfDay, differenceInDays } from 'date-fns'
import { formatFee, formatDateDDMMYYYY } from 'utils'

import { getDurationFromRange } from 'utils/durations'

import { getTotalCancellationCostsLeft, getTotalCancellationEndDate } from 'services/subscriptions'
import { selectEnabledProrratedPenalty } from 'services/feature-flag/selectors'

import { DurationMonthsDays } from './DurationMonthsDays'

export const PermanencyPending = styled(({ className, subscription }) => {
  const today = startOfDay(Date.now())
  const endDate = getTotalCancellationEndDate(subscription)

  const daysToEnd = differenceInDays(endDate, today)

  const enabledProrratedPenalty = useSelector(selectEnabledProrratedPenalty)

  return (
    <div className={`${className} flex p-t-24 p-b-12`}>
      <div className="pending-container">
        <Typography {...TextStyles.subtitle2Dark()}>
          Importe total* a pagar si da de baja hoy:
        </Typography>
        <Typography
          {...TextStyles.title1BoldDark({
            className: 'p-y-4',
          })}>
          {formatFee(getTotalCancellationCostsLeft(subscription, enabledProrratedPenalty))}
        </Typography>
        <Typography
          {...TextStyles.subM({
            className: 'p-y-4',
          })}>
          *Este importe es la suma de penalizaciones y pagos que tenga por amortizar
        </Typography>
      </div>

      <Divider orientation="vertical" />

      <div className="pending-container">
        <Typography {...TextStyles.subtitle2Dark()}>Para irse sin coste quedan:</Typography>
        <DurationMonthsDays className="p-y-4" duration={getDurationFromRange(endDate, today)} />
        <Typography
          {...TextStyles.subM({
            className: 'p-y-4',
          })}>
          {daysToEnd > 0 ? formatDateDDMMYYYY(endDate) : ''}
        </Typography>
      </div>
    </div>
  )
})`
  background-color: #f4f8ff;
  border-radius: 14px;
  .pending-container {
    width: 50%;
    padding: 6px 24px;
    text-align: center;
  }
`

PermanencyPending.propTypes = {
  className: PropTypes.string,
}
