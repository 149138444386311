export const ONLY_MOBILE = 'only_mobile'
export const ONLY_FIXED = '2p'
export const CONVERGENT = 'convergent'
export const FIXED_ONLY = 'fixed_only'
export const FIXED_MOBILE = 'mobile_fixed_line'
export const MULTISIM = 'multisim_line'
export const ACQUISITION = 'acquisition'
export const CROSS_SELL_ACQUISITION = 'cross-sell'
export const PORTABILITY_TYPE_PREPAID = 'prepaid'
export const PORTABILITY_TYPE_CONTRACT = 'postpaid'
export const ENERGY = 'energy'
export const LEADS = 'leads'
