import { APP_CONFIG } from 'services/app-config'

export const CONFIG = {
  api: {
    securizer: `${APP_CONFIG.care_private_cluster}/ms-securizer/securize`,
    loginAgent: `${APP_CONFIG.sales_cluster}/v1/login/agent`,
    insertionData: `${APP_CONFIG.care_cluster}/ms-typifications/iframe`,
    getRegistersTipis: `${APP_CONFIG.care_cluster}/ms-typifications/iframe/getByCustomerId`,
    getCampaignByEmail: `${APP_CONFIG.care_cluster}/ms-typifications/iframe/campaign`
  },
}
