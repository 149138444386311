import { createSelector } from 'reselect'
import { get, compact } from 'lodash'

import { selectD2RFromMsFlag, selectD2RNewDiscounts } from 'services/feature-flag/selectors'
import { selectIsCustomerActive } from 'modules/CustomerInfo'
import { selectIsConvergent, selectIs2p } from 'modules/Router/store/router.selectors'

import { selectAccount, selectIsPro, selectLandlineOffer } from './Basket.selectors'

import { getSecondHomeLines, getDiscountByTariff } from './discounts.selectors.helpers'

export const selectHasSecondResidenceDiscount = createSelector(
  selectLandlineOffer,
  ({ hasSecondResidenceDiscount }) => hasSecondResidenceDiscount,
)

export const selectSecondHomeDiscount = createSelector(
  selectIsCustomerActive,
  selectIsConvergent,
  selectIs2p,
  selectAccount,
  selectLandlineOffer,
  selectD2RNewDiscounts,
  selectD2RFromMsFlag,
  selectIsPro,
  (
    isCustomerActive,
    isConvergent,
    is2p,
    accountInfo,
    landlineOffer,
    isNewD2RFlag,
    isD2RMsFlag,
    isPro,
  ) => {
    if (!isCustomerActive || (isD2RMsFlag && is2p && !isPro)) {
      return []
    }

    const discountLines = getSecondHomeLines(
      isConvergent,
      is2p,
      get(accountInfo, 'lines', []),
      landlineOffer,
      isNewD2RFlag,
    )

    return compact(
      discountLines.map(discountLine => {
        const discount = getDiscountByTariff(discountLine.tariffId, isNewD2RFlag)

        return discount ? { ...discount, lineNumber: discountLine.id } : null
      }),
    )
  },
)
