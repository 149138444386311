import PropTypes from 'prop-types'
import { useState, useEffect } from 'react'
import styled from 'styled-components'
import { get, flatMap } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'

import { Typography } from '@material-ui/core'
import { TextStyles } from 'utils/text'

import {
  isLoading as selectAreSubscriptionsLoading,
  isConvergentSubscription,
  getLineNumbers,
  getSubscriptions,
} from 'services/subscriptions'

import { getIdentificationId } from 'services/customer-360/selectors'

import { isMobileTariff } from 'modules/tariffs/store-apigee/tariffs-apigee.helpers'
import { Modal, NotificationModal, OffersConfigurationActions, SpinnerCenter } from 'components/ui'
import {
  getError as getTypificationError,
  clearTypificationError,
  isDone,
} from 'services/typifications'

import {
  getError as getCVMError,
  isLoading as selectIsLoading,
  isTypificationOpen as selectIsTypificationOpen,
} from '../selectors'
import { OldSubscriptionCard } from '../components/OldSubscriptionCard'
import SubscriptionCardSplit from '../components/SubscriptionCardSplit'
import { SubscriptionCardLoading } from '../components/SubscriptionCardLoading'
import { TypificationsModal } from '../components/TypificationsModal'

import { initCustomerOffersConfiguration, clearError, closeTypification } from '../actions'
import { DynamicModal, useDynamicModals } from '../hooks/useDynamicModals'
import { useFetchOrders } from '../hooks/useFetchOrders'
import { useSubscriptionStatus } from '../hooks/useSubscriptionStatus'
import { usePortOutInfo } from '../hooks/usePortOutInfo'
import { useChangedTariff } from '../hooks/useChangedTariff'

// TODO: looks like NewOfferConfigurationContainer
const shouldSplitCard = props =>
  isConvergentSubscription(props.subscription) &&
  props.changedTariff &&
  isMobileTariff(props.changedTariff)

const SubscriptionCardEnhanced = ({ subscription, ...rest }) => {
  useFetchOrders(subscription)

  const { status, fixedStatus, mobileStatus } = useSubscriptionStatus(subscription)
  const { isPortOutLoading, mobilePortOut, fixedPortOut } = usePortOutInfo(subscription)

  const [isRotated, setIsRotated] = useState(false)
  const onRotate = () => setIsRotated(!isRotated)

  const { changedTariff, allowUndoChanges } = useChangedTariff(subscription)

  if (isPortOutLoading) {
    return <SubscriptionCardLoading subscription={subscription} {...rest} />
  }

  if (shouldSplitCard) {
    return <SubscriptionCardSplit subscription={subscription} {...rest} />
  }

  const props = {
    ...rest,
    status,
    fixedStatus,
    mobileStatus,
    mobilePortOut,
    fixedPortOut,
    isRotated,
    onRotate,
    subscription,
    changedTariff,
    allowUndoChanges,
  }

  return <OldSubscriptionCard {...props} />
}
SubscriptionCardEnhanced.propTypes = {
  subscription: PropTypes.object.isRequired,
}

const Container = styled.section`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 0 auto;
  position: relative;
  > section {
    margin: 12px;
  }
`
const OffersConfigurationContainer = props => {
  const { modalProps, onModalOpen, onTitleChange } = useDynamicModals()

  return (
    <>
      <DynamicModal {...modalProps} />

      {props.isTypificationOpen && (
        <Modal title="Tipificación" isOpen onClose={props.closeTypification}>
          <TypificationsModal
            onCancel={props.closeTypification}
            lineNumbers={props.lineNumbers}
            documentId={props.documentId}
          />
        </Modal>
      )}
      {props.subscriptions.map(subscription => (
        <SubscriptionCardEnhanced
          key={subscription.id}
          subscription={subscription}
          onModalOpen={onModalOpen}
          onTitleChange={onTitleChange}
        />
      ))}
    </>
  )
}

OffersConfigurationContainer.propTypes = {
  documentId: PropTypes.string.isRequired,
  subscriptions: PropTypes.array,
  lineNumbers: PropTypes.arrayOf(PropTypes.string),
  isTypificationOpen: PropTypes.bool,
  closeTypification: PropTypes.func.isRequired,
}

export default ({ ...rest }) => {
  const dispatch = useDispatch()

  const subscriptions = useSelector(getSubscriptions)

  const documentId = useSelector(getIdentificationId)
  const isTypificationOpen = useSelector(selectIsTypificationOpen)
  const lineNumbers = flatMap(subscriptions, sub => getLineNumbers(sub))
  const areSubscriptionsLoading = useSelector(selectAreSubscriptionsLoading)
  const isLoading = useSelector(selectIsLoading)
  const error = useSelector(getCVMError)
  const typificationError = useSelector(getTypificationError)
  const isTypificationDone = useSelector(isDone)

  useEffect(() => {
    dispatch(initCustomerOffersConfiguration())
  }, [])

  if (isTypificationDone) {
    window.close()

    return null
  }

  const generateOffersContainer = children => (
    <Container>
      <OffersConfigurationActions />

      {children}
    </Container>
  )

  if (isLoading) {
    return generateOffersContainer(
      <Modal isOpen>
        <div style={{ height: '85px' }}>
          <SpinnerCenter showMsg style={{ marginTop: '10%' }} />
        </div>
      </Modal>,
    )
  }

  if (error) {
    return generateOffersContainer(
      <NotificationModal
        type="alert"
        src="/assets/error.svg"
        title="Se ha encontrado un error al actualizar las suscripciones del cliente."
        isOpen
        onClose={() => dispatch(clearError())}>
        {error.map(errorMessage => (
          <Typography {...TextStyles.title2Dark()}>{errorMessage}</Typography>
        ))}
      </NotificationModal>,
    )
  }

  if (typificationError) {
    return generateOffersContainer(
      <NotificationModal
        type="alert"
        src="/assets/error.svg"
        title="Se ha encontrado un error al hacer la tipificación."
        isOpen
        onClose={() => dispatch(clearTypificationError())}>
        <Typography {...TextStyles.title2Dark()}>{get(typificationError, 'info', '-')}</Typography>
      </NotificationModal>,
    )
  }

  if (areSubscriptionsLoading) {
    return generateOffersContainer(<SpinnerCenter showMsg style={{ marginTop: '50%' }} />)
  }

  const props = {
    ...rest,
    subscriptions,
    documentId,
    isTypificationOpen,
    lineNumbers,
    closeTypification: () => dispatch(closeTypification()),
  }

  return generateOffersContainer(<OffersConfigurationContainer {...props} />)
}
