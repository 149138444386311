/* eslint-disable import/no-cycle */
import { LayoutComponent } from 'components/ui'
import PropTypes from 'prop-types'

import { SalesHeader } from '../SalesHeader'
import SidebarContainer from '../../containers/SidebarContainer/SidebarContainer'
import { NewClientSalesStep } from './NewClientSalesStep'

export function NewClientSalesComponent(props) {
  const { step, saleType } = props

  const mainContent = <NewClientSalesStep step={step} sale={saleType} {...props} />

  return (
    <LayoutComponent
      width="100%"
      fullWidth
      headerContent={<SalesHeader onCancelSalesProcess={props.cancelSalesProcess} />}
      sidebarContent={<SidebarContainer step={step} saleType={saleType} />}
      mainContent={mainContent}
    />
  )
}

NewClientSalesComponent.propTypes = {
  getCoverageData: PropTypes.func.isRequired,
  step: PropTypes.string.isRequired,
  saleType: PropTypes.string.isRequired,
  queryParams: PropTypes.object.isRequired,
  cancelSalesProcess: PropTypes.func.isRequired,
  theme: PropTypes.object,
}
