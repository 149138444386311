import * as constants from './constants'

const findSubscriptions = () => ({
  type: constants.FIND_SUBSCRIPTIONS_ATTEMPTED,
})

const findSubscriptionsSucceeded = data => ({
  type: constants.FIND_SUBSCRIPTIONS_SUCCEEDED,
  payload: { data: data?.products },
})

const findSubscriptionsFailed = error => ({
  type: constants.FIND_SUBSCRIPTIONS_FAILED,
  payload: error,
})

const resetSubscriptions = () => ({
  type: constants.RESET_SUBSCRIPTIONS,
})

const findSubscriptionByMsisdn = msisdn => ({
  type: constants.FIND_SUBSCRIPTION_BY_ID_ATTEMPTED,
  payload: {
    msisdn,
  },
})

const findSubscriptionByMsisdnSucceeded = (msisdn, data) => ({
  type: constants.FIND_SUBSCRIPTION_BY_ID_SUCCEEDED,
  payload: {
    msisdn,
    data,
  },
})

const findSubscriptionByMsisdnFailed = (msisdn, error) => ({
  type: constants.FIND_SUBSCRIPTION_BY_ID_FAILED,
  payload: {
    msisdn,
    error,
  },
})

const changeTariff = (msisdn, tariffId, options, v3) => ({
  type: constants.CHANGE_TARIFF_ATTEMPTED,
  loading: true,
  payload: { msisdn, tariffId, options, v3 },
})

const changeTariffSucceeded = msisdn => ({
  type: constants.CHANGE_TARIFF_SUCCEEDED,
  loading: false,
  error: false,
  payload: { msisdn },
})

const changeTariffFailed = (msisdn, error) => ({
  type: constants.CHANGE_TARIFF_FAILED,
  loading: false,
  error: true,
  payload: { msisdn, error },
})

const setBundles = (msisdn, bundleId, bundleStatus) => {
  return {
    type: constants.SET_BUNDLES_ATTEMPTED,
    payload: { msisdn, bundleId, bundleStatus },
  }
}

const setBundlesSucceeded = msisdn => ({
  type: constants.SET_BUNDLES_SUCCEEDED,
  payload: { msisdn },
})

const setBundlesFailed = (msisdn, error) => ({
  type: constants.SET_BUNDLES_FAILED,
  payload: { msisdn, error },
})

const disableDataBonus = (msisdn, bonusId) => {
  return {
    type: constants.DISABLE_DATA_BONUS_ATTEMPTED,
    payload: { msisdn, bonusId },
  }
}

const disableDataBonusSucceeded = msisdn => ({
  type: constants.DISABLE_DATA_BONUS_SUCCEEDED,
  payload: { msisdn },
})

const disableDataBonusFailed = (msisdn, error) => ({
  type: constants.DISABLE_DATA_BONUS_FAILED,
  payload: { msisdn, error },
})

const setDataBonusHebeSva = (
  msisdn,
  service,
  status,
  resetOnSuccess = false,
  resetOnFailure = false,
) => ({
  type: constants.SET_SVA_ATTEMPTED,
  payload: { msisdn, service, status, resetOnSuccess, resetOnFailure },
})

const setDataBonusHebeSvaSucceeded = (msisdn, status) => ({
  type: constants.SET_SVA_SUCCEEDED,
  payload: { msisdn, status },
})

const setDataBonusHebeSvaFailed = (msisdn, error) => ({
  type: constants.SET_SVA_FAILED,
  payload: { msisdn, error },
})

const setDataBonusHebeSvaReset = msisdn => ({
  type: constants.SET_SVA_RESET,
  payload: { msisdn },
})

const disableSvaSmartHome = (msisdn, orderId, contractId, documentNumber) => ({
  type: constants.DISABLE_SVA_SMARTHOME_ATTEMPTED,
  payload: { msisdn, orderId, contractId, documentNumber },
})

const disableSvaSmartHomeSucceeded = msisdn => ({
  type: constants.DISABLE_SVA_SMARTHOME_SUCCEEDED,
  payload: { msisdn },
})

const disableSvaSmartHomeFailed = (msisdn, error) => ({
  type: constants.DISABLE_SVA_SMARTHOME_FAILED,
  payload: { msisdn, error },
})

const disableSvaSmartHomeReset = msisdn => ({
  type: constants.DISABLE_SVA_SMARTHOME_RESET,
  payload: { msisdn },
})

const disableSvaHomeGo = (msisdn, subscriptionId, psIdKit, reason) => ({
  type: constants.DISABLE_SVA_HOMEGO_ATTEMPTED,
  payload: { msisdn, subscriptionId, psIdKit, reason },
})

const disableSvaHomeGoSucceeded = data => ({
  type: constants.DISABLE_SVA_HOMEGO_SUCCEEDED,
  payload: { data },
})

const disableSvaHomeGoFailed = error => ({
  type: constants.DISABLE_SVA_HOMEGO_FAILED,
  payload: { error },
})

const disableSvaHomeGoReset = () => ({
  type: constants.DISABLE_SVA_HOMEGO_RESET,
})

export const clearSTCError = msisdn => ({
  type: constants.CHANGE_TARIFF_CLEAR_ERRORS,
  error: false,
  loading: false,
  payload: { msisdn },
})

export const clearDisableBonusError = msisdn => ({
  type: constants.DISABLE_DATA_BONUS_CLEAR_ERRORS,
  error: false,
  loading: false,
  payload: { msisdn },
})

const setUpsellBundles = (msisdn, bundleId, customerId) => ({
  type: constants.SET_UPSELL_BUNDLES_ATTEMPTED,
  payload: { msisdn, bundleId, customerId },
})

const setUpsellBundlesSucceeded = msisdn => ({
  type: constants.SET_UPSELL_BUNDLES_SUCCEEDED,
  payload: { msisdn },
})

const setUpsellBundlesFailed = (msisdn, error) => ({
  type: constants.SET_UPSELL_BUNDLES_FAILED,
  payload: { msisdn, error },
})

const findPortOutInfo = msisdn => ({
  type: constants.FIND_PORT_OUT_INFO_ATTEMPTED,
  payload: { msisdn },
})

const findPortOutInfoSucceeded = (msisdn, data) => ({
  type: constants.FIND_PORT_OUT_INFO_SUCCEEDED,
  payload: { msisdn, data },
})

const findPortOutInfoFailed = (msisdn, error) => ({
  type: constants.FIND_PORT_OUT_INFO_FAILED,
  payload: { msisdn, error },
})

const findDocumentCDCT = (subscriptionId) => ({
  type: constants.FIND_DOCUMENT_CDCT_WORKORDER_ID_ATTEMPTED,
  payload: { subscriptionId },
})

const findDocumentCDCTSucceeded = (subscriptionId, data) => ({
  type: constants.FIND_DOCUMENT_CDCT_WORKORDER_ID_SUCCEEDED,
  payload: { subscriptionId, data },
})

const findDocumentCDCTFailed = (subscriptionId, error) => ({
  type: constants.FIND_DOCUMENT_CDCT_WORKORDER_ID_FAILED,
  payload: { subscriptionId, error },
})

export default {
  findSubscriptions,
  findSubscriptionsFailed,
  findSubscriptionsSucceeded,
  resetSubscriptions,
  findSubscriptionByMsisdn,
  findSubscriptionByMsisdnFailed,
  findSubscriptionByMsisdnSucceeded,
  changeTariff,
  changeTariffSucceeded,
  changeTariffFailed,
  setBundles,
  setBundlesSucceeded,
  setBundlesFailed,
  disableDataBonus,
  disableDataBonusSucceeded,
  disableDataBonusFailed,
  clearDisableBonusError,
  clearSTCError,
  setUpsellBundles,
  setUpsellBundlesSucceeded,
  setUpsellBundlesFailed,
  findPortOutInfo,
  findPortOutInfoSucceeded,
  findPortOutInfoFailed,
  setDataBonusHebeSva,
  setDataBonusHebeSvaSucceeded,
  setDataBonusHebeSvaFailed,
  setDataBonusHebeSvaReset,
  disableSvaSmartHome,
  disableSvaSmartHomeSucceeded,
  disableSvaSmartHomeFailed,
  disableSvaSmartHomeReset,
  disableSvaHomeGo,
  disableSvaHomeGoSucceeded,
  disableSvaHomeGoFailed,
  disableSvaHomeGoReset,
  findDocumentCDCT,
  findDocumentCDCTSucceeded,
  findDocumentCDCTFailed,
}
