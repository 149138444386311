import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { get } from 'lodash'

import * as productOrdersSvc from 'services/product-orders'
import * as subscriptionsSvc from 'services/subscriptions'
import { selectors as customer360SvcSelectors } from 'services/customer-360/selectors'

import { getContractPdf } from 'services/contract-files'
import { getDocumentTechDom } from 'services/document-template'
import { openBlob, buildBlob } from 'utils'
import { logError, formatErrorMessage } from 'services/logging'
import { selectURLProductId } from 'services/customer-products'

import {
  BONUS_TYPE_CODE,
  SVA_HEBE_CODE,
  SVA_HOMEGO_CODE,
  SVA_SMARTHOME_CODE,
} from 'modules/offers-configuration/constants'
import { getElFijoMsisdn } from 'modules/tariffs/helpers'

import { ProductMain } from '../../components/ProductMain/ProductMain'
import {
  extractDataMobileConsumption,
  extractDataFixedConsumption,
} from '../../selectors/selectors'
import { productMainContainerSelectors } from './ProductMainContainer.selectors'

const ProductMainContainer = ({ ...restProps }) => {
  const dispatch = useDispatch()

  const [contractDownloadLoading, setContractDownloadLoading] = useState(false)
  const [contractDownloadError, setContractDownloadError] = useState(false)

  const getBlobContractDownload = (fetchDownloadFunc, contractId) => {
    setContractDownloadLoading(true)
    setContractDownloadError(false)

    fetchDownloadFunc(contractId)
      .then(blob => {
        const blobBuilt = buildBlob([blob], 'pdf')
        openBlob(blobBuilt)
      })
      .catch(e => {
        setContractDownloadError(true)
        logError(new Error(formatErrorMessage(e)))
      })
      .then(() => setContractDownloadLoading(false))
  }

  const onContractDownload = contractId => {
    getBlobContractDownload(getContractPdf, contractId)
  }

  const onContractCdCtDownload = contractId => {
    getBlobContractDownload(getDocumentTechDom, contractId)
  }

  const productId = useSelector(selectURLProductId)
  const subscription = useSelector(subscriptionsSvc.getSubscriptionByCurrentProductId)

  const product = subscriptionsSvc.getProductById(subscription, productId)

  const billingAddress = useSelector(customer360SvcSelectors.getBillingAddress)
  const zipCode = get(billingAddress, 'postCode')

  const isFixedLine = product && subscriptionsSvc.isFixedLine(product)
  let fixedInstallationOrder

  const ordersByProductId = useSelector(productOrdersSvc.selectOrdersByProductId(productId))
  if (product && isFixedLine) {
    fixedInstallationOrder =
      productOrdersSvc.selectLastOrderWithInstallationFixedLine(ordersByProductId)
  }

  const msisdn = subscriptionsSvc.getMsisdn(subscription)

  const {
    STCErrors,
    isTariffChangeLoading,
    disableBonusErrors,
    disableBonusOK,
    disableBonusPreviousStatus,
    isDisableBonusLoading,
    disableSvaSmartHomeError,
    disableSvaSmartHomeOk,
    disableSvaHomeGoError,
    disableSvaHomeGoOk,
    documentType,
    documentId,
    uuid,
    sfid,
    caseOpened,
    customerBasicInfo,
    tax,
    dss,
  } = useSelector(productMainContainerSelectors(subscription, msisdn, productId, zipCode))

  const clearSTCError = () =>
    dispatch(subscriptionsSvc.actions.clearSTCError(subscriptionsSvc.getMsisdn(subscription)))

  const disableDataBonusSva = (
    currentSubscription,
    bundleType,
    orderId = null,
    contractId = null,
    documentNumber = null,
    psKitId = null,
  ) => {
    const bonusType = subscriptionsSvc.getBonusType(currentSubscription)
    const currentMsisdn = subscriptionsSvc.getMsisdn(currentSubscription)
    const msisdnFijo = getElFijoMsisdn(currentSubscription)

    const { disableDataBonus, setDataBonusHebeSva, disableSvaSmartHome, disableSvaHomeGo } =
      subscriptionsSvc.actions

    switch (bundleType) {
      case BONUS_TYPE_CODE:
        return dispatch(disableDataBonus(currentMsisdn, bonusType))
      case SVA_HEBE_CODE:
        return dispatch(
          setDataBonusHebeSva(
            currentMsisdn,
            SVA_HEBE_CODE,
            subscriptionsSvc.SVA_STATUS_DISABLED,
            true,
            false,
          ),
        )
      case SVA_SMARTHOME_CODE:
        return dispatch(disableSvaSmartHome(msisdnFijo, orderId, contractId, documentNumber))
      case SVA_HOMEGO_CODE:
        return dispatch(
          disableSvaHomeGo(msisdnFijo, currentSubscription?.subscription_id, psKitId, ''),
        )
      default:
        return null
    }
  }

  const clearDisableBonusSvaError = (currentSubscription, bundleType) => {
    const currentMsisdn = subscriptionsSvc.getMsisdn(currentSubscription)

    const { clearDisableBonusError, disableSvaSmartHomeReset, disableSvaHomeGoReset } =
      subscriptionsSvc.actions

    switch (bundleType) {
      case BONUS_TYPE_CODE:
        return dispatch(clearDisableBonusError(currentMsisdn))
      // TODO: Descomentar cuando se arregle la parte de HEBE
      // case SVA_HEBE_CODE:
      //  return dispatch(clearDisableBonusError(currentMsisdn))
      case SVA_SMARTHOME_CODE:
        return dispatch(disableSvaSmartHomeReset(currentMsisdn))
      case SVA_HOMEGO_CODE:
        return dispatch(disableSvaHomeGoReset())
      default:
        return null
    }
  }

  const props = {
    ...restProps,
    contractDownloadLoading,
    contractDownloadError,
    onContractDownload,
    onContractCdCtDownload,
    subscription,
    product,
    isFixedLine: product && subscriptionsSvc.isFixedLine(product),
    fixedInstallationOrder,
    STCErrors,
    isTariffChangeLoading,
    isDisableBonusLoading,
    disableSvaSmartHomeError,
    disableSvaSmartHomeOk,
    disableSvaHomeGoError,
    disableSvaHomeGoOk,
    disableBonusPreviousStatus,
    disableBonusErrors,
    disableBonusOK,
    documentType,
    documentId,
    uuid,
    sfid,
    caseOpened,
    customerBasicInfo,
    dataConsumption: {
      mobile: extractDataMobileConsumption(subscription),
      fixed: extractDataFixedConsumption(subscription),
    },
    tax,
    dss,
    paymentType: subscriptionsSvc.getPaymentType(subscription),
    clearSTCError,
    disableDataBonusSva,
    clearDisableBonusSvaError,
  }

  return <ProductMain {...props} />
}

export default ProductMainContainer
