import PropTypes from 'prop-types'
import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { TextStyles } from 'utils/text'
import { progressRatio } from 'utils/math'
import { TitleLineItemCard, Progressbar, LineItemCard } from 'components/ui'

const useStyles = makeStyles({
  'consumption-counter': {
    '& > .line-item-card': {
      '& .primary-text': {
        textTransform: 'none',
      },
    },

    '& .txt-range': {
      fontWeight: 400,
    },
  },
})

export const ConsumptionCounter = ({
  title,
  data: { totalUsage, totalAmount },
  formatRangeStart,
  formatRangeEnd,
  formatRemaining,
  formatConsumption,
  hasUnlimited,
  className,
}) => {
  const classes = useStyles()

  return (
    <Box
      display="flex"
      flexDirection="column"
      flexGrow={1}
      className={[classes['consumption-counter'], ...(className ? [className] : [])].join(' ')}>
      <TitleLineItemCard primaryText={title} isHeader />
      <div className="p-y-8 p-x-24">
        <Box display="flex" justifyContent="space-between">
          <Typography
            {...TextStyles.action({
              className: 'txt-range',
            })}>
            {formatRangeStart(0)}
          </Typography>
          <Box flexGrow={1} alignSelf="center" px="20px">
            <Progressbar
              value={
                progressRatio(hasUnlimited ? totalAmount : totalAmount - totalUsage, totalAmount) *
                100
              }
            />
          </Box>
          <Typography
            {...TextStyles.action({
              className: 'txt-range',
            })}>
            {formatRangeEnd(totalAmount, hasUnlimited)}
          </Typography>
        </Box>
      </div>
      <LineItemCard
        primaryText="Le quedan"
        secondaryText={formatRemaining(totalUsage, totalAmount, hasUnlimited)}
      />
      <LineItemCard primaryText="Ha consumido" secondaryText={formatConsumption(totalUsage)} />
    </Box>
  )
}

ConsumptionCounter.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  data: PropTypes.shape({
    totalUsage: PropTypes.number,
    totalAmount: PropTypes.number,
  }),
  formatRangeStart: PropTypes.func,
  formatRangeEnd: PropTypes.func,
  formatRemaining: PropTypes.func,
  formatConsumption: PropTypes.func,
  hasUnlimited: PropTypes.bool,
}
