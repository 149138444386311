import { useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { get, isEmpty } from 'lodash'
import {
  SpinnerCenter,
  LineItemCard,
  ButtonLink,
  NotificationModal,
  ActionAdvice,
  Modal,
  ModalActions,
  Button,
  ButtonCard,
} from 'components/ui'
import { useHistory, useLocation } from 'react-router-dom'

import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { TextStyles } from 'utils/text'

import { HighLightFrame, PopoverMenu } from 'modules/ui/components'

import { buildPath, AppPaths } from 'modules/AppPaths'
import { mbToGb, formatGB, formatMB, formatMonthlyFee, formatFee } from 'utils'

import { applyTax, getTax } from 'services/taxes'
import { selectURLDocumentId, selectURLDocumentType } from 'modules/CustomerDocument'
import { getSubscriptionTariffId, SVA_STATUS_DISABLED } from 'services/subscriptions'
import { selectors as customerSelectors } from 'services/customer-360'
import {
  isTariffCompatibleWithSva,
  getSubscriptionMostRecentOrder,
} from 'modules/offers-configuration/selectors'

import { useIsEnabledCvmAddDiscountsOrBonus } from 'modules/offers-configuration/hooks'

import { CVMActions } from 'modules/offers-configuration/CVMActions.constants'
import { BONUS_CODE_1GB, BONUS_CODE_3GB } from 'services/subscriptions/constants'
import {
  BONUS_TYPE_CODE,
  SVA_HEBE_CODE,
  SVA_HOMEGO_CODE,
  SVA_SMARTHOME_CODE,
  SVA_WIFI_TOTAL_01,
  SVA_WIFI_TOTAL_02,
} from 'modules/offers-configuration/constants'
import { getMsisdnByTypeSubscription } from 'modules/offers-configuration/helpers'
import { selectAllowedCancelHomeGoFlag } from 'services/customer-sva/selectors'
import { isProCustomer } from 'services/customer-360/helpers'
import { urlToRouterParams } from 'modules/Router/helpers'
import { getPenaltyTexts } from 'modules/SVAs/helpers/helpers'
import { HomeGoSvaDetailsModal } from './HomeGoSvaDetailsModal'

const PADDING_ELEMENT = '9px 24px'

const BUNDLE_TYPE_BONUS = 'bonus'
const BUNDLE_TYPE_SVA = 'sva'

const messages = {
  [BUNDLE_TYPE_BONUS]: {
    notificationTitle: 'Se ha encontrado un error al actualizar las suscripciones del cliente.',
    adviceDisableSuccess: 'Bono desactivado correctamente. Permanecerá activo hasta final de mes.',
    disableSuccessInfo: 'Este Bono se desactivará a final de mes.',
    titleModal: 'Desactivación de Bono',
    disableBonus: 'Se va a proceder a la desactivación del bono',
  },

  [BUNDLE_TYPE_SVA]: {
    notificationTitle: 'Se ha encontrado un error al actualizar el SVA del cliente.',
    adviceDisableSuccess: 'SVA desactivado correctamente.',
    disableSuccessInfo: 'Este SVA se desactivará.',
    penalty: 'Si no devuelve el dispositivo, se le cobrará una penalización de',
    titleModal: 'Desactivación de SVA',
    disableSva: '¿Está seguro de proceder a la desactivación del ',
  },
}

const useStyles = makeStyles(theme => ({
  'subscription-bundles-info': {
    '& .btn-link': {
      textAlign: 'left',
    },

    '& .text-with-padding': {
      padding: PADDING_ELEMENT,
      color: theme.palette.brand.medium,
    },
  },
}))

export function SubscriptionBundlesInfo({
  className,
  subscription,
  bonusesUpsell,
  bonusesMain,
  svaHebe,
  svaSmartHome,
  svaWifiTotal,
  svaHomeGo,
  disableDataBonusSva,
  isDisableBonusLoading,
  disableBonusPreviousStatus,
  disableBonusErrors,
  disableBonusOK,
  disableSvaSmartHomeError,
  disableSvaSmartHomeOk,
  disableSvaHomeGoError,
  disableSvaHomeGoOk,
  clearDisableBonusSvaError,
  dataHook,
  isPosOrMasProximo,
}) {
  const classes = useStyles()

  const isEnabledCvmAddBonusForSub = useIsEnabledCvmAddDiscountsOrBonus(subscription, false)

  const documentType = useSelector(selectURLDocumentType)
  const documentId = useSelector(selectURLDocumentId)
  const buildPathTo = id =>
    buildPath(
      AppPaths.cvm.id,
      { documentType, documentId },
      { subscription_id: subscription.id, op: id },
    )

  const history = useHistory()
  const location = useLocation()
  const handleClickAddDiscount = pathTo => {
    const url = urlToRouterParams(location, pathTo)
    history.push(url)
  }

  const customerSegment = useSelector(customerSelectors.getSegment)
  const isPro = isProCustomer(customerSegment)

  const subscriptionTariffId = getSubscriptionTariffId(subscription)
  const isCustomerResidential = useSelector(customerSelectors.selectIsCustomerResidential)
  const isTariffSvaCompatible = useSelector(state =>
    isTariffCompatibleWithSva(state, subscriptionTariffId),
  )
  const isSvaCompatible = isTariffSvaCompatible && isCustomerResidential

  const billingAddress = useSelector(state => customerSelectors.getBillingAddress(state))
  const tax = useSelector(state => getTax(state, get(billingAddress, 'postCode')))

  const wasDisabledInLastOperation = disableBonusPreviousStatus === SVA_STATUS_DISABLED

  const [isDisableModalBundleOpen, setReloadModalBundleOpen] = useState(false)
  const [isDisableModalHebeOpen, setReloadModalHebeOpen] = useState(false)
  const [isDisableModalSmartHomeOpen, setReloadModalSmartHomeOpen] = useState(false)
  const [isDisableModalHomeGoOpen, setReloadModalHomeGoOpen] = useState(false)

  const [isInfoModalOpen, setInfoModalOpen] = useState(false)

  const msisdn = getMsisdnByTypeSubscription(subscription)
  const orderByProductId = useSelector(state => getSubscriptionMostRecentOrder(state, msisdn))
  const documentNumber = useSelector(customerSelectors.getIdentificationId)

  const allowCancelHomeGo = useSelector(selectAllowedCancelHomeGoFlag)

  const getMessage = (bundleMessage, idMessage) => get(messages, [bundleMessage, idMessage])

  const onFocusModal = bundle => {
    if (bundle.type) {
      setReloadModalBundleOpen(!isDisableModalBundleOpen)
    } else if (bundle.contractTypeId === SVA_HEBE_CODE) {
      setReloadModalHebeOpen(!isDisableModalHebeOpen)
    } else if (bundle.contractTypeId === SVA_SMARTHOME_CODE) {
      setReloadModalSmartHomeOpen(!isDisableModalSmartHomeOpen)
    } else if (bundle.contractTypeId?.includes(SVA_HOMEGO_CODE)) {
      setReloadModalHomeGoOpen(!isDisableModalHomeGoOpen)
    }
  }

  const onConfirmModal = bundle => {
    if (bundle.type) {
      disableDataBonusSva(subscription, BONUS_TYPE_CODE)
    } else if (bundle.contractTypeId === SVA_HEBE_CODE) {
      disableDataBonusSva(subscription, SVA_HEBE_CODE)
    } else if (bundle.contractTypeId === SVA_SMARTHOME_CODE) {
      const orderId = orderByProductId?.id
      const contractId = orderByProductId?.contractId

      disableDataBonusSva(subscription, SVA_SMARTHOME_CODE, orderId, contractId, documentNumber)
    } else if (bundle.contractTypeId?.includes(SVA_HOMEGO_CODE)) {
      disableDataBonusSva(subscription, SVA_HOMEGO_CODE, null, null, null, bundle.contractTypeId)
    }

    onFocusModal(bundle)
  }

  const formatSvaMonthlyFee = bundle =>
    formatMonthlyFee(applyTax(tax, get(bundle, 'monthlyFee', 0)))

  const getLineItemCardText = ({ type, contractTypeId, contractTypeName }, withFee = true) => {
    let itemCardText

    if (type === BONUS_CODE_1GB) {
      itemCardText = `Bono 1 GB${withFee ? ' (6 €/mes)' : ''}`
    } else if (type === BONUS_CODE_3GB) {
      itemCardText = `Bono 3 GB${withFee ? ' (6,99 €/mes)' : ''}`
    } else if (contractTypeId === SVA_HEBE_CODE) {
      itemCardText = `SVA HEBE${withFee ? ` (${formatSvaMonthlyFee(svaHebe)})` : ''}`
    } else if (contractTypeId === SVA_SMARTHOME_CODE) {
      itemCardText = `SVA SMART HOME${withFee ? ` (${formatSvaMonthlyFee(svaSmartHome)})` : ''}`
    } else if (contractTypeId?.includes(SVA_HOMEGO_CODE)) {
      itemCardText = contractTypeName
    } else if (contractTypeId === SVA_WIFI_TOTAL_01) {
      itemCardText = 'WIFI TOTAL 1 dispositivo'
    } else if (contractTypeId === SVA_WIFI_TOTAL_02) {
      itemCardText = 'WIFI TOTAL 2 dispositivos'
    }

    return itemCardText
  }

  const getModalDisableBundleTitle = bundle =>
    getMessage(bundle.type ? BUNDLE_TYPE_BONUS : BUNDLE_TYPE_SVA, 'titleModal')

  const formatSvaPenalty = ({ penalty }) => {
    let message = ''
    if (penalty) {
      message = ` ${getMessage(BUNDLE_TYPE_SVA, 'penalty')} ${formatFee(applyTax(tax, penalty))}.`
    }
    return message
  }

  const getModalDisableBundleParagraphBody = bundle => {
    let paragraphText

    const bundleDescription = getLineItemCardText(bundle, false)

    if (bundle.type) {
      paragraphText = `${getMessage(BUNDLE_TYPE_BONUS, 'disableBonus')} ${bundleDescription}`
    } else if (bundle.contractTypeId === SVA_HEBE_CODE) {
      paragraphText = `${getMessage(BUNDLE_TYPE_SVA, 'disableSva')} ${bundleDescription}?`
    } else if (
      bundle.contractTypeId === SVA_SMARTHOME_CODE ||
      bundle.contractTypeId?.includes(SVA_HOMEGO_CODE)
    ) {
      paragraphText = `${getMessage(
        BUNDLE_TYPE_SVA,
        'disableSva',
      )} ${bundleDescription}?${formatSvaPenalty(bundle)}`
    }

    return paragraphText
  }

  const formatBonusUpsellName = ({ value }) => {
    const amountMB = Number(value)

    if (!Number.isNaN(amountMB)) {
      if (amountMB >= 1024) {
        return `Bono ${formatGB(mbToGb(amountMB), undefined, undefined, {
          noDecimalsIfZero: true,
        })}`
      }
      return `Bono ${formatMB(amountMB, undefined, undefined, {
        noDecimalsIfZero: true,
      })}`
    }

    return 'NaN'
  }

  const onCloseModalError = (
    hasDisableBonusErrors,
    hasDisableSvaSmartHomeError,
    hasDisableSvaHomeGoError,
  ) => {
    if (hasDisableBonusErrors) {
      clearDisableBonusSvaError(subscription, BONUS_TYPE_CODE)
    } else if (hasDisableSvaSmartHomeError) {
      clearDisableBonusSvaError(subscription, SVA_SMARTHOME_CODE)
    } else if (hasDisableSvaHomeGoError) {
      clearDisableBonusSvaError(subscription, SVA_HOMEGO_CODE)
    }
  }

  const generateActionSuccess = (bundleType, actionType) => (
    <Box p="12px 0px 12px 20px">
      <ActionAdvice type="info" message={getMessage(bundleType, actionType, '')} />
    </Box>
  )

  const generateModalAndDisableButton = (
    bundle,
    isDisableModalOpen,
    isDisableButon,
    penaltyTexts,
  ) => (
    <Box padding={PADDING_ELEMENT}>
      <ButtonLink
        className="btn-link"
        onClick={() => onFocusModal(bundle)}
        disabled={isDisableButon}>
        Desactivar
      </ButtonLink>
      <Modal title={getModalDisableBundleTitle(bundle)} isOpen={isDisableModalOpen}>
        <Typography {...TextStyles.dataDark()}>
          {getModalDisableBundleParagraphBody(bundle)}
        </Typography>
        {!isEmpty(penaltyTexts) &&
          penaltyTexts.map(text => (
            <Typography
              {...TextStyles.paragraphDark({
                style: { marginTop: '10px' },
              })}>
              {text}
            </Typography>
          ))}
        <ModalActions>
          <Button onClick={() => onConfirmModal(bundle)}>Desactivar</Button>
          <Button secondary onClick={() => onFocusModal(bundle)}>
            Cancelar
          </Button>
        </ModalActions>
      </Modal>
    </Box>
  )

  const menuActions = [
    {
      label: 'Bonos',
      onClick: () => handleClickAddDiscount(buildPathTo(CVMActions.add_discounts_or_bundles.id)),
      disabled: !isEnabledCvmAddBonusForSub,
    },
    {
      label: 'SVAs',
      onClick: () => handleClickAddDiscount(buildPathTo(CVMActions.add_sva.id)),
    },
  ]

  return (
    <div
      className={[classes['subscription-bundles-info'], ...(className ? [className] : [])].join(
        ' ',
      )}
      data-hook={dataHook}>
      <HighLightFrame />
      <div className="flex align-space-between-center">
        <Typography
          {...TextStyles.subtitle2Link({
            className: 'text-with-padding',
          })}>
          <span>Bonos y SVAs</span>
        </Typography>
        <Box padding={PADDING_ELEMENT}>
          <PopoverMenu
            ClickableComp={() => <ButtonCard data-hook="lnk-add-bundles-or-sva" label="Añadir" />}
            menuActions={menuActions}
            dropdown
          />
        </Box>
      </div>

      {isDisableBonusLoading && <SpinnerCenter absolute showMsg style={{ marginTop: '10px' }} />}

      {((disableBonusErrors && !isDisableBonusLoading) ||
        disableSvaSmartHomeError ||
        disableSvaHomeGoError) && (
        <NotificationModal
          type="alert"
          src="/assets/error.svg"
          title={getMessage(BUNDLE_TYPE_SVA, 'notificationTitle')}
          isOpen
          onClose={() =>
            onCloseModalError(
              !!disableBonusErrors?.info,
              !!disableSvaSmartHomeError?.info,
              !!disableSvaHomeGoError,
            )
          }>
          <Typography {...TextStyles.title2Dark()}>
            {disableBonusErrors?.info ||
              disableSvaSmartHomeError?.info ||
              disableSvaHomeGoError?.info}
          </Typography>
        </NotificationModal>
      )}

      {isSvaCompatible && !isEmpty(svaHebe) && (
        <>
          <LineItemCard primaryText={getLineItemCardText(svaHebe)} secondaryText="Activo" />

          {!isDisableBonusLoading &&
            (wasDisabledInLastOperation || disableBonusOK) &&
            !svaHebe.isDisabled &&
            generateActionSuccess(BUNDLE_TYPE_SVA, 'adviceDisableSuccess')}

          {svaHebe.isDisabled && generateActionSuccess(BUNDLE_TYPE_SVA, 'disableSuccessInfo')}

          {generateModalAndDisableButton(
            svaHebe,
            isDisableModalHebeOpen,
            isPosOrMasProximo || svaHebe.isDisabled || wasDisabledInLastOperation,
          )}
        </>
      )}

      {!isEmpty(svaSmartHome) && (
        <>
          <LineItemCard primaryText={getLineItemCardText(svaSmartHome)} secondaryText="Activo" />

          {disableSvaSmartHomeOk && generateActionSuccess(BUNDLE_TYPE_SVA, 'adviceDisableSuccess')}

          {generateModalAndDisableButton(
            svaSmartHome,
            isDisableModalSmartHomeOpen,
            isPosOrMasProximo || disableSvaSmartHomeOk,
          )}
        </>
      )}

      {!isEmpty(svaHomeGo) && (
        <>
          <LineItemCard
            primaryText={getLineItemCardText(svaHomeGo)}
            secondaryText="Activo"
            listActions={[
              {
                dataHook: 'detail-sva-homego',
                icon: 'visibility',
                action: () => setInfoModalOpen(true),
                disabled: !svaHomeGo.contractTypeName,
              },
            ]}
            fieldsApart
          />

          {disableSvaHomeGoOk && generateActionSuccess(BUNDLE_TYPE_SVA, 'adviceDisableSuccess')}

          {generateModalAndDisableButton(
            svaHomeGo,
            isDisableModalHomeGoOpen,
            isPosOrMasProximo || disableSvaHomeGoOk || !allowCancelHomeGo,
            getPenaltyTexts(svaHomeGo),
          )}

          {isInfoModalOpen && (
            <HomeGoSvaDetailsModal
              isOpen={isInfoModalOpen}
              setIsOpen={setInfoModalOpen}
              title={`Contrato de alarma Home GO - ${svaHomeGo.msisdn}`}
              data={svaHomeGo}
              isPro={isPro}
            />
          )}
        </>
      )}

      {!isEmpty(bonusesUpsell) &&
        bonusesUpsell.map(bonusUpsell => (
          <LineItemCard
            key={bonusUpsell.id}
            primaryText={formatBonusUpsellName(bonusUpsell)}
            secondaryText={bonusUpsell.active ? 'Activo' : 'Inactivo'}
          />
        ))}

      {!isEmpty(bonusesMain) && (
        <>
          <LineItemCard primaryText={getLineItemCardText(bonusesMain)} secondaryText="Activo" />

          {!isDisableBonusLoading &&
            !disableBonusErrors &&
            disableBonusOK &&
            !bonusesMain.isDisabled &&
            generateActionSuccess(BUNDLE_TYPE_BONUS, 'adviceDisableSuccess')}

          {bonusesMain.isDisabled && generateActionSuccess(BUNDLE_TYPE_BONUS, 'disableSuccessInfo')}

          {generateModalAndDisableButton(
            bonusesMain,
            isDisableModalBundleOpen,
            isPosOrMasProximo || bonusesMain.isDisabled || disableBonusOK,
          )}
        </>
      )}

      {!isEmpty(svaWifiTotal) && (
        <LineItemCard primaryText={getLineItemCardText(svaWifiTotal)} secondaryText="Activo" />
      )}

      {isEmpty(svaSmartHome) &&
        isEmpty(svaHebe) &&
        isEmpty(svaHomeGo) &&
        isEmpty(bonusesUpsell) &&
        isEmpty(bonusesMain) &&
        isEmpty(svaWifiTotal) && (
          <Typography
            {...TextStyles.body({
              style: {
                padding: PADDING_ELEMENT,
                fontStyle: 'italic',
              },
            })}>
            <span>No tiene ninguno activo</span>
          </Typography>
        )}
    </div>
  )
}

SubscriptionBundlesInfo.propTypes = {
  className: PropTypes.string,
  subscription: PropTypes.object,
  bonusesUpsell: PropTypes.arrayOf(PropTypes.object),
  bonusesMain: PropTypes.object,
  svaHebe: PropTypes.arrayOf(PropTypes.object),
  svaSmartHome: PropTypes.object,
  svaWifiTotal: PropTypes.arrayOf(PropTypes.object),
  svaHomeGo: PropTypes.object,
  disableDataBonusSva: PropTypes.func,
  isDisableBonusLoading: PropTypes.bool,
  disableBonusPreviousStatus: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  disableBonusErrors: PropTypes.bool,
  disableBonusOK: PropTypes.bool,
  disableSvaSmartHomeError: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  disableSvaSmartHomeOk: PropTypes.bool,
  disableSvaHomeGoError: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  disableSvaHomeGoOk: PropTypes.bool,
  clearDisableBonusSvaError: PropTypes.func,
  dataHook: PropTypes.string,
  isPosOrMasProximo: PropTypes.bool,
}
