import { useDispatch } from 'react-redux'

import { CANT_CHECK_ROUTER_NUMBER, ROUTER_NUMBER_INVALID } from 'modules/SharedSales/constants'
import { fetchAdslRouterStatus } from 'modules/Terminals/services/adsl-router'

import { setRequestLoading } from '../../store'

const SERIAL_NUMBER_AVAILABLE_STATUS = 'available'

export function useSerialRouterNumberValidation() {
  const dispatch = useDispatch()

  async function validate(value) {
    if (value.length < 10) {
      return null
    }

    dispatch(setRequestLoading({ loading: true }))

    let state

    try {
      const { status } = await fetchAdslRouterStatus(value)

      if (status !== SERIAL_NUMBER_AVAILABLE_STATUS) {
        state = ROUTER_NUMBER_INVALID
      }
    } catch (e) {
      state = CANT_CHECK_ROUTER_NUMBER
    }

    dispatch(setRequestLoading({ loading: false }))

    return state
  }

  return {
    validateSerialRouterNumber: validate,
  }
}
