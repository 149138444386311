import PropTypes from 'prop-types'

import { Box, Card, CardContent } from '@material-ui/core'

import { TitleSidebar, TextSidebar } from 'components/ui'

export const ClientAndOrderInfo = ({ clientFullName, msisdn, tariffDescription, tariffPrice }) => {
  return (
    <Box
      display="flex"
      pt="20px"
      justifyContent="center"
      data-hook="device-renewal-sidebar-clientInfo">
      <Card style={{ width: '90%', borderRadius: '10px', margin: '20px 0 8px 0' }}>
        <CardContent>
          <div>
            <TitleSidebar>Titular</TitleSidebar>
            <TextSidebar dataHook="client-full-name">{clientFullName || '-'}</TextSidebar>
          </div>
          <div>
            <TitleSidebar dataHook="full-address-title">Subscripción cabecera</TitleSidebar>
            <TextSidebar dataHook="main-line-id">{msisdn}</TextSidebar>
          </div>

          <div>
            <TitleSidebar>Tarifa</TitleSidebar>
            <TextSidebar>{tariffDescription}</TextSidebar>
          </div>

          <div>
            <TitleSidebar>Cuota tarifa</TitleSidebar>
            <TextSidebar>{tariffPrice}</TextSidebar>
          </div>
        </CardContent>
      </Card>
    </Box>
  )
}

ClientAndOrderInfo.propTypes = {
  clientFullName: PropTypes.string,
  msisdn: PropTypes.string,
  tariffDescription: PropTypes.string,
  tariffPrice: PropTypes.string,
}
