import { chain, get } from 'lodash'

const defaultValue = '-'

const MapCompanies = {
  1: 'ASM',
  2: 'Tienda',
  3: 'Correos',
  4: 'Correos',
}

const MapDeliveries = {
  1: 'Entrega en domicilio particular',
  2: 'Recogida en tienda',
  3: 'Recogida segura en sucursal Correos',
  4: 'Recogida en sucursal Correos',
}

const getProductsShipment = shipment =>
  chain(shipment)
    .get('orders')
    .map('productDescription')
    .join(', ')
    .value()

const getDeliveryTypeShipment = shipment =>
  chain(shipment)
    .get('deliveryType')
    .value()

const getExpeditionNumberShipment = shipment =>
  chain(shipment)
    .get('expeditionNumber')
    .value()

const getSendAddressShipment = shipment =>
  chain(shipment)
    .get('sendAddress')
    .pick(['address', 'zipcode', 'city', 'region'])
    .values()
    .filter(Boolean)
    .join(', ')
    .value()

const getFullNameShipment = shipment =>
  chain(shipment)
    .get('owner.name')
    .value()

const getDocumentTypeShipment = shipment =>
  chain(shipment)
    .get('owner.documentType')
    .value()

const getDocumentNumberShipment = shipment =>
  chain(shipment)
    .get('owner.documentNumber')
    .value()

const getPhoneNumberShipment = shipment => {
  const fixed = get(shipment, 'owner.fixed')
  const mobile = get(shipment, 'owner.mobile')
  return mobile === fixed
    ? mobile
    : chain(shipment)
        .get('owner')
        .pick(['fixed', 'mobile'])
        .values()
        .filter(Boolean)
        .join(' / ')
        .value()
}
const getLogisticStates = shipment =>
  chain(shipment)
    .get('orders')
    .first()
    .get('logisticStates')
    .value()

const extractShipmentLogisticData = shipment => {
  const deliveryType = getDeliveryTypeShipment(shipment)

  return {
    productsShipment: getProductsShipment(shipment) || defaultValue,
    deliveryCompany: MapCompanies[deliveryType] || defaultValue,
    expeditionNumber: getExpeditionNumberShipment(shipment),
    deliveryType: MapDeliveries[deliveryType] || defaultValue,
    sendAddress: getSendAddressShipment(shipment) || defaultValue,
    fullname: getFullNameShipment(shipment) || defaultValue,
    documentType: getDocumentTypeShipment(shipment) || defaultValue,
    documentNumber: getDocumentNumberShipment(shipment) || defaultValue,
    phoneNumber: getPhoneNumberShipment(shipment) || defaultValue,
    logisticStates: getLogisticStates(shipment) || defaultValue,
  }
}

export const helpers = { extractShipmentLogisticData }
