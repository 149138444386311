import { get, chain, flatten, eq, filter, maxBy, every, startsWith } from 'lodash'
import { createMatchSelector, getLocation } from 'connected-react-router'
import { createSelector } from 'reselect'
import { getCountryFromIsoCode, getBuildingInfoFromGescal } from 'utils'
import { parse, format } from 'date-fns'
import { selectQueryParam } from 'modules/Router'
import {
  structureAndFilterProductsCard,
  structureAndFilterProductsTree,
  structureAndFilterProductsSidebar,
  extractSubscriptionsData,
} from './helpers'

export const getCustomerProductsNotFiltered = state =>
  get(state, 'customerProducts.customerProducts', [])
export const getCustomerProductsError = state => get(state, 'customerProducts.error', undefined)
export const getCustomerProductsLoading = state => get(state, 'customerProducts.loading', [])
export const getCustomerProductsName = state => {
  const customer = get(state, 'customerProducts.customerProducts[0].customer', undefined)
  if (!customer) return undefined
  const { firstName, midName, lastName } = customer
  return [firstName, midName, lastName].filter(Boolean).join(' ')
}
export const selectCustomerProductsCard = createSelector(
  [getCustomerProductsNotFiltered],
  products => structureAndFilterProductsCard(products),
)
export const selectCustomerProductsTree = createSelector(
  [getCustomerProductsNotFiltered],
  products => structureAndFilterProductsTree(products),
)
export const selectCustomerProductsSidebar = createSelector(
  [getCustomerProductsNotFiltered],
  products => structureAndFilterProductsSidebar(products),
)

export const helperLanguage = customer => {
  const langId = Number(get(customer, 'language', 0))
  switch (langId) {
    case 1:
      return 'Castellano'
    case 2:
      return 'Catalan'
    case 3:
      return 'Euskera'
    case 4:
      return 'Galego'
    case 5:
      return 'Inglés'
    default:
      return 'Idioma desconocido'
  }
}

export const formatDataCardClients = createSelector(
  [getCustomerProductsNotFiltered],
  orders => {
    const data = maxBy(orders, order => parse(order.orderDate, 'yyyy-MM-dd', new Date()))
    const customer = get(data, 'customer', {})
    const indivId = get(customer, 'individualIdentification', null)
    const idCliente = get(data, 'id')
    const contactMedium = get(customer, 'contactMedium', null)
    const email = contactMedium && contactMedium.filter(contact => contact.type === 'Email')[0]
    const phone =
      contactMedium && contactMedium.filter(contact => contact.type === 'TelephoneNumber')[0]
    const { firstName, midName, lastName } = customer
    const billingAddress =
      contactMedium && contactMedium.filter(contact => contact.type === 'BillingAddress')[0]
    const formatBillingAddress =
      billingAddress &&
      billingAddress.medium &&
      `${billingAddress.medium.streetOne} ${billingAddress.medium.streetTwo} ${
        billingAddress.medium.city
      } ${billingAddress.medium.country} ${billingAddress.medium.postCode}`
    const customerCharacteristics = get(customer, 'characteristics', [])
    const birthDay = chain(customerCharacteristics)
      .filter({ name: 'birthday' })
      .map('value')
      .value()[0]
    const language = helperLanguage(customer)
    const clientBy = get(customer, 'incorporationDate')
    const clientByFormat =
      clientBy === 'null' || !clientBy ? '-' : String(format(clientBy, 'dd/MM/yyyy'))
    const segment = get(customer, 'segment', '-')
    return {
      name: {
        title: 'Nombre',
        value: `${firstName} ${midName}`,
      },
      surnames: {
        title: 'Apellidos',
        value: lastName,
      },
      identificationId: {
        title: indivId && get(indivId[0], 'type', ''),
        value: indivId && get(indivId[0], 'identificationId', ''),
      },
      clientId: {
        title: 'Id Cliente',
        value: idCliente,
      },
      birthDay: {
        title: 'Fecha de nacimiento',
        value: birthDay,
      },
      segment: {
        title: 'Segmento',
        value: segment,
      },
      clientBy: {
        title: 'Cliente desde',
        value: clientByFormat,
      },
      email: {
        title: 'Email',
        value: get(email, 'medium.emailAddress', null),
      },
      telephone: {
        title: 'Teléfono de contacto',
        value: get(phone, 'medium.number', null),
      },
      address: {
        title: 'Dirección',
        value: formatBillingAddress,
      },
      languagueComunication: {
        title: 'Idioma de comunicación',
        value: language,
      },
    }
  },
)

export const selectFormattedSubscriptions = createSelector(
  [getCustomerProductsNotFiltered],
  contracts => (contracts ? flatten(contracts.map(extractSubscriptionsData)) : null),
)

export const selectLastOrder = (productId, state) => {
  const selectorLastOrder = createSelector(
    [getCustomerProductsNotFiltered],
    products => {
      const matchOrders = filter(products, product =>
        eq(product.customer.products[0].id, productId),
      )

      return maxBy(matchOrders, order => parse(order.orderDate, 'yyyy-MM-dd', new Date()))
    },
  )

  return selectorLastOrder(state)
}

export const getFixedLine = product =>
  chain(product)
    .get('customer.products[0].productsSpecifications')
    .filter({ name: 'fixed' })
    .head()
    .value()

export const getInstallationAddressFixedLine = order =>
  chain(order)
    .get('customer.contactMedium')
    .filter({ type: 'InstallationAddressFixedLine' })
    .head()
    .get('medium')
    .value()

export const getInstallationAddressFixedLineStr = order => {
  const addressContact = getInstallationAddressFixedLine(order)

  if (addressContact) {
    const { streetOne, postCode, city, country } = addressContact
    const province = chain(addressContact)
      .get('characteristic')
      .filter({ name: 'province' })
      .head()
      .get('value')
      .value()
    const streetNumber = chain(addressContact)
      .get('characteristic')
      .filter({ name: 'streetNumber' })
      .head()
      .get('value')
      .value()
    const gescal = chain(addressContact)
      .get('characteristic')
      .filter({ name: 'gescal' })
      .head()
      .get('value')
      .value()

    return every([!streetOne, !streetNumber, !gescal, !postCode, !city, !province, !country])
      ? '-'
      : filter(
          [
            streetOne,
            streetNumber,
            getBuildingInfoFromGescal(gescal),
            postCode,
            city,
            province,
            getCountryFromIsoCode(country),
          ],
          Boolean,
        ).join(' ')
  }

  return '-'
}

export const selectInstallationFixedLineOrder = (lineNumber, state) => {
  const selectorLastInfoInstallationOrder = createSelector(
    [getCustomerProductsNotFiltered],
    products => {
      const productWithInfoInstallation = filter(products, product => {
        const fixedLine = getFixedLine(product)
        return fixedLine && eq(fixedLine.id, lineNumber) && getInstallationAddressFixedLine(product)
      })
      return maxBy(productWithInfoInstallation, order =>
        parse(order.orderDate, 'yyyy-MM-dd', new Date()),
      )
    },
  )

  return selectorLastInfoInstallationOrder(state)
}

export const selectProductURLMatch = createMatchSelector({
  path: '/vista/dashboard/documentType/:documentType/documentId/:documentId/line/:id',
})

export const selectFixedNumber = selectQueryParam('fixedNumber')

export const isVistaApp = createSelector(
  [getLocation],
  loc => startsWith(get(loc, 'pathname'), '/vista'),
)

export const selectURLProductId = createSelector(
  [selectProductURLMatch, selectFixedNumber, isVistaApp],
  (match, fixedNumber, isVista) => {
    if (isVista) {
      return get(match, 'params.id')
    }

    return get(match, 'params.id') || fixedNumber
  },
)
