export const FIND_BONUSES = 'OVID/BONUSES/FIND'
export const FIND_BONUSES_SUCCESS = 'OVID/BONUSES/SUCCESS'
export const FIND_BONUSES_ERROR = 'OVID/BONUSES/ERROR'

export const findBonusesAction = () => ({
  type: FIND_BONUSES,
})
export const findBonusesSuccessAction = data => ({
  type: FIND_BONUSES_SUCCESS,
  payload: {
    data,
  },
})

export const findBonusesErrorAction = error => ({
  type: FIND_BONUSES_ERROR,
  payload: error,
})
