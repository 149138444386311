import { put, takeLatest, select, take } from 'redux-saga/effects'
import { selectIsLoading } from 'modules/Auth'

import {
  actions as productOrdersActions,
  constants as productOrdersConstants,
} from 'services/product-orders'

import {
  findTariffsErrorAction,
  findTariffByIdAction,
  findTariffsAction,
  FIND_TARIFF_BY_ID_SUCCESS,
  selectTariffApigeeOneTariff,
  categoriesTariff2P_3P,
  selectTariffApigeeIsLoading,
} from 'modules/tariffs/store-apigee'
import { actions as actionsCoverage, getCoverageLoading } from 'modules/Coverage'

import { actions as actionsEnrichment } from 'services/enrichment'
import {
  actions as orderOnFlyActions,
  selectors as selectorsOrderOnFly,
} from 'services/order-on-fly'
import {
  actions as actionsCheckCTCAOnFly,
  selectors as selectorsCheckOrderCTOnFly,
} from 'services/check-order-ct-on-fly'

import {
  actions as actionsNewCustomer,
  selectors as selectorsNewCustomer,
} from 'services/customer-new'
import {
  actions as subscriptionsActions,
  constants as subscriptionsConstants,
  getSubscriptions,
} from 'services/subscriptions'
import { findTypeByFixedNumber } from '../../helpers'

export const INIT_MOVE_CLIENT_ACTION = 'MOVE_CLIENT/CLIENT/INIT'

export const initMoveClientSagaAction = payload => ({
  type: INIT_MOVE_CLIENT_ACTION,
  payload,
})

export function* initMoveClientSaga(e) {
  const { phoneNumber, fixedline } = e.payload

  yield put(actionsNewCustomer.findInit())

  yield put(productOrdersActions.findProductOrders(phoneNumber))
  yield take(productOrdersConstants.FIND_PRODUCT_ORDERS_SUCCESS)
  yield put(subscriptionsActions.findSubscriptionByMsisdn(phoneNumber))

  yield put(actionsCheckCTCAOnFly.checkCTCAOnFly())
  yield put(orderOnFlyActions.findOrderOnFly(fixedline))

  yield put(actionsEnrichment.findEnrichment())

  yield put(actionsCoverage.getCoverageToken())
  yield take(subscriptionsConstants.FIND_SUBSCRIPTION_BY_ID_SUCCEEDED)
  const products = yield select(getSubscriptions)
  const idTariff = findTypeByFixedNumber(products, phoneNumber)
  if (!idTariff) {
    yield put(findTariffsErrorAction({ httpCode: 404, error_message: 'Tariff not found' }))
    return
  }
  yield put(findTariffByIdAction({ id: idTariff }))
  yield take(FIND_TARIFF_BY_ID_SUCCESS)
  const tariff = yield select(selectTariffApigeeOneTariff(idTariff))
  const category = categoriesTariff2P_3P(tariff)
  const segment = yield select(selectorsNewCustomer.getSegment)
  yield put(findTariffsAction(category ? { segment, category } : undefined))
}

export function* watchInitMoveClientSaga() {
  yield takeLatest(INIT_MOVE_CLIENT_ACTION, initMoveClientSaga)
}

export const selectorInitialStep = state =>
  selectIsLoading(state) ||
  selectorsNewCustomer.getLoading(state) ||
  getCoverageLoading(state) ||
  selectTariffApigeeIsLoading(state) ||
  selectorsCheckOrderCTOnFly.getOrderCTOnFlyLoading(state) ||
  selectorsOrderOnFly.orderOnFlyLoading(state)
