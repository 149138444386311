import { APP_CONFIG } from 'services/app-config'
import { axiosMasStack, axiosJWT } from 'modules/axios'

const CONFIG = {
  api: {
    cadenceAlarmsSales: `${APP_CONFIG.mas_care}/alarms/v1/sales`,
  },
}

export function putCadenceAlarmsSales(sfid, dataSVAalarm) {
  return axiosMasStack({
    method: 'POST',
    url: `${CONFIG.api.cadenceAlarmsSales}`,
    data: { ...dataSVAalarm },
    headers: {
      Authorization: axiosJWT.defaults.headers.common.Authorization,
    },
  }).then(resp => resp.data)
}
