import { theme } from 'themes'

export function getPillColor(status) {
  switch (status) {
    case 'info':
      return theme.palette.brand.degradedRadialInfo
    case 'warning':
      return theme.palette.brand.degradedRadialWarning
    case 'alert':
      return theme.palette.brand.degradedRadialAlert
    default:
      return theme.palette.brand.degradedRadialInfo
  }
}

export function getPillBorder(status) {
  switch (status) {
    case 'info':
      return `2px solid ${theme.palette.brand.action_primary}`
    case 'warning':
      return `2px solid ${theme.palette.brand.attention}`
    case 'alert':
      return `2px solid ${theme.palette.brand.alert}`
    default:
      return `2px solid ${theme.palette.brand.action_primary}`
  }
}
