import PropTypes from 'prop-types'
import { OffersCardBack, OfferRotateAction, OfferItemSmall } from 'components/ui'
import { isConvergentSubscription } from 'services/subscriptions'

import { TariffItem } from './TariffItem'
import DiscountsBonusesItem from './DiscountsBonusesItem'
import PermanentItem from './PermanentItem'
import DeviceItem from './DeviceItem'
import PortOutInfo from './PortOutInfo'

export default function PortOut({
  subscription,
  onRotate,
  onModalOpen,

  fixedPortOut,
  mobilePortOut,
}) {
  const hasFixedPortOut = Boolean(fixedPortOut)
  const hasMobilePortOut = Boolean(mobilePortOut)
  const isConvergent = isConvergentSubscription(subscription)

  return (
    <>
      {!isConvergent && hasMobilePortOut && (
        <OffersCardBack>
          <OfferItemSmall label="Tarifa" value={subscription.description} />
          <PortOutInfo portOut={mobilePortOut} type="mobile" />
          <OfferRotateAction text="Ver info Tarifa" onRotate={onRotate} />
        </OffersCardBack>
      )}
      {isConvergent && hasMobilePortOut && hasFixedPortOut && (
        <OffersCardBack>
          <OfferItemSmall label="Tarifa" value={subscription.description} />
          <div>
            <PortOutInfo portOut={mobilePortOut} type="mobile" />
          </div>
          <div>
            <PortOutInfo portOut={fixedPortOut} type="fixed" />
          </div>
          <OfferRotateAction text="Ver info Tarifa" onRotate={onRotate} />
        </OffersCardBack>
      )}
      {isConvergent && hasFixedPortOut && !hasMobilePortOut && (
        <OffersCardBack>
          <div>
            <OfferItemSmall label="Tarifa" value={subscription.description} />
            <PortOutInfo portOut={fixedPortOut} type="fixed" />
          </div>
          <div>
            <TariffItem subscription={subscription} onModalOpen={onModalOpen} />
            <DiscountsBonusesItem subscription={subscription} onModalOpen={onModalOpen} />
            <PermanentItem isFixed={false} subscription={subscription} onModalOpen={onModalOpen} />
            <DeviceItem isFixed={false} subscription={subscription} />
          </div>
          <OfferRotateAction text="Ver info Tarifa" onRotate={onRotate} />
        </OffersCardBack>
      )}
      {isConvergent && !hasFixedPortOut && hasMobilePortOut && (
        <OffersCardBack>
          <div>
            <TariffItem subscription={subscription} onModalOpen={onModalOpen} />
            <DiscountsBonusesItem subscription={subscription} onModalOpen={onModalOpen} />
            <PermanentItem isFixed subscription={subscription} onModalOpen={onModalOpen} />
            <DeviceItem isFixed subscription={subscription} />
          </div>
          <div>
            <OfferItemSmall label="Tarifa" value={subscription.description} />
            <PortOutInfo portOut={mobilePortOut} type="mobile" />
          </div>
          <OfferRotateAction text="Ver info Tarifa" onRotate={onRotate} />
        </OffersCardBack>
      )}
    </>
  )
}

PortOut.propTypes = {
  subscription: PropTypes.object.isRequired,
  onRotate: PropTypes.func.isRequired,
  mobilePortOut: PropTypes.object,
  fixedPortOut: PropTypes.object,

  onModalOpen: PropTypes.func.isRequired,
}
