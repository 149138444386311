import { useEffect } from 'react'
import PropTypes from 'prop-types'

import { selectCanGoToStore } from 'services/feature-flag/selectors'
import { useSelector } from 'react-redux'

import { Typography } from '@material-ui/core'
import { ButtonsSelector } from 'components'
import {
  YOIGO_STORE,
  DELIVERY_POINT,
  INSTALLATION_ADDRESS,
  BILLING_ADDRESS,
  OTHER_ADDRESS,
  SERVICE_ADDRESS,
} from 'modules/SharedSales/constants/index'
import { AddressStore, OtherAddress, PresetAddress } from './components'

export function AddressChooser({
  title = '',
  innerTitle = '',
  type, // shipping, billing, terminalShipping
  availableAddressTypes, // otherAddress, installationAddress, yoigoStore, deliveryPoint + labels
  selectedAddressType, // one of availableAddressTypes
  onSelectAddress,
  preselectedAddress, // preselected address for otherAddress
  hideButtons,
  onSetAddress,
  onEditAddress,
  disabled,
  children,
  allowInputManualAddress,
  isRequiredFields,
  disableLinkModify,
  showOtherAddress = true
}) {
  const canGoStore = useSelector(selectCanGoToStore)

  useEffect(() => {
    onSelectAddress(selectedAddressType, type)
  }, [])

  const mapedAvailableAddressTypes = availableAddressTypes.map(elem => ({
    disabled: disabled || (elem.value === YOIGO_STORE.value && !canGoStore),
    ...elem,
  }))
  return (
    <div>
      {title && (
        <Typography variant="subtitle1" color="secondary" className="bold m-b-20">
          {title}
        </Typography>
      )}
      {availableAddressTypes.length && (
        <div>
          {!hideButtons && (
            <div>
              <ButtonsSelector
                dataHook={`${type}.buttons`}
                items={mapedAvailableAddressTypes}
                onSelect={address => onSelectAddress(address, type)}
                selectedItems={[selectedAddressType]}
              />
            </div>
          )}
          {children}
          <div className="m-t-16">
            <AddressComponent
              disabled={!!disabled}
              type={type}
              selected={selectedAddressType}
              title={innerTitle}
              onSetAddress={onSetAddress}
              preselectedAddress={preselectedAddress}
              onEditAddress={onEditAddress}
              allowInputManualAddress={allowInputManualAddress}
              isRequiredFields={isRequiredFields}
              disableLinkModify={disableLinkModify}
              showOtherAddress={showOtherAddress}
            />
          </div>
        </div>
      )}
    </div>
  )
}

function AddressComponent({
  preselectedAddress,
  selected,
  title,
  type,
  onSetAddress,
  onEditAddress,
  disabled,
  allowInputManualAddress,
  isRequiredFields,
  disableLinkModify,
  showOtherAddress = true,
}) {
  switch (selected) {
    case YOIGO_STORE.value:
    case DELIVERY_POINT.value:
      return <AddressStore addressType={type} />
    case INSTALLATION_ADDRESS.value:
    case SERVICE_ADDRESS.value:
    case BILLING_ADDRESS.value:
      return (
        <PresetAddress
          title={title}
          address={preselectedAddress.formattedAddress || preselectedAddress.address}
        />
      )
    case OTHER_ADDRESS.value:
      return showOtherAddress ? (
        <OtherAddress
          addressType={type}
          onSetAddress={onSetAddress}
          onEditAddress={onEditAddress}
          disabled={disabled}
          allowInputManualAddress={allowInputManualAddress}
          isRequiredFields={isRequiredFields}
          disableLinkModify={disableLinkModify}
        />
      ) : null
    default:
      return null
  }
}

AddressComponent.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  selected: PropTypes.string.isRequired,
  onSetAddress: PropTypes.func,
  preselectedAddress: PropTypes.any,
  showOtherAddress: PropTypes.bool,
  onEditAddress: PropTypes.func,
  disabled: PropTypes.bool,
  allowInputManualAddress: PropTypes.bool,
  isRequiredFields: PropTypes.bool,
  disableLinkModify: PropTypes.bool,
}

AddressChooser.propTypes = {
  availableAddressTypes: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string.isRequired, value: PropTypes.string.isRequired }),
  ),
  hideButtons: PropTypes.bool,
  innerTitle: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string.isRequired,
  onSetAddress: PropTypes.func,
  onSelectAddress: PropTypes.func.isRequired,
  selectedAddressType: PropTypes.string.isRequired,
  preselectedAddress: PropTypes.any,
  onEditAddress: PropTypes.func,
  disabled: PropTypes.bool,
  children: PropTypes.node,
  allowInputManualAddress: PropTypes.bool,
  isRequiredFields: PropTypes.bool,
  disableLinkModify: PropTypes.bool,
  showOtherAddress: PropTypes.bool,
}
