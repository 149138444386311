import { createSelector } from 'reselect'
import {
  get,
  maxBy,
  some,
  isEmpty,
  curryRight,
  isNil,
  head,
  find,
  last,
  pickBy,
  uniq,
  chain,
} from 'lodash'
import { isMobilePhoneNumber } from 'utils'
import { selectOrdersByProductId } from 'services/product-orders/selectors'
import {
  selectChangeTechnologyV3,
  selectChangeAddressV3,
  selectD2RNewDiscounts,
  selectStcV3Flag,
  selectEnabledNewClientSale,
} from 'services/feature-flag/selectors'
import { selectSfid } from 'modules/Auth/store/selectors'
import { getDiscounts, constants as discountConstants } from 'services/discounts-agent-categories'
import { selectBonusesData } from 'modules/bonuses'
import {
  selectContracts,
  getSegment,
  selectors as customer360SvcSelectors,
} from 'services/customer-360/selectors'
import { applyTax, getTax } from 'services/taxes'
import { getOrder, getCurrentTerritoryOwner } from 'modules/Coverage/store'
import { getMobilePart } from 'services/subscriptions/helpers'
import {
  isConvergentSubscription,
  getSubscriptions,
  selectSubscriptionByMsisdn,
  getNewTariffId,
  findMobilePermanent,
  getPaymentType as getSubscriptionPaymentType,
  getMobileLineNumber,
  getSubscriptionTariffId,
  getMsisdn,
  getMainSubscription,
  getPermanentTerminals,
  getAttributesPartialFees,
  isMobileOnlySubscription,
  formatDiscountAmountProrrated,
} from 'services/subscriptions'
import { selectContentfulResource } from 'modules/contentful'
import { selectTariffsApigeeById } from 'modules/tariffs/store-apigee/tariffs-apigee.selectors'
import {
  getTariffSegment,
  isRetiredTariff,
  isPostpaidTariff,
} from 'modules/tariffs/store-apigee/tariffs-apigee.helpers'
import {
  isDuoTariffId,
  isSinfinTariff,
  isSinfinMobileTariff,
  isNewSinfinMobileTariffRetired,
  isNewSinfinMobileTariff,
  isSinfinMobileTariffPro,
  isAdslTariff,
  isFtthTariff,
  isNebaTariff,
  isVulaTariff,
  isTariffWithPortfolioStcPermission,
  isCurrentTariffNebaFiber,
  isCurrentTariffVulaFiber,
  is2pTariff,
  is2pFreeLineTariff,
  isConvergentTariff,
  isMobileTariff,
  isPrepaidTariff,
  getPaymentType as getTariffPaymentType,
  selectTariffsApigeeDataTariffs,
  getConvergentRelatedMobileTariff,
  isTariffWithStatus,
  filterTariffsWithStatus,
  isElFijoTariff,
} from 'modules/tariffs/store-apigee'
import {
  isAdditionalLine,
  getCurrentDiscounts,
  isPrepaidPaymentType,
  isAdditionalDiscount,
  isSinfin30GbTariff,
  isNetflixTariff,
  isFibra300MbTariff,
  isFibra1GbTryAndBuyResidentialTariff,
  isFibra1Gb2pResidentialDiscountTariff,
  isFibra1Gb3pResidentialDiscountTariff,
  isFibra1GbRegEx,
  isFibra1GbTryAndBuyProTariff,
  isDuoTariff,
  allowTariffIndefiniteDiscountConvergent,
  getOtherFeesCvm,
  applyDiscount,
  isSinfinInfinite,
  isPruebaloProTariff,
  isPruebaloResidentialTariff,
  isPruebaloDigitalTariff,
} from 'modules/offers-configuration/helpers'
import {
  STATUS_ACTIVE,
  STATUS_RETIRED,
  STATUS_HIDDEN,
  CATEGORY_2P,
  CATEGORY_2P_V3,
} from 'modules/tariffs/tariffs.constants'
import { DUO_PRINCIPAL_TARIFF_ID } from 'services/subscriptions/constants'
import { getDiscountByTariff } from 'modules/NewClientSales/store/selectors/discounts.selectors.helpers'
import { MULTISIM_LINE } from 'services/customer-360/constants'
import {
  SVA_WIFI_TOTAL_01,
  SVA_WIFI_TOTAL_02,
  CVM_DISCOUNTS_CONVERGENT,
  CVM_DISCOUNT_MOBILE,
  CVM_BONUSES,
  OBSOLETE_TARIFFS,
  AVAILABLE_RETIRED_TARIFFS,
  AVAILABLE_TRY_AND_BUY_LOYALTY_TARIFFS,
  AVAILABLE_TRY_AND_BUY_RETIRED_TARIFFS,
  AVAILABLE_LOYALTY_RETENTION_RETIRED_TARIFFS,
  AVAILABLE_PRO_RETIRED_TARIFFS,
  AVAILABLE_LOYALTY_RETIRED_TARIFFS,
  CVM_FIXED_PENALTY_KEY,
  CVM_MOBILE_PENALTY_KEY,
  HEAD_BOILERPLATE_OPERATION,
  FOOTER_BOILERPLATE_OPERATION,
  COMPATIBLE_CURRENT_DISCOUNTS,
  CVM_TRY_AND_BUY_DISCOUNT,
  TRY_AND_BUY_MOBILE_DISCOUNT_PRO,
  CVM_INDEFINITE_DISCOUNT_MOBILE,
  CVM_INDEFINITE_DISCOUNT_CONVERGENT,
  CVM_NEW_PORTFOLIO_300MB_2P_DISCOUNT,
  CVM_FIBRA_300MB_2P_DISCOUNT,
  ADDITIONAL_LINE_DISCOUNTS,
  MOBILE_INFINITE_GIGAS_DISCOUNTS,
  CVM_TARIFFS_COMPATIBLE_WITH_HEBE_DISCOUNT,
  CVM_DISCOUNT_RETENTION_MOBILE,
  CVM_DISCOUNT_RETENTION_MOBILE_VALUES,
  TARIFF_DONT_LOSE_ADDITIONAL_DUO_DISCOUNT,
  SUBSCRIPTION_ADDITIONAL_LINE,
  MAIN_DUO_DISCOUNT_ID,
  SVA_FHD_300_CODE_RESIDENTIAL,
  SVA_FHD_300_CODE_PRO,
  MULTISIM_DISCOUNT,
  WARN_DISCOUNT_LOST,
  CVM_DISCOUNT_RETENTION,
  CVM_DISCOUNT_RETENTION_VALUES,
  CVM_DISCOUNT_RETENTION_CONVERGENT,
  CVM_DISCOUNT_RETENTION_CONVERGENT_VALUES,
  UNLIMITED_DURATION,
  MAIN_DUO_DISCOUNT_NEW_AMOUNT,
  MAIN_DUO_DISCOUNT_OLD_AMOUNT,
  CHANGE_TECHNOLOGY_SFID_ACCESS,
  CHANGE_ADDRESS_SFID_ACCESS,
  WIFI_TOTAL_01_DISCOUNTS,
  WIFI_TOTAL_02_DISCOUNTS,
  EXTRA_DUO_DISCOUNT_ID,
  MOBILE_MAIN_TARIFFS_DISCOUNTS,
  DISCOUNT_DISTRIBUIDOR_RES_DISC,
  CVM_DISCOUNT_SINFIN_INFINITE_GB_PRO,
  CVM_RETENTION_1GB_3P_DISCOUNTS,
  AMAZON_DISCOUNT,
  CONVERGENT_5_EUROS_DISCOUNT,
  NETFLIX_DISCOUNT,
  SOFTBUNDLE_NETFLIX_DISCOUNT,
  TARIFF_TYPE_MAIN_LINE,
  PRUEBALO_DIGITAL_12_DISCOUNTS,
  PRUEBALO_12_PRO_DISCOUNTS,
  PRUEBALO_12_RESIDENTIAL_DISCOUNTS,
  PRUEBALO_DIGITAL_18_DISCOUNTS,
  PRUEBALO_18_PRO_DISCOUNTS,
  PRUEBALO_18_RESIDENTIAL_DISCOUNTS,
  PRO_CONVERGENT_DISCOUNT_50_PERCENT
} from 'modules/offers-configuration/constants'
import { RESIDENTIAL, PRO } from 'modules/SharedSales/constants/productCategories'
import {
  applyDiscounts,
  byAscendingDuration,
  generateEmptyDiscounts,
  getTariffFee,
  normalizeCurrentDiscount,
  getTariffMultisimWithTax,
} from './hooks/summary.helpers'

export const getError = state => get(state, 'cvm.error')

export const isLoading = state => get(state, 'cvm.loading')

export const isLoadingTarrifsV3 = state => get(state, 'cvm.tariffsV3.loading')

export const isLoadingOrderElegibility = state => get(state, 'cvm.orderElegibility.loading')

export const isLoadingOrderConsequences = state => get(state, 'cvm.orderConsequences.loading')

export const getSubscriptionsState = state => get(state, 'cvm.subscriptions', {})

export const getSubscriptionState = (state, msisdn) => get(state, `cvm.subscriptions.${msisdn}`, {})

export const isTypificationOpen = state => get(state, 'cvm.typificationOpen')

export const getOrderElegibility = state => get(state, 'cvm.orderElegibility')

export const getOrderConsequences = state => get(state, 'cvm.orderConsequences')

export const getParentTechnology = state => get(state, 'cvm.parentTechnology')

export const getCoverageTechnology = state => get(state, 'cvm.coverageTechnology')

export const getCommercialMigration = state => get(state, 'cvm.commercialMigration')

export const getChangeAddress = state => get(state, 'cvm.changeAddress')

export const getChangeTechnology = state => get(state, 'cvm.changeTechnology')

export const getInstaller = state => get(state, 'cvm.installer')

export const getPendingTariffChange = (state, msisdn) => {
  const subscriptionChanges = getSubscriptionState(state, msisdn)
  return get(subscriptionChanges, 'confirmedTariff')
}

export const getPendingUnsubscription = (state, msisdn) => {
  const subscriptionChanges = getSubscriptionState(state, msisdn)
  return get(subscriptionChanges, 'unsubscribed', false)
}

export const getPendingFixedUnsubscription = (state, subscriptionId) => {
  const subscriptionChanges = getSubscriptionState(state, subscriptionId)
  return get(subscriptionChanges, 'fixedUnsubscribed', false)
}

export const getPendingDiscounts = (state, msisdn) => {
  const subscriptionChanges = getSubscriptionState(state, msisdn)
  return get(subscriptionChanges, 'confirmedDiscounts', [])
}

export const getPendingBonuses = curryRight((state, msisdn) => {
  const subscriptionChanges = getSubscriptionState(state, msisdn)
  return get(subscriptionChanges, 'confirmedBonuses', [])
}, 2)

export const getPermanenceOrPenaltyChanges = (state, msisdn, isFixed) => {
  const subscriptionChanges = getSubscriptionState(state, msisdn)
  return get(subscriptionChanges, `${isFixed ? CVM_FIXED_PENALTY_KEY : CVM_MOBILE_PENALTY_KEY}`, {})
}

export const getIsDuoPrincipalNewTariff = createSelector([getSubscriptionState], tariff =>
  get(tariff, 'isDuoPrincipalNewTariff', false),
)

export const getShowNewTariffDuoPrincipal = createSelector([getSubscriptionState], tariff =>
  get(tariff, 'showNewTariffDuoPrincipal', false),
)

export const getSubscriptionWithNewTariff = createSelector([getSubscriptionsState], tariffs =>
  pickBy(tariffs, tariff => !get(tariff, 'isDuoPrincipalNewTariff', false)),
)

export const isPermanentOrPenaltyRemovePending = (state, msisdn, isFixed) => {
  const permanentChanges = getPermanenceOrPenaltyChanges(state, msisdn, isFixed)
  return Boolean(
    get(permanentChanges, 'penaltyCondoned') || get(permanentChanges, 'permanenceRemoved'),
  )
}

export function getHasModifications(state, msisdn) {
  return some(
    [
      getPendingTariffChange(state, msisdn),
      !isEmpty(getPendingDiscounts(state, msisdn)),
      !isEmpty(getPendingBonuses(msisdn)(state)),
      isPermanentOrPenaltyRemovePending(state, msisdn, false),
      isPermanentOrPenaltyRemovePending(state, msisdn, true),
    ],
    Boolean,
  )
}

export const getNewTariff = curryRight((state, subscription) => {
  const newTariffId = getNewTariffId(subscription)
  return selectTariffsApigeeById(newTariffId)(state)
}, 2)

export const getChangedTariff = curryRight((state, subscription) => {
  const newTariff = getNewTariff(subscription)(state)
  const confirmedTariff = getPendingTariffChange(state, getMsisdn(subscription))
  return newTariff || confirmedTariff
}, 2)

export const getIsFixedBeingUnsubscribed = curryRight((state, subscription) => {
  const changedTariff = getChangedTariff(subscription)(state)

  return isConvergentSubscription(subscription) && changedTariff && isMobileTariff(changedTariff)
}, 2)

export const canModifySubscription = (state, subscription) =>
  Boolean(
    !getPendingUnsubscription(state, getMsisdn(subscription)) &&
      !getChangedTariff(subscription)(state),
  )

export const getSubscriptionMostRecentOrder = (state, msisdn) => {
  const orders = selectOrdersByProductId(msisdn)(state)
  return maxBy(orders, 'orderDate') || {}
}

export const is2pSubscription = (state, sub) => {
  const tariffId = getSubscriptionTariffId(sub)
  const tariff = selectTariffsApigeeById(tariffId)(state)
  return is2pTariff(tariff)
}

export const is2pFreeLineSubscription = (state, sub) => {
  const tariffId = getSubscriptionTariffId(sub)
  const tariff = selectTariffsApigeeById(tariffId)(state)
  return is2pFreeLineTariff(tariff)
}

export const is2pSubscriptionWithFakeMsisdn = (state, sub) =>
  is2pFreeLineSubscription(state, sub) && isMobilePhoneNumber(getMobileLineNumber(sub))

export const is2pSubscriptionWithoutMsisdn = (state, sub) =>
  is2pSubscription(state, sub) && !isMobilePhoneNumber(getMobileLineNumber(sub))

const getExistVasContract = (contractTypeId, subscription) =>
  !isEmpty(
    chain(subscription)
      .get('vasContracts')
      .filter(vasContract => vasContract.contractTypeId === contractTypeId)
      .value(),
  )

const filterDiscounts = (
  discounts,
  codes,
  tariffSegment,
  infiniteTariff,
  sinfin30GbTariff,
  sinfinMobileTariff,
  canIAddIndefiniteDiscountConvergent,
  tariffId,
  canISee2pDiscount,
  is2p,
  is2p300MbTariff,
  isNewD2R,
  is3pTariff,
  is3p1GbTariffResidential,
  is3p1GbTariffPro,
  multisimTariff,
  isWifiTotal1ud,
  isWifiTotal2ud,
  isMainDuoTariff,
  isMainLine,
  isSinfinfMobileTariffPro,
  is3p1GbTariffResidentialDiscount,
  is2p1GbResidentialDiscountTariff,
  is1GbTariff,
  is3pSinfinInfinite,
  netflixTariff,
  pruebaloDigitalTariff,
  pruebaloProTariff,
  pruebaloResidentialTariff
) => 
  discounts.filter(
    d =>  (!multisimTariff && codes.includes(d.discountCode)) ||
      (pruebaloDigitalTariff && 
        (PRUEBALO_DIGITAL_12_DISCOUNTS.includes(d.discountCode) || 
        PRUEBALO_DIGITAL_18_DISCOUNTS.includes(d.discountCode))) ||
      (pruebaloProTariff && 
        (PRUEBALO_12_PRO_DISCOUNTS.includes(d.discountCode) || 
        PRUEBALO_18_PRO_DISCOUNTS.includes(d.discountCode))) ||
      (pruebaloResidentialTariff && 
        (PRUEBALO_12_RESIDENTIAL_DISCOUNTS.includes(d.discountCode) || 
        PRUEBALO_18_RESIDENTIAL_DISCOUNTS.includes(d.discountCode))) ||
      (is3pSinfinInfinite && CONVERGENT_5_EUROS_DISCOUNT === d.discountCode) ||
      (netflixTariff && NETFLIX_DISCOUNT === d.discountCode) || 
      (isSinfinfMobileTariffPro && CVM_DISCOUNT_SINFIN_INFINITE_GB_PRO === d.discountCode) ||
      (isWifiTotal1ud && WIFI_TOTAL_01_DISCOUNTS.includes(d.discountCode)) ||
      (isWifiTotal2ud && WIFI_TOTAL_02_DISCOUNTS.includes(d.discountCode)) ||
      (isMainDuoTariff && EXTRA_DUO_DISCOUNT_ID === d.discountCode) ||
      (isMainLine && MOBILE_MAIN_TARIFFS_DISCOUNTS.includes(d.discountCode)) || 
      (sinfinMobileTariff && MOBILE_INFINITE_GIGAS_DISCOUNTS.includes(d.discountCode)) ||
      (sinfinMobileTariff && d.discountCode === TRY_AND_BUY_MOBILE_DISCOUNT_PRO) ||
      (infiniteTariff && d.discountCode === CVM_TRY_AND_BUY_DISCOUNT) ||
      (sinfin30GbTariff && CVM_INDEFINITE_DISCOUNT_MOBILE === d.discountCode) ||
      (sinfin30GbTariff && CVM_INDEFINITE_DISCOUNT_CONVERGENT === d.discountCode) ||
      (canIAddIndefiniteDiscountConvergent &&
        CVM_INDEFINITE_DISCOUNT_CONVERGENT === d.discountCode) ||
      (canISee2pDiscount && getDiscountByTariff(tariffId, isNewD2R)?.code === d.discountCode) ||
      (is2p300MbTariff && CVM_FIBRA_300MB_2P_DISCOUNT === d.discountCode) ||
      (is3pTariff && (
        DISCOUNT_DISTRIBUIDOR_RES_DISC === d.discountCode || 
        AMAZON_DISCOUNT === d.discountCode || 
        (!netflixTariff && SOFTBUNDLE_NETFLIX_DISCOUNT === d.discountCode))) ||
      (is1GbTariff && CVM_RETENTION_1GB_3P_DISCOUNTS.includes(d.discountCode)) ||
      (is3p1GbTariffResidential && SVA_FHD_300_CODE_RESIDENTIAL === d.discountCode) ||
      (is3p1GbTariffPro && SVA_FHD_300_CODE_PRO === d.discountCode) ||
      (multisimTariff && MULTISIM_DISCOUNT === d.discountCode) ||
      (is3p1GbTariffResidentialDiscount &&
        CVM_DISCOUNT_RETENTION === d.discountCode) ||
      (is2p1GbResidentialDiscountTariff &&
        CVM_DISCOUNT_RETENTION === d.discountCode &&
        !isEmpty(
          d.amounts.find(
            ({ value }) =>
              value === CVM_DISCOUNT_RETENTION_VALUES.TARIFF_1GB_10_EUROS ||
              value === CVM_DISCOUNT_RETENTION_VALUES.TARIFF_1GB_5_EUROS,
          ),
        )) ||
      (is2p &&
        ((CVM_DISCOUNT_RETENTION === d.discountCode &&
          !isEmpty(
            d.amounts.find(({ value }) => value === CVM_DISCOUNT_RETENTION_VALUES.SUPER_DUO),
          )) ||
          CVM_NEW_PORTFOLIO_300MB_2P_DISCOUNT === d.discountCode)),
  )

const getAdditionalLineDiscount = (state, tariffId = 'notFound') => {
  const isMainLineConvergent = isConvergentSubscription(getMainSubscription(state))
  const discountIdSubstr = `${tariffId}${isMainLineConvergent ? 'H' : 'HM'}`
  const isValid50PercentDiscount = discount => discount.discountCode.endsWith(discountIdSubstr)
  return ADDITIONAL_LINE_DISCOUNTS.filter(isValid50PercentDiscount)
}

const checkAvailable2pDiscounts = (tariff, existsConvergentTariff) => {
  const is2pCategory = is2pTariff(tariff)
  const is2pFreeLineCategory = is2pFreeLineTariff(tariff)
  const is2p = is2pCategory || is2pFreeLineCategory
  const canISee2pDiscount = existsConvergentTariff && is2p

  const is2p300MbTariff = canISee2pDiscount && isFibra300MbTariff(tariff)
  const is2p1GbResidentialDiscountTariff =
    is2pCategory && isFibra1Gb2pResidentialDiscountTariff(tariff)

  return {
    canISee2pDiscount,
    is2p,
    is2p300MbTariff,
    is2p1GbResidentialDiscountTariff,
  }
}

const checkAvailable3pDiscounts = tariff => {
  const is3pTariff = isConvergentTariff(tariff)

  const is3p1GbTariffResidential = is3pTariff && isFibra1GbTryAndBuyResidentialTariff(tariff)
  const is3p1GbTariffPro = is3pTariff && isFibra1GbTryAndBuyProTariff(tariff)
  const is3p1GbTariffResidentialDiscount =
    is3pTariff && isFibra1Gb3pResidentialDiscountTariff(tariff)

  return {
    is3pTariff,
    is3p1GbTariffResidential,
    is3p1GbTariffPro,
    is3p1GbTariffResidentialDiscount,
  }
}

const checkAvailableDiscounts = (tariff, existsConvergentTariff, subscription) => {
  const tariffSegment = getTariffSegment(tariff)
  const sinfin30GbTariff = isSinfin30GbTariff(tariff)
  const sinfinMobileTariff = isSinfinMobileTariff(tariff)
  const newSinfinMobileTariffRetired = isNewSinfinMobileTariffRetired(tariff)
  const newSinfinMobileTariff = isNewSinfinMobileTariff(tariff)
  const canIAddIndefiniteDiscountConvergent = allowTariffIndefiniteDiscountConvergent(tariff)
  const { canISee2pDiscount, is2p, is2p300MbTariff, is2p1GbResidentialDiscountTariff } =
    checkAvailable2pDiscounts(tariff, existsConvergentTariff)
  const isSinfinfMobileTariffPro = isSinfinMobileTariffPro(tariff)
  const {
    is3pTariff,
    is3p1GbTariffResidential,
    is3p1GbTariffPro,
    is3p1GbTariffResidentialDiscount,
  } = checkAvailable3pDiscounts(tariff)
  const is1GbTariff = isFibra1GbRegEx(tariff)
  const is3pSinfinInfinite = isSinfinInfinite(tariff)
  const isMainDuoTariff = isDuoTariff(tariff)
  const isMainLine = subscription.multisimType === TARIFF_TYPE_MAIN_LINE
  const isWifiTotal1ud = getExistVasContract(SVA_WIFI_TOTAL_01, subscription)
  const isWifiTotal2ud = getExistVasContract(SVA_WIFI_TOTAL_02, subscription)
  const netflixTariff = isNetflixTariff(tariff)
  const pruebaloDigitalTariff = isPruebaloDigitalTariff(tariff)
  const pruebaloProTariff = isPruebaloProTariff(tariff)
  const pruebaloResidentialTariff = isPruebaloResidentialTariff(tariff)

  return {
    sinfin30GbTariff,
    sinfinMobileTariff,
    newSinfinMobileTariff,
    newSinfinMobileTariffRetired,
    canIAddIndefiniteDiscountConvergent,
    canISee2pDiscount,
    is2p,
    is3pTariff,
    is3p1GbTariffResidential,
    is3p1GbTariffPro,
    isSinfinfMobileTariffPro,
    isWifiTotal1ud,
    isWifiTotal2ud,
    isMainDuoTariff,
    isMainLine,
    is3p1GbTariffResidentialDiscount,
    is2p300MbTariff,
    is2p1GbResidentialDiscountTariff,
    is1GbTariff,
    is3pSinfinInfinite,
    netflixTariff,
    pruebaloDigitalTariff,
    pruebaloProTariff,
    pruebaloResidentialTariff,
    tariffSegment
  }
}

// TODO: Permitir que disableDiscount sea multivalor
const getRetentionDiscounts = (discounts, disableDiscount) => {
  const filteredDiscounts = discounts.map(discount => {
    if (discount.discountCode === CVM_DISCOUNT_RETENTION_MOBILE) {
      return {
        ...discount,
        amounts: discount.amounts.filter(amount => amount.value !== disableDiscount),
      }
    }
    return discount
  })

  return filteredDiscounts
}

export const getDiscountOptions = (
  state,
  subscription,
  forceMobile,
  forceTariffId,
  currentTariff,
  infiniteTariff,
  existsConvergentTariff,
  is1wSales
) => {
  const hasPermanentTerminal = !isEmpty(getPermanentTerminals(subscription))
  const multisimTariff = get(subscription, 'multisimType') === MULTISIM_LINE
  const tariff = multisimTariff
    ? { id: get(subscription, 'id'), category: get(subscription, 'category') }
    : selectTariffsApigeeById(forceTariffId || getSubscriptionTariffId(subscription))(state)

  const isMobile = isMobileTariff(tariff) || isElFijoTariff(tariff) || forceMobile
  let codes = isMobile ? CVM_DISCOUNT_MOBILE : CVM_DISCOUNTS_CONVERGENT

  const lineType = isMobile
    ? discountConstants.MOBILE_CATEGORY
    : discountConstants.CONVERGENT_CATEGORY
  let discounts = getDiscounts(state, lineType)

  const isNewD2RFlag = selectD2RNewDiscounts(state)

  const {
    sinfin30GbTariff,
    sinfinMobileTariff,
    canIAddIndefiniteDiscountConvergent,
    newSinfinMobileTariff,
    newSinfinMobileTariffRetired,
    isSinfinfMobileTariffPro,
    canISee2pDiscount,
    is2p,
    is3pTariff,
    is3p1GbTariffResidential,
    is3p1GbTariffPro,
    is2p300MbTariff,
    isWifiTotal1ud,
    isWifiTotal2ud,
    isMainDuoTariff,
    isMainLine,
    is3p1GbTariffResidentialDiscount,
    is2p1GbResidentialDiscountTariff,
    is1GbTariff,
    is3pSinfinInfinite,
    netflixTariff,
    pruebaloDigitalTariff,
    pruebaloProTariff,
    pruebaloResidentialTariff,
    tariffSegment
  } = checkAvailableDiscounts(tariff, existsConvergentTariff, subscription)

  if(is1wSales && (tariffSegment === RESIDENTIAL || is2p)) {
    codes = codes.filter(code => code !== PRO_CONVERGENT_DISCOUNT_50_PERCENT)
  }

  // refactorizar en una funcion junto con is3p1GbTariffResidentialDiscount
  if(is1wSales && tariffSegment === PRO) {
    discounts = discounts.map(discount => {
      if (discount.discountCode === CVM_DISCOUNT_RETENTION_CONVERGENT) {
        return {
          ...discount,
          amounts: discount.amounts.filter(
            ({ value }) =>
              value !== CVM_DISCOUNT_RETENTION_CONVERGENT_VALUES.CONVERGENT_30 &&
              value !== CVM_DISCOUNT_RETENTION_CONVERGENT_VALUES.CONVERGENT_40,
          ),
        }
      }
      return discount
    })
  }

  if (sinfinMobileTariff || (!newSinfinMobileTariffRetired && !newSinfinMobileTariff)) {
    discounts = getRetentionDiscounts(
      discounts,
      CVM_DISCOUNT_RETENTION_MOBILE_VALUES.SINFIN_MOBILE_RETIRED,
    )
    discounts = getRetentionDiscounts(discounts, CVM_DISCOUNT_RETENTION_MOBILE_VALUES.SINFIN_MOBILE)
    discounts = getRetentionDiscounts(
      discounts,
      CVM_DISCOUNT_RETENTION_MOBILE_VALUES.SINFIN_INFINITE_GB_2_EUROS,
    )
  } else if (newSinfinMobileTariffRetired) {
    discounts = getRetentionDiscounts(discounts, CVM_DISCOUNT_RETENTION_MOBILE_VALUES.SINFIN_MOBILE)
    discounts = getRetentionDiscounts(
      discounts,
      CVM_DISCOUNT_RETENTION_MOBILE_VALUES.SINFIN_INFINITE_GB_2_EUROS,
    )
  } else if (newSinfinMobileTariff) {
    discounts = getRetentionDiscounts(
      discounts,
      CVM_DISCOUNT_RETENTION_MOBILE_VALUES.SINFIN_MOBILE_RETIRED,
    )
  }

  if (is3p1GbTariffResidentialDiscount) {
    discounts = discounts.map(discount => {
      if (discount.discountCode === CVM_DISCOUNT_RETENTION) {
        return {
          ...discount,
          amounts: discount.amounts.filter(
            ({ value }) =>
              value === CVM_DISCOUNT_RETENTION_VALUES.TARIFF_1GB_5_EUROS ||
              value === CVM_DISCOUNT_RETENTION_VALUES.TARIFF_1GB_10_EUROS,
          ),
        }
      }
      return discount
    })
  }

  if (is2p && !is2p1GbResidentialDiscountTariff) {
    discounts = discounts.map(discount => {
      if (discount.discountCode === CVM_DISCOUNT_RETENTION) {
        return {
          ...discount,
          amounts: discount.amounts.filter(
            ({ value }) =>
              value !== CVM_DISCOUNT_RETENTION_VALUES.TARIFF_1GB_5_EUROS &&
              value !== CVM_DISCOUNT_RETENTION_VALUES.TARIFF_1GB_10_EUROS,
          ),
        }
      }
      return discount
    })
  }

  if (hasPermanentTerminal) {
    discounts = getRetentionDiscounts(
      discounts,
      CVM_DISCOUNT_RETENTION_MOBILE_VALUES.ADDITIONAL_LINE_INFINITE_GB,
    )
  }

  const filteredDiscounts = [
    ...filterDiscounts(
      discounts,
      codes,
      tariffSegment,
      infiniteTariff,
      sinfin30GbTariff,
      sinfinMobileTariff,
      canIAddIndefiniteDiscountConvergent,
      tariff.id,
      canISee2pDiscount,
      is2p,
      is2p300MbTariff,
      isNewD2RFlag,
      is3pTariff,
      is3p1GbTariffResidential,
      is3p1GbTariffPro,
      multisimTariff,
      isWifiTotal1ud,
      isWifiTotal2ud,
      isMainDuoTariff,
      isMainLine,
      isSinfinfMobileTariffPro,
      is3p1GbTariffResidentialDiscount,
      is2p1GbResidentialDiscountTariff,
      is1GbTariff,
      is3pSinfinInfinite,
      netflixTariff,
      pruebaloDigitalTariff,
      pruebaloProTariff,
      pruebaloResidentialTariff
    ),
  ]

  if (hasPermanentTerminal) {
    return filteredDiscounts
  }
  return [...filteredDiscounts, ...getAdditionalLineDiscount(state, tariff && tariff.id)]
}

export const getBonusOptions = state =>
  selectBonusesData(state).filter(bonus => CVM_BONUSES.includes(bonus.id))

const getTariffId = (isStcV3Flag, tariff) => (isStcV3Flag ? tariff.psId : tariff.id)

const getTariffSubscriptionLineType = tariff => get(tariff, 'subscription_line_type', null)

const filterObsoleteTariffs = (isStcV3Flag, tariff) =>
  !OBSOLETE_TARIFFS.includes(getTariffId(isStcV3Flag, tariff))

const regExpTariffs =
  /^CONTDSLA003|^CONTDSLA010|^CONTDSLA030|^CONTFHE003|^CONTFHE030|^CONTFHE030|^CONTFHC010|^CONTFHC010|^CONTFHC030|^CONTFHF003|^CONTFHF010|^CONTFHF030|^CONTFHD003|^CONTFHHD010|^CONTFHD030/

const filterRetiredTariffs = (isStcV3Flag, tariff) => {
  if (isRetiredTariff(tariff)) {
    return !regExpTariffs.test(getTariffId(isStcV3Flag, tariff))
  }
  return true
}

const filterMobileOnlyTarrifs = tariff =>
  getTariffSubscriptionLineType(tariff) !== SUBSCRIPTION_ADDITIONAL_LINE

const isAvailableRetiredTariff = (isStcV3Flag, tariff) =>
  isStcV3Flag || AVAILABLE_RETIRED_TARIFFS.includes(getTariffId(isStcV3Flag, tariff))

const isAvailableTryAndBuyRetiredTariff = (isStcV3Flag, tariff) =>
  AVAILABLE_TRY_AND_BUY_RETIRED_TARIFFS.includes(getTariffId(isStcV3Flag, tariff))

const isAvailableRetiredLoyaltyTariff = (isStcV3Flag, tariff) =>
  isStcV3Flag || AVAILABLE_LOYALTY_RETIRED_TARIFFS.includes(getTariffId(isStcV3Flag, tariff))

const isAvailableRetiredLoyaltyRetentionTariff = (isStcV3Flag, tariff) =>
  isStcV3Flag ||
  AVAILABLE_LOYALTY_RETENTION_RETIRED_TARIFFS.includes(getTariffId(isStcV3Flag, tariff))

const isAvailableRetiredProTariff = (isStcV3Flag, tariff) =>
  isStcV3Flag || AVAILABLE_PRO_RETIRED_TARIFFS.includes(getTariffId(isStcV3Flag, tariff))

const isAvailableTryAndBuyLoyaltyTariffs = (isStcV3Flag, tariff) =>
  AVAILABLE_TRY_AND_BUY_LOYALTY_TARIFFS.includes(getTariffId(isStcV3Flag, tariff))

export const selectTariffs = state => get(state, 'cvm.tariffsV3.tariffs.data', [])

const filterTariffsWithStatusV3 = tariffStatus =>
  createSelector([selectTariffs], tariffs => {
    return tariffs?.filter(tariff => isTariffWithStatus(tariff, tariffStatus))
  })

const getActiveAndRetiredTariffs = (
  state,
  isLoyaltyAgent = false,
  isRetentionAgent = false,
  {
    isProAgent = false,
    currentTariff = null,
    hasStc = null,
    isStcV3Flag = false,
    isStcV3Phase2Flag = false,
  },
) => {
  const checkHasOldPorfolioStcPermission = isTariffWithPortfolioStcPermission(currentTariff)

  if (isStcV3Phase2Flag) {
    return selectTariffs(state)
  }

  const retiredTariffs = isStcV3Flag
    ? filterTariffsWithStatusV3(STATUS_RETIRED)(state)
    : filterTariffsWithStatus(STATUS_RETIRED)(state)
  let activeTariffs = isStcV3Flag
    ? filterTariffsWithStatusV3(STATUS_ACTIVE)(state)
    : filterTariffsWithStatus(STATUS_ACTIVE)(state)

  const hiddenTariffs = isStcV3Flag
    ? filterTariffsWithStatusV3(STATUS_HIDDEN)(state)
    : filterTariffsWithStatus(STATUS_HIDDEN)(state)

  if (!(isLoyaltyAgent || isRetentionAgent) && (!checkHasOldPorfolioStcPermission || hasStc)) {
    activeTariffs = activeTariffs.filter(
      tariff =>
        !isAvailableTryAndBuyRetiredTariff(isStcV3Flag, tariff) &&
        !isAvailableTryAndBuyLoyaltyTariffs(isStcV3Flag, tariff),
    )
  }

  const availableRetiredTariffs = retiredTariffs.filter(
    tariff =>
      isAvailableRetiredTariff(isStcV3Flag, tariff) ||
      (isProAgent && isAvailableRetiredProTariff(isStcV3Flag, tariff)) ||
      (isLoyaltyAgent && isAvailableRetiredLoyaltyTariff(isStcV3Flag, tariff)) ||
      ((isLoyaltyAgent || isRetentionAgent) &&
        isAvailableRetiredLoyaltyRetentionTariff(isStcV3Flag, tariff)) ||
      (checkHasOldPorfolioStcPermission &&
        !hasStc &&
        isAvailableTryAndBuyRetiredTariff(isStcV3Flag, tariff)),
  )

  return activeTariffs.concat(hiddenTariffs).concat(availableRetiredTariffs)
}

const getIndirectOrFtthTariffs = (state, isStcV3Flag, tariffs, subscription) => {
  let isNeba = false
  let isVula = false

  const orders = getOrder(state)
  const subscriptionId = get(subscription, 'subscription_id')
  const territoryOwner = getCurrentTerritoryOwner(orders, subscriptionId)

  if (!isNil(territoryOwner)) {
    isNeba = isCurrentTariffNebaFiber(territoryOwner)
    isVula = isCurrentTariffVulaFiber(territoryOwner)
  }

  if (!isNeba && !isVula) {
    const currentTariff = selectTariffsApigeeById(getSubscriptionTariffId(subscription))(state)
    isNeba = isNebaTariff(currentTariff)
    isVula = isVulaTariff(currentTariff)
  }

  if (isNeba) {
    return tariffs.filter(tariff => isNebaTariff(tariff, isStcV3Flag))
  }
  if (isVula) {
    return tariffs.filter(tariff => isVulaTariff(tariff, isStcV3Flag))
  }
  return tariffs.filter(
    tariff =>
      isFtthTariff(tariff, isStcV3Flag) &&
      !isNebaTariff(tariff, isStcV3Flag) &&
      !isVulaTariff(tariff, isStcV3Flag),
  )
}

const get2pPureTariffs = (state, isStcV3Flag, isStcV3Phase2Flag, subscription) => {
  if (isStcV3Phase2Flag) {
    return selectTariffs(state)
  }

  let tariffs

  const retiredTariffs = isStcV3Flag
    ? filterTariffsWithStatusV3(STATUS_RETIRED)(state)
    : filterTariffsWithStatus(STATUS_RETIRED)(state)

  const hiddenTariffs = isStcV3Flag
    ? filterTariffsWithStatusV3(STATUS_HIDDEN)(state)
    : filterTariffsWithStatus(STATUS_HIDDEN)(state)

  const retiredAndHiddenTariffs = retiredTariffs.concat(hiddenTariffs)

  if (isStcV3Flag) {
    const activeTariffs = filterTariffsWithStatusV3(STATUS_ACTIVE)(state)
    tariffs = activeTariffs
      .concat(retiredAndHiddenTariffs)
      .filter(tariff => tariff.tariffType === CATEGORY_2P_V3)
  } else {
    tariffs = retiredAndHiddenTariffs.filter(tariff => tariff.category === CATEGORY_2P)
  }

  return getIndirectOrFtthTariffs(state, isStcV3Flag, tariffs, subscription)
}

const getConvergentAnd2pTariffs = (
  state,
  isStcV3Flag,
  isStcV3Phase2Flag,
  isLoyaltyAgent,
  isRetentionAgent,
  subscription,
  hasStc,
) => {
  const currentTariff = selectTariffsApigeeById(getSubscriptionTariffId(subscription))(state)
  const availableTariffs = getActiveAndRetiredTariffs(state, isLoyaltyAgent, isRetentionAgent, {
    currentTariff,
    hasStc,
    isStcV3Flag,
    isStcV3Phase2Flag,
  })

  if (isStcV3Phase2Flag) {
    return availableTariffs
  }

  const activeTariffs = isStcV3Flag
    ? filterTariffsWithStatusV3(STATUS_ACTIVE)(state)
    : filterTariffsWithStatus(STATUS_ACTIVE)(state)

  const currentTariffIsConvergent = isConvergentTariff(currentTariff)
  const currentTariffIs2pFreeLine = is2pFreeLineTariff(currentTariff)
  // 2p tariffs with a fake msisdn can change to either 2p or 3p tariffs
  // 3p (convergent) tariffs can only change to 3p tariffs
  // 2p tariffs with no fake msisdn can only switch to 2p tariffs
  const tariffs =
    !is2pFreeLineSubscription(state, subscription) ||
    is2pSubscriptionWithFakeMsisdn(state, subscription)
      ? availableTariffs.filter(t => {
          const tariffIsConvergent = isConvergentTariff(t, isStcV3Flag)
          const tariffIs2pFreeLine = is2pFreeLineTariff(t, isStcV3Flag)
          // TODO: Temporary Ternary for Phase 1 migration of the JTS in Phase 2 the operation has to be deleted and only the content of the case False has to be left
          return (
            (isStcV3Flag ? currentTariffIsConvergent && tariffIsConvergent : tariffIsConvergent) ||
            (currentTariffIs2pFreeLine && tariffIs2pFreeLine)
          )
        })
      : activeTariffs.filter(is2pFreeLineTariff, isStcV3Flag)

  if (isAdslTariff(currentTariff))
    return tariffs.filter(tariff => isAdslTariff(tariff, isStcV3Flag))
  return getIndirectOrFtthTariffs(state, isStcV3Flag, tariffs, subscription)
}

const getMobileTariffs = (
  state,
  isStcV3Flag,
  isStcV3Phase2Flag,
  paymentType,
  isLoyaltyAgent,
  isRetentionAgent,
  isProAgent,
) => {
  const tariffs = isStcV3Phase2Flag
    ? selectTariffs(state)
    : getActiveAndRetiredTariffs(state, isLoyaltyAgent, isRetentionAgent, {
        isProAgent,
        isStcV3Flag,
      })

  return tariffs.filter(
    t =>
      isMobileTariff(t, isStcV3Flag || isStcV3Phase2Flag) &&
      (!paymentType || t.payment_type === paymentType),
  )
}

export const getTariffOptionsForSubscription = (
  state,
  isLoyaltyAgent,
  isRetentionAgent,
  isProAgent,
  subscription,
  hasStc,
  isStcV3Flag,
  isStcV3Phase2Flag,
  hideCurrentTariff,
  { paymentType, forceMobile },
) => {
  let tariffs
  const isMobileOnly = isMobileOnlySubscription(subscription)
  const tariffId = get(subscription, 'type')
  const tariff = selectTariffsApigeeById(tariffId)(state)
  const tariffSegment = getTariffSegment(tariff)

  if (isConvergentSubscription(subscription) && !forceMobile) {
    if (get(subscription, 'category') === CATEGORY_2P) {
      tariffs = get2pPureTariffs(state, isStcV3Flag, isStcV3Phase2Flag, subscription)
    } else {
      tariffs = getConvergentAnd2pTariffs(
        state,
        isStcV3Flag,
        isStcV3Phase2Flag,
        isLoyaltyAgent,
        isRetentionAgent,
        subscription,
        hasStc,
      )
    }
  } else {
    tariffs = getMobileTariffs(
      state,
      isStcV3Flag,
      isStcV3Phase2Flag,
      paymentType,
      isLoyaltyAgent,
      isRetentionAgent,
      isProAgent,
    )
  }

  if (isMobileOnly) {
    tariffs = tariffs.filter(t => filterMobileOnlyTarrifs(t))
  } else if (tariffSegment === RESIDENTIAL) {
    tariffs = tariffs.filter(t => filterRetiredTariffs(isStcV3Flag, t))
  }

  if (hideCurrentTariff) {
    tariffs = tariffs.filter(
      t =>
        (isStcV3Flag || isStcV3Phase2Flag ? t.psId : tariffs.id) !==
        getSubscriptionTariffId(subscription),
    )
  }

  return tariffs
    .filter(t => filterObsoleteTariffs(isStcV3Flag, t))
    .sort((a, b) =>
      (
        isStcV3Flag || isStcV3Phase2Flag
          ? a.commercialInformation.name.toLowerCase() > b.commercialInformation.name.toLowerCase()
          : a.commercial_name.toLowerCase() > b.commercial_name.toLowerCase()
      )
        ? 1
        : -1,
    )
}

export const getLegalText = (state, operations = new Set()) => {
  const legalTexts = selectContentfulResource('legalText')(state) || []
  const filteredLegalTexts = legalTexts.filter(({ operation }) => operations.has(operation))
  const headBoilerplate =
    legalTexts.find(({ operation }) => operation === HEAD_BOILERPLATE_OPERATION) || {}
  const footerBoilerplate =
    legalTexts.find(({ operation }) => operation === FOOTER_BOILERPLATE_OPERATION) || {}
  const filteredLegalTextsWithBoilerplate = [].concat(
    headBoilerplate,
    filteredLegalTexts,
    footerBoilerplate,
  )
  return filteredLegalTextsWithBoilerplate.reduce(
    (acc, { text }) => (acc ? `${acc}\n\n${text}` : text),
    '',
  )
}

export const getAdditionalLinesLost = (state, additionalParameters = {}) => {
  const { msisdn, newTariffId, unsubscribing } = additionalParameters
  const subscriptions = getSubscriptions(state)

  // return early if being called from an operation on an additional line
  // (no additional line should be lost from an operation on an additional line)
  const currentSubscription = selectSubscriptionByMsisdn(msisdn)(state)
  if (isAdditionalLine(currentSubscription)) return []

  const primarySubscriptions = subscriptions.filter(sub => !isAdditionalLine(sub))
  const additionalSubscriptions = subscriptions.filter(sub => isAdditionalLine(sub)) // subs with an additional line discount

  if (!additionalSubscriptions.length || !primarySubscriptions.length) return []

  const getSubscriptionsTariffs = subs =>
    subs.reduce((acc, sub) => {
      // if it's the current subscription getting its tariff changed, take into account this new tariff
      if (getMsisdn(sub) === msisdn && newTariffId) return [...acc, newTariffId]
      // if its tariff has already been changed or is in pending-to-apply state
      const confirmedTariff = getChangedTariff(sub)(state)
      if (confirmedTariff) return [...acc, confirmedTariff.id]
      // if this sub is being or has already been unsubscribed do not include its tariff
      const pendingUnsubscription = getPendingUnsubscription(state, getMsisdn(sub))
      if (pendingUnsubscription || (getMsisdn(sub) === msisdn && unsubscribing)) return acc
      // else return the sub's tariff
      return [...acc, getSubscriptionTariffId(sub)]
    }, [])

  const primarySubscriptionsTariffs = getSubscriptionsTariffs(primarySubscriptions)

  // get the max fee among all primary subscriptions tariffs
  const maxPrimarySubscriptionsTariffsValue = Math.max(
    ...primarySubscriptionsTariffs.map(tariff =>
      Number(get(selectTariffsApigeeById(tariff)(state), 'monthly_fee')),
    ),
  )

  // subs with a non "duo" tariff and an additional discount
  const regularAdditionalSubscriptions = additionalSubscriptions.filter(
    sub => !isDuoTariffId(getSubscriptionTariffId(sub)),
  )
  const regularAdditionalSubscriptionsTariffs = getSubscriptionsTariffs(
    regularAdditionalSubscriptions,
  )

  // get the max fee among all additional regular subscriptions tariffs
  const maxRegularAdditionalSubscriptionsTariffsValue = Math.max(
    ...regularAdditionalSubscriptionsTariffs.map(tariff =>
      Number(get(selectTariffsApigeeById(tariff)(state), 'monthly_fee')),
    ),
  )

  const additionalLinesLost = []

  // 1st logic: if the maximum tariff fee of the regular additional lines is greater than the
  // maximum tariff fee of the non-additional lines, all regular additional lines lose their
  // additional discount
  if (maxRegularAdditionalSubscriptionsTariffsValue > maxPrimarySubscriptionsTariffsValue) {
    additionalLinesLost.push(...regularAdditionalSubscriptions)
  }

  // 2nd logic: if there is no "sinfin" convergent subscription, the "duo" additional lines
  // lose their additional discount
  const duoAdditionalSubscriptions = additionalSubscriptions.filter(sub =>
    isDuoTariffId(getSubscriptionTariffId(sub)),
  )

  const hasSinfinConvergentSubscription = primarySubscriptions.some(sub => {
    const isConvergent = isConvergentSubscription(sub)
    const isSinfin = isSinfinTariff(selectTariffsApigeeById(getSubscriptionTariffId(sub))(state))
    const isUnsubscribeFixed = getPendingFixedUnsubscription(state, getMsisdn(sub))

    const pendingTariffChangeId = get(getPendingTariffChange(state, getMsisdn(sub)), 'id')
    const isUnsubscribeDontLoseAdditionalDuoDiscount =
      TARIFF_DONT_LOSE_ADDITIONAL_DUO_DISCOUNT.includes(pendingTariffChangeId)

    return (
      isConvergent &&
      isSinfin &&
      (!isUnsubscribeFixed || isUnsubscribeDontLoseAdditionalDuoDiscount)
    )
  })

  if (!hasSinfinConvergentSubscription) {
    additionalLinesLost.push(...duoAdditionalSubscriptions)
  }

  return additionalLinesLost
}

export const shouldWarnAdditionalLines = (state, additionalParameters = {}) => {
  const additionalLinesLost = getAdditionalLinesLost(state, additionalParameters)
  return additionalLinesLost.length > 0
}

const findMostRecentRenewalTariffId = (orders, msisdn) => {
  const renewalOrders = orders.filter(order => {
    const orderProduct = get(order, 'customer.products', []).find(p => p.id === msisdn)
    const pss = get(orderProduct, 'productsSpecifications', [])
    return pss.find(ps =>
      get(ps, 'productSpecCharacteristic', []).find(psc => psc.value === 'renewal'),
    )
  })
  const mostRecentRenewalOrder = maxBy(renewalOrders, 'orderDate')
  return get(mostRecentRenewalOrder, 'customer.products[0].type', undefined)
}

export const shouldWarnLowerValueTariffChange = (state, subscription, newTariffId) => {
  const tariffs = selectTariffsApigeeDataTariffs(state)
  const newTariff = selectTariffsApigeeById(newTariffId)(state)
  const idRelatedMobileCurrentTariff = get(getMobilePart(subscription), 'id') || ''

  const isConvergent = isConvergentSubscription(subscription)
  const currentMobileTariff = last(
    idRelatedMobileCurrentTariff.split('_').filter(s => s.includes('CONT')),
  )
  const relatedMobileNewTariff = getConvergentRelatedMobileTariff(newTariffId, tariffs) || {}
  const newTariffFee = Number(get(newTariff, 'monthly_fee'))

  const subscriptionOrders = selectOrdersByProductId(getMsisdn(subscription))(state)
  const mostRecentRenewalTariffId = findMostRecentRenewalTariffId(
    subscriptionOrders,
    getMsisdn(subscription),
  )
  // fall back to current tariff
  const compareToTariff = selectTariffsApigeeById(
    mostRecentRenewalTariffId || getSubscriptionTariffId(subscription),
  )(state)
  const compareToTariffFee = Number(get(compareToTariff, 'monthly_fee'))
  const hasMobilePermanent = Boolean(
    findMobilePermanent(subscription) &&
      !isPermanentOrPenaltyRemovePending(state, getMsisdn(subscription), false),
  )

  const isPosToPre = isPrepaidTariff(newTariff) && !isPrepaidTariff(compareToTariff)
  const hasSameMobileConvergentTariff =
    isConvergent && relatedMobileNewTariff.id === currentMobileTariff

  return (
    hasMobilePermanent &&
    !hasSameMobileConvergentTariff &&
    (newTariffFee < compareToTariffFee || isPosToPre)
  )
}

export const getCurrentCustomerTax = state =>
  getTax(state, customer360SvcSelectors.getBillingAddressPostCode(state))

export const getNotifyPermanenceModificationErrorMsg = (state, msisdn) =>
  get(state, `cvm.permanenceModifications[${msisdn}].error.info`, null)

export const getNotifyDiscountApplicationErrorMsg = (state, msisdn, discountCode) =>
  get(state, `cvm.applicableDiscounts.${msisdn}.${discountCode}.error.info`, null)

const getPermanenceModificationReasonName = (state, resource, code) => {
  const reasons = selectContentfulResource(`${resource}ReasonOptions`)(state) || []
  const reason = reasons.find(r => r.value === code)
  return get(reason, 'name')
}
export const getPenaltyCondonedReasonName = (state, code) =>
  getPermanenceModificationReasonName(state, 'removePenalty', code)

export const getPermanenceRemovedReasonName = (state, code) =>
  getPermanenceModificationReasonName(state, 'condonePermanence', code)

export const generatePartialFees = (
  currentDiscount,
  prices,
  confirmedTariff,
  isModificationView,
  isStcV3Flag,
) => {
  let partialFees = null

  if (isModificationView && confirmedTariff) {
    partialFees = {
      taxFeeAmount: isStcV3Flag
        ? get(confirmedTariff, 'totalPriceFreeTax')
        : get(confirmedTariff, 'monthly_fee'),
    }
  } else {
    partialFees = getAttributesPartialFees(currentDiscount, prices)
  }

  return partialFees
}

export const getCompatibleCurrentDiscounts = (
  state,
  subscription,
  { selectedDiscounts = [], selectedTariff },
  isModificationView = false,
) => {
  const isStcV3Flag = selectStcV3Flag(state)
  const prices = get(subscription, 'prices.data')

  const modifications = getSubscriptionsState(state)

  const selectedTariffData = selectTariffsApigeeById(selectedTariff)(state)
  const { confirmedTariff = selectedTariffData } = get(modifications, getMsisdn(subscription), {})

  let currentDiscounts = []

  if (!isModificationView || (isModificationView && !isDuoTariffId(get(confirmedTariff, 'id')))) {
    currentDiscounts = getCurrentDiscounts(subscription)
  }

  if (!isNil(prices) && !isEmpty(prices)) {
    currentDiscounts.reduce((accumulator, currentDiscount, index) => {
      const attributesPartialFees = generatePartialFees(
        currentDiscount,
        prices,
        confirmedTariff,
        isModificationView,
        isStcV3Flag,
      )
      accumulator[index].subscription_discount.partial_fee = attributesPartialFees
      accumulator[index].subscription_discount.prices = prices
      return accumulator
    }, currentDiscounts)
  }

  const paymentType = confirmedTariff
    ? getTariffPaymentType(confirmedTariff)
    : getSubscriptionPaymentType(subscription)
  const isPrepaid = isPrepaidPaymentType(paymentType)
  // Prepaid tariffs are not compatible with any kind of discounts
  if (isPrepaid) return []

  const additionalLinesLost = getAdditionalLinesLost(state, {
    msisdn: getMsisdn(subscription),
    newTariffId: selectedTariff,
  })

  return (
    currentDiscounts
      // When transform extra lines to additional lines
      .filter(d => {
        return (
          subscription.type === DUO_PRINCIPAL_TARIFF_ID || d.discountCode !== MAIN_DUO_DISCOUNT_ID
        )
      })
      // When another subscription forces a removal of this subscription discounts (additional lines)
      .filter(d => {
        if (additionalLinesLost.some(lineLost => lineLost.id === getMsisdn(subscription))) {
          return !isAdditionalDiscount(d)
        }
        return true
      })
      .filter(d => {
        if (selectedDiscounts.length > 0) {
          return COMPATIBLE_CURRENT_DISCOUNTS.includes(get(d, 'discount_plan.rule_id'))
        }
        return true
      })
  )
}

export const getCompatibleCurrentDiscountsModificationView = (state, subscription, modifications) =>
  getCompatibleCurrentDiscounts(state, subscription, modifications, true)

export const hasDiscountIncompatibilities = (
  state,
  subscription,
  { selectedDiscounts = [], preSelectedDiscount, selectedTariff },
) => {
  const selectedAndPreSelectedDiscounts = [...selectedDiscounts, preSelectedDiscount].filter(
    Boolean,
  )

  return (
    getCompatibleCurrentDiscounts(state, subscription, { selectedDiscounts, selectedTariff })
      .length !==
    getCompatibleCurrentDiscounts(state, subscription, {
      selectedDiscounts: selectedAndPreSelectedDiscounts,
      selectedTariff,
    }).length
  )
}

export const getIsTypificationAllowed = createSelector(
  getSegment,
  // TODO The undefined values is considered temporarily for clients onfly, because the customer360 does
  // not yet return the real segment. Once the customer360 service can know the segmennt this code should
  // updated to not consider the undefined value
  segment =>
    isNil(segment) ||
    segment === 'CONSUMER' ||
    segment === 'PRO-SOHO-COMPANY' ||
    segment === 'PRO-SOHO-AUTONOMO' ||
    segment === 'SOHO-COMPANY' ||
    segment === 'SOHO-AUTONOMO',
)

// TODO: Remove when Qvantel fix this issue
export const is25to30STC = (state, subscription, newTariffId) => {
  const actualTariff = selectTariffsApigeeById(subscription.type)(state)
  const newTariff = selectTariffsApigeeById(newTariffId)(state)

  if (newTariff && actualTariff) {
    const is25Tariff =
      actualTariff.commercial_name.includes('25 GB') && isConvergentTariff(actualTariff)
    const is30Tariff = newTariff.commercial_name.includes('30 GB') && isConvergentTariff(newTariff)

    return is25Tariff && is30Tariff
  }

  return false
}

export const selectSubscriptionWithLineType = createSelector(
  getSubscriptions,
  selectContracts,
  (subscriptions, contracts) => {
    const packages = head(contracts)?.packages
    const subscriptionsWithLineType = subscriptions.map(elem => {
      const pkg = find(packages, { id: elem?.id })

      return {
        ...elem,
        isMainLine:
          head(pkg?.products)?.multisimType === 'main_line' &&
          ['3P', '1P'].includes(head(pkg?.products)?.category),
        categories: head(pkg?.products)?.category,
        parentSubscriptionId: head(pkg?.products)?.multisimId,
        lineType: head(pkg?.products)?.multisimType,
        multisimData: head(pkg?.products)?.multisimData.map(multisimElem =>
          subscriptions.find(sub => sub?.subscription_id === multisimElem?.subscriptionId),
        ),
      }
    })

    return subscriptionsWithLineType
  },
)

export const isTariffCompatibleWithSva = (state, tariffId) => {
  if (!tariffId) {
    return false
  }

  const tariff = selectTariffsApigeeById(tariffId)(state)
  const is2pWithFiberTariff = is2pTariff(tariff) && isFtthTariff(tariff)
  const is3pTariff = isConvergentTariff(tariff)

  return is3pTariff || is2pWithFiberTariff
}

export const isPostpaidTariffId = (state, tariffId) => {
  const tariff = selectTariffsApigeeById(tariffId)(state)
  return isPostpaidTariff(tariff)
}

export const selectIs3pOr2pTariff = (state, tariffId) => {
  const tariff = selectTariffsApigeeById(tariffId)(state)
  return is2pTariff(tariff) || is2pFreeLineTariff(tariff) || isConvergentTariff(tariff)
}

export const selectIsMobileTariff = (state, tariffId) => {
  const tariff = selectTariffsApigeeById(tariffId)(state)
  return isMobileTariff(tariff)
}

export const selectIsElFijoTariff = (state, tariffId) => {
  const tariff = selectTariffsApigeeById(tariffId)(state)
  return isElFijoTariff(tariff)
}

export const isTariffCompatibleWithHebeDiscount = tariffId =>
  CVM_TARIFFS_COMPATIBLE_WITH_HEBE_DISCOUNT.includes(tariffId)

export const getDecisionAllowed = state => {
  const orderElegibility = getOrderElegibility(state)
  return get(orderElegibility, 'data.decisionAllowed', true)
}

export const getRejectionReason = state => {
  const orderElegibility = getOrderElegibility(state)
  return get(orderElegibility, 'data.rejectionReason')
}

export const getOrderElegibilityisKo = state => {
  const orderElegibility = getOrderElegibility(state)
  return !isEmpty(get(orderElegibility, 'error'))
}
export const selectDecisionAllowed = createSelector(getOrderElegibility, data =>
  get(data, 'data.decisionAllowed', false),
)

export const selectRejectionReason = createSelector(getOrderElegibility, data =>
  get(data, 'data.rejectionReason'),
)

export const selectOrderElegibilityisKo = createSelector(
  getOrderElegibility,
  data => !isEmpty(get(data, 'error')),
)

export const selectWarnings = createSelector(getOrderConsequences, data =>
  get(data, 'data.warnings'),
)

export const selectWarningPermanency = date =>
  createSelector(getOrderConsequences, data => {
    const dateAndPermanencies = get(data, 'data.date-and-permanencies-options')
    const permanencies = dateAndPermanencies && dateAndPermanencies.find(item => item.date === date)
    return get(permanencies, 'permanencies')
  })

export const selectOrderConsequencesIsKo = createSelector(
  getOrderConsequences,
  data => !isEmpty(get(data, 'error')),
)

export const selectExcludeDiscounts = createSelector(selectWarnings, warnings =>
  get(
    warnings?.find(warn => get(warn, 'warning') === WARN_DISCOUNT_LOST),
    'discounts',
  )?.map(discount => get(discount, 'discount')),
)

export const selectLoadingParentTechnology = createSelector(getParentTechnology, technology =>
  get(technology, 'loading'),
)

export const selectLoadingCoverageTechnology = createSelector(getCoverageTechnology, technology =>
  get(technology, 'loading'),
)

export const selectParentTechnology = createSelector(getParentTechnology, technology =>
  get(technology, 'id'),
)

export const selectCoverageTechnology = createSelector(getCoverageTechnology, technology =>
  get(technology, 'id'),
)

export const selectParentTechnologyIsKo = createSelector(
  getParentTechnology,
  technology => !isEmpty(get(technology, 'error')),
)

export const selectCoverageTechnologyIsKo = createSelector(
  getCoverageTechnology,
  technology => !isEmpty(get(technology, 'error')),
)

export const selectCoverageTechnologyErrorMessage = createSelector(
  getCoverageTechnology,
  technology => get(technology, 'error.error.info'),
)

export const generateFeeSummary = (
  state,
  { subscription, compatibleCurrentDiscounts, tax, withoutTerminalFee, taxNeeded },
) => {
  const multisimData = get(subscription, 'multisimData')
  const tariffMultisimWithTax = getTariffMultisimWithTax(subscription, tax)
  const pricesSubcription = get(subscription, 'prices.data', [])
  const pricesMultisim = !isEmpty(multisimData)
    ? multisimData.map(data => head(get(data, 'prices.data')))
    : {}
  const prices = [...pricesSubcription, ...(!isEmpty(multisimData) ? pricesMultisim : [])]
  const tariffId = get(subscription, 'type')
  const tariff = selectTariffsApigeeById(tariffId)(state)
  const is2p = is2pTariff(tariff) || is2pFreeLineTariff(tariff) || isElFijoTariff(tariff)
  const allDiscounts = compatibleCurrentDiscounts
    .map(discount => formatDiscountAmountProrrated(discount))
    .map(normalizeCurrentDiscount)
    .filter(
      ({ duration, lastMonth }) => duration >= 0 || duration === UNLIMITED_DURATION || lastMonth,
    )

  const discountsWithFiniteDuration = allDiscounts.filter(d => d.duration !== UNLIMITED_DURATION)
  const discountsForever = allDiscounts.filter(d => d.duration === UNLIMITED_DURATION)

  const periods = uniq([
    ...discountsWithFiniteDuration.sort(byAscendingDuration).map(d => d.duration),
    0,
  ])

  const fee = getTariffFee(subscription)
  const feeWithTax = applyTax(tax, fee) + tariffMultisimWithTax
  const feeWithForeverDiscountsApplied = discountsForever.reduce(
    (partialFee, d) => applyDiscount(partialFee, d),
    fee,
  )

  const discounts = discountsWithFiniteDuration
    .concat(generateEmptyDiscounts(periods))
    .sort(byAscendingDuration)
    .map(discount => ({
      ...discount,
      is2p,
    }))

  const tariffs = selectTariffsApigeeDataTariffs(state)
  const subscriptionType = subscription?.type
  const relatedMobileTariff = getConvergentRelatedMobileTariff(subscriptionType, tariffs)

  const appliedDiscounts = [
    ...applyDiscounts(feeWithForeverDiscountsApplied, discounts, prices, relatedMobileTariff),
  ]

  const enabledTaxAgileTv = selectEnabledNewClientSale(state)

  const otherFees = getOtherFeesCvm(
    subscription,
    tax,
    withoutTerminalFee,
    taxNeeded,
    enabledTaxAgileTv,
  )

  const { subscription_discount } =
    subscription.charges?.find(
      charge => charge.discount_plan.billing_item_id === MAIN_DUO_DISCOUNT_ID,
    ) || {}

  const isNewDuoDiscount = subscription_discount?.amount === MAIN_DUO_DISCOUNT_NEW_AMOUNT
  const isOldDuoDiscount = subscription_discount?.amount === MAIN_DUO_DISCOUNT_OLD_AMOUNT

  return {
    rawFeeSummary: periods.map(duration => {
      const monthlyFeeCalc =
        applyTax(
          tax,
          appliedDiscounts
            .filter(dis => dis.duration === duration)
            .reduce((sum, { monthlyFee }) => sum + monthlyFee, 0),
        ) + tariffMultisimWithTax

      return {
        totalFeeMainDuo:
          subscription.type === DUO_PRINCIPAL_TARIFF_ID &&
          ((isNewDuoDiscount && applyTax(tax, 2.48) + otherFees) ||
            (isOldDuoDiscount && otherFees.toString())),
        monthlyFee: monthlyFeeCalc + otherFees,
        savings: !isNewDuoDiscount && !isOldDuoDiscount ? feeWithTax - monthlyFeeCalc : 0,
        duration,
      }
    }),
  }
}

export const selectCommercialMigrationIsLoading = createSelector(getCommercialMigration, data =>
  get(data, 'loading'),
)

export const selectCommercialMigrationError = createSelector(getCommercialMigration, data =>
  get(data, 'error'),
)

export const selectCommercialMigrationData = createSelector(getCommercialMigration, data =>
  get(data, 'data'),
)

export const selectChangeAddressIsLoading = createSelector(getChangeAddress, data =>
  get(data, 'loading'),
)

export const selectChangeAddressError = createSelector(getChangeAddress, data => get(data, 'error'))

export const selectChangeAddressData = createSelector(getChangeAddress, data => get(data, 'data'))

export const selectChangeTechnologyIsLoading = createSelector(getChangeTechnology, data =>
  get(data, 'loading'),
)

export const selectChangeTechnologyError = createSelector(getChangeTechnology, data =>
  get(data, 'error'),
)

export const selectChangeTechnologyData = createSelector(getChangeTechnology, data =>
  get(data, 'data'),
)

export const selectInstaller = createSelector(getInstaller, data => get(data, 'installer'))

export const selectConnectionType = createSelector(getInstaller, data =>
  get(data, 'connectionType'),
)

export const selectChangeTechnology = createSelector(
  [selectSfid, selectChangeTechnologyV3],
  (sfid, flag) => flag || CHANGE_TECHNOLOGY_SFID_ACCESS.includes(sfid),
)

export const selectChangeAddress = createSelector(
  [selectSfid, selectChangeAddressV3],
  (sfid, flag) => flag || CHANGE_ADDRESS_SFID_ACCESS.includes(sfid),
)
