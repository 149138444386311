const brands = {
  yoigo: '1',
} // ADD more brands if needed
const salesTool = {
  newton: 'VISTA',
} // ADD more salesTool if needed

export function getCustomerData(
  contactMedium,
  appTime,
  params,
  segment,
  contractId,
  technology,
  tariff,
  sfid,
) {
  return {
    customer: {
      contactMedium,
    },
    submission: {
      appTime,
      brand: brands[params.brand],
      segment,
      salesTool: salesTool[params.channel],
      sfid,
      contractid: contractId,
    },
    modification: {
      MSISDN: params.documentId,
      modType: 1,
      technology,
      tariff,
    },
  }
}
