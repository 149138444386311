import PropTypes from 'prop-types'

import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import { Card } from 'modules/ui'
import { LineItemCard, OutlinedDivider } from 'components/ui'

import { formatDate } from 'utils/formatting/dates'
import { isValid } from 'date-fns'

import { formatCensuredIban } from 'modules/vista-360/helpers/formats.helper.js'

const useStyles = makeStyles({
  'sell-data': {
    '& .card-content': {
      padding: '24px 0',
    },

    '& .sell-data-item': {
      width: 'auto',
    },
  },
})

export function SellData({ data, isPosOrMasProximo }) {
  const classes = useStyles()

  return (
    <Card title="Datos de la venta" className={classes['sell-data']} withShadow>
      <Box display="flex" flexDirection="column">
        <LineItemCard
          className="sell-data-item"
          primaryText="Fecha del pedido"
          secondaryText={isValid(new Date(data.orderDate)) ? formatDate(data.orderDate) : ''}
        />
        <LineItemCard
          className="sell-data-item"
          primaryText="Canal de venta"
          secondaryText={data.channel}
        />
        <LineItemCard className="sell-data-item" primaryText="SFID" secondaryText={data.sfid} />
      </Box>

      <Box p="15px 24px">
        <OutlinedDivider />
      </Box>

      <Box display="flex" flexDirection="column">
        <LineItemCard
          className="sell-data-item"
          primaryText="Id de la Suscripción"
          secondaryText={data.parentAgreementId || '-'}
        />
      </Box>
      <Box display="flex" flexDirection="column">
        <LineItemCard
          className="sell-data-item"
          primaryText="Modalidad de contrato"
          secondaryText={data.typeContract}
        />
        <LineItemCard
          className="sell-data-item"
          primaryText="Método de pago"
          secondaryText={data.methodPay}
        />
        <LineItemCard
          className="sell-data-item"
          primaryText="Cuenta bancaria"
          secondaryText={isPosOrMasProximo ? formatCensuredIban(data.bankNumber) : data.bankNumber}
        />
      </Box>
    </Card>
  )
}

SellData.propTypes = {
  data: PropTypes.shape({
    orderDate: PropTypes.string,
    channel: PropTypes.string,
    sfid: PropTypes.string,
    parentAgreementId: PropTypes.string,
    typeContract: PropTypes.string,
    methodPay: PropTypes.string,
    bankNumber: PropTypes.string,
  }).isRequired,
  isPosOrMasProximo: PropTypes.bool,
}
