import { get, keyBy } from 'lodash'
import * as constants from './constants'

const getSetSvaPreviousStatus = (state, msisdn) =>
  get(state, ['disableDataBonus', msisdn, 'previousStatus'], null)

export const initialState = {
  loading: false,
  error: null,
  changeTariff: {},
  disableDataBonus: {},
  disableDataSva: {},
  cdctWorkOrderIds: {},
}

const initialStateService = {
  loading: false,
  error: null,
}

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case constants.FIND_SUBSCRIPTIONS_ATTEMPTED: {
      return {
        ...state,
        loading: true,
        error: null,
      }
    }

    case constants.FIND_SUBSCRIPTIONS_SUCCEEDED: {
      return {
        ...state,
        subs: {
          ...state.subs,
          ...keyBy(
            payload.data.map((sub, index) => ({
              loading: false,
              data: sub,
              order: index,
              error: null,
            })),
            'data.id',
          ),
        },
        error: null,
        loading: false,
      }
    }

    case constants.FIND_SUBSCRIPTIONS_FAILED: {
      return {
        ...state,
        error: { ...payload },
        loading: false,
      }
    }

    case constants.RESET_SUBSCRIPTIONS: {
      return {
        ...initialState,
      }
    }

    case constants.FIND_SUBSCRIPTION_BY_ID_ATTEMPTED: {
      return {
        ...state,
        subs: {
          ...state.subs,
          [payload.msisdn]: {
            loading: true,
            error: null,
          },
        },
        loading: true,
        // TODO: Maybe, this loading should only be updated when loading all subscriptions with FIND_SUBSCRIPTIONS_ATTEMPTED
      }
    }

    case constants.FIND_SUBSCRIPTION_BY_ID_SUCCEEDED: {
      return {
        ...state,
        subs: {
          ...state.subs,
          [payload.msisdn]: {
            loading: false,
            data: payload.data,
            error: null,
          },
        },
        loading: false,
        // TODO: Maybe, this loading should only be updated when loading all subscriptions with FIND_SUBSCRIPTIONS_ATTEMPTED
      }
    }

    case constants.FIND_SUBSCRIPTION_BY_ID_FAILED: {
      return {
        ...state,
        subs: {
          ...state.subs,
          [payload.msisdn]: {
            loading: false,
            error: payload.error,
          },
        },
        loading: false,
        error: payload.error,
      }
    }

    case constants.SET_BUNDLES_ATTEMPTED: {
      return {
        ...state,
        setBundles: {
          ...state.setBundles,
          [payload.msisdn]: {
            loading: true,
            done: false,
            error: null,
          },
        },
      }
    }

    case constants.DISABLE_DATA_BONUS_ATTEMPTED: {
      return {
        ...state,
        disableDataBonus: {
          ...state.disableDataBonus,
          [payload.msisdn]: {
            loading: true,
            done: false,
            error: null,
          },
        },
      }
    }

    case constants.SET_UPSELL_BUNDLES_ATTEMPTED: {
      return {
        ...state,
        setUpsellBundles: {
          ...state.setUpsellBundles,
          [payload.msisdn]: {
            loading: true,
            done: false,
            error: null,
          },
        },
      }
    }

    case constants.CHANGE_TARIFF_ATTEMPTED: {
      return {
        ...state,
        changeTariff: {
          ...state.changeTariff,
          [payload.msisdn]: {
            loading: true,
            done: false,
            error: null,
          },
        },
      }
    }
    case constants.SET_BUNDLES_SUCCEEDED: {
      return {
        ...state,
        setBundles: {
          ...state.setBundles,
          [payload.msisdn]: {
            loading: false,
            done: true,
          },
        },
      }
    }

    case constants.DISABLE_DATA_BONUS_SUCCEEDED: {
      return {
        ...state,
        disableDataBonus: {
          ...state.disableDataBonus,
          [payload.msisdn]: {
            loading: false,
            done: true,
            success: true,
            error: false,
          },
        },
      }
    }

    case constants.DISABLE_DATA_BONUS_CLEAR_ERRORS: {
      return {
        ...state,
        disableDataBonus: {
          ...state.disableDataBonus,
          [payload.msisdn]: {
            loading: false,
            error: false,
            success: false,
          },
        },
      }
    }

    case constants.CHANGE_TARIFF_CLEAR_ERRORS: {
      return {
        ...state,
        changeTariff: {
          ...state.changaTariff,
          [payload.msisdn]: {
            loading: false,
            error: null,
          },
        },
      }
    }

    case constants.SET_UPSELL_BUNDLES_SUCCEEDED: {
      return {
        ...state,
        setUpsellBundles: {
          ...state.setUpsellBundles,
          [payload.msisdn]: {
            loading: false,
            done: true,
            error: null,
          },
        },
      }
    }

    case constants.CHANGE_TARIFF_SUCCEEDED: {
      return {
        ...state,
        changeTariff: {
          ...state.changeTariff,
          [payload.msisdn]: {
            loading: false,
            done: true,
            error: null,
          },
        },
      }
    }

    case constants.SET_BUNDLES_FAILED: {
      return {
        ...state,
        setBundles: {
          ...state.setBundles,
          [payload.msisdn]: {
            loading: false,
            done: false,
            error: payload.error,
            success: false,
          },
        },
      }
    }

    case constants.DISABLE_DATA_BONUS_FAILED: {
      return {
        ...state,
        disableDataBonus: {
          ...state.disableDataBonus,
          [payload.msisdn]: {
            loading: false,
            done: false,
            error: payload.error,
          },
        },
      }
    }

    case constants.SET_UPSELL_BUNDLES_FAILED: {
      return {
        ...state,
        setUpsellBundles: {
          ...state.setUpsellBundles,
          [payload.msisdn]: {
            loading: false,
            done: false,
            error: payload.error,
          },
        },
      }
    }

    case constants.CHANGE_TARIFF_FAILED: {
      return {
        ...state,
        changeTariff: {
          ...state.changeTariff,
          [payload.msisdn]: {
            loading: false,
            done: false,
            error: payload.error,
          },
        },
      }
    }

    case constants.FIND_PORT_OUT_INFO_ATTEMPTED: {
      return {
        ...state,
        portOuts: {
          ...state.portOuts,
          [payload.msisdn]: {
            loading: true,
            error: false,
            data: null,
          },
        },
      }
    }

    case constants.FIND_PORT_OUT_INFO_SUCCEEDED: {
      return {
        ...state,
        portOuts: {
          ...state.portOuts,
          [payload.msisdn]: {
            loading: false,
            error: false,
            data: payload.data,
          },
        },
      }
    }

    case constants.FIND_PORT_OUT_INFO_FAILED: {
      return {
        ...state,
        portOuts: {
          ...state.portOuts,
          [payload.msisdn]: {
            loading: false,
            error: payload.error,
          },
        },
      }
    }

    case constants.SET_SVA_ATTEMPTED: {
      return {
        ...state,
        disableDataBonus: {
          ...state.disableDataBonus,
          [payload.msisdn]: {
            loading: true,
            success: false,
            error: null,
            previousStatus: getSetSvaPreviousStatus(state, payload.msisdn),
          },
        },
      }
    }

    case constants.SET_SVA_SUCCEEDED: {
      return {
        ...state,
        disableDataBonus: {
          ...state.disableDataBonus,
          [payload.msisdn]: {
            loading: false,
            success: true,
            error: false,
            previousStatus: payload.status,
          },
        },
      }
    }

    case constants.SET_SVA_FAILED: {
      return {
        ...state,
        disableDataBonus: {
          ...state.disableDataBonus,
          [payload.msisdn]: {
            loading: false,
            success: false,
            error: payload.error,
            previousStatus: getSetSvaPreviousStatus(state, payload.msisdn),
          },
        },
      }
    }

    case constants.SET_SVA_RESET: {
      return {
        ...state,
        disableDataBonus: {
          ...state.disableDataBonus,
          [payload.msisdn]: {
            loading: false,
            success: false,
            error: false,
            previousStatus: getSetSvaPreviousStatus(state, payload.msisdn),
          },
        },
      }
    }

    case constants.DISABLE_SVA_SMARTHOME_ATTEMPTED: {
      return {
        ...state,
        disableDataSva: {
          ...state.disableDataSva,
          smarthome: {
            ...initialStateService,
            msisdn: payload.msisdn,
            loading: true,
          },
        },
      }
    }

    case constants.DISABLE_SVA_SMARTHOME_SUCCEEDED: {
      return {
        ...state,
        disableDataSva: {
          ...state.disableDataSva,
          smarthome: {
            ...initialStateService,
            msisdn: payload.msisdn,
            success: true,
          },
        },
      }
    }

    case constants.DISABLE_SVA_SMARTHOME_FAILED: {
      return {
        ...state,
        disableDataSva: {
          ...state.disableDataSva,
          smarthome: {
            ...initialStateService,
            msisdn: payload.msisdn,
            error: payload.error,
          },
        },
      }
    }

    case constants.DISABLE_SVA_SMARTHOME_RESET: {
      return {
        ...state,
        disableDataSva: {
          ...state.disableDataSva,
          smarthome: {
            ...initialStateService,
            msisdn: payload.msisdn,
            error: null,
          },
        },
      }
    }

    case constants.DISABLE_SVA_HOMEGO_ATTEMPTED: {
      return {
        ...state,
        disableDataSva: {
          ...state.disableDataSva,
          homego: {
            ...initialStateService,
            loading: true,
          },
        },
      }
    }
    case constants.DISABLE_SVA_HOMEGO_SUCCEEDED: {
      return {
        ...state,
        disableDataSva: {
          ...state.disableDataSva,
          homego: {
            ...initialStateService,
            success: true,
          },
        },
      }
    }
    case constants.DISABLE_SVA_HOMEGO_FAILED: {
      return {
        ...state,
        disableDataSva: {
          ...state.disableDataSva,
          homego: {
            ...initialStateService,
            error: get(payload, 'error.endpointError') || payload.error,
          },
        },
      }
    }
    case constants.DISABLE_SVA_HOMEGO_RESET: {
      return {
        ...state,
        disableDataSva: {
          ...state.disableDataSva,
          homego: {
            ...initialStateService,
          },
        },
      }
    }

    case constants.FIND_DOCUMENT_CDCT_WORKORDER_ID_ATTEMPTED: {
      return {
        ...state,
        cdctWorkOrderIds: {
          ...state.cdctWorkOrderIds,
          [payload.subscriptionId]: {
            ...initialStateService,
            loading: true,
          },
        },
      }
    }
    case constants.FIND_DOCUMENT_CDCT_WORKORDER_ID_SUCCEEDED: {
      return {
        ...state,
        cdctWorkOrderIds: {
          ...state.cdctWorkOrderIds,
          [payload.subscriptionId]: {
            ...initialStateService,
            success: true,
            data: payload.data,
          },
        },
      }
    }
    case constants.FIND_DOCUMENT_CDCT_WORKORDER_ID_FAILED: {
      return {
        ...state,
        cdctWorkOrderIds: {
          ...state.cdctWorkOrderIds,
          [payload.subscriptionId]: {
            ...initialStateService,
            error: payload.error,
          },
        },
      }
    }

    default:
      return state
  }
}

export default reducer
