import PropTypes from 'prop-types'
import { get, values } from 'lodash'

import { Box, Typography, Divider } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import { TextStyles } from 'utils/text'
import IconStatus from './IconStatus'
import DoubleLineItemCard from '../card360/DoubleLineItemCard'

import { LOGISTIC_STEPS_ICONS, MAP_STATUS_CONFIG } from './LogisticStatus.helpers'

const useStyles = makeStyles(theme => ({
  'logistic-status': {
    borderRadius: '4px',
    backgroundColor: theme.palette.global.gray_light,

    '& .icons-wrapper': {
      position: 'relative',
      margin: '15px 30px 15px 15px',
    },

    '& hr': {
      backgroundColor: theme.palette.global.gray,
      height: '1.5px',
      left: 0,
      right: 0,
      bottom: '50%',
    },

    '& .date-text': {
      padding: '5px 24px 9px 24px',
      lineHeight: '14px',
      fontSize: '12px',
    },
  },
}))

function LogisticStatus({ statusCode, date }) {
  const classes = useStyles()

  const config = get(MAP_STATUS_CONFIG, statusCode, {})
  const secondaryText = `${get(config, 'description', '-')}`
  // const showHistoricLink = {
  //   label: 'Ver histórico',
  //   disabled: true,
  //   action: () => {
  //     return true
  //   },
  // }

  return (
    <Box
      display="flex"
      alignItems="center"
      m="18px 24px"
      p="13px 0px"
      className={classes['logistic-status']}>
      <Box display="flex" alignItems="center" flex={2} className="text-wrapper">
        <DoubleLineItemCard
          padding="9px 24px 0px 24px"
          primaryText="Estado logístico:"
          secondaryText={secondaryText}
        />
        <Typography
          {...TextStyles.labelDark({
            className: 'date-text',
          })}>
          {date}
        </Typography>
        {/* <LineItemCard link={showHistoricLink} /> */}
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        flex={3}
        className="icons-wrapper">
        <Divider absolute />
        {values(LOGISTIC_STEPS_ICONS).map(step => (
          <IconStatus key={JSON.stringify(step)} icon={step} status={get(config.steps, step)} />
        ))}
      </Box>
    </Box>
  )
}

LogisticStatus.propTypes = {
  statusCode: PropTypes.string,
  date: PropTypes.string,
}

export default LogisticStatus
