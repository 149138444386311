import PropTypes from 'prop-types'
import { get } from 'lodash'
import { Divider, Typography, Box } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/styles'
import { TextStyles } from 'utils/text'
import { Card } from 'modules/ui'
import { TitleLineItemCard, LineItemCard, OutlinedDivider } from 'components/ui'
import { HighLightFrame } from 'modules/ui/components'
import { formatFee } from 'utils'

import {
  formatRemainingMin,
  formatRangeEndMin,
  formatConsumptionMin,
  formatRangeStartMin,
} from './helpers'
import { ConsumptionCounter } from './ConsumptionCounter'

const useStyles = makeStyles({
  'usages-fixed-card': {
    '&& .card-content': {
      padding: '0px 0px',
    },

    '& .usage-card-consumption-title': {
      '& .primary-text': {
        textTransform: 'none',
      },
    },

    '& .otherExpenses': {
      position: 'relative',
    },
  },
})

export const UsagesFixedCard = ({ data, highLigthedUI, isPrepaid, taxNeeded }) => {
  const classes = useStyles()

  const {
    f2FVoiceTotalUsage,
    f2FVoiceTotalAmount,
    f2MVoiceTotalUsage,
    f2MVoiceTotalAmount,
    f2FHasUnlimited,
    f2MHasUnlimited,
    fixed,
  } = data

  const PADDING_ELEMENT = '9px 24px'

  const themeContext = useTheme()

  return (
    <Card
      className={`${classes['usages-fixed-card']} highLightable ${get(
        highLigthedUI,
        'otherExpenses',
      ) && 'active'}`}
      title="Contadores y consumo"
      data-hook="card-Contadores y consumo"
      withShadow
      minHeight="288px">
      <div className="">
        <ConsumptionCounter
          className="p-t-12"
          title="Contador Llamadas a Fijo"
          data={{
            totalUsage: f2FVoiceTotalUsage,
            totalAmount: f2FVoiceTotalAmount || 0,
          }}
          formatRangeStart={formatRangeStartMin}
          formatRangeEnd={formatRangeEndMin}
          formatRemaining={formatRemainingMin}
          formatConsumption={formatConsumptionMin}
          hasUnlimited={f2FHasUnlimited}
        />
        <Box p={PADDING_ELEMENT}>
          <OutlinedDivider />
        </Box>

        <ConsumptionCounter
          className=""
          title="Contador Llamadas a Móvil"
          data={{
            totalUsage: f2MVoiceTotalUsage,
            totalAmount: f2MVoiceTotalAmount || 0,
          }}
          formatRangeStart={formatRangeStartMin}
          formatRangeEnd={formatRangeEndMin}
          formatRemaining={formatRemainingMin}
          formatConsumption={formatConsumptionMin}
          hasUnlimited={f2MHasUnlimited}
        />

        <Box p={PADDING_ELEMENT}>
          <OutlinedDivider />
        </Box>

        <div
          data-hook={get(highLigthedUI, 'otherExpenses') && 'other-expenses-lightframe'}
          className="p-y-4 flex flex-column flex-grow otherExpenses">
          <HighLightFrame />
          <TitleLineItemCard
            className="usage-card-consumption-title"
            primaryText="Consumo fuera de tarifa"
            isHeader
          />
          <LineItemCard
            primaryText="Llamadas"
            secondaryText={formatFee(fixed.callsConsumedWithTaxes)}
            fieldsApart
          />
          <LineItemCard
            primaryText="Llamadas Premium"
            secondaryText={formatFee(fixed.premiumCallsConsumedWithTaxes)}
            fieldsApart
          />
          <LineItemCard
            primaryText="Llamadas Internacionales"
            secondaryText={formatFee(fixed.internationalCallsConsumedWithTaxes)}
            fieldsApart
          />
          {isPrepaid && (
            <LineItemCard
              primaryText="Resto llamadas prepago"
              secondaryText={formatFee(fixed.consumedCallsWithoutExtrasWithTaxes)}
              fieldsApart
            />
          )}
          <LineItemCard
            primaryText="Otros"
            secondaryText={formatFee(fixed.othersConsumedWithTaxes)}
            fieldsApart
          />

          <Box m="8px 24px 0">
            <Divider style={{ backgroundColor: themeContext.palette.global.gray_dark }} />
          </Box>

          <LineItemCard
            className="totals "
            secondaryTextDataHook="usages-card-other-expenses"
            primaryText="Gasto total fuera de tarifa"
            secondaryText={`${formatFee(
              fixed.totalExtrasFixedWithTaxes + fixed.othersConsumedWithTaxes,
            )}`}
            fieldsApart
          />
          {taxNeeded && (
            <Typography
              {...TextStyles.labelDark({
                className: 'xs-text p-y-0 p-x-24',
              })}>
              (Impuestos incluídos)
            </Typography>
          )}
        </div>
      </div>
    </Card>
  )
}

UsagesFixedCard.propTypes = {
  data: PropTypes.shape({
    f2FVoiceTotalUsage: PropTypes.number,
    f2FVoiceTotalAmount: PropTypes.number,
    f2MVoiceTotalUsage: PropTypes.number,
    f2MVoiceTotalAmount: PropTypes.number,
    totalExtras: PropTypes.number,
    f2FHasUnlimited: PropTypes.bool,
    f2MHasUnlimited: PropTypes.bool,
    fixed: PropTypes.object,
  }),
  taxNeeded: PropTypes.bool,
  highLigthedUI: PropTypes.object,
  isPrepaid: PropTypes.bool,
}
