import PropTypes from 'prop-types'

import { Box, Card, CardContent } from '@material-ui/core'

import { TitleSidebar, TextSidebar } from 'components/ui'

export const DeviceTypeAndChannelInfo = ({ deviceType, saleChannel }) => (
  <Box
    display="flex"
    pt="20px"
    justifyContent="center"
    data-hook="device-renewal-sidebar-device-type">
    <Card style={{ width: '90%', borderRadius: '10px', margin: '20px 0 8px 0' }}>
      <CardContent>
        <div>
          <TitleSidebar>Tipo de dispositivo</TitleSidebar>
          <TextSidebar dataHook="renewal-sidebar-device-type">{deviceType}</TextSidebar>
        </div>

        <div>
          <TitleSidebar>Catálogo de venta</TitleSidebar>
          <TextSidebar dataHook="renewal-sidebar-sale-channel">{saleChannel}</TextSidebar>
        </div>
      </CardContent>
    </Card>
  </Box>
)

DeviceTypeAndChannelInfo.propTypes = {
  deviceType: PropTypes.string,
  saleChannel: PropTypes.string,
}
