import { createStructuredSelector } from 'reselect'

import { selectChannelQueryParam } from 'modules/Router/store/router.selectors'
import { selectIsSVAAgileListLoading, selectSVAAgileListError } from 'modules/SVAs/store'
import {
  selectors as customer360Selectors,
  selectIsSelfEmployed,
} from 'services/customer-360/selectors'
import { selectBasketRequestLoading } from 'modules/NewClientSales/store/selectors/index'

import {
  selectAgileTvSvaWithStb,
  selectFetchAddAgileTvAllowedIsLoading,
  selectFetchAddAgileTvAllowedError,
  selectFetchAddAgileTvAllowedIsSuccess,
  selectAddAgileTvOrderIsLoading,
  selectAddAgileTvOrderError,
  selectAddAgileTvOrderIsSuccess,
} from '../../store/add-agiletv.selectors'

export const addAgileTvSelectors = createStructuredSelector({
  channel: selectChannelQueryParam,
  validationIsLoading: selectFetchAddAgileTvAllowedIsLoading,
  isLoading: selectIsSVAAgileListLoading,
  validationError: selectFetchAddAgileTvAllowedError,
  validationIsSuccess: selectFetchAddAgileTvAllowedIsSuccess,
  error: selectSVAAgileListError,
  agileTvSva: selectAgileTvSvaWithStb,
  documentId: customer360Selectors.getIdentificationId,
  documentType: customer360Selectors.getIdentificationType,
  customerEmail: customer360Selectors.getEmail,
  isSelfEmployed: selectIsSelfEmployed,
  isSaving: selectAddAgileTvOrderIsLoading,
  savingError: selectAddAgileTvOrderError,
  savingIsSuccess: selectAddAgileTvOrderIsSuccess,
  emailValidationIsLoading: selectBasketRequestLoading,
})
