import { Header } from 'modules/Header'
import { InputDocumentType } from 'modules/Layout/components/index'
import PropTypes from 'prop-types'
import { useMemo } from 'react'

import { CoverageModal } from '../CoverageModal'
import { NewSaleButton } from '../SaleButtons/NewSaleButton'

export function VistaDashboardHeader({
  isLoading,
  onSearch,
  inputHighlighted,
  documentId,
  className,
  gotCoverageData,
}) {
  const mainContent = (
    <InputDocumentType
      isLoading={isLoading}
      inputHighlighted={inputHighlighted}
      onSubmit={onSearch}
      value={documentId}
    />
  )

  const widgets = useMemo(() => {
    const widgetsContent = [
      {
        key: 'coverageModal',
        component: () => <CoverageModal gotCoverageData={gotCoverageData} />,
      },
    ]

    widgetsContent.push({
      key: 'newClientSale',
      component: () => <NewSaleButton />,
    })

    return widgetsContent
  }, [])

  return (
    <Header
      icon="menu"
      title="MASCare"
      mainContent={mainContent}
      widgets={widgets}
      className={className}
      iconText="Menú"
    />
  )
}

VistaDashboardHeader.propTypes = {
  isLoading: PropTypes.bool,
  onSearch: PropTypes.func,
  className: PropTypes.string,
  documentId: PropTypes.string,
  inputHighlighted: PropTypes.bool,
  gotCoverageData: PropTypes.func,
}
