import uuidv4 from 'uuid'
import { set, cloneDeep } from 'lodash'
import { basketActionTypes } from '../actions/basket.actions'
import { Basket as initialState, MobileLineOffer, LandlineOffer, Terminal } from '../models'

function setTerminalReservations(state, { terminals }) {
  const basket = cloneDeep(state)

  terminals.forEach(({ formikFieldName, reservationId }) => {
    set(basket, formikFieldName, reservationId)
  })

  return basket
}

function setTerminal(state, payload, isLoading = false, isError = false) {
  const isExtra = payload.lineType.includes('extra')
  const index = isExtra ? payload.lineType.match(/\d+/)[0] : null
  const data = payload.data || {}
  const terminalId = data.id || ''
  const isStockError = !!data.isStockError
  // if the line is not extra just replace the object values
  if (!isExtra) {
    return {
      ...state,
      [payload.lineType]: {
        ...state[payload.lineType],
        terminal: {
          ...Terminal,
          ...state[payload.lineType].terminal,
          id: terminalId,
          reservationId: '',
          details: {
            ...data,
            isLoading,
            isError,
            isStockError,
          },
        },
      },
    }
  }
  // get the correspondent extra line
  const extraLine = {
    ...state.extraMobileLinesOffers[index],
  }
  // set the new values for the line
  const newValue = {
    ...extraLine,
    terminal: {
      ...Terminal,
      ...extraLine.terminal,
      id: terminalId,
      reservationId: '',
      details: {
        ...data,
        isLoading,
        isError,
        isStockError,
      },
    },
  }
  // make a copy of the original array
  const newExtraMobileLinesOffers = [...state.extraMobileLinesOffers]
  // replace the old line with the new one
  newExtraMobileLinesOffers[index] = newValue

  return {
    ...state,
    extraMobileLinesOffers: [...newExtraMobileLinesOffers],
  }
}
export const basketReducer = (
  state = { ...cloneDeep(initialState), segment: 'residential' },
  action,
) => {
  const { type, payload } = action
  switch (type) {
    case basketActionTypes.SET_ORDER: {
      const { order } = payload
      return {
        ...state,
        order,
      }
    }
    case basketActionTypes.SET_BASKET_DATA: {
      const { order } = state
      return {
        ...state,
        ...payload,
        segment: state.segment,
        landlineOffer: payload.landlineOffer
          ? {
              ...payload.landlineOffer,
              id: payload.landlineOffer.id || `main-line-${uuidv4()}`,
            }
          : state.landlineOffer,
        mainMobileLineOffer: payload.mainMobileLineOffer
          ? {
              ...payload.mainMobileLineOffer,
              id: payload.mainMobileLineOffer.id || `main-mobile-${uuidv4()}`,
            }
          : state.mainMobileLineOffer,
        extraMobileLinesOffers: payload.extraMobileLinesOffers
          ? [
              ...payload.extraMobileLinesOffers.map(i => {
                if (!i.id) {
                  return {
                    ...i,
                    id: `extra-line-${uuidv4()}`,
                  }
                }
                return i
              }),
            ]
          : [],
        order,
      }
    }

    case basketActionTypes.SET_BASKET_TECHNOLOGY_DATA: {
      const { technology } = payload
      return {
        ...state,
        account: {
          ...state.account,
          technology,
        },
        mainMobileLineOffer: MobileLineOffer,
        extraMobileLinesOffers: [],
        landlineOffer: {
          ...LandlineOffer,
          signUpType: state.landlineOffer.signUpType,
          isPortability: state.landlineOffer.isPortability,
        },
      }
    }

    case basketActionTypes.SET_BASKET_LANDLINE_OFFER: {
      return {
        ...state,
        landlineOffer: {
          ...state.landlineOffer,
          ...payload,
        },
      }
    }

    case basketActionTypes.SET_BASKET_ACCOUNT_DATA: {
      return {
        ...state,
        account: {
          ...state.account,
          ...payload,
        },
      }
    }

    case basketActionTypes.SET_SHIPPING: {
      return {
        ...state,
        shipping: {
          ...payload,
        },
      }
    }

    case basketActionTypes.FETCH_TERMINAL_DETAILS: {
      return setTerminal(state, payload, true)
    }

    case basketActionTypes.FETCH_TERMINAL_DETAILS_SUCCESS: {
      return setTerminal(state, payload)
    }

    case basketActionTypes.FETCH_TERMINAL_DETAILS_ERROR: {
      return setTerminal(state, payload, false, true)
    }

    case basketActionTypes.SET_TERMINAL_RESERVATIONS: {
      return setTerminalReservations(state, payload)
    }

    case basketActionTypes.SET_TERMINAL_PAYMENT: {
      if (!payload.isExtra) {
        return {
          ...state,
          [payload.lineType]: {
            ...state[payload.lineType],
            terminal: {
              ...state[payload.lineType].terminal,
              paymentType: payload.data,
            },
          },
        }
      }
      // get the correspondent extra line
      const extraLine = { ...state.extraMobileLinesOffers[payload.index] }
      // set the new values for the line
      const newValue = {
        ...extraLine,
        terminal: { ...extraLine.terminal, paymentType: payload.data },
      }
      // make a copy of the original array
      const newExtraMobileLinesOffers = [...state.extraMobileLinesOffers]
      // replace the old line with the new one
      newExtraMobileLinesOffers[payload.index] = newValue

      return {
        ...state,
        extraMobileLinesOffers: [...newExtraMobileLinesOffers],
      }
    }

    case basketActionTypes.RESET_TERMINAL_DETAILS: {
      return setTerminal(state, payload)
    }

    case basketActionTypes.RESET_ALL_TERMINALS: {
      const extraLinesCopy = [...state.extraMobileLinesOffers]
      const extraLinesWithoutTerminals = extraLinesCopy.map(elem => ({ ...elem, terminal: {} }))
      return {
        ...state,
        mainMobileLineOffer: {
          ...state.mainMobileLineOffer,
          terminal: { ...Terminal },
        },
        extraMobileLinesOffers: [...extraLinesWithoutTerminals],
      }
    }

    case basketActionTypes.SET_MAIN_MOBILE_LINE_TARIFF: {
      return {
        ...state,
        mainMobileLineOffer: {
          ...state.mainMobileLineOffer,
          tariff: payload,
        },
      }
    }

    case basketActionTypes.ADD_ADDITIONAL_LINE: {
      const { line } = payload
      return {
        ...state,
        extraMobileLinesOffers: [...state.extraMobileLinesOffers, line],
      }
    }

    case basketActionTypes.REMOVE_ADDITIONAL_LINE: {
      const id = payload
      return {
        ...state,
        extraMobileLinesOffers: state.extraMobileLinesOffers.filter(i => i.id !== id),
      }
    }

    case basketActionTypes.SET_SELECTED_ADDRESS_TYPE: {
      return {
        ...state,
        [payload.type]: {
          ...state[payload.type],
          selectedAddressType: payload.value,
        },
      }
    }

    case basketActionTypes.SET_TERMINAL_SHIPPING: {
      const otherAddress = payload.otherAddress
        ? {
            otherAddress: {
              address: `${payload.otherAddress.streetType} ${payload.otherAddress.street}, ${payload.otherAddress.number}`,
              flat: payload.otherAddress.floor,
              door: payload.otherAddress.Hand,
              otherInfo: '',
              ...payload.otherAddress,
            },
          }
        : {}
      return {
        ...state,
        terminalShipping: {
          ...state.terminalShipping,
          ...otherAddress,
        },
      }
    }

    case basketActionTypes.RESET_DELIVERY_POINT: {
      return {
        ...state,
        terminalShipping: {
          ...state.terminalShipping,
          deliveryPoint: {
            ...state.terminalShipping.deliveryPoint,
            selectedPoint: {},
          },
        },
      }
    }

    case basketActionTypes.SET_ADDRESS: {
      const { addressType, flat, door, otherInfo, Hand, town, number, ...rest } = payload

      const address = !state[addressType].otherAddress.address
        ? `${rest.streetType} ${rest.street}, ${number}`
        : state[addressType].otherAddress.address
      const details =
        rest.streetType !== state[addressType].otherAddress.streetType
          ? {
              ...state[addressType].otherAddress,
              address,
              city: town,
              town,
              number,
              ...rest,
            }
          : { ...state[addressType].otherAddress, flat, door, otherInfo, number }

      return {
        ...state,
        [addressType]: {
          ...state[addressType],
          otherAddress: {
            ...details,
          },
        },
      }
    }

    case basketActionTypes.CLEAN_OTHER_ADDRESS: {
      return {
        ...state,
        [payload]: {
          ...state[payload],
          otherAddress: cloneDeep(initialState[payload].otherAddress),
        },
      }
    }

    case basketActionTypes.SET_SALE_SEGMENT: {
      return {
        ...state,
        segment: payload,
      }
    }

    case basketActionTypes.SET_IBAN: {
      return {
        ...state,
        billing: {
          ...state.billing,
          ibanNumber: payload.ibanNumber,
        },
      }
    }

    case basketActionTypes.SET_CUSTOMER_INFORMATION: {
      const { accountId, billingId, segment } = payload
      return {
        ...state,
        account: {
          ...state.account,
          accountId,
          segment,
        },
        billing: {
          ...state.billing,
          billingAccountId: billingId,
        },
      }
    }

    case basketActionTypes.SET_REQUEST_LOADING: {
      const { loading } = payload
      return {
        ...state,
        requestLoading: loading,
      }
    }

    case basketActionTypes.SET_ORDER_TYPE: {
      const { orderType } = payload
      return {
        ...state,
        orderType,
      }
    }

    case basketActionTypes.SET_BASKET_TARIFF: {
      return {
        ...state,
        ...payload,
      }
    }

    case basketActionTypes.RESET_MEMBER_GET_MEMBER_PROMO: {
      return {
        ...state,
        mainMobileLineOffer: {
          ...state.mainMobileLineOffer,
          memberGetMemberPromo: MobileLineOffer.memberGetMemberPromo,
        },
      }
    }

    default:
      return state
  }
}
