import { useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { pick, concat, get, isEmpty, remove } from 'lodash'
import styled from 'styled-components'

import { Typography, Box, Divider } from '@material-ui/core'
import { TextStyles } from 'utils/text'

import { ActionAdvice, Button, ButtonLink, Modal, SpinnerCenter } from 'components/ui'

import { permissions, selectCanIDO, selectIsPosOrMasProximo } from 'modules/Permissions'

import {
  findBarringsStatusAction,
  selectIsLoadingSetBarringStatus,
  selectErrorMessageSetBarringStatus,
  BarringGroups,
  getBarringGroupStr,
} from 'modules/barrings'
import { selectURLProductId } from 'services/customer-products'
import { Barring } from './Barring'
import { BarringsSummary } from './BarringsSummary'
import { SetBarringsResult } from './SetBarringsResult'

export const TabServicesBarrings = styled(
  ({
    className,
    sortedBarrings,
    isBarringsStatusLoading,
    hasBarringsStatusErrors,
    showErrorMsg,
    showBarringOnGoingMsg,
  }) => {
    const dispatch = useDispatch()
    const productId = useSelector(selectURLProductId)
    const [barringsWithChanges, setBarringsWithChanges] = useState([])
    const [openBarringsSummary, setOpenBarringsSummary] = useState(false)
    const [resetBarringStatus, setResetBarringStatus] = useState('')
    const [showBarringStatusResult, setShowBarringStatusResult] = useState(false)

    const isSetBarringStatusLoading = useSelector(selectIsLoadingSetBarringStatus(productId))
    const setBarringStatusError = useSelector(selectErrorMessageSetBarringStatus(productId))

    const removeBarringFromSummary = barringId => {
      setBarringsWithChanges(remove(barringsWithChanges, b => get(b, 'id') !== barringId))
      setResetBarringStatus(barringId)
    }

    const addBarringToSummary = barring => {
      setBarringsWithChanges(concat(barringsWithChanges, barring))
      setResetBarringStatus('')
    }

    const resetChanges = () => {
      setBarringsWithChanges([])
      setResetBarringStatus('all')
    }

    const refreshBarrings = () => {
      return dispatch(findBarringsStatusAction(productId))
    }

    const hasBarringPermissions = useSelector(selectCanIDO(permissions.barring_ops_access.id))

    const isPosOrMasProximo = useSelector(selectIsPosOrMasProximo)

    const barringGroupsInFirstColumn = pick(sortedBarrings, [
      BarringGroups.customer.id,
      ...(isPosOrMasProximo ? [] : [BarringGroups.atc.id, BarringGroups.debt.id]),
    ])

    const barringGroupsInSecondColumn = pick(
      sortedBarrings,
      isPosOrMasProximo
        ? []
        : [BarringGroups.limit.id, BarringGroups.risk.id, BarringGroups.product.id],
    )

    return (
      <Box position="relative">
        <Box display="flex" flexDirection="column" className={className}>
          <div
            className={hasBarringPermissions || isPosOrMasProximo ? 'flex' : 'edit-disabled flex'}
            data-hook="barrings-status">
            {isBarringsStatusLoading && <SpinnerCenter />}
            {!isEmpty(hasBarringsStatusErrors) && !isBarringsStatusLoading && (
              <Typography
                {...TextStyles.subtitle2Dark({
                  className: 'p-x-24 p-y-12',
                })}>
                No existe información para este cliente
              </Typography>
            )}
            {!isBarringsStatusLoading && isEmpty(hasBarringsStatusErrors) && (
              <>
                <div className="barring-container p-y-12" data-hook="activating-barring-advise">
                  {(showErrorMsg || showBarringOnGoingMsg) && (
                    <>
                      <ActionAdvice
                        className="p-y-12 p-x-24 action-div"
                        type="info"
                        block
                        message="Se han registrado los cambios, para comprobar su estado, por favor, pulsa en el botón actualizar"
                      />
                      <ButtonLink className="refresh-barring-button" onClick={refreshBarrings}>
                        Actualizar
                      </ButtonLink>
                    </>
                  )}

                  {Object.entries(barringGroupsInFirstColumn).map(
                    ([group, barrings], idx, allEle) => {
                      const isLastElement = idx === allEle.length - 1

                      return (
                        <div key={group}>
                          <Typography
                            {...TextStyles.subtitle2Link({
                              className: 'text-style',
                            })}>
                            <span>{getBarringGroupStr(group)}</span>
                          </Typography>

                          {barrings.map(barring => (
                            <Barring
                              key={barring.id}
                              barring={barring}
                              showBarringOnGoingMsg={showBarringOnGoingMsg}
                              removeBarringFromSummary={removeBarringFromSummary}
                              addBarringToSummary={addBarringToSummary}
                              resetBarringStatus={resetBarringStatus}
                              barringGroup={group}
                            />
                          ))}

                          {!isLastElement && (
                            <div className="m-y-16">
                              <Divider />
                            </div>
                          )}
                        </div>
                      )
                    },
                  )}
                </div>

                <Divider orientation="vertical" />

                <div className="barring-container p-y-12">
                  {Object.entries(barringGroupsInSecondColumn).map(
                    ([group, barrings], idx, allEle) => {
                      const isLastElement = idx === allEle.length - 1

                      return (
                        <div key={group}>
                          <Typography
                            {...TextStyles.subtitle2Link({
                              className: 'text-style',
                            })}>
                            <span>{getBarringGroupStr(group)}</span>
                          </Typography>

                          {barrings.map(barring => (
                            <Barring
                              key={barring.id}
                              barring={barring}
                              showBarringOnGoingMsg={showBarringOnGoingMsg}
                              removeBarringFromSummary={removeBarringFromSummary}
                              addBarringToSummary={addBarringToSummary}
                              resetBarringStatus={resetBarringStatus}
                              barringGroup={group}
                            />
                          ))}

                          {!isLastElement && (
                            <div className="m-y-16">
                              <Divider />
                            </div>
                          )}
                        </div>
                      )
                    },
                  )}
                </div>
              </>
            )}
          </div>

          {!isEmpty(barringsWithChanges) && (
            <>
              <Divider />
              <div className="p-y-24 p-x-24">
                <Button
                  className="button"
                  onClick={() => setOpenBarringsSummary(true)}
                  data-hook="confirm-barrings-btn"
                  disabled={false}>
                  Confirmar cambios en restricciones
                </Button>

                <Button secondary onClick={resetChanges}>
                  Cancelar
                </Button>
              </div>
            </>
          )}
        </Box>

        <Modal
          data-hook="barrings-summary-modal"
          isOpen={openBarringsSummary}
          title="Cambios en Restricciones"
          onClose={() => setOpenBarringsSummary(false)}>
          <BarringsSummary
            barringsWithChanges={barringsWithChanges}
            showSummary={setOpenBarringsSummary}
            removeBarringFromSummary={removeBarringFromSummary}
            setResetBarringStatus={setResetBarringStatus}
            setBarringsWithChanges={setBarringsWithChanges}
            showBarringStatusResult={showBarringStatusResult}
            setShowBarringStatusResult={setShowBarringStatusResult}
          />
        </Modal>

        {isSetBarringStatusLoading && <SpinnerCenter absolute style={{ top: 0 }} />}
        {showBarringStatusResult && !isSetBarringStatusLoading && (
          <SetBarringsResult
            error={setBarringStatusError}
            showBarringStatusResult={showBarringStatusResult}
            setShowBarringStatusResult={setShowBarringStatusResult}
          />
        )}
      </Box>
    )
  },
)`
  min-height: 474px;
  .edit-disabled {
    opacity: 0.6;
    pointer-events: none;
  }
  .barring-container {
    width: 50%;
  }
  .text-style {
    padding: 9px 24px;
    color: #2b67bb;
  }
  .action-div > div:first-child {
    background: ${props => get(props, 'theme.palette.bluePalette.blue_50')};
  }
  .action-div > div:last-child {
    padding: 0;
  }
  .action-div div {
    max-width: inherit;
  }
  .refresh-barring-button {
    padding: 0 24px;
  }
  .button {
    margin-right: 16px;
  }
`

TabServicesBarrings.propTypes = {
  sortedBarrings: PropTypes.object,
  isBarringsStatusLoading: PropTypes.bool,
  hasBarringsStatusErrors: PropTypes.object,
}
