import styled from 'styled-components'

import PortabilityDatePicker from './PortabilityDatePicker'

export const StyledPortabilityDatePicker = styled(PortabilityDatePicker)`
  padding-bottom: 24px;
  && .error {
    font-size: 12px;
    font-weight: 400;
    margin-left: 8px;
    color: ${props => props.theme.palette.brand.alert};
  }
`
