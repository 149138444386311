import { constants } from './constants'

const initialState = {
  data: null,
  error: null,
  errorVipType: null,
  loading: false,
}

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case constants.FIND_INIT: {
      return { ...initialState, loading: true }
    }
    case constants.FIND_SUCCESS: {
      return { ...state, data: payload.data, loading: false }
    }
    case constants.FIND_FAILED: {
      return {
        ...state,
        error: payload.error,
        loading: false,
      }
    }
    case constants.RESET: {
      return {
        ...initialState,
      }
    }

    // THIS IS DISPOSABLE
    case constants.SET_SUBSCRIPTION: {
      return {
        ...state,
        data: {
          ...state.data,
          subscription: payload,
        },
      }
    }

    case constants.FETCH_VIP_TYPE: {
      return {
        ...state,
        errorVipType: null,
      }
    }

    case constants.FETCH_VIP_TYPE_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          dataClient: {
            ...state.data.dataClient,
            vipType: payload.data.vipClassification,
          },
        },
        errorVipType: null,
      }
    }

    case constants.FETCH_VIP_TYPE_FAILED: {
      return {
        ...state,
        data: {
          ...state.data,
          dataClient: {
            ...state.data.dataClient,
            vipType: null,
          },
        },
        errorVipType: payload.error,
      }
    }

    default:
      return state
  }
}
