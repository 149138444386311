import { SIGNUP_TYPE_NEWLINE, SIGNUP_TYPE_PORTABILITY } from 'services/global-constants'
import { formatTariffName, formatBasicTariff } from 'modules/tariffs'
import { getTerminalWithComputedPrices } from 'modules/Terminals'
import { addMonthlyFeeWithTax } from 'modules/Packages'
import { get, head } from 'lodash'

import { getMobileTariff } from 'modules/Lines'

export function signUpTypeText(signUpType) {
  return signUpType === SIGNUP_TYPE_NEWLINE ? 'Alta Nueva' : 'Portabilidad'
}

export function formatUserName(line, mainUser) {
  const company = line.otherUser.documentType === 'CIF' ? line.otherUser.companyName : ''
  const titularName =
    line.isSameUser === 'no'
      ? `${line.otherUser.name} ${line.otherUser.surname1} ${
          line.otherUser.surname2 ? line.otherUser.surname2 : ''
        }`
      : `El mismo (${mainUser})`

  return company || titularName
}

export function getLandLineNumber(landlineOffer, is2p) {
  const provisionedFixedNumber = is2p ? landlineOffer.newFixedNumber : landlineOffer.newNumber.value
  return landlineOffer.signUpType === SIGNUP_TYPE_PORTABILITY
    ? landlineOffer.lineNumber
    : provisionedFixedNumber
}

export function getProvisionedMobileNumber(landlineOffer, is2p) {
  return is2p ? landlineOffer.newNumber.value : null
}

export function mapToMobileLineSummary(
  line,
  tax,
  mainUser,
  landlineTariff,
  isMobileOnly,
  isElFijo,
) {
  const mobileTitle = isElFijo
    ? `Fijo: ${line.tariff.name}`
    : `Móvil: ${getMobileTariff(landlineTariff)}`
  return {
    title: isMobileOnly ? `Móvil Cabecera: ${line.tariff.name}` : mobileTitle,
    mobileOffer: {
      ...line,
      selectedPackages: addMonthlyFeeWithTax(line.selectedPackages, tax),
    },
    signUpTypeText: signUpTypeText(line.signUpType),
    userName: formatUserName(line, mainUser),
    terminal:
      line.terminal && line.terminal.id ? getTerminalWithComputedPrices(line.terminal, tax) : null,
    tax,
  }
}

export function formatMobileTariffName(tariff) {
  const price =
    tariff.formatedDiscount && get(tariff.formatedDiscount, 'duration')
      ? `- ${get(head(tariff.discountPrice), 'fee')} €/mes (${tariff.priceWithTax} €/mes)`
      : `- ${tariff.priceWithTax} €/mes`

  return `${tariff.name} ${
    get(tariff.formatedDiscount, 'duration') === 0
      ? `${get(tariff.formatedDiscount, 'price')} €/mes`
      : price
  }`
}

export function mapToExtraMobileSummary(extraLines, tax, mainUser, isMobileOnly) {
  return extraLines.map(extra => {
    const tariff = formatBasicTariff(extra.tariff, tax)
    return {
      tariff,
      formattedTariffName: isMobileOnly ? formatMobileTariffName(tariff) : formatTariffName(tariff),

      title: `Móvil Adicional: ${extra.tariff.name}`,
      mobileOffer: {
        ...extra,
        selectedPackages: addMonthlyFeeWithTax(extra.selectedPackages, tax),
      },
      signUpTypeText: signUpTypeText(extra.signUpType),
      userName: formatUserName(extra, mainUser),
      tax,
      terminal:
        extra.terminal && extra.terminal.id
          ? getTerminalWithComputedPrices(extra.terminal, tax)
          : null,
    }
  })
}

export function formatCrossTariffPromoToDiscountMessage(crossSellTariffDiscount) {
  if (!crossSellTariffDiscount) return {}

  return {
    id: get(crossSellTariffDiscount, 'id'),
    messageText: get(crossSellTariffDiscount, 'description'),
  }
}
