import { useState } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { every, get } from 'lodash'
import { InputAdornment, TextField, FormHelperText, Divider, Icon } from '@material-ui/core'
import { ICONS } from 'utils/icons'
import {
  Modal,
  Button,
  ActionAdvice,
  FormSelectOutlined,
  SpinnerCenter,
  OutlinedDivider,
} from 'components/ui'
import {
  getFATPeriods,
  FATCategories,
  formatPeriodForResume,
  FAT_MAX_AMOUNT,
  FAT_MIN_AMOUNT,
} from 'modules/FAT'
import { getSubscriptionId, getMsisdn } from 'services/subscriptions/helpers'
import { formatFee, formatAmountStd } from 'utils'
import { FATResultModal } from './FATResultModal'
import { selectEnablePeriodFat } from 'services/feature-flag/selectors'

let FATData = {}

export const FATModal = styled(
  ({
    className,
    applyFATCompensation,
    FATCompensationClearError,
    FATErrors,
    isFATLoading,
    isOpen,
    onCloseModal,
    subscription,
  }) => {
    const [FATCategory, setFATCategory] = useState(get(FATCategories, 'COMP75.value'))
    const [FATAmount, setFATAmount] = useState('')
    const [FATSMSAmount, setFATSMSAmount] = useState('')
    const [FATPeriod, setFATPeriod] = useState('')
    const [FATComment, setFATComment] = useState('')
    const [sendData, setSendData] = useState(false)
    const [showResult, setShowResult] = useState(false)

    const enableFatPeriod = useSelector(selectEnablePeriodFat)

    const maxAmountReached = FATAmount > FAT_MAX_AMOUNT
    const minAmountReached = FATAmount < FAT_MIN_AMOUNT
    const maxSMSAmountReached = FATSMSAmount > 50
    const FATTotalAmount = FATAmount || FATSMSAmount

    const isDisabled =
      !every([FATCategory, FATTotalAmount, FATPeriod, FATComment]) ||
      maxAmountReached ||
      minAmountReached ||
      maxSMSAmountReached ||
      (FATCategory !== get(FATCategories, 'CPSMSFAT.value') && Number(FATAmount) === 0) ||
      (FATCategory === get(FATCategories, 'CPSMSFAT.value') && FATSMSAmount < 0)

    function setChanges() {
      FATData['fat-group-id'] = FATCategory
      FATData['euro-amount'] = FATAmount && formatAmountStd(FATAmount).replace(',', '.')
      FATData['sms-amount'] = FATSMSAmount
      FATData.period = FATPeriod
      FATData.comment = FATComment
      setSendData(true)
    }

    function resetChanges() {
      FATData = {}
      setFATCategory(get(FATCategories, 'COMP75.value'))
      setFATAmount('')
      setFATSMSAmount('')
      setFATPeriod('')
      setFATComment('')
      setSendData(false)
    }

    function submitChanges() {
      applyFATCompensation(getSubscriptionId(subscription), getMsisdn(subscription), FATData)
      setSendData(false)
      setShowResult(true)
      onCloseModal(false)
      resetChanges()
    }

    const items = FATSMSAmount
      ? [
          {
            label: 'Categoría',
            value: get(FATData, 'fat-group-id'),
          },
          {
            label: 'Cantidad de SMS',
            value: get(FATData, 'sms-amount'),
          },
          {
            label: 'Periodo',
            value: formatPeriodForResume(get(FATData, 'period')),
          },
          {
            label: 'Comentarios',
            value: get(FATData, 'comment'),
          },
        ]
      : [
          {
            label: 'Categoría',
            value: get(FATData, 'fat-group-id'),
          },
          {
            label: 'Importe',
            value: formatFee(get(FATData, 'euro-amount')),
          },
          {
            label: 'Periodo',
            value: formatPeriodForResume(get(FATData, 'period')),
          },
          {
            label: 'Comentarios',
            value: get(FATData, 'comment'),
          },
        ]

    return (
      <>
        <Modal
          className={className}
          data-hook="fat-modal"
          isOpen={isOpen}
          title="Descuento en factura"
          onClose={() => {
            resetChanges()
            onCloseModal(false)
          }}>
          {!sendData ? (
            <>
              <div className="p-y-12 flex">
                <FormSelectOutlined
                  label="Categoría FAT"
                  className="fat-category-field"
                  data-hook="fat-category"
                  name="fat-category"
                  options={Object.keys(FATCategories).map(category => ({
                    text: get(FATCategories, `${category}.text`),
                    value: get(FATCategories, `${category}.value`),
                  }))}
                  onChange={e => {
                    setFATCategory(e.target.value)
                    setFATAmount('')
                    setFATSMSAmount('')
                  }}
                  value={FATCategory}
                />

                {FATCategory === get(FATCategories, 'CPSMSFAT.value') ? (
                  <div className="fat-amount-div" data-hook="fat-amount">
                    <TextField
                      className="fat-amount-field"
                      label="Cantidad de SMS"
                      name="fat-sms-amount-field"
                      onChange={e => {
                        setFATSMSAmount(e.target.value.replace(/^0|[^0-9]/g, ''))
                        setFATAmount('')
                      }}
                      type="text"
                      value={FATSMSAmount}
                      variant="outlined"
                      inputProps={{
                        maxLength: '2',
                      }}
                      error={maxSMSAmountReached}
                    />
                    {maxSMSAmountReached ? (
                      <FormHelperText className="amount-error-msg">
                        El nº máximo de SMS es 50
                      </FormHelperText>
                    ) : null}
                  </div>
                ) : (
                  <div className="fat-amount-div" data-hook="fat-amount">
                    <TextField
                      className="fat-amount-field"
                      label="Importe"
                      name="fat-amount-field"
                      onChange={e => {
                        setFATAmount(e.target.value.replace(/[^0-9,.]/g, ''))
                        setFATSMSAmount('')
                      }}
                      type="text"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment className="icon-container" position="end">
                            <Icon className="icon euro-icon">{ICONS.euro}</Icon>
                          </InputAdornment>
                        ),
                      }}
                      inputProps={{
                        maxLength: '5',
                      }}
                      value={FATAmount.replace('.', ',')}
                      error={maxAmountReached || minAmountReached}
                      variant="outlined"
                    />
                    {maxAmountReached ? (
                      <FormHelperText className="amount-error-msg">
                        Máximo importe 100€
                      </FormHelperText>
                    ) : null}
                  </div>
                )}

                <FormSelectOutlined
                  label="Periodo"
                  className="fat-period-field"
                  data-hook="fat-period"
                  name="fat-period"
                  options={getFATPeriods(enableFatPeriod)}
                  value={FATPeriod}
                  onChange={e => setFATPeriod(e.target.value)}
                /> 
              </div>

              <div className="p-y-12" data-hook="fat-comments">
                <TextField
                  className="fat-comment-field"
                  label="Comentarios"
                  name="fat-comment"
                  onChange={e => setFATComment(e.target.value)}
                  type="text"
                  multiline
                  value={FATComment}
                  variant="outlined"
                  inputProps={{
                    maxLength: 200,
                  }}
                  rows={1}
                  rowsMax={4}
                />
              </div>

              <Divider className="divider" />

              <Button className="button" onClick={() => setChanges()} disabled={isDisabled}>
                Continuar
              </Button>

              <Button
                secondary
                onClick={() => {
                  resetChanges()
                  onCloseModal(false)
                }}>
                Cancelar
              </Button>
            </>
          ) : (
            <>
              <ActionAdvice className="m-y-24" type="info" items={items} />

              <OutlinedDivider className="divider" />

              <Button
                className="button"
                onClick={() => {
                  submitChanges()
                }}
                disabled={isDisabled}>
                Continuar
              </Button>

              <Button secondary onClick={() => setSendData(false)}>
                Volver Atrás
              </Button>
            </>
          )}
        </Modal>

        {isFATLoading && <SpinnerCenter absolute style={{ marginTop: '20%' }} />}

        {!isFATLoading && (
          <FATResultModal
            errors={FATErrors}
            isOpen={showResult}
            onClose={() => {
              setShowResult(false)
              FATCompensationClearError()
            }}
          />
        )}
      </>
    )
  },
)`
  .fat-category-field {
    width: 30%;
  }
  .fat-amount-div {
    width: 20%;
    margin: 0 28px;
    height: 56px;
  }
  .fat-amount-field {
    width: 100%;
  }
  .fat-amount-field input::placeholder {
    opacity: 0.9;
  }
  .fat-period-field {
    width: 40%;
  }
  .fat-comment-field {
    width: 97%;
  }
  .button {
    margin-right: 10px;
  }
  .divider {
    margin: 25px 0;
  }
  .icon-container {
    background: black;
    border-radius: 100%;
    padding: 2px;
    width: 28px;
    height: 20px;
  }
  .euro-icon {
    font-size: 15px;
    color: ${props => get(props, 'theme.palette.brand.light')};
  }
  .amount-error-msg {
    color: ${props => get(props, 'theme.palette.brand.alert')};
  }
  .MuiButtonBase-root {
    display: unset;
  }
`

FATModal.propTypes = {
  className: PropTypes.string,
  applyFATCompensation: PropTypes.func,
  FATCompensationClearError: PropTypes.func,
  FATErrors: PropTypes.object,
  isFATLoading: PropTypes.bool,
  isOpen: PropTypes.bool,
  onCloseModal: PropTypes.func,
  subscription: PropTypes.object,
}
