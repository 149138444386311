import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Typography, Box } from '@material-ui/core'
import { TextStyles } from 'utils/text'

import { LineItemCard } from 'components/ui'

const PADDING_ELEMENT = '9px 24px'

const LineItemCardStyled = styled(LineItemCard)`
  width: auto;
`
export function TabServicesSim({ servicesSim /* icc */ }) {
  const { pin, puk, pin2, puk2, imsi } = servicesSim
  return (
    <Box display="flex" flexDirection="column" data-hook="sim-info" className="p-y-12">
      <Typography
        {...TextStyles.subtitle2Link({
          style: {
            color: '#2b67bb',
            padding: PADDING_ELEMENT,
          },
        })}>
        <span>Información de la SIM</span>
      </Typography>{' '}
      <Box display="flex" flexDirection="column" p="10px 0 0 0">
        <div className="flex">
          <LineItemCardStyled primaryText="PIN 1" secondaryText={pin} />
          <LineItemCardStyled primaryText="PIN 2" secondaryText={pin2} />
        </div>
        <div className="flex">
          <LineItemCardStyled primaryText="PUK 1" secondaryText={puk} />
          <LineItemCardStyled primaryText="PUK 2" secondaryText={puk2} />
        </div>
        <div className="flex">
          <LineItemCardStyled primaryText="IMSI" secondaryText={imsi} />
        </div>
      </Box>
    </Box>
  )
}

TabServicesSim.propTypes = {
  servicesSim: PropTypes.object,
  // icc: PropTypes.string,
}
