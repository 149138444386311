import PropTypes from 'prop-types'
import { find } from 'lodash'

import { makeStyles } from '@material-ui/styles'
import { Typography } from '@material-ui/core'
import { FormikInput } from 'components/ui'

import { PortabilityPostpaid } from './components/PortabilityPostpaidComponent'
import { PortabilityPrepaid } from './components/PortabilityPrepaidComponent'

export function Portability({
  fieldNames,
  portaPaymentType,
  isMobile,
  signUpType,
  onCheckIsPortability,
  onPortabilityTypeChange,
  isCustomerLoading,
  mainUser,
  portabilityPerson,
  className,
  otherUserDocument,
  hasICCNumber,
  isElFijo,
  isMobileOnly,
  disableOfferEdit,
  isLeadFilled,
}) {
  const portabilityOptions = [
    {
      id: ['postpaid', 'portability'],
      value: 'postpaid',
      component: (
        <PortabilityPostpaid
          fieldNames={fieldNames}
          isCustomerLoading={isCustomerLoading}
          mainUser={mainUser}
          portabilityPerson={portabilityPerson}
          className={className}
          otherUserDocument={otherUserDocument}
          isMobileOnly={isMobileOnly}
          isLeadFilled={isLeadFilled}
        />
      ),
    },
    {
      id: ['prepaid', 'new'],
      value: 'prepaid',
      component: (
        <PortabilityPrepaid
          fieldNames={fieldNames}
          hasICCNumber={hasICCNumber}
          isMobileOnly={isMobileOnly}
          isLeadFilled={isLeadFilled}
        />
      ),
    },
  ]
  const selectUser = !isMobile || isElFijo ? signUpType : portaPaymentType
  const line = find(portabilityOptions, option => option.id.includes(selectUser)) || {}

  const useStyles = makeStyles({
    portability: {
      display: 'flex',
      alignItems: 'flex-start',
      minWidth: '200px',
    },
    portabilityType: {
      minWidth: '300px',
    },
    portaPaymentType: {
      alignSelf: 'center',
    },
  })

  const classes = useStyles()

  return (
    <>
      {isMobileOnly && disableOfferEdit && (
        <div className={classes.portaPaymentType}>
          <Typography variant="body1" className="bold p-r-36">
            {portaPaymentType === 'postpaid' ? 'Contrato' : 'Prepago'}
          </Typography>
        </div>
      )}
      {(!isMobileOnly || disableOfferEdit) && (
        <div className={`${classes.portability} p-r-16`}>
          <FormikInput
            label="Nº a portar"
            maxLength="9"
            disabled={{ bool: isMobileOnly && !isLeadFilled }}
            inputProps={{ disabled: true }}
            width="100%"
            name={fieldNames.PORTABILITY_NUMBER}
            onChange={onCheckIsPortability}
          />
        </div>
      )}
      {isMobile && !isElFijo && (
        <div className={`${classes.portability} ${classes.portabilityType}  p-r-16`}>
          {!disableOfferEdit && (
            <FormikInput
              select
              fullWidth
              disabled={{
                error: (!isMobileOnly || disableOfferEdit) && fieldNames.PORTABILITY_NUMBER,
              }}
              name={fieldNames.PORTABILITY_PAYMENT_TYPE}
              label="¿Contrato o Prepago? (donante)"
              options={[
                { value: 'postpaid', key: 'postpaid', label: 'Contrato' },
                { value: 'prepaid', key: 'prepaid', label: 'Prepago' },
              ]}
              onChange={() => onPortabilityTypeChange(fieldNames)}
            />
          )}
        </div>
      )}
      {(!isMobileOnly || disableOfferEdit) && selectUser && line.component}
    </>
  )
}

Portability.propTypes = {
  fieldNames: PropTypes.object,
  portaPaymentType: PropTypes.string,
  isMobile: PropTypes.bool,
  signUpType: PropTypes.string,
  onCheckIsPortability: PropTypes.func.isRequired,
  onPortabilityTypeChange: PropTypes.func,
  isCustomerLoading: PropTypes.bool,
  mainUser: PropTypes.string,
  portabilityPerson: PropTypes.string,
  className: PropTypes.string,
  otherUserDocument: PropTypes.string,
  hasICCNumber: PropTypes.bool,
  isElFijo: PropTypes.bool,
  isMobileOnly: PropTypes.bool,
  disableOfferEdit: PropTypes.bool,
  isLeadFilled: PropTypes.bool,
}
