import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import { TextStyles } from 'utils/text'

import { ReactComponent as BillsIconSvg } from './bills.svg'

const useStyles = makeStyles(theme => ({
  'billing-empty': {
    border: `2px dashed ${theme.palette.global.gray_light}`,
    padding: '85px 25px',
    margin: '15px',

    '& svg': {
      '& path': {
        '& fill': theme.palette.global.gray,
      },
    },

    '& p': {
      padding: '10px 0',
      textAlign: 'center',
      color: theme.palette.global.gray,
    },
  },
}))

function BillingEmpty() {
  const classes = useStyles()

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      className={classes['billing-empty']}>
      <BillsIconSvg />
      <Typography {...TextStyles.paragraphDark()}>No existen facturas para este cliente</Typography>
    </Box>
  )
}

export default BillingEmpty
