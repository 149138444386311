import { createSelector } from 'reselect'

export const selectActivity = state => state.activityRegister || {}

export function selectActivityOffersByMSISDN(msisdn) {
  return createSelector(
    selectActivity,
    activityRegister => activityRegister.offers[msisdn] || {},
  )
}

export function selectActivityErrorMessageByMSISDN(msisdn) {
  return createSelector(
    selectActivityOffersByMSISDN(msisdn),
    offer => offer.error && offer.error.error_message,
  )
}
