import PropTypes from 'prop-types'
import { Typography, Icon } from '@material-ui/core'
import { TextStyles } from 'utils/text'
import { ICONS } from 'utils/icons'

import { Badge } from 'modules/ui'
import { get, isEmpty } from 'lodash'
import { formatFee, deviceIconType } from 'utils'

export function HandsetDetail({ handset }) {
  return !isEmpty(handset) ? (
    <div className="p-x-24 p-y-8" data-hook="handset-detail">
      {handset.map(handsetItem => (
        <div key={handsetItem.id} className="flex p-x-24">
          <Icon className="icon" style={{ opacity: 0.7 }}>
            {ICONS[deviceIconType(get(handsetItem, 'name'))]}
          </Icon>
          <Typography
            {...TextStyles.subtitle2Dark({
              className: 'p-x-24',
            })}>
            {handsetItem.name}
          </Typography>
          {!isEmpty(handsetItem.imei) && (
            <Badge lg value={{ label: 'IMEI', value: handsetItem.imei }} />
          )}
          {!isEmpty(handsetItem.initial_payment) && (
            <Badge
              lg
              value={{ label: 'Pago inicial', value: formatFee(handsetItem.initial_payment || 0) }}
            />
          )}
          <Badge
            lg
            value={{
              label: 'Pago final',
              value: formatFee(handsetItem.final_payment || 0),
            }}
          />
          {!isEmpty(handsetItem.subsidy_contracts) &&
            handsetItem.subsidy_contracts.map(contract => (
              <Badge
                key={contract.monthly_fee}
                lg
                value={{
                  label: 'Cuota',
                  value: `${contract.monthly_fee} €/mes (${contract.contract_length} meses)`,
                }}
              />
            ))}
        </div>
      ))}
    </div>
  ) : (
    ''
  )
}

HandsetDetail.propTypes = {
  handset: PropTypes.arrayOf(PropTypes.object),
}
