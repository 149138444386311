import { constants } from './constants'

const initialSubState = {
  loading: null,
  error: null,
  data: {},
}

export const reducer = (state = {}, { type, payload }) => {
  switch (type) {
    case constants.FETCH:
      return { ...state, [payload.orderId]: { ...initialSubState, loading: true } }
    case constants.FETCH_SUCCESS:
      return {
        ...state,
        [payload.orderId]: { ...state[payload.orderId], loading: false, data: payload.data },
      }
    case constants.FETCH_ERROR:
      return {
        ...state,
        [payload.orderId]: { ...state[payload.orderId], loading: false, error: payload.error },
      }
    default:
      return state
  }
}
