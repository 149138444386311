import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import {
  findPrepaidLine,
  selectIsLoading,
  selectErrorMessage,
  selectPrepaidLineData,
} from 'modules/prepaid-line'
import { get, orderBy } from 'lodash'
import { useRouteMatch } from 'react-router-dom'

import { PrepaidLineDetail } from '../../components/PrepaidLineCard/PrepaidLineDetail'

export function PrepaidLineDetailContainer() {
  const match = useRouteMatch()
  let prepaidLineEvents = {}
  const productId = get(match, 'params.id')
  const dispatch = useDispatch()
  const prepaidLine = productId && useSelector(selectPrepaidLineData(productId))
  if (prepaidLine) {
    prepaidLineEvents = get(prepaidLine, 'prepaidEvents').filter(
      event => get(event, 'amount_without_vat') > 0,
    )
  }

  useEffect(() => {
    dispatch(findPrepaidLine(productId))
  }, [productId])

  const props = {
    isLoading: useSelector(selectIsLoading),
    errors: useSelector(selectErrorMessage),
    prepaidLineEvents: orderBy(prepaidLineEvents, [event => event.date], ['desc']),
    productId,
  }

  return <PrepaidLineDetail {...props} />
}

PrepaidLineDetailContainer.propTypes = {
  subscription: PropTypes.object,
}
