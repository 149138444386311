import { useState } from 'react'
import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'
import { LineItemCard, OutlinedDivider, SpinnerCenter } from 'components/ui'
import { Card } from 'modules/ui'
import { canIViewCompleteAccountNumber } from 'modules/vista-360/helpers/permissions.helper'
import { formatIBAN, formatCensuredIban } from '../../helpers/formats.helper'
import { CustomerWriteModalContainer } from '../../containers/CustomerWriteModalContainer'
import { ErrorBills } from '../BillHistoric/EmptyBills'

const PADDING_ELEMENT = '9px 24px'

export function BillingDataCard({
  isLoading,
  hasErrors,
  billingDataInfo,
  billingAddressObject,
  hasEditPermissions,
}) {
  const { clientId, iban, billingAddress } = billingDataInfo
  const viewCompleteAccountNumber = canIViewCompleteAccountNumber()
  const [openCustomerWriteModal, setOpenCustomerWriteModal] = useState(false)

  const actions = [
    {
      label: 'Editar',
      onClick: () => setOpenCustomerWriteModal(true),
      dataHook: 'change-iban-button',
      disabled: !hasEditPermissions,
    },
  ]

  return (
    <>
      {isLoading && <SpinnerCenter size={64} style={{ marginTop: '40px', marginBottom: '60px' }} />}

      {!isLoading && hasErrors && <ErrorBills />}
      {!isLoading && !hasErrors && (
        <>
          {billingDataInfo && (
            <Card
              title="Datos de facturación"
              id="Datos de facturacion"
              withShadow
              actions={actions}>
              <LineItemCard primaryText="Id de cliente" secondaryText={clientId} />
              <Box padding={PADDING_ELEMENT}>
                <OutlinedDivider />
              </Box>
              <LineItemCard
                primaryText="IBAN"
                secondaryText={
                  iban && (viewCompleteAccountNumber ? formatIBAN(iban) : formatCensuredIban(iban))
                }
              />
              <LineItemCard primaryText="Dirección de facturación" secondaryText={billingAddress} />
              {openCustomerWriteModal && (
                <CustomerWriteModalContainer
                  clientId={clientId}
                  openCustomerWriteModal={openCustomerWriteModal}
                  setOpenCustomerWriteModal={setOpenCustomerWriteModal}
                  currentIban={formatIBAN(iban)}
                  billingAddress={billingAddressObject}
                />
              )}
            </Card>
          )}
        </>
      )}
    </>
  )
}

BillingDataCard.propTypes = {
  isLoading: PropTypes.bool,
  hasErrors: PropTypes.bool,
  billingDataInfo: PropTypes.shape({
    clientId: PropTypes.string,
    iban: PropTypes.string,
    billingAddress: PropTypes.string,
  }),
  billingAddressObject: PropTypes.object,
  hasEditPermissions: PropTypes.bool,
}
