import PropTypes from 'prop-types'
import styled from 'styled-components'
import SubmenuItem from './SubmenuItem'
import CloseClient from './CloseClient'

const SubmenuWrapper = styled.nav`
  display: inline-flex;
  flex-direction: row;
  > div {
    & + div {
      border-left: 1px solid rgba(255, 255, 255, 0.3);
    }
  }
`

const Submenu = props => {
  const { items, closeAction } = props

  return (
    <SubmenuWrapper>
      {items.map(item => (
        <SubmenuItem
          key={item.icon}
          text={item.text}
          icon={item.icon}
          onClick={item.onClick}
          active={item.active}
        />
      ))}
      {closeAction ? <CloseClient onClick={closeAction} /> : null}
    </SubmenuWrapper>
  )
}

Submenu.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      icon: PropTypes.string,
      onClick: PropTypes.func,
      active: PropTypes.bool,
    }),
  ),
  closeAction: PropTypes.func,
}

export default Submenu
