// AlarmTypes
export const AlarmTypes = {
  OpenOrder: {
    id: 'OpenOrder',
  },
  Bills: {
    id: 'Bills',
  },
  Communication: {
    id: 'Communication',
  },
  SVAs: {
    id: 'SVAs',
  },
}

export const AlarmSubtypes = {
  ChangeTechnology: {
    id: 'ChangeTechnology',
  },
  ChangeAddress: {
    id: 'ChangeAddress',
  },
  NebaVula: {
    id: 'NebaVula',
  },
  MigHuella: {
    id: 'MigHuella',
  },
  Unpaid: {
    id: 'Unpaid',
  },
  Pending: {
    id: 'Pending',
  },
}

export const AlarmInfo = {
  OpenOrder: {
    id: 'OpenOrder',
    type: AlarmTypes.OpenOrder.id,
    subType: null,
    statuses: {
      default: {
        title: 'Cambio de Tecnología/Cambio de Domicilio en vuelo',
      },
    },
  },

  OpenOrder_ChangeTechnology: {
    id: 'OpenOrder_ChangeTechnology',
    type: AlarmTypes.OpenOrder.id,
    subType: AlarmSubtypes.ChangeTechnology.id,
    statuses: {
      default: {
        title: 'Cambio de Tecnología en vuelo',
      },
    },
  },

  OpenOrder_ChangeAddress: {
    id: 'OpenOrder_ChangeAddress',
    type: AlarmTypes.OpenOrder.id,
    subType: AlarmSubtypes.ChangeAddress.id,
    statuses: {
      default: {
        title: 'Cambio de Domicilio en vuelo',
      },
    },
  },

  OpenOrder_NebaVula: {
    id: 'OpenOrder_NebaVula',
    type: AlarmTypes.OpenOrder.id,
    subType: AlarmSubtypes.NebaVula.id,
    statuses: {
      default: {
        title: 'Cambio de Tecnología en vuelo',
      },
    },
  },

  OpenOrder_MigHuella: {
    id: 'OpenOrder_MigHuella',
    type: AlarmTypes.OpenOrder.id,
    subType: AlarmSubtypes.MigHuella.id,
    statuses: {
      default: {
        title: 'Cambio de Tecnología en vuelo',
      },
    },
  },

  Bills_Unpaid: {
    id: 'Bills_Unpaid',
    type: AlarmTypes.Bills.id,
    subType: AlarmSubtypes.Unpaid.id,
    statuses: {
      default: {
        title: 'Factura impagada',
      },
    },
  },

  Bills_Pending: {
    id: 'Bills_Pending',
    type: AlarmTypes.Bills.id,
    subType: AlarmSubtypes.Pending.id,
    statuses: {
      default: {
        title: 'Periodo de facturación',
      },
    },
  },

  Communication: {
    id: 'Communication',
    type: AlarmTypes.Communication.id,
    subType: null,
    statuses: {
      default: {
        title: 'Comunicación LivePerson',
      },
    },
  },

  SVA_Installation: {
    id: 'SVA_Installation',
    type: AlarmTypes.SVAs.id,
    subType: null,
    statuses: {
      default: {
        title: 'AVERIA PENDIENTE',
      },
    },
  },
}
