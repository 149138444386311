import { axiosJWT } from 'modules/axios'
import { CONFIG } from './environments'

export function findOrderOnFly(filters) {
  return axiosJWT({
    method: 'GET',
    url: CONFIG.api.orderOnFly,
    params: filters,
    headers: {
      ...axiosJWT.defaults.headers.common,
      'X-IT-Process': 'get_open_orders',
    },
  }).then(resp => resp.data)
}
