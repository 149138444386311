import { useState } from 'react'

export const useWaitingForLoad = () => {
  const [waitingForLoad, setWaitingForLoad] = useState(true)

  const executeWaiting = callback => {
    if (callback) {
      callback()
    }

    setWaitingForLoad(true)
  }

  return {
    waitingForLoad,
    setWaitingForLoad,
    executeWaiting,
  }
}
