import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/styles'
import { Card, CardContent, Typography, Box } from '@material-ui/core'

import { Advice, OutlinedDivider } from 'components/ui'

import { formatHourFromDate } from 'utils/formatting/dates'

import { LinesOrderSummary } from 'modules/Lines'

import { Step } from 'components/packages/ui/nextSteps/Step'

const useStyles = makeStyles(theme => ({
  'renewal-next-steps': {
    '& h6': {
      color: theme.palette.brand.medium,
    },
  },
}))

const OrderSummaryAdviceItem = ({ label, value }) => {
  return (
    <Box display="flex" alignItems="center">
      {!!label && (
        <Typography variant="subtitle2" className="p-r-4">
          {value ? `${label}:` : label}
        </Typography>
      )}

      {!!value && (
        <Typography variant="body1" style={{ fontWeight: 'bold' }}>
          {value}
        </Typography>
      )}
    </Box>
  )
}

OrderSummaryAdviceItem.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
}

const OrderSummaryAdvice = ({ docNumber, msisdn, documentType, orderId, deviceName }) => {
  return (
    <Advice type="info" block>
      <Box display="flex" justifyContent="space-between" width="100%" className="p-r-24">
        <OrderSummaryAdviceItem label="Nº PEDIDO" value={orderId} />

        <OrderSummaryAdviceItem label="NÚMERO" value={msisdn} />

        <OrderSummaryAdviceItem label={documentType} value={docNumber} />

        <OrderSummaryAdviceItem value={deviceName} />
      </Box>
    </Advice>
  )
}

OrderSummaryAdvice.propTypes = {
  docNumber: PropTypes.string,
  msisdn: PropTypes.string,
  documentType: PropTypes.string,
  orderId: PropTypes.string,
  deviceName: PropTypes.string,
}

export const Summary = ({
  taskId,
  deviceName,
  errorCheckout,
  startPollingTime,
  docNumber,
  msisdn,
  documentType,
  orderId,
  willDeviceBeShippedToStore,
  isDevicePaymentMethodUpfront,
}) => {
  const classes = useStyles()

  const orderResumeProps = {
    docNumber,
    msisdn,
    documentType,
    orderId,
    deviceName,
  }

  if (errorCheckout.length > 0) {
    return (
      <Card style={{ width: '100%' }}>
        <CardContent>
          <Box display="flex" alignItems="center" mb="20px">
            <Typography variant="subtitle2" className="p-r-4">
              Documento de Identidad:
            </Typography>

            <Typography variant="body1">{docNumber}</Typography>
          </Box>

          <Box display="flex" alignItems="center" mb="20px">
            <Typography variant="subtitle2" className="p-r-4">
              TaskId:
            </Typography>

            <Typography variant="body1">{taskId}</Typography>
          </Box>

          <Box display="flex" alignItems="center" mb="20px">
            <Typography variant="subtitle2" className="p-r-4">
              Hora:
            </Typography>

            <Typography variant="body1">{formatHourFromDate(startPollingTime)}</Typography>
          </Box>

          <OutlinedDivider />

          <LinesOrderSummary errorCheckout={errorCheckout} />
        </CardContent>
      </Card>
    )
  }

  return (
    <>
      <Card style={{ width: '100%' }}>
        <CardContent>
          <OrderSummaryAdvice {...orderResumeProps} />
        </CardContent>
      </Card>

      <Card style={{ width: '100%' }} className={classes['renewal-next-steps']}>
        <CardContent>
          <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
            PRÓXIMOS PASOS
          </Typography>

          <Box p="32px 50px 0">
            <Step title="Proceso de venta" description="Ya has completado el pedido." isActive />
            <Step
              title="Email de confirmación"
              description="Te enviaremos un email con la confirmación de tu pedido en las próximas 48h."
            />

            {willDeviceBeShippedToStore && (
              <Step
                title="Recogida del terminal"
                description={`Te avisaremos por SMS en el momento que recibamos tu ${deviceName} en nuestra tienda.`}
              />
            )}

            {!isDevicePaymentMethodUpfront && (
              <Step
                title="Firma digital"
                description={
                  <>
                    <Box mb="12px">
                      Te enviaremos un SMS en el transcurso de 1h en el que siguiendo los pasos
                      podrás firmar digitalmente el contrato de renuevo (con la misma validez que el
                      contrato físico).
                    </Box>

                    <Box mb="12px">
                      Necesitarás validar una <strong>dirección de mail</strong> para que te
                      enviemos la documentación y tener a mano el{' '}
                      <strong>documento identificativo del titular de la línea</strong> (DNI,
                      NIE...) para poder{' '}
                      <strong>subir una foto del documento por las dos caras</strong> (puedes
                      hacerla en ese momento o subir una que tengas previamente).
                    </Box>

                    <Box mb="12px">
                      Finalizado el proceso, podrás descargarte la documentación, y además la
                      recibirás en un correo.
                    </Box>

                    <Box mb="12px">
                      No obstante, si no lo consigues, no hay problema, en la entrega del terminal
                      te pediremos que firmes dos copias del contrato.
                    </Box>
                  </>
                }
              />
            )}
          </Box>
        </CardContent>
      </Card>

      <Card style={{ width: '100%' }}>
        <CardContent>
          <Typography variant="body1">
            Ya está. En este momento comenzamos la gestión de tu pedido. Si para finalizarlo fuera
            necesario solicitar documentación, nos pondremos en contacto contigo.{' '}
            <strong>¡Que pases un buen día!</strong>
          </Typography>
        </CardContent>
      </Card>
    </>
  )
}

Summary.propTypes = {
  taskId: PropTypes.string,
  deviceName: PropTypes.string.isRequired,
  errorCheckout: PropTypes.array.isRequired,
  startPollingTime: PropTypes.number,
  docNumber: PropTypes.string,
  msisdn: PropTypes.string,
  documentType: PropTypes.string,
  orderId: PropTypes.string,
  willDeviceBeShippedToStore: PropTypes.bool,
  isDevicePaymentMethodUpfront: PropTypes.bool,
}
