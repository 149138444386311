import { APP_CONFIG } from 'services/app-config'
import { axiosJWT } from 'modules/axios'

const terminalAPIUrl = `${APP_CONFIG.sales_cluster}/v1/terminals/commercialInfo`

export function fetchTerminalCommercialInfo(opts) {
  return axiosJWT({
    method: 'GET',
    url: terminalAPIUrl,
    params: { brand: 'YOIGO', ...opts },
  }).then(resp => resp.data)
}
