import PropTypes from 'prop-types'
import { has } from 'lodash'
import { useCallback, useState, useEffect } from 'react'
import { push } from 'connected-react-router'
import { useRouteMatch, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { actions as coverageActions } from 'modules/Coverage'
import { setBasketData } from 'modules/NewClientSales/store'
import { SpinnerCenter } from 'components/ui'

import { removeQueryParam } from 'modules/url'
import { fetchContentfulResourceAction } from 'modules/contentful'

import { exitAppAction } from 'modules/Core'
import { NewClientSalesComponent } from '../../components/NewClientSalesComponent'
import { selectSales } from './NewClientsContainer.selectors'
import { INFO, OFFERS, SUMMARY } from '../../constants'

function getSalesProps() {
  const match = useRouteMatch()
  const location = useLocation()

  const salesData = useSelector(selectSales)

  const dispatch = useDispatch()

  const getNextStepUrlPath = (nextStep, isEditing) => {
    // If isEditing is in the queryparams it means we come from summary, so when navigating, we go back to summary
    let params = location.search

    if (isEditing) {
      params = removeQueryParam(params, 'isEditing')
    }
    return `${isEditing ? SUMMARY : nextStep}${params}`
  }

  const props = {
    ...salesData,
    match,
    location,
    isEditing: location.search.indexOf('isEditing') !== -1,
    getNextStepUrlPath: useCallback((path, isEditing) => {
      return getNextStepUrlPath(path, isEditing)
    }),
    navigateToNextStep: useCallback((path, isEditing) => {
      const newPath = getNextStepUrlPath(path, isEditing)
      dispatch(push(`${newPath}`))
    }),
    cancelSalesProcess: useCallback(() => {
      dispatch(exitAppAction())
    }),
    getCoverageData: useCallback(data => {
      if (!has(data, 'appointmentDate')) {
        dispatch(coverageActions.getCoverageAction(data))
      }
    }),
    setBasketData: useCallback(data => {
      dispatch(setBasketData(data))
    }),
    fetchContentfulResource: useCallback((resource, params) => {
      dispatch(fetchContentfulResourceAction(resource, params))
    }),
    getCoverageToken: useCallback(data => {
      dispatch(coverageActions.getCoverageToken(data))
    }),
  }

  return props
}

export function NewClientsContainer() {
  const props = getSalesProps()
  const isLoading = props.isElFijo && props.isMainTariffsLoading
  const flowStep = props.isMobileOnly ? OFFERS : INFO
  const stepURL = props.match.params.step || flowStep

  const [step, onSetStep] = useState(stepURL)

  useEffect(() => {
    if (props.isConvergent || props.is2p || props.isCrossSell) {
      props.getCoverageToken({ isOvid: true })
    }
  }, [])

  useEffect(() => {
    onSetStep(stepURL)
  }, [stepURL])

  return isLoading ? <SpinnerCenter /> : <NewClientSalesComponent {...props} step={step} />
}

NewClientsContainer.propTypes = {
  isElFijo: PropTypes.bool,
  isMainTariffsLoading: PropTypes.bool,
  isMobileOnly: PropTypes.bool,
  match: PropTypes.object,
  getCoverageToken: PropTypes.func,
  isConvergent: PropTypes.bool,
  is2p: PropTypes.bool,
  isCrossSell: PropTypes.bool,
}
