import PropTypes from 'prop-types'
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  CircularProgress,
  Typography,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import { TextStyles } from 'utils/text'

export function Accordion({ title, children, className, expanded, onChange, isLoading }) {
  return (
    <div className={className}>
      <ExpansionPanel expanded={expanded} className="accordion-panel" onChange={onChange}>
        <ExpansionPanelSummary
          className="summary"
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content">
          <Typography {...TextStyles.dataDark()}>{title}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className="details">{children}</ExpansionPanelDetails>
      </ExpansionPanel>
      {isLoading && (
        <CircularProgress className="spinner" size="16px" color="secondary" expanded={expanded} />
      )}
    </div>
  )
}

Accordion.defaultProps = {
  onChange: () => {},
}

Accordion.propTypes = {
  title: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  expanded: PropTypes.bool,
  children: PropTypes.any,
  className: PropTypes.string,
  isLoading: PropTypes.bool,
}
