import PropTypes from 'prop-types'

import { Icon } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import { ICONS } from 'utils/icons'

import MainActions from './MainActions'
import Button from '../buttons/Button'

const useStyles = makeStyles({
  'right-area': {
    '& > button': {
      padding: '9px',

      '& .icon': {
        fontSize: '16px',
      },
    },

    '& button + button': {
      marginLeft: '6px',
    },
  },
})

const OffersConfigurationActions = ({ addAction, suspendAction, unsubscribeAction }) => {
  const classes = useStyles()

  return (
    <MainActions>
      <Button
        dashed
        onClick={addAction}
        style={{ width: '288px', justifyContent: 'flex-start' }}
        disabled>
        <Icon className="icon">{ICONS.add}</Icon>
        Añadir suscripción
      </Button>
      <div className={classes['right-area']}>
        <Button text onClick={suspendAction} disabled>
          <Icon className="icon">{ICONS.suspend}</Icon>
          Congelar todo
        </Button>
        <Button text onClick={unsubscribeAction} disabled>
          <Icon className="icon">{ICONS.delete}</Icon>
          Dar de baja todo
        </Button>
      </div>
    </MainActions>
  )
}

OffersConfigurationActions.propTypes = {
  addAction: PropTypes.func,
  suspendAction: PropTypes.func,
  unsubscribeAction: PropTypes.func,
}

export default OffersConfigurationActions
