import { useEffect, useState } from 'react'
import { useFormikContext } from 'formik'

import { formatYYYYMMDD_HHmmss } from 'utils/formatting/dates'

import { RESIDENTIAL } from 'modules/SharedSales/constants'

export const useGetHoursOptions = (segment, nameInputHour) => {
  const { setFieldValue } = useFormikContext()
  const [hoursOptions, setHoursOptions] = useState([])
  const [shouldShowHours, setShouldShowHours] = useState(false)

  const addHours = (date, numOfHours) => {
    const dateCopy = new Date(date.getTime())

    dateCopy.setTime(dateCopy.getTime() + numOfHours * 60 * 60 * 1000)

    return dateCopy
  }

  const addDays = (date, days) => {
    const dateCopy = new Date(date)

    dateCopy.setDate(dateCopy.getDate() + days)

    return dateCopy
  }

  const getWorkingHours = day => {
    const hours = []

    day.setHours(0, 0, 0, 0)
    for (let i = 10; i < 20; i += 1) {
      hours.push({
        key: i,
        label: `${i}h - ${i + 1}h`,
        value: formatYYYYMMDD_HHmmss(addHours(day, i)),
      })
    }

    return hours
  }

  const getHoursOptions = () => {
    const date = new Date()
    const currentHour = date.getHours()
    const hoursOptionsCopy = []

    const days = ['HOY:', 'MAÑANA:']
    days.forEach((day, i) => {
      hoursOptionsCopy.push({
        key: day,
        label: day,
        value: i,
        disabled: true,
      })
      getWorkingHours(addDays(date, i)).forEach(hour => {
        hoursOptionsCopy.push({ ...hour, disabled: hour.key < currentHour + 1 && day === 'HOY:' })
      })
    })

    return hoursOptionsCopy
  }

  useEffect(() => {
    const isResidentialSegment = segment === RESIDENTIAL

    if (isResidentialSegment) {
      setHoursOptions(getHoursOptions())
    } else {
      setFieldValue(nameInputHour, '')
    }

    setShouldShowHours(isResidentialSegment)
  }, [segment])

  return [hoursOptions, shouldShowHours]
}
