import { useEffect, useState } from 'react'
import { PropTypes } from 'prop-types'
import { useSelector } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'
import { CircularProgress, Box } from '@material-ui/core'
import { isNil } from 'lodash'

import { ButtonLink } from 'components/ui'
import { Card } from 'modules/ui'
import { OrderLimitModal } from 'modules/vista-360/components/Dashboard/SaleButtons'
import { selectCustomerOrderLimitLoading } from 'modules/CustomerOrders/store'
import {
  canEnableSomeSellOptionWithClient,
  getMainSubscriptionTariffId,
} from 'services/customer-360/selectors'
import { selectProfileId } from 'modules/Permissions'
import { getTelesalesChannel } from 'modules/vista-360/helpers/channel.helper'

import { selectTariffSegment } from 'modules/tariffs/store-apigee'
import { TELESALES_REGEX, NEWTON } from 'services/global-constants/channels'
import { selectors } from 'services/customer-360'
import { NewProductModal } from '../NewProductModal'

import { selectSaleWithClientCardButton } from './SaleWithClientCardButton.selectors'

function buildCarteraUrl(customerId, location) {
  const queryParams = new URLSearchParams(location.search)

  let segment
  let newSearch = ''

  const agentProfile = useSelector(selectProfileId)
  const mainSubscriptionId = useSelector(getMainSubscriptionTariffId)

  if (!isNil(mainSubscriptionId)) {
    segment = useSelector(selectTariffSegment(mainSubscriptionId))
  } else {
    segment = useSelector(selectors.getSegment)
  }

  const salesBaseUrl = '/add-client'

  if (queryParams.get('channel') === NEWTON) {
    newSearch = new URLSearchParams(
      location.search.replace(
        TELESALES_REGEX,
        getTelesalesChannel(agentProfile, segment, queryParams),
      ),
    )
  } else {
    newSearch = new URLSearchParams(location.search)
  }

  newSearch.delete('isCartera')
  newSearch.delete('isSalesFlow')
  newSearch.delete('isPresale')

  if (customerId) {
    newSearch.set('isCartera', true)
    newSearch.set('isSalesFlow', true)
  }

  if (!newSearch.has('channel')) {
    newSearch.set('channel', 'telesales')
  }

  const searchStr = newSearch.toString() ? `?${newSearch.toString()}` : ''

  return `${salesBaseUrl}${searchStr}`
}

export function SaleWithClientCardButton({ className, hasCustomerIdsLoaded }) {
  const [shouldCheckLimit, setShouldCheckLimit] = useState(false)
  const [openNewProductModal, setOpenNewProductModal] = useState(false)

  const history = useHistory()
  const location = useLocation()

  const isLimitLoading = useSelector(selectCustomerOrderLimitLoading)

  const { customerId } = useSelector(selectSaleWithClientCardButton)

  const carteraURL = buildCarteraUrl(customerId, location)

  function resetFlowOrder() {
    setShouldCheckLimit(true)
    setOpenNewProductModal(false)
  }

  const canEnableSellOption = useSelector(canEnableSomeSellOptionWithClient)

  useEffect(() => {
    setTimeout(() => {
      // Remove isCartera
      const newSearch = new URLSearchParams(location.search)
      newSearch.delete('isCartera')

      history.replace({
        pathname: location.pathname,
        search: newSearch.toString() ? `?${newSearch.toString()}` : '',
      })
    }, 100)
  }, [])

  useEffect(() => {
    if (!customerId) setShouldCheckLimit(false)
  }, [customerId])

  return (
    <>
      <Card className={`add-new-package-card ${className}`} title="">
        {!isLimitLoading || !shouldCheckLimit ? (
          <ButtonLink
            onClick={() => setOpenNewProductModal(true)}
            disabled={!canEnableSellOption || !hasCustomerIdsLoaded}
            data-hook="sale-withclient-button"
            underline={false}
            className="card-link">
            + Añadir nueva venta
          </ButtonLink>
        ) : (
          <Box p="12px 24px">
            <CircularProgress size="24px" color="primary" />
          </Box>
        )}

        <OrderLimitModal
          shouldCheckLimit={shouldCheckLimit}
          onOrderLimitOk={() => history.push(carteraURL)}
          onOrderLimitReached={() => setShouldCheckLimit(false)}
        />
      </Card>

      {openNewProductModal && (
        <NewProductModal
          openNewProductModal={openNewProductModal}
          setOpenNewProductModal={setOpenNewProductModal}
          onConfirm={resetFlowOrder}
          disabled={!canEnableSellOption}
          message="Recuerda que si tu cliente no quiere comprar un terminal, debes cargar la venta de la
            línea desde el botón “Añadir línea adicional con descuento” para que se le aplique el descuento de línea
            adicional."
        />
      )}
    </>
  )
}

SaleWithClientCardButton.propTypes = {
  className: PropTypes.string,
  hasCustomerIdsLoaded: PropTypes.bool,
}
