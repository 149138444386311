import { createSelector } from 'reselect'
import { get } from 'lodash'

import {
  selectSelectedTerminals,
  selectAllSelectedTerminals,
  selectMainMobileLineOffer,
  selectExtraMobileLinesOffers,
  selectStockOffer,
} from './Basket.selectors'
import { selectSaleTax } from './Taxes.selectors'
import {
  mapTerminalByLineType,
  mapTerminalsWithKeys,
  mapTerminalsFinancial,
  mapTerminalsSinglePayment,
} from './Basket.selectors.helpers'

import { getTerminalWithComputedPrices } from '../../../Terminals'

export const selectSelectedTerminalsWithComputedPrices = createSelector(
  selectSaleTax,
  selectSelectedTerminals,
  (tax, terminals) => {
    return {
      main: terminals.main ? getTerminalWithComputedPrices(terminals.main, tax) : terminals.main,
      additional: terminals.additional.map(a => getTerminalWithComputedPrices(a, tax)),
      all: terminals.all.map(a => getTerminalWithComputedPrices(a, tax)),
    }
  },
)

export const selectTotalMonthlyTerminalFee = createSelector(
  selectSelectedTerminalsWithComputedPrices,
  terminals =>
    terminals.all
      ? terminals.all.reduce(
          (curr, next) => curr + get(next, 'computedPrices.monthlyPayment', 0),
          0,
        )
      : 0,
)

export const selectHasTerminalsWithSeveralPayment = createSelector(
  selectTotalMonthlyTerminalFee,
  fee => fee > 0,
)

export const selectTPVTerminals = createSelector(
  [selectAllSelectedTerminals, selectSaleTax],
  (terminals, tax) =>
    terminals
      .map(terminal => getTerminalWithComputedPrices(terminal, tax))
      .filter(terminal => !!get(terminal, 'computedPrices.initialPayment', false))
      .sort((a, b) => b.isMain - a.isMain),
)

const selectTerminalsWithKeys = createSelector(
  selectStockOffer,
  selectMainMobileLineOffer,
  selectExtraMobileLinesOffers,
  mapTerminalsWithKeys,
)

export const makeBasketTerminalDetails = () =>
  createSelector(
    selectTerminalsWithKeys,
    selectSaleTax,
    (_, lineType) => lineType,
    (_, __, paymentType) => paymentType,
    mapTerminalByLineType,
  )

export const selectTerminalsFinancialData = createSelector(
  selectMainMobileLineOffer,
  selectExtraMobileLinesOffers,
  selectSaleTax,
  mapTerminalsFinancial,
)

export const selectTerminalsSinglePaymentData = createSelector(
  selectMainMobileLineOffer,
  selectExtraMobileLinesOffers,
  selectSaleTax,
  mapTerminalsSinglePayment,
)
