import { useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { isEmpty } from 'lodash'
import { SpinnerCenter } from 'components/ui'
import { isLoading, constants } from 'services/discounts-agent-categories'
import {
  isConvergentSubscription,
  getSubscriptions,
  getSubscriptionTariffId,
} from 'services/subscriptions'
import { selectProfileId, permissions, selectCanIDO } from 'modules/Permissions'
import { use1wSales } from 'modules/offers-configuration/hooks/use1wSales/use1wSales'
import { selectTariffsApigeeById, isConvergentTariff } from 'modules/tariffs/store-apigee'
import {
  formatDiscountsAmounts,
  formatDiscountsDurations,
  getDiscountInfo,
  is2PDiscount,
  isDiscountAgileTV,
  isDiscountHebe,
  isDiscountAdditionalLineInfiteGb,
  isRetentionAdditionalLineDiscount,
  isIndefiniteMobileDiscount,
  isIndefiniteConvergentDiscount,
  hasDiscountPermanency,
  getPermanencyId,
  isDiscountDiscAgileTv03,
  isDiscountMobDiscTBPRO,
  isDiscountMsedeDisc,
  is100MbDiscount,
  is100MbPreocupateCero,
  isConvergentDiscount50Pro,
  isAmazonDiscount,
  isRetConv40,
  isRetConv30,
  isDiscountCOL08DISCP,
  isNewD2RDiscount,
  is300Mb2rDiscount,
  isDiscPerm,
  isConvergent5EurosDiscount,
  isNetflixDiscount,
  isMobileMainTariffDiscount,
  isPruebaloResidentialDiscount,
  isPruebaloProDiscount,
  isPruebaloDigitalDiscount
} from 'modules/offers-configuration/helpers'

import { getDiscountOptions } from '../../selectors'
import {
  UNLIMITED_DURATION,
  SPECIAL_UNLIMITED_DURATION,
  CVM_RETENTION_DISCOUNTS,
  PERMANENCY_12_MONTHS,
  DTERM1241,
  DTERM1250,
  DTERM2450,
  DTERM12150,
  PRUEBALO_DISCOUNTS_PERMANENCY
} from '../../constants'

import DiscountForm from '../../components/DiscountForm'

const checkIfExistsConvergentTariff = (subscriptions, state) => {
  const hasConvergentTariff = subscriptions.map(subscription => {
    return isConvergentTariff(selectTariffsApigeeById(getSubscriptionTariffId(subscription))(state))
  })

  return hasConvergentTariff.includes(true)
}

export const DiscountFormContainer = ({
  subscription,
  isFixedReduction,
  newTariffId,
  currentTariff,
  infiniteTariff,
  hasIncompatibilities,
  onDiscountSelect,
  onDiscountPreSelect,
  goToNextStep,
  goToPreviousStep,
}) => {
  const isMobile = !isConvergentSubscription(subscription)
  const subscriptions = useSelector(getSubscriptions)
  const is1wSales = use1wSales()

  const category =
    isFixedReduction || isMobile ? constants.MOBILE_CATEGORY : constants.CONVERGENT_CATEGORY
  const isDiscountsLoading = useSelector(state => isLoading(state, category))
  const discountOptionsBase = useSelector(state =>
    getDiscountOptions(
      state,
      subscription,
      isFixedReduction,
      newTariffId,
      currentTariff,
      infiniteTariff,
      checkIfExistsConvergentTariff(subscriptions, state),
      is1wSales
    ),
  )

  const permissionCvmOpsAddDiscountsAll = useSelector(
    selectCanIDO(permissions.cvm_ops_add_discounts_all.id),
  )

  const permissionCvmOpsAddRetentionDiscounts = useSelector(
    selectCanIDO(permissions.cvm_ops_add_retention_discounts.id),
  )

  const profileId = useSelector(selectProfileId)
  const is1WSales = use1wSales()

  let discountOptions = []
  if (permissionCvmOpsAddDiscountsAll || is1WSales) {
    discountOptions = discountOptionsBase
  } else if (permissionCvmOpsAddRetentionDiscounts) {
    discountOptions = discountOptionsBase.filter(d =>
      CVM_RETENTION_DISCOUNTS.includes(d.discountCode),
    )
  }

  const [selectedAmount, setSelectedAmount] = useState('')
  const [selectedDuration, setSelectedDuration] = useState('')
  const [selectedPermanency, setSelectedPermanency] = useState('')
  const [durationOptions, setDurationOptions] = useState([])
  const [permanencyOptions, setPermanencyOptions] = useState([])

  const onAmountSelect = e => {
    const { value, unit, discountCode } = JSON.parse(e.target.value)

    const selectedDiscountOptions = discountOptions.filter(
      discount => discount.discountCode === discountCode,
    )
    const amountDurationOptions = formatDiscountsDurations(
      selectedDiscountOptions,
      JSON.parse(e.target.value),
    )

    const retentionAdditionalLineDiscount = isRetentionAdditionalLineDiscount(discountCode)

    if (
      // Se comenta porque se deshabilita temporalmente
      // isAdditionalLineDiscount(discountCode) ||
      retentionAdditionalLineDiscount ||
      isIndefiniteConvergentDiscount(discountCode) ||
      isIndefiniteMobileDiscount(discountCode) ||
      is2PDiscount(discountCode) ||
      isDiscountAgileTV(discountCode) ||
      isDiscountHebe(discountCode) ||
      isDiscountAdditionalLineInfiteGb(discountCode, value) ||
      isDiscountDiscAgileTv03(discountCode) ||
      isDiscountMobDiscTBPRO(discountCode) ||
      isDiscountMsedeDisc(discountCode) ||
      isDiscountCOL08DISCP(discountCode) ||
      isNewD2RDiscount(discountCode) ||
      is300Mb2rDiscount(discountCode) ||
      isNetflixDiscount(discountCode)
    ) {
      let duration = UNLIMITED_DURATION

      if (retentionAdditionalLineDiscount) {
        duration = SPECIAL_UNLIMITED_DURATION
      }

      const preSelectedDiscount = getDiscountInfo(discountOptions, {
        discountCode,
        value,
        unit,
        duration,
      })

      onDiscountPreSelect(preSelectedDiscount)

      setSelectedAmount(e.target.value)
      setSelectedDuration(duration)
      setDurationOptions(amountDurationOptions)

      return
    }

    setSelectedAmount(e.target.value)
    setSelectedDuration('')
    setDurationOptions(amountDurationOptions)
  }

  const onDurationSelect = e => {
    let durationPermanencyOptions

    const durationValue = e.target.value
    const { discountCode, value, unit } = JSON.parse(selectedAmount)
    const retConv40 = isRetConv40(discountCode, value)
    const retConv30 = isRetConv30(discountCode, value)
    const is100MbPreocupateCeroDiscount = is100MbPreocupateCero(discountCode, value)
    const convergentDiscount50Pro = isConvergentDiscount50Pro(discountCode)
    const convergent5EurosDiscount = isConvergent5EurosDiscount(discountCode)
    const amazonDiscount = isAmazonDiscount(discountCode)
    const is100MbTariffDiscount = is100MbDiscount(discountCode, value)
    const mobileMainTariffDiscount = isMobileMainTariffDiscount(discountCode)
    const discPerm = isDiscPerm(discountCode)
    const pruebaloDiscount = isPruebaloResidentialDiscount(discountCode) || 
                             isPruebaloProDiscount(discountCode) || 
                             isPruebaloDigitalDiscount(discountCode)

    const hasPermanency = hasDiscountPermanency(
      discountCode,
      value,
      durationValue,
      is100MbTariffDiscount,
      profileId,
    )

    if (hasPermanency) {
      if (is100MbPreocupateCeroDiscount || ((retConv40 || retConv30) || convergentDiscount50Pro && is1WSales)) {
        durationPermanencyOptions = [{ text: 'Sí', value: getPermanencyId(durationValue) }]
      } else if(pruebaloDiscount) {
        durationPermanencyOptions = [{ text: 'Sí', value: PRUEBALO_DISCOUNTS_PERMANENCY[discountCode] }]
      } else if(amazonDiscount) {
        durationPermanencyOptions = [{ text: 'Sí', value: DTERM1241 }]
      } else if(discPerm) {
        durationPermanencyOptions = [{ text: 'Sí', value: DTERM2450 }]
      } else if(convergent5EurosDiscount) {
        durationPermanencyOptions = [{ text: 'Sí', value: DTERM1250 }]
      } else if (is100MbTariffDiscount) {
        durationPermanencyOptions = [{ text: 'Sí', value: getPermanencyId(PERMANENCY_12_MONTHS) }]
      } else if (mobileMainTariffDiscount) {
        durationPermanencyOptions = [
          { text: 'Sí', value: DTERM12150 },
          { text: 'No', value: '' },
        ]
      } else {
        durationPermanencyOptions = [
          { text: 'Sí', value: getPermanencyId(durationValue) },
          { text: 'No', value: '' },
        ]
      }
    } else {
      durationPermanencyOptions = []
    }

    const preSelectedDiscount = getDiscountInfo(discountOptions, {
      discountCode,
      value,
      unit,
      duration: parseInt(durationValue, 10),
      permanency: '',
    })

    if (!hasPermanency) {
      onDiscountPreSelect(preSelectedDiscount)
    }

    setSelectedDuration(durationValue)
    setPermanencyOptions(durationPermanencyOptions)
    setSelectedPermanency(null)
  }

  const onPermanencySelect = e => {
    const permanency = !isEmpty(e.target.value) ? e.target.value : ''
    const { discountCode, value, unit } = JSON.parse(selectedAmount)
    const is100MbTariffDiscount = is100MbDiscount(discountCode, value)

    const preSelectedDiscount = getDiscountInfo(discountOptions, {
      discountCode,
      value,
      unit,
      duration: parseInt(selectedDuration, 10),
      permanency,
    })

    if (is100MbTariffDiscount) {
      preSelectedDiscount.monthPermanency = PERMANENCY_12_MONTHS
    }

    onDiscountPreSelect(preSelectedDiscount)

    setSelectedPermanency(permanency)
  }

  const onSubmit = () => {
    onDiscountSelect()
    goToNextStep()
  }

  const props = {
    amountOptions: formatDiscountsAmounts(discountOptions || [], profileId),
    durationOptions,
    permanencyOptions,
    onAmountSelect,
    selectedAmount,
    selectedDuration,
    selectedPermanency,
    onDurationSelect,
    onPermanencySelect,
    onSubmit,
    goToPreviousStep,
    hasIncompatibilities,
    profileId,
  }

  if (isDiscountsLoading) {
    return <SpinnerCenter />
  }

  return <DiscountForm {...props} />
}

DiscountFormContainer.propTypes = {
  subscription: PropTypes.object,
  isFixedReduction: PropTypes.bool,
  newTariffId: PropTypes.string,
  currentTariff: PropTypes.string,
  infiniteTariff: PropTypes.string,
  hasIncompatibilities: PropTypes.bool,
  onDiscountSelect: PropTypes.func,
  onDiscountPreSelect: PropTypes.func,
  goToNextStep: PropTypes.func,
  goToPreviousStep: PropTypes.func,
}
