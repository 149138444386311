export const GET_PONC_INIT = 'GET_PONC_INIT'
export const GET_PONC = 'GET_PONC'
export const GET_PONC_SUCCESS = 'GET_PONC_SUCCESS'
export const GET_PONC_FAILED = 'GET_PONC_FAILED'

export const getPoncInitAction = orderId => ({
  type: GET_PONC_INIT,
  payload: {
    orderId,
  },
})

export const getPoncAction = () => ({
  type: GET_PONC,
})

export const getPoncSuccessAction = options => ({
  type: GET_PONC_SUCCESS,
  payload: {
    options,
  },
})

export const getPoncFailedAction = error => ({
  type: GET_PONC_FAILED,
  payload: {
    error,
  },
})
