import { get } from 'lodash'
import { createSelector } from 'reselect'

import { selectSVAAgileList } from 'modules/SVAs/store'
import { formatSingleSVA } from 'modules/SVAs/helpers'
import { selectChangeAgileTvFlag } from 'services/feature-flag/selectors'

export const getChangeAgileTvData = state => get(state, 'changeAgileTv', {})

export const selectAgileTvSvaWithStb = psid =>
  createSelector([selectSVAAgileList], svas => {
    const agileTvSva = svas.filter(sva => sva.withStb && sva.psId !== psid)

    if (!agileTvSva) {
      return null
    }

    return agileTvSva.map(agileTv => formatSingleSVA(agileTv))
  })

export const selectChangeAgileTvOrderIsLoading = createSelector(
  [getChangeAgileTvData],
  changeAgileTv => get(changeAgileTv, 'loading'),
)
export const selectChangeAgileTvOrderError = createSelector([getChangeAgileTvData], changeAgileTv =>
  get(changeAgileTv, 'error'),
)
export const selectChangeAgileTvOrderIsSuccess = createSelector(
  [getChangeAgileTvData],
  changeAgileTv => get(changeAgileTv, 'success'),
)

export const selectChangeAgileTv = createSelector([selectChangeAgileTvFlag], flag => flag)
