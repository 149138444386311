import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getLegalTextsAction, selectLegalTextsByOrigin, selectLegalTextsById } from '../store'

export function useLegalTexts({ origin, textId }) {
  /**
   * If you pass textId you will get a single item, if you only pass origin, then you will get a list
   */
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getLegalTextsAction({ origin, textId }))
  }, [])

  const { isLoading, legalTexts } = useSelector(
    textId ? selectLegalTextsById(origin, textId) : selectLegalTextsByOrigin(origin),
  )

  return { isLoading, legalTexts }
}
