import { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

import { useSelector } from 'react-redux'
import { useFormikContext } from 'formik'
import { get, head } from 'lodash'

import { Typography } from '@material-ui/core'
import { TextStyles } from 'utils/text'

import { Button, Modal } from 'components/ui'
import { fetchFinancingConditions } from 'modules/financing'
import { onResetTerminal, useTerminalProffession } from 'modules/NewClientSales/hooks/Terminals'
import { useTerminalListForPs } from 'modules/NewClientSales/hooks/Terminals/useTerminalListForPs'
import { selectIsElFijo, selectIsPosSale } from 'modules/NewClientSales/store/'
import { Terminal } from 'modules/NewClientSales/store/models'
import { TerminalSelection } from 'modules/Terminals/components/TerminalSelectionComponent/index'
import { SINGLE } from 'services/global-constants'
import { DEVICE_SET_TOP_BOX } from 'modules/NewClientSales/constants/devicesCategory'
import { selectSVAAppleTv } from 'modules/NewClientSales/store/selectors'
import { LINE_TYPE } from 'modules/Lines'
import { selectAppleTvFlag } from 'services/feature-flag/selectors'
import { selectResellerQueryParam } from 'modules/Router/store/index'
import { useTerminalSelectionStore } from '../../../hooks/Terminals/useTerminalStore'
import { getFormikProps, mapToTerminalValues } from './TerminalSelectionContainer.helpers'
import { useSetFormikValuesToBasket } from '../../../hooks/Shared'

export function TerminalSelectionContainer({
  channel,
  fieldNames,
  disableOfferEdit,
  isLeadFilled,
  shouldDisableTerminal,
}) {
  const appleTvEnabled = useSelector(selectAppleTvFlag)

  const lineType = fieldNames.LINE_TYPE
  const isElFijo = useSelector(selectIsElFijo)
  const isPosSale = useSelector(selectIsPosSale)
  const reseller = useSelector(selectResellerQueryParam)

  const setFormikValuesToBasket = useSetFormikValuesToBasket()

  const isAppleTv = useSelector(selectSVAAppleTv) && lineType === LINE_TYPE.MAIN_MOBILE
  const setTopBoxCategory = DEVICE_SET_TOP_BOX

  const { values, errors, touched, setFieldValue, setFieldTouched } = useFormikContext()
  const {
    signUpType,
    terminalCategory,
    terminalBrand,
    terminalId,
    ps,
    mobileNumberType,
    reservationError,
    paymentType,
    portaPaymentType,
    isNewTerminal,
    jobId,
  } = getFormikProps(values, errors, touched, fieldNames, isElFijo)

  const {
    terminalDetails,
    isCompany,
    professionOptions,
    isMobileOnly,
    setTerminalInformation,
    fetchTerminalDetails,
  } = useTerminalSelectionStore(lineType, paymentType)

  const {
    isLoadingTerminalList,
    terminalListError,
    terminalList,
    fetchTerminals,
  } = useTerminalListForPs({
    shouldFetchList: isNewTerminal,
    ps,
    mobileNumberType,
  })

  const { showCompany, onSelectProfession } = useTerminalProffession(
    paymentType,
    jobId,
    setFieldValue,
    fieldNames.COMPANY,
  )

  const { resetTerminalData, setPaymentTypeTerminal } = onResetTerminal()

  // TODO: Fuse this with basket state so we can remove this and bruteTerminals
  const terminalModels = useMemo(() => {
    const ignored = []

    if (!isAppleTv) {
      ignored.push(setTopBoxCategory)
    }

    return mapToTerminalValues(terminalList, ignored)
  }, [terminalList, isAppleTv])

  const [isFinancialsLoading, setIsFinancialsLoading] = useState()
  const [terminalByIMEI, setTerminalByIMEI] = useState(null)
  const [isUpdateTerminalModalOpen, setIsUpdateTerminalModalOpen] = useState(false)

  const [isLoadingAppleTv, setIsLoadingAppleTv] = useState(false)

  function onModelChange(model) {
    resetTerminalData({ lineType: fieldNames.TERMINAL_LINE_TYPE })

    if (!model || !model.brand) {
      setFieldValue(fieldNames.TERMINAL_ID, '')
      setFieldValue(fieldNames.TERMINAL_DETAILS, '')
      return
    }

    if (model.terminalId === terminalId) {
      return
    }

    setFieldValue(fieldNames.IMEI, '')
    setFieldValue(fieldNames.TERMINAL_PAYMENT, '')
    setFieldValue(fieldNames.TERMINAL_DETAILS, {})

    setFieldValue(fieldNames.TERMINAL_MANUFACTURER, model.brand)

    setFieldValue(fieldNames.TERMINAL_CATEGORY, model.category)
    setFieldValue(fieldNames.TERMINAL_ID, model.terminalId)
    setFieldTouched(fieldNames.TERMINAL_RESERVATION_ID, false, false)
    setFieldTouched(fieldNames.TERMINAL_MANUFACTURER, false, false)
    setFieldTouched(fieldNames.IMEI, false, false)
  }

  function onCategoryChange(category) {
    resetTerminalData({ lineType: fieldNames.TERMINAL_LINE_TYPE })

    if (!category) {
      setFieldValue(fieldNames.TERMINAL_CATEGORY, '')
      setFieldValue(fieldNames.TERMINAL_MANUFACTURER, '')
      setFieldValue(fieldNames.TERMINAL_ID, '')
      setFieldValue(fieldNames.TERMINAL_DETAILS, '')
      return
    }

    if (category.label === terminalCategory) {
      return
    }

    setFieldValue(fieldNames.TERMINAL, Terminal)
    setFieldTouched(fieldNames.TERMINAL_RESERVATION_ID, false, false)
    setFieldValue(fieldNames.TERMINAL_ID, '')
    setFieldValue(fieldNames.TERMINAL_MANUFACTURER, '')
    setFieldValue(fieldNames.TERMINAL_CATEGORY, category.key)
  }

  function onBrandChange(brand) {
    resetTerminalData({ lineType: fieldNames.TERMINAL_LINE_TYPE })
    if (!brand) {
      setFieldValue(fieldNames.TERMINAL_MANUFACTURER, '')
      setFieldValue(fieldNames.TERMINAL_ID, '')
      setFieldValue(fieldNames.TERMINAL_DETAILS, '')
      return
    }

    if (brand.label === terminalBrand) {
      return
    }

    setFieldValue(fieldNames.TERMINAL, Terminal)
    setFieldTouched(fieldNames.TERMINAL_RESERVATION_ID, false, false)
    setFieldValue(fieldNames.TERMINAL_ID, '')
    setFieldValue(fieldNames.TERMINAL_CATEGORY, terminalCategory)
    setFieldValue(fieldNames.TERMINAL_MANUFACTURER, brand.label)
  }

  function onIMEIPsIdChange(psId) {
    if (!psId || psId === terminalId) {
      return
    }

    setTerminalByIMEI(null)
    terminalList.forEach(category => {
      category.brands.forEach(brand => {
        brand.models.forEach(model => {
          if (model.terminalId === psId) {
            setTerminalByIMEI({
              brand: brand.brand,
              name: model.name,
              terminalId: model.terminalId,
            })
            setIsUpdateTerminalModalOpen(true)
          }
        })
      })
    })
  }

  function selectNewTerminal(wantUpdateSelectedTerminal) {
    setIsUpdateTerminalModalOpen(false)
    if (!wantUpdateSelectedTerminal) {
      setFieldValue(fieldNames.IMEI, '')
      return
    }

    setFieldValue(fieldNames.TERMINAL_MANUFACTURER, terminalByIMEI.brand)
    setFieldValue(fieldNames.TERMINAL_ID, terminalByIMEI.terminalId)
    resetTerminalData({ lineType: fieldNames.TERMINAL_LINE_TYPE })
  }

  function onPaymentTypeChange(e) {
    setFieldValue(fieldNames.TERMINAL_PAYMENT, e.target.value)
    setPaymentTypeTerminal({
      data: e.target.value,
      lineType: fieldNames.TERMINAL_LINE_TYPE,
    })
  }

  function onAddTerminalChange(e) {
    setFormikValuesToBasket(fieldNames.HAS_TERMINAL, e.target.value)
    if (e.target.value === 'no') {
      resetTerminalData({ lineType: fieldNames.TERMINAL_LINE_TYPE })
      setFieldValue(fieldNames.TERMINAL, Terminal)
      setFieldTouched(fieldNames.TERMINAL_RESERVATION_ID, false, false)
    }
  }

  function preSelectPaymentType(details) {
    const typeOfPayments = Object.keys(get(details, 'paymentMethods', []))

    if (typeOfPayments.length === 1) {
      setFieldValue(fieldNames.TERMINAL_PAYMENT, head(typeOfPayments))
      setPaymentTypeTerminal({
        data: head(typeOfPayments),
        lineType: fieldNames.TERMINAL_LINE_TYPE,
      })
    }
  }

  useEffect(() => {
    if (appleTvEnabled && isAppleTv && !terminalId) {
      onAddTerminalChange({ target: { value: 'yes' } })
      setIsLoadingAppleTv(true)
    }
  }, [isAppleTv, terminalId])

  useEffect(() => {
    if (isLoadingTerminalList) {
      setIsLoadingAppleTv(false)
    }
  }, [isLoadingTerminalList])

  useEffect(() => {
    if (
      appleTvEnabled &&
      isAppleTv &&
      !terminalId &&
      isNewTerminal &&
      !isLoadingAppleTv &&
      terminalModels
    ) {
      const firstModelOnSetTopBoxCategory = terminalModels.find(
        device => device.category === setTopBoxCategory,
      )
      onModelChange(firstModelOnSetTopBoxCategory)
    }
  }, [isAppleTv, isNewTerminal, terminalId, terminalModels, isLoadingAppleTv])

  // Fetch terminal details
  useEffect(() => {
    if (!terminalDetails.id && terminalId) {
      let data = {
        tariffId: ps,
        terminalId,
        mobileNumberType,
        channel,
      }

      if (isPosSale) {
        data = {
          ...data,
          reseller,
        }
      }

      fetchTerminalDetails({
        lineType,
        isStockCheck: false,
        data,
      })
    }
  }, [terminalId])

  // Set formik state on terminal details
  useEffect(() => {
    preSelectPaymentType(terminalDetails)
    setFieldValue(fieldNames.TERMINAL_DETAILS, terminalDetails)
  }, [terminalDetails.isLoading])

  // Logic to fetch terminal financial data
  useEffect(() => {
    if (paymentType && paymentType !== SINGLE) {
      const { fees, finalPayment, monthlyPayment } = terminalDetails
      setIsFinancialsLoading(true)
      fetchFinancingConditions({
        monthlyPayment,
        totalMonths: fees,
        finalPayment: finalPayment || 0,
      })
        .then(res => {
          setTerminalInformation({
            lineType,
            data: { ...terminalDetails, ...res },
          })
          setFieldValue(fieldNames.TERMINAL_DETAILS, { ...terminalDetails, ...res })
          setIsFinancialsLoading(false)
        })
        .catch(() => {
          setTerminalInformation({ lineType, data: terminalDetails })
          setFieldValue(fieldNames.TERMINAL_DETAILS, terminalDetails)
          setIsFinancialsLoading(false)
        })
    }
  }, [paymentType])

  return (
    <>
      <TerminalSelection
        isPosSale={isPosSale}
        isElFijo={isElFijo}
        isFinancialsLoading={isFinancialsLoading}
        isMobileOnly={isMobileOnly}
        fieldNames={fieldNames}
        onAddTerminalChange={onAddTerminalChange}
        portaPaymentType={portaPaymentType}
        signUpType={signUpType}
        paymentType={paymentType}
        terminalModels={terminalModels}
        onCategoryChange={onCategoryChange}
        onModelChange={onModelChange}
        onBrandChange={onBrandChange}
        terminalDetails={terminalDetails}
        isListLoading={isLoadingTerminalList}
        listError={terminalListError}
        onPaymentTypeChange={onPaymentTypeChange}
        onValidIMEI={onIMEIPsIdChange}
        professionOptions={professionOptions}
        onSelectProfession={onSelectProfession}
        reservationError={reservationError}
        isNewTerminal={isNewTerminal}
        isCompany={isCompany}
        shouldShowCompany={showCompany}
        disableOfferEdit={disableOfferEdit}
        isLeadFilled={isLeadFilled}
        shouldDisableTerminal={shouldDisableTerminal}
        fetchTerminals={fetchTerminals}
        isAppleTv={isAppleTv}
      />
      <Modal isOpen={isUpdateTerminalModalOpen} width="500px" data-hook="icc-error-modal">
        <div className="flex align-center-start">
          <Typography {...TextStyles.paragraphDark()}>
            ¿Desea seleccionar terminal escaneado?
          </Typography>
        </div>
        <div className="p-y-12 m-t-8 flex align-space-around">
          <Button type="text" data-hook="close-modal" onClick={() => selectNewTerminal(true)}>
            SI
          </Button>
          <Button type="text" data-hook="close-modal" onClick={() => selectNewTerminal(false)}>
            NO
          </Button>
        </div>
      </Modal>
    </>
  )
}

TerminalSelectionContainer.propTypes = {
  channel: PropTypes.string.isRequired,
  fieldNames: PropTypes.object.isRequired,
  disableOfferEdit: PropTypes.bool,
  isLeadFilled: PropTypes.bool,
  shouldDisableTerminal: PropTypes.bool,
}
