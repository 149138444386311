import { useCallback, useState, useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import { push } from 'connected-react-router'
import { get } from 'lodash'

import { fetchContentfulResourceAction } from 'modules/contentful'
import { isSameCoverage } from 'modules/Coverage/helpers'
import { actions as taxes } from 'services/taxes'
import {
  resetMainTariffsAction,
  resetAllTariffsAction,
} from 'modules/tariffs/store/tariffs.actions'

import { useRequestIpAddress } from 'modules/Auth/hooks'

import {
  setBasketTechnologyData,
  setBasketTariff,
  LandlineOffer,
  MobileLineOffer,
  fetchPrescoringAction,
  selectBasketRequestLoading,
} from 'modules/NewClientSales/store'

import { Basket } from 'modules/NewClientSales/store/models'

import { SIGNUP_TYPE_NEWLINE, SIGNUP_TYPE_PORTABILITY, COMPANY } from 'services/global-constants'
import { withCheckoutForms } from '../../hocs/withCheckoutForms'
import { useSetCustomerInfo, useSetPresaleData } from '../../hooks/Shared'
import { OFFERS, BILLING } from '../../constants/index'

import {
  selectClientInfo,
  selectHasClientInfoChanged,
  getFormikProps,
} from './ClientInfoContainer.selectors'
import { ClientInfo } from '../../components/ClientInfo'

import {
  nebaResponse,
  ftthResponse,
  adslResponse,
  vulaResponse,
} from '../../../Coverage/services/mocks'
import { useReserveTerminals } from '../../hooks/Terminals'
import { useFormDataIsChanged } from '../../hooks/Shared/useFormDataIsChanged'
import { getSavePresaleRequestBody } from '../../helpers/presale.helpers'

const coverageMocks = {
  ftth: ftthResponse,
  adsl: adslResponse,
  neba: nebaResponse,
  vula: vulaResponse,
}
function ClientInfoCnt({
  queryParams,
  getCoverageData,
  getNextStepUrlPath,
  isEditing,
  setBasketData,
}) {
  const dispatch = useDispatch()
  const location = useLocation()

  const [isLoadingCoverage, setIsLoadingCoverage] = useState(true)
  const [hasChangedCoverage, setHasChangedCoverage] = useState(false)

  const { requestIpAddress } = useRequestIpAddress()
  const { values, signUpType, lineNumber, isPortability, setFieldValue, account, setValues } =
    getFormikProps()

  const {
    serviceAddressZipCode,
    isServiceSameAsBilling,
    isExistingClientFlow,
    isSubscription,
    isCrossSell,
    isMultiSim,
    isPresale,
    prescoringReference,
    presaleData,
    prescoringData,
    prescoringFailed,
    isCustomerAllowed,
    ...storeSelectors
  } = useSelector(selectClientInfo)
  const hasClientInfoChanged = useSelector(state =>
    selectHasClientInfoChanged(state, {
      formikAccount: account,
      storeAccount: storeSelectors.basketAcccount,
    }),
  )

  const segment = get(account, 'segment')
  const isCompany = segment === COMPANY

  const isGoingBack = location?.state?.goingBack

  const shouldUpdate = useMemo(() => {
    return isExistingClientFlow && !isGoingBack && !isEditing
  }, [isExistingClientFlow, location.state, isEditing])

  useSetCustomerInfo(shouldUpdate)
  useSetPresaleData(presaleData, isPresale && !isGoingBack && !isEditing)

  const [formDataPrescoringIsChanged, resetFormDataPrescoringIsChanged] =
    useFormDataIsChanged(values)

  const currentPresaleData =
    isPresale && values.presaleId ? getSavePresaleRequestBody(values).data : null

  const [presaleDataIsChanged, resetPresaleDataIsChanged] = useFormDataIsChanged(
    currentPresaleData && {
      landlineOffer: currentPresaleData.landlineOffer,
      account: currentPresaleData.account,
    },
    isPresale &&
      presaleData && {
        landlineOffer: presaleData.data.landlineOffer,
        account: presaleData.data.account,
      },
  )

  const { onReserveTerminals } = useReserveTerminals()

  const isLoading = useSelector(selectBasketRequestLoading)

  const shouldGoSummary =
    isEditing && ((!hasClientInfoChanged && !hasChangedCoverage) || isSubscription)

  const dispatchActions = {
    fetchContentfulResource: useCallback(data => {
      dispatch(fetchContentfulResourceAction(data))
    }),
    setBasketTechnologyData: useCallback(data => {
      dispatch(setBasketTechnologyData(data))
    }),
    requestIpAddress: useCallback(() => {
      requestIpAddress()
    }),
    resetMainTariffs: useCallback(() => {
      dispatch(resetMainTariffsAction())
    }),
  }

  function onProvinceChange() {
    setFieldValue('landlineOffer.lineNumber', '')
    setFieldValue('landlineOffer.newNumber', '')
  }

  function beforeContinue() {
    setBasketData(values)
    if (isSubscription) {
      return onReserveTerminals()
    }
    return false
  }

  function onTechnologyChange() {
    setValues({
      ...values,
      landlineOffer: { ...LandlineOffer, isPortability, signUpType, lineNumber },
      mainMobileLineOffer: MobileLineOffer,
      extraMobileLinesOffers: [],
    })
  }

  function setCoverageToValues(coverageData) {
    if (!coverageData) return
    if (
      coverageData.province !== account.province &&
      (!isPresale || presaleData.data.account.province !== account.province)
    ) {
      onProvinceChange()
    }
    if (!isSameCoverage(account.technology, coverageData.coverage)) onTechnologyChange()
    setFieldValue('account.province', coverageData.province)
    setFieldValue('account.technology', coverageData.coverage)
    setFieldValue('terminalShipping.deliveryPoint.zipCode', coverageData.zipCode)
    setFieldValue('landlineOffer.installationAddress', coverageData)
  }

  function onCoverageCheckFinished(coverageData) {
    setIsLoadingCoverage(false)
    setHasChangedCoverage(true)
    getCoverageData(coverageData)
    dispatchActions.resetMainTariffs()
  }

  function onSetConvergentInfo() {
    if (isPortability === 'no' && lineNumber) {
      setFieldValue('landlineOffer.lineNumber', '')
    }
  }

  async function onContinue() {
    if (isServiceSameAsBilling) dispatch(taxes.findTax(serviceAddressZipCode))

    onSetConvergentInfo()

    const nextStep = getNextStepUrlPath(
      isSubscription || isMultiSim ? BILLING : OFFERS,
      shouldGoSummary,
    )

    if (
      (isPresale && !presaleDataIsChanged) ||
      !formDataPrescoringIsChanged ||
      isMultiSim ||
      (!storeSelectors.isCartera && !isPresale && !isCrossSell && !isCompany)
    ) {
      dispatch(push(nextStep))
      return
    }

    dispatch(
      fetchPrescoringAction({
        nextStep,
      }),
    )
  }

  useEffect(() => {
    resetFormDataPrescoringIsChanged()
    resetPresaleDataIsChanged()
  }, [prescoringReference])

  function onResetCoverage() {
    getCoverageData({})
    setCoverageToValues({})
    dispatchActions.resetMainTariffs()
  }

  function handleIsPortability(e) {
    if (e.target.value === 'no') {
      setFieldValue('landlineOffer.signUpType', SIGNUP_TYPE_NEWLINE)
    } else {
      setFieldValue('landlineOffer.signUpType', SIGNUP_TYPE_PORTABILITY)
    }
  }

  function resetAllTariffsAndBasket() {
    if (!storeSelectors.isCartera) {
      setBasketData(Basket)
    } else {
      dispatch(
        setBasketTariff({
          landlineOffer: LandlineOffer,
          mainMobileLineOffer: MobileLineOffer,
        }),
      )
    }
    getCoverageData({})
    dispatch(resetAllTariffsAction())
  }

  useEffect(() => {
    dispatchActions.fetchContentfulResource('customerSegments', { brands: 'yoigo' })
    dispatchActions.fetchContentfulResource('countries')
    requestIpAddress()

    if (queryParams.isTesting) {
      onCoverageCheckFinished(coverageMocks[queryParams.technology])
    }

    if (storeSelectors.isElFijo || storeSelectors.isMobileOnly) {
      onResetCoverage()
    }
  }, [])

  useEffect(() => {
    setCoverageToValues(storeSelectors.coverageData)
  }, [storeSelectors.coverageData])

  return (
    <>
      <ClientInfo
        installationAddress={storeSelectors.installationAddress}
        onResetCoverage={onResetCoverage}
        isPortability={isPortability}
        setBasketData={setBasketData}
        nextStep={OFFERS}
        isLoadingCoverage={isLoadingCoverage}
        onCoverageCheckFinished={onCoverageCheckFinished}
        onContinue={onContinue}
        isLoading={isLoading}
        isEditing={shouldGoSummary}
        handleIsPortability={handleIsPortability}
        queryParams={queryParams}
        resetAllTariffsAndBasket={resetAllTariffsAndBasket}
        setFieldValue={setFieldValue}
        isSubscription={isSubscription}
        isCrossSell={!!isCrossSell}
        isMultiSim={!!isMultiSim}
        beforeContinue={beforeContinue}
        isPresale={!!isPresale}
        prescoringData={prescoringData}
        prescoringFailed={prescoringFailed}
        isCompany={isCompany}
        isCustomerAllowed={isCustomerAllowed}
        {...storeSelectors}
      />
    </>
  )
}

ClientInfoCnt.propTypes = {
  basket: PropTypes.object.isRequired,
  setBasketData: PropTypes.func.isRequired,
  queryParams: PropTypes.object.isRequired,
  getCoverageData: PropTypes.func.isRequired,
  navigateToNextStep: PropTypes.func.isRequired,
  getNextStepUrlPath: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
}

// Do we want to create the form like this or somehow else?
export const ClientInfoContainer = withCheckoutForms(ClientInfoCnt)
