export const BASE_FEE_NAME = 'base'

export const FIXED_PRODUCT_NAME = 'fixed'
export const MOBILE_PRODUCT_NAME = 'mobile'
export const OTHER_SERVICE_PRODUCT_NAME = 'Other Services'

export const SUBSCRIPTION_STATUS_ACTIVE = 'active'
export const SUBSCRIPTION_STATUS_VALIDATED = 'validated'
export const SUBSCRIPTION_STATUS_ALERT = 'alert'
export const SUBSCRIPTION_STATUS_INFO = 'info'
export const SUBSCRIPTION_STATUS_ATTENTION = 'attention'
export const SUBSCRIPTION_STATUS_BLOCKED = 'blocked'
export const SUBSCRIPTION_STATUS_DEFAULT = 'default'
export const SUBSCRIPTION_STATUS_OT = 'ot'

export const POSTPAID_CHARACTERISTIC_NAME = 'postpaid'
export const PREPAID_CHARACTERISTIC_NAME = 'prepaid'

export const PAYMENT_TYPES = [POSTPAID_CHARACTERISTIC_NAME, PREPAID_CHARACTERISTIC_NAME]

export const PRODUCT_TYPE_TO_ITEM_CODE = {
  [FIXED_PRODUCT_NAME]: '0',
  [MOBILE_PRODUCT_NAME]: '7',
  [OTHER_SERVICE_PRODUCT_NAME]: '55',
}

export const CONNECTION_SPEC = 'connection-type'

export const ADSL_TYPE = 'adsl'
export const FIBER_TYPE = 'fiber'

export const TARIFF_CHANGE_EVENT_CODE = 0

export const DONE_TARIFF_CHANGE_STATUS_CODE = 2
export const CANCELLED_TARIFF_CHANGE_STATUS_CODE = 4
export const ONGOING_TARIFF_CHANGE_STATUS_CODE = 8

export const SVA_STATUS_DISABLED = 0
export const SVA_STATUS_ENABLED = 1
export const SVA_STATUS_LITERAL_DISABLED = 'Inactive'
export const SVA_STATUS_LITERAL_ENABLED = 'Active'

export const RETENTION_BONUS_PREFIX = 'RET'
export const FIDELITY_BONUS_PREFIX = 'FID'

export const BONUS_CODE_1GB = 'DATA6'
export const BONUS_CODE_3GB = 'DATA7'
export const BONUS_CODES = [BONUS_CODE_1GB, BONUS_CODE_3GB]

export const FIND_SUBSCRIPTIONS_ATTEMPTED = 'OVID/SUBSCRIPTION/FIND'
export const FIND_SUBSCRIPTIONS_SUCCEEDED = 'OVID/SUBSCRIPTION/FOUND'
export const FIND_SUBSCRIPTIONS_FAILED = 'OVID/SUBSCRIPTION/FOUND_FAILED'
export const RESET_SUBSCRIPTIONS = 'OVID/SUBSCRIPTION/RESET'

export const FIND_SUBSCRIPTION_BY_ID_ATTEMPTED = 'OVID/SUBSCRIPTION/FIND_BY_ID'
export const FIND_SUBSCRIPTION_BY_ID_SUCCEEDED = 'OVID/SUBSCRIPTION/FOUND_BY_ID'
export const FIND_SUBSCRIPTION_BY_ID_FAILED = 'OVID/SUBSCRIPTION/FOUND_BY_ID_FAILED'

export const CHANGE_TARIFF_ATTEMPTED = 'OVID/SUBSCRIPTION/CHANGE_TARIFF/ATEMPT'
export const CHANGE_TARIFF_SUCCEEDED = 'OVID/SUBSCRIPTION/CHANGE_TARIFF/SUCCESS'
export const CHANGE_TARIFF_FAILED = 'OVID/SUBSCRIPTION/CHANGE_TARIFF/ERROR'
export const CHANGE_TARIFF_CLEAR_ERRORS = 'OVID/SUBSCRIPTION/CHANGE_TARIFF/CLEAR_ERRORS'

export const SET_BUNDLES_ATTEMPTED = 'OVID/SUBSCRIPTION/SET_BUNDLES/ATTEMPTED'
export const SET_BUNDLES_SUCCEEDED = 'OVID/SUBSCRIPTION/SET_BUNDLES/SUCCESS'
export const SET_BUNDLES_FAILED = 'OVID/SUBSCRIPTION/SET_BUNDLES/ERROR'

export const DISABLE_DATA_BONUS_ATTEMPTED = 'OVID/SUBSCRIPTION/DISABLE_DATA_BONUS/ATTEMPTED'
export const DISABLE_DATA_BONUS_SUCCEEDED = 'OVID/SUBSCRIPTION/DISABLE_DATA_BONUS/SUCCESS'
export const DISABLE_DATA_BONUS_FAILED = 'OVID/SUBSCRIPTION/DISABLE_DATA_BONUS/ERROR'
export const DISABLE_DATA_BONUS_CLEAR_ERRORS = 'OVID/SUBSCRIPTION/DISABLE_DATA_BONUS/CLEAR_ERRORS'

export const DISABLE_SVA_SMARTHOME_ATTEMPTED = 'OVID/SUBSCRIPTION/DISABLE_SVA_SMARTHOME/ATTEMPTED'
export const DISABLE_SVA_SMARTHOME_SUCCEEDED = 'OVID/SUBSCRIPTION/DISABLE_SVA_SMARTHOME/SUCCESS'
export const DISABLE_SVA_SMARTHOME_FAILED = 'OVID/SUBSCRIPTION/DISABLE_SVA_SMARTHOME/ERROR'
export const DISABLE_SVA_SMARTHOME_RESET = 'OVID/SUBSCRIPTION/DISABLE_SVA_SMARTHOME/RESET'

export const DISABLE_SVA_HOMEGO_ATTEMPTED = 'OVID/SUBSCRIPTION/DISABLE_SVA_HOMEGO/ATTEMPTED'
export const DISABLE_SVA_HOMEGO_SUCCEEDED = 'OVID/SUBSCRIPTION/DISABLE_SVA_HOMEGO/SUCCESS'
export const DISABLE_SVA_HOMEGO_FAILED = 'OVID/SUBSCRIPTION/DISABLE_SVA_HOMEGO/ERROR'
export const DISABLE_SVA_HOMEGO_RESET = 'OVID/SUBSCRIPTION/DISABLE_SVA_HOMEGO/RESET'

export const SET_SVA_ATTEMPTED = 'OVID/SUBSCRIPTION/SET_SVA/ATTEMPTED'
export const SET_SVA_SUCCEEDED = 'OVID/SUBSCRIPTION/SET_SVA/SUCCESS'
export const SET_SVA_FAILED = 'OVID/SUBSCRIPTION/SET_SVA/ERROR'
export const SET_SVA_RESET = 'OVID/SUBSCRIPTION/SET_SVA/RESET'

export const SET_UPSELL_BUNDLES_ATTEMPTED = 'OVID/SUBSCRIPTION/SET_UPSELL_BUNDLES/ATTEMPTED'
export const SET_UPSELL_BUNDLES_SUCCEEDED = 'OVID/SUBSCRIPTION/SET_UPSELL_BUNDLES/SUCCESS'
export const SET_UPSELL_BUNDLES_FAILED = 'OVID/SUBSCRIPTION/SET_UPSELL_BUNDLES/ERROR'

export const FIND_PORT_OUT_INFO_ATTEMPTED = 'OVID/SUBSCRIPTION/FIND_PORT_OUT_INFO/ATTEMPT'
export const FIND_PORT_OUT_INFO_SUCCEEDED = 'OVID/SUBSCRIPTION/FIND_PORT_OUT_INFO/SUCCESS'
export const FIND_PORT_OUT_INFO_FAILED = 'OVID/SUBSCRIPTION/FIND_PORT_OUT_INFO/ERROR'

export const FIND_DOCUMENT_CDCT_WORKORDER_ID_ATTEMPTED = 'OVID/SUBSCRIPTION/FIND_DOCUMENT_CDCT_WORKORDER_ID/ATTEMPTED'
export const FIND_DOCUMENT_CDCT_WORKORDER_ID_SUCCEEDED = 'OVID/SUBSCRIPTION/FIND_DOCUMENT_CDCT_WORKORDER_ID/SUCCESS'
export const FIND_DOCUMENT_CDCT_WORKORDER_ID_FAILED = 'OVID/SUBSCRIPTION/FIND_DOCUMENT_CDCT_WORKORDER_ID/ERROR'

export const CALL_RECORD_SORT_NUMBERS = 2
export const CALL_RECORD_INTERNATIONAL_CALL = 9
export const CALL_RECORD_PREFIX_800 = 64
export const CALL_RECORD_PREFIX_900 = 69
export const CALL_RECORD_TYPE_CALL = 'CALL'
export const CALL_RECORD_TYPE_GPRS = 'GPRS'
export const CALL_RECORD_TYPE_SMS = 'SMS'

export const STC_RULES = {
  MOBILE: 2,
  FIXED: 4,
}

export const MIN_UNLIMITED_MINUTES = 4500
export const MIN_UNLIMITED_GB = 230

export const MIN_UNLIMITED_MINUTES_F2F = 0
export const MIN_UNLIMITED_MINUTES_F2M = 46000

export default {
  FIND_SUBSCRIPTIONS_ATTEMPTED,
  FIND_SUBSCRIPTIONS_SUCCEEDED,
  FIND_SUBSCRIPTIONS_FAILED,
  RESET_SUBSCRIPTIONS,
  FIND_SUBSCRIPTION_BY_ID_ATTEMPTED,
  FIND_SUBSCRIPTION_BY_ID_SUCCEEDED,
  FIND_SUBSCRIPTION_BY_ID_FAILED,
  CHANGE_TARIFF_ATTEMPTED,
  CHANGE_TARIFF_SUCCEEDED,
  CHANGE_TARIFF_FAILED,
  SET_BUNDLES_ATTEMPTED,
  SET_BUNDLES_SUCCEEDED,
  SET_BUNDLES_FAILED,
  DISABLE_DATA_BONUS_ATTEMPTED,
  DISABLE_DATA_BONUS_SUCCEEDED,
  DISABLE_DATA_BONUS_FAILED,
  DISABLE_DATA_BONUS_CLEAR_ERRORS,
  CHANGE_TARIFF_CLEAR_ERRORS,
  SET_UPSELL_BUNDLES_ATTEMPTED,
  SET_UPSELL_BUNDLES_SUCCEEDED,
  SET_UPSELL_BUNDLES_FAILED,
  FIND_PORT_OUT_INFO_ATTEMPTED,
  FIND_PORT_OUT_INFO_SUCCEEDED,
  FIND_PORT_OUT_INFO_FAILED,
  STC_RULES,
  MIN_UNLIMITED_MINUTES,
  MIN_UNLIMITED_GB,
  MIN_UNLIMITED_MINUTES_F2F,
  MIN_UNLIMITED_MINUTES_F2M,
  SET_SVA_ATTEMPTED,
  SET_SVA_SUCCEEDED,
  SET_SVA_FAILED,
}

export const PERMANENT_ROUTER_IDS = ['FTERM', 'NEBTERM']
export const PERMANENT_TV_IDS = ['TVTERM']
export const PERMANENT_DISCOUNT_IDS_PREFIX = ['DTERM']
export const PERMANENT_BONUS_IDS_PREFIX = ['UTERM']
export const PERMANENT_TARIFF_IDS = ['TERM']

export const PRORRATED_FINANCIAL_ID = 'RVTERM'

export const DUO_PRINCIPAL_TARIFF_ID = 'CONT28'

export const CONVERGENT_CATEGORY = 'convergent'

export const OLD_DISCOUNT_AMOUNT_DUO_PRINCIPAL = 11.57

export const DOCTORGO_DISCOUNTS = [
  'DOCTORGORETDISC',
  'DOCTORGORETDISC02',
  'DOCTORGORETDISC03',
  'DOCTORGOCUSTSVADISC',
  'DOCTORGOCUSTSVADISC02',
  'DOCTORGOCUSTSVADISC03',
]
