export const ACTIVE_STATUS = 'active'
export const TERMINATED_STATUS = 'terminated'
export const DISMISSED_STATUS = 'dismissed'
export const DRAFT_STATUS = 'draft'

export const PRODUCT_STATUS_LABELS = {
  [ACTIVE_STATUS]: 'Activo',
  [TERMINATED_STATUS]: 'Terminado',
  [DISMISSED_STATUS]: 'Descartado',
  [DRAFT_STATUS]: 'En proceso',
}
