import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { Route } from 'react-router-dom'
import { push } from 'connected-react-router'
import { makeStyles } from '@material-ui/styles'
import { Typography, Snackbar, Box } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import { get, isEmpty } from 'lodash'
import { TextStyles } from 'utils/text'

import { Modal, NotificationModal, SpinnerCenter } from 'components/ui'

import { clearTypificationError } from 'services/typifications'

import { helpers as helpers360 } from 'services/customer-360'

import { TypificationsModal } from 'modules/offers-configuration/components/TypificationsModal'
import { closeTypification } from 'modules/offers-configuration/actions'
import { closeTypificationIframe } from 'modules/typifications/store/actions/securizerTypifications.actions'

import { buildPath, AppPaths } from 'modules/AppPaths'
import { MasConsentsGdprModal } from 'modules/mas-consents'

import { SaleWithClientCardButton } from '../../../offers-configuration/components/SaleWithClientCardButton'
import { Routes } from './Routes'
import DashboardHome from './DashboardHome'

import { AsideDashboard } from '../../components/AsideDashboard'

import { VistaDashboardHeader } from './VistaDashboardHeader'
import ModalError from './ModalError'
import CancelledCustomer from './CancelledCustomer'

const useStyles = makeStyles(theme => ({
  'modal-search-error': {
    top: '64px',
    zIndex: -1,
  },
  dashboard: {
    height: 'calc(100vh - 64px)',

    '& > div': {
      height: 'calc(100vh - 64px)',
    },

    '& .aside-left': {
      width: '390px',
      zIndex: 1,

      '& > div': {
        backgroundColor: theme.palette.brand.medium,
        boxShadow: '6px 1px 25px 0 rgba(0, 0, 0, 0.2)',
        height: '100%',
      },
    },

    '& .scroll-content': {
      width: 'calc(100vw - 390px)',
      overflowY: 'scroll',
    },
  },

  'dashboard-content': {
    '& .card-content': {
      padding: '10px 0px',
    },

    '& .subscription-card .card-content': {
      padding: '0px',
    },
  },

  'cancelled-client-box': {
    flexGrow: 1,
    height: 'calc(100vh - 64px)',
  },

  'sales-with-client-card-button': {
    margin: '0 24px',
    minHeight: '64px',

    '& .card-link': {
      textTransform: 'uppercase',
      textAlign: 'left',
      padding: '24px',
    },
  },
}))

export function Dashboard({
  isCustomer,
  isLoading,
  contracts,
  orders,
  errorSearch,
  documentId,
  documentType,
  typificationOpen,
  isTypificationDone,
  typificationError,
  typificationIframeOpen,
  typificationIframeToken,
  customerDocumentId,
  sectionData,
  gotCoverageData,
  queryParams,
  isLegacyCustomer,
  canEnergyViewContracts,
  energyIsAllowed,
  gdprData,
  onGdprSuccess,
  onGdprError,
  onGdprClose,
  showGdpr,
  hasGdprError,
  setHasGdprError,
  hasCustomerIdsLoaded,
  isPosOrMasProximo,
}) {
  const classes = useStyles()

  const dispatch = useDispatch()
  const lineNumbers = helpers360.getAllLinesAndElFijoMsisdn(contracts)

  // eslint-disable-next-line no-shadow
  function onSearch({ documentType = 'UNKNOWN', documentId, type, id }) {
    const toPath = buildPath(
      AppPaths.dashboard.id,
      { documentType, documentId, type, id },
      queryParams,
    )
    dispatch(push(toPath))
  }

  function onSelect({ type, id }) {
    const toPath = buildPath(
      AppPaths.dashboard.id,
      { documentType, documentId, type, id },
      queryParams,
    )
    dispatch(push(toPath))
  }

  useEffect(() => {
    if (isTypificationDone || typificationError) {
      dispatch(closeTypification())
    }
  }, [isTypificationDone, typificationError])

  function getContent() {
    // Path section props
    const { section, id } = sectionData

    if (errorSearch) {
      return <ModalError className={classes['modal-search-error']} errorType={errorSearch} />
    }

    if (documentId || isLoading) {
      return (
        <Box display="flex" alignItems="center" className={classes.dashboard}>
          <Box className="aside-left">
            <AsideDashboard
              data-hook="aside-dashboard"
              data={{ contracts, orders }}
              isLoading={isLoading}
              onSelect={onSelect}
              selectedSection={section}
              selectedId={id}
              isCustomer={isCustomer}
            />
          </Box>
          <Box flexGrow="1" className="scroll-content">
            {isLegacyCustomer && !isLoading && (
              <Box className={classes['cancelled-client-box']}>
                <CancelledCustomer searchKey={documentId} showButton={false} />
                <SaleWithClientCardButton
                  className={classes['sales-with-client-card-button']}
                  hasCustomerIdsLoaded={hasCustomerIdsLoaded}
                />
              </Box>
            )}
            {!isLegacyCustomer && !isLoading && isEmpty(contracts) && isEmpty(orders) ? (
              <Box className={classes['cancelled-client-box']}>
                <CancelledCustomer searchKey={documentId} />
                <SaleWithClientCardButton
                  className={classes['sales-with-client-card-button']}
                  hasCustomerIdsLoaded={hasCustomerIdsLoaded}
                />
              </Box>
            ) : (
              <Box position="relative" className={classes['dashboard-content']}>
                {!isLegacyCustomer && isLoading ? (
                  <SpinnerCenter showMsg />
                ) : (
                  <>
                    <Snackbar
                      open={hasGdprError}
                      autoHideDuration={2000}
                      onClose={() => setHasGdprError(false)}>
                      <Alert severity="error" onClose={() => setHasGdprError(false)}>
                        {gdprData?.hasError
                          ? 'Error cargando el componente de consentimientos del cliente.'
                          : 'Error actualizando los consentimientos del cliente. Por favor inténtalo más tarde.'}
                      </Alert>
                    </Snackbar>

                    {showGdpr && !!gdprData && !gdprData.hasError && (
                      <MasConsentsGdprModal
                        show
                        onSuccess={onGdprSuccess}
                        onError={onGdprError}
                        onClose={onGdprClose}
                        {...gdprData}
                      />
                    )}
                    <Route
                      render={props => (
                        <Routes
                          isCustomer={isCustomer}
                          orders={orders}
                          contracts={contracts}
                          matchUrl={props.match.url}
                          queryParams={queryParams}
                          canEnergyViewContracts={canEnergyViewContracts}
                          energyIsAllowed={energyIsAllowed}
                          isPosOrMasProximo={isPosOrMasProximo}
                        />
                      )}
                    />
                  </>
                )}
              </Box>
            )}
          </Box>
        </Box>
      )
    }

    return <DashboardHome />
  }

  return (
    <Box display="flex" flexDirection="column" height="100vh">
      { typificationIframeOpen &&  typificationIframeToken &&
      <Modal
        isOpen
        onClose={() => dispatch(closeTypificationIframe())}> 
        <iframe title="Tipificaciones" height="850px" width="800px" src={`/typfication/${typificationIframeToken}`} />
      </Modal>
  }
      {typificationOpen && (
        <Modal title="Tipificación" isOpen onClose={() => dispatch(closeTypification())}>
          <TypificationsModal
            onCancel={() => dispatch(closeTypification())}
            lineNumbers={lineNumbers}
            documentId={customerDocumentId}
          />
        </Modal>
      )}

      {typificationError && (
        <NotificationModal
          type="alert"
          src="/assets/error.svg"
          title="Se ha encontrado un error al hacer la tipificación."
          isOpen
          onClose={() => dispatch(clearTypificationError())}>
          <Typography {...TextStyles.dataDark()}>{get(typificationError, 'info', '-')}</Typography>
        </NotificationModal>
      )}

      <VistaDashboardHeader
        isLoading={isLoading}
        onSearch={onSearch}
        documentId={documentId}
        gotCoverageData={gotCoverageData}
      />
      {getContent()}
    </Box>
  )
}

Dashboard.propTypes = {
  isLoading: PropTypes.bool,
  errorSearch: PropTypes.string,
  contracts: PropTypes.array,
  orders: PropTypes.array,
  isCustomer: PropTypes.bool,
  documentId: PropTypes.string,
  documentType: PropTypes.string,
  typificationOpen: PropTypes.bool,
  isTypificationDone: PropTypes.bool,
  typificationError: PropTypes.bool,
  typificationIframeOpen: PropTypes.bool,
  typificationIframeToken:PropTypes.string,
  customerDocumentId: PropTypes.string,
  gotCoverageData: PropTypes.func,
  sectionData: PropTypes.shape({
    section: PropTypes.string,
    id: PropTypes.string,
  }),
  queryParams: PropTypes.object,
  isLegacyCustomer: PropTypes.bool,
  energyIsAllowed: PropTypes.bool,
  canEnergyViewContracts: PropTypes.bool,
  gdprData: PropTypes.object,
  onGdprSuccess: PropTypes.func.isRequired,
  onGdprError: PropTypes.func.isRequired,
  onGdprClose: PropTypes.func.isRequired,
  showGdpr: PropTypes.bool,
  hasGdprError: PropTypes.bool,
  setHasGdprError: PropTypes.func.isRequired,
  hasCustomerIdsLoaded: PropTypes.bool,
  isPosOrMasProximo: PropTypes.bool,
}
