import { createStructuredSelector, createSelector } from 'reselect'
import {
  selectRenewalOrderIsLoading,
  selectRenewalOrderHasError,
  getDeviceRenewalBasket,
  selectRenewalRejectionReasonCode,
  selectIsRenewalAllowed,
  selectIsRenewalTvAllowed,
  selectHasIbanError,
} from 'modules/device-renewal/store/device-renewal.selectors'

const selectBasket = createSelector(
  getDeviceRenewalBasket,
  basket => {
    const data = { ...basket }
    delete data.validation
    delete data.devices
    delete data.validation
    delete data.specifications
    delete data.financingConditions
    delete data.generateOrder
    delete data.reservationId

    return data
  },
)

export const deviceRenewalSelectors = createStructuredSelector({
  isLoading: selectRenewalOrderIsLoading,
  hasError: selectRenewalOrderHasError,
  hasIbanError: selectHasIbanError,
  rejectionCode: selectRenewalRejectionReasonCode,
  isAllowed: selectIsRenewalAllowed,
  isTvAllowed: selectIsRenewalTvAllowed,
  basket: selectBasket,
})
