import { createStructuredSelector, createSelector } from 'reselect'

import {
  selectors as customer360Selectors,
  getIdentificationId,
} from 'services/customer-360/selectors'
import { selectSaleTax } from 'modules/NewClientSales/store/selectors/Taxes.selectors'
import { selectChannelQueryParam } from 'modules/Router/store/router.selectors'
import { PRO } from 'modules/SharedSales/constants/productCategories'

import {
  selectRenewalDeviceInfo,
  selectIsFinalPaymentDiscountApplicable,
  selectIsDevicePaymentMethodUpfront,
  selectIsFinalPaymentBeingExtended,
  selectRenewalMsisdn,
  selectFormattedBillingAddress,
} from '../../store/device-renewal.selectors'

import { getFinancingInfo } from '../../helpers'

const selectDevice = createSelector(
  selectRenewalDeviceInfo,
  ({ name, paymentMethods, financingConditions }) => {
    const paymentType = Object.keys(paymentMethods)[0]
    const paymentMethod = paymentType ? paymentMethods[paymentType] : {}
    const financingInfo = getFinancingInfo(financingConditions, paymentMethod)
    return {
      name,
      ...financingInfo,
    }
  },
)

export const selectIsProRenewal = createSelector(
  selectChannelQueryParam,
  channel => channel.includes(PRO),
)

export const legalDataSelectors = createStructuredSelector({
  fullName: customer360Selectors.getFullName,
  documentid: getIdentificationId,
  device: selectDevice,
  isFinalPaymentDiscountApplicable: selectIsFinalPaymentDiscountApplicable,
  isDeviceBeingPaidUpfront: selectIsDevicePaymentMethodUpfront,
  isFinalPaymentBeingExtended: selectIsFinalPaymentBeingExtended,
  subscriptionMsisdn: selectRenewalMsisdn,
  formattedBillingAddress: selectFormattedBillingAddress,
  saleTax: selectSaleTax,
  isPro: selectIsProRenewal,
})
