import PropTypes from 'prop-types'
import styled from 'styled-components'
import { head, get } from 'lodash'

import { findTVPermanents } from 'services/subscriptions'
import { formatFee } from 'utils'

import { Badge } from 'modules/ui'
import { getAgileTvContract } from 'services/subscriptions/helpers'
import { ItemLine } from '../ItemLine'
import { PermanentBadgeItem } from '../PermanentBadgeItem'

export const TVDeviceDetails = styled(
  ({ className, subscription, taxNeeded, tax, enabledTaxAgileTv }) => {
    const permanent = head(findTVPermanents(subscription))

    const agileTVFee = getAgileTvContract(subscription, tax, taxNeeded, enabledTaxAgileTv)

    return (
      <div className={`tv-device-details ${className}`}>
        <ItemLine type="live_tv" label="Dispositivo: Agile TV" />
        <PermanentBadgeItem permanent={permanent} />
        {agileTVFee && (
          <>
            <Badge
              lg
              keyValContrast
              value={[{ label: 'Cuota', value: formatFee(agileTVFee.monthlyFee) }]}
            />
            <Badge
              lg
              keyValContrast
              value={[{ label: 'Email', value: get(agileTVFee, 'email', '') }]}
            />
          </>
        )}
      </div>
    )
  },
)`
  > .permanent-badge-item {
    margin-top: 18px;
  }
`

TVDeviceDetails.propTypes = {
  className: PropTypes.string,
  subscription: PropTypes.object,
  taxNeeded: PropTypes.bool,
  tax: PropTypes.number,
  enabledTaxAgileTv: PropTypes.bool,
}
