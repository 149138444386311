import { createSelector } from 'reselect'
import { get } from 'lodash'

const selectFormTypifications = state => get(state, 'formTypifications')

const selectIsValid = createSelector(selectFormTypifications, formData => get(formData, 'valid'))

const selectIsDirty = createSelector(selectFormTypifications, formData => get(formData, 'dirty'))

export const selectIsValidForm = createSelector(
  selectIsValid,
  selectIsDirty,
  (valid, dirty) => !valid || !dirty,
)

export const selectDataForm = createSelector(selectFormTypifications, formData =>
  get(formData, 'data'),
)
