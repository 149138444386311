import { axiosJWT } from 'modules/axios'

import { APP_CONFIG } from 'services/app-config'

const BASE_URL = `${APP_CONFIG.care_cluster}/installation/addressConnection`
const IUAS_URL = `${APP_CONFIG.care_cluster}/v1/locations/iuas`

export function getConnectionInstallation(gescal) {
  return axiosJWT({
    method: 'GET',
    // TODO: check in is going to keep gescal17 instead gescal37
    url: `${BASE_URL}/${gescal.toString().substring(0, 17)}`,
  })
}

// TODO: Unmock when backend deploy  https://mmsistemas.atlassian.net/browse/SALES-2162#icft=SALES-2162
export function getLocationIUA(gescal) {
  return axiosJWT({
    method: 'GET',
    url: `${IUAS_URL}?gescal="${gescal}"`,
  })
}
