import { useState, useEffect, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { get, isEmpty, isNumber } from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { TextStyles } from 'utils/text'

import {
  getMsisdn,
  getSvaHebe,
  getSvaSmartHome,
  SVA_STATUS_ENABLED,
  SVA_STATUS_DISABLED,
  getSvaSubscriptionType,
  getDisabledBonusPreviousStatus,
  getExistVasContract,
  actions as subscriptionActions,
  isDisableSvaError,
  isDisableSvaSuccess,
  isDisableSvaLoading,
} from 'services/subscriptions'
import {
  isTariffCompatibleWithSva,
  getSubscriptionMostRecentOrder,
  selectIsMobileTariff,
  selectIs3pOr2pTariff,
} from 'modules/offers-configuration/selectors'
import {
  selectIsCustomerResidential,
  selectInstallationAddressPackageByMsisdn,
  selectors as customerSelectors,
  getSegment,
} from 'services/customer-360/selectors'
import { selectIsPosOrMasProximo } from 'modules/Permissions'
import { formatFee } from 'utils'
import { applyTax, getTax } from 'services/taxes'
import {
  SVAS_TYPES_CODE_TEXT,
  SVA_HEBE_CODE,
  SVA_HOMEGO_CODE,
  SVA_SMARTHOME_CODE,
  SVA_WIFI_TOTAL_01,
  SVA_WIFI_TOTAL_02,
} from 'modules/offers-configuration/constants'

import {
  Button,
  LineItemCard,
  ButtonSwitch,
  NotificationModal,
  Modal,
  ModalActions,
  AlertAdvice,
  ButtonCard,
  SpinnerCenter,
} from 'components/ui'
import { useCadenceAlarms } from 'modules/NewClientSales/hooks/Cadence/useCadenceAlarms'
import { getElFijoMsisdn } from 'modules/tariffs/helpers'
import {
  selectHideHebeSvaFlag,
  selectSvaSmartHomeFlag,
  selectNewNetkiaCustomerUrl,
  selectEnabledTaxSvaAgileTv,
} from 'services/feature-flag/selectors'
import { TO_ACCEPT, TRY_AGAIN } from 'modules/SharedSales/constants/literals'
import { AddressChooserModal } from 'modules/Address/components/AddressChooserModalComponent/index'
import { getBillingAddressStr } from 'services/customer-new/selectors'
import SvasSetModal from 'modules/offers-configuration/components/AddSvaModal/AddSvaModal'
import {
  selectAllowedCancelHomeGoFlag,
  selectHomeGoAlarmsSvaByMsisdn,
} from 'services/customer-sva/selectors'
import { selectIsBibeAllowed } from 'modules/SVAs/store/svas.selectors'
import { getAddressStreetType } from 'services/cadence-flow/helpers'
import { getBillingAddress } from 'modules/orders/store/orders.selectors'
import { getVasWithTax } from 'services/subscriptions/helpers'
import { matchSvaSicorWithVasContract } from 'services/customer-sva/helpers'
import { getPenaltyTexts } from 'modules/SVAs/helpers/helpers'
import {
  BILLING_ADDRESS,
  INSTALLATION_ADDRESS,
} from 'modules/SharedSales/constants/shippingAddress'
import { isProCustomer } from 'services/customer-360/helpers'
import { APP_CONFIG } from 'services/app-config/index'

import { ModifyContactPhoneModal } from '../../ModifyContactPhoneModal/ModifyContactPhoneModal'
import { ModifyContactEmailModal } from '../../ModifyContactEmailModal/ModifyContactEmailModal'

const useStyles = makeStyles({
  svasBox: {
    width: '100%',
    position: 'relative',
  },
  svaLineItemText: {
    width: '160px',
  },
})

const NOTIFICATION_MODAL_TYPES = {
  success: {
    type: 'success',
    iconPath: '/assets/clap_clap.svg',
  },
  alert: {
    type: 'alert',
    iconPath: '/assets/error.svg',
  },
}

export const TabSvas = ({ activeSubscrip, onflySubscrip, productId }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const subscription = activeSubscrip || onflySubscrip
  const isOnFlySubscription = !!onflySubscrip

  const isPosOrMasproximo = useSelector(selectIsPosOrMasProximo)
  const svaHebe = useSelector(getSvaHebe)
  const svaSmartHome = useSelector(getSvaSmartHome)

  const isSvaSmartHomeFlag = useSelector(selectSvaSmartHomeFlag)
  const isHideHebeSva = useSelector(selectHideHebeSvaFlag)
  const newNetkiaCustomerUrl = useSelector(selectNewNetkiaCustomerUrl)

  const [svaHebeStatus, setHebeStatus] = useState(isEmpty(svaHebe))

  const [showNotificationAlarmsSva, setShowNotificationAlarmsSva] = useState(false)
  const [isOpenSvasModal, setOpenSvasModal] = useState(false)
  const [changingSvaType, setChangingSvaType] = useState()
  const [changingSvaSelected, setChangingSvaSelected] = useState()
  const [isModifyPhoneNumberModalOpen, setIsModifyPhoneNumberModalOpen] = useState(false)
  const [isModifyEmailModalOpen, setIsModifyEmailModalOpen] = useState(false)

  const msisdn = getMsisdn(subscription)
  const disableBonusPreviousStatus = useSelector(getDisabledBonusPreviousStatus(msisdn))
  const hasSvaHebe = isNumber(disableBonusPreviousStatus)
    ? disableBonusPreviousStatus
    : !!getSvaSubscriptionType(subscription, SVA_HEBE_CODE)

  const activeMsisdnFijo = activeSubscrip ? getElFijoMsisdn(subscription) : null
  const msisdnFijo = onflySubscrip ? onflySubscrip.id : activeMsisdnFijo

  const billingAddress = useSelector(state => customerSelectors.getBillingAddress(state))
  const tax = useSelector(state => getTax(state, get(billingAddress, 'postCode')))

  const orderBillingAddress = useSelector(getBillingAddress)

  const svaHomeGoAlarms = useSelector(
    selectHomeGoAlarmsSvaByMsisdn(msisdnFijo, subscription?.subscription_id),
  )

  const customerSegment = useSelector(getSegment)
  const enabledTaxSvaAgileTv = useSelector(selectEnabledTaxSvaAgileTv)
  const taxNeeded = !isProCustomer(customerSegment)
  const vasWithTax = getVasWithTax(subscription, tax, taxNeeded, enabledTaxSvaAgileTv)
  const svaHomeGo = matchSvaSicorWithVasContract(svaHomeGoAlarms, vasWithTax)

  const subscriptionTariffId = get(subscription, 'tariff.id', subscription?.type)

  const isCustomerResidential = useSelector(selectIsCustomerResidential)
  const isCompany = useSelector(customerSelectors.getIsCompany)
  const is3pOr2p = useSelector(state => selectIs3pOr2pTariff(state, subscriptionTariffId))
  const isMobileTariff = useSelector(state => selectIsMobileTariff(state, subscriptionTariffId))

  const [contactPhones, setContactPhones] = useState(null)
  const [contactEmail, setContactEmail] = useState(null)

  const isTariffSvaCompatible = useSelector(state =>
    isTariffCompatibleWithSva(state, subscriptionTariffId),
  )
  const isSvaCompatible = isTariffSvaCompatible && isCustomerResidential

  const {
    svas,
    sendAlarmCadence,
    isSmartHomeCompatible,
    isHomeGoCompatible,
    isAlarmsCadenceError,
    isAlarmsCadenceSuccess,
    isAlarmsCadenceLoading,
    resetAlarmCadence,
    clientContactData,
    isValidPhonesContact,
    isValidEmailContact,
  } = useCadenceAlarms(
    subscriptionTariffId,
    isCustomerResidential,
    msisdnFijo,
    subscription,
    isOnFlySubscription,
    isCompany,
    true,
  )

  const setSvaHebe = useSelector(state => state.subscriptions?.disableDataBonus[msisdn])
  const hasHebeError = !!get(setSvaHebe, ['error'], false)
  const hasHebeSuccess = !!get(setSvaHebe, ['success'], false)
  const isHebeLoading = get(setSvaHebe, ['loading'], false)

  const isSmartHomeDisableError = useSelector(isDisableSvaError(SVA_SMARTHOME_CODE))
  const isSmartHomeDisableOk = useSelector(isDisableSvaSuccess(SVA_SMARTHOME_CODE))
  const isSmartHomeDisableLoading = useSelector(isDisableSvaLoading(SVA_SMARTHOME_CODE))

  const isHomeGoDisableError = useSelector(isDisableSvaError(SVA_HOMEGO_CODE))
  const isHomeGoDisableOk = useSelector(isDisableSvaSuccess(SVA_HOMEGO_CODE))
  const isHomeGoDisableLoading = useSelector(isDisableSvaLoading(SVA_HOMEGO_CODE))

  const allowCancelHomeGo = useSelector(selectAllowedCancelHomeGoFlag)

  const hasSvaError =
    hasHebeError || isAlarmsCadenceError || isSmartHomeDisableError || isHomeGoDisableError
  const svaErrorMsg = hasSvaError && !isEmpty(isAlarmsCadenceError) ? isAlarmsCadenceError : false

  const [svaSmartHomeStatus, setSmartHomeStatus] = useState(
    (!isEmpty(svaSmartHome) || isAlarmsCadenceSuccess) && !isSmartHomeDisableOk,
  )

  const [svaHomeGoStatus, setHomeGoStatus] = useState(
    (!isEmpty(svaHomeGo) || isAlarmsCadenceSuccess) && !isHomeGoDisableOk,
  )

  const isEnabledWifiTotal1ud = useSelector(getExistVasContract(SVA_WIFI_TOTAL_01))
  const isEnabledWifiTotal2ud = useSelector(getExistVasContract(SVA_WIFI_TOTAL_02))

  const notificationType = hasSvaError ? 'alert' : 'success'
  const errorMsgButtonLabel = svaErrorMsg ? TO_ACCEPT : TRY_AGAIN
  const notificationButtonLabel = hasSvaError ? errorMsgButtonLabel : TO_ACCEPT
  const notificationTitle = hasSvaError
    ? 'No se ha podido modificar el SVA'
    : '¡Has modificado el SVA con éxito!'

  const formatSvaPenalty = ({ penalty }) => {
    let message = ''
    if (penalty) {
      message = ` Si no devuelve el dispositivo, se le cobrará una penalización de ${formatFee(
        applyTax(tax, penalty),
      )}.`
    }
    return message
  }

  const SVAS_MODAL_TYPES = {
    title: 'Desactivación de SVA',
    paragraph: `¿Está seguro de proceder a la desactivación del SVA ${
      SVAS_TYPES_CODE_TEXT[changingSvaType]
    }?${formatSvaPenalty(svaSmartHome)}`,
  }

  const showSetSvaHebeNotification = hasHebeError || hasHebeSuccess
  const showSetSvasNotification =
    showNotificationAlarmsSva &&
    (((isAlarmsCadenceError || isAlarmsCadenceSuccess) && !isAlarmsCadenceLoading) ||
      ((isSmartHomeDisableError || isSmartHomeDisableOk) && !isSmartHomeDisableLoading) ||
      ((isHomeGoDisableError || isHomeGoDisableOk) && !isHomeGoDisableLoading))

  const isSwitchHomeGoDisabled =
    (isPosOrMasproximo && svaHomeGoStatus) ||
    (!svaHomeGoStatus && !isHomeGoCompatible) ||
    (svaHomeGoStatus && !allowCancelHomeGo)

  const installationAddressFixedLine = useSelector(state =>
    selectInstallationAddressPackageByMsisdn(state, msisdn),
  )

  const setSvaPreviousStatus = svaType => {
    if (svaType === SVA_SMARTHOME_CODE) {
      setSmartHomeStatus(!svaSmartHomeStatus)
    } else if (svaType === SVA_HOMEGO_CODE) {
      setHomeGoStatus(!svaHomeGoStatus)
    }
  }

  const orderByProductId = useSelector(state =>
    getSubscriptionMostRecentOrder(state, productId || msisdn),
  )
  const orderId = orderByProductId?.id || onflySubscrip?.order_id
  const contractId = orderByProductId?.contractId || onflySubscrip?.contract_id

  const documentNumber = useSelector(customerSelectors.getIdentificationId)

  const isBibeAllowed = useSelector(selectIsBibeAllowed)

  const [showModalSvasDisableCheck, setShowModalSvasDisableCheck] = useState(false)
  const [openModifyAddress, setOpenModifyAddress] = useState(false)

  const onConfirmSvasDisableCheckModal = () => {
    if (changingSvaType === SVA_HOMEGO_CODE) {
      dispatch(
        subscriptionActions.disableSvaHomeGo(
          msisdnFijo,
          subscription?.subscription_id,
          svaHomeGo?.contractTypeId,
          '',
        ),
      )
    } else {
      dispatch(
        subscriptionActions.disableSvaSmartHome(msisdnFijo, orderId, contractId, documentNumber),
      )
    }
    setShowModalSvasDisableCheck(false)
    setShowNotificationAlarmsSva(true)
    setSvaPreviousStatus(changingSvaType)
  }

  const onCloseSvasDisableCheckModal = () => setShowModalSvasDisableCheck(false)

  const confirmAlarmCadence = (address, svaSelected, phones, email) => {
    const isCarteraFlow = !isOnFlySubscription
    sendAlarmCadence(
      contractId,
      orderId,
      address,
      isCarteraFlow,
      svaSelected.alarmType,
      svaSelected,
      phones,
      email,
    )

    if (svaSelected.alarmType === SVA_HOMEGO_CODE) {
      setHomeGoStatus(!svaHomeGoStatus)
    } else {
      setSmartHomeStatus(!svaSmartHomeStatus)
      dispatch(subscriptionActions.disableSvaSmartHomeReset(msisdn))
    }
    setShowNotificationAlarmsSva(true)
  }

  const svaInstallationAddress = useMemo(() => {
    const fixedInstallAddress = get(installationAddressFixedLine, 'medium')
    const mobile1pBillingAddress = isOnFlySubscription ? orderBillingAddress : billingAddress

    return isMobileTariff ? mobile1pBillingAddress : fixedInstallAddress
  }, [
    installationAddressFixedLine,
    isOnFlySubscription,
    orderBillingAddress,
    billingAddress,
    isMobileTariff,
  ])

  const svaInstallationAddressType = useMemo(() => {
    if (!getAddressStreetType(svaInstallationAddress)) {
      return null
    }

    return isMobileTariff ? BILLING_ADDRESS : INSTALLATION_ADDRESS
  }, [svaInstallationAddress])

  function showModalModifyAddress(svaSelected, { phones, email } = {}) {
    const hasStreetAddress =
      svaSelected.alarmType === SVA_SMARTHOME_CODE || getAddressStreetType(svaInstallationAddress)

    setContactPhones(phones)
    setContactEmail(email)

    if (
      svaInstallationAddress &&
      hasStreetAddress &&
      !svaSelected.alarmType.includes(SVA_HOMEGO_CODE)
    ) {
      confirmAlarmCadence(svaInstallationAddress, svaSelected, phones, email)
    } else {
      setOpenModifyAddress(true)
    }
  }

  function validateHomeGoContactInfo(svaSelected, { phones, email } = {}) {
    if (!isValidPhonesContact() && !phones && svaSelected.alarmType.includes(SVA_HOMEGO_CODE)) {
      setIsModifyPhoneNumberModalOpen(true)
    } else if (
      !isValidEmailContact() &&
      !email &&
      svaSelected.alarmType.includes(SVA_HOMEGO_CODE)
    ) {
      setIsModifyEmailModalOpen(true)
    } else {
      showModalModifyAddress(svaSelected, { phones, email })
    }
  }

  const onConfirmSvasModal = svasSelected => {
    const sva = get(svasSelected, '[0]')

    if (sva) {
      setChangingSvaSelected(sva)
      validateHomeGoContactInfo(sva)
    }
  }

  const handleOnChangeSvaButtonSwitch = svaType => {
    setChangingSvaType(svaType)

    if (svaType === SVA_HEBE_CODE) {
      const svaStatus = svaHebeStatus ? SVA_STATUS_DISABLED : SVA_STATUS_ENABLED

      dispatch(subscriptionActions.setDataBonusHebeSva(msisdn, SVA_HEBE_CODE, svaStatus))
      setHebeStatus(!svaHebeStatus)
    } else if (svaType === SVA_SMARTHOME_CODE) {
      if (svaSmartHomeStatus) {
        setShowModalSvasDisableCheck(true)
      } else {
        showModalModifyAddress({ alarmType: svaType })
      }
    } else if (svaType === SVA_HOMEGO_CODE) {
      if (svaHomeGoStatus) {
        setShowModalSvasDisableCheck(true)
      } else {
        setOpenSvasModal(true)
      }
    }
  }

  const handleOnCloseNotification = useCallback(
    svaSmartHomeEnableAlarm => {
      if (svaSmartHomeEnableAlarm) {
        resetAlarmCadence()
        if (changingSvaType === SVA_SMARTHOME_CODE) {
          dispatch(subscriptionActions.disableSvaSmartHomeReset(msisdn))
        } else if (changingSvaType === SVA_HOMEGO_CODE) {
          dispatch(subscriptionActions.disableSvaHomeGoReset())
        }
        setShowNotificationAlarmsSva(false)
        setChangingSvaType(null)
      } else {
        dispatch(subscriptionActions.setDataBonusHebeSvaReset(msisdn))
      }
    },
    [changingSvaType],
  )

  useEffect(() => setHebeStatus(hasSvaHebe), [hasSvaHebe])
  useEffect(() => {
    if (hasHebeError) {
      setHebeStatus(!svaHebeStatus)
    }
  }, [hasHebeError])

  useEffect(() => {
    if (isAlarmsCadenceError || isSmartHomeDisableError || isHomeGoDisableError) {
      setSvaPreviousStatus(changingSvaType)
    }
  }, [isAlarmsCadenceError, !!isSmartHomeDisableError, !!isHomeGoDisableError])

  const generateModal = (title, isOpen, paragraphText, onConfirm, onClose, penaltyTexts) => (
    <Modal title={title} isOpen={isOpen}>
      <Typography {...TextStyles.paragraphDark()}>{paragraphText}</Typography>
      {!isEmpty(penaltyTexts) &&
        penaltyTexts.map(text => (
          <Typography
            {...TextStyles.paragraphDark({
              style: { marginTop: '10px' },
            })}>
            {text}
          </Typography>
        ))}
      <ModalActions>
        <Button onClick={onConfirm}>Desactivar</Button>
        <Button secondary onClick={onClose}>
          Cancelar
        </Button>
      </ModalActions>
    </Modal>
  )

  const getHomeGoWarningFillData = () => (
    <AlertAdvice
      type="warning"
      message="Debes modificar los datos de contacto e introducir un dato válido para poder vender el SVA."
    />
  )

  return (
    <Box display="flex" data-hook="svas-tab" className={`p-y-12 ${classes.svasBox}`}>
      <div className="svas-container">
        {!isHideHebeSva && !!hasSvaHebe && (
          <div className="flex align-space-between align-start-center">
            <LineItemCard primaryText="HEBE" className={classes.svaLineItemText} />

            <div className="p-r-24">
              <ButtonSwitch
                checked={svaHebeStatus}
                onChange={() => handleOnChangeSvaButtonSwitch(SVA_HEBE_CODE)}
                disabled={(isPosOrMasproximo && svaHebeStatus) || !isSvaCompatible}
              />
            </div>
          </div>
        )}

        <div className="flex align-space-between align-start-center">
          <LineItemCard primaryText="SMART HOME" className={classes.svaLineItemText} />

          <div className="p-r-24">
            <ButtonSwitch
              checked={svaSmartHomeStatus}
              onChange={() => handleOnChangeSvaButtonSwitch(SVA_SMARTHOME_CODE)}
              disabled={
                (isPosOrMasproximo && svaSmartHomeStatus) ||
                !isSmartHomeCompatible ||
                !isSvaSmartHomeFlag ||
                (isEmpty(svaSmartHome) && svaSmartHomeStatus)
              }
            />
          </div>
        </div>

        <div className="flex align-space-between align-start-center">
          <LineItemCard primaryText="Home GO" className={classes.svaLineItemText} />

          <div className="p-r-24">
            <ButtonSwitch
              checked={svaHomeGoStatus}
              onChange={() => handleOnChangeSvaButtonSwitch(SVA_HOMEGO_CODE)}
              disabled={isSwitchHomeGoDisabled}
            />
          </div>
        </div>

        {!isOnFlySubscription && isBibeAllowed && (
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <LineItemCard primaryText="Seguros" className={classes.svaLineItemText} />

            <Box pr="24px">
              <ButtonCard
                label="Web gestión ATC"
                className="sva-button"
                icon="input"
                onClick={() =>
                  window.open(
                    newNetkiaCustomerUrl ? APP_CONFIG.new_bibe_broker : APP_CONFIG.bibe_broker,
                    '_blank',
                  )
                }
              />
            </Box>
          </Box>
        )}

        {(isHebeLoading ||
          isAlarmsCadenceLoading ||
          isSmartHomeDisableLoading ||
          isHomeGoDisableLoading) && <SpinnerCenter absolute style={{ marginTop: 0, top: 0 }} />}

        <NotificationModal
          type={NOTIFICATION_MODAL_TYPES[notificationType].type}
          src={NOTIFICATION_MODAL_TYPES[notificationType].iconPath}
          title={notificationTitle}
          message={svaErrorMsg}
          isOpen={showSetSvaHebeNotification || showSetSvasNotification}
          onClose={() => handleOnCloseNotification(showNotificationAlarmsSva)}>
          <Button
            data-hook="action-accept"
            onClick={() => handleOnCloseNotification(showNotificationAlarmsSva)}>
            {notificationButtonLabel}
          </Button>
        </NotificationModal>

        {showModalSvasDisableCheck &&
          generateModal(
            SVAS_MODAL_TYPES.title,
            showModalSvasDisableCheck,
            <b>{SVAS_MODAL_TYPES.paragraph}</b>,
            onConfirmSvasDisableCheckModal,
            onCloseSvasDisableCheckModal,
            getPenaltyTexts(svaHomeGo),
          )}

        {openModifyAddress && (
          <Modal
            isOpen={openModifyAddress}
            onClose={() => setOpenModifyAddress(false)}
            title="Editar datos de instalación del SVA"
            overFlowY="auto">
            <AddressChooserModal
              onConfirm={address =>
                confirmAlarmCadence(
                  address || svaInstallationAddress,
                  changingSvaSelected,
                  contactPhones,
                  contactEmail,
                )
              }
              preselectedAddress={{
                address: getBillingAddressStr(svaInstallationAddress),
              }}
              preselectedAddressType={svaInstallationAddressType}
              onClose={() => setOpenModifyAddress(false)}
              allowInputManualAddress={!is3pOr2p}
              isRequiredFields={!is3pOr2p}
            />
          </Modal>
        )}

        {isOpenSvasModal && (
          <Modal
            isOpen={isOpenSvasModal}
            onClose={() => setOpenSvasModal(false)}
            title="Añadir SVA"
            overFlowY="auto">
            <SvasSetModal
              svas={svas?.filter(sva => sva.alarmType === changingSvaType) || []}
              onClose={() => setOpenSvasModal(false)}
              onConfirmSvas={onConfirmSvasModal}
            />
          </Modal>
        )}

        <ModifyContactPhoneModal
          customer={{
            docType: clientContactData.individualIdentification?.type,
            docNumber: clientContactData.individualIdentification?.identificationId,
            isCompany: clientContactData.isCompany,
          }}
          current={clientContactData.telephones}
          open={isModifyPhoneNumberModalOpen}
          onClose={() => setIsModifyPhoneNumberModalOpen(false)}
          onSuccess={phones => {
            setContactPhones(phones)
            validateHomeGoContactInfo(changingSvaSelected, { phones, email: contactEmail })
          }}>
          {getHomeGoWarningFillData()}
        </ModifyContactPhoneModal>

        <ModifyContactEmailModal
          customer={{
            docType: clientContactData.individualIdentification?.type,
            docNumber: clientContactData.individualIdentification?.identificationId,
            isCompany: clientContactData.isCompany,
          }}
          current={clientContactData.mail}
          open={isModifyEmailModalOpen}
          onClose={() => setIsModifyEmailModalOpen(false)}
          onSuccess={email => {
            setContactEmail(email)
            validateHomeGoContactInfo(changingSvaSelected, { phones: contactPhones, email })
          }}>
          {getHomeGoWarningFillData()}
        </ModifyContactEmailModal>
      </div>

      <div className="svas-container">
        <div className="flex align-space-between align-start-center">
          <LineItemCard primaryText="WIFI TOTAL 1 dispositivo" />

          <div className="p-r-24">
            <ButtonSwitch checked={isEnabledWifiTotal1ud} disabled="true" />
          </div>
        </div>

        <div className="flex align-space-between align-start-center">
          <LineItemCard primaryText="WIFI TOTAL 2 dispositivos" />

          <div className="p-r-24">
            <ButtonSwitch checked={isEnabledWifiTotal2ud} disabled="true" />
          </div>
        </div>
      </div>
    </Box>
  )
}

TabSvas.propTypes = {
  activeSubscrip: PropTypes.object,
  onflySubscrip: PropTypes.object,
  productId: PropTypes.string,
}
