import { get, isEmpty } from 'lodash'
import { createMatchSelector } from 'connected-react-router'
import { createSelector, createStructuredSelector } from 'reselect'

const isLoading = state => get(state, 'vista360DashboardLoad.loading')
const hasErrors = state => !isEmpty(get(state, 'vista360DashboardLoad.error'))

const selectURLDashboardParamsMatch = createMatchSelector({
  path: '/vista/dashboard/documentType/:documentType/documentId/:documentId/:section?/:id?',
})

const selectURLDashboardSectionParam = createSelector(
  selectURLDashboardParamsMatch,
  match => get(match, 'params.section', ''),
)

const selectURLDashboardSectionIdParam = createSelector(
  selectURLDashboardParamsMatch,
  match => get(match, 'params.id', ''),
)

const selectURLDashboardParams = createStructuredSelector({
  section: selectURLDashboardSectionParam,
  id: selectURLDashboardSectionIdParam,
})

export const selectors = {
  isLoading,
  hasErrors,
  selectURLDashboardParams,
}
