import { get } from 'lodash'

export const getOrderStatusDescData = state => get(state, 'orderStatusDesc.data')

export const isLoading = state => get(state, 'orderStatusDesc.loading')

export const getErrorCode = state => get(state, 'orderStatusDesc.error.httpCode')
export const getErrorMsg = state => get(state, 'orderStatusDesc.error.info')

export const getOrderStatus = state => get(state, 'orderStatusDesc.data.orderStatus')
export const getProductStatus = state => get(state, 'orderStatusDesc.data.productStatus')
