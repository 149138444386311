import { call, put, takeLatest } from 'redux-saga/effects'
import { logError, formatErrorMessage } from 'services/logging'
import { axiosFactory } from 'modules/axios'

import { actionTypes } from 'modules/typifications/constants'
import { getRegistersTipis } from '../apis'
import { getRegisterTypificationsSuccess, getRegisterTypificationsFailed } from '../actions'

export function* registerTypificationsInit({ payload }) {
  try {
    const { customerId, channel } = payload
    const response = yield call(getRegistersTipis, customerId, channel)
    const { data } = response
    yield put(getRegisterTypificationsSuccess(data))
  } catch (e) {
    yield call(logError, { e: new Error(formatErrorMessage(e)) })
    const error = axiosFactory.buildError(e)
    yield put(getRegisterTypificationsFailed(error))
  }
}

export function* watchRegisterTypigicationsSagas() {
  yield takeLatest(actionTypes.GET_REGISTER_TYPIFICATIONS_INIT, registerTypificationsInit)
}
