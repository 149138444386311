import { useState, useEffect, useRef, useMemo } from 'react'
import PropTypes from 'prop-types'
import { isEmpty, find } from 'lodash'
import { makeStyles } from '@material-ui/styles'
import { Card } from 'modules/ui'
import { getMsisdn } from 'services/subscriptions'
import { AddAgileTvModal } from 'modules/agile-tv/add-agiletv/components'
import { AlertAdvice } from 'components/ui'

import { ModifyContactPhoneModal } from 'modules/vista-360/components/ModifyContactPhoneModal'

import { ModifyContactEmailModal } from 'modules/vista-360/components/ModifyContactEmailModal'
import { CommercialMigrationModal } from 'modules/offers-configuration/components/CommercialMigrationModal'
import { ChangeAddressModal } from 'modules/offers-configuration/components/ChangeAddressModal/ChangeAddressModal'
import { ChangeTechnologyModal } from 'modules/offers-configuration/components/ChangeTechnologyModal/ChangeTechnologyModal'
import { KairosAppointmentModal } from 'modules/kairos-appointment/components/KairosAppointmentModal'

import { ADD_SUBSCRIPTION_OPERATION, CROSS_SUBSCRIPTION_OPERATION } from '../../constants'

import { CrossSellWarnings } from './CrossSellWarnings'
import { SubscriptionDetailContainer } from '../../containers/SubscriptionDetailContainer'
import { OrderLimitModal } from '../OrderLimitModal'
import { CrossSellModal } from '../CrossSellModal'
import { DeviceRenewalModal } from '../DeviceRenewalModal'
import { NewProductModal } from '../NewProductModal'
import { MultiSimElegibilityModal } from '../MultiSimElegibilityModal'

const useStyles = makeStyles({
  'subscription-card': {
    '& .card-header .button-menu': {
      marginLeft: '12px',
    },
  },
})

export const SubscriptionCard = ({
  subscription,
  title,
  subTitle,
  actions,
  preHeader,
  menuActions,
  Notification,
  NotificationDisableDiscounts,
  NotificationAddSva,
  operationToOpen,
  shouldCheckLimit,
  setShouldCheckLimit,
  shouldCheckXsellAllowed,
  setShouldCheckXsellAllowed,
  shouldCheckDeviceRenewalAllowed,
  setShouldCheckDeviceRenewalAllowed,
  shouldCheckMultiSimAllowed,
  setShouldCheckMultiSimAllowed,
  navigateToNewSubscription,
  navigateToCrossSubscription,
  navigateToDeviceRenewal,
  navigateToAddMultiSim,
  salesOperationSelected,
  setSalesOperationSelected,
  footer,
  setOpenNewProductModal,
  openNewProductModal,
  hasIds,
  crossWarnings,
  canAddAgileTv,
  isOpenAddAgileTv,
  setIsOpenAddAgileTv,
  onSuccessAddAgileTv,
  ModalSvas,
  TechnologyOperationErrorModal,
  TechnologyOperationSuccessModal,
  clientContactData,
  isModifyPhoneNumberModalOpen,
  setIsModifyPhoneNumberModalOpen,
  isModifyEmailModalOpen,
  setIsModifyEmailModalOpen,
  validateHomeGoContactInfo,
  changingSvaSelected,
  commercialMigrationProps,
  changeTechnologyProps,
  changeAddressProps,
  kairosAppointmentProps,
}) => {
  const classes = useStyles()

  const msisdn = getMsisdn(subscription)
  const cardEle = useRef(null)
  const collapsed = isEmpty(operationToOpen)

  const originSubscription = useMemo(() => subscription[0] || subscription, [subscription])

  const [contactPhones, setContactPhones] = useState(null)
  const [contactEmail, setContactEmail] = useState(null)

  useEffect(() => {
    if (operationToOpen) {
      if (cardEle.current) {
        cardEle.current.scrollIntoView()
      }
      const action = find(menuActions, { id: operationToOpen })
      if (action && !action.disabled && action.onClick) {
        action.onClick()
      }
    }
  }, [operationToOpen])

  const navigateToAddSubscription = salesOperationType => {
    if (salesOperationType === ADD_SUBSCRIPTION_OPERATION) {
      navigateToNewSubscription(originSubscription)
    } else {
      setShouldCheckXsellAllowed(true)
    }
  }

  const getHomeGoWarningFillData = () => (
    <AlertAdvice
      type="warning"
      message="Debes modificar los datos de contacto e introducir un dato válido para poder vender el SVA."
    />
  )

  return (
    <>
      <Card
        className={`subscription-card ${classes['subscription-card']}`}
        id={msisdn}
        refContent={cardEle}
        title={title}
        subTitle={subTitle}
        preHeader={preHeader}
        actions={actions}
        menuButton={{ btnText: 'Opciones', menuActions }}
        toggable
        collapsed={collapsed}
        footer={footer}
        menuScroll>
        <SubscriptionDetailContainer subscription={subscription} />
      </Card>
      <Notification />
      <NotificationDisableDiscounts />
      <NotificationAddSva />

      <OrderLimitModal
        shouldCheckLimit={shouldCheckLimit}
        onOrderLimitOk={() => navigateToAddSubscription(salesOperationSelected)}
        onOrderLimitReached={() => {
          setShouldCheckLimit(false)
          setSalesOperationSelected(null)
        }}
      />
      <CrossSellModal
        shouldCheckAllowed={shouldCheckXsellAllowed}
        subscription={originSubscription}
        onCrossSellAllowed={() => navigateToCrossSubscription(subscription)}
        onCrossSellReached={() => {
          setShouldCheckLimit(false)
          setShouldCheckXsellAllowed(false)
          setSalesOperationSelected(null)
        }}
      />
      <MultiSimElegibilityModal
        shouldCheckAllowed={shouldCheckMultiSimAllowed}
        subscription={originSubscription}
        onMultiSimAllowed={(channel, segment) =>
          navigateToAddMultiSim(subscription, channel, segment)
        }
        onMultiSimReached={() => {
          setShouldCheckMultiSimAllowed(false)
          setSalesOperationSelected(null)
        }}
      />

      <DeviceRenewalModal
        shouldCheckAllowed={shouldCheckDeviceRenewalAllowed}
        subscription={originSubscription}
        onComplete={() => setShouldCheckDeviceRenewalAllowed(false)}
        onSuccess={() => navigateToDeviceRenewal(subscription.id)}
      />
      {openNewProductModal && (
        <NewProductModal
          openNewProductModal={openNewProductModal}
          setOpenNewProductModal={setOpenNewProductModal}
          onConfirm={() => {
            setShouldCheckLimit(true)
            setOpenNewProductModal(false)
          }}
          onCancel={() => {
            setSalesOperationSelected(null)
          }}
          disabled={
            (!originSubscription.isMainLine &&
              salesOperationSelected === ADD_SUBSCRIPTION_OPERATION) ||
            !hasIds
          }
          message={
            salesOperationSelected === ADD_SUBSCRIPTION_OPERATION
              ? `Recuerda que si tu cliente quiere un terminal con su alta de línea móvil, excepto para Línea DUO,  debes cargar la venta de la línea móvil desde el botón "Añadir Nueva Venta".`
              : null
          }>
          {salesOperationSelected === CROSS_SUBSCRIPTION_OPERATION && (
            <CrossSellWarnings list={crossWarnings} />
          )}
        </NewProductModal>
      )}

      {canAddAgileTv && (
        <AddAgileTvModal
          open={isOpenAddAgileTv}
          onClose={() => setIsOpenAddAgileTv(false)}
          onSuccess={onSuccessAddAgileTv}
          subscription={originSubscription}
        />
      )}

      <ModifyContactPhoneModal
        customer={{
          docType: clientContactData.individualIdentification?.type,
          docNumber: clientContactData.individualIdentification?.identificationId,
          isCompany: clientContactData.isCompany,
        }}
        current={clientContactData.telephones}
        open={isModifyPhoneNumberModalOpen}
        onClose={() => setIsModifyPhoneNumberModalOpen(false)}
        onSuccess={phones => {
          setContactPhones(phones)
          validateHomeGoContactInfo(changingSvaSelected, { phones, email: contactEmail })
        }}>
        {getHomeGoWarningFillData()}
      </ModifyContactPhoneModal>

      <ModifyContactEmailModal
        customer={{
          docType: clientContactData.individualIdentification?.type,
          docNumber: clientContactData.individualIdentification?.identificationId,
          isCompany: clientContactData.isCompany,
        }}
        current={clientContactData.mail}
        open={isModifyEmailModalOpen}
        onClose={() => setIsModifyEmailModalOpen(false)}
        onSuccess={email => {
          setContactEmail(email)
          validateHomeGoContactInfo(changingSvaSelected, { phones: contactPhones, email })
        }}>
        {getHomeGoWarningFillData()}
      </ModifyContactEmailModal>

      <ModalSvas />
      <TechnologyOperationErrorModal />
      <TechnologyOperationSuccessModal />
      <CommercialMigrationModal {...commercialMigrationProps} />
      <ChangeTechnologyModal {...changeTechnologyProps} />
      <ChangeAddressModal {...changeAddressProps} />

      <KairosAppointmentModal {...kairosAppointmentProps} />
    </>
  )
}
SubscriptionCard.propTypes = {
  subscription: PropTypes.object,
  title: PropTypes.node,
  preHeader: PropTypes.node,
  subTitle: PropTypes.string,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      icon: PropTypes.string,
      action: PropTypes.func,
    }),
  ),
  menuActions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      icon: PropTypes.string,
      action: PropTypes.func,
    }),
  ),
  Notification: PropTypes.any,
  NotificationDisableDiscounts: PropTypes.any,
  NotificationAddSva: PropTypes.any,
  operationToOpen: PropTypes.string,
  footer: PropTypes.node,
  shouldCheckLimit: PropTypes.bool,
  setShouldCheckLimit: PropTypes.func.isRequired,
  shouldCheckXsellAllowed: PropTypes.bool,
  setShouldCheckXsellAllowed: PropTypes.func.isRequired,
  shouldCheckDeviceRenewalAllowed: PropTypes.bool,
  setShouldCheckDeviceRenewalAllowed: PropTypes.func.isRequired,
  shouldCheckMultiSimAllowed: PropTypes.bool,
  setShouldCheckMultiSimAllowed: PropTypes.func.isRequired,
  navigateToNewSubscription: PropTypes.func.isRequired,
  navigateToCrossSubscription: PropTypes.func.isRequired,
  navigateToDeviceRenewal: PropTypes.func.isRequired,
  navigateToAddMultiSim: PropTypes.func.isRequired,
  salesOperationSelected: PropTypes.bool,
  setSalesOperationSelected: PropTypes.func.isRequired,
  openNewProductModal: PropTypes.bool,
  setOpenNewProductModal: PropTypes.func.isRequired,
  hasIds: PropTypes.bool,
  crossWarnings: PropTypes.object,
  isOpenAddAgileTv: PropTypes.bool,
  setIsOpenAddAgileTv: PropTypes.func.isRequired,
  onSuccessAddAgileTv: PropTypes.func,
  canAddAgileTv: PropTypes.bool,
  ModalSvas: PropTypes.any,
  TechnologyOperationErrorModal: PropTypes.any,
  TechnologyOperationSuccessModal: PropTypes.any,
  clientContactData: PropTypes.object.isRequired,
  isModifyPhoneNumberModalOpen: PropTypes.bool,
  setIsModifyPhoneNumberModalOpen: PropTypes.func.isRequired,
  isModifyEmailModalOpen: PropTypes.bool,
  setIsModifyEmailModalOpen: PropTypes.func.isRequired,
  validateHomeGoContactInfo: PropTypes.func.isRequired,
  changingSvaSelected: PropTypes.object,
  commercialMigrationProps: PropTypes.object,
  changeAddressProps: PropTypes.object,
  kairosAppointmentProps: PropTypes.object,
  changeTechnologyProps: PropTypes.object,
}
