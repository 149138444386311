import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Grid, Typography } from '@material-ui/core'
import { SpinnerCenter } from 'components/ui'
import { DiscountItem } from '../DiscountItem'

const Table = styled.table`
  border-top: 2px solid #dddddd;
  margin-top: 25px;
  width: 100%;
`

function DiscountList(props) {
  const { discounts, discountSelected, loading } = props

  const showDiscounts = () => {
    if (!discounts.length) {
      return <Typography variant="body1">No existen descuentos aplicados en esta orden</Typography>
    }
    return (
      <Table>
        <tbody>
          {discounts.map(discount => {
            const key = `${discount.discountCode}${discount.id}`
            return <DiscountItem key={key} discountSelected={discountSelected} {...discount} />
          })}
        </tbody>
      </Table>
    )
  }

  return (
    <>
      <Typography variant="body2" align="left">
        Descuentos de la orden:
      </Typography>
      {loading && (
        <Grid direction="column" alignItems="baseline" container>
          <SpinnerCenter />
        </Grid>
      )}
      {!loading && showDiscounts()}
    </>
  )
}

export default DiscountList

DiscountList.propTypes = {
  discounts: PropTypes.arrayOf(PropTypes.object).isRequired,
  discountSelected: PropTypes.string,
  loading: PropTypes.bool,
}

DiscountList.defaultProps = {
  discounts: [],
  discountSelected: '',
}
