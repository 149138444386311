import { ProductServices } from 'modules/vista-360/components/ProductServices/ProductServices'
import { ProductMainContainer } from '../../containers/ProductMainContainer'
// import { OrderTickets } from '../OrderTickets'
import { CustomerActivity } from '../../components/CustomerActivity/CustomerActivity'
import { UsageDetail } from '../../components/UsageDetail/UsageDetail'

export const tabs = {
  MAIN: 'main',
  CONFIGURATION: 'configuration',
  TICKETS: 'tickets',
  USE_DETAILS: 'use_details',
  SERVICES: 'services',
  REGISTER: 'register',
}

export const getTabCofig = (isFixedNumber, canIViewDetailUseSubscription) => {
  const tabsConfig = [
    {
      label: 'Principal',
      value: tabs.MAIN,
      component: ProductMainContainer,
    },
    {
      label: 'Detalles de uso',
      value: tabs.USE_DETAILS,
      component: UsageDetail,
      disabled: !canIViewDetailUseSubscription,
    },
    // {
    //   label: 'Tickets',
    //   value: tabs.TICKETS,
    //   component: OrderTickets,
    // },
    {
      label: 'Servicios',
      value: tabs.SERVICES,
      component: ProductServices,
    },
    {
      label: 'Registro',
      value: tabs.REGISTER,
      component: CustomerActivity,
    },
  ]

  const tabsConfigForFixedLine = [
    {
      label: 'Principal',
      value: tabs.MAIN,
      component: ProductMainContainer,
    },
    {
      label: 'Servicios',
      value: tabs.SERVICES,
      component: ProductServices,
    },
    {
      label: 'Detalles de uso',
      value: tabs.USE_DETAILS,
      component: UsageDetail,
      disabled: !canIViewDetailUseSubscription,
    },
    {
      label: 'Registro',
      value: tabs.REGISTER,
      component: CustomerActivity,
    },
  ]

  return isFixedNumber ? tabsConfigForFixedLine : tabsConfig
}
