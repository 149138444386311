import { get } from 'lodash'

const hasPenaltyCondoned = subscription =>
  get(subscription, 'fixed.penaltyCondoned', false) ||
  get(subscription, 'mobile.penaltyCondoned', false)
const hasChangedTariff = subscription => Boolean(get(subscription, 'confirmedTariff', false))
const hasBonusesAdded = subscription => Boolean(get(subscription, 'confirmedBonuses', []).length)
const hasDiscountsAdded = subscription =>
  Boolean(get(subscription, 'confirmedDiscounts', []).length)
const hasSubscriptionModified = subscription =>
  hasChangedTariff(subscription) || hasBonusesAdded(subscription) || hasDiscountsAdded(subscription)
const isModified = subscription =>
  hasPenaltyCondoned(subscription) || hasSubscriptionModified(subscription)

export const usePenaltyCondonation = modifications => {
  const ids = Object.keys(modifications)

  return {
    isOnlyPenaltyCondonation: ids.reduce((isOnlyCondonation, id) => {
      const subscription = modifications[id]
      return !isModified(subscription)
        ? isOnlyCondonation
        : isOnlyCondonation &&
            hasPenaltyCondoned(subscription) &&
            !hasSubscriptionModified(subscription)
    }, true),
    haveSubscriptionsChanged: ids.reduce(
      (changed, id) =>
        changed || hasChangedTariff(modifications[id]) || hasDiscountsAdded(modifications[id]),
      false,
    ),
  }
}
