import PropTypes from 'prop-types'
import { HTMLTemplate } from 'modules/ui/components/HTMLTemplate/HTMLTemplate'

import { Step } from './Step'

const MobileActivation = ({ isLastStep, title, description }) => (
  <Step
    title={title}
    description={<HTMLTemplate html={description} />}
    isLastStep={isLastStep}
    data-hook="mobile_activation"
  />
)

MobileActivation.propTypes = {
  isLastStep: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
}

export default MobileActivation
