import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/styles'

import { Box } from '@material-ui/core'

import { useForceSaveAuth } from 'modules/Auth'
import { selectCanIDO, permissions } from 'modules/Permissions'
import { selectSfid } from 'modules/Auth/store/selectors'
import { createInsuranceSale } from 'modules/Insurance'
import { selectNewNetkiaUrl } from 'services/feature-flag/selectors'

import { ButtonLink } from 'components/ui'
import { Card } from 'modules/ui'

const useStyles = makeStyles(theme => ({
  'add-new-insurance-package-card': {
    '& button': {
      color: theme.palette.brand.alert,

      '&:hover': {
        color: theme.palette.redPalette.red_500,
      },
    },
  },
}))

export const SaleInsuranceWithClientCardButton = () => {
  const classes = useStyles()

  const sfid = useSelector(selectSfid)
  const newNetkiaUrl = useSelector(selectNewNetkiaUrl)
  const { saveLatestAccessToken } = useForceSaveAuth()

  const canInsuranceSale = useSelector(selectCanIDO(permissions.cvm_insurance_sale.id))

  const goToNewSale = () => {
    const redirectUrl = createInsuranceSale(sfid, newNetkiaUrl)

    saveLatestAccessToken()
    window.location.href = redirectUrl
  }

  return (
    <Box mt="15px">
      <Card className={classes['add-new-insurance-package-card']} title="">
        <ButtonLink
          onClick={goToNewSale}
          disabled={!canInsuranceSale}
          data-hook="sale-insurance-withclient-button"
          underline={false}
          className="card-link">
          + Añadir nueva venta de seguro de terminal
        </ButtonLink>
      </Card>
    </Box>
  )
}
