import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import styled from 'styled-components'
import { Typography } from '@material-ui/core'
import { TextStyles } from 'utils/text'

import { Grid, Modal } from 'components/ui'

export const SetBarringsResult = styled(
  ({ className, error, showBarringStatusResult, setShowBarringStatusResult }) => {
    const icon = isEmpty(error) ? '/assets/clap_clap.svg' : '/assets/error.svg'
    const setBarringsStatusMsg = isEmpty(error)
      ? '¡Has realizado el proceso con éxito!'
      : 'Ha ocurrido un error en el proceso'

    return (
      <Modal
        className={className}
        type={!isEmpty(error) ? 'alert' : 'success'}
        isOpen={showBarringStatusResult}
        onClose={() => setShowBarringStatusResult(false)}
        data-hook="fat-modal-result">
        <Grid container justify="space-between" alignItems="center">
          <Grid className="content-grid" item xs={12}>
            <img src={icon} alt={setBarringsStatusMsg} />
          </Grid>
          <Grid className="content-grid" item xs={12}>
            <Grid className="content-grid" item xs={12}>
              <Typography {...TextStyles.title1Dark()}>{setBarringsStatusMsg}</Typography>
              {isEmpty(error) && (
                <Typography {...TextStyles.title1Dark()}>
                  Puede que la información tarde unos segundos en actualizarse
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Modal>
    )
  },
)`
  .content-grid {
    text-align: center;
    padding: 1em 0;
  }
`

SetBarringsResult.propTypes = {
  className: PropTypes.string,
  errors: PropTypes.object,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
}
