import styled from 'styled-components'
import { LinesOrderSummary } from './LineErrorComponent'

export const StyledLinesOrderSummary = styled(LinesOrderSummary)`
  & .popper {
    margin-top: 12px;
  }

  & .row {
    margin-top: 12px;
  }

  & .icon {
    cursor: pointer;
    margin-left: 12px;
    margin-right: 12px;
  }
`
