import PropTypes from 'prop-types'
import { Box, Card, CardContent } from '@material-ui/core'

import { TitleSidebar } from 'components/ui'

import OffersFeeSidebar from './OffersSidebar/OffersFeeSidebar'

export function TotalMonthlyPaymentsSidebar({ payments }) {
  return (
    <Box display="flex" justifyContent="center" data-hook="sideBar-totalmonthly">
      <Card style={{ width: '90%', borderRadius: '10px', margin: '8px 0' }}>
        <CardContent>
          <div>
            <TitleSidebar>Precio con dispositivos incluidos</TitleSidebar>
            <OffersFeeSidebar monthlyPayments={payments} />
          </div>
        </CardContent>
      </Card>
    </Box>
  )
}

TotalMonthlyPaymentsSidebar.propTypes = {
  payments: PropTypes.array,
}
