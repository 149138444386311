import { get, flatMap } from 'lodash'
import { SIGNUP_TYPE_PORTABILITY } from 'services/global-constants'
import { SVA_HOMEGO_CODE, XSELL_DISCOUNT_EXCEPTION } from 'modules/offers-configuration/constants'

import { MONETARY, PERCENTS } from '../models'

const getFixedLineTariffFee = relatedPrices =>
  relatedPrices.find(({ id }) => {
    // If discount prices match, means that this discount should only be applied for fixed part of the offer
    const sanitizedId = id.replace('CP_', '')
    return sanitizedId.includes('_CONTFH') || sanitizedId.includes('_CONTDSL')
  })

const getTotalDiscountByPercents = (tariffFee, amount) => (tariffFee * amount) / 100

export function formatDiscounts(discounts, tariffFee) {
  return (
    discounts &&
    discounts.map(
      ({ monthlyFee, amount, type, units, maxRepetitions, relatedPrices = [], ...discount }) => {
        const discountAmount = monthlyFee || amount

        // Safe check, maybe type or units are undefined and we force monetary in that case
        // if this changes, we can safely use Type and Units from the parameters
        const isMonetaryDiscount = type === MONETARY || units === MONETARY || (!type && !units)

        // If the fee is the main fixed offer, we only apply discount to that part of the price

        const fee = get(getFixedLineTariffFee(relatedPrices), 'value', tariffFee)
        return {
          ...discount,
          type: isMonetaryDiscount ? MONETARY : PERCENTS,
          discountAmount,
          monthlyFee: isMonetaryDiscount
            ? -discountAmount
            : getTotalDiscountByPercents(fee, discountAmount) * -1,
          duration: discount.duration ? discount.duration : maxRepetitions,
        }
      },
    )
  )
}

export function formatSummaryDiscounts(lines) {
  return flatMap(
    lines.map(line =>
      line?.tariff?.discounts?.map(discount => ({
        ...discount,
        phone: line.signUpType === SIGNUP_TYPE_PORTABILITY ? line.lineNumber : line.newNumber.value,
        isSummary: true,
        isMobile: get(line, 'id', '').includes('extra'),
        linePrice: line.tariff.priceWithTax,
      })),
    ),
  )
}

export const isCrossSellDiscount = discountId => {
  if (!discountId) {
    return false
  }

  const crossSellDiscountRegEx = /^CONVDISC_CS[0-9A-Za-z_]/

  return !XSELL_DISCOUNT_EXCEPTION.includes(discountId) && crossSellDiscountRegEx.test(discountId)
}

export const isHomeGoDiscount = discountId => discountId.includes(SVA_HOMEGO_CODE)
