import * as Yup from 'yup/lib'
import { map, keys, includes } from 'lodash'

const VALIDATION_OPTIONS = { abortEarly: false, strict: true }
const validateSync = (object, schema) => schema.validateSync(object, VALIDATION_OPTIONS)

const POSTAL_ADDRESS_MEDIUM = Yup.object({
  country: Yup.string().required(),
  city: Yup.string().required(),
  street: Yup.string().required(),
  building: Yup.string().required(),
  floor: Yup.string().required(),
  gescal: Yup.string().required(),
  geolocation: Yup.object({
    latitude: Yup.string().required(),
    longitude: Yup.string().required(),
  }),
  address_register_id: Yup.string().required(),
  state_or_province: Yup.string().required(),
  postal_code: Yup.string().required(),
  co_address: Yup.string().required(),
}).required()

export const validateInstallationMedium = installationMedium => {
  const schema = Yup.object({
    attributes: Yup.object({
      role: Yup.string()
        .required()
        .oneOf(['installation', 'delivery']),
      medium: POSTAL_ADDRESS_MEDIUM,
      medium_type: Yup.string()
        .required()
        .matches(/postal-address/),
    }),
  })
  return validateSync(installationMedium, schema)
}

export const validateBillingAccount = billingAccount => {
  const schema = Yup.object({
    attributes: Yup.object({
      characteristics: Yup.object({
        iban: Yup.string().required(),
        bank_name: Yup.string().required(),
      }),
    }).required(),
    relationships: Yup.object({
      billing_profile: Yup.array()
        .of(
          Yup.object({
            attributes: Yup.object({
              bill_delivery_method: Yup.string()
                .required()
                .matches(/EMAIL/),
              payment_method: Yup.string()
                .required()
                .matches(/Direct Debit/),
              billing_cycle: Yup.number()
                .positive()
                .integer()
                .required(),
              billing_interval: Yup.object({
                count: Yup.number()
                  .positive()
                  .integer()
                  .required(),
                interval: Yup.string()
                  .oneOf(['month'])
                  .required(),
              }).required(),
            }).required(),
          }).required(),
        )
        .max(1),
    }).required(),
  }).required()
  return validateSync(billingAccount, schema)
}

export const generateOfferValidator = offer => {
  const inputCharacteristicsSchema = {}
  for (const characteristicName of keys(offer.input_characteristics)) {
    const rules = offer.input_characteristics[characteristicName]
    let validator = Yup
    if (rules.value_regulator === 'selection') {
      validator = validator.string().oneOf(map(rules.values, v => v.value))
    } else if (rules.value_regulator === 'must_be_personalized') {
      if (rules.data_type === 'string') {
        validator = validator.string()
      }
      if (rules.validation !== null) {
        const removeInitialAndFinalSlashes = /^[/](.*)[/]$/g
        const regexRule = rules.validation.replace(removeInitialAndFinalSlashes, '$1')
        validator = validator.matches(new RegExp(regexRule))
      }
    }

    if (rules.mandatory) {
      validator = validator.required()
    }
    inputCharacteristicsSchema[characteristicName.toLowerCase()] = validator
  }

  return Yup.object(inputCharacteristicsSchema)
}

export const validateContactMedia = contactMedia => {
  const ROLES = ['primary', 'billing']
  const MEDIUM_TYPES = ['email-address', 'telephone-number', 'postal-address']
  const mediumType = contactMedia.attributes.medium_type

  let schema
  if (includes(MEDIUM_TYPES, mediumType)) {
    schema = Yup.object({
      role: Yup.string()
        .when('medium_type', {
          is: 'postal-address',
          then: Yup.string()
            .oneOf(['billing'])
            .required(),
          otherwise: Yup.string()
            .oneOf(ROLES)
            .required(),
        })
        .required(),
      medium: Yup.object()
        .when('medium_type', {
          is: 'email-address',
          then: Yup.object({
            email: Yup.string()
              .email()
              .required(),
          }).required(),
        })
        .when('medium_type', {
          is: 'telephone-number',
          then: Yup.object({
            number: Yup.string(),
            number_type: Yup.string()
              .oneOf(['mobile', 'fixed-line'])
              .required(),
          }).required(),
        })
        .when('medium_type', {
          is: 'postal-address',
          then: POSTAL_ADDRESS_MEDIUM,
        })
        .required(),
      medium_type: Yup.string()
        .oneOf(MEDIUM_TYPES)
        .required(),
    }).required()
  } else {
    const err = new Error()
    err.errors = ['wrong medium_type']
    throw err
  }
  return validateSync(contactMedia.attributes, schema)
}

export const validateIdentification = identification => {
  const schema = Yup.object({
    attributes: Yup.object({
      identification_id: Yup.string().required(),
      identification_type: Yup.string()
        .oneOf(['NIF'])
        .required(),
    }).required(),
  })

  return validateSync(identification, schema)
}

export const validateCompanyIdentification = identification => {
  const schema = Yup.object({
    attributes: Yup.object({
      identification_id: Yup.string().required(),
      identification_type: Yup.string()
        .oneOf(['CIF'])
        .required(),
    }).required(),
  })
  return validateSync(identification, schema)
}

export const validateIndividualAttributes = attributes => {
  const schema = Yup.object({
    given_name: Yup.string().required(),
    family_name: Yup.string().required(),
    additional_name: Yup.string(),
    language: Yup.string().required(),
    nationality: Yup.string().required(),
    honorific_prefix: Yup.string().required(),
    privacy_settings: Yup.object({
      mark_gdpr: Yup.boolean(),
    }),
  })
  return validateSync(attributes, schema)
}

export const validateExtraIndividualAttributes = attributes => {
  const schema = Yup.object({
    given_name: Yup.string().required(),
    family_name: Yup.string().required(),
    additional_name: Yup.string(),
  })
  return validateSync(attributes, schema)
}

export const validateExtraCompanyAttributes = attributes => {
  const schema = Yup.object({
    trading_name: Yup.string().required(),
    valid_for: Yup.object({
      start_datetime: Yup.string().required(),
    }),
  })
  return validateSync(attributes, schema)
}
