import * as constants from './constants'

const initialState = {
  loading: false,
  error: null,
  done: false,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.CREATE_TYPIFICATION_ATTEMPTED:
      return {
        ...initialState,
        loading: true,
      }
    case constants.CREATE_TYPIFICATION_SUCCEEDED:
      return {
        ...state,
        loading: false,
        error: null,
        done: true,
      }
    case constants.CLEAR_TYPIFICATION_ERROR:
      return {
        ...initialState,
      }
    case constants.CREATE_TYPIFICATION_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
        done: false,
      }
    default:
      return state
  }
}

export default reducer
