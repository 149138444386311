import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { fetchTerminalList } from 'modules/Terminals/services/TerminalListService'
import { selectTerminalListSelectors } from '../../containers/OfferContainer/TerminalSelectionContainer/TerminalSelectionContainer.selectors'
import { useChannelAndSegment } from '../Shared/index'

export function useTerminalListForPs({ shouldFetchList, ps, mobileNumberType }) {
  const [isLoadingTerminalList, setIsLoading] = useState(false)
  const [terminalListError, setError] = useState(false)
  const [terminalList, setTerminalList] = useState([])
  const { channel } = useChannelAndSegment()
  const { reseller } = useSelector(selectTerminalListSelectors)

  function fetchTerminals() {
    setTerminalList([])
    setIsLoading(true)
    setError(false)
    // TODO: remove once we dont need to mock the request to terminals
    fetchTerminalList(ps, channel, reseller, mobileNumberType)
      .then(res => {
        setTerminalList(res)
        setIsLoading(false)
      })
      .catch(() => {
        setIsLoading(false)
        setError(true)
      })
  }

  useEffect(() => {
    if (shouldFetchList && ps) {
      fetchTerminals()
    }
  }, [shouldFetchList, ps])

  return {
    isLoadingTerminalList,
    terminalListError,
    terminalList,
    fetchTerminals,
  }
}
