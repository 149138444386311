import * as constants from './constants'

const defaultState = {
  loading: false,
  data: {},
}
export const reducer = (state = defaultState, action) => {
  const { type, payload } = action

  switch (type) {
    case constants.CREATE_CHANGE_TECHNOLOGY: {
      return {
        ...state,
        loading: true,
      }
    }
    case constants.CREATE_CHANGE_TECHNOLOGY_SUCCESS: {
      return {
        ...state,
        data: payload.data,
        loading: false,
      }
    }
    case constants.CREATE_CHANGE_TECHNOLOGY_FAILED: {
      return {
        error_code: payload.error_code,
        error_message: payload.error_message,
        loading: false,
        data: {},
      }
    }
    case constants.CHANGE_TECHNOLOGY_SAVE_OPERATION_DATA: {
      return {
        ...state,
        dataOperation: payload.dataOperation,
      }
    }

    default:
      return state
  }
}
