/* eslint-disable import/no-cycle */

import { selectCoverageData } from 'modules/Coverage/store/selectors'
import { get, chain, some, head } from 'lodash'
import { createStructuredSelector, createSelector } from 'reselect'
import { createMatchSelector } from 'connected-react-router'
import {
  fromApiToProductLines,
  checkIfItsConvergent,
  getFormattedRetentionOptions,
  getLinePenalty,
  getProductType,
  getProductCharacteristic,
} from '../orders.helpers'

export const getOrderId = state => get(state, 'orders.data.id', '')

export const getSubscriptionId = state => get(state, 'orders.data.subscriptionId', '')

export const getExternalId = state => get(state, 'orders.data.externalId', '')
export const getContractid = state => get(state, 'orders.data.contractId', [])
export const getOrdersCustomer = state => get(state, 'orders.data.customer')
export const getOrdersStatus = state => get(state, 'orders.update')
export const getFirstNameFromOrdersCustomer = state => get(getOrdersCustomer(state), 'firstName')
export const getMidNameFromOrdersCustomer = state => get(getOrdersCustomer(state), 'midName')
export const getLastNameFromOrdersCustomer = state => get(getOrdersCustomer(state), 'lastName')
export const getProductsOrderCustomer = state => get(getOrdersCustomer(state), 'products')
export const getOrderLoading = state => get(state, 'orders.loading')
export const getOrderError = state => get(state, 'orders.error_message')
export const getOrderErrorCode = state => get(state, 'orders.error_code')
export const getOrderErrorMsg = state => get(state, 'orders.error_message')
export const getOrderUpdateError = state => get(state, 'orders.update.error')
export const getOrdersCancelDetails = state => get(state, 'orders.cancellation')
export const getOrdersAdditionalLines = state => get(state, 'orders.additionalLines')

export const getOrdersById =  state => get(state, 'orders.ordersById')

export const getSubscriptionIdFromOrdersById = orderId =>
  createSelector(
    [getOrdersById],
    ordersById =>
      get(ordersById, `${orderId}.subscriptionId`)
  )

export const getFullNameFromOrdersCustomer = createSelector(
  [getFirstNameFromOrdersCustomer, getMidNameFromOrdersCustomer, getLastNameFromOrdersCustomer],
  (firstName, midName, lastName) =>
    !firstName && !midName && !lastName
      ? '-'
      : [firstName, midName, lastName].filter(Boolean).join(' '),
)

export const getContactMediumFromOrdersCustomer = state =>
  get(getOrdersCustomer(state), 'contactMedium')

export const getTelephoneFromOrdersCustomer = createSelector(
  [getContactMediumFromOrdersCustomer],
  contactMedium =>
    !contactMedium || !Array.isArray(contactMedium)
      ? undefined
      : contactMedium.filter(contact => contact.type === 'TelephoneNumber')[0],
)

export const getEmailFromOrdersCustomer = createSelector(
  [getContactMediumFromOrdersCustomer],
  contactMedium =>
    !contactMedium || !Array.isArray(contactMedium)
      ? undefined
      : contactMedium.filter(contact => contact.type === 'Email')[0],
)

export const getIndividualIdentificationFromOrder = state =>
  get(state, 'orders.data.customer.individualIdentification', [])

export const getIdentificationIdFromOrder = state => {
  const individualIdentification = get(getOrdersCustomer(state), 'individualIdentification')
  return individualIdentification ? individualIdentification[0] : undefined
}

export const selectSegment = createSelector(
  getIdentificationIdFromOrder,
  id => id.type,
)

export const selectSegmentR = createSelector(
  getIdentificationIdFromOrder,
  id => (id.type === 'NIF' || id.type === 'NIE' ? 'B2C' : 'B2B'),
)

export const getCharacteristicsFromOrder = state => get(state, 'orders.data.characteristics', [])

export const getCharacteristicsCustomerFromOrder = state =>
  get(state, 'orders.data.customer.characteristics', [])

export const getPaymentMeanFromOrder = state => get(state, 'orders.data.paymentMean', {})

export const getFullNameAndIdentificationIdFromOrdersCustomer = createStructuredSelector({
  fullName: getFullNameFromOrdersCustomer,
  identification: getIdentificationIdFromOrder,
})

export const getIdOrderFromOrdersCustomer = state => get(state, 'orders.data.id')

export const getProductsOrdersCustomer = state => get(getOrdersCustomer(state), 'products', [])

export const getOneProductOrdersCustomer = (state, i) => {
  const product = get(getOrdersCustomer(state), 'products')
  return product && product[i] ? product[i] : undefined
}

export const getOneProductSpecsOrdersCustomer = (state, i) =>
  get(getOneProductOrdersCustomer(state, i), 'productsSpecifications')

export const getOneProductOneSpecOrdersCustomer = (state, nproduct, nspec) => {
  const productSpec = getOneProductSpecsOrdersCustomer(state, nproduct)
  return productSpec && productSpec[nspec] ? productSpec[nspec] : undefined
}

/*
 * returns work_order_type for all the productSpecCharacteristic associated to this order
 */
export const findWorkOrderTypes = order => {
  const products = get(order, 'products')
  return getProductCharacteristic(products, 'work_order_type')
}

export const selectInstallationPointProvider = createSelector(
  state => state,
  state => {
    const products = get(state, 'orders.data.customer.products')
    return head(getProductCharacteristic(products, 'installation_point_provider'))
  },
)

export const isFtthOrder = order => {
  const customer = get(order, 'customer')
  const workOrderTypes = findWorkOrderTypes(customer)
  return workOrderTypes.some(x => x === 'FTTH')
}

export const isAdslOrder = order => {
  const workOrderTypes = findWorkOrderTypes(order)
  return some(workOrderTypes, type => type === 'ADSL')
}

const getCustomer = state => get(state, 'customer.data')

const getPersonalDataFromCustomer = state => get(getCustomer(state), 'personalData')

export const getTelephoneFromCustomer = createSelector(
  [getPersonalDataFromCustomer],
  personalData => get(personalData, 'phoneNumber'),
)

export const getIdentificationIdFromCustomer = createSelector(
  [getPersonalDataFromCustomer],
  personalData => get(personalData, 'individualIdentification.value'),
)

export const getOrderData = state => get(state, 'orders.data')

const getProductLines = (products, chars) => {
  if (!products) return []
  return products.map(product => {
    const lines = get(product, 'productsSpecifications')
    const name = get(product, 'description', '')

    if (lines) {
      return fromApiToProductLines(lines, chars, name)
    }

    return []
  })[0]
}

// Will be used once we have penalties
// getTotalPenalty = lines =>
//   lines.reduce((prev, val) => `${get(prev, 'penalty') + get(val, 'penalty')} €`)
export const getOrderCancellationError = state => ({
  orderError: state.orders.error_code,
  orderLoading: state.orders.loading,
})

export const selectIsOrderTypeElFijo = createSelector(
  state => state,
  getProductsOrdersCustomer,
  (state, products) => {
    return get(head(products), 'category', '') === 'mobile_fixed_line'
  },
)

export const selectIsOrderTypeADSL = createSelector(
  getProductsOrdersCustomer,
  products => {
    return get(head(products), 'fixed_line_type', '') === 'adsl'
  },
)

export const selectIsOrderType2p = createSelector(
  getProductsOrdersCustomer,
  products => {
    const category = get(head(products), 'category', '')

    return category === 'fixed_only' || category === 'convergent'
  },
)

export const selectIsNewNumerationFixed = createSelector(
  getProductsOrdersCustomer,
  products =>
    products.some(product => {
      const productSpecs = get(product, 'productsSpecifications', []).find(
        spec => spec.name === 'fixed',
      )
      const productChars = get(productSpecs, 'productSpecCharacteristic', [])
      const phoneNumberType = productChars.find(psc => psc.name === 'phone_number_type')
      return get(phoneNumberType, 'value', '') === 'new'
    }),
)

export const selectLines = createSelector(
  [getProductsOrdersCustomer, getCharacteristicsFromOrder],
  getProductLines,
)

export const selectProductType = createSelector(
  getProductsOrdersCustomer,
  getProductType,
)
export const selectTechnology = createSelector(
  getOrdersCustomer,
  findWorkOrderTypes,
)

export const getCancelOptions = state => get(state, 'orders.cancellationOptions')
export const getRetainOptions = state => get(state, 'orders.retentionOptions.data')
export const selectRetainOptions = createSelector(
  getRetainOptions,
  getFormattedRetentionOptions,
)

export const selectDocumentType = state =>
  get(state, 'orders.data.customer.individualIdentification[0].type')

export const selectDocumentId = state =>
  get(state, 'orders.data.customer.individualIdentification[0].identificationId')

export const isConvergent = state => checkIfItsConvergent(getProductsOrdersCustomer(state))

export const getContactMediumFromOrdersCustomerWithNewDirection = state => {
  const cm = getContactMediumFromOrdersCustomer(state)
  const newDirection = selectCoverageData(state)
  cm.map(x => ({
    ...x,
    medium:
      x.type === 'InstallationAddress' ||
      x.type === 'BillingAddress' ||
      x.type === 'InstallationAddressFixedLine'
        ? Object.assign(newDirection)
        : x.medium,
  }))
  return cm
}

export const getOrders = state => get(state, 'orders.data')

export const getPenaltiesLoading = state => get(state, 'orders.penalties.loading') !== false

export const getGescal17Order = state => {
  const contactMedium = getContactMediumFromOrdersCustomer(state)
  return chain(contactMedium)
    .flatMap()
    .find({
      type: 'InstallationAddressFixedLine',
    })
    .flatMap('characteristic')
    .filter({
      name: 'gescal',
    })
    .map('value')
    .value()[0]
}

export const selectTerritoryOwnerOrder = createSelector(
  state => state,
  state => {
    const contactMedium = getContactMediumFromOrdersCustomer(state)
    return chain(contactMedium)
      .flatMap()
      .find({
        type: 'InstallationAddressFixedLine',
      })
      .flatMap('characteristic')
      .filter({
        name: 'territoryOwner',
      })
      .map('value')
      .value()[0]
  },
)

export const getFixedNumberPhone = state => {
  const products = get(getOrders(state), 'customer.products', {})
  return chain(products)
    .flatMap('productsSpecifications')
    .filter({
      name: 'fixed',
    }) // Type line
    .map('id')
    .value()[0]
}

export const selectTelephoneNumbers = createSelector(
  [getContactMediumFromOrdersCustomer],
  (contactMedium = []) => contactMedium.filter(elem => elem.type === 'TelephoneNumber'),
)

export const selectPenalties = state => get(state, 'orders.penalties', {})

export const getPenalties = createSelector(
  [selectPenalties],
  penalties => ({
    ...(penalties?.data ? getLinePenalty(penalties.data) : {}),
    error: !!penalties.error,
  }),
)

export const getBirthdate = createSelector(
  [getCharacteristicsCustomerFromOrder],
  data =>
    chain(data)
      .find({ name: 'birthday' })
      .get('value')
      .value(),
)

export const getIBAN = createSelector(
  [getPaymentMeanFromOrder],
  data =>
    chain(data)
      .head()
      .get('bankAccount.iban')
      .value(),
)

export const getSegmentCustomer = createSelector(
  [getOrdersCustomer],
  data =>
    chain(data)
      .get('segment')
      .value(),
)

export const getLanguageCustomer = createSelector(
  [getCharacteristicsCustomerFromOrder],
  data =>
    chain(data)
      .find({ name: 'language' })
      .get('value')
      .value()
      ?.replace('_', '-'),
)

export const getNationalityCustomer = createSelector(
  [getCharacteristicsCustomerFromOrder],
  data =>
    chain(data)
      .find({ name: 'nationality' })
      .get('value')
      .value(),
)

export const getOrderDate = createSelector(
  [getOrderData],
  data =>
    chain(data)
      .get('orderDate')
      .value(),
)

export const getChannelCharacteristic = createSelector(
  [getCharacteristicsFromOrder],
  data =>
    chain(data)
      .find({ name: 'channel' })
      .get('value')
      .value(),
)

export const selectIsPosOrder = createSelector(
  getChannelCharacteristic,
  value => ['posorder', 'pos'].includes(value),
)

export const getSFIDOrder = createSelector(
  [getOrderData],
  data =>
    chain(data)
      .get('sfid')
      .value(),
)

export const getFlow = createSelector(
  [getOrderData],
  data =>
    chain(data)
      .get('flow')
      .value(),
)

export const getBillingAddress = createSelector(
  [getContactMediumFromOrdersCustomer],
  data =>
    chain(data)
      .find({ type: 'BillingAddress' })
      .get('medium')
      .value(),
)

export const getContactTelephonesOrder = createSelector(
  [getContactMediumFromOrdersCustomer],
  data =>
    chain(data)
      .filter({ type: 'TelephoneNumber' })
      .map(el => get(el, 'medium.number'))
      .value(),
)

export const getEmail = createSelector(
  [getEmailFromOrdersCustomer],
  data =>
    chain(data)
      .get('medium.emailAddress')
      .value(),
)

export const getTypeContract = createSelector(
  [getIBAN],
  data => {
    let text = '-'
    if (data) {
      text = 'PostPago'
    }
    return text
  },
)

export const getMethodPay = createSelector(
  [getIBAN],
  data => {
    let text = '-'
    if (data) {
      text = 'Cuenta bancaria'
    }
    return text
  },
)

export const getProductNumber = createSelector(
  [getProductsOrdersCustomer],
  data =>
    chain(data)
      .flatMap('productNumber')
      .head()
      .value() || '-',
)

export const selectURLOrderMatch = createMatchSelector({
  path: [
    '/vista/dashboard/documentType/:documentType/documentId/:documentId/order/:orderId',
    '/vista/dashboard/documentType/:documentType/documentId/:documentId/orderDetail/:orderId',
    '/clients/orders/:orderId',
  ],
})

export const selectURLOrderId = createSelector(
  selectURLOrderMatch,
  match => get(match, 'params.orderId', ''),
)

const getOrdersCancellationInfo = state => get(state, 'orders.ordersCancellationInfo')

export const selectOrdersCancellationInfo = createSelector(
  [getOrdersCancellationInfo],
  cancellationInfo => cancellationInfo.data,
)

export const selectOrdersCancellationFlow = createSelector(
  [selectOrdersCancellationInfo, selectURLOrderId],
  (cancellationInfo, orderId) => {
    const mainOrder = cancellationInfo?.find(order => order.orderId === orderId)

    return mainOrder?.flow
  },
)

export const selectOrdersCancellationInfoIsLoading = createSelector(
  [getOrdersCancellationInfo],
  cancellationInfo => cancellationInfo.loading,
)

export const selectOrdersCancellationInfoHasError = createSelector(
  [getOrdersCancellationInfo],
  cancellationInfo => cancellationInfo.error,
)

export const selectOrdersCancellationIsLoading = createSelector(
  getOrdersCancelDetails,
  details => details.loading,
)

export const selectOrdersCancellationError = createSelector(
  getOrdersCancelDetails,
  details => details.error,
)

export const selectOrdersCancellationResult = createSelector(
  getOrdersCancelDetails,
  details => details.loading === false && !details.error,
)

export const selectOrdersCanceled = createSelector(
  getOrdersCancelDetails,
  details => (details.loading === false && details.error && details.result) || [],
)

export const selectOrdersAdditionalLinesIsLoading = createSelector(
  getOrdersAdditionalLines,
  details => details.loading,
)

export const selectOrdersAdditionalLinesError = createSelector(
  getOrdersAdditionalLines,
  details => details.error,
)

export const selectOrdersAdditionalLines = createSelector(
  getOrdersAdditionalLines,
  additionalLinesResult => additionalLinesResult.data,
)
