import PropTypes from 'prop-types'

import { useSelector } from 'react-redux'
import { selectPoncHasBeenReached } from 'modules/ponc'

import { CircularProgress, Box } from '@material-ui/core'
import { Button } from 'components/ui'
import { CardRow, CardTitle } from 'components'

const CancellationResult = ({ isSaving, hasError, isSuccess, closeWindow, onRetry }) => {
  const poncHasReached = useSelector(selectPoncHasBeenReached)

  return (
    <>
      {isSaving && (
        <Box display="flex" alignItems="center" p="50px 0">
          <CircularProgress size={100} />
        </Box>
      )}

      {isSuccess && (
        <>
          <CardRow>
            <CardTitle>SE REALIZÓ LA OPERACIÓN CON ÉXITO</CardTitle>
          </CardRow>
          <CardRow>
            <Button onClick={closeWindow}>VOLVER A VISTA</Button>
          </CardRow>
        </>
      )}

      {hasError && poncHasReached && (
        <>
          <CardRow>
            <CardTitle>SE HA ALCANZADO EL PUNTO DE NO CANCELACIÓN</CardTitle>
          </CardRow>
          <CardRow>
            <Button color="primary" variant="contained" onClick={closeWindow}>
              CANCELAR Y VOLVER
            </Button>
          </CardRow>
        </>
      )}

      {hasError && !poncHasReached && (
        <>
          <CardRow>
            <CardTitle>HA SUCEDIDO UN ERROR INESPERADO</CardTitle>
          </CardRow>
          <CardRow>
            <Box display="flex" alignItems="flex-start" justifyContent="flex-start">
              <div>
                <Button onClick={onRetry}>ENVIAR DE NUEVO</Button>
              </div>
              <div>
                <Button secondary onClick={closeWindow} style={{ marginLeft: '20px' }}>
                  VOLVER
                </Button>
              </div>
            </Box>
          </CardRow>
        </>
      )}
    </>
  )
}

CancellationResult.propTypes = {
  isSaving: PropTypes.bool,
  hasError: PropTypes.bool,
  isSuccess: PropTypes.bool,
  closeWindow: PropTypes.func,
  onRetry: PropTypes.func,
}

export default CancellationResult
