import { APP_CONFIG } from 'services/app-config'
import { axiosJWT } from 'modules/axios'

const donorAPIUrl = `${APP_CONFIG.sales_cluster}/v1/portabilities/holidays`

export function fetchPortabilityDatesAPI() {
  return axiosJWT({
    method: 'GET',
    url: donorAPIUrl,
  }).then(res => res)
}
