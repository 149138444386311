import { axiosJWT } from 'modules/axios'
import { APP_CONFIG } from 'services/app-config'

const CONFIG = {
  api: {
    checkout: `${APP_CONFIG.sales_cluster}/v1/checkout`,
    old_cluster: `${APP_CONFIG.apigee_host}/masmovil/v3/orders/checkout`,
  },
}

const checkoutURL = CONFIG.api.checkout

export function fetchCheckOrderStatus(taskId) {
  return axiosJWT({ method: 'GET', url: `${checkoutURL}/status/${taskId}` }).then(resp => resp.data)
}

export function createNewOrder(scoringToken, data) {
  return axiosJWT({
    method: 'POST',
    headers: {
      'scoring-token': scoringToken,
    },
    url: `${checkoutURL}/orders`,
    data,
  }).then(resp => resp.data)
}
