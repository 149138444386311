import { createSelector, createStructuredSelector } from 'reselect'
import { get, pickBy, head, isEqual, isEmpty } from 'lodash'
import {
  selectChannelQueryParam,
  selectBrandQueryParam,
  selectIsSubscriptionQueryParam,
  selectIs2p,
  selectIsCrossSellQueryParam,
  selectIsMultiSimQueryParam,
} from 'modules/Router'
import * as coverage from 'modules/Coverage'
import { selectTechnology } from 'modules/Coverage/store'
import { selectSfid, selectIpAddress } from 'modules/Auth'
import { secondResidenceDiscountPackOfferId } from 'modules/Discounts/constants'
import {
  SCORING_ERROR,
  SCORING_WAIT,
  SCORING_MISSING,
  SCORING_DENIEND,
  OTHER_ADDRESS,
} from 'modules/SharedSales/constants'

import { selectSecondHomeDiscount } from 'modules/NewClientSales/store/selectors/discounts.selectors'
import { selectIsMobileOnly } from 'modules/Router/store/index'
import { storeSelectors } from 'modules/NewClientSales/hooks/Shared/useSetCustomerInfo/useSetCustomerInfo.selectors'

import {
  selectCustomerChannel,
  selectCustomerData,
  selectCustomerSegment,
  selectors as customerSelectors,
} from 'services/customer-360/selectors'
import { selectD2RFromMsFlag } from 'services/feature-flag/selectors'
import { getNationalityCustomer } from 'modules/orders/index'
import {
  selectLandlineOffer,
  selectMainMobileLineOffer,
  selectAccount,
  selectExtraMobileLinesOffers,
  selectBilling,
  selectIBAN,
  selectReferenceNumber,
  selectIsCompany,
  selectServiceAddress,
  selectIsElFijo,
  selectExtraMobileLinesOfferIds,
} from './Basket.selectors'

import { selectSaleTax } from './Taxes.selectors'
import { mapAddressToBillingAddress, mapLineDataToScoring } from './scoring.selectors.helpers.new'
import {
  selectedLandlineProSVAsFormated,
  selectIs2pPure,
  selectIsExistingClientSaleFlow,
  selectPhoneNumbers,
} from './NewClientSales.selectors'

export const getScoring = state => get(state, 'scoring', {})

export const selectScoringToken = createSelector(
  [getScoring, selectIsMultiSimQueryParam],
  (scoring, isMultiSim) => (isMultiSim ? 'no-validation' : get(scoring, 'scoring.scoringToken')),
)

export const selectScoring = createSelector(
  getScoring,
  scoring => get(scoring, 'scoring.data'),
)

export const selectScoringDecision = createSelector(
  getScoring,
  scoring => get(scoring, 'scoring.data.decision'),
)

export const selectScoringLoading = createSelector(
  getScoring,
  scoring => scoring.loading,
)

export const selectPrescoringData = createSelector(
  getScoring,
  scoring => scoring?.prescoring
)

export const selectPrescoringFailed = createSelector(
  getScoring,
  scoring => scoring?.prescoring?.decision === 'D',
)

export const selectPrescoringErrorCode = createSelector(
  getScoring,
  scoring => scoring?.prescoring?.code,
)

export const selectPrescoringReferenceNumber = createSelector(
  getScoring,
  scoring => scoring?.prescoring?.referenceNumber,
)

export const selectScoringError = createSelector(
  getScoring,
  scoring => scoring.errorScoring,
)

export const selectScoringErrorMessage = createSelector(
  selectScoring,
  selectScoringError,
  (scoring, msError) => {
    const error = head(get(scoring, 'errors', []))?.errorc
    const decision = scoring?.decision

    if (msError) return SCORING_ERROR
    if (decision === 'D') return SCORING_DENIEND
    if (decision === 'C' || (error !== '000' && error !== '987')) return SCORING_MISSING
    if (error === '987') return SCORING_WAIT
    return ''
  },
)

// Internal selectors
const selectSegment = createSelector(
  selectAccount,
  account => get(account, 'segment'),
)

const selectChannel = createSelector(
  selectChannelQueryParam,
  channel => (channel.indexOf('telesales') > -1 ? 'TELESALES' : 'FISICO'),
)

export const selectSubmission = createStructuredSelector({
  brand: selectBrandQueryParam,
  salesTool: () => 'OVID',
  channel: selectChannel,
  channelRaw: selectChannelQueryParam,
  referenceNumber: selectReferenceNumber,
  segment: selectSegment,
  ip: selectIpAddress,
})

const selectSessionId = createSelector(
  coverage.getSessionId,
  selectServiceAddress,
  (sessionId, serviceAddress) => sessionId || serviceAddress.otherAddress.sessionId,
)

const selectScort = createSelector(
  selectIsCrossSellQueryParam,
  isCrossSell => (isCrossSell ? 'xsell' : 'Venta'), // @TODO should be calculated in the future,
)

const selectSellInfo = createStructuredSelector({
  sessionId: selectSessionId,
  sfId: selectSfid,
  scort: selectScort,
})

const selectPayment = createStructuredSelector({
  recurrentPM: () => '01', // @TODO once we have devices
  iban: selectIBAN,
})

const selectLineDetails = createSelector(
  [
    selectLandlineOffer,
    selectMainMobileLineOffer,
    selectExtraMobileLinesOffers,
    selectTechnology,
    selectSaleTax,
    selectIsMobileOnly,
    selectIsElFijo,
    selectIs2p,
    selectIsSubscriptionQueryParam,
    selectedLandlineProSVAsFormated,
    selectIsCrossSellQueryParam,
    selectIs2pPure,
  ],
  mapLineDataToScoring,
)

const selectMainOfferId = createSelector(
  [selectLandlineOffer, selectMainMobileLineOffer, selectExtraMobileLinesOfferIds],
  (landlineOffer, mainMobileLineOffer, extraMobileLineOfferIds) => {
    return landlineOffer.tariff?.id || mainMobileLineOffer.tariff?.id || extraMobileLineOfferIds[0]
  },
)

const selectPackOfferId = createSelector(
  selectIs2p,
  selectSecondHomeDiscount,
  selectMainOfferId,
  selectD2RFromMsFlag,
  selectLandlineOffer,
  (is2p, secondHomeDiscount, mainOfferId, isD2RMsFlag, { hasSecondResidenceDiscount }) =>
    (!isEmpty(secondHomeDiscount) || (isD2RMsFlag && hasSecondResidenceDiscount)) && is2p
      ? secondResidenceDiscountPackOfferId
      : mainOfferId,
)

const selectOffer = createSelector(
  selectLineDetails,
  selectPackOfferId,
  (lineDetails, packOfferId) => ({
    packOfferId,
    lineDetails,
  }),
)

const selectInstallationAddress = createStructuredSelector({
  gescal: coverage.selectGescal,
  city: coverage.selectCity,
  province: coverage.selectProvince,
  zipCode: coverage.selectPostalCode,
  streetType: coverage.selectTypeAddress,
  street: coverage.selectNameAddress,
  number: coverage.selectNumberAddress,
  door: coverage.selectDoorAddress,
  hand: coverage.selectHandAddress,
  stair: coverage.selectStairAddress,
  floor: coverage.selectFloorAddress,
  block: coverage.selectBlockAddress,
  letter: coverage.selectLetterAddress,
  addressRegisterId: coverage.selectIdAddress,
  country: () => 'ES',
  Km: coverage.selectKmAddress,
  bis: coverage.selectBisAddress,
})

const selectScoringInstallationAddress = createSelector(
  selectInstallationAddress,
  selectIsMobileOnly,
  selectIsElFijo,
  (installation, isMobileOnly, isElFijo) => {
    return isMobileOnly || isElFijo ? {} : installation
  },
)

const selectScoringBillingAddress = createSelector(
  selectInstallationAddress,
  selectBilling,
  selectServiceAddress,
  (installation, billing, service) => {
    let billingPostalAddress = {}

    switch (billing.selectedAddressType) {
      case 'installationAddress':
        billingPostalAddress = installation
        break
      case 'serviceAddress':
        billingPostalAddress = service.otherAddress
        break
      default:
        billingPostalAddress = billing.otherAddress

        break
    }

    const { flat, floor, address, ...finalBillingAddress } = billingPostalAddress

    return {
      floor: floor || flat,
      ...finalBillingAddress,
      street: address || finalBillingAddress.street,
    }
  },
)

const selectIsCarteraCustomerDataHasChanged = createSelector(
  selectIsExistingClientSaleFlow,
  selectBilling,
  selectAccount,
  selectCustomerData,
  storeSelectors,
  selectPhoneNumbers,
  (isCartera, billing, salesAccount, customerData, { customerIban }, phoneNumbers) => {
    let hasCustomerDataChanged = false

    if (isCartera) {
      const { mainNumber, secondNumber } = phoneNumbers
      const salesIbanWithoutSpaces = billing.ibanNumber.replace(/\s/g, '')
      const addressChanged =
        billing.selectedAddressType !== OTHER_ADDRESS.value || !billing.otherAddress.editAddress

      hasCustomerDataChanged =
        addressChanged ||
        (!isEqual(salesAccount.phoneContact, customerData.phoneContact) &&
          !isEqual(salesAccount.phoneContact, secondNumber)) ||
        (!isEqual(salesAccount.phoneContact2, customerData.phoneContact2) &&
          !isEqual(salesAccount.phoneContact2, mainNumber)) ||
        !isEqual(salesAccount.email, customerData.email) ||
        !isEqual(salesIbanWithoutSpaces, customerIban)
    }

    return hasCustomerDataChanged
  },
)

const selectDataRecover = createSelector(
  selectIsExistingClientSaleFlow,
  selectIsCarteraCustomerDataHasChanged,
  (isCarteraFlow, isCarteraDataChanged) => {
    return {
      dataRecover: isCarteraFlow ? '1' : '0',
      dataRecoverm: isCarteraDataChanged ? '1' : '0',
    }
  },
)

const selectCustomer = createSelector(
  selectAccount,
  selectScoringInstallationAddress,
  selectScoringBillingAddress,
  selectDataRecover,
  (account, scoringInstallation, scoringBilling, { dataRecover, dataRecoverm }) => {
    return pickBy({
      personalInformation: {
        nationality: account.nationality,
        dateOfBirth: account.birthday.replace(/\//gi, '-'),
        firstName: account.name,
        middleName: account.surname1,
        lastName: account.surname2,
        dataRecover,
        dataRecoverm,
        contact: {
          email: account.email,
          mobileNumber: account.phoneContact,
          telephoneNumber: account.phoneContact2,
        },
        identificationNumber: {
          type: account.documenttype,
          value: account.documentid,
        },
        companyIdentificationNumber: {
          type: 'CIF',
          value: account.companyCif,
        },
      },
      billingPostalAddress: scoringBilling.streetType && {
        ...scoringBilling,
      },
      installationAddress: scoringInstallation.streetType && {
        ...scoringInstallation,
      },
      terms: undefined,
    })
  },
)

const selectOrganizations = createSelector(
  selectAccount,
  account => ({
    companyName: account.companyName,
    creationDate: account.constitutionDate.replace(/\//gi, '-'),
  }),
)

export const selectScoringRequestBodyNew = createStructuredSelector({
  submission: selectSubmission,
  sellInfo: selectSellInfo,
  customer: selectCustomer,
  payment: selectPayment,
  offer: selectOffer,
  organizations: selectOrganizations,
})

export const selectPrescoringRequestBody = createSelector(
  selectSubmission,
  selectSellInfo,
  selectCustomer,
  selectOrganizations,
  selectIsCompany,
  (submission, sellInfo, customer, organizations, isCompany) =>
    pickBy({
      submission,
      sellInfo,
      customer,
      organizations: isCompany && organizations,
    }),
)

export const selectCareOrganizations = createSelector(
  selectCustomerData,
  customerData => ({
    companyName: customerData.companyName,
    creationDate: customerData.constitutionDate.replace(/\//gi, '-'),
  }),
)

export const selectCareCustomer = isDataChange =>
  createSelector(
    selectCustomerData,
    customerSelectors.getBillingAddress,
    getNationalityCustomer,
    (customerData, billingAddress, nationalityOnFlyCustomer) => {
      const dataRecoverm = isDataChange ? '1' : '0'

      const billingPostalAddress = mapAddressToBillingAddress(billingAddress)

      return pickBy({
        personalInformation: {
          nationality: customerData.nationality || nationalityOnFlyCustomer,
          dateOfBirth: customerData.birthday.replace(/\//gi, '-'),
          firstName: customerData.name,
          middleName: customerData.surname1,
          lastName: customerData.surname2,
          contact: {
            email: customerData.email,
            mobileNumber: customerData.phoneContact,
            telephoneNumber: customerData.phoneContact2,
          },
          identificationNumber: {
            type: customerData.documenttype,
            value: customerData.documentid,
          },
          companyIdentificationNumber: {
            type: 'CIF',
            value: customerData.companyCif,
          },
          dataRecover: '1',
          dataRecoverm,
        },
        billingPostalAddress,
        terms: undefined,
      })
    },
  )

export const selectCareSVAsSellInfo = createSelector(
  selectSubmission,
  selectSfid,
  customerSelectors.getIBAN,
  selectCustomerChannel,
  selectIpAddress,
  selectCustomerSegment,
  selectCareOrganizations,
  (submission, sfId, careIban, careChannel, ipAddress, careSegment, companyData) => {
    const organizations = companyData.companyName ? companyData : undefined

    return pickBy({
      submission: {
        ...submission,
        referenceNumber: null,
        segment: careSegment,
        channel: 'ALTERNATIVO',
        channelRaw: careChannel,
      },
      sellInfo: {
        sessionId: ipAddress,
        sfId,
        scort: 'svas',
      },
      payment: {
        recurrentPM: '0',
        iban: careIban,
      },
      organizations,
    })
  },
)
