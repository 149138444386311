import PropTypes from 'prop-types'
import { Typography, Box } from '@material-ui/core'
import { getCountryFromIsoCode } from 'utils'

import { formatCensuredDni } from 'modules/vista-360/helpers/formats.helper'

const PERSONAL_DATA_TITLE = 'DATOS PERSONALES'
const PERSONAL_DATA_ADMINSTRATOR_TITLE = 'DATOS DEL ADMINISTRADOR'

export function ClientDataSummary({
  name,
  surname1,
  surname2,
  documentid,
  phoneContact,
  nationality,
  birthday,
  documenttype,
  isCompany,
  isPosOrMasProximo,
}) {
  const clientNationality =
    nationality === ClientDataSummary.defaultProps.nationality
      ? '-'
      : getCountryFromIsoCode(nationality)
  const clientFullName =
    name === ClientDataSummary.defaultProps.name ? '-' : `${name} ${surname1} ${surname2}`

  return (
    <>
      <Typography variant="subtitle1" color="secondary" className="bold m-b-20">
        {isCompany ? PERSONAL_DATA_ADMINSTRATOR_TITLE : PERSONAL_DATA_TITLE}
      </Typography>
      <Box display="flex">
        <Box display="flex" alignItems="center" justifyContent="flex-start" mb="4px" mr="28px">
          <Typography variant="body1" className="p-r-4">
            Nombre y apellidos:
          </Typography>
          <Typography variant="body1" className="bold" data-hook="account.userName">
            {clientFullName}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="flex-start" mb="4px">
          <Typography variant="body1" className="p-r-4">
            {documenttype}:
          </Typography>
          <Typography variant="body1" className="bold" data-hook="account.documentid">
            {isPosOrMasProximo ? formatCensuredDni(documentid) : documentid}
          </Typography>
        </Box>
      </Box>
      <Box display="flex" mt="8px">
        {!isCompany ? (
          <Box display="flex" alignItems="center" justifyContent="flex-start" mb="4px" mr="28px">
            <Typography variant="body1" className="p-r-4">
              Fecha de nacimiento:
            </Typography>
            <Typography variant="body1" className="bold" data-hook="account.birthday">
              {`${birthday}`}
            </Typography>
          </Box>
        ) : null}

        <Box display="flex" alignItems="center" justifyContent="flex-start" mb="4px" mr="28px">
          <Typography variant="body1" className="p-r-4">
            Nacionalidad:
          </Typography>
          <Typography variant="body1" className="bold" data-hook="account.nationality">
            {clientNationality}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="flex-start">
          <Typography variant="body1" className="p-r-4">
            Teléfono de contacto 1:
          </Typography>
          <Typography variant="body1" className="bold" data-hook="account.phoneContact">
            {phoneContact}
          </Typography>
        </Box>
      </Box>
    </>
  )
}

ClientDataSummary.propTypes = {
  name: PropTypes.string,
  surname1: PropTypes.string,
  surname2: PropTypes.string,
  documentid: PropTypes.string,
  phoneContact: PropTypes.string,
  nationality: PropTypes.string,
  birthday: PropTypes.string,
  documenttype: PropTypes.string,
  isCompany: PropTypes.bool,
  isPosOrMasProximo: PropTypes.bool,
}

ClientDataSummary.defaultProps = {
  name: '-',
  surname1: '-',
  surname2: '',
  documentid: {
    value: '-',
  },
  phoneContact: '-',
  nationality: '-',
  birthday: '-',
  isCompany: false,
}
