import { useMemo, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'

import { useSelector, useDispatch } from 'react-redux'

import { useFormikContext } from 'formik'

import { Box } from '@material-ui/core'

import { Button } from 'components/ui'

import { SINGLE } from 'services/global-constants'
import { useTerminalProffession } from 'modules/NewClientSales/hooks/Terminals'
import {
  YOIGO_STORE,
  BILLING_ADDRESS,
  OTHER_ADDRESS,
} from 'modules/SharedSales/constants/shippingAddress'

import { useAgileTvValidation } from 'modules/NewClientSales/hooks/Validations/useAgileTvValidation'

import { useStepNavigation } from 'utils/navigation'
import { FinancedLegalModal } from 'modules/financing/components'

import { withDeviceRenewalForms } from '../../hocs/withDeviceRenewalForms'
import { RenewalSaveChangesStepButton } from '../../components/RenewalSaveChangesStepButton'
import { RenewalLegalModal } from '../../components/RenewalLegalModal'

import { setDeviceRenewalData } from '../../store/device-renewal.actions'

import {
  DEVICE_RENEWAL_FIELDNAMES,
  ORDERED_DEVICE_RENEWAL_STEPS,
  DEVICE_TYPE_AGILE_TV,
} from '../../constants'
import { OtherAddressModel } from '../../models'

import {
  deviceSummarySelectors,
  selectIsAdditionalLineWithDiscount,
} from './DeviceSummaryContainer.selectors'
import { DeviceSummary } from './DeviceSummary'

export const DeviceSummaryContainer = withDeviceRenewalForms(({ step }) => {
  const dispatch = useDispatch()
  const { values, setFieldValue } = useFormikContext()

  const { navigateToNextStep, goBack } = useStepNavigation(ORDERED_DEVICE_RENEWAL_STEPS, step)

  const [isFinancedLegalModalOpen, setIsFinancedLegalModalOpen] = useState(false)
  const [isLegalModalOpen, setIsLegalModalOpen] = useState(false)

  const {
    device,
    professions,
    preselectedAddress,
    msisdn,
    currentFinancedDeviceInfo,
    deviceType,
    agileTvData,
  } = useSelector(deviceSummarySelectors)

  const isAdditionalLineWithDiscount = useSelector(selectIsAdditionalLineWithDiscount(msisdn))

  const { validateAgileTvEmail } = useAgileTvValidation()

  useEffect(() => {
    if (deviceType === DEVICE_TYPE_AGILE_TV) {
      validateAgileTvEmail(
        get(values, DEVICE_RENEWAL_FIELDNAMES.SVA_AGILE_EMAIL),
        DEVICE_RENEWAL_FIELDNAMES.SVA_AGILE_EMAIL,
      )
    }
  }, [])

  const paymentType = useMemo(
    () => device?.paymentMethods && Object.keys(device.paymentMethods)[0],
    [device.paymentMethods],
  )
  const jobId = useMemo(() => get(values, DEVICE_RENEWAL_FIELDNAMES.JOB), [values])

  const { showCompany, onSelectProfession } = useTerminalProffession(
    paymentType,
    jobId,
    setFieldValue,
    DEVICE_RENEWAL_FIELDNAMES.COMPANY,
  )

  const availableAddressTypes = [YOIGO_STORE, BILLING_ADDRESS, OTHER_ADDRESS]

  const addressType = useMemo(() => get(values, DEVICE_RENEWAL_FIELDNAMES.ADDRESS_TYPE), [values])

  const onSelectAddressType = type => {
    setFieldValue(DEVICE_RENEWAL_FIELDNAMES.ADDRESS_TYPE, type)
  }

  const onResetOtherAddress = () => {
    setFieldValue(DEVICE_RENEWAL_FIELDNAMES.OTHER_ADDRESS, OtherAddressModel)
  }

  const onContinue = () => {
    if (!device.paymentMethods[SINGLE]) {
      setIsFinancedLegalModalOpen(true)
    } else {
      setIsLegalModalOpen(true)
    }
  }

  const onFinancedLegalConfirm = () => {
    setIsFinancedLegalModalOpen(false)
    setIsLegalModalOpen(true)
  }

  const onLegalConfirm = () => {
    setIsLegalModalOpen(false)

    dispatch(setDeviceRenewalData(values))
    setTimeout(navigateToNextStep, 100)
  }

  const props = {
    device,
    paymentType,
    professions,
    showCompany,
    onSelectProfession,
    availableAddressTypes,
    addressType,
    onSelectAddressType,
    onResetOtherAddress,
    preselectedAddress,
    isAdditionalLineWithDiscount,
    currentFinancedDeviceInfo,
    deviceType,
    agileTvData,
    validateAgileTvEmail,
  }

  return (
    <>
      <DeviceSummary {...props} onChangeDevice={goBack} />

      {isFinancedLegalModalOpen && (
        <FinancedLegalModal
          open
          fieldNames={DEVICE_RENEWAL_FIELDNAMES}
          onClose={onFinancedLegalConfirm}
        />
      )}

      {isLegalModalOpen && (
        <RenewalLegalModal
          isOpen={isLegalModalOpen}
          onConfirm={onLegalConfirm}
          agileTvData={agileTvData}
          deviceShipping={values.shipping}
        />
      )}

      <Box display="flex" alignItems="center" pt="12px">
        <RenewalSaveChangesStepButton
          stepName={step}
          isLoading={false}
          onContinue={onContinue}
          noStoreValues
        />

        <Button margin="0 0 0 16px" secondary onClick={goBack} data-hook="exit-device-renew">
          Atrás
        </Button>
      </Box>
    </>
  )
})

DeviceSummaryContainer.propTypes = {
  step: PropTypes.string,
}
