import { useState } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'

import { Modal } from 'components/ui'

export const DynamicModal = ({
  title,
  component: ContentComponent,
  componentProps,
  cssClasses,
  onClose,
}) => {
  const className = get(cssClasses, 'custom-width-modal', null)

  return (
    <Modal
      className={className}
      isOpen={Boolean(ContentComponent)}
      onClose={onClose}
      title={title || ''}
      overFlowY="auto">
      {ContentComponent && <ContentComponent {...componentProps} onClose={onClose} />}
    </Modal>
  )
}
DynamicModal.propTypes = {
  title: PropTypes.string,
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  componentProps: PropTypes.object,
  cssClasses: PropTypes.object,
  onClose: PropTypes.func,
}

export const useDynamicModals = () => {
  const [modalTitle, setModalTitle] = useState('')
  const [modalData, setModalData] = useState({})

  const onModalOpen = (component, componentProps, title, queryParams = null, cssClasses) => {
    setModalTitle(title)
    setModalData({
      component,
      componentProps,
      queryParams,
      cssClasses,
    })
  }

  const onModalClose = () => {
    if (modalData.queryParams) {
      modalData.queryParams()
    }

    setModalTitle('')
    setModalData({})
  }

  const onTitleChange = tariffType => {
    const title = `Modificar tarifa ${tariffType === 'postpaid' ? 'pospago' : 'prepago'}`
    setModalTitle(title)
  }

  return {
    modalProps: { title: modalTitle, ...modalData, onClose: onModalClose },
    onModalOpen,
    onModalClose,
    onTitleChange,
  }
}
