// TODO: migrate to datefn
export function formatDate(date) {
  if (!date) return null
  const dateToFormat = new Date(date)
  return {
    date: `${dateToFormat.getDate()}/${dateToFormat.getMonth() + 1}/${dateToFormat.getFullYear()}`,
    time: `${dateToFormat.getHours()}:${
      dateToFormat.getMinutes() < 10 ? `0${dateToFormat.getMinutes()}` : dateToFormat.getMinutes()
    }`,
  }
}
