import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { uniq } from 'lodash'
import { Field, getIn, useFormikContext } from 'formik'

import { CircularProgress, Box, FormHelperText, Typography } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { makeStyles } from '@material-ui/styles'

import { TextStyles } from 'utils/text'

import { ComposedTextField, ButtonLink } from 'components/ui'
import { DEVICE_CATEGORIES_NAMES } from 'modules/NewClientSales/constants'

import { TerminalStockComponent } from '../TerminalStockComponent'

const getCategoryLabel = categoryId => DEVICE_CATEGORIES_NAMES[categoryId] || categoryId

const useStyles = makeStyles(theme => ({
  input: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: theme.palette.brand.light,
    },
  },
}))

export function TerminalSelector({
  fieldNames,
  terminalModels,
  onCategoryChange,
  onBrandChange,
  onModelChange,
  terminalDetails,
  isStockCheck,
  isAppleTv,
  isListLoading,
  listError,
  disableOfferEdit,
  fetchTerminals,
}) {
  const styles = useStyles()
  const { values } = useFormikContext()

  const formCategory = getIn(values, fieldNames.TERMINAL_CATEGORY)
  const formBrand = getIn(values, fieldNames.TERMINAL_MANUFACTURER)
  const formTerminal = getIn(values, fieldNames.TERMINAL)
  const formTerminalId = getIn(values, fieldNames.TERMINAL_ID)

  const noAppleTvAvailableForSelectedTariff =
    isAppleTv && !isListLoading && !listError && !formTerminalId

  const terminalPrice =
    formTerminal?.paymentType === 'upfront'
      ? `${formTerminal?.details?.initialPayment} € (Pago Único)`
      : `${formTerminal?.details?.monthlyPayment} €/mes ${(!!formTerminal?.details
          ?.initialPayment &&
          `+ ${formTerminal?.details?.initialPayment} € (Pago Único)`) ||
          ''}`

  const terminalCommission = (terminalDetails?.commissionFee && terminalDetails.commissionFee) || 0

  const terminalPenalty = terminalDetails.permanency || 0

  const categories = useMemo(() => {
    const uniqueCategories = uniq(
      (terminalModels?.length > 0 && terminalModels.map(item => item.category)) || [],
    ).map(category => ({ label: getCategoryLabel(category), key: category, name: category }))

    return uniqueCategories
  }, [terminalModels])

  const category = useMemo(
    () => categories?.find(terminalCategory => terminalCategory?.key === formCategory),
    [formCategory, categories],
  )

  const brands = useMemo(() => {
    const uniqueBrands = uniq(
      (terminalModels?.length > 0 &&
        terminalModels
          .filter(option => !category || option.category === category?.key)
          .map(item => item.brand)) ||
        [],
    ).map(brand => ({ label: brand, key: brand, name: brand }))
    return uniqueBrands
  }, [category, terminalModels])

  const brand = useMemo(() => brands?.find(terminalBrand => terminalBrand?.label === formBrand), [
    formBrand,
    brands,
  ])

  const filteredTerminalOptions = useMemo(
    () =>
      terminalModels?.filter(
        option =>
          (!category || option.category === category.key) &&
          (!brand || option.brand === brand.label),
      ),
    [category, brand, terminalModels],
  )

  function onSelectTerminal(e, selected) {
    onModelChange(selected)
  }

  function onSelectCategory(e, newVal) {
    onCategoryChange(newVal)
  }

  function onSelectBrand(e, newVal) {
    onBrandChange(newVal)
  }

  return (
    <div>
      {isListLoading && <CircularProgress className="m-t-12" />}
      {!isListLoading && listError && (
        <div className="flex">
          <FormHelperText
            error
            name={fieldNames.TERMINAL_MANUFACTURER}
            data-hook="change-tariff-error">
            Ha sucedido un error, vuelve a intentarlo.
          </FormHelperText>
          <ButtonLink
            data-hook="add-bundles"
            className="m-l-16"
            type="button"
            onClick={fetchTerminals}>
            Volver a intentar
          </ButtonLink>
        </div>
      )}
      {noAppleTvAvailableForSelectedTariff && (
        <FormHelperText error data-hook="no-appleTV-tariff-error">
          No hay Apple TV para esta tarifa.
        </FormHelperText>
      )}
      {!isAppleTv && !isListLoading && (
        <>
          {!isListLoading && !listError && !brands.length && (
            <>
              <Typography
                {...TextStyles.subtitle1Dark({
                  className: 'm-t-12',
                })}
                data-hook="no-terminals-error">
                No hay dispositivos disponibles para esta tarifa.{' '}
              </Typography>
              {!isStockCheck && (
                <FormHelperText
                  error
                  name={fieldNames.TERMINAL_MANUFACTURER}
                  data-hook="change-tariff-error">
                  La tarifa seleccionada no dispone de dispositivos. Seleccione que &quot;No&quot;
                  desea dispositivo para esta línea.
                </FormHelperText>
              )}
            </>
          )}
          {!!brands.length && !isListLoading && !disableOfferEdit && !listError && (
            <div className="flex flex-wrap m-b-8">
              {categories?.length > 1 && (
                <div className="p-r-16">
                  <Field name={fieldNames.TERMINAL_CATEGORY}>
                    {({ field, form }) => (
                      <Autocomplete
                        options={categories}
                        getOptionLabel={option => option.label}
                        style={{ width: 300 }}
                        onChange={onSelectCategory}
                        value={{
                          label: getCategoryLabel(field.value),
                          key: field.value,
                          name: field.value,
                        }}
                        data-hook={field.name}
                        autoHighlight
                        renderInput={params => (
                          <ComposedTextField
                            className={styles.input}
                            variant="outlined"
                            label="Tipo"
                            touched={getIn(form.touched, field.name)}
                            error={getIn(form.errors, field.name)}
                            {...params}
                          />
                        )}
                      />
                    )}
                  </Field>
                </div>
              )}
              <div className="p-r-16">
                <Field name={fieldNames.TERMINAL_MANUFACTURER}>
                  {({ field, form }) => (
                    <Autocomplete
                      options={brands}
                      getOptionLabel={option => option.label}
                      style={{ width: 300 }}
                      onChange={onSelectBrand}
                      value={{
                        label: field.value,
                        key: field.value,
                        name: field.value,
                      }}
                      data-hook={field.name}
                      autoHighlight
                      renderInput={params => (
                        <ComposedTextField
                          className={styles.input}
                          variant="outlined"
                          label="Marca"
                          touched={getIn(form.touched, field.name)}
                          error={getIn(form.errors, field.name)}
                          {...params}
                        />
                      )}
                    />
                  )}
                </Field>
              </div>
              <div>
                <Field name={fieldNames.TERMINAL_ID}>
                  {({ field, form }) => (
                    <Autocomplete
                      onChange={onSelectTerminal}
                      options={filteredTerminalOptions}
                      getOptionLabel={option => option.label}
                      data-hook={field.name}
                      style={{ width: 300 }}
                      autoHighlight
                      value={
                        filteredTerminalOptions.find(opt => opt.terminalId === field.value) || {
                          label: '',
                        }
                      }
                      renderInput={params => (
                        <ComposedTextField
                          className={styles.input}
                          variant="outlined"
                          label="Modelo"
                          touched={getIn(form.touched, field.name)}
                          error={getIn(form.errors, field.name)}
                          {...params}
                        />
                      )}
                    />
                  )}
                </Field>
              </div>
              {isStockCheck && (
                <TerminalStockComponent
                  stock={terminalDetails.stock}
                  isStockLoading={terminalDetails.isLoading}
                  terminalId={terminalDetails.id}
                />
              )}
            </div>
          )}
        </>
      )}
      {disableOfferEdit && (
        <>
          <Typography
            {...TextStyles.subtitle1Dark({
              className: 'p-t-12',
            })}>
            Datos de terminal:
          </Typography>

          <Box display="flex" flexWrap="wrap" pb={1} pt={1}>
            <Box display="flex" pr={1}>
              <Typography {...TextStyles.subtitle2Dark()}>
                {formTerminal?.details?.name}:
              </Typography>
              <Typography
                {...TextStyles.paragraphDark({
                  className: 'p-x-8',
                })}>
                {' '}
                {terminalPrice}
              </Typography>
            </Box>

            {(terminalCommission && (
              <Box display="flex" px={1}>
                <Typography
                  {...TextStyles.subtitle2Dark({
                    className: 'p-x-8',
                  })}>
                  Comisión de apertura:
                </Typography>
                <Typography {...TextStyles.paragraphDark()}>{terminalCommission} €</Typography>
              </Box>
            )) ||
              ''}
            {(terminalPenalty && (
              <Box display="flex" px={1}>
                <Typography
                  {...TextStyles.subtitle2Dark({
                    className: 'p-x-8',
                  })}>
                  Penalización:
                </Typography>
                <Typography {...TextStyles.paragraphDark()}>{terminalPenalty} €</Typography>
              </Box>
            )) ||
              ''}
          </Box>
        </>
      )}
    </div>
  )
}

TerminalSelector.propTypes = {
  onCategoryChange: PropTypes.func,
  onBrandChange: PropTypes.func.isRequired,
  onModelChange: PropTypes.func.isRequired,
  isStockCheck: PropTypes.bool,
  isListLoading: PropTypes.bool,
  isAppleTv: PropTypes.bool,
  listError: PropTypes.bool,
  fieldNames: PropTypes.object,
  terminalModels: PropTypes.array,
  terminalDetails: PropTypes.object,
  disableOfferEdit: PropTypes.bool,
  fetchTerminals: PropTypes.func,
}
