import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormikContext } from 'formik'

import { landlineRegex, mobileNumberRegex } from 'utils'

import { fetchOperatorDonor, checkFixedOperatorDonor } from 'modules/Lines'
import { selectIsElFijoSaleType } from 'modules/Router/store/index'
import { LINE_TYPE } from 'modules/Lines/constants'
import { selectTechnology, selectTerritoryOwner, selectGescal } from 'modules/Coverage/store/index'
import { FTTH, ADSL } from 'modules/Coverage/constants/technologies'
import { selectSfid } from 'modules/Auth'
import { selectInternalPortabilityFlag } from 'services/feature-flag/selectors'

import {
  OPERATOR_NOT_FOUND,
  OPERATOR_IS_MASMOVIL_BRAND,
} from 'modules/SharedSales/constants/validations'

import { setRequestLoading } from '../../store'

const NOT_DEFINED_OPERATOR = 'No definido'
const MASMOVIL_OPERATOR_CODE = '00031'

export function useOperatorValidation(lineType) {
  const { setFieldValue } = useFormikContext()
  const internalPortabilityFlag = useSelector(selectInternalPortabilityFlag)

  const [prevState, setPrevState] = useState()
  const [hasMsOperatorError, setMsError] = useState()
  const isElFijo = useSelector(selectIsElFijoSaleType)
  const isFixedLine = lineType === LINE_TYPE.LANDLINE

  const territoryOwner = useSelector(selectTerritoryOwner)
  const technology = useSelector(selectTechnology)
  const technicalId = useSelector(selectGescal)
  const sfid = useSelector(selectSfid)

  const dispatch = useDispatch()

  function setAndReturnState(state, isMsError) {
    setMsError(isMsError)
    setPrevState(state)
    return state
  }

  async function validate(value) {
    if (
      (mobileNumberRegex.test(value) || landlineRegex.test(value)) &&
      (!isFixedLine || !!technicalId)
    ) {
      try {
        dispatch(setRequestLoading({ loading: true }))

        let operatorData = {}

        if (isFixedLine && internalPortabilityFlag) {
          const {
            operator,
            internalPortability,
            isPortable,
            installationRequired,
          } = await checkFixedOperatorDonor(value, {
            territoryOwner: territoryOwner?.value,
            technology: technology === ADSL ? technology : FTTH,
            sfid,
            technicalId,
          })

          operatorData = {
            id: operator.code,
            tradingName: operator.name,
            internalPortability,
            isPortable,
            installationRequired,
          }
        } else {
          operatorData = await fetchOperatorDonor(value)
        }

        dispatch(setRequestLoading({ loading: false }))

        if (!operatorData.tradingName || operatorData.tradingName === NOT_DEFINED_OPERATOR) {
          return setAndReturnState(OPERATOR_NOT_FOUND, true)
        }

        if (
          (isFixedLine && operatorData.internalPortability && !operatorData.isPortable) ||
          ((isElFijo || !internalPortabilityFlag) && operatorData.id === MASMOVIL_OPERATOR_CODE)
        ) {
          return setAndReturnState(OPERATOR_IS_MASMOVIL_BRAND, true)
        }

        setFieldValue(`${lineType}.operator`, operatorData)
        return setAndReturnState(undefined)
      } catch (e) {
        dispatch(setRequestLoading({ loading: false }))
        return setAndReturnState(OPERATOR_NOT_FOUND, true)
      }
    }
    return setAndReturnState(undefined)
  }

  async function validateOperator(value, regex, fromSignUp) {
    const trimmedValue = value?.trim()
    if (!fromSignUp) return prevState
    if (regex.test(trimmedValue)) {
      setMsError(false)
      const validation = await validate(trimmedValue)
      return validation
    }
    return setAndReturnState(undefined)
  }

  return { validateOperator, hasMsOperatorError, errorOperator: prevState }
}
