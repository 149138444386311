import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { Modal, ModalActions, Button } from 'components/ui'

import { ModalContent } from 'modules/offers-configuration/components/ModalContent'
import MultiStep from 'modules/offers-configuration/components/MultiStep'
import {
  selectDecisionAllowed,
  selectChangeTechnologyData,
  selectChangeTechnologyError,
  selectChangeTechnologyIsLoading,
  selectInstaller,
  selectConnectionType,
} from 'modules/offers-configuration/selectors'
import { initChangeTechnology } from 'modules/offers-configuration/actions'
import { CHANGE_TECHNOLOGY, TECHNOLOGY_CHANGE } from 'modules/offers-configuration/constants'
import { ChangesHeader } from 'modules/offers-configuration/components/ChangesHeader/ChangesHeader'
import { useTariffOptions } from 'modules/offers-configuration/hooks/useTariffOptions'
import { useChangeTechnologyOptions } from 'modules/offers-configuration/hooks/useChangeTechnologyOptions'

export const ChangeTechnologyModal = ({
  show,
  currentTariff,
  currentAddress,
  tariffSegment,
  subscription,
  subscriptionId,
  coverageTerritoryOwner,
  coverageAddressId,
  taxNeeded,
  isIndirectFiber,
  onClose,
  onError,
}) => {
  const dispatch = useDispatch()

  const [selectedTariffValue, setSelectedTariffValue] = useState('')
  const [selectedPsIdTariff, setSelectedPsIdTariff] = useState(null)
  const [selectedPoIdTariff, setSelectedPoIdTariff] = useState(null)

  const onTariffSelect = (e, psId, poId) => {
    setSelectedTariffValue(e.target.value)
    setSelectedPsIdTariff(psId)
    setSelectedPoIdTariff(poId)
  }

  const { filteredEnrichTariffs } = useTariffOptions(subscription, null, false, false)
  const isLoading = useSelector(selectChangeTechnologyIsLoading)
  const error = useSelector(selectChangeTechnologyError)
  const changeTechnologyResponse = useSelector(selectChangeTechnologyData)
  const decisionAllowed = useSelector(selectDecisionAllowed)
  const installer = useSelector(selectInstaller)
  const connectionType = useSelector(selectConnectionType)

  const options = useChangeTechnologyOptions(
    filteredEnrichTariffs,
    coverageTerritoryOwner,
    subscriptionId,
    selectedPoIdTariff,
    selectedTariffValue,
    TECHNOLOGY_CHANGE,
    {
      coverageAddressId,
      changeType: CHANGE_TECHNOLOGY,
      installer,
      connectionType,
      isIndirectFiber,
    },
  )

  useEffect(() => {
    if (changeTechnologyResponse || error) {
      onClose()
    }

    if (show && error) {
      onError('Se ha producido un error', 'No se ha podido realizar el cambio de tecnología')
    }
  }, [error, changeTechnologyResponse])

  return (
    <Modal
      isOpen={show}
      onClose={!isLoading ? () => onClose(true) : null}
      title="Cambio de tecnología"
      overFlowY="auto">
      <ModalContent data-hook="modal-change-technology">
        <MultiStep
          initialStep="tariffs"
          steps={{
            tariffs: () => (
              <>
                <ChangesHeader
                  currentAddress={currentAddress}
                  commercialName={get(currentTariff, 'commercial_name')}
                  filteredEnrichTariffs={filteredEnrichTariffs}
                  taxNeeded={taxNeeded}
                  subscription={subscription}
                  onTariffSelect={onTariffSelect}
                  selectedPsIdTariff={selectedPsIdTariff}
                  tariffSegment={tariffSegment}
                  subscriptionId={subscriptionId}
                  territoryOwner={coverageTerritoryOwner}
                />
                <ModalActions>
                  <Button
                    type="submit"
                    onClick={() => {
                      dispatch(initChangeTechnology(options))
                    }}
                    disabled={!decisionAllowed || isLoading}>
                    Confirmar
                  </Button>
                  <Button secondary onClick={() => onClose(true)}>
                    Cancelar
                  </Button>
                </ModalActions>
              </>
            ),
          }}
        />
      </ModalContent>
    </Modal>
  )
}

ChangeTechnologyModal.propTypes = {
  show: PropTypes.bool,
  currentTariff: PropTypes.object,
  currentAddress: PropTypes.string,
  tariffSegment: PropTypes.string,
  subscription: PropTypes.object,
  subscriptionId: PropTypes.number,
  taxNeeded: PropTypes.bool,
  isIndirectFiber: PropTypes.bool,
  onClose: PropTypes.func,
  onError: PropTypes.func,
  coverageTerritoryOwner: PropTypes.string,
  coverageAddressId: PropTypes.string,
}
