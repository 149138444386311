import { SIGNUP_TYPE_NEWLINE } from 'services/global-constants'

const hasMobileTariff = matches => Boolean(matches) && matches.length === 2
const mobileTariffIndex = 1

export const getMobileTariff = selectedOffer => {
  const commercialNameRegex = /.*\+ (.*)/g
  const matches = commercialNameRegex.exec(selectedOffer?.commercialName || selectedOffer?.name)

  return hasMobileTariff(matches) ? matches[mobileTariffIndex] : selectedOffer?.name || ''
}

export const getMobileNumberType = (signUpType, portabilityType, isElFijo) => {
  if (signUpType === SIGNUP_TYPE_NEWLINE) {
    return isElFijo ? 'fixed_new' : 'mobile_new'
  }
  if (portabilityType === 'prepaid') return 'mobile_portability_prepaid'
  return isElFijo ? 'fixed_portability' : 'mobile_portability_postpaid'
}
