import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

const Popover = styled.div`
  position: relative;
  padding: 24px;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  ${({ hasArrow }) =>
    hasArrow &&
    css`
      margin-top: 15px;
      &:after {
        content: '';
        position: absolute;
        top: 0px;
        left: ${props => props.arrowLeft || '5px'};
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid white;
        border-top: 10px solid white;
        border-bottom: 10px solid transparent;
        transform: rotate(-45deg);
        transform-origin: top left;
        box-shadow: 2px -2px 2px 0px rgba(0, 0, 0, 0.05);
      }
    `}
`

Popover.propTypes = {
  arrowLeft: PropTypes.string,
  hasArrow: PropTypes.bool,
}

export default Popover
