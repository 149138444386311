import { useState } from 'react'
import { openBlob, buildBlob } from 'utils'
import { logError, formatErrorMessage } from 'services/logging'

export const useDownloadFile = () => {
  const [state, setState] = useState({
    isLoading: false,
    error: null,
  })

  function downloadFromPromiseBlob(promiseBlob, extension = 'pdf') {
    setState({
      isLoading: true,
      error: null,
    })

    promiseBlob
      .then(blob => {
        const blobBuilt = buildBlob([blob], extension)
        return openBlob(blobBuilt)
      })
      .then(() => {
        setState({
          isLoading: false,
          error: null,
        })
      })
      .catch(e => {
        logError({ e: new Error(formatErrorMessage(e)), promiseBlob, extension })
        setState({
          isLoading: false,
          error: e,
        })
      })
  }

  return [state, downloadFromPromiseBlob]
}
