import PropTypes from 'prop-types'
import { get } from 'lodash'

import { useFormikContext } from 'formik'

import { Typography, Grid } from '@material-ui/core'

import { FormikInput, ButtonLink } from 'components/ui'
import { formatCensuredNif } from 'modules/vista-360/helpers/formats.helper'

export function Company({
  validateIfClientIsRegistered,
  isDateDisabled,
  isCartera,
  isCrossSell,
  isMultiSim,
  showRetry,
  isPosOrMasProximo,
}) {
  const { values } = useFormikContext()

  const companyCif = get(values, 'account.companyCif')

  return (
    <>
      <Typography variant="subtitle1" color="secondary" className="bold m-b-8 m-t-32">
        DATOS DE LA EMPRESA
      </Typography>

      <Grid container spacing={2} style={{ marginBottom: '20px' }}>
        <Grid item sm={4}>
          <FormikInput
            name="account.companyName"
            label="Nombre de la empresa"
            disabled={{
              misses: 'account.segment',
              error: 'account.documentid.value',
              bool: isCartera || isCrossSell || isMultiSim,
            }}
          />
        </Grid>
        <Grid item sm={4} style={{ maxWidth: '400px' }}>
          <FormikInput
            maxLength="9"
            name="account.companyCif"
            label="CIF"
            disabled={{
              misses: 'account.segment',
              error: 'account.documentid.value',
              bool: isCartera || isCrossSell || isMultiSim,
            }}
            onChange={value => {
              validateIfClientIsRegistered({ value, docType: 'CIF', isMainDocument: true })
            }}
            style={{ width: '100%' }}
            uppercase
            censuredHelper={
              (isCartera || isMultiSim || isCrossSell) && isPosOrMasProximo
                ? formatCensuredNif
                : null
            }
          />
          {showRetry && (
            <ButtonLink
              className="m-l-12"
              style={{ display: 'block' }}
              onClick={() =>
                validateIfClientIsRegistered({
                  value: companyCif,
                  docType: 'CIF',
                  isMainDocument: true,
                })
              }>
              Reintentar
            </ButtonLink>
          )}
        </Grid>
        <Grid item sm={4}>
          <FormikInput
            date
            name="account.constitutionDate"
            disabled={
              isDateDisabled
                ? { bool: true }
                : { misses: 'account.segment', error: 'account.documentid.value' }
            }
            label="Fecha constitución"
          />
        </Grid>
      </Grid>
    </>
  )
}

Company.propTypes = {
  validateIfClientIsRegistered: PropTypes.func.isRequired,
  isDateDisabled: PropTypes.bool,
  isCartera: PropTypes.bool,
  isCrossSell: PropTypes.bool,
  isMultiSim: PropTypes.bool,
  showRetry: PropTypes.bool,
  isPosOrMasProximo: PropTypes.bool,
}
