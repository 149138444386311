export const BILLING_ADDRESS = {
  label: 'La de facturación',
  value: 'billingAddress',
}
export const INSTALLATION_ADDRESS = {
  label: 'La de instalación',
  value: 'installationAddress',
}
export const OTHER_ADDRESS = {
  label: 'Otra dirección',
  value: 'otherAddress',
}
export const DELIVERY_POINT = {
  label: 'Punto de entrega',
  value: 'deliveryPoint',
}
export const YOIGO_STORE = {
  label: 'Tienda Yoigo (Recomendado)',
  value: 'yoigoStore',
}

export const SERVICE_ADDRESS = {
  label: 'La de servicio',
  value: 'serviceAddress',
}
