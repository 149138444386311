import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { set } from 'lodash'
import { useFormikContext } from 'formik'

import { FormikInput } from 'components/ui'

import { Box, Typography } from '@material-ui/core'

import { SIGNUP_TYPE_NEWLINE } from 'services/global-constants/index'

export function LineSignupType({
  fieldNames,
  lineOptions,
  onSignUpTypeChange,
  children,
  disableOfferEdit,
  signUpType,
}) {
  const { setValues, values } = useFormikContext()

  useEffect(() => {
    if (!disableOfferEdit && lineOptions?.length === 1) {
      onSignUpTypeChange(lineOptions[0].value)

      set(values, fieldNames.MOBILE_LINE_TYPE, lineOptions[0].value)
      setValues(values)
    }
  }, [lineOptions?.length])

  return (
    <Box display="flex" flexWrap="wrap" pb="12px" alignItems="start">
      <Box
        display="flex"
        alignItems="center"
        alignSelf={disableOfferEdit && signUpType !== SIGNUP_TYPE_NEWLINE && 'center'}
        height={!disableOfferEdit ? '88px' : '50px'}
        pr="36px">
        <Typography variant="body2" component="h4">
          Datos de línea
        </Typography>
      </Box>

      {!disableOfferEdit ? (
        <Box display="flex" pr="12px" mr="24px">
          <FormikInput
            disabled={{ misses: fieldNames.TARIFF_ID }}
            name={fieldNames.MOBILE_LINE_TYPE}
            dataHook={fieldNames.MOBILE_LINE_TYPE}
            select
            fullWidth
            label="Tipo de alta"
            options={lineOptions}
            onChange={onSignUpTypeChange}
            style={{ minWidth: '200px' }}
          />
        </Box>
      ) : (
        <Box alignSelf="center" mr="24px">
          <Typography variant="body1" className="bold" style={{ textTransform: 'uppercase' }}>
            {signUpType === SIGNUP_TYPE_NEWLINE ? 'Alta nueva' : 'Portabilidad'}
          </Typography>
        </Box>
      )}

      {children}
    </Box>
  )
}

LineSignupType.propTypes = {
  fieldNames: PropTypes.object,
  lineOptions: PropTypes.array,
  onSignUpTypeChange: PropTypes.func,
  children: PropTypes.any,
  signUpType: PropTypes.string,
  disableOfferEdit: PropTypes.bool,
}
