const FLAGS_INIT = 'OVID/FLAGS/FLAGS_INIT'
const FLAGS_SUCCESS = 'OVID/FLAGS/FLAGS_SUCCESS'
const FLAGS_FAILED = 'OVID/FLAGS/FLAGS_FAILED'

export const FLAG_ENVIRONMENT = {
  STA: 'STA',
  PROD: 'PRO',
}

export const FLAG_TYPE = {
  BOOLEAN: 'boolean',
  SFID_REGEXP: 'sfid_regex',
}

export const FLAG_STATUS = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
}

export const constants = { FLAGS_INIT, FLAGS_SUCCESS, FLAGS_FAILED }
