import PropTypes from 'prop-types'

import { Box } from '@material-ui/core'

import LineItemCard from './LineItemCard'

const DoubleLineItemCard = ({ primaryText, secondaryText, listActions, ...rest }) => (
  <Box display="flex" flexDirection="column" width="100%" {...rest}>
    <LineItemCard primaryText={primaryText} listActions={listActions} padding="0" />
    <LineItemCard secondaryText={secondaryText} padding="0" />
  </Box>
)

DoubleLineItemCard.defaultProps = {
  padding: '9px 24px',
}

DoubleLineItemCard.propTypes = {
  primaryText: PropTypes.string,
  secondaryText: PropTypes.string,
  listActions: PropTypes.array,
  padding: PropTypes.string,
}

export default DoubleLineItemCard
