import { useSelector } from 'react-redux'
import {
  isConvergentSubscription,
  getMobileLineNumber,
  getFixedLineNumber,
  selectPortOutInfo,
  isPortOutInfoLoading,
} from 'services/subscriptions'
import { selectIsLoadingByProductId } from 'services/product-orders'
import {
  isReadyForBilling,
  isActiveSubscription,
  is2pWithMobileLineUnsubscribed,
} from '../../helpers'
import {
  getSubscriptionMostRecentOrder,
  getPendingUnsubscription,
  getIsFixedBeingUnsubscribed,
} from '../../selectors'

export function useSubscriptionStatus(subscription) {
  const mobileNumber = getMobileLineNumber(subscription)
  const fixedNumber = getFixedLineNumber(subscription)

  const mostRecentOrder = useSelector(state =>
    getSubscriptionMostRecentOrder(state, subscription.id),
  )
  const unsubscribed = useSelector(state => getPendingUnsubscription(state, subscription.id))
  const isFixedUnsubscribing = useSelector(getIsFixedBeingUnsubscribed(subscription))

  const mobilePortOut = useSelector(selectPortOutInfo(mobileNumber))
  const fixedPortOut = useSelector(selectPortOutInfo(fixedNumber))

  const hasPortOut = Boolean(mobilePortOut || fixedPortOut)

  const isPortOutLoading = useSelector(
    state => isPortOutInfoLoading(state, fixedNumber) || isPortOutInfoLoading(state, mobileNumber),
  )

  const areOrdersLoading = useSelector(state => selectIsLoadingByProductId(state, subscription.id))

  let allStatus = {
    status: '',
    mobileStatus: {
      current: '',
      modified: null,
    },
    fixedStatus: {
      current: '',
      modified: null,
    },
  }

  if (isPortOutLoading || areOrdersLoading) return allStatus

  allStatus.status = {
    status: 'fly',
    mobileStatus: {
      current: 'fly',
      modified: null,
    },
    fixedStatus: {
      current: 'fly',
      modified: null,
    },
  }

  if (!isConvergentSubscription(subscription) || isReadyForBilling(mostRecentOrder)) {
    if (isActiveSubscription(subscription))
      allStatus = {
        status: 'active',
        mobileStatus: {
          current: 'active',
          modified: null,
        },
        fixedStatus: {
          current: 'active',
          modified: null,
        },
      }
  }
  if (is2pWithMobileLineUnsubscribed(subscription)) allStatus.status = 'active'
  if (unsubscribed) allStatus.status = 'unsubscribe'
  else if (hasPortOut) allStatus.status = 'port'

  if (isFixedUnsubscribing) allStatus.fixedStatus.modified = 'unsubscribe'
  if (mobilePortOut) allStatus.mobileStatus.current = 'port'
  if (fixedPortOut) allStatus.fixedStatus.current = 'port'

  return allStatus
}
