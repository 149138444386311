import { createSelector } from 'reselect'
import { get } from 'lodash'

const selectSecurizerTypifications = state => get(state, 'securizerTypifications')

export const selectSecurizerTokenTypifications = createSelector(
    selectSecurizerTypifications,
    securizer => get(securizer, 'data.token')
)

export const selectIsOpenTypificationIframe = createSelector(
    selectSecurizerTypifications,
    securizer => get(securizer, 'open'),
  )

  export const selectIsErrorSecurizerIframe = createSelector(
    selectSecurizerTypifications,
    securizer => get(securizer, 'error'),
  )