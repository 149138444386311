import { get } from 'lodash/fp'

import { constants } from './constants'

const initialState = {
  subscriptions: null,
  loading: false,
  error: null,
}

export const reducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case constants.FIND_ALL_SUBSCRIPTIONS: {
      return {
        ...state,
        loading: true,
      }
    }
    case constants.FIND_ALL_SUBSCRIPTIONS_SUCCESS: {
      return {
        ...state,
        subscriptions: get('subscriptions.data', payload),
        loading: false,
      }
    }
    case constants.FIND_ALL_SUBSCRIPTIONS_FAILED: {
      return {
        ...state,
        error: get('error', payload) || payload,
        loading: false,
      }
    }
    default:
      return state
  }
}
