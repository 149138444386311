import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/styles'
import { Card, CardContent, Box, Typography, Divider, Grid } from '@material-ui/core'

import { SectionHeader } from 'modules/ui'
import { SpinnerCenter, ActionAdvice } from 'components/ui'

import { formatDate } from 'utils/formatting/dates'

import { TextStyles } from 'utils/index'
import { PRODUCT_TYPE_LABELS } from '../../../contants/product-types'
import { PRODUCT_STATUS_LABELS } from '../../../contants/product-status'

const useStyles = makeStyles({
  'energy-contracts-not-found': {
    textAlign: 'center',

    '& h4': {
      marginBottom: '20px',
      padding: '10px 0',
    },

    '& .not-found-light': {
      marginTop: '64px',
      marginBottom: '48px',
    },
  },
})

const ContractInformationSection = ({ label, value }) => (
  <Box pb="12px">
    <Typography {...TextStyles.dataDark()}>
      <Typography
        {...TextStyles.labelDark({
          component: 'span',
          className: 'p-r-4',
        })}>
        {label}:
      </Typography>

      {value}
    </Typography>
  </Box>
)

ContractInformationSection.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
}

export const ClientEnergy = ({ menuItems, isLoading, error, contracts, onRetry }) => {
  const classes = useStyles()

  return (
    <>
      <SectionHeader title="Contratos de energía" actions={menuItems} />

      <Box p="24px">
        {isLoading && <SpinnerCenter />}

        {!isLoading &&
          contracts?.map(contract => (
            <Card style={{ width: '100%' }} key={contract.creationDate.toString()}>
              <Box p="20px 24px 18px" display="flex" alignItems="center">
                <Typography {...TextStyles.title3Dark()}>Contrato de energía</Typography>
              </Box>

              <Divider />

              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <ContractInformationSection
                      label="Producto"
                      value={PRODUCT_TYPE_LABELS[contract.productType] || contract.productType}
                    />

                    <ContractInformationSection
                      label="Estado"
                      value={PRODUCT_STATUS_LABELS[contract.state] || contract.state}
                    />

                    <ContractInformationSection label="CUPS" value={contract.supplyPointCode} />
                  </Grid>

                  <Grid item xs={1}>
                    <Divider orientation="vertical" />
                  </Grid>

                  <Grid item xs={4}>
                    <ContractInformationSection
                      label="Fecha de contratación"
                      value={formatDate(contract.creationDate)}
                    />

                    <ContractInformationSection
                      label="Fecha de activación"
                      value={formatDate(contract.activationDate)}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          ))}

        {!isLoading && contracts?.length === 0 && (
          <Card style={{ width: '100%' }} className={classes['energy-contracts-not-found']}>
            <CardContent>
              <Box display="flex" flexDirection="column" alignItems="center">
                <img
                  alt="contracts not found"
                  src="/assets/not_energy_contracts.svg"
                  className="not-found-light"
                />

                <Typography variant="h4" color="primary">
                  Este cliente no tiene contratos de luz
                </Typography>
              </Box>
            </CardContent>
          </Card>
        )}

        {!isLoading && error && (
          <ActionAdvice
            type="error"
            message="Ha ocurrido un error cargando los contratos. Por favor inténtelo más tarde."
            onRetry={onRetry}
          />
        )}
      </Box>
    </>
  )
}

ClientEnergy.propTypes = {
  menuItems: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  contracts: PropTypes.array,
  onRetry: PropTypes.func.isRequired,
}
