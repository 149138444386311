import { useState } from 'react'
import { useSelector } from 'react-redux'
import { selectTaskId } from 'modules/NewClientSales/store/selectors/orders.selectors'
import { fetchTXTContract } from '../../services/txtContract.service'

export function useDownloadTXTContract() {
  const [error, setError] = useState(false)
  const taskId = useSelector(selectTaskId)

  function downloadTXTContract() {
    setError(false)
    fetchTXTContract(taskId)
      .then(() => setError(false))
      .catch(() => setError(true))
  }

  return { downloadTXTContract, txtError: error }
}
