import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'

import { Box, Card, CardContent } from '@material-ui/core'

import { TitleSidebar, TextSidebar } from 'components/ui'
import { formatFee } from 'utils'

export const DeviceInfo = ({ device }) => {
  const paymentType = useMemo(
    () => device?.paymentMethods && Object.keys(device.paymentMethods)[0],
    [device.paymentMethods],
  )
  const paymentInfo = useMemo(() => (paymentType ? device.paymentMethods[paymentType] : null), [
    device.paymentMethods,
    paymentType,
  ])

  return (
    <Box
      display="flex"
      pt="20px"
      justifyContent="center"
      data-hook="device-renewal-sidebar-device-info">
      <Card style={{ width: '90%', borderRadius: '10px', margin: '20px 0 8px 0' }}>
        <CardContent>
          <div>
            <TitleSidebar>Dispositivo nuevo</TitleSidebar>
            <TextSidebar dataHook="renewal-sidebar-device-name">{device.name || '-'}</TextSidebar>
          </div>
          {paymentType && (
            <div>
              <TitleSidebar>Tipo de pago</TitleSidebar>
              <TextSidebar dataHook="renewal-sidebar-payment-type">
                {paymentType === 'upfront' ? 'Único' : 'A plazos'}
              </TextSidebar>
            </div>
          )}
          {paymentType === 'upfront' && (
            <div>
              <TitleSidebar>Precio</TitleSidebar>
              <TextSidebar dataHook="renewal-sidebar-payment">
                {formatFee(get(paymentInfo, 'initialPayment.taxIncludedAmount', 0))}
              </TextSidebar>
            </div>
          )}
          {paymentInfo && paymentType !== 'upfront' && paymentInfo.initialPayment && (
            <div>
              <TitleSidebar>Pago inicial</TitleSidebar>
              <TextSidebar dataHook="renewal-sidebar-initial-payment">
                {formatFee(get(paymentInfo, 'initialPayment.taxIncludedAmount', 0))}
              </TextSidebar>
            </div>
          )}
          {paymentInfo && paymentType !== 'upfront' && (
            <div>
              <TitleSidebar>Precio mes ({get(paymentInfo, `fees`, 0)})</TitleSidebar>
              <TextSidebar dataHook="renewal-sidebar-monthly-payment">
                {formatFee(get(paymentInfo, `monthlyPayment.taxFreeAmount`, 0))}/mes
              </TextSidebar>
            </div>
          )}
          {paymentInfo && paymentType !== 'upfront' && paymentInfo.finalPayment && (
            <div>
              <TitleSidebar>Pago final ({get(paymentInfo, `fees`, 0)})</TitleSidebar>
              <TextSidebar dataHook="renewal-sidebar-final-payment">
                {formatFee(get(paymentInfo, 'finalPayment.taxIncludedAmount', 0))}
              </TextSidebar>
            </div>
          )}
        </CardContent>
      </Card>
    </Box>
  )
}

DeviceInfo.propTypes = {
  device: PropTypes.object,
}
