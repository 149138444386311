import PropTypes from 'prop-types'

import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import { TerminalQuestion } from '../TerminalQuestionComponent'
import { TerminalsFilter } from '../TerminalsFilterComponent'

const useStyles = makeStyles(theme => ({
  'terminal-selection': {
    background: theme.palette.global.gray_light,
    marginLeft: '-24px',
    width: 'calc(100% + 48px)',
    maxWidth: 'inherit',
  },
}))
export function TerminalSelection({
  onCategoryChange,
  onBrandChange,
  onModelChange,
  onPaymentTypeChange,
  onValidIMEI,
  reservationError,
  paymentType,
  professionOptions,
  onSelectProfession,
  shouldShowCompany,
  signUpType,
  portaPaymentType,
  onAddTerminalChange,
  isNewTerminal,
  fieldNames,
  terminalModels,
  terminalDetails,
  isListLoading,
  listError,
  isCompany,
  isFinancialsLoading,
  isElFijo,
  isPosSale,
  className,
  disableOfferEdit,
  isMobileOnly,
  isLeadFilled,
  shouldDisableTerminal,
  fetchTerminals,
  isAppleTv,
}) {
  const classes = useStyles()

  return (
    <div className={(!isMobileOnly || disableOfferEdit || isNewTerminal) && 'full-width'}>
      {!disableOfferEdit && (
        <Grid item xs={12} className="p-b-16">
          <TerminalQuestion
            isElFijo={isElFijo}
            fieldNames={fieldNames}
            onAddTerminalChange={onAddTerminalChange}
            portaPaymentType={portaPaymentType}
            signUpType={signUpType}
            shouldDisableTerminal={shouldDisableTerminal}
            isAppleTv={isAppleTv}
          />
        </Grid>
      )}

      {(isNewTerminal || isAppleTv) && (
        <Grid
          xs={12}
          item
          className={`${[classes['terminal-selection'], ...(className ? [className] : [])].join(
            ' ',
          )} p-x-24 p-t-12 p-b-24`}>
          <TerminalsFilter
            isFinancialsLoading={isFinancialsLoading}
            paymentType={paymentType}
            fieldNames={fieldNames}
            terminalModels={terminalModels}
            onModelChange={onModelChange}
            onCategoryChange={onCategoryChange}
            onBrandChange={onBrandChange}
            terminalDetails={terminalDetails}
            isListLoading={isListLoading}
            listError={listError}
            onPaymentTypeChange={onPaymentTypeChange}
            shouldShowCompany={shouldShowCompany}
            professionOptions={professionOptions}
            onSelectProfession={onSelectProfession}
            reservationError={reservationError}
            onValidIMEI={onValidIMEI}
            isCompany={isCompany}
            isPosSale={isPosSale}
            disableOfferEdit={disableOfferEdit}
            isMobileOnly={isMobileOnly}
            isLeadFilled={isLeadFilled}
            fetchTerminals={fetchTerminals}
            isAppleTv={isAppleTv}
            signUpType={signUpType}
          />
        </Grid>
      )}
    </div>
  )
}

TerminalSelection.propTypes = {
  onCategoryChange: PropTypes.func.isRequired,
  onBrandChange: PropTypes.func.isRequired,
  onModelChange: PropTypes.func.isRequired,
  onPaymentTypeChange: PropTypes.func.isRequired,
  onValidIMEI: PropTypes.func.isRequired,
  reservationError: PropTypes.bool,
  isCompany: PropTypes.bool,
  paymentType: PropTypes.string,
  professionOptions: PropTypes.array,
  onSelectProfession: PropTypes.func.isRequired,
  shouldShowCompany: PropTypes.bool,
  signUpType: PropTypes.string,
  portaPaymentType: PropTypes.string,
  onAddTerminalChange: PropTypes.func.isRequired,
  isNewTerminal: PropTypes.bool,
  fieldNames: PropTypes.object,
  terminalModels: PropTypes.array,
  terminalDetails: PropTypes.object,
  isListLoading: PropTypes.bool,
  listError: PropTypes.bool,
  isFinancialsLoading: PropTypes.bool,
  isElFijo: PropTypes.bool,
  isPosSale: PropTypes.bool,
  disableOfferEdit: PropTypes.bool,
  isLeadFilled: PropTypes.bool,
  isMobileOnly: PropTypes.bool,
  className: PropTypes.string,
  shouldDisableTerminal: PropTypes.bool,
  fetchTerminals: PropTypes.func,
  isAppleTv: PropTypes.bool,
}
