// import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const TitleCard = styled.h2`
  color: ${props => (props.color ? props.color : 'inherit')};
  font-family: Roboto;
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-bottom: 8px;
  padding: ${props => (props.padding ? props.padding : '0')};
`

TitleCard.propTypes = {
  children: PropTypes.string.isRequired,
  color: PropTypes.string,
  padding: PropTypes.string,
}

export default TitleCard
