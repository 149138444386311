import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { get, head, isEmpty } from 'lodash'

import { Typography, Box, Divider } from '@material-ui/core'
import { TextStyles } from 'utils/text'

import {
  findRouterPermanents,
  getFeesPendingFromPermanent,
  isConvergentSubscription,
} from 'services/subscriptions'

import { isRouterPermanent3P } from 'services/subscriptions/helpers'
import { selectEnabledProrratedPenalty } from 'services/feature-flag/selectors'
import { PermanencyTab } from './PermanencyTab'
import { PendingAndTotalFee } from './PendingAndTotalFee'
import { FeeTab } from './FeeTab'

export const PermanencyRouter = styled(({ className, subscription }) => {
  const permanent = head(findRouterPermanents(subscription))
  const enableProrratedPenalty = useSelector(selectEnabledProrratedPenalty)

  return permanent ? (
    <div className={className}>
      <Typography
        {...TextStyles.subtitle1Secondary({
          className: 'txt-none p-b-12',
        })}>
        Fijo - Instalación Fibra
      </Typography>
      <div className="flex">
        {!isEmpty(permanent) && (
          <>
            <PermanencyTab validFor={permanent.validFor} totalMonths={permanent.length} />

            <Box mt="8px">
              <Divider orientation="vertical" />
            </Box>

            {isConvergentSubscription(subscription) && isRouterPermanent3P(permanent) ? (
              <FeeTab
                className="p-l-24"
                title="Penalización"
                fee={get(permanent, 'fees.penalty', 0)}
              />
            ) : (
              <PendingAndTotalFee
                className="p-l-24"
                title="Penalización"
                pendingFee={getFeesPendingFromPermanent(permanent)}
                totalFee={get(permanent, 'fees.penalty', 0)}
                enableProrratedPenalty={enableProrratedPenalty}
              />
            )}
          </>
        )}
        <Box mt="8px">
          <Divider orientation="vertical" />
        </Box>
      </div>
    </div>
  ) : null
})`
  .txt-none {
    text-transform: none;
  }
`

PermanencyRouter.propTypes = {
  className: PropTypes.string,
}
