import { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { selectIpAddress, actions } from '../store'

export const useRequestIpAddress = () => {
  const dispatch = useDispatch()

  const ipAddress = useSelector(selectIpAddress)

  const requestIpAddress = useCallback(
    (force = false) => {
      if (!ipAddress || force) {
        dispatch(actions.requestIpAddress())
      }
    },
    [ipAddress],
  )

  return {
    requestIpAddress,
  }
}
