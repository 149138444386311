import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'

import { HTMLTemplate } from 'modules/ui/components/HTMLTemplate/HTMLTemplate'

import { Step } from './Step'

const useStyles = makeStyles({
  'terminal-payment': {
    marginLeft: '24px',
  },
  paragraph: {
    marginTop: '8px',
  },
})

const TerminalShippingStep = ({ hasTerminals, financedTerminals, nextStepsTexts, isLastStep }) => {
  const classes = useStyles()

  const deferredPaymentTerminals = financedTerminals.filter(t => Boolean(t.finalPayment))

  return (
    hasTerminals && (
      <Step
        title={nextStepsTexts.terminal_delivery.title}
        description={
          <>
            <HTMLTemplate html={nextStepsTexts.terminal_delivery.first} />

            {deferredPaymentTerminals.length > 0 && (
              <DeferredPaymentStep
                deferredPaymentTerminals={deferredPaymentTerminals}
                nextStepsTexts={nextStepsTexts}
              />
            )}

            <p className={classes.paragraph}>
              <HTMLTemplate html={nextStepsTexts.terminal_delivery.second} />
            </p>
          </>
        }
        isLastStep={isLastStep}
        data-hook="terminal_shipping"
      />
    )
  )
}

TerminalShippingStep.propTypes = {
  hasTerminals: PropTypes.bool,
  financedTerminals: PropTypes.array,
  nextStepsTexts: PropTypes.object,
  isLastStep: PropTypes.bool,
}

TerminalShippingStep.defaultProps = {}

export const DeferredPaymentStep = ({ deferredPaymentTerminals, nextStepsTexts }) => {
  const classes = useStyles()

  return (
    <>
      <p className={classes.paragraph}>
        <HTMLTemplate html={nextStepsTexts.terminal_several_payment.first} />
      </p>
      <p className={classes.paragraph}>
        <HTMLTemplate html={nextStepsTexts.terminal_several_payment.second} />
        <span>
          <HTMLTemplate html={nextStepsTexts.terminal_several_payment.third} />
          <ul>
            {deferredPaymentTerminals.map(({ name, finalPayment }) => (
              <li className={classes['terminal-payment']} key={name}>
                <strong>{name}</strong>
                {` - `}
                <span>{`${finalPayment} €`}</span>
              </li>
            ))}
          </ul>
        </span>
      </p>
    </>
  )
}

DeferredPaymentStep.propTypes = {
  deferredPaymentTerminals: PropTypes.array,
  nextStepsTexts: PropTypes.object,
}

DeferredPaymentStep.defaultProps = {
  deferredPaymentTerminals: [],
}

export default TerminalShippingStep
