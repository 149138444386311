import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { FieldArray, useFormikContext } from 'formik'

import { Card, CardContent, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import { TextStyles } from 'utils/text'
import { ButtonLink, IconButton } from 'components/ui'

import { ExtraMobileLineOffer } from 'modules/NewClientSales/store/models/index'
import { COMPANY } from 'services/global-constants/index'
import { useSetFormikValuesToBasket } from 'modules/NewClientSales/hooks/Shared/index'

import { useExtraLine } from '../useExtraLine'
import { Line } from '../../Offer/Line/Line'

const useStyles = makeStyles({
  closeIcon: {
    position: 'absolute',
    right: '40px',
    top: '44px',
    zIndex: 9,
  },
})

function ExtraMobileLine({ lineType, index, disableOfferEdit, isLeadFilled }) {
  const {
    formikValues,
    tariffsError,
    fieldNames,
    availableTariffs,
    onChangeTariff,
    isLoadingTariffs,
    selectedExtraTariffs,
  } = useExtraLine({
    lineType,
    index,
  })

  return (
    <Line
      showPicker
      disableOfferEdit={disableOfferEdit}
      isLeadFilled={isLeadFilled}
      isMobileOnly
      showPackages
      showLineData={!!selectedExtraTariffs[index]?.id}
      selectedTariff={selectedExtraTariffs[index]}
      lineFieldNames={fieldNames}
      formikLineValues={formikValues}
      lineType={lineType}
      error={tariffsError}
      isLoading={isLoadingTariffs}
      onChangeTariff={onChangeTariff}
      tariffs={availableTariffs}
    />
  )
}

ExtraMobileLine.propTypes = {
  lineType: PropTypes.string,
  index: PropTypes.number,
  disableOfferEdit: PropTypes.bool,
  isLeadFilled: PropTypes.bool,
}

export function ExtraMobileLines({
  disableOfferEdit,
  isLeadFilled,
  linesLimit,
  additionalLinesLength,
  linesLimitReached,
}) {
  const {
    values: { extraMobileLinesOffers, account },
  } = useFormikContext()

  const styles = useStyles()
  const [mobileLinesError, setMobileLinesErrors] = useState(false)
  const setFormikValuesToBasket = useSetFormikValuesToBasket()
  function onAddExtraLine(arrayHelpers) {
    const maxLines = account.segment === COMPANY ? 24 : 5
    if (extraMobileLinesOffers.length < maxLines - 1) {
      // const newValues = [...extraMobileLinesOffers, ExtraMobileLineOffer]
      arrayHelpers.push(ExtraMobileLineOffer)
      // setFormikValuesToBasket('extraMobileLinesOffers', newValues)
    } else {
      setMobileLinesErrors(true)
    }
  }

  function onRemoveExtraTariff(arrayHelpers, index) {
    arrayHelpers.remove(index)
    const newValues = extraMobileLinesOffers.filter((tariff, i) => i !== index)
    setFormikValuesToBasket('extraMobileLinesOffers', newValues)
    setMobileLinesErrors(false)
  }

  useEffect(() => {
    if (!extraMobileLinesOffers.length) {
      setMobileLinesErrors(false)
    }
  }, [extraMobileLinesOffers.length])

  return (
    <FieldArray name="extraMobileLinesOffers">
      {arrayHelpers => (
        <>
          {extraMobileLinesOffers?.map((elem, index) => (
            <Card
              className="m-y-32"
              style={{ position: 'relative' }}
              key={`extramobileoffer-${index + 1}`}>
              <CardContent style={{ paddingBottom: 0 }}>
                <div className={styles.closeIcon}>
                  <IconButton
                    disabled={disableOfferEdit}
                    icon="close"
                    onClick={() => onRemoveExtraTariff(arrayHelpers, index)}
                  />
                </div>
                <ExtraMobileLine
                  index={index}
                  disableOfferEdit={disableOfferEdit}
                  isLeadFilled={isLeadFilled}
                  lineType={`extraMobileLinesOffers[${index}]`}
                  selectedTariff={elem.tariff}
                  // eslint-disable-next-line react/no-array-index-key
                />
              </CardContent>
            </Card>
          ))}
          {linesLimitReached && (
            <Card iconType="close" className="align-start-start p-24 m-y-24">
              <Typography {...TextStyles.labelAlert()}>
                El máximo número de líneas adicionales para la tarifa principal escogida es de{' '}
                {linesLimit - 1} y has añadido {additionalLinesLength}.
              </Typography>
            </Card>
          )}
          {!disableOfferEdit && (
            <Card iconType="close" className="align-start-start p-24 m-y-24">
              {mobileLinesError ? (
                <Typography {...TextStyles.labelAlert()}>
                  Has alcanzado el número máximo de líneas móviles por paquete, las demás líneas
                  móviles tendrán que ser contratadas fuera de este
                </Typography>
              ) : (
                <ButtonLink
                  dataHook="add-mobile-line"
                  onClick={() => onAddExtraLine(arrayHelpers)}
                  disabled={disableOfferEdit || linesLimitReached}
                  type="primary"
                  underline={false}>
                  + AÑADIR LÍNEA MÓVIL
                </ButtonLink>
              )}
            </Card>
          )}
        </>
      )}
    </FieldArray>
  )
}

ExtraMobileLines.propTypes = {
  disableOfferEdit: PropTypes.bool,
  isLeadFilled: PropTypes.bool,
  linesLimit: PropTypes.number || PropTypes.null,
  additionalLinesLength: PropTypes.number,
  linesLimitReached: PropTypes.bool,
}
