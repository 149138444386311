import { getRatioDaysActive } from 'services/subscriptions/helpers'
import { applyTax } from 'services/taxes'
import { selectTariffsApigeeById, getMonthlyFee } from 'modules/tariffs/store-apigee'
import { head, get } from 'lodash'

import { MULTISIM_LINE } from 'services/customer-360/constants'

export const addCurrentMonthEstimatedFeeWithTaxes = (
  state,
  tariffRanges = [],
  tax,
  prices,
  multisimType,
) =>
  tariffRanges.map(tariffRange => {
    let monthlyFeeWithTaxes = 0

    // Remove this if when migrate call tariff v2 to v3
    if (multisimType === MULTISIM_LINE) {
      monthlyFeeWithTaxes = applyTax(tax, get(head(prices), 'attributes.price.tax-free-amount'))
    } else {
      const tariff = selectTariffsApigeeById(tariffRange.tariffId)(state)
      monthlyFeeWithTaxes = applyTax(tax, getMonthlyFee(tariff))
    }

    const ratioDaysActive = getRatioDaysActive(tariffRange.start, tariffRange.end)

    return {
      ...tariffRange,
      monthlyFeeSofarWithTaxes: monthlyFeeWithTaxes * ratioDaysActive,
    }
  })
