import PropTypes from 'prop-types'
import { get } from 'lodash'

import { Typography } from '@material-ui/core'
import { TextStyles } from 'utils/text'

import styled from 'styled-components'

export const InfoValues = styled(({ className, title, values }) => {
  return (
    <div className={className}>
      {title && <Typography {...TextStyles.dataDark()}>{title}</Typography>}
      <div className="values">
        {values &&
          values.map(val => (
            <span className="val">
              <Typography
                {...TextStyles.labelDark({
                  component: 'span',
                })}>
                {val.label}
                {val.value ? ':' : ''}
              </Typography>
              {val.value && (
                <Typography
                  {...TextStyles.dataDark({
                    component: 'span',
                  })}>
                  {val.value}
                </Typography>
              )}
            </span>
          ))}
      </div>
    </div>
  )
})`
  padding: 24px 0px;
  background-color: ${props => get(props, 'theme.palette.global.gray_light')};

  > .values {
    display: flex;
    flex-wrap: wrap;

    > .val {
      margin-right: 40px;
      margin-top: 16px;
    }
  }
`

InfoValues.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
}
