import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Typography } from '@material-ui/core'

import { TextStyles } from 'utils/index'
import Modal from './Modal'

const useStyles = makeStyles({
  'notification-modal-content': {
    padding: '48px 0 50px',
    textAlign: 'center',

    '& img': {
      marginBottom: '34px',
    },

    '& .modal-title': {
      marginBottom: '31px',
    },
  },
})

const NotificationModal = props => {
  const classes = useStyles()

  return (
    <Modal overlay isOpen={props.isOpen} type={props.type} onClose={props.onClose}>
      <div className={classes['notification-modal-content']} data-hook={props.dataHook}>
        <img src={props.src} alt={props.title} />
        <Typography
          {...TextStyles.title1Dark({
            className: 'modal-title',
          })}>
          {props.title}
        </Typography>
        {props.message && (
          <Typography
            {...TextStyles.title1Dark({
              className: 'modal-title',
            })}>
            {props.message}
          </Typography>
        )}
        {props.children}
      </div>
    </Modal>
  )
}

NotificationModal.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  message: PropTypes.any,
  src: PropTypes.string,
  type: PropTypes.oneOf(['default', 'alert', 'attention', 'info', 'success']),
  onClose: PropTypes.func,
  children: PropTypes.node,
  dataHook: PropTypes.string,
}

export default NotificationModal
