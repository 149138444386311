import { get } from 'lodash'
import { useState, useEffect } from 'react'
import { getAnimationType } from '../helpers/routerAnimatedHelpers'
import { RouterAnimationStyles } from '../components/RouterAnimationStyles'

export function useRouterAnimationType(history) {
  const [lastRoute, setLastRoute] = useState('/')
  const [animationType, setAnimationType] = useState(RouterAnimationStyles.NONE)

  useEffect(() => {
    // set initial path
    setLastRoute(history.location.pathname)
    // history.listen returns an unlisten function
    return history.listen(location => {
      const type = get(location, 'state.disableAnimation', false)
        ? RouterAnimationStyles.NONE
        : getAnimationType(lastRoute, location.pathname)
      setLastRoute(location.pathname)
      setAnimationType(type)
    })
  }, [animationType, lastRoute])

  return [animationType]
}
