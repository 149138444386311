import { useState } from 'react'
import PropTypes from 'prop-types'

import { useSelector } from 'react-redux'

import {
  maxSTCReached,
  isConvergentSubscription,
  getSubscriptionContentDuoPrincipal,
  duoPrincipalContentOldTariff,
} from 'services/subscriptions'
import { selectors as customer360SvcSelectors } from 'services/customer-360/selectors'

import {
  isTariffCompatibleWithSva,
  isTariffCompatibleWithHebeDiscount,
  is25to30STC,
  selectDecisionAllowed,
  selectOrderElegibilityisKo,
  selectOrderConsequencesIsKo,
  selectWarnings,
} from '../selectors'
import { getSubscriptionFeeAmount, getIsPrepaidTariff } from '../helpers'

import { useTariffOptions } from '../hooks/useTariffOptions'
import { useTaxApplied } from '../hooks/useTaxApplied'

import { TariffsModal } from '../components/TariffsModal'

export const TariffsModalContainer = ({
  subscription,
  date: initialDate,
  selectedPsIdTariff: initialSelectedPsIdTariff,
  isFixedReduction,
  taxNeeded,
  isUnsusbcribe,
  onConfirm,
  onClose,
  onTitleChange,
  tariffSegment,
  subscriptionId,
}) => {
  const [discountChanges, setDiscountChanges] = useState(null)

  const [selectedTariffValue, setSelectedTariffValue] = useState('')
  const [selectedPsIdTariff, setSelectedPsIdTariff] = useState(initialSelectedPsIdTariff)
  const [date, setDate] = useState(maxSTCReached(subscription) ? 'nextMonth' : initialDate)
  const [newPaymentType, setNewPaymentType] = useState(null)

  const onTariffSelect = (e, psId) => {
    setSelectedTariffValue(e.target.value)
    setSelectedPsIdTariff(psId)
  }
  const onDateSelect = e => setDate(e.target.value)
  const onPaymentTypeChange = paymentType => setNewPaymentType(paymentType)

  const { filteredEnrichTariffs } = useTariffOptions(
    subscription,
    newPaymentType,
    isFixedReduction,
    isUnsusbcribe,
  )

  const compatibleTariffWithSva = useSelector(state =>
    isTariffCompatibleWithSva(state, selectedPsIdTariff),
  )
  const hebeDiscountIncompatibility = !isTariffCompatibleWithHebeDiscount(selectedPsIdTariff)
  const STC25to30 = useSelector(state => is25to30STC(state, subscription, selectedPsIdTariff))

  const subscriptionContentDuoPrincipal = useSelector(getSubscriptionContentDuoPrincipal)
  const duoPrincipalOldTariff = useSelector(duoPrincipalContentOldTariff)

  const showAlertTariffDUOWarning =
    subscriptionContentDuoPrincipal &&
    duoPrincipalOldTariff &&
    (selectedPsIdTariff ? isConvergentSubscription(subscription) : false)
  const availableStc = useSelector(selectDecisionAllowed)
  const orderEligibilitiesKo = useSelector(selectOrderElegibilityisKo)
  const orderConsequencesKo = useSelector(selectOrderConsequencesIsKo)
  const warnings = useSelector(selectWarnings)

  const customerIban = useSelector(customer360SvcSelectors.getIBAN)
  const feeAmount = getSubscriptionFeeAmount(subscription)

  const { feeAmount: feeAmountWithTax } = useTaxApplied({ feeAmount })

  const isPrepaidTariff = getIsPrepaidTariff(subscription, newPaymentType)

  const props = {
    showAlertTariffDUOWarning,
    availableStc,
    orderEligibilitiesKo,
    orderConsequencesKo,
    warnings,
    compatibleTariffWithSva,
    feeAmount: taxNeeded ? feeAmountWithTax : feeAmount,
    selectedTariffValue,
    selectedPsIdTariff,
    date,
    filteredEnrichTariffs,
    subscription,
    onConfirm,
    permanenceChanges: null,
    onClose,
    onTariffSelect,
    onDateSelect,
    isPrepaidTariff,
    newPaymentType,
    onPaymentTypeChange,
    customerIban,
    isFixedReduction,
    discountChanges,
    setDiscountChanges,
    onTitleChange,
    hebeDiscountIncompatibility,
    STC25to30,
    taxNeeded,
    tariffSegment,
    subscriptionId,
    isUnsusbcribe,
  }

  return <TariffsModal {...props} />
}

TariffsModalContainer.propTypes = {
  subscription: PropTypes.object.isRequired,
  date: PropTypes.number,
  selectedPsIdTariff: PropTypes.string,
  isFixedReduction: PropTypes.bool,
  isUnsusbcribe: PropTypes.bool,
  taxNeeded: PropTypes.bool,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
  onTitleChange: PropTypes.func,
  tariffSegment: PropTypes.string,
  subscriptionId: PropTypes.number,
}
