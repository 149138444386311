import styled from 'styled-components'
import { get } from 'lodash'

const OfferCardBackStyled = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: absolute;
  transform: rotateY(-180deg);
  background: ${props => get(props, 'theme.palette.brand.light')};
  .rotate-action {
    position: absolute;
    top: 8px;
    right: 0px;
  }
  .offer-item-portout {
    width: 100%;
    height: 62px;
    padding: 15px 25px 5px;
    box-sizing: border-box;
    &:first-child {
      background: ${props => get(props, 'theme.palette.global.gray_light')};
      padding: 21px 25px 0;
      height: 70px;
    }
    & + .offer-item-portout {
      border: none;
    }
  }
  .multicolumn & {
    > div {
      width: 50%;
      &:last-of-type {
        box-shadow: -2px 0 0 0 #ececec;
      }
    }
    > .offer-item-portout {
      width: 100%;
      & ~ div {
        .offer-item-portout {
          background: ${props => get(props, 'theme.palette.brand.light')};
          height: 62px;
          padding: 15px 25px 5px;
        }
      }
    }
  }
`

const OfferCardBack = props => <OfferCardBackStyled className="offer-card-back" {...props} />

export default OfferCardBack
