import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormikContext } from 'formik'

import { Card, CardContent } from '@material-ui/core'

import { FormError } from 'components/ui'
import { fetchCustomerDuoLimitAction } from 'modules/CustomerOrders'

import { useSetParentAgreementId } from '../../../hooks/Cartera/useSetParentAgreementId'
import { ExtraLinesContainer } from '../../../containers/OfferContainer'
import { SubscriptionSaleSelectors } from './SubscriptionSale.selectors'
import { ExtraMobileLineOffer } from '../../../store/models'
import { Lead } from '../LeadComponent'

export function SubscriptionSale() {
  const { setFieldValue } = useFormikContext()
  const { saleInfo, subscriptionInfo, hasExtraLines, isDuoLoading, hasDuoError } = useSelector(
    SubscriptionSaleSelectors,
  )
  const dispatch = useDispatch()

  useEffect(() => {
    if (saleInfo.docNumber && isDuoLoading === null) {
      dispatch(fetchCustomerDuoLimitAction(subscriptionInfo))
    }
  }, [saleInfo, isDuoLoading])

  useEffect(() => {
    if (!hasExtraLines) {
      setFieldValue('extraMobileLinesOffers', [ExtraMobileLineOffer])
    }
  }, [])

  useSetParentAgreementId(hasDuoError && !isDuoLoading, true)

  return (
    <>
      <Card>
        <CardContent>
          <Lead isCartera />
        </CardContent>
      </Card>
      {!hasDuoError ? (
        <ExtraLinesContainer />
      ) : (
        <Card>
          <CardContent>
            <FormError width="100%" className="p-b-16" fullwidth>
              Error al recuperar subscripciones del cliente. No se puede continuar con la venta.
            </FormError>
          </CardContent>
        </Card>
      )}
    </>
  )
}
