const GET_CUSTOMER_PRODUCTS_INIT = 'OVID/VISTA360/GET_CUSTOMER_PRODUCTS_INIT'
const GET_CUSTOMER_PRODUCTS = 'OVID/VISTA360/GET_CUSTOMER_PRODUCTS'
const RESET_CUSTOMER_PRODUCTS = 'OVID/VISTA360/RESET_CUSTOMER_PRODUCTS'
const GET_CUSTOMER_PRODUCTS_SUCCESS = 'OVID/VISTA360/GET_CUSTOMER_PRODUCTS_SUCCESS'
const GET_CUSTOMER_PRODUCTS_ERROR = 'OVID/VISTA360/GET_CUSTOMER_PRODUCTS_ERROR'

export const constants = {
  GET_CUSTOMER_PRODUCTS_INIT,
  GET_CUSTOMER_PRODUCTS,
  RESET_CUSTOMER_PRODUCTS,
  GET_CUSTOMER_PRODUCTS_SUCCESS,
  GET_CUSTOMER_PRODUCTS_ERROR,
}
