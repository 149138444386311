import { constants } from './constants'

const findCustomerSvaInit = (documentId, documentType) => ({
  type: constants.FIND_CUSTOMER_SVA,
  payload: {
    documentId,
    documentType,
  },
})

const findCustomerSvaSuccess = data => ({
  type: constants.FIND_CUSTOMER_SVA_SUCCESS,
  payload: {
    data,
  },
})

const findCustomerSvaFailed = error => ({
  type: constants.FIND_CUSTOMER_SVA_FAILED,
  payload: { error },
})

const resetCustomerSva = () => ({
  type: constants.RESET_CUSTOMER_SVA,
})

const putDisableCustomerSvaInit = (documentId, documentType, msisdn) => ({
  type: constants.DISABLE_CUSTOMER_SVA_INIT,
  payload: {
    documentId,
    documentType,
    msisdn,
  },
})

const putDisableCustomerSvaSuccess = data => ({
  type: constants.DISABLE_CUSTOMER_SVA_SUCCESS,
  payload: {
    data,
  },
})

const putDisableCustomerSvaFailed = error => ({
  type: constants.DISABLE_CUSTOMER_SVA_FAILED,
  payload: { error },
})

const putDisableCustomerSvaPending = () => ({
  type: constants.DISABLE_CUSTOMER_SVA_PENDING,
})

const resetDisableCustomerSva = () => ({
  type: constants.DISABLE_CUSTOMER_SVA_RESET,
})

const putHebeSvaCustomerInit = (msisdn, service, status) => ({
  type: constants.PUT_HEBE_SVA_CUSTOMER_INIT,
  payload: {
    msisdn,
    service,
    status,
  },
})

const putHebeSvaCustomerSuccess = data => ({
  type: constants.PUT_HEBE_SVA_CUSTOMER_SUCCESS,
  payload: {
    data,
  },
})
const fetchCustomerSvasInit = (accountId, msisdns) => ({
  type: constants.FETCH_CUSTOMER_SVAS,
  payload: {
    accountId,
    msisdns,
  },
})

const fetchCustomerSvasSuccess = data => ({
  type: constants.FETCH_CUSTOMER_SVAS_SUCCESS,
  payload: {
    data,
  },
})

const fetchCustomerSvasFailed = error => ({
  type: constants.FETCH_CUSTOMER_SVAS_FAILED,
  payload: { error },
})

const putHebeSvaCustomerFailed = error => ({
  type: constants.PUT_HEBE_SVA_CUSTOMER_FAILED,
  payload: { error },
})

const resetPutHebeSvaCustomer = () => ({
  type: constants.PUT_HEBE_SVA_CUSTOMER_RESET,
})

export const putHomeGoSvaStart = payload => ({
  type: constants.PURCHASE_HOMEGO_START,
  payload,
})

export const purchaseAlarmStart = () => ({
  type: constants.PURCHASE_ALARM_START,
})

export const purchaseAlarmSuccess = payload => ({
  type: constants.PURCHASE_ALARM_SUCCESS,
  payload,
})

export const purchaseAlarmFailed = error => ({
  type: constants.PURCHASE_ALARM_FAILED,
  payload: error,
})

export const resetPurchaseAlarm = () => ({
  type: constants.RESET_PURCHASE_ALARMS,
})

const findHomeGoSva = (customerId, msisdns) => ({
  type: constants.FIND_HOMEGO_SVA,
  payload: {
    customerId,
    msisdns,
  },
})

const findHomeGoSvaSuccess = data => ({
  type: constants.FIND_HOMEGO_SVA_SUCCESS,
  payload: {
    data,
  },
})

const findHomeGoSvaFailed = error => ({
  type: constants.FIND_HOMEGO_SVA_FAILED,
  payload: { error },
})

const cancelHomeGoSvaOrder = (msisdn, workflowId) => ({
  type: constants.CANCEL_HOMEGO_SVA_ORDER,
  payload: {
    msisdn,
    workflowId,
  },
})

const cancelHomeGoSvaOrderSuccess = () => ({
  type: constants.CANCEL_HOMEGO_SVA_ORDER_SUCCESS,
})

const cancelHomeGoSvaOrderFailed = error => ({
  type: constants.CANCEL_HOMEGO_SVA_ORDER_FAILED,
  payload: { error },
})

export const actions = {
  findCustomerSvaInit,
  findCustomerSvaSuccess,
  findCustomerSvaFailed,
  resetCustomerSva,
  putDisableCustomerSvaInit,
  putDisableCustomerSvaSuccess,
  putDisableCustomerSvaFailed,
  putDisableCustomerSvaPending,
  resetDisableCustomerSva,
  putHebeSvaCustomerInit,
  putHebeSvaCustomerSuccess,
  putHebeSvaCustomerFailed,
  resetPutHebeSvaCustomer,
  fetchCustomerSvasInit,
  fetchCustomerSvasSuccess,
  fetchCustomerSvasFailed,
  putHomeGoSvaStart,
  purchaseAlarmStart,
  purchaseAlarmSuccess,
  purchaseAlarmFailed,
  resetPurchaseAlarm,
  findHomeGoSva,
  findHomeGoSvaSuccess,
  findHomeGoSvaFailed,
  cancelHomeGoSvaOrder,
  cancelHomeGoSvaOrderSuccess,
  cancelHomeGoSvaOrderFailed,
}
