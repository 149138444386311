import PropTypes from 'prop-types'
import { RouterAnimationSlide } from '../RouterAnimationSlide'
import { RouterAnimationFade } from '../RouterAnimationFade'
import { RouterAnimationNone } from '../RouterAnimationNone'
import { RouterAnimationType, RouterAnimationTypes } from '../../helpers'

export function RouterAnimationStyles({ type, time }) {
  switch (type) {
    case RouterAnimationType.LEFT:
    case RouterAnimationType.RIGHT:
      return <RouterAnimationSlide type={type} time={time} />
    case RouterAnimationType.FADE:
      return <RouterAnimationFade time={time} />
    default:
      return <RouterAnimationNone />
  }
}

RouterAnimationStyles.defaultValues = {
  time: 500,
}

RouterAnimationStyles.propTypes = {
  time: PropTypes.number,
  type: PropTypes.oneOf(RouterAnimationTypes),
}
