import { createStructuredSelector, createSelector } from 'reselect'
import { get } from 'lodash'
import { formatPackagesName } from 'modules/Packages'
import {
  selectIsSubscriptionQueryParam,
  selectIsMultiSimQueryParam,
} from 'modules/Router/store/index'
import { selectAddressInfo, selectInstallationTechnologyName } from 'modules/Coverage'

import {
  selectAccount,
  selectPackagesWithFormattedName,
  getBasket,
  selectMainMobileTariff,
  selectIsPro,
} from 'modules/NewClientSales/store'

import {
  selectSelectedTerminalsWithComputedPrices,
  selectMonthlyPayments,
  selectTotalMonthlyTerminalFee,
  selectHasTerminalsWithSeveralPayment,
  selectIsElFijo,
  selectServiceAddress,
  selectMainTariff,
} from 'modules/NewClientSales/store/selectors/index'
import { selectFiberInstaller } from 'modules/Installations/store/selectors/installations.selectors'

// @TODO: this should select only the extra lines, we need to check the behavior
const selectExtraLinesSidebar = createSelector(
  getBasket,
  basket =>
    basket.extraMobileLinesOffers.map((elem, index) => ({
      index,
      name: get(elem, 'tariff.name'),
      packages: formatPackagesName(get(elem, 'selectedPackages', [])),
    })),
)

const selectTotalMonthlyPaymentsWithTerminals = createSelector(
  [selectMonthlyPayments, selectTotalMonthlyTerminalFee],
  (monthlyPayments, totalTerminalsFee) => {
    return (
      monthlyPayments.length &&
      monthlyPayments.map(payment => ({
        ...payment,
        amount: payment.amount + totalTerminalsFee,
      }))
    )
  },
)

const selectMainAddress = createSelector(
  selectIsElFijo,
  selectAddressInfo,
  selectServiceAddress,
  (isElFijo, installation, service) => {
    const { address, number = '' } = service.otherAddress

    const elFijoAddressNumber = number ? `, ${number}` : ''
    const { streetType, nameAddress, numberAddress, coverageTechnology } = installation
    const elFijoAddress = address ? `${address}${elFijoAddressNumber}` : '-'
    const convergentAddress = coverageTechnology
      ? `${streetType} ${nameAddress}, ${numberAddress}`
      : '-'
    return isElFijo ? elFijoAddress : convergentAddress
  },
)

export const selectSidebar = createStructuredSelector({
  fullAddress: selectMainAddress,
  account: selectAccount,
  landlineTariff: selectMainTariff,
  mainMobileTariff: selectMainMobileTariff,
  extraLines: selectExtraLinesSidebar,
  monthlyPayments: selectMonthlyPayments,
  packages: selectPackagesWithFormattedName,
  terminals: selectSelectedTerminalsWithComputedPrices,
  installationTechnologyName: selectInstallationTechnologyName,
  fiberInstaller: selectFiberInstaller,
  totalMonthlyFeeWithTerminals: selectTotalMonthlyPaymentsWithTerminals,
  hasTerminalsWithSeveralPayment: selectHasTerminalsWithSeveralPayment,
  isPro: selectIsPro,
  isSubscription: selectIsSubscriptionQueryParam,
  isMultiSim: selectIsMultiSimQueryParam,
})
