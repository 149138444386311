import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { Typography, Box } from '@material-ui/core'
import { TextStyles } from 'utils/text'

import { useDispatch } from 'react-redux'
import { exitAppAction } from 'modules/Core'
import {
  CLIENT_EXISTS,
  ON_BOARDING_DOC,
  CLIENT_EXISTS_DOC,
  PRESCORING_DENIEND,
} from 'modules/SharedSales/constants'

import { Modal, Button } from 'components/ui'
import { useAnalytics } from 'modules/Analytics/hooks/index'

export function ErrorModal({ error, hasMsError, fieldName, fieldValue, onCloseModal }) {
  const [openModal, setIsModalOpen] = useState()
  const analytics = useAnalytics()
  useEffect(() => {
    setIsModalOpen(!!hasMsError)
  }, [hasMsError])

  const dispatch = useDispatch()

  const onQuit = () => dispatch(exitAppAction())

  const quit = [CLIENT_EXISTS_DOC, PRESCORING_DENIEND].includes(error)
  const shouldGoVista360 =
    [CLIENT_EXISTS, ON_BOARDING_DOC].includes(error) && fieldValue && fieldName !== 'Número'

  const onClose = () => {
    onCloseModal()
    setIsModalOpen(false)
    if (quit) {
      analytics.sendCustomEvent({
        dataToSend: {
          eventAction: 'Accion/TipoCliente',
          eventLabel: 'ClienteNuevo',
          element: 'Ir a 360',
        },
      })
      onQuit()
    }
  }

  const buildUrlVista = () => {
    let basePath = '/vista/dashboard'
    if (fieldName !== 'Número' && fieldValue) {
      basePath += `/documentType/${fieldName}/documentId/${fieldValue.toUpperCase()}`
    }

    return `${basePath}`
  }

  return (
    <Modal isOpen={openModal} width="500px" data-hook="error-modal">
      <Box display="flex" alignItems="flex-start" textAlign="center">
        <Typography {...TextStyles.paragraphDark()}>{error}</Typography>
      </Box>
      {fieldName && fieldValue && (
        <Box display="flex" alignItems="flex-start" textAlign="center">
          <Typography {...TextStyles.paragraphDark()}>{`${fieldName}: ${fieldValue}`}</Typography>
        </Box>
      )}
      <Box display="flex" alignItems="flex-start" py="12px">
        {!shouldGoVista360 && (
          <Button type="button" data-hook="close-modal" onClick={onClose}>
            {quit ? 'Ir a Newton' : 'Cerrar'}
          </Button>
        )}
        {shouldGoVista360 && (
          <Button type="button" data-hook="close-modal" to={buildUrlVista()}>
            Ir a 360
          </Button>
        )}
      </Box>
    </Modal>
  )
}

ErrorModal.propTypes = {
  hasMsError: PropTypes.bool,
  error: PropTypes.string,
  fieldName: PropTypes.string,
  fieldValue: PropTypes.string,
  onCloseModal: PropTypes.func,
}

ErrorModal.defaultProps = {
  onCloseModal: () => {},
}
