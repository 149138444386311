import { APP_CONFIG } from 'services/app-config'
import { axiosJWT } from 'modules/axios'

export function fetchDeliveryPoints(zipCode) {
  return axiosJWT({
    method: 'GET',
    url: `${APP_CONFIG.sales_cluster}/v1/delivery-points/yoigo`,
    params: {
      zipCode,
    },
  }).then(resp => resp.data)
}
