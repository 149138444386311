import { AccountPackages } from '../AccountPackages'

export const tabs = {
  MAIN: 'main',
  CONTRACTS: 'contracts',
  HISTORICAL_ORDERS: 'historical_orders',
  LIFECYCLE_CA: 'lifecycle_ca',
}

export const tabsConfig = [
  {
    label: 'Paquetes',
    value: tabs.MAIN,
    component: AccountPackages,
  },
  // {
  //   label: 'Contratos',
  //   value: tabs.CONTRACTS,
  //   component: () => '',
  // },
  // {
  //   label: 'Histórico del pedido',
  //   value: tabs.HISTORICAL_ORDERS,
  //   component: () => '',
  // },
  // {
  //   label: 'Vida del C.A',
  //   value: tabs.LIFECYCLE_CA,
  //   component: () => '',
  // },
]
