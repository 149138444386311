import { constants } from './constants'

const checkInit = (checks, successStep, failedStep = successStep, params = {}, resetErrors) => ({
  type: constants.CHECK_INIT,
  payload: { checks, successStep, failedStep, params, resetErrors },
})

const check = ({ resetErrors = true }) => ({
  type: constants.CHECK,
  payload: { resetErrors },
})

const checked = step => ({
  type: constants.CHECKED,
  payload: { step },
})

const checkFailed = error => ({
  type: constants.CHECK_FAILED,
  payload: { error },
})

export const actions = {
  checkInit,
  check,
  checked,
  checkFailed,
}
