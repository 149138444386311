import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { join, isEmpty } from 'lodash'

import { helpers as helpers360, selectors as selectors360 } from 'services/customer-360'
import { actions as customerSvaActions } from 'services/customer-sva/actions'
import { selectSvaHomeGoFlag } from 'services/feature-flag/selectors'
import { selectAccountId } from 'modules/Auth'
import { selectHomeGoSvaError } from 'services/customer-sva/selectors'

export const useHomeGoSva = () => {
  const dispatch = useDispatch()

  const isSvaHomeGoFlag = useSelector(selectSvaHomeGoFlag)
  const contractsCustomer = useSelector(selectors360.contracts)
  const accountId = useSelector(selectAccountId)

  const findHomeGoSvaErrors = useSelector(selectHomeGoSvaError)

  const allLinesCustomer = useMemo(() => helpers360.getAllLinesAndElFijoMsisdn(contractsCustomer), [
    contractsCustomer,
  ])

  const msisdnsStr = useMemo(() => join(allLinesCustomer, ','), [allLinesCustomer])

  const findHomeGoSvaInit = useCallback(() => {
    if (!isEmpty(msisdnsStr)) {
      dispatch(customerSvaActions.findHomeGoSva(accountId, msisdnsStr))
    }
  }, [msisdnsStr, isSvaHomeGoFlag])

  return {
    findHomeGoSvaInit,
    hasFindHomeGoSvaError: !!findHomeGoSvaErrors?.cadence || !!findHomeGoSvaErrors?.sicor,
  }
}
