import { createStructuredSelector } from 'reselect'
import { selectQueryParams, selectIsMultiSimQueryParam } from 'modules/Router'
import { selectFeatureFlags } from 'services/feature-flag/selectors'
import { selectIsVula, selectIsAdsl } from 'modules/Coverage'
import { selectMainMobileTariffsIsLoading } from 'modules/tariffs/store'
import {
  selectIsMobileOnly,
  selectIsCarteraQueryParam,
  selectIsSubscriptionQueryParam,
  selectIsCrossSellQueryParam,
  selectIsConvergent,
  selectIs2p,
} from 'modules/Router/store/index'

import {
  selectBasket,
  selectAccount,
  selectSelectedExtraMobileTariffs,
  selectSaleParam,
  selectIsElFijo,
  selectSalesTypes,
  selectBillingAccountId,
  selectIs2pPure,
} from 'modules/NewClientSales/store/selectors/index'
import { selectPortabilityDates } from 'modules/Lines/store/selectors'

export const selectSales = createStructuredSelector({
  queryParams: selectQueryParams,
  account: selectAccount,
  extraLines: selectSelectedExtraMobileTariffs,
  basket: selectBasket,
  flags: selectFeatureFlags,
  isMobileOnly: selectIsMobileOnly,
  isElFijo: selectIsElFijo,
  saleType: selectSaleParam,
  sale: selectSalesTypes,
  isAdsl: selectIsAdsl,
  isVula: selectIsVula,
  billingAccountId: selectBillingAccountId,
  isMainTariffsLoading: selectMainMobileTariffsIsLoading,
  isCartera: selectIsCarteraQueryParam,
  isSubscription: selectIsSubscriptionQueryParam,
  isCrossSell: selectIsCrossSellQueryParam,
  is2pPure: selectIs2pPure,
  portaDates: selectPortabilityDates,
  isMultiSim: selectIsMultiSimQueryParam,
  isConvergent: selectIsConvergent,
  is2p: selectIs2p,
})
