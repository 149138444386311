import PropTypes from 'prop-types'

import { Card, CardContent, Box, Typography } from '@material-ui/core'

import { CardRowLabel, CardRowValue } from 'components'

const CardFooter = ({ children }) => (
  <Box padding="18px 24px" style={{ backgroundColor: '#F4F4F4' }}>
    {children}
  </Box>
)

CardFooter.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.array]),
}

const AddressSidebar = ({ customer, customerInfo, isElFijoCancellation }) => {
  return (
    <Card elevation={2} style={{ width: '100%', borderRadius: '4px', marginTop: 0 }}>
      {!isElFijoCancellation && (
        <CardContent style={{ paddingBottom: 0 }}>
          <Typography variant="h6">Dirección de la instalación</Typography>
          <Box marginTop="21px">
            <Box marginBottom="13px">
              <CardRowLabel>Calle: </CardRowLabel>
              <CardRowValue>
                {customerInfo.streetOne} {customerInfo.streetNumber}
              </CardRowValue>
            </Box>
            <Box marginBottom="13px">
              <CardRowLabel>Puerta: </CardRowLabel>
              <CardRowValue>{customerInfo.streetTwo}</CardRowValue>
            </Box>
            <Box marginBottom="13px">
              <CardRowLabel>Municipio: </CardRowLabel>
              <CardRowValue>{customerInfo.city}</CardRowValue>
            </Box>
            <Box marginBottom="13px">
              <Box display="flex" justifyContent="space-between" flexDirection="row">
                <div>
                  <CardRowLabel>CP: </CardRowLabel>
                  <CardRowValue>{customerInfo.postCode}</CardRowValue>
                </div>
                <div>
                  <CardRowLabel>Provincia: </CardRowLabel>
                  <CardRowValue>{customerInfo.province}</CardRowValue>
                </div>
              </Box>
            </Box>
          </Box>
        </CardContent>
      )}
      <CardFooter>
        <Box marginBottom="5px">
          <CardRowLabel>Tarifa contratada:</CardRowLabel>
        </Box>
        <div>
          {(customer?.products &&
            customer?.products.map(item => (
              <CardRowValue key={`desc-${item.id}`}>{item.description}</CardRowValue>
            ))) ||
            ''}
        </div>
      </CardFooter>
    </Card>
  )
}

AddressSidebar.propTypes = {
  customer: PropTypes.object,
  customerInfo: PropTypes.object,
  isElFijoCancellation: PropTypes.bool,
}

export default AddressSidebar
