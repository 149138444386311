import styled from 'styled-components'

export const FormContainer = styled.div.attrs(() => ({
  className: 'form-container',
}))`
  display: inline-flex;
  flex-direction: column;
  &:not(:last-child) {
    margin-right: 10px;
  }
  p + & {
    margin-top: 30px;
    color: black;
  }
  & + p {
    margin-top: 25px;
  }
  & + .advice-wrapper {
    margin-top: 25px;
  }
`
