import PropTypes from 'prop-types'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { head, get, isEmpty } from 'lodash'

import { formatMonthlyFee } from 'utils/index'

import { selectSfid } from 'modules/Auth'
import { profiles } from 'modules/Permissions'
import { validateAgentProfile } from 'modules/vista-360/components/OrderDashboard/OrderDashboard.helpers'

import { is2pPureTariff } from 'modules/vista-360/helpers/helpers'
import { isProCustomer } from 'services/customer-360/helpers'
import { getSegment, selectors as customer360SvcSelectors } from 'services/customer-360/selectors'
import { isElFijoTariff } from 'modules/tariffs/helpers'

import {
  getMsisdn,
  findFixedProduct,
  findMobileProduct,
  findFee,
  getSubscriptionTariffId,
  getSvaSubscriptionType,
  getSvaHebeMonthlyFeeSubscription,
  hasPortOutInfo,
  findMobilePermanents,
} from 'services/subscriptions'
import { hasAgileTV } from 'services/subscriptions/helpers'

import { SVA_HEBE_CODE } from 'modules/offers-configuration/constants'

import { applyTax, getTax } from 'services/taxes'
import {
  getHasModifications,
  getChangedTariff,
  getSubscriptionMostRecentOrder,
} from 'modules/offers-configuration/selectors'
import { selectIsPosOrMasProximo } from 'modules/Permissions/store/permissions-selectors'
import {
  get2pWelcomeLetterData,
  getElFijoWelcomeLetterData,
} from 'modules/offers-configuration/helpers'
import { isWithinDateRange } from 'utils/formatting/dates'

import { getCommercialName, selectTariffsApigeeById } from 'modules/tariffs/store-apigee'
import { getContractPdf } from 'services/contract-files'
import { useDownloadFile, useDownloadFileTxt, useDownloadFileWl } from 'modules/Core/hooks'
import { selectEnabledTaxSvaAgileTv } from 'services/feature-flag/selectors'

import { SubscriptionDetail } from '../../components/SubscriptionDetail'

export const SubscriptionDetailContainer = ({ subscription }) => {
  const isElFijo = isElFijoTariff(get(subscription, 'type'))
  const productFixed = findFixedProduct(subscription)
  const productMobile = findMobileProduct(subscription)
  const billingAddress = useSelector(customer360SvcSelectors.getBillingAddress)
  const postCode = get(billingAddress, 'postCode')
  const customerSegment = useSelector(getSegment)
  const taxNeeded = !isProCustomer(customerSegment)

  const tax = (taxNeeded && useSelector(state => getTax(state, postCode))) || 0

  const feeAmount = get(findFee(subscription), 'price.amount', 0)

  const tariff = useSelector(selectTariffsApigeeById(getSubscriptionTariffId(subscription)))
  const tariffId = getSubscriptionTariffId(subscription)
  const is2pPure = is2pPureTariff(tariffId)

  const orderByProductId = useSelector(state =>
    getSubscriptionMostRecentOrder(state, getMsisdn(subscription)),
  )
  const { contractId, sfid, id } = orderByProductId

  const [download, downloadFromPromiseBlob] = useDownloadFile()
  const [downloadTxt, downloadFromPromiseTxt] = useDownloadFileTxt()
  const [downloadWl, downloadFromPromiseWl] = useDownloadFileWl()

  let welcomeLetterData = {}
  if (is2pPure) {
    welcomeLetterData = get2pWelcomeLetterData(orderByProductId, postCode, customerSegment, tax)
  } else if (isElFijo) {
    welcomeLetterData = getElFijoWelcomeLetterData(orderByProductId, postCode)
  }

  const activateAtSubscription = get(subscription, 'activated_at')
  const contractIsWithinDateRange = isWithinDateRange(activateAtSubscription, 30)
  const agentSfid = useSelector(selectSfid)

  const isPosOrMasproximo = useSelector(selectIsPosOrMasProximo)
  const isPosAgent = validateAgentProfile([profiles.pos.id])
  const isSaleAgent = sfid === agentSfid
  const posAgentHasPermissions = isPosAgent && isSaleAgent && contractIsWithinDateRange

  const downloadPermissionPdf =
    contractId && (!isPosAgent || !isPosOrMasproximo || posAgentHasPermissions)
  const downloadPermissionTxtAndWl = posAgentHasPermissions

  const downloadActions = [
    {
      label: 'Descargar contrato',
      onClick: () => downloadFromPromiseBlob(getContractPdf(contractId)),
      disabled: !downloadPermissionPdf,
    },
    {
      label: 'Descargar TXT',
      onClick: () => downloadFromPromiseTxt(id),
      disabled: !downloadPermissionTxtAndWl,
    },
    {
      label: 'Descargar WL',
      onClick: () => downloadFromPromiseWl(welcomeLetterData),
      disabled: !downloadPermissionTxtAndWl,
    },
  ]

  const svaHebe = getSvaSubscriptionType(subscription, SVA_HEBE_CODE)

  let svaHebeAdviceFields
  if (!isEmpty(svaHebe)) {
    svaHebeAdviceFields = [
      { label: 'SVA', value: 'HEBE' },
      {
        label: 'Cuota',
        value: formatMonthlyFee(applyTax(tax, getSvaHebeMonthlyFeeSubscription(subscription))),
      },
      { label: 'Duración', value: 'Para siempre' },
    ]
  }

  const hasModifications = useSelector(state => getHasModifications(state, getMsisdn(subscription)))
  const hasPortOutInfoInSub = useSelector(state => hasPortOutInfo(state, getMsisdn(subscription)))
  const [toogledPortOutShow, setToogledPortOutShow] = useState(false)

  const changedTariff = useSelector(getChangedTariff(subscription))

  const [isCollapsed, setIsCollapsed] = useState(false)

  const mobilePermanent = head(findMobilePermanents(subscription))

  const hasAgileTVInSubscription = hasAgileTV(subscription)

  const enabledTaxAgileTv = useSelector(selectEnabledTaxSvaAgileTv)

  const props = {
    subscription,
    tariffName: getCommercialName(tariff),
    feeAmountWithTax: applyTax(tax, feeAmount),
    productFixed,
    productMobile,
    downloadActions,
    download,
    downloadTxt,
    downloadWl,
    svaHebe,
    svaHebeAdviceFields,
    isElFijo,
    hasModifications,
    hasPortOutInfoInSub,
    toogledPortOutShow,
    setToogledPortOutShow,
    changedTariff,
    isCollapsed,
    setIsCollapsed,
    mobilePermanent,
    hasAgileTVInSubscription,
    taxNeeded,
    tax,
    enabledTaxAgileTv,
  }

  return <SubscriptionDetail {...props} />
}

SubscriptionDetailContainer.propTypes = {
  subscription: PropTypes.object.isRequired,
}
