import * as constants from './constants'

export const reducer = (state = {}, action) => {
  const { type, payload } = action

  switch (type) {
    case constants.FIND_OFFERS: {
      return {
        loading: true,
      }
    }
    case constants.FIND_OFFERS_SUCCESS: {
      return {
        data: payload.data,
        loading: false,
      }
    }
    case constants.FIND_OFFERS_FAILED: {
      return {
        error_code: payload.error_code,
        error_message: payload.error_message,
        loading: false,
      }
    }

    default:
      return state
  }
}
