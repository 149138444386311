import { useMemo } from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/styles'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'
import WarningIcon from '@material-ui/icons/Warning'
import { CircularProgress } from '@material-ui/core'
import { ActionAdvice } from 'components/ui'

const useStyles = props =>
  makeStyles({
    wrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    text: {
      color: props.color,
      fontSize: '14px',
      fontWeight: 'bold',
      marginLeft: '8px',
    },
    icon: {
      color: props.color,
      fontSize: '24px',
    },
    advice: {
      maxWidth: '350px',
      maxHeight: '100px',
      '& span': {
        marginLeft: '10px',
        marginRight: '10px',
      },
    },
  })

const stockTypeVisualComponents = {
  highStock: {
    color: '#3ea200',
    helpComponent: CheckCircleIcon,
    text: 'HAY STOCK',
  },
  lowStock: {
    color: '#e96800',
    helpComponent: ErrorIcon,
  },
  noStock: {
    color: '#d0021b',
    helpComponent: WarningIcon,
    text: 'NO HAY STOCK',
  },
  loadingStock: {
    color: '#4c4c4c',
    helpComponent: CircularProgress,
    text: 'CONSULTANDO STOCK',
  },
}

export const DeviceStockInfo = ({
  isLoadingStock,
  stock,
  stockHasError,
  onRetryFetchStock,
  deviceId,
}) => {
  const stockVisualComponents = useMemo(() => {
    if (stockHasError) {
      return stockTypeVisualComponents.noStock
    }

    if (isLoadingStock || stock === undefined) {
      return stockTypeVisualComponents.loadingStock
    }

    if (stock <= 0) {
      return stockTypeVisualComponents.noStock
    }

    if (stock >= 11) {
      return stockTypeVisualComponents.highStock
    }

    if (stock < 11) {
      return { ...stockTypeVisualComponents.lowStock, text: `SOLO QUEDAN ${stock} U.` }
    }

    return stockTypeVisualComponents.loadingStock
  }, [stock])

  const HelpComponent = stockVisualComponents.helpComponent
  const classes = useStyles({ color: stockVisualComponents.color })()

  return (
    <div className={classes.wrapper}>
      {!stockHasError ? (
        <>
          <HelpComponent className={classes.icon} size="24px" />
          <div className={classes.text}>{stockVisualComponents.text}</div>
        </>
      ) : (
        <div className={classes.advice}>
          <ActionAdvice
            type="error"
            message="Error obteniendo el stock del dispositivo. Por favor, inténtelo más tarde."
            onRetry={() => onRetryFetchStock(deviceId)}
          />
        </div>
      )}
    </div>
  )
}

DeviceStockInfo.propTypes = {
  isLoadingStock: PropTypes.bool,
  stock: PropTypes.number,
  stockHasError: PropTypes.bool,
  onRetryFetchStock: PropTypes.func,
  deviceId: PropTypes.string,
}
