import { useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { Typography } from '@material-ui/core'
import { TextStyles } from 'utils/text'

import {
  Modal,
  Button,
  ComposedTextField,
  ActionAdvice,
  OutlinedDivider,
  SpinnerCenter,
} from 'components/ui'
import { getProvinceFromCode, isValidPostCode } from 'utils'
import { every, isEmpty, noop, omitBy } from 'lodash'
import { isValidIBANNumber } from 'modules/Iban'
import MaskedInput from 'react-text-mask'
import { ibanMask, getChangesKey } from 'modules/customer-write'
import { selectIsPosOrMasProximo } from 'modules/Permissions'

import { CustomerWriteResultModal } from './CustomerWriteResultModal'
import { formatIBAN, formatCensuredIban } from '../../helpers/formats.helper'

let changes = {}
export const CustomerWriteModal = styled(
  ({
    className,
    openCustomerWriteModal,
    setOpenCustomerWriteModal,
    currentIban,
    billingAddress,
    isCustomerWriteLoading,
    customerWriteErrors,
    customerWrite,
    customerWriteClearError,
  }) => {
    const isPosOrMasProximo = useSelector(selectIsPosOrMasProximo)

    const { streetOne, postCode, city, stateOrProvince } = billingAddress
    const region = getProvinceFromCode(stateOrProvince)
    const [newIban, setNewIban] = useState(currentIban)
    const [newStreetOne, setNewStreetOne] = useState(streetOne)
    const [newPostCode, setNewPostCode] = useState(postCode)
    const [newCity, setNewCity] = useState(city)
    const [newRegion, setNewRegion] = useState(region)
    const [sendData, setSendData] = useState(false)
    const [showResult, setShowResult] = useState(false)
    const hasChanges = !every([
      currentIban === newIban,
      streetOne === newStreetOne,
      postCode === newPostCode,
      city === newCity,
      region === newRegion,
    ])
    const hasEmptyFields = !every([newIban, newStreetOne, newPostCode, newCity, newRegion])
    const isDisabled =
      !hasChanges || hasEmptyFields || !isValidIBANNumber(newIban) || !isValidPostCode(newPostCode)

    function onChangeIban(e) {
      const iban = e.target.value
      setNewIban(iban)
    }

    function setChanges() {
      changes.iban = currentIban !== newIban ? newIban.replace(/ /g, '') : ''
      changes.address = streetOne !== newStreetOne ? newStreetOne : ''
      changes.zipcode = postCode !== newPostCode ? newPostCode : ''
      changes.city = city !== newCity ? newCity : ''
      changes.region = postCode !== newPostCode ? newPostCode.slice(0, 2) : ''
      setSendData(true)
    }

    function refreshChanges() {
      changes = {}
    }

    function submitChanges() {
      customerWrite(omitBy(changes, isEmpty))
      setSendData('result')
      setShowResult(true)
      refreshChanges()
    }

    function showValues(key) {
      if (key === 'iban') {
        return formatIBAN(changes[key])
      }
      if (key === 'region') {
        return getProvinceFromCode(changes[key])
      }

      return changes[key]
    }

    return (
      <>
        {isCustomerWriteLoading && !customerWriteErrors && (
          <SpinnerCenter absolute style={{ marginTop: 0 }} />
        )}
        <div>
          {!isCustomerWriteLoading && !customerWriteErrors && (
            <Modal
              className={className}
              data-hook="customer-write-modal"
              isOpen={openCustomerWriteModal && !showResult}
              title="Editar datos de facturación"
              onClose={() => setOpenCustomerWriteModal(false)}>
              {!sendData && sendData !== 'result' ? (
                <div>
                  <Typography
                    {...TextStyles.subtitle2Link({
                      className: 'modal-subtitle',
                    })}>
                    <span>IBAN</span>
                  </Typography>

                  <div>
                    {isPosOrMasProximo ? (
                      <ComposedTextField
                        value={newIban}
                        label="IBAN"
                        fullWidth
                        name="iban"
                        disabled
                        censuredHelper={formatCensuredIban}
                      />
                    ) : (
                      <MaskedInput
                        className="iban-field-input"
                        data-hook="iban-field"
                        onChange={onChangeIban}
                        value={newIban}
                        name="iban"
                        mask={ibanMask}
                        render={(ref, { defaultValue, ...props }) => (
                          <ComposedTextField
                            value={defaultValue}
                            label="IBAN"
                            fullWidth
                            onChange={props.onChange}
                            name="iban"
                            inputRef={ref}
                            className={!isValidIBANNumber(newIban) ? 'iban-field-error' : ''}
                          />
                        )}
                      />
                    )}
                  </div>

                  <Typography
                    {...TextStyles.subtitle2Link({
                      className: 'modal-subtitle',
                    })}>
                    <span>Dirección de facturación</span>
                  </Typography>

                  <div>
                    <ComposedTextField
                      className="address-field-input"
                      data-hook="address-field"
                      value={newStreetOne}
                      label="Calle, Número, Piso, Letra, Escalera, etc."
                      fullWidth
                      onChange={e => setNewStreetOne(e.target.value)}
                      name="address-field"
                    />
                  </div>

                  <div className="street-two-group">
                    <ComposedTextField
                      className="postalcode-field-input"
                      data-hook="postal-code-field"
                      value={newPostCode}
                      label="C.P."
                      maxLength="5"
                      onChange={e => {
                        setNewPostCode(e.target.value)
                        setNewRegion(getProvinceFromCode(newPostCode.slice(0, 2)))
                      }}
                      name="cp-field"
                      fieldState={!isValidPostCode(newPostCode) ? 'ERROR' : null}
                    />

                    <ComposedTextField
                      className="city-field-input"
                      data-hook="city-field"
                      value={newCity}
                      label="Localidad"
                      onChange={e => setNewCity(e.target.value)}
                      name="city-field"
                    />

                    <ComposedTextField
                      className="province-field-input"
                      data-hook="province-field"
                      value={newRegion}
                      readOnly
                      label="Provincia"
                      onChange={() => noop()}
                      name="province-field"
                    />
                  </div>

                  <OutlinedDivider className="dotted-divider" />

                  <Button className="button" onClick={() => setChanges()} disabled={isDisabled}>
                    Continuar
                  </Button>

                  <Button
                    secondary
                    onClick={() => {
                      refreshChanges()
                      setOpenCustomerWriteModal(false)
                    }}>
                    Cancelar
                  </Button>
                </div>
              ) : (
                <div>
                  <Typography
                    {...TextStyles.header({
                      className: 'modal-subtitle confirm-changes',
                    })}>
                    <span>Se van a realizar los siguientes cambios</span>
                  </Typography>

                  {Object.keys(changes).map(
                    key =>
                      !isEmpty(changes[key]) && (
                        <ActionAdvice
                          className="m-y-12"
                          type="info"
                          items={[
                            {
                              label: `${getChangesKey(key)}`,
                              value: showValues(key),
                            },
                          ]}
                        />
                      ),
                  )}

                  <OutlinedDivider className="dotted-divider" />

                  <Button className="button" onClick={() => submitChanges()} disabled={isDisabled}>
                    Continuar
                  </Button>

                  <Button secondary onClick={() => setSendData(false)}>
                    Volver Atrás
                  </Button>
                </div>
              )}
            </Modal>
          )}

          {!isCustomerWriteLoading && (
            <CustomerWriteResultModal
              errors={customerWriteErrors}
              data-hook="customer-write-modal-result"
              isOpen={openCustomerWriteModal && showResult}
              onClose={() => {
                setOpenCustomerWriteModal(false)
                customerWriteClearError()
              }}
            />
          )}
        </div>
      </>
    )
  },
)`
  .modal-subtitle {
    margin: 15px 0;
  }
  .iban-field-input {
    width: 45%;
  }
  .iban-field-error .StyledInput.StyledInput.StyledInput {
    fieldset {
      border-color: red;
    }
  }
  .iban-field-error .StyledInputLabel {
    color: red;
  }
  .address-field-input {
    width: 100%;
    margin-top: 5px;
    margin-bottom: 15px;
  }
  .street-two-group {
    margin: 15px 0;
    display: flex;
  }
  .postalcode-field-input {
    width: 17%;
  }
  .city-field-input {
    width: 38%;
    margin: 0 28px;
  }
  .province-field-input {
    width: 38%;
  }
  .dotted-divider {
    margin: 25px 0;
  }
  .button {
    margin-right: 10px;
  }
  .confirm-changes {
    font-size: 1em;
  }
`

CustomerWriteModal.propTypes = {
  className: PropTypes.string,
  gotCoverageData: PropTypes.func,
  isCustomerWriteLoading: PropTypes.bool,
  customerWriteErrors: PropTypes.object,
  customerWrite: PropTypes.func,
  customerWriteClearError: PropTypes.func,
}
