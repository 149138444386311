import {
  concat,
  get,
  chain,
  find,
  isEmpty,
  sortBy,
  uniqWith,
  filter,
  zipWith,
  drop,
  dropRight,
  reduce,
  map,
  mapValues,
  flatMap,
  maxBy,
  startsWith,
  some,
  isArray,
  head,
  isNil,
  compact,
  isObject,
  uniq,
  union,
} from 'lodash'

import {
  getDate,
  addMonths,
  isWithinInterval,
  isSameMonth,
  parseISO,
  differenceInDays,
  format,
  differenceInCalendarMonths,
  startOfDay,
  startOfMonth,
  differenceInYears,
  differenceInMonths,
  addYears,
  isPast,
  getDaysInMonth,
  addDays,
  isEqual,
  areIntervalsOverlapping,
  endOfMonth,
  min,
  max,
  endOfDay,
  differenceInSeconds,
  isFuture,
  isAfter,
  isValid,
  getMinutes,
  isBefore,
  isSameDay,
} from 'date-fns'

import {
  getNormInterval,
  lengthInDaysOfNomInterval,
  intersection,
  isValidInterval,
} from 'utils/dates'
import { formatFee, isMobilePhoneNumber, mbToGb, formatNumberWithComma } from 'utils'

import { applyTax } from 'services/taxes'
import { selectTariffsApigeeById, getMonthlyFee } from 'modules/tariffs/store-apigee'
import { is2pTariff, is2pFreeLineTariff } from 'modules/tariffs/store-apigee/tariffs-apigee.helpers'
import { NEBA_INFIX, SMSPrice } from 'modules/tariffs'
import { getIsPercentage } from 'services/customer-360/helpers'
import {
  SVA_HEBE_CODE,
  SVA_SMARTHOME_CODE,
  SVA_WIFI_TOTAL_01,
  SVA_WIFI_TOTAL_02,
  DISCOUNTS_THAT_APPLY_WHOLE_MONTH,
  MAIN_DUO_DISCOUNT_ID,
  DISCOUNTS_THAT_NOT_APPPLY_PRORRATED,
  SVA_AGILE_TV_REGEX,
} from 'modules/offers-configuration/constants'

import { isCrossSellDiscount } from 'modules/Discounts'

import { selectBonusById } from 'modules/bonuses'

import { MULTISIM_LINE } from 'services/customer-360/constants'
import {
  BASE_FEE_NAME,
  FIXED_PRODUCT_NAME,
  MOBILE_PRODUCT_NAME,
  OTHER_SERVICE_PRODUCT_NAME,
  PRODUCT_TYPE_TO_ITEM_CODE,
  CONNECTION_SPEC,
  ADSL_TYPE,
  FIBER_TYPE,
  RETENTION_BONUS_PREFIX,
  FIDELITY_BONUS_PREFIX,
  TARIFF_CHANGE_EVENT_CODE,
  CANCELLED_TARIFF_CHANGE_STATUS_CODE,
  ONGOING_TARIFF_CHANGE_STATUS_CODE,
  PREPAID_CHARACTERISTIC_NAME,
  POSTPAID_CHARACTERISTIC_NAME,
  STC_RULES,
  MIN_UNLIMITED_MINUTES,
  MIN_UNLIMITED_GB,
  MIN_UNLIMITED_MINUTES_F2F,
  MIN_UNLIMITED_MINUTES_F2M,
  PERMANENT_ROUTER_IDS,
  PERMANENT_TV_IDS,
  PERMANENT_DISCOUNT_IDS_PREFIX,
  PERMANENT_BONUS_IDS_PREFIX,
  BONUS_CODES,
  CALL_RECORD_SORT_NUMBERS,
  CALL_RECORD_INTERNATIONAL_CALL,
  CALL_RECORD_PREFIX_800,
  CALL_RECORD_PREFIX_900,
  CALL_RECORD_TYPE_CALL,
  CALL_RECORD_TYPE_SMS,
  CALL_RECORD_TYPE_GPRS,
  PRORRATED_FINANCIAL_ID,
  DUO_PRINCIPAL_TARIFF_ID,
  DOCTORGO_DISCOUNTS,
} from './constants'

export const formatRange = end => {
  let date = startOfDay(Date.now())
  const years = differenceInYears(parseISO(end), date)
  date = addYears(date, years)
  const months = differenceInMonths(parseISO(end), date)
  date = addMonths(date, months)
  const days = differenceInDays(parseISO(end), date)

  const dateUnitsPieces = {
    days: days > 0 ? `${days} día${days > 1 ? 's' : ''}` : '',
    months: months > 0 ? `${months} mes${months > 1 ? 'es' : ''}` : '',
    years: years > 0 ? `${years} año${years > 1 ? 's' : ''}` : '',
  }

  const monthsAndDaysStr = filter([dateUnitsPieces.months, dateUnitsPieces.days]).join(' y ')

  return filter([dateUnitsPieces.years, monthsAndDaysStr]).join(', ')
}

export const getDateFromMonths = months => addMonths(new Date(), Number(months))

const BUNDLE_FEE = 6 // this bundles/bonus has always same price = 6€

export const findFee = sub => sub?.productPrice?.find(p => p.name === BASE_FEE_NAME)

const findProduct = (productSpecs, type) => productSpecs.find(p => p.name === type)

const isActive = (valid_from, valid_to) => {
  let isWithin = false

  try {
    isWithin = isWithinInterval(Date.now(), {
      start: parseISO(valid_from),
      end: parseISO(valid_to),
    })
  } catch {
    console.warn('Invalid interval:', valid_from, valid_to)
  }

  return isWithin
}
export const getProducts = sub => get(sub, 'productsSpecifications', [])

export function getProductsNumbers(sub) {
  return chain(getProducts(sub)).map('id').value()
}

export const getProductById = (sub, productId) =>
  chain(sub).get('productsSpecifications').find({ id: productId }).value()

export const findFixedProduct = sub => findProduct(getProducts(sub), FIXED_PRODUCT_NAME)

export const findMobileProduct = sub => findProduct(getProducts(sub), MOBILE_PRODUCT_NAME)

export const findOtherServiceProduct = sub =>
  findProduct(getProducts(sub), OTHER_SERVICE_PRODUCT_NAME)

const findPermanentForProduct = (type, permanents) => {
  const productPermanents = permanents.filter(
    p => get(p, 'item.group.code') === PRODUCT_TYPE_TO_ITEM_CODE[type],
  )
  if (!productPermanents.length) {
    return undefined
  }

  return maxBy(productPermanents, p => parseISO(p.validFor.startDateTime))
}

export const findFixedPermanent = sub => {
  const permanent = findPermanentForProduct(FIXED_PRODUCT_NAME, sub.contracts.permanents)
  return get(permanent, 'residualLength', 0) > 0 ? permanent : undefined
}

export const findMobilePermanent = sub => {
  const permanent = findPermanentForProduct(MOBILE_PRODUCT_NAME, sub.contracts.permanents)
  return get(permanent, 'residualLength', 0) > 0 ? permanent : undefined
}

const findPermanentsForProduct = (type, permanents) =>
  permanents
    .filter(p => p.item.group.code === PRODUCT_TYPE_TO_ITEM_CODE[type])
    .filter(p => get(p, 'residualLength', 0) > 0)

const findPermanentsForProductByEndTimeValidation = (type, permanents) =>
  permanents
    .filter(p => p.item.group.code === PRODUCT_TYPE_TO_ITEM_CODE[type])
    .filter(p => !isPast(new Date(get(p, 'validFor.endDateTime'))))

export const getAllPenalties = sub => {
  const fromPermanents = chain(sub)
    .get('contracts')
    .get('permanents')
    .filter(obj => obj.residualLength !== 0)
    .reduce((result, item) => result + get(item, 'fees.penalty'), 0)
    .value()
  return parseFloat(fromPermanents)
}

export const correctResidualLengthCalc = (term, sub) => {
  const start = parseISO(chain(term).get('validFor').get('startDateTime').value())
  const end = parseISO(chain(term).get('validFor').get('endDateTime').value())
  const totalPenalties = getAllPenalties(sub)
  const totalDays = differenceInDays(end, start)
  const ratePerDay = totalDays !== 0 ? totalPenalties / totalDays : 0
  const pendingDaysFromNow = differenceInDays(end, Date.now())
  const pendingInMonths = Math.ceil(pendingDaysFromNow / 30) // differenceInMonths(end, Date.now()) doesn't ceil result

  const pendingPermanents = chain(sub)
    .get('contracts')
    .get('permanents')
    .filter(obj => obj.residualLength !== 0)
    .reduce((result, item) => result + get(item, 'fees.pending'), 0)
    .value()

  return { totalDays, ratePerDay, pendingDaysFromNow, pendingInMonths, pendingPermanents }
}

const findFixedPermanentsWithCorrectResidualLength = (type, sub) => {
  const permanents = chain(sub)
    .get('contracts')
    .get('permanents')
    .filter(p => p.item.group.code === PRODUCT_TYPE_TO_ITEM_CODE[type])
    .filter(p => get(p, 'residualLength', 0) > 0)
    .value()

  return permanents.map(perm => {
    const { pendingInMonths } = correctResidualLengthCalc(perm, sub)
    const date = startOfDay(Date.now())
    return { ...perm, residualLength: formatRange(addMonths(date, pendingInMonths).toISOString()) }
  })
}

export const findFixedPermanents = sub =>
  findPermanentsForProduct(FIXED_PRODUCT_NAME, sub.contracts.permanents)

export function findRouterPermanents(sub) {
  if (sub) {
    const fixedPermanents = findPermanentsForProduct(FIXED_PRODUCT_NAME, sub.contracts.permanents)

    return filter(fixedPermanents, permanent =>
      some(PERMANENT_ROUTER_IDS, routerId => startsWith(permanent.id, routerId)),
    )
  }
  return []
}

export function findTVPermanents(sub) {
  if (sub) {
    const fixedPermanents = findPermanentsForProduct(FIXED_PRODUCT_NAME, sub.contracts.permanents)

    return filter(fixedPermanents, permanent =>
      some(PERMANENT_TV_IDS, tvId => startsWith(permanent.id, tvId)),
    )
  }
  return []
}

export const findDiscountPermanents = sub =>
  chain(sub)
    .get('contracts')
    .get('permanents')
    .filter(p => some(PERMANENT_DISCOUNT_IDS_PREFIX, discountId => startsWith(p.id, discountId)))
    .filter(p => get(p, 'fees.pending') > 0)
    .value()

export const findBonusPermanents = sub =>
  chain(sub)
    .get('contracts')
    .get('permanents')
    .filter(p => some(PERMANENT_BONUS_IDS_PREFIX, bonusId => startsWith(p.id, bonusId)))
    .filter(p => get(p, 'fees.pending') > 0)
    .value()

export const findMobilePermanents = sub =>
  findPermanentsForProduct(MOBILE_PRODUCT_NAME, sub.contracts.permanents)

export const findMobilePermantsByEndTimeValidation = sub =>
  findPermanentsForProductByEndTimeValidation(MOBILE_PRODUCT_NAME, sub.contracts.permanents)

export const findOtherServicesPermanents = sub =>
  findPermanentsForProduct(OTHER_SERVICE_PRODUCT_NAME, sub.contracts.permanents)

export const isConvergentSubscription = sub => {
  const products = getProducts(sub)
  const productsKey = products.map(({ id, name }) => `${id}_${name}`)
  const productsKeyWithoutStc = uniq(productsKey)

  return productsKeyWithoutStc.length > 1
}

export const isMobileOnlySubscription = subscription => subscription?.categories === '1P'

export const getBonuses = sub => get(sub, 'bundle_upsells', []) || []

const isRetentionOrFidelityBonus = bonus =>
  bonus.id.startsWith(RETENTION_BONUS_PREFIX) || bonus.id.startsWith(FIDELITY_BONUS_PREFIX)

export const getRetentionAndFidelityBonuses = sub =>
  getBonuses(sub).filter(isRetentionOrFidelityBonus)

export const getDiscounts = sub => get(sub, 'charges', []) || []

export const getConnectionType = sub => {
  const product = findFixedProduct(sub)
  if (!product) {
    return undefined
  }
  const productSpecs = product.productSpecCharacteristic || []
  return productSpecs.find(spec => spec.name === CONNECTION_SPEC)
}

export const isAdsl = sub => {
  const connectionType = getConnectionType(sub)
  return Boolean(connectionType && connectionType.value === ADSL_TYPE)
}

export const isFtth = sub => {
  const connectionType = getConnectionType(sub)
  return Boolean(connectionType && connectionType.value === FIBER_TYPE)
}

export const hasInternetConnection = sub => isAdsl(sub) || isFtth(sub)

export const getSubscriptionTariffId = sub => get(sub, 'type', '')

export const isNeba = sub => isFtth(sub) && getSubscriptionTariffId(sub).includes(NEBA_INFIX)

const filterTariffChangeEvents = sub =>
  get(sub, 'events', []).filter(e => e.id === TARIFF_CHANGE_EVENT_CODE)

const findPendingTariffChangeEvent = sub =>
  filterTariffChangeEvents(sub).find(e => e.event_status.code === ONGOING_TARIFF_CHANGE_STATUS_CODE)

export const hasTariffChangeEvents = sub => !isEmpty(filterTariffChangeEvents(sub))

export const hasPendingTariffChange = sub => Boolean(findPendingTariffChangeEvent(sub))

export const maxSTCReached = sub => {
  const isMobile = isMobilePhoneNumber(sub.productNumber)
  const maxChangesAllowed = isMobile ? STC_RULES.MOBILE : STC_RULES.FIXED
  const now = Date.now()
  const nowISODate = parseISO(new Date(now).toISOString())

  const changesMade = filterTariffChangeEvents(sub).filter(
    e =>
      e.event_status.code !== CANCELLED_TARIFF_CHANGE_STATUS_CODE &&
      isSameMonth(parseISO(e.activated_at), nowISODate),
  )
  return changesMade.length >= maxChangesAllowed
}

export const getNewTariffId = sub => get(findPendingTariffChangeEvent(sub), 'new_tariff')

export const getPaymentType = sub => {
  if (isEmpty(sub)) {
    return undefined
  }
  const postpaidCharacteristic = get(
    find(get(sub, 'productCharacteristic', []), { name: POSTPAID_CHARACTERISTIC_NAME }),
    'value',
  )
  const isPostpaid = postpaidCharacteristic === 'true'
  return isPostpaid ? POSTPAID_CHARACTERISTIC_NAME : PREPAID_CHARACTERISTIC_NAME
}

export const isFixedLine = prod => get(prod, 'name') === FIXED_PRODUCT_NAME

export const getFixedLineNumber = sub => {
  const productNumber = get(findFixedProduct(sub), 'id')
  return isMobilePhoneNumber(productNumber) ? undefined : productNumber
}

export const getSubscriptionId = subscription => subscription.subscription_id

export const getMsisdn = subscription => get(subscription, 'id')

export const getMobileLineNumber = sub => {
  const productNumber = get(findMobileProduct(sub), 'id')
  return isMobilePhoneNumber(productNumber) ? productNumber : undefined
}

function findFinancialsForProduct(type, financials) {
  const productFinancials = financials.filter(
    p => get(p, 'item.group.code') === PRODUCT_TYPE_TO_ITEM_CODE[type],
  )
  if (!productFinancials.length) {
    return undefined
  }

  return productFinancials.reduce(
    (acc, p) =>
      new Date(p.validFor.startDateTime) > new Date(acc.validFor.startDateTime) ? p : acc,
    { validFor: { startDateTime: null } },
  )
}

export function findMobileFinancials(sub) {
  const financials = findFinancialsForProduct(MOBILE_PRODUCT_NAME, sub.contracts.financials)
  return get(financials, 'residualLength', 0) > 0 ? financials : undefined
}

export function getMobileCancellationCost(sub) {
  const financials = findMobileFinancials(sub)

  const quotasLeft = get(financials, 'fees.monthly', 0) * get(financials, 'residualLength', 0)
  const residual = get(financials, 'fees.residual', 0)

  return quotasLeft + residual
}

export function getUsages(sub, productType = MOBILE_PRODUCT_NAME) {
  return chain(sub).get('productUsage.usages').find({ name: productType }).value() || {}
}

export function getUsagesMobile(sub) {
  return getUsages(sub, MOBILE_PRODUCT_NAME)
}

export function getUsagesFixed(sub) {
  return getUsages(sub, FIXED_PRODUCT_NAME)
}

export function getUsageCharacteristic(sub, productType = MOBILE_PRODUCT_NAME) {
  const usages = getUsages(sub, productType)
  return get(usages, 'usageCharacteristic', [])
}

export function getUsageCharacteristicMobile(sub) {
  return getUsageCharacteristic(sub, MOBILE_PRODUCT_NAME)
}

export function getUsageCharacteristicFixed(sub) {
  return getUsageCharacteristic(sub, FIXED_PRODUCT_NAME)
}

export const getBundleUsages = sub => {
  return chain(sub).get('bundle_usages').head().value()
}

export const getConsumedDataInMbMobile = sub => {
  const bundle = getBundleUsages(sub)
  return parseFloat(chain(bundle).get('data_total_usage').value() || 0)
}

export const getTotalDataInMbMobile = sub => {
  const bundle = getBundleUsages(sub)
  return parseFloat(chain(bundle).get('data_total_amount').value() || 0)
}

const getAllBundleUsages = sub => chain(sub).get('bundle_usages').value()

const getDataBundle = (bundle, paramToSearch) => parseFloat(get(bundle, paramToSearch, 0))

const getTotalWithoutExtrasCallsConsumedWithBundle = (sub, subscriptionType) =>
  get(sub, 'call_detail_records', [])
    .filter(
      ({ subscription_type, event_type, call_case }) =>
        subscription_type === subscriptionType &&
        event_type !== CALL_RECORD_TYPE_GPRS &&
        ![CALL_RECORD_PREFIX_800, CALL_RECORD_PREFIX_900, CALL_RECORD_SORT_NUMBERS].includes(
          call_case,
        ),
    )
    .reduce((total, elem) => get(elem, 'total_without_vat') + total, 0)

const getPremiumCallsConsumedWithBundle = (sub, subscriptionType) =>
  get(sub, 'call_detail_records', [])
    .filter(
      ({ subscription_type, call_case }) =>
        subscription_type === subscriptionType &&
        ((call_case >= CALL_RECORD_PREFIX_800 && call_case <= CALL_RECORD_PREFIX_900) ||
          call_case === CALL_RECORD_INTERNATIONAL_CALL),
    )
    .reduce((total, elem) => get(elem, 'total_without_vat') + total, 0)

const getCallsConsumedWithBundle = (sub, subscriptionType) =>
  get(sub, 'call_detail_records', [])
    .filter(
      ({ subscription_type, event_type, bundle_id, call_case }) =>
        subscription_type === subscriptionType &&
        event_type === CALL_RECORD_TYPE_CALL &&
        bundle_id === '' &&
        (call_case === CALL_RECORD_PREFIX_800 || call_case === CALL_RECORD_PREFIX_900),
    )
    .reduce((total, elem) => get(elem, 'total_without_vat') + total, 0)

const getInternationalCallsConsumedWithBundle = (sub, subscriptionType) =>
  get(sub, 'call_detail_records', [])
    .filter(
      ({ subscription_type, event_type, call_case }) =>
        subscription_type === subscriptionType &&
        event_type === CALL_RECORD_TYPE_CALL &&
        call_case === CALL_RECORD_SORT_NUMBERS,
    )
    .reduce((total, elem) => get(elem, 'total_without_vat') + total, 0)

const getOthersConsumedWithBundle = (sub, subscriptionType) =>
  get(sub, 'call_detail_records', [])
    .filter(
      ({ subscription_type, event_type }) =>
        subscription_type === subscriptionType &&
        ![CALL_RECORD_TYPE_CALL, CALL_RECORD_TYPE_SMS, CALL_RECORD_TYPE_GPRS].includes(event_type),
    )
    .reduce((total, elem) => get(elem, 'total_without_vat') + total, 0)

const getSmsConsumedAtCostWithBundle = (sub, subscriptionType) =>
  get(sub, 'call_detail_records', []).filter(
    ({ subscription_type, event_type, total_without_vat }) =>
      subscription_type === subscriptionType &&
      event_type === CALL_RECORD_TYPE_SMS &&
      total_without_vat !== 0,
  ).length

const hasUnlimitedDataMobileWithBundle = bundle => {
  const totalDataInMbMobile = getDataBundle(bundle, 'data_total_amount')

  return totalDataInMbMobile >= MIN_UNLIMITED_GB * 1024
}

const getTotalDataConsumedWithBundle = (sub, subscriptionType) =>
  get(sub, 'call_detail_records', [])
    .filter(
      ({ subscription_type, event_type }) =>
        subscription_type === subscriptionType && event_type === CALL_RECORD_TYPE_GPRS,
    )
    .reduce((total, elem) => get(elem, 'total_without_vat') + total, 0)

const getSubscriptionCallsWithBundle = (sub, subscriptionType) =>
  chain(sub)
    .get('productsSpecifications')
    .find({
      name: 'mobile',
      subscriptionType,
    })
    .get('productSpecCharacteristic')
    .find({
      name: 'calls',
    })
    .value()

const getSubscriptionCallsInMinWithBundle = (sub, subscriptionType) =>
  +get(getSubscriptionCallsWithBundle(sub, subscriptionType), 'value')

const hasMobileUnlimitedMinWithBundle = (sub, subscriptionType) =>
  getSubscriptionCallsInMinWithBundle(sub, subscriptionType) >= MIN_UNLIMITED_MINUTES

const getUsagesWithBundle = (sub, subscriptionType, productType) =>
  chain(sub)
    .get('productUsage.usages')
    .find({ name: productType, subscriptionType })
    .defaultTo({})
    .value()

const getUsageCharacteristicWithBundle = (sub, subscriptionType, productType) => {
  const usages = getUsagesWithBundle(sub, subscriptionType, productType)

  return get(usages, 'usageCharacteristic', [])
}

const getTotalExtrasByProductWithBundle = (sub, subscriptionType, productType) => {
  const characteristic = getUsageCharacteristicWithBundle(sub, subscriptionType, productType)

  return parseFloat(
    chain(characteristic).find({ name: 'total-amount' }).get('value').defaultTo(0).value(),
  )
}

const getTotalExtrasMobileWithBundle = (sub, subscriptionType) =>
  getTotalExtrasByProductWithBundle(sub, subscriptionType, MOBILE_PRODUCT_NAME)

const getTotalExtrasFixedWithBundle = (sub, subscriptionType) =>
  getTotalExtrasByProductWithBundle(sub, subscriptionType, FIXED_PRODUCT_NAME)

const getUsageCharacteristicMobileWithBundle = (sub, subscriptionType) =>
  getUsageCharacteristicWithBundle(sub, subscriptionType, MOBILE_PRODUCT_NAME)

const getSmsConsumedWithBundleWithBundle = (sub, subscriptionType) => {
  const characteristic = getUsageCharacteristicMobileWithBundle(sub, subscriptionType)

  return parseFloat(
    chain(characteristic).find({ name: 'sms-consumed' }).get('value').defaultTo(0).value(),
  )
}

const getSmsAmountConsumedWithBundle = (sub, subscriptionType) => {
  const characteristic = getUsageCharacteristicMobileWithBundle(sub, subscriptionType)

  return parseFloat(
    chain(characteristic).find({ name: 'sms-amount' }).get('value').defaultTo(0).value(),
  )
}

const getDateBundle = ({ usages }) => chain(usages).head().get('valid_from').value()

// TODO: esto esta por el OPIT-266888 - cuando se cierre se tiene que quitar - Mbarranc
const getTypeQvantel = ({ subscription_type, usages }) => {
  if (isEmpty(usages)) {
    return subscription_type
  }

  const usage = usages.find(({ type }) => ['base', 'fixed'].includes(type))
  const compoundNameArray = !isEmpty(usage) && get(usage, 'name').split('_')
  const subscriptionTypeQvantel = !isEmpty(compoundNameArray)
    ? compoundNameArray[0]
    : subscription_type

  return subscriptionTypeQvantel
}

export const extractAllDataMobileConsumption = sub => {
  const bundles = getAllBundleUsages(sub)

  return chain(bundles)
    .map(bundle => {
      const subscriptionType = getTypeQvantel(bundle)

      const consumption = {
        type: subscriptionType,
        totalData: getDataBundle(bundle, 'data_total_amount'),
        consumedData: getDataBundle(bundle, 'data_total_usage'),
        totalCallsTariff: getSubscriptionCallsInMinWithBundle(sub, subscriptionType),
        consumedCallsTariff: getDataBundle(bundle, 'voice_total_usage'),
        consumedCallsWithoutExtras: getTotalWithoutExtrasCallsConsumedWithBundle(
          sub,
          subscriptionType,
        ),
        totalExtrasMobile: getTotalExtrasMobileWithBundle(sub, subscriptionType),
        totalExtrasFixed: getTotalExtrasFixedWithBundle(sub, subscriptionType),
        callsConsumed: getCallsConsumedWithBundle(sub, subscriptionType),
        premiumCallsConsumed: getPremiumCallsConsumedWithBundle(sub, subscriptionType),
        internationalCallsConsumed: getInternationalCallsConsumedWithBundle(sub, subscriptionType),
        othersConsumed: getOthersConsumedWithBundle(sub, subscriptionType),
        smsConsumed: getSmsConsumedWithBundleWithBundle(sub, subscriptionType),
        smsConsumedAtCost: getSmsConsumedAtCostWithBundle(sub, subscriptionType),
        smsAmountConsumed: getSmsAmountConsumedWithBundle(sub, subscriptionType),
        hasUnlimitedGigas: hasUnlimitedDataMobileWithBundle(bundle),
        hasUnlimitedMin: hasMobileUnlimitedMinWithBundle(sub, subscriptionType),
        totalDataConsumed: getTotalDataConsumedWithBundle(sub, subscriptionType),
      }

      const description = chain(sub.productsSpecifications)
        .find({ subscriptionType })
        .get('description')
        .defaultTo('')
        .value()

      const validFrom = getDateBundle(bundle)

      return {
        ...consumption,
        description,
        validFrom,
      }
    })
    .orderBy('validFrom', 'desc')
    .value()
}

export const getResetDate = sub => {
  const bundle = getBundleUsages(sub)
  return chain(bundle).get('usages').head().get('reset_date').value()
}

export function hasUnlimitedDataMobile(sub) {
  return getTotalDataInMbMobile(sub) >= MIN_UNLIMITED_GB * 1024
}

export const getTariffCallsBundle = sub => {
  const bundle = getBundleUsages(sub)
  return parseFloat(
    chain(bundle)
      .get('usages')
      .filter({ dial_type: 4 })
      .maxBy('total_amount')
      .get('total_amount')
      .value() || 0,
  )
}

export function getOthersConsumed(sub) {
  return get(sub, 'call_detail_records', [])
    .filter(
      item =>
        item.event_type !== CALL_RECORD_TYPE_CALL &&
        item.event_type !== CALL_RECORD_TYPE_SMS &&
        item.event_type !== CALL_RECORD_TYPE_GPRS,
    )
    .reduce((total, elem) => get(elem, 'total_without_vat') + total, 0)
}

export function getTotalExtrasByProduct(sub, productType = MOBILE_PRODUCT_NAME) {
  const charac = getUsageCharacteristic(sub, productType)
  return parseFloat(chain(charac).find({ name: 'total-amount' }).get('value').value() || 0)
}

export function getTotalExtrasFixed(sub) {
  return getTotalExtrasByProduct(sub, FIXED_PRODUCT_NAME)
}

export function getCallsConsumed(sub) {
  return get(sub, 'call_detail_records', [])
    .filter(item => item.event_type === CALL_RECORD_TYPE_CALL && item.bundle_id === '')
    .filter(
      item =>
        item.call_case !== CALL_RECORD_SORT_NUMBERS &&
        (item.call_case === CALL_RECORD_PREFIX_800 || item.call_case === CALL_RECORD_PREFIX_900),
    )
    .reduce((total, elem) => get(elem, 'total_without_vat') + total, 0)
}
export function getInternationalCallsConsumed(sub) {
  return get(sub, 'call_detail_records', [])
    .filter(
      item =>
        item.event_type === CALL_RECORD_TYPE_CALL && item.call_case === CALL_RECORD_SORT_NUMBERS,
    )
    .reduce((total, elem) => get(elem, 'total_without_vat') + total, 0)
}
export function getPremiumCallsConsumed(sub) {
  return get(sub, 'call_detail_records', [])
    .filter(
      item =>
        (item.call_case >= CALL_RECORD_PREFIX_800 && item.call_case <= CALL_RECORD_PREFIX_900) ||
        item.call_case === CALL_RECORD_INTERNATIONAL_CALL,
    )
    .reduce((total, elem) => get(elem, 'total_without_vat') + total, 0)
}

export function getTotalWithoutExtrasCallsConsumed(sub) {
  return get(sub, 'call_detail_records', [])
    .filter(
      item =>
        ![CALL_RECORD_PREFIX_800, CALL_RECORD_PREFIX_900, CALL_RECORD_SORT_NUMBERS].includes(
          item.call_case,
        ) && item.event_type !== CALL_RECORD_TYPE_GPRS,
    )
    .reduce((total, elem) => get(elem, 'total_without_vat') + total, 0)
}

export function getTotalDataConsumed(sub) {
  return get(sub, 'call_detail_records', [])
    .filter(item => item.event_type === CALL_RECORD_TYPE_GPRS)
    .reduce((total, elem) => get(elem, 'total_without_vat') + total, 0)
}

export function getTotalExtrasMobile(sub) {
  return getTotalExtrasByProduct(sub, MOBILE_PRODUCT_NAME)
}

export const getTotalExtras = sub => {
  return getTotalExtrasMobile(sub) + getTotalExtrasFixed(sub) + getOthersConsumed(sub)
}

export const getSmsConsumed = sub => {
  const charac = getUsageCharacteristicMobile(sub)
  return parseFloat(chain(charac).find({ name: 'sms-consumed' }).get('value').value() || 0)
}
export const getSmsConsumedAtCost = sub => {
  return get(sub, 'call_detail_records', []).filter(
    item => item.event_type === CALL_RECORD_TYPE_SMS && get(item, 'total_without_vat') !== 0,
  ).length
}

export const getSmsAmountConsumed = sub => {
  const charac = getUsageCharacteristicMobile(sub)
  return parseFloat(chain(charac).find({ name: 'sms-amount' }).get('value').value() || 0)
}

export const getCallDetailRecords = sub => get(sub, 'call_detail_records', [])

export const getSubscriptionCalls = sub => {
  return chain(sub)
    .get('productsSpecifications')
    .find({
      name: 'mobile',
    })
    .get('productSpecCharacteristic')
    .find({
      name: 'calls',
    })
    .value()
}
export const getSubscriptionCallsInMin = sub => {
  return +get(getSubscriptionCalls(sub), 'value')
}

export function hasMobileUnlimitedMin(sub) {
  return getSubscriptionCallsInMin(sub) >= MIN_UNLIMITED_MINUTES
}

export const getTotalData = sub =>
  chain(sub)
    .get('productsSpecifications')
    .find({
      name: 'mobile',
    })
    .get('productSpecCharacteristic')
    .find({
      name: 'data',
    })
    .value()

export const getResidualPermanents = sub => {
  const endDateTime = chain(sub)
    .get('contracts')
    .get('permanents')
    .find({ item: { group: { name: 'fixed' } } })
    .get('validFor')
    .get('endDateTime')
    .value()

  return endDateTime && !isPast(new Date(endDateTime)) ? formatRange(endDateTime) : '-'
}

export const getPermanentTerminals = sub =>
  chain(sub)
    .get('contracts')
    .get('permanents')
    .filter({ item: { group: { name: 'Terminals' } } })
    .filter(obj => obj.residualLength !== 0)
    .value()

export const getTerminal = sub =>
  chain(sub)
    .get('contracts')
    .get('permanents')
    .filter({ item: { group: { name: 'Terminals' } } })
    .filter(obj => obj.residualLength !== 0)
    .first()
    .value()

export const getFinancialTerminal = sub =>
  chain(sub)
    .get('contracts')
    .get('financials')
    .filter({ item: { group: { name: 'Terminals' } } })
    .filter(obj => obj.residualLength !== 0)
    .first()
    .value()

export const getFinancialTerminals = sub => {
  const terminals = chain(sub)
    .get('contracts')
    .get('financials')
    .filter({ item: { group: { name: 'Terminals' } } })
    .filter(obj => obj.residualLength !== 0)
    .value()

  return terminals
}

const formatTerminalResidualLengthToString = (term, sub) => {
  const { pendingInMonths } = correctResidualLengthCalc(term, sub)
  const date = startOfDay(Date.now())

  return { ...term, residualLength: formatRange(addMonths(date, pendingInMonths).toISOString()) }
}

export const getFinancialAndPermanencyTerminals = sub => {
  const finances = getFinancialTerminals(sub)
  const permanencies = getPermanentTerminals(sub)

  return map(finances, finance => ({
    finance,
    permanency: find(permanencies, permanency => finance?.item.code === permanency?.item.code),
  }))
}

export const getVas = sub => {
  const vasContracts = chain(sub)
    .get('vasContracts')
    .filter(obj => isActive(get(obj, 'validFrom'), get(obj, 'validTo')))
    .value()
  return vasContracts
}

const getIsNewAgileTv = ({ contractTypeId, enabledTaxAgileTv }) =>
  SVA_AGILE_TV_REGEX.test(contractTypeId) && enabledTaxAgileTv

export const getVasWithTax = (sub, tax, taxNeeded = true, enabledTaxAgileTv) =>
  getVas(sub).map(sva => {
    // NOTE: add tax for SVA HEBE, SMART HOME and WIFI TOTAL because is received with tax excluded, but other SVAs are received with tax included
    const monthlyFee = get(sva, 'monthlyFee', 0)
    const contratTypeId = get(sva, 'contractTypeId', null)
    const monthlyFeeWithTax =
      (taxNeeded &&
        [SVA_HEBE_CODE, SVA_SMARTHOME_CODE, SVA_WIFI_TOTAL_01, SVA_WIFI_TOTAL_02].includes(
          contratTypeId,
        )) ||
      getIsNewAgileTv({ ...sva, enabledTaxAgileTv })
        ? applyTax(tax, monthlyFee)
        : monthlyFee

    return { ...sva, monthlyFee: monthlyFeeWithTax }
  })

export const getAgileTVFee = ({ vasContracts }) =>
  chain(vasContracts)
    .find(
      ({ validFrom, validTo, contractTypeId }) =>
        isActive(validFrom, validTo) && SVA_AGILE_TV_REGEX.test(contractTypeId),
    )
    .value()

export const getAgileTvContract = (sub, tax, taxNeeded, enabledTaxAgileTv) =>
  getAgileTVFee({ vasContracts: getVasWithTax(sub, tax, taxNeeded, enabledTaxAgileTv) })

export const hasAgileTV = sub => !!getAgileTVFee(sub)

export const getTerminalsFinancials = sub => {
  const today = Date.now()

  const terminals = chain(sub)
    .get('contracts')
    .get('financials')
    .filter({ item: { group: { name: 'Terminals' } } })
    .filter(financial => isAfter(parseISO(financial.validFor.endDateTime), today))
    .value()
  return terminals
}

export const getTerminalFee = term => {
  return parseFloat(chain(term).get('fees').get('monthly').value() || 0)
}

export const getTerminalsFee = sub => {
  const terms = getFinancialTerminals(sub)
  return terms
    .map(term => parseFloat(chain(term).get('fees').get('monthly').value() || 0))
    .reduce((acum, item) => item + acum, 0)
}

export const getMontlhyVasFee = (sub, tax, taxNeeded, enabledTaxAgileTv) => {
  const vasContracts = getVasWithTax(sub, tax, taxNeeded, enabledTaxAgileTv)
  return vasContracts
    .map(vas => parseFloat(chain(vas).get('monthlyFee').value() || 0))
    .reduce((acum, item) => item + acum, 0)
}

export const getTerminalResidualMonths = term => {
  const today = Date.now()
  const endDate = chain(term).get('validFor').get('endDateTime').value() || 0

  return differenceInCalendarMonths(new Date(endDate), today)
}

export const getTerminalResidual = term => chain(term).get('fees').get('residual').value()

export const getTerminalName = term => {
  return chain(term).get('item').get('name').value()
}

export const getPendingIfCancel = sub => {
  const fromPermanents = chain(sub)
    .get('contracts')
    .get('permanents')
    .filter(obj => obj.residualLength !== 0)
    .reduce((result, item) => result + get(item, 'fees.pending'), 0)
    .value()

  const financialTerminals = getFinancialTerminals(sub).map(term =>
    formatTerminalResidualLengthToString(term, sub),
  )

  const fromFinancialsCalc = financialTerminals
    .map(term => {
      const { pendingInMonths } = correctResidualLengthCalc(term, sub)
      return [term, pendingInMonths]
    })
    .reduce((result, [item, pendingInMonths]) => {
      return result + get(item, 'fees.residual') + get(item, 'fees.monthly') * pendingInMonths
    }, 0)
  return fromPermanents + fromFinancialsCalc
}

function getData6TerminationEvent(sub) {
  return chain(sub)
    .get('events')
    .filter(
      filteredEvent =>
        filteredEvent.name === 'Per. Contract Termination' &&
        filteredEvent.bundleId === 'DATA6' &&
        isFuture(new Date(filteredEvent.activated_at)),
    )
    .value()
}

export const getBonus = sub => {
  const bonus = chain(sub)
    .get('productsSpecifications')
    .find({
      name: 'mobile',
    })
    .get('productSpecCharacteristic')
    .find(bonusInfo => BONUS_CODES.includes(bonusInfo.type))
    .value()

  const terminationObject = getData6TerminationEvent(sub)
  const isDisabled = !isEmpty(terminationObject)

  return bonus ? { ...bonus, isDisabled } : {}
}

export const getBonusType = sub => {
  return chain(sub)
    .get('productsSpecifications')
    .find({
      name: 'mobile',
    })
    .get('productSpecCharacteristic')
    .find(bonusInfo => BONUS_CODES.includes(bonusInfo.type))
    .get('type')
    .value()
}

export const getBundles = sub => {
  const bundles = chain(sub).get('bundle_upsells').value()

  return bundles.map(item => ({
    id: get(item, 'id'),
    value: get(item, 'amount'),
    unit: get(item, 'unit'),
    monthly_fee: BUNDLE_FEE,
    active: isActive(item.valid_from, item.valid_to),
  }))
}

export const getActiveUpsellAndNotUpsellBonus = sub => {
  const bonusNotUpsell = getBonus(sub)
  const bonusUpsell = getBundles(sub)

  if (isEmpty(bonusNotUpsell)) {
    return bonusUpsell
  }

  return union([bonusNotUpsell], bonusUpsell)
}

export const getActiveBundles = sub =>
  chain(sub)
    .get('productsSpecifications')
    .find({
      name: 'mobile',
    })
    .get('productSpecCharacteristic')
    .filter(spec => ['bonus-data', 'bonus-data-upsell'].includes(spec.name))
    .reject({ type: 'total-amount' })
    .value()

export const getActiveBundlesWithFees = (state, sub, tax) => {
  const bundles = getActiveBundles(sub)

  return map(bundles, bundle => {
    const bonus = selectBonusById(bundle.type)(state)

    return {
      ...bundle,
      value: get(bonus, 'attributes.mobile_data_amount_mb'),
      monthly_fee_with_tax: Math.round(
        applyTax(tax, get(bonus, 'attributes.monthly_fee', 0)) * get(bundle, 'count', 1),
      ),
      commercial_name: get(bonus, 'attributes.commercial_name', '-'),
    }
  })
}

export const getSvaSubscriptionType = (subscription, svaType) =>
  chain(subscription)
    .get('vasContracts')
    .find(sva => sva.contractTypeId === svaType)
    .value()

export const getSvaHebeMonthlyFeeSubscription = subscription =>
  get(getSvaSubscriptionType(subscription, SVA_HEBE_CODE), 'monthlyFee')

export const getPartialFees = (discountTargets, prices) =>
  compact(
    discountTargets.map(target =>
      prices.find(price => target.target_key === get(price, 'charge-category')),
    ),
  )

const isCrossSellDiscountForMobile = discountId => {
  const mobileCrossSellDiscountRegEx = /^CONVDISC_CS[0-9]{1,3}M[0-9]{1,2}$/

  return mobileCrossSellDiscountRegEx.test(discountId)
}

export const getAttributesPartialFees = (discount, prices) => {
  const partialFees = getPartialFees(discount.discount_plan.targets, prices)
  const checkIsPartialFee = partialFees.length === 1
  let partialFee = null

  if (!isNil(partialFees) && checkIsPartialFee) {
    const partialFeeValue = head(partialFees)

    partialFee = {
      taxFeeAmount: get(partialFeeValue, 'attributes.price.tax-free-amount'),
      taxRate: get(partialFeeValue, 'attributes.price.tax-rate'),
    }
  }

  return partialFee
}

export const getDiscDetails = (discount, prices = {}) => {
  let partialFee = {}

  if (!isNil(prices) && !isEmpty(prices)) {
    partialFee = getAttributesPartialFees(discount, prices)
  }

  const valid_from = parseISO(
    chain(discount).get('subscription_discount').get('valid_from').value(),
  )
  const valid_to = parseISO(chain(discount).get('subscription_discount').get('valid_to').value())

  const amount = chain(discount).get('subscription_discount').get('amount').value()

  const isPercentage = getIsPercentage(discount)

  const discountPlan = chain(discount).get('discount_plan').value()
  const discountId = chain(discount).get('discount_plan').get('rule_id').value()
  const subscriptionDiscountId = chain(discount)
    .get('subscription_discount')
    .get('discount_id')
    .value()
  const discountName = chain(discount)
    .get('discount_plan')
    .get('name')
    .replace('Descuento', 'Dto.')
    .value()

  const dateRange = `(${format(valid_from, 'dd/MM/yyyy')} al ${format(valid_to, 'dd/MM/yyyy')})`
  const explictMonth = chain(discount)
    .get('discount_plan')
    .get('validity_length_in_periods')
    .value()
  const months = explictMonth || Math.abs(differenceInCalendarMonths(valid_from, valid_to)) + 1
  const lengthRange = (valid_to && !isPast(valid_to) && formatRange(valid_to.toISOString())) || '-'

  const discountDetails = {
    amount,
    isPercentage,
    dateRange,
    discount_plan: discountPlan,
    discountId,
    subscriptionDiscountId,
    months,
    lengthRange,
    discountName,
    start: valid_from,
    end: valid_to,
  }

  if (!isNil(partialFee)) {
    discountDetails.partialFee = partialFee
  }

  return discountDetails
}

export const getFinancingDiscount = sub => {
  const discount = chain(sub)
    .get('charges')
    .find({
      discount_plan: {
        meta: {
          type: 'discount_plan_info',
        },
        discount_category: 'Financing',
      },
    })
    .value()
  return discount ? [discount] : []
}

export const getPrices = subscription => get(get(subscription, 'prices'), 'data', [])

export const getMobilePart = subscription =>
  getPrices(subscription).find(price => get(price, 'charge-category').includes('_Mobile_'))

export function getDiscountsCard(sub) {
  const prices = get(sub, 'prices.data')

  return map(getDiscounts(sub), discount => getDiscDetails(discount, prices))
}

const isUnlimited = (amount, unit) => {
  if (unit.toLowerCase() === 'mb') {
    return amount / 1024 > MIN_UNLIMITED_GB
  }

  if (unit.toLowerCase() === 'min') {
    return amount > MIN_UNLIMITED_MINUTES
  }

  return amount > MIN_UNLIMITED_GB
}

const valsWithUnitsFormat = valueAndUnit => {
  if (valueAndUnit) {
    const value = get(valueAndUnit, 'value')
    const unit = get(valueAndUnit, 'unit')

    if (isUnlimited(value, unit)) {
      return unit.toLowerCase() === 'min' ? 'Ilimitadas' : 'Infinitos gigas'
    }

    return chain(valueAndUnit).get('value').value()
      ? `${
          chain(valueAndUnit).get('unit').value() === 'MB'
            ? formatNumberWithComma(mbToGb(chain(valueAndUnit).get('value').value()))
            : chain(valueAndUnit).get('value').value()
        } ${
          chain(valueAndUnit).get('unit').value() === 'MB'
            ? 'GB'
            : chain(valueAndUnit).get('unit').value()
        }`
      : '-'
  }
  return false
}

export const tariffFee = (sub, tax) => {
  const fee = findFee(sub)
  const amount = parseFloat(get(fee, 'price.amount') || 0)
  const taxFee = parseFloat((amount * tax) / 100)
  return amount + taxFee
}

const isPromoBonus = code => {
  const regExpPromo = /RET[0-9]*GB/
  return regExpPromo.exec(code)
}

export function getCurrentMonthTariffRanges(sub) {
  const today = Date.now()
  const startMonth = startOfMonth(today)
  const endMonth = endOfMonth(today)

  // Filter all change events on the current  month
  const tariffChangeEvents = chain(sub)
    .get('events', [])
    .filter(
      event =>
        event.name === 'Subscription type change' &&
        event.event_status.code === 2 &&
        isSameMonth(parseISO(event.activated_at), today),
    )
    .value()

  if (!isEmpty(tariffChangeEvents)) {
    return flatMap(tariffChangeEvents, (tariffChangeEvent, i) => {
      const evActivatedDay = parseISO(tariffChangeEvent.activated_at)
      let firstTariff = []
      if (i === 0 && getDate(evActivatedDay) !== 1) {
        // First range (initial tariff when month starts)
        const startEvent = isBefore(startMonth, new Date(sub.activated_at))
          ? new Date(sub.activated_at)
          : startMonth
        firstTariff = isSameDay(evActivatedDay, startEvent)
          ? []
          : [
              {
                tariffId: tariffChangeEvent.old_tariff,
                start: startEvent,
                end: addDays(evActivatedDay, -1),
              },
            ]
      }
      let newTariff = []
      if (i + 1 >= tariffChangeEvents.length) {
        newTariff = [
          {
            tariffId: tariffChangeEvent.new_tariff,
            start: evActivatedDay,
            end: endMonth,
          },
        ]
      } else {
        const nextActivationDay = parseISO(tariffChangeEvents[i + 1].activated_at)
        newTariff = [
          {
            tariffId: tariffChangeEvent.new_tariff,
            start: evActivatedDay,
            end: endOfDay(addDays(nextActivationDay, -1)),
          },
        ]
      }
      return [...firstTariff, ...newTariff]
    })
  }

  return [
    {
      // Current tariff
      tariffId: getSubscriptionTariffId(sub),
      start: isBefore(startMonth, new Date(sub.activated_at))
        ? new Date(sub.activated_at)
        : startMonth,
      end: endMonth,
    },
  ]
}

function isWholeMonthDiscount(discountId) {
  return DISCOUNTS_THAT_APPLY_WHOLE_MONTH.includes(discountId)
}

export function getCurrentMonthDiscountRangesForTariffRange(discountCard = [], tariffRange) {
  const today = Date.now()

  const monthIntervalNorm = getNormInterval({ start: startOfMonth(today), end: endOfMonth(today) })

  const tariffRangeIntervalNorm = getNormInterval({
    start: tariffRange.start,
    end: tariffRange.end,
  })

  const allDiscountDates = flatMap(discountCard, discountDet =>
    isWholeMonthDiscount(discountDet.discountId) ? [] : [endOfDay(discountDet.end)],
  )

  // All dates normalized in tariffRange range in the current month
  const tariffMonthlyActiveRange = {
    start: max([monthIntervalNorm.start, tariffRangeIntervalNorm.start]),
    end: min([monthIntervalNorm.end, tariffRangeIntervalNorm.end]),
  }

  const allDates = concat(
    filter(
      allDiscountDates,
      date =>
        isValidInterval(tariffMonthlyActiveRange.start, tariffMonthlyActiveRange.end) &&
        isWithinInterval(date, tariffMonthlyActiveRange),
    ),
    tariffMonthlyActiveRange.start,
    tariffMonthlyActiveRange.end,
  )

  const allSortedDates = sortBy(uniqWith(allDates, isEqual))

  const intervals = zipWith(dropRight(allSortedDates), drop(allSortedDates), (start, end) => ({
    start,
    end,
  }))

  // Eliminate intervals with duration = 0
  const intervalsWithDurationNoZero = filter(
    intervals,
    interval => differenceInSeconds(interval.end, interval.start) > 1,
  )

  // Normalization of start/end in intervals
  const normalizedIntervals = intervalsWithDurationNoZero.map(({ start, end }) => ({
    start: getMinutes(start) === 59 ? startOfDay(addDays(start, 1)) : start,
    end: getMinutes(end) === 0 ? endOfDay(addDays(end, -1)) : end,
  }))

  const discountIntervals = normalizedIntervals.map(interval => ({
    ...interval,
    discounts: filter(
      discountCard,
      discount =>
        interval.start < interval.end &&
        discount.start < discount.end &&
        areIntervalsOverlapping(
          getNormInterval({ start: interval.start, end: interval.end }),
          getNormInterval({ start: discount.start, end: discount.end }),
        ),
    ),
  }))

  return discountIntervals
}

export const getDiscountAndSvaAmountProrrated = ({ discountId, amountWithTax, currMonthRange }) => {
  if (!isNil(discountId) && DISCOUNTS_THAT_NOT_APPPLY_PRORRATED.includes(discountId)) {
    return amountWithTax
  }

  const today = Date.now()
  const daysInMonth = getDaysInMonth(today)

  const daysInRange = lengthInDaysOfNomInterval(currMonthRange)

  return (amountWithTax / daysInMonth) * daysInRange
}

export const formatDiscountProrrated = ({ discountId, amountWithTax, currMonthRange }) =>
  formatFee(getDiscountAndSvaAmountProrrated({ discountId, amountWithTax, currMonthRange }))

export const formatSvaProrrated = ({ monthlyFee, currMonthRange }) =>
  formatFee(getDiscountAndSvaAmountProrrated({ amountWithTax: monthlyFee, currMonthRange }))

export const formatDiscountAmountProrrated = discount => {
  const today = Date.now()
  const monthIntervalNorm = getNormInterval({ start: startOfMonth(today), end: endOfMonth(today) })
  const discountId = get(discount, 'discount_plan.rule_id')
  const amount = get(discount, 'subscription_discount.amount')

  const rangeDate = {
    start: parseISO(get(discount, 'subscription_discount.valid_from')),
    end: parseISO(get(discount, 'subscription_discount.valid_to')),
  }

  if (discountId !== MAIN_DUO_DISCOUNT_ID) {
    return discount
  }

  const daysInMonth = getDaysInMonth(today)

  const currMonthRange = intersection(monthIntervalNorm, rangeDate)
  const daysInRange = lengthInDaysOfNomInterval(currMonthRange)
  const valueAfterProrratedDiscount = (amount / daysInMonth) * daysInRange

  return {
    ...discount,
    subscription_discount: { amount: valueAfterProrratedDiscount },
    isProrratedDuo: true,
  }
}

const getCurrentMonthTariffFeeWithDiscsAndTaxes = (
  discountRanges = [],
  tariffMonthlyFee,
  ratioDaysActive,
  tax,
) => {
  const tariffMonthlyFeeWithTax = applyTax(tax, tariffMonthlyFee)

  if (isEmpty(discountRanges)) {
    return tariffMonthlyFeeWithTax
  }

  const today = Date.now()
  const daysInMonth = getDaysInMonth(today)

  const amountDiscountsFixed = reduce(
    discountRanges,
    (acc, { discounts, start, end }) => {
      const diffInTime = differenceInSeconds(end, start)
      if (diffInTime <= 0) {
        return acc + 0
      }

      const discIntervalNorm = getNormInterval({ start, end })
      const daysInRange = lengthInDaysOfNomInterval(discIntervalNorm)
      const rangeMonthRatio = Math.round(daysInRange / daysInMonth)

      const valAfterFixedDiscounts = reduce(
        filter(discounts, discount => discount.isProrated || !discount.isPercentage),
        (acum, { discountId, amount, isPercentage, ...discount }) => {
          const monthIntervalNorm = getNormInterval({
            start: startOfMonth(today),
            end: endOfMonth(today),
          })

          return (
            acum +
            getDiscountAndSvaAmountProrrated({
              discountId,
              amountWithTax: isPercentage ? amount : applyTax(tax, amount),
              currMonthRange: intersection(monthIntervalNorm, discount),
            }) *
              rangeMonthRatio
          )
        },
        0,
      )
      return acc + valAfterFixedDiscounts
    },
    0,
  )

  const percentageDiscounts = discountRanges.reduce((acc, discountRange) => {
    if (!isEmpty(discountRange.discounts)) {
      acc.push(...discountRange.discounts)
    }

    return acc
  }, [])

  const partialDiscountsWithPercentage = percentageDiscounts.filter(
    discount => discount.isPercentage && discount.partialFee && !discount.isProrated,
  )

  const amountPartialDiscountsPercentage = partialDiscountsWithPercentage.reduce(
    (acc, { amount, partialFee }) =>
      acc + applyTax(tax, partialFee.taxFeeAmount * ratioDaysActive) * (amount / 100),
    0,
  )

  const discountsWithPercentage = percentageDiscounts.filter(
    discount => discount.isPercentage && !discount.partialFee && !discount.isProrated,
  )

  const amountDiscountsPercentage = discountsWithPercentage.reduce(
    (acc, { amount }) => acc + (tariffMonthlyFeeWithTax * amount) / 100,
    0,
  )

  return (
    tariffMonthlyFeeWithTax -
    (amountDiscountsFixed + amountDiscountsPercentage + amountPartialDiscountsPercentage)
  )
}

export const calculateCrossSellDiscount = (
  discount,
  { discountCode = null, prices = null, taxNeeded = true, is2pOrFijo = false },
) => {
  const pricesValue = isArray(prices) ? prices : get(prices, 'prices')
  const discountId = isObject(discount) ? discount.discountId : discountCode
  const discountForMobile = isCrossSellDiscountForMobile(discountId)

  const discountFactor = isObject(discount) ? discount.amount / 100 : discount / 100

  let partialFee
  if (is2pOrFijo) {
    if (discountForMobile) {
      return 0
    }
    partialFee = head(pricesValue)
  } else {
    partialFee = pricesValue?.find(price => {
      return get(price, 'charge-category').includes(discountForMobile ? 'Mobile' : 'Fixed')
    })
  }

  const feeAmount = taxNeeded
    ? get(partialFee, 'attributes.price.tax-included-amount')
    : get(partialFee, 'attributes.price.tax-free-amount')

  return feeAmount * discountFactor
}

const applyRelatedTariffToCrossSellDiscounts = (
  discounts,
  { prices = null, is2pOrFijo = false, taxNeeded },
) => {
  return map(discounts, discount => {
    if (!isCrossSellDiscount(discount.discountId)) {
      return discount
    }

    return {
      ...discount,
      amount: calculateCrossSellDiscount(discount, {
        prices,
        is2pOrFijo,
        taxNeeded,
      }),
      isProrated: true,
    }
  })
}

const getFatExpenses = (fats, tax) => {
  const currentMonthFATFormat = format(new Date(), 'yyyyMM')
  const [currentMonthFATs] = !fats
    ? [[], []]
    : fats.reduce(
        (result, fat) => {
          const isCurrentMonthFAT = currentMonthFATFormat === get(fat, 'air_time.period')
          result[isCurrentMonthFAT ? 0 : 1].push(fat)

          return result
        },
        [[], []],
      )

  const totalCurrentFATsAmount = currentMonthFATs.reduce((accum, fat) => {
    const FATamount = get(fat, 'air_time.amount', 0)
    const FATId = get(fat, 'air_time.air_time_group_id')
    const SMSCompensationType = FATId === 'CPSMSFAT'
    const totalFATAmount = SMSCompensationType ? applyTax(tax, FATamount * SMSPrice) : FATamount

    return accum + totalFATAmount
  }, 0)

  return totalCurrentFATsAmount
}

export const getRatioDaysActive = (start, end) => {
  const today = Date.now()
  const daysInCurrentMonth = getDaysInMonth(today)
  const monthIntervalNorm = getNormInterval({
    start: startOfMonth(today),
    end: endOfMonth(today),
  })

  const overlappingIntv = intersection(getNormInterval({ start, end }), monthIntervalNorm)

  const overlappingDays = lengthInDaysOfNomInterval(overlappingIntv)
  const ratioDaysActive = overlappingDays / daysInCurrentMonth

  return ratioDaysActive
}

export const getCurrentMonthEstimatedTotalExpenses = (
  state,
  sub,
  tax,
  svas,
  prices,
  is2pOrFijo,
  taxNeeded,
  fats = null,
  multisimType,
) => {
  const discountDetails = applyRelatedTariffToCrossSellDiscounts(getDiscountsCard(sub), {
    prices,
    is2pOrFijo,
    taxNeeded,
  }).filter(discount => !DOCTORGO_DISCOUNTS.includes(get(discount, 'discountId')))

  const tariffRanges = getCurrentMonthTariffRanges(sub)

  const discountRangesForTariffs = map(tariffRanges, tariffRange => {
    let monthlyFeeWithoutTaxes = 0

    // Remove this if when migrate call tariff v2 to v3
    if (multisimType === MULTISIM_LINE) {
      monthlyFeeWithoutTaxes = get(head(prices), 'attributes.price.tax-free-amount')
    } else {
      const tariff = selectTariffsApigeeById(tariffRange.tariffId)(state)
      monthlyFeeWithoutTaxes = getMonthlyFee(tariff)
    }

    const discountRangesForTariff = getCurrentMonthDiscountRangesForTariffRange(
      discountDetails,
      tariffRange,
    )

    const ratioDaysActive = getRatioDaysActive(tariffRange.start, tariffRange.end)

    const tariffFeeWithDiscsAndTaxes = getCurrentMonthTariffFeeWithDiscsAndTaxes(
      discountRangesForTariff,
      monthlyFeeWithoutTaxes * ratioDaysActive,
      ratioDaysActive,
      tax,
    )

    return tariffFeeWithDiscsAndTaxes
  })

  const totalFeesWithDiscounts = reduce(discountRangesForTariffs, (accum, cost) => accum + cost, 0)
  const totalFeesWithDiscountsAndFats = !isEmpty(fats)
    ? Math.max(totalFeesWithDiscounts - getFatExpenses(fats, tax), 0)
    : totalFeesWithDiscounts

  const enrichedBundles = getActiveBundlesWithFees(state, sub, tax)
  const totalBundlesFees = reduce(
    enrichedBundles,
    (accum, bundle) => accum + bundle.monthly_fee_with_tax,
    0,
  )

  const totalSvasFees = svas
    .filter(
      sva => sva.contractTypeId !== SVA_WIFI_TOTAL_01 && sva.contractTypeId !== SVA_WIFI_TOTAL_02,
    )
    .reduce((acc, sva) => {
      return acc + get(sva, 'monthlyFee', 0)
    }, 0)

  const today = Date.now()
  const monthIntervalNorm = getNormInterval({
    start: startOfMonth(today),
    end: endOfMonth(today),
  })
  const totalSvasProratedFees = svas
    .filter(
      sva => sva.contractTypeId === SVA_WIFI_TOTAL_01 || sva.contractTypeId === SVA_WIFI_TOTAL_02,
    )
    .map(vas => ({
      ...vas,
      currMonthRange: intersection(monthIntervalNorm, {
        start: new Date(vas.validFrom),
        end: new Date(vas.validTo),
      }),
    }))
    .reduce((acc, { currMonthRange, monthlyFee }) => {
      const daysInMonth = getDaysInMonth(today)
      const daysInRange = lengthInDaysOfNomInterval(currMonthRange)
      const rangeMonthRatio = Math.round(daysInRange / daysInMonth)

      return (
        acc +
        getDiscountAndSvaAmountProrrated({
          amountWithTax: monthlyFee,
          currMonthRange,
        }) *
          rangeMonthRatio
      )
    }, 0)

  const totalExtrasWithTaxes = applyTax(tax, getTotalExtras(sub))

  return Math.max(
    0,
    parseFloat(getTerminalsFee(sub)) +
      totalBundlesFees +
      totalFeesWithDiscountsAndFats +
      totalSvasFees +
      totalSvasProratedFees +
      totalExtrasWithTaxes,
  )
}

export const getSubscriptionsCurrentMonthEstimatedTotalExpenses = (
  state,
  subscriptions,
  tax,
  taxNeeded,
  enabledTaxSvaAgileTv,
) =>
  mapValues(subscriptions, ({ data: subscription }) => {
    const tariffId = get(subscription, 'type')
    const tariff = selectTariffsApigeeById(tariffId)(state)
    const is2pOrFijo = is2pTariff(tariff) || is2pFreeLineTariff(tariff)
    const multisimType = get(subscription, 'multisimType')
    return getCurrentMonthEstimatedTotalExpenses(
      state,
      subscription,
      tax,
      taxNeeded
        ? getVasWithTax(subscription, tax, taxNeeded, enabledTaxSvaAgileTv)
        : get(subscription, 'vasContracts', []),
      get(subscription, 'prices.data'),
      is2pOrFijo,
      taxNeeded,
      null,
      multisimType,
    )
  })

export const hasPromos = sub => getBonuses(sub).some(elem => isPromoBonus(elem.id))

export const hasDiscounts = sub => !isEmpty(getDiscountsCard(sub))

export function getLineNumbers(sub) {
  return [getFixedLineNumber(sub), getMobileLineNumber(sub)].filter(ele => !!ele)
}

export function getF2FVoiceTotalAmountInMin(sub) {
  return get(sub, 'bundle_usages[0].f2F_voice_total_amount', 0)
}

export function getF2FVoiceTotalUsageInMin(sub) {
  return get(sub, 'bundle_usages[0].f2F_voice_total_usage', 0)
}

export function getF2MVoiceTotalAmountInMin(sub) {
  return get(sub, 'bundle_usages[0].f2M_voice_total_amount', 0)
}

export function getF2MVoiceTotalUsageInMin(sub) {
  return get(sub, 'bundle_usages[0].f2M_voice_total_usage', 0)
}

export function getM2FVoiceTotalUsageInMin(sub) {
  const charac = getUsageCharacteristicMobile(sub)
  return parseFloat(chain(charac).find({ name: 'fixed-calls-consumed' }).get('value').value() || 0)
}

export function getM2MVoiceTotalUsageInMin(sub) {
  const charac = getUsageCharacteristicMobile(sub)
  return parseFloat(chain(charac).find({ name: 'mobile-calls-consumed' }).get('value').value() || 0)
}

export function getM2AllMVoiceTotalUsageInMin(sub) {
  return getM2FVoiceTotalUsageInMin(sub) + getM2MVoiceTotalUsageInMin(sub)
}

export function getTotalTariffUsageInMin(sub) {
  return parseFloat(get(sub, 'bundle_usages[0].voice_total_usage', 0))
}

export function hasF2FUnlimitedMin(sub) {
  return getF2FVoiceTotalAmountInMin(sub) >= MIN_UNLIMITED_MINUTES_F2F
}

export function hasF2MUnlimitedMin(sub) {
  return getF2MVoiceTotalAmountInMin(sub) >= MIN_UNLIMITED_MINUTES_F2M
}

export const formatDataSubscription = sub => {
  const today = Date.now()
  const startMonth = startOfMonth(today)

  return {
    totalData: valsWithUnitsFormat(getTotalData(sub)),
    calls: valsWithUnitsFormat(getSubscriptionCalls(sub)),
    bonusesUpsell: getBundles(sub),
    bonusesMain: getBonus(sub),
    svaHebe: getSvaSubscriptionType(sub, SVA_HEBE_CODE),
    svaSmartHome: getSvaSubscriptionType(sub, SVA_SMARTHOME_CODE),
    svaWifiTotal:
      getSvaSubscriptionType(sub, SVA_WIFI_TOTAL_01) ||
      getSvaSubscriptionType(sub, SVA_WIFI_TOTAL_02),
    discounts: getDiscountsCard(sub).filter(
      discount => isAfter(discount.end, startMonth) || isEqual(discount.end, startMonth),
    ),
    fixedPermanents: findFixedPermanentsWithCorrectResidualLength(FIXED_PRODUCT_NAME, sub),
    resetDate: getResetDate(sub),
  }
}

export const formatDataTerminal = term => ({
  terminalName: getTerminalName(term),
  initialPayment: false, // TODO: get info from products
  terminalResidualTerminal: getTerminalResidualMonths(term),
  terminalFee: `${getTerminalFee(term)} €`,
  terminalResidual: getTerminalResidual(term),
})

export function isTVPermanent(permanent) {
  return startsWith(get(permanent, 'id'), 'TVTERM')
}

export function isRouterPermanent3P(permanent) {
  const penaltyFee = get(permanent, 'fees.penalty')
  const pendingFee = get(permanent, 'fees.pending')
  const permanentId = get(permanent, 'id')
  const hasRouterPermanent3P =
    startsWith(permanentId, 'FTERM3') || startsWith(permanentId, 'NEBTERM3')

  return penaltyFee === pendingFee && hasRouterPermanent3P
}

export function getFeesPendingFromPermanent(permanent) {
  let totalFees = 0

  if (!permanent) {
    return 0
  }

  const today = startOfDay(Date.now())
  const firstDayValid = parseISO(permanent.validFor.startDateTime)
  const lastDayValid = parseISO(permanent.validFor.endDateTime)

  const totalDays = differenceInDays(lastDayValid, firstDayValid)
  const daysLeftValid = differenceInDays(lastDayValid, today)

  if (isValid(lastDayValid) && daysLeftValid > 0) {
    totalFees = get(permanent, 'fees.penalty', 0)

    if (!isTVPermanent(permanent) && !isRouterPermanent3P(permanent)) {
      totalFees = totalDays !== 0 && (totalFees / totalDays) * daysLeftValid
    }
  }

  return totalFees
}

export function getFees25WithDiscountFromFinancials(financials) {
  return get(financials, 'fees.residual', 0) - get(financials, 'fees.residualDiscount', 0)
}

export function getMonthlyFeesAmountLeftToPayFromFinancials(financials) {
  const monthsLeft = get(financials, 'residualLength')

  return monthsLeft * get(financials, 'fees.monthly', 0)
}

// Total fees that has to be paid on this financials
export function getTotalFeesToPayFromFinancials(financials) {
  return get(financials, 'length', 0) * get(financials, 'fees.monthly', 0)
}

export function getCancellationCostsLeftFromFinancials(financials) {
  return (
    getMonthlyFeesAmountLeftToPayFromFinancials(financials) +
    getFees25WithDiscountFromFinancials(financials)
  )
}

export function formatMonthlyFeeForFinancials(financials) {
  return ` ${formatFee(financials.fees.monthly)}/mes (quedan ${get(financials, 'residualLength')})`
}

export function getTotalCancellationCostsLeft(sub, enableProrratedPenalty) {
  const permanentFixed = findFixedPermanents(sub)
  const permanentMobile = findMobilePermanents(sub)

  const permanents = concat([], ...[permanentFixed, permanentMobile].filter(ele => !isEmpty(ele)))
  const totalCostsPermanents = reduce(
    permanents,
    (acc, permanent) =>
      acc +
      (enableProrratedPenalty
        ? getFeesPendingFromPermanent(permanent)
        : get(permanent, 'fees.penalty', 0)),
    0,
  )

  const terminalsFinancials = getTerminalsFinancials(sub)
  const totalCancellationCostsLeftFromFinancials = reduce(
    terminalsFinancials,
    (acc, financials) => acc + getCancellationCostsLeftFromFinancials(financials),
    0,
  )

  const permanent = head(findOtherServicesPermanents(sub))
  const totalOtherFeesPendingFromPermanent = getFeesPendingFromPermanent(permanent)

  return (
    totalCancellationCostsLeftFromFinancials +
    totalCostsPermanents +
    totalOtherFeesPendingFromPermanent
  )
}

// Returns first date that a customer can leave without cost
export function getTotalCancellationEndDate(sub) {
  const today = startOfDay(Date.now())

  const permanentFixed = findFixedPermanents(sub)
  const permanentMobile = findMobilePermanents(sub)

  const permanents = concat([], ...[permanentFixed, permanentMobile].filter(ele => !isEmpty(ele)))
  const terminalsFinancials = getTerminalsFinancials(sub)

  const allDates = [today]
    .concat(permanents, terminalsFinancials)
    .map(permanent => parseISO(get(permanent, 'validFor.endDateTime')))
    .filter(date => isValid(date))

  return max(allDates)
}
export const isFixedNumber = productId => productId > 800000000

export const getFATs = subscription => get(subscription, 'free_air_times')

export const getProductNumbers = subscription => {
  if (isArray(subscription)) {
    const fixed = chain(subscription)
      .head()
      .get('productsSpecifications')
      .find({ name: 'fixed' })
      .get('id')
      .value()
    const mobile = chain(subscription)
      .head()
      .get('productsSpecifications')
      .find({ name: 'mobile' })
      .get('id')
      .value()
    return { fixed, mobile }
  }
  const fixed = chain(subscription)
    .get('productsSpecifications')
    .find({ name: 'fixed' })
    .get('id')
    .value()
  const mobile = chain(subscription)
    .get('productsSpecifications')
    .find({ name: 'mobile' })
    .get('id')
    .value()

  return { fixed, mobile }
}

export const getFeesToPayFromFinancialsDescriptions = financials => {
  const monthlyFee = formatFee(get(financials, 'fees.monthly', 0))
  const numberOfMonths = get(financials, 'residualLength', 0)

  return `(${monthlyFee} X ${numberOfMonths} meses)`
}

export const formatRangeFromTodayToEndDate = end => {
  let date = startOfDay(Date.now())
  const years = differenceInYears(parseISO(end), date)
  date = addYears(date, years)
  const months = differenceInMonths(parseISO(end), date)
  date = addMonths(date, months)
  const days = differenceInDays(parseISO(end), date)

  let range = ''
  if (days) range = `${days} días`
  if (months) range = `${months} meses, ${range}`
  if (years) range = `${years} año, ${range}`

  return range.endsWith(', ') ? range.slice(0, -2) : range
}

export const checkIsProrratedFinancial = financial =>
  get(financial, 'id').includes(PRORRATED_FINANCIAL_ID)

export const isDuoPrincipalSubscription = subscription =>
  get(subscription, 'type') === DUO_PRINCIPAL_TARIFF_ID
