import { call, put, takeEvery } from 'redux-saga/effects'
import { logError, formatErrorMessage } from 'services/logging'
import { actions } from './actions'
import * as constants from './constants'
import { findEnrichment } from './api'

export function* findEnrichmentSaga() {
  try {
    const enrichment = yield call(findEnrichment)
    yield put(actions.foundEnrichment({ data: enrichment }))
  } catch (e) {
    yield call(logError, new Error(formatErrorMessage(e)))
    yield put(actions.findEnrichmentFailed())
  }
}

export function* watchFindEnrichment() {
  yield takeEvery(constants.FIND_ENRICHMENT, findEnrichmentSaga)
}
