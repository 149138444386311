import { useState } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { useSelector } from 'react-redux'

import { Box, Icon, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import { TextStyles } from 'utils/text'

import { ButtonLink } from 'components/ui'
import { ICONS } from 'utils/icons'

import { APP_CONFIG } from 'services/app-config'
import { selectDeferredPaymentMenu } from 'services/feature-flag/selectors'
import { externalAppsLinks, externalAppsLinksDeferredPaymentFlag } from './AppLauncher.helpers'
import { AppLauncherSearch } from './AppLauncherSearch'

const APP_DEFERRED_PAYMENT_ID = 'deferred-payment'

const useStyles = makeStyles(theme => ({
  'app-launcher': {
    top: 0,
    left: 0,
    bottom: 0,
    zIndex: 11,
    backgroundColor: 'rgba(43, 103, 187, 0.5)',

    '& ~ div, & ~ section': {
      filter: 'blur(16px)',
    },

    '& .btn-close': {
      position: 'sticky',
      top: '46px',

      '& .icon': {
        top: '35px',
        position: 'absolute',
        right: '46px',
        color: theme.palette.global.gray_light,
        cursor: 'pointer',
        fontSize: 'xx-large',
      },
    },

    '& .launcher-content': {
      width: '70%',
      paddingTop: '50px',
      margin: '0 auto',
      display: 'flex',
      flexDirection: 'column',
    },

    '& .launcher-container': {
      height: '75vh',
    },

    '& .launcher-apps': {
      width: '100%',
      display: 'grid',
      gridTemplateColumns: 'repeat(5, 1fr)',
      gridGap: '0 30px',
      overflowY: 'auto',
      overflowX: 'hidden',
      padding: '0 25px',
    },

    '& .search': {
      padding: '0px 10px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },

    '& .app-item': {
      borderRadius: '8px',
      boxShadow: '0 0 10px 1px rgba(137, 139, 140, 0.2)',
      backgroundColor: 'white',
      margin: '12px',
      textAlign: 'center',
      width: '100%',
      height: '125px',
    },

    '& .app-btn': {
      padding: '20px 0',
    },

    '& .app-icon': {
      border: '1px solid black',
      borderRadius: '50%',
      width: '48px',
      height: '48px',
    },
  },
}))

export const AppLauncher = ({ onClosing, documentId, agentSfid }) => {
  const classes = useStyles()

  const externalAppsLinksFeatureFlag = useSelector(selectDeferredPaymentMenu)
    ? externalAppsLinksDeferredPaymentFlag
    : externalAppsLinks

  const [filteredApps, setFilteredApps] = useState(externalAppsLinksFeatureFlag)
  const isHostInProd = APP_CONFIG.production
  const escPressed = e => {
    document.removeEventListener('keydown', escPressed)
    return e.keyCode === 27 && onClosing(false)
  }

  document.addEventListener('keydown', escPressed)

  return (
    <Box
      position="fixed"
      width="100%"
      padding="12px"
      className={classes['app-launcher']}
      data-hook="app-launcher">
      <div className="btn-close">
        <Icon data-hook="launcher-close" className="icon" onClick={() => onClosing(false)}>
          {ICONS.close}
        </Icon>
      </div>
      <div className="launcher-content">
        <AppLauncherSearch
          setFilteredApps={setFilteredApps}
          externalAppsLinks={externalAppsLinksFeatureFlag}
        />
        <div className="launcher-container">
          <div className="launcher-apps">
            {filteredApps.map(app => {
              const baseUrl = app.url
              const deferredPaymentUrl = `${baseUrl}?documentId=${documentId}&company=yoigo&sfid=${agentSfid}&origin=360`
              return (
                <div className="app-item" key={app.id}>
                  <ButtonLink
                    className="app-btn"
                    onClick={() =>
                      window.open(
                        app.id === APP_DEFERRED_PAYMENT_ID ? deferredPaymentUrl : baseUrl,
                        '_blank',
                      )
                    }
                    disabled={!isHostInProd && !get(app, 'hasPre')}>
                    <img className="app-icon" src={app.icon} alt={app.title} />
                    <Typography
                      {...TextStyles.title3Dark({
                        className: 'm-t-12',
                      })}>
                      {app.title}
                    </Typography>
                  </ButtonLink>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </Box>
  )
}

AppLauncher.propTypes = {
  onClosing: PropTypes.func.isRequired,
  documentId: PropTypes.string,
  agentSfid: PropTypes.string,
}
