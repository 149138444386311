import { useEffect } from 'react'
import { isEmpty } from 'lodash'
import { Route, Switch, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { FlowChooserContainer } from 'modules/new-clients/containers/FlowChooser'
import { AuthenticatedRoute } from 'modules/Auth'
import { useSaleExit } from 'modules/NewClientSales/hooks/Shared/useSaleExit'
import {
  selectIsMobileOnly,
  selectIsCarteraQueryParam,
  selectSaleQueryParam,
} from 'modules/Router/store'
import { selectEnabledNewClientSale } from 'services/feature-flag/selectors'
import PropTypes from 'prop-types'
import { NewClientsContainer } from './containers/NewClientsContainer'

import { SALES_BASE_PATH, SALES_INFO, SALES_OFFERS, SALES_TEST_ROUTE } from './constants'

export function NewClientSalesRoutes({ location }) {
  const isMobileOnly = useSelector(selectIsMobileOnly)
  const isEnabledNewClientSale = useSelector(selectEnabledNewClientSale)
  const isCartera = useSelector(selectIsCarteraQueryParam)
  const saleParam = useSelector(selectSaleQueryParam)
  const { goExitSale } = useSaleExit(false)

  const INIT_FLOW_STEP = isMobileOnly ? SALES_OFFERS : SALES_INFO

  useEffect(() => {
    if (!isEnabledNewClientSale && !isCartera && !isEmpty(saleParam)) {
      goExitSale()
    }
  }, [isEnabledNewClientSale, isCartera])

  return (
    <Switch>
      <Route exact path={SALES_TEST_ROUTE} component={FlowChooserContainer} />
      <Redirect
        from={SALES_BASE_PATH}
        exact
        to={{ pathname: INIT_FLOW_STEP, search: location.search }}
      />
      <AuthenticatedRoute path={`${SALES_BASE_PATH}/:step`} component={NewClientsContainer} />
    </Switch>
  )
}

NewClientSalesRoutes.propTypes = {
  location: PropTypes.object.isRequired,
}
