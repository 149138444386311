export const SFID_MIGRATION_TYPIFICATION_ACCESS = ['_FFLA']
export const SFID_UPGRADE_TYPIFICATION_ACCESS = ['_FUPS', '_FUP1', '_FUP6']
export const SFID_INBOUND_TYPIFICATION_ACCESS = ['_FREC']
export const SFID_ATCN_CAMPAIGN_ACCESS = ['SRVNEG', 'MKCN']
export const SFID_APN_CAMPAIGN_ACCESS = ['SRVAPN', 'SRV_APN']
export const SFID_GUUK = [
  'ALARREA02',
  'ANA.CANELA',
  'ANDONI.GUTIERREZ',
  'ARANZAZU.RUEDA',
  'ARITZ.IZQUIERDO',
  'AVISEDA02',
  'DLUENGO02',
  'EPENA02',
  'GARBINE.TELLERIA02',
  'GORKA.ARBAZAGOITIAIN',
  'ICIAR.DEANDRES',
  'JAIME.TORRIJOSIN',
  'JASKAITURRIETA',
  'JOSE.GARCIAIN',
  'JOSEANGEL.NEGRETE',
  'LEONID.FUENTEVILLA',
  'MAIDER.MENDIZABAL',
  'MALONSO0002',
  'MARIA.VARELA',
  'MGARCIA02',
  'MHID_GU0002',
  'PATRICIA.EZQUERRO02',
  'RAQUEL.QUIROGA',
  'RAUL.LOZANOIN',
  'SAIOA.BERASALUCEIN',
  'TERESA.PEREZIN',
  'VORECA',
  'XABIER.AYO',
]

export const REGEX_SFID_DOCTOR_GO = /^YC|^SRV/
export const REGEX_SFIDS_ALLOWED_CANCEL_HOMEGO =
  /_RBAJA|_RBAJA2|_RPORT|_RPORT2|_RBO|BL|CNN|MKDX|SRV|YC/

export const CAMPAIGN_VALUE_UPDATE = 'UP'
export const CAMPAIGN_VALUE_MIGRATION = 'MIG'
export const CAMPAIGN_VALUE_ATCN = 'ATCN'
export const CAMPAIGN_VALUE_APN = 'APN'
export const CAMPAIGN_VALUE_RT = 'RT'

export const REGEX_SFIDS_ENABLED_CAMPGAIGN_RT = /^MKDXNEG\d*|BLN001513/

export const CAMPAIGN_AREA_INBOUND = 'INBOUND FIDE'
export const CAMPAIGN_AREA_PRO_SOHO = 'NEGOCIO'

export const POS_PROFILE = 'POS'
export const MASPROXIMO_PROFILE = 'MASPROXIMO'

export const POS_STAGING_SFIDS = ['tienda_exclusiva_1', 'tienda_exclusiva_2', 'tienda_exclusiva_3']

export const ADDITIONAL_OPS_ACCESS_SFIDS = [
  'KT000014_RBO',
  'KT000022_RBO',
  'KT000017_RBO',
  'KT000001_RBO',
  'KT0000031_RBO',
  'KT0000056_RBO',
  'KT0000062_RBO',
  'KT0000054_RBO',
  'KT0000038_RBO',
  'KT000002_RBO',
  'KT0000042_RBO',
  'KT0000030_RBO',
  'KT0000036_RBO',
  'KT0000032_RBO',
  'KT0000034_RBO',
  'KT0000059_RBO',
  'KT000009_RBO',
  'KT000003_RBO',
  'KT000020_RBO',
  'KT000004_RBO',
  'KT0000033_RBO',
  'KT0000041_RBO',
  'KT000013_RBO',
  'KT000018_RBO',
  'KT0000050_RBO',
  'KT000016_RBO',
  'KT000010_RBO',
  'KT000021_RBO',
  'KT000023_RBO',
  'KT0000066_RBO',
  'KT000005_RBO',
  'KT000008_RBO',
]

export const D2D_ADDITIONAL_ENERGY_ACCESS = [
  'D2N01004',
  'D2N01031',
  'D2N01037',
  'D2N01057',
  'D2N01064',
  'D2N01076',
  'D2N01077',
  'D2N01089',
  'D2N01093',
  'D2N01106',
  'D2N01107',
  'D2N01117',
  'D2N01131',
  'D2N01137',
  'D2N01139',
  'D2N01152',
  'D2N01156',
  'D2N01161',
  'D2N01168',
  'D2N01169',
  'D2N01179',
  'D2N01189',
  'D2N01193',
  'D2N01212',
  'D2N01214',
  'D2N01217',
  'D2N01218',
  'D2N01220',
  'D2N01227',
  'D2N01231',
  'D2N01236',
  'D2N01242',
  'D2N01243',
  'D2N01250',
  'D2N01253',
  'D2N01256',
  'D2N01261',
  'D2N01262',
  'D2N01271',
  'D2N01274',
  'D2N01275',
  'D2N01276',
  'D2N01279',
  'D2N01284',
  'D2N01288',
  'D2N01291',
  'D2N01297',
  'D2N01299',
  'D2N01301',
  'D2N01303',
  'D2N01306',
  'D2N01309',
  'D2N01310',
  'D2N01312',
  'D2N01314',
  'D2N01320',
  'D2N01323',
  'D2N01326',
  'D2N01330',
  'D2N01331',
  'D2N01334',
  'D2N01335',
  'D2N01336',
  'D2N01339',
  'D2N01340',
  'D2N01344',
  'D2N01346',
  'D2N01347',
  'D2N01348',
  'D2N01349',
  'D2N01350',
  'D2N01351',
  'D2N01352',
  'D2N01353',
  'D2N01354',
  'D2N01363',
  'D2N01364',
  'D2N01365',
  'D2N01380',
  'D2N01394',
  'D2N01395',
  'D2N01398',
  'D2N01416',
]

export const REGEX_SFID_ENERGY_SALE_ACCESS = /^SBN/
export const D2D_ADDITIONAL_ENERGY_SALE_ACCESS = [
  'D2N01015',
  'D2N01016',
  'D2N01043',
  'D2N01061',
  'D2N01166',
  'D2N01170',
  'D2N01219',
  'D2N01272',
  'D2N01281',
  'D2N01286',
  'D2N01296',
  'D2N01319',
  'D2N01328',
  'D2N01332',
  'D2N01341',
  'D2N01345',
  'D2N01355',
  'D2N01359',
  'D2N01368',
  'D2N01372',
  'D2N01375',
  'D2N01130',
]
