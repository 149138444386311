import { get } from 'lodash'

export const getDiscount = (discounts, discountCode) =>
  discounts ? discounts.find(discount => discount.discountCode === discountCode) : null

export const getAmount = (amounts, { value, unit }) =>
  amounts ? amounts.find(amount => amount.value === value && amount.unit === unit) : null

export const getDuration = (durations, value) =>
  durations ? durations.find(duration => duration.value === value) : null

export const getDescription = duration => get(duration, 'description')

export const getAmountsList = (discounts, discountCode) =>
  get(getDiscount(discounts, discountCode), 'amounts')

export const getDurationList = (discounts, discountCode, { value, unit }) =>
  get(getAmount(getAmountsList(discounts, discountCode), { value, unit }), 'durations')

export const mapDiscounts = discounts =>
  discounts.map(discount => ({
    id: discount.discountCode,
    value: discount.discountCode,
    caption: `${discount.discountCode} ${
      discount.amounts.length === 1 && discount.amounts[0].durations.length === 1
        ? `: ${discount.amounts[0].durations[0].description}`
        : ''
    }`,
  }))

export const mapAmounts = amounts =>
  amounts.map(amount => ({
    id: `${amount.value}-${amount.unit}`,
    value: JSON.stringify({ value: amount.value, unit: amount.unit }),
    caption: `${amount.value} ${amount.unit === 'PORCENTAJE' ? '%' : '€'}`,
  }))

export const mapDurations = durations =>
  durations.map(duration => ({
    id: duration.value,
    value: JSON.stringify({ value: duration.value, description: duration.description }),
    caption: `${duration.value} ${duration.value > 1 ? 'meses' : 'mes'}`,
  }))
