import PropTypes from 'prop-types'
import { formatMonthlyFee } from 'utils'
import {
  OffersCard,
  OffersCardFront,
  OfferHeader,
  OfferContent,
  OfferFooter,
  OfferItem,
} from 'components/ui'
import { findFixedProduct, findMobileProduct } from 'services/subscriptions'
import { TariffItem } from './TariffItem'
import DiscountsBonusesItem from './DiscountsBonusesItem'
import PermanentItem from './PermanentItem'
import DeviceItem from './DeviceItem'
import { useTaxApplied } from '../hooks/useTaxApplied'
import { getSubscriptionFeeAmount } from '../helpers'
import { useUpdateSubscriptionHandlers } from '../hooks/useUpdateSubscriptionHandlers'

function SubscriptionCardSplit({ subscription, allowUndoChanges, onModalOpen }) {
  const { onUpdateSubscription } = useUpdateSubscriptionHandlers()

  const { feeAmount } = useTaxApplied({
    feeAmount: getSubscriptionFeeAmount(subscription),
  })

  return (
    <>
      <OffersCard width="288px">
        <OfferHeader
          title={findFixedProduct(subscription).id}
          subtitle="Línea fija + Internet"
          status="unsubscribe"
        />
        <OfferContent>
          <OffersCardFront>
            <OfferItem
              label="Tarifa"
              value={subscription.description}
              actionText="Modificar tarifa"
              disabled
            />
            <OfferItem
              label="Cuota"
              value={formatMonthlyFee(feeAmount)}
              actionText="Añadir descuentos y bonos"
              disabled
            />
            <PermanentItem isFixed subscription={subscription} onModalOpen={onModalOpen} />
            <DeviceItem isFixed subscription={subscription} disabled />
          </OffersCardFront>
        </OfferContent>
        <OfferFooter
          unsubscribed
          onCancel={() =>
            allowUndoChanges && onUpdateSubscription({ confirmedTariff: null, date: null })
          }
        />
      </OffersCard>
      <OffersCard width="288px">
        <OfferHeader
          title={findMobileProduct(subscription).id}
          subtitle="Línea móvil"
          status="new"
        />
        <OfferContent>
          <OffersCardFront>
            <TariffItem subscription={subscription} onModalOpen={onModalOpen} />
            <DiscountsBonusesItem subscription={subscription} onModalOpen={onModalOpen} />
            <PermanentItem isFixed={false} subscription={subscription} onModalOpen={onModalOpen} />
            <DeviceItem isFixed={false} subscription={subscription} disabled />
          </OffersCardFront>
        </OfferContent>
        <OfferFooter />
      </OffersCard>
    </>
  )
}

SubscriptionCardSplit.propTypes = {
  subscription: PropTypes.object.isRequired,
  onModalOpen: PropTypes.func.isRequired,
  allowUndoChanges: PropTypes.bool.isRequired,
}

export default SubscriptionCardSplit
