import { useState, useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'

import { cloneDeep, isEqual } from 'lodash'
import { makeStyles } from '@material-ui/styles'

import {
  Popover,
  Box,
  IconButton,
  Icon,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  FormControl,
  Select,
  MenuItem,
} from '@material-ui/core'
import { Button, ButtonLink, OutlinedDivider } from 'components/ui'

const useStyles = makeStyles({
  'add-order-select': {
    width: 'calc(100% - 30px)',
    margin: '5px 15px',

    '& .MuiSelect-selectMenu': {
      fontSize: '14px',
    },

    '& .MuiSelect-root.MuiSelect-select': {
      padding: '10px',
    },

    '& .Mui-disabled': {
      cursor: 'not-allowed',
    },
  },
})

export const SortableMenu = ({ options, currentOptions, defaultOptions, onChange, isApplied }) => {
  const classes = useStyles()

  const [anchorEl, setAnchorEl] = useState(null)

  const handleOpen = evt => {
    setAnchorEl(evt.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const [selectedOptions, setSelectedOptions] = useState(cloneDeep(defaultOptions))

  useEffect(() => {
    if (!isEqual(currentOptions, selectedOptions)) {
      setSelectedOptions(cloneDeep(currentOptions))
    }
  }, [currentOptions])

  const addonOptions = useMemo(
    () =>
      options.filter(
        option => !selectedOptions.find(selectedOption => selectedOption.id === option.id),
      ),
    [options, selectedOptions],
  )

  const onChangeOrderOption = (id, order) => {
    setSelectedOptions(
      selectedOptions.map(option => ({
        ...option,
        order: option.id === id ? order : option.order,
      })),
    )
  }

  const onDeleteOption = id => {
    setSelectedOptions(selectedOptions.filter(option => option.id !== id))
  }

  const onAddOption = id => {
    const optionToAdd = addonOptions.find(option => option.id === id)

    setSelectedOptions([...selectedOptions, { ...optionToAdd, order: 'asc' }])
  }

  const onResetOptions = () => {
    handleClose()
    onChange(cloneDeep(defaultOptions))
  }

  const onApplyOptions = () => {
    if (selectedOptions.length === 0) {
      onResetOptions()
      return
    }

    handleClose()
    onChange(cloneDeep(selectedOptions))
  }

  return (
    <>
      <IconButton
        color={isApplied ? 'primary' : undefined}
        aria-describedby="sortable-menu"
        data-hook="sortable-menu"
        onClick={handleOpen}>
        <Icon>sort</Icon>
      </IconButton>

      <Popover
        id="sortable-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={!!anchorEl}
        onClose={() => {
          handleClose()
          setSelectedOptions(currentOptions)
        }}
        data-hook="sortable-menu-box">
        <Box width="275px">
          <Box p="15px">
            <Typography variant="body2" component="h6">
              Ordenación
            </Typography>
          </Box>

          <Divider />

          <List style={{ paddingTop: '0' }}>
            {selectedOptions.map(option => (
              <div key={option.id} data-hook="sortable-selected-option">
                <ListItem>
                  <ListItemText data-hook="sortable-selected-option-label" primary={option.name} />

                  <ListItemSecondaryAction style={{ display: 'flex' }}>
                    <Box display="flex" mr="10px" data-hook="sortable-selected-option-order">
                      <IconButton
                        size="small"
                        color={option.order === 'asc' ? 'primary' : undefined}
                        onClick={() => onChangeOrderOption(option.id, 'asc')}>
                        <Icon>north</Icon>
                      </IconButton>

                      <IconButton
                        size="small"
                        color={option.order === 'desc' ? 'primary' : undefined}
                        onClick={() => onChangeOrderOption(option.id, 'desc')}>
                        <Icon>south</Icon>
                      </IconButton>
                    </Box>

                    <IconButton
                      size="small"
                      data-hook="sortable-selected-option-delete"
                      onClick={() => onDeleteOption(option.id)}>
                      <Icon>close</Icon>
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider />
              </div>
            ))}
          </List>

          <FormControl variant="outlined" className={classes['add-order-select']}>
            <Select
              value=""
              disabled={addonOptions.length === 0}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
              data-hook="sortable-add-option"
              onChange={event => onAddOption(event.target.value)}>
              <MenuItem value="" disabled>
                Añadir campo
              </MenuItem>
              {addonOptions.map(item => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Box m="10px 15px 0">
            <OutlinedDivider />
          </Box>

          <Box display="flex" p="5px 15px 15px">
            <ButtonLink onClick={onResetOptions} data-hook="sortable-reset">
              Resetear
            </ButtonLink>

            <Button
              onClick={onApplyOptions}
              data-hook="sortable-apply"
              style={{ marginLeft: 'auto' }}>
              Aplicar
            </Button>
          </Box>
        </Box>
      </Popover>
    </>
  )
}

SortableMenu.defaultProps = {
  defaultOptions: [],
}

const OPTION_PROPS_SHAPE = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  sort: PropTypes.oneOfType([PropTypes.func, PropTypes.string]).isRequired,
}

SortableMenu.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape(OPTION_PROPS_SHAPE)).isRequired,
  currentOptions: PropTypes.arrayOf(
    PropTypes.shape({ ...OPTION_PROPS_SHAPE, order: PropTypes.oneOf(['asc', 'desc']) }),
  ).isRequired,
  defaultOptions: PropTypes.arrayOf(
    PropTypes.shape({ ...OPTION_PROPS_SHAPE, order: PropTypes.oneOf(['asc', 'desc']) }),
  ),
  onChange: PropTypes.func.isRequired,
  isApplied: PropTypes.bool,
}
