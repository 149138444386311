import PropTypes from 'prop-types'
import { useState } from 'react'
import styled from 'styled-components'
import { every, get, remove, head, concat, isEmpty, uniqBy, flattenDeep } from 'lodash'

import { TextField, Typography, Divider } from '@material-ui/core'
import { TextStyles } from 'utils/text'

import { ButtonsSelector } from 'components'
import {
  Modal,
  Button,
  ActionAdvice,
  FormSelectOutlined,
  LineItemCard,
  OutlinedDivider,
  SpinnerCenter,
} from 'components/ui'
import { formatNumberWithComma, formatFee } from 'utils'
import {
  creditNoteCategories,
  creditNoteSubcategories,
  translateCategory,
  translateSubcategory,
  getSubInvoicesObject,
  getSubInvoiceData,
  getCreditSubInvoices,
  filterEmptySubInvoices,
  getInvoiceMsisdns,
} from 'modules/credit'
import { applyTax } from 'services/taxes'
import { CreditResultModal } from './CreditResultModal'
import { CreditSubInvoice } from './CreditSubInvoice'

let creditNoteData = {}

const creditTypeButtons = invoiceTotalAmount => [
  {
    label: `Toda la factura (${formatFee(invoiceTotalAmount)})`,
    value: JSON.stringify({
      partial: false,
    }),
    dataHook: 'total-bill-btn',
  },
  {
    label: 'Factura parcial',
    value: JSON.stringify({
      partial: true,
    }),
    dataHook: 'partial-bill-btn',
  },
]

const partialCreditNoteButtons = subInvoicesWithRows =>
  !isEmpty(subInvoicesWithRows) &&
  subInvoicesWithRows.map(subInvoice => {
    const msisdn = get(subInvoice, 'subInvoice.msisdn')
    const totalAmount = get(subInvoice, 'subInvoice.total')
    return {
      label: `${msisdn} - ${formatFee(totalAmount)}`,
      value: JSON.stringify({
        partial: true,
        msisdn,
        subInvoice: getSubInvoicesObject([subInvoice]),
      }),
      dataHook: `partial-bill-${msisdn}`,
      disabled: totalAmount === 0,
    }
  })

export const CreditModal = styled(
  ({
    className,
    invoice,
    applyCreditNote,
    creditClearError,
    creditErrors,
    isCreditLoading,
    isOpen,
    onCloseModal,
    tax,
  }) => {
    const invoiceId = get(invoice, 'id')
    const invoiceExternalId = get(invoice, 'externalId')
    const invoiceTotalAmount = parseFloat(get(invoice, 'amount')).toFixed(2)
    const subInvoicesWithRows = get(invoice, 'subInvoicesWithRows')
    const defaultLabel = `${formatFee(invoiceTotalAmount)} - Toda la factura`
    const [creditCategory, setCreditCategory] = useState('')
    const [creditAdviceLabels, setCreditAdviceLabels] = useState([])
    const [creditSubcategory, setCreditSubcategory] = useState('')
    const [creditJira, setCreditJira] = useState('')
    const [creditSubInvoices, setCreditSubInvoices] = useState([])
    const [sendData, setSendData] = useState(false)
    const [showResult, setShowResult] = useState(false)
    const [partialCreditNote, setPartialCreditNote] = useState(false)
    const [selectedRows, setSelectedRows] = useState([])
    const [creditMsisdns, setCreditMsisdns] = useState([])
    const [singleRowError, setSingleRowError] = useState(false)

    const getCreditLabels = (changes, subInvoices) => {
      const getRowAmount = (subInvoice, row) => {
        const currentSubInvoice = head(
          subInvoices.filter(
            subInv => get(subInv, 'subInvoice.id') === get(subInvoice, 'sub-invoice'),
          ),
        )
        const currentRow = head(
          get(currentSubInvoice, 'rows').filter(r => get(r, 'id') === get(row, 'invoice-row')),
        )
        const isFreeTaxRow = get(currentRow, 'totalWithoutVat') === get(currentRow, 'total')
        return isFreeTaxRow ? get(row, 'amount') : applyTax(tax, get(row, 'amount'))
      }

      const selectedSubInvoices = changes.map(subInvoice => ({
        'sub-invoice': get(subInvoice, 'sub-invoice'),
        amount: get(subInvoice, 'invoice-rows').reduce(
          (totalAmount, row) => getRowAmount(subInvoice, row) + totalAmount,
          0,
        ),
        amountVat: get(subInvoice, 'total'),
      }))

      const selectedMsisdns = selectedSubInvoices.map(subInvoice => {
        const currentInvoice = subInvoices.filter(
          subInv => get(subInv, 'subInvoice.id') === get(subInvoice, 'sub-invoice'),
        )
        return {
          msisdn: get(head(currentInvoice), 'subInvoice.msisdn'),
          'sub-invoice': get(subInvoice, 'sub-invoice'),
        }
      })

      const labels = selectedSubInvoices.map(subInvoice => {
        const msisdn = selectedMsisdns.filter(
          m => get(m, 'sub-invoice') === get(subInvoice, 'sub-invoice'),
        )
        return {
          label: `${formatFee(get(subInvoice, 'amount'))} - ${get(head(msisdn), 'msisdn')}`,
        }
      })

      return labels
    }

    const isDisabled =
      !every([creditCategory, creditSubcategory, creditJira]) ||
      isEmpty(creditSubInvoices) ||
      isEmpty(selectedRows) ||
      singleRowError

    function setChanges() {
      creditNoteData['reason-category'] = creditCategory
      if (!partialCreditNote) {
        creditNoteData.subInvoices = getSubInvoicesObject(subInvoicesWithRows)
      } else {
        creditNoteData.subInvoices = filterEmptySubInvoices(
          getCreditSubInvoices(creditSubInvoices, selectedRows),
        )
        setCreditAdviceLabels(getCreditLabels(creditNoteData.subInvoices, subInvoicesWithRows))
      }
      creditNoteData.reason = creditSubcategory
      creditNoteData.remark = creditJira
      creditNoteData.invoiceId = invoiceId
      creditNoteData.msisdn = creditMsisdns.join(',')
      creditNoteData.externalId = invoiceExternalId
      setSendData(true)
    }

    function resetChanges() {
      creditNoteData = {}
      setCreditCategory('')
      setCreditAdviceLabels([])
      setCreditSubcategory('')
      setCreditJira('')
      setPartialCreditNote(false)
      setSendData(false)
      setCreditSubInvoices([])
      setSelectedRows([])
      setCreditMsisdns([])
    }

    function submitChanges() {
      applyCreditNote(creditNoteData)
      resetChanges()
      setSendData(false)
      setShowResult(true)
      onCloseModal(false)
    }

    const typeButtons = creditTypeButtons(invoiceTotalAmount).map(button => ({
      ...button,
      selected: get(JSON.parse(get(button, 'value')), 'partial') === partialCreditNote,
    }))

    const isSubInvoiceSelected = subInvoiceId =>
      creditSubInvoices.filter(subInv => get(subInv, 'sub-invoice') === subInvoiceId).length > 0

    const partialCreditButtons = partialCreditNote
      ? partialCreditNoteButtons(subInvoicesWithRows).map(button => ({
          ...button,
          selected: isSubInvoiceSelected(
            get(JSON.parse(get(button, 'value')), 'subInvoice[0].sub-invoice'),
          ),
        }))
      : ''

    const removeMsisdn = msisdn => setCreditMsisdns(remove(creditMsisdns, m => m !== msisdn))

    const addMsisdn = msisdn => {
      if (creditMsisdns.includes(msisdn)) {
        return removeMsisdn(msisdn)
      }

      return setCreditMsisdns(concat(creditMsisdns, msisdn))
    }

    const removeSubInvoice = subInvoice => {
      const rowsToDelete = get(subInvoice, 'invoice-rows').map(row => get(row, 'invoice-row'))
      setSelectedRows(remove(selectedRows, row => !rowsToDelete.includes(get(row, 'invoice-row'))))

      setCreditSubInvoices(
        remove(
          creditSubInvoices,
          subInv => get(subInv, 'sub-invoice') !== get(subInvoice, 'sub-invoice'),
        ),
      )
    }

    const addSubInvoice = subInvoice => {
      if (isSubInvoiceSelected(get(subInvoice, 'sub-invoice'))) {
        return removeSubInvoice(subInvoice)
      }
      const subInvoices = concat(creditSubInvoices, subInvoice)
      const subInvoiceRows = get(subInvoice, 'invoice-rows')
      const rows = concat(selectedRows, subInvoiceRows)
      setSelectedRows(uniqBy(rows, 'invoice-row'))
      return setCreditSubInvoices(uniqBy(subInvoices, 'sub-invoice'))
    }

    return (
      <>
        <Modal
          className={className}
          data-hook="credit-modal"
          isOpen={isOpen}
          title="Abono"
          onClose={() => {
            resetChanges()
            onCloseModal(false)
          }}>
          {!sendData ? (
            <>
              <div>
                <Typography {...TextStyles.subtitle2Link()}>
                  <span className="blue-span">Factura seleccionada</span>
                </Typography>
                <div className="flex align-start p-t-24">
                  <LineItemCard
                    className="header-lines"
                    primaryText="ID Factura"
                    secondaryText={invoiceExternalId}
                  />
                  <LineItemCard
                    className="header-lines"
                    primaryText="Total"
                    secondaryText={`${formatNumberWithComma(invoiceTotalAmount)} €`}
                  />
                </div>
              </div>
              <OutlinedDivider className="divider" />
              <Typography {...TextStyles.subtitle2Link()}>
                <span className="blue-span">Nuevo Abono</span>
              </Typography>

              <div className="flex align-start-center">
                <Typography
                  {...TextStyles.labelDark({
                    className: 'main-text-style p-r-16',
                  })}>
                  Selecciona el tipo de abono:
                </Typography>
                <ButtonsSelector
                  dataHook="credit-type-buttons"
                  justify="flex-row"
                  items={typeButtons}
                  onSelect={value => {
                    const parsedValue = JSON.parse(value)
                    resetChanges()
                    setPartialCreditNote(get(parsedValue, 'partial'))
                  }}
                />
              </div>

              {partialCreditNote && (
                <div data-hook="partial-credit-options">
                  <Typography
                    {...TextStyles.labelDark({
                      className: 'main-text-style',
                    })}>
                    Suscripciones:
                  </Typography>
                  <ButtonsSelector
                    dataHook="partial-credit-buttons"
                    justify="flex-start"
                    isMultiSelect
                    clickToRemove
                    checkbox
                    items={partialCreditButtons}
                    onSelect={value => {
                      const parsedValue = JSON.parse(value)
                      addMsisdn(get(parsedValue, 'msisdn'))
                      addSubInvoice(head(get(parsedValue, 'subInvoice')))
                    }}
                  />
                </div>
              )}

              <div>
                {partialCreditNote &&
                  !isEmpty(creditSubInvoices) &&
                  creditSubInvoices.map(subInvoice => (
                    <CreditSubInvoice
                      subInvoiceObj={head(
                        getSubInvoiceData(subInvoicesWithRows, get(subInvoice, 'sub-invoice')),
                      )}
                      selectedRows={selectedRows}
                      setSelectedRows={setSelectedRows}
                      creditSubInvoices={creditSubInvoices}
                      setSingleRowError={setSingleRowError}
                      tax={tax}
                    />
                  ))}
              </div>

              <div>
                <Typography
                  {...TextStyles.labelDark({
                    className: 'main-text-style p-y-12',
                  })}>
                  Completa los motivos:
                </Typography>
                <div className="p-y-12 flex">
                  <FormSelectOutlined
                    label="Tipología de motivo"
                    className="credit-category-field"
                    data-hook="credit-category"
                    name="credit-category"
                    options={creditNoteCategories}
                    onChange={e => {
                      if (!partialCreditNote) {
                        setCreditSubInvoices(getSubInvoicesObject(subInvoicesWithRows))
                        setSelectedRows(
                          uniqBy(
                            flattenDeep(
                              concat(subInvoicesWithRows.map(subInvoice => subInvoice.rows)),
                            ),
                          ),
                          'invoice-row',
                        )
                        setCreditMsisdns(getInvoiceMsisdns(subInvoicesWithRows))
                        setCreditAdviceLabels([{ label: defaultLabel, subInvoice: '' }])
                      }
                      setCreditCategory(e.target.value)
                    }}
                    value={creditCategory}
                  />

                  <FormSelectOutlined
                    label="Motivo"
                    className="credit-subreason-field"
                    data-hook="credit-subreason"
                    name="credit-subcategory"
                    options={creditNoteSubcategories[creditCategory] || []}
                    onChange={e => {
                      setCreditSubcategory(e.target.value)
                    }}
                    value={creditSubcategory}
                    disabled={!creditCategory}
                  />

                  <TextField
                    className="credit-jira-field"
                    label="Motivo Jira"
                    name="credit-jira"
                    onChange={e => setCreditJira(e.target.value)}
                    type="text"
                    value={creditJira}
                    variant="outlined"
                    disabled={!creditSubcategory}
                  />
                </div>
              </div>

              <Divider className="divider" />

              <div className="p-b-24">
                <Button
                  className="button"
                  data-hook="next-btn"
                  onClick={() => setChanges()}
                  disabled={isDisabled}>
                  Continuar
                </Button>

                <Button
                  secondary
                  onClick={() => {
                    resetChanges()
                    onCloseModal(false)
                  }}>
                  Cancelar
                </Button>
              </div>
            </>
          ) : (
            <div data-hook="confirm-credit-changes">
              <Typography
                {...TextStyles.labelDark({
                  className: 'p-b-24 main-text-style',
                })}>
                Se va a realizar el siguiente abono:
              </Typography>

              <div className="flex flex-wrap">
                {creditAdviceLabels &&
                  creditAdviceLabels.map(label => (
                    <ActionAdvice
                      className="credit-advice p-r-12 p-b-12"
                      message={get(label, 'label')}
                    />
                  ))}
              </div>

              <div className="flex align-start p-t-12">
                <LineItemCard
                  primaryText="Tipología de motivo"
                  secondaryText={translateCategory(get(creditNoteData, 'reason-category'))}
                  className="header-lines"
                />
                <LineItemCard
                  primaryText="Motivo"
                  secondaryText={translateSubcategory(
                    get(creditNoteData, 'reason-category'),
                    get(creditNoteData, 'reason'),
                  )}
                  className="header-lines"
                />
                <LineItemCard
                  primaryText="Motivo Jira"
                  secondaryText={get(creditNoteData, 'remark')}
                  className="header-lines"
                />
              </div>

              <OutlinedDivider className="divider" />

              <Button
                className="button"
                data-hook="apply-credit-btn"
                onClick={() => submitChanges()}
                disabled={isDisabled}>
                Confirmar cambios
              </Button>

              <Button data-hook="cancel-credit-btn" secondary onClick={() => setSendData(false)}>
                Volver Atrás
              </Button>
            </div>
          )}
        </Modal>

        {isCreditLoading && <SpinnerCenter absolute style={{ marginTop: '50px' }} />}
        {!isCreditLoading && (
          <CreditResultModal
            errors={creditErrors}
            isOpen={showResult}
            onClose={() => {
              setShowResult(false)
              creditClearError()
            }}
          />
        )}
      </>
    )
  },
)`
  min-width: 550px;
  .header-lines {
    padding: 0 24px 0 0;
    width: auto;
  }
  .credit-category-field {
    width: 38%;
  }
  .credit-subreason-field {
    margin: 0 28px;
    width: 28%;
  }
  .credit-jira-field {
    width: 28%;
  }
  .button {
    margin-right: 10px;
  }
  .divider {
    margin: 20px 0;
  }
  .MuiButtonBase-root {
    display: unset;
  }
  .message {
    font-weight: 500;
  }
  .main-text-style {
    color: ${({ theme }) => theme.palette.global.gray_dark};
  }
  .credit-advice {
    min-width: 220px;
  }
`

CreditModal.propTypes = {
  className: PropTypes.string,
  applyCreditNote: PropTypes.func,
  creditClearError: PropTypes.func,
  creditErrors: PropTypes.object,
  isCreditLoading: PropTypes.bool,
  isOpen: PropTypes.bool,
  onCloseModal: PropTypes.func,
}
