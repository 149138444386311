import PropTypes from 'prop-types'

import './CustomerBillingAddress.css'
import { Section } from '../styles'

const CustomerBillingAddress = ({ billingAddress }) => {
  const country = billingAddress.country ? billingAddress.country : {}
  return (
    <Section>
      <div>
        <strong>Billing Address</strong>
      </div>
      <div>{billingAddress.streetOne}</div>
      <div>{billingAddress.streetTwo}</div>
      <div>{billingAddress.postCode}</div>
      <div>{billingAddress.city}</div>
      <div>{country.value}</div>
    </Section>
  )
}
CustomerBillingAddress.propTypes = {
  billingAddress: PropTypes.object,
}

export default CustomerBillingAddress
