import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLegalTexts } from 'modules/LegalTexts/hooks'

import { putHomeGoSvaStart } from 'services/customer-sva/actions'
import { MasConsentsGdpr } from 'modules/mas-consents'
import { ACQUISITION_CONSENT } from 'modules/mas-consents/constants'

import { withCheckoutForms } from '../../../hocs'
import { selectNextSteps } from '../../../store/selectors/NextStepsContainer.selectors'
import { NextStepsAndResult } from '../../../components/NextStepsAndResult/NextStepsAndResult'

function NextStepsCnt() {
  const dispatch = useDispatch()

  const {
    terminals,
    singlePaymentTerminals,
    saleType: { isPos },
    is2p,
    gdprData,
    shouldShowGdpr,
    homeGoSvaRetryData,
    updateClientConsentsFlag,
    isCartera,
    isSubscription,
    isCrossSell,
    isCustomerCanceled,
    ...restProps
  } = useSelector(selectNextSteps)

  const [showGdpr, setShowGdpr] = useState(shouldShowGdpr)
  const [gdprHasError, setGdprHasError] = useState(false)

  const { legalTexts } = useLegalTexts({ origin: 'sales_next_steps' })

  const onGdprSuccess = () => {
    setShowGdpr(false)
  }

  const onGdprError = () => {
    setShowGdpr(false)
    setGdprHasError(true)
  }

  const retryHomeGoSva = () => {
    dispatch(putHomeGoSvaStart(homeGoSvaRetryData))
  }

  const shouldUpdateClientConsents =
    updateClientConsentsFlag &&
    ((!isCartera && !isSubscription && !isCrossSell) || isCustomerCanceled)

  return (
    <>
      <MasConsentsGdpr
        show={showGdpr}
        onSuccess={onGdprSuccess}
        onError={onGdprError}
        consentType={ACQUISITION_CONSENT}
        shouldUpdateClientConsents={shouldUpdateClientConsents}
        {...gdprData}
      />

      {!showGdpr && (
        <NextStepsAndResult
          nextStepsTexts={legalTexts}
          isPos={isPos}
          financedTerminals={terminals}
          singlePaymentTerminals={singlePaymentTerminals}
          is2p={is2p}
          gdprHasError={gdprHasError}
          retryHomeGoSva={retryHomeGoSva}
          {...restProps}
        />
      )}
    </>
  )
}

export const NextStepsContainer = withCheckoutForms(NextStepsCnt)
