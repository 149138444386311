import { useState, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import {
  Button,
  LineItemCard,
  ButtonSwitch,
  NotificationModal,
  Advice,
  ButtonCard,
  SpinnerCenter,
} from 'components/ui'

import { selectCanIDO, permissions } from 'modules/Permissions'
import { REGEX_SFID_DOCTOR_GO } from 'modules/Permissions/constants'
import {
  selectHebeClientFlag,
  selectors as customerSvaSelectors,
  selectHasHebeSva,
  selectCustomerSvaDoctorGo,
} from 'services/customer-sva/selectors'
import { formatFee } from 'utils'
import { useProvinceTaxes } from 'services/taxes/hooks'
import { SVA_DOCTORGO_CODE, SVA_HEBE_CUSTOMER_CODE } from 'modules/offers-configuration/constants'
import { selectIsBibeAllowed } from 'modules/SVAs/store/svas.selectors'
import { TO_ACCEPT, TRY_AGAIN } from 'modules/SharedSales/constants/literals'
import { useCustomerSva } from 'modules/NewClientSales/hooks/CustomerSva/useCustomerSva'
import { useHebeSvaCustomer } from 'modules/NewClientSales/hooks/CustomerSva/useHebeSvaCustomer'
import {
  getIdentificationId,
  getIdentificationType,
  selectFirstFixedLine,
  selectors as customer360Selectors,
} from 'services/customer-360/selectors'
import { APP_CONFIG } from 'services/app-config/index'
import {
  selectFetchCutomerSvasFlag,
  selectDoctorGoUnsubscribeFlag,
  selectNewNetkiaCustomerUrl,
} from 'services/feature-flag/selectors'

const useStyles = makeStyles({
  svasBox: {
    width: '100%',

    '& .sva-button': {
      whiteSpace: 'nowrap',
    },
  },
  svaLineItemText: {
    minWidth: '130px',
    maxWidth: '230px',
  },
})

const NOTIFICATION_MODAL_TYPES = {
  success: {
    type: 'success',
    iconPath: '/assets/clap_clap.svg',
    notificationTitle: 'Se ha registrado la modificación del SVA con éxito',
    subTitle: 'Esta operación puede durar unos minutos',
    buttonLabel: TO_ACCEPT,
  },
  alert: {
    type: 'alert',
    iconPath: '/assets/error.svg',
    notificationTitle: 'No se ha podido modificar el SVA',
    subTitle: '',
    buttonLabel: TRY_AGAIN,
  },
}

export const TabClientSvas = () => {
  const classes = useStyles()

  const flagDoctorGoUnsubscribe = useSelector(selectDoctorGoUnsubscribeFlag)
  const flagHebeSvaClient = useSelector(selectHebeClientFlag)
  const newNetkiaCustomerUrl = useSelector(selectNewNetkiaCustomerUrl)

  const canFetchCustomerSvas = useSelector(selectFetchCutomerSvasFlag)
  const hasDoctorGoSva = useSelector(customerSvaSelectors.hasDoctorGoSva)
  const hasHebeSva = useSelector(selectHasHebeSva)

  const canDoctorGo =
    useSelector(selectCanIDO(permissions.cvm_doctor_go.id, null, REGEX_SFID_DOCTOR_GO)) &&
    flagDoctorGoUnsubscribe

  const findSvaError = useSelector(customerSvaSelectors.error)
  const msgSvaError = findSvaError ? 'No se ha podido consultar el estado del servicio' : ''

  const [svaDoctorGoStatus, setDoctorGoStatus] = useState(hasDoctorGoSva)
  const [svaHebeStatus, setHebeStatus] = useState(hasHebeSva)

  const documentId = useSelector(getIdentificationId)
  const documentType = useSelector(getIdentificationType)

  const customerSvaDoctorGo = useSelector(selectCustomerSvaDoctorGo)
  const firstFixedLine = useSelector(selectFirstFixedLine)

  const postCode = useSelector(customer360Selectors.getBillingAddressPostCode)

  useProvinceTaxes(postCode)

  const {
    fetchDisableCustomerSva,
    isDisableCustomerSvaSuccess,
    isDisableCustomerSvaError,
    isDisableCustomerSvaLoading,
    isDisableCustomerSvaPending,
    putDisableCustomerSvaPending,
    resetDisableCustomerSva,
  } = useCustomerSva()

  const {
    fetchEnableHebeSvaCustomer,
    fetchDisableHebeSvaCustomer,
    isHebeSvaCustomerSuccess,
    isHebeSvaCustomerError,
    isHebeSvaCustomerLoading,
    resetHebeSvaCustomer,
  } = useHebeSvaCustomer()

  const svasError = isDisableCustomerSvaError || isHebeSvaCustomerError

  const notificationType = svasError ? 'alert' : 'success'
  const showModalSvaNotification =
    svasError || isDisableCustomerSvaSuccess || isHebeSvaCustomerSuccess

  const isBibeAllowed = useSelector(selectIsBibeAllowed)

  const handleOnChangeSvaButtonSwitch = useCallback(
    svaType => {
      if (svaType === SVA_DOCTORGO_CODE) {
        fetchDisableCustomerSva(documentId, documentType, customerSvaDoctorGo.msisdn)
        setDoctorGoStatus(!svaDoctorGoStatus)
      }
      if (svaType === SVA_HEBE_CUSTOMER_CODE && firstFixedLine) {
        if (svaHebeStatus) {
          fetchDisableHebeSvaCustomer(firstFixedLine.line)
        } else {
          fetchEnableHebeSvaCustomer(firstFixedLine.line)
        }
        setHebeStatus(!svaHebeStatus)
      }
    },
    [svaDoctorGoStatus, svaHebeStatus],
  )

  const handleOnCloseNotification = useCallback(() => {
    if (isDisableCustomerSvaSuccess) {
      putDisableCustomerSvaPending()
    } else {
      resetDisableCustomerSva()
    }
    resetHebeSvaCustomer()
  }, [isDisableCustomerSvaSuccess])

  useEffect(() => setDoctorGoStatus(hasDoctorGoSva), [hasDoctorGoSva])
  useEffect(() => {
    if (isDisableCustomerSvaError) {
      setDoctorGoStatus(!svaDoctorGoStatus)
    }
    if (isHebeSvaCustomerError) {
      setHebeStatus(!svaHebeStatus)
    }
  }, [isDisableCustomerSvaError, isHebeSvaCustomerError])

  const generateLineItemSwitch = (
    primaryText,
    checked,
    onChange,
    disabled,
    secondaryText = '',
    alert = '',
  ) => (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <LineItemCard
        primaryText={primaryText}
        secondaryText={secondaryText}
        className={classes.svaLineItemText}
        labelBlock={!!secondaryText}
      />

      <Box pr="24px">
        <ButtonSwitch checked={checked} onChange={onChange} disabled={disabled} />
      </Box>

      {alert && <Advice type="warning">{alert}</Advice>}
    </Box>
  )

  return (
    <Box display="flex" data-hook="svas-tab" py="12px" className={classes.svasBox}>
      <div className="svas-container">
        {(isDisableCustomerSvaLoading || isHebeSvaCustomerLoading) && (
          <SpinnerCenter size={64} style={{ marginTop: '50px', marginBottom: '50px' }} />
        )}

        {generateLineItemSwitch(
          'Doctor GO',
          svaDoctorGoStatus && !isDisableCustomerSvaPending,
          () => {
            handleOnChangeSvaButtonSwitch(SVA_DOCTORGO_CODE)
          },
          !svaDoctorGoStatus ||
            !customerSvaDoctorGo ||
            !customerSvaDoctorGo.msisdn ||
            isDisableCustomerSvaPending ||
            !canDoctorGo,
          canFetchCustomerSvas && customerSvaDoctorGo
            ? `Cuota: ${formatFee(customerSvaDoctorGo.monthlyFeeWithTax)}/mes`
            : null,
          msgSvaError,
        )}

        {flagHebeSvaClient &&
          generateLineItemSwitch(
            'HEBE',
            svaHebeStatus,
            () => {
              handleOnChangeSvaButtonSwitch(SVA_HEBE_CUSTOMER_CODE)
            },
            findSvaError || !firstFixedLine,
            null,
            msgSvaError,
          )}

        {isBibeAllowed && (
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <LineItemCard primaryText="Seguros" className={classes.svaLineItemText} />

            <Box pr="24px">
              <ButtonCard
                label="Web gestión ATC"
                className="sva-button"
                icon="input"
                onClick={() =>
                  window.open(
                    newNetkiaCustomerUrl ? APP_CONFIG.new_bibe_broker : APP_CONFIG.bibe_broker,
                    '_blank',
                  )
                }
              />
            </Box>
          </Box>
        )}

        <NotificationModal
          type={NOTIFICATION_MODAL_TYPES[notificationType].type}
          src={NOTIFICATION_MODAL_TYPES[notificationType].iconPath}
          title={NOTIFICATION_MODAL_TYPES[notificationType].notificationTitle}
          message={svasError}
          isOpen={showModalSvaNotification}
          onClose={handleOnCloseNotification}>
          <Typography variant="body1" className="m-b-32">
            {NOTIFICATION_MODAL_TYPES[notificationType].subTitle}
          </Typography>
          <Button data-hook="action-accept" onClick={handleOnCloseNotification}>
            {NOTIFICATION_MODAL_TYPES[notificationType].buttonLabel}
          </Button>
        </NotificationModal>
      </div>
    </Box>
  )
}
