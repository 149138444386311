import PropTypes from 'prop-types'

import SimpleLayout from './SimpleLayout'

const SimpleLayoutContainer = props => <SimpleLayout {...props} />

SimpleLayoutContainer.propTypes = {
  isAgentLoggedIn: PropTypes.bool,
}

export default SimpleLayoutContainer
