import PropTypes from 'prop-types'

import { CircularProgress, Box, Typography } from '@material-ui/core'
import { ButtonLink } from 'components/ui'

import { SelectedProvisioningNumber } from './components/SelectedProvisioningNumberComponent'
import { SearchNumber } from './components/SearchNumberComponent'

export function SignUpLine({
  isMobile,
  fieldNames,
  enabledSearchNewNumber,
  lineType,
  onSearchButton,
  signUpProps,
  isElFijo,
  isMobileOnly,
  disableOfferEdit,
  isLeadFilled,
}) {
  const { isLoading, onChangeDesiredNumber, onSelectNewNumber, options } = signUpProps

  return !isMobileOnly || disableOfferEdit ? (
    <>
      <Box display="flex" flexWrap="wrap" alignSelf="center" alignItems="center">
        <Typography variant="body1" className="p-r-4">
          Nº asignado:
        </Typography>

        {isLoading ? (
          <Box display="flex" alignItems="center" ml="8px" mr="20px">
            <CircularProgress size="16px" />
          </Box>
        ) : (
          <SelectedProvisioningNumber numberDisplay={signUpProps} lineType={lineType} />
        )}

        {isMobile && !isElFijo && (
          <ButtonLink
            dataHook={`${lineType}.searchNewNumberButton`}
            type="button"
            disabled={isMobileOnly && !isLeadFilled}
            onClick={() => onSearchButton(enabledSearchNewNumber, fieldNames)}>
            Buscar número concreto
          </ButtonLink>
        )}
      </Box>
      {enabledSearchNewNumber && (
        <SearchNumber
          fieldNames={fieldNames}
          mobileNumberOptions={options}
          onChangeDesiredNumber={onChangeDesiredNumber}
          onSelectNewNumber={onSelectNewNumber}
        />
      )}
    </>
  ) : null
}

SignUpLine.propTypes = {
  fieldNames: PropTypes.object.isRequired,
  enabledSearchNewNumber: PropTypes.bool,
  isMobile: PropTypes.bool,
  isElFijo: PropTypes.bool,
  lineType: PropTypes.string,
  onSearchButton: PropTypes.func,
  isMobileOnly: PropTypes.bool,
  disableOfferEdit: PropTypes.bool,
  isLeadFilled: PropTypes.bool,
  signUpProps: PropTypes.shape({
    onSelectNewNumber: PropTypes.func.isRequired,
    onChangeDesiredNumber: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    options: PropTypes.array,
  }),
}
