import { useState } from 'react'
import { fetchTXTContract } from 'modules/NewClientSales/services/txtContract.service'

export const useDownloadFileTxt = () => {
  const [state, setState] = useState({
    isLoading: false,
    error: null,
  })

  function downloadFromPromiseTxt(trackId) {
    setState({
      isLoading: true,
      error: null,
    })

    fetchTXTContract(trackId, true)
      .then(() => {
        setState({
          isLoading: false,
          error: null,
        })
      })
      .catch(e => {
        setState({
          isLoading: false,
          error: e,
        })
      })
  }

  return [state, downloadFromPromiseTxt]
}
