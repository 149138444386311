const FIND_INIT = 'OVID/CUSTOMERNEW/FIND_INIT'
const FIND = 'OVID/CUSTOMERNEW/FIND'
const FIND_SUCCESS = 'OVID/CUSTOMERNEW/FIND_SUCCESS'
const FIND_FAILED = 'OVID/CUSTOMERNEW/FIND_FAILED'
const RESET = 'OVID/CUSTOMERNEW/RESET'

export const constants = {
  FIND_INIT,
  FIND,
  FIND_SUCCESS,
  FIND_FAILED,
  RESET,
}
