import pathToRegexp from 'path-to-regexp'
import { objectToQueryString } from 'modules/url'

import { AppPaths } from '../AppPaths'

export const buildPath = (appPathId, pathValues, queryValues = {}) => {
  if (!AppPaths[appPathId].regexPath) return ''
  const pathWithValues = pathValues
    ? pathToRegexp.compile(AppPaths[appPathId].regexPath)(pathValues)
    : AppPaths[appPathId].regexPath

  return queryValues ? [pathWithValues, objectToQueryString(queryValues)].join('?') : pathWithValues
}
