import { createSelector } from 'reselect'
import { get, head } from 'lodash'
import { selectFeatureFlagByName } from 'services/feature-flag/selectors'
import { COMPANY } from 'services/global-constants'
import { selectAllSVAList } from 'modules/SVAs/store/svas.selectors'

import { selectIsMultiSimQueryParam } from 'modules/Router/store/router.selectors'
import {
  formatAdditionalContracts,
  formatCheckoutOrderMainLineInfo,
} from './orders.selectors.helpers'
import { selectAccount, selectOrderCheckout } from './Basket.selectors'

// TODO: move to orders module
export const getNewOrder = state => state.ordersV3.newOrder
export const getCreationStatus = state => state.ordersV3.creationStatus
export const selectTaskId = createSelector(
  getNewOrder,
  order => order.data.taskId,
)

export const selectIsOrderCreated = createSelector(
  getNewOrder,
  order => order.data.taskId && !order.error_code,
)

export const selectOrderLoading = createSelector(
  getNewOrder,
  order => order.loading,
)

export const selectOrderIsProcessed = createSelector(
  [selectOrderLoading, selectIsOrderCreated],
  (orderIsLoading, orderIsCreated) => orderIsLoading || !!orderIsCreated,
)

export const selectOrderCheckingFinished = createSelector(
  getCreationStatus,
  status => get(status.data, 'status') === 'finished',
)

export const selectOrderCheckingId = createSelector(
  getCreationStatus,
  status => {
    const lines = get(status.data, 'success_response')

    if (!lines) {
      return null
    }

    return lines[Object.keys(lines)[0]]?.id
  },
)

export const selectStatusError = createSelector(
  getCreationStatus,
  status => get(status.data, 'error_response', {}),
)

export const selectStatusSuccess = createSelector(
  getCreationStatus,
  status => get(status.data, 'success_response', {}),
)

const selectMainLineStatusSuccess = createSelector(
  selectStatusSuccess,
  selectIsMultiSimQueryParam,
  (status, isMultiSim) => {
    const key = isMultiSim
      ? head(Object.keys(status))
      : Object.keys(status).find(i => i.indexOf('main-') !== -1)

    return key ? status[key] : null
  },
)

export const selectOrderStatusId = createSelector(
  selectMainLineStatusSuccess,
  status => get(status, 'id'),
)

export const selectOrderStatusCharacteristics = createSelector(
  selectMainLineStatusSuccess,
  status => get(status, 'characteristics'),
)

export const selectOrderStatusScoring = createSelector(
  selectOrderStatusCharacteristics,
  chars => {
    const result = chars.find(char => char.name === 'rove')
    return result ? get(result, 'value.level') : '0'
  },
)

export const selectOrderStatusScoringResult = createSelector(
  selectOrderStatusCharacteristics,
  chars => {
    const result = chars.find(char => char.name === 'rove')
    return get(result, 'value.result')
  },
)

export const selectCompanyAppointmentFlag = createSelector(
  state => state,
  state => selectFeatureFlagByName('company_appointment')(state),
)

export const selectStatusScoringValue = createSelector(
  selectAccount,
  selectOrderStatusScoringResult,
  selectOrderStatusScoring,
  selectCompanyAppointmentFlag,
  (account, result, level, flag) => (account.segment === COMPANY && flag ? result : level),
)

// TODO: Hopefully someday the backend return a list of orders
export const selectOrdersStatus = createSelector(
  getCreationStatus,
  status => get(status, 'data.operation_status'),
)

export const selectHasFailedMainLine = createSelector(
  selectStatusError,
  error => !!Object.keys(error).find(i => i.indexOf('main-') !== -1),
)

// Returns a array list with orderIds and statuses
export const selectOrders = createSelector(
  selectStatusSuccess,
  selectStatusError,
  selectOrdersStatus,
  selectAllSVAList,
  selectOrderCheckout,
  (successInfo, errorInfo, orderStatus, svas, orderCheckout) => {
    return Object.keys(orderStatus).map(orderKey => {
      const isSuccess = orderStatus[orderKey] === 'success'

      const info = isSuccess ? successInfo : errorInfo

      const contracts = formatAdditionalContracts(
        info && info[orderKey],
        orderKey,
        svas,
        orderCheckout,
      )

      return {
        orderKey,
        success: isSuccess,
        contracts,
        ...info[orderKey],
      }
    })
  },
)

// Select if the order has failed
export const selectOrderStatusError = createSelector(
  getCreationStatus,
  selectOrderCheckingFinished,
  selectStatusError,
  (status, isFinished, errorMsg) => {
    const errorItem = Object.keys(errorMsg).find(i => i.indexOf('main-') !== -1)
    return (
      (!!status.error_code && status.error_code !== '404') ||
      (isFinished && (errorItem ? errorMsg[errorItem] : null))
    )
  },
)

// Select if the order has succeded
export const selectOrderStatusSuccess = createSelector(
  [selectHasFailedMainLine, selectOrderCheckingFinished],
  (hasFailedMainLine, isFinished) => !hasFailedMainLine && isFinished,
)

// Polling start time, for timeout error checking
export const selectStartPollingTime = createSelector(
  state => state.ordersV3,
  orders => orders.pollingTime,
)

export const selectErrorFetchRetries = createSelector(
  state => state.ordersV3,
  orders => orders.fetchRetries,
)

export const selectContractId = createSelector(
  selectOrderStatusCharacteristics,
  characteristics => {
    const contractId = characteristics
      ? characteristics.find(i => i.name === 'reference_number')
      : ''
    return contractId ? contractId.value : ''
  },
)

export const selectSubscriptionId = createSelector(
  selectOrderStatusCharacteristics,
  characteristics => {
    const subscriptionId = characteristics
      ? characteristics.find(i => i.name === 'subscription_id')
      : ''
    return subscriptionId ? subscriptionId.value : ''
  },
)

export const selectOrderAccountId = createSelector(
  selectOrderStatusCharacteristics,
  characteristics => {
    const accountId = characteristics ? characteristics.find(i => i.name === 'account_id') : ''
    return accountId ? accountId.value : ''
  },
)

export const selectCheckoutOrderMainLineInfo = createSelector(
  selectOrderCheckout,
  orderCheckout => formatCheckoutOrderMainLineInfo(orderCheckout),
)
