import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import { push } from 'connected-react-router'
import { Formik } from 'formik'
import { Box } from '@material-ui/core'

import { Button, FormikInput } from 'components/ui'
import { authService } from 'modules/Auth'
import { objectToQueryString } from 'modules/url'

const ChannelChooserForm = ({
  values,
  setFieldValue,
  handleSubmit,
  buttons,
  onChange,
  disabled,
}) => {
  useEffect(() => {
    onChange(values)
  }, [values])

  return (
    <form onSubmit={handleSubmit}>
      <Box display="flex" mb="40px" width="480px" justifyContent="space-between" className="inputs">
        <Box mr="20px">
          <FormikInput label="SFID" name="sfid" trim />
        </Box>
        <Box>
          <FormikInput label="Reseller" name="reseller" trim uppercase />
        </Box>
      </Box>

      <Box display="flex" justifyContent="space-between">
        {buttons.map(({ text, value }) => (
          <Button
            key={`btn-${text}`}
            className="channel-button"
            type="submit"
            onClick={() => {
              setFieldValue('channel', value)
            }}
            data-hook="clients"
            variant="contained"
            disabled={!(values.sfid && values.reseller) || disabled}
            color="primary">
            {text}
          </Button>
        ))}
      </Box>
    </form>
  )
}

ChannelChooserForm.propTypes = {
  values: PropTypes.object,
  setFieldValue: PropTypes.func,
  handleSubmit: PropTypes.func,
  buttons: PropTypes.array,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
}

export const ChannelChooser = () => {
  const dispatch = useDispatch()

  const [targetURL, setTargetURL] = useState()

  async function generateURLS(values) {
    try {
      if (!values.sfid) return
      const response = await authService.generateUUID(values.sfid)
      if (response && response.uuid) {
        const queryString = objectToQueryString({
          reseller: values.reseller,
          sfid: values.sfid,
          uuid: response.uuid,
        })
        setTargetURL(queryString)
      }
    } catch (e) {
      console.warn('uuid:error', e)
    }
  }

  const buttons = [
    {
      text: 'Telesales',
      value: 'telesales',
      url: `/customer-search?brand=yoigo&channel=telesales&${targetURL}`,
    },
    {
      text: 'Telesales PRO',
      value: 'telesales_pro',
      url: `/customer-search?brand=yoigo&channel=telesales_pro&${targetURL}`,
    },
    {
      text: 'POS',
      value: 'pos',
      url: `/customer-search?brand=yoigo&channel=pos&${targetURL}`,
    },
  ]

  return (
    <Box
      display="flex"
      height="100vh"
      alignItems="center"
      flexDirection="column"
      justifyContent="center">
      <Formik
        initialValues={{ sfid: 'AW001200', reseller: 'BY', channel: '' }}
        onSubmit={values => {
          const selectedButton = buttons.find(button => button.value === values.channel)
          if (selectedButton?.url) {
            dispatch(push(selectedButton.url))
          }
        }}>
        {props => (
          <ChannelChooserForm
            {...props}
            buttons={buttons}
            onChange={values => {
              setTargetURL('')
              generateURLS(values)
            }}
            disabled={!targetURL}
          />
        )}
      </Formik>
    </Box>
  )
}
