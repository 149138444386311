export const CUSTOMER_WRITE_ATTEMPTED = 'OVID/CUSTOMER_WRITE/ATTEMPTED'
export const CUSTOMER_WRITE_SUCCEEDED = 'OVID/CUSTOMER_WRITE/SUCCEEDED'
export const CUSTOMER_WRITE_FAILED = 'OVID/CUSTOMER_WRITE/FAILED'
export const CLEAR_CUSTOMER_WRITE_ERROR = 'OVID/CUSTOMER_WRITE/CLEAR_ERROR'

export const customerWriteAction = (customerId, changesData) => ({
  type: CUSTOMER_WRITE_ATTEMPTED,
  payload: {
    customerId,
    changesData,
  },
})

export const customerWriteSuccededAction = data => ({
  type: CUSTOMER_WRITE_SUCCEEDED,
  payload: {
    data,
  },
})

export const customerWriteFailedAction = error => ({
  type: CUSTOMER_WRITE_FAILED,
  payload: error,
})

export const customerWriteClearErrorAction = () => ({
  type: CLEAR_CUSTOMER_WRITE_ERROR,
})
