import { axiosJWT, axiosCustomer } from 'modules/axios'
import { APP_CONFIG } from 'services/app-config'

const CACHE_PREPAID_LINE_INFO = {}

export function fetchPrepaidLine(productId) {
  const cacheKey = btoa(JSON.stringify({ productId }))

  if (CACHE_PREPAID_LINE_INFO[cacheKey]) {
    return CACHE_PREPAID_LINE_INFO[cacheKey]
  }

  CACHE_PREPAID_LINE_INFO[cacheKey] = axiosJWT({
    method: 'GET',
    url: `${APP_CONFIG.care_cluster}/search-360/product/${productId}/prepaid_info`,
    headers: {
      ...axiosJWT.defaults.headers.common,
      'X-Authorization': axiosCustomer.defaults.headers.common.Authorization,
    },
  })
    .then(resp => {
      return resp
    })
    .catch(err => {
      CACHE_PREPAID_LINE_INFO[cacheKey] = null
      throw err
    })

  return CACHE_PREPAID_LINE_INFO[cacheKey]
}
