const CHECK_INIT = 'OVID/WIZARD/CHECK_INIT'
const CHECK = 'OVID/WIZARD/CHECK'
const CHECKED = 'OVID/WIZARD/CHECKED'
const CHECK_FAILED = 'OVID/WIZARD/CHECK_FAILED'

export const constants = {
  CHECK_INIT,
  CHECK,
  CHECKED,
  CHECK_FAILED,
}
