import { get } from 'lodash/fp'

import { FETCH_CUSTOMER, FETCH_CUSTOMER_SUCCESS, FETCH_CUSTOMER_ERROR } from './constants'

const initialCustomerData = {
  data: null,
  error: null,
  loading: false,
}

export const reducer = (state = initialCustomerData, { type, payload }) => {
  switch (type) {
    case FETCH_CUSTOMER: {
      return {
        ...state,
        error: null,
        loading: true,
      }
    }
    case FETCH_CUSTOMER_SUCCESS: {
      return {
        ...state,
        data: get('data', payload),
        error: null,
        loading: false,
      }
    }
    case FETCH_CUSTOMER_ERROR: {
      return {
        ...state,
        error: get('error', payload) || payload,
        loading: false,
      }
    }

    default:
      return state
  }
}
