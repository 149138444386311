import { Grid } from '@material-ui/core'
import { styled } from '@material-ui/styles'

const SectionValidationGrid = styled(Grid)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '85%',
})

export default SectionValidationGrid
