import { useEffect, memo, useCallback, useState } from 'react'
import { isEmpty } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { useLoading } from 'modules/Core'
import { helpers as customer360SvcHelpers } from 'services/customer-360/helpers'
import * as resetStoreSvc from 'services/reset-store'
import { actions as coverageSvc } from 'modules/Coverage'

import { usePreventBackwards } from 'modules/ui/hooks'

import { useAnalytics } from 'modules/Analytics/hooks/index'

import { alarmActions } from 'modules/alarms/store'

import { useFetchCustomerIds } from 'modules/CustomerInfo'

import { useFetchCustomerSvas } from 'modules/NewClientSales/hooks/CustomerSva/useFetchCustomerSvas'
import { useHomeGoSva } from 'modules/NewClientSales/hooks/CustomerSva/useHomeGoSva'
import { dashboardContainerSelector } from './DashboardContainer.selectors'
import * as saga from '../../sagas/dashboardLoad'
import { Dashboard } from '../../components/Dashboard'

export const DashboardContainer = memo(() => {
  const dispatch = useDispatch()
  const analytics = useAnalytics()

  const childProps = useSelector(dashboardContainerSelector)

  const {
    customer360Error,
    hasErrors,
    documentId,
    documentType,
    documentTypeURL,
    isLoading,
    queryParams,
    contracts,
    customerId,
    sfid,
    areaSfid,
    identificationType,
    identificationId,
    gdprData,
    isPosOrMasProximo,
  } = childProps

  const [showGdpr, setShowGdpr] = useState(false)
  const [hasGdprError, setHasGdprError] = useState(false)
  const isDocumentTypeUnknown = documentTypeURL === 'UNKNOWN'
  const isInloading = useLoading(documentId)

  const { fetchCustomerIds, customerIdsIsLoading, customerIdsError } = useFetchCustomerIds()

  const { addPreventBackwards, removePreventBackwards } = usePreventBackwards()
  const { fetchCustomerSvas } = useFetchCustomerSvas()
  const { findHomeGoSvaInit } = useHomeGoSva()

  function gotCoverageData(data) {
    return dispatch(coverageSvc.gotCoverageData(data))
  }

  const loadInit = useCallback(() => {
    dispatch(saga.actions.loadInit(documentType, documentId))
  }, [documentType, documentId])

  useEffect(() => {
    dispatch(resetStoreSvc.actions.resetStore())
    if (documentId) {
      loadInit()
      setShowGdpr(true)

      setHasGdprError(false)
    }
  }, [documentId])

  useEffect(() => {
    if (gdprData?.hasError) {
      setHasGdprError(true)
    }
  }, [gdprData])

  useEffect(() => {
    if (identificationType && identificationId) {
      fetchCustomerIds(identificationType, identificationId)
    }
  }, [identificationType, documentId])

  let errorSearch

  if (isDocumentTypeUnknown || (customer360Error && customer360Error.httpCode === 404)) {
    errorSearch = 'notFound'
  } else if (customer360Error || hasErrors) {
    errorSearch = 'unknown'
  }

  const loading =
    (!queryParams.isSalesFlow && !documentId && !isDocumentTypeUnknown) ||
    (!!documentId && (isInloading || isLoading))
  const allLinesCustomer = customer360SvcHelpers.getAllLinesAndElFijoMsisdn(contracts)

  useEffect(() => {
    if (!loading && !isEmpty(allLinesCustomer) && !customerIdsIsLoading) {
      fetchCustomerSvas()
    }
  }, [loading, contracts, customerIdsIsLoading])

  useEffect(() => {
    if (!loading && !isEmpty(allLinesCustomer)) {
      dispatch(alarmActions.findAlarmsAction(allLinesCustomer))
      findHomeGoSvaInit()
    }
  }, [loading, contracts])

  useEffect(() => {
    // ANALYTICS - Saves traces of the search by the agent in analytics

    if (!loading && !!customerId && !!analytics) {
      analytics.sendCustomEvent({
        dataToSend: {
          eventAction: 'Accion/ConsultaClientePlataforma360',
          eventLabel: documentType,
          sfid,
          valorConsultado: customerId,
          areaAsesor: areaSfid,
        },
      })
    }
  }, [loading, documentType, sfid, analytics, customerId, areaSfid])

  function onGdprSuccess() {
    setShowGdpr(false)
    loadInit()
  }

  function onGdprError() {
    setShowGdpr(false)
    setHasGdprError(true)
  }

  function onGdprClose() {
    setShowGdpr(false)
  }

  useEffect(() => {
    if (documentId) {
      removePreventBackwards()
    } else {
      addPreventBackwards()
    }

    return () => {
      removePreventBackwards()
    }
  }, [documentId])

  return (
    <Dashboard
      {...childProps}
      gotCoverageData={gotCoverageData}
      errorSearch={errorSearch}
      isLoading={loading}
      onGdprSuccess={onGdprSuccess}
      onGdprError={onGdprError}
      onGdprClose={onGdprClose}
      showGdpr={showGdpr}
      hasGdprError={hasGdprError}
      setHasGdprError={setHasGdprError}
      hasCustomerIdsLoaded={!customerIdsIsLoading && !customerIdsError}
      isPosOrMasProximo={isPosOrMasProximo}
    />
  )
})
