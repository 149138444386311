import styled from 'styled-components'
import { get } from 'lodash'
import { AccountDashboard } from './AccountDashboard'

// TODO: Remove when adding the real components
export const AccountDashboardUI = styled(AccountDashboard)`
  background-color: ${props => get(props, 'theme.palette.brand.light')};
  flex-grow: 1;

  .tabs {
    padding: 10px 20px 0;
  }

  .tab-content {
    background-color: ${props => get(props, 'theme.palette.global.gray_light')};
    margin-top: 0;
  }
`
