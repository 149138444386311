import PropTypes from 'prop-types'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'
import { ButtonLink } from '../buttons/ButtonLink'

const Branch = styled.ul`
  border-bottom-left-radius: 10px;
  border-left: ${({ level }) => (level ? '1px solid rgba(185, 194, 199, 0.5)' : 'none')};
  list-style: none;
  margin: 5px 0 0 10px;
  padding: 5px 0;
`

const BranchItem = styled.li`
  border-top: ${({ level }) => (!level ? '1px solid rgba(185, 194, 199, 0.5)' : 'none')};
  margin-top: ${({ level }) => (!level ? '5px' : '0')};
  padding: 5px 10px;
`

const BranchItemLabel = styled.span`
  display: flex;
`

const Caption = styled(Typography)`
  && {
    color: #b9c2c7;
    margin-left: 10px;
  }
`

function TreeBranch({ subnodes, level, onClickItem }) {
  if (!subnodes.length) return null
  return (
    <Branch level={level}>
      {subnodes.map(({ id, title, caption, meta, nodes = [], disabled = false }) => (
        <BranchItem key={id} level={level}>
          <BranchItemLabel>
            <ButtonLink disabled={disabled} onClick={() => onClickItem({ id, meta })}>
              {title}
            </ButtonLink>
            <Caption variant="caption">{caption}</Caption>
          </BranchItemLabel>
          <TreeBranch subnodes={nodes} level={level + 1} onClickItem={onClickItem} />
        </BranchItem>
      ))}
    </Branch>
  )
}

TreeBranch.propTypes = {
  subnodes: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      id: PropTypes.string,
      caption: PropTypes.string,
      meta: PropTypes.any,
      nodes: PropTypes.array,
      disabled: PropTypes.bool,
    }),
  ),
  level: PropTypes.number,
  onClickItem: PropTypes.func,
}

TreeBranch.defaultProps = {
  subnodes: [],
  level: 0,
}

export default TreeBranch
