import PropTypes from 'prop-types'
import { Divider, Typography } from '@material-ui/core'

import { TextStyles } from 'utils/text'

import { isTerminalPaymentMultiple } from '../../helpers'
import { TerminalSummaryInterface } from '../../model/TerminalFormModel'

export const TerminalSummary = ({ terminal, className, lineType }) => {
  const isPaymentSeveral = isTerminalPaymentMultiple(terminal)
  const initialPayment = terminal.computedPrices.initialPayment || 0
  const monthlyPayment = terminal.computedPrices.monthlyPayment || 0
  const finalPayment = terminal.computedPrices.finalPayment || 0
  const { fees } = terminal.computedPrices

  const totalPayment = initialPayment + monthlyPayment * fees + finalPayment
  return (
    <div className={className}>
      <Divider className="divider" absolute style={{ margin: '0 0 16px' }} />

      <div className="flex flex-column">
        <Typography
          {...TextStyles.subtitle2Dark({
            className: 'm-y-20',
          })}
          data-hook={`${lineType}.terminal.name`}>
          Dispositivo: {terminal.details.name}
        </Typography>
        <div className="flex flex-wrap payments">
          <div className="p-r-36 flex align-start-center">
            <Typography {...TextStyles.paragraphDark()}>Pago:</Typography>
            <Typography
              {...TextStyles.subtitle2Dark({
                className: 'p-l-4',
              })}
              data-hook={`${lineType}.terminal.paymentType`}>
              {isPaymentSeveral ? 'A plazos' : 'Único'}
            </Typography>
          </div>
          {isPaymentSeveral ? (
            <>
              <div className="p-r-36 flex align-start-center">
                <Typography {...TextStyles.paragraphDark()}>P. inicial:</Typography>
                <Typography
                  {...TextStyles.subtitle2Dark({
                    className: 'p-l-4',
                  })}
                  data-hook={`${lineType}.terminal.initial`}>
                  {initialPayment} €
                </Typography>
              </div>
              <div className="p-r-36 flex align-start-center">
                <Typography {...TextStyles.paragraphDark()}>{`P. mes: (${fees})`}</Typography>
                <Typography
                  {...TextStyles.subtitle2Dark({
                    className: 'p-l-4',
                  })}
                  data-hook={`${lineType}.terminal.monthly`}>
                  {monthlyPayment} €/mes
                </Typography>
              </div>
              <div className="p-r-36 flex align-start-center">
                <Typography {...TextStyles.paragraphDark()}>P. final:</Typography>
                <Typography
                  {...TextStyles.subtitle2Dark({
                    className: 'p-l-4',
                  })}
                  data-hook={`${lineType}.terminal.final`}>
                  {finalPayment} €
                </Typography>
              </div>
            </>
          ) : (
            <div className="flex align-start-center">
              <Typography
                {...TextStyles.paragraphDark({
                  className: 'p-l-4',
                })}>
                Precio:
              </Typography>
              <Typography
                {...TextStyles.subtitle2Dark({
                  className: 'p-l-4',
                })}
                data-hook={`${lineType}.terminal.total`}>
                {totalPayment} €
              </Typography>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

TerminalSummary.propTypes = {
  terminal: TerminalSummaryInterface,
  className: PropTypes.string,
  lineType: PropTypes.string,
}
