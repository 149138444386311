export const getTypeFromCallCase = callCase => {
  switch (callCase) {
    case 2:
      return 'INTERNATIONAL'
    case 9:
    case 63:
    case 64:
    case 65:
    case 66:
    case 67:
    case 68:
    case 69:
      return 'CALL_PREMIUM'
    case 52:
      return 'SMS'
    case 71:
    case 72:
      return 'SMS_PREMIUM'
    default:
      return 'CALL'
  }
}
