import PropTypes from 'prop-types'

export function ChangeOwnerSearchResult({ className, customer }) {
  return <p className={className}>Search result: {customer}</p>
}

ChangeOwnerSearchResult.propTypes = {
  customer: PropTypes.string,
  className: PropTypes.string,
}
