import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useFormikContext } from 'formik'

import { Card, CardContent } from '@material-ui/core'

import { useSetParentAgreementId } from '../../../hooks/Cartera/useSetParentAgreementId'

import { Lead } from '../LeadComponent'

import { selectFormatedMobileTariffs, selectHasExtraLines } from '../../../store'

import { ExtraMobileLineOffer } from '../../../store/models'
import { ExtraLinesContainer } from '../../../containers/OfferContainer'

export function MultiSimSale() {
  const { setFieldValue } = useFormikContext()

  const [selectedTariff] = useSelector(selectFormatedMobileTariffs)
  const hasExtraLines = useSelector(selectHasExtraLines)

  useEffect(() => {
    if (!hasExtraLines) {
      setFieldValue('extraMobileLinesOffers', [{ ...ExtraMobileLineOffer, tariff: selectedTariff }])
    }
  }, [])

  useSetParentAgreementId(true, false, true)

  return (
    <>
      <Card>
        <CardContent>
          <Lead isMultiSim />
        </CardContent>
      </Card>

      <ExtraLinesContainer isMultiSim />
    </>
  )
}
