import { useState } from 'react'
import PropTypes from 'prop-types'
import styled, { withTheme } from 'styled-components'
import { get } from 'lodash'
import { Typography, Button, Grid } from '@material-ui/core'
import { SpinnerCenter } from 'components/ui'
import { Dialog } from 'components'
import Info from '@material-ui/icons/Info'

const SummaryWrapper = styled.div`
  margin-top: 50px;
`
const InfoButton = styled(Info)`
  cursor: pointer;
  padding-left: 5px;
  margin-bottom: -5px;
  color: ${({ theme }) => get(theme, 'palette.secondary.main')};
`
const StyledTypography = styled(Typography)`
  margin: 10px;
  margin-top: ${props => get(props, 'margin-top', '0')};
  margin-bottom: ${props => get(props, 'margin-bottom', '0')};
  align: ${props => get(props, 'align', 'left')};
  margin-left: 0;
`
const Code = styled.span`
  display: inline;
  text-decoration: ${({ incompatibilityrule }) =>
    incompatibilityrule === 'Cancel' || incompatibilityrule === 'Reject' ? 'line-through' : 'none'};
`

const StyledGrid = styled(Grid)`
  display: flex;
  align-items: center;
`
const StyledGridInline = styled.span`
  display: inline;
`
function DiscountsSummary({ selectedDiscount, discounts, onApplyDiscount, loading }) {
  const [state, setState] = useState({
    showDialogCommercialInfo: false,
    discount: null,
  })

  const setCommercialInfo = selectedDiscountValue => {
    setState({
      showDialogCommercialInfo: true,
      discount: selectedDiscountValue,
    })
  }

  const originalDiscounts = () =>
    discounts.map((discount, index) => (
      <StyledGridInline key={discount.id}>
        {index > 0 ? ' + ' : ''}
        <Code incompatibilityrule={discount.incompatibilityRule} variant="body2">
          {discount.discountCode}
        </Code>
        <InfoButton onClick={() => setCommercialInfo(discount)} />
      </StyledGridInline>
    ))

  const finalDiscounts = () => {
    return discounts
      .filter(
        discount =>
          discount.incompatibilityRule !== 'Cancel' && discount.incompatibilityRule !== 'Reject',
      )
      .map(discount => discount.discountCode)
      .concat(selectedDiscount.discountCode)
      .join(' + ')
  }

  const closeDialogCommercialInfo = () => {
    setState({ showDialogCommercialInfo: false })
  }

  const ShowDialogCommercialInfo = () => {
    const { discount } = state
    return (
      <Dialog open alignment="center" onClose={closeDialogCommercialInfo}>
        <StyledTypography variant="subtitle1">INFORMACIÓN COMERCIAL:</StyledTypography>
        {discount.discountCode ? (
          <>
            <StyledTypography variant="body2" margin-top="40px">
              CÓDIGO:
            </StyledTypography>
            <StyledTypography variant="body1" margin-bottom="40px">
              {discount.discountCode}
            </StyledTypography>
          </>
        ) : null}

        {discount.description ? (
          <>
            <StyledTypography variant="body2" margin-top="40px">
              DESCRIPCIÓN:
            </StyledTypography>
            <StyledTypography variant="body1" margin-bottom="40px">
              {discount.description}
            </StyledTypography>
          </>
        ) : null}

        {discount.amount && discount.unit ? (
          <>
            <StyledTypography variant="body2" margin-top="40px">
              DESCUENTO:
            </StyledTypography>
            <StyledTypography variant="body1" margin-bottom="40px">
              {discount.amount + (discount.unit === 'IMPORT' ? '€' : '%')}
            </StyledTypography>
          </>
        ) : null}

        {discount.duration ? (
          <>
            <StyledTypography variant="body2" margin-top="40px">
              DURACIÓN:
            </StyledTypography>
            <StyledTypography variant="body1" margin-bottom="40px">
              {discount.duration + (discount.duration > 1 ? ' MESES' : ' MES')}
            </StyledTypography>
          </>
        ) : null}

        <Button color="default" onClick={closeDialogCommercialInfo}>
          CERRAR
        </Button>
      </Dialog>
    )
  }

  const { showDialogCommercialInfo } = state
  const msgNoDiscounts = '-'
  const msgNoSelectedDiscount = 'Por favor, elige nuevo descuento.'
  return loading ? (
    <Grid direction="column" alignItems="baseline" container>
      <SpinnerCenter />
    </Grid>
  ) : (
    <SummaryWrapper data-hook="discounts-summary">
      <Typography variant="body2" align="left">
        Resumen:
      </Typography>
      <StyledGrid>
        <StyledTypography variant="body1" align="left">
          Descuentos de la orden:
        </StyledTypography>
        <StyledTypography variant="body2">
          {discounts.length ? originalDiscounts() : msgNoDiscounts}
        </StyledTypography>
      </StyledGrid>

      <StyledGrid>
        <StyledTypography variant="body1" align="left">
          Descuento nuevo:
        </StyledTypography>
        <StyledTypography variant="body2">
          {selectedDiscount ? (
            <>
              {selectedDiscount.discountCode}
              <InfoButton
                data-hook="btnCommercialInfo"
                onClick={() => setCommercialInfo(selectedDiscount)}
              />
            </>
          ) : (
            msgNoSelectedDiscount
          )}
        </StyledTypography>
      </StyledGrid>

      <StyledTypography variant="body1" align="left" margin-top="20px">
        Descuentos finales:
      </StyledTypography>
      <StyledTypography variant="body2" align="left" margin-bottom="40px">
        {selectedDiscount ? finalDiscounts() : msgNoSelectedDiscount}
      </StyledTypography>
      <Grid container>
        <Button
          data-hook="applyDiscountBtn"
          onClick={onApplyDiscount}
          variant="contained"
          color="primary"
          disabled={selectedDiscount === null}>
          Aplicar Descuento
        </Button>
      </Grid>
      {showDialogCommercialInfo && ShowDialogCommercialInfo()}
    </SummaryWrapper>
  )
}

DiscountsSummary.propTypes = {
  discounts: PropTypes.arrayOf(PropTypes.object),
  selectedDiscount: PropTypes.object,
  onApplyDiscount: PropTypes.func,
  loading: PropTypes.bool,
}

DiscountsSummary.defaultProps = {
  discounts: [],
}

export default withTheme(DiscountsSummary)
