import { useMemo, useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { useSelector, useDispatch } from 'react-redux'
import { Formik } from 'formik'
import { Typography } from '@material-ui/core'

import { TextStyles } from 'utils/text'

import { ValidateEmail } from 'utils'
import {
  Modal,
  FormikInput,
  ModalActions,
  Button,
  SpinnerCenter,
  SpinnerButton,
  NotificationModal,
} from 'components/ui'

import { useFetchCustomerIds, modifyCustomerContactMedia } from 'modules/CustomerInfo'
import { modifyContactEmailModalSelectors } from './ModifyContactEmailModal.selectors'

const ModifyContactEmailForm = ({ handleSubmit, errors, isSaving, onClose }) => {
  const hasErrors = useMemo(() => Object.keys(errors).length > 0, [errors])

  const validate = v => {
    if (!v) {
      return 'Campo requerido'
    }

    if (!ValidateEmail(v)) {
      return 'Email no válido'
    }

    return false
  }

  return (
    <form onSubmit={handleSubmit} style={{ paddingBottom: '1px' }}>
      <FormikInput
        name="email"
        fullWidth
        label="Email"
        validate={validate}
        disabled={{ bool: isSaving }}
        style={{ maxHeight: '76px' }}
      />

      <ModalActions>
        {!isSaving && (
          <Button type="submit" disabled={hasErrors || isSaving}>
            Aceptar
          </Button>
        )}

        {isSaving && <SpinnerButton />}

        {onClose && (
          <Button secondary onClick={onClose}>
            Cancelar
          </Button>
        )}
      </ModalActions>
    </form>
  )
}

ModifyContactEmailForm.propTypes = {
  errors: PropTypes.object,
  handleSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  isSaving: PropTypes.bool,
}

export const ModifyContactEmailModal = ({
  customer,
  current,
  open,
  onClose,
  onSuccess,
  children,
}) => {
  const dispatch = useDispatch()

  const { accountId, customerInfoIsLoading, customerInfoError, isSaving, hasError } = useSelector(
    modifyContactEmailModalSelectors,
  )

  const [isShowResultModal, setIsShowResultModal] = useState(false)
  const [isInitialized, setIsInitialized] = useState(false)
  const [submittedValues, setSubmittedValues] = useState(null)

  const { fetchCustomerIds } = useFetchCustomerIds()

  useEffect(() => {
    if (open && !!customer) {
      fetchCustomerIds(customer.docType, customer.docNumber)
      setIsInitialized(true)
    }
  }, [open, customer])

  const onSubmit = useCallback(
    values => {
      dispatch(
        modifyCustomerContactMedia(
          accountId,
          customer?.isCompany ? 'pro' : 'residential',
          {
            email: values.email,
          },
          3000,
        ),
      )

      setSubmittedValues(values.email)
    },
    [accountId, customer],
  )

  useEffect(() => {
    if (
      (!!submittedValues && !isSaving) ||
      (open && isInitialized && !customerInfoIsLoading && customerInfoError)
    ) {
      onClose()
      setIsShowResultModal(true)
    }
  }, [submittedValues, isSaving, open, isInitialized, customerInfoIsLoading, customerInfoError])

  const onNotificationClose = () => {
    setIsShowResultModal(false)
    setIsInitialized(false)
    setSubmittedValues(null)

    if (onSuccess && !hasError && !customerInfoError) {
      onSuccess(submittedValues)
    }
  }

  const resultModalTitle = useMemo(() => {
    if (hasError) {
      return 'Error intentando actualizar la dirección email'
    }

    if (customerInfoError) {
      return 'Error obteniendo los datos del cliente'
    }

    return 'Dirección email actualizada!'
  }, [customerInfoError, hasError])

  return (
    <>
      <NotificationModal
        isOpen={isShowResultModal}
        onClose={onNotificationClose}
        type={hasError || customerInfoError ? 'alert' : 'success'}
        src={hasError || customerInfoError ? '/assets/error.svg' : '/assets/clap_clap.svg'}
        title={resultModalTitle}>
        {(hasError || customerInfoError) && (
          <Typography
            {...TextStyles.paragraphDark({
              style: { marginBottom: '15px' },
            })}>
            Por favor, inténtalo más tarde.
          </Typography>
        )}
        <Button onClick={onNotificationClose}>Aceptar</Button>
      </NotificationModal>

      <Modal title="Modificar dirección email" isOpen={open} onClose={onClose}>
        {(customerInfoIsLoading || !!customerInfoError) && (
          <SpinnerCenter style={{ marginTop: '20px' }} />
        )}

        {!!accountId && (
          <>
            {children}

            <Formik
              initialValues={{
                email: current,
              }}
              onSubmit={onSubmit}>
              {({ handleSubmit, errors }) => (
                <ModifyContactEmailForm
                  errors={errors}
                  handleSubmit={handleSubmit}
                  isSaving={isSaving}
                  onClose={onClose}
                />
              )}
            </Formik>
          </>
        )}
      </Modal>
    </>
  )
}

ModifyContactEmailModal.propTypes = {
  customer: PropTypes.shape({
    docType: PropTypes.string.isRequired,
    docNumber: PropTypes.string.isRequired,
    isCompany: PropTypes.bool,
  }).isRequired,
  current: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  children: PropTypes.node,
}
