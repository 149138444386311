import { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Typography, Icon } from '@material-ui/core'
import { TextStyles } from 'utils/text'
import { ICONS } from 'utils/icons'

import { ButtonLink } from 'components/ui'
import { Card } from 'modules/ui'
import { formatHHmm_H } from 'utils/formatting/dates'
import { get, groupBy, last, first, orderBy } from 'lodash'
import { format } from 'date-fns'
import { APP_CONFIG } from 'services/app-config'
import es from 'date-fns/locale/es'
import { MSG_TYPES } from './constants'

function groupMsgsByDay(msgs) {
  const objByDay = groupBy(msgs, msg => format(get(msg, 'date'), 'P', { locale: es }))
  return orderBy(Object.entries(objByDay), ele => first(ele), ['asc'])
}

export const ChatCard = styled(({ className, msgs = [] }) => {
  const chatWrapper = useRef(null)
  useEffect(() => {
    if (chatWrapper.current) {
      chatWrapper.current.scrollTo(0, 100000)
    }
  }, [msgs])
  const daysWithMsgs = groupMsgsByDay(msgs)
  return (
    <Card
      refContent={chatWrapper}
      className={`${className}`}
      id="chat-card"
      title="Conversación"
      toggable
      childrenInHeader={
        <ButtonLink
          onClick={() => window.open(`${APP_CONFIG.liveperson}`, '_blank')}
          fullWidth={false}
          disabled={false}
          primary={false}
          type="primary">
          Live Person
          <Icon className="icon launch-icon">{ICONS.launch}</Icon>
        </ButtonLink>
      }>
      <div className="chat-wrapper">
        {daysWithMsgs.map((msgsByDay, i) => (
          <div key={`day-${i + 1}`}>
            <div className="day-wrapper">
              <div className="day-pill">{first(msgsByDay)}</div>
              {last(msgsByDay).map(msg => (
                <div
                  key={`${msg.date.toGMTString()}-${msg.sentBy}`}
                  className={msg.sentBy === 'Agent' ? 'agentMsg' : 'consumerMsg'}
                  data-hook={msg.sentBy === 'Agent' ? 'agent-msg' : 'consumer-msg'}>
                  <Typography
                    {...TextStyles.labelDark({
                      component: 'div',
                      className: 'header',
                    })}>
                    {`${msg.participant}   |   ${formatHHmm_H(msg.date)}`}
                  </Typography>
                  {msg.type === MSG_TYPES.STD ? (
                    <div className="bubble">
                      <Typography
                        {...TextStyles.labelDark({
                          component: 'div',
                          className: 'msg-text',
                        })}>
                        {msg.text}
                      </Typography>
                    </div>
                  ) : (
                    <div className="bubble transfer" data-hook="bubble-transfer">
                      <Typography
                        {...TextStyles.paragraphDark({
                          component: 'div',
                          className: 'msg-text',
                        })}>
                        <>
                          Transferido a <span className="bold">{msg.targetSkill}</span>
                        </>
                      </Typography>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </Card>
  )
})`
  && .card-content {
    padding: 20px 24px 18px 24px;
  }
  .launch-icon {
    font-size: 16px;
    margin-left: 4px;
  }
  .day-wrapper {
    display: flex;
    flex-direction: column;
  }
  .day-pill {
    align-self: center;
    padding: 4px 8px;
    font-size: 10px;
    background-color: ${props => get(props, 'theme.palette.global.gray_light')};
    color: ${props => get(props, 'theme.palette.global.gray_medium_06')};
  }
  .header {
    text-transform: uppercase;
    color: ${props => get(props, 'theme.palette.global.gray_medium_06')};
    font-size: 11px;
    font-weight: bold;
    padding: 0 20px;
  }
  .bubble {
    margin: 8px 0;
    border-radius: 20px;
    width: 80%;
    padding: 12px;
    line-height: 1.2;
  }
  .agentMsg {
    float: right;
    padding: 8px 0 4px 0;
    width: 100%;
  }
  .agentMsg .bubble {
    color: ${props => get(props, 'theme.palette.bluePalette.main')};
    background-color: #fff;
    border: 2px ${props => get(props, 'theme.palette.bluePalette.main')} solid;
    float: right;
  }
  .agentMsg .bubble.transfer {
    background-color: ${props => get(props, 'theme.palette.global.gray_light')};
    border: none;
    width: 80%;
  }
  .agentMsg .header {
    text-align: right;
  }
  .consumerMsg .header {
    text-align: left;
  }
  .consumerMsg {
    padding: 8px 0 4px 0;
    float: left;
    width: 100%;
  }
  .consumerMsg .bubble {
    background-color: ${props => get(props, 'theme.palette.bluePalette.main')};
    float: left;
  }
  .consumerMsg .msg-text {
    color: #fff;
  }
`

ChatCard.propTypes = {
  msgs: PropTypes.arrayOf(PropTypes.object),
  className: PropTypes.string,
}
