import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/styles'
import { Card, Box, Icon, Typography } from '@material-ui/core'

import { TextStyles } from 'utils/text'

const useStyles = makeStyles(theme => ({
  'toggle-card-button': {
    outline: 'none',
    opacity: ({ disabled }) => disabled && 0.5,

    '&:focus > div': {
      borderColor: ({ selected, disabled }) => !selected && !disabled && '#CCC',
    },

    '& > div': {
      width: '100%',
      height: '100%',
      cursor: ({ disabled }) => !disabled && 'pointer',
      margin: 0,
      border: ({ selected }) => `2px solid ${selected ? theme.palette.primary.main : '#FFF'}`,
      backgroundColor: ({ selected }) => selected && theme.palette.bluePalette.blue_50,

      '& .icon': {
        color: theme.palette.global.gray_dark,
        fontSize: '120px',
      },

      '& h5': {
        marginTop: '32px',
        textAlign: 'center',
      },
    },
  },
}))

export const ToggleCardButton = ({ className, icon, label, selected, onSelected, disabled }) => {
  const classes = useStyles({ selected, disabled })

  return (
    <a
      className={[classes['toggle-card-button'], className].join(' ')}
      onClick={!selected && !disabled ? onSelected : undefined}
      onKeyPress={evt => {
        if (evt.key === 'Enter' && !selected && !disabled) {
          onSelected()
        }
      }}
      role="button"
      tabIndex="0"
      aria-disabled={disabled}>
      <Card>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          p="20px"
          height="100%">
          {icon && <Icon className="icon">{icon}</Icon>}

          <Typography {...TextStyles.title3Dark()}>{label}</Typography>
        </Box>
      </Card>
    </a>
  )
}

ToggleCardButton.defaultProps = {
  className: '',
}

ToggleCardButton.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string,
  label: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  onSelected: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}
