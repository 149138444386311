import { connect } from 'formik'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'

import { SpinnerButton, Button } from 'components/packages/ui/buttons'
import { useScrollToFirstInputError } from 'modules/Core/FormUtils'

const SaveChangesStepButtonComponent = ({
  children,
  isLoading,
  beforeContinue,
  onContinue,
  afterContinue,
  formik,
  hasValidationErrors,
  disabled,
  customValidationAction,
  setFormData,
}) => {
  const { scrollToFirstError } = useScrollToFirstInputError()

  function checkStatusErrors() {
    const errors = Object.values(formik.status).filter(elem => !!elem)
    return errors.length > 0
  }

  async function validateErrors() {
    const errors = await formik.validateForm()

    formik.setTouched(errors)
    const shouldShowError =
      !isEmpty(errors) || hasValidationErrors || isLoading || checkStatusErrors()

    if (shouldShowError && scrollToFirstError) {
      scrollToFirstError(errors)
    }
    return shouldShowError
  }

  async function validateAndOnContinue() {
    if (beforeContinue) {
      await beforeContinue()
    }

    const hasErrors = await validateErrors()

    formik.setSubmitting(true)

    if (hasErrors || (customValidationAction && !(await customValidationAction()))) {
      formik.setSubmitting(false)
      return
    }

    if (setFormData) {
      setFormData(formik.values)
    }

    try {
      if (afterContinue) {
        afterContinue(formik.values)
      }

      await onContinue()
    } catch (e) {
      console.warn(e)
    }

    formik.setSubmitting(false)
  }

  return isLoading ? (
    <SpinnerButton style={{ marginLeft: '16px' }} disabled />
  ) : (
    <Button
      type="button"
      onClick={validateAndOnContinue}
      disabled={disabled}
      name="pageContinue"
      style={{ marginLeft: '16px' }}
      data-hook="page-continue">
      {children}
    </Button>
  )
}

SaveChangesStepButtonComponent.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  isLoading: PropTypes.bool,
  beforeContinue: PropTypes.func,
  onContinue: PropTypes.func.isRequired,
  setFormData: PropTypes.func,
  formik: PropTypes.object.isRequired,
  hasValidationErrors: PropTypes.bool,
  afterContinue: PropTypes.func,
  disabled: PropTypes.bool,
  customValidationAction: PropTypes.func,
}

export const SaveChangesStepButton = connect(SaveChangesStepButtonComponent)
