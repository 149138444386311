export const getSegmentIcon = segment => {
  if (segment)
    switch (segment.toLowerCase()) {
      case 'consumer':
        return 'home'
      case 'influencer':
        return 'verified'
      case 'pro-soho-autonomo':
      case 'pro-soho-company':
        return 'star'
      case 'soho-company':
      case 'soho-autonomo':
        return 'work'
      case 'warning':
        return 'warning'
      default:
        return 'home'
    }
  else {
    return 'home'
  }
}

export const deviceIconType = device => {
  switch (true) {
    case device.includes('PlayStation'):
      return 'videogame'
    case device.includes('SmartTV'):
      return 'tv'
    default:
      return 'mobile'
  }
}

export const ICONS = {
  add: 'add',
  asideMenu: 'calendar_view_day',
  archive: 'archive',
  back: 'arrow_back',
  bills: 'insert_drive_file',
  box: 'unarchive',
  calendar: 'calendar_today',
  circle: 'lens',
  client: 'person_outline',
  cancel: 'cancel',
  close: 'close',
  comments: 'comments',
  createTicket: 'note_add',
  delete: 'delete',
  delivery: 'local_shipping',
  done: 'done',
  remove_done: 'remove_done',
  download: 'get_app',
  downArrow: 'arrow_drop_down',
  upArrow: 'arrow_drop_up',
  edit: 'edit',
  email: 'email',
  error: 'error_outline',
  errorFilled: 'error',
  expandArrow: 'expand_more',
  expandLess: 'expand_less',
  forward: 'arrow_forward',
  generalView: '360',
  home: 'home',
  info: 'info',
  launch: 'launch',
  menu: 'menu',
  messages: 'forum',
  mobile: 'stay_primary_portrait',
  moreVert: 'more_vert',
  notes: 'assignment',
  phone: 'phone',
  offers: 'tune',
  otherServices: 'devices',
  profile: 'person_outline',
  router: 'router',
  addressPoint: 'room',
  search: 'search',
  suscriptions: 'list',
  suspend: 'ac_unit',
  split: 'swap_horiz',
  tickets: 'calendar_view_day',
  transferData: 'import_export',
  workOrders: 'business_center',
  upload: 'cloud_upload',
  minimize: 'unfold_less',
  maximize: 'unfold_more',
  left: 'chevron_left',
  warning: 'warning',
  refresh: 'refresh',
  right: 'chevron_right',
  eye: 'remove_red_eye',
  filter: 'filter_list',
  simCard: 'sim_card',
  cart: 'shopping_cart',
  formatLeft: 'format_align_left',
  smsTipification: 'question_answer',
  Entrante: 'call',
  Saliente: 'phone_callback',
  description: 'description',
  build: 'build',
  work: 'work',
  exitToApp: 'exit_to_app',
  event: 'event',
  star: 'star',
  visibility: 'visibility',
  tv: 'tv',
  live_tv: 'live_tv',
  roaming: 'flight',
  warningOutlined: 'report_problem_outlined',
  euro: 'euro',
  receipt: 'receipt',
  crop_din: 'crop_din',
  playlist_add_check: 'playlist_add_check',
  help: 'help',
  flag: 'flag',
  videogame: 'sports_esports',
  verified: 'verified',
  bulb: 'emoji_objects',
  paid: 'paid',
  linked_camera: 'linked_camera',
  catching: 'catching_pokemon',
  input: 'input',
}
