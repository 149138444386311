import { chain } from 'lodash'

export const formatAddressStr = addressInfo => {
  if (!addressInfo) return '-'
  const { streetOne, streetTwo, postCode, city, characteristic } = addressInfo

  const streetNumber =
    addressInfo.streetNumber ||
    chain(characteristic)
      .find({ name: 'streetNumber' })
      .get('value')
      .value()

  const province =
    addressInfo.province ||
    chain(characteristic)
      .find({ name: 'province' })
      .get('value')
      .value()

  return [
    streetOne,
    streetNumber,
    streetTwo && `${streetTwo}.`,
    postCode.concat(' -'),
    `${city}`,
    province && `, ${province}`,
  ]
    .filter(Boolean)
    .join(' ')
}

export const formatCensuredNif = nif => {
  if (!nif) {
    return ''
  }

  return [nif.slice(0, 1), ['*****'], nif.slice(6, 9)].join('')
}

export const formatCensuredDni = dni => {
  if (!dni) {
    return ''
  }

  return [['*****'], dni.slice(5, 9)].join('')
}

export const formatCensuredIban = iban => {
  if (!iban) {
    return ''
  }

  const ibanStr = iban.toString(iban)
  const ibanSlice = [
    ibanStr.slice(0, 4),
    ibanStr.slice(4, 8),
    ibanStr.slice(8, 12),
    ['**'],
    ['*****'],
  ].join(' ')
  return [ibanSlice, ibanStr.slice(19, 24)].join('')
}

export const formatIBAN = iban => {
  if (!iban) {
    return ''
  }

  const ibanStr = iban.toString(iban)
  return [
    ibanStr.slice(0, 4),
    ibanStr.slice(4, 8),
    ibanStr.slice(8, 12),
    ibanStr.slice(12, 16),
    ibanStr.slice(16, 20),
    ibanStr.slice(20, 24),
  ].join(' ')
}
