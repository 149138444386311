import PropTypes from 'prop-types'
import { get } from 'lodash'
import { OfferItem } from 'components/ui'
import { findMobilePermanent, findFixedProduct, findMobileProduct } from 'services/subscriptions'

const DeviceItem = ({ subscription, isFixed, disabled }) => {
  const permanent = isFixed ? undefined : findMobilePermanent(subscription)
  const product = isFixed ? findFixedProduct(subscription) : findMobileProduct(subscription)

  if (!product) {
    return null
  }

  return (
    <OfferItem
      label={`Dispositivo${isFixed ? ' Internet' : ' Móvil'}`}
      value={isFixed ? 'Router' : get(permanent, 'item.name', 'No')}
      actionText="Añadir un dispositivo"
      disabled={disabled}
    />
  )
}

DeviceItem.propTypes = {
  subscription: PropTypes.object,
  isFixed: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
}

export default DeviceItem
