import PropTypes from 'prop-types'

import { Box, Card, CardContent } from '@material-ui/core'

import { TitleSidebar, TextSidebar } from 'components/ui'

export const FinancedDeviceDecisionsInfo = ({ decisionsInfo }) => {
  const { deviceName, summaryDecisions } = decisionsInfo
  const showDeviceDecisionsInfo = deviceName && summaryDecisions && summaryDecisions.length > 0

  return (
    <>
      {showDeviceDecisionsInfo ? (
        <Box
          display="flex"
          pt="20px"
          justifyContent="center"
          data-hook="device-renewal-sidebar-device-type">
          <Card style={{ width: '90%', borderRadius: '10px', margin: '20px 0 8px 0' }}>
            <CardContent>
              <div>
                <TitleSidebar>Dispositivo actual</TitleSidebar>
                <TextSidebar dataHook="renewal-sidebar-financing-decision">
                  {deviceName}
                </TextSidebar>
              </div>
              {summaryDecisions.map((decision, i) => (
                <>
                  <TitleSidebar>{decision.name}</TitleSidebar>
                  <TextSidebar dataHook={`renewal-sidebar-decision-${i}`}>
                    {decision.description}
                  </TextSidebar>
                </>
              ))}
            </CardContent>
          </Card>
        </Box>
      ) : null}
    </>
  )
}

FinancedDeviceDecisionsInfo.propTypes = {
  decisionsInfo: PropTypes.object,
}
