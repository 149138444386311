import {
  FETCH_PORTABILITY_DATES,
  FETCH_PORTABILITY_DATES_SUCCESS,
  FETCH_PORTABILITY_DATES_ERROR,
} from './constants'

const fetchPortabilityDates = () => ({
  type: FETCH_PORTABILITY_DATES,
})

const fetchPortabilityDatesSuccess = dates => ({
  type: FETCH_PORTABILITY_DATES_SUCCESS,
  payload: { dates },
})

const fetchPortabilityDatesError = error => ({
  type: FETCH_PORTABILITY_DATES_ERROR,
  payload: { error },
})

export const actions = {
  fetchPortabilityDates,
  fetchPortabilityDatesSuccess,
  fetchPortabilityDatesError,
}
