import axios from 'axios'
import { CONFIG } from './environments'

const provisioningAPIUrl = `${CONFIG.api.provisioning}`
const brand = `${CONFIG.api.brandYoigo}/`

/**
 *
 * @param {string} postCode
 * @param {string} customerId
 * @param {string} token agent or customer token
 */
export function findProvisioning(postCode, customerId, token) {
  return axios({
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'X-Customer-ID': customerId,
    },
    url: `${provisioningAPIUrl}${brand}${postCode}`,
  }).then(resp => resp.data)
}
