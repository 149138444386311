import { APP_CONFIG } from 'services/app-config'

export const CONFIG = {
  api: {
    provisioning: `${APP_CONFIG.apigee_host}/masmovil/v1/products/landline/`,
    brandYoigo: 'YOIGO',
    brandMM: 'masmovil',
    mobileProvisioning: `${APP_CONFIG.apigee_host}/masmovil/v1/products/random_msisdn`,
  },
}
