import PropTypes from 'prop-types'
import { ButtonLink, FormError } from 'components/ui'
import { FieldArray, useFormikContext } from 'formik'
import { get } from 'lodash'
import { fetchTariffsAction } from 'modules/tariffs/store'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { Card, CardContent, CircularProgress } from '@material-ui/core'

import { useChannelAndSegment, useSetFormikValuesToBasket } from '../../../hooks/Shared'
import { useLimitInfo } from '../../../hooks/Lines'
import { ExtraMobileLineOffer } from '../../../store/models'
import { ExtraLine } from '../ExtraLine'
import { storeSelectors } from './ExtraLinesContainer.selectors'

// remove once we addapt convergent sale to new ux

const StyledFormError = styled(FormError)`
  margin-bottom: 28px;
  small {
    position: relative;
  }
`

export function ExtraLinesContainer({ isMultiSim }) {
  const { values } = useFormikContext()
  const extraMobileLinesOffers = get(values, 'extraMobileLinesOffers', [])

  const [mobileLinesError, setMobileLinesErrors] = useState(false)
  const setFormikValuesToBasket = useSetFormikValuesToBasket()
  const {
    mainOffer,
    selectedExtraTariffs,
    tariffList,
    loading,
    error,
    isSubscription,
    isCrossSell,
    subsctiptionTariffId,
  } = useSelector(storeSelectors)
  const { channel, segment } = useChannelAndSegment()

  const dispatch = useDispatch()

  const { hasReachedExtraTariffsLimit } = useLimitInfo()

  useEffect(() => {
    if (mainOffer.ps || !!isSubscription || !!isCrossSell) {
      dispatch(
        fetchTariffsAction({
          mainTariffPoId: mainOffer.id,
          mainTariffPsId: isSubscription ? subsctiptionTariffId : mainOffer.ps,
          segment,
          channel,
          type: 'additional',
          operation: isSubscription || isCrossSell ? 'newSubscription' : '',
        }),
      )
    }
  }, [mainOffer.ps, isSubscription, isCrossSell])

  function onAddExtraLine(arrayHelpers) {
    if (!hasReachedExtraTariffsLimit) {
      const newValues = [...extraMobileLinesOffers, ExtraMobileLineOffer]
      arrayHelpers.push(ExtraMobileLineOffer)
      setFormikValuesToBasket('extraMobileLinesOffers', newValues)
    } else {
      setMobileLinesErrors(true)
    }
  }

  function onRemoveExtraTariff(arrayHelpers, index) {
    return () => {
      arrayHelpers.remove(index)
      const newValues = extraMobileLinesOffers.filter((tariff, i) => i !== index)
      setFormikValuesToBasket('extraMobileLinesOffers', newValues)
      setMobileLinesErrors(false)
    }
  }

  return (
    <FieldArray name="extraMobileLinesOffers">
      {arrayHelpers => (
        <>
          {extraMobileLinesOffers.map((elem, index) => (
            <Card
              className="m-y-32"
              key={`extramobileoffer-${index + 1}`}
              style={{ position: 'relative' }}>
              <CardContent style={{ paddingBottom: '0' }}>
                <ExtraLine
                  error={error}
                  isLoading={loading}
                  tariffList={tariffList}
                  onRemoveExtraTariff={onRemoveExtraTariff(arrayHelpers, index)}
                  index={index}
                  selectedTariff={selectedExtraTariffs[index]}
                  // eslint-disable-next-line react/no-array-index-key
                />
              </CardContent>
            </Card>
          ))}
          {!isMultiSim && (
            <Card className="align-start-start m-y-24">
              <CardContent>
                {mobileLinesError ? (
                  <StyledFormError width="100%" fullwidth>
                    Has alcanzado el número máximo de líneas móviles por paquete, las demás líneas
                    móviles tendrán que ser contratadas fuera de este
                  </StyledFormError>
                ) : (
                  <div className="flex">
                    <ButtonLink
                      dataHook="add-mobile-line"
                      onClick={() => onAddExtraLine(arrayHelpers)}
                      disabled={(!mainOffer.id && !isSubscription) || loading}
                      type="primary"
                      underline={false}>
                      + AÑADIR LÍNEA MÓVIL
                    </ButtonLink>
                    {loading && (
                      <CircularProgress size={16} color="primary" className="m-l-8 m-t-4" />
                    )}
                  </div>
                )}
              </CardContent>
            </Card>
          )}
        </>
      )}
    </FieldArray>
  )
}

ExtraLinesContainer.propTypes = {
  isMultiSim: PropTypes.bool,
}
