import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useFormikContext } from 'formik'
import {
  setPaymentType,
  resetAllTerminals,
  resetTerminalDetailsAction,
} from 'modules/NewClientSales/store/actions'

import { Terminal } from 'modules/NewClientSales/store/models'
import { fieldNames as getFieldNames } from 'modules/Lines/line.config'
import { LINE_TYPE } from 'modules/Lines/constants'

export function onResetTerminal() {
  const dispatch = useDispatch()
  const { setFieldValue } = useFormikContext()

  const resetTerminalData = useCallback(data => {
    dispatch(resetTerminalDetailsAction(data))
  })

  const setPaymentTypeTerminal = useCallback(data => {
    dispatch(setPaymentType(data))
  })

  const onResetAllTerminals = useCallback(data => {
    dispatch(resetAllTerminals(data))
  })

  const resetDeviceMainMobileLine = useCallback(() => {
    const fieldNames = getFieldNames(LINE_TYPE.MAIN_MOBILE, false)

    setFieldValue(fieldNames.HAS_TERMINAL, '')
    setFieldValue(fieldNames.TERMINAL, Terminal)
    resetTerminalData({ lineType: fieldNames.LINE_TYPE })
  })

  return {
    resetTerminalData,
    setPaymentTypeTerminal,
    onResetAllTerminals,
    resetDeviceMainMobileLine,
  }
}
