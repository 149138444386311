import { get, chain } from 'lodash'
import { isFtthOrder } from 'modules/orders'

const modelingDataIdentification = identification =>
  chain(identification)
    .map('identificationId')
    .value()

const modelingDataTechnology = data =>
  chain(data)
    .map('description')
    .value()

const modelingDataAddress = data => {
  const basicInfo = chain(data)
    .filter({ type: 'InstallationAddressFixedLine' })
    .map('medium')
    .value()[0]
  const number = get(basicInfo, 'characteristic', '')
  const numberFormat = chain(number)
    .filter({ name: 'streetNumber' })
    .map('value')
    .value()[0]

  return {
    ...basicInfo,
    number: numberFormat,
  }
}
export const showCoverageStreet = coverageData =>
  coverageData
    ? String(
        `${coverageData.streetType} ${coverageData.street} ${coverageData.number} ${
          coverageData.floor
        } ${coverageData.block} ${coverageData.zipCode}, ${coverageData.town} (${
          coverageData.province
        })`,
      )
    : ''

export const modelData = order => {
  const idOrder = get(order, 'id')
  const customer = get(order, 'customer')
  const identification = get(customer, 'individualIdentification')
  const products = get(customer, 'products')
  const address = get(customer, 'contactMedium')
  const fullName =
    customer !== undefined
      ? `${customer.firstName} ${customer.midName} ${customer.lastName}`
      : '---'
  const modelingAddress = modelingDataAddress(address)
  const addressInstallation =
    customer !== undefined
      ? `${modelingAddress.streetOne} ${modelingAddress.number} ${modelingAddress.streetTwo}, ${
          modelingAddress.postCode
        } ${modelingAddress.city}`
      : '---'
  return {
    idOrder,
    fullName,
    identificationId: modelingDataIdentification(identification)[0],
    suscription: modelingDataTechnology(products)[0],
    technology: isFtthOrder(order) ? 'FTTH' : '---',
    addressInstallation,
  }
}
