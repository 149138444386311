import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { isEmpty, orderBy, get, groupBy, first, last } from 'lodash'
import { format, parseISO } from 'date-fns'

import { Typography, Icon, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import { TextStyles } from 'utils/text'
import { ICONS } from 'utils/icons'

import * as subscriptionsSvc from 'services/subscriptions'
import { selectURLProductId } from 'services/customer-products'
import { applyTax, getTax } from 'services/taxes'
import { selectors as customerSelectors } from 'services/customer-360'
import { SpinnerCenter } from 'components/ui'
import { Card } from 'modules/ui'
import { Board } from '../../components/Board'
import { ListWithFilters } from '../../components/ListWithFilters'
import { UsageItem } from './UsageItem'

import { getTypeFromCallCase } from './UsageDetail.helpers'

const initialFilters = {
  startDate: null,
  endDate: null,
  filteredTypes: [],
  customFilteredTypes: [],
}

const EXPENSE = 'EXPENSE'
const NO_EXPENSE = 'NO_EXPENSE'
const WITHOUT_BUNDLE = 'WITHOUT_BUNDLE'

const CUSTOM_FILTERS_VALUE = [EXPENSE, NO_EXPENSE, WITHOUT_BUNDLE]

const nodes = [
  {
    label: 'Llamadas',
    value: 'CALL',
    tagClassName: 'CALL',
    icon: <Icon className="icon">{ICONS.phone}</Icon>,
  },
  {
    label: 'Llamadas Internacionales',
    value: 'INTERNATIONAL',
    tagClassName: 'INTERNATIONAL',
    icon: <Icon className="icon">{ICONS.phone}</Icon>,
  },
  {
    label: 'Llamadas Premium',
    value: 'CALL_PREMIUM',
    tagClassName: 'CALL_PREMIUM',
    icon: <Icon className="icon">{ICONS.phone}</Icon>,
  },
  {
    label: 'SMS',
    value: 'SMS',
    tagClassName: 'SMS',
    icon: <Icon className="icon">{ICONS.smsTipification}</Icon>,
  },
  {
    label: 'SMS Premium',
    value: 'SMS_PREMIUM',
    tagClassName: 'SMS_PREMIUM',
    icon: <Icon className="icon">{ICONS.smsTipification}</Icon>,
  },
  {
    label: 'Uso de datos',
    value: 'GPRS',
    tagClassName: 'GPRS',
    icon: <Icon className="icon">{ICONS.split}</Icon>,
  },
  {
    label: 'Roaming',
    value: 'ROAMING',
    tagClassName: 'ROAMING',
    icon: <Icon className="icon">{ICONS.roaming}</Icon>,
  },
  {
    label: 'Otros',
    value: 'OTHER',
    tagClassName: 'OTHER',
    icon: <Icon className="icon">{ICONS.help}</Icon>,
  },
  {
    label: 'Consumo con gastos',
    value: EXPENSE,
    tagClassName: EXPENSE,
    icon: <Icon className="icon">{ICONS.paid}</Icon>,
  },
  {
    label: 'Consumo sin gastos',
    value: NO_EXPENSE,
    tagClassName: NO_EXPENSE,
    icon: <Icon className="icon">{ICONS.paid}</Icon>,
  },
  {
    label: 'Consumo fuera de bono',
    value: WITHOUT_BUNDLE,
    tagClassName: WITHOUT_BUNDLE,
    icon: <Icon className="icon">{ICONS.paid}</Icon>,
  },
]

function EmptyUsageDetail() {
  return (
    <Card title="Detalles de uso" withShadow>
      <div className="p-l-24 m-y-12">
        <Typography {...TextStyles.subtitle2Dark()}>
          No existen interacciones para este cliente
        </Typography>
      </div>
    </Card>
  )
}

const useStyles = makeStyles({
  'usage-detail': {
    position: 'relative',

    '&& .card-content': {
      paddingTop: 0,
      marginBottom: '-10px',
    },
  },
})

export const UsageDetail = ({ className }) => {
  const classes = useStyles()

  const initialCheckboxFilters = { checked: [], expanded: [] }
  const isLoading = useSelector(subscriptionsSvc.isLoading)
  const lineId = useSelector(selectURLProductId)
  const subscription = useSelector(subscriptionsSvc.selectSubscriptionByMsisdn(lineId))
  const dataCdr = subscriptionsSvc.getCallDetailRecords(subscription)
  const [testState, setState] = useState(initialCheckboxFilters)
  const [filters, setFilters] = useState(initialFilters)

  useEffect(() => {
    const filterTypes = testState.checked
      .filter(filter => !CUSTOM_FILTERS_VALUE.includes(filter))
      .reduce((acc, filter) => {
        return [...acc, filter]
      }, [])

    const customFiltersTypes = testState.checked
      .filter(filter => CUSTOM_FILTERS_VALUE.includes(filter))
      .reduce((acc, filter) => {
        return [...acc, filter]
      }, [])

    setFilters({
      startDate: filters.startDate,
      endDate: filters.endDate,
      filteredTypes: filterTypes,
      customFilteredTypes: customFiltersTypes,
    })
  }, [testState, filters.startDate, filters.endDate])

  function onChangeStartDate(startDate) {
    setFilters({ ...filters, startDate })
  }

  function onResetFilters() {
    setFilters(initialFilters)
    setState(initialCheckboxFilters)
  }

  function onChangeEndDate(endDate) {
    setFilters({ ...filters, endDate })
  }

  // taxes
  const billingAddress = useSelector(state => customerSelectors.getBillingAddress(state))
  const postCode = get(billingAddress, 'postCode')
  const tax = useSelector(state => getTax(state, postCode))

  function groupItemsByDay(itemsList) {
    const objByDay = groupBy(itemsList, date => format(parseISO(get(date, 'event_at')), 'P'))
    const arrayItems = Object.values(objByDay).map(item => [get(first(item), 'date'), item])
    const withDataConsumedPerDay = arrayItems.map(item => {
      const date = first(item)
      const allItemsPerDay = last(item)
      const allDataItemsPerDay = allItemsPerDay.filter(element => get(element, 'type') === 'GPRS')
      const restDataItemsPerDay = allItemsPerDay.filter(
        element => !(get(element, 'type') === 'GPRS'),
      )
      const restDataItemsPerDayWithOthers = restDataItemsPerDay.map(element =>
        element.call_case !== 2 &&
        !(element.call_case >= 64 && element.call_case <= 69) &&
        element.call_case !== 9 &&
        !['GPRS', 'CALL', 'SMS'].includes(get(element, 'type'))
          ? {
              ...element,
              type: 'OTHER',
            }
          : element,
      )

      const dataAcumulated = allDataItemsPerDay.reduce((accum, currentValue) => {
        return get(currentValue, 'data_amount', 0) + accum
      }, 0)
      const totalWithoutVat = allDataItemsPerDay.reduce((accum, currentValue) => {
        return get(currentValue, 'total_without_vat', 0) + accum
      }, 0)
      const totalWithVat = applyTax(tax, totalWithoutVat)
      const newItemList = dataAcumulated
        ? [
            {
              type: 'GPRS',
              data_amount: dataAcumulated,
              totalWithVat,
              date: get(first(allDataItemsPerDay), 'date'),
            },
            ...restDataItemsPerDayWithOthers,
          ]
        : restDataItemsPerDayWithOthers
      return [date, newItemList]
    })
    return withDataConsumedPerDay
  }

  const filtersSelected = filters.filteredTypes
  const customFiltersSelected = filters.customFilteredTypes

  const stuffCustomFiltrableObj = dataCdr.map(item => {
    return {
      ...item,
      type: get(item, 'event_type').includes('CALL')
        ? getTypeFromCallCase(get(item, 'call_case'))
        : get(item, 'event_type'),
      date: get(item, 'event_at'),
      expense: get(item, 'total_without_vat') !== 0,
      no_expense: get(item, 'total_without_vat') === 0,
      without_bundle: get(item, 'bundle_id') === '',
    }
  })

  const dataItems = groupItemsByDay(stuffCustomFiltrableObj)
  const dataItemsSorted = orderBy(dataItems, [0], ['desc']).map(item => [
    first(item),
    orderBy(last(item), ['date'], ['desc']),
  ])
  return (
    <Board className={[classes['usage-detail'], ...(className ? [className] : [])].join(' ')}>
      <Grid item xs={12}>
        {isEmpty(dataItemsSorted) && !isLoading && <EmptyUsageDetail />}
        {isLoading && <SpinnerCenter showMsg />}
        {!isEmpty(dataItemsSorted) && dataItemsSorted && (
          <Card title="Detalles de uso">
            <ListWithFilters
              items={dataItemsSorted}
              dataTree={nodes}
              testState={testState}
              setState={setState}
              filters={filters}
              onChangeStartDate={onChangeStartDate}
              onChangeEndDate={onChangeEndDate}
              filtersSelected={
                filtersSelected.length === initialFilters.filteredTypes.length
                  ? []
                  : filtersSelected
              }
              customFiltersSelected={
                initialFilters.customFilteredTypes &&
                customFiltersSelected.length === initialFilters.customFilteredTypes.length
                  ? []
                  : customFiltersSelected
              }
              ItemComponent={UsageItem}
              onResetFilters={onResetFilters}
            />
          </Card>
        )}
      </Grid>
    </Board>
  )
}
