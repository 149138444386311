import { createSelector } from 'reselect'
import { get, isEmpty } from 'lodash/fp'

export const getCustomerSubscriptions = get('customerSubscriptions.subscriptions')
export const getIsLoadingSubscriptions = get('customerSubscriptions.loading')

// Return an array with the product offering ids of
// the customer's subscriptions
const mapProductOfferings = customerSubscriptions =>
  isEmpty(customerSubscriptions)
    ? []
    : customerSubscriptions.map(get('relationships.product_offering.data.id'))

export const selectProductOfferings = createSelector(
  getCustomerSubscriptions,
  mapProductOfferings,
)
