import { createStructuredSelector } from 'reselect'

import {
  selectIsRenewalAllowed,
  selectIsRenewalTvAllowed,
  selectIsRenewalAllowedLoading,
  selectRenewalRejectionReasonCode,
  selectRenewalOrderChannel,
  selectIban,
} from 'modules/device-renewal/store/device-renewal.selectors'

export const selectDeviceRenewalModal = createStructuredSelector({
  channel: selectRenewalOrderChannel,
  iban: selectIban,
  isRenewalAllowed: selectIsRenewalAllowed,
  isRenewalTvAllowed: selectIsRenewalTvAllowed,
  isRenewalAllowedLoading: selectIsRenewalAllowedLoading,
  rejectionReasonCode: selectRenewalRejectionReasonCode,
})
