import { useState } from 'react'
import PropTypes from 'prop-types'
import { get, cloneDeep, isEmpty } from 'lodash'

import {
  Card,
  Box,
  Grid,
  Divider,
  Typography,
  RadioGroup,
  Radio,
  FormControlLabel,
  Icon,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import BrokenImageIcon from '@material-ui/icons/BrokenImage'

import { formatFee } from 'utils'
import { SEVERAL, SINGLE, QUOTA25 } from 'services/global-constants'

import { Button, ButtonLink, DotsCircularSpinner, OutlinedDivider } from 'components/ui'

import { DeviceSpecificationsModal } from '../DeviceSpecificationsModal'
import { FinancedPaymentDetailModal } from '../FinancedPaymentDetailModal'
import { DeviceStockInfo } from '../DeviceStockInfo'

const useStyles = makeStyles(theme => ({
  'device-card': {
    border: ({ selected }) => `2px solid ${selected ? theme.palette.primary.main : '#FFF'}`,
    backgroundColor: ({ selected }) => selected && theme.palette.bluePalette.blue_50,
  },
  'device-image': {
    width: '100%',
    maxWidth: '120px',
    minWidth: '70px',

    '& img': {
      width: '100%',
      userSelect: 'none',
    },
  },
  'no-image-wrapper': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    border: '1px solid #d4d4d4',
    borderRadius: '5px',
    padding: '20px',
    '& svg': {
      fontSize: '5em',
      marginBottom: '10px',
    },
  },
  characteristic: {
    marginBottom: '4px',
  },
  'low-stock': {
    fontSize: '14px',
    color: 'red',
    marginLeft: '16px',
  },
  'high-stock': {
    fontSize: '14px',
    color: 'green',
    marginLeft: '16px',
  },
  'payment-methods': {
    flexDirection: 'row',
    alignItems: 'flex-start',
    height: '100%',
    flexWrap: 'nowrap',
    textAlign: 'left',
    whiteSpace: 'nowrap',

    '& .radio-check + span': {
      fontWeight: 'bold !important',
      color: `${theme.palette.global.gray_dark} !important`,
    },

    '& .price': {
      fontWeight: 'bold',

      '& .price-label': {
        fontSize: '18px',
        marginLeft: '6px',
      },
    },

    '& hr': {
      backgroundColor: theme.palette.global.gray_dark,
      width: '2px',
    },

    '& .month-number, & .financed-payment': {
      fontWeight: 'normal',
      color: theme.palette.global.gray_dark,
    },

    '& .financed-payment': {
      whiteSpace: 'normal',

      '& span': {
        marginLeft: '5px',
        fontSize: '16px',
        fontWeight: 500,
        whiteSpace: 'nowrap',
      },

      '& button': {
        textAlign: 'left',
      },
    },
  },
}))

export const DeviceCard = ({
  value,
  selected,
  defaultPaymentMethodSelected,
  onSelected,
  stock,
  stockHasError,
  isLoadingStock = false,
  onRetryFetchStock,
  noSelectedButton,
  children,
  dataHook,
}) => {
  const { paymentMethods, image } = value
  const financedPaymentInfo = get(paymentMethods, SEVERAL) || get(paymentMethods, QUOTA25)

  const classes = useStyles({ selected: !noSelectedButton && selected })

  const [paymentSelected, setPaymentSelected] = useState(defaultPaymentMethodSelected || null)
  const [specificationsIsOpen, setSpecificationsIsOpen] = useState(false)
  const [financedPaymentDetailIsOpen, setFinancedPaymentDetailIsOpen] = useState(false)

  const generateFinancedPayment = paymentName => {
    const checkboxValue = paymentName === SEVERAL ? SEVERAL : QUOTA25
    const paymentInfo = get(paymentMethods, paymentName, {})

    return (
      <Box display="flex">
        <Box display="flex" flexDirection="column" pl="20px">
          <FormControlLabel
            value={checkboxValue}
            control={<Radio className="radio-check" color="primary" />}
            label="Pago a plazos"
          />

          <Box pl="30px">
            <Typography
              variant="h4"
              component="p"
              className="price"
              data-hook="terminal.monthlyPayment">
              {get(paymentInfo, `monthlyPayment.taxFreeAmount`, 0)}
              <span className="price-label">€/mes</span>
            </Typography>
            <Typography
              variant="subtitle2"
              component="p"
              className="month-number"
              data-hook="terminal.fees">
              {get(paymentInfo, `fees`, 0)} meses
            </Typography>
          </Box>
        </Box>
        <Box pl="20px" pt="12px" className="financed-payment">
          {paymentInfo.initialPayment && (
            <Typography variant="subtitle2" component="p">
              Pago inicial:
              <span>{formatFee(get(paymentInfo, 'initialPayment.taxIncludedAmount', 0))}</span>
            </Typography>
          )}
          {paymentInfo.finalPayment && (
            <Typography variant="subtitle2" component="p">
              Pago final:
              <span>{formatFee(get(paymentInfo, 'finalPayment.taxIncludedAmount', 0))}</span>
            </Typography>
          )}

          <Typography variant="subtitle2" component="p">
            Interés:
            <span>
              {formatFee(
                ((get(paymentInfo, `monthlyPayment.taxFreeAmount`, 0) *
                  get(paymentInfo, `fees`, 0) +
                  get(paymentInfo, 'finalPayment.taxIncludedAmount', 0)) *
                  get(value, 'financingConditions.commissionFeePercentage', 0)) /
                  100,
              )}
            </span>
          </Typography>
          <Typography variant="subtitle2" component="p">
            Permanencia:
            <span>
              {get(paymentInfo, 'permanency.count', 0)} m (
              {formatFee(get(paymentInfo, 'permanency.penaltyAmountTaxFree'))})
            </span>
          </Typography>
          <ButtonLink onClick={() => setFinancedPaymentDetailIsOpen(true)}>
            Detalle pago a plazos
          </ButtonLink>
        </Box>
      </Box>
    )
  }

  function onClickSelect(paymentMethodValue = paymentSelected) {
    const device = cloneDeep(value)
    device.paymentMethods = {
      [paymentMethodValue]: value.paymentMethods[paymentMethodValue],
    }

    onSelected(device)
  }

  function onChangePaymentMethod(paymentMethodValue) {
    setPaymentSelected(paymentMethodValue)
    if (selected || noSelectedButton) {
      onClickSelect(paymentMethodValue)
    }
  }

  return (
    <>
      <DeviceSpecificationsModal
        id={value.id}
        name={value.name}
        open={specificationsIsOpen}
        onClose={() => setSpecificationsIsOpen(false)}
      />
      <FinancedPaymentDetailModal
        name={value.name}
        financedPaymentInfo={financedPaymentInfo}
        financingConditionsTaxes={get(value, 'financingConditions', {})}
        open={financedPaymentDetailIsOpen}
        onClose={() => setFinancedPaymentDetailIsOpen(false)}
      />
      <Card
        style={{ width: '100%' }}
        elevation={2}
        className={classes['device-card']}
        data-hook={dataHook}>
        <Grid container>
          <Grid item xs={3}>
            <Box display="flex" height="100%">
              <Box display="flex" width="100%" justifyContent="center" p="24px">
                <div data-hook="terminal.image" className={classes['device-image']}>
                  {image ? (
                    <img src={image} alt="Device" />
                  ) : (
                    <div className={classes['no-image-wrapper']}>
                      <BrokenImageIcon color="disabled" />
                      <Typography variant="caption">Imagen no disponible</Typography>
                    </div>
                  )}
                </div>
              </Box>
              <Divider orientation="vertical" />
            </Box>
          </Grid>
          <Grid item xs={9}>
            <Box p="29px 24px">
              <Box display="flex" justifyContent="space-between" mb="20px">
                <Typography variant="h5" style={{ fontWeight: 'bold' }}>
                  {value.name}
                </Typography>

                <DeviceStockInfo
                  isLoadingStock={isLoadingStock}
                  stockHasError={stockHasError}
                  stock={stock}
                  onRetryFetchStock={onRetryFetchStock}
                  deviceId={value.id}
                />
              </Box>

              <Grid container spacing={2} style={{ marginTop: '14px' }}>
                <Grid item sm={12} md={3} xl={4}>
                  <Typography variant="body2" className={classes.characteristic}>
                    Características
                  </Typography>
                  {value.characteristics.storageMemory && (
                    <Typography
                      variant="body2"
                      className={classes.characteristic}
                      data-hook="terminal.capacity">
                      <Typography variant="subtitle2" component="span" className="p-r-4">
                        Memoria interna (GB):
                      </Typography>

                      {get(value, 'characteristics.storageMemory')}
                    </Typography>
                  )}

                  {value.characteristics.screenSize && (
                    <Typography
                      variant="body2"
                      className={classes.characteristic}
                      data-hook="terminal.screen">
                      <Typography variant="subtitle2" component="span" className="p-r-4">
                        Tamaño de pantalla (pulgadas):
                      </Typography>
                      {get(value, 'characteristics.screenSize')}
                    </Typography>
                  )}

                  <ButtonLink onClick={() => setSpecificationsIsOpen(true)}>
                    Ver características
                  </ButtonLink>
                </Grid>
                <Grid item sm={12} md={9} xl={8}>
                  <RadioGroup
                    name="terminal.paymentMethod"
                    value={paymentSelected}
                    data-hook={`upfront-payment-${value.id}`}
                    onChange={e => {
                      return onChangePaymentMethod(e.target.value)
                    }}
                    className={classes['payment-methods']}>
                    {get(paymentMethods, 'upfront') && (
                      <>
                        <Box display="flex" flexDirection="column" pr="20px">
                          <FormControlLabel
                            value={SINGLE}
                            control={
                              <Radio
                                className="radio-check"
                                color="primary"
                                disabled={!stock || stock < 1}
                              />
                            }
                            label="Pago único"
                          />

                          <Box pl="30px">
                            <Typography
                              variant="h4"
                              component="p"
                              data-hook="terminal.initialPayment"
                              className="price">
                              {formatFee(
                                get(paymentMethods, 'upfront.initialPayment.taxIncludedAmount', 0),
                              ).replace('€', '')}

                              <span className="price-label">€</span>
                            </Typography>
                          </Box>
                        </Box>
                        {Object.values(paymentMethods).filter(method => !isEmpty(method)).length >
                          1 && <Divider orientation="vertical" />}
                      </>
                    )}

                    {!isEmpty(get(paymentMethods, SEVERAL)) && generateFinancedPayment(SEVERAL)}
                    {!isEmpty(get(paymentMethods, QUOTA25)) && generateFinancedPayment(QUOTA25)}
                  </RadioGroup>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          {!noSelectedButton && (
            <Grid item xs={12}>
              <Divider />

              <Box display="flex" justifyContent="center" py="10px">
                <Button
                  data-hook={`select-device-btn-${value.id}`}
                  secondary
                  disabled={!paymentSelected || selected || !stock}
                  onClick={() => onClickSelect()}>
                  {!selected && 'Seleccionar'}
                  {selected && stock ? 'Seleccionado' : null}
                  {selected && !stock && (
                    <Box height="15px">
                      <div style={{ marginTop: '-9px' }}>
                        <DotsCircularSpinner width="32px" />
                      </div>
                    </Box>
                  )}
                </Button>
              </Box>
            </Grid>
          )}

          {!!children && (
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={1}>
                  <Box display="flex" alignItems="center" height="100%">
                    <OutlinedDivider style={{ width: '100%' }} />
                  </Box>
                </Grid>
                <Grid item xs={1}>
                  <Box display="flex" alignItems="center" height="100%">
                    <OutlinedDivider style={{ width: 'calc(50% - 10px)' }} />
                    <Icon size={20} color="disabled">
                      control_point
                    </Icon>
                    <OutlinedDivider style={{ width: 'calc(50% - 10px)' }} />
                  </Box>
                </Grid>
                <Grid item xs={10}>
                  <Box display="flex" alignItems="center" height="100%">
                    <OutlinedDivider style={{ width: '100%' }} />
                  </Box>
                </Grid>
              </Grid>
              {children}
            </Grid>
          )}
        </Grid>
      </Card>
    </>
  )
}

DeviceCard.propTypes = {
  value: PropTypes.object.isRequired,
  selected: PropTypes.bool,
  isLoadingStock: PropTypes.bool,
  defaultPaymentMethodSelected: PropTypes.string,
  onSelected: PropTypes.func.isRequired,
  stock: PropTypes.number,
  stockHasError: PropTypes.bool,
  onRetryFetchStock: PropTypes.func,
  noSelectedButton: PropTypes.bool,
  children: PropTypes.node,
  dataHook: PropTypes.string,
}
