import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import {
  Typography,
  CircularProgress,
  FormControlLabel,
  RadioGroup,
  Radio,
  Box,
} from '@material-ui/core'
import { selectCashOnDeliveryFlag } from 'services/feature-flag/selectors'

import { OutlinedDivider, ActionAdvice, AlertAdvice } from 'components/ui'

import { TerminalsIframeComponent } from './TerminalsIframeComponent'

const PaymentTypes = { TPV: 'TPV', COD: 'CASH_ON_DELIVERY' }

function PaymentTypeChooser({ paymentType, onChange, noCodPayment, codSurcharge }) {
  const canPayOnDelivery = useSelector(selectCashOnDeliveryFlag)

  return (
    <>
      <Box display="flex" alignItems="center" mb="20px">
        <Typography component="h6" variant="body1" className="bold m-r-32">
          Método de pago
        </Typography>

        <RadioGroup row name="terminal-payment-chooser" value={paymentType} onChange={onChange}>
          <FormControlLabel
            value={PaymentTypes.TPV}
            data-hook="card-payment-radio"
            control={<Radio color="primary" />}
            label="Con tarjeta"
          />
          {!noCodPayment && (
            <FormControlLabel
              disabled={!canPayOnDelivery}
              value={PaymentTypes.COD}
              control={<Radio color="primary" />}
              data-hook="cod-payment-radio"
              label={`Contra reembolso ${codSurcharge > 0 ? `(Recargo de ${codSurcharge}€)` : ''}`}
            />
          )}
        </RadioGroup>
      </Box>

      {paymentType && <OutlinedDivider style={{ marginBottom: '31px' }} />}
    </>
  )
}

PaymentTypeChooser.propTypes = {
  paymentType: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  noCodPayment: PropTypes.bool,
  codSurcharge: PropTypes.number,
}

export function TerminalsPaymentComponent(props) {
  const {
    amount,
    onSuccess,
    onError,
    onRetry,
    onChange,
    getUrl,
    className,
    isSuccess,
    isError,
    isLoading,
    paymentType,
    numOfRetries,
    url,
    step,
    noCodPayment,
    codSurcharge,
  } = props

  const isMaxRetriesReached = numOfRetries === 2

  const handleMessage = e => {
    // We need to ignore other possible global events
    if (!get(e, 'data.numOperacionNNP', false)) return

    const code = get(e, 'data.codigo', false)
    if (code === 'OK') {
      onSuccess(e.data)
    } else {
      onError(true)
    }
  }

  function onChangePaymentType(evt) {
    onChange({ isTPV: true, amount }, evt.target.value)
  }

  useEffect(() => {
    window.addEventListener('message', handleMessage)
    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [step])

  useEffect(() => {
    if (!url && paymentType === PaymentTypes.TPV) {
      getUrl(amount)
    }
  }, [paymentType, url])

  if (isMaxRetriesReached) {
    return (
      <div className={className}>
        <AlertAdvice
          className="m-y-12"
          message="No se ha podido realizar el pago de este dispositivo. Si desea continuar, este dispositivo se
          eliminará de la orden. Si es el dispositivo asociado a la línea cabecera, no se podrá
          continuar con la creación de la orden."
        />
      </div>
    )
  }

  if (isError) {
    return (
      <div className={className}>
        <PaymentTypeChooser
          className="payment-chooser"
          onChange={onChangePaymentType}
          paymentType={paymentType}
          noCodPayment={noCodPayment}
          codSurcharge={codSurcharge}
        />

        <ActionAdvice
          type="error"
          icon="warning"
          className="m-y-12"
          message="Lo sentimos, ha habido un error inesperado"
          onRetry={onRetry}
        />
      </div>
    )
  }

  if (isSuccess) {
    return (
      <ActionAdvice type="success" message="El pago del dispositivo se efectuó correctamente" />
    )
  }

  if (!isSuccess && !isError && !isLoading) {
    return (
      <div className={className}>
        <>
          <PaymentTypeChooser
            paymentType={paymentType}
            onChange={onChangePaymentType}
            noCodPayment={noCodPayment}
            codSurcharge={codSurcharge}
          />
          {paymentType === PaymentTypes.TPV && (
            <Box m="14px 0" p="14px 0">
              <TerminalsIframeComponent url={url} className="terminal-iframe" />
            </Box>
          )}
          {paymentType === PaymentTypes.COD && (
            <ActionAdvice
              type="info"
              message={`Paga en metálico el importe del pedido cuando te lo entreguen. Con recargo de ${codSurcharge} euros
            (impuestos incluidos)`}
            />
          )}
        </>
      </div>
    )
  }

  return <CircularProgress />
}

TerminalsPaymentComponent.defaultProps = {
  codSurcharge: 0,
}

TerminalsPaymentComponent.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onRetry: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  getUrl: PropTypes.func,
  className: PropTypes.string,
  amount: PropTypes.number.isRequired,
  numOfRetries: PropTypes.number.isRequired,
  isSuccess: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  url: PropTypes.string,
  paymentType: PropTypes.string.isRequired,
  codSurcharge: PropTypes.number,
  step: PropTypes.number,
  noCodPayment: PropTypes.bool,
}
