import PropTypes from 'prop-types'

import { Typography } from '@material-ui/core'
import { TextStyles } from 'utils/text'

import { formatNumberWithComma } from 'utils'

export const PackagesSummary = ({ name, monthlyFee, duration, lineType }) => {
  const formatedMonthlyFee = formatNumberWithComma(monthlyFee)
  const formatedDuration = duration > 1 ? `| ${duration} meses` : `| ${duration} mes`
  return (
    <div className="flex m-b-20">
      <Typography {...TextStyles.paragraphDark()}>Bono añadido: </Typography>
      <Typography {...TextStyles.subtitle1Dark()} data-hook={`${lineType}.${name}`}>
        {name} adicional | {formatedMonthlyFee} €/mes {duration ? formatedDuration : ''}
      </Typography>
    </div>
  )
}

PackagesSummary.propTypes = {
  name: PropTypes.string,
  monthlyFee: PropTypes.number,
  duration: PropTypes.number,
  lineType: PropTypes.string,
}
