import { useDispatch, useSelector } from 'react-redux'
import { appointmentActions } from 'modules/Appointments'
import { selectNewAppointment, selectIsD2D } from 'modules/NewClientSales/store/selectors'
import { CardWrapper as Card } from 'components/ui'
import { KairosAppointment } from 'modules/kairos-appointment/components/KairosAppointment'

import { notifyD2DOrderFinishedAction } from '../../../store/actions'

export const NewSalesAppointment = ({ navigateToNextStep }) => {
  const dispatch = useDispatch()

  const isD2D = useSelector(selectIsD2D)

  const setAppointmentError = error => dispatch(appointmentActions.setAppointmentError(error))
  const notifyD2D = () => dispatch(notifyD2DOrderFinishedAction())

  const appointmentData = useSelector(selectNewAppointment)

  const onAppointmentFinish = () => {
    if (isD2D) {
      notifyD2D()
    } else {
      navigateToNextStep('next-steps', false)
    }
  }

  const onAppointmentFailed = error => {
    onAppointmentFinish()

    if (!isD2D) {
      setAppointmentError(error)
    }
  }

  const props = {
    data: appointmentData,
    onSuccess: onAppointmentFinish,
    onError: onAppointmentFailed,
  }

  return (
    <Card width="100%">
      <KairosAppointment show {...props} />
    </Card>
  )
}

NewSalesAppointment.propTypes = {}
