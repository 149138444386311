import { APP_CONFIG } from 'services/app-config'

const URL_PATH_CUSTOMER_360 = 'search-360/customer'

export const CONFIG = {
  api: {
    customer360: `${APP_CONFIG.care_cluster}/${URL_PATH_CUSTOMER_360}`,
    vipType: `${APP_CONFIG.care_cluster}/${URL_PATH_CUSTOMER_360}/vipclassification`,
  },
}
