import PropTypes from 'prop-types'
import { isNumber } from 'lodash'
import { Typography, Divider } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { TextStyles } from 'utils/text'
import { LineItemCard, Modal, ModalActions, Button } from 'components/ui'
import { formatFee } from 'utils/formatting/index'
import { getSvaServiceRequests } from 'services/customer-sva/helpers'
import { formatDateStr } from 'utils/formatting/dates'
import { getTextCalculatedStayTime, getTextTotalStayTime } from 'modules/SVAs/helpers/helpers'

const useStyles = makeStyles({
  textLineItem: {
    '& .primary-text': {
      whiteSpace: 'nowrap',
    },
  },
})

export const HomeGoSvaDetailsModal = ({ isOpen, setIsOpen, title, data, isPro }) => {
  const classes = useStyles()

  const { stayCost, totalStayCost, noReturnCost } = data?.stayStatus || {}

  const textSvaServiceRequest = getSvaServiceRequests(data)
  const alarmState = data.workOrderStatus
    ? `${data.workOrderStatus} ${textSvaServiceRequest ? ` - ${textSvaServiceRequest}` : ''}`
    : '-'

  const monthlyFee = isPro ? data.monthlyNetFee : data.monthlyTotalFee
  const singleFee = isPro ? data.singleNetFee : data.singleTotalFee
  const withoutTax = isPro ? '(impuestos no incluidos)' : ''

  return (
    <Modal title={title} isOpen={isOpen}>
      {data.contractTypeName && (
        <Typography {...TextStyles.subtitle1Link()}>
          <span>{data.contractTypeName}</span>
        </Typography>
      )}

      <LineItemCard padding="6px 0" primaryText="Estado de la alarma" secondaryText={alarmState} />
      <LineItemCard
        padding="6px 0"
        primaryText="Cuota"
        secondaryText={isNumber(monthlyFee) ? `${formatFee(monthlyFee)}/mes ${withoutTax}` : '-'}
      />
      {!!singleFee && (
        <LineItemCard
          padding="6px 0"
          primaryText="Cuota adicional"
          secondaryText={singleFee ? `${formatFee(singleFee)}/mes ${withoutTax}` : '-'}
        />
      )}
      <LineItemCard
        padding="6px 0"
        primaryText="Fecha de contratación"
        secondaryText={formatDateStr(data.startContractDate, 'yyyy-MM-dd')}
      />
      {data.installComp && (
        <LineItemCard
          padding="6px 0"
          primaryText="Dispositivos adicionales"
          className={classes.textLineItem}
          secondaryText={data.installComp}
        />
      )}
      {data.contractTypeName && (
        <LineItemCard
          padding="6px 0"
          primaryText="Tipo de kit"
          secondaryText={data.contractTypeName}
        />
      )}
      {data.streetContact && (
        <LineItemCard
          padding="6px 0"
          primaryText="Dirección de instalación de alarma"
          secondaryText={data.streetContact}
        />
      )}
      {data.phoneContact && (
        <LineItemCard
          padding="6px 0"
          primaryText="Teléfono contacto instalación"
          secondaryText={data.phoneContact}
        />
      )}
      <br />

      <div className="flex align-space-between">
        <div className="flex flex-column" style={{ width: '30%' }}>
          <Typography {...TextStyles.dataDark()}>Permanencia actual / total:</Typography>
          <div className="flex" style={{ alignItems: 'center' }}>
            <Typography {...TextStyles.title3Dark()}>{getTextCalculatedStayTime(data)}</Typography>
            <Typography
              {...TextStyles.dataDark({
                style: { paddingLeft: '5px' },
              })}>
              / {getTextTotalStayTime(data)}
            </Typography>
          </div>
        </div>

        <Divider orientation="vertical" flexItem />

        <div className="flex flex-column" style={{ width: '30%' }}>
          <Typography {...TextStyles.dataDark()}>Penalización actual / total:</Typography>
          <div className="flex" style={{ alignItems: 'center' }}>
            <Typography {...TextStyles.title3Dark()}>
              {stayCost ? formatFee(stayCost) : '-'}
            </Typography>
            <Typography
              {...TextStyles.dataDark({
                style: { paddingLeft: '5px' },
              })}>
              / {totalStayCost ? formatFee(totalStayCost) : '-'}
            </Typography>
          </div>
        </div>

        <Divider />

        <div className="flex flex-column" style={{ width: '30%' }}>
          <Typography {...TextStyles.dataDark()}>
            Penalización por no devolución de dispositivos:
          </Typography>
          <Typography {...TextStyles.title3Dark()}>
            {noReturnCost ? formatFee(noReturnCost) : '-'}
          </Typography>
        </div>
      </div>

      <ModalActions>
        <Button secondary onClick={() => setIsOpen(false)}>
          Cerrar
        </Button>
      </ModalActions>
    </Modal>
  )
}

HomeGoSvaDetailsModal.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  title: PropTypes.string,
  data: PropTypes.object,
  isPro: PropTypes.bool,
}
