import styled from 'styled-components'
import ItemRowCard from './ItemRowCard'

const ItemRowLabelCard = styled(ItemRowCard)`
  color: ${props => (props.color ? props.color : '#b9c2c7')};
  font-size: 12px;
  line-height: 14px;
  padding: ${props => (props.padding ? props.padding : '0')};
`
export default ItemRowLabelCard
