import { axiosJWT } from 'modules/axios'

import { CONFIG } from './environments'

export const putOrderDiscounts = (orderId, dataOrderDiscount) =>
  axiosJWT({
    method: 'PUT',
    url: `${CONFIG.api.orderDiscounts}/?orderId=${orderId}`,
    data: {
      orderDiscounts: [{ ...dataOrderDiscount }],
    },
  }).then(resp => resp.data)
