import { createSelector, createStructuredSelector } from 'reselect'
import { get, chain, isEmpty } from 'lodash'
import { extendedLiteralDate, formatHourFromDate } from 'utils/formatting'
import uuidv4 from 'uuid/v4'

const selectRegistersTypifications = state => get(state, 'registerTypifications')

export const selectRegistersTypificationsLoading = createSelector(
  selectRegistersTypifications,
  registersTypifications => get(registersTypifications, 'loading'),
)

export const selectRegistersTypificationsError = createSelector(
  selectRegistersTypifications,
  registersTypifications => get(registersTypifications, 'error'),
)

export const selectRegistersTypificationsData = createSelector(
  selectRegistersTypifications,
  registersTypifications => get(registersTypifications, 'data'),
)

const selectRegisterIsSuccess = createSelector(
  selectRegistersTypifications,
  registersTypifications => get(registersTypifications, 'registerIsSuccess'),
)

export const getRegistersTypifications = createSelector(
  selectRegistersTypificationsData,
  registers =>
    chain(registers)
      .map(register => ({ ...register, hour: formatHourFromDate(get(register, 'timestamp')) }))
      .groupBy(register => extendedLiteralDate(get(register, 'timestamp')))
      .map((values, key) => ({ id: uuidv4(), date: key, registers: values }))
      .orderBy(new Date('date'), 'desc')
      .value(),
)

const getExistsRegisters = createSelector(
  selectRegistersTypificationsData,
  registers => !isEmpty(registers),
)

export const selectExitsRegisters = createStructuredSelector({
  registerIsSuccess: selectRegisterIsSuccess,
  existsRegisters: getExistsRegisters,
})
