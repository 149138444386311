import { get, map, every, isEmpty, find, chain, head, last, partition, isNil } from 'lodash'

import {
  CUSTOMER_PRO_SOHO_SEGMENT,
  MULTISIM_LINE,
  constants,
 SOHO_COMPANY } from 'services/customer-360/constants'
import { RESIDENTIAL, PRO } from 'modules/SharedSales/constants/productCategories'
import {
  getProvinceFromCodeOrString,
  getCountryFromIsoCode,
  NoDataStr,
  landlineRegex,
  formatNumberWithComma,
} from 'utils'
import { isElFijoTariff } from 'modules/tariffs/helpers'
import { applyTax } from 'services/taxes'
import { TARIFF_MIXED } from 'modules/offers-configuration/constants'

const STATUS_ON_FLY_STR = 'fly'
const STATUS_ACTIVE_STR = 'active'

const getIdentificationStr = identification =>
  `${get(identification, 'type', NoDataStr)}: ${get(identification, 'identificationId', NoDataStr)}`

const SegmentTypes = {
  CONSUMER: {
    id: 'CONSUMER',
  },
  AUTONOMO: {
    id: 'AUTONOMO',
  },
  COMPANY: {
    id: 'COMPANY',
  },
  'SOHO-AUTONOMO': {
    id: 'SOHO-AUTONOMO',
  },
  'SOHO-COMPANY': {
    id: 'SOHO-COMPANY',
  },
  'PRO-SOHO-AUTONOMO': {
    id: 'PRO-SOHO-AUTONOMO',
  },
  'PRO-SOHO-COMPANY': {
    id: 'PRO-SOHO-COMPANY',
  },
}

const segmentOptions = {
  CONSUMER: 'Particular',
  AUTONOMO: 'Autónomo',
  COMPANY: 'Negocio',
  'SOHO-AUTONOMO': 'Autónomo',
  'SOHO-COMPANY': 'Negocio',
  'PRO-SOHO-AUTONOMO': 'PRO Autónomo',
  'PRO-SOHO-COMPANY': 'PRO Empresa',
}

const getSegmentStr = segment => get(segmentOptions, segment, 'Desconocido')

const getMsisdnByOrderIdFromContracts = (contracts, orderId) =>
  chain(contracts)
    .flatMap('packages')
    .find(item => item.orderId === orderId)
    .get('id')
    .value()

export const getProductByOrderIdFromContracts = (contracts, orderId) =>
  chain(contracts)
    .flatMap('packages')
    .find(item => item.orderId === orderId)
    .get('products')
    .head()
    .value()

const getContactPhonesStr = phones =>
  !isEmpty(phones) ? map(phones, 'number').join(' / ') : NoDataStr

const getBillingAddressStr = billingAddress => {
  if (billingAddress) {
    const { city, country, postCode, stateOrProvince, streetOne, streetTwo } = billingAddress
    return every([!city, !country, !postCode, !stateOrProvince, !streetOne, !streetTwo], Boolean)
      ? NoDataStr
      : [
          streetOne,
          streetTwo,
          postCode,
          city,
          getProvinceFromCodeOrString(stateOrProvince),
          getCountryFromIsoCode(country),
        ]
          .filter(Boolean)
          .join(' ')
  }
  return NoDataStr
}

const langObj = {
  1: {
    code: 'es-ES',
    name: 'Castellano',
  },
  2: {
    code: 'ca-ES',
    name: 'Catalán',
  },
  3: {
    code: 'eu-ES',
    name: 'Euskera',
  },
  4: {
    code: 'gl-ES',
    name: 'Galego',
  },
  5: {
    code: 'en-EN',
    name: 'Inglés',
  },
}

const defaultLangObj = {
  code: 'en-EN',
  name: 'Idioma desconocido',
}
export const getLangFromId = id => get(langObj, id, defaultLangObj)
const getLangFromCode = code => find(Object.values(langObj), { code }) || defaultLangObj

const getLastOrderId = orders =>
  chain(orders)
    .last()
    .get('id')
    .value()

const isLineOnFly = line => get(line, 'stringStatus') === 'fly'

const getProductByLineId = (contracts, lineId) =>
  chain(contracts)
    .flatMap('packages')
    .flatMap('products')
    .find(prod => find(prod.lines, { line: lineId }))
    .value()

const getProductByMsisdn = (contracts, msisdn) =>
  chain(contracts)
    .flatMap('packages')
    .flatMap('products')
    .find(
      product =>
        product.msisdn === msisdn || product.id === msisdn || !!find(product.lines, { id: msisdn }),
    )
    .value()

const getLineByLineId = (contracts, lineId) =>
  chain(contracts)
    .flatMap('packages')
    .flatMap('products')
    .flatMap('lines')
    .find(line => line.id === lineId)
    .value()

const getProductByOrderId = (contracts, orderId) =>
  chain(contracts)
    .flatMap(contract => contract.packages)
    .flatMap(pack => pack.products)
    .find(product => product.lines.length > 0 && product.lines[0].orderId === orderId)
    .value()

const getInstallationAddressPackageByMsisdn = (contracts, msisdn) => {
  const address = chain(contracts)
    .flatMap('packages')
    .find(pack => pack.id === msisdn)
    .get('installationAddressFixedLine')
    .value()

  const characteristic = get(address, 'medium.characteristic')

  if (characteristic) {
    characteristic.forEach(item => {
      address.medium[item.name] = item.value
    })
  }
  return address
}

const getAllLinesCustomer = contracts =>
  chain(contracts)
    .flatMap('packages')
    .flatMap('products')
    .flatMap('lines')
    .flatMap('line')
    .value()

const getAllLinesAndElFijoMsisdn = contracts => {
  const products = chain(contracts)
    .flatMap('packages')
    .flatMap('products')
    .value()

  const isElFijoProduct = products.filter(product => isElFijoTariff(get(product, 'tariffId')))

  const allLines = chain(contracts)
    .flatMap('packages')
    .flatMap('products')
    .flatMap('lines')
    .flatMap('line')
    .value()

  return !isEmpty(isElFijoProduct)
    ? [...allLines, ...isElFijoProduct.map(product => get(product, 'msisdn'))]
    : allLines
}

export const isProCustomer = customerSegment => CUSTOMER_PRO_SOHO_SEGMENT.includes(customerSegment)

export const getSegmentTariffMixed = customerSegment =>
  isProCustomer(customerSegment) ? PRO : RESIDENTIAL

export const getMultisimTariffSegment = (tariffSegment, customerSegment) => {
  if(isNil(tariffSegment) || tariffSegment === TARIFF_MIXED) {
    if(customerSegment === SOHO_COMPANY) {
      return PRO
    }
      return getSegmentTariffMixed(customerSegment)
  }

  return tariffSegment
}

export const getIsPercentage = discount =>
  chain(discount)
    .get('discount_plan')
    .get('amount_unit')
    .lowerCase()
    .isEqual('percentage')
    .value()

const getDiscountAmount = (discount, tax) => {
  const isPercentage = getIsPercentage(discount)
  let { amount } = discount.subscription_discount

  if (!isPercentage) {
    amount = applyTax(tax, amount)
  }

  return `${formatNumberWithComma(amount)}${isPercentage ? '%' : '€'}`
}

function getAllLinesByType(contracts, lineType) {
  return chain(contracts)
    .flatMap('packages')
    .flatMap('products')
    .flatMap('lines')
    .filter({ type: lineType })
    .value()
}

function isConvergent(product) {
  return (
    chain(product)
      .get('lines')
      .size()
      .value() > 1
  )
}

function getTariffId(product) {
  return get(product, 'tariffId')
}

function getCharacteristic(chars, name) {
  return get(chars && chars.find(char => get(char, 'name', '') === name), 'value', '')
}

function getMedium(mediums, name, path) {
  return get(mediums && mediums.find(medium => get(medium, 'type', '') === name), path, '')
}

function getPhoneContact(mediums, type) {
  const phoneContacts = mediums.filter(elem => elem.type === 'TelephoneNumber')
  const requestedNumber = find(phoneContacts, { medium: { type } })
  return get(requestedNumber, 'medium.number')
}

function mapLanguage(value) {
  switch (value) {
    case '1':
      return 'spa'
    case '2':
      return 'cat'
    case '3':
      return 'eus'
    case '5':
      return 'eng'
    default:
      return 'spa'
  }
}

function getOrderIdByOrders(orders) {
  return chain(orders)
    .map(el => el.id)
    .value()
}

function getMultisimTypeStr(type, discounts, tax) {
  switch (type) {
    case 'main_line':
      return 'Línea cabecera'
    case 'extra_line':
      return 'Línea extra'
    case 'additional_line': {
      const discount = head(discounts)

      if (isEmpty(discount)) {
        return 'Línea adicional'
      }

      return `Línea adicional con ${getDiscountAmount(discount, tax)} de descuento`
    }

    default:
      return ''
  }
}

const getVipTypeFromResponse = vipTypeResponse => {
  const vipType = {}

  switch (vipTypeResponse.vipClassification) {
    case constants.RESPONSE_FIELDS_MAPPING.NOT_INFLUENCER:
      vipType.vipClassification = constants.INFLUENCER_TYPES.NOT_INFLUENCER
      break

    case constants.RESPONSE_FIELDS_MAPPING.INFLUENCER:
      vipType.vipClassification = constants.INFLUENCER_TYPES.INFLUENCER
      break

    case constants.RESPONSE_FIELDS_MAPPING.MAINI:
      vipType.vipClassification = constants.INFLUENCER_TYPES.MAINI
      break

    case constants.RESPONSE_FIELDS_MAPPING.HEBE:
      vipType.vipClassification = constants.INFLUENCER_TYPES.HEBE
      break

    default:
      vipType.vipClassification = null
      break
  }

  return vipType
}

const getPhoneContactByPreference = numbers => {
  const [preferredNumbers, nonPreferredNumbers] = partition(
    numbers,
    elem => elem.preferred === true || elem.preferred === 'true',
  )

  if (preferredNumbers.length === 1) {
    return {
      mainNumber: head(preferredNumbers)?.medium,
      secondNumber: head(nonPreferredNumbers)?.medium,
    }
  }

  const [fixed, mobile] = partition(preferredNumbers, number => landlineRegex.test(number?.medium))

  if (mobile.length === 1 && fixed.length === 1) {
    return { mainNumber: head(fixed)?.medium, secondNumber: head(mobile)?.medium }
  }

  return { mainNumber: head(mobile)?.medium, secondNumber: last(mobile)?.medium }
}

const generateMultisimData = contract => {
  const packages = get(contract, 'packages')

  const newPackages = packages
    .map(elem => {
      const products = get(elem, 'products')

      const newProducts = products.map(newProduct => {
        const multisimData = packages
          .filter(pkg => {
            const product = head(get(pkg, 'products'))

            return (
              get(product, 'multisimType') === MULTISIM_LINE &&
              get(product, 'multisimId') === get(newProduct, 'subscriptionId')
            )
          })
          .map(pkg => head(get(pkg, 'products')))

        return {
          ...newProduct,
          multisimData,
        }
      })

      return {
        ...elem,
        products: newProducts,
      }
    })
    .filter(pkg => get(head(get(pkg, 'products')), 'multisimType') !== MULTISIM_LINE)

  return {
    ...contract,
    packages: newPackages,
  }
}

export const helpers = {
  getIdentificationStr,
  getSegmentStr,
  getContactPhonesStr,
  getBillingAddressStr,
  getLangFromId,
  getLangFromCode,
  getLastOrderId,
  isLineOnFly,
  getProductByMsisdn,
  getProductByLineId,
  getLineByLineId,
  getProductByOrderId,
  getInstallationAddressPackageByMsisdn,
  getMsisdnByOrderIdFromContracts,
  getProductByOrderIdFromContracts,
  getAllLinesCustomer,
  getAllLinesAndElFijoMsisdn,
  getAllLinesByType,
  isConvergent,
  getTariffId,
  STATUS_ON_FLY_STR,
  STATUS_ACTIVE_STR,
  getCharacteristic,
  getMedium,
  getPhoneContact,
  getPhoneContactByPreference,
  mapLanguage,
  getOrderIdByOrders,
  SegmentTypes,
  getMultisimTypeStr,
  getVipTypeFromResponse,
  generateMultisimData,
}
