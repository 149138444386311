import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { selectRouter } from 'modules/Router/store'
import { selectIsLoading, selectCustomerTokenError, selectAccountInfoError } from 'modules/Auth'

import { getCompanyInfo } from 'services/global-selectors/selectors'
import { selectIsOrderTypeElFijo } from 'modules/orders'

import { ExistingClientSidebar } from '../../components/ExistingClientSidebar'
import { AddressSidebar, TariffSidebar } from '../../components'

import { useLayoutData } from './selectors'

function CustomCp({ customer, customerInfo }) {
  const {
    location: { pathname },
  } = useSelector(selectRouter)
  const isElFijoCancellation = useSelector(selectIsOrderTypeElFijo)

  const props = {
    isElFijoCancellation,
    customer,
    customerInfo,
  }

  return pathname.includes('cancel') ? <AddressSidebar {...props} /> : <TariffSidebar {...props} />
}

CustomCp.propTypes = {
  customer: PropTypes.object,
  customerInfo: PropTypes.object,
}

function ExistingClientSidebarContainer() {
  const { orderLoading, orderError, ...childProps } = useLayoutData()

  const customerTokenLoading = useSelector(selectIsLoading)
  const customerTokenError = useSelector(selectCustomerTokenError)
  const accountInfoError = useSelector(selectAccountInfoError)

  const companyInfo = useSelector(getCompanyInfo)

  const isLoading = useMemo(() => customerTokenLoading || orderLoading, [
    customerTokenLoading,
    orderLoading,
  ])
  const isError = useMemo(() => !!accountInfoError || !!customerTokenError || !!orderError, [
    accountInfoError,
    customerTokenError,
    orderError,
  ])

  return (
    <ExistingClientSidebar
      companyInfo={companyInfo}
      isLoading={isLoading}
      isError={isError}
      {...childProps}>
      <CustomCp {...childProps} />
    </ExistingClientSidebar>
  )
}

export default ExistingClientSidebarContainer
