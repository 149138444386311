import { useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'

import { useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import { Box } from '@material-ui/core'

import { AdviceSection, Button } from 'components/ui'

import { SpinnerCenter } from 'components/packages/ui/SpinnerCenter'

import { Steps } from './Steps'

import { retrieveSubscription } from '../../store/device-renewal.actions'
import { renewalValidationMessagesError } from '../../constants/validation-errors'

import { deviceRenewalSelectors } from './StepsContainer.selectors'

const RetrieveSubscriptionAlertError = ({ title, body, onRetry }) => {
  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <Box maxWidth="872px" p="20px">
        <AdviceSection
          type="error"
          image={{ url: '/assets/error.svg', alt: 'Not found' }}
          title={title}
          body={body}>
          <Box display="flex" mt="15px" justifyContent="center">
            {onRetry && (
              <Button onClick={onRetry} secondary>
                Reintentar
              </Button>
            )}
          </Box>
        </AdviceSection>
      </Box>
    </Box>
  )
}
RetrieveSubscriptionAlertError.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string,
  onRetry: PropTypes.func,
}

export const StepsContainer = () => {
  const dispatch = useDispatch()
  const { msisdn, step: currentStepUrl } = useParams()

  const [step, setStep] = useState(currentStepUrl)

  const {
    isLoading,
    hasError,
    hasIbanError,
    basket,
    rejectionCode,
    isAllowed,
    isTvAllowed,
  } = useSelector(deviceRenewalSelectors)

  const ibanErrorMessage = hasIbanError ? renewalValidationMessagesError.iban_not_valid : undefined

  const props = {
    step,
    basket,
  }

  const loadData = useCallback(() => {
    dispatch(retrieveSubscription(msisdn))
  }, [msisdn])

  useEffect(() => {
    loadData()
  }, [msisdn])

  useEffect(() => {
    setStep(currentStepUrl)
  }, [currentStepUrl])

  if (isLoading) {
    return <SpinnerCenter />
  }

  return (
    <>
      {hasError && (
        <RetrieveSubscriptionAlertError
          title="Error"
          body="Ha ocurrido un error al cargar los datos, por favor inténtelo más tarde."
          onRetry={loadData}
        />
      )}

      {!hasError && ((!isAllowed && rejectionCode) || hasIbanError) && (
        <RetrieveSubscriptionAlertError
          title="Venta no permitida"
          body={
            renewalValidationMessagesError[rejectionCode] ||
            ibanErrorMessage ||
            renewalValidationMessagesError.general
          }
        />
      )}

      {!hasError && !hasIbanError && (isAllowed || isTvAllowed) && <Steps {...props} />}
    </>
  )
}
