import { axiosJWT } from 'modules/axios'
import { APP_CONFIG } from 'services/app-config'

const url = `${APP_CONFIG.sales_cluster}/v1/terminals/models`

const TERMINAL_LIST_CACHE = {}
export function fetchTerminalList(tariffId, channel, reseller, mobileNumberType) {
  const cacheKey = btoa(JSON.stringify({ tariffId, mobileNumberType }))

  if (TERMINAL_LIST_CACHE[cacheKey]) {
    return TERMINAL_LIST_CACHE[cacheKey]
  }

  TERMINAL_LIST_CACHE[cacheKey] = axiosJWT({
    method: 'GET',
    baseURL: `${url}/${tariffId}`,
    params: { channel, reseller, mobileNumberType },
  })
    .then(resp => {
      return resp.data
    })
    .catch(err => {
      TERMINAL_LIST_CACHE[cacheKey] = null
      throw err
    })

  return TERMINAL_LIST_CACHE[cacheKey]
}
