import * as Yup from 'yup/lib'
import { REQUIRED_FIELD_TEXT } from 'modules/SharedSales/constants/validations'

export const BillingSchema = {
  billing: Yup.object({
    paymentType: Yup.string().required(REQUIRED_FIELD_TEXT),
    transaction: Yup.object().when(['paymentType'], {
      is: paymentType => paymentType === 'TPV',
      then: Yup.object({
        hashSalida: Yup.string()
          .trim()
          .required(REQUIRED_FIELD_TEXT),
      }),
    }),
  }),
}
