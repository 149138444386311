import { put, select, take } from 'redux-saga/effects'

import { FIND_BONUSES_SUCCESS, FIND_BONUSES_ERROR, findBonusesAction } from './bonuses.actions'
import { selectBonusesErrorCode } from './bonuses.selectors'

export function* callBonusesSaga() {
  yield put(findBonusesAction())
  yield take([FIND_BONUSES_SUCCESS, FIND_BONUSES_ERROR])
  return yield select(selectBonusesErrorCode)
}
