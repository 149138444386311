import { createStructuredSelector, createSelector } from 'reselect'
import { formatTariffName } from 'modules/tariffs'
import { formatSummaryDiscounts } from 'modules/Discounts'
import { secondResidenceDiscountMessage } from 'modules/Discounts/constants'
import { COMPANY } from 'services/global-constants'
import { selectInstallationTechnologyName } from 'modules/Coverage/store/selectors'

import {
  selectIsMobileOnly,
  selectIs2p,
  selectIsSubscriptionQueryParam,
  selectIsCrossSellQueryParam,
  selectIsMultiSimQueryParam,
} from 'modules/Router/store/index'
import {
  selectMainMobileLineOffer,
  selectAccount,
  selectExtraMobileLinesOffers,
  selectLandlineOffer,
  selectedLandlineSVAs,
  selectMonthlyPayments,
  selectSaleTax,
  selectMainOfferTariff,
  selectIsElFijo,
  selectIsPro,
  selectSecondHomeDiscount,
  selectMainTariffCrossSellDiscounts,
  selectIs2pPure,
  selectedLandlineProSVAsFormated,
} from 'modules/NewClientSales/store/selectors/index'
import { selectFiberInstaller } from 'modules/Installations/store/selectors/installations.selectors'

import { getFixedLineTariffLabel } from '../../components/Common/common.label.helper'

import {
  mapToExtraMobileSummary,
  mapToMobileLineSummary,
  getLandLineNumber,
  getProvisionedMobileNumber,
  signUpTypeText,
  formatUserName,
  formatMobileTariffName,
  formatCrossTariffPromoToDiscountMessage,
} from './OfferSummaryContainer.helpers'

export const selectMainTariffName = createSelector(
  selectMainOfferTariff,
  selectSaleTax,
  selectIsMobileOnly,
  selectIsElFijo,
  (mainTariff, tax, isMobileOnly, isElFijo) =>
    isMobileOnly || isElFijo
      ? formatMobileTariffName(mainTariff)
      : formatTariffName(mainTariff, tax),
)

const selectLandlineNumber = createSelector(
  selectLandlineOffer,
  selectIs2p,
  (landline, is2p) => getLandLineNumber(landline, is2p),
)

const selectProvisionedMobileNumber = createSelector(
  selectLandlineOffer,
  selectIs2p,
  (landline, is2p) => getProvisionedMobileNumber(landline, is2p),
)

const selectSummaryLinesDiscounts = createSelector(
  selectLandlineOffer,
  selectMainMobileLineOffer,
  selectExtraMobileLinesOffers,
  (landline, mainLine, extras) => formatSummaryDiscounts([landline, mainLine, ...extras]),
)

const selectSignUpType = createSelector(
  selectLandlineOffer,
  landline => landline.signUpType,
)

const selectLandLineInternetName = createSelector(
  selectLandlineOffer,
  landline => {
    return getFixedLineTariffLabel(landline.tariff)
  },
)

const selectSignUpTypeText = createSelector(
  selectSignUpType,
  signUpTypeText,
)

const selectMainUser = createSelector(
  selectAccount,
  ({ name, surname1, surname2, companyName, segment }) => {
    const isCompany = segment === COMPANY
    return isCompany ? companyName : `${name} ${surname1} ${surname2}`
  },
)

const selectUserName = createSelector(
  selectLandlineOffer,
  selectMainUser,
  formatUserName,
)

const selectExtaMobileSummary = createSelector(
  selectExtraMobileLinesOffers,
  selectSaleTax,
  selectMainUser,
  selectIsMobileOnly,
  mapToExtraMobileSummary,
)

const selectMainMobileSummary = createSelector(
  selectMainMobileLineOffer,
  selectSaleTax,
  selectMainUser,
  selectLandlineOffer,
  selectIsMobileOnly,
  selectIsElFijo,
  mapToMobileLineSummary,
)

const selectDiscountMessages = createSelector(
  selectSecondHomeDiscount,
  selectMainTariffCrossSellDiscounts,
  (secondResidenceDiscount, crossSellTariffDiscounts) => {
    const discountMessages = []

    if (secondResidenceDiscount?.length > 0) {
      discountMessages.push(secondResidenceDiscountMessage)
    }

    if (crossSellTariffDiscounts?.length > 0) {
      crossSellTariffDiscounts.forEach(discount => {
        const messageDiscount = formatCrossTariffPromoToDiscountMessage(discount)
        if (messageDiscount?.messageText) {
          discountMessages.push(messageDiscount)
        }
      })
    }

    return discountMessages
  },
)

export const selectOfferSummary = createStructuredSelector({
  selectedSVA: selectedLandlineSVAs,
  selectedProSvas: selectedLandlineProSVAsFormated,
  userName: selectUserName,
  signUpTypeText: selectSignUpTypeText,
  signUpType: selectSignUpType,
  summaryLinesDiscounts: selectSummaryLinesDiscounts,
  landlineNumber: selectLandlineNumber,
  provisionedMobileNumber: selectProvisionedMobileNumber,
  landlineFiberInstaller: selectFiberInstaller,
  landlineInternetName: selectLandLineInternetName,
  landlineTechnology: selectInstallationTechnologyName,
  payments: selectMonthlyPayments,
  tax: selectSaleTax,
  extraMobileLinesOffers: selectExtaMobileSummary,
  mobileLineSummmaryProps: selectMainMobileSummary,
  isElFijo: selectIsElFijo,
  isPro: selectIsPro,
  is2p: selectIs2p,
  isSubscription: selectIsSubscriptionQueryParam,
  isMobileOnly: selectIsMobileOnly,
  discountMessages: selectDiscountMessages,
  isCrossSell: selectIsCrossSellQueryParam,
  is2pPure: selectIs2pPure,
  isMultiSim: selectIsMultiSimQueryParam,
})
