import { useEffect, memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { get } from 'lodash'

import { useForceSaveAuth } from 'modules/Auth'

import * as subscriptionsSvc from 'services/subscriptions'
import * as ticketingSvc from 'services/ticketing'

import { isElFijoTariff } from 'modules/tariffs/helpers'
import { buildPath, AppPaths } from 'modules/AppPaths'
import { useRequestIpAddress } from 'modules/Auth/hooks/useRequestIpAddress'
import subscriptionActions from 'services/subscriptions/actions'
import { productDashboardContainerSelectors } from './ProductDashboardContainer.selectors'
import { loadInitAction } from '../../sagas/productDashboardLoad'
import { ProductDashboard } from '../../components/ProductDashboard'

export const ProductDashboardContainer = memo(() => {
  const dispatch = useDispatch()

  const { requestIpAddress } = useRequestIpAddress()
  const { saveLatestAccessToken } = useForceSaveAuth()

  const {
    isLoading,
    hasErrors,
    productId,
    subscription,
    caseOpened,
    connectionData,
    permissionClientOpsAccess,
  } = useSelector(productDashboardContainerSelectors())

  const isElFijo = isElFijoTariff(get(subscription, 'type'))
  const product = subscriptionsSvc.getProductById(subscription, productId)
  const subscription_id = get(subscription, 'subscription_id')

  const operationsEnabled = product && subscriptionsSvc.isFixedLine(product)

  useEffect(() => {
    dispatch(loadInitAction())
    requestIpAddress()
  }, [])

  useEffect(() => {
    if (subscription_id) {
      dispatch(subscriptionActions.findDocumentCDCT(subscription_id))
    }
  }, [subscription_id])

  const operationsLink = operationsEnabled
    ? buildPath(
        AppPaths.client_operations.id,
        {},
        {
          documentId: connectionData.documentId,
          documentType: connectionData.documentType,
          fixedNumber: product.id,
          uuid: connectionData.uuid || '',
          sfid: connectionData.sfid || '',
          channel: 'newton',
          brand: 'yoigo',
        },
      )
    : ''

  const opClients = operationsEnabled
    ? [
        {
          disabled: !permissionClientOpsAccess || isElFijo,
          onClick: () => {
            saveLatestAccessToken()
            window.open(`${window.location.origin}${operationsLink}`, '_blank', 'toolbar=false')
          },
          label: 'Operaciones de cliente',
        },
      ]
    : []

  const actions = [
    // {
    //   disabled: caseOpened,
    //   onClick: () => dispatch(ticketingSvc.actions.create.openCase()),
    //   label: 'Crear Ticket',
    // },
    ...opClients,
  ]

  const props = {
    isLoading,
    hasErrors,
    caseOpened,
    subscription,
    productId,
    operationsEnabled,
    actions,
    onCloseCase: () => dispatch(ticketingSvc.actions.create.closeCase()),
  }

  return <ProductDashboard {...props} />
})
