import { constants } from './constants'

const findOrderOnFly = fixedNumber => ({
  type: constants.FIND_ORDERONFLY_INIT,
  payload: fixedNumber,
})

const findOrderOnFlySuccess = data => ({
  type: constants.FIND_ORDERONFLY_SUCCESS,
  payload: {
    ...data,
  },
})

const findOrderOnFlyFailed = error => ({
  type: constants.FIND_ORDERONFLY_FAILED,
  payload: error,
})

export const actions = { findOrderOnFly, findOrderOnFlySuccess, findOrderOnFlyFailed }
