import { createStructuredSelector } from 'reselect'
import {
  selectDiscountsWithIncompatibilities,
  selectSelectedDiscount,
  selectPutLoading,
  selectLoading,
  selectError,
  selectPutError,
  selectPutResponse,
} from 'services/discounts-order'
import {
  selectError as selectIncompatibilitiesError,
  selectLoading as selectIncompatibilitiesLoading,
} from 'services/discounts-incompatibilities'
import {
  selectDiscountsAgentLoading,
  selectDiscountsAgent,
  selectDiscountsAgentError,
} from 'services/discounts-agent'
import { selectURLOrderId } from 'modules/orders'

export const selectDiscountsContainerData = createStructuredSelector({
  discountsOrder: selectDiscountsWithIncompatibilities,
  discountsOrderLoading: selectLoading,
  discountsOrderError: selectError,
  discountsAgent: selectDiscountsAgent,
  discountsAgentLoading: selectDiscountsAgentLoading,
  discountsAgentError: selectDiscountsAgentError,
  selectedDiscount: selectSelectedDiscount,
  discountsIncompatibilitiesLoading: selectIncompatibilitiesLoading,
  discountsIncompatibilitiesError: selectIncompatibilitiesError,
  discountsPutResponse: selectPutResponse,
  discountsPutLoading: selectPutLoading,
  discountsPutError: selectPutError,
  orderId: selectURLOrderId,
})
