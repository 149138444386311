import PropTypes from 'prop-types'

import { Component } from 'react'
import { CardWrapper as Card } from 'components/ui'
import { Appointment } from 'modules/Appointments/components'

class NewAppointment extends Component {
  componentDidMount() {
    const { getAppointmentToken } = this.props
    getAppointmentToken()
  }

  onAppointmentFinish = () => {
    if (this.props.isD2D) {
      this.props.notifyD2D()
    } else {
      this.props.navigateToNextStep('next-steps', false)
    }
  }

  onAppointmentFailed = error => {
    this.onAppointmentFinish()
    if (this.props.isD2D) {
      this.props.notifyD2D()
    } else {
      this.props.setAppointmentError(error)
    }
  }

  enableNavigation = () => {
    const { token } = this.props

    return !token
  }

  render() {
    const { token } = this.props

    /* const localToken =
      'eyJhbGciOiJIUzI1NiJ9.eyJ1cmxDc3MiOiJodHRwczovL3JlYWN0Y2l0YWNpb25lc3ByZS5ibG9iLmNvcmUud2luZG93cy5uZXQvcmVhY3Qvc2l0ZVF2YW50ZWwuY3NzIiwic2VnbWVudCI6IlJFU0lERU5USUFMIiwic2VhcmNoU2xvdHNMaW1pdCI6MCwiY2F0ZWdvcnkiOiJJTlNUQUxMQVRJT04iLCJwcm9kdWN0U3BlY2lmaWNhdGlvbiI6eyJzY2hlbWEiOnsiaWQiOiJGVFRIIn19LCJhY3Rpb24iOiJOVUVWQUNJVEEiLCJzdGF0ZSI6IkNpdGFkYSIsInNjb3JpbmciOiIwIiwiZ2VzY2FsIjoiMjgwMDAwMTA1Mzk0MDAwMTkgICAgICAgICAwMDFBIiwib3JkZXJXb3JrSWQiOiJZNzAyMDE4MTA2NjY2NjY2NjY2NjAwMDQ2NjY2NjY2MSIsInJlbGF0ZWRQYXJ0eSI6eyJpZCI6IkVDQVJFIn0sImN1c3RvbWVyIjp7ImZpcnN0TmFtZSI6IkZvbyIsIm1pZE5hbWUiOiJCYXIiLCJsYXN0TmFtZSI6IkJheiIsImRvY3VtZW50VHlwZSI6Ik5JRiIsImRvY3VtZW50SWQiOiI0MzAwMzY2NE4iLCJjb21wYW55Q3VzdG9tZXIiOmZhbHNlLCJkb2N1bWVudElkQ2lmIjoiIn0sImNvbnRhY3QiOnsiY2l0eSI6Ik1hZHJpZCIsImNvdW50cnkiOiJFc3Bhw7FhIiwiZW1haWxBZGRyZXNzIjoiZm9vLmJhci5iYXpAZXhhbXBsZS5uZXQiLCJ0eXBlIjoiSW5zdGFsbGF0aW9uQWRkcmVzcyIsIm51bWJlciI6IiIsInBvc3RDb2RlIjoiMjgwMDciLCJzdGF0ZU9yUHJvdmluY2UiOiJNQURSSUQiLCJzdHJlZXRPbmUiOiJDYWxsZSBMb3MgTWVzZWpvIDE5Iiwic3RyZWV0VHdvIjoiIiwiZml4ZWROdW1iZXIiOiI5MTkyOTk0MTEiLCJjb250YWN0TnVtYmVyIjoiNjk5OTk5OTk5In0sIm1lc3NhZ2UiOiIifQ.GrfX1U9waNuhcL05v7vMgNKDXcEHUYHPVHiE29pDC44'
    */
    return (
      <>
        <Card width="100%">
          {token && (
            <Appointment
              withoutCss
              token={token}
              onAppointmentFinish={this.onAppointmentFinish}
              onAppointmentFailed={this.onAppointmentFailed}
            />
          )}
        </Card>
      </>
    )
  }
}
NewAppointment.propTypes = {
  token: PropTypes.string,
  getAppointmentToken: PropTypes.func,
  setAppointmentError: PropTypes.func,
  isD2D: PropTypes.bool,
  notifyD2D: PropTypes.func,
  navigateToNextStep: PropTypes.func,
}

export default NewAppointment
