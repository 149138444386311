import { useMemo, useCallback, useState } from 'react'
import PropTypes from 'prop-types'

import { useSelector, useDispatch } from 'react-redux'
import { Box } from '@material-ui/core'
import { useFormikContext } from 'formik'
import { SINGLE } from 'services/global-constants'

import { Button } from 'components/ui'
import { SpinnerCenter } from 'components/packages/ui/SpinnerCenter'

import { ORDERED_DEVICE_RENEWAL_STEPS } from 'modules/device-renewal/constants/steps'
import { useStepNavigation } from 'utils/navigation'

import { reserveTerminalById } from 'modules/Terminals/services/TerminalReserveService/index'
import { withDeviceRenewalForms } from '../../hocs/withDeviceRenewalForms'
import { useDeviceOffers } from '../../hooks'
import {
  selectDeviceType,
  selectSubscriptionFinancedDeviceInfo,
} from '../../store/device-renewal.selectors'
import { getSidebarDeviceType } from '../../store/device-renewal.selectors.helpers'
import { setDeviceRenewalData } from '../../store/device-renewal.actions'
import {
  DEVICE_RENEWAL_FIELDNAMES,
  DEVICE_RESERVATION_AND_STOCK_CHANNEL,
  DEVICE_TYPE_AGILE_TV,
} from '../../constants'

import { DeviceOffers } from './DeviceOffers/DeviceOffers'

import { RenewalSaveChangesStepButton } from '../../components/RenewalSaveChangesStepButton'

export const DeviceOffersContainer = withDeviceRenewalForms(({ step }) => {
  const dispatch = useDispatch()
  const selectedDeviceType = useSelector(selectDeviceType)

  const { values, setFieldValue } = useFormikContext()
  const { goBack } = useStepNavigation(ORDERED_DEVICE_RENEWAL_STEPS, step)

  const currentFinancedTerminalInfo = useSelector(selectSubscriptionFinancedDeviceInfo)

  const [reservationError, setReservationError] = useState(null)

  const deviceSelected = useMemo(() => {
    const { id, paymentMethods, stock, stock_error } = values[DEVICE_RENEWAL_FIELDNAMES.DEVICE]

    return {
      id,
      paymentMethod: paymentMethods ? Object.keys(paymentMethods)[0] : null,
      stock,
      stock_error,
    }
  }, [values[DEVICE_RENEWAL_FIELDNAMES.DEVICE]])

  const reserveDeviceAndValidate = useCallback(async () => {
    let hasTerminalBeenReserved = true
    setReservationError(null)

    try {
      if (values.device.id) {
        const reservationId = await reserveTerminalById(
          values.device.id,
          DEVICE_RESERVATION_AND_STOCK_CHANNEL,
        )

        dispatch(
          setDeviceRenewalData({
            reservationId,
          }),
        )
      }
    } catch (err) {
      console.warn(err)
      hasTerminalBeenReserved = false
      setReservationError(
        'No se ha podido reservar el dispositivo. Por favor, vuelve a intentarlo más tarde.',
      )
    }
    return hasTerminalBeenReserved
  })

  const deviceType = useMemo(() => {
    let value = getSidebarDeviceType(selectedDeviceType)

    if (value === 'Terminal') {
      value = value.toLowerCase()
    }

    if (value === 'Otro' || value === '-') {
      value = 'dispositivo'
    }

    return value
  }, [selectedDeviceType])

  const isAgileTvRenewal = selectedDeviceType === DEVICE_TYPE_AGILE_TV

  const {
    brandOptions,
    modelOptions,
    screenSizeOptions,
    capacityOptions,
    categoryOptions,
    fetchDevicesError,
    fetchAgileTvError,
    isFetching,
    agileTvData,
    resetDevicesCatalog,
    devicesToShow,
    canShowMoreDevices,
    showMoreDevices,
    filteredDevices,
    devicesStock,
    retryFetchDeviceStock,
    orderFields,
    applyOrder,
  } = useDeviceOffers(DEVICE_RESERVATION_AND_STOCK_CHANNEL, isAgileTvRenewal)

  const selectedDeviceStock = useMemo(() => {
    let stock

    if (deviceSelected?.id && devicesStock) {
      stock = devicesStock.find(deviceStock => deviceStock.deviceId === deviceSelected.id)?.stock
    }

    return stock
  }, [deviceSelected, devicesStock])

  const onSelectDevice = device => {
    const paymentType = Object.keys(device.paymentMethods)[0]

    setFieldValue(DEVICE_RENEWAL_FIELDNAMES.DEVICE, { ...device, stock_error: false })
    if (paymentType === SINGLE) {
      setFieldValue(DEVICE_RENEWAL_FIELDNAMES.ID_DUE_DATE, '')
      setFieldValue(DEVICE_RENEWAL_FIELDNAMES.SALARY, '')
      setFieldValue(DEVICE_RENEWAL_FIELDNAMES.COMPANY, '')
      setFieldValue(DEVICE_RENEWAL_FIELDNAMES.JOB, '')
    }

    dispatch(
      setDeviceRenewalData({
        [DEVICE_RENEWAL_FIELDNAMES.DEVICE]: { ...device, stock_error: false },
        reservationId: '',
      }),
    )
  }

  const props = {
    deviceType,
    brandOptions,
    modelOptions,
    screenSizeOptions,
    capacityOptions,
    categoryOptions,
    filteredDevices: devicesToShow,
    fetchAgileTvError,
    fetchDevicesError,
    isFetching,
    agileTvData,
    deviceSelected,
    onSelectDevice,
    resetDevicesCatalog,
    currentFinancedTerminalInfo,
    reservationError,
    showMoreDevices,
    canShowMoreDevices,
    totalDevicesCount: filteredDevices.length,
    devicesStock,
    retryFetchDeviceStock,
    isAgileTvRenewal,

    orderFields,
    applyOrder,
  }

  return (
    <>
      {isFetching || !devicesToShow ? (
        <SpinnerCenter showMsg />
      ) : (
        <>
          <DeviceOffers {...props} />

          <Box display="flex" alignItems="center" pt="12px">
            <RenewalSaveChangesStepButton
              data-hook="device-offers-continue"
              stepName={step}
              isLoading={false}
              disabled={!deviceSelected.id || !selectedDeviceStock}
              customValidationAction={reserveDeviceAndValidate}
            />

            <Button margin="0 0 0 16px" secondary onClick={goBack} data-hook="exit-device-renew">
              Atrás
            </Button>
          </Box>
        </>
      )}
    </>
  )
})
DeviceOffersContainer.propTypes = {
  step: PropTypes.string,
}
