import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { get } from 'lodash'
import { Formik } from 'formik'
import { Box, Typography } from '@material-ui/core'
import {
  Modal,
  SpinnerCenter,
  ModalActions,
  Button,
  SpinnerButton,
  ActionAdvice,
} from 'components/ui'

import { AgileTvModalForm } from 'modules/agile-tv/components'
import { selectContentfulResource } from 'modules/contentful'
import { NETFLIX_TARIFFS, ALTA_AGILE_CARTERA_OPERATION, PENALTY_AGILE_CARTERA_OPERATION } from 'modules/offers-configuration/constants'
import { formatFee } from 'utils'
import { applyTax } from 'services/taxes'
import { getCurrentCustomerTax } from 'modules/offers-configuration/selectors'

export const AddAgileTvModal = ({
  open,
  onClose,
  isLoading,
  step,
  setStep,
  agileTvEmail,
  setAgileTvEmail,
  agileTvSelected,
  setAgileTvSelected,
  agileTvSva,
  emailValidationIsLoading,
  msisdn,
  tariffId,
  isSaving,
  applyAddAgileTv,
}) => {
  const legalTexts = useSelector(selectContentfulResource('legalText')) || []
  const agileTvLegalText = get(legalTexts.find(legalText => legalText.operation === ALTA_AGILE_CARTERA_OPERATION), 'text')
  const penaltyAgileTv = get(legalTexts.find(legalText => legalText.operation === PENALTY_AGILE_CARTERA_OPERATION), 'text')
  const showLegalText = NETFLIX_TARIFFS.includes(tariffId)
  const tax = useSelector(getCurrentCustomerTax) || 0
  const penaltyWithTaxes = applyTax(tax, penaltyAgileTv).toFixed(2)
 
  return (
    <Modal title="Añadir servicio televisión" isOpen={open} onClose={!isSaving ? onClose : null}>
      {isLoading && <SpinnerCenter style={{ marginTop: '20px' }} />}

      {!isLoading && agileTvSva && (
        <>
          {step === 0 && (
            <Formik
              initialValues={{ email: agileTvEmail, agileTv: agileTvSelected }}
              onSubmit={values => {
                const { email, agileTv } = values
                setAgileTvEmail(email)
                setAgileTvSelected(agileTv)
                setStep(1)
              }}>
              {props => (
                <AgileTvModalForm
                  agileTvSva={agileTvSva}
                  emailValidationIsLoading={emailValidationIsLoading}
                  onClose={onClose}
                  legalText={agileTvLegalText}
                  showLegalText={showLegalText}
                  penalty={penaltyWithTaxes}
                  {...props}
                />
              )}
            </Formik>
          )}

          {step === 1 && (
            <>
              <Box mb="16px">
                <Typography variant="body1" component="h5" color="primary" className="bold">
                  Resumen
                </Typography>

                <ActionAdvice
                  className="m-y-24"
                  type="info"
                  message={`${agileTvSelected.commercialInfo.name} (${formatFee(
                    agileTvSelected.price.taxFreeAmount,
                  )}/mes imp. incluido) - ${msisdn}`}
                />
              </Box>

              <Box mb="23px">
                <Typography variant="body1" component="h5" color="primary" className="bold">
                  Email
                </Typography>
                <Box mt="10px" mb="5px">
                  <Typography variant="body1" style={{ maxWidth: '256px', fontWeight: 'bold' }}>
                    {agileTvEmail}
                  </Typography>
                </Box>
              </Box>

              <Box mb="23px">
                <Typography variant="body1" component="h5" color="primary" className="bold">
                  Facturación
                </Typography>

                <Box mt="10px" mb="5px">
                  <Typography variant="subtitle1" color="textSecondary" style={{ fontWeight: 200 }}>
                    Se aplicará el cobro en la próxima factura
                  </Typography>
                </Box>
              </Box>

              <ModalActions>
                {!isSaving && <Button onClick={applyAddAgileTv}>Confirmar</Button>}
                {isSaving && <SpinnerButton disabled />}

                <Button secondary onClick={() => setStep(0)} disabled={isSaving}>
                  Volver atrás
                </Button>
              </ModalActions>
            </>
          )}
        </>
      )}
    </Modal>
  )
}

AddAgileTvModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  isLoading: PropTypes.bool,
  step: PropTypes.number.isRequired,
  setStep: PropTypes.func.isRequired,
  agileTvEmail: PropTypes.string.isRequired,
  setAgileTvEmail: PropTypes.func.isRequired,
  agileTvSelected: PropTypes.object.isRequired,
  setAgileTvSelected: PropTypes.func.isRequired,
  agileTvSva: PropTypes.array,
  emailValidationIsLoading: PropTypes.bool,
  msisdn: PropTypes.string.isRequired,
  tariffId: PropTypes.string,
  isSaving: PropTypes.bool,
  applyAddAgileTv: PropTypes.func.isRequired,
}
