import { get } from 'lodash'
import { selectSfid } from 'modules/Auth'
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import { logError, formatErrorMessage } from 'services/logging'
import { getSubscriptionId, selectSubscriptionByProductId } from 'services/subscriptions'
import { fetchBarringsStatus, setBarringStatus } from '../services/barrings.service'
import * as actions from './barrings.actions'

export function* findBarringsStatusSaga({ payload: { productId } }) {
  try {
    const response = yield call(fetchBarringsStatus, productId)
    const barringsStatusData = get(response, 'data')
    yield put(actions.findBarringsStatusSuccessAction(productId, barringsStatusData))
  } catch (e) {
    yield call(logError, { e: new Error(formatErrorMessage(e)), productId })
    yield put(actions.findBarringsStatusFailedAction(productId, e))
  }
}

export function* setBarringStatusSaga({ payload: { productId, barrings, data } }) {
  try {
    const subscription = yield select(selectSubscriptionByProductId(productId))
    const subscriptionId = getSubscriptionId(subscription)

    const sfid = yield select(selectSfid)
    yield call(setBarringStatus, subscriptionId, sfid, data)

    for (let i = 0; i < barrings.length; i += 1) {
      yield put(actions.setBarringStatusSuccededAction(productId, barrings[i]))
    }

    yield put(actions.setMultipleBarringStatusSucceededAction(productId))
  } catch (e) {
    yield call(logError, { e: new Error(formatErrorMessage(e)), productId, barrings, data })
    yield put(actions.setMultipleBarringStatusFailedAction(productId))
  }
}

export function* watchBarringsStatus() {
  yield takeEvery(actions.FIND_BARRINGS_STATUS, findBarringsStatusSaga)
  yield takeLatest(actions.SET_MULTIPLE_BARRINGS_STATUS_ATTEMPTED, setBarringStatusSaga)
}
