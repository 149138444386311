import { get, isNil, isEmpty, toUpper } from 'lodash'
import { PRO_TARIFF_SEGMENT } from 'modules/tariffs/tariffs.constants'
import { TELESALES_PRO, POS, TELESALES, NEWTON } from 'services/global-constants/channels'
import { POS_PROFILE, MASPROXIMO_PROFILE } from 'modules/Permissions/constants'
import { isProSohoProfile } from 'modules/Permissions/profiles'
import { CUSTOMER_PRO_SEGMENT } from 'services/customer-360/constants'

export const getTelesalesChannel = (profile, segment, queryParams, agentSfid = '') => {
  const channel = get(queryParams, 'channel')

  let telesalesChannel
  let segmentBuilt

  if (isNil(segment)) {
    segmentBuilt =
      channel === TELESALES_PRO ||
      (channel === NEWTON && !isEmpty(agentSfid) && isProSohoProfile(toUpper(agentSfid)))
        ? PRO_TARIFF_SEGMENT
        : TELESALES
  } else {
    segmentBuilt = segment
  }

  if (profile === POS_PROFILE || profile === MASPROXIMO_PROFILE) {
    telesalesChannel = POS
  } else if (segmentBuilt === PRO_TARIFF_SEGMENT || CUSTOMER_PRO_SEGMENT.includes(segmentBuilt)) {
    telesalesChannel = TELESALES_PRO
  } else {
    telesalesChannel = TELESALES
  }

  return telesalesChannel
}
