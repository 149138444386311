import { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { get, chain, isEmpty } from 'lodash'
import { useSelector } from 'react-redux'
import { permissions, selectCanIDO } from 'modules/Permissions'
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Icon,
  IconButton,
  Box,
  CircularProgress,
  Typography,
  Tooltip,
  Checkbox,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { buildPath, AppPaths } from 'modules/AppPaths'
import { ThemedCell, ButtonLink, ButtonCard } from 'components/ui'
import { formatDate, formatMonthAbreviate, formatYearAbreviate } from 'utils/formatting/dates'
import { formatNumberWithComma, formatFee } from 'utils'
import { selectURLDocumentId, selectURLDocumentType } from 'modules/CustomerDocument'
import { CVMActions } from 'modules/offers-configuration/CVMActions.constants'
import { selectBillingDifferencesFlag } from 'services/feature-flag/selectors'
import { BILLING_GROUPS } from 'modules/billings/billings.constants'
import { CreditModalContainer } from '../../containers/CreditModalContainer/CreditModalContainer'
import DownloadBtnPdf from '../BillingCard/Bill/DownloadBtn/DownloadBtnPdf'
import { AdjustBillModal } from './AdjustBillModal'

const LITERAL_CREDITED = 'ABONADO'
const LITERAL_CREDIT = 'ABONAR'

const formatLiteralDate = monthDate => {
  return `${formatMonthAbreviate(monthDate)} ${formatYearAbreviate(monthDate)}`
}

const useStyles = makeStyles(theme => ({
  'unpaid-text': {
    color: theme.palette.brand.alert,
  },
  'subinvoices-table': {
    '& tbody': {
      borderRadius: '0',

      '& tr td': {
        backgroundColor: '#E9F2FF',
        borderLeft: 0,
        borderRight: 0,

        '&:first-child': {
          width: 'unset',
          borderRight: 0,
          borderLeft: 0,
          fontSize: '14px',
          padding: '5.5px 20px',
        },
      },
    },
  },
  'breakdown-table': {
    margin: '10px 5px',
    '& thead tr th': {
      padding: '0 5px 15px',
      fontSize: '12px',
      fontWeight: 'bold',
      height: '39px',
    },

    '& tbody tr': {
      '&.losted-discount td': {
        color: theme.palette.brand.alert,
      },

      '& td': {
        fontWeight: 'normal',
        padding: '0 5px 5px',

        '& .adjust-item-check': {
          padding: '0 9px',
          marginLeft: '-16px',
        },

        '&.diff-price, &.price': {
          textAlign: 'right',
        },

        '&.price': {
          paddingRight: '20px',
          width: '100px',
        },

        '&.diff-price': {
          fontWeight: 500,
          fontSize: '16px',
          paddingRight: '20px',
          width: '200px',
        },

        '&.comments': {
          fontSize: '12px',
        },
      },

      '&.real-head td, &.real-head td.diff-price': {
        fontSize: '13px',
        fontWeight: 'bold',
      },
    },

    '& tbody tr td, & thead tr th': {
      color: theme.palette.global.gray_dark,

      textAlign: 'left',
      borderBottom: 0,

      '&:first-child': {
        width: '50px',
        padding: '0 5px 5px',
        textAlign: 'right',
      },

      '&:last-child': {
        borderLeft: 0,
      },
    },
  },
}))

// eslint-disable-next-line react/prop-types
const TextAmount = ({ bill }) => {
  const classes = useStyles()

  const amount = get(bill, 'amount', 0)
  const isUnpaid = get(bill, 'isUnpaid', false)

  return (
    <>
      {!isUnpaid && `${formatNumberWithComma(amount)} €`}
      {isUnpaid && (
        <Typography variant="body2" component="span" className={classes['unpaid-text']}>
          {`Impagado ${formatNumberWithComma(amount)} €`}
        </Typography>
      )}
    </>
  )
}

function showTextPayMode(bill) {
  const literal = chain(bill)
    .get('payments')
    .head()
    .get('source')
    .value()

  return literal === 'DD' ? 'Domiciliado' : literal
}

const TableWithSubInvoiceRow = ({
  data,
  onBillAdjustClick,
  isCredited,
  creditDisabled,
  fatDisabled,
  onCreditClick,
  isLoadingRegisters,
  allowSubInvoiceButtons,
}) => {
  const classes = useStyles()

  const [isSummaryExpanded, setIsSummaryExpanded] = useState(false)
  const [isShowFullBreakdown, setIsShowFullBreakdown] = useState(false)
  const [adjustSelectedItems, setAdjustSelectedItems] = useState({})

  const invoiceRowsToShow = useMemo(() => {
    const lostedRows = data.lostedRows || []

    return [
      ...(isShowFullBreakdown ? data.rows : data.rows.filter(row => row.diffTotal > 0)),
      ...(isShowFullBreakdown
        ? lostedRows
        : lostedRows.filter(
            row => row.billingItem.group.code === BILLING_GROUPS.DISCOUNTS && row.diffTotal > 0,
          )),
    ]
  }, [isShowFullBreakdown, data.rows, data.lostedRows])

  const onAdjustItemSelectedChange = row => {
    const selectedItems = { ...adjustSelectedItems }

    if (selectedItems[row.id]) {
      delete selectedItems[row.id]
    } else {
      selectedItems[row.id] = row
    }

    setAdjustSelectedItems(selectedItems)
  }

  const documentType = useSelector(selectURLDocumentType)
  const documentId = useSelector(selectURLDocumentId)

  const pathToAddDiscountsOrBundlesCVM = buildPath(
    AppPaths.cvm.id,
    { documentType, documentId },
    { subscription_id: data.subInvoice.msisdn, op: CVMActions.add_discounts_or_bundles.id },
  )

  const onBillAdjust = () => {
    const adjustData = chain(Object.values(adjustSelectedItems))
      .reduce(
        (result, value) => ({
          total: result.total + value.diffTotal,
          totalWithoutVat: result.totalWithoutVat + value.diffTotalWithoutVat,
          concepts: [
            ...result.concepts,
            `${value.billingItem.group.nameEs} ${value.billingItem.nameEs}`,
          ],
        }),
        { total: 0, totalWithoutVat: 0, concepts: [] },
      )
      .value()

    onBillAdjustClick(adjustData)
  }

  return (
    <>
      <TableRow>
        <ThemedCell colspan="3">
          <Box display="flex" alignItems="center">
            <IconButton
              data-hook="expand-subInvoice"
              aria-label="expand row"
              size="small"
              style={{ margin: '0 16px' }}
              onClick={() => setIsSummaryExpanded(!isSummaryExpanded)}>
              <Icon>{isSummaryExpanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</Icon>
            </IconButton>
            <a
              href="#"
              className="expand-link"
              onClick={e => {
                e.preventDefault()
                setIsSummaryExpanded(!isSummaryExpanded)
              }}>
              {data.subInvoice.fixedNumber && `${data.subInvoice.fixedNumber} -`}{' '}
              {data.subInvoice.msisdn}
            </a>
          </Box>
        </ThemedCell>
        <ThemedCell className="amount">{formatNumberWithComma(data.subInvoice.total)} €</ThemedCell>
        <ThemedCell className="credit-column" />
      </TableRow>
      {isSummaryExpanded && (
        <TableRow>
          <ThemedCell colspan="5">
            <Table size="small" className={classes['breakdown-table']}>
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: '50px' }} />
                  <TableCell colspan="2">
                    <Box display="flex" alignItems="center">
                      <Box mr="16px">DESGLOSE DE FACTURA</Box>
                      <ButtonLink onClick={() => setIsShowFullBreakdown(!isShowFullBreakdown)}>
                        Ver/ocultar desglose completo
                      </ButtonLink>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box display="flex" justifyContent="flex-end">
                      <Box marginRight="16px" display="flex" alignItems="center">
                        {isLoadingRegisters ? (
                          <CircularProgress size="20px" />
                        ) : (
                          <Tooltip title="Ya abonado" disableHoverListener={!isCredited}>
                            <span>
                              <ButtonCard
                                className="credit-button"
                                data-hook="credit-btn"
                                label="Credit note"
                                style={{ whiteSpace: 'nowrap' }}
                                disabled={
                                  !allowSubInvoiceButtons ||
                                  isCredited ||
                                  creditDisabled ||
                                  !isEmpty(Object.keys(adjustSelectedItems))
                                }
                                onClick={onCreditClick}
                              />
                            </span>
                          </Tooltip>
                        )}
                      </Box>

                      <ButtonCard
                        label="FAT"
                        onClick={onBillAdjust}
                        disabled={
                          fatDisabled ||
                          !allowSubInvoiceButtons ||
                          isEmpty(Object.keys(adjustSelectedItems))
                        }
                      />

                      <Box marginLeft="16px">
                        <ButtonCard
                          label="Aplicar descuento"
                          to={pathToAddDiscountsOrBundlesCVM}
                          style={{ whiteSpace: 'nowrap' }}
                          disabled={
                            !allowSubInvoiceButtons || !isEmpty(Object.keys(adjustSelectedItems))
                          }
                        />
                      </Box>
                    </Box>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!isEmpty(invoiceRowsToShow) && (
                  <TableRow className="real-head">
                    <TableCell />
                    <TableCell>Concepto</TableCell>
                    <TableCell className="price">Importe mes</TableCell>
                    <TableCell className="diff-price" title="Diferencia de importe mes anterior">
                      Dif. importe mes anterior
                    </TableCell>
                    <TableCell>Comentarios</TableCell>
                  </TableRow>
                )}

                {invoiceRowsToShow.map(row => (
                  <TableRow key={row.id} className={row.losted && 'losted-discount'}>
                    <TableCell>
                      {row.diffTotal > 0 && (
                        <Checkbox
                          checked={!!adjustSelectedItems[row.id]}
                          onChange={() => onAdjustItemSelectedChange(row)}
                          className="adjust-item-check"
                        />
                      )}
                    </TableCell>
                    <TableCell>
                      {row.billingItem.group.nameEs} {row.billingItem.nameEs}
                    </TableCell>
                    <TableCell className="price">{!row.losted && formatFee(row.total)} </TableCell>
                    <TableCell className="diff-price">
                      {!!row.diffTotal && formatFee(row.diffTotal)}
                    </TableCell>
                    <TableCell className="comments">
                      {row.losted && (
                        <>
                          {row.billingItem.group.code === BILLING_GROUPS.DISCOUNTS &&
                          row.diffTotal > 0
                            ? 'Este mes, el cliente ha perdido este descuento'
                            : 'Este mes el cliente ya no paga esta cuota'}
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                ))}

                {isEmpty(invoiceRowsToShow) && (
                  <TableRow>
                    <TableCell />
                    <TableCell>Ningún cambio en su factura respecto al mes anterior.</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </ThemedCell>
        </TableRow>
      )}
    </>
  )
}
TableWithSubInvoiceRow.propTypes = {
  data: PropTypes.object.isRequired,
  onBillAdjustClick: PropTypes.func.isRequired,
  isCredited: PropTypes.bool,
  creditDisabled: PropTypes.bool,
  fatDisabled: PropTypes.bool,
  onCreditClick: PropTypes.func.isRequired,
  isLoadingRegisters: PropTypes.bool,
  allowSubInvoiceButtons: PropTypes.bool,
}

const TableBillRow = ({
  data,
  monthDate,
  onCreditClick,
  creditDisabled,
  fatDisabled,
  isLoadingRegisters,
  onBillAdjustClick,
  billingDifferencesFlag,
}) => {
  const classes = useStyles()

  const [isSummaryExpanded, setIsSummaryExpanded] = useState(false)

  return (
    <>
      <TableRow>
        <ThemedCell align="center" rowSpan={isSummaryExpanded ? 2 : 1}>
          {formatLiteralDate(monthDate)}
        </ThemedCell>
        <ThemedCell align="center" className="billId">
          {billingDifferencesFlag && (
            <IconButton
              data-hook="expand-subInvoices"
              aria-label="expand row"
              size="small"
              style={{ marginRight: '16px' }}
              onClick={() => setIsSummaryExpanded(!isSummaryExpanded)}>
              <Icon>{isSummaryExpanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</Icon>
            </IconButton>
          )}

          <a
            href="#"
            className="expand-link"
            onClick={e => {
              e.preventDefault()
              setIsSummaryExpanded(!isSummaryExpanded)
            }}>
            {data.externalId}
          </a>
        </ThemedCell>
        <ThemedCell align="center">{showTextPayMode(data)}</ThemedCell>
        <ThemedCell align="center">{formatDate(data.paymentDueDate)}</ThemedCell>
        <ThemedCell align="center" className="amount">
          <TextAmount bill={data} />
        </ThemedCell>
        <ThemedCell align="center" className="credit-column">
          <DownloadBtnPdf billId={data.id} />
        </ThemedCell>
        {!billingDifferencesFlag && (
          <ThemedCell align="center">
            {isLoadingRegisters ? (
              <CircularProgress size="20px" />
            ) : (
              <>
                {data.isCredited ? (
                  LITERAL_CREDITED
                ) : (
                  <ButtonLink
                    className="credit-button"
                    data-hook="credit-btn"
                    disabled={creditDisabled}
                    onClick={onCreditClick}>
                    {LITERAL_CREDIT}
                  </ButtonLink>
                )}
              </>
            )}
          </ThemedCell>
        )}
      </TableRow>
      {isSummaryExpanded && (
        <TableRow>
          <ThemedCell />
          <ThemedCell style={{ padding: 0 }}>
            <TableRow>
              <Table className={classes['subinvoices-table']}>
                <TableBody>
                  {data.subInvoicesWithRows.map(subInvoiceWithRows => (
                    <TableWithSubInvoiceRow
                      key={subInvoiceWithRows.subInvoice.invoiceId}
                      data={subInvoiceWithRows}
                      onCreditClick={onCreditClick}
                      isCredited={data.isCredited}
                      creditDisabled={creditDisabled}
                      fatDisabled={fatDisabled}
                      isLoadingRegisters={isLoadingRegisters}
                      onBillAdjustClick={adjustData =>
                        onBillAdjustClick({
                          ...adjustData,
                          invoiceId: subInvoiceWithRows.subInvoice.invoiceId,
                          subscriptionId: subInvoiceWithRows.subInvoice.subscriptionId,
                          msisdn: subInvoiceWithRows.subInvoice.msisdn,
                          tariff: subInvoiceWithRows.subInvoice.subscriptionType,
                          customerId: subInvoiceWithRows.subInvoice.customerId,
                        })
                      }
                      allowSubInvoiceButtons={
                        data.amount > 0 && subInvoiceWithRows.subInvoice.total > 0
                      }
                    />
                  ))}
                </TableBody>
              </Table>
            </TableRow>
          </ThemedCell>
        </TableRow>
      )}
    </>
  )
}
TableBillRow.propTypes = {
  data: PropTypes.object.isRequired,
  monthDate: PropTypes.object.isRequired,
  onCreditClick: PropTypes.func.isRequired,
  creditDisabled: PropTypes.bool,
  fatDisabled: PropTypes.bool,
  isLoadingRegisters: PropTypes.bool,
  onBillAdjustClick: PropTypes.func.isRequired,
  billingDifferencesFlag: PropTypes.bool,
}
export function BillTableBody({ data, isLoadingRegisters }) {
  const [openCreditModal, setOpenCreditModal] = useState(false)
  const [activeBill, setActiveBill] = useState()
  const [billAdjustData, setBillAdjustData] = useState(null)
  const [refreshData, setRefreshData] = useState(false)

  const isBillAdjustModalOpen = billAdjustData !== null
  const billingDifferencesFlag = useSelector(selectBillingDifferencesFlag)

  const disabledCreditAndFat = !useSelector(selectCanIDO(permissions.credit_fat_ops_access.id))

  return (
    <>
      <AdjustBillModal
        data={billAdjustData}
        show={isBillAdjustModalOpen}
        onClose={result => {
          setBillAdjustData(null)

          if (result) {
            setRefreshData(true)
            setTimeout(() => setRefreshData(false), 20)
          }
        }}
      />

      <TableBody>
        {!refreshData &&
          data.map(monthBills => {
            const [monthDate, bills] = monthBills

            return bills.map(bill => (
              <TableBillRow
                key={bill.id}
                data={bill}
                monthDate={monthDate}
                onCreditClick={() => {
                  setOpenCreditModal(true)
                  setActiveBill(bill)
                }}
                creditDisabled={disabledCreditAndFat}
                fatDisabled={disabledCreditAndFat}
                isLoadingRegisters={isLoadingRegisters}
                onBillAdjustClick={setBillAdjustData}
                billingDifferencesFlag={billingDifferencesFlag}
              />
            ))
          })}
      </TableBody>

      {activeBill && (
        <CreditModalContainer
          invoice={activeBill}
          isOpen={openCreditModal}
          onCloseModal={setOpenCreditModal}
        />
      )}
    </>
  )
}

BillTableBody.propTypes = {
  data: PropTypes.array,
  isLoadingRegisters: PropTypes.bool,
}

export default BillTableBody
