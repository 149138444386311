import styled from 'styled-components'

const CardTitle = styled.h3`
  height: 21px;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #555555;
  margin-top: 0;
  margin-bottom: ${props => (props.single ? '0px' : '21px;')};
  text-align: ${props => (props.align ? props.align : 'left')};
`

export default CardTitle
