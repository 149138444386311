import { get } from 'lodash'
import { createSelector } from 'reselect'

export const getIsAllowedData = state => get(state, 'multisim.isAllowed', {})

export const selectIsMultiSimAllowedData = createSelector(
  [getIsAllowedData],
  isAllowedData => isAllowedData?.data || null,
)
export const selectIsMultiSimAllowedLoading = createSelector(
  [getIsAllowedData],
  isAllowedData => isAllowedData?.loading,
)
export const selectIsMultiSimAllowedError = createSelector(
  [getIsAllowedData],
  isAllowedData => isAllowedData?.error,
)

export const selectIsMultiSimAllowed = createSelector(
  [selectIsMultiSimAllowedLoading, selectIsMultiSimAllowedData],
  (isLoading, data) => !isLoading && data,
)
