import { SVA_HEBE_CUSTOMER_CODE } from 'modules/offers-configuration/constants'
import { useDispatch, useSelector } from 'react-redux'

import { actions } from 'services/customer-sva/actions.js'
import {
  selectHebeSvaCustomerError,
  selectHebeSvaCustomerSuccess,
  selectHebeSvaCustomerLoading,
} from 'services/customer-sva/selectors'
import { SVA_STATUS_DISABLED, SVA_STATUS_ENABLED } from 'services/subscriptions/constants'

export const useHebeSvaCustomer = () => {
  const dispatch = useDispatch()

  const isHebeSvaCustomerSuccess = useSelector(selectHebeSvaCustomerSuccess)
  const isHebeSvaCustomerError = useSelector(selectHebeSvaCustomerError)
  const isHebeSvaCustomerLoading = useSelector(selectHebeSvaCustomerLoading)

  const dispatchHebe = (msisdn, status) => {
    dispatch(actions.putHebeSvaCustomerInit(msisdn, SVA_HEBE_CUSTOMER_CODE, status))
  }

  const fetchEnableHebeSvaCustomer = msisdn => {
    dispatchHebe(msisdn, SVA_STATUS_ENABLED)
  }

  const fetchDisableHebeSvaCustomer = msisdn => {
    dispatchHebe(msisdn, SVA_STATUS_DISABLED)
  }

  const resetHebeSvaCustomer = () => {
    dispatch(actions.resetPutHebeSvaCustomer())
  }

  return {
    fetchEnableHebeSvaCustomer,
    fetchDisableHebeSvaCustomer,
    isHebeSvaCustomerSuccess,
    isHebeSvaCustomerError,
    isHebeSvaCustomerLoading,
    resetHebeSvaCustomer,
  }
}
