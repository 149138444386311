import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { push } from 'connected-react-router'

import { Formik } from 'formik'
import { Box } from '@material-ui/core'

import { Button, TabsContainer, FormikInput } from 'components/ui'
import { authService } from 'modules/Auth'
import { objectToQueryString } from 'modules/url'

import { getDocumentType, MSISDN } from 'modules/CustomerDocument'
import { selectQueryParams } from 'modules/Router'

const SearchCustomerContainerForm = ({
  tab,
  values,
  setFieldValue,
  handleSubmit,
  onChange,
  disabled,
}) => {
  useEffect(() => {
    // 26577367Q with active subscription (vista)
    // 72831104V + 80130397 with on fly order (orders)
    // 86684714Z + 931178535 with on fly order (tech-change)
    if (tab === 'vista') {
      setFieldValue('sfid', 'AE0001')
      setFieldValue('documentId', '15540415M')
    }
    if (tab === 'tech-change') {
      setFieldValue('sfid', 'AE0001_RPORT')

      // TODO: support this flow
      // setDocumentId('26577367Q')
      // setFixedNumber('919402899')

      setFieldValue('documentId', '30761970E')
      setFieldValue('fixedNumber', '950860157')
    }
    if (tab === 'orders') {
      setFieldValue('sfid', 'AE0001')
      setFieldValue('documentId', '72831104V')
      setFieldValue('orderId', '80130397')
    }
  }, [tab])

  useEffect(() => {
    onChange(values)
  }, [values])

  return (
    <>
      <div className="full-width flex align-space-between m-b-16">
        <Box mr="8px">
          <FormikInput label="SFID" name="sfid" trim />
        </Box>

        <Box mr="8px">
          <FormikInput label="NIF/NIE/MSISDN/CLI" name="documentId" trim uppercase />
        </Box>

        {tab === 'tech-change' && (
          <Box mr="8px">
            <FormikInput label="FIXED NUMBER" name="fixedNumber" trim />
          </Box>
        )}

        {tab === 'orders' && (
          <Box mr="8px">
            <FormikInput label="ORDER ID" name="orderId" trim />
          </Box>
        )}
      </div>

      <Button
        data-hook="launch"
        type="submit"
        onClick={handleSubmit}
        disabled={
          !(values.sfid && values.documentId && getDocumentType(values.documentId)) || disabled
        }>
        Launch
      </Button>
    </>
  )
}

SearchCustomerContainerForm.propTypes = {
  tab: PropTypes.string,
  values: PropTypes.object,
  setFieldValue: PropTypes.func,
  handleSubmit: PropTypes.func,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
}

export const SearchCustomerContainer = ({ className }) => {
  const dispatch = useDispatch()

  const tabsNames = ['vista', 'orders', 'tech-change']
  const urlQueryParams = useSelector(selectQueryParams)

  const [tabValue, setTabValue] = useState(
    urlQueryParams.tab ? tabsNames[+urlQueryParams.tab] : tabsNames[0],
  )
  const [targetURL, setTargetURL] = useState('')

  const location = useLocation()

  const params = {
    brand: 'yoigo',
    channel: 'newton',
  }

  function getVistaUrl({ documentId, sfid }, data, uuid) {
    const queryParams = new URLSearchParams(location.search)
    let basePath = queryParams.get('redirect') || '/vista/dashboard'
    if (documentId) {
      const documentType = getDocumentType(documentId.toUpperCase())
      basePath += `/documentType/${documentType}/documentId/${documentId.toUpperCase()}`
    }
    const queryString = objectToQueryString({
      ...data,
      sfid,
      uuid,
    })
    return `${basePath}?${queryString}`
  }

  function getTechChangeUrl({ documentId, fixedNumber, sfid }, data, uuid) {
    let documentType
    if (documentId) {
      documentType = getDocumentType(documentId.toUpperCase())
    }
    const queryString = objectToQueryString({
      ...data,
      documentId,
      documentType,
      fixedNumber,
      sfid,
      uuid,
    })

    return `/clients/change-services?${queryString}`
  }

  function getOrderstUrl({ documentId, sfid, orderId }, data, uuid) {
    let documentType
    if (documentId) {
      documentType = getDocumentType(documentId.toUpperCase())
    }
    const queryString = objectToQueryString({
      ...data,
      documentId,
      documentType,
      sfid,
      uuid,
      msisdn: documentType === MSISDN ? documentId : undefined,
    })

    return `/clients/orders/${orderId}/actions?${queryString}`
  }

  const urlGenerators = {
    vista: getVistaUrl,
    'tech-change': getTechChangeUrl,
    orders: getOrderstUrl,
  }

  async function generateURLS(values, data) {
    try {
      if (!values.sfid) return
      const response = await authService.generateUUID(values.sfid)
      if (response && response.uuid && values.documentId) {
        setTargetURL(urlGenerators[tabValue](values, data, response.uuid))
      }
    } catch (e) {
      console.warn('uuid:error', e)
    }
  }

  let tabsConfig = []

  tabsConfig = [
    ...tabsConfig,
    {
      value: 'vista',
      label: 'Vista 360',
    },
    {
      value: 'orders',
      label: 'Orders',
    },
    {
      value: 'tech-change',
      label: 'Tech-Change',
    },
  ]

  return (
    <Box
      component="section"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      className={className}>
      <TabsContainer
        className="m-b-24"
        tabsConfig={tabsConfig}
        onChange={(evt, index, value) => {
          setTabValue(value)
        }}
        queryParamName="tab"
      />

      <Formik
        initialValues={{ sfid: 'AE0001', documentId: '', fixedNumber: '', orderId: '' }}
        onSubmit={() => {
          dispatch(push(targetURL))
        }}>
        {props => (
          <SearchCustomerContainerForm
            tab={tabValue}
            onChange={values => {
              setTargetURL('')
              generateURLS(values, params)
            }}
            disabled={!targetURL}
            {...props}
          />
        )}
      </Formik>
    </Box>
  )
}

SearchCustomerContainer.propTypes = {
  className: PropTypes.string,
}
