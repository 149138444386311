import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import {
  Box,
  Select,
  MenuItem,
  FormControl,
  Button,
  Typography,
  Grid,
  CircularProgress,
} from '@material-ui/core'
import uuidv4 from 'uuid/v4'

import { map, find, get, isEmpty } from 'lodash'
import { makeStyles } from '@material-ui/styles'

import { ContainerTitle, CardRow, CardRowLabel, CardRowValue } from 'components'
import { filterPropertiesByFixedLine } from 'services/change-technology/selectors'
import { getZipCode } from 'services/change-technology'
import { helpers as helpersCustomer } from 'services/customer-new'
import { applyTax, getTax } from 'services/taxes'
import { Modal } from '../Modal'
import { OrderModal } from '../OrderModal'

const useStyles = makeStyles({
  'promo-grid': {
    border: '1px dashed #CCC',
    padding: '10px',
    margin: '10px',
    textAlign: 'center',
  },
  'modal-grid': {
    display: 'flex',
    alignItems: 'center',
    flexFlow: 'column wrap',
    margin: '10px',
  },
})

const NewSubscriptions = ({
  available,
  tariff,
  data,
  onMenuItemChange,
  handleUpdate,
  changeTechnologyJson,
  offer,
  productOrders,
  sendOrder,
  phoneNumber,
  index,
  newSubscription,
  onSelectChange,
  onOpenHandler,
  select,
  customer,
  enrichment,
  orderId,
  loadingOrder,
  showAppointment,
  isNebaTerritoryOwner,
}) => {
  const classes = useStyles()

  const [orderModal, setOrderModal] = useState(false)
  const [modal, setModal] = useState(false)
  const [orderInProgress, setOrderInProgress] = useState(false)

  useEffect(() => {
    if (available) {
      const dataIndex = data.findIndex(elem => elem.id === tariff.type)
      const item = find(data, { id: tariff.type })
      onMenuItemChange(dataIndex, item)
      handleUpdate(item.commercial_name)
    }
  }, [])

  const onClickContractHandler = () => {
    setOrderModal(false)
    setModal(true)
    setOrderInProgress(true)
  }

  const onSendOrder = () => {
    const lineTypeOrderJson = filterPropertiesByFixedLine(changeTechnologyJson, offer)
    if (lineTypeOrderJson.characteristics) {
      const segment = get(productOrders, 'customer.segment')
      lineTypeOrderJson.characteristics.push({ name: 'segment', value: segment })
    }
    sendOrder(lineTypeOrderJson)
    setOrderModal(true)
  }

  const handleClose = () => {
    setOrderModal(false)
    setModal(false)
    setOrderInProgress(false)
  }

  let item = {}
  let itemInfo = {}

  if (!isEmpty(offer)) {
    item = offer
    itemInfo = find(enrichment, { id: item.id }) || {}
  }

  let zipCode = ''

  if (!isEmpty(changeTechnologyJson)) {
    zipCode = getZipCode(changeTechnologyJson)
  }

  const tax = useSelector(state => getTax(state, zipCode))

  let modalComponent = <div />

  if (!loadingOrder && orderModal) {
    const identificationType = helpersCustomer.getIdentificationTypeByCustomer(customer)
    modalComponent = (
      <OrderModal
        orderId={orderId}
        showAppointment={isNebaTerritoryOwner === false && showAppointment}
        showDownloadResume={!!orderId && identificationType !== 'CIF'}
      />
    )
  }

  return (
    <Grid item xs={12} sm={6}>
      <ContainerTitle>SUSCRIPCIÓN NUEVA</ContainerTitle>
      {phoneNumber ? (
        <CardRow subscript>
          <CardRowLabel>Nuevo teléfono: </CardRowLabel>
          <CardRowValue>{phoneNumber}</CardRowValue>
        </CardRow>
      ) : null}
      {newSubscription ? (
        <div>
          <Box minWidth="120px">
            <FormControl style={{ width: '100%' }}>
              <Select
                value={select}
                onChange={onSelectChange}
                displayEmpty
                inputProps={{ name: 'select', id: 'tariff' }}
                data-hook="selectNewSubscriptions">
                <MenuItem value="" onMouseUp={() => onMenuItemChange(null)}>
                  <em>Seleccionar nueva tarifa</em>
                </MenuItem>
                {map(data, (dataItem, i) => (
                  <MenuItem
                    key={`${dataItem.id}${i}`}
                    value={dataItem.commercial_name}
                    onMouseUp={() => onMenuItemChange(i, dataItem)}>
                    {dataItem.commercial_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <Button
            variant="contained"
            color="primary"
            onClick={onOpenHandler}
            disabled={index === null}
            data-hook="Mostrar Detalles"
            className="m-t-8">
            Mostrar Detalles
          </Button>
        </div>
      ) : null}
      <Box justifyContent="flex-end" marginTop="10px">
        <Button
          variant="contained"
          color="primary"
          onClick={onClickContractHandler}
          disabled={index === null || orderInProgress}
          data-hook="Contratar">
          Contratar
        </Button>
      </Box>
      {offer ? (
        <Modal
          key={uuidv4()}
          orderData={changeTechnologyJson}
          handleClose={() => handleClose()}
          sendOrder={() => onSendOrder(data)}
          lineType={item.fixed_line_type}
          orderId={orderId}
          showAppointment={showAppointment}
          open={modal}
          title="Comprobar datos"
          data-hook="modalVerifyData"
          message={
            <Grid className={classes['modal-grid']}>
              {' '}
              <Typography variant="h6" color="primary">
                Cliente:
              </Typography>
              <Typography variant="body2">
                {`${customer.firstName} ${customer.midName} ${customer.lastName}`}
              </Typography>
              <Typography variant="h6" color="primary">
                Número de Identificación:
              </Typography>
              <Typography variant="body2">
                {customer.individualIdentification[0].identificationId}
              </Typography>
              <Typography variant="h6" color="primary">
                Tarifa Elegida:
              </Typography>
              <Typography variant="body2">{item.commercial_name}</Typography>
              <Typography variant="h6" color="primary">
                Precio Mensual:
              </Typography>
              <Typography variant="body2">{applyTax(tax, item.monthly_fee)}€ imp. inc.</Typography>
              <Grid className={classes['modal-grid']}>
                <div>
                  <Typography variant="h6" color="primary">
                    Permanencias:
                  </Typography>
                </div>
                <div>
                  {itemInfo.penalization ? (
                    <Grid key={index} className={classes['promo-grid']}>
                      <Typography variant="body2">{itemInfo.penalization.fixed}</Typography>
                      <Typography variant="body2">{itemInfo.penalization.mobile}</Typography>
                    </Grid>
                  ) : (
                    <Typography variant="body2">No hay información</Typography>
                  )}
                </div>
              </Grid>
              <Grid className={classes['modal-grid']}>
                <div>
                  <Typography variant="h6" color="primary">
                    Promociones:
                  </Typography>
                </div>
                <div>
                  {itemInfo.promotion ? (
                    <Grid key={index} className={classes['promo-grid']}>
                      <Typography variant="h6">{itemInfo.promotion.title}</Typography>
                      <Typography variant="body2">{itemInfo.promotion.desc}</Typography>
                    </Grid>
                  ) : (
                    <Typography variant="body2">No existe ninguna promocion aplicable</Typography>
                  )}
                </div>
                {loadingOrder && (
                  <Box marginTop="10px">
                    <CircularProgress />
                  </Box>
                )}
              </Grid>
            </Grid>
          }
          cancelBtn="Cancelar"
          disabledBtn={orderModal}
          orderButton="Asignar"
        />
      ) : null}
      {modalComponent}
    </Grid>
  )
}

NewSubscriptions.propTypes = {
  phoneNumber: PropTypes.string,
  index: PropTypes.number,
  newSubscription: PropTypes.bool,
  data: PropTypes.array,
  onSelectChange: PropTypes.func,
  onMenuItemChange: PropTypes.func,
  onOpenHandler: PropTypes.func,
  select: PropTypes.string,
  customer: PropTypes.object,
  tariff: PropTypes.object,
  offer: PropTypes.object,
  enrichment: PropTypes.array,
  orderId: PropTypes.string,
  loadingOrder: PropTypes.bool,
  available: PropTypes.bool,
  changeTechnologyJson: PropTypes.object,
  sendOrder: PropTypes.func,
  handleUpdate: PropTypes.func,
  showAppointment: PropTypes.bool,
  productOrders: PropTypes.object,
  isNebaTerritoryOwner: PropTypes.bool,
}

export default NewSubscriptions
