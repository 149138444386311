import { Switch, Redirect, useRouteMatch, useLocation } from 'react-router-dom'

import { AuthenticatedRoute } from 'modules/Auth'
import { StepsContainer } from './containers/StepsContainer'

export function Routes() {
  const match = useRouteMatch()
  const { search } = useLocation()

  return (
    <Switch>
      <AuthenticatedRoute
        isCustomer
        exact
        path={`${match.url}/:msisdn/:step`}
        component={StepsContainer}
        showAlert
      />

      <Redirect
        from={`${match.url}/:msisdn`}
        to={`${match.url}/:msisdn/device-type-selection${search}`}
      />
    </Switch>
  )
}
