export const FETCH_ORDER = 'OVID/ORDERS/FETCH_ORDER'
export const FETCH_ORDER_ONLY = 'OVID/ORDERS/FETCH_ORDER_ONLY'
export const FETCH_ORDER_SUCCESS = 'OVID/ORDERS/FETCH_ORDER_SUCCESS'
export const FETCH_ORDER_FAILED = 'OVID/ORDERS/FETCH_ORDER_FAILED'

export const FETCH_ADDITIONAL_LINES = 'OVID/ORDERS/FETCH_ADDITIONAL_LINES'
export const FETCH_ADDITIONAL_LINES_SUCCESS = 'OVID/ORDERS/FETCH_ADDITIONAL_LINES_SUCCESS'
export const FETCH_ADDITIONAL_LINES_FAILED = 'OVID/ORDERS/FETCH_ADDITIONAL_LINES_FAILED'

export const CANCEL_ORDER_INIT = 'OVID/SESSION/CANCEL_ORDER_INIT'
export const CANCEL_ORDER = 'OVID/SESSION/CANCEL_ORDER'
export const CANCELED_ORDER = 'OVID/SESSION/CANCELED_ORDER'
export const CANCEL_ORDER_ERROR = 'OVID/SESSION/CANCEL_ORDER_ERROR'

export const CANCEL_ORDERS = 'OVID/SESSION/CANCEL_ORDERS'
export const CANCEL_ORDERS_SUCCESS = 'OVID/SESSION/CANCEL_ORDERS_SUCCESS'
export const CANCEL_ORDERS_FAILED = 'OVID/SESSION/CANCEL_ORDERS_FAILED'
export const RESET_CANCEL_ORDERS = 'OVID/SESSION/RESET_CANCEL_ORDERS'

export const UPDATE_ORDER_INIT = 'OVID/SESSION/UPDATE_ORDER_INIT'
export const UPDATE_ORDER = 'OVID/SESSION/UPDATE_ORDER'
export const UPDATED_ORDER = 'OVID/SESSION/UPDATED_ORDER'
export const UPDATE_ORDER_FAILED = 'OVID/SESSION/UPDATE_ORDER_FAILED'
export const INCREMENT_STEP = 'INCREMENT/STEP'
export const RETAIN_ORDER = 'OVID/ORDERS/RETAIN_ORDER'
export const RETAIN_ORDER_SUCCESS = 'OVID/ORDERS/RETAIN_ORDER_SUCCESS'
export const RETAIN_ORDER_ERROR = 'OVID/ORDERS/RETAIN_ORDER_ERROR'

export const FETCH_PENALTY_ORDER = 'OVID/SESSION/FETCH_PENALTY_ORDER'
export const FETCH_PENALTY_ORDER_SUCCESS = 'OVID/SESSION/FETCH_PENALTY_ORDER_SUCCESS'
export const FETCH_PENALTY_ORDER_FAILED = 'OVID/SESSION/FETCH_PENALTY_ORDER_FAILED'

export const FETCH_ORDERS_CANCELLATION_INFO = 'OVID/SESSION/FETCH_ORDERS_CANCELLATION_INFO'
export const FETCH_ORDERS_CANCELLATION_INFO_SUCCESS =
  'OVID/SESSION/FETCH_ORDERS_CANCELLATION_INFO_SUCCESS'
export const FETCH_ORDERS_CANCELLATION_INFO_FAILED =
  'OVID/SESSION/FETCH_ORDERS_CANCELLATION_INFO_FAILED'

export const fetchOrderAction = orderId => ({
  type: FETCH_ORDER,
  payload: {
    orderId,
  },
})

export const fetchOrderOnlyAction = orderId => ({
  type: FETCH_ORDER_ONLY,
  payload: {
    orderId,
  },
})

export const fetchOrderSuccessAction = data => ({
  type: FETCH_ORDER_SUCCESS,
  payload: {
    ...data,
  },
})

export const fetchOrderFailedAction = error => ({
  type: FETCH_ORDER_FAILED,
  payload: { error },
})

export const fetchOrderAdditionalLinesAction = (docType, docNumber, orderId) => ({
  type: FETCH_ADDITIONAL_LINES,
  payload: { docType, docNumber, orderId },
})

export const fetchOrderAdditionalLinesSuccessAction = data => ({
  type: FETCH_ADDITIONAL_LINES_SUCCESS,
  payload: data,
})

export const fetchOrderAdditionalLinesFailedAction = error => ({
  type: FETCH_ADDITIONAL_LINES_FAILED,
  payload: error,
})

export const incrementStepAction = step => ({
  type: INCREMENT_STEP,
  payload: {
    stepModifiedOrder: step,
  },
})

export const cancelOrderInitAction = (orderId, data, notification) => ({
  type: CANCEL_ORDER_INIT,
  payload: {
    orderId,
    data,
    notification,
  },
})

export const cancelOrderAction = () => ({
  type: CANCEL_ORDER,
})

export const canceledOrderAction = () => ({
  type: CANCELED_ORDER,
})

export const cancelOrderErrorAction = error => ({
  type: CANCEL_ORDER_ERROR,
  payload: error,
})

export const cancelOrdersAction = (orders, data, notification) => ({
  type: CANCEL_ORDERS,
  payload: {
    orders,
    data,
    notification,
  },
})

export const cancelOrdersSuccessAction = ordersCanceled => ({
  type: CANCEL_ORDERS_SUCCESS,
  payload: {
    ordersCanceled,
  },
})

export const cancelOrdersFailedAction = (error, ordersCanceled) => ({
  type: CANCEL_ORDERS_FAILED,
  payload: { error, ordersCanceled },
})

export const resetCancelOrdersAction = () => ({
  type: RESET_CANCEL_ORDERS,
})

export const retainOrderAction = orderId => ({
  type: RETAIN_ORDER,
  payload: { orderId },
})

export const retainOrderSuccessAction = orderId => ({
  type: RETAIN_ORDER_SUCCESS,
  payload: { orderId },
})

export const retainOrderErrorAction = orderId => ({
  type: RETAIN_ORDER_ERROR,
  payload: { orderId },
})

export const updateOrderInitAction = orderId => ({
  type: UPDATE_ORDER_INIT,
  payload: {
    orderId,
  },
})

export const updateOrderAction = () => ({
  type: UPDATE_ORDER,
})

export const updatedOrderAction = data => ({
  type: UPDATED_ORDER,
  payload: { data },
})

export const updateOrderFailedAction = error => ({
  type: UPDATE_ORDER_FAILED,
  payload: { error },
})

export const fetchPenaltyOrderAction = orderId => ({
  type: FETCH_PENALTY_ORDER,
  payload: {
    orderId,
  },
})

export const fetchPenaltyOrderSuccessAction = data => ({
  type: FETCH_PENALTY_ORDER_SUCCESS,
  payload: {
    data,
  },
})

export const fetchPenaltyOrderFailedAction = error => ({
  type: FETCH_PENALTY_ORDER_FAILED,
  payload: {
    error,
  },
})

export const fetchOrdersCancellationInfoAction = (docNumber, docType) => ({
  type: FETCH_ORDERS_CANCELLATION_INFO,
  payload: {
    docNumber,
    docType,
  },
})

export const fetchOrdersCancellationInfoSuccessAction = payload => ({
  type: FETCH_ORDERS_CANCELLATION_INFO_SUCCESS,
  payload,
})

export const fetchOrdersCancellationInfoFailedAction = payload => ({
  type: FETCH_ORDERS_CANCELLATION_INFO_FAILED,
  payload,
})
