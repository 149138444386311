import { call, put, takeEvery, takeLatest, select } from 'redux-saga/effects'
import { merge, chain, get } from 'lodash'
import { logError, formatErrorMessage } from 'services/logging'
import { axiosFactory } from 'modules/axios'
import { selectSfid, selectCustomerId } from 'modules/Auth/store/selectors'
import { setBundlesHebeSva } from 'services/subscriptions/api'
import { selectIsExistingClientSaleFlow } from 'modules/NewClientSales/store/selectors/index'
import { getHomeGoRequestData } from 'services/cadence-flow/helpers'
import { selectTariffsApigeeById } from 'modules/tariffs/store-apigee/tariffs-apigee.selectors'
import { isFtthTariff } from 'modules/tariffs/store-apigee/index'
import { selectIsMobileOnly } from 'modules/Router/store/router.selectors'
import { getIBAN } from 'modules/orders/store/orders.selectors'
import { selectSvaHomeGoOnflyFlag } from 'services/feature-flag/selectors'
import {
  findCustomerSva,
  putDisabledCustomerSva,
  getCustomerSvasInfo,
  findMasServicesSva,
  putHomeGoSva,
  findSicorSva,
  getHomeGoSvasCadenceStatus,
  putHomeGoSvaWithoutScoring,
  cancelHomeGoSvaOrder,
} from './api'
import { constants, HOMEGO_SVA_SICOR_STATUS } from './constants'
import { actions } from './actions'
import { mapCustomerSvas, mapMasServicesToCustomerSvas } from './helpers'

export function* findCustomerSvaSaga({ payload: { documentId, documentType } }) {
  try {
    const customerSvaData = yield call(findCustomerSva, documentId, documentType)

    yield put(actions.findCustomerSvaSuccess(customerSvaData))
  } catch (e) {
    yield call(logError, { e: new Error(formatErrorMessage(e)), documentId })
    const error = axiosFactory.buildError(e)
    yield put(actions.findCustomerSvaFailed(error))
  }
}

export function* watchFindCustomerSvaSaga() {
  yield takeEvery(constants.FIND_CUSTOMER_SVA, findCustomerSvaSaga)
}

export function* putCustomerSvaSaga({ payload }) {
  const { documentId, documentType, msisdn } = payload
  const sfidCode = yield select(selectSfid)
  const customerId = yield select(selectCustomerId)

  try {
    const response = yield call(
      putDisabledCustomerSva,
      sfidCode,
      documentId,
      customerId,
      documentType,
      msisdn,
    )

    yield put(actions.putDisableCustomerSvaSuccess(response))
  } catch (e) {
    yield call(logError, { e: new Error(formatErrorMessage(e)), payload })
    const error = axiosFactory.buildError(e)
    yield put(actions.putDisableCustomerSvaFailed(error))
  }
}

export function* watchPutCustomerSvaSaga() {
  yield takeEvery(constants.DISABLE_CUSTOMER_SVA_INIT, putCustomerSvaSaga)
}
export function* putHebeSvaCustomerSaga({ payload }) {
  const { msisdn, service, status } = payload
  const customerId = yield select(selectCustomerId)

  try {
    const response = yield call(setBundlesHebeSva, msisdn, service, status, customerId)
    yield put(actions.putHebeSvaCustomerSuccess(response))
  } catch (e) {
    yield call(logError, { e: new Error(formatErrorMessage(e)), payload })
    const error = axiosFactory.buildError(e)
    yield put(actions.putHebeSvaCustomerFailed(error))
  }
}

export function* watchPutHebeSvaCustomerSaga() {
  yield takeEvery(constants.PUT_HEBE_SVA_CUSTOMER_INIT, putHebeSvaCustomerSaga)
}
export function* fetchCustomerSvasSaga({ payload: { accountId, msisdns } }) {
  try {
    if (msisdns) {
      const masServicesSvaData = yield call(findMasServicesSva, msisdns)

      yield put(actions.fetchCustomerSvasSuccess(mapMasServicesToCustomerSvas(masServicesSvaData)))
    } else {
      const customerSvasInfo = yield call(getCustomerSvasInfo, accountId)

      yield put(actions.fetchCustomerSvasSuccess(mapCustomerSvas(customerSvasInfo)))
    }
  } catch (e) {
    yield call(logError, { e: new Error(formatErrorMessage(e)), accountId })
    const error = axiosFactory.buildError(e)
    yield put(actions.fetchCustomerSvasFailed(error))
  }
}

export function* watchFetchCustomerSvasSaga() {
  yield takeEvery(constants.FETCH_CUSTOMER_SVAS, fetchCustomerSvasSaga)
}

export function* putHomeGoSaga({ payload }) {
  yield put(actions.purchaseAlarmStart())

  const sfidCode = yield select(selectSfid)
  const isSalesCarteraFlow = yield select(selectIsExistingClientSaleFlow)
  const tariff = yield selectTariffsApigeeById(payload.tariffId)
  const isMobileOnly = yield select(selectIsMobileOnly)
  const technology = isFtthTariff(tariff) ? 'FTTH' : 'ADSL'
  const ibanNumberOnFlyCustomer = yield select(getIBAN)

  try {
    const homeGoData = getHomeGoRequestData({
      ...payload,
      sfidCode,
      isSalesCarteraFlow,
      technology: !isMobileOnly ? technology : null,
      ibanNumberOnFlyCustomer,
    })

    const response = yield call(
      homeGoData.scoring.referenceNumber ? putHomeGoSvaWithoutScoring : putHomeGoSva,
      homeGoData,
    )

    if (response?.workflowId) {
      yield put(actions.purchaseAlarmSuccess(response))
    } else {
      yield put(actions.purchaseAlarmFailed(response))
    }
  } catch (e) {
    const error = axiosFactory.buildError(e)

    if (e.response?.data?.code) {
      error.errorCadence = e.response.data
    } else {
      const errorReg = /^\$\.([A-Z.a-z]*): is missing|is required/
      const responseData = e.response?.data
      const matchResponse = responseData.match && responseData.match(errorReg)

      if (matchResponse?.length > 1) {
        error.responseData = responseData
        error.errorMessage = `El campo '${matchResponse[1]}' es requerido y no está informado.`
      }
    }

    yield call(logError, new Error(formatErrorMessage(e)))
    yield put(actions.purchaseAlarmFailed(error))
  }
}

export function* watchPutHomeGoSales() {
  yield takeLatest(constants.PURCHASE_HOMEGO_START, putHomeGoSaga)
}

export function* getHomeGoCustomerSvasSaga({ payload: { customerId, msisdns } }) {
  try {
    const svaHomeGoAlarmFlag = yield select(selectSvaHomeGoOnflyFlag)

    const homeGoSvasResponses = yield call(() =>
      Promise.allSettled([
        findSicorSva(customerId),
        ...(svaHomeGoAlarmFlag ? [getHomeGoSvasCadenceStatus(msisdns)] : []),
      ]),
    )

    let [sicorSvaData, cadenceSvaStatus] = homeGoSvasResponses.map(res =>
      res.status === 'fulfilled' ? res.value : null,
    )
    if (sicorSvaData) {
      sicorSvaData = chain(sicorSvaData)
        .map(sicor => get(sicor, 'msisdn'))
        .uniq()
        .value()
        .reduce((accumulator, msisdnFiltered) => {
          const sicorFiltered = chain(sicorSvaData)
            .filter(
              sva =>
                get(sva, 'msisdn') === msisdnFiltered &&
                get(sva, 'workOrderStatus') !== HOMEGO_SVA_SICOR_STATUS.CANCELLED,
            )
            .reduce((acc, sicor) =>
              new Date(acc.startContractDate) > new Date(sicor.startContractDate) ? acc : sicor,
            )
            .value()

          if (sicorFiltered) {
            accumulator[sicorFiltered.msisdn] = sicorFiltered
          }

          return accumulator
        }, {})
    }

    if (cadenceSvaStatus) {
      cadenceSvaStatus = chain(cadenceSvaStatus).keyBy('msisdn').value()
    }

    const homeGoSvas = merge(sicorSvaData || {}, cadenceSvaStatus || {})

    yield put(actions.findHomeGoSvaSuccess(homeGoSvas))

    if (!sicorSvaData || !cadenceSvaStatus) {
      const [sicorError, cadenceError] = homeGoSvasResponses.map(res =>
        res.status === 'rejected' ? axiosFactory.buildError(res.reason) : null,
      )

      yield put(
        actions.findHomeGoSvaFailed({
          sicor: sicorError,
          cadence: cadenceError,
        }),
      )
    }
  } catch (err) {
    console.warn(err)
  }
}

export function* watchGetHomeGoCustomerSvasSaga() {
  yield takeLatest(constants.FIND_HOMEGO_SVA, getHomeGoCustomerSvasSaga)
}

export function* cancelHomeGoSvaOrderSaga({ payload: { msisdn, workflowId } }) {
  try {
    const sfid = yield select(selectSfid)

    const cancelResponse = yield call(cancelHomeGoSvaOrder, msisdn, workflowId, sfid)

    if (!cancelResponse.cancelled) {
      throw cancelResponse
    }

    yield put(actions.cancelHomeGoSvaOrderSuccess())
  } catch (e) {
    const error = { responseData: e.response?.data }

    if (e.response?.data) {
      const errorReg = /^\$\.([A-Z.a-z]*): is missing|is required/
      const responseData = e.response?.data
      const matchResponse = responseData.match && responseData.match(errorReg)

      if (matchResponse?.length > 1) {
        error.message = `El campo '${matchResponse[1]}' es requerido y no está informado.`
      }
    }

    yield call(logError, { e: new Error(formatErrorMessage(e)), workflowId })
    yield put(actions.cancelHomeGoSvaOrderFailed(error || e))
  }
}

export function* watchCancelHomeGoSvaOrderSaga() {
  yield takeLatest(constants.CANCEL_HOMEGO_SVA_ORDER, cancelHomeGoSvaOrderSaga)
}
