import * as constants from './constants'

export const initialState = {
  tickets: {
    loading: false,
    data: [],
    error: null,
  },
}

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case constants.FIND_TICKETS_ATTEMPTED: {
      return {
        ...state,
        tickets: {
          loading: true,
        },
      }
    }

    case constants.FIND_TICKETS_SUCCEEDED: {
      return {
        ...state,
        tickets: {
          loading: false,
          data: payload.data,
        },
      }
    }

    case constants.FIND_TICKETS_FAILED: {
      return {
        ...state,
        tickets: {
          ...state.tickets,
          loading: false,
          error: { ...payload },
        },
      }
    }

    default:
      return state
  }
}

export default reducer
