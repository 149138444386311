import axios from 'axios'
import { axiosJWT } from 'modules/axios'

import { APP_CONFIG } from 'services/app-config'

const BASE_URL = `${APP_CONFIG.sales_cluster}/v1/fraud-scoring`

function fetchClientIpAddress() {
  return axiosJWT({
    method: 'GET',
    url: `${BASE_URL}/ipAddress`,
  })
}

function fetchClientIpAddressFromExternalApi() {
  return axios.get('https://ipapi.co/json', {
    transformRequest: (data, headers) => {
      Object.keys(headers.common).forEach(header => {
        if (header === 'Accept') {
          return
        }

        delete headers.common[header]
      })
    },
  })
}

export const fraudService = {
  fetchClientIpAddress,
  fetchClientIpAddressFromExternalApi,
}
