import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { OfferFooter } from 'components/ui'
import { isConvergentSubscription, getMsisdn } from 'services/subscriptions'

import { is2pSubscription, getSubscriptionState } from '../selectors'

import UnsubscribeModal from './UnsubscribeModal'
import { useUpdateSubscriptionHandlers } from '../hooks/useUpdateSubscriptionHandlers'

function SubscriptionCardFooter({ subscription, onModalOpen }) {
  const allowUnsubscribe = useSelector(state => !is2pSubscription(state, subscription))
  const subscriptionCardState = useSelector(state =>
    getSubscriptionState(state, getMsisdn(subscription)),
  )

  const { onUpdateSubscription, onClearSubscription } = useUpdateSubscriptionHandlers()

  const isConvergent = isConvergentSubscription(subscription)

  return (
    <OfferFooter
      allowUnsubscribe={allowUnsubscribe}
      unsubscribed={subscriptionCardState.unsubscribed}
      isConvergent={isConvergent}
      onCancel={() => onUpdateSubscription({ unsubscribed: false })}
      onUnsubscribe={() =>
        onModalOpen(
          UnsubscribeModal,
          {
            subscription,
            onUpdateSubscription,
            onConfirm: data => {
              onClearSubscription()
              onUpdateSubscription(data)
            },
          },
          'Dar de baja',
        )
      }
    />
  )
}

SubscriptionCardFooter.propTypes = {
  subscription: PropTypes.object,
  onModalOpen: PropTypes.func.isRequired,
}

export default SubscriptionCardFooter
