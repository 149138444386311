import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useFormikContext } from 'formik'

import { emailRegex } from 'utils'
import {
  REQUIRED_FIELD_TEXT,
  ERROR_DATA_TEXT,
  UNEXPECTED_ERROR,
  EMAIL_NOT_AVAILABLE,
} from 'modules/SharedSales/constants/validations'
import { fetchSVAExists, fetchSVABlackList } from 'modules/SVAs'

import { setRequestLoading } from '../../store'

export function useAgileTvValidation() {
  const [prevValue, setPrevValue] = useState('')
  const [prevState, setPrevState] = useState()
  const [hasMsError, setMsError] = useState()
  const { setStatus, status } = useFormikContext()

  const dispatch = useDispatch()

  function setErrorStatus(validation, field) {
    setStatus({ ...status, [field]: validation })
  }

  function setAndReturnState(state, isMsError, field) {
    setMsError(isMsError)
    setPrevState(state)
    setErrorStatus(state, field)
    dispatch(setRequestLoading({ loading: false }))

    return state
  }

  async function validate(value, field) {
    setMsError(false)
    dispatch(setRequestLoading({ loading: true }))
    return fetchSVABlackList({ searchType: 'email', searchValue: value })
      .then(() => {
        return setAndReturnState(EMAIL_NOT_AVAILABLE, true, field)
      })
      .catch(err => {
        if (err?.response?.status !== 404) return setAndReturnState(UNEXPECTED_ERROR, true, field)
        return fetchSVAExists({ email: value })
          .then(() => {
            return setAndReturnState(undefined, false, field)
          })
          .catch((errSva) => {
            if (errSva?.response?.status === 400)
              return setAndReturnState(EMAIL_NOT_AVAILABLE, true, field)
            return setAndReturnState(UNEXPECTED_ERROR, true, field)
          })
      })
  }

  async function validateAgileTvEmail(value, field) {
    const trimmedValue = value?.trim()
    setPrevValue(trimmedValue)

    if (!trimmedValue) return setAndReturnState(REQUIRED_FIELD_TEXT, false, field)

    if (trimmedValue === prevValue) return prevState

    if (emailRegex.test(trimmedValue)) {
      const validation = await validate(trimmedValue, field)
      return validation
    }
    return setAndReturnState(ERROR_DATA_TEXT, false, field)
  }

  function resetValidateAgileTvEmail(field) {
    setAndReturnState(null, false, field)
  }

  return {
    validateAgileTvEmail,
    resetValidateAgileTvEmail,
    hasMsError,
    error: prevState,
  }
}
