import { connect } from 'react-redux'
import { get } from 'lodash'
import { selectSfid } from 'modules/Auth'
import { selectQueryParam } from 'modules/Router'
import { actions as actionsChangeTechnology } from 'services/change-technology'
import { getAddress, getCustomerInfo } from 'services/global-selectors/selectors'

import {
  selectTariffApigeeOneTariff,
  selectTariffApigeeErrorCode,
  selectTariffApigeeIsLoading,
  findTariffsAction,
  selectTariffsApigeeDataTariffs,
} from 'modules/tariffs/store-apigee'

import {
  actions as actionsTerritCompat,
  selectors as selectorsTerritCompat,
} from 'services/territory-compatibility'
import { getSubscriptionByCurrentProductId } from 'services/subscriptions/selectors'
import { isAdslOrder } from 'modules/orders'
import { selectors as selectorsOrderOnFly } from 'services/order-on-fly'
import { selectors as selectorsCheckOrderCTOnFly } from 'services/check-order-ct-on-fly'
import { selectors as selectorsCustomer } from 'services/customer-new'
import { selectOrderTerritoryOwner, selectErrorMessage } from 'services/product-orders'
import { getConnectionType } from 'services/subscriptions/helpers'
import { actions as actionsOffers } from 'services/offers'
import {
  selectNebaEnabledFlag,
  selectInstallations360,
  selectCoverageV1,
} from 'services/feature-flag/selectors'
import {
  selectIsLoadingInstallations,
  selectInstaller,
  selectConnectionType,
  fetchInstallationDirection,
} from 'modules/InstallationsCare'
import { modelSubscriptionProduct } from '../../helpers/formats.helper'
import { ChangeTechnology } from '../../components/ChangeTechology'
import { getSubscriptionSagaAction, selectorInitialStep } from './get-subscription.saga'

const mapDispatchToProps = dispatch => ({
  getSubscriptionData: payload => dispatch(getSubscriptionSagaAction(payload)),
  findOffers: payload => dispatch(actionsOffers.findOffers(payload)),
  findTariffs: payload => dispatch(findTariffsAction(payload)),
  getTerritCompat: payload => dispatch(actionsTerritCompat.getTerritCompatInit(payload)),
  sendOrder: changeTechnologyData => {
    dispatch(actionsChangeTechnology.createChangeTechnology({ changeTechnologyData }))
    dispatch(actionsChangeTechnology.saveOperation({ changeTechnologyData }))
  },
  fetchInstallationDirection: data => dispatch(fetchInstallationDirection(data)),
})
const mapStateToProps = state => {
  const customerState = get(state.productOrders, 'data', {})
  const subscription = getSubscriptionByCurrentProductId(state)
  const territoryCompatibility = selectorsTerritCompat.getTerritCompat(state)
  const FFlagNeba = selectNebaEnabledFlag(state)
  const sfid = selectSfid(state)
  const FFlagInstaller = selectInstallations360(state)
  const coverageV1 = selectCoverageV1(state)

  return {
    sfid,
    fixedNumber: selectQueryParam('fixedNumber')(state),
    customerId: selectQueryParam('documentId')(state),
    customerIdType: selectQueryParam('documentType')(state),
    customer: customerState,
    tariffs: selectTariffsApigeeDataTariffs(state),
    tariffsError: selectTariffApigeeErrorCode(state),
    orderError: selectErrorMessage(state),
    tariffsLoading: selectTariffApigeeIsLoading(state),
    productCoverage: get(state, 'productCoverage'),
    initialOrder: get(state, 'productOrders.data.id'),
    orderId: get(state, 'changeTechnology.data.orderId'),
    loadingOrder: get(state, 'changeTechnology.loading'),
    enrichment: get(state, 'enrichment.data'),
    productProvisioning: get(state, 'productProvisioning'),
    productOffers: get(state, 'productOffers'),
    coverage: coverageV1
      ? get(state.coverage, 'data.technology', [])
      : get(state.coverage, 'data[0].technology', []),
    coverageLoading: get(state.coverage, 'loading'),
    coverageError: get(state.coverage, 'error_code'),
    coverageData: coverageV1 ? get(state.coverage, 'data', []) : get(state.coverage, 'data[0]', []),
    customerInfo: getCustomerInfo('InstallationAddressFixedLine')(state),
    installationAddress: getAddress('InstallationAddressFixedLine')(state),
    isAdslOrder: isAdslOrder(get(customerState, 'customer')),
    productOrders: get(state, 'productOrders.data', {}),
    tokenCoverage: get(state, 'coverage.token'),
    customerProd: get(state, 'customerNew.data.products', []),
    orderCTOnFly: selectorsCheckOrderCTOnFly.getOrderCTOnFly(state),
    orderOnFly: selectorsOrderOnFly.orderOnFlyData(state),
    loadingInitialStep: selectorInitialStep(state),
    customerNew: selectorsCustomer.getCustomer(state),
    tariffById: selectTariffApigeeOneTariff(get(subscription, 'type'))(state),
    customerWithProductUpdated: modelSubscriptionProduct(customerState, subscription),
    descriptionTariffSubscription: get(subscription, 'description', ''),
    segment: selectorsCustomer.getSegment(state),
    territoryCompatible: get(territoryCompatibility, 'destinationConnectionType', null),
    territoryOwnerOrder: selectOrderTerritoryOwner(state),
    connectionType: get(getConnectionType(subscription), 'value'),
    isFeatNebaEnabled: FFlagNeba,
    isLoadingInstallations: selectIsLoadingInstallations(state),
    installer: selectInstaller(state),
    installConnectionType: selectConnectionType(state),
    FFlagInstaller,
    coverageV1,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangeTechnology)
