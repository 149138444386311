import { isNil, get } from 'lodash'
import { call, put, takeLatest, select } from 'redux-saga/effects'
import jwt_decode from 'jwt-decode'

import * as featureFlagSvc from 'services/feature-flag'

import { getCampaignArea } from 'modules/vista-360/helpers/helpers'
import { selectProfile } from 'modules/Permissions/store/permissions-selectors'
import { isProSohoProfile } from 'modules/Permissions/profiles'
import { actionTypes , AGENTS } from 'modules/typifications/constants'
import { getCampaignByEmail } from 'modules/typifications/store/apis/registerTypifications.api'
import { selectEnableEmailIframeTypification } from 'services/feature-flag/selectors'
import { addDataTypificationsSuccess, addDataTypificationsFailed } from '../actions'
import { isDataTokenCorrect, authorizationTokenHeader, hasCampaignEmailError } from '../../helpers'

export function* addDataTypificationsInit({ payload }) {
  const flag = yield select(selectEnableEmailIframeTypification)
  const { token } = payload
  let decoded = jwt_decode(token)
  const isSecurizedToken = Object.keys(decoded).includes('aud')
  let campaignByEmail

  if (isSecurizedToken) {
    decoded = decoded.business
    const { sfid } = decoded
    authorizationTokenHeader(token, sfid)
    yield put(featureFlagSvc.actions.getFlags())
  }
   
  try {
    const dataCodeError = yield call(isDataTokenCorrect, decoded, isSecurizedToken)

    if (isNil(dataCodeError)) {
      const { sfid, area, channel } = decoded
      let campaignArea = area

      if (isNil(campaignArea)) {
        if (isProSohoProfile(sfid?.toUpperCase())) {
          campaignArea = 'NEGOCIO'
        } else {
          const agentProfile = yield call(selectProfile, sfid)
          campaignArea = yield call(getCampaignArea, agentProfile, sfid, false)
        }
      }

      if(flag && channel === AGENTS) {
        const responseCampaignByEmail = yield call(getCampaignByEmail, sfid)
        campaignByEmail = get(responseCampaignByEmail, 'campaign.name')
      }

      yield put(addDataTypificationsSuccess({ ...decoded, area: campaignArea, campaignByEmail, isSecurizedToken }))
    } else {
      yield put(addDataTypificationsFailed(dataCodeError))
    }
  } catch (e) {
    const error = yield call(hasCampaignEmailError)
    yield put(addDataTypificationsSuccess({ ...decoded, isSecurizedToken, error }))
    console.warn(e)
  }
}

export function* watchDataTypficationSagas() {
  yield takeLatest(actionTypes.DATA_TYPIFICATIONS_INIT, addDataTypificationsInit)
}
