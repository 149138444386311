import {
  REGISTER_OFFER_ATTEMPTED,
  REGISTER_OFFER_SUCCESS,
  REGISTER_OFFER_ERROR,
} from './activity-register.actions'

export const initialState = {
  offers: {},
}

export function reducer(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case REGISTER_OFFER_ATTEMPTED: {
      const { msisdn } = payload
      return {
        ...state,
        offers: {
          ...state.offers,
          [msisdn]: {
            ...state.offers[msisdn],
            loading: true,
            error: null,
          },
        },
      }
    }

    case REGISTER_OFFER_SUCCESS: {
      const { msisdn } = payload
      return {
        ...state,
        offers: {
          ...state.offers,
          [msisdn]: {
            ...state.offers[msisdn],
            loading: false,
          },
        },
      }
    }

    case REGISTER_OFFER_ERROR: {
      const { msisdn, error } = payload
      return {
        ...state,
        offers: {
          ...state.offer,
          [msisdn]: {
            ...state.offers[msisdn],
            loading: false,
            error,
          },
        },
      }
    }

    default:
      return state
  }
}
