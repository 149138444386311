import { isNil } from 'lodash'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { ModalActions, Button, AlertAdvice } from 'components/ui'

import { validateActiveDiscount } from 'services/subscriptions'
import { SVA_HEBE_DISCOUNT_CODE } from 'modules/offers-configuration/constants'
import { parseBonusId } from '../helpers'
import DiscountAdvice from './DiscountAdvice'
import BonusAdvice from './BonusAdvice'
import DiscountsAndBonusesSelector from './DiscountsAndBonusesSelector'

const DiscountsWrapper = styled.div`
  margin: 11px 0 11px;
  line-height: 1.5;
`

export const DiscountsAndBonusesSelected = styled(
  ({
    compatibleCurrentDiscounts,
    onDiscountSelect,
    onDiscountPreSelect,
    hasIncompatibilities,
    selectedDiscounts,
    currentBonuses,
    selectedBonuses,
    onDiscountCancel,
    isOpen,
    type,
    openPopover,
    closePopover,
    onBonusSelect,
    onBonusCancel,
    subscription,
    canAddBonus,
    canAddDiscount,
    newPaymentType,
    onConfirm,
    forceShowConfirm,
    isFixedReduction,
    infiniteTariff,
    sinfin30GbTariff,
    newTariffId,
    currentTariff,
    className,
    isCompatibleTariffWithSva,
    hebeDiscountIncompatibility,
    disabledButtonConfirm,
  }) => {
    const noDiscountsNorBonuses =
      [].concat(compatibleCurrentDiscounts, selectedDiscounts, currentBonuses, selectedBonuses)
        .length === 0

    const subscriptionWithHebeDiscount = validateActiveDiscount(
      subscription,
      SVA_HEBE_DISCOUNT_CODE,
    )

    const tariffIncompatibleWithSvaHebe =
      !isNil(isCompatibleTariffWithSva) && !isCompatibleTariffWithSva

    return (
      <DiscountsWrapper className={className}>
        <p className="discounts-title">Descuentos o promociones activos:</p>
        <ul>
          {noDiscountsNorBonuses ? (
            <li>
              <strong>- No tiene descuentos ni bonos activos</strong>
            </li>
          ) : (
            <>
              {compatibleCurrentDiscounts.map(d => (
                <DiscountAdvice
                  key={d.discount_plan.rule_id}
                  value={d.subscription_discount.amount}
                  name={d.discount_plan.name}
                  unit={d.discount_plan.amount_unit}
                />
              ))}

              {selectedDiscounts.map(d => (
                <DiscountAdvice
                  key={d.discountCode}
                  discountCode={d.discountCode}
                  monthPermanency={d.monthPermanency}
                  value={d.value}
                  unit={d.unit}
                  duration={d.duration}
                  permanency={d.permanency}
                  onDelete={() => onDiscountCancel(d)}
                  onUpdate={() => openPopover('discount')}
                />
              ))}

              {subscriptionWithHebeDiscount && tariffIncompatibleWithSvaHebe && (
                <AlertAdvice
                  message="¡Ojo, esta Tarifa es incompatible con el SVA HEBE!.
                Si continúas con el cambio de Tarifa, perderá el Descuento HEBE."
                />
              )}

              {subscriptionWithHebeDiscount &&
                !tariffIncompatibleWithSvaHebe &&
                !!hebeDiscountIncompatibility && (
                  <AlertAdvice message="¡Ojo, esta Tarifa es incompatible con el descuento HEBE!" />
                )}

              {currentBonuses.map((b, i) => (
                <BonusAdvice
                  key={`current-bonus-${b.id}-${i.toString()}`}
                  amount={b.value}
                  validTo={b.valid_to}
                />
              ))}
              {selectedBonuses.map(b => (
                <BonusAdvice
                  key={`selected-bonus-${b.id}`}
                  amount={b.attributes.mobile_data_amount_mb}
                  {...parseBonusId(b.id)}
                  onDelete={() => onBonusCancel(b)}
                  onUpdate={() => openPopover('bonus')}
                />
              ))}
            </>
          )}
        </ul>
        <DiscountsAndBonusesSelector
          onDiscountSelect={onDiscountSelect}
          onBonusSelect={onBonusSelect}
          onDiscountPreSelect={onDiscountPreSelect}
          hasIncompatibilities={hasIncompatibilities}
          subscription={subscription}
          canAddBonus={canAddBonus}
          canAddDiscount={canAddDiscount}
          newPaymentType={newPaymentType}
          isOpen={isOpen}
          type={type}
          openPopover={openPopover}
          closePopover={closePopover}
          selectedDiscounts={selectedDiscounts}
          isFixedReduction={isFixedReduction}
          infiniteTariff={infiniteTariff}
          sinfin30GbTariff={sinfin30GbTariff}
          newTariffId={newTariffId}
          currentTariff={currentTariff}
        />
        {(selectedDiscounts.length > 0 || selectedBonuses.length > 0 || forceShowConfirm) && (
          <ModalActions>
            <Button
              data-hook="action-confirm"
              disabled={disabledButtonConfirm}
              onClick={() => {
                onConfirm({
                  confirmedDiscounts: selectedDiscounts,
                  confirmedBonuses: selectedBonuses,
                  compatibleTariffWithSva: isCompatibleTariffWithSva,
                  hebeDiscountIncompatibility,
                })
              }}>
              Confirmar
            </Button>
          </ModalActions>
        )}
      </DiscountsWrapper>
    )
  },
)`
  > .discounts-title {
    margin-bottom: 12px;
  }
  & .advice-wrapper:first-child {
    margin-top: 0;
  }
  & .advice-wrapper {
    margin-top: 16px;
  }
`

DiscountsAndBonusesSelected.propTypes = {
  selectedDiscounts: PropTypes.array,
  compatibleCurrentDiscounts: PropTypes.array,
  selectedBonuses: PropTypes.array,
  currentBonuses: PropTypes.array,
  onDiscountCancel: PropTypes.func,
  onBonusCancel: PropTypes.func,
  onDiscountSelect: PropTypes.func,
  onDiscountPreSelect: PropTypes.func,
  hasIncompatibilities: PropTypes.bool,
  onBonusSelect: PropTypes.func,
  subscription: PropTypes.object.isRequired,
  canAddBonus: PropTypes.bool,
  canAddDiscount: PropTypes.bool,
  newPaymentType: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  forceShowConfirm: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  openPopover: PropTypes.func.isRequired,
  closePopover: PropTypes.func.isRequired,
  isFixedReduction: PropTypes.bool,
  newTariffId: PropTypes.string,
  currentTariff: PropTypes.object,
  infiniteTariff: PropTypes.bool,
  sinfin30GbTariff: PropTypes.bool,
  isCompatibleTariffWithSva: PropTypes.bool,
  hebeDiscountIncompatibility: PropTypes.bool,
  disabledButtonConfirm: PropTypes.bool,
}

DiscountsAndBonusesSelected.defaultProp = {
  newPaymentType: '',
  selectedDiscounts: [],
  compatibleCurrentDiscounts: [],
  selectedBonuses: [],
  currentBonuses: [],
  forceShowConfirm: false,
}
