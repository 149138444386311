import { get, flatten } from 'lodash'
import { createSelector } from 'reselect'
import { selectors as customerSelector } from 'services/customer-360'
import { KEY_PHONE } from './constants'

export const getContactPhonesFiltered = createSelector(
  customerSelector.getContactPhones,
  // TODO: sort phones by type, 'mobile' most important than 'landline'
  phones => phones.filter(({ type }) => type === KEY_PHONE),
)

const sortBykey = key => data =>
  data.sort((a, b) => {
    if (a[key] < b[key]) {
      return -1
    }
    if (a[key] > b[key]) {
      return 1
    }
    return 0
  })

export const isLoadingCategories = state => get(state, 'ticketing.create.categories.loading', false)
export const isLoadingSubcategories = state =>
  get(state, 'ticketing.create.subCategories.loading', false)
export const isCaseOpened = state => get(state, `ticketing.create.opened`, false)
export const getErrorCode = state => get(state, 'ticketing.create.error.httpCode')
export const getErrorMsg = state => get(state, 'ticketing.create.error.info')
export const _getCategories = state => get(state, 'ticketing.create.categories.data', [])
export const getCategories = createSelector(
  _getCategories,
  sortBykey('name'),
)
export const getCategoriesErrorMsg = state => get(state, 'ticketing.create.categories.error.info')
export const getCategoriesErrorCode = state =>
  get(state, 'ticketing.create.categories.error.httpCode')
export const _getSubcategories = state => get(state, 'ticketing.create.subCategories.data', [])
export const getSubcategories = createSelector(
  _getSubcategories,
  sortBykey('name'),
)
export const getSubcategoriesErrorMsg = state =>
  get(state, 'ticketing.create.subCategories.error.info')
export const getSubcategoriesErrorCode = state =>
  get(state, 'ticketing.create.subCategories.error.httpCode')

export const isLoadingTicketTypes = state =>
  get(state, 'ticketing.create.ticketTypes.loading', false)
export const getTicketTypes = state => get(state, 'ticketing.create.ticketTypes.data')
export const getTicketTypesErrorMsg = state => get(state, 'ticketing.create.ticketTypes.error.info')
export const getTicketTypesErrorCode = state =>
  get(state, 'ticketing.create.ticketTypes.error.httpCode')

export const getTicketFormDataAllSteps = state => get(state, 'ticketing.create.ticketDataSteps', [])
export const getTicketFormDataByStep = (state, step) =>
  get(state, `ticketing.create.ticketDataSteps[${step}]`, [])
export const getTicketFormData = createSelector(
  getTicketFormDataAllSteps,
  steps => flatten(steps).reduce((acc, step) => ({ ...acc, ...step }), {}),
)

export const isCreatingTicket = state =>
  get(state, 'ticketing.create.ticketCreation.loading', false)
export const getCreationTicketResponse = state => get(state, 'ticketing.create.ticketCreation.data')
export const getCreationTicketError = state => get(state, 'ticketing.create.ticketCreation.error')
export const getCreationTicketErrorMsg = state =>
  get(state, 'ticketing.create.ticketCreation.error.info')
export const getCreationTicketErrorCode = state =>
  get(state, 'ticketing.create.ticketCreation.error.httpCode')

export const getTickets = state => get(state, 'ticketing.view.tickets.data')
export const getTicketsErrorMsg = state => get(state, 'ticketing.view.tickets.error.info')
export const getTicketsErrorCode = state => get(state, 'ticketing.view.tickets.error.httpCode')
export const getLoadingTickets = state => get(state, 'ticketing.view.tickets.loading', false)
