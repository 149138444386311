import { put, takeLatest, select, call, all } from 'redux-saga/effects'
import { sendTransactionComplete } from 'modules/Analytics/redux/analytics.actions'
import { push } from 'connected-react-router'
import { selectInstallsMM } from 'modules/Installations'
import { selectIsFtth } from 'modules/Coverage'

import { SVA_HOMEGO_CODE, SVA_SMARTHOME_CODE } from 'modules/offers-configuration/constants'
import { putOrderDiscountsStart } from 'services/order-discounts/actions'
import { callProductOrdersByIdSaga, selectOrder } from 'services/product-orders'
import { putCadenceAlarmSalesStart } from 'services/cadence-flow/actions'
import { selectIsCustomerResidential } from 'services/customer-360/selectors'
import { RESIDENTIAL, PRO } from 'modules/SharedSales/constants'
import { putHomeGoSvaStart } from 'services/customer-sva/actions'

import { get } from 'lodash'
import {
  selectIsConvergent,
  selectIs2p,
  selectIsMobileOnly,
} from 'modules/Router/store/router.selectors'
import {
  selectCadenceAlarmsInvoicingAddressSales,
  selectCadenceAlarmsSendAddressSales,
} from 'modules/NewClientSales/store/selectors/cadence.selectors'

import { selectSvaSmartHomeFlag } from 'services/feature-flag/selectors'
import { orderActionTypes, notifyD2DOrderFinishedAction } from '../actions'

import {
  selectTransactionForAnalytics,
  selectIsD2D,
  selectIsElFijo,
  selectSecondHomeDiscount,
  selectOrderCheckingId,
  selectedLandlineProSVAs,
  selectLandlineOffer,
  selectContractId,
  selectCheckoutOrderMainLineInfo,
  selectPurchaseHomeItSvaSalesData,
} from '../selectors'
import { selectIsFiberInstallationRequired } from '../selectors/NewClientSales.selectors'

function* sendOrdersDiscount(linesDiscount) {
  const { lineNumber } = linesDiscount
  let orderId

  if (lineNumber) {
    yield call(callProductOrdersByIdSaga, lineNumber)
    const orderProductData = yield select(selectOrder)
    orderId = orderProductData.id
  } else {
    orderId = yield select(selectOrderCheckingId)
  }

  yield put(putOrderDiscountsStart(orderId, linesDiscount))
}

function formatSmartHomeAddress(data) {
  const { addressType, name } = data

  const nameAddressStreetType =
    !addressType || name.includes(addressType) ? name : `${addressType} ${name}`

  return {
    ...data,
    name: nameAddressStreetType,
  }
}

function* sendCadenceAlarmsSales(landLineOffer, proSVAs) {
  const orderId = yield select(selectOrderCheckingId)
  const contractId = yield select(selectContractId)
  const checkoutOrderInfo = yield select(selectCheckoutOrderMainLineInfo)

  const isCustomerResidential = yield select(selectIsCustomerResidential)
  const invoicingAddress = yield select(selectCadenceAlarmsInvoicingAddressSales)
  const sendAddress = yield select(selectCadenceAlarmsSendAddressSales)

  const subscriptionType = get(landLineOffer, 'tariff.psId', '')
  const msisdn = get(checkoutOrderInfo, 'characteristics.ch_fixed_number', '')

  const isSvaSmartHomeFlag = yield select(selectSvaSmartHomeFlag)
  const isConvergent = yield select(selectIsConvergent)
  const is2p = yield select(selectIs2p)

  for (const proSVA of proSVAs) {
    if (isSvaSmartHomeFlag && (isConvergent || is2p) && proSVA.psId === SVA_SMARTHOME_CODE) {
      yield put(
        putCadenceAlarmSalesStart(
          contractId,
          orderId,
          msisdn,
          subscriptionType,
          formatSmartHomeAddress(invoicingAddress),
          formatSmartHomeAddress(sendAddress),
          isCustomerResidential ? RESIDENTIAL : PRO,
        ),
      )
    }

    if (proSVA.psId.includes(SVA_HOMEGO_CODE)) {
      const homeItData = yield select(selectPurchaseHomeItSvaSalesData)

      yield put(putHomeGoSvaStart(homeItData))
    }
  }
}

export function* orderSuccededSaga() {
  const transactionForAnalytics = yield select(selectTransactionForAnalytics)
  yield put(sendTransactionComplete(transactionForAnalytics))

  const isD2D = yield select(selectIsD2D)
  const isMobileOnly = yield select(selectIsMobileOnly)
  const isFTTH = yield select(selectIsFtth)
  const installsMM = yield select(selectInstallsMM)
  const isFiberInstallationRequired = yield select(selectIsFiberInstallationRequired)

  const isElFijo = yield select(selectIsElFijo)

  const landLineOffer = yield select(selectLandlineOffer)

  const proSVAs = yield select(selectedLandlineProSVAs)

  const secondHomeDiscounts = yield select(selectSecondHomeDiscount)

  if (secondHomeDiscounts.length > 0) {
    yield all(secondHomeDiscounts.map(linesDiscount => sendOrdersDiscount(linesDiscount)))
  }

  yield sendCadenceAlarmsSales(landLineOffer, proSVAs)

  // Mobile only
  if (isMobileOnly || isElFijo) {
    if (isD2D) {
      // If is D2D ignore next steps
      yield put(notifyD2DOrderFinishedAction())
    } else {
      yield put(push(`next-steps${window.location.search}`))
    }

    return
  }

  // Convergent
  const shouldShowInstallationAppointment = (isFTTH || installsMM) && isFiberInstallationRequired

  if (shouldShowInstallationAppointment) {
    // If is fiber or installs MM we go to appointments
    yield put(push(`appointment${window.location.search}`))
  } else if (isD2D) {
    // If is D2D ignore next steps
    yield put(notifyD2DOrderFinishedAction())
  } else {
    yield put(push(`next-steps${window.location.search}`))
  }
}

export function* watchOrderSucceded() {
  yield takeLatest(orderActionTypes.FINISH_POLL_ORDER_SUCCESS, orderSuccededSaga)
}

export function* orderFailedSaga() {
  const isD2D = yield select(selectIsD2D)
  if (isD2D) {
    yield put(notifyD2DOrderFinishedAction())
  } else {
    yield put(push(`next-steps${window.location.search}`))
  }
}

export function* watchOrderFailed() {
  yield takeLatest(orderActionTypes.FINISH_POLL_ORDER_ERROR, orderFailedSaga)
}
