import styled from 'styled-components'
import OtherAddress from './OtherAddress'

export const StyledOtherAddress = styled(OtherAddress)`
  && .coverage-wrapper {
    position: relative;
    #coverage-container {
      min-height: auto;
    }
  }

  && .form-error {
    margin-top: 20px;
  }
`
