const FETCH_ADD_AGILETV_ALLOWED = 'OVID/ADD_AGILETV/FETCH_ADD_AGILETV_ALLOWED'
const FETCH_ADD_AGILETV_ALLOWED_SUCCESS = 'OVID/ADD_AGILETV/FETCH_ADD_AGILETV_ALLOWED_SUCCESS'
const FETCH_ADD_AGILETV_ALLOWED_FAILED = 'OVID/ADD_AGILETV/FETCH_ADD_AGILETV_ALLOWED_FAILED'

const ADD_AGILETV = 'OVID/ADD_AGILETV/ADD_AGILETV'
const ADD_AGILETV_SUCCESS = 'OVID/ADD_AGILETV/ADD_AGILETV_SUCCESS'
const ADD_AGILETV_FAILED = 'OVID/ADD_AGILETV/ADD_AGILETV_FAILED'

export const constants = {
  FETCH_ADD_AGILETV_ALLOWED,
  FETCH_ADD_AGILETV_ALLOWED_SUCCESS,
  FETCH_ADD_AGILETV_ALLOWED_FAILED,

  ADD_AGILETV,
  ADD_AGILETV_SUCCESS,
  ADD_AGILETV_FAILED,
}
