/* eslint-disable no-label-var */
/* eslint-disable no-labels */
/* eslint-disable no-extra-label */
import { find, get, head, isEmpty, last } from 'lodash'
import {
  MAIN_ORDER_ERROR,
  UNEXPECTED_ERROR,
  CHECKOUT_ERROR_EXISTS,
  CHECKOUT_ERROR_BARRING,
  CHECKOUT_ERROR_COMMON,
  CHECKOUT_ERROR_SUBSCRIPTION,
  CHECKOUT_ERROR_ONGOING,
  CHECKOUT_ERROR_MANDATORY_FIELDS,
  CHECKOUT_ERROR_ROVE_FAILED,
} from 'modules/SharedSales/constants'
import { APP_CONFIG } from 'services/app-config'
import {
  AUTONOMO,
  COMPANY,
  CONSUMER,
  POS,
  TELESALES,
  TELESALES_PRO,
  POS_PRO,
} from 'services/global-constants'
import { getStackedFees, getTariffFeesAndDiscounts } from 'modules/tariffs/helpers'
import { formatNumberWithComma, roundTwoDecimals } from 'utils'

import {
  clientExistsRegex,
  barringExistsRegex,
  commonError,
  subscriptionError,
  ongoingOrder,
  mandatoryFields,
  failedConnectionRegex,
} from '../../validations/Checkout.validation'

export function filterSegments(channel, segments) {
  let finalSegments = []
  switch (channel) {
    case POS:
    case TELESALES:
      finalSegments = [CONSUMER, AUTONOMO, COMPANY]
      break
    case TELESALES_PRO:
    case POS_PRO:
      finalSegments = [AUTONOMO, COMPANY]
      break
    default:
      finalSegments = [CONSUMER, AUTONOMO, COMPANY]
  }
  return Array.isArray(segments)
    ? segments
        .filter(segment => finalSegments.includes(segment.backendId))
        .sort((a, b) => (a.value > b.value ? 1 : -1))
    : []
}

export function getOrderTerminalsConditions(terminals) {
  return terminals.map(({ name, singlePayment, initialPayment, monthlyPayment, finalPayment }) => ({
    name,
    singlePayment,
    initialPayment,
    monthlyPayment,
    finalPayment,
    // Mocked data until we got real data about discounts / stuff
    numOfPayments: 24,
    commissions: {
      total: 20,
      percent: 2,
    },
    taxes: {
      tin: 4,
      tae: 5,
    },
    discounts: {
      terminal: 10,
    },
  }))
}

export function getFiberType(techType) {
  switch (techType) {
    case 'NEBA':
    case 'VULA':
      return 'indirect'
    case 'FTTH':
      return 'direct'
    case 'ADSL':
      return 'adsl'
    default:
      return null
  }
}

export function mapToPostalAddressUpdate(billing) {
  const address = billing?.medium
  const postalCode = address.postal_code || address['postal-code'] || address.zipCode

  return {
    ...address,
    street: address?.street || address?.address,
    'postal-code': postalCode,
    'street-type':
      address['street-type'] || head(address?.street?.split(' ')) || address?.streetType,
    'state-or-province': String(postalCode).slice(0, 2),
    country: 'ES',
    building: address?.building || address?.number,
  }
}

function hasResponseErrorsForThisLine(status, id) {
  const error_response = get(status, 'data.error_response')
  return error_response && error_response[id]
}

export function mapToFormatedError(status, line, error, isFinished) {
  const errorLine = get(error, `${line.id}`)
  const { title } = head(get(errorLine, 'result.errors', [{}]))
  let message =
    hasResponseErrorsForThisLine(status, line.id) || !isFinished
      ? UNEXPECTED_ERROR
      : MAIN_ORDER_ERROR
  if (clientExistsRegex.test(title)) message = CHECKOUT_ERROR_EXISTS
  if (barringExistsRegex.test(title)) message = CHECKOUT_ERROR_BARRING
  if (commonError.test(title)) message = CHECKOUT_ERROR_COMMON
  if (subscriptionError.test(title)) message = CHECKOUT_ERROR_SUBSCRIPTION
  if (ongoingOrder.test(title)) message = CHECKOUT_ERROR_ONGOING
  if (mandatoryFields.test(title)) message = CHECKOUT_ERROR_MANDATORY_FIELDS
  if (failedConnectionRegex.test(title)) message = CHECKOUT_ERROR_ROVE_FAILED

  return {
    message,
    error: !!title && title,
  }
}

export function mapToErrorCheckout(
  isCompany,
  account,
  order,
  extraLines,
  landLine,
  mainMobile,
  error,
  success,
  status,
  isFinished,
  isMobileOnly,
  isElFijo,
) {
  const allOrders = get(order, 'relationships.order_items', [])
  const mainLine = isMobileOnly || isElFijo ? mainMobile : landLine
  let hasFoundError
  const formatedDocuments = [...extraLines, mainLine].map(elem => ({
    id: elem.id,
    documentType: isCompany ? 'CIF' : get(account, 'documenttype'),
    documentId: isCompany ? get(account, 'companyCif') : get(account, 'documentid'),
  }))

  const result = allOrders.map(elem => {
    const tariffId = get(
      elem,
      'relationships.order_product[0]relationships.product_offering[0].id',
      '',
    )
    const orderFixedNumber = get(
      elem,
      'relationships.order_product[0].attributes.inputted_characteristics.ch_fixed_number',
      '',
    )
    const orderMSISDN = get(
      elem,
      'relationships.order_product[0].attributes.inputted_characteristics.ch_msisdn',
      '',
    )

    const formatedExtraLines = extraLines.map(extra => ({
      ...extra.tariff,
      name: get(extra.tariff, 'commercialInformation.name', ''),
    }))

    let shouldStartOpen

    const id = get(success, `${elem.id}.id`)

    if (!id && !hasFoundError) {
      hasFoundError = true
      shouldStartOpen = true
    }

    return {
      documentType: get(find(formatedDocuments, { id: elem.id }), 'documentType', ''),
      documentId: get(find(formatedDocuments, { id: elem.id }), 'documentId', ''),
      error: mapToFormatedError(status, elem, error, isFinished),
      key: elem.id,
      name: get(find([...formatedExtraLines, mainLine.tariff], { id: tariffId }), 'name', ''),
      phone: orderFixedNumber || orderMSISDN,
      id,
      shouldStartOpen,
    }
  })

  return result
}

export function mapToAnalyticsTransactionCompleteEvent(
  taskId,
  payments,
  cType,
  terminals,
  tariffs,
  sfid,
) {
  return {
    sfid,
    orderId: taskId,
    basketId: taskId,
    items: tariffs
      .filter(tariff => tariff.id && tariff.name)
      .map(tariff => ({
        idTariff: get(tariff, 'id', ''),
        idModel: get(tariff, 'id', ''),
        brand: 'YOIGO',
        category: 'Tarifas/Combinadas',
        price: {
          initialPrice: 0,
          monthPrice: last(get(tariff, 'discountPrice', [{ amount: 0 }])).amount,
          finalPrice: 0,
          monthPriceWithDiscountsWithIVA: head(get(tariff, 'discountPrice', [{ amount: 0 }]))
            .amount,
        },
        variant: tariff.id.includes('CONTFH') ? 'Línea cabecera' : 'Línea extra',
        name: get(tariff, 'name', ''),
      }))
      .concat(
        terminals.all &&
          terminals.all.map(terminal => ({
            idModel: get(terminal, 'id', ''),
            brand: 'YOIGO',
            category: 'Dispositivos/Moviles',
            price: {
              initialPrice: 0,
              monthPrice: get(terminal, 'paymentResult.amount', 0),
              finalPrice: 0,
              monthPriceWithDiscountsWithIVA: get(terminal, 'paymentResult.amount', 0),
            },
            variant: terminal.isMain ? 'Línea cabecera' : 'Línea extra',
            name: get(terminal, 'details.name', ''),
          })),
      ),
    payment: 'cuenta_bancaria',
    totalAmount: get(last(payments), 'amount', 0),
    newRegister: true,
    transactionPlatform: 'ovid',
    cType: head(get(cType, 'technology', [])),
    url: `${APP_CONFIG.siteroot}/add-client/sales`,
  }
}

function pickOneOfEach(groups, duration, nextDuration) {
  return groups.map(fees => {
    let result = null
    result = fees.find(fee => {
      return fee.duration === duration
    })
    if (!result) {
      result = fees.find(fee => {
        return fee.duration > duration && fee.duration <= nextDuration
      })
    }
    if (!result) {
      result = fees.find(fee => {
        return fee.duration === 0 && !fees.find(() => fee.duration === duration)
      })
    }
    return result
  })
}

function sumFees(...allFees) {
  if (allFees.length <= 1) {
    return allFees[0]
  }
  const durations = Array.from(
    new Set(allFees.reduce((curr, next) => [...curr, ...next]).map(i => i.duration)),
  ).sort()

  const result = durations.map((duration, i) => {
    const candidates = []

    for (let o = 0; o < allFees.length; o += 1) {
      const picked = pickOneOfEach(allFees, duration, durations[i + 1] || duration)
      candidates.push(picked)
      continue
    }
    return candidates[0].reduce((curr, next) => ({
      amount: curr.amount + next.amount,
      duration,
    }))
  })

  return result
}

export function getFeesWithBundles(tariffs, bundles, tax) {
  const tariffsFees = tariffs
    .filter(o => !!o.id)
    ?.map(tariff => {
      const brute = getTariffFeesAndDiscounts(tariff, tax)
      return getStackedFees(brute.fees)
    })

  const stacked = (tariffsFees?.length && sumFees(...tariffsFees)) || []

  const bundlesFee = bundles
    ?.map(
      pack =>
        (tax === 1 && pack.monthlyFee) ||
        pack.monthlyFeeWithTax ||
        pack.prices?.taxFreeAmount ||
        pack.price?.taxFreeAmount,
    )
    ?.reduce((curr, next) => curr + next, 0)

  const feesWithBundles = stacked.map(fee => ({ ...fee, amount: fee.amount + bundlesFee }))

  feesWithBundles.sort((a, b) => (a.duration > b.duration ? 1 : -1))

  const baseFee = feesWithBundles.shift()

  if (baseFee) {
    return [...feesWithBundles, baseFee]
  }
  return feesWithBundles
}

export const formatLineBundles = (bundles = [], label, isPro) => {
  return bundles.map(bundle => ({
    display: [
      {
        label: bundle.active ? 'Mantiene' : label,
        value: bundle.name || bundle.commercialInfo.name,
      },
      {
        label: 'Precio',
        value: `${formatNumberWithComma(
          roundTwoDecimals(
            (isPro ? bundle.monthlyFee : bundle.monthlyFeeWithTax) ||
              get(
                bundle,
                'prices.taxIncludedAmount',
                get(bundle, 'prices.taxFreeAmount', get(bundle, 'price.taxFreeAmount', 0)),
              ),
          ),
        )} €/mes`,
      },
    ],
    id: bundle.id || bundle.psId,
    description: bundle.commercialInfo?.description,
    active: bundle.active,
  }))
}

export function formatDiscounts(tariff) {
  return tariff?.discounts?.map(discount => {
    const formatedPrice = `${roundTwoDecimals(discount.amount)} €`

    if (discount.penalty) {
      return {
        display: [
          {
            label: 'Penalización',
            value: `${discount.penalty} €`,
          },
        ],
      }
    }
    if (discount.duration) {
      return {
        display: [
          {
            label: 'Promoción',
            value: formatedPrice,
          },
          {
            label: 'Duración',
            value: `${discount.duration} meses`,
          },
        ],
      }
    }

    return {
      display: [
        {
          label: discount.isD2rDiscount ? 'Promoción (Descuento segunda residencia)' : 'Promoción',
          value: formatedPrice,
        },
      ],
    }
  })
}

export function formatSvasPenalty(svas) {
  return svas
    ?.filter(sva => !isEmpty(sva.terms))
    .map(sva => ({
      display: [
        {
          label: `Penalización SVA ${sva.name || sva.commercialInfo.name}`,
          value: `${roundTwoDecimals(
            sva.terms.taxIncludedAmount || get(sva, 'terms.taxFreeAmount', 0),
          )} €`,
        },
        {
          label: 'Duración',
          value: `${sva.terms.count} meses`,
        },
      ],
    }))
}

export function objectHasEmptyValue(obj) {
  const values = Object.values(obj)
  return values.some(value => !value)
}

export function applyTaxOnlyProSVAs(packages, proSVAsFormated) {
  return packages.map(pack => {
    const proSva = proSVAsFormated.find(sva => sva.poId === pack.poId)
    return proSva || pack
  })
}
