import { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { exitAppAction } from 'modules/Core'
import { actions as authActions } from 'modules/Auth'
import { removeQueryParam } from 'modules/url'

import { Button } from 'components/ui'

export function QuitButton() {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()

  const getTargetUrl = (baseURL, params) => {
    const urlParams = new URLSearchParams(location.search)
    urlParams.set('isSalesFlow', true)

    return params.reduce(
      (urlWithoutParams, param) => removeQueryParam(urlWithoutParams, param),
      `${baseURL}?${urlParams.toString()}`,
    )
  }

  const vista360URL = useMemo(
    () =>
      getTargetUrl('/vista/dashboard', [
        'sale',
        'saleSegment',
        'isCartera',
        'isSubscription',
        'isCross',
        'isPresale',
        'isMultiSim',
      ]),
    [],
  )

  const onClose = useCallback(() => {
    dispatch(exitAppAction())
    dispatch(authActions.resetDocumentId())
    history.push(vista360URL)
  })

  return (
    <Button type="button" onClick={onClose}>
      VOLVER A PANTALLA DE INICIO
    </Button>
  )
}
