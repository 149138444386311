import { phoneTypes } from './phone.constants'
import { mobileNumberRegex, phoneNumber } from '../../validations/regex'

/**
 * Recognize phone type
 *
 * @param phone {String}
 * @returns {FIXEDNUMBER|MSISDN} phone type
 */
export function getPhoneType(phone) {
  if (mobileNumberRegex.test(phone)) return phoneTypes.MSISDN
  if (phoneNumber.test(phone)) return phoneTypes.FIXEDNUMBER

  return null
}

export function isMobilePhoneNumber(phone = '') {
  return mobileNumberRegex.test(phone)
}
