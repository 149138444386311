import styled from 'styled-components'
import PropTypes from 'prop-types'
import { get } from 'lodash'

import { Typography, Box, Divider } from '@material-ui/core'
import { TextStyles } from 'utils/text'

import { getFeesPendingFromPermanent } from 'services/subscriptions'

import { PermanencyTab } from './PermanencyTab'
import { PendingAndTotalFee } from './PendingAndTotalFee'

export const PermanencyBonus = styled(({ className, permanents }) => (
  <div className={className}>
    <Typography
      {...TextStyles.subtitle1Secondary({
        className: 'txt-none p-b-12',
      })}>
      Bonos
    </Typography>
    <div className="flex">
      {permanents.map(permanent => (
        <>
          <PermanencyTab
            validFor={get(permanent, 'validFor')}
            totalMonths={get(permanent, 'length')}
          />

          <Box mt="8px">
            <Divider orientation="vertical" />
          </Box>

          <PendingAndTotalFee
            className="p-l-24"
            title="Penalización"
            pendingFee={getFeesPendingFromPermanent(permanent)}
            totalFee={get(permanent, 'fees.penalty', 0)}
          />
        </>
      ))}
      <Box mt="8px">
        <Divider orientation="vertical" />
      </Box>
    </div>
  </div>
))`
  .txt-none {
    text-transform: none;
  }
`

PermanencyBonus.propTypes = {
  className: PropTypes.string,
}
