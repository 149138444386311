import { isDuoTariffId } from 'modules/tariffs/store-apigee/tariffs-apigee.helpers'
import { DUO_TARIFFS_IDS } from 'modules/offers-configuration/constants'

import { constants as customer360Constants } from 'services/customer-360/constants'

const hasSomeAdditionalLine = mainLineChildrens =>
  !!mainLineChildrens.find(pack => pack.lineType === customer360Constants.ADDITIONAL_LINE)

export const getCrossWarnings = (subscription, clientChildrenPackages) => {
  const isMainLine = subscription.lineType === customer360Constants.MAIN_LINE

  const mainLineOrderId = isMainLine
    ? subscription.subscription_id
    : subscription.parentSubscriptionId
  const mainLineChildrens = clientChildrenPackages.filter(
    pack => pack.parentOrderId === mainLineOrderId,
  )

  const isDuoPrincipal = subscription.type === DUO_TARIFFS_IDS[0]
  const duoLines = mainLineChildrens.filter(pack => isDuoTariffId(pack.tariffId))

  return {
    isDuo: isDuoTariffId(subscription.type),
    hasDuo: isMainLine && duoLines.length > 0,
    hasAdditionalLines: isMainLine && hasSomeAdditionalLine(mainLineChildrens),
    hasDuoInterminable: isDuoPrincipal && duoLines.length > 1,
  }
}
