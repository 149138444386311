import { useEffect } from 'react'
import PropTypes from 'prop-types'

import { useFormikContext } from 'formik'
import { Box, Radio, FormControlLabel } from '@material-ui/core'

import { PORTABILITY_TYPE_CONTRACT } from 'modules/SharedSales/constants'
import { SIGNUP_TYPE_NEWLINE } from 'services/global-constants'
import { FormikRadio } from 'components/ui'

export function TerminalQuestion({
  fieldNames,
  onAddTerminalChange,
  portaPaymentType,
  signUpType,
  isElFijo,
  disableOfferEdit,
  shouldDisableTerminal,
  isAppleTv,
}) {
  const { setFieldValue } = useFormikContext()
  const missesElFijo =
    signUpType === SIGNUP_TYPE_NEWLINE ? fieldNames.MOBILE_LINE_TYPE : fieldNames.PORTABILITY_NUMBER
  const errorsElFijo = signUpType === SIGNUP_TYPE_NEWLINE ? null : fieldNames.PORTABILITY_NUMBER
  const misses =
    signUpType === SIGNUP_TYPE_NEWLINE
      ? fieldNames.MOBILE_LINE_TYPE
      : fieldNames.PORTABILITY_PAYMENT_TYPE
  const error =
    portaPaymentType === PORTABILITY_TYPE_CONTRACT ? fieldNames.FIRST_NAME : fieldNames.ICC_NUMBER

  const noRadioProps = {
    className: 'radio-check',
    color: 'primary',
  }

  const yesRadioProps = isAppleTv
    ? {
        ...noRadioProps,
        checked: true,
      }
    : noRadioProps

  useEffect(() => {
    if (shouldDisableTerminal) setFieldValue(fieldNames.HAS_TERMINAL, 'no')
  }, [shouldDisableTerminal])

  return (
    <Box display="flex" className="align-start-center">
      <FormikRadio
        row
        isDisabled={{
          misses: isElFijo ? missesElFijo : misses,
          error: isElFijo ? errorsElFijo : error,
          bool: disableOfferEdit || shouldDisableTerminal,
        }}
        onChange={e => onAddTerminalChange(e, fieldNames)}
        name={fieldNames.HAS_TERMINAL}
        dataHook={fieldNames.HAS_TERMINAL}
        label="¿Quieres añadir un dispositivo?">
        <>
          <FormControlLabel
            value="yes"
            control={<Radio data-hook={`${fieldNames.HAS_TERMINAL}.yes`} {...yesRadioProps} />}
            label="Sí"
          />
          {!isAppleTv && (
            <FormControlLabel
              value="no"
              control={<Radio data-hook={`${fieldNames.HAS_TERMINAL}.no`} {...noRadioProps} />}
              label="No"
            />
          )}
        </>
      </FormikRadio>
    </Box>
  )
}

TerminalQuestion.propTypes = {
  onAddTerminalChange: PropTypes.func.isRequired,
  fieldNames: PropTypes.object,
  signUpType: PropTypes.string,
  portaPaymentType: PropTypes.string,
  isElFijo: PropTypes.bool,
  disableOfferEdit: PropTypes.bool,
  shouldDisableTerminal: PropTypes.bool,
  isAppleTv: PropTypes.bool,
}
