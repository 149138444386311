export const OtherAddressModel = {
  address: '',
  city: '',
  door: '',
  flat: '',
  number: '',
  otherInfo: '',
  province: '',
  zipCode: '',
}
