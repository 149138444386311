import { PropTypes } from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectIsLoading,
  selectErrorMessage,
  fatCompensationAction,
  fatCompensationClearErrorAction,
} from 'modules/FAT'
import { getSubscriptionByCurrentProductId } from 'services/subscriptions/selectors'
import { FATModal } from '../../components/FATModal/FATModal'

export default function FATModalContainer({ isOpen, onCloseModal }) {
  const dispatch = useDispatch()
  const props = {
    subscription: useSelector(getSubscriptionByCurrentProductId),
    isOpen,
    onCloseModal,
    isFATLoading: useSelector(selectIsLoading),
    FATErrors: useSelector(selectErrorMessage),
    applyFATCompensation: (subscriptionId, msisdn, FATdata) =>
      dispatch(fatCompensationAction(subscriptionId, msisdn, FATdata)),
    FATCompensationClearError: () => dispatch(fatCompensationClearErrorAction()),
  }
  return <FATModal {...props} />
}

FATModalContainer.propTypes = {
  isOpen: PropTypes.bool,
  onCloseModal: PropTypes.func,
}
