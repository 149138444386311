import { axiosJWT } from 'modules/axios'
import { APP_CONFIG } from 'services/app-config'

const contractURL = `${APP_CONFIG.sales_cluster}/v1/checkout/basket`

export function fetchReadableContract(orderId) {
  return axiosJWT({
    method: 'GET',
    url: `${contractURL}/${orderId}/humanReadable`,
    responseType: 'blob',
  }).then(resp => {
    const url = window.URL.createObjectURL(new Blob([resp.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `Contrato-${orderId}.txt`)
    document.body.appendChild(link)
    link.click()
  })
}
