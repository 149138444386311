import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { set } from 'lodash'
import { useFormikContext } from 'formik'
import { Terminal } from 'modules/Terminals/model'

import { LAND_LINE_OFFER } from '../../constants'
import { setBasketData } from '../../store/actions'

export function useResetLine(fieldNames) {
  const dispatch = useDispatch()
  const { setValues, values } = useFormikContext()

  const onSetBasketData = useCallback(data => {
    dispatch(setBasketData(data))
  })

  function onFormatNewValues(newValues, shouldDisableTerminal) {
    if (fieldNames.LINE_TYPE === LAND_LINE_OFFER) {
      set(newValues, fieldNames.SVA, {})
    }
    set(newValues, fieldNames.TERMINAL, Terminal)
    set(newValues, fieldNames.HAS_TERMINAL, shouldDisableTerminal ? 'no' : '')
    set(newValues, fieldNames.SELECTED_PACKAGES, [])
    return newValues
  }

  function onResetLines() {
    const newValues = onFormatNewValues({ ...values, extraMobileLinesOffers: [] })
    setValues(newValues)
    onSetBasketData(newValues)
  }

  function onResetSingleLine(shouldDisableTerminal) {
    const newValues = onFormatNewValues(values, shouldDisableTerminal)
    setValues(newValues)
    onSetBasketData(newValues)
  }

  return { onResetLines, onResetSingleLine }
}
