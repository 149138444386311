import PropTypes from 'prop-types'

import { useSelector } from 'react-redux'
import { get, map } from 'lodash'

import { getCompanyInfo } from 'services/global-selectors/selectors'
import { getProductsOrdersCustomer } from 'modules/orders'

import { getCurrentDateFormatted } from 'utils/dates'

const RetainLegalRecording = ({ personalData, lines }) => {
  const companyInfo = useSelector(getCompanyInfo)
  const currentDate = getCurrentDateFormatted()
  const products = useSelector(getProductsOrdersCustomer)

  const companyName = get(companyInfo, 'name', '')
  const companyCif = get(companyInfo, 'individualIdentification', '')

  return (
    <>
      <li>
        “Tal y como hemos acordado, Hoy a fecha de {currentDate.formatedDate}, siendo las las{' '}
        {currentDate.hours} Horas, {currentDate.minutes} Minutos procedemos a aplicar un descuento
        del XXX % / xx €, durante XXX meses en la tarifa {products.map(elem => elem.description)} de
        la línea/s
        {map(lines, (item, index) => (
          <span key={item.id}>
            {' '}
            {item.id} {item.category === 'fixed' ? 'fija' : 'móvil'}
            {index === lines.length - 2 ? ' y ' : ''}
          </span>
        ))}
      </li>
      <li>
        ¿Estás de acuerdo ? <b>SI</b>
      </li>
      <li>
        Confírmame tu Nombre y Apellidos:{' '}
        <b>
          {personalData.fullName} {companyName ? ` / ${companyName}` : null}
        </b>
      </li>
      <li>
        Confírmame tu DNI / CIF:{' '}
        <b>
          {get(personalData, 'identification.identificationId')}{' '}
          {companyCif ? ` / ${companyCif}` : null}
        </b>
      </li>
      <li>Muchas gracias.Buenos días </li>
    </>
  )
}

RetainLegalRecording.propTypes = {
  personalData: PropTypes.object,
  lines: PropTypes.array,
}

export default RetainLegalRecording
