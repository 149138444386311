import styled from 'styled-components'

const Card = styled.section`
  width: ${props => (props.width ? props.width : '100%')};
  height: ${props => (props.height ? props.height : 'unset')};
  max-height: ${props => (props.maxHeight ? props.maxHeight : 'unset')};
  border-radius: ${props => props.borderradius || '14px'};
  background-color: #fcfcfc;
  box-shadow: ${props => (props.noshadow ? '0' : '0 2px 4px 1px rgba(0, 0, 0, 0.27)')};
  margin: ${props => (props.margin ? props.margin : '24px auto')};
  padding: ${props => (props.padding ? props.padding : '24px')};
  box-sizing: border-box;
  overflow-y: ${props => props.overflowy || 'auto'};
  position: relative;
`
export default Card
