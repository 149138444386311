import { createStructuredSelector } from 'reselect'
import { useSelector } from 'react-redux'

import { useLoading } from 'modules/Core'
import { selectCustomerTokenError, selectDocumentId, selectAccountInfoError } from 'modules/Auth'
import { selectQueryParams } from 'modules/Router/store/router.selectors'
import * as customer360Svc from 'services/customer-360'
import { selectors as dashboardSelectors } from 'modules/vista-360/sagas/dashboardLoad/selectors'

export function separateNumberfromString(str) {
  if (!str) return ''
  return str
    .split('')
    .map((elem, i, arr) => (!!Number(arr[i]) && !Number(arr[i + 1]) ? `${elem} ` : elem))
    .join('')
}

export const isAppLoading = () => {
  const dashboardContainerSelector = createStructuredSelector({
    customerTokenError: selectCustomerTokenError,
    accountInfoError: selectAccountInfoError,
    isLoading: dashboardSelectors.isLoading || customer360Svc.selectors.isLoading,
    documentId: selectDocumentId,
    queryParams: selectQueryParams,
  })

  const childProps = useSelector(dashboardContainerSelector)
  const { customerTokenError, documentId, isLoading, queryParams } = childProps

  const isInloading = useLoading(documentId)
  const loading =
    (!queryParams.isSalesFlow &&
      (!(documentId || customerTokenError) || isInloading || isLoading)) ||
    (queryParams.isSalesFlow && (isInloading || isLoading))

  return loading
}
