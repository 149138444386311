import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import * as subscriptionsSvc from 'services/subscriptions'
import { applyTax, getTax } from 'services/taxes'
import { get } from 'lodash'
import { selectors as customerSelectors } from 'services/customer-360'
import { isProCustomer } from 'services/customer-360/helpers'
import { getSegment } from 'services/customer-360/selectors'
import { extractDataFixedConsumption } from '../../selectors/selectors'

import { UsagesFixedCard } from '../../components/Usages'

export function UsagesFixedCardContainer({ sub, isElFijo, highLigthedUI, isPrepaid }) {
  const billingAddress = useSelector(state => customerSelectors.getBillingAddress(state))
  const postCode = get(billingAddress, 'postCode')
  const customerSegment = useSelector(state => getSegment(state))
  const taxNeeded = !isProCustomer(customerSegment)

  const tax = (taxNeeded && useSelector(state => getTax(state, postCode))) || 0

  const extractDataFixedConsumptionData = extractDataFixedConsumption(sub)
  const callsConsumedWithTaxes = applyTax(
    tax,
    get(extractDataFixedConsumptionData, 'callsConsumed'),
  )
  const premiumCallsConsumedWithTaxes = applyTax(
    tax,
    get(extractDataFixedConsumptionData, 'premiumCallsConsumed'),
  )
  const internationalCallsConsumedWithTaxes = applyTax(
    tax,
    get(extractDataFixedConsumptionData, 'internationalCallsConsumed'),
  )
  const othersConsumedWithTaxes = applyTax(
    tax,
    get(extractDataFixedConsumptionData, 'othersConsumed'),
  )
  const totalExtrasFixedWithTaxes = applyTax(
    tax,
    get(extractDataFixedConsumptionData, 'totalExtrasFixed'),
  )
  const data = {
    f2FVoiceTotalAmount: subscriptionsSvc.getF2FVoiceTotalAmountInMin(sub),
    f2FVoiceTotalUsage: subscriptionsSvc.getF2FVoiceTotalUsageInMin(sub),
    f2MVoiceTotalAmount: subscriptionsSvc.getF2MVoiceTotalAmountInMin(sub),
    f2MVoiceTotalUsage: subscriptionsSvc.getF2MVoiceTotalUsageInMin(sub),
    totalExtras: subscriptionsSvc.getTotalExtrasFixed(sub),
    f2FHasUnlimited: subscriptionsSvc.hasF2FUnlimitedMin(sub),
    f2MHasUnlimited: subscriptionsSvc.hasF2MUnlimitedMin(sub),
    fixed: {
      callsConsumedWithTaxes,
      premiumCallsConsumedWithTaxes,
      internationalCallsConsumedWithTaxes,
      othersConsumedWithTaxes,
      totalExtrasFixedWithTaxes,
    },
    isPrepaid,
  }
  return (
    <UsagesFixedCard
      data={data}
      isElFijo={isElFijo}
      highLigthedUI={highLigthedUI}
      taxNeeded={taxNeeded}
    />
  )
}

UsagesFixedCardContainer.propTypes = {
  sub: PropTypes.object,
  isElFijo: PropTypes.bool,
  highLigthedUI: PropTypes.object,
  isPrepaid: PropTypes.bool,
}
