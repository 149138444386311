import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Typography } from '@material-ui/core'
import { TextStyles } from 'utils/text'

import { formatFee } from 'utils'

function FeeTabLine({ className, fee, feeDescription }) {
  return (
    <div className={className}>
      <Typography {...TextStyles.title1BoldDark()}>{formatFee(fee)}</Typography>
      {feeDescription && (
        <Typography
          {...TextStyles.labelDark({
            className: 'p-t-24',
          })}>
          {feeDescription}
        </Typography>
      )}
    </div>
  )
}

export const FeeTab = styled(({ className, title, fee, feeDescription }) => {
  return (
    <div className={className}>
      <Typography {...TextStyles.subtitle2Dark()}>{title}:</Typography>

      <FeeTabLine className="p-t-4" fee={fee} feeDescription={feeDescription} />
    </div>
  )
})`
  width: 215px;
`

FeeTabLine.propTypes = {
  className: PropTypes.string,
  fee: PropTypes.number,
  feeDescription: PropTypes.string,
}

FeeTab.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  fee: PropTypes.number,
  feeDescription: PropTypes.string,
}
