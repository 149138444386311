import PropTypes from 'prop-types'
import styled from 'styled-components'
import { get } from 'lodash'
import { Icon } from '@material-ui/core'
import { ICONS } from 'utils/icons'

import { ButtonLink } from '../buttons/ButtonLink'

const OfferItemModifiedWrapper = styled.div`
  button {
    position: absolute;
    top: 7px;
    right: 4px;
    .icon {
      margin-right: 0;
    }
  }
`

const ItemLabel = styled.div`
  color: #b9c2c7;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 2px;
`

const ItemContent = styled.div`
  border-radius: 4px;
  background-color: #f3f3f3;
  border: solid 1px #e3e3e3;
  position: relative;
  padding: 8px;
`

const ItemValue = styled.div`
  color: ${props => get(props, 'theme.palette.global.gray_dark')};
  font-size: 14px;
  font-weight: 500;
`

const OfferItemModified = props => {
  const { title, labels, onRemove } = props

  return (
    <OfferItemModifiedWrapper className="offer-item">
      <ItemLabel>{title}</ItemLabel>
      <ItemContent>
        {labels.map(label => (
          <ItemValue key={label}>{label}</ItemValue>
        ))}
        {onRemove ? (
          <ButtonLink onClick={onRemove}>
            <Icon className="icon">{ICONS.close}</Icon>
          </ButtonLink>
        ) : null}
      </ItemContent>
    </OfferItemModifiedWrapper>
  )
}

OfferItemModified.propTypes = {
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
  onRemove: PropTypes.func,
  title: PropTypes.string.isRequired,
}

export default OfferItemModified
