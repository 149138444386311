import { permissions, selectCanIDO } from 'modules/Permissions'
import { useSelector } from 'react-redux'
import { D2D_ADDITIONAL_ENERGY_ACCESS } from 'modules/Permissions/constants'
import { selectIsPosOrMasProximo } from 'modules/Permissions/store/permissions-selectors'

export const canIViewAdditionalInfoActivityRegister = () =>
  useSelector(selectCanIDO(permissions.additional_activity_register.id))

export const canIBillDownload = () => useSelector(selectCanIDO(permissions.bill_download.id))

export const canIViewCompleteAccountNumber = () =>
  useSelector(
    selectCanIDO(permissions.view_complete_account_number.id, D2D_ADDITIONAL_ENERGY_ACCESS),
  ) || !useSelector(selectIsPosOrMasProximo)

export const canIViewDetailUseSubscription = () =>
  useSelector(selectCanIDO(permissions.view_detail_use_subscription.id))
