import PropTypes from 'prop-types'

import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import { isEmpty } from 'lodash/fp'

const buildCoverageResult = technology => {
  if (isEmpty(technology)) return 'No hay cobertura'
  return technology.join('-')
}

const useStyles = makeStyles(theme => ({
  'coverage-result': {
    marginLeft: ({ is360 }) => (is360 ? '0px' : '-25px'),
    fontSize: '20px',
    color: ({ hasTechnology }) =>
      hasTechnology ? theme.palette.global.gray_dark : theme.palette.global.light,
    textAlign: 'left',
    backgroundColor: ({ hasTechnology }) => (hasTechnology ? '#e0f6c5' : '#ff341e'),
    fontWeight: 'bold',
  },
}))

const CoverageResult = ({ className, technology, applicationId }) => {
  const classes = useStyles({ hasTechnology: !isEmpty(technology), is360: applicationId === '360' })

  return (
    <Box
      display="inline-block"
      p="10px 24px"
      mb="22px"
      width="100%"
      className={[classes['coverage-result'], ...(className ? [className] : [])].join(' ')}
      data-hook="coverage-result">
      Resultado de cobertura: {buildCoverageResult(technology)}
    </Box>
  )
}

CoverageResult.propTypes = {
  technology: PropTypes.array,
  className: PropTypes.string,
  applicationId: PropTypes.string,
}

export default CoverageResult
