import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Typography } from '@material-ui/core'
import { TextStyles } from 'utils/text'

import { Grid, Modal } from 'components/ui'
import { isEmpty, get } from 'lodash'
import { formatErrorMsg } from 'modules/credit'

export const CreditResultModal = styled(({ className, errors, isOpen, onClose }) => {
  const info = get(errors, 'error')
  const icon = isEmpty(errors) ? '/assets/clap_clap.svg' : '/assets/error.svg'
  const creditNoteResult = isEmpty(errors)
    ? 'Abono de factura aplicado correctamente'
    : 'Ha ocurrido un error al crear el abono de factura'

  const errorMsg = info && formatErrorMsg(info)

  return (
    <Modal
      className={className}
      type={!isEmpty(errors) ? 'alert' : 'success'}
      data-hook="credit-modal-result"
      isOpen={isOpen}
      onClose={onClose}>
      <Grid container justify="space-between" alignItems="center">
        <Grid className="content-grid" item xs={12}>
          <img src={icon} alt={creditNoteResult} />
        </Grid>
        <Grid className="content-grid" item xs={12}>
          <Grid className="content-grid" item xs={12}>
            <Typography {...TextStyles.title1Dark()}>{creditNoteResult}</Typography>
            {errorMsg && <Typography {...TextStyles.title1Dark()}>{errorMsg}</Typography>}
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  )
})`
  .content-grid {
    text-align: center;
    padding: 1em 0;
  }
`

CreditResultModal.propTypes = {
  errors: PropTypes.object,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
}
