import { createSelector } from 'reselect'
import { get } from 'lodash'

export const getInstallations = state => state.installationsCare

export const selectIsLoadingInstallations = createSelector(getInstallations, inst => inst.loading)

export const selectInstaller = createSelector(getInstallations, inst => get(inst, 'data.installer'))

export const selectConnectionType = createSelector(getInstallations, inst =>
  get(inst, 'data.connectionType'),
)

export const selectInstallationsData = createSelector(getInstallations, inst => inst.data)
