import PropTypes from 'prop-types'
import { get } from 'lodash'
import { makeStyles } from '@material-ui/styles'

import { ButtonLink } from './ButtonLink'

const useStyles = makeStyles(theme => ({
  'button-card-wrapper': {
    '& .button-card': {
      alignItems: 'center',
      border: `1px solid ${get(theme, 'palette.global.gray_light_05')}`,
      borderRadius: ({ circle }) => (circle ? '100%' : '50px'),
      display: 'inline-flex',
      justifyContent: 'center',
      fontWeight: 500,
      minHeight: '24px',
      padding: ({ circle }) => (circle ? 0 : '0 15px'),
      textTransform: 'uppercase',
      width: ({ circle }) => circle && '24px',

      '& .icon': {
        fontSize: '16px',
        margin: ({ circle }) => circle && 0,
      },

      cursor: ({ disabled, loading }) => {
        if (disabled) {
          return null
        }

        return loading ? 'wait' : 'pointer'
      },
    },
  },
}))

function ButtonCard({ className, label, icon, action, onClick, ...rest }) {
  const classes = useStyles({ circle: !label, ...rest })

  return (
    <div className={classes['button-card-wrapper']}>
      <ButtonLink
        className={`button-card ${className}`}
        data-hook={`btn-card-${label}`}
        iconType={icon}
        onClick={onClick || action || (() => {})}
        underline={false}
        {...rest}>
        {label && <span>{label}</span>}
      </ButtonLink>
    </div>
  )
}

ButtonCard.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.string,
  action: PropTypes.func,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
}

export default ButtonCard
