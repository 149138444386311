import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import { Box, Typography, TextField, Snackbar, Portal } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import {
  Button,
  Modal,
  SpinnerCenter,
  ModalActions,
  ButtonLink,
  NotificationModal,
} from 'components/ui'
import { getCoverageToken } from 'modules/Coverage'
import { selectSfid } from 'modules/Auth/store/selectors'
import { selectQueryParams } from 'modules/Router/store/router.selectors'

import { useValidateErrors } from '../../../../hooks/Validations/useValidateErrors'
import {
  selectPrescoringFailed,
  selectPrescoringReferenceNumber,
  selectScoringLoading,
  selectIsSavingPresale,
  selectPresaleSavingError,
  selectSavedPresaleId,
  savePresaleData,
  fetchPrescoringAction,
  selectPrescoringErrorCode,
} from '../../../../store'
import { getSavePresaleRequestBody } from '../../../../helpers'

export const PreSaleSaveButton = ({ stepName, setBasketData, disabled }) => {
  const dispatch = useDispatch()

  const isSaving = useSelector(selectIsSavingPresale)
  const preSaleId = useSelector(selectSavedPresaleId)
  const presaleSavingError = useSelector(selectPresaleSavingError)
  const coverageToken = useSelector(getCoverageToken)

  const isPrescoringFailed = useSelector(selectPrescoringFailed)
  const isPrescoringLoading = useSelector(selectScoringLoading)
  const prescoringErrorCode = useSelector(selectPrescoringErrorCode)
  const prescoringReference = useSelector(selectPrescoringReferenceNumber)

  const sfid = useSelector(selectSfid)
  const { sale: orderType } = useSelector(selectQueryParams)

  const { validateErrors, formikValues } = useValidateErrors(stepName)

  const [isShowIdModal, setIsShowIdModal] = useState(false)
  const [isFormikValuesChanged, setIsFormikValuesChanged] = useState(false)

  const [inputRef, setInputRef] = useState(null)
  const [preSaleIdIsCopied, setPreSaleIdIsCopied] = useState(false)

  const [isShowErrorModal, setIsShowErrorModal] = useState(false)

  useEffect(() => {
    setIsFormikValuesChanged(true)
  }, [formikValues])

  useEffect(() => {
    if (
      isShowIdModal &&
      !isPrescoringLoading &&
      (!preSaleId || isFormikValuesChanged || isPrescoringFailed || !!prescoringErrorCode)
    ) {
      dispatch(fetchPrescoringAction({ noPreReferenceNumber: true }))
      if (prescoringErrorCode) {
        setIsFormikValuesChanged(true)
      }
    }
  }, [isShowIdModal])

  useEffect(() => {
    if (
      isShowIdModal &&
      !isPrescoringLoading &&
      ((!preSaleId && !isSaving) || isFormikValuesChanged) &&
      !isPrescoringFailed &&
      !!prescoringReference
    ) {
      dispatch(
        savePresaleData(
          getSavePresaleRequestBody(
            formikValues,
            sfid,
            coverageToken,
            orderType,
            prescoringReference,
          ),
        ),
      )
      setIsFormikValuesChanged(false)
    }
  }, [isPrescoringLoading, isPrescoringFailed, prescoringReference])

  useEffect(() => {
    if (
      presaleSavingError ||
      (isShowIdModal && !isPrescoringLoading && prescoringErrorCode) ||
      isPrescoringFailed
    ) {
      setIsShowIdModal(false)

      if (!isPrescoringFailed) {
        setIsShowErrorModal(true)
      }
    }
  }, [presaleSavingError, isPrescoringFailed, prescoringErrorCode])

  const copyPreSaleId = () => {
    inputRef.select()
    inputRef.setSelectionRange(0, 256)
    document.execCommand('copy')

    setPreSaleIdIsCopied(true)
  }

  const openPreSaleIdModal = async () => {
    const errors = await validateErrors()
    if (errors) {
      return
    }

    setBasketData(formikValues)

    if (presaleSavingError) {
      setIsFormikValuesChanged(true)
    }

    setIsShowIdModal(true)
  }

  return (
    <>
      <NotificationModal
        isOpen={isShowErrorModal}
        type="alert"
        src="/assets/error.svg"
        title={
          !isPrescoringLoading && !prescoringReference
            ? 'Error comprobando prescoring'
            : 'Error guardando preventa'
        }
        onClose={() => {
          setIsShowErrorModal(false)
        }}>
        <Box mb="20px">
          <Typography variant="body1">Se ha producido un fallo, reintente por favor.</Typography>
        </Box>

        <Button
          data-hook="action-accept"
          onClick={() => {
            setIsShowErrorModal(false)
          }}>
          Cerrar
        </Button>
      </NotificationModal>

      <Portal>
        <Snackbar
          open={preSaleIdIsCopied}
          autoHideDuration={2000}
          onClose={() => setPreSaleIdIsCopied(false)}>
          <Alert severity="success" onClose={() => setPreSaleIdIsCopied(false)}>
            ID copiado al portapapeles!
          </Alert>
        </Snackbar>
      </Portal>

      <Modal
        isOpen={isShowIdModal}
        title="Guardar preventa"
        onClose={!isSaving && !isPrescoringLoading ? () => setIsShowIdModal(false) : null}
        style={{ width: '425px' }}>
        {(isSaving || isPrescoringLoading) && (
          <SpinnerCenter size="48px" style={{ margin: '50px 0' }} />
        )}

        {!isSaving && !isPrescoringLoading && (
          <>
            <Typography variant="body1">Por favor, copie el siguiente ID:</Typography>

            <Box display="flex" mt="20px">
              <TextField
                value={preSaleId}
                size="medium"
                style={{ width: '100%', marginRight: '20px' }}
                readonly
                inputRef={input => input && setInputRef(input)}
                data-hook="presale-save-id"
                onClick={copyPreSaleId}
              />
              <ButtonLink onClick={copyPreSaleId}>Copiar</ButtonLink>
            </Box>

            <ModalActions>
              <Button onClick={() => setIsShowIdModal(false)}>Aceptar</Button>
            </ModalActions>
          </>
        )}
      </Modal>

      <Button secondary onClick={openPreSaleIdModal} disabled={disabled} data-hook="presale-save">
        Guardar preventa
      </Button>
    </>
  )
}

PreSaleSaveButton.propTypes = {
  stepName: PropTypes.string.isRequired,
  setBasketData: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}
