export const ActivityTypes = {
  communications: {
    id: 'communications',
    relativePath: '/communications',
  },
  typifications: {
    id: 'typifications',
    relativePath: '/typifications',
  },
  orders_history: {
    id: 'orders_history',
    relativePath: '/orders/history',
  },
  logistic: {
    id: 'logistic',
    relativePath: '/logistic',
  },
  memo: {
    id: 'memo',
    relativePath: '/memo',
  },
  all: {
    id: 'all',
    relativePath: '',
  },
}
export const call_cases = {
  2: 'International Call',
  3: 'Roaming voice(MO)Intern. dest.',
  4: 'Roaming voice(MO Local dest.',
  5: 'Roaming EU',
  7: 'Roaming Voice (MT)',
  8: 'Freephone Voice and SMS',
  9: 'Service Number',
  10: 'RETEVISIÓN MÓVIL, S.A.',
  11: 'TELEFÓNICA MÓVILES ESPAÑA, S.A',
  12: 'VODAFONE ESPAÑA, S.A.',
  13: 'XFERA MÓVILES, S.A.',
  14: 'FRANCE TELECOM ESPANA, S.A.',
  15: 'EUSKALTEL, S.A.',
  16: 'R CABLE Y TELECOM. GALICIA, S.A',
  17: 'CABLEUROPA, S.A.U.',
  18: 'BT ESPAÑA COMPAÑÍA DE SERVICIO',
  19: 'JAZZ TELECOM, S.A.U.',
  20: 'E-PLUS MÓVILES VIRTUALES ESPAÑ',
  21: 'BARABLU MÓVIL ESPAÑA, S.L',
  22: 'VIZZAVI ESPAÑA, S.L.',
  23: 'LYCAMOBILE, S.L.',
  24: 'FONYOU TELECOM, S.L.',
  25: 'TELECABLE DE ASTURIAS, S.A.U.',
  30: 'Call Forwarding',
  31: 'Call Forwarding to other',
  41: 'Mobile Data',
  51: 'SMS national and international',
  52: 'SMS to other operator',
  53: 'Roaming SMS (MO)',
  63: '80x special numbers',
  64: 'Service numbers 20-30',
  65: 'Tariff per minute numbers',
  66: 'Special tariff short numbers',
  67: 'Special tariff short numbers',
  68: 'Special tariff short numbers',
  69: 'Service numbers 60-30-30',
  71: 'Premium SMS (MO)',
  72: 'Premium SMS (MT)',
  78: 'Premium Wap',
  80: 'Xfera own net',
  81: 'Voice National',
  89: 'VIDEO call',
  92: 'GPRS Roaming (MO)',
  93: 'GPRS national (MO)',
  94: 'MMS national',
  95: 'MMS Roaming (MO)',
  96: 'MMS Roaming (MT)',
  97: 'MMS Intern. dest.',
  98: 'Voice mail calls',
  102: 'Contrato2 Voice Flat rate',
  103: 'Contrato2 SMS Flat rate',
  104: 'MMS e-mail',
  160: 'Somalia',
  241: 'Cuba',
  641: 'Cuba - Mobile',
  722: 'Korea, PDR (North) - Mobile',
  801: 'Roaming EU-Int',
  802: 'Roaming Int-EU',
  888: 'e-mail',
  899: 'International leg',
  901: 'Yoigo customer care',
  902: 'VODAFONE',
  903: 'ORANGE',
  904: 'TELEFONICA',
  950: 'Unknown calls',
  999: 'Invalid ticket',
}

export const EXPIRED_STATUS = 'Expirado'
