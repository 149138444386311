import PropTypes from 'prop-types'
import styled from 'styled-components'
import { formatMonthlyFee } from 'utils'
import { formatDuration, formatGB } from '../helpers'
import { useTaxApplied } from '../hooks/useTaxApplied'
import { UNLIMITED_DURATION } from '../constants'

const ModWrapper = styled.div`
  margin-bottom: 25px;
`

const ModTitle = styled.h2`
  margin: 0 8px 12px;
  font-size: 16px;
  font-weight: bold;
  color: #4d4e4f;
`

const ModItem = styled.div`
  background: #fcfcfc;
  border-radius: 10px;
  padding: 16px 21px;
  color: #b9c2c7;
  font-size: 12px;
  font-weight: bold;
  span {
    display: block;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.14;
    color: #4d4e4f;
  }
  p {
    margin: 0;
  }
  p + p {
    margin: 13px 0 0;
  }
  & + & {
    margin-top: 24px;
  }
`
const SubscriptionModification = ({
  id,
  name,
  bonusData,
  bonusDuration,
  discountDuration,
  duration,
  penalty,
  ...props
}) => {
  const { discountFee, promoFee, fee } = useTaxApplied({
    discountFee: props.discountFee,
    promoFee: props.promoFee,
    fee: props.fee,
  })

  return (
    <ModItem key={id}>
      <p>
        Número: <span>{id}</span>
      </p>
      <p>
        Cambio realizado:{' '}
        <span>
          {name}
          {Boolean(bonusData) && ` + ${formatGB(bonusData)} x ${formatDuration(bonusDuration)}`}.
        </span>
      </p>
      {Boolean(discountFee && discountDuration) && (
        <p>
          Cuota promocional:{' '}
          <span>
            {`${
              discountDuration === UNLIMITED_DURATION
                ? 'Para siempre'
                : formatDuration(discountDuration)
            } x ${formatMonthlyFee(discountFee)}`}
            .
          </span>
        </p>
      )}
      {Boolean(fee) && (
        <p>
          Cuota{promoFee ? ' sin promoción' : ''}: <span>{formatMonthlyFee(fee)}.</span>
        </p>
      )}
      {Boolean(duration) && (
        <p>
          Permanencia: <span>{duration} meses máximo</span>
        </p>
      )}
      {penalty && (
        <p>
          Penalización: <span>{penalty} €</span>
        </p>
      )}
    </ModItem>
  )
}

const SubscriptionsModifications = props => (
  <ModWrapper>
    <ModTitle>Vas a modificar:</ModTitle>
    {props.modifiedSubscriptions.length ? (
      props.modifiedSubscriptions.map(subscription => (
        <SubscriptionModification key={subscription.id} {...subscription} />
      ))
    ) : (
      <ModItem />
    )}
  </ModWrapper>
)

SubscriptionModification.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  bonusData: PropTypes.number,
  bonusDuration: PropTypes.number,
  discountFee: PropTypes.number,
  discountDuration: PropTypes.number,
  fee: PropTypes.number,
  promoFee: PropTypes.number,
  duration: PropTypes.number,
  penalty: PropTypes.number,
}

SubscriptionsModifications.propTypes = {
  modifiedSubscriptions: PropTypes.array,
}

export default SubscriptionsModifications
