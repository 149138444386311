import { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Select, MenuItem, FormControl, Typography, Grid } from '@material-ui/core'
import { SpinnerCenter } from 'components/ui'
import { CardRow } from 'components'
import { getAmountsList, getDurationList, mapDiscounts, mapAmounts, mapDurations } from './helpers'

const SelectorsWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 25px 0;
`
const SelectorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: ${props => props.width || 'auto'};
`

const MenuItemText = styled.p`
  text-overflow: ellipsis;
  overflow: hidden;
`

class DiscountsSelector extends Component {
  state = {
    discountCode: '',
    amount: '',
    unit: '',
    duration: '',
    description: '',
  }

  updateDiscount = discountCode => {
    this.setState({
      discountCode,
      amount: '',
      unit: '',
      duration: '',
      description: '',
    })
    this.resetSelectedDiscount()
  }

  updateAmount = objAmount => {
    const { value, unit } = JSON.parse(objAmount)
    this.setState({
      duration: '',
      description: '',
      amount: value,
      unit,
    })
    this.resetSelectedDiscount()
  }

  updateDuration = objDuration => {
    const { value, description } = JSON.parse(objDuration)

    const fullDiscount = {
      ...this.state,
      duration: value,
      description,
    }

    this.setState(fullDiscount)

    this.props.onSelectDiscountChange(fullDiscount)
  }

  resetSelectedDiscount = () => {
    this.props.onResetSelectedDiscount()
  }

  buildSelector = ({ label, width, items, value, name, onChangeHandler }) => (
    <SelectorWrapper width={width}>
      <CardRow>
        <Typography variant="body1">{label}:</Typography>
      </CardRow>
      <FormControl>
        <Select
          value={value}
          inputProps={{ name, id: `${name}Id` }}
          onChange={e => onChangeHandler(e.target.value)}
          placeholder="Selecciona un valor..."
          displayEmpty>
          <MenuItem value="" disabled>
            <em>Selecciona un valor...</em>
          </MenuItem>
          {items.map(item => (
            <MenuItem key={item.id} value={item.value}>
              <MenuItemText>{item.caption}</MenuItemText>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </SelectorWrapper>
  )

  selectorDiscount = () => {
    const { discountsAgent } = this.props
    const { discountCode } = this.state

    if (discountsAgent) {
      return this.buildSelector({
        label: 'Código',
        width: '50%',
        items: mapDiscounts(discountsAgent),
        value: discountCode,
        name: 'discount',
        onChangeHandler: this.updateDiscount,
      })
    }

    return null
  }

  selectorAmount = () => {
    const { discountsAgent } = this.props
    const { discountCode, amount, unit } = this.state
    const amountsList = getAmountsList(discountsAgent, discountCode) || []

    if (amountsList.length > 1) {
      return this.buildSelector({
        label: 'Valor',
        width: '25%',
        items: mapAmounts(amountsList),
        value: JSON.stringify({ value: amount, unit }),
        name: 'amount',
        onChangeHandler: this.updateAmount,
      })
    }

    if (amountsList.length === 1 && !amount) {
      this.updateAmount(JSON.stringify({ value: amountsList[0].value, unit: amountsList[0].unit }))
    }

    return null
  }

  selectorDuration = () => {
    const { discountsAgent } = this.props
    const { discountCode, amount, unit, duration, description } = this.state
    const durationsList =
      getDurationList(discountsAgent, discountCode, {
        value: amount,
        unit,
      }) || []

    if (durationsList.length > 1) {
      return this.buildSelector({
        label: 'Duración',
        width: '25%',
        items: mapDurations(durationsList),
        value: JSON.stringify({ value: duration, description }),
        name: 'duration',
        onChangeHandler: this.updateDuration,
      })
    }

    if (durationsList.length === 1 && !duration) {
      this.updateDuration(
        JSON.stringify({
          value: durationsList[0].value,
          description: durationsList[0].description,
        }),
      )
    }

    return null
  }

  renderSelectors = () => {
    const { discountCode, amount } = this.state
    return (
      <>
        <Typography variant="body2">Selecciona un descuento:</Typography>
        <SelectorsWrapper>
          {this.selectorDiscount()}
          {discountCode && this.selectorAmount()}
          {amount && this.selectorDuration()}
        </SelectorsWrapper>
      </>
    )
  }

  render() {
    const { loading } = this.props

    return (
      <Grid direction="column" alignItems="baseline" container>
        {loading ? <SpinnerCenter /> : this.renderSelectors()}
      </Grid>
    )
  }
}

DiscountsSelector.propTypes = {
  loading: PropTypes.bool,
  discountsAgent: PropTypes.array,
  onSelectDiscountChange: PropTypes.func.isRequired,
  onResetSelectedDiscount: PropTypes.func.isRequired,
}

DiscountsSelector.defaultProps = {
  loading: false,
  discountsAgent: [],
}

export default DiscountsSelector
