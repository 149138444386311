import { actionTypes } from 'modules/typifications/constants'

const initialState = {
  data: {
    accessToken: null,
    agentToken: null,
    sfid: null,
    uuid: null,
  },
  error: null,
  loading: false,
}

export function authTypificationsReducer(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case actionTypes.LOGIN_AGENT_INIT: {
      return {
        ...state,
        data: {
          sfid: payload.sfid,
          uuid: payload.uuid,
        },
        loading: true,
      }
    }

    case actionTypes.LOGIN_AGENT_SUCCESS: {
      return {
        data: { ...state.data, ...payload },
        loading: false,
      }
    }

    case actionTypes.LOGIN_AGENT_FAILED: {
      return {
        ...state,
        error: payload,
        loading: false,
      }
    }

    default:
      return state
  }
}
