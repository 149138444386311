import { useMemo } from 'react'
import PropTypes from 'prop-types'

import { Modal } from '../Modal'

import { CANCEL_OPERATION, CHANGE_ADDRESS } from '../../constants/cancellations'

const CancelOrderModal = ({ operationType, onClose, onConfirm, open }) => {
  const { orderButton, title } = useMemo(() => {
    if (operationType === CANCEL_OPERATION) {
      return {
        orderButton: 'Sí, cancelación',
        title: 'Vas a confirmar la cancelación, ¿Estás seguro?',
      }
    }
    if (operationType === CHANGE_ADDRESS) {
      return {
        title: 'No se pudo actualizar la orden. Se ha cancelado.',
      }
    }
    return { orderButton: 'Sí, retener', title: 'Vas a confirmar la retención, ¿Estás seguro?' }
  }, [operationType])

  return (
    <Modal
      open={open}
      cancelBtn="Atrás"
      orderButton={orderButton}
      sendOrder={onConfirm}
      handleClose={onClose}
      title={title}
    />
  )
}

CancelOrderModal.propTypes = {
  operationType: PropTypes.string,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  open: PropTypes.bool,
}

export default CancelOrderModal
