import PropTypes from 'prop-types'
import styled from 'styled-components'
import { range, get } from 'lodash'

import { Icon } from '@material-ui/core'
import { ICONS } from 'utils/icons'

export const SurveyBanner = styled(({ className, rating = 0, maxRating = 5 }) => {
  const rate = Math.max(rating, 0)

  return (
    <div className={`${className} survey-banner`}>
      {range(maxRating).map(i =>
        i < rate ? (
          <Icon className="icon filled">{ICONS.star}</Icon>
        ) : (
          <Icon className="icon outlined">{ICONS.star}</Icon>
        ),
      )}
    </div>
  )
})`
  display: flex;
  align-items: center;

  > .icon {
    font-size: 16px;

    &.filled {
      color: ${props => get(props, 'theme.palette.bluePalette.main')};
    }

    &.icon.outlined {
      color: ${props => get(props, 'theme.palette.global.gray_medium_2')};
    }
  }
`

SurveyBanner.propTypes = {
  className: PropTypes.string,
  rating: PropTypes.number,
  maxRating: PropTypes.number,
}
