export const CVMActions = {
  change_tariff: {
    id: 'change_tariff',
    desc: 'Change Tariff',
  },

  commercial_migration: {
    id: 'commercial_migration',
    desc: 'Commercial Migration',
  },

  add_mobile_line: {
    id: 'add_mobile_line',
    desc: 'Añadir línea adicional con descuento',
  },

  change_address: {
    id: 'change_address',
    dessc: 'Cambio de domicilio',
  },

  add_discounts_or_bundles: {
    id: 'add_discounts_or_bundles',
    desc: 'Add discounts or bundles',
  },

  add_multisim_discount: {
    id: 'add_multisim_discount',
    desc: 'Add multisim discounts',
  },

  add_sva: {
    id: 'add_sva',
    desc: 'Añadir SVA',
  },

  disable_discounts_or_bundles: {
    id: 'disable_discounts_or_bundles',
    desc: 'Remove discounts or bundles',
  },

  disable_multisim_discounts: {
    id: 'disable_multisim_discounts',
    desc: 'Remove multisim discounts',
  },

  modify_fixed_permanency: {
    id: 'modify_fixed_permanency',
    desc: 'Modify fixed permanency',
  },

  modify_mobile_permanency: {
    id: 'modify_mobile_permanency',
    desc: 'Modify mobile permanency',
  },

  freeze: {
    id: 'freeze',
    desc: 'Freeze',
  },

  break: {
    id: 'break',
    desc: 'Break',
  },

  convertMobileSubToConvergent: {
    id: 'convert_sub_to_convergent',
    desc: 'Convert mobile only subscription to Convergent',
  },

  unsubscribe: {
    id: 'unsubscribe',
    desc: 'Unsubscribe',
  },

  additionalOps: {
    id: 'additionalOps',
    desc: 'Opciones Adicionales',
  },

  add_multi_sim: {
    id: 'add_multi_sim',
    desc: 'Añadir MultiSIM',
  },
}
