import {
  get,
  find,
  filter,
  chain,
  setWith,
  cloneDeep,
  map,
  flatMap,
  head,
  isEmpty,
  has,
} from 'lodash'
import { applyTax } from 'services/taxes'
import { formatNumberWithComma } from 'utils'
import { MAIN_DUO_DISCOUNT_ID } from 'modules/offers-configuration/constants'

/*
 * @param productName: string (Fibra 300Mb + Sinfín 25)
 * @param index: number (0 or 1 for each slice)
 * @returns string (Fibra 300Mb || Sinfín 25)
 */
export const getTariffName = (productName, index) => {
  if (productName.length < 1) return ''
  return productName[index]
}

export const getOwner = data =>
  data
    ? data.find(
        spec => spec.name === 'mobile_donor_operator' || spec.name === 'donor_operator_name',
      )
    : null

/*
 * @param spect: Array of order/products/product/productsSpecifications/productSpecCharacteristic
 * @returns string (Telefonica, Orange...)
 */
export const getPortabilityOwner = portabilityOwner =>
  portabilityOwner ? portabilityOwner.value.split(' ')[0] : null

/*
 * @param spect: Array of order/products/product/productsSpecifications/productSpecCharacteristic
 * @returns string 'Alta nueva || Portabilidad'
 */
export const getLineType = (specs, chars) => {
  const portabilityOwner = getOwner(specs) || getOwner(chars)

  return get(portabilityOwner, 'value', false)
    ? `Portabilidad (${getPortabilityOwner(portabilityOwner)})`
    : 'Alta nueva'
}

export const checkIfItsConvergent = specs =>
  specs.length < 0 ? false : !!find(get(...specs, 'productsSpecifications'), { name: 'fixed' })

const getFixedStatus = productSpecCharacteristic =>
  chain(productSpecCharacteristic)
    .find({ name: 'fixed_status' })
    .get('value')
    .value()

/*
 * @param products: Array of order/products/product/productsSpecifications
 * @param productName: string (Fibra 300Mb + Sinfín 25)
 * @returns Array of mapped lines
 */
export const fromApiToProductLines = (lines, chars, productName) => {
  const indexLine = lines.findIndex(elem => elem.name === 'mobile')
  if (indexLine === 0) lines.reverse()

  return lines.map(({ name, id, productSpecCharacteristic }) => ({
    category: name,
    tariffName: productName,
    id,
    type: getLineType(chars, productSpecCharacteristic),
    fixedStatus: getFixedStatus(productSpecCharacteristic),
  }))
}

export const getLinePenalty = penalties => {
  if (!penalties || penalties.length === 0) {
    return {}
  }

  return penalties
    .map(penalty => {
      const productName = penalty.product.includes('fixed') ? 'fixed' : 'mobile'
      return { [productName]: penalty?.taxIncludedAmount || penalty?.taxFreeAmount || 0 }
    })
    .reduce((curr, next) => ({ ...curr, ...next }))
}

export const getFormattedRetentionOptions = retentionOptions =>
  retentionOptions && retentionOptions.map(option => get(option, 'text'))

export const getProductType = p =>
  chain(p)
    .flatMap('type')
    .value()

export const getContactMediumFixedLine = (contact, newAddress) => {
  const contactCustomer = contact.find(x => x.type === 'InstallationAddressFixedLine') || {}
  const fixedLine = cloneDeep(contactCustomer)
  setWith(fixedLine, 'medium.city', get(newAddress, 'town'))
  setWith(fixedLine, 'medium.postCode', get(newAddress, 'zipCode'))
  setWith(
    fixedLine,
    'medium.streetOne',
    `${get(newAddress, 'streetType')} ${get(newAddress, 'street')}`,
  )
  setWith(
    fixedLine,
    'medium.streetTwo',
    get(newAddress, 'block') +
      get(newAddress, 'floor') +
      get(newAddress, 'door') +
      get(newAddress, 'Hand') +
      get(newAddress, 'letter'),
  )
  const characteristic = []

  characteristic.push({
    name: 'streetType',
    value: get(newAddress, 'streetType'),
  })
  characteristic.push({
    name: 'streetNumber',
    value: get(newAddress, 'number'),
  })
  characteristic.push({ name: 'province', value: get(newAddress, 'province') })
  characteristic.push({ name: 'gescal', value: get(newAddress, 'gescal') })
  Object.assign(fixedLine.medium.characteristic, characteristic)
  return [fixedLine]
}

export const isB2C = idType => (idType === 'NIF' || idType === 'NIE' ? 'B2C' : 'B2B')

export function getProductCharacteristic(products, characteristic) {
  const productSpecs = flatMap(products, product => get(product, 'productsSpecifications'))
  const productSpecsChar = flatMap(productSpecs, spec => get(spec, 'productSpecCharacteristic'))
  const selectedChar = filter(productSpecsChar, { name: characteristic })
  return map(selectedChar, char => get(char, 'value'))
}

export const getDiscountText = (offerSubscription, taxRate) => {
  const discount_plans = head(get(offerSubscription, 'discount_plans'))

  let id
  let unit
  let amount
  let discountWithoutTax

  if (has(discount_plans, 'unit')) {
    ;({ id, unit, amount } = discount_plans)
  } else {
    const promotion = head(get(offerSubscription, 'tariff.promotions', {}))
    id = get(promotion, 'id')
    const promotionsAttributes = get(promotion, 'attributes', {})
    ;({ unit, amount } = promotionsAttributes)
  }

  if (unit === 'percents' || unit === 'Percentage') {
    unit = '%'
  } else {
    unit = '€'
    discountWithoutTax = id === MAIN_DUO_DISCOUNT_ID
    amount = discountWithoutTax ? amount : applyTax(taxRate, amount)
  }

  return `Línea adicional con ${formatNumberWithComma(amount)}${unit} de descuento ${
    discountWithoutTax ? 'sin impuestos incluidos' : ''
  }`
}

export const parentOrderIdStr = (parentOrderId, offerSubscription, taxRate) => {
  let lineTypeTitle

  if (parentOrderId === 0) {
    lineTypeTitle = 'Línea cabecera'
  } else if (isEmpty(offerSubscription)) {
    lineTypeTitle = 'Línea adicional'
  } else {
    const mainOfferSubscription = head(offerSubscription)
    lineTypeTitle = getDiscountText(mainOfferSubscription, taxRate)
  }

  return lineTypeTitle
}

export const getFixedNumberOnFlySubscription = subscription => {
  const fixed = get(subscription, 'fixed', [])
  return head(fixed)?.id
}
