import { createStructuredSelector, createSelector } from 'reselect'

import {
  selectIsCrossSellAllowed,
  selectIsCrossAllowedLoading,
} from 'services/cross-sell/selectors'
import {
  getIdentificationId,
  getIdentificationType,
  selectIsSelfEmployed,
  getSegment,
} from 'services/customer-360/selectors'
import { selectQueryParams } from 'modules/Router/store/index'

const selectSaleInformation = createSelector(
  getIdentificationId,
  getIdentificationType,
  selectIsSelfEmployed,
  getSegment,
  selectQueryParams,
  (docNumber, docType, selfEmployed, segment, { channel }) => ({
    docNumber,
    docType,
    selfEmployed:
      (!!selfEmployed && selfEmployed !== 'false') ||
      (segment || '').toLowerCase().includes('autonomo'),
    segment: (segment || '').toLowerCase().includes('pro') ? 'pro' : 'residential',
    channel,
  }),
)

export const selectCrossSellModal = createStructuredSelector({
  saleInformation: selectSaleInformation,
  isCrossSellAllowed: selectIsCrossSellAllowed,
  isLoading: selectIsCrossAllowedLoading,
})
