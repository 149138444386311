import PropTypes from 'prop-types'
import { isConvergentSubscription } from 'services/subscriptions'
import { OffersCard, OfferHeader, OfferContent, OfferItemLoading } from 'components/ui'
import { convergentCardWidth, cardWidth } from './OldSubscriptionCard'

export function SubscriptionCardLoading({ subscription }) {
  const isConvergent = isConvergentSubscription(subscription)
  return (
    <OffersCard width={`${isConvergent ? convergentCardWidth : cardWidth}px`}>
      <OfferHeader />
      <OfferContent multicolumn={isConvergent}>
        <OfferItemLoading />
        <OfferItemLoading />
        <OfferItemLoading />
        <OfferItemLoading />
        {isConvergent && (
          <>
            <OfferItemLoading />
            <OfferItemLoading />
          </>
        )}
      </OfferContent>
    </OffersCard>
  )
}

SubscriptionCardLoading.propTypes = {
  subscription: PropTypes.object,
}
