import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'

import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import { DoubleLineItemCard, LineItemCard, TitleLineItemCard, OutlinedDivider } from 'components/ui'
import { Card } from 'modules/ui'

const PADDING_ELEMENT = '9px 24px'

const useStyles = makeStyles(() => ({
  brandHeading: {
    paddingBottom: '0',
    textTransform: 'capitalize',
    '& p.primary-text': {
      textTransform: 'none',
    },
  },
}))

export function ContactCard({ data: { address, mail, telephones, rrss, username }, menuActions }) {
  const classes = useStyles()

  return (
    <>
      <Card
        title="Contacto"
        id="Contacto"
        menuButton={menuActions ? { btnText: 'Opciones', menuActions } : null}
        withShadow>
        <DoubleLineItemCard
          primaryText="Dirección"
          secondaryText={address}
          padding={PADDING_ELEMENT}
        />

        <DoubleLineItemCard primaryText="Dirección email" secondaryText={mail} />

        {!isEmpty(telephones) && (
          <Box p={PADDING_ELEMENT}>
            <OutlinedDivider />
          </Box>
        )}
        {!isEmpty(telephones) &&
          telephones.map((el, i) => (
            <LineItemCard
              primaryText={`Teléfono ${i + 1}`}
              secondaryText={el}
              key={`${el}-${i.toString()}`}
            />
          ))}

        {!isEmpty(rrss) && (
          <Box p={PADDING_ELEMENT}>
            <OutlinedDivider />
          </Box>
        )}
        {!isEmpty(rrss) &&
          Object.keys(rrss).map(el => (
            <LineItemCard primaryText={el} secondaryText={rrss[el]} key={el} bottomDivider />
          ))}

        <Box p={PADDING_ELEMENT}>
          <OutlinedDivider />
        </Box>

        <TitleLineItemCard primaryText="Mi Yoigo" className={classes.brandHeading} isHeader />
        <DoubleLineItemCard primaryText="Usuario" secondaryText={username} />
      </Card>
    </>
  )
}

ContactCard.propTypes = {
  data: PropTypes.shape({
    address: PropTypes.string,
    telephones: PropTypes.array,
    rrss: PropTypes.object,
    mail: PropTypes.string,
    username: PropTypes.string,
  }),
  menuActions: PropTypes.array,
}
