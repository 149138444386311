import { actionTypes } from './actions'

const initialState = {
  subscriptions: {},
  error: null,
  loading: false,
  typificationOpen: false,
  permanenceModifications: {},
  applicableDiscounts: {},
  disableDiscounts: {},
  setSvas: {},
  tariffsV3: {
    tariffs: [],
    loading: false,
    error: null,
  },
  orderElegibility: {
    data: {},
    loading: false,
    error: null,
  },
  orderConsequences: {
    data: {},
    loading: false,
    error: null,
  },
  parentTechnology: {
    id: null,
    loading: false,
    error: null,
  },
  coverageTechnology: {
    id: null,
    loading: false,
    error: null,
  },
  commercialMigration: {
    loading: false,
    error: null,
    data: null,
  },
  changeAddress: {
    loading: false,
    error: null,
    data: null,
  },
  changeTechnology: {
    loading: false,
    error: null,
    data: null,
  },
}

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.OPEN_TYPIFICATION:
      return {
        ...state,
        typificationOpen: true,
      }

    case actionTypes.CLOSE_TYPIFICATION:
      return {
        ...state,
        typificationOpen: false,
      }

    case actionTypes.UPDATE_SUBSCRIPTIONS:
      return {
        ...state,
        loading: true,
      }

    case actionTypes.UPDATE_SUBSCRIPTION:
      return {
        ...state,
        subscriptions: {
          ...state.subscriptions,
          [action.payload.msisdn]: {
            ...state.subscriptions[action.payload.msisdn],
            ...action.payload.data,
          },
        },
      }

    case actionTypes.CLEAR_SUBSCRIPTION:
      return {
        ...state,
        subscriptions: {
          ...state.subscriptions,
          [action.payload.msisdn]: {},
        },
      }

    case actionTypes.CLEAR_ALL_SUBSCRIPTIONS:
      return {
        ...state,
        subscriptions: {},
      }

    case actionTypes.SET_ERROR:
      return { ...state, error: action.payload }

    case actionTypes.SET_FINISHED:
      return {
        ...state,
        loading: false,
      }

    case actionTypes.CLEAR_ERROR:
      return { ...state, error: null }

    case actionTypes.NOTIFY_PERMANENCE_MODIFICATION_FAILED: {
      return {
        ...state,
        permanenceModifications: {
          ...state.permanenceModifications,
          [action.payload.msisdn]: {
            loading: false,
            error: action.payload.error,
          },
        },
      }
    }

    case actionTypes.INIT_TARIFFS_V3: {
      return {
        ...state,
        tariffsV3: {
          loading: true,
        },
      }
    }

    case actionTypes.FETCH_TARIFFS_V3: {
      return {
        ...state,
        tariffsV3: {
          tariffs: action.payload.tariffs,
          loading: true,
        },
      }
    }

    case actionTypes.FETCH_TARIFFS_SUCCESS_V3: {
      return {
        ...state,
        tariffsV3: {
          ...state.tariffsV3,
          loading: false,
        },
      }
    }

    case actionTypes.FETCH_TARIFFS_FAILED_V3: {
      return {
        ...state,
        tariffsV3: {
          loading: false,
          error: action.payload,
        },
      }
    }

    case actionTypes.INIT_ORDER_ELEGIBILITY: {
      return {
        ...state,
        orderElegibility: {
          loading: true,
        },
      }
    }

    case actionTypes.FETCH_ORDER_ELEGIBILITY: {
      return {
        ...state,
        orderElegibility: {
          data: action.payload.response.data,
          loading: true,
        },
      }
    }

    case actionTypes.FETCH_ORDER_SUCCESS_ELEGIBILITY: {
      return {
        ...state,
        orderElegibility: {
          ...state.orderElegibility,
          loading: false,
        },
      }
    }

    case actionTypes.FETCH_ORDER_FAILED_ELEGIBILITY: {
      return {
        ...state,
        orderElegibility: {
          loading: false,
          error: action.payload,
        },
      }
    }

    case actionTypes.FETCH_ORDER_RESET_ELEGIBILITY: {
      return {
        ...state,
        orderElegibility: initialState.orderElegibility,
      }
    }

    case actionTypes.INIT_ORDER_CONSEQUENCES: {
      return {
        ...state,
        orderConsequences: {
          loading: true,
        },
      }
    }

    case actionTypes.FETCH_ORDER_CONSEQUENCES: {
      return {
        ...state,
        orderConsequences: {
          data: action.payload.response.data,
          loading: true,
        },
      }
    }

    case actionTypes.FETCH_ORDER_SUCCESS_CONSEQUENCES: {
      return {
        ...state,
        orderConsequences: {
          ...state.orderConsequences,
          loading: false,
        },
      }
    }

    case actionTypes.FETCH_ORDER_CONSEQUENCES_RESET: {
      return {
        ...state,
        orderConsequences: initialState.orderConsequences,
      }
    }

    case actionTypes.FETCH_ORDER_FAILED_CONSEQUENCES: {
      return {
        ...state,
        orderConsequences: {
          loading: false,
          error: action.payload,
        },
      }
    }

    case actionTypes.INIT_PARENT_TECHNOLOGY: {
      return {
        ...state,
        parentTechnology: {
          loading: true,
        },
      }
    }

    case actionTypes.INIT_COVERAGE_TECHNOLOGY: {
      return {
        ...state,
        coverageTechnology: {
          loading: true,
        },
      }
    }

    case actionTypes.FETCH_PARENT_TECHNOLOGY_SUCCESS: {
      return {
        ...state,
        parentTechnology: {
          ...state.parentTechnology,
          id: action.payload,
          loading: false,
        },
      }
    }

    case actionTypes.FETCH_COVERAGE_TECHNOLOGY_SUCCESS: {
      return {
        ...state,
        coverageTechnology: {
          ...state.coverageTechnology,
          id: action.payload,
          loading: false,
        },
      }
    }

    case actionTypes.FETCH_PARENT_TECHNOLOGY_FAILED: {
      return {
        ...state,
        parentTechnology: {
          loading: false,
          error: action.payload,
        },
      }
    }

    case actionTypes.FETCH_COVERAGE_TECHNOLOGY_FAILED: {
      return {
        ...state,
        coverageTechnology: {
          loading: false,
          error: action.payload,
        },
      }
    }

    case actionTypes.NOTIFY_DISCOUNT_APPLICATION_FAILED: {
      const { msisdn, discountCode } = action.payload
      return {
        ...state,
        applicableDiscounts: {
          ...state.applicableDiscounts,
          [msisdn]: {
            ...state.applicableDiscounts[msisdn],
            [discountCode]: {
              loading: false,
              error: action.payload.error,
            },
          },
        },
      }
    }

    case actionTypes.NOTIFY_DISCOUNTS_DISABLE_ATTEMPTED: {
      return {
        ...state,
        disableDiscounts: {
          loading: true,
          succeededDiscounts: [],
          failedDiscounts: [],
          error: null,
        },
      }
    }

    case actionTypes.NOTIFY_DISCOUNTS_DISABLE_SUCCEEDED: {
      const { msisdn, succeededDiscounts } = action.payload

      return {
        ...state,
        disableDiscounts: {
          loading: false,
          msisdn,
          succeededDiscounts,
          failedDiscounts: [],
          error: null,
        },
      }
    }

    case actionTypes.NOTIFY_DISCOUNTS_DISABLE_FAILED: {
      const { msisdn, succeededDiscounts, failedDiscounts, error } = action.payload

      return {
        ...state,
        disableDiscounts: {
          loading: false,
          msisdn,
          succeededDiscounts,
          failedDiscounts,
          error,
        },
      }
    }

    case actionTypes.NOTIFY_DISCOUNTS_DISABLE_RESET: {
      return {
        ...state,
        disableDiscounts: {
          loading: false,
          msisdn: null,
          succeededDiscounts: [],
          failedDiscounts: [],
          error: null,
        },
      }
    }

    case actionTypes.NOTIFY_SET_SVAS_ATTEMPTED: {
      return {
        ...state,
        setSvas: {
          loading: true,
          succeededSvas: [],
          failedSvas: [],
          error: null,
        },
      }
    }

    case actionTypes.NOTIFY_SET_SVAS_SUCCEEDED: {
      const { msisdn, succeededSvas } = action.payload

      return {
        ...state,
        setSvas: {
          loading: false,
          msisdn,
          succeededSvas,
          failedSvas: [],
          error: null,
        },
      }
    }

    case actionTypes.NOTIFY_SET_SVAS_FAILED: {
      const { msisdn, succeededSvas, failedSvas, error } = action.payload

      return {
        ...state,
        setSvas: {
          loading: false,
          msisdn,
          succeededSvas,
          failedSvas,
          error,
        },
      }
    }

    case actionTypes.NOTIFY_SET_SVAS_RESET: {
      return {
        ...state,
        setSvas: {
          loading: false,
          msisdn: null,
          succeededSvas: [],
          failedSvas: [],
          error: null,
        },
      }
    }

    case actionTypes.INIT_COMMERCIAL_MIGRATION: {
      return {
        ...state,
        commercialMigration: {
          loading: true,
          data: null,
          error: null,
        },
      }
    }

    case actionTypes.FETCH_COMMERCIAL_MIGRATION_SUCCEDED: {
      return {
        ...state,
        commercialMigration: {
          loading: false,
          data: action.payload,
          error: null,
        },
      }
    }

    case actionTypes.FETCH_COMMERCIAL_MIGRATION_FAILED: {
      return {
        ...state,
        commercialMigration: {
          loading: false,
          error: action.payload,
          data: null,
        },
      }
    }

    case actionTypes.INIT_CHANGE_ADDRESS: {
      return {
        ...state,
        changeAddress: {
          loading: true,
          data: null,
          error: null,
        },
      }
    }

    case actionTypes.INIT_CHANGE_TECHNOLOGY: {
      return {
        ...state,
        changeTechnology: {
          loading: true,
          data: null,
          error: null,
        },
      }
    }

    case actionTypes.INIT_INSTALLER: {
      return {
        ...state,
        installer: {
          loading: true,
          data: null,
          error: null,
        },
      }
    }

    case actionTypes.FETCH_CHANGE_ADDRESS_SUCCEDED: {
      return {
        ...state,
        changeAddress: {
          loading: false,
          data: action.payload,
          error: null,
        },
      }
    }

    case actionTypes.FETCH_CHANGE_TECHNOLOGY_SUCCEDED: {
      return {
        ...state,
        changeTechnology: {
          loading: false,
          data: action.payload,
          error: null,
        },
      }
    }

    case actionTypes.FETCH_INSTALLER_SUCCEDED: {
      return {
        ...state,
        installer: {
          loading: false,
          installer: action.payload.installer,
          connectionType: action.payload.connectionType,
          error: null,
        },
      }
    }

    case actionTypes.FETCH_CHANGE_ADDRESS_FAILED: {
      return {
        ...state,
        changeAddress: {
          loading: false,
          error: action.payload,
          data: null,
        },
      }
    }

    case actionTypes.FETCH_CHANGE_TECHNOLOGY_FAILED: {
      return {
        ...state,
        changeTechnology: {
          loading: false,
          error: action.payload,
          data: null,
        },
      }
    }

    case actionTypes.FETCH_INSTALLER_FAILED: {
      return {
        ...state,
        installer: {
          loading: false,
          error: action.payload,
          data: null,
        },
      }
    }

    default:
      return state
  }
}

export default reducer
