import { useFormikContext } from 'formik'
import { useSerialRouterNumberValidation } from 'modules/NewClientSales/hooks/Validations/useSerialRouterNumberValidation'
import { landlineRegex } from 'utils'

import { useIsCustomerPhoneValidation, useOperatorValidation } from '../../../hooks/Validations'

export function useLandlineValidation({ lineType }) {
  const {
    validateCustomer,
    errorCustomer,
    hasMsErrorCustomer,
    fieldCustomer,
    valueCustomer,
  } = useIsCustomerPhoneValidation(lineType)

  const { validateOperator, hasMsOperatorError, errorOperator } = useOperatorValidation(lineType)
  const { validateSerialRouterNumber } = useSerialRouterNumberValidation()

  const { setStatus, status, setFieldValue } = useFormikContext()

  async function validateLandline(value, fromSignUp) {
    const customerValidation = await validateCustomer(value, landlineRegex, fromSignUp)
    const operatorValidation = await validateOperator(value, landlineRegex, fromSignUp)

    const validation = customerValidation || operatorValidation

    setStatus({ ...status, [`${lineType}.lineNumber`]: validation })

    return validation
  }

  async function validateRouterNumber(routerNumberValue) {
    const routerStatus = await validateSerialRouterNumber(routerNumberValue)
    setFieldValue(`${lineType}.routerValidationMessage`, routerStatus)
  }

  return {
    validateLandline,
    validateRouterNumber,
    errorMs: errorCustomer || errorOperator,
    hasMsError: hasMsErrorCustomer || hasMsOperatorError,
    fieldName: fieldCustomer,
    fieldValue: valueCustomer,
  }
}
