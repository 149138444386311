import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import { selectIsElFijo } from 'modules/NewClientSales/store'
import { selectIs2p, selectIsMobileOnly } from 'modules/Router/store/index'
import { formatNumberWithComma, mobileNumberRegex } from 'utils'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { HTMLTemplate } from 'modules/ui/components/HTMLTemplate/HTMLTemplate'

const useStyles = makeStyles(theme => ({
  'legal-texts': {
    '& div': {
      color: theme.palette.global.gray_dark,
    },

    '& p': {
      color: theme.palette.global.gray_dark,
      marginBottom: '24px',
    },

    '& strong': {
      fontWeight: 500,
    },

    '& ul > li': {
      marginBottom: '4px',
      lineHeight: '20px',
    },
  },
}))

const LegalTerminal = ({ legalTexts, terminal }) => {
  const {
    commissions,
    name,
    singlePayment,
    taxes,
    discounts,
    initialPayment,
    monthlyPayment,
    finalPayment,
    numOfPayments,
    computedPrices,
  } = terminal
  if (!name) return null

  if (commissions && commissions.total) {
    return (
      <HTMLTemplate
        html={legalTexts.legal_text_terminal_without_commissions}
        values={{
          name,
          permanency:
            computedPrices.permanency && computedPrices.permanency > 0
              ? computedPrices.permanency
              : '24',
          terminalDiscount: discounts.terminal,
        }}
      />
    )
  }

  return (
    <HTMLTemplate
      html={legalTexts.legal_text_terminal_with_commissions}
      values={{
        name,
        singlePayment,
        initialPayment,
        finalPayment,
        numOfPayments,
        monthlyPayment,
        taxes,
        commissions,
      }}
    />
  )
}
LegalTerminal.propTypes = {
  terminal: PropTypes.object.isRequired,
  legalTexts: PropTypes.object.isRequired,
}
LegalTerminal.defaultProps = {
  terminal: {
    commissions: {},
    singlePayment: 0,
    taxes: { tin: 0, tae: 0 },
    discounts: {
      terminal: 0,
      total: 0,
    },
    initialPayment: 0,
    monthlyPayment: 0,
    finalPayment: 0,
    numOfPayments: 0,
  },
  legalTexts: PropTypes.object.isRequired,
}

function LegalOfferSummary({ linesLegal, legalTexts, isElFijo, isPro, is2p }) {
  const products = linesLegal.products.map(product => (
    <div>
      <strong>
        {product.name} {product.amount > 1 ? `x${product.amount}` : ''}
      </strong>
    </div>
  ))

  const fees = linesLegal.monthlyPayments.map(payment => (
    <div>
      {payment.duration ? `Precio con descuento (${payment.duration} meses): ` : `Precio final: `}
      <strong>
        {formatNumberWithComma(payment.amount)} €/mes {isPro && '+ impuestos'}
      </strong>
    </div>
  ))
  return (
    <>
      <HTMLTemplate html={legalTexts.legal_offer_summary} />
      <Box mb="24px">{products}</Box>
      <Box mb="24px">{fees}</Box>
      {!isElFijo && !is2p && linesLegal && !isEmpty(linesLegal.terminals) && (
        <>
          <HTMLTemplate html={legalTexts.legal_terminal_summary_title} />
          {linesLegal.terminals.map((terminal, i) => (
            <HTMLTemplate
              key={`legal-terminal-${i.toString()}`}
              html={legalTexts.legal_terminal_summary}
              values={{
                terminalName: terminal.details.name,
                terminalInitialPayment: terminal.computedPrices.initialPayment || 0,
                terminalMonthlyPayment: terminal.computedPrices.monthlyPayment || 0,
                terminalFinalPayment: terminal.computedPrices.finalPayment || 0,
              }}
            />
          ))}
        </>
      )}
      {isElFijo && (
        <>
          {linesLegal.terminals.map(
            terminal =>
              terminal.computedPrices.permanency > 0 && (
                <HTMLTemplate
                  html={legalTexts.legal_text_terminal_without_commissions}
                  values={{
                    name: terminal.details.name,
                    permanency: terminal.computedPrices.peramnency,
                    terminalDiscount: terminal.computedPrices.discount,
                  }}
                />
              ),
          )}
        </>
      )}
    </>
  )
}
LegalOfferSummary.propTypes = {
  linesLegal: PropTypes.object.isRequired,
  legalTexts: PropTypes.object.isRequired,
  isElFijo: PropTypes.bool,
  isPro: PropTypes.bool,
  is2p: PropTypes.bool,
}

LegalOfferSummary.propTypes = {
  linesLegal: PropTypes.object.isRequired,
  legalTexts: PropTypes.object.isRequired,
  isElFijo: PropTypes.bool,
  isPro: PropTypes.bool,
  is2p: PropTypes.bool,
}

const LegalTerminals = ({ terminals, legalTexts }) => {
  return terminals.map(terminal => (
    <LegalTerminal
      terminal={terminal}
      key={`legalterminal-${terminal.name}`}
      legalTexts={legalTexts}
    />
  ))
}

export function LegalTexts({
  portaLinesWithDonor,
  portabilityLinesByTitular,
  fullName,
  documentid,
  currentDate,
  currentTime,
  fiberType,
  terminals = [],
  isFixedPortability,
  isFixedInternalPortability,
  isFixedInstallationRequired,
  linesLegal,
  agileTV: { hasAgileTV, isPromo, terms, hasAppleTv },
  hasSmartTv,
  permanencyTerms = {},
  isPro,
  isSubscription,
  showConfirm,
  legalTexts,
  legalTextsNoReturnalPenaltiesValues,
  selectedHomeGoAlarm,
}) {
  const classes = useStyles()

  const isElFijo = useSelector(selectIsElFijo)
  const isMobileOnly = useSelector(selectIsMobileOnly)
  const is2p = useSelector(selectIs2p)

  const { ffthRouterNoReturnalPenalty, adslRouterNoReturnalPenalty, agileTvNoReturnalPenalty } =
    legalTextsNoReturnalPenaltiesValues

  const showNoAdslText = !isElFijo && !isMobileOnly && fiberType !== 'adsl' && !isSubscription

  function getLineTypeTexts(phone) {
    const isMobile = mobileNumberRegex.test(phone)
    return {
      headerLineTypeText: isMobile ? 'MÓVIL' : 'FIJO',
      bodyLneTypeText: isMobile ? 'móvil' : 'fija',
    }
  }

  const agileTvNoReturnalPenaltyAmount = isPro
    ? agileTvNoReturnalPenalty.taxFree
    : agileTvNoReturnalPenalty.withTaxes

  return (
    <div className={classes['legal-texts']}>
      <HTMLTemplate
        data-hook="legal-text-ftth"
        html={isElFijo ? legalTexts.el_fijo_GDPR : legalTexts.legal_text_ftth}
        values={{
          fullName,
          documentid,
          currentDate,
          currentTime,
          withHomeGoAlarm: !!selectedHomeGoAlarm,
        }}
      />
      {!!(terminals && terminals.length) && (
        <LegalTerminals
          data-hook="legal-text-terminals"
          terminals={terminals}
          legalTexts={legalTexts}
        />
      )}
      {!!(portaLinesWithDonor && portaLinesWithDonor.length) &&
        portaLinesWithDonor.map(line => {
          return (
            <HTMLTemplate
              key={`portaline-${line.portaPhone}`}
              html={legalTexts.legal_donor}
              data-hook="legal-text-legal-donor"
              values={{ ...line, ...getLineTypeTexts(line.portaPhone) }}
            />
          )
        })}
      {showConfirm && (
        <HTMLTemplate data-hook="legal-texts-confirm" html={legalTexts.legal_texts_confirm} />
      )}
      {!!(portabilityLinesByTitular && portabilityLinesByTitular.length) && !isElFijo && !is2p && (
        <HTMLTemplate
          data-hook="legal-text-portability-by-titular"
          html={legalTexts.legal_text_portability_by_titular}
        />
      )}

      {hasAgileTV && (!isPromo || (isPromo && terms)) && (
        <HTMLTemplate
          data-hook="legal-text-agile-tv-promo"
          html={isPromo ? legalTexts.term_agiletvpromo : legalTexts.term_agiletv}
          values={
            isPromo
              ? {
                  permanence: terms.count,
                  penalty: isPro
                    ? `${terms.taxFreeAmount}€ (impuestos no incluídos)`
                    : `${terms.taxIncludedAmount}`,
                  noReturnalPenalty: !hasAppleTv ? agileTvNoReturnalPenaltyAmount : null,
                }
              : {
                  noReturnalPenalty: !hasAppleTv ? agileTvNoReturnalPenaltyAmount : null,
                }
          }
        />
      )}

      {hasSmartTv && (
        <HTMLTemplate
          data-hook="legal-text-smarttv-installation"
          html={legalTexts.legal_text_smarttv_installation}
        />
      )}

      <HTMLTemplate html={legalTexts.contract_duration} />

      {permanencyTerms?.count > 0 && (!isFixedInternalPortability || isFixedInstallationRequired) && (
        <HTMLTemplate
          data-hook="legal-text-fiber-type-indirect"
          html={
            is2p
              ? legalTexts.legal_text_indirect_permanency_2p
              : legalTexts.legal_text_indirect_permanency
          }
          values={{
            taxFreeAmount: permanencyTerms?.taxIncludedAmount,
            monthNumber: permanencyTerms?.count,
            monthPlaceHolder: permanencyTerms?.count > 1 ? 'meses' : 'mes',
            textIvaNoIncluido: isPro ? ' (impuestos no incluidos)' : '',
          }}
        />
      )}

      {
        /* Convergent ADSL */
        !isElFijo && !isMobileOnly && fiberType === 'adsl' && (
          <HTMLTemplate
            data-hook="legal-text-fiber-type-adsl"
            html={legalTexts.legal_text_adsl}
            values={
              isPro
                ? {
                    penalty: adslRouterNoReturnalPenalty.taxFree,
                  }
                : {
                    penalty: adslRouterNoReturnalPenalty.withTaxes,
                  }
            }
          />
        )
      }

      {
        /* Convergent FTTH/NEBA/VULA */
        showNoAdslText && (
          <HTMLTemplate
            data-hook="legal-text-fiber-type-no-adsl"
            html={legalTexts.legal_text_no_adsl}
            values={{
              penalty: isPro
                ? ffthRouterNoReturnalPenalty.taxFree
                : ffthRouterNoReturnalPenalty.withTaxes,
            }}
          />
        )
      }

      {
        /* Fixed Portability */
        (isFixedPortability || isElFijo) && !is2p && (
          <HTMLTemplate
            data-hook="legal-text-fixed-portability"
            html={legalTexts.legal_text_fixed_portability}
          />
        )
      }

      {selectedHomeGoAlarm && (
        <HTMLTemplate
          data-hook="legal-text-homego-alarm"
          html={legalTexts.legal_text_homego_alarm}
          values={{
            homegoAlarmName: selectedHomeGoAlarm.commercialInfo.name,
          }}
        />
      )}

      <LegalOfferSummary
        linesLegal={linesLegal}
        legalTexts={legalTexts}
        isElFijo={isElFijo}
        isPro={isPro}
        is2p={is2p}
      />
    </div>
  )
}

LegalTexts.propTypes = {
  commissions: PropTypes.shape({
    percent: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
  }),
  showConfirm: PropTypes.bool,
  fullName: PropTypes.string,
  documentid: PropTypes.string,
  currentDate: PropTypes.string,
  currentTime: PropTypes.string,
  fiberType: PropTypes.string,
  terminals: PropTypes.array,
  isFixedPortability: PropTypes.bool,
  isFixedInternalPortability: PropTypes.bool,
  isFixedInstallationRequired: PropTypes.bool,
  discounts: PropTypes.shape({
    total: PropTypes.number.isRequired,
  }),
  permanencyTerms: PropTypes.object,
  agileTV: PropTypes.object.isRequired,
  hasSmartTv: PropTypes.bool,
  portaLinesWithDonor: PropTypes.array,
  portabilityLinesByTitular: PropTypes.array,
  linesLegal: PropTypes.shape({
    products: PropTypes.array,
    monthlyPayments: PropTypes.array,
    terminals: PropTypes.array,
  }),
  isSubscription: PropTypes.bool,
  isPro: PropTypes.bool,
  selectedHomeGoAlarm: PropTypes.object,
  legalTexts: PropTypes.object.isRequired,
  legalTextsNoReturnalPenaltiesValues: PropTypes.object.isRequired,
}

LegalTexts.defaultProps = {
  currentDate: 'XX/XX/XX',
  currentTime: 'XX',
  terminals: [],
  isFixedPortability: false,
  discounts: {
    total: 0,
    terminal: 0,
  },
}
