import styled from 'styled-components'
import { TerminalsPaymentDialog } from './TerminalsPaymentDialog'

export const StyledTerminalsPaymentDialog = styled(TerminalsPaymentDialog)`
  & > .successful-items {
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }
  & > .flex {
    display: flex;
    align-items: center;
  }
  & > .space-between {
    justify-content: space-between;
  }
  .action-buttons > * {
    margin: 0 4px;
  }
  & > .tpv-item > .summary {
  margin-top: 12px;
  .divider {
    display: none;
  }
  .offer-title {
    font-weight: bold;
    margin-bottom: 4px;
  }
  & > .space-between {
    justify-content: space-between;
  }

  & > .payment-chooser {
    display: flex;
    flex-direction: row;
    div[role='radiogroup'] {
      display: flex;
      flex-direction: row;
    }
  }
`
