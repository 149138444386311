export const Terminal = {
  id: '',
  manufacturer: '',
  category: '',
  paymentType: '',
  idDueDate: '',
  job: '',
  salary: '',
  company: '',
  details: {},
  paymentResult: {},
  reservationId: '',
  imei: '',
}
