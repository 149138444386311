import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'

const ListItem = ({ children, ...props }) => (
  <Box display="flex" flexDirection="row" alignItems="center" p="6px 24px" {...props}>
    {children}
  </Box>
)
ListItem.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ListItem
