import PropTypes from 'prop-types'
import { differenceInMonths, parseISO } from 'date-fns'
import { ActionAdvice } from 'components/ui'
import { formatGB } from '../helpers'

function BonusAdvice({ amount, validTo, penalty, onUpdate, onDelete, onCancel }) {
  const duration = validTo ? differenceInMonths(parseISO(validTo), new Date()) + 1 : null

  const items = [{ label: 'Bono datos', value: formatGB(amount) }]
  if (typeof penalty !== 'undefined') {
    items.push({ label: 'Permanencia', value: penalty ? `Sí (${duration} meses)` : 'No' })
  }

  return (
    <ActionAdvice
      className="m-t-20"
      items={items}
      type="info"
      onUpdate={onUpdate}
      onDelete={onDelete}
      onCancel={onCancel}
    />
  )
}

BonusAdvice.propTypes = {
  amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  validTo: PropTypes.number,
  penalty: PropTypes.bool,
  onDelete: PropTypes.func,
  onUpdate: PropTypes.func,
  onCancel: PropTypes.func,
}

export default BonusAdvice
