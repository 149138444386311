export const customerInfoActionTypes = {
  SET_NEW_IBAN_VALUE_CARTERA: 'OVID/CUSTOMER_INFO/SET_NEW_IBAN_VALUE_CARTERA',
  SET_NEW_IBAN_VALUE_CARTERA_SUCCESS: 'OVID/CUSTOMER_INFO/SET_NEW_IBAN_VALUE_CARTERA_SUCCESS',
  SET_NEW_IBAN_VALUE_CARTERA_ERROR: 'OVID/CUSTOMER_INFO/SET_NEW_IBAN_VALUE_CARTERA_ERROR',
  MODIFY_CUSTOMER_CONTACT_MEDIA: 'OVID/CUSTOMER_INFO/MODIFY_CUSTOMER_CONTACT_MEDIA',
  MODIFY_CUSTOMER_CONTACT_MEDIA_SUCCESS: 'OVID/CUSTOMER_INFO/MODIFY_CUSTOMER_CONTACT_MEDIA_SUCCESS',
  MODIFY_CUSTOMER_CONTACT_MEDIA_ERROR: 'OVID/CUSTOMER_INFO/MODIFY_CUSTOMER_CONTACT_MEDIA_ERROR',
  SET_NEW_POSTAL_ADDRESS_CARTERA: 'OVID/CUSTOMER_INFO/SET_NEW_POSTAL_ADDRESS_CARTERA',
  SET_NEW_POSTAL_ADDRESS_CARTERA_SUCCESS:
    'OVID/CUSTOMER_INFO/SET_NEW_POSTAL_ADDRESS_CARTERA_SUCCESS',
  SET_NEW_POSTAL_ADDRESS_CARTERA_ERROR: 'OVID/CUSTOMER_INFO/SET_NEW_POSTAL_ADDRESS_CARTERA_ERROR',
  RESET_CARTERA_VALUES_STORE: 'OVID/CUSTOMER_INFO/RESET_CARTERA_VALUES_STORE',
  RESET_IS_CUSTOMER: 'OVID/CUSTOMER_INFO/RESET_IS_CUSTOMER',
  FETCH_IS_CUSTOMER: 'OVID/CUSTOMER_INFO/FETCH_IS_CUSTOMER',
  FETCH_IS_CUSTOMER_SUCCESS: 'OVID/CUSTOMER_INFO/FETCH_IS_CUSTOMER_SUCCESS',
  FETCH_IS_CUSTOMER_ERROR: 'OVID/CUSTOMER_INFO/FETCH_IS_CUSTOMER_ERROR',
  FETCH_CUSTOMER_IS_ALLOWED: 'OVID/CUSTOMER_INFO/FETCH_CUSTOMER_IS_ALLOWED',
  FETCH_CUSTOMER_IS_ALLOWED_SUCCESS: 'OVID/CUSTOMER_INFO/FETCH_CUSTOMER_IS_ALLOWED_SUCCESS',
  FETCH_CUSTOMER_IS_ALLOWED_ERROR: 'OVID/CUSTOMER_INFO/FETCH_CUSTOMER_IS_ALLOWED_ERROR',
  FETCH_CUSTOMER_INFORMATION: 'OVID/CUSTOMER_INFO/FETCH_CUSTOMER_INFORMATION',
  SET_CUSTOMER_INFORMATION_SUCCESS: 'OVID/CUSTOMER_INFO/SET_CUSTOMER_INFORMATION_SUCCESS',
  SET_CUSTOMER_INFORMATION_ERROR: 'OVID/CUSTOMER_INFO/SET_CUSTOMER_INFORMATION_ERROR',
}

export const fetchNewIbanValueCartera = () => ({
  type: customerInfoActionTypes.SET_NEW_IBAN_VALUE_CARTERA,
})

export const fetchNewIbanValueCarteraSuccess = payload => ({
  type: customerInfoActionTypes.SET_NEW_IBAN_VALUE_CARTERA_SUCCESS,
  payload,
})

export const fetchNewIbanValueCarteraError = payload => ({
  type: customerInfoActionTypes.SET_NEW_IBAN_VALUE_CARTERA_ERROR,
  payload,
})

export const modifyCustomerContactMedia = (accountId, segment, data, delay = 0) => ({
  type: customerInfoActionTypes.MODIFY_CUSTOMER_CONTACT_MEDIA,
  payload: {
    accountId,
    segment,
    data,
    delay,
  },
})

export const modifyCustomerContactMediaSuccess = payload => ({
  type: customerInfoActionTypes.MODIFY_CUSTOMER_CONTACT_MEDIA_SUCCESS,
  payload,
})

export const modifyCustomerContactMediaError = payload => ({
  type: customerInfoActionTypes.MODIFY_CUSTOMER_CONTACT_MEDIA_ERROR,
  payload,
})

export const setNewPostalAddressCartera = () => ({
  type: customerInfoActionTypes.SET_NEW_POSTAL_ADDRESS_CARTERA,
})

export const setNewPostalAddressCarteraSuccess = payload => ({
  type: customerInfoActionTypes.SET_NEW_POSTAL_ADDRESS_CARTERA_SUCCESS,
  payload,
})

export const setNewPostalAddressCarteraError = payload => ({
  type: customerInfoActionTypes.SET_NEW_POSTAL_ADDRESS_CARTERA_ERROR,
  payload,
})

export const resetCarteraValuesStore = () => ({
  type: customerInfoActionTypes.RESET_CARTERA_VALUES_STORE,
})

export const resetIsCustomerAction = () => ({
  type: customerInfoActionTypes.RESET_IS_CUSTOMER,
})

export const fetchIsCustomerAction = payload => ({
  type: customerInfoActionTypes.FETCH_IS_CUSTOMER,
  payload,
})

export const fetchIsCustomerActionSuccess = payload => ({
  type: customerInfoActionTypes.FETCH_IS_CUSTOMER_SUCCESS,
  payload,
})

export const fetchIsCustomerActionError = payload => ({
  type: customerInfoActionTypes.FETCH_IS_CUSTOMER_ERROR,
  payload,
})

export const fetchCustomerInformation = payload => ({
  type: customerInfoActionTypes.FETCH_CUSTOMER_INFORMATION,
  payload,
})

export const setCustomerInformation = payload => ({
  type: customerInfoActionTypes.SET_CUSTOMER_INFORMATION_SUCCESS,
  payload,
})

export const setCustomerInformationError = payload => ({
  type: customerInfoActionTypes.SET_CUSTOMER_INFORMATION_ERROR,
  payload,
})

export const fetchCustomerIsAllowed = payload => ({
  type: customerInfoActionTypes.FETCH_CUSTOMER_IS_ALLOWED,
  payload,
})

export const fetchCustomerIsAllowedSucess = payload => ({
  type: customerInfoActionTypes.FETCH_CUSTOMER_IS_ALLOWED_SUCCESS,
  payload,
})

export const fetchCustomerIsAllowedError = payload => ({
  type: customerInfoActionTypes.FETCH_CUSTOMER_IS_ALLOWED_ERROR,
  payload,
})
