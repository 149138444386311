import PropTypes from 'prop-types'
import { Modal } from '../'

const CancelOrderInBusModal = props => {
  const { orderId, success } = props
  return (
    <Modal
      open
      title={success ? 'Orden cancelada con éxito' : 'Ha sucedido un error'}
      message={success ? `La orden cancelada fue ${orderId}` : 'Por favor, inténtelo de nuevo.'}
      appointment={false}
      quit
      cancelBtn="Salir"
    />
  )
}

CancelOrderInBusModal.propTypes = {
  orderId: PropTypes.string,
  success: PropTypes.bool,
}

export default CancelOrderInBusModal
