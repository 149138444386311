import { actionTypes } from 'modules/typifications/constants'

const initialState = {
  insertionSuccess: false,
  error: null,
  loading: false,
}

export function insertionTypificationsReducer(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case actionTypes.INSERTION_TYPIFICATIONS_INIT: {
      return {
        ...state,
        loading: true,
      }
    }

    case actionTypes.INSERTION_TYPIFICATIONS_SUCCESS: {
      return {
        ...state,
        insertionSuccess: true,
        loading: false,
      }
    }

    case actionTypes.INSERTION_TYPIFICATIONS_FAILED: {
      return {
        ...state,
        insertionSuccess: false,
        error: payload,
        loading: false,
      }
    }

    case actionTypes.INSERTION_TYPIFICATIONS_RESET: {
      return initialState
    }

    default:
      return state
  }
}
