import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useFormikContext } from 'formik'

import { fetchAuthIsCustomerDoc } from 'modules/CustomerInfo/services/customerInfo.service'
import { isValidDocumentByType } from 'modules/CustomerDocument'
import { CANT_CHECK_USER, CLIENT_EXISTS } from 'modules/SharedSales/constants'
import { COMPANY } from 'services/global-constants/index'
import { setRequestLoading } from '../../store'

export function useIsCustomerDocumentValidation() {
  const {
    values: {
      account: { documentid, segment, companyCif },
    },
  } = useFormikContext()

  const inputValue = segment === COMPANY ? companyCif : documentid

  const [prevValue, setPrevValue] = useState(inputValue)
  const [prevState, setPrevState] = useState()
  const [hasMsErrorCustomer, setMsError] = useState()
  const [selectedDocument, setSelectedDocument] = useState()

  const dispatch = useDispatch()

  function setAndReturnState(state, isMsError) {
    setMsError(isMsError)
    setPrevState(state)
    return state
  }

  async function validateWithMS(value, docType) {
    if (!value || !docType) return setAndReturnState(undefined)
    setMsError(false)
    // this is to know when the continue button should have a loading state, is not done yet
    dispatch(setRequestLoading({ loading: true }))

    let state

    try {
      const { status } = await fetchAuthIsCustomerDoc(docType, value).catch(err => {
        throw err
      })

      dispatch(setRequestLoading({ loading: false }))

      if (status === 'Active' || status === 'In flight' || status === 'Canceled-Inactive') {
        state = CLIENT_EXISTS
      } else if (status === 'Failed') {
        state = CANT_CHECK_USER
      }
    } catch {
      dispatch(setRequestLoading({ loading: false }))

      state = CANT_CHECK_USER
    }

    return setAndReturnState(state, !!state)
  }

  const validateCustomer = async function validateCustomer(value, fromDocument, docType) {
    const trimmedValue = value?.trim()
    setSelectedDocument(docType)
    setPrevValue(trimmedValue)

    if (isValidDocumentByType(trimmedValue, docType) || fromDocument) {
      const validation = await validateWithMS(trimmedValue, docType)
      return validation
    }
    return setAndReturnState(prevState || undefined)
  }

  return {
    validateCustomer,
    errorCustomer: prevState,
    hasMsErrorCustomer,
    valueCustomer: prevValue,
    fieldCustomer: selectedDocument,
  }
}
