import { constants } from './constants'

const initialState = {
  error: [],
  loading: false,
}

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case constants.CHECK: {
      return {
        ...state,
        error: payload.resetErrors ? [] : [...state.error],
        loading: true,
      }
    }
    case constants.CHECKED: {
      return {
        ...state,
        step: payload.step,
        loading: false,
      }
    }
    case constants.CHECK_FAILED: {
      return {
        ...state,
        error: [...state.error, payload.error],
        loading: false,
      }
    }
    default:
      return state
  }
}
