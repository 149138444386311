import { useEffect, useState } from 'react'
import { eq } from 'lodash'
import PropTypes from 'prop-types'
import { getDocumentType } from 'modules/CustomerDocument'
import { MSISDN, CLI, UNKNOWN } from 'modules/CustomerDocument/constants'

import { makeStyles } from '@material-ui/styles'
import { Box, CircularProgress, Icon } from '@material-ui/core'
import { ICONS } from 'utils/icons'

import { profiles } from 'modules/Permissions'
import { validateAgentProfile } from 'modules/vista-360/components/OrderDashboard/OrderDashboard.helpers'

const useStyles = makeStyles(theme => ({
  'input-document-type': {
    backgroundColor: theme.palette.global.gray_light,
    border: ({ inputHighlighted }) => (inputHighlighted ? '2px solid white' : 'none'),
    borderRadius: '4px',
    boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12)',
    maxWidth: '600px',
    padding: '10px 15px',

    '& .search-icon': {
      color: theme.palette.global.gray_dark,
      zIndex: 1,
    },

    '& input': {
      color: theme.palette.global.gray_dark,
      backgroundColor: 'transparent',
      border: 'none',
      flex: 1,
      fontSize: '16px',
      height: '100%',
      padding: '0 10px',

      '&:focus': {
        outline: 0,
      },
    },

    '& .type-document-matched': {
      '& p': {
        color: theme.palette.global.gray_dark,
        letterSpacing: '0.8px',
      },

      '& .icon': {
        color: theme.palette.global.gray_dark,
        marginRight: '10px',
      },
    },

    '& .reset-input-button': {
      background: 'none',
      border: 'none',
      cursor: 'pointer',
      color: theme.palette.global.gray_dark,

      '&:focus': {
        outline: 0,
      },
    },
  },
}))
export function InputDocumentType({ isLoading, value, inputHighlighted, onSubmit }) {
  const classes = useStyles({ inputHighlighted })

  const isD2d = validateAgentProfile([profiles.d2d.id])

  const [state, setState] = useState({
    modelInput: value || '',
    documentType: null,
  })

  function msgUnkownDocumentType(cleanVal, silent) {
    setState({ modelInput: cleanVal })
    if (!silent) {
      onSubmit({
        documentType: UNKNOWN,
        documentId: cleanVal || 'NONE',
      })
    }
  }

  function matchDocumentType(cleanVal, silent) {
    const lineTypes = ['MSISDN', 'CLI']

    const documentType = getDocumentType(cleanVal)

    if (documentType) {
      if (isD2d && (documentType === MSISDN || documentType === CLI)) {
        msgUnkownDocumentType(cleanVal, silent)
      } else {
        setState({ documentType, modelInput: cleanVal })
        if (!silent) {
          onSubmit({
            documentType,
            documentId: cleanVal,
            ...(lineTypes.includes(documentType) ? { type: 'line', id: cleanVal } : {}),
          })
        }
      }
    } else {
      msgUnkownDocumentType(cleanVal, silent)
    }
  }

  useEffect(() => {
    if (value) {
      matchDocumentType(value.trim().toUpperCase(), true)
    }
  }, [])

  function updateModelInput(evt) {
    setState({ modelInput: evt.target.value.trim().toUpperCase(), documentType: null })
  }

  function clearInput() {
    setState({ modelInput: '', documentType: null })
  }

  return (
    <Box display="flex" alignItems="center" flex="1" justifyContent="center" p="10px 20px">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        flex="1"
        height="100%"
        className={classes['input-document-type']}>
        {isLoading ? (
          <CircularProgress size={24} />
        ) : (
          <Icon data-hook="search-icon" className="search-icon">
            {ICONS.search}
          </Icon>
        )}
        <input
          type="text"
          maxLength="30"
          disabled={isLoading}
          onChange={updateModelInput}
          data-hook="input-document-type"
          onKeyPress={ev => eq(ev.key, 'Enter') && matchDocumentType(state.modelInput)}
          value={state.modelInput}
        />

        {state.documentType ? (
          <div data-hook="document-type-matched" className="type-document-matched">
            <p>{state.documentType}</p>
          </div>
        ) : (
          <button type="button" className="reset-input-button" onClick={clearInput}>
            <Icon>{ICONS.close}</Icon>
          </button>
        )}
      </Box>
    </Box>
  )
}

InputDocumentType.propTypes = {
  isLoading: PropTypes.bool,
  value: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  inputHighlighted: PropTypes.bool,
}

InputDocumentType.defaultProps = {
  value: '',
  inputHighlighted: false,
}
