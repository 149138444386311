import { createStructuredSelector } from 'reselect'

import { selectChannelQueryParam } from 'modules/Router/store/router.selectors'
import {
  selectIsSVAAgileListLoading,
  selectSVAAgileListError,
  selectSVAAgileListMsgError,
  selectSVAAgileListIsSuccess,
} from 'modules/SVAs/store'

import { selectors as customer360Selectors } from 'services/customer-360/selectors'
import { selectSfid } from 'modules/Auth/store/selectors'

import {
  selectChangeAgileTvOrderIsLoading,
  selectChangeAgileTvOrderError,
  selectChangeAgileTvOrderIsSuccess,
} from '../../store/change-agiletv.selectors'

import { selectAgileTvSvaWithStb } from '../../store'

export const changeAgileTvSelectors = psid =>
  createStructuredSelector({
    channel: selectChannelQueryParam,
    agileTvSva: selectAgileTvSvaWithStb(psid),
    isLoading: selectIsSVAAgileListLoading || selectChangeAgileTvOrderIsLoading,
    svaAgileListError: selectSVAAgileListError,
    svaAgileListMsgError: selectSVAAgileListMsgError,
    svaAgileListIsSuccess: selectSVAAgileListIsSuccess,
    customerEmail: customer360Selectors.getEmail,
    sfid: selectSfid,
    changeError: selectChangeAgileTvOrderError,
    changeIsSuccess: selectChangeAgileTvOrderIsSuccess,
  })
