import { useState } from 'react'
import PropTypes from 'prop-types'
import { ButtonsSelector } from 'components'
import { Card } from 'modules/ui'
import { Button, ModalActions, OutlinedDivider } from 'components/ui'
import { Radio, FormControlLabel, Box, Typography, Divider } from '@material-ui/core'
import { useHistory } from 'react-router-dom'

import { PreSaleFetchChooser } from 'modules/NewClientSales/components/Common/PreSaleFetchChooser'
import { ONLY_MOBILE, ENERGY, LEADS } from 'modules/SharedSales/constants'

export function FlowChooserComponent({
  saleTypeButtons,
  mobileTypeButtons,
  segmentButtons,
  onSelect,
  onResetSelect,
  onSelectSegment,
  saleType,
  saleFlowURL,
  vista360URL,
  onBackToVista360,
  canSale,
  onCreateEnergySale,
  canAgentAccessPresale,
  isEnabledNewClientSale,
  className,
}) {
  const history = useHistory()

  const [isPresaleFetch, setIsPresaleFetch] = useState(false)

  return (
    <div className={`${className} flex align-center-center`}>
      <div className="card-wrapper">
        <Card className="p-x-24 p-y-16 m-y-32" style={{ minWidth: '765px' }}>
          <div>
            <FormControlLabel
              value="new-client"
              control={
                <Radio
                  data-hook="new-client-radio"
                  checked
                  className="radio-check"
                  color="primary"
                />
              }
              label={isPresaleFetch ? 'CONTINUAR PREVENTA' : 'CLIENTE NUEVO'}
            />
          </div>

          {!isPresaleFetch && (
            <>
              <div className="p-y-16">
                <Typography variant="body1">
                  Selecciona el tipo de venta que vas a realizar...
                </Typography>
              </div>
              <ButtonsSelector
                dataHook="sale-buttons"
                justify="flex-start"
                items={saleTypeButtons}
                onSelect={value => onSelect(value, 'sale')}
              />
              {saleType === ONLY_MOBILE && (
                <>
                  <OutlinedDivider />
                  <div className="p-y-16">
                    <Typography variant="body1">¿Sólo móvil o El Fijo?</Typography>
                  </div>
                  <ButtonsSelector
                    dataHook="only-mobile-buttons"
                    justify="flex-start"
                    items={mobileTypeButtons}
                    onSelect={value => onSelect(value, 'mobileType')}
                  />
                </>
              )}
              {saleType !== ENERGY && saleType !== LEADS && (
                <>
                  <OutlinedDivider />
                  <div className="p-y-16">
                    <Typography variant="body1">... y el segmento</Typography>
                  </div>
                  <ButtonsSelector
                    dataHook="channel-buttons"
                    justify="flex-start"
                    items={segmentButtons}
                    onSelect={onSelectSegment}
                  />
                </>
              )}

              <ModalActions>
                <Box position="relative" top="-16px">
                  <Divider absolute />
                </Box>
                <Box display="flex" alignItems="center">
                  <Button
                    margin="0 16px 0 0"
                    onClick={() => {
                      onBackToVista360()
                      history.push(vista360URL)
                    }}
                    secondary
                    data-hook="exit-sale">
                    Volver
                  </Button>
                  <Button
                    to={saleType !== ENERGY && saleType !== LEADS ? saleFlowURL : null}
                    disabled={!canSale}
                    onClick={() => {
                      if (saleType === ENERGY) {
                        onCreateEnergySale()
                      } else if (saleType === LEADS) {
                        history.push(saleFlowURL)
                      }
                    }}
                    data-hook="start-sale"
                    style={{ marginRight: 'auto' }}>
                    Comenzar la venta
                  </Button>

                  {canAgentAccessPresale && (
                    <Button
                      secondary
                      onClick={() => setIsPresaleFetch(true)}
                      data-hook="presale-fetch"
                      disabled={!isEnabledNewClientSale}>
                      Continuar preventa
                    </Button>
                  )}
                </Box>
              </ModalActions>
            </>
          )}

          {isPresaleFetch && (
            <PreSaleFetchChooser
              setIsShow={() => {
                onResetSelect()
                setIsPresaleFetch(false)
              }}
            />
          )}
        </Card>
      </div>
    </div>
  )
}

FlowChooserComponent.propTypes = {
  saleTypeButtons: PropTypes.array.isRequired,
  mobileTypeButtons: PropTypes.array.isRequired,
  segmentButtons: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  onResetSelect: PropTypes.func.isRequired,
  onSelectSegment: PropTypes.func.isRequired,
  saleType: PropTypes.string,
  saleFlowURL: PropTypes.string,
  canSale: PropTypes.bool.isRequired,
  className: PropTypes.string,
  vista360URL: PropTypes.string,
  onCreateEnergySale: PropTypes.func.isRequired,
  onBackToVista360: PropTypes.func.isRequired,
  canAgentAccessPresale: PropTypes.bool,
  isEnabledNewClientSale: PropTypes.bool,
}
