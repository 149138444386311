import { omit } from 'lodash'
import { constants } from './constants'

const initialState = {
  discounts: null,
  discountsAccumulator: {},
  orderDiscounts: {},
  error: null,
  loading: false,
  errorAccumulator: {},
  loadingAccumulator: {},
}

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case constants.GET_DISCOUNTS: {
      return {
        ...state,
        discounts: null,
        error: null,
        loading: true,
        loadingAccumulator: {
          ...state.loadingAccumulator,
          [payload.orderId]: true,
        },
        errorAccumulator: omit(state.errorAccumulator, payload.orderId),
      }
    }
    case constants.GET_DISCOUNTS_SUCCESS: {
      return {
        ...state,
        discounts: payload.discounts,
        discountsAccumulator: {
          ...state.discountsAccumulator,
          ...payload.discounts.reduce(
            (acum, d) => ({
              ...acum,
              [d.discountCode]: d,
            }),
            {},
          ),
        },
        orderDiscounts: {
          ...state.orderDiscounts,
          [payload.orderId]: payload.discounts.map(d => d.discountCode),
        },
        loading: false,
        loadingAccumulator: omit(state.loadingAccumulator, payload.orderId),
      }
    }
    case constants.GET_DISCOUNTS_FAILED: {
      return {
        ...state,
        error: payload.error,
        loading: false,
        loadingAccumulator: omit(state.loadingAccumulator, payload.orderId),
        errorAccumulator: {
          ...state.errorAccumulator,
          [payload.orderId]: payload.error,
        },
      }
    }
    default:
      return state
  }
}
