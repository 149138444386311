import * as constants from './constants'

const defaultState = {
  loading: false,
  data: [],
}
export const reducer = (state = defaultState, action) => {
  const { type, payload } = action

  switch (type) {
    case constants.FIND_ENRICHMENT_SUCCESS: {
      return {
        ...payload,
        loading: false,
      }
    }
    case constants.FIND_ENRICHMENT_FAILED: {
      return {
        error_code: payload.error_code,
        error_message: payload.error_message,
        loading: false,
        data: [],
      }
    }

    default:
      return state
  }
}
