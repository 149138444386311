// This function notifys the D2D native app, sending information through the `androidJSHandler`
export function notifyD2DOrder(body) {
  const userAgent = (navigator.userAgent || navigator.vendor || window.opera || '').toLowerCase()

  if (/android/i.test(userAgent) && window.JSI && window.JSI.sendSellInformationToNativeApp) {
    try {
      const stringifiedData = JSON.stringify(body)
      // El nombre de esta funcion es el correspondiente a la funcion que implementaremos nosotros en Android (No cambiar)
      window.JSI.sendSellInformationToNativeApp(stringifiedData)
    } catch (e) {
      console.warn(e)
      // alert('Android:' + e.message)
    }
  }

  if (/android/i.test(userAgent) && window.sendSellInformationToNativeApp) {
    try {
      const stringifiedData = JSON.stringify(body)
      // El nombre de esta funcion es el correspondiente a la funcion que implementaremos nosotros en Android (No cambiar)
      window.sendSellInformationToNativeApp(stringifiedData)
    } catch (e) {
      console.warn(e)
      // alert('Android:' + e.message)
    }
  }
}

export function notifyD2DCancel() {
  const error = JSON.stringify({
    codeError: 1,
    messageError: 'User canceled',
    descriptionError: '',
  })

  const userAgent = (navigator.userAgent || navigator.vendor || window.opera || '').toLowerCase()

  if (/android/i.test(userAgent) && window.JSI && window.JSI.cancelProcessToNativeApp) {
    try {
      // El nombre de esta funcion es el correspondiente a la funcion que implementaremos nosotros en Android (No cambiar)
      window.JSI.cancelProcessToNativeApp(error)
    } catch (e) {
      console.warn(e)
      // alert('Android:' + e.message)
    }
  }
}
