import { call, put, takeLatest, select } from 'redux-saga/effects'
import { logError, formatErrorMessage } from 'services/logging'
import { axiosFactory } from 'modules/axios'
import { selectGescal17 } from 'modules/Coverage/store/selectors'

import {
  installationsActionTypes,
  fetchInstallationDirectionSuccess,
  fetchInstallationDirectionError,
} from '../actions'

import { getConnectionInstallation } from '../../services'

export function* fetchInstallationDirectionSaga(data) {
  const gescal = data.payload || (yield select(selectGescal17))
  try {
    const installationResult = yield call(getConnectionInstallation, gescal)
    yield put(fetchInstallationDirectionSuccess(installationResult.data))
  } catch (e) {
    console.warn(e)
    yield call(logError, { e: new Error(formatErrorMessage(e)), data, gescal })
    const error = axiosFactory.buildError(e)
    yield put(fetchInstallationDirectionError(error))
  }
}

export function* watchFetchInstallationDirectionCare() {
  yield takeLatest(installationsActionTypes.FETCH_INSTALLATION, fetchInstallationDirectionSaga)
}
