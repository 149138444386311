import { get } from 'lodash'

export const selectDiscountsAgentById = (state, ids) => {
  const discounts = get(state, 'discountsAgent.discountsAccumulator', {})
  return ids.map(id => discounts[id]).filter(d => d)
}
export const selectDiscountsAgentByOrderId = (state, orderId) => {
  const discountsCodes = get(state, `discountsAgent.orderDiscounts.${orderId}`, [])
  return selectDiscountsAgentById(state, discountsCodes)
}

export const isLoading = (state, orderId) =>
  get(state, `discountsAgent.loadingAccumulator.${orderId}`, false)

export const hasError = (state, orderId) =>
  get(state, `discountsAgent.errorAccumulator.${orderId}`, false)

// DEPRECATED: please use above selectors which take into account orderId
// saving multiple requests
export const selectDiscountsAgent = state => get(state, 'discountsAgent.discounts')
export const selectDiscountsAgentError = state => get(state, 'discountsAgent.error', null)
export const selectDiscountsAgentLoading = state => get(state, 'discountsAgent.loading', false)
