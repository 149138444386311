import { head } from 'lodash'
import { call, put, take, takeEvery, select } from 'redux-saga/effects'
import { orderPonrInitAction } from 'modules/ponr'
import { selectPonc, getPoncInitAction, GET_PONC_SUCCESS } from 'modules/ponc'
import { logError, formatErrorMessage } from 'services/logging'
import { axiosFactory } from 'modules/axios'
import { getCoverageAddressUpdateOrder } from 'modules/Coverage'
import { selectCustomerId, selectSfid } from 'modules/Auth'
import { selectURLDocumentId } from 'modules/CustomerDocument/selectors/index'
import { getDocumentType } from 'modules/CustomerDocument'

import { selectCancelPartialOrders, selectUpdateOrderV1 } from 'services/feature-flag/selectors'
import * as actions from './orders.actions'

import {
  find,
  cancelOrder,
  updateOrder,
  retainOrder,
  fetchPenaltyOrder,
  getOrdersCancellationInfo,
  fetchAdditionalLines,
  cancelPartialOrder,
  replaceOrder,
} from '../services/orders.service'
import { CANCELLATION_FLOW_TOGETHER } from '../constants'
import { selectOrdersCancellationFlow } from './orders.selectors'

export function* retainOrderSaga({ payload: { orderId } }) {
  try {
    yield put(actions.retainOrderAction())
    yield call(retainOrder, orderId)
    yield put(actions.retainOrderSuccessAction())
  } catch (e) {
    yield call(logError, { e: new Error(formatErrorMessage(e)), orderId })
    const error = axiosFactory.buildError(e)
    yield put(actions.retainOrderErrorAction(error))
  }
}

export function* watchRetainOrder() {
  yield takeEvery(actions.RETAIN_ORDER, retainOrderSaga)
}

export function* fetchOrderSaga({ payload: { orderId } }) {
  try {
    const customerId = yield select(selectCustomerId)
    const order = yield call(find, customerId, orderId)

    const docNumber = yield select(selectURLDocumentId)
    const docType = getDocumentType(docNumber.toUpperCase())

    yield put(actions.fetchOrdersCancellationInfoAction(docNumber, docType))

    const cancellationInfoAction = yield take([
      actions.FETCH_ORDERS_CANCELLATION_INFO_SUCCESS,
      actions.FETCH_ORDERS_CANCELLATION_INFO_FAILED,
    ])

    if (cancellationInfoAction.type === actions.FETCH_ORDERS_CANCELLATION_INFO_FAILED) {
      throw new Error('cancellation info error')
    }

    const cancellationFlow = yield select(selectOrdersCancellationFlow)

    const canCancelPartialOrders = yield select(selectCancelPartialOrders)

    yield put(orderPonrInitAction(orderId))

    if (!canCancelPartialOrders || cancellationFlow === CANCELLATION_FLOW_TOGETHER) {
      yield put(actions.fetchPenaltyOrderAction(orderId))
    }

    yield put(actions.fetchOrderSuccessAction({ data: order }))
  } catch (e) {
    yield call(logError, { e: new Error(formatErrorMessage(e)), orderId })
    const error = axiosFactory.buildError(e)
    yield put(actions.fetchOrderFailedAction(error))
  }
}

export function* watchFetchOrder() {
  yield takeEvery(actions.FETCH_ORDER, fetchOrderSaga)
}

export function* fetchOrderOnlySaga({ payload: { orderId } }) {
  try {
    const customerId = yield select(selectCustomerId)
    const order = yield call(find, customerId, orderId)
    yield put(actions.fetchOrderSuccessAction({ data: order }))
  } catch (e) {
    yield call(logError, { e: new Error(formatErrorMessage(e)), orderId })
    const error = axiosFactory.buildError(e)
    yield put(actions.fetchOrderFailedAction(error))
  }
}

export function* watchFetchOrderOnly() {
  yield takeEvery(actions.FETCH_ORDER_ONLY, fetchOrderOnlySaga)
}

export function* fetchPenaltyOrderSaga({ payload: { orderId } }) {
  try {
    const penalties = yield call(fetchPenaltyOrder, orderId)
    yield put(actions.fetchPenaltyOrderSuccessAction(penalties))
  } catch (e) {
    yield call(logError, { e: new Error(formatErrorMessage(e)), orderId })
    const error = axiosFactory.buildError(e)
    yield put(actions.fetchPenaltyOrderFailedAction(error))
  }
}

export function* watchFetchPenaltyOrder() {
  yield takeEvery(actions.FETCH_PENALTY_ORDER, fetchPenaltyOrderSaga)
}

export function* fetchAdditionalLinesSaga({ payload: { docType, docNumber, orderId } }) {
  try {
    const data = yield call(fetchAdditionalLines, docType, docNumber, orderId)

    yield put(actions.fetchOrderAdditionalLinesSuccessAction(data))
  } catch (e) {
    yield call(logError, { e: new Error(formatErrorMessage(e)), orderId })
    const error = axiosFactory.buildError(e)
    yield put(actions.fetchOrderAdditionalLinesFailedAction(error))
  }
}

export function* watchFetchAdditionalLines() {
  yield takeEvery(actions.FETCH_ADDITIONAL_LINES, fetchAdditionalLinesSaga)
}

export function* cancelOrdersSaga({ payload: { orderId, data } }) {
  try {
    yield put(actions.cancelOrderAction())
    // Ask ponc
    yield put(getPoncInitAction(orderId))
    // Wait for ponc
    yield take(GET_PONC_SUCCESS)
    const poncResult = yield select(selectPonc)
    // Cancel order
    if (!poncResult.reached) {
      yield call(cancelOrder, data)
    }
    yield put(actions.canceledOrderAction())
  } catch (e) {
    yield call(logError, { e: new Error(formatErrorMessage(e)), orderId, data })
    const error = axiosFactory.buildError(e)
    yield put(actions.cancelOrderErrorAction(error))
  }
}

export function* watchCancelOrder() {
  yield takeEvery(actions.CANCEL_ORDER_INIT, cancelOrdersSaga)
}

const generateCancelOrder = (order, data, sfid) =>
  cancelOrder({
    ...data,
    orderId: order.id,
    cancelRelated: order.cancelRelated,
    sfid,
  }).then(() => order.id)

const generatePartialCancelOrder = (order, data, sfid) =>
  cancelPartialOrder(
    { ...data, orderId: order.id, v3: head(order.lines).v3 },
    sfid,
    order.partialOperation,
  ).then(() => order.id)

export function* cancelMultipleOrdersSaga({ payload: { orders, data } }) {
  try {
    const sfid = yield select(selectSfid)

    const canceledOrders = []

    // Replace orders
    if (orders.toReplace) {
      try {
        // eslint-disable-next-line no-shadow
        const { cancelOrder, replaceableOrder } = orders.toReplace

        yield call(
          replaceOrder,
          data,
          sfid,
          cancelOrder.lines[0].v3,
          cancelOrder.id,
          replaceableOrder.id,
        )
      } catch (error) {
        // eslint-disable-next-line no-throw-literal
        throw { e: error }
      }

      canceledOrders.push(orders.toReplace.cancelOrder.id, orders.toReplace.replaceableOrder.id)
    }

    // Cancel orders
    if (orders.toDelete.length > 0) {
      const cancellations = orders.toDelete.map(order =>
        order.partialOperation
          ? generatePartialCancelOrder(order, data, sfid)
          : generateCancelOrder(order, data, sfid),
      )

      const cancelOrdersResult = yield call(() => Promise.allSettled(cancellations))

      canceledOrders.push(
        ...cancelOrdersResult.filter(res => res.status === 'fulfilled').map(res => res.value),
      )

      const error = cancelOrdersResult.find(res => res.status === 'rejected')
      if (error) {
        // eslint-disable-next-line no-throw-literal
        throw { e: error.reason, canceledOrders }
      }
    }

    yield put(actions.cancelOrdersSuccessAction(canceledOrders))
  } catch ({ e, canceledOrders }) {
    yield call(logError, { e: new Error(formatErrorMessage(e)), orders, data })
    const error = axiosFactory.buildError(e)
    yield put(actions.cancelOrdersFailedAction(error, canceledOrders))
  }
}

export function* watchCancelOrders() {
  yield takeEvery(actions.CANCEL_ORDERS, cancelMultipleOrdersSaga)
}

export function* updateOrdersSaga({ payload: { orderId } }) {
  try {
    yield put(actions.updateOrderAction())

    const isChangeAddressV1 = yield select(selectUpdateOrderV1)
    const coverageAddress = yield select(getCoverageAddressUpdateOrder)
    const updateResult = yield call(updateOrder, orderId, coverageAddress, isChangeAddressV1)

    yield put(actions.updatedOrderAction(updateResult))
  } catch (e) {
    yield call(logError, { e: new Error(formatErrorMessage(e)), orderId })
    const error = axiosFactory.buildError(e)
    yield put(actions.updateOrderFailedAction(error))
  }
}

export function* watchUpdateOrder() {
  yield takeEvery(actions.UPDATE_ORDER_INIT, updateOrdersSaga)
}

export function* fetchOrdersCancellationInfoSaga({ payload: { docNumber, docType } }) {
  try {
    const cancellationInfo = yield call(getOrdersCancellationInfo, docNumber, docType)
    yield put(actions.fetchOrdersCancellationInfoSuccessAction(cancellationInfo))
  } catch (e) {
    yield call(logError, { e: new Error(formatErrorMessage(e)), docNumber, docType })
    const error = axiosFactory.buildError(e)
    yield put(actions.fetchOrdersCancellationInfoFailedAction(error))
  }
}

export function* watchFetchOrdersCancellationInfo() {
  yield takeEvery(actions.FETCH_ORDERS_CANCELLATION_INFO, fetchOrdersCancellationInfoSaga)
}
