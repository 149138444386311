import { call, put, takeLatest } from 'redux-saga/effects'
import { logError, formatErrorMessage } from 'services/logging'
import { axiosFactory } from 'modules/axios'
import { actions } from './actions'
import { constants } from './constants'
import { fetchOrderStatusDesc } from './api'

export function* findOrderStatusDescSaga({ payload: { orderStatusId, fixedStatusId } }) {
  try {
    const orderStatusData = yield call(fetchOrderStatusDesc, orderStatusId, fixedStatusId)

    yield put(actions.findOrderStatusDescSucceeded({ data: orderStatusData }))
  } catch (e) {
    yield call(logError, { e: new Error(formatErrorMessage(e)), orderStatusId, fixedStatusId })
    const error = axiosFactory.buildError(e)
    yield put(actions.findOrderStatusDescFailed(error))
  }
}

export function* watchFindOrderStatusDesc() {
  yield takeLatest(constants.FIND_ORDER_STATUS_DESC, findOrderStatusDescSaga)
}
