import {
  POS,
  TELESALES,
  TELESALES_PRO,
  FUSION_TELESALES,
  FUSION_TELESALES_PRO,
  CC,
  CC_PRO,
  POS_PRO,
  FUSION_POS_PRO,
  FUSION_POS,
} from 'services/global-constants'

export function mapSegments(channel) {
  switch (channel) {
    case TELESALES:
    case POS:
      return 'residential'
    case TELESALES_PRO:
    case POS_PRO:
      return 'pro'
    default:
      return 'residential'
  }
}

export function mapToCCChannel(channel = '', isMobileOnly) {
  switch (channel.toLowerCase()) {
    case TELESALES:
      return !isMobileOnly ? FUSION_TELESALES : CC
    case TELESALES_PRO:
      return !isMobileOnly ? FUSION_TELESALES_PRO : CC_PRO
    case POS_PRO:
      return isMobileOnly ? POS_PRO : FUSION_POS_PRO
    case POS:
      return isMobileOnly ? POS : FUSION_POS

    default:
      return FUSION_TELESALES
  }
}
