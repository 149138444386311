import { useState } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { useLocation } from 'react-router-dom'

import { Card } from 'modules/ui'
import { DoubleLineItemCard } from 'components/ui'

import { MasConsentsGdprModal } from 'modules/mas-consents'
import { formatCensuredDni, formatCensuredNif } from 'modules/vista-360/helpers/formats.helper'
import { NIF, NIE, CIF, PASSPORT } from 'modules/CustomerDocument/constants/documentTypes'
import { selectIsPosOrMasProximo } from 'modules/Permissions'

export function OwnerCard({ data, enableChangeOwner, gdprData }) {
  const [isGdprModalOpen, setIsGdprModalOpen] = useState(null)

  const isPosOrMasProximo = useSelector(selectIsPosOrMasProximo)

  const location = useLocation()
  const { fullName, birthdate, identification, segment, country, language } = data
  const documentType = get(identification, 'type')
  const documentId = get(identification, 'identificationId')

  let censuredDocumentId = documentId

  if (isPosOrMasProximo) {
    if (documentType === NIF || documentType === NIE || documentType === PASSPORT.toUpperCase()) {
      censuredDocumentId = formatCensuredDni(documentId)
    } else if (documentType === CIF) {
      censuredDocumentId = formatCensuredNif(documentId)
    }
  }

  const actions = enableChangeOwner
    ? [
        {
          label: 'CAMBIO',
          to: `${location.pathname}/changeOwner`,
          dataHook: 'change-owner-button',
        },
      ]
    : []

  return (
    <>
      {!!gdprData && (
        <MasConsentsGdprModal
          show={isGdprModalOpen}
          onClose={() => setIsGdprModalOpen(false)}
          onSuccess={() => setIsGdprModalOpen(false)}
          {...gdprData}
        />
      )}

      <Card title="Titular" id="Titular" actions={actions} withShadow>
        <DoubleLineItemCard primaryText="Segmento" secondaryText={segment} />
        <DoubleLineItemCard primaryText="Nombre y Apellidos" secondaryText={fullName} />
        <DoubleLineItemCard primaryText={documentType} secondaryText={censuredDocumentId} />
        <DoubleLineItemCard primaryText="Fecha de nacimiento" secondaryText={birthdate} />
        <DoubleLineItemCard primaryText="País de origen" secondaryText={country} />
        <DoubleLineItemCard primaryText="Idioma" secondaryText={language} />

        {!!gdprData && (
          <DoubleLineItemCard
            primaryText="Preferencias de privacidad"
            listActions={[
              {
                action: () => setIsGdprModalOpen(true),
                dataHook: 'privacy-preferences',
                icon: 'visibility',
                disabled: !gdprData.subscriptionId,
              },
            ]}
          />
        )}
      </Card>
    </>
  )
}

OwnerCard.propTypes = {
  data: PropTypes.object,
  enableChangeOwner: PropTypes.bool,
  gdprData: PropTypes.shape({
    subscriptionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    verbalId: PropTypes.string,
    customerDocument: PropTypes.string.isRequired,
    segment: PropTypes.string.isRequired,
    lang: PropTypes.string,
    channel: PropTypes.string.isRequired,
    consentType: PropTypes.string.isRequired,
  }),
}
