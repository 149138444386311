import { Typography } from '@material-ui/core'
import { TextStyles } from 'utils/text'

import { Card } from 'modules/ui'

export const ErrorOrderProducts = () => (
  <Card title="Productos" withShadow>
    <div className="p-l-24">
      <Typography {...TextStyles.subtitle2Dark()}>Se ha producido un error</Typography>
    </div>
  </Card>
)
