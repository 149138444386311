import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Formik } from 'formik'

import { Typography, Divider, Box } from '@material-ui/core'

import { TextStyles } from 'utils/index'
import { Button } from 'components/ui'
import { Card } from 'modules/ui'
import { documentTypes } from 'modules/CustomerDocument'
import { selectors } from 'services/customer-360'

import { ChangeOwnerLines } from '../../components/ChangeOwnerLines'
import { ChangeOwnerSearch } from '../../components/ChangeOwnerSearch'

export function ChangeOwnerCard({ className }) {
  const lines = useSelector(selectors.lines)

  function searchChanged(value) {
    console.warn('searchChanged', value)
  }

  function onLinesChange(newLines) {
    console.warn('newLines', newLines)
  }

  return (
    <Formik
      initialValues={{
        newClient: false,
        documentType: documentTypes.NIF,
      }}
      onSubmit={(values, actions) => {
        console.warn('onSubmit', values, actions)
      }}>
      {({ values, handleSubmit }) => (
        <Card className={className} title="Selección de líneas y titular" withShadow>
          <Box display="flex" flexDirection="column" p="12px" alignItems="flex-start">
            <ChangeOwnerLines lines={lines} onChange={onLinesChange} />

            <Box m="8px 0">
              <Divider className="divider" />
            </Box>

            <Typography
              {...TextStyles.subtitle1Secondary({
                className: 'subtitle1_secondary',
              })}>
              Identifica al Nuevo Titular
            </Typography>

            <ChangeOwnerSearch onChange={searchChanged} value={values} />

            <Button onClick={handleSubmit}>Buscar Titular</Button>
          </Box>
        </Card>
      )}
    </Formik>
  )
}

ChangeOwnerCard.propTypes = {
  className: PropTypes.string,
}
