const LOGIN_AGENT_INIT = 'OVID/TYPIFICATIONS/LOGIN_AGENT_INIT'
const LOGIN_AGENT_SUCCESS = 'OVID/TYPIFICATIONS/LOGIN_AGENT_SUCCESS'
const LOGIN_AGENT_FAILED = 'OVID/TYPIFICATIONS/LOGIN_AGENT_FAILED'
const DATA_TYPIFICATIONS_INIT = 'OVID/TYPIFICATIONS/DATA_INIT'
const DATA_TYPIFICATIONS_SUCCESS = 'OVID/TYPIFICATIONS/DATA_SUCCESS'
const DATA_TYPIFICATIONS_FAILED = 'OVID/TYPIFICATIONS/DATA_FAILED'
const DATA_TYPIFICATIONS_LOADING = 'OVID/TYPIFICATIONS/DATA_LOADING'
const INSERTION_TYPIFICATIONS_INIT = 'OVID/TYPIFICATIONS/INSERTION_INIT'
const INSERTION_TYPIFICATIONS_SUCCESS = 'OVID/TYPIFICATIONS/INSERTION_SUCCESS'
const INSERTION_TYPIFICATIONS_FAILED = 'OVID/TYPIFICATIONS/INSERTION_FAILED'
const INSERTION_TYPIFICATIONS_RESET = 'OVID/TYPIFICATIONS/INSERTION_RESET'
const GET_REGISTER_TYPIFICATIONS_INIT = 'OVID/TYPIFICATIONS/GET_REGISTER_INIT'
const GET_REGISTER_TYPIFICATIONS_SUCCESS = 'OVID/TYPIFICATIONS/GET_REGISTER_SUCCESS'
const GET_REGISTER_TYPIFICATIONS_FAILED = 'OVID/TYPIFICATIONS/GET_REGISTER_FAILED'
const SET_DATA_FORM_TYPIFICATIONS = 'OVID/TYPIFICATIONS/DATA_FORM'
const SECURIZER_TYPIFICATION_INIT = 'OVID/TYPIFICATIONS/SECURIZER_TYPIFICATION_INIT'
const SECURIZER_SUCCESS = 'OVID/TYPIFICATIONS/SECURIZER_SUCCESS'
const SECURIZER_FAILED = 'OVID/TYPIFICATIONS/SECURIZER_FAILED'
const RESET_SECURIZER_FAILED = 'OVID/TYPIFICATIONS/RESET_SECURIZER_FAILED'
const CLOSE_TYPIFICATION_IFRAME = 'OVID/TYPIFICATIONS/CLOSE_TYPIFICATION_IFRAME'

export const actionTypes = {
  LOGIN_AGENT_INIT,
  LOGIN_AGENT_SUCCESS,
  LOGIN_AGENT_FAILED,
  DATA_TYPIFICATIONS_INIT,
  DATA_TYPIFICATIONS_SUCCESS,
  DATA_TYPIFICATIONS_FAILED,
  DATA_TYPIFICATIONS_LOADING,
  INSERTION_TYPIFICATIONS_INIT,
  INSERTION_TYPIFICATIONS_SUCCESS,
  INSERTION_TYPIFICATIONS_FAILED,
  INSERTION_TYPIFICATIONS_RESET,
  GET_REGISTER_TYPIFICATIONS_INIT,
  GET_REGISTER_TYPIFICATIONS_SUCCESS,
  GET_REGISTER_TYPIFICATIONS_FAILED,
  SET_DATA_FORM_TYPIFICATIONS,
  SECURIZER_TYPIFICATION_INIT,
  SECURIZER_SUCCESS,
  SECURIZER_FAILED,
  RESET_SECURIZER_FAILED,
  CLOSE_TYPIFICATION_IFRAME
}

export const DATA_TOKEN_OBLIGATORY_FIELDS = ['uuid', 'channel', 'customer_id', 'sfid', 'brand']
export const DATA_TOKEN_OBLIGATORY_FIELDS_SECURIZED = ['channel', 'customer_id', 'sfid', 'brand']

export const NOT_AUTHENTICATED_ERROR = 'notAuthenticated'
export const NOT_AVIABLE_ERROR = 'notAviable'
const NOT_CHANNEL_ERROR = 'notChannel'
const NOT_CUSTOMER_ID_ERROR = 'notCustomerId'
const NOT_SFID_ERROR = 'notSfid'
const NOT_BRAND_ERROR = 'notBrand'
export const NOT_REGISTERS_ERROR = 'notRegisters'
export const NOT_DATA_CAMPAIGN = 'notDataCampaign'
export const INSERTION_SUCCESS = 'insertionSuccess'
export const INSERTION_ERROR = 'insertionError'
export const CAMPAIGN_BY_EMAIL_ERROR = 'campaignByEmailError'
export const DATA_TOKEN_ERROR_CODE = {
  uuid: NOT_AUTHENTICATED_ERROR,
  channel: NOT_CHANNEL_ERROR,
  customer_id: NOT_CUSTOMER_ID_ERROR,
  sfid: NOT_SFID_ERROR,
  brand: NOT_BRAND_ERROR,
}
export const CAMPAIGN_BY_EMAIL_TYPS = [CAMPAIGN_BY_EMAIL_ERROR]
export const SUCCESS_NOTIFICATIONS_TIPIS = [INSERTION_SUCCESS]
export const INSERTION_NOTIGICATIONS_TIPIS = [INSERTION_SUCCESS, INSERTION_ERROR]
export const TYPE_NOTIFICATIONS_TIPIS = [
  NOT_AUTHENTICATED_ERROR,
  NOT_AVIABLE_ERROR,
  NOT_CHANNEL_ERROR,
  NOT_CUSTOMER_ID_ERROR,
  NOT_SFID_ERROR,
  NOT_BRAND_ERROR,
  NOT_REGISTERS_ERROR,
  NOT_DATA_CAMPAIGN,
  INSERTION_SUCCESS,
  INSERTION_ERROR,
  CAMPAIGN_BY_EMAIL_ERROR
]
export const IMAGE_ERROR = {
  [NOT_AUTHENTICATED_ERROR]: '/assets/error.svg',
  [NOT_AVIABLE_ERROR]: '/assets/alert_bubbles.svg',
  [NOT_CHANNEL_ERROR]: '/assets/error.svg',
  [NOT_CUSTOMER_ID_ERROR]: '/assets/error.svg',
  [NOT_SFID_ERROR]: '/assets/error.svg',
  [NOT_BRAND_ERROR]: '/assets/error.svg',
  [NOT_REGISTERS_ERROR]: '/assets/error.svg',
  [NOT_DATA_CAMPAIGN]: '/assets/error.svg',
  [INSERTION_SUCCESS]: '/assets/clap_clap.svg',
  [INSERTION_ERROR]: '/assets/error.svg',
  [CAMPAIGN_BY_EMAIL_ERROR]: '/assets/error.svg',
}
export const TITLE_ERROR = {
  [NOT_AUTHENTICATED_ERROR]:
    'No se ha podido autenticar el acceso. Por favor, póngase en contacto con el administrador',
  [NOT_AVIABLE_ERROR]: 'Página en mantenimiento',
  [NOT_CHANNEL_ERROR]: 'No se ha encontrado channel',
  [NOT_CUSTOMER_ID_ERROR]: 'No se ha encontrado identificador de cliente',
  [NOT_SFID_ERROR]: 'No se ha encontrado SFID de usuario',
  [NOT_BRAND_ERROR]:
    'No se ha encontrado brand. Por favor, especifique la marca para poder continuar',
  [NOT_REGISTERS_ERROR]: 'Se ha producido un error al obtener los registros de tipificaciones',
  [NOT_DATA_CAMPAIGN]: 'El usuario no tiene campañas de tipificación disponibles',
  [INSERTION_SUCCESS]: 'La tipificación se ha registrado con éxito',
  [INSERTION_ERROR]:
    'Se ha producido un error, por favor, vuelve intentar el registro de la tipificación pasados unos minutos. Disculpa las molestias',
  [CAMPAIGN_BY_EMAIL_ERROR]: 'No se ha podido determinar la campaña del usuario. No se pueden recuperar sus opciones de tipificación'
}
export const BODY_ERROR = {
  [NOT_AUTHENTICATED_ERROR]: 'Por favor, autentique la sesión para poder continuar',
  [NOT_AVIABLE_ERROR]: 'Actualmente la siguiente página no se encuentra disponible',
  [NOT_CHANNEL_ERROR]: 'Por favor, identifique su canal para poder continuar',
  [NOT_CUSTOMER_ID_ERROR]: 'Por favor, indique cliente para poder continuar',
  [NOT_SFID_ERROR]: 'Por favor, identifique al usuario para poder continuar',
}

export const SECURIZER_ERROR = 'Se ha producido un error en la autenticación, inténtalo de nuevo pasados unos instantes.'

export const DEFAULT_VALUE_SELECTOR = 'N/A'

export const MASCARE = 'mascare' 
export const AGENTS = 'agents' 
