import { UNLIMITED_DURATION } from '../../../constants'
import { formatDuration } from '../../../helpers'

export function getPromoInterval(summary, i) {
  if (i === 0) {
    const { duration } = summary[0]

    let firstRange = ''
    if (duration === -1) {
      firstRange = 'el mes actual'
    } else if (duration === 1) {
      firstRange = 'el 1 mes'
    } else {
      firstRange = `los ${duration} primeros meses`
    }

    return firstRange
  }

  const previousDuration = summary[i - 1].duration
  const lastDuration = summary[i].duration
  if (previousDuration === -1) {
    return `del mes actual al mes ${lastDuration}`
  }
  return `del mes ${summary[i - 1].duration} al mes ${lastDuration}`
}

export function formatDiscountDuration(duration) {
  let cuota = 'Cuota '
  if (duration === UNLIMITED_DURATION) {
    cuota = cuota.concat('para siempre')
  } else if (duration === -1) {
    cuota = cuota.concat('1 mes')
  } else {
    cuota = cuota.concat(formatDuration(duration))
  }

  return cuota
}
