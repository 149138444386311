import PropTypes from 'prop-types'
import { formatNumberWithComma } from 'utils'

import { Checkbox, FormControlLabel } from '@material-ui/core'

export function BundleItem({
  className = '',
  checked,
  disabled,
  onChange,
  id,
  name,
  monthlyFeeWithTax,
  duration,
}) {
  return (
    <div className={className}>
      <FormControlLabel
        data-hook="bundle-item"
        control={
          <Checkbox
            className="checkbox"
            checked={checked}
            onChange={onChange}
            color="primary"
            value={id}
            disabled={disabled}
          />
        }
        label={`${name} | ${formatNumberWithComma(monthlyFeeWithTax)} €/mes ${
          duration ? `| ${duration} meses` : ''
        }`}
      />
    </div>
  )
}

BundleItem.defaultProps = {
  className: '',
}

BundleItem.propTypes = {
  className: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  duration: PropTypes.number,
  monthlyFeeWithTax: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}
