import PropTypes from 'prop-types'

import { Card, CardContent, Box, Typography, Divider } from '@material-ui/core'

export const FinancedDeviceSummary = ({ currentFinancedDeviceInfo, classes }) => {
  const { deviceName, summaryDecisions } = currentFinancedDeviceInfo

  const showFinancedDeviceSummary = deviceName && summaryDecisions && summaryDecisions.length > 0

  return (
    <>
      {showFinancedDeviceSummary && (
        <Card style={{ width: '100%' }} className={classes['device-new-card']}>
          <Box p="20px 24px 18px" display="flex" alignItems="center">
            <Typography variant="h5" style={{ fontWeight: 'bold' }}>
              Dispositivo actual - {currentFinancedDeviceInfo.deviceName}
            </Typography>
          </Box>

          <Divider />

          <CardContent>
            <Typography component="h6" variant="body1" style={{ fontWeight: 'bold' }}>
              DATOS DEL DISPOSITIVO ACTUAL
            </Typography>
            <Box mt="12px">
              {summaryDecisions.map((decision, i) => (
                <div key={`summary-decision-${i.toString()}`}>
                  <Typography
                    variant="body2"
                    className={classes['device-data']}
                    data-hook="terminal.capacity">
                    <Typography variant="subtitle2" component="span" className="p-r-4">
                      {decision.name}:
                    </Typography>
                    {decision.description}
                  </Typography>
                </div>
              ))}
            </Box>
          </CardContent>
        </Card>
      )}
    </>
  )
}

FinancedDeviceSummary.propTypes = {
  currentFinancedDeviceInfo: PropTypes.object,
  classes: PropTypes.object,
}
