import * as Yup from 'yup'
import { REQUIRED_FIELD_TEXT } from 'modules/SharedSales/constants/validations'

const tipisFormValidationSchema = Yup.object().shape({
  operator: Yup.string().required(REQUIRED_FIELD_TEXT),
  campaign: Yup.string().required(REQUIRED_FIELD_TEXT),
  reason: Yup.string().required(REQUIRED_FIELD_TEXT),
  subreason: Yup.string().required(REQUIRED_FIELD_TEXT),
  subreason2: Yup.string().required(REQUIRED_FIELD_TEXT),
  result: Yup.string().required(REQUIRED_FIELD_TEXT),
  subresult: Yup.string().required(REQUIRED_FIELD_TEXT),
  msisdn: Yup.string().required(REQUIRED_FIELD_TEXT),
  description: Yup.string().required(REQUIRED_FIELD_TEXT),
})

export default tipisFormValidationSchema
