import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'

import { TextStyles } from 'utils/text'
import { roundTwoDecimals, formatPercent } from 'utils'

const generateDiscountMessagesList = discountMessages =>
  (discountMessages?.length &&
    discountMessages.map(({ messageText, id }) => {
      return (
        <Typography
          {...TextStyles.subtitle2Dark({
            className: 'p-y-4',
          })}
          data-hook={`discount-message-${id}`}
          key={`discount-message-${id}`}>
          {`- ${messageText}`}
        </Typography>
      )
    })) ||
  null

const generateDiscountsList = discounts =>
  (discounts.length &&
    discounts
      ?.filter(i => !!i)
      ?.map(
        ({
          amount = 0,
          duration = 0,
          isSummary = false,
          phone = '',
          isMobile = false,
          units,
          linePrice,
          isD2rDiscount,
        }) => {
          const total =
            units === 'percents'
              ? `${amount}% : ${roundTwoDecimals(-formatPercent(linePrice, amount))} €/mes`
              : `${amount} €/mes`
          return (
            <Typography
              {...TextStyles.subtitle2Dark({
                className: 'p-y-4',
              })}
              data-hook={`discount-${duration}`}
              key={`discount-range-${duration}-${amount}`}>
              {`- Promoción ${isD2rDiscount ? '(Descuento segunda residencia)' : ''}: ${total}`}
              {duration ? ` durante ${duration} meses ` : ''}
              {isMobile && isSummary && ` en línea adicional ${phone}`}
            </Typography>
          )
        },
      )) ||
  null

export const DiscountSummaryComponent = ({ discounts, discountMessages }) => (
  <>
    {generateDiscountsList(discounts)}
    {generateDiscountMessagesList(discountMessages)}
  </>
)

DiscountSummaryComponent.propTypes = {
  discounts: PropTypes.array.isRequired,
  discountMessages: PropTypes.array.isRequired,
}
