import { get, includes, isNil, pick, pickBy, sortBy, uniqBy, head, reduce } from 'lodash'
import {
  addMonths,
  addYears,
  differenceInDays,
  differenceInMonths,
  differenceInYears,
  parseISO,
  startOfDay,
  addDays,
  startOfMonth,
} from 'date-fns'

import { formatDateYYMMDD } from 'utils/formatting/dates'
import {
  isConvergentSubscription,
  getProducts,
  getDiscounts,
  getFeesPendingFromPermanent,
  FIXED_PRODUCT_NAME,
  MOBILE_PRODUCT_NAME,
  PREPAID_CHARACTERISTIC_NAME,
  getMsisdn,
  getMontlhyVasFee,
  getTerminalsFee,
  getPaymentType,
} from 'services/subscriptions'

import { isProCustomer } from 'services/customer-360/helpers'
import { isElFijoTariff, getElFijoMsisdn } from 'modules/tariffs/helpers'
import { isCrossSellDiscount } from 'modules/Discounts/helpers/helpers'
import { calculateCrossSellDiscount, findFee } from 'services/subscriptions/helpers'

import { applyTax } from 'services/taxes/helpers'

import { formatNumber, formatFee } from 'utils'

import { ONLY_FIXED, FIXED_MOBILE } from 'modules/SharedSales/constants'

import {
  CATEGORY_2P_FREE_LINE,
  CATEGORY_CONVERGENT,
  CATEGORY_MOBILE,
  CATEGORY_MOBILE_V3,
} from 'modules/tariffs/tariffs.constants'
import { PRO } from 'modules/SharedSales/constants/productCategories'
import {
  READY_FOR_BILLING_ORDER_STATUS,
  ACTIVE_SUBSCRIPTION_STATUS,
  UNSUBSCRIBED_MOBILE_LINE_2P_STATUS,
  INVALID_DISCOUNTS_SUFFIX,
  ADDITIONAL_LINE_DISCOUNT_CATEGORIES,
  CVM_FIXED_PENALTY_KEY,
  CVM_MOBILE_PENALTY_KEY,
  CVM_TRY_AND_BUY_DISCOUNT,
  CVM_INDEFINITE_DISCOUNT_MOBILE,
  CVM_INDEFINITE_DISCOUNT_CONVERGENT,
  CVM_PRO_DISCOUNTS_WITH_PERMANENCY,
  CVM_PRO_DURATIONS_WITH_PERMANENCY,
  CVM_PRO_DISCOUNTS_WITHOUT_PERMANENCY,
  CVM_DISCOUNT_WITHOUT_PERMANENCY,
  CVM_2P_DISCOUNTS,
  CVM_DISCOUNT_AGILE_TV,
  MOBILE_MAIN_TARIFFS_DISCOUNTS,
  RETENTION_ADDITIONAL_LINE_DISCOUNT,
  BUSINESS_PROFILE_IDS,
  INFINITE_CODE,
  SINFIN_30GB,
  MULTISIM_TARIFF,
  ADSL_FIBRA_SINFIN_30GB,
  ADSL_20MB,
  ADSL_20MB_PURE,
  FIBER_100MB_PURE,
  FIBER_600MB_PURE,
  FIBER_1GB_PURE,
  FIBRA_1GB_3P_RESIDENTIAL,
  FIBRA_1GB_3P_PRO,
  TARIFF_1GB_REGEX,
  FIBRA_1GB_3P_RESIDENTIAL_DISCOUNT,
  FIBRA_1GB_2P_RESIDENTIAL_DISCOUNT,
  FIBRA_100MB,
  FIBRA_600MB,
  FIBRA_1GB,
  SINFIN_40GB_IDS_WITH_INDEFINITE_DISCOUNT,
  UNLIMITED_DURATION,
  UNLIMITED_DURATIONS,
  PERMANENT_DOMAINS,
  ADDITIONAL_LINE_DISCOUNTS,
  NETWORK_ISSUE_DISCOUNT,
  MODAL_TITLES,
  MULTIPLE_DISCOUNTS_SEGMENTS,
  MOBILE_INFINITE_GIGAS_DISCOUNTS,
  SINFIN_60GB,
  UNIT_TYPE_FIXED,
  SVA_HEBE_DISCOUNT_CODE,
  CVM_RETENTION_1GB_3P_DISCOUNTS,
  DISCOUNT_DESCRIPTION,
  SVA_FHD_300_CODE_RESIDENTIAL,
  SVA_FHD_300_CODE_PRO,
  CVM_DISCOUNT_RETENTION_MOBILE,
  CVM_DISCOUNT_RETENTION_MOBILE_VALUES,
  PRORETMOV50,
  PRORETMOV100,
  CONT_DISC_TB_20,
  RETDISCMOB_CONT999GP,
  CONV999DISCR01,
  CONV999DISCP01,
  CONVDISC_CS100_6,
  DISCAGILETV03,
  DISCAGILETV06,
  MOB_DISC_TB_PRO,
  FH600DISC003,
  FH600DISC004,
  FH600DISC001,
  FH600DISC002,
  MSEDEDISC,
  CVM_NEW_PORTFOLIO_2R_DISCOUNT,
  CVM_NEW_PORTFOLIO_300MB_PR0_2P_DISCOUNT,
  CVM_NEW_PORTFOLIO_300MB_2P_DISCOUNT,
  CVM_FIBRA_300MB_2P_DISCOUNT,
  WIFI_TOTAL_DISCOUNTS,
  CVM_DISCOUNT_RETENTION_CONVERGENT,
  CVM_DISCOUNT_RETENTION_CONVERGENT_VALUES,
  CVM_DISCOUNT_RETENTION,
  CVM_DISCOUNT_RETENTION_VALUES,
  PRICE_DUO_PRINCIPAL,
  TYPE_TERM,
  COL08DISCP,
  CONVDISC_CS50_3,
  RIGHT_NOW,
  NEXT_DAY,
  IN_TWO_DAYS,
  MULTISIM_DISCOUNT,
  FIBRA_300MB,
  EXTRA_DUO_DISCOUNT_ID,
  DUO_TARIFF_ID,
  DISCOUNT_DISTRIBUIDOR_RES_DISC,
  CVM_DISCOUNT_SINFIN_INFINITE_GB_PRO,
  DISC_PERM,
  AMAZON_DISCOUNT,
  SINFIN_INFINITE_3P,
  CONVERGENT_5_EUROS_DISCOUNT,
  NETFLIX_TARIFFS,
  NETFLIX_DISCOUNT,
  SOFTBUNDLE_NETFLIX_DISCOUNT,
  PRUEBALO_12_PRO_DISCOUNTS,
  PRUEBALO_12_RESIDENTIAL_DISCOUNTS,
  PRUEBALO_18_PRO_DISCOUNTS,
  PRUEBALO_18_RESIDENTIAL_DISCOUNTS,
  PRUEBALO_DIGITAL_12_DISCOUNTS,
  PRUEBALO_DIGITAL_18_DISCOUNTS,
  PRUEBALO_DIGITAL_TARIFFS,
  PRUEBALO_RESIDENTIAL_TARIFFS,
  PRUEBALO_PRO_TARIFFS,
  PRO_CONVERGENT_DISCOUNT_50_PERCENT
} from './constants'

export const is2pWithMobileLineUnsubscribed = sub =>
  UNSUBSCRIBED_MOBILE_LINE_2P_STATUS.includes(sub.subscription_status)

export const isReadyForBilling = order => READY_FOR_BILLING_ORDER_STATUS.includes(order.state)

export const isActiveSubscription = sub =>
  ACTIVE_SUBSCRIPTION_STATUS.includes(sub.subscription_status)

const relativeUnitNames = ['PORCENTAJE', 'Percentage', 'percentage']
const fixedUnitNames = ['IMPORTE', 'Fixed', 'fixed']

export const unitToSymbolMap = {
  ...relativeUnitNames.reduce((acc, r) => ({ ...acc, [r]: '%' }), {}),
  ...fixedUnitNames.reduce((acc, f) => ({ ...acc, [f]: '€' }), {}),
}

export const parseBonusId = (id = '') => {
  const [, durationAndPenalty = ''] = `${id}`.split('_')
  const [duration, penalty] = durationAndPenalty.split('m')
  return {
    duration: Number(duration) || 1,
    penalty: penalty === 'P',
  }
}

const isValidDiscount = discount =>
  !get(discount, 'discount_plan.rule_id', '').endsWith(INVALID_DISCOUNTS_SUFFIX)

export const getCurrentDiscounts = subscription =>
  getDiscounts(subscription).filter(isValidDiscount)

export const applyDiscount = (monthlyFee, discount = {}, applyToInitialFee = false) => {
  const { unit, value, discountCode, partialFee, prices, is2p } = discount

  switch (true) {
    case isCrossSellDiscount(discountCode): {
      const relatedFixedTariffDiscountAmount = calculateCrossSellDiscount(value, {
        discountCode,
        prices,
        taxNeeded: false,
        is2pOrFijo: is2p,
      })

      return applyToInitialFee
        ? relatedFixedTariffDiscountAmount
        : Math.max(Number(monthlyFee) - Number(relatedFixedTariffDiscountAmount), 0)
    }
    // With try&buy discount code, It returns just the discount value
    case discountCode === CVM_TRY_AND_BUY_DISCOUNT:
      return applyToInitialFee ? Number(value) : Math.max(monthlyFee - Number(value), 0)

    case !isNil(partialFee?.taxFeeAmount) && relativeUnitNames.includes(unit): {
      const discountValue = Number(partialFee.taxFeeAmount) * (Number(value) / 100)
      return applyToInitialFee ? discountValue : Math.max(Number(monthlyFee - discountValue), 0)
    }
    case relativeUnitNames.includes(unit):
      return applyToInitialFee
        ? (Number(monthlyFee) * Number(value)) / 100
        : Math.max(Number(monthlyFee * (1 - Number(value) / 100)), 0)
    case fixedUnitNames.includes(unit):
      return applyToInitialFee ? Number(value) : Math.max(monthlyFee - Number(value), 0)
    default:
      return undefined
  }
}

export const includeInfinite = tariffType => tariffType.includes(INFINITE_CODE)

export const includeSinfin30Gb = tariffType =>
  tariffType.includes(SINFIN_30GB) || ADSL_FIBRA_SINFIN_30GB.includes(tariffType)

export const includeMultisim = tariffType => tariffType === MULTISIM_TARIFF

export const includeAdsl20Mb = tariffType =>
  ADSL_20MB.includes(tariffType) || ADSL_20MB_PURE.includes(tariffType)

export const includeFibra100Mb = tariffType =>
  FIBRA_100MB.includes(tariffType) || FIBER_100MB_PURE.includes(tariffType)

export const isFibra300MbTariff = tariff => tariff.id && FIBRA_300MB.includes(tariff.id)

export const includeFibra600Mb = tariffType =>
  FIBRA_600MB.includes(tariffType) || FIBER_600MB_PURE.includes(tariffType)

export const includeFibra1Gb = tariffType =>
  FIBRA_1GB.includes(tariffType) || FIBER_1GB_PURE.includes(tariffType)

export const isPruebaloResidentialTariff = tariff => tariff && tariff.id && PRUEBALO_RESIDENTIAL_TARIFFS.includes(tariff.id)
export const isPruebaloProTariff = tariff => tariff && tariff.id && PRUEBALO_PRO_TARIFFS.includes(tariff.id)
export const isPruebaloDigitalTariff = tariff => tariff && tariff.id && PRUEBALO_DIGITAL_TARIFFS.includes(tariff.id)

export const isMultisimTariff = tariff => tariff && tariff.id && includeMultisim(tariff.id)

export const isInfiniteTariff = tariff => tariff && includeInfinite(tariff.id) && tariff.id

export const isSinfin30GbTariff = tariff => tariff && includeSinfin30Gb(tariff.id) && tariff.id

export const isNetflixTariff = tariff => tariff && tariff.id && NETFLIX_TARIFFS.includes(tariff.id)

export const isFibra1GbTryAndBuyResidentialTariff = tariff =>
  tariff.id && FIBRA_1GB_3P_RESIDENTIAL.includes(tariff.id)

export const isFibra1Gb3pResidentialDiscountTariff = tariff =>
  tariff.id && FIBRA_1GB_3P_RESIDENTIAL_DISCOUNT.includes(tariff.id)

export const isFibra1GbRegEx = tariff => tariff.id && TARIFF_1GB_REGEX.test(tariff.id)

export const isFibra1Gb2pResidentialDiscountTariff = tariff =>
  tariff.id && FIBRA_1GB_2P_RESIDENTIAL_DISCOUNT.includes(tariff.id)

export const isFibra1GbTryAndBuyProTariff = tariff =>
  tariff.id && FIBRA_1GB_3P_PRO.includes(tariff.id)

export const isSinfin60GbTariff = tariff => tariff && tariff.id === SINFIN_60GB

export const isDuoTariff = tariff => tariff && tariff.id === DUO_TARIFF_ID

export const allowTariffIndefiniteDiscountConvergent = tariff =>
  SINFIN_40GB_IDS_WITH_INDEFINITE_DISCOUNT.includes(get(tariff, 'id'))

export const isSinfinInfinite = tariff => tariff && SINFIN_INFINITE_3P.includes(tariff.id)

export const shouldEnableInfiniteOption = (type, infiniteTariff) => {
  // First conditional indicates the current tariff is the infinite one
  // Second conditonal indicates if this one has been chosen on the dropdown item
  if (infiniteTariff) {
    return infiniteTariff
  }
  if (includeInfinite(type) && infiniteTariff === undefined) {
    return type
  }
  return false
}

export const normalizePromo = promo =>
  promo && {
    id: promo.id,
    ...parseBonusId(promo.id),
    ...get(promo, 'attributes', promo),
  }

export const isFixedDiscountUnit = unit => fixedUnitNames.includes(unit)

export const formatDiscountValue = discount =>
  `${isFixedDiscountUnit(discount.unit) ? formatNumber(discount.value) : discount.value}${
    unitToSymbolMap[discount.unit]
  }`

export const isAdditionalLineDiscount = discountCode =>
  ADDITIONAL_LINE_DISCOUNTS.map(d => d.discountCode).includes(discountCode)

export const isRetentionAdditionalLineDiscount = discountCode =>
  RETENTION_ADDITIONAL_LINE_DISCOUNT.includes(discountCode)

const isNetworkIssueDiscount = discountCode => discountCode === NETWORK_ISSUE_DISCOUNT

export const isFiveEurosConvergentDiscount = discountCode => discountCode === 'RETFH600DISCR01'
const isDiscountFiveEurosFijo = discountCode => discountCode === 'RETFH600DISCR02'
export const is2PDiscount = discountCode => CVM_2P_DISCOUNTS.includes(discountCode)

export const isIndefiniteMobileDiscount = discountCode =>
  discountCode === CVM_INDEFINITE_DISCOUNT_MOBILE

export const isIndefiniteConvergentDiscount = discountCode =>
  discountCode === CVM_INDEFINITE_DISCOUNT_CONVERGENT

export const isDiscountAdditionalLineInfiteGb = (discountCode, value) =>
  discountCode === CVM_DISCOUNT_RETENTION_MOBILE &&
  value === CVM_DISCOUNT_RETENTION_MOBILE_VALUES.ADDITIONAL_LINE_INFINITE_GB

const isDiscountSinfin40Gbx20 = (discountCode, value) =>
  discountCode === CVM_DISCOUNT_RETENTION_MOBILE &&
  value === CVM_DISCOUNT_RETENTION_MOBILE_VALUES.SINFIN_40_GB_X_20

const isSinfinMobileDisc = (discountCode, value) =>
  discountCode === CVM_DISCOUNT_RETENTION_MOBILE &&
  (value === CVM_DISCOUNT_RETENTION_MOBILE_VALUES.SINFIN_MOBILE_RETIRED ||
    value === CVM_DISCOUNT_RETENTION_MOBILE_VALUES.SINFIN_MOBILE)

const isSinfinInfiniteGb2Euros = (discountCode, value) =>
  discountCode === CVM_DISCOUNT_RETENTION_MOBILE &&
  value === CVM_DISCOUNT_RETENTION_MOBILE_VALUES.SINFIN_INFINITE_GB_2_EUROS

const isSinfinInfiniteGb4Euros = (discountCode, value) =>
  discountCode === CVM_DISCOUNT_RETENTION_MOBILE &&
  value === CVM_DISCOUNT_RETENTION_MOBILE_VALUES.SINFIN_INFINITE_GB_4_EUROS

const isSinfinInfiniteGb6Euros = (discountCode, value) =>
  discountCode === CVM_DISCOUNT_RETENTION_MOBILE &&
  value === CVM_DISCOUNT_RETENTION_MOBILE_VALUES.SINFIN_INFINITE_GB_6_EUROS

const isDiscountProInfiniteGb = discountCode => discountCode === CVM_DISCOUNT_SINFIN_INFINITE_GB_PRO

export const is100MbAnd15Gb = (discountCode, value) =>
  discountCode === CVM_DISCOUNT_RETENTION_CONVERGENT &&
  value === CVM_DISCOUNT_RETENTION_CONVERGENT_VALUES.CONVERGENT_100_MB_AND_15_GB

export const is100MbAnd40Gb = (discountCode, value) =>
  discountCode === CVM_DISCOUNT_RETENTION_CONVERGENT &&
  value === CVM_DISCOUNT_RETENTION_CONVERGENT_VALUES.CONVERGENT_100_MB_AND_40_GB

export const is100MbDiscount = (discountCode, value) =>
  is100MbAnd15Gb(discountCode, value) || is100MbAnd40Gb(discountCode, value)

export const is100MbPreocupateCero = (discountCode, value) =>
  discountCode === CVM_DISCOUNT_RETENTION_CONVERGENT &&
  value === CVM_DISCOUNT_RETENTION_CONVERGENT_VALUES.PREOCUPATE_CERO_100_MB

export const isRetConv40 = (discountCode, value) =>
  discountCode === CVM_DISCOUNT_RETENTION_CONVERGENT &&
  value === CVM_DISCOUNT_RETENTION_CONVERGENT_VALUES.CONVERGENT_40

export const isRetConv30 = (discountCode, value) =>
  discountCode === CVM_DISCOUNT_RETENTION_CONVERGENT &&
  value === CVM_DISCOUNT_RETENTION_CONVERGENT_VALUES.CONVERGENT_30

export const isSuperDuo = (discountCode, value) =>
  discountCode === CVM_DISCOUNT_RETENTION && value === CVM_DISCOUNT_RETENTION_VALUES.SUPER_DUO

const isMobileInfiniteGigas = discountCode => MOBILE_INFINITE_GIGAS_DISCOUNTS.includes(discountCode)

export const isDiscountAgileTV = discountCode => discountCode === CVM_DISCOUNT_AGILE_TV

export const isMobileMainTariffDiscount = discountCode => MOBILE_MAIN_TARIFFS_DISCOUNTS.includes(discountCode)

export const isDiscountHebe = discountCode => discountCode === SVA_HEBE_DISCOUNT_CODE
export const isDiscountFhd300Residential = discountCode =>
  discountCode === SVA_FHD_300_CODE_RESIDENTIAL
export const isDicountFhd300Pro = discountCode => discountCode === SVA_FHD_300_CODE_PRO

export const isDiscountProret50 = discountCode => discountCode === PRORETMOV50
export const isDiscountProret100 = discountCode => discountCode === PRORETMOV100
export const isDiscountContDiscTB20 = discountCode => discountCode === CONT_DISC_TB_20
export const isDiscountRetDiscMobCont999GP = discountCode => discountCode === RETDISCMOB_CONT999GP
export const isDiscountConv999DiscR01 = discountCode => discountCode === CONV999DISCR01
export const isDiscountConv999DiscP01 = discountCode => discountCode === CONV999DISCP01
export const isDiscountConvDiscCS1006 = discountCode => discountCode === CONVDISC_CS100_6
export const isDiscountDiscAgileTv03 = discountCode => discountCode === DISCAGILETV03
export const isDiscountDiscAgileTV06 = discountCode => discountCode === DISCAGILETV06
export const isDiscountMobDiscTBPRO = discountCode => discountCode === MOB_DISC_TB_PRO
export const isDiscountF600DISC001 = discountCode => discountCode === FH600DISC001
export const isDiscountF600DISC002 = discountCode => discountCode === FH600DISC002
export const isDiscountF600DISC003 = discountCode => discountCode === FH600DISC003
export const isDiscountF600DISC004 = discountCode => discountCode === FH600DISC004
export const isDiscountMsedeDisc = discountCode => discountCode === MSEDEDISC
export const isDiscountCOL08DISCP = discountCode => discountCode === COL08DISCP

export const isDiscount50Fijo = discountCode => discountCode === CONVDISC_CS50_3

const isMultisimDiscount = discountCode => discountCode === MULTISIM_DISCOUNT

export const isNewD2RDiscount = discountCode => discountCode === CVM_NEW_PORTFOLIO_2R_DISCOUNT

export const isNew300MbProDiscount = discountCode =>
  discountCode === CVM_NEW_PORTFOLIO_300MB_PR0_2P_DISCOUNT

export const isNew300MbDiscount = discountCode =>
  discountCode === CVM_NEW_PORTFOLIO_300MB_2P_DISCOUNT

export const is300Mb2rDiscount = discountCode => discountCode === CVM_FIBRA_300MB_2P_DISCOUNT

export const isWifiTotalDiscount = discountCode => WIFI_TOTAL_DISCOUNTS.includes(discountCode)

export const isNetflixDiscount = discountCode => discountCode === NETFLIX_DISCOUNT
export const isSoftbundleNetflixDiscount = discountCode => discountCode === SOFTBUNDLE_NETFLIX_DISCOUNT

const is1GbDiscount = (discountCode, value) =>
  (discountCode === CVM_DISCOUNT_RETENTION &&
  ((value === CVM_DISCOUNT_RETENTION_VALUES.TARIFF_1GB_5_EUROS) ||
  value === CVM_DISCOUNT_RETENTION_VALUES.TARIFF_1GB_10_EUROS))

export const isDuoDiscount = discountCode => EXTRA_DUO_DISCOUNT_ID === discountCode

export const isDistribuidorResDisc = discountCode => discountCode === DISCOUNT_DISTRIBUIDOR_RES_DISC

export const isRetention1gbDiscount = discountCode =>
  CVM_RETENTION_1GB_3P_DISCOUNTS.includes(discountCode)

export const isDiscPerm = discountCode => discountCode === DISC_PERM

export const isAmazonDiscount = discountCode => discountCode === AMAZON_DISCOUNT

export const isConvergent5EurosDiscount = discountCode => discountCode === CONVERGENT_5_EUROS_DISCOUNT

export const isPruebaloResidentialDiscount = discountCode => 
  PRUEBALO_18_RESIDENTIAL_DISCOUNTS.includes(discountCode) || 
  PRUEBALO_12_RESIDENTIAL_DISCOUNTS.includes(discountCode)

export const isPruebaloProDiscount = discountCode => 
PRUEBALO_18_PRO_DISCOUNTS.includes(discountCode) || 
PRUEBALO_12_PRO_DISCOUNTS.includes(discountCode)

export const isPruebaloDigitalDiscount = discountCode => 
  PRUEBALO_DIGITAL_18_DISCOUNTS.includes(discountCode) ||
  PRUEBALO_DIGITAL_12_DISCOUNTS.includes(discountCode)

export const isConvergentDiscount50Pro = discountCode => discountCode === PRO_CONVERGENT_DISCOUNT_50_PERCENT

const getDescriptionDiscount = discountCode => DISCOUNT_DESCRIPTION.includes(discountCode)

export const formatDiscountName = (discount, profileId) => {
  const { discountCode: code, value, description } = discount

  if (profileId === PRO.toUpperCase() && discount.description) {
    return discount.description
  }

  switch (true) {
    // Se comenta porque se deshabilita temporalmente
    /* case isAdditionalLineDiscount(code):
      return `Promoción ${formatDiscountValue(discount)} línea adicional` */

    case is100MbPreocupateCero(code, value):
      return '100Mb + nueva Preocúpate cero'

    case is100MbAnd40Gb(code, value):
      return '100Mb + 40GB a 30€'

    case is100MbAnd15Gb(code, value):
      return '100Mb + 15GB a 25€'

    case isRetentionAdditionalLineDiscount(code):
      return 'Descuento Línea Adicional'

    case isNetworkIssueDiscount(code):
      return `Descuento ${formatDiscountValue(discount)} por incidencia de red`

    case isIndefiniteMobileDiscount(code):
      return 'Descuento móvil indefinido'

    case isIndefiniteConvergentDiscount(code):
      return 'Descuento convergente indefinido'

    case isFiveEurosConvergentDiscount(code):
      return 'Descuento Fibra 600 -  5€. Convergente'

    case isDiscountFiveEurosFijo(code):
      return 'Descuento Fibra 600 -  5€. Solo Fijo'

    case is2PDiscount(code):
      return 'Descuento Segunda Residencia 2P'

    case isDiscountSinfin40Gbx20(code, value):
      return 'Sinfin 40GB a 20€'

    case isSinfinInfiniteGb2Euros(code, value):
      return 'Descuento 2€ sobre gigas infinitos 35€'

    case isSinfinInfiniteGb4Euros(code, value):
      return 'Descuento 4€ Sinfín gigas infinitos'

    case isSinfinInfiniteGb6Euros(code, value):
      return 'Descuento 6€ Sinfín gigas infinitos'

    case isDiscountProInfiniteGb(code, value):
      return 'Descuento PRO 2,42€ sobre gigas infinitos 38€'

    case isSinfinMobileDisc(code, value):
      return 'Retención Gigas Infinitos'

    case isDiscountAdditionalLineInfiteGb(code, value):
      return 'Descuento de Línea Adicional Sinfín GB Infinitos'

    case isMobileInfiniteGigas(code):
      return 'Descuento retención móvil gigas infinitos'

    case isDiscountHebe(code):
      return 'Descuento Preocúpate Cero'

    case isDiscountFhd300Residential(code):
      return 'Descuento de 3 meses de Fibra 1GB a precio de 300'

    case isDiscountContDiscTB20(code):
      return 'Descuento Try & Buy'

    case isDiscountRetDiscMobCont999GP(code):
      return 'Dto Retención Móvil GB Infinitos'

    case isDiscountConv999DiscR01(code):
      return 'Descuento ADSL/Fibra GBs Infinitos'

    case isDiscountConv999DiscP01(code):
      return 'Descuento ADSL/Fibra GBs Infinitos PRO'

    case isDiscountConvDiscCS1006(code):
      return 'Descuento xsell 100% línea fija'

    case isDiscountDiscAgileTv03(code):
      return 'Descuento TV service + fiber 3 euros'

    case isDiscountDiscAgileTV06(code):
      return 'Descuento TV service + fiber 6 euros'

    case isDiscountMobDiscTBPRO(code):
      return 'Descuento promoción GB Infinitos PRO'

    case isDiscountF600DISC001(code):
      return 'Descuento promoción fibra 600 MB 3P PRO'

    case isDiscountF600DISC002(code):
      return 'Descuento promoción fibra 600 MB 2P PRO'

    case isDiscountF600DISC003(code):
      return 'Descuento promoción fibra 600 MB 3P'

    case isDiscountF600DISC004(code):
      return 'Descuento promoción fibra 600 MB 2P'

    case isDiscountMsedeDisc(code):
      return 'Descuento multisede PRO'

    case isDiscountCOL08DISCP(code):
      return 'Descuento de Colectivos'

    case isMultisimDiscount(code):
      return 'Descuento MultiSim'

    case isSuperDuo(code, value):
      return 'Descuento 3€ SuperDuo'

    case isDiscount50Fijo(code):
      return '50% Suspensión temporal Fijo'

    case isNewD2RDiscount(code):
      return 'Descuento Internet segunda residencia'

    case isNew300MbProDiscount(code):
      return 'Descuento 2P Fibra 300 (-12eur)'

    case isNew300MbDiscount(code):
      return 'Descuento 2P Fibra 300 (-12,1eur)'

    case is300Mb2rDiscount(code):
      return 'Descuento segunda Residencia_300'

    case is1GbDiscount(code, value) || getDescriptionDiscount(code):
      return description

    default:
      return `Promoción ${formatDiscountValue(discount)}`
  }
}

export const formatDuration = months => `${months} mes${months > 1 ? 'es' : ''}`

export const formatDiscountNameAndDuration = (discount, profileId) =>
  `${formatDiscountName(discount, profileId)} ${
    discount.duration !== UNLIMITED_DURATION ? formatDuration(discount.duration) : ''
  }`

export const formatBonusName = bonus =>
  bonus.amount ? `Bono ${bonus.amount} ${bonus.unit}` : bonus.attributes.commercial_name

export const formatDiscountsAmounts = (options, profileId) =>
  sortBy(
    uniqBy(
      options.reduce((acum, discount) => {
        const amounts = discount.amounts.map(amount => ({
          discountCode: discount.discountCode,
          value: amount.value,
          unit: amount.unit,
          description: head(amount.durations).description,
        }))
        return [...acum, ...amounts]
      }, []),
      d => formatDiscountName(d, profileId),
    ),
    ['description', 'unit', 'value'],
  )

export const formatDiscountsDurations = (options, { value, unit }) =>
  sortBy(
    uniqBy(
      options.reduce((acum, o) => {
        const matchingAmount = o.amounts.find(a => a.value === value && a.unit === unit)
        if (matchingAmount) {
          return [
            ...acum,
            ...matchingAmount.durations.map(duration => ({
              ...duration,
            })),
          ]
        }
        return acum
      }, []),
      o => o.value,
    ),
    'value',
  ).filter(d => {
    return (
      d.value !== UNLIMITED_DURATION ||
      (d.value === UNLIMITED_DURATION &&
        (is100MbDiscount(head(options).discountCode, value) ||
          isFiveEurosConvergentDiscount(head(options).discountCode, value)))
    )
  })

export const formatGB = mb => `${Number(mb / 1024).toFixed(mb % 1024 && 1)}GB`

export const formatBundleName = (bundle, taxRate) => {
  const bundlePriceWithTaxes = applyTax(taxRate, Number(bundle.monthly_fee)) // Aplicamos los impuestos para todo el mundo, comprobar aquí si es tipo pro u otro.
  switch (bundle.mobile_data_amount_mb) {
    case 1024:
      return `Bono 1GB - ${formatFee(bundlePriceWithTaxes)}`
    case 1536:
      return `Bono 1'5GB - ${formatFee(bundlePriceWithTaxes)}`
    case 3072:
      return `Bono 3GB - ${formatFee(bundlePriceWithTaxes)}`
    default:
      return `Bono promocional ${formatGB(bundle.mobile_data_amount_mb)}`
  }
}

export const getDiscountInfo = (
  discountOptions,
  { discountCode, value, unit, duration, permanency },
) => {
  const discountOptionsSimplified = discountOptions.reduce((acum, discount) => {
    const amounts = discount.amounts.reduce((m, a) => {
      const durations = a.durations.map(d => ({
        discountCode: discount.discountCode,
        value: a.value,
        unit: a.unit,
        duration: d.value,
        permanency,
      }))
      return [...m, ...durations]
    }, [])
    return [...acum, ...amounts]
  }, [])
  return discountOptionsSimplified.find(
    d =>
      d.discountCode === discountCode &&
      d.value === value &&
      d.unit === unit &&
      d.duration === duration,
  )
}

const getProductTypeName = product => {
  const names = { [FIXED_PRODUCT_NAME]: 'Internet', [MOBILE_PRODUCT_NAME]: 'Móvil' }
  return names[product] || ''
}

export const formatCardSubtitle = subscription => {
  const types = getProducts(subscription).map(p => p.name)
  const subtitle = types.map(getProductTypeName).join(' + ')
  return isConvergentSubscription(subscription) ? `Línea Fija + ${subtitle}` : subtitle
}

export const formatRange = end => {
  let date = startOfDay(Date.now())
  const years = differenceInYears(parseISO(end), date)
  date = addYears(date, years)
  const months = differenceInMonths(parseISO(end), date)
  date = addMonths(date, months)
  const days = differenceInDays(parseISO(end), date)

  let range = ''
  if (days) range = `${days} días`
  if (months) range = `${months} meses, ${range}`
  if (years) range = `${years} año, ${range}`
  // NOTE: Remove trailing comma
  return range.endsWith(', ') ? range.slice(0, -2) : range
}

export const isUnlimitedDiscountByDates = (start, end) =>
  UNLIMITED_DURATIONS.includes(differenceInMonths(end, start) + 1)

export function formatPermanentFromDetails(endDateTime, fees) {
  if (!isNil(endDateTime) && !isNil(fees)) {
    return `${formatRange(endDateTime)}
    (${formatFee(fees)})`
  }

  return '-'
}

export function formatPermanent(permanent) {
  if (permanent) {
    return formatPermanentFromDetails(
      permanent.validFor.endDateTime,
      getFeesPendingFromPermanent(permanent),
    )
  }

  return '-'
}

const getPermanentDomain = id => {
  const [prefix] = id.match(/^[DUF]?TERM/) || []
  return PERMANENT_DOMAINS[prefix]
}

export const fullyFormatPermanent = permanent =>
  `Por ${getPermanentDomain(permanent.id)} - ${formatPermanent(permanent)}`

const hasTariffChanged = modifications => Boolean(modifications.confirmedTariff)

const hasBonusesAdded = modifications => Boolean(get(modifications, 'confirmedBonuses', []).length)

const hasDiscountsAdded = modifications =>
  Boolean(get(modifications, 'confirmedDiscounts', []).length)

export const hasSubscriptionChanged = modifications =>
  hasTariffChanged(modifications) ||
  hasBonusesAdded(modifications) ||
  hasDiscountsAdded(modifications)

export const isPrepaidPaymentType = paymentType => paymentType === PREPAID_CHARACTERISTIC_NAME

export const isAdditionalDiscount = discount =>
  ADDITIONAL_LINE_DISCOUNT_CATEGORIES.includes(get(discount, 'discount_plan.discount_category'))

export const isAdditionalLine = sub => Boolean(getDiscounts(sub).find(isAdditionalDiscount))

export const getSfidSuffix = sfid => {
  let suffixMatch = sfid.match(/.+(_.+)/)

  if (isNil(suffixMatch)) {
    suffixMatch = sfid.match(/(_.+)/)
  }

  return suffixMatch && suffixMatch[1]
}

export const extractOfferResume = (customerId, offer, portOutInfo, tariffId, profileId) => {
  const discount = get(offer, 'confirmedDiscounts.[0]')
  const bundle = get(offer, 'confirmedBonuses.[0]', {})
  const mobilePenaltyCondoned = get(offer, `${CVM_MOBILE_PENALTY_KEY}.penaltyCondoned`, false)
  const fixedPenaltyCondoned = get(offer, `${CVM_FIXED_PENALTY_KEY}.penaltyCondoned`, false)
  const mobilePermanenceRemoved = get(offer, `${CVM_MOBILE_PENALTY_KEY}.permanenceRemoved`, false)
  const fixedPermanenceRemoved = get(offer, `${CVM_FIXED_PENALTY_KEY}.permanenceRemoved`, false)
  const discountPermanence = get(discount, 'permanency')

  const resume = {
    nif: get(customerId, 'identificationId'),
    stc: get(offer, 'confirmedTariff.commercial_name'),
    discounts: discount && formatDiscountNameAndDuration(discount, profileId),
    discountPermanence,
    bundles: bundle.id && formatBonusName(bundle),
    bundleId: bundle.id,
    bundlePermanence: parseBonusId(bundle.id).penalty,
    penaltyCondoned:
      mobilePenaltyCondoned ||
      fixedPenaltyCondoned ||
      mobilePermanenceRemoved ||
      fixedPermanenceRemoved,
    portOut: Boolean(portOutInfo),
    portOutCompany: get(portOutInfo, 'ReceptorOperatorName'),
    clitypeOrigin: tariffId,
    billcodeDisc: discount && discount.discountCode,
    channel: profileId,
    clitypeTarget: get(offer, 'confirmedTariff.id'),
  }

  return pickBy(resume, Boolean)
}

export const isConvergentOrder = order => Object.keys(get(order, 'note', {})).length === 2

export const PRODUCT_TO_PERMANENT_ORDER = {
  fixed: {
    main: ['FTERM'],
    secondary: ['DTERM', 'UTERM'],
  },
  mobile: {
    main: ['TERM'],
    secondary: ['DTERM', 'UTERM'],
  },
}

const findPermanentsByOrder = (product, order, permanents = []) => {
  const filtered = permanents.filter(p => {
    const [prefix] = p.id.match(/^[DUF]?TERM/) || []
    return get(PRODUCT_TO_PERMANENT_ORDER, `${product}.${order}`, []).includes(prefix)
  })
  return filtered.length ? filtered : undefined
}

const findMainPermanents = (product, permanents = []) =>
  findPermanentsByOrder(product, 'main', permanents)

const findSecondaryPermanents = (product, permanents = []) =>
  findPermanentsByOrder(product, 'secondary', permanents)

const computeTotalPermanent = (permanents = []) => {
  const pending = permanents.reduce((total, p) => total + p.fees.pending, 0)
  const [endDateTime] = permanents
    .map(p => p.validFor.endDateTime)
    .sort()
    .reverse()
  return {
    fees: { pending },
    validFor: { endDateTime },
  }
}

export const findTotalPermanent = (product, permanents) => {
  const existingPermanents =
    findMainPermanents(product, permanents) || findSecondaryPermanents(product, permanents)
  return computeTotalPermanent(existingPermanents)
}

export const findMobileTotalPermanent = subscription =>
  findTotalPermanent(CVM_MOBILE_PENALTY_KEY, subscription.contracts.permanents)

export const findFixedTotalPermanent = subscription =>
  findTotalPermanent(CVM_FIXED_PENALTY_KEY, subscription.contracts.permanents)

export const setModalTitle = segment => {
  switch (segment) {
    case 'PRO-SOHO-AUTONOMO':
    case 'PRO-SOHO-COMPANY':
      return MODAL_TITLES.PRO
    default:
      return MODAL_TITLES.RESIDENTIAL
  }
}

export const isProSegment = segment => MULTIPLE_DISCOUNTS_SEGMENTS.includes(segment)

export const shouldApplyMultipleDiscounts = (segment, selectedDiscounts, isPrepaidTariff) =>
  isProSegment(segment) ? true : selectedDiscounts.length === 0 && !isPrepaidTariff

export const hasGenericDiscountPermanency = duration => duration === 12 || duration === 18 || duration === 24

export const hasProDiscountWithoutPermanency = discountCode =>
  includes(CVM_PRO_DISCOUNTS_WITHOUT_PERMANENCY, discountCode)

const hasDiscountWithoutPermanency = discountCode =>
  CVM_DISCOUNT_WITHOUT_PERMANENCY.includes(discountCode)

export const hasSohoDiscountPermanency = (discountCode, duration) => {
  if (!discountCode || !duration) {
    return false
  }

  const discountWithPermanency = includes(CVM_PRO_DISCOUNTS_WITH_PERMANENCY, discountCode)
  const durationWithPermanency = includes(CVM_PRO_DURATIONS_WITH_PERMANENCY, duration)
  return discountWithPermanency && durationWithPermanency
}

export const hasDiscountPermanency = (
  discountCode,
  value,
  duration,
  is100MbTariffDiscount,
  profileId = null,
) => {
  const isSohoOrProProfile = includes(BUSINESS_PROFILE_IDS, profileId)

  if (
    (isSohoOrProProfile && hasProDiscountWithoutPermanency(discountCode)) ||
    isSinfinInfiniteGb2Euros(discountCode, value) ||
    isSuperDuo(discountCode, value) ||
    is1GbDiscount(discountCode, value) ||
    hasDiscountWithoutPermanency(discountCode) ||
    isDiscountProInfiniteGb(discountCode) ||
    isNetflixDiscount(discountCode) || 
    isSoftbundleNetflixDiscount(discountCode)
  ) {
    return false
  }

  let hasDiscount = hasGenericDiscountPermanency(duration)

  if (duration !== '' && !hasDiscount) {
    if (isSohoOrProProfile) {
      hasDiscount = hasSohoDiscountPermanency(discountCode, duration)
    } else if (is100MbTariffDiscount) {
      hasDiscount = is100MbTariffDiscount
    }
  }
  return hasDiscount
}

export const getPermanencyId = duration => `DTERM${duration}-${duration}0`

export const getMsisdnByTypeSubscription = subscription =>
  isElFijoTariff(get(subscription, 'type'))
    ? getElFijoMsisdn(subscription)
    : getMsisdn(subscription)

export const pickDiscountsProperties = (
  discounts,
  properties = ['discountId', 'discountCode', 'name', 'value', 'unit'],
) => discounts.map(discount => pick(discount, properties))

export const applyTaxesToDiscount = (discount, taxRate) => {
  const { unit } = discount
  let { value: discountAmount } = discount

  if (unit === UNIT_TYPE_FIXED) {
    discountAmount = applyTax(taxRate, Number(discountAmount))
  }

  return { ...discount, value: discountAmount }
}

export const getOtherFeesCvm = (
  subscription,
  tax,
  withoutTerminalFee,
  taxNeeded,
  enabledTaxAgileTv,
) => {
  const vasFee = getMontlhyVasFee(subscription, tax, taxNeeded, enabledTaxAgileTv)
  const terminalFee = getTerminalsFee(subscription)

  if (withoutTerminalFee) {
    return vasFee
  }

  return vasFee + terminalFee
}

const getCustomerAndCustomerProductWl = (order, product) => {
  const customer = get(order, 'customer')
  let customerProduct

  if (isNil(product)) {
    customerProduct = head(get(customer, 'products'))
  }

  return { customer, customerProduct }
}

export const get2pWelcomeLetterData = (
  order,
  postCode,
  segment,
  tax,
  { product = null, fixedLineNumber = null } = {},
) => {
  const { customer, customerProduct } = getCustomerAndCustomerProductWl(order, product)
  const monthlyFee = get(product, 'monthly_fee') || get(customerProduct, 'monthly_fee')

  return {
    clientName: get(customer, 'firstName'),
    fixedLineNumber: fixedLineNumber || get(customerProduct, 'id'),
    paymentType: get(product, 'payment_type') || get(customerProduct, 'payment_type'),
    taxZipCode: postCode,
    price: applyTax(tax, monthlyFee).toFixed(2),
    segment: isProCustomer(segment) ? 'pro' : 'residential',
    tariffName: get(product, 'commercial_name') || get(customerProduct, 'description'),
    tariffType: ONLY_FIXED,
  }
}

export const getElFijoWelcomeLetterData = (order, postCode, product = null) => {
  const { customerProduct } = getCustomerAndCustomerProductWl(order, product)

  return {
    tariffName: get(product, 'commercial_name') || get(customerProduct, 'description'),
    taxZipCode: postCode,
    tariffType: FIXED_MOBILE,
  }
}

export const is2pFreeLineTariffStc = tariff => get(tariff, 'tariffType') === CATEGORY_2P_FREE_LINE

export const isConvergentTariffStc = tariff => get(tariff, 'tariffType') === CATEGORY_CONVERGENT

export const getMonthlyFeeTariff = tariff =>
  get(tariff, 'totalPriceFreeTax', 0) ||
  get(findFee(tariff), 'price.amount', 0) ||
  get(tariff, 'monthly_fee', 0)

export const getMonthlyPricesTaxFree = tariff =>
  reduce(get(tariff, 'prices'), (acc, price) => acc + get(price, 'taxFreeAmount'), 0)

export const generateTariffDuoPrincipal = tax => ({
  confirmedTariff: {
    prices: [
      {
        taxFreeAmount: PRICE_DUO_PRINCIPAL,
        taxRate: tax,
        taxIncludedAmount: applyTax(tax, PRICE_DUO_PRINCIPAL),
        discounts: [],
        priceSpecification: null,
      },
    ],
    id: 'CONT28',
    commercial_name: 'La Duo Principal',
    totalPriceFreeTax: PRICE_DUO_PRINCIPAL,
  },
  confirmedDiscounts: [],
  isDuoPrincipalNewTariff: true,
})

export const getTypeMigration = (categoryCurrentSubscription, categoryNewSubscription) =>
  categoryCurrentSubscription === CATEGORY_MOBILE &&
  categoryNewSubscription === CATEGORY_MOBILE_V3 &&
  categoryCurrentSubscription !== categoryNewSubscription
    ? 'plan-migration'
    : 'plan-change'

export const getTariff = (showV3Tariffs, filteredEnrichTariffs, psIdOrPoIdTariff) =>
  filteredEnrichTariffs.find(t => {
    const idType = showV3Tariffs ? 'poId' : 'id'

    return get(t, idType) === psIdOrPoIdTariff
  })

export const getActivationDate = date => {
  const today = Date.now()

  switch (date) {
    case RIGHT_NOW.value:
      return formatDateYYMMDD(today)

    case NEXT_DAY.value:
      return formatDateYYMMDD(addDays(today, 1))

    case IN_TWO_DAYS.value:
      return formatDateYYMMDD(addDays(today, 2))

    default:
      return formatDateYYMMDD(startOfMonth(addMonths(today, 1)))
  }
}

export const getPermanenciesMessage = (codePermanency, penal) => {
  const typePermanency = codePermanency.match(/[a-zA-Z]+/g)[0]
  return `Este cambio implica el cobro de una penalización de ${penal}€ por ${TYPE_TERM[typePermanency]}`
}

/* eslint-disable no-unused-vars */
export const filterModifications = modifications =>
  Object.fromEntries(
    Object.entries(modifications).filter(
      ([key, modification]) => !get(modification, 'isDuoPrincipalNewTariff', false),
    ),
  )

const NOT_UPSELL_BUNDLE_AMOUNTS = [1024, 1536, 200, 3072]

export function isUpsellBundleAmount(amount) {
  return !NOT_UPSELL_BUNDLE_AMOUNTS.includes(amount)
}

export const getSubscriptionFeeAmount = subscription => get(findFee(subscription), 'price.amount')

export const getIsPrepaidTariff = (subscription, newPaymentType) => {
  const currentPaymentType = getPaymentType(subscription)
  const paymentType = newPaymentType || currentPaymentType

  return isPrepaidPaymentType(paymentType)
}

const getDigitProvince = postCode => postCode.substring(0, 2)

export const checkProvince = (actualAddress, newAddress) => {
  const postCodeActualAddress = get(actualAddress, 'postCode')
  const postCodeNewAddress = get(newAddress, 'zipCode')

  return getDigitProvince(postCodeActualAddress) === getDigitProvince(postCodeNewAddress)
}
