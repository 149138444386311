import { PropTypes } from 'prop-types'
import { get } from 'lodash'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { helpers as customer360SvcHelpers } from 'services/customer-360/helpers'
import { selectURLProductId } from 'services/customer-products/selectors'
import { objectToQueryString } from 'modules/url'
import { ProductDashboardContainer } from '../../containers/ProductDashboardContainer'

export const ProductRule = ({ contracts, parentUrl, queryValues }) => {
  const lineId = useSelector(selectURLProductId)
  const line = customer360SvcHelpers.getLineByLineId(contracts, lineId)
  const isOnFly = customer360SvcHelpers.isLineOnFly(line)
  const orderId = get(line, 'orderId')
  const urlOrder = `${parentUrl}/order/${orderId}`
  const urlRedirectOrder = queryValues
    ? [urlOrder, objectToQueryString(queryValues)].join('?')
    : urlOrder

  return !isOnFly ? <ProductDashboardContainer /> : <Redirect to={urlRedirectOrder} />
}

ProductRule.propTypes = {
  contracts: PropTypes.array,
  parentUrl: PropTypes.string,
  queryValues: PropTypes.object,
}
