import styled from 'styled-components'
import PropTypes from 'prop-types'
import { ConfigLine } from 'components/ui'
import {
  getMobileLineNumber,
  getFixedLineNumber,
  hasInternetConnection,
} from 'services/subscriptions'

import { useSubscriptionStatus } from '../../hooks/useSubscriptionStatus'

export const LinesItem = styled(({ className, subscription }) => {
  const mobileNumber = getMobileLineNumber(subscription)
  const fixedNumber = getFixedLineNumber(subscription)
  const isInternetConnected = hasInternetConnection(subscription)

  const { mobileStatus, fixedStatus } = useSubscriptionStatus(subscription)

  return (
    <div className={`lines-item ${className}`}>
      {mobileNumber && (
        <ConfigLine
          type="mobile"
          line={mobileNumber}
          status={mobileStatus.current}
          newStatus={mobileStatus.modified}
        />
      )}
      {fixedNumber && (
        <ConfigLine
          type="fixed"
          line={fixedNumber}
          status={fixedStatus.current}
          newStatus={fixedStatus.modified}
        />
      )}
      {isInternetConnected && (
        <ConfigLine
          type="internet"
          line="Internet"
          status={fixedStatus.current}
          newStatus={fixedStatus.modified}
        />
      )}
    </div>
  )
})`
  display: flex;
  & .config-line {
    margin-right: 8px;
  }
`

LinesItem.propTypes = {
  className: PropTypes.string,
  subscription: PropTypes.object,
}
