export const coverageActionTypes = {
  FIND_COVERAGE: 'OVID/COVERAGE/FINDCOVERAGE',
  FIND_COVERAGE_SUCCESS: 'OVID/COVERAGE/FIND_COVERAGE_SUCCESS',
  FIND_COVERAGE_FAILED: 'OVID/COVERAGE/FIND_COVERAGE_FAILED',
  COVERAGE_RESET: 'OVID/COVERAGE/COVERAGE_RESET',
  FIND_COVERAGE_NOT_FOUND: 'OVID/COVERAGE/FIND_COVERAGE_NOT_FOUND',
  GET_COVERAGE_TOKEN: 'OVID/GET_COVERAGE_TOKEN',
  GET_COVERAGE_TOKEN_SUCCESS: 'OVID/GET_COVERAGE_TOKEN_SUCCESS',
  GET_COVERAGE_TOKEN_FAILED: 'OVID/GET_COVERAGE_TOKEN_FAILED',
  GET_COVERAGE_DATA_SUCCESS: 'OVID/GET_COVERAGE_DATA_SUCCESS',
  GET_COVERAGE: 'OVID/COVERAGE/GET_COVERAGE',
  GET_COVERAGE_SUCCESS: 'OVID/COVERAGE/GET_COVERAGE_SUCCESS',
  GET_COVERAGE_RESET: 'OVID/COVERAGE/GET_COVERAGE_RESET',
  SET_SELECTED_TECHNOLOGY: 'OVID/COVERAGE/SET_SELECTED_TECHNOLOGY',

  GET_ACCESS_TOKEN: 'OVID/COVERAGE/GET_ACCESS_TOKEN',
  GET_ACCESS_TOKEN_SUCCESS: 'OVID/COVERAGE/GET_ACCESS_TOKEN_SUCCESS',
  GET_ACCESS_TOKEN_FAILED: 'OVID/COVERAGE/GET_ACCESS_TOKEN_FAILED',

  SET_COVERAGE: 'OVID/COVERAGE/PRESALE/SET_COVERAGE',
}

const findCoverage = payload => ({
  type: coverageActionTypes.FIND_COVERAGE,
  payload,
})

const findCoverageSuccess = data => ({
  type: coverageActionTypes.FIND_COVERAGE_SUCCESS,
  payload: {
    ...data,
  },
})

const findCoverageFailed = error => ({
  type: coverageActionTypes.FIND_COVERAGE_FAILED,
  payload: {
    ...error,
  },
})

const coverageReset = () => ({
  type: coverageActionTypes.COVERAGE_RESET,
})

const getCoverageToken = payload => ({
  type: coverageActionTypes.GET_COVERAGE_TOKEN,
  payload,
})

const getCoverageTokenSuccess = token => ({
  type: coverageActionTypes.GET_COVERAGE_TOKEN_SUCCESS,
  payload: { token },
})

const getCoverageFailed = error => ({
  type: coverageActionTypes.GET_COVERAGE_TOKEN_FAILED,
  payload: { error },
})

const gotCoverageData = data => ({
  type: coverageActionTypes.GET_COVERAGE_DATA_SUCCESS,
  payload: { data },
})

export const getCoverageAction = coverage => ({
  type: coverageActionTypes.GET_COVERAGE,
  payload: { coverage },
})

const getCoverageSuccess = coverage => ({
  type: coverageActionTypes.GET_COVERAGE_SUCCESS,
  payload: { coverage },
})

const getCoverageReset = () => ({
  type: coverageActionTypes.GET_COVERAGE_RESET,
})

const getAccessToken = () => {
  return {
    type: coverageActionTypes.GET_ACCESS_TOKEN,
  }
}

const getAccessTokenSuccess = accessToken => ({
  type: coverageActionTypes.GET_ACCESS_TOKEN_SUCCESS,
  payload: { accessToken },
})

const getAccessTokenError = error => ({
  type: coverageActionTypes.GET_ACCESS_TOKEN_FAILED,
  payload: { error },
})

const setCoverage = payload => ({
  type: coverageActionTypes.SET_COVERAGE,
  payload,
})

export const actions = {
  findCoverage,
  findCoverageSuccess,
  findCoverageFailed,
  coverageReset,
  getCoverageToken,
  getCoverageTokenSuccess,
  getCoverageFailed,
  gotCoverageData,
  getCoverageAction,
  getCoverageSuccess,
  getCoverageReset,
  getAccessToken,
  getAccessTokenSuccess,
  getAccessTokenError,
  setCoverage,
}
