import { constants } from './constants'

export const reducer = (
  state = {
    error_code: null,
    error_message: null,
    loading: null,
    cancellationOptions: { data: [{ value: '0', text: 'Ninguna opción válida' }], loading: null },
  },
  action,
) => {
  const { type, payload } = action

  switch (type) {
    case constants.FIND_ORDERONFLY_INIT: {
      return {
        data: null,
        error_code: null,
        error_message: null,
        loading: true,
      }
    }
    case constants.FIND_ORDERONFLY_SUCCESS: {
      return {
        ...state,
        error_code: null,
        error_message: null,
        ...payload,
        loading: false,
      }
    }
    case constants.FIND_ORDERONFLY_FAILED: {
      return {
        ...state,
        error_code: payload.httpCode,
        error_message: payload.info,
        data: null,
        loading: false,
      }
    }

    default:
      return state
  }
}
