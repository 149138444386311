import { useState } from 'react'
import styled from 'styled-components'
import { get } from 'lodash'
import { getLighten } from 'utils'
import { Box } from '@material-ui/core'
import { Tabs } from 'components/ui'
import { Card } from 'modules/ui'
import { TabClientSvas } from './TabClientSvas'

const CardWrapper = styled(Card)`
  .tabHeader {
    padding: 0;
    border-bottom: 2px solid
      ${props => getLighten(get(props, 'theme.palette.global.gray_medium'), 0.8)};
  }
`

export const ClientServicesCard = () => {
  const [selectedTab, setSelectedTab] = useState('0')
  const tabsInfo = [{ label: 'svas', val: '0', component: TabClientSvas, data_hook: 'svas-tab' }]

  const TabContent = get(tabsInfo, `${selectedTab}.component`)
  const onSelectChangeHandler = (_, tab) => setSelectedTab(tab)

  return (
    <>
      <CardWrapper margin="0" title="Servicios del Cliente" withShadow>
        <Box display="flex" className="tabHeader">
          <Tabs
            val={selectedTab}
            tabsInfo={tabsInfo}
            onSelectChange={onSelectChangeHandler}
            data_hook={`${tabsInfo}`}
          />
        </Box>
        <Box display="flex" className="tabContent">
          <TabContent />
        </Box>
      </CardWrapper>
    </>
  )
}
