import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { ButtonLink } from '../buttons/ButtonLink'

const useStyles = makeStyles({
  'advice-action-wrapper': {
    display: 'flex',
    'align-items': 'center',
    'margin-top': props => props.marginTop,
    'margin-bottom': props => props.marginBottom,
  },
  'advice-action-wrapper-options': {
    display: 'inline-block',
    'padding-left': '21px',
    'padding-right': '22px',
    '& button:not(:last-child)': {
      'padding-right': '22px',
    },
  },
})

export function ActionsWrapper({
  className = '',
  dataHook,
  children,
  onUpdate,
  onCancel,
  onDelete,
  onDisable,
  onComplete,
  onRetry,
}) {
  const classes = useStyles()

  return (
    <div
      data-hook={dataHook || 'alert-advice'}
      className={`advice-wrapper ${className} ${classes['advice-action-wrapper']}`}>
      {children}
      {onUpdate || onCancel || onDelete || onComplete || onDisable || onRetry ? (
        <div className={classes['advice-action-wrapper-options']}>
          {onUpdate ? <ButtonLink onClick={onUpdate}>Modificar</ButtonLink> : null}
          {onCancel ? <ButtonLink onClick={onCancel}>Cancelar</ButtonLink> : null}
          {onDelete ? <ButtonLink onClick={onDelete}>Eliminar</ButtonLink> : null}
          {onDisable ? <ButtonLink onClick={onDisable}>Desactivar</ButtonLink> : null}
          {onComplete ? <ButtonLink onClick={onComplete}>Completar datos</ButtonLink> : null}
          {onRetry ? <ButtonLink onClick={onRetry}>Reintentar</ButtonLink> : null}
        </div>
      ) : null}
    </div>
  )
}

ActionsWrapper.defaultProps = {
  className: '',
}

ActionsWrapper.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  onDelete: PropTypes.func,
  onUpdate: PropTypes.func,
  onCancel: PropTypes.func,
  onDisable: PropTypes.func,
  onComplete: PropTypes.func,
  onRetry: PropTypes.func,
  dataHook: PropTypes.string,
}
