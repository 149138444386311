import { get } from 'lodash'
import { axiosJWT, axiosCustomer } from 'modules/axios'
import { getDocumentType } from 'modules/CustomerDocument'
import { CONFIG } from './environments'

export function find(customerId, documentId) {
  return axiosJWT({
    method: 'GET',
    headers: {
      'X-Authorization': axiosCustomer.defaults.headers.common.Authorization,
    },
    url: `${CONFIG.api.customer360}/${customerId}`,
    params: { documentId, documentType: getDocumentType(documentId), isCustomer: true },
  }).then(resp => {
    // partial response, throw error
    if (resp.status === 206) {
      throw get(resp, 'data.result')
    }
    return resp.data
  })
}

export function findNotClientWithoutOrders(customerId, documentId, agentToken) {
  return axiosJWT({
    method: 'GET',
    headers: {
      'X-Authorization': `Bearer ${agentToken}`,
    },
    url: `${CONFIG.api.customer360}/${customerId}`,
    params: { documentId, documentType: getDocumentType(documentId) },
  }).then(resp => {
    // partial response, throw error
    if (resp.status === 206) {
      throw get(resp, 'data.result')
    }
    return resp.data
  })
}

export const fetchVipTypeClient = (documentType, documentId) =>
  axiosJWT({
    method: 'GET',
    headers: {
      'X-Authorization': axiosCustomer.defaults.headers.common.Authorization,
    },
    url: CONFIG.api.vipType,
    params: { documentType, documentId },
  }).then(resp => resp.data)
