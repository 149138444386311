import { FIND_BILLINGS, FIND_BILLINGS_SUCCESS, FIND_BILLINGS_ERROR } from './billings.actions'
import { addIsUnpaid } from '../billings.helpers'

const initialState = {
  error: null,
  loading: false,
}

export function reducer(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case FIND_BILLINGS: {
      return { ...initialState, loading: true }
    }
    case FIND_BILLINGS_SUCCESS: {
      return { ...state, data: addIsUnpaid(payload.data), loading: false }
    }
    case FIND_BILLINGS_ERROR: {
      return {
        ...state,
        error: payload.error,
        loading: false,
      }
    }
    default:
      return state
  }
}
