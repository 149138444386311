import { call, put, takeLatest, select } from 'redux-saga/effects'
import { logError, formatErrorMessage } from 'services/logging'
import { axiosFactory } from 'modules/axios'

import { actionTypes } from 'modules/typifications/constants'
import { insertionData } from '../apis'
import { callInsertionTypificationsSuccess, callInsertionTypificationsFailed } from '../actions'
import { selectDataForm, selectDataCallInsertion } from '../selectors'

export function* insertionDataTypifications() {
  try {
    const dataForm = yield select(selectDataForm)
    const dataCallInsertion = yield select(selectDataCallInsertion)
    const dataInsertion = { ...dataForm, ...dataCallInsertion }

    yield call(insertionData, dataInsertion)
    yield put(callInsertionTypificationsSuccess())
  } catch (e) {
    yield call(logError, { e: new Error(formatErrorMessage(e)) })
    const error = axiosFactory.buildError(e)
    yield put(callInsertionTypificationsFailed(error))
  }
}

export function* watchInsertionTypificationsSagas() {
  yield takeLatest(actionTypes.INSERTION_TYPIFICATIONS_INIT, insertionDataTypifications)
}
