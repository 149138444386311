import { get } from 'lodash'
import { createSelector } from 'reselect'

export const getLines = state => state.lines

export const selectPortabilityDates = createSelector(
  getLines,
  lines => get(lines, 'data.dates'),
)

export const selectPortabilityDatesError = createSelector(
  getLines,
  lines => !!get(lines, 'error_code'),
)

export const selectPortabilityDatesHasFailed = createSelector(
  getLines,
  lines => get(lines, 'data.status') === 206,
)

export const selectPortabilityDatesLoading = createSelector(
  getLines,
  lines => !!get(lines, 'loading'),
)
