import PropTypes from 'prop-types'

import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import { formatFee, TextStyles } from 'utils'

const useStyles = makeStyles({
  'pending-and-total-fee': {
    width: '215px',

    '& p.pending': {
      fontWeight: 500,

      '& span': {
        fontWeight: 'normal',
        fontSize: '16px',
        marginLeft: '10px',
        '&.previous-fee': {
          textDecoration: 'line-through',
        },
      },

      '&:not(.fee-description)': {
        fontWeight: 'bold',
      },
    },
  },
})

const penaltyFee = (pendingFee, totalFee, enableProrratedPenalty) =>
  enableProrratedPenalty ? (
    <>
      {formatFee(pendingFee).replace(totalFee ? '€' : '', '')}

      {totalFee && <span>/{formatFee(totalFee)}</span>}
    </>
  ) : (
    <>{totalFee && formatFee(totalFee)}</>
  )

const PendingAndTotalLine = ({
  className,
  pendingFee,
  previousFee,
  totalFee,
  feeDescription,
  enableProrratedPenalty,
}) => {
  return (
    <>
      <Typography
        {...TextStyles.title1Dark({
          className,
          component: 'p',
          color: 'textPrimary',
        })}>
        {penaltyFee(pendingFee, totalFee, enableProrratedPenalty)}

        {previousFee && <span className="previous-fee">{formatFee(previousFee)}</span>}
      </Typography>
      <Typography
        {...TextStyles.labelDark({
          className: 'p-t-4 fee-description',
          component: 'p',
        })}>
        {feeDescription}
      </Typography>
    </>
  )
}
PendingAndTotalLine.propTypes = {
  className: PropTypes.string,
  pendingFee: PropTypes.number,
  previousFee: PropTypes.number,
  totalFee: PropTypes.number,
  feeDescription: PropTypes.string,
  enableProrratedPenalty: PropTypes.bool,
}

export const PendingAndTotalFee = ({
  className,
  title,
  pendingFee,
  previousFee,
  totalFee,
  feeDescription,
  enableProrratedPenalty,
}) => {
  const classes = useStyles()

  return (
    <div className={[classes['pending-and-total-fee'], className].join(' ')}>
      <Typography {...TextStyles.subtitle2Dark()}>{title}:</Typography>

      <PendingAndTotalLine
        className="pending p-t-4"
        pendingFee={pendingFee}
        previousFee={previousFee}
        totalFee={totalFee}
        feeDescription={feeDescription}
        enableProrratedPenalty={enableProrratedPenalty}
      />
    </div>
  )
}

PendingAndTotalFee.defaultProps = {
  className: '',
  enableProrratedPenalty: true,
}

PendingAndTotalFee.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  pendingFee: PropTypes.number,
  previousFee: PropTypes.number,
  totalFee: PropTypes.number,
  feeDescription: PropTypes.string,
  enableProrratedPenalty: PropTypes.bool,
}
