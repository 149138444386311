import { createPortal } from 'react-dom'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Typography, Box } from '@material-ui/core'

import { TextStyles } from 'utils/text'

import Dialog from '../dialog/Dialog'

export { ModalActions } from './ModalActions'

const useStyles = makeStyles({
  'modal-overlay': {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 10,
    transform: 'translateZ(0)',
    backgroundColor: ({ overlay }) => `rgba(0, 0, 0, ${overlay ? '0.4' : '0'})`,
  },
  'modal-title': {
    borderBottom: '1px solid #d8d8d8',
    paddingBottom: '17px',
    marginBottom: '16px',
    marginTop: '2px',
    paddingRight: '40px',
  },
})

const Modal = ({
  isOpen,
  children,
  title,
  onClose,
  width,
  overlay,
  type,
  overFlowY,
  className,
  ...rest
}) => {
  const classes = useStyles({ overlay })

  const onModalClose = e => {
    if (!e.target || !e.target.classList?.contains('modal-overlay')) {
      return
    }

    if (onClose) {
      onClose(e)
    }
  }

  if (!isOpen) {
    return null
  }

  return createPortal(
    // eslint-disable-next-line
    <div
      className={`modal-overlay ${classes['modal-overlay']} ${className || ''}`}
      onClick={onModalClose}>
      <Dialog onClose={onClose} width={width} type={type} overFlowY={overFlowY} {...rest}>
        {title && (
          <Box className={classes['modal-title']}>
            <Typography {...TextStyles.title1Dark()}>{title}</Typography>
          </Box>
        )}
        {children}
      </Dialog>
    </div>,
    document.body,
  )
}

Modal.defaultProps = {
  overlay: true,
  width: '872px',
}

Modal.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  children: PropTypes.node,
  onClose: PropTypes.func,
  width: PropTypes.string,
  overFlowY: PropTypes.string,
  overlay: PropTypes.bool,
  type: PropTypes.oneOf(['default', 'alert', 'attention', 'info', 'success']),
}

export default Modal
