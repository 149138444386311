import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { Box, Typography } from '@material-ui/core'

import { NotificationModal, SpinnerCenter } from 'components/ui'
import { MULTISIM_NOT_ALLOWED } from 'modules/SharedSales/constants/validations'
import { resetMultiSimAllowed, fetchMultiSimAllowed } from 'services/multisim/actions'
import { selectTariffSegment } from 'modules/tariffs/store-apigee'
import { getSegment } from 'services/customer-360/selectors'
import { getMultisimTariffSegment } from 'services/customer-360/helpers'
import { POS, POS_PRO } from 'services/global-constants/channels'
import { PRO } from 'modules/SharedSales/constants/productCategories'
import { selectMultiSimModal } from './MultiSimElegibilityModal.selectors'

export function MultiSimElegibilityModal({
  shouldCheckAllowed,
  subscription,
  onMultiSimAllowed,
  onMultiSimReached,
}) {
  const dispatch = useDispatch()
  const [isNotAllowedModalOpen, setIsNotAllowedModalOpen] = useState(false)
  const { isMultiSimAllowed, isLoading, error } = useSelector(selectMultiSimModal)

  const tariffSegment = useSelector(selectTariffSegment(subscription.type))
  const customerSegment = useSelector(getSegment)
  const segment = getMultisimTariffSegment(tariffSegment, customerSegment)
  const channel = segment === PRO ? POS_PRO : POS

  function checkAllowed() {
    dispatch(fetchMultiSimAllowed(subscription.subscription_id, channel, segment))
  }

  useEffect(() => {
    if (shouldCheckAllowed && isLoading === null) {
      checkAllowed()
    }
  }, [shouldCheckAllowed, isLoading])

  useEffect(() => () => dispatch(resetMultiSimAllowed()), [])

  useEffect(() => {
    if (isLoading === false && !!shouldCheckAllowed) {
      if (isMultiSimAllowed) {
        setIsNotAllowedModalOpen(false)

        onMultiSimAllowed(channel, segment)
      } else {
        setIsNotAllowedModalOpen(true)
        onMultiSimReached()
      }
    }
  }, [isLoading, shouldCheckAllowed, isMultiSimAllowed])

  if (isLoading) {
    return <SpinnerCenter absolute overlay />
  }

  return (
    <NotificationModal
      isOpen={isNotAllowedModalOpen}
      type="alert"
      src="/assets/error.svg"
      onClose={() => {
        setIsNotAllowedModalOpen(false)
        dispatch(resetMultiSimAllowed())
      }}>
      <Typography variant="h5" style={{ fontWeight: 400 }}>
        {MULTISIM_NOT_ALLOWED}
      </Typography>
      {error && (
        <Box mt="14px">
          <Typography variant="body1">{error}</Typography>
        </Box>
      )}
    </NotificationModal>
  )
}

MultiSimElegibilityModal.propTypes = {
  shouldCheckAllowed: PropTypes.bool,
  subscription: PropTypes.object,
  onMultiSimAllowed: PropTypes.func.isRequired,
  onMultiSimReached: PropTypes.func.isRequired,
}
