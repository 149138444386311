import PropTypes from 'prop-types'
import { Badge } from 'modules/ui'

import { formatPermanent } from '../../helpers'

export function PermanentBadgeItem({ className, permanent }) {
  const permanentValue = permanent ? formatPermanent(permanent) : 'No'

  return (
    <Badge
      lg
      keyValContrast
      className={`permanent-badge-item ${className}`}
      isFixed
      value={[{ label: 'Permanencia', value: permanentValue }]}
    />
  )
}

PermanentBadgeItem.propTypes = {
  className: PropTypes.string,
  permanent: PropTypes.object,
}
