const DEFAULT_TOLERANCE = Number.EPSILON

/**
 * The function tests whether the relative difference
 * between val1 and val2 is smaller than the default tolerance.
 *
 * @param {float} val1
 * @param {float} val2
 */

export function flEquals(val1, val2, tolerance = DEFAULT_TOLERANCE) {
  return Math.abs(val1 - val2) < tolerance
}

/**
 * Returns the pct of the given value val
 *
 * @param {float} val
 * @param {float} pct
 */
export function applyPct(val, pct) {
  return val * (pct / 100)
}

/**
 * Returns the rate of elements, but interpreting them as a progress of one respect the other
 * So when total = 0 then the progress is 1
 *
 * @param {float} val
 * @param {float} total
 */
export function progressRatio(val, total) {
  if (flEquals(0, total)) {
    return 1
  }

  return val / total
}
