import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  'sidebar-container': {
    backgroundColor: theme.palette.global.gray_dark_2,
    'box-shadow': '2px 2px 4px 0 rgba(157,157,157, 0.5)',
    maxWidth: '420px',
  },
}))

const SidebarContainer = ({ children, className, ...props }) => {
  const classes = useStyles()

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      height="100%"
      minWidth="400px"
      padding="56px 32px"
      component="section"
      className={[classes['sidebar-container'], className].join(' ')}
      {...props}>
      {children}
    </Box>
  )
}

SidebarContainer.propTypes = {
  children: PropTypes.element,
  className: PropTypes.string,
}

export default SidebarContainer
