import PropTypes from 'prop-types'
import { isNull } from 'lodash'
import { Button, ModalActions, AlertAdvice, FormSelectOutlined } from 'components/ui'

import {
  isFiveEurosConvergentDiscount,
  formatDiscountName,
  formatDuration,
  hasDiscountPermanency,
  is100MbDiscount,
  isDuoDiscount,
} from 'modules/offers-configuration/helpers'

import { FormContainer } from './FormContainer'
import { UNLIMITED_DURATION, SPECIAL_UNLIMITED_DURATION } from '../constants'

const DiscountForm = ({
  amountOptions,
  durationOptions,
  permanencyOptions,
  onAmountSelect,
  selectedAmount,
  selectedDuration,
  selectedPermanency,
  onDurationSelect,
  onPermanencySelect,
  onSubmit,
  goToPreviousStep,
  hasIncompatibilities,
  profileId,
}) => {
  const selectedAmountObject = selectedAmount ? JSON.parse(selectedAmount) : {}
  const is100MbTariffDiscount = is100MbDiscount(
    selectedAmountObject.discountCode,
    selectedAmountObject.value,
  )
  const isLoyaltyConvergentDiscount = isFiveEurosConvergentDiscount(
    selectedAmountObject.discountCode,
  )

  const shouldShowDuration =
    (selectedAmount &&
      selectedDuration !== UNLIMITED_DURATION &&
      selectedDuration !== SPECIAL_UNLIMITED_DURATION) ||
    ((is100MbTariffDiscount || isLoyaltyConvergentDiscount) &&
      selectedDuration === UNLIMITED_DURATION)

  const shouldShowPermanency = hasDiscountPermanency(
    selectedAmountObject.discountCode,
    selectedAmountObject.value,
    selectedDuration,
    is100MbTariffDiscount,
    profileId,
  )

  const shouldShowSubmit =
    selectedAmount &&
    (selectedDuration ||
      selectedDuration === UNLIMITED_DURATION ||
      selectedDuration === SPECIAL_UNLIMITED_DURATION) &&
    (!shouldShowPermanency || (shouldShowPermanency && !isNull(selectedPermanency)))

  const shouldShowConflict =
    shouldShowSubmit && hasIncompatibilities && !isDuoDiscount(selectedAmountObject.discountCode)

  return (
    <>
      <FormContainer data-hook="add-discount-popover">
        <FormSelectOutlined
          label="Selecciona el descuento"
          minWidth="320px"
          value={selectedAmount}
          options={amountOptions.map(discount => ({
            value: JSON.stringify(discount),
            text: formatDiscountName(discount, profileId),
          }))}
          onChange={onAmountSelect}
        />
      </FormContainer>

      {shouldShowDuration && (
        <FormContainer>
          <FormSelectOutlined
            label="Duración"
            minWidth="160px"
            value={selectedDuration}
            options={durationOptions.map(duration => ({
              value: duration.value,
              text:
                duration.value === UNLIMITED_DURATION
                  ? 'Ilimitado'
                  : formatDuration(duration.value),
            }))}
            onChange={onDurationSelect}
          />
        </FormContainer>
      )}

      {shouldShowPermanency && (
        <FormContainer>
          <FormSelectOutlined
            label="Permanencia"
            minWidth="160px"
            value={selectedPermanency}
            options={permanencyOptions}
            onChange={onPermanencySelect}
            maxWidth="none"
          />
        </FormContainer>
      )}

      {shouldShowConflict && (
        <AlertAdvice
          message="CONFLICTO: Este descuento no es compatible con otro ya aplicado. El descuento
            seleccionado eliminará el descuento anterior"
        />
      )}

      <ModalActions>
        {shouldShowSubmit && (
          <Button data-hook="action-confirm" onClick={onSubmit}>
            Confirmar descuento
          </Button>
        )}
        <Button secondary onClick={goToPreviousStep}>
          Cancelar
        </Button>
      </ModalActions>
    </>
  )
}

DiscountForm.propTypes = {
  amountOptions: PropTypes.array.isRequired,
  durationOptions: PropTypes.array.isRequired,
  permanencyOptions: PropTypes.array.isRequired,
  onAmountSelect: PropTypes.func.isRequired,
  selectedAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  selectedDuration: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  selectedPermanency: PropTypes.string,
  onDurationSelect: PropTypes.func.isRequired,
  onPermanencySelect: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  goToPreviousStep: PropTypes.func.isRequired,
  hasIncompatibilities: PropTypes.bool.isRequired,
  profileId: PropTypes.string.isRequired,
}

export default DiscountForm
