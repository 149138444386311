import { call, put, takeEvery, select } from 'redux-saga/effects'
import { logError, formatErrorMessage } from 'services/logging'
import { axiosFactory } from 'modules/axios'
import { selectAccountId } from 'modules/Auth'

import { FETCH_CUSTOMER_CONTACT_MEDIA } from './constants'
import { fetchCustomerContactMediaSuccess, fetchCustomerContactMediaError } from './action-creators'
import { fetchCustomerContactMedia } from './api'

function* customerContactMediaSaga() {
  try {
    const accountId = yield select(selectAccountId)
    const customerContactMedia = yield call(fetchCustomerContactMedia, accountId)
    yield put(fetchCustomerContactMediaSuccess(customerContactMedia))
  } catch (e) {
    yield call(logError, new Error(formatErrorMessage(e)))
    const error = axiosFactory.buildError(e)
    yield put(fetchCustomerContactMediaError(error))
  }
}

export function* watchFetchCustomerContactMediaSaga() {
  yield takeEvery(FETCH_CUSTOMER_CONTACT_MEDIA, customerContactMediaSaga)
}
