import { createSelector } from 'reselect'
import { selectRouterSearch } from 'modules/Router'
import { selectPonr } from 'modules/ponr'
import { selectPonc } from 'modules/ponc'
import { isFtthOrder, isConvergent, getOrders } from 'modules/orders'
import { chain, isEmpty } from 'lodash'
import { checkIsNebaAndVula } from 'modules/Coverage'

const getOrdersContainerData = (
  ponr,
  ponc,
  isFtth,
  isConvergentTariff,
  search,
  fixedInternalPortability,
  installationRequired,
) => ({
  ponr,
  ponc,
  isFtth,
  isConvergentTariff,
  search,
  fixedInternalPortability,
  installationRequired,
})

const selectIsFtthOrder = createSelector(
  getOrders,
  isFtthOrder,
)

const selectFixedInternalPortability = createSelector(
  [getOrders],
  orders =>
    chain(orders)
      .get('fixed_internal_portability')
      .value(),
)

const selectInstallationRequired = createSelector(
  [getOrders],
  orders =>
    chain(orders)
      .get('installation_required')
      .value(),
)

export const selectOrdersContainerData = createSelector(
  [
    selectPonr,
    selectPonc,
    selectIsFtthOrder,
    isConvergent,
    selectRouterSearch,
    selectFixedInternalPortability,
    selectInstallationRequired,
  ],
  getOrdersContainerData,
)

export const selectIsNebaAndVulaOrder = createSelector(
  [getOrders],
  orders => {
    const territoryOwner = chain(orders)
      .get('customer.contactMedium')
      .find({ type: 'InstallationAddressFixedLine' })
      .get('medium.characteristic')
      .find({ name: 'territoryOwner' })
      .get('value')
      .value()
    return checkIsNebaAndVula(territoryOwner)
  },
)

export const selectOrderContentLineFiber = createSelector(
  [getOrders],
  orders => {
    const fixedLineType = chain(orders)
      .get('customer.products')
      .find(product => product.fixed_line_type === 'fiber')
      .value()
    return !isEmpty(fixedLineType)
  },
)
