import PropTypes from 'prop-types'

import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import { Button } from 'components/ui'

import { featureFlag } from 'services/feature-flag'

import { CancelOrdersButton } from './CancelOrdersButton'
import { DiscountsOrderButton } from './DiscountsOrderButton'

const useStyles = makeStyles({
  small: {
    marginTop: '10px',
    maxWidth: '200px',
    textAlign: 'center',
    display: 'block',
    color: 'red',
  },
})

const Orders = ({
  navigate,
  orderId,
  documentId,
  documentType,
  search,
  redirect,
  canCancelPartialOrders,
  fetchSubOrders,
  ponr,
  isFiber,
  isNeba,
  isVistaEnabled,
  fixedInternalPortability,
  installationRequired,
}) => {
  const classes = useStyles()

  const onModifyAddressClick = () =>
    navigate && navigate(`/clients/orders/${orderId}/address/edit?${search}`)

  const onCancellOrderClick = () => {
    if (!canCancelPartialOrders) {
      fetchSubOrders()
    }

    if (navigate) {
      navigate(`/clients/orders/${orderId}/cancel?${search}`)
    }
  }

  const onApplyDiscountClick = () =>
    navigate && navigate(`/clients/orders/${orderId}/discounts/edit?${search}`)

  const buildUrlVista = () => {
    let basePath = redirect || '/vista/dashboard'
    if (documentId) {
      basePath += `/documentType/${documentType}/documentId/${documentId.toUpperCase()}/order/${orderId.toUpperCase()}?brand=yoigo&channel=newton`
    }

    return `${basePath}`
  }

  const goToVista = () => navigate && navigate(buildUrlVista())

  const onClose = () => window.close()

  const isValidPonr = ponr && !ponr.reached && isFiber
  const ftthWithPortability =
    isFiber && fixedInternalPortability === 'true' && installationRequired === 'false'
  const validOnboarding = isValidPonr && !isNeba

  const ChangeAddressOrderButton = () => (
    <Box
      display="flex"
      p="20px 0 0 20px"
      flexDirection="column"
      justifyContent="center"
      alignItems="center">
      <Button
        disabled={!validOnboarding || ftthWithPortability}
        id="rounder"
        data-hook="btnModifyAddress"
        onClick={onModifyAddressClick}>
        Modificar direccion
      </Button>
      {!isValidPonr && !ponr.loading ? (
        <span className={classes.small}>Punto de no retorno alcanzado</span>
      ) : null}
      {ftthWithPortability ? (
        <span className={classes.small}>Portabilidad interna sin instalación</span>
      ) : null}
    </Box>
  )

  const ChangeAddressButton = featureFlag('address_change')(ChangeAddressOrderButton)

  return (
    <>
      <Box display="flex" style={{ height: '100%' }} justifyContent="center" alignItems="center">
        <Box display="flex" alignItems="flex-start" justifyContent="center">
          <Box display="flex" p="20px 0 0 20px" flexDirection="column" justifyContent="center">
            <Button data-hook="rounder" onClick={onClose}>
              Volver a Vista
            </Button>
          </Box>

          <ChangeAddressButton />

          <CancelOrdersButton
            orderId={orderId}
            docNumber={documentId}
            docType={documentType}
            onClick={onCancellOrderClick}
          />

          <DiscountsOrderButton onClick={onApplyDiscountClick} />

          {isVistaEnabled && (
            <Box
              display="flex"
              p="20px 0 0 20px"
              flexDirection="column"
              justifyContent="center"
              alignItems="center">
              <Button onClick={goToVista}>MASCare</Button>
            </Box>
          )}
        </Box>
      </Box>
    </>
  )
}

Orders.propTypes = {
  orderId: PropTypes.string.isRequired,
  documentId: PropTypes.string,
  documentType: PropTypes.string,
  ponr: PropTypes.any.isRequired,
  isFiber: PropTypes.bool.isRequired,
  isNeba: PropTypes.bool.isRequired,
  navigate: PropTypes.func,
  search: PropTypes.string,
  fetchSubOrders: PropTypes.func,
  redirect: PropTypes.string,
  isVistaEnabled: PropTypes.bool,
  canCancelPartialOrders: PropTypes.bool,
  fixedInternalPortability: PropTypes.bool,
  installationRequired: PropTypes.bool,
}

export default Orders
