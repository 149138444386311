import { get } from 'lodash'
import { UNLIMITED_DURATION } from 'modules/offers-configuration/constants.js'

const ACTIVE_STATUS = 'active'
export const TWOP_TYPE = '2P'
const CONVERGENT_TYPE = '3P'
const FIXED = 'fixed'
const MSG_DESCRIPTION = 'Descuento segunda residencia'
const PERCENTAGE_UNIT_TYPE = 'Percentage'

const DISCOUNTS = {
  RETDSLA2RESIDISC: {
    code: 'RETDSLA2RESIDISC',
    price: 40.63,
    duration: UNLIMITED_DURATION,
    unit: PERCENTAGE_UNIT_TYPE,
    description: MSG_DESCRIPTION,
  },
  RETFHE2RESIDISC: {
    code: 'RETFHE2RESIDISC',
    price: 40.63,
    duration: UNLIMITED_DURATION,
    unit: PERCENTAGE_UNIT_TYPE,
    description: MSG_DESCRIPTION,
  },
  RETFHF2RESIDISC: {
    code: 'RETFHF2RESIDISC',
    price: 38.3,
    duration: UNLIMITED_DURATION,
    unit: PERCENTAGE_UNIT_TYPE,
    description: MSG_DESCRIPTION,
  },
  RETFHD2RESIDISC: {
    code: 'RETFHD2RESIDISC',
    price: 31.58,
    duration: UNLIMITED_DURATION,
    unit: PERCENTAGE_UNIT_TYPE,
    description: MSG_DESCRIPTION,
  },
  RETDSLA2RESIDISC02: {
    code: 'RETDSLA2RESIDISC02',
    price: 31.23,
    duration: UNLIMITED_DURATION,
    unit: PERCENTAGE_UNIT_TYPE,
    description: MSG_DESCRIPTION,
  },
  RETFHC2RESIDISC02: {
    code: 'RETFHC2RESIDISC02',
    price: 31.23,
    duration: UNLIMITED_DURATION,
    unit: PERCENTAGE_UNIT_TYPE,
    description: MSG_DESCRIPTION,
  },
  RETFHF2RESIDISC02: {
    code: 'RETFHF2RESIDISC02',
    price: 31.9,
    duration: UNLIMITED_DURATION,
    unit: PERCENTAGE_UNIT_TYPE,
    description: MSG_DESCRIPTION,
  },
  RETFHD2RESIDISC02: {
    code: 'RETFHD2RESIDISC02',
    price: 28.85,
    duration: UNLIMITED_DURATION,
    unit: PERCENTAGE_UNIT_TYPE,
    description: MSG_DESCRIPTION,
  },
  FH2RESIDISC: {
    code: 'FH2RESIDISC',
    duration: UNLIMITED_DURATION,
    price: 8.27,
    unit: FIXED,
    description: 'Descuento Internet segunda residencia',
  },
  CONTFHDISCP001: {
    code: 'CONTFHDISCP001',
    duration: UNLIMITED_DURATION,
    price: 12,
    unit: FIXED,
    description: 'Descuento 2P Fibra 300 (-12eur)',
  },
  CONTFHDISCR001: {
    code: 'CONTFHDISCR001',
    duration: UNLIMITED_DURATION,
    price: 10,
    unit: FIXED,
    description: 'Descuento 2P Fibra 300 (-12,1eur)',
  },
}

const TARIFF_DISCOUNTS = {
  CONTDSLAR: 'RETDSLA2RESIDISC',
  CONTFHER: 'RETFHE2RESIDISC',
  CONTFHENR: 'RETFHE2RESIDISC',
  CONTFHENR01: 'RETFHE2RESIDISC',
  CONTFHFR: 'RETFHF2RESIDISC',
  CONTFHFNR: 'RETFHF2RESIDISC',
  CONTFHDR: 'RETFHD2RESIDISC',
  // 2P PURO new tariffs
  CONTDSLAFR: 'RETDSLA2RESIDISC',
  CONTFHEFR: 'RETFHE2RESIDISC',
  CONTFHENFR: 'RETFHE2RESIDISC',
  CONTFHENFR01: 'RETFHE2RESIDISC',
  CONTFHFFR: 'RETFHF2RESIDISC',
  CONTFHFNFR: 'RETFHF2RESIDISC',
  CONTFHDFR: 'RETFHD2RESIDISC',
}

const NEW_TARIFF_DISCOUNTS = {
  ...TARIFF_DISCOUNTS,
  CONTDSLAR: 'RETDSLA2RESIDISC02',
  CONTDSLAFR: 'RETDSLA2RESIDISC02',
  CONTFHDFR01: 'RETFHD2RESIDISC02', // 1G
  CONTFHDNFR: 'RETFHD2RESIDISC02',
  CONTFHCFR: 'RETFHC2RESIDISC02', // 300M
  CONTFHCNFR: 'RETFHC2RESIDISC02',
  CONTFHFNR: 'RETFHF2RESIDISC02', // 600M
  CONTFHFFR: 'RETFHF2RESIDISC02',
  CONTFHFNFR: 'RETFHF2RESIDISC02',
  CONTFHFR: 'RETFHF2RESIDISC02',
  CONTFHDFR02: 'FH2RESIDISC', // 1G
  CONTFHDNFR01: 'FH2RESIDISC',
  CONTFHFFR01: 'FH2RESIDISC', // 600M
  CONTFHFNFR01: 'FH2RESIDISC',
  CONTFHGFR: 'FH2RESIDISC', // 500M
  CONTFHGNFR: 'FH2RESIDISC',
}

const mapLandlineOfferToDiscountLine = landlineOffer => ({
  id: null,
  tariffId: get(landlineOffer, 'tariff.psId', null),
})

/**
 * Get lines for apply possible second home discount
 * @param {*} isConvergent If new sale is convergent
 * @param {*} is2p If new sale is 2p
 * @param {*} lines Current lines of the client
 * @param {*} landlineOffer New sale landline info.
 */
export function getSecondHomeLines(isConvergent, is2p, lines, landlineOffer, isNewD2R) {
  let secondHomeDiscountLines = []

  const client2pActiveLines = lines.filter(order => {
    const existTariffDiscount = isNewD2R
      ? !!NEW_TARIFF_DISCOUNTS[order.tariffId]
      : !!TARIFF_DISCOUNTS[order.tariffId]

    return (
      order.stringStatus === ACTIVE_STATUS &&
      order.category === TWOP_TYPE &&
      order.type === FIXED &&
      existTariffDiscount
    )
  })

  if (isConvergent && client2pActiveLines.length > 0) {
    secondHomeDiscountLines = client2pActiveLines
  } else if (is2p) {
    const clientConvergentActiveFirstLine = lines.find(
      order =>
        order.stringStatus === ACTIVE_STATUS &&
        order.category === CONVERGENT_TYPE &&
        order.type === FIXED,
    )

    if (clientConvergentActiveFirstLine)
      secondHomeDiscountLines = [
        mapLandlineOfferToDiscountLine(landlineOffer),
        ...client2pActiveLines,
      ]
  }

  return secondHomeDiscountLines
}

export function getDiscountByTariff(tariff, isNewD2R) {
  if (isNewD2R) {
    return (NEW_TARIFF_DISCOUNTS[tariff] && DISCOUNTS[NEW_TARIFF_DISCOUNTS[tariff]]) || null
  }
  return (TARIFF_DISCOUNTS[tariff] && DISCOUNTS[TARIFF_DISCOUNTS[tariff]]) || null
}
