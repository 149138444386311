import { call, put, select, takeLatest } from 'redux-saga/effects'
import { logError, formatErrorMessage } from 'services/logging'
import { selectSfid } from 'modules/Auth'
import { selectProfileId } from 'modules/Permissions'
import { get } from 'lodash'
import { selectors as customerSelectors } from 'services/customer-360'
import * as actions from './credit.actions'
import { applyCreditNote } from '../services/credit.service'

export function* creditNoteSaga({ payload: { data } }) {
  try {
    const sfid = yield select(selectSfid)
    const documentId = yield select(customerSelectors.getIdentificationId)
    const profileId = yield select(selectProfileId)
    const customerData = { sfid, documentId, profileId }
    const result = yield call(applyCreditNote, customerData, data)
    yield put(actions.creditNoteSuccededAction(result))
  } catch (e) {
    yield call(logError, { e: new Error(formatErrorMessage(e)), data })
    yield put(actions.creditNoteFailedAction(get(e, 'response.data.detailMsg')))
  }
}

export function* watchCreditNote() {
  yield takeLatest(actions.CREDIT_ATTEMPTED, creditNoteSaga)
}
