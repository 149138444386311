import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { head, isEmpty, chain, get } from 'lodash'
import styled from 'styled-components'

import { Typography, Box, Divider } from '@material-ui/core'
import { TextStyles } from 'utils/text'

import { LineItemCard, OutlinedDivider } from 'components/ui'
import { formatFee, setPromotionLiteral } from 'utils'
import { getCommercialName, selectTariffsApigeeById } from 'modules/tariffs/store-apigee'
import { is2pPureTariff } from 'modules/vista-360/helpers/helpers'
import { FeesItem } from '../FeesItem'
import { DiscountPlans } from './DiscountPlans'

export const PriceBreakdown = styled(({ offer, feesData, promotion, className, taxNeeded }) => {
  const { discount_plans, fixed, handset, router, sim, tariff } = offer
  const tariffObj = useSelector(selectTariffsApigeeById(tariff.id))
  const is2pPure = is2pPureTariff(get(offer, 'tariff.id'))
  const penaltyFee = productOffered =>
    chain(productOffered)
      .head()
      .get('permanency_contracts')
      .head()
      .get('penalty_fee')
      .value() || 0
  return (
    !isEmpty(offer) && (
      <div className={className} data-hook="price-breakdown">
        <div>
          <Typography {...TextStyles.subtitle1Link()}>
            <span>DATOS DE CABECERA</span>
          </Typography>

          {!isEmpty(fixed) && (
            <LineItemCard
              className="line-item-width"
              padding="6px 0"
              data-hook="line-item-fixed"
              primaryText="Línea Fija"
              secondaryText={head(fixed).phone_number}
            />
          )}
          {!isEmpty(sim) && !is2pPure && (
            <LineItemCard
              className="line-item-width"
              padding="6px 0"
              data-hook="line-item-sim"
              primaryText="Línea Móvil"
              secondaryText={head(sim).mobile_number}
            />
          )}
          {!isEmpty(tariff) && (
            <LineItemCard
              className="line-item-width"
              padding="6px 0"
              data-hook="line-item-tariff"
              primaryText="Tarifa"
              secondaryText={getCommercialName(tariffObj)}
            />
          )}

          {!isEmpty(tariff) && (
            <>
              <div className="flex align-start">
                <LineItemCard
                  className="line-item-width"
                  padding="6px 24px 6px 0"
                  data-hook="line-item-tariff-fee"
                  primaryText="Cuota tarifa (sin impuestos)"
                  secondaryText={`${formatFee(tariff.monthly_fee)}/mes`}
                />
                <LineItemCard
                  className="line-item-width"
                  padding="6px 24px 6px 0"
                  data-hook="line-item-tariff-permanency"
                  primaryText="Permanencia"
                  secondaryText={`${chain(router)
                    .head()
                    .get('permanency_contracts')
                    .head()
                    .get('contract_length')
                    .value() || '0'} meses`}
                />
                {penaltyFee(router) ? (
                  <LineItemCard
                    className="line-item-width"
                    padding="6px 0"
                    data-hook="line-item-tariff-penalty-fee"
                    primaryText="Penalización"
                    secondaryText={formatFee(penaltyFee(router))}
                  />
                ) : (
                  ''
                )}
              </div>
              {!isEmpty(promotion) && (
                <LineItemCard
                  className="line-item-width"
                  padding="6px 0"
                  primaryText="Promoción aplicada"
                  secondaryText={setPromotionLiteral(
                    get(promotion, 'attributes'),
                    get(promotion, 'id'),
                  )}
                />
              )}
            </>
          )}

          {!isEmpty(discount_plans) && !isEmpty(tariff) && <DiscountPlans {...offer} />}
        </div>
        {!isEmpty(handset) ? (
          <>
            <Box py="8px">
              <OutlinedDivider />
            </Box>

            <div>
              {handset.map(handsetItem => (
                <div key={handsetItem.imei}>
                  <LineItemCard
                    className="line-item-width"
                    padding="6px 0"
                    primaryText="Dispositivo"
                    secondaryText={handsetItem.name}
                  />

                  <div className="flex align-start">
                    <LineItemCard
                      className="line-item-width"
                      padding="6px 24px 6px 0"
                      primaryText="Pago inicial"
                      secondaryText={formatFee(handsetItem.initial_payment)}
                    />
                    {handsetItem.final_payment ? (
                      <LineItemCard
                        className="line-item-width"
                        padding="6px 8px"
                        primaryText="Pago final"
                        secondaryText={formatFee(handsetItem.final_payment)}
                      />
                    ) : (
                      ''
                    )}
                  </div>

                  {!isEmpty(handsetItem.subsidy_contracts) &&
                    handsetItem.subsidy_contracts.map(contract => (
                      <div key={contract.monthly_fee}>
                        <div className="flex align-start">
                          <LineItemCard
                            className="line-item-width"
                            padding="6px 24px 6px 0"
                            primaryText="Cuota"
                            secondaryText={`${contract.monthly_fee}€/mes`}
                          />
                          <LineItemCard
                            className="line-item-width"
                            padding="6px 24px 6px 0"
                            primaryText="Permanencia"
                            secondaryText={`${chain(handset)
                              .head()
                              .get('subsidy_contracts')
                              .head()
                              .get('contract_length')
                              .value()} meses`}
                          />
                          <LineItemCard
                            className="line-item-width"
                            padding="6px 24px 6px 0"
                            primaryText="Penalización"
                            secondaryText={formatFee(penaltyFee(sim))}
                          />
                        </div>
                      </div>
                    ))}
                </div>
              ))}
            </div>
          </>
        ) : (
          ''
        )}

        <Box m="12px 0">
          <Divider />
        </Box>

        {!isEmpty(discount_plans) &&
          !!head(discount_plans).amount &&
          !!head(discount_plans).valid_periods_amount && (
            <>
              <Typography {...TextStyles.subtitle1Link()}>
                <span>DESCUENTOS GENERALES</span>
              </Typography>

              {!isEmpty(discount_plans) && !isEmpty(tariff) && <DiscountPlans {...offer} />}

              <Box py="8px">
                <OutlinedDivider />
              </Box>
            </>
          )}
        <FeesItem feesData={feesData} promotion={promotion} taxNeeded={taxNeeded} />
      </div>
    )
  )
})`
  .line-item-width {
    width: auto;
  }
  .price {
    padding: 0 12px 0 0;
  }
`

PriceBreakdown.propTypes = {
  offer: PropTypes.object,
  feesData: PropTypes.object,
  promotion: PropTypes.object,
  taxNeeded: PropTypes.bool,
}
