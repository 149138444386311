const LanguageOptionsValues = {
  spanish: 'spa',
  catalan: 'cat',
  euskera: 'eus',
  english: 'eng',
}

export const AccountLanguageOptions = [
  { key: 'ESP', value: LanguageOptionsValues.spanish, label: 'Español' },
  { key: 'CAT', value: LanguageOptionsValues.catalan, label: 'Catalán' },
  { key: 'EUK', value: LanguageOptionsValues.euskera, label: 'Euskera' },
  { key: 'ENG', value: LanguageOptionsValues.english, label: 'Inglés' },
]

const defaultAccountLanguageValue = LanguageOptionsValues.spanish

export const Account = {
  name: '',
  surname1: '',
  surname2: '',
  documenttype: '',
  documentid: '',
  nationality: '',
  phoneContact: '',
  phoneNumber: '',
  companyName: '',
  companyCif: '',
  salesSelector: '',
  segment: '',
  constitutionDate: '',
  birthday: '',
  phoneContact2: '',
  email: '',
  language: defaultAccountLanguageValue,
  technology: '',
  province: '',
  lines: [],
  historicOrders: [],
}
