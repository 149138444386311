import { constants } from './constants'

export const fetchCrossSellAllowed = (saleInformation, subscription) => ({
  type: constants.FETCH_CROSS_SELL_ALLOWED,
  payload: {
    saleInformation,
    subscription,
  },
})

export const fetchCrossSellAllowedSuccess = () => ({
  type: constants.FETCH_CROSS_SELL_ALLOWED_SUCCESS,
})

export const fetchCrossSellAllowedFailed = () => ({
  type: constants.FETCH_CROSS_SELL_ALLOWED_FAILED,
})

export const resetCrossSellAllowed = () => ({
  type: constants.RESET_CROSS_SELL_ALLOWED,
})

export const fetchCrossSellCompatibleDiscounts = (
  productNumber,
  productSpecification,
  idPOCrossSell,
  idPOPromoXSell,
) => ({
  type: constants.FETCH_CROSS_COMPATIBLE_DISCOUNTS,
  payload: {
    productNumber,
    productSpecification,
    idPOCrossSell,
    idPOPromoXSell,
  },
})

export const fetchCrossSellCompatibleDiscountsSuccess = data => ({
  type: constants.FETCH_CROSS_COMPATIBLE_DISCOUNTS_SUCCESS,
  payload: data,
})

export const fetchCrossSellCompatibleDiscountsFailed = () => ({
  type: constants.FETCH_CROSS_COMPATIBLE_DISCOUNTS_FAILED,
})

export const resetCrossSellCompatibleDiscounts = () => ({
  type: constants.RESET_CROSS_COMPATIBLE_DISCOUNTS,
})

export const fetchCrossSellTariffPromotions = (segment, poId) => ({
  type: constants.FETCH_CROSS_TARIFF_PROMOTIONS,
  payload: {
    segment,
    poId,
  },
})

export const fetchCrossSellTariffPromotionsSuccess = data => ({
  type: constants.FETCH_CROSS_TARIFF_PROMOTIONS_SUCCESS,
  payload: data,
})

export const fetchCrossSellTariffPromotionsFailed = () => ({
  type: constants.FETCH_CROSS_TARIFF_PROMOTIONS_FAILED,
})
