import { useState, useEffect } from 'react'

export function useFormikSubmitted(isSubmitting) {
  const [isSubmitted, setIsSubmitted] = useState(false)

  useEffect(() => {
    if (isSubmitting) {
      setIsSubmitted(true)
    }
  }, [isSubmitting])

  return {
    isSubmitted,
  }
}
