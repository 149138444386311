export const NEWTON = 'newton'
export const POS = 'pos'
export const TELESALES = 'telesales'
export const TELESALES_PRO = 'telesales_pro'
export const FUSION_TELESALES = 'fusion_telesales'
export const FUSION_TELESALES_PRO = 'fusion_telesales_pro'
export const POS_PRO = 'pos_pro'
export const FUSION_POS = 'fusion_pos'
export const FUSION_POS_PRO = 'fusion_pos_pro'
export const FUSION_SELF_PRO = 'fusion_self_pro'
export const CC = 'cc'
export const CC_PRO = 'cc_pro'
export const SELF_ORDER = 'selforder'
export const TELESALES_ORDER = 'telesalesorder'
export const POS_ORDER = 'posorder'
export const POS_ORDER_PRO = 'posorder_pro'
export const FUSION_POS_ORDER = 'fusion_posorder'
export const FUSION_POS_ORDER_PRO = 'fusion_posorder_pro'
export const CC_ORDER = 'ccorder'
export const FUSION_CC_ORDER = 'fusion_ccorder'
export const RENEWAL_ORDER = 'renewal_order'
export const RENEWAL_TELESALES_ORDER = 'renewal_telesales_order'
export const RENEWAL_CTC_ORDER = 'renewal_ctc_order'
export const RETENTION_ORDER = 'retention_order'
export const FUSION_EMISSION = 'fusion_emission'

export const TELESALES_REGEX = /newton|telesales_pro|telesales/

// channel codes
export const CHANNEL_CODES = {
  [TELESALES]: '02',
  [FUSION_TELESALES]: '02',
  [CC]: '04',
  [TELESALES_PRO]: '08',
  [FUSION_TELESALES_PRO]: '08',
  [CC_PRO]: '09',
}
