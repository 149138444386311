import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import {
  findBillingsInitAction,
  selectBillingsIsLoading,
  selectBillingsError,
  selectBillingsData,
} from 'modules/billings'

import { SpinnerCenter } from 'components/ui'

import { LatestBills } from '../../components/LatestBills'

export function LatestBillsContainer({ className }) {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(findBillingsInitAction({ filter: 'period:12' }))
  }, [])

  const isLoading = useSelector(selectBillingsIsLoading)

  const props = {
    className,
    isLoading,
    hasErrors: useSelector(selectBillingsError),
    bills: useSelector(selectBillingsData),
  }

  return (
    <>
      {isLoading && <SpinnerCenter absolute style={{ marginTop: '20%' }} />}
      {!isLoading && <LatestBills {...props} />}
    </>
  )
}

LatestBillsContainer.propTypes = {
  className: PropTypes.string,
}
