import { svasActionTypes } from './svas.actions'

const initialState = {
  pro: {
    data: [],
    loading: null,
    error_code: null,
    error_message: null,
  },
  agile: {
    data: [],
    loading: null,
    error_code: null,
    error_message: null,
  },
  renewalAgile: {
    data: [],
    loading: null,
    error_code: null,
    error_message: null,
  },
  sameEmail: false,
}

export const svaReducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case svasActionTypes.FETCH_SVAS_LIST_PRO:
      return {
        ...state,
        pro: {
          ...initialState.pro,
          loading: true,
        },
      }

    case svasActionTypes.FETCH_SVAS_LIST_PRO_SUCCESS:
      return {
        ...state,
        pro: {
          loading: false,
          data: payload,
          error_code: false,
          error_message: false,
        },
      }

    case svasActionTypes.FETCH_SVAS_LIST_PRO_ERROR:
      return {
        ...state,
        pro: {
          loading: false,
          data: payload.svas,
          error_code: payload.error.httpCode,
          error_message: payload.error.info,
        },
      }

    case svasActionTypes.FETCH_SVAS_AGILE_TV:
      return {
        ...state,
        agile: {
          ...initialState.agile,
          loading: true,
          success: false,
        },
      }

    case svasActionTypes.FETCH_SVAS_AGILE_TV_SUCCESS:
      return {
        ...state,
        agile: {
          loading: false,
          data: payload,
          error_code: null,
          error_message: null,
          success: true,
        },
      }

    case svasActionTypes.FETCH_SVAS_AGILE_TV_ERROR:
      return {
        ...state,
        agile: {
          loading: false,
          data: [],
          error_code: payload.httpCode,
          error_message: payload.info,
          success: false,
        },
      }

    case svasActionTypes.SET_SAME_EMAIL_SVA: {
      return {
        ...state,
        sameEmail: payload,
      }
    }

    case svasActionTypes.FETCH_RENEWAL_SVAS_AGILE_TV:
      return {
        ...state,
        renewalAgile: {
          ...initialState.renewalAgile,
          loading: true,
        },
      }

    case svasActionTypes.FETCH_RENEWAL_SVAS_AGILE_TV_SUCCESS:
      return {
        ...state,
        renewalAgile: {
          loading: false,
          data: payload,
          error_code: null,
          error_message: null,
        },
      }

    case svasActionTypes.FETCH_RENEWAL_SVAS_AGILE_TV_ERROR:
      return {
        ...state,
        renewalAgile: {
          loading: false,
          data: [],
          error_code: payload.httpCode,
          error_message: payload.info,
        },
      }

    default:
      return state
  }
}
