import { call, put, takeLatest, select } from 'redux-saga/effects'
import { logError, formatErrorMessage } from 'services/logging'
import { axiosFactory } from 'modules/axios'
import { push } from 'connected-react-router'
import { get, isNil } from 'lodash'

import {
  fetchCreditScoringActionSuccess,
  fetchCreditScoringActionError,
  fetchPrescoringActionSuccess,
  fetchPrescoringActionError,
  scoringActionTypes,
  setBasketData,
  setRequestLoading,
} from '../actions'

import {
  selectPrescoringRequestBody,
  selectScoringRequestBodyNew,
} from '../selectors/scoring.selectors.new'
import { fetchCreditScoring, fetchPrescoring } from '../../services'
import { selectBasket } from '../selectors/index'

export function* fetchCreditScoringSaga({ payload }) {
  try {
    yield put(setRequestLoading({ loading: true }))

    const salesScoringBody = yield select(selectScoringRequestBodyNew)

    const scoringBody = payload || salesScoringBody
    const scoringResult = yield call(fetchCreditScoring, scoringBody)
    yield put(
      fetchCreditScoringActionSuccess({
        data: {
          ...get(scoringResult, 'data.submissionResponse'),
          errors: get(scoringResult, 'data.errors'),
        },
        scoringToken: get(scoringResult, 'data.scoringToken'),
      }),
    )
    yield put(setRequestLoading({ loading: false }))
  } catch (e) {
    yield call(logError, new Error(formatErrorMessage(e)))
    const error = axiosFactory.buildError(e)

    yield put(setRequestLoading({ loading: false }))
    yield put(fetchCreditScoringActionError(error))
  }
}

export function* watchFetchCreditScoringSaga() {
  yield takeLatest(scoringActionTypes.FETCH_CREDIT_SCORING, fetchCreditScoringSaga)
}

export function* fetchPrescoringSaga({ payload }) {
  try {
    const { scoreType } = payload

    yield put(setRequestLoading({ loading: true }))
    let data = yield select(selectPrescoringRequestBody)

    if (!isNil(scoreType)) {
      const submission = { ...data.submission, scoreType: payload.scoreType }
      data = { ...data, submission }
    }

    if (payload.noPreReferenceNumber) {
      delete data.submission.referenceNumber
    }
    const basket = yield select(selectBasket)
    const prescoringResult = yield call(fetchPrescoring, data)
    const result = {
      ...get(prescoringResult, 'data.submissionResponse'),
      scoringToken: get(prescoringResult, 'data.scoringToken'),
    }

    yield put(fetchPrescoringActionSuccess(result))
    yield put(setBasketData({ ...basket, referenceNumber: get(result, 'referenceNumber', '') }))

    yield put(setRequestLoading({ loading: false }))

    if (result.decision !== 'D') {
      // If the result decision is OK, redirect to next page
      if (payload && payload.nextStep) {
        yield put(push(payload.nextStep))
      }
    }
  } catch (e) {
    yield call(logError, { e: new Error(formatErrorMessage(e)), payload })
    yield put(setRequestLoading({ loading: false }))

    if (payload && payload.nextStep) {
      yield put(push(payload.nextStep))
    }
    const error = axiosFactory.buildError(e)
    yield put(fetchPrescoringActionError(error))
  }
}

export function* watchFetchPrescoringSaga() {
  yield takeLatest(scoringActionTypes.FETCH_PRESCORING, fetchPrescoringSaga)
}
