/* eslint-disable no-unused-vars */
import {
  chain,
  find,
  flatMap,
  get,
  groupBy,
  isEmpty,
  map,
  mapValues,
  pick,
  reduce,
  uniqBy,
} from 'lodash'
import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { getTax, isLoading as isLoadingTax, applyTax } from 'services/taxes'
import { isProCustomer } from 'services/customer-360/helpers'
import { getSegment } from 'services/customer-360/selectors'
import { selectCustomerId } from 'modules/Auth/store/selectors'
import {
  selectFetchCutomerSvasFlag,
  selectEnabledTaxSvaAgileTv,
} from 'services/feature-flag/selectors'

import { selectors as customerSelectors } from 'services/customer-360'
import {
  selectSubscriptions,
  getSubscriptionsCurrentMonthEstimatedTotalExpenses,
  isLoading as isLoadingSubscriptions,
  findFixedProduct,
} from 'services/subscriptions'
import { selectActiveCustomerSvas } from 'services/customer-sva/selectors'
import { isElFijoTariff } from 'modules/tariffs/helpers/index'
import { actions as customerActivityActions } from 'modules/CustomerActivity'
import {
  getConvergentRelatedMobileTariff,
  selectTariffsApigeeDataTariffs,
} from 'modules/tariffs/store-apigee'

import { roundTwoDecimals } from 'utils'

import { isCrossSellDiscount } from 'modules/Discounts'
import { MonthlyCustomerConsumptionCard } from '../../components/MonthlyCustomerConsumptionCard'

const EMPTY_EXPENSES = 0

const calculateOverallExpenses = subscriptions =>
  reduce(subscriptions, (result, subscription) => result + subscription.totalExpenses, 0)

const useCalculateOverallExpenses = subscriptions =>
  roundTwoDecimals(calculateOverallExpenses(subscriptions))

const hasSubscriptionCrossSellDiscount = subscription =>
  find(subscription?.charges, ({ discount_plan: discount }) =>
    isCrossSellDiscount(discount.rule_id),
  )

const addOwnAndRelatedTariffToConvergentSubscriptions = (subscriptions, tariffs) =>
  mapValues(subscriptions, subscription => {
    if (!hasSubscriptionCrossSellDiscount(subscription.data)) {
      return subscription
    }

    return {
      ...subscription,
      data: {
        ...subscription.data,
        tariff: tariffs[subscription.data?.type],
        relatedCrossSellTariff: getConvergentRelatedMobileTariff(subscription.data?.type, tariffs),
      },
    }
  })

const mergeSubscriptionData = (subscription, subscriptionId, subscriptionExpenses) => {
  if (!subscription.data) {
    return subscription
  }

  const basicFields = pick(subscription.data, ['id', 'productNumber', 'description'])

  const isPostPaid = get(subscription, ['data', 'is_postpaid'], false)
  const fixedLine = findFixedProduct(get(subscription, 'data', {}))
  const isElFijo = isElFijoTariff(get(subscription, ['data', 'type'], null))

  const associatedToLine = isElFijo ? fixedLine?.id : basicFields?.id

  const totalExpenses = roundTwoDecimals(subscriptionExpenses[subscriptionId]) || EMPTY_EXPENSES

  return { ...basicFields, isPostPaid, associatedToLine, totalExpenses }
}

const formatAndGroupByPaymentSubscriptions = (subscriptions, customerSvas, totalExpenses) =>
  !isEmpty(subscriptions)
    ? chain(subscriptions)
        .flatMap((fullSubscriptions, id) =>
          mergeSubscriptionData(fullSubscriptions, id, totalExpenses),
        )
        .filter(subscription => !isEmpty(subscription.id))
        .uniqBy('productNumber')
        .concat(
          customerSvas.map(sva => ({
            id: `${sva.code}-${sva.startDate}`,
            description: sva.name,
            isPostPaid: true,
            totalExpenses: sva.monthlyFeeWithTax,
          })),
        )
        .value()
    : []

const useGroupSubscriptionsByPayment = suscriptions =>
  groupBy(suscriptions, subscription => (subscription.isPostPaid ? 'postPaid' : 'prePaid'))

const useSelectSubscriptionsExpenses = () => {
  const customerId = useSelector(selectCustomerId)
  const customerSegment = useSelector(getSegment)
  const taxNeeded = !isProCustomer(customerSegment)
  const dispatch = useDispatch()
  const postalCode = useSelector(customerSelectors.getBillingAddressPostCode)
  const subscriptions = useSelector(selectSubscriptions)

  let formattedSubscriptions

  useEffect(() => {
    if (customerId) {
      dispatch(customerActivityActions.fetchCustomerSubscriptions(postalCode))
    }
  }, [])

  const customerSvas = useSelector(selectActiveCustomerSvas)
  const canFetchCustomerSvas = useSelector(selectFetchCutomerSvasFlag)

  if (subscriptions) {
    const tax = (taxNeeded && useSelector(state => getTax(state, postalCode))) || 0

    const tariffs = useSelector(selectTariffsApigeeDataTariffs)
    const enabledTaxSvaAgileTv = useSelector(selectEnabledTaxSvaAgileTv)
    const subscriptionsWithTariffs = addOwnAndRelatedTariffToConvergentSubscriptions(
      subscriptions,
      tariffs,
    )

    const subscriptionsTotalExpenses = useSelector(state =>
      getSubscriptionsCurrentMonthEstimatedTotalExpenses(
        state,
        subscriptionsWithTariffs,
        tax,
        taxNeeded,
        enabledTaxSvaAgileTv,
      ),
    )

    formattedSubscriptions = formatAndGroupByPaymentSubscriptions(
      subscriptions,
      canFetchCustomerSvas ? customerSvas : [],
      subscriptionsTotalExpenses,
    )
  }

  return formattedSubscriptions
}

const useIsLoadingTaxOrSubscriptions = () => {
  const postalCode = useSelector(customerSelectors.getBillingAddressPostCode)
  const taxLoading = useSelector(state => isLoadingTax(state, postalCode))
  const subscripttionsLoading = useSelector(isLoadingSubscriptions)

  return taxLoading || subscripttionsLoading
}

export function MonthlyCustomerConsumptionContainer() {
  const customerSegment = useSelector(getSegment)
  const taxNeeded = !isProCustomer(customerSegment)

  const loading = useIsLoadingTaxOrSubscriptions()

  const subscriptions = useSelectSubscriptionsExpenses()
  const subscriptionsGroupedByPayment = useGroupSubscriptionsByPayment(subscriptions)

  const overallExpenses = useCalculateOverallExpenses(subscriptions)
  const prePaidOverallExpenses = useCalculateOverallExpenses(subscriptionsGroupedByPayment.prePaid)
  const postPaidOverallExpenses = useCalculateOverallExpenses(
    subscriptionsGroupedByPayment.postPaid,
  )

  const props = {
    subscriptionsGroupedByPayment,
    overallExpenses,
    prePaidOverallExpenses,
    postPaidOverallExpenses,
    taxNeeded,
    loading,
  }

  return <MonthlyCustomerConsumptionCard {...props} />
}
