import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useFormikContext } from 'formik'
import { get } from 'lodash'

import { Box, FormControlLabel, Radio } from '@material-ui/core'
import { FormikRadio, FormikInput } from 'components/ui'

import { useOfferTypeValidation } from './useOfferTypeValidation'
import { ErrorModal } from '../../Common'

export function OfferType({ isDisabled, isPortability, handleIsPortability, installationAddress }) {
  const { validate, hasMsError, error, fieldName, fieldValue } = useOfferTypeValidation(
    'landlineOffer',
  )

  const { setStatus, status, values } = useFormikContext()

  const { lineNumber } = get(values, 'landlineOffer', {})

  useEffect(() => {
    if (isPortability === 'yes') {
      validate(lineNumber, true)
    } else {
      setStatus({ ...status, 'landlineOffer.lineNumber': '' })
    }
  }, [isPortability, installationAddress.gescal])

  return (
    <>
      <Box display="flex" alignItems="center" flexWrap="wrap">
        <Box pr="20px">
          <FormikRadio
            disabled={isDisabled}
            label="¿Va a hacer portabilidad de número fijo?"
            name="landlineOffer.isPortability"
            row
            onChange={handleIsPortability}>
            <FormControlLabel
              value="yes"
              control={
                <Radio
                  data-hook="landlineOffer.isPortability.yes"
                  className="radio-check"
                  color="primary"
                />
              }
              label="Sí"
            />
            <FormControlLabel
              value="no"
              control={
                <Radio
                  data-hook="landlineOffer.isPortability.no"
                  className="radio-check"
                  color="primary"
                />
              }
              label="No"
            />
          </FormikRadio>
        </Box>
        {isPortability === 'yes' && (
          <FormikInput
            onChange={validate}
            className="m-r-14"
            width="280px"
            maxLength="9"
            name="landlineOffer.lineNumber"
            label="Teléfono fijo a portar"
            margin="none"
          />
        )}
        <ErrorModal
          error={error}
          hasMsError={hasMsError}
          fieldName={fieldName}
          fieldValue={fieldValue}
        />
      </Box>
    </>
  )
}

OfferType.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  isPortability: PropTypes.string.isRequired,
  handleIsPortability: PropTypes.func.isRequired,
  installationAddress: PropTypes.object,
}
