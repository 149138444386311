import { constants } from './add-agiletv.constants'

export const fetchAddAgileTvAllowed = ({
  documentType,
  documentId,
  segment,
  isSelfEmployed,
  channel,
}) => ({
  type: constants.FETCH_ADD_AGILETV_ALLOWED,
  payload: {
    documentType,
    documentId,
    segment,
    isSelfEmployed,
    channel,
  },
})

export const fetchAddAgileTvAllowedSuccess = () => ({
  type: constants.FETCH_ADD_AGILETV_ALLOWED_SUCCESS,
})

export const fetchAddAgileTvAllowedFailed = error => ({
  type: constants.FETCH_ADD_AGILETV_ALLOWED_FAILED,
  payload: error,
})

export const addAgileTv = ({ email, productOfferingId, parentAgreementId }) => ({
  type: constants.ADD_AGILETV,
  payload: {
    email,
    productOfferingId,
    parentAgreementId,
  },
})

export const addAgileTvSuccess = () => ({
  type: constants.ADD_AGILETV_SUCCESS,
})

export const addAgileTvFailed = error => ({
  type: constants.ADD_AGILETV_FAILED,
  payload: error,
})
