import { scoringActionTypes } from '../actions'

export const scoringInitialState = {
  scoring: {
    decission: null, // Unset
    reference_number: '',
  },
  prescoring: {},
  loading: false,
  errorScoring: false,
  errorPrescoring: false,
}

export const scoringReducer = (state = scoringInitialState, action) => {
  const { type, payload } = action

  switch (type) {
    case scoringActionTypes.FETCH_CREDIT_SCORING: {
      return { ...state, loading: true, scoring: { ...scoringInitialState.scoring } }
    }
    case scoringActionTypes.FETCH_CREDIT_SCORING_SUCCESS: {
      return { ...state, loading: false, errorScoring: false, scoring: { ...payload } }
    }
    case scoringActionTypes.FETCH_CREDIT_SCORING_ERROR: {
      return {
        ...state,
        loading: false,
        errorScoring: true,
        scoring: {
          decission: null, // Unset
          reference_number: '',
        },
      }
    }

    case scoringActionTypes.RESET_CREDIT_SCORING: {
      return {
        ...scoringInitialState,
        prescoring: state.prescoring,
        ipAddress: state.ipAddress,
        errorPrescoring: state.errorPrescoring,
      }
    }

    case scoringActionTypes.FETCH_PRESCORING: {
      return { ...state, loading: true, prescoring: { ...scoringInitialState.prescoring } }
    }

    case scoringActionTypes.FETCH_PRESCORING_SUCCESS: {
      return {
        ...state,
        loading: false,
        errorPrescoring: payload.decision === 'D',
        prescoring: { ...payload },
      }
    }
    case scoringActionTypes.FETCH_PRESCORING_ERROR: {
      return {
        ...state,
        loading: false,
        errorPrescoring: false, // se permite la venta
        prescoring: { ...payload },
      }
    }
    default:
      return state
  }
}
