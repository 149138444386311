// this service could probably be in a global customers module
// we need to discuss this with care

import { axiosJWT } from 'modules/axios'

import { APP_CONFIG } from 'services/app-config'

const BASE_URL_INFO = `${APP_CONFIG.sales_cluster}/v1/customers/customerAccountInfo`
const BASE_URL_BILLING_ACCOUNT = `${APP_CONFIG.sales_cluster}/v1/customers/billing-account`
const BASE_URL_ACCOUNT_ID = `${APP_CONFIG.sales_cluster}/v1/customers/contact-media`
const BASE_URL_POSTAL_ADDRESS = `${APP_CONFIG.sales_cluster}/v1/customers/postal-address`
const BASE_URL_IS_CUSTOMER = `${APP_CONFIG.sales_cluster}/v1/customers/isCustomerByDocument`
const BASE_URL_IS_PHONE = `${APP_CONFIG.sales_cluster}/v1/customers/isCustomerByPhone`
const BASE_URL_MAS_STACK = `${APP_CONFIG.sales_cluster}/v1/customers/allowSaleCustomersMasStack`

export function fetchCustomerInformation(docNumber, docType) {
  return axiosJWT({
    method: 'GET',
    url: BASE_URL_INFO,
    params: {
      docNumber,
      docType,
    },
  }).then(resp => resp.data)
}

export function fetchNewIbanValueCartera(bankAccount, billingAccountId) {
  return axiosJWT({
    method: 'PATCH',
    url: `${BASE_URL_BILLING_ACCOUNT}/${billingAccountId}`,
    data: {
      bankAccount,
    },
  }).then(resp => resp.data)
}

export function setCustomerContactMedia(accountId, segment, data) {
  return axiosJWT({
    method: 'PATCH',
    params: {
      segment,
    },
    url: `${BASE_URL_ACCOUNT_ID}/${accountId}`,
    data,
  }).then(resp => resp.data)
}

export function setNewPostalAddressCartera(postalAddress, accountId, params) {
  return axiosJWT({
    method: 'PATCH',
    params,
    url: `${BASE_URL_POSTAL_ADDRESS}/${accountId}`,
    data: postalAddress,
  }).then(resp => resp.data)
}

export function fetchAuthIsCustomerDoc(docType, docNumber) {
  return axiosJWT({
    method: 'GET',
    url: BASE_URL_IS_CUSTOMER,
    params: { docNumber, docType },
  }).then(res => res.data)
}

export function fetchAuthIsCustomerNum(phoneType, phoneNumber) {
  return axiosJWT({
    method: 'GET',
    url: BASE_URL_IS_PHONE,
    params: { phoneType, phoneNumber },
  }).then(res => res.data)
}

export const fetchCustomerIsAllowed = (documentType, document) =>
  axiosJWT({
    method: 'GET',
    url: BASE_URL_MAS_STACK,
    params: { documentType, document },
  }).then(res => res.data)
