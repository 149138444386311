import { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'

import { Card, CardContent, Box, Typography, Divider } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import { formatFee, TextStyles } from 'utils'

import { ButtonLink, ActionAdvice, FormikInput } from 'components/ui'

import { TerminalFinancialData } from 'modules/Terminals/components/TerminalFinancialDataComponent'
import { AddressChooser } from 'modules/Address'
import { OTHER_ADDRESS } from 'modules/SharedSales/constants/shippingAddress'

import { DEVICE_TYPE_AGILE_TV } from 'modules/device-renewal/constants/deviceTypes'
import { SVAsModal } from 'modules/SVAs/components/SVAsModalComponent/SVAsModal'
import { FinancedDeviceSummary } from '../../../components/FinancedDeviceSummary'
import { DeviceSpecificationsModal } from '../../../components/DeviceSpecificationsModal'
import { FinancedPaymentDetailModal } from '../../../components/FinancedPaymentDetailModal'
import { DEVICE_RENEWAL_FIELDNAMES } from '../../../constants'

const useStyles = makeStyles(theme => ({
  'device-new-card': {
    '& .change-device': {
      marginLeft: '20px',
    },
  },
  'device-data': {
    marginBottom: '4px',
  },

  'summary-advice': {
    marginTop: '25px',

    '& > div': {
      minHeight: '50px',

      '& .message': {
        fontWeight: 500,
        color: theme.palette.global.gray_dark,
      },
    },
  },

  'other-address-warning': {
    margin: '20px 0 30px',
  },

  dotted: {
    backgroundColor: 'initial',
    height: '1px',
    borderBottom: `2px dashed ${theme.palette.global.gray_medium_2}`,
  },

  flexRow: {
    display: 'flex',
    'flex-direction': 'row',
  },
}))

export const DeviceSummary = ({
  device,
  deviceType,
  onChangeDevice,
  paymentType,
  professions,
  showCompany,
  onSelectProfession,
  availableAddressTypes,
  addressType,
  onSelectAddressType,
  onResetOtherAddress,
  preselectedAddress,
  isAdditionalLineWithDiscount,
  currentFinancedDeviceInfo,
  agileTvData,
  validateAgileTvEmail,
}) => {
  const classes = useStyles()

  const [specificationsIsOpen, setSpecificationsIsOpen] = useState(false)
  const [financedPaymentDetailIsOpen, setFinancedPaymentDetailIsOpen] = useState(false)

  const paymentMethodInfo = useMemo(
    () => device?.paymentMethods && device?.paymentMethods[paymentType],
    [device.paymentMethods, paymentType],
  )

  return (
    <>
      <DeviceSpecificationsModal
        id={device.id}
        name={device.name}
        open={specificationsIsOpen}
        onClose={() => setSpecificationsIsOpen(false)}
      />
      <FinancedPaymentDetailModal
        name={device.name}
        financedPaymentInfo={paymentMethodInfo}
        financingConditionsTaxes={get(device, 'financingConditions', {})}
        open={financedPaymentDetailIsOpen}
        onClose={() => setFinancedPaymentDetailIsOpen(false)}
      />

      {currentFinancedDeviceInfo && (
        <FinancedDeviceSummary
          currentFinancedDeviceInfo={currentFinancedDeviceInfo}
          classes={classes}
        />
      )}

      <Card style={{ width: '100%' }} className={classes['device-new-card']}>
        <Box p="20px 24px 18px" display="flex" alignItems="center">
          <Typography variant="h5" style={{ fontWeight: 'bold' }}>
            Dispositivo nuevo - {device.name}
          </Typography>

          <ButtonLink onClick={onChangeDevice} className="change-device">
            Cambiar dispositivo
          </ButtonLink>
        </Box>

        <Divider />

        <CardContent>
          <Typography component="h6" variant="body1" style={{ fontWeight: 'bold' }}>
            DATOS DEL DISPOSITIVO:
          </Typography>

          <Box display="flex" mt="12px">
            <div style={{ maxWidth: '50%' }}>
              <Typography
                variant="body2"
                className={classes['device-data']}
                data-hook="terminal.capacity">
                <Typography variant="subtitle2" component="span" className="p-r-4">
                  Capacidad:
                </Typography>

                {get(device, 'characteristics.capacity')}
              </Typography>

              <Typography
                variant="body2"
                className={classes['device-data']}
                data-hook="terminal.screen">
                <Typography variant="subtitle2" component="span" className="p-r-4">
                  Pantalla:
                </Typography>
                {get(device, 'characteristics.screen')}
              </Typography>

              {device.id && paymentType !== 'upfront' && (
                <Typography
                  variant="body2"
                  className={classes['device-data']}
                  data-hook="terminal.penalty">
                  <Typography variant="subtitle2" component="span" className="p-r-4">
                    Permanencia:
                  </Typography>
                  {get(paymentMethodInfo, 'permanency.count', 0)} meses
                </Typography>
              )}

              {device.id && paymentType !== 'upfront' && (
                <Typography
                  variant="body2"
                  className={classes['device-data']}
                  data-hook="terminal.comission">
                  <Typography variant="subtitle2" component="span" className="p-r-4">
                    Comisión de aplazamiento:
                  </Typography>
                  {formatFee(
                    (get(paymentMethodInfo, 'monthlyPayment.taxFreeAmount', 0) *
                      get(paymentMethodInfo, 'fees', 0) *
                      get(device, 'financingConditions.commissionFeePercentage', 0)) /
                      100,
                  )}
                </Typography>
              )}
              {device.id && (
                <ButtonLink onClick={() => setSpecificationsIsOpen(true)}>
                  Ver características
                </ButtonLink>
              )}
            </div>
            <div className="m-l-36 p-l-36">
              {device.id && paymentType !== 'upfront' && (
                <Typography
                  variant="body2"
                  className={classes['device-data']}
                  data-hook="terminal.initialPayment">
                  <Typography variant="subtitle2" component="span" className="p-r-4">
                    Precio mes ({get(paymentMethodInfo, 'fees', 0)}):
                  </Typography>
                  {formatFee(get(paymentMethodInfo, 'monthlyPayment.taxFreeAmount', 0))}
                  /mes
                </Typography>
              )}

              {device.id && (paymentType === 'upfront' || paymentMethodInfo?.initialPayment) && (
                <Typography
                  variant="body2"
                  className={classes['device-data']}
                  data-hook="terminal.initialPayment">
                  <Typography variant="subtitle2" component="span" className="p-r-4">
                    {paymentType === 'upfront' ? 'Pago único' : 'Pago inicial'}:
                  </Typography>
                  {formatFee(get(paymentMethodInfo, 'initialPayment.taxIncludedAmount', 0))}
                </Typography>
              )}

              {device.id && paymentType !== 'upfront' && paymentMethodInfo?.finalPayment && (
                <Typography
                  variant="body2"
                  className={classes['device-data']}
                  data-hook="terminal.finalPayment">
                  <Typography variant="subtitle2" component="span" className="p-r-4">
                    Pago final (Q25):
                  </Typography>
                  {formatFee(get(paymentMethodInfo, 'finalPayment.taxIncludedAmount', 0))}
                  /mes
                </Typography>
              )}

              {device.id && paymentType !== 'upfront' && (
                <ButtonLink onClick={() => setFinancedPaymentDetailIsOpen(true)}>
                  Ver info Pago
                </ButtonLink>
              )}
            </div>
          </Box>

          {isAdditionalLineWithDiscount && (
            <ActionAdvice
              className={classes['summary-advice']}
              type="warning"
              icon="info"
              message="Si contrata el dispositivo, perderá el descuento de línea adicional."
            />
          )}
          {get(paymentMethodInfo, 'permanency.penaltyAmountTaxFree') && (
            <ActionAdvice
              className={classes['summary-advice']}
              type="info"
              message={`Descuento aplicado de ${formatFee(
                get(paymentMethodInfo, 'permanency.penaltyAmountTaxFree'),
              )} por permanencia de ${get(
                paymentMethodInfo,
                'permanency.count',
                0,
              )} meses de Contrato de Yoigo, ya incluido en el precio.`}
            />
          )}

          {device.id && paymentType !== 'upfront' && (
            <>
              <Box my="30px">
                <Divider className={classes.dotted} />
              </Box>

              <TerminalFinancialData
                fieldNames={DEVICE_RENEWAL_FIELDNAMES}
                professionOptions={professions}
                shouldShowCompany={showCompany}
                onSelectProfession={onSelectProfession}
              />
            </>
          )}

          {deviceType === DEVICE_TYPE_AGILE_TV && agileTvData && (
            <div className="m-t-20">
              <Divider className={classes.dotted} />

              <div className="m-t-20">
                <Typography
                  {...TextStyles.subtitle2Dark({
                    component: 'h6',
                  })}>
                  SERVICIO DE TELEVISIÓN ADICIONAL:
                </Typography>

                <Typography
                  {...TextStyles.paragraphDark()}
                  className={`m-t-12 m-b-8 ${classes.flexRow}`}>
                  {`${agileTvData.commercialInfo.name} (${formatFee(
                    agileTvData.price.taxFreeAmount,
                  )}/mes imp. incluido)`}

                  {agileTvData.commercialInfoFormated && (
                    <SVAsModal commercialInfo={agileTvData.commercialInfoFormated} />
                  )}
                </Typography>
              </div>
              <div className="m-t-20">
                <Typography variant="body1" style={{ fontWeight: 300, marginBottom: '5px' }}>
                  Email para la subscripción:
                </Typography>

                <FormikInput
                  name={DEVICE_RENEWAL_FIELDNAMES.SVA_AGILE_EMAIL}
                  label="Email"
                  width="50%"
                  validate={value =>
                    validateAgileTvEmail(value, DEVICE_RENEWAL_FIELDNAMES.SVA_AGILE_EMAIL)
                  }
                />
              </div>
            </div>
          )}
        </CardContent>
      </Card>

      <Card style={{ width: '100%' }}>
        <Box p="20px 24px 18px" display="flex" alignItems="center">
          <Typography variant="h5" style={{ fontWeight: 'bold' }}>
            Dirección de entrega
          </Typography>
        </Box>

        <Divider />

        <CardContent>
          <AddressChooser
            preselectedAddress={preselectedAddress}
            availableAddressTypes={availableAddressTypes}
            selectedAddressType={addressType}
            onSelectAddress={onSelectAddressType}
            onEditAddress={onResetOtherAddress}
            type="shipping">
            {addressType === OTHER_ADDRESS.value && (
              <ActionAdvice
                className={[classes['other-address-warning'], classes['summary-advice']].join(' ')}
                type="warning"
                message='AVISO: Eligiendo "Otra dirección", el proceso de venta terminará a través de ATC.'
              />
            )}
          </AddressChooser>
        </CardContent>
      </Card>
    </>
  )
}

DeviceSummary.propTypes = {
  device: PropTypes.object.isRequired,
  deviceType: PropTypes.string.isRequired,
  onChangeDevice: PropTypes.func.isRequired,
  paymentType: PropTypes.string.isRequired,
  professions: PropTypes.array.isRequired,
  showCompany: PropTypes.bool,
  onSelectProfession: PropTypes.func.isRequired,
  availableAddressTypes: PropTypes.array.isRequired,
  addressType: PropTypes.string,
  onSelectAddressType: PropTypes.func.isRequired,
  onResetOtherAddress: PropTypes.func.isRequired,
  preselectedAddress: PropTypes.object.isRequired,
  isAdditionalLineWithDiscount: PropTypes.bool,
  currentFinancedDeviceInfo: PropTypes.object,
  agileTvData: PropTypes.object,
  validateAgileTvEmail: PropTypes.func.isRequired,
}
