import { get } from 'lodash/fp'

import {
  FIND_ORDER_PRODUCTS_BY_IDS,
  FIND_ORDER_PRODUCTS_BY_IDS_SUCCESS,
  FIND_ORDER_PRODUCTS_BY_IDS_FAILED,
} from './constants'

const initialState = {
  data: {},
  loading: false,
  error: null,
}

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FIND_ORDER_PRODUCTS_BY_IDS: {
      return {
        ...state,
        error: null,
        loading: true,
      }
    }
    case FIND_ORDER_PRODUCTS_BY_IDS_SUCCESS: {
      return {
        ...state,
        data: { ...state.data, ...payload },
        loading: false,
      }
    }
    case FIND_ORDER_PRODUCTS_BY_IDS_FAILED: {
      return {
        ...state,
        error: get('error', payload) || payload,
        loading: false,
      }
    }
    default:
      return state
  }
}
