import { constants } from './change-agiletv.constants'

const initialState = {
  loading: false,
  error: null,
  success: false,
}

export const reducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case constants.CHANGE_AGILETV: {
      return {
        ...state,
        loading: true,
        error: null,
        success: false,
      }
    }

    case constants.CHANGE_AGILETV_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: null,
        success: true,
      }
    }

    case constants.CHANGE_AGILETV_FAILED: {
      return {
        ...state,
        loading: false,
        error: payload,
        success: false,
      }
    }

    default:
      return state
  }
}
