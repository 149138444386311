import { createStructuredSelector, createSelector } from 'reselect'

import { selectContentfulResource } from 'modules/contentful'
import { mapToProfessionOptions } from 'modules/NewClientSales/containers/OfferContainer/TerminalSelectionContainer/TerminalSelectionContainer.helpers'

import { getSubscriptionDiscounts } from 'services/subscriptions/selectors'

import { ADDITIONAL_LINE } from 'modules/SharedSales/constants/productCategories'

import { selectRenewalAgileTvFormated } from 'modules/SVAs/store/svas.selectors'
import {
  selectRenewalDeviceInfo,
  selectRenewalMsisdn,
  selectFinancedDeviceSummaryInfo,
  selectRenewalSubscriptionProduct,
  selectFormattedBillingAddress,
  selectDeviceType,
} from '../../store/device-renewal.selectors'

const selectProfessions = selectContentfulResource('profession')

const selectProfessionOptions = createSelector(
  selectProfessions,
  mapToProfessionOptions,
)

const selectDevice = createSelector(
  [selectRenewalDeviceInfo],
  deviceInfo => {
    return deviceInfo
  },
)

export const selectIsAdditionalLineWithDiscount = msisdn =>
  createSelector(
    [selectRenewalSubscriptionProduct, getSubscriptionDiscounts(msisdn)],
    (product, discounts) =>
      product.multisimType === ADDITIONAL_LINE &&
      !!discounts?.find(discount => discount.value === 50),
  )

export const deviceSummarySelectors = createStructuredSelector({
  device: selectDevice,
  professions: selectProfessionOptions,
  preselectedAddress: selectFormattedBillingAddress,
  msisdn: selectRenewalMsisdn,
  currentFinancedDeviceInfo: selectFinancedDeviceSummaryInfo,
  deviceType: selectDeviceType,
  agileTvData: selectRenewalAgileTvFormated,
})
