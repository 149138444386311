import PropTypes from 'prop-types'
import { map, isEmpty } from 'lodash'

import { ActionAdvice, SpinnerCenter } from 'components/ui'

import { Box, Divider, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import { Card } from 'modules/ui/components/Card/Card'
import LineItemCard from 'components/packages/ui/card360/LineItemCard'

import { formatFee } from 'utils'

import {
  hasBundlesCallError,
  ERROR_BUNDLES_CALL_MESSAGE,
} from 'modules/bonuses/store/bonuses.helper'

import {
  PREPAID_CHARACTERISTIC_NAME,
  POSTPAID_CHARACTERISTIC_NAME,
} from 'services/subscriptions/index'

const SubscriptionsListByPayment = ({ subscriptions, type = PREPAID_CHARACTERISTIC_NAME }) => {
  if (isEmpty(subscriptions)) {
    return null
  }

  return map(subscriptions, ({ id, description, associatedToLine, totalExpenses }) => (
    <LineItemCard
      key={`${type}-${id}`}
      className="line-item-fst p-b-4"
      primaryTextDataHook={`${type}-paid-tariff-fee-${id}`}
      primaryText={`${description}${associatedToLine ? ` (${associatedToLine})` : ''}`}
      secondaryText={formatFee(totalExpenses)}
    />
  ))
}

const TotalsLineItem = ({ primaryText, overallExpenses }) => (
  <LineItemCard
    className="totals"
    fieldsApart
    primaryText={primaryText}
    secondaryText={`${formatFee(Math.max(0, overallExpenses))}`}
  />
)
TotalsLineItem.propTypes = {
  primaryText: PropTypes.string,
  overallExpenses: PropTypes.number,
}

const PostPaidSubscriptionsSection = ({ postPaid, overallExpenses, taxNeeded }) => {
  if (isEmpty(postPaid)) {
    return (
      <ActionAdvice
        className="advice-no-post-paid"
        type="info"
        message="No tiene subscripciones postpago"
      />
    )
  }

  return (
    <>
      <LineItemCard secondaryText="Precios con todo incluido" />
      <LineItemCard primaryText="(Dtos, bonos, dispositivos, gastos fuera de tarifa…)" isHeader />
      <SubscriptionsListByPayment subscriptions={postPaid} type={POSTPAID_CHARACTERISTIC_NAME} />

      <Box m="24px" mt="8px" mb="0px">
        <Divider className="divider" />
      </Box>

      <TotalsLineItem primaryText="Gasto estimado total " overallExpenses={overallExpenses} />

      {taxNeeded && (
        <Typography variant="caption" className="p-y-0 p-x-24">
          (Impuestos incluidos)
        </Typography>
      )}
      {hasBundlesCallError() && (
        <div className="p-x-24 p-y-12">
          <ActionAdvice type="error" icon="warning" message={ERROR_BUNDLES_CALL_MESSAGE} />
        </div>
      )}
    </>
  )
}

PostPaidSubscriptionsSection.propTypes = {
  postPaid: PropTypes.array,
  overallExpenses: PropTypes.number,
  taxNeeded: PropTypes.bool,
}

const PrePaidSubscriptionsSection = ({ prePaid, overallExpenses }) => {
  if (isEmpty(prePaid)) {
    return null
  }

  return (
    <div className="pre-paid-list">
      <LineItemCard secondaryText="Líneas prepago del cliente" />

      <SubscriptionsListByPayment subscriptions={prePaid} type={PREPAID_CHARACTERISTIC_NAME} />

      <Box m="24px" mt="8px" mb="0px" pt="1px">
        <Divider className="divider" />
      </Box>

      <LineItemCard
        className="totals"
        fieldsApart
        primaryText="Gasto estimado prepagos"
        secondaryText={`${formatFee(Math.max(0, overallExpenses))}`}
      />
    </div>
  )
}
PrePaidSubscriptionsSection.propTypes = {
  prePaid: PropTypes.array,
  overallExpenses: PropTypes.number,
}

const useStyles = makeStyles(theme => ({
  'monthly-customer-consumption-card': {
    '& .card-content .line-item-fst > div': {
      justifyContent: 'space-between',
      width: '100%',
    },

    '& .card-content .advice-no-post-paid': {
      margin: '10px 20px 0 20px',
    },

    '& .card-content .pre-paid-list': {
      marginTop: '20px',
    },

    '& .card-content .totals .primary-text': {
      fontSize: '16px',
      fontWeight: 500,
    },

    '& .divider': {
      backgroundColor: theme.palette.global.gray_dark,
    },
  },
}))

export const MonthlyCustomerConsumptionCard = ({
  subscriptionsGroupedByPayment,
  prePaidOverallExpenses,
  postPaidOverallExpenses,
  taxNeeded,
  loading,
}) => {
  const classes = useStyles()

  const { prePaid, postPaid } = subscriptionsGroupedByPayment

  return (
    <Card
      className={classes['monthly-customer-consumption-card']}
      id="monthly-cost-card"
      title="Gasto Suscripciones"
      withShadow>
      {loading && (
        <div className="loader-wrapper">
          <SpinnerCenter />
        </div>
      )}

      {!loading && (
        <>
          <PostPaidSubscriptionsSection
            postPaid={postPaid}
            overallExpenses={postPaidOverallExpenses}
            taxNeeded={taxNeeded}
          />
          <PrePaidSubscriptionsSection prePaid={prePaid} overallExpenses={prePaidOverallExpenses} />
        </>
      )}
    </Card>
  )
}

MonthlyCustomerConsumptionCard.defaultProps = {}

MonthlyCustomerConsumptionCard.propTypes = {
  subscriptionsGroupedByPayment: PropTypes.shape({
    prePaid: PropTypes.array,
    postPaid: PropTypes.array,
  }).isRequired,
  prePaidOverallExpenses: PropTypes.number.isRequired,
  postPaidOverallExpenses: PropTypes.number.isRequired,
  taxNeeded: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
}
