const RETRIEVE_SUBSCRIPTION = 'OVID/RENEWAL/RETRIEVE_SUBSCRIPTION'

const FETCH_DEVICE_RENEWAL_ALLOWED = 'OVID/RENEWAL/FETCH_DEVICE_RENEWAL_ALLOWED'
const FETCH_DEVICE_RENEWAL_ALLOWED_SUCCESS = 'OVID/RENEWAL/FETCH_DEVICE_RENEWAL_ALLOWED_SUCCESS'
const FETCH_DEVICE_RENEWAL_ALLOWED_FAILED = 'OVID/RENEWAL/FETCH_DEVICE_RENEWAL_ALLOWED_FAILED'
const RESET_DEVICE_RENEWAL_ALLOWED = 'OVID/RENEWAL/RESET_DEVICE_RENEWAL_ALLOWED'

const FETCH_DEVICE_RENEWAL_DEVICES = 'OVID/RENEWAL/FETCH_DEVICE_RENEWAL_DEVICES'
const FETCH_DEVICE_RENEWAL_DEVICES_SUCCESS = 'OVID/RENEWAL/FETCH_DEVICE_RENEWAL_DEVICES_SUCCESS'
const FETCH_DEVICE_RENEWAL_DEVICES_FAILED = 'OVID/RENEWAL/FETCH_DEVICE_RENEWAL_DEVICES_FAILED'
const RESET_DEVICE_RENEWAL_DEVICES = 'OVID/RENEWAL/RESET_DEVICE_RENEWAL_DEVICES'

const FETCH_DEVICE_SPECIFICATIONS = 'OVID/RENEWAL/DEVICE_OFFERS/FETCH_DEVICE_SPECIFICATIONS'
const FETCH_DEVICE_SPECIFICATIONS_SUCCESS =
  'OVID/RENEWAL/DEVICE_OFFERS/FETCH_DEVICE_SPECIFICATIONS_SUCCESS'
const FETCH_DEVICE_SPECIFICATIONS_FAILED =
  'OVID/RENEWAL/DEVICE_OFFERS/FETCH_DEVICE_SPECIFICATIONS_FAILED'

const FETCH_FINANCING_CONDITIONS = 'OVID/RENEWAL/DEVICE_FINANCING/FETCH_FINANCING_CONDITIONS'
const FETCH_FINANCING_CONDITIONS_SUCCESS =
  'OVID/RENEWAL/DEVICE_FINANCING/FETCH_FINANCING_CONDITIONS_SUCCESS'
const FETCH_FINANCING_CONDITIONS_FAILED =
  'OVID/RENEWAL/DEVICE_FINANCING/FETCH_FINANCING_CONDITIONS_FAILED'
const RESET_FINANCING_CONDITIONS = 'OVID/RENEWAL/DEVICE_FINANCING/RESET_FINANCING_CONDITIONS'

const GENERATE_ORDER = 'OVID/RENEWAL/GENERATE_ORDER'
const START_POLLING = 'OVID/RENEWAL/START_POLLING'
const RETRY_POLLING = 'OVID/RENEWAL/RETRY_POLLING'

const GENERATE_ORDER_SUCCESS = 'OVID/RENEWAL/GENERATE_ORDER_SUCCESS'
const GENERATE_ORDER_FAILED = 'OVID/RENEWAL/GENERATE_ORDER_FAILED'

const SET_DEVICE_RENEWAL_DATA = 'OVID/RENEWAL/SET_DEVICE_RENEWAL_DATA'

export const constants = {
  RETRIEVE_SUBSCRIPTION,
  FETCH_DEVICE_RENEWAL_ALLOWED,
  FETCH_DEVICE_RENEWAL_ALLOWED_SUCCESS,
  FETCH_DEVICE_RENEWAL_ALLOWED_FAILED,
  RESET_DEVICE_RENEWAL_ALLOWED,
  SET_DEVICE_RENEWAL_DATA,
  FETCH_DEVICE_RENEWAL_DEVICES,
  FETCH_DEVICE_RENEWAL_DEVICES_SUCCESS,
  FETCH_DEVICE_RENEWAL_DEVICES_FAILED,
  RESET_DEVICE_RENEWAL_DEVICES,
  FETCH_DEVICE_SPECIFICATIONS,
  FETCH_DEVICE_SPECIFICATIONS_SUCCESS,
  FETCH_DEVICE_SPECIFICATIONS_FAILED,
  FETCH_FINANCING_CONDITIONS,
  FETCH_FINANCING_CONDITIONS_SUCCESS,
  FETCH_FINANCING_CONDITIONS_FAILED,
  RESET_FINANCING_CONDITIONS,
  GENERATE_ORDER,
  START_POLLING,
  RETRY_POLLING,
  GENERATE_ORDER_SUCCESS,
  GENERATE_ORDER_FAILED,
}
