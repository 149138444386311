import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'

import { Typography, Icon } from '@material-ui/core'
import { TextStyles } from 'utils/text'
import { ICONS } from 'utils/icons'

import { Badge } from 'modules/ui'

export function RouterDetail({ router }) {
  return !isEmpty(router) ? (
    <>
      <div className="flex align-start-stretch p-x-24 p-y-8" data-hook="router-detail">
        {router.map(singleRouter => (
          <div key={`container-${singleRouter.serial_number}`} className="flex p-x-24">
            <Icon className="icon">{ICONS.router}</Icon>
            <Typography
              {...TextStyles.subtitle2Dark({
                className: 'p-x-24',
              })}>
              Dispositivo: Router
            </Typography>
            {!isEmpty(singleRouter.serial_number) && (
              <Badge
                key={`badge-${singleRouter.serial_number}`}
                lg
                value={{ label: 'Nº de serie', value: singleRouter.serial_number }}
              />
            )}
            {!isEmpty(singleRouter.permanency_contracts) &&
              singleRouter.permanency_contracts.map(permanency =>
                permanency.contract_length && permanency.penalty_fee ? (
                  <Badge
                    key={`${permanency.contract_length}-${permanency.penalty_fee}`}
                    lg
                    value={{
                      label: 'Permanencia',
                      value: `${permanency.contract_length} meses (${permanency.penalty_fee} €)`,
                    }}
                  />
                ) : (
                  ''
                ),
              )}
          </div>
        ))}
      </div>
    </>
  ) : (
    ''
  )
}

RouterDetail.propTypes = {
  router: PropTypes.arrayOf(PropTypes.object),
}
