import { axiosCustomer, axiosJWT } from 'modules/axios'
import { APP_CONFIG } from 'services/app-config'

const url = `${APP_CONFIG.apigee_host}/masmovil/v3/customers`
const urlLimits = `${APP_CONFIG.sales_cluster}/v1/customers`

export function findAllOrders(accountId) {
  return axiosCustomer({
    method: 'GET',
    headers: {
      'X-Customer-ID': accountId,
    },
    url: `${url}/${accountId}/orders`,
  }).then(resp => resp.data)
}

export function fetchCustomerOrderLimit(params) {
  return axiosJWT({
    method: 'GET',
    url: `${urlLimits}/order-limits`,
    params,
  }).then(resp => resp.data)
}

export function fetchCustomerDuoLimit(params) {
  return axiosJWT({
    method: 'GET',
    url: `${urlLimits}/duo-lines`,
    params,
  }).then(resp => resp.data)
}
