import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { get, isEmpty } from 'lodash'
import { getMsisdn } from 'services/subscriptions'

import { parseBonusId } from '../../helpers'
import { updateSubscription } from '../../actions'
import { getPendingDiscounts, getPendingBonuses } from '../../selectors'

import DiscountAdvice from '../DiscountAdvice'
import BonusAdvice from '../BonusAdvice'

export function DiscountsBonusesModified({ className, subscription }) {
  const confirmedDiscounts = useSelector(state =>
    getPendingDiscounts(state, get(subscription, 'id')),
  )
  const confirmedBonuses = useSelector(getPendingBonuses(get(subscription, 'id')))

  const dispatch = useDispatch()
  const onUpdateSubscription = data => dispatch(updateSubscription(getMsisdn(subscription), data))

  const noDiscountsOrBonuses = isEmpty(confirmedDiscounts) && isEmpty(confirmedBonuses)

  return !noDiscountsOrBonuses ? (
    <div className={className}>
      {confirmedDiscounts.map(discount => (
        <DiscountAdvice
          key={discount.discountCode}
          value={discount.value}
          unit={discount.unit}
          duration={discount.duration}
          onCancel={() =>
            onUpdateSubscription({
              confirmedDiscounts: confirmedDiscounts.filter(disc => disc !== discount),
              confirmedBonuses,
            })
          }
        />
      ))}

      {confirmedBonuses.map(bonus => (
        <BonusAdvice
          key={bonus.id}
          amount={bonus.attributes.mobile_data_amount_mb}
          {...parseBonusId(bonus.id)}
          onCancel={() =>
            onUpdateSubscription({
              confirmedDiscounts,
              confirmedBonuses: confirmedBonuses.filter(bons => bons !== bonus),
            })
          }
        />
      ))}
    </div>
  ) : null
}

DiscountsBonusesModified.propTypes = {
  className: PropTypes.string,
  subscription: PropTypes.object,
}
