import PropTypes from 'prop-types'

import { Box, Typography, Icon } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { TextStyles } from 'utils/index'
import { ICONS } from 'utils/icons'

const useStyles = makeStyles(theme => ({
  'app-launcher-search': {
    padding: '0px 10px',
    marginBottom: '15px',
    height: '10vh',

    '& .title': {
      margin: '5px 12px',
    },

    '& > div': {
      border: '2px solid',
      borderColor: theme.palette.global.gray_light,
      borderRadius: '8px',
      width: '336px',
      padding: '5px 10px',

      '& .icon': {
        color: theme.palette.global.gray_light,
        zIndex: 1,
      },

      '& input': {
        color: theme.palette.global.gray_light,
        backgroundColor: 'transparent',
        border: 'none',
        flex: 1,
        fontSize: '15px',
        padding: '0 10px',

        '&:focus': {
          outline: 0,
        },

        '&::placeholder': {
          color: theme.palette.global.gray_light,
        },
      },
    },
  },
}))

export const AppLauncherSearch = ({ setFilteredApps, externalAppsLinks }) => {
  const classes = useStyles()

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      className={classes['app-launcher-search']}>
      <Typography
        {...TextStyles.title1Light({
          className: 'title p-y-12',
        })}>
        Menú
      </Typography>

      <Box display="flex" alignItems="center" className="p-10">
        <Icon data-hook="search-icon" className="icon">
          {ICONS.search}
        </Icon>

        <input
          name="app-search"
          placeholder="Nombre de la herramienta"
          type="text"
          maxLength="30"
          onChange={e =>
            setFilteredApps(
              externalAppsLinks.filter(app =>
                app.title.toLowerCase().includes(e.target.value.toLowerCase()),
              ),
            )
          }
          data-hook="input-document-type"
        />
      </Box>
    </Box>
  )
}

AppLauncherSearch.propTypes = {
  setFilteredApps: PropTypes.func.isRequired,
  externalAppsLinks: PropTypes.array.isRequired,
}
