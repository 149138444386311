import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { join, isEmpty } from 'lodash'

import { selectAccountId } from 'modules/CustomerInfo/store/customerInfo.selectors'
import { helpers as helpers360, selectors as selectors360 } from 'services/customer-360'
import { actions as customerSvaActions } from 'services/customer-sva/actions'

import { selectFetchCutomerSvasFlag } from 'services/feature-flag/selectors'

export const useFetchCustomerSvas = () => {
  const dispatch = useDispatch()

  const canFetchCustomerSvas = useSelector(selectFetchCutomerSvasFlag)

  const contractsCustomer = useSelector(selectors360.contracts)

  const allLinesCustomer = useMemo(
    () => helpers360.getAllLinesAndElFijoMsisdn(contractsCustomer),
    [contractsCustomer],
  )

  const msisdnsStr = useMemo(() => join(allLinesCustomer, ','), [allLinesCustomer])

  const accountId = useSelector(selectAccountId)

  const fetchCustomerSvas = useCallback(() => {
    if (canFetchCustomerSvas || !isEmpty(msisdnsStr)) {
      dispatch(
        customerSvaActions.fetchCustomerSvasInit(
          accountId,
          !canFetchCustomerSvas ? msisdnsStr : null,
        ),
      )
    }
  }, [accountId, msisdnsStr])

  return {
    fetchCustomerSvas,
  }
}
