import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'

import { Typography } from '@material-ui/core'
import { TextStyles } from 'utils/text'

import { Badge } from 'modules/ui'
// import { PopoverInfo } from '../PopoverInfo'
// import SubscriptionStatusBadge from '../../../../components/packages/ui/subscription-status-badge/SubscriptionStatusBadge'

const iconSvg = {
  fixed: '/assets/fixed_phone.svg',
  mobile: '/assets/sim_filled.svg',
  internet: '/assets/fixed_internet_filled.svg',
}

export function SimDetail({ sim }) {
  return !isEmpty(sim) ? (
    <>
      <div className="flex align-start-stretch p-x-24 p-y-8" data-hook="sim-detail">
        {sim.map(simItem => (
          <div key={simItem.id} className="flex p-x-24 align-start-center">
            <img src={iconSvg.mobile} alt="mobile" />
            <Typography
              {...TextStyles.subtitle2Dark({
                className: 'p-x-24',
              })}>
              {simItem.mobile_number}
            </Typography>
            {simItem.state_desc && (
              <>
                {/* <SubscriptionStatusBadge status={simItem.state_desc} variant="small" /> */}
                {/* <PopoverInfo info={simItem.state_desc} /> */}
              </>
            )}

            {simItem.donor_operator_name && (
              <>
                <Badge lg value={{ label: 'Portabilidad' }} />
                <Badge
                  lg
                  value={{
                    label: 'Operador donante',
                    value: simItem.donor_operator_name,
                  }}
                />
              </>
            )}
            {simItem.icc && <Badge lg value={{ label: 'ICC', value: simItem.icc }} />}
            {!isEmpty(simItem.permanency_contracts) &&
              simItem.permanency_contracts.map(permanency => (
                <Badge
                  lg
                  value={{
                    label: 'Permanencia',
                    value: `${permanency.contract_length} meses (${permanency.penalty_fee} €)`,
                  }}
                />
              ))}
          </div>
        ))}
      </div>
    </>
  ) : (
    ''
  )
}

SimDetail.propTypes = {
  sim: PropTypes.arrayOf(PropTypes.object),
}
