import { get, reduce, isNil, trim } from 'lodash'

import { flags as flagsFromFile } from './flags'
import { FLAG_TYPE } from './constants'

export const getFlagsFromParam = param => {
  if (isNil(param)) {
    return {}
  }
  return reduce(
    param.split(','),
    (flags, val) => Object.assign({}, { [trim(val)]: true }, flags),
    {},
  )
}

export const getUrlSearchParam = (search, param) => new URLSearchParams(search).getAll(param)[0]

export const mapFlagsToBool = (flags, sfid) =>
  flags.reduce((flagItems, flag) => {
    let flagStatus = false

    if (flag.type === FLAG_TYPE.BOOLEAN) {
      flagStatus = flag.value === 'true'
    }

    if (flag.type === FLAG_TYPE.SFID_REGEXP) {
      flagStatus = new RegExp(flag.value).test(sfid)
    }

    return {
      ...flagItems,
      [flag.name]: flagStatus,
    }
  }, {})

export const getFeatureFlags = (storeFlags, router, sfid) => {
  const search = get(router, 'location.search', '')

  // Flags on the url
  const nextFeatFlagVals = new URLSearchParams(search).getAll('featureFlag')[0]
  const flagsFromURL = getFlagsFromParam(nextFeatFlagVals)

  return {
    featureFlags: {
      ...mapFlagsToBool(flagsFromFile, sfid),
      ...storeFlags,
      ...flagsFromURL,
    },
  }
}

export const keysFromHyphenToUnderscore = res =>
  Object.keys(res).reduce((result, key) => {
    /* eslint-disable no-param-reassign */
    if (key.indexOf('-') > -1) {
      result[key.replace(/-/g, '_')] = res[key]
    } else {
      result[key] = res[key]
    }
    return result
  }, {})
