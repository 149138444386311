export const customerOrderActionTypes = {
  FIND_ALL_ORDERS: 'OVID/CUSTOMER/FIND_ALL_ORDERS',
  FIND_ALL_ORDERS_SUCCESS: 'OVID/CUSTOMER/FIND_ALL_ORDERS_SUCCESS',
  FIND_ALL_ORDERS_FAILED: 'OVID/CUSTOMER/FIND_ALL_ORDERS_FAILED',
  FETCH_CUSTOMER_ORDER_LIMIT: 'OVID/CUSTOMER/FETCH_CUSTOMER_ORDER_LIMIT',
  FETCH_CUSTOMER_ORDER_LIMIT_SUCCESS: 'OVID/CUSTOMER/FETCH_CUSTOMER_ORDER_LIMIT_SUCCESS',
  FETCH_CUSTOMER_ORDER_LIMIT_ERROR: 'OVID/CUSTOMER/FETCH_CUSTOMER_ORDER_LIMIT_ERROR',
  RESET_CUSTOMER_ORDER_LIMIT_LOADING: 'OVID/CUSTOMER/RESET_CUSTOMER_ORDER_LIMIT_LOADING',
  FETCH_CUSTOMER_DUO_LIMIT: 'OVID/CUSTOMER/FETCH_CUSTOMER_DUO_LIMIT',
  FETCH_CUSTOMER_DUO_LIMIT_SUCCESS: 'OVID/CUSTOMER/FETCH_CUSTOMER_DUO_LIMIT_SUCCESS',
  FETCH_CUSTOMER_DUO_LIMIT_ERROR: 'OVID/CUSTOMER/FETCH_CUSTOMER_DUO_LIMIT_ERROR',
  RESET_CUSTOMER_ORDER_LIMIT: 'OVID/CUSTOMER/RESET_CUSTOMER_ORDER_LIMIT',
}

export const findAllOrders = payload => ({
  type: customerOrderActionTypes.FIND_ALL_ORDERS,
  payload,
})

export const findAllOrdersSuccess = orders => ({
  type: customerOrderActionTypes.FIND_ALL_ORDERS_SUCCESS,
  payload: {
    orders,
  },
})

export const findAllOrdersError = error => ({
  type: customerOrderActionTypes.FIND_ALL_ORDERS_FAILED,
  payload: {
    error,
  },
})

export const fetchCustomerOrderLimit = payload => ({
  type: customerOrderActionTypes.FETCH_CUSTOMER_ORDER_LIMIT,
  payload,
})

export const fetchCustomerOrderLimitSuccess = payload => ({
  type: customerOrderActionTypes.FETCH_CUSTOMER_ORDER_LIMIT_SUCCESS,
  payload,
})

export const fetchCustomerOrderLimitError = payload => ({
  type: customerOrderActionTypes.FETCH_CUSTOMER_ORDER_LIMIT_ERROR,
  payload,
})

export const fetchCustomerDuoLimitAction = payload => ({
  type: customerOrderActionTypes.FETCH_CUSTOMER_DUO_LIMIT,
  payload,
})

export const fetchCustomerDuoLimitSuccess = payload => ({
  type: customerOrderActionTypes.FETCH_CUSTOMER_DUO_LIMIT_SUCCESS,
  payload,
})

export const fetchCustomerDuoLimitError = payload => ({
  type: customerOrderActionTypes.FETCH_CUSTOMER_DUO_LIMIT_ERROR,
  payload,
})

export const resetCustomerOrderLimitLoading = () => ({
  type: customerOrderActionTypes.RESET_CUSTOMER_ORDER_LIMIT_LOADING,
})
export const resetCustomerOrderLimit = () => ({
  type: customerOrderActionTypes.RESET_CUSTOMER_ORDER_LIMIT,
})
