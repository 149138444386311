import PropTypes from 'prop-types'

import { isNil } from 'lodash'

import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import { getLighten } from 'utils'

import { getCssArrowMixin } from './arrow.helper'

import SubscriptionStatusBadge from '../subscription-status-badge/SubscriptionStatusBadge'

const iconSvg = {
  fixed: '/assets/fixed_internet_filled.svg',
  mobile: '/assets/sim_filled.svg',
  mobile_fixed_line: '/assets/fixed_internet_filled.svg',
}

const ITEM_HEIGHT = 40

const useStyles = makeStyles(theme => ({
  'product-line': {
    height: `${ITEM_HEIGHT}px`,
    cursor: 'pointer',
    backgroundColor: ({ isSelected }) => isSelected && getLighten(theme.palette.brand.medium, 0.3),
    boxShadow: ({ isSelected }) => isSelected && '0 2px 10px 0 rgba(0, 0, 0, 0.2)',
    zIndex: ({ isSelected }) => isSelected && 1,
    padding: ({ isMultisim }) => (isMultisim ? '0 48px 0 79px' : '0 48px'),

    '& span img': {
      filter: 'brightness(100)',
      marginRight: '8px',
      width: '25px',
    },

    '&:hover': {
      backgroundColor: ({ isSelected }) =>
        !isSelected && getLighten(theme.palette.brand.medium, 0.1),
    },

    '&::after': {
      ...getCssArrowMixin(ITEM_HEIGHT, getLighten(theme.palette.brand.medium, 0.3)),
      display: ({ isSelected }) => !isSelected && 'none',
    },
  },
  'product-icon': {
    width: ({ isMultisim }) => isMultisim && '20px !important',
  },
}))
function ProductLine({
  lineItem: { id, line, stringStatus, type, orderId },
  selectedLine,
  selectedOrder,
  onClick,
  isMultisim,
}) {
  const isOnFly = !isNil(orderId)
  const isSelectedOrder = orderId === selectedOrder
  const isSelectedLine = id === selectedLine

  const isSelected = isSelectedLine || (isOnFly && isSelectedOrder)

  const goTo = {
    id: isOnFly ? orderId : id,
    type: isOnFly ? 'order' : 'line',
  }

  const classes = useStyles({ isSelected, isOnFly, isMultisim })

  return (
    <Box
      component="li"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      position="relative"
      data-hook={`${type}-${stringStatus}-${line}`}
      data-selected={isSelected}
      className={classes['product-line']}
      onClick={() => onClick(goTo)}>
      <Box component="span" display="flex" alignItems="center">
        <img alt={type} src={iconSvg[type]} className={classes['product-icon']} />
        <Typography variant="body1" className="light">
          {line}
        </Typography>
      </Box>
      {stringStatus && <SubscriptionStatusBadge status={stringStatus} variant="small" />}
    </Box>
  )
}

ProductLine.propTypes = {
  lineItem: PropTypes.shape({
    id: PropTypes.string.isRequired,
    line: PropTypes.string.isRequired,
    stringStatus: PropTypes.oneOf(['active', 'fly', 'incidence', 'cancelled']).isRequired,
    type: PropTypes.oneOf(['fixed', 'mobile', 'mobile_fixed_line']).isRequired,
    orderId: PropTypes.string,
  }).isRequired,
  orderId: PropTypes.string,
  selectedLine: PropTypes.string,
  selectedOrder: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  isMultisim: PropTypes.bool,
}

export default ProductLine
