import { Typography } from '@material-ui/core'
import { TextStyles } from 'utils/text'

import { Card } from 'modules/ui'

export function EmptyActivity() {
  return (
    <Card title="Registro interacciones" withShadow>
      <div className="p-l-24 m-y-12">
        <Typography {...TextStyles.subtitle2Dark()}>
          No existen interacciones para este cliente
        </Typography>
      </div>
    </Card>
  )
}

export function ErrorActivity() {
  return (
    <Card title="Registro interacciones" withShadow>
      <div className="p-l-24 m-y-12">
        <Typography {...TextStyles.subtitle2Dark()}>Se ha producido un error</Typography>
      </div>
    </Card>
  )
}
