import { useState } from 'react'
import PropTypes from 'prop-types'
import { mapValues, isEmpty } from 'lodash'
import { useSelector } from 'react-redux'
import { Checkbox, FormControlLabel, Box, Divider } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { ScrollableContent, Button, ModalActions } from 'components/ui'

import { selectSubscriptionByMsisdn } from 'services/subscriptions'
import { POSTPAID } from 'modules/tariffs/tariffs.constants'
import { ModalContent } from './ModalContent'
import { getLegalText, getSubscriptionsState } from '../selectors'

import {
  DISCOUNT_OR_BONUS_OPERATION,
  TARIFF_OPERATION,
  PREPAID_TO_POSPAID_OPERATION_ES,
  PREPAID_TO_POSPAID_OPERATION_EN,
  PREPAID_TO_POSPAID_OPERATION_CAT,
  UNSUBSCRIBE_OPERATION,
} from '../constants'

const hasDiscountOrBonusModification = ({ confirmedDiscounts = [], confirmedBonuses = [] }) =>
  [].concat(confirmedDiscounts, confirmedBonuses).length > 0

const hasTariffModification = ({ confirmedTariff }) => Boolean(confirmedTariff)

const hasPrepaidToPospaidModification = ({ confirmedTariff }, msisdn) => {
  const currentTariff = useSelector(selectSubscriptionByMsisdn(msisdn))

  const isCurrentTarrifPrepaid = !currentTariff.is_postpaid
  const isConfirmedTariffPostpaid = confirmedTariff?.payment_type === POSTPAID

  return isCurrentTarrifPrepaid && isConfirmedTariffPostpaid
}

const hasUnsubscribedModification = ({ fixedUnsubscribed, mobileUnsubscribed }) =>
  fixedUnsubscribed || mobileUnsubscribed

const getLegalOperations = modifications => {
  const operations = new Set()

  mapValues(modifications, (mod, msisdn) => {
    if (hasDiscountOrBonusModification(mod)) {
      operations.add(DISCOUNT_OR_BONUS_OPERATION)
    }

    if (hasTariffModification(mod)) {
      operations.add(TARIFF_OPERATION)
    }

    if (hasUnsubscribedModification(mod)) {
      operations.add(UNSUBSCRIBE_OPERATION)
    }

    if (hasPrepaidToPospaidModification(mod, msisdn)) {
      operations.add(PREPAID_TO_POSPAID_OPERATION_ES)
      operations.add(PREPAID_TO_POSPAID_OPERATION_EN)
      operations.add(PREPAID_TO_POSPAID_OPERATION_CAT)
    }
  })

  return operations
}

const useStyles = makeStyles({
  scrollableContainer: {
    height: '40vh',
    'line-height': '1.6rem',
  },
})

const LegalTextModal = ({ hideLegal, summaryFeesText, onConfirm, onClose }) => {
  const styles = useStyles()

  const modifications = useSelector(getSubscriptionsState)
  const operations = getLegalOperations(modifications)

  const legalText = useSelector(state => getLegalText(state, operations)) || ''

  const [selectedSendSms, setSendSms] = useState(false)

  const handleOnChangeSmsCheckbox = () => {
    setSendSms(!selectedSendSms)
  }

  return (
    <ModalContent data-hook="legal-text-modal">
      {!hideLegal && (
        <>
          <ScrollableContent className={styles.scrollableContainer}>
            {legalText.split(/\n/).map((p, i) => (
              /* eslint-disable react/no-array-index-key */
              <p key={i + p}>{p}</p>
            ))}
          </ScrollableContent>
          <Box mt="26px" mb="33px">
            <Divider />
          </Box>
        </>
      )}

      {!isEmpty(summaryFeesText) && (
        <>
          <p>
            <strong>{`${summaryFeesText}`}</strong>
          </p>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                className="checkbox"
                checked={selectedSendSms}
                onChange={() => handleOnChangeSmsCheckbox()}
              />
            }
            label="Enviar SMS al cliente"
          />
        </>
      )}

      <ModalActions>
        <Button onClick={() => onConfirm(selectedSendSms)}>Aceptar y Confirmar</Button>
        <Button secondary onClick={onClose}>
          Cancelar
        </Button>
      </ModalActions>
    </ModalContent>
  )
}

LegalTextModal.propTypes = {
  hideLegal: PropTypes.bool,
  summaryFeesText: PropTypes.array,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default LegalTextModal
