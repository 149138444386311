import { select } from 'redux-saga/effects'
import { selectTariffApigeeConvergentTariffsIds } from 'modules/tariffs/store-apigee'
import { getProductsOrdersCustomer } from 'modules/orders'
import { isValidNewDirection } from 'modules/Coverage'

export const validators = {
  validCoverage: {
    type: 'syncValidator',
    *validator() {
      const availableTariffs = yield select(selectTariffApigeeConvergentTariffsIds)
      const orderProducts = yield select(getProductsOrdersCustomer)
      const isValidTariff = orderProducts.some(({ type }) => availableTariffs.includes(type))
      const checkFtthNewDirection = yield select(isValidNewDirection)

      return isValidTariff === true && checkFtthNewDirection === true
    },
  },
  // differentGescal17: {
  //   type: 'syncValidator',
  //   *validator() {
  //     yield console.warn('differentGescal17')
  //     const gescalOlderDirection = yield select(getGescal17Order)
  //     const gescalNewDirection = yield select(getGescal17)
  //     return gescalNewDirection === gescalOlderDirection
  //   },
  // },
}
