import { useState, useEffect } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { concat, get, head, isEmpty, remove, uniqBy } from 'lodash'
import { LineItemCard } from 'components/ui'
import { formatFee, roundTwoDecimals } from 'utils'
import { Checkbox, TextField, InputAdornment, FormHelperText, Icon } from '@material-ui/core'
import { ICONS } from 'utils/icons'
import { getGroupNameFromRow, getRowObj } from 'modules/credit'
import { applyTax } from 'services/taxes'

export const CreditSingleRow = styled(
  ({
    className,
    currentRow,
    selectedRows,
    setSelectedRows,
    isTotalSubInvoice,
    setIsTotalSubInvoice,
    setSingleRowError,
    tax,
  }) => {
    const isSelectedRow = !isEmpty(
      selectedRows.filter(r => get(r, 'invoice-row') === get(currentRow, 'id')),
    )
    const selectedRowCustomAmount = () => {
      const selectedRow = selectedRows.filter(r => get(r, 'invoice-row') === get(currentRow, 'id'))
      return get(head(selectedRow), 'amountWithoutVat', 0)
    }
    const currentRowAmount = parseFloat(get(currentRow, 'totalWithoutVat'))
    const defaultAmount = isSelectedRow ? selectedRowCustomAmount() : 0
    const [customRowAmount, setCustomRowAmount] = useState(defaultAmount)
    const rowWithNegativeAmount = currentRowAmount < 0
    const maxAmountReached =
      (!rowWithNegativeAmount &&
        parseFloat(customRowAmount.toString().replace(',', '.')) > currentRowAmount) ||
      (rowWithNegativeAmount && parseFloat(customRowAmount.toString().replace(',', '.')) > 0)
    const minAmountReached =
      (rowWithNegativeAmount &&
        parseFloat(customRowAmount.toString().replace(',', '.')) < currentRowAmount) ||
      (!rowWithNegativeAmount && parseFloat(customRowAmount.toString().replace(',', '.')) < 0)
    const hasErrors = maxAmountReached || minAmountReached
    const amountRange = rowWithNegativeAmount
      ? `${formatFee(currentRowAmount.toString().replace('.', ','))} - 0 €`
      : `0 € - ${formatFee(currentRowAmount.toString().replace('.', ','))}`

    const addRow = row => {
      if (isTotalSubInvoice) {
        setIsTotalSubInvoice(false)
      }
      selectedRowCustomAmount()
      return setSelectedRows(uniqBy(concat(selectedRows, row)), 'invoice-row')
    }

    const removeRow = row => {
      setCustomRowAmount(0)

      return setSelectedRows(
        remove(
          selectedRows,
          selectedRow => get(selectedRow, 'invoice-row') !== get(row, 'invoice-row'),
        ),
      )
    }

    const handleOnClick = row => {
      if (isSelectedRow) {
        setSingleRowError(false)
        removeRow(getRowObj(row))
      } else {
        addRow(getRowObj(row))
      }
    }

    const updateCurrentRowAmount = updatedRow => {
      setSelectedRows(
        uniqBy(
          selectedRows.map(r => {
            if (get(r, 'invoice-row') === get(updatedRow, 'invoice-row')) {
              return updatedRow
            }
            return r
          }),
        ),
        'invoice-row',
      )
    }

    const getMaxLength = () => {
      if (currentRowAmount.toString().includes('.')) {
        return currentRowAmount.toString().length + 1
      }
      return currentRowAmount.toString().length + 3
    }

    useEffect(() => {
      const rowWithCustomAmount = getRowObj({
        ...currentRow,
        totalWithoutVat: parseFloat(customRowAmount.toString().replace(',', '.')),
        total: roundTwoDecimals(
          applyTax(tax, parseFloat(customRowAmount.toString().replace(',', '.'))),
        ),
      })
      if (customRowAmount && !hasErrors) {
        setSingleRowError(false)
        if (!isSelectedRow) {
          addRow(rowWithCustomAmount)
        } else {
          updateCurrentRowAmount(rowWithCustomAmount)
        }
      }
      if (hasErrors) {
        setSingleRowError(true)
      }
    }, [customRowAmount])

    return (
      <div className={`${className} flex align-start-center`}>
        <Checkbox
          color="primary"
          className="checkbox-component"
          checked={isSelectedRow}
          disabled={isTotalSubInvoice}
          onChange={() => handleOnClick(currentRow)}
          name={`row-${get(currentRow, 'id')}`}
          id={`row-${get(currentRow, 'id')}`}
        />

        <LineItemCard
          className="text-capitalized row-line"
          primaryText={{
            children: getGroupNameFromRow(currentRow),
            ...(currentRow.diffTotal > 0
              ? {
                  variant: 'subtitle2',
                  color: 'error',
                  style: { fontWeight: 'normal' },
                }
              : {}),
          }}
          secondaryText={formatFee(currentRowAmount)}
          fieldsApart
        />

        <div>
          <TextField
            className="amount-input"
            label="Importe"
            name="row-amount"
            onChange={e =>
              setCustomRowAmount(e.target.value.replace(/[^0-9,.-]/g, '').replace('.', ','))
            }
            InputProps={{
              endAdornment: (
                <InputAdornment className="icon-container" position="end">
                  <Icon className="icon euro-icon">{ICONS.euro}</Icon>
                </InputAdornment>
              ),
            }}
            inputProps={{ maxLength: getMaxLength() }}
            value={customRowAmount ? customRowAmount.toString().replace('.', ',') : ''}
            error={hasErrors}
            variant="outlined"
            disabled={isTotalSubInvoice || !isSelectedRow}
          />
          {hasErrors ? (
            <FormHelperText className="amount-error-msg">
              Importe erróneo ({amountRange})
            </FormHelperText>
          ) : null}
        </div>
      </div>
    )
  },
)`
  background-color: #f5f5f5;
  padding: 4px 12px;
  .checkbox-component {
    padding: 8px 12px;
  }
  .text-capitalized > div > .primary-text {
    text-transform: capitalize;
  }
  .amount-input {
    background: ${props => get(props, 'theme.palette.brand.light')};
    width: 70%;
  }
  .amount-input input {
    font-size: 15px;
    height: 15px;
  }
  .amount-input label {
    font-size: 15px;
  }
  .amount-error-msg {
    padding: 4px 0;
    color: ${props => get(props, 'theme.palette.brand.alert')};
  }
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
  .secondary-text {
    white-space: nowrap;
  }
`

CreditSingleRow.propTypes = {
  className: PropTypes.string,
  applyCreditNote: PropTypes.func,
  creditClearError: PropTypes.func,
  creditErrors: PropTypes.object,
  isCreditLoading: PropTypes.bool,
  isOpen: PropTypes.bool,
  onCloseModal: PropTypes.func,
}
