import { get, set, clone, union, isString, chain, isNull, isEmpty } from 'lodash'
import { getProvinceFromCodeOrString } from 'utils'

export const formatAddress = coverageData => {
  if (!coverageData) {
    return ''
  }
  const { streetType, street, number, floor, block, zipCode, town, province, Hand } = coverageData
  return String(
    `${streetType} ${street} ${number} ${floor !== '' ? floor : ''} ${
      Hand !== '' ? Hand : ''
    } ${block} ${zipCode}, ${town} (${province})`,
  )
}
export const formatAddressInfo = coverageData => {
  if (!coverageData) {
    return '-'
  }
  const {
    streetType,
    nameAddress,
    numberAddress,
    floorAddress,
    handAddress,
    getBlockAddress,
    postalCode,
    state,
    province,
  } = coverageData
  return String(
    `${streetType} ${nameAddress} ${numberAddress} 
    ${!isEmpty(floorAddress) ? floorAddress : ''} 
    ${!isEmpty(handAddress) ? handAddress : ''} 
    ${getBlockAddress} ${postalCode}, ${state} (${province})`,
  )
}
export const trimObjExceptGescal = obj => {
  if (!Array.isArray(obj) && typeof obj !== 'object') return obj
  return Object.keys(obj).reduce(
    (acc, key) => {
      if (isNull(obj[key])) {
        acc[key.trim()] = null
      } else {
        acc[key.trim()] =
          typeof obj[key] === 'string' && key !== 'gescal'
            ? obj[key].trim()
            : trimObjExceptGescal(obj[key])
      }
      return acc
    },
    Array.isArray(obj) ? [] : {},
  )
}
export const addAddresData = address => {
  const trimmedAddress = trimObjExceptGescal(address)
  const territoryOwnerTrimmedAddress =
    chain(trimmedAddress)
      .get('coverage')
      .head()
      .get('characteristics')
      .find({ name: 'territoryOwner' })
      .get('value')
      .value() || ''

  const addressId =
    chain(address)
      .get('coverage')
      .head()
      .get('characteristics')
      .find({ name: 'addressId' })
      .get('value')
      .value() || ''

  return {
    // Unncessary fields: city, streetOne, streetTwo, country, postCode
    city: get(trimmedAddress, 'town'),
    streetOne: get(trimmedAddress, 'street'),
    streetTwo: get(trimmedAddress, 'streetTwo'),
    country: get(trimmedAddress, 'country'),
    postCode: get(trimmedAddress, 'zipCode'),
    phoneNumber: get(trimmedAddress, 'phoneNumber'),
    province: get(trimmedAddress, 'province'),
    town: get(trimmedAddress, 'town'),
    streetType: get(trimmedAddress, 'streetType'),
    street: get(trimmedAddress, 'street'),
    number: get(trimmedAddress, 'number'),
    zipCode: get(trimmedAddress, 'zipCode'),
    floor: get(trimmedAddress, 'floor'),
    door: get(trimmedAddress, 'door'),
    stair: get(trimmedAddress, 'stair'),
    letter: get(trimmedAddress, 'letter'),
    bis_duplicate: get(trimmedAddress, 'bis_duplicate'),
    km: get(trimmedAddress, 'Km'),
    block: get(trimmedAddress, 'block'),
    hand: get(trimmedAddress, 'Hand'),
    territoryOwner: territoryOwnerTrimmedAddress,
    addressId,
    characteristic: Object.keys(trimmedAddress)
      .map(item =>
        /* eslint-disable indent */
        trimmedAddress[item] !== ''
          ? {
              name: item,
              value:
                isString(trimmedAddress[item]) && item !== 'gescal'
                  ? trimmedAddress[item].trim()
                  : trimmedAddress[item],
            }
          : false,
      )
      .filter(obj => obj),
  }
}
export const formatOrderWithNewDirection = (order, covDir) => {
  const newDir = addAddresData(covDir)
  const contactMedium = get(order, 'customer.contactMedium', [])
  const indexFixedLine = contactMedium.findIndex(x => x.type === 'InstallationAddressFixedLine')
  return set(order, `customer.contactMedium[${indexFixedLine}].medium`, newDir)
}
export const formatOrderWithoutNewDirection = (
  order,
  territoryOwnerCoverageApi,
  addressIdCoverageApi,
) => {
  const contactMedium = get(order, 'customer.contactMedium', [])
  const indexFixedLine = contactMedium.findIndex(x => x.type === 'InstallationAddressFixedLine')
  const newDir = addAddresData(order.customer.contactMedium[indexFixedLine].medium)
  const mediumFixedLine = contactMedium[indexFixedLine].medium
  const indexFixedStreetType = mediumFixedLine.characteristic.findIndex(
    x => x.name === 'streetType',
  )
  const indexFixedStreetNumber = mediumFixedLine.characteristic.findIndex(
    x => x.name === 'streetNumber',
  )
  const indexFixedProvince = mediumFixedLine.characteristic.findIndex(x => x.name === 'province')

  const final = {
    ...newDir,
    ...mediumFixedLine,
    ...{
      zipCode: mediumFixedLine.postCode,
      town: mediumFixedLine.city,
      province: mediumFixedLine.characteristic[indexFixedProvince].value,
      country: mediumFixedLine.country,
      streetType: mediumFixedLine.characteristic[indexFixedStreetType].value,
      street: mediumFixedLine.streetOne,
      number: mediumFixedLine.characteristic[indexFixedStreetNumber].value,
      /* 'streetTwo' contains the extra info address(bis, stair, floor, ...)
      which comes from the bus when the address just in case of WithoutNewDirection flow */
      bis_duplicate: mediumFixedLine.streetTwo,
      territoryOwner: territoryOwnerCoverageApi,
      addressId: addressIdCoverageApi,
    },
  }
  return set(order, `customer.contactMedium[${indexFixedLine}].medium`, final)
}

export const replaceCustomerToCustomerNew = (dataOrder, customerNew) => {
  const contactMedium = get(customerNew, 'contactMedium', [])

  // Add province literal at object billingAddress
  const provinceCode = chain(contactMedium)
    .find({ type: 'BillingAddress' })
    .get('medium.stateOrProvince')
    .value()
  const mapProvinceToString = getProvinceFromCodeOrString(provinceCode)
  set(contactMedium, '[1].medium.province', mapProvinceToString)

  return {
    ...dataOrder,
    customer: {
      ...dataOrder.customer,
      firstName: get(customerNew, 'firstName', ''),
      midName: get(customerNew, 'midName', ''),
      lastName: get(customerNew, 'lastName', ''),
      individualIdentification: clone(customerNew.individualIdentification, true),
      contactMedium: union(
        contactMedium,
        dataOrder.customer.contactMedium.filter(
          el =>
            el.type === 'CurrentInstallationAddress' || el.type === 'InstallationAddressFixedLine',
        ),
      ),
    },
  }
}

export const modelSubscriptionProduct = (productOrder, subs) => {
  const x = {
    ...productOrder,
    products: [
      {
        id: get(subs, 'id', ''),
        type: get(subs, 'type', ''),
        description: get(subs, 'description', false),
        productNumber: get(subs, 'productNumber', ''),
        productsSpecifications: get(subs, 'productsSpecifications', []),
        category: get(subs, 'category', ''),
        subscriptionId: get(subs, 'subscription_id', ''),
      },
    ],
  }
  return x
}

export const hasAdslCustomer = data => {
  const technology = chain(data)
    .get('products')
    .head()
    .get('productsSpecifications')
    .find({ name: 'fixed' })
    .get('productSpecCharacteristic')
    .find({ name: 'connection-type' })
    .get('value')
    .value()
  return technology === 'adsl'
}
