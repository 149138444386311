import { flatMap, get, isEmpty, groupBy } from 'lodash'

export const BarringGroups = {
  customer: {
    id: 'customer',
    name: 'Restricciones de Cliente',
  },
  atc: {
    id: 'atc',
    name: 'Restricciones de ATC',
  },
  debt: {
    id: 'debt',
    name: 'Restricciones de Deuda',
  },
  product: {
    id: 'product',
    name: 'Restricciones de Producto',
  },
  limit: {
    id: 'limit',
    name: 'Restricciones del Límite',
  },
  risk: {
    id: 'risk',
    name: 'Restricciones de Riesgo',
  },
}

export const DISABLED_BARRING_GROUPS = [
  BarringGroups.debt.id,
  BarringGroups.risk.id,
  BarringGroups.limit.id,
]

export const BarringInfos = {
  // Customer Group

  Cust_Roaming_off: {
    id: 'Cust_Roaming_off',
    group: BarringGroups.customer.id,
    name: 'Roaming',
    description: 'Cust_Roaming_off',
  },

  Cust_International_off: {
    id: 'Cust_International_off',
    group: BarringGroups.customer.id,
    name: 'Roaming Europa',
    description: 'Cust_International_off',
  },

  Cust_TotalPremium_off: {
    id: 'Cust_TotalPremium_off',
    group: BarringGroups.customer.id,
    name: 'Premium total',
    description: 'Cust_TotalPremium_off',
  },

  Cust_LOST: {
    id: 'Cust_LOST',
    group: BarringGroups.customer.id,
    name: 'Robo o pérdida',
    description: 'Cust_LOST',
  },

  Internet: {
    id: 'Internet',
    group: BarringGroups.customer.id,
    name: 'Internet',
    description: 'Internet',
  },

  C_BRAIC: {
    id: 'C_BRAIC',
    group: BarringGroups.customer.id,
    name: 'Roaming Internacional',
    description: 'C_BRAIC',
  },

  C_BRIAP: {
    id: 'C_BRIAP',
    group: BarringGroups.customer.id,
    name: 'Roaming + Internacional + Premium total',
    description: 'C_BRIAP',
  },

  C_BPSUB: {
    id: 'C_BPSUB',
    group: BarringGroups.customer.id,
    name: 'Premium',
    description: 'C_BPSUB',
  },

  // ATC Group
  ATC_Roaming_off: {
    id: 'ATC_Roaming_off',
    group: BarringGroups.atc.id,
    name: 'Roaming',
    description: 'ATC_Roaming_off',
  },

  ATC_International_off: {
    id: 'ATC_International_off',
    group: BarringGroups.atc.id,
    name: 'Internacional',
    description: 'ATC_International_off',
  },

  ATC_TotalPremium_Off: {
    id: 'ATC_TotalPremium_Off',
    group: BarringGroups.atc.id,
    name: 'Premium Total',
    description: 'ATC_TotalPremium_Off',
  },

  ATC_Service_Suspension: {
    id: 'ATC_Service_Suspension',
    group: BarringGroups.atc.id,
    name: 'Suspensión temporal',
    description: 'ATC_Service_Suspension',
  },

  // Debt Group
  Debt_Restricted: {
    id: 'Debt_Restricted',
    group: BarringGroups.debt.id,
    name: 'Restringir deuda',
    description: 'Debt_Restricted',
  },

  Debt_Hotl: {
    id: 'Debt_Hotl',
    group: BarringGroups.debt.id,
    name: 'Redirección de cobros (Llamadas)',
    description: 'Debt_Hotl',
  },

  Debt_LP: {
    id: 'Debt_LP',
    group: BarringGroups.debt.id,
    name: 'Redirección de cobros (Internet)',
    description: 'Debt_LP',
  },

  Debt_HOTLP: {
    id: 'Debt_HOTLP',
    group: BarringGroups.debt.id,
    name: 'Redirección de cobros (Total)',
    description: 'Debt_HOTLP',
  },

  // Limit Group
  Limits_Roaming_off: {
    id: 'Limits_Roaming_off',
    group: BarringGroups.limit.id,
    name: 'Roaming',
    description: 'Limits_Roaming_off',
  },

  Limits_International_off: {
    id: 'Limits_International_off',
    group: BarringGroups.limit.id,
    name: 'Internacional',
    description: 'Limits_International_off',
  },

  Limits_TotalPremium_Off: {
    id: 'Limits_TotalPremium_Off',
    group: BarringGroups.limit.id,
    name: 'Premium Total',
    description: 'Limits_TotalPremium_Off',
  },

  Limits_Restricted: {
    id: 'Limits_Restricted',
    group: BarringGroups.limit.id,
    name: 'Límite restringido',
    description: 'Limits_Restricted',
  },

  // Risk Group
  Risk_Roaming_off: {
    id: 'Risk_Roaming_off',
    group: BarringGroups.risk.id,
    name: 'Roaming',
    description: 'Risk_Roaming_off',
  },

  Risk_International_off: {
    id: 'Risk_International_off',
    group: BarringGroups.risk.id,
    name: 'Internacional',
    description: 'Risk_International_off',
  },

  Risk_TotalPremium_Off: {
    id: 'Risk_TotalPremium_Off',
    group: BarringGroups.risk.id,
    name: 'Premium Total',
    description: 'Risk_TotalPremium_Off',
  },

  Risk_Restricted: {
    id: 'Risk_Restricted',
    group: BarringGroups.risk.id,
    name: 'Riesgo restringido',
    description: 'Risk_Restricted',
  },

  Risk_Suspension: {
    id: 'Risk_Suspension',
    group: BarringGroups.risk.id,
    name: 'Suspensión de riesgo',
    description: 'Risk_Suspension',
  },

  Y_HURRIC: {
    id: 'Y_HURRIC',
    group: BarringGroups.risk.id,
    name: 'Roaming + Internacional',
    description: 'Y_HURRIC',
  },

  Y_HURG: {
    id: 'Y_HURG',
    group: BarringGroups.risk.id,
    name: 'Roaming + Internacional + Premium total',
    description: 'Y_HURG',
  },

  Risk_Incoming_SMS_Off: {
    id: 'Risk_Incoming_SMS_Off',
    group: BarringGroups.risk.id,
    name: 'SMS entrantes',
    description: 'Risk_Incoming_SMS_Off',
  },

  Risk_Outgoing_SMS_Off: {
    id: 'Risk_Outgoing_SMS_Off',
    group: BarringGroups.risk.id,
    name: 'SMS salientes',
    description: 'Risk_Outgoing_SMS_Off',
  },

  // Product Group
  Prod_TotalPremium_Off: {
    id: 'Prod_TotalPremium_Off',
    group: BarringGroups.product.id,
    name: 'Premium Total',
    description: 'Prod_TotalPremium_Off',
  },

  Y_BPSUB: {
    id: 'Y_BPSUB',
    group: BarringGroups.product.id,
    name: 'Suscripción Premium Yoigo',
    description: 'Y_BPSUB',
  },

  Y_HURP_P: {
    id: 'Y_HURP_P',
    group: BarringGroups.product.id,
    name: 'Suscripción Premium Yoigo - Mensual 40€ ',
    description: 'Y_HURP_P',
  },

  Prod_Int: {
    id: 'Prod_Int',
    group: BarringGroups.product.id,
    name: 'Roaming + Internacional',
    description: 'Prod_Int',
  },
}

export function getBarringInfo(barringId) {
  const stdBarringInfo = BarringInfos[barringId]

  if (stdBarringInfo) {
    return stdBarringInfo
  }

  const basicInfo = {
    id: barringId,
    group: 'none',
    name: barringId,
    description: barringId,
  }

  if (barringId.startsWith('Cust_') || barringId.startsWith('C_')) {
    return {
      ...basicInfo,
      group: BarringGroups.customer.id,
    }
  }

  if (barringId.startsWith('ATC_')) {
    return {
      ...basicInfo,
      group: BarringGroups.atc.id,
    }
  }

  if (barringId.startsWith('Debt_')) {
    return {
      ...basicInfo,
      group: BarringGroups.debt.id,
    }
  }

  if (barringId.startsWith('Limits_')) {
    return {
      ...basicInfo,
      group: BarringGroups.limit.id,
    }
  }

  if (barringId.startsWith('Risk_')) {
    return {
      ...basicInfo,
      group: BarringGroups.risk.id,
    }
  }

  if (barringId.startsWith('Prod_')) {
    return {
      ...basicInfo,
      group: BarringGroups.product.id,
    }
  }

  return {
    ...basicInfo,
    id: 'none',
    name: `Sin determinar - ${barringId}`,
    group: '',
  }
}

export function getBarringGroupStr(barringGroupId) {
  const barringGroup = BarringGroups[barringGroupId]

  if (barringGroup) {
    return barringGroup.name
  }

  return ''
}

export function getBarringsGroupedByType(barrings) {
  return groupBy(barrings, barring => getBarringInfo(barring.id).group)
}

export function translateBarringStatus(status) {
  return status === 'inactive' ? 'No Activo' : 'Activo'
}

export const translateBarringStatusNumber = status => (status === 1 ? 'Activo' : 'No Activo')

export const getBarringName = barringId =>
  BarringInfos[barringId] ? BarringInfos[barringId].name : ''

export function setBarringStatusHelper(barring) {
  return {
    barr_code: get(barring, 'id'),
    barr_status: get(barring, 'status') === 'active' ? 'off' : 'on',
  }
}

export function getNewStatusHelper(status) {
  return status === 'active' ? 'Desactivación en proceso' : 'Activación en proceso'
}

export function barringWithErrors(barrings) {
  const flattenedBarrings = flatMap(barrings)
  return !isEmpty(flattenedBarrings.filter(barring => get(barring, 'error')))
}

export function barringOnGoing(barrings) {
  const flattenedBarrings = flatMap(barrings)
  return !isEmpty(flattenedBarrings.filter(barring => get(barring, 'isOnGoing')))
}

export function formatBarringAdviceMsg(barring) {
  const barringStatusStr = get(barring, 'status') === 'active' ? 'Activación' : 'Desactivación'
  const barringInfo = getBarringInfo(get(barring, 'id'))
  const barringGroup = get(barringInfo, 'group')

  return `${barringStatusStr} de ${get(barringInfo, 'name')} (${getBarringGroupStr(barringGroup)})`
}

export const DISABLED_PRODUCT_BARRING_ID = [
  BarringInfos.Prod_TotalPremium_Off.id,
  BarringInfos.Y_HURP_P.id,
  BarringInfos.Prod_Int.id,
]
