import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { isEmpty, get } from 'lodash'
import { getIn, useFormikContext } from 'formik'
import {
  CircularProgress,
  Radio,
  FormHelperText,
  Typography,
  Box,
  RadioGroup,
  FormControlLabel,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import { FormikInput, ButtonLink } from 'components/ui'

import { fetchDeliveryPoints } from '../../../../services'

const useStyles = makeStyles(theme => ({
  'delivery-points': {
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: ({ hasSelectedPointErrors }) =>
      hasSelectedPointErrors ? theme.palette.brand.alert : theme.palette.global.gray,

    '& > div': {
      display: 'flex',
      flexDirection: 'column',

      '& > label': {
        padding: '10px',
        marginRight: 0,
        borderBottomWidth: '1px',
        borderBottomStyle: 'solid',
        borderBottomColor: theme.palette.global.gray_light,
        display: 'flex',
      },
    },
  },
}))

export function AddressStore({ addressType, className }) {
  const { values, errors, touched, setFieldValue } = useFormikContext()

  const deliveryPoint = getIn(values, `${addressType}.deliveryPoint`)
  const { zipCode, selectedPoint } = deliveryPoint
  const deliveryPointErrors = get(errors, `${addressType}.deliveryPoint`)
  const touchedFields = get(touched, `${addressType}.deliveryPoint`)

  const hasZipCodeErrors = get(deliveryPointErrors, 'zipCode') && get(touchedFields, 'zipCode')
  const selectedPointErrorMessage = get(deliveryPointErrors, 'selectedPoint.id')
  const hasSelectedPointErrors =
    !!get(deliveryPointErrors, 'selectedPoint.id') && get(touchedFields, 'selectedPoint.id')

  const [deliveryPoints, setDeliveryPoints] = useState([])
  const [deliveryError, setDeliveryError] = useState(false)
  const [deliveryLoading, setDeliveryLoading] = useState(false)

  const classes = useStyles({ hasSelectedPointErrors })

  async function fetchPoints() {
    setDeliveryPoints([])
    setDeliveryLoading(true)
    setDeliveryError(false)
    fetchDeliveryPoints(zipCode)
      .then(res => {
        setDeliveryPoints(res)
        setDeliveryLoading(false)
      })
      .catch(() => {
        setDeliveryError(true)
        setDeliveryLoading(false)
      })
  }

  useEffect(() => {
    if (zipCode) {
      fetchPoints()
    }
  }, [])

  function setDeliveryPoint(e) {
    const selectedDeliveryPoint = deliveryPoints.find(elem => elem.id === e.target.value)
    setFieldValue(`${addressType}.deliveryPoint.selectedPoint`, selectedDeliveryPoint)
  }

  function onZipCodeChange() {
    setDeliveryPoints([])
    setDeliveryError(false)
    setFieldValue(`${addressType}.deliveryPoint.selectedPoint.id`, '')
  }

  return (
    <div className={className}>
      <Typography variant="body1">
        Se recomienda entregar los dispositivos en tienda, por seguridad. Introduce el código postal
        para buscar tiendas cercanas.
      </Typography>
      <Box display="flex" alignItems="baseline">
        <FormikInput
          customError={deliveryError}
          name={`${addressType}.deliveryPoint.zipCode`}
          label="Código Postal"
          maxLength="5"
          onChange={onZipCodeChange}
          style={{ maxWidth: '180px' }}
        />
        <Box mx="16px">
          <ButtonLink type="button" onClick={fetchPoints} disabled={!zipCode || hasZipCodeErrors}>
            {isEmpty(deliveryPoints) ? 'Buscar' : 'Volver a buscar'}
          </ButtonLink>
        </Box>
        {deliveryLoading && <CircularProgress size="16px" color="secondary" />}
      </Box>
      {!isEmpty(deliveryPoints) && (
        <Box maxHeight="210px" overflowY="auto" mt="16px" className={classes['delivery-points']}>
          <RadioGroup
            onChange={setDeliveryPoint}
            value={selectedPoint.id}
            name={`${addressType}.deliveryPoint.selectedPoint.id`}>
            {deliveryPoints.map(({ id, attributes: { name, address, opening_hours } }) => (
              <FormControlLabel
                key={id}
                value={id}
                control={<Radio />}
                label={
                  <div>
                    <Typography variant="body1" className="bold">
                      {name}
                    </Typography>
                    <Typography variant="body1">{`${address}. ${opening_hours.monday}`}</Typography>
                  </div>
                }
              />
            ))}
          </RadioGroup>
        </Box>
      )}
      {(hasSelectedPointErrors || deliveryError || isEmpty(deliveryPoints)) && (
        <Box mt="16px">
          <FormHelperText error style={{ width: '100%' }}>
            {deliveryError && 'No se han encontrado tiendas en el código postal seleccionado.'}
            {!isEmpty(deliveryPoints) && !!hasSelectedPointErrors && selectedPointErrorMessage}
            {isEmpty(deliveryPoints) &&
              hasSelectedPointErrors &&
              'Es obligatorio seleccionar una tienda'}
          </FormHelperText>
        </Box>
      )}
    </div>
  )
}

AddressStore.propTypes = {
  addressType: PropTypes.string.isRequired,
  className: PropTypes.string,
}
