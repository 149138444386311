import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { ComposedTextField } from 'components/ui'
import { Checkbox, FormControlLabel } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
  input: {
    width: '250px',
  },
  checkbox: {
    'margin-right': '0px',
  },
})

export function SVAInput({
  description,
  regexp,
  name,
  saveFieldValue,
  storeField,
  shouldValidate,
  shouldShowCheckbox,
}) {
  const [value, setValue] = useState(storeField)
  const [selected, onSetSelected] = useState(storeField === 'NUEVO')
  const [error, setError] = useState()
  const [touched, setTouched] = useState()

  const styles = useStyles()

  function validate(val) {
    setTouched(true)
    if (selected) return setError('')
    if (!val) {
      return setError('El campo es obligatorio')
    }

    if (regexp) {
      const formattedRegexp = new RegExp(regexp.substr(1, regexp.length - 2))
      return formattedRegexp.test(val)
        ? setError('')
        : setError('Los datos introducidos son erróneos')
    }

    return setError('')
  }

  function onChange(val) {
    setValue(val)
    validate(val)
  }

  function onSelectPhone() {
    setValue(selected ? '' : 'NUEVO')
    setError('')
    onSetSelected(!selected)
  }

  useEffect(() => {
    saveFieldValue(name, error ? '' : value)
  }, [value])

  useEffect(() => {
    if (shouldValidate) {
      validate(value)
    }
  }, [shouldValidate])

  return (
    <div className="flex align-space-between-center">
      <ComposedTextField
        data-hook={name}
        className={shouldShowCheckbox ? styles.input : ''}
        disabled={selected}
        label={description}
        value={value}
        onChange={e => onChange(e.target.value)}
        touched={touched}
        error={error}
        onBlur={() => validate(value)}
        variant="outlined"
        fullWidth={!shouldShowCheckbox}
      />
      {shouldShowCheckbox && (
        <FormControlLabel
          className={styles.checkbox}
          control={
            <Checkbox
              data-hook="sva-new-phone"
              color="primary"
              className="checkbox"
              checked={selected}
              onChange={onSelectPhone}
            />
          }
          label="Nuevo teléfono"
        />
      )}
    </div>
  )
}

SVAInput.propTypes = {
  description: PropTypes.string.isRequired,
  regexp: PropTypes.string,
  name: PropTypes.string.isRequired,
  saveFieldValue: PropTypes.func.isRequired,
  storeField: PropTypes.object,
  shouldValidate: PropTypes.bool,
  shouldShowCheckbox: PropTypes.bool,
}
