import { useState } from 'react'
import PropTypes from 'prop-types'

import { Collapse, Typography, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import { getLighten, TextStyles } from 'utils'

import ConfigPortOutHeader from './ConfigPortOutHeader'

const useStyles = makeStyles(theme => ({
  'config-port-out': {
    backgroundColor: getLighten(theme.palette.global.gray_dark, 0.9),

    '& > div:not(:last-child)': {
      borderBottom: `1px dotted ${getLighten(theme.palette.global.gray_medium, 0.5)}`,
    },

    '& .text-field': {
      '& > *': {
        alignSelf: 'center',

        '&:first-child': {
          paddingRight: '4px',
          '&:after': {
            content: '":"',
          },
        },
      },
    },
  },
}))

const ConfigPortOut = ({ portOutData, ...props }) => {
  const classes = useStyles()

  const [collapsed, setCollapsed] = useState(props.collapsed !== undefined ? props.collapsed : true)

  const toggle = () => {
    setCollapsed(!collapsed)
  }

  return (
    <div>
      <ConfigPortOutHeader collapsed={collapsed} actionToggle={toggle} />

      <Collapse in={!collapsed} timeout="auto" className="child-fluid">
        <div className={classes['config-port-out']}>
          {portOutData &&
            portOutData[0].lines.map(line => (
              <Box pt="15px" pb="8px" my="49px">
                <Box display="flex" pb="12px">
                  {line.title && (
                    <Typography {...TextStyles.paragraphDark()}>
                      <strong>{line.title}</strong>
                    </Typography>
                  )}
                </Box>
                <Box display="flex" flexWrap="wrap" alignItems="center">
                  {line.info &&
                    line.info.map(({ label, value }) => (
                      <Box mr="40px" mb="16px" className="text-field">
                        <Typography {...TextStyles.labelDark()}>{label}</Typography>
                        <Typography {...TextStyles.dataDark()}>{value}</Typography>
                      </Box>
                    ))}
                </Box>
              </Box>
            ))}
        </div>
      </Collapse>
    </div>
  )
}

ConfigPortOut.propTypes = {
  portOutData: PropTypes.arrayOf(
    PropTypes.shape({
      lines: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          info: PropTypes.arrayOf(
            PropTypes.shape({
              label: PropTypes.string,
              value: PropTypes.string,
            }),
          ),
        }),
      ),
    }),
  ),
  collapsed: PropTypes.bool,
}

export default ConfigPortOut
