import { get } from 'lodash'
import { makeStyles } from '@material-ui/styles'

// Generic Container Sizes
const heightPrincipalContainer = '750px'
const heightHeader = '8%'
const heightDataContainer = '82%'
const heightActionsButtons = '10%'

// Styles TypificationContainer

export const useTypificationContainerStyles = makeStyles(theme => ({
  root: {
    backgroundColor: get(theme, 'palette.componentColors.White'),
    height: '100vh',
    '& .principal-container': {
      height: heightPrincipalContainer,
    },
    '& .header': {
      height: heightHeader,
      backgroundColor: get(theme, 'palette.brand.light'),
      borderBottom: `1px solid ${get(theme, 'palette.global.gray_light_05')}`,
      color: get(theme, 'palette.global.gray_dark'),
      display: 'flex',
      alignItems: 'center',
      paddingLeft: '20px',
      '& .MuiTypography-h4': {
        fontSize: '22px',
      },
    },
    '& .data-container': {
      height: heightDataContainer,
      maxHeight: '100%',
      overflow: 'auto',
    },
  },
}))

// TypificationNotification Syles

export const useTypificationNotificationSyles = makeStyles({
  styles: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
})

// TypificationsListContainer Styles

export const useTypificationsListContainerStyles = makeStyles(theme => ({
  notificationContainer: {
    borderBottom: `1px solid ${get(theme, 'palette.global.gray_light_05')}`,
    padding: '10px 20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

// TypificationsList Styles

export const useTypificationsListStyles = makeStyles(theme => ({
  styles: {
    '& .list-item': {
      border: `1px solid ${get(theme, 'palette.global.gray_light_05')}`,
    },
    '& .header-list': {
      backgroundColor: get(theme, 'palette.global.gray_light'),
      borderBottom: `1px solid ${get(theme, 'palette.global.gray_light_05')}`,
      color: get(theme, 'palette.global.gray_dark'),
      fontSize: '15px',
      padding: '10px 20px',
    },
    '& .body-list': {
      display: 'flex',
      flexDirection: 'column',
      padding: '10px 20px',
    },
    '& .container-header': {
      marginBottom: '10px',
    },
    '& .item-header': {
      display: 'flex',
      justifyContent: 'space-between',
    },
    '& .collapse-info': {
      alignSelf: 'flex-end',
      marginBottom: '10px',
    },
    '& .item-info': {
      display: 'flex',
      alignItems: 'center',
      '& .MuiTypography-h6': {
        fontSize: '14px',
      },
      '& .MuiTypography-subtitle2': {
        lineHeight: '1.6',
      },
    },
    '& .item-information': {
      marginTop: '10px',
      '& .MuiTypography-h6': {
        fontSize: '14px',
      },
    },
    '& .item-footer': {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '10px',
    },
  },
}))

// TypificationForm styles

export const useTypificationFormStyles = makeStyles({
  styles: {
    '& .no-spacing': {
      paddingTop: '0!important',
      paddingBottom: '0!important',
    },
    '& .top-spacing': {
      marginTop: '5px',
    },
    '& .first-element-group': {
      paddingTop: '0!important',
      paddingBottom: '7px!important',
    },
  },
})

// ActionsButtons styles

export const useActionsButtonsStyles = makeStyles(theme => ({
  styles: {
    height: heightActionsButtons,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '20px',
    maxHeight: '100%',
    '& .primary-button-container': {
      '& .MuiButton-containedPrimary': {
        backgroundColor: get(theme, 'palette.componentColors.White'),
        color: get(theme, 'palette.primary.main'),
      },
      '& .MuiButton-containedPrimary:hover': {
        backgroundColor: get(theme, 'palette.global.gray_light_05'),
        color: get(theme, 'palette.componentColors.White'),
      },
    },
    '& .second-button-container': {
      display: 'flex',
      justifyContent: 'flex-end',
      paddingRight: '20px',
      '& Button:disabled': {
        cursor: 'not-allowed',
        pointerEvents: 'auto',
      },
    },
  },
}))
