import { call, put, takeEvery } from 'redux-saga/effects'
import { logError, formatErrorMessage } from 'services/logging'
import { get } from 'lodash'

import * as actions from './prepaid-line.actions'
import { fetchPrepaidLine } from '../services/prepaid-line.service'

export function* findPrepaidLineSaga({ payload: { productId } }) {
  try {
    const response = yield call(fetchPrepaidLine, productId)
    const prepaidLineData = get(response, 'data')
    yield put(actions.findPrepaidLineSuccess(prepaidLineData, productId))
  } catch (e) {
    yield call(logError, { e: new Error(formatErrorMessage(e)), productId })
    yield put(actions.findPrepaidLineFailed(e, productId))
  }
}

export function* watchPrepaidLine() {
  yield takeEvery(actions.FIND_PREPAID_LINE, findPrepaidLineSaga)
}
