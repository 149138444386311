import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useFormikContext } from 'formik'
import { fieldNames as getFieldNames } from 'modules/Lines/line.config'
import { resetAdditionalTariffsAction } from 'modules/tariffs/store'
import { getFormikSelectors } from 'modules/NewClientSales/hooks/Lines'
import { LINE_TYPE } from 'modules/Lines/constants'

// TODO: Move getFormikSelectors from some upper place
import { resetCustomerOrderLimit } from 'modules/CustomerOrders/store/index'

import { useResetLine } from '../../hooks/Shared'
import { setMainMobileLineTariff } from '../../store/actions'
import { selectMainMobileTariff, selectMainSelectedTariff } from '../../store/selectors/index'
import { useTariffs } from './useTariffs'

export function useLine({ lineType }) {
  const { setFieldValue, values } = useFormikContext()
  // TODO: getFieldNames second param is set to true but depends on  isMobileOnly || isElFijo
  const fieldNames = getFieldNames(lineType, true)

  // TODO check where tariffsType is coming from now onwards
  // isElFijo ? 'mobile_fixed_line' : 'mobile'
  const dispatch = useDispatch()
  // TODO: check to do this for landline as well
  const selectedTariff = useSelector(
    lineType === LINE_TYPE.MAIN_MOBILE ? selectMainMobileTariff : selectMainSelectedTariff,
  )
  const tariffReqParams =
    lineType === LINE_TYPE.MAIN_MOBILE
      ? { type: 'mobile' }
      : {
          type: 'additional',
          mainTariffPoId: selectedTariff.id,
          mainTariffPsId: selectedTariff.ps,
        }

  const { tariffs, isLoadingTariffs, tariffsError, selectedExtraTariffs } = useTariffs({
    ...tariffReqParams,
  })
  const { onResetLines } = useResetLine(fieldNames)
  const formikValues = getFormikSelectors(fieldNames, setFieldValue, values)

  function onChangeTariff(tariff) {
    if (tariff?.ps !== formikValues[lineType]?.ps) {
      onResetLines(fieldNames)
      setFieldValue(fieldNames.TARIFF, tariff)
      dispatch(setMainMobileLineTariff(tariff))
      dispatch(resetAdditionalTariffsAction())
      dispatch(resetCustomerOrderLimit())
    }
  }

  useEffect(() => {
    if (
      lineType === LINE_TYPE.MAIN_MOBILE &&
      formikValues[lineType]?.tariff?.ps !== selectedTariff.ps
    ) {
      setFieldValue(fieldNames.TARIFF, selectedTariff)
    }
  }, [selectedTariff])

  return {
    tariffs,
    isLoadingTariffs,
    onChangeTariff,
    selectedTariff,
    selectedExtraTariffs,
    fieldNames,
    formikValues,
    tariffsError,
  }
}
