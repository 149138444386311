import PropTypes from 'prop-types'
import { get, isEmpty, isNil } from 'lodash'

import { Typography, Divider } from '@material-ui/core'
import { TextStyles } from 'utils/text'

import { Price, Advice } from 'components/ui'

import { setPromotionLiteral } from 'utils'

export function FeesItem({ feesData, promotion, noDivider, taxNeeded }) {
  const finalWithTax = get(feesData, 'finalWithTax')
  const finalWithoutTax = get(feesData, 'finalWithoutTax')

  return (
    <>
      <div className="fees-item flex" data-hook="fees-item">
        {!isEmpty(get(feesData, 'periods')) && !isEmpty(get(feesData, 'periods.amount')) && (
          <div>
            <Price className="price" title="Cuotas temporales" />
            {feesData.periods.map(singlePeriod => (
              <div className="flex align-start-center">
                <Typography
                  {...TextStyles.subtitle2Dark({
                    style: { padding: '2px' },
                  })}>
                  <span>{`${get(singlePeriod, 'amount')}€/mes`}</span>
                </Typography>
                <Typography
                  {...TextStyles.subtitle2Dark({
                    style: { padding: '2px' },
                  })}>
                  {`(mes ${get(singlePeriod, 'from')} a ${get(singlePeriod, 'to')})`}
                </Typography>
              </div>
            ))}
            <Divider orientation="vertical" />
          </div>
        )}
        {isNil(finalWithTax) || !taxNeeded ? (
          <div className="p-x-24">
            <Price
              className="p-b-10"
              title="Precio sin impuestos"
              monthly
              price={finalWithoutTax}
            />
            {isNil(finalWithTax) && (
              <Advice type="error">Se ha producido un error al calcular los impuestos</Advice>
            )}
          </div>
        ) : (
          <div className="flex">
            <Price
              className="p-x-24"
              title={isEmpty(promotion) ? 'Precio final' : 'Precio sin promoción'}
              monthly
              price={finalWithTax}
            />
            {!isEmpty(promotion) && (
              <>
                <Divider orientation="vertical" />
                <div className="p-x-24">
                  <Typography {...TextStyles.subtitle2Dark()}>Promoción aplicada:</Typography>
                  <Typography
                    {...TextStyles.subtitle2Dark({
                      className: 'p-t-12',
                    })}>
                    {setPromotionLiteral(get(promotion, 'attributes'), get(promotion, 'id'))}
                  </Typography>
                </div>
                {!noDivider && <Divider orientation="vertical" />}
              </>
            )}
          </div>
        )}
        {get(feesData, 'penalty') > 0 ? (
          <div>
            <Divider orientation="vertical" />
            <Price
              className="p-x-24"
              title="Si cancela hoy"
              price={feesData.penalty}
              aux="Por terminal"
            />
            <Divider orientation="vertical" />
          </div>
        ) : (
          ''
        )}
      </div>
    </>
  )
}

FeesItem.propTypes = {
  feesData: PropTypes.object,
  promotion: PropTypes.object,
  noDivider: PropTypes.bool,
  taxNeeded: PropTypes.bool,
}
