import { useMemo } from 'react'
import { useLocation, useHistory } from 'react-router-dom'

import { removeQueryParam } from 'modules/url'
import { SALES_TEST_ROUTE } from '../../constants/index'
import { useSetVista360URL } from './useSetVista360URL'

export const useSaleExit = backToVista => {
  const location = useLocation()
  const history = useHistory()

  const { vista360URL } = useSetVista360URL()

  function getTargetURLWithoutParams(baseURL) {
    let url = removeQueryParam(`${baseURL}${location.search}`, 'sale')

    url = removeQueryParam(url, 'isEditing')
    url = removeQueryParam(url, 'saleSegment')
    url = removeQueryParam(url, 'isPresale')

    return url
  }

  const saleExitUrl = useMemo(() => {
    if (backToVista) {
      return vista360URL
    }

    return getTargetURLWithoutParams(SALES_TEST_ROUTE)
  }, [])

  const goExitSale = () => history.push(saleExitUrl)

  return {
    saleExitUrl,
    goExitSale,
  }
}
