import { axiosJWT } from 'modules/axios'
import { APP_CONFIG } from 'services/app-config'
import { get } from 'lodash'

export function fatCompensation(customerData, data) {
  return axiosJWT({
    method: 'POST',
    url: `${APP_CONFIG.care_cluster}/search-360/product/fat`,
    data: {
      data: {
        type: 'subscriptions-apply-fat',
        attributes: {
          'subscription-id': get(customerData, 'subscriptionId'),
          username: get(customerData, 'sfid'),
          msisdn: get(customerData, 'msisdn'),
          channel: get(customerData, 'profileId'),
          'id-number': get(customerData, 'documentId'),
          ...data,
        },
      },
    },
  })
}
