import { connect } from 'react-redux'
import { selectIsCancelNebaEnabled } from 'modules/tariffs/store-apigee'
import { actions as orderOnFlyActions, selectors } from 'services/order-on-fly'
import {
  selectSfid,
  selectDocumentId,
  selectIsLoading,
  isOffersAuthorizedAgent,
} from 'modules/Auth'
import { selectQueryParam, selectURLSearchParamsIterator } from 'modules/Router'
import { ChangeServices } from '../../components/ChangeServices'
import { selectIsVistaAuthorized } from '../../../vista-360/selectors/selectors'

const mapDispatchToProps = dispatch => ({
  getSubscriptionData: fixedNumber => {
    dispatch(orderOnFlyActions.findOrderOnFly(fixedNumber))
  },
})

const mapStateToProps = state => {
  const sfid = selectSfid(state)

  const isOffersAuthorized = isOffersAuthorizedAgent(sfid)

  return {
    documentId: selectDocumentId(state),
    fixedNumber: selectQueryParam('fixedNumber')(state),
    redirect: selectQueryParam('redirect')(state),
    query: selectURLSearchParamsIterator(state),
    errorOnFly: selectors.orderOnFlyError(state),
    loading: state.orderOnFly.loading,

    onFlyTechnology: Boolean(selectors.selectOrderOnFlyChTech(state)),
    onFlyDirection: Boolean(selectors.selectOrderOnFlyChAddr(state)),
    onFlyTechNeba: Boolean(selectors.selectOrderOnFlyChTechNeba(state)),

    isOffersAuthorized,
    isCancelNebaEnabled: selectIsCancelNebaEnabled(state),
    isVistaEnabled: selectIsVistaAuthorized(state),
    loadingSession: selectIsLoading(state),
  }
}

export const ChangeServicesContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ChangeServices)
