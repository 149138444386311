import PropTypes from 'prop-types'
import MainBranch from './TreeBranch'

function TreeView({ nodes = [], onClickItem }) {
  return (
    <div className="tree-view">
      <MainBranch subnodes={nodes} onClickItem={onClickItem} />
    </div>
  )
}

TreeView.propTypes = {
  nodes: PropTypes.array,
  onClickItem: PropTypes.func,
}

export default TreeView
