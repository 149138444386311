import { useEffect, useState } from 'react'

const brandOptionsDefault = [
  {
    key: 'Yoigo',
    value: 'Yoigo',
    label: 'Yoigo',
  },
]

const segmentOptionsDefault = [
  {
    key: 'Residencial',
    value: 'residential',
    label: 'Residencial',
  },
  {
    key: 'business',
    value: 'business',
    label: 'Negocio',
  },
]

const productsOptionsJoints = [
  {
    key: 'security',
    value: 'security',
    label: 'Alarmas HomeGO',
  },
]

/* const productOptionsResidencial = [
  {
    key: 'familyhealth',
    value: 'familyhealth',
    label: 'Seguro Pack Familiar DoctorGO',
  },
] */

const productOptionsBusiness = [
  {
    key: 'e_invoice',
    value: 'e_invoice',
    label: 'Factura electrónica',
  },
  {
    key: 'cybersecurity',
    value: 'cybersecurity',
    label: 'Ciberseguridad',
  },
  {
    key: 'lite_digital_solutions',
    value: 'lite_digital_solutions',
    label: 'Soluciones Digitales Lite',
  },
  {
    key: 'online_presence_basic',
    value: 'online_presence_basic',
    label: 'Presencia Online BÁSICA (Servicio)',
  },
  {
    key: 'online_presence_basic_added',
    value: 'online_presence_basic_added',
    label: 'Presencia Online BÁSICA (Venta añadida de web, microsite y Ecommerce)',
  },
  {
    key: 'professional_web',
    value: 'professional_web',
    label: 'Web Profesional',
  },
  {
    key: 'google_visibility_essential',
    value: 'google_visibility_essential',
    label: 'Visibilidad en Google gestionada ESENCIAL',
  },
  {
    key: 'google_visibility_optimal',
    value: 'google_visibility_optimal',
    label: 'Visibilidad en Google gestionada ÓPTIMA',
  },
  {
    key: 'micrositee_web',
    value: 'micrositee_web',
    label: 'Web Micrositee',
  },
  {
    key: 'professional_ecommerce',
    value: 'professional_ecommerce',
    label: 'Ecommerce profesional',
  },
  {
    key: 'rrss_4',
    value: 'rrss_4',
    label: 'RRSS 4 (sin reseñas)',
  },
  {
    key: 'seo_4',
    value: 'seo_4',
    label: 'SEO 4 Expresiones',
  },
]

export const useGetOptionsSalesLead = segment => {
  const [brandOptions, setBrandOptions] = useState(brandOptionsDefault)
  const [productOptions, setProductOptions] = useState([])
  const [segmentOptions, setSegmentOptions] = useState(segmentOptionsDefault)

  useEffect(() => {
    setProductOptions([
      ...productsOptionsJoints,
      ...(segment === 'business' ? productOptionsBusiness : []),
    ])
  }, [segment])

  return [
    brandOptions,
    productOptions,
    segmentOptions,
    setBrandOptions,
    setProductOptions,
    setSegmentOptions,
  ]
}
