import { constants } from './constants'

const findOrderProducts = payload => ({
  type: constants.FIND_ORDER_PRODUCTS,
  payload,
})

const findOrderProductsSuccess = data => ({
  type: constants.FIND_ORDER_PRODUCTS_SUCCESS,
  payload: {
    ...data,
  },
})

const findOrderProductsFailed = error => ({
  type: constants.FIND_ORDER_PRODUCTS_FAILED,
  payload: {
    error,
  },
})

const findOrdersProductsByClient = payload => ({
  type: constants.FIND_ORDERS_PRODUCT_BY_CLIENT,
  payload,
})

const findOrdersProductsByClientSuccess = data => ({
  type: constants.FIND_ORDERS_PRODUCT_BY_CLIENT_SUCCESS,
  payload: {
    ...data,
  },
})

const findOrdersProductsByClientFailed = error => ({
  type: constants.FIND_ORDERS_PRODUCT_BY_CLIENT_FAILED,
  payload: {
    error,
  },
})

export const actions = {
  findOrderProducts,
  findOrderProductsSuccess,
  findOrderProductsFailed,
  findOrdersProductsByClient,
  findOrdersProductsByClientSuccess,
  findOrdersProductsByClientFailed,
}
