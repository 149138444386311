import PropTypes from 'prop-types'
import { ButtonsSelector } from 'components'

export const AddressButtons = ({
  shippingAddressList,
  selectedAddressType,
  addressType,
  className,
  onClickButton,
}) => {
  const shippingAddressListItems = shippingAddressList.map(address => ({
    ...address,
    selected: address.value === selectedAddressType,
  }))

  return (
    <ButtonsSelector
      className={className}
      dataHook={`${addressType}.buttons`}
      items={shippingAddressListItems}
      onSelect={onClickButton}
    />
  )
}

AddressButtons.propTypes = {
  shippingAddressList: PropTypes.array.isRequired,
  selectedAddressType: PropTypes.string,
  addressType: PropTypes.string,
  className: PropTypes.string,
  onClickButton: PropTypes.func.isRequired,
}
