import PropTypes from 'prop-types'

import LineItemCard from './LineItemCard'

export function TitleLineItemCard({ className, primaryText, ...rest }) {
  return (
    <LineItemCard
      className={`${className} line-item-card`}
      primaryText={{
        color: 'primary',
        variant: 'body1',
        style: { textTransform: 'uppercase', fontWeight: 'bold' },
        children: primaryText,
      }}
      {...rest}
    />
  )
}

TitleLineItemCard.propTypes = {
  className: PropTypes.string,
  primaryText: PropTypes.string,
}
