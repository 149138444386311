import { get, isEmpty } from 'lodash'
import { selectAppointmentError } from 'modules/Appointments'
import { selectInstallsMM } from 'modules/Installations'
import {
  selectIs2p,
  selectIsCarteraQueryParam,
  selectChannelQueryParam,
  selectIsSubscriptionQueryParam,
  selectIsCrossSellQueryParam,
  selectIsMultiSimQueryParam,
} from 'modules/Router/store/index'
import { createSelector, createStructuredSelector } from 'reselect'
import { selectClientGdprUpdateFlag, selectGdprConsentsFlag } from 'services/feature-flag/selectors'
import { selectIsCustomerCanceled } from 'modules/CustomerInfo/store/customerInfo.selectors'
import {
  selectIsPurchaseAlarmError,
  selectIsPurchaseAlarmLoading,
} from 'services/customer-sva/selectors'
import { SIGNUP_TYPE_PORTABILITY, AUTONOMO, COMPANY } from 'services/global-constants'
import {
  RESIDENTIAL_SEGMENT,
  SELF_EMPLOYED_SEGMENT,
  TELESALES_CHANNEL,
  POS_CHANNEL,
} from 'modules/mas-consents/constants'

import {
  selectAllSelectedTerminals,
  selectHasAgileTV,
  selectIsCompany,
  selectIsElFijo,
  selectLandlineOffer,
  selectVerbalId,
  selectMainMobileLineTerminal,
  selectSalesTypes,
  selectIsPosSale,
  selectAccount,
  selectSelectedHomeGoAlarm,
} from './Basket.selectors'
import {
  selectTerminalsFinancialData,
  selectTerminalsSinglePaymentData,
} from './BasketWithTaxes.selectors'
import {
  selectErrorCheckout,
  selectFiberType,
  selectHasIUA,
  selectOrderData,
  selectIsExistingClientSaleFlow,
  selectIs2pPure,
  selectIsFiberInstallationRequired,
} from './NewClientSales.selectors'
import { selectContractId, selectOrderCheckingId, selectSubscriptionId } from './orders.selectors'
import { selectPurchaseHomeItSvaSalesData } from './Summary.selectors'
import { mapLanguageToCode } from './Summary.selectors.helpers'

const selectIsPortabilityFromFTTH = createSelector(
  selectLandlineOffer,
  landline => landline.isPortabilityFromFtth === 'yes',
)

const selectIsFixedPortability = createSelector(
  selectLandlineOffer,
  landline => landline.signUpType === SIGNUP_TYPE_PORTABILITY,
)

const selectHasTerminals = createSelector(
  selectAllSelectedTerminals,
  terminals => !isEmpty(terminals),
)

const selectHasVoiceBox = createSelector(
  selectMainMobileLineTerminal,
  terminal => {
    const VOICE_BOX_ID = 'RM43B31W2'
    return get(terminal, 'id') === VOICE_BOX_ID
  },
)

const selectMasConsentsChannel = createSelector(
  selectChannelQueryParam,
  channel => (channel.toLowerCase().indexOf('pos') > -1 ? POS_CHANNEL : TELESALES_CHANNEL),
)

export const selectGdprData = createSelector(
  [selectAccount, selectContractId, selectSubscriptionId, selectVerbalId, selectMasConsentsChannel],
  (
    { documenttype, documentid, segment, language },
    contractId,
    subscriptionId,
    verbalId,
    channel,
  ) => ({
    contractId,
    subscriptionId,
    verbalId,
    customerDocument: documentid,
    customerDocumentType: documenttype,
    segment: segment === AUTONOMO ? SELF_EMPLOYED_SEGMENT : RESIDENTIAL_SEGMENT,
    lang: mapLanguageToCode(language),
    channel,
  }),
)

export const shouldShowGdpr = createSelector(
  [
    selectGdprConsentsFlag,
    selectIsExistingClientSaleFlow,
    selectIsCarteraQueryParam,
    selectIsCustomerCanceled,
    selectOrderCheckingId,
    selectAccount,
  ],
  (consentsGdprFlag, isExistingClient, isCartera, isCancelled, orderId, { segment }) => {
    return (
      consentsGdprFlag &&
      segment !== COMPANY &&
      !!orderId &&
      (!isExistingClient || (isCartera && isCancelled))
    )
  },
)

export const selectNextSteps = createStructuredSelector({
  errorCheckout: selectErrorCheckout,
  errorAppointment: selectAppointmentError,
  order: selectOrderData,
  isPosSale: selectIsPosSale,
  terminals: selectTerminalsFinancialData,
  singlePaymentTerminals: selectTerminalsSinglePaymentData,
  hasTerminals: selectHasTerminals,
  isFixedPortability: selectIsFixedPortability,
  isPortabilityFromFtth: selectIsPortabilityFromFTTH,
  isCompany: selectIsCompany,
  agileTV: selectHasAgileTV,
  installsMM: selectInstallsMM,
  isInstallationRequired: selectIsFiberInstallationRequired,
  hasIUA: selectHasIUA,
  fiberType: selectFiberType,
  isElFijo: selectIsElFijo,
  saleType: selectSalesTypes,
  hasVoiceBox: selectHasVoiceBox,
  is2p: selectIs2p,
  is2pPure: selectIs2pPure,
  gdprData: selectGdprData,
  shouldShowGdpr,
  homeGoSvaHasError: selectIsPurchaseAlarmError,
  homeGoSvaIsLoading: selectIsPurchaseAlarmLoading,
  homeGoSvaRetryData: selectPurchaseHomeItSvaSalesData,
  selectedHomeGoAlarm: selectSelectedHomeGoAlarm,
  updateClientConsentsFlag: selectClientGdprUpdateFlag,
  isCartera: selectIsCarteraQueryParam,
  isSubscription: selectIsSubscriptionQueryParam,
  isCrossSell: selectIsCrossSellQueryParam,
  isCustomerCanceled: selectIsCustomerCanceled,
  isMultiSim: selectIsMultiSimQueryParam,
})
