const GET_DISCOUNTS_INCOMPATIBILITIES_INIT = 'OVID/DISCOUNTS/GET_DISCOUNTS_INCOMPATIBILITIES_INIT'
const GET_DISCOUNTS_INCOMPATIBILITIES_SUCCESS =
  'OVID/DISCOUNTS/GET_DISCOUNTS_INCOMPATIBILITIES_SUCCESS'
const GET_DISCOUNTS_INCOMPATIBILITIES_FAILED =
  'OVID/DISCOUNTS/GET_DISCOUNTS_INCOMPATIBILITIES_FAILED'
export const constants = {
  GET_DISCOUNTS_INCOMPATIBILITIES_INIT,
  GET_DISCOUNTS_INCOMPATIBILITIES_SUCCESS,
  GET_DISCOUNTS_INCOMPATIBILITIES_FAILED,
}
