import PropTypes from 'prop-types'
import styled from 'styled-components'
import { ButtonBase, Icon } from '@material-ui/core'
import { ICONS } from 'utils/icons'

const colorPainter = (primary, active) => {
  if (primary) return '#71a0c1'
  if (!primary && active) return '#ffffff'
  return '#2981bc'
}

const SubmenuItemWrapper = styled.div`
  button {
    min-width: ${props => (props.primary ? null : '94px')};
    max-width: ${props => (props.primary ? '72px' : null)};
    color: ${({ theme }) => theme.palette.primary.main};
    font-weight: bold;
    background: ${props => colorPainter(props.primary, props.active)};
    color: ${props => (props.active ? '#2981bc' : '#ffffff')};
    flex-direction: column;
    height: 100%;
    &:hover {
      background: ${props => (props.active ? '#ffffff' : '#71a0c1')};
    }
  }
  .icon,
  svg {
    font-size: 24px;
    color: inherit;
    display: block;
    margin: 0 auto;
  }
`

const ItemContent = styled.div`
  padding: 6px;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-around;
`

const ItemText = styled.div`
  color: inherit;
  font-size: 12px;
`

const SubmenuItem = props => {
  const { text, icon, onClick, active, primary } = props

  return (
    <SubmenuItemWrapper active={active} primary={primary}>
      <ButtonBase onClick={onClick}>
        <ItemContent>
          <Icon className="icon">{ICONS[icon]}</Icon>
          <ItemText>{text}</ItemText>
        </ItemContent>
      </ButtonBase>
    </SubmenuItemWrapper>
  )
}

SubmenuItem.defaultProps = {
  active: false,
}

SubmenuItem.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.string,
  onClick: PropTypes.func,
  active: PropTypes.bool,
  primary: PropTypes.bool,
}

export default SubmenuItem
