import { createStructuredSelector, createSelector } from 'reselect'

import { selectors as customer360Selectors } from 'services/customer-360/selectors'
import { selectSubscriptionTariffSegmentQueryParam } from 'modules/Router/store/index'
import { selectAccountSelfEmployed } from 'modules/CustomerInfo/store/customerInfo.selectors'
import { RESIDENTIAL, PRO } from 'modules/SharedSales/constants'
import { selectChannelQueryParam } from 'modules/Router/store/router.selectors'
import {
  selectRenewalSVAAgileLoading,
  selectRenewalAgileTvFormated,
  selectRenewalSVAAgileError,
} from 'modules/SVAs/store'
import {
  selectFetchedDevices,
  selectFetchDevicesError,
  selectIsFetchingDevices,
  selectRenewalOfferCode,
  selectRenewalSaleChannelValue,
  selectSubscriptionLineType,
  selectRenewalSubscriptionIsPostpaid,
  selectSubscriptionTariffType,
  selectDeviceType,
} from '../store/device-renewal.selectors'

import { removeDevicesWithoutPaymentMethods } from '../store/device-renewal.selectors.helpers'

const selectDevicesWithoutPaymentMethods = createSelector(
  selectFetchedDevices,
  fetchedDevices =>
    fetchedDevices ? removeDevicesWithoutPaymentMethods(fetchedDevices) : undefined,
)

const selectSubscriptionSegment = createSelector(
  selectSubscriptionTariffSegmentQueryParam,
  subscriptionTariffSegment => (subscriptionTariffSegment === 'pro' ? PRO : RESIDENTIAL),
)

const selectSubscriptionPaymentType = createSelector(
  selectRenewalSubscriptionIsPostpaid,
  subscriptionIsPostpaid => (subscriptionIsPostpaid ? 'postpaid' : 'prepaid'),
)

export const deviceOfferSelectors = createStructuredSelector({
  devices: selectDevicesWithoutPaymentMethods,
  fetchDevicesError: selectFetchDevicesError,
  isFetchingDevices: selectIsFetchingDevices,
  customerId: customer360Selectors.getIdentificationId,
  customerIdentificationType: customer360Selectors.getIdentificationType,
  subscriptionSegment: selectSubscriptionSegment,
  selfEmployed: selectAccountSelfEmployed,
  offerCode: selectRenewalOfferCode,
  renewalSaleChannel: selectRenewalSaleChannelValue,
  deviceType: selectDeviceType,
  subscriptionLineType: selectSubscriptionLineType,
  subscriptionPaymentType: selectSubscriptionPaymentType,
  tariffType: selectSubscriptionTariffType,
  channel: selectChannelQueryParam,
  isFetchingAgile: selectRenewalSVAAgileLoading,
  agileTvData: selectRenewalAgileTvFormated,
  fetchAgileTvError: selectRenewalSVAAgileError,
})
