import { useState, useMemo, useEffect } from 'react'
import { throttle } from 'lodash'
import { useSelector } from 'react-redux'

import { fetchStreets } from 'modules/Coverage/services'
import { selectSaleChannel } from 'modules/NewClientSales/store/selectors/index'
import { selectUUID } from 'modules/Auth/store/index'
import { logError, formatErrorMessage } from 'services/logging'

export function useAddressAutocomplete(initialValue, allowInputManualAddress = true) {
  const [inputValue, setInputValue] = useState(initialValue)
  const [addresses, setAddresses] = useState([])

  const channel = useSelector(selectSaleChannel)
  const checkId = useSelector(selectUUID)
  const fetch = useMemo(
    () =>
      throttle((request, cb) => {
        fetchStreets(request)
          .then(res => cb(res.data))
          .catch(e => {
            logError(new Error(formatErrorMessage(e)))
          })
      }, 200),
    [],
  )

  useEffect(() => {
    if (!inputValue) return
    fetch({ search: inputValue, channel, checkId }, results => {
      if (results) {
        const listResults = [...results]
        if (allowInputManualAddress) {
          listResults.push('Utilizar la dirección introducida')
        }
        setAddresses(listResults)
      }
    })
  }, [inputValue, fetch])

  return { setInputValue, setAddresses, addresses, inputValue }
}
