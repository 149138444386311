import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Switch, Route } from 'react-router-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { history } from '../../services'
import { useRouterAnimationType } from '../../hooks'
import { RouterAnimationStyles } from '../RouterAnimationStyles'

export const AnimatedSwitch = styled(({ className, children, animationTime }) => {
  const [animationType] = useRouterAnimationType(history)
  return (
    <>
      <RouterAnimationStyles type={animationType} time={animationTime} />
      <Route
        render={({ location }) => (
          <TransitionGroup className={className}>
            <CSSTransition
              key={location.pathname}
              timeout={animationType ? animationTime + 100 : 0}
              classNames="animate">
              <Switch location={location}>{children}</Switch>
            </CSSTransition>
          </TransitionGroup>
        )}
      />
    </>
  )
})`
  width: 100%;
`

AnimatedSwitch.defaultProps = {
  animationTime: 600,
}

AnimatedSwitch.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  animationTime: PropTypes.number,
}
