import { axiosJWT } from 'modules/axios'
import { CONFIG } from './environments'

export function fetchIBAN(iban) {
  // if (process.env.REACT_APP_ENV === 'production') {
  return axiosJWT({
    method: 'GET',
    url: `${CONFIG.api.banks}/${iban}`,
  }).then(resp => resp.data)
  // }

  // return Promise.resolve({
  //   iban,
  //   name: 'TEST BANK',
  //   office: {
  //     address: 'Bank Street',
  //     city: 'Madrid',
  //     name: 'A fake Bank',
  //     zipCode: '280015',
  //   }
  // })
}
