import PropTypes from 'prop-types'

import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import { get, head } from 'lodash'

import { formatAddressStr } from 'modules/vista-360/helpers/formats.helper'

import { TextStyles } from 'utils/text'

import ProductLine from './ProductLine'

const useStyles = makeStyles({
  'package-box': {
    letterSpacing: '0.5px',

    '& .package, & .address': {
      fontSize: '12px',
      lineHeight: 'normal',
    },

    '& .package': {
      padding: '0 48px',
    },

    '& .address': {
      padding: '6px 48px',
    },
  },
})

const PackageBox = ({ packageItem, selectedLine, selectedOrder, onClickLine }) => {
  const classes = useStyles()

  const address = get(packageItem, 'installationAddressFixedLine.medium')
  const products = get(packageItem, 'products')

  const getPackageTitle = product => {
    const commercialName = get(product, 'description')
    const lineType = head(get(product, 'lines'))
    return get(lineType, 'type') === 'mobile_fixed_line'
      ? `${commercialName} (${get(product, 'msisdn')})`
      : commercialName
  }

  return (
    <Box display="flex" className={classes['package-box']}>
      {products &&
        products.map(product => (
          <Box boxSizing="border-box" width="100%" key={product.id}>
            <Typography
              {...TextStyles.tagM({
                className: 'package',
                component: 'div',
                style: {
                  textAlign: null,
                },
              })}
              data-hook={`package-box-${selectedLine}`}>
              {getPackageTitle(product)}
            </Typography>

            {address && (
              <Typography
                {...TextStyles.tagM({
                  className: 'address',
                  component: 'div',
                  style: {
                    textAlign: null,
                  },
                })}>
                {formatAddressStr(address)}
              </Typography>
            )}

            <Box component="ul" p="14px 0" m="0">
              {product.lines &&
                product.lines.map(line => (
                  <ProductLine
                    key={line.id}
                    lineItem={line}
                    orderId={packageItem.orderId}
                    selectedLine={selectedLine}
                    selectedOrder={selectedOrder}
                    onClick={onClickLine}
                  />
                ))}
            </Box>

            {get(product, 'multisimData') &&
              get(product, 'multisimData').map(multisimDataItem => (
                <Box boxSizing="border-box" width="100%" key={multisimDataItem.id}>
                  <Box component="div" p="0px 48px 0px 35px" m="0">
                    <Typography
                      className="package light bold"
                      mt={2}
                      component="div"
                      variant="caption"
                      data-hook={`package-box-${selectedLine}`}>
                      {getPackageTitle(multisimDataItem)}
                    </Typography>
                  </Box>

                  <Box component="ul" p="5px 0" m="0">
                    {multisimDataItem.lines &&
                      multisimDataItem.lines.map(line => (
                        <ProductLine
                          key={line.id}
                          lineItem={line}
                          orderId={multisimDataItem.orderId}
                          selectedLine={selectedLine}
                          selectedOrder={selectedOrder}
                          onClick={onClickLine}
                          isMultisim
                        />
                      ))}
                  </Box>
                </Box>
              ))}
          </Box>
        ))}
    </Box>
  )
}

PackageBox.propTypes = {
  packageItem: PropTypes.shape({
    id: PropTypes.string,
    orderId: PropTypes.string,
    description: PropTypes.string,
    products: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        description: PropTypes.string,
        lines: PropTypes.arrayOf(PropTypes.object),
      }),
    ),
  }),
  selectedLine: PropTypes.string,
  selectedOrder: PropTypes.string,
  onClickLine: PropTypes.func,
}

export default PackageBox
