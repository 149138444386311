import {
  FETCH_ENERGY_CONTRACTS,
  FETCH_ENERGY_CONTRACTS_SUCCESS,
  FETCH_ENERGY_CONTRACTS_FAILED,
} from './energy.actions'

const initialState = {
  loading: false,
  error: null,
  data: null,
}

export const reducer = (state = initialState, action) => {
  const { payload } = action

  switch (action.type) {
    case FETCH_ENERGY_CONTRACTS:
      return {
        ...state,
        data: null,
        error: null,
        loading: true,
      }
    case FETCH_ENERGY_CONTRACTS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: payload.data,
      }
    case FETCH_ENERGY_CONTRACTS_FAILED:
      return {
        ...state,
        loading: false,
        error: payload.error,
      }

    default:
      return state
  }
}
