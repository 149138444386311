import { axiosJWT } from 'modules/axios'

import { APP_CONFIG } from 'services/app-config'

const BASE_URL_IS_MULTISIM_ALLOWED = `${APP_CONFIG.sales_cluster}/v1/tariffs/multisimEligibility`

export function fetchMultiSimAllowed(subscriptionId, poid) {
  return axiosJWT({
    method: 'GET',
    url: BASE_URL_IS_MULTISIM_ALLOWED,
    params: {
      subscriptionId,
      poid,
    },
  }).then(resp => resp.data)
}
