import PropTypes from 'prop-types'
import { get } from 'lodash'

import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import { Modal } from 'components/ui'
import { TextStyles } from 'utils/index'

const configModal = {
  notFound: {
    img: '/assets/not_found.svg',
    alt: 'Cliente no encontrado',
    title: 'No se ha encontrado ningún cliente con el dato de búsqueda.',
    body: 'Por favor, inténtalo de nuevo.',
  },
  unknown: {
    img: '/assets/error.svg',
    alt: 'Error en backend',
    title: 'Lo sentimos, ha ocurrido un error durante tu búsqueda.',
    body: 'Por favor, inténtalo de nuevo.',
  },
}

const useStyles = makeStyles(theme => ({
  'modal-error': {
    padding: '30px 70px',
    lineHeight: '1.2em',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: theme.palette.global.gray_dark,

    '& h1': {
      padding: '10px 0',
    },

    '& span': {
      fontWeight: 'normal',
    },
  },
}))

const ModalError = ({ errorType, className }) => {
  const classes = useStyles()
  const modal = get(configModal, errorType)

  return (
    <Modal className={className} type="alert" hasCloseBtn={false} overlay={false} isOpen>
      <div className={classes['modal-error']} data-hook={`error-${errorType}`}>
        <img alt={modal.alt} src={modal.img} />
        <Typography
          {...TextStyles.title1Dark({
            component: 'h1',
          })}
          data-hook="order-modal-error">
          {modal.title}
        </Typography>
        <Typography
          {...TextStyles.title2Dark({
            component: 'span',
          })}>
          {modal.body}
        </Typography>
      </div>
    </Modal>
  )
}

ModalError.propTypes = {
  className: PropTypes.string,
  errorType: PropTypes.oneOf(['notFound', 'unknown']).isRequired,
}

export default ModalError
