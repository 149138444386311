import { useEffect } from 'react'
import { connect, useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { Box } from '@material-ui/core'
import { Button } from 'components/ui'

import { getPondInitAction, selectPond } from 'modules/pond'
import { selectEnableOnflyDiscounts } from 'services/feature-flag/selectors'
import { selectDiscountsOrderButtonData } from './selectors'

const Small = styled.small`
  margin-top: 10px;
  max-width: 200px;
  text-align: center;
  display: block;
  color: red;
`

function DiscountsJourneyButton(props) {
  const { canApplyDiscounts, isCustomerTokenError, onClick } = props
  const dispatch = useDispatch()

  const enableDiscounts = useSelector(selectEnableOnflyDiscounts)
  const pond = useSelector(selectPond)
  const isValidPond = pond && !pond.reached

  useEffect(() => {
    if (props.orderId) {
      dispatch(getPondInitAction(props.orderId))
    }
  }, [props.orderId])

  return (
    enableDiscounts && 
    <Box
      display="flex"
      padding="20px 0 0 20px"
      flexDirection="column"
      justifyContent="center"
      alignItems="center">
      <Button
        className="orderActions__applyDiscount"
        onClick={onClick}
        data-hook="btnApplyDiscount">
        Aplicar descuento
      </Button>
      {!canApplyDiscounts ? <Small>No estás autorizado</Small> : null}
      {isCustomerTokenError ? <Small>Datos de usuario no encontrados</Small> : null}
      {!isValidPond && !pond.loading ? <Small>Punto de no descuento alcanzado</Small> : null}
    </Box>
  )
}

const mapStateToProps = store => ({ ...selectDiscountsOrderButtonData(store) })

export const DiscountsOrderButton = connect(mapStateToProps)(DiscountsJourneyButton)

DiscountsJourneyButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  orderId: PropTypes.string.isRequired,
  canApplyDiscounts: PropTypes.bool,
  isCustomerTokenError: PropTypes.bool,
}

DiscountsJourneyButton.defaultProps = {
  canApplyDiscounts: null,
}
