import { useSelector } from 'react-redux'

import PropTypes from 'prop-types'
import { parseISO, format } from 'date-fns'

import { Typography, Divider, Box, Icon } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { TextStyles } from 'utils/text'
import { ICONS } from 'utils/icons'

import { Card } from 'modules/ui'
import { Price, OutlinedDivider, ButtonCard } from 'components/ui'

import {
  formatDataTerminal,
  findTVPermanents,
  getFeesPendingFromPermanent,
  getCancellationCostsLeftFromFinancials,
  checkIsProrratedFinancial,
} from 'services/subscriptions'

import {
  getLighten,
  formatNumber,
  formatTimeUnit,
  TIME_UNITS,
  formatRange,
  deviceIconType,
} from 'utils'
import { getDurationFromRange } from 'utils/durations'

import { get, head, isNil } from 'lodash'
import LineItemCard from 'components/packages/ui/card360/LineItemCard'
import {
  SVA_HOMEGO_CODE,
  SVA_SMARTHOME_CODE,
  SVA_AGILE_TV_PREMIUM,
} from 'modules/offers-configuration/constants'
import {
  getFormatPermanentDateRange,
  getTextCalculatedStayTime,
  getTextTotalStayTime,
} from 'modules/SVAs/helpers/helpers'
import { selectChangeAgileTv } from 'modules/agile-tv/change-agiletv/store/change-agiletv.selectors'
import { selectEnabledBtcAgileTv } from 'services/feature-flag/selectors'
import { DurationMonthsDays } from '../PermanencyDetail/DurationMonthsDays'

const PADDING_ELEMENT = '9px 24px'

const useStyles = makeStyles(theme => ({
  'text-link': {
    color: theme.palette.brand.medium,
    padding: PADDING_ELEMENT,
    display: 'inline-flex',
  },
  'mobile-placeholder': {
    padding: PADDING_ELEMENT,

    '& .placeholder-wrapper': {
      width: '128px',
      height: '169px',
      display: 'flex',
      backgroundColor: '#F5F5F5',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '5px',
      border: 'solid #DBDBDB 1px',

      '& .material-icons': {
        color: '#C4C4C4',
        fontSize: '66px',
      },
    },
  },

  'display-placeholder': {
    padding: '9px 0px 9px 24px',

    '& .placeholder-wrapper': {
      width: '128px',
      height: '88px',
      display: 'flex',
      backgroundColor: '#F5F5F5',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '5px',
      border: 'solid #DBDBDB 1px',

      '& .material-icons': {
        color: '#C4C4C4',
        fontSize: '66px',
      },
    },
  },
  'terminal-and-services-card': {
    '& .block-1': {
      width: '152px',
    },

    '& .block-2': {
      width: '400px',
      paddingTop: '12px',

      '& .secondary-text': {
        fontSize: '16px',
      },
    },

    '& .block-3': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'start',
      width: '470px',

      '& .price': {
        padding: '0 24px',
        width: '204px',
      },
    },

    '& .VAS .block-3 .price .highLightFrame': {
      marginLeft: '-15px',
      height: '150%',
      marginTop: '-15px',
      width: '90%',
    },

    '& .divider': {
      height: '100%',
    },

    '& .duration-months-days': {
      display: 'inline',
      fontSize: '16px',

      '& h4, & h6': {
        fontSize: '16px',
        fontWeight: 500,
        marginRight: 0,
        display: 'inline',
      },
    },

    '& .xs-text': {
      fontSize: '11px',
      marginTop: '0px',
      marginBottom: '12px',
    },

    '& .wrapper': {
      display: 'flex',
    },

    '&& .card-content': {
      position: 'relative',
    },

    '& .tetra-prices-container': {
      display: 'flex',
      justifyContent: 'flex-start',
      flexWrap: 'wrap',
      backgroundColor: 'transparent',
      padding: '0 0px 17px 0px',
      width: '500px',

      '& .highLightFrame': {
        marginLeft: '-15px',
        marginTop: '-15px',
      },

      '& .highLightable': {
        padding: 0,
      },

      '& > *': {
        padding: '0 24px',
      },

      '& .price': {
        width: '180px',
        padding: '16px 0',

        '&:first-child, &:nth-child(2)': {
          borderBottom: 0,
        },

        '&:first-child': {
          marginLeft: '24px',
          paddingRight: '24px',
          borderRight: `1px solid ${getLighten(theme.palette.global.gray_dark, 0.8)}`,
          borderBottom: `1px solid ${getLighten(theme.palette.global.gray_dark, 0.8)}`,
        },

        '&:nth-child(2)': {
          paddingLeft: '24px',
          paddingRight: '24px',
          borderBottom: `1px solid ${getLighten(theme.palette.global.gray_dark, 0.8)}`,
        },

        '&:nth-child(3)': {
          marginLeft: '24px',
          paddingRight: '24px',
          borderRight: `1px solid ${getLighten(theme.palette.global.gray_dark, 0.8)}`,
        },

        '&:nth-child(4)': {
          paddingLeft: '24px',
          paddingRight: '24px',
        },

        '& strong': {
          fontSize: '24px',
          fontWeight: 800,
        },
      },
    },
  },
  'button-change-service': {
    fontSize: '12px',
  },
}))

const MobilePlaceholder = ({ classes }) => (
  <div className={classes['mobile-placeholder']}>
    <div className="placeholder-wrapper">
      <Icon className="icon">{ICONS.mobile}</Icon>
    </div>
  </div>
)

MobilePlaceholder.propTypes = {
  classes: PropTypes.object.isRequired,
}

const DisplayPlaceholder = ({ classes, device }) => (
  <div className={classes['display-placeholder']}>
    <div className="placeholder-wrapper">
      <Icon className="icon">{ICONS[device]}</Icon>
    </div>
  </div>
)

DisplayPlaceholder.propTypes = {
  classes: PropTypes.object.isRequired,
  device: PropTypes.string.isRequired,
}

const deviceCardComponent = (financialTerminal, permanencyTerminal, highLigthedUI, classes) => {
  const terminalFormatted = formatDataTerminal(financialTerminal)
  const isProrratedFinancial = checkIsProrratedFinancial(financialTerminal)

  const startDate = permanencyTerminal
    ? parseISO(get(permanencyTerminal, 'validFor.startDateTime'))
    : null
  const endDate = permanencyTerminal
    ? parseISO(get(permanencyTerminal, 'validFor.endDateTime'))
    : null
  const financialStartDate =
    financialTerminal && parseISO(get(financialTerminal.validFor, 'startDateTime'))
  const financialEndDate =
    financialTerminal && parseISO(get(financialTerminal.validFor, 'endDateTime'))
  const financialTotalMonths = financialTerminal && get(financialTerminal, 'length')

  const totalMonths = permanencyTerminal?.length
  const { terminalResidual } = terminalFormatted
  const hasTerminalResidual = !isNil(terminalResidual)

  const lastItem = true
  const today = Date.now()

  return (
    <div className="terminals" key={financialTerminal.id}>
      <Typography
        {...TextStyles.subtitle2Link({
          className: classes['text-link'],
        })}>
        <span>{terminalFormatted.terminalName}</span>
      </Typography>
      <div className="wrapper">
        <div className="block-1">
          {deviceIconType(get(terminalFormatted, 'terminalName')) !== 'mobile' ? (
            <DisplayPlaceholder
              classes={classes}
              device={deviceIconType(get(terminalFormatted, 'terminalName'))}
            />
          ) : (
            <MobilePlaceholder classes={classes} />
          )}
        </div>
        {permanencyTerminal && !isProrratedFinancial ? (
          <div className="block-2">
            <LineItemCard
              primaryText="Permanencia"
              secondaryText={
                <>
                  <DurationMonthsDays
                    className="duration-months-days"
                    duration={getDurationFromRange(endDate, today)}
                  />
                  {` / de ${totalMonths} ${formatTimeUnit(totalMonths, TIME_UNITS.MONTHS.id)}`}
                </>
              }
            />
            <Typography
              {...TextStyles.labelDark({
                className: 'xs-text p-y-0 p-x-24',
              })}>
              ({formatRange(startDate, endDate)})
            </Typography>
          </div>
        ) : (
          <div className="block-2">
            <LineItemCard
              primaryText={isProrratedFinancial ? 'Pago final prorrateado' : 'Cuotas pendientes'}
              secondaryText={
                <>
                  {` ${get(
                    financialTerminal,
                    'residualLength',
                  )} meses / de ${financialTotalMonths} ${formatTimeUnit(
                    financialTotalMonths,
                    TIME_UNITS.MONTHS.id,
                  )}`}
                </>
              }
            />
            <Typography
              {...TextStyles.labelDark({
                className: 'xs-text p-y-0 p-x-24',
              })}>
              ({formatRange(financialStartDate, financialEndDate)})
            </Typography>
          </div>
        )}
        <OutlinedDivider orientation="vertical" />
        <div className="block-3">
          <div
            className={`tetra-prices-container ${
              !hasTerminalResidual && 'tetra-prices-container-without-border'
            }`}>
            {terminalFormatted.initialPayment && (
              <Price
                title="Pago inicial"
                monthly
                price={formatNumber(terminalFormatted.initialPayment)}
                aux="Sin descuentos"
              />
            )}
            {lastItem && (
              <div
                className={`highLightable ${get(highLigthedUI, 'terminalFee') && 'active'}`}
                data-hook={get(highLigthedUI, 'terminalFee') && 'terminal-fee-lightframe'}>
                <Price
                  title="Precio mes"
                  monthly
                  price={formatNumber(terminalFormatted.terminalFee)}
                />
              </div>
            )}
            {lastItem && hasTerminalResidual && (
              <Price title="Pago final" price={formatNumber(terminalResidual)} />
            )}
            <Price
              title="Si cancela hoy"
              price={formatNumber(getCancellationCostsLeftFromFinancials(financialTerminal))}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export const TerminalsAndServicesCard = ({
  subscription,
  terminals,
  VAS,
  highLigthedUI,
  setIsOpenChangeAgileTv,
  setPsIdActualAgileTv,
}) => {
  const classes = useStyles()

  const changeAgileTvFlag = useSelector(selectChangeAgileTv)
  const enabledBtcAgileTv = useSelector(selectEnabledBtcAgileTv)

  const today = Date.now()

  return (
    <Card
      title="Dispositivos"
      withShadow
      className={classes['terminal-and-services-card']}
      id="Devices">
      {terminals.map(terminal => {
        const { finance: financialTerminal, permanency: permanencyTerminal } = terminal
        return deviceCardComponent(financialTerminal, permanencyTerminal, highLigthedUI, classes)
      })}

      {!!VAS.length && !!terminals.length && (
        <Box p={PADDING_ELEMENT}>
          <OutlinedDivider />
        </Box>
      )}

      {VAS.filter(service => service.contractTypeId !== SVA_SMARTHOME_CODE).map(service => {
        const isHomeGo = service.contractTypeId?.includes(SVA_HOMEGO_CODE)
        const tvPermanent = head(findTVPermanents(subscription))
        const hasTvPermanent = tvPermanent && !isHomeGo
        const startDate = hasTvPermanent
          ? parseISO(get(tvPermanent, 'validFor.startDateTime'))
          : parseISO(get(service, 'validFrom'))
        const endDate = hasTvPermanent
          ? parseISO(get(tvPermanent, 'validFor.endDateTime'))
          : parseISO(get(service, 'validTo'))
        const totalMonths = tvPermanent ? tvPermanent.length : 0
        const emailAgileTv = get(service, 'email', '')
        const contractTypeId = get(service, 'contractTypeId')
        const isAgileTv = /^AGILETV/.test(contractTypeId)

        const homeGoPenalty = isHomeGo && service.stayStatus?.stayCost

        return (
          <div className="VAS">
            <Typography
              {...TextStyles.subtitle2Link({
                className: classes['text-link'],
              })}>
              <span>{get(service, 'contractTypeName') || 'Agile TV'}</span>
              {changeAgileTvFlag && isAgileTv && (
                <Box padding="0px 15px">
                  <ButtonCard
                    className={classes['button-change-service']}
                    type="primary"
                    label="Cambiar servicio TV"
                    disabled={contractTypeId !== SVA_AGILE_TV_PREMIUM && !enabledBtcAgileTv}
                    onClick={() => {
                      setIsOpenChangeAgileTv(true)
                      setPsIdActualAgileTv(contractTypeId)
                    }}
                  />
                </Box>
              )}
            </Typography>
            <div className="wrapper">
              <div className="block-1">
                <DisplayPlaceholder classes={classes} device="tv" />
              </div>
              <div className="block-2">
                <LineItemCard
                  primaryText="Fecha de contratación"
                  secondaryText={format(new Date(startDate), 'dd/MM/yyyy')}
                />
                {emailAgileTv && <LineItemCard primaryText="Email" secondaryText={emailAgileTv} />}
                {hasTvPermanent && (
                  <>
                    <LineItemCard
                      primaryText="Permanencia"
                      secondaryText={
                        <>
                          <DurationMonthsDays
                            className="duration-months-days"
                            duration={getDurationFromRange(endDate, today)}
                          />
                          {` / de ${totalMonths} ${formatTimeUnit(
                            totalMonths,
                            TIME_UNITS.MONTHS.id,
                          )}`}
                        </>
                      }
                    />
                    <Typography
                      {...TextStyles.labelDark({
                        className: 'xs-text p-y-0 p-x-24',
                      })}>
                      ({formatRange(startDate, endDate)})
                    </Typography>
                  </>
                )}
                {isHomeGo && service?.stayStatus && (
                  <>
                    <LineItemCard
                      primaryText="Permanencia"
                      secondaryText={`${getTextCalculatedStayTime(
                        service,
                      )} / ${getTextTotalStayTime(service)}`}
                    />
                    <Typography
                      {...TextStyles.labelDark({
                        className: 'xs-text p-y-0 p-x-24',
                      })}>
                      ({getFormatPermanentDateRange(service)})
                    </Typography>
                  </>
                )}
              </div>
              <OutlinedDivider orientation="vertical" />

              {(!isHomeGo || !!service.monthlyFee || !!homeGoPenalty) && (
                <div className="block-3">
                  <div className={`highLightable ${get(highLigthedUI, 'VASFee') && 'active'}`}>
                    <Price title="Precio mes" monthly price={formatNumber(service.monthlyFee)} />
                  </div>

                  <Divider orientation="vertical" className="divider" />
                  <Price
                    title="Si cancela hoy"
                    price={formatNumber(
                      isHomeGo ? homeGoPenalty : getFeesPendingFromPermanent(tvPermanent),
                    )}
                  />
                </div>
              )}
            </div>
          </div>
        )
      })}
    </Card>
  )
}
TerminalsAndServicesCard.propTypes = {
  subscription: PropTypes.object,
  terminals: PropTypes.array,
  VAS: PropTypes.array,
  highLigthedUI: PropTypes.object,
  setIsOpenChangeAgileTv: PropTypes.func.isRequired,
  setPsIdActualAgileTv: PropTypes.func.isRequired,
}
