/* eslint-disable react/prop-types */
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { HTMLTemplate } from 'modules/ui/components/HTMLTemplate/HTMLTemplate'

import { Step } from './Step'
import TerminalShippingStep from './TerminalShippingStep'
import { TerminalFinancial } from './TerminalFinancial'
import NextStepsADSL from './NextStepsADSL'
import MobileActivation from './MobileActivation'
import { NextStepsElFijo } from './NextStepsElFijo'

export const nextStepsDataHooks = {
  saleProcess: {
    title: 'sale_process.title',
    description: 'sale_process.description',
  },
  confirmationEmail: {
    title: 'confirmation_email.title',
    description: 'confirmation_email.description',
  },
  activation: {
    title: 'activation.title',
    description: 'activation.description',
  },
  delivery_info: {
    title: 'delivery_info.title',
    description: 'delivery_info.description',
  },
  ftth_installation: {
    title: 'ftth_installation.title',
    description_2p: 'ftth_installation.description_2p',
    description: 'ftth_installation.description',
  },
  ftth_no_installation: {
    title: 'ftth_no_installation.title',
    description: 'ftth_no_installation.description',
  },
  sva: {
    description: 'sva.description',
  },
  sva_no_installation: {
    description: 'sva_no_installation.description',
  },
  ftth_yoigo_installation: {
    title: 'ftth_yoigo_installation.title',
    description: 'ftth_yoigo_installation.description',
  },
  homego_appointment: {
    title: 'homego_appointment.title',
    description: 'homego_appointment.description',
  },
  homego_alarm_activation: {
    title: 'homego_alarm_activation.title',
    description: 'homego_alarm_activation.description',
  },
  technician_neba: {
    title: 'technician_neba.title',
  },
}

const TelefonicaTechnicianNEBAStep = ({ nextStepsTexts, is2p }) => {
  const description = is2p ? 'description_2p' : 'description'
  return (
    <Step
      title={nextStepsTexts.technician_neba.title}
      description={
        <HTMLTemplate
          html={nextStepsTexts.technician_neba[description]}
          data-hook={nextStepsDataHooks.technician_neba.title}
        />
      }
    />
  )
}

const YoigoFTTHStep = ({ hasAgileTV, nextStepsTexts }) => (
  <Step
    title={nextStepsTexts.ftth_yoigo_installation.title}
    description={
      <span>
        {
          <HTMLTemplate
            html={nextStepsTexts.ftth_yoigo_installation.description}
            data-hook={nextStepsDataHooks.ftth_yoigo_installation.description}
          />
        }
        {hasAgileTV && (
          <HTMLTemplate
            html={nextStepsTexts.sva.description}
            data-hook={nextStepsDataHooks.sva.description}
          />
        )}
      </span>
    }
    data-hook={nextStepsDataHooks.ftth_yoigo_installation.title}
  />
)

const DeliveryStep = ({ isLastStep, nextStepsTexts, isPos }) => {
  let key
  let dataHook

  if (isPos) {
    key = 'activation'
    dataHook = nextStepsDataHooks.activation
  } else {
    key = 'delivery_info'
    dataHook = nextStepsDataHooks.delivery_info
  }

  return (
    <Step
      isLastStep={isLastStep}
      title={nextStepsTexts[key].title}
      description={
        <HTMLTemplate html={nextStepsTexts[key].description} data-hook={dataHook.description} />
      }
      data-hook={dataHook.title}
    />
  )
}

const FTTHStep = ({ hasAgileTV, nextStepsTexts, is2p }) => {
  let description
  let dataHook

  if (is2p) {
    description = 'description_2p'
    dataHook = nextStepsDataHooks.ftth_installation.description_2p
  } else {
    description = 'description'
    dataHook = nextStepsDataHooks.ftth_installation.description
  }

  return (
    <span>
      <HTMLTemplate html={nextStepsTexts.ftth_installation[description]} data-hook={dataHook} />
      {hasAgileTV && (
        <HTMLTemplate
          html={nextStepsTexts.sva.description}
          data-hook={nextStepsDataHooks.sva.description}
        />
      )}
    </span>
  )
}

const fiberTypeEnum = {
  DIRECT: 'direct',
  ADSL: 'adsl',
  INDIRECT: 'indirect',
}

const useStyles = makeStyles({
  'next-steps': {
    position: 'relative',
    padding: '0 50px',
  },
})

export function NextSteps({
  fiberType,
  isFixedPortability,
  isPortabilityFromFtth,
  financedTerminals,
  singlePaymentTerminals,
  isCompany,
  hasTerminals,
  installsMM,
  isInstallationRequired,
  agileTV: { hasAgileTV },
  hasIUA,
  nextStepsTexts,
  isElFijo,
  isPos,
  hasVoiceBox,
  is2p,
  selectedHomeGoAlarm,
}) {
  const classes = useStyles()

  const {
    installation_instructions,
    instructions_voice_box,
    asistance,
    teleasist_voice_box,
  } = nextStepsTexts
  const instructionsTexts = !hasVoiceBox ? installation_instructions : instructions_voice_box
  const asistanceTexts = !hasVoiceBox ? asistance : teleasist_voice_box

  return (
    <div className={classes['next-steps']}>
      {/* Common Text */}
      <Step
        title={nextStepsTexts.sale_process.title}
        description={
          <HTMLTemplate
            html={
              selectedHomeGoAlarm
                ? nextStepsTexts.sale_process_homego.description
                : nextStepsTexts.sale_process.description
            }
            data-hook={nextStepsDataHooks.saleProcess.description}
          />
        }
        isActive
        data-hook={nextStepsDataHooks.saleProcess.title}
      />
      <Step
        title={nextStepsTexts.confirmation_email.title}
        description={
          <HTMLTemplate
            html={
              selectedHomeGoAlarm
                ? nextStepsTexts.confirmation_email_homego.description
                : nextStepsTexts.confirmation_email.description
            }
            data-hook={nextStepsDataHooks.confirmationEmail.description}
          />
        }
        data-hook={nextStepsDataHooks.confirmationEmail.title}
      />
      {!fiberType && (
        <DeliveryStep
          isLastStep={!fiberType && !hasTerminals && !isElFijo}
          isPos={isPos}
          nextStepsTexts={nextStepsTexts}
        />
      )}
      {/* Convergent Direct Fiber */
      isInstallationRequired && (fiberType === fiberTypeEnum.DIRECT || installsMM) && (
        <Step
          title={nextStepsTexts.ftth_installation.title}
          description={
            <FTTHStep hasAgileTV={hasAgileTV} nextStepsTexts={nextStepsTexts} is2p={is2p} />
          }
          data-hook={nextStepsDataHooks.ftth_installation.title}
        />
      )}
      {/* Convergent ADSL */
      fiberType === fiberTypeEnum.ADSL && !installsMM && (
        <NextStepsADSL
          nextStepsTexts={nextStepsTexts}
          portability={isFixedPortability}
          isPortabilityFromFtth={isPortabilityFromFtth}
          is2p={is2p}
        />
      )}
      {/* Convergent Indirect Fiber */
      isInstallationRequired && fiberType === fiberTypeEnum.INDIRECT && !installsMM && !hasIUA && (
        <>
          <TelefonicaTechnicianNEBAStep nextStepsTexts={nextStepsTexts} is2p={is2p} />
          <YoigoFTTHStep hasAgileTV={hasAgileTV} nextStepsTexts={nextStepsTexts} />
        </>
      )}
      {!isInstallationRequired &&
        (fiberType === fiberTypeEnum.INDIRECT || fiberType === fiberTypeEnum.DIRECT) && (
          <Step
            title={nextStepsTexts.ftth_installation.title}
            description={
              <>
                <HTMLTemplate
                  html={nextStepsTexts.ftth_no_installation.description}
                  data-hook={nextStepsDataHooks.ftth_no_installation.description}
                />
                {hasAgileTV && (
                  <HTMLTemplate
                    html={nextStepsTexts.sva_no_installation.description}
                    data-hook={nextStepsDataHooks.sva_no_installation.description}
                  />
                )}
              </>
            }
            data-hook={nextStepsDataHooks.ftth_installation.title}
          />
        )}
      {/* Common texts */}
      {isElFijo && (
        <NextStepsElFijo
          installationTitle={instructionsTexts.title}
          installationDescription={instructionsTexts.description}
          teleasistTitle={asistanceTexts.title}
          teleasistDescription={asistanceTexts.description}
        />
      )}
      {hasTerminals && (
        <TerminalFinancial
          nextStepsTexts={nextStepsTexts}
          financedTerminals={financedTerminals}
          singlePaymentTerminals={singlePaymentTerminals}
          isCompany={isCompany}
        />
      )}
      {!is2p && (
        <>
          {fiberType && (
            <MobileActivation
              title={nextStepsTexts.mobile_activation.title}
              description={
                isInstallationRequired || fiberType === fiberTypeEnum.ADSL
                  ? nextStepsTexts.mobile_activation.description
                  : nextStepsTexts.mobile_activation_no_install.description
              }
              isLastStep={!hasTerminals && !selectedHomeGoAlarm}
            />
          )}
          <TerminalShippingStep
            nextStepsTexts={nextStepsTexts}
            financedTerminals={financedTerminals}
            hasTerminals={hasTerminals}
            isLastStep={!selectedHomeGoAlarm}
          />
        </>
      )}
      {selectedHomeGoAlarm && (
        <>
          <Step
            title={nextStepsTexts.homego_appointment.title}
            description={
              <HTMLTemplate
                html={nextStepsTexts.homego_appointment.description}
                data-hook={nextStepsDataHooks.homego_appointment.description}
              />
            }
            data-hook={nextStepsDataHooks.homego_appointment.title}
          />

          <Step
            title={nextStepsTexts.homego_alarm_activation.title}
            description={
              <HTMLTemplate
                html={nextStepsTexts.homego_alarm_activation.description}
                data-hook={nextStepsDataHooks.homego_alarm_activation.description}
              />
            }
            data-hook={nextStepsDataHooks.homego_alarm_activation.title}
            isLastStep
          />
        </>
      )}
    </div>
  )
}

NextSteps.propTypes = {
  nextStepsTexts: PropTypes.object.isRequired,
  isElFijo: PropTypes.bool,
  fiberType: PropTypes.string.isRequired,
  isFixedPortability: PropTypes.bool.isRequired,
  isPortabilityFromFtth: PropTypes.bool.isRequired,
  financedTerminals: PropTypes.array,
  isCompany: PropTypes.bool,
  hasTerminals: PropTypes.bool,
  agileTV: PropTypes.object.isRequired,
  installsMM: PropTypes.bool.isRequired,
  isInstallationRequired: PropTypes.bool,
  hasIUA: PropTypes.bool,
  isPos: PropTypes.bool,
  hasVoiceBox: PropTypes.bool,
  is2p: PropTypes.bool,
  singlePaymentTerminals: PropTypes.array,
}

NextSteps.defaultProps = {
  financedTerminals: [],
}
