import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { get, head, isEmpty, isNil, some } from 'lodash'
import styled from 'styled-components'

import { Typography } from '@material-ui/core'
import { TextStyles } from 'utils/text'

import { isElFijoTariff } from 'modules/tariffs/helpers'
import {
  findFixedProduct,
  findTVPermanents,
  findMobilePermanents,
  findRouterPermanents,
  findOtherServicesPermanents,
  findDiscountPermanents,
  findBonusPermanents,
  getTerminalsFinancials,
  getSubscriptionTariffId,
} from 'services/subscriptions'

import { getCommercialName, selectTariffsApigeeById } from 'modules/tariffs/store-apigee'

import { PermanencyPending } from './PermanencyPending'
import { PermanencyTerminals } from './PermanencyTerminals'
import { PermanencyRouter } from './PermanencyRouter'
import { PermanencyAgileTv } from './PermanencyAgileTv'
import { PermanencyOtherServices } from './PermanencyOtherServices'
import { PermanencyTariffFromTerminal } from './PermanencyTariffFromTerminal'
import { PermanencyDiscount } from './PermanencyDiscount'
import { PermanencyBonus } from './PermanencyBonus'

export const PermanencyDetail = styled(({ className, subscription }) => {
  const discountPermanent = findDiscountPermanents(subscription)
  const bonusPermanent = findBonusPermanents(subscription)
  const tariff = useSelector(selectTariffsApigeeById(getSubscriptionTariffId(subscription)))
  const isElFijo = isElFijoTariff(get(subscription, 'type'))
  const productFixed = findFixedProduct(subscription)
  const permanentRouter = head(findRouterPermanents(subscription))
  const otherServicesPermanent = head(findOtherServicesPermanents(subscription))
  const tvPermanent = head(findTVPermanents(subscription))
  const mobilePermanent = head(findMobilePermanents(subscription))
  const terminalsFinancials = getTerminalsFinancials(subscription)

  const hasTariffPermanents = some(
    [
      discountPermanent,
      bonusPermanent,
      permanentRouter,
      otherServicesPermanent,
      tvPermanent,
      mobilePermanent,
    ],
    e => !isNil(e) && !isEmpty(e),
  )

  return (
    <div className={className} data-hook="permanency-detail-modal">
      {subscription && <PermanencyPending className="m-y-16" subscription={subscription} />}

      {hasTariffPermanents && (
        <Typography
          {...TextStyles.subtitle1Secondary({
            className: 'p-b-8',
          })}>
          {`TARIFA (${getCommercialName(tariff)})`}
        </Typography>
      )}

      {!isEmpty(discountPermanent) && (
        <PermanencyDiscount className="p-b-24 p-x-24" permanents={discountPermanent} />
      )}

      {!isEmpty(bonusPermanent) && (
        <PermanencyBonus className="p-b-24 p-x-24" permanents={bonusPermanent} />
      )}

      {permanentRouter && !isElFijo && (
        <PermanencyRouter className="p-b-24 p-x-24" subscription={subscription} />
      )}

      {productFixed && tvPermanent && (
        <PermanencyAgileTv className="p-b-24 p-x-24" subscription={subscription} />
      )}

      {otherServicesPermanent && (
        <PermanencyOtherServices className="p-b-24 p-x-24" subscription={subscription} />
      )}

      {mobilePermanent && (
        <PermanencyTariffFromTerminal className="p-b-24 p-x-24" subscription={subscription} />
      )}

      {!isEmpty(terminalsFinancials) && (
        <PermanencyTerminals className="p-t-16" subscription={subscription} />
      )}
    </div>
  )
})``

PermanencyDetail.propTypes = {
  className: PropTypes.string,
}
