import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { get } from 'lodash'

import { Icon } from '@material-ui/core'

const adviceType = {
  info: {
    backgroundIcon: 'palette.brand.action_primary',
    backgroundText: 'palette.bluePalette.blue_50',
  },
  warning: {
    backgroundIcon: 'palette.brand.attention',
    backgroundText: 'palette.orangePalette.orange_50',
  },
  error: {
    backgroundIcon: 'palette.brand.alert',
    backgroundText: 'palette.redPalette.red_50',
  },
  success: {
    backgroundIcon: 'palette.greenPalette.green_500',
    backgroundText: 'palette.greenPalette.green_50',
  },
  default: {
    backgroundText: 'palette.global.gray_light',
  },
}

const useStyles = makeStyles(theme => ({
  advice: {
    display: 'flex',
    width: props => (props.block ? '100%' : 'auto'),
    background: theme.palette.global.gray_light,
    'line-height': '22px',
    'border-top-right-radius': '4px',
    'border-bottom-right-radius': '4px',
    'margin-bottom': '10px',
  },
  'advice-icon': {
    display: 'flex',
    'align-items': 'center',
    'justify-content': 'center',
    width: '32px',
    'border-top-left-radius': '4px',
    'border-bottom-left-radius': '4px',
    'background-color': props => get(theme, props.backgroundIcon),

    '& .icon': {
      'font-size': '22px',
      color: theme.palette.brand.light,
    },
  },
  'advice-text': {
    display: 'flex',
    'flex-wrap': 'wrap',
    padding: '6px 25px 6px 20px',
    width: props => (props.block ? '100%' : 'auto'),
    'background-color': props => get(theme, props.backgroundText),
    'max-width': props => (props.block ? '100%' : '632px'),
    'justify-content': props => props.justifyContent,
    borderTopRightRadius: '4px',
    borderBottomRightRadius: '4px',
  },
}))

const defaultIcons = {
  error: 'warning',
  default: 'error',
}

export function Advice({ id = '0', type, icon, block, justifyContent, children }) {
  const typeStyle = adviceType[type] || adviceType.default
  const classes = useStyles({ ...typeStyle, block, justifyContent })

  const defaultIcon = defaultIcons[type] || defaultIcons.default
  return (
    <div className={classes.advice} data-hook={`advice-${id}`}>
      <div className={classes['advice-icon']} type={type}>
        <Icon className="icon">{icon || defaultIcon}</Icon>
      </div>
      <div className={`text-wrapper ${classes['advice-text']}`} type={type}>
        {children}
      </div>
    </div>
  )
}

Advice.defaultProps = {
  id: '0',
  type: 'info',
}

Advice.propTypes = {
  id: PropTypes.string,
  type: PropTypes.oneOf(['error', 'info', 'warning', 'success']).isRequired,
  icon: PropTypes.string,
  block: PropTypes.bool,
  justifyContent: PropTypes.string,
  children: PropTypes.node,
}
