import { get, isEmpty } from 'lodash'
import {
  isTerminalPaymentMultiple,
  isTerminalPaymentSingle,
  getTerminalComputedPrices,
} from 'modules/Terminals'
import { formatNumberWithComma, formatStringToNumber } from 'utils'
import { SIGNUP_TYPE_PORTABILITY } from 'services/global-constants'
import { LINE_TYPE } from 'modules/Lines/constants'

import { formatDiscounts } from './NewClientSales.selectors.helpers'

function mapTerminals(main, extra, tax, isPaymentMultiple) {
  const terminals = [main, ...extra].map((line, index) => {
    return index === 0
      ? { lineType: LINE_TYPE.MAIN_MOBILE, ...line.terminal }
      : { lineType: `extraMobileLinesOffers[${index - 1}]`, ...line.terminal }
  })

  const terminalsFinanced = isPaymentMultiple
    ? terminals.filter(isTerminalPaymentMultiple)
    : terminals.filter(isTerminalPaymentSingle)

  return terminalsFinanced.map(terminalFinanced => {
    return {
      ...terminalFinanced.details,
      ...getTerminalComputedPrices(terminalFinanced, tax),
      paymentType: terminalFinanced.paymentType,
      lineType: terminalFinanced.lineType,
    }
  })
}

export function mapTerminalsFinancial(main, extra, tax) {
  return mapTerminals(main, extra, tax, true)
}

export function mapTerminalsSinglePayment(main, extra, tax) {
  return mapTerminals(main, extra, tax, false)
}

export function mapTerminalByLineType(terminals, tax, lineType, paymentType) {
  const terminal = terminals[lineType] || {}
  if (isEmpty(terminal)) return terminal
  return {
    ...terminal,
    ...getTerminalComputedPrices({ paymentType, details: terminal }, tax),
    paymentType,
    lineType,
  }
}

export function mapTerminalsWithKeys(stock, mainMobile, extras) {
  const terminalsWithKeys = {
    stock: { ...get(stock, 'terminal.details', {}) },
    [LINE_TYPE.MAIN_MOBILE]: { ...get(mainMobile, 'terminal.details', {}) },
  }
  extras.forEach((extra, index) => {
    const key = `extraMobileLinesOffers[${index}]`
    terminalsWithKeys[key] = get(extra, 'terminal.details', {})
  })
  return terminalsWithKeys
}

export function getPortabilityNumbers(lines) {
  return lines.reduce(
    (curr, next) =>
      next.signUpType === SIGNUP_TYPE_PORTABILITY && next.lineNumber
        ? [...curr, next.lineNumber]
        : curr,
    [],
  )
}

export function setDiscountItems(tariff, selectedBundles) {
  const formatedPackages = selectedBundles
    ?.filter(bundle => !!bundle.monthlyFeeWithTax)
    ?.map(bundle => ({
      display: [
        { label: 'Bono', value: bundle.name || bundle.commercialInfo.name },
        {
          label: 'Precio',
          value: `${bundle.monthlyFeeWithTax}`,
        },
      ],
      id: bundle.id,
    }))

  const discounts = formatDiscounts(tariff)

  return discounts?.length > 0 ? [...discounts, ...formatedPackages] : formatedPackages || []
}

export function formatPriceWithPackages(savedTariff, selectedPackages) {
  let tariffPrice = savedTariff?.priceWithTax
  const price = get(savedTariff, 'formatedDiscount.price')
  if (price) {
    tariffPrice = formatStringToNumber(price)
  }
  const summedValues = selectedPackages?.reduce((curr, next) => {
    return curr + next.monthlyFeeWithTax
  }, tariffPrice)

  return typeof summedValues !== 'string' ? formatNumberWithComma(summedValues) : summedValues
}
