import {
  FETCH_PORTABILITY_DATES,
  FETCH_PORTABILITY_DATES_ERROR,
  FETCH_PORTABILITY_DATES_SUCCESS,
} from './constants'

const initialState = {
  data: {
    dates: {},
    status: null,
  },
  loading: null,
  error_code: null,
  error_message: null,
}

export const reducer = (state = initialState, action) => {
  const { payload } = action

  switch (action.type) {
    case FETCH_PORTABILITY_DATES:
      return { ...state, loading: true, error_code: null, error_message: null }
    case FETCH_PORTABILITY_DATES_SUCCESS:
      return {
        ...state,
        loading: false,
        data: { dates: payload.dates.data, status: payload.dates.status },
      }
    case FETCH_PORTABILITY_DATES_ERROR:
      return {
        ...state,
        data: {
          dates: {},
          status: null,
        },
        loading: false,
        error_code: payload.error.httpCode,
        error_message: payload.error.info,
      }

    default:
      return state
  }
}
