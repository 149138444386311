import { useState, useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Box, CircularProgress, Typography } from '@material-ui/core'
import { TextStyles } from 'utils/text'
import { Card, PopoverMenu } from 'modules/ui'
import { ButtonCard, Modal, Button, NotificationModal, AlertAdvice } from 'components/ui'
import { get, isEmpty, isNil, head } from 'lodash'

import { selectSfid, useForceSaveAuth } from 'modules/Auth'
import { profiles, permissions, selectCanIDO, selectIsPosOrMasProximo } from 'modules/Permissions'
import {
  validateAgentProfile,
  canIAccessToAdditionalOps,
} from 'modules/vista-360/components/OrderDashboard/OrderDashboard.helpers'
import {
  get2pWelcomeLetterData,
  getElFijoWelcomeLetterData,
} from 'modules/offers-configuration/helpers'
import { ModifyContactPhoneModal } from 'modules/vista-360/components/ModifyContactPhoneModal'

import {
  getSegment,
  selectors as customer360SvcSelectors,
  selectInstallationAddressPackageByMsisdn,
} from 'services/customer-360/selectors'
import {
  SVA_SMARTHOME_CODE,
  ADD_SUBSCRIPTION_OPERATION,
  SVAS_TYPES_CODE_TEXT,
  SVA_HOMEGO_CODE,
} from 'modules/offers-configuration/constants'
import { getAddressStreetType } from 'services/cadence-flow/helpers'
import { getBillingAddressStr } from 'services/customer-new/selectors'

import { BadgeStatusDetail } from 'components'

import { isProCustomer, getProductByOrderIdFromContracts } from 'services/customer-360/helpers'

import { parentOrderIdStr } from 'modules/orders'
import { helpers } from 'services/offers-detail'
import { getCommercialName, getTariffId } from 'modules/tariffs/store-apigee'

import { generateAditionalOperationsLink } from 'modules/vista-360/containers/OrderDashboardContainer/helpers'
import { getDocumentType } from 'modules/CustomerDocument/helpers/index'
import { MainFeesItem } from 'modules/vista-360/components/MainFeesItem/MainFeesItem'
import { FixedDetail } from 'modules/vista-360/components/FixedDetail/FixedDetail'
import { SimDetail } from 'modules/vista-360/components/SimDetail/SimDetail'
import { RouterDetail } from 'modules/vista-360/components/RouterDetail/RouterDetail'
import { InternetDetail } from 'modules/vista-360/components/InternetDetail/InternetDetail'
import { HandsetDetail } from 'modules/vista-360/components/HandsetDetail/HandsetDetail'
import { ProductDivider } from 'modules/vista-360/components/OrderProducts/ProductDivider'

import { selectDeviceRenewalFlag } from 'services/feature-flag/selectors'

import { useDownloadFile, useDownloadFileTxt, useDownloadFileWl } from 'modules/Core/hooks'
import { getContractPdf } from 'services/contract-files'
import { useCadenceAlarms } from 'modules/NewClientSales/hooks/Cadence/useCadenceAlarms'
import { TO_ACCEPT, TRY_AGAIN } from 'modules/SharedSales/constants/literals'
import { AddressChooserModal } from 'modules/Address/components/AddressChooserModalComponent/index'
import { is2pPureTariff } from 'modules/vista-360/helpers/helpers'
import { isElFijoTariff } from 'modules/tariffs/store-apigee/tariffs-apigee.helpers'
import { selectTariffsApigeeById } from 'modules/tariffs/store-apigee/tariffs-apigee.selectors'
import { CVMActions } from 'modules/offers-configuration/CVMActions.constants'
import { fetchOrderOnlyAction } from 'modules/orders/store/orders.actions'
import { APP_CONFIG } from 'services/app-config'
import {
  getBillingAddress,
  getSubscriptionIdFromOrdersById,
} from 'modules/orders/store/orders.selectors'
import { selectIs3pOr2pTariff, selectIsMobileTariff } from 'modules/offers-configuration/selectors'
import {
  BILLING_ADDRESS,
  INSTALLATION_ADDRESS,
} from 'modules/SharedSales/constants/shippingAddress'

import { ModifyContactEmailModal } from 'modules/vista-360/components/ModifyContactEmailModal/ModifyContactEmailModal'
import { useAddSubscription } from '../../hooks/useAddSubscription'

import { OrderLimitModal } from '../OrderLimitModal'
import { NewProductModal } from '../NewProductModal'
import SvasSetModal from '../../components/AddSvaModal/AddSvaModal'

const NOTIFICATION_MODAL_TYPES = {
  success: {
    type: 'success',
    iconPath: '/assets/clap_clap.svg',
  },
  alert: {
    type: 'alert',
    iconPath: '/assets/error.svg',
  },
}

const NotificationAddSva = ({ isSuccess, isError, isLoading, showModal, closeModal, svaName }) => {
  const hasError = isError && !isSuccess
  const msgError = hasError && !isEmpty(isError) ? isError : null

  const notificationType = hasError ? 'alert' : 'success'
  const errorMsgButtonLabel = msgError ? TO_ACCEPT : TRY_AGAIN
  const buttonLabel = hasError ? errorMsgButtonLabel : TO_ACCEPT

  const title = hasError
    ? 'No se ha podido añadir el SVA'
    : `¡Has añadido el SVA ${svaName} con éxito!`

  return (
    <NotificationModal
      type={NOTIFICATION_MODAL_TYPES[notificationType].type}
      src={NOTIFICATION_MODAL_TYPES[notificationType].iconPath}
      title={title}
      message={msgError}
      onClose={closeModal}
      isOpen={showModal && !isLoading}>
      <Button data-hook="action-accept" onClick={closeModal}>
        {buttonLabel}
      </Button>
    </NotificationModal>
  )
}

export const OrderCard = styled(({ className, order, offerDetail, hasIds, taxRate }) => {
  const dispatch = useDispatch()
  const canDeviceRenewal = useSelector(selectDeviceRenewalFlag)

  const [salesOperationSelected, setSalesOperationSelected] = useState(null)
  const [shouldCheckLimit, setShouldCheckLimit] = useState(false)
  const [openNewProductModal, setOpenNewProductModal] = useState(false)

  const [openAddSVA, setOpenAddSVA] = useState(false)
  const [openModifyAddress, setOpenModifyAddress] = useState(false)
  const [showNotificationAlarmsSva, setShowNotificationAlarmsSva] = useState(false)
  const [changingSvaSelected, setChangingSvaSelected] = useState()

  useEffect(() => {
    setOpenNewProductModal(salesOperationSelected !== null)
  }, [salesOperationSelected])

  const isPosOrMasProximo = useSelector(selectIsPosOrMasProximo)
  const orderSfid = order.sfid
  const isCustomerResidential = useSelector(customer360SvcSelectors.selectIsCustomerResidential)
  const isCompany = useSelector(customer360SvcSelectors.getIsCompany)
  const agentSfid = useSelector(selectSfid)
  const customerSegment = useSelector(getSegment)
  const taxNeeded = !isProCustomer(customerSegment)
  const postCode = useSelector(state => customer360SvcSelectors.getBillingAddressPostCode(state))
  const contracts = useSelector(customer360SvcSelectors.contracts)
  const tariffId = get(order, 'tariffId')
  const tariffDescription = get(order, 'tariffDescription')
  const is2pPure = is2pPureTariff(tariffId)
  const isElFijo = isElFijoTariff(useSelector(state => selectTariffsApigeeById(tariffId)(state)))
  const fixedNumber = get(order, 'fixedNumber')
  const mobileNumber = get(order, 'mobileNumber')
  const product = getProductByOrderIdFromContracts(contracts, order.id)
  const msisdn = get(product, 'id')
  const orderTypeLiteral = get(order, 'type') === 'Convergente' ? 'Internet' : get(order, 'type')
  const offer =
    offerDetail &&
    offerDetail.find(el => {
      const orderId = get(el, 'order_info.order_id', '')
      return orderId === order.id
    })
  const offerSubscription = get(offer, 'subscriptions', {})
  const orderOfferSubscription = head(offerSubscription) || {}

  const parentOrderId = get(order, 'parentOrderId')

  const { navigateToNewSubscription } = useAddSubscription('inflight')

  const permissionsCvmOpsAddDevices = useSelector(selectCanIDO(permissions.cvm_ops_add_devices.id))
  const documentId = useSelector(customer360SvcSelectors.getIdentificationId)
  const orderId = get(order, 'id')
  const orderInfo = get(offer, 'order_info')
  const tariffOfferSubscription = get(orderOfferSubscription, 'tariff')
  const orderSubscriptionId = useSelector(state => getSubscriptionIdFromOrdersById(orderId)(state))

  const installationAddressFixedLine = useSelector(state =>
    selectInstallationAddressPackageByMsisdn(state, msisdn),
  )

  const connectionData = {
    documentId,
    documentType: getDocumentType(documentId),
  }
  const operationLink = generateAditionalOperationsLink(orderId || 'orderId', connectionData) || ''
  const contractId = offer?.order_info?.contract_id
  const [download, downloadFromPromiseBlob] = useDownloadFile()
  const [downloadTxt, downloadFromPromiseTxt] = useDownloadFileTxt()
  const [downloadWl, downloadFromPromiseWl] = useDownloadFileWl()

  const [isCollapsed, setIsCollapsed] = useState(false)
  const isPosAgent = validateAgentProfile([profiles.pos.id])
  const isSaleAgent = orderSfid === agentSfid
  const posAgentHasPermissions = isPosAgent && isSaleAgent
  const downloadPermissionPdf =
    contractId && (!isPosAgent || !isPosOrMasProximo || posAgentHasPermissions)
  const downloadPermissionTxtAndWl = posAgentHasPermissions

  let welcomeLetterData = {}
  if (is2pPure) {
    welcomeLetterData = get2pWelcomeLetterData(orderInfo, postCode, customerSegment, taxRate, {
      product: tariffOfferSubscription,
      fixedLineNumber: get(head(get(orderOfferSubscription, 'fixed')), 'phone_number'),
    })
  } else if (isElFijo) {
    welcomeLetterData = getElFijoWelcomeLetterData(orderInfo, postCode, tariffOfferSubscription)
  }

  const permissionsCvmOpsAddMobileLine = useSelector(
    selectCanIDO(permissions.cvm_ops_add_mobile_line.id),
  )

  const canEnableSellOption = useSelector(customer360SvcSelectors.canEnableSomeSellOptionWithClient)

  const subscriptionTariffId = getTariffId(tariffOfferSubscription)
  const is3pOr2p = useSelector(state => selectIs3pOr2pTariff(state, subscriptionTariffId))
  const isMobileTariff = useSelector(state => selectIsMobileTariff(state, subscriptionTariffId))

  const orderBillingAddress = useSelector(getBillingAddress)

  const { saveLatestAccessToken } = useForceSaveAuth()

  const [isModifyPhoneNumberModalOpen, setIsModifyPhoneNumberModalOpen] = useState(false)
  const [isModifyEmailModalOpen, setIsModifyEmailModalOpen] = useState(false)
  const [contactPhones, setContactPhones] = useState(null)
  const [contactEmail, setContactEmail] = useState(null)

  const paymentType = get(orderOfferSubscription, 'tariff.payment_type', '')

  const {
    svas,
    isLoadingSvas,
    sendAlarmCadence,
    isSmartHomeCompatible,
    isHomeGoCompatible,
    isAlarmsCadenceError,
    isAlarmsCadenceSuccess,
    isAlarmsCadenceLoading,
    resetAlarmCadence,
    clientContactData,
    isValidPhonesContact,
    isValidEmailContact,
  } = useCadenceAlarms(
    subscriptionTariffId,
    isCustomerResidential,
    fixedNumber,
    {
      ...orderOfferSubscription,
      subscription_id: orderSubscriptionId,
      postpaid: paymentType === 'postpaid',
    },
    true,
    isCompany,
    openAddSVA,
  )

  function closeModalSva() {
    setShowNotificationAlarmsSva(false)
    resetAlarmCadence()
    setChangingSvaSelected(null)
  }

  const svaInstallationAddress = useMemo(() => {
    const fixedInstallAddress = get(installationAddressFixedLine, 'medium')

    return isMobileTariff ? orderBillingAddress : fixedInstallAddress
  }, [installationAddressFixedLine, isMobileTariff, orderBillingAddress])

  const svaInstallationAddressType = useMemo(() => {
    if (!getAddressStreetType(svaInstallationAddress)) {
      return null
    }

    return isMobileTariff ? BILLING_ADDRESS : INSTALLATION_ADDRESS
  }, [svaInstallationAddress])

  const confirmAlarmCadence = (address, svaSelected, phones, email) => {
    setOpenModifyAddress(false)
    const isCarteraFlow = false

    sendAlarmCadence(
      contractId,
      orderId,
      address,
      isCarteraFlow,
      svaSelected.alarmType,
      svaSelected,
      phones,
      email,
    )
    setShowNotificationAlarmsSva(true)
  }

  function showModalModifyAddress(svaSelected, { phones, email } = {}) {
    const hasStreetAddress =
      svaSelected.alarmType === SVA_SMARTHOME_CODE || getAddressStreetType(svaInstallationAddress)
    setOpenAddSVA(false)

    setContactPhones(phones)
    setContactEmail(email)

    if (
      svaInstallationAddress &&
      hasStreetAddress &&
      !svaSelected.alarmType.includes(SVA_HOMEGO_CODE)
    ) {
      confirmAlarmCadence(svaInstallationAddress, svaSelected, phones, email)
    } else {
      setOpenModifyAddress(true)
    }
  }

  function validateHomeGoContactInfo(svaSelected, { phones, email } = {}) {
    if (!isValidPhonesContact() && !phones && svaSelected.alarmType.includes(SVA_HOMEGO_CODE)) {
      setIsModifyPhoneNumberModalOpen(true)
    } else if (
      !isValidEmailContact() &&
      !email &&
      svaSelected.alarmType.includes(SVA_HOMEGO_CODE) &&
      !isPosOrMasProximo
    ) {
      setIsModifyEmailModalOpen(true)
    } else {
      showModalModifyAddress(svaSelected, { phones, email })
    }
  }

  const onConfirmSvasModal = svasSelected => {
    const sva = get(svasSelected, '[0]')

    if (sva) {
      setChangingSvaSelected(sva)
      validateHomeGoContactInfo(sva)
    }
  }

  const additionalOpsActionEnabled = canIAccessToAdditionalOps(order.sfid)

  const menuActions = [
    ...(permissionsCvmOpsAddDevices && !canDeviceRenewal
      ? [
          {
            label: 'Añadir dispositivo',
            onClick: () => window.open(APP_CONFIG.newton_renewal, '_blank'),
          },
        ]
      : []),

    {
      label: CVMActions.add_mobile_line.desc,
      onClick: () => setSalesOperationSelected(ADD_SUBSCRIPTION_OPERATION),
      disabled:
        !permissionsCvmOpsAddMobileLine ||
        !!order.parentOrderId ||
        !hasIds ||
        !order.canHaveAditionalSubscription ||
        !canEnableSellOption,
    },
    {
      label: CVMActions.add_sva.desc,
      onClick: () => setOpenAddSVA(true),
      disabled: !isSmartHomeCompatible && !isHomeGoCompatible,
    },
    {
      label: CVMActions.additionalOps.desc,
      onClick: () => {
        saveLatestAccessToken()
        window.open(operationLink, '_blank')
      },
      disabled: !additionalOpsActionEnabled,
    },
  ]

  const downloadActions = [
    {
      label: 'Descargar contrato',
      onClick: () => downloadFromPromiseBlob(getContractPdf(contractId)),
      disabled: !downloadPermissionPdf,
    },
    {
      label: 'Descargar TXT',
      onClick: () => downloadFromPromiseTxt(orderId),
      disabled: !downloadPermissionTxtAndWl,
    },
    {
      label: 'Descargar WL',
      onClick: () => downloadFromPromiseWl(welcomeLetterData),
      disabled: !downloadPermissionTxtAndWl,
    },
  ]

  useEffect(() => {
    if (orderId) {
      dispatch(fetchOrderOnlyAction(orderId))
    }
  }, [])

  const getHomeGoWarningFillData = () => (
    <AlertAdvice
      type="warning"
      message="Debes modificar los datos de contacto e introducir un dato válido para poder vender el SVA."
    />
  )

  return (
    <Card
      className={`order-onfly-card ${className}`}
      id={`order-${order.id}`}
      key={order.id}
      title={`${tariffDescription} ${msisdn || mobileNumber ? ` | ${msisdn || mobileNumber}` : ''}`}
      menuButton={{ btnText: 'Opciones', menuActions }}
      preHeader={
        <BadgeStatusDetail
          superStatus={order.superStatus || 'fly'}
          status={order.status}
          subStatus={order.subStatus || {}}
        />
      }
      footer={
        <ul>
          {fixedNumber ? <li>{fixedNumber}</li> : ''}
          {mobileNumber && !is2pPureTariff(get(order, 'tariffId')) ? <li>{mobileNumber}</li> : ''}
          {order.type ? <li>{orderTypeLiteral}</li> : ''}
          {!isNil(parentOrderId) && (
            <li>{parentOrderIdStr(parentOrderId, offerSubscription, taxRate)}</li>
          )}
        </ul>
      }
      order={order}
      toggable
      collapsed>
      {!isEmpty(offerSubscription) &&
        offerSubscription.map(el => {
          return (
            <div key={el.id}>
              <MainFeesItem
                feesData={helpers.generateFeeSummary(el, taxRate)}
                offer={el}
                taxNeeded={taxNeeded}
              />

              <Box className="flex align-space-between">
                <Typography
                  {...TextStyles.subtitle2Link({
                    className: 'header-lines',
                    color: 'secondary',
                  })}>
                  <span>{getCommercialName(get(el, 'tariff'))}</span>
                </Typography>

                <Box className="download-button-container flex align-center align-start-center justify-center m-r-24">
                  {download.isLoading || downloadTxt.isLoading || downloadWl.isLoading ? (
                    <CircularProgress size="20px" />
                  ) : (
                    <PopoverMenu
                      ClickableComp={() => (
                        <ButtonCard
                          data-hook="download-contract"
                          label="Descargar archivos"
                          onClick={() => setIsCollapsed(!!isCollapsed)}
                          disabled={!downloadPermissionPdf && !downloadPermissionTxtAndWl}
                        />
                      )}
                      data-hook="popover-donwload-contract"
                      menuActions={downloadActions}
                      dropdown
                    />
                  )}
                </Box>
              </Box>

              {!isEmpty(el.fixed) && (
                <>
                  <FixedDetail {...el} />
                  {!isEmpty(el.internet) ||
                    !isEmpty(el.router) ||
                    !isEmpty(el.sim) ||
                    (!isEmpty(el.handset) && <ProductDivider />)}
                </>
              )}
              {!isEmpty(el.internet) && (
                <>
                  <InternetDetail {...el} />
                  {!isEmpty(el.router) ||
                    !isEmpty(el.sim) ||
                    (!isEmpty(el.handset) && <ProductDivider />)}
                </>
              )}
              {!isEmpty(el.router) && (
                <>
                  <RouterDetail {...el} />
                  {!isEmpty(el.sim) || (!isEmpty(el.handset) && <ProductDivider />)}
                </>
              )}
              {!isEmpty(el.sim) && !is2pPureTariff(tariffId) && (
                <>
                  <SimDetail {...el} />
                  {!isEmpty(el.handset) && <ProductDivider />}
                </>
              )}
              {!isEmpty(el.handset) && <HandsetDetail {...el} />}
            </div>
          )
        })}
      <OrderLimitModal
        shouldCheckLimit={shouldCheckLimit}
        onOrderLimitOk={() => {
          if (salesOperationSelected === ADD_SUBSCRIPTION_OPERATION) {
            navigateToNewSubscription(offerSubscription[0] || offerSubscription, offerDetail)
          }
        }}
        onOrderLimitReached={() => setShouldCheckLimit(false)}
      />
      {openNewProductModal && (
        <NewProductModal
          openNewProductModal={openNewProductModal}
          setOpenNewProductModal={setOpenNewProductModal}
          onConfirm={() => {
            setShouldCheckLimit(true)
            setOpenNewProductModal(false)
          }}
          disabled={order.parentOrderId || !hasIds || !order.canHaveAditionalSubscription}
          message={`Recuerda que si tu cliente quiere un terminal con su alta de línea móvil, excepto para Línea DUO,  debes cargar la venta de la línea móvil desde el botón "Añadir Nueva Venta".`}
        />
      )}
      {openAddSVA && (
        <Modal
          isOpen={openAddSVA}
          onClose={() => setOpenAddSVA(false)}
          title="Añadir SVA"
          overFlowY="auto">
          <SvasSetModal
            svas={svas}
            subscription={offerSubscription}
            tariffId={subscriptionTariffId}
            isLoadingSvas={isLoadingSvas}
            onConfirmSvas={onConfirmSvasModal}
            onClose={() => setOpenAddSVA(false)}
          />
        </Modal>
      )}
      {openModifyAddress && (
        <Modal
          isOpen={openModifyAddress}
          onClose={() => setOpenModifyAddress(false)}
          title="Editar datos de instalación del SVA"
          overFlowY="auto">
          <AddressChooserModal
            onConfirm={address =>
              confirmAlarmCadence(
                address || svaInstallationAddress,
                changingSvaSelected,
                contactPhones,
                contactEmail,
              )
            }
            preselectedAddress={{
              address: getBillingAddressStr(svaInstallationAddress),
            }}
            preselectedAddressType={svaInstallationAddressType}
            onClose={() => setOpenModifyAddress(false)}
            allowInputManualAddress={!is3pOr2p}
            isRequiredFields={!is3pOr2p}
          />
        </Modal>
      )}

      <ModifyContactPhoneModal
        customer={{
          docType: clientContactData.individualIdentification?.type,
          docNumber: clientContactData.individualIdentification?.identificationId,
          isCompany: clientContactData.isCompany,
        }}
        current={clientContactData.telephones}
        open={isModifyPhoneNumberModalOpen}
        onClose={() => setIsModifyPhoneNumberModalOpen(false)}
        onSuccess={phones => {
          setContactPhones(phones)
          validateHomeGoContactInfo(changingSvaSelected, { phones, email: contactEmail })
        }}>
        {getHomeGoWarningFillData()}
      </ModifyContactPhoneModal>

      <ModifyContactEmailModal
        customer={{
          docType: clientContactData.individualIdentification?.type,
          docNumber: clientContactData.individualIdentification?.identificationId,
          isCompany: clientContactData.isCompany,
        }}
        current={clientContactData.mail}
        open={isModifyEmailModalOpen}
        onClose={() => setIsModifyEmailModalOpen(false)}
        onSuccess={email => {
          setContactEmail(email)
          validateHomeGoContactInfo(changingSvaSelected, { phones: contactPhones, email })
        }}>
        {getHomeGoWarningFillData()}
      </ModifyContactEmailModal>

      <NotificationAddSva
        isSuccess={isAlarmsCadenceSuccess}
        isError={isAlarmsCadenceError}
        isLoading={isAlarmsCadenceLoading}
        showModal={showNotificationAlarmsSva}
        closeModal={closeModalSva}
        svaName={SVAS_TYPES_CODE_TEXT[(changingSvaSelected?.alarmType)]}
      />
    </Card>
  )
})`
  .header-lines {
    padding: 9px 24px;
  }

  .download-button-container {
    min-width: 182px;
  }

  .download-button-container > button {
    flex-direction: row-reverse;
  }
`

OrderCard.propTypes = {
  className: PropTypes.string,
  order: PropTypes.object,
  offerDetail: PropTypes.array,
  taxRate: PropTypes.number,
}

NotificationAddSva.propTypes = {
  isSuccess: PropTypes.bool,
  isError: PropTypes.any,
  isLoading: PropTypes.bool,
  showModal: PropTypes.bool,
  closeModal: PropTypes.func,
  svaName: PropTypes.string,
}
