import { call, put, takeEvery } from 'redux-saga/effects'
import { logError, formatErrorMessage } from 'services/logging'
import { axiosFactory } from 'modules/axios'

import { actions } from './actions'
import * as constants from './constants'
import { findProductOrders } from './api'
import { sortProductOrders } from './helpers'

export function* findProductOrdersSaga({ payload: { productId, filterBy, customerId } }) {
  try {
    const productOrders = yield call(findProductOrders, productId, filterBy, customerId)
    const sortedProductOrders = yield call(sortProductOrders, productOrders)
    yield put(actions.findProductOrdersSuccess(sortedProductOrders, productId))
  } catch (e) {
    yield call(logError, { e: new Error(formatErrorMessage(e)), productId, filterBy })
    const error = axiosFactory.buildError(e)
    yield put(actions.findProductOrdersFailed(error, productId))
  }
}

export function* watchFindProductOrders() {
  yield takeEvery(constants.FIND_PRODUCT_ORDERS, findProductOrdersSaga)
}
