import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import { TextStyles } from 'utils/text'

import { OutlinedDivider } from 'components/ui'
import { Badge } from 'modules/ui'
import { isEmpty } from 'lodash'
// import { PopoverInfo } from '../PopoverInfo'
// import SubscriptionStatusBadge from '../../../../components/packages/ui/subscription-status-badge/SubscriptionStatusBadge'

const iconSvg = {
  fixed: '/assets/fixed_phone.svg',
  mobile: '/assets/sim_filled.svg',
  internet: '/assets/fixed_internet_filled.svg',
}

export function FixedDetail({ fixed }) {
  return !isEmpty(fixed) ? (
    <>
      <div className="p-x-24 p-y-8" data-hook="fixed-detail">
        {fixed.map(fixedItem => (
          <div key={fixedItem.id} className="flex p-x-24 align-start-center">
            <img src={iconSvg.fixed} alt="fixed" />
            <Typography
              {...TextStyles.subtitle2Dark({
                className: 'p-x-24',
              })}>
              {fixedItem.phone_number}
            </Typography>
            {!isEmpty(fixedItem.state_desc) && (
              <>
                {/* <SubscriptionStatusBadge status={fixedItem.state_desc} variant="small" /> */}
                {/* <PopoverInfo info={fixedItem.state_desc} /> */}
              </>
            )}
            {!isEmpty(fixedItem.fixed_internal_portability) &&
              (fixedItem.fixed_internal_portability === 'true' ? (
                <Badge lg value={{ label: 'Portabilidad interna' }} />
              ) : (
                <Badge lg value={{ label: 'Portabilidad' }} />
              ))}

            {!isEmpty(fixedItem.installation_required) &&
              (fixedItem.installation_required === 'true' ? (
                <Badge lg value={{ label: 'Con instalación' }} />
              ) : (
                <Badge lg value={{ label: 'Sin instalación' }} />
              ))}

            {!isEmpty(fixedItem.donor_operator_name) && (
              <Badge
                lg
                value={{ label: 'Operador donante', value: fixedItem.donor_operator_name }}
              />
            )}
          </div>
        ))}
      </div>

      <div className="p-x-24 p-y-8">
        <OutlinedDivider />
      </div>
    </>
  ) : (
    ''
  )
}

FixedDetail.propTypes = {
  fixed: PropTypes.arrayOf(PropTypes.object),
}
