import PropTypes from 'prop-types'
import { withTheme } from 'styled-components'
import { Box } from '@material-ui/core'
import { OutlinedDivider } from 'components/ui'

import BillHeader from './BillHeader'

const PADDING_ELEMENT = '9px 24px'

const Bill = ({ billData, hasDivider }) => (
  <div>
    <BillHeader
      externalId={billData.externalId}
      billId={billData.id}
      billAmount={billData.amount}
      isUnpaid={billData.isUnpaid}
    />
    {hasDivider && (
      <Box padding={PADDING_ELEMENT}>
        <OutlinedDivider />
      </Box>
    )}
  </div>
)

Bill.propTypes = {
  billData: PropTypes.object,
  hasDivider: PropTypes.bool,
}

export default withTheme(Bill)
