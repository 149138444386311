import { Component } from 'react'
import PropTypes from 'prop-types'
import { Coverage } from 'components'

class AddressForm extends Component {
  shouldComponentUpdate(nextProps) {
    const { tokenGenerated, onCoverageCheckFinished } = this.props
    return (
      tokenGenerated !== nextProps.tokenGenerated ||
      onCoverageCheckFinished !== nextProps.onCoverageCheckFinished
    )
  }

  onCoverageCheckFinished = coverageData => {
    const { coverage } = coverageData
    this.props.gotCoverageData(coverageData)
    this.props.next(coverage)
  }

  render() {
    return (
      <Coverage
        token={this.props.tokenGenerated}
        onCoverageCheckFinished={this.onCoverageCheckFinished}
        containerId="coverage-address"
      />
    )
  }
}

export default AddressForm

AddressForm.propTypes = {
  next: PropTypes.func.isRequired,
  tokenGenerated: PropTypes.string,
  onCoverageCheckFinished: PropTypes.func,
  gotCoverageData: PropTypes.func,
}

AddressForm.defaultProps = {
  tokenGenerated: '',
}
