export const getIncompatibilityRule = (incompatibilities = [], discount = {}) =>
  incompatibilities.find(incompatibility => incompatibility.discountCode === discount.discountCode)

export const updateDiscountsMeta = (
  discounts = [],
  modificationUsername = '',
  modificationDate = new Date().toISOString(),
) =>
  discounts.map(discount => ({
    ...discount,
    modificationUsername,
    sfid: modificationUsername,
    modificationDate,
  }))
