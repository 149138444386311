import * as Yup from 'yup/lib'
import { landlineRegex } from 'utils'
import { REQUIRED_FIELD_TEXT } from 'modules/SharedSales/constants'
import * as validations from 'modules/SharedSales/constants/validations'

export const OfferTypeSchema = {
  landlineOffer: Yup.object().shape({
    isPortability: Yup.string().required(validations.REQUIRED_FIELD_TEXT),
    lineNumber: Yup.string().when('isPortability', {
      is: 'yes',
      then: Yup.string()
        .trim()
        .matches(landlineRegex, validations.ERROR_DATA_TEXT)
        .required(REQUIRED_FIELD_TEXT),
      otherwise: Yup.string(),
    }),
    installationAddress: Yup.object().shape({
      gescal: Yup.string().required(REQUIRED_FIELD_TEXT),
    }),
  }),
}
