import { call, put, takeLatest } from 'redux-saga/effects'
import { logError, formatErrorMessage } from 'services/logging'
import { axiosFactory } from 'modules/axios'

import { actionTypes } from 'modules/typifications/constants'
import { securizer } from 'modules/typifications/store/apis/authTypifications.api'
import { securizerSuccess, securizerFailed } from '../actions'


export function* initSecurizerTypificationSaga({ payload }) {
    try {
      const response = yield call(securizer, payload)
      yield put(securizerSuccess(response))
    } catch (e) {
      yield call(logError, new Error(formatErrorMessage(e)))
      const error = axiosFactory.buildError(e)
      yield put(securizerFailed(error))
    }
  }


export function* watchSecurizerTypigicationsSagas() {
    yield takeLatest(actionTypes.SECURIZER_TYPIFICATION_INIT, initSecurizerTypificationSaga)
  }