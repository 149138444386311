import { call, put, takeLatest } from 'redux-saga/effects'
import { logError, formatErrorMessage } from 'services/logging'
import { axiosFactory } from 'modules/axios'

import * as actions from './bonuses.actions'
import { fetchBonuses } from '../services/bonuses.service'

export function* findBonusesSaga() {
  try {
    const bonuses = yield call(fetchBonuses)

    yield put(actions.findBonusesSuccessAction(bonuses))
  } catch (e) {
    yield call(logError, new Error(formatErrorMessage(e)))
    const error = axiosFactory.buildError(e)
    yield put(actions.findBonusesErrorAction(error))
  }
}

export function* watchFindBonuses() {
  yield takeLatest(actions.FIND_BONUSES, findBonusesSaga)
}
