import PropTypes from 'prop-types'

import { isEmpty } from 'lodash'
import { ActionAdvice } from 'components/ui'
import { Box, Divider } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

export function AddedProductsSummary({ list, onDelete, type = 'info' }) {
  const useStyles = makeStyles({
    adviceStyles: {
      width: '100%',
      '& .advice-text-field': {
        paddingRight: '16px',
      },
      '& .text-wrapper': {
        position: 'relative',
        paddingRight: '0px',
      },
      '& .icon': {
        alignSelf: 'center',
        cursor: 'pointer',
      },
      '& [data-hook="icon-cancel"]': {
        position: 'absolute',
        right: '12px',
      },
      '& .advice-wrapper': {
        width: '100%',
      },
    },
  })

  const styles = useStyles()

  return (
    !isEmpty(list) && (
      <Box width={1}>
        <Divider />
        <Box display="flex" flexWrap="wrap" py="20px">
          {list.map(({ display, id, active }, index, arr) => (
            <Box
              key={id || `discount-${index}`}
              display="flex"
              mr={(index !== arr.length - 1 && 16) || 0}
              my="4px"
              width="380px">
              <ActionAdvice
                className={`advice ${styles.adviceStyles}`}
                id="bundle"
                type={type}
                items={display}
                onClose={onDelete && !active ? () => onDelete(id) : null}
                block
              />
            </Box>
          ))}
        </Box>
      </Box>
    )
  )
}
AddedProductsSummary.propTypes = {
  list: PropTypes.array,
  onDelete: PropTypes.func,
  type: PropTypes.string,
}
