import { connect } from 'react-redux'
import { get } from 'lodash'
import { selectSfid } from 'modules/Auth'
import { selectQueryParam } from 'modules/Router'
import { actions as actionsChangeTechnology } from 'services/change-technology'
import { getCustomerInfo } from 'services/global-selectors/selectors'
import {
  selectTariffApigeeConvergentTariffs,
  selectTariffApigeeErrorCode,
  selectTariffApigeeIsLoading,
} from 'modules/tariffs/store-apigee'
import { isAdslOrder } from 'modules/orders'
import { ChangeTechnologyOld } from '../../components/ChangeTechology'
import { getSubscriptionSagaAction } from './get-subscription.saga'

const mapDispatchToProps = dispatch => ({
  getSubscriptionData: payload => dispatch(getSubscriptionSagaAction(payload)),

  sendOrder: changeTechnologyData =>
    dispatch(actionsChangeTechnology.createChangeTechnology({ changeTechnologyData })),
})

const mapStateToProps = state => {
  const customerState = get(state.productOrders, 'data', {})
  return {
    sfid: selectSfid,
    fixedNumber: selectQueryParam('fixedNumber')(state),
    customer: customerState,
    tariffs: selectTariffApigeeConvergentTariffs(state),
    tariffsError: selectTariffApigeeErrorCode(state),
    tariffsLoading: selectTariffApigeeIsLoading(state),
    penalty: state.productPenalizations.data,
    productCoverage: state.productCoverage,
    initialOrder: state.productOrders.data.id,
    order: state.changeTechnology.data.orderId,
    loadingOrder: state.changeTechnology.loading,
    enrichment: state.enrichment.data,
    productProvisioning: state.productProvisioning,
    productOffers: state.productOffers,
    coverage: get(state.coverage, 'data[0].technology', []),
    coverageLoading: get(state.coverage, 'loading'),
    coverageError: get(state.coverage, 'error_code'),
    coverageData: get(state.coverage, 'data[0]', []),
    customerInfo: getCustomerInfo('InstallationAddressFixedLine')(state),
    isAdslOrder: isAdslOrder(get(customerState, 'customer')),
    productOrders: get(state, 'productOrders.data', {}),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ChangeTechnologyOld)
