import { get } from 'lodash'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { Card, CardContent, Typography, Box } from '@material-ui/core'
import { CardRow, CardRowLabel, CardRowValue } from 'components'
import { PlaceholderLine } from 'components/ui'
import { CIF } from 'modules/CustomerDocument/constants/documentTypes'

import { selectIsPosOrMasProximo } from 'modules/Permissions'
import { formatCensuredDni, formatCensuredNif } from 'modules/vista-360/helpers/formats.helper'

const getCompanyId = companyInfo => get(companyInfo, 'individualIdentification')

const getName = (customer, companyInfo) =>
  get(companyInfo, 'name') ||
  `
  ${get(customer, 'firstName', '')}
  ${get(customer, 'midName', '')}
  ${get(customer, 'lastName', '')}
  `

const getTypeId = (companyInfo, customerInfo) =>
  getCompanyId(companyInfo) ? 'CIF' : get(customerInfo, 'typeId') || 'DNI'

const getId = (companyInfo, customerInfo) => getCompanyId(companyInfo) || get(customerInfo, 'id')

export function ExistingClientSidebar({
  customer,
  customerInfo,
  orderId,
  companyInfo,
  children,
  isLoading,
  isError,
}) {
  const isPosOrMasProximo = useSelector(selectIsPosOrMasProximo)

  const documentType = getTypeId(companyInfo, customerInfo)
  const documentId = getId(companyInfo, customerInfo)
  const censuredDocumentId =
    documentType === CIF ? formatCensuredNif(documentId) : formatCensuredDni(documentId)

  return !isLoading && !isError ? (
    <>
      <Card elevation={2} style={{ width: '100%', borderRadius: '4px', marginTop: 0 }}>
        <CardContent>
          <Typography variant="h6">Datos Personales</Typography>
          <Box marginTop="21px">
            <CardRow>
              <CardRowLabel>Nombre:</CardRowLabel>
              <CardRowValue>{getName(customer, companyInfo)}</CardRowValue>
            </CardRow>
            <CardRow>
              <CardRowLabel>{documentType}:</CardRowLabel>
              <CardRowValue>{isPosOrMasProximo ? censuredDocumentId : documentId}</CardRowValue>
            </CardRow>
          </Box>
        </CardContent>
      </Card>
      <Card elevation={2} style={{ width: '100%', borderRadius: '4px', marginTop: 0 }}>
        <CardContent>
          <Typography variant="h6">Nº de orden: {orderId || '-'}</Typography>
        </CardContent>
      </Card>
      {children}
    </>
  ) : (
    <Card elevation={2} style={{ width: '100%', borderRadius: '4px', marginTop: 0 }}>
      <CardContent>
        {isError ? (
          <Typography variant="h6">Se ha producido un error</Typography>
        ) : (
          <>
            <Box mb="20px">
              <Typography variant="h6">
                <PlaceholderLine height="15px" />
              </Typography>
            </Box>
            <CardRow>
              <PlaceholderLine width="50%" />
            </CardRow>
            <CardRow>
              <PlaceholderLine />
            </CardRow>
          </>
        )}
      </CardContent>
    </Card>
  )
}

ExistingClientSidebar.propTypes = {
  customer: PropTypes.object,
  customerInfo: PropTypes.object,
  orderId: PropTypes.string,
  companyInfo: PropTypes.object,
  children: PropTypes.element,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
}

ExistingClientSidebar.defaultProps = {
  isLoading: true,
  isError: false,
}
