import PropTypes from 'prop-types'

import { Typography } from '@material-ui/core'
import { FormikInput } from 'components/ui'

export const TerminalFinancialData = ({
  fieldNames,
  professionOptions,
  shouldShowCompany,
  onSelectProfession,
  isLeadFilled,
  isMobileOnly,
}) => {
  return (
    <div>
      <Typography variant="body2">
        Por favor, rellena estos datos para completar el pedido del terminal a plazos
      </Typography>
      <div className="flex flex-wrap">
        <div className="flex-grow p-r-16">
          <FormikInput
            disabled={{ bool: isMobileOnly && !isLeadFilled }}
            backgroundcolor="#fff"
            label="Caducidad Documento"
            date
            fullWidth
            name={fieldNames.ID_DUE_DATE}
          />
        </div>
        <div className="flex-grow p-r-16">
          <FormikInput
            disabled={{ bool: isMobileOnly && !isLeadFilled }}
            money
            backgroundcolor="#fff"
            label="Salario"
            fullWidth
            name={fieldNames.SALARY}
          />
        </div>
        <div className="flex-grow p-r-16">
          <FormikInput
            disabled={{ bool: isMobileOnly && !isLeadFilled }}
            backgroundcolor="#fff"
            onChange={onSelectProfession}
            label="Profesión/Sector"
            select
            name={fieldNames.JOB}
            fullWidth
            options={professionOptions}
          />
        </div>
        {shouldShowCompany && (
          <div className="flex-grow">
            <FormikInput
              disabled={{ bool: isMobileOnly && !isLeadFilled }}
              fullWidth
              backgroundcolor="#fff"
              label="Empresa"
              name={fieldNames.COMPANY}
            />
          </div>
        )}
      </div>
    </div>
  )
}

TerminalFinancialData.propTypes = {
  fieldNames: PropTypes.object.isRequired,
  professionOptions: PropTypes.array.isRequired,
  onSelectProfession: PropTypes.func.isRequired,
  shouldShowCompany: PropTypes.bool,
  isLeadFilled: PropTypes.bool,
  isMobileOnly: PropTypes.bool,
}
