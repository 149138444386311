import { remove, set, get, cloneDeep } from 'lodash'

export const removeSpeedIfADSL = (offer, specsArr) => {
  if (offer.fixed_line_type === 'adsl') {
    return specsArr.filter(elem => elem.name !== 'upload_speed' && elem.name !== 'download_speed')
  }
  return specsArr
}

export const filterPropertiesByFixedLine = (changeAddressJson, offer) => {
  const specsPath = 'customer.products[0].productsSpecifications[0].productSpecCharacteristic'
  const result = cloneDeep(changeAddressJson)
  const specsArr = get(result, specsPath)
  const specsArrayFiltered = removeSpeedIfADSL(offer, specsArr)
  if (offer) {
    set(result, specsPath, removeSpeedIfADSL(offer, specsArr))
  }
  remove(specsArrayFiltered, elem => !elem.value)
  return result
}
