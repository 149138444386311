import { includes } from 'lodash'
import { permissions, selectCanIDO, selectProfileId, profiles } from 'modules/Permissions'
import { selectSfid } from 'modules/Auth'
import { useSelector } from 'react-redux'
import { selectHasSpecialAccessToAdditionalOps } from 'modules/Permissions/store/permissions-selectors'

export const canIAccessToAdditionalOps = orderSfid => {
  const agentProfile = useSelector(selectProfileId)
  const agentSfid = useSelector(selectSfid)
  const permissionAdditionalOpsAccess = useSelector(
    selectCanIDO(permissions.additional_ops_access.id),
  )
  const hasProfileAdditionalOpsAgent = includes(
    [profiles.pos.id, profiles.d2d.id, profiles.masProximo.id],
    agentProfile,
  )

  const isAgentInitOrder = hasProfileAdditionalOpsAgent && agentSfid === orderSfid
  const hasAccessToAdditionalOps = !hasProfileAdditionalOpsAgent && permissionAdditionalOpsAccess
  const hasSpecialAccessToAdditionalOps = selectHasSpecialAccessToAdditionalOps(agentSfid)

  return isAgentInitOrder || hasAccessToAdditionalOps || hasSpecialAccessToAdditionalOps
}

export const validateAgentProfile = profilesToValidate => {
  const agentProfile = useSelector(selectProfileId)
  return includes(profilesToValidate, agentProfile)
}
