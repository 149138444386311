import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { get } from 'lodash'
import { Box, Typography } from '@material-ui/core'

import { selectHomeGoSelectedFullAddress } from 'modules/NewClientSales/store/selectors/Basket.selectors'
import { formatNumberWithComma } from 'utils'
import { SVA_HOMEGO_CODE } from 'modules/offers-configuration/constants'
import { getPriceWithTax } from 'modules/Taxes/helpers.js'
import { selectSaleTax } from 'modules/NewClientSales/store/selectors/Taxes.selectors'

const AGILETV = 'AGILETV'
function SingleSVASummary({ sva, length, i, isPro }) {
  const saleTax = useSelector(selectSaleTax)

  const priceFreeTax = get(sva, 'monthlyFee')
  const priceWithTax = getPriceWithTax(
    {
      taxFreeAmount: priceFreeTax,
      taxRate: saleTax,
    },
    saleTax,
  )

  const labelPriceWithTax = isPro
    ? `${formatNumberWithComma(priceFreeTax)}€/mes (imp. no incluidos)`
    : `${formatNumberWithComma(priceWithTax)}€/mes`

  const homeGoSelectedFullAddress = useSelector(selectHomeGoSelectedFullAddress)

  return (
    <Box data-hook={`summary.SVA.${sva.poId}`} mb="16px">
      <Box display="flex" alignItems="start-center" width="100%">
        <Typography variant="body1" className="p-r-4">
          Servicio adicional {length > 1 ? i + 1 : ''}:
        </Typography>
        {sva.canHavePenalty ? (
          <Typography variant="body2" data-hook="landlineOffer.SVA">
            {sva.name} (
            {sva.duration && !sva.psId.includes(AGILETV)
              ? `${labelPriceWithTax} durante ${sva.duration} meses con penalización`
              : `${labelPriceWithTax}`}
            ).
          </Typography>
        ) : (
          <Typography variant="body2" data-hook="landlineOffer.SVA">
            {sva.name} {sva.psId.includes(SVA_HOMEGO_CODE) ? '' : labelPriceWithTax}
          </Typography>
        )}
      </Box>
      {sva.psId.includes(SVA_HOMEGO_CODE) && (
        <Box display="flex" alignItems="start-center" width="100%">
          <Typography variant="body1" style={{ marginRight: '5px' }}>
            Dirección de instalación del servicio:
          </Typography>
          <Typography variant="body2">{homeGoSelectedFullAddress?.formattedAddress}</Typography>
        </Box>
      )}
    </Box>
  )
}

SingleSVASummary.propTypes = {
  sva: PropTypes.object,
  length: PropTypes.number,
  i: PropTypes.number,
  isPro: PropTypes.bool,
}

export function SVASummary({ selectedSVA, selectedProSvas, isPro }) {
  const svaWithPenalty = { ...selectedSVA, canHavePenalty: true }

  const svaArray = svaWithPenalty.psId ? [svaWithPenalty, ...selectedProSvas] : selectedProSvas

  return svaArray.map((sva, i, arr) => (
    <SingleSVASummary sva={sva} key={sva.poId} length={arr.length} i={i} isPro={isPro} />
  ))
}
