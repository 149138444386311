import * as Yup from 'yup/lib'
import { zipCodeRegex, noSpecialCharactersRegex } from 'utils'
import {
  REQUIRED_FIELD_TEXT,
  INVALID_ZIP_CODE,
  INVALID_FLAT,
  INVALID_DOOR,
  YOIGO_STORE,
  DELIVERY_POINT,
  OTHER_ADDRESS,
} from 'modules/SharedSales/constants'

export const ShippingSchema = {
  selectedAddressType: Yup.string(),
  deliveryPoint: Yup.object().when('selectedAddressType', {
    is: YOIGO_STORE.value || DELIVERY_POINT.value,
    then: Yup.object({
      zipCode: Yup.string()
        .matches(zipCodeRegex, INVALID_ZIP_CODE)
        .required(REQUIRED_FIELD_TEXT),
      selectedPoint: Yup.object({
        id: Yup.string().required(REQUIRED_FIELD_TEXT),
      }),
    }),
    otherwise: Yup.object({
      zipCode: Yup.string(),
      selectedPointId: Yup.object({
        id: Yup.string(),
      }),
    }),
  }),
  otherAddress: Yup.object().when('selectedAddressType', {
    is: OTHER_ADDRESS.value,
    then: Yup.object({
      address: Yup.string().required(REQUIRED_FIELD_TEXT),
      number: Yup.string().when('editAddress', {
        is: true,
        then: Yup.string().nullable(),
        otherwise: Yup.string()
          .nullable()
          .required(REQUIRED_FIELD_TEXT),
      }),
      flat: Yup.string().matches(noSpecialCharactersRegex, INVALID_FLAT),
      door: Yup.string().matches(noSpecialCharactersRegex, INVALID_DOOR),
      zipCode: Yup.string()
        .matches(zipCodeRegex, INVALID_ZIP_CODE)
        .required(REQUIRED_FIELD_TEXT),
      city: Yup.string().required(REQUIRED_FIELD_TEXT),
      province: Yup.string().required(REQUIRED_FIELD_TEXT),
    }),
  }),
}
