import PropTypes from 'prop-types'

import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
  'section-title-grid': {
    margin: '57px 32px 24px',
  },
})

const SectionTitleGrid = ({ children, className, ...props }) => {
  const classes = useStyles()

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="baseline"
      className={[classes['section-title-grid'], className].join(' ')}
      {...props}>
      {children}
    </Box>
  )
}

SectionTitleGrid.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]),
  className: PropTypes.string,
}

export default SectionTitleGrid
