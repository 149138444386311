import uuidv4 from 'uuid'
import { createSelector, createStructuredSelector } from 'reselect'

import { selectSfid, selectIpAddress } from 'modules/Auth'
import {
  selectors as customer360Selectors,
  selectCustomerData,
  selectCustomerAddress,
} from 'services/customer-360/selectors'

import { selectAccountId, selectBillingId } from 'modules/CustomerInfo/store'
import {
  mapToPersonalData,
  mapToContactMedia,
  mapToIndividualIdentification,
} from 'services/checkout-order-generator/checkoutGenerator.helpers'
import { selectRenewalAgileTvFormated } from 'modules/SVAs/store'
import {
  selectRenewalSaleChannelValue,
  selectRenewalSubscriptionId,
  selectDeviceBillingTransaction,
  selectDeviceBillingType,
  selectRenewalDeviceShipping,
  selectRenewalDeviceInfo,
  selectFinancedLegalConsentsData,
  selectFinancialData,
  selectFinancedDeviceDecisions,
  selectSubscriptionFinancedDeviceInfo,
  selectFinalPaymentDiscountAmount,
  selectPermanencyContractId,
  selectInstallmentContractId,
  selectIbanInformation,
  selectIsFinalPaymentDiscountApplicable,
  selectDeviceType,
  selectRenewalAgileTvEmail,
  selectRenewalReservationId,
} from '../store/device-renewal.selectors'

import {
  generateDeviceRootOrderItem,
  generateDeviceShipping,
  generateFinancingDecisionsChildOrder,
  generateBillingAccount,
} from './generator.helpers'
import { DEVICE_TYPE_AGILE_TV } from '../constants/deviceTypes'

const selectSalesInfo = createSelector(
  selectRenewalSaleChannelValue,
  selectSfid,
  selectIpAddress,
  (channel, sfid, ip) => ({
    channel,
    sfid,
    ip,
    salesType: 'renewal',
  }),
)

const selectFinancingDecisionsChildOrder = createSelector(
  [
    selectFinancedDeviceDecisions,
    selectSubscriptionFinancedDeviceInfo,
    selectFinalPaymentDiscountAmount,
    selectPermanencyContractId,
    selectInstallmentContractId,
    selectIsFinalPaymentDiscountApplicable,
  ],
  (
    financedDeviceDecisions,
    financedDeviceInfo,
    finalPaymentDiscountAmount,
    permanencyContractId,
    installmentContractId,
    isFinalPaymentDiscountApplicable,
  ) =>
    generateFinancingDecisionsChildOrder(
      financedDeviceDecisions,
      financedDeviceInfo,
      finalPaymentDiscountAmount,
      permanencyContractId,
      installmentContractId,
      isFinalPaymentDiscountApplicable,
    ),
)

const selectAgileTvChildOrder = createSelector(
  [selectDeviceType, selectRenewalAgileTvEmail, selectRenewalAgileTvFormated],
  (deviceType, email, agileTvFormated) => {
    if (deviceType !== DEVICE_TYPE_AGILE_TV) {
      return null
    }

    return {
      inputtedCharacteristics: {
        CH_TV_EMAIL: email,
      },

      productOfferingId: agileTvFormated.poId,
    }
  },
)

const selectRenewalOrderChildren = createSelector(
  [selectFinancingDecisionsChildOrder, selectAgileTvChildOrder],
  (financingDecisionsChildOrder, agileTvChildOrder) => {
    const orderChildren = []

    if (financingDecisionsChildOrder) {
      orderChildren.push(financingDecisionsChildOrder)
    }

    if (agileTvChildOrder) {
      orderChildren.push(agileTvChildOrder)
    }

    return orderChildren
  },
)

const selectRenewalOrder = createSelector(
  [
    selectRenewalDeviceInfo,
    selectFinancialData,
    selectFinancedLegalConsentsData,
    customer360Selectors.getSegment,
    selectRenewalOrderChildren,
    selectRenewalReservationId,
  ],
  (
    { paymentMethods },
    financialData,
    financedLegalConsents,
    segment,
    orderChildren,
    reservationId,
  ) => {
    const paymentType = Object.keys(paymentMethods)[0]

    return {
      rootOrderItem: generateDeviceRootOrderItem(
        paymentMethods[paymentType],
        paymentType,
        financialData,
        financedLegalConsents,
        segment,
        reservationId,
      ),
      orderChildren,
      id: `renewal-${uuidv4()}`,
    }
  },
)

const selectAllOrders = createSelector(
  [selectRenewalOrder],
  renewalOrder => [renewalOrder],
)

const selectDevicePaymentInfo = createSelector(
  [selectDeviceBillingType, selectDeviceBillingTransaction],
  (billingType, transaction) => {
    if (billingType === '') {
      return { isFree: true }
    }

    return {
      isTPV: billingType === 'CASH_ON_DELIVERY',
      ...transaction,
    }
  },
)

const selectDeviceShipping = createSelector(
  [selectRenewalDeviceShipping, selectCustomerAddress],
  (deviceShipping, customerAddress) => {
    return generateDeviceShipping(deviceShipping, customerAddress)
  },
)

export const selectBasketData = createSelector(
  [
    selectAccountId,
    customer360Selectors.getIdentificationType,
    customer360Selectors.getIdentificationId,
    customer360Selectors.getSegment,
    selectBillingId,
    selectRenewalSubscriptionId,
  ],
  (accountId, documenttype, documentid, segment, billingAccountId, subscriptionId) => {
    return {
      account: {
        accountId,
        documenttype,
        documentid,
      },

      billing: {
        billingAccountId,
      },

      orderType: 'renewal',
      segment: (segment || '').toLowerCase().includes('pro') ? 'pro' : 'residential',
      parentAgreementId: subscriptionId.toString(),
    }
  },
)

const selectCustomerInfo = createSelector(
  [selectCustomerData],
  customerData => {
    return {
      ...customerData,
      nationality: customerData.nationality || 'ES',
      birthday: customerData.birthday,
      email: customerData.email,
      phoneContact: customerData.phoneContact || '',
      phoneContact2: customerData.phoneContact2 || '',
    }
  },
)

const selectIndividualIdentification = createSelector(
  [customer360Selectors.getIdentificationType, customer360Selectors.getIdentificationId],
  (identificationType, identificationId) => {
    return mapToIndividualIdentification({
      documentid: identificationId,
      documenttype: identificationType,
    })
  },
)

const selectIndividualContactMedia = createSelector(
  selectCustomerInfo,
  mapToContactMedia,
)

const selectPersonalData = createSelector(
  selectCustomerInfo,
  mapToPersonalData,
)

export const selectCustomerAccountInfo = createSelector(
  [
    selectIndividualIdentification,
    selectIndividualContactMedia,
    selectPersonalData,
    selectCustomerAddress,
  ],
  (individualIdentification, individualContactMedia, personalInformation, customerAddress) => {
    return {
      personalInformation,
      individualIdentification,
      individualContactMedia,
      billingAddress: { medium: customerAddress },
    }
  },
)

export const selectBillingAccount = createSelector(
  [selectIbanInformation],
  ibanInformation => generateBillingAccount(ibanInformation),
)

export const generateOrderSelectors = createStructuredSelector({
  salesInfo: selectSalesInfo,
  allOrders: selectAllOrders,
  devicePaymentInfo: selectDevicePaymentInfo,
  deviceShipping: selectDeviceShipping,
  customerAccountInformation: selectCustomerAccountInfo,
  billingAccount: selectBillingAccount,
})
