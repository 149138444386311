import {
  GET_LEGAL_TEXTS,
  GET_LEGAL_TEXTS_SUCCESS,
  GET_LEGAL_TEXTS_ERROR,
} from './legalTexts.constants'

export function getLegalTextsAction(payload) {
  return {
    type: GET_LEGAL_TEXTS,
    payload,
  }
}

export function getLegalTextsSuccessAction(origin, legalTexts) {
  return {
    type: GET_LEGAL_TEXTS_SUCCESS,
    payload: { origin, legalTexts },
  }
}

export function getLegalTextsErrorsAction(origin, error) {
  return {
    type: GET_LEGAL_TEXTS_ERROR,
    payload: { origin, error },
  }
}
