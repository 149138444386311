/* eslint-disable-next-line no-unused-vars */
import { chain, get, groupBy, head, map, orderBy, value, isNil, compact } from 'lodash'
import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { startOfMonth, endOfMonth } from 'date-fns'

import { permissions, selectCanIDO } from 'modules/Permissions'
import { isProCustomer } from 'services/customer-360/helpers'
import { getSegment } from 'services/customer-360/selectors'
import { selectEnabledTaxSvaAgileTv, selectEnableFat } from 'services/feature-flag/selectors'
import {
  getTotalExtras,
  getTerminalsFee,
  getMontlhyVasFee,
  hasPromos,
  getDiscountsCard,
  hasDiscounts,
  calculateCrossSellDiscount,
  getCurrentMonthEstimatedTotalExpenses,
  getCurrentMonthTariffRanges,
  getActiveBundlesWithFees,
  getMsisdn,
  getFATs,
} from 'services/subscriptions'

import {
  findPrepaidLine,
  selectIsLoading,
  selectErrorMessage,
  selectPrepaidLineData,
} from 'modules/prepaid-line'

import { applyTax, getTax } from 'services/taxes'
import { selectors as customerSelectors } from 'services/customer-360'

import {
  getCommercialName,
  getConvergentRelatedMobileTariff,
  selectTariffsApigeeDataTariffs,
  selectTariffsApigeeById,
} from 'modules/tariffs/store-apigee'

import { addCurrentMonthEstimatedFeeWithTaxes } from 'services/subscriptions/tariffRange.helpers'
import { isCrossSellDiscount, isHomeGoDiscount } from 'modules/Discounts'
import {
  is2pTariff,
  is2pFreeLineTariff,
  isElFijoTariff,
} from 'modules/tariffs/store-apigee/tariffs-apigee.helpers'

import { getNormInterval, intersection } from 'utils/dates'
import { formatGB, mbToGb, formatMB } from 'utils'

import { MonthlyConsumptionCard } from '../../components/MonthlyConsumptionCard'

const formatBundleName = bundle => {
  const amountMB = Number(bundle.value)
  const moreThanOneBundle = get(bundle, 'count') > 1 ? `(${get(bundle, 'count')}U)` : ''

  if (Number.isNaN(amountMB)) {
    return 'Bono no cargado correctamente'
  }

  if (amountMB >= 1024) {
    return `Bono ${formatGB(mbToGb(amountMB), undefined, undefined, {
      noDecimalsIfZero: true,
    })} ${moreThanOneBundle}`
  }
  return `Bono ${formatMB(amountMB, undefined, undefined, {
    noDecimalsIfZero: true,
  })} ${moreThanOneBundle}`
}

export function MonthlyConsumptionContainer({
  subscription,
  VAS,
  UIActionUpdate,
  highLigthedUI,
  isPrepaid,
}) {
  const location = useLocation()
  const dispatch = useDispatch()

  let currentBalance = ''
  let prepaidEvents = {}

  const enabledFat = useSelector(selectEnableFat)
  const hasBillDiscountPermission = useSelector(
    selectCanIDO(permissions.bill_discount_ops_access.id),
  ) && enabledFat

  const subscriptionId = getMsisdn(subscription)
  const prepaidLine = subscriptionId && useSelector(selectPrepaidLineData(subscriptionId))
  const fats = getFATs(subscription, [])

  if (prepaidLine) {
    currentBalance = get(prepaidLine, 'currentBalance').replace('.', ',')
    prepaidEvents = get(prepaidLine, 'prepaidEvents').filter(
      event => get(event, 'amount_without_vat') > 0,
    )
  }
  const actions = [
    {
      label: 'HISTÓRICO',
      to: `${location.pathname}/prepaid-detail`,
      dataHook: 'prepaid-detail',
    },
  ]

  useEffect(() => {
    if (isPrepaid) {
      dispatch(findPrepaidLine(subscriptionId))
    }
  }, [subscriptionId])

  const today = Date.now()
  const monthIntervalNorm = getNormInterval({
    start: startOfMonth(today),
    end: endOfMonth(today),
  })

  const tariffRanges = getCurrentMonthTariffRanges(subscription)

  const billingAddress = useSelector(state => customerSelectors.getBillingAddress(state))
  const postCode = get(billingAddress, 'postCode')
  const customerSegment = useSelector(getSegment)
  const taxNeeded = !isProCustomer(customerSegment)
  const tax = (taxNeeded && useSelector(state => getTax(state, postCode))) || 0

  const subscriptionType = subscription?.type
  const tariffs = useSelector(selectTariffsApigeeDataTariffs)
  const relatedMobileTariff = getConvergentRelatedMobileTariff(subscriptionType, tariffs) || {}
  const prices = get(subscription, 'prices.data')
  const tariffId = get(subscription, 'type')
  const multisimType = get(subscription, 'multisimType')
  const tariff = useSelector(selectTariffsApigeeById(tariffId))
  const is2pOrFijo = is2pTariff(tariff) || is2pFreeLineTariff(tariff) || isElFijoTariff(tariff)
  const enabledTaxAgileTv = useSelector(selectEnabledTaxSvaAgileTv)

  const enrichedTariffRanges = useSelector(state => {
    return map(
      addCurrentMonthEstimatedFeeWithTaxes(state, tariffRanges, tax, prices, multisimType),
      enrichedTariffRange => ({
        ...enrichedTariffRange,
        commercial_name: getCommercialName(
          selectTariffsApigeeById(enrichedTariffRange.tariffId)(state),
          false,
          multisimType,
        ),
      }),
    )
  })

  const totalExpenses = useSelector(state =>
    getCurrentMonthEstimatedTotalExpenses(
      state,
      subscription,
      tax,
      VAS,
      prices,
      is2pOrFijo,
      taxNeeded,
      fats,
      multisimType,
    ),
  )

  const totalExpensesWithoutFats = useSelector(state =>
    getCurrentMonthEstimatedTotalExpenses(
      state,
      subscription,
      tax,
      VAS,
      prices,
      is2pOrFijo,
      taxNeeded,
      null,
      multisimType,
    ),
  )

  const activeBundlesWithFees = useSelector(
    state => getActiveBundlesWithFees(state, subscription, tax) || [],
  )

  const bundleGroups = chain(activeBundlesWithFees)
    .groupBy('value')
    .map(bundleGroup => ({
      count: bundleGroup.length,
      id: head(bundleGroup),
      name: formatBundleName(head(bundleGroup)),
      nameCountSuffix: bundleGroup.length > 1 ? ` (x${bundleGroup.length})` : '',
      totalFee: bundleGroup.reduce(
        (sum, bundle) => sum + get(bundle, 'monthly_fee_with_tax', 0),
        0,
      ),
    }))
    .value()

  const enrichedDiscounts = compact(
    map(getDiscountsCard(subscription), discount => {
      let { isPercentage } = discount
      let amountWithTax = isPercentage ? '-' : applyTax(tax, discount.amount)

      if (isCrossSellDiscount(discount.discountId)) {
        const relatedFixedTariffDiscountAmount = calculateCrossSellDiscount(discount, {
          prices,
          taxNeeded,
          is2pOrFijo,
        })

        isPercentage = false
        amountWithTax = relatedMobileTariff ? relatedFixedTariffDiscountAmount : 0
      }

      return {
        ...discount,
        currMonthRange: intersection(monthIntervalNorm, discount),
        amountWithTax,
        isPercentage,
      }
    }),
  )

  const enrichedVas = VAS.map(vas => ({
    ...vas,
    currMonthRange: intersection(monthIntervalNorm, {
      start: new Date(vas.validFrom),
      end: new Date(vas.validTo),
    }),
  }))

  const restExpensesWithTaxes = applyTax(tax, getTotalExtras(subscription))
  const restDiscounts = enrichedDiscounts.filter(discount => isHomeGoDiscount(discount.discountId))

  const consumptionData = {
    enrichedTariffRanges,
    restExpenses: restExpensesWithTaxes,
    terminalsFee: getTerminalsFee(subscription),
    VAS: enrichedVas,
    VASFee: getMontlhyVasFee(subscription, tax, taxNeeded, enabledTaxAgileTv),
    hasPromos: hasPromos(subscription),
    discounts: enrichedDiscounts.filter(discount => !isHomeGoDiscount(discount.discountId)),
    hasDiscounts: hasDiscounts(subscription),
    restDiscounts,
    activeBundlesWithFees,
    bundleGroups,
    totalExpenses,
    totalExpensesWithoutFats,
  }

  const prepaidData = {
    isLoadingPrepaid: useSelector(selectIsLoading),
    errorsPrepaid: useSelector(selectErrorMessage),
    currentBalance,
    prepaidEvents: orderBy(prepaidEvents, [event => event.date], ['desc']),
    actions,
  }

  return (
    <MonthlyConsumptionCard
      consumptionData={consumptionData}
      UIActionUpdate={UIActionUpdate}
      highLigthedUI={highLigthedUI}
      prepaidData={prepaidData}
      isPrepaid={isPrepaid}
      fats={fats}
      tax={tax}
      taxNeeded={taxNeeded}
      billDiscountPermission={hasBillDiscountPermission}
    />
  )
}

MonthlyConsumptionContainer.propTypes = {
  subscription: PropTypes.object.isRequired,
  VAS: PropTypes.arrayOf(PropTypes.object),
  UIActionUpdate: PropTypes.func,
  highLigthedUI: PropTypes.object,
  isPrepaid: PropTypes.bool,
}
