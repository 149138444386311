import { constants } from './constants'

const findOrderStatusDesc = (orderStatusId, fixedStatusId) => ({
  type: constants.FIND_ORDER_STATUS_DESC,
  payload: { orderStatusId, fixedStatusId },
})

const findOrderStatusDescSucceeded = data => ({
  type: constants.FIND_ORDER_STATUS_DESC_SUCCEEDED,
  payload: { data },
})

const findOrderStatusDescFailed = error => ({
  type: constants.FIND_ORDER_STATUS_DESC_FAILED,
  payload: error,
})

export const actions = {
  findOrderStatusDesc,
  findOrderStatusDescSucceeded,
  findOrderStatusDescFailed,
}
