export const permissions = {
  // Additional operations
  additional_ops_access: {
    id: 'additional_ops_access',
    desc: 'Allow aditional operations access',
  },

  // Additional activity register
  additional_activity_register: {
    id: 'additional_activity_register',
    desc: 'Allow view additional information of activity register',
  },

  // Client operations
  client_ops_access: {
    id: 'client_ops_access',
    desc: 'Allow client operations access',
  },

  // Bill download
  bill_download: {
    id: 'bill_download',
    desc: 'Allow bill download',
  },

  // Typification operations
  typification_ops_access: {
    id: 'typification_ops_access',
    desc: 'Allow typifications operations access',
  },

  typification_iframe_access: {
    id: 'typification_iframe_access',
    desc: 'Allow typifications iframe access',
  },

  // Credit and FAT operations
  credit_fat_ops_access: {
    id: 'credit_fat_ops_access',
    desc: 'Allow credit and FAT operations access',
  },

  // Barring operations
  barring_ops_access: {
    id: 'barring_ops_access',
    desc: 'Allow barring operations access',
  },

  // Bill discount operations
  bill_discount_ops_access: {
    id: 'bill_discount_ops_access',
    desc: 'Allow discount bill operations access',
  },

  // Edit IBAN operation
  bill_edit_data: {
    id: 'bill_edit_data',
    desc: 'Allow edit iban',
  },

  // CVM operations
  cvm_ops_access: {
    id: 'cvm_ops_access',
    desc: 'Allow CVM operations access',
  },
  cvm_ops_tariff_change: {
    id: 'cvm_ops_tariff_change',
    desc: 'Allow CVM tariff change',
  },
  cvm_ops_add_discounts_all: {
    id: 'cvm_ops_add_discounts_all',
    desc: 'Allow CVM add all types of discounts',
  },
  cvm_ops_add_retention_discounts: {
    id: 'cvm_ops_add_retention_discounts',
    desc: 'Allow CVM add discounts of 10%',
  },
  cvm_ops_disable_discounts_all: {
    id: 'cvm_ops_disable_discounts_all',
    desc: 'Allow CVM disable all types of discounts',
  },
  cvm_ops_add_multisim_discounts: {
    id: 'cvm_ops_add_multisim_discounts',
    desc: 'Allow CVM add multisim discounts',
  },
  cvm_ops_add_bundles_all: {
    id: 'cvm_ops_add_bundles_all',
    desc: 'Allow CVM add all types of bundles',
  },
  cvm_ops_modify_permanency_fixed: {
    id: 'cvm_ops_modify_permanency_fixed',
    desc: 'Allow CVM modify permanency for fixed part',
  },
  cvm_ops_modify_permanency_mobile: {
    id: 'cvm_ops_modify_permanency_mobile',
    desc: 'Allow CVM modify permanency for mobile part',
  },
  // cvm_ops_freeze_package: {
  //   id: 'cvm_ops_freeze_package',
  //   desc: 'Allow CVM to freeze packages',
  // },
  // cvm_ops_break_package: {
  //   id: 'cvm_ops_break_package',
  //   desc: 'Allow CVM to break packages',
  // },
  cvm_ops_unsubscribe_package: {
    id: 'cvm_ops_unsubscribe_package',
    desc: 'Allow CVM to unsubscribe packages',
  },
  cvm_ops_add_devices: {
    id: 'cvm_ops_add_devices',
    desc: 'Allow CVM to add devices/terminals',
  },
  cvm_ops_add_mobile_line: {
    id: 'cvm_ops_add_mobile_line',
    desc: 'Allow CVM to add mobile line',
  },
  cvm_modify_contact_email: {
    id: 'cvm_modify_contact_email',
    desc: 'Allow CVM to modify customer contact email',
  },
  cvm_modify_contact_phone_numbers: {
    id: 'cvm_modify_contact_phone_numbers',
    desc: 'Allow CVM to modify customer contact phone numbers',
  },
  cvm_commercial_migration: {
    id: 'cvm_commercial_migration',
    desc: 'Allow CVM to add commercial migration',
  },
  // ENERGY
  cvm_energy_sale: {
    id: 'cvm_energy_sale',
    desc: 'Allow to sale an energy product',
  },
  energy_go_crm: {
    id: 'energy_go_crm',
    desc: 'Allow go to lucera CRM',
  },
  energy_view_contracts: {
    id: 'energy_view_contracts',
    desc: 'Allow to view energy contracts of client',
  },

  view_complete_account_number: {
    id: 'view_complete_account_number',
    desc: 'Allow to view the complete account number',
  },
  // DOCTOR GO
  cvm_doctor_go: {
    id: 'cvm_doctor_go',
    desc: 'Allow to enable/disable Doctor Go',
  },
  // MULTISIM
  cvm_multi_sim: {
    id: 'cvm_multi_sim',
    desc: 'Allow to sale multiSIM',
  },
  // INSURANCE
  cvm_insurance_sale: {
    id: 'cvm_insurance_sale',
    desc: 'Allow to sale an insurance product',
  },
  // INSURANCE PPI
  cvm_insurance_ppi_sale: {
    id: 'cvm_insurance_ppi_sale',
    desc: 'Allow to sale an insurance ppi product',
  },
  // DETAIL USE SUBSCRIPTION
  view_detail_use_subscription: {
    id: 'view_detail_use_subscription',
    desc: 'Allow to view detail use in subscription',
  },
}
