import { find, filter, negate, isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import { Badge } from 'modules/ui'

export const STATUSES = [
  {
    id: 'enCurso',
    descES: 'En curso',
    badgeType: 'validated',
  },
  {
    id: 'finalizada',
    descES: 'Finalizado',
    badgeType: 'blocked',
  },
  {
    id: 'UNKNOWN',
    descES: '-',
    badgeType: 'info',
  },
]

export function VoiceCallsStatusBadge({ className, status, msg }) {
  const statusObj = find(STATUSES, { id: status }) || STATUSES.UNKNOWN
  const fullMsg = filter([statusObj.descES, msg], negate(isEmpty)).join(' ')

  return (
    <Badge className={className} strong type={statusObj.badgeType} value={{ label: fullMsg }} />
  )
}

VoiceCallsStatusBadge.propTypes = {
  msg: PropTypes.string,
  className: PropTypes.string,
  status: PropTypes.string,
}
