import { constants } from './constants'

const initialState = {
  discountsIncompatibilities: [],
  matrix: {},
  error: null,
  loading: false,
}

const updateMatrix = (matrix, discountCode, incompatibilities) => {
  const map = incompatibilities.reduce((acc, inc) => {
    acc[inc.discountCode] = inc.rule
    return acc
  }, {})
  return {
    ...matrix,
    [discountCode]: {
      ...matrix[discountCode],
      ...map,
    },
  }
}

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case constants.GET_DISCOUNTS_INCOMPATIBILITIES_INIT: {
      return {
        ...state,
        discountsIncompatibilities: [],
        error: null,
        loading: true,
      }
    }
    case constants.GET_DISCOUNTS_INCOMPATIBILITIES_SUCCESS: {
      return {
        ...state,
        discountsIncompatibilities: payload.discountsIncompatibilities,
        matrix: updateMatrix(
          state.matrix,
          payload.discountCode,
          payload.discountsIncompatibilities,
        ),
        loading: false,
      }
    }

    case constants.GET_DISCOUNTS_INCOMPATIBILITIES_FAILED: {
      return {
        ...state,
        error: payload.error,
        loading: false,
      }
    }

    default:
      return state
  }
}
