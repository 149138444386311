import styled from 'styled-components'
import { CurrentAndNewFees } from './CurrentAndNewFees'

export const CurrentAndNewFeesUI = styled(CurrentAndNewFees)`
  flex: 1;

  & .tabs-div {
    & .tabs {
      min-height: auto;
      padding: 0px;

      & .tab-root {
        min-width: auto;
        min-height: auto;
        margin-right: 20px;
      }
    }
  }
`
