import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import { useFormikContext } from 'formik'

import { Button } from 'components/ui'
import { useStepNavigation } from 'utils/navigation'

import { withDeviceRenewalForms } from '../../hocs/withDeviceRenewalForms'
import { RenewalSaveChangesStepButton } from '../../components/RenewalSaveChangesStepButton'
import { ORDERED_DEVICE_RENEWAL_STEPS } from '../../constants'
import { generateOrder as generateOrderAction } from '../../store/device-renewal.actions'
import { billingContainerSelectors } from './BillingContainer.selectors'
import { Billing } from './Billing'

export const BillingContainer = withDeviceRenewalForms(({ step }) => {
  const dispatch = useDispatch()

  const { paymentInfo, isYoigoStoreShipping } = useSelector(billingContainerSelectors)

  const { validateForm, errors } = useFormikContext()

  const { navigateToNextStep, goBack } = useStepNavigation(ORDERED_DEVICE_RENEWAL_STEPS, step)

  const generateOrder = () => {
    dispatch(generateOrderAction())
  }

  useEffect(() => {
    validateForm()

    if (paymentInfo?.amount === 0) {
      generateOrder()
      navigateToNextStep()
    }
  }, [paymentInfo])

  const props = {
    paymentInfo,
    isYoigoStoreShipping,
  }

  return (
    <>
      <Billing {...props} />

      <RenewalSaveChangesStepButton
        stepName={step}
        disabled={!isEmpty(errors)}
        afterContinue={generateOrder}
      />

      <Button margin="0 0 0 16px" secondary onClick={goBack} data-hook="exit-device-renew">
        Atrás
      </Button>
    </>
  )
})

BillingContainer.propTypes = {
  step: PropTypes.string,
}
