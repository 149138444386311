import PropTypes from 'prop-types'

export const Terminal = {
  id: '',
  manufacturer: '',
  category: '',
  paymentType: '',
  idDueDate: '',
  job: '',
  salary: '',
  company: '',
  details: {},
  paymentResult: {},
}

export const TerminalSummaryInterface = PropTypes.shape({
  computedPrices: PropTypes.shape({
    initialPayment: PropTypes.number,
    monthlyPayment: PropTypes.number,
    finalPayment: PropTypes.number,
    totalPayment: PropTypes.number,
    fees: PropTypes.number,
  }),
  terminalPaymentOrderId: PropTypes.number,
  paymentType: PropTypes.string,
  details: PropTypes.shape({
    name: PropTypes.string,
  }),
})
