import PropTypes from 'prop-types'
import styled from 'styled-components'
import { ButtonCard } from 'components/ui'
import { APP_CONFIG } from 'services/app-config'
import { get } from 'lodash'

const modalText = 'Parece que el cliente ha desaparecido o se ha dado de baja'

const CancelledCustomer = styled(({ className, searchKey, showButton = true }) => {
  const action = {
    label: 'Ver suscripciones en Newton',
    to: `${APP_CONFIG.cancelled_clients}?searchkey=${searchKey}`,
    target: '_blank',
  }

  return (
    <div className={`${className} cancelled-client`} data-hook="cancelled-client">
      <img alt={modalText} src="/assets/cancelled_client.svg" />
      <h1 className="cancelled-client-title">{modalText}</h1>
      {showButton && <ButtonCard key={action.label} {...action} />}
    </div>
  )
})`
  background: ${props => get(props, 'theme.palette.common.white')};
  height: 650px;
  width: 97%;
  border-radius: 14px;
  margin: 24px;
  box-shadow: 0 0 10px 1px rgba(137, 139, 140, 0.2);
  padding: 30px 70px;
  line-height: 1.2em;
  letter-spacing: normal;
  text-align: center;
  color: ${props => get(props, 'theme.palette.brand.medium')};
  .cancelled-client-title {
    line-height: 28px;
    padding: 10px 0;
    font-size: 24px;
    margin-top: 25px;
    margin-bottom: 20px;
  }
  .button-card {
    width: 296px;
    height: 36px;
    color: ${props => get(props, 'theme.palette.common.white')};
    background-color: ${props => get(props, 'theme.palette.bluePalette.main')};
    font-size: 14px;
  }
`

CancelledCustomer.propTypes = {
  className: PropTypes.string,
  msisdn: PropTypes.string.isRequired,
  showButton: PropTypes.bool,
}

export default CancelledCustomer
