import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { Box } from '@material-ui/core'

import { ContainerTitle, CardRow, CardRowLabel, CardRowValue } from 'components'

import { Modal } from '../Modal'

const ClientInfo = ({
  customerInfo,
  customer,
  coverage,
  customerTariff,
  installer,
  showInstallerInfo,
}) => {
  const [address, setAddress] = useState('')

  useEffect(() => {
    if (customerInfo) {
      setAddress(customerInfo.address)
    }
  }, [customerInfo])

  let data = <h2> Ooops... </h2>

  if (customer) {
    data = (
      <Box display="flex" flexDirection="column" alignItems="center">
        <ContainerTitle data-hook="current-client-info">INFORMACIÓN ACTUAL</ContainerTitle>
        <CardRow>
          <CardRowLabel>Dirección de instalación:</CardRowLabel>
          <CardRowValue>{address}</CardRowValue>
        </CardRow>
        {customerTariff !== '' && (
          <CardRow>
            <CardRowLabel>Tarifa actual:</CardRowLabel>
            <CardRowValue>{customerTariff}</CardRowValue>
          </CardRow>
        )}
        {showInstallerInfo && installer && (
          <CardRow>
            <CardRowLabel>Instalación:</CardRowLabel>
            <CardRowValue>
              {installer === 'MM' ? 'Instalación propia' : 'Instalación de Telefónica'}
            </CardRowValue>
          </CardRow>
        )}
      </Box>
    )
  }
  return (
    <>
      {data}

      <Modal
        open={coverage}
        title="Aviso Importante!"
        message="Servicio de Fibra no está disponible en esta dirección."
        cancelBtn="Salir"
        quit
      />
    </>
  )
}

ClientInfo.propTypes = {
  customer: PropTypes.object.isRequired,
  customerInfo: PropTypes.object,
  coverage: PropTypes.bool,
  installer: PropTypes.string,
  showInstallerInfo: PropTypes.bool,
  customerTariff: PropTypes.string,
}

export default ClientInfo
