import { createSelector } from 'reselect'
import {
  selectIsAlarmsCadenceError,
  selectIsAlarmsCadenceSuccess,
  selectIsAlarmsCadenceLoading,
  selectAlarmsCadenceErrorCode,
  selectAlarmsCadenceErrorMsg,
} from 'services/cadence-flow/selectors'
import {
  selectIsPurchaseAlarmSuccess,
  selectIsPurchaseAlarmError,
  selectPurchaseAlarmErrorMsg,
  selectIsPurchaseAlarmLoading,
  selectPurchaseAlarmErrorCode,
} from 'services/customer-sva/selectors'

export const selectIsAlarmsSuccess = createSelector(
  [selectIsAlarmsCadenceSuccess, selectIsPurchaseAlarmSuccess],
  (isAlarmsCadenceSuccess, isPurchaseAlarmSuccess) =>
    isAlarmsCadenceSuccess || isPurchaseAlarmSuccess,
)

export const selectIsAlarmsError = createSelector(
  [selectIsAlarmsCadenceError, selectIsPurchaseAlarmError],
  (isAlarmsCadenceError, isPurchaseAlarmError) => isAlarmsCadenceError || isPurchaseAlarmError,
)

export const selectIsAlarmsLoading = createSelector(
  [selectIsAlarmsCadenceLoading, selectIsPurchaseAlarmLoading],
  (isAlarmsCadenceLoading, isPurchaseAlarmLoading) =>
    isAlarmsCadenceLoading || isPurchaseAlarmLoading,
)

export const selectAlarmsErrorCode = createSelector(
  [selectAlarmsCadenceErrorCode, selectPurchaseAlarmErrorCode],
  (alarmsCadenceErrorCode, purchaseAlarmErrorCode) =>
    alarmsCadenceErrorCode || purchaseAlarmErrorCode,
)

export const selectAlarmsErrorMsg = createSelector(
  [selectAlarmsCadenceErrorMsg, selectPurchaseAlarmErrorMsg],
  (alarmsCadenceErrorMsg, purchaseAlarmErrorMsg) => alarmsCadenceErrorMsg || purchaseAlarmErrorMsg,
)
