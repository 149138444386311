import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { COMPANY } from 'services/global-constants'
import { Box, Divider } from '@material-ui/core'
import { ButtonLink } from 'components/ui'
import { CompanyDataSummary, ClientDataSummary } from 'modules/Client'
import { selectIsPosOrMasProximo } from 'modules/Permissions'

const MODIFY_COMPANY_DATA_MSG = 'Modificar Datos de administrador y empresa'
const MODIFY_PERSONAL_DATA_MSG = 'Modificar datos personales'

export function PersonalDataSummary({
  account,
  account: { segment },
  onClickChangePersonalData,
  isMultiSim,
}) {
  const isPosOrMasProximo = useSelector(selectIsPosOrMasProximo)

  const isCompany = segment === COMPANY
  return (
    <>
      {isCompany ? (
        <>
          <Box pb="24px">
            <ClientDataSummary {...account} isCompany isPosOrMasProximo={isPosOrMasProximo} />
          </Box>

          <Divider />

          <CompanyDataSummary {...account} isPosOrMasProximo={isPosOrMasProximo} />
        </>
      ) : (
        <ClientDataSummary {...account} isPosOrMasProximo={isPosOrMasProximo} />
      )}
      {!isMultiSim && (
        <Box mt="8px">
          <ButtonLink to="info?isEditing=true" onClick={onClickChangePersonalData}>
            {isCompany ? MODIFY_COMPANY_DATA_MSG : MODIFY_PERSONAL_DATA_MSG}
          </ButtonLink>
        </Box>
      )}
    </>
  )
}

PersonalDataSummary.propTypes = {
  account: PropTypes.object,
  onClickChangePersonalData: PropTypes.func,
  isMultiSim: PropTypes.bool,
}
