import PropTypes from 'prop-types'
import styled from 'styled-components'
import { isNil } from 'lodash'

import { Typography } from '@material-ui/core'
import { TextStyles } from 'utils/text'

import { HighLightFrame } from 'modules/ui/components/HighLightFrame'
import { formatStringToNumber } from 'utils'
import { ButtonLink } from './../buttons/ButtonLink'

const Label = styled.span`
  display: inline-block;
  margin-bottom: 8px;
  font-weight: bold;
`

const Value = styled.span`
  margin-left: 0.5em;
`

const Price = styled(props => {
  const { className, title, price, monthly, aux, linkText, quotas, subscriptionId } = props

  let formattedPriceToNumber
  if (!isNil(price)) formattedPriceToNumber = formatStringToNumber(price)

  return (
    <div className={`price ${className}`}>
      <HighLightFrame />
      <Typography
        {...TextStyles.subtitle2Dark({
          className: 'title',
        })}>
        {title}:
      </Typography>
      {formattedPriceToNumber >= 0 ? (
        <div>
          <Typography
            {...TextStyles.title1BoldDark({
              className: 'price-item',
              component: 'span',
            })}>
            {price}
          </Typography>
          {/* TODO: Ask nieves for either new textstyle of 18px or use this one of 20px */}
          <Typography
            {...TextStyles.title2Dark({
              className: 'price-item',
              component: 'span',
            })}>
            <strong>{monthly ? '€/mes' : '€'}</strong>
          </Typography>

          <Typography
            {...TextStyles.labelDark({
              className: 'aux',
              component: 'p',
            })}>
            {aux}
          </Typography>
        </div>
      ) : (
        quotas && (
          <div className="listItem">
            {quotas.map((item, i) => (
              <Typography
                {...TextStyles.labelDark()}
                key={`quotas-${subscriptionId}-${i.toString()}`}>
                <Label>
                  {item.label} {item.period && `(${item.period})`} {monthly ? ' €/mes' : ':'}
                </Label>
                <Value>{monthly ? `(${item.value})` : `${item.value} €`}</Value>
              </Typography>
            ))}
          </div>
        )
      )}
      {linkText && <ButtonLink>Link</ButtonLink>}
    </div>
  )
})`
  &.price {
    position: relative;
  }

  .price-list {
    display: flex;
  }

  .price-item {
    padding-right: 8px;
  }

  .title {
    margin-bottom: 4px;
  }

  .price-item {
    margin-bottom: 5px;
    span {
      font-size: 18px;
      &:before {
        content: '';
      }
    }
  }
  .aux {
    margin-top: 4px;
    margin-bottom: 8px;
  }
  .listItem {
    margin-top: 14px;
  }
`

Price.propTypes = {
  title: PropTypes.string,
  price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  monthly: PropTypes.bool,
  aux: PropTypes.string,
  linkText: PropTypes.bool,
  quotas: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  subscriptionId: PropTypes.string,
}

export default Price
