import { getIn } from 'formik'
import { getMobileTariff, getMobileNumberType } from 'modules/Lines'
import { setDiscountItems, formatPriceWithPackages } from '../../store'
import { getFormikSelectors as getFormikLineSelectors } from '../../containers/OfferContainer/LandlineContainer/LandlineContainer.selectors'

export function getFormikSelectors(fieldNames, setFieldValue, values) {
  // TODO: remove useFormikContext from this helper as uses nested useFormikContext (inside getFormikLineSelectors)
  const lineSelectors = getFormikLineSelectors(fieldNames, setFieldValue, values)
  const signUpType = getIn(values, fieldNames.MOBILE_LINE_TYPE)
  const portaPaymentType = getIn(values, fieldNames.PORTABILITY_PAYMENT_TYPE)
  const selectedTariff = getIn(values, fieldNames.TARIFF)

  const selectedValues = {
    portaPaymentType,
    signUpType,
    ...lineSelectors,
    enabledSearchNewNumber: getIn(values, fieldNames.NEW_LINE_SEARCH),
    selectedPackages: getIn(values, fieldNames.SELECTED_PACKAGES),
    newFixedNumber: getIn(values, fieldNames.NEW_FIXED_NUMBER_VALUE),
    mobileNumberOptions: getIn(values, fieldNames.NEW_NUMBER_OPTIONS),
    jobId: getIn(values, fieldNames.JOB),
    isPortabilityDatePostponed: getIn(values, fieldNames.POSTPONE_PORTABILITY),
    portabilityPerson: getIn(values, fieldNames.PORTABILITY_PERSON),
    otherUserDocument: getIn(values, fieldNames.DOCUMENT_TYPE),
    isAdditionalType: getIn(values, fieldNames.TARIFF_TYPE) === 'additional_line',
    portaDate: getIn(values, fieldNames.POSTPONE_PORTABILITY_NEW_DATE),
    isNewTerminal: getIn(values, fieldNames.HAS_TERMINAL) === 'yes',
    tariffName: `Móvil ${
      fieldNames.LINE_TYPE.startsWith('main') ? 'Cabecera' : ''
    } ${getMobileTariff(selectedTariff)}`,
    mobileNumberType: getMobileNumberType(signUpType, portaPaymentType),
    promos: setDiscountItems(
      getIn(values, fieldNames.TARIFF),
      getIn(values, fieldNames.SELECTED_PACKAGES),
    ),
    finalPrice: formatPriceWithPackages(
      getIn(values, fieldNames.TARIFF),
      getIn(values, fieldNames.SELECTED_PACKAGES),
    ),
    imei: getIn(values, fieldNames.IMEI),
  }

  return {
    ...selectedValues,
  }
}
