import { useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { Divider, Typography, Box, Icon } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { TextStyles } from 'utils/text'
import { ICONS } from 'utils/icons'
import { AppLauncher } from 'components'

import { selectDocumentId, selectSfid, selectCustomerIsLoading, selectCustomerLoaded } from 'modules/Auth'
import { selectShowMigrationCustomerMessage, selectNewTextFrozenClient } from 'services/feature-flag/selectors'

import { selectMigrationStatus } from 'modules/Auth/store/selectors'
import { getError, getLoading } from 'services/customer-360/selectors'
import { HeaderProfile } from './HeaderProfile.component'

const HeaderProfileWidget = {
  key: 'headerProfile',
  component: HeaderProfile,
}
function HeaderWidgets({ widgets, isD2D, onClickPowerButton }) {
  return widgets.map(widget => {
    const Component = widget.component
    return (
      <div className="widget p-l-20" key={widget.key}>
        <Component isD2D={isD2D} onClickPowerButton={onClickPowerButton} />
      </div>
    )
  })
}

const useStyles = makeStyles(theme => ({
  header: {
    position: 'relative',
    zIndex: 1,
    height: '64px',
    backgroundColor: 'white',
    display: 'flex',
    alignItems: 'center',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2)',

    '& .menu-button': {
      height: '100%',
      cursor: 'pointer',
      outline: 'none',
      border: 'none',
      color: theme.palette.global.gray_dark,
      backgroundColor: 'white',
    },

    '& .icon-text': {
      textTransform: 'capitalize',
      fontSize: '12px',
    },

    '& hr': {
      backgroundColor: theme.palette.global.gray,
    },

    '& button': {
      backgroundColor: ({ isD2D }) => isD2D && 'white',
    },
  },
  'warning-status': {
    background: '#ffe661',
    padding: '20px 10px', // '15px 10px',
    fontSize: '14px',
    textAlign: 'center',
  },
  boldeable: {
    fontWeight: 'bold',
  },
}))
export function Header({
  icon = 'menu',
  iconText,
  title = '',
  mainContent,
  children,
  widgets = [],
  isD2D,
  onClickHome,
  onClickPowerButton,
}) {
  const classes = useStyles({ isD2D })

  const FROZEN_STATUS = 'frozen'
  const MIGRATED_STATUS = 'migrated'
  const STATUS_LABEL = {
    [FROZEN_STATUS]: 'en proceso de migración',
    [MIGRATED_STATUS]: 'migrado',
  }

  const agentSfid = useSelector(selectSfid)
  const documentId = useSelector(selectDocumentId)
  const isLoaded = useSelector(selectCustomerLoaded) 

  const showMigrationMessage =
    useSelector(selectShowMigrationCustomerMessage) && !useSelector(selectCustomerIsLoading)
  const newTextFrozenClient = useSelector(selectNewTextFrozenClient)
  const migrationStatus = useSelector(selectMigrationStatus) || FROZEN_STATUS
  const migrationsStatusLabel = STATUS_LABEL[migrationStatus]

  const [openAppLauncher, setOpenAppLauncher] = useState(false)
  const headerIcon = isD2D ? 'home' : icon
  const headerText = isD2D ? 'inicio' : iconText
  const onClick = isD2D ? onClickHome : setOpenAppLauncher

  const customer360Loading = useSelector(getLoading)
  const customer360Error = useSelector(getError)

  let text

  if(migrationStatus === FROZEN_STATUS) {
    if(newTextFrozenClient) {
      text = 
      (
        <>
          <span>  Para ejecutar alguna acción sobre este cliente, 
            incluye sus datos en la WEB de migración donde                 
            la web sea un enlace a&nbsp;
          </span> 
          <a target="_blank" href="https://topertoolrfm.masmovil.com" rel="noreferrer">
            https://topertoolrfm.masmovil.com 
          </a>
        </>
      )
    } else {
      text = ` Por favor, no
      realices ninguna operación sobre él. Toda operación se debe realizar en el nuevo stack /
      Agents / MySIM.`
    }
  } else {
    text = ` Por favor, no realices ninguna operación sobre él. Toda operación se debe realizar en el nuevo stack / Agents / MySIM.`
  }

  return (
    <>
      {openAppLauncher && (
        <AppLauncher onClosing={setOpenAppLauncher} agentSfid={agentSfid} documentId={documentId} />
      )}
      <div className={classes.header}>
        <button
          data-hook="app-launcher-button"
          type="button"
          className="p-l-20 p-r-20 flex flex-column align-center-center menu-button"
          onClick={() => onClick(true)}>
          <Icon>{ICONS[headerIcon]}</Icon>
          {iconText && <p className="icon-text">{headerText}</p>}
        </button>
        <Divider orientation="vertical" />
        <div className="p-r-20">
          <Typography
            {...TextStyles.title2Dark({
              className: 'p-l-20',
            })}>
            {title}
          </Typography>
        </div>
        <Box px="20px" maxWidth="100%" flexGrow="1">
          {mainContent || children}
        </Box>
        {!isD2D && <Divider orientation="vertical" />}
        <HeaderWidgets
          isD2D={isD2D}
          className="p-x-20"
          onClickPowerButton={onClickPowerButton}
          widgets={widgets.length ? [...widgets, HeaderProfileWidget] : [HeaderProfileWidget]}
        />
      </div>
      {isLoaded && !customer360Loading && !customer360Error && showMigrationMessage && migrationsStatusLabel && (
        <div className={classes['warning-status']}>
          Cliente <span className={classes.boldeable}>{migrationsStatusLabel}</span>.
          {text}
        </div>
      )}
    </>
  )
}

Header.propTypes = {
  isD2D: PropTypes.bool,
  icon: PropTypes.string,
  title: PropTypes.any,
  iconText: PropTypes.any,
  mainContent: PropTypes.element,
  onClickHome: PropTypes.func,
  onClickPowerButton: PropTypes.func,
  children: PropTypes.arrayOf(PropTypes.element),
  widgets: PropTypes.arrayOf(
    PropTypes.shape({ key: PropTypes.string.isRequired, component: PropTypes.component }),
  ),
}
