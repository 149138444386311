/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/no-cycle */
import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { get } from 'lodash'
import { Popover as PopoverCore } from '@material-ui/core'

const Arrow = styled(({ className }) => {
  return <div className={className} />
})`
  background-color: inherit;
  position: absolute;
  width: 15px;
  height: 15px;
  transform: rotate(-45deg);
  top: -8px;
  left: ${props => props.pos === 'left' && '5px'};
  right: ${props => props.pos === 'right' && '5px'};
  border: 1px solid ${props => get(props, 'theme.palette.global.gray_medium_2')};
  border-left: 1px solid transparent;
  border-bottom: 1px solid transparent;
`

export const Popover = styled(
  ({ id, hasArrow, arrowPos, popoverTarget, children, className, ...rest }) => {
    const [anchorElement, setAnchorElement] = useState(null)

    const handlePopoverClose = () => {
      setAnchorElement(null)
    }
    const open = Boolean(anchorElement)

    useEffect(() => {
      setAnchorElement(popoverTarget)
    }, [popoverTarget])

    const defaultAnchorOrigin = {
      vertical: 'bottom',
      horizontal: 'center',
    }

    const defaultTransformOrigin = {
      vertical: 'top',
      horizontal: 'left',
    }

    return (
      <PopoverCore
        {...rest}
        id={id}
        className={className}
        open={open}
        anchorEl={popoverTarget}
        anchorOrigin={rest.anchorOrigin || defaultAnchorOrigin}
        transformOrigin={rest.transformOrigin || defaultTransformOrigin}
        elevation={rest.elevation || 1}
        onClose={handlePopoverClose}>
        {children}
        {hasArrow && <Arrow pos={arrowPos} className={`${className} popover-arrow`} />}
      </PopoverCore>
    )
  },
)`
  pointer-events: none;

  & .MuiPopover-root {
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.2);
    background-color: ${props => get(props, 'theme.palette.brand.light')};
  }

  & .MuiPopover-paper {
    margin-top: 8px;
    border-radius: 12px;
    padding: 16px 24px;
    position: absolute;
    overflow: visible;
  }

  & .popover-arrow {
    border-top: 1px solid rgba(0, 0, 0, 0.03);
    border-right: 1px solid rgba(0, 0, 0, 0.03);
  }
`

Popover.propTypes = {
  arrowLeft: PropTypes.string,
  hasArrow: PropTypes.bool,
}
