import { axiosJWT } from 'modules/axios'
import { CONFIG } from './environments'

export function getDocumentTechDom(changeTechnologyOrderId) {
  return axiosJWT({
    method: 'GET',
    responseType: 'blob',
    url: `${CONFIG.api.techDom}/${changeTechnologyOrderId}`,
  }).then(resp => resp.data)
}
