import * as Yup from 'yup/lib'
import { SINGLE } from 'services/global-constants'
import { REQUIRED_FIELD_TEXT } from 'modules/SharedSales/constants'
import { isValidIdDueDate } from 'utils'
import * as validations from 'modules/SharedSales/constants/validations'

export const terminalFilterSchema = (isPosSale = false, isMobileOnly, disableOfferEdit) => ({
  isNewTerminal: Yup.string().required(REQUIRED_FIELD_TEXT),
  terminal: Yup.object().when('isNewTerminal', {
    is: 'yes',
    then: Yup.object({
      category: Yup.string().required(REQUIRED_FIELD_TEXT),
      manufacturer: Yup.string().required(REQUIRED_FIELD_TEXT),
      id: Yup.string().required(REQUIRED_FIELD_TEXT),
      paymentType: Yup.string().required(REQUIRED_FIELD_TEXT),
      reservationId: isPosSale
        ? Yup.string()
        : Yup.string().required(
            'La reserva de terminal ha fallado. Vuelve a intentarlo o selecciona otro modelo',
          ),
      job:
        isMobileOnly && !disableOfferEdit
          ? Yup.string()
          : Yup.string().when(['$isCompany', 'paymentType'], {
              is: (isCompany, paymentType) => isCompany || paymentType === SINGLE,
              then: Yup.string(),
              otherwise: Yup.string()
                .trim()
                .required(REQUIRED_FIELD_TEXT),
            }),
      salary:
        isMobileOnly && !disableOfferEdit
          ? Yup.string()
          : Yup.string().when(['$isCompany', 'paymentType'], {
              is: (isCompany, paymentType) => isCompany || paymentType === SINGLE,
              then: Yup.string(),
              otherwise: Yup.string()
                .trim()
                .required(REQUIRED_FIELD_TEXT),
            }),
      company: Yup.string().when('job', {
        is: job => !!job,
        then: Yup.string()
          .trim()
          .required(REQUIRED_FIELD_TEXT),
      }),
      idDueDate: Yup.string().when(['$isCompany', 'paymentType'], {
        is: (isCompany, paymentType) => isCompany || paymentType === SINGLE,
        then: Yup.string().trim(),
        otherwise:
          isMobileOnly && !disableOfferEdit
            ? Yup.string()
            : Yup.string()
                .trim()
                .test('ValidateIdDueDate', validations.ERROR_DATA_TEXT, isValidIdDueDate)
                .required(REQUIRED_FIELD_TEXT),
      }),
    }),

    otherwise: Yup.object({
      manufacturer: Yup.string(),
      id: Yup.string(),
      paymentType: Yup.string(),
      job: Yup.string(),
      salary: Yup.string(),
      idDueDate: Yup.string(),
      reservationId: Yup.string(),
    }),
  }),
})
