import { call, put, takeEvery, select } from 'redux-saga/effects'
import { logError, formatErrorMessage } from 'services/logging'
import { axiosFactory } from 'modules/axios'
import { selectors, helpers } from 'services/customer-360'
import { get } from 'lodash'

import { actions } from './actions'
import { constants } from './constants'
import { fetch } from './api'

function* fetchSaga({ payload: { orderId, msisdn } }) {
  try {
    const orderInfo = yield select(selectors.getIdentification)
    const contracts = yield select(selectors.contracts)
    const documentId = get(orderInfo, 'identificationId')
    const msisdnOnFly = yield helpers.getMsisdnByOrderIdFromContracts(contracts, orderId)
    const productId = msisdn || msisdnOnFly
    const data = yield call(fetch, documentId, productId)
    yield put(actions.fetchSuccess(orderId, data))
  } catch (e) {
    yield call(logError, { e: new Error(formatErrorMessage(e)), orderId, msisdn })
    const error = axiosFactory.buildError(e)
    yield put(actions.fetchError(orderId, error))
  }
}

function* watchFetch() {
  yield takeEvery(constants.FETCH, fetchSaga)
}

export const saga = {
  fetchSaga,
  watchFetch,
}
