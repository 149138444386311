import { useEffect, useCallback, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { push } from 'connected-react-router'

import { getDocumentType } from 'modules/CustomerDocument'
import { selectCancelPartialOrders } from 'services/feature-flag/selectors'

import { fetchOrderAction, selectURLOrderId, fetchOrderAdditionalLinesAction } from 'modules/orders'
import { selectURLDocumentId } from 'modules/CustomerDocument/selectors/index'

import { Orders } from '../../components'

import {
  selectOrdersContainerData,
  selectIsNebaAndVulaOrder,
  selectOrderContentLineFiber,
} from './selectors'
import { selectIsVistaAuthorized } from '../../../vista-360/selectors/selectors'

export function OrdersContainer() {
  const dispatch = useDispatch()
  const params = useSelector(selectOrdersContainerData)
  const orderId = useSelector(selectURLOrderId)

  const canCancelPartialOrders = useSelector(selectCancelPartialOrders)

  const documentId = useSelector(selectURLDocumentId)
  const isNeba = useSelector(selectIsNebaAndVulaOrder)
  const isFiber = useSelector(selectOrderContentLineFiber)
  const isVistaEnabled = useSelector(selectIsVistaAuthorized)

  const documentType = useMemo(() => getDocumentType(documentId.toUpperCase()), [documentId])

  useEffect(() => {
    dispatch(fetchOrderAction(orderId))
  }, [orderId])

  const fetchSubOrders = useCallback(() => {
    dispatch(fetchOrderAdditionalLinesAction(documentType, documentId, orderId))
  }, [documentId, documentType, orderId])

  return (
    <Orders
      {...{
        ...params,
        orderId,
        documentId,
        documentType,
        isNeba,
        isFiber,
        navigate: url => dispatch(push(url)),
        isVistaEnabled,
        fetchSubOrders,
        canCancelPartialOrders,
      }}
    />
  )
}
