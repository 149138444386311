import { takeEvery, put } from 'redux-saga/effects'
import { get } from 'lodash'
import { LOCATION_CHANGE } from 'connected-react-router'
import { APP_CONFIG } from 'services/app-config'
import { pageLoadedAction } from './analytics.actions'

function* initialiseAnalytics() {
  const script = document.createElement('script')
  script.type = 'text/javascript'
  script.async = true
  script.innerHTML = `
  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${APP_CONFIG.analytics.id}');
  `
  document.body.appendChild(script)

  const iframe = document.createElement('iframe')
  iframe.src = `https://www.googletagmanager.com/ns.html?id=${APP_CONFIG.analytics.id}`
  iframe.height = '0'
  iframe.width = '0'
  iframe.style = 'display:none;visibility:hidden'

  const noscript = document.createElement('noscript')
  noscript.appendChild(iframe)
  document.body.appendChild(noscript)

  yield put(pageLoadedAction())
}

export function* watchAnalyticsInit() {
  yield takeEvery(
    action => action.type === LOCATION_CHANGE && get(action, 'payload.isFirstRendering'),
    initialiseAnalytics,
  )
}
