import { get, cloneDeep, set } from 'lodash'
import { addAddresData } from '../../helpers/formats.helper'
/* eslint-disable no-param-reassign, max-len */
export const fillMoveAddress = (address, changeAddressJson) => {
  const newAddressJson = cloneDeep(changeAddressJson)
  const contactMedium = get(newAddressJson, 'customer.contactMedium', {})
  const indexFixedLine = contactMedium.findIndex(x => x.type === 'InstallationAddressFixedLine')
  set(newAddressJson, `customer.contactMedium[${indexFixedLine}].medium`, addAddresData(address))
  newAddressJson.characteristics[0].value = 'address'
  newAddressJson.customer.products[0].productsSpecifications[0].productSpecCharacteristic[4].value = get(
    address,
    'coverage[0].characteristics[0].value',
  )
  newAddressJson.customer.products[0].productsSpecifications[0].productSpecCharacteristic[5].value = get(
    address,
    'addressId',
  )
  return newAddressJson
}

export const fillMoveNumber = (number, changeAddressJson) => {
  changeAddressJson.customer.products[0].productNumber = get(number, 'number')
  changeAddressJson.customer.products[0].productsSpecifications[0].id = get(number, 'number')
  changeAddressJson.customer.products[0].productsSpecifications[0].productSpecCharacteristic[0].value = get(
    number,
    'number',
  )
  changeAddressJson.characteristics[1].value = 'true'
  return changeAddressJson
}
/* eslint-enable no-param-reassign, max-len */
