import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { TextStyles } from 'utils/text'

import {
  Checkbox,
  Chip,
  FormHelperText,
  MenuItem,
  Select,
  OutlinedInput,
  Typography,
  Icon,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { ICONS } from 'utils/icons'
import { StyledFormControl, StyledInputLabel } from './componentsUtilsOutline'

const useStyles = makeStyles(theme => ({
  cssLabel: {
    color: theme.palette.global.gray,
    whiteSpace: 'nowrap',
    padding: '0 4px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: 'calc(100% - 46px)',
  },
  cssSelect: {
    width: 'auto',
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'space-around',
    justifyContent: 'flex-start',
    '&:focus': {
      background: 'transparent',
    },
  },
  cssSelectContent: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  cssChip: {
    border: '1px solid',
    borderColor: theme.palette.global.gray_light,
    backgroundColor: theme.palette.brand.light,
    color: theme.palette.global.gray_dark,
    margin: '0.33em',
    height: 'auto',
    lineHeight: '1.5em',
    padding: '0em',
    fontSize: '16px',

    '& .material-icons': {
      color: theme.palette.brand.action_tertiary,
      cursor: 'pointer',
    },
  },
  cssCheckbox: {
    '& *': {
      marginLeft: 'auto',
    },
  },
}))

function MultiSelectOutline({
  value = [],
  onChange,
  minWidth,
  width,
  labelColor,
  labelText,
  name,
  id,
  options,
  error,
  errorText,
  disabled,
}) {
  const classes = useStyles()

  const [selections, selectionAction] = useState([...value])

  const onSelectChange = event => {
    selectionAction(event.target.value)
    if (onChange) {
      onChange(event.target.value)
    }
  }

  const onDeleteSelected = event => {
    const removedValue = event.target.dataset.value
    const newSelection = selections.filter(item => item !== removedValue)
    selectionAction(newSelection)
    if (onChange) {
      onChange(newSelection)
    }
  }

  useEffect(() => {
    selectionAction([...new Set(value)].filter(val => options.map(opt => opt.value).includes(val)))
  }, [value, options])

  return (
    <StyledFormControl variant="outlined" minwidth={minWidth} error={error}>
      <StyledInputLabel
        classes={{
          root: classes.cssLabel,
        }}
        backgroundcolor={labelColor}
        htmlFor={id}
        margin="dense">
        {labelText}
      </StyledInputLabel>
      <Select
        multiple
        disabled={disabled}
        // autoWidth -> prevents menu props weird behaviours when the size of Select grows auto
        autoWidth={!width}
        width={width}
        value={selections}
        onChange={onSelectChange}
        classes={{
          select: classes.cssSelectContent,
          selectMenu: classes.cssSelect,
        }}
        input={<OutlinedInput labelWidth={0} name={name} id={id} margin="dense" />}
        MenuProps={{
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        }}
        renderValue={selected => (
          <>
            {selected.map(_value => (
              <Chip
                key={_value}
                className={classes.cssChip}
                label={
                  (options && options.length && options.find(opt => _value === opt.value).label) ||
                  _value
                }
                color="secondary"
                deleteIcon={
                  <Icon className="icon" data-value={_value} style={{ fontSize: '16px' }}>
                    {ICONS.close}
                  </Icon>
                }
                onDelete={onDeleteSelected}
              />
            ))}
          </>
        )}>
        {!(options && options.length) ? (
          <MenuItem value="">
            <Typography {...TextStyles.paragraphDark()}>No hay datos</Typography>
          </MenuItem>
        ) : (
          options.map(option => (
            <MenuItem key={option.value} value={option.value}>
              <Typography {...TextStyles.paragraphDark()}>
                {option.label || option.value}
              </Typography>
              <Checkbox
                className={classes.cssCheckbox}
                checked={selections.includes(option.value)}
                color="primary"
              />
            </MenuItem>
          ))
        )}
      </Select>
      {error && errorText ? <FormHelperText>{errorText}</FormHelperText> : null}
    </StyledFormControl>
  )
}

MultiSelectOutline.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func,
  width: PropTypes.string,
  minWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  labelColor: PropTypes.string,
  labelText: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  error: PropTypes.bool,
  errorText: PropTypes.string,
  disabled: PropTypes.bool,
}

MultiSelectOutline.defaultProps = {
  minWidth: '',
  id: 'multi-select',
  name: null,
  labelColor: '#fff',
  labelText: '',
  options: [],
  error: false,
  disabled: false,
}

export default MultiSelectOutline
