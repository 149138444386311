import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { ONLY_MOBILE } from 'modules/SharedSales/constants'
import { selectIsMobileOnly } from 'modules/Router/store'

import { INFO, OFFERS, STEPS, OFFER_CONFIG_STEPS } from '../../constants'

import Sidebar from '../../components/Sidebar/Sidebar'
import { selectSidebar } from './SidebarContainer.selectors'
import { selectMainOfferTariff } from '../../store'
import { useMainUser } from '../../hooks/Shared'

function SidebarContainer({ saleType, step }) {
  const {
    account,
    terminals,
    fullAddress,
    isSubscription,
    isMultiSim,
    ...sidebarProps
  } = useSelector(selectSidebar)
  const isMobileOnly = useSelector(selectIsMobileOnly)
  const baseSteps = isMobileOnly ? OFFER_CONFIG_STEPS : STEPS
  const mainOffer = useSelector(selectMainOfferTariff)
  const mainUser = useMainUser(account)
  const saleTypeName = saleType === ONLY_MOBILE ? 'Móvil' : 'Convergente'
  const stepForShowTerminals = saleType === ONLY_MOBILE && isMobileOnly ? OFFERS : INFO
  const firstStep = isMobileOnly ? baseSteps[OFFERS] : baseSteps[INFO]
  const showTerminals = !!(
    baseSteps[step] >= baseSteps[stepForShowTerminals] &&
    terminals &&
    terminals.all.length > 0
  )
  const showClientInfo = baseSteps[step] >= firstStep
  const showOffers =
    isMobileOnly || isSubscription
      ? baseSteps[step] >= firstStep
      : baseSteps[step] >= baseSteps[OFFERS]

  return (
    <Sidebar
      {...sidebarProps}
      mainOffer={mainOffer}
      mainUser={mainUser}
      fullAddress={fullAddress}
      saleTypeName={saleTypeName}
      showClientInfo={showClientInfo}
      showTerminals={showTerminals}
      showOffers={showOffers}
      terminals={terminals}
      saleType={saleType}
      step={step}
      isSubscription={!!isSubscription}
      isMultiSim={!!isMultiSim}
    />
  )
}

SidebarContainer.propTypes = {
  saleType: PropTypes.string.isRequired,
  step: PropTypes.string.isRequired,
}

export default SidebarContainer
