import { useEffect, useState, useMemo } from 'react'
import PropTypes from 'prop-types'

import { useDispatch, useSelector } from 'react-redux'

import { useRequestIpAddress } from 'modules/Auth/hooks/useRequestIpAddress'
import { fetchSvaAgileTv } from 'modules/SVAs/store'

import { Typography } from '@material-ui/core'
import { NotificationModal, Button } from 'components/ui'

import { addAgileTv, fetchAddAgileTvAllowed } from '../../store/add-agiletv.actions'
import { formatAddAgileTvValidationError } from '../../helpers'

import { useChannelAndSegment } from '../../hooks'

import { addAgileTvSelectors } from './AddAgileTvModalContainer.selectors'
import { AddAgileTvModal } from './AddAgileTvModal'

export const AddAgileTvModalContainer = ({ open, onClose, onSuccess, subscription }) => {
  const dispatch = useDispatch()
  const { requestIpAddress } = useRequestIpAddress()

  const [step, setStep] = useState(0)
  const [isShowResultModal, setIsShowResultModal] = useState(false)
  
  const {
    validationIsLoading,
    validationIsSuccess,
    validationError,
    isLoading,
    error,
    agileTvSva,
    documentId,
    documentType,
    customerEmail,
    isSelfEmployed,
    isSaving,
    savingError,
    savingIsSuccess,
    emailValidationIsLoading,
  } = useSelector(addAgileTvSelectors)

  const hasError = (!isLoading && !agileTvSva) || !!error

  const [agileTvEmail, setAgileTvEmail] = useState(customerEmail)
  const [agileTvSelected, setAgileTvSelected] = useState({})

  const { segment, channel } = useChannelAndSegment(subscription)

  useEffect(() => {
    if (open) {
      setStep(0)
      requestIpAddress()

      dispatch(
        fetchAddAgileTvAllowed({
          documentType,
          documentId,
          segment,
          isSelfEmployed,
          channel,
        }),
      )
    }
  }, [open])

  useEffect(() => {
    if (open && validationIsSuccess) {
      dispatch(
        fetchSvaAgileTv({
          mainTariffPsId: subscription.type,
          channel,
          segment,
        }),
      )
    }
  }, [validationIsSuccess])

  const applyAddAgileTv = () => {
    dispatch(
      addAgileTv({
        email: agileTvEmail,
        productOfferingId: agileTvSelected.poId,
        parentAgreementId: subscription.subscription_id,
      }),
    )
  }

  const props = {
    open,
    onClose,
    isLoading: isLoading || validationIsLoading,
    step,
    setStep,
    agileTvSva,
    msisdn: subscription.id,
    agileTvEmail,
    setAgileTvEmail,
    agileTvSelected,
    setAgileTvSelected,
    emailValidationIsLoading,
    isSaving,
    applyAddAgileTv,
    tariffId: subscription.type
  }

  const onNotificationClose = () => {
    setIsShowResultModal(false)

    if (onSuccess && !hasError && savingIsSuccess) {
      onSuccess()
    }
  }

  useEffect(() => {
    if (
      open &&
      !isLoading &&
      !validationIsLoading &&
      !isSaving &&
      (validationError || hasError || savingError || savingIsSuccess)
    ) {
      if (!savingError) {
        onClose()
      }

      setIsShowResultModal(true)
    }
  }, [validationError, hasError, savingError, savingIsSuccess])

  const resultModalTitle = useMemo(() => {
    let title = 'Servicio agile tv premium añadido correctamente'

    if (hasError) {
      title = 'Ha ocurrido un error durante la carga de datos.'
    }

    if (((hasError || savingError) && error === 404) || (!agileTvSva && !hasError)) {
      title = 'No existe un producto AgileTV compatible con tu subscripción.'
    }

    if (savingError) {
      title = 'Error intentando añadir el servicio.'
    }

    if (validationError) {
      title = 'Venta no permitida'
    }

    return title
  }, [validationError, hasError, savingError, error, agileTvSva])

  return (
    <>
      <NotificationModal
        isOpen={isShowResultModal}
        onClose={onNotificationClose}
        type={hasError || savingError || validationError ? 'alert' : 'success'}
        src={
          hasError || savingError || validationError ? '/assets/error.svg' : '/assets/clap_clap.svg'
        }
        title={resultModalTitle}>
        <>
          {hasError ||
            (savingError && (
              <Typography variant="body1" style={{ marginBottom: '15px' }}>
                {savingError?.detailMsg || 'Por favor, inténtalo más tarde.'}
              </Typography>
            ))}

          {validationError && (
            <Typography variant="body1" style={{ marginBottom: '15px' }}>
              {formatAddAgileTvValidationError(validationError)}
            </Typography>
          )}

          <Button onClick={onNotificationClose}>Aceptar</Button>
        </>
      </NotificationModal>

      <AddAgileTvModal {...props} />
    </>
  )
}

AddAgileTvModalContainer.propTypes = {
  subscription: PropTypes.object.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
}
