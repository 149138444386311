import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Formik, Form, useFormikContext } from 'formik'
import { sortBy, isEmpty, isNil } from 'lodash'
import { Divider, Box, Typography, Grid } from '@material-ui/core'
import { TextStyles } from 'utils/text'
import { FormikInput } from 'components/ui'
import { useTypificationFormStyles } from 'modules/typifications/styles'

import { selectContentfulResource, selectContentfulLoading } from 'modules/contentful'
import { typificationFormSelectors, setDataFormTypifications } from 'modules/typifications/store'
import tipisFormValidationSchema from './TypificationForm.validation'
import TypificationFields from './TypificationForm.model'
import { useFormTipisData, useDefaultValueSelectors } from '../../hooks'
import { formatNumberMsisdn, getNameFieldOptions } from '../../helpers'

const FormData = () => {
  const dispatch = useDispatch()
  const { setFieldValue, values, isValid, dirty } = useFormikContext()

  const { msisdns } = useSelector(typificationFormSelectors)

  const operators = sortBy(useSelector(selectContentfulResource('typificationIframeOperator')), [
    'text',
  ]) 
  const campaigns = sortBy(useSelector(selectContentfulResource('typificationIframeCampaign')), [
    'text',
  ])
  const operatorsLoading = useSelector(selectContentfulLoading('typificationIframeOperator'))
  
  const hideOperatorsField = !operatorsLoading && !isNil(operatorsLoading) && isEmpty(operators) || isEmpty(operators)


  const { fieldOption, setFieldOption } = useFormTipisData(msisdns, operators, campaigns, values, operatorsLoading)
  const { selectorsDisabled, setSelectorsDisabled } = useDefaultValueSelectors(fieldOption, values, operatorsLoading)

  const resetSelect = fields =>
    fields.forEach(field => {
      setFieldValue(field, '')
      setSelectorsDisabled(selectors => ({ ...selectors, [field]: { bool: false } }))
      setFieldOption(oldOptions => ({
        ...oldOptions,
        ...{ [getNameFieldOptions(field)]: [] },
      }))
    })

  useEffect(() => {
    dispatch(setDataFormTypifications({ ...values, isValid, dirty }))
  }, [values, isValid, dirty])

  return (
    <Form>
      { !hideOperatorsField && 
        <div>
          <Grid container spacing={2}>
            <Grid item xs={6} className="no-spacing top-spacing">
              <FormikInput
                name="operator"
                label="Selecciona el Operador Relacionado"
                select
                options={fieldOption.operators}
              />
            </Grid>
          </Grid>
        
          <Box my="12px">
            <Divider />
          </Box>
        </div>
      }
      <Grid container spacing={2}>
        <Grid item xs={6} className="no-spacing">
          {!isEmpty(msisdns) ? (
            <FormikInput
              name="msisdn"
              label="Selecciona el MSISDN"
              select
              options={fieldOption.msisdns}
            />
          ) : (
            <FormikInput
              name="msisdn"
              label="MSISDN"
              maxLength="9"
              onChange={newMsisdn => {
                setFieldValue('msisdn', formatNumberMsisdn(values, newMsisdn))
              }}
            />
          )}
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6} className="first-element-group">
          <FormikInput
            name="campaign"
            label="Selecciona la campaña"
            select
            options={fieldOption.campaigns}
            onChange={() => {
              resetSelect(['reason', 'result', 'subreason', 'subreason2', 'subresult'])
            }}
          />
        </Grid>
      </Grid>
      <Box my="12px">
        <Divider />
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={6} className="no-spacing">
          <FormikInput
            name="reason"
            label="Selecciona el motivo"
            select
            options={fieldOption.reasons}
            onChange={() => {
              resetSelect(['result', 'subreason', 'subreason2', 'subresult'])
            }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6} className="no-spacing">
          <FormikInput
            name="subreason"
            label="Selecciona el submotivo"
            select
            options={fieldOption.subreasons}
            onChange={() => {
              resetSelect(['subreason2'])
            }}
            disabled={selectorsDisabled.subreason}
          />
        </Grid>
        <Grid item xs={6} className="no-spacing">
          <FormikInput
            name="subreason2"
            label="Selecciona el submotivo 2"
            select
            options={fieldOption.subreasons2}
            disabled={selectorsDisabled.subreason2}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6} className="no-spacing">
          <FormikInput
            name="result"
            label="Selecciona el resultado"
            select
            options={fieldOption.results}
            onChange={() => {
              resetSelect(['subresult'])
            }}
            disabled={selectorsDisabled.result}
          />
        </Grid>
        <Grid item xs={6} className="no-spacing">
          <FormikInput
            name="subresult"
            label="Selecciona el detalle del resultado"
            select
            options={fieldOption.subresults}
            disabled={selectorsDisabled.subresult}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} className="no-spacing">
        <FormikInput name="description" label="Oferta y breve descripción de la llamada" />
      </Grid>
    </Form>
  )
}

export const TypificationForm = () => {
  const typificationFormClasses = useTypificationFormStyles()

  return (
    <Box className={`p-t-12 p-x-24 ${typificationFormClasses.styles}`}>
      <Typography
        {...TextStyles.paragraphDark({
          className: 'modal-legend',
        })}>
        Por favor, codifica la acción que has realizado.
      </Typography>
      <Formik initialValues={TypificationFields} validationSchema={tipisFormValidationSchema}>
        <FormData />
      </Formik>
    </Box>
  )
}
