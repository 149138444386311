import PropTypes from 'prop-types'
import {
  ONLY_MOBILE,
  CONVERGENT,
  FIXED_MOBILE,
  PRESCORING_DENIEND,
} from 'modules/SharedSales/constants'
import { Box } from '@material-ui/core'
import { isEmpty } from 'lodash'

import { ONLY_FIXED } from 'modules/SharedSales/constants/order'
import { Button } from 'components/ui'

import { ConvergentSale } from '../ConvergentSaleComponent'
import { OnlyMobileSale } from '../OnlyMobileSaleComponent'
import { OnlyFixedSale } from '../OnlyFixedSaleComponent'
import { SubscriptionSale } from '../SubscriptionSaleComponent'
import { MultiSimSale } from '../MultiSimSaleComponent'
import { FixedMobileSale } from '../FixedMobileSaleComponent'
import SaveChangesButton from '../../Common/SaveChangesButton'
import { ErrorModal } from '../../Common'
import { PreSaleSaveButton } from './PreSaleSaveButton'
import { useSaleExit } from '../../../hooks/Shared/useSaleExit'

export function ClientInfo({
  installationAddress,
  coverageToken,
  onResetCoverage,
  isPortability,
  queryParams: { isTesting },
  setBasketData,
  isLoadingCoverage,
  onCoverageCheckFinished,
  onContinue,
  isLoading,
  isEditing,
  hasTechnology,
  handleIsPortability,
  saleType,
  isCartera,
  prescoringData,
  prescoringFailed,
  isSubscription,
  isCrossSell,
  beforeContinue,
  resetAllTariffsAndBasket,
  hasDuoError,
  canAgentAccessPresale,
  isElFijo,
  isPresale,
  isPos,
  isMultiSim,
  isCompany,
  isCustomerAllowed,
}) {
  const { goExitSale } = useSaleExit(isSubscription || isCrossSell || isMultiSim)

  let component

  const convergentComponent = () => (
    <ConvergentSale
      isCartera={!!isCartera}
      isCrossSell={!!isCrossSell}
      handleIsPortability={handleIsPortability}
      installationAddress={installationAddress}
      isLoadingCoverage={isLoadingCoverage}
      coverageToken={coverageToken}
      onCoverageCheckFinished={onCoverageCheckFinished}
      resetCoverage={onResetCoverage}
      isTesting={isTesting}
      isPortability={isPortability}
      hasTechnology={hasTechnology}
      isPresale={isPresale}
    />
  )

  if (isCrossSell) component = convergentComponent()

  switch (saleType) {
    case ONLY_MOBILE:
      component = <OnlyMobileSale isCartera={!!isCartera} />
      break
    case CONVERGENT:
      component = convergentComponent()
      break
    case FIXED_MOBILE:
      component = <FixedMobileSale isCartera={!!isCartera} />
      break
    case ONLY_FIXED:
      component = (
        <OnlyFixedSale
          isCartera={!!isCartera}
          handleIsPortability={handleIsPortability}
          installationAddress={installationAddress}
          isLoadingCoverage={isLoadingCoverage}
          coverageToken={coverageToken}
          onCoverageCheckFinished={onCoverageCheckFinished}
          resetCoverage={onResetCoverage}
          isTesting={isTesting}
          isPortability={isPortability}
          hasTechnology={hasTechnology}
          isPresale={isPresale}
          isPos={isPos}
        />
      )
      break
    default:
      break
  }

  return (
    <>
      {component}
      {!!isSubscription && <SubscriptionSale />}
      {!!isMultiSim && <MultiSimSale />}
      <ErrorModal hasMsError={prescoringFailed} error={PRESCORING_DENIEND} />
      <Box display="flex" alignItems="center">
        <Button
          margin="0 16px 0 0"
          secondary
          onClick={() => {
            goExitSale()
            resetAllTariffsAndBasket()
          }}
          data-hook="exit-sale">
          Volver
        </Button>
        <SaveChangesButton
          isLoading={isLoading}
          beforeContinue={beforeContinue}
          stepName="ClientInfoForm"
          setBasketData={setBasketData}
          isEditing={isEditing}
          nomargin
          onContinue={onContinue}
          disabled={
            (!isCartera && !isCrossSell && !isMultiSim && !isSubscription && !isCustomerAllowed) ||
            (!!isSubscription && hasDuoError) ||
            ((saleType === CONVERGENT || saleType === ONLY_FIXED) &&
              !isCompany &&
              !isCartera &&
              !isPresale &&
              !isCrossSell &&
              (isEmpty(prescoringData) || prescoringFailed))
          }
        />

        {!isCartera &&
          !isSubscription &&
          !isCrossSell &&
          !isMultiSim &&
          !isElFijo &&
          canAgentAccessPresale && (
            <Box ml="auto">
              <PreSaleSaveButton
                stepName="ClientInfoForm"
                setBasketData={setBasketData}
                disabled={isLoading}
              />
            </Box>
          )}
      </Box>
    </>
  )
}

ClientInfo.propTypes = {
  onCoverageCheckFinished: PropTypes.func.isRequired,
  onContinue: PropTypes.func.isRequired,
  onResetCoverage: PropTypes.func.isRequired,
  setBasketData: PropTypes.func.isRequired,
  queryParams: PropTypes.any.isRequired,
  installationAddress: PropTypes.object.isRequired,
  coverageToken: PropTypes.string,
  saleType: PropTypes.string,
  isPortability: PropTypes.string.isRequired,
  isLoadingCoverage: PropTypes.bool.isRequired,
  hasTechnology: PropTypes.bool,
  isEditing: PropTypes.bool,
  isLoading: PropTypes.bool,
  isCartera: PropTypes.bool,
  isCrossSell: PropTypes.bool,
  handleIsPortability: PropTypes.func.isRequired,
  prescoringData: PropTypes.object,
  prescoringFailed: PropTypes.bool,
  isSubscription: PropTypes.bool,
  beforeContinue: PropTypes.func.isRequired,
  resetAllTariffsAndBasket: PropTypes.func.isRequired,
  hasDuoError: PropTypes.bool,
  isElFijo: PropTypes.bool,
  isPresale: PropTypes.bool,
  canAgentAccessPresale: PropTypes.bool,
  isPos: PropTypes.bool,
  isMultiSim: PropTypes.bool,
  isCompany: PropTypes.bool,
  isCustomerAllowed: PropTypes.bool,
}
