import { get, isEmpty } from 'lodash'
import { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { Grid } from '@material-ui/core'

import {
  formatDataSubscription,
  getFinancialAndPermanencyTerminals,
  getVasWithTax,
  getSubscriptionTariffId,
  getMsisdn,
} from 'services/subscriptions'
import { selectEnabledTaxSvaAgileTv } from 'services/feature-flag/selectors'

import { getCommercialName, selectTariffsApigeeById } from 'modules/tariffs/store-apigee'
import { ChangeAgileTvModal } from 'modules/agile-tv/change-agiletv/components'

import { isElFijoTariff, getElFijoMsisdn } from 'modules/tariffs/helpers'
import { MonthlyConsumptionContainer } from 'modules/consumption/containers/MonthlyConsumptionContainer'
import { selectHomeGoAlarmsSvaByMsisdn } from 'services/customer-sva/selectors'
import { getSegment } from 'services/customer-360/selectors'
import { isProCustomer } from 'services/customer-360/helpers'
import {
  matchSvaSicorWithVasContract,
  replaceVasHomeGowithSicor,
} from 'services/customer-sva/helpers'
import { SubscriptionCard } from '../SubscriptionCard/SubscriptionCard'
import { ElFijoSubscriptionCard } from '../SubscriptionCard/ElFijoSubscriptionCard'
import { UsagesFixedCardContainer } from '../../containers/UsagesFixedCardContainer'
import { UsagesMobileCardContainer } from '../../containers/UsagesMobileCardContainer'
import { TerminalsAndServicesCard } from '../TerminalsAndServicesCard/TerminalsAndServicesCard'

import { Board } from '../../components/Board'

export const ProductMain = styled(
  ({
    className,
    subscription,
    isFixedLine,
    tax,
    dss,
    paymentType,
    isTariffChangeLoading,
    STCErrors,
    clearSTCError,
    disableDataBonusSva,
    isDisableBonusLoading,
    disableSvaSmartHomeError,
    disableSvaSmartHomeOk,
    disableSvaHomeGoError,
    disableSvaHomeGoOk,
    disableBonusPreviousStatus,
    disableBonusErrors,
    disableBonusOK,
    clearDisableBonusSvaError,
    contractDownloadLoading,
    contractDownloadError,
    setContractDownloadError,
    onContractDownload,
    onContractCdCtDownload,
  }) => {
    const tariff = useSelector(selectTariffsApigeeById(getSubscriptionTariffId(subscription)))
    const tariffDescription = getCommercialName(tariff)
    const terminals = getFinancialAndPermanencyTerminals(subscription)

    const customerSegment = useSelector(getSegment)
    const taxNeeded = !isProCustomer(customerSegment)
    const enabledTaxSvaAgileTv = useSelector(selectEnabledTaxSvaAgileTv)
    const vasWithTax = getVasWithTax(subscription, tax, taxNeeded, enabledTaxSvaAgileTv)

    const msisdnOrFijo = subscription && getElFijoMsisdn(subscription)
    const msisdn = getMsisdn(subscription)
    const svaHomeGo = useSelector(
      selectHomeGoAlarmsSvaByMsisdn(msisdnOrFijo, subscription?.subscription_id, subscription?.id),
    )
    const svaHomeGoWithVas = matchSvaSicorWithVasContract(svaHomeGo, vasWithTax)

    const VAS = replaceVasHomeGowithSicor(vasWithTax, svaHomeGoWithVas)

    const isElFijo = isElFijoTariff(get(subscription, 'type'))
    const highLigthedUIInit = {
      tariff: false,
      discounts: false,
      bundles: false,
      otherExpenses: false,
      terminalFee: false,
      VASFee: false,
    }

    const terminalFee = useRef(null)
    const discounts = useRef(null)
    const [highLigthedUI, setHighLigthedUI] = useState(highLigthedUIInit)
    const [isOpenChangeAgileTv, setIsOpenChangeAgileTv] = useState(false)
    const [psIdActualAgileTv, setPsIdActualAgileTv] = useState(null)
    const UIActionUpdate = target => {
      setHighLigthedUI({ ...highLigthedUI, [[target]]: true })
      setTimeout(() => {
        setHighLigthedUI(highLigthedUIInit)
      }, 1200)
      if (terminalFee.current && (target === 'terminalFee' || target === 'VASFee')) {
        terminalFee.current.scrollIntoView({ behavior: 'smooth' })
      }
      if (discounts.current && target === 'discounts') {
        discounts.current.scrollIntoView({ behavior: 'smooth' })
      }
    }
    const isPrepaid = paymentType === 'prepaid'
    const dataSubscriptionFormatted = subscription && {
      ...formatDataSubscription(subscription),
      svaHomeGo: svaHomeGoWithVas,
      tariffDescription,
    }

    return (
      <>
        <Board className={className}>
          <>
            <Grid item xs={4}>
              {subscription &&
                (isElFijo ? (
                  <ElFijoSubscriptionCard
                    dataSubscrtiptionFormatted={dataSubscriptionFormatted}
                    dss={dss}
                    subscription={subscription}
                    UIActionUpdate={UIActionUpdate}
                    highLigthedUI={highLigthedUI}
                    isTariffChangeLoading={isTariffChangeLoading}
                    refScroll={discounts}
                    contractDownloadLoading={contractDownloadLoading}
                    contractDownloadError={contractDownloadError}
                    setContractDownloadError={setContractDownloadError}
                    onContractDownload={onContractDownload}
                  />
                ) : (
                  <SubscriptionCard
                    dataSubscrtiptionFormatted={dataSubscriptionFormatted}
                    dss={dss}
                    subscription={subscription}
                    UIActionUpdate={UIActionUpdate}
                    highLigthedUI={highLigthedUI}
                    isTariffChangeLoading={isTariffChangeLoading}
                    STCErrors={STCErrors}
                    clearSTCError={clearSTCError}
                    disableDataBonusSva={disableDataBonusSva}
                    isDisableBonusLoading={isDisableBonusLoading}
                    disableBonusPreviousStatus={disableBonusPreviousStatus}
                    disableBonusErrors={disableBonusErrors}
                    disableBonusOK={disableBonusOK}
                    disableSvaSmartHomeError={disableSvaSmartHomeError}
                    disableSvaSmartHomeOk={disableSvaSmartHomeOk}
                    disableSvaHomeGoError={disableSvaHomeGoError}
                    disableSvaHomeGoOk={disableSvaHomeGoOk}
                    clearDisableBonusSvaError={clearDisableBonusSvaError}
                    refScroll={discounts}
                    contractDownloadLoading={contractDownloadLoading}
                    contractDownloadError={contractDownloadError}
                    setContractDownloadError={setContractDownloadError}
                    onContractDownload={onContractDownload}
                    onContractCdCtDownload={onContractCdCtDownload}
                    isPrepaid={isPrepaid}
                  />
                ))}
            </Grid>

            {isPrepaid ? (
              <Grid item xs={4}>
                {subscription && !isFixedLine && (
                  <UsagesMobileCardContainer
                    sub={subscription}
                    highLigthedUI={highLigthedUI}
                    isPrepaid={isPrepaid}
                  />
                )}
              </Grid>
            ) : (
              <Grid item xs={4}>
                {subscription && isFixedLine && (
                  <UsagesFixedCardContainer
                    sub={subscription}
                    highLigthedUI={highLigthedUI}
                    isPrepaid={isPrepaid}
                  />
                )}
                {subscription && !isFixedLine && (
                  <UsagesMobileCardContainer
                    sub={subscription}
                    highLigthedUI={highLigthedUI}
                    isPrepaid={isPrepaid}
                  />
                )}
              </Grid>
            )}

            <Grid item xs={4}>
              {subscription && (
                <MonthlyConsumptionContainer
                  subscription={subscription}
                  tax={tax}
                  VAS={VAS}
                  UIActionUpdate={UIActionUpdate}
                  highLigthedUI={highLigthedUI}
                  isPrepaid={isPrepaid}
                />
              )}
            </Grid>

            {((!isEmpty(terminals) || !isEmpty(VAS)) && (
              <Grid item xs={12}>
                <TerminalsAndServicesCard
                  terminals={terminals}
                  VAS={VAS}
                  subscription={subscription}
                  highLigthedUI={highLigthedUI}
                  refScroll={terminalFee}
                  setIsOpenChangeAgileTv={setIsOpenChangeAgileTv}
                  setPsIdActualAgileTv={setPsIdActualAgileTv}
                />
              </Grid>
            )) ||
              ''}
          </>
        </Board>
        <ChangeAgileTvModal
          subscription={subscription}
          open={isOpenChangeAgileTv}
          psid={psIdActualAgileTv}
          onClose={() => setIsOpenChangeAgileTv(false)}
          onSuccess={() => setIsOpenChangeAgileTv(false)}
          msisdn={msisdn}
        />
      </>
    )
  },
)`
  position: relative;
  && .highLightable {
    position: relative;
  }
  && .highLightable.active .highLightFrame {
    opacity: 1;
  }
`

ProductMain.propTypes = {
  subscription: PropTypes.object,
  product: PropTypes.object,
  isFixedLine: PropTypes.bool,
  fixedInstallationOrder: PropTypes.object,
  customerBasicInfo: PropTypes.object,
}
