import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useFormikContext } from 'formik'
import { differenceInMonths } from 'date-fns'
import { find, includes } from 'lodash'

import { LINE_TYPE } from 'modules/Lines/constants'
import { fieldNames as getFieldNames } from 'modules/Lines/line.config'
import { useSetFormikValuesToBasket } from 'modules/NewClientSales/hooks/Shared/useSetFormikValuesToBasket'

import { selectors as customer360Selectors } from 'services/customer-360'

import { fetchCrossSellTariffPromotions as fetchCrossSellTariffPromotionsAction } from 'services/cross-sell/actions'
import { selectIsCrossSellQueryParam } from 'modules/Router/store/index'
import {
  selectCrossTariffDiscounts,
  selectCrossTariffDiscountsLoading,
  selectCrossTariffDiscountsError,
} from 'services/cross-sell/selectors'
import { selectCrossSellTariffPromo } from 'modules/NewClientSales/store/selectors/Basket.selectors'

export function useCrossSellTariffPromotions() {
  const dispatch = useDispatch()
  const setFormikValuesToBasket = useSetFormikValuesToBasket()
  const { setFieldValue } = useFormikContext()

  const isCrossSell = !!useSelector(selectIsCrossSellQueryParam)

  const customerCreationDate = useSelector(customer360Selectors.getActivatedAt)

  const mainLineFilesNames = getFieldNames(LINE_TYPE.LANDLINE, false)
  const crossTariffPromoFieldName = mainLineFilesNames.CROSS_SELL_TARIFF_PROMO

  const basketCrossTariffPromo = useSelector(selectCrossSellTariffPromo)
  const selectedCrossTariffPromo = basketCrossTariffPromo
  const hasCrossTariffPromo = !!basketCrossTariffPromo?.poId

  const promotionsData = useSelector(selectCrossTariffDiscounts)
  const isLoadingPromotions = useSelector(selectCrossTariffDiscountsLoading)
  const isErrorPromotions = useSelector(selectCrossTariffDiscountsError)

  const filterPromotions = useMemo(() => {
    const monthsDifference = Math.abs(
      differenceInMonths(new Date(), new Date(customerCreationDate)),
    )

    return monthsDifference >= 3
      ? promotionsData
      : promotionsData?.filter(promos =>
          find(promos.discounts, discount => includes(discount.name, '600DISC') || includes(discount.name, '300DISC')),
        )
  }, [customerCreationDate, promotionsData])

  function fetchCrossSellTariffPromotions(segment, selectedTariff) {
    dispatch(fetchCrossSellTariffPromotionsAction(segment, selectedTariff.poId))
  }

  function setDiscount(selectDiscount) {
    const crossSellDiscount = (selectDiscount?.poId) ? selectDiscount : {}
    setFieldValue(crossTariffPromoFieldName, crossSellDiscount)
    setFormikValuesToBasket(crossTariffPromoFieldName, crossSellDiscount)
  }

  return {
    isCrossSell,
    fetchCrossSellTariffPromotions,
    isLoadingPromotions,
    isErrorPromotions,
    promotionsData: filterPromotions,
    setDiscount,
    hasCrossTariffPromo,
    selectedCrossTariffPromo,
  }
}
