import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { ThemeProvider } from 'styled-components'
import { makeStyles } from '@material-ui/styles'
import { theme } from 'themes'
import { Routes } from '../../Routes'
import { INITIALISE_APP } from '../../constants'

const useStyles = makeStyles({
  appContainer: {
    position: 'fixed',
    width: '100%',
  },
})

function App() {
  const dispatch = useDispatch()
  const classes = useStyles()

  useEffect(() => {
    dispatch({ type: INITIALISE_APP })
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <MuiThemeProvider theme={theme}>
        <main className={`App ${classes.appContainer}`}>
          <Routes />
        </main>
      </MuiThemeProvider>
    </ThemeProvider>
  )
}

export default App
