import { axiosJWT } from 'modules/axios'
import { APP_CONFIG } from 'services/app-config'

export function fetchBarringsStatus(productId) {
  return axiosJWT({
    method: 'GET',
    url: `${APP_CONFIG.care_cluster}/search-360/product/${productId}/barrings_status`,
  })
}

export function setBarringStatus(subscriptionId, sfid, barrings) {
  const data = {
    data: {
      type: 'subscriptions-barrings-change',
      attributes: {
        'subscription-id': subscriptionId,
        username: sfid,
        barrings,
        memo: {
          title: 'Modificación de barrings',
          content: 'A petición del cliente',
        },
      },
    },
  }

  return axiosJWT({
    method: 'POST',
    url: `${APP_CONFIG.care_cluster}/search-360/product/barring`,
    data,
  })
}
