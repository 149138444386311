import { GET_ORDER_PONR, GET_ORDER_PONR_SUCCESS, GET_ORDER_PONR_ERROR } from './ponr.actions'

const initialState = {
  reached: true,
  loading: true,
  error: false,
}

export const reducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case GET_ORDER_PONR: {
      return { ...state, loading: true }
    }
    case GET_ORDER_PONR_SUCCESS: {
      return { ...state, reached: !!action.payload.ponr, loading: false }
    }

    case GET_ORDER_PONR_ERROR: {
      return {
        ...state,
        error_code: payload.error_code,
        error_message: payload.error_message,
        loading: false,
      }
    }

    default:
      return state
  }
}
