import PropTypes from 'prop-types'
import styled from 'styled-components'
import RadioGroup from '@material-ui/core/RadioGroup'

function CustomRadioGroup(props) {
  const { className, children, ...other } = props
  return (
    <RadioGroup className={className} {...other}>
      {children}
    </RadioGroup>
  )
}

CustomRadioGroup.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

export default styled(CustomRadioGroup)`
  display: 'flex';
  flex-direction: 'row';
`
