import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/styles'

import { Modal } from 'components/ui'
import { MasConsentsGdpr } from '../MasConsentsGDPR'

const useStyles = makeStyles({
  'gdpr-modal': {
    '& > div': {
      padding: '0',

      '& [role="contentinfo"]': {
        padding: '0',

        '& div + div': {
          margin: '0',
        },
      },
    },
  },
})

export const MasConsentsGdprModal = ({ show, onClose, ...rest }) => {
  const classes = useStyles()

  return (
    <Modal className={classes['gdpr-modal']} isOpen={show} onClose={onClose}>
      <MasConsentsGdpr show {...rest} />
    </Modal>
  )
}

MasConsentsGdprModal.propTypes = {
  show: PropTypes.bool,
  subscriptionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  verbalId: PropTypes.string,
  customerDocument: PropTypes.string.isRequired,
  segment: PropTypes.string.isRequired,
  lang: PropTypes.string.isRequired,
  channel: PropTypes.string.isRequired,
  consentType: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func,
}
