import styled from 'styled-components'
import PropTypes from 'prop-types'
import { get, isEmpty } from 'lodash'

import { Typography, Divider } from '@material-ui/core'
import { TextStyles } from 'utils/text'

import {
  getCancellationCostsLeftFromFinancials,
  getFeesToPayFromFinancialsDescriptions,
  getMonthlyFeesAmountLeftToPayFromFinancials,
  getTerminalsFinancials,
  getTotalFeesToPayFromFinancials,
  getFees25WithDiscountFromFinancials,
  checkIsProrratedFinancial,
} from 'services/subscriptions'

import { PermanencyTab } from './PermanencyTab'
import { PendingAndTotalFee } from './PendingAndTotalFee'
import { FeeTab } from './FeeTab'

export const PermanencyTerminals = styled(({ className, subscription }) => {
  const terminalsFinancials = getTerminalsFinancials(subscription)

  return (
    !isEmpty(terminalsFinancials) && (
      <div className={className}>
        <Typography
          {...TextStyles.subtitle1Secondary({
            className: 'p-b-8',
          })}>
          DISPOSITIVO:
        </Typography>
        {terminalsFinancials.map(financials => {
          const isProrratedFinancial = checkIsProrratedFinancial(financials)

          return (
            <div className="p-x-12 p-b-24">
              <Typography
                {...TextStyles.subtitle1Secondary({
                  className: 'txt-none p-x-12 p-b-8',
                })}>
                {get(financials, 'item.name')}
              </Typography>

              <div className="flex">
                <PermanencyTab
                  className="p-l-12 p-r-24 p-b-8"
                  validFor={financials.validFor}
                  totalMonths={financials.length}
                  residualMonth={financials.residualLength}
                  financial={isProrratedFinancial}
                />

                <Divider orientation="vertical" />

                <PendingAndTotalFee
                  className="p-l-24"
                  title="Importe por amortizar"
                  pendingFee={getMonthlyFeesAmountLeftToPayFromFinancials(financials)}
                  totalFee={getTotalFeesToPayFromFinancials(financials)}
                  feeDescription={getFeesToPayFromFinancialsDescriptions(financials)}
                />

                {!isProrratedFinancial && (
                  <>
                    <Divider orientation="vertical" />
                    <FeeTab
                      className="p-l-24"
                      title="Pago final (Q25)"
                      fee={getFees25WithDiscountFromFinancials(financials)}
                    />
                  </>
                )}

                <Divider orientation="vertical" />

                <FeeTab
                  className="p-l-24 fee-tab-width"
                  title="Si cancela hoy"
                  fee={getCancellationCostsLeftFromFinancials(financials)}
                  feeDescription={
                    isProrratedFinancial
                      ? '(Cuotas pendientes)'
                      : '(Cuotas pendientes + pago final)'
                  }
                />
              </div>
            </div>
          )
        })}
      </div>
    )
  )
})`
  .txt-none {
    text-transform: none;
  }

  .fee-tab-width {
    width: 228px;
  }
`

PermanencyTerminals.propTypes = {
  className: PropTypes.string,
}
