import { get } from 'lodash'
import { axiosJWT } from 'modules/axios'
import { CONFIG } from 'modules/typifications/environments'
import { authorizationTokenHeader } from 'modules/typifications/helpers'
import { MASCARE } from 'modules/typifications/constants'

export const loginAgent = ({ sfid, uuid }) =>
  axiosJWT({
    method: 'POST',
    url: CONFIG.api.loginAgent,
    data: {
      sfid,
      uuid,
    },
  })
    .then(response => {
      const agentToken = get(response.data, 'accessToken')
      authorizationTokenHeader(agentToken, sfid)
      return response
    })
    .catch(e => {
      e.response.data.error_message = e.response.data.detailMsg
      throw e
    })

export const securizer = ({customerId, sfid, area, msisdns}) =>  
  axiosJWT({
    method: 'POST',
    url: CONFIG.api.securizer,
    data: {
      sfid,
      area,
      channel: MASCARE,
      brand: 'yoigo',
      customer_id: customerId,
      msisdns
    },
  })
  .then(response => response.data)
  .catch(e => {
      e.response.data.error_message = e.response.data.detailMsg
      throw e
    })



