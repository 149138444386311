import { useDispatch } from 'react-redux'

import {initSecurizerTypification,closeTypificationIframe} from 'modules/typifications/store/actions/securizerTypifications.actions'
import {
  updateSubscriptions,
  clearAllSubscriptions,
  openTypification,
  closeTypification,
  setFinished,
} from '../../actions'

export function useCvmActions() {
  const dispatch = useDispatch()

  return {
    updateSubscriptionsDispatch: (data, optionsV3, migrationOrStc, sendSms, smsContent) =>
      dispatch(updateSubscriptions(data, optionsV3, migrationOrStc, sendSms, smsContent)),
    clearAllSubscriptionsDispatch: () => dispatch(clearAllSubscriptions()),
    openTypificationDispatch: () => dispatch(openTypification()),
    setFinishedDispatch: () => dispatch(setFinished()),
    closeTypificationDispatch: () => dispatch(closeTypification()),
    openTypificationIframeDispatch: ({ customerId, sfid, area, msisdns }) => 
      {
        dispatch(closeTypificationIframe())
        dispatch(initSecurizerTypification({customerId, sfid, area, msisdns}))
      }
  }
}
