import { createStructuredSelector, createSelector } from 'reselect'
import { selectCoverageFullAddress, selectTechnology, selectAddressInfo } from 'modules/Coverage'
import { selectIBANErrorCode, selectIBANIsLoading } from 'modules/Iban'
import { selectIsLoadingInstallations, selectFiberInstaller } from 'modules/Installations'
import {
  selectIsMobileOnly,
  selectIsSubscriptionQueryParam,
  selectIsCarteraQueryParam,
} from 'modules/Router/store'

import {
  selectCanGoToStore,
  selectOtherAddressClientOnlyMobile,
} from 'services/feature-flag/selectors'

import {
  YOIGO_STORE,
  BILLING_ADDRESS,
  OTHER_ADDRESS,
  INSTALLATION_ADDRESS,
  SERVICE_ADDRESS,
} from 'modules/SharedSales/constants'
import { formatOtherFullAddress } from 'modules/Coverage/helpers/index'
import { selectIsMultiSimQueryParam } from 'modules/Router/store/router.selectors'
import {
  selectHasSomeTerminal,
  selectLandlineOffer,
  selectBilling,
  selectShipping,
  selectTerminalsShipping,
  selectServiceAddress,
  selectIsElFijo,
  selectAccountId,
  selectIsCompany,
} from './Basket.selectors'
import { selectTaxZipCode, selectNewPostalAddresss } from './NewClientSales.selectors'

function getFormattedOtherAddress(otherAddress) {
  return { ...otherAddress, formattedAddress: formatOtherFullAddress(otherAddress) }
}

function getShippingAddressTypes(isMobileOnly, isElFijo, isSubscription, isCartera, addressFlag) {
  if (!addressFlag && ((isCartera && isMobileOnly) || isSubscription)) {
    return [BILLING_ADDRESS]
  }

  if (isMobileOnly || !!isSubscription) {
    return [BILLING_ADDRESS, OTHER_ADDRESS]
  }

  if (isElFijo) {
    if (addressFlag || !isCartera) {
      return [SERVICE_ADDRESS, BILLING_ADDRESS, OTHER_ADDRESS]
    }
    return [SERVICE_ADDRESS, BILLING_ADDRESS]
  }

  return [INSTALLATION_ADDRESS]
}

function getTerminalShippingAddressTypes(isMobileOnly, hasTerminals, isSubscription) {
  if (isMobileOnly || !!isSubscription) {
    return [BILLING_ADDRESS, OTHER_ADDRESS]
  }

  return hasTerminals
    ? [YOIGO_STORE, OTHER_ADDRESS, INSTALLATION_ADDRESS]
    : [INSTALLATION_ADDRESS, OTHER_ADDRESS]
}

const selectBillingAvailableAddressTypes = createSelector(
  selectIsMobileOnly,
  selectIsElFijo,
  selectIsSubscriptionQueryParam,
  (isMobileOnly, isElFijo, isSubscription) => {
    const mainAddress = isElFijo ? SERVICE_ADDRESS : INSTALLATION_ADDRESS
    return isMobileOnly || !!isSubscription ? [OTHER_ADDRESS] : [mainAddress, OTHER_ADDRESS]
  },
)

const selectShippingAvailableAddressTypes = createSelector(
  [
    selectIsMobileOnly,
    selectIsElFijo,
    selectIsSubscriptionQueryParam,
    selectIsCarteraQueryParam,
    selectOtherAddressClientOnlyMobile,
  ],
  getShippingAddressTypes,
)

const selectTerminalShippingAvailableAddressTypes = createSelector(
  [selectIsMobileOnly, selectHasSomeTerminal, selectIsSubscriptionQueryParam],
  getTerminalShippingAddressTypes,
)

export const selectBillingAddress = createSelector(
  [
    selectCoverageFullAddress,
    selectBilling,
    selectServiceAddress,
    selectIsMobileOnly,
    selectIsElFijo,
    selectIsSubscriptionQueryParam,
  ],
  (address, billing, serviceAddress, isMobileOnly, isElFijo, isSubscription) => {
    if (isMobileOnly || !!isSubscription || billing.selectedAddressType === OTHER_ADDRESS.value) {
      return getFormattedOtherAddress(billing.otherAddress)
    }

    return {
      address: isElFijo
        ? getFormattedOtherAddress(serviceAddress.otherAddress).formattedAddress
        : address,
    }
  },
)

export const selectBillingAddressCoverageInfo = createSelector(
  [
    selectAddressInfo,
    selectBilling,
    selectServiceAddress,
    selectIsMobileOnly,
    selectIsElFijo,
    selectIsSubscriptionQueryParam,
  ],
  (address, billing, serviceAddress, isMobileOnly, isElFijo, isSubscription) => {
    if (isMobileOnly || !!isSubscription) {
      return getFormattedOtherAddress(billing.otherAddress)
    }
    if (billing.selectedAddressType === OTHER_ADDRESS.value) {
      return getFormattedOtherAddress(billing.otherAddress)
    }

    return {
      address: isElFijo
        ? getFormattedOtherAddress(serviceAddress.otherAddress).formattedAddress
        : address,
    }
  },
)

export const selectServiceOtherAddress = createSelector(
  selectServiceAddress,
  serviceAddress => serviceAddress.otherAddress,
)

export const selectShippingAddress = createSelector(
  [
    selectCoverageFullAddress,
    selectShipping,
    selectServiceAddress,
    selectBilling,
    selectBillingAddress,
    selectTerminalsShipping,
    selectIsMobileOnly,
    selectIsElFijo,
    selectIsSubscriptionQueryParam,
  ],
  (
    address,
    shipping,
    service,
    billing,
    billingFormated,
    terminalShipping,
    isMobileOnly,
    isElFijo,
    isSubscription,
  ) => {
    const billingAddress = billing.otherAddress
    const shippingAddress = shipping.otherAddress
    const terminalShippingAddress = terminalShipping.otherAddress
    const selectedShippingType = shipping.selectedAddressType
    const selectedTerminalShippingType = terminalShipping.selectedAddressType
    const serviceAddress = service.otherAddress
    if (isMobileOnly || !!isSubscription) {
      return {
        line:
          selectedShippingType === OTHER_ADDRESS.value
            ? getFormattedOtherAddress(shippingAddress)
            : getFormattedOtherAddress(billingAddress),
        terminal:
          selectedTerminalShippingType === OTHER_ADDRESS.value
            ? getFormattedOtherAddress(terminalShippingAddress)
            : getFormattedOtherAddress(billingAddress),
      }
    }
    if (isElFijo) {
      const presetedAddress =
        selectedShippingType === SERVICE_ADDRESS.value
          ? getFormattedOtherAddress(serviceAddress)
          : billingFormated
      return {
        line:
          selectedShippingType === OTHER_ADDRESS.value
            ? getFormattedOtherAddress(shippingAddress)
            : presetedAddress,
        terminal:
          selectedTerminalShippingType === OTHER_ADDRESS.value
            ? getFormattedOtherAddress(terminalShippingAddress)
            : presetedAddress,
      }
    }
    return {
      line: shippingAddress.address ? getFormattedOtherAddress(shippingAddress) : { address },
      terminal:
        selectedTerminalShippingType === OTHER_ADDRESS.value
          ? getFormattedOtherAddress(terminalShippingAddress)
          : { address },
    }
  },
)

const selectedBillingAddressType = createSelector(
  [
    selectBilling,
    selectIsMobileOnly,
    selectIsElFijo,
    selectIsSubscriptionQueryParam,
    selectIsMultiSimQueryParam,
  ],
  (billing, isMobileOnly, isElFijo, isSubscription, isMultiSim) => {
    if (isElFijo || isMultiSim) {
      return billing.selectedAddressType || SERVICE_ADDRESS.value
    }
    if (isMobileOnly || !!isSubscription) {
      return billing.selectedAddressType || OTHER_ADDRESS.value
    }
    return billing.selectedAddressType || INSTALLATION_ADDRESS.value
  },
)

const selectedShippingAddressType = createSelector(
  [selectShipping, selectIsMobileOnly, selectIsElFijo, selectIsSubscriptionQueryParam],
  (shipping, isMobileOnly, isElFijo, isSubscription) => {
    if (isMobileOnly || isSubscription) {
      return shipping.selectedAddressType || BILLING_ADDRESS.value
    }
    if (isElFijo) {
      return shipping.selectedAddressType || SERVICE_ADDRESS.value
    }
    return shipping.selectedAddressType || INSTALLATION_ADDRESS.value
  },
)

const selectedTerminalShippingAddressType = createSelector(
  [selectTerminalsShipping, selectIsMobileOnly, selectCanGoToStore, selectIsSubscriptionQueryParam],
  (shipping, isMobileOnly, canGoToStore, isSubscription) => {
    const defaultAddress = canGoToStore ? YOIGO_STORE.value : INSTALLATION_ADDRESS.value
    if (isMobileOnly || !!isSubscription) {
      return shipping.selectedAddressType || BILLING_ADDRESS.value
    }
    return shipping.selectedAddressType || defaultAddress
  },
)

const selectUpdateCarteraValues = createSelector(
  selectAccountId,
  selectIsCompany,
  (accountId, isCompany) => ({
    accountId,
    params: { segment: isCompany ? 'pro' : 'residential' },
  }),
)

const selectShouldResetAddress = createSelector(
  selectIsCarteraQueryParam,
  selectNewPostalAddresss,
  (isCartera, postalAddress) => !!isCartera && !postalAddress.building,
)

export const selectBillingData = createStructuredSelector({
  address: selectBillingAddress,
  addressType: selectedBillingAddressType,
  availableAddressTypes: selectBillingAvailableAddressTypes,
  updateValues: selectUpdateCarteraValues,
  isMobileOnly: selectIsMobileOnly,
  isSubscription: selectIsSubscriptionQueryParam,
  shouldResetAddress: selectShouldResetAddress,
  isMultiSim: selectIsMultiSimQueryParam,
})

export const selectShippingData = createStructuredSelector({
  address: selectShippingAddress,
  addressType: selectedShippingAddressType,
  terminalAddressType: selectedTerminalShippingAddressType,
  availableAddressTypes: selectShippingAvailableAddressTypes,
  availableTerminalAddressTypes: selectTerminalShippingAvailableAddressTypes,
  isMobileOnly: selectIsMobileOnly,
  isElFijo: selectIsElFijo,
  isSubscription: selectIsSubscriptionQueryParam,
  isCartera: selectIsCarteraQueryParam,
  hasTerminals: selectHasSomeTerminal,
})

export const selectMainAddress = createSelector(
  selectCoverageFullAddress,
  selectServiceAddress,
  selectIsElFijo,
  (installation, service, isElFijo) =>
    isElFijo ? getFormattedOtherAddress(service.otherAddress).formattedAddress : installation,
)

export const billingContainerSelectors = createStructuredSelector({
  installationFullAddress: selectCoverageFullAddress,
  ibanError: selectIBANErrorCode,
  ibanLoading: selectIBANIsLoading,
  isLoadingInstallations: selectIsLoadingInstallations,
  fiberInstaller: selectFiberInstaller,
  landlineOffer: selectLandlineOffer,
  billing: selectBilling,
  shipping: selectShipping,
  terminalShipping: selectTerminalsShipping,
  isMobileOnly: selectIsMobileOnly,
  isElFijo: selectIsElFijo,
  isMultiSim: selectIsMultiSimQueryParam,
  technology: selectTechnology,
  serviceAddress: selectServiceAddress,
  taxZipCode: selectTaxZipCode,
  isSubscription: selectIsSubscriptionQueryParam,
  billingAddress: selectBillingAddress,
  shippingAddress: selectShippingAddress,
})
