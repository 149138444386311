import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { useFormikContext } from 'formik'
import { Typography } from '@material-ui/core'
import { TextStyles } from 'utils/text'

import { OtherAddress } from 'modules/Address'
import { selectIsCarteraQueryParam } from 'modules/Router/store/index'

import {
  cleanOtherAddressAction,
  setAddressAction,
} from 'modules/NewClientSales/store/actions/index'

import { selectServiceOtherAddress } from '../../../store'

export function ServiceAddress() {
  const [previousProvince, setPreviousProvince] = useState('')
  const dispatch = useDispatch()
  const { setFieldValue } = useFormikContext()

  const address = useSelector(selectServiceOtherAddress)
  const isCartera = useSelector(selectIsCarteraQueryParam)

  function onSetAddress(result) {
    dispatch(setAddressAction({ ...result, addressType: 'serviceAddress' }))
  }

  function onEditAddress(type) {
    dispatch(cleanOtherAddressAction(type))
  }

  useEffect(() => {
    if (address.province) {
      setPreviousProvince(address.province)
      if (previousProvince && previousProvince !== address.province) {
        setFieldValue('mainMobileLineOffer.newFixedNumber', {
          error: null,
          isLoading: null,
          value: '',
        })
      }
    }
    setFieldValue('serviceAddress.otherAddress', address)
  }, [address])

  return (
    <>
      <Typography
        {...TextStyles.subtitle1Secondary({
          className: 'm-b-8 m-t-32',
        })}>
        DIRECCIÓN DE SERVICIO
      </Typography>
      <OtherAddress
        disabled={address.editAddress && !!isCartera}
        addressType="serviceAddress"
        onSetAddress={onSetAddress}
        onEditAddress={onEditAddress}
      />
    </>
  )
}
