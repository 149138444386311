import { useState } from 'react'
import { PropTypes } from 'prop-types'
import { Header } from 'modules/Header'
import CancelSalesProcessDialog from './CancelSalesProcessDialog'
import { useD2DHeader } from '../../hooks/D2D/useD2DHeader'

export function SalesHeader({ onCancelSalesProcess }) {
  const [isReloadModalOpen, setReloadModalOpen] = useState(false)

  const { onClickPowerButton, onClickHome, isD2D } = useD2DHeader()

  function onContinueSalesProcess() {
    setReloadModalOpen(false)
  }

  function onConfirmModal() {
    setReloadModalOpen(false)

    onCancelSalesProcess()
  }

  function showContinueSaleModal() {
    setReloadModalOpen(true)
  }
  return (
    <>
      <Header
        icon="menu"
        iconText="Menu"
        onMenuClick={showContinueSaleModal}
        title="MASSales"
        isD2D={isD2D}
        onClickHome={onClickHome}
        onClickPowerButton={onClickPowerButton}
      />

      <CancelSalesProcessDialog
        isOpen={isReloadModalOpen}
        onClose={onContinueSalesProcess}
        onCancelSalesProcess={onConfirmModal}
      />
    </>
  )
}

SalesHeader.propTypes = {
  onCancelSalesProcess: PropTypes.func,
}
