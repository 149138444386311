export const FIND_BILLINGS_INIT = 'OVID/BILLINGS/FIND_INIT'
export const FIND_BILLINGS = 'OVID/BILLINGS/FIND'
export const FIND_BILLINGS_SUCCESS = 'OVID/BILLINGS/FIND_SUCCESS'
export const FIND_BILLINGS_ERROR = 'OVID/BILLINGS/FIND_ERROR'

export function findBillingsInitAction(filters) {
  return {
    type: FIND_BILLINGS_INIT,
    payload: {
      filters,
    },
  }
}

export function findBillingsAction(filters) {
  return {
    type: FIND_BILLINGS,
    payload: {
      filters,
    },
  }
}

export function findBillingsSuccessAction(data) {
  return {
    type: FIND_BILLINGS_SUCCESS,
    payload: {
      ...data,
    },
  }
}

export function findBillingsErrorAction(error) {
  return {
    type: FIND_BILLINGS_ERROR,
    payload: { error },
  }
}
