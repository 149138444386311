import * as Yup from 'yup/lib'
import { REQUIRED_FIELD_TEXT } from 'modules/SharedSales/constants/validations'
// This schema is for only mobile without terminal

export const OnlyMobileSchema = {
  mainMobileLineOffer: Yup.object().shape({
    tariff: Yup.object().shape({
      id: Yup.string().when('$isMobileOnly', {
        is: true,
        then: Yup.string().required(REQUIRED_FIELD_TEXT),
        otherwise: Yup.string(),
      }),
    }),
  }),
}
