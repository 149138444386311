import { useState } from 'react'
import PropTypes from 'prop-types'

import { isEmpty, get } from 'lodash'
import { ActionAdvice, ButtonLink, Modal, ModalActions, Button } from 'components/ui'
import { Box, Divider, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { SVA_HOMEGO_CODE } from 'modules/offers-configuration/constants'

const useStyles = makeStyles({
  adviceStyles: {
    width: '100%',
    '& .advice-text-field': {
      paddingRight: '16px',
    },
    '& .text-wrapper': {
      position: 'relative',
      paddingRight: '0px',
    },
    '& .icon': {
      alignSelf: 'center',
      cursor: 'pointer',
    },
    '& [data-hook="icon-cancel"]': {
      position: 'absolute',
      right: '12px',
    },
    '& .advice-wrapper': {
      width: '100%',
    },
  },
  boxStyles: {
    marginRight: '30px',
  },
})

export function SVAsAdvice({ svaList, svaPermanencyList, onDelete }) {
  const styles = useStyles()

  const [isSvaDetailsModalOpen, setIsSvaDetailsModalOpen] = useState({})

  const openSvaDetailsModal = (svaId, isOpen) =>
    setIsSvaDetailsModalOpen({ ...isSvaDetailsModalOpen, [svaId]: isOpen })

  const filterDisplayArray = (svaId, displayArray) => {
    return svaId.includes(SVA_HOMEGO_CODE) && displayArray
      ? [get(displayArray, '[0]', {})]
      : displayArray
  }

  return (
    (!isEmpty(svaList) || !isEmpty(svaPermanencyList)) && (
      <Box width={1}>
        <Divider />
        <Box display="flex" flexWrap="wrap" py="20px">
          {svaList.map(({ display, id, active, description }, index) => (
            <Box
              key={id || `sva-advice-${index}`}
              display="flex"
              className={`advice ${styles.boxStyles}`}
              my="4px"
              width="390px">
              <ActionAdvice
                className={`advice ${styles.adviceStyles}`}
                id="bundle"
                type="info"
                items={filterDisplayArray(id, display)}
                onClose={onDelete && !active ? () => onDelete(id) : null}
                block>
                {id.includes(SVA_HOMEGO_CODE) && (
                  <>
                    <Modal
                      title={`Detalle - Home GO ${display[0].value}`}
                      isOpen={isSvaDetailsModalOpen[id]}
                      onClose={() => openSvaDetailsModal(id, false)}>
                      <Typography variant="body1">{description}</Typography>
                      <ModalActions>
                        <Button secondary onClick={() => openSvaDetailsModal(id, false)}>
                          Cerrar
                        </Button>
                      </ModalActions>
                    </Modal>

                    <Box display="flex" mr="25px">
                      <ButtonLink onClick={() => openSvaDetailsModal(id, true)}>
                        Más info
                      </ButtonLink>
                    </Box>
                  </>
                )}
              </ActionAdvice>
            </Box>
          ))}
          {svaPermanencyList.map(({ display, id }, index) => (
            <Box
              key={id || `sva-advice-permanency-${index}`}
              display="flex"
              className={`advice ${styles.boxStyles}`}
              minWidth="380px">
              <ActionAdvice
                id="bundle"
                className={`advice ${styles.adviceStyles}`}
                items={display}
                type="error"
                icon="warning"
                block
              />
            </Box>
          ))}
        </Box>
      </Box>
    )
  )
}
SVAsAdvice.propTypes = {
  svaList: PropTypes.array,
  svaPermanencyList: PropTypes.array,
  onDelete: PropTypes.func,
}
