import { createStructuredSelector, createSelector } from 'reselect'
import { subDays, isWithinInterval } from 'date-fns'
import { selectProfileId, profiles } from 'modules/Permissions'
import {
  isConvergent,
  selectOrdersAdditionalLines,
  selectOrdersCancellationInfo,
  selectOrdersCancellationInfoIsLoading,
  selectOrdersAdditionalLinesIsLoading,
  selectOrdersCancellationInfoHasError,
  selectOrdersAdditionalLinesError,
  selectIsOrderTypeElFijo,
  selectIsPosOrder,
  selectOrdersCancellationFlow,
  selectIsNewNumerationFixed,
  getPenaltiesLoading,
  getOrderLoading,
  getOrderErrorCode,
} from 'modules/orders'
import { CANCELLATION_FLOW_TOGETHER } from 'modules/orders/constants'
import { selectPoncHasBeenReached, selectPoncLoading, selectPoncHasError } from 'modules/ponc'

const selectIsElFijoAndPosAndNewNumeration = createSelector(
  [selectIsOrderTypeElFijo, selectIsPosOrder, selectIsNewNumerationFixed],
  (isElFijo, isPos, isNewNumerationFixed) => {
    return isElFijo && isPos && isNewNumerationFixed
  },
)

const selectIsCancellable = createSelector(
  [isConvergent, selectIsElFijoAndPosAndNewNumeration],
  (isConvergentOrder, isElFijoAndPosAndNewNumeration) =>
    isConvergentOrder && !isElFijoAndPosAndNewNumeration,
)

const selectIsValidForTogether = createSelector(
  selectPoncHasBeenReached,
  poncHasBeenReached => !poncHasBeenReached,
)
const selectIsValidForSeparately = createSelector(
  [selectOrdersCancellationInfo, selectOrdersAdditionalLines],
  (cancellationInfo, additionalLines) => {
    if (!additionalLines) {
      return false
    }

    return !!additionalLines?.find(
      line =>
        !!cancellationInfo.find(
          info =>
            info.orderId === line.orderId &&
            (info.cancellable || info.fixedCancellable || info.mobileCancellable),
        ),
    )
  },
)

const selectIsValidForPos = createSelector(
  [selectOrdersAdditionalLines],
  additionalLines => {
    const mainLineData = additionalLines?.find(line => line.main)
    if (!mainLineData || !mainLineData.creationDate) {
      return false
    }

    const yesterday = subDays(new Date(), 1)

    return isWithinInterval(new Date(mainLineData.creationDate), {
      start: yesterday,
      end: new Date(),
    })
  },
)

const selectIsValid = createSelector(
  [
    selectOrdersCancellationInfo,
    selectOrdersCancellationFlow,
    selectIsValidForTogether,
    selectIsValidForSeparately,
    selectProfileId,
    selectIsValidForPos,
  ],
  (cancellationInfo, flow, isValidForTogether, isValidForSeparately, profileId, isValidForPos) => {
    if (!cancellationInfo) {
      return false
    }

    const isPosChannel = profileId === profiles.pos.id
    return (
      (!isPosChannel || isValidForPos) &&
      (flow === CANCELLATION_FLOW_TOGETHER ? isValidForTogether : isValidForSeparately)
    )
  },
)

const selectIsLoading = createSelector(
  [
    getOrderLoading,
    selectOrdersCancellationInfoIsLoading,
    selectOrdersCancellationFlow,
    selectOrdersAdditionalLinesIsLoading,
    getPenaltiesLoading,
    selectPoncLoading,
  ],
  (
    orderIsLoading,
    cancellationInfoIsLoading,
    flow,
    additionalLinesIsLoading,
    penaltiesIsLoading,
    poncIsLoading,
  ) => {
    if (orderIsLoading || cancellationInfoIsLoading || additionalLinesIsLoading) {
      return true
    }

    return flow === CANCELLATION_FLOW_TOGETHER ? penaltiesIsLoading || poncIsLoading : false
  },
)

const selectHasError = createSelector(
  [
    getOrderErrorCode,
    selectOrdersCancellationInfoHasError,
    selectOrdersAdditionalLinesError,
    selectOrdersCancellationFlow,
    selectPoncHasError,
  ],
  (orderErrorCode, cancellationInfoHasError, additionalLinesHasError, flow, poncHasError) => {
    if (orderErrorCode || cancellationInfoHasError || additionalLinesHasError) {
      return true
    }

    return flow === CANCELLATION_FLOW_TOGETHER ? poncHasError : false
  },
)

export const selectCancelOrderButtonData = createStructuredSelector({
  flow: selectOrdersCancellationFlow,
  isCancellable: selectIsCancellable,
  isLoading: selectIsLoading,
  hasError: selectHasError,
  isValid: selectIsValid,
})
