import { call, put, takeEvery } from 'redux-saga/effects'
import { logError, formatErrorMessage } from 'services/logging'
import { axiosFactory } from 'modules/axios'

import { actions } from './actions'
import { constants } from './constants'
import { territoryCompatible } from './api'

export function* getTerritCompatSaga({ payload }) {
  try {
    const result = yield call(territoryCompatible, payload)
    yield put(actions.getTerritCompatSuccess(result))
  } catch (e) {
    yield call(logError, { e: new Error(formatErrorMessage(e)), payload })
    const error = axiosFactory.buildError(e)
    yield put(actions.getTerritCompatFailed(error))
  }
}

export function* watchTerritCompatSaga() {
  yield takeEvery(constants.GET_TERRIT_COMPAT_INIT, getTerritCompatSaga)
}
