/* eslint-disable import/no-cycle */
import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { Card as CardMaterial, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import { PopoverMenu } from 'modules/ui'
import { ButtonCard } from 'components/ui'
import { CardContent } from './CardContent'
import { CardHeader } from './CardHeader'
import { CardContentLoading } from './CardContentLoading'
import { CardContentError } from './CardContentError'
import { CardContentEmpty } from './CardContentEmpty'

const useStyles = makeStyles({
  card: {
    width: '100%',
    margin: '0px',
  },
})

export const Card = ({
  className,
  id = '0',
  title,
  subTitle,
  preHeader,
  footer,
  toolTip,
  toggable,
  collapsed,
  menuButton,
  actions,
  menuActions,
  childrenInHeader,
  children,
  withShadow,
  loading,
  error,
  empty,
  refContent,
  style,
  menuScroll,
}) => {
  const classes = useStyles()

  const [isCollapsed, setIsCollapsed] = useState(!!collapsed)

  useEffect(() => {
    setIsCollapsed(!!collapsed)
  }, [collapsed])

  return (
    <CardMaterial
      elevation={withShadow ? 1 : null}
      className={['card', classes.card, ...(className ? [className] : [])].join(' ')}
      data-hook={`card-${id}`}
      style={style}>
      {(title || subTitle) && (
        <CardHeader
          className="card-header"
          title={title}
          subTitle={subTitle}
          toolTip={toolTip}
          onClickCollapse={() => setIsCollapsed(!isCollapsed)}
          toggable={toggable}
          collapsed={isCollapsed}
          preHeader={preHeader}
          actions={actions}
          menuActions={menuActions}>
          {menuButton ? (
            <PopoverMenu
              id={id}
              ClickableComp={() => (
                <ButtonCard
                  data-hook="action-modificar"
                  label={menuButton.btnText}
                  onClick={() => setIsCollapsed(false)}
                />
              )}
              menuActions={menuButton.menuActions}
              dropdown
              menuScroll={menuScroll}
            />
          ) : (
            ''
          )}
          {childrenInHeader || ''}
        </CardHeader>
      )}
      {!loading && error && <CardContentError />}
      {!loading && !error && empty && <CardContentEmpty />}
      {!error && (
        <CardContent collapsed={isCollapsed} refContent={refContent}>
          {loading ? <CardContentLoading /> : children}
        </CardContent>
      )}
      {!error && footer && (
        <Box className="card-footer cvm" data-hook={`card-footer-${id}`} p="24px">
          {footer}
        </Box>
      )}
    </CardMaterial>
  )
}

Card.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  subTitle: PropTypes.string,
  preHeader: PropTypes.node,
  footer: PropTypes.node,
  toolTip: PropTypes.string,
  toggable: PropTypes.bool,
  collapsed: PropTypes.bool,
  menuButton: PropTypes.shape({
    btnText: PropTypes.string,
    menuActions: PropTypes.array,
  }),
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      icon: PropTypes.string,
      action: PropTypes.func,
    }),
  ),
  menuActions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func,
      to: PropTypes.string,
      disabled: PropTypes.bool,
    }),
  ),

  childrenInHeader: PropTypes.node,
  children: PropTypes.node,
  withShadow: PropTypes.bool,
  loading: PropTypes.bool,
  error: PropTypes.bool,
  empty: PropTypes.bool,
  refContent: PropTypes.object,
  style: PropTypes.object,
  menuScroll: PropTypes.bool,
}

Card.defaultProps = {
  toggable: false,
  collapsed: false,
  withShadow: true,
}
