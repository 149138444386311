import { axiosJWT } from 'modules/axios'

import { APP_CONFIG } from 'services/app-config'

const url = `${APP_CONFIG.sales_cluster}/v1/leads/lead`

export const setSalesLead = data => {
  return axiosJWT({
    method: 'POST',
    url,
    data,
  }).then(res => res.data)
}
