import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import MaskedInput from 'react-text-mask'
import { makeStyles } from '@material-ui/styles'
import { Box } from '@material-ui/core'

import { ComposedTextField } from 'components/ui'

import { validateIBANAction } from '../../store'

const fourDigitsIBAN = [/\d/, /\d/, /\d/, /\d/]

function getModuleDivision(divident, divisor) {
  let f = divident
  const partLength = 10

  while (f.length > partLength) {
    const part = f.substring(0, partLength)
    f = (part % divisor) + f.substring(partLength)
  }

  return f % divisor
}

export const calculateIbanFromAccountNumber = accountNumber => {
  if (accountNumber.length < 20) {
    return ''
  }

  /* We put the first 4 characters ES00 at the end, after CCC. We sustitute the letters for numbers: the E with 14 and the S with 28. */
  const orderedItems = `${accountNumber}142800`

  /* We add all the numbers and obtain the remainder of dividing by 97 */
  const sumR = getModuleDivision(orderedItems, 97)

  /* We calculate 98 - R  If the number is less than 10 we add a 0 in front of it */
  const remainder = 98 - sumR

  if (remainder < 10) {
    return `ES0${remainder}`
  }

  return `ES${remainder}`
}

const useStyles = makeStyles({
  'hide-iban': {
    display: 'none',
  },
  'autocomplete-iban-button': {
    color: '#4a90e2',
    fontFamily: "'Roboto',sistem-ui,sans-serif",
    fontSize: '14px',
    fontWeight: 500,
    textDecoration: 'underline',
    background: 'none',
    border: 'none',
    padding: 0,
    margin: 0,
    outline: 'none',
    cursor: 'pointer',
  },
})

const IbanAutoComplete = ({
  value,
  label,
  error,
  type,
  fullWidth,
  onChange,
  name,
  onBlur,
  disabled,
  touched,
  dataHook,
  ibanInputClass,
  hideInput,
}) => {
  const classes = useStyles()

  const maxLength = 29
  const dispatch = useDispatch()
  const [showAutoCompleteIban, setShowAutoCompleteIban] = useState(false)
  const [inputValue, setInputValue] = useState(value)

  useEffect(() => {
    if (value.length === 24) {
      dispatch(validateIBANAction(value))
      setInputValue(value.match(/.{1,4}/g).join(' '))
      onChange(value.match(/.{1,4}/g).join(' '))
    }
  }, [])

  useEffect(() => {
    setInputValue(value)
  }, [value])

  const validateIban = iban => {
    if (iban.length === 24) {
      dispatch(validateIBANAction(iban))
    }
  }

  const calculateIban = () => {
    const accountNumber = inputValue.replace(/ /g, '')
    const result = calculateIbanFromAccountNumber(accountNumber)
    if (result) {
      const iban = `${result} ${inputValue}`.trim() // trim to prevent last whitespace caused by input mask
      setInputValue(iban)
      setShowAutoCompleteIban(false)
      validateIban(iban.replace(/ /g, ''))
      onChange(iban)
    }
  }

  const onChangeIban = e => {
    const iban = e.target.value.replace(/ /g, '')

    if (iban.length === 24) {
      validateIban(iban)
    } else if (iban.length === 20) {
      setShowAutoCompleteIban(true)
    } else {
      setShowAutoCompleteIban(false)
    }
    setInputValue(e.target.value)
    onChange(e.target.value)
  }

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      className={hideInput ? classes['hide-iban'] : ''}>
      <MaskedInput
        onChange={onChangeIban}
        guide={false}
        data-hook={dataHook}
        value={inputValue}
        onBlur={onBlur}
        name={name}
        mask={[
          /([a-zA-Z0-9])/,
          /([a-zA-Z0-9])/,
          /\d/,
          /\d/,
          ' ',
          ...fourDigitsIBAN,
          ' ',
          ...fourDigitsIBAN,
          ' ',
          ...fourDigitsIBAN,
          ' ',
          ...fourDigitsIBAN,
          ' ',
          ...fourDigitsIBAN,
        ]}
        render={(ref, { defaultValue, ...props }) => {
          return (
            <ComposedTextField
              value={defaultValue}
              label={label}
              error={error}
              type={type}
              fullWidth={fullWidth}
              onChange={props.onChange}
              name={name}
              onBlur={props.onBlur}
              disabled={disabled}
              margin="normal"
              variant="outlined"
              touched={touched}
              className={ibanInputClass}
              InputProps={{
                inputProps: {
                  maxLength,
                },
              }}
              inputRef={ref}
              autoComplete="off"
            />
          )
        }}
      />

      {showAutoCompleteIban ? (
        <button
          type="button"
          className={classes['autocomplete-iban-button']}
          data-hook="autocomplete-iban-button"
          onClick={calculateIban}>
          Calcular IBAN
        </button>
      ) : null}
    </Box>
  )
}

IbanAutoComplete.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
  type: PropTypes.string,
  fullWidth: PropTypes.bool,
  onChange: PropTypes.func,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  onBlur: PropTypes.func,
  touched: PropTypes.bool,
  dataHook: PropTypes.string,
  ibanInputClass: PropTypes.string,
  hideInput: PropTypes.bool,
}

export default IbanAutoComplete
