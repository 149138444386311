import PropTypes from 'prop-types'
import { Box, Typography } from '@material-ui/core'

import { TextStyles } from 'utils/text'

import { ActionAdvice } from './../advices/ActionAdvice'
import { AlertAdvice } from './../advices/AlertAdvice'

const ConfigChanges = ({ items, onCancel, message, onComplete }) => {
  return (
    <Box overflow="hidden">
      <Typography {...TextStyles.subtitle2Dark()}>Cambios a realizar:</Typography>
      <Box mt="17px" mb="16px">
        <ActionAdvice type="info" items={items} onCancel={onCancel} />
      </Box>
      <AlertAdvice className="m-b-16" message={message} onComplete={onComplete} />
    </Box>
  )
}

ConfigChanges.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  onCancel: PropTypes.func,
  message: PropTypes.string,
  onComplete: PropTypes.func,
}

export default ConfigChanges
