import { axiosCustomer, axiosJWT } from 'modules/axios'
import { omit } from 'lodash'
import { CONFIG } from './environments'

const CACHE_OFFERS = {}

export function getOrderProducts(orderId) {
  const commonHeaders = omit(axiosCustomer.defaults.headers.common, [
    'Authorization',
    'X-Application-ID',
    'X-SFID',
  ])

  const cacheKey = btoa(JSON.stringify({ orderId }))
  if (CACHE_OFFERS[cacheKey]) {
    return CACHE_OFFERS[cacheKey]
  }

  CACHE_OFFERS[cacheKey] = axiosJWT({
    method: 'GET',
    headers: {
      ...commonHeaders,
      'X-Authorization': axiosCustomer.defaults.headers.common.Authorization,
    },
    url: `${CONFIG.api.offersDetail}/${orderId}`,
  })
    .then(resp => {
      return resp.data
    })
    .catch(err => {
      CACHE_OFFERS[cacheKey] = null
      throw err
    })

  return CACHE_OFFERS[cacheKey]
}
