import { useState } from 'react'
import PropTypes from 'prop-types'
import { isEmpty, get, head, map, reduce, orderBy } from 'lodash'
import { useSelector } from 'react-redux'

import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { TextStyles } from 'utils/text'

import { Card } from 'modules/ui'
import {
  LineItemCard,
  ButtonLink,
  NotificationModal,
  Modal,
  ActionAdvice,
  OutlinedDivider,
  SpinnerCenter,
} from 'components/ui'

import { HighLightFrame } from 'modules/ui/components'

import { formatFee, formatRange, isValidInterval } from 'utils'
import { formatDate } from 'utils/formatting/dates'
import { parseISO, isWithinInterval } from 'date-fns'
import { selectURLDocumentId, selectURLDocumentType } from 'modules/CustomerDocument'
import { getNewTariff, canModifySubscription } from 'modules/offers-configuration/selectors'
import { formatPermanent, formatPermanentFromDetails } from 'modules/offers-configuration/helpers'
import { useIsEnabledCvmModifyPermanencyForLine } from 'modules/offers-configuration/hooks'
import { CVMActions } from 'modules/offers-configuration/CVMActions.constants'
import { selectURLProductId } from 'services/customer-products'
import {
  selectEnabledTaxSvaAgileTv,
  selectEnabledProrratedPenalty,
} from 'services/feature-flag/selectors'
import {
  getProductById,
  isFixedLine,
  getCurrentMonthTariffRanges,
  findFixedPermanents,
  findMobilePermanents,
  findTVPermanents,
  getTerminalsFinancials,
  getTotalCancellationCostsLeft,
  getFeesPendingFromPermanent,
  getTerminalName,
  formatMonthlyFeeForFinancials,
  getVasWithTax,
} from 'services/subscriptions'
import { addCurrentMonthEstimatedFeeWithTaxes } from 'services/subscriptions/tariffRange.helpers'
import { applyTax, getTax } from 'services/taxes'
import { selectors as customerSelectors } from 'services/customer-360'
import { getCommercialName, selectTariffsApigeeById } from 'modules/tariffs/store-apigee'
import { buildPath, AppPaths } from 'modules/AppPaths'
import { permissions, selectCanIDO } from 'modules/Permissions'
import { selectContractId } from 'services/product-orders'
import { selectIsPosOrMasProximo } from 'modules/Permissions/store/permissions-selectors'
import { is2pPureTariff } from 'modules/vista-360/helpers/helpers'
import { useCDCTWorkOrderIds } from 'modules/vista-360/hooks/useCDCTWorkOrderIds'
import { getSegment } from 'services/customer-360/selectors'
import { isProCustomer } from 'services/customer-360/helpers'
import { SubscriptionBundlesInfo } from '../SubscriptionBundlesInfo/SubscriptionBundlesInfo'
import { SubscriptionDiscountsInfo } from '../SubscriptionDiscountsInfo/SubscriptionDiscountsInfo'
import { PermanencyDetail } from '../PermanencyDetail/PermanencyDetail'
import { ContractDownloadError } from './ContractDownloadError'

const PADDING_ELEMENT = '9px 24px'

const useStyles = makeStyles(theme => ({
  'subscription-card': {
    '& .subs-card-button-lnk': {
      whiteSpace: 'nowrap',
    },

    '& .xs-text': {
      fontSize: '12px',
      marginTop: '-4px',
      marginBottom: '12px',
    },

    '& .current-tariff': {
      backgroundColor: 'transparent',
      paddingTop: '8px',
      paddingBottom: '8px',
    },

    '& .multiple.current-tariff': {
      backgroundColor: theme.palette.brand.attention_01,
    },

    '& .old-tariff': {
      backgroundColor: 'transparent',
      paddingTop: '8px',
      paddingBottom: '8px',
      borderRadius: '0px',

      '& .faded': {
        opacity: 0.5,
      },
    },

    '& .btn-link': {
      textAlign: 'left',
      display: 'block',
    },
  },
}))

export const SubscriptionCard = ({
  highLigthedUI,
  refScroll,
  contractDownloadLoading,
  contractDownloadError,
  setContractDownloadError,
  onContractDownload,
  onContractCdCtDownload,
  dataSubscrtiptionFormatted,
  subscription,
  className,
  isTariffChangeLoading,
  STCErrors,
  clearSTCError,
  dss,
  disableDataBonusSva,
  isDisableBonusLoading,
  disableBonusPreviousStatus,
  disableBonusErrors,
  disableBonusOK,
  disableSvaSmartHomeError,
  disableSvaSmartHomeOk,
  disableSvaHomeGoError,
  disableSvaHomeGoOk,
  clearDisableBonusSvaError,
  isPrepaid,
}) => {
  const classes = useStyles()

  const {
    calls,
    bonusesUpsell,
    bonusesMain,
    svaHebe,
    svaSmartHome,
    svaWifiTotal,
    svaHomeGo,
    discounts,
    totalData,
    resetDate,
  } = dataSubscrtiptionFormatted
  const isPosOrMasproximo = useSelector(selectIsPosOrMasProximo)
  const lineId = useSelector(selectURLProductId)

  const product = getProductById(subscription, lineId)
  const isFixedLineVal = isFixedLine(product)
  const subscription_id = get(subscription, 'subscription_id')
  const multisimType = get(subscription, 'multisimType')

  const documentType = useSelector(selectURLDocumentType)
  const documentId = useSelector(selectURLDocumentId)
  const contractId = useSelector(selectContractId)
  const pathToChangeTariffCVM = buildPath(
    AppPaths.cvm.id,
    { documentType, documentId },
    { subscription_id: get(subscription, 'id'), op: CVMActions.change_tariff.id },
  )

  // Permanents
  const latestFixedPermanentByEndDate = head(
    orderBy(
      findFixedPermanents(subscription),
      permanent => parseISO(permanent.validFor.endDateTime),
      'desc',
    ),
  )

  const totalCostsFixedPermanents = reduce(
    findFixedPermanents(subscription),
    (acc, permanent) => acc + getFeesPendingFromPermanent(permanent),
    0,
  )

  const mobilePermanent = head(findMobilePermanents(subscription))
  const tvPermanent = head(findTVPermanents(subscription))

  // Terminal Financials
  const terminalsFinancials = getTerminalsFinancials(subscription)

  const newTariffInProcess = useSelector(getNewTariff(subscription))
  const permissionsCvmOpsTariffChange = useSelector(
    selectCanIDO(permissions.cvm_ops_tariff_change.id),
  )
  const canModifySubscriptionVal = useSelector(state => canModifySubscription(state, subscription))
  const disabledChangedTariff = !canModifySubscriptionVal || !permissionsCvmOpsTariffChange

  const isEnabledCvmModifyPermanencyForLine = useIsEnabledCvmModifyPermanencyForLine(lineId)
  const pathToModifyPermanencyCVM = buildPath(
    AppPaths.cvm.id,
    { documentType, documentId },
    {
      subscription_id: get(subscription, 'id'),
      op: isFixedLineVal
        ? CVMActions.modify_fixed_permanency.id
        : CVMActions.modify_mobile_permanency.id,
    },
  )
  const [isOpenedPermanencyDetail, setIsOpenedPermanencyDetail] = useState(false)

  const enabledProrratedPenalty = useSelector(selectEnabledProrratedPenalty)

  const totalCancellationCostLeft = getTotalCancellationCostsLeft(
    subscription,
    enabledProrratedPenalty,
  )

  const billingAddress = useSelector(state => customerSelectors.getBillingAddress(state))
  const postCode = get(billingAddress, 'postCode')
  const tax = useSelector(state => getTax(state, postCode))
  const tariffRanges = getCurrentMonthTariffRanges(subscription)

  const workOrderIdCDCT = useCDCTWorkOrderIds(subscription_id)

  const customerSegment = useSelector(getSegment)
  const enabledTaxSvaAgileTv = useSelector(selectEnabledTaxSvaAgileTv)
  const taxNeeded = !isProCustomer(customerSegment)
  const VAS = getVasWithTax(subscription, tax, taxNeeded, enabledTaxSvaAgileTv)

  const enrichedDiscounts = map(discounts, discount => ({
    ...discount,
    amountWithTax: discount.isPercentage ? '-' : applyTax(tax, discount.amount),
  }))

  const enrichedTariffRanges = useSelector(state => {
    return orderBy(
      map(addCurrentMonthEstimatedFeeWithTaxes(state, tariffRanges, tax), enrichedTariffRange => ({
        ...enrichedTariffRange,
        commercial_name: getCommercialName(
          selectTariffsApigeeById(enrichedTariffRange.tariffId)(state),
          false,
          multisimType,
        ),
        current:
          isValidInterval(enrichedTariffRange.start, enrichedTariffRange.end) &&
          isWithinInterval(new Date(), {
            start: new Date(enrichedTariffRange.start),
            end: new Date(enrichedTariffRange.end),
          }),
      })),
      item => item.start,
      'desc',
    )
  })
  const multipleTariff = enrichedTariffRanges.length !== 1

  const menuActions = [
    {
      label: 'Descargar contrato',
      onClick: () => onContractDownload(contractId),
      disabled: isPosOrMasproximo,
    },
    {
      label: 'Descargar anexo CD/CT',
      onClick: () => onContractCdCtDownload(workOrderIdCDCT),
      disabled: !workOrderIdCDCT,
    },
  ]

  let deviceAndQuotasInformation

  if (
    isEmpty(terminalsFinancials) &&
    isEmpty(mobilePermanent) &&
    isEmpty(tvPermanent) &&
    isEmpty(VAS)
  ) {
    deviceAndQuotasInformation = (
      <>
        <LineItemCard primaryText="Dispositivo" secondaryText="Sin dispositivo" />
        <LineItemCard primaryText="Cuotas pendientes" secondaryText="Sin dispositivo" />
        <Box p={PADDING_ELEMENT}>
          <OutlinedDivider />
        </Box>
      </>
    )
  } else if (!isEmpty(terminalsFinancials) && !isEmpty(mobilePermanent)) {
    deviceAndQuotasInformation = (
      <>
        <LineItemCard primaryText="Dispositivo" secondaryText={getTerminalName(mobilePermanent)} />
        <LineItemCard
          primaryText="Cuotas pendientes"
          secondaryText={formatMonthlyFeeForFinancials(head(terminalsFinancials))}
        />
        <Box p={PADDING_ELEMENT}>
          <OutlinedDivider />
        </Box>
      </>
    )
  } else if (isEmpty(terminalsFinancials) && !isEmpty(mobilePermanent)) {
    deviceAndQuotasInformation = (
      <>
        <LineItemCard primaryText="Dispositivo" secondaryText={getTerminalName(mobilePermanent)} />
        <LineItemCard primaryText="Cuotas pendientes" secondaryText="Sin cuotas pendientes" />
        <Box p={PADDING_ELEMENT}>
          <OutlinedDivider />
        </Box>
      </>
    )
  }

  return (
    <Card
      className={[classes['subscription-card'], ...(className ? [className] : [])].join(' ')}
      title="Suscripción"
      id="Suscripción"
      menuActions={menuActions}
      withShadow>
      <div
        data-hook={get(highLigthedUI, 'tariff') && 'subscription-lightframe'}
        className={`highLightable ${get(highLigthedUI, 'tariff') && 'active'}`}>
        <HighLightFrame />
        {enrichedTariffRanges &&
          enrichedTariffRanges.map((enrichedTariffRange, i) => (
            <div key={`${enrichedTariffRange.tariffId + i}`}>
              <div
                className={`${enrichedTariffRange.current ? 'current-tariff' : 'old-tariff'} ${
                  multipleTariff ? 'multiple' : ''
                }`}>
                <LineItemCard
                  className="line-item-fst faded"
                  secondaryTextDataHook="tariff-name"
                  primaryText={
                    // eslint-disable-next-line
                    multipleTariff
                      ? enrichedTariffRange.current
                        ? 'Tarifa NUEVA'
                        : 'Tarifa ANTERIOR'
                      : 'Tarifa'
                  }
                  secondaryText={enrichedTariffRange.commercial_name}
                />
                <Typography variant="body1" className="xs-text p-x-24 faded">
                  {`(${formatRange(enrichedTariffRange.start, enrichedTariffRange.end)})`}
                </Typography>
                {i === enrichedTariffRanges.length - 1 && (
                  <Box className="p-b-8 p-x-24">
                    <ButtonLink
                      className="btn-link"
                      data-hook="lnk-change-tariff"
                      to={pathToChangeTariffCVM}
                      disabled={disabledChangedTariff}>
                      Modificar tarifa
                    </ButtonLink>
                  </Box>
                )}
              </div>
              {i !== enrichedTariffRanges.length - 1 && (
                <Box p={PADDING_ELEMENT}>
                  <OutlinedDivider />
                </Box>
              )}
            </div>
          ))}

        {contractDownloadLoading && <SpinnerCenter absolute />}
        {contractDownloadError && (
          <ContractDownloadError
            isOpen={contractDownloadError}
            onClose={() => setContractDownloadError(false)}
          />
        )}

        {newTariffInProcess && (
          <div className="p-x-24 p-y-12">
            <ActionAdvice
              type="info"
              message={`Existe un cambio de tarifa en vuelo: ${newTariffInProcess.commercial_name}`}
            />
          </div>
        )}
        {isTariffChangeLoading && <SpinnerCenter absolute />}
        {STCErrors !== null && (
          <NotificationModal
            type="alert"
            src="/assets/error.svg"
            title="Se ha encontrado un error al actualizar las suscripciones del cliente."
            isOpen
            onClose={clearSTCError}>
            <Typography {...TextStyles.title2Dark()}>{STCErrors}</Typography>
          </NotificationModal>
        )}
      </div>

      {!is2pPureTariff(get(subscription, 'type')) && (
        <>
          <LineItemCard primaryText="Total internet" secondaryText={totalData} />
          <LineItemCard primaryText="Total llamadas" secondaryText={calls} />
        </>
      )}

      {isPrepaid && resetDate && (
        <LineItemCard primaryText="Renovación tarifa" secondaryText={formatDate(resetDate)} />
      )}
      <LineItemCard primaryText="¿Comparte datos?" secondaryText={dss ? 'Sí' : 'No'} />

      <Box p={PADDING_ELEMENT}>
        <OutlinedDivider />
      </Box>

      {/* Permanency */}
      <LineItemCard
        primaryText="Perm. Tarifa"
        secondaryText={
          isFixedLineVal
            ? formatPermanentFromDetails(
                get(latestFixedPermanentByEndDate, 'validFor.endDateTime'),
                totalCostsFixedPermanents,
              )
            : formatPermanent(mobilePermanent)
        }
      />

      <Box className="p-b-8 p-x-24">
        <ButtonLink
          className="btn-link"
          dataHook={isFixedLineVal ? 'lnk-modify-permanency-fixed' : 'lnk-modify-permanency-mobile'}
          to={pathToModifyPermanencyCVM}
          disabled={!isEnabledCvmModifyPermanencyForLine}>
          Modificar Permanencia
        </ButtonLink>
      </Box>

      <div>
        <Modal
          width="auto"
          isOpen={isOpenedPermanencyDetail}
          title="Desglose de permanencia y penalización"
          onClose={() => setIsOpenedPermanencyDetail(false)}>
          <PermanencyDetail subscription={subscription} />
        </Modal>
      </div>

      <Box p={PADDING_ELEMENT}>
        <OutlinedDivider />
      </Box>

      {/* Terminals */}
      {deviceAndQuotasInformation}

      <div className="flex align-space-between p-r-24">
        <LineItemCard
          primaryText="Si cancela hoy"
          secondaryText={formatFee(totalCancellationCostLeft)}
        />
        <ButtonLink
          className="subs-card-button-lnk"
          data-hook="permanency-detail-btn"
          onClick={() => setIsOpenedPermanencyDetail(true)}
          disabled={!(totalCancellationCostLeft > 0)}>
          Ver desglose
        </ButtonLink>
      </div>

      <Box p={PADDING_ELEMENT}>
        <OutlinedDivider />
      </Box>

      <SubscriptionBundlesInfo
        subscription={subscription}
        bonusesUpsell={bonusesUpsell}
        bonusesMain={bonusesMain}
        svaHebe={svaHebe}
        svaSmartHome={svaSmartHome}
        svaWifiTotal={svaWifiTotal}
        svaHomeGo={svaHomeGo}
        disableDataBonusSva={disableDataBonusSva}
        isDisableBonusLoading={isDisableBonusLoading}
        disableBonusPreviousStatus={disableBonusPreviousStatus}
        disableBonusErrors={disableBonusErrors}
        disableBonusOK={disableBonusOK}
        disableSvaSmartHomeError={disableSvaSmartHomeError}
        disableSvaSmartHomeOk={disableSvaSmartHomeOk}
        disableSvaHomeGoError={disableSvaHomeGoError}
        disableSvaHomeGoOk={disableSvaHomeGoOk}
        clearDisableBonusSvaError={clearDisableBonusSvaError}
        className={`highLightable ${get(highLigthedUI, 'bundles') && 'active'}`}
        dataHook={get(highLigthedUI, 'bundles') ? 'bundles-lightframe' : null}
        isPosOrMasProximo={isPosOrMasproximo}
      />
      <Box p={PADDING_ELEMENT}>
        <OutlinedDivider />
      </Box>

      <SubscriptionDiscountsInfo
        refScroll={refScroll}
        subscription={subscription}
        discounts={enrichedDiscounts}
        className={`highLightable ${get(highLigthedUI, 'discounts') && 'active'}`}
        dataHook={get(highLigthedUI, 'discounts') && 'discounts-lightframe'}
      />
    </Card>
  )
}

SubscriptionCard.propTypes = {
  dataSubscrtiptionFormatted: PropTypes.object,
  refScroll: PropTypes.object,
  contractDownloadLoading: PropTypes.bool,
  contractDownloadError: PropTypes.bool,
  setContractDownloadError: PropTypes.func,
  onContractDownload: PropTypes.func,
  onContractCdCtDownload: PropTypes.func,
  dss: PropTypes.bool,
  subscription: PropTypes.object,
  STCErrors: PropTypes.string,
  clearSTCError: PropTypes.func,
  isTariffChangeLoading: PropTypes.bool,
  disableDataBonusSva: PropTypes.func,
  isDisableBonusLoading: PropTypes.bool,
  disableBonusErrors: PropTypes.bool,
  disableBonusOK: PropTypes.bool,
  disableSvaSmartHomeError: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  disableSvaSmartHomeOk: PropTypes.bool,
  clearDisableBonusSvaError: PropTypes.func,
  highLigthedUI: PropTypes.object,
  className: PropTypes.string,
  disableBonusPreviousStatus: PropTypes.bool,
  disableSvaHomeGoError: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  disableSvaHomeGoOk: PropTypes.bool,
  isPrepaid: PropTypes.bool,
}
