import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { CircularProgress, Typography, FormHelperText } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { TariffCellDiscount } from 'modules/ui'

const useStyles = makeStyles({
  message: {
    paddingBottom: '16px',
    color: 'red',
  },
})

export function TariffDiscounts({
  isLoadingPromotions,
  isErrorPromotions,
  promotionsData,
  setDiscount,
  selectedDiscount,
}) {
  const classes = useStyles()

  useEffect(() => {
    if (isLoadingPromotions) {
      setDiscount({})
    }
  }, [isLoadingPromotions])

  return (
    <>
      {isLoadingPromotions && <CircularProgress size="32px" color="secondary" />}
      {!isLoadingPromotions && isErrorPromotions && (
        <FormHelperText error data-hook="tariff-discounts-error" className="p-b-20">
          Ha sucedido un error al recuperar los descuentos, vuelva a seleccionar una tarifa.
        </FormHelperText>
      )}
      {!isLoadingPromotions && promotionsData.length > 0 && (
        <>
          <Typography variant="body1" className="p-r-4">
            Estas son las promociones disponibles para la tarifa seleccionada
          </Typography>

          <div className="flex full-width flex-wrap align-start-center p-b-28 p-t-20">
            {promotionsData?.map(discount => (
              <div className="m-r-20 m-b-12 tariff-wrapper" key={discount?.poId}>
                <TariffCellDiscount
                  selected={discount?.poId === selectedDiscount?.poId}
                  discount={discount}
                  onSelectDiscount={selectDiscount => {
                    setDiscount(selectDiscount)
                  }}
                />
              </div>
            ))}
          </div>
        </>
      )}
      {!isLoadingPromotions && promotionsData.length === 0 && (
        <Typography variant="body1" className={classes.message}>
          No hay promociones para esta tarifa
        </Typography>
      )}
    </>
  )
}

TariffDiscounts.propTypes = {
  isLoadingPromotions: PropTypes.bool,
  isErrorPromotions: PropTypes.bool,
  promotionsData: PropTypes.array,
  setDiscount: PropTypes.func,
  selectedDiscount: PropTypes.object,
}
