import { get } from 'lodash'

const getData = state => get(state, 'offersDetail.data', {})
const isLoading = state => get(state, 'offersDetail.loading')
const hasErrors = state => get(state, 'offersDetail.error')

const getOrdersByClient = state => get(state, 'offersDetail.ordersByClient', [])

export const selectors = {
  getData,
  isLoading,
  hasErrors,
  getOrdersByClient,
}
