import { makeStyles } from '@material-ui/styles'

import PropTypes from 'prop-types'
import { AdviceTextField } from './AdviceTextField'

const useStyles = makeStyles({
  'advice-label-value': {
    '&&': { margin: '0' },
    '& .label': {
      'font-size': '14px',
    },
    '& .value': {
      'font-size': '16px',
      'font-weight': '500',
      'padding-left': '4px',
    },
  },
})

export function AdviceLabelValue({ label, value, padding }) {
  const classes = useStyles()

  return (
    <AdviceTextField
      className={`advice-label-value ${classes['advice-label-value']}`}
      padding={padding}>
      <span className="label">{label ? `${label}:` : ''}</span>
      <span className="value">{value}</span>
    </AdviceTextField>
  )
}

AdviceLabelValue.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  padding: PropTypes.string,
}
