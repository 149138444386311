import { useCallback, useEffect } from 'react'

import { useSelector, useDispatch } from 'react-redux'
import { useForceSaveAuth } from 'modules/Auth'

import { fetchEnergyContracts } from '../../store/energy.actions'

import { goEnergyCRM, addSfidParam } from '../../helpers'

import { clientEnergyContainerSelectors } from './ClientEnergyContainer.selectors'

import { ClientEnergy } from './ClientEnergy'

export const ClientEnergyContainer = () => {
  const dispatch = useDispatch()

  const {
    permissionsGoEnergyCRM,
    permissionsViewContracts,
    documentId,
    contracts,
    isLoading,
    error,
    isPosOrMasproximo,
  } = useSelector(clientEnergyContainerSelectors)

  const { saveLatestAccessToken } = useForceSaveAuth()

  const fetchContracts = useCallback(() => {
    if (permissionsViewContracts) {
      dispatch(fetchEnergyContracts(documentId))
    }
  }, [documentId, permissionsViewContracts])

  useEffect(() => {
    if (!isLoading && contracts === null && error === null) {
      fetchContracts()
    }
  }, [])

  const goToCRM = useCallback(() => {
    let url = goEnergyCRM(!isPosOrMasproximo ? documentId : null)

    url = addSfidParam(url)

    saveLatestAccessToken()
    window.open(url, '_blank')
  }, [documentId])

  const actions = permissionsGoEnergyCRM
    ? [
        {
          label: 'Ir al CRM de energía',
          onClick: goToCRM,
        },
      ]
    : []

  const props = {
    menuItems: actions,
    isLoading,
    error,
    contracts,
    onRetry: fetchContracts,
  }

  return <ClientEnergy {...props} />
}

ClientEnergyContainer.propTypes = {}
