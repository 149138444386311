import { LineItemCard } from 'components/ui'
import { get, isEmpty } from 'lodash'
import styled from 'styled-components'
import { getTranslatedPeriod, formatFee } from 'utils'

const LineItemCardStyled = styled(LineItemCard)`
  width: auto;
`

function formatDiscountPeriod(discount) {
  if (discount) {
    if (discount.valid_periods_amount === 0) {
      return 'Para siempre'
    }
    return `${discount.valid_periods_amount} ${getTranslatedPeriod(discount.valid_periods_unit)}`
  }
  return ''
}

export function DiscountPlans({ discount_plans, tariff }) {
  return (
    !isEmpty(discount_plans) &&
    discount_plans.map(discount => {
      const discountId = get(discount, 'id')
      const discountUnit = get(discount, 'unit')
      let fee

      if (discountUnit) {
        const prices = get(tariff, 'prices')
        const discountTargets = get(discount, 'discountTargets', [])

        if (isEmpty(discountTargets)) {
          fee = tariff.monthly_fee
        } else {
          fee = discountTargets.reduce((acc, discountTarget) => {
            const targetValue = get(discountTarget, 'targetValue')
            const price = prices.find(target => get(target, 'charge-category') === targetValue)
            return acc + get(price, 'prices.tax-free-amount')
          }, 0)
        }
      }

      return discount.amount ? (
        <div key={`${discountId}`} className="m-b-8">
          <div className="flex align-start">
            <LineItemCardStyled primaryText="Descuento" secondaryText={discount.description} />
          </div>
          <div className="flex align-start m-l-20" data-hook="discount-plans">
            {discountUnit === 'percents' ? (
              <LineItemCardStyled
                primaryText="Importe"
                secondaryText={`-${formatFee((fee * discount.amount) / 100)}/mes (${
                  discount.amount
                }%)`}
              />
            ) : (
              <LineItemCardStyled
                primaryText="Importe"
                secondaryText={`-${formatFee(Number(discount.amount))}/mes`}
              />
            )}
            <LineItemCardStyled
              primaryText="Duración"
              secondaryText={formatDiscountPeriod(discount)}
            />
          </div>
        </div>
      ) : (
        ''
      )
    })
  )
}
