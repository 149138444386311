import { useSelector } from 'react-redux'
import { useState } from 'react'
import { selectContractId } from 'modules/NewClientSales/store/selectors/orders.selectors'

import { fetchPDFContract } from '../../services/pdfContract.service'

export function useDownloadPDFContract() {
  const [error, setError] = useState(false)

  const contractId = useSelector(selectContractId)

  function downloadPDFContract() {
    setError(false)
    fetchPDFContract(contractId)
      .then(() => setError(false))
      .catch(() => setError(true))
  }

  return { downloadPDFContract, contractError: error }
}
