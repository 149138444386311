import { APP_CONFIG } from 'services/app-config'

export const CONFIG = {
  api: {
    applyDiscount: `${APP_CONFIG.care_cluster}/ms-discounts/apply-discount`,
    condonePenalties: `${APP_CONFIG.apigee_host}/masmovil/v1/products/condone_penalties`,
    getTariffsV3: `${APP_CONFIG.care_cluster}/ms-tariffs-change/stc/tariffs`,
    getTariffsV3Phase2: `${APP_CONFIG.care_cluster}/ms-eligibilities/tariffsPO`,
    getOrderElegibility: `${APP_CONFIG.care_cluster}/ms-eligibilities/decisions`,
    getOrderConsequences: `${APP_CONFIG.care_cluster}/ms-eligibilities/consequences`,
    getTechnology: `${APP_CONFIG.care_cluster}/ms-eligibilities/productCategories`,
    technologyChange: `${APP_CONFIG.care_cluster}/ms-tech-change/technology-change`,
    addressChange: `${APP_CONFIG.care_cluster}/ms-address-change/address-change`,
    getInstaller: `${APP_CONFIG.care_cluster}/installation/addressConnection`,
  },
}
