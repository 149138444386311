import { lighten, darken } from '@material-ui/core/styles/colorManipulator'
import { segmentPalette } from '../../themes/mainTheme/colors'

export const getLighten = (color, value = 0.25) => lighten(color, value)
export const getDarken = (color, value = 0.25) => darken(color, value)

export const getSegmentColor = segment => {
  if (segment) {
    switch (segment.toLowerCase()) {
      case 'consumer':
        return segmentPalette.consumer
      case 'influencer':
        return segmentPalette.influencer
      case 'customersva':
        return segmentPalette.customerSva
      case 'pro-soho-autonomo':
      case 'pro-soho-company':
        return segmentPalette.pro
      case 'soho-company':
      case 'soho-autonomo':
        return segmentPalette.soho
      case 'warning':
        return segmentPalette.warning
      default:
        return segmentPalette.default
    }
  } else {
    return segmentPalette.default
  }
}
