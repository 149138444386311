import PropTypes from 'prop-types'
import { Badge } from 'modules/ui/components/Badge'
import { BadgeTypes } from 'modules/ui/components/Badge/constants'

export const TICKET_STATUS = {
  CREADO: 'Creado',
  ESPERA_INFO_CLIENTE: 'Espera Info Cliente',
  EN_PROGRESO: 'En Progreso',
  PDTE_VALIDACION: 'Pdte Validacion',
  RESUELTO: 'Resuelto',
  CANCELADO: 'Cancelado',
  CERRADO: 'Cerrado',
}

export const ticketStatusLabels = {
  [TICKET_STATUS.CREADO]: 'Creado',
  [TICKET_STATUS.ESPERA_INFO_CLIENTE]: 'Espera Info Cliente',
  [TICKET_STATUS.EN_PROGRESO]: 'En Progreso',
  [TICKET_STATUS.PDTE_VALIDACION]: 'Pdte Validación',
  [TICKET_STATUS.RESUELTO]: 'Resuelto',
  [TICKET_STATUS.CANCELADO]: 'Cancelado',
  [TICKET_STATUS.CERRADO]: 'Cerrado',
}

export const ticketStatusColors = {
  [TICKET_STATUS.CREADO]: BadgeTypes.INFO,
  [TICKET_STATUS.ESPERA_INFO_CLIENTE]: BadgeTypes.ATTENTION,
  [TICKET_STATUS.EN_PROGRESO]: BadgeTypes.ALTERNATIVE,
  [TICKET_STATUS.PDTE_VALIDACION]: BadgeTypes.ATTENTION,
  [TICKET_STATUS.RESUELTO]: BadgeTypes.VALIDATED,
  [TICKET_STATUS.CANCELADO]: BadgeTypes.ALERT,
  [TICKET_STATUS.CERRADO]: BadgeTypes.BLOCKED,
}

const ticketStatusLabelsChip = {
  [TICKET_STATUS.CREADO]: { label: 'C' },
  [TICKET_STATUS.ESPERA_INFO_CLIENTE]: { icon: 'frozen' },
  [TICKET_STATUS.EN_PROGRESO]: { label: 'P' },
  [TICKET_STATUS.PDTE_VALIDACION]: { label: 'V' },
  [TICKET_STATUS.RESUELTO]: { label: 'R' },
  [TICKET_STATUS.CANCELADO]: { label: 'X' },
  [TICKET_STATUS.CERRADO]: { label: 'C' },
}

export function TicketStatusBadge(props) {
  const { status, chip, lg } = props

  return (
    <Badge
      minWidth="70px"
      maxWidth="70px"
      strong
      chip={chip}
      lg={lg}
      value={
        chip ? ticketStatusLabelsChip[status] : { label: ticketStatusLabels[status].toUpperCase() }
      }
      type={ticketStatusColors[status]}
    />
  )
}

TicketStatusBadge.defaultProps = {
  status: 'Cerrado',
}

TicketStatusBadge.propTypes = {
  status: PropTypes.oneOf([
    TICKET_STATUS.CREADO,
    TICKET_STATUS.ESPERA_INFO_CLIENTE,
    TICKET_STATUS.EN_PROGRESO,
    TICKET_STATUS.PDTE_VALIDACION,
    TICKET_STATUS.RESUELTO,
    TICKET_STATUS.CANCELADO,
    TICKET_STATUS.CERRADO,
  ]).isRequired,
  chip: PropTypes.bool,
  lg: PropTypes.bool,
}
