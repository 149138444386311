import { get, isEmpty } from 'lodash'
import { createSelector } from 'reselect'

const getCustomerActivity = state => get(state, 'customerActivity')

const getErrorCallsNodes = state => get(state, 'customerActivity.errorCallsNodes')

export const selectError = createSelector(
  getCustomerActivity,
  customerActivity => customerActivity.error,
)

export const selectData = createSelector(
  getCustomerActivity,
  customerActivity => customerActivity.data,
)

export const selectLoading = createSelector(
  getCustomerActivity,
  customerActivity => customerActivity.loading,
)

export const selectIsErrorsInNodes = createSelector(
  getErrorCallsNodes,
  errorCallsNodes => !isEmpty(errorCallsNodes),
)

export const selectErrorInNode = node =>
  createSelector(
    getErrorCallsNodes,
    errorCallsNodes => !isEmpty(errorCallsNodes?.find(error => error === node)),
  )
