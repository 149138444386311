import styled, { css } from 'styled-components'

export default styled.div`
  font-size: 14px;
  color: white;
  text-align: center;
  border-radius: 4px;

  padding: 10px;
  min-width: 256px;
  display: inline-block;
  background-color: #b9c2c7;
  color: #fff;
  margin-right: 24px;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};

  ${({ selected, theme }) =>
    selected &&
    css`
      background-color: ${theme.palette.primary.main};
    `};
`
