import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { isEmpty, get } from 'lodash'
import { Price } from 'components/ui'
import { formatNumber } from 'utils'
import {
  findFixedPermanents,
  findMobilePermanents,
  getTotalCancellationCostsLeft,
} from 'services/subscriptions'
import { selectEnabledProrratedPenalty } from 'services/feature-flag/selectors'

import { getPermanenceOrPenaltyChanges } from '../../selectors'

function getPermanentPenaltyRemovedCosts(permanenceAndPenalty, permanent) {
  const hasPermanenceOrPenaltyRemoved =
    !isEmpty(permanenceAndPenalty) &&
    (permanenceAndPenalty.permanenceRemoved || permanenceAndPenalty.penaltyCondoned)

  return hasPermanenceOrPenaltyRemoved ? get(permanent, '[0].fees.pending', 0) : 0
}

export function PermanentInfoItem({ className, subscription, isModified }) {
  const permanentFixed = findFixedPermanents(subscription)
  const permanentMobile = findMobilePermanents(subscription)

  const enabledProrratedPenalty = useSelector(selectEnabledProrratedPenalty)
  const totalCost = getTotalCancellationCostsLeft(subscription, enabledProrratedPenalty)

  // New Permanents
  const permanenceAndPenaltyMobile = useSelector(state =>
    getPermanenceOrPenaltyChanges(state, subscription.id),
  )

  const permanenceAndPenaltyFixed = useSelector(state =>
    getPermanenceOrPenaltyChanges(state, subscription.id, true),
  )

  const newTotalCost =
    totalCost -
    getPermanentPenaltyRemovedCosts(permanenceAndPenaltyMobile, permanentMobile) -
    getPermanentPenaltyRemovedCosts(permanenceAndPenaltyFixed, permanentFixed)

  return totalCost > 0 ? (
    <Price
      className={className}
      title={isModified ? 'Nueva permanencia' : 'Si cancela hoy'}
      price={isModified ? formatNumber(newTotalCost) : formatNumber(totalCost)}
      montly
      aux="Por permanencia"
    />
  ) : null
}

PermanentInfoItem.propTypes = {
  className: PropTypes.string,
  subscription: PropTypes.object,
  isModified: PropTypes.bool,
}
