import { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'

import { Typography } from '@material-ui/core'
import { TextStyles } from 'utils/text'

import { FormikInput, Modal, Button, ButtonLink } from 'components/ui'

import { get } from 'lodash'
import { useFormikContext } from 'formik'

import { useICCValidation } from '../../../../hooks/useICCValidation'

const ICCID_WITHOUT_PREFIX_LENGTH = 15
const ICCID_SPAIN_PREFIX = 8934

export function InputICC({
  fieldNames,
  checkWithCatalog,
  isPortability,
  isMobileOnly,
  isLeadFilled,
  noObtainPrefix = false,
}) {
  const [open, setIsOpen] = useState()
  const { validate, error, hasMsError } = useICCValidation(checkWithCatalog)

  const { values, setFieldValue } = useFormikContext()

  const iccName = useMemo(
    () => (isPortability ? fieldNames.PORTABILITY_ICC_NUMBER : fieldNames.ICC_NUMBER),
    [isPortability],
  )

  const iccValue = useMemo(() => get(values, iccName), [values, iccName])

  const obtainIccidPrefix = () => {
    setFieldValue(iccName, ICCID_SPAIN_PREFIX + iccValue)
  }

  useEffect(() => {
    setIsOpen(!!hasMsError)
  }, [hasMsError])

  return (
    <>
      <div className="flex">
        <FormikInput
          name={iccName}
          label="ICC de la tarjeta"
          maxLength="19"
          disabled={{ bool: isMobileOnly && !isLeadFilled }}
          validate={validate}
          fullWidth
        />

        {!noObtainPrefix && (
          <ButtonLink
            data-hook="get-iccid-button"
            className="m-l-36"
            onClick={obtainIccidPrefix}
            disabled={!iccValue || iccValue.length !== ICCID_WITHOUT_PREFIX_LENGTH}>
            Obtener ICC
          </ButtonLink>
        )}
      </div>

      <Modal isOpen={open} width="500px" data-hook="icc-error-modal">
        <div className="flex align-center-start">
          <Typography {...TextStyles.paragraphDark()}>{error}</Typography>
        </div>
        <div className="p-y-12 flex align-center-start">
          <Button data-hook="close-modal" onClick={() => setIsOpen(false)}>
            VOLVER
          </Button>
        </div>
      </Modal>
    </>
  )
}

InputICC.propTypes = {
  fieldNames: PropTypes.object.isRequired,
  checkWithCatalog: PropTypes.bool,
  isPortability: PropTypes.bool,
  isMobileOnly: PropTypes.bool,
  isLeadFilled: PropTypes.bool,
  noObtainPrefix: PropTypes.bool,
}
