import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import { Typography } from '@material-ui/core'
import { TextStyles } from 'utils/text'

const PopoverlistStyled = styled.div`
  position: absolute;
  top: ${props => props.top && props.top};
  right: ${props => props.right && props.right};
  left: ${props => props.left && props.left};
  z-index: 1;
  padding-left: 8px;
  padding-right: 15px;
  min-width: 160px;
  box-sizing: border-box;
  background: #ffffff;
  text-align: center;
  font-weight: 500;
  border-radius: 8px;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.2);
  &::before {
    ${props =>
      props.hasArrow &&
      css`
        content: '';
        position: absolute;
        width: 16px;
        height: 16px;
        top: 50%;
        right: ${props.arrowPlacement === 'right' ? '-3px' : ''};
        left: ${props.arrowPlacement === 'left' ? '-11px' : ''};
        transform: translateY(-50%);
        transform: rotate(-45deg);
        transform-origin: top left;
        background-color: #ffffff;
        box-shadow: ${props.arrowPlacement === 'right'
          ? '2px 2px 1px 1px rgba(0, 0, 0, 0.05);'
          : '-2px -2px 1px 1px rgba(0, 0, 0, 0.05);'};
      `}
  }
`

const PopoverLi = styled.li`
  cursor: pointer;
  padding-top: 11px;
  padding-bottom: 13px;
  &:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }
`

const PopoverList = props => {
  const { actions, top, right, left, hasArrow, arrowPlacement } = props

  return (
    <PopoverlistStyled
      top={top}
      right={right}
      left={left}
      actions={actions}
      hasArrow={hasArrow}
      arrowPlacement={arrowPlacement}>
      <ul>
        {actions.map((action, i) => (
          <PopoverLi key={i.toString()}>
            <Typography {...TextStyles.link()} onClick={action.action}>
              {action.text}
            </Typography>
          </PopoverLi>
        ))}
      </ul>
    </PopoverlistStyled>
  )
}

PopoverList.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      action: PropTypes.string,
      text: PropTypes.string,
    }),
  ),
  top: PropTypes.string,
  right: PropTypes.string,
  left: PropTypes.string,
  hasArrow: PropTypes.bool,
  arrowPlacement: PropTypes.oneOf(['left', 'right']),
}

export default PopoverList
