import { getUrlObject, getUrlWithoutParams } from './urlHelpers'

export function getURLSearchParams(searchStr) {
  return new URLSearchParams(searchStr)
}

// TODO: deprecated, try to use getURLSearchParams
export function getQueryParams(searchStr = '') {
  return searchStr
    ? searchStr
        .replace('?', '')
        .split('&')
        .reduce((acc, param) => {
          const [key, value] = param.split('=')
          acc[key] = decodeURIComponent(value)
          return acc
        }, {})
    : {}
}

/**
 * Add a queryParam to a query search
 */
export function addQueryParam(search, key, value) {
  if (search.split('?').length > 1) {
    return search
      .split('?')
      .map((urlSegment, index) => {
        return index ? addQueryParam(urlSegment, key, value) : urlSegment
      })
      .join('?')
  }
  const params = getURLSearchParams(search)
  params.set(key, value)
  return params.toString()
}

/**
 * Add a queryParam to a absolute url
 */
export function addQueryParamToAbsolute(url, key, value) {
  const urlData = getUrlObject(url)
  let params = urlData.search || '?'

  params = addQueryParam(params, key, value)

  return `${getUrlWithoutParams(urlData)}${params}`
}

/**
 * Add a queryParam to a query search or absolute URL
 */
export function removeQueryParam(url, key) {
  if (url.split('?').length > 1) {
    return url
      .split('?')
      .map((urlSegment, index) => {
        return index ? removeQueryParam(urlSegment, key) : urlSegment
      })
      .join('?')
  }
  const params = getURLSearchParams(url)
  params.delete(key)
  return params.toString()
}

export function objectToQueryString(object) {
  return Object.keys(object)
    .sort((a, b) => a - b)
    .map(key => `${key}=${encodeURIComponent(object[key])}`)
    .join('&')
}

export function mergeParams(search1, search2) {
  const params1 = getQueryParams(search1)
  const params2 = getQueryParams(search2)
  const newParams = { ...params1, ...params2 }

  return objectToQueryString(newParams)
}

export function toLowerKeyParams(params) {
  return Object.keys(params).reduce(
    (result, key) => ({
      ...result,
      [key.toLowerCase()]: params[key],
    }),
    {},
  )
}
