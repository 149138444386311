import { useEffect } from 'react'
import { useFormikContext } from 'formik'
import { useSelector } from 'react-redux'

import { selectUpsellSendSubscriptionId } from 'services/feature-flag/selectors'

import { selectSubscriptionMsisdn } from 'services/customer-360/selectors'

import { selectContractId, selectDuoLimitSubscriptionId } from 'modules/CustomerOrders'

import { selectSubscriptionInformation } from '../../store/selectors'

export const useSetParentAgreementId = (
  shouldSetParentAgreementId,
  isSubscriptionSale,
  isMultiSimSale,
) => {
  const { setFieldValue } = useFormikContext()

  const subscriptionInfo = useSelector(selectSubscriptionInformation)
  const contractId = useSelector(selectContractId)
  const subscriptionId = useSelector(selectDuoLimitSubscriptionId)
  const susbscriptionMsisdn = useSelector(selectSubscriptionMsisdn)

  const upsellSendSubscriptionIdFlag = useSelector(selectUpsellSendSubscriptionId)

  const setParentAgreementId = () => {
    let idSubscription =
      subscriptionInfo.saleType === 'active' ? subscriptionInfo.subscriberId : subscriptionId

    idSubscription =
      upsellSendSubscriptionIdFlag && (isSubscriptionSale || isMultiSimSale) && idSubscription
        ? String(idSubscription)
        : ''

    setFieldValue('contractId', idSubscription || contractId || null)
    if (isMultiSimSale) {
      setFieldValue('mainProductNumber', susbscriptionMsisdn || null)
    }
  }

  useEffect(() => {
    if (contractId || subscriptionId || shouldSetParentAgreementId) {
      setParentAgreementId()
    }
  }, [contractId, subscriptionId, shouldSetParentAgreementId])
}
