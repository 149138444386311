import { get, find, head, pickBy } from 'lodash'
import { ValidateEmail, isMobilePhoneNumber } from 'utils'
import { documentTypes } from 'modules/CustomerDocument'
import {
  SIGNUP_TYPE_PORTABILITY,
  SIGNUP_TYPE_NEW,
  SINGLE,
  COMPANY,
  AUTONOMO,
} from 'services/global-constants'

import {
  PORTABILITY_TYPE_PREPAID,
  ADSL_LOWER_CASED,
  FIBER,
  PAPER,
  EMAIL,
  NEBA,
  VULA,
  FTTH,
  ADSL,
} from 'modules/SharedSales/constants'

import {
  FINANCIAL_CODE_ZEROS,
  FINANCIAL_CODE_XFERA,
} from 'modules/SharedSales/constants/financialCodes'
import { parseISO } from 'date-fns'

const getFixedPortabilityNumber = (value, isElFijo, is2p) => {
  const elFijoPortaNumber = isElFijo && value.lineNumber
  const portaNumber2p = is2p && value.lineNumber
  const portaFixedNumber = !(is2p || isElFijo) && value.lineNumber
  return elFijoPortaNumber || portaNumber2p || portaFixedNumber
}

const getMobilePortabilityNumber = (value, isElFijo, is2p) => {
  const portaMobileNumber = !(is2p || isElFijo) && value.lineNumber
  return portaMobileNumber
}

const getPortabilityNumber = (value, isElFijo, isFixedLine, is2p) => {
  return isFixedLine
    ? getFixedPortabilityNumber(value, isElFijo, is2p)
    : getMobilePortabilityNumber(value, isElFijo, is2p)
}

const getFixedNewNumber = (value, isElFijo, is2p) => {
  const elFijoNewFixedNumber = isElFijo && value.newFixedNumber
  const newFixedNumber2p = is2p && value.newFixedNumber
  const newFixedNumber = !(isElFijo || is2p) && value.newNumber.value
  return elFijoNewFixedNumber || newFixedNumber2p || newFixedNumber
}

const getMobileNewNumber = (value, isElFijo, is2p, isCrossSell) => {
  const elFijoNewMobileNumber = isElFijo && value.newNumber.value

  const newMobileNumber2p = is2p && value.newNumber.value

  const newMobileNumberCross = isCrossSell && value.lineNumber

  const newMobileNumber = !(is2p || isElFijo) && value.newNumber.value

  return newMobileNumberCross || elFijoNewMobileNumber || newMobileNumber2p || newMobileNumber
}

const getNewNumber = (value, isElFijo, isFixedLine, is2p, isCrossSell) => {
  return isFixedLine
    ? getFixedNewNumber(value, isElFijo, is2p)
    : getMobileNewNumber(value, isElFijo, is2p, isCrossSell)
}

const signUpLineNumber = (
  signUpType,
  portabilityNumber,
  newNumber,
  isElFijo,
  is2p,
  isFixedLine,
) => {
  if (signUpType === SIGNUP_TYPE_PORTABILITY) {
    if ((isElFijo || is2p) && isFixedLine) return portabilityNumber
    if ((isElFijo || is2p) && !isFixedLine) return newNumber
    return portabilityNumber
  }
  return newNumber
}

const returnLineNumber = (value, isElFijo, isFixedLine, is2p, isCrossSell) => {
  const portaNumber = getPortabilityNumber(value, isElFijo, isFixedLine, is2p)
  const newNumber = getNewNumber(value, isElFijo, isFixedLine, is2p, isCrossSell)
  const signUpType = value.signUpType || SIGNUP_TYPE_NEW

  return signUpLineNumber(signUpType, portaNumber, newNumber, isElFijo, is2p, isFixedLine)
}

const getSignUpType = value =>
  value.signUpType === SIGNUP_TYPE_PORTABILITY ? SIGNUP_TYPE_PORTABILITY : SIGNUP_TYPE_NEW

const getMobileNumberType = isNewNumber => (isNewNumber ? SIGNUP_TYPE_NEW : SIGNUP_TYPE_PORTABILITY)

const mobileInputtedCharacteristics = (
  isNewNumber,
  isPrepaid,
  mobileLine,
  isElFijo,
  is2p,
  isCrossSell,
  is2pPure,
) => {
  const msisdn =
    is2p && is2pPure
      ? {}
      : pickBy({ CH_MSISDN: returnLineNumber(mobileLine, isElFijo, false, is2p, isCrossSell) })

  const inputtedCharacteristics = pickBy({
    CH_Mobile_Portability_ICCID:
      !isNewNumber && isPrepaid ? mobileLine.portabilityIccNumber : undefined,
    CH_Mobile_Portability_Payment_Type: !isNewNumber ? mobileLine.portabilityType : undefined,
    CH_Mobile_Portability_OperatorName: !isNewNumber
      ? get(mobileLine, `operator.tradingName`)
      : undefined,
    CH_Mobile_Portability_OperatorCode: !isNewNumber ? get(mobileLine, `operator.id`) : undefined,
    hard_reservation: !isCrossSell ? get(mobileLine, 'terminal.reservationId') : undefined,
  })

  const mobileType =
    !isCrossSell && !(is2p && is2pPure)
      ? pickBy({ ch_mobile_number_type: getMobileNumberType(isNewNumber) })
      : {}
  return { ...inputtedCharacteristics, ...mobileType, ...msisdn }
}

const returnShapedContactMedia = ({ value, type, role }) => {
  const isEmail = ValidateEmail(value)
  const medium = isEmail
    ? {
        email: value,
      }
    : {
        number: value,
        number_type: type,
      }
  return {
    role: role || 'primary',
    medium,
    mediumType: isEmail ? 'email-address' : 'telephone-number',
  }
}

const returnPortabilityTechnologyType = (signUpType, isPortability, technology) => {
  switch (technology) {
    case NEBA:
    case VULA:
    case FTTH:
      return isPortability === 'yes' ? FIBER : null
    case ADSL: {
      let technologyPortability = isPortability === 'yes' ? FIBER : ADSL_LOWER_CASED
      if (signUpType !== SIGNUP_TYPE_PORTABILITY) technologyPortability = null
      return technologyPortability
    }
    default:
      return null
  }
}
// Exportable helpers

export const mapToInstallationMedium = (
  {
    streetType,
    street,
    number,
    zipCode,
    floor,
    gescal,
    addressId,
    id,
    town,
    city,
    flat,
    door,
    km,
    Hand,
    bis_duplicate,
    letter,
    stair,
    block,
    otherInfo,
    geolocation,
    provinceId,
  },
  coAddresExpanded = true,
) =>
  pickBy({
    medium: {
      gescal: gescal || id || '000',
      geolocation,
      co_address: coAddresExpanded
        ? `${floor || flat || ''} ${Hand || door || ''} ${otherInfo || ''}`
        : `${otherInfo || ''}`,
      country: 'ES',
      street: `${streetType} ${street}`,
      building: number,
      floor: floor || flat,
      city: town || city,
      state_or_province: zipCode ? zipCode.substr(0, 2) : provinceId,
      postal_code: zipCode,
      address_register_id: addressId || id || ' ',
      door,
      letter,
      stair,
      block,
      hand: Hand,
      km,
      bis: bis_duplicate,
      'street-type': streetType,
    },
  })

export const formatYoigoStore = ({ selectedPoint: { id, name, attributes }, zipCode }) => {
  const { city, address } = attributes
  return {
    medium: {
      'point-id': id,
      'point-name': name,
      'point-type': 'yoigo_shop',
      'postal-address': {
        country: 'ES',
        street: address,
        city,
        'state-or-province': String(zipCode).slice(0, 2),
        'postal-code': zipCode,
      },
    },
    type: 'delivery-point',
  }
}

export const mapToOtherAddress = (coverage, address) => {
  if (get(address, 'deliveryPoint.selectedPoint.id', false)) {
    return formatYoigoStore(address.deliveryPoint)
  }

  return address.selectedAddressType === 'otherAddress'
    ? mapToInstallationMedium(address.otherAddress)
    : mapToInstallationMedium(coverage)
}

export const mapToElFijoShippingAddress = (shipping, billing, service) => {
  const elFijoBillingAddress =
    billing.selectedAddressType === 'otherAddress' ? billing.otherAddress : service.otherAddress
  const elFijoMainDefaultAddress =
    shipping.selectedAddressType === 'billingAddress' ? elFijoBillingAddress : service.otherAddress
  return elFijoMainDefaultAddress
}

export const mapToRootOrderMobileItem = (mobileLine, isElFijo, is2p, isCrossSell, is2pPure) => {
  const isNewNumber = isElFijo || is2p || getSignUpType(mobileLine) === SIGNUP_TYPE_NEW
  const isPrepaid = mobileLine.portabilityType === PORTABILITY_TYPE_PREPAID

  const mobileCharacteristics = mobileInputtedCharacteristics(
    isNewNumber,
    isPrepaid,
    mobileLine,
    isElFijo,
    is2p,
    isCrossSell,
    is2pPure,
  )

  return {
    inputtedCharacteristics: mobileCharacteristics,
    productOfferingId: mobileLine.tariff.id,
    requestedDate: get(mobileLine, 'portabilityDate', null),
  }
}

export const mapToRootOrderLandlineItem = (
  landlineOffer,
  mainMobileLineOffer,
  coverage,
  installationData,
  technology,
  elFijoId,
  is2p,
  isCrossSell,
  is2pPure,
  internalPortabilityFlag,
) => {
  const offer = elFijoId ? mainMobileLineOffer : landlineOffer

  const broadTechType = returnPortabilityTechnologyType(
    offer.signUpType,
    offer.isPortabilityFromFtth,
    elFijoId ? FTTH : technology,
  )

  const mobileCharacteristics = get(
    mapToRootOrderMobileItem(
      is2p ? landlineOffer : mainMobileLineOffer,
      !!elFijoId,
      is2p,
      isCrossSell,
      is2pPure,
    ),
    'inputtedCharacteristics',
  )
  const isPortability = getSignUpType(offer) === SIGNUP_TYPE_PORTABILITY
  const isADSL = technology === ADSL
  const isInternalPortability = get(offer, 'operator.internalPortability', false)
  const isInstallationRequired =
    !isInternalPortability || get(offer, 'operator.installationRequired', false)

  const inputtedCharacteristics = pickBy({
    ...mobileCharacteristics,
    ch_broadband_portability_technology_type: broadTechType,
    CH_Fixed_number_type: getSignUpType(offer),
    CH_Broadband_number_type:
      !elFijoId && (!broadTechType ? SIGNUP_TYPE_NEW : SIGNUP_TYPE_PORTABILITY),
    CH_Fixed_Portability_OperatorName: isPortability && get(offer, 'operator.tradingName'),
    CH_Fixed_Portability_OperatorCode: isPortability && get(offer, 'operator.id'),
    CH_Fixed_Internal_Portability:
      isPortability && internalPortabilityFlag && !elFijoId
        ? isInternalPortability.toString()
        : null,
    CH_Installation_Required:
      isPortability && internalPortabilityFlag && !elFijoId
        ? (!isADSL && isInstallationRequired).toString()
        : null,
    CH_Exempt_Permanency:
      !isADSL && isPortability && internalPortabilityFlag && !elFijoId
        ? (
            !get(offer, 'operator.installationRequired', false) &&
            get(offer, 'operator.internalPortability', false)
          ).toString()
        : null,
    CH_Fixed_Number: returnLineNumber(offer, !!elFijoId, true, is2p),
    CH_Broadband_Estimated_Speed: offer.tariff.fixedLineDownloadSpeed,
    CH_Fixed_Territory_Owner: elFijoId
      ? undefined
      : get(
          find(get(head(get(coverage, 'coverage', [])), 'characteristics', {}), {
            name: 'territoryOwner',
          }),
          'value',
        ),
    CH_Fixed_Coverage_Token: elFijoId ? undefined : coverage.sessionId,
    CH_Fixed_IUA: isPortability && !isADSL && !isInstallationRequired ? undefined : offer.IUA,
    CH_Installation_Point_Type:
      elFijoId || (isPortability && !isADSL && !isInstallationRequired)
        ? undefined
        : installationData.connectionType,
    CH_Installation_Point_Provider:
      elFijoId || (isPortability && !isADSL && !isInstallationRequired)
        ? undefined
        : installationData.installer,
  })
  return {
    inputtedCharacteristics,
    productOfferingId: offer.tariff.id,
    requestedDate: get(mainMobileLineOffer, 'portabilityDate', null),
  }
}

export const mapToBillingAccount = (billing, ibanData) => ({
  attributes: {
    name: 'default',
    currency: 'EUR',
    characteristics: {
      iban: get(ibanData, 'iban') || billing.ibanNumber,
      bank_name: get(ibanData, 'name'),
    },
  },
  billingProfileAttributes: {
    bill_delivery_method: billing.isPaperInvoice ? PAPER : EMAIL,
    payment_method: 'Direct Debit',
    billing_cycle: 1,
    billing_interval: { count: 1, interval: 'month' },
  },
})

export const mapToIndividualIdentification = (account, idDueDate) =>
  pickBy({
    attributes: {
      identification_id: account.documentid || account.documentId,
      identification_type: account.documenttype || account.documentType,
      valid_for: idDueDate
        ? {
            end_datetime: idDueDate,
            meta: {
              type: 'valid-for-datetime',
            },
          }
        : null,
    },
  })

export const mapToCompanyIdentification = account => ({
  attributes: {
    identification_id: account.companyCif,
    identification_type: documentTypes.CIF,
  },
})

const getNumberTypeFromValue = value => {
  return isMobilePhoneNumber(value) ? 'mobile' : 'fixed-line'
}

export const mapToContactMedia = ({ phoneContact, phoneContact2, email }) => {
  return [
    { value: phoneContact, type: getNumberTypeFromValue(phoneContact) },
    { value: phoneContact2, type: getNumberTypeFromValue(phoneContact2), role: 'home' },
    { value: email, type: 'email' },
  ].map(elem => returnShapedContactMedia(elem))
}

export const mapToOrderChildItem = (items, routerNumber, isPos, iccNumber, is2pAnd2pPure) => {
  const simCharacteristics = pickBy({ CH_ICCID: iccNumber })

  const itemsFiltered = is2pAnd2pPure
    ? items.filter(item => !get(item, 'id', '').includes('SIM'))
    : items

  return itemsFiltered.map(elem => {
    const inputtedCharacteristics = isPos
      ? { ...pickBy(elem.inputtedCharacteristics), ...simCharacteristics }
      : { ...pickBy(elem.inputtedCharacteristics) }

    return {
      noDelivery: elem.noDelivery,
      inputtedCharacteristics:
        elem.category === 'router'
          ? pickBy({
              ch_router_id: routerNumber || undefined,
            })
          : inputtedCharacteristics,
      productOfferingId: elem.id || elem,
      category: elem.category,
    }
  })
}

export const mapToOrderChildren = (characteristics, shipping, mainAddress) => {
  return characteristics.map(characteristic => ({
    characteristic,
    installation: get(characteristic, 'productOfferingId', []).includes('SIM')
      ? shipping
      : mainAddress,
  }))
}

export const mapToPersonalData = ({
  name,
  surname1,
  surname2,
  nationality,
  birthday,
  language,
  segment,
}) => ({
  language,
  nationality,
  given_name: name,
  family_name: surname1,
  additional_name: surname2 || '',
  date_of_birth: birthday ? birthday.split('/').reverse().join('-') : null,
  privacy_settings: {
    mark_gdpr: false,
  },
  characteristics: {
    self_employed: segment === AUTONOMO ? 'true' : 'false',
  },
  honorific_prefix: 'Sr.',
})

export const mapToCompanyData = ({ companyName, constitutionDate }) => {
  const [day, month, year] = constitutionDate ? constitutionDate.split('/') : ['27', '07', '2018']
  const start_datetime = parseISO(`${year}-${month}-${day}T05:00:00`)

  return {
    trading_name: companyName,
    valid_for: {
      start_datetime,
    },
  }
}

export const mapToOtherIndividualIndentification = (lines, account) =>
  lines
    .filter(offer => offer.isSameUser === 'no' && offer.signUpType === SIGNUP_TYPE_PORTABILITY)
    .map(elem => {
      const { otherUser, type } = elem
      const isCompany = otherUser.documentType === documentTypes.CIF
      return {
        companyIdentification: mapToIndividualIdentification(elem.otherUser),
        companyData: mapToCompanyData({
          companyName: otherUser.companyName,
        }),
        individualIdentification: mapToIndividualIdentification(
          !isCompany ? elem.otherUser : account,
        ),
        personalInformation: mapToPersonalData(isCompany ? { ...account } : { ...otherUser }),
        type,
        isCompany,
      }
    })

export const formatDueDate = date => {
  if (!date) {
    return null
  }

  const splittedDate = date.split('/')
  return new Date(splittedDate[2], splittedDate[1], splittedDate[0])
}

export const mapToSelectMobileIds = (
  lines,
  shipping,
  segment,
  financedLegalConsents,
  canSendBankConsent,
) =>
  lines
    .filter(line => line.isNewTerminal === 'yes')
    .map(line => {
      const { paymentType, job, salary, company, idDueDate } = get(line, 'terminal')

      const CH_Financed_By = segment === COMPANY ? FINANCIAL_CODE_ZEROS : FINANCIAL_CODE_XFERA
      const terminalIsFinanced = paymentType !== SINGLE

      const inputtedCharacteristics = {
        Ch_Hard_Book: null,
        CH_Financed_By: paymentType === SINGLE ? undefined : CH_Financed_By,
        CH_Dni_Expiry_Date: terminalIsFinanced ? formatDueDate(idDueDate) : undefined,
        CH_Monthly_Income: terminalIsFinanced
          ? salary?.replace('€', '').replace('.', '')
          : undefined,
        CH_Profession: terminalIsFinanced ? job?.toString() : undefined,
        CH_Employer: terminalIsFinanced ? company : undefined,
        CH_Bank_Consent_1:
          terminalIsFinanced && canSendBankConsent ? financedLegalConsents.consent1 : undefined,
        CH_Bank_Consent_2:
          terminalIsFinanced && canSendBankConsent ? financedLegalConsents.consent2 : undefined,
        CH_Bank_Consent_3:
          terminalIsFinanced && canSendBankConsent ? financedLegalConsents.consent3 : undefined,
      }
      const imei = get(line, 'terminal.imei', null)
      if (imei) inputtedCharacteristics.ch_imei_id = imei
      return {
        characteristic: {
          productOfferingId: get(line, `terminal.details.paymentMethods.${paymentType}.id`),
          inputtedCharacteristics,
        },
        installation: shipping,
      }
    })

export const mapMemberGetMemberToChildOrderItem = memberGetMemberPromo => {
  const mappedData = []

  if (memberGetMemberPromo?.poId && memberGetMemberPromo?.msisdn) {
    mappedData.push({
      noDelivery: true,
      productOfferingId: memberGetMemberPromo.poId,
      inputtedCharacteristics: {
        CH_Referee: memberGetMemberPromo.msisdn,
      },
    })
  }

  return mappedData
}

export const mapFormatCampaign = campaign => {
  const po = campaign?.campaignPo
  return po
    ? [
        {
          noDelivery: true,
          productOfferingId: po,
          inputtedCharacteristics: {
            CH_CampaignCode: campaign?.id,
          },
        },
      ]
    : []
}

export const mapFormatCampaignDiscounts = campaign => {
  const po = campaign?.campaignPo
  return po ? campaign.discounts.map(discount => discount.externalId) : []
}

export const mapCrossSellDiscountToChildOrderItem = lineOffer => {
  const mappedData = []

  if (lineOffer.crossSellTariffPromo?.poId) {
    mappedData.push({
      noDelivery: true,
      productOfferingId: lineOffer.crossSellTariffPromo.poId,
      inputtedCharacteristics: {},
    })
  }

  return mappedData
}

const mapToFormatedBundles = selectedBundles =>
  selectedBundles.map(elem => ({
    inputtedCharacteristics: elem.characteristics || {},
    productOfferingId: elem.id,
  }))

export const mapToFormatMainOrder = (
  rootOrderItem,
  isMobileOnly,
  isElFijo,
  landlineOffer,
  mainMobileLineOffer,
  terminalAddress,
  billingAccount,
  shipping,
  account,
  mainAddress,
  mainLineBundles,
  saleType,
  is2p,
  isCrossSell,
  is2pPure,
  isConvergent,
  financedLegalConsents,
  canSendBankConsent,
) => {
  const { isPos } = saleType
  const ICC = get(mainMobileLineOffer, 'iccNumber')
  const memberGetMemberPromo = get(mainMobileLineOffer, 'memberGetMemberPromo')

  const is2pAnd2pPure = is2p && is2pPure

  const mappedMemberGetMemberPromo = mapMemberGetMemberToChildOrderItem(memberGetMemberPromo)
  const mappedCrossSellDiscount = isCrossSell
    ? mapCrossSellDiscountToChildOrderItem(landlineOffer)
    : []

  const campaignData =
    is2p || isConvergent || isCrossSell ? landlineOffer.campaign : mainMobileLineOffer.campaign

  const bundles = mapToOrderChildItem(
    mainLineBundles,
    landlineOffer.routerNumber,
    isPos,
    ICC,
    is2pAnd2pPure,
  )

  const discounts = [...mapFormatCampaignDiscounts(campaignData)]

  const orderChildren = mapToOrderChildren(
    [
      ...bundles,
      ...mapFormatCampaign(campaignData),
      ...mappedMemberGetMemberPromo,
      ...mappedCrossSellDiscount,
    ],
    shipping,
    mainAddress,
  )

  const orderMobile = head(
    mapToSelectMobileIds(
      [mainMobileLineOffer],
      terminalAddress,
      account.segment,
      financedLegalConsents,
      canSendBankConsent,
    ),
  )

  const notConvergentOffer = isMobileOnly
    ? mainMobileLineOffer
    : { ...mainMobileLineOffer, type: 'fixed' }

  const otherUserIdentification = mapToOtherIndividualIndentification(
    !isMobileOnly && !isElFijo
      ? [{ ...landlineOffer, type: 'fixed' }, mainMobileLineOffer]
      : [notConvergentOffer],
    account,
  )

  return {
    rootOrderItem: {
      ...rootOrderItem,
      discounts,
    },
    billingAccount,
    orderChildren,
    otherUserIdentification,
    orderMobile,
    id: isMobileOnly || isElFijo ? mainMobileLineOffer.id : landlineOffer.id,
  }
}

export const mapToGetExtraMobileLines = (
  extraLines,
  billingAccount,
  account,
  shipping,
  terminalAddress,
  mainAddress,
  financedLegalConsents,
  canSendBankConsent,
  isMultiSim,
) =>
  extraLines.map(elem => {
    const bundledItems = mapToFormatedBundles([
      ...elem.selectedPackages,
      {
        id: head(get(elem, 'tariff.bundledItems', [])),
        ...(isMultiSim
          ? {
              characteristics: {
                CH_ICCID: get(elem, 'iccNumber'),
              },
            }
          : {}),
      },
    ])

    const campaigns = mapFormatCampaign(elem.campaign)
    const rootOrderItem = mapToRootOrderMobileItem(elem)
    const orderChildren = mapToOrderChildren([...bundledItems, ...campaigns], shipping, mainAddress)
    const orderMobile = head(
      mapToSelectMobileIds(
        [elem],
        terminalAddress,
        account.segment,
        financedLegalConsents,
        canSendBankConsent,
      ),
    )

    const discounts = [...mapFormatCampaignDiscounts(elem.campaign)]

    const otherUserIdentification = mapToOtherIndividualIndentification([elem], account)
    return {
      rootOrderItem: {
        ...rootOrderItem,
        discounts,
      },
      billingAccount,
      orderChildren,
      otherUserIdentification,
      orderMobile,
      id: elem.id,
    }
  })

export const mapToGetFullExtraMobileLines = (selectedExtraMobileLines, offersExtraInformation) =>
  selectedExtraMobileLines.map(elem => get(offersExtraInformation, `${elem.tariff.id}`), {})

export const mapToSelectIdDueDate = line => {
  const idDueDate = get(line, 'terminal.idDueDate', false)

  return formatDueDate(idDueDate)
}

export const mapToCarteraAddress = ({ address, zipCode, town, city }) => {
  return {
    country: 'ES',
    street: address,
    city: town || city,
    'state-or-province': String(zipCode).slice(0, 2),
    'postal-code': zipCode,
  }
}

export const mapToCarteraOrShippingAddress = (address, shipping) => {
  return shipping.selectedAddressType === 'otherAddress'
    ? mapToInstallationMedium(shipping.otherAddress)
    : { medium: mapToCarteraAddress(address) }
}

export const hasAddressId = address => {
  return !!address?.addressId || !!address?.id || !!address?.sessionId
}
