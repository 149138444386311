import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'

import * as atcSvc from 'services/atc'
import { OrderAtcMain } from '../../components/OrderAtcMain/OrderAtcMain'

const NOT_SHOW_ATC_STATUS = ['20', '21', '31', '41', '42', '43']

export const OrderAtcContainer = ({ orderId, orderStatus, ...rest }) => {
  const dispatch = useDispatch()

  const notShowAtc = NOT_SHOW_ATC_STATUS.includes(orderStatus?.code)

  const props = {
    loading: useSelector(atcSvc.selectors.selectIsLoading),
    error: useSelector(atcSvc.selectors.selectHasError),
    url: useSelector(atcSvc.selectors.selectIframeUrl),
    notShowAtc,
    ...rest,
  }

  useEffect(() => {
    if (!notShowAtc) {
      dispatch(atcSvc.actions.fetchAtcAuthToken(orderId))
    }
  }, [])

  return <OrderAtcMain {...props} />
}

OrderAtcContainer.propTypes = {
  orderId: PropTypes.string,
  orderStatus: PropTypes.object,
}
