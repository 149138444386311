import { get, isEmpty, pickBy, values } from 'lodash'

import * as validators from './validators'

const isCustomer = (customerAccount, customerParty) =>
  get(customerAccount, 'type') === 'customer-accounts' &&
  get(customerParty, 'attributes.role') === 'customer'

export default class CheckoutValidator {
  constructor({ offerDetail, result, offersMetadata }) {
    if (!offersMetadata) {
      this.offersMetadata = [offerDetail]
    } else {
      this.offersMetadata = offersMetadata
    }
    this.result = result
    this.errors = {}
  }

  validate() {
    this.validateOrders()
    this.validateCustomer()
    return pickBy(this.errors)
  }

  validateOrders() {
    this.result.relationships.order_items.forEach((rootOrder, position) => {
      const offerMetadata = this.offersMetadata[position]
      const isConvergent = offerMetadata.data[0].relationships.categories.product_categories.some(
        x => x.id === 'convergent',
      )
      const isMainOrder = position === 0

      this.validateRootOrder({ rootOrder, offerMetadata })
      if (isConvergent) {
        this.validateRootInstallationMedium({ rootOrder })
      }
      if (isMainOrder) {
        this.validateRootBillingAccount({ rootOrder })
      }
      this.validateChildrenOrders({ rootOrder, offerMetadata })
      this.validateOrderRelatedParties({ rootOrder })
    })
  }

  validateRootOrder({ offerMetadata, rootOrder }) {
    const input = rootOrder.relationships.order_product[0].attributes.inputted_characteristics
    try {
      const schema = validators.generateOfferValidator(offerMetadata.data[0])
      schema.validateSync(input, { abortEarly: false })
    } catch (err) {
      this.errors.rootOffer = err.errors
    }
  }

  validateChildrenOrders({ rootOrder, offerMetadata }) {
    const MAP_POids_to_PSs = {}
    for (const PO of values(offerMetadata.included.product_offerings)) {
      const PSid = get(PO, 'relationships.specifications.specifications[0].id')
      const PS = get(offerMetadata.included.specifications, PSid)
      if (PS) {
        MAP_POids_to_PSs[PO.id] = PS
      }
    }

    get(rootOrder, 'relationships.child_order_items', []).forEach((childOrder, position) => {
      const childOrderProduct = childOrder.relationships.order_product[0]
      const childOrderProductPO = childOrderProduct.relationships.product_offering[0]

      const PS = get(MAP_POids_to_PSs, childOrderProductPO.id)
      if (PS) {
        this.validateChildOrder({ childOrderProduct, position, offerMetadata: PS })
        this.validateChildInstallationMedium({ childOrder, position })
        this.validateChildDeliveryMedium({ childOrder, position })
      }
    })
  }

  validateRootInstallationMedium({ rootOrder }) {
    try {
      validators.validateInstallationMedium(rootOrder.relationships.installation_medium[0])
    } catch (err) {
      this.errors.installation_medium = err.errors
    }
  }

  validateRootBillingAccount({ rootOrder }) {
    try {
      validators.validateBillingAccount(rootOrder.relationships.billing_account[0])
    } catch (err) {
      this.errors.billing_account = err.errors
    }
  }

  validateChildOrder({ childOrderProduct, position, offerMetadata }) {
    try {
      const schema = validators.generateOfferValidator(offerMetadata)
      schema.validateSync(childOrderProduct.attributes.inputted_characteristics, {
        abortEarly: false,
      })
    } catch (err) {
      if (!this.errors[`order_child_${position}`]) {
        this.errors[`order_child_${position}`] = {}
      }
      this.errors[`order_child_${position}`].attributes = err.errors
    }
  }

  validateChildInstallationMedium({ childOrder, position }) {
    try {
      if (!isEmpty(childOrder.relationships.installation_medium)) {
        validators.validateInstallationMedium(childOrder.relationships.installation_medium[0])
      }
    } catch (err) {
      if (!this.errors[`order_child_${position}`]) {
        this.errors[`order_child_${position}`] = {}
      }
      this.errors[`order_child_${position}`].installation_medium = err.errors
    }
  }

  validateChildDeliveryMedium({ childOrder, position }) {
    try {
      if (!isEmpty(childOrder.relationships.delivery_medium)) {
        validators.validateInstallationMedium(childOrder.relationships.delivery_medium[0])
      }
    } catch (err) {
      if (!this.errors[`order_child_${position}`]) {
        this.errors[`order_child_${position}`] = {}
      }
      this.errors[`order_child_${position}`].installation_medium = err.errors
    }
  }

  validateExtraUser({ customerParty, i }) {
    try {
      const customerIndividual = get(customerParty, 'relationships.party[0]')
      this.validateCustomerIdentification({ customerIndividual })
      this.validateContactMedias({ customerIndividual })
      this.validateCustomerAttributes({ customerIndividual, isExtra: true })
    } catch (err) {
      this.errors[`related_party_users_${i}`] = err.errors
    }
  }

  validateOrderRelatedParties({ rootOrder }) {
    if (!isEmpty(get(rootOrder, 'relationships.related_parties', []))) {
      const relatedParties = get(rootOrder, 'relationships.related_parties', [])
      relatedParties.forEach((customerParty, i) => {
        this.validateExtraUser({ customerParty, i })
      })
    }
  }

  validateCustomer() {
    const customerAccount = get(this.result, 'relationships.customer_account[0]')
    const customerParty = get(customerAccount, 'relationships.related_parties[0]')
    // Protection against unknown types
    if (get(customerAccount, 'type') !== 'customer-accounts') {
      return
    }

    if (isCustomer(customerAccount, customerParty)) {
      const customerIndividual = get(customerParty, 'relationships.party[0]')
      this.validateCustomerIdentification({ customerIndividual })
      this.validateContactMedias({ customerIndividual })
      this.validateCustomerAttributes({ customerIndividual })
    }
    this.validateBillingAccounts({ customerAccount })
  }

  validateCustomerAttributes({ customerIndividual, isExtra }) {
    try {
      const { attributes } = customerIndividual
      if (isExtra) {
        this.validateExtraCustomerAttributes({ attributes })
      } else {
        validators.validateIndividualAttributes(attributes)
      }
    } catch (err) {
      this.errors.customer_attributes = err.errors
    }
  }

  validateExtraCustomerAttributes({ attributes }) {
    try {
      if (attributes.trading_name) {
        validators.validateExtraCompanyAttributes(attributes)
      } else {
        validators.validateExtraIndividualAttributes(attributes)
      }
    } catch (err) {
      this.errors.customer_attributes_extra = err.errors
    }
  }

  validateCustomerIdentification({ customerIndividual }) {
    customerIndividual.relationships.identifications.forEach((identification, i) => {
      try {
        if (customerIndividual.type === 'organizations') {
          validators.validateCompanyIdentification(identification)
        } else {
          validators.validateIdentification(identification)
        }
      } catch (err) {
        this.errors[`identification_${i}`] = err.errors
      }
    })
  }

  validateContactMedias({ customerIndividual }) {
    customerIndividual.relationships.contact_media.forEach((contactMedia, i) => {
      try {
        validators.validateContactMedia(contactMedia)
      } catch (err) {
        this.errors[`contact_media_${i}`] = err.errors
      }
    })
  }

  validateBillingAccounts({ customerAccount }) {
    customerAccount.relationships.billing_accounts.forEach((billingAccount, i) => {
      try {
        validators.validateBillingAccount(billingAccount)
      } catch (err) {
        this.errors[`customer_billing_account_${i}`] = err.errors
      }
    })
  }
}
