import { useMemo, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { TerminalStockContainer } from 'modules/NewClientSales/containers/ClientInfoContainer/TerminalStockContainer/index'
import { SVAsProModal } from 'modules/SVAs/components/SVAsProModalComponent/index'
import { LINE_TYPE } from 'modules/Lines/constants'
import { useLocation } from 'react-router-dom'

import {
  TariffPickerActions,
  TariffPickerFinalPrice,
  TariffPickerHeading,
  TariffPickerModal,
} from 'modules/tariffs/components'

import { useCampaigns } from 'modules/tariffs/hooks'
import { useSecondResidenceDiscount } from 'modules/NewClientSales/hooks/Discounts/useSecondResidenceDiscount'
import { useChannelAndSegment } from 'modules/NewClientSales/hooks/Shared'
import { AddedProductsSummary } from './AddedProductsSummary'
import { BundlesPickerModal } from './BundlesPickerModal'
import { useTariffPicker } from './useTariffPicker'
import { TariffPermanency } from './TariffPermanency'
import { SecondResidenceDiscountAdvice } from './SecondResidenceDiscountAdvice'
import { SVAsAdvice } from './SVAsAdvice'

const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    borderRadius: '10px',
    border: ({ selectedTariff, isDisabled }) =>
      `2px solid ${
        !selectedTariff?.id || isDisabled
          ? theme.palette.global.gray
          : theme.palette.bluePalette.blue_500
      }`,
    marginBottom: '16px',
    position: 'relative',
    'flex-direction': 'column',
  },
  'header-content': {
    padding: '17px 24px 0',
  },
  'products-summary': {
    '& > div:first-child hr': {
      display: 'none',
    },
  },
}))

const mapCrossSellBundlesToAdvices = crossSellBundles => (
  <AddedProductsSummary
    list={crossSellBundles?.map(crossSellBundle => ({
      display: [
        {
          label: 'Pierde',
          value: crossSellBundle.name,
        },
      ],
    }))}
    type="error"
  />
)

export function SalesTariffPicker({
  selectedTariff,
  selectedBundles,
  tariffs,
  isLoadingTariffs,
  isConvergent,
  isCrossSell,
  lineType,
  onCancelSelectTariff,
  onSelectBundles,
  onSelectTariff,
  onDeleteBundle,
  isStockCheck,
  shouldShowAddBundles,
  shouldShowCrossSellBundlesChanges,
  error,
  fieldNames,
  disableOfferEdit,
  isMobileOnly,
  isMultiSim,
  isLeadFilled,
  errorDelete,
  shouldShowPermanency,
}) {
  const isDisabled = useMemo(() => !isLeadFilled && isMobileOnly && disableOfferEdit, [
    isLeadFilled,
    isMobileOnly,
    disableOfferEdit,
  ])
  const classes = useStyles({ selectedTariff, isDisabled })

  const {
    isMobile,
    isPro,
    onClickAddProSVAs,
    onClickAddBundles,
    onClickChangeTariff,
    disableBundles,
    disableChangeTariff,
    disableProSVAs,
    isLoadingBundles,
    isOpenBundlesModal,
    isOpenTariffsModal,
    isOpenProSVAsModal,
    onCancelAddBundles,
    onCancelChangeTariff,
    bundleList,
    onClickSelectTariff,
    onClickSelectBundles,
    onClickDeleteBundle,
    onClickDeleteProSVA,
    onClickSelectProSVAs,
    proSVAs,
    proSVAList,
    formatedSvas,
    formatedSvasPermanency,
    crossSellCompatibleBundles,
    crossSellIncompatibleBundles,
    crossSellCompatibleDiscountAdvices,
    crossSellIncompatibleDiscountAdvices,
    crossSellReplaceableDiscountAdvices,
    crossSellTariffPromotionAdvice,
    isXsellFlag
    //  onCancelAddProSvas,
  } = useTariffPicker(
    selectedTariff,
    selectedBundles,
    tariffs,
    onCancelSelectTariff,
    onSelectTariff,
    onSelectBundles,
    onDeleteBundle,
    lineType,
    disableOfferEdit,
    isMobileOnly,
    isCrossSell,
    isMultiSim,
  )

  const { segment } = useChannelAndSegment()

  const location = useLocation()

  const isEditing = location.search.indexOf('isEditing') !== -1
  const [isFirstSelectedTariff, setIsFirstSelectedTariff] = useState(true)
  const [isBackStep, setIsBackStep] = useState(location?.state?.goingBack || isEditing)

  useEffect(() => {
    if ((crossSellCompatibleBundles.length || selectedBundles.length) && !isBackStep) {
      onClickSelectBundles(crossSellCompatibleBundles.map(bundle => ({ ...bundle, active: true })))
    }
  }, [bundleList])

  useEffect(() => {
    if (!isFirstSelectedTariff) {
      setIsBackStep(false)
    }

    setIsFirstSelectedTariff(false)
  }, [selectedTariff])

  const { campaign, resetCampaign, canApplyCampaign } = useCampaigns(fieldNames, lineType)
  const { discountAdviceMessage, doesSecondResidenceDiscountApply } = useSecondResidenceDiscount(
    fieldNames,
  )

  return (
    <div className="flex flex-column full-width m-b-8">
      <div className={classes.header}>
        <div className={classes['header-content']}>
          <TariffPickerHeading
            selectedTariff={selectedTariff}
            lineType={lineType}
            onClickChangeTariff={onClickChangeTariff}
            disabled={isDisabled}
            errorDelete={errorDelete}>
            {selectedTariff?.id && !disableOfferEdit && (
              <TariffPickerActions
                disableChangeTariff={disableChangeTariff}
                onClickChangeTariff={onClickChangeTariff}
                onClickAddBundles={onClickAddBundles}
                disableOfferEdit={disableOfferEdit}
                disableBundles={disableBundles}
                isMobile={isMobile}
                isPro={isPro}
                isLoadingBundles={isLoadingBundles}
                disableSVAs={disableProSVAs}
                onClickAddProSvas={onClickAddProSVAs}
                shouldShowAddBundles={shouldShowAddBundles}
                disableProSVAs={disableProSVAs}
                onClickAddProSVAs={onClickAddProSVAs}
                fieldNames={fieldNames}
                selectedTariff={selectedTariff}
                lineType={lineType}
                disableCampaignButton={
                  !!campaign?.campaignPo || (!isXsellFlag && isCrossSell) || !canApplyCampaign || isMultiSim
                }
              />
            )}
          </TariffPickerHeading>

          {selectedTariff?.id && (
            <div className={classes['products-summary']}>
              {campaign?.campaignPo && (
                <AddedProductsSummary
                  type="success"
                  list={[
                    {
                      display: [{ label: `Campaña ${campaign.id}`, value: campaign.discountText }],
                    },
                  ]}
                  onDelete={() => resetCampaign()}
                />
              )}

              <AddedProductsSummary list={crossSellIncompatibleDiscountAdvices} type="error" />

              <AddedProductsSummary list={crossSellCompatibleDiscountAdvices} />

              <AddedProductsSummary list={crossSellReplaceableDiscountAdvices} type="warning" />

              <AddedProductsSummary list={selectedTariff?.formatedDiscounts} />

              <AddedProductsSummary list={crossSellTariffPromotionAdvice} />

              {shouldShowCrossSellBundlesChanges && !isLoadingBundles && (
                <>{mapCrossSellBundlesToAdvices(crossSellIncompatibleBundles, false)}</>
              )}

              <AddedProductsSummary list={selectedTariff?.bundles} onDelete={onClickDeleteBundle} />

              {doesSecondResidenceDiscountApply && (
                <SecondResidenceDiscountAdvice discountMessage={discountAdviceMessage} />
              )}

              {(lineType === LINE_TYPE.LANDLINE || lineType === LINE_TYPE.MAIN_MOBILE) && (
                <SVAsAdvice
                  svaList={formatedSvas}
                  svaPermanencyList={formatedSvasPermanency}
                  onDelete={onClickDeleteProSVA}
                />
              )}

              {!!selectedTariff?.permanency?.amount &&
                (lineType !== LINE_TYPE.LANDLINE || shouldShowPermanency) && (
                  <TariffPermanency permanency={selectedTariff?.permanency} isPro={isPro} />
                )}
            </div>
          )}
        </div>

        {selectedTariff?.id && (
          <TariffPickerFinalPrice selectedTariff={selectedTariff} isPro={isPro} />
        )}
      </div>

      {selectedTariff?.id && (
        <>
          {isStockCheck && selectedTariff?.id && (
            <div className="p-t-12">
              <TerminalStockContainer />
            </div>
          )}
          <div>
            <SVAsProModal
              svaList={proSVAList}
              saveSvas={onClickSelectProSVAs}
              storeSvas={proSVAs}
              isOpen={isOpenProSVAsModal}
              isPro={isPro}
              isConvergent={isConvergent}
            />
            <BundlesPickerModal
              tariff={selectedTariff}
              isOpen={isOpenBundlesModal}
              onCancel={onCancelAddBundles}
              onSelectBundles={onClickSelectBundles}
              selectedBundles={selectedBundles}
              bundleList={bundleList}
            />
          </div>
        </>
      )}
      <TariffPickerModal
        isOpen={isOpenTariffsModal}
        onCancel={onCancelChangeTariff}
        isConvergent={isConvergent}
        tariffs={tariffs}
        selectedTariff={selectedTariff}
        isLoadingTariffs={isLoadingTariffs}
        onSelectTariff={(tariff, xsellPromo) => {
          if (campaign?.campaignPo) resetCampaign()

          onClickSelectTariff(tariff, xsellPromo)
        }}
        error={error}
        segment={segment}
      />
    </div>
  )
}

SalesTariffPicker.propTypes = {
  selectedTariff: PropTypes.object,
  error: PropTypes.object,
  lineType: PropTypes.string,
  onSelectBundles: PropTypes.func,
  tariffs: PropTypes.array,
  selectedBundles: PropTypes.array,
  isLoadingTariffs: PropTypes.bool,
  isConvergent: PropTypes.bool,
  isStockCheck: PropTypes.bool,
  isLeadFilled: PropTypes.bool,
  disableOfferEdit: PropTypes.bool,
  isMobileOnly: PropTypes.bool,
  shouldShowAddBundles: PropTypes.bool,
  shouldShowCrossSellBundlesChanges: PropTypes.bool,
  shouldShowPermanency: PropTypes.bool,
  onSelectTariff: PropTypes.func,
  onCancelSelectTariff: PropTypes.func,
  onDeleteBundle: PropTypes.func,
  fieldNames: PropTypes.object,
  errorDelete: PropTypes.bool,
  isCrossSell: PropTypes.bool,
  isMultiSim: PropTypes.bool,
}
