import PropTypes from 'prop-types'
import { Advice } from './Advice'
import { AdviceTextField } from './AdviceTextField'
import { ActionsWrapper } from './ActionsWrapper'

export function AlertAdvice({
  className = '',
  dataHook,
  message,
  type = 'error',
  block,
  onRetry,
  onUpdate,
  onCancel,
  onDelete,
  onComplete,
}) {
  return (
    <ActionsWrapper
      className={className}
      dataHook={dataHook || 'alert-advice'}
      onUpdate={onUpdate}
      onCancel={onCancel}
      onDelete={onDelete}
      onComplete={onComplete}
      onRetry={onRetry}>
      <Advice type={type} block={block}>
        <AdviceTextField>
          <strong>{message}</strong>
        </AdviceTextField>
      </Advice>
    </ActionsWrapper>
  )
}

AlertAdvice.defaultProps = {
  className: '',
  type: 'error',
}

AlertAdvice.propTypes = {
  className: PropTypes.string,
  dataHook: PropTypes.string,
  message: PropTypes.string,
  type: PropTypes.oneOf(['error', 'info', 'warning', 'success']),
  block: PropTypes.bool,
  onDelete: PropTypes.func,
  onUpdate: PropTypes.func,
  onCancel: PropTypes.func,
  onComplete: PropTypes.func,
  onRetry: PropTypes.func,
}
