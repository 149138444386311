import { useState, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getIn, useFormikContext } from 'formik'

import { logError, formatErrorMessage } from 'services/logging'

import { mobileNumberRegex } from 'utils'
import { selectMemberGetMemberPromoInfo, fetchMemberGetMemberAction } from 'modules/tariffs/store'

import { fetchAuthIsCustomerNum } from 'modules/CustomerInfo/services/customerInfo.service'

import {
  selectMemberGetMemberContenfulDetails,
  selectMemberGetMemberPromo,
} from 'modules/NewClientSales/store/selectors'
import { resetMemberGetMemberPromo } from 'modules/NewClientSales/store/actions'

const PHONE_NUMBER_TYPE = 'msisdn'

export const useMemberGetMember = (msisdnFieldName, memberGetMemberPoIdFieldName, isModalOpen) => {
  const [isValidatingMsisdn, setIsValidatingMsisdn] = useState(false)
  const [hasMsisdnValidationError, setHasMsisdnValidationError] = useState(false)
  const [hasPromoBeenApplied, setHasPromoBeenApplied] = useState(false)

  const memberGetMemberContenfulDetails = useSelector(selectMemberGetMemberContenfulDetails)
  const { memberGetMemberPoId, isLoading, isAvailable } = useSelector(
    selectMemberGetMemberPromoInfo,
  )

  const basketMemberGetMemberPromo = useSelector(selectMemberGetMemberPromo)
  const hasPromoBeenAddedToBasket =
    !!basketMemberGetMemberPromo?.poId && !!basketMemberGetMemberPromo?.msisdn

  const dispatch = useDispatch()
  const { values, setFieldValue, setStatus, status } = useFormikContext()
  const msisdnValue = getIn(values, msisdnFieldName)

  useEffect(() => {
    if (isModalOpen && !memberGetMemberPoId) {
      dispatch(fetchMemberGetMemberAction())
    }
  }, [isModalOpen])

  const isMsisdnFormatError = useMemo(() => {
    return !!status[msisdnFieldName]
  }, [status])

  const isMsisdnFormatValid = msisdn => mobileNumberRegex.test(msisdn)

  const resetFieldValues = () => {
    setFieldValue(msisdnFieldName, '')
    setFieldValue(memberGetMemberPoIdFieldName, '')
    setStatus({ ...status, [msisdnFieldName]: null })
  }

  const resetMemberGetMemberValues = () => {
    resetFieldValues()

    if (hasPromoBeenAddedToBasket) {
      dispatch(resetMemberGetMemberPromo())
    }

    setIsValidatingMsisdn(false)
    setHasPromoBeenApplied(false)
    setHasMsisdnValidationError(false)
  }

  const validateMsisdnFormat = msisdn => {
    let fieldStatus = null

    const formatIsValid = isMsisdnFormatValid(msisdn)

    if (!formatIsValid) {
      fieldStatus = 'El valor introducido no es correcto'
    }

    setStatus({ ...status, [msisdnFieldName]: fieldStatus })
  }

  const handleMsisdnValidationError = () => {
    setHasMsisdnValidationError(true)
  }

  const handleMsisdnValidationSuccess = () => {
    setFieldValue(memberGetMemberPoIdFieldName, memberGetMemberPoId)
    setHasMsisdnValidationError(false)
    setHasPromoBeenApplied(true)
  }

  const isMsisdnValid = async (phoneNumberType, msisdn) => {
    let isValid = false
    try {
      const fetchCustomerStatusResult = await fetchAuthIsCustomerNum(phoneNumberType, msisdn)
      const customerStatus = fetchCustomerStatusResult.status

      if (customerStatus === 'Active') {
        isValid = true
      }
    } catch (err) {
      logError(new Error(formatErrorMessage(err)))
    }

    return isValid
  }

  const validateMsisdnAndApplyPromo = async () => {
    if (isMsisdnFormatError) {
      return
    }

    setIsValidatingMsisdn(true)

    if (await isMsisdnValid(PHONE_NUMBER_TYPE, msisdnValue)) {
      handleMsisdnValidationSuccess()
    } else {
      handleMsisdnValidationError()
    }

    setIsValidatingMsisdn(false)
  }

  return {
    isValidatingMsisdn,
    validateMsisdnAndApplyPromo,
    validateMsisdnFormat,
    isMsisdnFormatError,
    memberGetMemberContenfulDetails,
    resetMemberGetMemberValues,
    msisdnValue,
    memberGetMemberPoId,
    isLoadingMemberGetMemberInfo: isLoading,
    isPromoAvailable: isAvailable,
    hasMsisdnValidationError,
    hasPromoBeenApplied,
    hasPromoBeenAddedToBasket,
  }
}
