import { createSelector } from 'reselect'
import { get } from 'lodash'
import { getURLSearchParams, getQueryParams } from 'modules/url'
import { CONVERGENT, FIXED_MOBILE, ONLY_MOBILE, ONLY_FIXED } from 'modules/SharedSales/constants'

export function selectRouter(state) {
  return get(state, 'router')
}

export const selectRouterSearch = createSelector(
  selectRouter,
  router =>
    get(router, 'location.search', '')
      .substr(1)
      .replace(/\?|%3F[a-zA-Z]*&/g, '&') || '',
)

// TODO: deprecated try to use URLSearch version
export const selectQueryParams = createSelector(selectRouterSearch, getQueryParams)

export const selectURLSearchParamsIterator = createSelector(selectRouterSearch, getURLSearchParams)

export const selectURLSearchParams = createSelector(selectURLSearchParamsIterator, paramsIterator =>
  Array.from(paramsIterator).map(([name, value]) => ({
    name,
    value,
  })),
)

export function selectQueryParam(paramName, defaultValue = '') {
  return createSelector(
    selectURLSearchParamsIterator,
    params => params.get(paramName) || defaultValue,
  )
}

export const selectSFIDQueryParam = selectQueryParam('sfid')

export const selectBrandQueryParam = selectQueryParam('brand')

export const selectChannelQueryParam = selectQueryParam('channel')

export const selectResellerQueryParam = selectQueryParam('reseller')

export const selectIsCarteraQueryParam = selectQueryParam('isCartera', false)

export const selectIsSubscriptionQueryParam = selectQueryParam('isSubscription', false)

export const selectIsCrossSellQueryParam = selectQueryParam('isCross', false)

export const selectIsPresaleQueryParam = selectQueryParam('isPresale', false)

export const selectIsMultiSimQueryParam = selectQueryParam('isMultiSim', false)

export const selectDocumentIdQueryParam = selectQueryParam('documentId')
export const selectDocumentTypeQueryParam = selectQueryParam('documentType')

export const selectSubscriptionTariffSegmentQueryParam = selectQueryParam('tariffSegment')

export const selectSaleQueryParam = selectQueryParam('sale', null)

export const selectIsMobileOnly = createSelector(
  selectQueryParams,
  params => params.sale === ONLY_MOBILE,
)

export const selectIsElFijoSaleType = createSelector(
  selectQueryParams,
  params => params.sale === FIXED_MOBILE,
)

export const selectIsConvergent = createSelector(
  selectQueryParams,
  params => params.sale === CONVERGENT,
)

export const selectIs2p = createSelector(selectQueryParams, params => params.sale === ONLY_FIXED)
