import { styled } from '@material-ui/styles'
import { FlowChooserComponent } from './FlowChooser.component'

export const StyledFlowChooserComponent = styled(FlowChooserComponent)({
  width: '100%',

  '& .card-wrapper': {
    width: '100%',
  },
})
