import { useEffect, useLayoutEffect, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import { Box } from '@material-ui/core'
import { SpinnerCenter } from 'components/ui'
import { Card } from 'modules/ui'

import { useFormikContext } from 'formik'

import { fetchContentfulResourceAction } from 'modules/contentful/index'
import {
  fetchCustomerOrderLimit,
  selectCustomerOrderLimit,
  selectCustomerOrderLimitLoading,
} from 'modules/CustomerOrders/store/index'
import { withCheckoutForms } from 'modules/NewClientSales/hocs/index'
import { setBasketData } from 'modules/NewClientSales/store'
import { useRequestIpAddress } from 'modules/Auth/hooks'
import { fetchPrescoringAction } from 'modules/NewClientSales/store/actions/index'
import {
  selectBasketRequestLoading,
  selectSaleInformation,
  selectPrescoringFailed,
} from 'modules/NewClientSales/store/selectors/index'
import { selectCustomerAllowed } from 'modules/CustomerInfo/store/customerInfo.selectors'
import { selectIsCarteraQueryParam } from 'modules/Router/store/index'
import { PRESCORING_DENIEND } from 'modules/SharedSales/constants'
import { AUTONOMO } from 'services/global-constants/index'

import { Lead } from '../../../../NewClientSales/components/ClientInfo'
import { BILLING } from '../../../../NewClientSales/constants/index'
import { useLimitInfo } from '../../../hooks/Lines'
import { BackButton } from '../../Common/BackButton'
import SaveChangesButton from '../../Common/SaveChangesButton'
import { ExtraMobileLines } from '../ExtraMobileLines'
import { MainMobileLine } from '../MainMobileLine'
import { ErrorModal } from '../../Common/index'
import { useSetCustomerInfo } from '../../../hooks/Shared'

function NewLead() {
  const dispatch = useDispatch()
  const isCartera = useSelector(selectIsCarteraQueryParam)
  const prescoringFailed = useSelector(selectPrescoringFailed)

  const { requestIpAddress } = useRequestIpAddress()

  useEffect(() => {
    dispatch(fetchContentfulResourceAction('customerSegments', { brands: 'yoigo' }))
    dispatch(fetchContentfulResourceAction('countries'))
    requestIpAddress()
  }, [])

  return (
    <Card className="p-24">
      <Lead isCartera={isCartera} />
      <ErrorModal hasMsError={prescoringFailed} error={PRESCORING_DENIEND} />
    </Card>
  )
}

export const LeadAndLinesSummary = withCheckoutForms(function LeadAndLinesSummary({
  getNextStepUrlPath,
  isEditing,
}) {
  const dispatch = useDispatch()

  const {
    errors,
    values,
    values: { account, extraMobileLinesOffers },
  } = useFormikContext()

  const isLoading = useSelector(selectBasketRequestLoading)

  const saleInformation = useSelector(selectSaleInformation)

  const isLinesLimitLoading = useSelector(selectCustomerOrderLimitLoading)

  const linesLimit = useSelector(selectCustomerOrderLimit)

  const isCartera = useSelector(selectIsCarteraQueryParam)

  const isCustomerAllowed = useSelector(selectCustomerAllowed)

  useSetCustomerInfo(!!isCartera)

  const { additionalLinesLength } = useLimitInfo()

  const buttonRef = useRef(null)

  const isLeadFilled = useMemo(() => !!account.documentid && !errors.account, [errors, account])

  const linesLimitReached = useMemo(
    () => linesLimit && linesLimit - 1 < additionalLinesLength,
    [linesLimit, additionalLinesLength],
  )

  useLayoutEffect(() => {
    if (!isLinesLimitLoading && buttonRef.current) {
      buttonRef.current.scrollIntoView()
    }
  }, [isLinesLimitLoading])

  function getOrdersLimit() {
    dispatch(setBasketData(values))
    dispatch(
      fetchCustomerOrderLimit({
        ...saleInformation,
        docNumber: values.account.documentid,
        docType: values.account.documenttype,
        selfEmployed: values.account.segment === AUTONOMO,
      }),
    )
  }

  useEffect(() => {
    if (isLeadFilled && extraMobileLinesOffers.length > 0 && !linesLimit) {
      getOrdersLimit()
    }
  }, [isLeadFilled, extraMobileLinesOffers, linesLimit])

  function onContinue() {
    dispatch(
      fetchPrescoringAction({
        nextStep: getNextStepUrlPath(BILLING, isEditing),
      }),
    )
  }

  return (
    <div>
      <NewLead />
      <Box mt={4}>
        <MainMobileLine disableOfferEdit isLeadFilled={isLeadFilled} />
      </Box>
      <ExtraMobileLines
        linesLimit={linesLimit}
        disableOfferEdit
        isLeadFilled={isLeadFilled}
        additionalLinesLength={additionalLinesLength}
        linesLimitReached={linesLimitReached}
      />
      {!!isLinesLimitLoading && <SpinnerCenter size={24} style={{ marginTop: '20px' }} />}
      <Box display="flex" mt={linesLimit ? 0 : 4}>
        <BackButton goTo="offers" nomargin />
        <Box ml={2} ref={buttonRef}>
          <SaveChangesButton
            stepName="SetupDataForm"
            isLoading={isLoading}
            setBasketData={d => dispatch(setBasketData(d))}
            isEditing={isEditing}
            disabled={linesLimitReached || (!isCartera && !isCustomerAllowed)}
            nomargin
            onContinue={onContinue}
          />
        </Box>
      </Box>
    </div>
  )
})

LeadAndLinesSummary.propTypes = {
  getNextStepUrlPath: PropTypes.string,
  isEditing: PropTypes.bool,
}
