const FIND_CUSTOMER_SVA = 'OVID/CUSTOMER360/FIND_CUSTOMER_SVA'
const FIND_CUSTOMER_SVA_SUCCESS = 'OVID/CUSTOMER360/FIND_CUSTOMER_SVA_SUCCESS'
const FIND_CUSTOMER_SVA_FAILED = 'OVID/CUSTOMER360/FIND_CUSTOMER_SVA_FAILED'
const RESET_CUSTOMER_SVA = 'OVID/CUSTOMER360/RESET_CUSTOMER_SVA'

const DISABLE_CUSTOMER_SVA_INIT = 'OVID/CUSTOMER360/DISABLE_CUSTOMER_SVA_INIT'
const DISABLE_CUSTOMER_SVA_SUCCESS = 'OVID/CUSTOMER360/DISABLE_CUSTOMER_SVA_SUCCESS'
const DISABLE_CUSTOMER_SVA_FAILED = 'OVID/CUSTOMER360/DISABLE_CUSTOMER_SVA_FAILED'
const DISABLE_CUSTOMER_SVA_PENDING = 'OVID/CUSTOMER360/DISABLE_CUSTOMER_SVA_PENDING'
const DISABLE_CUSTOMER_SVA_RESET = 'OVID/CUSTOMER360/DISABLE_CUSTOMER_SVA_RESET'

const PUT_HEBE_SVA_CUSTOMER_INIT = 'OVID/CUSTOMER360/PUT_HEBE_SVA_CUSTOMER_INIT'
const PUT_HEBE_SVA_CUSTOMER_SUCCESS = 'OVID/CUSTOMER360/PUT_HEBE_SVA_CUSTOMER_SUCCESS'
const PUT_HEBE_SVA_CUSTOMER_FAILED = 'OVID/CUSTOMER360/PUT_HEBE_SVA_CUSTOMER_FAILED'
const PUT_HEBE_SVA_CUSTOMER_RESET = 'OVID/CUSTOMER360/PUT_HEBE_SVA_CUSTOMER_RESET'

const FETCH_CUSTOMER_SVAS = 'OVID/CUSTOMER360/FETCH_CUSTOMER_SVAS'
const FETCH_CUSTOMER_SVAS_SUCCESS = 'OVID/CUSTOMER360/FETCH_CUSTOMER_SVAS_SUCCESS'
const FETCH_CUSTOMER_SVAS_FAILED = 'OVID/CUSTOMER360/FETCH_CUSTOMER_SVAS_FAILED'

const PURCHASE_ALARM_START = 'OVID/PURCHASE_ALARM_START'
const PURCHASE_ALARM_SUCCESS = 'OVID/PURCHASE_ALARM_SUCCESS'
const PURCHASE_ALARM_FAILED = 'OVID/PURCHASE_ALARM_FAILED'
const RESET_PURCHASE_ALARMS = 'OVID/RESET_PURCHASE_ALARMS'

const PURCHASE_HOMEGO_START = 'OVID/PURCHASE_HOMEGO_START'

const FIND_HOMEGO_SVA = 'OVID/CUSTOMER360/FIND_HOMEGO_SVA'
const FIND_HOMEGO_SVA_SUCCESS = 'OVID/CUSTOMER360/FIND_HOMEGO_SVA_SUCCESS'
const FIND_HOMEGO_SVA_FAILED = 'OVID/CUSTOMER360/FIND_HOMEGO_SVA_FAILED'

const CANCEL_HOMEGO_SVA_ORDER = 'OVID/CUSTOMER360/CANCEL_HOMEGO_SVA_ORDER'
const CANCEL_HOMEGO_SVA_ORDER_SUCCESS = 'OVID/CUSTOMER360/CANCEL_HOMEGO_SVA_ORDER_SUCCESS'
const CANCEL_HOMEGO_SVA_ORDER_FAILED = 'OVID/CUSTOMER360/CANCEL_HOMEGO_SVA_ORDER_FAILED'

const CUSTOMER_SVA_RESPONSE_FIELDS_MAPPING = {
  NOT_INFLUENCER: 'FALSE',
  INFLUENCER: 'INFLUENCER',
  MAINI: 'MAINI',
  HEBE: 'HEBE',
  DOCTOR_GO: 'Doctor GO',
}

const CUSTOMER_SVA_TYPES = {
  DOCTOR_GO: 'Doctor GO',
  HEBE: 'HEBE',
}

const VALID_CUSTOMER_SVAS_TYPES = [CUSTOMER_SVA_TYPES.DOCTOR_GO, CUSTOMER_SVA_TYPES.HEBE]

export const HOMEGO_SVA_SICOR_STATUS = {
  INSTALLED: 'INSTALADA',
  CANCELLED: 'CANCELADA',
}

export const HOMEGO_SVA_CADENCE_STATUS = {
  START: 'START',
  FRAUD: 'FRAUD',
  CRM_ACTIVATION: 'CRM_ACTIVATION',
  SBN_ONBOARDING: 'SBN_ONBOARDING',
  CHECK_ORDER: 'CHECK_ORDER',
  SBN_READY_TO_INSTALL: 'SBN_READY_TO_INSTALL',
  COMMISSION: 'COMMISSION',
  END: 'END',
}

export const HOMEGO_SVA_CADENCE_SUBSTATUS = {
  IN_PROGRESS: 'IN_PROGRESS',
  INCIDENCE: 'INCIDENCE',
  FAILED: 'FAILED',
  CANCELLED: 'CANCELLED',
  CANCELLING: 'CANCELLING',
  COMPLETED: 'COMPLETED',
}

export const HOMEGO_SVA_ERROR_MESSAGES = {
  ALARM_NOT_INSTALLED: 'ALARM_NOT_INSTALLED',
}

export const constants = {
  FIND_CUSTOMER_SVA,
  FIND_CUSTOMER_SVA_SUCCESS,
  FIND_CUSTOMER_SVA_FAILED,
  RESET_CUSTOMER_SVA,
  CUSTOMER_SVA_RESPONSE_FIELDS_MAPPING,
  CUSTOMER_SVA_TYPES,
  VALID_CUSTOMER_SVAS_TYPES,
  DISABLE_CUSTOMER_SVA_INIT,
  DISABLE_CUSTOMER_SVA_SUCCESS,
  DISABLE_CUSTOMER_SVA_FAILED,
  DISABLE_CUSTOMER_SVA_PENDING,
  DISABLE_CUSTOMER_SVA_RESET,
  PUT_HEBE_SVA_CUSTOMER_INIT,
  PUT_HEBE_SVA_CUSTOMER_SUCCESS,
  PUT_HEBE_SVA_CUSTOMER_FAILED,
  PUT_HEBE_SVA_CUSTOMER_RESET,
  FETCH_CUSTOMER_SVAS,
  FETCH_CUSTOMER_SVAS_SUCCESS,
  FETCH_CUSTOMER_SVAS_FAILED,
  PURCHASE_HOMEGO_START,
  PURCHASE_ALARM_START,
  PURCHASE_ALARM_SUCCESS,
  PURCHASE_ALARM_FAILED,
  RESET_PURCHASE_ALARMS,
  FIND_HOMEGO_SVA,
  FIND_HOMEGO_SVA_SUCCESS,
  FIND_HOMEGO_SVA_FAILED,
  CANCEL_HOMEGO_SVA_ORDER,
  CANCEL_HOMEGO_SVA_ORDER_SUCCESS,
  CANCEL_HOMEGO_SVA_ORDER_FAILED,
}
