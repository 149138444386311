import { APP_CONFIG } from 'services/app-config'
import { axiosJWT } from 'modules/axios'

const provisioningURL = `${APP_CONFIG.sales_cluster}/v1/phone-numberings`
const brand = `YOIGO`

export function findLandlineProvisioningNumber(postalCode) {
  return axiosJWT({
    method: 'GET',
    url: `${provisioningURL}/landline`,
    params: {
      postalCode,
      brand,
    },
  }).then(resp => resp.data.number)
}

export function findMobileProvisioningNumber(match) {
  return axiosJWT({
    method: 'GET',
    url: `${provisioningURL}/msisdns/free`,
    params: {
      match,
    },
  }).then(res => res.data)
}
