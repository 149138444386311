import { put, takeLatest, call } from 'redux-saga/effects'
import { axiosFactory } from 'modules/axios'

import { logError, formatErrorMessage } from 'services/logging'
import { presaleActionTypes, presaleActions } from '../actions'
import { fetchPresale, savePresale } from '../../services'

export function* savePresaleDataSaga({ payload: { presaleData } }) {
  try {
    const savedPresaleData = yield call(savePresale, presaleData)
    if (!savedPresaleData.id) {
      throw new Error('Empty id returned')
    }

    yield put(presaleActions.savePresaleDataSuccess(savedPresaleData))
  } catch (e) {
    yield call(logError, { e: new Error(formatErrorMessage(e)), presaleData })
    const error = axiosFactory.buildError(e)
    yield put(presaleActions.savePresaleDataError(error))
  }
}

export function* watchSavePresaleDataSaga() {
  yield takeLatest(presaleActionTypes.SAVE_PRESALE_DATA, savePresaleDataSaga)
}

export function* fetchPresaleDataSaga({ payload: { presaleId } }) {
  try {
    const presaleData = yield call(fetchPresale, presaleId)
    yield put(presaleActions.fetchPresaleDataSuccess({ presaleId, ...presaleData }))
  } catch (e) {
    yield call(logError, { e: new Error(formatErrorMessage(e)), presaleId })
    const error = axiosFactory.buildError(e)
    yield put(presaleActions.fetchPresaleDataError(error))
  }
}

export function* watchFetchPresaleDataSaga() {
  yield takeLatest(presaleActionTypes.FETCH_PRESALE_DATA, fetchPresaleDataSaga)
}
