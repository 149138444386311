import PropTypes from 'prop-types'
import { get, isUndefined } from 'lodash'
import { makeStyles } from '@material-ui/styles'

import { Badge } from 'modules/ui/components/Badge'
import { getDarken } from 'utils'
import {
  getBorderFromStatusType,
  getColorFromStatusType,
  statusColors,
} from './BadgeStatusDetail.helpers'

const useStyles = makeStyles({
  'badge-status-detail': {
    '& .first-badge': {
      height: '24px',
      margin: 0,
      padding: '0 15px',
      textTransform: 'uppercase',
      borderTopRightRadius: '1px',
      borderBottomRightRadius: '1px',
    },

    '& .second-badge': {
      height: '24px',
      margin: '0 0 -1px 0',
      padding: '0 15px 0 20px',
      borderTopRightRadius: '2px',
      borderBottomRightRadius: '2px',
      backgroundColor: ({ superStatus }) => getDarken(getColorFromStatusType(superStatus), 0.2),
      boxShadow: 'inset 16px 0 16px -16px rgba(0, 0, 0, 0.5)',
    },

    '& .third-badge': {
      backgroundColor: '#FFF',
      border: getBorderFromStatusType('ot'),
      color: getColorFromStatusType('ot'),
      marginLeft: '10px',
      padding: '0 15px',
    },

    '& .triangle': {
      height: 0,
      width: 0,
      borderTop: '12px solid transparent',
      borderBottom: '12px solid transparent',
      borderLeft: ({ superStatus }) => getBorderFromStatusType(superStatus),
      zIndex: 0,
      marginRight: '-9px',
    },
  },
})
export const BadgeStatusDetail = ({
  className,
  superStatus,
  status,
  subStatus,
  statusOt,
  categoryOt,
  icon,
}) => {
  const classes = useStyles({ superStatus })

  return (
    <>
      {status && (
        <div
          className={[
            'flex',
            classes['badge-status-detail'],
            ...(className ? [className] : []),
          ].join(' ')}>
          <Badge
            strong
            lg
            className="first-badge"
            type={get(statusColors, superStatus, statusColors.unknown)}
            value={{
              label: `${get(status, 'code') ? `(${get(status, 'code')})` : ''} ${get(
                status,
                'description',
              )}`,
              icon,
            }}
            iconRight
          />
          <>
            {subStatus ? (
              <>
                <div className="triangle" />
                <Badge
                  strong
                  lg
                  className="second-badge"
                  type={get(statusColors, superStatus, statusColors.unknown)}
                  value={{
                    label: `${get(subStatus, 'code') ? `(${get(subStatus, 'code')})` : ''} ${get(
                      subStatus,
                      'description',
                      '',
                    )}`,
                  }}
                />
              </>
            ) : (
              ''
            )}
          </>
          <>
            {statusOt && (
              <Badge
                strong
                lg
                className="third-badge"
                type={get(statusColors, statusOt, statusColors.unknown)}
                value={{
                  label: `${statusOt} ${!isUndefined(categoryOt) ? ` - ${categoryOt}` : ''}`,
                }}
              />
            )}
          </>
        </div>
      )}
    </>
  )
}

BadgeStatusDetail.propTypes = {
  className: PropTypes.string,
  superStatus: PropTypes.string,
  status: PropTypes.object,
  subStatus: PropTypes.object,
  statusOt: PropTypes.string,
  categoryOt: PropTypes.string,
  icon: PropTypes.string,
}
