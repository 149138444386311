import { Component } from 'react'
import { PropTypes } from 'prop-types'
import { isEmpty } from 'lodash'

import { getOrderByType } from 'services/order-on-fly'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 10vh;
`

class ClientValidation extends Component {
  setStep = () => {
    setTimeout(() => {
      this.props.setStep()
    }, 1000)
  }

  render() {
    const {
      loading,
      existCoverage,
      coverageLoading,
      checkOrderCTOnFly,
      tariffsError,
      tokenCoverage,
      orderError,
    } = this.props

    const onFly = getOrderByType(this.props.orderOnFly)
    if (!loading) {
      if (tariffsError) {
        return (
          <Typography variant="h6">No se pudieron cargar las tarifas para este cliente.</Typography>
        )
      }
      if (orderError) {
        return (
          <Typography variant="h6">
            Se ha producido un error con la carga de datos. Inténtelo de nuevo.
          </Typography>
        )
      }
      if (onFly.change) {
        return (
          <Typography variant="h6">
            El cliente tiene una orden abierta de cambio de tecnología.
          </Typography>
        )
      }
      if (onFly.move) {
        return (
          <Typography variant="h6">
            El cliente tiene una orden abierta de cambio de dirección.
          </Typography>
        )
      }
      if (checkOrderCTOnFly) {
        return <Typography variant="h6">El cliente tiene un cambio de titular abierto.</Typography>
      }
      if (!existCoverage && coverageLoading === false) {
        return (
          <Wrapper>
            <Typography variant="h6">
              Servicio de Fibra no está disponible en esta dirección.
            </Typography>
            <div style={{ textAlign: 'center' }}>
              <Button
                variant="contained"
                color="primary"
                data-hook="Editar"
                onClick={() => this.props.goEditAddressFn()}>
                Editar dirección
              </Button>
            </div>
          </Wrapper>
        )
      }

      // TODO refactor
      if (loading === false && !isEmpty(tokenCoverage) && checkOrderCTOnFly === false) {
        this.setStep()
      } else {
        return <Typography variant="h6">Datos del cliente comprobado con éxito.</Typography>
      }
    }
    return <CircularProgress size={100} />
  }
}

ClientValidation.propTypes = {
  setStep: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  existCoverage: PropTypes.bool,
  coverageLoading: PropTypes.bool,
  checkOrderCTOnFly: PropTypes.bool,
  tariffsError: PropTypes.bool,
  tokenCoverage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  orderError: PropTypes.bool,
  orderOnFly: PropTypes.object,
  goEditAddressFn: PropTypes.func,
}

export default ClientValidation
