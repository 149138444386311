import { createStructuredSelector, createSelector } from 'reselect'
import {
  canEnableSomeSellOptionWithClient,
  selectClientPackages,
  getSegment,
} from 'services/customer-360/selectors'
import { selectDocumentId, selectDocumentType } from 'modules/Auth'
import { selectChannelQueryParam } from 'modules/Router/store/index'
import { selectAddAgileTvFlag } from 'services/feature-flag/selectors'
import { selectIsPosOrMasProximo } from 'modules/Permissions'

const selectClientChildrenPackages = createSelector(
  [selectClientPackages],
  packages => packages.filter(p => p.parentOrderId),
)

export const selectSubscriptionCardContainer = createStructuredSelector({
  canEnableSellOption: canEnableSomeSellOptionWithClient,
  documentType: selectDocumentType,
  documentId: selectDocumentId,
  segment: getSegment,
  channel: selectChannelQueryParam,
  clientChildrenPackages: selectClientChildrenPackages,
  canAddAgileTv: selectAddAgileTvFlag,
  isPosOrMasProximo: selectIsPosOrMasProximo,
})
