import { APP_CONFIG } from 'services/app-config'
import { axiosJWT } from 'modules/axios'

const url = `${APP_CONFIG.sales_cluster}/v1/campaigns`

export function fetchCampaign(campaignId, psId) {
   return axiosJWT({
    method: 'GET',
    url: `${url}/${campaignId}?psId=${psId}`,
  })
    .then(resp => { 
      const campaigns = resp.data.discounts.map(discount => {
          return {...discount, isCampaign: true}
      })
      return {...resp.data, discounts: campaigns}
    })
    .catch(() => null) 
}

export function fetchMemberGetMember() {
  return axiosJWT({
    method: 'GET',
    url: `${url}/memberGetMember`,
  }).then(resp => resp.data)
}
