import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { Card, CardContent, Box, Typography, Divider } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import { ActionAdvice, NotificationModal, Button, OutlinedDivider } from 'components/ui'

import SearchIcon from '@material-ui/icons/Search'
import { DeviceCard } from '../../../components/DeviceCard'
import { DeviceOffersFilters } from '../../../components/DeviceOffersFilters'
import { CurrentFinancedDeviceInfo } from '../../../components/CurrentFinancedDeviceInfo'
import { SortableMenu } from '../../../components/SortableMenu'
import { AgileTvInfo } from '../../../components/AgileTvInfo'
import { SORTABLE_OPTIONS, DEFAULT_SORT } from '../../../hooks/useDeviceOffers'

const useStyles = makeStyles({
  'section-header': {
    '& h6': {
      fontWeight: 'bold',
      marginRight: '16px',
    },

    '& span': {
      fontSize: '14px',
    },
  },
  'search-icon': {
    margin: '0px auto 24px auto',
    '& svg': {
      fontSize: '3em',
    },
  },
})

export const DeviceOffers = ({
  deviceType,
  brandOptions,
  screenSizeOptions,
  capacityOptions,
  modelOptions,
  filteredDevices,
  fetchDevicesError,
  fetchAgileTvError,
  agileTvData,
  deviceSelected,
  onSelectDevice,
  resetDevicesCatalog,
  currentFinancedTerminalInfo,
  reservationError,
  showMoreDevices,
  canShowMoreDevices,
  totalDevicesCount,
  categoryOptions,
  devicesStock,
  retryFetchDeviceStock,
  isAgileTvRenewal,
  orderFields,
  applyOrder,
}) => {
  const classes = useStyles()

  const [showReservationErrorModal, setShowReservationErrorModal] = useState(false)

  useEffect(() => {
    if (reservationError) {
      setShowReservationErrorModal(true)
    }
  }, [reservationError])

  return (
    <>
      {(!!currentFinancedTerminalInfo?.fee || !!currentFinancedTerminalInfo?.penalty) && (
        <CurrentFinancedDeviceInfo deviceInfo={currentFinancedTerminalInfo} />
      )}

      <Card style={{ width: '100%' }}>
        <Box p="20px 24px 18px">
          <Typography variant="h5" style={{ fontWeight: 'bold' }}>
            Nuevo {deviceType}
          </Typography>
        </Box>

        <Divider />

        {!isAgileTvRenewal && (
          <DeviceOffersFilters
            brandOptions={brandOptions}
            modelOptions={modelOptions}
            screenSizeOptions={screenSizeOptions}
            capacityOptions={capacityOptions}
            categoryOptions={categoryOptions}
            classes={classes}
            showTerminalSpecsFilters={deviceType === 'terminal'}
            showCategoryFilter={deviceType === 'dispositivo'}
          />
        )}

        <CardContent>
          {!isAgileTvRenewal && (
            <Box display="flex" alignItems="center" mb="16px" className={classes['section-header']}>
              <Typography component="h6" variant="body1">
                PRODUCTOS
              </Typography>
              <Typography component="span" variant="body1">
                Mostrando{' '}
                {`${filteredDevices.length} de ${totalDevicesCount} resultado${
                  totalDevicesCount.length !== 1 ? 's' : ''
                }`}
              </Typography>

              {!fetchDevicesError && (
                <Box ml="auto" display="flex" alignItems="center">
                  <Typography variant="body1" component="span" color="textSecondary">
                    Ordenado por {orderFields.map(option => option.name).join(', ')}
                  </Typography>

                  <SortableMenu
                    options={SORTABLE_OPTIONS}
                    currentOptions={orderFields}
                    defaultOptions={DEFAULT_SORT}
                    onChange={applyOrder}
                    isApplied
                  />
                </Box>
              )}
            </Box>
          )}

          {(fetchDevicesError || fetchAgileTvError) && (
            <ActionAdvice
              type="error"
              message="Ha habido un error al cargar los productos. Por favor, inténtalo más tarde."
              width="100%"
              onRetry={resetDevicesCatalog}
            />
          )}

          {(!!agileTvData || !isAgileTvRenewal) &&
            filteredDevices.map(device => {
              const isSelected = deviceSelected?.id === device.id
              const deviceStock = devicesStock.find(stock => stock.deviceId === device.id)

              return (
                <DeviceCard
                  dataHook={`device-card-${device.id}`}
                  key={device.id}
                  value={device}
                  selected={isSelected}
                  defaultPaymentMethodSelected={isSelected ? deviceSelected.paymentMethod : null}
                  onSelected={onSelectDevice}
                  stock={deviceStock?.stock}
                  stockHasError={deviceStock && deviceStock.stock === undefined}
                  isLoadingStock={deviceStock === undefined}
                  onRetryFetchStock={retryFetchDeviceStock}
                  noSelectedButton={isAgileTvRenewal}>
                  {isAgileTvRenewal && <AgileTvInfo data={agileTvData} />}
                </DeviceCard>
              )
            })}

          {(!fetchDevicesError && filteredDevices.length === 0) ||
          (isAgileTvRenewal && !fetchAgileTvError && !agileTvData) ? (
            <Box display="flex" alignItems="center" m="50px auto 50px auto" flexDirection="column">
              <Box className={classes['search-icon']}>
                <SearchIcon />
              </Box>

              <Typography variant="body1">
                {isAgileTvRenewal && !fetchAgileTvError && !agileTvData
                  ? 'No hemos encontrado ningún servicio de TV compatible con esta subscripción'
                  : 'No hemos encontrado resultados'}
              </Typography>
            </Box>
          ) : null}

          <NotificationModal
            isOpen={showReservationErrorModal}
            type="alert"
            src="/assets/error.svg"
            onClose={() => {
              setShowReservationErrorModal(false)
            }}>
            <Typography variant="body1">{reservationError}</Typography>
          </NotificationModal>

          {canShowMoreDevices && (
            <>
              <OutlinedDivider />
              <Box display="flex" justifyContent="center" mt="10px">
                <Button
                  data-hook="btn-show-more-devices"
                  secondary
                  onClick={() => showMoreDevices()}>
                  MOSTRAR MÁS DISPOSITIVOS
                </Button>
              </Box>
            </>
          )}
        </CardContent>
      </Card>
    </>
  )
}

DeviceOffers.defaultProps = {
  showMoreDevices: () => {},
}

DeviceOffers.propTypes = {
  deviceType: PropTypes.string,
  devicesStock: PropTypes.array,
  brandOptions: PropTypes.array,
  modelOptions: PropTypes.array,
  screenSizeOptions: PropTypes.array,
  capacityOptions: PropTypes.array,
  categoryOptions: PropTypes.array,
  reservationError: PropTypes.object,
  showMoreDevices: PropTypes.func,
  canShowMoreDevices: PropTypes.bool,
  filteredDevices: PropTypes.array.isRequired,
  fetchDevicesError: PropTypes.object,
  fetchAgileTvError: PropTypes.string,
  agileTvData: PropTypes.object,
  deviceSelected: PropTypes.object,
  onSelectDevice: PropTypes.func.isRequired,
  resetDevicesCatalog: PropTypes.func,
  currentFinancedTerminalInfo: PropTypes.object,
  totalDevicesCount: PropTypes.number,
  retryFetchDeviceStock: PropTypes.func,
  isAgileTvRenewal: PropTypes.bool,
  orderFields: PropTypes.array,
  applyOrder: PropTypes.func.isRequired,
}
