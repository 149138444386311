import { call, put, takeEvery, select } from 'redux-saga/effects'
import { logError, formatErrorMessage } from 'services/logging'
import { axiosFactory } from 'modules/axios'

import { actions } from './actions'
import * as constants from './constants'
import { findTranslation, getTranslatorToken } from './api'
import { getAuthData } from './selectors'

export function* findTranslationSaga() {
  try {
    const iso = 'en'
    const term = 'Hola'

    const data = yield select(getAuthData)
    const token = yield call(getTranslatorToken, data)
    const translate = yield call(findTranslation, iso, term, token)

    yield put(actions.foundTranslation({ data: translate }))
  } catch (e) {
    yield call(logError, new Error(formatErrorMessage(e)))
    const error = axiosFactory.buildError(e)
    yield put(actions.findTranslationFailed(error))
  }
}

export function* watchFindTranslation() {
  yield takeEvery(constants.FIND_TRANSLATION, findTranslationSaga)
}
