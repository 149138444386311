import { APP_CONFIG } from 'services/app-config'
import { axiosJWT } from 'modules/axios'

const BASE_URL_TARIFFS_DISCOUNTS = `${APP_CONFIG.sales_cluster}/v1/tariffs/promotions`

export function fetchAddtionalTariffsWithoutDiscounts(params) {
  return axiosJWT({
    method: 'GET',
    url: `${APP_CONFIG.sales_cluster}/v1/tariffs/additionalLinesWithoutDiscount`,
    params,
  }).then(resp => resp.data)
}

export function fetchAdditionalTariffs(params) {
  if (!params.mainTariffPsId) {
    return []
  }
  return axiosJWT({
    method: 'GET',
    url: `${APP_CONFIG.sales_cluster}/v1/tariffs/additionalLines`,
    params,
  }).then(resp => resp.data)
}

export function fetchTariffs(params) {
  return axiosJWT({
    method: 'GET',
    url: `${APP_CONFIG.sales_cluster}/v1/tariffs/tariffs`,
    params,
  }).then(resp => resp.data) 
}

export function fetchCompetitorsTariffs(params) {
  return axiosJWT({
    method: 'GET',
    url: `${APP_CONFIG.sales_cluster}/v1/competitors/promotions?`,
    params,
  })
}

export function fetchTariffPromotions(segment, poId) {
  return axiosJWT({
    method: 'GET',
    url: BASE_URL_TARIFFS_DISCOUNTS,
    params: {
      segment,
      poId,
    },
  }).then(resp => resp.data)
}
