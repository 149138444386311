import { createStructuredSelector, createSelector } from 'reselect'
import {
  selectCustomerData,
  selectSegment,
  selectData,
  selectContracts,
  selectHistoricOrders,
  selectSubscriptionId,
} from 'services/customer-360/selectors'
import { helpers } from 'services/customer-360/helpers'
import {
  selectIsSubscriptionQueryParam,
  selectIsCarteraQueryParam,
  selectIsCrossSellQueryParam,
} from 'modules/Router/store/index'

import {
  getCharacteristicsCustomerFromOrder,
  getPaymentMeanFromOrder,
  getNationalityCustomer,
  getEmailFromOrdersCustomer,
} from 'modules/orders'

import { get, head, chain, isEqual } from 'lodash'
import { AUTONOMO, CONSUMER } from 'services/global-constants/segments'

import { format } from 'date-fns'
import {
  selectIsCustomerCanceled,
  selectBillingId,
  selectAccountId,
} from 'modules/CustomerInfo/store'

import {
  selectBillingCarteraAddress,
  selectPhoneNumbers,
} from 'modules/NewClientSales/store/selectors/NewClientSales.selectors'
import {
  selectServiceAddress,
  selectDocumentId,
  selectAccountSegment,
} from 'modules/NewClientSales/store/selectors/Basket.selectors'
import { selectCoverageData } from 'modules/Coverage/store/index'
import { selectBillingAddress } from '../../../store/selectors/billing.selectors'

export const selectSaleSegment = createSelector(
  selectSegment,
  segment => {
    if (!segment) return undefined
    return segment.includes('Pro') ? 'pro' : 'residential'
  },
)

const selectAllCustomerData = createSelector(
  selectCustomerData,
  selectAccountSegment,
  selectAccountId,
  getCharacteristicsCustomerFromOrder,
  getNationalityCustomer,
  getEmailFromOrdersCustomer,
  selectPhoneNumbers,
  selectIsCustomerCanceled,
  selectContracts,
  selectHistoricOrders,
  selectSaleSegment,
  (
    data,
    accountSegment,
    accountId,
    orderCharacteristics,
    nationality,
    emailFromOrders,
    { mainNumber, secondNumber },
    isCanceled,
    contracts,
    historicOrders,
    saleSegment,
  ) => {
    const birthDay = helpers.getCharacteristic(orderCharacteristics, 'birthday')
    const birthdayInFlight = birthDay ? format(new Date(birthDay), 'dd/MM/yyyy') : ''

    const lines = chain(contracts)
      .flatMap(contract => contract.packages)
      .flatMap(pack => pack.products)
      .flatMap(product => {
        const { description, category, tariffId } = product

        return chain(product.lines)
          .flatMap(line => ({ ...line, description, category, tariffId }))
          .value()
      })
      .value()

    let segment = accountSegment || data.segment
    if (saleSegment && segment === CONSUMER && saleSegment === 'pro') {
      segment = AUTONOMO
    }

    return {
      ...data,
      segment,
      accountId,
      nationality: data.nationality || nationality || 'ES',
      birthday: data.birthday || birthdayInFlight,
      email: data.email || emailFromOrders?.medium?.emailAddress,
      phoneContact: isCanceled ? '' : (secondNumber || data.phoneContact || '').toString(),
      phoneContact2: isCanceled ? '' : (mainNumber || data.phoneContact2 || '').toString(),
      lines,
      historicOrders,
    }
  },
)

const selectCustomerIban = createSelector(
  selectData,
  getPaymentMeanFromOrder,
  selectIsCustomerCanceled,
  ({ dataClient }, paymentMean, isCanceled) => {
    return isCanceled
      ? ''
      : helpers.getCharacteristic(dataClient.characteristic, 'iban') ||
          get(head(paymentMean), 'bankAccount.iban', '')
  },
)

function getIsValidAddress(address) {
  return (
    !!(address.zipCode || address.postal_code) &&
    !!(address.city || address.town) &&
    !!(address.province || address.state_or_province) &&
    !!(address.building || address.number)
  )
}

export const selectHasChangedAndFilledAddress = createSelector(
  selectBillingCarteraAddress,
  selectBillingAddress,
  selectCoverageData,
  selectServiceAddress,
  (carteraAddress, { formattedAddress, ...postalAddress }, coverage, service) => {
    if (!isEqual(carteraAddress, postalAddress) && !postalAddress.editAddress) {
      return (
        getIsValidAddress(postalAddress) ||
        getIsValidAddress(coverage) ||
        getIsValidAddress(service.otherAddress)
      )
    }
    return false
  },
)

export const storeSelectors = createStructuredSelector({
  customerData: selectAllCustomerData,
  customerAddress: selectBillingCarteraAddress,
  customerIban: selectCustomerIban,
  isSubscription: selectIsSubscriptionQueryParam,
  isCrossSell: selectIsCrossSellQueryParam,
  segment: selectSaleSegment,
  accountId: selectAccountId,
  billingAccountId: selectBillingId,
  isCartera: selectIsCarteraQueryParam,
  documentId: selectDocumentId,
  subscriptionId: selectSubscriptionId,
})
