import { get } from 'lodash'
import { createSelector } from 'reselect'
import {
  selectIsCarteraQueryParam,
  selectIsCrossSellQueryParam,
  selectIsSubscriptionQueryParam,
  selectIsMultiSimQueryParam,
} from 'modules/Router/store/index'

export const getIBANData = state => get(state, 'iban')

export const selectIBAN = createSelector(
  getIBANData,
  data => get(data, 'iban', {}),
)

export const selectIBANError = createSelector(
  getIBANData,
  data => get(data, 'error', 'NO ERROR'),
)

export const selectIBANIsLoading = createSelector(
  getIBANData,
  data => get(data, 'isLoading'),
)

export const selectIBANErrorCode = createSelector(
  selectIBANError,
  error => get(error, 'code', ''),
)

export const selectIBANErrorMessage = createSelector(
  selectIBANError,
  error => get(error, 'message'),
)

export const selectExistingIbanIsBeingEdited = createSelector(
  getIBANData,
  data => get(data, 'existingIbanIsBeingEdited', false),
)

export const selectShouldMaskIban = createSelector(
  selectIsCarteraQueryParam,
  selectIsCrossSellQueryParam,
  selectIsSubscriptionQueryParam,
  selectExistingIbanIsBeingEdited,
  selectIsMultiSimQueryParam,
  (isCartera, isCrossSell, isSubscription, existingIbanIsBeingEdited, isMultiSim) => {
    const isExistingClient = isCartera || isCrossSell || isSubscription || isMultiSim

    return isExistingClient && !existingIbanIsBeingEdited
  },
)
