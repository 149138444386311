import { useState, useMemo } from 'react'
import PropTypes from 'prop-types'

import { useSelector, useDispatch } from 'react-redux'

import { actions as customer360Actions } from 'services/customer-360'

import {
  RESIDENTIAL_SEGMENT,
  SELF_EMPLOYED_SEGMENT,
  VISTA_CHANNEL,
  PORTFOLIO_CONSENT,
} from 'modules/mas-consents/constants'
import { CARTERA_SEGMENTS } from 'services/global-constants'

import { ClientMain } from '../../components/ClientMain/ClientMain'

import {
  formatDataOwner,
  formatDataClientArea,
  formatDataClientAddresses,
} from '../../components/OrderMain/helpers'

import { formatDataContact } from './helpers'
import { clientMainContainerSelectors } from './ClientMainContainer.selectors'

export const ClientMainContainer = ({ className }) => {
  const dispatch = useDispatch()

  const [isModifyPhoneNumberModalOpen, setIsModifyPhoneNumberModalOpen] = useState(false)

  const {
    dataCardClients,
    isLoading,
    hasErrors,
    enableChangeOwner,
    canModifyContactPhoneNumbers,
    gdprConsentsAllowed,
    subscriptionId,
    verbalId,
  } = useSelector(clientMainContainerSelectors)

  const clientData = useMemo(
    () => ({
      ownerData: formatDataOwner(dataCardClients),
      contactData: {
        ...formatDataContact(dataCardClients),
        ...formatDataClientArea(dataCardClients),
      },
      clientAddressData: formatDataClientAddresses(dataCardClients),
    }),
    [dataCardClients],
  )

  const refreshCustomer360Info = () => {
    dispatch(customer360Actions.findInit())
  }

  const contactMenuActions = [
    {
      id: 'modify-contact-phone',
      label: 'Modificar teléfono de contacto',
      onClick: () => {
        setIsModifyPhoneNumberModalOpen(true)
      },
      disabled: !canModifyContactPhoneNumbers,
    },
  ]

  const gdprData = {
    subscriptionId,
    verbalId,
    customerDocument: clientData.ownerData.identification?.identificationId,
    segment:
      clientData.ownerData.segmentCode === CARTERA_SEGMENTS.CONSUMER
        ? RESIDENTIAL_SEGMENT
        : SELF_EMPLOYED_SEGMENT,
    lang: clientData.ownerData.languageCode,
    channel: VISTA_CHANNEL,
    consentType: PORTFOLIO_CONSENT,
  }

  const props = {
    className,
    isLoading,
    hasErrors,
    enableChangeOwner,
    contactMenuActions,
    isModifyPhoneNumberModalOpen,
    setIsModifyPhoneNumberModalOpen,
    onModifyPhoneNumberSuccess: refreshCustomer360Info,
    gdprData,
    gdprConsentsAllowed,
    ...clientData,
  }

  return <ClientMain {...props} />
}

ClientMainContainer.defaultProps = {
  className: '',
}

ClientMainContainer.propTypes = {
  className: PropTypes.string,
}
