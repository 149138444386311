import PropTypes from 'prop-types'

import { Typography } from '@material-ui/core'
import { TextStyles } from 'utils/text'

import { formatCensuredNif } from 'modules/vista-360/helpers/formats.helper'

export function CompanyDataSummary({
  companyName,
  companyCif,
  constitutionDate,
  isPosOrMasProximo,
}) {
  return (
    <div className="p-t-24">
      <Typography
        {...TextStyles.subtitle1Secondary({
          className: 'm-b-29',
        })}>
        DATOS DE LA EMPRESA
      </Typography>
      <div className="flex">
        <div className="flex align-start-center m-b-4 m-r-28">
          <Typography
            {...TextStyles.paragraphDark({
              className: 'p-r-4',
            })}>
            Nombre de la empresa:
          </Typography>
          <Typography {...TextStyles.subtitle1Dark()}>
            <span data-hook="account.companyName">{companyName}</span>
          </Typography>
        </div>
      </div>
      <div className="flex m-t-8">
        <div className="flex align-start-center m-b-4 m-r-28">
          <Typography
            {...TextStyles.paragraphDark({
              className: 'p-r-4',
            })}>
            CIF:
          </Typography>
          <Typography {...TextStyles.subtitle1Dark()}>
            <span data-hook="account.companyCif">
              {isPosOrMasProximo ? formatCensuredNif(companyCif) : companyCif}
            </span>
          </Typography>
        </div>

        <div className="flex align-start-center m-b-4  m-r-28">
          <Typography
            {...TextStyles.paragraphDark({
              className: 'p-r-4',
            })}>
            Fecha de fundación de la empresa:
          </Typography>
          <Typography {...TextStyles.subtitle1Dark()}>
            <span data-hook="account.constitutionDate">{constitutionDate}</span>
          </Typography>
        </div>
      </div>
    </div>
  )
}

CompanyDataSummary.propTypes = {
  companyName: PropTypes.string.isRequired,
  companyCif: PropTypes.string.isRequired,
  constitutionDate: PropTypes.string.isRequired,
  isPosOrMasProximo: PropTypes.bool,
}
