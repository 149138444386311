import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { Popper, Fade, Paper, Typography, Icon } from '@material-ui/core'
import { TextStyles } from 'utils/text'
import { ICONS } from 'utils/icons'

import { Advice } from 'components/ui'

function LineSummary({
  line: { error, name, phone, id, documentId, documentType, shouldStartOpen },
  className,
}) {
  const [open, setOpen] = useState(false)
  const anchor = document.getElementById(`icon-${phone}`)

  function handlePopperOpen() {
    setOpen(!open)
  }

  useEffect(() => {
    if (shouldStartOpen) {
      handlePopperOpen({ currentTarget: anchor })
    }
  }, [])

  return (
    <div className={`${className} flex align-space-between-center full-width`}>
      <div className="flex-equal-size p-r-24">
        {id ? (
          <Typography {...TextStyles.subtitle1Dark()}>Nº Pedido: {id}</Typography>
        ) : (
          <Typography {...TextStyles.subtitle1Dark()}>Error en el pedido</Typography>
        )}
      </div>
      <div className="flex flex-equal-size p-r-24 align-start-center">
        <Typography
          {...TextStyles.paragraphDark({
            className: 'p-r-4',
          })}>
          Número:
        </Typography>
        <Typography {...TextStyles.subtitle1Dark()}>{phone}</Typography>
      </div>
      <div className="flex flex-equal-size p-r-24 align-start-center">
        <Typography
          {...TextStyles.paragraphDark({
            className: 'p-r-4',
          })}>
          {documentType}:
        </Typography>
        <Typography {...TextStyles.subtitle1Dark()}>{documentId}</Typography>
      </div>
      {id && (
        <div className="flex-equal-size">
          <Typography {...TextStyles.subtitle1Dark()}>{name}</Typography>
        </div>
      )}
      {!id && (
        <div className="flex flex-equal-size align-start-center">
          <Typography {...TextStyles.subtitle1Dark()}>{name}</Typography>
          <Icon
            id={`icon-${phone}`}
            className="icon"
            aria-describedby="simple-popper"
            color="secondary"
            style={{ fontSize: '16px' }}
            onClick={handlePopperOpen}>
            {ICONS.info}
          </Icon>

          <Popper
            className="popper"
            placement="bottom-start"
            id="simple-popper"
            open={open}
            anchorPosition="left"
            anchorEl={anchor}
            transition>
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={350}>
                <Paper style={{ padding: '8px', maxWidth: '400px' }}>
                  <p>{error.message}</p>
                  {error.error && <p>{error.error}</p>}
                </Paper>
              </Fade>
            )}
          </Popper>
        </div>
      )}
    </div>
  )
}
LineSummary.propTypes = {
  line: PropTypes.shape({
    error: PropTypes.shape({
      message: PropTypes.string,
      error: PropTypes.string,
    }),
    name: PropTypes.string,
    phone: PropTypes.string,
    id: PropTypes.string,
    documentId: PropTypes.string,
    documentType: PropTypes.string,
    shouldStartOpen: PropTypes.bool,
  }),
  className: PropTypes.string,
}

function LineError({ line }) {
  return (
    <Advice type="error" block>
      <LineSummary line={line} />
    </Advice>
  )
}
LineError.propTypes = {
  line: PropTypes.object,
}

export function LinesOrderSummary({ errorCheckout, className }) {
  return errorCheckout.map(line => (
    <div key={line.key} className={className}>
      <div className="row">
        {line.id ? (
          <Advice type="info" block>
            <LineSummary line={line} className={className} />
          </Advice>
        ) : (
          <LineError line={line} className={className} />
        )}
      </div>
    </div>
  ))
}

LinesOrderSummary.proptypes = {
  errorCheckout: PropTypes.array.isRequired,
  className: PropTypes.string,
}
