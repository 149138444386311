import { useState } from 'react'
import PropTypes from 'prop-types'

import { Typography, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Button, ButtonLink, FormikInput, Modal, ModalActions, SpinnerButton } from 'components/ui'

const MEMBER_GET_MEMBER_INITIAL_INFO = (
  <>
    Si tiene un amigo en Yoigo, dando su número de teléfono tendrán cada uno un descuento en su
    factura de <strong>5€/mes IVA inc. durante 3 meses</strong>
  </>
)

const useStyles = makeStyles({
  msisdnInput: {
    maxWidth: '200px',
  },
})

const ContenfulInfoController = ({ showFullText, handleShowMoreClick, contentfulText }) => (
  <>
    <Typography variant="body1">{MEMBER_GET_MEMBER_INITIAL_INFO}</Typography>

    {showFullText && (
      <Typography variant="body1" className="p-r-36 p-t-24">
        {contentfulText || 'no information available'}
      </Typography>
    )}
    <Box pt="16px">
      <ButtonLink data-hook="mgm-contentful-btn" onClick={handleShowMoreClick}>
        {!showFullText ? 'Más información' : 'Ocultar'}
      </ButtonLink>
    </Box>
  </>
)

ContenfulInfoController.propTypes = {
  showFullText: PropTypes.bool,
  handleShowMoreClick: PropTypes.func.isRequired,
  contentfulText: PropTypes.string,
}

export const MemberGetMemberPromoModal = ({
  isOpen,
  onClose,
  msisdnFieldName,
  isValidatingMsisdn,
  validateMsisdnAndApplyPromo,
  validateMsisdnFormat,
  isMsisdnFormatError,
  isLoadingMemberGetMemberInfo,
  msisdnValue,
  memberGetMemberContenfulDetails,
  resetMemberGetMemberValues,
}) => {
  const canValidateMsisdn = !!msisdnValue && !isMsisdnFormatError && !isValidatingMsisdn
  const [showContenfulText, setShowContenfulText] = useState(false)

  const classes = useStyles()

  const handleCloseModal = () => {
    resetMemberGetMemberValues()
    onClose()
  }

  return (
    <>
      <Modal isOpen={isOpen && !isLoadingMemberGetMemberInfo} title="Promoción - Trae a un amigo">
        <ContenfulInfoController
          showFullText={showContenfulText}
          handleShowMoreClick={() => setShowContenfulText(!showContenfulText)}
          contentfulText={memberGetMemberContenfulDetails}
        />

        <Box display="flex" flexWrap="wrap" className="align-start p-t-32 p-b-8">
          <Typography variant="body2" className="p-r-36 p-t-28">
            Teléfono de su amigo
          </Typography>
          <FormikInput
            className={classes.msisdnInput}
            label="MSISDN"
            name={msisdnFieldName}
            maxLength="9"
            fullWidth
            onChange={value => validateMsisdnFormat(value)}
          />
        </Box>

        <ModalActions>
          {isValidatingMsisdn ? (
            <SpinnerButton disabled />
          ) : (
            <Button
              onClick={validateMsisdnAndApplyPromo}
              disabled={!canValidateMsisdn}
              data-hook="mgm-confirm-button">
              Confirmar
            </Button>
          )}
          <Button
            data-hook="mgm-cancel-button"
            onClick={handleCloseModal}
            disabled={isValidatingMsisdn}
            secondary
            style={{ marginLeft: '16px' }}>
            Cancelar
          </Button>
        </ModalActions>
      </Modal>
    </>
  )
}

MemberGetMemberPromoModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  msisdnFieldName: PropTypes.string.isRequired,
  isValidatingMsisdn: PropTypes.bool,
  validateMsisdnAndApplyPromo: PropTypes.func.isRequired,
  validateMsisdnFormat: PropTypes.func.isRequired,
  isMsisdnFormatError: PropTypes.bool,
  memberGetMemberContenfulDetails: PropTypes.string.isRequired,
  resetMemberGetMemberValues: PropTypes.func.isRequired,
  isLoadingMemberGetMemberInfo: PropTypes.bool,
  msisdnValue: PropTypes.string,
}
