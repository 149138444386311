import { Component } from 'react'
import { get, isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import { Card, SectionTitle, SectionTitleGrid, Dialog } from 'components'

import Button from '@material-ui/core/Button'
import Close from '@material-ui/icons/Close'

import { DiscountsSelector } from '../DiscountsSelector'
import { DiscountsSummary } from '../DiscountsSummary'
import { DiscountsList } from '../DiscountsList'

export class Discounts extends Component {
  onSelectDiscountChange = discount => {
    const { discountsOrder, selectDiscount, getDiscountsIncompatibilitiesOrder } = this.props
    const discountValue = discount.discountCode
    selectDiscount(discount)
    if (discountsOrder.length) {
      getDiscountsIncompatibilitiesOrder(
        discountValue,
        discountsOrder.map(d => d.discountCode).join(','),
      )
    }
  }

  onResetSelectedDiscount = () => {
    const { selectedDiscount, selectDiscount } = this.props
    if (!isEmpty(selectedDiscount)) selectDiscount(null)
  }

  applyDiscountHandler = () => {
    const { orderId, putDiscountsOder } = this.props
    putDiscountsOder(orderId)
  }

  closeTab = () => {
    window.close()
  }

  showErrorDialog = () => {
    const {
      discountsAgentError,
      discountsOrderError,
      discountsIncompatibilitiesError,
      discountsPutError,
    } = this.props
    return (
      <Dialog open alignment="center">
        <Typography variant="subtitle1">Se ha producido un error:</Typography>
        {discountsAgentError && (
          <Typography variant="body2">
            No se pudieron obtener los descuentos autorizados del agente
          </Typography>
        )}
        {discountsOrderError && (
          <Typography variant="body2">No se pudieron obtener los descuentos de la orden</Typography>
        )}
        {discountsIncompatibilitiesError && (
          <Typography variant="body2">
            No se pudieron obtener los incompatibilidades del descuento selecccionado
          </Typography>
        )}
        {discountsPutError && (
          <Typography variant="body2">No se pudo añadir el descuento a la orden</Typography>
        )}
        {!this.isAgentAuthorized() && (
          <Typography variant="body2">No estás autorizado a aplicar descuentos</Typography>
        )}
        <Button color="default" onClick={this.closeTab}>
          Volver a vista
        </Button>
      </Dialog>
    )
  }

  showConfirmationDialog = () => {
    const discountValue = get(this.props.selectedDiscount, 'discountCode')
    return (
      <Dialog open alignment="center" data-hook="dialogConfirmation">
        <Typography variant="subtitle1">Operación realizada con éxito</Typography>
        <Typography variant="body2">
          El descuento {discountValue} ha sido aplicado correctamente.
        </Typography>
        <Button color="default" onClick={this.closeTab}>
          Volver a vista
        </Button>
      </Dialog>
    )
  }

  isAnyError = () => {
    const {
      discountsAgentError,
      discountsOrderError,
      discountsIncompatibilitiesError,
      discountsPutError,
    } = this.props
    return (
      discountsAgentError ||
      discountsOrderError ||
      discountsIncompatibilitiesError ||
      discountsPutError ||
      !this.isAgentAuthorized()
    )
  }

  isDiscountApplied = () => {
    const { discountsPutResponse, discountsPutError } = this.props
    return discountsPutResponse && !discountsPutError
  }

  isAgentAuthorized = () => {
    const { discountsAgent } = this.props
    // The agents are authorized to apply discounts when their profile have available discounts
    return Array.isArray(discountsAgent) ? discountsAgent.length : true
  }

  render() {
    const {
      discountsOrder,
      discountsAgent,
      selectedDiscount,
      discountsOrderLoading,
      discountsAgentLoading,
      discountsIncompatibilitiesLoading,
      discountsPutLoading,
    } = this.props
    const discountValue = get(selectedDiscount, 'discountCode')
    return (
      <>
        <SectionTitleGrid xs={12}>
          <SectionTitle width="auto">Descuentos</SectionTitle>
          <Button color="default" onClick={this.closeTab}>
            cancelar y volver
            <Close />
          </Button>
        </SectionTitleGrid>
        <Card width="80%">
          <DiscountsSelector
            loading={discountsAgentLoading}
            discountsAgent={discountsAgent}
            onSelectDiscountChange={discount => this.onSelectDiscountChange(discount)}
            onResetSelectedDiscount={this.onResetSelectedDiscount}
          />
          <DiscountsList
            discounts={discountsOrder}
            discountSelected={discountValue}
            loading={discountsOrderLoading || discountsIncompatibilitiesLoading}
          />
          <DiscountsSummary
            discounts={discountsOrder}
            selectedDiscount={selectedDiscount}
            onApplyDiscount={this.applyDiscountHandler}
            loading={discountsIncompatibilitiesLoading || discountsPutLoading}
          />
        </Card>
        {this.isAnyError() && this.showErrorDialog()}
        {this.isDiscountApplied() && this.showConfirmationDialog()}
      </>
    )
  }
}

Discounts.propTypes = {
  orderId: PropTypes.string.isRequired,
  getDiscountsIncompatibilitiesOrder: PropTypes.func.isRequired,
  putDiscountsOder: PropTypes.func.isRequired,
  discountsOrder: PropTypes.arrayOf(PropTypes.object),
  selectDiscount: PropTypes.func.isRequired,
  discountsAgent: PropTypes.any,
  selectedDiscount: PropTypes.object,
  discountsPutResponse: PropTypes.object,
  discountsAgentError: PropTypes.object,
  discountsOrderError: PropTypes.object,
  discountsPutError: PropTypes.object,
  discountsIncompatibilitiesError: PropTypes.object,
  discountsOrderLoading: PropTypes.bool,
  discountsIncompatibilitiesLoading: PropTypes.bool,
  discountsPutLoading: PropTypes.bool,
  discountsAgentLoading: PropTypes.bool,
}

Discounts.defaultProps = {
  discountsOrder: [],
}
