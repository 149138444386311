import { OrderMainContainer } from '../../containers/OrderMainContainer'
import { OrderProductsContainer } from '../../containers/OrderProductsContainer'
import { OrderAtcContainer } from '../../containers/OrderAtcContainer'
import { ProductServices } from '../ProductServices/ProductServices'
// import OrderCommunications from '../OrderCommunications/OrderCommunications'
// import OrderMemo from '../OrderMemo/OrderMemo'

export const tabs = {
  MAIN: 'main',
  PRODUCTS: 'products',
  TICKETS: 'tickets',
  COMMUNICATIONS: 'communications',
  MEMO: 'memo',
  ATC: 'atc',
  SERVICES: 'services',
}

export const tabsConfig = [
  {
    label: 'Principal',
    value: tabs.MAIN,
    component: OrderMainContainer,
  },
  {
    label: 'Productos',
    value: tabs.PRODUCTS,
    component: OrderProductsContainer,
  },
  {
    label: 'Provisión',
    value: tabs.ATC,
    component: OrderAtcContainer,
  },
  {
    label: 'Servicios',
    value: tabs.SERVICES,
    component: ProductServices,
  },
  // {
  //   label: 'Comunicaciones',
  //   value: tabs.COMMUNICATIONS,
  //   component: OrderCommunications,
  // },
  // {
  //   label: 'MEMO',
  //   value: tabs.MEMO,
  //   component: OrderMemo,
  // },
]
