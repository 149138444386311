import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { noop } from 'lodash'

import { Collapse, Box, Icon, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import { getLighten, getDarken } from 'utils'
import { ICONS } from 'utils/icons'

import { getCssArrowMixin } from './arrow.helper'

const ITEM_HEIGHT = 48

const useStyles = makeStyles(theme => ({
  'sidebar-menu-item': {
    '& .sidebar-menu-item-title': {
      backgroundColor: ({ selected }) =>
        selected ? getLighten(theme.palette.brand.medium, 0.3) : 'inherit',
      borderBottom: '1px solid rgba(255, 255, 255, 0.2)',
      cursor: 'pointer',
      height: `${ITEM_HEIGHT}px`,
      userSelect: 'none',
      boxShadow: ({ selected, overlay }) =>
        (selected || overlay) && '0 2px 10px 0 rgba(0, 0, 0, 0.2)',
      zIndex: ({ selected, overlay }) => (selected || overlay) && '1',

      '&:hover': {
        backgroundColor: ({ selected }) => !selected && getLighten(theme.palette.brand.medium, 0.1),
      },

      '&::after': {
        ...getCssArrowMixin(ITEM_HEIGHT, getLighten(theme.palette.brand.medium, 0.3)),
        display: ({ selected }) => !selected && 'none',
      },
    },

    '& .arrow-icon-wrapper': {
      width: '8px',

      '& .icon': {
        color: theme.palette.brand.light,
        transform: ({ shouldExpanded }) => (shouldExpanded ? 'rotate(0)' : 'rotate(-90deg)'),
        transitionProperty: 'transform',
        transitionDuration: '0.2s',
      },
    },

    '&:last-child > .sidebar-menu-item-component': {
      marginBottom: '18px',
    },

    '& .sidebar-menu-subitems-wrapper': {
      backgroundColor: getDarken(theme.palette.brand.medium, 0.1),
    },

    '& .sidebar-menu-item-collapse': {
      width: `calc(100% + ${ITEM_HEIGHT / 2}px)`,

      '& > div': {
        width: `calc(100% - ${ITEM_HEIGHT / 2}px)`,
      },
    },
  },
}))

const SidebarMenuItem = ({
  title,
  expanded,
  onClick,
  component,
  alwaysExpanded,
  selected,
  overlay,
  children,
}) => {
  const [isExpanded, setIsExpanded] = useState(expanded)

  useEffect(() => {
    setIsExpanded(expanded)
  }, [expanded])

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded)
  }

  const onClickHandler = () => {
    onClick()

    toggleExpanded()
  }

  const shouldExpanded = isExpanded || alwaysExpanded
  const classes = useStyles({ selected, overlay, shouldExpanded })

  return (
    <Box
      component="li"
      display="flex"
      flexDirection="column"
      flexShrink="0"
      className={classes['sidebar-menu-item']}>
      {title && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
          p="0 15px"
          position="relative"
          className="sidebar-menu-item-title"
          data-hook={title}
          data-selected={selected}
          onClick={onClickHandler}>
          <Box
            display="flex"
            justifyContent="center"
            mr="16px"
            component="span"
            className="arrow-icon-wrapper">
            {children && !alwaysExpanded && <Icon className="icon">{ICONS.downArrow}</Icon>}
          </Box>
          <Typography variant="body1" className="light">
            {title}
          </Typography>
        </Box>
      )}
      {component && (
        <Box mt="18px" className="sidebar-menu-item-component">
          {component}
        </Box>
      )}
      {children && (
        <Box component="ul" p="0" className="sidebar-menu-subitems-wrapper">
          <Collapse in={shouldExpanded} timeout="auto" className="sidebar-menu-item-collapse">
            {children}
          </Collapse>
        </Box>
      )}
    </Box>
  )
}

SidebarMenuItem.propTypes = {
  title: PropTypes.string,
  component: PropTypes.node,
  children: PropTypes.node,
  expanded: PropTypes.bool,
  alwaysExpanded: PropTypes.bool,
  selected: PropTypes.bool,
  overlay: PropTypes.bool,
  onClick: PropTypes.func,
}

SidebarMenuItem.defaultProps = {
  expanded: false,
  alwaysExpanded: false,
  selected: false,
  overlay: false,
  onClick: noop,
}

export default SidebarMenuItem
