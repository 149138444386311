import PropTypes from 'prop-types'
import { HTMLTemplate } from 'modules/ui/components/HTMLTemplate/HTMLTemplate'
import { Step } from './Step'

function RouterSteps({ shipmentTitle, shipmentDescription, portaTitle, portaDescription }) {
  return (
    <>
      {shipmentTitle && shipmentDescription && (
        <Step title={shipmentTitle} description={<HTMLTemplate html={shipmentDescription} />} />
      )}
      {portaTitle && portaDescription && (
        <Step title={portaTitle} description={<HTMLTemplate html={portaDescription} />} />
      )}
    </>
  )
}

RouterSteps.propTypes = {
  shipmentTitle: PropTypes.string,
  shipmentDescription: PropTypes.string,
  portaTitle: PropTypes.string,
  portaDescription: PropTypes.string,
}

export default RouterSteps
