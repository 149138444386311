import { GET_PONC, GET_PONC_SUCCESS, GET_PONC_FAILED } from './ponc.actions'

const initialState = {
  cancelationDate: '',
  reached: false,
  loading: false,
}

export const reducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case GET_PONC: {
      return {
        ...initialState,
        loading: true,
      }
    }

    case GET_PONC_SUCCESS: {
      return {
        ...state,
        loading: false,
        ...action.payload.options,
      }
    }

    case GET_PONC_FAILED: {
      return {
        ...state,
        loading: false,
        error: payload.error,
      }
    }

    default:
      return state
  }
}
