import { useSelector } from 'react-redux'

import { get } from 'lodash'
import { applyTax } from 'services/taxes'

import { getCurrentCustomerTax } from '../selectors'

export const useTaxApplied = amounts => {
  const tax = useSelector(getCurrentCustomerTax)

  const taxes = Object.keys(amounts).reduce(
    (taxedProps, prop) => ({
      ...taxedProps,
      [prop]: applyTax(tax, get(amounts, prop, 0)),
    }),
    {},
  )

  return {
    tax,
    ...taxes,
  }
}
