import { constants } from './constants'

function getDiscounts(profiles, category) {
  return {
    type: constants.GET_DISCOUNTS,
    payload: {
      profiles,
      category,
    },
  }
}

function getDiscountsSuccess(discounts, category) {
  return {
    type: constants.GET_DISCOUNTS_SUCCESS,
    payload: {
      discounts,
      category,
    },
  }
}

function getDiscountsFailed(error, category) {
  return {
    type: constants.GET_DISCOUNTS_FAILED,
    payload: {
      error,
      category,
    },
  }
}

export const actions = {
  getDiscounts,
  getDiscountsSuccess,
  getDiscountsFailed,
}
