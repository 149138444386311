import { get } from 'lodash'

const provincesCodes = {
  1: 'Araba/Álava',
  2: 'Albacete',
  3: 'Alicante/Alacant',
  4: 'Almería',
  5: 'Ávila',
  6: 'Badajoz',
  7: 'Balears, Illes',
  8: 'Barcelona',
  9: 'Burgos',
  10: 'Cáceres',
  11: 'Cádiz',
  12: 'Castellón/Castelló',
  13: 'Ciudad Real',
  14: 'Córdoba',
  15: 'Coruña, A',
  16: 'Cuenca',
  20: 'Gipuzkoa',
  17: 'Girona',
  18: 'Granada',
  19: 'Guadalajara',
  21: 'Huelva',
  22: 'Huesca',
  23: 'Jaén',
  24: 'León',
  25: 'Lleida',
  27: 'Lugo',
  28: 'Madrid',
  29: 'Málaga',
  30: 'Murcia',
  31: 'Navarra',
  32: 'Ourense',
  33: 'Asturias',
  34: 'Palencia',
  35: 'Palmas, Las',
  36: 'Pontevedra',
  26: 'Rioja, La',
  37: 'Salamanca',
  38: 'Santa Cruz de Tenerife',
  39: 'Cantabria',
  40: 'Segovia',
  41: 'Sevilla',
  42: 'Soria',
  43: 'Tarragona',
  44: 'Teruel',
  45: 'Toledo',
  46: 'Valencia/València',
  47: 'Valladolid',
  48: 'Bizkaia',
  49: 'Zamora',
  50: 'Zaragoza',
  51: 'Ceuta',
  52: 'Melilla',
}

export const getProvinceFromCode = province => get(provincesCodes, Number(province), '')
export const getProvinceFromCodeOrString = province => {
  const provinceFromCode = getProvinceFromCode(province)
  return provinceFromCode !== '' ? provinceFromCode : province
}

export const getProvinceArray = Object.keys(provincesCodes).map(elem => ({
  key: elem,
  label: provincesCodes[elem],
  value: provincesCodes[elem],
}))

const countryCodes = {
  AF: 'Afganistán',
  AL: 'Albania',
  DE: 'Alemania',
  DZ: 'Argelia',
  AD: 'Andorra',
  AO: 'Angola',
  AI: 'Anguila',
  AQ: 'Antártida',
  AG: 'Antigua y Barbuda',
  AN: 'Antillas Neerlandesas',
  SA: 'Arabia Saudita',
  AR: 'Argentina',
  AM: 'Armenia',
  AW: 'Aruba',
  AU: 'Australia',
  AT: 'Austria',
  AZ: 'Azerbayán',
  BE: 'Bélgica',
  BS: 'Bahamas',
  BH: 'Bahrein',
  BD: 'Bangladesh',
  BB: 'Barbados',
  BZ: 'Belice',
  BJ: 'Benín',
  BT: 'Bhután',
  BY: 'Bielorrusia',
  MM: 'Birmania',
  BO: 'Bolivia',
  BA: 'Bosnia y Herzegovina',
  BW: 'Botsuana',
  BR: 'Brasil',
  BN: 'Brunéi',
  BG: 'Bulgaria',
  BF: 'Burkina Faso',
  BI: 'Burundi',
  CV: 'Cabo Verde',
  KH: 'Camboya',
  CM: 'Camerún',
  CA: 'Canadá',
  TD: 'Chad',
  CL: 'Chile',
  CN: 'China',
  CY: 'Chipre',
  VA: 'Ciudad del Vaticano',
  CO: 'Colombia',
  KM: 'Comoras',
  CG: 'Congo',
  CD: 'Congo',
  KP: 'Corea del Norte',
  KR: 'Corea del Sur',
  CI: 'Costa de Marfil',
  CR: 'Costa Rica',
  HR: 'Croacia',
  CU: 'Cuba',
  DK: 'Dinamarca',
  DM: 'Dominica',
  EC: 'Ecuador',
  EG: 'Egipto',
  SV: 'El Salvador',
  AE: 'Emiratos Árabes Unidos',
  ER: 'Eritrea',
  SK: 'Eslovaquia',
  SI: 'Eslovenia',
  ES: 'España',
  US: 'Estados Unidos de América',
  EE: 'Estonia',
  ET: 'Etiopía',
  PH: 'Filipinas',
  FI: 'Finlandia',
  FJ: 'Fiyi',
  FR: 'Francia',
  GA: 'Gabón',
  GM: 'Gambia',
  GE: 'Georgia',
  GH: 'Ghana',
  GI: 'Gibraltar',
  GD: 'Granada',
  GR: 'Grecia',
  GL: 'Groenlandia',
  GP: 'Guadalupe',
  GU: 'Guam',
  GT: 'Guatemala',
  GF: 'Guayana Francesa',
  GG: 'Guernsey',
  GN: 'Guinea',
  GQ: 'Guinea Ecuatorial',
  GW: 'Guinea-Bissau',
  GY: 'Guyana',
  HT: 'Haití',
  HN: 'Honduras',
  HK: 'Hong kong',
  HU: 'Hungría',
  IN: 'India',
  ID: 'Indonesia',
  IR: 'Irán',
  IQ: 'Irak',
  IE: 'Irlanda',
  BV: 'Isla Bouvet',
  IM: 'Isla de Man',
  CX: 'Isla de Navidad',
  NF: 'Isla Norfolk',
  IS: 'Islandia',
  BM: 'Islas Bermudas',
  KY: 'Islas Caimán',
  CK: 'Islas Cook',
  CC: 'Islas Cocos (Keeling)',
  AX: 'Islas de Åland',
  FO: 'Islas Feroe',
  GS: 'Islas Georgias del Sur y Sandwich del Sur',
  HM: 'Islas Heard y McDonald',
  MV: 'Islas Maldivas',
  FK: 'Islas Malvinas',
  MP: 'Islas Marianas del Norte',
  MH: 'Islas Marshall',
  PN: 'Islas Pitcairn',
  SB: 'Islas Salomón',
  TC: 'Islas Turcas y Caicos',
  UM: 'Islas Ultramarinas Menores de Estados Unidos',
  VG: 'Islas Vírgenes Británicas',
  VI: 'Islas Vírgenes de los Estados Unidos',
  IL: 'Israel',
  IT: 'Italia',
  JM: 'Jamaica',
  JP: 'Japón',
  JE: 'Jersey',
  JO: 'Jordania',
  KZ: 'Kazajistán',
  KE: 'Kenia',
  KG: 'Kirgizstán',
  KI: 'Kiribati',
  KW: 'Kuwait',
  LB: 'Líbano',
  LA: 'Laos',
  LS: 'Lesoto',
  LV: 'Letonia',
  LR: 'Liberia',
  LY: 'Libia',
  LI: 'Liechtenstein',
  LT: 'Lituania',
  LU: 'Luxemburgo',
  MX: 'México',
  MC: 'Mónaco',
  MO: 'Macao',
  MK: 'Macedônia',
  MG: 'Madagascar',
  MY: 'Malasia',
  MW: 'Malawi',
  ML: 'Mali',
  MT: 'Malta',
  MA: 'Marruecos',
  MQ: 'Martinica',
  MU: 'Mauricio',
  MR: 'Mauritania',
  YT: 'Mayotte',
  FM: 'Micronesia',
  MD: 'Moldavia',
  MN: 'Mongolia',
  ME: 'Montenegro',
  MS: 'Montserrat',
  MZ: 'Mozambique',
  NA: 'Namibia',
  NR: 'Nauru',
  NP: 'Nepal',
  NI: 'Nicaragua',
  NE: 'Niger',
  NG: 'Nigeria',
  NU: 'Niue',
  NO: 'Noruega',
  NC: 'Nueva Caledonia',
  NZ: 'Nueva Zelanda',
  OM: 'Omán',
  NL: 'Países Bajos',
  PK: 'Pakistán',
  PW: 'Palau',
  PS: 'Palestina',
  PA: 'Panamá',
  PG: 'Papúa Nueva Guinea',
  PY: 'Paraguay',
  PE: 'Perú',
  PF: 'Polinesia Francesa',
  PL: 'Polonia',
  PT: 'Portugal',
  PR: 'Puerto Rico',
  QA: 'Qatar',
  GB: 'Reino Unido',
  CF: 'República Centroafricana',
  CZ: 'República Checa',
  DO: 'República Dominicana',
  RE: 'Reunión',
  RW: 'Ruanda',
  RO: 'Rumanía',
  RU: 'Rusia',
  EH: 'Sahara Occidental',
  WS: 'Samoa',
  AS: 'Samoa Americana',
  BL: 'San Bartolomé',
  KN: 'San Cristóbal y Nieves',
  SM: 'San Marino',
  MF: 'San Martín (Francia)',
  PM: 'San Pedro y Miquelón',
  VC: 'San Vicente y las Granadinas',
  SH: 'Santa Elena',
  LC: 'Santa Lucía',
  ST: 'Santo Tomé y Príncipe',
  SN: 'Senegal',
  RS: 'Serbia',
  SC: 'Seychelles',
  SL: 'Sierra Leona',
  SG: 'Singapur',
  SY: 'Siria',
  SO: 'Somalia',
  LK: 'Sri lanka',
  ZA: 'Sudáfrica',
  SD: 'Sudán',
  SE: 'Suecia',
  CH: 'Suiza',
  SR: 'Surinám',
  SJ: 'Svalbard y Jan Mayen',
  SZ: 'Swazilandia',
  TJ: 'Tadjikistán',
  TH: 'Tailandia',
  TW: 'Taiwán',
  TZ: 'Tanzania',
  IO: 'Territorio Británico del Océano Índico',
  TF: 'Territorios Australes y Antárticas Franceses',
  TL: 'Timor Oriental',
  TG: 'Togo',
  TK: 'Tokelau',
  TO: 'Tonga',
  TT: 'Trinidad y Tobago',
  TN: 'Tunez',
  TM: 'Turkmenistán',
  TR: 'Turquía',
  TV: 'Tuvalu',
  UA: 'Ucrania',
  UG: 'Uganda',
  UY: 'Uruguay',
  UZ: 'Uzbekistán',
  VU: 'Vanuatu',
  VE: 'Venezuela',
  VN: 'Vietnam',
  WF: 'Wallis y Futuna',
  YE: 'Yemen',
  DJ: 'Yibuti',
  ZM: 'Zambia',
  ZW: 'Zimbabue',
}

const countryPrefix = {
  '93': 'Afghanistan',
  '355': 'Albania',
  '49': 'Alemania',
  '213': 'Algeria',
  '376': 'Andorra',
  '244': 'Angola',
  '1264': 'Anguila',
  '1268': 'Antigua & Barbuda',
  '966': 'Arabia Saudí',
  '54': 'Argentina',
  '374': 'Armenia',
  '297': 'Aruba',
  '247': 'Ascension Island',
  '61': 'Australia',
  '43': 'Austria',
  '944': 'Azerbaijan',
  '1242': 'Bahamas',
  '973': 'Bahrain',
  '880': 'Bangladesh',
  '1246': 'Barbados',
  '375': 'Belarus',
  '32': 'Belgium',
  '501': 'Belize',
  '229': 'Benin',
  '1441': 'Bermuda',
  '591': 'Bolivia',
  '387': 'Bosnia',
  '267': 'Botswana',
  '55': 'Brasil',
  '673': 'Brunei',
  '359': 'Bulgaria',
  '226': 'Burkina Faso',
  '257': 'Burundi',
  '975': 'Butan',
  '855': 'Camboya',
  '237': 'Camerun',
  '235': 'Chad',
  '56': 'Chile',
  '86': 'China',
  '357': 'Chipre',
  '57': 'Colombia',
  '242': 'Congo',
  '850': 'Corea del Norte',
  '82': 'Corea del Sur',
  '225': 'Costa de Ivory',
  '506': 'Costa Rica',
  '385': 'Croacia',
  '53': 'Cuba',
  '246': 'Diego Garcia',
  '45': 'Dinamarca',
  '1767': 'Dominica',
  '5939': 'Ecuador',
  '20': 'Egipto',
  '503': 'El Salvador',
  '971': 'Emiratos arabes unidos',
  '291': 'Eritrea',
  '421': 'Eslovaquia',
  '386': 'Eslovenia',
  '34': 'España',
  '372': 'Estonia',
  '251': 'Etiopia',
  '63': 'Filipinas',
  '358': 'Finlandia',
  '33': 'Francia',
  '241': 'Gabón',
  '220': 'Gambia',
  '995': 'Georgia',
  '233': 'Ghana',
  '350': 'Gibraltar',
  '1473': 'Granada',
  '30': 'Grecia',
  '299': 'Groenlandia',
  '590': 'Guadalupe',
  '1671': 'Guam',
  '502': 'Guatemala',
  '592': 'Guayana',
  '224': 'Guinea',
  '245': 'Guinea Bissau',
  '240': 'Guinea Ecuatorial',
  '594': 'Guinea francesa',
  '509': 'Haiti',
  '31': 'Holanda',
  '504': 'Honduras',
  '852': 'Hong Kong',
  '36': 'Hungria',
  '91': 'India',
  '62': 'Indonesia',
  '98': 'Iran',
  '964': 'Iraq',
  '353': 'Irlanda',
  '262': 'Isla Reunion',
  '354': 'Islandia',
  '238': 'Islas Cabo Verde',
  '1345': 'Islas Caiman',
  '682': 'Islas Cook',
  '500': 'Islas Falkland',
  '298': 'Islas Feroe',
  '679': 'Islas Fidji',
  '1670': 'Islas Mariana',
  '692': 'Islas Marshall',
  '6723': 'Islas Norfolk',
  '677': 'Islas Salomón',
  '1284': 'Islas Vírgenes Británicas',
  '972': 'Israel',
  '39': 'Italy',
  '1876': 'Jamaica',
  '81': 'Japón',
  '962': 'Jordan',
  '771, 772, 776': 'Kazakistan',
  '254': 'Kenya',
  '996': 'Kirguiquistan',
  '686': 'Kiribati',
  '965': 'Kuwait',
  '856': 'Laos',
  '961': 'Lebanon',
  '266': 'Lesotho',
  '371': 'Letonia',
  '231': 'Liberia',
  '218': 'Libia',
  '423': 'Liechtenstein',
  '370': 'Lituania',
  '352': 'Luxemburgo',
  '853': 'Macau',
  '389': 'Macedonia',
  '261': 'Madagascar',
  '60': 'Malasia',
  '265': 'Malawi',
  '960': 'Maldivas',
  '223': 'Mali',
  '356': 'Malta',
  '212': 'Marruecos',
  '596': 'Martinica',
  '230': 'mauricio',
  '222': 'Mauritania',
  '52': 'Mexico',
  '691': 'Micronesia',
  '373': 'Moldavia',
  '377': 'Monaco',
  '976': 'Mongolia',
  '382': 'Montenegro',
  '1664': 'Montserrat',
  '258': 'Mozambique',
  '95': 'Myanmar',
  '264': 'Namibia',
  '674': 'Nauru',
  '977': 'Nepal',
  '1869': 'Nevis & St. Kitts',
  '505': 'Nicaragua',
  '227': 'Niger',
  '234': 'Nigeria',
  '683': 'Niue',
  '47': 'Noruega',
  '687': 'Nueva Caledonia',
  '64': 'Nueva Zelanda',
  '968': 'Oman',
  '': 'PAÍS PREFIJO',
  '92': 'Pakistan',
  '680': 'Palau',
  '970': 'Palestina',
  '507': 'Panama',
  '675': 'Papua Nueva Guinea',
  '595': 'Paraguay',
  '51': 'Peru',
  '689': 'Polinesia francesa',
  '48': 'Polonia',
  '351': 'Portugal',
  '974': 'Qatar',
  '44': 'Reino Unido',
  '236': 'Rep. Centro Africana',
  '420': 'Rep. Checa',
  '243': 'Rep. Dem. del Congo',
  '1809, 1849': 'Rep. Dominicana',
  '250': 'Ruanda',
  '40': 'Rumania',
  '7': 'Rusia',
  '685': 'Samoa oriental',
  '378': 'San Marino',
  '239': 'San Tome',
  '221': 'Senegal',
  '381': 'Serbia',
  '248': 'Seychelles',
  '232': 'Sierra Leone',
  '65': 'Singapur',
  '963': 'Siria',
  '252': 'Somalia',
  '94': 'Sri Lanka',
  '290': 'St. Helena',
  '1758': 'St. Lucia',
  '508': 'St. Pierre & Miquelon',
  '1784': 'St. Vicente & Granadinas',
  '249': 'Sudan',
  '46': 'Suecia',
  '41': 'Suiza',
  '27': 'Surafrica',
  '597': 'Surinam',
  '268': 'Swazilandia',
  '66': 'Tailandia',
  '886': 'Taiwan',
  '992': 'Tajikistan',
  '255': 'Tanzania',
  '670': 'Timor del Este',
  '228': 'Togo',
  '690': 'Tokelau',
  '676': 'Tonga',
  '1868': 'Trinidad & Tobago',
  '216': 'Tunez',
  '90': 'Turquia',
  '688': 'Tuvalu',
  '380': 'Ucrania',
  '256': 'Uganda',
  '598': 'Uruguay',
  '1': 'USA y Canada',
  '998': 'Uzbekistan',
  '678': 'Vanuatu',
  '58': 'Venezuela',
  '84': 'Vietnam',
  '681': 'Wallis & Futuna',
  '967': 'Yemen',
  '253': 'Yibouti',
  '260': 'Zambia',
  '263': 'Zimbawe',
}

export const getCountryFromPrefix = country => get(countryPrefix, country)

export const getCountryFromIsoCode = country => get(countryCodes, country, '')
