import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { permissions, selectCanIDO , selectProfileId } from 'modules/Permissions'
import { selectSfid } from 'modules/Auth/store/selectors'
import { selectEnableMasCareIframeTypication } from 'services/feature-flag/selectors'
import { selectCustomerId } from 'modules/Auth'
import { helpers as helpers360, selectors as selectors360 } from 'services/customer-360'

const useGetMsisdns = () => {
  const contractsCustomer = useSelector(selectors360.contracts)

  const msisdns = useMemo(() => helpers360.getAllLinesAndElFijoMsisdn(contractsCustomer), [
      contractsCustomer,
    ])

  return msisdns
}

export const useGetIframeData = () => {
    const permissionIframeAccess = useSelector(selectEnableMasCareIframeTypication) && useSelector(selectCanIDO(permissions.typification_iframe_access.id))

    const sfid = useSelector(selectSfid)
    const area = useSelector(selectProfileId)
    const customerId = useSelector(selectCustomerId)
    const msisdns = useGetMsisdns()

    return {permissionIframeAccess, sfid, area, customerId, msisdns}
}