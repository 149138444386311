import { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { useFormikContext } from 'formik'
import { Advice, FormikInput } from 'components/ui'
import { useIMEIValidation } from '../../hooks/useIMEIValidation'

export function InputIMEI({ fieldNames, onValid }) {
  const { validate, fetchValidateIMEI, currentPsId } = useIMEIValidation()

  const { values, setFieldValue } = useFormikContext()
  const imeiValidationMessage = useMemo(() => get(values, fieldNames.IMEI_VALIDATION_MESSAGE), [values])

  async function validateIMEI(inputValue) {
    const validateStatus = await fetchValidateIMEI(inputValue)
    setFieldValue(fieldNames.IMEI_VALIDATION_MESSAGE, validateStatus)
  }

  useEffect(() => {
    return onValid && onValid(currentPsId)
  }, [currentPsId])

  return (
    <div className="flex align-start-center">
      <FormikInput
        validate={validate}
        onChange={validateIMEI}
        label="Introduce el IMEI del terminal"
        name={fieldNames.IMEI}
        dataHook={fieldNames.IMEI}
        maxLength="15"
        backgroundcolor="white"
      />
      {imeiValidationMessage && (
        <div className="m-l-36" style={{ minWidth: '400px' }}>
          <Advice type="warning">{imeiValidationMessage}</Advice>
        </div>
      )}
    </div>
  )
}

InputIMEI.propTypes = {
  fieldNames: PropTypes.string,
  onValid: PropTypes.func.isRequired,
}
