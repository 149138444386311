import { useState } from 'react'
import PropTypes from 'prop-types'

import { Typography, Box, Divider } from '@material-ui/core'
import { TextStyles } from 'utils/text'

import { Modal } from 'components/ui'
import { get, head, isEmpty } from 'lodash'
import { ButtonLink } from '../../../../components/packages/ui/buttons/ButtonLink'
import { PriceBreakdown } from '../PriceBreakdown/'
import { FeesItem } from '../FeesItem'

export function MainFeesItem({ feesData, offer, taxNeeded }) {
  const [openModal, setOpenModal] = useState(false)
  const promotions = get(offer, 'tariff.promotions')
  return (
    !isEmpty(offer) && (
      <>
        <div className="flex align-space-between" data-hook="main-fees-item">
          <Typography
            {...TextStyles.subtitle2Link({
              color: 'primary',
              style: { padding: '9px 24px' },
            })}>
            <span>RESUMEN DE PRECIOS</span>
          </Typography>
          <div className="p-x-24">
            <ButtonLink onClick={() => setOpenModal(true)} dataHook="view-price-breakdown">
              Ver desglose
            </ButtonLink>
          </div>
        </div>

        <div>
          <Modal
            isOpen={openModal}
            title="Desglose de precios"
            onClose={() => setOpenModal(false)}
            data-hook="price-breakdown-modal">
            <PriceBreakdown
              offer={offer}
              feesData={feesData}
              promotion={head(promotions)}
              taxNeeded={taxNeeded}
            />
          </Modal>
        </div>

        <div className="p-x-12">
          <FeesItem
            feesData={feesData}
            promotion={head(promotions)}
            taxNeeded={taxNeeded}
            noDivider
          />
        </div>

        <Box m="12px">
          <Divider />
        </Box>
      </>
    )
  )
}

MainFeesItem.propTypes = {
  feesData: PropTypes.object,
  offer: PropTypes.object,
  taxNeeded: PropTypes.bool,
}
