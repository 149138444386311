import { Component } from 'react'
import { ContainerTitle, CardRow, CardRowLabel, CardRowValue } from 'components'
import { checkIsNeba, checkIsCommercialVula } from 'modules/Coverage'
import { getTerritoryOwnerByCoverage } from 'modules/Coverage/helpers'
import { PropTypes } from 'prop-types'
import { get, isEmpty, find, isString } from 'lodash'
import { Modal } from '../Modal'

class CustomerMoveAddress extends Component {
  checkCoverageFtth = data => {
    if (!data) return null
    const dataExtracted = data
    const ftth = get(dataExtracted, 'ftth')
    const adsl = get(dataExtracted, 'ull')
    const isFtth = !isEmpty(ftth)
    const isAdsl = !isEmpty(adsl)
    const characteristics = get(dataExtracted, 'characteristics')
    const territoryOwner = get(find(characteristics, x => x.name === 'territoryOwner'), 'value')

    const isOpen = this.props.isFeatNebaEnabled
      ? !isFtth && !isAdsl
      : (!isFtth && !isAdsl) || checkIsNeba(territoryOwner)
    let coverage = ''
    if (isFtth) {
      coverage = 'FTTH'
    }
    if (isAdsl) {
      coverage = 'ADSL'
    }
    if (!isFtth && !isAdsl && isString(data)) {
      coverage = 'no disponible'
    }

    return (
      <span>
        {!isEmpty(coverage) &&
        (this.props.isFeatNebaEnabled ? !checkIsNeba(territoryOwner) : true) ? (
          coverage
        ) : (
          <>
            {coverage}
            <Modal
              open={isOpen}
              title="Aviso Importante"
              message="Cobertura no esta disponible en esta dirección."
              cancelBtn="Salir"
              quit={this.props.closeWindowIfExistError}
            />
          </>
        )}
      </span>
    )
  }

  render() {
    let installationLabel
    const {
      coverageData: { coverage },
      address,
      FFlagInstaller,
    } = this.props

    const territoryOwnerByCoverage = getTerritoryOwnerByCoverage(coverage)

    if (checkIsNeba(territoryOwnerByCoverage)) {
      installationLabel = 'Instalación de Telefónica (NEBA)'
    } else if (checkIsCommercialVula(territoryOwnerByCoverage)) {
      installationLabel = 'Instalación de Telefónica (VULA)'
    } else {
      installationLabel = 'Instalación propia'
    }

    // TODO refactor
    return (
      <div>
        <ContainerTitle>DIRECCIÓN NUEVA</ContainerTitle>
        <CardRow>
          <CardRowLabel>Dirección de la instalación: </CardRowLabel>
          <CardRowValue>{address}</CardRowValue>
        </CardRow>
        <CardRow>
          <CardRowLabel>Cobertura: </CardRowLabel>
          <CardRowValue>{this.checkCoverageFtth(...coverage)}</CardRowValue>
        </CardRow>
        {FFlagInstaller && (
          <CardRow>
            <CardRowLabel>Instalación:</CardRowLabel>
            <CardRowValue>{installationLabel}</CardRowValue>
          </CardRow>
        )}
      </div>
    )
  }
}

CustomerMoveAddress.propTypes = {
  address: PropTypes.string,
  closeWindowIfExistError: PropTypes.bool,
  isFeatNebaEnabled: PropTypes.bool,
  coverageData: PropTypes.object,
  FFlagInstaller: PropTypes.bool,
}
export default CustomerMoveAddress
