import { makeStyles } from '@material-ui/styles'
import { Typography, Box } from '@material-ui/core'

import PropTypes from 'prop-types'
import { TextStyles } from 'utils/text'
import { TariffInfoModalComponent } from '../TariffInfoModalComponent'

const useStyles = makeStyles(theme => ({
  heading: {
    paddingTop: '10px',
    paddingBottom: '24.5px',

    '& h4, & .month-price': {
      color: '#2e2e2e',
    },
  },
  'open-tariff-button': {
    border: 'none',
    borderRradius: '10px',
    cursor: 'pointer',
    background: 'transparent',
    outline: 'none',
    marginBottom: '17px',
  },
  disabled: {
    '& > *': {
      color: theme.palette.global.gray,
    },
  },
}))

export function TariffPickerHeading({
  selectedTariff,
  lineType,
  onClickChangeTariff,
  errorDelete,
  disabled,
  children,
}) {
  const styles = useStyles()
  return (
    <Box
      display="flex"
      width={1}
      flex={(!selectedTariff?.id && '1 1') || null}
      justifyContent={(!selectedTariff?.id && 'center') || null}
      className={`${(disabled && styles.disabled) || ''}`}>
      {selectedTariff?.id ? (
        <>
          <Box display="flex" className={`${styles.heading}`}>
            <Box display="flex" alignItems="center">
              <Typography
                {...TextStyles.title3Dark({
                  component: 'h4',
                })}
                data-hook="landlineOffer.tariff.id">
                {selectedTariff?.name || 'No hay tarifa seleccionada'}
              </Typography>

              <Typography
                {...TextStyles.subtitle2Dark({
                  component: 'span',
                  className: 'month-price p-x-8',
                })}>
                ({selectedTariff?.priceWithTax} €/mes)
              </Typography>

              <TariffInfoModalComponent
                lineType={lineType}
                name={selectedTariff?.name}
                disabled={disabled}
                title={selectedTariff?.name}
                description={
                  selectedTariff?.commercialInformation &&
                  selectedTariff?.commercialInformation.description
                }
              />
            </Box>
            {children}
          </Box>

          {errorDelete && (
            <Typography
              {...TextStyles.labelAlert({
                className: 'thin',
              })}>
              {' '}
              Para eliminar esta tarifa, elimine primero el resto de líneas extra dependientes de la
              misma
            </Typography>
          )}
        </>
      ) : (
        <button
          data-hook="open-tariff-modal"
          type="button"
          onClick={onClickChangeTariff}
          className={`${styles['open-tariff-button']} flex align-center full-width`}>
          <Typography
            {...TextStyles.subtitle1Link({
              component: 'h5',
            })}>
            + SELECCIONAR LA TARIFA {lineType.startsWith('main') && ' DE CABECERA'}
          </Typography>
        </button>
      )}
    </Box>
  )
}

TariffPickerHeading.propTypes = {
  selectedTariff: PropTypes.any,
  onClickChangeTariff: PropTypes.func,
  lineType: PropTypes.string,
  disabled: PropTypes.bool,
  errorDelete: PropTypes.bool,
  children: PropTypes.node,
}
