import { Grid } from '@material-ui/core'
import { styled } from '@material-ui/styles'

const SectionGrid = styled(Grid)(({ height, alignitems }) => ({
  height: height || null,
  margin: '50px auto',
  display: 'flex',
  justifyContent: 'center',
  alignItems: alignitems || null,
}))

export default SectionGrid
