import { get, sortBy, isEmpty, flatMap, find } from 'lodash'
import { createSelector, createStructuredSelector } from 'reselect'
import { selectSfid } from 'modules/Auth'
import {
  NEBA,
  VULA,
  FTTH,
  ADSL,
  OTHER_ADDRESS,
  ONLY_FIXED,
  FIXED_MOBILE,
  MULTISIM,
} from 'modules/SharedSales/constants'
import { selectContentfulResource, selectContentful } from 'modules/contentful'
import { selectCustomerOrderLimit, selectBusyDuoLines } from 'modules/CustomerOrders'

import {
  formatMobileTariffs,
  formatTariffError,
  formatBasicTariff,
  mapToSortedMobileTariffs,
  getDiscountFromCampaign,
  uniqsDiscountsFromCrossSellPromotion,
  addDiscountsToTariffPrices,
} from 'modules/tariffs/helpers'
import {
  selectQueryParams,
  selectIsConvergent,
  selectIsMobileOnly,
  selectIs2p,
} from 'modules/Router'

import { selectInstallationsData, selectFiberInstaller } from 'modules/Installations'
import { selectInstallationsSalesFlag } from 'services/feature-flag/selectors'
import { COMPANY, AUTONOMO, SIGNUP_TYPE_NEWLINE } from 'services/global-constants'
import {
  selectSubscriptionStatus,
  selectSubscriptionId,
  selectCustomerAddress,
  selectTelephoneNumbersCustomer,
} from 'services/customer-360/selectors'

import { selectTelephoneNumbers } from 'modules/orders'

import { helpers as customer360Helpers } from 'services/customer-360/helpers'

import {
  selectIsNeba,
  selectIsVula,
  selectCoverageData,
  selectIsAdsl,
  selectTerritoryOwner,
} from 'modules/Coverage'
import {
  selectIsCarteraQueryParam,
  selectIsSubscriptionQueryParam,
  selectIsCrossSellQueryParam,
  selectIsMultiSimQueryParam,
} from 'modules/Router/store/index'

import {
  selectAdditionalMobileTariffs,
  selectAdditionalMobileTariffsIsLoading,
  selectAdditionalMobileTariffsError,
  selectMainMobileTariffs,
  selectMainMobileTariffsError,
  selectMainMobileTariffsIsLoading,
  selectMainLineTariffs,
  selectIsLoadingMainLineTariffs,
} from 'modules/tariffs/store'
import { selectTariffsApigeeSelectedTerminalDevices } from 'modules/tariffs/store-apigee'
import { CIF } from 'modules/CustomerDocument/constants/documentTypes'
import { CATEGORY_2P_PURE, POSTPAID } from 'modules/tariffs/tariffs.constants'

import { selectIsCustomerCanceled } from 'modules/CustomerInfo/store/index'
import { getPriceWithTax } from 'modules/Taxes/helpers.js'
import { formatSVAS } from 'modules/SVAs/helpers/index'
import { selectMainLineTariffsError } from 'modules/tariffs/store/tariffs.selectors'

import {
  selectStatusError,
  getCreationStatus,
  selectOrderCheckingFinished,
  selectStatusSuccess,
  selectTaskId,
  selectOrderStatusId,
} from './orders.selectors'
import {
  selectMainMobileLineOffer,
  selectExtraMobileLinesOffers,
  selectPackages,
  selectOrderCheckout,
  selectLandlineOffer,
  selectSelectedTerminals,
  selectMainPackages,
  selectAccount,
  selectHasSomeTerminal,
  selectIsCompany,
  selectIsElFijo,
  selectSaleChannel,
  selectBilling,
  selectServiceAddress,
  getBasket,
  selectCrossSellTariffPromo,
  selectedLandlineProSVAs,
  selectMainMobileTariffName,
  selectIsPro,
  selectHasAgileTV,
} from './Basket.selectors'
import { selectSelectedTerminalsWithComputedPrices } from './BasketWithTaxes.selectors'
import {
  filterSegments,
  getOrderTerminalsConditions,
  getFiberType,
  mapToErrorCheckout,
  mapToAnalyticsTransactionCompleteEvent,
  getFeesWithBundles,
  formatLineBundles,
  formatDiscounts,
  mapToPostalAddressUpdate,
  applyTaxOnlyProSVAs,
} from './NewClientSales.selectors.helpers'

import { selectSaleTax } from './Taxes.selectors'
import { selectCoverageTechnology, selectTechnology } from '../../../Coverage/store/selectors'
import { mapToOtherAddress, mapToCarteraAddress } from './checkout.selectors.helpers'
import {
  BROADBAND_TYPE_ADSL,
  BROADBAND_TYPE_FIBRA,
  ROUTER_NAME_FIBRA,
  ROUTER_NAME_ADSL,
  FFTH_ROUTER_PENALTY_TAX_FREE,
  ADSL_ROUTER_PENALTY_TAX_FREE,
} from '../../constants'

const SELLER_APPOINTMENT_ROLE = '14'

// @TODO move this to contentful module
const selectCustomerSegments = selectContentfulResource('customerSegments')

export const selectCountries = selectContentfulResource('countries')

export const selectMemberGetMemberContenfulDetails = createSelector(
  [selectContentful],
  contentful => {
    const legalTexts = get(contentful, ['legalText', 'data'])

    const campaignMgm = legalTexts?.find(text => text.operation === 'campaign_MGM')

    return campaignMgm?.text || null
  },
)

// Removed CEUTA & MELILLA === EA code
export const selectOrderedCountries = createSelector(selectCountries, countries =>
  sortBy(countries || [], ['name']).filter(i => i.alpha2code !== 'EA'),
)

export const selectSegments = createSelector(
  [selectSaleChannel, selectCustomerSegments],
  (channel, segments) => filterSegments(channel, segments),
)

const mapToSelectedTariffs = (landLine, mainMobile, extraLines) => {
  const selectedLines = { ...extraLines, mainMobile, landLine }
  return Object.keys(selectedLines).map(line => {
    return selectedLines[line]
  })
}

export const selectMainMobileTariffCampaign = createSelector(
  selectMainMobileLineOffer,
  mainMobileOffer => mainMobileOffer.campaign && getDiscountFromCampaign(mainMobileOffer.campaign),
)

export const selectMainMobileTariff = createSelector(
  [selectMainMobileLineOffer, selectMainPackages, selectSaleTax],
  (mainMobile, mainPackages, tax) => {
    const { tariff } = mainMobile
    return {
      ...tariff,
      bundles: formatLineBundles(mainMobile.selectedPackages, 'Promoción'),
      formatedDiscounts: formatDiscounts(tariff, tax),
      discountPrice:
        tariff?.id && tariff?.fees && getFeesWithBundles([tariff], [...mainPackages], tax),
    }
  },
)

export const selectedLandlineProSVAsFormated = createSelector(
  selectedLandlineProSVAs,
  selectSaleTax,
  (landlineProSvas, tax) => formatSVAS(landlineProSvas, tax),
)

export const selectFormatedProSvas = createSelector(
  selectedLandlineProSVAsFormated,
  selectIsPro,
  (proSVAsFormatted, isPro) => formatLineBundles(proSVAsFormatted, 'SVA', isPro) || [],
)

export const selectMainPackagesProSvasWithTax = createSelector(
  selectMainPackages,
  selectedLandlineProSVAsFormated,
  (mainPackages, proSVAsFormated) => applyTaxOnlyProSVAs(mainPackages, proSVAsFormated),
)

export const selectPackagesProSvasWithTax = createSelector(
  selectPackages,
  selectedLandlineProSVAsFormated,
  (packages, proSVAsFormated) => applyTaxOnlyProSVAs(packages, proSVAsFormated),
)

export const selectMainTariffCampaign = createSelector(
  selectLandlineOffer,
  landlineOffer => landlineOffer.campaign && getDiscountFromCampaign(landlineOffer.campaign),
)

export const selectMainTariffCrossSellDiscounts = createSelector(
  selectCrossSellTariffPromo,
  crossSellTariffPromo =>
    crossSellTariffPromo && uniqsDiscountsFromCrossSellPromotion(crossSellTariffPromo),
)

export const selectMainTariff = createSelector(
  [selectLandlineOffer, selectMainMobileLineOffer, selectMainPackagesProSvasWithTax, selectSaleTax],
  (landlineOffer, mainMobile, mainPackages, tax) => {
    const { tariff } = landlineOffer
    return {
      ...tariff,
      bundles: formatLineBundles(mainMobile.selectedPackages, 'Promoción'),
      formatedDiscounts: formatDiscounts(tariff, tax),
      discountPrice: tariff.fees && getFeesWithBundles([tariff], [...mainPackages], tax),
    }
  },
)

export const selectSelectedExtraMobileTariffs = createSelector(
  [selectExtraMobileLinesOffers, selectSaleTax],
  (extraMobile, tax) =>
    extraMobile.map(line => {
      const campaign = line.campaign && getDiscountFromCampaign(line.campaign)
      const prices =
        (campaign?.units &&
          line.tariff?.prices?.map(price => ({
            ...price,
            discounts: [...price.discounts, campaign],
          }))) ||
        line.tariff?.prices
      return {
        ...formatBasicTariff({ ...line.tariff, prices }, tax),
        bundles: formatLineBundles(line.selectedPackages, 'Promoción'),
        formatedDiscounts: formatDiscounts(line.tariff, tax),
        discountPrice:
          line.tariff.id && getFeesWithBundles([line.tariff], line.selectedPackages, tax),
        longDescription: get(line, 'tariff.commercialInformation.description'),
      }
    }),
)

export const selectFormattedMainMobileTariff = createSelector(
  [selectMainMobileTariff, selectSaleTax, selectMainMobileTariffCampaign],
  (tariff, tax, campaign) => {
    const prices =
      (campaign?.units &&
        tariff?.prices?.map(price => ({
          ...price,
          discounts: [...price.discounts, campaign],
        }))) ||
      tariff?.prices
    return formatBasicTariff({ ...tariff, prices }, tax)
  },
)

export const selectFormattedLandlineTariff = createSelector(
  [
    selectMainTariff,
    selectSaleTax,
    selectMainPackagesProSvasWithTax,
    selectMainTariffCampaign,
    selectMainTariffCrossSellDiscounts,
  ],
  (tariff, tax, mainPackages, campaign, crossSellDiscounts) => {
    let { prices } = tariff

    if (tariff.psId && crossSellDiscounts?.length > 0) {
      prices = addDiscountsToTariffPrices(tariff, crossSellDiscounts)
    }

    if (campaign?.units) {
      prices = prices?.map(price => ({
        ...price,
        discounts: [...price.discounts, campaign],
      }))
    }

    const tariffFormatBasic = formatBasicTariff({ ...tariff, prices }, tax)

    return {
      ...tariffFormatBasic,
      discountPrice:
        tariffFormatBasic.fees && getFeesWithBundles([tariffFormatBasic], [...mainPackages], tax),
    }
  },
)

export const selectSelectedTariffs = createSelector(
  [
    selectFormattedLandlineTariff,
    selectFormattedMainMobileTariff,
    selectSelectedExtraMobileTariffs,
  ],
  mapToSelectedTariffs,
)

export const selectMonthlyPayments = createSelector(
  [selectSelectedTariffs, selectPackagesProSvasWithTax, selectSaleTax],
  getFeesWithBundles,
)

// TODO: this selector is bringing info from old tariffs :S
const selectOrderTerminalsConditions = createSelector(
  selectTariffsApigeeSelectedTerminalDevices,
  getOrderTerminalsConditions,
)

// @TODO: Remove this mock with total order real discount
const getDiscountsMock = () => ({
  total: 50,
})

export const selectFiberType = createSelector(selectTechnology, technology =>
  getFiberType(technology),
)

export const selectOrderData = createStructuredSelector({
  terminals: selectOrderTerminalsConditions,
  discounts: getDiscountsMock,
  fiberType: selectFiberType,
})

export const selectErrorCheckout = createSelector(
  selectIsCompany,
  selectAccount,
  selectOrderCheckout,
  selectExtraMobileLinesOffers,
  selectLandlineOffer,
  selectMainMobileLineOffer,
  selectStatusError,
  selectStatusSuccess,
  getCreationStatus,
  selectOrderCheckingFinished,
  selectIsMobileOnly,
  selectIsElFijo,
  mapToErrorCheckout,
)

export const selectSaleParam = createSelector(selectQueryParams, params => params.sale)

export const selectMainOffer = createSelector(
  [selectIsMobileOnly, selectIsElFijo, selectLandlineOffer, selectMainMobileLineOffer],
  (isMobileOnly, isElFijo, landline, mainMobile) =>
    isMobileOnly || isElFijo ? mainMobile : landline,
)

export const selectMainOfferTariff = createSelector(
  [
    selectIsMobileOnly,
    selectIsElFijo,
    selectFormattedLandlineTariff,
    selectFormattedMainMobileTariff,
  ],
  (isMobileOnly, isElFijo, landlineTariff, mobileTariff) =>
    isMobileOnly || isElFijo ? mobileTariff : landlineTariff,
)

// TODO: once sales container is refactored we can pass these through it
// instead of repeating them.

export const selectTerminalAddress = createSelector(
  values => get(values, 'terminalShipping.selectedAddressType'),
  addressType => addressType,
)

export const selectTransactionForAnalytics = createSelector(
  selectTaskId,
  selectMonthlyPayments,
  selectCoverageTechnology,
  selectSelectedTerminals,
  selectSelectedTariffs,
  selectSfid,
  mapToAnalyticsTransactionCompleteEvent,
)

export const selectLinesForLegal = createSelector(
  [
    selectMainOffer,
    selectExtraMobileLinesOffers,
    selectMonthlyPayments,
    selectSelectedTerminalsWithComputedPrices,
  ],
  (mainLine, extraLines, monthlyPayments, terminalsComputed) => {
    const allLines = [mainLine, ...extraLines]

    const products = []
    allLines.forEach(line => {
      const formated = {
        id: line.tariff.id,
        name: line.tariff.name,
        amount: allLines.filter(i => i.tariff.id === line.tariff.id).length,
      }
      if (!products.find(p => p.id === line.tariff.id)) {
        products.push(formated)
      }
    })

    const formatedInfo = {
      products,
      monthlyPayments,
      terminals: terminalsComputed.all,
    }

    return formatedInfo
  },
)

export const selectMainSelectedTariff = createSelector(
  selectIsMobileOnly,
  selectIsElFijo,
  selectFormattedMainMobileTariff,
  selectFormattedLandlineTariff,
  (isMobileOnly, isElFijo, mainMobile, landline) => {
    return isMobileOnly || isElFijo ? mainMobile : landline
  },
)

export const selectFormatedAdditionalTariffs = createSelector(
  selectAdditionalMobileTariffs,
  selectSaleTax,
  (tariffList, tax) => formatMobileTariffs(tariffList, tax),
)

export const selectFormatedMainTariffs = createSelector(
  selectMainLineTariffs,
  selectSaleTax,
  (mainTariffs, tax) => {
    return mapToSortedMobileTariffs(mainTariffs.map(tariff => formatBasicTariff(tariff, tax)))
  },
)

export const selectAdditionalTariffsErrorType = createSelector(
  selectAdditionalMobileTariffs,
  selectAdditionalMobileTariffsIsLoading,
  selectAdditionalMobileTariffsError,
  (tariffs, isLoading, error) => formatTariffError(tariffs, isLoading, error),
)

export const selectMainLineTariffsErrorType = createSelector(
  selectMainLineTariffs,
  selectIsLoadingMainLineTariffs,
  selectMainLineTariffsError,
  (tariffs, isLoading, error) => formatTariffError(tariffs, isLoading, error),
)

export const selectFormatedMobileTariffs = createSelector(
  selectMainMobileTariffs,
  selectSaleTax,
  selectIsMultiSimQueryParam,
  (tariffList, tax, isMultiSim) => formatMobileTariffs(tariffList, tax, isMultiSim),
)

export const selectMobileTariffsErrorType = createSelector(
  selectMainMobileTariffs,
  selectMainMobileTariffsIsLoading,
  selectMainMobileTariffsError,
  (tariffs, isLoading, error) => formatTariffError(tariffs, isLoading, error),
)
export const selectHasIUA = createSelector(selectLandlineOffer, landline => !!landline.IUA)

export const selectHasDeliveryAddress = createSelector(
  selectIsMobileOnly,
  selectIsElFijo,
  selectTechnology,
  selectHasSomeTerminal,
  selectIsConvergent,
  selectIsCrossSellQueryParam,
  (isMobileOnly, isElFijo, technology, hasSomeTerminal, isConvergent, isCrossSell) => {
    if (isMobileOnly || isElFijo) return true
    switch (technology) {
      case FTTH:
      case NEBA:
      case VULA:
        return hasSomeTerminal && !isConvergent && !isCrossSell
      case ADSL:
        return true

      default:
        return false
    }
  },
)

export const selectTaxZipCode = createSelector(
  selectServiceAddress,
  selectBilling,
  selectIsElFijo,
  selectLandlineOffer,
  (service, billing, isElFijo, landline) => {
    const { installationAddress } = landline
    if (billing.selectedAddressType === OTHER_ADDRESS.value) {
      return billing.otherAddress.zipCode
    }
    const DEFAULT_ZIP_CODE = isElFijo
      ? get(service, 'otherAddress.zipCode', '28028')
      : get(installationAddress, 'zipCode', '28028')
    return DEFAULT_ZIP_CODE
  },
)

export const selectIsFiberInstallationRequired = createSelector(
  selectLandlineOffer,
  landlineOffer => {
    const isNewNumber = landlineOffer.signUpType === SIGNUP_TYPE_NEWLINE
    const { operator } = landlineOffer

    return isNewNumber || !operator.internalPortability || operator.installationRequired
  },
)

export const selectInstallation = createSelector(
  selectInstallationsData,
  selectInstallationsSalesFlag,
  selectLandlineOffer,
  selectIsNeba,
  selectIsVula,
  selectIsFiberInstallationRequired,
  (installationData, installationFlag, landline, isNeba, isVula, isFiberInstallationRequired) => {
    const shouldSendInstallationData =
      installationFlag && ((isNeba && !landline.IUA) || isVula) && isFiberInstallationRequired
    return shouldSendInstallationData ? installationData : {}
  },
)

const selectNeedsCarteraAddress = createSelector(
  selectIsCarteraQueryParam,
  selectIsSubscriptionQueryParam,
  selectBilling,
  (isCartera, isSubscription, billing) =>
    (!!isCartera || !!isSubscription) &&
    billing.selectedAddressType === 'otherAddress' &&
    !billing.otherAddress.street,
)

export const selectBillinAddressByType = createSelector(
  selectCoverageData,
  selectBilling,
  selectServiceAddress,
  selectIsMobileOnly,
  selectIsElFijo,
  selectNeedsCarteraAddress,
  (coverage, billing, service, isMobile, isElFijo, needsCarteraAddress) => {
    if (needsCarteraAddress) {
      return { medium: mapToCarteraAddress(billing.otherAddress) }
    }
    if (isElFijo) {
      return mapToOtherAddress(service.otherAddress, billing)
    }
    return mapToOtherAddress(
      coverage,
      isMobile ? { ...billing, selectedAddressType: 'otherAddress' } : billing,
    )
  },
)

export const selectNewPostalAddresss = createSelector(
  selectBillinAddressByType,
  mapToPostalAddressUpdate,
)

export const selectRequiredPos = createSelector(
  selectSelectedExtraMobileTariffs,
  selectedExtras => {
    const required = selectedExtras
      .filter(elem => !isEmpty(elem.requiredPo))
      .map(tariff => tariff.requiredPo)
    return flatMap(required)
  },
)

export const selectSubscriptionInformation = createSelector(
  selectAccount,
  selectSubscriptionId,
  selectSubscriptionStatus,
  ({ documentid, documenttype, segment, companyCif }, id, status) => ({
    docNumber: segment === COMPANY ? companyCif : documentid,
    docType: segment === COMPANY ? CIF : documenttype,
    saleType: status,
    orderId: status !== 'active' ? id : '',
    subscriberId: status === 'active' ? id : '',
  }),
)

export const selectSaleInformation = createSelector(
  selectAccount,
  getBasket,
  ({ documentid, documenttype, segment, companyCif }, basket) => ({
    docNumber: segment === COMPANY ? companyCif : documentid,
    docType: segment === COMPANY ? CIF : documenttype,
    selfEmployed: segment === AUTONOMO,
    segment: basket?.segment,
  }),
)

export const selectNewAppointmentQw = createStructuredSelector({
  hasDeviceDelivery: selectHasSomeTerminal,
  order: selectOrderStatusId,
  docNumber: createSelector(selectSaleInformation, ({ docNumber }) => docNumber),
})

export const selectNewAppointment = createSelector(
  selectSfid,
  selectAccount,
  selectLandlineOffer,
  selectIsPro,
  selectTerritoryOwner,
  selectOrderStatusId,
  selectCoverageData,
  selectTechnology,
  selectFiberInstaller,
  selectHasSomeTerminal,
  selectHasAgileTV,
  (
    sfid,
    account,
    landline,
    { isPro },
    territoryOwner,
    orderId,
    coverage,
    technology,
    installer,
    hasSomeTerminal,
    { hasAgileTV },
  ) => {
    let voipType = 'VOIP'
    let technologyType = 'FTTH'
    if (hasAgileTV) {
      voipType += '__AGILETV'
    }
    if (technology !== technologyType) {
      technologyType += ` ${technology}`
    }

    return {
      category: 'INSTALLATION',
      entregaTerminal: hasSomeTerminal,
      docNumber: get(account, 'documentid'),
      cliEmail: get(account, 'email'),
      cliFirstName: get(account, 'name'),
      cliLastName: get(account, 'surname2'),
      cliMiddleName: get(account, 'surname1'),
      cliPhoneNumber: get(account, 'phoneContact2'),
      sellChannel: 'yoigo',
      sellDate: new Date(),
      seller: 'yoigo',
      workOrderName: 'ALTA',
      scheduledDate: '',
      scheduledTimeStart: '',
      scheduledTimeEnd: '',
      orderStatus: 'PENDIENTE INSTALACION',
      orderType: `Alta ${technologyType} + ${voipType}`,
      contact: {
        type: get(coverage, 'streetType'),
        city: get(coverage, 'town'),
        country: 'España',
        postCode: get(coverage, 'zipCode'),
        number: get(coverage, 'number'),
      },
      ot: `Y${orderId}`,
      cliId: 'CUSTOMER',
      workOrderServices: {
        workOrderServiceFtth: {
          gescal: get(coverage, 'gescal'),
          territoryOwner: get(territoryOwner, 'value'),
          donorOperator: get(landline, 'newNumber') ? '' : get(landline, 'operator.id'),
          connectionInstallationFlow: installer ? `install ${installer}` : '',
        },
      },
      role: SELLER_APPOINTMENT_ROLE,
      user: sfid,
      cliSegment: isPro ? 'pro' : 'residential',
      source: 'ysales',
      tenant: 'yoigo',
    }
  },
)

export const selectHasExtraLines = createSelector(
  selectExtraMobileLinesOffers,
  extraLines => extraLines.length > 0,
)
export const selectSelectedAdditionalLines = createSelector(
  selectExtraMobileLinesOffers,
  extras => {
    const tariffId = extras.map((elem, index) => ({ ...elem, index }))
    const notDuo = tariffId.filter(
      extra =>
        (extra.tariff.tariffType !== 'extra_line' && !!extra.tariff.id) ||
        (extra.tariff.tariffType === 'extra_line' && extra.isNewTerminal === 'yes'),
    )
    return notDuo
  },
)

export const selectAdditionalLinesLength = createSelector(
  selectSelectedAdditionalLines,
  extras => extras.length,
)

export const selectHasReachedAdditionalLinesLimit = createSelector(
  selectAdditionalLinesLength,
  selectCustomerOrderLimit,
  selectIsSubscriptionQueryParam,
  selectIsCrossSellQueryParam,
  (additionalsLength, limit, isSubscription, isCrossSell) => {
    const actualLimit = isSubscription || isCrossSell ? limit : limit - 1
    return additionalsLength >= actualLimit
  },
)

export const selectHasReachedExtraTariffsLimit = createSelector(
  selectSelectedExtraMobileTariffs,
  selectFormatedAdditionalTariffs,
  selectCustomerOrderLimit,
  selectHasReachedAdditionalLinesLimit,
  selectBusyDuoLines,
  selectIsSubscriptionQueryParam,
  selectIsCrossSellQueryParam,
  (
    extraLines,
    extraTariffs,
    limit,
    hasReachedAdditionalLinesLimit,
    busyDuoLines,
    isSubscription,
    isCrossSell,
  ) => {
    const selectedExtraTariffs =
      extraLines.filter(tariff => tariff.tariffType === 'extra_line').length + busyDuoLines

    const extraLineTariffsLength = extraTariffs
      .filter(tariff => tariff.tariffType === 'extra_line')
      .map(tariff => tariff.cardinality.max || 0)
      .reduce((curr, next) => curr + next, 0)

    return (
      (limit === 1 && !isSubscription && !isCrossSell) ||
      (hasReachedAdditionalLinesLimit && selectedExtraTariffs === extraLineTariffsLength)
    )
  },
)

export const selectShowConfirm = createSelector(
  selectIsConvergent,
  selectLandlineOffer,
  selectMainMobileLineOffer,
  (isConvergent, landline, mobileLine) => {
    const hasNewLandline = landline.signUpType === SIGNUP_TYPE_NEWLINE
    const hasPortaMobile = mobileLine.signUpType !== SIGNUP_TYPE_NEWLINE

    return !!isConvergent && hasNewLandline && hasPortaMobile
  },
)

export const selectBillingCarteraAddress = createSelector(
  selectCustomerAddress,
  selectIsCustomerCanceled,
  (address, isCanceled) => (!isCanceled ? address : {}),
)

export const selectIs2pPure = createSelector(selectLandlineOffer, landLine => {
  const tariffType = get(landLine, 'tariff.tariffType')
  return tariffType === CATEGORY_2P_PURE
})

export const selectIsExistingClientSaleFlow = createSelector(
  selectIsCarteraQueryParam,
  selectIsSubscriptionQueryParam,
  selectIsCrossSellQueryParam,
  selectIsMultiSimQueryParam,
  (isCartera, isSubscription, isCrossSell, isMultiSim) =>
    isCartera || isSubscription || isCrossSell || isMultiSim,
)

const selectNumbers = createSelector(
  selectTelephoneNumbers,
  selectTelephoneNumbersCustomer,
  (numbers, customerNumbers) =>
    [...numbers, ...customerNumbers].reduce((cur, next) => {
      if (!find(cur, { medium: { number: next?.medium?.number } })) {
        return [...cur, next]
      }
      return cur
    }, []),
)

export const selectPhoneNumbers = createSelector(selectNumbers, numbers => {
  const { mainNumber, secondNumber } = customer360Helpers.getPhoneContactByPreference(numbers)

  return {
    mainNumber: mainNumber?.number,
    secondNumber: secondNumber?.number,
  }
})

export const selectWelcomeLetterData = createSelector(
  [
    selectTaxZipCode,
    selectMainMobileTariffName,
    selectIsPro,
    selectIs2p,
    selectIsElFijo,
    selectLandlineOffer,
    selectExtraMobileLinesOffers,
    selectAccount,
    selectIsMultiSimQueryParam,
  ],
  (
    taxZipCode,
    tariffName,
    isPro,
    is2p,
    isElFijo,
    landlineOffer,
    extraMobileOffers,
    account,
    isMultiSim,
  ) => {
    if (!is2p && !isElFijo && !isMultiSim) {
      return {}
    }
    let welcomeLetterData = {
      taxZipCode,
      tariffName,
    }

    if (is2p) {
      const isPortability = !(landlineOffer.signUpType === SIGNUP_TYPE_NEWLINE)

      welcomeLetterData = {
        ...welcomeLetterData,
        tariffName: landlineOffer.tariff.name,
        tariffType: ONLY_FIXED,
        segment: isPro ? 'pro' : 'residential',
        price: landlineOffer.tariff.priceWithTax?.toFixed(2),
        fixedLineNumber: isPortability ? landlineOffer.lineNumber : landlineOffer.newFixedNumber,

        clientName: `${account.name}`,
        paymentType: POSTPAID,
      }
    }

    if (isElFijo) {
      welcomeLetterData = {
        ...welcomeLetterData,
        tariffType: FIXED_MOBILE,
      }
    }

    if (isMultiSim) {
      welcomeLetterData = {
        ...welcomeLetterData,
        tariffType: MULTISIM,
        segment: isPro ? 'pro' : 'residential',
        price: extraMobileOffers[0].tariff.priceWithTax?.toFixed(2),
        mobileNumber: extraMobileOffers[0].newNumber.value,

        clientName: `${account.name}`,
        paymentType: POSTPAID,
      }
    }

    return welcomeLetterData
  },
)

export const selectBroadbandType = createSelector([selectIsAdsl], isAdsl =>
  isAdsl ? BROADBAND_TYPE_ADSL : BROADBAND_TYPE_FIBRA,
)

export const selectRouterName = createSelector([selectBroadbandType], broadbandType =>
  broadbandType === BROADBAND_TYPE_ADSL ? ROUTER_NAME_ADSL : ROUTER_NAME_FIBRA,
)

export const selectFfthRouterNoReturnalPenalty = createSelector([selectSaleTax], saleTax =>
  getPriceWithTax(
    {
      taxFreeAmount: FFTH_ROUTER_PENALTY_TAX_FREE,
      taxRate: saleTax,
    },
    saleTax,
  ),
)

export const selectAdslRouterNoReturnalPenalty = createSelector([selectSaleTax], saleTax =>
  getPriceWithTax(
    {
      taxFreeAmount: ADSL_ROUTER_PENALTY_TAX_FREE,
      taxRate: saleTax,
    },
    saleTax,
  ),
)

export const selectRouterPenaltyWithTaxes = createSelector(
  [selectBroadbandType, selectFfthRouterNoReturnalPenalty, selectAdslRouterNoReturnalPenalty],
  (broadbandType, ffthRouterPenalty, adslRouterPenalty) =>
    broadbandType === BROADBAND_TYPE_FIBRA ? ffthRouterPenalty : adslRouterPenalty,
)
