import * as documentTypes from '../constants'

export function isValidMSISDN(value) {
  return new RegExp('^[6|7][0-9]{8}$').test(value.toUpperCase())
}

export function isValidCLI(value) {
  return new RegExp('^9|^8[0-9]{8}$').test(value.toUpperCase())
}

export const isValidNIF = value => {
  const nifRexp = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKE]$/i
  if (!value.match(nifRexp)) {
    return false
  }
  const dni_letters = 'TRWAGMYFPDXBNJZSQVHLCKE'
  const letter = dni_letters.charAt(parseInt(value, 10) % 23)
  return letter === value.charAt(8)
}

export const isValidNIE = NIE => {
  const nieRexp = /^[XYZ]{1}[0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i
  if (!NIE.match(nieRexp)) {
    return false
  }
  let nie_prefix = NIE.charAt(0)
  switch (nie_prefix) {
    case 'X':
      nie_prefix = '0'
      break
    case 'Y':
      nie_prefix = '1'
      break
    case 'Z':
      nie_prefix = '2'
      break
    default:
      return false
  }
  return isValidNIF(nie_prefix + NIE.substr(1))
}

export const isValidCIF = cif => {
  if (!cif || cif.length !== 9) {
    return false
  }
  const letters = ['J', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I']
  const digits = cif.substr(1, cif.length - 2)
  const letter = cif.substr(0, 1)
  const control = cif.substr(cif.length - 1)
  let sum = 0
  let i
  let digit

  if (!letter.match(/[A-Z]/)) {
    return false
  }

  for (i = 0; i < digits.length; i += 1) {
    digit = parseInt(digits[i], 10)

    if (Number.isNaN(digit)) {
      return false
    }
    if (i % 2 === 0) {
      digit *= 2
      if (digit > 9) {
        digit = (parseInt((digit / 10).toString(), 10) + digit) % 10
      }
      sum += digit
    } else {
      sum += digit
    }
  }
  sum %= 10
  if (sum !== 0) {
    digit = 10 - sum
  } else {
    digit = sum
  }
  if (letter.match(/[ABEH]/)) {
    return String(digit) === control
  }
  if (letter.match(/[NPQRSW]/)) {
    return letters[digit] === control
  }

  return String(digit) === control || letters[digit] === control
}

export const isValidPassport = value => {
  const regex = /^(?!^0+$)[a-zA-Z0-9]{6,9}$/g
  return regex.test(value)
}

/**
 * Recognize dni type
 *
 * @param value {String}
 * @returns {NIF|CIF|NIE|CLI|MSISDN} document type
 */
export function getDocumentType(value = '') {
  if (isValidNIF(value)) return documentTypes.NIF
  if (isValidNIE(value)) return documentTypes.NIE
  if (isValidCIF(value)) return documentTypes.CIF
  if (isValidMSISDN(value)) return documentTypes.MSISDN
  if (isValidCLI(value)) return documentTypes.CLI

  return null
}

export function isValidDocumentByType(document = '', type) {
  switch (type) {
    case documentTypes.NIF:
      return isValidNIF(document.toUpperCase())
    case documentTypes.NIE:
      return isValidNIE(document.toUpperCase())
    case documentTypes.CIF:
      return isValidCIF(document.toUpperCase())
    case documentTypes.MSISDN:
      return isValidMSISDN(document.toUpperCase())
    case documentTypes.CLI:
      return isValidCLI(document.toUpperCase())
    case documentTypes.PASSPORT:
      return isValidPassport(document.toUpperCase())
    default:
      return false
  }
}
