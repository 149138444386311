import {
  INSTALLATION_ADDRESS,
  OTHER_ADDRESS,
  DELIVERY_POINT,
  BILLING_ADDRESS,
  YOIGO_STORE,
} from 'modules/SharedSales/constants'
import { get } from 'lodash'
import { formatOtherFullAddress } from 'modules/Coverage/helpers'

import { getPriceWithTax } from 'modules/Taxes/helpers.js'
import { formatNumberWithComma } from 'utils'

const formatDeliveryPoint = ({ zipCode, selectedPoint }) =>
  selectedPoint ? `En tienda (${get(selectedPoint, 'attributes.address')}, ${zipCode})` : ''

export const mapToSummaryAddress = (
  { selectedAddressType, otherAddress, deliveryPoint },
  installationFullAddress,
  billingFullAddress,
  isMobileOnly,
) => {
  switch (selectedAddressType) {
    case INSTALLATION_ADDRESS.value:
      return `${installationFullAddress} (la misma)`

    case OTHER_ADDRESS.value:
      return formatOtherFullAddress(otherAddress)

    case YOIGO_STORE.value:
    case DELIVERY_POINT.value:
      return formatDeliveryPoint(deliveryPoint)

    case BILLING_ADDRESS.value:
    default:
      return `${isMobileOnly ? billingFullAddress : installationFullAddress} (la mísma)`
  }
}

export const getFormattedPriceWithTaxes = (taxFreeAmount, saleTax) =>
  formatNumberWithComma(
    getPriceWithTax(
      {
        taxFreeAmount,
        taxRate: saleTax,
      },
      saleTax,
    ),
  )

export const mapLanguageToCode = lang => {
  switch (lang) {
    case 'spa':
      return 'es-ES'
    case 'eus':
      return 'eu-ES'
    default:
      return 'en-EN'
  }
}
