import {
  startOfDay,
  endOfDay,
  min,
  max,
  differenceInDays,
  isValid,
  isBefore,
  isEqual,
} from 'date-fns'

import { getDurationFromRange } from '../durations'

/**
 * Normalize an interval of dates:
 * - start = startOfDay(start)
 * - end = endOfDay(end)
 *
 * @param {{start: date, end: date}}
 * @return {{start: date, end: date}}
 */
export function getNormInterval({ start, end }) {
  return {
    start: startOfDay(start),
    end: endOfDay(end),
  }
}

export function lengthInDaysOfNomInterval(interval) {
  const normInterval = getNormInterval(interval)

  return Math.max(differenceInDays(normInterval.end, normInterval.start) + 1, 0)
}

/**
 * Returns the intersection of two intervals of dates
 * - start = startOfDay(start)
 * - end = endOfDay(end)
 *
 * @param {{start: date, end: date}} interval1
 * @param {{start: date, end: date}} interval2
 * @return {{start: date, end: date}}
 */
export function intersection(interval1, interval2) {
  return {
    start: max([interval1.start, interval2.start]),
    end: min([interval1.end, interval2.end]),
  }
}

export const getCurrentDate = () => {
  const date = new Date()
  return `${date.getUTCFullYear()}${date.getUTCMonth() +
    1}${date.getUTCDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`
}

export const formatDurationRange = (endDate, startDate) => {
  const duration = getDurationFromRange(endDate, startDate)

  if (duration) {
    let range = ''
    if (duration.days) range = `${duration.days} días`
    if (duration.months) range = `${duration.months} meses, ${range}`
    if (duration.years) range = `${duration.years} año, ${range}`
    // NOTE: Remove trailing comma
    return range.endsWith(', ') ? range.slice(0, -2) : range
  }

  return ''
}

export const getCurrentDateFormatted = () => {
  const date = new Date()
  return {
    formatedDate: `${date.getUTCDate()}/${date.getUTCMonth() + 1}/${date.getUTCFullYear()}`,
    hours: date.getHours(),
    minutes: date.getMinutes(),
  }
}

export const isValidInterval = (start, end) =>
  isValid(new Date(start)) &&
  isValid(new Date(end)) &&
  (isBefore(new Date(start), new Date(end)) || isEqual(new Date(start), new Date(end)))
