import { axiosJWT } from 'modules/axios'
import { CONFIG } from './environments'

export function createTypification(documentId, typification) {
  return axiosJWT({
    method: 'POST',
    url: `${CONFIG.api.typifications}/${documentId}`,
    data: typification,
  }).catch(e => {
    e.response.data.error_message = e.response.data.info
    throw e
  })
}
