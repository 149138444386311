import { put, select, take } from 'redux-saga/effects'

import { selectCustomerId } from 'modules/Auth'
import actions from './actions'
import * as selectors from './selectors'
import * as constants from './constants'

export function* callSaga() {
  yield put(actions.findSubscriptions())
  yield take([constants.FIND_SUBSCRIPTIONS_SUCCEEDED, constants.FIND_SUBSCRIPTIONS_FAILED])
  return yield select(selectors.getErrorCode)
}

export function* callSubscriptionByMsisdnSaga(msisdn) {
  yield put(actions.findSubscriptionByMsisdn(msisdn))
  yield take(
    action =>
      (action.type === constants.FIND_SUBSCRIPTION_BY_ID_SUCCEEDED ||
        action.type === constants.FIND_SUBSCRIPTION_BY_ID_FAILED) &&
      action.payload.msisdn === msisdn,
  )
  return yield select(selectors.getSubscriptionByMsisdnErrorCode, msisdn)
}

export function* callChangeTariffSaga(msisdn, tariffId, options, v3 = false) {
  yield put(actions.changeTariff(msisdn, tariffId, options, v3))
  yield take(
    action =>
      (action.type === constants.CHANGE_TARIFF_SUCCEEDED ||
        action.type === constants.CHANGE_TARIFF_FAILED) &&
      action.payload.msisdn === msisdn,
  )

  return yield select(selectors.getTariffChangeErrorMsg, msisdn)
}

export function* callSetBundlesSaga(msisdn, bundleId, bundleStatus) {
  yield put(actions.setBundles(msisdn, bundleId, bundleStatus))
  yield take(
    action =>
      (action.type === constants.SET_BUNDLES_SUCCEEDED ||
        action.type === constants.SET_BUNDLES_FAILED) &&
      action.payload.msisdn === msisdn,
  )
  return yield select(selectors.selectSetBundlesErrorMsg(msisdn))
}

export function* callSetUpsellBundlesSaga(msisdn, bundleId) {
  const customerId = yield select(selectCustomerId)
  yield put(actions.setUpsellBundles(msisdn, bundleId, customerId))
  yield take(
    action =>
      (action.type === constants.SET_UPSELL_BUNDLES_SUCCEEDED ||
        action.type === constants.SET_UPSELL_BUNDLES_FAILED) &&
      action.payload.msisdn === msisdn,
  )
  return yield select(selectors.getSetUpsellBundlesErrorMsg, msisdn)
}
