import styled from 'styled-components'
import PropTypes from 'prop-types'
import Card from '../card/Card'

const CardStyled = styled(Card)`
  position: relative;
  transform: perspective(1000px) rotateY(0deg);
  transition: transform 0.3s linear;
  will-change: transform;
  transform-style: preserve-3d;
  overflow: hidden;
  &.is-rotated {
    transform: perspective(1000px) rotateY(180deg);
    .offer-header,
    .offer-footer {
      transform: rotateY(180deg);
    }
    .offer-card-front {
      pointer-events: none;
    }
    .offer-card-back {
      opacity: 1;
      pointer-events: auto;
    }
  }
  .offer-card-back {
    opacity: 0;
    transition: opacity 0s 0.15s;
    pointer-events: none;
    will-change: opacity;
  }
  .offer-header,
  .offer-footer {
    transform: rotateY(0);
    transition: transform 0s 0.15s;
    will-change: transform;
  }
`

const OffersCardPortout = props => (
  <CardStyled className={props.isRotated ? 'is-rotated' : null} padding="0" margin="0" {...props} />
)

OffersCardPortout.defaultProps = {
  isRotated: false,
}

OffersCardPortout.propTypes = {
  children: PropTypes.node,
  width: PropTypes.string,
  isRotated: PropTypes.bool.isRequired,
}

export default OffersCardPortout
