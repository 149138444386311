import FormLabel from '@material-ui/core/FormLabel'
import { withStyles } from '@material-ui/core/styles'

const styles = {
  root: {
    display: 'flex',
    width: 'auto',
    background: 'transparent',
    'font-family': 'Roboto',
    'font-size': '16px',
    'font-weight': '500',
    'font-style': 'normal',
    'font-stretch': 'normal',
    'line-height': 'normal',
    'letter-spacing': 'normal',
    color: '#646464',
    'padding-right': '20px;',
  },
  error: {
    display: 'flex',
    background: 'transparent',
    'font-family': 'Roboto',
    'font-size': '16px',
    'font-weight': '500',
    color: '#ff5c1e',
  },
}
function CustomFormLegend(props) {
  const { children, error, classes, ...other } = props
  return (
    <FormLabel className={error ? classes.error : classes.root} component="legend" {...other}>
      {children}
    </FormLabel>
  )
}
export default withStyles(styles)(CustomFormLegend)
