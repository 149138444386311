import { useState } from 'react'
import PropTypes from 'prop-types'

import { isEmpty } from 'lodash'

import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { Box, Typography, Icon } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import { ICONS } from 'utils/icons'
import { vista360Token } from 'modules/Coverage/constants'
import { CoverageForm } from 'modules/Coverage/components/CoverageForm'
import { Modal, Button } from 'components/ui'
import { selectAddressInfo } from 'modules/Coverage'
import { OutlinedIconButton } from 'modules/ui/components/OutlinedIconButton/OutlinedIconButon'

const useStyles = makeStyles(theme => ({
  'coverage-modal': {
    '& .coverage-button': {
      padding: 0,
      minWidth: '38px',
      height: '35px',
      border: `1px solid ${theme.palette.global.gray}`,
      borderRadius: '4px',
      boxShadow: 'none',

      '& .icon, & svg': {
        margin: 0,
      },
    },

    '& .coverage-button:hover': {
      backgroundColor: 'transparent',
    },

    '& .white-coverage-modal-button': {
      backgroundColor: theme.palette.common.white,
      '& .icon': {
        margin: 0,
        color: theme.palette.bluePalette.main,
      },
    },

    '& .blue-coverage-modal-button': {
      backgroundColor: theme.palette.bluePalette.main,
      '& .icon': {
        margin: 0,
        color: theme.palette.common.white,
      },
    },
  },
}))

export const CoverageModal = ({ gotCoverageData }) => {
  const classes = useStyles()

  const [openCoverageModal, setOpenCoverageModal] = useState(false)
  const installationAddress = useSelector(selectAddressInfo)
  const location = useLocation()

  return (
    <div className={classes['coverage-modal']}>
      <OutlinedIconButton
        onClick={() => setOpenCoverageModal(true)}
        data-hook="coverage-modal-button">
        <Icon color="primary">{ICONS.addressPoint}</Icon>
      </OutlinedIconButton>

      <Modal
        data-hook="coverage-modal"
        isOpen={openCoverageModal}
        title="Buscador de cobertura"
        onClose={() => setOpenCoverageModal(false)}>
        {isEmpty(installationAddress.gescal) && (
          <Box p="25px 0">
            <Typography variant="h5" style={{ fontWeight: 400 }} className="modal-subtitle">
              Dirección nueva
            </Typography>
          </Box>
        )}
        <Box height="400px">
          <CoverageForm
            name="landlineOffer.installationAddress.gescal"
            className="coverage-360"
            installationAddress={installationAddress}
            coverageToken={vista360Token}
            onCoverageCheckFinished={gotCoverageData}
            reset={gotCoverageData}
            applicationId="360"
          />
        </Box>
        <Button to={`/add-client${location.search}`}>Ir a portal de venta</Button>
      </Modal>
    </div>
  )
}

CoverageModal.propTypes = {
  gotCoverageData: PropTypes.func,
}
