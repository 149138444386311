import PropTypes from 'prop-types'

import { Typography } from '@material-ui/core'

import { LINE_TYPE } from 'modules/Lines'
import { SIGNUP_TYPE_PORTABILITY } from 'services/global-constants'
import { PORTABILITY_TYPE_PREPAID } from 'modules/SharedSales/constants'
import { PackagesSummary } from 'modules/Packages'
import { TerminalSummary } from 'modules/Terminals'
import { SVASummary } from 'modules/SVAs/components/SVASummaryComponent/SVASummaryComponent'

export function MobileLineSummary({
  title,
  signUpTypeText,
  mobileOffer,
  userName,
  terminal,
  lineType,
  isElFijo,
  isCrossSell,
  selectedSVA = {},
  selectedProSvas = [],
  isPro,
  isMobileOnly = false,
  isMultiSim,
}) {
  const newNumber = isElFijo ? mobileOffer.newFixedNumber : mobileOffer.newNumber.value

  return (
    <>
      {!isMultiSim && (
        <div>
          <Typography
            variant="subtitle2"
            color="secondary"
            className="bold"
            data-hook={`${lineType}.offer-title`}>
            {title} {isCrossSell && lineType === LINE_TYPE.MAIN_MOBILE && mobileOffer.lineNumber}
          </Typography>
        </div>
      )}

      {(!isCrossSell || lineType !== LINE_TYPE.MAIN_MOBILE) && (
        <>
          <div className="m-y-16 flex">
            <div className="flex align-start-center p-r-36">
              <Typography variant="body1" className="p-r-4">
                Tipo:
              </Typography>
              <Typography variant="body1" className="bold" data-hook={`${lineType}.signUpType`}>
                {signUpTypeText}
              </Typography>
            </div>
            <div className="flex align-start-center p-r-36">
              <Typography variant="body1" className="p-r-4">
                Número:
              </Typography>
              <Typography variant="body1" className="bold" data-hook={`${lineType}.number`}>
                {mobileOffer.signUpType === SIGNUP_TYPE_PORTABILITY
                  ? mobileOffer.lineNumber
                  : newNumber}
              </Typography>
            </div>
            {isElFijo && (
              <div className="flex align-start-center p-r-36">
                <Typography variant="body1" className="p-r-4">
                  Nº Móvil asignado:
                </Typography>
                <Typography variant="body1" className="bold" data-hook={`${lineType}.newNumber`}>
                  {mobileOffer.newNumber.value}
                </Typography>
              </div>
            )}
            {mobileOffer.iccNumber &&
              (mobileOffer.portabilityType === PORTABILITY_TYPE_PREPAID || isMultiSim) && (
                <div className="flex align-start-center p-r-36">
                  <Typography variant="body1" className="p-r-4">
                    ICC {isMultiSim ? '' : '(prepago)'}:{' '}
                  </Typography>
                  <Typography
                    variant="body1"
                    className="bold"
                    data-hook={`${mobileOffer}.iccNumber`}>
                    {mobileOffer.iccNumber}
                  </Typography>
                </div>
              )}
          </div>
          {mobileOffer.signUpType === SIGNUP_TYPE_PORTABILITY && (
            <div className="m-b-16 flex align-start-center p-r-36">
              <Typography variant="body1" className="p-r-4">
                Titular: {'  '}
              </Typography>
              <Typography variant="body1" className="bold" data-hook={`${lineType}.userName`}>
                {userName}
              </Typography>
            </div>
          )}

          {isMobileOnly && (
            <SVASummary selectedSVA={selectedSVA} selectedProSvas={selectedProSvas} isPro={isPro} />
          )}

          {mobileOffer.selectedPackages &&
            mobileOffer.selectedPackages.map(item => (
              <div className="flex align-start-center" key={`${mobileOffer}.${item.name}`}>
                <PackagesSummary
                  {...item}
                  monthlyFee={item.monthlyFeeWithTax}
                  lineType={lineType}
                />
              </div>
            ))}
          {terminal && terminal.id && <TerminalSummary lineType={lineType} terminal={terminal} />}
        </>
      )}
    </>
  )
}

MobileLineSummary.propTypes = {
  title: PropTypes.string.isRequired,
  mobileOffer: PropTypes.shape({
    signUpType: PropTypes.string.isRequired,
    lineNumber: PropTypes.string.isRequired,
    iccNumber: PropTypes.string,
    portabilityType: PropTypes.string,
    selectedPackages: PropTypes.array,
    newFixedNumber: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    newNumber: PropTypes.object,
  }),
  userName: PropTypes.string.isRequired,
  lineType: PropTypes.string.isRequired,
  terminal: PropTypes.any,
  signUpTypeText: PropTypes.string.isRequired,
  isCrossSell: PropTypes.bool,
  isElFijo: PropTypes.bool,
  selectedSVA: PropTypes.object,
  selectedProSvas: PropTypes.array,
  isPro: PropTypes.bool,
  isMobileOnly: PropTypes.bool,
  isMultiSim: PropTypes.bool,
}
