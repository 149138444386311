export const MSG_TYPES = {
  STD: 0,
  TRANSFER: 1,
}

export const CLOSING_REASONS = {
  MANUAL_CLOSE: {
    id: 'MANUAL_CLOSE',
    desc: 'Manual',
  },
  BRAND_AUTO_CLOSE: {
    id: 'BRAND_AUTO_CLOSE',
    desc: 'Automático',
  },
  OTHER: {
    id: 'OTHER',
    desc: 'Motivo de cierre no dado',
  },
}
