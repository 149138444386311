import { flatten, get, isEmpty, head } from 'lodash'
import { formatPackagesName } from 'modules/Packages/helpers'
import {
  selectQueryParams,
  selectChannelQueryParam,
  selectIsSubscriptionQueryParam,
  selectIsCrossSellQueryParam,
  selectIsCarteraQueryParam,
  selectIsMultiSimQueryParam,
} from 'modules/Router'
import { selectCoverageFullAddress, selectAddressInfo } from 'modules/Coverage'
import { formatOtherFullAddress } from 'modules/Coverage/helpers/index'

import { selectIsElFijoSaleType, selectIsMobileOnly } from 'modules/Router/store/index'
import {
  PORTABILITY_TYPE_PREPAID,
  YOIGO_STORE,
  ONLY_MOBILE,
  FIXED_MOBILE,
  BILLING_ADDRESS,
  INSTALLATION_ADDRESS,
} from 'modules/SharedSales/constants'
import { LINE_TYPE } from 'modules/Lines/constants'
import { DEVICE_TV } from 'modules/NewClientSales/constants'

import { createSelector } from 'reselect'
import { formatSingleSVA } from 'modules/SVAs/helpers'
import {
  SEVERAL,
  QUOTA25,
  COMPANY,
  POS,
  POS_PRO,
  SIGNUP_TYPE_PORTABILITY,
  SIGNUP_TYPE_NEWLINE,
} from 'services/global-constants'
import { SVA_HOMEGO_CODE } from 'modules/offers-configuration/constants'

import { OTHER_ADDRESS } from 'modules/SharedSales/constants/shippingAddress'
import { selectSubscriptionChannel } from 'services/customer-360/selectors'
import { getTaxes } from 'services/taxes'
import { BasketFilled } from '../../mocks/basketFilled'

// Main getter
export const getBasket = state => get(state, 'basket')

// Top level selectors
export const selectReferenceNumber = createSelector(getBasket, basket =>
  get(basket, 'referenceNumber'),
)
export const selectAccount = createSelector(getBasket, basket => get(basket, 'account', {}))

export const selectBilling = createSelector(getBasket, basket => get(basket, 'billing', {}))

export const selectLandlineOffer = createSelector(getBasket, basket =>
  get(basket, 'landlineOffer', {}),
)

export const selectMainMobileLineOffer = createSelector(getBasket, basket =>
  get(basket, LINE_TYPE.MAIN_MOBILE),
)

export const selectStockOffer = createSelector(getBasket, basket => get(basket, 'stock'))

export const selectExtraMobileLinesOffers = createSelector(getBasket, basket =>
  get(basket, 'extraMobileLinesOffers', []),
)

export const selectShipping = createSelector(getBasket, basket => get(basket, 'shipping', {}))

export const selectServiceAddress = createSelector(getBasket, basket =>
  get(basket, 'serviceAddress', {}),
)

export const selectOrderCheckout = createSelector(getBasket, basket => get(basket, 'order', {}))

// Selected Billing Selectors

export const selectIBAN = createSelector(selectBilling, billing =>
  get(billing, 'ibanNumber', '').replace(/ /g, ''),
)

// Selected Offer selectors

export const selectLandlineTariffId = createSelector(selectLandlineOffer, offer =>
  get(offer, 'tariff.id', ''),
)

export const selectMobilelineTariffId = createSelector(selectMainMobileLineOffer, offer =>
  get(offer, 'tariff.id', ''),
)

export const selectMainlineTariffId = createSelector(
  [selectLandlineTariffId, selectMobilelineTariffId],
  (landlineTariffId, mainMobileLineTariffId) => landlineTariffId || mainMobileLineTariffId,
)

export const selectExtraMobileLinesOfferIds = createSelector(selectExtraMobileLinesOffers, offers =>
  offers.map(offer => offer.tariff.id),
)

export const selectBasket = createSelector([getBasket, selectQueryParams], (basket, { fillSale }) =>
  fillSale ? BasketFilled : basket,
)

export const selectBasketAndOrder = createSelector(
  [
    getBasket,
    selectIsSubscriptionQueryParam,
    selectIsCrossSellQueryParam,
    selectIsMultiSimQueryParam,
  ],
  ({ order, ...basket }, isSubscription, isCrossSell, isMultiSim) => {
    let { orderType } = basket

    if (isSubscription) {
      orderType = 'upsell'
    }

    if (isCrossSell) {
      orderType = 'crosssell'
    }

    if (isMultiSim) {
      orderType = 'multiSim'
    }

    return {
      basket: { ...basket, orderType },
      order,
    }
  },
)

export const selectVerbalId = createSelector(
  [selectBasket, selectLandlineOffer, selectMainMobileLineOffer],
  ({ orderType }, landlineOffer, mainMobileLineOffer) => {
    const { newFixedNumber, newNumber, signUpType, lineNumber } =
      orderType === ONLY_MOBILE || orderType === FIXED_MOBILE ? mainMobileLineOffer : landlineOffer

    return signUpType === SIGNUP_TYPE_NEWLINE ? newFixedNumber || newNumber?.value : lineNumber
  },
)

export const selectTerminalsShipping = createSelector(getBasket, basket =>
  get(basket, 'terminalShipping'),
)

export const selectIsYoigoStoreShipping = createSelector(
  selectTerminalsShipping,
  ({ selectedAddressType }) => selectedAddressType === YOIGO_STORE.value,
)

// Mobile Details Selected Selector
// TODO: remove this selector
export const selectedAddressType = createSelector(
  selectBilling,
  billing => billing.selectedAddressType,
)

export const selectZipCodeOtherAddress = createSelector(selectBilling, billing =>
  get(billing.otherAddress, 'zipCode'),
)

// Terminal can have a object without values, sometimes, filter by id
export const selectMainMobileLineTerminal = createSelector(selectMainMobileLineOffer, offer =>
  offer && offer.terminal && offer.terminal.id ? offer.terminal : null,
)

export const selectMemberGetMemberPromo = createSelector(
  selectMainMobileLineOffer,
  offer => offer.memberGetMemberPromo,
)

export const selectCrossSellTariffPromo = createSelector(
  selectLandlineOffer,
  offer => offer.crossSellTariffPromo,
)

export const selectHasSmartTv = createSelector(
  selectMainMobileLineTerminal,
  terminal => terminal?.category === DEVICE_TV,
)

export const selectOffersTerminals = createSelector(
  [selectMainMobileLineTerminal, selectExtraMobileLinesOffers],
  (terminal, extra) => {
    const additional = extra.map((line, idx) => {
      const extraTerminal = get(line, 'terminal')
      return (
        extraTerminal && {
          ...extraTerminal,
          terminalPaymentOrderId: terminal ? idx + 1 : idx,
          isMain: false,
          lineType: `extraMobileLinesOffers[${idx}]`,
        }
      )
    })
    return {
      main: terminal,
      additional,
      all: terminal
        ? [
            {
              ...terminal,
              terminalPaymentOrderId: 0,
              isMain: true,
              lineType: LINE_TYPE.MAIN_MOBILE,
            },
            ...additional,
          ]
        : [...additional],
    }
  },
)

export const selectSelectedTerminals = createSelector([selectOffersTerminals], offersTerminals => ({
  ...offersTerminals,
  all: offersTerminals.all.filter(elem => elem && elem.id),
  additional: offersTerminals.additional.filter(elem => elem && elem.id),
}))

export const selectAllSelectedTerminals = createSelector(
  selectSelectedTerminals,
  terminals => terminals.all,
)

export const selectTerminalPaymentInfo = createSelector(selectOffersTerminals, terminals => {
  const allLinesTerminals = [terminals.main, ...terminals.additional]
  return allLinesTerminals.map(terminal =>
    !isEmpty(get(terminal, 'paymentResult')) ? get(terminal, 'paymentResult') : { isFree: true },
  )
})

export const selectHasSomeTerminal = createSelector(
  selectAllSelectedTerminals,
  terminals => terminals.length > 0,
)

export const selectHasSomeFinancedTerminal = createSelector(selectAllSelectedTerminals, terminals =>
  terminals.find(terminal => terminal.paymentType === SEVERAL || terminal.paymentType === QUOTA25),
)

// once we can remove the flag we can move this selector to router module

export const selectIsElFijo = createSelector(selectIsElFijoSaleType, saleType => saleType)

export const selectSaleChannel = createSelector(
  getBasket,
  selectQueryParams,
  selectSubscriptionChannel,
  (basket, { channel = '', isSubscription, isMultiSim }, subscriptionChannel) => {
    if (isSubscription) {
      return channel
    }

    const [channelType] = channel.split('_')

    if (isMultiSim) {
      return subscriptionChannel
    }

    const saleSegment = get(basket, 'segment')
    return saleSegment === 'residential' ? channelType : `${channelType}_${saleSegment}`
  },
)

export const selectZipCodeBilling = createSelector(
  [
    selectAddressInfo,
    selectedAddressType,
    selectZipCodeOtherAddress,
    selectServiceAddress,
    selectIsElFijo,
  ],
  (installationAddress, addressType, otherAddressZipCode, serviceAddress, isElFijo) => {
    const mainAddressZipCode = isElFijo
      ? get(serviceAddress, 'otherAddress.zipCode')
      : installationAddress.postalCode
    return addressType === 'otherAddress' ? otherAddressZipCode : mainAddressZipCode
  },
)

export const selectTax = createSelector([selectZipCodeBilling, getTaxes], (zipCode, taxes) => {
  return 1 + parseInt(get(taxes, `data.${zipCode}`, 21), 10) / 100
})

export const selectBaksetSaleTax = createSelector(
  selectTax,
  selectSaleChannel,
  (tax, channelParam) => {
    return channelParam.includes('pro') ? 1 : tax
  },
)

// @TODO: this should select only the extra lines, we need to check the behavior
export const selectMainPackages = createSelector(
  [getBasket, selectBaksetSaleTax],
  (basket, tax) => {
    const mainBundles = get(basket[LINE_TYPE.MAIN_MOBILE], 'selectedPackages', [])
    const agileTv = formatSingleSVA(get(basket.landlineOffer, 'SVA', {}), tax)
    const proSva = get(basket.landlineOffer, 'proSVAs', [])
    const groupedSVAS = agileTv.poId ? [agileTv, ...proSva] : proSva
    return isEmpty(groupedSVAS) ? mainBundles : [...mainBundles, ...groupedSVAS]
  },
)

export const selectSVAAgileTv = createSelector(selectLandlineOffer, landline =>
  get(landline, 'SVA', {}),
)

export const selectSVAAppleTvWithPsId = psId =>
  createSelector(
    selectSVAAgileTv,
    agileTv => get(agileTv, 'psId') === psId && !get(agileTv, 'withStb', true),
  )

export const selectSVAAppleTv = createSelector(
  selectSVAAgileTv,
  agileTv => !get(agileTv, 'withStb', true),
)

export const selectPackages = createSelector(
  selectMainPackages,
  selectExtraMobileLinesOffers,
  (mainPackages, extraLines) => {
    const selectedExtraLinesPackages = flatten(
      extraLines.map(elem => get(elem, 'selectedPackages', [])),
    )
    return [...mainPackages, ...selectedExtraLinesPackages]
  },
)

export const selectPackagesWithFormattedName = createSelector(
  selectMainPackages,
  formatPackagesName,
)

export const selectAccountSegment = createSelector(selectAccount, account =>
  get(account, 'segment'),
)

export const selectIsCompany = createSelector(selectAccountSegment, segment => segment === COMPANY)

export const selectedLandlineSVAs = createSelector(selectLandlineOffer, landline =>
  landline.SVA?.poId ? formatSingleSVA(landline.SVA) : {},
)

export const selectedLandlineProSVAs = createSelector(selectLandlineOffer, landline =>
  get(landline, 'proSVAs', []),
)

export const selectHasAgileTV = createSelector(selectedLandlineSVAs, sva =>
  sva.poId
    ? { hasAgileTV: true, isPromo: !!get(sva, 'duration', 0), hasAppleTv: !sva.withStb }
    : {
        hasAgileTV: false,
        isPromo: 0,
      },
)

export const selectSelectedHomeGoAlarm = createSelector(selectedLandlineProSVAs, proSVAs =>
  proSVAs.find(proSVA => proSVA.psId.includes(SVA_HOMEGO_CODE)),
)

export const selectAllLinesWithDonor = createSelector(
  [
    selectIsCompany,
    selectLandlineOffer,
    selectMainMobileLineOffer,
    selectExtraMobileLinesOffers,
    selectAccount,
  ],
  (isCompany, landline, mainMobile, extras, account) => {
    const mainUser = isCompany
      ? account.companyName
      : `${account.name} ${account.surname1} ${account.surname2 ? account.surname2 : ''}`
    const lines = [landline, mainMobile, ...extras]
    const portaLinesWithDonor = lines.filter(line => {
      return (
        line.signUpType === SIGNUP_TYPE_PORTABILITY &&
        line.isSameUser === 'no' &&
        line.portabilityType !== PORTABILITY_TYPE_PREPAID
      )
    })
    const formatedLines = portaLinesWithDonor.map(line => ({
      documentId: line.otherUser.documentId,
      mainUser,
      portaPhone: line.lineNumber,
      otherUser: line.otherUser.name
        ? `${line.otherUser.name} ${line.otherUser.surname1} ${
            line.otherUser.surname2 ? line.otherUser.surname2 : ''
          }`
        : line.otherUser.companyName,
      operator: line.operator.tradingName,
    }))

    return formatedLines
  },
)

export const selectPortabilityLinesByTitular = createSelector(
  [selectLandlineOffer, selectMainMobileLineOffer, selectExtraMobileLinesOffers],
  (landline, mainMobile, extras) => {
    const lines = [landline, mainMobile, ...extras]
    return lines.filter(line => {
      return line.signUpType === SIGNUP_TYPE_PORTABILITY && line.isSameUser === 'yes'
    })
  },
)

export const selectIsPro = createSelector(getBasket, basket => get(basket, 'segment') === 'pro')

export const selectSalesTypes = createSelector(
  selectSaleChannel,
  selectQueryParams,
  selectAccount,
  (channel, { reseller }) => {
    const isPos = channel === POS || channel === POS_PRO
    const isD2D = reseller === 'D2D'
    return { isPos, isD2D }
  },
)

export const selectIsPosSale = createSelector(selectChannelQueryParam, channel => channel === POS)

export const selectIsServiceAddressBillingAddress = createSelector(
  selectBilling,
  billing => billing.selectedAddressType === 'serviceAddress',
)

export const selectServiceAddressZipCode = createSelector(selectServiceAddress, service =>
  get(service, 'otherAddress.zipCode'),
)

export const selectMainMobileTariffName = createSelector(
  [selectIsMultiSimQueryParam, selectMainMobileLineOffer, selectExtraMobileLinesOffers],
  (isMultiSim, mainMobile, extraMobiles) =>
    get(isMultiSim ? head(extraMobiles) : mainMobile, 'tariff.name'),
)

export const selectBasketRequestLoading = createSelector(getBasket, basket =>
  get(basket, 'requestLoading'),
)

export const selectBillingAccountId = createSelector(selectBilling, billing =>
  get(billing, 'billingAccountId', ''),
)

export const selectFinancedConsents = createSelector(getBasket, basket =>
  get(basket, 'financedLegalConsents', {}),
)

export const selectAccountId = createSelector(selectAccount, account => account.accountId)

export const selectPhoneContact = createSelector(selectAccount, account => account.phoneContact)

export const selectPhoneContact2 = createSelector(selectAccount, account => account.phoneContact2)

export const selectEmail = createSelector(selectAccount, account => account.email)

export const selectDocumentId = createSelector(selectAccount, account => account.documentid)

export const selectHomeGoPreselectedFullAddress = createSelector(
  [selectIsMobileOnly, selectCoverageFullAddress, selectBilling, selectIsCarteraQueryParam],
  (isMobileOnly, installationAddress, billingAddress, isCartera) => {
    if (isMobileOnly) {
      return !isCartera
        ? {
            type: BILLING_ADDRESS,
            formattedAddress: formatOtherFullAddress(billingAddress.otherAddress),
          }
        : null
    }

    return { type: INSTALLATION_ADDRESS, formattedAddress: installationAddress }
  },
)

export const selectHomeGoSelectedFullAddress = createSelector(
  [getBasket, selectHomeGoPreselectedFullAddress],
  ({ homeGo }, preselectedAddress) => {
    if (homeGo.selectedAddressType === OTHER_ADDRESS.value) {
      return {
        type: OTHER_ADDRESS,
        formattedAddress: formatOtherFullAddress(homeGo.otherAddress),
      }
    }

    return preselectedAddress
  },
)
