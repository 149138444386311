import PropTypes from 'prop-types'
import { Box, Radio, FormControlLabel } from '@material-ui/core'
import { FormikRadio, Callout, SpinnerCenter } from 'components/ui'
import { OtherPerson } from '../OtherPersonComponent'

export function PortabilityPostpaid({
  fieldNames,
  isCustomerLoading,
  mainUser,
  portabilityPerson,
  otherUserDocument,
  isMobileOnly,
  isLeadFilled,
}) {
  const customerName = isCustomerLoading ? '' : `El mismo (${mainUser})`
  const selectValues = {
    ME: 'yes',
    OTHER_PERSON: 'no',
  }

  return (
    <Box width="100%" className="m-y-4">
      {isCustomerLoading && <SpinnerCenter />}
      {!isCustomerLoading && (
        <>
          <div>
            <FormikRadio
              row
              name={fieldNames.PORTABILITY_PERSON}
              label="Titular donante"
              disabled={isMobileOnly && !isLeadFilled}>
              <FormControlLabel
                value={selectValues.ME}
                control={<Radio className="radio-check" color="primary" />}
                label={customerName}
              />
              <FormControlLabel
                value={selectValues.OTHER_PERSON}
                control={<Radio className="radio-check" color="primary" />}
                label="Otra persona"
              />
            </FormikRadio>
          </div>

          {portabilityPerson === selectValues.OTHER_PERSON && (
            <Callout>
              <OtherPerson fieldNames={fieldNames} otherUserDocument={otherUserDocument} />
            </Callout>
          )}
        </>
      )}
    </Box>
  )
}

PortabilityPostpaid.propTypes = {
  fieldNames: PropTypes.object,
  isCustomerLoading: PropTypes.bool,
  portabilityPerson: PropTypes.string,
  mainUser: PropTypes.string,
  otherUserDocument: PropTypes.string,
  isMobileOnly: PropTypes.bool,
  isLeadFilled: PropTypes.bool,
}
