import { useState, useEffect } from 'react'

import { Typography } from '@material-ui/core'

import { NotificationModal } from 'components/ui'

import { fieldNames as getFieldNames } from 'modules/Lines/line.config'
import { LINE_TYPE } from 'modules/Lines/constants'
import { useMemberGetMember } from './useMemberGetMember'
import { MemberGetMemberPromo } from './MemberGetMemberPromo'
import { MemberGetMemberPromoModal } from './MemberGetMemberPromoModal'

const PRIMARY_ERROR_MESSAGE = '¡Vaya, no se ha podido aplicar la promoción "Trae un amigo"!'

export const MemberGetMemberPromoContainer = () => {
  const [showMemberGetMemberModal, setShowMemberGetMemberModal] = useState(false)

  const fieldNames = getFieldNames(LINE_TYPE.MAIN_MOBILE, true)
  const msisdnFieldName = fieldNames.MEMBER_GET_MEMBER_REFEREE_MSISDN
  const memberGetMemberPoIdFieldName = fieldNames.MEMBER_GET_MEMBER_PO_ID

  const memberGetMemberStatus = useMemberGetMember(
    msisdnFieldName,
    memberGetMemberPoIdFieldName,
    showMemberGetMemberModal,
  )

  const {
    isPromoAvailable,
    hasMsisdnValidationError,
    hasPromoBeenApplied,
    resetMemberGetMemberValues,
    hasPromoBeenAddedToBasket,
    isLoadingMemberGetMemberInfo,
  } = memberGetMemberStatus

  const handlePromoCardClick = () => {
    setShowMemberGetMemberModal(!showMemberGetMemberModal)
  }

  const handleRemovePromoClick = () => {
    resetMemberGetMemberValues()
  }

  const handleModalClose = () => {
    resetMemberGetMemberValues()
    setShowMemberGetMemberModal(false)
  }

  const showErrorModal = !isPromoAvailable || hasMsisdnValidationError
  const secondaryErrorMessage = !isPromoAvailable
    ? 'Esta promoción ya no está activa.'
    : 'A este cliente no se le puede aplicar este tipo de promoción.'

  useEffect(() => {
    if (hasPromoBeenApplied) setShowMemberGetMemberModal(false)
  }, [hasPromoBeenApplied])

  return (
    <>
      <MemberGetMemberPromo
        hasPromoBeenApplied={hasPromoBeenApplied || hasPromoBeenAddedToBasket}
        handleClick={handlePromoCardClick}
        handleRemovePromoClick={handleRemovePromoClick}
        isLoadingMemberGetMemberInfo={isLoadingMemberGetMemberInfo}
      />
      {showErrorModal ? (
        <>
          <NotificationModal
            isOpen={showMemberGetMemberModal}
            type="alert"
            src="/assets/error.svg"
            dataHook="mgm-error-modal"
            onClose={() => handleModalClose()}>
            <Typography variant="h4" style={{ marginBottom: 24 }}>
              {PRIMARY_ERROR_MESSAGE}
            </Typography>

            <Typography variant="h5" style={{ fontWeight: 400 }}>
              {secondaryErrorMessage}
            </Typography>
          </NotificationModal>
        </>
      ) : (
        <>
          <MemberGetMemberPromoModal
            isOpen={showMemberGetMemberModal}
            onClose={() => handleModalClose()}
            {...memberGetMemberStatus}
            msisdnFieldName={msisdnFieldName}
          />
        </>
      )}
    </>
  )
}
