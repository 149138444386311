export const CREDIT_ATTEMPTED = 'OVID/CREDIT/ATTEMPTED'
export const CREDIT_SUCCEEDED = 'OVID/CREDIT/SUCCEEDED'
export const CREDIT_FAILED = 'OVID/CREDIT/FAILED'
export const CLEAR_CREDIT_ERROR = 'OVID/CREDIT/CLEAR_ERROR'

export const applyCreditNoteAction = data => ({
  type: CREDIT_ATTEMPTED,
  payload: {
    data,
  },
})

export const creditNoteSuccededAction = data => ({
  type: CREDIT_SUCCEEDED,
  payload: {
    data,
  },
})

export const creditNoteFailedAction = error => ({
  type: CREDIT_FAILED,
  payload: { error },
})

export const creditNoteClearErrorAction = () => ({
  type: CLEAR_CREDIT_ERROR,
})
