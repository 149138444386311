import { omit } from 'lodash'
import { constants } from './constants'

const initialState = {}

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case constants.GET_DISCOUNTS: {
      return {
        ...state,
        discounts: {
          ...state.discounts,
          [payload.category]: [],
        },
        loading: {
          ...state.loading,
          [payload.category]: true,
        },
        error: omit(state.error, payload.category),
      }
    }
    case constants.GET_DISCOUNTS_SUCCESS: {
      return {
        ...state,
        discounts: {
          ...state.discounts,
          [payload.category]: payload.discounts,
        },
        loading: {
          ...state.loading,
          [payload.category]: false,
        },
        error: omit(state.error, payload.category),
      }
    }
    case constants.GET_DISCOUNTS_FAILED: {
      return {
        ...state,
        discounts: {
          ...state.discounts,
          [payload.category]: [],
        },
        loading: {
          ...state.loading,
          [payload.category]: false,
        },
        error: {
          ...state.error,
          [payload.category]: payload.error,
        },
      }
    }
    default:
      return state
  }
}
