export const VALIDATE_IBAN = 'IBAN_VALIDATION/VALIDATE_IBAN/FETCH'
export const VALIDATE_IBAN_SUCCESS = 'IBAN_VALIDATION/VALIDATE_IBAN/SUCCESS'
export const VALIDATE_IBAN_ERROR = 'IBAN_VALIDATION/VALIDATE_IBAN/ERROR'
export const SET_EXISTING_IBAN_BEING_EDITED = 'IBAN_VALIDATION/SET_EXISTING_IBAN_BEING_EDITED'

export const validateIBANAction = iban => ({
  type: VALIDATE_IBAN,
  payload: { iban },
})

export const validateIBANActionSuccess = iban => ({
  type: VALIDATE_IBAN_SUCCESS,
  payload: iban,
})

export const validateIBANActionError = error => ({
  type: VALIDATE_IBAN_ERROR,
  payload: { ...error },
})

export const setExistingClientIsBeingEdited = () => ({
  type: SET_EXISTING_IBAN_BEING_EDITED,
  payload: null,
})
