import { useMemo, useState } from 'react'
import { get, uniqBy, flatMap } from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import {
  selectIsElFijo,
  selectTransactionForAnalytics,
  selectSaleParam,
  selectPackages,
  selectMainTariffCrossSellDiscounts,
  selectSaleTax,
} from 'modules/NewClientSales/store/selectors/index'
import {
  selectSubscriptionBundles,
  selectSubscriptionProductNumber,
} from 'services/customer-360/selectors'
import { selectBonusesData } from 'modules/bonuses/store/bonuses.selectors'
import { useAnalytics } from 'modules/Analytics/hooks/index'
import { LINE_TYPE } from 'modules/Lines'
import { PERCENTS } from 'modules/Discounts/models'

import {
  selectCrossCompatibleDiscounts,
  selectCrossIncompatibleDiscounts,
  selectCrossReplaceableDiscounts,
} from 'services/cross-sell/selectors'
import { fetchCrossSellCompatibleDiscounts } from 'services/cross-sell/actions'

import { selectSvaHomeGoFlag, selectSvaSmartHomeFlag, selectEnabledXsellSaleCampaignFlag } from 'services/feature-flag/selectors'
import { SVA_HOMEGO_CODE } from 'modules/offers-configuration/constants'
import { proSvasSelectors } from '../../Offer/ProSvas/ProSvas.selectors'
import { useFetchBundles } from './useFetchBundles'
import { useProSvas } from './useProSVAs'

export function useTariffPicker(
  selectedTariff,
  selectedLinePackages,
  tariffList,
  onCancelSelectTariff,
  onSelectTariff,
  onSelectBundles,
  onDeleteBundle,
  lineType,
  isSummary,
  isMobileOnly,
  isCrossSell,
  isMultiSim,
) {
  const dispatch = useDispatch()

  const analyticsTransaction = useSelector(selectTransactionForAnalytics)
  const proSVASelectors = useSelector(proSvasSelectors)
  const isElFijo = useSelector(selectIsElFijo)
  const saleType = useSelector(selectSaleParam)
  const bonuses = useSelector(selectBonusesData)
  const tax = useSelector(selectSaleTax)

  const crossSellSubscriptionBundles = useSelector(selectSubscriptionBundles)
  const crossSellSubscriptionProductNumber = useSelector(selectSubscriptionProductNumber)

  const crossSellCompatibleDiscounts = useSelector(selectCrossCompatibleDiscounts)
  const crossSellIncompatibleDiscounts = useSelector(selectCrossIncompatibleDiscounts)
  const crossSellReplaceableDiscounts = useSelector(selectCrossReplaceableDiscounts)

  const crossSellTariffDiscounts = useSelector(selectMainTariffCrossSellDiscounts)

  const selectedBundles = useSelector(selectPackages)
  const isSvaSmartHomeFlag = useSelector(selectSvaSmartHomeFlag)
  const isSvaHomeGoFlag = useSelector(selectSvaHomeGoFlag)
  const isXsellFlag = useSelector(selectEnabledXsellSaleCampaignFlag)

  const isMainLine = useMemo(() => !lineType?.includes('extraMobileLinesOffers'), [lineType])
  const [isOpenTariffsModal, setIsOpenTariffsModal] = useState(
    !selectedTariff?.ps && !isElFijo && !isMultiSim && isMainLine,
  )
  const [isOpenBundlesModal, setIsOpenBundlesModal] = useState(false)
  const [isOpenProSVAsModal, setIsOpenProSVAsModal] = useState(false)

  const disableEditOffer = isMobileOnly && isSummary
  const { bundleList, isLoadingBundles } = useFetchBundles(selectedTariff?.ps, tax, isMultiSim)
  const analytics = useAnalytics()

  const {
    disableProSVAs,
    onSelectProSVAs,
    onDeleteProSVA,
    proSVAs,
    svaList,
    formatedSvas,
    formatedSvasPermanency,
  } = useProSvas(
    selectedTariff?.ps,
    (lineType === LINE_TYPE.LANDLINE && (proSVASelectors.isPro || isSvaSmartHomeFlag)) ||
      (lineType === LINE_TYPE.MAIN_MOBILE && isSvaHomeGoFlag && !isMultiSim),
    null,
    !isElFijo,
  )

  function onClickAddProSVAs() {
    setIsOpenProSVAsModal(true)
  }

  function onClickAddBundles() {
    setIsOpenBundlesModal(true)
  }

  function onClickChangeTariff() {
    setIsOpenTariffsModal(true)
  }

  function onCancelChangeTariff() {
    setIsOpenTariffsModal(false)
    onCancelSelectTariff()
  }

  function onClickSelectTariff(tariff, xsellPromo) {
    if (analytics) {
      analytics.addToCart({
        ctaText: 'Seleccionar esta tarifa',
        append: true,
        items: [
          { ...tariff, price: { finalPrice: tariff.priceWithTax } },
          ...analyticsTransaction.items,
        ],
        externalfunnelType: saleType,
      })
    }

    setIsOpenTariffsModal(false)
    onSelectTariff(tariff)

    if (isCrossSell && isMainLine) {
      dispatch(
        fetchCrossSellCompatibleDiscounts(
          crossSellSubscriptionProductNumber,
          tariff?.ps,
          tariff?.poId,
          xsellPromo?.poId,
        ),
      )
    }
  }

  function onCancelAddBundles() {
    setIsOpenBundlesModal(false)
  }

  function onClickSelectBundles(bundles) {
    if (analytics) {
      analytics.addToCart({
        ctaText: 'Confirmar bono',
        append: true,
        items: [...analyticsTransaction.items, ...bundles],
        externalfunnelType: saleType,
      })
    }
    setIsOpenBundlesModal(false)
    onSelectBundles(bundles)
  }

  function onClickDeleteBundle(bundleId) {
    if (analytics) {
      analytics.addToCart({
        ctaText: 'Eliminar',
        append: true,
        items: [...analyticsTransaction.items],
        externalfunnelType: saleType,
      })
    }
    onDeleteBundle(bundleId)
  }

  function onClickDeleteProSVA(sva) {
    if (analytics) {
      analytics.addToCart({
        ctaText: 'Eliminar',
        append: true,
        items: [...analyticsTransaction.items],
        externalfunnelType: saleType,
      })
    }

    onDeleteProSVA(sva)
  }

  function onCancelAddProSvas() {
    setIsOpenProSVAsModal(false)
  }

  function onClickSelectProSVAs(svas) {
    const svaWithPrice = svas.map(sva => {
      if (sva.commercialInfoFormated) {
        return sva
      }

      const { prices } = sva

      if (sva.psId.includes(SVA_HOMEGO_CODE)) {
        prices.taxFreeAmount = 0
      }

      return {
        ...sva,
        price: prices,
        prices: [prices],
      }
    })

    if (analytics) {
      analytics.addToCart({
        ctaText: 'Confirmar sva pro',
        append: true,
        items: [...analyticsTransaction.items, ...svaWithPrice],
        externalfunnelType: saleType,
      })
    }

    setIsOpenProSVAsModal(false)
    onSelectProSVAs(svaWithPrice)
  }

  const disableBundles = useMemo(
    () =>
      isMultiSim ||
      (!disableEditOffer && !bundleList?.length > 0) ||
      bundleList?.length === selectedLinePackages?.length,
    [bundleList, selectedLinePackages, disableEditOffer, isMultiSim],
  )

  const disableChangeTariff = useMemo(() => !disableEditOffer && tariffList?.length <= 1, [
    tariffList,
    disableEditOffer,
  ])

  const { crossSellCompatibleBundles, crossSellIncompatibleBundles } = useMemo(() => {
    const compatibleBundles = []
    const incompatibleBundles = []

    let compatibleBundle

    if (crossSellSubscriptionBundles && bundleList && isMainLine) {
      crossSellSubscriptionBundles
        .filter(b => !selectedBundles.find(selectedBundle => selectedBundle.ps === b.type))
        .forEach(crossSellBundle => {
          compatibleBundle = bundleList.find(bundle => bundle.ps === crossSellBundle.type)

          if (compatibleBundle) {
            compatibleBundles.push(compatibleBundle)
          } else {
            const bonusData = bonuses.find(bonus => bonus.id === crossSellBundle.type)

            incompatibleBundles.push({
              ps: crossSellBundle.type,
              name: get(bonusData, 'attributes.commercial_name', '-'),
              price: null,
            })
          }
        })
    }

    return {
      crossSellCompatibleBundles: compatibleBundles,
      crossSellIncompatibleBundles: incompatibleBundles,
    }
  }, [bundleList, crossSellSubscriptionBundles, selectedBundles])

  const mapCrossSellDiscountAdvices = (list, label) =>
    list.map(discount => ({
      id: discount.ruleId,
      display: [
        {
          label,
          value: discount.name,
        },
      ],
    }))

  const crossSellCompatibleDiscountAdvices = useMemo(
    () => (isMainLine ? mapCrossSellDiscountAdvices(crossSellCompatibleDiscounts, 'Mantiene') : []),
    [crossSellCompatibleDiscounts],
  )

  const crossSellIncompatibleDiscountAdvices = useMemo(
    () => (isMainLine ? mapCrossSellDiscountAdvices(crossSellIncompatibleDiscounts, 'Pierde') : []),
    [crossSellIncompatibleDiscounts],
  )

  const crossSellReplaceableDiscountAdvices = useMemo(
    () =>
      isMainLine ? mapCrossSellDiscountAdvices(crossSellReplaceableDiscounts, 'Reemplazado') : [],
    [crossSellReplaceableDiscounts],
  )

  function findCrossSellDiscountsInPrice(price, crossSellDiscounts) {
    let discounts = []
    const crossSellDiscountsNames =
      crossSellDiscounts?.length > 0 ? crossSellDiscounts.map(discount => discount.name) : []

    if (price?.discounts?.length > 0 && crossSellDiscountsNames.length > 0) {
      discounts = price.discounts.filter(priceDiscount =>
        crossSellDiscountsNames.includes(priceDiscount?.name),
      )
    }
    return discounts
  }

  function findUniquesCrossSellDiscountsInPrices(tariffPrices, crossSellDiscounts, uniqueByKey) {
    return tariffPrices?.length > 0
      ? uniqBy(
          flatMap(tariffPrices, price => findCrossSellDiscountsInPrice(price, crossSellDiscounts)),
          uniqueByKey,
        )
      : []
  }

  function mapCrossSellDiscountToAdviceInfo(discount, crossSellTariffDiscountsPoId) {
    return {
      id: crossSellTariffDiscountsPoId,
      display: [
        {
          label: `Dto. CrossSell`,
          value: `${discount.amount} ${discount.units === PERCENTS ? '%' : '€'}`,
        },
        {
          label: 'Duración',
          value: `${discount.maxRepetitions} meses`,
        },
      ],
    }
  }

  const crossSellTariffPromotionAdvice = useMemo(() => {
    const uniqueCrossSellDiscountsApplied = findUniquesCrossSellDiscountsInPrices(
      selectedTariff?.prices,
      crossSellTariffDiscounts,
      'amount',
    )

    return uniqueCrossSellDiscountsApplied.map(discount => {
      return mapCrossSellDiscountToAdviceInfo(discount, crossSellTariffDiscounts.poId)
    })
  }, [crossSellTariffDiscounts, selectedTariff?.prices])

  return {
    isMobile: proSVASelectors?.isMobile,
    isPro: proSVASelectors?.isPro,
    onClickAddProSVAs,
    onClickAddBundles,
    onClickChangeTariff,
    disableBundles,
    disableChangeTariff,
    isLoadingBundles,
    isOpenBundlesModal,
    isOpenTariffsModal,
    isOpenProSVAsModal,
    onCancelAddBundles,
    onCancelAddProSvas,
    onCancelChangeTariff,
    bundleList,
    onClickSelectTariff,
    onClickSelectBundles,
    onClickDeleteBundle,
    disableProSVAs,
    onDeleteProSVA,
    proSVAs,
    proSVAList: svaList,
    onClickDeleteProSVA,
    onClickSelectProSVAs,
    formatedSvas,
    formatedSvasPermanency,
    crossSellCompatibleBundles,
    crossSellIncompatibleBundles,
    crossSellCompatibleDiscountAdvices,
    crossSellIncompatibleDiscountAdvices,
    crossSellReplaceableDiscountAdvices,
    crossSellTariffPromotionAdvice,
    isXsellFlag
  }
}
