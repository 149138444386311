import { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import { get } from 'lodash'
import { Field, getIn } from 'formik'
import MaskedInput from 'react-text-mask'
import { MenuItem } from '@material-ui/core'

import { ComposedTextField } from '../ComposedTextField'

const useStyles = makeStyles({
  'text-field': {
    backgroundColor: ({ backgroundcolor }) => backgroundcolor || 'transparent',
    display: 'flex',
    flexBasis: '90%',
    minWidth: '150px',
    flex: 1,
    width: ({ width }) => width || 'auto',
  },
})

const isDisabled = (disabled, isMissing, hasValError) =>
  !disabled ? false : disabled.bool || isMissing || hasValError

const TextMaskDate = ({ inputRef, ...other }) => (
  <MaskedInput
    {...other}
    ref={ref => {
      inputRef(ref ? ref.inputElement : null)
    }}
    keepCharPositions
    mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
  />
)
TextMaskDate.propTypes = {
  inputRef: PropTypes.func,
}

const TextMaskMoney = ({ inputRef, ...other }) => {
  const numberMask = createNumberMask({
    prefix: '',
    suffix: '€',
    thousandsSeparatorSymbol: '.',
    integerLimit: 5,
  })
  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={numberMask}
    />
  )
}
TextMaskMoney.propTypes = {
  inputRef: PropTypes.object,
}

const EnhancedField = ({
  className,
  width,
  backgroundColor,
  form,
  field,
  disabled,
  date,
  money,
  endAdornment,
  maxLength,
  customError,
  ...rest
}) => {
  const classes = useStyles({ width, backgroundColor })

  const onChange = (e, value) => {
    const inputValue = e?.target?.value || value

    form.setFieldValue(field.name, inputValue)

    if (rest.onChange) {
      rest.onChange(inputValue, field.name, form)
    }
  }

  const onBlur = (e, value) => {
    const customValue = get(e, 'target.value') || value
    form.setFieldValue(field.name, customValue?.toString().trim())
    form.setFieldTouched(field.name, true)
  }

  useEffect(() => {
    const { checkOnMount, onChange: onChangeValue } = rest

    if (checkOnMount && field.value) {
      onChangeValue(null, field.value, field.name, form)
    }
  }, [])

  const maskInput = useMemo(() => {
    const data = {}

    if (date) {
      data.inputComponent = TextMaskDate
    }

    if (money) {
      data.inputComponent = TextMaskMoney
    }

    return data
  }, [date, money])

  const isMissing = get(disabled, 'misses') ? !getIn(form.values, disabled.misses) : false
  const hasValError = get(disabled, 'error') ? !!getIn(form.errors, disabled.error) : false
  const customErrorText = (form.status || {})[field.name]
  const fieldError = getIn(form.errors, field.name)

  const props = {
    error: fieldError || customErrorText || customError,
    touched: !!customErrorText || getIn(form.touched, field.name),
    value: field.value,
    name: field.name,
    disabled: isDisabled(disabled, isMissing, hasValError),
    ...rest,
    'data-hook': field.name,
    onChange,
    onBlur,
    InputProps: {
      ...maskInput,
      endAdornment,
      inputProps: {
        maxLength,
      },
    },
  }

  return (
    <ComposedTextField
      className={[classes['text-field'], ...(className ? [className] : [])].join(' ')}
      {...props}>
      {props.options &&
        props.options.map((elem, i) => {
          const j = i
          return (
            <MenuItem key={`menu-item-${j}`} value={elem.value}>
              {elem.label}
            </MenuItem>
          )
        })}
    </ComposedTextField>
  )
}
EnhancedField.propTypes = {
  className: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  backgroundColor: PropTypes.string,
  form: PropTypes.object,
  field: PropTypes.object,
  disabled: PropTypes.object,
  date: PropTypes.bool,
  money: PropTypes.bool,
  endAdornment: PropTypes.node,
  maxLength: PropTypes.string,
  customError: PropTypes.bool,
  options: PropTypes.array,
}

const FormikInput = ({ name, validate, dataHook, dataTest, ...props }) => (
  <Field name={name} validate={validate}>
    {({ form, field }) => (
      <EnhancedField
        data-hook={dataHook}
        margin={props.margin || 'normal'}
        variant="outlined"
        field={field}
        form={form}
        {...props}
      />
    )}
  </Field>
)

FormikInput.propTypes = {
  /** Formik field name */
  name: PropTypes.string,
  /** Indicates if it is a combo input */
  select: PropTypes.bool,

  validate: PropTypes.func,
  dataHook: PropTypes.string,
  dataTest: PropTypes.string,

  margin: PropTypes.string,
}

FormikInput.defaultProps = {
  name: '',
  select: false,
}

export default FormikInput
