import { constants } from './constants'

const getFlags = () => {
  return {
    type: constants.FLAGS_INIT,
  }
}

const getFlagsSuccess = data => ({
  type: constants.FLAGS_SUCCESS,
  payload: {
    ...data,
  },
})

const getFlagsFailed = error => ({
  type: constants.FLAGS_FAILED,
  payload: {
    error,
  },
})

export const actions = { getFlags, getFlagsSuccess, getFlagsFailed }
