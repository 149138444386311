import { withFormik, yupToFormErrors } from 'formik'
import { COMPANY } from 'services/global-constants'
import { SalesSchema } from '../validations'
import { STEPS, OFFER_CONFIG_STEPS } from '../constants/wizardSteps'
import { getPortabilityNumbers } from '../store/selectors'

export const withCheckoutForms = withFormik({
  mapPropsToValues: props => {
    return props.basket
  },
  mapPropsToStatus: () => ({}),
  validate: async (
    values,
    {
      isElFijo,
      isMobileOnly,
      saleType,
      isAdsl,
      isVula,
      step,
      queryParams: { channel },
      isCartera,
      isSubscription,
      billingAccountId,
      sale: { isPos },
      isCrossSell,
      is2pPure,
      portaDates,
      isMultiSim,
    },
  ) => {
    const {
      account: { segment, companyCif, documentid },
      mainMobileLineOffer: { iccNumber },
    } = values
    const context = {
      isCartera: !!isCartera,
      isSubscription,
      isCrossSell,
      channel,
      isElFijo,
      isMobileOnly,
      isMultiSim,
      isAdsl,
      isVula,
      billingAccountId,
      isCompany: segment === COMPANY,
      documentId: segment === COMPANY ? companyCif : documentid,
      iccNumber,
      portaNumbers: getPortabilityNumbers([
        values.mainMobileLineOffer,
        ...values.extraMobileLinesOffers,
      ]),
      portaDates,
    }

    let errors = {}

    await SalesSchema(
      isMobileOnly ? OFFER_CONFIG_STEPS[step] : STEPS[step],
      isMobileOnly,
      saleType,
      isElFijo,
      isPos,
      isSubscription,
      isCrossSell,
      is2pPure,
      isMultiSim,
    )
      .validate(values, { abortEarly: false, context })
      .catch(err => {
        errors = yupToFormErrors(err)
      })

    return errors
  },
})
