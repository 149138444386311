import { call, put, takeLatest, select } from 'redux-saga/effects'
import { isEmpty, omitBy } from 'lodash'
import { logError, formatErrorMessage } from 'services/logging'
import { axiosFactory } from 'modules/axios'
import { selectSfid } from 'modules/Auth'
import { selectProfileId } from 'modules/Permissions'
import { selectors as customerSelectors } from 'services/customer-360'
import { createTypification } from 'services/typifications/actions'
import { sendSmsCommunication, SMS_SENDER_INFO } from 'services/sms-communication'

import * as actions from './FAT.actions'
import { fatCompensation } from '../services/FAT.service'

export function* fatCompensationSaga({
  payload: { subscriptionId, msisdn, data, typification, sms },
}) {
  try {
    const sfid = yield select(selectSfid)
    const documentId = yield select(customerSelectors.getIdentificationId)
    const profileId = yield select(selectProfileId)

    const customerData = { sfid, subscriptionId, documentId, msisdn, profileId }
    const result = yield call(fatCompensation, customerData, omitBy(data, isEmpty))

    yield put(actions.fatCompensationSuccededAction(result))

    if (typification) {
      yield put(createTypification({ msisdn, documentId, area: 'FAT', ...typification }))
    }

    if (sms) {
      yield put(sendSmsCommunication(SMS_SENDER_INFO.Yoigo, sms.phoneNumber, sms.content))
    }
  } catch (e) {
    yield call(logError, { e: new Error(formatErrorMessage(e)), subscriptionId, msisdn, data })
    const error = axiosFactory.buildError(e)
    yield put(actions.fatCompensationFailedAction(error))
  }
}

export function* watchFATCompensation() {
  yield takeLatest(actions.FAT_ATTEMPTED, fatCompensationSaga)
}
