import { uniqBy, flatMap, every, isEmpty, head, get, toUpper } from 'lodash'
import * as roles from './roles'
import { SFID_GUUK } from './constants'

const RETENTION_SFIDS = [
  'ATC00993',
  'BL001457',
  'YC006516',
  'YC006679',
  'YC010050',
  'YC010054',
  'YC010055',
  'YC010058',
  'YC010059',
  'YC010061',
]

const RETENTION_BREAKDOWN_SFIDS = [
  'ALESPADA',
  'ANDRES.AUTHEMAN',
  'CBASTANTEENCIM',
  'CDIAZRONCERO',
  'CMARTINGILBA',
  'CMERINOMOLI',
  'ECARDENASM',
  'EDELGADO',
  'EGONZALEZPERA',
  'ETERCEROFERNA',
  'JEANETTE.ALLENDE',
  'MARIA.ALONSOG',
  'MBRENESM',
  'MCMURIEL',
  'MKCN_LUCAS.TAVARES',
  'SDRONDA',
  'SMINGUEZS',
  'TROLDAN',
  'VANESA.BARRIOS',
  'YC006749',
  'YC006750',
  'YC006752',
  'YC006753',
  'YC006754',
  'YC006755',
  'YC006757',
  'YC006760',
  'YC006761',
  'YC006762',
  'YC006763',
  'YC006764',
  'YC006766',
  'YC006767',
  'YC006769',
  'YC006770',
  'YC006771',
  'YC006772',
  'YC006773',
  'YC006774',
  'YC006775',
  'YC006776',
  'YC006777',
  'YC006778',
  'YC006782',
  'YC006783',
  'YC006785',
  'YC006840',
  'YC006841',
  'YC006842',
]

const CANCELLATION_SFIDS = [
  'SRV00006',
  'SRV00010',
  'SRV00019',
  'SRV00299',
  'SRV00447',
  'SRV00568',
  'SRV00573',
  'SRV00619',
  'SRV00635',
  'SRV00638',
  'SRV00639',
  'SRV00641',
  'SRV00642',
  'SRV00643',
  'SRV00645',
  'SRV00646',
  'SRV00647',
  'SRV00648',
  'SRV00649',
  'SRV00650',
  'SRV00652',
  'SRV00653',
  'SRV00654',
  'SRV00655',
  'SRV00656',
  'SRV00657',
  'SRV00658',
  'SRV00660',
  'SRV00662',
  'SRV00666',
  'SRV00668',
  'SRV0067',
  'SRV00670',
  'SRV00671',
  'SRV00673',
  'SRV00674',
  'SRV00675',
  'SRV00677',
  'SRV00678',
  'SRV00679',
  'SRV00680',
  'SRV00681',
  'SRV00685',
  'SRV00686',
  'SRV00689',
  'SRV0177',
  'SRVC00060',
  'SV001040',
  'SV001086',
  'SV001132',
  'SV001144',
  'SV001202',
  'SV001360',
  'SVFC00013',
  'SVFC00628',
  'SVFC00630',
  'SVFC00633',
  'YC001215',
  'YC004478',
  'YC004499',
  'YC004512',
  'YC004697',
  'YC006157',
  'YC006377',
  'YC006379',
  'YC006629',
  'YC006630',
  'YC006654',
  'YC006694',
  'YC010051',
  'YC010059',
  'YC010061',
  'YC010064',
]

const SALES_SFIDS = [
  'VERONICA.GUTIERREZ',
  'TARA.CAYON',
  'SONIA.GARRIDO',
  'SEILA.RIVES',
  'PAULA.PEREZ',
  'PATRICIA.MALLAVIA',
  'PATRICIA.ARRANZ',
  'MARIO.DIGLERIA',
  'LANDERAS',
  'MARIATERESA.LOZANO',
  'MJIMENEZG',
  'MARIA.COBO',
  'MARIADELCARMEN.ACEBO',
  'EVA.COLINO',
  'EREVUELTA',
  'BGOMEZ',
  'BEGONA.LOZANO',
  'NURIA.CHARCAN',
  'SANDRA.VELARDE',
  'PABLO.ORTEGA',
  'NORA.LOPEZ',
  'NATALIA.GARCIAC',
  'MARTA.GOMEZ',
  'MARTA.CARRERA',
  'MARIA.GUTIERREZ',
  'MARIAGRACIA.RODRIGUEZ',
  'JOSE.REYBEL',
  'IDELAOSA',
  'IMARTINEZPA',
  'ESTER.FERNANDEZ',
  'ESTEFANIA.SALGADO',
  'SONIA.GUTIERREZ',
  'VERONICA.CARRAL',
  'NAHIR.PRESA',
  'PABLO.MORAL',
  'ADRIA.DIAZDL',
  'AGUEDA.COSSIO',
  'JQUIRCE',
  'MGIRALDEZ',
  'MFLANDEZ',
  'MARIADELCARMEN.ACEBO',
  'VERONICA.GUTIERREZ',
  'BEGONA.LOZANO',
  'MJIMENEZG',
  'LANDERAS',
  'PAULA.PEREZ',
  'SEILA.RIVES',
  'EVA.COLINO',
  'PATRICIA.MALLAVIA',
  'PATRICIA.ARRANZ',
  'MARIA.COBO',
  'SONIA.GARRIDO',
  'MARIO.DIGLERIA',
  'IMARTINEZPA',
  'JENNIFER.MARRERO',
  'PAULA.PEÑA',
  'NATALIA.GARCIAC',
  'ALBAITZIAR.BARASOAIN',
  'LRODRIGUEZC',
  'MARTA.GOMEZ',
  'IGARCIAP',
  'JOSE.REYBEL',
  'ESTER.FERNANDEZ',
  'SANDRA.VELARDE',
  'MARIA.GUTIERREZ',
  'ESTEFANIA.SALGADO',
  'YAIZA.RODRIGUEZ',
  'DAMASO.LOPEZ',
  'VICTORIA.VELASCO',
  'ISABEL.REVUELTA',
  'PAULA.GOMEZ'
]

const ONBOARDING_SFIDS = ['YC006630']

const ATC_SFIDS = ['ATC00930', 'YC001525', 'YC004838']

const MASPROXIMO_SFIDS = ['LB152006']

export const profiles = {
  admin: {
    id: 'ADMIN',
    desc: 'ADMIN',
    roles: ['super_admin'],
  },
  atc: {
    id: 'ATC',
    desc: 'ATC',
    roles: ['atc_admin'],
  },
  atc_comercial: {
    id: 'ATC-COMERCIAL',
    desc: 'ATC Comercial',
    roles: ['atc_comercial'],
  },
  cancelation: {
    id: 'CANCELACIONES',
    desc: 'Cancelaciones',
    roles: ['atc_cancelations_admin'],
  },
  portfolio: {
    id: 'CARTERA',
    desc: 'Cartera',
    roles: [],
  },
  customer_loyalty: {
    id: 'FIDELIZACION',
    desc: 'Customer loyalty',
    roles: ['atc_fidelization_admin'],
  },
  customer_loyalty_inbound: {
    id: 'FIDELIZACION INBOUND',
    desc: 'Customer loyalty',
    roles: ['loyalty_inbound_outbound'],
  },
  customer_loyalty_outbound: {
    id: 'FIDELIZACION OUTBOUND',
    desc: 'Customer loyalty',
    roles: ['loyalty_inbound_outbound'],
  },
  business: {
    id: 'NEGOCIOS',
    desc: 'Business',
    roles: [],
  },
  perfect_start: {
    id: 'PERFECT_START',
    desc: 'Perfect Start',
    roles: [],
  },
  pos: {
    id: 'POS',
    desc: 'POS',
    roles: ['pos_admin'],
  },
  pro: {
    id: 'PRO',
    desc: 'Pro',
    roles: ['pro_admin'],
  },
  retention: {
    id: 'RETENCION',
    desc: 'Retentions',
    roles: ['atc_retention_admin'],
  },
  retentionBreakdown: {
    id: 'RETENCIÓN-AVERIAS',
    desc: 'Retention breakdown',
    roles: ['atc_retention_breakdown_admin'],
  },
  soho: {
    id: 'SOHO',
    desc: 'Soho',
    roles: ['soho_admin'],
  },
  sales: {
    id: 'VENTAS',
    desc: 'Sales',
    roles: ['atc_sales_admin'],
  },
  xselling: {
    id: 'XSELLING',
    desc: 'XSelling',
    roles: ['atc_xselling_admin'],
  },
  d2d: {
    id: 'D2D',
    desc: 'D2D SOHO operators',
    roles: ['d2d_soho'],
  },
  masProximo: {
    id: 'MASPROXIMO',
    desc: 'MAS Proximo operators',
    roles: ['masproximo_admin'],
  },
  onboarding: {
    id: 'ONBOARDING',
    desc: 'Onboarding operators',
    roles: ['onboarding_admin'],
  },
  backoffice: {
    id: 'BACKOFFICE',
    desc: 'backoffice',
    roles: ['backoffice_admin'],
  },
  guuk: {
    id: 'GUUK',
    desc: 'guuk',
    roles: [],
  },
}

export const getAgentProfileBySegment = segment => {
  switch (segment) {
    case 'PRO-SOHO-AUTONOMO':
    case 'PRO-SOHO-COMPANY':
      return profiles.pro.id
    case 'SOHO-COMPANY':
    case 'SOHO-AUTONOMO':
      return profiles.soho.id
    case 'CONSUMER':
      return profiles.retention.id
    default:
      return ''
  }
}

const PRO_SOHO_REGX =
  /^APN_|^APN_SRV_|^ATC_NEG_MK_|^ATC_NEG_SRV|^B12DXNEG|^BLN|^EBN|^ESN|^FIDE_NEG_MAD_|^FIDE_NEG_MK_|^FIDE_NEG_VAC_|^GCN|^KCN|^KIN|^MCN|^MI|^MK_NEG|^MKCN(?!N|_LUCAS.TAVARES)|^MKDX|^RETBJ_NEG_|^RETPO_NEG_|^SC_NEG_|^SRV_APN|^SRV_ATC_NEG_|^SRV_NEG|^SVR_NEG_|^SRVAPN|^SRVNEG|^VA|^VNC|^VON|_CA$|_FCA$|_PREV$|_RET$/

export const isProSohoProfile = sfid => sfid && PRO_SOHO_REGX.test(sfid)

const profileIdsRulesBase = [
  {
    // SUPER ADMIN
    rule: (userAuthObj = {}) => {
      const regMatcher = /^BL(?!001457)[0-9]|^AINARA.ALVAREZ$|^NATALIA$|^YC00TEST_RPA$/
      if (userAuthObj.sfid && regMatcher.test(userAuthObj.sfid)) {
        return [profiles.admin.id]
      }
      return []
    },
  },
  {
    // GUUK
    rule: (userAuthObj = {}) => {
      if (userAuthObj.sfid && SFID_GUUK.includes(userAuthObj.sfid)) {
        return [profiles.guuk.id]
      }
      return []
    },
  },
  {
    // PRO || SOHO
    rule: (userAuthObj = {}, customerSegment = '') => {
      if (isProSohoProfile(userAuthObj.sfid) && !RETENTION_BREAKDOWN_SFIDS.includes(userAuthObj.sfid)) {
        const agentProfileBySegment = getAgentProfileBySegment(customerSegment)
        return !isEmpty(agentProfileBySegment) ? agentProfileBySegment : [profiles.retention.id]
      }
      return []
    },
  },
  {
    // ATC-COMERCIAL
    rule: (userAuthObj = {}) => {
      const regMatcher = /^GSS_ATC_|^KN_ATC_|^MK_ATC_|^MK_MKT_|^SRV_ONB|_ACC$/
      if (userAuthObj.sfid && regMatcher.test(userAuthObj.sfid)) {
        return [profiles.atc_comercial.id]
      }
      return []
    },
  },
  {
    // XSELLING
    rule: (userAuthObj = {}) => {
      const regMatcher = /^AB|^AC|^AE|^AO|^AV|^BX|^EY|^GX|^KO|^KS|^LE|^MK(?!C)|^MV|^PF/
      if (userAuthObj.sfid && regMatcher.test(userAuthObj.sfid)) {
        return [profiles.xselling.id]
      }
      return []
    },
  },
  {
    // VENTAS
    rule: (userAuthObj = {}) => {
      const regMatcher =
        /^1W|^AN|^AT(?!C)|^AW|^BA|^BT|^BW|^CW|^EW|^FW|^GW|^KW|^KX|^LW|^MW|^NX|^PA|^PS|^SL|^SW|^VK|^VW|^XA|^ZT|^ZW|_OU$/
      if (
        userAuthObj.sfid &&
        !ONBOARDING_SFIDS.includes(userAuthObj.sfid) &&
        !RETENTION_BREAKDOWN_SFIDS.includes(userAuthObj.sfid) &&
        regMatcher.test(userAuthObj.sfid) ||
        SALES_SFIDS.includes(userAuthObj.sfid)
      ) {
        return [profiles.sales.id]
      }
      return []
    },
  },
  {
    // ONBOARDING
    rule: (userAuthObj = {}) => {
      if (userAuthObj.sfid && ONBOARDING_SFIDS.includes(userAuthObj.sfid)) {
        return [profiles.onboarding.id]
      }
      return []
    },
  },
  {
    // CANCELACIONES
    rule: (userAuthObj = {}) => {
      const regMatcher =
        /^BL(?!([0-9]|N))|^B12|^BO|^CANCAON|^CANCINN|^CANCMKT|^CNN|^GS\d*$|^MB|^MKCNN|^SPV|^SR|^YOIGO_USER_2|_CN$|_BOCN$/
      if (
        userAuthObj.sfid &&
        !RETENTION_BREAKDOWN_SFIDS.includes(userAuthObj.sfid) &&
        (regMatcher.test(userAuthObj.sfid) || CANCELLATION_SFIDS.includes(userAuthObj.sfid))
      ) {
        return [profiles.cancelation.id]
      }
      return []
    },
  },
  {
    // RETENCIONES
    rule: (userAuthObj = {}, customerSegment = '') => {
      const regMatcher =
        /^MDIAZDEC$|^MKC(.)*_|_RBAJA$|_RBAJA2$|_ RBAJA$|_RBANG$|_RBO|_RNAVY$|_RPORT$|_RPORT2$|_ RPORT$/

      const isDeviceRenewalFlow = window.location.pathname.includes('device-renewal')

      if (
        userAuthObj.sfid && !RETENTION_BREAKDOWN_SFIDS.includes(userAuthObj.sfid) &&
        (regMatcher.test(userAuthObj.sfid) || RETENTION_SFIDS.includes(userAuthObj.sfid))
      ) {
        const agentProfileBySegment = getAgentProfileBySegment(customerSegment)
        return !isEmpty(agentProfileBySegment) && !isDeviceRenewalFlow
          ? agentProfileBySegment
          : [profiles.retention.id]
      }
      return []
    },
  },
  {
    // RETENCION-AVERIAS
    rule: (userAuthObj = {}) => {
      const regMatcher = /^N200|^ZEC|^ZE_REC/
      if (
        userAuthObj.sfid &&
        (regMatcher.test(userAuthObj.sfid) || RETENTION_BREAKDOWN_SFIDS.includes(userAuthObj.sfid))
      ) {
        return [profiles.retentionBreakdown.id]
      }
      return []
    },
  },
  {
    // FIDELIZACIÓN
    rule: (userAuthObj = {}) => {
      const regMatcher =
        /_FBAL$|_FBOF$|_FC25$|_FCAN$|_FDAT$|_FDIS$|_FF3P$|_FFCI$|_FFPC$|_FIN2$|_FIRR$|_FMA1$|_FMAS$|_FRTY$|_FTVA$|_FTYC$|_FUP2$|_FUPS2$|_FVEN$|_FWPP$|_STVA$|_UP1$|_UPS$/
      if (userAuthObj.sfid && regMatcher.test(userAuthObj.sfid)) {
        return [profiles.customer_loyalty.id]
      }
      return []
    },
  },
  {
   // FIDELIZACION OUTBOUND
    rule: (userAuthObj = {}) => {
      const regMatcher = /_F3UP$|_FFAP$|_FFLA$|_FUP1$|_FUP6$|_FUPS$|_FTER$|_FTRM$|_FTVB$|_FQ25$/ 
      if (userAuthObj.sfid && regMatcher.test(userAuthObj.sfid)) {
        return [profiles.customer_loyalty_outbound.id]
      }
      return []
    },
  },
  {
    // FIDELIZACION INBOUND
     rule: (userAuthObj = {}) => {
       const regMatcher = /_FBAN$|_FLAB$|_FREC$/ 
       if (userAuthObj.sfid && regMatcher.test(userAuthObj.sfid)) {
         return [profiles.customer_loyalty_inbound.id]
       }
       return []
     },
   },
  {
    // POS
    rule: (userAuthObj = {}) => {
      const regMatcher = /^AX|^BY|^DX|^KH|^LM|^MD|^TA|^LB|^LY|^PH/
      if (
        userAuthObj.sfid &&
        !MASPROXIMO_SFIDS.includes(userAuthObj.sfid) &&
        regMatcher.test(userAuthObj.sfid)
      ) {
        return [profiles.pos.id]
      }
      return []
    },
  },
  {
    // MASPROXIMO
    rule: (userAuthObj = {}) => {
      const regMatcher = /^MP|^MME/
      if (
        userAuthObj.sfid &&
        (regMatcher.test(userAuthObj.sfid) || MASPROXIMO_SFIDS.includes(userAuthObj.sfid))
      ) {
        return [profiles.masProximo.id]
      }
      return []
    },
  },
  {
    // D2D
    rule: (userAuthObj = {}) => {
      const regMatcher = /^D2N|^DAM|^DBL|^DPN/
      if (userAuthObj.sfid && regMatcher.test(userAuthObj.sfid)) {
        return [profiles.d2d.id]
      }
      return []
    },
  },
  {
    // BACKOFFICE
    rule: (userAuthObj = {}) => {
      const regMatcher = /^BDIAZ$|^INMA$|^ELIBARRANCO$|^ESEGURA$|^LTABARDAU$|^MOROZCO$|^SMOTA$|^EVC|^NTTBOCB|^NTT_BO_CB|^ZE_BO_LOG_|^ZE_LOG_COORD_|^ZEBO/
      if (userAuthObj.sfid && regMatcher.test(userAuthObj.sfid)) {
        return [profiles.backoffice.id]
      }
      return []
    },
  },
]

const profileIdsRules = [
  {
    // ATC
    rule: (userAuthObj = {}) => {
      const regMatcher =
        /^GSS_AVE|^GSS(?!_)|^KNC|^SRV(?!(APN|NEG|_APN|_NEG|_ATC_NEG|_ONB))|^SVFC|_ENER|^MK_AVE|^KN_AVE|^AVERIAS_KNT|^MK_ATC(?!_)|^KN_ATC(?!_)$/
      
      if (
        userAuthObj.sfid &&
        !ONBOARDING_SFIDS.includes(userAuthObj.sfid) &&
        !CANCELLATION_SFIDS.includes(userAuthObj.sfid) &&
        !RETENTION_BREAKDOWN_SFIDS.includes(userAuthObj.sfid) &&
        (every(profileIdsRulesBase, profileIdsRule => isEmpty(profileIdsRule.rule(userAuthObj))) ||
          regMatcher.test(userAuthObj.sfid) ||
          ATC_SFIDS.includes(userAuthObj.sfid))
      ) {
        return [profiles.atc.id]
      }
      return []
    },
  },
  ...profileIdsRulesBase,
]

export function getProfileIdForUser(userAuthObj, customerSegment) {
  const capitalizeUserAuthObj = { ...userAuthObj, sfid: toUpper(get(userAuthObj, 'sfid')) }

  // TODO: At the moment there should be just a single profile active
  return head(
    uniqBy(
      flatMap(profileIdsRules, profileIdsRule =>
        profileIdsRule.rule(capitalizeUserAuthObj, customerSegment),
      ),
      'id',
    ),
  )
}

function getRoles(profilesIds = []) {
  const rolesIdsFromProfiles = uniqBy(
    flatMap(Object.values(profiles), profile =>
      profilesIds.includes(profile.id) ? profile.roles : [],
    ),
    'id',
  )
  return roles.getRoles(rolesIdsFromProfiles)
}

export function getPermissions(profilesIds = []) {
  return uniqBy(
    flatMap(getRoles(profilesIds), rolesForUse => rolesForUse.permissions),
    'id',
  )
}
