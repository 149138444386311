import { put, select, take } from 'redux-saga/effects'

import { fetchOrderOnlyAction, FETCH_ORDER_SUCCESS, FETCH_ORDER_FAILED } from './orders.actions'
import { getOrderError } from './orders.selectors'

export function* callFindByIdSaga(orderId) {
  yield put(fetchOrderOnlyAction(orderId))
  yield take([FETCH_ORDER_SUCCESS, FETCH_ORDER_FAILED])
  return yield select(getOrderError)
}
