import PropTypes from 'prop-types'
import { format, isValid } from 'date-fns'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { TextStyles } from 'utils/index'
import { Card } from 'modules/ui'
import { LineItemCard } from 'components/ui'

const useStyles = makeStyles({
  'typification-detail': {
    '& .flex > .card': {
      width: '49%',
      marginTop: '5px',
      marginBottom: '24px',
    },

    '& .top-div': {
      marginBottom: '24px',
    },

    '& .three-items-line': {
      width: 'auto',
      padding: 0,
      paddingRight: '20px',
    },

    '& .text-style': {
      paddingBottom: '15px',
      paddingRight: '10px',
    },

    '& .card-with-padding': {
      padding: '11px 0',
    },

    '& .comment': {
      margin: '10px 0',
      padding: '6px 24px',
      maxHeight: '20vh',
      overflow: 'auto',
      fontSize: '16px',
      lineHeight: 1.4,
    },

    '& .no-wrap-primary-text .primary-text': {
      whiteSpace: 'nowrap',
    },
  },
})

export const TypificationDetail = ({
  data: { typification, isIframeTypification, date, sfid, phone, channel },
  className,
}) => {
  const classes = useStyles()

  const formatedDate = date && isValid(new Date(date)) && format(new Date(date), 'dd/MM/yyyy HH:mm')

  const VOID_DATA_MESSAGE = 'No aplica'

  return (
    <div
      className={[classes['typification-detail'], ...(className ? [className] : [])].join(' ')}
      data-hook="typification-detail-card">
      {formatedDate && (
        <Typography
          {...TextStyles.body({
            className: 'text-style',
          })}>
          {formatedDate} h
        </Typography>
      )}
      <div className="flex align-start-center">
        <Typography
          {...TextStyles.title1BoldDark({
            className: 'text-style',
          })}>
          {isIframeTypification ? 'Tipificación Iframe' : 'Tipificación'}
        </Typography>
        <Typography
          {...TextStyles.link({
            className: 'text-style',
          })}>
          {sfid}
        </Typography>
      </div>

      <div className="flex align-start top-div">
        <LineItemCard className="three-items-line" primaryText="MSISDN" secondaryText={phone} />
        {typification?.operator ? (
          <LineItemCard
            className="three-items-line"
            primaryText="Operador Relacionado"
            secondaryText={typification.operator}
          />
        ) : null}
        <LineItemCard className="three-items-line" primaryText="Campaña" secondaryText={channel} />
      </div>

      <div className="flex align-space-between-stretch">
        <Card title="Motivos" withShadow>
          <div className="card-with-padding">
            <LineItemCard
              className="no-wrap-primary-text"
              primaryText="Motivo"
              secondaryText={typification?.reason ? typification.reason : VOID_DATA_MESSAGE}
            />
            <LineItemCard
              className="no-wrap-primary-text"
              primaryText="Submotivo 1"
              secondaryText={typification?.subReason1 ? typification.subReason1 : VOID_DATA_MESSAGE}
            />
            <LineItemCard
              className="no-wrap-primary-text"
              primaryText="Submotivo 2"
              secondaryText={typification?.subReason2 ? typification.subReason2 : VOID_DATA_MESSAGE}
            />
          </div>
        </Card>

        <Card title="Acción realizada" withShadow>
          <div className="card-with-padding">
            { isIframeTypification ? 
              <>
                <LineItemCard
                  primaryText="Resultado"
                  secondaryText={typification?.result ? typification.result : VOID_DATA_MESSAGE}
                />
                <LineItemCard
                  primaryText="Detalle"
                  secondaryText={typification?.subresult ? typification.subresult : VOID_DATA_MESSAGE}
                />
              </>
            : 
              <>
                <LineItemCard
                  primaryText="Retenido / No retenido"
                  secondaryText={typification?.retained1 ? typification.retained1 : VOID_DATA_MESSAGE}
                />
                <LineItemCard
                  primaryText="Detalle"
                  secondaryText={typification?.retained2 ? typification.retained2 : VOID_DATA_MESSAGE}
                />
              </>
            }
          </div>
        </Card>
      </div>

      <Card title="Comentario" withShadow>
        <Typography
          {...TextStyles.body({
            className: 'comment',
          })}>
          {typification.description || 'No hay comentarios.'}
        </Typography>
      </Card>
    </div>
  )
}

TypificationDetail.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object,
}
