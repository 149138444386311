import { useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import { Typography } from '@material-ui/core'
import { TextStyles } from 'utils/text'
import { useTypificationsListContainerStyles } from 'modules/typifications/styles'
import { getRegistersTypifications } from '../../store'
import TypificationList from './components/TypificationList'

export const TypificationListContainer = () => {
  const typificationsListContainerClasses = useTypificationsListContainerStyles()

  const registersData = useSelector(getRegistersTypifications)

  return (
    <>
      {!isEmpty(registersData) ? (
        <TypificationList {...{ registersData }} />
      ) : (
        <>
          <div className={typificationsListContainerClasses.notificationContainer}>
            <Typography {...TextStyles.dataDark()}>
              Actualmente no hay registros disponibles.
            </Typography>
          </div>
        </>
      )}
    </>
  )
}
