import { useState } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { LineItemCard, DoubleLineItemCard } from 'components/ui'
import { Card } from 'modules/ui'
import { getContractPdf } from 'services/contract-files'
import { logError, formatErrorMessage } from 'services/logging'
import { openBlob, buildBlob } from 'utils'

import { APP_CONFIG } from 'services/app-config'
import { is2pPureTariff } from 'modules/vista-360/helpers/helpers'
import { selectIsPosOrMasProximo } from 'modules/Permissions'
import { formatDataFlow } from '../OrderMain/helpers'

const { basicToken } = APP_CONFIG

function OrderCard({ data }) {
  const [loadingDownload, setLoadingDownload] = useState(false)

  const onDownload = contractId => {
    setLoadingDownload(true)

    getContractPdf(contractId)
      .then(blob => {
        const blobBuilt = buildBlob([blob], 'pdf')
        openBlob(blobBuilt)
      })
      .catch(e => logError(new Error(formatErrorMessage(e))))
      .then(() => setLoadingDownload(false))
  }

  const { orderId, dateOrder, channel, sfid, contractId, flow, products } = data

  const isPosOrMasProximo = useSelector(selectIsPosOrMasProximo)
  const is2pPure = !!products.find(product => is2pPureTariff(product.type))

  return (
    <Card title="Pedido" id="Pedido" withShadow>
      <LineItemCard primaryText="Nº Pedido" secondaryText={orderId} />
      <LineItemCard primaryText="Fecha de pedido" secondaryText={dateOrder} />
      <LineItemCard primaryText="Canal de venta" secondaryText={channel} />
      <LineItemCard primaryText="SFID" secondaryText={sfid} />
      <DoubleLineItemCard
        primaryText="Contrato generado"
        secondaryText={contractId}
        listActions={
          !isPosOrMasProximo ? [
            {
              icon: 'download',
              action: () => onDownload(contractId, basicToken),
              loading: loadingDownload,
            },
          ] : null
        }
      />
      {!is2pPure && (
        <LineItemCard primaryText="Tipo de Flujo" secondaryText={formatDataFlow(flow)} />
      )}
    </Card>
  )
}

OrderCard.propTypes = {
  data: PropTypes.object,
}

export default OrderCard
