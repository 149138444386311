const ADDRESS_DETAILS = 'addressDetails'
const ADDRESS_FORM = 'addressForm'
const NEW_APPOINTMENT = 'newAppointment'
const ADDRESS_SUMMARY = 'addressSummary'
const AUX_ADDRESS_SUMMARY_FAILED = 'auxAddressSummary_FAILED'
const CHECK_GESCAL = 'checkGescal17'

export const constants = {
  ADDRESS_DETAILS,
  ADDRESS_FORM,
  NEW_APPOINTMENT,
  ADDRESS_SUMMARY,
  AUX_ADDRESS_SUMMARY_FAILED,
  CHECK_GESCAL,
}
