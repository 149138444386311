import { axiosJWT } from 'modules/axios'
import { APP_CONFIG } from 'services/app-config'

const welcomeLetterURL = `${APP_CONFIG.sales_cluster}/v1/welcome-letter/pdf`

export function fetchWelcomeLetter(
  tariffName,
  postalCode,
  tariffType,
  segment,
  price,
  fixedLineNumber,
  mobileNumber,
  clientName,
  paymentType,
) {
  return axiosJWT({
    method: 'POST',
    url: welcomeLetterURL,
    params: { tariffType },
    data: {
      tariffName,
      postalCode,
      segment,
      price,
      fixedNumber: fixedLineNumber,
      mobileNumber,
      clientName,
      payment: paymentType,
    },
    responseType: 'blob',
  }).then(resp => {
    const url = window.URL.createObjectURL(new Blob([resp.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', 'welcome-letter.pdf')
    document.body.appendChild(link)
    link.click()
  })
}
