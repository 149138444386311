import { isEmpty } from 'lodash'
import { axiosCustomer, axiosJWT } from 'modules/axios'
import { APP_CONFIG } from 'services/app-config'

export function findAlarms(customerId, msisdns = [], documentType, documentId) {
  const msisdnsStr = msisdns.join(',')

  return axiosJWT({
    method: 'GET',
    headers: {
      'X-Authorization': axiosCustomer.defaults.headers.common.Authorization,
    },
    url: `${APP_CONFIG.care_cluster}/search-360/customer/${customerId}/alarms`,
    params: isEmpty(msisdnsStr)
      ? { documentType, documentId }
      : { msisdns: msisdnsStr, documentType, documentId },
    documentType,
    documentId,
  }).then(resp => resp.data)
}
