import { useState } from 'react'
import { useSelector } from 'react-redux'
import { selectTaskId } from 'modules/NewClientSales/store/selectors'
import { fetchReadableContract } from '../../services/readableContract.service'

export function useDownloadReadableContract() {
  const [error, setError] = useState(false)
  const taskId = useSelector(selectTaskId)

  function downloadReadableContract() {
    setError(false)
    fetchReadableContract(taskId)
      .then(() => setError(false))
      .catch(() => setError(true))
  }

  return { downloadReadableContract, readableContractError: error }
}
