/* eslint-disable import/no-cycle */
import PropTypes from 'prop-types'
import { Card, CardContent, Box } from '@material-ui/core'
import { CARTERA_ERROR } from 'modules/SharedSales/constants'

import {
  LandlineContainer,
  ExtraLinesContainer,
  MainMobileContainer,
} from '../../containers/OfferContainer'

// import { composeExtraMobileLineKey } from './Offer.helpers'
import { BackButton } from '../Common/BackButton'
import SaveChangesButton from '../Common/SaveChangesButton'
import { ErrorModal } from '../Common'

const Offer = ({
  beforeContinue,
  onContinue,
  isEditing,
  fieldNames,
  setBasketData,
  mainMobileTariff,
  isConvergent,
  isElFijo,
  is2p,
  isLoading,
  carteraUpdateError,
  onCloseModal,
  isSubscription,
  isCrossSell,
}) => {
  return (
    <>
      {!isSubscription && (
        <Card className="m-y-32">
          <CardContent style={{ paddingBottom: '0' }}>
            {(isConvergent || is2p || isCrossSell) && <LandlineContainer fieldNames={fieldNames} />}
            {!is2p && (
              <MainMobileContainer
                selectedTariff={mainMobileTariff}
                showLineData
                showPackages
                isStockCheck
              />
            )}
          </CardContent>
        </Card>
      )}
      {!isElFijo && !is2p && <ExtraLinesContainer />}
      <Box display="flex">
        <BackButton goTo="info" nomargin />
        <SaveChangesButton
          stepName="OfferForm"
          isLoading={isLoading}
          setBasketData={setBasketData}
          onContinue={onContinue}
          beforeContinue={beforeContinue}
          isOffer
          isEditing={isEditing}
        />
      </Box>

      <ErrorModal
        hasMsError={carteraUpdateError}
        error={CARTERA_ERROR}
        onCloseModal={onCloseModal}
      />
    </>
  )
}

Offer.propTypes = {
  isEditing: PropTypes.bool,
  onContinue: PropTypes.func.isRequired,
  setBasketData: PropTypes.func.isRequired,
  beforeContinue: PropTypes.func.isRequired,
  fieldNames: PropTypes.object,
  isConvergent: PropTypes.bool,
  isElFijo: PropTypes.bool,
  is2p: PropTypes.bool,
  isLoading: PropTypes.bool,
  carteraUpdateError: PropTypes.bool,
  onCloseModal: PropTypes.func,
  isSubscription: PropTypes.bool,
  isCrossSell: PropTypes.bool,
  mainMobileTariff: PropTypes.object,
}
export default Offer
