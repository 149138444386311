import { useMemo } from 'react'
import { get, chain } from 'lodash'
import { useSelector } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { selectRouter } from 'modules/Router/store'

import {
  getOrdersCustomer,
  getIdOrderFromOrdersCustomer,
  getOrderLoading,
  getOrderError,
} from 'modules/orders'
import { selectOrder, selectErrorMessage } from 'services/product-orders'
import { getCustomerInfo } from 'services/global-selectors/selectors'
import { selectIsLogged } from 'modules/Auth'

const lastCustomerWithProducts = state => {
  const realCustomer = get(state, 'customerNew.data', {})
  const infoCustomer = get(selectOrder(state), 'customer', {})
  const individualIdentification = get(realCustomer, 'individualIdentification')
  const params = new URLSearchParams(get(state, 'router.location.search', ''))
  const fixedNumber = params.get('fixedNumber')
  const suscriptions = chain(state)
    .get('subscriptions.subs', {})
    .get(fixedNumber)
    .get('data')
    .value()

  return {
    ...infoCustomer,
    firstName: get(realCustomer, 'firstName', ''),
    lastName: get(realCustomer, 'lastName', ''),
    midName: get(realCustomer, 'midName', ''),
    individualIdentification: get(realCustomer, 'individualIdentification'),
    typeId: chain(individualIdentification)
      .head()
      .get('type')
      .value(),
    id: chain(individualIdentification)
      .head()
      .get('identificationId')
      .value(),
    products: [
      {
        id: get(suscriptions, 'id', ''),
        type: get(suscriptions, 'type', ''),
        description: get(suscriptions, 'description', false),
        productNumber: get(suscriptions, 'productNumber', ''),
        productsSpecifications: get(suscriptions, 'productsSpecifications', []),
      },
    ],
  }
}

const loadingSidebarInfo = state => {
  if (
    get(state, 'subscriptions.loading') === false &&
    get(state, 'customerNew.loading') === false &&
    get(state, 'productOrders.loading') === false
  ) {
    return false
  }
  return true
}
const layoutVariables = [
  {
    match: /\/clients\/orders\/\d+\/[actions(/?)|address/edit(/?)]/g,
    selectors: {
      customer: state => getOrdersCustomer(state),
      orderId: state => getIdOrderFromOrdersCustomer(state),
      customerInfo: state => getCustomerInfo('InstallationAddressFixedLine', 'orders')(state),
      orderLoading: state => getOrderLoading(state),
      orderError: state => getOrderError(state),
    },
  },
  {
    match: '/clients/change-technology',
    selectors: {
      customer: state => lastCustomerWithProducts(state),
      orderId: state => get(selectOrder(state), 'id'),
      customerInfo: state => lastCustomerWithProducts(state),
      orderLoading: state => loadingSidebarInfo(state),
      orderError: selectErrorMessage,
    },
  },
  {
    match: '/clients/move-client',
    selectors: {
      customer: state => lastCustomerWithProducts(state),
      orderId: state => get(selectOrder(state), 'id'),
      customerInfo: state => lastCustomerWithProducts(state),
      orderLoading: state => loadingSidebarInfo(state),
      orderError: selectErrorMessage,
    },
  },
  {
    match: '/clients/status-order-on-fly',
    selectors: {
      customer: state => lastCustomerWithProducts(state),
      orderId: state => get(selectOrder(state), 'id'),
      customerInfo: state => lastCustomerWithProducts(state),
      orderLoading: state => loadingSidebarInfo(state),
      orderError: selectErrorMessage,
    },
  },
]

export const useLayoutData = () => {
  const router = useSelector(selectRouter)
  const isLogged = useSelector(selectIsLogged)

  const structuredSelectors = useMemo(() => {
    const pathname = get(router, 'location.pathname')
    if (!pathname) {
      return null
    }

    const data = layoutVariables.find(each => pathname.match(each.match))
    if (!isLogged) delete data.selectors.customer

    return createStructuredSelector(data.selectors)
  }, [router, isLogged])

  return useSelector(structuredSelectors)
}
