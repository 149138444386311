import { useFormikContext } from 'formik'
import { get } from 'lodash'

import { COMPANY } from 'services/global-constants'

import { useIsCustomerDocumentValidation } from '../../../hooks/Validations'

export function useLeadValidation({ isCartera, isCrossSell, isMultiSim }) {
  const { validateCustomer, errorCustomer, hasMsErrorCustomer, fieldCustomer, valueCustomer } =
    useIsCustomerDocumentValidation()

  const { values, setStatus, status } = useFormikContext()
  const { segment } = get(values, 'account', {})

  function getDocumentFieldName() {
    return segment === COMPANY ? 'account.companyCif' : 'account.documentid'
  }

  function setErrorStatus(validation) {
    const field = getDocumentFieldName()
    setStatus({ ...status, [field]: validation })
  }

  async function validateIfClientIsRegistered({ value, isMainDocument, fromDocument, docType }) {
    let validation
    if (isMainDocument && !isCartera && !isCrossSell && !isMultiSim) {
      setErrorStatus(null)
      validation = await validateCustomer(value, fromDocument, docType)
    }
    setErrorStatus(validation)

    return validation
  }

  return {
    validateIfClientIsRegistered,
    error: errorCustomer,
    hasMsError: hasMsErrorCustomer,
    fieldName: fieldCustomer,
    fieldvalue: valueCustomer,
    resetMsError: () => setErrorStatus(null),
  }
}
