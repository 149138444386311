import { push } from 'connected-react-router'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'

export const useStepNavigation = (steps, stepName) => {
  const dispatch = useDispatch()
  const { search } = useLocation()

  const navigateToStep = step => {
    dispatch(push(step + search))
  }

  const goBack = () => {
    const currentStepIndex = steps.indexOf(stepName)

    if (currentStepIndex === -1 || currentStepIndex === 0) {
      return
    }

    navigateToStep(steps[currentStepIndex - 1])
  }

  const navigateToNextStep = () => {
    const currentStepIndex = steps.indexOf(stepName)

    if (currentStepIndex === -1 || currentStepIndex === steps.length - 1) {
      return
    }

    navigateToStep(steps[currentStepIndex + 1])
  }

  return { navigateToNextStep, goBack }
}
