import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { isEmpty, find, flatMap } from 'lodash'
import { useDispatch } from 'react-redux'

import { Checkbox, FormControl, FormControlLabel } from '@material-ui/core'

import { Button, ModalActions, SpinnerCenter } from 'components/ui'
import { SVA_STATUS_ENABLED, SVA_STATUS_DISABLED, getMsisdn } from 'services/subscriptions'

import { setSubscriptionSvas } from '../../actions'
import { ModalContent } from '../ModalContent'

import { SVA_HEBE_CODE, SVA_HOMEGO_CODE, SVA_SMARTHOME_CODE } from '../../constants'

const AddSvasModal = ({ subscription, svas = [], onClose, onConfirmSvas, isLoadingSvas }) => {
  const dispatch = useDispatch()

  const onSetSvas = changes => {
    const msisdn = getMsisdn(subscription)

    return dispatch(setSubscriptionSvas(msisdn, changes))
  }

  const [selectedSvas, setSelectedSvas] = useState([])

  const toggleSva = svaId => setSelectedSvas([svaId])

  const generateSvaCheckbox = ({ svaId, name: svaName, subSvas = [] }) => {
    const isChecked =
      selectedSvas.includes(svaId) ||
      (subSvas && !!subSvas.find(subSva => selectedSvas.includes(subSva.svaId)))

    return (
      <div key={`sublist_${svaId}`}>
        <FormControlLabel
          key={svaId}
          control={
            <Checkbox
              key={svaId}
              color="primary"
              className="checkbox"
              value={svaId}
              checked={isChecked}
              onClick={() => toggleSva(svaId, subSvas)}
            />
          }
          label={svaName}
        />
        {isChecked && subSvas && (
          <div className="m-l-20">{subSvas.map(sva => generateSvaCheckbox(sva))}</div>
        )}
      </div>
    )
  }

  generateSvaCheckbox.propTypes = {
    svaId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    subSvas: PropTypes.array,
  }

  const getSvaChanges = () => {
    const subSvas = flatMap(svas, 'subSvas')
    const allSvas = [...svas, ...subSvas]

    const changes = allSvas
      .filter(
        sva => sva.status !== selectedSvas.includes(sva?.svaId) && sva?.svaId !== SVA_HOMEGO_CODE,
      )
      .map(modifiedSva => ({
        ...modifiedSva,
        status: selectedSvas.includes(modifiedSva?.svaId)
          ? SVA_STATUS_ENABLED
          : SVA_STATUS_DISABLED,
      }))

    return changes
  }

  const confirmSetSva = () => {
    const changes = getSvaChanges()

    if (find(changes, { svaId: SVA_HEBE_CODE })) {
      onSetSvas(getSvaChanges())
    }
    onClose()

    if (
      (find(changes, { svaId: SVA_SMARTHOME_CODE }) ||
        find(changes, element => element.svaId?.includes(SVA_HOMEGO_CODE))) &&
      onConfirmSvas
    ) {
      onConfirmSvas(changes)
    }
  }

  useEffect(() => {
    const preselectedSvas = svas.filter(sva => sva.status).map(selectedSva => selectedSva.svaId)
    setSelectedSvas(preselectedSvas)
  }, [])

  return (
    <>
      <ModalContent data-hook="modal-set-sva">
        <div className="m-b-8">
          <p>Elige los servicios que quieres poner al contrato y haz click en confirmar</p>
        </div>

        {isLoadingSvas ? (
          <SpinnerCenter size={48} style={{ marginTop: '48px' }} />
        ) : (
          <FormControl component="fieldset">
            {svas.map(sva => generateSvaCheckbox(sva))}
          </FormControl>
        )}

        <ModalActions>
          <Button
            data-hook="action-set-sva-submit"
            disabled={isEmpty(getSvaChanges())}
            onClick={() => confirmSetSva()}>
            Confirmar
          </Button>
        </ModalActions>
      </ModalContent>
    </>
  )
}

AddSvasModal.propTypes = {
  svas: PropTypes.arrayOf(
    PropTypes.shape({
      svaId: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      status: PropTypes.bool,
    }),
  ).isRequired,
  subscription: PropTypes.object,
  onClose: PropTypes.func,
  onConfirmSvas: PropTypes.func,
  isLoadingSvas: PropTypes.bool,
}

AddSvasModal.defaultProps = {
  svas: [],
}

export default AddSvasModal
