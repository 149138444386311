import { chain } from 'lodash'
import { APP_CONFIG } from 'services/app-config'

const getContentfulFieldName = (response, field) => {
  if (field) return response[field]
  return APP_CONFIG.production ? response.text : response && (response.textDev || response.text)
}

export function getPropertyFromResponse(response, isNextSteps) {
  return chain(response)
    .keyBy('operation')
    .mapValues(value => getContentfulFieldName(value, isNextSteps))
    .value()
}
