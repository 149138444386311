import { memo } from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import { isEqual, isEmpty } from 'lodash'
import { helpers as customer360SvcHelpers } from 'services/customer-360/helpers'
import { AnimatedSwitch } from 'modules/Router'
import { AuthenticatedRoute } from 'modules/Auth'
import { ChangeOwnerContainer } from 'modules/change-owner'
import { AccountDashboard } from 'modules/vista-360/components/AccountDashboard'

import { objectToQueryString } from 'modules/url/index'
import { ClientEnergyContainer } from 'modules/energy'

import { PrepaidLineDetailContainer } from '../../containers/PrepaidLineDetailContainer'
import { ClientDashboardContainer } from '../../containers/ClientDashboardContainer'
import { OrderDashboardContainer } from '../../containers/OrderDashboardContainer'
import { OrderDetailContainer } from '../../containers/OrderDetailContainer'
import { ProductRule } from './Routes.rules'

export const Routes = memo(
  ({
    isCustomer,
    orders,
    contracts,
    matchUrl,
    queryParams,
    energyIsAllowed,
    canEnergyViewContracts,
  }) => {
    const lastOrderId = customer360SvcHelpers.getLastOrderId(orders)

    return (
      <AnimatedSwitch>
        <AuthenticatedRoute
          isCustomer
          exact
          path={`${matchUrl}/changeOwner`}
          component={ChangeOwnerContainer}
        />
        <AuthenticatedRoute
          isCustomer
          exact
          path={`${matchUrl}/orderDetail/:id`}
          component={OrderDetailContainer}
        />

        <AuthenticatedRoute
          isCustomer
          exact
          path={`${matchUrl}/line/:id`}
          render={props => (
            <ProductRule
              parentUrl={matchUrl}
              contracts={contracts}
              queryValues={queryParams}
              {...props}
            />
          )}
        />

        <AuthenticatedRoute
          isCustomer
          exact
          path={`${matchUrl}/line/:id/changeOwner`}
          component={ChangeOwnerContainer}
        />
        <AuthenticatedRoute
          isCustomer
          exact
          path={`${matchUrl}/order/:id`}
          render={() => <OrderDashboardContainer />}
        />
        <AuthenticatedRoute
          isCustomer
          exact
          path={`${matchUrl}/line/:id/prepaid-detail`}
          component={PrepaidLineDetailContainer}
        />

        {!isEmpty(orders) && (
          <AuthenticatedRoute
            isCustomer
            exact
            path={`${matchUrl}/customer-account`}
            component={AccountDashboard}
          />
        )}

        {energyIsAllowed && canEnergyViewContracts && (
          <AuthenticatedRoute exact path={`${matchUrl}/energy`} component={ClientEnergyContainer} />
        )}

        {isCustomer ? (
          <>
            {isEmpty(orders) && (
              <AuthenticatedRoute
                isCustomer
                exact
                path={`${matchUrl}/customer-account`}
                component={AccountDashboard}
              />
            )}

            <AuthenticatedRoute exact path={matchUrl} component={ClientDashboardContainer} />
          </>
        ) : (
          lastOrderId && (
            // When is not a customer, we redirect to the last order
            <AuthenticatedRoute
              render={() => (
                <Redirect
                  to={`${matchUrl}/order/${lastOrderId}?${objectToQueryString(queryParams)}`}
                />
              )}
            />
          )
        )}
      </AnimatedSwitch>
    )
    // We could accept match param if we memoize all params,
    // but is easier to receive match.url directly and match only by primitive types
  },
  (prevProps, nextProps) =>
    prevProps.isCustomer === nextProps.isCustomer &&
    isEqual(prevProps.orders, nextProps.orders) &&
    isEqual(prevProps.contracts, nextProps.contracts) &&
    prevProps.matchUrl === nextProps.matchUrl &&
    isEqual(prevProps.queryParams, nextProps.queryParams) &&
    prevProps.energyIsAllowed === nextProps.energyIsAllowed &&
    prevProps.canEnergyViewContracts === nextProps.canEnergyViewContracts &&
    prevProps.isPosOrMasProximo === nextProps.isPosOrMasProximo,
)

Routes.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
  isCustomer: PropTypes.bool,
  orders: PropTypes.array,
  contracts: PropTypes.array,
  matchUrl: PropTypes.string,
  queryParams: PropTypes.object,
  energyIsAllowed: PropTypes.bool,
  canEnergyViewContracts: PropTypes.bool,
  isPosOrMasProximo: PropTypes.bool,
}
