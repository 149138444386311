const GET_DISCOUNTS_INIT = 'OVID/DISCOUNTS_AGENT/GET_DISCOUNTS_INIT'
const GET_DISCOUNTS = 'OVID/DISCOUNTS_AGENT/GET_DISCOUNTS'
const GET_DISCOUNTS_SUCCESS = 'OVID/DISCOUNTS_AGENT/GET_DISCOUNTS_SUCCESS'
const GET_DISCOUNTS_FAILED = 'OVID/DISCOUNTS_AGENT/GET_DISCOUNTS_FAILED'

export const constants = {
  GET_DISCOUNTS_INIT,
  GET_DISCOUNTS,
  GET_DISCOUNTS_SUCCESS,
  GET_DISCOUNTS_FAILED,
}
