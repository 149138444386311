import { get, filter, startsWith, keys } from 'lodash'
import { checkIsNeba as isNebaTerritory, checkIsVula } from 'modules/Coverage'
import {
  DUO_TARIFFS_IDS,
  SINFIN_REGEX,
  SINFIN_MOBILE_TARIFF_IDS,
  CVM_TARIFF_RETENTION_MOBILE_RETIRED,
  CVM_TARIFF_RETENTION_MOBILE,
  CVM_TARIFF_RETENTION_MOBILE_PRO,
} from 'modules/offers-configuration/constants'
import { ADDITIONAL_LINE_CATEGORY_EL_FIJO } from 'modules/orders/constants'
import { MULTISIM_LINE } from 'services/customer-360/constants'
import {
  ADSL,
  FIBER,
  TARIFF_CATEGORY_3P,
  STATUS_ACTIVE,
  STATUS_RETIRED,
  STATUS_HIDDEN,
  CATEGORY_2P,
  CATEGORY_2P_FREE_LINE,
  CATEGORY_MOBILE,
  CATEGORY_MOBILE_V3,
  CATEGORY_CONVERGENT,
  RESIDENTIAL_SUFIX,
  PREPAID,
  POSTPAID,
  NEBA_CANCELLATIONS_AUTHORIZED_SFIDS_PREFIX,
  INDIRECT_FIBER,
  VULA_SUFIX,
  TARIFFS_WITH_OLD_PORTFOLIO_PERMISSION,
} from '../tariffs.constants'

export const getUniqueKey = (object = {}) => {
  const sortedKeys = Object.keys(object).sort()
  return sortedKeys.reduce((acc, key) => `${acc}#${key}:${object[key]}`, '#')
}

export const isActiveTariff = tariff => get(tariff, 'status') === STATUS_ACTIVE

export const isRetiredTariff = tariff => get(tariff, 'status') === STATUS_RETIRED

export const isHiddenTariff = tariff => get(tariff, 'status') === STATUS_HIDDEN

export const isTariffWithStatus = (tariff, status) => get(tariff, 'status') === status

export const is2pTariff = tariff => get(tariff, 'category') === CATEGORY_2P

export const is2pFreeLineTariff = (tariff, v3 = false) => {
  const category = v3 ? get(tariff, 'tariffType') : get(tariff, 'category')

  return category === CATEGORY_2P_FREE_LINE
}

export const isElFijoTariff = tariff => get(tariff, 'category') === ADDITIONAL_LINE_CATEGORY_EL_FIJO

export const categoriesTariff2P_3P = tariff => {
  if (!tariff || !tariff.category) {
    return undefined
  }
  return is2pTariff(tariff) ? CATEGORY_2P : TARIFF_CATEGORY_3P
}
export const isMobileTariff = (tariff, v3 = false) =>
  v3
    ? get(tariff, 'tariffType') === CATEGORY_MOBILE_V3
    : get(tariff, 'category') === CATEGORY_MOBILE

export const isConvergentTariff = (tariff, v3 = false) => {
  const category = v3 ? get(tariff, 'tariffType') : get(tariff, 'category')

  return category === CATEGORY_CONVERGENT
}

export const isAdslTariff = (tariff, v3 = false) => {
  const type = v3 ? get(tariff, 'broadband_type') : get(tariff, 'fixed_line_type')

  return type === ADSL
}

export const isFtthTariff = (tariff, v3 = false) => {
  const type = v3 ? get(tariff, 'broadband_type') : get(tariff, 'fixed_line_type')

  return type === FIBER
}

const hasVulaSufix = (tariff, v3 = false) => {
  const idTariff = v3 ? get(tariff, 'psId', '') : get(tariff, 'id', '')
  const endsWithSufix = VULA_SUFIX.some(sufix => idTariff.endsWith(sufix))

  return endsWithSufix
}

export const isCurrentTariffNebaFiber = territoryOwner => territoryOwner.includes('NEB')
export const isCurrentTariffVulaFiber = territoryOwner => territoryOwner.includes('NBL')

const getIndirectFiberType = (tariff, v3 = false) =>
  v3 ? get(tariff, 'broadband_type') : get(tariff, 'fiber_type')

export const isNebaTariff = (tariff, v3 = false) =>
  getIndirectFiberType(tariff, v3) === INDIRECT_FIBER && !hasVulaSufix(tariff, v3)

const isOkFiberTypeAndDownloadSpeed = (tariff, speed, v3 = false) => {
  const isCorrectSpeed = v3
    ? get(tariff, 'internetDownloadSpeed') === speed
    : get(tariff, 'fixed_line_upload_speed') === speed &&
      get(tariff, 'fixed_line_download_speed') === speed

  return isCorrectSpeed && getIndirectFiberType(tariff, v3) === INDIRECT_FIBER
}

const is1000MIndirectTariff = (tariff, v3 = false) =>
  isOkFiberTypeAndDownloadSpeed(tariff, '1000M', v3)

export const is600MIndirectTariff = (tariff, v3 = false) =>
  isOkFiberTypeAndDownloadSpeed(tariff, '600M', v3)

export const is300MIndirectTariff = (tariff, v3 = false) =>
  isOkFiberTypeAndDownloadSpeed(tariff, '300M', v3)

export const isTariffWithPortfolioStcPermission = tariff =>
  TARIFFS_WITH_OLD_PORTFOLIO_PERMISSION.includes(get(tariff, 'id'))

export const isVulaTariff = (tariff, v3 = false) => {
  return (
    is1000MIndirectTariff(tariff, v3) ||
    is600MIndirectTariff(tariff, v3) ||
    is300MIndirectTariff(tariff, v3) ||
    (hasVulaSufix(tariff, v3) && getIndirectFiberType(tariff, v3) === INDIRECT_FIBER)
  )
}

export const getPaymentType = tariff => get(tariff, 'payment_type', '')

export const isPrepaidTariff = tariff => getPaymentType(tariff) === PREPAID

export const isPostpaidTariff = tariff => getPaymentType(tariff) === POSTPAID

export const isResidential = tariffId => tariffId.endsWith(RESIDENTIAL_SUFIX)

const filterNebaTariffs = (tariffs, isFeatNebaEnabled) =>
  isFeatNebaEnabled ? filter(tariffs, tariff => isNebaTariff(tariff)) : []

export const filterTariffs = (tariffs, territoryOwner, isFeatNebaEnabled, currentTariff = null) => {
  if (!tariffs) return undefined
  const filteredTariffs = is2pTariff(currentTariff)
    ? filter(tariffs, tariff => is2pTariff(tariff))
    : tariffs

  if (isNebaTerritory(territoryOwner)) {
    return filterNebaTariffs(filteredTariffs, isFeatNebaEnabled)
  }
  if (checkIsVula(territoryOwner)) {
    return filter(filteredTariffs, tariff => isVulaTariff(tariff))
  }

  if (is2pTariff(currentTariff)) {
    return filter(filteredTariffs, tariff => !isNebaTariff(tariff) && !isVulaTariff(tariff))
  }

  return filter(
    filteredTariffs,
    tariff => !isNebaTariff(tariff) && !isVulaTariff(tariff) && !is2pTariff(tariff),
  )
}

export const isSfidCancelNebaAuthorized = sfid =>
  NEBA_CANCELLATIONS_AUTHORIZED_SFIDS_PREFIX.some(authorizedSfid =>
    startsWith(sfid, authorizedSfid),
  )

export function getMonthlyFee(tariff) {
  return (tariff && tariff.monthly_fee) || 0
}

export const isDuoTariffId = tariffId => DUO_TARIFFS_IDS.includes(tariffId)

export const isMultisimLine = multisimType => multisimType === MULTISIM_LINE

export function getTariffId(tariff, v3 = false) {
  return v3 ? get(tariff, 'psId') : get(tariff, 'id')
}

export function getCommercialName(tariff, v3 = false, multisimType) {
  if (tariff) {
    const tariffId = getTariffId(tariff, v3)
    if (isDuoTariffId(tariffId)) {
      return tariffId === 'CONT28' ? 'LA DUO Principal' : 'LA DUO 9 euros'
    }

    return v3 ? get(tariff, 'commercialInformation.name') : get(tariff, 'commercial_name')
  }
  // Remove this if when remove param v3
  if (isMultisimLine(multisimType)) {
    return 'La MultiSIM'
  }
  return ''
}

export const isSinfinTariff = tariff => SINFIN_REGEX.test(getCommercialName(tariff))

export const isSinfinMobileTariff = tariff => SINFIN_MOBILE_TARIFF_IDS.includes(get(tariff, 'id'))

export const isNewSinfinMobileTariffRetired = tariff =>
  CVM_TARIFF_RETENTION_MOBILE_RETIRED === get(tariff, 'id')

export const isNewSinfinMobileTariff = tariff => CVM_TARIFF_RETENTION_MOBILE === get(tariff, 'id')

export const isSinfinMobileTariffPro = tariff =>
  CVM_TARIFF_RETENTION_MOBILE_PRO === get(tariff, 'id')

export const getConvergentRelatedMobileTariff = (convergentTariffId, tariffs) => {
  const TARIFF_MOBILE_SUFFIX = 'GR'

  const tariffRegEx = /^(CONT)(FHF|FHE|FHD|FHN|FHT|DSLA|DSL)([A-Z0-9_]+)$/
  const mobileLineTariffSuffixRegEx = /^(CONT[0-9_]+)(GNHR|GNTR|GNR|GTR|GHR|GR)([0-9_]{0,2})$/

  const staticTariffMap = {
    CONTDSL3G: `CONT003${TARIFF_MOBILE_SUFFIX}`,
  }

  if (!tariffRegEx.test(convergentTariffId) && !staticTariffMap[convergentTariffId]) {
    return null
  }

  const mobileRelatedTariffId = staticTariffMap[convergentTariffId]
    ? staticTariffMap[convergentTariffId]
    : convergentTariffId
        .replace(tariffRegEx, '$1$3')
        .replace(mobileLineTariffSuffixRegEx, `$1${TARIFF_MOBILE_SUFFIX}`)

  const hasRelatedMobileTariff = keys(tariffs).includes(mobileRelatedTariffId)

  return hasRelatedMobileTariff ? tariffs[mobileRelatedTariffId] : null
}

export const getTariffSegment = tariff => get(tariff, 'segment_type')
