import { Component } from 'react'
import PropTypes from 'prop-types'
import { get, uniq, isString, isEqual } from 'lodash'

import { Button, Box, Divider } from '@material-ui/core'

import { CardTitle, CardRow, CardRowLabel, CardRowValue } from 'components'
import { SpinnerButton } from 'components/ui'
import { showCoverageStreet } from '../AddressChange/address.helper'
import { CancelOrderModal } from '../CancelOrderModal'
import { CHANGE_ADDRESS } from '../../constants/cancellations'
import { Modal } from '../Modal'

class AddressSummary extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showModal: false,
      modalState: -1,
      modal: false,
      isDisabled: false,
      isLoading: false,
    }
  }

  componentDidUpdate(newProps) {
    const { order, ponc, orderStatusError, wizardError } = this.props

    if (isEqual(newProps, this.props)) return

    if (order.loading === false && orderStatusError === null && !wizardError && !ponc.error) {
      this.setState({ modal: true })
      this.setState({ isLoading: false })
    } else if (order.loading === false || ponc.error) {
      this.setModalMessage(this.props)
      this.setState({ showModal: true })
      this.setState({ isLoading: false })
    }
  }

  onClose = () => {
    window.close()
  }

  onModalClose = () => {
    this.setState({ showModal: false, modalState: -1 })
    window.close()
  }

  onAccept = () => {
    this.handleCancelOrder()
    this.setState({ isDisabled: true })
  }

  setModalMessage = props => {
    const hasError = props.order.error_code || props.ponc.error
    let result
    if (!hasError && !props.ponc.reached) {
      result = 4
    } else if (props.ponc.reached) {
      result = 1
    } else {
      result = 2
    }

    this.setState({ modalState: result, isDisabled: false })
  }

  handleCancelOrder = () => {
    const { cancelOrder, orderId } = this.props

    cancelOrder(orderId, {
      orderId,
      body: {
        reason: 'No tiene cobertura o order error',
        date: new Date(),
        email: '',
        clientName: '',
      },
      notify: false,
    })
  }

  handleUpdateOrder = () => {
    const { updateOrder, orderId } = this.props

    updateOrder(orderId)

    this.setState({ isLoading: true })
  }

  validCoverage = () => {
    const coverage = get(this.props, 'coverageData.coverage', [])
    const isEmptyCoverageFtth =
      isString(coverage) === true ? null : coverage.some(x => x.ftth.length === 0)
    if (isEmptyCoverageFtth === false) {
      const coverageItem = coverage.find(
        coverageEle => coverageEle.ftth && coverageEle.ftth.length > 0,
      )
      return uniq(
        coverageItem.ftth
          .map(coverageFtthItem => parseInt(coverageFtthItem.split('M_')[0], 10))
          .sort((a, b) => b - a),
      ).join(', ')
    }
    return null
  }

  render() {
    const { coverageData, wizardError, orderStatusError } = this.props
    const { isDisabled, isLoading } = this.state
    let text = ''
    if (wizardError) {
      text = wizardError
    } else if (orderStatusError !== null) {
      text = 'No se pudo actualizar la orden. Se ha cancelado.'
    }

    return (
      <>
        <CardTitle>DIRECCIÓN NUEVA</CardTitle>
        <CardRow>
          <CardRowLabel>Dirección de instalación:</CardRowLabel>
          <CardRowValue>{showCoverageStreet(coverageData)}</CardRowValue>
        </CardRow>
        <Box mt="15px" mb="30px">
          <Divider />
        </Box>
        {(wizardError || orderStatusError !== null) && (
          <>
            <CancelOrderModal
              operationType={CHANGE_ADDRESS}
              open={this.state.showModal}
              onClose={this.onModalClose}
              onConfirm={this.onModalClose}
              modalState={this.state.modalState}
            />
            <CardTitle style={{ height: 'auto' }}>{text} </CardTitle>
            <Button
              onClick={this.onAccept}
              variant="contained"
              color="primary"
              data-hook="rounder"
              disabled={isDisabled}>
              ACEPTAR
            </Button>
          </>
        )}
        {isLoading && <SpinnerButton />}
        {!wizardError && orderStatusError === null && !isLoading && (
          <Button
            onClick={this.handleUpdateOrder}
            variant="contained"
            color="primary"
            data-hook="rounder">
            CONFIRMAR DIRECCIÓN
          </Button>
        )}
        <Modal
          cancelBtn="Cerrar ventana"
          open={this.state.modal}
          onClose={this.onModalClose}
          title="Orden confirmada y enviada con éxito!"
          quit
        />
      </>
    )
  }
}

export default AddressSummary

AddressSummary.propTypes = {
  cancelOrder: PropTypes.func,
  updateOrder: PropTypes.func,
  coverageData: PropTypes.object,
  orderId: PropTypes.string,
  wizardError: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  order: PropTypes.object,
  ponc: PropTypes.object,
  orderStatusError: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
}

AddressSummary.defaultProps = {
  coverageData: {
    streetType: '',
    street: '',
    number: '',
    floor: '',
    block: '',
    zipCode: '',
    town: '',
    province: '',
    coverage: '',
  },
  orderId: '',
}
