import { Button, Modal, ModalActions } from 'components/ui'

import { Typography, Divider, Box } from '@material-ui/core'
import { find } from 'lodash'
import PropTypes from 'prop-types'
import { useState, useEffect } from 'react'
import { BundleItem } from './BundleItem'
import { BundlePrice } from './BundlePrice'

export function BundlesPickerModal({
  isOpen,
  tariff,
  onSelectBundles,
  bundleList,
  onCancel,
  selectedBundles = [],
}) {
  const [preSelectedBundles, setPreSelectedBundles] = useState([])
  const [emtpyBundles, setIsEmptyBundles] = useState(false)

  useEffect(() => {
    if (bundleList.length) {
      setPreSelectedBundles(selectedBundles)
    }
  }, [selectedBundles, bundleList])

  function toggleSelectedPackage(e, elem) {
    if (e.target.checked) {
      setPreSelectedBundles([...preSelectedBundles, elem])
    } else {
      setPreSelectedBundles(preSelectedBundles.filter(pack => pack.id !== elem.id))
    }
  }

  const onConfirm = () => {
    // onConfirm button will only close the modal if we have selected any bundles
    if (preSelectedBundles?.length <= 0) {
      setIsEmptyBundles(true)
    } else {
      setIsEmptyBundles(false)
      onSelectBundles(preSelectedBundles)
    }
  }

  const onClose = () => {
    setIsEmptyBundles(false)
    onCancel()
  }

  return (
    <Modal isOpen={isOpen} title="Añadir bonos" data-hook="bundles-modal">
      <div className="info-row">
        <Typography variant="body1" color="textSecondary">
          Tarifa seleccionada:
        </Typography>
        <Typography variant="body1" color="textSecondary" className="bold">
          {tariff.name}
        </Typography>
      </div>
      <div className="info-row">
        <Typography variant="body1" color="textSecondary">
          Cuota actual:
        </Typography>
        <Typography variant="body1" color="textSecondary" className="bold">
          {tariff.monthlyFee.taxFreeAmount} €/mes
        </Typography>
      </div>
      {bundleList.map(elem => (
        <BundleItem
          {...elem}
          key={elem.id}
          onChange={e => toggleSelectedPackage(e, elem)}
          checked={!!find(preSelectedBundles, elem)}
          disabled={!!find(selectedBundles, elem)}
        />
      ))}

      <Box my="16px">
        <Divider />
      </Box>

      <div className="info-row">
        <BundlePrice tariff={tariff} preSelectedBundles={preSelectedBundles} />
      </div>
      <ModalActions>
        <Button
          margin="4px 16px 4px 0px"
          data-hook="confirm-bundle"
          onClick={onConfirm}
          disabled={selectedBundles.length === preSelectedBundles.length}>
          CONFIRMAR BONO
        </Button>
        <Button secondary onClick={onClose}>
          CANCELAR
        </Button>
      </ModalActions>
      {emtpyBundles && <span className="error">No hay ningún bono seleccionado</span>}
    </Modal>
  )
}

BundlesPickerModal.propTypes = {
  isOpen: PropTypes.bool,
  tariff: PropTypes.any,
  onSelectBundles: PropTypes.func,
  onCancel: PropTypes.func,
  bundleList: PropTypes.array,
  selectedBundles: PropTypes.array,
}
