export const FAT_ATTEMPTED = 'OVID/FAT/ATTEMPTED'
export const FAT_SUCCEEDED = 'OVID/FAT/SUCCEEDED'
export const FAT_FAILED = 'OVID/FAT/FAILED'
export const CLEAR_FAT_ERROR = 'OVID/FAT/CLEAR_ERROR'

export const fatCompensationAction = (subscriptionId, msisdn, data, notifyData = {}) => ({
  type: FAT_ATTEMPTED,
  payload: {
    subscriptionId,
    msisdn,
    data,
    ...notifyData,
  },
})

export const fatCompensationSuccededAction = data => ({
  type: FAT_SUCCEEDED,
  payload: {
    data,
  },
})

export const fatCompensationFailedAction = error => ({
  type: FAT_FAILED,
  payload: error,
})

export const fatCompensationClearErrorAction = () => ({
  type: CLEAR_FAT_ERROR,
})
