import { Card } from 'modules/ui'
import styled from 'styled-components'

export const LoadingProducts = styled(props => {
  return (
    <div className={props.className}>
      <Card title="Productos" loading />
    </div>
  )
})`
  > .card {
    & .card-content {
      min-height: 250px;
    }
  }
`
