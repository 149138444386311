import { useMemo } from 'react'
import PropTypes from 'prop-types'

import { useSelector } from 'react-redux'

import { getIn, useFormikContext } from 'formik'
import { isEmpty } from 'lodash'

import { OTHER_ADDRESS } from 'modules/SharedSales/constants/shippingAddress'
import { AddressChooser } from 'modules/Address'
import { Modal, ModalActions, Button } from 'components/ui'

import { HomeGo as homeGoModel } from '../../../store/models/homeGo'
import { selectHomeGoPreselectedFullAddress } from '../../../store/selectors/Basket.selectors'

export const ModifyHomeGoAddressModal = ({ onSave, onClose, allowInputManualAddress, isRequiredFields }) => {
  const { values, setFieldValue, errors } = useFormikContext()

  const homeGoPreselectedFullAddress = useSelector(selectHomeGoPreselectedFullAddress)

  const selectedAddressType =
    getIn(values, 'homeGo.selectedAddressType') ||
    homeGoPreselectedFullAddress?.type?.value ||
    OTHER_ADDRESS.value
  const otherAddress = getIn(values, 'homeGo.otherAddress') || homeGoModel.otherAddress

  const initialHomeGoAddress = useMemo(
    () => ({
      selectedAddressType,
      otherAddress,
    }),
    [],
  )

  const onCancel = () => {
    setFieldValue('homeGo.selectedAddressType', initialHomeGoAddress.selectedAddressType)
    setFieldValue('homeGo.otherAddress', initialHomeGoAddress.otherAddress)

    onClose()
  }

  return (
    <Modal isOpen onClose={onCancel} title="Datos de instalación del SVA Home GO" overFlowY="auto">
      <AddressChooser
        title="Dirección de instalación"
        preselectedAddress={homeGoPreselectedFullAddress}
        availableAddressTypes={
          homeGoPreselectedFullAddress
            ? [homeGoPreselectedFullAddress.type, OTHER_ADDRESS]
            : [OTHER_ADDRESS]
        }
        type="homeGo"
        selectedAddressType={selectedAddressType}
        onSelectAddress={type => {
          setFieldValue('homeGo.selectedAddressType', type)
          if (type !== OTHER_ADDRESS.value) {
            setFieldValue('homeGo.otherAddress', homeGoModel.otherAddress)
          }
        }}
        onSetAddress={() => {}}
        onEditAddress={() => setFieldValue('homeGo.otherAddress', homeGoModel.otherAddress)}
        allowInputManualAddress={allowInputManualAddress}
        isRequiredFields={isRequiredFields}
      />
      <ModalActions>
        <Button
          onClick={onSave}
          disabled={
            !selectedAddressType ||
            ((!otherAddress || isEmpty(otherAddress) || !otherAddress.number || !isEmpty(errors?.homeGo)) &&
              selectedAddressType === OTHER_ADDRESS.value)
          }>
          Continuar
        </Button>
        <Button secondary onClick={onCancel}>
          Cancelar
        </Button>
      </ModalActions>
    </Modal>
  )
}

ModifyHomeGoAddressModal.propTypes = {
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  allowInputManualAddress: PropTypes.bool,
  isRequiredFields: PropTypes.bool,
}
