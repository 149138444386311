import { axiosJWT } from 'modules/axios'

import { APP_CONFIG } from 'services/app-config'

const BASE_URL_FETCH_ENERGY_CONTRACTS = `${APP_CONFIG.sales_cluster}/v1/customers/energy/contracts`

export const fetchEnergyContracts = docNumber =>
  axiosJWT({
    method: 'GET',
    url: BASE_URL_FETCH_ENERGY_CONTRACTS,
    params: {
      docNumber,
    },
  }).then(resp => resp.data)
