import { useSelector, useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { filter, isNil } from 'lodash'
import { makeStyles } from '@material-ui/styles'

import { ListDivider, TabsContainer, SpinnerCenter } from 'components/ui'
import { SectionHeader } from 'modules/ui'
import { AlarmsContainer } from 'modules/alarms'

import { useCvmActions } from 'modules/offers-configuration/hooks'
import { useShowTypification } from 'modules/vista-360/hooks/useShowTypification'
import { useGetIframeData } from 'modules/typifications/hooks'
import { getIsTypificationAllowed } from 'modules/offers-configuration/selectors'
import { selectIsPosOrMasProximo } from 'modules/Permissions/store/permissions-selectors'
import { selectIsErrorSecurizerIframe } from 'modules/typifications/store/selectors/securizerTypifications.selectors'
import { resetSecurizerFailed } from 'modules/typifications/store/actions/securizerTypifications.actions'
import { SECURIZER_ERROR } from 'modules/typifications/constants'

import NotificationModal from 'components/packages/ui/modal/NotificationModal'

import { tabsConfig, tabs } from './tabsConfig'

const PADDING = '10px 20px'

const useStyles = makeStyles(theme => ({
  'client-dashboard': {
    backgroundColor: ({ isLoading }) => !isLoading && theme.palette.brand.light,
    flexGrow: 1,

    '& .tabs': {
      padding: '10px 20px 0',
    },

    '& .tab-content': {
      backgroundColor: theme.palette.global.gray_light,
      marginTop: 0,
    },

    '& .card': {
      height: '100%',
    },
  },
}))

export function ClientDashboard({ isLoading, hasErrors, clientData }) {
  const dispatch = useDispatch()

  const classes = useStyles({ isLoading })

  const [isSecurizerErrorModal, setIsSecurizerErrorModal] = useState(false)

  const { openTypificationDispatch, openTypificationIframeDispatch } = useCvmActions()
  const { permissionIframeAccess, sfid, area, customerId, msisdns } = useGetIframeData()
  const isTypificationAllowed = useSelector(getIsTypificationAllowed)
  const isPosOrMasproximo = useSelector(selectIsPosOrMasProximo)
  const isSecurizerError = useSelector(selectIsErrorSecurizerIframe)

  useEffect(() => {
    if(!isNil(isSecurizerError)) {
      setIsSecurizerErrorModal(isSecurizerError)
    }
  }, [isSecurizerError])

  const onNotificationClose = () => {
    setIsSecurizerErrorModal(false)
    dispatch(resetSecurizerFailed())
  }

  const actionIframeTipis = permissionIframeAccess ? [{
    label: 'Tipificación',
    onClick: () => openTypificationIframeDispatch({customerId, sfid, area, msisdns}),
    disabled: !isTypificationAllowed,
  }] : []

  const visibleTabsConfig = isPosOrMasproximo
    ? filter(tabsConfig, config => config.value !== tabs.BILLINGS)
    : tabsConfig

  const actions = useShowTypification()
    ? [
        {
          label: 'Tipificar',
          onClick: openTypificationDispatch,
          disabled: !isTypificationAllowed,
        },
        ...actionIframeTipis
      ]
    : [...actionIframeTipis]

  return (
    <div className={classes['client-dashboard']}>
      {isLoading && <SpinnerCenter showMsg />}
      {isSecurizerErrorModal && <NotificationModal   
        type="alert"
        src="/assets/error.svg"
        title={SECURIZER_ERROR}
        isOpen
        onClose={onNotificationClose} />}
      {!isLoading && !hasErrors && (
        <>
          <SectionHeader title={clientData.fullName} actions={actions} />

          <ListDivider padding={PADDING} />

          <AlarmsContainer className="container-alarms p-x-24 p-t-24 p-b-12" />
          <TabsContainer
            tabsConfig={visibleTabsConfig}
            queryParamName="tab"
            dataHook="client-tabs"
          />
        </>
      )}
    </div>
  )
}

ClientDashboard.propTypes = {
  isLoading: PropTypes.bool,
  hasErrors: PropTypes.bool,
  clientData: PropTypes.object,
}
