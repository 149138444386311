import { axiosJWT, axiosCustomer } from 'modules/axios'
import { APP_CONFIG } from 'services/app-config'

const BASE_URL_SEARCH_360_COMMUNICATIONS = `${APP_CONFIG.care_cluster}/search-360/mascommunications`

export function sendSmsCommunication(
  senderInfo,
  terminatingNumber,
  content,
  realTimeRequired = 'true',
  scheduledTime = '',
  expirationTime = '',
) {
  return axiosJWT({
    method: 'POST',
    headers: {
      'X-Authorization': axiosCustomer.defaults.headers.common.Authorization,
    },
    url: `${BASE_URL_SEARCH_360_COMMUNICATIONS}/sms`,
    data: {
      senderInfo,
      terminatingNumber,
      content,
      realTimeRequired,
      scheduledTime,
      expirationTime,
    },
  }).then(resp => resp.data)
}

const CACHE_MASCOMMUNICATIONS_SMS = {}

export const getSmsCommunications = terminatingNumber => {
  const cacheKey = btoa(JSON.stringify({ terminatingNumber }))

  if (CACHE_MASCOMMUNICATIONS_SMS[cacheKey]) {
    return CACHE_MASCOMMUNICATIONS_SMS[cacheKey]
  }

  CACHE_MASCOMMUNICATIONS_SMS[cacheKey] = axiosJWT({
    method: 'GET',
    url: `${BASE_URL_SEARCH_360_COMMUNICATIONS}/sms`,
    params: {
      terminatingNumber,
    },
  })
    .then(resp => {
      return resp
    })
    .catch(() => {
      CACHE_MASCOMMUNICATIONS_SMS[cacheKey] = null
      return []
      // throw err
    })

  return CACHE_MASCOMMUNICATIONS_SMS[cacheKey]
}
