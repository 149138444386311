import { axiosJWT } from 'modules/axios'

import { APP_CONFIG } from 'services/app-config'

const BASE_URL_IS_CROSSELL_ALLOWED = `${APP_CONFIG.sales_cluster}/v1/customers/xsellEligibility`

const BASE_URL_COMPATIBLE_DISCOUNTS = `${APP_CONFIG.sales_cluster}/v1/customers/discount-info`

export function fetchCrossSellAllowed(
  customerId,
  productNumber,
  channel,
  salesType = 'cross-sell',
) {
  return axiosJWT({
    method: 'GET',
    url: BASE_URL_IS_CROSSELL_ALLOWED,
    params: {
      accountId: customerId,
      msisdn: productNumber,
      orderChannel: channel,
      salesType,
    },
  }).then(resp => resp.data)
}

export function fetchCrossCompatibleDiscounts(
  productSpecification,
  productNumber,
  idPOCrossSell,
  idPOPromoXSell,
) {
  return axiosJWT({
    method: 'GET',
    url: BASE_URL_COMPATIBLE_DISCOUNTS,
    params: {
      msisdn: productNumber,
      productSpecification,
      idPOxSell: idPOCrossSell,
      idPOPromoXSell,
    },
  }).then(resp => resp.data)
}
