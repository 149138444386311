/* eslint-disable react/no-this-in-sfc */
import PropTypes from 'prop-types'

import { constants } from './constants'
import { AddressForm } from '../../../components/AddressForm'
import { AddressSummary } from '../../../components/AddressSummary'
import { NewAppointment } from '../../../components/NewAppointment'

function WizardManager(props) {
  const {
    currentStep,
    parentData: {
      checkWizard,
      coverage,
      tokenAppointment,
      getPonr,
      ponr,
      ponc,
      order,
      cancelOrder,
      updateOrder,
      coverageData,
      orderId,
      gotCoverageData,
      wizardError,
      externalId,
      gescal17Order,
      gescal17NewDirection,
      appointmentState,
      orderStatusError,
      newAppointmentData,
    },
  } = props

  const steps = {
    [constants.ADDRESS_FORM]: {
      next() {
        checkWizard(
          [
            'getTariffsRated',
            { check: 'validCoverage', required: ['getTariffsRated'] },
            { check: 'getAppointmentToken', required: ['validCoverage'] },
            // { check: 'maxHours', required: ['getAppointment'] },
            // { check: 'getAppointmentToken', required: ['maxHours'] },
            // 'getAppointmentToken',
            // { check: 'scoring', required: ['maxHours'] },
            // 'scoring',
            'rove',
            // { check: 'rove', required: ['maxHours'] }, // TODO: Will be implemented in the future
          ],
          constants.NEW_APPOINTMENT,
          constants.ADDRESS_SUMMARY,
          {
            getAppointment: [orderId],
          },
        )
      },
      getProps() {
        return {
          next: this.next,
          tokenGenerated: coverage.token,
          gotCoverageData,
        }
      },
      getComponent() {
        return <AddressForm {...this.getProps()} />
      },
    },
    [constants.AUX_ADDRESS_SUMMARY_FAILED]: {
      next() {
        checkWizard(
          ['ponr'],
          constants.ADDRESS_SUMMARY,
          constants.ADDRESS_SUMMARY,
          {
            ponr: [orderId],
          },
          false,
        )
      },
      getComponent() {
        this.next()
        return null
      },
    },
    [constants.NEW_APPOINTMENT]: {
      next() {
        checkWizard(['ponr'], constants.ADDRESS_SUMMARY, undefined, {
          ponr: [orderId],
        })
      },
      getProps() {
        return {
          next: this.next,
          token: tokenAppointment,
          coverageData,
          gescal17Order,
          gescal17NewDirection,
          appointmentState,
          newAppointmentData,
        }
      },
      getComponent() {
        return <NewAppointment {...this.getProps()} />
      },
    },

    [constants.ADDRESS_SUMMARY]: {
      getProps() {
        return {
          getPonr,
          ponr,
          ponc,
          order,
          cancelOrder,
          updateOrder,
          coverageData,
          orderId,
          wizardError,
          externalId,
          orderStatusError,
        }
      },
      getComponent() {
        return <AddressSummary {...this.getProps()} />
      },
    },
  }

  const step = steps[currentStep]
  return <>{step ? step.getComponent() : null}</>
}

WizardManager.propTypes = {
  currentStep: PropTypes.string,
  parentData: PropTypes.shape({
    checkWizard: PropTypes.func,
    coverage: PropTypes.object,
    tokenAppointment: PropTypes.string,
    getPonr: PropTypes.func,
    ponr: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    ponc: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    order: PropTypes.object,
    cancelOrder: PropTypes.func,
    updateOrder: PropTypes.func,
    coverageData: PropTypes.object,
    orderId: PropTypes.string,
    gotCoverageData: PropTypes.func,
    wizardError: PropTypes.string,
    appointmentData: PropTypes.object,
    externalId: PropTypes.string,
    gescal17Order: PropTypes.string,
    gescal17NewDirection: PropTypes.string,
    appointmentState: PropTypes.string,
    orderStatusError: PropTypes.string,
    newAppointmentData: PropTypes.object,
  }),
}

export default WizardManager
