import { put, select, take } from 'redux-saga/effects'

import actions from './actions'
import * as selectors from './selectors'
import constants from './constants'

export function* callSaga(zipCode) {
  yield put(actions.findTax(zipCode))
  yield take(
    action =>
      (action.type === constants.FIND_TAX_SUCCESS || action.type === constants.FIND_TAX_ERROR) &&
      action.payload.zipCode === zipCode,
  )
  return yield select(selectors.getErrorCode, zipCode)
}
