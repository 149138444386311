import styled from 'styled-components'

import FormControlLabel from '@material-ui/core/FormControlLabel'

const CustomFormControlLabel = styled(FormControlLabel)`
  display: ${props => props.display || 'flex'};
`

function CustomFormLabel(props) {
  const { children, classes, ...other } = props
  return <CustomFormControlLabel {...other}>{children}</CustomFormControlLabel>
}
export default CustomFormLabel
