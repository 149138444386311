import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { selectors as customer360SvcSelectors } from 'services/customer-360/selectors'
import { selectDocumentId } from 'modules/Auth'
import { selectIsPosOrMasProximo } from 'modules/Permissions'
import { ContractsOrders } from '../../components/ContractsOrders/ContractsOrders'

export const ContractsOrdersContainer = props => {
  const dataHistoricOrders = useSelector(customer360SvcSelectors.getDataHistoricOrders)
  const documentId = useSelector(selectDocumentId)
  const isPosOrMasProximo = useSelector(selectIsPosOrMasProximo)

  return (
    <ContractsOrders
      dataHistoricOrders={dataHistoricOrders}
      className={props.className}
      searchKey={documentId}
      isPosOrMasProximo={isPosOrMasProximo}
    />
  )
}

ContractsOrdersContainer.propTypes = {
  className: PropTypes.string,
}
