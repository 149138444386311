export const basketActionTypes = {
  SET_BASKET_DATA: 'OVID/BASKET/SET_BASKET_DATA',
  SET_BASKET_TECHNOLOGY_DATA: 'OVID/BASKET/SET_BASKET_TECHNOLOGY_DATA',
  SET_ORDER: 'OVID/BASKET/SET_ORDER',
  SET_BASKET_ACCOUNT_DATA: 'OVID/BASKET/SET_BASKET_ACCOUNT_DATA',
  FETCH_TERMINAL_DETAILS: 'OVID/BASKET/FETCH_TERMINAL_DETAILS',
  FETCH_TERMINAL_DETAILS_SUCCESS: 'OVID/BASKET/FETCH_TERMINAL_DETAILS_SUCCESS',
  FETCH_TERMINAL_DETAILS_ERROR: 'OVID/BASKET/FETCH_TERMINAL_DETAILS_ERROR',
  RESET_TERMINAL_DETAILS: 'OVID/BASKET/RESET_TERMINAL_DETAILS',
  SET_TERMINAL_PAYMENT: 'OVID/BASKET/SET_TERMINAL_PAYMENT',
  SET_TERMINAL_RESERVATIONS: 'OVID/BASKET/SET_TERMINAL_RESERVATIONS',
  RESET_ALL_TERMINALS: 'OVID/BASKET/RESET_ALL_TERMINALS',
  ADD_ADDITIONAL_LINE: 'OVID/BASKET/ADD_ADDITIONAL_LINE',
  REMOVE_ADDITIONAL_LINE: 'OVID/BASKET/REMOVE_ADDITIONAL_LINE',
  SET_TERMINAL_SHIPPING: 'OVID/BASKET/SET_TERMINAL_SHIPPING',
  RESET_DELIVERY_POINT: 'OVID/BASKET/RESET_DELIVERY_POINT',
  SET_SHIPPING: 'OVID/BASKET/SET_SHIPPING',
  SET_SELECTED_ADDRESS_TYPE: 'OVID/BASKET/SET_SELECTED_ADDRESS_TYPE',
  SET_ADDRESS: 'OVID/BASKET/SET_ADDRESS',
  CLEAN_OTHER_ADDRESS: 'OVID/BASKET/CLEAN_OTHER_ADDRESS',
  SET_MAIN_MOBILE_LINE_TARIFF: 'OVID/BASKET/SET_MAIN_MOBILE_LINE_TARIFF',
  SET_SALE_SEGMENT: 'OVID/BASKET/SET_SALE_SEGMENT',
  SET_IBAN: 'OVID/BASKET/SET_IBAN',
  SET_REQUEST_LOADING: 'OVID/BASKET/SET_REQUEST_LOADING',
  SET_ORDER_TYPE: 'OVID/BASKET/SET_ORDER_TYPE',
  SET_BASKET_TARIFF: 'OVID/BASKET/SET_BASKET_TARIFF',
  SET_CUSTOMER_INFORMATION: 'OVID/BASKET/SET_CUSTOMER_INFORMATION',
  RESET_MEMBER_GET_MEMBER_PROMO: 'OVID/RESET_BASKET_MEMBER_GET_MEMBER_PROMO',
  SET_NEW_BASKET_ACCOUNT_VALUES_CARTERA: 'OVID/BASKET/SET_NEW_ACCOUNT_VALUES_CARTERA',
  SET_BASKET_LANDLINE_OFFER: 'OVID/BASKET/SET_BASKET_LANDLINE_OFFER',
}

export const setBasketData = payload => ({
  type: basketActionTypes.SET_BASKET_DATA,
  payload,
})

export const setMainMobileLineTariff = payload => ({
  type: basketActionTypes.SET_MAIN_MOBILE_LINE_TARIFF,
  payload,
})

export const setOrder = order => ({
  type: basketActionTypes.SET_ORDER,
  payload: {
    order,
  },
})

export const setBasketTechnologyData = technology => ({
  type: basketActionTypes.SET_BASKET_TECHNOLOGY_DATA,
  payload: {
    technology,
  },
})

export const setBasketAccountData = payload => ({
  type: basketActionTypes.SET_BASKET_ACCOUNT_DATA,
  payload,
})

export const setTerminalShipping = payload => ({
  type: basketActionTypes.SET_TERMINAL_SHIPPING,
  payload,
})

export const resetDeliveryPoint = payload => ({
  type: basketActionTypes.RESET_DELIVERY_POINT,
  payload,
})

export const setShipping = payload => ({
  type: basketActionTypes.SET_SHIPPING,
  payload,
})

export const fetchTerminalDetailsAction = payload => {
  return {
    type: basketActionTypes.FETCH_TERMINAL_DETAILS,
    payload,
  }
}

export const fetchTerminalDetailsSuccessAction = payload => {
  return {
    type: basketActionTypes.FETCH_TERMINAL_DETAILS_SUCCESS,
    payload,
  }
}

export const fetchTerminalDetailsErrorAction = payload => {
  return {
    type: basketActionTypes.FETCH_TERMINAL_DETAILS_ERROR,
    payload,
  }
}

export const resetTerminalDetailsAction = payload => {
  return {
    type: basketActionTypes.RESET_TERMINAL_DETAILS,
    payload: {
      ...payload,
    },
  }
}

export const setPaymentType = payload => {
  const isExtra = payload.lineType.includes('extra')
  const index = isExtra ? payload.lineType.match(/\d+/)[0] : null
  return {
    type: basketActionTypes.SET_TERMINAL_PAYMENT,
    payload: {
      ...payload,
      index,
      isExtra,
    },
  }
}

export const setTerminalReservations = payload => ({
  type: basketActionTypes.SET_TERMINAL_RESERVATIONS,
  payload,
})

export const resetAllTerminals = () => ({
  type: basketActionTypes.RESET_ALL_TERMINALS,
})

export const addAdditionalLineAction = payload => ({
  type: basketActionTypes.ADD_ADDITIONAL_LINE,
  payload,
})

export const removeAdditionalLineAction = payload => ({
  type: basketActionTypes.REMOVE_ADDITIONAL_LINE,
  payload,
})

export const setSelectedAddressTypeAction = payload => ({
  type: basketActionTypes.SET_SELECTED_ADDRESS_TYPE,
  payload,
})

export const setAddressAction = payload => ({
  type: basketActionTypes.SET_ADDRESS,
  payload,
})

export const cleanOtherAddressAction = payload => ({
  type: basketActionTypes.CLEAN_OTHER_ADDRESS,
  payload,
})

export const setSaleSegmentAction = payload => ({
  type: basketActionTypes.SET_SALE_SEGMENT,
  payload,
})

export const setIbanAction = payload => ({
  type: basketActionTypes.SET_IBAN,
  payload,
})

export const setRequestLoading = payload => ({
  type: basketActionTypes.SET_REQUEST_LOADING,
  payload,
})

export const setOrderType = payload => ({
  type: basketActionTypes.SET_ORDER_TYPE,
  payload,
})

export const setBasketTariff = payload => ({
  type: basketActionTypes.SET_BASKET_TARIFF,
  payload,
})

export const setCustomerInformation = payload => ({
  type: basketActionTypes.SET_CUSTOMER_INFORMATION,
  payload,
})

export const resetMemberGetMemberPromo = () => ({
  type: basketActionTypes.RESET_MEMBER_GET_MEMBER_PROMO,
})

export const setNewBasketAccountValuesCartera = type => ({
  type: basketActionTypes.SET_NEW_BASKET_ACCOUNT_VALUES_CARTERA,
  payload: {
    type,
  },
})

export const setBasketLandlineOffer = payload => ({
  type: basketActionTypes.SET_BASKET_LANDLINE_OFFER,
  payload,
})
