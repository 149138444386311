import { Button, MenuItem, Select, Divider, FormControl, InputLabel, Box } from '@material-ui/core'
import PropTypes from 'prop-types'
import { map } from 'lodash'

const WizardRequest = ({
  required,
  selectedOption,
  disabled,
  state,
  onMenuItemChange,
  defaultOption,
  options,
  onClickContinue,
  continueText,
  onClickGoBack,
  onClickConfirm,
  confirmText,
  defaultDisabled,
  onClick,
  defaultText,
  outlinedButton,
  children,
}) => {
  let component
  const isDisabled = (required && !selectedOption) || (disabled && state === 1)

  switch (state) {
    case 1:
      component = (
        <>
          <Box width="100%" maxWidth="390px" mt="40px" mb="36px">
            <FormControl variant="outlined" style={{ width: '100%' }} data-hook="wizard-reason">
              <InputLabel id="subreason-label">{defaultOption}</InputLabel>
              <Select
                labelId="subreason-label"
                label={defaultOption}
                value={selectedOption}
                displayEmpty
                onChange={event => onMenuItemChange(event.target.value)}>
                <MenuItem value="">
                  <em>{defaultOption}</em>
                </MenuItem>
                {map(options, (item, index) => (
                  <MenuItem key={`${item.value}${index}`} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <Divider />

          {children}

          <Box display="flex" flexDirection="row">
            <Button
              disabled={isDisabled}
              variant="contained"
              color="primary"
              onClick={onClickContinue}
              style={{ marginRight: '15px' }}
              data-hook="wizard-continue-button">
              {continueText}
            </Button>
            <Button variant="outlined" color="primary" onClick={onClickGoBack}>
              Atrás
            </Button>
          </Box>
        </>
      )
      break
    case 2:
      component = (
        <>
          <Button
            variant="contained"
            color="primary"
            onClick={onClickConfirm}
            disabled={isDisabled}
            style={{ marginRight: '15px' }}>
            {confirmText}
          </Button>
          <Button variant="outlined" color="primary" onClick={onClickGoBack}>
            Atrás
          </Button>
        </>
      )
      break
    default:
      component = (
        <Button
          disabled={defaultDisabled}
          style={{ marginTop: '10px' }}
          color="primary"
          variant={outlinedButton ? 'outlined' : 'contained'}
          onClick={onClick}>
          {defaultText}
        </Button>
      )
      break
  }
  return component
}

WizardRequest.propTypes = {
  onClick: PropTypes.func.isRequired,
  onClickConfirm: PropTypes.func.isRequired,
  onClickContinue: PropTypes.func.isRequired,
  onClickGoBack: PropTypes.func.isRequired,
  onMenuItemChange: PropTypes.func.isRequired,
  selectedOption: PropTypes.any.isRequired,
  options: PropTypes.any.isRequired,
  state: PropTypes.number.isRequired,
  defaultOption: PropTypes.string.isRequired,
  continueText: PropTypes.string.isRequired,
  confirmText: PropTypes.string.isRequired,
  defaultText: PropTypes.string.isRequired,
  children: PropTypes.element,
  outlinedButton: PropTypes.bool,
}

WizardRequest.defaultProps = {
  required: false,
  outlinedButton: false,
}

export default WizardRequest
