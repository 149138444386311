import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { makeStyles } from '@material-ui/styles'
import { Typography, Box } from '@material-ui/core'

import { useForceSaveAuth } from 'modules/Auth'
import { selectCanIDO, permissions } from 'modules/Permissions'
import {
  D2D_ADDITIONAL_ENERGY_ACCESS,
  D2D_ADDITIONAL_ENERGY_SALE_ACCESS,
  REGEX_SFID_ENERGY_SALE_ACCESS,
} from 'modules/Permissions/constants'
import {
  getIdentificationId,
  selectors as customer360Selectors,
} from 'services/customer-360/selectors'
import { createEnergySale, addSfidParam } from 'modules/energy/helpers'

import { ModifyContactEmailModal } from 'modules/vista-360/components/ModifyContactEmailModal'

import { actions as customer360Actions } from 'services/customer-360/actions'

import { ButtonLink, Modal, ActionAdvice, ModalActions, Button } from 'components/ui'
import { Card } from 'modules/ui'
import { selectEnergyEmail } from 'services/feature-flag/selectors'

const useStyles = makeStyles(theme => ({
  'add-new-energy-package-card': {
    '& button': {
      color: theme.palette.brand.attention,

      '&:hover': {
        color: theme.palette.orangePalette.orange_500,
      },
    },
  },
}))

export const SaleEnergyWithClientCardButton = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const [open, setOpen] = useState(false)
  const [isModifyEmailModalOpen, setIsModifyEmailModalOpen] = useState(false)

  const modifyEmailAvailable = useSelector(selectEnergyEmail)
  const customerId = useSelector(getIdentificationId)
  const canEnergySale = useSelector(
    selectCanIDO(
      permissions.cvm_energy_sale.id,
      [...D2D_ADDITIONAL_ENERGY_ACCESS, ...D2D_ADDITIONAL_ENERGY_SALE_ACCESS],
      REGEX_SFID_ENERGY_SALE_ACCESS,
    ),
  )
  const canModifyContactEmail = selectCanIDO(permissions.cvm_modify_contact_email.id)

  const currentEmail = useSelector(customer360Selectors.getEmail)
  const clientIdentification = useSelector(customer360Selectors.getIdentification)
  const isCompany = useSelector(customer360Selectors.getIsCompany)

  const { saveLatestAccessToken } = useForceSaveAuth()

  const refreshCustomer360Info = () => {
    dispatch(customer360Actions.findInit())
  }

  const onClickToModifyEmail = () => {
    setOpen(false)
    setIsModifyEmailModalOpen(true)
  }

  const goToNewSale = () => {
    const redirectUrl = createEnergySale(customerId)

    saveLatestAccessToken()
    window.location.href = addSfidParam(redirectUrl)
  }

  return (
    <>
      <ModifyContactEmailModal
        customer={{
          docType: clientIdentification?.type,
          docNumber: clientIdentification?.identificationId,
          isCompany,
        }}
        current={currentEmail}
        open={isModifyEmailModalOpen}
        onClose={() => {
          setIsModifyEmailModalOpen(false)
        }}
        onSuccess={refreshCustomer360Info}
      />

      <Modal title="Verificar dirección email" isOpen={open} onClose={() => setOpen(false)}>
        <Typography variant="body1" style={{ paddingBottom: '20px' }}>
          Es imprescindible, para gestionar la venta de energía, que el correo electrónico del
          cliente sea válido. Si no lo has hecho ya, por favor, valídalo y modifícalo si es
          necesario antes de realizar la venta. La dirección email de contacto actual del cliente
          es:
        </Typography>

        <ActionAdvice type="warning" message={currentEmail} block />

        <ModalActions style={{ display: 'flex' }}>
          <Button secondary style={{ marginRight: 'auto' }} onClick={() => setOpen(false)}>
            Cancelar
          </Button>

          {modifyEmailAvailable && (
            <Button secondary onClick={onClickToModifyEmail} disabled={!canModifyContactEmail}>
              Modificar
            </Button>
          )}

          <Button
            onClick={() => {
              setOpen(false)
              goToNewSale()
            }}>
            Nueva venta energía
          </Button>
        </ModalActions>
      </Modal>

      <Box mt="15px">
        <Card className={classes['add-new-energy-package-card']} title="">
          <ButtonLink
            onClick={() => setOpen(true)}
            disabled={!canEnergySale}
            data-hook="sale-energy-withclient-button"
            underline={false}
            className="card-link">
            + Añadir nueva venta de energía
          </ButtonLink>
        </Card>
      </Box>
    </>
  )
}
