import { get } from 'lodash'

export function getError(state) {
  return get(state, 'typifications.error')
}

export const isLoading = state => get(state, 'typifications.loading', false)

export function isDone(state) {
  return get(state, 'typifications.done', false)
}
