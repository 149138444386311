import PropTypes from 'prop-types'

import { Box, Grid, Divider, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { SVAsModal } from 'modules/SVAs/components/SVAsModalComponent'
import { formatFee } from 'utils'

const useStyles = makeStyles({
  'agile-image': {
    width: '100%',
    maxWidth: '120px',
    minWidth: '70px',

    '& img': {
      width: '100%',
      userSelect: 'none',
    },
  },

  'agile-price': {
    fontWeight: 'bold',

    '& .price-label': {
      fontSize: '18px',
      marginLeft: '6px',
    },
  },
})

export const AgileTvInfo = ({ data }) => {
  const classes = useStyles()

  return (
    <Grid container>
      <Grid item xs={3}>
        <Box display="flex" height="100%">
          <Box display="flex" width="100%" justifyContent="center" p="36px 24px 24px">
            <div className={classes['agile-image']}>
              <img src="/assets/agile-tv.png" alt="Agile tv" />
            </div>
          </Box>
          <Divider orientation="vertical" />
        </Box>
      </Grid>
      <Grid item xs={9}>
        <Box p="29px 24px">
          <Box display="flex" justifyContent="space-between" mb="20px">
            <Typography variant="h5" className="bold">
              {data?.commercialInfo?.name}
            </Typography>
          </Box>

          <Grid container spacing={2} style={{ marginTop: '14px' }}>
            <Grid item sm={12} md={3} xl={4}>
              {data.commercialInfoFormated && (
                <SVAsModal commercialInfo={data.commercialInfoFormated} />
              )}
            </Grid>
            <Grid item sm={12} md={9} xl={8}>
              <Box display="flex" flexDirection="column" pb="20px">
                <Typography variant="body1" className="bold" style={{ marginLeft: '30px' }}>
                  Pago mensual (impuesto incl.)
                </Typography>

                <Box pl="30px" pt="10px">
                  <Typography
                    variant="h4"
                    component="p"
                    data-hook="terminal.initialPayment"
                    className={classes['agile-price']}>
                    {formatFee(data?.price?.taxFreeAmount).replace('€', '')}

                    <span className="price-label">€</span>
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  )
}

AgileTvInfo.propTypes = {
  data: PropTypes.object.isRequired,
}
