import { useSelector } from 'react-redux'
import { permissions, selectCanIDO, selectProfileId, profiles } from 'modules/Permissions'
import { selectSfid } from 'modules/Auth'
import { checkProfile } from 'modules/Auth/helpers'

import {
  SFID_MIGRATION_TYPIFICATION_ACCESS,
  SFID_UPGRADE_TYPIFICATION_ACCESS,
  SFID_INBOUND_TYPIFICATION_ACCESS,
} from 'modules/Permissions/constants'
import { isSfidInArray } from 'modules/vista-360/helpers/helpers.js'
import { selectEnableMasCareIframeTypication } from 'services/feature-flag/selectors'

export const useShowTypification = () => {
  const profileId = useSelector(selectProfileId)
  const sfid = useSelector(selectSfid)

  const isAdminProfile = checkProfile(profiles, profileId, 'admin.id')
  const isFidelizacion = checkProfile(profiles, profileId, 'customer_loyalty.id')
  const isFidelizacionOutboundAndInbound = 
    checkProfile(profiles, profileId, 'customer_loyalty_outbound.id') || 
    checkProfile(profiles, profileId, 'customer_loyalty_inbound.id') 
  const isUpgradeProfile = isSfidInArray(SFID_UPGRADE_TYPIFICATION_ACCESS, sfid)
  const isMigrationProfile = isSfidInArray(SFID_MIGRATION_TYPIFICATION_ACCESS, sfid)
  const isInboundTypificationAccess = isSfidInArray(SFID_INBOUND_TYPIFICATION_ACCESS, sfid)

  const isUpgradeOrMigrationProfile = isUpgradeProfile || isMigrationProfile

  return (
    !useSelector(selectEnableMasCareIframeTypication) && isFidelizacionOutboundAndInbound ||
    (useSelector(selectCanIDO(permissions.typification_ops_access.id))) &&
      (!isFidelizacion ||
        (isFidelizacion && (isUpgradeOrMigrationProfile || isInboundTypificationAccess))) && !isAdminProfile
  ) 
}
