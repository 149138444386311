import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectCustomerData } from 'services/customer-360/selectors'
import {
  selectResellerQueryParam,
  selectBrandQueryParam,
  selectChannelQueryParam,
} from 'modules/Router/store/index'

export function useSetVista360URL(maintainVista360View = true) {
  const customerData = useSelector(selectCustomerData)
  const channel = useSelector(selectChannelQueryParam)
  const reseller = useSelector(selectResellerQueryParam)
  const brand = useSelector(selectBrandQueryParam)

  const vista360URL = useMemo(() => {
    let vista360BaseURL = '/vista/dashboard'
    const documentType = customerData?.companyCif ? 'CIF' : customerData.documenttype
    const documentId = customerData?.companyCif
      ? customerData.companyCif
      : customerData.documentid.toUpperCase()

    const params = `isSalesFlow=true&channel=${channel}&reseller=${reseller}&brand=${brand}`

    vista360BaseURL +=
      documentType && !!maintainVista360View
        ? `/documentType/${documentType}/documentId/${documentId}?${params}`
        : `?${params}`
    return vista360BaseURL
  }, [customerData, maintainVista360View])

  return { vista360URL }
}
