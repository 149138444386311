import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { join, isEmpty } from 'lodash'

import { helpers as helpers360, selectors as selectors360 } from 'services/customer-360'
import { actions as customerActivityActions } from 'modules/CustomerActivity'

export const useClientDashboard = () => {
  const dispatch = useDispatch()

  const contractsCustomer = useSelector(selectors360.contracts)

  const allLinesCustomer = useMemo(
    () => helpers360.getAllLinesAndElFijoMsisdn(contractsCustomer),
    [contractsCustomer],
  )

  const msisdnsStr = useMemo(() => join(allLinesCustomer, ','), [allLinesCustomer])

  const fetchCustomerActivityWithMsisdns = customerId => {
    if (!isEmpty(msisdnsStr)) {
      dispatch(customerActivityActions.fetchCustomerActivity(msisdnsStr, customerId))
    }
  }

  return [fetchCustomerActivityWithMsisdns]
}
