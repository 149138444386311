import * as Yup from 'yup/lib'
import { isValidDocumentByType, documentTypes } from 'modules/CustomerDocument'
import { validateHasNumbers } from 'utils'
import { SIGNUP_TYPE_PORTABILITY } from 'services/global-constants'

import * as validations from 'modules/SharedSales/constants/validations'

export const lineOwnerValidationSchema = {
  isSameUser: Yup.string().when('signUpType', {
    is: 'portability',
    then: Yup.string().required(validations.REQUIRED_FIELD_TEXT),
    otherwise: Yup.string(),
  }),
  otherUser: Yup.object().when(['isSameUser', 'signUpType'], {
    is: (isSameUser, signUpType) => isSameUser === 'no' && signUpType === SIGNUP_TYPE_PORTABILITY,
    then: Yup.object({
      documentType: Yup.string()
        .trim()
        .test('documentType', validations.ERROR_DATA_TEXT, value => !validateHasNumbers(value))
        .required(validations.REQUIRED_FIELD_TEXT),
      name: Yup.string().when('documentType', {
        is: 'CIF',
        then: Yup.string().trim(),
        otherwise: Yup.string()
          .trim()
          .test('name', validations.ERROR_DATA_TEXT, value => !validateHasNumbers(value))
          .required(validations.REQUIRED_FIELD_TEXT),
      }),
      surname1: Yup.string().when('documentType', {
        is: 'CIF',
        then: Yup.string(),
        otherwise: Yup.string()
          .trim()
          .test('name', validations.ERROR_DATA_TEXT, value => !validateHasNumbers(value))
          .required(validations.REQUIRED_FIELD_TEXT),
      }),
      surname2: Yup.string().when(['documentType'], {
        is: documentType => documentType === 'NIE' || documentType === 'CIF',
        then: Yup.string()
          .trim()
          .test('surname2', validations.ERROR_DATA_TEXT, value => !validateHasNumbers(value)),
        otherwise: Yup.string()
          .trim()
          .test('surname1', validations.ERROR_DATA_TEXT, value => !validateHasNumbers(value))
          .required(validations.REQUIRED_FIELD_TEXT),
      }),
      companyName: Yup.string().when('documentType', {
        is: 'CIF',
        then: Yup.string()
          .trim()
          .required(validations.REQUIRED_FIELD_TEXT),
      }),
      documentId: Yup.string()
        .when('documentType', {
          is: 'NIF',
          then: Yup.string()
            .trim()
            // eslint-disable-next-line
            .test('ValidateDocument', validations.SAME_USER_TEXT, function(value) {
              return value !== this.options.context.documentId
            })
            .test('ValidateNIF', validations.ERROR_DATA_TEXT, value =>
              isValidDocumentByType(value, 'NIF'),
            )
            .required(validations.REQUIRED_FIELD_TEXT),
        })
        .when('documentType', {
          is: 'NIE',
          then: Yup.string()
            .trim()
            // eslint-disable-next-line
            .test('ValidateDocument', validations.SAME_USER_TEXT, function(value) {
              return value !== this.options.context.documentId
            })
            .test('ValidateNIF', validations.ERROR_DATA_TEXT, value =>
              isValidDocumentByType(value, 'NIE'),
            )

            .required(validations.REQUIRED_FIELD_TEXT),
        })
        .when('documentType', {
          is: 'CIF',
          then: Yup.string()
            .trim()
            // eslint-disable-next-line
            .test('ValidateDocument', validations.SAME_USER_TEXT, function(value) {
              return value !== this.options.context.documentId
            })
            .test('ValidateCif', validations.ERROR_DATA_TEXT, value =>
              isValidDocumentByType(value, 'CIF'),
            )

            .required(validations.REQUIRED_FIELD_TEXT),
        })
        .when('documentType', {
          is: documentTypes.PASSPORT,
          then: Yup.string()
            .trim()
            // eslint-disable-next-line
            .test('ValidateDocument', validations.SAME_USER_TEXT, function(value) {
              return value !== this.options.context.documentId
            })
            .test('ValidatePASSPORT', validations.ERROR_DATA_TEXT, value =>
              isValidDocumentByType(value, documentTypes.PASSPORT),
            )

            .required(validations.REQUIRED_FIELD_TEXT),
        })
        .required(validations.REQUIRED_FIELD_TEXT),
    }),
    otherwise: Yup.object({
      documentType: Yup.string(),
      name: Yup.string(),
      surname1: Yup.string(),
      surname2: Yup.string(),
      documentId: Yup.string(),
    }),
  }),
}
