import { APP_CONFIG } from 'services/app-config'

export const CONFIG = {
  api: {
    search360Customers: `${APP_CONFIG.care_cluster}/search-360/customer`,
    changeTariff: `${APP_CONFIG.care_cluster}/ms-tariff-change/change-tariff`,
    changeTariffV3: `${APP_CONFIG.care_cluster}/ms-tariffs-change/stc/change-tariff`,
    products: `${APP_CONFIG.apigee_host}/masmovil/v1/products`,
    portabilityInfo: `${APP_CONFIG.care_cluster}/portabilities-info/portabilityinfo`,
    search360Products: `${APP_CONFIG.care_cluster}/search-360/product`,
    svahebe: `${APP_CONFIG.care_cluster}/ms-sva-hebe`,
    svaSmartHome: `${APP_CONFIG.mas_care}/alarms/v1`,
    bundleRenewals: `${APP_CONFIG.care_cluster}/add-bundle-renewal`,
    cancelSvaHomeGo: `${APP_CONFIG.care_cluster}/ms-sva-homeit/cancellation`,
  },
}
