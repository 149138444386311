import PropTypes from 'prop-types'
import styled, { withTheme } from 'styled-components'

import { Box, Typography, Icon } from '@material-ui/core'
import { TextStyles } from 'utils/text'
import { ICONS } from 'utils/icons'

import { formatFee, DEFAULT_FIXED_DIGITS } from 'utils'

import { ListItem } from 'components/ui'

import DownloadBtnPdf from './DownloadBtn/DownloadBtnPdf'

const ListItemStyled = styled(ListItem)`
  padding-top: 0;
  padding-bottom: 7px;
  justify-content: space-between;
`

const LineTxt = styled(Typography)`
  font-weight: 500;
  line-height: 1.3em;
  font-size: 16px;
`

const LineTxt2 = styled(Typography)`
  line-height: 1.3em;
  padding-right: 10px;
`

const UnpaidTxt = styled(Typography)`
  text-transform: initial;
  color: ${props => props.theme.palette.brand.alert};
`

const UnpaidIconWrapper = styled.span`
  padding: 0 4px;
  .icon {
    font-size: 10px;
    color: ${props => props.theme.palette.brand.alert};
  }
`

const BillHeader = ({
  billId,
  externalId,
  billAmount,
  collapsed,
  actionToggle,
  action,
  isUnpaid,
  ...rest
}) => (
  <ListItemStyled {...rest}>
    <Box display="flex" alignItems="center">
      <Box display="flex" flexDirection="column">
        <Box display="flex" alignItems="center">
          <LineTxt variant="body1">{externalId}</LineTxt>
          {isUnpaid && (
            <UnpaidIconWrapper>
              <Icon className="icon">{ICONS.circle}</Icon>
            </UnpaidIconWrapper>
          )}
        </Box>
        <Box display="flex" alignItems="center">
          {isUnpaid && <UnpaidTxt {...TextStyles.tagXs()}>Pendiente de pago</UnpaidTxt>}
        </Box>
      </Box>
    </Box>
    <Box display="flex" alignItems="center">
      <LineTxt2 {...TextStyles.dataDark()}>
        {formatFee(billAmount, DEFAULT_FIXED_DIGITS, false)}
      </LineTxt2>
      <DownloadBtnPdf billId={billId} />
    </Box>
  </ListItemStyled>
)

BillHeader.propTypes = {
  billId: PropTypes.string,
  externalId: PropTypes.string,
  billAmount: PropTypes.number,
  collapsed: PropTypes.bool,
  isUnpaid: PropTypes.bool,
  actionToggle: PropTypes.func,
  action: PropTypes.func,
}

export default withTheme(BillHeader)
