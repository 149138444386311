import { createStructuredSelector, createSelector } from 'reselect'
import { get } from 'lodash'
import { selectIsConvergent, selectIs2p, selectIsMobileOnly } from 'modules/Router'
import { selectModifyContactMediaError } from 'modules/CustomerInfo'
import { selectDuoLimitLoading } from 'modules/CustomerOrders'
import { selectMainLineTariffs } from 'modules/tariffs/store/index'
import { selectIsCrossSellQueryParam } from 'modules/Router/store/index'
import { selectCrossDiscountsLoading } from 'services/cross-sell/selectors'

import { LINE_TYPE } from 'modules/Lines/constants'

import {
  selectSaleTax,
  selectHasSomeTerminal,
  selectMainMobileTariff,
  selectTerminalAddress,
  selectIsElFijo,
  selectBasketRequestLoading,
  selectIsPosSale,
  selectSaleInformation,
  selectSubscriptionInformation,
  selectBroadbandType,
  selectRouterName,
  selectRouterPenaltyWithTaxes,
} from '../../store'

export const selectTerminalsReservationInfo = createSelector(
  values => values,
  values => {
    const mainMobileTerminalInfo = [
      {
        terminalId: get(values, `${LINE_TYPE.MAIN_MOBILE}.terminal.id`),
        id: LINE_TYPE.MAIN_MOBILE,
        field: `${LINE_TYPE.MAIN_MOBILE}.terminal.reservationId`,
        stock: get(values, `${LINE_TYPE.MAIN_MOBILE}.terminal.details.stock`),
      },
    ]
    const extraMobileTerminalInfo = values.extraMobileLinesOffers.map((line, i) => ({
      terminalId: get(line, 'terminal.id'),
      id: `extraMobileLinesOffers[${i}]`,
      type: `extraMobileLinesOffers`,
      pos: i,
      field: `extraMobileLinesOffers[${i}].terminal.reservationId`,
      stock: get(line, `terminal.details.stock`),
    }))
    return [...mainMobileTerminalInfo, ...extraMobileTerminalInfo].filter(elem => elem.terminalId)
  },
)

const selectIsLoading = createSelector(
  [selectBasketRequestLoading, selectCrossDiscountsLoading],
  (isBasketRequestLoading, isCrossDiscountsLoading) =>
    isBasketRequestLoading || isCrossDiscountsLoading,
)

export const selectOffers = createStructuredSelector({
  isConvergent: selectIsConvergent,
  is2p: selectIs2p,
  isMobileOnly: selectIsMobileOnly,
  isElFijo: selectIsElFijo,
  isPosSale: selectIsPosSale,
  isCrossSell: selectIsCrossSellQueryParam,
  tax: selectSaleTax,
  tariffs: selectMainLineTariffs,
  hasTerminals: selectHasSomeTerminal,
  mainMobileTariff: selectMainMobileTariff,
  terminalAddress: selectTerminalAddress,
  isLoading: selectIsLoading,
  carteraUpdateError: selectModifyContactMediaError,
  saleInformation: selectSaleInformation,
  subscriptionInformation: selectSubscriptionInformation,
  isDuoLoading: selectDuoLimitLoading,
  broadbandType: selectBroadbandType,
  routerName: selectRouterName,
  routerPenaltyWithTaxes: selectRouterPenaltyWithTaxes,
})
