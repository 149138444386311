import { combineReducers } from 'redux'
import { pick } from 'lodash'
import { connectRouter } from 'connected-react-router'
import { reducer as auth } from 'modules/Auth'
import { reducer as orders } from 'modules/orders'
import { reducer as productOrders } from 'services/product-orders'
import { reducer as customerProducts } from 'services/customer-products'
import { tariffsReducer as tariffs } from 'modules/tariffs/store'
import { reducer as tariffsApigee } from 'modules/tariffs/store-apigee'
import { reducer as coverage } from 'modules/Coverage'
import { reducer as enrichment } from 'services/enrichment'
import { reducer as translation } from 'services/translation'
import { reducer as ponr } from 'modules/ponr'
import { reducer as ponc } from 'modules/ponc'
import { reducer as pond } from 'modules/pond'
import { reducer as changeTechnology } from 'services/change-technology'
import { reducer as provisioning } from 'services/provisioning'
import { reducer as offers } from 'services/offers'
import { reducer as offersDetail } from 'services/offers-detail'
import { reducer as rove } from 'services/rove'
import { reducer as appointments } from 'modules/Appointments'
import { reducer as wizard } from 'services/wizard'
import { reducer as featureFlag } from 'services/feature-flag'
import { reducer as discountsOrder } from 'services/discounts-order'
import { reducer as discountsIncompatibilities } from 'services/discounts-incompatibilities'
import { reducer as orderOnFly } from 'services/order-on-fly'
import { reducer as discountsAgent } from 'services/discounts-agent'
import { reducer as subscriptions } from 'services/subscriptions'
import { reducer as contentful } from 'modules/contentful'
import { reducer as taxes } from 'services/taxes'
import { reducer as bonuses } from 'modules/bonuses'
import { reducer as customerNew } from 'services/customer-new'
import { reducer as customer360 } from 'services/customer-360'
import { reducer as billings } from 'modules/billings'
import { reducer as logisticOrders } from 'services/logistic-orders'
import { reducer as cvm, offersConfigurationInit } from 'modules/offers-configuration'
import { reducer as checkOrderCTOnFly } from 'services/check-order-ct-on-fly'
import { reducer as territCompat } from 'services/territory-compatibility'
import { reducer as iban } from 'modules/Iban'
import { installationsReducer as installations } from 'modules/Installations'
import { installationsReducer as installationsCare } from 'modules/InstallationsCare'
import { legalTexts } from 'modules/LegalTexts'
import { reducer as customerActivity } from 'modules/CustomerActivity'
import { reducer as customerWrite } from 'modules/customer-write'
import { reducer as prepaidLine } from 'modules/prepaid-line'
import { reducer as credit } from 'modules/credit'
import { reducer as fat } from 'modules/FAT'
import { reducer as deviceRenewal } from 'modules/device-renewal/store/device-renewal.reducer'
import { reducer as smsCommunication } from 'services/sms-communication/reducer'
import { reducer as addAgileTv } from 'modules/agile-tv/add-agiletv/store/add-agiletv.reducer'
import { reducer as changeAgileTv } from 'modules/agile-tv/change-agiletv/store'
import {
  basketReducer as basket,
  scoringReducer as scoring,
  ordersReducer as ordersV3,
  presaleReducer as presale,
} from 'modules/NewClientSales/store'

import { svaReducer as svas } from 'modules/SVAs/store'

import { customerInfoReducer as customerInfo } from 'modules/CustomerInfo'

import {
  vista360DashboardLoad,
  vista360ClientDashboardLoad,
  vista360ProductDashboardLoad,
  vista360OrderDashboardLoad,
  vista360OrderMainTabLoad,
  vista360OrderDetailLoad,
} from 'modules/vista-360'

import { existingClientsWatchOnFlyStatusLoad } from 'modules/existing-clients'

import { reducer as ticketing } from 'services/ticketing'
import { reducer as customerOrders } from 'modules/CustomerOrders'
import { reducer as customerSubscriptions } from 'services/customer-subscriptions'
import { reducer as orderProducts } from 'services/order-products'
import { reducer as typifications } from 'services/typifications'
import { notificationsReducer as notifications } from 'services/notifications'
import { reducer as customerV3 } from 'services/customer-v3'
import { reducer as customerContactMedia } from 'services/customer-v3-contact-medias'
import { reducer as activityRegister } from 'modules/activity-register'
import { reducer as discountsAgentCategories } from 'services/discounts-agent-categories'
import { reducer as orderStatusDesc } from 'services/order-status-desc'
import { reducer as cancelOrdersBus } from 'modules/cancel-order-bus'
import * as resetStoreSvc from 'services/reset-store'
import { reducer as lines } from 'modules/Lines'
import { reducer as barringsStatus } from 'modules/barrings'
import { reducer as alarms } from 'modules/alarms/store'
import { reducer as atc } from 'services/atc'
import { reducer as cross } from 'services/cross-sell/reducer'
import { reducer as multisim } from 'services/multisim/reducer'
import { reducer as energy } from 'modules/energy/store/energy.reducer'
import { cadenceReducer as cadence } from 'services/cadence-flow/reducer'
import { reducer as customerSva } from 'services/customer-sva/reducer'
import {
  authTypificationsReducer as authTypifications,
  dataTypificationsReducer as dataTypifications,
  securizerTypificationsReducer as securizerTypifications,
  insertionTypificationsReducer as insertionTypifications,
  registerTypificationsReducer as registerTypifications,
  formTypificationsReducer as formTypifications,
} from 'modules/typifications'

// Reducers to change data through the whole store
function appReducer(state, action) {
  switch (action.type) {
    case resetStoreSvc.constants.STORE_RESET: {
      return pick(state, ['router', 'featureFlag', 'auth'])
    }
    default:
      return state
  }
}

// Important to keep alphabetical order, please
export default history => {
  const combinerReducer = combineReducers({
    activityRegister,
    addAgileTv,
    alarms,
    appointments,
    atc,
    auth,
    authTypifications,
    barringsStatus,
    basket,
    billings,
    bonuses,
    cadence,
    cancelOrdersBus,
    changeAgileTv,
    changeTechnology,
    checkOrderCTOnFly,
    contentful,
    coverage,
    credit,
    cross,
    customer360,
    customerActivity,
    customerContactMedia,
    customerInfo,
    customerNew,
    customerOrders,
    customerProducts,
    customerSubscriptions,
    customerSva,
    customerV3,
    customerWrite,
    cvm,
    dataTypifications,
    securizerTypifications,
    deviceRenewal,
    discountsAgent,
    discountsAgentCategories,
    discountsIncompatibilities,
    discountsOrder,
    energy,
    enrichment,
    existingClientsWatchOnFlyStatusLoad,
    fat,
    featureFlag,
    formTypifications,
    iban,
    insertionTypifications,
    installations,
    installationsCare,
    legalTexts,
    lines,
    logisticOrders,
    multisim,
    notifications,
    offers,
    offersConfigurationInit,
    offersDetail,
    orderOnFly,
    orderProducts,
    orders,
    ordersV3,
    orderStatusDesc,
    ponc,
    pond,
    ponr,
    prepaidLine,
    presale,
    productOrders,
    provisioning,
    registerTypifications,
    router: connectRouter(history),
    rove,
    scoring,
    smsCommunication,
    subscriptions,
    svas,
    // tariffsModule should be the only one and the other two should be deleted
    tariffs,
    tariffsApigee,
    taxes,
    territCompat,
    ticketing,
    translation,
    typifications,
    vista360ClientDashboardLoad,
    vista360DashboardLoad,
    vista360OrderDashboardLoad,
    vista360OrderDetailLoad,
    vista360OrderMainTabLoad,
    vista360ProductDashboardLoad,
    wizard,
  })

  return (state, action) => {
    return [combinerReducer, appReducer].reduce(
      (stateAcc, reducerFn) => reducerFn(stateAcc, action),
      state,
    )
  }
}
