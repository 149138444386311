const PriceInterface = {
  currency: '',
  taxFreeAmount: '',
  taxIncludedAmount: '',
  taxRate: '',
}
export const TerminalDetailInterface = {
  name: '',
  image: '',
  longDescription: '',
  characteristics: {
    capacity: '',
    screen: '',
  },
  paymentMethods: {
    financing: {
      currency: '',
      initialPayment: PriceInterface,
      monthlyPayment: PriceInterface,
      fees: '',
    },
    quota25: {
      currency: '',
      initialPayment: PriceInterface,
      monthlyPayment: PriceInterface,
      fees: '',
    },
    financing_with_back_fee: {
      currency: '',
      initialPayment: PriceInterface,
      monthlyPayment: PriceInterface,
      finalPayment: PriceInterface,
      fees: '',
    },
    upFront: {
      initialPayment: PriceInterface,
    },
  },
}
