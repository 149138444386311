import PropTypes from 'prop-types'
import { get, round } from 'lodash'

import { Typography, Icon } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { TextStyles } from 'utils/text'
import { ICONS } from 'utils/icons'

import { segsToSegsOrMinsLiteral } from 'utils/formatting/durations'
import { formatHHmm_hFromStr } from 'utils/formatting/dates'
import { formatNumberWithComma, formatFee, getCountryFromPrefix } from 'utils'
import { useSelector } from 'react-redux'
import { applyTax, getTax } from 'services/taxes'
import { selectors as customerSelectors } from 'services/customer-360'
import { call_cases } from 'modules/CustomerActivity/CustomerActivity.constants'
import { getTypeFromCallCase } from './UsageDetail.helpers'

const iconTypeActivityMap = {
  CALL: {
    icon: 'phone',
    literal: 'Llamadas',
  },
  INTERNATIONAL: {
    icon: 'phone',
    literal: 'Llamadas Internacionales',
  },
  CALL_PREMIUM: {
    icon: 'phone',
    literal: 'Llamadas Premium',
  },
  SMS: {
    icon: 'smsTipification',
    literal: 'SMS',
  },
  SMS_PREMIUM: {
    icon: 'smsTipification',
    literal: 'SMS Premium',
  },
  GPRS: {
    icon: 'split',
    literal: 'Uso de datos',
  },
  OTHER: {
    icon: 'help',
    literal: 'Otros',
  },
}

const useStyles = makeStyles(theme => ({
  'usage-item': {
    '& .type-activity-item': {
      padding: '3px 0',
      color: theme.palette.global.gray_dark,
      fontSize: '14px',
    },

    '& .boldable': {
      '& strong': {
        fontSize: '16px',
      },
    },

    '& .activity': {
      width: '100%',
      height: 'auto',
      display: 'flex',
      justifyContent: 'space-between',
      borderBottom: '1px solid #d9d9d9',
      padding: '5px 0',
      fontSize: '14px',
    },

    '& .icon-wrapper': {
      width: '68px',
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'center',
      height: '30px',

      '& .icon': {
        fontSize: '19px',
      },
    },

    '& .left-wrapper': {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-around',
      flexFlow: 'column',
    },

    '& .right-wrapper': {
      width: '220px',
      display: 'flex',
      flexFlow: 'column',
      alignItems: 'right',
      justifyContent: 'space-between',
      textAlign: 'right',
      paddingRight: '18px',

      '& .date-duration': {
        paddingTop: '5px',
        fontSize: '12px',
      },
    },

    '&& .cost p': {
      fontSize: '16px',
    },
  },
}))
export const UsageItem = ({ data, dataConsumed, className }) => {
  const classes = useStyles()

  const dataAdHocFormat = formatNumberWithComma(round(dataConsumed / 1024 / 1024, 2))
  const activityInfo = iconTypeActivityMap[data.type]
  const itemType =
    get(data, 'type').includes('CALL') || get(data, 'type').includes('SMS')
      ? getTypeFromCallCase(get(data, 'call_case'))
      : get(data, 'type')
  const literal =
    itemType === 'INTERNATIONAL' && getCountryFromPrefix(get(data, 'call_destination_id'))
      ? `${get(activityInfo, 'literal')} - ${getCountryFromPrefix(
          get(data, 'call_destination_id'),
        )}`
      : get(activityInfo, 'literal')

  // taxes
  const billingAddress = useSelector(state => customerSelectors.getBillingAddress(state))
  const postCode = get(billingAddress, 'postCode')
  const tax = useSelector(state => getTax(state, postCode))
  const totalWithoutVAT =
    get(data, 'total_without_vat', 0) === 0
      ? get(data, 'totalWithVat', 0)
      : get(data, 'total_without_vat', 0)
  const totalWithVat = applyTax(tax, totalWithoutVAT)
  return (
    <div className={[classes['usage-item'], ...(className ? [className] : []).join(' ')]}>
      <li className="activity">
        <div className="icon-wrapper">
          <Icon className="icon">{ICONS[get(activityInfo, 'icon')]}</Icon>
        </div>
        <div className="left-wrapper">
          <Typography {...TextStyles.labelDark()}>{get(data, 'phone')}</Typography>
          <Typography
            {...TextStyles.labelDark({
              className: 'type-activity-item',
            })}>
            {literal}
          </Typography>
          {itemType === 'OTHER' && (
            <Typography
              {...TextStyles.labelDark({
                className: 'boldable type-activity-item',
              })}>
              <span className="bold">
                {call_cases[data.call_case]}
                {data.event_type === 'CALL' ? ` (${get(data, 'b_number')})` : ''}
              </span>
            </Typography>
          )}
          {(data.event_type === 'CALL' || data.event_type === 'SMS') && (
            <Typography
              {...TextStyles.labelDark({
                className: 'boldable type-activity-item',
              })}>
              <span className="bold">{get(data, 'b_number')}</span>
            </Typography>
          )}
          {itemType === 'GPRS' && (
            <Typography
              {...TextStyles.labelDark({
                className: 'boldable type-activity-item',
              })}>
              <span className="bold">{dataAdHocFormat} MB</span>
            </Typography>
          )}
        </div>
        <div className="right-wrapper">
          {!data.event_type === 'CALL' && (
            <Typography {...TextStyles.labelDark()}>
              {formatHHmm_hFromStr(get(data, 'date'))}
            </Typography>
          )}
          {(data.event_type === 'CALL' || data.event_type === 'SMS') && (
            <>
              <Typography
                {...TextStyles.labelDark({
                  className: 'date-duration',
                })}>
                {formatHHmm_hFromStr(get(data, 'event_at'))}
                {data.event_type === 'CALL' &&
                  ` - ${segsToSegsOrMinsLiteral(get(data, 'duration', 0))} `}
              </Typography>
              <Typography
                {...TextStyles.subtitle1Dark({
                  className: 'cost',
                })}>
                {formatFee(totalWithVat, 2, true)}
              </Typography>
            </>
          )}
          {['GPRS', 'OTHER'].includes(itemType) && (
            <Typography
              {...TextStyles.subtitle1Dark({
                className: 'cost',
              })}>
              {formatFee(totalWithVat, 2, true)}
            </Typography>
          )}
        </div>
      </li>
    </div>
  )
}

UsageItem.propTypes = {
  data: PropTypes.object,
  dataConsumed: PropTypes.number,
  className: PropTypes.string,
}
