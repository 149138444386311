import { get, chain } from 'lodash'
import { FTTH, ADSL, NEBA, VULA } from 'modules/Coverage'
import { SVA_HOMEGO_CODE, SVA_SMARTHOME_CODE } from 'modules/offers-configuration/constants'
import { getFixedNumberOnFlySubscription } from 'modules/orders/orders.helpers'
import { FIBER } from 'modules/SharedSales/constants'
import {
  isAdslTariff,
  isFtthTariff,
  isNebaTariff,
  isVulaTariff,
  is2pTariff,
} from 'modules/tariffs/store-apigee/tariffs-apigee.helpers'
import { getTerminalComputedPrices } from 'modules/Terminals'
import { getFixedLineNumber, getMobileLineNumber, getMsisdn } from 'services/subscriptions/helpers'

// TODO: This should be the only selector helpers for scoring after the new service is working

const ADSL_LOWER_CASED = ADSL.toLowerCase()
const SIGNUP_TYPE_NEWLINE = 'new number'

const getTechnology = technology => {
  if ([FIBER, NEBA, VULA, FTTH].includes(technology)) return FTTH
  if ([ADSL_LOWER_CASED, ADSL].includes(technology)) return ADSL
  if (technology === 'MF') return 'MF'
  return 'MO'
}

const getDiscountsIdsFromTariff = tariff =>
  tariff.prices
    ? chain(tariff.prices)
        .map(price => price?.discounts)
        .flatten()
        .filter(discount => !!discount?.id)
        .map(discountWithId => discountWithId.id)
        .value()
    : []

const getTariffId = tariff => {
  const discountsIds = getDiscountsIdsFromTariff(tariff)

  return !discountsIds.length ? tariff.ps : `${tariff.ps}:${discountsIds.join(':')}`
}

const getDeviceData = (terminal, tax) => {
  const prices = getTerminalComputedPrices(terminal, tax)
  const {
    id,
    details: { name },
    paymentType,
  } = terminal
  return {
    offerId: id,
    deviceName: name,
    upfrontPm: paymentType === 'upfront' ? prices.initialPayment : null,
    instalmentPm: paymentType !== 'upfront' ? prices.monthlyPayment : null,
    instalmentPlanl: paymentType !== 'upfront' ? prices.fees : null,
    fRValue: paymentType !== 'upfront' ? prices.finalPayment : null,
    terms: paymentType !== 'upfront' && prices.permanency ? prices.permanency.toString() : '',
  }
}

const getBroadBandAndFijoServiceId = (landline, isPortability, is2p) => {
  let serviceId = landline?.newNumber?.value

  if (isPortability) {
    serviceId = landline.lineNumber
  } else if (is2p) {
    serviceId = landline.newFixedNumber
  }

  return serviceId
}

const getBroadBandScoringData = (landline, technology, is2p) => {
  const tariffId = getTariffId(landline.tariff)
  const isPortability = landline.signUpType !== SIGNUP_TYPE_NEWLINE

  return {
    serviceID: getBroadBandAndFijoServiceId(landline, isPortability, is2p),
    lineType: 'BROADBAND',
    operDon: isPortability ? landline.operator.tradingName : null,
    operDonP: isPortability ? 'postpaid' : 'new',
    donorH: landline.isSameUser === 'yes' ? 0 : 1,
    donorHIdentificationNumber: {
      type: get(landline.otherUser, 'documentType', ''),
      value: get(landline.otherUser, 'documentId', ''),
    },
    tariff: tariffId,
    technology: getTechnology(technology),
  }
}

const getFijoScoringData = (landline, technology, isElFijo, is2p) => {
  const isPortability = landline.signUpType !== SIGNUP_TYPE_NEWLINE
  const tariffId = getTariffId(landline.tariff)
  let operDonP = 'new'
  if (isPortability) {
    const { internalPortability, installationRequired } = landline.operator

    if (internalPortability) {
      operDonP = installationRequired ? 'internal_postpaid_installation' : 'internal_postpaid'
    } else {
      operDonP = 'postpaid'
    }
  }

  const line = {
    serviceID: getBroadBandAndFijoServiceId(landline, isPortability, is2p),
    lineType: 'FIJO',
    donorH: landline.isSameUser === 'no' ? 1 : 0,
    donorHIdentificationNumber: {
      type: get(landline.otherUser, 'documentType', ''),
      value: get(landline.otherUser, 'documentId', ''),
    },
    tariff: tariffId,
    technology: getTechnology(technology),
    operDonP,
  }
  if (isElFijo) {
    return {
      ...line,
      operDon: isPortability ? landline.operator.tradingName : 'null',
    }
  }
  return line
}

export const getSVAsScoringData = (landline, proSVAsFormated, is2p) => {
  return chain(proSVAsFormated)
    .filter(sva => sva.psId === SVA_SMARTHOME_CODE || sva.psId.includes(SVA_HOMEGO_CODE))
    .map(svaAlarm => {
      const isPortability = landline.signUpType !== SIGNUP_TYPE_NEWLINE
      const tariffId = getTariffId(landline.tariff)

      let lineType = svaAlarm.psId
      let offerId = svaAlarm.poId
      let deviceName = svaAlarm.commercialInfo?.name || svaAlarm.psId

      if (svaAlarm.psId.includes(SVA_HOMEGO_CODE)) {
        lineType = SVA_HOMEGO_CODE
        offerId = svaAlarm.psId
        deviceName = svaAlarm.psId
      }

      return {
        serviceID: getBroadBandAndFijoServiceId(landline, isPortability, is2p),
        lineType,
        tariff: tariffId,
        technology: null,
        operDonP: null,
        devices: [
          {
            offerId,
            deviceName,
            upfrontPm: 0,
            instalmentPm: 0,
            instalmentPlanl: 0,
            fRValue: 0,
            terms: null,
          },
        ],
      }
    })
    .value()
}

const getMobileDataScoring = (mobileLine, tax, isElFijo, is2p, isCrossSell) => {
  const tariffId = getTariffId(mobileLine.tariff)
  const line = {
    serviceID:
      mobileLine.signUpType === SIGNUP_TYPE_NEWLINE || isElFijo || is2p
        ? mobileLine.newNumber.value
        : mobileLine.lineNumber,
    lineType: 'MOVIL',
    operDonP: isCrossSell ? 'cartera' : mobileLine.portabilityType || 'new',
    operDon: mobileLine.signUpType === SIGNUP_TYPE_NEWLINE ? null : mobileLine.operator.tradingName,
    devices:
      mobileLine.terminal && mobileLine.terminal.id
        ? [getDeviceData(mobileLine.terminal, tax)]
        : [],
    donorH: mobileLine.isSameUser === 'no' ? 1 : 0,
    donorHIdentificationNumber: {
      type: get(mobileLine.otherUser, 'documentType', ''),
      value: get(mobileLine.otherUser, 'documentId', ''),
    },
    tariff: tariffId,
    technology: getTechnology(mobileLine.selectedTechnology),
  }
  if (isElFijo) {
    return {
      ...line,
      opernDonP: 'new',
      operDon: null,
      donorH: 0,
      donorHIdentificationNumber: {
        type: '',
        value: '',
      },
    }
  }

  return line
}

const getFormattedMobileLine = (mobileLine, landline, isElFijo, is2p) => {
  let formattedMobileLine = mobileLine

  if (isElFijo) {
    formattedMobileLine = { ...mobileLine, selectedTechnology: 'MF' }
  } else if (is2p) {
    formattedMobileLine = { ...mobileLine, newNumber: landline.newNumber }
  }

  return formattedMobileLine
}

export const mapLineDataToScoring = (
  landline,
  mobileLine,
  extraLines,
  technology,
  tax,
  isMobileOnly,
  isElFijo,
  is2p,
  isSubscription,
  proSVAsFormated,
  isCrossSell,
  is2pPure,
) => {
  const formatedMobileLine = getFormattedMobileLine(mobileLine, landline, isElFijo, is2p)

  const elFijoLandline = getFijoScoringData(
    { ...mobileLine, newNumber: mobileLine.newFixedNumber },
    'MF',
    isElFijo,
    is2p,
  )
  const mainTariff = isElFijo || isMobileOnly ? formatedMobileLine.tariff : landline.tariff

  const landlineScoringData = isElFijo
    ? elFijoLandline
    : getFijoScoringData(landline, technology, isElFijo, is2p)

  const proSVAsScoringData = getSVAsScoringData(landline, proSVAsFormated, is2p)

  const broadBandScoringData = isElFijo
    ? [landlineScoringData]
    : [
        getBroadBandScoringData(landline, technology, is2p),
        landlineScoringData,
        ...proSVAsScoringData,
      ]

  const extraFormatedLines = extraLines.map(line => getMobileDataScoring(line, tax))

  const mobileLines = !isSubscription
    ? [
        {
          ...getMobileDataScoring(formatedMobileLine, tax, isElFijo, is2p, isCrossSell),
          tariff: getTariffId(mainTariff),
        },
        ...extraFormatedLines,
      ]
    : extraFormatedLines

  const lines3p2pElFijo = is2pPure
    ? broadBandScoringData
    : [...broadBandScoringData, ...mobileLines]

  return isMobileOnly || !!isSubscription
    ? [...mobileLines, ...proSVAsScoringData]
    : lines3p2pElFijo
}

export const mapLineSubscriptionToScoring = (
  landline,
  mobileLine,
  tariff,
  isElFijo,
  is2p,
  tax,
  proSVAs,
) => {
  const isFiber =
    isFtthTariff(tariff) || isNebaTariff(tariff) || isVulaTariff(tariff) ? FTTH : false
  const isAdsl = isAdslTariff(tariff) ? ADSL : false
  const isFijo = isElFijo ? 'MF' : false
  const technology = isFiber || isAdsl || isFijo

  const landlineScoringData = getFijoScoringData(landline, technology, isElFijo, is2p)
  const broadBandScoringData = getBroadBandScoringData(landline, technology, is2p)

  const mobileLineScoringData = getMobileDataScoring(mobileLine, tax, isElFijo, is2p)

  const proSVAsScoringData = getSVAsScoringData(landline, proSVAs, is2p)

  const result = [broadBandScoringData, landlineScoringData, ...proSVAsScoringData]

  return mobileLineScoringData ? [...result, mobileLineScoringData] : result
}

const getLineData = (lineNumber, tariff) => ({
  signUpType: SIGNUP_TYPE_NEWLINE,
  isSameUser: 'yes',
  tariff: {
    ...tariff,
    ps: tariff.id,
  },
  operator: {
    tradingName: '',
  },
  newNumber: {
    value: lineNumber,
  },
  lineNumber,
  newFixedNumber: lineNumber,
})

export const mapSubscriptionToLandline = (subscription, tariff) => {
  const lineNumber =
    getFixedLineNumber(subscription) || getFixedNumberOnFlySubscription(subscription)

  return getLineData(lineNumber, tariff)
}

export const mapSubscriptionToMobileline = (subscription, tariff) => {
  const mobileNumber = getMobileLineNumber(subscription)

  return getLineData(mobileNumber, tariff)
}

export const mapAddressToBillingAddress = address => ({
  street: `${address.streetOne} ${address.streetTwo}`,
  city: address.city,
  zipCode: address.postCode,
})

export const mapAddressToInstallAddress = address => ({
  streetType: address.addressType,
  street: `${address.name} ${address.other}`,
  number: address.number,
  city: address.city,
  zipCode: address.postalCode,
  block: address.block || '',
  floor: address.flat || '',
  stair: address.stairs || '',
  bis: address.bis || '',
  gescal: address.gescal || address.technicalId,
})

export const getHomeGoFromSvas = svas => {
  return svas.find(sva => sva.psId.includes(SVA_HOMEGO_CODE))
}

export const getOfferCareSubscriptionScoring = (subscription, tariff, proSVAs) => {
  const is2p = is2pTariff(subscription)
  const homeGoSva = getHomeGoFromSvas(proSVAs)
  const landLineData = mapSubscriptionToLandline(subscription, tariff)

  if(!landLineData.newNumber.value && homeGoSva) {
    landLineData.newNumber.value = getMsisdn(subscription)
  }

  return {
    packOfferId: homeGoSva ? homeGoSva.poId : null,
    lineDetails: getSVAsScoringData(landLineData, proSVAs, is2p),
  }
}
