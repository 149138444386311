import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { APP_CONFIG } from 'services/app-config'

import { isAtDomain, addQueryParam, getUrlObject, getUrlWithoutParams } from 'modules/url'

import { ENERGY_REDIRECT_URI_PARAM } from '../../contants'

import { energyRedirectSelectors } from './EnergyRedirectContainer.selectors'

const ACCESS_TOKEN_PARAM_NAME = 'yoigo_accesstoken'

export const EnergyRedirectContainer = () => {
  const { redirectUri, accessToken, urlParams } = useSelector(energyRedirectSelectors)

  useEffect(() => {
    if (!!redirectUri && !!accessToken && isAtDomain(redirectUri, APP_CONFIG.energy)) {
      const url = getUrlObject(redirectUri)

      let params = url.search || '?'

      urlParams.forEach(param => {
        if (param.name !== ENERGY_REDIRECT_URI_PARAM) {
          params = addQueryParam(params, param.name, param.value)
        }
      })

      params = addQueryParam(params, ACCESS_TOKEN_PARAM_NAME, accessToken)

      window.location.href = getUrlWithoutParams(url) + params
    }
  }, [redirectUri, accessToken])

  return <div />
}
