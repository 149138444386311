import { useSelector } from 'react-redux'
import { size } from 'lodash'

import {
  findFixedProduct,
  findMobileProduct,
  findFixedPermanents,
  findMobilePermanents,
  selectSubscriptionByProductId,
  getProductById,
  isFixedLine,
} from 'services/subscriptions'
import { permissions, selectCanIDO } from 'modules/Permissions'

import { canModifySubscription } from '../../selectors'

export function useIsEnabledCvmModifyPermanencyForLine(lineId) {
  const subscription = useSelector(selectSubscriptionByProductId(lineId))

  const product = getProductById(subscription, lineId)
  const isFixedLineVal = isFixedLine(product)

  const productFixed = findFixedProduct(subscription)
  const productMobile = findMobileProduct(subscription)

  const disabled = useSelector(state => !canModifySubscription(state, subscription))
  const permanentFixed = findFixedPermanents(subscription)
  const permanentMobile = findMobilePermanents(subscription)

  const permissionsCvmOpsModifyPermanencyFixed = useSelector(
    selectCanIDO(permissions.cvm_ops_modify_permanency_fixed.id),
  )
  const permissionsCvmOpsModifyPermanencyMobile = useSelector(
    selectCanIDO(permissions.cvm_ops_modify_permanency_mobile.id),
  )

  if (isFixedLineVal) {
    return (
      !disabled &&
      productFixed &&
      size(permanentFixed) > 0 &&
      permissionsCvmOpsModifyPermanencyFixed
    )
  }
  return (
    !disabled &&
    productMobile &&
    size(permanentMobile) > 0 &&
    permissionsCvmOpsModifyPermanencyMobile
  )
}
