import { call, put, takeEvery, select } from 'redux-saga/effects'
import { logError, formatErrorMessage } from 'services/logging'
import { axiosFactory } from 'modules/axios'
import { selectCustomerOrAgentToken, selectCustomerId, selectSfid } from 'modules/Auth'

import { appointmentActions, appointmentActionTypes } from './appointments.actions'

import { getToken, getKairosAppointmentToken } from '../services'
import { selectDataAppointment, selectSaleAppointment } from './appointments.selectors'

export function* getAppointmentTokenSaga() {
  try {
    yield put(appointmentActions.getAppointmentToken())
    const dataAppointment = yield select(selectDataAppointment)
    const token = yield select(selectCustomerOrAgentToken)
    const customerId = yield select(selectCustomerId)
    const appointmentToken = yield call(getToken, { data: dataAppointment, token, customerId })

    yield put(appointmentActions.gotAppointmentToken(appointmentToken))
  } catch (e) {
    yield call(logError, new Error(formatErrorMessage(e)))
    const error = axiosFactory.buildError(e)
    yield put(appointmentActions.getAppointmentTokenFailed(error))
  }
}

export function* watchGetAppointmentToken() {
  yield takeEvery(appointmentActionTypes.GET_APPOINTMENT_TOKEN_INIT, getAppointmentTokenSaga)
}

export function* getAppointmentSaleTokenSaga() {
  try {
    yield put(appointmentActions.getAppointmentToken())
    const dataAppointment = yield select(selectSaleAppointment)
    const appointmentToken = yield call(getToken, { data: dataAppointment, newURL: true })

    yield put(appointmentActions.gotAppointmentToken(appointmentToken))
  } catch (e) {
    yield call(logError, new Error(formatErrorMessage(e)))
    const error = axiosFactory.buildError(e)
    yield put(appointmentActions.getAppointmentTokenFailed(error))
  }
}

export function* watchGetAppointmentSaleToken() {
  yield takeEvery(
    appointmentActionTypes.GET_APPOINTMENT_SALE_TOKEN_INIT,
    getAppointmentSaleTokenSaga,
  )
}

export function* getKairosAppointmentTokenSaga({ payload }) {
  try {
    const sfid = yield select(selectSfid)

    const appointmentToken = yield call(getKairosAppointmentToken, { ...payload, user: sfid })

    yield put(appointmentActions.getKairosAppointmentTokenSuccess(appointmentToken.token))
  } catch (e) {
    yield call(logError, new Error(formatErrorMessage(e)))
    const error = axiosFactory.buildError(e)
    yield put(appointmentActions.getKairosAppointmentTokenFailed(error))
  }
}

export function* watchGetKairosAppointmentToken() {
  yield takeEvery(
    appointmentActionTypes.GET_KAIROS_APPOINTMENT_TOKEN,
    getKairosAppointmentTokenSaga,
  )
}
