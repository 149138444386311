import { createSelector } from 'reselect'
import { find, isEmpty, isNull, includes } from 'lodash'

import { APP_CONFIG } from 'services/app-config'
import { selectSfid } from 'modules/Auth'
import { selectors as customerNewSelectors } from 'services/customer-new'
import { selectors as customer360Selectors } from 'services/customer-360'

import { getProfileIdForUser, getPermissions, profiles } from '../profiles'
import { POS_STAGING_SFIDS, ADDITIONAL_OPS_ACCESS_SFIDS } from '../constants'

export const selectProfile = (sfid, customerSegment) => {
  if (!APP_CONFIG.production && POS_STAGING_SFIDS.includes(sfid)) {
    return profiles.pos.id
  }

  return getProfileIdForUser({ sfid }, customerSegment)
}

const selectCustomerSegment = createSelector(
  customer360Selectors.getSegment,
  customerNewSelectors.getSegment,
  (segmentFirstSource, segmentSecondSource) => segmentFirstSource || segmentSecondSource,
)

export const selectProfileId = createSelector(
  selectSfid,
  selectCustomerSegment,
  (sfid, customerSegment) => selectProfile(sfid, customerSegment),
)

export const selectIsPosOrMasProximo = createSelector(
  selectProfileId,
  profileId => profileId === profiles.pos.id || profileId === profiles.masProximo.id,
)

export const selectCanIDO = (permissionId, sfidsWithAdditionalAccess = null, sfidRegex = null) =>
  createSelector(
    selectProfileId,
    selectSfid,
    (profileId, sfid) =>
      Boolean(find(getPermissions(!isEmpty(profileId) ? [profileId] : []), { id: permissionId })) ||
      (!isEmpty(sfidsWithAdditionalAccess) ? sfidsWithAdditionalAccess.includes(sfid) : false) ||
      (!isNull(sfidRegex) ? sfidRegex.test(sfid) : false),
  )

export const selectHasSpecialAccessToAdditionalOps = agentSfid =>
  includes(ADDITIONAL_OPS_ACCESS_SFIDS, agentSfid)

export const selectProfilesDiscount = createSelector(selectProfileId, selectProfiles => {
  if (!isEmpty(selectProfiles)) {
    if (selectProfiles === profiles.backoffice.id || selectProfiles === profiles.atc_comercial.id) {
      return [profiles.atc.id]
    } if (selectProfiles === profiles.customer_loyalty_inbound.id || selectProfiles === profiles.customer_loyalty_outbound.id) {
      return [profiles.customer_loyalty.id]
    }
    return [selectProfiles]
  }
  return []
})
