import PropTypes from 'prop-types'
import { get } from 'lodash'

import { Tooltip as MatTooltip, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import { TextStyles } from 'utils/text'

const useStyles = makeStyles(theme => ({
  lightTooltip: {
    background: theme.palette.brand.light,
    color: theme.palette.global.gray_dark,
    boxShadow: get(theme, 'shadows[1]'),
    fontSize: 14,
    padding: '16px 11px',
    maxWidth: '265px',
  },
  hide: {
    display: 'none',
  },
}))

const ToolTip = ({ placement, title, children, visible, bold }) => {
  const classes = useStyles()

  return (
    <MatTooltip
      placement={placement}
      title={
        <>
          <Typography {...TextStyles.subtitle2Dark()}>{bold}</Typography>
          {title}
          <span className={classes.arrow} />
        </>
      }
      classes={{ tooltip: visible ? classes.lightTooltip : classes.hide }}>
      {children}
    </MatTooltip>
  )
}

ToolTip.propTypes = {
  title: PropTypes.string.isRequired,
  bold: PropTypes.string,
  placement: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
  visible: PropTypes.bool.isRequired,
}

export default ToolTip
