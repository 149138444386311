import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Box, Icon } from '@material-ui/core'

import { TextStyles } from 'utils/text'
import { ICONS } from 'utils/icons'

import { ButtonLink } from '../ui/buttons/ButtonLink'

import { getPillBorder, getPillColor } from './PillAlarm.helpers'

const useStyles = makeStyles({
  'pill-alarm': {
    border: ({ status }) => getPillBorder(status),
    display: 'flex',
    padding: 0,
    background: 'white',
    borderRadius: '14px',
    minHeight: '72px',
    maxHeight: '130px',

    '& .icon-wrapper': {
      display: 'flex',
      justifyContent: 'flex-start',
      width: '56px',
    },

    '& .rounded-corner': {
      backgroundImage: ({ status }) => getPillColor(status),
      borderRadius: '20% 0 90% 10%',
      height: '100%',
      position: 'relative',
      width: '100%',

      '& .icon': {
        position: 'absolute',
        top: '20%',
        left: '20%',
        color: 'white',
      },
    },

    '& .content-wrapper': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      padding: '8px',
      color: 'white',
    },
  },
})

export const PillAlarm = ({ title, subtitle, description, icon, dataHook, actions, status }) => {
  const classes = useStyles({ status })

  return (
    <div className={classes['pill-alarm']} data-hook={dataHook || 'pill-alarm'}>
      <div className="icon-wrapper">
        <div className="rounded-corner">
          {icon && (
            <Icon className="icon MuiIcon-outlined" data-hook={`icon-${icon}`}>
              {ICONS[icon]}
            </Icon>
          )}
        </div>
      </div>
      <div className="content-wrapper">
        {title && <Typography {...TextStyles.subtitle2Dark()}>{title}</Typography>}
        {subtitle && (
          <Typography
            {...TextStyles.labelDark({
              component: 'p',
            })}>
            {subtitle}
          </Typography>
        )}
        {description && (
          <Typography
            {...TextStyles.labelDark({
              component: 'p',
            })}>
            {description}
          </Typography>
        )}
        <Box display="flex">
          {actions &&
            actions.map(action => (
              <ButtonLink style={{ marginRight: '10px' }} onClick={action.onClick}>
                {action.name}
              </ButtonLink>
            ))}
        </Box>
      </div>
    </div>
  )
}

PillAlarm.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.string,
  icon: PropTypes.string,
  dataHook: PropTypes.string,
  status: PropTypes.string,
  actions: PropTypes.array,
}
