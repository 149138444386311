import { useEffect } from 'react'
import PropTypes from 'prop-types'

import { useFormikContext } from 'formik'

import { LineDataContainer } from '../../../containers/OfferContainer/LineDataContainer'
import { useSetFormikValuesToBasket } from '../../../hooks/Shared'
import { SalesTariffPicker } from '../../Common/SalesTariffPicker/SalesTariffPicker'

export function Line({
  tariffs,
  selectedTariff,
  lineFieldNames,
  formikLineValues,
  lineType,
  showPackages,
  error,
  isLoading,
  showPicker,
  showLineData,
  onChangeTariff,
  isStockCheck,
  disableOfferEdit,
  isMobileOnly,
  isMultiSim,
  isLeadFilled,
  errorDelete,
  shouldDisableTerminal,
}) {
  const { setFieldValue } = useFormikContext()

  const setFormikValuesToBasket = useSetFormikValuesToBasket()

  function onSetBundles(remainingPackages) {
    setFieldValue(lineFieldNames.SELECTED_PACKAGES, remainingPackages)
    setFormikValuesToBasket(lineFieldNames.SELECTED_PACKAGES, [...remainingPackages])
  }

  function onDeletePackage(id) {
    const remainingPackages = formikLineValues.selectedPackages.filter(pack => pack.id !== id)
    onSetBundles(remainingPackages)
  }

  useEffect(() => {
    if (tariffs.length === 1 && !selectedTariff.id && !isLoading) {
      onChangeTariff(tariffs[0])
    }
  }, [tariffs, isLoading])

  return (
    <>
      <div className="flex">
        {showPicker && (
          <SalesTariffPicker
            selectedTariff={selectedTariff}
            selectedBundles={formikLineValues.selectedPackages}
            tariffs={tariffs}
            isLoadingTariffs={isLoading}
            lineType={lineType}
            onSelectTariff={onChangeTariff}
            onCancelSelectTariff={() => {}}
            onSelectBundles={onSetBundles}
            onDeleteBundle={onDeletePackage}
            shouldShowAddBundles={showPackages}
            error={error}
            fieldNames={lineFieldNames}
            disableOfferEdit={disableOfferEdit}
            isMobileOnly={isMobileOnly}
            isStockCheck={isStockCheck}
            isLeadFilled={isLeadFilled}
            errorDelete={errorDelete}
            isMultiSim={isMultiSim}
          />
        )}
      </div>
      {showLineData && (
        <LineDataContainer
          selectedTariff={selectedTariff}
          lineType={lineType}
          isMobileOnly={isMobileOnly}
          isMultiSim={isMultiSim}
          disableOfferEdit={disableOfferEdit}
          isLeadFilled={isLeadFilled}
          shouldDisableTerminal={shouldDisableTerminal}
        />
      )}
    </>
  )
}

Line.propTypes = {
  tariffs: PropTypes.array,
  selectedTariff: PropTypes.object,
  lineFieldNames: PropTypes.object,
  formikLineValues: PropTypes.object,
  lineType: PropTypes.string,
  showPackages: PropTypes.bool,
  error: PropTypes.any,
  isLoading: PropTypes.bool,
  showPicker: PropTypes.bool,
  showLineData: PropTypes.bool,
  onChangeTariff: PropTypes.func,
  isStockCheck: PropTypes.bool,
  disableOfferEdit: PropTypes.bool,
  isMobileOnly: PropTypes.bool,
  isMultiSim: PropTypes.bool,
  isLeadFilled: PropTypes.bool,
  errorDelete: PropTypes.any,
  shouldDisableTerminal: PropTypes.bool,
}
