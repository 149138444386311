import { createSelector } from 'reselect'
import { get } from 'lodash'

import {
  getTelephoneFromOrdersCustomer,
  getFirstNameFromOrdersCustomer,
  getMidNameFromOrdersCustomer,
  getLastNameFromOrdersCustomer,
  getIdentificationIdFromOrder,
  getEmailFromOrdersCustomer,
  getOrderId,
} from 'modules/orders'

import {
  selectAccount,
  selectIsCompany,
  selectHasAgileTV,
} from 'modules/NewClientSales/store/selectors/Basket.selectors'

import {
  selectOrderStatusId,
  selectStatusScoringValue,
} from 'modules/NewClientSales/store/selectors/orders.selectors'
import { getCompanyInfo } from 'services/global-selectors/selectors'
import { getOrderData } from 'modules/orders/store/orders.selectors'

import {
  selectGescal,
  selectCity,
  selectProvince,
  selectPostalCode,
  selectTypeAddress,
  selectNameAddress,
  selectNumberAddress,
  selectDoorAddress,
  selectHandAddress,
  selectStairAddress,
  selectFloorAddress,
  selectBlockAddress,
  selectIdAddress,
  selectKmAddress,
  selectBisAddress,
  getCoverageToken,
  getTerritoryOwner,
  selectCoverageData,
  selectInstallationTechnologiesNames,
  NEBA,
  VULA,
  FTTH,
  ADSL,
} from 'modules/Coverage'

import { APP_CONFIG } from 'services/app-config'

const selectAppointments = state => state.appointments || {}

export const selectAppointmentToken = createSelector(
  selectAppointments,
  appointment => appointment.appointmentToken,
)

export const selectAppointmentError = createSelector(selectAppointments, state => !!state.error)

// Returns one of this values ADSL|FTTH|NEBA|VULA, but if it is NEBA or VULA return them with priority
function getMainTechnologyName(names = []) {
  const allowedValues = [NEBA, VULA, FTTH, ADSL]
  const technology = allowedValues.find(i => names.indexOf(i) !== -1)

  return technology === NEBA || technology === VULA ? `${FTTH} ${technology}` : technology
}

export const selectSaleAppointment = createSelector(
  selectIsCompany,
  selectAccount,
  selectCoverageData,
  getTerritoryOwner,
  getCoverageToken,
  selectOrderStatusId,
  selectStatusScoringValue,
  selectInstallationTechnologiesNames,
  selectHasAgileTV,
  (
    isCompany,
    account,
    coverage,
    territoryOwner,
    coverageToken,
    orderId,
    scoringLevel,
    technologiesNames,
    { hasAgileTV },
  ) => {
    const customer = {
      firstName: account.name,
      midName: account.surname1,
      lastName: account.surname2,
      identificationId: account.documentid,
      documentType: account.documenttype,
      companyCustomer: isCompany,
    }
    if (isCompany) {
      customer.documentIdCif = account.companyCif
    }

    let voipType = 'VOIP'
    if (hasAgileTV) {
      voipType += '__AGILETV'
    }

    const orderType = `Alta ${getMainTechnologyName(technologiesNames)} + ${voipType}`

    return {
      action: 'NUEVACITA',
      category: 'INSTALLATION',
      customer,
      contact: {
        emailAddress: account.email,
        fixedNumber: account.phoneContact,
        contactNumber: account.phoneContact2,
        characteristic: {
          type: 'InstallationAddress',
          door: coverage.door,
          hand: coverage.Hand,
          stair: coverage.stair,
          streetNumber: coverage.number,
          letter: coverage.letter,
          block: coverage.block,
          floor: coverage.floor,
          territoryOwner,
          gescal: coverage.addressId,
          coverageToken,
          km: coverage.Km,
          bis: coverage.bis_duplicate,
        },
        city: coverage.town,
        country: 'España',
        postCode: coverage.zipCode,
        stateOrProvince: coverage.province,
        streetOne: `${coverage.streetType} ${coverage.street}`,
        streetTwo: `${coverage.number}`,
      },
      externalId: `Y${orderId}`,
      gescal: coverage.gescal,
      limit: 28,
      marketSegment: 'RESIDENTIAL',
      message: '',
      productSpecification: {
        schema: {
          id: orderType,
        },
      },
      orderType,
      relatedParty: {
        id: 'ESHOP',
      },
      scoring: scoringLevel,
      state: 'No citada',
      urlCss: APP_CONFIG.cdnAppointmentsUrlCss,
    }
  },
)

export const selectDataAppointment = state => {
  // TODO: investigate why some orders has no phone number
  const defaultThelephoneNumber = {
    type: 'TelephoneNumber',
    medium: {
      number: '666666666',
    },
  }
  const telephoneNumber = getTelephoneFromOrdersCustomer(state)
  const email = getEmailFromOrdersCustomer(state)
  return {
    action: 'NUEVACITA',
    urlCss: APP_CONFIG.cdnAppointmentsUrlCss,
    scoring: '',
    state: 'No citada',
    message: '',
    marketSegment: 'RESIDENTIAL',
    gescal: selectGescal(state),
    limit: 28,
    category: 'INSTALLATION',
    externalId: `Y${getOrderId(state)}`,
    customer: {
      firstName: getFirstNameFromOrdersCustomer(state),
      midName: getMidNameFromOrdersCustomer(state),
      lastName: getLastNameFromOrdersCustomer(state),
      contactMedium: [
        telephoneNumber || defaultThelephoneNumber,
        telephoneNumber ? email : { ...email, preferred: 'true' },
        {
          preferred: 'false',
          type: 'InstallationAddress',
          medium: {
            city: selectCity(state),
            country: selectProvince(state),
            postCode: selectPostalCode(state),
            stateOrProvince: '',
            streetOne: `${selectTypeAddress(state)} ${selectNameAddress(state)}`,
            streetTwo: `${selectNumberAddress(state)}`,
            characteristic: [
              {
                name: 'door',
                value: selectDoorAddress(state),
              },
              {
                name: 'hand',
                value: selectHandAddress(state),
              },
              {
                name: 'stair',
                value: selectStairAddress(state),
              },
              {
                name: 'streetNumber',
                value: selectNumberAddress(state),
              },
              {
                name: 'letter',
                value: selectHandAddress(state),
              },
              {
                name: 'block',
                value: selectBlockAddress(state),
              },
              {
                name: 'floor',
                value: selectFloorAddress(state),
              },
              {
                name: 'territoryOwner',
                value: getTerritoryOwner(state),
              },
              {
                name: 'gescal',
                value: selectIdAddress(state),
              },
              {
                name: 'coverageToken',
                value: getCoverageToken(state),
              },
              {
                name: 'km',
                value: selectKmAddress(state),
              },
              {
                name: 'bis',
                value: selectBisAddress(state),
              },
            ],
          },
        },
      ],
      individualIdentification: [getIdentificationIdFromOrder(state)],
    },
    relatedParty: {
      id: 'ESHOP',
    },
    productSpecification: {
      schema: {
        id: getMainTechnologyName(selectInstallationTechnologiesNames(state)),
      },
    },
  }
}

export const selectKairosAppointmentData = state => {
  const companyId = get(getCompanyInfo(state), 'individualIdentification')
  const customerId = get(getOrderData(state), 'clientId')

  return {
    hasDeviceDelivery: false,
    order: getOrderId(state),
    docNumber: companyId || customerId,
  }
}

export const selectKairosAppointmentToken = createSelector(
  selectAppointments,
  appointment => appointment.kairos?.appointmentToken,
)

export const selectKairosAppointmentTokenError = createSelector(
  selectAppointments,
  appointment => appointment.kairos?.error,
)
