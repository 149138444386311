import { get } from 'lodash'
import { createMatchSelector } from 'connected-react-router'
import { createSelector } from 'reselect'
import { selectDocumentIdQueryParam } from 'modules/Router/store/router.selectors'

export const selectURLDocumentParams = createMatchSelector({
  path: '/vista/dashboard/documentType/:documentType/documentId/:documentId',
})

export const selectURLDocumentId = createSelector(
  [selectURLDocumentParams, selectDocumentIdQueryParam],
  (match, queryDocumentId) =>
    get(match, 'params.documentId', '').toUpperCase() || queryDocumentId.toUpperCase(),
)

export const selectURLDocumentType = createSelector(
  selectURLDocumentParams,
  match => get(match, 'params.documentType', ''),
)
