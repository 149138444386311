/* eslint-disable import/no-cycle */
import PropTypes from 'prop-types'

import { Typography, Icon, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import { ButtonCard, ButtonMenu, ToolTip } from 'components/ui'
import { getLighten } from 'utils'
import { ICONS } from 'utils/icons'

import { PopoverMenu } from '../PopoverMenu'

const useStyles = makeStyles(theme => ({
  'card-header': {
    '& .content': {
      borderBottom: ({ collapsed }) =>
        `${!collapsed ? '2px' : '0'} solid ${getLighten(theme.palette.global.gray_medium, 0.8)}`,

      '& > .titles': {
        outline: 'none',

        '& .title': {
          whiteSpace: 'nowrap',
          marginRight: '5px',
        },

        '& .icon': {
          marginLeft: '20px',
          alignSelf: 'center',
        },
      },

      '& > .rest': {
        '& > .actions': {
          '& > .button-card': {
            marginLeft: '20px',
          },

          '& > .button-disabled': {
            cursor: 'not-allowed',
            '&:hover': {
              color: theme.palette.bluePalette.main,
            },
          },
        },

        '& > .button-menu': {
          marginLeft: '20px',
        },
      },
    },

    '& .second-badge': {
      width: '100%',
      justifyContent: 'left',
    },
  },
}))

export const CardHeader = ({
  className,
  title,
  subTitle,
  toolTip = '',
  onClickCollapse,
  toggable,
  collapsed,
  children,
  actions,
  menuActions,
  preHeader,
}) => {
  const classes = useStyles({ collapsed })

  const iconType = collapsed ? ICONS.downArrow : ICONS.upArrow

  return (
    <div className={[classes['card-header'], ...(className ? [className] : [])].join(' ')}>
      {preHeader && preHeader}
      <Box
        display="flex"
        width="100%"
        flexWrap="wrap"
        className="content"
        justifyContent="space-between"
        p="20px 24px 18px 24px"
        data-hook="card-header">
        <Box
          display="flex"
          alignItems="baseline"
          mr="10px"
          className="titles clickable"
          onClick={() => onClickCollapse()}
          onKeyPress={() => onClickCollapse()}
          role="button"
          tabIndex="0"
          data-hook="action-show-subscription-card">
          {toolTip && (
            <ToolTip title={toolTip} placement="bottom" visible>
              <Typography className="title bold" variant="h5" color="textSecondary">
                {title}
              </Typography>
            </ToolTip>
          )}
          {!toolTip && (
            <Typography className="title bold" variant="h5" color="textSecondary">
              {title}
            </Typography>
          )}
          <Typography
            className="sub-title"
            variant="body1"
            color="textSecondary"
            style={{ fontWeight: 500 }}>
            {subTitle}
          </Typography>
          {toggable && (
            <Icon className="icon" data-hook={`icon_${iconType}`}>
              {iconType}
            </Icon>
          )}
        </Box>

        <Box display="flex" alignContent="center" className="rest">
          <Box display="flex" alignContent="center">
            {children}
          </Box>
          <Box display="flex" alignContent="center" className="actions">
            {actions &&
              actions.map(action => (
                <ButtonCard
                  className={action.disabled ? 'button-disabled' : null}
                  key={action.label}
                  {...action}
                />
              ))}
          </Box>
          <PopoverMenu ClickableComp={ButtonMenu} menuActions={menuActions} />
        </Box>
      </Box>
    </div>
  )
}

CardHeader.propTypes = {
  className: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  subTitle: PropTypes.string,
  toolTip: PropTypes.string,
  toggable: PropTypes.bool,
  onClickCollapse: PropTypes.func,
  collapsed: PropTypes.bool,
  children: PropTypes.node,
  preHeader: PropTypes.node,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      icon: PropTypes.string,
      action: PropTypes.func,
    }),
  ),
  menuActions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func,
      to: PropTypes.string,
      disabled: PropTypes.bool,
    }),
  ),
}
