export const FIND_CONVERGENT_TARIFFS = 'TARIFFS/FIND_CONVERGENT_TARIFFS/FETCH'
export const FIND_CONVERGENT_TARIFFS_SUCCESS = 'TARIFFS/FIND_CONVERGENT_TARIFFS/SUCCESS'
export const FIND_CONVERGENT_TARIFFS_ERROR = 'TARIFFS/FIND_CONVERGENT_TARIFFS/ERROR'

export const FIND_CONVERGENT_TARIFFS_IDS = 'TARIFFS/FIND_CONVERGENT_TARIFFS_IDS/FETCH'
export const FIND_CONVERGENT_TARIFFS_IDS_SUCCESS = 'TARIFFS/FIND_CONVERGENT_TARIFFS_IDS/SUCCESS'
export const FIND_CONVERGENT_TARIFFS_IDS_ERROR = 'TARIFFS/FIND_CONVERGENT_TARIFFS_IDS/ERROR'

export const FIND_TARIFFS = 'TARIFFS/FIND_TARIFFS/FETCH'
export const RESET_TARIFFS = 'TARIFFS/FIND_TARIFFS/RESET'
export const FIND_TARIFFS_SUCCESS = 'TARIFFS/FIND_TARIFFS/SUCCESS'
export const FIND_TARIFFS_ERROR = 'TARIFFS/FIND_TARIFFS/ERROR'
export const FIND_TARIFF_BY_ID = 'TARIFFS/FIND_TARIFF_BY_ID/FETCH'
export const FIND_TARIFF_BY_ID_SUCCESS = 'TARIFFS/FIND_TARIFF_BY_ID/SUCCESS'
export const FIND_TARIFF_BY_ID_ERROR = 'TARIFFS/FIND_TARIFF_BY_ID/ERROR'
export const SET_TERMINAL_MODEL = 'TARIFFS/SET_TERMINAL_MODEL'

export const SET_SELECTED_TARIFF = 'TARIFFS/SET_SELECTED_TARIFF'

export const findConvergentTariffsAction = filters =>
  console.warn(
    'The action "findConvergentTariffs" is deprecated and will be removed soon, do not use it or migrate to new generic action "findTariffs"',
  ) || {
    type: FIND_CONVERGENT_TARIFFS,
    payload: filters,
  }

export const findConvergentTariffsSuccessAction = data =>
  console.warn(
    'The action "findConvergentTariffsSuccess" is deprecated and will be removed soon, do not use it or migrate to new generic action "findTariffsSuccess"',
  ) || {
    type: FIND_CONVERGENT_TARIFFS_SUCCESS,
    payload: data,
  }

export const findConvergentTariffsErrorAction = error => ({
  type: FIND_CONVERGENT_TARIFFS_ERROR,
  payload: error,
})

export const findConvergentTariffsIdsAction = () => ({
  type: FIND_CONVERGENT_TARIFFS_IDS,
})
export const findConvergentTariffsIdsSuccessAction = data => ({
  type: FIND_CONVERGENT_TARIFFS_IDS_SUCCESS,
  payload: data,
})
export const findConvergentTariffsIdsErrorAction = error => ({
  type: FIND_CONVERGENT_TARIFFS_IDS_ERROR,
  payload: error,
})

export const findTariffsAction = filters => ({
  type: FIND_TARIFFS,
  payload: filters,
})
export const resetTariffsAction = () => ({
  type: RESET_TARIFFS,
})
export const findTariffsSuccessAction = ({ data, filters = {} }) => ({
  type: FIND_TARIFFS_SUCCESS,
  payload: { data, filters },
})

export const findTariffsErrorAction = (error, filters) => ({
  type: FIND_TARIFFS_ERROR,
  payload: { error, filters },
})
export const findTariffByIdAction = id => ({
  type: FIND_TARIFF_BY_ID,
  payload: id,
})

export const findTariffByIdSuccessAction = ({ data, filters = {} }) => ({
  type: FIND_TARIFF_BY_ID_SUCCESS,
  payload: { data, filters },
})

export const findTariffByIdErrorAction = error => ({
  type: FIND_TARIFF_BY_ID_ERROR,
  payload: error,
})
export const setTerminalAction = (model, lineType) => ({
  type: SET_TERMINAL_MODEL,
  payload: { model, lineType },
})

export const setSelectedTariffAction = (tariffId, lineType) => ({
  type: SET_SELECTED_TARIFF,
  payload: {
    tariffId,
    lineType,
  },
})
