import PropTypes from 'prop-types'

import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import { SpinnerCenter } from 'components/packages/ui/SpinnerCenter'
import { ActionAdvice } from 'components/ui'

import { DEVICE_RENEWAL_FIELDNAMES } from 'modules/device-renewal/constants'
import { PenaltyAmendCheckbox } from '../PenaltyAmendCheckbox'
import { FinancingDecisionRadio } from '../FinancingDecisionRadio'

const useStyles = makeStyles({
  radioQuestion: {
    marginRight: '24px',
  },
  decisionOptionText: {
    fontWeight: '500',
  },
})

export const FinancedDeviceConditions = ({ financingConditionsInfo, deviceInfo }) => {
  const {
    financingConditionsError,
    isLoadingFinancingConditions,
    shouldAmendPenalty,
    shouldLetDecideIfAmendPenalty,
    shouldAllowFinancingDecision,
    shouldMantainFinancing,
    shouldAllowFinalPaymentDecision,
  } = financingConditionsInfo
  const { finalPayment } = deviceInfo

  const classes = useStyles()

  return (
    <>
      {isLoadingFinancingConditions ? (
        <SpinnerCenter />
      ) : (
        <>
          <PenaltyAmendCheckbox
            shouldAmendPenalty={shouldAmendPenalty}
            shouldLetDecideIfAmendPenalty={shouldLetDecideIfAmendPenalty}
            fieldNames={DEVICE_RENEWAL_FIELDNAMES}
            classes={classes}
          />
          <FinancingDecisionRadio
            finalPayment={finalPayment}
            fieldNames={DEVICE_RENEWAL_FIELDNAMES}
            classes={classes}
            shouldAllowFinancingDecision={shouldAllowFinancingDecision}
            shouldMantainFinancing={shouldMantainFinancing}
            shouldAllowFinalPaymentDecision={shouldAllowFinalPaymentDecision}
          />
        </>
      )}

      {financingConditionsError && (
        <Box mt="12px">
          <ActionAdvice
            type="error"
            message="Ha habido un error al cargar las condiciones financieras. Por favor, inténtalo más tarde."
            width="100%"
          />
        </Box>
      )}
    </>
  )
}

FinancedDeviceConditions.propTypes = {
  financingConditionsInfo: PropTypes.object,
  deviceInfo: PropTypes.object,
}
