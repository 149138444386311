import { useEffect } from 'react'
import PropTypes from 'prop-types'

import { useFormikContext } from 'formik'

import { Checkbox, FormControlLabel, Box, Divider } from '@material-ui/core'

export const PenaltyAmendCheckbox = ({
  fieldNames,
  shouldAmendPenalty,
  shouldLetDecideIfAmendPenalty,
}) => {
  const { setFieldValue, values } = useFormikContext()

  const showAmendPenaltyCheckbox = shouldAmendPenalty || shouldLetDecideIfAmendPenalty

  const onCheckAmendPenalty = () => {
    setFieldValue(fieldNames.FINANCED_AMEND_PENALTY, !values.financedDeviceDecisions.amendPenalty)
  }

  useEffect(() => {
    if (shouldAmendPenalty) {
      setFieldValue(fieldNames.FINANCED_AMEND_PENALTY, true)
    }
  }, [shouldAmendPenalty])

  return (
    <>
      {showAmendPenaltyCheckbox ? (
        <>
          <Divider className="dotted" />
          <Box mt="12px">
            <FormControlLabel
              key="penalty-amend-checkbox"
              control={
                <Checkbox
                  key="penalty-amend-checkbox"
                  name={fieldNames.FINANCED_AMEND_PENALTY}
                  color="primary"
                  className="checkbox"
                  checked={values.financedDeviceDecisions.amendPenalty}
                  onChange={() => onCheckAmendPenalty()}
                  disabled={shouldAmendPenalty}
                />
              }
              label="Perdonar penalización"
            />
          </Box>
        </>
      ) : null}
    </>
  )
}

PenaltyAmendCheckbox.propTypes = {
  fieldNames: PropTypes.object,
  shouldAmendPenalty: PropTypes.bool,
  shouldLetDecideIfAmendPenalty: PropTypes.bool,
}
