import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { getMsisdn } from 'services/subscriptions'

import { getHasModifications, getChangedTariff } from '../../selectors'

import { ItemLine } from '../ItemLine'
import { TariffItemModified } from '../TariffItemModified'
import { UnsubscribeModified } from '../UnsubscribeModified'
import { DiscountsBonusesModified } from '../DiscountsBonusesModified'
import { PermanenceModified } from '../PermanenceModified'

export const ModificationDetails = styled(({ className, subscription }) => {
  const hasModifications = useSelector(state => getHasModifications(state, getMsisdn(subscription)))
  const changedTariff = useSelector(getChangedTariff(subscription))

  return hasModifications || changedTariff ? (
    <div data-hook="modification-details" className={className}>
      <ItemLine label="Cambios a realizar:" />
      <div className="section-advices">
        <TariffItemModified subscription={subscription} />
        <UnsubscribeModified subscription={subscription} />
        <DiscountsBonusesModified subscription={subscription} />
        <PermanenceModified subscription={subscription} />
        <PermanenceModified isFixed subscription={subscription} />
      </div>
    </div>
  ) : null
})`
  > .section-advices {
    margin-top: 16px;

    & .advice-wrapper:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  > .MuiTypography-root {
    margin-right: 18px;
  }
`
ModificationDetails.propTypes = {
  className: PropTypes.string,
  subscription: PropTypes.object,
}
