import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { Sidebar } from './Sidebar/Sidebar'
import { ORDERED_DEVICE_RENEWAL_STEPS } from '../../constants'
import { sidebarSelectors } from './SidebarContainer.selectors'

export const SidebarContainer = ({ stepName, ...restProps }) => {
  const { shouldShowDeviceType, shouldShowDeviceInfo, shouldShowCurrentDevice } = useMemo(() => {
    const currentStepIndex = ORDERED_DEVICE_RENEWAL_STEPS.indexOf(stepName)

    return {
      shouldShowDeviceType: currentStepIndex > 0,
      shouldShowDeviceInfo: currentStepIndex > 0,
      shouldShowCurrentDevice: currentStepIndex > 1,
    }
  }, [stepName])

  const selectors = useSelector(sidebarSelectors)

  return (
    <Sidebar
      shouldShowClientInfo
      shouldShowDeviceType={shouldShowDeviceType}
      shouldShowDeviceInfo={shouldShowDeviceInfo}
      shouldShowCurrentDevice={shouldShowCurrentDevice}
      {...restProps}
      {...selectors}
    />
  )
}

SidebarContainer.propTypes = {
  stepName: PropTypes.string,
}
