const GET_APPOINTMENT_SALE_TOKEN_INIT = 'OVID/ORDERS/FIND_APPOINTMENT_SALE_INIT'
const GET_APPOINTMENT_TOKEN_INIT = 'OVID/ORDERS/GET_APPOINTMENT_TOKEN_INIT'
const GET_APPOINTMENT_TOKEN = 'OVID/ORDERS/GET_APPOINTMENT_TOKEN'
const GOT_APPOINTMENT_TOKEN = 'OVID/ORDERS/GOT_APPOINTMENT_TOKEN'
const GET_APPOINTMENT_TOKEN_FAILED = 'OVID/ORDERS/GET_APPOINTMENT_TOKEN_FAILED'
const SET_APPOINTMENT_ERROR = 'OVID/APPOINTMENTS/SET_APPOINTMENT_ERROR'

const GET_KAIROS_APPOINTMENT_TOKEN = 'OVID/APPOINTMENTS/GET_KAIROS_APPOINTMENT_TOKEN'
const GET_KAIROS_APPOINTMENT_TOKEN_SUCCESS =
  'OVID/APPOINTMENTS/GET_KAIROS_APPOINTMENT_TOKEN_SUCCESS'
const GET_KAIROS_APPOINTMENT_TOKEN_FAILED = 'OVID/APPOINTMENTS/GET_KAIROS_APPOINTMENT_TOKEN_FAILED'

export const appointmentActionTypes = {
  GET_APPOINTMENT_TOKEN_INIT,
  GET_APPOINTMENT_SALE_TOKEN_INIT,
  GET_APPOINTMENT_TOKEN,
  GOT_APPOINTMENT_TOKEN,
  GET_APPOINTMENT_TOKEN_FAILED,
  SET_APPOINTMENT_ERROR,
  GET_KAIROS_APPOINTMENT_TOKEN,
  GET_KAIROS_APPOINTMENT_TOKEN_SUCCESS,
  GET_KAIROS_APPOINTMENT_TOKEN_FAILED,
}

const getAppointmentTokenInit = () => ({
  type: appointmentActionTypes.GET_APPOINTMENT_TOKEN_INIT,
})

const getAppointmentSaleTokenInit = () => ({
  type: appointmentActionTypes.GET_APPOINTMENT_SALE_TOKEN_INIT,
})

const getAppointmentToken = () => ({
  type: appointmentActionTypes.GET_APPOINTMENT_TOKEN,
})

const gotAppointmentToken = appointmentToken => ({
  type: appointmentActionTypes.GOT_APPOINTMENT_TOKEN,
  payload: {
    appointmentToken,
  },
})

const getAppointmentTokenFailed = error => ({
  type: appointmentActionTypes.GET_APPOINTMENT_TOKEN_FAILED,
  payload: {
    error,
  },
})

const getKairosAppointmentToken = payload => ({
  type: appointmentActionTypes.GET_KAIROS_APPOINTMENT_TOKEN,
  payload,
})

const getKairosAppointmentTokenSuccess = appointmentToken => ({
  type: appointmentActionTypes.GET_KAIROS_APPOINTMENT_TOKEN_SUCCESS,
  payload: {
    appointmentToken,
  },
})

const getKairosAppointmentTokenFailed = error => ({
  type: appointmentActionTypes.GET_KAIROS_APPOINTMENT_TOKEN_FAILED,
  payload: {
    error,
  },
})

const setAppointmentError = error => ({
  type: appointmentActionTypes.SET_APPOINTMENT_ERROR,
  payload: {
    error,
  },
})

export const appointmentActions = {
  getAppointmentTokenInit,
  getAppointmentSaleTokenInit,
  getAppointmentToken,
  gotAppointmentToken,
  getAppointmentTokenFailed,
  setAppointmentError,
  getKairosAppointmentToken,
  getKairosAppointmentTokenSuccess,
  getKairosAppointmentTokenFailed,
}
