import { useState } from 'react'
import PropTypes from 'prop-types'

import { Box, Collapse } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import { getSegmentColor, getSegmentIcon, getDarken } from 'utils'

import { formatCensuredDni, formatCensuredNif } from 'modules/vista-360/helpers/formats.helper'
import ListDivider from '../../list/ListDivider'
import IconLineItem from '../../list/IconLineItem'

const useStyles = makeStyles(theme => ({
  'client-basic-info': {
    borderBottom: '1px solid rgba(255, 255, 255, 0.2)',

    '& .extra-info': {
      backgroundColor: getDarken(theme.palette.brand.medium, 0.1),
    },

    '& .wrapper': {
      padding: '24px',
    },

    '& .icon-line-item-header': {
      textTransform: 'uppercase',

      '& .first-block': {
        borderRadius: '4px',
      },

      '& .icon': {
        marginLeft: '8px',
      },
    },

    '& .icon-line-item': {
      '& .first-block p': {
        lineHeight: '1em',
      },
    },
  },
}))

const ClientBasicInfo = ({ customerDat, ...rest }) => {
  const classes = useStyles()

  const [collapsed, setCollapsed] = useState(true)
  const toggle = () => setCollapsed(!collapsed)

  return (
    <div data-hook="client-basic-info" className={classes['client-basic-info']} {...rest}>
      {/* Fixed Client Info */}
      <Box p="24px" className="fixed-info">
        <IconLineItem
          className="icon-line-item-header"
          bgColor={getSegmentColor(customerDat.segment)}
          iconType={getSegmentIcon(customerDat.segment)}
          primaryTxt={customerDat.segmentLine}
        />
        {!!customerDat.vipType && (
          <IconLineItem
            className="icon-line-item-header"
            bgColor={getSegmentColor('influencer')}
            iconType={getSegmentIcon('influencer')}
            primaryTypo={{ variant: 'body1', className: '' }}
            primaryTxt={customerDat.vipType}
          />
        )}

        {!!customerDat.hasValidCustomerSva && (
          <IconLineItem
            className="icon-line-item-header"
            bgColor={getSegmentColor('customersva')}
            iconType={getSegmentIcon('influencer')}
            primaryTxt={customerDat.customerSvaLabel}
            data-hook="customer-sva"
          />
        )}

        {(customerDat.hasVipError || !!customerDat.customerSvaError) && (
          <IconLineItem
            className="icon-line-item-header"
            bgColor={getSegmentColor('warning')}
            iconType={getSegmentIcon('warning')}
            primaryTypo={{ variant: 'subtitle2', style: { fontWeight: 'normal' } }}
            primaryTxt="recargue cliente"
            data-hook="customer-vip-sva-error"
          />
        )}

        {customerDat.isCompany && (
          <>
            <Box p="10px 0">
              <IconLineItem className="icon-line-item" primaryTxt={customerDat.company} />
              <IconLineItem
                className="icon-line-item"
                primaryTxt={
                  customerDat.isPosOrMasproximo
                    ? formatCensuredNif(customerDat.documentId)
                    : customerDat.documentId
                }
              />
            </Box>
            <Box p="10px 0">
              <IconLineItem className="icon-line-item" primaryTxt={customerDat.fullName} />
              <IconLineItem
                className="icon-line-item"
                primaryTxt={
                  customerDat.isPosOrMasproximo
                    ? formatCensuredDni(customerDat.authorizedPersonDocumentId)
                    : customerDat.authorizedPersonDocumentId
                }
              />
            </Box>
          </>
        )}
        {!customerDat.isCompany && (
          <Box p="10px 0">
            <IconLineItem className="icon-line-item" primaryTxt={customerDat.fullName} />
            <IconLineItem
              className="icon-line-item"
              primaryTxt={
                customerDat.isPosOrMasproximo
                  ? formatCensuredDni(customerDat.documentId)
                  : customerDat.documentId
              }
            />
          </Box>
        )}
        <>
          <ListDivider opacity="0.4" />
          <IconLineItem
            primaryTxt={['Activo desde', customerDat.activeFromStr].filter(Boolean).join(': ')}
          />
          <IconLineItem
            actionLabel={collapsed ? 'Ver datos de contacto' : 'Ocultar datos de contacto'}
            action={toggle}
            data-hook="show-contact-data-link"
          />
        </>
      </Box>

      {/* Extra Client Info */}
      <Box className="extra-info">
        <Collapse
          in={!collapsed}
          timeout="auto"
          classes={{ wrapper: 'wrapper' }}
          className="collapse">
          <IconLineItem
            iconType="addressPoint"
            alignItems="flex-start"
            primaryTypo={{
              variant: 'subtitle2',
              style: { fontWeight: 'normal' },
            }}
            primaryTxt={customerDat.billingAddress}
            data-hook="address-contact"
          />

          <IconLineItem
            iconType="phone"
            primaryTypo={{
              variant: 'subtitle2',
              style: { fontWeight: 'normal', lineHeight: 'inherit' },
            }}
            primaryTxt={customerDat.tfn}
            data-hook="phone-contact"
          />

          <IconLineItem
            iconType="email"
            primaryTypo={{
              variant: 'subtitle2',
              style: { fontWeight: 'normal', lineHeight: 'inherit' },
            }}
            primaryTxt={customerDat.email}
            data-hook="email-contact"
          />
        </Collapse>
      </Box>
    </div>
  )
}

ClientBasicInfo.propTypes = {
  customerDat: PropTypes.object,
  theme: PropTypes.object,
  collapsed: PropTypes.bool,
  toggle: PropTypes.func,
}

export default ClientBasicInfo
