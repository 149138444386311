import { createSelector, createStructuredSelector } from 'reselect'
import { get, isEmpty } from 'lodash'
import { selectCoverageData, selectTechnology } from 'modules/Coverage'
import { getIBANData } from 'modules/Iban'
import { selectSfid, selectIpAddress } from 'modules/Auth'
import {
  selectIsCarteraQueryParam,
  selectIs2p,
  selectIsSubscriptionQueryParam,
  selectIsCrossSellQueryParam,
  selectIsConvergent,
  selectIsMobileOnly,
  selectIsMultiSimQueryParam,
} from 'modules/Router/store/index'

import { SVA_HOMEGO_CODE } from 'modules/offers-configuration/constants'

import { ACQUISITION, CROSS_SELL_ACQUISITION } from 'modules/SharedSales/constants'
import {
  selectFinancedDeviceLegalConsentsFlag,
  selectInternalPortabilityFlag,
  selectIsSVAEmail,
} from 'services/feature-flag/selectors'
import {
  selectLandlineOffer,
  selectMainMobileLineOffer,
  selectShipping,
  selectBilling,
  selectAccount,
  selectExtraMobileLinesOffers,
  selectTerminalsShipping,
  selectTerminalPaymentInfo,
  selectServiceAddress,
  selectIsElFijo,
  selectSalesTypes,
  selectSaleChannel,
  selectIsPosSale,
  selectSVAAppleTv,
  selectOrderCheckout,
  selectFinancedConsents,
} from './Basket.selectors'
import {
  selectInstallation,
  selectBillinAddressByType,
  selectIs2pPure,
} from './NewClientSales.selectors'
import {
  mapToInstallationMedium,
  mapToBillingAccount,
  mapToIndividualIdentification,
  mapToCompanyIdentification,
  mapToContactMedia,
  mapToPersonalData,
  mapToGetExtraMobileLines,
  mapToGetFullExtraMobileLines,
  mapToOtherAddress,
  mapToCompanyData,
  mapToSelectIdDueDate,
  mapToFormatMainOrder,
  mapToRootOrderMobileItem,
  mapToRootOrderLandlineItem,
  hasAddressId,
  mapToElFijoShippingAddress,
  mapToCarteraOrShippingAddress,
} from './checkout.selectors.helpers'

import { mapToCCChannel } from '../../helpers'

const selectExtraMobileLinesOffersOrdered = createSelector(
  selectExtraMobileLinesOffers,
  extras => extras.sort(a => (a.tariff.tariffType === 'extra_line' ? -1 : 1)),
)

const selectSalesInfo = createSelector(
  selectIsMobileOnly,
  selectIsElFijo,
  selectSaleChannel,
  selectSfid,
  selectIpAddress,
  selectAccount,
  selectIsPosSale,
  selectIsCrossSellQueryParam,
  selectIsMultiSimQueryParam,
  // selectResellerQueryParam,
  // TODO: channel support
  // (isMobileOnly, isElFijo, channel, sfid, ip, account, isPos, isCrossSell, reseller) => ({
  (isMobileOnly, isElFijo, channel, sfid, ip, account, isPos, isCrossSell, isMultiSim) => ({
    channel: mapToCCChannel(channel, isMobileOnly || isElFijo || isMultiSim),
    sfid,
    ip,
    isPos,
    isCrossSell,
    salesType: isCrossSell ? CROSS_SELL_ACQUISITION : ACQUISITION,
    isMultiSim,
    // reseller,
  }),
)

// order.addInstallationMedium CONTACTMEDIA
const selectInstallationMedium = createSelector(
  selectCoverageData,
  mapToInstallationMedium,
)

const selectShippingMedium = createSelector(
  selectCoverageData,
  selectShipping,
  selectServiceAddress,
  selectIsElFijo,
  selectIsMobileOnly,
  selectBilling,
  selectIsCarteraQueryParam,
  selectIsSubscriptionQueryParam,
  (coverage, shipping, service, isElFijo, isMobileOnly, billing, isCartera, isSubscription) => {
    const elFijoMainDefaultAddress = mapToElFijoShippingAddress(shipping, billing, service)
    const otherSaleDefaultAddress =
      isMobileOnly || !!isSubscription ? billing.otherAddress : coverage
    const finalDefaultAddress = isElFijo ? elFijoMainDefaultAddress : otherSaleDefaultAddress

    if ((!!isCartera || !!isSubscription) && !hasAddressId(finalDefaultAddress)) {
      return mapToCarteraOrShippingAddress(finalDefaultAddress, shipping)
    }
    return mapToOtherAddress(finalDefaultAddress, shipping)
  },
)

const selectMainAddress = createSelector(
  selectIsMobileOnly,
  selectIsElFijo,
  selectInstallationMedium,
  selectBillinAddressByType,
  selectServiceAddress,
  (isMobileOnly, isElFijo, installation, billing, service) => {
    if (isElFijo) return service
    return isMobileOnly ? billing : installation
  },
)

const selectTerminalAddress = createSelector(
  selectCoverageData,
  selectTerminalsShipping,
  selectShippingMedium,
  selectIsMobileOnly,
  selectIsElFijo,
  selectIsSubscriptionQueryParam,
  (coverage, terminal, shipping, isMobileOnly, isElFijo, isSubscription) =>
    isMobileOnly || isElFijo || !!isSubscription ? shipping : mapToOtherAddress(coverage, terminal),
)

//  order.addBillingAccount
const selectBillingAccount = createSelector(
  [state => state, selectBilling],
  (state, billing) => mapToBillingAccount(billing, getIBANData(state)),
)

// order.individualIdentification

const selectIdDueDate = createSelector(
  selectMainMobileLineOffer,
  mapToSelectIdDueDate,
)

const selectIndividualIdentification = createSelector(
  selectAccount,
  selectIdDueDate,
  mapToIndividualIdentification,
)

// order.companyIdentification
const selectCompanyIdentification = createSelector(
  selectAccount,
  mapToCompanyIdentification,
)

// individual.addContactMedia
const selectIndividualContactMedia = createSelector(
  selectAccount,
  mapToContactMedia,
)

export const selectPersonalData = createSelector(
  selectAccount,
  mapToPersonalData,
)

const selectCompanyData = createSelector(
  selectAccount,
  mapToCompanyData,
)

const selectExtraMobileLines = createSelector(
  selectExtraMobileLinesOffersOrdered,
  selectBillingAccount,
  selectAccount,
  selectShippingMedium,
  selectTerminalAddress,
  selectMainAddress,
  selectFinancedConsents,
  selectFinancedDeviceLegalConsentsFlag,
  selectIsMultiSimQueryParam,
  mapToGetExtraMobileLines,
)

const selectFullExtraLineTariff = createSelector(
  selectExtraMobileLinesOffersOrdered,
  mapToGetFullExtraMobileLines,
)

const selectOrderSegment = createSelector(
  selectAccount,
  account => get(account, 'segment'),
)

const selectLandlineSVAS = createSelector(
  selectLandlineOffer,
  landline => {
    const SVA = {
      id: landline.SVA.poId,
      noDelivery: true,
      inputtedCharacteristics: { CH_TV_EMAIL: landline.SVA.email },
    }
    const SVAPro = landline.proSVAs
      .filter(proSVA => !proSVA.psId.includes(SVA_HOMEGO_CODE))
      .map(elem => ({
        noDelivery: true,
        id: elem.poId,
        inputtedCharacteristics: elem.fields,
      }))

    return SVA.id ? [SVA, ...SVAPro] : SVAPro
  },
)

const selectMainLineBundles = createSelector(
  selectIsMobileOnly,
  selectIsElFijo,
  selectLandlineOffer,
  selectMainMobileLineOffer,
  selectLandlineSVAS,
  (isMobileOnly, isElFijo, landline, mainMobile, svas) => {
    return [
      ...(isMobileOnly || isElFijo
        ? get(mainMobile, 'tariff.bundledItems', [])
        : get(landline, 'tariff.bundledItems', [])),
      ...mainMobile.selectedPackages.filter(bundle => !bundle.active),
      ...svas,
    ]
  },
)

const selectMainRootOrderItem = createSelector(
  selectIsMobileOnly,
  selectLandlineOffer,
  selectMainMobileLineOffer,
  selectCoverageData,
  selectInstallation,
  selectTechnology,
  selectIsElFijo,
  selectIs2p,
  selectIsCrossSellQueryParam,
  selectIs2pPure,
  selectInternalPortabilityFlag,
  selectIsMultiSimQueryParam,
  (
    isMobileOnly,
    landline,
    mainMobile,
    coverage,
    installation,
    technology,
    isElFijo,
    is2p,
    isCrossSell,
    is2pPure,
    internalPortabilityFlag,
    isMultiSim,
  ) =>
    (isMobileOnly || isMultiSim) && !isElFijo
      ? mapToRootOrderMobileItem(mainMobile)
      : mapToRootOrderLandlineItem(
          landline,
          mainMobile,
          coverage,
          installation,
          technology,
          isElFijo ? mainMobile.tariff.id : false,
          is2p,
          isCrossSell,
          is2pPure,
          internalPortabilityFlag,
        ),
)

const selectMainOrder = createSelector(
  [
    selectMainRootOrderItem,
    selectIsMobileOnly,
    selectIsElFijo,
    selectLandlineOffer,
    selectMainMobileLineOffer,
    selectTerminalAddress,
    selectBillingAccount,
    selectShippingMedium,
    selectAccount,
    selectMainAddress,
    selectMainLineBundles,
    selectSalesTypes,
    selectIs2p,
    selectIsCrossSellQueryParam,
    selectIs2pPure,
    selectIsConvergent,
    selectFinancedConsents,
    selectFinancedDeviceLegalConsentsFlag,
  ],
  mapToFormatMainOrder,
)

const selectAllOrders = createSelector(
  [
    selectIsSubscriptionQueryParam,
    selectIsMultiSimQueryParam,
    selectMainOrder,
    selectExtraMobileLines,
  ],
  (isSubscription, isMultiSim, main, extra) =>
    isSubscription || isMultiSim ? extra : [main, ...extra],
)

const selectIsOrderGenerated = createSelector(
  [selectOrderCheckout],
  order => !isEmpty(order),
)

export const orderCheckoutSelectors = createStructuredSelector({
  isOrderGenerated: selectIsOrderGenerated,
  salesInfo: selectSalesInfo,
  allOrders: selectAllOrders,
  billingAddress: selectBillinAddressByType,
  individualIdentification: selectIndividualIdentification,
  companyIdentification: selectCompanyIdentification,
  individualContactMedia: selectIndividualContactMedia,
  personalInformation: selectPersonalData,
  companyData: selectCompanyData,
  offerDetail: selectLandlineOffer,
  extraLinesDetails: selectFullExtraLineTariff,
  segment: selectOrderSegment,
  terminalPaymentInfo: selectTerminalPaymentInfo,
  isSVAEmail: selectIsSVAEmail,
  isAppleTv: selectSVAAppleTv,
})
