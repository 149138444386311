import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'

const PresetAddress = ({ title, address }) => {
  if (!address) {
    return null
  }

  return (
    <div className="flex align-start-center">
      <Typography variant="body1" className="p-r-4">
        La dirección es: {title}
      </Typography>
      <Typography variant="body1" className="bold" data-hook="delivery-address">
        {address}
      </Typography>
    </div>
  )
}

PresetAddress.propTypes = {
  title: PropTypes.string,
  address: PropTypes.string,
}

PresetAddress.defaultProps = {
  title: 'Se entregará en la dirección: ',
}

export default PresetAddress
