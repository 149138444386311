import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { Grid } from '@material-ui/core'
import { Button } from 'components/ui'
import { useActionsButtonsStyles } from 'modules/typifications/styles'
import { selectEnabledExitButtonIframeTipis } from 'services/feature-flag/selectors'

export const ActionsButtons = ({ buttons }) => {
  const actionsButtons = useActionsButtonsStyles()
  const enabledExitButton = useSelector(selectEnabledExitButtonIframeTipis)
  return (
    <Grid container className={actionsButtons.styles} display="flex">
      {buttons &&
        buttons.map((button, index) => {
          const { name, action, type, isExitButton, disabled } = button
          const isSecondaryButton = (index + 1) % 2 === 0
          const showButton = (isExitButton && enabledExitButton) || !isExitButton
          const classButtonContainer = isSecondaryButton
            ? 'second-button-container'
            : 'primary-button-container'

          return (
            <Grid key={`action-button_${name}`} item className={classButtonContainer} xs={6}>
              {showButton && (
                <Button type={type} onClick={action} disabled={disabled}>
                  {name}
                </Button>
              )}
            </Grid>
          )
        })}
    </Grid>
  )
}

ActionsButtons.propTypes = {
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      action: PropTypes.func,
      type: PropTypes.string,
      isExitButton: PropTypes.bool.isRequired,
      disabled: PropTypes.bool,
    }),
  ).isRequired,
}
