import PropTypes from 'prop-types'
import { isEmpty } from 'lodash/fp'
import { TextField, MenuItem } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import { capitalizeText } from 'utils/formatting'

import { fieldStates } from './constants'
import { useFieldState } from './useFieldState'
import { useFocusHandler } from './useFocusHandler'

/*
  It's hard to make friends: MUI internal component & styled-component
  But this technique allows to style internal MUI components without pain
  We can't select MUI components by MUI classes - classes are erased in prod build

  So, lets create our own classNames and pass them by props to <TextField />
 */

const useStyles = makeStyles(theme => ({
  cssLabel: {
    color: theme.palette?.global.gray_dark,
  },

  cssOutlinedInput: {
    '&:hover $notchedOutline': {
      borderColor: theme.palette?.global.gray,
    },
  },

  cssError: {
    '& $notchedOutline, &:hover $notchedOutline': {
      borderColor: `${theme.palette?.brand.alert} !important`,
    },
  },

  cssFocused: {
    '& $notchedOutline, &:hover $notchedOutline': {
      borderColor: theme.palette?.brand.action_primary,
    },
  },

  notchedOutline: {
    borderColor: theme.palette?.global.gray,
  },

  'valid-outlined-input': {
    '& $notchedOutline': {
      borderColor: `${theme.palette?.brand.action_primary} !important`,
    },
  },

  'valid-outlined-label': {
    color: theme.palette?.brand.action_primary,
  },

  cssSelect: {
    '& div:focus': {
      backgroundColor: 'initial', // override MUI grey style
    },
  },
}))

const getHelperText = ({ focused, error, touched, helperText }) =>
  !isEmpty(error) && !focused && touched ? error : helperText

function removeLetters(text) {
  if (!text.match(/^[0-9]+$/)) {
    return text.replace(/\D/g, '')
  }
  return text
}

const getCensuredComponent =
  censuredHelper =>
  ({ inputRef, value, ...other }) =>
    <input ref={inputRef} value={censuredHelper(value)} {...other} />

/**
 * Styled <TextField /> replacement
 *
 * Add class names to TextField parts
 * Sets error or helperText
 * Passes down all rest props
 */
const ComposedTextField = ({
  error,
  helperText,
  touched,
  InputProps,
  value,
  variant,
  onChange,
  uppercase,
  capitalize,
  onlyNumbers,
  trim,
  censuredHelper,
  fieldState,
  ...restProps
}) => {
  const classes = useStyles()

  const { focused, onFocus, onBlur } = useFocusHandler(restProps)
  const { hasError, isValid, isDisabled } = useFieldState({
    value,
    error,
    focused,
    touched,
    fieldState,
    ...restProps,
  })

  const onInputChange = e => {
    const { target } = e
    const shouldTransformValue =
      typeof target.value === 'string' && (onlyNumbers || trim || uppercase || capitalize)

    if (target.value && shouldTransformValue) {
      if (trim) {
        target.value = target.value.trim()
      }

      if (onlyNumbers) {
        target.value = removeLetters(target.value)
      } else if (uppercase) {
        target.value = target.value.toUpperCase()
      } else if (capitalize) {
        target.value = capitalizeText(target.value)
      }
    }

    if (onChange) {
      onChange(e)
    }
  }

  return (
    <TextField
      InputProps={{
        classes: {
          root: `${classes.cssOutlinedInput} ${isValid ? classes['valid-outlined-input'] : ''}`,
          focused: classes.cssFocused,
          notchedOutline: classes.notchedOutline,
          error: classes.cssError,
        },
        ...InputProps,
        ...(censuredHelper ? { inputComponent: getCensuredComponent(censuredHelper) } : {}),
      }}
      InputLabelProps={{
        classes: {
          root: `${classes.cssLabel} ${isValid ? classes['valid-outlined-label'] : ''}`,
        },
      }}
      SelectProps={{ className: classes.cssSelect }}
      error={hasError}
      disabled={isDisabled}
      helperText={getHelperText({ focused, error, touched, helperText })}
      value={value || ''}
      variant={variant || 'outlined'}
      {...restProps}
      onFocus={onFocus}
      onBlur={onBlur}
      onChange={onInputChange}>
      {restProps.options &&
        restProps.options.map((elem, i) => (
          <MenuItem
            key={`menu-item-${elem.value}${i + 1}`}
            data-hook={elem.label}
            value={elem.value}
            disabled={elem.disabled}>
            {elem.label}
          </MenuItem>
        ))}
    </TextField>
  )
}

ComposedTextField.propTypes = {
  ...TextField.propTypes,
  variant: PropTypes.string,
  fieldState: PropTypes.oneOf(Object.values(fieldStates)),
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  censuredHelper: PropTypes.func,
}

ComposedTextField.defaultProps = {
  variant: 'outlined',
}

export default ComposedTextField
