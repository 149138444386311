import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { APP_CONFIG } from 'services/app-config'
import { SpinnerCenter } from 'components/ui'
import { addOwnStyles } from './Appointment.helper'
import './styles.css'

const vendor = `${APP_CONFIG.cdnAppointments}/react/vendor.js?v=12571887`
const main = `${APP_CONFIG.cdnAppointments}/react/main.js?v=7974756`
const vendorCss = `${APP_CONFIG.cdnAppointments}/react/vendor.css`
const siteCss = `${APP_CONFIG.cdnAppointments}/react/site.css`

export function Appointment({ token, withoutCss, onAppointmentFinish, onAppointmentFailed }) {
  const [showAppointment, setShowAppointment] = useState(true)
  const [isLoading, setIsLoading] = useState(false)

  function loadResource(resource) {
    return new Promise((resolve, reject) => {
      let script = ''
      function appendResource() {
        script.addEventListener('load', () => {
          resolve()
        })
        script.addEventListener('error', e => {
          reject(e)
        })
        document.head.appendChild(script)
      }
      if (resource.includes('.css')) {
        script = document.createElement('link')
        script.setAttribute('rel', 'stylesheet')
        script.setAttribute('type', 'text/css')
        script.setAttribute('href', resource)
        script.setAttribute('cdn', 'appointment')
        appendResource(script)
        const head = document.getElementsByTagName('head')[0]
        head.insertBefore(script, head.childNodes[0])
      } else {
        script = document.createElement('script')
        script.src = resource
        appendResource(script)
      }
    })
  }

  function onAppointmentCheckFinished() {
    Array.prototype.forEach.call(document.querySelectorAll('link[cdn=appointment]'), element => {
      element.parentNode.removeChild(element)
    })
    const sheet = document.getElementById('appointments-style')
    if (sheet) {
      sheet.disabled = true
      sheet.parentNode.removeChild(sheet)
    }
    setShowAppointment(false)
  }

  function initializeAppointment() {
    const wrapper = document.getElementById('client-app-appointment')
    const messages = [
      'La cita ha sido creada correctamente',
      'La cita ha sido postpuesta correctamente',
      'La cita ha sido cancelada correctamente"',
      'La cita ha sido modificada correctamente',
      'La tarea de falta de capacidad ha sido correctamente creada',
    ]
    if (wrapper) {
      wrapper.innerHTML = ''
      const { MM } = window
      MM.default.clearState()

      const config = {
        token,
        idHtml: 'client-app-appointment',
      }
      MM.default.Citaciones.init(config, onAppointmentCheckFinished)
      setIsLoading(false)
      window.addEventListener(
        'message',
        e => {
          if (e.data.code === '200' && messages.includes(e.data.message)) {
            onAppointmentFinish()
          } else if (e.data.code === '500' || e.data.code === '400') {
            onAppointmentFailed(e.data.code)
          }
        },
        false,
      )
    }
  }

  async function loadResources() {
    // load vendor js first
    if (withoutCss) {
      addOwnStyles()
    }
    await loadResource(vendor)
    await Promise.all([main, vendorCss, siteCss].map(loadResource))
    initializeAppointment()
  }

  useEffect(() => {
    loadResources()
    return () => {
      onAppointmentCheckFinished()
    }
  }, [])

  return (
    <>
      {showAppointment && <div id="client-app-appointment" />}
      {isLoading && <SpinnerCenter />}
    </>
  )
}

Appointment.defaultProps = {
  onAppointmentFailed: () => {},
  withoutCss: false,
}

Appointment.propTypes = {
  onAppointmentFinish: PropTypes.func,
  onAppointmentFailed: PropTypes.func,
  withoutCss: PropTypes.bool,
  token: PropTypes.string.isRequired,
}
