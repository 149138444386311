import { put, select, take } from 'redux-saga/effects'

import {
  FIND_TARIFFS_SUCCESS,
  FIND_TARIFFS_ERROR,
  findTariffsAction,
} from './tariffs-apigee.actions'
import { selectTariffApigeeErrorMessage } from './tariffs-apigee.selectors'

export function* callTariffsSaga(filters) {
  yield put(findTariffsAction(filters))
  yield take(
    action =>
      (action.type === FIND_TARIFFS_SUCCESS || action.type === FIND_TARIFFS_ERROR) &&
      action.payload.filters === filters,
  )

  return yield select(selectTariffApigeeErrorMessage)
}
