export const vulaResponse = {
  gescal: '01000500021600015',
  coverage: [
    {
      ull: [],
      ftth: [
        '100M_10M',
        '200M_200M',
        '200M_20M',
        '300M_300M',
        '30M_30M',
        '320K_320K',
        '35M_5M',
        '50M_5M',
        '100M_100M',
        '300M_30M',
        '50M_50M',
        '1000M_300M',
        '600M_600M',
      ],
      characteristics: [
        {
          name: 'territoryOwner',
          value: 'FIBNBL01',
        },
        {
          name: 'speedEstimated',
          value:
            '100M_10M || 200M_200M || 200M_20M || 300M_300M || 30M_30M || 320K_320K || 35M_5M || 50M_5M || 100M_100M || 300M_30M || 50M_50M || 1000M_300M || 600M_600M',
        },
        {
          name: 'addressId',
          value: '',
        },
        {
          name: 'telefonicaCoverage',
          value: 'false',
        },
      ],
    },
  ],
  phoneNumber: 'undefined',
  province: 'ALAVA',
  town: 'Vitoria-Gasteiz',
  streetType: 'Calle',
  street: 'Barratxi Kalea',
  number: '15',
  bis_duplicate: '',
  zipCode: '01013',
  block: '',
  stair: '',
  door: '',
  letter: '',
  floor: '',
  Hand: '',
  Km: '',
  addressId: '',
  sessionId: '4efabc02-a187-4c0e-b84d-139552604c32',
}
