import * as Yup from 'yup/lib'
import { SIGNUP_TYPE_PORTABILITY, SIGNUP_TYPE_NEWLINE } from 'services/global-constants'
import * as validations from 'modules/SharedSales/constants/validations'
import { landlineRegex } from 'utils'

export const LineSchema = ({
  validation,
  is2p,
  isElFijo,
  disableOfferEdit,
  isMobileOnly,
  is2pPure,
}) => ({
  signUpType: disableOfferEdit
    ? Yup.string()
    : Yup.string().required(validations.REQUIRED_FIELD_TEXT),
  newNumber:
    is2pPure
      ? Yup.string()
      : Yup.object().when('signUpType', {
          is: signUpType => signUpType === SIGNUP_TYPE_NEWLINE || isElFijo || is2p,
          then: Yup.object({
            value: Yup.string()
              .matches(validation, validations.ERROR_DATA_TEXT)
              .required(validations.REQUIRED_FIELD_TEXT),
          }),
          otherwise: Yup.object({
            value: Yup.string().nullable(),
          }).nullable(),
        }),
  lineNumber:
    isMobileOnly && !disableOfferEdit
      ? Yup.string()
      : Yup.string().when('signUpType', {
          is: SIGNUP_TYPE_PORTABILITY,
          then: Yup.string()
            .trim()
            /* eslint-disable-next-line */
            .test('ValidatePortaNumber', validations.SAME_PORTABILITY, function(value) {
              const numberRepetitions = this.options.context.portaNumbers.filter(
                number => number === value,
              ).length
              return numberRepetitions < 2
            })
            .matches(isElFijo || is2p ? landlineRegex : validation, validations.ERROR_DATA_TEXT)
            .required(validations.REQUIRED_FIELD_TEXT),
          otherwise: Yup.string(),
        }),
  operator: Yup.object().when('signUpType', {
    is: signUpType =>
      signUpType === SIGNUP_TYPE_PORTABILITY &&
      (!isMobileOnly || (isMobileOnly && disableOfferEdit)),
    then: Yup.object({
      id: Yup.string().required(),
      tradingName: Yup.string().required(),
    }),
    otherwise: Yup.object({
      id: Yup.string(),
      tradingName: Yup.string(),
    }),
  }),
  SVA: Yup.object({
    psId: Yup.string(),
    email: Yup.string().when('psId', {
      is: psId => !!psId,
      then: Yup.string().required(validations.REQUIRED_FIELD_TEXT),
      otherwise: Yup.string(),
    }),
  }),
})
