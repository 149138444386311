import { get } from 'lodash'
import {
  FIND_CONVERGENT_TARIFFS,
  FIND_CONVERGENT_TARIFFS_SUCCESS,
  FIND_CONVERGENT_TARIFFS_ERROR,
  FIND_CONVERGENT_TARIFFS_IDS,
  FIND_CONVERGENT_TARIFFS_IDS_SUCCESS,
  FIND_CONVERGENT_TARIFFS_IDS_ERROR,
  FIND_TARIFFS,
  FIND_TARIFFS_SUCCESS,
  FIND_TARIFFS_ERROR,
  RESET_TARIFFS,
  FIND_TARIFF_BY_ID_SUCCESS,
  FIND_TARIFF_BY_ID_ERROR,
  SET_TERMINAL_MODEL,
  SET_SELECTED_TARIFF,
} from './tariffs-apigee.actions'
import { getUniqueKey } from './tariffs-apigee.helpers'

export const initialState = {
  loading: null,
  data: { convergent: [], convergentIds: [], tariffs: {}, filters: {} },
  selectedTariffs: {},
  selectedTerminals: {},
}

export const reducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case FIND_CONVERGENT_TARIFFS: {
      return {
        ...state,
        error_code: null,
        error_message: null,
        loading: true,
      }
    }
    case FIND_CONVERGENT_TARIFFS_SUCCESS: {
      return {
        ...state,
        error_code: null,
        error_message: null,
        data: { ...state.data, convergent: payload.data },
        loading: false,
      }
    }
    case FIND_CONVERGENT_TARIFFS_ERROR: {
      return {
        ...state,
        error_code: payload.httpCode,
        error_message: payload.info,
        loading: false,
        data: { ...state.data, convergent: [] },
      }
    }
    case FIND_CONVERGENT_TARIFFS_IDS: {
      return {
        ...state,
        error_code: null,
        error_message: null,
        loading: true,
      }
    }
    case FIND_CONVERGENT_TARIFFS_IDS_SUCCESS: {
      return {
        ...state,
        error_code: null,
        error_message: null,
        data: { ...state.data, convergentIds: payload.data },
        loading: false,
      }
    }
    case FIND_CONVERGENT_TARIFFS_IDS_ERROR: {
      return {
        ...state,
        error_code: payload.httpCode,
        error_message: payload.info,
        loading: false,
        data: { ...state.data, convergentIds: [] },
      }
    }
    case FIND_TARIFFS: {
      return {
        ...state,
        error_code: null,
        error_message: null,
        loading: true,
      }
    }
    case FIND_TARIFFS_SUCCESS: {
      const { data, filters } = payload
      const newTariffs = data.reduce((acc, tariff) => {
        acc[tariff.id] = tariff
        return acc
      }, {})
      const filtersKey = getUniqueKey(filters)
      const tariffIds = data.map(t => t.id)
      return {
        ...state,
        error_code: null,
        error_message: null,
        loading: false,
        data: {
          ...state.data,
          tariffs: { ...state.data.tariffs, ...newTariffs },
          filters: { ...state.data.filters, [filtersKey]: [...tariffIds] },
        },
      }
    }
    case FIND_TARIFFS_ERROR: {
      return {
        ...state,
        error_code: payload.error.httpCode,
        error_message: payload.error.info,
        loading: false,
        data: { ...state.data },
      }
    }
    case RESET_TARIFFS: {
      return {
        ...state,
        data: {
          ...state.data,
          tariffs: {},
        },
      }
    }
    case FIND_TARIFF_BY_ID_SUCCESS: {
      const { data } = payload
      const tariff = get(data, '[0]')
      return {
        ...state,
        error_code: null,
        error_message: null,
        loading: false,
        data: {
          ...state.data,
          tariffById: { ...state.data.tariff, [tariff.id]: tariff },
        },
      }
    }
    case FIND_TARIFF_BY_ID_ERROR: {
      return {
        ...state,
        error_code: payload.httpCode,
        error_message: payload.info,
        loading: false,
        data: { ...state.data },
      }
    }
    case SET_TERMINAL_MODEL: {
      return {
        ...state,
        selectedTerminals: { ...state.selectedTerminals, [payload.lineType]: payload.model },
      }
    }

    case SET_SELECTED_TARIFF: {
      return {
        ...state,
        selectedTariffs: {
          ...state.selectedTariffs,
          [payload.lineType]: payload.tariffId,
        },
      }
    }
    default:
      return state
  }
}
