import * as Yup from 'yup/lib'
import * as validations from 'modules/SharedSales/constants/validations'
import { ONLY_MOBILE, CONVERGENT, ONLY_FIXED, FIXED_MOBILE } from 'modules/SharedSales/constants'

import { OfferTypeSchema } from './OfferType.validation'
import { LeadSchema } from './Lead.validation'
import { OnlyMobileSchema } from './OnlyMobile.validation'
import { ServiceAddressSchema } from './ServiceAddress.validation'
import { ExtraMobileSchema } from './MobileLineWithTariff.validation'

export const ClientInfoSchema = (saleType, isSubscription, isMultiSim, isCrossSell) => {
  let validation = {}
  switch (saleType) {
    case CONVERGENT:
    case ONLY_FIXED:
      validation = { ...OfferTypeSchema }
      break
    case ONLY_MOBILE:
      validation = { ...OnlyMobileSchema }
      break
    case FIXED_MOBILE:
      validation = { ...OnlyMobileSchema, ...ServiceAddressSchema }
      break
    default:
      break
  }

  if (isSubscription || isMultiSim) {
    validation = {
      ...ExtraMobileSchema(),
      contractId: Yup.string()
        .trim()
        .required(validations.REQUIRED_FIELD_TEXT),
    }
  }

  if (isCrossSell) {
    validation = { ...OfferTypeSchema }
  }

  return {
    ...validation,
    ...LeadSchema,
  }
}
