import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { KeyboardDatePicker } from '@material-ui/pickers'

export const FormDatePicker = ({
  label,
  className,
  onChange,
  name,
  maxDate,
  minDate,
  disabled,
  value,
  error,
  ...rest
}) => {
  const [selectedDate, setSelectedDate] = useState(value)

  useEffect(() => {
    setSelectedDate(value)
  }, [value])

  return (
    <KeyboardDatePicker
      className={className}
      autoOk
      disableToolbar
      name={name}
      variant="inline"
      inputVariant="outlined"
      format="dd/MM/yyyy"
      label={label}
      value={selectedDate}
      onChange={date => {
        setSelectedDate(date)
        if (onChange) onChange(date)
      }}
      maxDate={maxDate}
      minDate={minDate}
      disabled={disabled}
      error={error}
      {...rest}
    />
  )
}

FormDatePicker.propTypes = {
  className: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onChange: PropTypes.func,
  name: PropTypes.string,
  maxDate: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  minDate: PropTypes.object,
  disabled: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
}
