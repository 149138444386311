import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { get, isEmpty, isNil } from 'lodash'
import { Divider, Box, Grid, CircularProgress } from '@material-ui/core'
import { AlertAdvice } from 'components/ui'
import PropTypes from 'prop-types'

import { formatNumber } from 'utils'
import {
  selectRejectionReason,
  selectWarnings,
  selectWarningPermanency,
  selectDecisionAllowed,
  selectOrderElegibilityisKo,
  selectOrderConsequencesIsKo,
  shouldWarnAdditionalLines,
  is25to30STC,
  shouldWarnLowerValueTariffChange,
  isLoadingOrderElegibility,
  isLoadingOrderConsequences,
} from 'modules/offers-configuration/selectors'
import { selectors } from 'services/customer-360/selectors'
import { getPermanenciesMessage, getActivationDate } from 'modules/offers-configuration/helpers'
import {
  fetchOrderResetElegibility,
  fetchOrderConsequencesReset,
} from 'modules/offers-configuration/actions'
import {
  getMsisdn,
  isConvergentSubscription,
  getFeesPendingFromPermanent,
  getSubscriptionContentDuoPrincipal,
  duoPrincipalContentOldTariff,
  findMobilePermanent,
} from 'services/subscriptions'
import { PRICE_DUO_PRINCIPAL } from 'modules/offers-configuration/constants'
import { isDuoTariffId } from 'modules/tariffs/store-apigee/tariffs-apigee.helpers'
import { applyTax, getTax } from 'services/taxes'

export const TariffWarnings = ({ date, subscription, selectedPsIdTariff, taxNeeded }) => {
  const dispatch = useDispatch()
  const decisionAllowed = useSelector(selectDecisionAllowed)
  const decisionDetails = useSelector(selectRejectionReason)
  const warnings = useSelector(selectWarnings)
  const warningPermanency = useSelector(selectWarningPermanency(getActivationDate(date)))
  const orderEligibilitiesKo = useSelector(selectOrderElegibilityisKo)
  const orderConsequencesKo = useSelector(selectOrderConsequencesIsKo)
  const showAttentionWarning = decisionAllowed && orderConsequencesKo

  const showAdditionalLinesDiscountWarning = useSelector(state =>
    selectedPsIdTariff
      ? shouldWarnAdditionalLines(state, {
          msisdn: getMsisdn(subscription),
          newTariffId: selectedPsIdTariff,
        })
      : false,
  )
  const showConvergentTariffDiscountWarning = selectedPsIdTariff
    ? isConvergentSubscription(subscription)
    : false
  const showAlertTariffDUOWarning = useSelector(
    state =>
      getSubscriptionContentDuoPrincipal(state) &&
      duoPrincipalContentOldTariff(state) &&
      (selectedPsIdTariff ? isConvergentSubscription(subscription) : false),
  )

  const showDUOTariffDiscountWarning = selectedPsIdTariff
    ? isDuoTariffId(selectedPsIdTariff)
    : false
  const showLowerValueTariffChangeWarning = useSelector(state =>
    shouldWarnLowerValueTariffChange(state, subscription, selectedPsIdTariff),
  )
  const STC25to30 = useSelector(state => is25to30STC(state, subscription, selectedPsIdTariff))
  const mobilePermanent = findMobilePermanent(subscription)
  const penaltyAmount = formatNumber(getFeesPendingFromPermanent(mobilePermanent))
  const billingAddress = useSelector(selectors.getBillingAddress)
  const postCode = get(billingAddress, 'postCode')
  const tax = useSelector(state => getTax(state, postCode))
  const newTariffDuo = formatNumber(
    (taxNeeded && applyTax(tax, PRICE_DUO_PRINCIPAL)) || PRICE_DUO_PRINCIPAL,
  )
  const loadingOrderElegibility = useSelector(isLoadingOrderElegibility)
  const loadingOrderConsequences = useSelector(isLoadingOrderConsequences)
  const sectionIsloading = loadingOrderElegibility || loadingOrderConsequences

  useEffect(() => {
    return () => {
      if (!showAttentionWarning) {
        dispatch(fetchOrderResetElegibility())
        dispatch(fetchOrderConsequencesReset())
      }
    }
  }, [showAttentionWarning])

  return (
    <>
      {sectionIsloading ? (
        <>
          <Grid container justify="center" alignItems="center" xs={12}>
            <Grid className="content-grid" item style={{ marginTop: '20px' }}>
              <CircularProgress size="60px" color="secondary" />
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          {!isNil(warnings) && !isEmpty(warnings) && (
            <>
              <Box my="20px">
                <Divider />
              </Box>
              {warnings.map((item, index) => {
                return (
                  <AlertAdvice
                    type="warning"
                    dataHook={`alertAdvice-warnings-${index + 1}`}
                    key={`alertAdvice-warnings-${index + 1}`}
                    message={item.detail}
                  />
                )
              })}
            </>
          )}

          {!isNil(warningPermanency) && !isEmpty(warningPermanency) && (
            <>
              <Box my="20px">
                <Divider />
              </Box>
              {warningPermanency.map((item, index) => {
                return (
                  <AlertAdvice
                    dataHook={`alertAdvice-permanencies-${index + 1}`}
                    key={`alertAdvice-permanencies-${index + 1}`}
                    message={getPermanenciesMessage(item.permanency, item.penal)}
                  />
                )
              })}
            </>
          )}
          {showAttentionWarning && showAdditionalLinesDiscountWarning && (
            <>
              <Box my="24px">
                <Divider />
              </Box>
              <AlertAdvice message="ATENCIÓN, informa al cliente que perderá el descuento asociado a las líneas adicionales." />
            </>
          )}

          {showAttentionWarning && showConvergentTariffDiscountWarning && (
            <>
              <Box my="24px">
                <Divider />
              </Box>
              <AlertAdvice message="ATENCIÓN, informa al cliente que perderá todos los descuentos que no sean compatibles con la nueva tarifa convergente." />
            </>
          )}

          {showAttentionWarning && showAlertTariffDUOWarning && (
            <>
              <Box my="24px">
                <Divider />
              </Box>
              <AlertAdvice
                message={`El precio de tu linea Dúo costará ${newTariffDuo}€ impuestos ${
                  !taxNeeded ? 'no' : ''
                } incluidos.`}
              />
            </>
          )}

          {showAttentionWarning && showDUOTariffDiscountWarning && (
            <>
              <Box my="24px">
                <Divider />
              </Box>
              <AlertAdvice message="ATENCIÓN, informa al cliente que perderá todos los descuentos que no sean compatibles con la nueva tarifa DUO." />
            </>
          )}

          {showAttentionWarning && showLowerValueTariffChangeWarning && !STC25to30 && (
            <>
              <Box my="24px">
                <Divider />
              </Box>
              <AlertAdvice
                message={`ATENCIÓN, este cambio de Tarifa implica el cobro de la penalización por el terminal adquirido. Importe: ${penaltyAmount}€.`}
              />
            </>
          )}
          {!decisionAllowed && !isEmpty(decisionDetails) && (
            <>
              <Box my="24px">
                <Divider />
              </Box>
              <AlertAdvice
                dataHook="alert-orderElegibilities-ko"
                message={`ATENCIÓN, no se puede realizar el cambio hacia la tarifa seleccionada. Detalle del Error: ${decisionDetails}`}
              />
            </>
          )}
          {orderEligibilitiesKo && (
            <>
              <Box my="24px">
                <Divider />
              </Box>
              <AlertAdvice
                dataHook="alert-orderElegibilities-error"
                message="No se ha podido consultar la elegibilidad del STC. Por favor, vuelva a intentarlo pasado unos instantes."
              />
            </>
          )}
        </>
      )}
    </>
  )
}

TariffWarnings.propTypes = {
  date: PropTypes.number,
  subscription: PropTypes.object,
  selectedPsIdTariff: PropTypes.string,
  taxNeeded: PropTypes.bool,
}
