import { useState, useEffect, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { Typography } from '@material-ui/core'

import { NotificationModal, Button, ModalActions } from 'components/ui'
import {
  fetchDeviceRenewalAllowed,
  resetDeviceRenewalAllowed,
} from 'modules/device-renewal/store/device-renewal.actions'

import { ADDITIONAL_LINE } from 'modules/SharedSales/constants/productCategories'

import { renewalValidationMessagesError } from 'modules/device-renewal/constants/validation-errors'
import { selectDeviceRenewalModal } from './DeviceRenewalModal.selectors'

export function DeviceRenewalModal({ shouldCheckAllowed, subscription, onSuccess, onComplete }) {
  const dispatch = useDispatch()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const {
    channel,
    iban,
    isRenewalAllowed,
    isRenewalTvAllowed,
    isRenewalAllowedLoading,
    rejectionReasonCode,
  } = useSelector(selectDeviceRenewalModal)

  const isAdditionalLine = useMemo(() => subscription.lineType === ADDITIONAL_LINE, [subscription])

  const errorMessage = useMemo(() => {
    if (!renewalValidationMessagesError) {
      return null
    }

    return (
      renewalValidationMessagesError[rejectionReasonCode] || renewalValidationMessagesError.general
    )
  }, [renewalValidationMessagesError, rejectionReasonCode])

  const resetRenewalValidation = () => {
    dispatch(resetDeviceRenewalAllowed())
  }

  const checkAllowed = useCallback(() => {
    const fixedLine = subscription.productsSpecifications.find(line => line.name === 'fixed')
    const phoneNumber = fixedLine?.id

    dispatch(
      fetchDeviceRenewalAllowed(
        channel,
        iban,
        subscription.id,
        subscription.subscription_id,
        phoneNumber,
      ),
    )
  }, [subscription])

  const onValidationIsFinished = isSuccess => {
    setIsModalOpen(false)
    onComplete()

    resetRenewalValidation()
    if (isSuccess) {
      onSuccess()
    }
  }

  useEffect(() => {
    if (shouldCheckAllowed && isRenewalAllowed === null) {
      checkAllowed()
    }
  }, [shouldCheckAllowed, isRenewalAllowed])

  useEffect(() => {
    if (isRenewalAllowedLoading === false && !!shouldCheckAllowed) {
      if ((isRenewalAllowed || isRenewalTvAllowed) && !isAdditionalLine) {
        onValidationIsFinished(true)
      } else {
        setIsModalOpen(true)
      }
    }
  }, [isRenewalAllowedLoading, shouldCheckAllowed, isRenewalAllowed, isRenewalTvAllowed])

  return isAdditionalLine && (isRenewalAllowed || isRenewalTvAllowed) ? (
    <NotificationModal
      isOpen={isModalOpen}
      type="attention"
      src="/assets/alert_bubbles.svg"
      onClose={() => onValidationIsFinished(false)}>
      <Typography variant="h5" style={{ fontWeight: 400 }}>
        {renewalValidationMessagesError.is_additional_line}
      </Typography>
      <ModalActions>
        <Button
          onClick={() => onValidationIsFinished(true)}
          data-hook="sale-withclient-confirm-button"
          className="card-link">
          Aceptar y continuar
        </Button>
        <Button secondary onClick={() => onValidationIsFinished(false)}>
          Cancelar venta
        </Button>
      </ModalActions>
    </NotificationModal>
  ) : (
    <NotificationModal
      isOpen={isModalOpen}
      type="alert"
      src="/assets/error.svg"
      onClose={() => onValidationIsFinished(false)}>
      <Typography variant="h5" style={{ fontWeight: 400 }}>
        {errorMessage}
      </Typography>
    </NotificationModal>
  )
}

DeviceRenewalModal.propTypes = {
  shouldCheckAllowed: PropTypes.bool,
  subscription: PropTypes.object,
  onSuccess: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
}
