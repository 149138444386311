import { useSelector } from 'react-redux'
import { defaultTo, get } from 'lodash'
import { findMobileFinancials } from 'services/subscriptions'

import { selectTariffsApigeeDataTariffs } from 'modules/tariffs/store-apigee'
import { isProCustomer } from 'services/customer-360/helpers'
import { getSegment } from 'services/customer-360/selectors'
import { getCurrentCustomerTax, getSubscriptionsState } from '../../selectors'
import { generateRawFeeSummaryForSubscription } from '../../hooks/useFeeSummary'

import { FeesItem } from '../../components/FeesItem'

export function getModifiedFeesProps(props) {
  const modifications = useSelector(getSubscriptionsState)
  const customerSegment = useSelector(getSegment)
  const taxNeeded = !isProCustomer(customerSegment)
  const tax = (taxNeeded && useSelector(getCurrentCustomerTax)) || 0
  const tariffs = useSelector(selectTariffsApigeeDataTariffs)

  const rawFeeSummary = useSelector(state =>
    generateRawFeeSummaryForSubscription(state, {
      subscription: props.subscription,
      modifications,
      tax,
      taxNeeded,
      tariffs,
    }),
  )

  const terminalsFinancials = findMobileFinancials(props.subscription)
  const terminalsLastPayment = defaultTo(get(terminalsFinancials, 'fees.residual'), 0)
  const endDatePayment = get(terminalsFinancials, 'validFor.endDateTime')

  return {
    ...props,
    ...rawFeeSummary,
    terminalsFinancials,
    terminalsLastPayment,
    endDatePayment,
    isModified: true,
  }
}

export function ModifiedFeesContainer(props) {
  const newProps = getModifiedFeesProps(props)

  return (
    <div className="p-x-12">
      <FeesItem {...newProps} />
    </div>
  )
}
