import { get } from 'lodash'
import { createSelector } from 'reselect'
import { checkForMsisdn } from './helpers'

const getOrderCTOnFly = state => get(state, 'checkOrderCTOnFly.data.datos', [])

const getOrderCTOnFlyLoading = state => get(state, 'checkOrderCTOnFly.loading')

const checkOrderCTOnFly = msisdn =>
  createSelector(
    [getOrderCTOnFly],
    orders => checkForMsisdn(orders, msisdn),
  )
export const selectors = { getOrderCTOnFly, getOrderCTOnFlyLoading, checkOrderCTOnFly }
