export const checksErrors = checks => {
  const result = checks.find(
    check =>
      check === 'validCoverage' ||
      check === 'scoring' ||
      check === 'rove' ||
      check === 'ponr' ||
      check === 'maxHours' ||
      check === 'getAppointment' ||
      check === 'getTariffsRated',
  )
  switch (result) {
    case 'validCoverage': {
      return 'La nueva dirección no dispone de la misma tecnología que la dirección actual. La Orden se cancelará automáticamente. Debes volver a cargar un nuevo pedido con la dirección correcta.'
    }
    case 'scoring':
    case 'rove': {
      return 'Servicio no disponible para este cliente.'
    }
    case 'ponr': {
      return 'Alcanzado el punto de no retorno.'
    }
    case 'maxHours': {
      return 'No se puede modificar la dirección, faltan más de 24h para la cita.'
    }
    case 'getAppointment': {
      return 'Error: no se puede organizar una cita.'
    }
    case 'getTariffsRated': {
      return 'Ha fallado el servicio TARIFF.'
    }
    default:
      return false
  }
}
