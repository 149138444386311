import { get } from 'lodash'
import { createSelector } from 'reselect'

const territCompatData = state => get(state, 'territCompat.data')

const territCompatLoading = state => get(state, 'territCompat.loading')

export const territCompat = state => get(state, 'territCompat')

const territCompatError = state => {
  const error_code = get(state, 'territCompat.error_code', null)
  const error_message = get(state, 'territCompat.error_message', null)
  if (error_code && error_message) {
    return true
  }
  return false
}

const getTerritCompat = createSelector(
  [territCompat],
  territCompat,
)

export const selectors = {
  territCompatData,
  territCompatLoading,
  territCompatError,
  getTerritCompat,
  territCompat,
}
