import { css } from 'styled-components'
import { get } from 'lodash'

const colors = {
  gray_0: '#4d4e4f',
  blue: '#3a8ff0',
  light_2: '#656f7a',
}

const baseCss = css`
  font-family: Roboto;
  line-height: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  strong {
    font-weight: 500;
  }
`

const TextStyleCss = {
  // Old Styles
  action: css`
    color: ${colors.gray_0};
    font-size: 12px;
    font-weight: 700;
  `,

  body: css`
    color: ${colors.gray_0};
    font-size: 14px;
    font-weight: 400;
  `,

  header: css`
    color: ${colors.gray_0};
    font-size: 20px;
    font-weight: 400;
  `,

  header_link: css`
    color: ${colors.blue};
    font-size: 20px;
    font-weight: 400;
  `,

  header_contrast: css`
    color: ${props => get(props, 'theme.palette.brand.light')};
    font-size: 20px;
    font-weight: 400;
  `,

  sub_m: css`
    ${baseCss}
    color: ${props => get(props, 'theme.palette.global.gray_dark')};
    font-size: 12px;
    font-weight: 500;
  `,

  // From the Style Guide

  page_header_dark: css`
    ${baseCss}
    color: ${props => get(props, 'theme.palette.global.gray_dark')};
    font-size: 28px;
    font-weight: 300;
  `,

  page_header_light: css`
    ${baseCss}
    color: ${props => get(props, 'theme.palette.brand.light')};
    font-size: 28px;
    font-weight: 300;
  `,

  title_1_dark: css`
    ${baseCss}
    color: ${props => get(props, 'theme.palette.global.gray_dark')};
    font-size: 24px;
    line-height: 1.17;
  `,

  title_1_bold_dark: css`
    ${baseCss}
    color: ${props => get(props, 'theme.palette.global.gray_dark')};
    font-size: 24px;
    font-weight: bold;
    line-height: 1.17;
  `,

  title_1_link: css`
    ${baseCss}
    color: ${props => get(props, 'theme.palette.brand.action_primary')};
    font-size: 24px;
    line-height: 1.17;
  `,

  title_1_light: css`
    ${baseCss}
    color: ${props => get(props, 'theme.palette.brand.light')};
    font-size: 24px;
    line-height: 1.17;
  `,

  title_2_dark: css`
    ${baseCss}
    color: ${props => get(props, 'theme.palette.global.gray_dark')};
    font-size: 20px;
    line-height: 1.2;
  `,

  title_2_bold_dark: css`
    ${baseCss}
    color: ${props => get(props, 'theme.palette.global.gray_dark')};
    font-size: 20px;
    font-weight: bold;
    line-height: 1.2;
  `,

  title_2_medium_bold_dark: css`
  ${baseCss}
  color: ${props => get(props, 'theme.palette.global.gray_dark')};
  font-size: 18px;
  font-weight: bold;
  line-height: 1.2;
`,

  title_2_medium_dark: css`
  ${baseCss}
  color: ${props => get(props, 'theme.palette.global.gray_dark')};
  font-size: 18px;
  line-height: 1.2;
  font-weight: 500;
`,

  title_2_link: css`
    ${baseCss};
    color: ${props => get(props, 'theme.palette.brand.action_primary')};
    font-size: 20px;
    line-height: 1.2;
  `,

  title_2_light: css`
    ${baseCss}
    color: ${props => get(props, 'theme.palette.brand.light')};
    font-size: 20px;
    line-height: 1.2;
  `,

  title_3_dark: css`
  ${baseCss}
    color: ${props => get(props, 'theme.palette.global.gray_dark')};
    font-size: 20px;
    line-height: 1.2;
    font-weight: bold;
  `,

  subtitle_1_dark: css`
    ${baseCss}
    text-transform: uppercase;
    color: ${props => get(props, 'theme.palette.global.gray_dark')};
    font-size: 16px;
    line-height: 1.5;
    font-weight: bold;
  `,

  subtitle_1_link: css`
    ${baseCss}
    text-transform: uppercase;
    color: ${props => get(props, 'theme.palette.brand.action_primary')};
    font-size: 16px;
    line-height: 1.5;
    font-weight: bold;
  `,

  subtitle_1_light: css`
    ${baseCss}
    text-transform: uppercase;
    color: ${props => get(props, 'theme.palette.brand.light')};
    font-size: 16px;
    line-height: 1.5;
    font-weight: bold;
  `,

  subtitle_1_secondary: css`
    ${baseCss}
    text-transform: uppercase;
    color: ${props => get(props, 'theme.palette.brand.medium')};
    font-size: 16px;
    line-height: 1.5;
    font-weight: bold;
  `,

  subtitle_1_light_14: css`
    ${baseCss}
    text-transform: uppercase;
    color: ${props => get(props, 'theme.palette.brand.light')};
    font-size: 14px;
    line-height: 1.5;
    font-weight: bold;
  `,

  subtitle_2_dark: css`
    ${baseCss}
    color: ${props => get(props, 'theme.palette.global.gray_dark')};
    font-size: 16px;
    line-height: 1.5;
    font-weight: bold;
  `,

  subtitle_2_link: css`
    ${baseCss}
    color: ${props => get(props, 'theme.palette.brand.action_primary')};
    font-size: 16px;
    line-height: 1.5;
    font-weight: bold;
  `,

  subtitle_2_light: css`
    ${baseCss}
    color: ${props => get(props, 'theme.palette.brand.light')};
    font-size: 16px;
    line-height: 1.5;
    font-weight: bold;
  `,

  paragraph_dark: css`
    ${baseCss}
    color: ${props => get(props, 'theme.palette.global.gray_dark')};
    font-size: 16px;
    line-height: 1.5;
  `,

  paragraph_link: css`
    ${baseCss}
    color: ${props => get(props, 'theme.palette.brand.action_primary')};
    font-size: 16px;
    line-height: 1.5;
  `,

  paragraph_light: css`
    ${baseCss}
    color: ${props => get(props, 'theme.palette.brand.light')};
    font-size: 16px;
    line-height: 1.5;
  `,

  label_dark: css`
    ${baseCss}
    color: ${props => get(props, 'theme.palette.global.gray_dark')};
    font-size: 14px;
    line-height: 1.14;
  `,

  label_dark_bold: css`
    ${baseCss}
    color: ${props => get(props, 'theme.palette.global.gray_dark')};
    font-size: 14px;
    line-height: 1.14;
    font-weight: 500;
`,

  label_link: css`
    ${baseCss}
    color: ${props => get(props, 'theme.palette.brand.action_primary')};
    font-size: 14px;
    font-weight: 500;
    line-height: 1.14;
    text-decoration: underline;
  `,

  label_light: css`
    ${baseCss}
    color: ${props => get(props, 'theme.palette.brand.light')};
    font-size: 14px;
    line-height: 1.7;
  `,

  label_light_2: css`
    ${baseCss}
    color: ${colors.light_2};
    font-size: 14px;
    line-height: 1.14;
  `,

  label_alert: css`
    ${baseCss}
    color: ${props => get(props, 'theme.palette.brand.alert')};
    font-size: 14px;
    line-height: 1.7;
  `,

  data_dark: css`
    ${baseCss}
    color: ${props => get(props, 'theme.palette.global.gray_dark')};
    font-size: 16px;
    line-height: 1;
    font-weight: 500;
  `,

  data_link: css`
    ${baseCss}
    color: ${props => get(props, 'theme.palette.brand.action_primary')};
    font-size: 16px;
    line-height: 1;
    font-weight: 500;
  `,

  data_light: css`
    ${baseCss}
    color: ${props => get(props, 'theme.palette.brand.light')};
    font-size: 16px;
    line-height: 1;
    font-weight: 500;
  `,

  link: css`
    ${baseCss}
    color: ${props => get(props, 'theme.palette.brand.action_primary')};
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.4px;
  `,

  button_principal: css`
    ${baseCss}
    text-transform: uppercase;
    color: ${props => get(props, 'theme.palette.brand.action_primary')};
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.4px;
  `,

  button_secondary: css`
    ${baseCss}
    text-transform: uppercase;
    color: ${props => get(props, 'theme.palette.brand.action_secondary')};
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.4px;
  `,

  button_principal_center: css`
    ${baseCss}
    text-transform: uppercase;
    color: ${props => get(props, 'theme.palette.brand.action_primary')};
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.4px;
    text-align: center;
  `,

  button_secondary_center: css`
    ${baseCss}
    text-transform: uppercase;
    color: ${props => get(props, 'theme.palette.brand.action_secondary')};
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.4px;
    text-align: center;
  `,

  button_white_center: css`
    ${baseCss}
    text-transform: uppercase;
    color: ${props => get(props, 'theme.palette.brand.light')};
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.4px;
    text-align: center;
  `,

  tag_xl: css`
    ${baseCss}
    text-transform: uppercase;
    color: ${props => get(props, 'theme.palette.brand.light')};
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.4px;
    text-align: center;
  `,

  tag_m: css`
    ${baseCss}
    text-transform: uppercase;
    color: ${props => get(props, 'theme.palette.brand.light')};
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.5px;
    text-align: center;
  `,

  tag_xs: css`
    ${baseCss}
    text-transform: uppercase;
    color: ${props => get(props, 'theme.palette.brand.light')};
    font-size: 10px;
    font-weight: bold;
    letter-spacing: 0.4px;
    text-align: center;
  `,
}

export default TextStyleCss
