import { constants } from './constants'

const initialState = {
  error: null,
  loading: false,
}

export const reducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case constants.FIND: {
      return { ...initialState, loading: true }
    }
    case constants.FIND_SUCCESS: {
      return { ...state, data: payload.data, loading: false }
    }
    case constants.FIND_FAILED: {
      return {
        ...state,
        error: payload.error,
        loading: false,
      }
    }
    case constants.RESET: {
      return {
        ...initialState,
      }
    }
    default:
      return state
  }
}
