import { axiosJWT } from 'modules/axios'

import { APP_CONFIG } from 'services/app-config'

const BASE_URL_NEW_SCORING = `${APP_CONFIG.sales_cluster}/v1/fraud-scoring`

export function fetchCreditScoring(data) {
  return axiosJWT({
    method: 'POST',
    url: `${BASE_URL_NEW_SCORING}/scoring`,
    data,
  })
}

export function fetchPrescoring(data) {
  return axiosJWT({
    method: 'POST',
    url: `${BASE_URL_NEW_SCORING}/prescoring`,
    data,
  })
}
