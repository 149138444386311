import PropTypes from 'prop-types'
import { Checkbox, FormControlLabel, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import { StyledPortabilityDatePicker as PortabilityDatePicker } from './PortabilityDatePicker.ui'

const useStyles = makeStyles({
  'postpone-portability-date': {
    '& .porta-input': {
      marginTop: '16px',
    },

    '& p > strong': {
      fontWeight: 500,
    },

    '& .postpone-description': {
      fontWeight: 300,
    },
  },
})

export const PostponePortabilityDate = ({
  fieldNames,
  isPortabilityDatePostponed,
  onCheckPortaDate,
  ...restProps
}) => {
  const classes = useStyles()

  return (
    <div className={classes['postpone-portability-date']}>
      <Grid container className="border-top p-y-16 full-width">
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              className="checkbox"
              checked={isPortabilityDatePostponed}
              onChange={e => onCheckPortaDate(e, fieldNames)}
              name={fieldNames.POSTPONE_PORTABILITY}
              id={fieldNames.POSTPONE_PORTABILITY}
              value={fieldNames.POSTPONE_PORTABILITY}
            />
          }
          label={
            <Typography variant="body1">
              Marca esta casilla <strong>si el cliente tiene permanencia </strong> y quieres
              posponer la fecha de portabilidad por defecto.
            </Typography>
          }
        />
        {isPortabilityDatePostponed && (
          <div className="m-t-4 m-l-32">
            <Typography variant="body1" className="postpone-description">
              Puedes escoger una fecha entre los 5 y 30 días posteriores a la venta.
              <strong>Ojo, siempre posterior al fin de permanencia </strong>
              para evitar penalizaciones. No se pueden realizar portabilidades en festivos o fines
              de semana.
            </Typography>
            <Typography variant="body1" className="postpone-description">
              Si el cliente necesitara añadir documentación adicional, no podemos garantizar que se
              mantenga la fecha elegida.
            </Typography>
            <div className="porta-input m-b-24">
              <PortabilityDatePicker fieldNames={fieldNames} {...restProps} />
            </div>
          </div>
        )}
      </Grid>
    </div>
  )
}

PostponePortabilityDate.propTypes = {
  className: PropTypes.string,
  fieldNames: PropTypes.object,
  isPortabilityDatePostponed: PropTypes.bool,
  onCheckPortaDate: PropTypes.func,
}
PostponePortabilityDate.defaultProps = {}
