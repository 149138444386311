import { call, all, select, put, takeLatest } from 'redux-saga/effects'
import { selectSfid } from 'modules/Auth'
import { selectProfileId } from 'modules/Permissions'
import { isEmpty } from 'lodash'
import * as discounts_agent_categoriesSvc from 'services/discounts-agent-categories'
import { callContentfulSaga } from 'modules/contentful'
import {
  actions as subscriptionsSvcActions,
  callSaga as subscriptionsSvcCallSaga,
} from 'services/subscriptions'
import { callTariffsSaga } from 'modules/tariffs/store-apigee/tariffs-apigee.call'
import { callBonusesSaga } from 'modules/bonuses'
import * as taxesSvc from 'services/taxes'
import * as customer360 from 'services/customer-360'
import * as offersDetailSvc from 'services/offers-detail'

import { getSfidSuffix } from 'modules/offers-configuration/helpers'

import { actions } from './actions'
import { constants } from './constants'

export function* initOffersConfiguratorSaga() {
  try {
    yield put(subscriptionsSvcActions.resetSubscriptions())
    let errorCode = null
    const sfid = yield select(selectSfid)
    const profileId = yield select(selectProfileId)
    const agentProfiles = !isEmpty(profileId) ? [profileId] : []
    const segment = yield select(customer360.selectors.getSegment)
    const orders = yield select(customer360.selectors.orders)
    const customerZipCode = yield select(customer360.selectors.getBillingAddressPostCode)

    errorCode = yield call(taxesSvc.callSaga, customerZipCode)
    if (errorCode) {
      return yield put(actions.loadFailed({ error: { code: errorCode } }))
    }

    const sfidSuffix = getSfidSuffix(sfid)

    errorCode = yield call(callContentfulSaga, 'sfidProfiles', { 'sfid[in]': sfidSuffix })
    if (errorCode) {
      return yield put(actions.loadFailed({ error: { code: errorCode } }))
    }

    if (!isEmpty(orders)) {
      const ordersId = customer360.helpers.getOrderIdByOrders(orders)
      yield put(offersDetailSvc.actions.findOrdersProductsByClient({ ordersId }))
    }

    const paralleCalls = yield all([
      call(subscriptionsSvcCallSaga),
      call(callTariffsSaga, { segment }),
      // Get retired tariffs because a subscription can have one retired
      call(callTariffsSaga, { status: 'retired,hidden', segment }),

      call(callBonusesSaga),

      call(
        discounts_agent_categoriesSvc.callSaga,
        agentProfiles,
        discounts_agent_categoriesSvc.constants.CONVERGENT_CATEGORY,
      ),
      call(
        discounts_agent_categoriesSvc.callSaga,
        agentProfiles,
        discounts_agent_categoriesSvc.constants.MOBILE_CATEGORY,
      ),
    ])

    errorCode = paralleCalls.find(ele => Boolean(ele))
    if (errorCode) {
      return yield put(actions.loadFailed({ error: { code: errorCode } }))
    }

    return yield put(actions.loadSucceeded())
  } catch (err) {
    return yield put(actions.loadFailed({ error: { code: -1 } }))
  }
}

export function* watchLoad() {
  yield takeLatest(constants.LOAD_INIT, initOffersConfiguratorSaga)
}
