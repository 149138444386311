const FETCH_MULTISIM_ALLOWED = 'OVID/MULTISIM/FETCH_MULTISIM_ALLOWED'
const FETCH_MULTISIM_ALLOWED_SUCCESS = 'OVID/MULTISIM/FETCH_MULTISIM_ALLOWED_SUCCESS'
const FETCH_MULTISIM_ALLOWED_FAILED = 'OVID/MULTISIM/FETCH_MULTISIM_ALLOWED_FAILED'
const RESET_MULTISIM_ALLOWED = 'OVID/MULTISIM/RESET_MULTISIM_ALLOWED'

export const constants = {
  FETCH_MULTISIM_ALLOWED,
  FETCH_MULTISIM_ALLOWED_SUCCESS,
  FETCH_MULTISIM_ALLOWED_FAILED,
  RESET_MULTISIM_ALLOWED,
}
