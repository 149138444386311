import { axiosJWT } from 'modules/axios'
import { CONFIG } from './environments'

export const getDiscountsIncompatibilities = (newDiscountCode, oldDiscountCodes) =>
  axiosJWT({
    method: 'GET',
    url: `${
      CONFIG.api.discountsIncompatibilities
    }?newDiscountCode=${newDiscountCode}&oldDiscountCodes=${oldDiscountCodes}`,
  }).then(resp => resp.data)
