import { selectContentfulResource } from 'modules/contentful'
import { selectResellerQueryParam, selectIsMobileOnly } from 'modules/Router/store/index'
import { createSelector, createStructuredSelector } from 'reselect'

import { selectIsElFijo } from 'modules/NewClientSales/store/selectors/index'
import { mapToProfessionOptions } from './TerminalSelectionContainer.helpers'
import { selectSalesTypes } from '../../../store'

const selectProfessions = selectContentfulResource('profession')

export const selectProfessionOptions = createSelector(
  selectProfessions,
  mapToProfessionOptions,
)

export const selectTerminalListSelectors = createStructuredSelector({
  isMobileOnly: selectIsMobileOnly,
  reseller: selectResellerQueryParam,
  isElFijo: selectIsElFijo,
  saleType: selectSalesTypes,
})
