import styled from 'styled-components'

const MainActions = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  padding: 32px 12px;
  justify-content: space-between;
  align-items: center;
`

export default MainActions
