import { constants } from './constants'

const initialState = {}

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case constants.GET_ROVE: {
      return {
        ...state,
        error: null,
        loading: true,
      }
    }
    case constants.GOT_ROVE: {
      return {
        ...state,
        rove: payload.rove,
        error: null,
        loading: false,
      }
    }
    case constants.GET_ROVE_FAILED: {
      return {
        ...state,
        rove: null,
        error: payload.error,
        loading: false,
      }
    }
    default:
      return state
  }
}
