import { put, takeLatest, take, select } from 'redux-saga/effects'
import {
  resetTariffsAction,
  findTariffByIdAction,
  findTariffsAction,
  FIND_TARIFF_BY_ID_SUCCESS,
  FIND_TARIFFS_SUCCESS,
  selectTariffApigeeOneTariff,
  categoriesTariff2P_3P,
} from 'modules/tariffs/store-apigee'
import { selectors as selectorsCustomer } from 'services/customer-new'
import { actions as actionsOffers } from 'services/offers'
import { getSubscriptions } from 'services/subscriptions'
import { selectQueryParam } from 'modules/Router'
import { findTypeByFixedNumber } from '../../helpers'

export const INIT_GET_TARIFFS_OFFERS_ACTION = 'MOVE_CLIENT_SUMMARY/TARIFFS/INIT'

export const initMoveClientSummarySagaAction = payload => ({
  type: INIT_GET_TARIFFS_OFFERS_ACTION,
  payload,
})

export function* initMoveClienSummarySaga(e) {
  const filters = e.payload
  yield put(resetTariffsAction())
  const products = yield select(getSubscriptions)

  const phoneNumber = yield selectQueryParam('fixedNumber')

  const idTariff = findTypeByFixedNumber(products, phoneNumber)
  yield put(findTariffByIdAction({ id: idTariff }))
  yield take(FIND_TARIFF_BY_ID_SUCCESS)
  const tariff = yield select(selectTariffApigeeOneTariff(idTariff))
  const category = categoriesTariff2P_3P(tariff)
  const segment = yield select(selectorsCustomer.getSegment)
  yield put(findTariffsAction(category ? { segment, category, ...filters } : filters))
  yield take(FIND_TARIFFS_SUCCESS)
  yield put(actionsOffers.findOffers(category ? { category, ...filters } : filters))
}

export function* watchInitMoveClientSummarySaga() {
  yield takeLatest(INIT_GET_TARIFFS_OFFERS_ACTION, initMoveClienSummarySaga)
}
