import { createGlobalStyle } from 'styled-components'

export const RouterAnimationNone = createGlobalStyle`
  &.animate-enter {
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
  }
`
