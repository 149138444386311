import { get, isEmpty } from 'lodash'
import { createSelector, createStructuredSelector } from 'reselect'

export const getProvisioningError = state => !isEmpty(get(state.provisioning, 'error_code'))
export const getProvisioningNumber = state => get(state.provisioning, 'data.number', '')
export const getProvisioningIsLoading = state => get(state.provisioning, 'loading')

const selectProvisioningNumber = createSelector(
  [getProvisioningError, getProvisioningNumber],
  (existErrorCode, number) => (existErrorCode ? 'Error en la consulta' : number),
)

export const getProvisioningData = createStructuredSelector({
  newNumber: selectProvisioningNumber,
  errorNewNumber: getProvisioningError,
  loadingNewNumber: getProvisioningIsLoading,
})
