import styled from 'styled-components'
import { AddressButtons } from './AddressButtons'

export const StyledAddressButtons = styled(AddressButtons)`
  && {
    justify-content: flex-start;
  }
  & > button {
    margin-right: 15px;
  }
`
