export const DEVICE_RENEWAL_FIELDNAMES = {
  SALE_CHANNEL: 'saleChannel',
  DEVICE_TYPE: 'deviceType',
  DEVICE: 'device',
  DEVICE_STOCK: 'device.stock',
  DEVICE_STOCK_HAS_ERROR: 'device.stock_error',
  DEVICE_BRAND_FILTER: 'deviceFilters.brand',
  DEVICE_MODEL_FILTER: 'deviceFilters.model',
  DEVICE_MIN_PRICE_FILTER: 'deviceFilters.minPrice',
  DEVICE_MAX_PRICE_FILTER: 'deviceFilters.maxPrice',
  DEVICE_DUAL_SIM_FILTER: 'deviceFilters.dualSim',
  DEVICE_SCREEN_SIZE_FILTER: 'deviceFilters.screenSize',
  DEVICE_CAPACITY_FILTER: 'deviceFilters.capacity',
  DEVICE_CATEGORY_FILTER: 'deviceFilters.category',
  ID_DUE_DATE: 'financialData.dueDate',
  SALARY: 'financialData.salary',
  JOB: 'financialData.job',
  COMPANY: 'financialData.company',
  ADDRESS_TYPE: 'shipping.type',
  OTHER_ADDRESS: 'shipping.otherAddress',
  OTHER_ADDRESS_NUMBER: 'shipping.otherAddress.number',
  OTHER_ADDRESS_FLAT: 'shipping.otherAddress.flat',
  OTHER_ADDRESS_DOOR: 'shipping.otherAddress.door',
  OTHER_ADDRESS_OTHER_INFO: 'shipping.otherAddress.other_info',
  FINANCED_AMEND_PENALTY: 'financedDeviceDecisions.amendPenalty',
  FEES_PAYMENT_TYPE: 'financedDeviceDecisions.feesPaymentType',
  FINAL_PAYMENT_TYPE: 'financedDeviceDecisions.finalPaymentType',
  BILLING_PAYMENT_TYPE: 'billing.paymentType',
  BILLING_PAYMENT_TRANSACTION: 'billing.transaction',
  FINANCED_LEGAL_CONSENT_1: 'financedLegalConsents.consent1',
  FINANCED_LEGAL_CONSENT_2: 'financedLegalConsents.consent2',
  FINANCED_LEGAL_CONSENT_3: 'financedLegalConsents.consent3',
  SVA_AGILE_EMAIL: 'svaAgileEmail',
}
