import { call, put, takeEvery } from 'redux-saga/effects'
import { logError, formatErrorMessage } from 'services/logging'
import { axiosFactory } from 'modules/axios'
import { actions } from './actions'
import { constants } from './constants'
import { findOrderOnFly } from './api'

export function* findOrderOnFlySaga({ payload }) {
  try {
    const orderOnFly = yield call(findOrderOnFly, {
      fixedline: payload,
    })
    yield put(actions.findOrderOnFlySuccess({ data: orderOnFly }))
  } catch (e) {
    yield call(logError, { e: new Error(formatErrorMessage(e)), payload })
    const error = axiosFactory.buildError(e)
    yield put(actions.findOrderOnFlyFailed(error))
  }
}

export function* watchOrderOnFly() {
  yield takeEvery(constants.FIND_ORDERONFLY_INIT, findOrderOnFlySaga)
}
