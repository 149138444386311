import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { useFormikContext } from 'formik'

import { FormikInput, Callout } from 'components/ui'

export function SearchNumber({
  fieldNames,
  mobileNumberOptions,
  onSelectNewNumber,
  onChangeDesiredNumber,
  className,
}) {
  const { values } = useFormikContext()

  useEffect(() => {
    const desiredNumber = get(values, fieldNames.NEW_LINE_SEARCH_DIGITS, '')
    const currentNumber = get(values, fieldNames.NEW_LINE_NUMBER, '')

    if (currentNumber) {
      onChangeDesiredNumber(desiredNumber, currentNumber)
    }
  }, [])

  const options = mobileNumberOptions.map(elem => ({ label: elem, value: elem, id: elem }))
  return (
    <div className="p-x-24 full-width m-b-20">
      <Callout className={className}>
        <div className="flex flex-wrap">
          <div className="p-r-16">
            <FormikInput
              maxLength="4"
              label="Nº deseado"
              name={fieldNames.NEW_LINE_SEARCH_DIGITS}
              onChange={value => onChangeDesiredNumber(value)}
            />
          </div>
          <div>
            <FormikInput
              name={fieldNames.NEW_LINE_NUMBER}
              label="Seleccionar Número"
              select
              width="256px"
              options={options}
              onChange={onSelectNewNumber}
            />
          </div>
        </div>
      </Callout>
    </div>
  )
}

SearchNumber.propTypes = {
  fieldNames: PropTypes.object,
  mobileNumberOptions: PropTypes.array.isRequired,
  onSelectNewNumber: PropTypes.func.isRequired,
  onChangeDesiredNumber: PropTypes.func.isRequired,
  className: PropTypes.string,
}
