import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

const style = theme => ({
  'terminal-description': {
    ...theme.typography.body1,
    fontSize: '14px',
    '& > span': {
      fontWeight: '500',
    },
    '& strong': {
      fontWeight: 'bold',
    },
  },
})

const TerminalItemDescriptionComponent = ({ children, classes }) => (
  <div className={classes['terminal-description']}>{children}</div>
)

TerminalItemDescriptionComponent.propTypes = {
  classes: PropTypes.any,
  children: PropTypes.any,
}

export default withStyles(style)(TerminalItemDescriptionComponent)
