import PropTypes from 'prop-types'
import { find } from 'lodash'
import styled from 'styled-components'
import { FormikInput } from 'components/ui'
import { documentTypes, PASSPORT_LABEL } from 'modules/CustomerDocument/constants/documentTypes'

export function OtherPerson({ fieldNames, otherUserDocument, className }) {
  const documentTypeOptions = [
    { key: documentTypes.NIF, value: documentTypes.NIF, label: documentTypes.NIF },
    { key: documentTypes.NIE, value: documentTypes.NIE, label: documentTypes.NIE },
    { key: documentTypes.CIF, value: documentTypes.CIF, label: documentTypes.CIF },
    { key: documentTypes.PASSPORT, value: documentTypes.PASSPORT, label: PASSPORT_LABEL },
  ]
  const optionComponent = find(documentTypeOptions, i => i.value === otherUserDocument) || {}
  return (
    <div className={`${className} full-width`}>
      <div className="flex flex-wrap">
        <div className="flex-1 p-r-16">
          <FormikInput
            fullWidth
            select
            name={fieldNames.DOCUMENT_TYPE}
            label="T.Documento"
            options={documentTypeOptions}
          />
        </div>
        <div className="flex-1 p-r-16">
          <FormikInput
            fullWidth
            maxLength="9"
            disabled={{ misses: fieldNames.DOCUMENT_TYPE }}
            name={fieldNames.DOCUMENT_VALUE}
            label={optionComponent.label || 'Documento'}
            uppercase
          />
        </div>
      </div>
      {!!otherUserDocument &&
        (optionComponent.value === documentTypes.CIF ? (
          <div className="flex-1 p-r-16">
            <FormikInput fullWidth name={fieldNames.COMPANY_NAME} label="Nombre de la empresa" />
          </div>
        ) : (
          <div className="flex flex-wrap">
            <div className="flex-1 p-r-16">
              <FormikInput
                fullWidth
                disabled={{ error: fieldNames.DOCUMENT_VALUE }}
                name={fieldNames.FIRST_NAME}
                label="Nombre"
                capitalize
              />
            </div>
            <div className="flex-1 p-r-16">
              <FormikInput
                fullWidth
                disabled={{ error: fieldNames.DOCUMENT_VALUE }}
                name={fieldNames.LAST_NAME_1}
                label="Apellido 1"
                capitalize
              />
            </div>
            <div className="flex-1 p-r-16">
              <FormikInput
                fullWidth
                disabled={{ error: fieldNames.DOCUMENT_VALUE }}
                name={fieldNames.LAST_NAME_2}
                label="Apellido 2"
                capitalize
              />
            </div>
          </div>
        ))}
    </div>
  )
}

export const StyledOtherPerson = styled(OtherPerson)`
  font-weight: 400;
`

OtherPerson.propTypes = {
  otherUserDocument: PropTypes.string,
  fieldNames: PropTypes.object.isRequired,
  className: PropTypes.string,
}
