import PropTypes from 'prop-types'
import styled from 'styled-components'
import { get } from 'lodash'

const OfferItemWrapper = styled.div`
  opacity: ${props => (props.disabled ? '0.4' : '1')};
`

const ItemLabel = styled.div`
  color: ${props => get(props, 'theme.palette.global.gray')};
  font-size: 12px;
  font-weight: bold;
`

const ItemValue = styled.div`
  color: ${props => (props.frozen ? '#9d9d9d' : '#4d4e4f')};
  font-size: 14px;
`

const OfferItemSmall = props => {
  const { label, value, disabled, frozen } = props

  return (
    <OfferItemWrapper className="offer-item-portout" disabled={disabled} frozen={frozen}>
      <ItemLabel>{label}</ItemLabel>
      <ItemValue frozen={frozen}>{value}</ItemValue>
    </OfferItemWrapper>
  )
}

OfferItemSmall.propTypes = {
  disabled: PropTypes.bool,
  frozen: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.string,
}

export default OfferItemSmall
