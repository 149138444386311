import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { isEmpty } from 'lodash'
import { ActionAdvice } from 'components/ui'

import { getMsisdn } from 'services/subscriptions'

import { getPermanenceOrPenaltyChanges } from '../../selectors'
import { updateSubscription } from '../../actions'
import { CVM_FIXED_PENALTY_KEY, CVM_MOBILE_PENALTY_KEY } from '../../constants'

export function PermanenceModified({ className, subscription, isFixed }) {
  const permanenceAndPenalty = useSelector(state =>
    getPermanenceOrPenaltyChanges(state, subscription.id, isFixed),
  )

  const typeLabel = isFixed ? 'Fija' : 'Móvil'

  const dispatch = useDispatch()
  const onUpdateSubscription = data => dispatch(updateSubscription(getMsisdn(subscription), data))

  const hasPermanenceOrPenalty =
    !isEmpty(permanenceAndPenalty) &&
    (permanenceAndPenalty.permanenceRemoved || permanenceAndPenalty.penaltyCondoned)

  return hasPermanenceOrPenalty ? (
    <div className={className}>
      <ActionAdvice
        type="info"
        onCancel={() =>
          onUpdateSubscription({
            [isFixed ? CVM_FIXED_PENALTY_KEY : CVM_MOBILE_PENALTY_KEY]: {
              penaltyCondoned: false,
              permanenceRemoved: false,
              reason: null,
            },
          })
        }
        items={
          permanenceAndPenalty.penaltyCondoned
            ? [
                {
                  label: `Penalización ${typeLabel}`,
                  value: 'Condonada',
                },
              ]
            : [
                {
                  label: `Permanencia ${typeLabel}`,
                  value: 'Eliminada',
                },
              ]
        }
      />
    </div>
  ) : null
}

PermanenceModified.propTypes = {
  className: PropTypes.string,
  subscription: PropTypes.object,
  isFixed: PropTypes.bool,
}
