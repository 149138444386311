import * as constants from './constants'

const findProvisioning = zipCode => ({
  type: constants.FIND_PROVISIONING,
  zipCode,
})

const foundProvisioning = data => ({
  type: constants.FIND_PROVISIONING_SUCCESS,
  payload: {
    ...data,
    loading: false,
  },
})

const findProvisioningFailed = error => ({
  type: constants.FIND_PROVISIONING_FAILED,
  payload: {
    error,
    loading: false,
  },
})

const findProvisioningReset = () => ({
  type: constants.FIND_PROVISIONING_RESET,
})

export const actions = {
  findProvisioning,
  foundProvisioning,
  findProvisioningFailed,
  findProvisioningReset,
}
