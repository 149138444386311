import { useState } from 'react'
import PropTypes from 'prop-types'

import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import { getLighten } from 'utils'
import * as logisticOrdersSvc from 'services/logistic-orders'
import { Tabs } from 'components/ui'
import { Card } from 'modules/ui'

import LogisticCardBody from './LogisticCardBody'

const useStyles = makeStyles(theme => ({
  'logistic-card': {
    '& .tabs-wrapper': {
      padding: 0,
      borderBottom: `2px solid ${getLighten(theme.palette.global.gray_medium, 0.8)}`,
    },
  },
}))
export const LogisticCard = ({ data, loading, isPosOrMasProximo }) => {
  const classes = useStyles()

  const [shipment, setShipment] = useState(0)

  const onSelectChangeHandler = (_, value) => setShipment(value)

  const getShipmentData = (shipmentData = [], i) => shipmentData[i] || null

  const getTabsConfig = (tabConfig = []) =>
    tabConfig.map((_, i) => ({ label: `Envío ${i + 1}`, val: i }))

  const shipmentData = getShipmentData(data, shipment)
  const tabsInfo = getTabsConfig(data)

  return (
    <Card
      title="Logística"
      id="Logística"
      withShadow
      loading={loading}
      empty={!shipmentData}
      className={classes['logistic-card']}>
      <>
        {tabsInfo.length > 1 ? (
          <Box display="flex" flexDirection="column" p={0} className="tabs-wrapper">
            <Tabs val={shipment} tabsInfo={tabsInfo} onSelectChange={onSelectChangeHandler} />
          </Box>
        ) : (
          ''
        )}
        {shipmentData && (
          <LogisticCardBody
            data={logisticOrdersSvc.helpers.extractShipmentLogisticData(shipmentData)}
            isPosOrMasProximo={isPosOrMasProximo}
          />
        )}
      </>
    </Card>
  )
}

LogisticCard.defaultProps = {
  data: [],
}

LogisticCard.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  isPosOrMasProximo: PropTypes.bool,
}
