import { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Modal, ButtonLink } from 'components/ui'

const Content = styled.span`
  line-height: 24px;
  white-space: pre-line;
`

export function TariffInfoModalComponent({ lineType, title, description, name, disabled }) {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <>
      <ButtonLink
        className="button-link"
        type="primary"
        iconType="info"
        onClick={() => setIsOpen(true)}
        disabled={!name || disabled}
        dataHook={`${lineType}.infoButton`}
      />
      <Modal
        data-hook="info-modal"
        title={title}
        isOpen={isOpen}
        type="default"
        onClose={() => setIsOpen(false)}>
        <Content data-hook="info-modal-content">{description}</Content>
      </Modal>
    </>
  )
}

TariffInfoModalComponent.propTypes = {
  name: PropTypes.string,
  lineType: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  disabled: PropTypes.bool,
}
