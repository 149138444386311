import { constants } from './constants'

const getDiscountsIncompatibilitiesInit = (newDiscountCode, currentDiscountsCode) => ({
  type: constants.GET_DISCOUNTS_INCOMPATIBILITIES_INIT,
  payload: {
    newDiscountCode,
    currentDiscountsCode,
  },
})

const getDiscountsIncompatibilitiesSuccess = (discountCode, discountsIncompatibilities) => ({
  type: constants.GET_DISCOUNTS_INCOMPATIBILITIES_SUCCESS,
  payload: {
    discountCode,
    discountsIncompatibilities,
  },
})

const getDiscountsIncompatibilitiesFailed = error => ({
  type: constants.GET_DISCOUNTS_INCOMPATIBILITIES_FAILED,
  payload: {
    error,
  },
})

export const actions = {
  getDiscountsIncompatibilitiesInit,
  getDiscountsIncompatibilitiesSuccess,
  getDiscountsIncompatibilitiesFailed,
}
