import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import {
  getMonthlyFeeTariff,
  generateTariffDuoPrincipal,
} from 'modules/offers-configuration/helpers'
import { get } from 'lodash'
import { formatMonthlyFee } from 'utils'
import { ActionAdvice } from 'components/ui'
import { applyTax, getTax } from 'services/taxes'
import {
  getMsisdn,
  getSubscriptionDuoPrincipal,
  getSubscriptionConvergent,
} from 'services/subscriptions'
import { isProCustomer } from 'services/customer-360/helpers'
import {
  isDuoPrincipalSubscription,
  isConvergentSubscription,
} from 'services/subscriptions/helpers'
import { getSegment, selectors as customer360SvcSelectors } from 'services/customer-360/selectors'

import { buildTariffName } from 'modules/tariffs/helpers/tariffs.helpers'
import { formatDiscounts } from 'modules/NewClientSales/store/selectors/NewClientSales.selectors.helpers'
import { updateSubscription } from '../../actions'
import {
  getChangedTariff,
  getNewTariff,
  getIsDuoPrincipalNewTariff,
  getShowNewTariffDuoPrincipal,
} from '../../selectors'

export function TariffItemModified({ className, subscription }) {
  const newTariff = useSelector(getNewTariff(subscription))
  const msisdn = getMsisdn(subscription)
  const isDuoPrincipalNewTariff = useSelector(state => getIsDuoPrincipalNewTariff(state, msisdn))
  const showNewTariffDuoPrincipal = useSelector(state =>
    getShowNewTariffDuoPrincipal(state, msisdn),
  )
  const subscriptionIsDuoPrincipal = isDuoPrincipalSubscription(subscription)
  const subscriptionIsConvergentSubscription = isConvergentSubscription(subscription)

  const allowUndoChanges = !newTariff && !isDuoPrincipalNewTariff

  const changedTariff = useSelector(getChangedTariff(subscription))
  const billingAddress = useSelector(customer360SvcSelectors.getBillingAddress)

  const postCode = get(billingAddress, 'postCode')

  const customerSegment = useSelector(getSegment)
  const taxNeeded = !isProCustomer(customerSegment)

  const tax = (taxNeeded && useSelector(state => getTax(state, postCode))) || 0

  const changedTariffWithTax = applyTax(tax, getMonthlyFeeTariff(changedTariff))

  const subscriptionDuoPrincipal = useSelector(getSubscriptionDuoPrincipal)
  const msisdnDuo = getMsisdn(subscriptionDuoPrincipal)
  const subscriptionDuoPrincipalContentNewTariff = useSelector(state =>
    getIsDuoPrincipalNewTariff(state, msisdnDuo),
  )

  const subscriptionConvergent = useSelector(getSubscriptionConvergent)
  const msisdnConvergent = getMsisdn(subscriptionConvergent)
  const convergentContentShowNewTariffDuoPrincipal = useSelector(state =>
    getShowNewTariffDuoPrincipal(state, msisdnConvergent),
  )

  const dispatch = useDispatch()
  const onUpdateSubscription = data => {
    dispatch(updateSubscription(msisdn, data))

    if (
      subscriptionIsConvergentSubscription &&
      showNewTariffDuoPrincipal &&
      subscriptionDuoPrincipalContentNewTariff
    ) {
      dispatch(
        updateSubscription(msisdnDuo, {
          confirmedTariff: null,
          date: null,
        }),
      )
    } else if (
      subscriptionIsDuoPrincipal &&
      !isDuoPrincipalNewTariff &&
      convergentContentShowNewTariffDuoPrincipal
    ) {
      dispatch(updateSubscription(msisdnDuo, generateTariffDuoPrincipal(tax)))
    }
  }

  const discountWithTaxes = changedTariff?.calculatedDiscounts?.discounts?.map(discount => ({
    ...discount,
    amount: applyTax(tax, discount.amount),
  }))

  const tariffDiscountFormated = get(
    formatDiscounts({
      ...changedTariff,
      discounts: discountWithTaxes,
    }),
    '[0].display',
  )

  return changedTariff ? (
    <ActionAdvice
      id="tariff-modified"
      className={className}
      type="info"
      onCancel={
        allowUndoChanges
          ? () =>
              onUpdateSubscription({
                confirmedTariff: null,
                date: null,
                showNewTariffDuoPrincipal: null,
              })
          : undefined
      }
      items={[
        {
          label: 'Nueva Tarifa',
          value: buildTariffName(
            get(changedTariff, 'id'),
            get(changedTariff, 'commercial_name', ''),
          ),
        },
        { label: 'Nueva Cuota', value: formatMonthlyFee(changedTariffWithTax) },
        get(tariffDiscountFormated, '[0]', {}),
      ]}
    />
  ) : null
}

TariffItemModified.propTypes = {
  className: PropTypes.string,
  subscription: PropTypes.object,
}
