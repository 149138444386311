export const SALES_TEST_ROUTE = '/add-client'
export const SALES_BASE_PATH = '/add-client/sales'
export const SALES_INFO = `${SALES_BASE_PATH}/info`
export const SALES_OFFERS = `${SALES_BASE_PATH}/offers`
export const SALES_BILLING = `${SALES_BASE_PATH}/billing`
export const SALES_SUMMARY = `${SALES_BASE_PATH}/summary`
export const SALES_APPOINTMENT = `${SALES_BASE_PATH}/appointment`
export const SALES_NEXT_STEPS = `${SALES_BASE_PATH}/next-steps`
export const SALES_PAYMENT_SUCCESS = `${SALES_BASE_PATH}/payment/success`
export const SALES_LEADS_PATH = '/sales-leads'
