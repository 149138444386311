import styled from 'styled-components'

export const Section = styled.section`
  background-color: #ffffff;
  border: 1px solid #eee;
  border-top: 5px solid #ffdf00;
  border-radius: 3px;
  box-shadow: 0 0 25px rgba(40, 47, 60, 0.05), 0 20px 25px rgba(40, 47, 60, 0.05),
    0 3px 4px rgba(40, 47, 60, 0.05);
  text-align: left;
  padding: 30px;
`
