import { get } from 'lodash'

import * as actions from './prepaid-line.actions'

const initialState = {
  data: {},
  loading: false,
  error: null,
}

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actions.FIND_PREPAID_LINE: {
      return {
        ...state,
        loading: true,
      }
    }
    case actions.FIND_PREPAID_LINE_SUCCESS: {
      return {
        ...state,
        data: {
          [payload.productId]: payload.data,
        },
        loading: false,
      }
    }
    case actions.FIND_PREPAID_LINE_FAILED: {
      return {
        ...state,
        error: get('error', payload) || payload,
        loading: false,
      }
    }
    default:
      return state
  }
}
