import { call, put, select, takeLatest, takeLeading, delay } from 'redux-saga/effects'
import { logError, formatErrorMessage } from 'services/logging'
import { axiosFactory } from 'modules/axios'
import { generateOrder } from 'modules/NewClientSales/containers/SalesContainer/Summary/checkout/generator'
import { sendTransactionError } from 'modules/Analytics/redux/analytics.actions'

import {
  orderActionTypes,
  fetchOrderCreationStatusSuccess,
  fetchOrderCreationStatusError,
  createNewOrderSuccess,
  createNewOrderError,
  startPollingOrderStatus,
  finishedPollingOrderStatusSuccess,
  finishedPollingOrderStatusError,
  setOrder,
} from '../actions'
import { fetchCheckOrderStatus, createNewOrder } from '../../services/orders.service'
import { closePresale } from '../../services/presale.service'
import {
  getBasket,
  selectTaskId,
  selectScoringToken,
  orderCheckoutSelectors,
  selectErrorFetchRetries,
  selectOrderCheckingFinished,
  selectStartPollingTime,
  selectOrderStatusSuccess,
  selectBasketAndOrder,
  selectTransactionForAnalytics,
  selectExtraMobileLinesOffers,
} from '../selectors'

// Request the order status
export function* fetchOrderCreationStatusSaga() {
  try {
    const taskId = yield select(selectTaskId)
    const status = yield call(fetchCheckOrderStatus, taskId)
    yield put(fetchOrderCreationStatusSuccess(status))
  } catch (e) {
    console.warn(e)
    yield call(logError, new Error(formatErrorMessage(e)))
    const error = axiosFactory.buildError(e)
    yield put(fetchOrderCreationStatusError(error))
  }
}

export function* createNewOrderSaga() {
  try {
    const { isOrderGenerated, ...checkout } = yield select(orderCheckoutSelectors)
    if (isOrderGenerated) {
      return
    }

    const newOrder = yield call(generateOrder, checkout)

    yield put(setOrder(newOrder))
    const scoringToken = yield select(selectScoringToken)
    const basketAndOrder = yield select(selectBasketAndOrder)
    const taskId = yield call(createNewOrder, scoringToken, basketAndOrder)

    yield put(createNewOrderSuccess(taskId))
    // start polling
    yield put(startPollingOrderStatus())
  } catch (e) {
    console.warn(e)
    yield call(logError, new Error(formatErrorMessage(e)))
    const error = axiosFactory.buildError(e)
    yield put(createNewOrderError(error))
  }
}

export function* watchCreateNewOrder() {
  yield takeLeading(orderActionTypes.CREATE_NEW_ORDER, createNewOrderSaga)
}

const TIME_POLLING = 3000
const DEFAULT_POLLING_TIME = 90000
const ERROR_RETRIES = 10

export function* pollingOrderStatusSaga() {
  // Call the fetch saga
  yield call(fetchOrderCreationStatusSaga)

  // if is finished stop polling
  const isFinished = yield select(selectOrderCheckingFinished)
  const isSuccess = yield select(selectOrderStatusSuccess)
  const fetchRetries = yield select(selectErrorFetchRetries)
  const transactionForAnalytics = yield select(selectTransactionForAnalytics)
  const extraLines = yield select(selectExtraMobileLinesOffers)

  const MAX_POLLING_TIME = extraLines.length * 2000 + DEFAULT_POLLING_TIME

  if (!isFinished && fetchRetries < ERROR_RETRIES) {
    const startPollingTime = yield select(selectStartPollingTime)
    const difference = Date.now() - startPollingTime
    if (difference > MAX_POLLING_TIME) {
      yield put(
        fetchOrderCreationStatusError({
          error: {
            httpCode: '504',
            info: 'Client cut pulling due timeout',
          },
        }),
      )
      yield put(finishedPollingOrderStatusError())
      yield put(sendTransactionError({ errorCode: '504', item: transactionForAnalytics }))
    } else {
      // Continue polling
      yield delay(TIME_POLLING)
      yield call(pollingOrderStatusSaga)
    }
  } else if (isSuccess) {
    const { presaleId } = yield select(getBasket)

    if (presaleId) {
      try {
        yield call(closePresale, presaleId)
      } catch (e) {
        console.error('Error closing presale data', e, presaleId)
      }
    }
    yield put(finishedPollingOrderStatusSuccess())
  } else {
    yield put(finishedPollingOrderStatusError())
    yield put(sendTransactionError({ errorCode: '408', item: transactionForAnalytics }))
  }
}

export function* watchStartPollingOrder() {
  // Loading a true
  yield takeLatest(orderActionTypes.START_POLL_ORDER, pollingOrderStatusSaga)
}
