import PropTypes from 'prop-types'
import { Box, Typography, Checkbox, FormControlLabel } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'

const StyledFormLabel = withStyles({
  label: {
    fontSize: '12px',
    color: '#4d4e4f',
  },
})(FormControlLabel)

const PaperInvoice = ({
  values: {
    billing: { isPaperInvoice },
  },
  handleChange,
}) => (
  <>
    <Box display="flex" flexDirection="column" alignItems="flex-start">
      <Typography variant="body1">
        En Yoigo estamos comprometidos con el medio ambiente, por lo cual cada mes te enviaremos un
        SMS con un link para acceder a la factura electrónica. Regístrate en el área de cliente
        miyoigo.com y allí tendrás las facturas además de toda la información de tu contrato.
      </Typography>
      <Typography variant="body1">
        Como ves, es mucho más sencillo y tiene la misma validez.
      </Typography>
    </Box>
    <Box display="flex" flexDirection="column" alignItems="flex-end">
      <StyledFormLabel
        name="billing.isPaperInvoice"
        control={
          <Checkbox
            className="checkbox"
            color="primary"
            icon={<CheckBoxOutlineBlankIcon style={{ fontSize: 15 }} />}
            checkedIcon={<CheckBoxIcon style={{ fontSize: 15 }} />}
            checked={!!isPaperInvoice}
            onChange={handleChange}
            value="isPaperInvoice"
          />
        }
        label="El cliente quiere factura en papel"
      />
    </Box>
  </>
)

PaperInvoice.propTypes = {
  values: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
}

export default PaperInvoice
