import * as constants from './constants'

const initialState = {
  linesProvisioning: {
    loading: false,
    error_code: null,
    error_message: null,
    data: {},
  },
}

export const reducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case constants.FIND_PROVISIONING: {
      return { loading: true, error_code: null, error_message: null, data: {} }
    }
    case constants.FIND_PROVISIONING_SUCCESS: {
      return { data: payload.data, loading: false }
    }
    case constants.FIND_PROVISIONING_FAILED: {
      return {
        error_code: payload.error.httpCode,
        error_message: payload.error.info,
        loading: false,
      }
    }
    case constants.FIND_PROVISIONING_RESET: {
      return {
        ...state,
        loading: false,
        error_code: null,
        error_message: null,
        data: {},
      }
    }

    default:
      return state
  }
}
