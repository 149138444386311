import { useState } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { Modal, Button } from 'components/ui'
import { uniq, get } from 'lodash'

import { TerminalsPaymentComponent } from 'modules/Terminals/components'
import { ModalActions } from 'components/packages/ui/modal/Modal'
import { TerminalSummaryInterface } from 'modules/Terminals/model/TerminalFormModel'
import { TerminalSummary } from 'modules/Terminals'
import { getPaymentIframeUrl } from 'modules/Terminals/services'
import { selectCashOnDeliveryFlag } from 'services/feature-flag/selectors'
import { selectIsYoigoStoreShipping } from 'modules/NewClientSales/store/selectors'

function updateStep(state, step, newVal) {
  return state.map((item, i) => {
    if (i === step) {
      return {
        ...item,
        ...newVal,
      }
    }
    return item
  })
}

function ConfirmModalComponent({ isOpen, onConfirm, onClose, title, message }) {
  return (
    <Modal title={title} isOpen={isOpen} type="default">
      <p>{message}</p>
      <ModalActions>
        <Button margin="4px 16px 4px 0" onClick={onConfirm}>
          Confirmar
        </Button>
        <Button secondary onClick={onClose}>
          Volver
        </Button>
      </ModalActions>
    </Modal>
  )
}

ConfirmModalComponent.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
}

// TODO: We should disable the buttons when a payment is going on,
// We need to detect (somehow) if the iframe is loading or if you press the pay button.
function StepperButtons({ numOfTerminals, step, setStep, disabled }) {
  return (
    <div>
      <Button
        text
        color="secondary"
        disabled={step === 0 || disabled}
        onClick={() => setStep(step - 1)}>
        Anterior
      </Button>
      <Button
        dataHook="tpv-next-button"
        text
        color="secondary"
        disabled={step === numOfTerminals - 1 || disabled}
        onClick={() => setStep(step + 1)}>
        Siguiente
      </Button>
    </div>
  )
}

StepperButtons.propTypes = {
  numOfTerminals: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
  setStep: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
}
const PaymentTypes = { TPV: 'TPV', COD: 'CASH_ON_DELIVERY' }

// First terminal will always be the associated to the main tariff, and will be mandatory
export function TerminalsPaymentDialog({ isOpen, onClose, onSuccess, terminals, className }) {
  const canPayOnDelivery = useSelector(selectCashOnDeliveryFlag)
  const isYoigoStoreShipping = useSelector(selectIsYoigoStoreShipping)

  const defaultItemState = {
    isSuccess: false,
    isError: false,
    isLoading: false,
    url: null,
    paymentType: canPayOnDelivery ? '' : 'TPV',
    numOfRetries: 0,
  }
  const initialListState = Array.from(terminals, () => defaultItemState)
  const [step, setStep] = useState(0)
  // State for child payment components
  const [terminalListState, setTerminalListState] = useState(initialListState)
  // TPV and COD successful payments
  const [TPVPayments, setTPVPayments] = useState([])
  const [CODPayments, setCODPayments] = useState([])
  // Confirmation modals
  const [isExitModalOpen, setExitModalOpen] = useState(false)

  // useful view shorthands
  const numOfTerminals = terminals.length
  const numOfSuccessfulItems = [...TPVPayments, ...CODPayments].length
  const currentTerminal = terminals[step]
  const canContinue = numOfSuccessfulItems === numOfTerminals
  const canPayNextTerminal =
    (currentTerminal && (!currentTerminal.isMain || numOfSuccessfulItems)) || CODPayments.length
  const currentItemState = terminalListState[step]
  const amount = get(
    currentTerminal,
    'computedPrices.initialPayment',
    get(currentTerminal, 'computedPrices.totalPayment', 0),
  )

  function onContinue() {
    if (canContinue) {
      onSuccess([...TPVPayments, ...CODPayments])
    }
  }

  function onItemSuccess(item) {
    const newSuccessfulItems = uniq([...TPVPayments, item])
    setTPVPayments(newSuccessfulItems)
    setTerminalListState(
      updateStep(terminalListState, step, {
        isSuccess: true,
        isError: false,
        isLoading: false,
      }),
    )
  }

  function onItemError(value) {
    setTerminalListState(
      updateStep(terminalListState, step, {
        isSuccess: false,
        isError: value,
        isLoading: false,
        numOfRetries: currentItemState.numOfRetries + 1,
      }),
    )
  }

  function onItemLoad(url) {
    setTerminalListState(
      updateStep(terminalListState, step, {
        url,
        isLoading: false,
      }),
    )
  }

  function onItemChange(item, value) {
    if (value === PaymentTypes.COD) {
      const newCODPayments = uniq([...CODPayments, item])
      setCODPayments(newCODPayments)
    } else {
      setCODPayments(CODPayments.filter(i => i.lineType !== item.lineType))
    }
    setTerminalListState(
      updateStep(terminalListState, step, {
        paymentType: canPayOnDelivery ? value : 'TPV',
        paymentResult: null,
      }),
    )
  }

  function getAndSetUrl() {
    setTerminalListState(
      updateStep(terminalListState, step, {
        isLoading: true,
      }),
    )
    return getPaymentIframeUrl(amount)
      .then(iframeUrl => {
        onItemLoad(iframeUrl)
      })
      .catch(() => {
        onItemError(true)
      })
  }

  function onItemRetry() {
    onItemError(false)
    getAndSetUrl()
  }

  return (
    <div>
      <Modal
        title={`Pago con de dispositivo - ${step + 1} ${get(currentTerminal, 'details.name', '')}`}
        isOpen={isOpen}
        className={className}
        type="default">
        <p className="successful-items">
          Dispositivos pagados: {numOfSuccessfulItems} de {numOfTerminals}
        </p>
        <div className="tpv-item">
          <TerminalSummary className="summary" terminal={currentTerminal} />
          {currentTerminal && (
            <TerminalsPaymentComponent
              terminal={currentTerminal}
              step={step}
              onError={onItemError}
              onRetry={onItemRetry}
              onChange={(item, val) =>
                onItemChange(
                  {
                    paymentResult: item,
                    id: currentTerminal.id,
                    lineType: currentTerminal.lineType,
                  },
                  val,
                )
              }
              onSuccess={e =>
                onItemSuccess({
                  paymentResult: e,
                  id: currentTerminal.id,
                  lineType: currentTerminal.lineType,
                })
              }
              onLoad={onItemLoad}
              amount={amount}
              getUrl={getAndSetUrl}
              noCodPayment={isYoigoStoreShipping}
              {...currentItemState}
            />
          )}
        </div>
        <div className="flex space-between">
          <StepperButtons
            numOfTerminals={numOfTerminals}
            step={step}
            setStep={setStep}
            disabled={!canPayNextTerminal}
          />

          <div className="action-buttons">
            <Button data-hook="finish-checkout" disabled={!canContinue} onClick={onContinue}>
              Finalizar pedido
            </Button>
            {!numOfSuccessfulItems && (
              <Button secondary onClick={() => setExitModalOpen(true)}>
                Salir
              </Button>
            )}
          </div>
        </div>
        <ConfirmModalComponent
          isOpen={isExitModalOpen}
          onClose={() => setExitModalOpen(false)}
          onConfirm={onClose}
          title="La orden se perderá"
          message="¿Estás seguro de que quieres salir?"
        />
      </Modal>
    </div>
  )
}

TerminalsPaymentDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onSuccess: PropTypes.func.isRequired,
  terminals: PropTypes.arrayOf(TerminalSummaryInterface),
  className: PropTypes.string,
  onClose: PropTypes.func.isRequired,
}
