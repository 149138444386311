export const HomeGo = {
  selectedAddressType: '',
  otherAddress: {
    address: '',
    city: '',
    door: '',
    flat: '',
    number: '',
    otherInfo: '',
    province: '',
    zipCode: '',
    fullAddress: '',
  },
}
