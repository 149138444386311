import { useState } from 'react'
import { useSelector } from 'react-redux'
import { get } from 'lodash'
import { Box, Icon, CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { ICONS } from 'utils/icons'
import { ButtonLink } from 'components/ui'
import { getDocumentTechDom } from 'services/document-template'
import { openBlob, buildBlob } from 'utils'
import { logError, formatErrorMessage } from 'services/logging'

const useStyles = makeStyles({
  'download-btn-wrapper': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: '10px',

    '& p': {
      fontSize: '1rem',
      fontFamily: 'Roboto',
      fontWeight: 400,
    },
  },
})

const DownloadResume = () => {
  const classes = useStyles()

  const changeTechnologyOrderId = useSelector(state =>
    get(state, 'changeTechnology.data.orderId', ''),
  )

  const [loading, setLoading] = useState(false)
  const downloadInit = () => setLoading(true)
  const downloadFinish = () => setLoading(false)

  const onDownload = () => {
    downloadInit()
    getDocumentTechDom(changeTechnologyOrderId)
      .then(blob => {
        const blobBuilt = buildBlob([blob], 'pdf')
        openBlob(blobBuilt)
      })
      .catch(e => logError(new Error(formatErrorMessage(e))))
      .then(downloadFinish)
  }

  return (
    <Box display="flex" m="8px 0 0 0">
      <ButtonLink
        className={classes['download-btn-wrapper']}
        onClick={onDownload}
        disabled={loading}>
        <Icon className="icon">{ICONS.download}</Icon>
        <p data-hook="descargar pdf">Descargar PDF</p>
      </ButtonLink>
      {loading && <CircularProgress size={25} color="secondary" />}
      {/* <CircularProgress size={25} color="secondary" /> */}
    </Box>
  )
}

export default DownloadResume
