import { createSelector } from 'reselect'
import { find } from 'lodash'

import { selectFirstSubscription } from 'services/subscriptions/selectors'
import {
  selectors as customer360Selectors,
  helpers as customer360Helpers,
} from 'services/customer-360'
import { getOrderId } from 'modules/orders'

const selectFirstClientSubscriptionProduct = createSelector(
  [selectFirstSubscription, customer360Selectors.contracts],
  (firstClientSubscription, contracts) =>
    customer360Helpers.getProductByMsisdn(contracts, firstClientSubscription?.id),
)

export const selectClientVerbalId = createSelector(
  [selectFirstClientSubscriptionProduct],
  subscriptionProduct => {
    let verbalId

    if (subscriptionProduct) {
      const { category, lines } = subscriptionProduct
      const verbalLineType = category === '1P' ? 'mobile' : 'fixed'
      verbalId = lines.find(line => line.type === verbalLineType)?.id || lines[0]?.id
    }

    return verbalId
  },
)

export const selectClientSubscriptionId = createSelector(
  selectFirstSubscription,
  subscription => subscription?.subscription_id,
)

export const selectOrderVerbalId = createSelector(
  getOrderId,
  customer360Selectors.orders,
  (orderId, orders) => {
    let verbalId

    const order = find(orders, { id: orderId })

    if (!!orderId && !!order) {
      const { type, fixedNumber, mobileNumber } = order

      verbalId = type === 'Sólo Móvil' ? mobileNumber : fixedNumber
    }

    return verbalId
  },
)
