import { useMemo } from 'react'
import PropTypes from 'prop-types'

import { Typography } from '@material-ui/core'
import { formatNumberWithComma } from 'utils/formatting/index'

export function BundlePrice({ preSelectedBundles, tariff }) {
  const fees = useMemo(() => {
    const bundlesFee = preSelectedBundles
      .map(pack => ({ amount: pack.monthlyFeeWithTax, duration: 0 }))
      .reduce((curr, next) => ({ ...curr, amount: curr.amount + next.amount }), {
        amount: 0,
        duration: 0,
      })
    return tariff?.fees?.map(fee => ({ ...fee, amount: bundlesFee.amount + fee.amount }))
  }, [preSelectedBundles, tariff])
  return (
    <>
      {fees.length && (
        <div className="flex flex-wrap">
          <Typography variant="body1" color="textSecondary" className="p-r-4">
            Precio resultante:
          </Typography>
          {fees.reverse().map((elem) =>
            elem.duration ? (
              <Typography variant="body1" color="textSecondary" className="bold p-r-4" key={`bundled-${elem.amount}-${elem.duration}`}>
                {`${formatNumberWithComma(elem.amount)} €/mes durante ${
                  elem.duration
                } meses, después`}
              </Typography>
            ) : (
              <Typography
                variant="body1"
                color="textSecondary"
                data-hook="bundle-total"
                className="bold p-r-4"
                key={`bundled-${elem.amount}`}>
                {` ${formatNumberWithComma(elem.amount)} €/mes`}
              </Typography>
            ),
          )}
        </div>
      )}
    </>
  )
}

BundlePrice.propTypes = {
  preSelectedBundles: PropTypes.array,
  tariff: PropTypes.object,
}

export default BundlePrice
