import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormikContext } from 'formik'

import { fetchSvaProList } from 'modules/SVAs/store'
import { SVA_HOMEGO_CODE } from 'modules/offers-configuration/constants'

import { HomeGo as homeGoModel } from '../../../store/models/homeGo'
import { useSetFormikValuesToBasket, useChannelAndSegment } from '../../../hooks/Shared'
import { proSvasSelectors } from '../../Offer/ProSvas/ProSvas.selectors'

export function useProSvas(mainTariffPsId, shouldFetch, careSegment, shouldFetchHomeGo) {
  const dispatch = useDispatch()
  const { setFieldValue } = useFormikContext() || {}
  const setFormikValuesToBasket = useSetFormikValuesToBasket()
  const { channel, segment } = useChannelAndSegment()
  const { svaList, isLoading, storeSvas, formatedSvas, formatedSvasPermanency } = useSelector(
    proSvasSelectors,
  )

  useEffect(() => {
    if (mainTariffPsId && shouldFetch) {
      dispatch(
        fetchSvaProList({
          channel,
          segment: careSegment || segment,
          mainTariffPsId,
          shouldFetchHomeGo,
        }),
      )
    }
  }, [mainTariffPsId])

  useEffect(() => {
    if (!!setFieldValue && !storeSvas.find(sva => sva.psId.includes(SVA_HOMEGO_CODE))) {
      setFieldValue('homeGo', homeGoModel)
    }
  }, [storeSvas])

  function saveSvas(svas) {
    if (!svas) return
    setFieldValue('landlineOffer.proSVAs', svas)
    setFormikValuesToBasket('landlineOffer.proSVAs', svas)
  }

  function deleteSva(id) {
    const filteredSvas = storeSvas.filter(elem => elem.psId !== id)
    setFieldValue('landlineOffer.proSVAs', filteredSvas)
    setFormikValuesToBasket('landlineOffer.proSVAs', filteredSvas)
  }

  const disableSVAs = useMemo(() => isLoading || svaList?.length <= 0, [isLoading, svaList])

  return {
    disableProSVAs: disableSVAs,
    onSelectProSVAs: saveSvas,
    onDeleteProSVA: deleteSva,
    proSVAs: storeSvas,
    svaList,
    isLoadingSvas: isLoading,
    formatedSvas,
    formatedSvasPermanency,
  }
}
