import { useEffect, useMemo, useState } from 'react'
import { fetchCompetitorsTariffs } from '../services'

export function useFetchTariffCompetitors({ tariff, showCompetitors }) {
  const [isLoadingCompetitors, setIsLoadingCompetitors] = useState(false)
  const [competitors, setCompetitors] = useState({})

  const competitorsTariffs = useMemo(() => {
    return (competitors && (tariff?.id && competitors[tariff.id])) || []
  }, [competitors, tariff])

  const params = {
    technology: 'FIBER',
    mobileData: tariff?.mobileData,
    speed: tariff?.internetDownloadSpeed,
    priceY: tariff?.priceWithTax,
  }

  function getTariffs() {
    // move this to the Then
    setIsLoadingCompetitors(true)
    fetchCompetitorsTariffs(params).then(res => {
      setCompetitors({ [tariff.id]: res?.data })
      setIsLoadingCompetitors(false)
    })
  }

  useEffect(() => {
    if (tariff?.id && competitorsTariffs?.length <= 0 && showCompetitors) {
      getTariffs()
    }
  }, [tariff, showCompetitors])

  return { isLoadingCompetitors, competitorsTariffs }
}
