import { useState, useEffect, useMemo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useFormikContext } from 'formik'
import { get } from 'lodash'

import { fetchRenewalSvaAgileTv } from 'modules/SVAs/store'
import { mapSegments } from 'modules/NewClientSales/helpers'

import { DEVICE_RENEWAL_FIELDNAMES } from 'modules/device-renewal/constants'
import { fetchDevices, resetDevices } from '../store/'
import { useDevicesStock } from './useDevicesStock'

import { deviceOfferSelectors } from './useDeviceOffers.selectors'

import {
  orderDevices,
  mapDevicesToBrandOptions,
  mapDevicesToScreenSizeOptions,
  mapDevicesToCapacityOptions,
  mapDevicesToCategoryOptions,
  mapDevicesToModelOptions,
  doesDeviceMeetFiltersCriterias,
  noFilterHasBeenSelected,
} from './useDeviceOffers.helpers'

export const MAX_DEVICES_PER_PAGE = 5

const FILTER_TYPES_EXCEPTIONS = {
  BRAND: 'brand',
  CATEGORY: 'category',
  MODEL: 'model',
  CAPACITY: 'capacity',
  SCREEN: 'screen',
}

export const SORTABLE_OPTIONS = [
  {
    id: 'MARCA',
    name: 'Marca',
    sort: 'brand',
  },
  {
    id: 'PRECIO',
    name: 'Precio',
    sort: 'paymentMethods.upfront.initialPayment.taxIncludedAmount',
  },
]

export const DEFAULT_SORT = [
  {
    ...SORTABLE_OPTIONS[0],
    order: 'asc',
  },
  {
    ...SORTABLE_OPTIONS[1],
    order: 'asc',
  },
]

export const useDeviceOffers = (stockChannel, isAgileTvRenewal) => {
  const dispatch = useDispatch()

  const { values, setFieldValue } = useFormikContext()

  const {
    devices,
    fetchDevicesError,
    isFetchingDevices,
    channel,
    isFetchingAgile,
    agileTvData,
    fetchAgileTvError,
    ...fetchDevicesParams
  } = useSelector(deviceOfferSelectors)
  const { tariffType } = fetchDevicesParams

  const { brand, model, minPrice, maxPrice, dualSim, screenSize, capacity, category } = get(
    values,
    'deviceFilters',
    {},
  )

  const [filteredDevices, setFilteredDevices] = useState([])
  const [devicesToShow, setDevicesToShow] = useState([])
  const [currentDisplayIndex, setCurrentDisplayIndex] = useState(0)
  const [modelOptions, setModelOptions] = useState([])
  const [brandOptions, setBrandOptions] = useState([])
  const [screenSizeOptions, setScreenSizeOptions] = useState([])
  const [capacityOptions, setCapacityOptions] = useState([])
  const [categoryOptions, setCategoryOptions] = useState([])
  const [orderFields, setOrderFields] = useState(DEFAULT_SORT)

  const applyOrder = options => {
    setOrderFields(options)

    if (filteredDevices) {
      setFilteredDevices(orderDevices(filteredDevices, options))
    }
  }

  const { devicesStock, getDevicesStock, retryFetchDeviceStock } = useDevicesStock(stockChannel)

  const generateAndSetFiltersInputsOptions = (devicesToMap, filterTypeExceptions = []) => {
    if (devicesToMap?.length > 0) {
      if (!filterTypeExceptions.includes(FILTER_TYPES_EXCEPTIONS.MODEL)) {
        setModelOptions(mapDevicesToModelOptions(devicesToMap))
      }

      if (!filterTypeExceptions.includes(FILTER_TYPES_EXCEPTIONS.CATEGORY)) {
        setCategoryOptions(mapDevicesToCategoryOptions(devicesToMap))
      }

      if (!filterTypeExceptions.includes(FILTER_TYPES_EXCEPTIONS.BRAND)) {
        setBrandOptions(mapDevicesToBrandOptions(devicesToMap))
      }

      if (!filterTypeExceptions.includes(FILTER_TYPES_EXCEPTIONS.CAPACITY)) {
        setCapacityOptions(mapDevicesToCapacityOptions(devicesToMap))
      }

      if (!filterTypeExceptions.includes(FILTER_TYPES_EXCEPTIONS.SCREEN)) {
        setScreenSizeOptions(mapDevicesToScreenSizeOptions(devicesToMap))
      }
    }
  }

  const isPriceFilterValid = useMemo(() => {
    let result = true
    if ((minPrice || minPrice === 0) && (maxPrice || maxPrice === 0)) {
      result = parseInt(minPrice, 10) <= parseInt(maxPrice, 10)
    }

    if (!minPrice && minPrice !== 0 && (!maxPrice && maxPrice !== 0)) {
      result = false
    }

    return result
  }, [minPrice, maxPrice])

  function filterDevices(
    devicesToFilter,
    brandsToFilter,
    modelsToFilter,
    shouldApplyPriceFilter,
    minPriceFilter,
    maxPriceFilter,
    shouldBeDualSim = false,
    screenSizeToFilter,
    capacityToFilter,
    categoryToFilter,
  ) {
    let newFilteredDevices
    const shouldFilterDevices =
      brandsToFilter?.length > 0 ||
      modelsToFilter?.length > 0 ||
      shouldApplyPriceFilter ||
      shouldBeDualSim ||
      screenSizeToFilter?.length > 0 ||
      capacityToFilter?.length > 0 ||
      categoryToFilter?.length > 0

    if (shouldFilterDevices) {
      newFilteredDevices = devicesToFilter.filter(device =>
        doesDeviceMeetFiltersCriterias(
          device,
          brandsToFilter,
          modelsToFilter,
          shouldApplyPriceFilter,
          minPriceFilter,
          maxPriceFilter,
          shouldBeDualSim,
          screenSizeToFilter,
          capacityToFilter,
          categoryToFilter,
        ),
      )
    } else {
      newFilteredDevices = filteredDevices.slice()
    }

    const orderedAndFilteredDevices = orderDevices(newFilteredDevices, orderFields)
    setFilteredDevices(orderedAndFilteredDevices)
    return orderedAndFilteredDevices
  }

  const showNextDevices = (startIndex, endIndex) => {
    if (startIndex === 0) {
      setDevicesToShow([...filteredDevices.slice(startIndex, endIndex)])
    } else {
      setDevicesToShow([...devicesToShow, ...filteredDevices.slice(startIndex, endIndex)])
    }
  }

  const showMoreDevices = (startIndex = currentDisplayIndex) => {
    if (startIndex <= filteredDevices.length) {
      let endIndex = startIndex + MAX_DEVICES_PER_PAGE
      if (endIndex >= filteredDevices.length) {
        endIndex = filteredDevices.length
      }
      showNextDevices(startIndex, endIndex)
      setCurrentDisplayIndex(endIndex)
    }
  }

  const canShowMoreDevices = useMemo(() => devicesToShow.length < filteredDevices.length, [
    devicesToShow,
  ])

  const resetMinPriceFilter = () => {
    setFieldValue(DEVICE_RENEWAL_FIELDNAMES.DEVICE_MIN_PRICE_FILTER, '')
  }

  const applyAllFilters = useCallback(() => {
    return filterDevices(
      devices,
      brand,
      model,
      isPriceFilterValid,
      minPrice,
      maxPrice,
      dualSim,
      screenSize,
      capacity,
      category,
    )
  }, [
    devices,
    brand,
    model,
    isPriceFilterValid,
    minPrice,
    maxPrice,
    dualSim,
    screenSize,
    capacity,
    category,
  ])

  const removeAllFiltersAndRestoreOptions = () => {
    setFilteredDevices(orderDevices(devices, orderFields))
    generateAndSetFiltersInputsOptions(devices)
  }

  const segment = mapSegments(channel)

  const retrieveDevices = () => {
    dispatch(fetchDevices(fetchDevicesParams))
  }

  const retrieveAgileTvData = () => {
    dispatch(
      fetchRenewalSvaAgileTv({
        tariffType,
        paymentType: fetchDevicesParams.subscriptionPaymentType,
        channel: fetchDevicesParams.renewalSaleChannel,
        segment,
      }),
    )
  }

  const resetDevicesCatalog = useCallback(() => {
    if (fetchAgileTvError) {
      retrieveAgileTvData()
    }

    if (fetchDevicesError) {
      dispatch(resetDevices())
      retrieveDevices()
    }
  })

  const filtersRules = [
    {
      match: ({ deviceFilters }) => noFilterHasBeenSelected(deviceFilters, isPriceFilterValid),
      action: () => {
        removeAllFiltersAndRestoreOptions()
      },
    },
    {
      match: ({ deviceFilters }) => deviceFilters.screenSize?.length > 0,
      action: () => {
        generateAndSetFiltersInputsOptions(applyAllFilters(), [
          FILTER_TYPES_EXCEPTIONS.MODEL,
          FILTER_TYPES_EXCEPTIONS.BRAND,
          FILTER_TYPES_EXCEPTIONS.CAPACITY,
          FILTER_TYPES_EXCEPTIONS.SCREEN,
        ])
      },
    },

    {
      match: ({ deviceFilters }) => deviceFilters.capacity?.length > 0,
      action: () => {
        generateAndSetFiltersInputsOptions(applyAllFilters(), [
          FILTER_TYPES_EXCEPTIONS.MODEL,
          FILTER_TYPES_EXCEPTIONS.BRAND,
          FILTER_TYPES_EXCEPTIONS.CAPACITY,
        ])
      },
    },

    {
      match: ({ deviceFilters }) => deviceFilters.model?.length > 0,
      action: () => {
        generateAndSetFiltersInputsOptions(applyAllFilters(), [
          FILTER_TYPES_EXCEPTIONS.MODEL,
          FILTER_TYPES_EXCEPTIONS.BRAND,
          FILTER_TYPES_EXCEPTIONS.CATEGORY,
        ])
      },
    },
    {
      match: ({ deviceFilters }) => deviceFilters.brand?.length > 0,
      action: () => {
        generateAndSetFiltersInputsOptions(applyAllFilters(), [
          FILTER_TYPES_EXCEPTIONS.BRAND,
          FILTER_TYPES_EXCEPTIONS.CATEGORY,
        ])
      },
    },

    {
      match: ({ deviceFilters }) => deviceFilters.category?.length > 0,
      action: () => {
        generateAndSetFiltersInputsOptions(applyAllFilters(), [FILTER_TYPES_EXCEPTIONS.CATEGORY])
      },
    },
    {
      match: () => true,
      action: () => {
        generateAndSetFiltersInputsOptions(applyAllFilters())
      },
    },
  ]

  useEffect(() => {
    if ((!devices || !!fetchDevicesError) && !isFetchingDevices) {
      if (isAgileTvRenewal) {
        retrieveAgileTvData()
      }

      retrieveDevices()
    }
  }, [])

  useEffect(() => {
    setDevicesToShow([])
    showMoreDevices(0)
  }, [filteredDevices])

  useEffect(() => {
    if (devicesToShow.length > 0) {
      getDevicesStock(devicesToShow)
    }
  }, [devicesToShow])

  useEffect(() => {
    const parsedMinPrice = parseInt(minPrice, 10)
    const parsedMaxPrice = parseInt(maxPrice, 10)
    const isMinPriceValid = parsedMinPrice <= parsedMaxPrice || (parsedMinPrice && !parsedMaxPrice)

    if (!isMinPriceValid) {
      resetMinPriceFilter()
    }

    const filterRulesMatchProps = {
      deviceFilters: values.deviceFilters,
    }

    if (devices) {
      let i = 0
      let filterRuleAction

      while (!filterRuleAction && i < filtersRules.length) {
        const filtersRule = filtersRules[i]

        if (filtersRule.match(filterRulesMatchProps)) {
          filterRuleAction = filtersRule.action
        }

        i += 1
      }

      if (filterRuleAction) {
        filterRuleAction()
      }
    }
  }, [
    devices,
    JSON.stringify(brand),
    JSON.stringify(model),
    minPrice,
    maxPrice,
    dualSim,
    JSON.stringify(screenSize),
    JSON.stringify(capacity),
    JSON.stringify(category),
  ])

  return {
    brandOptions,
    modelOptions,
    screenSizeOptions,
    capacityOptions,
    categoryOptions,
    filteredDevices,
    fetchDevicesError,
    fetchAgileTvError: fetchAgileTvError && fetchAgileTvError !== 404,
    isFetching: isFetchingDevices || (isAgileTvRenewal && isFetchingAgile),
    agileTvData,
    isPriceFilterValid,
    resetDevicesCatalog,
    devicesToShow,
    showMoreDevices,
    canShowMoreDevices,
    devicesStock,
    retryFetchDeviceStock,
    orderFields,
    applyOrder,
  }
}
