import { chain } from 'lodash'
import { getFixedLineNumber, getSubscriptionTariffId } from 'services/subscriptions'

const findProductByFixedNumber = (products, fixedNumber) =>
  chain(products)
    .find(prod => getFixedLineNumber(prod) === fixedNumber)
    .value()

export const findTypeByFixedNumber = (products, fixedNumber) =>
  getSubscriptionTariffId(findProductByFixedNumber(products, fixedNumber))
