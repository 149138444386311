import PropTypes from 'prop-types'

import { useFormikContext } from 'formik'

import { makeStyles } from '@material-ui/styles'
import {
  CardContent,
  Box,
  Typography,
  InputAdornment,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core'

import EuroIcon from '@material-ui/icons/Euro'

import { FormikInput, ButtonLink } from 'components/ui'

import { DEVICE_RENEWAL_FIELDNAMES } from 'modules/device-renewal/constants'

import { DeviceFilterAutocomplete } from '../../components/DeviceFilterAutocomplete'

const useStyles = makeStyles({
  'price-title': {
    marginRight: '15px',
  },
  'price-filter-input': {
    width: '150px',
    marginRight: '15px',
    '& input[type="number"]': {
      '-moz-appearance': 'textField',
    },
  },
  'dual-sim-label': {
    marginLeft: '20px',
    marginRight: '15px',
  },
  'brand-model-input': {
    width: '150px',
  },
})

export const DeviceOffersFilters = ({
  brandOptions,
  modelOptions,
  screenSizeOptions,
  capacityOptions,
  classes,
  showTerminalSpecsFilters = true,
  showCategoryFilter = false,
  categoryOptions,
}) => {
  const { setFieldValue, values, handleChange, setValues } = useFormikContext()

  const filtersClasses = useStyles()

  const handleReestablishFiltersClick = () => {
    setFieldValue(DEVICE_RENEWAL_FIELDNAMES.DEVICE_BRAND_FILTER, [])
    setFieldValue(DEVICE_RENEWAL_FIELDNAMES.DEVICE_MODEL_FILTER, [])
    setFieldValue(DEVICE_RENEWAL_FIELDNAMES.DEVICE_MIN_PRICE_FILTER, '')
    setFieldValue(DEVICE_RENEWAL_FIELDNAMES.DEVICE_MAX_PRICE_FILTER, '')
    setFieldValue(DEVICE_RENEWAL_FIELDNAMES.DEVICE_DUAL_SIM_FILTER, false)
    setFieldValue(DEVICE_RENEWAL_FIELDNAMES.DEVICE_SCREEN_SIZE_FILTER, [])
    setFieldValue(DEVICE_RENEWAL_FIELDNAMES.DEVICE_CAPACITY_FILTER, [])
    setFieldValue(DEVICE_RENEWAL_FIELDNAMES.DEVICE_CATEGORY_FILTER, [])
  }

  return (
    <>
      <CardContent>
        <Box display="flex" alignItems="center" mb="16px" className={classes['section-header']}>
          <Typography component="h6" variant="body1">
            FILTROS
          </Typography>
          <ButtonLink onClick={handleReestablishFiltersClick}>Restablecer filtros</ButtonLink>
        </Box>

        <Box alignItems="center" mb="16px" className={classes['section-header']}>
          <Box display="flex" alignItems="center">
            {showCategoryFilter && (
              <Box mr="35px">
                <DeviceFilterAutocomplete
                  multiple
                  value={values.deviceFilters?.category || []}
                  options={categoryOptions}
                  onChange={newOption => {
                    setValues({
                      ...values,
                      deviceFilters: {
                        ...values.deviceFilters,
                        category: newOption,
                        brand: [],
                        model: [],
                        capacity: [],
                        screenSize: [],
                      },
                    })
                  }}
                  inputLabel="Categoria"
                />
              </Box>
            )}

            {!!brandOptions && (
              <Box mr="35px">
                <DeviceFilterAutocomplete
                  multiple
                  value={values.deviceFilters?.brand || []}
                  options={brandOptions}
                  onChange={newOption => {
                    setValues({
                      ...values,
                      deviceFilters: {
                        ...values.deviceFilters,
                        brand: newOption,
                        model: [],
                        capacity: [],
                        screenSize: [],
                      },
                    })
                  }}
                  inputLabel="Marca"
                />
              </Box>
            )}

            <Box>
              <DeviceFilterAutocomplete
                multiple
                value={values.deviceFilters?.model || []}
                options={modelOptions}
                onChange={newOption => {
                  setValues({
                    ...values,
                    deviceFilters: {
                      ...values.deviceFilters,
                      model: newOption,
                      capacity: [],
                      screenSize: [],
                    },
                  })
                }}
                inputLabel="Modelo"
              />
            </Box>
          </Box>
          {showTerminalSpecsFilters && (
            <Box display="flex" mt="24px" alignItems="center">
              <Box>
                <DeviceFilterAutocomplete
                  multiple
                  value={values.deviceFilters?.capacity || []}
                  options={capacityOptions}
                  onChange={newOption => {
                    setFieldValue(DEVICE_RENEWAL_FIELDNAMES.DEVICE_CAPACITY_FILTER, newOption)
                    setValues({
                      ...values,
                      deviceFilters: {
                        ...values.deviceFilters,
                        capacity: newOption,
                        screenSize: [],
                      },
                    })
                  }}
                  inputLabel="Memoria interna (GB)"
                />
              </Box>
              <Box ml="35px">
                <DeviceFilterAutocomplete
                  multiple
                  value={values.deviceFilters?.screenSize || []}
                  options={screenSizeOptions}
                  onChange={newOption => {
                    setFieldValue(DEVICE_RENEWAL_FIELDNAMES.DEVICE_SCREEN_SIZE_FILTER, newOption)
                  }}
                  inputLabel="Tamaño de pantalla (pulgadas)"
                />
              </Box>
            </Box>
          )}
        </Box>
        <Box display="flex" alignItems="center" mb="16px" className={classes['section-header']}>
          <Typography component="span" variant="body1" className={filtersClasses['price-title']}>
            Precio
          </Typography>
          <Box className={filtersClasses['price-filter-input']}>
            <FormikInput
              name={DEVICE_RENEWAL_FIELDNAMES.DEVICE_MIN_PRICE_FILTER}
              label="Desde"
              onlyNumbers
              endAdornment={
                <InputAdornment position="end">
                  <EuroIcon />
                </InputAdornment>
              }
            />
          </Box>
          <Box className={filtersClasses['price-filter-input']}>
            <FormikInput
              name={DEVICE_RENEWAL_FIELDNAMES.DEVICE_MAX_PRICE_FILTER}
              label="Hasta"
              onlyNumbers
              endAdornment={
                <InputAdornment position="end">
                  <EuroIcon />
                </InputAdornment>
              }
            />
          </Box>
          {showTerminalSpecsFilters && (
            <>
              <Box display="flex" alignItems="center">
                <Typography
                  component="span"
                  variant="body1"
                  className={filtersClasses['dual-sim-label']}>
                  Dual SIM
                </Typography>
                <FormControlLabel
                  key="dual-sim-filter"
                  control={
                    <Checkbox
                      key="dual-sim-filter"
                      name={DEVICE_RENEWAL_FIELDNAMES.DEVICE_DUAL_SIM_FILTER}
                      color="primary"
                      className="checkbox"
                      checked={values.deviceFilters.dualSim}
                      onChange={handleChange}
                    />
                  }
                />
              </Box>
            </>
          )}
        </Box>
      </CardContent>
    </>
  )
}

DeviceOffersFilters.propTypes = {
  brandOptions: PropTypes.array,
  modelOptions: PropTypes.array,
  classes: PropTypes.object,
  showTerminalSpecsFilters: PropTypes.bool,
  screenSizeOptions: PropTypes.array,
  capacityOptions: PropTypes.array,
  categoryOptions: PropTypes.array,
  showCategoryFilter: PropTypes.bool,
}
