import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { fetchContentfulResourceAction } from '../store/contentful.actions'
import { selectContentfulResource } from '../store/contentful.selectors'

export const useFetchContentfulResource = (resourceName, params, alias = '') => {
  const dispatch = useDispatch()
  const contentfulResource = useSelector(selectContentfulResource(alias || resourceName))

  const fetchContentfulResource = (force = false) => {
    if (!contentfulResource || force) {
      dispatch(fetchContentfulResourceAction(resourceName, params, alias))
    }
  }

  useEffect(() => {
    fetchContentfulResource()
  }, [])

  return {
    contentfulResource,
    fetchContentfulResource,
  }
}
