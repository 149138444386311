import { alarmsActionTypes } from './alarms.actions'

const alarmsInitialState = {
  data: null,
  error: null,
  loading: null,
}

export const reducer = (state = alarmsInitialState, { type, payload }) => {
  switch (type) {
    case alarmsActionTypes.CUSTOMER_FIND_ALARMS: {
      return { ...alarmsInitialState, loading: true }
    }

    case alarmsActionTypes.CUSTOMER_FIND_ALARMS_SUCCESS: {
      return { ...state, data: payload.data, loading: false, error: null }
    }

    case alarmsActionTypes.CUSTOMER_FIND_ALARMS_ERROR: {
      return {
        ...state,
        error: payload.error,
        loading: false,
      }
    }
    default:
      return state
  }
}
