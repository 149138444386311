import { connect } from 'react-redux'
import { get } from 'lodash'
import { actions as coverage, selectGescal as getGescal17NewDirection } from 'modules/Coverage'
import { appointmentActions, selectAppointmentToken } from 'modules/Appointments'
import { orderPonrInitAction, selectPonrReached } from 'modules/ponr'
import { selectPonc } from 'modules/ponc'
import {
  cancelOrderInitAction,
  updateOrderInitAction,
  getGescal17Order,
  getOrderUpdateError,
  selectURLOrderId,
} from 'modules/orders'
import { actions as wizardService, getStep, getStepLoading } from 'services/wizard'
import { selectKairosAppointmentData } from 'modules/Appointments/store/appointments.selectors'
import { AddressChange } from '../../components'
import { checksErrors } from './utils'

const mapDispatchToProps = dispatch => ({
  getAppointmentToken: () => dispatch(appointmentActions.getAppointmentTokenInit()),
  gotCoverageData: data => dispatch(coverage.gotCoverageData(data)),
  getPonr: orderId => dispatch(orderPonrInitAction(orderId)),
  cancelOrder: (orderId, data) => dispatch(cancelOrderInitAction(orderId, data)),
  updateOrder: orderId => dispatch(updateOrderInitAction(orderId)),
  checkWizard: (checks, successStep, failedStep, params = {}, resetErrors = true) =>
    dispatch(wizardService.checkInit(checks, successStep, failedStep, params, resetErrors)),
})

const mapStateToProps = state => ({
  orderId: selectURLOrderId(state),
  orders: get(state, 'orders'),
  orderStatusError: getOrderUpdateError(state),
  ponr: selectPonrReached(state),
  ponc: selectPonc(state),
  order: get(state, 'orders'),
  customer: get(state, 'orders.data'),
  coverage: get(state, 'coverage'),
  creditScoring: get(state, 'creditScoring'),
  rove: get(state, 'rove'),
  wizardError: checksErrors(state.wizard.error),
  tokenAppointment: selectAppointmentToken(state),
  coverageData: get(state, 'coverage.data'),
  currentStep: getStep(state),
  loadingStep: getStepLoading(state),
  gescal17Order: getGescal17Order(state),
  gescal17NewDirection: getGescal17NewDirection(state),
  appointmentState: 'No citada',
  newAppointmentData: selectKairosAppointmentData(state),
})
export default connect(mapStateToProps, mapDispatchToProps)(AddressChange)
