import { get } from 'lodash'

const formatIframeTypification = (iframeYypificationsData, msisdns) => {
    return iframeYypificationsData.reduce((acc, typification) => {
        let formattedTypification
        const msisdn = get(typification, 'msisdn')
  
        if(msisdns.includes(msisdn)) {
          formattedTypification = ({
          type: 'typification',
          isIframeTypification: true,
          date: get(typification, 'timestamp'),
          phone: msisdn,
          typification: {
            reason: get(typification, 'reason'),
            subReason1: get(typification, 'subreason1'),
            subReason2: get(typification, 'subreason2'),
            description: get(typification, 'description'),
            retained1: get(typification, 'result'),
            result: get(typification, 'result'),
            subresult: get(typification, 'subresult')
          },
          sfid: get(typification, 'sfid'),
          channel: get(typification, 'area'),
        })
      
        acc.push(formattedTypification)
      }
  
        return acc
      }, [])
}
export const helpers = {
    formatIframeTypification
}
