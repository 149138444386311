import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'

import { Typography } from '@material-ui/core'
import { TextStyles } from 'utils/index'

const useStyles = makeStyles({
  'sidebar-title': {
    marginBottom: '6px',
    opacity: 0.6,
  },
})

const TitleSidebar = ({ children, dataHook }) => {
  const classes = useStyles()

  return (
    <Typography
      {...TextStyles.labelDark({
        className: classes['sidebar-title'],
      })}
      data-hook={dataHook}>
      {children}
    </Typography>
  )
}

TitleSidebar.propTypes = {
  children: PropTypes.node.isRequired,
  dataHook: PropTypes.string,
}

export default TitleSidebar
