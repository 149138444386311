import { createStructuredSelector } from 'reselect'
import { selectCanIDO, permissions } from 'modules/Permissions'
import { getIdentificationId } from 'services/customer-360/selectors'
import { D2D_ADDITIONAL_ENERGY_ACCESS } from 'modules/Permissions/constants'
import { selectIsPosOrMasProximo } from 'modules/Permissions/store/permissions-selectors'

import {
  selectEnergyContractsIsLoading,
  selectEnergyContractsError,
  selectEnergyContractsData,
} from '../../store/energy.selectors'

export const clientEnergyContainerSelectors = createStructuredSelector({
  permissionsGoEnergyCRM: selectCanIDO(permissions.energy_go_crm.id, D2D_ADDITIONAL_ENERGY_ACCESS),
  permissionsViewContracts: selectCanIDO(
    permissions.energy_view_contracts.id,
    D2D_ADDITIONAL_ENERGY_ACCESS,
  ),
  documentId: getIdentificationId,
  isLoading: selectEnergyContractsIsLoading,
  error: selectEnergyContractsError,
  contracts: selectEnergyContractsData,
  isPosOrMasproximo: selectIsPosOrMasProximo,
})
