import { constants } from './constants'

const findInit = customerId => ({
  type: constants.FIND_INIT,
  payload: {
    customerId,
  },
})

const findSuccess = data => ({
  type: constants.FIND_SUCCESS,
  payload: {
    data,
  },
})

const findFailed = error => ({
  type: constants.FIND_FAILED,
  payload: { error },
})

const reset = () => ({
  type: constants.RESET,
})

const setSubscriptionAction = payload => ({
  type: constants.SET_SUBSCRIPTION,
  payload,
})

const fetchVipType = documentId => ({
  type: constants.FETCH_VIP_TYPE,
  payload: { documentId },
})

const fetchVipTypeSuccess = data => ({
  type: constants.FETCH_VIP_TYPE_SUCCESS,
  payload: { data },
})

const fetchVipTypeFailed = error => ({
  type: constants.FETCH_VIP_TYPE_FAILED,
  payload: { error },
})

export const actions = {
  findInit,
  findSuccess,
  findFailed,
  reset,
  setSubscriptionAction,
  fetchVipType,
  fetchVipTypeSuccess,
  fetchVipTypeFailed,
}
