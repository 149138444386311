import styled from 'styled-components'
import { Accordion } from './Accordion'

export const StyledAccordion = styled(Accordion)`
  position: relative;

  & .spinner {
    position: absolute;
    top: ${({ expanded }) => (expanded ? '12px' : '2px')};
    left: 200px;
  }

  & .accordion-panel {
    box-shadow: none;
  }

  & .details {
    padding: 0;
  }

  &::before {
    opacity: 0;
  }

  & .summary {
    padding: 0;
  }

  & .summary > div[role='button'] {
    position: absolute;
    left: 160px;
    right: unset;
  }
`
