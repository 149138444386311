export const STATUS_ACTIVE = 'active'
export const STATUS_RETIRED = 'retired'
export const STATUS_HIDDEN = 'hidden'

export const CATEGORY_2P_V3 = 'fixed_line'
export const CATEGORY_2P_PURE = CATEGORY_2P_V3
export const CATEGORY_2P = 'fixed_only'
export const CATEGORY_2P_FREE_LINE = 'fixed_with_free_line'
export const CATEGORY_MOBILE = 'mobile_only'
export const CATEGORY_MOBILE_V3 = 'mobile_line'
export const CATEGORY_CONVERGENT = 'convergent'
export const TARIFF_CATEGORY_3P = 'convergent,mobile_only,fusion'

export const TYPE_EXTRA_LINE = 'extra_line'

export const POSTPAID = 'postpaid'
export const PREPAID = 'prepaid'

export const ADSL = 'adsl'
export const FIBER = 'fiber'
export const NEBA = 'NEBA'
export const VULA = 'VULA'
export const INDIRECT_FIBER = 'indirect_fiber'

export const NEBA_INFIX = 'NB'
export const VULA_SUFIX = ['01', '03']

export const RESIDENTIAL_SUFIX = 'R'
export const SMSPrice = 0.1
export const NEBA_AUTHORIZED_SFIDS_PREFIX = ['AO', 'KS', 'AB']

export const VULA_100MB_FIBER = [
  'CONTFHE015GNR01',
  'CONTFHE999GNTR03',
  'CONTFHE020GNP01',
  'CONTFHE999GNTP03',
  'CONTFHE005GNP03',
  'CONTFHE003GNR03',
  'CONTFHE999GNHR01',
]
export const NEBA_100MB_FIBER = [
  'CONTFHE015GNR',
  'CONTFHE999GNTR02',
  'CONTFHE020GNP',
  'CONTFHE999GNTP02',
  'CONTFHE005GNP02',
  'CONTFHE003GNR02',
  'CONTFHE999GNHR',
]

export const TARIFFS_WITH_OLD_PORTFOLIO_PERMISSION = [
  'CONTFHF999GTR02',
  'CONTFHF999GNTR02',
  'CONTFHF999GHR',
  'CONTFHF999GNHR',
]

export const NEBA_AUTHORIZED_SFIDS = [
  '_F',
  '_RPORT',
  '_RBAJA',
  'natalia',
  'MCN01001_FCA',
  'MCN01002_FCA',
  'MCN01003_FCA',
  'MCN01004_FCA',
  'MCN01005_FCA',
  'MCN01006_FCA',
  'MCN01007_FCA',
  'MCN01008_FCA',
  'MCN01009_FCA',
  'MCN01010_FCA',
  'MCN01011_FCA',
  'MCN01012_FCA',
  'MCN01013_FCA',
  'MCN01014_FCA',
  'MCN01015_FCA',
  'MCN01016_FCA',
  'MCN01017_FCA',
  'MCN01018_FCA',
  'MCN01019_FCA',
  'MCN01020_FCA',
  'MCN01021_FCA',
  'MCN01022_FCA',
  'MCN01023_FCA',
  'ESN01037_FCA',
  'ESN01045_FCA',
  'ESN01046_FCA',
  'ESN01047_FCA',
  'ESN01048_FCA',
  'ESN01050_FCA',
  'ESN01038_FCA',
  'ESN01039_FCA',
  'ESN01027_FCA',
  'ESN01040_FCA',
  'ESN01041_FCA',
  'ESN01042_FCA',
  'ESN01043_FCA',
  'ESN01012_FCA',
  'ESN01029_FCA',
  'ESN01007_FCA',
  'ESN01026_FCA',
  'ESN01032_FCA',
  'ESN01051_FCA',
  'ESN01052_FCA',
  'ESN01053_FCA',
  'ESN01054_FCA',
  'ESN01055_FCA',
  'ESN01056_FCA',
  'ESN01057_FCA',
  'ESN01058_FCA',
  'ESN01059_FCA',
  'ESN01060_FCA',
  'ESN01061_FCA',
  'VCN01001_FCA',
  'VCN01002_FCA',
  'VCN01003_FCA',
  'VCN01004_FCA',
  'VCN01005_FCA',
  'VCN01006_FCA',
  'VCN01007_FCA',
  'VCN01008_FCA',
  'KIN01001_CA',
  'KIN01002_CA',
  'KIN01003_CA',
  'KIN01004_CA',
  'KIN01005_CA',
  'KIN01006_CA',
  'KIN01007_CA',
  'KIN01011_CA',
  'KIN01013_CA',
  'KIN01014_CA',
  'KIN01016_CA',
  'KIN01017_CA',
  'KIN01018_CA',
  'KIN01019_CA',
  'KIN01020_CA',
  'KIN01021_CA',
  'KIN01023_CA',
  'KIN01024_CA',
  'KIN01025_CA',
  'KIN01026_CA',
  'KIN01031_CA',
  'KIN01034_CA',
  'KIN01036_CA',
  'KIN01038_CA',
  'KIN01039_CA',
  'KIN01040_CA',
  'MIN01002_IN',
  'MIN01003_IN',
  'MIN01004_IN',
  'MIN01010_IN',
  'MIN01011_IN',
  'MIN01013_IN',
  'MIN01018_IN',
  'MIN01020_IN',
  'VCN01001_FCA',
  'VCN01002_FCA',
  'VCN01003_FCA',
  'VCN01004_FCA',
  'VCN01005_FCA',
  'VCN01006_FCA',
  'VCN01007_FCA',
  'VCN01008_FCA',
  'VCN01009_FCA',
  'VCN01010_FCA',
  'VCN01011_FCA',
  'VCN01012_FCA',
  'VCN01013_FCA',
  'VCN01014_FCA',
  'VON01015_CA',
  'VON01016_CA',
  'VON01017_CA',
  'VON01018_CA',
  'VON01019_CA',
  'VON01020_CA',
  'VON01021_CA',
]

export const NEBA_CANCELLATIONS_AUTHORIZED_SFIDS_PREFIX = ['MB', 'BO']

export const PRO_TARIFF_SEGMENT = 'pro'
