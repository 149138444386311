import { useState, useEffect } from 'react'

import { get, find } from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import { push } from 'connected-react-router'

import { SpinnerCenter } from 'components/ui'

import { fetchContentfulResourceAction } from 'modules/contentful'
import { resetCancelOrdersAction, selectURLOrderId } from 'modules/orders'
import { selectRouterSearch } from 'modules/Router'

import { CancelOrders } from '../../components'
import { selectOrderCancellationData } from './CancelOrdersContainer.selectors'
import { CANCEL_OPERATION, RETAIN_OPERATION } from '../../constants/cancellations'

export const CancelOrdersContainer = () => {
  const routeOrderId = useSelector(selectURLOrderId)
  const routeSearch = useSelector(selectRouterSearch)

  const {
    cancelOptions,
    retainOptions,
    orderId,
    personalData,
    isElFijoCancellation,
    penalties,
    orders,
    isSaving,
    hasError,
    canceledOrders,
    isSuccess,
  } = useSelector(selectOrderCancellationData)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchContentfulResourceAction('cancellationReasonOptions', { brands: 'yoigo' }))
    dispatch(fetchContentfulResourceAction('retentionReasonOptions', { brands: 'yoigo' }))

    return () => {
      dispatch(resetCancelOrdersAction())
    }
  }, [])

  useEffect(() => {
    if (!orderId) {
      dispatch(push(`/clients/orders/${routeOrderId}/actions?${routeSearch}`))
    }
  }, [orderId])

  const [currentStep, setCurrentStep] = useState(0)
  const [isModalOpened, setIsModalOpened] = useState(false)
  const [email, setEmail] = useState({
    value: '',
    isValid: false,
    isChecked: false,
  })
  const [callReason, setCallReason] = useState('')
  const [retainReason, setRetainReason] = useState('')
  const [cancelReason, setCancelReason] = useState({
    value: '',
    options: [],
  })

  const [operationType, setOperationType] = useState(null)

  const onRetain = () => {
    setOperationType(RETAIN_OPERATION)
    setCurrentStep(1)
    setRetainReason('')
  }

  const onCancel = () => {
    setOperationType(CANCEL_OPERATION)
    setCurrentStep(1)
    setCancelReason({ ...cancelReason, value: '' })
  }

  const onSelectCallReason = reason => {
    const cancelReasons = get(find(cancelOptions, { text: reason }), 'subreason')
    setCallReason(reason)
    setCancelReason({ value: '', options: cancelReasons })
  }

  const onSelectCancelReason = (value = '') => {
    setCancelReason({ ...cancelReason, value })
  }

  const onContinue = () => {
    setCurrentStep(currentStep + 1)

    setEmail({
      ...email,
      isChecked: false,
      isValid: false,
    })
  }

  const showModal = show => {
    setIsModalOpened(show)
  }

  const setEmailField = properties => {
    setEmail({
      ...email,
      ...properties,
    })
  }

  const onInputEmail = (isValid, value) => {
    setEmailField({
      value: isValid ? value : '',
      isValid,
    })
  }

  const onWizardGoBack = () => {
    setCurrentStep(currentStep - 1)
    setEmailField({ isChecked: false })

    if (currentStep === 1) {
      setOperationType(null)
    }
  }

  const props = {
    cancelOptions,
    retainOptions,
    orderId,
    personalData,
    isElFijoCancellation,
    currentStep,
    isModalOpened,
    setIsModalOpened,
    email,
    setEmail,
    callReason,
    setCallReason,
    retainReason,
    setRetainReason,
    cancelReason,
    setCancelReason,
    onSelectCallReason,
    onSelectCancelReason,
    onRetain,
    onCancel,
    onContinue,
    showModal,
    setEmailField,
    onInputEmail,
    onWizardGoBack,
    operationType,
    penalties,
    orders,
    isSaving,
    hasError,
    canceledOrders,
    isSuccess,
  }

  return (
    <>
      {orderId && orders && <CancelOrders {...props} />}
      {!orders && <SpinnerCenter />}
    </>
  )
}
