import PropTypes from 'prop-types'

import { Box, Typography } from '@material-ui/core'

import { Callout } from 'components/ui'

import { InputICC } from '../InputICC'

export function PortabilityPrepaid({
  fieldNames,
  hasICCNumber,
  className,
  isMobileOnly,
  isLeadFilled,
}) {
  return hasICCNumber ? (
    <Box my="4px" px="24px" width="100%" className={`${className}`}>
      <Callout>
        <Box display="flex" alignItems="center" width="100%">
          <Typography variant="body1" component="label" className="p-r-36">
            Introduce el ICC de la tarjeta donante
          </Typography>

          <Box className="icc-wrapper" width="45%">
            <InputICC
              fieldNames={fieldNames}
              isPortability
              isMobileOnly={isMobileOnly}
              isLeadFilled={isLeadFilled}
            />
          </Box>
        </Box>
      </Callout>
    </Box>
  ) : (
    <></>
  )
}

PortabilityPrepaid.propTypes = {
  fieldNames: PropTypes.object,
  hasICCNumber: PropTypes.bool,
  isMobileOnly: PropTypes.bool,
  isLeadFilled: PropTypes.bool,
  className: PropTypes.string,
}
