import { useState } from 'react'
import { CircularProgress, Box } from '@material-ui/core'
import PropTypes from 'prop-types'
import { ButtonLink } from 'components/ui'
import {
  selectIsCampaigns,
  selectSvaHomeGoFlag,
  selectSvaSmartHomeFlag,
} from 'services/feature-flag/selectors'
import { useSelector } from 'react-redux'
import { selectIsSubscriptionQueryParam } from 'modules/Router/store/index'
import { LINE_TYPE } from 'modules/Lines/constants'
import { ModalCampaigns } from '../Campaigns'

export function TariffPickerActions({
  disableChangeTariff,
  onClickChangeTariff,
  onClickAddBundles,
  disableBundles,
  isMobile,
  isPro,
  isLoadingBundles,
  shouldShowAddBundles,
  disableProSVAs,
  onClickAddProSVAs,
  fieldNames,
  selectedTariff,
  lineType,
  disableCampaignButton,
  disableOfferEdit,
}) {
  const isCampaigns = useSelector(selectIsCampaigns)
  const isSubscription = useSelector(selectIsSubscriptionQueryParam)
  const isSvaSmartHomeFlag = useSelector(selectSvaSmartHomeFlag)
  const isSvaHomeGoFlag = useSelector(selectSvaHomeGoFlag)
  const [showAddCampaign, setShowAddCampaign] = useState(false)
  return (
    <Box display="flex">
      <ButtonLink
        disabled={disableChangeTariff || disableOfferEdit}
        data-hook="change-tariff"
        className="m-l-16"
        onClick={onClickChangeTariff}>
        Cambiar la tarifa
      </ButtonLink>

      {shouldShowAddBundles && (
        <>
          {(!isLoadingBundles && (
            <ButtonLink
              data-hook="add-bundles"
              className="m-l-16"
              type="button"
              onClick={onClickAddBundles}
              disabled={disableBundles || disableOfferEdit}>
              {isMobile || !!isSubscription ? 'Bonos' : 'Bonos Cabecera'}
            </ButtonLink>
          )) || <CircularProgress size="16px" />}
        </>
      )}

      {((lineType === LINE_TYPE.LANDLINE && (isPro || isSvaSmartHomeFlag)) ||
        (lineType === LINE_TYPE.MAIN_MOBILE && isSvaHomeGoFlag)) && (
        <ButtonLink
          dataHook="add-pro-sva"
          className="m-l-16"
          disabled={disableProSVAs || disableOfferEdit}
          onClick={onClickAddProSVAs}>
          Añadir SVAs
        </ButtonLink>
      )}

      {isCampaigns && (
        <ButtonLink
          disabled={disableCampaignButton}
          dataHook="add-campaign"
          className="m-l-16"
          onClick={() => {
            setShowAddCampaign(!showAddCampaign)
          }}>
          Añadir campaña
        </ButtonLink>
      )}

      <ModalCampaigns
        isOpen={showAddCampaign}
        tariff={selectedTariff}
        fieldNames={fieldNames}
        lineType={lineType}
        closeModal={() => setShowAddCampaign(false)}
      />
    </Box>
  )
}

TariffPickerActions.propTypes = {
  disableChangeTariff: PropTypes.bool,
  onClickChangeTariff: PropTypes.func,
  onClickAddBundles: PropTypes.func,
  disableBundles: PropTypes.bool,
  isMobile: PropTypes.bool,
  isPro: PropTypes.bool,
  isLoadingBundles: PropTypes.bool,
  shouldShowAddBundles: PropTypes.bool,
  disableProSVAs: PropTypes.bool,
  disableOfferEdit: PropTypes.bool,
  onClickAddProSVAs: PropTypes.func,
  fieldNames: PropTypes.object,
  selectedTariff: PropTypes.object,
  lineType: PropTypes.string,
  disableCampaignButton: PropTypes.bool,
}
