import { call, put, takeEvery, select } from 'redux-saga/effects'
import { get } from 'lodash'
import { logError, formatErrorMessage } from 'services/logging'
import { axiosFactory } from 'modules/axios'
import { actions } from './actions'
import { constants } from './constants'
import { selectDiscountsToUpdate } from './selectors'
import { getDiscounts, putDiscounts } from './api'

export function* getDiscountsSaga({ payload: { orderId } }) {
  try {
    yield put(actions.getDiscounts())
    const data = yield call(getDiscounts, orderId)
    const discounts = get(data, 'orderDiscounts', [])
    yield put(actions.getDiscountsSuccess(discounts))
  } catch (e) {
    yield call(logError, { e: new Error(formatErrorMessage(e)), orderId })
    const error = axiosFactory.buildError(e)
    yield put(actions.getDiscountsFailed(error))
  }
}

export function* watchGetDiscounts() {
  yield takeEvery(constants.GET_DISCOUNTS_INIT, getDiscountsSaga)
}

export function* putDiscountsSaga({ payload: { orderId } }) {
  try {
    yield put(actions.putDiscounts())
    const discounts = yield select(selectDiscountsToUpdate)
    const response = yield call(putDiscounts, orderId, discounts)
    yield put(actions.putDiscountsSuccess(response || 200))
  } catch (e) {
    yield call(logError, { e: new Error(formatErrorMessage(e)), orderId })
    const error = axiosFactory.buildError(e)
    yield put(actions.putDiscountsFailed(error))
  }
}

export function* watchPutDiscounts() {
  yield takeEvery(constants.PUT_DISCOUNTS_INIT, putDiscountsSaga)
}
