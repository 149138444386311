import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useFormikContext } from 'formik'

import { setBasketData } from 'modules/NewClientSales/store'
import PropTypes from 'prop-types'
import { Button } from 'components/ui'
import { useHistory } from 'react-router-dom'

export function BackButton(props) {
  const history = useHistory()
  const dispatch = useDispatch()

  const saveFormikData = useCallback(data => {
    dispatch(setBasketData(data))
  })

  const { values } = useFormikContext()

  function goBack(e) {
    const params = history.location.search
    saveFormikData(values)

    history.push(`${props.goTo}${params}`, { goingBack: true })

    if (props.onClick) {
      props.onClick(e)
    }
  }
  return (
    <Button
      type="button"
      secondary
      style={{ marginLeft: props.nomargin ? '0' : '16px' }}
      data-hook="back-button-sales"
      onClick={goBack}>
      ATRÁS
    </Button>
  )
}

BackButton.propTypes = {
  goTo: PropTypes.string,
  nomargin: PropTypes.any,
  onClick: PropTypes.func,
}
