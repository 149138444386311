import { useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { FormikInput } from 'components/ui'
import { makeStyles } from '@material-ui/styles'
import { Checkbox, FormControlLabel } from '@material-ui/core'

import { setSameEmailSVA, selectSameEmail } from '../../store'

export function SVAEmailInput({ fieldNames, onChangeSVAEmail, emailVal, validateAgileTvEmail }) {
  const isSelected = useSelector(selectSameEmail)
  const dispatch = useDispatch()
  const [email, setEmail] = useState(emailVal)

  function onChangeEmail(value) {
    setEmail(value)
    onChangeSVAEmail(value, isSelected)
  }

  function onChangeCheckbox() {
    const emailValue = !isSelected ? email : ''
    onChangeSVAEmail(emailValue, true)
    dispatch(setSameEmailSVA(!isSelected))
  }

  const useStyles = makeStyles({
    svaEmail: {
      minHeight: '100px',
      minWidth: '240px',
      flex: 1,
    },
    svaEmailCheckbox: {
      flex: 2,
    },
  })

  const classes = useStyles()

  return (
    <div className="flex">
      <div className={`${classes.svaEmail} p-r-32`}>
        <FormikInput
          data-hook={fieldNames.SVA_EMAIL}
          onChange={onChangeEmail}
          validate={value => validateAgileTvEmail(value, fieldNames.SVA)}
          name={fieldNames.SVA_EMAIL}
          label="Email para la suscripción"
          fullWidth
          trim
        />
      </div>
      <FormControlLabel
        className={`${classes.svaEmailCheckbox} label`}
        control={
          <Checkbox
            data-hook="sva-email-checkbox"
            color="primary"
            className="checkbox"
            checked={isSelected}
            onChange={onChangeCheckbox}
          />
        }
        label="Usar también como email de contacto"
      />
    </div>
  )
}

SVAEmailInput.propTypes = {
  fieldNames: PropTypes.object.isRequired,
  onChangeSVAEmail: PropTypes.func.isRequired,
  emailVal: PropTypes.string,
  validateAgileTvEmail: PropTypes.func,
}
