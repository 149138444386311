const GET_DISCOUNTS_INIT = 'OVID/DISCOUNTS/GET_DISCOUNTS_INIT'
const GET_DISCOUNTS = 'OVID/DISCOUNTS/GET_DISCOUNTS'
const GET_DISCOUNTS_SUCCESS = 'OVID/DISCOUNTS/GET_DISCOUNTS_SUCCESS'
const GET_DISCOUNTS_FAILED = 'OVID/DISCOUNTS/GET_DISCOUNTS_FAILED'
const SELECT_DISCOUNT = 'OVID/DISCOUNTS/SELECT_DISCOUNT'
const RESET_DISCOUNTS = 'OVID/DISCOUNTS/RESET_DISCOUNTS'
const PUT_DISCOUNTS_INIT = 'OVID/DISCOUNTS/PUT_DISCOUNTS_INIT'
const PUT_DISCOUNTS = 'OVID/DISCOUNTS/PUT_DISCOUNTS'
const PUT_DISCOUNTS_SUCCESS = 'OVID/DISCOUNTS/PUT_DISCOUNTS_SUCCESS'
const PUT_DISCOUNTS_FAILED = 'OVID/DISCOUNTS/PUT_DISCOUNTS_FAILED'

export const constants = {
  GET_DISCOUNTS_INIT,
  GET_DISCOUNTS,
  GET_DISCOUNTS_SUCCESS,
  GET_DISCOUNTS_FAILED,
  SELECT_DISCOUNT,
  RESET_DISCOUNTS,
  PUT_DISCOUNTS_INIT,
  PUT_DISCOUNTS,
  PUT_DISCOUNTS_SUCCESS,
  PUT_DISCOUNTS_FAILED,
}
