import { useState } from 'react'
import PropTypes from 'prop-types'

import { Icon, Typography } from '@material-ui/core'
import { TextStyles } from 'utils/text'

import { Modal, ModalActions, Button } from 'components/ui'

export function HeaderLogoutModal({ onClickPowerButton }) {
  const [isOpen, setIsOpen] = useState()
  function onConfirm() {
    setIsOpen(false)
    onClickPowerButton()
  }
  return (
    <>
      <Icon onClick={() => setIsOpen(true)}>power_settings_new_icon</Icon>
      <Modal isOpen={isOpen} title="¿Seguro que quieres salir?">
        <div className="flex">
          <Typography
            {...TextStyles.paragraphDark({
              className: 'm-r-4',
            })}>
            Vas a abandonar la aplicación,{' '}
          </Typography>
          <Typography {...TextStyles.subtitle2Dark()}> ¿estás seguro?</Typography>
        </div>

        <ModalActions>
          <Button margin="4px 16px 4px 0px" onClick={onConfirm}>
            SI, SALIR DE LA APLICACIÓN
          </Button>
          <Button secondary onClick={() => setIsOpen(false)}>
            NO, ME QUEDO
          </Button>
        </ModalActions>
      </Modal>
    </>
  )
}

HeaderLogoutModal.propTypes = {
  onClickPowerButton: PropTypes.func.isRequired,
}
