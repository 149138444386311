import uuidv4 from 'uuid'

import CheckoutGenerator from 'services/checkout-order-generator/CheckoutGenerator'
import { addIndividualCustomerToParty } from 'services/checkout-order-generator/checkoutGenerator.helpers'

const addCustomerToAccount = (customerAccountInformation, checkoutAccount) => {
  const party = checkoutAccount.createParty({ role: 'customer' })
  addIndividualCustomerToParty({ party, ...customerAccountInformation })
}

export const generateOrder = ({
  salesInfo,
  allOrders,
  devicePaymentInfo,
  deviceShipping,
  customerAccountInformation,
  billingAccount,
}) => {
  const basket = new CheckoutGenerator({ offersMetadata: [] })

  basket.setSalesInfo(salesInfo)

  let billing
  allOrders.forEach(({ rootOrderItem, id, orderChildren }, index) => {
    const order = basket.createOrder(id)
    order.setRequestedStartDateTime(rootOrderItem)

    order.setRootOrderItem(rootOrderItem)

    if (index === 0) {
      order.addInstallationMedium(deviceShipping)
      if (billingAccount) {
        billing = order.addBillingAccount(billingAccount)
      }

      if (!devicePaymentInfo.isFree) {
        basket.setTerminalPaymentInfo(devicePaymentInfo, id)
      }
    }

    orderChildren.forEach(child => {
      const idChild = `order-child-${uuidv4()}`
      const orderChild = order.createOrderChild(idChild)

      orderChild.setRootOrderItem(child)
    })
  })

  const account = basket.createCustomerAccount({
    type: 'customer-accounts',
    name: 'default',
  })

  addCustomerToAccount(customerAccountInformation, account)
  if (billing) {
    account.addBillingAccountObject(billing)
  }

  return basket.result
}
