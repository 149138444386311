import { Typography } from '@material-ui/core'

import { TextStyles } from 'utils/text'

import { ActionAdvice } from 'components/ui'
import { formatFee } from 'utils'
import { get } from 'lodash'
import LineItemCard from 'components/packages/ui/card360/LineItemCard'

export const FutureFATs = ({ futureMonthFATs }) => {
  return (
    futureMonthFATs &&
    futureMonthFATs.map(fat => {
      const FATId = get(fat, 'air_time.air_time_group_id')
      const FATamount = get(fat, 'air_time.amount', 0)
      const dateFATFormat = get(fat, 'air_time.period')
      const dateFATFormatter = date => `${date.slice(0, 1)}/20${date.slice(2, 4)}`
      return (
        <div className="p-x-24 p-y-12">
          <ActionAdvice
            id={FATId}
            message={
              <>
                <LineItemCard
                  className="fat-line-item-card"
                  fieldsApart
                  primaryText={`FAT ${FATId} `}
                  secondaryText={`${formatFee(FATamount * 0.1)}`}
                />
                <Typography {...TextStyles.labelDark()} className="xs-text">
                  ({dateFATFormatter(dateFATFormat)})
                </Typography>
              </>
            }
            block
          />
        </div>
      )
    })
  )
}
