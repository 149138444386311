import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { get } from 'lodash'

import { ActionAdvice } from 'components/ui'

import { getSegment, selectors as customerSelectors } from 'services/customer-360/selectors'
import { isProCustomer } from 'services/customer-360/helpers'
import { applyTax, getTax } from 'services/taxes'

import {
  isFixedDiscountUnit,
  formatDiscountValue,
  formatDuration,
  is100MbAnd15Gb,
  is100MbAnd40Gb,
  isFiveEurosConvergentDiscount,
} from '../helpers'

import { UNLIMITED_DURATIONS } from '../constants'

const DiscountAdvice = ({
  discountCode,
  monthPermanency,
  name,
  value,
  unit,
  duration,
  permanency,
  hasConflict,
  onUpdate,
  onDelete,
  onCancel,
  block = false,
}) => {
  const customerSegment = useSelector(getSegment)
  const taxNeeded = !isProCustomer(customerSegment) && isFixedDiscountUnit(unit)
  const billingAddress = useSelector(state => customerSelectors.getBillingAddress(state))
  const postCode = get(billingAddress, 'postCode')

  const tax = (taxNeeded && useSelector(state => getTax(state, postCode))) || 0
  const is100MbDiscount = is100MbAnd15Gb(discountCode, value) || is100MbAnd40Gb(discountCode, value)
  const isLoyaltyConvergentDiscount = isFiveEurosConvergentDiscount(discountCode)
  const items = [
    { label: 'Dto aplicado', value: formatDiscountValue({ value: applyTax(tax, value), unit }) },
  ]

  if (duration) {
    if (!UNLIMITED_DURATIONS.includes(duration)) {
      items.push({ label: 'Duración', value: formatDuration(duration) })
    } else if (is100MbDiscount || isLoyaltyConvergentDiscount) {
      items.push({ label: 'Duración', value: 'Ilimitado' })
    }
  }

  if (duration && permanency) {
    if (is100MbDiscount) {
      items.push({ label: 'Permanencia', value: `Sí (${monthPermanency} meses)` })
    } else {
      items.push({ label: 'Permanencia', value: `Sí (${duration} meses)` })
    }
  }

  return (
    <ActionAdvice
      id="discount-bonuses-modified"
      items={items}
      type={hasConflict ? 'error' : 'info'}
      message={name}
      onUpdate={onUpdate}
      onDelete={onDelete}
      onCancel={onCancel}
      block={block}
    />
  )
}

DiscountAdvice.propTypes = {
  discountCode: PropTypes.string,
  monthPermanency: PropTypes.number,
  value: PropTypes.number.isRequired,
  unit: PropTypes.string.isRequired,
  duration: PropTypes.number,
  permanency: PropTypes.string,
  name: PropTypes.string,
  hasConflict: PropTypes.bool,
  onDelete: PropTypes.func,
  onUpdate: PropTypes.func,
  onCancel: PropTypes.func,
  block: PropTypes.bool,
}

DiscountAdvice.defaultProps = {
  name: '',
}

export default DiscountAdvice
