import { axiosJWT } from 'modules/axios'

import { CONFIG } from './environments'

export const user = {
  key: Math.random().toString(36).substring(7),
  anonymous: true,
}

export const readAll = () =>
  axiosJWT({
    method: 'GET',
    url: `${CONFIG.api.flags}/readAll`,
  }).then(resp => resp.data)
