import { get, isEmpty, head } from 'lodash'

import { formatFee } from 'utils'
import {
  getFeesPendingFromPermanent,
  getMonthlyFeesAmountLeftToPayFromFinancials,
  getTotalFeesToPayFromFinancials,
  getFeesToPayFromFinancialsDescriptions,
  getFees25WithDiscountFromFinancials,
  getCancellationCostsLeftFromFinancials,
} from 'services/subscriptions'

import {
  getTerminalsFinancials,
  findMobilePermantsByEndTimeValidation,
} from 'services/subscriptions/helpers'

import { startOfDay, differenceInDays } from 'date-fns'
import {
  SALE_CHANNEL_RETENTION,
  SALE_CHANNEL_RENEWAL,
  SALE_CHANNEL_CTC,
  DEVICE_TYPE_TERMINAL,
  DEVICE_TYPE_AGILE_TV,
  DEVICE_TYPE_WITHOUT_IMEI,
  penaltyDaysLowerLimit,
  penaltyDaysUpperLimit,
  quota25DaysLowerLimit,
  quota25DaysUpperLimit,
  FEES_DECISION_KEEP_FINANCING,
  FINAL_PAYMENT_DECISION_FINANCING,
} from '../constants'

export const getSidebarDeviceType = basketDeviceType => {
  switch (basketDeviceType) {
    case DEVICE_TYPE_TERMINAL:
      return 'Terminal'
    case DEVICE_TYPE_AGILE_TV:
      return 'Agile TV + Apple Tv'
    case DEVICE_TYPE_WITHOUT_IMEI:
      return 'Otro'
    default:
      return '-'
  }
}

export const getSidebarSaleChannel = basketSaleChannel => {
  switch (basketSaleChannel) {
    case SALE_CHANNEL_CTC:
      return 'Genérico'
    case SALE_CHANNEL_RENEWAL:
      return 'Fidelización'
    case SALE_CHANNEL_RETENTION:
      return 'Retención'
    default:
      return '-'
  }
}

export const getDevicePenalty = device => ({
  pending: getFeesPendingFromPermanent(device?.permanent),
  total: get(device?.permanent, 'fees.penalty', 0),
  startDate: new Date(device?.permanent.validFor.startDateTime),
  endDate: new Date(device?.permanent.validFor.endDateTime),
})

export const getDeviceFee = device => {
  if (device?.residualLength && device?.fees) {
    return {
      pending: getMonthlyFeesAmountLeftToPayFromFinancials(device),
      total: getTotalFeesToPayFromFinancials(device),
      months: getFeesToPayFromFinancialsDescriptions(device),
    }
  }
  return null
}

export const doesDeviceFinalPaymentExists = device => {
  let doesFinalPaymentExists = false

  if (device?.fees) {
    const finalPayment = getFees25WithDiscountFromFinancials(device)
    doesFinalPaymentExists = !!finalPayment && finalPayment > 0
  }

  return doesFinalPaymentExists
}

export const getDeviceFinalPayment = (
  device,
  finalPaymentDiscountAmount,
  isFinalPaymentDiscountApplicable,
) => {
  let deviceFinalPayment

  if (device?.fees) {
    const finalPayment = getFees25WithDiscountFromFinancials(device)

    if (finalPayment) {
      const finalPaymentDiscount =
        finalPayment && finalPaymentDiscountAmount && isFinalPaymentDiscountApplicable
          ? finalPaymentDiscountAmount
          : 0

      deviceFinalPayment = {
        amount: finalPayment - finalPaymentDiscount >= 0 ? finalPayment - finalPaymentDiscount : 0,
        total: finalPayment,
        date: new Date(device?.validFor.endDateTime),
      }
    }
  }
  return deviceFinalPayment
}

export const getDeviceCancelationToday = device => {
  if (device?.fees && device?.residualLength) {
    return {
      amount: getCancellationCostsLeftFromFinancials(device),
    }
  }
  return null
}

export const getDeviceResidualPenaltyDays = penalty => {
  let residualPenaltyDays = 9999
  const todayDate = startOfDay(Date.now())
  if (penalty?.endDate) {
    residualPenaltyDays = differenceInDays(penalty.endDate, todayDate)
  }
  return residualPenaltyDays
}

export const getShouldAmendPenalty = (penalty, residualPenaltyDays) => {
  return penalty && residualPenaltyDays < penaltyDaysLowerLimit
}

export const getShouldLetDecideIfAmendPenalty = (penalty, residualPenaltyDays) => {
  return (
    penalty &&
    residualPenaltyDays > penaltyDaysLowerLimit &&
    residualPenaltyDays <= penaltyDaysUpperLimit
  )
}

export const getShouldAllowFinancingDecision = (fees, residualPenaltyDays) => {
  return (
    fees &&
    residualPenaltyDays > penaltyDaysLowerLimit &&
    residualPenaltyDays <= penaltyDaysUpperLimit
  )
}

export const getShouldMantainFinancing = (fees, residualPenaltyDays) =>
  fees && residualPenaltyDays <= penaltyDaysLowerLimit

export const getShouldAllowFinalPaymentDecision = (quota25ExtensionStatus, residualPenaltyDays) =>
  quota25ExtensionStatus === 'allowed' &&
  residualPenaltyDays >= quota25DaysLowerLimit &&
  residualPenaltyDays <= quota25DaysUpperLimit

export const getFeesToPayFromFinancedDevice = financials => {
  return {
    monthlyFee: formatFee(get(financials, 'fees.monthly', 0)),
    numberOfMonths: get(financials, 'residualLength', 0),
  }
}

export const getFinancedDeviceSummaryInfo = (
  deviceName,
  penalty,
  cancelationToday,
  finalPayment,
  financedDeviceDecisions,
  financials,
) => {
  const { monthlyFee, numberOfMonths } = getFeesToPayFromFinancedDevice(financials)

  const { feesPaymentType, finalPaymentType } = financedDeviceDecisions

  const summaryDecisions = []

  if (penalty?.pending || financedDeviceDecisions?.amendPenalty) {
    summaryDecisions.push({
      name: 'Penalización',
      description: financedDeviceDecisions?.amendPenalty
        ? 'Sin penalización'
        : `${formatFee(penalty.pending)}`,
    })
  }

  if (numberOfMonths && monthlyFee) {
    summaryDecisions.push({
      name:
        feesPaymentType === FEES_DECISION_KEEP_FINANCING
          ? 'Mantener pago a plazos'
          : 'Liquidar pagos pendientes',
      description:
        feesPaymentType === FEES_DECISION_KEEP_FINANCING
          ? `${formatFee(monthlyFee)} x ${numberOfMonths} meses`
          : `${formatFee(financials.fees.monthly * numberOfMonths)}`,
      isKeepingFinancing: feesPaymentType === FEES_DECISION_KEEP_FINANCING,
      monthlyFee,
      numberOfMonths,
      finalPayment: finalPayment?.total,
      cancelationToday: cancelationToday?.amount,
    })
  }

  if (finalPayment?.amount) {
    summaryDecisions.push({
      name: 'Pago final (Q25)',
      description:
        finalPaymentType === FINAL_PAYMENT_DECISION_FINANCING
          ? `${formatFee(finalPayment.amount / 12)} x 12 meses`
          : `${formatFee(finalPayment.amount)}`,
    })
  }

  return {
    deviceName,
    summaryDecisions,
  }
}

export function removeDevicesWithoutPaymentMethods(devicesToFilter) {
  return devicesToFilter.filter(device => {
    return !isEmpty(device.paymentMethods)
  })
}

export const getDeviceFinancing = renewalSubscription => {
  const deviceFinancing = getTerminalsFinancials(renewalSubscription).filter(financial =>
    financial.id.includes('PAYTERM'),
  )
  return head(deviceFinancing)
}

export const getDevicePermanent = renewalSubscription => {
  return head(findMobilePermantsByEndTimeValidation(renewalSubscription))
}
