import { call, put, takeLatest, select } from 'redux-saga/effects'
import { format } from 'date-fns'
import { logError, formatErrorMessage } from 'services/logging'
import { axiosFactory } from 'modules/axios'

import { selectSfid } from 'modules/Auth'

import { putOrderDiscountsSuccess, putOrderDiscountsFailed } from './actions'
import { PUT_ORDER_DISCOUNTS_START } from './constants'
import { putOrderDiscounts } from './api'

export function* putOrderDiscountsSaga({ payload: { orderId, dataDiscount } }) {
  const sfidCode = yield select(selectSfid)

  try {
    const discountData = {
      discountCode: dataDiscount.code,
      description: dataDiscount.description,
      amount: dataDiscount.price,
      unit: dataDiscount.unit,
      duration: dataDiscount.duration,
      sfid: sfidCode,
      state: 'New',
      modificationDate: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
      modificationUsername: sfidCode,
    }

    const response = yield call(putOrderDiscounts, orderId, discountData)

    yield put(putOrderDiscountsSuccess(response))
  } catch (e) {
    const error = axiosFactory.buildError(e)
    yield call(logError, new Error(formatErrorMessage(e)))
    yield put(putOrderDiscountsFailed(error))
  }
}

export function* watchPutOrderDiscounts() {
  yield takeLatest(PUT_ORDER_DISCOUNTS_START, putOrderDiscountsSaga)
}
