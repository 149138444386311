import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { useFormikContext } from 'formik'

import { FormControlLabel, RadioGroup, Radio, Box, Typography, Divider } from '@material-ui/core'
import { ActionAdvice } from 'components/ui'

import { FEES_DECISION_KEEP_FINANCING, FEES_DECISION_SETTLE_PAYMENT_NOW } from '../../constants'

import { FinalPaymentFinancingDecision } from '../FinalPaymentFinancingDecision'

export const FinancingDecisionRadio = ({
  finalPayment,
  fieldNames,
  classes,
  shouldAllowFinancingDecision,
  shouldMantainFinancing,
  shouldAllowFinalPaymentDecision,
}) => {
  const { setFieldValue, values } = useFormikContext()

  const radioFieldName = fieldNames.FEES_PAYMENT_TYPE

  const shouldShowRadio = shouldAllowFinancingDecision || shouldMantainFinancing

  const defaultFeesPaymentType = shouldMantainFinancing
    ? FEES_DECISION_KEEP_FINANCING
    : FEES_DECISION_SETTLE_PAYMENT_NOW

  const finalPaymentValue = finalPayment?.amount
  const finalPaymenttFinancingValuePerMonth = finalPaymentValue ? finalPaymentValue / 12 : ''

  const currentRadioValue = values.financedDeviceDecisions?.feesPaymentType
  const [feesPaymentType, setFeesPaymentType] = useState(
    currentRadioValue || defaultFeesPaymentType,
  )

  const onRadioChange = value => {
    setFeesPaymentType(value)
    setFieldValue(radioFieldName, value)
  }

  useEffect(() => {
    if (!currentRadioValue && shouldShowRadio) {
      onRadioChange(defaultFeesPaymentType)
    }
  }, [])

  return (
    <>
      {shouldShowRadio ? (
        <Box mt="12px">
          <Divider className="dotted" />
          <Box>
            <RadioGroup
              name={radioFieldName}
              value={feesPaymentType}
              onChange={e => onRadioChange(e.target.value)}>
              <Box>
                <Typography component="span" variant="body1" className={classes.radioQuestion}>
                  ¿Qué desea hacer con la cuota actual?
                </Typography>
                <FormControlLabel
                  value={FEES_DECISION_SETTLE_PAYMENT_NOW}
                  control={
                    <Radio
                      className="radio-check"
                      color="primary"
                      disabled={shouldMantainFinancing}
                    />
                  }
                  label={
                    <Typography
                      component="span"
                      variant="body1"
                      className={classes.decisionOptionText}>
                      Liquidar ahora pagos pendientes
                    </Typography>
                  }
                />
                <FormControlLabel
                  value={FEES_DECISION_KEEP_FINANCING}
                  control={
                    <Radio
                      className="radio-check"
                      color="primary"
                      disabled={shouldMantainFinancing}
                    />
                  }
                  label={
                    <Typography
                      component="span"
                      variant="body1"
                      className={classes.decisionOptionText}>
                      Mantener pago a plazos
                    </Typography>
                  }
                />
              </Box>
            </RadioGroup>
          </Box>

          {feesPaymentType === FEES_DECISION_SETTLE_PAYMENT_NOW && (
            <Box mt="12px" mb="12px">
              <ActionAdvice
                type="info"
                message="En la próxima factura se le cobrarán: las cuotas pendientes, la Q25 (si la tiene) y la penalización por permanencia."
                width="100%"
              />
            </Box>
          )}

          {shouldAllowFinalPaymentDecision && finalPayment?.amount > 0 && (
            <FinalPaymentFinancingDecision
              fieldNames={fieldNames}
              financingCostPerMonth={finalPaymenttFinancingValuePerMonth}
              classes={classes}
            />
          )}
        </Box>
      ) : null}
    </>
  )
}

FinancingDecisionRadio.propTypes = {
  finalPayment: PropTypes.object,
  fieldNames: PropTypes.object,
  classes: PropTypes.object,
  shouldAllowFinancingDecision: PropTypes.bool,
  shouldMantainFinancing: PropTypes.bool,
  shouldAllowFinalPaymentDecision: PropTypes.bool,
}
