import { LayoutComponent } from 'components/ui'
import { makeStyles } from '@material-ui/styles'

import {
  OffersConfigurationContainer,
  HeaderCustomerContainer,
  OffersSidebarContainer,
} from './containers'

const useStyles = makeStyles({
  'offers-layout': {
    '& section': {
      backgroundColor: 'initial',
    },
  },
})

export function Routes() {
  const classes = useStyles()

  return (
    <LayoutComponent
      className={classes['offers-layout']}
      sideBarFixed
      headerContent={<HeaderCustomerContainer />}
      sidebarContent={<OffersSidebarContainer />}
      mainContent={<OffersConfigurationContainer />}
    />
  )
}
