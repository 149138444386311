/* eslint-disable import/no-cycle */

import { Box, Typography } from '@material-ui/core'

import { TextStyles } from 'utils/text'

export const CardContentEmpty = () => (
  <Box pt="10px" pl="24px">
    <Typography {...TextStyles.subtitle2Dark()}>No se han encontrado datos</Typography>
  </Box>
)
