import { CurrentFeesContainer } from '../../containers/CurrentFeesContainer'
import { ModifiedFeesContainer } from '../../containers/ModifiedFeesContainer'

export const tabs = {
  CURRENT_FEE: 'current_fee',
  NEW_FEE: 'new_fee',
}

export const tabsConfig = [
  {
    label: 'CUOTA ACTUAL',
    value: tabs.CURRENT_FEE,
    component: CurrentFeesContainer,
  },
  {
    label: 'CUOTA NUEVA',
    value: tabs.NEW_FEE,
    component: ModifiedFeesContainer,
  },
]
