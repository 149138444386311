import { useSelector } from 'react-redux'

import { selectSecondHomeDiscount } from 'modules/NewClientSales/store/selectors'
import { secondResidenceDiscountMessage } from 'modules/Discounts/constants'

export const useSecondResidenceDiscount = () => {
  const secondResidenceDiscount = useSelector(selectSecondHomeDiscount)

  const doesSecondResidenceDiscountApply = secondResidenceDiscount?.length > 0

  return {
    doesSecondResidenceDiscountApply,
    secondResidenceDiscount,
    discountAdviceMessage: secondResidenceDiscountMessage.messageText,
    discountOfferSummaryMessage: secondResidenceDiscountMessage.messageText,
  }
}
