import * as Yup from 'yup/lib'
import { landlineRegex } from 'utils/index'
import * as validations from 'modules/SharedSales/constants/validations'
import { SIGNUP_TYPE_NEWLINE } from 'services/global-constants'
import { mobileLineSchema } from './MobileLine.validation'

const MobileValidation = (isElFijo, isPosSale) => ({
  ...mobileLineSchema(isElFijo, isPosSale),
  tariff: Yup.object().shape({
    id: Yup.string().when('$isMobileOnly', {
      is: true,
      then: Yup.string().required(validations.REQUIRED_FIELD_TEXT),
      otherwise: Yup.string(),
    }),
  }),
})

export const MainMobileSchema = (isElFijo, isPosSale, is2p, isCrossSell) => {
  const Schema = is2p || isCrossSell ? {} : MobileValidation(isElFijo, isPosSale)

  return {
    mainMobileLineOffer: Yup.object().shape({
      ...Schema,
      newFixedNumber: Yup.string().when(['signUpType'], {
        is: signUpType => signUpType === SIGNUP_TYPE_NEWLINE,
        then: isElFijo
          ? Yup.string()
              .matches(landlineRegex, validations.ERROR_DATA_TEXT)
              .required(validations.REQUIRED_FIELD_TEXT)
          : Yup.string().nullable(),
        otherwise: Yup.string(),
      }),
    }),
  }
}

export const ExtraMobileSchema = (disableOfferEdit, isMobileOnly, isPosSale) => ({
  extraMobileLinesOffers: Yup.array().of(
    Yup.object().shape({
      ...mobileLineSchema(false, isPosSale, disableOfferEdit, isMobileOnly),
      tariff: Yup.object().shape({
        id: Yup.string().required(validations.REQUIRED_FIELD_TEXT),
      }),
    }),
  ),
})
