import { capitalize } from 'lodash'
import { createStructuredSelector, createSelector } from 'reselect'

import {
  selectAccountInfoError,
  selectIsCustomer,
  selectDocumentId,
  selectDocumentType,
} from 'modules/Auth'

import { selectIsPosOrMasProximo } from 'modules/Permissions/store/permissions-selectors'

import { selectURLDocumentType } from 'modules/CustomerDocument'
import {
  getIdentificationType,
  getIdentificationId,
  selectors as customer360SvcSelectors,
} from 'services/customer-360/selectors'
import { getError as getTypificationError, isDone } from 'services/typifications'
import { isTypificationOpen, isLoadingTarrifsV3 } from 'modules/offers-configuration/selectors'
import { selectIsOpenTypificationIframe, selectSecurizerTokenTypifications } from 'modules/typifications/store/selectors/securizerTypifications.selectors'
import { selectQueryParams } from 'modules/Router/store/index'
import { selectIsLegacyCustomer, selectSfid, selectCustomerId } from 'modules/Auth/store/index'
import { selectCanIDO, permissions, selectProfileId } from 'modules/Permissions'
import { selectEnergyIsAllowed } from 'modules/energy/store/energy.selectors'

import { selectGdprClientCampaignConsentsFlag } from 'services/feature-flag/selectors'
import { selectAccountId } from 'modules/CustomerInfo/store/customerInfo.selectors'
import { CARTERA_SEGMENTS } from 'services/global-constants'
import { D2D_ADDITIONAL_ENERGY_ACCESS } from 'modules/Permissions/constants'

import {
  getSubscriptionId as getOrderSubscriptionId,
  getSegmentCustomer,
  getLanguageCustomer,
  getOrderLoading,
  getOrderError,
} from 'modules/orders'
import {
  RESIDENTIAL_SEGMENT,
  SELF_EMPLOYED_SEGMENT,
  VISTA_POPUP_CHANNEL,
} from 'modules/mas-consents/constants'
import {
  selectClientSubscriptionId,
  selectClientVerbalId,
  selectOrderVerbalId,
} from 'modules/mas-consents'
import {
  isLoading as getSubscriptionsIsLoading,
  getErrorCode as getErrorCodeSubscriptions,
  selectHasSubscriptions,
} from 'services/subscriptions/selectors'

import { getLangFromId } from 'services/customer-360/helpers'
import * as saga from '../../sagas/dashboardLoad'
import { selectors } from '../../sagas/dashboardLoad/selectors'

const selectIsLoading = createSelector(
  [saga.selectors.isLoading, customer360SvcSelectors.loading],
  (vista360IsLoading, customer360IsLoading) => vista360IsLoading || customer360IsLoading,
)

const selectGdprData = createSelector(
  [
    selectGdprClientCampaignConsentsFlag,
    customer360SvcSelectors.getIsCompany,
    customer360SvcSelectors.customerStatus,
    customer360SvcSelectors.customerConsents,
    getOrderSubscriptionId,
    selectClientSubscriptionId,
    selectOrderVerbalId,
    selectClientVerbalId,
    customer360SvcSelectors.getIdentificationId,
    customer360SvcSelectors.getSegment,
    getSegmentCustomer,
    customer360SvcSelectors.getLang,
    getLanguageCustomer,
    getOrderLoading,
    getSubscriptionsIsLoading,
    selectHasSubscriptions,
    getErrorCodeSubscriptions,
    getOrderError,
  ],
  (
    canShowGdprCampaignConsents,
    customerIsCompany,
    customerStatus,
    customerConsents,
    orderSubscriptionId,
    clientSubscriptionId,
    orderVerbalId,
    clientVerbalId,
    documentid,
    segment360,
    segmentOrder,
    lang360,
    langOrder,
    orderIsLoading,
    subscriptionsIsLoading,
    hasSubscriptions,
    subscriptionsError,
    orderError,
  ) => {
    if (
      !canShowGdprCampaignConsents ||
      customerIsCompany ||
      customerStatus === 'cancelled' ||
      (customerConsents !== 'acquisition' && customerConsents !== 'campaign')
    ) {
      return null
    }

    const subscriptionId = orderSubscriptionId || clientSubscriptionId || "''"
    const verbalId = orderVerbalId || clientVerbalId || "''"

    const segment = segmentOrder || segment360
    const lang = langOrder || getLangFromId(lang360)?.code

    const hasError = !subscriptionId && (hasSubscriptions || !!subscriptionsError || !!orderError)
    const isLoading = (!subscriptionId && !hasError) || orderIsLoading || subscriptionsIsLoading

    return {
      subscriptionId,
      verbalId,
      customerDocument: documentid,
      segment:
        capitalize(segment) === CARTERA_SEGMENTS.CONSUMER
          ? RESIDENTIAL_SEGMENT
          : SELF_EMPLOYED_SEGMENT,
      lang,
      channel: VISTA_POPUP_CHANNEL,
      consentType: customerConsents,
      isLoading,
      hasError,
    }
  },
)

export const dashboardContainerSelector = createStructuredSelector({
  tariffsV3Loading: isLoadingTarrifsV3,
  accountInfoError: selectAccountInfoError,
  hasErrors: saga.selectors.hasErrors,
  isCustomer: selectIsCustomer,
  customer360Error: customer360SvcSelectors.error,
  contracts: customer360SvcSelectors.contracts,
  orders: customer360SvcSelectors.orders,
  isLoading: selectIsLoading,
  documentTypeURL: selectURLDocumentType,
  documentType: selectDocumentType,
  documentId: selectDocumentId,

  typificationOpen: isTypificationOpen,
  typificationIframeOpen: selectIsOpenTypificationIframe,
  
  typificationIframeToken: selectSecurizerTokenTypifications,

  typificationError: getTypificationError,
  isTypificationDone: isDone,

  customerDocumentId: customer360SvcSelectors.getIdentificationId,

  sectionData: selectors.selectURLDashboardParams,
  queryParams: selectQueryParams,
  isLegacyCustomer: selectIsLegacyCustomer,
  customerId: selectCustomerId,
  sfid: selectSfid,
  areaSfid: selectProfileId,

  energyIsAllowed: selectEnergyIsAllowed,
  canEnergyViewContracts: selectCanIDO(
    permissions.energy_view_contracts.id,
    D2D_ADDITIONAL_ENERGY_ACCESS,
  ),
  accountId: selectAccountId,

  identificationType: getIdentificationType,
  identificationId: getIdentificationId,

  gdprData: selectGdprData,

  isPosOrMasProximo: selectIsPosOrMasProximo,
})
