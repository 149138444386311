import PropTypes from 'prop-types'

import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  'sidebar-menu': {
    backgroundColor: theme.palette.brand.medium,
  },
}))

function SidebarMenu(props) {
  const classes = useStyles()

  return (
    <Box
      display="flex"
      flex={1}
      flexDirection="column"
      m={0}
      p={0}
      className={classes['sidebar-menu']}>
      {props.children}
    </Box>
  )
}

SidebarMenu.propTypes = {
  children: PropTypes.node.isRequired,
}

export default SidebarMenu
