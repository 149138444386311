import { get } from 'lodash/fp'
import { customerOrderActionTypes } from './customerOrders.actions'

const initialState = {
  orders: null,
  error: null,
  loading: false,
  orderLimit: {
    data: null,
    error: null,
    loading: null,
  },
  duoLimit: {
    data: null,
    error: null,
    loading: null,
  },
}

export const reducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case customerOrderActionTypes.FIND_ALL_ORDERS: {
      return {
        ...state,
        loading: true,
      }
    }
    case customerOrderActionTypes.FIND_ALL_ORDERS_SUCCESS: {
      return {
        ...state,
        orders: get('orders.data', payload),
        loading: false,
      }
    }
    case customerOrderActionTypes.FIND_ALL_ORDERS_FAILED: {
      return {
        ...state,
        error: get('error', payload) || payload,
        loading: false,
      }
    }

    case customerOrderActionTypes.FETCH_CUSTOMER_ORDER_LIMIT: {
      return {
        ...state,
        orderLimit: {
          data: null,
          error: null,
          loading: true,
        },
      }
    }

    case customerOrderActionTypes.FETCH_CUSTOMER_ORDER_LIMIT_SUCCESS: {
      return {
        ...state,
        orderLimit: {
          data: payload,
          error: null,
          loading: false,
        },
      }
    }

    case customerOrderActionTypes.FETCH_CUSTOMER_ORDER_LIMIT_ERROR: {
      return {
        ...state,
        orderLimit: {
          data: null,
          error: payload,
          loading: false,
        },
      }
    }

    case customerOrderActionTypes.RESET_CUSTOMER_ORDER_LIMIT_LOADING: {
      return {
        ...state,
        orderLimit: {
          ...state.orderLimit,
          loading: null,
        },
      }
    }

    case customerOrderActionTypes.FETCH_CUSTOMER_DUO_LIMIT_SUCCESS: {
      return {
        ...state,
        duoLimit: {
          data: payload,
          error: null,
          loading: false,
        },
      }
    }

    case customerOrderActionTypes.FETCH_CUSTOMER_DUO_LIMIT_ERROR: {
      return {
        ...state,
        duoLimit: {
          data: null,
          error: payload,
          loading: false,
        },
      }
    }

    case customerOrderActionTypes.RESET_CUSTOMER_ORDER_LIMIT: {
      return {
        ...state,
        orderLimit: { ...initialState.orderLimit },
      }
    }

    default:
      return state
  }
}
