import { axiosCustomer } from 'modules/axios'
import { CONFIG } from './environments'

export function getRove(data, orderId) {
  return axiosCustomer({
    method: 'post',
    url: `${CONFIG.api.rove}${orderId}/ROVE`,
    data,
  }).then(resp => resp.data)
}
