import { useEffect, useState } from 'react'
import { useFormikContext } from 'formik'
import { isEmpty, get, head, isNil } from 'lodash'
import { getNameFieldOptions } from '../helpers'

export const useDefaultValueSelectors = (fieldOption, values, operatorsLoading) => {
  const [selectorsDisabled, setSelectorsDisabled] = useState({})
  const { setFieldValue } = useFormikContext()
  const { reason, result, subreason, subreason2, subresult, operator } = values

  const getFieldOptionHasOneValue = fieldOptions => fieldOptions.length === 1

  const setFirstValueSelector = (field, fieldOptions) => {
    const valueOption = get(head(fieldOptions), 'value')

    setSelectorsDisabled(selectors => ({ ...selectors, [field]: { bool: true } }))
    setFieldValue(field, valueOption)
  }

  const setValueSelectors = fields => {
    fields.forEach(field => {
      const fieldOptions = get(fieldOption, getNameFieldOptions(field))
      const fieldOptionHasOneValue = getFieldOptionHasOneValue(fieldOptions)

      if (fieldOptionHasOneValue) {
        setFirstValueSelector(field, fieldOptions)
      }
    })
  }

  useEffect(() => {
    if (!isEmpty(reason) && isEmpty(result) && isEmpty(subreason)) {
      setValueSelectors(['result', 'subreason'])
    }
  }, [reason, result, subreason, fieldOption])

  useEffect(() => {
    if (!isEmpty(subreason) && isEmpty(subreason2)) {
      setValueSelectors(['subreason2'])
    }
  }, [subreason, subreason2, fieldOption])

  useEffect(() => {
    if (!isEmpty(result) && isEmpty(subresult)) {
      setValueSelectors(['subresult'])
    }
  }, [result, subresult, fieldOption])

  useEffect(() => {
      if(!operatorsLoading && !isNil(operatorsLoading) && isEmpty(operator)) {
        setValueSelectors(['operator'])
      }
  }, [operator, fieldOption])

  return { selectorsDisabled, setSelectorsDisabled }
}
