import { call, takeLatest, select, put } from 'redux-saga/effects'
import * as ordersSvc from 'modules/orders'

import { actions } from './actions'
import { constants } from './constants'

function* orderDashboardLoadSaga({ payload: { orderId } }) {
  try {
    const errorOrder = yield call(ordersSvc.callFindByIdSaga, orderId)

    if (errorOrder) {
      const payload = {
        error: {
          errorOrder: errorOrder && {
            code: yield select(ordersSvc.getOrderErrorCode),
            msg: yield select(ordersSvc.getOrderErrorMsg),
          },
        },
      }
      return yield put(actions.loadFailed(payload))
    }

    return yield put(actions.loadSucceeded())
  } catch (e) {
    const payload = {
      error: {
        all: {
          msg: e,
        },
      },
    }

    return yield put(actions.loadFailed(payload))
  }
}

export function* watchLoad() {
  yield takeLatest(constants.LOAD_INIT, orderDashboardLoadSaga)
}
