import { call, put, takeEvery, select } from 'redux-saga/effects'
import { logError, formatErrorMessage } from 'services/logging'
import { getOrderId } from 'modules/orders'
import { axiosFactory } from 'modules/axios'

import { actions } from './actions'
import { constants } from './constants'
import { getRove } from './api'
import { selectCustomerData } from './selectors'

export function* getRoveSaga() {
  try {
    yield put(actions.getRove())

    const customerData = yield select(selectCustomerData)
    const orderId = yield select(getOrderId)

    const rove = yield call(getRove, customerData, orderId)

    yield put(actions.gotRove(rove.decission))
  } catch (e) {
    yield call(logError, new Error(formatErrorMessage(e)))
    const error = axiosFactory.buildError(e)
    yield put(actions.getRoveFailed(error))
  }
}

export function* watchGetRove() {
  yield takeEvery(constants.GET_ROVE_INIT, getRoveSaga)
}
