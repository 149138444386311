import { axiosCustomer, axiosJWT, axiosMasStack } from 'modules/axios'
import {
  SVA_STATUS_DISABLED,
  SVA_STATUS_LITERAL_DISABLED,
  SVA_STATUS_LITERAL_ENABLED,
} from 'services/subscriptions/constants'
import { CONFIG } from './environments'

export function fetchSubscriptions(customerId) {
  return axiosJWT({
    method: 'GET',
    headers: {
      'X-Authorization': axiosCustomer.defaults.headers.common.Authorization,
    },
    url: `${CONFIG.api.search360Customers}/${customerId}/products`,
  }).then(response => response.data)
}

export function fetchSubscriptionByMsisdn(productId) {
  return axiosJWT({
    method: 'GET',
    url: `${CONFIG.api.search360Products}/${productId}`,
  }).then(response => response.data)
}

export function updateSubscriptionTariff(msisdn, tariffId, options = {}, v3 = false) {
  const url = v3 ? CONFIG.api.changeTariffV3 : CONFIG.api.changeTariff

  return axiosJWT({
    method: 'POST',
    url,
    data: {
      msisdn,
      tariffId,
      ...options,
    },
  })
    .then(resp => resp.data)
    .catch(e => {
      e.response.data.error_message =
        e.response.data.detailMsg || e.response.data.info || e.response.data.result.info
      throw e
    })
}

export const setBundles = (msisdn, bundleId, bundleStatus) =>
  axiosJWT({
    method: 'PUT',
    url: `${CONFIG.api.search360Products}/${msisdn}/bundle`,
    data: {
      'bundle-id': bundleId,
      'bundle-status': bundleStatus,
    },
  }).then(resp => resp.data)

export const setBundlesHebeSva = (msisdn, service, status, customerId) => {
  const finalStatus =
    status === SVA_STATUS_DISABLED ? SVA_STATUS_LITERAL_DISABLED : SVA_STATUS_LITERAL_ENABLED

  const sendData = { msisdn, service, status: finalStatus }

  if (customerId) sendData.custnum = customerId

  return axiosJWT({
    method: 'PUT',
    url: `${CONFIG.api.svahebe}/sva-hebe`,
    data: {
      data: [sendData],
    },
  }).then(resp => resp.data)
}

export const disableSvaSmartHome = alarmData =>
  axiosMasStack({
    method: 'POST',
    url: `${CONFIG.api.svaSmartHome}/cancel`,
    data: alarmData,
    headers: {
      Authorization: axiosJWT.defaults.headers.common.Authorization,
    },
  }).then(resp => resp.data)

export const setUpsellBundles = (msisdn, bundleId, customerId) =>
  axiosJWT({
    method: 'POST',
    url: `${CONFIG.api.bundleRenewals}/bundles`,
    headers: {
      'X-Authorization': axiosJWT.defaults.headers.common.Authorization,
      'X-Customer-ID': customerId,
    },
    data: {
      msisdn,
      bundleId,
    },
  }).then(resp => resp.data)

export function fetchPortOutInfo(msisdn) {
  return axiosJWT({
    method: 'GET',
    url: `${CONFIG.api.portabilityInfo}/${msisdn}`,
  }).then(response => response.data)
}

export function disableSvaHomeGo(dataSvaCancelation) {
  return axiosJWT({
    method: 'POST',
    url: CONFIG.api.cancelSvaHomeGo,
    data: dataSvaCancelation,
  }).then(resp => resp.data)
}
