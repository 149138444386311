import { useMemo } from 'react'
import { isEmpty, isNumber } from 'lodash/fp'

import { fieldStates } from './constants'

export const getFieldState = ({ value, error, focused, disabled, touched, fieldState }) => {
  const { FOCUSED, DISABLED, ERROR, DEFAULT, VALID } = fieldStates
  const hasValue = isNumber(value) || !isEmpty(value)

  if (!isEmpty(fieldState)) return fieldState // if fieldState is passed from props
  if (!isEmpty(error) && touched) return ERROR
  if (disabled) return DISABLED
  if (focused) return FOCUSED
  if (isEmpty(error) && hasValue) return VALID // assume that field should have value, to be valid

  return DEFAULT
}

export const useFieldState = props => {
  const fieldState = getFieldState(props)

  const hasError = useMemo(() => fieldState === fieldStates.ERROR, [fieldState])
  const isValid = useMemo(() => fieldState === fieldStates.VALID, [fieldState])
  const isDisabled = useMemo(() => fieldState === fieldStates.DISABLED, [fieldState])

  return {
    fieldState,
    hasError,
    isValid,
    isDisabled,
  }
}
