import PropTypes from 'prop-types'
import { ButtonBase, Icon } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { ICONS } from 'utils/icons'

const useStyles = makeStyles(theme => ({
  'button-menu': {
    '&&': {
      width: '24px',
      height: '24px',
      borderRadius: '50%',
      border: '1px solid rgba(137, 139, 140, 0.4)',
      boxSizing: 'border-box',
      backgroundColor: 'transparent',
      color: theme.palette.brand.action_primary,
      fontFamily: 'Material Icons',
      fontSize: '20px',
      textAlign: 'center',
      transition:
        'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',

      '&:hover': {
        backgroundColor: 'rgba(137, 139, 140, 0.16)',
      },
    },
  },
}))

export default function ButtonMenu({ icon, className, ...rest }) {
  const classes = useStyles()

  return (
    <ButtonBase
      className={['button-menu', classes['button-menu'], ...(className ? [className] : [])].join(
        ' ',
      )}
      {...rest}>
      <Icon className="icon" style={{ fontSize: '20px' }}>
        {ICONS[icon || 'moreVert']}
      </Icon>
    </ButtonBase>
  )
}

ButtonMenu.propTypes = {
  icon: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
}
