import PropTypes from 'prop-types'
import { Card, CardContent, Typography, Divider, Box } from '@material-ui/core'
import { FormError } from 'components/ui'
import { CoverageForm } from 'modules/Coverage/components/CoverageForm'
import { ErrorMessage } from 'formik'
import { OfferType } from '../OfferTypeComponent'
import { Lead } from '../LeadComponent'

const fieldNames = {
  PORTABILITY_NUMBER: 'landlineOffer.lineNumber',
  OPERATOR: 'landlineOffer.operator',
  DOCUMENT_ID: 'account.documentid',
  CLIENT_EXISTS_LAND: 'landlineOffer.clientExistsError',
}

export function ConvergentSale({
  installationAddress,
  isLoadingCoverage,
  coverageToken,
  onCoverageCheckFinished,
  resetCoverage,
  isTesting,
  isPortability,
  hasTechnology,
  handleIsPortability,
  isCartera,
  isCrossSell,
  isPresale,
}) {
  return (
    <>
      <Card className="m-y-32">
        <CardContent>
          <Box mb="32px">
            <Typography variant="subtitle1" color="secondary" className="bold m-b-20">
              DIRECCIÓN DE INSTALACIÓN
            </Typography>
            <CoverageForm
              name="landlineOffer.installationAddress.gescal"
              installationAddress={installationAddress}
              isLoading={isLoadingCoverage}
              coverageToken={coverageToken}
              onCoverageCheckFinished={onCoverageCheckFinished}
              reset={resetCoverage}
              isTesting={isTesting}
            />
            <ErrorMessage name="landlineOffer.installationAddress.gescal">
              {msg => (
                <FormError className="p-b-16 m-l-16" fullwidth>
                  {msg}
                </FormError>
              )}
            </ErrorMessage>
          </Box>
          <Divider />

          <Box py="12px">
            <OfferType
              fieldNames={fieldNames}
              isDisabled={!hasTechnology}
              lineType="landlineOffer"
              installationAddress={installationAddress}
              isPortability={isPortability}
              handleIsPortability={handleIsPortability}
            />
          </Box>
          <Divider />

          <Lead
            fieldNames={fieldNames}
            isSegmentChooserDisabled={!hasTechnology}
            isCartera={!!isCartera}
            isCrossSell={!!isCrossSell}
            isPresale={isPresale}
            applyPrescoring={!isCartera && !isPresale && !isCrossSell}
          />
        </CardContent>
      </Card>
    </>
  )
}

ConvergentSale.propTypes = {
  installationAddress: PropTypes.object.isRequired,
  isLoadingCoverage: PropTypes.bool,
  coverageToken: PropTypes.string,
  onCoverageCheckFinished: PropTypes.func.isRequired,
  resetCoverage: PropTypes.func.isRequired,
  isTesting: PropTypes.string,
  isPortability: PropTypes.string.isRequired,
  hasTechnology: PropTypes.bool,
  handleIsPortability: PropTypes.func.isRequired,
  isCartera: PropTypes.bool,
  isCrossSell: PropTypes.bool,
  isPresale: PropTypes.bool,
}
