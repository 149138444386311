const FIND_ORDER_STATUS_DESC = 'OVID/ORDER-STATUS/FIND'
const FIND_ORDER_STATUS_DESC_SUCCEEDED = 'OVID/ORDER-STATUS/FOUND'
const FIND_ORDER_STATUS_DESC_FAILED = 'OVID/ORDER-STATUS/FOUND_FAILED'

const FixedStatusMappping = {
  active: 'Activa',
  fly: 'En Vuelo',
  incidence: 'Incidencia',
}

export const constants = {
  FIND_ORDER_STATUS_DESC,
  FIND_ORDER_STATUS_DESC_SUCCEEDED,
  FIND_ORDER_STATUS_DESC_FAILED,
  FixedStatusMappping,
}
