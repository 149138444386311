export const salesLeadModel = {
  sfId: '',
  brand: 'Yoigo',
  product: '',
  name: '',
  surname1: '',
  surname2: '',
  telephoneNumber: '',
  segment: 'residential',
  contactHour: '',
}
