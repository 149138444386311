import { componentColors, grayPalette } from './colors';
var fonts = ['Roboto'];
var config = {
  fontSize: 14
};
var typography = Object.assign({
  fontFamily: fonts.join(','),
  h3: {
    fontSize: 32,
    color: componentColors.BlackHeadsText,
    fontWeight: 300
  },
  h4: {
    fontSize: 20,
    color: componentColors.BlackHeadsText,
    fontWeight: 400
  },
  h5: {
    fontSize: 20,
    color: componentColors.BlackHeadsText
  },
  h6: {
    fontSize: 18,
    color: componentColors.BlackHeadsText,
    fontWeight: 500
  },
  body1: {
    fontSize: 16,
    color: grayPalette.gray_dark,
    fontWeight: 300
  },
  body2: {
    fontSize: 16,
    color: grayPalette.gray_dark,
    fontWeight: 500
  },
  caption: {
    fontSize: 13,
    color: componentColors.BlackCaptionText,
    fontWeight: 300
  },
  button: {
    color: componentColors.BlueLinkText
  }
}, config);
export { typography };