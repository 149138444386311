import { axiosCustomerV3 } from 'modules/axios'

import { CONFIG } from './environments'

export function fetchCustomerContactMedia(accountId) {
  return axiosCustomerV3({
    method: 'GET',
    url: `${CONFIG.api.customers}/${accountId}/contactMedia`,
  }).then(resp => resp.data)
}
