import { useMemo, useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { useSelector, useDispatch } from 'react-redux'
import { Formik } from 'formik'
import { Typography } from '@material-ui/core'

import { TextStyles } from 'utils/text'

import { mobileNumberRegex, mobileOrLanlineNumberRegex } from 'utils'

import {
  Modal,
  FormikInput,
  ModalActions,
  Button,
  SpinnerCenter,
  SpinnerButton,
  NotificationModal,
} from 'components/ui'

import { useFetchCustomerIds, modifyCustomerContactMedia } from 'modules/CustomerInfo'
import { modifyContactPhoneModalSelectors } from './ModifyContactPhoneModal.selectors'

const ModifyContactPhoneForm = ({
  handleSubmit,
  errors,
  isSaving,
  onClose,
  showSecondPhoneField,
}) => {
  const hasErrors = useMemo(() => Object.keys(errors).length > 0, [errors])

  const isRequired = v => {
    if (!v) {
      return 'Campo requerido'
    }

    return false
  }

  const isMobileNumber = v => {
    if (!mobileNumberRegex.test(v)) {
      return 'Número de teléfono móvil no válido'
    }

    return false
  }

  const isPhoneNumber = v => {
    if (!mobileOrLanlineNumberRegex.test(v)) {
      return 'Número de teléfono no válido'
    }

    return false
  }

  return (
    <form onSubmit={handleSubmit} style={{ paddingBottom: '1px' }}>
      <FormikInput
        name="phoneNumber1"
        maxLength="9"
        fullWidth
        label="Teléfono 1"
        validate={v =>
          isRequired(v) ||
          (showSecondPhoneField && isPhoneNumber(v)) ||
          (!showSecondPhoneField && isMobileNumber(v))
        }
        disabled={{ bool: isSaving }}
        style={{ maxHeight: '76px' }}
      />

      {showSecondPhoneField && (
        <FormikInput
          name="phoneNumber2"
          maxLength="9"
          fullWidth
          label="Teléfono 2"
          validate={v => isRequired(v) || isMobileNumber(v)}
          disabled={{ bool: isSaving }}
          style={{ maxHeight: '76px' }}
        />
      )}

      <ModalActions>
        {!isSaving && (
          <Button type="submit" disabled={hasErrors || isSaving}>
            Aceptar
          </Button>
        )}

        {isSaving && <SpinnerButton />}

        {onClose && (
          <Button secondary onClick={onClose}>
            Cancelar
          </Button>
        )}
      </ModalActions>
    </form>
  )
}

ModifyContactPhoneForm.propTypes = {
  errors: PropTypes.object,
  handleSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  isSaving: PropTypes.bool,
  showSecondPhoneField: PropTypes.bool,
}

export const ModifyContactPhoneModal = ({
  customer,
  current,
  open,
  onClose,
  onSuccess,
  children,
}) => {
  const dispatch = useDispatch()

  const { accountId, customerInfoIsLoading, customerInfoError, isSaving, hasError } = useSelector(
    modifyContactPhoneModalSelectors,
  )

  const [isShowResultModal, setIsShowResultModal] = useState(false)
  const [isInitialized, setIsInitialized] = useState(false)
  const [submittedValues, setSubmittedValues] = useState(null)

  const { fetchCustomerIds } = useFetchCustomerIds()

  useEffect(() => {
    if (open && !!customer) {
      fetchCustomerIds(customer.docType, customer.docNumber)
      setIsInitialized(true)
    }
  }, [open, customer])

  const onSubmit = useCallback(
    values => {
      const { phoneNumber1, phoneNumber2 } = values

      dispatch(
        modifyCustomerContactMedia(
          accountId,
          customer?.isCompany ? 'pro' : 'residential',
          phoneNumber2
            ? {
                telephoneHome: phoneNumber1,
                telephonePrimary: phoneNumber2,
              }
            : {
                telephonePrimary: phoneNumber1,
              },
          3000,
        ),
      )

      setSubmittedValues([phoneNumber2, phoneNumber1])
    },
    [accountId, customer],
  )

  useEffect(() => {
    if (
      (!!submittedValues && !isSaving) ||
      (open && isInitialized && !customerInfoIsLoading && customerInfoError)
    ) {
      onClose()
      setIsShowResultModal(true)
    }
  }, [submittedValues, isSaving, open, isInitialized, customerInfoIsLoading, customerInfoError])

  const onNotificationClose = () => {
    setIsShowResultModal(false)
    setIsInitialized(false)
    setSubmittedValues(null)

    if (onSuccess && !hasError && !customerInfoError) {
      onSuccess(submittedValues)
    }
  }

  const resultModalTitle = useMemo(() => {
    if (hasError) {
      return 'Error intentando actualizar el teléfono de contacto'
    }

    if (customerInfoError) {
      return 'Error obteniendo los datos del cliente'
    }

    return 'Teléfono de contacto actualizado!'
  }, [customerInfoError, hasError])

  return (
    <>
      <NotificationModal
        isOpen={isShowResultModal}
        onClose={onNotificationClose}
        type={hasError || customerInfoError ? 'alert' : 'success'}
        src={hasError || customerInfoError ? '/assets/error.svg' : '/assets/clap_clap.svg'}
        title={resultModalTitle}>
        {(hasError || customerInfoError) && (
          <Typography
            {...TextStyles.paragraphDark({
              style: { marginBottom: '15px' },
            })}>
            Por favor, inténtalo más tarde.
          </Typography>
        )}
        <Button onClick={onNotificationClose}>Aceptar</Button>
      </NotificationModal>

      <Modal title="Modificar teléfono de contacto" isOpen={open} onClose={onClose}>
        {(customerInfoIsLoading || !!customerInfoError) && (
          <SpinnerCenter style={{ marginTop: '20px' }} />
        )}

        {!!accountId && (
          <>
            {children}

            <Formik
              initialValues={{
                phoneNumber1: current[1] || current[0],
                phoneNumber2: current[1] ? current[0] : undefined,
              }}
              onSubmit={onSubmit}>
              {({ handleSubmit, errors }) => (
                <ModifyContactPhoneForm
                  errors={errors}
                  handleSubmit={handleSubmit}
                  isSaving={isSaving}
                  onClose={onClose}
                  showSecondPhoneField={current.length > 1}
                />
              )}
            </Formik>
          </>
        )}
      </Modal>
    </>
  )
}

ModifyContactPhoneModal.propTypes = {
  customer: PropTypes.shape({
    docType: PropTypes.string.isRequired,
    docNumber: PropTypes.string.isRequired,
    isCompany: PropTypes.bool,
  }).isRequired,
  current: PropTypes.array,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  children: PropTypes.node,
}
