import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import { TextStyles } from 'utils/text'

import { Modal, ModalActions, Button } from 'components/ui'

const SaveChangesModal = props => {
  const { isOpen, onClose, onSuccess } = props

  return (
    <Modal title="Guardar Cambios" isOpen={isOpen} onClose={onClose}>
      <Typography {...TextStyles.paragraphDark()}>
        Tus cambios se han guardado correctamente y el pedido se cargará
      </Typography>

      <ModalActions>
        <Button margin="4px 16px 4px 0" onClick={onSuccess} primary>
          Aceptar
        </Button>

        <Button secondary onClick={onClose}>
          Rechazar
        </Button>
      </ModalActions>
    </Modal>
  )
}

SaveChangesModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
}

export default SaveChangesModal
