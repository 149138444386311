import { isEmpty, get } from 'lodash'
import { useFormikContext } from 'formik'

import { getMobileTariff } from 'modules/Lines/helpers/helpers'
import { fieldNames } from 'modules/Lines/line.config'
import { fetchTariffsAction } from 'modules/tariffs/store'
import { LINE_TYPE } from 'modules/Lines/constants'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Divider } from '@material-ui/core'
import { Line } from '../../../components/Offer/Line'
import { getFormikSelectors } from '../../../hooks/Lines'
import { useChannelAndSegment, useResetLine } from '../../../hooks/Shared'
import { setMainMobileLineTariff } from '../../../store'
import { storeSelectors } from './MainMobileContainer.selectors'

const requiredFields = [
  'account.documenttype',
  'account.documentid',
  'account.name',
  'account.surname1',
  'account.surname2',
  'account.phoneContact',
  `${LINE_TYPE.MAIN_MOBILE}.lineNumber`,
]

export function MainMobileContainer({ showPackages, showLineData, selectedTariff, isStockCheck }) {
  const dispatch = useDispatch()

  const {
    tax,
    tariffs,
    errorType,
    isLoading,
    isMobileOnly,
    isElFijo,
    isPro,
    isPos,
    isCrossSell,
    subscriptionProductNumber,
  } = useSelector(storeSelectors)

  const lineFieldNames = fieldNames(LINE_TYPE.MAIN_MOBILE, isMobileOnly || isElFijo)

  const { setFieldValue, values } = useFormikContext()
  const mainMobileLine = get(values, LINE_TYPE.MAIN_MOBILE, {})
  const { lineNumber } = mainMobileLine
  const ps = get(mainMobileLine, 'tariff.ps')

  const { onResetLines } = useResetLine(lineFieldNames)

  const { channel, segment } = useChannelAndSegment()

  const formikLineValues = getFormikSelectors(lineFieldNames, setFieldValue, values)

  function onChangeTariff(tariff) {
    if (tariff.ps !== ps) {
      onResetLines(lineFieldNames)
      setFieldValue(lineFieldNames.TARIFF, tariff)
      dispatch(setMainMobileLineTariff(tariff))
    }
  }

  useEffect(() => {
    if (isCrossSell) {
      setFieldValue(lineFieldNames.LINE_NUMBER, subscriptionProductNumber)
    }

    if (!isEmpty(tariffs) || isLoading === false) return

    if (isMobileOnly || isElFijo) {
      let tariffType = 'mobile'
      if (isElFijo) {
        tariffType = 'mobile_fixed_line'
      }

      dispatch(
        fetchTariffsAction({
          segment,
          channel,
          type: tariffType,
        }),
      )
    }
  }, [])

  useEffect(() => {
    if (!ps && selectedTariff.ps) {
      setFieldValue(lineFieldNames.TARIFF, selectedTariff)
    }
  }, [selectedTariff])

  return (
    <>
      {!isElFijo && (
        <>
          <Divider />
          <h3 className={`card-title small m-t-20 m-b-12${isCrossSell ? ' p-b-12' : ''}`}>
            Móvil cabecera: {isCrossSell ? lineNumber : getMobileTariff(selectedTariff)}
          </h3>
        </>
      )}

      {!isCrossSell && (
        <Line
          showPicker={isMobileOnly || isElFijo}
          showLineData={!!showLineData}
          isPro={isPro}
          showPackages={showPackages}
          selectedTariff={selectedTariff}
          lineFieldNames={lineFieldNames}
          tax={tax}
          selectedPackages={formikLineValues.selectedPackages}
          formikLineValues={formikLineValues}
          lineType={LINE_TYPE.MAIN_MOBILE}
          error={errorType}
          isLoading={isLoading}
          isStockCheck={!isPos && isStockCheck}
          requiredFields={isElFijo && requiredFields}
          onChangeTariff={onChangeTariff}
          tariffs={tariffs}
        />
      )}
    </>
  )
}

MainMobileContainer.propTypes = {
  selectedTariff: PropTypes.object.isRequired,
  showPackages: PropTypes.bool,
  showLineData: PropTypes.bool,
  isStockCheck: PropTypes.bool,
}
