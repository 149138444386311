import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import { TextStyles } from 'utils/text'
import { Grid, Modal } from 'components/ui'
import { isEmpty } from 'lodash'

export const CustomerWriteResultModal = styled(({ className, errors, isOpen, onClose }) => {
  const icon = isEmpty(errors) ? '/assets/clap_clap.svg' : '/assets/error.svg'
  const customerWriteResult = isEmpty(errors)
    ? 'Has editado los datos correctamente'
    : 'Ha ocurrido un error al actualizar los datos del cliente'

  return (
    <Modal
      type={!isEmpty(errors) ? 'alert' : 'success'}
      data-hook="customer-write-modal-result"
      isOpen={isOpen}
      onClose={onClose}>
      <Grid className={className} container justify="space-between" alignItems="center">
        <Grid className="content-grid" item xs={12}>
          <img src={icon} alt={customerWriteResult} />
        </Grid>
        <Grid className="content-grid" item xs={12}>
          <Grid className="content-grid" item xs={12}>
            <Typography {...TextStyles.title1Dark()}>{customerWriteResult}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  )
})`
  .content-grid {
    text-align: center;
    padding: 1em 0;
  }
`

CustomerWriteResultModal.propTypes = {
  errors: PropTypes.object,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
}
