import { useMemo } from 'react'
import PropTypes from 'prop-types'

import { Box, Typography, CircularProgress, Chip, Icon } from '@material-ui/core'

import { makeStyles } from '@material-ui/styles'

import { TextStyles } from 'utils/text'
import { ICONS } from 'utils/icons'

const useStyles = makeStyles({
  competitors: {
    '& > p': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      '& > .icon': {
        verticalAlign: 'middle',
      },
    },
  },
})

export function CompetitorItem({
  operator,
  technology,
  tariffName,
  mobileData,
  downSpeed,
  promotionPrice,
  promotionMonths,
  price,
  savings,
}) {
  const classes = useStyles()

  return (
    <Box display="flex" alignItems="center" className={classes.competitors} key={tariffName}>
      <Typography
        {...TextStyles.dataDark({
          component: 'span',
          className: 'p-r-16',
        })}>
        {operator}:
      </Typography>

      <Chip label={technology === 'FIBER' ? 'Fibra' : technology} color="primary" size="small" />

      <Typography
        {...TextStyles.labelDark({
          component: 'span',
          className: 'thin p-r-16',
        })}>
        {`${tariffName} + ${Math.floor(mobileData)} Gb`}
      </Typography>

      <Box display="flex" alignItems="center" pr="16px">
        <Typography
          {...TextStyles.labelDark({
            component: 'span',
            className: 'thin',
          })}>
          {downSpeed}
        </Typography>
        <Icon className="icon">{ICONS.download}</Icon>
      </Box>

      <Typography
        {...TextStyles.dataDark({
          component: 'span',
          className: 'p-r-16',
        })}>
        {`${promotionPrice} €/mes (${promotionMonths} meses), después ${price} €/mes`}
      </Typography>

      <Chip label={`Ahorro de ${savings} €/año`} className="MuiChip-colorWarning" size="small" />
    </Box>
  )
}

CompetitorItem.propTypes = {
  operator: PropTypes.string,
  technology: PropTypes.string,
  tariffName: PropTypes.string,
  mobileData: PropTypes.string,
  downSpeed: PropTypes.string,
  promotionPrice: PropTypes.number,
  promotionMonths: PropTypes.number,
  price: PropTypes.number,
  savings: PropTypes.number,
}

export function TariffCompetitors({ tariffCompetitors, pickedTariff, isLoading }) {
  const noCompetitors = useMemo(() => pickedTariff.id && tariffCompetitors.length === 0, [
    pickedTariff,
    tariffCompetitors,
  ])
  if (!pickedTariff.id) {
    return (
      <Typography variant="body1" color="textSecondary" className="p-b-16">
        Selecciona una tarifa de cabecera
      </Typography>
    )
  }
  if (isLoading) {
    return <CircularProgress size="32px" color="secondary" />
  }
  if (noCompetitors) {
    return (
      <Typography variant="body1" color="textSecondary" className="p-b-16">
        No existen tarifas de la competencia
      </Typography>
    )
  }

  return tariffCompetitors.map(tariffCompetitor => <CompetitorItem {...tariffCompetitor} />)
}
TariffCompetitors.propTypes = {
  tariffCompetitors: PropTypes.array,
  pickedTariff: PropTypes.object,
  isLoading: PropTypes.bool
}
