import { CONSUMER, SIGNUP_TYPE_PORTABILITY } from 'services/global-constants'
import { PORTABILITY_TYPE_CONTRACT } from 'modules/SharedSales/constants'

export const BasketFilled = {
  account: {
    name: 'Isaac',
    surname1: 'Newton',
    surname2: 'Ayscough',
    documenttype: 'NIF',
    documentid: '81111111r',
    nationality: 'ES',
    phoneContact: '611111111',
    phoneNumber: '911111112',
    companyName: '',
    companyCif: '',
    salesSelector: '',
    segment: CONSUMER,
    constitutionDate: '',
    birthday: '31/12/1999',
    phoneContact2: '636666666',
    email: 'foo@bar.com',
    language: 'Español',
  },
  landlineOffer: {
    isPortabilityFromFtth: 'yes',
    isPortability: 'yes',
    selectedTechnology: 'FTTH',
    PAR: '',
    IUA: '',
    operator: 'YOIGO',
    signUpType: SIGNUP_TYPE_PORTABILITY,
    lineNumber: '911111112',
    isSameUser: 'yes',
    otherUser: {
      documentType: 'NIF',
      documentId: '81111111r',
      name: 'Isaac',
      surname1: 'Newton',
      surname2: 'Ayscough',
      companyName: '',
    },
    tariff: { id: 'G053CHRN2CVP012', name: 'Fibra 300 Mb + La Sinfín 25 GB PRO' },
    routerNumber: '',
  },
  mainMobileLineOffer: {
    signUpType: SIGNUP_TYPE_PORTABILITY,
    lineNumber: '679988656',
    portabilityType: PORTABILITY_TYPE_CONTRACT,
    iccNumber: '',
    isSameUser: 'yes',
    otherUser: {
      documentType: '',
      documentId: '',
      name: '',
      surname1: '',
      surname2: '',
      companyName: '',
    },
    enabledSearchNewNumber: false,
    desiredNumber: '3666',
    selectedNewNumber: '636663666',
    isNewTerminal: 'no',
    terminal: { id: 'G020PANA2', manufacturer: 'Google', paymentType: 'postpaid' },
    isPortabilityDate: false,
    portabilityDate: { province: '', date: null },
    tariff: {
      id: '',
      name: '',
    },
    enableSearchStock: false,
  },
  extraMobileLinesOffers: [
    {
      tariff: { id: 'CONTFH69_207', name: 'Con fin 8' },
      signUpType: SIGNUP_TYPE_PORTABILITY,
      lineNumber: '636663666',
      portabilityType: PORTABILITY_TYPE_CONTRACT,
      iccNumber: '',
      isSameUser: 'yes',
      otherUser: {
        documentType: '',
        documentId: '',
        name: '',
        surname1: '',
        surname2: '',
        companyName: '',
      },
      enabledSearchNewNumber: false,
      desiredNumber: '3666',
      selectedNewNumber: '636663666',
      isNewTerminal: 'no',
      terminal: { id: '2', manufacturer: 'Samsung', paymentType: 'several' },
      isPortabilityDate: false,
      portabilityDate: { province: '', date: null },
    },
  ],
  shipping: {
    selectedAddressType: 'installationAddress',
    otherAddress: {
      address: 'Calle Frida Kahlo',
      number: '2',
      flat: '1A',
      zipCode: '28051',
      otherInfo: '',
      door: '',
      city: 'Madrid',
      province: 'MADRID',
    },
    deliveryPoint: { zipCode: '11111', selectedPointId: '1' },
  },
  billing: {
    ibanNumber: 'ES6000491500051234567892',
    selectedAddressType: 'installationAddress',
    otherAddress: {
      address: 'Calle Frida Kahlo',
      number: '2',
      flat: '1A',
      zipCode: '28051',
      otherInfo: '',
      door: '',
      city: 'Madrid',
      province: 'MADRID',
    },
    isPaperInvoice: '',
  },
}
