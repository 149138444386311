// NOTE: Actions with CVM infix are handled by this module's reducer
export const actionTypes = {
  UPDATE_SUBSCRIPTION: 'OVID/CVM/UPDATE_SUBSCRIPTION',
  CLEAR_SUBSCRIPTION: 'OVID/CVM/CLEAR_SUBSCRIPTION',
  CLEAR_ALL_SUBSCRIPTIONS: 'OVID/CVM/CLEAR_ALL_SUBSCRIPTIONS',
  OPEN_TYPIFICATION: 'OVID/CVM/OPEN_TYPIFICATION',
  CLOSE_TYPIFICATION: 'OVID/CVM/CLOSE_TYPIFICATION',
  SET_ERROR: 'OVID/CVM/SET_ERROR',
  SET_FINISHED: 'OVID/CVM/SET_FINISHED',
  CLEAR_ERROR: 'OVID/CVM/CLEAR_ERROR',
  INIT_OFFERS_CONFIGURATION: 'OVID/CVM/INIT_OFFERS_CONFIGURATION',
  UPDATE_SUBSCRIPTIONS: 'OVID/CVM/UPDATE_SUBSCRIPTIONS',
  UPDATE_AND_SEND_SUBSCRIPTION: 'OVID/CVM/UPDATE_AND_SEND_SUBSCRIPTION',
  DISABLE_SUBSCRIPTION_DISCOUNTS: 'OVID/CVM/DISABLE_SUBSCRIPTION_DISCOUNTS',
  SET_SUBSCRIPTION_SVAS: 'OVID/CVM/SET_SUBSCRIPTION_SVAS',
  INIT_TARIFFS_V3: 'OVID/CVM/INIT_TARIFFS_V3',
  FETCH_TARIFFS_V3: 'OVID/CVM/FETCH_TARIFFS_V3',
  FETCH_TARIFFS_SUCCESS_V3: 'OVID/CVM/FETCH_TARIFFS_SUCCESS_V3',
  FETCH_TARIFFS_FAILED_V3: 'OVID/CVM/FETCH_TARIFFS_FAILED_V3',
  INIT_ORDER_ELEGIBILITY: 'OVID/CVM/INIT_ORDER_ELEGIBILITY',
  FETCH_ORDER_ELEGIBILITY: 'OVID/CVM/FETCH_ORDER_ELEGIBILITY',
  FETCH_ORDER_SUCCESS_ELEGIBILITY: 'OVID/CVM/FETCH_ORDER_SUCCESS_ELEGIBILITY',
  FETCH_ORDER_FAILED_ELEGIBILITY: 'OVID/CVM/FETCH_ORDER_FAILED_ELEGIBILITY',
  FETCH_ORDER_RESET_ELEGIBILITY: 'OVID/CVM/FETCH_ORDER_RESET_ELEGIBILITY',
  INIT_ORDER_CONSEQUENCES: 'OVID/CVM/INIT_ORDER_CONSEQUENCES',
  FETCH_ORDER_CONSEQUENCES: 'OVID/CVM/FETCH_ORDER_CONSEQUENCES',
  FETCH_ORDER_SUCCESS_CONSEQUENCES: 'OVID/CVM/FETCH_ORDER_SUCCESS_CONSEQUENCES',
  FETCH_ORDER_FAILED_CONSEQUENCES: 'OVID/CVM/FETCH_ORDER_FAILED_CONSEQUENCES',
  FETCH_ORDER_CONSEQUENCES_RESET: 'OVID/CVM/FETCH_ORDER_CONSEQUENCES_RESET',
  INIT_PARENT_TECHNOLOGY: 'OVID/CVM/INIT_PARENT_TECHNOLOGY',
  INIT_COVERAGE_TECHNOLOGY: 'OVID/CVM/INIT_COVERAGE_TECHNOLOGY',
  FETCH_PARENT_TECHNOLOGY_SUCCESS: 'OVID/CVM/FETCH_PARENT_TECHNOLOGY_SUCCESS',
  FETCH_COVERAGE_TECHNOLOGY_SUCCESS: 'OVID/CVM/FETCH_COVERAGE_TECHNOLOGY_SUCCESS',
  FETCH_PARENT_TECHNOLOGY_FAILED: 'OVID/CVM/FETCH_PARENT_TECHNOLOGY_FAILED',
  FETCH_COVERAGE_TECHNOLOGY_FAILED: 'OVID/CVM/FETCH_COVERAGE_TECHNOLOGY_FAILED',
  NOTIFY_PERMANENCE_MODIFICATION_ATTEMPTED: 'OVID/CVM/NOTIFY_PERMANENCE_MODIFICATION/ATTEMPT',
  NOTIFY_PERMANENCE_MODIFICATION_SUCCEEDED: 'OVID/CVM/NOTIFY_PERMANENCE_MODIFICATION/SUCCESS',
  NOTIFY_PERMANENCE_MODIFICATION_FAILED: 'OVID/CVM/NOTIFY_PERMANENCE_MODIFICATION/FAILED',
  NOTIFY_DISCOUNT_APPLICATION_ATTEMPTED: 'OVID/CVM/NOTIFY_DISCOUNT_APPLICATION/ATTEMPT',
  NOTIFY_DISCOUNT_APPLICATION_SUCCEEDED: 'OVID/CVM/NOTIFY_DISCOUNT_APPLICATION/SUCCESS',
  NOTIFY_DISCOUNT_APPLICATION_FAILED: 'OVID/CVM/NOTIFY_DISCOUNT_APPLICATION/FAILED',
  NOTIFY_DISCOUNTS_DISABLE_ATTEMPTED: 'OVID/CVM/NOTIFY_DISCOUNTS_DISABLE/ATTEMPT',
  NOTIFY_DISCOUNTS_DISABLE_SUCCEEDED: 'OVID/CVM/NOTIFY_DISCOUNTS_DISABLE/SUCCESS',
  NOTIFY_DISCOUNTS_DISABLE_FAILED: 'OVID/CVM/NOTIFY_DISCOUNTS_DISABLE/FAILED',
  NOTIFY_DISCOUNTS_DISABLE_RESET: 'OVID/CVM/NOTIFY_DISCOUNTS_DISABLE/RESET',
  NOTIFY_SET_SVAS_ATTEMPTED: 'OVID/CVM/NOTIFY_SET_SVAS/ATTEMPT',
  NOTIFY_SET_SVAS_SUCCEEDED: 'OVID/CVM/NOTIFY_SET_SVAS/SUCCESS',
  NOTIFY_SET_SVAS_FAILED: 'OVID/CVM/NOTIFY_SET_SVAS/FAILED',
  NOTIFY_SET_SVAS_RESET: 'OVID/CVM/NOTIFY_SET_SVAS/RESET',
  INIT_COMMERCIAL_MIGRATION: 'OVID/CVM/INIT_COMMERCIAL_MIGRATION',
  FETCH_COMMERCIAL_MIGRATION_SUCCEDED: 'OVID/CVM/FETCH_COMMERCIAL_MIGRATION_SUCCEDED',
  FETCH_COMMERCIAL_MIGRATION_FAILED: 'OVID/CVM/FETCH_COMMERCIAL_MIGRATION_FAILED',
  INIT_CHANGE_ADDRESS: 'OVID/CVM/INIT_CHANGE_ADDRESS',
  FETCH_CHANGE_ADDRESS_SUCCEDED: 'OVID/CVM/FETCH_CHANGE_ADDRESS_SUCCEDED',
  FETCH_CHANGE_ADDRESS_FAILED: 'OVID/CVM/FETCH_CHANGE_ADDRESS_FAILED',
  INIT_CHANGE_TECHNOLOGY: 'OVID/CVM/INIT_CHANGE_TECHNOLOGY',
  FETCH_CHANGE_TECHNOLOGY_SUCCEDED: 'OVID/CVM/FETCH_CHANGE_TECHNOLOGY_SUCCEDED',
  FETCH_CHANGE_TECHNOLOGY_FAILED: 'OVID/CVM/FETCH_CHANGE_TECHNOLOGY_FAILED',
  INIT_INSTALLER: 'OVID/CVM/INIT_INSTALLER',
  FETCH_INSTALLER_SUCCEDED: 'OVID/CVM/FETCH_INSTALLER_SUCCEDED',
  FETCH_INSTALLER_FAILED: 'OVID/CVM/FETCH_INSTALLER_FAILED',
}

export const updateSubscription = (msisdn, changes) => ({
  type: actionTypes.UPDATE_SUBSCRIPTION,
  payload: { msisdn, data: changes },
})

export const clearSubscription = msisdn => ({
  type: actionTypes.CLEAR_SUBSCRIPTION,
  payload: { msisdn },
})

export const clearAllSubscriptions = () => ({
  type: actionTypes.CLEAR_ALL_SUBSCRIPTIONS,
})

export const setError = error => ({
  type: actionTypes.SET_ERROR,
  payload: error,
})

export const setFinished = () => ({
  type: actionTypes.SET_FINISHED,
})

export const clearError = () => ({
  type: actionTypes.CLEAR_ERROR,
})

export const openTypification = () => ({
  type: actionTypes.OPEN_TYPIFICATION,
})

export const closeTypification = () => ({
  type: actionTypes.CLOSE_TYPIFICATION,
})

export const initCustomerOffersConfiguration = () => ({
  type: actionTypes.INIT_OFFERS_CONFIGURATION,
})

export const updateSubscriptions = (data, optionsV3, migrationOrStc, sendSms, smsContent) => {
  return {
    type: actionTypes.UPDATE_SUBSCRIPTIONS,
    payload: { data, optionsV3, migrationOrStc, sendSms, smsContent },
  }
}

export const updateAndSendSubscription = (
  msisdn,
  subscriptionId,
  changes,
  optionsV3,
  migrationOrStc,
) => ({
  type: actionTypes.UPDATE_AND_SEND_SUBSCRIPTION,
  payload: { msisdn, subscriptionId, changes, optionsV3, migrationOrStc },
})

export const disableSubscriptionDiscounts = (msisdn, subscriptionId, changes) => ({
  type: actionTypes.DISABLE_SUBSCRIPTION_DISCOUNTS,
  payload: { msisdn, subscriptionId, changes },
})

export const setSubscriptionSvas = (msisdn, changes) => ({
  type: actionTypes.SET_SUBSCRIPTION_SVAS,
  payload: { msisdn, changes },
})

export const initTariffsV3 = (
  channel,
  status,
  tariffSegment,
  subscriptionId,
  phase2,
  purpose,
  territoryOwner,
) => ({
  type: actionTypes.INIT_TARIFFS_V3,
  payload: { channel, status, tariffSegment, subscriptionId, phase2, purpose, territoryOwner },
})

export const fetchTariffsV3 = tariffs => ({
  type: actionTypes.FETCH_TARIFFS_V3,
  payload: { tariffs },
})

export const fetchTariffsSuccessV3 = () => ({
  type: actionTypes.FETCH_TARIFFS_SUCCESS_V3,
})

export const fetchTariffsFailedV3 = error => ({
  type: actionTypes.FETCH_TARIFFS_FAILED_V3,
  payload: { error },
})

export const initOrderElegibility = (
  subscription,
  newTariff,
  activationStamp,
  purpose,
  territoryOwner,
) => ({
  type: actionTypes.INIT_ORDER_ELEGIBILITY,
  payload: { subscription, newTariff, activationStamp, purpose, territoryOwner },
})

export const fetchOrderElegibility = response => ({
  type: actionTypes.FETCH_ORDER_ELEGIBILITY,
  payload: { response },
})

export const fetchOrderSuccessElegibility = () => ({
  type: actionTypes.FETCH_ORDER_SUCCESS_ELEGIBILITY,
})

export const fetchOrderFailedElegibility = error => ({
  type: actionTypes.FETCH_ORDER_FAILED_ELEGIBILITY,
  payload: { error },
})

export const fetchOrderResetElegibility = () => ({
  type: actionTypes.FETCH_ORDER_RESET_ELEGIBILITY,
})

export const initOrderConsequences = (subscriptionId, tariffId) => ({
  type: actionTypes.INIT_ORDER_CONSEQUENCES,
  payload: { subscriptionId, tariffId },
})

export const fetchOrderConsequences = response => ({
  type: actionTypes.FETCH_ORDER_CONSEQUENCES,
  payload: { response },
})

export const fetchOrderSuccessConsequences = () => ({
  type: actionTypes.FETCH_ORDER_SUCCESS_CONSEQUENCES,
})

export const fetchOrderFailedConsequences = error => ({
  type: actionTypes.FETCH_ORDER_FAILED_CONSEQUENCES,
  payload: { error },
})

export const fetchOrderConsequencesReset = () => ({
  type: actionTypes.FETCH_ORDER_CONSEQUENCES_RESET,
})

export const initParentTechnology = territoryOwner => ({
  type: actionTypes.INIT_PARENT_TECHNOLOGY,
  payload: { territoryOwner },
})

export const initCoverageTechnology = territoryOwner => ({
  type: actionTypes.INIT_COVERAGE_TECHNOLOGY,
  payload: { territoryOwner },
})

export const fetchParentTechnologySuccess = id => ({
  type: actionTypes.FETCH_PARENT_TECHNOLOGY_SUCCESS,
  payload: id,
})

export const fetchCoverageTechnologySuccess = id => ({
  type: actionTypes.FETCH_COVERAGE_TECHNOLOGY_SUCCESS,
  payload: id,
})

export const fetchParentTechnologyFailed = error => ({
  type: actionTypes.FETCH_PARENT_TECHNOLOGY_FAILED,
  payload: { error },
})

export const fetchCoverageTechnologyFailed = error => ({
  type: actionTypes.FETCH_COVERAGE_TECHNOLOGY_FAILED,
  payload: { error },
})

export const notifyPermanenceModification = (msisdn, amount, reason) => ({
  type: actionTypes.NOTIFY_PERMANENCE_MODIFICATION_ATTEMPTED,
  payload: { msisdn, amount, reason },
})

export const notifyPermanenceModificationSucceeded = (msisdn, data) => ({
  type: actionTypes.NOTIFY_PERMANENCE_MODIFICATION_SUCCEEDED,
  payload: { msisdn, data },
})

export const notifyPermanenceModificationFailed = (msisdn, error) => ({
  type: actionTypes.NOTIFY_PERMANENCE_MODIFICATION_FAILED,
  payload: { msisdn, error },
})

export const notifyDiscountApplication = (msisdn, subscriptionId, discount, activationStamp) => ({
  type: actionTypes.NOTIFY_DISCOUNT_APPLICATION_ATTEMPTED,
  payload: { msisdn, subscriptionId, activationStamp, ...discount },
})

export const notifyDiscountApplicationSucceeded = (msisdn, discountCode) => ({
  type: actionTypes.NOTIFY_DISCOUNT_APPLICATION_SUCCEEDED,
  payload: { msisdn, discountCode },
})

export const notifyDiscountApplicationFailed = (msisdn, discountCode, error) => ({
  type: actionTypes.NOTIFY_DISCOUNT_APPLICATION_FAILED,
  payload: { msisdn, discountCode, error },
})

export const notifyDiscountsDisable = () => ({
  type: actionTypes.NOTIFY_DISCOUNTS_DISABLE_ATTEMPTED,
  payload: null,
})

export const notifyDiscountsDisableSucceeded = (msisdn, succeededDiscounts) => ({
  type: actionTypes.NOTIFY_DISCOUNTS_DISABLE_SUCCEEDED,
  payload: { msisdn, succeededDiscounts },
})

export const notifyDiscountsDisableFailed = (
  msisdn,
  succeededDiscounts,
  failedDiscounts,
  error,
) => ({
  type: actionTypes.NOTIFY_DISCOUNTS_DISABLE_FAILED,
  payload: { msisdn, succeededDiscounts, failedDiscounts, error },
})

export const notifyDiscountsDisableReset = () => ({
  type: actionTypes.NOTIFY_DISCOUNTS_DISABLE_RESET,
  payload: null,
})

export const notifySetSvas = () => ({
  type: actionTypes.NOTIFY_SET_SVAS_ATTEMPTED,
  payload: null,
})

export const notifySetSvasSucceeded = (msisdn, succeededSvas) => ({
  type: actionTypes.NOTIFY_SET_SVAS_SUCCEEDED,
  payload: { msisdn, succeededSvas },
})

export const notifySetSvasFailed = (msisdn, succeededSvas, failedSvas, error) => ({
  type: actionTypes.NOTIFY_SET_SVAS_FAILED,
  payload: { msisdn, succeededSvas, failedSvas, error },
})

export const notifySetSvasReset = () => ({
  type: actionTypes.NOTIFY_SET_SVAS_RESET,
  payload: null,
})

export const initCommercialMigration = options => ({
  type: actionTypes.INIT_COMMERCIAL_MIGRATION,
  payload: options,
})

export const fetchCommercialMigrationSucceeded = payload => ({
  type: actionTypes.FETCH_COMMERCIAL_MIGRATION_SUCCEDED,
  payload,
})

export const fetchCommercialMigrationFailed = error => ({
  type: actionTypes.FETCH_COMMERCIAL_MIGRATION_FAILED,
  payload: { error },
})

export const initChangeAddress = options => ({
  type: actionTypes.INIT_CHANGE_ADDRESS,
  payload: options,
})

export const fetchChangeAddressSucceeded = payload => ({
  type: actionTypes.FETCH_CHANGE_ADDRESS_SUCCEDED,
  payload,
})

export const fetchChangeAddressFailed = error => ({
  type: actionTypes.FETCH_CHANGE_ADDRESS_FAILED,
  payload: { error },
})

export const initChangeTechnology = options => ({
  type: actionTypes.INIT_CHANGE_TECHNOLOGY,
  payload: options,
})

export const fetchChangeTechnologySucceeded = payload => ({
  type: actionTypes.FETCH_CHANGE_TECHNOLOGY_SUCCEDED,
  payload,
})

export const fetchChangeTechnologyFailed = error => ({
  type: actionTypes.FETCH_CHANGE_TECHNOLOGY_FAILED,
  payload: { error },
})

export const initInstaller = gescal17 => ({
  type: actionTypes.INIT_INSTALLER,
  payload: gescal17,
})

export const fetchInstallerSucceeded = payload => ({
  type: actionTypes.FETCH_INSTALLER_SUCCEDED,
  payload,
})

export const fetchInstallerFailed = error => ({
  type: actionTypes.FETCH_INSTALLER_FAILED,
  payload: { error },
})
