import { axiosCustomer } from 'modules/axios'
import { CONFIG } from './environments'

const customersAPIUrl = `${CONFIG.api.customers}`

export function find(customerId) {
  return axiosCustomer({
    method: 'GET',
    url: `${customersAPIUrl}/${customerId}?expand=contactMedium,individualIdentification,characteristic,productNumber`,
  }).then(resp => resp.data)
}
