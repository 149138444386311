/**
 * Simple state machine component to create views that
 * navigate from one component to another
 *
 * It exposes the 'goToStep' method to the components
 * passed as 'steps'
 *
 * Example usage:
 *  <MultiStep
 *    initialStep='selection'
 *    steps={{
 *      'selection': ({goToStep}) => (
 *          <SelectionStep next={() => goToStep('confirmation')} />
 *       ),
 *      'confirmation': ({goToStep}) => (
 *          <ConfirmationStep previous={() => goToStep('selection')} />
 *        ),
 *    }}
 *  />
 */
import { useState } from 'react'
import PropTypes from 'prop-types'

const MultiStep = ({ currentStep, steps, forceStep, goToStep, goBack }) => {
  const stepComponent = steps[forceStep || currentStep]
  if (!stepComponent) {
    console.warn(`Step ${currentStep} does not exist, make sure it's spelled correctly`)
    return null
  }

  return stepComponent({ goToStep, goBack })
}

MultiStep.propTypes = {
  steps: PropTypes.shape({ [PropTypes.string]: PropTypes.node }).isRequired,
}

const MutiStepContainer = ({ initialStep, ...rest }) => {
  const [currentStep, setCurrentStep] = useState(initialStep)
  const [previousStep, setPreviousStep] = useState(null)

  const goToStep = step => {
    setPreviousStep(currentStep)
    setCurrentStep(step)
  }

  const goBack = () => {
    setPreviousStep(currentStep)
    setCurrentStep(previousStep)
  }

  const props = {
    ...rest,
    currentStep,
    goToStep,
    goBack,
  }
  return <MultiStep {...props} />
}
MutiStepContainer.propTypes = {
  initialStep: PropTypes.string.isRequired,
}

export default MutiStepContainer
