import PropTypes from 'prop-types'

import { Typography, Box } from '@material-ui/core'
import { ButtonLink } from 'components/ui'

export function AddressSummary({
  installationFullAddress,
  shippingFullAddress,
  terminalsAddress,
  onClickChangeAddress,
  isMobileOnly,
  isElFijo,
  hasDeliveryAddress,
  isPos,
  isSubscription,
}) {
  return (
    <>
      <Typography variant="subtitle1" color="secondary" className="bold m-b-20">
        DIRECCIONES
      </Typography>
      {!isMobileOnly && !isSubscription && (
        <>
          <Box display="flex" alignItems="center" justifyContent="flex-start" mb="4px">
            <Typography variant="body1" className="p-r-4">
              Dirección de {isElFijo ? 'servicio' : 'instalación'}:{' '}
            </Typography>
            <Typography variant="body1" className="bold" data-hook="intallationAddress.fullAddress">
              {installationFullAddress}
            </Typography>
          </Box>
          <ButtonLink
            to="info?isEditing=true"
            className="flex m-b-4"
            onClick={onClickChangeAddress}>
            Modificar
          </ButtonLink>
        </>
      )}
      {!isPos && (
        <>
          <Box display="flex" alignItems="center" justifyContent="flex-start" mb="4px">
            <Typography variant="body1" className="p-r-4">
              Dirección de entrega:
            </Typography>
            <Typography variant="body1" className="bold">
              <span data-hook="delivery.fullAddress">{shippingFullAddress}</span>
            </Typography>
          </Box>
          {hasDeliveryAddress && (
            <ButtonLink
              to="billing?isEditing=true"
              className="m-b-4 flex"
              onClick={onClickChangeAddress}>
              Modificar datos de entrega
            </ButtonLink>
          )}
          {terminalsAddress && !isMobileOnly && !isElFijo && !isSubscription && (
            <>
              <Box display="flex" alignItems="center" justifyContent="flex-start" mb="4px">
                <Typography variant="body1" className="p-r-4">
                  Dirección de entrega dispositivo:{' '}
                </Typography>
                <Typography variant="body1" className="bold">
                  {terminalsAddress}
                </Typography>
              </Box>
              <ButtonLink
                to="billing?isEditing=true"
                className="m-b-4 flex"
                onClick={onClickChangeAddress}>
                Modificar datos de entrega
              </ButtonLink>
            </>
          )}
        </>
      )}
    </>
  )
}

AddressSummary.propTypes = {
  installationFullAddress: PropTypes.string.isRequired,
  isMobileOnly: PropTypes.bool.isRequired,
  shippingFullAddress: PropTypes.string,
  terminalsAddress: PropTypes.string,
  onClickChangeAddress: PropTypes.func,
  isElFijo: PropTypes.bool.isRequired,
  hasDeliveryAddress: PropTypes.bool,
  isSubscription: PropTypes.bool,
  isPos: PropTypes.bool.isRequired,
}
