import PropTypes from 'prop-types'
import { FormSelectOutlined } from 'components/ui'
import { makeStyles } from '@material-ui/styles'
import { useSelector } from 'react-redux'
import { get } from 'lodash'
import { applyTax, getTax } from 'services/taxes'
import { STATUS_RETIRED } from 'modules/tariffs/tariffs.constants'

import { formatMonthlyFee } from 'utils'
import { buildTariffName } from 'modules/tariffs/helpers/tariffs.helpers'
import { selectors as customerSelectors } from 'services/customer-360'
import { getMonthlyFeeTariff, getMonthlyPricesTaxFree } from 'modules/offers-configuration/helpers'
import { selectStcV3Flag } from 'services/feature-flag/selectors'
import { FormContainer } from './FormContainer'

const useStyles = makeStyles({
  'retired-tariff': {
    color: '#d02f0c',
  },
})

const SelectTariff = ({
  selectedTariff,
  onTariffSelect,
  options,
  date,
  onDateSelect,
  dateOptions,
  taxNeeded,
  isLoyaltyAgent,
  isUnsusbcribe,
}) => {
  const isStcV3Flag = useSelector(selectStcV3Flag)
  const showV3Tariffs = isStcV3Flag && !isUnsusbcribe
  const billingAddress = useSelector(state => customerSelectors.getBillingAddress(state))
  const postCode = get(billingAddress, 'postCode')
  const tax = useSelector(state => getTax(state, postCode))
  const totalWithTax = value => {
    return (taxNeeded && applyTax(tax, value)) || value
  }
  const classes = useStyles()

  return (
    <>
      <FormContainer>
        <FormSelectOutlined
          label="Selecciona la nueva tarifa"
          minWidth="392px"
          value={selectedTariff}
          options={options.map(tariff => ({ value: tariff.id, ...tariff }))}
          onChange={onTariffSelect}>
          {tariff => (
            <div
              className={
                showV3Tariffs && tariff.status === STATUS_RETIRED ? classes['retired-tariff'] : ''
              }>
              <span>{buildTariffName(tariff.id, tariff.commercial_name, isLoyaltyAgent)}</span>
              {tariff?.calculatedDiscounts?.discounts?.length > 0 && (
                <>
                  <span style={{ textDecoration: 'line-through' }}>
                    {formatMonthlyFee(totalWithTax(getMonthlyPricesTaxFree(tariff)))}
                  </span>
                  {' -'}
                </>
              )}
              <span>{formatMonthlyFee(totalWithTax(getMonthlyFeeTariff(tariff)))}</span>
            </div>
          )}
        </FormSelectOutlined>
      </FormContainer>
      {selectedTariff && (
        <FormContainer>
          <FormSelectOutlined
            label="¿Cuándo hago el cambio?"
            minWidth="265px"
            value={date}
            options={dateOptions}
            onChange={onDateSelect}
          />
        </FormContainer>
      )}
    </>
  )
}

SelectTariff.propTypes = {
  selectedTariff: PropTypes.string,
  onTariffSelect: PropTypes.func.isRequired,
  options: PropTypes.array,
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onDateSelect: PropTypes.func.isRequired,
  dateOptions: PropTypes.array,
  taxNeeded: PropTypes.bool,
  isLoyaltyAgent: PropTypes.bool,
  isUnsusbcribe: PropTypes.bool,
}

export default SelectTariff
