import { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'

import { useDispatch, useSelector } from 'react-redux'

import { useFormikContext } from 'formik'

import {
  cleanOtherAddressAction,
  setSelectedAddressTypeAction,
} from 'modules/NewClientSales/store/actions'
import { selectIsPosSale } from 'modules/NewClientSales/store/selectors/index'
import { ADSL, ADSL_LOWER_CASED, FTTH } from 'modules/SharedSales/constants'
import { actions as taxes } from 'services/taxes'

import { SIGNUP_TYPE_PORTABILITY } from 'services/global-constants/signUpTypes'
import { selectIs2p } from 'modules/Router/store/router.selectors'
import { Billing } from '../../components/Billing'
import { SUMMARY } from '../../constants'
import { withCheckoutForms } from '../../hocs/withCheckoutForms'
import { billingContainerSelectors } from '../../store'

function BillingCnt({ setBasketData, isEditing, navigateToNextStep }) {
  const { setFieldValue } = useFormikContext()

  const dispatch = useDispatch()

  function onEditAddress(type) {
    dispatch(cleanOtherAddressAction(type))
  }

  function onSelectAddress(selectedAddress, type) {
    dispatch(setSelectedAddressTypeAction({ type, value: selectedAddress }))
  }
  const {
    landlineOffer: { IUA, operator, signUpType },
    billing,
    shipping,
    technology,
    terminalShipping,
    taxZipCode,
    billingAccountId,
    billingAddress,
    shippingAddress,
    ...storeSelectors
  } = useSelector(billingContainerSelectors)
  const isPos = useSelector(selectIsPosSale)
  const is2P = useSelector(selectIs2p)

  const formatedTechnology = technology === ADSL_LOWER_CASED ? ADSL : FTTH
  const findTax = useCallback(data => {
    dispatch(taxes.findTax(data))
  })
  function onContinue() {
    // TODO: Is mobile only, find tax on billing address?

    navigateToNextStep(SUMMARY, isEditing)
  }

  // TODO: This is done because the state is not updating the formik values
  // This should dissapear once the formik and the state are in sync
  useEffect(() => {
    setFieldValue('landlineOffer.IUA', IUA)
  }, [IUA])

  useEffect(() => {
    if (taxZipCode) {
      findTax(taxZipCode)
    }
  }, [taxZipCode])

  useEffect(() => {
    setFieldValue('billing.otherAddress', billing.otherAddress)
    setFieldValue('billing.selectedAddressType', billing.selectedAddressType)
  }, [billing.otherAddress, billing.selectedAddressType])

  useEffect(() => {
    setFieldValue('shipping.otherAddress', shipping.otherAddress)
    setFieldValue('shipping.selectedAddressType', shipping.selectedAddressType)
  }, [shipping.otherAddress, shipping.selectedAddressType])

  useEffect(() => {
    setFieldValue('terminalShipping', terminalShipping)
  }, [terminalShipping])

  useEffect(() => {
    if (billingAddress?.address || billingAddress?.formattedAddress) {
      setFieldValue(
        'billing.otherAddress.fullAddress',
        billingAddress?.formattedAddress || billingAddress?.address,
      )
    }
  }, [billingAddress])

  useEffect(() => {
    if (shippingAddress?.line?.address) {
      setFieldValue('landlineOffer.installationAddress.fullAddress', shippingAddress?.line?.address)
    }
  }, [shippingAddress])

  const isInternalPortabilityWithoutInstallation =
    technology !== ADSL &&
    signUpType === SIGNUP_TYPE_PORTABILITY &&
    operator.internalPortability &&
    !operator.installationRequired

  return (
    <Billing
      onContinue={onContinue}
      isEditing={isEditing}
      selectedTechnology={formatedTechnology}
      onEditAddress={onEditAddress}
      onSelectAddress={onSelectAddress}
      setBasketData={setBasketData}
      isPos={isPos}
      is2P={is2P}
      isInternalPortabilityWithoutInstallation={isInternalPortabilityWithoutInstallation}
      {...storeSelectors}
    />
  )
}

BillingCnt.propTypes = {
  setBasketData: PropTypes.func.isRequired,
  isEditing: PropTypes.bool,
  navigateToNextStep: PropTypes.func.isRequired,
}

export const BillingContainer = withCheckoutForms(BillingCnt)
