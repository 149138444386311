import { useState, useEffect } from 'react'

export const useLoading = prop => {
  const [lastValue, setLastValue] = useState(undefined)

  useEffect(() => {
    setLastValue(prop)
  }, [prop])

  return prop && prop !== lastValue
}
