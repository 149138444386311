import { axiosCustomer } from 'modules/axios'
import { CONFIG } from './environments'

const API_URL = `${CONFIG.api.ticketing}`
const TICKET_URL = `${API_URL}/ticket`
const TYPES_URL = `${TICKET_URL}/type`

export const getTicketPath = (id = null) => (!id ? TICKET_URL : `${TICKET_URL}/${id}`)
export const getTicketTypesPath = () => TYPES_URL
export const getTicketCategoriesPath = typeId => `${TYPES_URL}/${typeId}/category`
export const getSubcategoryPath = id => `${TICKET_URL}/category/${id}/subcategory`

export const findCategories = ticketTypeId =>
  axiosCustomer({
    method: 'GET',
    url: getTicketCategoriesPath(ticketTypeId),
  }).then(({ data }) => data)

export const findSubcategories = categoryId =>
  axiosCustomer({
    method: 'GET',
    url: getSubcategoryPath(categoryId),
  }).then(({ data }) => data)

export const findTicketTypes = () =>
  axiosCustomer({
    method: 'GET',
    url: getTicketTypesPath(),
  }).then(({ data }) => data)

export const findTickets = () =>
  axiosCustomer({
    method: 'GET',
    url: getTicketPath(),
  }).then(({ data }) => data)

export const findTicket = ticketId =>
  axiosCustomer({
    method: 'GET',
    url: getTicketPath(ticketId),
  }).then(({ data }) => data)

export const createTicket = (customerId, ticketData) =>
  axiosCustomer({
    method: 'POST',
    data: {
      ...ticketData,
      TKT_T_NumeroCliente__c: customerId,
    },
    url: getTicketPath(),
  }).then(({ data }) => data)
