import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import {
  selectFormatedMobileTariffs,
  selectMobileTariffsErrorType,
  selectFormatedAdditionalTariffs,
  selectAdditionalTariffsErrorType,
  selectSelectedExtraMobileTariffs,
} from 'modules/NewClientSales/store/selectors/index'

import {
  fetchTariffsAction,
  selectAdditionalMobileTariffsIsLoading,
  selectMainMobileTariffsIsLoading,
} from 'modules/tariffs/store'

import { useChannelAndSegment } from '../../hooks/Shared/index'

const selectUseMobileTariffs = createStructuredSelector({
  isLoadingTariffs: selectMainMobileTariffsIsLoading,
  tariffs: selectFormatedMobileTariffs,
  tariffsError: selectMobileTariffsErrorType,
})

const selectUseAdditionalTariffs = createStructuredSelector({
  isLoadingTariffs: selectAdditionalMobileTariffsIsLoading,
  tariffs: selectFormatedAdditionalTariffs,
  tariffsError: selectAdditionalTariffsErrorType,
  selectedExtraTariffs: selectSelectedExtraMobileTariffs,
})

// TODO: Pass correct type
// isElFijo ? 'mobile_fixed_line' : 'mobile'
export function useTariffs(params) {
  const dispatch = useDispatch()
  const { channel, segment } = useChannelAndSegment()

  // TODO add here logic for all different tariff types
  const { tariffs, isLoadingTariffs, tariffsError, ...rest } = useSelector(
    params.type === 'mobile' ? selectUseMobileTariffs : selectUseAdditionalTariffs,
  )

  const shouldLoadTariffs = useMemo(() => isLoadingTariffs === null && !tariffs.length, [
    isLoadingTariffs,
    tariffs,
  ])

  useEffect(() => {
    if (shouldLoadTariffs) {
      dispatch(
        fetchTariffsAction({
          segment,
          channel,
          ...params,
        }),
      )
    }
  }, [shouldLoadTariffs])

  return {
    tariffs,
    isLoadingTariffs,
    tariffsError,
    selectedExtraTariffs: rest.selectedExtraTariffs || [],
  }
}
