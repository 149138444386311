import { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Formik } from 'formik'
import { object, string } from 'yup'
import { useSelector, useDispatch } from 'react-redux'
import { Input, FormHelperText } from '@material-ui/core'
import { Button, ModalActions } from 'components/ui'
import {
  validateIBANAction,
  getIBANData,
  selectIBANIsLoading,
  selectIBANErrorMessage,
} from 'modules/Iban'

const ibanLength = 20

const AccountFields = styled.div`
  margin: 18px 0 2px;
`
const InputStyled = styled(Input)`
  box-sizing: border-box;
`
const NormalField = styled.div`
  width: 100px;
  display: inline-block;
`
const SmallField = styled.div`
  width: 82px;
  display: inline-block;
`
const BigField = styled.div`
  width: 258px;
  display: inline-block;
`

const createNumericRegexp = number => new RegExp(`^(\\d{${number}})$`)

const bankLength = 4
const bankRegex = createNumericRegexp(bankLength)

const branchLength = 4
const branchRegex = createNumericRegexp(branchLength)

const controlLength = 2
const controlRegex = createNumericRegexp(controlLength)

const accountLength = 10
const accountRegex = createNumericRegexp(accountLength)

const IBANSchema = object().shape({
  bank: string().matches(bankRegex),
  branch: string().matches(branchRegex),
  control: string().matches(controlRegex),
  account: string().matches(accountRegex),
})

const genericErrorMessage = 'El IBAN no es correcto'

const BankAccountForm = ({ errors, touched, values, handleChange, handleBlur, onConfirm }) => {
  const dispatch = useDispatch()

  const { bank, branch, control, account } = values
  const iban = bank + branch + control + account

  const isIBANLoading = useSelector(selectIBANIsLoading)
  const IBANData = useSelector(getIBANData)
  const IBANErrorMessage = useSelector(selectIBANErrorMessage)

  useEffect(() => {
    if (iban.length === ibanLength && !IBANData && !isIBANLoading && !IBANErrorMessage) {
      dispatch(validateIBANAction(iban))
    }
  })

  const onIBANSubmit = e => {
    e.preventDefault()
    onConfirm(iban)
  }

  const shouldShowErrorMessage =
    IBANErrorMessage ||
    Object.keys(errors).reduce((acc, name) => acc || (errors[name] && touched[name]), false)
  const shouldDisableSubmit = !IBANData
  return (
    <form onSubmit={onIBANSubmit}>
      <AccountFields>
        <NormalField>
          <InputStyled
            name="bank"
            inputProps={{
              maxLength: bankLength,
            }}
            value={values.bank}
            error={IBANErrorMessage || (errors.bank && touched.bank)}
            fullWidth
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={'0'.repeat(bankLength)}
          />
        </NormalField>
        <NormalField>
          <InputStyled
            name="branch"
            inputProps={{
              maxLength: branchLength,
            }}
            value={values.branch}
            error={IBANErrorMessage || (errors.branch && touched.branch)}
            fullWidth
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={'0'.repeat(branchLength)}
          />
        </NormalField>
        <SmallField>
          <InputStyled
            name="control"
            inputProps={{
              maxLength: controlLength,
            }}
            value={values.control}
            error={IBANErrorMessage || (errors.control && touched.control)}
            fullWidth
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={'0'.repeat(controlLength)}
          />
        </SmallField>
        <BigField>
          <InputStyled
            name="account"
            inputProps={{
              maxLength: accountLength,
            }}
            value={values.account}
            error={IBANErrorMessage || (errors.account && touched.account)}
            fullWidth
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={'0'.repeat(accountLength)}
          />
        </BigField>
        {shouldShowErrorMessage && <FormHelperText error>{genericErrorMessage}</FormHelperText>}
      </AccountFields>
      <ModalActions>
        <Button type="submit" disabled={shouldDisableSubmit}>
          Confirmar
        </Button>
      </ModalActions>
    </form>
  )
}

BankAccountForm.propTypes = {
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
}

const BankAccountStep = ({ onConfirm }) => (
  <>
    <p>
      No tenemos información de cuenta bancaria para este cliente. Tienes que añadirla para
      completar la solicitud.
    </p>
    <h2>INTRODUCE EL NÚMERO DE CUENTA</h2>
    <Formik
      initialValues={{ bank: '', branch: '', control: '', account: '' }}
      validationSchema={IBANSchema}>
      {formikProps => <BankAccountForm {...{ ...formikProps, onConfirm }} />}
    </Formik>
  </>
)
BankAccountStep.propTypes = {
  onConfirm: PropTypes.func.isRequired,
}

export default BankAccountStep
