import { createStructuredSelector } from 'reselect'
import { selectors as customer360Selectors } from 'services/customer-360/selectors'
import {
  selectRenewalSubscriptionTariffName,
  selectRenewalSubscriptionTariffPrice,
  selectRenewalMsisdn,
  selectRenewalDeviceType,
  selectRenewalSaleChannel,
  selectRenewalDeviceInfo,
  selectFinancedDeviceSummaryInfo,
} from 'modules/device-renewal/store/device-renewal.selectors'

export const sidebarSelectors = createStructuredSelector({
  clientFullName: customer360Selectors.getFullName,
  msisdn: selectRenewalMsisdn,
  tariffDescription: selectRenewalSubscriptionTariffName,
  tariffPrice: selectRenewalSubscriptionTariffPrice,
  deviceType: selectRenewalDeviceType,
  saleChannel: selectRenewalSaleChannel,
  device: selectRenewalDeviceInfo,
  financedDeviceDecisionsInfo: selectFinancedDeviceSummaryInfo,
})
