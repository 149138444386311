import { get } from 'lodash'
import { theme } from 'themes'

import {
  SUBSCRIPTION_STATUS_VALIDATED,
  SUBSCRIPTION_STATUS_ALERT,
  SUBSCRIPTION_STATUS_INFO,
  SUBSCRIPTION_STATUS_ATTENTION,
  SUBSCRIPTION_STATUS_BLOCKED,
  SUBSCRIPTION_STATUS_DEFAULT,
  SUBSCRIPTION_STATUS_OT,
} from 'services/subscriptions/constants'

export const statusColors = {
  new: SUBSCRIPTION_STATUS_VALIDATED,
  active: SUBSCRIPTION_STATUS_VALIDATED,
  port: SUBSCRIPTION_STATUS_ALERT,
  freeze: SUBSCRIPTION_STATUS_INFO,
  fly: SUBSCRIPTION_STATUS_ATTENTION,
  unsubscribe: SUBSCRIPTION_STATUS_BLOCKED,
  historical: SUBSCRIPTION_STATUS_DEFAULT,
  incidence: SUBSCRIPTION_STATUS_BLOCKED,
  unknown: SUBSCRIPTION_STATUS_ATTENTION,
  cancelled: SUBSCRIPTION_STATUS_BLOCKED,
  delivered: SUBSCRIPTION_STATUS_VALIDATED,
  mobile: SUBSCRIPTION_STATUS_INFO,
  ot: SUBSCRIPTION_STATUS_OT,
}

export const getBorderFromStatusType = status => {
  const BORDER_PROPS = '10px solid'
  switch (get(statusColors, status)) {
    case SUBSCRIPTION_STATUS_INFO:
      return `${BORDER_PROPS} ${theme.palette.brand.action_primary}`
    case SUBSCRIPTION_STATUS_BLOCKED:
      return `${BORDER_PROPS} ${theme.palette.global.gray_dark}`
    case SUBSCRIPTION_STATUS_ATTENTION:
      return `${BORDER_PROPS} ${theme.palette.brand.attention}`
    case SUBSCRIPTION_STATUS_VALIDATED:
      return `${BORDER_PROPS} ${theme.palette.brand.validated}`
    case SUBSCRIPTION_STATUS_ALERT:
      return `${BORDER_PROPS} ${theme.palette.brand.alert}`
    case SUBSCRIPTION_STATUS_OT:
      return `3px solid ${theme.palette.brand.attention}`
    default:
      return `${BORDER_PROPS} ${theme.palette.global.gray_light}`
  }
}

export const getColorFromStatusType = status => {
  switch (get(statusColors, status)) {
    case SUBSCRIPTION_STATUS_INFO:
      return theme.palette.brand.action_primary
    case SUBSCRIPTION_STATUS_BLOCKED:
      return theme.palette.global.gray_dark
    case SUBSCRIPTION_STATUS_ATTENTION:
      return theme.palette.brand.attention
    case SUBSCRIPTION_STATUS_VALIDATED:
      return theme.palette.brand.validated
    case SUBSCRIPTION_STATUS_ALERT:
      return theme.palette.brand.alert
    case SUBSCRIPTION_STATUS_OT:
      return theme.palette.brand.attention
    default:
      return theme.palette.global.gray_light
  }
}
