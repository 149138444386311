import PropTypes from 'prop-types'
import { Table, TableCell, TableRow } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const joinClassWithStyles = (styles, className) =>
  [styles, ...(className ? [className] : [])].join(' ')

const useStyles = makeStyles(theme => ({
  'themed-cell-header': {
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: '0.7px',
    color: theme.palette.global.gray_dark,
    backgroundSolor: theme.palette.global.gray_light,
    textAlign: ({ align }) => align || 'left',
    width: ({ width }) => width || 'unset',
  },
  'themed-row': {
    '& th:first-child': {
      borderTopLeftRadius: '14px',
    },

    '& th:last-child': {
      borderTopRightRadius: '14px',
    },
  },

  'themed-table': {
    backgroundColor: theme.palette.global.gray_light,
    borderRadius: '14px',
    boxShadow: `0 0px 0px 1px ${theme.palette.global.gray_medium_04}`,

    '& tbody > tr:last-child > td': {
      borderWidth: '0px',
    },

    '& tbody > tr > td:last-child': {
      borderLeft: `1px solid ${theme.palette.global.gray_medium_04}`,
    },

    '& tbody > tr > td:first-child': {
      fontWeight: 'bold',
      padding: '0 5px 0 20px',
      textAlign: 'left',
      fontSize: '12px',
    },

    '& tbody > tr > td': {
      fontSize: '14px',
      textAlign: 'center',
      padding: '5.5px 5px',
    },

    '& thead > tr > th': {
      fontSize: '12px',
      textAlign: 'center',
      lineHeight: 1.2,
    },

    '& thead > tr > th:first-child': {
      padding: '0 5px 0 20px',
      textAlign: 'left',
      fontSize: '12px',
    },

    display: 'flex',
    flexFlow: 'column',
    height: ' 100%',
    width: '100%',

    '& thead': {
      flex: '0 0 auto',
      width: 'calc(100% - 1em)',
    },

    '& tbody': {
      flex: '1 1 auto',
      display: 'block',
      overflowY: 'scroll',
      overflowX: 'hidden',
      borderBottomLeftRadius: '14px',
      borderBottomRightRadius: '14px',
      backgroundColor: theme.palette.brand.light,
    },

    '& tbody tr': {
      width: '100%',
    },

    '& thead, & tbody tr': {
      display: 'table',
      tableLayout: 'fixed',
    },
  },
  'themed-cell': {
    textAlign: ({ align }) => align || 'left',
    width: ({ width }) => width || 'unset',
    padding: '0 0 0 20px',
    backgroundColor: theme.palette.brand.light,
    color: `${theme.palette.global.gray_dark} !important`,
  },
}))

export const ThemedCellHeader = ({ width, align, className, ...rest }) => {
  const classes = useStyles({ width, align })

  return (
    <TableCell
      className={joinClassWithStyles(classes['themed-cell-header'], className)}
      {...rest}
    />
  )
}
ThemedCellHeader.propTypes = {
  width: PropTypes.string,
  align: PropTypes.string,
  className: PropTypes.string,
}

export const ThemedTableRow = ({ className, ...props }) => {
  const classes = useStyles()

  return <TableRow className={joinClassWithStyles(classes['themed-row'], className)} {...props} />
}
ThemedTableRow.propTypes = {
  className: PropTypes.string,
}

export const ThemedTable = ({ className, ...props }) => {
  const classes = useStyles()

  return <Table className={joinClassWithStyles(classes['themed-table'], className)} {...props} />
}
ThemedTable.propTypes = {
  className: PropTypes.string,
}

export const ThemedCell = ({ width, align, className, ...rest }) => {
  const classes = useStyles({ width, align })

  return <TableCell className={joinClassWithStyles(classes['themed-cell'], className)} {...rest} />
}
ThemedCell.propTypes = {
  width: PropTypes.string,
  align: PropTypes.string,
  className: PropTypes.string,
}
