import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Typography } from '@material-ui/core'
import { TextStyles } from 'utils/text'

import { ButtonLink } from '../buttons/ButtonLink'

const ConfigTariffItemStyled = styled.div`
  margin-top: 3px;
  margin-bottom: 24px;
  button {
    display: block;
  }
`
const TextField = styled.div`
  display: flex;
  & > div {
    margin-bottom: 8px;
    align-self: center;
    &:first-child {
      padding-right: 4px
      &:after {
        content: ':';
      }
    }
  }
`

const ConfigTariffItem = props => {
  const { label, value, discountLabel, discountValue, btnTxt, handleClick } = props
  return (
    <ConfigTariffItemStyled>
      <TextField>
        <Typography {...TextStyles.labelDark()}>{label}</Typography>
        <Typography {...TextStyles.dataDark()}>{value}</Typography>
      </TextField>
      <TextField>
        {discountLabel && <Typography {...TextStyles.labelDark()}>{discountLabel}</Typography>}
        {discountValue && <Typography {...TextStyles.dataDark()}>{discountValue}</Typography>}
      </TextField>
      <ButtonLink onClick={handleClick}>{btnTxt}</ButtonLink>
    </ConfigTariffItemStyled>
  )
}

ConfigTariffItem.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  discountLabel: PropTypes.string,
  discountValue: PropTypes.string,
  btnTxt: PropTypes.string,
  handleClick: PropTypes.func,
}

export default ConfigTariffItem
