export function getMaskedIbanNumber(iban) {
  let ibanMask = ''

  if (iban) {
    const ibanLength = iban.length
    const ibanLastFourDigits = iban.substr(ibanLength - 4, ibanLength - 1)
    ibanMask = `**** **** **** **** **** ${ibanLastFourDigits.toUpperCase()}`
  }

  return ibanMask
}
