import { put, select, take } from 'redux-saga/effects'

import { actions } from './actions'
import { selectors } from './selectors'
import { constants } from './constants'

export function* callSaga(orderId, msisdn) {
  yield put(actions.fetch(orderId, msisdn))
  yield take([constants.FETCH_SUCCESS, constants.FETCH_ERROR])
  return yield select(state => selectors.selectLogisticErrorByOrderId(state, orderId))
}
