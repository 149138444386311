import { APP_CONFIG } from 'services/app-config'
import { addQueryParamToAbsolute } from 'modules/url'

import { USER, PASS, USER_STA, PASS_STA } from './constants'

const CREATE_SALE_USER_PARAM = 'usuario'
const CREATE_SALE_PASS_PARAM = 'password'
const CREATE_SALE_SFID_PARAM = 'sfid'

export const createInsuranceSale = (sfid, newNetkiaUrl) => {
  let url = newNetkiaUrl ? `${APP_CONFIG.new_bibe_sale}` : `${APP_CONFIG.bibe_sale}`

  url = addQueryParamToAbsolute(url, CREATE_SALE_USER_PARAM, USER)
  url = addQueryParamToAbsolute(url, CREATE_SALE_PASS_PARAM, PASS)

  if (sfid) {
    url = addQueryParamToAbsolute(url, CREATE_SALE_SFID_PARAM, sfid)
  }

  return url
}

export const createInsurancePpiSale = () => {
  let url = APP_CONFIG.bibe_ppi_sale
  const isProd = APP_CONFIG.production

  url = addQueryParamToAbsolute(url, CREATE_SALE_USER_PARAM, isProd ? USER : USER_STA)
  url = addQueryParamToAbsolute(url, CREATE_SALE_PASS_PARAM, isProd ? PASS : PASS_STA)

  return url
}
