export const svasActionTypes = {
  FETCH_SVAS_LIST_PRO: 'OVID/SVAS/FETCH_SVAS_LIST_PRO',
  FETCH_SVAS_LIST_PRO_SUCCESS: 'OVID/SVAS/FETCH_SVAS_LIST_PRO_SUCCESS',
  FETCH_SVAS_LIST_PRO_ERROR: 'OVID/SVAS/FETCH_SVAS_LIST_PRO_ERROR',
  FETCH_SVAS_AGILE_TV: 'OVID/SVAS/FETCH_SVAS_AGILE_TV',
  FETCH_SVAS_AGILE_TV_SUCCESS: 'OVID/SVAS/FETCH_SVAS_AGILE_TV_SUCCESS',
  FETCH_SVAS_AGILE_TV_ERROR: 'OVID/SVAS/FETCH_SVAS_AGILE_TV_ERROR',
  SET_SAME_EMAIL_SVA: 'OVID/SVAS/SET_SAME_EMAIL_SVA',
  FETCH_RENEWAL_SVAS_AGILE_TV: 'OVID/SVAS/FETCH_RENEWAL_SVAS_AGILE_TV',
  FETCH_RENEWAL_SVAS_AGILE_TV_SUCCESS: 'OVID/SVAS/FETCH_RENEWAL_SVAS_AGILE_TV_SUCCESS',
  FETCH_RENEWAL_SVAS_AGILE_TV_ERROR: 'OVID/SVAS/FETCH_RENEWAL_SVAS_AGILE_TV_ERROR',
}

export const fetchSvaProList = payload => ({
  type: svasActionTypes.FETCH_SVAS_LIST_PRO,
  payload,
})

export const fetchSvaProListError = payload => ({
  type: svasActionTypes.FETCH_SVAS_LIST_PRO_ERROR,
  payload,
})

export const fetchSvaProListSuccess = payload => ({
  type: svasActionTypes.FETCH_SVAS_LIST_PRO_SUCCESS,
  payload,
})

export const fetchSvaAgileTv = payload => ({
  type: svasActionTypes.FETCH_SVAS_AGILE_TV,
  payload,
})

export const fetchSvaAgileTvSuccess = payload => ({
  type: svasActionTypes.FETCH_SVAS_AGILE_TV_SUCCESS,
  payload,
})

export const fetchSvaAgileTvError = payload => ({
  type: svasActionTypes.FETCH_SVAS_AGILE_TV_ERROR,
  payload,
})

export const setSameEmailSVA = payload => ({
  type: svasActionTypes.SET_SAME_EMAIL_SVA,
  payload,
})

export const fetchRenewalSvaAgileTv = payload => ({
  type: svasActionTypes.FETCH_RENEWAL_SVAS_AGILE_TV,
  payload,
})

export const fetchRenewalSvaAgileTvSuccess = payload => ({
  type: svasActionTypes.FETCH_RENEWAL_SVAS_AGILE_TV_SUCCESS,
  payload,
})

export const fetchRenewalSvaAgileTvError = payload => ({
  type: svasActionTypes.FETCH_RENEWAL_SVAS_AGILE_TV_ERROR,
  payload,
})
