import { call, put, takeEvery, select } from 'redux-saga/effects'
import { logError, formatErrorMessage } from 'services/logging'
import { axiosFactory } from 'modules/axios'

import { actions } from './actions'
import * as constants from './constants'
import { findOffers } from './api'
import { getTerritoryOwner } from './selectors'

export function* findOffersSaga({ payload }) {
  try {
    const territoryOwner = yield select(getTerritoryOwner)

    const territoryOwnerFinal = payload.territoryOwner || territoryOwner
    const offers = yield call(findOffers, { ...payload, territoryOwner: territoryOwnerFinal })
    yield put(actions.foundOffers({ data: offers }))
  } catch (e) {
    yield call(logError, { e: new Error(formatErrorMessage(e)), payload })
    const error = axiosFactory.buildError(e)
    yield put(actions.findOffersFailed(error))
  }
}

export function* watchFindOffers() {
  yield takeEvery(constants.FIND_OFFERS, findOffersSaga)
}
