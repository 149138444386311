import PropTypes from 'prop-types'
import { Modal } from '../Modal'

const OrderModal = props => {
  const { orderId, showAppointment, showDownloadResume } = props
  return (
    <Modal
      open
      title={orderId ? 'Orden generada con éxito' : 'Ha sucedido un error'}
      message={
        orderId ? `El identificador de la orden es ${orderId}` : 'Por favor, inténtelo de nuevo.'
      }
      appointment={!!orderId}
      quit={!!orderId}
      cancelBtn="Salir"
      showAppointment={showAppointment}
      showDownloadResume={showDownloadResume}
    />
  )
}

OrderModal.propTypes = {
  orderId: PropTypes.string,
  showAppointment: PropTypes.bool,
  showDownloadResume: PropTypes.bool,
}

export default OrderModal
