import { useState } from 'react'

import { fetchTerminalStock } from 'modules/Terminals/services/TerminalsStockService'

export const useDevicesStock = stockChannel => {
  const [devicesStock, setDevicesStock] = useState([])
  const [isFetchingStock, setIsFetchingStock] = useState(false)

  const buildFetchStockPromise = deviceId => {
    return new Promise((resolve, reject) => {
      fetchTerminalStock(deviceId, stockChannel)
        .then(deviceStock => {
          resolve({ deviceId, stock: deviceStock })
        })
        // eslint-disable-next-line
        .catch(() => reject({ deviceId }))
    })
  }

  const getDevicesStock = devices => {
    if (!isFetchingStock) {
      setIsFetchingStock(true)
      return Promise.all(
        devices
          .filter(
            deviceToCheckForStock =>
              !devicesStock.find(deviceStock => deviceStock.deviceId === deviceToCheckForStock.id),
          )
          .map(device =>
            buildFetchStockPromise(device.id, stockChannel).catch(
              deviceWithStockError => deviceWithStockError,
            ),
          ),
      ).then(checkStockResults => {
        setDevicesStock([...devicesStock, ...checkStockResults])
        setIsFetchingStock(false)
      })
    }
    return []
  }

  const retryFetchDeviceStock = async deviceId => {
    const stocksWithoutDevice = devicesStock.filter(
      deviceStock => deviceStock.deviceId !== deviceId,
    )
    try {
      setDevicesStock(stocksWithoutDevice)

      const deviceStock = await buildFetchStockPromise(deviceId)
      const newStocks = [...stocksWithoutDevice, ...[deviceStock]]
      setDevicesStock(newStocks)
    } catch (err) {
      setDevicesStock([...stocksWithoutDevice, ...[{ deviceId }]])
    }
  }

  return { devicesStock, getDevicesStock, retryFetchDeviceStock }
}
