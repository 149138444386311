/* eslint-disable import/no-cycle */
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { get } from 'lodash'

import { Icon } from '@material-ui/core'
import { ICONS } from 'utils/icons'

import { BadgeTypes } from './constants'

export const Badge = styled(({ className, iconRight, value, type }) => {
  return (
    <span className={`badge ${className}`} type={type}>
      {value &&
        value.map &&
        value.map(val => (
          <span key={val.label}>
            {!iconRight && val.icon && <Icon className="icon">{ICONS[val.icon]}</Icon>}
            <span className="truncated">{val.label}</span>
            {val.value ? ':' : ''} {val.value && <strong>{val.value}</strong>}
            {iconRight && val.icon && <Icon className="icon icon-right">{ICONS[val.icon]}</Icon>}
          </span>
        ))}
      {value && !value.map && (
        <span>
          {!iconRight && value.icon && <Icon className="icon">{ICONS[value.icon]}</Icon>}
          <span className="truncated">{value.label}</span>
          {value.value ? ':' : ''} {value.value && <strong>{value.value}</strong>}
          {iconRight && value.icon && <Icon className="icon icon-right">{ICONS[value.icon]}</Icon>}
        </span>
      )}
    </span>
  )
})`
  display: inline-flex;
  background-color: ${props => {
    if (props.outline) {
      return get(props, 'theme.palette.brand.light')
    }
    switch (props.type) {
      case BadgeTypes.INFO:
        return get(props, 'theme.palette.brand.action_primary')
      case BadgeTypes.ALTERNATIVE:
        return get(props, 'theme.palette.brand.action_secondary')
      case BadgeTypes.BLOCKED:
        return get(props, 'theme.palette.global.gray_dark')
      case BadgeTypes.ATTENTION:
        return get(props, 'theme.palette.brand.attention')
      case BadgeTypes.VALIDATED:
        return get(props, 'theme.palette.brand.validated')
      case BadgeTypes.ALERT:
        return get(props, 'theme.palette.brand.alert')
      default:
        return get(props, 'theme.palette.global.gray_light')
    }
  }};
  border: ${props => {
    if (props.outline) {
      switch (props.type) {
        case BadgeTypes.INFO:
          return `1px solid ${get(props, 'theme.palette.brand.action_primary')}`
        case BadgeTypes.ALTERNATIVE:
          return `1px solid ${get(props, 'theme.palette.brand.action_secondary')}`
        case BadgeTypes.BLOCKED:
          return `1px solid ${get(props, 'theme.palette.global.gray_dark')}`
        case BadgeTypes.ATTENTION:
          return `1px solid ${get(props, 'theme.palette.brand.attention')}`
        case BadgeTypes.VALIDATED:
          return `1px solid ${get(props, 'theme.palette.brand.validated')}`
        case BadgeTypes.ALERT:
          return `1px solid ${get(props, 'theme.palette.brand.alert')}`
        default:
          return `1px solid ${get(props, 'theme.palette.global.gray_light')}`
      }
    }
    return '1px solid transparent'
  }};
  border-radius: 2px;
  color: ${props => {
    if (props.outline) {
      return get(props, 'theme.palette.componentColors.BlackBodyText')
    }
    switch (props.type) {
      case BadgeTypes.ATTENTION:
      case BadgeTypes.ALTERNATIVE:
      case BadgeTypes.VALIDATED:
      case BadgeTypes.ALERT:
      case BadgeTypes.BLOCKED:
      case BadgeTypes.INFO:
        return get(props, 'theme.palette.brand.light')
      default:
        return get(props, 'theme.palette.componentColors.BlackBodyText')
    }
  }};
  font-weight: ${({ strong }) => (strong ? 'bold' : 'initial')};

  & .truncated {
    font-size: ${({ lg, keyValContrast }) => {
      if (keyValContrast) {
        return lg ? '14px' : '12px'
      }
      return lg ? '12px' : '10px'
    }};
    + strong {
      padding-left: 0.3em;
    }
  }

  .truncated + * {
    font-size: ${({ lg, keyValContrast }) => {
      if (keyValContrast) {
        return lg ? '16px' : '14px'
      }
      return lg ? '12px' : '10px'
    }};
  }

  justify-content: center;
  letter-spacing: 0.6px;
  margin-right: 1em;
  min-width: ${({ minWidth, chip }) => (!chip && minWidth) || 'auto'};
  max-width: ${({ maxWidth, chip }) => (!chip && maxWidth) || 'none'};
  padding: ${({ lg, chip, keyValContrast }) => {
    if (keyValContrast) {
      return lg ? '10px 16px;' : '5px 8px;'
    }
    return lg ? `5px ${chip ? 5 : 8}px` : `3px ${chip ? 5 : 8}px`
  }};

  text-align: center;

  strong {
    font-weight: bold;
  }

  & > span {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & > span:not(:last-child) {
    margin-right: 1em;
  }

  & > span,
  .truncated {
    min-width: ${({ value }) => (value && value.icon ? '2px' : '8px')};
    overflow: ${({ chip }) => (chip ? 'visible' : 'hidden')};
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .icon {
    font-size: ${({ chip }) => (chip ? '15px' : '1.1em')};
    line-height: ${({ chip }) => (chip ? '10px' : 'initial')};
    margin-right: ${({ chip }) => (chip ? '0' : '0.5em')};
    overflow: visible;
    position: ${({ chip }) => (chip ? 'relative' : 'initial')};
    max-width: ${({ chip }) => (chip ? '6px' : 'none')};
    max-height: ${({ chip }) => (chip ? '10px' : 'none')};
    left: -3.5px;
    top: 1px;
  }

  .icon-right {
    font-size: 0.6em;
    margin-left: 0.5em;
    margin-right: 0;
  }
`

Badge.defaultProps = {
  type: Badge.DEFAULT,
  keyValContrast: false,
}

Badge.propTypes = {
  className: PropTypes.string,
  chip: PropTypes.bool,
  strong: PropTypes.bool,
  minWidth: PropTypes.string,
  maxWidth: PropTypes.string,
  lg: PropTypes.bool,
  outline: PropTypes.bool,
  keyValContrast: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string,
      icon: PropTypes.string,
    }),

    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string,
        icon: PropTypes.string,
      }),
    ),
  ]),
  type: PropTypes.oneOf([
    BadgeTypes.DEFAULT,
    BadgeTypes.INFO,
    BadgeTypes.ATTENTION,
    BadgeTypes.VALIDATED,
    BadgeTypes.ALERT,
    BadgeTypes.ALTERNATIVE,
    BadgeTypes.BLOCKED,
  ]),
}
