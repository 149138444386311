import { installationsActionTypes } from '../actions'

const initialState = {
  loading: false,
  error: false,
  data: {
    installer: '',
    connectionType: '',
  },
}

export const installationsReducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case installationsActionTypes.FETCH_INSTALLATION: {
      return { loading: true, error: false, data: { ...initialState.data } }
    }

    case installationsActionTypes.FETCH_INSTALLATION_SUCCESS: {
      return { loading: false, error: false, data: payload }
    }

    case installationsActionTypes.FETCH_INSTALLATION_ERROR: {
      return {
        loading: false,
        error: true,
        data: {
          installer: 'MM',
          connectionType: '',
        },
      }
    }

    case installationsActionTypes.RESET_INSTALLATION: {
      return {
        ...initialState,
      }
    }

    default:
      return state
  }
}
