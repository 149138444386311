export const STEP_STATUS = {
  ACTIVE: 'active',
  CANCELLED: 'cancelled',
  ALERT: 'alert',
}

export const LOGISTIC_STEPS_ICONS = {
  PROCESSING: 'notes',
  PREPARING: 'box',
  IN_DELIVERY: 'delivery',
  ARRIVED: 'home',
}

export const MAP_STATUS_CONFIG = {
  1: {
    description: 'Pedido recibido por el operador logístico',
    steps: {
      [LOGISTIC_STEPS_ICONS.PROCESSING]: STEP_STATUS.ACTIVE,
    },
  },
  2: {
    description: 'Pedido recibido por el operador logístico',
    steps: {
      [LOGISTIC_STEPS_ICONS.PROCESSING]: STEP_STATUS.ACTIVE,
    },
  },
  3: {
    description: 'Cancelado',
    steps: {
      [LOGISTIC_STEPS_ICONS.PROCESSING]: STEP_STATUS.CANCELLED,
    },
  },
  4: {
    description: 'En preparación',
    steps: {
      [LOGISTIC_STEPS_ICONS.PREPARING]: STEP_STATUS.ACTIVE,
    },
  },
  6: {
    description: 'Preparado para enviar al mensajero',
    steps: {
      [LOGISTIC_STEPS_ICONS.PREPARING]: STEP_STATUS.ACTIVE,
    },
  },
  7: {
    description: 'En proceso de envío',
    steps: {
      [LOGISTIC_STEPS_ICONS.IN_DELIVERY]: STEP_STATUS.ACTIVE,
    },
  },
  8: {
    description: 'Entregado',
    steps: {
      [LOGISTIC_STEPS_ICONS.ARRIVED]: STEP_STATUS.ACTIVE,
    },
  },
  9: {
    description: 'Datos de entrega erróneos',
    steps: {
      [LOGISTIC_STEPS_ICONS.IN_DELIVERY]: STEP_STATUS.CANCELLED,
    },
  },
  10: {
    description: 'Imposible entregar al cliente tras 3 intentos',
    steps: {
      [LOGISTIC_STEPS_ICONS.IN_DELIVERY]: STEP_STATUS.CANCELLED,
    },
  },
  11: {
    description: 'En proceso de devolución a almacén',
    steps: {
      [LOGISTIC_STEPS_ICONS.IN_DELIVERY]: STEP_STATUS.CANCELLED,
    },
  },
  12: {
    description: 'Devuelto a almacén',
    steps: {
      [LOGISTIC_STEPS_ICONS.PREPARING]: STEP_STATUS.CANCELLED,
    },
  },
  13: {
    description: 'Cliente no contactado',
    steps: {
      [LOGISTIC_STEPS_ICONS.IN_DELIVERY]: STEP_STATUS.CANCELLED,
    },
  },
  14: {
    description: 'Pedido perdido/robado al transportista',
    steps: {
      [LOGISTIC_STEPS_ICONS.IN_DELIVERY]: STEP_STATUS.CANCELLED,
    },
  },
  15: {
    description: 'Incidencia Transporte',
    steps: {
      [LOGISTIC_STEPS_ICONS.IN_DELIVERY]: STEP_STATUS.CANCELLED,
    },
  },
  17: {
    description: 'En revisión por parte de almacén',
    steps: {
      [LOGISTIC_STEPS_ICONS.PREPARING]: STEP_STATUS.CANCELLED,
    },
  },
  18: {
    description: 'Pedido en tienda a la espera de ser recogido por el cliente',
    steps: {
      [LOGISTIC_STEPS_ICONS.ARRIVED]: STEP_STATUS.ACTIVE,
    },
  },
  19: {
    description: 'Cancelado por no ser recogido en tienda',
    steps: {
      [LOGISTIC_STEPS_ICONS.ARRIVED]: STEP_STATUS.CANCELLED,
    },
  },
  22: {
    description: 'Cancelado por procesos internos',
    steps: {
      [LOGISTIC_STEPS_ICONS.PREPARING]: STEP_STATUS.CANCELLED,
    },
  },
  33: {
    description: 'Cancelación solicitada',
    steps: {
      [LOGISTIC_STEPS_ICONS.PREPARING]: STEP_STATUS.CANCELLED,
    },
  },
  35: {
    description: 'Cancelado con el pedido en tienda',
    steps: {
      [LOGISTIC_STEPS_ICONS.ARRIVED]: STEP_STATUS.CANCELLED,
    },
  },
  44: {
    description: 'Rotura de ICC',
    steps: {
      [LOGISTIC_STEPS_ICONS.PREPARING]: STEP_STATUS.CANCELLED,
    },
  },
  66: {
    description: 'Cancelado con el pedido en almacén',
    steps: {
      [LOGISTIC_STEPS_ICONS.PREPARING]: STEP_STATUS.CANCELLED,
    },
  },
  67: {
    description: 'Cancelado durante el reparto',
    steps: {
      [LOGISTIC_STEPS_ICONS.IN_DELIVERY]: STEP_STATUS.CANCELLED,
    },
  },
  75: {
    description: 'Verificando cancelación solicitada',
    steps: {
      [LOGISTIC_STEPS_ICONS.PROCESSING]: STEP_STATUS.CANCELLED,
    },
  },
  79: {
    description: 'Pendiente de cancelación - predelivery',
    steps: {
      [LOGISTIC_STEPS_ICONS.PROCESSING]: STEP_STATUS.CANCELLED,
    },
  },
  80: {
    description: 'Pendiente de cancelación - pos-envío',
    steps: {
      [LOGISTIC_STEPS_ICONS.IN_DELIVERY]: STEP_STATUS.CANCELLED,
    },
  },
  81: {
    description: 'En proceso de devolución a almacén',
    steps: {
      [LOGISTIC_STEPS_ICONS.IN_DELIVERY]: STEP_STATUS.CANCELLED,
    },
  },
  100: {
    description: 'Desistimiento solicitado',
    steps: {
      [LOGISTIC_STEPS_ICONS.ARRIVED]: STEP_STATUS.CANCELLED,
    },
  },
  875: {
    description: 'Tarjeta SIM destruida',
    steps: {
      [LOGISTIC_STEPS_ICONS.IN_DELIVERY]: STEP_STATUS.CANCELLED,
    },
  },
  4000: {
    description: 'Documentación entregada en almacén',
    steps: {
      [LOGISTIC_STEPS_ICONS.PREPARING]: STEP_STATUS.CANCELLED,
    },
  },
  4030: {
    description: 'Documentación perdida',
    steps: {
      [LOGISTIC_STEPS_ICONS.PROCESSING]: STEP_STATUS.CANCELLED,
    },
  },
  4050: {
    description: 'Documentación entregada al custodiador',
    steps: {
      [LOGISTIC_STEPS_ICONS.ARRIVED]: STEP_STATUS.ACTIVE,
    },
  },
  420: {
    description: 'Rotura de stock',
    steps: {
      [LOGISTIC_STEPS_ICONS.PREPARING]: STEP_STATUS.CANCELLED,
    },
  },
}
