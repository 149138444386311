import { useState } from 'react'
import { useDispatch } from 'react-redux'

import { iccNumberRegex } from 'utils'
import {
  REQUIRED_FIELD_TEXT,
  NOT_AVAILABLE_ICC,
  NOT_EXIST_ICC,
  NOT_VALID_ICC,
} from 'modules/SharedSales/constants/validations'

import { setRequestLoading } from 'modules/NewClientSales/store/actions/basket.actions'
import { fetchICCValidation } from '../../services'

export function useICCValidation(checkWithCatalog) {
  const dispatch = useDispatch()

  const [prevValue, setPrevValue] = useState('')
  const [prevState, setPrevState] = useState()
  const [hasMsError, setMsError] = useState()

  function setAndReturnState(state, isMsError) {
    setMsError(isMsError)
    setPrevState(state)
    return state
  }

  async function validate(value) {
    const trimmedValue = value?.trim()
    setPrevValue(trimmedValue)

    if (!trimmedValue) {
      return setAndReturnState(REQUIRED_FIELD_TEXT)
    }

    if (trimmedValue === prevValue) {
      return prevState
    }

    if (iccNumberRegex.test(trimmedValue)) {
      dispatch(setRequestLoading({ loading: true }))

      try {
        const { simStatus } = await fetchICCValidation(trimmedValue, checkWithCatalog)

        dispatch(setRequestLoading({ loading: false }))

        if (!checkWithCatalog && !simStatus) {
          return setAndReturnState(undefined)
        }

        if (simStatus !== 'available') {
          return setAndReturnState(NOT_AVAILABLE_ICC, true)
        }

        return setAndReturnState(undefined)
      } catch (e) {
        dispatch(setRequestLoading({ loading: false }))
        return setAndReturnState(NOT_EXIST_ICC, true)
      }
    } else {
      return setAndReturnState(NOT_VALID_ICC)
    }
  }

  return { validate, error: prevState, hasMsError }
}
