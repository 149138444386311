import { isEmpty } from 'lodash'

export const checkForMsisdn = (orders, msisdn) => {
  if (isEmpty(orders) === false && msisdn !== '') {
    return Array.isArray(orders)
      ? orders.some(order => (order.msisdn === msisdn ? order['acc-inprogress'] : false))
      : false
  }
  return null
}
