import { customerInfoActionTypes } from './customerInfo.actions'

const initialState = {
  ibanUpdate: {
    loading: null,
    error: null,
  },
  postalAddressUpdate: {
    loading: null,
    error: null,
  },
  contactMediaModification: {
    loading: null,
    error: null,
  },
  isCustomer: {
    data: {},
    loading: null,
    error: null,
  },
  customerIds: {
    data: {
      docNumber: null,
    },
    loading: null,
    error: null,
  },
  isMasStackCustomer: {
    data: {},
    loading: null,
    error: null,
  },
}

export const customerInfoReducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case customerInfoActionTypes.SET_NEW_IBAN_VALUE_CARTERA: {
      return {
        ...state,
        ibanUpdate: {
          loading: true,
          error: null,
        },
      }
    }
    case customerInfoActionTypes.SET_NEW_IBAN_VALUE_CARTERA_SUCCESS: {
      return {
        ...state,
        ibanUpdate: {
          loading: false,
          error: null,
        },
      }
    }
    case customerInfoActionTypes.SET_NEW_IBAN_VALUE_CARTERA_ERROR: {
      return {
        ...state,
        ibanUpdate: {
          loading: false,
          error: payload,
        },
      }
    }

    case customerInfoActionTypes.MODIFY_CUSTOMER_CONTACT_MEDIA: {
      return {
        ...state,
        contactMediaModification: {
          loading: true,
          error: null,
        },
      }
    }
    case customerInfoActionTypes.MODIFY_CUSTOMER_CONTACT_MEDIA_SUCCESS: {
      return {
        ...state,
        contactMediaModification: {
          loading: false,
          error: null,
        },
      }
    }
    case customerInfoActionTypes.MODIFY_CUSTOMER_CONTACT_MEDIA_ERROR: {
      return {
        ...state,
        contactMediaModification: {
          loading: false,
          error: payload,
        },
      }
    }

    case customerInfoActionTypes.SET_NEW_POSTAL_ADDRESS_CARTERA: {
      return {
        ...state,
        postalAddressUpdate: {
          loading: true,
          error: null,
        },
      }
    }
    case customerInfoActionTypes.SET_NEW_POSTAL_ADDRESS_CARTERA_SUCCESS: {
      return {
        ...state,
        postalAddressUpdate: {
          loading: false,
          error: null,
        },
      }
    }
    case customerInfoActionTypes.SET_NEW_POSTAL_ADDRESS_CARTERA_ERROR: {
      return {
        ...state,
        postalAddressUpdate: {
          loading: false,
          error: payload,
        },
      }
    }

    case customerInfoActionTypes.RESET_IS_CUSTOMER: {
      return {
        ...state,
        isCustomer: {
          loading: false,
          data: {},
          error: null,
        },
      }
    }

    case customerInfoActionTypes.FETCH_IS_CUSTOMER: {
      return {
        ...state,
        isCustomer: {
          loading: true,
          error: null,
        },
      }
    }

    case customerInfoActionTypes.FETCH_IS_CUSTOMER_SUCCESS: {
      return {
        ...state,
        isCustomer: {
          data: payload,
          loading: false,
          error: null,
        },
      }
    }

    case customerInfoActionTypes.FETCH_IS_CUSTOMER_ERROR: {
      return {
        ...state,
        isCustomer: {
          data: {},
          loading: false,
          error: payload,
        },
      }
    }

    case customerInfoActionTypes.FETCH_CUSTOMER_INFORMATION: {
      return {
        ...state,
        customerIds: { data: { docNumber: payload.docNumber }, loading: true, error: null },
      }
    }

    case customerInfoActionTypes.SET_CUSTOMER_INFORMATION_SUCCESS: {
      return {
        ...state,
        customerIds: {
          ...state.customerIds,
          data: { ...state.customerIds.data, ...payload },
          loading: false,
        },
      }
    }

    case customerInfoActionTypes.SET_CUSTOMER_INFORMATION_ERROR: {
      return {
        ...state,
        customerIds: { ...state.customerIds, error: payload, loading: false },
      }
    }

    case customerInfoActionTypes.RESET_CARTERA_VALUES_STORE: {
      return initialState
    }

    case customerInfoActionTypes.FETCH_CUSTOMER_IS_ALLOWED: {
      return {
        ...state,
        isMasStackCustomer: {
          loading: true,
          error: null,
        },
      }
    }

    case customerInfoActionTypes.FETCH_CUSTOMER_IS_ALLOWED_SUCCESS: {
      return {
        ...state,
        isMasStackCustomer: {
          data: payload,
          loading: false,
          error: null,
        },
      }
    }

    case customerInfoActionTypes.FETCH_CUSTOMER_IS_ALLOWED_ERROR: {
      return {
        ...state,
        isMasStackCustomer: {
          data: {},
          loading: false,
          error: payload,
        },
      }
    }

    default:
      return state
  }
}
