import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'
import { Advice } from 'components/ui'
import { SINGLE } from 'services/global-constants'
import { TerminalItemComponent } from '../TerminalItemComponent'
import { TerminalFinancialData } from '../TerminalFinancialDataComponent'
import { TerminalSelector } from '../TerminalSelectorComponent'
import { InputIMEI } from '../InputIMEI'

export function TerminalsFilter({
  fieldNames,
  paymentType,
  onModelChange,
  onCategoryChange,
  onBrandChange,
  onValidIMEI,
  reservationError,
  isListLoading,
  terminalModels,
  onPaymentTypeChange,
  terminalDetails,
  listError,
  isCompany,
  shouldShowCompany,
  professionOptions,
  onSelectProfession,
  isFinancialsLoading,
  isPosSale,
  disableOfferEdit,
  isMobileOnly,
  isLeadFilled,
  fetchTerminals,
  isAppleTv,
  signUpType,
}) {
  const showMainStockError =
    terminalDetails.id && terminalDetails.stock <= 0 && !terminalDetails.isLoading

  return (
    <>
      <TerminalSelector
        terminalModels={terminalModels}
        onModelChange={onModelChange}
        onCategoryChange={onCategoryChange}
        onBrandChange={onBrandChange}
        fieldNames={fieldNames}
        terminalDetails={terminalDetails}
        listError={listError}
        isListLoading={isListLoading}
        disableOfferEdit={disableOfferEdit}
        isLeadFilled={isLeadFilled}
        fetchTerminals={fetchTerminals}
        isAppleTv={isAppleTv}
      />
      {!listError && !isListLoading && !disableOfferEdit && (
        <>
          <TerminalItemComponent
            isFinancialsLoading={isFinancialsLoading}
            terminalDetails={terminalDetails}
            onPaymentTypeChange={onPaymentTypeChange}
            fieldNames={fieldNames}
            reservationError={reservationError}
            paymentType={paymentType}
            disableOfferEdit={disableOfferEdit}
            isPosSale={isPosSale}
            signUpType={signUpType}
          />

          {showMainStockError && (
            <Advice type="error" block>
              <Box pb="12px">
                No hay stock disponible. Marque un NO si el cliente no quiere dispositivo para poder
                continuar la venta.
              </Box>
            </Advice>
          )}
          {isPosSale && <InputIMEI fieldNames={fieldNames} onValid={onValidIMEI} />}
        </>
      )}
      {paymentType &&
        paymentType !== SINGLE &&
        !isCompany &&
        (!isMobileOnly || disableOfferEdit) && (
          <TerminalFinancialData
            fieldNames={fieldNames}
            shouldShowCompany={shouldShowCompany}
            professionOptions={professionOptions}
            onSelectProfession={onSelectProfession}
            isLeadFilled={isLeadFilled}
            isMobileOnly={isMobileOnly}
          />
        )}
    </>
  )
}

TerminalsFilter.propTypes = {
  isFinancialsLoading: PropTypes.bool,
  onCategoryChange: PropTypes.func.isRequired,
  onBrandChange: PropTypes.func.isRequired,
  onModelChange: PropTypes.func.isRequired,
  onPaymentTypeChange: PropTypes.func.isRequired,
  onValidIMEI: PropTypes.func.isRequired,
  reservationError: PropTypes.bool,
  paymentType: PropTypes.string,
  professionOptions: PropTypes.array,
  onSelectProfession: PropTypes.func.isRequired,
  fieldNames: PropTypes.object,
  terminalModels: PropTypes.array,
  terminalDetails: PropTypes.object,
  isListLoading: PropTypes.bool,
  listError: PropTypes.bool,
  isCompany: PropTypes.bool,
  shouldShowCompany: PropTypes.bool,
  isPosSale: PropTypes.bool,
  disableOfferEdit: PropTypes.bool,
  isMobileOnly: PropTypes.bool,
  isLeadFilled: PropTypes.bool,
  fetchTerminals: PropTypes.func,
  isAppleTv: PropTypes.bool,
  signUpType: PropTypes.string,
}
