import { SIGNUP_TYPE_PORTABILITY, SIGNUP_TYPE_NEWLINE } from 'services/global-constants'
import { SignUpLine, Portability } from './components'
import { LINE_TYPE } from './constants'

export const fieldNames = (lineType, isMobileOnly) => {
  const terminalLineType = lineType.startsWith('land') ? LINE_TYPE.MAIN_MOBILE : lineType
  const tariffLineType =
    !isMobileOnly && lineType.startsWith('main') ? LINE_TYPE.LANDLINE : lineType
  return {
    SEGMENT: 'account.segment',
    MAIN_COMPANY_NAME: 'account.companyName',
    NAME: 'account.name',
    SURNAME_1: 'account.surname1',
    SURNAME_2: 'account.surname2',

    LINE_TYPE: lineType,
    TERMINAL_LINE_TYPE: terminalLineType,
    IS_PORTABILITY: `${lineType}.isPortability`,
    SELECTED_TECHNOLOGY: `${lineType}.selectedTechnology`,
    MOBILE_LINE_TYPE: `${lineType}.signUpType`,
    PORTABILITY_PERSON: `${lineType}.isSameUser`,
    PORTABILITY_NUMBER: `${lineType}.lineNumber`,
    ROUTER_NUMBER: `${lineType}.routerNumber`,
    ROUTER_VALIDATION_MESSAGE: `${lineType}.routerValidationMessage`,
    PORTABILITY_PAYMENT_TYPE: `${lineType}.portabilityType`,
    PORTABILITY_ICC_NUMBER: `${lineType}.portabilityIccNumber`,
    ICC_NUMBER: `${lineType}.iccNumber`,
    IUA: `${lineType}.IUA`,
    LINE_NUMBER: `${lineType}.lineNumber`,
    NEW_LINE_SEARCH: `${lineType}.enabledSearchNewNumber`,
    NEW_LINE_NUMBER: `${lineType}.selectedNewNumber`,
    NEW_LINE_SEARCH_DIGITS: `${lineType}.desiredNumber`,
    DOCUMENT_TYPE: `${lineType}.otherUser.documentType`,
    DOCUMENT_VALUE: `${lineType}.otherUser.documentId`,
    FIRST_NAME: `${lineType}.otherUser.name`,
    LAST_NAME_1: `${lineType}.otherUser.surname1`,
    LAST_NAME_2: `${lineType}.otherUser.surname2`,
    COMPANY_NAME: `${lineType}.otherUser.companyName`,
    POSTPONE_PORTABILITY: `${lineType}.isPortabilityDate`,
    POSTPONE_PORTABILITY_NEW_DATE: `${lineType}.portabilityDate`,

    MOBILE_SIGN_UP_TYPE: `${terminalLineType}.signUpType`, // used for campaigns
    MOBILE_PORTABILITY_PAYMENT_TYPE: `${terminalLineType}.portabilityType`, // used for campaigns
    HAS_TERMINAL: `${terminalLineType}.isNewTerminal`,
    TERMINAL_CATEGORY: `${terminalLineType}.terminal.category`,
    TERMINAL_MANUFACTURER: `${terminalLineType}.terminal.manufacturer`,
    TERMINAL_RESERVATION_ID: `${terminalLineType}.terminal.reservationId`,
    TERMINAL_PAYMENT: `${terminalLineType}.terminal.paymentType`,
    TERMINAL_DETAILS: `${terminalLineType}.terminal.details`,
    TERMINAL_ID: `${terminalLineType}.terminal.id`,
    TERMINAL: `${terminalLineType}.terminal`,
    SALARY: `${terminalLineType}.terminal.salary`,
    ID_DUE_DATE: `${terminalLineType}.terminal.idDueDate`,
    JOB: `${terminalLineType}.terminal.job`,
    COMPANY: `${terminalLineType}.terminal.company`,
    RESERVATION_ID: `${terminalLineType}.terminal.reservationId`,
    IMEI: `${terminalLineType}.terminal.imei`,
    IMEI_VALIDATION_MESSAGE: `${lineType}.terminal.imeiValidationMessage`,

    NEW_NUMBER_VALUE: `${lineType}.newNumber.value`,
    NEW_NUMBER_OPTIONS: `${lineType}.newNumber.options`,
    NEW_FIXED_NUMBER_VALUE: `${lineType}.newFixedNumber`,
    OPERATOR: `${lineType}.operator`,
    CLIENT_EXISTS_LAND: `${lineType}.clientExistsError`,
    IS_PORTA_FTTH: `${lineType}.isPortabilityFromFtth`,
    SELECTED_PACKAGES: `${terminalLineType}.selectedPackages`,
    TARIFF: `${tariffLineType}.tariff`,
    TARIFF_ID: `${tariffLineType}.tariff.id`,
    TARIFF_TYPE: `${tariffLineType}.tariff.tariffType`,
    SVA: `${lineType}.SVA`,
    SVA_EMAIL: `${lineType}.SVA.email`,
    PRO_SVAS: `${lineType}.proSVAs`,
    CAMPAIGN: `${lineType}.campaign`,
    CAMPAIGN_ID: `${lineType}.campaign.id`,
    MEMBER_GET_MEMBER_PO_ID: `${lineType}.memberGetMemberPromo.poId`,
    MEMBER_GET_MEMBER_REFEREE_MSISDN: `${lineType}.memberGetMemberPromo.msisdn`,
    CROSS_SELL_TARIFF_PROMO: `${lineType}.crossSellTariffPromo`,
    HAS_D2R_DISCOUNT: `${lineType}.hasSecondResidenceDiscount`,
  }
}

export const lineOptions = [
  { value: SIGNUP_TYPE_PORTABILITY, component: Portability, label: 'Portabilidad' },
  { value: SIGNUP_TYPE_NEWLINE, component: SignUpLine, label: 'Alta nueva' },
]
