import { get } from 'lodash'
import { createSelector } from 'reselect'

import { customerStatuses } from '../CustomerInfo.constants'

const getCustomerInfo = state => state.customerInfo

export const selectModifyContactMediaError = createSelector(
  getCustomerInfo,
  customerInfo => !!customerInfo.contactMediaModification.error,
)

export const selectModifyContactMediaIsLoading = createSelector(
  getCustomerInfo,
  customerInfo => !!customerInfo.contactMediaModification.loading,
)

export const selectPostalAddressValuesError = createSelector(
  getCustomerInfo,
  customerInfo => !!customerInfo.postalAddressUpdate.error,
)

export const selectEmailValueError = createSelector(
  getCustomerInfo,
  customerInfo => !!customerInfo.ibanUpdate.error,
)

export const selectIsCustomerLoading = createSelector(
  getCustomerInfo,
  customerInfo => customerInfo.isCustomer.loading,
)

export const selectIsCustomerActive = createSelector(
  getCustomerInfo,
  customerInfo => customerInfo.isCustomer.data?.status === customerStatuses.active,
)

export const selectIsCustomerCanceled = createSelector(
  getCustomerInfo,
  customerInfo => customerInfo.isCustomer.data?.status === customerStatuses.canceled,
)

export const selectCustomerInfoIsLoading = createSelector(
  getCustomerInfo,
  customerInfo => customerInfo?.customerIds?.loading,
)

export const selectCustomerInfoError = createSelector(
  getCustomerInfo,
  customerInfo => customerInfo?.customerIds?.error,
)

export const selectCustomerInfoDocNumber = createSelector(
  getCustomerInfo,
  customerInfo => customerInfo?.customerIds?.data.docNumber,
)

export const selectAccountId = createSelector(
  getCustomerInfo,
  customerInfo => customerInfo?.customerIds?.data.accountId,
)

export const selectBillingId = createSelector(
  getCustomerInfo,
  customerInfo => customerInfo?.customerIds?.data.billingId,
)

export const selectAccountType = createSelector(
  getCustomerInfo,
  customerInfo => customerInfo?.customerIds?.data.accountType,
)

export const selectAccountSelfEmployed = createSelector(
  getCustomerInfo,
  customerInfo => customerInfo?.customerIds?.data.selfEmployed,
)

export const selectMasStackData = createSelector(getCustomerInfo, customerInfo =>
  get(customerInfo, 'isMasStackCustomer.data'),
)

export const selectMasStackLoading = createSelector(getCustomerInfo, customerInfo =>
  get(customerInfo, 'isMasStackCustomer.loading'),
)

export const selectCustomerAllowedError = createSelector(
  getCustomerInfo,
  customerInfo => !!get(customerInfo, 'isMasStackCustomer.error'),
)

export const selectCustomerAllowed = createSelector(
  getCustomerInfo,
  selectCustomerAllowedError,
  (customerInfo, isCustomerInfoError) => {
    if (isCustomerInfoError) {
      return true
    }

    return get(customerInfo, 'isMasStackCustomer.data.allowed')
  },
)
