import constants from './constants'

const findTax = zipCode => ({
  type: constants.FIND_TAX,
  payload: { zipCode },
})

const findTaxSuccess = (zipCode, data) => ({
  type: constants.FIND_TAX_SUCCESS,
  payload: { data, zipCode },
})

const findTaxError = (zipCode, error) => ({
  type: constants.FIND_TAX_ERROR,
  payload: { zipCode, ...error },
})

export default {
  findTax,
  findTaxSuccess,
  findTaxError,
}
