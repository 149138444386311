import * as constants from './constants'

// GET Product Orders Data
const findProductOrders = (productId, { filterBy = 'fixednumber', customerId = null } = {}) => ({
  type: constants.FIND_PRODUCT_ORDERS,
  payload: {
    productId,
    filterBy,
    customerId,
  },
})

const findProductOrdersSuccess = (data, productId) => ({
  type: constants.FIND_PRODUCT_ORDERS_SUCCESS,
  payload: {
    data,
    productId,
  },
})

const findProductOrdersFailed = (error, productId) => ({
  type: constants.FIND_PRODUCT_ORDERS_FAILED,
  payload: {
    error,
    productId,
  },
})

export const actions = {
  findProductOrders,
  findProductOrdersSuccess,
  findProductOrdersFailed,
}
