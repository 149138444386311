import { get, chain, isEmpty } from 'lodash'
import { formatNumberWithComma } from 'utils'
import { applyTax } from 'services/taxes'

const byAscendingDuration = (a, b) => get(a, 'valid_periods') - get(b, 'valid_periods')

const routerPenalty = subscription =>
  Number(
    chain(subscription)
      .head('router')
      .head('permanency_contracts)')
      .get('penalty_fee')
      .value() || 0,
  )

const simPenalty = subscription =>
  Number(
    chain(subscription)
      .head('sim')
      .head('permanency_contracts)')
      .get('penalty_fee')
      .value() || 0,
  )

const getTotalPenaltyFee = subscription => {
  return formatNumberWithComma(routerPenalty(subscription) + simPenalty(subscription))
}

const getDiscountAmount = (discount, tariffFee) => (tariffFee * Number(discount.amount)) / 100

const calculateDiscountPeriods = subscription => {
  const { discount_plans, tariff } = subscription
  const subscriptionFee = tariff.monthly_fee

  const response = []

  if (isEmpty(discount_plans)) {
    return false
  }

  discount_plans.sort(byAscendingDuration).forEach((discount, i) => {
    if (isEmpty(response)) {
      response.push({
        from: 1,
        to: discount.valid_periods,
        amount:
          discount.unit === 'Percentage'
            ? formatNumberWithComma(getDiscountAmount(discount, subscriptionFee))
            : formatNumberWithComma(subscriptionFee - discount.amount),
      })
    } else {
      response.push({
        from: response[i - 1].to + 1,
        to: discount.valid_periods,
        amount:
          discount.unit === 'Percentage'
            ? formatNumberWithComma(getDiscountAmount(discount, subscriptionFee))
            : formatNumberWithComma(subscriptionFee - discount.amount),
      })
    }
  })

  return response
}

const generateFeeSummary = (subscription, taxRate) => {
  const { tariff } = subscription

  return {
    periods: calculateDiscountPeriods(subscription),
    final: formatNumberWithComma(tariff.monthly_fee),
    finalWithTax: formatNumberWithComma(
      taxRate
        ? applyTax(taxRate, tariff.monthly_fee_with_discounts_without_tax)
        : tariff.monthly_fee_with_discounts,
    ),
    finalWithoutTax: formatNumberWithComma(tariff.monthly_fee_with_discounts_without_tax),
    penalty: getTotalPenaltyFee(subscription),
  }
}

export const helpers = {
  generateFeeSummary,
  routerPenalty,
  simPenalty,
}
