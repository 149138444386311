import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { Typography } from '@material-ui/core'
import { TextStyles } from 'utils/text'

import { Card } from 'modules/ui'
import { isEmpty, map, get } from 'lodash'
import { helpers } from 'services/offers-detail'
import { getCommercialName, selectTariffsApigeeById } from 'modules/tariffs/store-apigee'
import { selectors as customerSelectors } from 'services/customer-360/selectors'
import { getTax, actions as taxes } from 'services/taxes'
import { isProCustomer } from 'services/customer-360/helpers'
import { APP_CONFIG } from 'services/app-config'
import { selectIsPosOrMasProximo } from 'modules/Permissions'
import { MainFeesItem } from '../MainFeesItem/MainFeesItem'
import { RouterDetail } from '../RouterDetail/RouterDetail'
import { HandsetDetail } from '../HandsetDetail/HandsetDetail'
import { InternetDetail } from '../InternetDetail/InternetDetail'
import { FixedDetail } from '../FixedDetail/FixedDetail'
import { SimDetail } from '../SimDetail/SimDetail'
import { EmptyOrderProducts } from './EmptyOrderProducts'
import { ErrorOrderProducts } from './ErrorOrderProducts'
import { ProductDivider } from './ProductDivider'
import { LoadingProducts } from './LoadingProducts'

export const OrderProducts = styled(({ offers, loading, errors, className }) => {
  const dispatch = useDispatch()
  const actions = [{ label: 'MNP/Relanzar Porta', to: APP_CONFIG.mnp, target: '_blank' }]
  const isPosOrMasProximo = useSelector(selectIsPosOrMasProximo)

  const tariffs = useSelector(state =>
    map(offers, offer => selectTariffsApigeeById(get(offer, 'tariff.id'))(state)),
  )

  const billingAddress = useSelector(customerSelectors.getBillingAddress)
  const postalCode = get(billingAddress, 'postCode')
  const taxRate = useSelector(state => getTax(state, postalCode))

  const customerSegment = useSelector(customerSelectors.getSegment)
  const taxNeeded = !isProCustomer(customerSegment)

  useEffect(() => {
    if (postalCode && !taxRate) {
      dispatch(taxes.findTax(postalCode))
    }
  }, [postalCode, taxRate])

  return (
    <div className={className}>
      {loading && <LoadingProducts />}
      {!loading && errors && <ErrorOrderProducts />}
      {!loading && !errors && isEmpty(offers) && <EmptyOrderProducts />}
      {!errors &&
        !loading &&
        !isEmpty(offers) &&
        offers.map((offer, idx) => (
          <Card
            key={offer.id}
            title={getCommercialName(tariffs[idx])}
            actions={!isPosOrMasProximo && actions}
            id={`Product-${get(offer, 'id')}`}>
            <MainFeesItem
              feesData={helpers.generateFeeSummary(offer, taxRate)}
              offer={offer}
              taxNeeded={taxNeeded}
            />
            <Typography
              {...TextStyles.subtitle2Link({
                className: 'header-lines',
              })}>
              <span>{getCommercialName(tariffs[idx])} - LINEAS DE CABECERA</span>
            </Typography>
            {!isEmpty(offer.fixed) && (
              <>
                <FixedDetail {...offer} />
                {!isEmpty(offer.internet) ||
                  !isEmpty(offer.router) ||
                  !isEmpty(offer.sim) ||
                  (!isEmpty(offer.handset) && <ProductDivider />)}
              </>
            )}
            {!isEmpty(offer.internet) && (
              <>
                <InternetDetail {...offer} />
                {!isEmpty(offer.router) ||
                  !isEmpty(offer.sim) ||
                  (!isEmpty(offer.handset) && <ProductDivider />)}
              </>
            )}
            {!isEmpty(offer.router) && (
              <>
                <RouterDetail {...offer} />
                {!isEmpty(offer.sim) || (!isEmpty(offer.handset) && <ProductDivider />)}
              </>
            )}
            {!isEmpty(offer.sim) && (
              <>
                <SimDetail {...offer} />
                {!isEmpty(offer.handset) && <ProductDivider />}
              </>
            )}
            {!isEmpty(offer.handset) && <HandsetDetail {...offer} />}
          </Card>
        ))}
    </div>
  )
})`
  > .card {
    & .header-lines {
      padding: 9px 24px;
    }
  }
  > .circular-progress-center {
    padding-top: 150px;
    z-index: 1000;
  }
`

OrderProducts.propTypes = {
  className: PropTypes.string,
  offers: PropTypes.array,
  loading: PropTypes.bool,
  errors: PropTypes.bool,
}
