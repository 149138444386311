import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { head } from 'lodash'
import { getIdentificationId } from 'services/customer-360/selectors'
import { selectProfileId } from 'modules/Permissions'
import { actions } from 'services/customer-360'

import { selectQueryParams } from 'modules/Router'
import { NEWTON } from 'services/global-constants/channels'
import { getTelesalesUrl } from '../helpers'

export function useAddMultiSim(status) {
  const queryParams = useSelector(selectQueryParams)
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()

  const customerId = useSelector(getIdentificationId)
  const agentProfile = useSelector(selectProfileId)

  function navigateToAddMultiSim(sub, channel, segment, offerDetail = []) {
    const order = offerDetail.find(detail => head(detail.subscriptions)?.id === sub?.id)
    const orderId = order?.order_info?.order_id
    const tariffId = sub?.tariff?.id

    let url
    if (queryParams.channel === NEWTON) {
      url = getTelesalesUrl(
        customerId,
        'isMultiSim',
        location,
        '/add-client/sales/info',
        agentProfile,
        segment,
      ).finalUrl
    } else {
      url = getTelesalesUrl(customerId, 'isMultiSim', location, '/add-client/sales/info').finalUrl
    }

    dispatch(
      actions.setSubscriptionAction({
        id: status === 'active' ? sub?.subscription_id : orderId,
        status,
        tariffId: status === 'active' ? sub?.type : tariffId,
        tariffSegment: segment,
        channel,
        msisdn: sub.id,
      }),
    )
    history.push(url)
  }

  return { navigateToAddMultiSim }
}
