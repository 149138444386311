import { call, put, takeLatest } from 'redux-saga/effects'
import { logError, formatErrorMessage } from 'services/logging'
import { axiosFactory } from 'modules/axios'

import {
  SEND_SMS_COMMUNICATION,
  sendSmsCommunicationSuccess,
  sendSmsCommunicationFailed,
} from './actions'
import { sendSmsCommunication } from './api'

function* sendSmsCommunicationSaga({ payload }) {
  const {
    senderInfo,
    terminatingNumber,
    content,
    realTimeRequired,
    scheduledTime,
    expirationTime,
  } = payload

  try {
    yield call(
      sendSmsCommunication,
      senderInfo,
      terminatingNumber,
      content,
      realTimeRequired,
      scheduledTime,
      expirationTime,
    )

    yield put(sendSmsCommunicationSuccess())
  } catch (e) {
    yield call(logError, { e: new Error(formatErrorMessage(e)), payload })
    const error = axiosFactory.buildError(e)
    yield put(sendSmsCommunicationFailed(error))
  }
}

export function* watchSmsCommunicationSaga() {
  yield takeLatest(SEND_SMS_COMMUNICATION, sendSmsCommunicationSaga)
}
