import { compact } from 'lodash'

import { formatDate } from 'utils/formatting/dates'
import { helpers as customer360SvcHelpers } from 'services/customer-360/helpers'
import {
  selectTelephoneNumbersCustomer,
  selectors as customer360SvcSelectors,
} from 'services/customer-360/selectors'

import * as subscriptionsSvc from 'services/subscriptions'
import { selectSfid } from 'modules/Auth'
import { selectors } from 'services/feature-flag'

import { selectProfileId } from 'modules/Permissions'
import { createSelector, createStructuredSelector } from 'reselect'
import {
  getSegmentCustomer,
  getFullNameFromOrdersCustomer,
  getIdentificationIdFromOrder,
  getBirthdate,
  getNationalityCustomer,
  getSubscriptionId,
  getOrderId,
  getOrderDate,
  getChannelCharacteristic,
  getContractid,
  getFlow,
  getSFIDOrder,
  getEmail,
  getBillingAddress,
  getContactTelephonesOrder,
  getIBAN,
  getTypeContract,
  getProductNumber,
  getMethodPay,
  getLanguageCustomer,
  getProductsOrderCustomer,
} from 'modules/orders'
import { selectOrderVerbalId } from 'modules/mas-consents/selectors'
import { POS_PROFILE } from 'modules/Permissions/constants'
import {
  VISTA_AUTHORIZED_SFIDS,
  VISTA_AUTHORIZED_SUFIX_SFID,
  VISTA_AUTHORIZED_PREFIX_SFID,
  VISTA_AUTHORIZED_PREFIX_SUFIX_SFID,
} from './constants'

export const selectPhones = createSelector(
  selectTelephoneNumbersCustomer,
  numbers => {
    const { mainNumber, secondNumber } = customer360SvcHelpers.getPhoneContactByPreference(
      numbers?.value() || [],
    )

    return compact([mainNumber, secondNumber])
  },
)

export const extractPersonalData = createSelector(
  [
    customer360SvcSelectors.getFirstName,
    customer360SvcSelectors.getMidName,
    customer360SvcSelectors.getLastName,
    customer360SvcSelectors.getFullName,
    customer360SvcSelectors.getName,
    customer360SvcSelectors.getSurname,
    customer360SvcSelectors.getIdentification,
    customer360SvcSelectors.getId,
    customer360SvcSelectors.getBirthday,
    customer360SvcSelectors.getSegment,
    customer360SvcSelectors.getActivatedAt,
    customer360SvcSelectors.getEmail,
    selectPhones,
    customer360SvcSelectors.getBillingAddress,
    customer360SvcSelectors.getLang,
    customer360SvcSelectors.getNationality,
    customer360SvcSelectors.getIsCompany,
  ],
  (
    firstName,
    midName,
    lastName,
    fullName,
    name,
    surname,
    individualIdentification,
    clientId,
    birthDay,
    segment,
    activeFrom,
    email,
    contactPhones,
    billingAddress,
    lang,
    nationality,
    isCompany,
  ) => ({
    firstName,
    midName,
    lastName,
    fullName,
    name,
    surname,
    birthDayStr: formatDate(birthDay),
    individualIdentification,
    clientId,
    segment,
    segmentStr: customer360SvcHelpers.getSegmentStr(segment),
    isCompany,
    activeFromStr: formatDate(activeFrom),
    email,
    contactPhones,
    contactPhonesStr: customer360SvcHelpers.getContactPhonesStr(contactPhones),
    billingAddress,
    billingAddressStr: customer360SvcHelpers.getBillingAddressStr(billingAddress),
    lang,
    langStr: customer360SvcHelpers.getLangFromId(lang)?.name,
    langCode: customer360SvcHelpers.getLangFromId(lang)?.code,
    nationality,
  }),
)

export const extractOwnerData = createStructuredSelector({
  segment: getSegmentCustomer,
  isCompany: customer360SvcSelectors.getIsCompany,
  fullName: getFullNameFromOrdersCustomer,
  individualIdentification: getIdentificationIdFromOrder,
  birthdate: getBirthdate,
  nationality: getNationalityCustomer,
  langCode: getLanguageCustomer,
  langStr: createSelector(
    getLanguageCustomer,
    lang => customer360SvcHelpers.getLangFromCode(lang)?.name,
  ),
})

export const extractOrderData = createStructuredSelector({
  orderId: getOrderId,
  subscriptionId: getSubscriptionId,
  dateOrder: getOrderDate,
  channel: getChannelCharacteristic,
  sfid: getSFIDOrder,
  contractId: getContractid,
  flow: getFlow,
  products: getProductsOrderCustomer,
  verbalAgreementId: selectOrderVerbalId,
})

export const extractContactOrderData = createStructuredSelector({
  address: getBillingAddress,
  telephones: getContactTelephonesOrder,
  mail: getEmail,
})

export const extractServicesSimData = createStructuredSelector({
  pin: subscriptionsSvc.getPinMobile,
  puk: subscriptionsSvc.getPukMobile,
  pin2: subscriptionsSvc.getPin2Mobile,
  puk2: subscriptionsSvc.getPuk2Mobile,
  imsi: subscriptionsSvc.getImsiMobile,
})
export const extractDataMobileConsumption = createStructuredSelector({
  totalData: subscriptionsSvc.getTotalDataInMbMobile,
  consumedData: subscriptionsSvc.getConsumedDataInMbMobile,
  totalCallsTariff: subscriptionsSvc.getSubscriptionCallsInMin,
  consumedCallsTariff: subscriptionsSvc.getTotalTariffUsageInMin,
  consumedCallsWithoutExtras: subscriptionsSvc.getTotalWithoutExtrasCallsConsumed,
  totalExtrasMobile: subscriptionsSvc.getTotalExtrasMobile,
  totalExtrasFixed: subscriptionsSvc.getTotalExtrasFixed,
  callsConsumed: subscriptionsSvc.getCallsConsumed,
  premiumCallsConsumed: subscriptionsSvc.getPremiumCallsConsumed,
  internationalCallsConsumed: subscriptionsSvc.getInternationalCallsConsumed,
  othersConsumed: subscriptionsSvc.getOthersConsumed,
  smsConsumed: subscriptionsSvc.getSmsConsumed,
  smsConsumedAtCost: subscriptionsSvc.getSmsConsumedAtCost,
  smsAmountConsumed: subscriptionsSvc.getSmsAmountConsumed,
  hasUnlimitedGigas: subscriptionsSvc.hasUnlimitedDataMobile,
  hasUnlimitedMin: subscriptionsSvc.hasMobileUnlimitedMin,
  totalDataConsumed: subscriptionsSvc.getTotalDataConsumed,
})
export const extractDataFixedConsumption = createStructuredSelector({
  totalCallsTariff: subscriptionsSvc.getTariffCallsBundle,
  consumedCallsTariff: subscriptionsSvc.getM2AllMVoiceTotalUsageInMin,
  consumedCallsWithoutExtras: subscriptionsSvc.getTotalWithoutExtrasCallsConsumed,
  totalExtrasFixed: subscriptionsSvc.getTotalExtrasFixed,
  callsConsumed: subscriptionsSvc.getCallsConsumed,
  premiumCallsConsumed: subscriptionsSvc.getPremiumCallsConsumed,
  internationalCallsConsumed: subscriptionsSvc.getInternationalCallsConsumed,
  othersConsumed: subscriptionsSvc.getOthersConsumed,
  hasUnlimitedMin: subscriptionsSvc.hasMobileUnlimitedMin,
})

export const extractDataOrderDetailSell = createStructuredSelector({
  orderDate: getOrderDate,
  channel: getChannelCharacteristic,
  sfid: getSFIDOrder,
  typeContract: getTypeContract,
  methodPay: getMethodPay,
  bankNumber: getIBAN,
})

export const extractDataOrderDetailDocuments = createStructuredSelector({
  idContract: getContractid,
  suscription: getProductNumber,
  orderDate: getOrderDate,
  fullName: getFullNameFromOrdersCustomer,
})

export const selectIsVistaAuthorized = createSelector(
  [selectSfid, selectors.selectFeatureFlagByName('vista')],
  (sfid, vista) =>
    (vista && VISTA_AUTHORIZED_SFIDS.some(authorizedSfid => sfid === authorizedSfid)) ||
    VISTA_AUTHORIZED_SUFIX_SFID.some(sufix => sfid.endsWith(sufix)) ||
    VISTA_AUTHORIZED_PREFIX_SFID.some(prefix => sfid.startsWith(prefix)) ||
    VISTA_AUTHORIZED_PREFIX_SUFIX_SFID.some(
      val => sfid.startsWith(val.startBy) && sfid.endsWith(val.endBy),
    ),
)

export const selectIsPosProfile = createSelector(
  selectProfileId,
  agentProfile => agentProfile === POS_PROFILE,
)
