import { call, takeLatest, put } from 'redux-saga/effects'
import * as logisticOrdersSvc from 'services/logistic-orders'
import * as ordersSvc from 'modules/orders'
import * as offersDetailSvc from 'services/offers-detail'

import { actions } from './actions'
import { constants } from './constants'

function* orderDetailSaga({ payload: { orderId, msisdn } }) {
  try {
    yield call(ordersSvc.callFindByIdSaga, orderId)
    yield put(actions.loadSucceeded())

    yield call(logisticOrdersSvc.callSaga, orderId, msisdn)
    yield call(offersDetailSvc.callSaga, orderId)
  } catch (e) {
    yield put(actions.loadFailed(e))
  }
}

export function* watchLoad() {
  yield takeLatest(constants.LOAD_INIT, orderDetailSaga)
}
