import { useSelector } from 'react-redux'
import { selectCanIDO, permissions } from 'modules/Permissions'

import { makeStyles } from '@material-ui/styles'

import { Box } from '@material-ui/core'
import { ButtonLink } from 'components/ui'
import { Card } from 'modules/ui'

import { useForceSaveAuth } from 'modules/Auth'
import { createInsurancePpiSale } from 'modules/Insurance'

const useStyles = makeStyles(theme => ({
  'add-new-insurance-ppi-package-card': {
    '& button': {
      color: theme.palette.greenPalette.green_500,

      '&:hover': {
        color: theme.palette.greenPalette.green_600,
      },
    },
  },
}))

export const SaleInsurancePpiWithClientCardButton = () => {
  const classes = useStyles()

  const { saveLatestAccessToken } = useForceSaveAuth()

  const canInsurancePpiSale = useSelector(selectCanIDO(permissions.cvm_insurance_ppi_sale.id))

  const goToNewSale = () => {
    const redirectUrl = createInsurancePpiSale()

    saveLatestAccessToken()
    window.location.href = redirectUrl
  }

  return (
    <Box mt="15px">
      <Card className={classes['add-new-insurance-ppi-package-card']} title="">
        <ButtonLink
          onClick={goToNewSale}
          disabled={!canInsurancePpiSale}
          data-hook="sale-insurance-ppi-withclient-button"
          underline={false}
          className="card-link">
          + Añadir nueva venta de seguro de protección de pagos
        </ButtonLink>
      </Card>
    </Box>
  )
}
