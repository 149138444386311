import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { getTax, isLoading } from '../selectors'
import taxesActions from '../actions'

export const useProvinceTaxes = zipCode => {
  const dispatch = useDispatch()

  const tax = useSelector(state => getTax(state, zipCode))
  const taxIsLoading = useSelector(state => isLoading(state, zipCode))

  useEffect(() => {
    if (!zipCode || tax || taxIsLoading) {
      return
    }

    dispatch(taxesActions.findTax(zipCode))
  }, [zipCode])

  return {
    tax,
    taxIsLoading,
  }
}
