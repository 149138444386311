import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { FormDateProvider, FormDatePicker } from 'components/ui'
import { Grid, CircularProgress } from '@material-ui/core'
import { format } from 'date-fns'
import { get } from 'lodash'

import {
  actions,
  selectPortabilityDatesError,
  selectPortabilityDates,
  selectPortabilityDatesHasFailed,
  selectPortabilityDatesLoading,
} from '../../store'

function PortabilityDatePicker({
  fieldNames,
  portaDate,
  onSetPortaDate,
  className,
  errors,
  touched,
}) {
  const dispatch = useDispatch()

  const portabilityDates = useSelector(selectPortabilityDates)
  const error = useSelector(selectPortabilityDatesError)
  const hasFailed = useSelector(selectPortabilityDatesHasFailed)
  const isLoading = useSelector(selectPortabilityDatesLoading)

  const fetchPortabilityDates = () => dispatch(actions.fetchPortabilityDates())

  const isTouched = get(touched, fieldNames.POSTPONE_PORTABILITY_NEW_DATE, false)
  const { startDay, endDay, holidays = [] } = portabilityDates
  const isFieldError = get(errors, fieldNames.POSTPONE_PORTABILITY_NEW_DATE, '') && isTouched

  useEffect(() => {
    if (!startDay) {
      fetchPortabilityDates()
    }
  }, [])

  function disableDates(date) {
    return holidays.includes(format(date, 'yyyy-MM-dd', { awareOfUnicodeTokens: true }))
  }

  return (
    <FormDateProvider>
      <Grid container>
        <Grid item xs={4}>
          {isLoading && <CircularProgress />}

          {!error && !isLoading && (
            <FormDatePicker
              error={isFieldError && isTouched}
              initialFocusedDate={new Date(startDay)}
              label="Fecha Nueva"
              disablePast
              minDate={new Date(startDay)}
              maxDate={new Date(endDay)}
              value={portaDate}
              name={fieldNames.POSTPONE_PORTABILITY_NEW_DATE}
              shouldDisableDate={disableDates}
              onChange={date => onSetPortaDate(date, fieldNames)}
            />
          )}

          {isFieldError ? (
            <div className={className}>
              <span className="error">El campo es obligatorio</span>
            </div>
          ) : null}

          {hasFailed && (
            <div className={className}>
              <span className="error">No se ha podido cargar el calendario de festivos</span>
            </div>
          )}

          {error && (
            <div className={className}>
              <span className="error">Ha sucedio un error al cargar el calendario de festivos</span>
            </div>
          )}
        </Grid>
      </Grid>
    </FormDateProvider>
  )
}

PortabilityDatePicker.propTypes = {
  fieldNames: PropTypes.object.isRequired,
  portaDate: PropTypes.string,
  onSetPortaDate: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
  errors: PropTypes.object,
  touched: PropTypes.object,
}

export default PortabilityDatePicker
