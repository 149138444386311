import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { OfferItem, OfferItemModified } from 'components/ui'
import { formatMonthlyFee } from 'utils'

import { selectProfileId } from 'modules/Permissions'
import DiscountsAndBonusesModal from './DiscountsAndBonusesModal'
import { getPendingDiscounts, getPendingBonuses, canModifySubscription } from '../selectors'
import {
  formatBonusName,
  formatDiscountNameAndDuration,
  getSubscriptionFeeAmount,
} from '../helpers'
import { useUpdateSubscriptionHandlers } from '../hooks/useUpdateSubscriptionHandlers'
import { useTaxApplied } from '../hooks/useTaxApplied'

const DiscountsBonusesItem = ({ subscription, onModalOpen }) => {
  const confirmedDiscounts = useSelector(state => getPendingDiscounts(state, subscription.id))
  const confirmedBonuses = useSelector(getPendingBonuses(subscription.id))

  const disabled = useSelector(state => !canModifySubscription(state, subscription))

  const { onUpdateSubscription } = useUpdateSubscriptionHandlers()

  const { feeAmount } = useTaxApplied({
    feeAmount: getSubscriptionFeeAmount(subscription),
  })

  const profileId = useSelector(selectProfileId)

  return confirmedDiscounts.length + confirmedBonuses.length === 0 ? (
    <OfferItem
      label="Cuota"
      value={formatMonthlyFee(feeAmount)}
      actionText="Añadir descuentos y bonos"
      actionClick={() => {
        onModalOpen(
          DiscountsAndBonusesModal,
          {
            onConfirm: onUpdateSubscription,
            subscription,
          },
          'Añadir descuentos y bonos',
        )
      }}
      disabled={disabled}
    />
  ) : (
    <OfferItemModified
      title={`Cuota: ${formatMonthlyFee(feeAmount)}`}
      labels={confirmedDiscounts
        .map(discount => formatDiscountNameAndDuration(discount, profileId))
        .concat(confirmedBonuses.map(bonus => formatBonusName(bonus)))}
      onRemove={() => onUpdateSubscription({ confirmedDiscounts: [], confirmedBonuses: [] })}
    />
  )
}

DiscountsBonusesItem.propTypes = {
  subscription: PropTypes.object.isRequired,
  onModalOpen: PropTypes.func.isRequired,
}

export default DiscountsBonusesItem
