import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Typography } from '@material-ui/core'
import { TextStyles } from 'utils/text'

import { formatTimeUnit, TIME_UNITS } from 'utils'

export const DurationMonthsDays = styled(({ className, duration }) => {
  if (duration) {
    const { months, days } = { months: duration.years * 12 + duration.months, days: duration.days }

    return (
      <div className={`${className} flex`}>
        {months ? (
          <Typography {...TextStyles.title1BoldDark()}>
            {`${months} ${formatTimeUnit(months, TIME_UNITS.MONTHS.id)}`}
          </Typography>
        ) : (
          ''
        )}
        {months && days ? (
          <Typography {...TextStyles.title2MediumBoldDark()}>
            <strong>&nbsp;y&nbsp;</strong>
          </Typography>
        ) : (
          ''
        )}
        {days ? (
          <Typography {...TextStyles.title2MediumBoldDark()}>
            <strong>{`${days} ${formatTimeUnit(days, TIME_UNITS.DAYS.id)}`}</strong>
          </Typography>
        ) : (
          ''
        )}
      </div>
    )
  }

  return ''
})`
  align-items: baseline;
  justify-content: center;
`

DurationMonthsDays.propTypes = {
  className: PropTypes.string,
  duration: PropTypes.object,
}
