import { useFormikContext } from 'formik'
import { getFormikSelectors } from './getMobileFormikSelectors'
import { useOnCheckDUOTariffs } from './useOnCheckDUOTariffs'

export function useSingleExtraMobileLogic(
  fieldNames,
  index,
  tariffList,
  lineType,
  isLoadingTariffs,
  canOnlyShowDuo,
  shouldDisableTerminal,
) {
  const { setFieldValue, values } = useFormikContext()
  const {
    selectedTariff,
    isNewTerminal,
    isAdditionalType,
    ps,
    ...formikLineValues
  } = getFormikSelectors(fieldNames, setFieldValue, values)

  // this logic can be moved once we unify the designs of only mobile and convergent
  const { tariffOptions, onChangeTariff, availableTariffs, isLoadingState } = useOnCheckDUOTariffs({
    index,
    tariffList,
    selectedTariff,
    isNewTerminal,
    isAdditionalType,
    fieldNames,
    lineType,
    ps,
    isLoadingTariffs,
    canOnlyShowDuo,
    shouldDisableTerminal,
  })

  return {
    tariffOptions,
    availableTariffs,
    onChangeTariff,
    isLoadingTariffs: isLoadingState,
    selectedTariff,
    isNewTerminal,
    isAdditionalType,
    ps,
    formikLineValues,
  }
}
