import { uniqBy, filter, orderBy } from 'lodash'
import { capitalizeText } from 'utils/formatting'

export function orderDevices(devicesToOrder, deviceOrdering = []) {
  if (devicesToOrder && deviceOrdering.length) {
    const sortingCriterias = deviceOrdering.map(ordering => ordering.sort)
    const sortingTypes = deviceOrdering.map(ordering => ordering.order)

    return orderBy(devicesToOrder, sortingCriterias, sortingTypes)
  }
  return devicesToOrder
}

export function mapDevicesToModelOptions(devicesToMap) {
  return orderBy(
    devicesToMap.map(deviceToMap => ({
      label: deviceToMap.name,
      value: deviceToMap.id,
    })),
    [modelOption => modelOption.label.toLowerCase()],
    ['asc'],
  )
}

export function findDevicesByModels(devicesToSearch, modelsToFind) {
  return devicesToSearch.filter(device => modelsToFind.includes(device.id))
}

export function mapDevicesToBrandOptions(devicesToMap) {
  const allBrands = devicesToMap.map(device => ({
    value: device.brand,
    label: capitalizeText(device.brand),
  }))

  return orderBy(
    uniqBy(allBrands, brand => brand.value),
    [brandOption => brandOption.label.toLowerCase()],
    ['asc'],
  )
}

export function mapDevicesToScreenSizeOptions(devicesToMap) {
  const allSizes = filter(devicesToMap, device => !!device.characteristics.screenSize).map(
    device => ({
      value: device.characteristics?.screenSize,
      label: device.characteristics?.screenSize,
    }),
  )

  return orderBy(
    uniqBy(allSizes, size => size.value),
    [screenSizeOption => parseFloat(screenSizeOption.value) || screenSizeOption.value],
    ['asc'],
  )
}

export function mapDevicesToCapacityOptions(devicesToMap) {
  const allCapacities = filter(devicesToMap, device => !!device.characteristics.storageMemory).map(
    device => ({
      value: device.characteristics?.storageMemory,
      label: device.characteristics?.storageMemory,
    }),
  )

  return orderBy(
    uniqBy(allCapacities, storageMemory => storageMemory.value),
    [storageMemoryOption => parseFloat(storageMemoryOption.value) || storageMemoryOption.value],
    ['asc'],
  )
}

export function mapDevicesToCategoryOptions(devicesToMap) {
  const allCategories = filter(devicesToMap, device => !!device.category).map(device => ({
    value: device.category,
    label: device.category,
  }))

  return orderBy(
    uniqBy(allCategories, category => category.value),
    [categoryOption => categoryOption.label.toLowerCase()],
    ['asc'],
  )
}

function isDeviceInPriceRange(filterMinPrice, filterMaxPrice, device) {
  const devicePrice = device.paymentMethods?.upfront?.initialPayment?.taxIncludedAmount

  let parsedMinValue = parseInt(filterMinPrice, 10)
  let parsedMaxValue = parseInt(filterMaxPrice, 10)
  parsedMinValue = parsedMinValue || 0
  parsedMaxValue = !parsedMaxValue && parsedMaxValue !== 0 ? 99999 : parsedMaxValue

  return (
    (devicePrice || devicePrice === 0) &&
    devicePrice >= parsedMinValue &&
    devicePrice <= parsedMaxValue
  )
}

function isDeviceDualSim(device) {
  return device.characteristics?.dualSim === true
}

function isDeviceValueIncludedInOptions(value, options) {
  return options.map(option => option.value).includes(value)
}

export function doesDeviceMeetFiltersCriterias(
  device,
  brandsToFilter,
  modelsToFilter,
  shouldApplyPriceFilter,
  minPriceFilter,
  maxPriceFilter,
  shouldBeDualSim,
  screenSizesToFilter,
  capacitiesToFilter,
  categoriesToFilter,
) {
  if (shouldApplyPriceFilter && !isDeviceInPriceRange(minPriceFilter, maxPriceFilter, device)) {
    return false
  }
  if (modelsToFilter?.length > 0 && !isDeviceValueIncludedInOptions(device.id, modelsToFilter)) {
    return false
  }

  if (brandsToFilter?.length > 0 && !isDeviceValueIncludedInOptions(device.brand, brandsToFilter)) {
    return false
  }

  if (shouldBeDualSim && !isDeviceDualSim(device)) {
    return false
  }

  if (
    screenSizesToFilter?.length > 0 &&
    !isDeviceValueIncludedInOptions(device.characteristics?.screenSize, screenSizesToFilter)
  ) {
    return false
  }

  if (
    capacitiesToFilter?.length > 0 &&
    !isDeviceValueIncludedInOptions(device.characteristics?.storageMemory, capacitiesToFilter)
  ) {
    return false
  }

  if (
    categoriesToFilter?.length > 0 &&
    !isDeviceValueIncludedInOptions(device.category, categoriesToFilter)
  ) {
    return false
  }

  return true
}

export function noFilterHasBeenSelected(
  { brand, model, dualSim, screenSize, capacity, category },
  isPriceFilterValid,
) {
  return (
    !brand?.length > 0 &&
    !model?.length > 0 &&
    !isPriceFilterValid &&
    !dualSim &&
    !screenSize?.length > 0 &&
    !capacity?.length > 0 &&
    !category?.length > 0
  )
}
