import { getIn, useFormikContext } from 'formik'
import {
  CAMPAIGN_EMPTY,
  CAMPAIGN_INVALID,
  CAMPAIGN_LINE_INVALID,
  CAMPAIGN_SELECT_TARIFF,
} from 'modules/SharedSales/constants'
import { fetchCampaign } from 'modules/tariffs/services/index'
import { useState, useEffect, useMemo } from 'react'
import { useSetFormikValuesToBasket } from 'modules/NewClientSales/hooks/Shared/useSetFormikValuesToBasket'

export function useCampaigns(fieldNames, lineType) {
  const [shouldShowResetCampaignError, setShouldShowResetCampaignError] = useState(false)
  const setFormikValuesToBasket = useSetFormikValuesToBasket()
  const { values, setFieldValue, setStatus, status } = useFormikContext()
  const { campaign } = getIn(values, lineType)
  const campaignValue = getIn(values, fieldNames.CAMPAIGN_ID)
  const hasTerminal = getIn(values, fieldNames.HAS_TERMINAL)
  const tariffType = getIn(values, fieldNames.TARIFF_TYPE)
  const signUpType =
    tariffType === 'fixed_line'
      ? getIn(values, fieldNames.MOBILE_LINE_TYPE)
      : getIn(values, fieldNames.MOBILE_SIGN_UP_TYPE)
  const portabilityType = getIn(values, fieldNames.MOBILE_PORTABILITY_PAYMENT_TYPE)

  const canApplyCampaign = useMemo(
    () =>
      tariffType === 'convergent' ||
      tariffType === 'mobile_line' ||
      tariffType === 'main_line' ||
      tariffType === 'mobile_fixed_line' ||
      tariffType === 'fixed_line',
    [tariffType],
  )

  function setError(errorText) {
    setStatus({ ...status, [fieldNames.CAMPAIGN_ID]: errorText })
  }

  function resetCampaign() {
    setFormikValuesToBasket(fieldNames.CAMPAIGN, {})
    setFieldValue(fieldNames.CAMPAIGN_ID, '')
    setFieldValue(fieldNames.CAMPAIGN, {})
    setError(null)
  }

  function shouldResetCampaignBySignUpType(_campaign) {
    return !signUpType ||
      !_campaign?.campaignPo ||
      _campaign?.instanceCharacteristics?.numberType?.length === 0
      ? false
      : !_campaign?.instanceCharacteristics?.numberType?.includes(signUpType)
  }

  function shouldResetCampaignByPortabilityType(_campaign) {
    if (tariffType === 'fixed_line') return false
    return !portabilityType ||
      !_campaign?.campaignPo ||
      _campaign?.instanceCharacteristics?.portabilityPaymentType?.length === 0
      ? false
      : !_campaign?.instanceCharacteristics?.portabilityPaymentType?.includes(portabilityType)
  }

  function shouldResetCampaignByTerminal(_campaign) {
    if (
      tariffType === 'fixed_line' &&
      _campaign?.campaignPo &&
      _campaign?.instanceCharacteristics?.campaignType.includes('sim_and_handset')
    )
      return true
    return !hasTerminal ||
      !_campaign?.campaignPo ||
      _campaign?.instanceCharacteristics?.campaignType?.length === 0
      ? false
      : (hasTerminal === 'yes' &&
          !_campaign?.instanceCharacteristics?.campaignType.includes('sim_and_handset')) ||
          (hasTerminal === 'no' &&
            !_campaign?.instanceCharacteristics?.campaignType.includes('sim_only'))
  }

  function checkIsValidCampaign(_campaign) {
    return (
      !shouldResetCampaignBySignUpType(_campaign) &&
      !shouldResetCampaignByPortabilityType(_campaign) &&
      !shouldResetCampaignByTerminal(_campaign)
    )
  }

  function getDiscountText(_campaign) {
    const discount = _campaign?.discounts[0]
    if (!discount) return ''
    return discount.units === 'monetary'
      ? discount.amount * -1
      : `${(Math.round(discount.amount * 100) / 100) * -1} %`
  }

  useEffect(() => {
    if (!checkIsValidCampaign(campaign)) {
      setError(CAMPAIGN_LINE_INVALID)
      if (campaign) {
        setShouldShowResetCampaignError(true)
        resetCampaign()
      }
    }
  }, [signUpType, portabilityType, hasTerminal])

  async function applyCampaign(tariffPsId) {
    setError(null)
    if (!campaignValue) return setError(CAMPAIGN_EMPTY)
    if (!tariffPsId) return setError(CAMPAIGN_SELECT_TARIFF)
    const campaignFetched = await fetchCampaign(campaignValue, tariffPsId)
    if (!campaignFetched) return setError(CAMPAIGN_INVALID)
    if (!checkIsValidCampaign(campaignFetched)) {
      return setError(CAMPAIGN_LINE_INVALID)
    }

    if (campaignFetched?.campaignPo) {
      setFormikValuesToBasket(fieldNames.CAMPAIGN, {
        id: campaignValue,
        discountText: getDiscountText(campaignFetched),
        ...campaignFetched,
      })
      setError(null)
    }
    return true
  }

  return {
    canApplyCampaign,
    campaign,
    applyCampaign,
    resetCampaign,
    shouldShowResetCampaignError,
    setShouldShowResetCampaignError,
  }
}
