import { get } from 'lodash'
import { call, put, takeLatest, takeEvery, select } from 'redux-saga/effects'
import { logError, formatErrorMessage } from 'services/logging'
import { axiosFactory } from 'modules/axios'
import { selectTerritoryOwner } from 'modules/Coverage'

import {
  FIND_TARIFF_BY_ID,
  FIND_TARIFFS,
  FIND_CONVERGENT_TARIFFS,
  FIND_CONVERGENT_TARIFFS_IDS,
  findTariffByIdSuccessAction,
  findTariffByIdErrorAction,
  findTariffsSuccessAction,
  findTariffsErrorAction,
  findConvergentTariffsSuccessAction,
  findConvergentTariffsErrorAction,
  findConvergentTariffsIdsSuccessAction,
  findConvergentTariffsIdsErrorAction,
} from './tariffs-apigee.actions'
import {
  fetchApigeeTariffs,
  fetchTariffById,
  fetchConvergentTariffs,
  fetchConvergentTariffsIds,
} from '../services/tariffs-apigee.service'

export function* findTariffByIdSaga({ payload: id }) {
  try {
    const tariffs = yield call(fetchTariffById, id.id)

    yield put(findTariffByIdSuccessAction({ data: tariffs, id }))
  } catch (e) {
    yield call(logError, { e: new Error(formatErrorMessage(e)), id })
    const error = axiosFactory.buildError(e)
    yield put(findTariffByIdErrorAction(error))
  }
}

export function* watchFindTariffById() {
  yield takeEvery(FIND_TARIFF_BY_ID, findTariffByIdSaga)
}

export function* findTariffsSaga({ payload: filters }) {
  try {
    const tariffs = yield call(fetchApigeeTariffs, filters)

    yield put(findTariffsSuccessAction({ data: tariffs, filters }))
  } catch (e) {
    yield call(logError, { e: new Error(formatErrorMessage(e)), filters })
    const error = axiosFactory.buildError(e)
    yield put(findTariffsErrorAction(error, filters))
  }
}

export function* watchFindTariffs() {
  yield takeEvery(FIND_TARIFFS, findTariffsSaga)
}

export function* findConvergentTariffsSaga({ payload }) {
  try {
    const tariffs = yield call(fetchConvergentTariffs, payload)

    yield put(findConvergentTariffsSuccessAction({ data: tariffs }))
  } catch (e) {
    yield call(logError, { e: new Error(formatErrorMessage(e)), payload })
    const error = axiosFactory.buildError(e)
    yield put(findConvergentTariffsErrorAction(error))
  }
}

export function* watchFindConvergentTariffs() {
  yield takeLatest(FIND_CONVERGENT_TARIFFS, findConvergentTariffsSaga)
}

export function* findConvergentTariffsIdsSaga() {
  try {
    const territoryOwner = yield select(selectTerritoryOwner)
    const tariffs = yield call(fetchConvergentTariffsIds, get(territoryOwner, 'value'))

    yield put(findConvergentTariffsIdsSuccessAction({ data: tariffs }))
  } catch (e) {
    yield call(logError, new Error(formatErrorMessage(e)))
    const error = axiosFactory.buildError(e)
    yield put(findConvergentTariffsIdsErrorAction(error))
  }
}

export function* watchFindConvergentTariffsIds() {
  yield takeLatest(FIND_CONVERGENT_TARIFFS_IDS, findConvergentTariffsIdsSaga)
}
