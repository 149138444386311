import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { FIBER_INSTALLER } from 'modules/Installations/constants'
import { Box, Card, CardContent } from '@material-ui/core'

import { TitleSidebar, TextSidebar } from 'components/ui'
import {
  selectIsMobileOnly,
  selectIs2p,
  selectIsSubscriptionQueryParam,
  selectIsCrossSellQueryParam,
  selectIsMultiSimQueryParam,
} from 'modules/Router/store/index'

import { selectIsElFijo } from '../../store'

export function ClientInfoSidebar({
  mainUser,
  fullAddress,
  installationTechnologyName,
  fiberInstaller,
}) {
  const isElFijo = useSelector(selectIsElFijo) && 'El Fijo'
  const isMobileOnly = useSelector(selectIsMobileOnly) && 'Solo móvil'
  const is2p = useSelector(selectIs2p) && 'Internet + fijo'
  const isSubscription = !!useSelector(selectIsSubscriptionQueryParam) && 'Subscripción existente'
  const isCrossSell = !!useSelector(selectIsCrossSellQueryParam) && 'Conversión a convergente'
  const isMultiSim = !!useSelector(selectIsMultiSimQueryParam) && 'Añadir MultiSIM'
  const convergent = 'Convergente'

  return (
    <Box display="flex" pt="20px" justifyContent="center" data-hook="sideBar-clientInfo">
      <Card style={{ width: '90%', borderRadius: '10px', margin: '20px 0 8px 0' }}>
        <CardContent>
          <div>
            <TitleSidebar>Tipo de contratación</TitleSidebar>
            <TextSidebar dataHook="sale-type">
              {isSubscription ||
                isCrossSell ||
                isElFijo ||
                isMobileOnly ||
                is2p ||
                isMultiSim ||
                convergent}
            </TextSidebar>
          </div>
          {!isMobileOnly && !isSubscription && !isMultiSim && (
            <div>
              <TitleSidebar dataHook="full-address-title">
                Dirección de {isElFijo ? 'servicio' : 'instalación'}
              </TitleSidebar>
              <TextSidebar dataHook="full-address">{fullAddress}</TextSidebar>
            </div>
          )}

          {!!fiberInstaller && (
            <div>
              <TitleSidebar>Instala {installationTechnologyName}</TitleSidebar>
              <TextSidebar>
                {fiberInstaller === FIBER_INSTALLER.MM ? 'YOIGO' : 'Telefónica'}
              </TextSidebar>
            </div>
          )}

          <div>
            <TitleSidebar>Titular</TitleSidebar>
            <TextSidebar dataHook="holder">{mainUser || '-'}</TextSidebar>
          </div>
        </CardContent>
      </Card>
    </Box>
  )
}

ClientInfoSidebar.propTypes = {
  mainUser: PropTypes.string,
  fullAddress: PropTypes.string,
  installationTechnologyName: PropTypes.string,
  fiberInstaller: PropTypes.string,
}
