import { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { filter, find, union } from 'lodash'

import { Box } from '@material-ui/core'

import { ORDER_TYPES } from 'modules/orders/constants/order-types'
import { getProductNumbers } from 'services/subscriptions'
import { NewOffersConfigurationContainer } from 'modules/offers-configuration/containers'
import { selectSubscriptionWithLineType } from 'modules/offers-configuration/selectors'

import { selectors as customer360SvcSelectors } from 'services/customer-360/selectors'
import { helpers as customer360SvcHelpers } from 'services/customer-360/helpers'

import { FilterLines } from './FilterLines'

export function AccountPackages({ className }) {
  const subscriptions = useSelector(selectSubscriptionWithLineType)
  const ordersOnfly = useSelector(customer360SvcSelectors.ordersWithCategoy)

  const [selectedLinesInfo, setSelectedLinesInfo] = useState([])

  const customerStatus = useSelector(customer360SvcSelectors.getStatus)

  const subsActiveFiltered = useMemo(
    () =>
      filter(subscriptions, sub => {
        const productNumbers = getProductNumbers(sub)

        return (
          find(selectedLinesInfo, {
            productId: productNumbers.mobile,
            type: 'subscription',
            stringStatus: customer360SvcHelpers.STATUS_ACTIVE_STR,
          }) ||
          find(selectedLinesInfo, {
            productId: productNumbers.fixed,
            type: 'subscription',
            stringStatus: customer360SvcHelpers.STATUS_ACTIVE_STR,
          })
        )
      }),
    [subscriptions, selectedLinesInfo],
  )

  const ordersFiltered = useMemo(
    () =>
      customerStatus !== 'cancelled'
        ? filter(ordersOnfly, order =>
            find(selectedLinesInfo, {
              type: 'order',
              orderId: order.id,
              stringStatus: customer360SvcHelpers.STATUS_ON_FLY_STR,
            }),
          ).filter(filteredOrder => !ORDER_TYPES.includes(filteredOrder.type))
        : [],
    [customerStatus, ordersOnfly, selectedLinesInfo],
  )

   const subsAndOrdersFiltered = useMemo(
    () =>
      union(subsActiveFiltered, ordersFiltered),
      [subsActiveFiltered, ordersFiltered],
  ) 

  function onSelectedLines(selectedLinesInfoIn) {
    setSelectedLinesInfo(selectedLinesInfoIn)
  }

  return (
    <Box display="flex" flexDirection="column" className={className}>
      <Box pb="24px">
        <FilterLines onSelectedLines={onSelectedLines} />
      </Box>

      <NewOffersConfigurationContainer
        subsAndOrdersFiltered={subsAndOrdersFiltered}
        subsActiveFiltered={subsActiveFiltered}
        ordersFiltered={ordersFiltered}
      />
    </Box>
  )
}

AccountPackages.propTypes = {
  className: PropTypes.string,
}
