import PropTypes from 'prop-types'

import { Grid } from '@material-ui/core'
import { ContainerTitle, CardRow, CardRowLabel, CardRowValue } from 'components'

const CurrentSubscription = ({ products, customerTariff }) => (
  <Grid item xs={12} sm={6}>
    <ContainerTitle>SUSCRIPCIÓN ACTUAL</ContainerTitle>
    {(products &&
      products.map(item => (
        <div key={item.id}>
          <CardRow>
            <CardRowLabel>Tarifa actual:</CardRowLabel>
            <CardRowValue>{customerTariff}</CardRowValue>
          </CardRow>
          {item.productsSpecifications.map(linea => (
            <CardRow key={linea.id} subscript>
              <CardRowLabel>Teléfono:</CardRowLabel>
              <CardRowValue fontWeight="300">{linea.id}</CardRowValue>
            </CardRow>
          ))}
        </div>
      ))) ||
      ''}
  </Grid>
)

CurrentSubscription.propTypes = {
  products: PropTypes.array,
  customerTariff: PropTypes.string,
}

export default CurrentSubscription
