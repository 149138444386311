import {
  get,
  chain,
  includes,
  every,
  find,
  isEmpty,
  head,
  capitalize,
  isUndefined,
  map,
  isNaN,
} from 'lodash'
import { createSelector } from 'reselect'
import { NoDataStr, getProvinceFromCode, mobileNumberRegex } from 'utils'
import { format } from 'date-fns'

import { selectURLOrderId } from 'modules/orders'
import { selectAccountType } from 'modules/CustomerInfo/store/customerInfo.selectors'
import {
  COMPANY,
  CONSUMER,
  AUTONOMO,
  CARTERA_SEGMENTS,
  D2D_RESELLER,
} from 'services/global-constants'

import { isDuoTariffId } from 'modules/tariffs/store-apigee/tariffs-apigee.helpers'

import { selectQueryParams } from 'modules/Router/store/index'

import { CC, CC_PRO, NEWTON, POS } from 'services/global-constants/channels'
import { helpers } from './helpers'
import { CUSTOMER_STATUS } from './constants'

export const getData = state => get(state, 'customer360.data', {})
export const getLoading = state => get(state, 'customer360.loading')
export const getError = state => get(state, 'customer360.error')

const getDataClient = state => get(getData(state), 'dataClient')
const getDataHistoricOrders = state => get(getData(state), 'historicOrders')

const getFirstName = state => get(getDataClient(state), 'firstName')
const getMidName = state => get(getDataClient(state), 'midName')
const getLastName = state => get(getDataClient(state), 'lastName')
export const getId = state => get(getDataClient(state), 'id')
const getStatus = state => get(getDataClient(state), 'status')

const getFullName = createSelector(
  [getFirstName, getMidName, getLastName],
  (firstName, midName, lastName) =>
    every([!firstName, !midName, !lastName], Boolean)
      ? NoDataStr
      : [firstName, midName, lastName].filter(Boolean).join(' '),
)

const getName = createSelector([getFirstName], firstName => (!firstName ? NoDataStr : firstName))

const getSurname = createSelector([getMidName, getLastName], (midName, lastName) =>
  every([!midName, !lastName], Boolean) ? NoDataStr : [midName, lastName].filter(Boolean).join(' '),
)

const getActivatedAt = createSelector([getDataClient], data => get(data, 'validFor.startDateTime'))

export const getIdentification = createSelector([getDataClient], data =>
  chain(data).get('individualIdentification').filter('identificationId').get('[0]').value(),
)

export const getIdentificationType = createSelector([getDataClient], data =>
  chain(data).get('individualIdentification').filter('identificationId').get('[0].type').value(),
)

export const getIdentificationId = createSelector([getDataClient], data =>
  chain(data)
    .get('individualIdentification')
    .filter('identificationId')
    .get('[0].identificationId')
    .value(),
)

const getBirthday = createSelector([getDataClient], data =>
  chain(data).get('characteristic').filter({ name: 'birthday' }).get('[0].value').value(),
)

export const getSegment = createSelector([getDataClient], data =>
  chain(data).get('characteristic').filter({ name: 'customer-segment' }).get('[0].value').value(),
)

export const selectIsSelfEmployed = createSelector([getDataClient], data =>
  chain(data).get('characteristic').filter({ name: 'self-employed' }).get('[0].value').value(),
)

const getEmail = createSelector([getDataClient], data =>
  chain(data).get('contactMedium').filter({ type: 'Email' }).get('[0].medium.emailAddress').value(),
)
export const selectTelephoneNumbersCustomer = createSelector(
  [getDataClient],
  data => chain(data).get('contactMedium').filter({ type: 'TelephoneNumber' }) || [],
)

const getContactPhones = createSelector([getDataClient], data =>
  chain(data).get('contactMedium').filter({ type: 'TelephoneNumber' }).map('medium').value(),
)

const getContactMobilePhones = createSelector([getContactPhones], dataPhones =>
  dataPhones.filter(phone => mobileNumberRegex.test(phone.number)),
)

const getBillingAddress = createSelector([getDataClient], data =>
  chain(data).get('contactMedium').filter({ type: 'BillingAddress' }).get('[0].medium').value(),
)

const getBillingAddressPostCode = createSelector([getBillingAddress], billingAddress =>
  get(billingAddress, 'postCode'),
)

const getLang = createSelector([getDataClient], data =>
  chain(data).get('characteristic').filter({ name: 'language' }).get('[0].value').value(),
)

const getCompany = createSelector([getDataClient], data =>
  chain(data).get('characteristic').find({ name: 'company' }).get('value').value(),
)

const getIsCompany = createSelector([getSegment], data => includes(data, 'COMPANY'))

const getAuthorizedPersonDocumentId = createSelector([getDataClient], data =>
  chain(data)
    .get('characteristic')
    .find({ name: 'authorized-person-id-number' })
    .get('value')
    .value(),
)

const getIBAN = createSelector([getDataClient], data =>
  chain(data).get('characteristic').filter({ name: 'iban' }).get('[0].value').value(),
)

export const selectHistoricOrders = createSelector(getDataHistoricOrders, data =>
  chain(data).value(),
)

export const selectContracts = createSelector([getData], data =>
  chain(data).get('contracts', []).map(helpers.generateMultisimData).value(),
)

export const selectProductByLineId = createSelector(
  selectContracts,
  (_, lineId) => lineId,
  (contracts, lineId) => helpers.getProductByLineId(contracts, lineId),
)

const selectLineByLineId = createSelector(
  selectContracts,
  (_, lineId) => lineId,
  (contracts, lineId) => helpers.getLineByLineId(contracts, lineId),
)

export const selectInstallationAddressPackageByMsisdn = createSelector(
  selectContracts,
  (_, msisdn) => msisdn,
  (contracts, msisdn) => helpers.getInstallationAddressPackageByMsisdn(contracts, msisdn),
)

export const selectSubscriptions = createSelector([selectContracts], contracts =>
  chain(contracts[0]?.packages)
    .filter(contract => isUndefined(contract.orderId))
    .value(),
)

export const getProducts = createSelector([selectSubscriptions], subscriptions =>
  map(subscriptions, subscription => subscription.products),
)

export const getMainSubscription = createSelector([getProducts], products =>
  products.find(product => product[0].multisimType === 'main_line'),
)

export const getMainSubscriptionTariffId = createSelector([getMainSubscription], subscription =>
  chain(subscription).head().get('tariffId').value(),
)

export const selectSubscriptionsIds = createSelector([selectSubscriptions], subscriptions =>
  map(subscriptions, subscription => subscription.id),
)

export const selectOrders = createSelector([getData], data => chain(data).get('orders', []).value())

export const selectCurrentOrder = createSelector(
  [selectURLOrderId, selectOrders],
  (orderId, orders) => orders.find(order => order.id === orderId),
)

export const selectCurrentHistoricOrder = createSelector(
  [selectURLOrderId, getDataHistoricOrders],
  (orderId, historicOrders) => historicOrders.find(order => order.id === orderId),
)

const selectLines = createSelector([selectContracts], data =>
  chain(data)
    .flatMap(contract => contract.packages)
    .flatMap(pack => pack.products)
    .flatMap(product => {
      const { description } = product
      return chain(product.lines)
        .flatMap(line => ({ ...line, description }))
        .filter(line => line.stringStatus === 'active')
        .value()
    })
    .value(),
)

export const selectClientPackages = createSelector(
  [selectContracts, selectOrders],
  (contracts, orders) =>
    chain(contracts)
      .flatMap(contract => contract.packages)
      .flatMap(pack => {
        const { id, description } = pack
        const product = head(pack?.products)
        const { multisimType, multisimId, category, tariffId, lines } = product

        const order = find(orders, { mobileNumber: id, superStatus: 'fly' })

        const isDuo = isDuoTariffId(tariffId)

        let orderLineType = 'main_line'

        if (order?.parentOrderId) {
          orderLineType = isDuo ? 'extra_line' : 'additional_line'
        }

        const lineType = multisimType || orderLineType
        const parentOrderId = multisimId || order?.parentOrderId

        return {
          id,
          parentOrderId,
          description,
          category,
          tariffId,
          lines,
          lineType,
        }
      })
      .value(),
)

export const selectFirstFixedLine = createSelector(getProducts, products =>
  chain(products)
    .flatMap()
    .filter(product => ['3P', '2P'].includes(product.category))
    .flatMap(product => {
      const { id } = product
      return chain(product.lines)
        .flatMap(line => ({ ...line, id }))
        .filter({ stringStatus: 'active', type: 'fixed' })
        .value()
    })
    .get('[0]', null)
    .value(),
)

const selectErrorCode = createSelector([getError], data => chain(data).get('httpCode').value())

const selectErrorMessage = createSelector([getError], data => chain(data).get('info').value())

const getNationality = createSelector([getDataClient], data =>
  chain(data).get('characteristic').filter({ name: 'nationality' }).get('[0].value').value(),
)

const selectDSS = createSelector(
  selectContracts,
  (_, lineId) => lineId,
  (contracts, lineId) => {
    const product = helpers.getProductByLineId(contracts, lineId)
    return !!(get(product, 'dss2') || get(product, 'dss4'))
  },
)

export const selectMultisimType = createSelector(
  selectContracts,
  (_, lineId) => lineId,
  (contracts, lineId) => {
    const product = helpers.getProductByLineId(contracts, lineId)
    return get(product, 'multisimType')
  },
)

export const selectData = createSelector(getData, data => {
  return { data, dataClient: get(data, 'dataClient', {}) }
})

export const selectSegment = createSelector(selectData, ({ dataClient }) => {
  const originalSegment = helpers.getCharacteristic(dataClient.characteristic, 'customer-segment')

  return originalSegment ? capitalize(originalSegment) : ''
})

export const selectCustomerSegment = createSelector(
  selectSegment,
  selectAccountType,
  (segment, customerInfoSegment) => {
    switch ((customerInfoSegment || segment).toLowerCase()) {
      case CARTERA_SEGMENTS.CONSUMER.toLowerCase():
        return CONSUMER
      case CARTERA_SEGMENTS.AUTONOMO.toLowerCase():
      case CARTERA_SEGMENTS['SOHO-AUTONOMO'].toLowerCase():
      case CARTERA_SEGMENTS['PRO-SOHO-AUTONOMO'].toLowerCase():
        return AUTONOMO
      case CARTERA_SEGMENTS.COMPANY.toLowerCase():
      case CARTERA_SEGMENTS['SOHO-COMPANY'].toLowerCase():
      case CARTERA_SEGMENTS['PRO-SOHO-COMPANY'].toLowerCase():
        return COMPANY
      default:
        return CONSUMER
    }
  },
)

export const selectCustomerChannel = createSelector(selectSegment, segment =>
  segment.toLowerCase() === CARTERA_SEGMENTS.CONSUMER.toLowerCase() ? CC : CC_PRO,
)

export const selectIsCustomerResidential = createSelector(
  selectSegment,
  segment => !segment.toLowerCase().startsWith('pro'),
)

export const selectConstitutionDate = createSelector(selectData, ({ dataClient }) => {
  const isCompany = get(dataClient, 'individualIdentification[0].type', null) === 'CIF'

  const constitutionDate =
    helpers.getCharacteristic(dataClient.characteristic, 'incorporation-date') ||
    helpers.getCharacteristic(dataClient.characteristic, 'creation-date')

  return isCompany && constitutionDate ? format(new Date(constitutionDate), 'dd/MM/yyyy') : ''
})

export const selectCustomerData = createSelector(
  selectData,
  selectCustomerSegment,
  ({ data, dataClient }, segment) => {
    const contracts = get(data, 'contracts', {})
    const phoneContactMobile = helpers.getPhoneContact(dataClient.contactMedium || [], 'mobile')
    const phoneContactFixed = helpers.getPhoneContact(dataClient.contactMedium || [], 'fixed')
    const phoneContract = get(contracts, '[0].packages[0].id')
    const customerBirthDay = helpers.getCharacteristic(dataClient.characteristic, 'birthday')
    const birthdayCustomer = customerBirthDay
      ? format(new Date(customerBirthDay), 'dd/MM/yyyy')
      : ''
    const isCompany = get(dataClient, 'individualIdentification[0].type', null) === 'CIF'
    const creationDate =
      helpers.getCharacteristic(dataClient.characteristic, 'incorporation-date') ||
      helpers.getCharacteristic(dataClient.characteristic, 'creation-date')
    const constitutionDate =
      isCompany && creationDate ? format(new Date(creationDate), 'dd/MM/yyyy') : ''

    return {
      accountId: dataClient.id,
      name: dataClient.firstName,
      surname1: dataClient.midName,
      surname2: dataClient.lastName,
      documenttype: isCompany
        ? helpers.getCharacteristic(dataClient.characteristic, 'authorized-person-id-number-type')
        : get(dataClient, 'individualIdentification[0].type', null),
      documentid: isCompany
        ? helpers.getCharacteristic(dataClient.characteristic, 'authorized-person-id-number')
        : get(dataClient, 'individualIdentification[0].identificationId', ''),
      nationality: helpers.getCharacteristic(dataClient.characteristic, 'nationality'),
      phoneContact: (phoneContactMobile || phoneContactFixed || phoneContract || '').toString(),
      language: helpers.mapLanguage(
        helpers.getCharacteristic(dataClient.characteristic, 'language') || 1,
      ),
      phoneContact2: (phoneContactFixed || phoneContactMobile || phoneContract || '').toString(),

      phoneNumber: get(contracts, '[0].packages[0].id', '').toString(),
      companyName: helpers.getCharacteristic(dataClient.characteristic, 'company') || '',
      companyCif: isCompany
        ? get(dataClient, 'individualIdentification[0].identificationId', '')
        : '',
      segment,
      constitutionDate,
      birthday: birthdayCustomer,
      email: helpers.getMedium(dataClient.contactMedium, 'Email', 'medium.emailAddress'),
    }
  },
)

export const selectCustomerAddress = createSelector(getBillingAddress, billingAddress => {
  if (!billingAddress || isEmpty(billingAddress)) return {}

  const { city, postCode, streetOne, stateOrProvince } = billingAddress

  const number = (streetOne || '').slice(-2)

  return {
    streetType: 'Calle',
    city,
    number: !isNaN(+number) ? number : null,
    town: city,
    zipCode: postCode,
    province:
      getProvinceFromCode(stateOrProvince) || getProvinceFromCode(String(postCode).slice(0, 2)),
    address:
      streetOne && number && !isNaN(+number)
        ? streetOne.replace(new RegExp(` ${number}$`), '')
        : streetOne,
    editAddress: true,
  }
})

// THIS IS DISPOSABLE
export const selectSubscriptionTariffId = createSelector(
  getData,
  data => data?.subscription?.tariffId,
)

export const selectSubscriptionStatus = createSelector(getData, data => data?.subscription?.status)

export const selectSubscriptionId = createSelector(getData, data => data?.subscription?.id)

export const selectSubscriptionProductNumber = createSelector(
  getData,
  data => data?.subscription?.productNumber,
)

export const selectSubscriptionMsisdn = createSelector(getData, data => data?.subscription?.msisdn)

export const selectSubscriptionBundles = createSelector(
  getData,
  data => data?.subscription?.bundles,
)

export const selectSubscriptionChannel = createSelector(
  getData,
  data => data?.subscription?.channel,
)

export const selectOrdersWithCategory = createSelector(
  selectOrders,
  selectContracts,
  (orders, contracts) => {
    const packages = head(contracts)?.packages
    return orders.map(order => {
      const pkg =
        find(packages, { id: order.fixedNumber }) || find(packages, { id: order.mobileNumber })
      return {
        ...order,
        tariffId: get(head(get(pkg, 'products')), 'tariffId'),
        canHaveAditionalSubscription: ['3P', '1P'].includes(head(pkg?.products)?.category),
      }
    })
  },
)

const selectCustomerConsents = createSelector([getDataClient], dataClient =>
  helpers.getCharacteristic(dataClient?.characteristic, 'consents'),
)

const selectCustomerStatus = createSelector([getDataClient], data => get(data, 'status'))

export const selectVipType = createSelector([getDataClient], data => get(data, 'vipType'))

export const selectHasVipError = state => get(state, 'customer360.errorVipType')

export const selectSaleInformation = createSelector(
  getIdentificationId,
  getIdentificationType,
  selectIsSelfEmployed,
  getSegment,
  selectQueryParams,
  getStatus,
  (docNumber, docType, selfEmployed, segment, { channel, reseller }, status) => ({
    docNumber,
    docType,
    selfEmployed:
      (!!selfEmployed && selfEmployed !== 'false') ||
      (segment || '').toLowerCase().includes('autonomo'),
    segment: (segment || '').toLowerCase().includes('pro') ? 'pro' : 'residential',
    channel,
    reseller,
    status,
  }),
)

export const canEnableSomeSellOptionWithClient = createSelector(
  selectSaleInformation,
  ({ docNumber, docType, segment, channel, reseller, status }) => {
    const customerId = docNumber
    const PASSPORT_DOC_TYPE = 'PASSPORT'

    const checkSegmentAndChannel =
      status === CUSTOMER_STATUS.Canceled ||
      reseller === D2D_RESELLER ||
      (segment.includes('pro') && channel.includes('pro')) ||
      (!segment.includes('pro') && !channel.includes('pro')) ||
      channel === POS ||
      channel === NEWTON

    return !!customerId && docType !== PASSPORT_DOC_TYPE && checkSegmentAndChannel
  },
)

export const selectors = {
  data: getData,
  loading: getLoading,
  contracts: selectContracts,
  lines: selectLines,
  orders: selectOrders,
  subscriptions: selectSubscriptions,
  subscriptionsIds: selectSubscriptionsIds,
  ordersWithCategoy: selectOrdersWithCategory,
  error: getError,
  errorCode: selectErrorCode,
  errorMessage: selectErrorMessage,
  getIdentificationType,
  getIdentificationId,
  getBillingAddressPostCode,
  getCompany,
  getIsCompany,
  getAuthorizedPersonDocumentId,
  getIBAN,
  selectProductByLineId,
  selectLineByLineId,
  getId,
  getSegment,
  getFullName,
  getEmail,
  getActivatedAt,
  getBillingAddress,
  getContactPhones,
  getContactMobilePhones,
  getName,
  getSurname,
  getIdentification,
  getBirthday,
  getLang,
  getFirstName,
  getMidName,
  getLastName,
  getStatus,
  getNationality,
  selectDSS,
  selectMultisimType,
  getDataHistoricOrders,
  customerStatus: selectCustomerStatus,
  customerConsents: selectCustomerConsents,
  selectVipType,
  selectHasVipError,
  canEnableSomeSellOptionWithClient,
  selectIsCustomerResidential,
  getMainSubscription,
  getMainSubscriptionTariffId,
  getProducts,
}
