import PropTypes from 'prop-types'

import { useFormikContext } from 'formik'
import { get } from 'lodash'

import { SVAsCheckbox } from 'modules/SVAs'
import { useSelector } from 'react-redux'

import { RESIDENTIAL, CONSUMER } from 'modules/SharedSales/constants'
import { selectSVAAppleTv } from 'modules/NewClientSales/store/selectors'

import { onResetTerminal } from 'modules/NewClientSales/hooks/Terminals'
import { useSetFormikValuesToBasket, useChannelAndSegment } from '../../../hooks/Shared'
import { useAgileTvValidation } from '../../../hooks/Validations'
import { ErrorModal } from '../../Common'

export function AgileTv({ fieldNames, mainTariffPsId, isSvaFlag, is2p, isCrossSell, isCartera }) {
  const setFormikValuesToBasket = useSetFormikValuesToBasket()
  const { channel, segment } = useChannelAndSegment()
  const { validateAgileTvEmail, resetValidateAgileTvEmail, hasMsError, error } =
    useAgileTvValidation()
  const { setFieldValue, values } = useFormikContext()

  const {
    documentid,
    documenttype,
    segment: customerSegment,
    companyCif,
  } = get(values, 'account', {})
  const SVA = get(values, 'landlineOffer.SVA', {})
  const { email } = SVA

  const isAppleTv = useSelector(selectSVAAppleTv)
  const { resetDeviceMainMobileLine } = onResetTerminal()

  const svaSegment = segment === CONSUMER ? RESIDENTIAL : segment

  function onCheckSVA(checkedSVA, isSelected, isAppleTvSVA = false) {
    const finalSVA = !isSelected ? { ...checkedSVA, email } : { email }

    setFieldValue(fieldNames.SVA, finalSVA)
    setFormikValuesToBasket(fieldNames.SVA, finalSVA)

    if (isAppleTv && !isAppleTvSVA) {
      resetDeviceMainMobileLine()
    }

    resetValidateAgileTvEmail(fieldNames.SVA)
  }

  function onChangeSVAEmail(value, useSameEmail) {
    if (useSameEmail) setFieldValue('account.email', value)
  }

  return (
    <div className="m-b-12">
      <h3 className="card-title small m-t-20 m-b-20">Televisión</h3>
      <SVAsCheckbox
        isSvaFlag={isSvaFlag}
        onChangeSVAEmail={onChangeSVAEmail}
        segment={svaSegment}
        fieldNames={fieldNames}
        selectedSVA={SVA}
        onCheckSVA={onCheckSVA}
        channel={channel}
        mainTariffPsId={mainTariffPsId}
        emailVal={email}
        is2p={is2p}
        isCartera={isCartera}
        isCrossSell={isCrossSell}
        validateAgileTvEmail={validateAgileTvEmail}
        documentType={documenttype}
        documentId={documentid}
        salesSegment={customerSegment}
        companyCif={companyCif}
        isSelfEmployed={customerSegment === 'Autonomo'}
      />
      <ErrorModal error={error} hasMsError={hasMsError} />
    </div>
  )
}

AgileTv.propTypes = {
  fieldNames: PropTypes.object.isRequired,
  mainTariffPsId: PropTypes.string.isRequired,
  isSvaFlag: PropTypes.bool,
  is2p: PropTypes.bool,
  isCrossSell: PropTypes.bool,
  isCartera: PropTypes.bool,
}
