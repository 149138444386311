import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { TabsContainer } from 'components/ui'
import { getMsisdn } from 'services/subscriptions'
import { getHasModifications } from '../../selectors'

import { tabsConfig } from './tabsConfig'

const actionIn = {
  label: 'ver desglose',
  onClick: null,
  disabled: true,
}

export function CurrentAndNewFees({ className, subscription, action = actionIn }) {
  const contentProps = {
    subscription,
  }

  const hasModifications = useSelector(state => {
    return getHasModifications(state, getMsisdn(subscription))
  })

  const tabsInfo = tabsConfig.filter(tab => !(tab.label === 'CUOTA NUEVA' && !hasModifications))

  return (
    <TabsContainer
      className={`current-and-new-fees ${className}`}
      small
      tabsConfig={tabsInfo}
      contentProps={contentProps}
      action={action}
    />
  )
}

CurrentAndNewFees.propTypes = {
  action: PropTypes.shape({
    label: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
  }),
  className: PropTypes.string,
  subscription: PropTypes.object,
}
