import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useFormikContext } from 'formik'

import { actions as coverageActions } from 'modules/Coverage'

import { LandlineOffer } from '../../../store/models/landlineOffer'

import { setBasketLandlineOffer, setBasketAccountData } from '../../../store'

export const useSetPresaleData = (presaleData, shouldSetPresaleData = false) => {
  const dispatch = useDispatch()
  const { setFieldValue } = useFormikContext()

  useEffect(() => {
    if (shouldSetPresaleData && !!presaleData) {
      const {
        data: { account, landlineOffer, coverageToken, referenceNumber },
        sfid,
        presaleId,
      } = presaleData

      const presaleLandlineOffer = {
        ...LandlineOffer,
        ...landlineOffer,
      }

      dispatch(
        coverageActions.setCoverage({
          token: coverageToken,
          data: landlineOffer.installationAddress,
        }),
      )

      dispatch(setBasketLandlineOffer(presaleLandlineOffer))
      dispatch(setBasketAccountData(account))
      setFieldValue('landlineOffer', presaleLandlineOffer)
      setFieldValue('referenceNumber', referenceNumber)
      setFieldValue('account', account)
      setFieldValue('referralSfid', sfid)
      setFieldValue('presaleId', presaleId)
    }
  }, [])
}
