import {
  SEND_SMS_COMMUNICATION,
  SEND_SMS_COMMUNICATION_SUCCESS,
  SEND_SMS_COMMUNICATION_FAILED,
} from './actions'

const initialState = {
  loading: false,
  error: null,
  success: false,
}

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SEND_SMS_COMMUNICATION: {
      return {
        ...state,
        loading: true,
        error: null,
        success: false,
      }
    }

    case SEND_SMS_COMMUNICATION_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: null,
        success: true,
      }
    }

    case SEND_SMS_COMMUNICATION_FAILED: {
      return {
        ...state,
        loading: false,
        error: payload,
        success: false,
      }
    }

    default:
      return state
  }
}
