import {
  VALIDATE_IBAN,
  VALIDATE_IBAN_SUCCESS,
  VALIDATE_IBAN_ERROR,
  SET_EXISTING_IBAN_BEING_EDITED,
} from '../actions'

export const initialState = {
  isLoading: false,
  iban: '',
  error: {},
  name: '',
  office: {
    address: '',
    city: '',
    name: '',
    zipCode: '',
  },
  existingIbanIsBeingEdited: false,
}

export const reducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case VALIDATE_IBAN: {
      return {
        ...initialState,
        existingIbanIsBeingEdited: state.existingIbanIsBeingEdited,
        isLoading: true,
      }
    }
    case VALIDATE_IBAN_SUCCESS: {
      return {
        ...state,
        error: {},
        ...payload,
        isLoading: false,
      }
    }
    case VALIDATE_IBAN_ERROR: {
      return {
        ...initialState,
        existingIbanIsBeingEdited: state.existingIbanIsBeingEdited,
        error: {
          code: payload.httpCode,
          message: payload.info,
        },
        isLoading: false,
      }
    }
    case SET_EXISTING_IBAN_BEING_EDITED: {
      return {
        ...state,
        existingIbanIsBeingEdited: true,
      }
    }
    default:
      return state
  }
}
