import PropTypes from 'prop-types'
import { last, isEmpty } from 'lodash'

import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import { getDarken } from 'utils'

import { LineItemCard, DoubleLineItemCard, TitleLineItemCard, LogisticStatus } from 'components/ui'
import { formatNetkiaDate } from 'utils/formatting/dates'

import { formatCensuredDni } from 'modules/vista-360/helpers/formats.helper'
import { NIF } from 'modules/CustomerDocument/constants/documentTypes'

const useStyles = makeStyles(theme => ({
  'logistic-card-body': {
    alignItems: 'stretch',

    '& > div': {
      padding: '10px 0',
    },

    '& > div + div': {
      borderLeft: `1px solid ${getDarken(theme.palette.global.gray_medium, 0.1)}`,
    },
  },
}))
function LogisticCardBody({ data, isPosOrMasProximo }) {
  const classes = useStyles()

  const censuredDocumentId =
    isPosOrMasProximo && data.documentType === NIF
      ? formatCensuredDni(data.documentNumber)
      : data.documentNumber

  return (
    <Box display="flex" flexDirection="row" className={classes['logistic-card-body']}>
      <Box display="flex" flexDirection="column" flex={2}>
        <TitleLineItemCard
          primaryText="Productos a enviar" /* listActions={mockActionsEditLabel} */
        />
        <LineItemCard secondaryText={data.productsShipment} />
        <TitleLineItemCard
          primaryText="Empresa transportista" /* listActions={mockActionsEditLabel} */
        />
        <LineItemCard
          primaryText="Nombre"
          secondaryText={data.deliveryCompany}
          // link={mockLinkDelivery}
        />
        <LineItemCard primaryText="Número de expedición" secondaryText={data.expeditionNumber} />
        {!isEmpty(data.logisticStates) && (
          <LogisticStatus
            key={JSON.stringify(last(data.logisticStates))}
            statusCode={last(data.logisticStates).logisticStateID}
            date={formatNetkiaDate(last(data.logisticStates).created)}
          />
        )}
        <TitleLineItemCard primaryText="Dirección" /* listActions={mockActionsEditLabel} */ />
        <LineItemCard primaryText="Tipo de envío" secondaryText={data.deliveryType} />
        <LineItemCard primaryText="Dirección de entrega" secondaryText={data.sendAddress} />
      </Box>
      <Box display="flex" flexDirection="column" flex={1}>
        <TitleLineItemCard
          primaryText="Autorizado en la recogida"
          // listActions={mockActionsEditIcon}
        />
        <DoubleLineItemCard primaryText="Nombre y Apellidos" secondaryText={data.fullname} />
        <DoubleLineItemCard
          primaryText={`${data.documentType}`}
          secondaryText={censuredDocumentId}
        />
        <DoubleLineItemCard primaryText="Teléfono de contacto" secondaryText={data.phoneNumber} />
      </Box>
    </Box>
  )
}

LogisticCardBody.propTypes = {
  data: PropTypes.shape({
    productsShipment: PropTypes.string,
    deliveryCompany: PropTypes.string,
    expeditionNumber: PropTypes.string,
    deliveryType: PropTypes.string,
    sendAddress: PropTypes.string,
    fullname: PropTypes.string,
    documentType: PropTypes.string,
    documentNumber: PropTypes.string,
    phoneNumber: PropTypes.string,
    logisticStates: PropTypes.array,
  }),
  isPosOrMasProximo: PropTypes.bool,
}

export default LogisticCardBody
