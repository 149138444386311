import axios from 'axios'
import { get, reduce, snakeCase, camelCase } from 'lodash'
import { APP_CONFIG } from '../../../services/app-config'

import {
  axiosFactory,
  axiosJWT,
  axiosUUID,
  axiosBasic,
  axiosCustomer,
  axiosRefresh,
} from '../../../modules/axios'
import { getDocumentType } from '../../../modules/CustomerDocument'

export const AUTH_URL = `${APP_CONFIG.sales_cluster}/v1/login/`
export const REFRESH_URL = `${AUTH_URL}agent/refresh`

function login(sfid, uuid) {
  return axiosJWT({
    method: 'POST',
    url: AUTH_URL,
    data: {
      user: sfid,
      password: uuid,
    },
  })
    .then(response => {
      axiosFactory.setDefaultHeaders(axiosJWT, {
        Authorization: `Bearer ${response.data.accessToken}`,
      })
      axiosFactory.setCommonDefaultHeaders({
        'X-SFID': sfid,
      })
      return response.data
    })
    .catch(e => {
      throw e.response.data.result
    })
}

function fetchAccountInfo(documentId) {
  const documentType = documentId && getDocumentType(documentId)

  return axiosJWT({
    method: 'GET',
    url: `${APP_CONFIG.care_cluster}/ms-accounts-360/account-info`,
    params: { documentId, documentType },
  })
    .then(response => {
      return response.data
    })
    .catch(e => {
      throw e.response.data
    })
}

export function getAccessToken() {
  return axiosJWT.defaults.headers.common.Authorization.split(' ')[1]
}

let refreshMutex
function refresh() {
  if (refreshMutex) {
    return refreshMutex
  }
  const accessToken = axiosJWT.defaults.headers.common.Authorization.split(' ')[1]
  const refreshToken = axiosRefresh.defaults.headers.common.Authorization.split(' ')[1]

  if (!accessToken || !refreshToken) {
    /* eslint prefer-promise-reject-errors: [0] */
    return Promise.reject({
      response: {
        data: 'refresh:undefined',
      },
    })
  }
  refreshMutex = axiosRefresh({
    method: 'POST',
    url: REFRESH_URL,
    data: {
      accessToken,
    },
  }).then(response => {
    axiosFactory.setDefaultHeaders(axiosJWT, {
      Authorization: `Bearer ${response.data.accessToken}`,
    })
    axiosFactory.setDefaultHeaders(axiosRefresh, {
      Authorization: `Bearer ${response.data.refreshToken}`,
    })
    refreshMutex = undefined
    return response
  })

  return refreshMutex
}

function shouldRefreshRetry(err) {
  return get(err, 'response.status', null) === 401 && get(err, 'config.url', null) !== REFRESH_URL
}

axiosJWT.interceptors.response.use(undefined, err => {
  if (shouldRefreshRetry(err)) {
    return refresh().then(response => {
      const retryConfig = {
        ...err.config,
        headers: {
          ...err.config.headers,
          Authorization: `Bearer ${response.data.accessToken}`,
        },
      }
      return axios(retryConfig)
    })
  }

  return Promise.reject(err)
})

// Simple cache to prevent multiple uuid requests
const CACHE_UUID = {}
/**
 * Generates an UUID with dev default credentials for dev purposes
 * @param {string} username dev username
 * @param {string} roles dev user role
 */
function generateUUID(username = 'pedro', roles = ['DUALPROFILE', 'READONLY']) {
  if (CACHE_UUID[`${username}::${roles}`]) {
    return CACHE_UUID[`${username}::${roles}`]
  }

  const params = {
    method: 'POST',
    data: {
      username,
      roles,
    },
    url: `${APP_CONFIG.care_cluster}/uuid/`,
  }

  CACHE_UUID[`${username}::${roles}`] = axiosBasic(params)
    .then(resp => resp.data)
    .catch(err => {
      CACHE_UUID[`${username}::${roles}`] = null
      throw err
    })

  return CACHE_UUID[`${username}::${roles}`]
}

// TODO: deprecated but new
function loginAgentJWT(sfid, uuid) {
  return axiosUUID({
    method: 'POST',
    url: `${AUTH_URL}agent`,
    data: {
      sfid,
      uuid,
    },
  })
    .then(response => {
      axiosFactory.setDefaultHeaders(axiosUUID, {
        Authorization: `Bearer ${response.data.agentToken}`,
      })
      axiosFactory.setDefaultHeaders(axiosJWT, {
        Authorization: `Bearer ${response.data.accessToken}`,
      })
      axiosFactory.setDefaultHeaders(axiosRefresh, {
        Authorization: `Bearer ${response.data.refreshToken}`,
      })
      axiosFactory.setCommonDefaultHeaders({
        'X-SFID': sfid,
      })
      return response.data
    })
    .catch(e => {
      axiosFactory.resetCommonDefaultHeaders()
      throw e.response.data.result
    })
}

// TODO: deprecated apigee agent token
export function loginAgent(uuid, sfid, isV3) {
  const url = isV3
    ? `${APP_CONFIG.apigee_host}/masmovil/v3/security/agents/login/${uuid}`
    : `${APP_CONFIG.apigee_host}/masmovil/v1/security/ovid/agent_token/${uuid}`
  return axiosBasic({
    method: 'GET',
    url,
    headers: {
      'X-SFID': sfid,
    },
  }).then(resp => resp.data.agent_token)
}

function logout() {
  axiosFactory.resetCommonDefaultHeaders()
  localStorage.clear()
  sessionStorage.clear()
}

function parseJwt(token) {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(c => {
        return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`
      })
      .join(''),
  )

  return JSON.parse(jsonPayload)
}

/**
 * CUSTOMER TOKEN LEGACY
 */

const v1Url = `${APP_CONFIG.apigee_host}/masmovil/v1/security/customer_token`

function mapDocumentType(documentType) {
  return documentType === 'CLI' ? 'FIXEDNUMBER' : documentType
}

function getDocumentsURLSearchParams(documentsDescription) {
  const documentType = mapDocumentType(documentsDescription.documentType)
  const enhancedDocumentsDescription = { ...documentsDescription, documentType }

  return reduce(
    enhancedDocumentsDescription,
    (searchParams, value, fieldName) => {
      searchParams.append(snakeCase(fieldName), value)
      return searchParams
    },
    new URLSearchParams(),
  )
}

// TODO: deprecated
function mapToApigeePostCustomerToken({
  grant_type = 'client_credentials',
  documentType,
  documentId,
}) {
  // const url = v3Url
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
  }
  return {
    method: 'POST',
    headers,
    data: getDocumentsURLSearchParams({
      grant_type,
      documentType,
      documentId,
    }),
    url: v1Url,
  }
}

export function fetchCustomerToken(documentId) {
  const documentType = documentId && getDocumentType(documentId)
  return axiosUUID(mapToApigeePostCustomerToken({ documentType, documentId }))
    .then(resp => {
      const tokenObject = {
        ...Object.keys(resp.data).reduce((token, key) => {
          // eslint-disable-next-line no-param-reassign
          token[camelCase(key)] = resp.data[key]
          return token
        }, {}),
        documentId,
      }
      if (tokenObject.accountId) {
        // eslint-disable-next-line prefer-destructuring
        tokenObject.accountId = tokenObject.accountId[0]
      }
      axiosFactory.setDefaultHeaders(axiosCustomer, {
        Authorization: `Bearer ${tokenObject.customerToken}`,
        'X-Customer-ID': tokenObject.customerId,
      })
      return tokenObject
    })
    .catch(e => {
      throw e.response.data.result
    })
}

export const authService = {
  login,
  refresh,
  shouldRefreshRetry,
  generateUUID,
  loginAgent,
  loginAgentJWT,
  logout,
  parseJwt,
  getAccessToken,
  // TODO: remove customer token stuff
  fetchCustomerToken,
  fetchAccountInfo,
}
