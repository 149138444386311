import { createGlobalStyle } from 'styled-components'
import PropTypes from 'prop-types'
import { RouterAnimationType, RouterAnimationTypes } from '../../helpers'

export const RouterAnimationSlide = createGlobalStyle`
  .animate-enter{    
    position: absolute;
    width: inherit;
    /* Best behavior in Chrome:
     left: ${props => (props.type === RouterAnimationType.LEFT ? '-100vw' : '100vw')}; */
    left: ${props => (props.type === RouterAnimationType.LEFT ? '0vw' : '100vw')};
    transition: transform ${props => `${props.time / 1000}s`};
    transform: translateX(${props => (props.type === RouterAnimationType.LEFT ? '-100vw' : '0vw')});
  }
  .animate-exit{
    position: absolute;
    width: inherit;
    transition: transform ${props => `${props.time / 1000}s`};
    transform: translateX(${props =>
      props.type === RouterAnimationType.LEFT ? '100vw' : '-100vw'});
  }
  .animate-enter-active {
    transition: transform ${props => `${props.time / 1000}s`};
    /* Best behavior in Chrome:
    transform: translateX(${props =>
      props.type === RouterAnimationType.LEFT ? '100vw' : '-100vw'}); */
    transform: translateX(${props => (props.type === RouterAnimationType.LEFT ? '0vw' : '-100vw')});
  }
  .animate-exit-active {
    transition: transform ${props => `${props.time / 1000}s`};
    transform: translateX(${props =>
      props.type === RouterAnimationType.LEFT ? '100vw' : '-100vw'});
  }
`

RouterAnimationSlide.defaultValues = {
  time: 500,
}

RouterAnimationSlide.propTypes = {
  time: PropTypes.number,
  type: PropTypes.oneOf(RouterAnimationTypes),
}
