import { useEffect, useState, useMemo } from 'react'
import PropTypes from 'prop-types'

import { useDispatch, useSelector } from 'react-redux'
import { useFormikContext } from 'formik'
import { isEmpty, get } from 'lodash'

import { logError, formatErrorMessage } from 'services/logging'

import { selectAccountId } from 'modules/Auth/store/selectors'
import { fetchTariffsAction } from 'modules/tariffs/store/index'
import { SIGNUP_TYPE_NEWLINE, SIGNUP_TYPE_PORTABILITY } from 'services/global-constants'

import { selectD2RFromMsFlag } from 'services/feature-flag/selectors'
import { LINE_TYPE } from 'modules/Lines/constants'
import { actions as taxes } from 'services/taxes'
import {
  selectIs2pPure,
  selectMainLineTariffsErrorType,
} from 'modules/NewClientSales/store/selectors/NewClientSales.selectors'
import { Landline } from '../../../components/Offer/Landline'
import { useSignUpLine } from '../../../hooks/Lines'
import {
  useChannelAndSegment,
  useMainUser,
  useResetLine,
  useSetFormikValuesToBasket,
} from '../../../hooks/Shared'
import { fetchSecondHomeDiscountEligibility } from '../../../services/secondResidence.service'
import { onResetTerminal } from '../../../hooks/Terminals'
import { getFormikSelectors, storeSelectors } from './LandlineContainer.selectors'
import { useLandlineValidation } from './useLandlineValidation'

export function LandlineContainer({ fieldNames }) {
  const { setFieldValue, values, setStatus, status } = useFormikContext()
  const {
    selectedSVAS,
    portabilityAnswer,
    accountSegment,
    lineNumber,
    hasD2rDiscount,
    ...formikSelectors
  } = getFormikSelectors(fieldNames, setFieldValue, values)

  const mainUser = useMainUser()
  const dispatch = useDispatch()
  const setFormikValuesToBasket = useSetFormikValuesToBasket()
  const { channel, segment } = useChannelAndSegment()
  const {
    tariffList,
    isNeba,
    isAdsl,
    tax,
    mainOffer,
    isPro,
    is2p,
    installationAddress: { postalCode },
    tariffType,
    isLoadingTariffs,
    isSvaFlag,
    isCrossSell,
    isCartera,
  } = useSelector(storeSelectors)
  const [isLoadingSecondHomeDiscount, setIsLoadingSecondHomeDiscount] = useState(false)

  const is2pPure = useSelector(selectIs2pPure)

  const { onResetAllTerminals } = onResetTerminal()

  const { onResetLines } = useResetLine(fieldNames)

  const shouldShowSVA = !isAdsl && !!mainOffer.ps

  const {
    validateLandline,
    validateRouterNumber,
    hasMsError,
    errorMs,
    fieldName,
    fieldValue,
  } = useLandlineValidation({
    lineType: LINE_TYPE.LANDLINE,
  })

  const routerValidationMessage = useMemo(() => get(values, fieldNames.ROUTER_VALIDATION_MESSAGE), [values])

  const mainLineTariffsError = useSelector(selectMainLineTariffsErrorType)

  const shouldShowHelperText =
    ['yes', SIGNUP_TYPE_PORTABILITY].includes(portabilityAnswer) && isAdsl
  const shouldShowPortability = !isAdsl || shouldShowHelperText

  function onFetchProvisioningNumberError(err) {
    setFieldValue(fieldNames.NEW_NUMBER_ERROR, err.response.status)
    setFieldValue(fieldNames.NEW_NUMBER_OPTIONS, [])
  }

  function onFetchProvisioningNumberSuccess(res) {
    setFieldValue(fieldNames.NEW_NUMBER_VALUE, Array.isArray(res) ? res[0] : res)
    setFieldValue(fieldNames.NEW_NUMBER_OPTIONS, res)
  }

  function onFetchFixedProvisioningNumberSuccess(res) {
    setFieldValue(fieldNames.NEW_FIXED_NUMBER_VALUE, Array.isArray(res) ? res[0] : res)
  }

  function onSignUpTypeChange(value) {
    setFieldValue(fieldNames.IUA, '')
    const isPortability = value === SIGNUP_TYPE_PORTABILITY
    setFieldValue(fieldNames.IS_PORTABILITY, isPortability ? 'yes' : 'no')
  }

  function onChangePortabilityFromFTTH() {
    setFieldValue(fieldNames.IUA, '')
  }

  function onChangeTariff(tariff) {
    let newTariff = tariff
    const priceDiscount = get(newTariff, 'prices[0].discounts[0]')

    if (priceDiscount) {
      newTariff = { ...newTariff, priceDiscount }
    }

    if (tariff.ps !== formikSelectors.ps) {
      onResetLines()
      setFieldValue(fieldNames.TARIFF, newTariff)
      setFormikValuesToBasket(fieldNames.TARIFF, newTariff)
      setFieldValue(fieldNames.PRO_SVAS, [])
      setFormikValuesToBasket(fieldNames.PRO_SVAS, [])
      onResetAllTerminals()
    }
  }

  function onSetPackages(remainingPackages) {
    setFieldValue(fieldNames.SELECTED_PACKAGES, remainingPackages)
    setFormikValuesToBasket(fieldNames.SELECTED_PACKAGES, [...remainingPackages])
  }

  function onDeleteBundle(id) {
    const remainingPackages = formikSelectors.selectedPackages.filter(pack => pack.id !== id)
    onSetPackages(remainingPackages)
  }

  const signUpMobile = useSignUpLine(
    formikSelectors.newNumber,
    is2p ? SIGNUP_TYPE_NEWLINE : null,
    true,
    postalCode,
    onFetchProvisioningNumberError,
    onFetchProvisioningNumberSuccess,
    formikSelectors.mobileNumberOptions,
  )

  const { error, ...signUpProps } = useSignUpLine(
    is2p ? formikSelectors.newFixedNumber : formikSelectors.newNumber,
    formikSelectors.signUpType,
    false,
    postalCode,
    () => {},
    is2p ? onFetchFixedProvisioningNumberSuccess : onFetchProvisioningNumberSuccess,
  )

  useEffect(() => {
    if (formikSelectors.signUpType === SIGNUP_TYPE_PORTABILITY) {
      validateLandline(lineNumber, true)
    } else {
      setStatus({ ...status, 'landlineOffer.lineNumber': '' })
    }
  }, [formikSelectors.signUpType])

  const isD2RMsFlag = useSelector(selectD2RFromMsFlag)
  const accountId = useSelector(selectAccountId)

  const shouldCheckSecondHomeResidenceDiscount = is2p && isCartera && isD2RMsFlag && !isPro

  useEffect(() => {
    if (shouldCheckSecondHomeResidenceDiscount && hasD2rDiscount === undefined) {
      setIsLoadingSecondHomeDiscount(true)

      fetchSecondHomeDiscountEligibility(accountId)
        .then(data => {
          setFieldValue(fieldNames.HAS_D2R_DISCOUNT, data.eligible)
          setIsLoadingSecondHomeDiscount(false)
        })
        .catch(err => {
          setFieldValue(fieldNames.HAS_D2R_DISCOUNT, null)
          setIsLoadingSecondHomeDiscount(false)

          logError(new Error(formatErrorMessage(err)))
        })
    }
  }, [])

  useEffect(() => {
    if (
      !isEmpty(tariffList) ||
      (shouldCheckSecondHomeResidenceDiscount && hasD2rDiscount === undefined)
    ) {
      return
    }

    dispatch(
      fetchTariffsAction({
        segment,
        channel,
        type: tariffType,
        crossSell: !!isCrossSell,
        hasD2rDiscount,
      }),
    )
  }, [hasD2rDiscount])

  useEffect(() => {
    if (postalCode) {
      dispatch(taxes.findTax(postalCode))
    }
  }, [postalCode])

  useEffect(() => {
    if (is2pPure) {
      setFieldValue('landlineOffer.newNumber', '')
      setFormikValuesToBasket('landlineOffer.newNumber', '')
    }
  }, [is2pPure])

  const SVAProps = {
    selectedTariff: mainOffer,
    onSetPackages,
    showPackages: shouldShowSVA,
    fieldNames,
    tax,
    lineType: LINE_TYPE.MAIN_MOBILE,
  }

  return (
    <Landline
      mainUser={mainUser}
      channel={channel}
      fieldNames={fieldNames}
      isPro={isPro}
      is2p={is2p}
      isCrossSell={!!isCrossSell}
      isCartera={isCartera}
      isAdsl={isAdsl}
      isNeba={isNeba}
      mainOffer={mainOffer}
      onChangePortabilityFromFTTH={onChangePortabilityFromFTTH}
      onChangeTariff={onChangeTariff}
      onSignUpTypeChange={onSignUpTypeChange}
      segment={segment}
      shouldShowHelperText={shouldShowHelperText}
      shouldShowPortability={shouldShowPortability}
      shouldShowSVA={shouldShowSVA}
      signUpMobile={is2p && signUpMobile}
      signUpProps={signUpProps}
      tariffList={tariffList}
      isLoading={isLoadingTariffs || isLoadingSecondHomeDiscount}
      SVAProps={SVAProps}
      selectedBundles={formikSelectors.selectedPackages}
      onDeleteBundle={onDeleteBundle}
      isSvaFlag={isSvaFlag}
      validateLandline={validateLandline}
      validateRouterNumber={validateRouterNumber}
      routerValidationMessage={routerValidationMessage}
      hasMsError={hasMsError}
      errorMs={errorMs}
      mainLineTariffsError={mainLineTariffsError}
      fieldName={fieldName}
      fieldValue={fieldValue}
      is2pPure={is2pPure}
      {...formikSelectors}
    />
  )
}

LandlineContainer.propTypes = {
  mainOffer: PropTypes.object,
  fieldNames: PropTypes.object.isRequired,
  tariffList: PropTypes.array,
  tax: PropTypes.number,
}
